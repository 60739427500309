import React from "react";
import LeftSearchBoxModalLayout from "./left-searchbox-modal-layout";

export default class LeftSearchBoxModal extends React.Component<any, {}> {
  render() {
    const {
      img,
      className,
      onClickAccept,
      mainTitle,
      mainText,
      active,
      buttonText,
      close,
      withCancel,
      withInput,
      withSearhBox,
      closeButtonText,
      noSecondaryButton,
    } = this.props;

    return (
      <LeftSearchBoxModalLayout
        className={className}
        img={img}
        onClickAccept={() => onClickAccept()}
        mainTitle={mainTitle}
        mainText={mainText}
        buttonText={buttonText}
        active={active}
        close={close}
        withCancel={withCancel}
        withInput={withInput}
        withSearchBox={withSearhBox}
        closeButtonText={closeButtonText}
        noSecondaryButton={noSecondaryButton}
      />
    );
  }
}
