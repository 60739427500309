import HocelotServices from "../../../../services/HocelotServices";
import { deepGet } from "../../../../utils/utility";
import { isValidDocumentID } from "../../../../utils/validation";
import { validateFields } from "../components/assets";
import { FormValues } from "../hooks/hooks.types";

export const addressData = {
  province: { value: "", label: "" },
  town: { value: "", label: "" },
  address: { label: "", value: "" },
  number: "",
  portalValue: "",
  stairsValue: "",
  floorValue: "",
  doorValue: "",
  postalCode: "",
  isAddressNotNormalized: false,
};

export const takerData = {
  name: "",
  lastname1: "",
  lastname2: "",
  birthDate: "",
  gender: "",
  nationality: {
    value: "",
    label: "",
  },
  docNumber: "",
  docType: "",
  profession: {
    code: "",
    other: "",
    value: "",
  },
  passportDate: "",
};

export const contactData = {
  mobilePhone: "",
  landlinePhone: "",
  email: "",
};

export const guaranteesData: {
  [key: string]: {
    label: string;
    value: boolean;
    capitalToInsure?: string;
    beneficiariesType?: string;
    beneficiarie?: {}[];
  };
} = {
  GS09: {
    label: "Indemnización hospit.",
    value: false,
    capitalToInsure: ""
  },
  GS30: {
    label: "Dental",
    value: false
  },
  GS99: {
    label: "Accidentes",
    value: false,
    capitalToInsure: "",
    beneficiariesType: ""
  }
};

export const documentsData = { uploadDocuments: [] };
export const initialStepperData = () => {
  const isOcasoPolicyString = localStorage.getItem("loggedUser") || "";
  const isOcasoPolicyObj = isOcasoPolicyString
    ? JSON.parse(isOcasoPolicyString)
    : {};
  const isOcasoPolicy =
    isOcasoPolicyObj &&
    isOcasoPolicyObj.roles &&
    Array.isArray(isOcasoPolicyObj.roles)
      ? isOcasoPolicyObj.roles.includes("SUSCAGENTEOCASO")
      : false;

  const policyDataString = localStorage.getItem("policyData") || "{}";
  const policyData = policyDataString ? JSON.parse(policyDataString) : {};

  return {
    taker: takerData,
    address: addressData,
    contact: contactData,
    guarantees: guaranteesData,
    documents: documentsData,
    clinicalData: {},
    policyData: { ...policyData, isOcasoPolicy },
  };
};

export const validateData = (values: FormValues) => {
  const { errors: takerErrors, hasValidation: takerHasValidation } = validateFields(values.taker, takerRules((deepGet(values, 'taker.docType',) || '')));
  const { errors: addressErrors, hasValidation: addressHasValidation } = validateFields(values.address, addressRules);
  const { errors: contactErrors, hasValidation: contactHasValidation } = validateFields(values.contact, contactRules);
  const { errors: guaranteesErrors, hasValidation: guaranteesHasValidation } = validateFields(values.guarantees, guaranteesRules);

  const hasStepOneValidation =
    takerHasValidation && contactHasValidation && addressHasValidation;

  return {
    errors: {
      taker: takerErrors,
      contact: contactErrors,
      address: addressErrors,
      guarantees: guaranteesErrors,
    },
    hasStepOneValidation,
    guaranteesHasValidation
  };
};

const takerRules = (docType: string) => ({
  name: "required",
  lastname1: "required",
  birthDate: "required",
  docType: "required",
  gender: "required",
  nationality: (nationality: { label: "" }) => {
    return !nationality || !nationality.label ? "required" : null;
  },
  docNumber: (docNumber: string) => {
    if (!docNumber || !docType) return 'required';
    const isValid = isValidDocumentID(docNumber, docType);
    return isValid ? null : 'invalid-identification';
  }
});


const contactRules = {
  mobilePhone: (mobilePhone: string) => {
    if (!mobilePhone) return 'required';
    if (mobilePhone.length < 13) return 'invalid-phone-number';
    return null
  },
  email: "required",
};

const addressRules = {
  province: (province: { label: "" }) => {
    return !province || !province.label ? "required" : null;
  },
  town: (town: { label: "" }) => {
    return !town || !town.label ? "required" : null;
  },
  address: (address: { label: "" }) => {
    return !address || !address.label ? "required" : null;
  },
  number: "required",
  postalCode: (postalCode: { label: "" }) => {
    return !postalCode || !postalCode.label ? "required" : null;
  },
};

const guaranteesRules = {
  GS09: (data: { value: boolean; capitalToInsure: string }) => {
      return data.value && !data.capitalToInsure ? {capitalToInsure: "required"} : null;
    },
  GS99: (data: { value: boolean; capitalToInsure: string, beneficiariesType: string, beneficiaries: [] }) => {
      const { value, capitalToInsure, beneficiariesType, beneficiaries } = data;
      if (value) {
        if (!capitalToInsure && !beneficiariesType) {
          return { capitalToInsure: 'required', beneficiariesType: 'required' }
        }
        if (!capitalToInsure) {
          return { capitalToInsure: 'required', beneficiariesType: '' }
        } 
        
        if (!beneficiariesType) {
          return { capitalToInsure: '', beneficiariesType: 'required' }
        } else {
          if (beneficiariesType === 'designatedBeneficiaries' && !beneficiaries) return { beneficiaries: 'required' }
        }
      }
      return null;
    },
};

export const emptyRemoveProps = {
  beneficiaries: [],
  index: 0,
  code: "",
};

export const validateEmailByHocelot = async (value: string) => {
  try {
    const result = await HocelotServices.verifyEmail({
      data: { email: value.trim() },
      setLoading: () => {},
    });
    if (!result.isVerified) {
      return "invalid-email";
    } else return '';
  } catch (error) {
    return "invalid-email";
  }
}