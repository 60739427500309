import styled from "styled-components";
import { colors } from "../../../assets";

export const CampaignsCreateContainer = styled.div`
  display: flex;
  width: 100%;
  font-family: Museo Sans;
  font-style: normal;

  .campaigns-main-container {
    width: 100%;
  }

  .campaigns-create-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 50px;
    align-items: center;
    &__title {
    }
    &__guide {
    }
  }

  .campaigns-create-section-title {
    margin-bottom: 30px;
    color: #004f8b;
  }

  .campaigns-create-section-subtitle {
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #3a4b68;
  }

  .campaigns-create-forms {
  }

  .campaigns-back-button {
    margin-bottom: 16px;
  }

  .campaigns-side-bar {
    margin-top: 29px;
    width: 230px;
    margin-left: 40px;

    &__item {
      margin-bottom: 20px;
    }
  }

  .campaigns-side-bar-prizes {
    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: ${colors["TX-001-50"]};
      margin-top: 68px;
      margin-bottom: 20px;
    }
    &__card {
      margin-bottom: 16px;
      padding-left: 10px;
    }
    &__button {
      margin-top: 40px;
      padding-left: 10px;
    }
  }
`;
