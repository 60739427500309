import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { MascotsInsuredDataContainer } from "./mascots-insured-data-style";
import InputBox from "../../../../components/input-box/input-box";
import DicotomicButton from "../../../../components/dicotomic-button";
import SelectBox from "../../../../components/select-box/select-box";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import MainButton from "../../../../components/main-button";
import Title02Text from "../../../../components/components-text/title-02-text";
import DicotomicCheckbox from "../../../../components/dicotomic-checkbox/dicotomic-checkbox";
import TYPE_PETS from "../../../../constants/typePets.json";
import HeadsTitleText from "../../../../components/components-text/heads-title-text";
import GuideLine from "../../../../components/guide-line";
import { ValueForm } from "../../../../utils/interfaces";
import { withPolicy } from "../../../../context/mixin/with-policy";
import { withGeneral } from "../../../../context/mixin/with-general";
import ConstantsServices from "../../../../services/ConstantsServices";
import SearchBox from "../../../../components/search-box-mediadores/search-box";
import CoveragesServices from "../../../../services/CoveragesServices";
import { capitalValidation, formatCoverages } from "../../../../utils/coverages";
import { images } from "../../../../assets";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import { CAPITAL_OPTIONS_AV04, CAPITAL_OPTIONS_AV05, CAPITAL_OPTIONS_AV06, CAPITAL_OPTIONS_RC } from "../../../../constants/typePets";
import { formatter } from "../../../../utils/utility";
import LabelText from "../../../../components/components-text/label-text";
import { getProductTypeFromURL } from "../../../../constants/policyType";

interface MascotsInsuredDataProps extends RouteComponentProps {
  match: any;
  policy: any;
  coverages: any;
  setLoading: Function;
  newStep?: () => void;
  goBack?: () => void;
  linkDetail?: string;
  goToDetail?: number;
  companiesException?:Function;
  goToValidatePet?: boolean;
  validatePet?: (goToValidatePet: boolean) => void;
}

interface MascotsInsuredDataState {
  isEdit?: any;
  petBreeds: any[];
  petBreedsFiltered: any[];
  coverages?: any;
  indexSearch: string;
  formData?: {
    contact: {
      name: ValueForm;
      birthdate: ValueForm;
      type: ValueForm;
      breed: ValueForm;
      owner: ValueForm;
      numChip: ValueForm;
    };
    coverages: any;
    [key: string]: any;
    isReadOnly: boolean;
  };
  companies: any[];
  otherPolicy?: any
}

class MascotsInsuredData extends React.Component<
  MascotsInsuredDataProps,
  MascotsInsuredDataState
> {
  constructor(props: MascotsInsuredDataProps) {
    super(props);

    //const formData = this.getFormData();

    const urlString = window.location.href;
    const url = new URL(urlString);
    const index = url.searchParams.get("index");

    // const isEdit = {
    //   index,
    //   value: !!formData.contact.numChip.value,
    // }

    this.state = {
      //isEdit,
      //formData,
      petBreeds: [],
      petBreedsFiltered: [],
      indexSearch: "",
      companies: [],
      otherPolicy: {}
    };
  }

  componentDidMount = () => {
    const { policy, history } = this.props;
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    if (Object.keys(policy.data.taker).length === 0)
      history.push("/contratacion/nueva");
    this.getPetBreeds();
    this.getCoveragesData();
    this.getCompaniesList();
  };

  componentDidUpdate = (prevProps: MascotsInsuredDataProps) => {
    const { goToDetail } = this.props;
    const { indexSearch } = this.state;

    if (this.props.goToValidatePet && !prevProps.goToValidatePet) {
      return this.toValidate();
    }

    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.search &&
      this.props.history.location.search !== indexSearch
    ) {
      this.setState({ indexSearch: this.props.history.location.search }, () =>
        this.getCoveragesData()
      );
    }

    if (goToDetail !== undefined && prevProps.goToDetail !== goToDetail) {
      this.toValidate(goToDetail);
    }
  };

  async getCoveragesData() {
    const { policy, coverages } = this.props
    const productCoverageName = policy.product.products_coverageName
    let cov = []

    if (coverages && coverages[productCoverageName]) {
      cov = formatCoverages(coverages[productCoverageName])
    } else {
      const resp: any = await CoveragesServices.getCoverages({
        setLoading: this.props.setLoading,
        loader: true,
        coverageType: productCoverageName,
      })
      cov = formatCoverages(resp.data)
    }

    this.setState({ coverages: cov }, () => {
      const formData = this.getFormData();
      this.setState({ formData }, () =>{ 
        this.getPetBreeds();
      });
    });
  }

  getFormData() {
    const { props } = this;
    const { coverages } = this.state;
    const urlString = window.location.href;
    const url = new URL(urlString);
    const index = url.searchParams.get("index");
    let formData: any = {};
    if (index !== null) {
      formData = props.policy.data.insureds[parseInt(index)];
      if(formData && formData.coverages){
        Object.entries(formData.coverages).forEach(([code, coverage]:any) => {
          if(coverage.otherInsurance===null){
            this.setState((prevState)=>({
              otherPolicy:{
                  ...prevState.otherPolicy,
                  [code]: "No"
                }
              })
            )
          }
          else{
            this.setState((prevState)=>({
              otherPolicy:{
                  ...prevState.otherPolicy,
                  [code]: "Si"
                }
              })
            )
          }
        });
        
      }
    }
    
    return !formData || Object.keys(formData).length === 0
      ? {
        contact: {
          name: {
            value: "",
            error: false,
            errorCode: "",
            required: true,
          },
          birthdate: {
            value: "",
            error: false,
            errorCode: "",
            required: true,
          },
          type: {
            value: "",
            error: false,
            errorCode: "",
            required: true,
          },
          breed: {
            value: "",
            error: false,
            errorCode: "",
            required: true,
          },
          owner: {
            value: props.policy.data.taker.contact
              ? `${props.policy.data.taker.contact.name.value} ${props.policy.data.taker.contact.lastname1.value} ${props.policy.data.taker.contact.lastname2.value}`
              : "",
            error: false,
            errorCode: "",
            required: true,
          },
          numChip: {
            value: "",
            error: false,
            errorCode: "",
            required: true,
          },
        },
        coverages: this.getCoverages(coverages),
        isReadOnly: false,
      }
      : { ...formData, isReadOnly: props.policy.data.isReadOnly };
  }

  _formatCapitales = (cap: any) => {
    let options: Array<{
      [key: string]: string;
    }> = []
    let s = cap.map((v: any) => {
      options.push({ "label": `${formatter.format(Number(v.importe))}`, "value": v.importe })
    })
    return options;
  }

  getCoverages(coverages: any) {
    const keys = Object.keys(coverages);
    const coveragesObj: any = {};

    keys.forEach((k) => {
      let capitalOptionsSelected
      for (let i = 0; i < coverages[k].items.length; i++) {
        switch(coverages[k].items[i].code){
          case "RC01":
            capitalOptionsSelected = CAPITAL_OPTIONS_RC
            break;
          case "AV04":
            capitalOptionsSelected = CAPITAL_OPTIONS_AV04
            break;
          case "AV05":
            capitalOptionsSelected = CAPITAL_OPTIONS_AV05
            break;
          case "AV06":
            capitalOptionsSelected = CAPITAL_OPTIONS_AV06
            break;
        }
        const optionsCapitales = this.props.coverages.pet.find((cov:any)=>cov.code===coverages[k].items[i].code);

        coveragesObj[coverages[k].items[i].code] = {
          value:
            coverages[k].items[i].defaultValue !== undefined
              ? coverages[k].items[i].defaultValue
              : k === "main",
          error: false,
          errorCode: "",
          required: true,
          name: coverages[k].items[i].name,
          complementary: k === "complementary",
          optional: k === "optional",
          notShow: k === "notShow",
          optionCode: coverages[k].items[i].optionCode,
        };
        if (coverages[k].items[i].capital) {
          coveragesObj[coverages[k].items[i].code] = {
            ...coveragesObj[coverages[k].items[i].code],
            min: coverages[k].items[i].capital.min,
            max: coverages[k].items[i].capital.max,
            options: coverages[k].items[i].capital.options,
            capital: {
              value: coverages[k].items[i].capital.value || "" ,
              error: false,
              errorCode: "",
              required: coveragesObj[coverages[k].items[i].code].value,
            },
          };
        }
        if(coverages[k].items[i].otherInsurance && coverages[k].items[i].otherInsurance!==null){
          coveragesObj[coverages[k].items[i].code] = {
            ...coveragesObj[coverages[k].items[i].code],
            otherInsurance: {
              company:{
                value: coverages[k].items[i].otherInsurance.company || "",
                error: false,
                errorCode: "",
                required: true,
              },
              numberPolicy:{
                value: coverages[k].items[i].otherInsurance.numberPolicy || "",
                error: false,
                errorCode: "",
                required: true,
              }
            }
          }
          this.setState((prevState)=>({
            otherPolicy:{
                ...prevState.otherPolicy,
                [coverages[k].items[i].code]: "Si"
              }
            })
          )
        }
        else if(coverages[k].items[i].otherInsurance && coverages[k].items[i].otherInsurance===null && this.state.otherPolicy[coverages[k].items[i].code]==="Si"){
          coveragesObj[coverages[k].items[i].code] = {
            ...coveragesObj[coverages[k].items[i].code],
            otherInsurance: {
              company:{
                value: "",
                error: false,
                errorCode: "",
                required: true,
              },
              numberPolicy:{
                value: "",
                error: false,
                errorCode: "",
                required: true,
              }
            }
          }
        }
        else{
          coveragesObj[coverages[k].items[i].code] = {
            ...coveragesObj[coverages[k].items[i].code],
            otherInsurance:null
          }
          this.setState((prevState)=>({
            otherPolicy:{
                ...prevState.otherPolicy,
                [coverages[k].items[i].code]: "No"
              }
            })
          )
        }
        let covOpc=["AV04", "AV05", "AV06", "RC01"];
        if(covOpc.includes(coverages[k].items[i].code)){
          if (optionsCapitales!==null && optionsCapitales.capitales!==null) {
            coveragesObj[coverages[k].items[i].code] = {
              ...coveragesObj[coverages[k].items[i].code],
              options: this._formatCapitales(optionsCapitales.capitales)
            };
          }
          else{
            coveragesObj[coverages[k].items[i].code] = {
              ...coveragesObj[coverages[k].items[i].code],
              options: capitalOptionsSelected
            };
          }
        }
        if(coveragesObj[coverages[k].items[i].code].options){
          coveragesObj[coverages[k].items[i].code] = {
            ...coveragesObj[coverages[k].items[i].code],
            capital: {
              value: coveragesObj[coverages[k].items[i].code].capital?coveragesObj[coverages[k].items[i].code].capital.value:"",
              error: false,
              errorCode: "",
              required: coveragesObj[coverages[k].items[i].code].value,
            },
          };
        }
        if (coverages[k].items[i].requiredCoverages) {
          coveragesObj[coverages[k].items[i].code].requiredCoverages =
            coverages[k].items[i].requiredCoverages;
        }
        if (coverages[k].items[i].dependantCoverages) {
          coveragesObj[coverages[k].items[i].code].dependantCoverages =
            coverages[k].items[i].dependantCoverages;
        }
      }
    });

    return coveragesObj;
  }

  getPetBreeds = async () => {
    const { setLoading } = this.props;
    const { formData } = this.state;
    if (!formData || !formData.contact || !formData.contact.type || !formData.contact.type.value) return;
    let responsePetBreeds = await ConstantsServices.getPetBreeds({
      loader: false,
      setLoading: setLoading,
      params: [`petType=${formData.contact.type.value}`],
    });
    responsePetBreeds = responsePetBreeds.sort((a: any, b: any) => {
      if (a.label < b.label) return -1;
      else if (a.label > b.label) return 1;
      return 0;
    });
    const petBreeds = responsePetBreeds.map((p: any) => ({
      label: p.label,
      value: p.value.toString(),
    }));
    // formData.contact.breed = {
    //   value: "",
    //   error: false,
    //   errorCode: "",
    //   required: true
    // }
    this.setState({ petBreedsFiltered: petBreeds, petBreeds, formData });
  };

  getCompaniesList = async () => {
    const { setLoading } = this.props;
    let responseCompanies = await ConstantsServices.getCompanies({
      loader: false,
      setLoading: setLoading,
    });
    responseCompanies = responseCompanies.sort((a: any, b: any) => {
      if (a.DESC_CIA < b.DESC_CIA) return -1;
      else if (a.DESC_CIA > b.DESC_CIA) return 1;
      return 0;
    });
    const companiesFormat = responseCompanies.map((p: any) => ({
      value: p.COD_CIA,
      label: p.DESC_CIA,
    }));
    this.setState({ companies: companiesFormat });
  };

  filterPetList = (value: any) => {
    this.setState({
      petBreedsFiltered: this.state.petBreeds.filter((pet) =>
        pet.label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            value
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
      )
    })
  }

  getErrorsFormData = (obj:any) =>{
    let contadorErrores = 0;

    // Verificar si el objeto es un array
    if (Array.isArray(obj)) {
      // Recorrer cada elemento del array
      for (let i = 0; i < obj.length; i++) {
        // Llamar recursivamente a la función para cada elemento
        contadorErrores += this.getErrorsFormData(obj[i]);
      }
    } else if (typeof obj === 'object' && obj !== null) {
      // Si el objeto no es un array pero es un objeto
      // Recorrer cada propiedad del objeto
      for (let clave in obj) {
        // Verificar si la propiedad es "error" y tiene el valor "true"
        if (clave === 'error' && obj[clave] === true) {
          contadorErrores++;
        } else {
          // Llamar recursivamente a la función para cada propiedad del objeto
          contadorErrores += this.getErrorsFormData(obj[clave]);
        }
      }
    }

  // Devolver el contador de errores
  return contadorErrores;
  }
  changeValue = (value: any, block: string, key: string = "") => {
    const { formData } = this.state;
    if (!formData) return;
    if (typeof value === "string" && key !== "type")
      value = value.toUpperCase();
    formData[block][key].value = value;
    if (key === "type") {
      formData.contact.breed.value = "";
    }
    if (formData[block][key].capital) {
      formData[block][key].capital.required = value;
    }
    if(formData[block][key].capital&&!value){
      formData[block][key].capital.value = '';
      formData[block][key].capital.required = value;

    }
    this.props.policy.changeData();
    this.setState({ formData }, () => {
      if (key === "type") this.getPetBreeds();
    });
  };

  changeValueCoverage = (
    value: any,
    block: string,
    keyCoverage: string = "",
    key: string,
    otherInsurance?: string
  ) => {
    const { formData } = this.state;
    if (!formData) return;
    if(otherInsurance){
      formData[block][keyCoverage][key][otherInsurance].errorCode = '';
      formData[block][keyCoverage][key][otherInsurance].value = value;
    }
    else{
      formData[block][keyCoverage][key].errorCode = '';
      formData[block][keyCoverage][key].value = value;
    }
    

    this.props.policy.handleNewChanges();
    this.props.policy.changeData();
    this.setState({ formData });
  };

  toValidate = (detailIndex?: number, block?: string, fromThisComponent?: boolean) => {
    const { newStep, validatePet, goToValidatePet, location, policy } = this.props;
    const { formData, isEdit } = this.state;

    //Capital validation
    let error_capital = false

    if (formData && formData.coverages){
      const validatedCoverages = capitalValidation(formData.coverages)
      if(Object.keys(validatedCoverages).length!==0){
        formData.coverages = {...validatedCoverages}
        error_capital= true
      }
    }

    const type = "insured";
    const obj = this.props.policy.validateForm(formData, type, block, isEdit, error_capital);
    // const hasError =
    //   Object.values(obj.formData.contact).filter((value: any) => value.error)
    //     .length > 0 ;
    const hasError= this.getErrorsFormData(obj.formData);
    this.setState({ formData: obj.formData, isEdit: obj.isEdit }, () => {
      if (!hasError && !goToValidatePet) {
        const search = location.search || '';
        const index = search[search.length - 1];
        const petLength = policy.data.insureds.length;

        if (!fromThisComponent) {
          if (detailIndex !== undefined) this.goToDetail(detailIndex);
          else newStep && newStep();
        } else if (petLength - 1 > parseInt(index)) {
          this.goToDetail(parseInt(index) + 1)
        } else if (detailIndex !== undefined) {
          this.goToDetail(detailIndex);
        } else {
          newStep && newStep();
        }
      }
    });
      
    validatePet && validatePet(false);
  };

  goToDetail = (index: number) => {
    const { linkDetail, history, policy } = this.props;
    const urlProduct = getProductTypeFromURL(policy.product.products_link);

    history.push(
      linkDetail +
      urlProduct +
      "/" +
      (index === -1 ? "tomador" : index === -2? "asegurado-beneficiario" : `asegurado?index=${index}`)
    );
  };

  renderColumn(items: any[], pos?: any) {
    if (items.length % 3 !== 0) {
      const array = new Array(3 - (items.length % 3)).fill(0);

      return array.map((i, index) => (
        <div
          key={`col-empty-${pos}-${index}`}
          className="personal-checkbox-item col-50"
        />
      ));
    }
  }

  getBreed(value: number) {
    const { petBreeds } = this.state;
    const breed = petBreeds.find((p: any) => p.value === value);
    if (breed) return breed.label;
    return "";
  }

  getOtherInsurance(code:string, event:any) {
    const {formData, companies} = this.state;
    const {companiesException} = this.props;
    let otherInsurance:any = formData&&formData.coverages[code].otherInsurance?formData.coverages[code].otherInsurance:null;
    event.persist();

    if(event.target.value==="Si"){
      if(companies.length===0){
        if(companiesException)companiesException();
        this.setState((prevState)=>({
          otherPolicy:{
              ...prevState.otherPolicy,
              [code]: "No"  
            }
          })
        ) 
      }
      if(otherInsurance===null && companies.length!==0 ){
        otherInsurance={
          company:{
            value: "",
            error: false,
            errorCode: "",
            required:true,
          },
          numberPolicy:{
            value: "",
            error: false,
            errorCode: "",
            required:true,
          }
        }
        this.setState((prevState)=>({
          otherPolicy:{
            ...prevState.otherPolicy,
            [code]: event.target.value   
          }
        })) 
      } 
    }
    if(event.target.value==="No"){
      otherInsurance=null;
      this.setState((prevState)=>({
        otherPolicy:{
          ...prevState.otherPolicy,
          [code]: event.target.value   
        }
      })) 
    }

    this.setState((prevState)=>({
      formData:prevState.formData&&{
          ...prevState.formData,
          coverages: {
            ...prevState.formData.coverages,
            [code]: {
              ...prevState.formData.coverages[code],
              otherInsurance: otherInsurance
            }
          }  
        }
      })
    ) 
  }

  render() {
    const { formData } = this.state;
    if (!formData) return <></>;
    const { contact, coverages, isReadOnly } = formData;
    const { petBreeds, petBreedsFiltered, otherPolicy, companies } = this.state;
    const { policy } = this.props;
    const urlString = window.location.href;
    const url = new URL(urlString);
    const index = url.searchParams.get("index");
    const getTablesNumberMascots = ()=>{
      let main:any = [];
      let complementary:any = [];
      let table:any = [];
      const othersPolicy = ["RC01", "AV04"];
      Object.keys(this.state.coverages).map((k)=>{
        if(this.state.coverages[k].items.length > 0){
          this.state.coverages[k].items.map((item:any, i:number)=>{
            if(k==='main'){
              main.push(
                <div key={i}>
                  <div className="content-wrapper"><p>{item.name}</p></div>
                </div>
              );
            }
            else{
              complementary.push(
                <div className={coverages[item.code].value?"flex-space-between plr-20":" flex-space-between content-wrapper"} key={i}>
                  <DicotomicCheckbox
                    initChecked={coverages[item.code].value}
                    disabled={item.disabled || formData.isReadOnly}
                    className={item.value===true?"optional pt-10 colSin-50":"optional colSin-50"}
                    checkboxText={coverages[item.code].name}
                    onChange={(value: string) =>{
                      this.changeValue(
                        value,
                        "coverages",
                        item.code
                      )
                    }}
                  />
                  {coverages[item.code].value &&
                    <div className="h-auto colSin-50">
                      <div className="selectBox flex justifyFlex-end">
                        <label className="point">&nbsp;</label>
                        <SelectBox
                          placeholder="Seleccionar capital"
                          required={true}
                          errorCode={coverages[item.code].capital&&coverages[item.code].capital.errorCode}
                          optionsText={coverages[item.code].options&&coverages[item.code].options}
                          initialValue={coverages[item.code].capital&&coverages[item.code].capital.value}
                          defaultValue={coverages[item.code].capital&&coverages[item.code].capital.value}
                          optionKey={"value"}
                          optionValue={"label"}
                          onChange={(value: string) =>{
                            this.changeValueCoverage(
                              value,
                              "coverages",
                              item.code,
                              "capital"
                            )
                          }}
                        /> 
                      </div>                         
                    </div>
                  }
                  {coverages[item.code].value && 
                    othersPolicy.includes(item.code) && 
                    <><div className="flex colSin-100">
                      <div className="colSin-40 pl-38"><p className="content-wrapper">¿Posee ya un seguro de {item.name}?</p></div>
                      <div className="flex">
                        <div className="radioBox pt-5">
                          <label>
                            <span>Sí</span>
                            <input
                              type="radio"
                              name={`otherPolicy${item.code}-${index&&Number(index)+1}`}
                              value={"Si"}
                              checked={otherPolicy[item.code]==="Si" && companies.length!==0}
                              onChange={(ev) => this.getOtherInsurance(item.code, ev)}
                            />
                          </label>
                        </div>
                        <div className="radioBox pt-5">
                          <label>
                            <span>No</span>
                            <input
                              type="radio"
                              name={`otherPolicy${item.code}-${index&&Number(index)+1}`}
                              value={"No"}
                              checked={otherPolicy[item.code]==="No" || companies.length===0}
                              onChange={(ev) => this.getOtherInsurance(item.code, ev)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="flex colSin-100">
                      <div className="flex align-center pl-38">
                        <label className="content-wrapper">¿En qué compañía?</label>
                      </div>
                      <div className="selectBox flex justify-start">
                        {coverages[item.code].otherInsurance && 
                          coverages[item.code].otherInsurance!==null &&
                          coverages[item.code].otherInsurance.company.required &&
                          <label className="point">&nbsp;</label>}
                        <SelectBox
                          placeholder="Selecciona"
                          required={coverages[item.code].otherInsurance && coverages[item.code].otherInsurance!==null && coverages[item.code].otherInsurance.company ? coverages[item.code].otherInsurance.company.required: false}
                          errorCode={coverages[item.code].otherInsurance && coverages[item.code].otherInsurance!==null && coverages[item.code].otherInsurance.company ? coverages[item.code].otherInsurance.company.errorCode: ''}
                          optionsText={companies}
                          initialValue={companies.length!==0 && coverages[item.code].otherInsurance && coverages[item.code].otherInsurance!==null && coverages[item.code].otherInsurance.company ? coverages[item.code].otherInsurance.company.value: ''}
                          defaultValue={companies.length!==0 && coverages[item.code].otherInsurance && coverages[item.code].otherInsurance!==null && coverages[item.code].otherInsurance.company ? coverages[item.code].otherInsurance.company.value: ''}
                          optionKey={"value"}
                          optionValue={"label"}
                          onChange={(value: string) =>{
                            this.changeValueCoverage(
                              value,
                              "coverages",
                              item.code,
                              "otherInsurance",
                              "company"
                            )
                          }}
                          disabled={otherPolicy && otherPolicy[item.code] === "No" || coverages[item.code].otherInsurance===null || companies.length===0}
                        />
                      </div>
                      <div className="flex align-center">
                        <label className="content-wrapper">Nº Póliza</label>
                      </div>
                      <div className="inputBox colSin-30 flex justify-start">
                      {coverages[item.code].otherInsurance && 
                          coverages[item.code].otherInsurance!==null &&
                          coverages[item.code].otherInsurance.numberPolicy.required &&
                          <label className="point">&nbsp;</label>}
                        <InputBox
                          type="number"
                          required={coverages[item.code].otherInsurance && coverages[item.code].otherInsurance!==null && coverages[item.code].otherInsurance.numberPolicy ? coverages[item.code].otherInsurance.numberPolicy.required : false}
                          errorCode={coverages[item.code].otherInsurance && coverages[item.code].otherInsurance!==null && coverages[item.code].otherInsurance.numberPolicy ? coverages[item.code].otherInsurance.numberPolicy.errorCode: ''}
                          value={companies.length!==0 && coverages[item.code].otherInsurance && coverages[item.code].otherInsurance!==null && coverages[item.code].otherInsurance.numberPolicy ? coverages[item.code].otherInsurance.numberPolicy.value: ''}
                          onChange={(value: string) =>
                            this.changeValueCoverage(
                              value,
                              "coverages",
                              item.code,
                              "otherInsurance",
                              "numberPolicy"
                            )
                          }
                          disabled={isReadOnly || otherPolicy && otherPolicy[item.code] === "No" || coverages[item.code].otherInsurance===null ||companies.length===0}
                        />
                      </div>
                    </div></>
                  }
              </div>)
              }
            });
          }
      }); 
      table.push(
        <div className="personal-data-container mb-10" key={Number(index)}>
          <div className="col-100 mb-1 borderB-white"><div className="col-25 table-bgBlue content-wrapper">Mascota {Number(index)+1}</div></div>
          <div className="colSin-30 table-tr mr-2per">
            <p className="table-bgBlue content-wrapper">GARANTÍAS OBLIGATORIAS</p>
            {main}
          </div>
          <div className="colSin-69 table-tr">
            <p className="table-bgBlue content-wrapper">GARANTÍAS OPCIONALES</p>
            {complementary}
          </div>
        </div>)
        return table;    
    }

    return (
      <MascotsInsuredDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <HeadsTitleText>
              <p>{`Datos de la mascota ${index !== null
                ? parseFloat(index) + 1
                : policy.lengthInsured + 1
                } a asegurar`}</p>
            </HeadsTitleText>
          </div>
          <div className="data-flow-status-guide">
            <GuideLine steps={[0, 0, 0, 0]} currentStep={2} />
          </div>
        </div>
        <div className="personal-data-wrapper">
          <div className="data-title">
            <Title02Text>
              <p>Datos de la mascota</p>
            </Title02Text>
          </div>

          <div className="personal-data-container">
            <div className="personal-data-row">
              <div className="personal-data-item col-50">
                <div className="inputBox">
                  <InputBox
                    placeholder="Nombre"
                    required={contact.name.required}
                    errorCode={contact.name.errorCode}
                    value={contact.name.value}
                    onChange={(value: string) =>
                      this.changeValue(value, "contact", "name")
                    }
                    disabled={isReadOnly}
                  />
                </div>
              </div>
              <div className="personal-data-item col-50">
                <div className="inputBox">
                  <CalendarBox
                    initialValue={contact.birthdate.value}
                    forbidFutureDates={true}
                    placeholder="Fecha de nacimiento"
                    required={contact.birthdate.required}
                    errorCode={contact.birthdate.errorCode}
                    onChange={(value: string) =>
                      this.changeValue(value, "contact", "birthdate")
                    }
                    disabled={isReadOnly}
                  />
                </div>
              </div>
            </div>
            <div className="personal-data-row">
              <div className="personal-data-item col-25">
                <DicotomicButton
                  errorCode={contact.type.errorCode}
                  labelText="Tipo"
                  options={TYPE_PETS}
                  initialValue={contact.type ? contact.type.value : ""}
                  onChange={(value: string) => {
                    if (value !== contact.type.value)
                      this.changeValue(value, "contact", "type");
                  }}
                  withTab={true}
                  disabled={isReadOnly}
                />
              </div>
              <div className="personal-data-item col-75">
                <div className="inputBox topMargin10">
                  <SearchBox
                    onChange={(ev: any) => { this.filterPetList(ev.target.value) }}
                    labelText=""
                    optionsText={petBreedsFiltered}
                    optionKey={"value"}
                    optionValue={"label"}
                    initialValue={
                      contact.breed.value.label ||
                      this.getBreed(contact.breed.value.value) ||
                      ""
                    }
                    required={contact.breed.required}
                    errorCode={contact.breed.errorCode}
                    onSelect={(value: any) =>
                      this.changeValue(value, "contact", "breed")
                    }
                    disabled={isReadOnly}
                  />
                  <label>Raza</label>
                </div>
              </div>
            </div>
            <div className="personal-data-row">
              <div className="personal-data-item col-100">
                <div className="inputBox topMargin10">
                  <InputBox
                    placeholder="Microchip"
                    required={contact.numChip.required}
                    errorCode={contact.numChip.errorCode}
                    value={contact.numChip.value}
                    maxLength={15}
                    type={"number"}
                    onChange={(value: string) =>
                      this.changeValue(value, "contact", "numChip")
                    }
                    disabled={isReadOnly}
                  />
                </div>
              </div>
            </div>
            <div className="data-title">
              <LabelText>
                <p>DATOS DE LAS GARANTÍAS</p>
              </LabelText>
            </div>
            {getTablesNumberMascots()}
          </div>
        </div>
        <div className="personal-data-container">
          <div className="personal-data-row">
            <div className="personal-data-buttons">
              <div className="personal-data-buttons__item">
                <MainButton
                  withTab={true}
                  icon={true}
                  type={"white"}
                  iconImg={images.IconArrowLeftBlue}
                  text="Atrás"
                  onClick={() => this.props.goBack ? this.props.goBack() : this.props.history.goBack()}
                />
              </div>
              <div className="personal-data-buttons__item">
                <MainButton
                  withTab={true}
                  text="Siguiente"
                  onClick={() => this.toValidate(undefined, '', true)}
                />
              </div>
            </div>
          </div>
        </div>
      </MascotsInsuredDataContainer>
    );
  }
}

export default withGeneral(withCoverages(withPolicy(withRouter(MascotsInsuredData))));
