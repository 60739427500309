import React, { Component } from "react";
import { ICoveragesAmount } from "../../containers/simulations/new-simulation/result/interfaces/result-interface";
import MainTable from "../main-table";
import { DentalInsuredContainer } from "./dental-insured-result-style";

interface DentalInsuredProps {
  age: number;
  birthDate: string;
  checked: boolean;
  id: number;
  index: number;
  onChange: Function;
  onClick: Function;
  result?: boolean;
  onCheckInsured?: any;
  insured?: any;
  gender?: string;
  parentesco?: string;
  coverages: any;
  coverageAmount?: any;
}

interface DentalInsuredState {
  checked: boolean;
}

const insuredTableColumns = [
  {
    text: "",
    filter: false,
    ascendent: true,
    key: "entire-mini-title",
  },
  {
    text: "Cobertura",
    filter: false,
    ascendent: true,
    key: "dimmed-mini-title",
  },
  {
    text: "Capital",
    filter: false,
    ascendent: true,
    key: "dimmed-mini-title",
  },
  {
    text: "Prima neta",
    filter: false,
    ascendent: true,
    key: "dimmed-mini-title",
  },
  {
    text: "Prima total",
    filter: false,
    ascendent: true,
    key: "dimmed-mini-title",
  },
  {
    text: "Impuesto",
    filter: false,
    ascendent: false,
    key: "dimmed-mini-title",
  },
];

class DentalInsuredResultItem extends Component<DentalInsuredProps, DentalInsuredState> {

  constructor(props: DentalInsuredProps) {
    super(props)
    this.state = { checked: this.props.checked }
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.checked !== undefined) {
      this.setState({ checked: nextProps.checked });
    }
  }

  handleOnCheck(): void {
    this.props.onCheckInsured(this.props.index);
    this.setState({ checked: !this.state.checked });
  }

  getInsuredTableRows = (coverageAmount: any, coveragesParam: any) => {
    const { coverages } = this.props;
    let order: any = []


    let filter = coverages.sort((a: any, b: any) => {
      if (a.required) return (b.required && a.code > b.code) ? 1 : -1;
      else if (b.required) return 1
      else if (a.code < b.code) return -1;
      else return 1;

    })
    filter.map((x: any) => {
      if (order.indexOf(x.code) < 0)
        order.push(x.code)
    })
    let coveragesFilteredOrdered = order.map((order: any) => {
      return coverageAmount.find((x: any) =>
        x.CODE === order)
    })
    coveragesFilteredOrdered = coveragesFilteredOrdered.filter(function (x: any) {
      if (x != undefined) {
        if (x.CAPITAL && x.CAPITAL.toUpperCase() === "INCLUIDO") {
          x.CAPITAL = "1"
        } else {
          let res = coveragesParam.find((y: any) => x.CODE === y.code)
          if (res && res.capital) {
            x.CAPITAL = res.capital.toString()
          }
          if (res && res.capitalValue) {
            x.CAPITAL = res.capitalValue
          }
        }
        return x;
      }
    });
    const rows = coveragesFilteredOrdered.map((coverage: ICoveragesAmount) => ({
      item: {
        text: 1,
        key: 'coverage item',
        idLink: 'item'
      },
      description: {
        text: coverage.DESCRIPTION,
        key: 'coverage description',
        idLink: 'description'
      },
      capital: {
        text: 'INCLUIDO',
        key: '',
        idLink: 'capital'
      },
      netPremium: {
        text: coverage.PRIMANETA,
        key: 'number-align-right',
        idLink: 'netPremium'
      },
      totalPremium: {
        text: coverage.PRIMATOTAL,
        key: 'number-align-right',
        idLink: 'totalPremium'
      },
      taxes: {
        text: coverage.TOTAL_IMPUESTOS,
        key: 'number-align-right',
        idLink: 'taxes'
      },
      required: coveragesParam.find((cov: any) => cov.code === coverage.CODE) && coveragesParam.find((cov: any) => cov.code === coverage.CODE).required,
    }))

    rows.map((ele: any, index: number) => ele.item.text = index + 1)

    return rows;
  }

  getEmptyTableRows = (coverageAmount: any, coveragesParam: any) => {
    const { coverages } = this.props;
    let order: any = []


    let filter = coverages.sort((a: any, b: any) => {
      if (a.required) return (b.required && a.code > b.code) ? 1 : -1;
      else if (b.required) return 1
      else if (a.code < b.code) return -1;
      else return 1;

    })
    filter.map((x: any) => {
      if (order.indexOf(x.code) < 0)
        order.push(x.code)
    })
    const rows = coveragesParam.map((coverage: any) => ({
      item: {
        text: 1,
        key: 'coverage item',
        idLink: 'item'
      },
      description: {
        text: coverage.description,
        key: 'coverage description',
        idLink: 'description'
      },
      capital: {
        text: 'INCLUIDO',
        key: '',
        idLink: 'capital'
      },
      netPremium: {
        text: '-',
        key: 'number-align-right',
        idLink: 'netPremium'
      },
      totalPremium: {
        text: '-',
        key: 'number-align-right',
        idLink: 'totalPremium'
      },
      taxes: {
        text: '-',
        key: 'number-align-right',
        idLink: 'taxes'
      },
      required: coveragesParam.find((cov: any) => cov.code === coverage.CODE) && coveragesParam.find((cov: any) => cov.code === coverage.CODE).required,
    }))

    rows.map((ele: any, index: number) => ele.item.text = index + 1)

    return rows;
  }

  render() {

    const {
      onClick,
      coverages,
      age,
      id,
      birthDate,
      coverageAmount,
      result,
      index
    } = this.props

    return (
      <DentalInsuredContainer>
        <div className="tab" key={id}>
          {
            (result === undefined || result === false) ? (<span className="delete" onClick={() => onClick()}></span>) : ''
          }
          <input
            type="checkbox"
            checked={this.state.checked}
            onChange={() => this.handleOnCheck()} />
          <label className="tab-label">
            <span className="colSin-20">{`Asegurado ${id}`}</span>
            <span>{birthDate}</span>
            <span>{age} años</span>
          </label>

          {coverageAmount && coverageAmount.length > 0 && coverageAmount.find((cov: any) => cov.NUMERO_DE_RIESGO === index) !== undefined ?
            <div className="tab-content">
              <div className="result-simulation-item-content">
                <div className="result-simulation-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={insuredTableColumns}
                    rows={this.getInsuredTableRows(coverageAmount, coverages)}
                    keysRow={['item', 'description', 'capital', 'netPremium', 'totalPremium', 'taxes']}
                  />
                </div>
              </div>
            </div> :
            <div className="tab-content">
              <div className="result-simulation-item-content">
                <div className="result-simulation-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={insuredTableColumns}
                    rows={this.getEmptyTableRows(coverageAmount, coverages)}
                    keysRow={['item', 'description', 'capital', 'netPremium', 'totalPremium', 'taxes']}
                  />
                </div>
              </div>
            </div>
          }
        </div>

      </DentalInsuredContainer>
    )

  }
}

export default DentalInsuredResultItem;



