import React from "react";
import MultiSelectModalLayout from "./multi-select-modal-layout";

export default class MultiSelectModal extends React.Component<any, {}> {
  render() {
    const { className, onClickAccept, onCloseModal, mainTitle, active, checkConditions, enableAcceptButton, defaultValues } =
      this.props;

    return (
      <MultiSelectModalLayout
      enableAcceptButton={enableAcceptButton}
      checkConditions={checkConditions}
      active={active}
      mainTitle={mainTitle}
      className={className}
      onClickAccept={(e: Array<string>) => onClickAccept(e)}
      onCloseModal={() => onCloseModal()}
      defaultValues={defaultValues}
      />
    );
  }
}
