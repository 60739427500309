import React from "react";
import MainTableScreen from "./main-table-screen";

class MainTable extends React.Component<any, {}> {
  render() {
    const { rows, columns, keysRow, rowLink, rowKeyLink, className, onOrder, defaultOrder, justOrderOneColumn, keysAlignRight, mediator } = this.props;
    return <MainTableScreen
      className={className}
      rowLink={rowLink}
      rowKeyLink={rowKeyLink}
      rows={rows}
      columns={columns}
      keysRow={keysRow}
      onOrder={onOrder}
      defaultOrder={defaultOrder}
      justOrderOneColumn={justOrderOneColumn}
      keysAlignRight={keysAlignRight}
      mediator={mediator}
    />;
  }
}

export default MainTable;
