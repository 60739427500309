export const STATE = {
    INFORMADO_SENDA: 'IS',

}
export const POST_DATA_PROTECTION = {
    YES: 'S',
    NO: 'N',
    EMAIL: 'EMAIL',
    GDPR: 'GDPR',

}