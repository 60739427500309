import React from "react";
import MainModalCampaignsLayout from "./main-modal-campaigns-layout";

export default class MainModalCampaigns extends React.Component<any, {}> {
  render() {
    const {
      img,
      className,
      onClickAccept,
      mainTitle,
      mainText,
      active,
      buttonText,
      close,
      centered,
      table,
      buttonClassName,
      inputCount
    } = this.props;

    return (
      <MainModalCampaignsLayout
        className={className}
        img={img}
        onClickAccept={() => onClickAccept()}
        mainTitle={mainTitle}
        mainText={mainText}
        buttonText={buttonText}
        active={active}
        close={close}
        centered={centered}
        table={table}
        buttonClassName={buttonClassName}
        inputCount={inputCount}
      />
    );
  }
}
