import React, { FC, useState } from "react";
import PhoneBox from "../../../../../components/phone-box/phone-box";
import { UseFormFunctionsProps } from "../../hooks/hooks.types";
import InputBox from "../../../../../components/input-box/input-box";
import { deepGet } from "../../../../../utils/utility";
import { HealthContactFormContainer } from "../../../../hirings/new-hiring/health/forms/contact-item/contact-form-item-style";
import { validateEmailByHocelot } from "../../new-insured/utils";

const ContactForm: FC<UseFormFunctionsProps> = ({
  values,
  errors,
  handleChange,
  // estas tres de abajo son si no quieres que el componente valide por si mismo
  customError,  // el código de error
  disableValidation, // indica que vas a desactivar
  setCustomError,   // función para setear el código de error
}) => {
  const [emailError, setEmailError] = useState("");

  const validateEmail = async (value: string) => {
    setEmailError(await validateEmailByHocelot(value));
  };

  return (
    <HealthContactFormContainer>
      <div className="content-wrapper">
        <div className="personal-data-wrapper margin-top-20" id="data-contact">
          <div className="data-title topMargin20">
            <h5>DATOS CONTACTO</h5>
          </div>
          <div className="colSin-100">
            <div className="colSin-50 rightPadding20 topMargin20 bottomMargin20">
              <div className="inputBox">
                <PhoneBox
                  errorCode={deepGet(errors, "contact.mobilePhone", "")}
                  onChange={(value: string) =>
                    handleChange("mobilePhone", value, "contact")
                  }
                  labelText=""
                  required={true}
                  initialValue={values.contact.mobilePhone}
                  disabled={false}
                />
                <label>teléfono móvil</label>
              </div>
            </div>
            <div className="colSin-50 rightPadding20 topMargin20 bottomMargin20">
              <div className="inputBox">
                <InputBox
                  errorCode={""}
                  onChange={(value: string) =>
                    handleChange("landlinePhone", value, "contact")
                  }
                  placeholder="TELÉFONO FIJO"
                  type={"number"}
                  value={values.contact.landlinePhone}
                  maxLength={12}
                  disabled={false}
                />
              </div>
            </div>

            <div className="colSin-100 rightPadding20 bottomMargin20">
              <div className="inputBox">
                <InputBox
                  errorCode={
                    disableValidation
                      ? customError
                      : emailError || deepGet(errors, "contact.email", "")
                  }
                  onChange={(value: string) => {
                    setCustomError && setCustomError('')
                    handleChange("email", value, "contact");
                  }}
                  onBlur={(value: string) =>
                    !disableValidation && validateEmail(value)
                  }
                  placeholder="CORREO ELECTRÓNICO"
                  required={true}
                  type={"email"}
                  value={values.contact.email}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HealthContactFormContainer>
  );
};

export default ContactForm;
