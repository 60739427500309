import React, { FC } from "react";
import { PersonalInformationContainer } from "../../../../hirings/new-hiring/health/forms/personal-information/personal-information-style";
import InputBox from "../../../../../components/input-box/input-box";
import SelectBox from "../../../../../components/select-box/select-box";
import DOC_TYPES from "../../../../../constants/doc_types";
import { PersonalBasicDataProps } from "../personal-basic-data/personal-basic-data.types";
import CalendarBox from "../../../../../components/calendar-box/calendar-box";
import moment from "moment";
import DicotomicButton from "../../../../../components/dicotomic-button";
import SEX from "../../../../../constants/sex.json";
import NationalitySelect from "../../../../../components/nationality-select/nationality-select";
import CheckboxWrapper from "../checkbox-wrapper/checkbox-wrapper";
import PetForm from "../pet-form/pet-form";

const VariableData: FC<PersonalBasicDataProps> = ({ formData, step, typeForm } ) => {
  const { values, handleChange, errors } = formData;

  return (
    <PersonalInformationContainer>
      {values.type === 'MASCOTA' ? ( <PetForm typeForm={typeForm} formData={formData} step={step} /> ) 
            : (
                <div className="personal-data-fisica">
                  {/* Nombre */}
                  <CheckboxWrapper
                    label={"Modificar Nombre y Apellidos de documento"}
                    isChecked={values.check.name}
                    step="check"
                    onCheckboxChange={handleChange}
                    name="name"
                  >
                    <div className="personal-data-row">
                      <div className="personal-data-item col-50">
                        <div className="inputBox">
                          <InputBox
                            placeholder="Nombre"
                            required={values.check.name}
                            errorCode={errors.newName}
                            disabled={!values.check.name}
                            value={values.newName}
                            onChange={(value: string) => handleChange("newName", value)}
                            type={"letters"}
                          />
                        </div>
                      </div>
                      <div className="personal-data-item col-50">
                        <div className="inputBox">
                          <InputBox
                            placeholder="Apellido 1"
                            required={values.check.name}
                            errorCode={errors.newLastname1}
                            disabled={!values.check.name}
                            value={values.newLastname1}
                            onChange={(value: string) =>
                              handleChange("newLastname1", value)
                            }
                            type={"letters"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="personal-data-row">
                      <div className="personal-data-item col-50">
                        <div className="inputBox">
                          <InputBox
                            placeholder="Apellido 2"
                            required={false}
                            disabled={!values.check.name}
                            value={values.newLastname2}
                            onChange={(value: string) =>
                              handleChange("newLastname2", value)
                            }
                            type={"letters"}
                          />
                        </div>
                      </div>
                      <div className="personal-data-item col-50"></div>
                    </div>
                  </CheckboxWrapper>
                  {/* Documento */}
                  <CheckboxWrapper
                    label={"Modificar Número de documento"}
                    isChecked={values.check.doc}
                    step="check"
                    onCheckboxChange={handleChange}
                    name="doc"
                  >
                    <div className="personal-data-row">
                      <div className="personal-data-item col-50">
                        <div className="inputBox">
                          <InputBox
                            placeholder="Nº de documento"
                            required={true}
                            errorCode={""}
                            maxLength={9}
                            value={values.docNumber}
                            disabled={true}
                            onChange={() => {}}
                            type={"NIF"}
                          />
                        </div>
                      </div>
                      <div className="personal-data-item col-50"></div>
                    </div>
                    <div className="personal-data-row">
                      <div className="personal-data-item col-50">
                        <div className="selectBox">
                          <SelectBox
                            placeholder="Documento de identidad"
                            required={values.check.doc}
                            errorCode={errors.newDocType}
                            optionsText={DOC_TYPES.taker}
                            optionKey={"value"}
                            optionValue={"label"}
                            initialValue={values.newDocType}
                            defaultValue={values.newDocType}
                            disabled={!values.check.doc}
                            onChange={(value: string) =>
                              handleChange("newDocType", value)
                            }
                          />
                          <label>{"Documento de identidad"}</label>
                        </div>
                      </div>
                      <div className="personal-data-item col-50">
                        <div className="inputBox">
                          <InputBox
                            placeholder="Nº de documento"
                            required={values.check.doc}
                            errorCode={errors.newDocNumber}
                            maxLength={9}
                            value={values.newDocNumber}
                            disabled={!values.check.doc}
                            onChange={(value: string) =>
                              handleChange("newDocNumber", value.toUpperCase())
                            }
                            type={
                              values.newDocType === "NIF"
                                ? "NIF"
                                : values.newDocType === "NIE"
                                ? "NIE"
                                : values.newDocType === "PAS"
                                ? "PAS"
                                : "NIF"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </CheckboxWrapper>
                  {/* Fecha Nacimiento */}
                  <CheckboxWrapper
                    label={
                      "Modificar Fecha de nacimiento (impacto en prima si se trata de asegurado)"
                    }
                    isChecked={values.check.birthDate}
                    step="check"
                    onCheckboxChange={handleChange}
                    name="birthDate"
                  >
                    <div className="personal-data-row">
                      <div className="personal-data-item col-50">
                        <div className="inputBox">
                          <CalendarBox
                            initialValue={values.birthDate}
                            placeholder="Fecha de nacimiento actual"
                            disabled={true}
                            onChange={() => {}}
                          />
                        </div>
                      </div>
                      <div className="personal-data-item col-50">
                        <div className="inputBox">
                          <CalendarBox
                            initialValue={values.newBirthDate}
                            placeholder="Nueva fecha de nacimiento"
                            forbidFutureDates
                            minAge={0}
                            maxDate={moment().toISOString()}
                            required={values.check.birthDate}
                            disabled={!values.check.birthDate}
                            errorCode={errors.newBirthDate}
                            onChange={(value: string) =>
                              handleChange("newBirthDate", value)
                            }
                            health={true}
                          />
                        </div>
                      </div>
                    </div>
                  </CheckboxWrapper>
                  {/* Género */}
                  <CheckboxWrapper
                    label={"Modificar Género"}
                    isChecked={values.check.gender}
                    step="check"
                    onCheckboxChange={handleChange}
                    name="gender"
                  >
                    <div className="personal-data-row">
                      <div className="personal-data-item col-50">
                        <div className="personal-data-item col-25">
                          <DicotomicButton
                            labelText="Sexo"
                            options={SEX}
                            withTab={true}
                            disabled={true}
                            initialValue={values.gender}
                            onChange={() => {}}
                          />
                        </div>
                      </div>
                      <div className="personal-data-item col-50">
                        <div className="personal-data-item col-25">
                          <DicotomicButton
                            labelText="Sexo"
                            options={SEX}
                            withTab={true}
                            errorCode={errors.newGender}
                            required={values.check.gender}
                            disabled={!values.check.gender}
                            initialValue={values.newGender}
                            onChange={(value: string) =>
                              handleChange("newGender", value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </CheckboxWrapper>
                  {/* Nacionalidad */}
                  <CheckboxWrapper
                    label={"Modificar Nacionalidad"}
                    isChecked={values.check.nationality}
                    step="check"
                    onCheckboxChange={handleChange}
                    name="nationality"
                  >
                    <div className="personal-data-row">
                      <div className="personal-data-item col-50">
                        <NationalitySelect
                          disabled={!values.check.nationality}
                          required={values.check.nationality}
                          nationalityValue={values.newNationality}
                          nationalityError={errors.newNationality}
                          onSelect={(value) => handleChange("newNationality", value)}
                        />
                      </div>
                      <div className="personal-data-item col-50"></div>
                    </div>
                  </CheckboxWrapper>
                </div>
              )}
    </PersonalInformationContainer>
  );
};

export default VariableData;
