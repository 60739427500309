export enum Steps {
    DatosTomador = 1,
    DatosMascotas = 2,
    DatosBeneficiario = 3,
    ProteccionDatos = 4,
  }
  
  export class PetHireNavHelper {
    static getStepFromNavigationRoute(path: string): Steps {
      if (path.indexOf("/contratacion/nueva/mascotas/tomador") !== -1) {
        return Steps.DatosTomador;
      }
      
      if (path.indexOf("/contratacion/nueva/mascotas/asegurado-beneficiario") !== -1) {
        return Steps.DatosBeneficiario;
      }

      if (path.indexOf("/contratacion/nueva/mascotas/asegurado") !== -1) {
        return Steps.DatosMascotas;
      }
      
      if (path.indexOf("/contratacion/nueva/mascotas/proteccion-datos") !== -1) {
        return Steps.ProteccionDatos;
      }
      
      return Steps.DatosTomador;
    }
  }