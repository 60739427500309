import React from "react";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../assets";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import GuideLine from "../../../../components/guide-line";
import MainButton from "../../../../components/main-button";
import { DentalInsuredDataContainer } from "./dental-insured-data-style"
import { IHealthCoverage, STORAGE_KEY } from "../../../../constants/health";
import { calcAge } from "../../../../utils/utility";
import { getDayMonthYear } from "../../../../utils/time";
import { ErrorModalContainer } from "../../../../components/error-modal/error-modal-style";
import DentalInsuredItem from "../../../../components/dental-insured-item/dental-insured-item";
import SelectBox from "../../../../components/select-box/select-box";
import { PARENTESCO } from "../../../../constants/dental-constants";
import DicotomicButton from "../../../../components/dicotomic-button";
import YesNo from "../../../../constants/typeYesNo.json"
import { CoveragesResponseDto } from "../../../../services/HealthService-dto";
import HealthService from "../../../../services/HealthService";
import { getPrices } from "../dental-simulation-helper";
import { campaign } from "../../../hirings/new-hiring/health/health-price/health-price-dto/campaign-dto";
import CampaignsServices from "../../../../services/CampaignsServices";
import { withPolicy } from "../../../../context/mixin/with-policy";
import { withPayTypes } from "../../../../context/mixin/with-pay-types";
import { DEFAULT_PAY_METHOD } from "../../../../constants/payTypes";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import { capitalizeFirstLetter } from "../../../../utils";

interface DentalInsuredProps extends RouteComponentProps {
  setLoading: Function,
  data: any;
  policy: any;
  allPayTypes: any;
  coverages: any;
}

export interface DentalInsuredSimulationInterface {
  birthDate: string,
  gender?: string,
  checked: boolean,
  parentesco?: string,
  hasAnotherInsurance?: boolean,
  coverages: IHealthCoverage[],
}

interface DentalInsuredState {
  insuredData: DentalInsuredSimulationInterface[],
  birthDate: string,
  valid: {
    birthDate: boolean,
    parentesco: {
      validated: boolean,
      errorCode: string
    }
  },
  mediatorData: {
    name: string;
    startDate: string;
  },
  coverages: IHealthCoverage[],
  showLeftSearchBoxModal: boolean,
  indexInsured: number,
  modalErrorText: string,
  parentesco: string,
  hasAnotherInsurance: boolean,
  modalErrorShow: boolean,
}

class DentalInsuredDataLayout extends React.Component<DentalInsuredProps, DentalInsuredState> {

  constructor(props: DentalInsuredProps) {
    super(props);
    this.state = {
      birthDate: '',
      insuredData: this.props.data.insuredData ? this.props.data.insuredData : [],
      mediatorData: this.props.data.mediatorData,
      valid: {
        birthDate: false,
        parentesco: {
          validated: false,
          errorCode: ''
        },
      },
      showLeftSearchBoxModal: false,
      indexInsured: 0,
      modalErrorText: "",
      parentesco: "",
      modalErrorShow: false,
      coverages: [],
      hasAnotherInsurance: false
    }
  }

  componentDidMount = async () => {
    const { coverages, policy } = this.props
    if(Object.keys(coverages).length>0 || Object.keys(policy.product).length>0){
      this.getCoverages()
    }
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  };
  
  componentDidUpdate(prevProps: Readonly<DentalInsuredProps>, prevState: Readonly<DentalInsuredState>, snapshot?: any): void {
    const { coverages, policy } = this.props

    if((Object.keys(prevProps.coverages).length===0 && Object.keys(coverages).length!==0) ||
      (Object.keys(prevProps.policy.product).length===0 && Object.keys(policy.product).length!==0)){
      this.getCoverages()
    }
  }


  async getCoverages() {
    const { setLoading, policy, coverages} = this.props;
    const productType: string = policy.product.products_coverageName;
    let coveragesAux: IHealthCoverage[] = [] 

    if (coverages && coverages[productType]) {
      coveragesAux = this.formatCoverages(coverages[productType])
    } else {
      if(productType){
        const resCoverages = await HealthService.getCoveragesFromDB(setLoading, true, productType)
        .catch((error) => {
          this.setState({
            modalErrorShow: true,
            modalErrorText: `Se ha producido un error al obtener las coberturas. Vuelva a intentarlo más tarde.`
          });
          throw error.statusText;
        });
        coveragesAux = this.formatCoverages(resCoverages)
      }
    }
    this.setState({ coverages: coveragesAux })
  }

  formatCoverages(unformattedCoverages: CoveragesResponseDto[]): IHealthCoverage[] {
    if (unformattedCoverages && unformattedCoverages && unformattedCoverages.length > 0) {
      let formattedCoverages: IHealthCoverage[] = []
      unformattedCoverages.forEach((coverage: any) => {
        let desc = "";
        if (coverage.name){
          desc = capitalizeFirstLetter(coverage.name.toLowerCase())
        }
        
        formattedCoverages.push({
          capMaxCober: coverage.capMaxCober,
          capMinCober: coverage.capMinCober,
          checked: coverage.required,
          code: coverage.code,
          description: desc,
          edadMax: coverage.edadMax,
          edadMin: coverage.edadMin,
          required: coverage.required,
          capital: coverage.capital,
          capitales: coverage.capitales
        })
      })
      return formattedCoverages;
    }
    return []
  }

  getCampaigns = async () => {
    const { setLoading, policy, data } = this.props;
    let allCampaigns: campaign[] = [];
    if (policy && policy.product && policy.product.products_colectivo && policy.product.products_combinacion_comercial) {
      allCampaigns = await CampaignsServices.getCampaigns({
        loader: true,
        setLoading,
        colective: policy.product.products_colectivo,
        combinacion_comercial: policy.product.products_combinacion_comercial,
        fecha_inicio: this.checkPolicyStartDate(data)
      })
        .catch((error) => {
          this.setState({
            modalErrorShow: true,
            modalErrorText: `Se ha producido un error al obtener las campañas. Vuelva a intentarlo más tarde.`
          });
          throw error.statusText;
        });
    }

    return allCampaigns;
  }

  checkPolicyStartDate (data: any): string {
    if (data && data.mediatorData && data.mediatorData.startDate) {
      return data.mediatorData.startDate;
    }
    return '';
  }

  async getPrices() {
    const { setLoading, data, allPayTypes, policy } = this.props;
    let selectedPrice = {
      paymentTime: this.props.data.priceData ? this.props.data.priceData.price.paymentTime : DEFAULT_PAY_METHOD.description,
      payType: this.props.data.priceData ? this.props.data.priceData.price.payType : DEFAULT_PAY_METHOD.code,
      price: '',
      discount: '',
      taxes: '',
      total: ''
    }

    let campaigns = await this.getCampaigns();
    let selectedCampaigns = campaigns.filter(campaign => campaign.isRequired)

    if (data && data.priceData && data.priceData.campaigns && data.priceData.campaigns.length > 0) {
      data.priceData.campaigns.forEach((campaign: any) => {
        const foundCampaign = campaigns.find((bbddCampaign) => bbddCampaign.code === campaign.code)
        if (foundCampaign && !foundCampaign.isRequired) {
          selectedCampaigns.push(foundCampaign)
        }
      })
    }

    const [selectablePrices, resPrices] = await getPrices(setLoading, data, selectedCampaigns, selectedPrice, allPayTypes, policy.product)
      .catch((error) => {
        this.setState({
          modalErrorShow: true,
          modalErrorText: `Error al simular la póliza: ${(error && error.data && error.data.message) ?
            error.data.message : 'Vuelva a intentarlo más tarde.'}`
        })
        throw error;
      })

    if (resPrices && resPrices && resPrices.RESPUESTA && resPrices.RESPUESTA.IMPORTESFORPAGO) {
      const prices = resPrices.RESPUESTA.IMPORTESFORPAGO;

      let selected = prices.find((price: any) => price.PERIODO_COBRO === selectedPrice.payType);

      selectedPrice = {
        paymentTime: selectedPrice.paymentTime,
        payType: selected ? selected.PERIODO_COBRO : '',
        price: selected ? selected.PRIMANETA : '',
        discount: selected ? selected.DESCUENTOS : '',
        taxes: selected ? selected.TOTAL_IMPUESTOS : '',
        total: selected ? selected.PRIMATOTAL : ''
      }
      data.resPrices = resPrices.RESPUESTA;
      data.priceData = {
        price: selectedPrice,
        campaigns: selectedCampaigns
      }

      data.selectablePrices = selectablePrices;
      data.selectableCampaigns = selectedCampaigns;
    }
  }

  toValidate = async () => {
    let { insuredData, coverages } = this.state;
    const { history, data } = this.props;
    data.insuredData = insuredData;
    data.coverages = coverages;

    await this.getPrices().then(() => history.push("/simulacion/nueva/dental/precios"));
  }

  handleBirthData = (value: string) => {
    const { valid } = this.state
    valid.birthDate = (value.length === 10)
    this.setState({ birthDate: value, valid: valid })
  }

  handleAddInsured = () => {

    const { birthDate, valid, parentesco, coverages, hasAnotherInsurance } = this.state


    valid.parentesco.validated = false;

    let { insuredData } = this.state

    insuredData.forEach((insured: DentalInsuredSimulationInterface) => {
      insured.checked = false;
    });

    const newInsuredData = JSON.parse(JSON.stringify(insuredData))
    if (newInsuredData.length > 0) {
      if (this.checkParentesco(newInsuredData) === true && newInsuredData.length <= 9) {
        valid.birthDate = false;
        newInsuredData.push({
          birthDate,
          checked: true,
          profession: { code: '', value: '' },
          parentesco: parentesco,
          coverages: coverages,
          hasAnotherInsurance: hasAnotherInsurance
        });
        this.setState({
          birthDate: '',
          insuredData: newInsuredData,
          parentesco: '',
          hasAnotherInsurance: false
        })
      } else if (!this.checkParentesco(newInsuredData)) {
        this.setState({
          modalErrorText: `No se pueden añadir más asegurados con parentesco: ${this.checkParentescoName(this.state.parentesco)}.`,
          modalErrorShow: true,
        })
      } else if (newInsuredData.length >= 9) {
        this.setState({
          modalErrorText: `No se pueden añadir más de 10 asegurados`,
          modalErrorShow: true,
        })
      }
    } else {
      valid.birthDate = false;
      newInsuredData.push({
        birthDate,
        checked: true,
        parentesco: parentesco,
        coverages: coverages,
        hasAnotherInsurance: hasAnotherInsurance
      });
      this.setState({
        birthDate: '',
        insuredData: newInsuredData,
        parentesco: '',
        hasAnotherInsurance: false
      })
    }

  }

  checkParentesco(insuredData: any[],): boolean {
    let group_to_values = insuredData.reduce((obj, item) => {
      let found = obj.findIndex((existe: any) => existe.value == item.parentesco);
      if (found < 0) {
        obj.push({ value: item.parentesco, cantidad: 1 });
      } else {
        obj[found].cantidad++;
      }
      return obj;
    }, []);

    let checked = true;
    const find = group_to_values.find((parent: any) => parent.value === this.state.parentesco)

    if (find) {
      const res = PARENTESCO.find((itm: any) => itm.value === find.value)

      if (find.cantidad >= res.max && this.state.parentesco === find.value) {
        checked = false
      } else {
        checked = true
      }
    }
    return checked
  }

  handleRemoveInsured = (id: number) => {
    let insuredData = this.state.insuredData;
    insuredData.splice(id, 1);
    this.setState({ insuredData });
  }

  onChangeOption = (insured: number, age: number, coverage: IHealthCoverage, value: boolean) => {

    if (coverage.required) return false
    //const showLeftSearchBoxModal = (reqProfession && value === true)

    const { insuredData } = this.state
    const newInsuredData = JSON.parse(JSON.stringify(insuredData))
    const index = newInsuredData[insured].coverages.map(function (e: any) {
      return e.code
    }).indexOf(coverage.code)
    newInsuredData[insured].coverages[index].checked = value

    this.setState({ insuredData: newInsuredData, indexInsured: insured })
  }

  handleParentescoChange(value: string) {
    const { valid } = this.state
    valid.parentesco.validated = (value !== "")

    this.setState({ parentesco: value, valid: valid })
  }

  handleValueChange(value: boolean) {

    this.setState({ hasAnotherInsurance: value })

  }

  handleInsuredCheck(insuredIndex: number) {
    let { insuredData } = this.state;
    insuredData.forEach((insured: DentalInsuredSimulationInterface, index) => {
      if (index === insuredIndex) {
        insured.checked = !insured.checked;
      }
    });

    this.setState({ insuredData });
  }
  checkParentescoName(value: string): string {

    let name = PARENTESCO.find(n => n.value === value)

    return name.label
  }

  render() {

    const {
      birthDate,
      insuredData,
      parentesco,
      valid,
      coverages,
      hasAnotherInsurance,
    } = this.state
    return (
      <DentalInsuredDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <h2>{'DATOS DEL SEGURO'}</h2>
          </div>
          <div className="data-flow-status-guide">
            <GuideLine
              steps={[0, 0, 0, 0]}
              currentStep={2}
            />
          </div>
        </div>
        <div className="data-wrapper">
          <div className="content-wrapper bottomMargin40">
            <div className="personal-data-wrapper">
              <div className="data-title">
                <h5>DATOS DE LOS ASEGURADOS</h5>
              </div>
            </div>
            <div className="data-row colSin-100">
              <div className="colSin-33 rightPadding20 topMargin12">
                <div className="inputBox">
                  <CalendarBox
                    initialValue={birthDate}
                    placeholder="Fecha de nacimiento"
                    forbidFutureDates
                    maxDate={moment().toISOString()}
                    required
                    // disabled={useTakerData}
                    // errorCode={contact.birthdate.errorCode}
                    onChange={(value: string) => {
                      this.handleBirthData(value);
                    }}
                    health={true}
                  />
                </div>
              </div>
              <div className="colSin-33 rightPadding20 topMargin10">
                <div className="selectBox">
                  <SelectBox
                    isMediator={true}
                    optionsText={PARENTESCO}
                    optionKey={"value"}
                    optionValue={"label"}
                    defaultValue={""}
                    onChange={(value: string) =>
                      this.handleParentescoChange(value)
                    }
                    propValue={parentesco}
                    required
                  />
                  <label>Parentesco</label>
                </div>
              </div>
              <div className="colSin-33 rightPadding20 topMargin10">
                <div className="inputBox">
                  <DicotomicButton
                    required={true}
                    options={YesNo}
                    withTab={true}
                    initialValue={hasAnotherInsurance}
                    onChange={(value: boolean) => this.handleValueChange(value)}
                  />
                  <label>¿ES ASEGURADO DE ASISA SALUD?</label>
                </div>
              </div>
            </div>
            <div className="data-row colSin-100">
              <div className="colSin-75" />
              <div className="colSin-25 rightPadding20 topPadding15">
                <MainButton
                  disabled={!(valid.birthDate && valid.parentesco.validated)}
                  text="Añadir Asegurado"
                  onClick={() => this.handleAddInsured()}
                />
              </div>
            </div>
          </div>
          <div className="content-wrapper bottomMargin20">
            <div className="tabs">
              {insuredData.map((insurance, index) => (
                <DentalInsuredItem
                  age={calcAge(getDayMonthYear(insurance.birthDate).toString())}
                  birthDate={insurance.birthDate}
                  parentesco={this.checkParentescoName(insurance.parentesco || "")}
                  hasAnotherInsurance={insurance.hasAnotherInsurance}
                  checked={insurance.checked}
                  id={index + 1}
                  index={index}
                  key={index}
                  onChange={
                    (insured: number, age: number = calcAge(getDayMonthYear(insurance.birthDate).toString()), coverage: IHealthCoverage, value: boolean) => this.onChangeOption(insured, age, coverage, value)
                  }
                  onCheckInsured={(insured: number) => this.handleInsuredCheck(insured)}
                  onClick={() => this.handleRemoveInsured(index)}
                  coverages={coverages}
                />

              ))}
            </div>
          </div>
        </div>



        <div className="colSin-100 topMargin20">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push("/simulacion/nueva/dental/mediador")}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                text="Siguiente"
                disabled={insuredData.length <= 0}
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>
        <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={() => this.setState({ modalErrorShow: false })} />
            </div>

          </div>
        </ErrorModalContainer>
      </DentalInsuredDataContainer>
    );
  }
}

export default withCoverages(withPayTypes(withPolicy(withRouter(DentalInsuredDataLayout))));