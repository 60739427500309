import React, { Component } from "react";
import CheckboxItemLayout from "../../../../../../components/checkbox-item/checkbox-item-layout";
import InputBox from "../../../../../../components/input-box/input-box";
import SearchBox from "../../../../../../components/search-box-mediadores/search-box";
import { ValueFormHealthAddress } from "../../../../../../constants/health";
import AddressServices from "../../../../../../services/AddressServices";
import { AddressDataDto, DefaultAddressDataDto, AddressDataItem, DefaultValueFormHealthAddress, DefaultAddressDataTravelWelcomeDto } from "./address-form-item-dto";
import { TravelAddressFormContainer } from "./address-form-item-style";
import InfoModal from "../../../../../../components/info-modal";
import { images } from "../../../../../../assets";
import ConstantsServices from "../../../../../../services/ConstantsServices";
import { withPolicy } from "../../../../../../context/mixin/with-policy";
import { PRODUCT_TYPE } from "../../../../../../constants/policyType";
import DicotomicCheckbox from "../../../../../../components/dicotomic-checkbox/dicotomic-checkbox";

export interface AddressData {
    address: AddressDataItem;
    wayType: string;
    number: string;
    portal?: string;
    stairs?: string;
    floor?: string;
    door?: string;
    postalCode: string;
    town: AddressDataItem;
    province: AddressDataItem;
    sameAsTaker: boolean;
    country?: AddressDataItem;
    normalizaDireciones: "N" | "S";
}

interface HealthAddressFormProps {
    validate: {
        validateRequest: boolean;
        isValidated: boolean;
    };
    physicalPerson: boolean;
    onValidated: (person: AddressData) => void;
    onCleanForm?: boolean;
    insured?: boolean;
    onCheckTakerData?: any;
    useTakerData?: boolean;
    setLoading: Function;
    taker?: AddressData;
    insureds?: AddressData[];
    insuredIndex: number;
    isReadOnly?: boolean;
    onAnyChange: Function;
    usePersonalTakerData?: boolean;
    isPersonalCheckChanging?: boolean;
    policy: any;
}

interface HealthAddressFormState {
    addressData: AddressDataDto;
    countries: any[];
    countriesFiltered: any[];
    provinces: any[];
    cities: any[];
    streets: any[];
    postalCodes: any[];
    physicalPerson: boolean;
    previousData: AddressDataDto;
    showModal: boolean;
    infoModalText: string;
}

class TravelAddressFormItem extends Component<HealthAddressFormProps, HealthAddressFormState> {

    private _searchWaiter: any = null;

    constructor(props: HealthAddressFormProps) {
        super(props);
        this.state = {
            addressData: DefaultAddressDataTravelWelcomeDto(),
            countries: [],
            countriesFiltered: [],
            provinces: [],
            cities: [],
            streets: [],
            postalCodes: [],
            physicalPerson: this.props.physicalPerson,
            previousData: DefaultAddressDataTravelWelcomeDto(),
            showModal: false,
            infoModalText: ''
        }
    }

    componentWillReceiveProps(nextProps: HealthAddressFormProps) {
        let { validate, onCleanForm, useTakerData, taker } = this.props;
        const { addressData } = this.state;
        const isWelcomeProduct = nextProps.policy.product.products_internalName === PRODUCT_TYPE.TRAVEL_WELCOME

        if (Object.keys(this.props.policy.product).length === 0 && Object.keys(nextProps.policy.product).length !== 0 && !taker) {
            if(!isWelcomeProduct){
                this.setState({addressData: DefaultAddressDataDto() , previousData: DefaultAddressDataDto()})
            }
        }

        if(isWelcomeProduct && (!nextProps.taker || (nextProps.insured && nextProps.insuredIndex === -1 && !nextProps.useTakerData))){
            if(useTakerData === true && !nextProps.useTakerData){
                this.getPreviousData();
            } else {
                if(!addressData.country.value && !addressData.province.value && !addressData.address.value && !addressData.number.value && !addressData.postalCode.value &&
                    !addressData.portalValue.value && !addressData.stairsValue.value && !addressData.floorValue.value && !addressData.doorValue.value){
                    this.setState({ addressData: DefaultAddressDataTravelWelcomeDto(), previousData: DefaultAddressDataTravelWelcomeDto() });
                }
            }
        }
        if (nextProps.validate.validateRequest) {
            validate.validateRequest = false;
            validate.isValidated = this.validateForm();
        }
        else if (nextProps.onCleanForm) {
            this.cleanForm();
            onCleanForm = false;
        }

        this.setState({ physicalPerson: nextProps.physicalPerson });
        if (nextProps.useTakerData && !nextProps.onCleanForm) {
            if (!this.state.addressData.sameAsTaker) {
                let newAddressData = this.state.addressData;
                newAddressData.sameAsTaker = !newAddressData.sameAsTaker;
                this.storePreviousData(true);
                this.completeFormTakerData();
                this.setState({ addressData: newAddressData });
            }
        }
        if (nextProps.isPersonalCheckChanging && !nextProps.useTakerData) {
            this.getPreviousData();
        }
        if (nextProps.insuredIndex !== undefined && nextProps.insuredIndex !== -1 && this.props.insuredIndex !== nextProps.insuredIndex) {
            this.completeInsuredData(nextProps.insuredIndex);
            this.storePreviousData(true);
            this.setPreviousDataDisabled(false);
        }
    }

    async componentDidMount() {
        let { countries } = this.state;
        let { policy } = this.props;
        const internalName = policy.product.products_internalName
        
        countries = await ConstantsServices.getCountries({
          setLoading: this.props.setLoading,
        });
  
        countries.map((country: any) => country.label = country.label.toUpperCase());

        if(Object.keys(policy.product).length !== 0){
            const isWelcomeProduct = internalName === PRODUCT_TYPE.TRAVEL_WELCOME
            if(!isWelcomeProduct){
                this.setState({addressData: DefaultAddressDataDto() , previousData: DefaultAddressDataDto()})
            }
        }

        if (!this.props.insured && this.props.taker !== undefined) {
            this.completeFormTakerData();
        }
        if (this.props.insured && this.props.taker !== undefined && this.state.addressData.sameAsTaker) {
            this.completeFormTakerData();
        }
        this.setState({ countries });
    }

    handleCheckTakerChange() {
        let { addressData } = this.state
        const { useTakerData, usePersonalTakerData } = this.props;
        if (!useTakerData) {
            if (!addressData.sameAsTaker) {
                this.storePreviousData();
                this.completeFormTakerData();
                this.props.onAnyChange()
                this.props.onCheckTakerData(true);
            }
        }
        else if (addressData.sameAsTaker && !usePersonalTakerData) {
            this.getPreviousData();
            this.props.onAnyChange()
            this.props.onCheckTakerData(false);
        }

    }

    storePreviousData(clear: boolean = false): void {
        const { addressData } = this.state;
        const previousData: AddressDataDto = JSON.parse(JSON.stringify(addressData));

        previousData.address.errorCode = "";
        previousData.number.errorCode = "";
        previousData.portalValue.errorCode = "";
        previousData.stairsValue.errorCode = "";
        previousData.floorValue.errorCode = "";
        previousData.doorValue.errorCode = "";
        previousData.postalCode.errorCode = "";
        previousData.town.errorCode = "";
        previousData.province.errorCode = "";
        previousData.country.errorCode = "";

        if(clear){
            previousData.address.value = "";
            previousData.number.value = "";
            previousData.portalValue.value = "";
            previousData.stairsValue.value = "";
            previousData.floorValue.value = "";
            previousData.doorValue.value = "";
            previousData.postalCode.value = "";
            previousData.town.value = "";
            previousData.province.value = "";
            previousData.country.value = "";
        }

        this.setState({ previousData })
    }

    setPreviousDataDisabled(disabled: boolean) {
        const previousData: AddressDataDto = JSON.parse(JSON.stringify(DefaultAddressDataDto()));
        previousData.address.disabled = disabled;
        previousData.number.disabled = disabled;
        previousData.portalValue.disabled = disabled;
        previousData.stairsValue.disabled = disabled;
        previousData.floorValue.disabled = disabled;
        previousData.doorValue.disabled = disabled;
        previousData.postalCode.disabled = disabled;
        previousData.town.disabled = disabled;
        previousData.province.disabled = disabled;
        previousData.country.disabled = disabled;

        this.setState({ previousData })
    }

    getPreviousData() {
        let { previousData, addressData } = this.state;

        addressData.address = previousData.address;
        addressData.wayType = previousData.wayType;
        addressData.postalCode = previousData.postalCode;
        addressData.town = previousData.town;
        addressData.province = previousData.province;
        addressData.provinceLabel = previousData.province.value;

        addressData.number = previousData.number;
        addressData.doorValue = previousData.doorValue;
        addressData.floorValue = previousData.floorValue;
        addressData.portalValue = previousData.portalValue;
        addressData.stairsValue = previousData.stairsValue;
        addressData.country = previousData.country;
        addressData.normalizaDireciones = previousData.normalizaDireciones;

        addressData.sameAsTaker = false;

        previousData = DefaultAddressDataDto();
        this.setState({ previousData, addressData });
    }

    completeInsuredData(index: number) {
        const { insureds } = this.props;

        let currentInsured = insureds ? insureds[index] : undefined;
        if (currentInsured) {
            if (currentInsured.sameAsTaker) {
                this.completeFormTakerData();
            }
            else {
                this.setAddressData(currentInsured, currentInsured.sameAsTaker);
            }
        }
        else {
            this.cleanForm();
        }
    }

    setAddressData(newData: AddressData, disabled: boolean) {
        let { addressData } = this.state;
        addressData.address = this.getValueFormHealthAddress(newData.address, disabled);
        addressData.wayType = newData.wayType;
        addressData.postalCode = this.getValueFormHealthAddress(newData.postalCode, disabled);
        addressData.town = this.getValueFormHealthAddress(newData.town, disabled);
        addressData.province = this.getValueFormHealthAddress(newData.province, disabled);
        addressData.number = this.getValueFormHealthAddress(newData.number, disabled);
        addressData.doorValue = this.getValueFormHealthAddress(newData.door, disabled);
        addressData.floorValue = this.getValueFormHealthAddress(newData.floor, disabled);
        addressData.portalValue = this.getValueFormHealthAddress(newData.portal, disabled);
        addressData.stairsValue = this.getValueFormHealthAddress(newData.stairs, disabled);
        addressData.country = this.getValueFormHealthAddress(newData.country, disabled);
        addressData.sameAsTaker = newData.sameAsTaker;
        addressData.normalizaDireciones = newData.normalizaDireciones;

        this.setState({ addressData });
    }

    getValueFormHealthAddress(value: string | AddressDataItem | undefined, disabled: boolean): ValueFormHealthAddress {
        if (!value || typeof value === 'string') {
            return {
                value: value ? value : '',
                label: '',
                errorCode: '',
                disabled: disabled,
                code: '',
            };
        } else {
            return {
                value: value.value,
                label: value.label,
                errorCode: '',
                disabled: disabled,
                code: value.code,
            };
        }

    }

    mapAddressDataItem = (item: ValueFormHealthAddress): AddressDataItem => {
        return {
            value: item.value,
            code: item.code,
            label: item.label
        }
    }

    completeFormTakerData() {
        const { taker, insured } = this.props;
        if (taker && Object.keys(taker).length > 0) {
            taker.sameAsTaker = true;
            this.setAddressData(taker, insured === true)
        }
    }

    handleChangeNormalizedAddress = (value: boolean) => {
        const { addressData } = this.state;
        const normalizaDireciones = value ? "N" : "S";
        addressData.normalizaDireciones = normalizaDireciones;
        this.props.onAnyChange();
        // Si de no normalizado pasa a normalizado
        // limpia los datos para coger los correctos normalizados
        if (normalizaDireciones === "S") {
            addressData.province = {
                ...addressData.province,
                value: "",
                label: "",
                code: "",
                errorCode: "",
            }
            addressData.town = {
                ...addressData.town,
                value: "",
                label: "",
                errorCode: "",
                disabled: true,
            }
            addressData.address = {
                ...addressData.address,
                value: "",
                label: "",
                errorCode: "",
                disabled: true,
            }
            addressData.postalCode = {
                ...addressData.postalCode,
                value: "",
                label: "",
                errorCode: "",
                disabled: true,
            }
        } else {
            addressData.province = {
                ...addressData.province,
                errorCode: "",
                disabled: false,
            }
            addressData.town = {
                ...addressData.town,
                errorCode: "",
                disabled: false,
            }
            addressData.address = {
                ...addressData.address,
                errorCode: "",
                disabled: false,
            }
            addressData.postalCode = {
                ...addressData.postalCode,
                errorCode: "",
                disabled: false,
            }
        }
        this.setState({ addressData });
    }

    async handleFormChange(value: any, input: string): Promise<void> {
        let { addressData } = this.state
        const { policy } = this.props;
        this.props.onAnyChange();
        const normalizaDireciones = addressData.normalizaDireciones === "S";
        const isWelcomeProduct = policy.product.products_internalName === PRODUCT_TYPE.TRAVEL_WELCOME
        
        if(!isWelcomeProduct && normalizaDireciones) {
            switch (input) {
                case "number":
                case "stairsValue":
                case "portalValue":
                case "doorValue":
                case "floorValue":
                    value = value.toUpperCase();
                    Object.keys(addressData).forEach((k: string) => {
                        if (k === input) {
                            if (addressData[k].value.length === 0) {
                                addressData[k].errorCode = '';
                            }
                            addressData[k].value = value;
                        }
                    })
                    this.setState({ addressData });
                    break;
    
                case "province":
                case "town":
                case "postalCode":
                    Object.keys(addressData).forEach((k: string) => {
                        if (k === input) {
                            if (addressData[k].value.length === 0) {
                                addressData[k].errorCode = '';
                            }
                            addressData[k].value = value.label;
                            addressData[k].code = value.value;
                        }
                    })
    
                    if (input === 'province') {
                        addressData.town.disabled = false;
                        addressData.address.disabled = true;
                        addressData.postalCode.disabled = true;
                        addressData.provinceLabel = value.label;
    
                    }
                    else if (input === 'town') {
                        addressData.address.disabled = false;
                        addressData.postalCode.disabled = true;
                    }
    
                    this.setState({ addressData });
                    break;
                case "address":
                    addressData.postalCode.disabled = false;
                    addressData.wayType = value.extra.street.code;
                    if (addressData.address.value.length === 0) {
                        addressData.address.errorCode = '';
                    }
                    addressData.address.value = value.extra.street.name;
                    addressData.address.label = value.label;
                    addressData.address.code = value.value;
                    break;
                case "country":
                    addressData.country.value = value.label;
                    addressData.country.code = value.value;
                    addressData.country.errorCode = '';
                    break;
            }
        } else {
            switch (input) {
                case "number":
                case "stairsValue":
                case "portalValue":
                case "doorValue":
                case "floorValue":
                case "town":
                case "province":
                case "postalCode":
                case "address":
                    value = value.toUpperCase();
                    Object.keys(addressData).forEach((k: string) => {
                        if (k === input) {
                            if (addressData[k].value.length === 0) {
                                addressData[k].errorCode = '';
                            }
                            addressData[k].value = value;
                        }
                    })
                    this.setState({ addressData });
                    if (input === 'postalCode' && value.length === 5) {
                        addressData.province.code = value.slice(0, 2);
                    }
                    break;
                case "country":
                    addressData.country.value = value.label;
                    addressData.country.code = value.value;
                    addressData.country.errorCode = '';
                    break;
            }
        }
        this.setState({ addressData })
        let formatedStreet = addressData.address.value; if (addressData.wayType) { formatedStreet = addressData.wayType + " " + formatedStreet }
        //Comprobación código INE 
        if (input !== 'country' && this.checkAddresComplete() && !isWelcomeProduct && normalizaDireciones) {
            let resAddressCheck = await AddressServices.getAddressCheck({
                city: addressData.town.value,
                street: formatedStreet,
                house: addressData.number.value,
                zip: addressData.postalCode.value,
                loader: true,
                setLoading: this.props.setLoading
            });

            if (resAddressCheck && resAddressCheck.length > 0) {
                addressData.postalCode.errorCode = '';
                addressData.province.errorCode = '';
                addressData.town.errorCode = '';
                addressData.number.errorCode = '';
                addressData.address.errorCode = '';
                this.setState({ addressData });
            }
            else {//Cuando la dirección no es válida
                addressData.postalCode.errorCode = 'invalid-address';
                addressData.province.errorCode = 'invalid-address';
                addressData.town.errorCode = 'invalid-address';
                addressData.number.errorCode = 'invalid-address';
                addressData.address.errorCode = 'invalid-address';
                this.setState({ addressData });
                if(resAddressCheck.data.message){
                    this.setState({showModal : true, infoModalText: resAddressCheck.data.message});
                }
            }
        }
    }
    checkAddresComplete(): boolean {
        const { addressData } = this.state;

        if (addressData.address.value !== '' &&
            addressData.town.value !== '' &&
            addressData.number.value !== '' &&
            addressData.postalCode.value !== '')
            return true

        return false;
    }

    validateForm(): boolean {
        let { addressData } = this.state;
        let noErrors = true;

        //Validación código postal
        if (!addressData.postalCode.value) {
            addressData.postalCode.errorCode = 'required';
            noErrors = false;
        }

        //Validación población
        if (!addressData.town.value) {
            addressData.town.errorCode = 'required';
            noErrors = false;
        }

        //Validación provincia
        if (!addressData.province.value) {
            addressData.province.errorCode = 'required';
            noErrors = false;
        }

        //Validación domicilio
        if (!addressData.address.value) {
            addressData.address.errorCode = 'required';
            noErrors = false;
        }

        //Validación número/km
        if (!addressData.number.value) {
            addressData.number.errorCode = 'required';
            noErrors = false;
        }

        //Validación país
        if (!addressData.country.value) {
            addressData.country.errorCode = 'required';
            noErrors = false;
        }

        //Si el checkAddress dió error
        if (
            addressData.number.errorCode === 'invalid-address' ||
            addressData.address.errorCode === 'invalid-address' ||
            addressData.province.errorCode === 'invalid-address' ||
            addressData.town.errorCode === 'invalid-address' ||
            addressData.postalCode.errorCode === 'invalid-address'
        ) {
            noErrors = false;
        }

        if (noErrors) {
            if (this.props.physicalPerson) {
                this.props.onValidated(
                    {
                        address: this.mapAddressDataItem(addressData.address),
                        wayType: addressData.wayType,
                        number: addressData.number.value,
                        portal: addressData.portalValue.value,
                        stairs: addressData.stairsValue.value,
                        floor: addressData.floorValue.value,
                        door: addressData.doorValue.value,
                        postalCode: addressData.postalCode.value,
                        town: this.mapAddressDataItem(addressData.town),
                        province: this.mapAddressDataItem(addressData.province),
                        country: this.mapAddressDataItem(addressData.country),
                        sameAsTaker: addressData.sameAsTaker,
                        normalizaDireciones: addressData.normalizaDireciones,
                    }
                );
            }
            else {
                this.props.onValidated(
                    {
                        address: this.mapAddressDataItem(addressData.address),
                        wayType: addressData.wayType,
                        number: addressData.number.value,
                        portal: addressData.portalValue.value,
                        stairs: addressData.stairsValue.value,
                        floor: addressData.floorValue.value,
                        door: addressData.doorValue.value,
                        postalCode: addressData.postalCode.value,
                        town: this.mapAddressDataItem(addressData.town),
                        province: this.mapAddressDataItem(addressData.province),
                        country: this.mapAddressDataItem(addressData.country),
                        sameAsTaker: addressData.sameAsTaker,
                        normalizaDireciones: addressData.normalizaDireciones,
                    }
                );
            }
        }
        this.setState({ addressData });
        return noErrors;
    }

    cleanForm() {
        let { addressData, streets, postalCodes, provinces, cities } = this.state;

        addressData = DefaultAddressDataDto();
        streets = [];
        postalCodes = [];
        provinces = [];
        cities = [];

        this.setState({ addressData, streets, postalCodes, provinces, cities });
    }

    getFilteredCountries = async (value: string, block: string) => {
        const { countries, addressData } = this.state;
        const { policy } = this.props;
        const isWelcomeProduct = policy.product.products_internalName === PRODUCT_TYPE.TRAVEL_WELCOME

        if (value.length < 1) {
            addressData.country = DefaultValueFormHealthAddress(false);
            this.setState({ addressData })
        }

        if (value.length < 2) return;
        if (value.length >= 2) {
            this.setState({
                countriesFiltered: countries.filter((country) =>
                    country.label.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    .includes(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                    &&
                    (!isWelcomeProduct ? true : country.value !== 'ESPA')
                ),
            });
        }
    };

    getProvinces = async (input: string, block: string) => {
        const { setLoading } = this.props;
        let { addressData } = this.state;
        const resProvinces = await AddressServices.getProvinces({
            loader: false,
            setLoading: setLoading,
            params: [`input=${input}`],
        });
        const provinces = resProvinces.map((p: any) => ({
            label: p.name,
            value: p.code,
        }));
        addressData.town.value = "";
        addressData.address.value = "";
        addressData.address.label = "";
        addressData.postalCode.value = "";
        addressData.number.value = "";
        addressData.doorValue.value = "";
        addressData.stairsValue.value = "";
        addressData.floorValue.value = "";
        addressData.portalValue.value = "";

        this.setState({
            provinces,
            cities: [],
            streets: [],
            postalCodes: [],
            addressData
        });
    };

    getCities = async (input: string, block: string) => {
        const { setLoading } = this.props;
        const { addressData } = this.state;
        const resCities = await AddressServices.getCities({
            loader: false,
            setLoading: setLoading,
            params: [
                `input=${input}`,
                `provinceCode=${addressData.province.code}`,
            ],
        });
        const cities = resCities.map((p: any) => ({
            label: p.name,
            value: p.code,
        }));
        addressData.address.value = "";
        addressData.postalCode.value = "";
        addressData.number.value = "";
        addressData.doorValue.value = "";
        addressData.stairsValue.value = "";
        addressData.floorValue.value = "";
        addressData.portalValue.value = "";

        this.setState({ cities, streets: [], postalCodes: [], addressData });
    };

    getStreets = async (input: string, block: string) => {
        const { setLoading } = this.props;
        const { addressData } = this.state;
        const resStreets = await AddressServices.getStreets({
            loader: false,
            setLoading: setLoading,
            params: [
                `input=${input}`,
                `provinceCode=${addressData.province.code}`,
                `cityCode=${addressData.town.code}`,
            ],
        });
        addressData.postalCode.value = "";
        const streets = resStreets.map((p: any) => ({
            label: `${p.extra.street.code} ${p.extra.street.name}`,
            value: p.code,
            extra: p.extra,
        }));
        addressData.postalCode.value = "";
        addressData.number.value = "";
        addressData.doorValue.value = "";
        addressData.stairsValue.value = "";
        addressData.floorValue.value = "";
        addressData.portalValue.value = "";
        this.setState({ streets, postalCodes: [], addressData });
    };

    getPostalCodes = async (input: string, block: string) => {
        const { setLoading } = this.props;
        const { addressData } = this.state;
        const resPostalCodes = await AddressServices.getPostalCodes({
            loader: false,
            setLoading: setLoading,
            params: [
                `input=${input}`,
                `provinceCode=${addressData.province.code}`,
                `cityCode=${addressData.town.code}`,
                `street=${addressData.address.code}`,
            ],
        });
        const postalCodes = resPostalCodes.map((p: any) => ({
            label: p.name,
            value: p.name,
        }));

        this.setState({ postalCodes });
    };

    changeValueSearchBox = (ev: any, block: string, key: string = "") => {
        const { countries, provinces, cities, streets, postalCodes } = this.state;

        if (this._searchWaiter) {
            clearInterval(this._searchWaiter);
        }
        const value: string = ev.target.value;
        if(key === "country") {
            if (!countries[0] || countries[0].label.toLowerCase() !== value.toLowerCase()) this.getFilteredCountries(value, block);
            return;
        } else { 
            const value: string = ev.target.value;
            if (value.length < 2) return;
    
            this._searchWaiter = setTimeout(async () => {
                switch (key) {
                    case "province":
                        if (
                            !provinces[0] ||
                            provinces[0].label.toLowerCase() !== value.toLowerCase()
                        ) {
                            this.getProvinces(value, block);
                        }
                        break;
                    case "city":
                        if (
                            !cities[0] ||
                            cities[0].label.toLowerCase() !== value.toLowerCase()
                        ) {
                            this.getCities(value, block);
                        }
                        break;
                    case "postalCode":
                        if (
                            !postalCodes[0] ||
                            postalCodes[0].label.toLowerCase() !== value.toLowerCase()
                        ) {
                            this.getPostalCodes(value, block);
                        }
                        break;
                    case "street":
                        if (
                            !streets[0] ||
                            streets[0].label.toLowerCase() !== value.toLowerCase()
                        ) {
                            this.getStreets(value, block);
                        }
                        break;
                    default:
                        break;
                }
            }, 400);
        }
    };

    render() {
        const { addressData,
            provinces,
            cities,
            streets,
            postalCodes,
            showModal,
            countriesFiltered
        } = this.state
        const { insured, isReadOnly, policy } = this.props;
        const productInternalName = policy.product.products_internalName
        const isWelcomeProduct = productInternalName === PRODUCT_TYPE.TRAVEL_WELCOME
        const normalizaDireciones = addressData.normalizaDireciones === "S";
        
        return (
            <TravelAddressFormContainer>
                <div className="content-wrapper bottomMargin20" ref={''}>
                    <div className="personal-data-wrapper" id="data-address">
                        <div className="data-title">
                            <h5>DATOS DOMICILIO</h5>
                        </div>

                        <div className="colSin-100">
                            {insured ? (
                                <CheckboxItemLayout
                                    name='Los datos del domicilio son los mismos que los del tomador'
                                    checked={addressData.sameAsTaker}
                                    onChange={() => this.handleCheckTakerChange()}
                                    showError={false}
                                    checkboxText='Los datos del domicilio son los mismos que los del tomador'
                                    errorText='Error'
                                    disabled={isReadOnly}
                                />
                            ) : ''
                            }

                            <div className="colSin-33 rightPadding20 bottomMargin20 topMargin20">
                                <div className="inputBox">
                                    <SearchBox
                                        onChange={(ev: any) =>
                                            this.changeValueSearchBox(ev, "address", "country")
                                        }
                                        errorCode={addressData.country.errorCode}
                                        onSelect={(item: string) => this.handleFormChange(item, "country")}
                                        required
                                        disabled={addressData.country.disabled || isReadOnly}
                                        withLoader={true}
                                        initialValue={addressData.country.value}
                                        optionsText={countriesFiltered}
                                        optionKey={"code"}
                                        optionValue={"label"}
                                        labelText=""
                                    />
                                    <label>PAÍS</label>
                                </div>
                            </div>

                            <div className="colSin-33 rightPadding20 bottomMargin20 topMargin20">
                                <div className="inputBox">
                                    {!isWelcomeProduct && normalizaDireciones ? (
                                            <div>
                                                <SearchBox
                                                    onChange={(ev: any) =>
                                                        this.changeValueSearchBox(ev, "address", "province")
                                                    }
                                                    errorCode={addressData.province.errorCode}
                                                    onSelect={(item: string) => this.handleFormChange(item, "province")}
                                                    required
                                                    disabled={addressData.province.disabled || isReadOnly}
                                                    withLoader={true}
                                                    initialValue={addressData.province.value}
                                                    optionsText={provinces}
                                                    optionKey={"code"}
                                                    optionValue={"label"}
                                                    labelText=""
                                                    notScroll
                                                />
                                                <label>PROVINCIA</label>
                                            </div>
                                        )
                                        : 
                                        (
                                            <InputBox
                                                errorCode={addressData.province.errorCode}
                                                onChange={(value: string) => this.handleFormChange(value, "province")}
                                                placeholder="Provincia"
                                                disabled={addressData.province.disabled || isReadOnly}
                                                type={"text"}
                                                value={addressData.province.value}
                                                required
                                                notScroll
                                            />
                                        )
                                    }
                                </div>
                            </div>

                            <div className="colSin-33 rightPadding20 bottomMargin20 topMargin20">
                                <div className="inputBox">
                                    {!isWelcomeProduct && normalizaDireciones ? (
                                            <div>
                                                <SearchBox
                                                    onChange={(ev: any) =>
                                                        this.changeValueSearchBox(ev, "address", "city")
                                                    }
                                                    errorCode={addressData.town.errorCode}
                                                    onSelect={(item: string) => this.handleFormChange(item, "town")}
                                                    required
                                                    disabled={addressData.town.disabled || isReadOnly}
                                                    initialValue={addressData.town.value}
                                                    optionsText={cities}
                                                    optionKey={"code"}
                                                    optionValue={"label"}
                                                    labelText=''
                                                    withLoader={true}
                                                    notScroll
                                                />
                                                <label>POBLACIÓN</label>
                                            </div>
                                        )
                                        : 
                                        (
                                            <InputBox
                                                errorCode={addressData.town.errorCode}
                                                onChange={(value: string) => this.handleFormChange(value, "town")}
                                                placeholder="Población"
                                                disabled={addressData.town.disabled || isReadOnly}
                                                type={"text"}
                                                value={addressData.town.value}
                                                required
                                                notScroll
                                            />
                                        )
                                    }
                                </div>
                            </div>

                            <div className="colSin-100 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    {!isWelcomeProduct && normalizaDireciones ? (
                                            <div>
                                                <SearchBox
                                                    onChange={(ev: any) =>
                                                        this.changeValueSearchBox(ev, "address", "street")
                                                    }
                                                    errorCode={addressData.address.errorCode}
                                                    onSelect={(item: string) => this.handleFormChange(item, "address")}
                                                    required
                                                    disabled={addressData.address.disabled || isReadOnly}
                                                    initialValue={addressData.address.label}
                                                    optionsText={streets}
                                                    optionKey={"code"}
                                                    optionValue={"label"}
                                                    labelText=''
                                                    withLoader={true}
                                                    notScroll
                                                />
                                                <label>DOMICILIO</label>
                                            </div>
                                        )
                                        : 
                                        (
                                            <InputBox
                                                errorCode={addressData.address.errorCode}
                                                onChange={(value: string) => this.handleFormChange(value, "address")}
                                                placeholder="Domicilio"
                                                disabled={addressData.address.disabled || isReadOnly}
                                                type={"text"}
                                                value={addressData.address.value}
                                                required
                                                notScroll
                                            />
                                        )
                                    }
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">

                                <div className="inputBox">
                                    <InputBox
                                        errorCode={addressData.number.errorCode}
                                        onChange={(value: string) => this.handleFormChange(value, "number")}
                                        placeholder="NÚMERO/KM"
                                        required
                                        disabled={addressData.number.disabled || isReadOnly}
                                        type={"number"}
                                        maxLength={10}
                                        value={addressData.number.value}
                                    />
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        onChange={(value: string) => this.handleFormChange(value, "portalValue")}
                                        placeholder="PORTAL"
                                        type={"number"}
                                        disabled={addressData.portalValue.disabled || isReadOnly}
                                        maxLength={5}
                                        value={addressData.portalValue.value}
                                    />
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        onChange={(value: string) => this.handleFormChange(value, "stairsValue")}
                                        placeholder="ESCALERA"
                                        type={"text"}
                                        disabled={addressData.stairsValue.disabled || isReadOnly}
                                        maxLength={5}
                                        value={addressData.stairsValue.value}
                                    />
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        onChange={(value: string) => this.handleFormChange(value, "floorValue")}
                                        placeholder="PISO"
                                        type={"number"}
                                        disabled={addressData.floorValue.disabled || isReadOnly}
                                        maxLength={5}
                                        value={addressData.floorValue.value}
                                    />
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        onChange={(value: string) => this.handleFormChange(value, "doorValue")}
                                        placeholder="PUERTA"
                                        type={"text"}
                                        maxLength={5}
                                        disabled={addressData.doorValue.disabled || isReadOnly}
                                        value={addressData.doorValue.value}
                                    />
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    {!isWelcomeProduct && normalizaDireciones ? (
                                            <div>
                                                <SearchBox
                                                    onChange={(ev: any) =>
                                                        this.changeValueSearchBox(ev, "address", "postalCode")
                                                    }
                                                    errorCode={addressData.postalCode.errorCode}
                                                    onSelect={(item: string) => this.handleFormChange(item, "postalCode")}
                                                    required
                                                    disabled={addressData.postalCode.disabled || isReadOnly}
                                                    initialValue={addressData.postalCode.value}
                                                    optionsText={postalCodes}
                                                    optionKey={"code"}
                                                    optionValue={"label"}
                                                    labelText=''
                                                    withLoader={true}
                                                    notScroll
                                                />
                                                <label>CÓDIGO POSTAL</label>
                                            </div>
                                        )
                                        : 
                                        (
                                            <InputBox
                                                errorCode={addressData.postalCode.errorCode}
                                                onChange={(value: string) => this.handleFormChange(value, "postalCode")}
                                                placeholder="Código postal"
                                                disabled={addressData.postalCode.disabled || isReadOnly}
                                                type={"text"}
                                                value={addressData.postalCode.value}
                                                required
                                                notScroll
                                                maxLength={isWelcomeProduct ? 50 : 5}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            {
                                !isWelcomeProduct && (
                                    <div className="colSin-80 rightPadding20 bottomMargin20">
                                        <div className="inputBox">
                                            <DicotomicCheckbox
                                                className={`${addressData.province.disabled || isReadOnly ? "disabled" : "" } optional`}
                                                checkboxText="CONTRATAR CON DIRECCIÓN NO NORMALIZADA"
                                                onChange={(value: boolean) => this.handleChangeNormalizedAddress(value)}
                                                disabled={addressData.province.disabled || isReadOnly}
                                                initChecked={addressData.normalizaDireciones === "N"}
                                            />
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
                {showModal && (
                    <InfoModal
                    mainText={"Domicilio incorrecto. Por favor, verifique si los datos son correctos o debe modificarlos. "+this.state.infoModalText}
                    close={() => this.setState({ showModal: false })}
                    active={true}
                    img={images.IconInfoBlue}
                    />
                )}
            </TravelAddressFormContainer >
        )

    }
}

export default withPolicy(TravelAddressFormItem);
