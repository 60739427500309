import React from "react";
import { ContactContainer } from "./contact-style";
import GoBack from "../../components/go-back";
import SelectBox from "../../components/select-box/select-box";
import MainButton from "../../components/main-button";
import { images } from "../../assets";
import InputBox from "../../components/input-box/input-box";
import InputFile from "../../components/input-file/input-file";
import { ValueForm } from "../../utils/interfaces";
import ContactServices from "../../services/ContactServices";
import { withGeneral } from "../../context/mixin/with-general";
import LeftInfoModal from "../../components/left-info-modal";
import InfoModalLayout from "../../components/info-modal/info-modal-layout";
import { acceptedFileMimeType } from "../../constants/acceptedFileMimeType";

interface ContactProps {
  setLoading: Function;
  user: {
    email: string,
    name: string,
    lastname1: string,
    lastname2: string
  };
  mediator: {
    mediatorCode: string
  };
}

interface ContactState {
  contact: {
    type: ValueForm;
    message: ValueForm;
    file: ValueForm;
    [key: string]: ValueForm;
  };
  mediatorCode: string,
  userName: string,
  userEmail: string,
  showModal: string;
  textModal: string;
  titleModal: string;
  classNameModal: string;
}

interface AttachedFile {
  name: string;
  size: string;
  creation_date: string;
  error: boolean;
  data: any;
}

class ContactLayout extends React.Component<ContactProps, ContactState> {
  static state: ContactState;
  constructor(props: ContactProps) {
    super(props);
    this.state = {
      contact: {
        type: {
          value: "",
          error: false,
          errorCode: "",
          required: true
        },
        message: {
          value: "",
          error: false,
          errorCode: "",
          required: true
        },
        file: {
          value: "",
          error: false,
          errorCode: "",
          required: true
        },
      },
      mediatorCode: '',
      userName: '',
      userEmail: '',
      showModal: "",
      textModal: "",
      titleModal: "",
      classNameModal: ""
    };
  }


  changeValue = (value: any, key: string = "") => {
    const { contact } = this.state;
    contact[key].value = value;

    this.setState({ contact });
  };
  validateForm = () => {
    const { contact } = this.state;
    let isOk = true;
    Object.keys(contact).forEach((key: string) => {
      if (contact[key].required && contact[key].value === "") {
        contact[key].error = true;
        contact[key].errorCode = "required";
        isOk = false;
      } else {
        contact[key].error = false;
        contact[key].errorCode = "";
      }
    });

    this.setState({ contact });
    return isOk;
  };

  handleSend = async () => {
    const isOk = this.validateForm();

    if (isOk) {
      const { setLoading } = this.props;
      const { contact } = this.state;
      const data = new FormData();

      data.append("type", contact.type.value);
      data.append("message", contact.message.value);
      data.append("file", contact.file.value.data);
      data.append("mediatorCode",this.props.mediator.mediatorCode);
      data.append("userName",`${this.props.user.name+" "+this.props.user.lastname1+" "+this.props.user.lastname2}`);
      data.append("userEmail",this.props.user.email);
      // data.append("contactMail", "email@pruebas.com");
     
      try {
        const res = await ContactServices.contact({
          loader: true,
          setLoading: setLoading,
          data
        });
        this.setState({
          titleModal: "Enviado",
          textModal: `¡Mensaje enviado con éxito! Gracias por contactar`,
          classNameModal: "success",
          showModal: "success"
        });
      } catch (err) {
        let ex: any = err;
        this.setState({
          titleModal: "Error",
          textModal: (ex.data.statusCode == 400 && ex.data.message) ? ex.data.message : "¡Ha ocurrido un error inesperado! Contacta con asisa si necesitas ayuda",
          classNameModal: "error",
          showModal: "error"
        });
      }
      
    }
  };

  closeModal = () => {
    this.setState({ showModal: "" });
  };

  render() {
    const {
      contact,
      showModal,
      textModal,
      titleModal,
      classNameModal
    } = this.state;
    return (
      <ContactContainer>
        <div className="go-back-head">
          <GoBack />
        </div>

        <div className="contact-content">
          <div className="contact-left">
            <div className="contact-left-top">
              <div className="contact-left-top__title">
                <p>Contacto</p>
              </div>
              <div className="contact-left-top__subtitle">
                <p>
                  ¿En qué podemos ayudarte? ¿Algún problema? Te escuchamos...
                </p>
              </div>
            </div>
            <div className="contact-info">
              <div className="contact-info-item">
                <div className="contact-info-item__icon">
                  <img src={images.IconMailBlue} alt="" />
                </div>
                <div className="contact-info-item__link">
                  <a href="mailto:soportewebmediadores@asisa.es">soportewebmediadores@asisa.es</a>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-right">
            <div className="contact-right-form">
              <div className="contact-right-input">
                <div className="selectBox">
                  <SelectBox
                    labelText="Motivos"
                    optionsText={[
                      { value: "technicalSupport", label: "Problema técnico" },
                      { value: "complaint", label: "Reclamación" },
                      { value: "suggestion", label: "Sugerencia" },
                      { value: "legalSupport", label: "Soporte legal" },
                      { value: "others", label: "Otros" }
                    ]}
                    optionKey={"value"}
                    optionValue={"label"}
                    disabled={contact.type.disabled}
                    required={contact.type.value}
                    errorCode={contact.type.errorCode}
                    initialValue={contact.type.value}
                    onChange={(value: string) => this.changeValue(value, "type")}
                  />
                </div>
              </div>
              <div className="contact-right-input">
                <div className="inputBox inputArea">
                  <InputBox
                    area={true}
                    areaRows={2}
                    placeholder=""
                    labelText="Detalles"
                    required={contact.message.required}
                    errorCode={contact.message.errorCode}
                    value={contact.message.value}
                    onChange={(value: string) =>
                      this.changeValue(value, "message")
                    }
                  />
                </div>
              </div>
              <div className="contact-right-input">
                <InputFile
                  labelText="Adjuntar Archivo"
                  placeholder="Selecciona un archivo..."
                  required={contact.file.required}
                  errorCode={contact.file.errorCode}
                  value={contact.file.value ? contact.file.value.name : ''}
                  onChange={(files: AttachedFile[]) =>
                    this.changeValue(files[0], "file")
                  }
                  accept={acceptedFileMimeType}
                />
              </div>
            </div>

            <div className="contact-right__button">
              <MainButton text={"Enviar"} onClick={() => this.handleSend()} />
            </div>
          </div>
        </div>
        {showModal === "success" && (
          <InfoModalLayout
            img={images.IconDoubleFill}
            className={classNameModal}
            mainText={textModal}
            buttonText={"Cerrar"}
            active={showModal === "success"}
            onClickAccept={() => this.closeModal()}
            close={() => this.closeModal()}
            noSecondaryButton
          />
        )}

        <LeftInfoModal
          img={images.WarningError}
          className={classNameModal}
          mainText={textModal}
          mainTitle={titleModal}
          buttonText={"Cerrar"}
          active={showModal === "error"}
          onClickAccept={() => this.closeModal()}
          close={() => this.closeModal()}
          noSecondaryButton
        />
      </ContactContainer>
    );
  }
}

export default withGeneral(ContactLayout);
