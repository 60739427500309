import React from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { HomeContainer } from "./home-style";
import { images } from "../../assets";
import WebText from "../../components/components-text/web-text";
import MainButton from "../../components/main-button";
import MenuText from "../../components/components-text/menu-text";
import H3Text from "../../components/components-text/h3-text";
import Body02Text from "../../components/components-text/body-02-text";
import HeadText from "../../components/components-text/head-text";
import BodyText from "../../components/components-text/body-text";
import MainModal from "../../components/main-modal";
import { withGeneral } from "../../context/mixin/with-general";
import StatsServices from "../../services/StatsServices";
import GestDocServices from "../../services/Gest-Doc.Services";
import { GestDoc } from "../../services/GestDoc";
import { getFileExtension } from "../../utils/utility";
import { ErrorModalContainer } from "../../components/error-modal/error-modal-style";
import { IUser } from "../../models/user-model";
import { GestDocFileListResponseDto } from "../../models/gest-doc-models";
import { rolesSuplementos } from "../../constants";
import { isSupplementOptionEnabled } from "../../utils/validation";
import CardCommunications from "../../components/card-communications";
import { NotificationType, getShowNotificationsFormat } from "../communications/helpers";
import CommunicationsServices from "../../services/CommunicationsServices";

interface HomeState {
  stats: any;
  documentModal: boolean;
  serverError: boolean;
  notifications: NotificationType[];
}

interface HomeProps extends RouteComponentProps {
  setLoading: Function;
  hasInformativeNote: boolean;
  setHasInformativeNote: Function;
  user: IUser;
}

class HomeLayout extends React.Component<HomeProps, HomeState> {
  readonly state: HomeState;

  constructor(props: HomeProps) {
    super(props);
    this.state = {
      documentModal: false,
      serverError: false,
      stats: {
        expiredSimulations: 0,
        hirings: 0,
        simulations: 0,
      },
      notifications: [],
    };
  }

  componentDidMount() {
    this.getStats();
    this.getNotifications();
  }

  componentDidUpdate(prevProps: Readonly<HomeProps>): void {
    const prevUser = prevProps.user;
    const newUser = this.props.user;
    if (prevUser.roles !== newUser.roles) this.getNotifications();
  }

  getStats = async () => {
    const { setLoading } = this.props;

    const response = await StatsServices.getStats({
      loader: true,
      setLoading: setLoading,
    });
    this.setState({ stats: response });
  };

  getNotifications = async () => {
    const { user } = this.props;
    const response = await CommunicationsServices.getHomeNotifications(user);
    if (response.ok) {
      this.setState({ notifications: response.data });
    }
  };

  render() {
    const { stats, documentModal, notifications } = this.state;
    const { hasInformativeNote, user } = this.props;
    return (
      <HomeContainer>
        {notifications.length > 0 && (
          <div className="notifications-container">
            {notifications.map((notification) => {
              return (
                <CardCommunications
                  key={notification.id}
                  notification={notification}
                  origin="home"
                />
              );
            })}
          </div>
        )}
        <div className="home-hero-intro-container">
          <div className="home-hero-intro-item text">
            <div className="home-hero-intro-body">
              <p>¿Quieres hacer una nueva simulación?</p>
            </div>
            <div className="home-hero-intro-btn">
              <MainButton
                text="Simular"
                onClick={() => this.props.history.push("/simulacion/nueva")}
              />
            </div>
          </div>

          <div className="home-hiring-data-container">
            <div className="home-hiring-data-top-container">
              <div className="home-hero-intro-body">
                <p>Contratación</p>
              </div>
              {/* <div className="home-hiring-data-select">
                <p>Text field</p>
              </div> */}
            </div>

            <div>
              <div className="home-hiring-data-middle-container">
                <div className="home-hiring-data-amount">
                  <p className="home-hiring-data-amount-hired">
                    {stats.hirings}
                  </p>
                </div>
                <div className="home-hiring-data-info">
                  <p>Contrataciones desde<br /> simulación</p>
                </div>
              </div>

              <div className="home-hiring-data-btn-container">
                <MainButton
                  text="Contratar"
                  onClick={() =>
                    this.props.history.push("/contratacion/pendiente?new=true")
                  }
                />
              </div>
            </div>
          </div>

          <div className="pic">
            <img src={images.newHeroImage} className={`position-${notifications.length}`} />
          </div>
        </div>

        <div className="home-data-wrapper">
          <div className="home-simulation-data-container">
            <div className="home-simulation-data-top-container">
              <div className="home-simulation-data-top-title">
                <H3Text>
                  <p>Simulación</p>
                </H3Text>
              </div>
              {/* <div className="home-simulation-data-top-select">
                <p>Text field</p>
              </div> */}
            </div>

            <div className="home-simulation-data-content">
              <div className="home-simulation-data-slot-container">
                <div className="home-simulation-data-slot-number">
                  <p>{stats.simulations}</p>
                </div>
                <div className="home-simulation-data-slot-info">
                  <div className="home-simulation-data-slot-top-container">
                    <div className="home-simulation-data-slot-info-top-title">
                      <HeadText>
                        <p>Simulaciones en los últimos 30 días</p>
                      </HeadText>
                    </div>
                    <div className="home-simulation-data-slot-info-top-btn">
                      <MainButton
                        text="Simular"
                        onClick={() =>
                          this.props.history.push("/simulacion/nueva")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="home-simulation-data-slot-container">
                <div className="home-simulation-data-slot-number">
                  <p>{stats.expiredSimulations}</p>
                </div>
                <div className="home-simulation-data-slot-info">
                  <div className="home-simulation-data-slot-top-container">
                    <div className="home-simulation-data-slot-info-top-title">
                      <HeadText>
                        <p>Simulaciones expiradas</p>
                      </HeadText>
                    </div>
                    <div className="home-simulation-data-slot-info-top-btn">
                      {/* <MainButton text="Ver" onClick={() => this.props.history.push("/simulacion/pendiente?expired=true")} /> */}
                      <MainButton
                        text="Ver"
                        onClick={() =>
                          this.props.history.push("/simulacion/expirada")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isSupplementOptionEnabled(user, rolesSuplementos) && (
          <div className="home-modification-container">
            <div className="home-modification-item text">
              <div className="home-modification-body">
                <p>¿Quieres hacer una modificación o suplemento?</p>
              </div>
              <div className="home-modification-btn">
                <MainButton
                  text="Modificación/ Suplemento"
                  onClick={() => this.props.history.push("/suplementos")}
                />
              </div>
            </div>
            <div className="home-modification-item pic">
              {/* <img src={images.newHeroImage} /> */}
            </div>
          </div>
        )}
      </HomeContainer>
    );
  }
}

export default withGeneral(withRouter(HomeLayout));
