import GeneralServices from "./GeneralServices";
import { BaseServiceRequestDto } from "./dto/request/BaseServiceRequest.dto";
import { IPayMethodByProductRequest } from "./PayMethodServices.dto";

class PayMethodServices {
  getPayMethodByProduct = async ({ loader, setLoading, productId }: IPayMethodByProductRequest) => {
    try {
      const response = await GeneralServices.get({
        setLoading,
        loader,
        endpoint: `payMethod/productPayMethod?product_id=${productId}`,
      });
      return {
        ok: true,
        data: response.data,
      };
    } catch (error) {
      return {
        error: true,
        message: typeof error === "string" ? error : "Se ha producido un error, inténtelo más tarde.",
      };
    }
  };

  getPayMethods = async ({ loader, setLoading }: BaseServiceRequestDto) => {
    try {
      const response = await GeneralServices.get({
        setLoading,
        loader,
        endpoint: "payMethod/allPayMethods",
      });
      return {
        ok: true,
        data: response.data
      };
    } catch (error) {
      return {
        error: true,
        message: typeof error === "string" ? error : "Error al obtener el método de pago.",
      };
    }
  };
}

export default new PayMethodServices();
