import React from 'react';
import { images } from '../../assets';
import Loader from '../loader';
import PoliciesItem from '../policies-item';

import {
    RouteComponentProps, withRouter
} from "react-router-dom";
import { withPolicy } from '../../context/mixin/with-policy';
import ProductServices from '../../services/ProductServices';
import { AREA_NAMES, CARD_NAMES } from '../../constants/policyType';
import { withDynamicProducts } from '../../context/mixin/with-dynamic-products';

interface DynamicProductsProps extends RouteComponentProps {
    type: string;
    setItemHealthClick?: Function,
    navigateHealth?: Function,
    checkProductArea: Function;
}

export interface ProductsItem {

}
export interface PoliciesItem {
    products: ProductsItem
}
interface DynamicProductsState {
    loading: boolean;
    policiesItem: any;
}

class DynamicProducts extends React.Component<DynamicProductsProps, DynamicProductsState> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            policiesItem: []
        };
    }
    componentDidMount = async () => {
        // Se hace llamada al servidor 
        let json: string = localStorage.getItem('loggedUser') || "{code:0}";
        let user
        let args
        if (json === "{code:0}") {
            this.props.history.push("")
        } else {
            user = JSON.parse(json)
            args = {
                mediator_id: user.code,
                loader: false,
                setLoading: () => { }
            };
        }

        const dynamic_products = await ProductServices.getDynamicProducts(args);
        this.setState({
            policiesItem: dynamic_products
        })
    }

    _formatId = (products: any, tipo: string) => {
        if (tipo == "simple") {
            return products[0].id[0]
        }
        else {
            let result: string[] = [];
            products.map((product: { id: any; }) => {
                result.push(product.id[0])
            })
            return result;
        }
    }
    _formatLink = (products: any, tipo: string) => {
        let array = []
        let link

        if (tipo == "simple") {
            link = (this.props.type === "simulacion") ? products[0].linkSimulacion : products[0].link
            if (link.includes('http')) {
                return link
            } else {
                return `/${this.props.type}${link}`
            }
        }
        else {
            let result: string[] = [];
            products.map((product: { linkSimulacion: any; link: any; }) => {
                link = (this.props.type === "simulacion") ? product.linkSimulacion : product.link;
                if (link.includes('http')) {
                    result.push(link)
                } else {
                    result.push(`/${this.props.type}${link}`)
                }
            })
            return result;
        }
        // if (linkSimulacion && this.props.type === "simulacion") {
        //    array = link
        // } else {
        //     array = JSON.parse(link)
        // }
        // if (array.length === 1) {
        //     let value = Object.values(array[0])[0]
        //     return `/${this.props.type}${value}`
        // } else {
        //     let result = [];
        //     for (var key in array) {
        //         let value = Object.values(array[key])[0]
        //         result.push(`/${this.props.type}${value}`)
        //     }
        //     return result
        // }

    }
    _formatHistory = (url: string) => {
        return `/${this.props.type}${url}`

    }
    _formatComplex = (products: any) => {
        let array = [];
        let result: { text: any; link: string; id: any; }[] = [];
        products.map((prd: { linkSimulacion: any; link: any; name: any; id: any; }) => {
            let link = (this.props.type === "simulacion") ? prd.linkSimulacion : prd.link
            result.push({ text: prd.name, link: `/${this.props.type}${link}`, id: prd.id[0] })
        })

        return result;
    }
    _textButton = (type: string) => {
        return type === "contratacion" ? "contratar" : "simular"
    }
    _parseImage = (img: string, cardName: string) => {
        if (img && img.includes("http")) {
            return img;
        } else {
            switch (cardName) {
                case CARD_NAMES.VIDA:
                    return images.WavePolicieYellow;
                case CARD_NAMES.TRAVEL:
                    return images.WavePolicieTravel;
                case CARD_NAMES.MASCOTAS:
                    return images.WavePolicieRed;
                case CARD_NAMES.DECESOS:
                    return images.WavePoliciePurple;
                case CARD_NAMES.SALUD:
                    return images.WavePolicieSalud;
                case CARD_NAMES.DENTAL:
                    return images.WavePolicieDental;
                case CARD_NAMES.PYMES:
                    return images.WavePoliciePymes;
                default:
                    return images.WavePolicieBlue;
            }
        }
    }

    getProductById = (product_id: string) => {
        const { policiesItem } = this.state
        let selectedProduct

        for (const businessLine in policiesItem) {
            selectedProduct = policiesItem[businessLine].products.find((product: any) => product.id[0] == product_id)
            if (selectedProduct && Object.keys(selectedProduct).length !== 0) break
        }

        return selectedProduct
    }

    setGlobalInternalName = (product_id: string) => {
        let selectedProduct = this.getProductById(product_id)

        if (selectedProduct && Object.keys(selectedProduct).length !== 0) {
            localStorage.setItem('internalName', selectedProduct.internalName)
        }
    }

    render() {
        const { checkProductArea } = this.props
        return (
            <>
                {(this.state.loading) &&
                    <Loader />
                }
                {this.state.policiesItem ?


                    Object.keys(this.state.policiesItem).map((key, index) => {
                        let tipo = this.state.policiesItem[key].products.length > 1 ? "complex" : "simple"
                        const isGIPArea = checkProductArea(AREA_NAMES.GIP, this.state.policiesItem[key].products[0].internalName)
                        const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, this.state.policiesItem[key].products[0].internalName)
                        
                        if(isGIPArea && this.props.type === 'contratacion'){
                            return <></>
                        }else{
                            return <PoliciesItem
                            key={index}
                            textButton={this._textButton(this.props.type)}
                            id={this._formatId(this.state.policiesItem[key].products, tipo)}
                            type={tipo}
                            typeName={this.state.policiesItem[key].products[0].name}
                            nameItem={this.state.policiesItem[key].products[0].nameItem}
                            link={this._formatLink(this.state.policiesItem[key].products, tipo)}
                            arrayComplexItem={(tipo === "complex") && this._formatComplex(this.state.policiesItem[key].products)}
                            changeStep={() => (isHealthArea || isGIPArea) ?
                                (this.props.navigateHealth && this.props.navigateHealth()) :
                                this.props.history.push(this._formatHistory(this.state.policiesItem[key].products[0].changeStep))
                            }
                            image={this._parseImage(this.state.policiesItem[key].products[0].image, this.state.policiesItem[key].products[0].cardName)}
                            setItemClick={(value: any) => (isHealthArea || isGIPArea) && this.props.setItemHealthClick && this.props.setItemHealthClick(value)}
                            setGlobalInternalName={(product_id: string) => { this.setGlobalInternalName(product_id) }}
                            cardName={this.state.policiesItem[key].products[0].cardName}
                        />
                        }
                    })
                    : <Loader />
                }
            </>
        );
    }
}

export default withDynamicProducts(withPolicy(withRouter(DynamicProducts)));

