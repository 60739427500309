import styled from "styled-components";
import { colors } from "../../assets/colors";

export const CampaignSidebarStyle = styled.div`
  position: absolute;
  top: 110px;
  right: 50px;
  width: 250px;

  .campaigns-sidebar-button {
    margin-bottom: 39px;
  }
  .campaigns-sidebar-section-title {
    color: #b5bcc6;
    margin-bottom: 20px;
  }

  .campaigns-sidebar-goal {
    margin-bottom: 68px;
    &__item {
      margin-bottom: 12px;
    }
  }

  .campaigns-sidebar-campaign {
    margin-bottom: 80px;
    &__item {
      margin-bottom: 16px;
    }
  }

  .campaigns-sidebar-policy-cards-subsection {
    border-top: 0.5px solid ${colors["PR-001-30"]};
    color: ${colors["PR-001-80"]};
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 12px;
    &__item {
      margin-top: 10px;
      display: flex;
      align-items: baseline;
      &__number {
        font-size: 20px;
        line-height: 28px;
        margin-right: 5px;
      }
      &__text {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .campaigns-sidebar-policy-cards-flex-container {
    display: flex;
    align-items: center;
  }

  .campaigns-sidebar-policy-cards {
    font-family: "museo sans";

    &__item {
      width: 100%;
      background: rgba(179, 202, 220, 0.15);
      border-radius: 3px;
      padding: 16px 10px;
      margin-bottom: 16px;
      &__number {
        font-size: 40px;
        line-height: 48px;
        font-weight: 700;
        text-align: center;
        color: ${colors["PR-001-100"]};
        margin-right: 10px;
      }

      &__subtitle {
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: ${colors["PR-001-80"]};
      }
    }
  }
`;
