import React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withGeneral } from "../../../context/mixin/with-general";
import { IMediator } from "../../../models/mediator-model";
import { SupplementContainer } from "./supplement-style";
import { data } from "./../supplements-data";
import SupplementIframeLayout from "../../../components/supplement-iframe/supplement-iframe-layout";
import { REACT_APP_OCASO_FORM_URL } from "../../../services/url";
import LeftInfoModal from "../../../components/left-info-modal";
import { images } from "../../../assets";
import CipherServices from '../../../services/CipherServices';
import SupplementsServices from '../../../services/SupplementsServices';

interface SupplementsProps extends RouteComponentProps {
    //setLoading: Function;
    mediator: IMediator;
    match: any,
    user: any,
  }

  interface SupplementsStage {
    
    modalErrorShow: boolean;
    modalErrorText: string;
      url: string;
      supplements: any
}

const SUSCAGENTE = "SUSCAGENTE";
const SUSCAGENTEOCASO = "SUSCAGENTEOCASO";
class SupplementsLayout extends React.Component<SupplementsProps, SupplementsStage> {

constructor(props: SupplementsProps){
    super(props);

    this.state = {
        modalErrorShow: false, 
        modalErrorText: "FFF",
        url: "",
        supplements: []
    }

};

componentDidMount = async () => {
    try{
        this.setState({ url: await this.getUrl()});
    } catch(err) {
        this.showErrorModal("Error obteniendo la información del vínculo.");
    }
}

    getParams(){
        let params = {
            param1 : "/"+this.props.match.params.param1,
            param2 : "/"+this.props.match.params.param2,
        };
        return params
    }

    getState(){
        const { history } = this.props;
        const mediator = history.location.state
        return mediator;
    }
    getRoles() {
        const { user } = this.props;
        const roles = user.roles
        return roles;
    }

    async getUrl(){
        const { user } = this.props;
        const params = this.getParams();
        const mediator = this.getState();
        let url = "";
        let path = "";
        let code = "";
        let isOnlyFather = true;
        data.forEach(element => {
            if(element.link === params.param1){
                path = element.path;

                //TODO: COMPROBAR QUE SI ES SOLO EL PADRE NO COMPRUEBE LOS HIJOS
                if (element.child) {
                    isOnlyFather = false;
                    element.child.forEach(elementChild => {
                if(elementChild.link === params.param1+params.param2){
                    code = elementChild.code
                }               
            });
                }
            }
        });
        if(mediator === undefined || mediator.mediator === undefined){
            this.showErrorModal("No se encontro el mediador.");
        } else if (code === "" && !isOnlyFather) {
            this.showErrorModal("No se encontro el codigo del formulario.");
        }else{
            const encryptedParams = await CipherServices.encrypt({data: {
                dataList: [
                    { key: "mediator", value: mediator.mediator },
                    { key: "email", value: user.email },
                ]
            }, setLoading: () => {}});
            url = REACT_APP_OCASO_FORM_URL + path + "?Mediador=" + encodeURIComponent(encryptedParams.dataList[0].value) + "&EmailMediador=" + encodeURIComponent(encryptedParams.dataList[1].value);
            const roles = this.getRoles()

            if (roles.includes(SUSCAGENTE)) {
                url += "&R=SA"
            } else if (roles.includes(SUSCAGENTEOCASO)) {
                url += "&R=SAO"
            }

            if (!isOnlyFather) {
                url = url + "&ACC=" + code;
            }
        }
        return url;
    }

    showErrorModal = (text: string = "Ha ocurrido un error.") => this.setState({ modalErrorShow: true, modalErrorText: text });
    hideErrorModal = () => {
        this.setState({ modalErrorShow: false, modalErrorText: "" });
        this.props.history.push({ pathname: '/suplementos' });
    }

    render() {

        const { url, modalErrorShow, modalErrorText } = this.state;
        
        return (
            
            <>
            <SupplementContainer>
               <SupplementIframeLayout 
                url={url}
               />
               <LeftInfoModal
                    img={images.WarningError}
                    className={"error"}
                    mainTitle={"Se ha producido un error"}
                    mainText={modalErrorText}
                    buttonText={"Cerrar"}
                    active={modalErrorShow}
                    onClickAccept={this.hideErrorModal}
                    close={this.hideErrorModal}
                    noSecondaryButton
                    />
            </SupplementContainer>
            </>
        );
    }
}
export default withRouter(withGeneral(SupplementsLayout));