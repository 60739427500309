import styled from "styled-components";

export const CardConditionCampaignContainer = styled.div`
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  border: 1px solid
    ${props => (props.status === "completed" ? "#40CAA9" : "#DE566B")};
  border-radius: 3px;
  display: flex;
  align-items: center;

  .card-conditition-state {
    padding: 8px;

    font-family: Museo Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    background: #ffa08022;
    border-radius: 3px;
    width: fit-content;
  }

  .card-conditition-title {
    font-family: Museo Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${props => (props.status === "completed" ? "#40CAA9" : "#DE566B")};
  }

  .card-conditition-button {
    width: 140px;
    div {
      max-height: 40px;
    }
  }
`;
