import React, { Component } from "react";
import MainTableLayout from "./main-table-layout";

export default class MainTableScreen extends Component<any, {}> {
  state = {
    rows: [],
  };

  componentDidMount() {
    const { rows } = this.props;
    this.setState({ rows });
  }

  orderBy(key: string, ascendent: boolean | undefined) {
    const { rows } = this.state;
    if (ascendent) {
      rows.sort((a: any, b: any) => {
        if (a[key].text.toLowerCase() < b[key].text.toLowerCase()) {
          return -1;
        } else if (a[key].text.toLowerCase() > b[key].text.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    } else {
      rows.sort((a: any, b: any) => {
        if (a[key].text.toLowerCase() > b[key].text.toLowerCase()) {
          return -1;
        } else if (a[key].text.toLowerCase() < b[key].text.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }
    this.setState({ rows });
  }

  render() {
    const { rows, columns, keysRow, rowLink, rowKeyLink, className, onOrder, defaultOrder, justOrderOneColumn, keysAlignRight, mediator } = this.props;
    return (
      <MainTableLayout
        className={className}
        rowLink={rowLink}
        rowKeyLink={rowKeyLink}
        keysRow={keysRow}
        rows={rows}
        columns={columns}
        orderBy={(key: string, ascendent: boolean | undefined) => onOrder ? onOrder(key, ascendent) : this.orderBy(key, ascendent)}
        defaultOrder={defaultOrder}
        justOrderOneColumn={justOrderOneColumn}
        keysAlignRight={keysAlignRight}
        mediator={mediator}
      />
    );
  }
}
