import React, { ChangeEvent } from "react";

import { TextareaWrapper } from "./Textarea.style";
import NoteText from "../../components-text/note-text";
import { ERROR_MESSAGES } from "../../../constants/errorMessages";

interface TextareaProps {
  onChange: (newValue: string) => void;
  value: string;
  className?: string;
  errorCode?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  maxLength?: number;
}

function Textarea({
  onChange,
  value,
  className,
  errorCode,
  label,
  placeholder,
  required = false,
  rows = 4,
  maxLength,
}: TextareaProps): JSX.Element {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    onChange(newValue);
  };

  return (
    <TextareaWrapper className={`${className} ${errorCode ? "error" : ""}`}>
      {label && (
        <label htmlFor="textarea-input" className={required ? "required" : ""}>
          {label}
        </label>
      )}
      <textarea
        id="textarea-input"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        rows={rows}
        required={required}
        maxLength={maxLength}
      />
      {errorCode && (
        <div className="input-box-error">
          <NoteText>
            <p>{`* ${ERROR_MESSAGES[errorCode] || ""}`}</p>
          </NoteText>
        </div>
      )}
    </TextareaWrapper>
  );
}

export default Textarea;
