import React, {Component} from 'react';
import {GuideLineContainer} from './guide-line-style';

class GuideLineLayout extends Component<any, {}> {

    readonly state:any = {}

    constructor(props:any){
        super(props);

        this.state = {
            selectedStep: props.currentStep,
        }
    }


    selectStep(item: any, index: number){
        this.setState({selectedStep: index});
    }

    getClassName(index: number){
        const { selectedStep} = this.state;
        const { currentStep, isClickable} = this.props;
        
        if(( isClickable && index === selectedStep) || (!isClickable && index === currentStep)){
            return 'active';
        }else if(( isClickable && index < selectedStep) || (!isClickable && index < currentStep)){
            return 'done';
        }
        return '';
    }

    render(){
        const { steps, isClickable, currentStep } = this.props;
        return(
            <GuideLineContainer>
                {steps.map((item:any, index:number)=>(
                    <span key={`point-${index}`} className={`dot ${this.getClassName(index)} ${isClickable ? 'clickable' : ''} `} onClick={()=> isClickable ? this.selectStep(item, index) : ''}></span>
                ))}
            </GuideLineContainer>
        )
    }
}

export default GuideLineLayout;