import styled from "styled-components";
import { colors } from "../../../../assets";

export const ModalParticipantContainer = styled.div`
    font-family: Museo Sans;
    font-style: normal;
    font-weight: 500;

    display: flex;
    color: ${colors["PR-001-100"]};
    position: fixed;
    z-index: 100;
    background: ${colors["background-modal"]};
    left: 0;
    width: 100%;
    top: 0;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    height: 100%;
    overflow: scroll;

    .modal-participant-container {
        background: #ffffff;
        border-radius: 3px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        padding: 20px 22px 40px 40px;
        max-width: 620px;
        width: 620px;
        /* max-height: 90vh;
        overflow: hidden; */
    }

    .modal-participant-aux-container {
        padding: 100px;
        height: 100%;
    }

    .modal-participant-close {
        text-align: right;
        margin-bottom: 6px;
        img {
        max-width: 18px;
        cursor: pointer;
        }
    }

    .modal-participant-subtitle {
        margin-bottom: 16px;
        margin-left: 82px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
    }

    .modal-participant-back {
      display: flex;
      margin-bottom: 16px;
      margin-left: 82px;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: ${colors['SC-001-100']};
      align-items: center;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
    }

    .modal-participant-header {
      margin-bottom: 8px;
    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      &__icon {
        display: flex;
        background: rgba(179, 202, 220, 0.3);
        width: 62px;
        min-width: 62px;
        height: 62px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;

        img {
          max-width: 20px;
        }
      }
      &__text {
        margin-left: 20px;
      }
      &__subtext {
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;

        /* Text / TX—001 / 50 — Disabled */

        color: #848FA1;
      }
    }
  }

  .modal-participant-wrapper {
    margin-left: 80px;
    margin-right: 50px;
    .modal-participant-card {
      background: rgba(179, 202, 220, 0.2);
      border-radius: 3px;
      padding: 14px;
      &:last-child {
        padding: 20px;
      }
      margin-bottom: 20px;
      .modal-participant-card-title {
        font-size: 18px;
        line-height: 26px;
        display: flex;
        justify-content: space-between;
        color: #004F8B;
        margin-bottom: 8px;
        &__text {
          margin-right: 16px;
        }
        &__info {
          background: rgb(130, 88, 101, 0.2);
          border-radius: 3px;
          color: #632E3E;
          font-weight: 300;
          font-size: 14px;
          line-height: 22px;
          padding: 5px 10px 5px 10px;
          height: fit-content;
        }
      }
      .modal-participant-card-subtitle {
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        color: #848FA1;
      }
      .modal-participant-card-tiny-title {
        font-size: 12px;
        line-height: 14px;
        padding-bottom: 6px;
        color: #3A4B68;
        border-bottom: 0.75px solid #004F8B;
      }
      .modal-participant-card-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        line-height: 20px;
        margin-top: 24px;
        margin-bottom: 12px;
        color: #091E42;
        div:last-child {
          font-weight: 300;
        }
      }
      .modal-participant-card-result-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 21px 16px 21px;
        font-weight: 500;
        font-size: 14px;
        color: #091E42;
        background: #FFFFFF;
        border-radius: 3px;
      }
    }
  }
  
  .modal-participant-advice {
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #848FA1;
    margin-bottom: 32px;
  }

  .modal-participant-view-button {
    margin-left: 80px;
    display: flex;
    justify-content: space-between;
    padding: 14px;
    background: rgb(179,202,220, 0.2);
    border-radius: 3px;
    align-items: center;
    margin-right: 54px;
    margin-bottom: 20px;
    &__text {

    }
    &__img {
      cursor: pointer;
      width: 18px;
      height: 18px;
    }
  }

  .conditions-table-container {
    margin-left: 80px;
    color: #091E42;
    background: rgb(179,202,220, 0.2);
    border-radius: 3px;
    padding: 0px 20px 20px 20px;
    margin-right: 42px;
    table {
      width: 100%;
      margin-right: 54px;
      text-align: left;
      border-spacing:0 24px; 
      tr {
        &:first-child {
          border-bottom: 0.75px solid #004F8B;
        }
        th {
          padding-bottom: 6px;
          padding-top: 20px;
          color: #848FA1;
          font-weight: 300;
          font-size: 12px;
          &:first-child {
            color: #3A4B68;
          }
        }
        td {
          max-width: 60px;
          padding-top: 24px;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          &:first-child {
            color: #091E42;
            font-weight: 500;
          }
        }
      }

      &__line {
        
      }
    }
  }
`