export const data = [
    {
        title: "Cambio de datos básicos",
        subTitle: "(requieren documento justificativo)",
        link: "/data",
        path: "/FormulariosMediadores/Formularios/CambioDatosBasicos",
        child: [
            { 
                title: "Numero de documento",
                link: "/data/doc",
                code: "D"
            },
            { 
                title: "Nombre y Apellidos de documento",
                link: "/data/nom",
                code: "I"
            },
            { 
                title: "Fecha de nacimiento (impacto en prima si se trata de asegurado)",
                link: "/data/fec",
                code: "F"
            },
            { 
                title: "Nacionalidad",
                link: "/data/nac",
                code: "N"
            },
            { 
                title: "Genero",
                link: "/data/gen",
                code: "G"
            },
        ]
    },
    {
        title: "Suplementos administrativos",
        link: "/admi",
        path: "/FormulariosMediadores/Formularios/SuplementosAdministrativos",
        child: [
            { 
                title: "Correo electrónico",
                link: "/admi/mail",
                code: "E"
            },
            { 
                title: "Teléfono móvil",
                link: "/admi/movi",
                code: "M"
            },
            { 
                title: "Teléfono fijo",
                link: "/admi/fijo",
                code: "T"
            },
            { 
                title: "IBAN Tomador",
                link: "/admi/ibto",
                code: "IT"
            },
            { 
                title: "IBAN Asegurado",
                link: "/admi/ibas",
                code: "IA"
            },
        ]
    },
    {
        title: "Suplementos económicos",
        link: "/eco",
        path: "/FormulariosMediadores/Formularios/SuplementosEconomicos",
        child: [
            { 
                title: "Forma de pago",
                link: "/eco/pago",
                code: "FP"
            },
            { 
                title: "Cambio domicilio",
                link: "/eco/domi",
                code: "DR"
            },
        ]
    },
    {
        title: "Solicitud exención de carencias",
        subTitle: "(requieren documento justificativo)",
        link: "/excarencias",
        path: "/FormulariosMediadores/Formularios/SolicitudExencionCarencias",
    },
    {
        title: "Baja de Asegurado/Póliza",
        subTitle: "(requieren documento justificativo)",
        link: "/asebaja",
        path: "/FormulariosMediadores/Formularios/BajaAsegurado",
    },
    {
        title: "Incorporación nuevo asegurado",
        subTitle: "(requieren documento justificativo)",
        link: "/asealta",
        path: "/FormulariosMediadores/Formularios/NuevoAsegurado",
    },
    {
        title: "Cambio de tomador",
        subTitle: "(requieren documento justificativo)",
        link: "/tomador",
        path: "/FormulariosMediadores/Formularios/CambioTomador",
    }
];