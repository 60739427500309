import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { NewInsuredContainer } from "../new-insured/newInsuredStepper-style";
import InputBox from "../../../../components/input-box/input-box";
import PersonalBasicData from "../components/personal-basic-data/personal-basic-data";
import { deepGet } from "../../../../utils/utility";
import RequiredDocumentsStep from "../new-insured/required-documents/required-documents-step";
import useForm from "../hooks/useForm";
import {
  buttonList,
  getPolicyData,
  initialData,
  isValidStep,
  mergeInsurancesAndTaker,
  stepsMenuProps,
  validateBasicData,
} from "./utils";
import PoliciesServices from "../../../../services/PoliciesServices";
import { getNifTomador, getPolicyNumber, getRamo } from "../utils";
import { TakerDataContainer } from "../../../hirings/new-hiring/health/health-taker/taker-data-style";
import MainButton from "../../../../components/main-button";
import { images } from "../../../../assets";
import StepsMenu from "../../../../components/steps-menu/steps-menu";
import SupplementsServices from "../../../../services/SupplementsServices";
import LeftInfoModal from "../../../../components/left-info-modal";
import BreadcrumbText from "../../../../components/components-text/breadcrumb-text";
import { RiskDetailPersonDataDto, RiskDetailPetDataDto } from "../../../clients/clients-policies/dto/detail-clients-policies-layout-dto";
import { ResultMergeInsurancesAndTaker, Taker } from "./basic-data.types";
import dateFns from 'date-fns';
import { getMediatorCompleteName } from "../../../supplements/utils";

const BasicDataForm = (props: RouteComponentProps) => {
  const [step, setStep] = useState<number>(1);
  const [isAnyCheckTrue, setIsAnyCheckTrue] = useState<boolean>(false);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [getTakerFinished, setGetTakerFinished] = useState<boolean>(false);
  const [getInsurancesFinished, setGetInsurancesFinished] = useState<boolean>(false);
  const [modal, setModal] = useState<{msg: string, status: boolean, type: string}>({ msg: "", status: false, type: "" });
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [taker, setTaker] = useState<Taker | {}>({});
  const [insurances, setInsurances] = useState<RiskDetailPersonDataDto[]>([]);
  const [pets, setPets] = useState<RiskDetailPetDataDto[]>([]);
  const [personsList, setPersonsList] = useState<ResultMergeInsurancesAndTaker[]>([]);
  const [modalError, setModalError] = useState<boolean>(false);
  const { values, errors, handleChange, setErrors, handleMultipleChanges } = useForm(
    initialData(),
    validateBasicData,
    isFirstTime
  );

  useEffect(() => {
    getData();
    mergeData();
  }, []);

  useEffect(() => {
    getTakerFinished && getInsurancesFinished && taker && insurances  && mergeData();
  }, [getTakerFinished, getInsurancesFinished]);

  useEffect(() => {
    setIsAnyCheckTrue(Object.values(values.check).some(value => value === true));
  }, [values.check]);

  const getData = async () => {
    try {
      const takerDetailResponse = await PoliciesServices.searchTakerDetail(() => {}, true, { codMediador: values.mediator, nifTomador: getNifTomador() });
      const takerDetailData = deepGet(takerDetailResponse, "takerDetail", []) || [];
      const takerDetail = takerDetailData ? takerDetailData[0] : {};
      const birthDate = deepGet(takerDetail, `fec_nacimiento_tomador`, "") || '';
      const formattedBirthDate = dateFns.format(birthDate, "DD/MM/YYYY", { locale: 'es' })
  
      handleChange("docNumber", deepGet(takerDetail, `nif_tomador`, ""));
      handleChange("birthDate", formattedBirthDate);
      handleChange("gender", deepGet(takerDetail, `sexo`, ""));
      handleChange("name", deepGet(takerDetail, "nom_tomador", ""));
      setTaker(takerDetail);
      setGetTakerFinished(true);
  
      if (!modalError) {
        const riskDetail = await PoliciesServices.searchRiskDetail(() => {}, true, [],
          { ramo: getRamo(), codMediador: values.mediator, searchType: "Poliza", data: getPolicyNumber(), }
        );
        setInsurances(deepGet(riskDetail, "riskDetail.personList", []) || []);
        setPets(deepGet(riskDetail, "riskDetail.petList", []) || []);
        setGetInsurancesFinished(true);
      }
    } catch {
      setModalError(true);
    }
  };

  const mergeData = () => {
    setPersonsList(mergeInsurancesAndTaker(insurances, taker as Taker, pets));
  };

  const goToNextStep = () => {
    const { errors, hasValidation } = validateBasicData(values);
    if (isValidStep(hasValidation)) {
      setErrors({})
      setStep((prevValue: number) => prevValue + 1);
      setIsFirstTime(true);
    } else {
      setIsFirstTime(false);
      setErrors(errors);
    }
  };

  const goToPreviousStep = () => {
    step === 1 && props.history.push(`/suplementos/${getPolicyNumber()}`);
    setErrors({});
    setStep((prevValue: number) => prevValue - 1);
  };

  const handleSubmit = async () => {
    const response = await SupplementsServices.sendBasicDataModificationsEmail( values );

    if (response.data.status) { setModal({ msg: "Los datos han sido enviado correctamente", status: true, type: "success", });
    } else {
      setModal({ msg: response.data.msg, status: true, type: "error" });
    }
  };

  const validateForm = () => {
    const { errors, hasValidation } = validateBasicData(values);
    if (values.type === 'MASCOTA' || hasValidation.documents) {
      setConfirmationModal(true);
    } else {
      setErrors(errors);
    }
  };

  const redirect = () => {
    setModalError(false);
    props.history.push(
      `/polizas-clientes/detalle/${values.mediator}/${getPolicyNumber()}/${deepGet(getPolicyData(), "order", "")}`,
      {
        data: {
          colectivo: {
            text: `${getPolicyData().numeroColectivo}|${getPolicyData().producto}`,
          },
          nif_tomador: {
            text: getPolicyData().nifTomador,
          },
        },
      }
    );
  };

  return (
    <NewInsuredContainer>
      <div className="subTitle">
        <BreadcrumbText>
          {"NUEVO SUPLEMENTO - CAMBIO DATOS BÁSICOS"}
        </BreadcrumbText>
      </div>
      <div className="personal-data-row form-width container-background">
        <div className="personal-data-item col-50">
          <div className="inputBox">
            <InputBox
              placeholder="Mediador"
              required={true}
              disabled={true}
              value={getMediatorCompleteName()}
              onChange={() => {}}
              type={"letters"}
            />
          </div>
        </div>
        <div className="personal-data-item col-50">
          <div className="inputBox">
            <InputBox
              placeholder="Datos de Póliza a modificar"
              required={true}
              disabled={true}
              value={values.policy}
              onChange={() => {}}
              type={"letters"}
            />
          </div>
        </div>
      </div>
      <div className="form-width">
        {step === 1 && (
          <PersonalBasicData
            typeForm={deepGet(props, "match.params.type", "") || ""}
            formData={{ values, errors, handleChange }}
            step={step}
            personsList={personsList}
            handleMultipleChanges={handleMultipleChanges}
          />
        )}
        {step === 2 && (
          <RequiredDocumentsStep
            values={values}
            errors={errors}
            handleChange={handleChange}
            hideTitle
            hideInsurance
            docTypeDisabled
            required
          />
        )}
        <TakerDataContainer>
          <div className="colSin-100 topMargin20 bottomMargin20">
            <div className="personal-data-buttons">
              <div className="personal-data-buttons__item">
                <MainButton
                  withTab={true}
                  icon={true}
                  type={"white"}
                  iconImg={images.IconArrowLeftBlue}
                  disabled={false}
                  text="Atrás"
                  onClick={goToPreviousStep}
                />
              </div>
              <div className="personal-data-buttons__item">
                {step === 1 ? (
                  <MainButton
                    withTab={true}
                    text="Siguiente"
                    disabled={step !== 1 || !isAnyCheckTrue}
                    onClick={goToNextStep}
                  />
                ) : (
                  <MainButton
                    withTab={true}
                    text="Enviar"
                    onClick={() => validateForm()}
                  />
                )}
              </div>
            </div>
          </div>
          <StepsMenu
            policyData={stepsMenuProps.policyData}
            buttonFunction={setStep}
            actualStep={step}
            buttonList={buttonList}
          />

          <LeftInfoModal
            img={images.IconCheckGreen2}
            mainTitle={"Aviso"}
            mainText={"Se va a enviar una solicitud de cambio de datos básicos"}
            secondaryText={"¿Desea Continuar?"}
            noSecondaryButton={false}
            buttonText={"Si"}
            closeButtonText={"NO"}
            active={confirmationModal}
            onClickAccept={handleSubmit}
            close={() => setConfirmationModal(false)}
            className={"info"}
          />
          <LeftInfoModal
            img={ modal.type === "error" ? images.WarningError : images.IconCheckGreen2 }
            mainTitle={modal.type === "error" ? "Error" : "Aviso"}
            mainText={modal.msg}
            noSecondaryButton={true}
            buttonText="Continuar"
            active={modal.status}
            onClickAccept={redirect}
            close={redirect}
            className={modal.type === "error" ? "error" : "info"}
          />
        </TakerDataContainer>
      </div>
      <LeftInfoModal
        img={ images.WarningError }
        mainTitle={"error"}
        mainText={'Ha habido un error al cargar datos de los asegurados'}
        noSecondaryButton={true}
        buttonText="Volver"
        active={modalError}
        onClickAccept={redirect}
        close={redirect}
        className={"error"}
      />
    </NewInsuredContainer>
  );
};

export default withRouter(BasicDataForm);
