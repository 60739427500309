import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import dateFns from "date-fns";
import moment from "moment";
import { DataContainer } from "./data-style";
import InputBox from "../../../components/input-box/input-box";
import DicotomicButton from "../../../components/dicotomic-button";
import SelectBox from "../../../components/select-box/select-box";
import CalendarBox from "../../../components/calendar-box/calendar-box";
import MainButton from "../../../components/main-button";
import PhoneBox from "../../../components/phone-box/phone-box";
import WebText from "../../../components/components-text/web-text";
import Body02Text from "../../../components/components-text/body-02-text";
import DicotomicCheckbox from "../../../components/dicotomic-checkbox/dicotomic-checkbox";
import SEX from "../../../constants/sex.json";
import WOMAN_TYPES from "../../../constants/typeWoman.json";
import DOC_TYPES from "../../../constants/doc_types";
import GuideLine from "../../../components/guide-line";
import ConstantsServices from "../../../services/ConstantsServices";
import { withGeneral } from "../../../context/mixin/with-general";
import AddressServices from "../../../services/AddressServices";
import { CIVIL_STATUS } from "../../../constants/civilStatus";
import { ValueForm } from "../../../utils/interfaces";
import { RISK_ACTIVITY } from "../../../constants";
import SearchBox from "../../../components/search-box-mediadores/search-box";
import { withPolicy } from "../../../context/mixin/with-policy";
import { currencyFormat, chunkArray } from "../../../utils";
import CoveragesServices from "../../../services/CoveragesServices";
import { capitalValidation, formatCoverages } from "../../../utils/coverages";
import InfoModal from "../../../components/info-modal";
import { images } from "../../../assets";
import { withCoverages } from "../../../context/mixin/with-coverages";
import CheckboxItem from "../../../components/checkbox-item";
import { isValidDocumentID } from "../../../utils/validation";
import { ERROR_MESSAGES } from "../../../constants/errorMessages";
import { SimpleSelectboxInterface } from "../../simulations/new-simulation/choose-policy/new-simulation.dto";
import { IMediator } from "../../../models/mediator-model";
import { getRelatedMediatorsCodes } from "../../../utils/mediatorCode";
import HocelotServices from "../../../services/HocelotServices";
import { withDynamicProducts } from "../../../context/mixin/with-dynamic-products";
import { AREA_NAMES, PRODUCT_TYPE, getProductTypeFromURL } from "../../../constants/policyType";
import { formatter } from "../../../utils/utility";
import { CAPITAL_OPTIONS_AV04, CAPITAL_OPTIONS_AV05, CAPITAL_OPTIONS_AV06, CAPITAL_OPTIONS_RC } from "../../../constants/typePets";
import AddressItem from "./address-item";

interface DataProps extends RouteComponentProps {
  setGuideLine: any;
  setLoading: Function;
  policy: any;
  nationality?: boolean;
  civilStatus?: boolean;
  address2?: boolean;
  insured?: boolean;
  noAddress?: boolean;
  noProfession?: boolean;
  noRisk?: boolean;
  noRequired?: boolean;
  capital?: any;
  hiring?: boolean;
  changeState?: boolean;
  payTypes?: any[];
  newStep?: () => void;
  goBack?: () => void;
  fromSimulation?: boolean;
  showInfo?: boolean;
  goToDetail?: number;
  linkDetail?: string;
  coverages: any;
  mediator: IMediator;
  selectedMediatorCode: string;
  checkProductArea: Function;
  productsByAreaName: any;
}

interface DataState {
  search: string;
  modal: string;
  showModal: boolean;
  infoModalText: string;
  changeState: boolean;
  isEdit: any;
  pathname: string;
  searchUrl: string;
  selectedWomanType: string;
  countries: any[];
  countriesFiltered: any[];
  professions: any[];
  professionsFiltered: any[];
  activities: any[];
  activitiesFiltered: any[];
  provinces: any[];
  provincesList: any[];
  provincesFiltered: any[];
  loadSearchInput: boolean;
  cities: any[];
  citiesList: any[];
  citiesFiltered: any[];
  postalCodes: any[];
  postalCodesList: any[];
  postalCodesFiltered: any[];
  streets: any[];
  streetsList: any[];
  streetsFiltered: any[];
  coverages: any;
  deathServicesDropdown: any[];
  deathServicesDescriptions: any;
  prevSearchInputValue: string;
  prevSearchInputField: string;
  relatedMediatorCodes: Array<SimpleSelectboxInterface>;
  mediatorCodeSelected: string,
  formData?: {
    useTakerAddress: boolean;
    useTakerData: boolean;
    isReadOnly: boolean;
    contact: {
      name: ValueForm;
      lastname1: ValueForm;
      lastname2: ValueForm;
      birthdate: ValueForm;
      docType: ValueForm;
      docNumber: ValueForm;
      sex: ValueForm;
      civilStatus: ValueForm;
      nationality: ValueForm;
    };
    profession: {
      occupation: ValueForm;
      riskActivity: ValueForm;
      activityType: ValueForm;
    };
    address: {
      street: ValueForm;
      streetNumber: ValueForm;
      stairs: ValueForm;
      floorNumber: ValueForm;
      door: ValueForm;
      province: ValueForm;
      city: ValueForm;
      postalCode: ValueForm;
      normalizaDireciones: ValueForm;
    };
    info: {
      landlinePhoneNumber: ValueForm;
      mobile: ValueForm;
      email: ValueForm;
    };
    date: {
      numPets: ValueForm;
      iban: ValueForm;
      payType: ValueForm;
      starterDay: ValueForm;
      capital: ValueForm;
    };
    coverages: any;
    address2: {
      numberInsured: ValueForm;
      streetType: ValueForm;
      street: ValueForm;
      streetNumber: ValueForm;
      stairs: ValueForm;
      floorNumber: ValueForm;
      door: ValueForm;
      province: ValueForm;
      city: ValueForm;
      postalCode: ValueForm;
      country: ValueForm;
      [key: string]: ValueForm;
    };
    paymentType: {
      payment: ValueForm;
    };
    [key: string]: any;
  };
  capitalCoverages?: any
}

class DataLayout extends React.Component<DataProps, DataState> {
  private timeout: any = null;
  private _searchWaiter: any = null;
  private mainContainer: any = null;
  contentWrapperRef: any = null;
  inputIBAN: any = null;

  constructor(props: DataProps) {
    super(props);

    this.state = {
      activities: [],
      activitiesFiltered: [],
      changeState: false,
      cities: [],
      citiesFiltered: [],
      citiesList: [],
      countries: [],
      countriesFiltered: [],
      coverages: null,
      deathServicesDescriptions: {},
      deathServicesDropdown: [],
      isEdit: {},
      loadSearchInput: false,
      modal: "",
      showModal: false,
      infoModalText: "",
      pathname: "",
      postalCodes: [],
      postalCodesFiltered: [],
      postalCodesList: [],
      prevSearchInputField: "",
      prevSearchInputValue: "",
      professions: [],
      professionsFiltered: [],
      provinces: [],
      provincesFiltered: [],
      provincesList: [],
      search: "",
      searchUrl: "",
      selectedWomanType: "C",
      streets: [],
      streetsFiltered: [],
      streetsList: [],
      relatedMediatorCodes: getRelatedMediatorsCodes(this.props.mediator),
      mediatorCodeSelected: props.selectedMediatorCode ? props.selectedMediatorCode : props.mediator.mediatorCode,
    };
    this.timeout = 0;
    this.mainContainer = React.createRef();
    this.contentWrapperRef = React.createRef();
    this.inputIBAN = React.createRef();
  }

  componentDidMount = async () => {
    const urlString = window.location.href;
    const isDeath = urlString.indexOf("decesos") > -1;
    const { setLoading, coverages, history, policy, noProfession, checkProductArea, productsByAreaName} = this.props;

    // WE CHECK IF THE USER DELETED MORE THAN 2 INSUREDS, AND WANTED TO GO BACK
    const { location: { search, pathname } } = history;
    if (search && search !== "" && pathname.indexOf("asegurado") > -1) {
      const insuredNumber = search.match(/index=(\d+)/i);
      if (insuredNumber && insuredNumber[1] > policy.data.insureds.length) {
        history.push(`${pathname}?index=${policy.data.insureds.length - 1}`);
      }
    }
    //

    setTimeout(() => {
      if (
        this.contentWrapperRef &&
        this.contentWrapperRef.current &&
        this.contentWrapperRef.current.scrollTo
      ) {
        this.contentWrapperRef.current.scrollTo(0, 0);
      }
    }, 100);

    const countries: any[] = await ConstantsServices.getCountries({
      setLoading: setLoading,
    });

    let activities: any[] = []
    if(Object.keys(policy.product).length!==0 && Object.keys(productsByAreaName).length!==0){
      const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
      if(!noProfession){
        activities = await ConstantsServices.getActivities({
          setLoading: setLoading,
        });

        this.getProfessions()
      }
      if(!(!!this.props.hiring) || (this.props.hiring && !isPetArea)){
        this.getCapitalCoverages()
      }
      
      this.setState({ coverages: formatCoverages(coverages[policy.product.products_coverageName]) });
    }

    if (
      isDeath &&
      ((history.location.state && history.location.state.simulationId) ||
        (policy.data && policy.data.insureds && policy.data.insureds[0]) ||
        !!this.props.hiring)
    ) {
      const postalCode =
        (history.location.state &&
          history.location.state.data &&
          history.location.state.data.insureds &&
          history.location.state.data.insureds[0].address.postalCode) ||
        (policy.data.insureds[0] &&
          policy.data.insureds[0].address.postalCode.value.value);
      if (postalCode) {
        this.getDeathServices(postalCode);
      }
    }

    let mediatorCode: string = this.state.mediatorCodeSelected;
    if (history.location.state)
      mediatorCode = history.location.state.selectedMediator ?
        history.location.state.selectedMediator :
        this.state.mediatorCodeSelected;

    this.setState(
      {
        countries,
        activities,
        mediatorCodeSelected: mediatorCode,
      },
      () => this.setFormData()
    );
  };
  private _formatCoverages = (coverages: any) => {
    let coveragesCapitales = [];
    for (let i = 0; i < coverages.length; i++) {
      if (coverages[i].capitales) {
        coveragesCapitales.push(coverages[i])
      }
    }
    return coveragesCapitales;

  }
  getCapitalCoverages = async() => {
    const { policy, coverages } = this.props
    const { selectedWomanType } = this.state

    const productCoverageName= policy.product.products_coverageName
    const productInternalName= policy.product.products_internalName
    let capitalCoverages: any = {};

    let cov: any
    if (coverages && coverages[productCoverageName]) {
      cov = this._formatCoverages(coverages[productCoverageName])
    } else {
      const resp: any = await CoveragesServices.getCoverages({
        setLoading: this.props.setLoading,
        loader: true,
        coverageType: productCoverageName,
      })
      cov = this._formatCoverages(resp.data)
    }
    // Obtener del contexto si existen si no, hacer una llamada a obtener coberturas
    if (cov) {
      if(productInternalName === PRODUCT_TYPE.WOMAN){
        capitalCoverages = cov.filter((cov: any) =>
          cov.optionCode === selectedWomanType 
        )
      }else{
        capitalCoverages = cov
      }
      capitalCoverages = capitalCoverages.reduce((accumulator:any, currentValue:any) => ({ ...accumulator, [currentValue.code]: currentValue}), {})
    }
    
    this.setState({ capitalCoverages }
    );
  }

  getProfessions = async() => {
    const { setLoading, policy } = this.props;

    const professions: any[] = await ConstantsServices.getProfessions({
      setLoading: setLoading,
    });
    
    const productArea = policy.product.products_areaName
    const uniqueProfessions = professions.map((prof) => {
      if (prof.product === "ALL" || prof.product === productArea) return prof.value;
    });

    const addedProducts: string[] = [];
    const filteredProfessions = professions.filter((prof) => {
      const added = addedProducts.includes(prof.value);
      addedProducts.push(prof.value);
      return uniqueProfessions.includes(prof.value) && !added;
    });

    this.setState({ professions: filteredProfessions });
  }

  static getDerivedStateFromProps(nextProps: DataProps, prevState: DataState) {
    if (nextProps.history.location.pathname !== prevState.pathname) {
      return { pathname: nextProps.history.location.pathname };
    } else if (nextProps.history.location.search !== prevState.searchUrl) {
      return { searchUrl: nextProps.history.location.search };
    } else if (nextProps.changeState !== prevState.changeState) {
      return { changeState: nextProps.changeState };
    } else return null;
  }

  prevIndex = "-1";
  async componentDidUpdate(prevProps: DataProps, prevState: DataState) {
    const {
      coverages,
      insured,
      showInfo,
      fromSimulation,
      goToDetail,
      mediator,
      policy,
      noProfession,
      setLoading,
      checkProductArea,
      hiring
    } = this.props;
    let { mediatorCodeSelected, relatedMediatorCodes, formData} = this.state;
    const urlString = window.location.href;
    const url = new URL(urlString);
    const index = url.searchParams.get("index");
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)

    let activities: any[] = []
    if(Object.keys(prevProps.policy.product).length===0 && Object.keys(policy.product).length!==0){
      if(!noProfession){
        activities = await ConstantsServices.getActivities({
          setLoading: setLoading,
        });
        this.setState({ activities })

        this.getProfessions()
      }

      if(!hiring || (hiring && !isPetArea)){
        this.getCapitalCoverages()
      }

    }

    if(Object.keys(prevProps.policy.product).length===0 && Object.keys(policy.product).length!==0 && Object.keys(coverages).length!==0 ||
    Object.keys(prevProps.coverages).length===0 && Object.keys(coverages).length!==0 && Object.keys(policy.product).length!==0){
      this.setState(
        { 
          coverages: formatCoverages(coverages[policy.product.products_coverageName]) }, 
          () => {this.setFormData()
        }
      );
    }

    if(prevProps.coverages !== this.state.coverages && this.state.coverages != null && formData && !formData.hasOwnProperty('coverages')){
      if(insured || (isPetArea && !hiring)){
        formData['coverages'] = this.getCoverages(this.state.coverages)
        this.setState({formData})
      }
    }

    

    if (relatedMediatorCodes.length === 0 && mediatorCodeSelected === undefined) {
      relatedMediatorCodes = getRelatedMediatorsCodes(this.props.mediator);
      mediatorCodeSelected = mediator.mediatorCode;
      relatedMediatorCodes.length > 0 && mediatorCodeSelected !== undefined &&
        this.setState({ relatedMediatorCodes, mediatorCodeSelected });
    }

    if (
      !showInfo &&
      fromSimulation &&
      showInfo !== prevProps.showInfo &&
      this.contentWrapperRef &&
      this.contentWrapperRef.current &&
      this.contentWrapperRef.current.scrollTo &&
      this.inputIBAN &&
      this.inputIBAN.current
    ) {
      const inputIBAN = this.inputIBAN.current.querySelector("input");
      if (inputIBAN) {
        inputIBAN.focus();
      }
      this.contentWrapperRef.current.scrollTo(0, 2000);
    }
    if (insured !== prevProps.insured || (index && index !== this.prevIndex)) {
      this.prevIndex = index || "-1";
      this.setState(
        {
          provinces: [],
          cities: [],
          postalCodes: [],
          streets: [],
        },
        () => {
          this.setFormData();
          this.contentWrapperRef &&
            this.contentWrapperRef.current &&
            this.contentWrapperRef.current.scrollTo &&
            this.contentWrapperRef.current.scrollTo(0, 0);
        }
      );
    }
    

    if (goToDetail !== undefined && prevProps.goToDetail !== goToDetail) {
      this.toValidate(goToDetail);
    }
  }

  goToDetail = (index: number) => {
    const { linkDetail, history, policy } = this.props;
    const baseUrl = !!this.props.hiring ? policy.product.products_link : policy.product.products_linkSimulacion
    const urlProduct = getProductTypeFromURL(baseUrl)

    history.push(
      linkDetail +
      urlProduct +
      "/" +
      (index === -1 ? "tomador" : index === -2? "asegurado-beneficiario" : `asegurado?index=${index}`)
    );
  };

  setFormData() {
    let formData;
    const aseguradoRCURL:string = "/contratacion/nueva/mascotas/asegurado-beneficiario"
    if(this.props.history.location.pathname === aseguradoRCURL && 
      this.props.policy.data.beneficiary &&
      Object.entries(this.props.policy.data.beneficiary).length===0){
        formData = this.getFormData(true)
      }
    else{
      formData = this.getFormData();
    }
    this.setState({ formData });
  }

  checkSameTakerAddres(beneficiaryPet?:boolean) {
    const { policy, history } = this.props;
    const index =
      history.location.search !== ""
        ? parseInt(history.location.search.split("=")[1], 10)
        : 0;
    if (
      !policy.data.taker ||
      !policy.data.taker.address ||
      (!policy.data.insureds[index] ||
      !policy.data.insureds[index].address && !beneficiaryPet)
    )
      return false;
    let isSame = true;
    Object.entries(policy.data.taker.address).forEach((entry: any[]) => {
      const key = entry[0];
      const takerValue = entry[1].value.value
        ? entry[1].value.value
        : entry[1].value;
      if( beneficiaryPet ){
        const beneficiaryValue= policy.data.beneficiary && policy.data.beneficiary.address[key].value.value
        ? policy.data.beneficiary.address[key].value.value
        : policy.data.beneficiary.address[key].value;

        if (takerValue !== beneficiaryValue) isSame = false
      }
      else{
        const insuredValue = policy.data.insureds[index].address[key].value.value
        ? policy.data.insureds[index].address[key].value.value
        : policy.data.insureds[index].address[key].value;
      
        if (takerValue !== insuredValue) isSame = false;
      }
    });
    return isSame;
  }
  addLabel(data: any) {
    if (data.address && data.address.street && data.address.street.value && data.address.street.value.extra)
      data.address.street.value.label = `${data.address.street.value.extra.street.code} ${data.address.street.value.value}`
    return data
  }
  getFormData(clean = false) {
    const { props } = this;
    if (!this.state) return {};
    const { countries } = this.state;
    const urlString = window.location.href;
    const index =
      props.history.location.search !== ""
        ? parseInt(props.history.location.search.split("=")[1], 10)
        : null;
    const isPets = urlString.indexOf("mascotas") > -1;
    const isDeath = urlString.indexOf("decesos") > -1;
    const isSimulation = !(!!props.hiring)
    const isHiring = !!props.hiring;
    const isDeathTakerSimulation = this.state.pathname === "/simulacion/nueva/decesos/tomador";
    const isDeathSimulation = isDeath && isSimulation;
    const isDeathFirstStep = isDeath && urlString.includes("tomador");
    const isInsuredSimulationDeath = isDeathSimulation && urlString.indexOf("asegurado") > -1;
    let formData: any = {};
    const aseguradoRCURL = "/contratacion/nueva/mascotas/asegurado-beneficiario"

    if (props.insured) {
      if (Object.keys(props.policy.data.taker).length === 0) {
        props.history.push(
          props.history.location.pathname.replace("asegurado", "tomador")
        );
      } else if (index !== null && props.policy.data.insureds[index] &&
        props.history.location.pathname !== aseguradoRCURL) {
        formData = JSON.parse(
          JSON.stringify(props.policy.data.insureds[index])
        );
        formData.useTakerAddress = this.checkSameTakerAddres();
      } else if (
        props.policy.data.insureds &&
        props.policy.data.insureds.length > 0 &&
        !isDeath &&
        props.history.location.pathname !== aseguradoRCURL
      ) {
        formData = JSON.parse(JSON.stringify(props.policy.data.insureds[0]));
        formData.useTakerAddress = this.checkSameTakerAddres();
      } else if (props.history.location.pathname === aseguradoRCURL &&
        props.policy.data.beneficiary &&
        Object.entries(props.policy.data.beneficiary).length>0) {
          formData = JSON.parse(JSON.stringify(props.policy.data.beneficiary));
          formData.useTakerAddress = this.checkSameTakerAddres(true);
      }

      if (formData.foreignAddress && formData.foreignAddress.country) {
        formData.foreignAddress.country.value =
          countries.find(
            (country) => country.value === formData.foreignAddress.country.value
          ) ||
          formData.foreignAddress.country.value ||
          "";
      }
    } else {
      formData = this.addLabel(JSON.parse(JSON.stringify(props.policy.data.taker)));
      if (props.policy.data.isReadOnly) formData.isReadOnly = props.policy.data.isReadOnly;

      // Añadimos al formdata
      if (formData.address && formData.address.postalCode && isDeath) {
        this.getDeathServices(formData.address.postalCode.value.value || formData.address.postalCode.value);
      }
      if(formData.contact && formData.contact.nationality && formData.contact.nationality.value && formData.contact.nationality.value.value === "ESPA"){
        if(formData.contact.lastname2){
          formData.contact.lastname2.required = true;
        }
      }
    }

    let isRequiredEmail = false;

    if (isPets) {
      isRequiredEmail = true;
    } else {
      isRequiredEmail = isHiring
    }

    const isSpanishNationality =
    formData.contact &&
    formData.contact.nationality &&
    formData.contact.nationality.value &&
    formData.contact.nationality.value.value === "ESPA"

    let form: any;

    if (clean || !formData || Object.keys(formData).length === 0) {
      form = {
        useTakerAddress: false,
        useTakerData: false,
        isReadOnly: false,
        hiringDeath: {
          checkedConditions: {
            value: "",
            error: false,
            errorCode: "",
            required: isDeath && isHiring,
          },
        },
        foreignAddress: {
          country: {
            value: "",
            error: false,
            errorCode: "",
            required: isDeath,
          },
          address: {
            value: "",
            error: false,
            errorCode: "",
            required: isDeath,
          },
        },
        contact: {
          name: {
            value: "",
            error: false,
            errorCode: "",
            required: true && !isInsuredSimulationDeath,
          },
          lastname1: {
            value: "",
            error: false,
            errorCode: "",
            required: true && !isInsuredSimulationDeath,
          },
          lastname2: {
            value: "",
            error: false,
            errorCode: "",
            required: isSpanishNationality,
          },
          birthdate: {
            value: "",
            error: false,
            errorCode: "",
            required: !isPets || isHiring,
          },
          docType: {
            value: "",
            error: false,
            errorCode: "",
            required: !isSimulation,
          },
          docNumber: {
            value: "",
            error: false,
            errorCode: "",
            required: !isSimulation,
          },
          sex: {
            value: "",
            error: false,
            errorCode: "",
            required: (!isPets || isHiring) && !isDeathSimulation,
          },
          civilStatus: {
            value: "",
            error: false,
            errorCode: "",
            required: false,
          },
          nationality: {
            value: "",
            error: false,
            errorCode: "",
            required: false,
          },
        },
        profession: {
          occupation: {
            value: "",
            error: false,
            errorCode: "",
            required: !props.noProfession,
          },
          riskActivity: {
            value: "N",
            error: false,
            errorCode: "",
            required: false,
          },
          activityType: {
            value: "",
            error: false,
            errorCode: "",
            required: false,
          },
        },
        address: {
          street: {
            value: "",
            error: false,
            errorCode: "",
            required: !props.noAddress && !isInsuredSimulationDeath,
          },
          streetNumber: {
            value: "",
            error: false,
            errorCode: "",
            required: !props.noAddress && !isInsuredSimulationDeath,
          },
          stairs: {
            value: "",
            error: false,
            errorCode: "",
            required: false,
          },
          floorNumber: {
            value: "",
            error: false,
            errorCode: "",
            required: false,
          },
          door: {
            value: "",
            error: false,
            errorCode: "",
            required: false,
          },
          province: {
            value: "",
            error: false,
            errorCode: "",
            required: !props.noAddress && !isInsuredSimulationDeath,
          },
          city: {
            value: "",
            error: false,
            errorCode: "",
            required: !props.noAddress && !isInsuredSimulationDeath,
          },
          postalCode: {
            value: "",
            error: false,
            errorCode: "",
            required: !props.noAddress || isDeathTakerSimulation,
          },
          normalizaDireciones: {
            value: "S",
            error: false,
            errorCode: "",
            required: false,
          }
        },
        info: {
          landlinePhoneNumber: {
            value: "",
            error: false,
            errorCode: "",
            required: false,
          },
          mobile: {
            value: "",
            error: false,
            errorCode: "",
            required: isPets || isHiring,
          },
          email: {
            value: "",
            error: false,
            errorCode: "",
            required: isRequiredEmail,
          },
        },
        date: {
          iban: {
            value: "",
            error: false,
            errorCode: "",
            required: (isHiring && !props.insured) || false,
          },
          numPets: {
            value: "",
            error: false,
            errorCode: "",
            required: isPets && !isHiring,
          },
          payType: {
            value: "",
            error: false,
            errorCode: "",
            required: (isHiring && !props.insured) || false,
          },
          starterDay: {
            value:
              props.policy.data &&
                props.policy.data.taker &&
                props.policy.data.taker.date &&
                props.policy.data.taker.date.starterDay
                ? props.policy.data.taker.date.starterDay.value
                : this.getInitialCalendarValue(props.policy.product),
            error: false,
            errorCode: "",
            required: true,
          },
          capital: {
            value: "",
            error: false,
            errorCode: "",
            required: !!props.capital,
            min: props.capital ? props.capital.min : null,
            max: props.capital ? props.capital.max : null,
            options: props.capital ? props.capital.options : null,
          },
        },
        paymentType: {
          payment: {
            value: "",
            error: false,
            errorCode: "",
            required: isPets && !isHiring,
          },
        },
        address2: {
          numberInsured: {
            value: "",
            error: false,
            errorCode: "",
            required: false,
            disabled: true,
          },
          streetType: {
            value: "",
            error: false,
            errorCode: "",
            required: props.address2 || false,
            disabled: true,
          },
          street: {
            value: "",
            error: false,
            errorCode: "",
            required: props.address2 || false,
            disabled: true,
          },
          streetNumber: {
            value: "",
            error: false,
            errorCode: "",
            required: props.address2 || false,
            disabled: true,
          },
          stairs: {
            value: "",
            error: false,
            errorCode: "",
            required: props.address2 || false,
            disabled: true,
          },
          floorNumber: {
            value: "",
            error: false,
            errorCode: "",
            required: props.address2 || false,
            disabled: true,
          },
          door: {
            value: "",
            error: false,
            errorCode: "",
            required: props.address2 || false,
            disabled: true,
          },
          province: {
            value: "",
            error: false,
            errorCode: "",
            required: props.address2 || false,
            disabled: true,
          },
          city: {
            value: "",
            error: false,
            errorCode: "",
            required: props.address2 || false,
            disabled: true,
          },
          postalCode: {
            value: "",
            error: false,
            errorCode: "",
            required: props.address2 || false,
            disabled: true,
          },
          country: {
            value: "",
            error: false,
            errorCode: "",
            required: props.address2 || false,
            disabled: true,
          },
        },
      };

      if (isDeathFirstStep) {
        form.serviceCodeBlock = {
          serviceCode: {
            value: "",
            error: false,
            errorCode: "",
            required: false,
            disabled: true,
          },
        };
        form.payment = {
          payType: {
            value: "",
            error: false,
            errorCode: "",
            required: isDeathTakerSimulation,
            disabled: true,
          },
        };
      }
    } else {
      // if (!!formData && !formData.hiringDeath) {
      //   formData.hiringDeath = {
      //     checkedConditions: {
      //       value: "",
      //       error: false,
      //       errorCode: "",
      //       required:
      //         props.history.location.pathname ===
      //         "/contratacion/nueva/decesos/tomador",
      //     },
      //   };
      // }
      
      if (
        !!formData &&
        props.history.location.pathname ===
        "/contratacion/nueva/decesos/tomador"
      ) {
        if (!formData.hiringDeath || !formData.hiringDeath.checkedConditions) {
          formData.hiringDeath = {
            checkedConditions: {
              value: "",
              error: false,
              errorCode: "",
              required: true,
            },
          };
        } else if (
          formData.hiringDeath &&
          formData.hiringDeath.checkedConditions &&
          !formData.hiringDeath.checkedConditions.required
        ) {
          formData.hiringDeath.checkedConditions.required = true;
        }
      }
      
      form = formData;
    }

    if (isHiring && form && form.info && form.info.mobile) {
      form.info.mobile = {
        ...form.info.mobile,
        required: true,
      };
    }
    return form;
  }

  getInitialCalendarValue = (product: any) => {
    let value;
    switch (product.products_areaName) {
      case AREA_NAMES.PET:
        value = dateFns.format(moment().add(1, "day").toDate(), "DD/MM/YYYY");
        break;
      default:
        value = dateFns.format(new Date(), "DD/MM/YYYY");
        break;
    }
    return value;
  };

  getMinCalendarValue = (product: any): string => {
    let value;
    switch (product.products_areaName) {
      case AREA_NAMES.PET:
        value = moment().toISOString(true);
        break;
      default:
        value = moment().subtract(1, "day").toISOString(true);
        break;
    }
    return value;
  };

  getMaxCalendarValue = (product: any): string => {
    let value;
    switch (product.products_areaName) {
      case AREA_NAMES.LIFE:
        value =  moment().add(90, "day").toISOString();
        break;
      case AREA_NAMES.DEATH:
        value = moment().add(60, "day").toISOString();
        break;
      case AREA_NAMES.PET:
        value = moment().add(60, "day").toISOString();
        break;
      default:
        value = moment().add(1, "months").toISOString();
        break;
    }
    return value;
  };

  getCoverages(coverages: any) {
    const { capitalCoverages } = this.state
    const urlString = window.location.href;
    const isPets = urlString.indexOf("mascotas") > -1;

    if (!coverages) return null;

    const coveragesObj: any = {};

    Object.keys(coverages).forEach((k) => {
      let capitalOptionsSelected;
      for (let i = 0; i < coverages[k].items.length; i++) {
        switch(coverages[k].items[i].code){
          case "RC01":
            capitalOptionsSelected = CAPITAL_OPTIONS_RC
            break;
          case "AV04":
            capitalOptionsSelected = CAPITAL_OPTIONS_AV04
            break;
          case "AV05":
            capitalOptionsSelected = CAPITAL_OPTIONS_AV05
            break;
          case "AV06":
            capitalOptionsSelected = CAPITAL_OPTIONS_AV06
            break;
        }
        coveragesObj[coverages[k].items[i].code] = {
          value:
            coverages[k].items[i].defaultValue !== undefined
              ? coverages[k].items[i].defaultValue
              : k === "main",
          error: false,
          errorCode: "",
          required: true,
          name: coverages[k].items[i].name,
          complementary: k === "complementary",
          optional: k === "optional",
          notShow: k === "notShow",
          optionCode: coverages[k].items[i].optionCode,
        };
        if (capitalCoverages && Object.keys(capitalCoverages).length!==0 && capitalCoverages.hasOwnProperty(coverages[k].items[i].code) && capitalCoverages[coverages[k].items[i].code].capitales) {
          coveragesObj[coverages[k].items[i].code] = {
            ...coveragesObj[coverages[k].items[i].code],
            options: this._formatCapitales(capitalCoverages[coverages[k].items[i].code].capitales),
          };
        }
        if(isPets &&  coveragesObj[coverages[k].items[i].code].complementary && (!capitalCoverages || Object.keys(capitalCoverages).length===0)){
          coveragesObj[coverages[k].items[i].code] = {
            ...coveragesObj[coverages[k].items[i].code],
            options: capitalOptionsSelected,
          };
        }
        if(coverages[k].items[i].capital){
          coveragesObj[coverages[k].items[i].code] = {
            ...coveragesObj[coverages[k].items[i].code],
            min: coverages[k].items[i].capital.min,
            max: coverages[k].items[i].capital.max,
          };
        }
        if(coverages[k].items[i].otherInsurance && coverages[k].items[i].otherInsurance!==null){
          coveragesObj[coverages[k].items[i].code] = {
            ...coveragesObj[coverages[k].items[i].code],
            otherInsurance: coverages[k].items[i].otherInsurance
          }
        }
        else{
          coveragesObj[coverages[k].items[i].code] = {
            ...coveragesObj[coverages[k].items[i].code],
            otherInsurance:null
          }
        }
        if(coveragesObj[coverages[k].items[i].code].options || coveragesObj[coverages[k].items[i].code].max > 0){
          coveragesObj[coverages[k].items[i].code] = {
            ...coveragesObj[coverages[k].items[i].code],
            capital: {
              value: "",
              error: false,
              errorCode: "",
              required: coveragesObj[coverages[k].items[i].code].value,
            },
          };
        }
        if (coverages[k].items[i].requiredCoverages) {
          coveragesObj[coverages[k].items[i].code].requiredCoverages =
            coverages[k].items[i].requiredCoverages;
        }
        if (coverages[k].items[i].dependantCoverages) {
          coveragesObj[coverages[k].items[i].code].dependantCoverages =
            coverages[k].items[i].dependantCoverages;
        }
      }
    });

    return coveragesObj;
  }

  getProvinces = async (input: string, block: string) => {
    this.setState({ loadSearchInput: true });
    // const inputQuery = input.substr(0, 2).toLocaleLowerCase();
    const { setLoading } = this.props;
    const { formData } = this.state;
    if (!formData) return;
    const resProvinces = await AddressServices.getProvinces({
      loader: false,
      setLoading: setLoading,
      params: [`input=${input}`],
    });
    const provinces = resProvinces.map((p: any) => ({
      label: p.name,
      value: p.code,
    }));
    formData[block].city.value = "";
    formData[block].street.value = "";
    formData[block].postalCode.value = "";
    // const listFilter = provinces.filter(
    //   (item: any) =>
    //     item["label"].toLowerCase().indexOf(input.toLowerCase()) > -1
    // );
    this.setState({
      provincesList: provinces,
      provinces,
      cities: [],
      streets: [],
      postalCodes: [],
      formData,
    });
  };

  getCities = async (input: string, block: string) => {
    this.setState({ loadSearchInput: true });
    // const inputQuery = input.substr(0, 2).toLocaleLowerCase();
    const { setLoading } = this.props;
    const { formData } = this.state;
    // search=city&input=ma&provinceCode=28
    if (!formData) return;
    const resCities = await AddressServices.getCities({
      loader: false,
      setLoading: setLoading,
      params: [
        `input=${input}`,
        `provinceCode=${formData[block].province.value.value}`,
      ],
    });
    const cities = resCities.map((p: any) => ({
      label: p.name,
      value: p.code,
    }));
    formData[block].street.value = "";
    formData[block].postalCode.value = "";
    const listFilter = cities.filter(
      (item: any) =>
        item["label"].toLowerCase().indexOf(input.toLowerCase()) > -1
    );
    this.setState({
      citiesList: cities,
      cities,
      streets: [],
      postalCodes: [],
      formData,
    });
    // this.setState({ loadSearchInput: false });
  };

  getStreets = async (input: string, block: string): Promise<any[]> => {
    this.setState({ loadSearchInput: true });
    // const inputQuery = input.substr(0, 2).toLocaleLowerCase();
    const { setLoading } = this.props;
    const { formData } = this.state;
    if (!formData) return [];
    const resStreets = await AddressServices.getStreets({
      loader: false,
      setLoading: setLoading,
      params: [
        `input=${input}`,
        `provinceCode=${formData[block].province.value.value}`,
        `cityCode=${formData[block].city.value.value}`,
      ],
    });
    formData[block].postalCode.value = "";
    const streets = resStreets.map((p: any) => ({
      label: `${p.extra.street.code} ${p.extra.street.name}`,
      value: p.extra.street.name,
      extra: p.extra,
    }));

    this.setState({ streets, streetsList: streets, postalCodes: [], formData }, () => { return streets });
    return streets;
    // this.setState({ loadSearchInput: false });
  };

  getPostalCodes = async (input: string, block: string) => {
    this.setState({ loadSearchInput: true });
    // const inputQuery = input.substr(0, 2).toLocaleLowerCase();
    const { setLoading } = this.props;
    const { formData } = this.state;
    if (!formData) return;
    const resPostalCodes = await AddressServices.getPostalCodes({
      loader: false,
      setLoading: setLoading,
      params: [
        `input=${input}`,
        `provinceCode=${formData[block].province.value.value}`,
        `cityCode=${formData[block].city.value.value}`,
        `street=${formData[block].street.value.label}`,
      ],
    });
    const postalCodes = resPostalCodes.map((p: any) => ({
      label: p.name,
      value: p.name,
    }));

    this.setState({ postalCodes, postalCodesList: postalCodes });
    // this.setState({ loadSearchInput: false });
  };

  changeValue = (
    value: any,
    block: string,
    key: string = "",
    requiredCovs?: string[],
    dependantCovs?: string[],
    label?: string
  ) => {
    const { formData } = this.state;
    if (!formData) return;
    if (
      typeof value === "string" &&
      key !== "civilStatus" &&
      key !== "activityType"
    ) {
      value = value.toUpperCase();
    }

    if (!(key === 'sex' && value === formData.contact.sex.value) &&
      !(key === 'riskActivity' && value === formData.profession.riskActivity.value)) {
      this.props.policy.handleNewChanges();
    }

    if (
      (key === "docType" &&
        value !== "NOID" &&
        value !== "") ||
      (key === "docNumber" &&
        formData.contact.docType.value !== "NOID" &&
        formData.contact.docType.value !== "" &&
        value !== "")
    ) {
      if (key === "docType" && value === "MENOR") {
        formData.contact.docNumber.value = "MENOR";
        formData.contact.docNumber.errorCode = "";
        formData.contact.docNumber.error = false;
        formData.contact.docType.errorCode = "";
        formData.contact.docType.error = false;
      } else if (
        !isValidDocumentID(
          key === "docType" ? formData.contact.docNumber.value : value,
          key === "docType" ? value : formData.contact.docType.value
        )
      ) {
        if (formData.contact.docNumber.value === "MENOR") {
          formData.contact.docNumber.value = "";
          formData.contact.docNumber.errorCode = "";
          formData.contact.docNumber.error = false;
          formData.contact.docType.errorCode = "";
          formData.contact.docType.error = false;
        } else {
          formData.contact.docNumber.errorCode = "invalid-format";
          formData.contact.docNumber.error = true;
          formData.contact.docType.errorCode = "";
          formData.contact.docType.error = false;
        }
      } else {
        formData.contact.docNumber.errorCode = "";
        formData.contact.docNumber.error = false;
        formData.contact.docType.errorCode = "";
        formData.contact.docType.error = false;
      }
    }

    if (
      (key === "docType" && (value === "NOID" || value === "")) ||
      (key === "docNumber" &&
        (formData.contact.docType.value !== "NOID" ||
          formData.contact.docType.value !== "") &&
        value === "")
    ) {
      formData.contact.docNumber.errorCode = "";
      formData.contact.docNumber.error = false;
      formData.contact.docType.errorCode = "";
      formData.contact.docType.error = false;
    }

    formData[block][key].value = value;
    if(block==="paymentType" && key==="payment"){
      formData.paymentType.payment.value=value;
      formData.date.payType.value=value;
      formData.paymentType.payment.value=value;
      formData.paymentType.payment.error=false;
      formData.paymentType.payment.errorCode='';
    }
    if(formData.date.numPets && formData.date.numPets.value!='' && formData.date.numPets.value>10){
      formData.date.numPets.error=true;
      formData.date.numPets.errorCode='max-error-mascots-10';
      formData.date.numPets.value='';
    }
    if (formData[block][key].capital) {
      formData[block][key].capital.required = value;
    }
    if (block === 'profession' && key === 'occupation') {
      formData[block][key].label = value.label;
    }
    if (label) {
      formData[block][key].label = label;
    }
    if (value && requiredCovs && requiredCovs.length > 0) {
      requiredCovs.forEach((cov) => {
        formData[block][cov].value = true;
      });
    } else if (!value && dependantCovs && dependantCovs.length > 0) {
      dependantCovs.forEach((cov) => {
        formData[block][cov].value = false;
      });
    }
    if (key === "DC04-O") {
      Object.keys(formData["address2"]).forEach((k: string) => {
        formData["address2"][k].disabled = !formData[block][key].value;
      });
    }
    const urlString = window.location.href;
    const isDeath = urlString.indexOf("decesos") > -1;
    if (key === "DC04" && value === false) {
      formData["foreignAddress"] = {
        country: {
          value: "",
          error: false,
          errorCode: "",
          required: isDeath,
        },
        address: {
          value: "",
          error: false,
          errorCode: "",
          required: isDeath,
        },
      }
    }
    this.props.policy.changeData();
    if (formData.address.normalizaDireciones.value === "N" && key === "postalCode" && (value.length === 5 || (value.value && value.value.length === 5))) {
      const provinceCode = value.value ? value.value.slice(0, 2) : value.slice(0, 2);
      formData.address.province.value.value = provinceCode;
    }
    this.setState({ formData }, () => {
      if (isDeath && block === "address" && key === "postalCode" && (value.length === 5 || (value.value && value.value.length === 5))) {
        this.getDeathServices(value.value || value);
      }
    });
    switch (key) {
      case "province":
        this.setState({ provincesFiltered: this.state.provincesFiltered.filter((item) => item.label === value.label) })
        break;
      case "city":
        this.setState({ citiesFiltered: this.state.citiesFiltered.filter((item) => item.label === value.label) })
        break;
      case "street":
        this.setState({ streetsFiltered: this.state.streetsFiltered.filter((item) => item.value === value.value) })
        break;
      case "postalCode":
        this.setState({ postalCodesFiltered: this.state.postalCodesFiltered.filter((item) => item.label === value.label) })
        break;
      default:
        break;
    }
    //Solicitar segundo apellido obligatoriamente cuando la nacionalidad sea española
    if(key === "nationality") {
      if(formData.contact.nationality.value && formData.contact.nationality.value.value === "ESPA"){
        formData.contact.lastname2.required = true;
      } else {
        formData.contact.lastname2.required = false;
        formData.contact.lastname2.error = false;
        formData.contact.lastname2.errorCode = "";
      }
    }
  };

  getDeathServices = async (postalCode: string) => {
    const args = {
      postalCode,
      setLoading: () => { },
      loader: true,
      productType: "death",
    };
    const deathServices: any = await CoveragesServices.getCoverageDescriptions(
      args
    );
    if (deathServices.length <= 0) return;
    const deathServicesDropdown = deathServices.map((service: any) => ({
      value: service.code,
      label: `${service.code} - ${service.description}`,
    }));
    const deathServicesDescriptions: any = {};
    deathServices.forEach((service: any) => {
      deathServicesDescriptions[service.code] = service.breakdown.map(
        (serviceB: any) => serviceB.description
      );
    });
    let { formData } = this.state;
    if (formData && formData.serviceCodeBlock) formData.serviceCodeBlock.serviceCode.required = true;
    this.setState({
      deathServicesDropdown,
      deathServicesDescriptions,
      formData
    });
  };

  changeCoverage = (
    value: boolean | string,
    block: string,
    key: string = "",
    positionArray: number,
    capital?: boolean
  ) => {
    const { formData } = this.state;
    if (!formData) return;
    
    if(formData[block][positionArray][key].capital&&capital){
      formData[block][positionArray][key].capital.value = value;
      formData[block][positionArray][key].capital.error = false;
      formData[block][positionArray][key].capital.errorCode = '';
    }

    if(formData[block][positionArray][key].capital&&!capital){
      formData[block][positionArray][key].capital.value = '';
    }

    if (value && key && !capital){
      formData[block][positionArray][key].value = true;
    } else if (!value && key && !capital) {
        formData[block][positionArray][key].value = false;
      }
    this.props.policy.changeData();
    this.setState({ formData });
  };

  changeValueCoverage = (
    value: any,
    block: string,
    keyCoverage: string = "",
    key: string
  ) => {
    const { formData } = this.state;
    if (!formData) return;
    formData[block][keyCoverage][key].errorCode = '';
    formData[block][keyCoverage][key].value = value;

    this.props.policy.changeData();
    this.setState({ formData });
  };

  changeValueSearchBox = (ev: any, block: string, key: string = "") => {
    this.setState({ loadSearchInput: true });
    if (ev === '') {
      let { formData } = this.state;
      if (formData && formData[block] && formData[block][key] && formData[block][key].value && formData[block][key].value.value && formData[block][key].value.value.length > 0) {
        switch (key) {
          case "nationality":
            formData.contact.nationality.value = {value: '', label: ''}
            formData.contact.lastname2.required = false;
            formData.contact.lastname2.error = false;
            formData.contact.lastname2.errorCode = "";
            break;
          default:
            formData[block][key].value.value = '';
            formData[block][key].value.label = '';
            break;
        }
        this.setState({ formData, countriesFiltered: [] })  
      }
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      const {
        provinces,
        cities,
        streets,
        postalCodes
      } = this.state;
      if (this._searchWaiter) {
        clearInterval(this._searchWaiter);
      }
      let streetList = [];
      const value: string = ev;
      if (
        value.length >= 2
        // value.length >= 2 &&
        // (value.substr(0, 2).toLocaleLowerCase() !=
        //   prevSearchInputValue.substr(0, 2).toLocaleLowerCase() ||
        //   key !== prevSearchInputField)
      ) {
        switch (key) {
          case "province":
            if (
              !provinces[0] ||
              provinces[0].label.toLowerCase() !== value.toLowerCase()
            ) {
              await this.getProvinces(value, block);
              this.setState({
                citiesFiltered: [],
                postalCodesFiltered: [],
                streetsFiltered: [],
              });
            }
            break;
          case "city":
            if (
              !cities[0] ||
              cities[0].label.toLowerCase() !== value.toLowerCase()
            ) {
              await this.getCities(value, block);
              this.setState({ postalCodesFiltered: [], streetsFiltered: [] });
            }
            break;
          case "postalCode":
            if (
              !postalCodes[0] ||
              postalCodes[0].label.toLowerCase() !== value.toLowerCase()
            ) {
              await this.getPostalCodes(value, block);
              let s = 0;

            }
            break;
          case "street":
            if (
              !streets[0] ||
              streets[0].label.toLowerCase() !== value.toLowerCase()
            ) {
              streetList = await this.getStreets(value, block);
              this.setState({ postalCodesFiltered: [] });
            }
            break;
          default:
            break;
        }
      }
      if (value.length >= 2) {
        switch (key) {
          case "province":
            this.setState({
              provincesFiltered: this.state.provinces.filter((province) =>
                province.label
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    value
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
              ),
            });
            this.setState({
              citiesFiltered: [],
              postalCodesFiltered: [],
              streetsFiltered: [],
            });
            break;
          case "city":
            this.setState({
              citiesFiltered: this.state.cities.filter((city) =>
                city.label
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    value
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
              ),
            });
            this.setState({ postalCodesFiltered: [], streetsFiltered: [] });
            break;
          case "postalCode":
            this.setState({
              postalCodesFiltered: this.state.postalCodes.filter((postalCode) =>
                postalCode.label
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    value
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
              ),
            });
            break;
          case "street":
            this.setState({
              streetsFiltered: streetList.filter((street) =>
                street.label
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    value
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
              ),
            });
            this.setState({ postalCodesFiltered: [] });
            break;
          case "occupation":
            this.setState({
              professionsFiltered: this.state.professions.filter((prefession) =>
                prefession.label
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    value
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
              ),
            });
            break;
          case "activityType":
            this.setState({
              activitiesFiltered: this.state.activities.filter((activity) =>
                activity.label
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    value
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
              ),
            });
            break;
          case "nationality":
            this.setState({
              countriesFiltered: this.state.countries.filter((country) =>
                country.label
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    value
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
              ),
            });
            break;
          case "country":
            this.setState({
              countriesFiltered: JSON.parse(JSON.stringify(this.state.countries.filter((country) =>
                country.label
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    value
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
              ))),
            });
            break;
          default:
            break;
        }
      }
      if (value.length < 2) {
        switch (key) {
          case "province":
            this.setState({ provincesFiltered: [] });
            break;
          case "city":
            this.setState({ citiesFiltered: [] });
            break;
          case "postalCode":
            this.setState({ postalCodesFiltered: [] });
            break;
          case "street":
            this.setState({ streetsFiltered: [] });
            break;
          case "occupation":
            this.setState({ professionsFiltered: [] });
            break;
          case "activityType":
            this.setState({ activitiesFiltered: [] });
            break;
          case "nationality":
            this.setState({ countriesFiltered: [] });
            break;
          default:
            break;
        }
      }
      this.setState({
        prevSearchInputField: key,
        search: "",
        prevSearchInputValue: value,
        loadSearchInput: false,
      });
    }, 1000);
  };

  changeNormalizedAddress = (value: boolean) => {
    const { formData } = this.state;
    if (!formData) return;

    const normalizaDireciones = value ? "N" : "S";
    formData.address.normalizaDireciones.value = normalizaDireciones;

    // Si de no normalizado pasa a normalizado
    // limpia los datos para coger los correctos normalizados
    if (normalizaDireciones === "S") {
      const address = {
        ...formData.address,
        province: {
          ...formData.address.province,
          value: "",
          error: false,
          errorCode: "",
          disabled: false,
        },
        city: {
          ...formData.address.city,
          value: "",
          error: false,
          errorCode: "",
          disabled: true,
        },
        street: {
          ...formData.address.street,
          value: "",
          error: false,
          errorCode: "",
          disabled: true,
        },
        postalCode: {
          ...formData.address.postalCode,
          value: "",
          error: false,
          errorCode: "",
          disabled: true,
        }
      }
      formData.address = address;
    } else {
      const address = {
        ...formData.address,
        province: {
          ...formData.address.province,
          error: false,
          errorCode: "",
          disabled: false,
        },
        city: {
          ...formData.address.city,
          error: false,
          errorCode: "",
          disabled: false,
        },
        street: {
          ...formData.address.street,
          error: false,
          errorCode: "",
          disabled: false,
        },
        postalCode: {
          ...formData.address.postalCode,
          error: false,
          errorCode: "",
          disabled: true,
        }
      }
      formData.address = address;
    }

    this.setState({ formData });
  }

  toValidate = async (detailIndex?: number, block?: string) => {
    const { formData, isEdit } = this.state;
    const { insured, newStep, hiring, policy, history } = this.props;
    const aseguradoRCURL = "/contratacion/nueva/mascotas/asegurado-beneficiario";
    const type = history.location.pathname === aseguradoRCURL ?"beneficiary"
                  :insured ? "insured":"taker";
    const addressRequired = formData && formData.address.city.required;
    let isValidAddress = true;
    const deathFirstStep =
      this.state.pathname.includes("tomador") &&
      this.state.pathname.includes("decesos");
    const isDeathSimulation =
      this.state.pathname === "/simulacion/nueva/decesos/tomador";
    const isSimulation = !(!!hiring);
    const urlString = window.location.href;
    const url = new URL(urlString);
    const index = url.searchParams.get("index");

    let requiredNif = false;
    let requiredDocType = false;
    let duplicatedNif = false;

    if (
      (isSimulation &&
        formData &&
        formData.contact.docNumber.value &&
        (!formData.contact.docType.value ||
          formData.contact.docType.value === "NOID" ||
          formData.contact.docType.value === "")) ||
      (formData &&
        !formData.contact.docNumber.value &&
        formData.contact.docType.value &&
        formData.contact.docType.value !== "MENOR" &&
        formData.contact.docType.value !== "NOID" &&
        formData.contact.docType.value !== "")
    ) {
      if (formData.contact.docNumber.value) {
        formData.contact.docType.errorCode = "invalid-document-type-value";
        formData.contact.docType.error = true;
        requiredDocType = true;
      } else {
        formData.contact.docNumber.errorCode = "invalid-document-value";
        formData.contact.docNumber.error = true;
        requiredNif = true;
      }
    }

    if (
      formData &&
      (insured && type!=="beneficiary") &&
      formData.contact.docNumber.value &&
      formData.contact.docType.value &&
      policy.data &&
      policy.data.insureds.length > 0
    ) {
      const urlString = window.location.href;
      const url = new URL(urlString);
      const index = url.searchParams.get("index");
      const editing =index && !!policy.data.insureds[index];
      let found;
      for (const insured of policy.data.insureds) {
        if (
          insured.contact.docType.value === formData.contact.docType.value &&
          insured.contact.docNumber.value === formData.contact.docNumber.value &&
          insured.contact.docType.value != "MENOR"
        ) {
          if (editing && !found) {
            found = true;
          } else {
            formData.contact.docNumber.error = true;
            formData.contact.docNumber.errorCode = "document-duplicated";
            duplicatedNif = true;
            break;
          }
        }
      }
    }

    if(formData && policy.product.products_internalName === PRODUCT_TYPE.WOMAN ){
      formData.contact.sex.value = 'M'
    }

    //Capital validation
    let error_capital = false
    let auxCoverages;
    if (formData && formData.coverages && type !== "beneficiary"){
      if(Array.isArray(formData.coverages)){
        formData.coverages.map((coverageIndex:any, i:number)=>{
          auxCoverages = capitalValidation(coverageIndex);
          if(Object.keys(auxCoverages).length!==0){
            formData.coverages[i] = {...auxCoverages}          
            error_capital= true
          }
        }) 
      }
      else {
        const validatedCoverages = capitalValidation(formData.coverages)
        if(Object.keys(validatedCoverages).length!==0){
          formData.coverages = {...validatedCoverages}
          error_capital= true
        }
      }
    }

    const obj = this.props.policy.validateForm(
      formData,
      type,
      block,
      isEdit,
      duplicatedNif || error_capital,
      index
    );

    if ((type === "taker" || type === "beneficiary") && formData && formData.info.email && formData.info.email.value !== '') {
      try {
        const result = await HocelotServices.verifyEmail({data: { email: formData.info.email.value }, setLoading: () => {}});
        if (result.isVerified)  {
          obj.formData.info.email.error = false;
          obj.formData.info.email.errorCode = "";
        } else {
          obj.formData.info.email.error = true;
          obj.formData.info.email.errorCode = "invalid-email";   
        }
      } catch (error) {
        obj.formData.info.email.errorCode = error && error.status === 422 ? 'invalid-email' : 'hocelot-verify-error';
        obj.formData.info.email.error = true;
      }
    }

    if (addressRequired && formData && formData.address.normalizaDireciones.value === "S") {
      let streetFormated = AddressServices.addStreetCode(formData)
      const args = {
        setLoading: () => { },
        loader: true,
        city: formData.address.city.value.label,
        house: formData.address.streetNumber.value,
        street: streetFormated,
        zip: formData.address.postalCode.value.value,
      };
      const resp = await AddressServices.getAddressCheck(args);
      if (resp && resp.length > 0) {
        obj.formData.address.city.error = false;
        obj.formData.address.city.errorCode = "";
        obj.formData.address.streetNumber.error = false;
        obj.formData.address.streetNumber.errorCode = "";
        obj.formData.address.street.error = false;
        obj.formData.address.street.errorCode = "";
        obj.formData.address.postalCode.error = false;
        obj.formData.address.postalCode.errorCode = "";
      } else {
        obj.formData.address.city.error = true;
        obj.formData.address.city.errorCode = "invalid-address";
        obj.formData.address.streetNumber.error = true;
        obj.formData.address.streetNumber.errorCode = "invalid-address";
        obj.formData.address.street.error = true;
        obj.formData.address.street.errorCode = "invalid-address";
        obj.formData.address.postalCode.error = true;
        obj.formData.address.postalCode.errorCode = "invalid-address";
        isValidAddress = false;
        if(resp.data && resp.data.message){
          this.setState({showModal : true, infoModalText: resp.data.message});
        }
      }
    }
    if (deathFirstStep) {
      if (!obj.formData.serviceCodeBlock.serviceCode.value) {
        obj.formData.serviceCodeBlock.serviceCode.error = true;
        obj.formData.serviceCodeBlock.serviceCode.errorCode = "required";
      } else {
        obj.formData.serviceCodeBlock.serviceCode.error = false;
        obj.formData.serviceCodeBlock.serviceCode.errorCode = "";
      }
      if (isDeathSimulation) {
        if (!obj.formData.payment.payType.value) {
          obj.formData.payment.payType.error = true;
          obj.formData.payment.payType.errorCode = "required";
        } else {
          obj.formData.payment.payType.error = false;
          obj.formData.payment.payType.errorCode = "";
        }
      }
    }

    if (obj.formData && (requiredNif || requiredDocType)) {
      if (requiredNif) {
        obj.formData.contact.docNumber.error = true;
        obj.formData.contact.docNumber.errorCode = "invalid-document-value";
      } else {
        obj.formData.contact.docType.error = true;
        obj.formData.contact.docType.errorCode = "invalid-document-type-value";
      }
    }

    if (obj.formData && duplicatedNif) {
      obj.formData.contact.docNumber.error = true;
      obj.formData.contact.docNumber.errorCode = "document-duplicated";    
    }

    if (
      obj.formData &&
      (obj.formData.useTakerData ||
        (obj.formData.profession.riskActivity &&
          obj.formData.profession.riskActivity.value !== "Y")) &&
      obj.formData.profession.activityType.value
    ) {
      obj.formData.profession.activityType.value = "";
    }

    if(obj.formData && obj.formData.coverages && !error_capital){
      if(Array.isArray(obj.formData.coverages)){
        obj.formData.coverages.map((coverage:any, i:number)=>{
          Object.keys(coverage).forEach(coverageCode => {
            if(coverage[coverageCode].value && coverage[coverageCode].capital && coverage[coverageCode].capital.value){
              coverage[coverageCode].capital.error = false
              coverage[coverageCode].capital.errorCode = ''
            }
          })
        });
      }
    }
    setTimeout(() => {
      if (!this.mainContainer || !this.mainContainer.current) return;
      const errorElem: any = Array.from(
        this.mainContainer.current.getElementsByClassName("error")
      ).concat(
        Array.from(
          this.mainContainer.current.getElementsByClassName("input-box-error")
        )
      );
      if (errorElem.length > 0 && errorElem[0].offsetTop !== undefined) {
        if (
          !this.contentWrapperRef ||
          !this.contentWrapperRef.current ||
          !this.contentWrapperRef.current.scrollTo
        )
          return;
        this.contentWrapperRef.current.scrollTo(
          0,
          errorElem[0].offsetTop - 200
        );
      } else {
        if (detailIndex !== undefined) {
          this.goToDetail(detailIndex);
        } else {
          newStep && isValidAddress && newStep();
        }
      }
    }, 300);

    this.setState({ formData: obj.formData, isEdit: obj.isEdit });
  };

  renderColumn(items: any[], pos?: any) {
    if (items.length % 3 !== 0) {
      const array = new Array(3 - (items.length % 3)).fill(0);

      return array.map((i, index) => (
        <div
          key={`col-empty-${pos}-${index}`}
          className="personal-checkbox-item col-50"
        />
      ));
    }
  }

  handleChangeWomanType(type: string) {
    const { formData } = this.state;
    const { coverages, policy } = this.props
    if (!formData || !coverages) return;
    formData.coverages = this.getCoverages(
      formatCoverages(
        coverages[policy.product.products_coverageName].filter((cov: any) => cov.optioncode === type)
      )
      );
    this.setState({ formData, selectedWomanType: type });
  }

  handleUseTakerData = (useTakerData: boolean) => {
    const {
      policy: {
        data: { taker },
      },
    } = this.props;
    let { formData } = this.state;
    const previousFormData = formData;
    if (useTakerData) formData = JSON.parse(JSON.stringify(taker));
    else if (formData) formData = this.getFormData(true);

    if (previousFormData && previousFormData.foreignAddress && formData) {
      formData.foreignAddress = previousFormData.foreignAddress;
    }
    if (formData) {
      formData.useTakerData = useTakerData;
      formData.contact.lastname2.required = false;
      if(formData.contact && formData.contact.nationality && formData.contact.nationality.value && formData.contact.nationality.value.value === "ESPA") {
        formData.contact.lastname2.required = true;
      } 
      this.setState({ formData });
    }
  };

  getActivityType = (profession: any, activities: any): string | undefined => {
    if (!profession.activityType.value) {
      return "";
    }
    if (profession.activityType.value.label) {
      return profession.activityType.value.label;
    } else {
      const activity = activities.find(
        (activity: any) => activity.value === profession.activityType.value
      );
      return activity ? activity.label : "";
    }
  };

  handleUseTakerAddress = (useTakerAddress: boolean) => {
    const {
      policy: {
        data: {
          taker: { address },
        },
      },
      noAddress,
      hiring
    } = this.props;
    const { formData } = this.state;
    const urlString = window.location.href;
    const isInsuredSimulationDeath =
      !(!!hiring) &&
      urlString.indexOf("decesos") > -1 &&
      urlString.indexOf("asegurado") > -1;

    if (formData) {
      formData.useTakerAddress = useTakerAddress;
      if (useTakerAddress) formData.address = address;
      else {
        formData.address = {
          street: {
            value: "",
            error: false,
            errorCode: "",
            required: !noAddress && !isInsuredSimulationDeath,
          },
          streetNumber: {
            value: "",
            error: false,
            errorCode: "",
            required: !noAddress && !isInsuredSimulationDeath,
          },
          stairs: {
            value: "",
            error: false,
            errorCode: "",
            required: false,
          },
          floorNumber: {
            value: "",
            error: false,
            errorCode: "",
            required: false,
          },
          door: {
            value: "",
            error: false,
            errorCode: "",
            required: false,
          },
          province: {
            value: "",
            error: false,
            errorCode: "",
            required: !noAddress && !isInsuredSimulationDeath,
          },
          city: {
            value: "",
            error: false,
            errorCode: "",
            required: !noAddress && !isInsuredSimulationDeath,
          },
          postalCode: {
            value: "",
            error: false,
            errorCode: "",
            required: !noAddress,
          },
          normalizaDireciones: {
            value: "S",
            error: false,
            errorCode: "",
            required: false,
          }
        };
      }
      this.setState({ formData }, () => this.props.policy.changeData());
    }
  };
  _formatCapitales = (cap: any) => {
    let options: Array<{
      [key: string]: string;
    }> = []
    let s = cap.map((v: any) => {
      options.push({ "label": `${formatter.format(Number(v.importe))}`, "value": v.importe })
    })
    return options;
  }
  renderCoverages = (coveragesP: any, column: number) => {

    const { policy } = this.props;
    const { formData, selectedWomanType } = this.state;
    if (!formData || !coveragesP) return;
    const { coverages } = formData;
    if (!coverages || Object.keys(coverages).length===0) return;
    const coveragesProps = JSON.parse(JSON.stringify(coveragesP));

    if (policy.product.products_internalName === PRODUCT_TYPE.WOMAN) {
      Object.keys(coveragesProps).forEach(key => {
        coveragesProps[key].items = coveragesProps[key].items.filter(
          (cov: any) => cov.optionCode === selectedWomanType
        );
      })
    }

    return Object.keys(coveragesProps).map(
      (k) =>
        coveragesProps[k].items.length > 0 &&
        k !== "notShow" && (
          <div key={k} className="personal-checkbox-container">
            {policy.product.products_internalName !== PRODUCT_TYPE.WOMAN && (
              <div className="personal-checkbox-title">
                <Body02Text>
                  <p>{coveragesProps[k].title}</p>
                </Body02Text>
              </div>
            )}
            {chunkArray(coveragesProps[k].items, column).map((row, index) => (
              <div key={index} className="personal-checkbox-row">
                {row.map((item: any, index: number) =>
                  !item.checkbox ? (
                    <div
                      key={`coverage-${k}-${index}`}
                      className="personal-checkbox-description"
                    >
                      <WebText>
                        <p>{item.name}</p>
                      </WebText>
                    </div>
                  ) 
                  : (
                    <div
                      key={`coverage-${k}-${index}`}
                      className="personal-checkbox-item col-50"
                    >
                      <div className="personal-checkbox-subitem">
                      <DicotomicCheckbox
                          initChecked={coverages[item.code].value}
                          disabled={item.disabled || formData.isReadOnly}
                          className={k === "main" ? "mandatory" : "optional"}
                          checkboxText={item.name}
                          onChange={(value: string) =>
                            this.changeValue(
                              value,
                              "coverages",
                              item.code,
                              item.requiredCoverages,
                              item.dependantCoverages)
                          }
                        />
                        {coverages[item.code].capital && 
                          (coverages[item.code].value ? (
                            coverages[item.code].options ? (
                              <div className="selectBox">
                                <SelectBox
                                  labelText="Capital a asegurar"
                                  disabled={!coverages[item.code].value || formData.isReadOnly}
                                  required={coverages[item.code].capital.required}
                                  errorCode={coverages[item.code].capital.errorCode}
                                  optionsText={coverages[item.code].options}
                                  optionKey={"value"}
                                  optionValue={"label"}
                                  initialValue={coverages[item.code].capital.value}
                                  onChange={(value: string) =>
                                    this.changeValueCoverage(
                                      value,
                                      "coverages",
                                      item.code,
                                      "capital"
                                    )
                                  }
                                />
                              </div>
                            )
                            : (
                                <div className="personal-checkbox-subitem">
                                  <div className="inputBox">
                                    <InputBox
                                      labelText={`capital a asegurar (min: ${currencyFormat(
                                        item.capital.min
                                      )} € - max: ${currencyFormat(item.capital.max)} €)`}
                                      disabled={!coverages[item.code].value || formData.isReadOnly}
                                      required={coverages[item.code].capital.required}
                                      errorCode={coverages[item.code].capital && coverages[item.code].capital.errorCode}
                                      value={
                                        coverages[item.code].value
                                          ? coverages[item.code].capital.value
                                          : ""
                                      }
                                      type={"currency"}
                                      onBlur={(value: string) =>
                                        this.changeValueCoverage(
                                          value,
                                          "coverages",
                                          item.code,
                                          "capital"
                                        )
                                      }
                                    />
                                   </div>
                                </div>
                              )
                          ) 
                          : '')
                        }
                      </div> 
                    </div>
                  )
                )}

                {this.renderColumn(row, k)}
              </div>
            ))}
          </div>
        )
    );
  };

  handleSelectMediatorChange(value: string): void {
    this.props.policy.setMediatorSelectedCode && this.props.policy.setMediatorSelectedCode(value);
    this.setState({ mediatorCodeSelected: value });
  }

  render() {
    const {
      activities,
      activitiesFiltered,
      citiesFiltered,
      countries,
      countriesFiltered,
      coverages,
      formData,
      modal,
      showModal,
      postalCodesFiltered,
      professions,
      professionsFiltered,
      provincesFiltered,
      selectedWomanType,
      streetsFiltered,
      deathServicesDropdown,
      deathServicesDescriptions,
      relatedMediatorCodes,
      mediatorCodeSelected,
      loadSearchInput,
    } = this.state;
    if (!formData) return <></>;
    const {
      address2: address2Prop,
      capital,
      civilStatus,
      hiring,
      history,
      insured,
      nationality,
      noAddress,
      noProfession,
      noRequired,
      noRisk,
      policy,
    } = this.props;
    
    const urlString = window.location.href;
    const isPets = urlString.indexOf("mascotas") > -1;
    const isDeath = urlString.indexOf("decesos") > -1;
    const {
      address,
      address2,
      contact,
      date,
      foreignAddress,
      hiringDeath,
      info,
      isReadOnly,
      payment,
      paymentType,
      profession,
      serviceCodeBlock,
      useTakerAddress,
      useTakerData,
    } = formData;

    const url = new URL(urlString);
    const index = url.searchParams.get("index");
    const nInsured = index || policy.lengthInsured;
    const isDeathFirstStep =
      isDeath &&
      this.state.pathname.includes("decesos") &&
      this.state.pathname.includes("tomador");
    const isDeathSimulation =
      this.state.pathname === "/simulacion/nueva/decesos/tomador";
    const isSimulation = !(!!hiring);

    const isInsuredSimulationDeath =
      isSimulation &&
      urlString.indexOf("decesos") > -1 &&
      urlString.indexOf("asegurado") > -1;
    const selectedProfession = professions.filter(
      (prof) => profession && prof.value === profession.occupation.value
    );
    const selectedNationality = countries.filter(
      (nat) => contact.nationality && nat.value === contact.nationality.value
    );
    const productInternalName = policy.product && policy.product.products_internalName ? policy.product.products_internalName : ''

    const deleteInsuredCoverages = (index:number) => {
      const { formData } = this.state;
      if (!formData) return;
      formData.coverages.splice(index,1);
      this.changeValue(Number(formData.date.numPets.value)-1===0?'':Number(formData.date.numPets.value)-1, "date", "numPets");
    }

    const getTablesNumberMascots = (coveragesProps:any) =>{
      if (!formData || !coveragesProps) return;
      const { coverages, date } = formData;
      if (!coverages) return;
      let tables:any = [];
      
      for (let index = 0; index < date.numPets.value; index++) {
        tables.push(
          <div className="personal-data-container mb-10" key={index}>
            <div className="col-100 borderB-white"><div className="col-25 table-bgBlue content-wrapper">Mascota {index+1}</div></div>
            <div className="colSin-30 table-tr mr-2per">
                <p className="table-bgBlue content-wrapper">{coveragesProps.main.title.toUpperCase()}</p>
                {coveragesProps.main.items.map((item:any, i:number)=>{
                    return(
                      <div key={i}>
                        <div className="content-wrapper"><p>{item.name}</p></div>
                      </div>
                    );
                })}
            </div>
            
            <div className="colSin-65 table-tr">
              <p className="table-bgBlue content-wrapper">{coveragesProps.complementary.title.toUpperCase()}</p>
              <div>
              {coveragesProps.complementary.items.map((item:any, i:number)=>{
                    return(
                      <div className={coverages[index][item.code].value===true?"flex-space-between plr-20":" flex-space-between content-wrapper"} key={i}>
                      <DicotomicCheckbox
                        initChecked={coverages[index][item.code].value}
                        disabled={item.disabled || formData.isReadOnly}
                        className={coverages[index][item.code].value===true?"optional pt-10":"optional"}
                        checkboxText={item.name}
                        onChange={(value: string) =>{
                          this.changeCoverage(
                            value,
                            "coverages",
                            item.code,
                            index
                          )}
                        }
                      />
                      {coverages[index][item.code].value===true &&
                        <div className="h-auto">
                          <div className="selectBox flex">
                            <label className="point">&nbsp;</label>
                            <SelectBox
                              placeholder="Seleccionar capital"
                              required={true}
                              errorCode={coverages[index][item.code].capital.errorCode}
                              optionsText={coverages[index][item.code].options}
                              initialValue={coverages[index][item.code].capital.value}
                              defaultValue={coverages[index][item.code].capital.value}
                              optionKey={"value"}
                              optionValue={"label"}
                              onChange={(value: string) =>
                                value!=''&&this.changeCoverage(value, "coverages", item.code, index, true)
                              }
                            /> 
                          </div>                         
                        </div>
                      }
                    </div>
                  )}
                )}
              </div>
            </div>
            <div className="colSin-4 flex align-center justify-center">
              <img className="colSin-60 pointer" src={images.IconDeleteBlue} onClick={()=>deleteInsuredCoverages(index)}/>
            </div>
          </div>
        );
      }

      return tables;
    }

    return (
      <DataContainer ref={this.mainContainer}>
        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <h2>{!insured ? `DATOS DEL TOMADOR` : `DATOS DEL ASEGURADO`}</h2>
          </div>
          <div className="data-flow-status-guide">
            <GuideLine
              steps={isPets ? [0, 0, 0] : [0, 0, 0, 0]}
              currentStep={insured ? 2 : 1}
            />
          </div>
        </div>
        <div className="content-wrapper" ref={this.contentWrapperRef}>
          <div className="personal-data-wrapper">
            <div className="data-title">
              <h5>DATOS PERSONALES</h5>
            </div>
            <div className="data-subtitle"></div>
            <div className="personal-data-container">
              {(insured && !!productInternalName && productInternalName !== PRODUCT_TYPE.DEATH ||
                insured && productInternalName === PRODUCT_TYPE.DEATH && url.searchParams.get("index") === '0') && (
                  <div className="personal-data-row">
                    <div className="personal-data-item col-100">
                      <CheckboxItem
                        className={"blue"}
                        checkboxText={"El tomador de la póliza es el asegurado"}
                        checked={useTakerData}
                        disabled={isReadOnly}
                        onChange={() => {
                          this.contentWrapperRef &&
                            this.contentWrapperRef.current &&
                            this.contentWrapperRef.current.scrollTo &&
                            this.contentWrapperRef.current.scrollTo(
                              0,
                              document.body.scrollHeight
                            );
                          this.handleUseTakerData(!useTakerData);
                        }}
                      />
                    </div>
                  </div>
                )}

              <div className="personal-data-row">
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Nombre"
                      required={contact.name.required}
                      errorCode={contact.name.errorCode}
                      disabled={useTakerData || isReadOnly}
                      value={contact.name.value}
                      onChange={(value: string) =>
                        this.changeValue(value, "contact", "name")
                      }
                    />
                  </div>
                </div>
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Apellido 1"
                      required={contact.lastname1.required}
                      errorCode={contact.lastname1.errorCode}
                      disabled={useTakerData || isReadOnly}
                      value={contact.lastname1.value}
                      onChange={(value: string) =>
                        this.changeValue(value, "contact", "lastname1")
                      }
                      type={"letters"}
                    />
                  </div>
                </div>
              </div>
              <div className="personal-data-row">
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Apellido 2"
                      required={contact.lastname2.required}
                      errorCode={contact.lastname2.errorCode}
                      disabled={useTakerData || isReadOnly}
                      value={contact.lastname2.value || ""}
                      onChange={(value: string) =>
                        this.changeValue(value, "contact", "lastname2")
                      }
                      type={"letters"}
                    />
                  </div>
                </div>
                {!isPets || hiring ? (
                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <CalendarBox
                        initialValue={
                          contact.birthdate.value === ""
                            ? ""
                            : // : moment(contact.birthdate.value).format("DD/MM/YYYY")
                            contact.birthdate.value
                        }
                        // initialValue={"18/09/2020"}
                        placeholder="Fecha de nacimiento"
                        forbidFutureDates
                        minAge={insured ? undefined : 18}
                        maxDate={moment().toISOString()}
                        required={contact.birthdate.required}
                        disabled={useTakerData || isReadOnly}
                        errorCode={contact.birthdate.errorCode}
                        onChange={(value: string) => {
                          this.changeValue(value, "contact", "birthdate");
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <InputBox
                        placeholder="Correo electrónico"
                        labelText=""
                        required={info.email.required}
                        errorCode={info.email.errorCode}
                        disabled={useTakerData || isReadOnly}
                        maxLength={50}
                        value={info.email.value}
                        onChange={(value: string) =>
                          this.changeValue(value, "info", "email")
                        }
                        type={"email"}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="personal-data-row">
                <div className="personal-data-item col-50">
                  <div className="selectBox">
                    <SelectBox
                      placeholder="Documento de identidad"
                      required={contact.docType.required}
                      errorCode={contact.docType.errorCode}
                      optionsText={
                        insured
                          ? isSimulation
                            ? DOC_TYPES.insuredsSimulation
                            : DOC_TYPES.insureds
                          : isSimulation
                            ? DOC_TYPES.othersSimulation
                            : DOC_TYPES.others
                      }
                      optionKey={"value"}
                      optionValue={"label"}
                      initialValue={contact.docType.value}
                      defaultValue={contact.docType.value}
                      disabled={useTakerData || isReadOnly}
                      onChange={(value: string) => {
                        if (value !== contact.docType.value) {
                          this.changeValue(value, "contact", "docType");
                        }
                      }}
                    />
                    <label>{"Documento de identidad"}</label>
                  </div>
                </div>
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Nº de documento"
                      required={contact.docNumber.required}
                      errorCode={contact.docNumber.errorCode}
                      maxLength={
                        contact.docType.value === "NIF" ||
                          contact.docType.value === "NIE"
                          ? 9
                          : undefined
                      }
                      value={contact.docNumber.value}
                      disabled={
                        contact.docType.value === "MENOR" || useTakerData || isReadOnly
                      }
                      onChange={(value: string) =>
                        this.changeValue(value, "contact", "docNumber")
                      }
                    />
                  </div>
                </div>
              </div>
              {(!isPets || hiring) && (nationality || civilStatus) && (
                <div className="personal-data-row">
                  {nationality && (
                    <div className="personal-data-item col-50">
                      <div className="inputBox">
                        <SearchBox
                          onChange={(ev: any) =>
                            this.changeValueSearchBox(
                              ev.target.value,
                              "contact",
                              "nationality"
                            )
                          }
                          labelText=""
                          optionsText={countriesFiltered}
                          loader={this.state.loadSearchInput}
                          optionKey={"value"}
                          optionValue={"label"}
                          disabled={useTakerData || isReadOnly}
                          initialValue={contact.nationality && contact.nationality.value && contact.nationality.value.label
                            ? contact.nationality.value.label
                            : selectedNationality[0]
                              ? selectedNationality[0].label
                              : ""
                          }
                          required={contact.nationality.required}
                          errorCode={contact.nationality.errorCode}
                          onSelect={(value: any) =>
                            this.changeValue(value, "contact", "nationality")
                          }
                        />
                        <label>Nacionalidad</label>
                      </div>
                    </div>
                  )}
                  {civilStatus ? (
                    <div className="personal-data-item col-50">
                      <div className="selectBox">
                        <SelectBox
                          placeholder="Estado civil"
                          required={contact.civilStatus.required}
                          disabled={useTakerData || isReadOnly}
                          errorCode={contact.civilStatus.errorCode}
                          optionsText={CIVIL_STATUS}
                          optionKey={"value"}
                          optionValue={"label"}
                          initialValue={contact.civilStatus.value}
                          onChange={(value: string) =>
                            this.changeValue(value, "contact", "civilStatus")
                          }
                        />
                        <label>Estado civil</label>
                      </div>
                    </div>
                  ) : <div className="personal-data-item col-50"/> }
                </div>
              )}
              {(!isPets || hiring) && (
                <div className="personal-data-row">
                  <div className="personal-data-item col-25 bottomMargin20">
                    <DicotomicButton
                      required={contact.sex.required}
                      labelText="Sexo"
                      options={SEX}
                      withTab={true}
                      disabled={
                        useTakerData ||
                        productInternalName === PRODUCT_TYPE.WOMAN || 
                        isReadOnly
                      }
                      initialValue={productInternalName === PRODUCT_TYPE.WOMAN ? 'M' : contact.sex.value}
                      onChange={(value: string) =>
                        this.changeValue(value, "contact", "sex")
                      }
                      errorCode={contact.sex.errorCode}
                    />
                  </div>
                  <div className="personal-data-item col-75" />
                </div>
              )}
            </div>

            {!noProfession && (
              <div className="personal-data-container">
                <div className="personal-data-row">
                  {!noRisk && (
                    <div className="personal-data-item col-100">
                      <div className="selectBox">
                        <SearchBox
                          onChange={(ev: any) =>
                            this.changeValueSearchBox(
                              ev.target.value,
                              "profession",
                              "occupation"
                            )
                          }
                          labelText=""
                          required={profession.occupation.required}
                          errorCode={profession.occupation.errorCode}
                          optionsText={professionsFiltered}
                          loader={this.state.loadSearchInput}
                          disabled={useTakerData || isReadOnly}
                          icon={images.IconArrowDownBlue}
                          optionKey={"value"}
                          optionValue={"label"}
                          initialValue={
                            profession.occupation.value.label
                              ? profession.occupation.value.label
                              : selectedProfession[0]
                                ? selectedProfession[0].label
                                : ""
                          }
                          onSelect={(value: any) =>
                            this.changeValue(value, "profession", "occupation")
                          }
                        />
                        <label>PROFESIÓN</label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="personal-data-row">
                  <div className="personal-data-item col-25">
                    <DicotomicButton
                      labelText="Actividad de riesgo"
                      withTab={true}
                      options={RISK_ACTIVITY}
                      initialValue={
                        profession.riskActivity
                          ? profession.riskActivity.value
                          : ""
                      }
                      disabled={useTakerData || isReadOnly}
                      onChange={(value: string) =>
                        this.changeValue(value, "profession", "riskActivity")
                      }
                    />
                  </div>
                  <div className="personal-data-item col-75 topMargin15">
                    <div className="selectBox">
                      <SearchBox
                        disabled={
                          useTakerData ||
                          (profession.riskActivity &&
                            profession.riskActivity.value !== "Y") ||
                          isReadOnly
                        }
                        required={profession.activityType.required}
                        errorCode={profession.activityType.errorCode}
                        onChange={(ev: any) =>
                          this.changeValueSearchBox(
                            ev.target.value,
                            "profession",
                            "activityType"
                          )
                        }
                        labelText=""
                        optionsText={activitiesFiltered}
                        loader={this.state.loadSearchInput}
                        optionKey={"value"}
                        optionValue={"label"}
                        icon={images.IconArrowDownBlue}
                        initialValue={this.getActivityType(
                          profession,
                          activities
                        )}
                        onSelect={(value: string) =>
                          this.changeValue(value, "profession", "activityType")
                        }
                      />
                      <label>Tipo de actividad</label>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!noAddress && (
              <AddressItem
                address={address}
                citiesFiltered={citiesFiltered}
                insured={insured}
                isInsuredSimulationDeath={isInsuredSimulationDeath}
                isReadOnly={isReadOnly}
                loadSearchInput={loadSearchInput}
                onChangeNormalizedAddress={this.changeNormalizedAddress}
                onChangeValue={this.changeValue}
                onChangeValueSearchBox={this.changeValueSearchBox}
                onUseTakerAddress={this.handleUseTakerAddress}
                postalCodesFiltered={postalCodesFiltered}
                provincesFiltered={provincesFiltered}
                streetsFiltered={streetsFiltered}
                urlString={urlString}
                useTakerAddress={useTakerAddress}
                useTakerData={useTakerData}
              />
            )}
            
          </div>

          <div className="personal-data-container">
            <div className="personal-data-row">
              <div className="personal-data-item col-50">
                <div className="inputBox">
                  <InputBox
                    required={info.landlinePhoneNumber.required}
                    disabled={useTakerData || isReadOnly}
                    type={"number"}
                    maxLength={9}
                    errorCode={info.landlinePhoneNumber.errorCode}
                    value={info.landlinePhoneNumber.value}
                    onChange={(value: string) =>
                      this.changeValue(value, "info", "landlinePhoneNumber")
                    }
                  />
                  <label>Teléfono Fijo</label>
                </div>
              </div>
              <div className="personal-data-item col-50">
                <div className="inputBox">
                  <PhoneBox
                    labelText=""
                    initialValue={info.mobile.value}
                    disabled={useTakerData || isReadOnly}
                    required={info.mobile.required}
                    errorCode={info.mobile.errorCode}
                    onChange={(value: string) =>
                      this.changeValue(value, "info", "mobile")
                    }
                  />
                  <label>teléfono móvil</label>
                </div>
              </div>
            </div>
            {(!isPets || hiring) && (
              <div className="personal-data-row">
                <div className="personal-data-item col-100">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Correo electrónico"
                      labelText=""
                      disabled={useTakerData || isReadOnly}
                      required={info.email.required}
                      errorCode={info.email.errorCode}
                      maxLength={50}
                      value={info.email.value}
                      onChange={(value: string) =>
                        this.changeValue(value, "info", "email")
                      }
                      type={"email"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="data-title">
            <h5>DATOS DE PÓLIZA</h5>
          </div>
          <div className="data-subtitle"></div>
          <div className="personal-data-container">
            {!insured && relatedMediatorCodes.length > 0 &&
              <div className="personal-data-mediador col-100">
                <div className="selectBox">
                  <SelectBox
                    isMediator={true}
                    optionsText={relatedMediatorCodes}
                    optionKey={"value"}
                    optionValue={"label"}
                    defaultValue={mediatorCodeSelected}
                    disabled={isReadOnly}
                    onChange={(value: string) => this.handleSelectMediatorChange(value)}
                    propValue={mediatorCodeSelected}
                    required
                  />
                  <label>Mediador</label>
                </div>
              </div>
            }

            {hiring && !insured && (
              <div className="personal-data-row">
                <div className="personal-data-item col-50" ref={this.inputIBAN}>
                  <div className="inputBox">
                    <InputBox
                      type={"iban"}
                      placeholder="iban"
                      required={date.iban.required}
                      errorCode={date.iban.errorCode}
                      value={date.iban.value}
                      onChange={(value: string) =>
                        this.changeValue(value, "date", "iban")
                      }
                      disabled={isReadOnly}
                    />
                  </div>
                </div>
                <div className="personal-data-item col-50">
                  <div className="selectBox">
                    <SelectBox
                      labelText=""
                      required={date.payType.required}
                      errorCode={date.payType.errorCode}
                      optionsText={policy.productPayTypes}
                      optionKey={"value"}
                      optionValue={"label"}
                      initialValue={date.payType.value}
                      onChange={(value: string) =>
                        this.changeValue(value, "date", "payType")
                      }
                      disabled={isReadOnly}
                    />
                    <label>Tipo de pago</label>
                  </div>
                </div>
              </div>
            )}
            <div className="personal-data-row">
              <div className="personal-data-item col-50">
                <div className="inputBox">
                  <CalendarBox
                    labelText=""
                    initialValue={date.starterDay.value}
                    disabled={urlString.indexOf("asegurado") > -1 || isReadOnly}
                    minDate={this.getMinCalendarValue(policy.product)}
                    maxDate={this.getMaxCalendarValue(policy.product)}
                    onChange={(value: string) => {
                      this.changeValue(value, "date", "starterDay");
                    }}
                  />
                  <label>Fecha de Inicio</label>
                </div>
              </div>
              {capital && capital.min && (
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      placeholder={`capital a asegurar (min: ${currencyFormat(
                        capital.min
                      )} € - max: ${currencyFormat(capital.max)} €)`}
                      labelText={""}
                      required={date.capital.required}
                      errorCode={date.capital.errorCode}
                      disabled={useTakerData || isReadOnly}
                      type={"currency"}
                      value={date.capital.value}
                      onBlur={(value: string) =>
                        this.changeValue(value, "date", "capital")
                      }
                    />
                  </div>
                </div>
              )}
              {isPets && !hiring && (
                <>
                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <InputBox
                        placeholder="Cantidad de mascotas a asegurar"
                        required={date.numPets.required}
                        errorCode={date.numPets.errorCode}
                        value={date.numPets.value}
                        type="number"
                        onChange={(value: string) =>{
                          let coverageArray = []
                          this.changeValue(value, "date", "numPets");
                            for (let index = 0; index < parseInt(value); index++) {
                              coverageArray.push(this.getCoverages(coverages));
                            }
                          formData.coverages = coverageArray
                          }
                        }
                      />
                    </div>
                  </div>
                  <div className="personal-data-item col-50">
                    <div className="selectBox">
                      <SelectBox
                        labelText=""
                        required={paymentType.payment.required}
                        errorCode={paymentType.payment.errorCode}
                        optionsText={policy.productPayTypes}
                        initialValue={paymentType.payment.value}
                        defaultValue={paymentType.payment.value}
                        optionKey={"value"}
                        optionValue={"label"}
                        onChange={(value: string) =>
                          this.changeValue(value, "paymentType", "payment")
                        }
                        disabled={isReadOnly}
                      />
                      <label>Periodicidad de pago</label>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

         {formData.date && formData.date.numPets && formData.date.numPets.value >0 && isSimulation &&getTablesNumberMascots(coverages)} 
         
          {productInternalName === PRODUCT_TYPE.WOMAN && this.props.insured && (
            <div className="personal-data-row">
              <div className="personal-data-item col-25 bottomMargin20">
                <DicotomicButton
                  labelText="Tipo de garantía"
                  options={WOMAN_TYPES}
                  disabled={isReadOnly}
                  initialValue={selectedWomanType}
                  onChange={(value: string) => {
                    this.handleChangeWomanType(value);
                  }}
                />
              </div>
              <div className="personal-data-item col-75" />
            </div>
          )}
         {insured && !isPets &&
            this.renderCoverages(coverages, 3)}
          {formData.coverages &&
            formData.coverages["DC04"] &&
            formData.coverages["DC04"].value &&
            insured && (
              <div className="personal-data-container">
                <div className="personal-data-row">
                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <SearchBox
                        onChange={(ev: any) =>
                          this.changeValueSearchBox(
                            ev.target.value,
                            "foreignAddress",
                            "country"
                          )
                        }
                        labelText=""
                        optionsText={countriesFiltered}
                        optionKey={"value"}
                        optionValue={"label"}
                        initialValue={
                          foreignAddress
                            ? foreignAddress.country.value.label || ""
                            : ""
                        }
                        required={
                          foreignAddress
                            ? foreignAddress.country.required
                            : false
                        }
                        errorCode={
                          foreignAddress ? foreignAddress.country.errorCode : ""
                        }
                        isCountry={true}
                        onSelect={(value: any) =>
                          this.changeValue(value, "foreignAddress", "country")
                        }
                      />
                      <label>País de residencia en el extranjero</label>
                    </div>
                  </div>
                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <InputBox
                        placeholder="Dirección en el extranjero"
                        labelText=""
                        required={
                          foreignAddress
                            ? foreignAddress.address.required
                            : false
                        }
                        errorCode={
                          foreignAddress ? foreignAddress.address.errorCode : ""
                        }
                        value={
                          foreignAddress ? foreignAddress.address.value : ""
                        }
                        onChange={(value: string) =>
                          this.changeValue(value, "foreignAddress", "address")
                        }
                        disabled={isReadOnly}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          {isDeathSimulation && (
            <div className="personal-data-container p3n3">
              <div className="personal-data-row">
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      required={true}
                      disabled={address.postalCode.disabled || isReadOnly}
                      errorCode={address.postalCode.errorCode}
                      placeholder="Código postal"
                      value={
                        address.postalCode.value.label ||
                        address.postalCode.value ||
                        ""
                      }
                      maxLength={5}
                      type={"number"}
                      onChange={(value: string) =>
                        this.changeValue(value, "address", "postalCode")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*address2Prop && (
          <div className="personal-data-container p3n3">
            <div className="personal-data-row">
              <div className="personal-data-item col-100">
                <InputBox
                  labelText="Número de asegurado"
                  required={address2.numberInsured.required}
                  disabled={address2.numberInsured.disabled}
                  errorCode={address2.numberInsured.errorCode}
                  value={address2.numberInsured.value}
                  onChange={(value: string) =>
                    this.changeValue(value, "address2", "numberInsured")
                  }
                />
              </div>
            </div>
            <div className="personal-data-row">
              <div className="personal-data-item col-50">
                <SearchBox
                  onChange={(ev: any) =>
                    this.changeValueSearchBox(ev, "address2", "country")
                  }
                  labelText="País"
                  optionsText={countries}
                  optionKey={"code"}
                  optionValue={"label"}
                  initialValue={address2.country.value.label || ""}
                  required={address2.country.required}
                  disabled={address2.country.disabled}
                  errorCode={address2.country.errorCode}
                  onSelect={(value: string) =>
                    this.changeValue(value, "address2", "country")
                  }
                />
              </div>
              <div className="personal-data-item col-50">
                <InputBox
                  labelText="Provincia"
                  value={address2.province.value}
                  required={address2.province.required}
                  disabled={address2.province.disabled}
                  errorCode={address2.province.errorCode}
                  onChange={(value: string) =>
                    this.changeValue(value, "address2", "province")
                  }
                />
              </div>
            </div>

            <div className="personal-data-row">
              <div className="personal-data-item col-50">
                <InputBox
                  labelText="Localidad"
                  value={address2.city.value}
                  required={address2.city.required}
                  disabled={address2.city.disabled}
                  errorCode={address2.city.errorCode}
                  onChange={(value: string) =>
                    this.changeValue(value, "address2", "city")
                  }
                />
              </div>
              <div className="personal-data-item col-50">
                <SelectBox
                  labelText="Tipo de vía"
                  required={address2.streetType.required}
                  disabled={address2.streetType.disabled}
                  errorCode={address2.streetType.errorCode}
                  optionsText={TYPE_STREET}
                  optionKey={"value"}
                  optionValue={"label"}
                  initialValue={address2.streetType.value}
                  onChange={(value: string) =>
                    this.changeValue(value, "address2", "streetType")
                  }
                />
              </div>
            </div>
            <div className="personal-data-row">
              <div className="personal-data-item col-75">
                <InputBox
                  labelText="Domicilio"
                  required={address2.street.required}
                  disabled={address2.street.disabled}
                  errorCode={address2.street.errorCode}
                  value={address2.street.value}
                  onChange={(value: string) =>
                    this.changeValue(value, "address2", "street")
                  }
                />
              </div>
              <div className="personal-data-item col-25">
                <InputBox
                  required={address2.postalCode.required}
                  disabled={address2.postalCode.disabled}
                  errorCode={address2.postalCode.errorCode}
                  labelText="Código postal"
                  value={address2.postalCode.value}
                  onChange={(value: string) =>
                    this.changeValue(value, "address2", "postalCode")
                  }
                />
              </div>
            </div>
            <div className="personal-data-row">
              <div className="personal-data-item col-25">
                <InputBox
                  labelText="Número"
                  value={address2.streetNumber.value}
                  required={address2.streetNumber.required}
                  disabled={address2.streetNumber.disabled}
                  errorCode={address2.streetNumber.errorCode}
                  onChange={(value: string) =>
                    this.changeValue(value, "address2", "streetNumber")
                  }
                />
              </div>
              <div className="personal-data-item col-25">
                <InputBox
                  labelText="Escalera"
                  value={address2.stairs.value}
                  required={address2.stairs.required}
                  disabled={address2.stairs.disabled}
                  errorCode={address2.stairs.errorCode}
                  onChange={(value: string) =>
                    this.changeValue(value, "address2", "stairs")
                  }
                />
              </div>
              <div className="personal-data-item col-25">
                <InputBox
                  labelText="Piso"
                  value={address2.floorNumber.value}
                  required={address2.floorNumber.required}
                  disabled={address2.floorNumber.disabled}
                  errorCode={address2.floorNumber.errorCode}
                  onChange={(value: string) =>
                    this.changeValue(value, "address2", "floorNumber")
                  }
                />
              </div>
              <div className="personal-data-item col-25">
                <InputBox
                  labelText="Puerta"
                  value={address2.door.value}
                  required={address2.door.required}ok
                  disabled={address2.door.disabled}
                  errorCode={address2.door.errorCode}
                  onChange={(value: string) =>
                    this.changeValue(value, "address2", "door")
                  }
                />
              </div>
            </div>
          </div>
        )*/}
          {isDeathFirstStep && (
            <div className="personal-data-container">
              <div className="personal-data-row">
                {isDeathSimulation &&
                  payment &&
                  payment.payType &&
                  payment.payType.required && (
                    <div className="personal-data-item col-50">
                      <div className="selectBox">
                        <SelectBox
                          labelText=""
                          required={payment && payment.payType.required}
                          errorCode={payment && payment.payType.errorCode}
                          optionsText={policy.productPayTypes}
                          initialValue={payment && payment.payType.value}
                          defaultValue={payment && payment.payType.value}
                          optionKey={"value"}
                          optionValue={"label"}
                          onChange={(value: string) =>
                            this.changeValue(value, "payment", "payType")
                          }
                          disabled={isReadOnly}
                        />
                        <label>Periodicidad de pago</label>
                      </div>
                    </div>
                  )}
                <div className="personal-data-item col-50">
                  <div className="selectBox">
                    <SelectBox
                      labelText=""
                      disabled={
                        !(
                          deathServicesDropdown &&
                          deathServicesDropdown.length > 0
                        ) || isReadOnly
                      }
                      required={isDeathFirstStep && formData.serviceCodeBlock && formData.serviceCodeBlock.serviceCode.required}
                      errorCode={
                        serviceCodeBlock &&
                        serviceCodeBlock.serviceCode.errorCode
                      }
                      optionsText={deathServicesDropdown}
                      optionKey={"value"}
                      optionValue={"label"}
                      initialValue={
                        serviceCodeBlock && serviceCodeBlock.serviceCode.value
                      }
                      onChange={(value: string) => {
                        this.changeValue(
                          value,
                          "serviceCodeBlock",
                          "serviceCode"
                        );
                      }}
                    />
                    <label>Servicio asociado</label>
                  </div>
                </div>
              </div>
              {deathServicesDescriptions[
                serviceCodeBlock && serviceCodeBlock.serviceCode.value
              ] && (
                  <div className="personal-data-row death-services-block">
                    <div className="personal-data-item col-100">
                      <div className="personal-data-death-services-title">
                        DESGLOSE DEL SERVICIO SELECCIONADO
                      </div>
                      <ul className="personal-data-death-services-item-container">
                        {deathServicesDescriptions[
                          serviceCodeBlock && serviceCodeBlock.serviceCode.value
                        ].map((serviceDesc: string, index: number) => (
                          <li
                            key={`${serviceDesc}-${index}`}
                            className="personal-data-death-services-item"
                          >
                            {serviceDesc}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
            </div>
          )}
          {
            // !!hiring &&
            //   !!isDeath &&
            //   !(
            //     !!this.props.history.location.state &&
            //     !!this.props.location.state.simulationId
            //   )
            history.location.pathname ===
            "/contratacion/nueva/decesos/tomador" &&
            hiringDeath && (
              <div className="personal-data-container">
                <div className="personal-sub-header">
                  Declaración de buen estado de salud
                </div>
                <div className="personal-data-row">
                  <div className="personal-data-item col-100 checkbox-overflow">
                    <CheckboxItem
                      onChange={(e: any) =>
                        this.changeValue(
                          e.target.checked,
                          "hiringDeath",
                          "checkedConditions"
                        )
                      }
                      checked={hiringDeath.checkedConditions.value || ""}
                      checkboxText={
                        "El tomador del seguro declara que en el momento de la contratación ninguno de los asegurados padece enfermedad grave alguna que pueda ser causa de fallecimiento ni ha sufrido accidentes cuyas secuelas no sean ya definitivas."
                      }
                      className={"blue"}
                      withTab={true}
                      showError={hiringDeath.checkedConditions.error}
                      errorText={
                        ERROR_MESSAGES[
                        hiringDeath.checkedConditions.errorCode
                        ]
                      }
                    />
                  </div>
                </div>
              </div>
            )
          }
          <div className="personal-data-container">
            <div className="personal-data-row">
              <div className="personal-data-buttons">
                <div className="personal-data-buttons__item">
                  <MainButton
                    withTab={true}
                    icon={true}
                    type={"white"}
                    iconImg={images.IconArrowLeftBlue}
                    text="Atrás"
                    onClick={() => this.props.goBack ? this.props.goBack() : isPets? history.push("/contratacion/nueva") : this.props.history.goBack()}
                  />
                </div>
                <div className="personal-data-buttons__item">
                  <MainButton
                    withTab={true}
                    text="Siguiente"
                    onClick={() => {this.toValidate()}}
                  />
                </div>
              </div>
            </div>
          </div>
          {modal === "correct-address" && (
            <InfoModal
              mainText={"La dirección es correcta"}
              close={() => this.setState({ modal: "" })}
              active={true}
              img={images.IconCheckGreen}
            />
          )}
          {showModal && (
            <InfoModal
              mainText={"Domicilio incorrecto. Por favor, verifique si los datos son correctos o debe modificarlos. "}
              extraText={this.state.infoModalText}
              close={() => this.setState({ showModal: false })}
              active={true}
              img={images.IconInfoBlue}
            />
          )}
        </div>
      </DataContainer>
    );
  }
}

export default withGeneral(withDynamicProducts(withCoverages(withPolicy(withRouter(DataLayout)))));