import styled from 'styled-components';

export const InsuredMembersContainer = styled.div`

    .inflow-members-sidebar {
        position: fixed;
        top: 90px;
        right: 50px;
        width: 250px;
    }

    .inflow-members-sidebar-wrapper {
        max-height: calc(100vh - 130px);
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .wrapper-content-table {
        margin-bottom: 10px;
        min-width: 100%;
        max-width: 1250px;
        overflow-x: auto;

        .table {
            min-width: 1020px;
        }
    }

`