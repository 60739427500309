import GeneralServices from "./GeneralServices";

class CampaignsServices {
  getCampaigns = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `campaigns?colective=${args.colective}&combinacion_comercial=${args.combinacion_comercial}&fecha_inicio=${args.fecha_inicio}`
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };
}

export default new CampaignsServices();
