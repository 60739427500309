import styled from "styled-components";
import { colors } from "../../assets";
import { BodyTextContainer } from "../../components/components-text/body-text/body-text-styles";
import { images } from "../../assets";

export const HealthQuestionnaireTableContainer = styled.div`
.priceModel {
    margin-top: 15px
}

.checkedClass {
  box-shadow: inset 0 0 0 6px #ababab !important
}

.selectBox {
    height: auto;
    margin-bottom: 20px;
}
.result-simulation-special-row {
    width: 100%;
    display: flex;
    float: left;
    margin-bottom: 20px;
}
.col-10 {
    width: 10%;
    &.number {
      text-align: right;
      margin-right: 30px;
    }
}
.col-15 {
    width: 15%;
}
.col-20 {
    width: 20%;
}
.col-25 {
    width: 25%;
}
.col-2 {
    width: 20%;
}
.col-30 {
    width: 30%;
}
.col-35 {
    width: 35%;
}
.col-40 {
    width: 40%;
}
.col-50 {
    width: 50%;
}
.col-60 {
    width: 60%;
}
.col-70 {
    width: 70%;
}
.detail-amount {
    text-align: right;
}
.tab-label-title {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background-color: #004a8f;
    color: #fff;
    border: 1px solid #f4f4f4;
    font-size: 18px;
    height: 60px;

}
.tab-content-table {
    display: flex;
    justify-content: space-between;
    border: 1px solid #f4f4f4;
}
.table-campaign {
    width: 96%;
    thead {
        border-bottom: 1px solid ${colors["PR-001-100"]};
    }    
    th {
        padding: 10px 15px;
        color: ${colors["FL-001-Default"]};
        .highlighted { color: ${colors["TX-001-50"]};}
        background-color: ${colors["PR-001-100"]};

        p {
            color: ${colors["FL-001-Default"]};
        }
    }
    td {
        > div {
            padding: 8px 0;
            font-size: 16px;
        }
    
        ${BodyTextContainer} {
            display: inline-block;
            &.highlighted { font-weight: 600;}
        }
    }
}
.float-right-cell {
    float: right;
}
.amount {
    text-align: right;
}

.data-flow-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  .content-wrapper {
    overflow: initial;
    padding-right: 16px;
    padding: 20px;
    background-color: #fbfbfb;
    display: block;
    width: 100%;
    float: left;
  }
  @media (min-width: 320px) {
    .content-wrapper {
      width: 55% !important;
    }
  }
  @media (min-width: 1201px) {
    .content-wrapper {
      width: 100% !important;
    }
  }
  .subquestionnaire {
    margin-left: 10px;
  }

  .question {
    margin: 5px;
    margin-bottom: 15px;
    input {
      border: none !important;
    }

    > .col-50 {
      width: 50%;
    }

    &.isRow {
      .inputBox {
        margin-top: 12px;
        height: auto;
      }

      .personal-data-item {
        width: 100%;
      }
    }
  }

  .question-title {
    margin: 5px;
    margin-bottom: 10px;   
    position: relative;
    padding-left: 20px;

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      background: #e40202;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }

    h6 {
      margin-bottom: 10px;
      padding-bottom: 20px;
      border: none;
    }

    ~ .question {
      margin-bottom: 30px;
      padding-left: 25px;
    }
  }

  fieldset.subquestionnaire {
    .subquestionnaire {
      margin-top: 20px;
      margin-left: 15px;

      h4 {
        font-size: 20px;
      }

      .content-wrapper {
        margin-top: 0;
      }
    }
  }

  .radioquestion {
    margin: initial;
  }

  .data-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 30px;
  }

  .margin5 {
    margin: 5px;
  }

  .questionnaire-special-title {
    color: ${colors["PR-001-100"]};
    border-bottom: 1px solid ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .questionnaire-special-title-buttons {
    display: flex;
    width: 52%;
    justify-content: flex-end;

    > div {
      width: 45%
      margin: 0 1%;
    }
    > div.icon-check-block {
      width: 5%
      margin: 0 1%;
    }
  }

  .healt-questionnaire-first-section {
    > div {
      width: 25%;

      &.
    }
  }

  .first-section-item {
    float: left;
  }

  .second-section-item {

  }

  .third-section-item {

  }

  .hide-item {
    display: none;
  }
}

.custom-file-upload-button {
    background-color: #e4e4e4;

    > input[type="file"] {
      display:none;

      ~ div {
        background-color: ${colors["PR-001-100"]};
        color: #fff;
        border-radius: 0px 3px 3px 0px;

        &:before {

          content: url(${images.IconSearchWhite});
          position: absolute;
          width: 15px;
          height: 15px;
          left: 15px;
        }
      }

    &:disabled {
      ~ div {
        background-color: #e2e2e2;
        border-radius: 0px 3px 3px 0px;
        color: #ababab;

        &:before {
          content: url(${images.IconSearchGray});
        }
      }
    }        
  }
}

.isRow{
  width: 45%;
  float: left;
  margin: 0% 5% 4% 0;
}

.isQuestionnaire textarea {
  width: 100%;
  height: auto;
}

.counter-field-main {
  height: 48px;

  .counter-button {
    height: 24px;
  }
}

.subquestionnaire {
  h4 {
    color: ${colors["PR-001-100"]};
    padding: 18px;
  }

  .subquestionnaire {
    h4 {
      background: transparent;
      color: #3f3f3f;
      padding: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 25px;
      width: 100%;
      display: block;
      float: none;
      clear: both;
    }
  }
}

.ushEP {
  padding: 0;

  &:before .disabled {
    width: 7px;
    height: 7px;
    top: 4px;
    
    &.disabled {
      background: #fff;
    }
  }

  .counter-field-topbar {
    padding-left: 15px;
  }

  .counter-field-main {
    .input-counter-error {
      position: absolute;
      top: 63px;
    }
  }
}

.counter-input-field {
  width: 100%;
}

.addButton {
  float: left;
  width: 5%;
  margin-right: 2%;
  max-height: 25px;
  max-width: 25px;
}

.dicotomic-button-label {
  
    padding-left: 15px;
  
}

/* .upload-buttons {
  > div {
    background-color: #fff;
    color: ${colors["PR-001-100"]};
    border: 1px solid ${colors["PR-001-100"]};

    &:hover {
      background-color: ${colors["PR-001-100"]};
      color: #fff;
    }

    &.disabled {
                           
      pointer-events: none;
      cursor: default;
      background-color: #e2e2e2;          
      color: #ababab;  
      border: none;
      
      .main-button-icon {
        display: none;
      }

      &:before {
        content: url(${images.IconUploadGrey});
      }
    }
  }

  > div {     

    &:hover {
      .main-button-icon {
        display: none;
      }

      &:before {
        content: url(${images.IconUploadWhite});       
      }
    }
  } */


@media (max-width: 1140px) {
  .isRow{
    width: 95%;

    .inputBox {
      margin-bottom: 0px;
    }
  }

  .addButton {
    width: 100%;   
  }

  .questionnaire-special-title {    
    flex-flow: column;
  }

  .questionnaire-special-title-buttons {
    flex-flow: column;
  }

  .questionnaire-special-title-buttons {
    display: flex;
    flex-flow: column;
    width: 100%;
    
    > div {
      width: 100% 
      margin: 5% 0 0 0;
    }
  }
}

.switchBox.disabled {
  filter: grayscale(1);
  pointer-events: none;
}

.icon-check-hidden {
  display: none;
}

.icon-check-block {
  display: block;
  img {
    width: 22px;
    margin-top: 15px;
  }
}`;
