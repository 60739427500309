import React from "react";
import { Title03TextContainer } from "./title-03-text-styles";

interface Title03TextProps {
    children: any;
    className?: string;
    color?: string;
}

const Title03Text = ({children = '', className='', color=''}:Title03TextProps) => {
        return (
        <Title03TextContainer color={color} className={className}>
            {children}
        </Title03TextContainer>
    )
} 
export default Title03Text;