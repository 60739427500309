import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { LoginContainer } from './login-style';
import { images } from '../../assets';
import MainButton from '../../components/main-button';
import H4Text from '../../components/components-text/h4-text';
import H1Text from '../../components/components-text/h1-text';
import HeadsTitleText from '../../components/components-text/heads-title-text';
import { withGeneral } from '../../context/mixin/with-general';
import { IAuthService } from '../../auth/AuthService';

interface LoginProps extends RouteComponentProps {
  authService: IAuthService;
  isLocked?: boolean;
}

class LoginLayout extends React.Component<LoginProps, {}> {
  containerRef: any;

  constructor(props: LoginProps) {
    super(props);
    this.containerRef = React.createRef();
  }

  loginOIDC = async () => {
    const { authService } = this.props;
    await authService.login();
  }

  render() {

    return (
      <LoginContainer>
        <div className="login-main-container">
          <div className="login-main-pic">
            <img src={images.LogoAsisaBlue} alt="ASISA" />
          </div>
          <div className="login-main-title">
            <div className="login-main-title__portal">
              <H4Text>Portal de</H4Text>
            </div>
            <div className="login-main-title__subject">
              <H1Text>Mediadores</H1Text>
            </div>
            <div className="login-main-title__country">
              <HeadsTitleText>España</HeadsTitleText>
            </div>
          </div>
          <div className="login-form">
            <div className="login-idserver-button">
              <div className="login-idserver-button__title">
                <p>{ !this.props.isLocked ? 
                  'ACCEDE CON TU CUENTA' : 
                  'APLICACIÓN EN MANTENIMIENTO'
                }</p>
              </div>
              <div className="login-idserver-button__text">
                <p>
                { !this.props.isLocked ? 
                  'Utiliza tus credenciales o tu cuenta de correo corporativa para acceder de forma automática:':
                  'Este portal se encuentra actualmente fuera de servicio, pronto volverá a estar disponible. Disculpen las molestias.' 
                }
                </p>
              </div>
              { !this.props.isLocked && 
                <div className="login-idserver-button__button">
                  <MainButton text="Acceder" type="" onClick={this.loginOIDC} />
                </div>
              }
            </div>
          </div>
        </div>

        <div className="login-main-bottom-wave">
          <img src={images.BigLoginBottomWave} alt="BigLoginBottomWave" />
        </div>

        <div className="login-main-right-wave">
          <img src={images.BigLoginRightWave} alt="BigLoginRightWave" />
        </div>

      </LoginContainer>
    );
  }
}

export default withGeneral(withRouter(LoginLayout));
