import styled from "styled-components";
import { colors } from "../../assets/colors";

export const PhoneBoxContainer = styled.div`
  position: relative;

  &.show-list {
    z-index: 12;
    .input-box-main-field-select {
      background: #ffffff;
      outline: none;
    }
    .input-box-main-list {
      display: block;
    }
  }

  &.required {
    .input-box-main-field-select,
    .input-box-main-field-input {
      &:before {
        content: "";
        z-index: 9;
        position: absolute;
        top: 22px;
        left: 4px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: transparent;
      }
    }
  }


  &.disabled {
    pointer-events: none;

    .input-box-topbar-label {
      color: ${colors["TX-001-30"]};
    }

    .input-box-main {
      
      
      input {
        color: #989898 !important;
        background: ${colors["FL-001-Disabled"]};
        border: 1px solid #B5BCC6 !important;
      }
    }

    .input-box-main-field {
      background: ${colors["FL-001-Disabled"]};
      border-bottom: 1px solid ${colors["TX-001-30"]};
      color: ${colors["TX-001-30"]};

      &:before {
        background: ${colors["TX-001-30"]};
      }
    }
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
  }

  .input-box-topbar-label {
    color: ${colors["PR-001-50"]};
    text-transform: uppercase;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 29;
    width: 260px;
    right: 0;
  }

  .input-box-error {
    color: ${colors["ER-001-100"]};
  }

  .input-box-main-field {
    position: relative;
    width: 100%;
    padding: 13px 18px 9px;
    padding-right: 30px;
    transition: all 0.2s ease-in-out;
    /* margin-bottom: 4px; */
    color: ${colors["PR-001-100"]};
    border-bottom: 1px solid ${colors["PR-001-100"]};
    cursor: pointer;
    height: 48px;

    p {
      cursor: inherit;
    }

    &:hover {
      background: #f0fafd;
    }

    &:focus {
      background: #ffffff;
      outline: none;
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 20px;
    z-index: 10;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-list {
    position: absolute;
    top: calc(100% + 10px);
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 12;
    background: #ffffff;
    width: 100%;
    color: ${colors["PR-001-100"]};
    max-height: 250px;
    overflow: scroll;

    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;

      span {
        margin-left: 8px;
        font-size: 14px;
        color: ${colors["TX-001-50"]};
      }

      &:hover {
        background: #f0fafd;
      }

      &.no-data {
        font-family: "museo sans";
        font-size: 14px;
        line-height: 20px;
        color: ${colors["TX-001-50"]};
        padding: 15px;
      }
    }
  }

  .input-box-main {
    display: flex;
  }

  .input-box-main-field-select {
    position: relative;
    width: 100%;
    cursor: pointer;
    margin-right: 20px;
    max-width: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &:hover {
      background: #f0fafd;
    }
    input {
      &:focus {
        background: #ffffff;
        outline: none;
      }
    }
  }

  input {
    font-family: "museo sans";
    line-height: 24px;
    font-size: 16px;
    width: 100%;
    font-weight: 300;
    color: ${colors["PR-001-100"]};
    padding: 13px 18px 9px;
    transition: all 0.2s ease-in-out;
    padding-right: 30px;
    /* margin-bottom: 4px; */
    height: 48px;
  }

  .input-box-main-field-input {
    position: relative;
    width: 100%;

    input {


      &:focus {
        background: #ffffff;
        outline: none;
      }
    }

    &:hover {
      background: #f0fafd;
    }

    &:focus {
      background: #ffffff;
      outline: none;
    }
  }
`;
