/** Ejemplos, para clases y props
    <PhoneBox
        // className="show-list show-tooltip error"
    />
*/

import React, { Component } from "react";
import { PhoneBoxContainer } from "./phone-box-style";
import { images } from "../../assets/images";
import TooltipBox from "../tooltip-box/tooltip-box";
import BodyText from "../components-text/body-text";
import NoteText from "../components-text/note-text";
import LabelText from "../components-text/label-text";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import Axios from "axios";
import { hasNumber, isNumeric, isValidNumber } from "../../utils/validation";
import { colors } from "../../assets";

interface PhoneBoxProps {
  className?: string;
  disabled?: boolean;
  icon?: string;
  iconFocus?: string;
  iconDisabled?: string;
  required?: boolean;
  errorCode?: string;

  initialValue?: string;
  onChange: (value: string, error?: boolean) => void;
  labelText: string;

  withTooltip?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;
}

interface PhoneBoxState {
  showTooltip: boolean;
  showPrefixList: boolean;
  prefix: string;
  number: string;
  search: string;
  foundPrefixes: Array<{ name: string; code: string }>;
  errorPrefix: string;
  errorNumber: string;
  focus: boolean;
  [key: string]: any;
}

class PhoneBox extends Component<PhoneBoxProps, PhoneBoxState> {
  private toggleContainer: any;

  constructor(props: PhoneBoxProps) {
    super(props);
    this.state = {
      showTooltip: false,
      showPrefixList: false,
      prefix: "+34",
      number: "",
      search: "",
      foundPrefixes: [],
      errorPrefix: "",
      errorNumber: "",
      focus: false,
    };

    this.toggleContainer = React.createRef();

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    window.addEventListener("click", this.onClickOutsideHandler);
    const { initialValue } = this.props;
    if (initialValue && initialValue !== "") {
      const phone: any = initialValue.split(" ");
      this.setState({ prefix: phone[0], number: phone[1] });
    }
  };

  componentDidUpdate(prevProps: PhoneBoxProps) {
    const { initialValue, errorCode } = this.props;
    const { errorNumber } = this.state
    if (
      initialValue &&
      prevProps.initialValue !== initialValue &&
      initialValue !== ""
    ) {
      const phone: any = initialValue.split(" ");
      this.setState({ prefix: phone[0], number: phone[1] });
    }

    if (
      prevProps.initialValue !== initialValue &&
      (!initialValue || initialValue === "")
    ) {
      this.setState({
        showTooltip: false,
        showPrefixList: false,
        prefix: "+34",
        number: "",
        search: "",
        foundPrefixes: [],
        errorPrefix: "",
        errorNumber: "",
        focus: false,
      });
    }
    if (initialValue && initialValue.length === 13 && errorNumber === "required") {
      this.setState({
        errorNumber: "",
      });
    }

  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClickOutsideHandler);
  }

  onClickOutsideHandler(event: Event) {
    if (
      this.state.showPrefixList &&
      !this.toggleContainer.current.contains(event.target)
    ) {
      this.setState({ showPrefixList: false });
    }
  }

  getPrefix(value: string) {
    return value.substr(0, value.indexOf(" "));
  }

  getNumber(value: string) {
    return value.substr(value.indexOf(" ") + 1);
  }

  onChangeHandler(e: any) {
    if (e.target.value) {
      isValidNumber(e.target.value) && this.handleNumberChange(e.target.value);
      return;
    }
    if (e.target.value === "") {
      this.handleNumberChange(e.target.value);
    }
  }

  onChangeHandlerPrefix(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value) {
      const prefixNumber = e.target.value.slice(1);
      isValidNumber(prefixNumber) && this.handleBlur("prefix", e.target.value);
    }
    if (e.target.value === "+") {
      this.handleBlur("prefix", "+");
    }
  }

  validate(field: string, callback?: Function) {
    const { required } = this.props;
    const { prefix, number } = this.state;

    if (required) {
      let error = "";
      if (field === "prefix") {
        if (prefix === "" || prefix === "+") {
          error = "required";
        }
        this.setState({ errorPrefix: error }, () => {
          if (callback) callback();
        });
      } else if (field === "number") {
        if (number === "") {
          error = "required";
        }
        this.setState({ errorNumber: error }, () => {
          if (callback) callback();
        });
      }
    } else {
      if (callback) callback();
    }
  }

  handleBlur(field: string, value: string) {
    const { onChange } = this.props;
    this.setState(
      { [field]: value, focus: false },
      () => {
        this.validate(field, () => {
          const { prefix, number, errorPrefix, errorNumber } = this.state;
          let hasError = errorPrefix !== "" || errorNumber !== "";
          let phone =
            prefix.charAt(0) === "+"
              ? `${prefix} ${number || ""}`
              : `+${prefix} ${number || ""}`;
          onChange(phone, hasError);
        });
      }
    );
  }

  handleSelectPrefix(prefix: string) {
    const { onChange } = this.props;
    this.setState(
      {
        prefix,
        search: prefix,
        showPrefixList: false,
      },
      () => {
        const { prefix, number } = this.state;
        let phone =
          prefix.charAt(0) === "+"
            ? `${prefix} ${number}`
            : `+${prefix} ${number}`;
        onChange(phone);
      }
    );
  }

  handleNumberChange(number: string) {
    const { onChange } = this.props;
    this.setState({ number }, () => {
      const { prefix, number } = this.state;
      let phone =
        prefix.charAt(0) === "+"
          ? `${prefix} ${number}`
          : `+${prefix} ${number}`;
      onChange(phone);
    });
  }

  render() {
    const {
      disabled,
      className,
      labelText,
      errorCode,
      withTooltip,
      tooltipTitle,
      tooltipText,
      required,
    } = this.props;
    const {
      showTooltip,
      showPrefixList,
      prefix,
      number,
      errorPrefix,
      errorNumber,
      focus,
    } = this.state;

    const error_text_prefix = errorPrefix
      ? ERROR_MESSAGES[errorPrefix]
      : errorCode
        ? ERROR_MESSAGES[errorCode]
        : "";
    const error_text_number = errorNumber
      ? ERROR_MESSAGES[errorNumber]
      : errorCode
        ? ERROR_MESSAGES[errorCode]
        : "";

    return (
      <PhoneBoxContainer
        className={`${className} ${disabled ? "disabled" : ""} ${required ? "required" : ""
          }`}
      >
        <div className="input-box-topbar">
          <div className="input-box-topbar-label">
            <LabelText
              color={showPrefixList || focus ? colors["SC-001-100"] : ""}
            >
              <p>{labelText}</p>
            </LabelText>
          </div>

          {withTooltip && (
            <div
              className="input-box-topbar-icon"
              onClick={() =>
                this.setState((prevState: PhoneBoxState) => ({
                  showTooltip: !prevState.showTooltip,
                }))
              }
            >
              <img src={disabled ? images.IconInfoGrey : images.IconInfoBlue} />
              <div className="input-box-tooltip">
                <TooltipBox
                  active={showTooltip}
                  tooltipTitle={tooltipTitle}
                  tooltipText={tooltipText}
                />
              </div>
            </div>
          )}
        </div>

        <div className="input-box-main">
          <div
            ref={this.toggleContainer}
            className={`input-box-main-field-select ${errorPrefix ? "error" : ""
              }  ${prefix !== "" ? "complete" : ""}`}
            onClick={() =>
              this.setState((prevState: PhoneBoxState) => ({
                showPrefixList: !prevState.showPrefixList,
              }))
            }
          >
            <div className="input-box-main-field-select-inputs">
              <input
                disabled={disabled}
                type="text"
                autoComplete="nope"
                value={prefix}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.onChangeHandlerPrefix(e) }}
                onBlur={(e) => {
                  this.handleBlur("prefix", e.target.value);
                }}
                onFocus={(e) => this.setState({ focus: true })}
                maxLength={4}
              />
            </div>
            {(errorPrefix) && (
              <div className="input-box-error">
                <NoteText>
                  <p>{`* ${error_text_prefix}`}</p>
                </NoteText>
              </div>
            )}
          </div>

          <div
            className={`input-box-main-field-input ${errorNumber || errorCode ? "error" : ""
              }  ${number !== "" ? "complete" : ""}`}
          >
            <input
              disabled={disabled}
              type="text"
              autoComplete="nope"
              value={number}
              // onChange={(e) =>
              //   isValidNumber(e.target.value) &&
              //   this.handleNumberChange(e.target.value)
              // }
              onChange={(e: any) => { this.onChangeHandler(e) }}
              onBlur={(e) => {
                this.handleBlur("number", e.target.value);
              }}
              onFocus={(e) => this.setState({ focus: true })}
              maxLength={prefix === "+34" || prefix === "+351" ? 9 : 13}
            />
            {(errorNumber || errorCode) && (
              <div className="input-box-error">
                <NoteText>
                  <p>{`* ${error_text_number}`}</p>
                </NoteText>
              </div>
            )}
          </div>
        </div>
      </PhoneBoxContainer>
    );
  }
}

export default PhoneBox;
