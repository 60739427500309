import React, { Component } from "react";
import { CollapsedSidebarContainer } from "./collapsed-sidebar-style";
import { images } from "../../assets";
import { withRouter } from "react-router";
import LeftInfoModal from "../left-info-modal";
import { withGeneral } from "../../context/mixin/with-general";
import TooltipBoxLight from "../tooltip-box-light/tooltip-box-light";
import { roleNotAllowed } from "../../constants";
import { STORAGE_KEY } from "../../constants/health";

const ADMIN = "ADMIN";

class CollapsedSidebarLayout extends Component<any, any> {
  state = {
    link: "",
    activeLeftModal: false,
    tooltipActive: 0,
    tooltipText: "",
  };
  notShowCollapsedSidebar = [
    "/",
    // "/home",
    "/simulacion/pendiente",
    "/simulacion/expirada",
    "/contratacion/pendiente",
    "/contratacion/realizada",
    "/polizas-clientes",
    "/liquidaciones-clientes",
    "/recibos-clientes",
    "/ficheros-eiac",
    // "/facturas",
    "/informes",
    "/contacto",
    "/permisos",
    "/aviso-contratacion",
    "/aviso-legal",
    "/politica-conservacion-datos",
    "/politica-adicional-rgpd",
    "/politica-cookies",
    "/politica-proteccion-datos",
    "/politica-conservacion-datos",
    "/detalle-simulacion-pendiente",
    "/informacion-adicional-rgpd",
    //"/asisa",
    "/notificaciones",
    "/preguntas-ocaso",
  ];
  notShowCollapsedSidebarId = [
    "/simulacion/pendiente/detalle/",
    "/contratacion/pendiente/detalle/",
    "/polizas-clientes/detalle",
    "/recibos-clientes/detalle",
  ];
  simulationPages = "/simulacion/nueva";
  hiringPages = "/contratacion/nueva";
  clientsPages = ["/"];
  campaignsPages = ["/campañas"];
  contactPages = ["/"];
  legalPages = ["/"];
  supplementsPages = ["/suplemento"];
  panelNotasPages = ["/panel-notificaciones"];
  gestorNotasPages = ["/gestor-notificaciones"];

  componentDidMount() {
    this.setState({ tooltipActive: 0, tooltipText: "" });
  }

  handleExit(link: string) {
    const { history } = this.props;
    const notModal =
      history.location.pathname.indexOf("campañas") > -1 ||
      history.location.pathname.indexOf("suplementos") > -1 ||
      history.location.pathname.indexOf("preguntas-frecuentes") > -1 ||
      history.location.pathname.indexOf("preguntas-ocaso") > -1 ||
      history.location.pathname.indexOf("panel-notificaciones") > -1 ||
      history.location.pathname.indexOf("gestor-notificaciones") > -1 ||
      history.location.pathname.indexOf("comparacion") > -1;
    const isHealth = history.location.pathname.indexOf("salud") > -1;
    if (notModal || (isHealth && (history.location.pathname.indexOf("resultado") > -1))) {
      this.setState({ link }, () => {
        this.navigate();
      });
    } else {
      this.setState({ link, activeLeftModal: true });
    }
    this.setState({ tooltipActive: 0, tooltipText: "" });
  }

  closeLeftModal() {
    this.setState({ activeLeftModal: false });
  }

  navigate() {
    const { link } = this.state;
    localStorage.removeItem(STORAGE_KEY.sKey);
    this.props.history.push(link);
  }

  setTooltip(e: any, text: string) {
    this.setState({
      tooltipActive: parseInt(e.target.getBoundingClientRect().top, 10),
      tooltipText: text,
    });
  }

  showOptanonSettingsDisplay = () => {
    var btn = document.getElementById("openCookieSettings");
    btn && btn.click();
  }

  render() {
    const { history, is404, is403, isCampaignConfiguration, user } = this.props;
    const { activeLeftModal, tooltipActive, tooltipText } = this.state;
    let notCollapsedSidebar: boolean = false;

    let simulationActivePage: string = "";
    let hiringActivePage: string = "";
    let supplementActivePage: string = "";
    let panelNotasActivePage: string = "";
    let gestorNotasActivePage: string = "";

    let clientsActivePage: boolean = false;
    let campaignsActivePage: string = "";
    let contactActivePage: boolean = false;
    let legalActivePage: boolean = false;
    
    if (this.notShowCollapsedSidebar.includes(history.location.pathname)) {
      notCollapsedSidebar = true;
    }

    if (
      this.notShowCollapsedSidebarId.filter(
        (item) => history.location.pathname.indexOf(item) !== -1
      ).length > 0
    ) {
      notCollapsedSidebar = true;
    }

    if (this.notShowCollapsedSidebar.includes(history.location.pathname)) {
      notCollapsedSidebar = true;
    }

    if (history.location.pathname.indexOf(this.simulationPages) > -1) {
      simulationActivePage = "active";
    }

    if (history.location.pathname.indexOf(this.hiringPages) > -1) {
      hiringActivePage = "active";
    }

    if (history.location.pathname.indexOf(this.supplementsPages) > -1) {
      supplementActivePage = "active";
    }

    if (this.clientsPages.includes(history.location.pathname)) {
      clientsActivePage = true;
    }

    if (this.campaignsPages.includes(history.location.pathname)) {
      campaignsActivePage = "active";
    }

    if (this.contactPages.includes(history.location.pathname)) {
      contactActivePage = true;
    }

    if (this.legalPages.includes(history.location.pathname)) {
      legalActivePage = true;
    }

    if (is404 || is403 || isCampaignConfiguration) {
      notCollapsedSidebar = true;
    }

    if (history.location.pathname.indexOf(this.panelNotasPages) > -1) {
      panelNotasActivePage = "active";
    }

    if (history.location.pathname.indexOf(this.gestorNotasPages) > -1) {
      gestorNotasActivePage = "active";
    }

    return notCollapsedSidebar ? (
      ""
    ) : (
      <>
        <CollapsedSidebarContainer>
          <div
            onClick={() => this.handleExit("/")}
            className="collapsed-sidebar-logo"
          >
            <img src={images.LogoAsisaCollapsedWhite} alt="Asisa" />
          </div>
          <div className="collapsed-sidebar-nav">
            <div className="collapsed-sidebar-main">
              {!isCampaignConfiguration && (
                <div
                  onClick={() => this.handleExit("/")}
                  className={`collapsed-sidebar-main-item`}
                  onMouseEnter={(e: any) => this.setTooltip(e, "Inicio")}
                  onMouseLeave={(e: any) => this.setState({ tooltipActive: 0 })}
                >
                  <img src={images.IconHomeWhite} alt="inicio" />
                </div>
              )}
              {!isCampaignConfiguration && (
                <div
                  onClick={() => this.handleExit("/simulacion/pendiente")}
                  className={`collapsed-sidebar-main-item ${simulationActivePage}`}
                  onMouseEnter={(e: any) => this.setTooltip(e, "Simulaciones")}
                  onMouseLeave={(e: any) => this.setState({ tooltipActive: 0 })}
                >
                  <img src={images.IconSimulationWhite} alt="simulaciones" />
                </div>
              )}
              {!isCampaignConfiguration && (
                <div
                  onClick={() => this.handleExit("/contratacion/pendiente")}
                  className={`collapsed-sidebar-main-item ${hiringActivePage}`}
                  onMouseEnter={(e: any) =>
                    this.setTooltip(e, "Contrataciones")
                  }
                  onMouseLeave={(e: any) => this.setState({ tooltipActive: 0 })}
                >
                  <img src={images.IconHiringWhite} alt="Contrataciones" />
                </div>
              )}
              {!isCampaignConfiguration && user.roles
                ? (!user.roles.includes(roleNotAllowed) && user.code) && (
                  <div
                    onClick={() => this.handleExit("/ficheros-eiac")}
                    className="collapsed-sidebar-main-item"
                    onMouseEnter={(e: any) => this.setTooltip(e, "Clientes")}
                    onMouseLeave={(e: any) =>
                      this.setState({ tooltipActive: 0 })
                    }
                  >
                    <img src={images.IconClientsWhite} alt="clientes" />
                  </div>
                )
                : !isCampaignConfiguration &&
                !user.roles && user.code && (
                  <div
                    onClick={() => this.handleExit("/ficheros-eiac")}
                    className="collapsed-sidebar-main-item"
                    onMouseEnter={(e: any) => this.setTooltip(e, "Clientes")}
                    onMouseLeave={(e: any) =>
                      this.setState({ tooltipActive: 0 })
                    }
                  >
                    <img src={images.IconClientsWhite} alt="clientes" />
                  </div>
              )}

              {
                !isCampaignConfiguration && (
                  <div
                    onClick={() => this.handleExit("/panel-notificaciones")}
                    className={`collapsed-sidebar-main-item ${panelNotasActivePage}`}
                    onMouseEnter={(e: any) => this.setTooltip(e, "Notificaciones")}
                    onMouseLeave={(e: any) => this.setState({ tooltipActive: 0 })}
                  >
                    <img src={images.BellWhiteIcon} alt="Notificaciones" />
                  </div>
                )
                
              }
              {/* <div
                  onClick={() => this.handleExit("/campañas")}
                  className={`collapsed-sidebar-main-item ${campaignsActivePage}`}
                  onMouseEnter={(e: any) => this.setTooltip(e, 'Campañas')}
                  onMouseLeave={(e: any) => this.setState({ tooltipActive: 0 })}
                >
                  <img
                    src={images.iconCampaignsWhite}
                  />
                </div> */}
              {isCampaignConfiguration && (
                <div
                  onClick={() => this.handleExit("/config-puntos")}
                  className="collapsed-sidebar-main-item"
                >
                  <img src={images.iconCampaignsWhite} alt="config" />
                </div>
              )}
            </div>
            <div className="collapsed-sidebar-footer">
            {
                !isCampaignConfiguration && user.roles && user.roles.includes(ADMIN) && (
                  <div
                    onClick={() => this.handleExit("/gestor-notificaciones")}
                    className={`collapsed-sidebar-main-item ${gestorNotasActivePage}`}
                    onMouseEnter={(e: any) => this.setTooltip(e, "Gestor de notificaciones")}
                    onMouseLeave={(e: any) => this.setState({ tooltipActive: 0 })}
                  >
                    <img src={images.BellWhiteIcon} alt="Gestor de notificaciones" />
                  </div>
                )
              }
              <div
                onClick={() => this.handleExit("/contacto")}
                className="collapsed-sidebar-footer-item"
                onMouseEnter={(e: any) => this.setTooltip(e, "Contacto")}
                onMouseLeave={(e: any) => this.setState({ tooltipActive: 0 })}
              >
                <img src={images.IconContactCollapsedWhite} alt="contacto" />
              </div>
              <div
                onClick={() => this.showOptanonSettingsDisplay()}
                className="collapsed-sidebar-footer-item"
                onMouseEnter={(e: any) =>
                  this.setTooltip(e, "Configuración de cookies")
                }
                onMouseLeave={(e: any) => this.setState({ tooltipActive: 0 })}
              >
                <img src={images.IconCookies} alt="configuracion cookies" />
              </div>
              <div
                onClick={() => this.handleExit("/aviso-legal")}
                className="collapsed-sidebar-footer-item"
                onMouseEnter={(e: any) =>
                  this.setTooltip(e, "Políticas legales")
                }
                onMouseLeave={(e: any) => this.setState({ tooltipActive: 0 })}
              >
                <img src={images.IconLegalCollapsedWhite} alt="politicas legales" />
              </div>
            </div>
          </div>
          {tooltipActive > 0 && (
            <div
              className={"collapsed-sidebar-tooltip"}
              style={{ top: tooltipActive - 10 }}
            >
              <TooltipBoxLight
                active={true}
                tooltipTitle={tooltipText}
                tooltipText={""}
                leftArrow={true}
              />
            </div>
          )}
        </CollapsedSidebarContainer>

        <LeftInfoModal
          className="warning"
          img={images.IconWarningYellow}
          mainTitle={`Vas a salir de la ${history.location.pathname.indexOf("simulacion") > -1
            ? "simulación"
            : history.location.pathname.indexOf("campañas") > -1
              ? "campañas"
                : history.location.pathname.indexOf("suplemento") > -1
                ? "modificación"
                : "contratación"
            }`}
          mainText={`Si sale del proceso de ${history.location.pathname.indexOf("simulacion") > -1
            ? "simulación"
            : history.location.pathname.indexOf("suplemento") > -1
                ? "suplemento"
                : "contratación"
            } se perderá los cambios que NO hayan sido guardados`}
          buttonText="Salir"
          active={activeLeftModal}
          close={() => this.closeLeftModal()}
          onClickAccept={() => this.navigate()}
        />
      </>
    );
  }
}
export default withGeneral(withRouter(CollapsedSidebarLayout));
