import GeneralServices from "./GeneralServices";

class CoveragesServices {
  getCountries = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        loader: args.loader === false ? false : true,
        endpoint: `constants/countries${args.params ? "?" + args.params.join("&") : ""
          }`
      });
    } catch (err) {
      return [];
    }
  };

  getCoverages = async (args: any) => {
    try {
      return GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `coverages?product=${args.coverageType}`,
        ignoreSetFalse: args.ignoreSetFalse,
      });
    } catch (err) {
      return [];
    }
  };
  getProductsCoverages = async (args: any) => {
    try {
      return GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `coverages/allCoverages`,
        ignoreSetFalse: args.ignoreSetFalse,
      });
    } catch (err) {
      return [];
    }
  };
  getCoveragesConfig = async (args: any) => {
    try {
      const configs = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `coverage-config/config`,
        ignoreSetFalse: args.ignoreSetFalse,
      });
      return configs.data
    } catch (err) {
      return [];
    }
  };

  getCoverageDescriptions = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `products?product=${args.productType}&postalCode=${args.postalCode}`
      });
      return response.data;
    } catch (err) {
      return [];
    }
  };
  
}

export default new CoveragesServices();
