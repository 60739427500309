import styled from "styled-components";

export const ItemCampaignGoalStyle = styled.div`
  display: flex;
  align-items: flex-start;
  font-family: "museo sans";
  .item-campaign-goal-icon {
    margin-right: 10px;
    background: rgba(179, 202, 220, 0.15);
    border-radius: 3px;
    min-width: 32px;
    height: 32px;
    padding: 4px;
  }
  .item-campaign-goal-content {
    color: #004f8b;
    &__title {
      font-size: 16px;
      line-height: 27px;
    }
    &__text {
      font-size: 16px;
      line-height: 27px;
      font-weight: 300;
    }
  }

  &.with-text {
    align-items: flex-start;
    .item-campaign-goal-content {
      color: #004f8b;
      &__title {
        margin-bottom: 4px;
      }
    }
  }
`;
