import React from "react";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../assets";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import GuideLine from "../../../../components/guide-line";
import MainButton from "../../../../components/main-button";
import { TravelInsuredDataContainer } from "./travel-insured-data-style"
import { IHealthCoverage } from "../../../../constants/health";
import { ErrorModalContainer } from "../../../../components/error-modal/error-modal-style";
import SelectBox from "../../../../components/select-box/select-box";
import SimulationServices from "../../../../services/SimulationServices";
import { withPolicy } from "../../../../context/mixin/with-policy";
import { TravelHireMenuHelper } from "../../../../components/travel-hire-menu/travel-hire-menu";
import { IUser } from "../../../../models/user-model";
import { IMediator } from "../../../../models/mediator-model";
import InputBox from "../../../../components/input-box/input-box";
import { TRAVEL_MAX_EFFECT_DAYS } from "../../../../services/url";
import HealthService from "../../../../services/HealthService";
import { getRelatedMediatorsCodes } from "../../../../utils/mediatorCode";
import MediatorServices from "../../../../services/MediatorServices";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../context/mixin/with-general";
import { formatCoveragesTravel } from "../../../../utils/coverages";
import { PRODUCT_TYPE } from "../../../../constants/policyType";
import TravelCountriesServices from "../../../../services/TravelCountriesServices";
import { SPANISH_COUNTRY_OPTION, TRAVEL_COUNTRIES_FIELDS } from "../../../../constants/optionsTravelPolicy";
interface TravelInsuredProps extends RouteComponentProps {
  setLoading: Function,
  match: any;
  user: IUser;
  policy: any;
  mediator: IMediator;
  data: any;
  coverages: any;
}

export interface TravelInsuredSimulationInterface {
  policy: TravelPolicyInfo,
  coverages: IHealthCoverage[],
}
export interface TravelPolicyInfo {
  departureDate: string,
  returnDate: string,
  residenceCountry: string,
  travelDestination: string,
  numPassengers: string,
}
interface TravelInsuredState {
  insuredData: TravelInsuredSimulationInterface,
  coverages: IHealthCoverage[],
  availableInsured: boolean,
  showLeftSearchBoxModal: boolean,
  modalErrorText: string,
  modalErrorShow: boolean,
  errorCodes: any,
  mediatorValue: string,
  mediatorData: {
    code: string;
    name: string;
    startDate: string;
    nif: string;
    email: string;
  },
  mediatorCode: {
    value: string,
    error: boolean,
    errorCode: string
  },
  mediator: {
    value: string,
    error: boolean,
    errorCode: string
  },
  relatedMediatorCodes: any,
  mediatorCodeSelected: string,
  residenceCountries: any[];
  travelDestinations: any[];
}

export class TravelInsuredDataLayout extends React.Component<TravelInsuredProps, TravelInsuredState> {

  constructor(props: any) {
    super(props);
    this.state = {
      insuredData: {
        policy: {
          departureDate: "",
          returnDate: "",
          residenceCountry: "",
          travelDestination: "",
          numPassengers: "",
        },
        coverages: [],
      },
      errorCodes: {
        departureDate: {
          error: false,
          errorCode: ""
        },
        returnDate: {
          error: false,
          errorCode: ""
        },
        residenceCountry: {
          error: false,
          errorCode: ""
        },
        travelDestination: {
          error: false,
          errorCode: ""
        },
        numPassengers: {
          error: false,
          errorCode: ""
        }
      },
      mediatorValue: `${props.user.name} ${props.user.lastname1} ${props.user.lastname2}`,
      mediatorData: {
        code: "",
        name: "",
        startDate: "",
        email: "",
        nif: "",
      },
      mediator: {
        value: '',
        error: false,
        errorCode: ''
      },
      mediatorCode: {
        value: '',
        error: false,
        errorCode: ''
      },
      relatedMediatorCodes: getRelatedMediatorsCodes(this.props.mediator),
      mediatorCodeSelected: props.selectedMediatorCode
        ? props.selectedMediatorCode
        : props.mediator.mediatorCode,
      modalErrorShow: false,
      showLeftSearchBoxModal: false,
      modalErrorText: "",
      coverages: [],
      availableInsured: false,
      residenceCountries: [],
      travelDestinations: [],
    }
  }

  componentDidMount = async () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
     let { mediator, mediatorCode } = this.state;
    let { user, policy } = this.props;

    mediator.value = `${user.name} ${user.lastname1} ${user.lastname2}`
    mediatorCode.value = user.code
    const { data } = this.props

    if (Object.keys(policy.product).length !== 0) {
      this.getTravelCountries()
    }

    if(data && data.taker && data.taker.policy){
      this.setState({insuredData: {
        policy: {
          departureDate: data.taker.policy.departureDate,
          returnDate: data.taker.policy.returnDate,
          residenceCountry: data.taker.policy.residenceCountry,
          travelDestination: data.taker.policy.travelDestination,
          numPassengers: data.taker.policy.numPassengers,
        },
        coverages: data.taker.coverages,
      }})
    }
    if(data && data.taker && data.taker.coverages){
      this.setState({coverages: data.taker.coverages })
    }
  }

  componentDidUpdate(prevProps: Readonly<TravelInsuredProps>, prevState: Readonly<TravelInsuredState>, snapshot?: any) {
    const { policy } = this.props;
    const { insuredData, residenceCountries } = this.state

    if (Object.keys(prevProps.policy.product).length === 0 && Object.keys(policy.product).length !== 0) {
      this.getCoverages()
      this.getTravelCountries()
      
      if (policy.product.products_internalName === PRODUCT_TYPE.TRAVEL_ALL_YEAR) {
        insuredData.policy.departureDate = moment().format("DD/MM/YYYY");
        insuredData.policy.returnDate = moment()
          .add(1, "years")
          .subtract(1, "days")
          .format("DD/MM/YYYY");
      }
    }

    if((prevState.residenceCountries.length === 0 && residenceCountries.length !== 0 && Object.keys(policy.product).length !== 0)
    || (Object.keys(prevProps.policy.product).length === 0 && Object.keys(policy.product).length !== 0 && residenceCountries.length !== 0)){
      insuredData.policy.residenceCountry = policy.product.products_internalName === PRODUCT_TYPE.TRAVEL_WELCOME ? '' : this.setSpanishResidenceCountry()
    }
  }

  async getCoverages() {
    const { setLoading, policy, coverages } = this.props;
    const { insuredData } = this.state
    const productType: string = policy.product.products_coverageName;
    let cov = []

    if (coverages && coverages[productType]) {
      cov = coverages[productType]
    } else {
      cov = await HealthService.getCoveragesFromDB(setLoading, true, productType)
      .catch((error) => {
        this.setState({ 
          modalErrorShow: true, 
          modalErrorText: `Se ha producido un error al obtener las coberturas. Vuelva a intentarlo más tarde.` 
        });
        throw error.statusText;
      });
    }

    if (cov !== undefined && cov.length) {
      insuredData.coverages = [...formatCoveragesTravel(cov)]
      this.setState({ coverages: [...formatCoveragesTravel(cov)] });
    }
  }

  toValidate = async () => {
    const { insuredData, errorCodes, coverages } = this.state;
    if (!insuredData.policy.residenceCountry || insuredData.policy.residenceCountry === "") {
      errorCodes.residenceCountry = { error: true, errorCode: 'required' }
    }
    if (!insuredData.policy.travelDestination || insuredData.policy.travelDestination === "") {
      errorCodes.travelDestination = { error: true, errorCode: 'required' }
    }
    if (!insuredData.policy.departureDate || insuredData.policy.departureDate === "") {
      errorCodes.departureDate = { error: true, errorCode: 'required' }
    }
    if (!insuredData.policy.returnDate || insuredData.policy.returnDate === "") {
      errorCodes.returnDate = { error: true, errorCode: 'required' }
    }
    if (!insuredData.policy.numPassengers || parseInt(insuredData.policy.numPassengers) === 0) {
      errorCodes.numPassengers = { error: true, errorCode: 'required' }
    }
    if (this.checkDate()) {
      errorCodes.returnDate = { error: true, errorCode: 'badDate' }
    }
    if (this.checkErrors()) {
      const { policy } = this.props;
      this.props.policy.setTravelSimulationData('insured', insuredData);
      this.props.policy.setTravelSimulationData('policyData', { colective: parseInt(policy.product.products_colectivo), combiproduct: parseInt(policy.product.products_combinacion_comercial), coverages });

      this.SimulateTravel()

    }
    this.setState({ errorCodes })
  }

  checkDate(): boolean {
    const { insuredData } = this.state
    const departureDate = moment(insuredData.policy.departureDate, "DD/MM/YYYY")
    const returnDate = moment(insuredData.policy.returnDate, "DD/MM/YYYY")

    return moment(returnDate).isBefore(departureDate) ? true : false
  }
  async SimulateTravel() {
    const { setLoading, history, policy} = this.props;
    const { insuredData, mediatorCodeSelected, coverages } = this.state;
    const productInternalName = policy.product.products_internalName

    const parsedCoverages = coverages.map((coverage) => {
      if (coverage.checked || coverage.required)
        return {
          type: coverage.code,
          code: coverage.code,
          capital: 1
        }
    })

    try {
      SimulationServices.travel({
        loader: true,
        setLoading: setLoading,
        data: {
          ...insuredData.policy,
          coverages: parsedCoverages,
          selectedMediatorCode: mediatorCodeSelected,
          productType: productInternalName
        },
      })
      .then((response: any) => {
        this.props.policy.setTravelSimulationData('simulationResponse', response);

        history.push(
          TravelHireMenuHelper.getSimulationStepNavigationPath(
            TravelHireMenuHelper.getSimulationStepFromNavigationRoute(
              this.props.match.url,
              productInternalName
            ) + 1,
            productInternalName
          )
        )
      }).catch(e => {
        if (e.data && e.data.errors && e.data.errors.warnings && e.data.errors.warnings.length > 0) {
          this.setState({ modalErrorShow: true, modalErrorText: e.data.errors.warnings[0] })
        } else {
          this.setState({ modalErrorShow: true, modalErrorText: 'Se ha producido un error. Vuelva a intentarlo más tarde.' })
        }

      });
    } catch (e) {
      console.error(e)
      this.setState({ modalErrorShow: true, modalErrorText: 'Se ha producido un error. Vuelva a intentarlo más tarde.' })
    }
  }
  checkErrors() {
    const { errorCodes } = this.state
    var check = true
    Object.values(errorCodes).forEach((error: any) => {
      if (error.error === true)
        check = false
    })
    return check
  }
  handleValueChange(value: string, input: string) {
    const { insuredData, errorCodes } = this.state
    switch (input) {
      case "residenceCountry":
        insuredData.policy.residenceCountry = value
        errorCodes.residenceCountry = { error: false, errorCode: '' }
        break;
      case "travelDestination":
        insuredData.policy.travelDestination = value
        errorCodes.travelDestination = { error: false, errorCode: '' }
        break;
      case "departureDate":
        insuredData.policy.departureDate = value
        errorCodes.departureDate = { error: false, errorCode: '' }
        break;
      case "returnDate":
        insuredData.policy.returnDate = value
        errorCodes.returnDate = { error: false, errorCode: '' }
        break;
      case "numPassengers":
        insuredData.policy.numPassengers = value
        errorCodes.numPassengers = { error: false, errorCode: '' }
        break;
    }

    this.setState({ insuredData, errorCodes })
  }
  async handleSelectMediatorChange(value: string) {
    this.props.policy.setMediatorSelectedCode &&
    this.props.policy.setTravelSimulationData('mediatorCode', value) && this.props.policy.setMediatorSelectedCode(value);
    this.setState({ mediatorCodeSelected: value });
    const mediatorInfo = {
      setLoading: () => { },
      loader: true,
      mediatorCode: value,
    };
    const mediatorName = await MediatorServices.getMediatorInfo(mediatorInfo);
    let { mediatorData, mediatorValue, mediator } = this.state;
    mediatorValue = mediatorName.mediatorName;
    mediatorData.name = value;
    mediatorData.code = value;
    mediatorData.email = mediatorName.email;
    //mediator.value = mediatorName.mediatorName

    this.setState({
      mediatorData, mediatorValue, mediator: {
        ...mediator,
        value: mediatorName.mediatorName
      }
    });
  }

  getTravelCountries = async() => {
    const { policy } = this.props
    try {
      const travelCountries = await TravelCountriesServices.getTravelCountriesByProduct({
        product: policy.product.products_internalName,
        loader: true,
        setLoading: () => { }
      })
      if(travelCountries && travelCountries.length){
        const travelDestinations = travelCountries.filter((country: any) => country.field === TRAVEL_COUNTRIES_FIELDS.TRAVEL_DESTINATION)
        const residenceCountries = travelCountries.filter((country: any) => country.field === TRAVEL_COUNTRIES_FIELDS.RESIDENCE_COUNTRY)
        this.setState({travelDestinations, residenceCountries})
      }
    } catch (error) {
      this.setState({ modalErrorShow: true, modalErrorText: 'Se ha producido un error al obtener los países de residencia y los destinos del viaje. Vuelva a intentarlo más tarde.'})
    }
    
  }

  setSpanishResidenceCountry = () => {
    const { residenceCountries } = this.state

    const spanishResidenceCountry = residenceCountries.find(rc => rc.label === SPANISH_COUNTRY_OPTION)
    const value = spanishResidenceCountry ? spanishResidenceCountry.value : ''
    
    return value
  }

  render() {

    const {
      insuredData,
      availableInsured,
      errorCodes,
      coverages,
      relatedMediatorCodes,
      mediatorCodeSelected,
      travelDestinations,
      residenceCountries
    } = this.state
    const { policy } = this.props
    const productInternalName = policy.product.products_internalName
    return (
      <TravelInsuredDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <h2>{'DATOS DEL SEGURO'}</h2>
          </div>
          <div className="data-flow-status-guide">
            <GuideLine
              steps={[0, 0, 0, 0]}
              currentStep={2}
            />
          </div>
        </div>
        <div className="data-wrapper">
          <div className="content-wrapper bottomMargin40">
            <div className="personal-data-wrapper">
              <div className="data-title">
                <h5>DATOS DEL VIAJE</h5>
              </div>
            </div>
            <div className="data-row colSin-100">
              <div className="colSin-100 topMargin20">
                <div className="colSin-50 rightPadding20">
                  <div className="selectBox">
                    <SelectBox
                      isMediator={true}
                      optionsText={relatedMediatorCodes}
                      optionKey={"value"}
                      optionValue={"label"}
                      defaultValue={mediatorCodeSelected}
                      onChange={(value: string) =>
                        this.handleSelectMediatorChange(value)
                      }
                      propValue={mediatorCodeSelected}
                      required
                    />
                    <label>Mediador Seleccionado</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="data-row colSin-100">
              <div className="colSin-50 rightPadding20 topMargin12">
                <div className="selectBox">
                  <SelectBox
                    placeholder="Selecciona un país de residencia"
                    initialValue={insuredData.policy.residenceCountry}
                    defaultValue={insuredData.policy.residenceCountry}
                    optionKey={"value"}
                    optionValue={"label"}
                    optionsText={residenceCountries}
                    required
                    errorCode={errorCodes.residenceCountry.errorCode}
                    onChange={(value: string) => {
                      this.handleValueChange(value, "residenceCountry");
                    }}
                    disabled={false}
                  />
                  <label>País de Residencia</label>
                </div>
              </div>
              <div className="colSin-50 rightPadding20 topMargin12">
                <div className="selectBox">
                  <SelectBox
                    initialValue={insuredData.policy.travelDestination}
                    placeholder="Selecciona un destino"
                    optionsText={travelDestinations}
                    required
                    errorCode={errorCodes.travelDestination.errorCode}
                    disabled={false}
                    optionKey={"value"}
                    optionValue={"label"}
                    defaultValue={insuredData.policy.travelDestination}
                    onChange={(value: string) => {
                      this.handleValueChange(value, "travelDestination");
                    }}
                  />
                  <label>Destino del viaje</label>
                </div>
              </div>
            </div>
            <div className="data-row colSin-100">
              {productInternalName !== PRODUCT_TYPE.TRAVEL_ALL_YEAR ?
                <>
                  <div className="colSin-33 rightPadding20 topMargin12">
                    <div className="inputBox">
                      <CalendarBox
                        initialValue={insuredData.policy.departureDate}
                        placeholder="Fecha de Ida"
                        errorCode={errorCodes.departureDate.errorCode}
                        minDate={moment().add(-1, 'day').toISOString()}
                        required
                        onChange={(value: string) => {
                          this.handleValueChange(value, "departureDate");
                        }}
                      />
                    </div>
                  </div>
                  <div className="colSin-33 rightPadding20 topMargin12">
                    <div className="inputBox">
                      <CalendarBox
                        initialValue={insuredData.policy.returnDate}
                        errorCode={errorCodes.returnDate.errorCode}
                        placeholder="Fecha de Vuelta"
                        minDate={insuredData.policy.departureDate !== "" ? moment(insuredData.policy.departureDate, "DD/MM/YYYY").add(-1, "seconds").toISOString(true) : moment().toISOString()}
                        maxDate={insuredData.policy.departureDate !== "" ? moment(insuredData.policy.departureDate, "DD/MM/YYYY").add(TRAVEL_MAX_EFFECT_DAYS, "days").toISOString(true) : moment().toISOString()}
                        required
                        onChange={(value: string) => {
                          this.handleValueChange(value, "returnDate");
                        }}
                      />
                    </div>
                  </div>
                </> : ""}
              <div className="colSin-33 rightPadding20 topMargin12">
                <div className="inputBox">
                  <InputBox
                    placeholder="Nº de pasajeros"
                    value={insuredData.policy.numPassengers}
                    errorCode={errorCodes.numPassengers.errorCode}
                    type="number"
                    required={true}
                    onChange={(value: string) => {
                      this.handleValueChange(value, "numPassengers");
                    }}

                  />

                </div>
              </div>
            </div>
            <>
              <div className="colSin-50 rightPadding10">
                {coverages.filter((cov: IHealthCoverage) => cov.required === true)
                  .map((coverage: IHealthCoverage, item: number) => (
                    <label
                      className={`checkbox-container${(coverage.required) ? ' noPointer' : ''}`}
                      key={item}>
                      <input
                        type="checkbox"
                        checked={coverage.required || coverage.checked}
                        onChange={(e) => ''}
                      />
                      <div className={`checkmark-container${coverage.required ? '-readonly' : ''}`}>
                        <div className="checkmark-item">
                          <img src={(coverage.required || coverage.checked) ? images.IconTickWhite : images.IconCloseWhite} alt="" />
                        </div>
                      </div>
                      <div className="sc-iwsKbI eLRFbD">
                        <p>{coverage.description}</p>
                      </div>
                    </label>

                  ))}
              </div>

              <div className="colSin-50 rightPadding10">
                {coverages.filter((cov: IHealthCoverage) => cov.required === false)
                  .map((coverage: IHealthCoverage, item: number) => (
                    <div>

                      <label
                        className={`checkbox-container${(coverage.required) ? ' noPointer' : ''}`}
                        key={item}>

                        <input
                          type="checkbox"
                          checked={(coverage.required || coverage.checked)}
                          onChange={(e) => ''}
                        />
                        <div className={
                          `checkmark-container${coverage.required ? '-readonly' : ''}`
                        }>
                          <div className="checkmark-item">
                            <img src={
                              ((coverage.required ||
                                coverage.checked)) ? images.IconTickWhite : images.IconCloseWhite} alt="" />
                          </div>
                        </div>
                        <div className="sc-iwsKbI eLRFbD">
                          <p>{coverage.description}</p>
                        </div>
                      </label>
                    </div>
                  ))}
              </div>
            </>
          </div>
        </div>


        <div className="colSin-100 topMargin20">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push("/simulacion/nueva/")}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                text="Siguiente"
                disabled={availableInsured}
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>
        <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={() => this.setState({ modalErrorShow: false })} />
            </div>

          </div>
        </ErrorModalContainer>
      </TravelInsuredDataContainer>
    );
  }
}

export default withCoverages(withPolicy(withGeneral(withRouter(TravelInsuredDataLayout))));
