import styled, { css } from 'styled-components';

export const ButtonTextContainer = styled.div`
    font-family: "museo sans";
    font-weight: 500;
    font-size: ${props => props.fontSize};
    line-height: 16px;
    text-transform: uppercase;
    
    ${props => css`
        color: ${props.color}
    `}




`;