import React, { Component } from "react";
import { HealthPricingSimulationPaymentOptionDto } from "../../services/dto/response/GipSimulationResponse.dto";
import { formatter } from "../../utils/utility";
import { GipPaymentResultContainer } from "./gip-payment-result-style";

interface GipPaymentResultProps {
  priceData: HealthPricingSimulationPaymentOptionDto[],
  yearlyPrice: number,
  paymentType: string,
  checked: boolean,
}

interface GipPaymentResultState {
  checked: boolean;
}


class GipPaymentResultItem extends Component<GipPaymentResultProps, GipPaymentResultState> {

  constructor(props: GipPaymentResultProps) {
    super(props)
    this.state = { checked: this.props.checked }
  }
  
  handleOnCheck = () => {
    this.setState({ checked: !this.state.checked });
  }


  render() {

    const {
      priceData,
      yearlyPrice,
      paymentType,
    } = this.props


    return (
      <GipPaymentResultContainer>
        <div className="tab">
          <input type="checkbox" checked={this.state.checked} onChange={() => this.handleOnCheck()} />
          <div className="tab-label">
            <span className = "selected-payment-type">{`Periodicidad seleccionada: ${paymentType}`}</span>
            <span>{`Precio anual: ${formatter.format(yearlyPrice)}`}</span>
          </div>
          <div className="tab-content">
            <div className="tabs">
              <label className="tab-label-title col-100">
                <div className="col-20">Periodicidad</div>
                <div className="col-80">
                  <div className="result-simulation-special-row">
                    <div className="col-30">Prima Neta</div>
                    <div className="col-35">Prima Neta por Mes</div>
                    <div className="col-35">Prima Neta por Año</div>
                  </div>
                </div>
              </label>
              {priceData.map((priceOption: HealthPricingSimulationPaymentOptionDto) => (
                priceOption.DesFormaPago === paymentType ?
                  <label className="tab-content-table selected-price col-100">
                    <div className="col-20">{priceOption.DesFormaPago}</div>
                    <div className="col-80">
                      <div className="result-simulation-special-row">
                        <div className="col-30 amount">{formatter.format(priceOption.TotalPrimaNeta)}</div>
                        <div className="col-35 amount">{formatter.format(priceOption.TotalPrimaNetaMes)}</div>
                        <div className="col-35 amount">{formatter.format(priceOption.TotalPrimaNetaAnio)}</div>
                      </div>
                    </div>
                  </label>
                  :
                  <label className="tab-content-table col-100">
                    <div className="col-20">{priceOption.DesFormaPago}</div>
                    <div className="col-80">
                      <div className="result-simulation-special-row">
                        <div className="col-30 amount">{formatter.format(priceOption.TotalPrimaNeta)}</div>
                        <div className="col-35 amount">{formatter.format(priceOption.TotalPrimaNetaMes)}</div>
                        <div className="col-35 amount">{formatter.format(priceOption.TotalPrimaNetaAnio)}</div>
                      </div>
                    </div>
                  </label>
              ))}
            </div>
          </div>
        </div >

      </GipPaymentResultContainer >
    )

  }
}

export default GipPaymentResultItem;



