import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import { GipHireMenuHelper } from "../../../../../components/gip-menu/gip-hire-menu";
import InputBox from "../../../../../components/input-box/input-box";
import MainButton from "../../../../../components/main-button";
import SelectBox from "../../../../../components/select-box/select-box";
import { YES_NO_CONSTANT } from "../../../../../constants";
import { IDataProtectionMethod } from "../../../../../constants/health";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { HealthPricingSimulationPaymentOptionDto, HealthPricingSimulationProductResultDto } from "../../../../../services/dto/response/GipSimulationResponse.dto";
import GipServices from "../../../../../services/GipServices";
import HealthService from "../../../../../services/HealthService";
import { ValueForm } from "../../../../../utils/interfaces";
import { ProtectionDataContainer } from "./gip-protection-data-style";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";
import { withPayTypes } from "../../../../../context/mixin/with-pay-types";
import PayTypesServices from "../../../../../services/PayTypesServices";
import { DEFAULT_PAY_METHOD } from "../../../../../constants/payTypes";

export interface IProtectionData {
  consentDate: ValueForm;
  dataTransfer: ValueForm;
  thirdParty: ValueForm;
  thirdPartyCalls: ValueForm;
  isReadOnly: boolean;
}

interface priceTypeModel {
  paymentTime: string;
  payType: string;
  price: string;
  taxes: string;
  total: string;
}

interface IProtectionDataState {
  protectionData: IProtectionData;
  canPostProtectionData: boolean;
  modalErrorShow: boolean;
  modalErrorText: any;
  selectablePrices: priceTypeModel[];
  selectedPrice: {
    paymentTime: string;
    payType: string,
    price: string;
    taxes: string;
    total: string;
  };
  simulationOutput: HealthPricingSimulationProductResultDto[];
  responseBody: any;
  priceData: any;
  priceDataSelected: any;
  priceDataInsured: any;
  priceDataInsuredSelected: any;
  cancelSignModalShow: any;
  anyChange: boolean;
}

interface IProtectionDataProps extends RouteComponentProps {
  history: any;
  newStep: Function;
  goBack: Function;
  policy: any;
  data: any;
  setLoading: any;
  cotizacion: string;
  saveHiring: Function;
  readOnly: boolean;
  match: any;
  removeSign: Function;
  allPayTypes: any;
}

class GipProtectionDataLayout extends React.Component<
  IProtectionDataProps,
  IProtectionDataState
> {

  constructor(props: IProtectionDataProps) {
    super(props);

    this.state = {
      protectionData: {
        consentDate: {
          value: props.data.protectionData ? props.data.protectionData.protectionData.consentDate.value : moment().format('DD/MM/YYYY'),
          error: false,
          errorCode: '',
          required: false,
          disabled: true,
        },
        dataTransfer: {
          value: props.data.protectionData ? props.data.protectionData.protectionData.dataTransfer.value : '',
          error: false,
          errorCode: '',
          required: true,
          disabled: false,
        },
        thirdParty: {
          value: props.data.protectionData ? props.data.protectionData.protectionData.thirdParty.value : '',
          error: false,
          errorCode: '',
          required: true,
          disabled: false,
        },
        thirdPartyCalls: {
          value: props.data.protectionData ? props.data.protectionData.protectionData.thirdPartyCalls.value : '',
          error: false,
          errorCode: '',
          required: true,
          disabled: false,
        },
        isReadOnly: (props.policy && props.policy.data && props.policy.data.isReadOnly) || false,
      },
      canPostProtectionData: false,
      modalErrorShow: false,
      modalErrorText: "",
      selectablePrices: [],
      selectedPrice: {
        paymentTime: props.data.taker &&
          props.data.taker.policy &&
          props.data.taker.policy.paymentType ?
          PayTypesServices.getPayTypeByCode(props.allPayTypes, props.data.taker.policy.paymentType).description :
          DEFAULT_PAY_METHOD.description,
        payType: props.data.taker &&
          props.data.taker.policy &&
          props.data.taker.policy.paymentType ?
          props.data.taker.policy.paymentType : DEFAULT_PAY_METHOD.code,
        price: '',
        taxes: '',
        total: ''
      },
      simulationOutput: [],
      responseBody: {},
      priceData: {},
      priceDataSelected: {},
      priceDataInsured: [],
      priceDataInsuredSelected: [],
      cancelSignModalShow: false,
      anyChange: false,
    };
  }

  componentWillMount() {
    /* let { data } = this.props;
    if (data && data.taker && !data.taker.physicalPerson)
      this.props.history.push(HealthHireMenuHelper.getNextStepNavigationPath(Steps.ProteccionDatos)); */
  }

  async componentDidMount() {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);

    let { data } = this.props;

    if (data.RGPD_tomador_nuevo !== 'NO') {
      //const getResponse = await this.getProtectionData()

      /* if (getResponse) {
        if (getResponse.body.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS === '') {
          //Si está vacío
          data.RGPD_tomador_nuevo = 'SI';
          this.setState({ canPostProtectionData: true })
        }
        else {
          //Si ya se había enviado la petición previamente
          data.RGPD_tomador_nuevo = 'NO';
          this.assignProtectionResponse(getResponse.body);
          this.setState({ canPostProtectionData: false })
        }
      } */
    } else {
      // this.assignProtectionFromDB(data.protectionData);
      // this.setState({ canPostProtectionData: false })
    }

  }

  assignProtectionResponse(response: any) {
    let { protectionData } = this.state;
    protectionData = {
      ...protectionData,
      consentDate: { ...protectionData.consentDate, value: response.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS.FECHA_CONSENTIMIENTO },
      dataTransfer: { ...protectionData.dataTransfer, value: response.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS.CESION_DATOS },
      thirdParty: { ...protectionData.thirdParty, value: response.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS.PUBLICIDAD_ESCRITA_TERCEROS },
      thirdPartyCalls: { ...protectionData.thirdPartyCalls, value: response.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS.PUBLICIDAD_TELEFONICA_TERCEROS }
    }
    this.setState({ protectionData })
  }


  assignProtectionFromDB(dataProtection: IProtectionData) {
    let { protectionData } = this.state;
    if (dataProtection)
      protectionData = {
        consentDate: { value: dataProtection.consentDate, error: false, errorCode: '', required: false },
        dataTransfer: { value: dataProtection.dataTransfer, error: false, errorCode: '', required: true },
        thirdParty: { value: dataProtection.thirdParty, error: false, errorCode: '', required: true },
        thirdPartyCalls: { value: dataProtection.thirdPartyCalls, error: false, errorCode: '', required: true },
        isReadOnly: protectionData.isReadOnly
      };
    this.setState({ protectionData })
  }

  async getProtectionData() {
    const { setLoading, cotizacion } = this.props;

    return await HealthService.getDataProtection(setLoading, true, cotizacion)
      .then((data: any) => {
        return data;
      })
      .catch((error) => {
        this.setState({ modalErrorShow: true, modalErrorText: error })
      });
  }

  async postProtectionData() {
    const { protectionData } = this.state;

    const request = this.createProtectionDataRequest(protectionData, this.props.cotizacion);

    await HealthService.setDataProtection(this.props.setLoading, true, request)
      .then((response) => response)
      .catch((error) => {
        this.setState({ modalErrorShow: true, modalErrorText: error })
      });
  }

  createProtectionDataRequest(protectionData: IProtectionData, cotizacion: string): IDataProtectionMethod {
    return {
      COTIZACION: cotizacion,
      PROTECCION_DATOS: {
        CANCELACION_DATOS: 'N',
        CESION_DATOS: protectionData.dataTransfer.value,
        FECHA_CONSENTIMIENTO: protectionData.consentDate.value,
        OBSERVACIONES: '',
        PUBLICIDAD_ESCRITA_PROPIOS: 'S',
        PUBLICIDAD_ESCRITA_TERCEROS: protectionData.thirdParty.value,
        PUBLICIDAD_TELEFONICA_PROPIOS: 'S',
        PUBLICIDAD_TELEFONICA_TERCEROS: protectionData.thirdPartyCalls.value !== '' ? protectionData.thirdPartyCalls.value : '-',
        RECEPCION_COMUNICACION: 'EMAIL',
        RED_COMERCIAL: 'N',
        ROBINSON: 'N',
        TIPO_CONSENTIMIENTO: 'GDPR'
      }
    }
  }

  changeValue(value: string, type: string) {
    let { protectionData, anyChange } = this.state;

    switch (type) {
      case 'dataTransfer':
        protectionData.dataTransfer.value === value ? anyChange = false : anyChange = true;
        protectionData.dataTransfer.value = value;
        protectionData.dataTransfer.errorCode = '';   
        break;
      case 'thirdParty':
        protectionData.thirdParty.value === value ? anyChange = false : anyChange = true;
        protectionData.thirdParty.value = value;
        protectionData.thirdParty.errorCode = '';
        break;
      case 'thirdPartyCalls':
        protectionData.thirdPartyCalls.value === value ? anyChange = false : anyChange = true;
        protectionData.thirdPartyCalls.value = value;
        protectionData.thirdPartyCalls.errorCode = '';
        break;
      default:
        break;
    }

    this.setState({ protectionData, anyChange });
  }

  async GipSimulation() {
    let data = this.props.data;
    let insuredList: { birthdate: string; isInsuredClient: number; }[] = [];
    data.insureds.forEach((insured: { birthDate: string; }) => { insuredList.push({ "birthdate": insured.birthDate, "isInsuredClient": 0 }) })
    let request = {
      startDate: this.getDateForGipFromString(data.taker.policy.startDate),
      colective: data.policyData.colective,
      combiProd: data.policyData.combiproduct,
      insuredList: insuredList,
      province: parseInt(data.taker.address.province.code)
    }

    const response = await GipServices.getGipSimulation({
      loader: true,
      setLoading: this.props.setLoading,
      data: request,
    })
      .then((response) => response)
      .catch(error => { throw error });
    return response;
  }

  getDateForGipFromString(dateString: string) {
    try {
      const [day, month, year] = dateString.split('/');
      const date = new Date(year + "-" + month + "-" + day).toISOString();
      return date;
    }
    catch (err) {
      throw err;
    }
  }

  loadDataNextScreen = async () => {
    const { policy } = this.props
    var prices: priceTypeModel[] = []
    try {
      var dataReply = await this.GipSimulation();
      if (dataReply.result === 0) {
        this.setState({ responseBody: dataReply });
        if (dataReply.responseContent && dataReply.responseContent.salida) {
          if (typeof (dataReply.responseContent.salida) !== "string" && dataReply.responseContent.salida.ListaProductosResultado.length === 1) {
            this.setState({ simulationOutput: dataReply.responseContent.salida.ListaProductosResultado })
            var priceList = dataReply.responseContent.salida.ListaProductosResultado
            priceList.forEach(priceSet => {
              priceSet.ListaTarifasFormaPagoResultado.forEach(priceOption => prices.push({
                paymentTime: priceOption.DesFormaPago,
                payType: priceOption.CodFormaPago.toString(),
                price: priceOption.TotalPrimaNeta.toString(),
                taxes: "",
                total: priceOption.TotalPrimaNeta.toString()
              }))
            })
          }
          else {
            if (dataReply.responseContent.cod_error !== '') {
              this.setState({ modalErrorShow: true, modalErrorText: dataReply.responseContent.salida.toString().replace('||', '').trim() })
              return
            }
          }
        }
      } else if (dataReply.result > 0) {
        this.setState({ modalErrorShow: true, modalErrorText: dataReply.reason })
        return
      }

      this.setState({ selectedPrice: prices[0] })

      this.setState({ selectablePrices: prices });
      let tipoPago: any = policy.productPayTypes.find((payType:any) => payType.value === this.props.data.taker.policy.paymentType)
      let codPago = tipoPago ? parseInt(tipoPago.num) : 0
      let { simulationOutput } = this.state
      let paymentInsured: HealthPricingSimulationPaymentOptionDto[][] = [];
      let paymentInsuredSelected: HealthPricingSimulationPaymentOptionDto[] = [];

      simulationOutput.forEach(priceSet => {
        priceSet.ListaTarifasFormaPagoResultado.forEach(priceOption => {
          if (priceOption.CodFormaPago === codPago) {
            this.setState({ priceDataSelected: priceOption });
          }
        })
        priceSet.ListaTarifasPorAsegurado.forEach(insured => {
          paymentInsured.push(insured.ListaTarifas);
          insured.ListaTarifas.forEach(priceOption => {
            if (priceOption.CodFormaPago === codPago) {
              paymentInsuredSelected.push(priceOption);
            }
          })
        })
      })

      this.setState({ priceData: this.state.simulationOutput[0].ListaTarifasFormaPagoResultado });
      this.setState({ priceDataInsuredSelected: paymentInsuredSelected });
      this.setState({ priceDataInsured: paymentInsured });
      let sendData = {
        pricingData: {
          priceData: this.state.priceData,
          priceDataSelected: this.state.priceDataSelected,
          priceDataInsured: this.state.priceDataInsured,
          priceDataInsuredSelected: this.state.priceDataInsuredSelected

        }
      }
      this.props.policy.setHealthData(sendData);


    }
    catch {
      // const { history } = this.props;
      // history.replace("");
    }

  }

  async toValidate() {
    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true) {
      this.setState({ cancelSignModalShow: true })
      return false;
    }
    else {
      await this.validateAndSave()
    }
  }

  async validateAndSave() {

    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true)
      this.props.removeSign()

    let { protectionData } = this.state;
    let { data } = this.props;
    if (!this.props.readOnly)
      await this.loadDataNextScreen();
    else {
      this.props.history.push(
        GipHireMenuHelper.getStepNavigationPath(
          GipHireMenuHelper.getStepFromNavigationRoute(
            this.props.match.url,
            GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
          ) + 1,
          this.props.data.insureds.lenght,
          GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
        )
      )
      return;
    }
    let error = false;
    if (protectionData.consentDate.value === '') {
      error = true;
      protectionData.consentDate.errorCode = 'required';
    }
    if (protectionData.dataTransfer.value === '') {
      error = true;
      protectionData.dataTransfer.errorCode = 'required';
    }
    if (protectionData.thirdParty.value === '') {
      error = true;
      protectionData.thirdParty.errorCode = 'required';
    }
    let sendData = { protectionData }
    this.props.policy.setHealthData(sendData);

    if (error === false && !this.state.modalErrorShow) {
      /* if (canPostProtectionData) { await this.postProtectionData(); } */

      data.RGPD_tomador_nuevo = 'NO';
      //this.props.policy.setProtectionData(protectionData);
      await this.props.saveHiring(true);

      this.props.history.push(
        GipHireMenuHelper.getStepNavigationPath(
          GipHireMenuHelper.getStepFromNavigationRoute(
            this.props.match.url,
            GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
          ) + 1,
          this.props.data.insureds.lenght,
          GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
        )
      )
    } else
      this.setState({ protectionData })
  }

  hideErrorModal = () => this.setState({ modalErrorShow: false, modalErrorText: "" });

  render() {
    let { protectionData, cancelSignModalShow } = this.state;

    return (
      <ProtectionDataContainer>
        <div className="content-wrapper">
          <div className="personal-data-wrapper">
            <div className="data-flow-status">
              <div className="data-flow-status-title">
                <h2>PROTECCIÓN DE DATOS</h2>
              </div>
            </div>
            <div className="personal-data-container">
              <div className="personal-data-row">
                <div className="personal-data-item col-33">
                  <div className="inputBox">
                    <InputBox
                      type={"text"}
                      placeholder="Fecha de consentimiento"
                      value={protectionData.consentDate.value}
                      disabled={true}
                      required={protectionData.consentDate.required}
                    />
                  </div>
                </div>

                <div className="personal-data-item col-33">
                  <div className="selectBox">
                    <SelectBox
                      placeholder="Seleccionar"
                      required={protectionData.dataTransfer.required}
                      errorCode={protectionData.dataTransfer.errorCode}
                      disabled={this.props.readOnly}
                      optionsText={YES_NO_CONSTANT}
                      optionKey={"value"}
                      optionValue={"label"}
                      initialValue={protectionData.dataTransfer.value}
                      defaultValue={protectionData.dataTransfer.value}
                      onChange={(value: string) => {
                        this.changeValue(value, "dataTransfer");
                      }}
                    />
                    <label>{"Cesión de datos"}</label>
                  </div>
                </div>
              </div>

              <div className="data-title">
                <h5>ENVÍO DE PUBLICIDAD ESCRITA</h5>
              </div>

              <div className="personal-data-row">

                <div className="personal-data-item col-33">
                  <div className="selectBox">
                    <SelectBox
                      placeholder="Seleccionar"
                      required={protectionData.thirdParty.required}
                      errorCode={protectionData.thirdParty.errorCode}
                      disabled={this.props.readOnly}
                      optionsText={YES_NO_CONSTANT}
                      optionKey={"value"}
                      optionValue={"label"}
                      initialValue={protectionData.thirdParty.value}
                      defaultValue={protectionData.thirdParty.value}
                      onChange={(value: string) => {
                        this.changeValue(value, "thirdParty");
                      }}
                    />
                    <label>{"Envío de publicidad de terceros"}</label>
                  </div>
                </div>

              </div>

              <div className="data-title">
                <h5>ACCIÓN COMERCIAL POR TELÉFONO</h5>
              </div>

              <div className="personal-data-row">
                <div className="personal-data-item col-33">
                  <div className="selectBox tooLongLabelHandler">
                    <SelectBox
                      placeholder="Seleccionar"
                      errorCode={protectionData.thirdPartyCalls.errorCode}
                      //disabled={!canPostProtectionData || protectionData.thirdPartyCalls.disabled || protectionData.isReadOnly}
                      optionsText={[...YES_NO_CONSTANT, { value: '-', label: 'Seleccionar' }]}
                      optionKey={"value"}
                      optionValue={"label"}
                      initialValue={protectionData.thirdPartyCalls.value}
                      defaultValue={protectionData.thirdPartyCalls.value}
                      disabled={this.props.readOnly}
                      onChange={(value: string) => {
                        this.changeValue(value, "thirdPartyCalls");
                      }}
                    />
                    <label>{"Cesión de llamadas promocionando productos de terceros"}</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="personal-data-container">
              <div className="personal-data-row">
                <div className="personal-data-buttons">
                  <div className="personal-data-buttons__item">
                    <MainButton
                      withTab={true}
                      icon={true}
                      type={"white"}
                      iconImg={images.IconArrowLeftBlue}
                      text="Atrás"
                      onClick={() => {
                        this.props.history.push(
                          GipHireMenuHelper.getStepNavigationPath(
                            GipHireMenuHelper.getStepFromNavigationRoute(
                              this.props.match.url,
                              GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
                            ) - 1,
                            0,
                            GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
                          )
                        )
                      }}
                    />
                  </div>
                  <div className="personal-data-buttons__item">
                    <MainButton
                      withTab={true}
                      text="Siguiente"
                      onClick={() => this.toValidate()}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={this.hideErrorModal} />
            </div>
          </div>
        </ErrorModalContainer>
        <LeftInfoModal
          img={images.IconWarningYellow}
          className="error"
          mainTitle={"¿Seguro que desea continuar?"}
          mainText={"Se cancelará la firma previamente solicitada"}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ cancelSignModalShow: false })}
          active={cancelSignModalShow}
          onClickAccept={() => {
            this.setState({ cancelSignModalShow: false }, () => this.validateAndSave())
          }}
        />
      </ProtectionDataContainer>
    );
  }

}

export default withGeneral(withPayTypes(withPolicy(withRouter(GipProtectionDataLayout))));
