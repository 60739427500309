import styled from "styled-components";
import { colors } from "../../../../../assets";
import { images } from "../../../../../assets/images";

export const PaymentMethodsContainer = styled.div`

  .data-flow-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .content-wrapper {
    overflow: initial;
    padding-right: 16px;
    padding: 20px;
    background-color: #fbfbfb;
    display: block;
    width: 100%;
    float: left;

    .personal-data-wrapper {
      .disabled-dark {
        .disabled {
          p {
            color: ${colors["primaryColor"]};
          }
        }
      }
    }
  }
  @media (min-width: 320px) {
    .content-wrapper {
      width: 55% !important;
    }
  }
  @media (min-width: 1201px) {
    .content-wrapper {
      width: 100% !important;
    }
  }
  .custom-file-upload {
    display: flex;

    input[type="text"] {
      border-radius: 3px 0px 0px 3px;
    }
  }

  .custom-file-upload-button {
      background-color: #e4e4e4;

      > input[type="file"] {
        display:none;

        ~ div {
          background-color: ${colors["PR-001-100"]};
          color: #fff;
          border-radius: 0px 3px 3px 0px;

          &:before {

            content: url(${images.IconSearchWhite});
            position: absolute;
            width: 15px;
            height: 15px;
            left: 15px;
          }
        }

      &:disabled {
        ~ div {
          background-color: #e2e2e2;
          border-radius: 0px 3px 3px 0px;
          color: #ababab;

          &:before {
            content: url(${images.IconSearchGray});
          }
        }
      }        
    }
  }

  .personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  .input-box-error-file {
    color: ${colors["ER-001-100"]};
    margin-top: -15px;

    p {
      color: ${colors["ER-001-100"]};
    }
  }

  .downloadSepaButton > div:first-child {
    padding: 20px;
  }
  
  .data-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 24px;
  }

  .question {
    margin: 5px;
    margin-bottom: 15px;

    > .col-50 {
      width: 50%;
    }

    &.isRow {
      .inputBox {
        margin-top: 12px;
        height: auto;
      }

      .personal-data-item {
        width: 100%;
      }
    }
  }

  .question-title {
    margin: 5px;
    margin-bottom: 10px;   
    position: relative;
    padding-left: 20px;

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      background: #e40202;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }

    h6 {
      margin-bottom: 10px;
      padding-bottom: 20px;
      border: none;
    }

    ~ .question {
      margin-bottom: 30px;
      padding-left: 25px;
    }
  }

  .isRow{
    width: 45%;
    float: left;
    margin: 0% 5% 4% 0;
  }
  @media (max-width: 1140px) {
    .isRow{
      width: 95%;
  
      .inputBox {
        margin-bottom: 0px;
      }
    }
  }

  table.table-signedDate {
    float: right;
    margin-right: 15px;
    margin-top: 20px;
    td {
      padding: 5px;
      min-width: 300px;
    }
  }

  .backgroundCheckbox {
    background-color: aliceblue;
    padding: 5px 0 20px 5px;
  }

  .tabs {
    overflow: ${props => props.showCalendar ? 'visible' : 'hidden'};

    .tab {
      width: 100%;
      overflow: ${props => props.showCalendar ? 'visible' : 'hidden'};
      position: relative;

      .delete {
        width: 24px;
        height: 24px;
        line-height: 24px;
        position: absolute;
        left: calc(100% - 170px);
        top: 18px;
        background-color: #3f3f3f;
        color: #fff;
        border-radius: 100%;
        text-align: center;
        cursor: pointer;
        z-index: 2;

        &:before {
          content: url(${images.IconCloseWhite});
        }

        &:hover {
          background-color: #004a8f;
        }
      }

      .tab-label {
        width: 100%;
        display: block;
        background: #fff;
        color: #3f3f3f;
        border: 1px solid #f4f4f4;
        font-size: 18px;
        height: 75px;
        padding-top: 18px;
        cursor: pointer;

        &:after {
          content: "❯";
          width: 30px;
          height: 30px;
          text-align: center;
          transition: all 300ms;
          transform: rotate(90deg);
          border-radius: 100%;
          text-align: center;
          line-height: 30px;
          position: absolute;
          right: 20px;
          top: 22px;
        }

        div {
          width: 96%;
          display: flex;
          /*justify-content: space-between;*/
          padding: 0 1em;
          margin-bottom: 5px;

          &:first-of-type {
            span {
              font-size: 13px;
              color: ${colors["TX-001-50"]};
              font-weight: normal;

            }
          }
        }

        span {
          &:first-of-type {
            width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &:nth-of-type(2) {
            width: 20%;
          }
          &:nth-of-type(3) {
            width: 10%;
          }
          &:nth-of-type(4) {
            width: 20%;
          }
          font-weight: bold;
          transition: all 300ms;
          /*width: 25%;*/
          color: ${colors["PR-001-100"]};
        }
      }

      .tab-content {
        height: 0;
        padding: 0 1em;
        color: #2c3e50;
        background: #fff;
        transition: all 300ms;
        margin-bottom: 10px;
        opacity: 0;
        overflow: ${props => props.showCalendar ? 'visible' : 'hidden'};
      }

      .tab-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        background: #2c3e50;
        cursor: pointer;

        &:hover {
          background: #1a252f;
        }
      }

      input[type="checkbox"].tabCheck {
        position: absolute;
        right: 2px;
        opacity: 0;
        width: 60px;
        height: 60px;
        cursor: pointer;
        z-index: 1;
        margin: 0;

        &:hover {
          + {
            .tab-label:after {
              background-color: #efefef;
            }
          }
        }

        &:checked {
          + {
            .tab-label:after {
              transform: rotate(270deg);
            }
          }

          ~ .tab-content {
            min-height: ${props => props.showCalendar ? '320px' : '70px'};
            height: auto;
            padding: 25px 15px 20px;
            opacity: 1;
            box-shadow: 0px 4px 10px -3px #e0e0e0;
          }
        }
      }
    }
  }

`;

export const CardInfo = styled.div`
  color: ${colors["primaryColor"]};
  background-color: aliceblue;
  font-size: 16px;
  line-height: 22px;
  padding: 1rem;
  display: flex;
  align-items: center;
  text-align: justify;
  font-style: italic;

  ${({ origin }) =>
    origin === "panel" &&
    `
    margin-bottom: 5px;
    border: 2px solid ${colors["PR-001-100"]};
    padding: 1rem;

    .icon {
      margin-right: 2rem;
    }
  `}

  .icon {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }

  .content {
    a.link {
      color: ${colors["PR-001-100"]};
      text-decoration: underline;
      font-weight: 700;
    }
  }
`;
