import styled from 'styled-components';

export const QuestionnaireItemContainer = styled.div`
  padding-left: 20px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 11px;
    left: 0;
    background: #e40202;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .questionnaire-text {
    color: #004f8b;
    margin-bottom: 24px;
    line-height: 27px;
  }

  .questionnaire-fields-container {
  }

  .questionnaire-fields-row {
    display: flex;
    width: 100%;
    margin-bottom: 38px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .questionnaire-fields-col {
    width: 100%;

    input {
      height: 52px;
      border-radius: 3px;
      border: 1px solid #004f8b;
      width: 100%;
      padding: 13px 22px;
      color: #004f8b;

      &:disabled,
      &.disabled {
        color: #b5bcc6;
        border-radius: 3px;
        border: 1px solid #b5bcc6;
      }
    }

    &.col-50 {
      width: calc((100% - 40px) / 2);
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 980px) {
    .questionnaire-fields-row {
      flex-wrap: wrap;
      margin-bottom: 24px;
      &:last-child {
        margin: 0;
      }
    }

    .questionnaire-fields-col {
      &.col-50 {
        width: 100%;
        margin: 0;
        margin-bottom: 24px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
`;
