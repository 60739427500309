import styled from "styled-components";
import { colors } from "../../assets";
import { images } from '../../assets';

export const FilterInputContainer = styled.div`
  width: 100%;
  border-radius: 5px;

  &.error {
    .input-filter-container {
      border: 1px solid ${colors["ER-001-100"]};
    }

    .input-filter-error {
      margin-top: 8px;
      color: ${colors["ER-001-100"]};

      p {
        color: ${colors["ER-001-100"]};
      }
    }
  }

  .input-filter-container {
    display: flex;
    width: 100%;
    border-radius: 5px;
    background: rgba(179, 202, 220, 0.1);
    position: relative;

    &.required {
      &:before{
        content: "";
        z-index: 9;
        position: absolute;
        top: 23px;
        left: 8px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: transparent;
      }
    }
  }

  &.disabled {
    background: ${colors["FL-001-Disabled"]};

    input {
      color: ${colors["TX-001-30"]};
      ::placeholder{
        color: ${colors["TX-001-30"]};
      }
    }
  }

  input {
    width: 100%;
    padding: 11px 15px;
    padding-right: 50px;
    color: ${colors["PR-001-100"]};
    font-weight: 300;
    font-family: "museo sans";
    font-size: 16px;
    min-height: 48px;
    text-transform: uppercase;
  }

  input::placeholder {
    color: ${colors["PR-001-100"]};
  }

  .input-filter-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    padding: 5px;

    img {
      max-width: 20px;
    }
  }

  .deleteButton{
    background: transparent;
    background-image: url(${images.IconCloseBlue});
    background-repeat: no-repeat;
    border: none;
    height: 15px;
    width: 15px;
    cursor: pointer;
  }
`;
