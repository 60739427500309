import styled from "styled-components";
import { colors } from "../../../../assets";
import { BodyTextContainer } from "../../../../components/components-text/body-text/body-text-styles";
import { NoteTextContainer } from "../../../../components/components-text/note-text/note-text-styles";

export const HiringDetailPendantContainer = styled.div`
  .detail-pendant-hiring-back {
    display: flex;
    justify-content: space-between;
  }

  .detail-pendant-hiring-buttons-item {
    min-width: 200px;
    margin-bottom: 16px;
  }

  .detail-pendant-hiring-special {
    padding: 40px;
    margin-bottom: 24px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
  }

  .detail-pendant-hiring-special-title {
    color: ${colors["PR-001-100"]};
    border-bottom: 1px solid ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 24px;
  }

  .detail-pendant-hiring-special-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .detail-pendant-hiring-special-item {
    display: flex;
  }

  .detail-pendant-hiring-special-subitem {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  .detail-pendant-hiring-special-subitem-title {
    text-transform: uppercase;
    color: ${colors["TX-001-30"]};
    margin-bottom: 6px;
  }

  .detail-pendant-hiring-special-subitem-content {
    color: ${colors["TX-001-100"]};
  }

  .detail-pendant-hiring-item-content-table {
    margin-bottom: 55px;
    min-width: 100%;
    max-width: 450px;
    overflow-x: auto;

    .table {
      min-width: 840px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    thead {
      border-bottom: 1px solid ${colors["PR-001-100"]};
    }

    th {
      padding: 15px 15px;
      min-width: 50px;
      max-width: 100px;
      color: ${colors["TX-001-30"]};
      .highlighted {
        color: ${colors["TX-001-50"]};
      }
    }

    td {
      padding: 15px 15px;
      min-width: 50px;

      ${BodyTextContainer} {
        display: inline-block;
        &.highlighted {
          font-weight: 600;
        }
      }
    }

    .main-table-row {
      &:first-child {
        td {
          padding-top: 10px;
        }
      }

      &:hover {
        box-shadow: none;
      }
    }
  }
  .detail-pendant-hiring-item-content p {
      white-space: break-spaces;
  }

  .detail-pendant-hiring-item-content-advise {
    color: ${colors["TX-001-30"]};
  }

  .detail-pendant-hiring-item-content-capital-text {
    margin-bottom: 50px;

    p {
      color: ${colors["TX-001-50"]};
    }

    span {
      color: ${colors["TX-001-100"]};
      margin-left: 8px;
    }
  }

  .detail-pendant-hiring-container {
    margin-bottom: 40px;
  }

  .detail-pendant-hiring-item {
    padding: 40px;
    margin-bottom: 24px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
  }

  .detail-pendant-hiring-item-title {
    color: ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 24px;
  }

  .detail-pendant-hiring-footer {
    padding-top: 37px;
    margin-bottom: 130px;
    color: ${colors["PR-001-100"]};
    ${NoteTextContainer} {
      line-height: 1.5em;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pendant-hiring-pagination {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
  }
  .checkbox-container input:not(:checked) {
    color: ${colors["TX-001-50"]};
  }

  outline: none;

  .checkbox-container {
    display: flex;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 15px;

    &.noPointer {
      cursor: initial;
    }

    div:nth-of-type(2) {
      line-height: 25px;
      font-size: 16px;
    }

    &:hover {
      input ~ .checkmark {
        background-color: rgba(0, 79, 139, 0.3);
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark-container {
        background: #e8f9f5;
        justify-content: flex-end;

        .checkmark-item {
          background: #40caa9;
        }

        img {
          max-width: 14px;
        }
      }
  }

  .checkmark-container {
    min-width: 24px;
    background: #ffffff;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 14px;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;

    img {
      max-width: 14px;
    }
  }

  .checkmark-item {
    background: ${colors["TX-001-30"]};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    width: 20px;
    height: 20px;    
  }
`;
