import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { FaqsContainer } from "./faqs-style";
import MainButton from "../../components/main-button";
import InputBox from "../../components/input-box/input-box";
import Title03Text from "../../components/components-text/title-03-text";
import { Faq, FaqAreas } from "../../services/FaqsServices.dto";
import FaqsServices from "../../services/FaqsServices";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import { images } from "../../assets";
import LeftInfoModal from "../../components/left-info-modal";

interface FaqsProps extends RouteComponentProps {}

interface FaqArea {
  name: string,
  key: string,
}
interface FaqsState {
  filterText: string,
  area: string,
  faqs: Faq[],
  selectedFaq: Faq | null,
  faqAreas: FaqArea[],
  error: string,
  errorType: string,
  errorModalTitle: string,
}

class FaqsLayout extends Component<FaqsProps, FaqsState> {
  state: FaqsState = {
    faqs: [],
    filterText: '',
    selectedFaq: null,
    area: '',
    faqAreas: [],
    error: '',
    errorType: '',
    errorModalTitle: '',
  }

  componentDidMount() {
    this.getAreas();
  }

  getAreas = async () => {
    const args = {
      loader: false,
      setLoading: () => {}
    };
    const faqAreasList = await FaqsServices.getFaqAreas(args);
    if (faqAreasList.error) {
      this.setState({
        error: `${ERROR_MESSAGES["unknow-error"]}`,
        errorModalTitle: "Error en el servicio",
        errorType: "error",
      });
      return;
    }
    const faqAreas = faqAreasList.map((area: FaqAreas) => {
        const key = Object.keys(area)[0];
        const name = area[key].products[0].itemName.toLocaleLowerCase();
        return { key, name };
    });
    this.setState({ faqAreas }, () => this.getArea());
  }

  getArea = () => {
    const faqAreas = this.state.faqAreas.map((faqArea) => faqArea.name.toLocaleLowerCase());
    if (!faqAreas.length) return;
    
    const { search } = this.props.location;
    const area = search ? search.slice(6) : 'salud';
    if (faqAreas.includes(area)) this.setPolicyFaqs(area);
    else this.setPolicyFaqs(faqAreas[0]);
  }

  setPolicyFaqs = async (area: string) => {
    const { location } = this.props;
    this.props.history.push(`${location.pathname}?area=${area}`);
    this.getFaqs(area);
  }

  getFaqs = async (area: string, getFirstSelectedFaq?: boolean) => {
    if (area === this.state.area) return;

    const faqArea = this.state.faqAreas.find((faqArea) => {
      return faqArea.name.toLocaleLowerCase() === area
    })
    if (!faqArea) return;

    const faqs = await FaqsServices.getFaqs({ lineaNegocio: faqArea.key, loader: false, setLoading: () => {} });
    if (faqs.error) {
      this.setState({
        error: `${ERROR_MESSAGES["unknow-error"]}`,
        errorModalTitle: "Error en el servicio",
        errorType: "error",
      });
      return;
    }

    const selectedFaq = faqs[0];
    this.setState({ area, faqs: faqs, selectedFaq, filterText: '' });
  }

  handleChangeSearch = (value: string) => {
    this.setState({ filterText: value, selectedFaq: null });
  }

  getFilteredFaqs = (filterText: string) => {
    const { faqs } = this.state;
    const filteredFaqs = faqs.filter((faq) => {
      const pregunta = faq.pregunta.contenido;
      return pregunta.toLocaleLowerCase().includes(filterText.toLowerCase());
    });
    return filteredFaqs;
  }

  handleOnClickFaq = (id: number) => {
    const { faqs } = this.state;
    const selectedFaq = faqs.find((faq: Faq) => faq.pregunta.id === id);
    if (selectedFaq) this.setState({ selectedFaq });
  }

  renderSelectedFaq = () => {
    const { selectedFaq } = this.state;
    if (!selectedFaq) return;
  
    const pregunta = selectedFaq.pregunta.contenido;
    const respuestas = selectedFaq.respuestas;
    respuestas.sort((a, b) => a.orden > b.orden ? 1 : -1);

    return (
      <>
        <Title03Text className="response-pregunta">{pregunta}</Title03Text>
        <div className="response-content">
          {
            respuestas.length > 0 && respuestas.map((respuesta) => {
              const { id, tipo, contenido } = respuesta;

              if(tipo === 'imagen') {
                return <div key={id} className="img"><img src={contenido} alt="" /></div>
              }

              return <p key={id} className={tipo}>{contenido}</p>
            })
          }
        </div>
      </>
    )
  }

  render() {
    const { filterText, area, selectedFaq, faqAreas, error, errorType, errorModalTitle } = this.state;
    const filteredFaqs = this.getFilteredFaqs(filterText);
    const areaTitle = `${area.charAt(0).toUpperCase()}${area.slice(1)}`;
  
    return (
      <FaqsContainer>
        <div className="buttons-container">
          {faqAreas.map(({ name, key }) => {
            return (
              <MainButton
                key={key}
                text={name}
                onClick={() => this.setPolicyFaqs(name.toLocaleLowerCase())}
              />
            );
          })}
        </div>

        <Title03Text>
          Preguntas Frecuentes - {areaTitle}
        </Title03Text>

        <div className="content">
          <div>
            <div className="inputBox">
              <InputBox
                value={filterText}
                onChange={(value: string) => this.handleChangeSearch(value)}
                placeholder="Escriba el término de búsqueda..."
                type="text"
                notUsePlacehoderAsLabel
              />
            </div>
            <div className="list">
              {filteredFaqs.map((faq) => {
                const { pregunta } = faq;
                const { id, contenido } = pregunta;
                const selectedFaqId = (selectedFaq && selectedFaq.pregunta &&  selectedFaq.pregunta.id) || '';
                const className = id === selectedFaqId ? 'selected' : '';
                return (
                  <p
                    key={id}
                    className={className}
                    onClick={() => this.handleOnClickFaq(id)}
                  >
                   {contenido}
                  </p>
                )
              })}
            </div>
          </div>
          <div className="response">
            {selectedFaq && Object.keys(selectedFaq).length > 0 && this.renderSelectedFaq()}
          </div>
        </div>

        <LeftInfoModal
          img={images.IconWarningYellow}
          className={errorType}
          mainTitle={errorModalTitle}
          mainText={error}
          buttonText="Cerrar"
          active={error ? true : false}
          onClickAccept={() => this.setState({ error: "", errorType: "", errorModalTitle: "" })}
          close={() => this.setState({ error: "", errorType: "", errorModalTitle: "" })}
          noSecondaryButton
        />
        
      </FaqsContainer>
    );
  }
}

export default withRouter(FaqsLayout);
