/**
    ChooseHiringModal
        img={images.IconCheckboxBlue} es el icono que va a salir en la modal
        text={'Vas a iniciar el proceso de contratación, ¿estás seguro?'} es el texto principal de la modal
        className={classname} es la clase que se le quiera meter al modal
        onClickAccept={()=> goToNewHiring()} meter la funcion a la que se quiera llamar desde el screen del container desde el que se importa
    /> 
 */

import React, { Component } from "react";
import { ChooseHiringModalContainer } from "./choose-hiring-modal-style";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { images } from "../../assets";
import H4Text from "../components-text/h4-text";
import MainButton from "../main-button";
import WebText from "../components-text/web-text";
import InputBox from "../input-box/input-box";

interface ChooseHiringModalRouterProps { }

interface ChooseHiringModalProps extends RouteComponentProps<ChooseHiringModalRouterProps> {
  className?: string;
  img?: string;
  onClickAccept: Function;
  mainTitle: string;
  mainText: string;
  buttonText: string;
  active?: boolean;
  close: Function;
}

interface ChooseHiringModalState {
  active: string;
  radioValue: string;
  search: string;
}

class ChooseHiringModalLayout extends Component<ChooseHiringModalProps, ChooseHiringModalState> {
  readonly state: ChooseHiringModalState;
  containerRef: any;
  subContainerRef: any;

  public static defaultProps = {
    active: false,
    img: images.IconSearchBlue,
    onClickAccept: () => { },
    className: "",
    mainTitle: "",
    mainText: "",
    buttonText: "",
    close: () => { },
  };

  constructor(props: ChooseHiringModalProps) {
    super(props);
    this.state = {
      active: props.active ? "active" : "",
      radioValue: 'No',
      search: ''
    };
    this.containerRef = React.createRef();
    this.subContainerRef = React.createRef();
  }

  componentDidMount() {
    this.containerRef.current.addEventListener("click", this.handleClickOutside.bind(this));
  }

  componentWillUnmount() {
    this.containerRef.current.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  componentDidUpdate(prevProps: ChooseHiringModalProps) {
    if (prevProps.active !== this.props.active) {
      this.setState({ active: this.props.active ? "active" : "" });
    }
  }

  isVisible(e: any) {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
  }

  handleClickOutside(ev: any) {
    const { close } = this.props;
    this.isVisible(ev.target) &&
      this.subContainerRef.current &&
      !this.subContainerRef.current.contains(ev.target) &&
      close();
  }

  // openChooseHiringModal(ev: any) {
  //   let { modifiedAccept, isModifiedAccept } = this.state;
  //   if (ev.detail.navigate) {
  //     modifiedAccept = ev.detail.navigate;
  //     isModifiedAccept = true;
  //   } else {
  //     modifiedAccept = () => {};
  //     isModifiedAccept = false;
  //   }
  //   this.setState({ active: "active", modifiedAccept, isModifiedAccept });
  // }

  close() {
    this.setState({ active: "" });
  }

  open() {
    this.setState({ active: "active" });
  }

  acceptChooseHiringModal() {
    // const { isModifiedAccept, modifiedAccept } = this.state;
    const { onClickAccept, close } = this.props;
    const { radioValue, search } = this.state;
    if (onClickAccept) {
      onClickAccept(radioValue, search);
    }
    this.close();
  }

  render() {
    const { img, mainTitle, mainText, className, buttonText, close } = this.props;
    const { active, radioValue, search } = this.state;

    return (
      <ChooseHiringModalContainer className={`${active} ${className}`} ref={this.containerRef}>
        <div className="recover-modal" ref={this.subContainerRef}>
          <div className="recover-modal-close">
            <img src={images.IconCloseGrey} onClick={() => close ? close() : this.close()} />
          </div>

          <div className="recover-modal-main">
            <div className="recover-modal-main-title">
              <div className="recover-modal-icon-bg">
                <img src={img} />
              </div>
              <div className="recover-modal-text">
                <H4Text>{mainTitle}</H4Text>
              </div>
            </div>

            <div className="recover-modal-main-container">
              <div className="recover-modal-main-row">
                <div className="radioBox">
                  <label>
                    <input
                      className="recover-option"
                      type="radio"
                      name="hireOption"
                      value={"Si"}
                      checked={radioValue === 'Si'}
                      onChange={(ev) => this.setState({ radioValue: ev.target.value })}
                    />
                    <span>Si, recuperar simulación</span>
                  </label>
                </div>
                <div className="recover-modal-main-row-input">
                  <div className="inputBox height50">
                    <InputBox
                      placeholder="Introduce ID de simulación"
                      type="text"
                      required
                      value={search}
                      disabled={radioValue === 'No'}
                      onChange={(value) => this.setState({ search: value })}
                    />
                  </div>
                </div>
              </div>
              <div className="recover-modal-main-row">
                <div className="radioBox">
                  <label>
                    <input
                      className="recover-option"
                      type="radio"
                      name="hireOption"
                      value={"No"}
                      checked={radioValue === 'No'}
                      onChange={(ev) => this.setState({ radioValue: ev.target.value })}
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="recover-modal-main-action">
              <div className="recover-modal-button">
                <MainButton text={'Cancelar'} onClick={() => close()} type={'ghost'} />
                <MainButton text={buttonText} onClick={() => this.acceptChooseHiringModal()} />

              </div>
            </div>
          </div>
        </div>
      </ChooseHiringModalContainer>
    );
  }
}

export default withRouter(ChooseHiringModalLayout);
