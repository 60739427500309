import React, { Component } from "react";
import { CampaignsCreateStepContainer } from "./campains-create-steps-style";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import SelectBox from "../../../../components/select-box/select-box";
import MainButton from "../../../../components/main-button";
import { images } from "../../../../assets";

const audienceOptions = [
  { label: "Corredores", value: "runners" },
  { label: "Coordinadores", value: "coordinators" },
  { label: "Agentes locales", value: "exclusiveAgents" },
  { label: "Compradores", value: "buyers" }
];

const audienceOptionsKeyValues: any = {
  runners: "Corredores",
  coordinators: "Coordinadores",
  exclusiveAgents: "Agentes locales",
  buyers: "Compradores"
};

interface ICampaingsCreateStep2Props {
  goNext: boolean;
  onNext: (state: ICampaingsCreateStep2State) => void;
  previousState?: ICampaingsCreateStep2State;
  setDisabledNext: (disabled: boolean) => void;
}

export interface ICampaingsCreateStep2State {
  selectedCard: string;
  selectedAudience: string[];
  savedAudience: boolean;
}

class CampaingsCreateStep2 extends Component<
  ICampaingsCreateStep2Props,
  ICampaingsCreateStep2State
  > {
  constructor(props: ICampaingsCreateStep2Props) {
    super(props);
    this.state = {
      selectedCard: "",
      selectedAudience: [],
      savedAudience: false
    };
  }

  componentDidMount() {
    const { setDisabledNext, previousState } = this.props;
    if (previousState) this.setState(previousState);
    else setDisabledNext(true);
  }

  componentDidUpdate(prevProps: ICampaingsCreateStep2Props) {
    const { goNext, onNext } = this.props;
    if (goNext && prevProps.goNext !== goNext) {
      onNext(this.state);
    }
  }

  handleSelect(selectedOptions: string[]) {
    this.setState({ selectedAudience: selectedOptions });
  }

  handleSaveAudience() {
    const { setDisabledNext } = this.props;
    setDisabledNext(true);
    this.setState({ savedAudience: true }, () => {
      setDisabledNext(false);
    });
  }

  resetState(selectedCard: string) {
    const { setDisabledNext } = this.props;
    this.setState(
      {
        selectedCard,
        savedAudience: false,
        selectedAudience: []
      },
      () => setDisabledNext(true)
    );
  }

  render() {
    const { setDisabledNext } = this.props;
    const { selectedCard, selectedAudience, savedAudience } = this.state;
    return (
      <CampaignsCreateStepContainer>
        <div className="col-50">
          <div
            className={`card-container ${
              selectedCard === "incentive" ? "card-shadow" : ""
              } ${selectedCard === "contest" ? "card-opacity" : ""}`}
          >
            <div className="card-container__subtitle">Campaña</div>
            <div className="card-container__title">Incentivo</div>
            {selectedCard !== "incentive" ? (
              <div className="card-container__button">
                <MainButton
                  text={"SELECCIONAR"}
                  onClick={() => this.resetState("incentive")}
                />
              </div>
            ) : (
                <>
                  <div className="card-container__info">
                    {savedAudience
                      ? "Público objetivo elegido:"
                      : "Acabas de seleccionar el tipo de campaña incentivo."}
                  </div>
                  {savedAudience && (
                    <>
                      {selectedAudience.length === 0 ? (
                        <ul className="card-container__list">
                          <li>{'Todos los mediadores'}</li>
                        </ul>
                      ) : (
                          <ul className="card-container__list">
                            {selectedAudience.map((aud: string) => (
                              <li>{audienceOptionsKeyValues[aud]}</li>
                            ))}
                          </ul>
                        )}

                      <div className="card-container__button">
                        <MainButton
                          text={"EDITAR"}
                          type={"full-light"}
                          onClick={() => {
                            this.setState(
                              {
                                savedAudience: false
                              },
                              () => {
                                setDisabledNext(true);
                              }
                            );
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            <div className="card-container_back-img"></div>
          </div>
          <div
            className={`card-container ${
              selectedCard === "contest" ? "card-shadow" : ""
              } ${selectedCard === "incentive" ? "card-opacity" : ""}`}
          >
            <div className="card-container__subtitle">Campaña</div>
            <div className="card-container__title">Concurso</div>
            {selectedCard !== "contest" ? (
              <div className="card-container__button">
                <MainButton
                  text={"SELECCIONAR"}
                  onClick={() => this.resetState("contest")}
                />
              </div>
            ) : (
                <>
                  <div className="card-container__info">
                    {savedAudience
                      ? "Público objetivo elegido:"
                      : "Acabas de seleccionar el tipo de campaña concurso."}
                  </div>
                  {savedAudience && (
                    <>
                      {selectedAudience.length === 0 ? (
                        <ul className="card-container__list">
                          <li>{'Todos los mediadores'}</li>
                        </ul>
                      ) : (
                          <ul className="card-container__list">
                            {selectedAudience.map((aud: string) => (
                              <li>{audienceOptionsKeyValues[aud]}</li>
                            ))}
                          </ul>
                        )}
                      <div className="card-container__button">
                        <MainButton
                          text={"EDITAR"}
                          type={"full-light"}
                          onClick={() => {
                            this.setState(
                              {
                                savedAudience: false
                              },
                              () => {
                                setDisabledNext(true);
                              }
                            );
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}

            {/* <div className="card-container_back-img">
              <img src={images.WaveCampaignsCard} />
            </div> */}
          </div>
        </div>
        <div className="col-50">
          <div className="section">
            {selectedCard === "incentive" && !savedAudience && (
              <>
                <div className="section__title">{"PÚBLICO OBJETIVO"}</div>
                <div className="section__subtitle">
                  {"Puede seleccionar varios tipos de objetivo."}
                </div>
                <div>
                  <SelectBox
                    initialValue={""}
                    labelText="PÚBLICO"
                    required={true}
                    multiple={true}
                    errorCode={""}
                    optionKey={"value"}
                    optionValue={"label"}
                    optionsText={audienceOptions}
                    placeholder={"Seleccione el tipo de público"}
                    selectedMultipleOptions={selectedAudience}
                    onMultipleChange={selectedOptions =>
                      this.handleSelect(selectedOptions)
                    }
                    onChange={(value: string) => { }}
                  />
                </div>
                <div className="step-2-next-button">
                  <MainButton
                    text="CONTINUAR"
                    onClick={() => this.handleSaveAudience()}
                  />
                </div>
              </>
            )}
          </div>
          <div className="section">
            {selectedCard === "contest" && !savedAudience && (
              <>
                <div className="section__title">{"PÚBLICO OBJETIVO"}</div>
                <div className="section__subtitle">
                  {"Puede seleccionar varios tipos de objetivo."}
                </div>
                <div>
                  <SelectBox
                    initialValue={""}
                    labelText="PÚBLICO"
                    required={true}
                    multiple={true}
                    errorCode={""}
                    optionKey={"value"}
                    optionValue={"label"}
                    selectedMultipleOptions={selectedAudience}
                    optionsText={audienceOptions}
                    placeholder={"Seleccione el tipo de público"}
                    onMultipleChange={selectedOptions =>
                      this.handleSelect(selectedOptions)
                    }
                    onChange={(value: string) => { }}
                  />
                </div>
                <div className="step-2-next-button">
                  <MainButton
                    text="CONTINUAR"
                    onClick={() => this.handleSaveAudience()}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </CampaignsCreateStepContainer>
    );
  }
}

export default CampaingsCreateStep2;
