import * as React from "react";
import { ItemCampaignGoalStyle } from "./item-campaign-goal-style";

export interface IItemCampaignGoalProps {
  text?: string[];
  title: string;
  icon: string;
}

export interface IItemCampaignGoalState {}

export default class ItemCampaignGoal extends React.Component<IItemCampaignGoalProps, IItemCampaignGoalState> {
  constructor(props: IItemCampaignGoalProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { text, title, icon } = this.props;
    return (
      <ItemCampaignGoalStyle className={text && "with-text"}>
        <div className="item-campaign-goal-icon">
          <img src={icon} alt="" />
        </div>
        <div className="item-campaign-goal-content">
          <div className="item-campaign-goal-content__title">
            <p>{title}</p>
          </div>

          {text && (
            <div className="item-campaign-goal-content__text">
              {text.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          )}
        </div>
      </ItemCampaignGoalStyle>
    );
  }
}
