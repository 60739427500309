export const inputDisabled = {
  required: false,
  disabled: true,
  showError: false,
  errorText: '',
  initValue: '',
};

export const inputEnabled = {
  required: false,
  disabled: false,
  showError: false,
  errorText: '',
  initValue: '',
};

export const inputError = {
  required: false,
  disabled: false,
  showError: true,
  errorText: '*Campo obligatorio',
  initValue: '',
};

export const inputRequired = {
  required: true,
  disabled: false,
  showError: false,
  errorText: '',
  initValue: '',
};

export const policesColumns = [
  {
    text: "Linea Negocio",
    filter: true,
    ascendent: true,
    key: 'responsiveHeader'
  },
  {
    text: "Colectivo",
    filter: true,
    ascendent: true,
    key: 'responsiveHeader'
  },
  {
    text: "Póliza / Orden",
    filter: true,
    ascendent: false,
    key: 'responsiveHeader fixedWidth160'
  },
  {
    text: "Mediador",
    filter: true,
    ascendent: true,
    key: 'responsiveHeader fixedWidth150'
  },
  {
    text: "Documento Acreditativo Tomador",
    filter: true,
    ascendent: true,
    key: 'responsiveHeader fixedWidth170'
  },
  {
    text: "Nombre Completo Tomador",
    filter: true,
    ascendent: true,
    key: 'responsiveHeader'
  },
  {
    text: "Estado de la póliza",
    filter: true,
    ascendent: true,
    key: 'responsiveHeader fixedWidth150'
  },
  {
    text: "Corriente Pago",
    filter: true,
    ascendent: true,
    key: 'responsiveHeader fixedWidth150'
  },
];

export const policyDataColumns = [
  {
    text: "Linea/Grupo de negocio",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Colectivo",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Nº de póliza",
    filter: false,
    ascendent: false,
    key: "responsiveHeader",
  },
  {
    text: "Estado",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Fecha de emisión",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Fecha de efecto",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Fecha de vencimiento",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Forma de pago",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
];

export const takerDataColumns = [
  {
    text: "DNI/NIE",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Fecha de nacimiento",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Sexo",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Estado civil",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Dirección completa",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Teléfono",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Email",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
];

export const petDataColumns = [
  {
    text: "Fecha efecto alta",
    filter: true,
    ascendent: true,
    key: "responsiveHeader fixedWidth170",
  },
  {
    text: "Fecha efecto baja",
    filter: true,
    ascendent: true,
    key: "responsiveHeader fixedWidth170",
  },
  {
    text: "Nombre mascotas",
    filter: true,
    ascendent: false,
    key: "responsiveHeader",
  },
  {
    text: "Nombre del propietario",
    filter: true,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Nº chip",
    filter: true,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Fecha de nacimiento",
    filter: true,
    ascendent: true,
    key: "responsiveHeader fixedWidth170",
  },
  {
    text: "Tipo mascota",
    filter: true,
    ascendent: true,
    key: "responsiveHeader",
  },
];

export const personDataColumns = [
  {
    text: "Fecha efecto alta",
    filter: true,
    ascendent: true,
    key: "responsiveHeader fixedWidth170",
  },
  {
    text: "Fecha efecto baja",
    filter: true,
    ascendent: true,
    key: "responsiveHeader fixedWidth170",
  },
  {
    text: "Nombre y apellidos",
    filter: true,
    ascendent: false,
    key: "responsiveHeader",
  },
  {
    text: "Documento acreditativo",
    filter: true,
    ascendent: true,
    key: "responsiveHeader fixedWidth170",
  },
  {
    text: "Fecha de nacimiento",
    filter: true,
    ascendent: true,
    key: "responsiveHeader fixedWidth170",
  },
  {
    text: "Sexo",
    filter: true,
    ascendent: true,
    key: "responsiveHeader fixedWidth150",
  },
  {
    text: "Dirección",
    filter: true,
    ascendent: true,
    key: "responsiveHeader",
  },
];

export const receiptDataColumns = [
  {
    text: "Nº recibo",
    filter: true,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Estado",
    filter: true,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Código de delegación de cobro",
    filter: true,
    ascendent: false,
    key: "responsiveHeader",
  },
  {
    text: "Fecha de vencimiento",
    filter: true,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Fecha de emisión",
    filter: true,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Fecha de envío al cobro",
    filter: true,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Fecha de alta",
    filter: true,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Importe total del recibo",
    filter: true,
    ascendent: true,
    key: "responsiveHeader",
  },
];

export const POLICY_SEARCH_OPTION = {
  ASEGURADO: "Asegurado",
  TOMADOR: "Tomador",
  POLIZA: "Poliza",
}

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  }
};