export const DEFAULT_PAY_METHOD = {
    code: 'MENS',
    description: 'Mensual'
}

export const PAY_TYPES = {
    MENS: 'MENS',
    BIME: 'BIME',
    TRIM: 'TRIM',
    SEME: 'SEME',
    ANUA: 'ANUA'
}

export const ALL_PAY_TYPES = {
    ...PAY_TYPES,
    UNIC: 'UNIC'
}

export const PAY_METHODS = {
    BANC: 'BANC',
    TABA: 'TABA',
}
