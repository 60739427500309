import { getDayMonthYear } from "./time";
import { formatCoverages } from "./../utils/coverages";
import ProductServices from "../services/ProductServices";
import { AREA_NAMES } from "../constants/policyType";
import { formatter } from "./utility";
//import { SIGNATURE_STATUS_MODIFY_POLICY } from "../containers/hirings/new-hiring/types";

const getCoverages = (insured, productType, coveragesRaw) => {
  if (
    !coveragesRaw || !coveragesRaw[productType] ||
    (coveragesRaw.constructor === Object &&
      Object.keys(coveragesRaw).length === 0)
  ) {
    return;
  }
  const formatCapitales = (cap) => {
    let options = []
    cap.map((v) => 
      options.push({ "label": `${formatter.format(Number(v.importe))}`, "value": v.importe })
    )
    return options;
  }
  const coverages = formatCoverages(coveragesRaw[productType]);
  const coveragesObj = {};
  const keys = Object.keys(coverages);
  keys.forEach((k) => {
    for (let i = 0; i < coverages[k].items.length; i++) {
      const coverage = insured.coverages
        ? insured.coverages.find(
          (c) => c.code === coverages[k].items[i].code
        ) || {}
        : {};
      coveragesObj[coverages[k].items[i].code] = {
        value: k === "main" || !!coverage.capital,
        error: false,
        errorCode: "",
        required: k !== "complementary",
        name: coverages[k].items[i].name,
        complementary: k === "complementary",
        optional: k === "optional",
        notShow: k === "notShow",
      };
      if (coverages[k].items[i].capital) {
        coveragesObj[coverages[k].items[i].code] = {
          ...coveragesObj[coverages[k].items[i].code],
          min: coverages[k].items[i].capital.min,
          max: coverages[k].items[i].capital.max,
          options: coverages[k].items[i].capital.options,
          capital: {
            value:
              coverages[k].items[i].capital.value || coverage.capital || "",
            error: false,
            errorCode: "",
            required: false,
          },
        };
      }
      if (coverages[k].items[i].code === "V096") {
        coveragesObj[coverages[k].items[i].code] = {
          ...coveragesObj[coverages[k].items[i].code],
          capital: {
            value: coverage.capital ? coverage.capital.toString() || "" : "",
            error: false,
            errorCode: "",
            required: false,
          },
        };
      }
      
      if((productType==="pet" || productType==="pet_2024") && coveragesObj[coverages[k].items[i].code].complementary){
        let options=coveragesRaw[productType].find((codes)=>codes.code===coverages[k].items[i].code)
        coveragesObj[coverages[k].items[i].code] = {
          ...coveragesObj[coverages[k].items[i].code],
          capital: {
            value: coverage.capital ? coverage.capital.toString() || "" : "",
            error: false,
            errorCode: "",
            required: false,
          },
          options: formatCapitales(options.capitales)
        };
      }
      if(coverage.otherInsurance && coverage.otherInsurance!==null){
        coveragesObj[coverages[k].items[i].code] = {
          ...coveragesObj[coverages[k].items[i].code],
          otherInsurance:{
            company:{
              value: coverage.otherInsurance.company,
              error: false,
              errorCode: "",
              required: true,
            },
            numberPolicy:{
              value: coverage.otherInsurance.numberPolicy,
              error: false,
              errorCode: "",
              required: true,
            }
          }
        }
      }
      else{
        coveragesObj[coverages[k].items[i].code] = {
          ...coveragesObj[coverages[k].items[i].code],
          otherInsurance:null
        }
      }
    }
  });

  return coveragesObj;
};

const getInsureds = (insureds, productType, taker, coveragesRaw, productsByAreaName, productCoverageName) => {

  const isPetArea = ProductServices.checkProductArea(AREA_NAMES.PET, productType, productsByAreaName)
  const isDeathArea = ProductServices.checkProductArea(AREA_NAMES.DEATH, productType, productsByAreaName)
  const isLifeArea = ProductServices.checkProductArea(AREA_NAMES.LIFE, productType, productsByAreaName)

  const urlString = window.location.href;
  const isDeathFirstStep = isDeathArea && urlString.includes("tomador");

  return insureds.map((insured) => ({
    useTakerData: insured.useTakerData || (insured.docNumber && taker.docNumber === insured.docNumber),
    foreignAddress: {
      country: {
        value: insured.foreignAddress ? insured.foreignAddress.country : "",
        label: insured.foreignAddress ? insured.foreignAddress.countryLabel : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
      },
      address: {
        value: insured.foreignAddress ? insured.foreignAddress.address : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
      },
    },
    contact: {
      name: {
        value: insured.name,
        error: false,
        errorCode: "",
        required: true,
      },
      lastname1: {
        value: insured.lastname1,
        error: false,
        errorCode: "",
        required: true,
      },
      lastname2: {
        value: insured.lastname2,
        error: false,
        errorCode: "",
        required: insured.nationality === "ESPA" ? true : false,
      },
      birthdate: {
        value: getDayMonthYear(insured.birthdate),
        error: false,
        errorCode: "",
        required: true,
      },
      docType: {
        value: insured.docType,
        error: false,
        errorCode: "",
        required: true,
      },
      docNumber: {
        value: insured.docNumber,
        error: false,
        errorCode: "",
        required: true,
      },
      sex: {
        value: insured.sex,
        error: false,
        errorCode: "",
        required: true,
      },
      civilStatus: {
        value: insured.civilStatus,
        error: false,
        errorCode: "",
        required: false,
      },
      nationality: {
        value: { value: insured.nationality, label: insured.nationalityLabel }, // VALUE : {LABEL: '', VALUE: ''}
        error: false,
        errorCode: "",
        required: false,
      },
    },
    profession: {
      occupation: {
        value: insured.occupation, // VALUE : {LABEL: '', VALUE: ''}
        error: false,
        errorCode: "",
        required: !isDeathArea && !isPetArea,
      },
      riskActivity: {
        value: insured.riskActivity ? "Y" : "N",
        error: false,
        errorCode: "",
        required: false,
      },
      activityType: {
        value: insured.riskActivity, // VALUE: {LABEL: '', VALUE: ''}
        error: false,
        errorCode: "",
        required: false,
      },
    },
    address: {
      street: {
        value: {
          label: !isPetArea ? insured.address.street : "",
          value:
            !isPetArea
              ? insured.address.street.replace(insured.address.streetType, "")
              : "",
          extra:
            !isPetArea
              ? { street: { code: insured.address.streetType } }
              : "",
        },
        error: false,
        errorCode: "",
        required: true,
      },
      streetNumber: {
        value: insured.address.streetNumber,
        error: false,
        errorCode: "",
        required: !isPetArea,
      },
      stairs: {
        value: insured.address.stairs,
        error: false,
        errorCode: "",
        required: false,
      },
      floorNumber: {
        value: insured.address.floorNumber,
        error: false,
        errorCode: "",
        required: false,
      },
      door: {
        value: insured.address.floorNumber,
        error: false,
        errorCode: "",
        required: false,
      },
      province: {
        value: {
          label: insured.address.provinceLabel,
          value: insured.address.province,
        },
        error: false,
        errorCode: "",
        required: !isPetArea,
      },
      city: {
        value: {
          label: insured.address.cityLabel,
          value: insured.address.city,
        },
        error: false,
        errorCode: "",
        required: !isPetArea,
      },
      postalCode: {
        value: {
          label: insured.address.postalCode,
          value: insured.address.postalCode,
        },
        error: false,
        errorCode: "",
        required: !isPetArea,
      },
      normalizaDireciones: {
        value: insured.address.normalizaDireciones || "S",
        error: false,
        errorCode: "",
        required: false,
      }
    },
    info: {
      landlinePhoneNumber: {
        value: insured.landlinePhoneNumber,
        error: false,
        errorCode: "",
        required: false,
      },
      mobile: {
        value:
          insured.movilPhonePrefix && insured.movilPhonePrefix !== ""
            ? `${insured.movilPhonePrefix} ${insured.movilPhoneNumber}`
            : "",
        error: false,
        errorCode: "",
        required: false,
      },
      email: {
        value: insured.email,
        error: false,
        errorCode: "",
        required: !isPetArea && !isDeathArea,
      },
    },
    date: {
      iban: {
        value: taker.iban,
        error: false,
        errorCode: "",
        required: false,
      },
      payType: {
        value: taker.payType,
        error: false,
        errorCode: "",
        required: false,
      },
      starterDay: {
        value: taker.starterDay,
        error: false,
        errorCode: "",
        required: true,
      },
      capital: {
        value: isLifeArea ? insured.coverages[0].capital.toString() : "",
        error: false,
        errorCode: "",
        required: isLifeArea,
      },
    },
    coverages: getCoverages(insured, productCoverageName, coveragesRaw),
    address2: {
      numberInsured: {
        value: "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      streetType: {
        value:
          insured.foreignAddress && insured.foreignAddress.address
            ? insured.foreignAddress.address.split(" ")[0]
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      street: {
        value:
          insured.foreignAddress && insured.foreignAddress.address
            ? insured.foreignAddress.address.split(" ")[1]
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      streetNumber: {
        value:
          insured.foreignAddress &&
            insured.foreignAddress.address &&
            insured.foreignAddress.address.split(" ")[3]
            ? insured.foreignAddress.address.split(" ")[3].replace(",", "")
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      stairs: {
        value:
          insured.foreignAddress && insured.foreignAddress.address
            ? insured.foreignAddress.address.split(" ")[4]
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      floorNumber: {
        value:
          insured.foreignAddress &&
            insured.foreignAddress.address &&
            insured.foreignAddress.address.split(" ")[5]
            ? insured.foreignAddress.address.split(" ")[5].split("-")[0]
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      door: {
        value:
          insured.foreignAddress &&
            insured.foreignAddress.address &&
            insured.foreignAddress.address.split(" ")[5]
            ? insured.foreignAddress.address.split(" ")[5].split("-")[1]
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      province: {
        value:
          insured.foreignAddress &&
            insured.foreignAddress.address &&
            insured.foreignAddress.address.split(" ")[7]
            ? insured.foreignAddress.address.split(" ")[7].replace(",", "")
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      city: {
        value:
          insured.foreignAddress &&
            insured.foreignAddress.address &&
            insured.foreignAddress.address.split(" ")[6]
            ? insured.foreignAddress.address.split(" ")[6].replace(",", "")
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      postalCode: {
        value:
          insured.foreignAddress && insured.foreignAddress.address
            ? insured.foreignAddress.address
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      country: {
        value:
          insured.foreignAddress && insured.foreignAddress.country
            ? {
              label: insured.foreignAddress.country,
              value: insured.foreignAddress.country,
            }
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
    },
    serviceCodeBlock: {
      serviceCode: {
        value: insured.serviceCode || "",
        error: false,
        errorCode: "",
        // required: productType === "death",
        required: isDeathFirstStep,
        disabled: true,
      },
    },
    hiringDeath: {
      checkedConditions: {
        error: false,
        errorCode: "",
        required: true,
        value: insured.checkedConditions ? insured.checkedConditions : false
      }
    },
    // payment: {
    //   payType: {
    //     value: "",
    //     error: false,
    //     errorCode: "",
    //     required: productType === "death",
    //     disabled: true,
    //   },
    // },
    questionnaires: insured.questionnaires,
  }));
};

const getInsuredsPet = (insureds, taker, coveragesRaw, productCoverageName) => {
  // const owner = getInsureds(
  //   insureds,
  //   productType,
  //   taker,
  //   coveragesRaw
  // );

  if (taker && insureds[0] && taker.docNumber === insureds[0].docNumber)
    return [];

  return insureds.map((insured) => ({
    contact: {
      name: {
        value: insured.name,
        error: false,
        errorCode: "",
        required: true,
      },
      birthdate: {
        value: getDayMonthYear(insured.birthdate),
        error: false,
        errorCode: "",
        required: true,
      },
      type: {
        value: insured.type,
        error: false,
        errorCode: "",
        required: true,
      },
      breed: {
        value: {
          value: insured.breed,
          label: insured.breedLabel,
        },
        error: false,
        errorCode: "",
        required: true,
      },
      owner: {
        value: insureds[0]
          ? `${taker.name} ${taker.lastname1} ${taker.lastname2}`
          : "",
        error: false,
        errorCode: "",
        required: true,
      },
      numChip: {
        value: insured.numChip,
        error: false,
        errorCode: "",
        required: true,
      },
    },
    coverages: getCoverages(insured, productCoverageName, coveragesRaw),
  }));
};
const getBeneficiary = (beneficiary, productType, taker, coveragesRaw, productsByAreaName) => {

  const isPetArea = ProductServices.checkProductArea(AREA_NAMES.PET, productType, productsByAreaName)
  const isDeathArea = ProductServices.checkProductArea(AREA_NAMES.DEATH, productType, productsByAreaName)
  const isLifeArea = ProductServices.checkProductArea(AREA_NAMES.LIFE, productType, productsByAreaName)

  const urlString = window.location.href;
  const isDeathFirstStep = isDeathArea && urlString.includes("tomador");
  let benef;

  if(beneficiary){
  benef={
    useTakerData: beneficiary.useTakerData || (beneficiary.docNumber && taker.docNumber === beneficiary.docNumber),
    foreignAddress: {
      country: {
        value: beneficiary.foreignAddress ? beneficiary.foreignAddress.country : "",
        label: beneficiary.foreignAddress ? beneficiary.foreignAddress.countryLabel : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
      },
      address: {
        value: beneficiary.foreignAddress ? beneficiary.foreignAddress.address : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
      },
    },
    contact: {
      name: {
        value: beneficiary.name,
        error: false,
        errorCode: "",
        required: true,
      },
      lastname1: {
        value: beneficiary.lastname1,
        error: false,
        errorCode: "",
        required: true,
      },
      lastname2: {
        value: beneficiary.lastname2,
        error: false,
        errorCode: "",
        required: beneficiary.nationality === "ESPA" ? true : false,
      },
      birthdate: {
        value: getDayMonthYear(beneficiary.birthdate),
        error: false,
        errorCode: "",
        required: true,
      },
      docType: {
        value: beneficiary.docType,
        error: false,
        errorCode: "",
        required: true,
      },
      docNumber: {
        value: beneficiary.docNumber,
        error: false,
        errorCode: "",
        required: true,
      },
      sex: {
        value: beneficiary.sex,
        error: false,
        errorCode: "",
        required: true,
      },
      civilStatus: {
        value: beneficiary.civilStatus,
        error: false,
        errorCode: "",
        required: false,
      },
      nationality: {
        value: { value: beneficiary.nationality, label: beneficiary.nationalityLabel }, // VALUE : {LABEL: '', VALUE: ''}
        error: false,
        errorCode: "",
        required: false,
      },
    },
    profession: {
      occupation: {
        value: beneficiary.occupation, // VALUE : {LABEL: '', VALUE: ''}
        error: false,
        errorCode: "",
        required: !isDeathArea && !isPetArea,
      },
      riskActivity: {
        value: beneficiary.riskActivity ? "Y" : "N",
        error: false,
        errorCode: "",
        required: false,
      },
      activityType: {
        value: beneficiary.riskActivity, // VALUE: {LABEL: '', VALUE: ''}
        error: false,
        errorCode: "",
        required: false,
      },
    },
    address: {
      street: {
        value: {
          label: beneficiary.address.street,
          value: beneficiary.address.street.replace(beneficiary.address.streetType, ""),
          extra: { street: { code: beneficiary.address.streetType } }
        },
        error: false,
        errorCode: "",
        required: true,
      },
      streetNumber: {
        value: beneficiary.address.streetNumber,
        error: false,
        errorCode: "",
        required: !isPetArea,
      },
      stairs: {
        value: beneficiary.address.stairs,
        error: false,
        errorCode: "",
        required: false,
      },
      floorNumber: {
        value: beneficiary.address.floorNumber,
        error: false,
        errorCode: "",
        required: false,
      },
      door: {
        value: beneficiary.address.floorNumber,
        error: false,
        errorCode: "",
        required: false,
      },
      province: {
        value: {
          label: beneficiary.address.provinceLabel,
          value: beneficiary.address.province,
        },
        error: false,
        errorCode: "",
        required: !isPetArea,
      },
      city: {
        value: {
          label: beneficiary.address.cityLabel,
          value: beneficiary.address.city,
        },
        error: false,
        errorCode: "",
        required: !isPetArea,
      },
      postalCode: {
        value: {
          label: beneficiary.address.postalCode,
          value: beneficiary.address.postalCode,
        },
        error: false,
        errorCode: "",
        required: !isPetArea,
      },
      normalizaDireciones: {
        value: beneficiary.address.normalizaDireciones || "S",
        error: false,
        errorCode: "",
        required: false,
      }
    },
    info: {
      landlinePhoneNumber: {
        value: beneficiary.landlinePhoneNumber,
        error: false,
        errorCode: "",
        required: false,
      },
      mobile: {
        value:
          beneficiary.movilPhonePrefix && beneficiary.movilPhonePrefix !== ""
            ? `${beneficiary.movilPhonePrefix} ${beneficiary.movilPhoneNumber}`
            : "",
        error: false,
        errorCode: "",
        required: false,
      },
      email: {
        value: beneficiary.email,
        error: false,
        errorCode: "",
        required: !isPetArea && !isDeathArea,
      },
    },
    date: {
      iban: {
        value: taker.iban,
        error: false,
        errorCode: "",
        required: false,
      },
      payType: {
        value: taker.payType,
        error: false,
        errorCode: "",
        required: false,
      },
      starterDay: {
        value: taker.starterDay,
        error: false,
        errorCode: "",
        required: true,
      },
      capital: {
        value: isLifeArea ? beneficiary.coverages[0].capital.toString() : "",
        error: false,
        errorCode: "",
        required: isLifeArea,
      },
    },
    address2: {
      numberInsured: {
        value: "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      streetType: {
        value:
          beneficiary.foreignAddress && beneficiary.foreignAddress.address
            ? beneficiary.foreignAddress.address.split(" ")[0]
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      street: {
        value:
          beneficiary.foreignAddress && beneficiary.foreignAddress.address
            ? beneficiary.foreignAddress.address.split(" ")[1]
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      streetNumber: {
        value:
          beneficiary.foreignAddress &&
            beneficiary.foreignAddress.address &&
            beneficiary.foreignAddress.address.split(" ")[3]
            ? beneficiary.foreignAddress.address.split(" ")[3].replace(",", "")
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      stairs: {
        value:
          beneficiary.foreignAddress && beneficiary.foreignAddress.address
            ? beneficiary.foreignAddress.address.split(" ")[4]
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      floorNumber: {
        value:
          beneficiary.foreignAddress &&
            beneficiary.foreignAddress.address &&
            beneficiary.foreignAddress.address.split(" ")[5]
            ? beneficiary.foreignAddress.address.split(" ")[5].split("-")[0]
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      door: {
        value:
          beneficiary.foreignAddress &&
            beneficiary.foreignAddress.address &&
            beneficiary.foreignAddress.address.split(" ")[5]
            ? beneficiary.foreignAddress.address.split(" ")[5].split("-")[1]
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      province: {
        value:
          beneficiary.foreignAddress &&
            beneficiary.foreignAddress.address &&
            beneficiary.foreignAddress.address.split(" ")[7]
            ? beneficiary.foreignAddress.address.split(" ")[7].replace(",", "")
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      city: {
        value:
          beneficiary.foreignAddress &&
            beneficiary.foreignAddress.address &&
            beneficiary.foreignAddress.address.split(" ")[6]
            ? beneficiary.foreignAddress.address.split(" ")[6].replace(",", "")
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      postalCode: {
        value:
          beneficiary.foreignAddress && beneficiary.foreignAddress.address
            ? beneficiary.foreignAddress.address
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
      country: {
        value:
          beneficiary.foreignAddress && beneficiary.foreignAddress.country
            ? {
              label: beneficiary.foreignAddress.country,
              value: beneficiary.foreignAddress.country,
            }
            : "",
        error: false,
        errorCode: "",
        required: isDeathArea,
        disabled: true,
      },
    },
    serviceCodeBlock: {
      serviceCode: {
        value: beneficiary.serviceCode || "",
        error: false,
        errorCode: "",
        // required: productType === "death",
        required: isDeathFirstStep,
        disabled: true,
      },
    },
    hiringDeath: {
      checkedConditions: {
        error: false,
        errorCode: "",
        required: true,
        value: beneficiary.checkedConditions ? beneficiary.checkedConditions : false
      }
    },
    questionnaires: beneficiary.questionnaires,
  };
}
else{
  benef={};
}

  return benef;
};
const getBeneficiaries = (data) => {
  return data.beneficiaries
    ? data.beneficiaries.map((b) => ({
      name: {
        value: b.name,
        error: false,
        errorCode: "",
        required: true,
      },
      lastName1: {
        value: b.lastName1,
        error: false,
        errorCode: "",
        required: true,
      },
      lastName2: {
        value: b.lastName2,
        error: false,
        errorCode: "",
        required: false,
      },
      docType: {
        value: b.docType,
        error: false,
        errorCode: "",
        required: true,
      },
      docNumber: {
        value: b.docNumber,
        error: false,
        errorCode: "",
        required: true,
      },
      percentage: {
        value: b.percentage,
        error: false,
        errorCode: "",
        required: true,
      },
    }))
    : [];
};

const getLoan = (data) => {
  return data.loan ? data.loan : {};
};

export const getDataForm = (data, productType, productCoverageName, productsByAreaName, coveragesRaw, status = '') => {

  const isHealthArea = ProductServices.checkProductArea(AREA_NAMES.HEALTH, productType, productsByAreaName)
  const isGIPArea = ProductServices.checkProductArea(AREA_NAMES.GIP, productType, productsByAreaName)
  const isPetArea = ProductServices.checkProductArea(AREA_NAMES.PET, productType, productsByAreaName)
  const isDeathArea = ProductServices.checkProductArea(AREA_NAMES.DEATH, productType, productsByAreaName)
  const isLifeArea = ProductServices.checkProductArea(AREA_NAMES.LIFE, productType, productsByAreaName)
  const isDentalArea = ProductServices.checkProductArea(AREA_NAMES.DENTAL, productType, productsByAreaName)
  const isTravelArea = ProductServices.checkProductArea(AREA_NAMES.TRAVEL, productType, productsByAreaName)

  const urlString = window.location.href;
  const hiring = urlString.indexOf("contratacion") > -1;
  const isDeathFirstStep = isDeathArea && urlString.includes("tomador");
 
  if (isHealthArea || isGIPArea || isDentalArea || isTravelArea) {
    return {
      mediator: data.mediatorData,
      insureds: data.insuredData,
      price: data.priceData
    }
  }
  let ret = {
    taker: {
      contact: {
        name: {
          value: data.taker.name,
          error: false,
          errorCode: "",
          required: true,
        },
        lastname1: {
          value: data.taker.lastname1,
          error: false,
          errorCode: "",
          required: true,
        },
        lastname2: {
          value: data.taker.lastname2,
          error: false,
          errorCode: "",
          required: false,
        },
        birthdate: {
          value: getDayMonthYear(data.taker.birthdate),
          error: false,
          errorCode: "",
          required: true,
        },
        docType: {
          value: data.taker.docType,
          error: false,
          errorCode: "",
          required: true,
        },
        docNumber: {
          value: data.taker.docNumber,
          error: false,
          errorCode: "",
          required: true,
        },
        sex: {
          value: data.taker.sex,
          error: false,
          errorCode: "",
          required: true,
        },
        civilStatus: {
          value: data.taker.civilStatus,
          error: false,
          errorCode: "",
          required: false,
        },
        nationality: {
          value: {
            value: data.taker.nationality,
            label: data.taker.nationalityLabel
          }, // VALUE : {LABEL: '', VALUE: ''}
          error: false,
          errorCode: "",
          required: false,
        },
      },
      profession: {
        occupation: {
          value: data.taker.occupation, // VALUE : {LABEL: '', VALUE: ''}
          error: false,
          errorCode: "",
          required: !isDeathArea && !isPetArea,
          label: data.taker.occupationLabel
        },
        riskActivity: {
          value: data.taker.riskActivity ? "Y" : "N",
          error: false,
          errorCode: "",
          required: false,
          label: data.taker.riskActivityLabel
        },
        activityType: {
          value: data.taker.riskActivity, // VALUE : {LABEL: '', VALUE: ''}
          error: false,
          errorCode: "",
          required: false,
          label: data.taker.riskActivityLabel
        },
      },
      address: {
        street: {
          value: {
            label: data.taker.address.street,
            value: data.taker.address.street.replace(
              data.taker.address.streetType,
              ""
            ),
            extra: { street: { code: data.taker.address.streetType } },
          },
          error: false,
          errorCode: "",
          required: true,
        },
        streetNumber: {
          value: data.taker.address.streetNumber,
          error: false,
          errorCode: "",
          required: true,
        },
        stairs: {
          value: data.taker.address.stairs,
          error: false,
          errorCode: "",
          required: false,
        },
        floorNumber: {
          value: data.taker.address.floorNumber,
          error: false,
          errorCode: "",
          required: false,
        },
        door: {
          value: data.taker.address.floorNumber,
          error: false,
          errorCode: "",
          required: false,
        },
        province: {
          value: {
            label: data.taker.address.provinceLabel,
            value: data.taker.address.province,
          },
          error: false,
          errorCode: "",
          required: true,
        },
        city: {
          value: {
            label: data.taker.address.cityLabel,
            value: data.taker.address.city,
          },
          error: false,
          errorCode: "",
          required: true,
        },
        postalCode: {
          value: {
            label: data.taker.address.postalCode,
            value: data.taker.address.postalCode,
          },
          error: false,
          errorCode: "",
          required: true,
        },
        normalizaDireciones: {
          value: data.taker.address.normalizaDireciones || "S",
          error: false,
          errorCode: "",
          required: false,
        }
      },
      info: {
        landlinePhoneNumber: {
          value: data.taker.landlinePhoneNumber,
          error: false,
          errorCode: "",
          required: false,
        },
        mobile: {
          value:
            data.taker.movilPhonePrefix && data.taker.movilPhonePrefix !== ""
              ? `${data.taker.movilPhonePrefix} ${data.taker.movilPhoneNumber}`
              : "",
          error: false,
          errorCode: "",
          required: false,
        },
        email: {
          value: data.taker.email,
          error: false,
          errorCode: "",
          required: true,
        },
      },
      date: {
        iban: {
          value: data.payment ? data.payment.iban : "",
          error: false,
          errorCode: "",
          required: true,
        },
        payType: {
          value: data.payment ? data.payment.payType : isPetArea ? data.taker.payment : "",
          error: false,
          errorCode: "",
          required: true,
        },
        starterDay: {
          value:
            data.payment && data.payment.starterDay
              ? data.payment.starterDay
              : data.taker && data.taker.starterDay
                ? data.taker.starterDay
                : "",
          error: false,
          errorCode: "",
          required: true,
        },
        capital: {
          value: isLifeArea && data.insureds.length > 0 ? data.insureds[0].coverages[0].capital.toString() : "",
          error: false,
          errorCode: "",
          required: isLifeArea,
        },
      },
      coverages: getCoverages(
        data.insureds.length > 0 ? data.insureds[0] : data.taker,
        productCoverageName,
        coveragesRaw
      ),
      address2: {
        numberInsured: {
          value: "",
          error: false,
          errorCode: "",
          required: isDeathArea,
          disabled: true,
        },
        streetType: {
          value:
            data.insureds[0] &&
              data.insureds[0].foreignAddress &&
              data.insureds[0].foreignAddress.address
              ? data.insureds[0].foreignAddress.address.split(" ")[0]
              : "",
          error: false,
          errorCode: "",
          required: isDeathArea,
          disabled: true,
        },
        street: {
          value:
            data.insureds[0] &&
              data.insureds[0].foreignAddress &&
              data.insureds[0].foreignAddress.address
              ? data.insureds[0].foreignAddress.address.split(" ")[1]
              : "",
          error: false,
          errorCode: "",
          required: isDeathArea,
          disabled: true,
        },
        streetNumber: {
          value:
            data.insureds[0] &&
              data.insureds[0].foreignAddress &&
              data.insureds[0].foreignAddress.address &&
              data.insureds[0].foreignAddress.address.split(" ")[3]
              ? data.insureds[0].foreignAddress.address
                .split(" ")[3]
                .replace(",", "")
              : "",
          error: false,
          errorCode: "",
          required: isDeathArea,
          disabled: true,
        },
        stairs: {
          value:
            data.insureds[0] &&
              data.insureds[0].foreignAddress &&
              data.insureds[0].foreignAddress.address
              ? data.insureds[0].foreignAddress.address.split(" ")[4]
              : "",
          error: false,
          errorCode: "",
          required: isDeathArea,
          disabled: true,
        },
        floorNumber: {
          value:
            data.insureds[0] &&
              data.insureds[0].foreignAddress &&
              data.insureds[0].foreignAddress.address &&
              data.insureds[0].foreignAddress.address.split(" ")[5]
              ? data.insureds[0].foreignAddress.address
                .split(" ")[5]
                .split("-")[0]
              : "",
          error: false,
          errorCode: "",
          required: isDeathArea,
          disabled: true,
        },
        door: {
          value:
            data.insureds[0] &&
              data.insureds[0].foreignAddress &&
              data.insureds[0].foreignAddress.address &&
              data.insureds[0].foreignAddress.address.split(" ")[5]
              ? data.insureds[0].foreignAddress.address
                .split(" ")[5]
                .split("-")[1]
              : "",
          error: false,
          errorCode: "",
          required: isDeathArea,
          disabled: true,
        },
        province: {
          value:
            data.insureds[0] &&
              data.insureds[0].foreignAddress &&
              data.insureds[0].foreignAddress.address &&
              data.insureds[0].foreignAddress.address.split(" ")[7]
              ? data.insureds[0].foreignAddress.address
                .split(" ")[7]
                .replace(",", "")
              : "",
          error: false,
          errorCode: "",
          required: isDeathArea,
          disabled: true,
        },
        city: {
          value:
            data.insureds[0] &&
              data.insureds[0].foreignAddress &&
              data.insureds[0].foreignAddress.address &&
              data.insureds[0].foreignAddress.address.split(" ")[6]
              ? data.insureds[0].foreignAddress.address
                .split(" ")[6]
                .replace(",", "")
              : "",
          error: false,
          errorCode: "",
          required: isDeathArea,
          disabled: true,
        },
        postalCode: {
          value:
            data.insureds[0] &&
              data.insureds[0].foreignAddress &&
              data.insureds[0].foreignAddress.address
              ? data.insureds[0].foreignAddress.address
              : "",
          error: false,
          errorCode: "",
          required: isDeathArea,
          disabled: true,
        },
        country: {
          value:
            data.insureds[0] &&
              data.insureds[0].foreignAddress &&
              data.insureds[0].foreignAddress.country
              ? {
                label: data.insureds[0].foreignAddress.country.value,
                value: data.insureds[0].foreignAddress.country.label,
              }
              : "",
          error: false,
          errorCode: "",
          required: isDeathArea,
          disabled: true,
        },
      },
    },
    insureds:
      isPetArea && typeof data.insureds === "number"
        ? []
        : isPetArea
          ? getInsuredsPet(data.insureds, data.taker, coveragesRaw, productCoverageName)
          : getInsureds(data.insureds, productType, data.taker, coveragesRaw, productsByAreaName, productCoverageName),    
    beneficiary: isPetArea&&hiring?getBeneficiary(data.beneficiary, productType, data.taker, coveragesRaw, productsByAreaName):{},
    beneficiaries:
      !isPetArea && !isDeathArea
        ? getBeneficiaries(data)
        : [],
    loan: isLifeArea ? getLoan(data) : {},
    isReadOnly: status === 'EN REVISION',
  };

  if (isDeathArea) {
    ret.taker.serviceCodeBlock = {
      serviceCode: {
        value:
          (data.taker && data.taker.serviceCode) ||
          (data.insureds[0] && data.insureds[0].serviceCode) ||
          "",
        error: false,
        errorCode: "",
        // required: productType === "death",
        required: isDeathFirstStep,
        disabled: true,
      },
    }
    ret.taker.hiringDeath = {
      checkedConditions: {
        error: false,
        errorCode: "",
        required: true,
        value: (data.taker && data.taker.checkedConditions) ||
          (data.insureds[0] && data.insureds[0].checkedConditions) || false
      }
    }
  }
  return ret;
};
