
export const COVERAGES_PETS = {
  main: {
    title: "Cobertura principal",
    items: [
      {
        name: "Asistencia veterinaria",
        code: "AV01",
        checkbox: true,
        disabled: true
      }
    ]
  },
  complementary: {
    title: "Cobertura complementarias",
    items: [
      {
        name: "Servicio telefónico de orientación veterinaria",
        code: "AV02-C",
        checkbox: true,
        disabled: true,
        defaultValue: true
      },
      {
        name: 'Servicio de información "Mundo mascota"',
        code: "AV03-C",
        checkbox: true,
        disabled: true,
        defaultValue: true
      }
    ]
  },
  optional: {
    title: "",
    items: []
  }
};

export const COVERAGES_HEALTH_CAPITAL_REQUIRED = {

  items: [
    {
      code: 'GS09',
      capital: {
        options: [
          { label: "30 €", value: '30' },
          { label: "60 €", value: '60' },
          { label: "90 €", value: '90' }
        ]
      },
      reqBeneficiaries: false,
      reqProfession: false,
    },
    {
      code: 'GS99',
      capital: {
        options: [
          { label: "10.000 €", value: '10000' },
          { label: "20.000 €", value: '20000' },
          { label: "30.000 €", value: '30000' }
        ]
      },
      reqBeneficiaries: true,
      reqProfession: true,
    }
  ]

}