import React from "react";
import { withRouter } from "react-router-dom";
import { withGeneral } from "../../context/mixin/with-general";
import { FaqsContainer } from "./faqs-style";
import FaqsIframe from "../../components/faqs-iframe/faqs-iframe";
import { REACT_APP_OCASO_FORM_URL } from "../../services/url";

interface FaqsProps { }

class FaqsLayout extends React.Component<FaqsProps, {}> {
  state = { };
  
  render() {

    let url = REACT_APP_OCASO_FORM_URL + "/FormulariosMediadores/FAQ/PreguntasFrecuentes";

    return (
      <FaqsContainer>
        <FaqsIframe url={url}/>
      </FaqsContainer>
    );
  }
}

export default withRouter(withGeneral(FaqsLayout));
