import styled, { css } from 'styled-components';

export const Title02TextContainer = styled.div`
    font-family: "museo sans";
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    ${props => css`
        color: ${props.color}
    `}




`;