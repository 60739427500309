import React from "react";
import { InfoTextContainer } from "./info-text-styles";

interface InfoTextProps {
    children: any;
    className?: string;
    color?: string;
}

const InfoText = ({children = '', className='', color=''}:InfoTextProps) => {
        return (
        <InfoTextContainer color={color} className={className}>
            {children}
        </InfoTextContainer>
    )
} 
export default InfoText;