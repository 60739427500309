import styled from "styled-components";
import { colors } from "../../../assets";

export const ClientsEiacContainer = styled.div`
  font-family: Museo Sans;

  .col-1 {width: 8.33% !important;}
  .col-2 {width: 16.66%; !important}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}

  .filter-row-container {
    display: flex;
    &.right {
      float: right;
    }
  }

  .back-arrow-container {
    margin-bottom: 18px;
  }
  .main-title-container {
    margin-bottom: 38px;
    color: ${colors["PR-001-100"]};
  }

  .tabs-container {
    position: relative;
    width: fit-content;
    margin-bottom: 35px;
    &__tabs {
      display: flex;
      border-bottom: 0.5px solid #B5BCC6;
      padding: 0 20px 12px;
      div {
        cursor: pointer;
        font-size: 16px;
        line-height: 17px;
        text-transform: uppercase;
        color: #B5BCC6;
        transition: color .2s ease-in-out;
        &:first-child {
          margin-right: 28px;
        }
      }
      .selected {
        color: #004F8B;
      }
    }
    &__line {
      border-top: 4px solid #004F8B;
      width: 150px;
      position: absolute;
      bottom: -1px;
      right: auto;
      transition: right .2s ease-in-out;
      right: 142px;
    }
    .selected-right {
      right: 0;
    }
  }

  .main-filter-container {
    width: 100%;
    margin-bottom: 15px;
    background-color: #fbfbfb;
    padding: 20px;

    .keep-calm {
      padding: 1px 3px;
      background: ${colors["AL-001-20"]};
      font-size: 15px;
      margin-bottom: 30px;
  
      p {
        color: ${colors["SC-004-100"]};
        padding-right: 0;
      }
    }
  }

  .main-filter-item {
    width: 100%;
    margin-right: 36px;
    &--left {
      display: flex;
    }
    &--select {
      max-width: 200px;
      min-width: 200px;
      &:last-child {
        max-width: 170px;
        min-width: 170px;
        margin-left: 30px;
      }
    }
  }

  .main-results-number {
    color: ${colors["TX-001-100"]};
    margin-bottom: 40px;
    span {
      font-weight: 600;
    }
  }

  .pendant-simulations-table {
    margin-bottom: 75px;
    min-width: 100%;
    max-width: 1250px;
    overflow-x: auto;
  }

  .pendant-simulation-pagination {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
  }

  .main-filter-second-row {
    margin-top: 40px;
    display: flex;
    width: 100%;
  }

  .main-filter-third-row{
    display: flex;
  }
`;
