// React
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
// Assets
import { images } from "../../assets";
// Components
import MainButton from "../main-button";
import InsuredCard from "../insured-card/insured-card";
import { DentalHireMenuContainer } from "./dental-hire-menu-style";
import { withPolicy } from "../../context/mixin/with-policy";

export enum Steps {
    DatosTomador = 1,
    DatosAsegurados = 2,
    Garantias = 3,
    DocumentacionRequerida = 4,
    ProteccionDatos = 5,
    Prima = 6,
    MetodosPago = 7,
    DetallePrevioContratacion = 8
}

interface DentalHireMenuProps extends RouteComponentProps<any> {
    // Required
    initialCurrentStep: Steps,
    initialInsureds: any[], // => { name?: string, lastname1?: string, lastname2?: string, [x: string]: any }
    codMediador: string,
    selectedMediator?: string,
    insuredSaved?: boolean;
    // Optional
    disableSubButtons?: boolean,
    currentNumInsured?: number,
    isSignedDocument?: boolean;
    isHired?: boolean;
    disableSignButton?: boolean;
    physicalSignature?: boolean;
    // Functions
    previousOnNavigation?: () => boolean,
    insuredOnAdd?: (insuredSaved: any) => Promise<void>,
    insuredOnRemove?: (index: number) => void,
    insuredOnEdit?: (index: number) => void,
    insuredOnSave?: () => void,
    resumeOnHiring?: () => void,
    downloadPrecontractualDocument?: () => void,
    sendMailPrecontractualDocument?: () => void,
    signPrecontractualDocument?: () => void,
    policy: any;
}

interface DentalHireMenuState {
    currentStep: Steps,
    insureds: any[], // => { name?: string, lastname1?: string, lastname2?: string, [x: string]: any }
    showInsuredsOnDatosAsegurados: boolean,
    mediador: string
}

export class DentalHireMenuHelper {
    static getStepFromNavigationRoute(path: string): Steps {
        if (path.indexOf("/contratacion/nueva/dental/tomador") !== -1) return Steps.DatosTomador;
        else if (path.indexOf("/contratacion/nueva/dental/asegurados") !== -1) return Steps.DatosAsegurados;
        else if (path.indexOf("/contratacion/nueva/dental/garantias") !== -1) return Steps.Garantias;
        else if (path.indexOf("/contratacion/nueva/dental/documentacion-requerida") !== -1) return Steps.DocumentacionRequerida;
        else if (path.indexOf("/contratacion/nueva/dental/proteccion-datos") !== -1) return Steps.ProteccionDatos;
        else if (path.indexOf("/contratacion/nueva/dental/precio") !== -1) return Steps.Prima;
        else if (path.indexOf("/contratacion/nueva/dental/medios-pago") !== -1) return Steps.MetodosPago;
        else if (path.indexOf("/contratacion/nueva/dental/resultado") !== -1) return Steps.DetallePrevioContratacion;
        else return Steps.DatosTomador;
    }

    static getStepNavigationPath(step: Steps, insuredsLength: number): string {
        let currentInsured = insuredsLength - 1;
        currentInsured = currentInsured < 0 ? 0 : currentInsured;
        switch (step) {
            case Steps.DatosTomador: return "/contratacion/nueva/dental/tomador";
            case Steps.DatosAsegurados: return insuredsLength === 0 ?
                '/contratacion/nueva/dental/asegurados/' :
                `/contratacion/nueva/dental/asegurados/${currentInsured}`;
            case Steps.Garantias: return "/contratacion/nueva/dental/garantias";
            case Steps.DocumentacionRequerida: return "/contratacion/nueva/dental/documentacion-requerida";
            case Steps.ProteccionDatos: return "/contratacion/nueva/dental/proteccion-datos";
            case Steps.Prima: return "/contratacion/nueva/dental/precio";
            case Steps.MetodosPago: return "/contratacion/nueva/dental/medios-pago";
            case Steps.DetallePrevioContratacion: return "/contratacion/nueva/dental/resultado";
            default: return "";
        }
    }

    static getPreviousStep(currentStep: Steps): Steps {
        try {
            return currentStep - 1;
        } catch {
            return Steps.DatosTomador;
        }
    }

    static getPreviousStepNavigationPath(currentStep: Steps, insuredLength?: number): string {
        return this.getStepNavigationPath(this.getPreviousStep(currentStep), insuredLength || 1);
    }

    static getNextStep(currentStep: Steps): Steps {
        try {
            return currentStep + 1;
        } catch {
            return Steps.DetallePrevioContratacion;
        }
    }

    static getNextStepNavigationPath(currentStep: Steps): string {
        return this.getStepNavigationPath(this.getNextStep(currentStep), 1);
    }
}

class DentalHireMenuLayout extends React.Component<DentalHireMenuProps, DentalHireMenuState> {
    constructor(props: DentalHireMenuProps) {
        super(props);

        this.state = {
            currentStep: props.initialCurrentStep,
            insureds: props.initialInsureds,
            showInsuredsOnDatosAsegurados: props.initialCurrentStep === Steps.DatosAsegurados,
            mediador: '',
        };
    }

    componentDidMount() {
        this.props.history.listen((location, action) => {
            var step = DentalHireMenuHelper.getStepFromNavigationRoute(location.pathname);
            this.setState({
                currentStep: step,
                showInsuredsOnDatosAsegurados: step === Steps.DatosAsegurados,
            });
        });
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.initialInsureds !== this.props.initialInsureds) {
            this.setState({ insureds: nextProps.initialInsureds });
        }

    }

    getButtonCssName = (step: Steps): string => step === this.state.currentStep ? "" : "google"

    isMenuItemDisabled = (step: Steps): boolean => step > this.state.currentStep;

    isMenuItemSelected = (step: Steps): boolean => step === this.state.currentStep;

    handleNavigation(step: Steps): void {
        if (step === this.state.currentStep)
            return;

        let doNavigation = Steps.DatosAsegurados || !this.props.previousOnNavigation || this.props.previousOnNavigation()
        if (doNavigation) {

            switch (step) {
                default:
                    this.props.history.push(DentalHireMenuHelper.getStepNavigationPath(step, this.state.insureds.length));
                    break;
            }
        }
    }

    tryDownloadPrecontractualDocument(): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.downloadPrecontractualDocument)
                this.props.downloadPrecontractualDocument();
        }
    }

    tyrSendMailPrecontractualDocument() {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.sendMailPrecontractualDocument)
                this.props.sendMailPrecontractualDocument();
        }
    }

    trySignPrecontractualDocument() {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.signPrecontractualDocument)
                this.props.signPrecontractualDocument();
        }
    }
    tryResumeOnHiring(): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.resumeOnHiring)
                this.props.resumeOnHiring();
        }
    }

    async tryInsuredOnAdd(): Promise<void> {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.insuredOnAdd)
                await this.props.insuredOnAdd(this.props.insuredSaved);
        }
    }

    tryInsuredOnSave(): void {
        if (this.props.insuredOnSave)
            this.props.insuredOnSave()
    }

    isSubMenuItemSelected = (step: Steps, numInsured: number): boolean => step === this.state.currentStep && numInsured === (this.props.currentNumInsured ? this.props.currentNumInsured : 0);

    tryRemoveInsured(event: any, step: Steps, numInsured: number): void {
        event.stopPropagation();
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (step === Steps.DatosAsegurados && this.props.insuredOnRemove)
                this.props.insuredOnRemove(numInsured)
        }
    }

    tryGoToDetail(step: Steps, numInsured: number): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (step === Steps.DatosAsegurados && this.props.insuredOnEdit)
                this.props.insuredOnEdit(numInsured)
        }
    }

    renderInsuredCards(renderForStep: Steps, showRemoveIco: boolean = true, divClickGoToDetail: boolean = false): JSX.Element[] {
        let elements: JSX.Element[] = [];
        if (this.state.insureds) {
            for (let i = 0; i < this.state.insureds.length; i++) {
                elements.push(
                    <li key={`asegurado-${i}`}>
                        <InsuredCard
                            selected={this.isSubMenuItemSelected(renderForStep, i)}
                            insuredMember={this.state.insureds[i]}
                            removeInsured={!this.props.disableSubButtons && showRemoveIco ? (event: any) => this.tryRemoveInsured(event, renderForStep, i) : undefined}
                            goToDetail={() => this.tryGoToDetail(renderForStep, i)}
                            textSubtitle={"Asegurado"}
                            divClickGoToDetail={divClickGoToDetail}
                        />
                    </li>
                );
            }
        }
        return elements;
    }

    getMainButtonMenu(title: string, step: Steps): JSX.Element {
        return <MainButton
            text={title}
            type={this.getButtonCssName(step)}
            withTab={true}
            disabled={this.isMenuItemDisabled(step)}
            onClick={() => this.handleNavigation(step)}
        />;
    }

    getMainButtonSubMenu(title: string, step: Steps, onClick: Function, type: string | undefined = "full-light", image?: string | undefined, disabled: boolean | undefined = undefined): JSX.Element {
        return <MainButton
            text={title}
            icon={image !== undefined}
            iconImg={image}
            type={type}
            withTab={true}
            disabled={disabled !== undefined ? disabled : this.isMenuItemDisabled(step)}
            onClick={() => onClick()}
        />
    }

    render() {
        const {policy} = this.props
        const productName = policy.product.products_name
        const productCardName = policy.product.products_cardName

        return (
            <DentalHireMenuContainer>
                <div className="inflow-sidebar-type">
                    <img src={images.MiniWavePolicieDental} alt="" />
                    <div className="inflow-sidebar-type-wrapper">
                        <div className="inflow-sidebar-type-text">
                            <p>PÓLIZA {productCardName}</p>
                        </div>
                        <div className="inflow-sidebar-type-title">
                            <p>{productName}</p>
                        </div>
                    </div>
                </div>
                <div className="cod-mediador">COD. MEDIADOR<br /><span>{this.state.mediador ? this.state.mediador : this.props.selectedMediator ? this.props.selectedMediator : this.props.codMediador}</span></div>
                <ul className="ulMenu">
                    <li>{this.getMainButtonMenu("DATOS DEL TOMADOR", Steps.DatosTomador)}</li>
                    <li>
                        {this.getMainButtonMenu("DATOS DE LOS ASEGURADOS", Steps.DatosAsegurados)}
                        <ul className="ulSubmenu" style={{ "display": (this.state.showInsuredsOnDatosAsegurados ? "block" : "none") }}>
                            {
                                !this.props.disableSubButtons && this.state.currentStep === Steps.DatosAsegurados &&
                                <li>{this.getMainButtonSubMenu("GUARDAR ASEGURADO", Steps.DatosAsegurados, () => this.tryInsuredOnSave(), "full-light", images.IconSaveWhite)}</li>
                            }
                            {this.renderInsuredCards(Steps.DatosAsegurados, this.state.currentStep === Steps.DatosAsegurados, true)}
                            {
                                !this.props.disableSubButtons &&
                                <li>{this.getMainButtonSubMenu("NUEVO ASEGURADO", Steps.DatosAsegurados, () => this.tryInsuredOnAdd(), "full-light", images.PlusWhite, this.isMenuItemDisabled(Steps.DatosAsegurados) || this.state.insureds.length === 10)}</li>
                            }
                        </ul>
                    </li>
                    <li>{this.getMainButtonMenu("GARANTÍAS", Steps.Garantias)}</li>
                    <li>{this.getMainButtonMenu("DOCUMENTACIÓN REQUERIDA", Steps.DocumentacionRequerida)}</li>
                    <li>{this.getMainButtonMenu("PROTECCIÓN DE DATOS", Steps.ProteccionDatos)}</li>
                    <li>{this.getMainButtonMenu("PRIMA", Steps.Prima)}</li>
                    <li>{this.getMainButtonMenu("MÉTODOS DE PAGO", Steps.MetodosPago)}</li>
                    <li>
                        {this.getMainButtonMenu("DETALLE PREV. CONTRATACIÓN", Steps.DetallePrevioContratacion)}
                        <ul className="ulSubmenu" style={{ "display": (true ? "block" : "none") }}>
                            <li>
                                {
                                    this.state.currentStep === Steps.DetallePrevioContratacion &&
                                    this.getMainButtonSubMenu("DESCARGAR", Steps.DetallePrevioContratacion, () => this.tryDownloadPrecontractualDocument(), "full-light")
                                }
                                {
                                    this.state.currentStep === Steps.DetallePrevioContratacion &&
                                    this.getMainButtonSubMenu("ENVIAR POR EMAIL", Steps.DetallePrevioContratacion, () => this.tyrSendMailPrecontractualDocument(), "full-light")
                                }
                                {
                                    this.state.currentStep === Steps.DetallePrevioContratacion &&
                                    this.getMainButtonSubMenu(
                                        "FIRMA PRECONTRACTUAL",
                                        Steps.DetallePrevioContratacion,
                                        () => this.trySignPrecontractualDocument(),
                                        "full-light",
                                        undefined,
                                        this.props.physicalSignature || this.props.disableSignButton)
                                }
                                {
                                    this.state.currentStep === Steps.DetallePrevioContratacion &&
                                    this.props.physicalSignature &&
                                    this.getMainButtonSubMenu(
                                        "CONTRATAR",
                                        Steps.DetallePrevioContratacion,
                                        () => this.tryResumeOnHiring(),
                                        "full-light",
                                        undefined,
                                        !this.props.isSignedDocument || this.props.isHired)
                                }
                            </li>
                        </ul>
                    </li>
                </ul>
            </DentalHireMenuContainer>
        );
    }
}

export const DentalHireMenu = withPolicy(withRouter(DentalHireMenuLayout))
