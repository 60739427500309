import React, { Dispatch, SetStateAction } from "react";
import { HealthQuestionnaireContainer } from "./health-questionnaire-data-style";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getDayMonthYear } from "../../../../../utils/time";
import { NewHiringContainer } from "../../new-hiring-style";
import InputCounter from "../../../../../components/input-counter/input-counter";
import MainButton from "../../../../../components/main-button";
import { images } from "../../../../../assets";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import HealthService from "../../../../../services/HealthService";
import Title03Text from "../../../../../components/components-text/title-03-text";
import {
  HealthQuestionnairesData,
  HealthQuestionnairyDto,
  PREGUNTA,
  RESPUESTA,
  AnswerModel,
  SelectQuestModel,
  AddictionSubValueModel,
  questionnaireModel,
  SendaResponseQuestionnaire,
} from "./health-questionnaire-dto/health-questionnaire-dto";
import CalendarBox from "../../../../../components/calendar-box/calendar-box";
import DicotomicButton from "../../../../../components/dicotomic-button";
import SelectBox from "../../../../../components/select-box/select-box";
import InputBox from "../../../../../components/input-box/input-box";
import moment from "moment";
import DicotomicCheckbox from "../../../../../components/dicotomic-checkbox/dicotomic-checkbox";
import SEX from "../../../../../constants/sex.json";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { calculateIMC, getFormReviewStatus, getSendaPostBody } from "./health-questionnaire-senda-helper";
import { GestDoc } from "../../../../../services/GestDoc";
import GestDocServices from "../../../../../services/Gest-Doc.Services";
import {
  changeFileName,
  deepGet,
  downloadFile,
  getFileExtension,
} from "../../../../../utils/utility";
import { ERROR_MESSAGES } from "../../../../../constants/errorMessages";
import { NOTA_INFORMATIVA_PARENT_NODE_ID } from "../../../../../services/url";
import { GestDocSearchFileRequestDto } from "../../../../../models/gest-doc-models";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import {
  HealthHireMenuHelper,
  Steps,
} from "../../../../../components/health-hire-menu/health-hire-menu";
import { IUser } from "../../../../../models/user-model";
import { IMediator } from "../../../../../models/mediator-model";
import { SendaResponseBody } from "../../../../../services/HealthService.SendaBaseResponse";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";
import { parseResponses } from "../../../../forms/supplements/new-insured/questionnaire.utils";
import { InfoCard } from "./health-questionnaire-data-style";

interface RouteParams {
  index: string;
}

interface HealthQuestionnaireLayoutProps
  extends RouteComponentProps<RouteParams> {
  setLoading: Function;
  newStep: Function;
  saveHiring: Function;
  saveQuestionnaire: boolean;
  goToDetail?: number;
  policy: any;
  data: any;
  user: IUser;
  mediator: IMediator;
  match: any;
  isReadOnly: boolean;
  cotizacion: string;
  removeSign: Function;
  isSupplementsQuestionaire?: boolean;
  setHasFechaEfecto?: Dispatch<SetStateAction<{ status: boolean; isInitialRender: boolean;}>>;
  onBack?: () => void;
}

interface HealthQuestionnaireLayoutState {
  insuredIndex: number;
  insuredDocNumber: string;
  questionnaireData?: HealthQuestionnairesData;
  questionnaireSelected: questionnaireModel;
  anyError: boolean;
  rendered: any;
  addAddictionsModalActive: boolean;
  activeSelectSubCues: string;
  hasPendingChanges: boolean;
  isLoadingData: boolean;
  modalErrorShow: boolean;
  modalErrorText: string;
  isQuestionnaireUploaded: boolean;
  previousModalShow: boolean;
  nextModalShow: boolean;
  serverError: boolean;
  effectiveDateChangeModal: boolean;
  fechaEfectoRetroactiva: 'N' | 'S';
  errorMessage: string;
  cancelSignModalShow: boolean,
}

class HealthQuestionnaireLayout extends React.Component<
  HealthQuestionnaireLayoutProps,
  HealthQuestionnaireLayoutState
> {
  state: HealthQuestionnaireLayoutState;

  anyError: boolean = false;

  constructor(props: any) {
    super(props);
    if (
      props.data.insureds.length === 0 ||
      props.data.insureds.length < parseInt(props.match.params.index)
    )
      props.history.push(
        `/${
          props.history.location.pathname.indexOf("simulacion") > -1
            ? "simulacion"
            : "contratacion"
        }/nueva`
      );
    this.state = {
      insuredIndex: 0,
      insuredDocNumber: "",
      questionnaireSelected: {
        questionnaireResponse: {},
        questionnaireMultipleResponse: {},
        selectQuest: [],
        allNotChecked: false,
        hasQuestionnaireAuth: false,
      },
      questionnaireData: undefined,
      anyError: false,
      rendered: undefined,
      addAddictionsModalActive: false,
      activeSelectSubCues: "",
      hasPendingChanges: false,
      isLoadingData: false,
      modalErrorShow: false,
      modalErrorText: "",
      isQuestionnaireUploaded: false,
      previousModalShow: false,
      nextModalShow: false,
      serverError: false,
      errorMessage: "",
      cancelSignModalShow: false,
      effectiveDateChangeModal: false,
      fechaEfectoRetroactiva: deepGet(props, 'data.fechaEfectoRetroactiva') || 'N',
    };
  }

  componentWillReceiveProps(nextProps: any) {
    let { saveQuestionnaire } = this.props;
    if (nextProps.saveQuestionnaire) {
      saveQuestionnaire = false;
      this.saveQuestionnaire();
    }
    if (
      nextProps.onGotoDetail !== undefined &&
      nextProps.onGotoDetail !== this.state.insuredIndex
    ) {
      let insuredDocNumber =
        nextProps.data.insureds[nextProps.onGotoDetail].docNumber;
      let questionnaire = JSON.parse(
        JSON.stringify(
          nextProps.data.questionnaires &&
            nextProps.data.questionnaires[insuredDocNumber]
            ? nextProps.data.questionnaires[insuredDocNumber]
            : {
                questionnaireResponse: {},
                questionnaireMultipleResponse: {},
                selectQuest: [],
                allNotChecked: false,
              }
        )
      );

      if (questionnaire.questionnaireData)
        this.setState(
          {
            insuredIndex: nextProps.onGotoDetail,
            insuredDocNumber: insuredDocNumber,
            questionnaireSelected: questionnaire.questionnaireData,
          },
          this.getHealthQuestionnaires
        );
      else
        this.setState(
          {
            insuredIndex: nextProps.onGotoDetail,
            insuredDocNumber: insuredDocNumber,
            questionnaireSelected: questionnaire,
          },
          this.getHealthQuestionnaires
        );
    }
  }

  componentWillMount() {
    let { insuredIndex, questionnaireSelected } = this.state;
    window.addEventListener("scroll", () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${window.scrollY}px`
      );
    });

    insuredIndex =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.index
        ? parseInt(this.props.match.params.index)
        : 0;
    let insuredIdentifier =
      this.props.data.insureds &&
      this.props.data.insureds[this.props.match.params.index]
        ? this.props.data.insureds[this.props.match.params.index].docNumber
        : "";

    if (insuredIdentifier && this.props.data.questionnaires)
      questionnaireSelected = JSON.parse(
        JSON.stringify(
          this.props.data.questionnaires[insuredIdentifier]
            ? this.props.data.questionnaires[insuredIdentifier]
                .questionnaireData
            : questionnaireSelected
        )
      );
    this.setState(
      {
        insuredIndex,
        questionnaireSelected,
        insuredDocNumber: insuredIdentifier,
      },
      this.getHealthQuestionnaires
    );
  }

  async saveQuestionnaire() {
    if (
      this.props.data &&
      this.props.data.signData &&
      this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED
    ) {
      this.setState({ cancelSignModalShow: true });
      return false;
    } else {
      await this.validateAndSave();
    }
  }

  async validateAndSave() {
    if (
      this.props.data &&
      this.props.data.signData &&
      this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED
    )
      this.props.removeSign();

    await this.validateQuestionnaire();

    if (!this.state.anyError) {
      const { questionnaireSelected, insuredDocNumber } = this.state;

      let questionnaire = {
        questionnaire: true,
        insuredDocNumber: insuredDocNumber,
        questionnaireData: questionnaireSelected,
      };

      this.props.policy.setHealthData(questionnaire);
      this.props.saveHiring(true);
      this.state.isLoadingData = true;
    }
  }

  getHealthQuestionnaires = async () => {
    const { setLoading } = this.props;

    if (
      !this.state.questionnaireData ||
      !this.state.questionnaireData.healthQuestList ||
      !this.state.questionnaireData.healthQuestList.ASISASALUD
    ) {
      let respQuest: HealthQuestionnairesData =
        await HealthService.getQuestionnaries(setLoading, true)
          .then((data: any) => {
            return data;
          })
          .catch((error) => {
            this.setState({
              serverError: true,
              errorMessage: `Error al obtener cuestionarios: ${error.data.message}`,
            });
            return;
          });
      this.setState(
        { questionnaireData: respQuest, isLoadingData: true },
        this.getRenderedQuest
      );
    } else {
      this.getRenderedQuest();
    }
  };

  getRenderedQuest() {
    const { questionnaireData } = this.state;
    if (questionnaireData && questionnaireData.healthQuestList) {
      const firstProperty: string = Object.keys(
        questionnaireData.healthQuestList
      )[0];
      if (firstProperty) {
        const rendered = this.renderHealthQuestionnaire(
          questionnaireData.healthQuestList[firstProperty]
        );
        this.setState({ rendered });
      }
    }

    if (this.state.isLoadingData) {
      this.state.hasPendingChanges = false;
      this.state.isLoadingData = false;
    }
  }

  getDefaultAnswerModel = (
    question: PREGUNTA,
    answerId: string,
    parentId: string = "",
    multiple: boolean
  ): AnswerModel => {
    let defValue, subCuesAnswer;

    if (multiple && question.RESPUESTAS_PREGUNTA.RESPUESTA)
      defValue = question.RESPUESTAS_PREGUNTA.RESPUESTA.find(
        (val) => val.IDENTIFICADOR === answerId
      );

    if (question.RESPUESTAS_PREGUNTA.RESPUESTA)
      subCuesAnswer = question.RESPUESTAS_PREGUNTA.RESPUESTA.find(
        (val) => val.SUBCODICUES !== ""
      );

    let defaultAnswer = {
      id: question.CODIPREG,
      answerId: multiple ? answerId : undefined,
      parentId: parentId,
      isEnabled: multiple ? false : parentId !== undefined,
      isRequired: this.isAnswerRequired(question),
      errorCode: "",
      value: "",
      subValue: "",
      checked: multiple ? false : undefined,
      subCues: multiple
        ? defValue && defValue.SUBCODICUES
          ? defValue.SUBCODICUES
          : ""
        : subCuesAnswer && subCuesAnswer.SUBCODICUES
        ? subCuesAnswer.SUBCODICUES
        : undefined,
    };

    let { questionnaireSelected } = this.state;

    if (!multiple) {
      questionnaireSelected.questionnaireResponse = {
        ...questionnaireSelected.questionnaireResponse,
        [question.CODIPREG]: defaultAnswer,
      };
    }

    return defaultAnswer;
  };

  getQuestionnaireResponseState = (
    question: PREGUNTA,
    answerId: string,
    parentId: string
  ): AnswerModel => {
    if (
      this.state.questionnaireSelected.questionnaireResponse[question.CODIPREG]
    )
      return this.state.questionnaireSelected.questionnaireResponse[
        question.CODIPREG
      ];
    else return this.getDefaultAnswerModel(question, answerId, parentId, false);
  };

  setQuestionnaireResponseState(answerModel: AnswerModel) {
    const { questionnaireSelected } = this.state;

    let newResponse = this.state.questionnaireSelected.questionnaireResponse;
    newResponse[answerModel.id] = answerModel;

    questionnaireSelected.questionnaireResponse = newResponse;
    this.setState({ questionnaireSelected }, this.getRenderedQuest);
  }

  getQuestionnaireMultipleResponseState = (
    question: PREGUNTA,
    answerId: string,
    parentId: string = ""
  ): AnswerModel => {
    let defaultAnswer;
    if (
      this.state.questionnaireSelected.questionnaireMultipleResponse[
        question.CODIPREG
      ]
    )
      defaultAnswer =
        this.state.questionnaireSelected.questionnaireMultipleResponse[
          question.CODIPREG
        ].find((val) => val.answerId === answerId);
    if (!defaultAnswer)
      defaultAnswer = this.getDefaultAnswerModel(
        question,
        answerId,
        parentId,
        true
      );
    return defaultAnswer;
  };

  setQuestionnaireMultipleResponseState(
    id: string,
    answerModel: AnswerModel,
    parentId: string = ""
  ) {
    const { questionnaireSelected } = this.state;
    let newResponse = questionnaireSelected.questionnaireMultipleResponse;
    let answerToChange = newResponse[id]
      ? newResponse[id].find((val) => val.answerId === answerModel.answerId)
      : undefined;

    if (answerToChange) {
      answerToChange.value = answerModel.value;
      answerToChange.subValue = answerModel.subValue;
      answerToChange.checked = answerModel.checked;
    } else {
      if (!newResponse[id]) newResponse[id] = [];

      newResponse[id].push(answerModel);
    }

    questionnaireSelected.questionnaireMultipleResponse = newResponse;
    this.setState({ questionnaireSelected }, this.getRenderedQuest);
  }

  setQuestionnaireResponseAllNot(isAllNo: boolean) {
    const { questionnaireSelected } = this.state;

    if (isAllNo) {
      questionnaireSelected.questionnaireMultipleResponse = {};
    }

    for (let key in questionnaireSelected.questionnaireResponse) {
      let value = questionnaireSelected.questionnaireResponse[key];
      value.value = value.id.length > 2 ? (isAllNo ? "n" : "") : value.value;
    }
    questionnaireSelected.allNotChecked = isAllNo;

    this.setState({ questionnaireSelected }, this.getRenderedQuest);
  }

  handleChangeQuestionnaireAuth(value: boolean) {
    const { questionnaireSelected } = this.state;
    this.setState({ questionnaireSelected: { ...questionnaireSelected, hasQuestionnaireAuth: value }});
  }

  onAnswerValueChange(
    question: PREGUNTA,
    answer: RESPUESTA,
    answerModel: AnswerModel,
    multiple: boolean = false,
    subValue: boolean | string | undefined = undefined
  ) {
    // Refresh SelectBoxes from modal if needed
    if (
      (typeof subValue == "undefined" || typeof subValue == "boolean") &&
      (answer.TIPORESP === "CUES" ||
        (answer.TIPORESP === "VALO" && answer.VALUERES !== answerModel.value) ||
        answer.TIPORESP === "VATX" ||
        answer.TIPORESP === "VATA")
    )
      this.restartSelectQuests(answerModel, answer, question);

    answerModel.errorCode =
      (answerModel.isEnabled &&
        answerModel.isRequired &&
        answer.VALUERES === "s" &&
        typeof subValue === "string" &&
        subValue.length > 0) ||
      (multiple && typeof subValue === "string" && subValue.length > 0)
        ? ""
        : "required";
    if (
      answerModel.value !== answer.VALUERES ||
      (subValue &&
        typeof subValue === "string" &&
        answerModel.subValue !== subValue) ||
      (subValue &&
        typeof subValue === "boolean" &&
        answerModel.checked !== subValue)
    )
      this.state.hasPendingChanges = true;
    answerModel.value = answer.VALUERES;

    if (typeof subValue === "string") {
      answerModel.subValue = subValue;
    }

    answerModel.checked =
      typeof subValue === "boolean" ? subValue : answerModel.checked;
    answerModel.isEnabled = multiple
      ? answerModel.checked
        ? true
        : false
      : answerModel.isEnabled;

    for (let key in this.state.questionnaireSelected.questionnaireResponse) {
      let value = this.state.questionnaireSelected.questionnaireResponse[key];
      if (value.parentId == answerModel.id) {
        value.isEnabled = answerModel.value == "s";
      }
    }

    if (multiple) {
      this.setQuestionnaireMultipleResponseState(
        question.CODIPREG,
        answerModel,
        question.IDENTIFICADOR
      );
    } else this.setQuestionnaireResponseState(answerModel);
  }

  onQuestionValueChange(
    question: PREGUNTA,
    answerModel: AnswerModel,
    multiple: boolean = false,
    value: any = undefined
  ) {
    if (answerModel.value !== value) this.state.hasPendingChanges = true;
    answerModel.value = value;
    answerModel.errorCode =
      answerModel.isRequired && value.length === 0 ? "required" : "";

    for (let key in this.state.questionnaireSelected.questionnaireResponse) {
      let value = this.state.questionnaireSelected.questionnaireResponse[key];
      if (value.parentId == answerModel.id) {
        value.isEnabled = answerModel.value == "s";
      }
    }

    if (multiple)
      this.setQuestionnaireMultipleResponseState(
        question.CODIPREG,
        answerModel,
        question.IDENTIFICADOR
      );
    else this.setQuestionnaireResponseState(answerModel);
  }

  isAnswerRequired = (question: PREGUNTA) => question.SWOBLIGA === "S";
  isAnswerMultiple = (question: PREGUNTA) => question.SWRESMUL === "S";

  isQuestionDisabled(
    currentAnswerValue: AnswerModel | undefined,
    answer: RESPUESTA
  ): boolean {
    if (currentAnswerValue) {
      if (
        currentAnswerValue.value === answer.VALUERES ||
        currentAnswerValue.checked === true
      )
        return false;
      else if (
        currentAnswerValue.value === answer.VALUERES &&
        currentAnswerValue.checked === false
      )
        return true;
      else if (currentAnswerValue.value === answer.VALUERES) return false;
    }
    return true;
  }

  isMultipleQuestionDisabled(
    currentAnswerValue: AnswerModel[],
    answer: RESPUESTA
  ): boolean {
    let existingAnswer = currentAnswerValue
      ? currentAnswerValue.find((val) => val.answerId === answer.IDENTIFICADOR)
      : undefined;
    return this.isQuestionDisabled(existingAnswer, answer);
  }

  restartSubQuestionnaire(question: PREGUNTA) {
    for (let key in this.state.questionnaireSelected.questionnaireResponse) {
      let element = this.state.questionnaireSelected.questionnaireResponse[key];
      if (element.parentId === question.CODIPREG) {
        element.checked = undefined;
        element.value = "";
      }
    }
    for (let key in this.state.questionnaireSelected
      .questionnaireMultipleResponse) {
      let element =
        this.state.questionnaireSelected.questionnaireMultipleResponse[key];
      element.forEach((v) => {
        if (v.parentId === question.CODIPREG) {
          v.checked = false;
          v.value = "";
        }
      });
    }
  }

  getSubQuestionnaire(subcodicues: string): HealthQuestionnairyDto | undefined {
    const { questionnaireData } = this.state;
    if (
      questionnaireData &&
      questionnaireData.healthQuestList &&
      questionnaireData.healthQuestList[subcodicues]
    )
      return questionnaireData.healthQuestList[subcodicues];
    else return undefined;
  }

  restartSelectQuests(
    answerModel: AnswerModel,
    respuesta: RESPUESTA,
    pregunta: PREGUNTA
  ) {
    const { questionnaireSelected } = this.state;
    if (pregunta.RESPUESTAS_PREGUNTA.RESPUESTA && respuesta.TIPORESP === "VALO")
      pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.forEach((answerToReset) => {
        if (
          answerToReset.CUESTIONARIO &&
          answerToReset.CUESTIONARIO.PREGUNTAS_CUESTIONARIO.PREGUNTA
        )
          answerToReset.CUESTIONARIO.PREGUNTAS_CUESTIONARIO.PREGUNTA.forEach(
            (question) => {
              if (
                questionnaireSelected.questionnaireMultipleResponse[
                  question.CODIPREG
                ]
              )
                questionnaireSelected.questionnaireMultipleResponse[
                  question.CODIPREG
                ].forEach((multipleAnswer) => {
                  multipleAnswer.checked = false;
                  multipleAnswer.isEnabled = false;
                  if (
                    typeof multipleAnswer.subValue !== "string" &&
                    multipleAnswer.subValue.subvalues
                  ) {
                    Object.keys(multipleAnswer.subValue.subvalues).forEach(
                      (subValueKey) => {
                        delete questionnaireSelected.questionnaireResponse[
                          subValueKey
                        ];
                      }
                    );
                  }
                });
            }
          );
      });
    if (
      typeof answerModel.subValue !== "string" &&
      Object.keys(answerModel.subValue.subvalues).length > 0 &&
      answerModel.answerId === respuesta.IDENTIFICADOR
    ) {
      for (let key in answerModel.subValue.subvalues) {
        if (questionnaireSelected.questionnaireResponse[key])
          delete questionnaireSelected.questionnaireResponse[key];
      }
    } else if (
      typeof answerModel.subValue === "string" &&
      answerModel.subValue.length > 0 &&
      answerModel.parentId === pregunta.CODIPREG
    ) {
      // En este caso debemos borrar el valor
      let id = answerModel.id || answerModel.answerId;
      if (id && questionnaireSelected.questionnaireResponse[id]) {
        questionnaireSelected.questionnaireResponse[id].subValue = "";
        questionnaireSelected.questionnaireResponse[id].value = "";
      } else {
        if (id && pregunta.RESPUESTAS_PREGUNTA.RESPUESTA) {
          pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.forEach((answerToReset) => {
            if (
              id &&
              answerToReset.CUESTIONARIO &&
              answerToReset.CUESTIONARIO.PREGUNTAS_CUESTIONARIO.PREGUNTA
            ) {
              answerToReset.CUESTIONARIO.PREGUNTAS_CUESTIONARIO.PREGUNTA.forEach(
                (question) => {
                  if (
                    id &&
                    questionnaireSelected.questionnaireMultipleResponse[id]
                  ) {
                    questionnaireSelected.questionnaireMultipleResponse[
                      id
                    ].forEach((multipleAnswer) => {
                      if (
                        id &&
                        multipleAnswer.answerId === answerModel.answerId &&
                        typeof answerModel.subValue === "string" &&
                        answerModel.subValue.length > 0
                      ) {
                        multipleAnswer.subValue = "";
                        multipleAnswer.value = "";
                      }
                    });
                  }
                }
              );
            }
          });
        }
      }
    }
  }

  renderDateAnswers(question: PREGUNTA, parentId: string) {
    let answerValue = this.getQuestionnaireResponseState(
      question,
      question.IDENTIFICADOR,
      parentId
    );
    // is birthday Quest ? -> auto assign value
    answerValue.value =
      question.CODIPREG == "04"
        ? this.props.data.insureds[this.state.insuredIndex] &&
          getDayMonthYear(
            this.props.data.insureds[this.state.insuredIndex].birthDate
          ).toString()
        : "";

    return (
      <div className="inputBox isRow marginTop">
        <CalendarBox
          className={"noChangeLabel"}
          key={question.IDENTIFICADOR}
          initialValue={answerValue.value}
          placeholder={question.TITUPREG}
          forbidFutureDates
          maxDate={moment().toISOString()}
          required={true}
          errorCode={answerValue.errorCode}
          disabled={this.props.isReadOnly || question.CODIPREG == "04"}
          onChange={(value: string) =>
            this.onQuestionValueChange(question, answerValue, false, value)
          }
        />
      </div>
    );
  }

  renderNumberAnswers(
    question: PREGUNTA,
    parentId: string,
    onlyNumbers: boolean
  ) {
    // Disabled IMC (07) y PERCENTIL-OMS (08)
    if (question.CODIPREG !== "07" && question.CODIPREG !== "08") {
      let answerValue = this.getQuestionnaireResponseState(
        question,
        question.IDENTIFICADOR,
        parentId
      );
      let unit =
        question.IDENTIFICADOR === "15519"
          ? "kg"
          : question.IDENTIFICADOR === "15520"
          ? "cm"
          : undefined;

      return (
        <InputCounter
          onlyNumbers={onlyNumbers}
          key={question.IDENTIFICADOR}
          onChange={(value: string) =>
            this.onQuestionValueChange(question, answerValue, false, value)
          }
          value={answerValue.value}
          labelText={question.TITUPREG.toUpperCase()}
          disabled={this.props.isReadOnly}
          required={answerValue.isRequired}
          errorCode={answerValue.errorCode}
          unit={unit}
        />
      );
    }
  }

  addSelectQuestOptions(
    question: PREGUNTA,
    codigoCues: string,
    parentId: string
  ): SelectQuestModel {
    let selectBoxes = question.RESPUESTAS_PREGUNTA.RESPUESTA
      ? ([] = question.RESPUESTAS_PREGUNTA.RESPUESTA.map(
          (questResponse: RESPUESTA, index: number) => {
            return {
              label: questResponse.DESCRIPCION,
              value: questResponse.VALUERES,
            };
          }
        ))
      : [{ label: "", value: "" }];
    return {
      id: question.CODIPREG,
      identificador: codigoCues,
      tituPreg: question.TITUPREG,
      selectBoxes:
        question.TITUPREG === "Tipo"
          ? selectBoxes.sort((a, b) => (a.label > b.label ? 1 : -1))
          : selectBoxes,
    };
  }

  renderAnswer(
    pregunta: PREGUNTA,
    respuesta: RESPUESTA,
    isParentMultiple: boolean,
    parentId: string
  ): React.ReactNode[] | JSX.Element[] {
    let question: JSX.Element[] = [];
    let questions: React.ReactNode[] = [];

    const isDisabled = this.state.questionnaireSelected
      .questionnaireMultipleResponse[pregunta.CODIPREG]
      ? this.isMultipleQuestionDisabled(
          this.state.questionnaireSelected.questionnaireMultipleResponse[
            pregunta.CODIPREG
          ],
          respuesta
        )
      : this.isQuestionDisabled(
          this.state.questionnaireSelected.questionnaireResponse[
            pregunta.CODIPREG
          ],
          respuesta
        );
    const hideItemCss = isDisabled ? "hide-item" : "";

    let subAnswerValue = isParentMultiple
      ? this.getQuestionnaireMultipleResponseState(
          pregunta,
          respuesta.IDENTIFICADOR
        )
      : this.getQuestionnaireResponseState(
          pregunta,
          respuesta.IDENTIFICADOR,
          parentId
        );

    switch (respuesta.TIPORESP) {
      case "VATA":
        question.push(
          <InputBox
            key={"sub" + respuesta.IDENTIFICADOR}
            area={true}
            areaRows={4}
            type={"text"}
            value={
              typeof subAnswerValue.subValue === "string"
                ? subAnswerValue.subValue
                : ""
            }
            required={true}
            errorCode={
              subAnswerValue && subAnswerValue.errorCode
                ? subAnswerValue.errorCode
                : ""
            }
            disabled={this.props.isReadOnly || isDisabled}
            className={"question isQuestionnaire " + hideItemCss}
            cleanValue={true}
            onChange={(value: string) =>
              this.onAnswerValueChange(
                pregunta,
                respuesta,
                subAnswerValue,
                isParentMultiple,
                value
              )
            }
            notScroll={true}
          />
        );
        break;

      case "VALO":
        break;

      case "VATX": // Text
      case "TXTL": // Text
        question.push(
          <InputBox
            key={"sub" + respuesta.IDENTIFICADOR}
            type={"text"}
            value={
              typeof subAnswerValue.subValue === "string"
                ? subAnswerValue.subValue
                : ""
            }
            errorCode={
              subAnswerValue && subAnswerValue.errorCode
                ? subAnswerValue.errorCode
                : ""
            }
            required={true}
            disabled={isDisabled}
            className={"question " + hideItemCss}
            cleanValue={true}
            notScroll={true}
            onChange={(value: string) =>
              this.onAnswerValueChange(
                pregunta,
                respuesta,
                subAnswerValue,
                isParentMultiple,
                value
              )
            }
          />
        );

        break;

      case "CUES":
        if (respuesta.CUESTIONARIO)
          questions.push(
            <div className="subquestionnaire">
              <>
                {this.renderHealthQuestionnaire(
                  respuesta.CUESTIONARIO,
                  respuesta.SUBCODICUES
                )}
              </>
            </div>
          );
        else
          questions.push(
            <div className="subquestionnaire">
              <>
                {this.renderSubcuest(
                  pregunta,
                  pregunta.CODIPREG,
                  respuesta.SUBCODICUES
                )}
              </>
            </div>
          );
        break;
    }

    return question.length > 0 ? question : questions;
  }

  setMultipleResponseDefault(pregunta: PREGUNTA) {
    let { questionnaireSelected } = this.state;
    if (
      questionnaireSelected.questionnaireMultipleResponse &&
      !questionnaireSelected.questionnaireMultipleResponse[pregunta.CODIPREG] &&
      pregunta.RESPUESTAS_PREGUNTA.RESPUESTA
    ) {
      questionnaireSelected.questionnaireMultipleResponse[pregunta.CODIPREG] =
        [];
      pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.forEach((respuesta) => {
        questionnaireSelected.questionnaireMultipleResponse[
          pregunta.CODIPREG
        ].push({
          id: pregunta.CODIPREG,
          answerId: respuesta.IDENTIFICADOR,
          parentId: pregunta.CODIPREG,
          isEnabled: false,
          isRequired: this.isAnswerRequired(pregunta),
          errorCode: "",
          value: "",
          subValue: this.getSubvalue(respuesta) as
            | AddictionSubValueModel
            | string,
          checked: false,
          subCues: respuesta.SUBCODICUES,
        });
      });
    }
  }

  getSubvalue(respuesta: RESPUESTA): AddictionSubValueModel | string {
    if (
      respuesta.TIPORESP === "CUES" &&
      respuesta.CUESTIONARIO &&
      respuesta.CUESTIONARIO.PREGUNTAS_CUESTIONARIO.PREGUNTA
    ) {
      let subvalue: AddictionSubValueModel = {
        subCues: respuesta.IDENTIFICADOR,
        subvalues: {},
      };
      respuesta.CUESTIONARIO.PREGUNTAS_CUESTIONARIO.PREGUNTA.forEach(
        (pregunta) => {
          subvalue.subvalues[pregunta.CODIPREG] = [];
          pregunta.RESPUESTAS_PREGUNTA.RESPUESTA &&
            pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.forEach((respuesta) => {
              subvalue.subvalues[pregunta.CODIPREG].push({
                dataType: respuesta.TIPORESP,
                id: respuesta.ID_PREGUNTA,
                label: respuesta.DESCRIPCION,
                value: respuesta.VALUERES,
              });
            });
        }
      );

      return subvalue;
    } else {
      return "";
    }
  }

  renderHealthQuestionnaire(
    questionnaire: HealthQuestionnairyDto | undefined,
    parentId: string = ""
  ): React.ReactNode[] {
    let questionDetail: React.ReactNode[] = [];
    let questions: React.ReactNode[] = [];

    if (
      questionnaire &&
      questionnaire.PREGUNTAS_CUESTIONARIO &&
      questionnaire.PREGUNTAS_CUESTIONARIO.PREGUNTA
    ) {
      questionnaire.PREGUNTAS_CUESTIONARIO.PREGUNTA.forEach(
        (pregunta: PREGUNTA) => {
          if (pregunta.SWRESMUL === "S") {
            this.setMultipleResponseDefault(pregunta);
          }
          switch (pregunta.TIPODATO) {
            case "SUBCUEST":
              //Render subcuestionario
              questions.push(this.renderSubcuest(pregunta, pregunta.CODIPREG));
              break;
            case "SELECT":
              //Render pregunta con SELECT
              questions.push(this.renderSelect(pregunta, pregunta.CODIPREG));
              break;
            case "RADIO":
              //Render pregunta con RADIO
              questions.push(
                pregunta.CODIPREG !== "02" ? (
                  <div className="question">
                    {this.renderRadio(pregunta, pregunta.CODIPREG)}
                  </div>
                ) : (
                  this.renderRadio(pregunta, pregunta.CODIPREG)
                )
              );
              break;
            case "CHECK":
              //Render pregunta con CHECK
              questions.push(this.renderCheck(pregunta, pregunta.CODIPREG));
              break;
            case "DATE":
              //Render pregunta con CHECK
              questions.push(
                this.renderDateAnswers(pregunta, pregunta.CODIPREG)
              );
              break;
            case "DOUBLE":
              questions.push(
                <div className="question isRow">
                  {this.renderNumberAnswers(pregunta, pregunta.CODIPREG, false)}
                </div>
              );
              break;
            case "NUMBER":
              questions.push(
                <div className="question isRow">
                  {this.renderNumberAnswers(pregunta, pregunta.CODIPREG, true)}
                </div>
              );
              break;
          }
        }
      );
    }

    if (questionnaire && questionnaire.TIPOCUES === "DETA")
      questionDetail.push(
        <div className="subquestionnaire">
          <h4>{questionnaire.TITULO}</h4>
          <>{questions}</>
        </div>
      );
    else return questions;

    return questionDetail;
  }

  renderSelect(pregunta: PREGUNTA, parentId: string): React.ReactNode[] {
    let questions: React.ReactNode[] = [];
    let answerValue = this.getQuestionnaireResponseState(
      pregunta,
      pregunta.IDENTIFICADOR,
      parentId
    );
    if (answerValue)
      questions.push(
        <div className="selectBox topMargin10">
          <div className="legal-heirs-table">
            <SelectBox
              key={pregunta.IDENTIFICADOR}
              placeholder={""}
              required={answerValue.isRequired}
              errorCode={answerValue.errorCode}
              optionsText={
                pregunta.RESPUESTAS_PREGUNTA.RESPUESTA
                  ? ([] = pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.map(
                      (questResponse: RESPUESTA, index: number) => {
                        return {
                          label: questResponse.DESCRIPCION,
                          value: questResponse.VALUERES,
                        };
                      }
                    ))
                  : [{ label: "", value: "" }]
              }
              optionKey={"value"}
              optionValue={"label"}
              initialValue={
                answerValue.value ? answerValue.value : "Selecciona"
              }
              defaultValue={""}
              disabled={this.props.isReadOnly}
              onChange={(value: string) =>
                this.onQuestionValueChange(pregunta, answerValue, false, value)
              }
            />
            <label>{pregunta.TITUPREG}</label>
          </div>
        </div>
      );
    return questions;
  }

  renderRadio(pregunta: PREGUNTA, parentId: string): React.ReactNode[] {
    let questions: React.ReactNode[] = [];
    const { insuredIndex, questionnaireSelected } = this.state;
    const { data } = this.props;

    if (pregunta.CODIPREG !== "02")
      questions.push(
        <div className="question-title">
          <h6>{pregunta.TITUPREG}</h6>
        </div>
      );
    if (pregunta.RESPUESTAS_PREGUNTA.RESPUESTA)
      pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.forEach(
        (respuesta: RESPUESTA, index) => {
          let answerResult = this.getQuestionnaireResponseState(
            pregunta,
            respuesta.IDENTIFICADOR,
            parentId
          );

          if (pregunta.CODIPREG === "02" && index === 0) {
            questions.push(
              <div className="personal-data-item isRow">
                <div className="personal-data-item col-25">
                  <DicotomicButton
                    key={respuesta.IDENTIFICADOR}
                    required={true}
                    labelText={pregunta.TITUPREG}
                    options={SEX}
                    disabled={true}
                    initialValue={data.insureds[insuredIndex].gender}
                    onChange={(e: string) =>
                      this.onAnswerValueChange(
                        pregunta,
                        respuesta,
                        answerResult
                      )
                    }
                  />
                </div>
              </div>
            );
          } else if (pregunta.CODIPREG !== "02")
            questions.push(
              <div
                className={
                  this.props.isReadOnly || questionnaireSelected.allNotChecked
                    ? "radioBox disabled"
                    : "radioBox"
                }
              >
                <label key={"l" + respuesta.IDENTIFICADOR}>
                  <input
                    key={respuesta.IDENTIFICADOR}
                    type="radio"
                    name={respuesta.ID_PREGUNTA}
                    value={respuesta.VALUERES}
                    disabled={
                      this.props.isReadOnly ||
                      questionnaireSelected.allNotChecked
                    }
                    checked={
                      answerResult && answerResult.value === respuesta.VALUERES
                    }
                    required={this.isAnswerRequired(pregunta)}
                    onChange={(e) =>
                      this.onAnswerValueChange(
                        pregunta,
                        respuesta,
                        answerResult
                      )
                    }
                  />
                  <span className="recover-checkmark">
                    {" "}
                    {respuesta.DESCRIPCION}
                  </span>
                </label>
              </div>
            );

          if (respuesta.VALUERES === answerResult.value) {
            questions.push(
              this.renderAnswer(pregunta, respuesta, false, pregunta.CODIPREG)
            );
          }
        }
      );

    return questions;
  }

  renderCheck(pregunta: PREGUNTA, parentId: string): React.ReactNode[] {
    const { questionnaireData } = this.state;
    let questions: React.ReactNode[] = [];

    if (
      pregunta.RESPUESTAS_PREGUNTA &&
      pregunta.RESPUESTAS_PREGUNTA.RESPUESTA
    ) {
      pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.forEach((respuesta) => {
        let answerValue = this.getQuestionnaireMultipleResponseState(
          pregunta,
          respuesta.IDENTIFICADOR,
          parentId
        );
        let checkValue = answerValue ? answerValue.checked : false;
        questions.push(
          <div
            className={`switchBox ${this.props.isReadOnly ? "disabled" : ""}`}
          >
            <DicotomicCheckbox
              key={"c" + respuesta.IDENTIFICADOR}
              initChecked={checkValue}
              disabled={this.props.isReadOnly}
              className={"optional"}
              checkboxText={respuesta.DESCRIPCION}
              onChange={(value: boolean) =>
                this.onAnswerValueChange(
                  pregunta,
                  respuesta,
                  answerValue,
                  pregunta.RESPUESTAS_PREGUNTA.RESPUESTA &&
                    pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.length > 1,
                  value
                )
              }
            />
          </div>
        );
        if (
          respuesta.TIPORESP === "CUES" &&
          respuesta.CUESTIONARIO &&
          Object.keys(respuesta.CUESTIONARIO).length > 0 &&
          answerValue.checked
        )
          questions.push(
            this.renderHealthQuestionnaire(respuesta.CUESTIONARIO)
          );
        else if (answerValue.checked)
          questions.push(
            this.renderAnswer(pregunta, respuesta, true, parentId)
          );
      });
    }
    return questions;
  }

  renderSubcuest(
    pregunta: PREGUNTA,
    parentId: string,
    answerId?: string
  ): React.ReactNode[] {
    const { questionnaireData } = this.state;
    let questions: React.ReactNode[] = [];
    if (
      pregunta.RESPUESTAS_PREGUNTA &&
      pregunta.RESPUESTAS_PREGUNTA.RESPUESTA
    ) {
      pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.forEach((respuesta) => {
        if (
          respuesta.SUBCODICUES &&
          questionnaireData &&
          questionnaireData.healthQuestList
        ) {
          if (answerId) {
            if (answerId === respuesta.SUBCODICUES) {
              const subCues =
                questionnaireData.healthQuestList[respuesta.SUBCODICUES];
              if (subCues) {
                questions.push(
                  <div className="subquestionnaire">
                    {this.renderHealthQuestionnaire(subCues, parentId)}
                  </div>
                );
              }
            }
          } else {
            const subCues =
              questionnaireData.healthQuestList[respuesta.SUBCODICUES];
            if (subCues)
              questions.push(
                <div className="subquestionnaire">
                  {this.renderHealthQuestionnaire(subCues, parentId)}
                </div>
              );
          }
        }
      });
    }
    return questions;
  }
  // END Render methods //

  // Validation methods //
  setValidationErrors(): boolean {
    const { questionnaireSelected, insuredDocNumber } = this.state;
    const { data } = this.props;
    let hasError = false;

    // Check for errors on simple response quests
    for (let key in questionnaireSelected.questionnaireResponse) {
      let value = questionnaireSelected.questionnaireResponse[key];
      if (value.id === "02" && !this.props.isSupplementsQuestionaire) {
        const gender = data.insureds.find(
          (insured: any) => insured.docNumber === insuredDocNumber
        ).gender;
        if (value.value !== gender) value.value = gender;
      }
      if (value.isEnabled && value.isRequired) {
        value.errorCode =
          (value.value && value.value != "s") ||
          (value.value &&
            value.value == "s" &&
            (value.subValue || value.subCues))
            ? ""
            : "required";
        if (value.errorCode === "required") hasError = true;
      }
    }
    return hasError;
  }

  setMultipleValidationErrors(): boolean {
    const { questionnaireSelected } = this.state;
    let errorCount = 0;

    // Check for errors on multiple response quests
    for (let key in questionnaireSelected.questionnaireMultipleResponse) {
      let multQuest = questionnaireSelected.questionnaireMultipleResponse[key];

      let anyCheckSelected = false;
      multQuest.forEach((subvalue) => {
        if (subvalue.isRequired && subvalue.isEnabled) {
          anyCheckSelected = true;
          if (typeof subvalue.subValue === "string") {
            if (subvalue.subValue.length === 0) errorCount += 1;
          } else {
            Object.keys(subvalue.subValue.subvalues).forEach((subvalueData) => {
              if (!questionnaireSelected.questionnaireResponse[subvalueData])
                errorCount += 1;
            });
          }
        }
      });
      if (!anyCheckSelected) errorCount += 1;
    }
    return errorCount > 0;
  }

  validateQuestionnaire = async () => {
    let supplementsValidations = false;
    if (this.props.setHasFechaEfecto) {
      this.props.setHasFechaEfecto((prevValue) => {
        if (!prevValue.status) {
          supplementsValidations = true;
        }
        return prevValue.status ? { status: true, isInitialRender: true } : { status: false, isInitialRender: false };
      });
    }
    if (this.setValidationErrors() || this.setMultipleValidationErrors() || supplementsValidations) {
      this.setState(
        {
          anyError: true,
          modalErrorShow: true,
          modalErrorText: "Debe completar todos los campos requeridos.",
        },
        this.getRenderedQuest
      );
    } else {
      this.setState(
        { anyError: false, modalErrorShow: false, modalErrorText: "" },
        this.getRenderedQuest
      );
    }
  };

  validateIMCAndResponse = () => {
    if (this.props.data.questionnaires && this.state.isLoadingData) {
      let data: SendaResponseQuestionnaire = getSendaPostBody(
        deepGet(this.props, "data.questionnaires") || {},
        deepGet(this.state, "questionnaireData.healthQuestList") || {},
        deepGet(this.props, "data.idcotiza") || "",
        deepGet(this.props, "data.insureds") || {}
      );

      const adultMin = deepGet(this.state, 'questionnaireData.ADULT_IMC_MIN', undefined);
      const adultMax = deepGet(this.state, 'questionnaireData.ADULT_IMC_MAX', undefined);
      const seniorMin = deepGet(this.state, 'questionnaireData.SENIOR_IMC_MIN', undefined);
      const seniorMax = deepGet(this.state, 'questionnaireData.SENIOR_IMC_MAX', undefined);

      let extractedDataArray = getFormReviewStatus(data, adultMin, adultMax, seniorMin, seniorMax);

      for (let extractedData of extractedDataArray) {
        if ( extractedData.needsReviewByIMC || extractedData.needsReview) {
          this.setState({ effectiveDateChangeModal: true, fechaEfectoRetroactiva: 'S' });
          return; // Si se encuentra un formulario que necesita revisión, se detiene el proceso
        } else {
          if (this.state.fechaEfectoRetroactiva === 'S') {
            this.setState({ fechaEfectoRetroactiva: 'N' });
          }
        }
      }
    }
    
    this.onNext();
  };

  // END Validation methods //

  // Navigation methods //
  onBack(forcePrevious: boolean = false) {
    if (this.props.onBack) {
      this.props.onBack();
      return;
    }
    if (forcePrevious || !this.state.hasPendingChanges)
      this.props.history.push(
        HealthHireMenuHelper.getPreviousStepNavigationPath(Steps.Cuestionarios)
      );
    else this.setState({ previousModalShow: true }, this.getRenderedQuest);
  }

  async onNext(forceNext: boolean = false) {
    if (
      this.props.data.insureds.length !==
      Object.keys(this.props.data.questionnaires).length
    ) {
      this.setState({
        serverError: true,
        errorMessage: "Debe completar todos los cuestionarios para continuar.",
      });
      return;
    }

    const { policy, data } = this.props;
    const { insuredIndex, questionnaireSelected } = this.state;
    const hasQuestionnaireAuth = !!deepGet(policy, "product.productFields.step7.questionnaireAuth") || false;
    const sameAsTaker = data.insureds && data.insureds[insuredIndex] && data.insureds[insuredIndex].sameAsTaker || false;
    if (hasQuestionnaireAuth && !sameAsTaker && !questionnaireSelected.hasQuestionnaireAuth) {
      this.setState({
        serverError: true,
        errorMessage: "Debe tener autorización para compartir los datos de salud del asegurado."
      });
      return;
    }

    if (
      !this.props.isReadOnly &&
      this.state.questionnaireData &&
      this.state.questionnaireData.healthQuestList &&
      this.props.data.idcotiza &&
      this.state.questionnaireData.healthQuestList.ASISASALUD
    ) {
      let data: SendaResponseQuestionnaire = getSendaPostBody(
        this.props.data.questionnaires,
        this.state.questionnaireData.healthQuestList,
        this.props.data.idcotiza,
        this.props.data.insureds
      );

      const response: SendaResponseBody =
        await HealthService.uploadQuestionnaires(
          this.props.setLoading,
          true,
          data
        )
          .then((data: any) => {
            return data;
          })
          .catch((error) => {
            this.setState({
              serverError: true,
              errorMessage: error,
            });
            return;
          });

      if (response) {
        this.props.data.uploadedQuests = true;
        this.props.data.status = response.RESPUESTA.ESTADO;
        // línea quitada porque era para la modal automática de fecha de efecto
        // this.props.data.fechaEfectoRetroactiva = this.state.fechaEfectoRetroactiva;
        const modalLiteral: any = deepGet(this.state, 'questionnaireData.modalLiteral') || undefined;
        const modalTextObj = modalLiteral && modalLiteral.find((value: {key: string, literal_ES: string}) => value.key === "fechaEfectoRetroactiva-modal1");
        const modalText = modalTextObj ? modalTextObj.literal_ES : '';
        this.props.policy.setHealthData(this.props.data);
        this.props.data.modalLiteral = modalText;
        await this.props.saveHiring(false, true);
        this.props.history.push(
          HealthHireMenuHelper.getNextStepNavigationPath(Steps.Cuestionarios)
        );
      }
    } else {
      if (this.props.isSupplementsQuestionaire) {
        await this.validateQuestionnaire();
        const multipleResponseForm = deepGet(this.state, 'questionnaireSelected.questionnaireMultipleResponse.1801', []);
        const drugsValues = multipleResponseForm && multipleResponseForm.length > 0 ? multipleResponseForm[2] : undefined
        const parsedResponse = parseResponses(deepGet(this.state, 'questionnaireSelected.questionnaireResponse', {}) || {}, {checked: deepGet(drugsValues, 'checked', '') || '', subValue: deepGet(drugsValues, 'subValue', '') || ''});
        if (!this.state.modalErrorShow) {
          this.props.saveHiring(parsedResponse);
        }
      } else {
        this.props.saveHiring();
        this.props.history.push(
          HealthHireMenuHelper.getNextStepNavigationPath(Steps.Cuestionarios)
        );
      }
    }
  }
  // END Navigation methods //

  getQuestionnaireTemplate = async () =>
    this.getAndDownloadFile(
      GestDoc.getPersonHealthQuestionnaireTemplateRequest()
    );

  async getAndDownloadFile(request: GestDocSearchFileRequestDto) {
    let result = await GestDocServices.searchAndGetFirstFile(
      this.props.setLoading,
      true,
      request
    );
    if (result && result.fileData) {
      downloadFile(result.fileData, result.fileName, result.fileType, true);
    } else {
      this.showErrorModal("Se ha producido un error al descargar el archivo.");
    }
  }

  onFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { policy } = this.props;

    this.setState({ isQuestionnaireUploaded: false });

    if (event.target.files && event.target.files.length) {
      let file = event.target.files[0];
      const fileExtension = getFileExtension(file.name);
      const { data, setLoading, user, mediator, cotizacion } = this.props;
      const { insuredDocNumber } = this.state;

      const newFileName = GestDoc.getPersonHealthQuestionnaireNewName(
        this.state.insuredDocNumber,
        fileExtension
      );
      file = changeFileName(file, newFileName);
      let metadata;
      if (Object.keys(policy.product).length !== 0) {
        metadata = GestDoc.getquestionnaireDocumentsRequest(
          false,
          user,
          data.taker,
          data.insureds.map((insured: any) => {
            if (insured.docNumber === insuredDocNumber) return insured;
          }),
          cotizacion,
          policy.product.products_colectivo,
          policy.product.products_internalName,
          policy.product.products_combinacion_comercial,
          mediator.nif
        ).fileInfo;
      }
      const templateInfo = await GestDoc.searchNodeAndGetInfo(
        GestDoc.getPersonHealthQuestionnaireTemplateRequest(),
        this.props.setLoading
      );
      let result;
      if (metadata) {
        result = await GestDoc.uploadDocument(
          NOTA_INFORMATIVA_PARENT_NODE_ID,
          file,
          metadata,
          this.props.setLoading,
          templateInfo
        );
      }

      if (result) {
        this.setState({ isQuestionnaireUploaded: true });
      } else {
        this.showErrorModal(ERROR_MESSAGES["unknow-error"]);
      }

      if (event.target && event.target.value) event.target.value = "";
    }
  };

  showErrorModal = (text: string = "Ha ocurrido un error.") =>
    this.setState({ modalErrorShow: true, modalErrorText: text });
  hideErrorModal = () =>
    this.setState({ modalErrorShow: false, modalErrorText: "" });

  render() {
    const { data, isReadOnly, policy } = this.props;
    const {
      insuredIndex,
      rendered,
      addAddictionsModalActive,
      activeSelectSubCues,
      questionnaireSelected,
      isQuestionnaireUploaded,
      serverError,
      errorMessage,
      cancelSignModalShow,
      effectiveDateChangeModal,
    } = this.state;
    const message = deepGet(policy, "product.productFields.step7.message.tooltip", "");
    const hasQuestionnaireAuth = !!deepGet(policy, "product.productFields.step7.questionnaireAuth") || false;
    const sameAsTaker = data.insureds && data.insureds[insuredIndex] && data.insureds[insuredIndex].sameAsTaker || false;

    const modalLiteral: any = deepGet(this.state, 'questionnaireData.modalLiteral') || undefined;
    const modalTextObj =  modalLiteral && modalLiteral.find((value: {key: string, literal_ES: string}) => value.key === "fechaEfectoRetroactiva-modal1");
    const modalQuestionObj = modalLiteral && modalLiteral.find((value: {key: string, literal_ES: string}) => value.key === 'fechaEfectoRetroactiva-modal2');
    const modalText = modalTextObj ? modalTextObj.literal_ES : '';
    const modalQuestion= modalQuestionObj ? modalQuestionObj.literal_ES : '';

    return (
      <NewHiringContainer>
        <div className="new-hiring-flow-status">
          <div className="new-hiring-flow-status-title">
            <h2>{"DATOS CLÍNICOS DE LOS ASEGURADOS"}</h2>
          </div>
        </div>

        {hasQuestionnaireAuth && !sameAsTaker && (
          <DicotomicCheckbox
            disabled={isReadOnly}
            key={insuredIndex}
            initChecked={questionnaireSelected.hasQuestionnaireAuth}
            className="optional"
            checkboxText="Tiene autorización para compartir los datos de salud del asegurado."  
            onChange={(value: boolean) =>
              this.handleChangeQuestionnaireAuth(value)
            }
          />
        )}

        <HealthQuestionnaireContainer>
          <div className="content-wrapper" ref={""}>
            <div className="questionnaire-special-title">
              <Title03Text>
                <p>
                  {data.insureds.length > 0
                    ? data.insureds[insuredIndex].name +
                      " " +
                      data.insureds[insuredIndex].lastname1 +
                      " " +
                      (data.insureds[insuredIndex].lastname2
                        ? data.insureds[insuredIndex].lastname2
                        : "")
                    : ""}
                </p>
              </Title03Text>
            </div>

            {message && (
              <div className="legal">
                <InfoCard origin={origin}>
                  <div className="icon ">
                    <img src={images.IconInfoBlue} alt="" />
                  </div>
                  <div className="content leftMargin10">
                    <p>{message}</p>
                  </div>
                </InfoCard>
              </div>
            )}
            
            <div
              className={`switchBox ${this.props.isReadOnly ? "disabled" : ""}`}
            >
              <DicotomicCheckbox
                disabled={isReadOnly}
                key={"allNo"}
                initChecked={questionnaireSelected.allNotChecked}
                className={"optional"}
                checkboxText={"Responder todo NO"}
                onChange={(value: boolean) =>
                  this.setQuestionnaireResponseAllNot(value)
                }
              />
            </div>
          </div>

          <div
            className={
              this.props.isReadOnly
                ? "content-wrapper noEvents"
                : "content-wrapper"
            }
            id="mainQuestionnaire"
          >
            {rendered}
          </div>

          <div className="content-wrapper topMargin20" ref={""}>
            <div className="questionnaire-button-container">
              <div className="personal-data-buttons">
                <div className="personal-data-buttons__item">
                  <MainButton
                    withTab={true}
                    icon={true}
                    type={"white"}
                    iconImg={images.IconArrowLeftBlue}
                    text="Atrás"
                    onClick={() => this.onBack()}
                  />
                </div>
                <div className="personal-data-buttons__item">
                  <MainButton
                    text={this.props.isSupplementsQuestionaire ? "Enviar" : "Siguiente"}
                    disabled={/* areQuestionnairesCompleted*/ false}
                    onClick={() => this.onNext()}
                  />
                </div>
              </div>
            </div>
          </div>
        </HealthQuestionnaireContainer>

        <LeftInfoModal
          img={images.WarningError}
          className={"error"}
          mainTitle={"Se ha producido un error"}
          mainText={this.state.modalErrorText}
          buttonText={"Cerrar"}
          active={this.state.modalErrorShow}
          onClickAccept={this.hideErrorModal}
          close={this.hideErrorModal}
          noSecondaryButton
        />

        <LeftInfoModal
          img={images.IconWarningYellow}
          mainTitle={"¿Seguro que desea continuar sin guardar?"}
          mainText={"Perderá todos los cambios introducidos."}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ previousModalShow: false })}
          active={this.state.previousModalShow}
          onClickAccept={() =>
            this.setState({ previousModalShow: false }, () => this.onBack(true))
          }
          className="error"
        />

        <LeftInfoModal
          img={images.IconWarningYellow}
          mainTitle={"¿Seguro que desea continuar sin guardar?"}
          mainText={"Perderá todos los cambios introducidos."}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ nextModalShow: false })}
          active={this.state.nextModalShow}
          onClickAccept={() =>
            this.setState({ nextModalShow: false }, () => this.onNext(true))
          }
          className="error"
        />

        <ErrorModalContainer className={`${serverError && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{errorMessage}</p>
            </div>
            <div className="button-error">
              <MainButton
                text="Aceptar"
                onClick={() => this.setState({ serverError: false })}
              />
            </div>
          </div>
        </ErrorModalContainer>
        <LeftInfoModal
          img={images.IconInfoBlue}
          mainTitle={"Aviso"}
          mainText={modalText || ''}
          buttonText="Continuar sin modificar fecha de efecto"
          closeButtonText="Continuar modificando fecha de efecto"
          close={() => this.setState({ effectiveDateChangeModal: false, fechaEfectoRetroactiva: 'S' }, () => this.onNext())}
          active={this.state.effectiveDateChangeModal}
          onClickAccept={() =>
            this.setState({ effectiveDateChangeModal: false, fechaEfectoRetroactiva: 'N' }, () => this.onNext())}
          className="info"
          hasAcceptNoClose={true}
          secondaryText={modalQuestion || ''}
          buttonFontSizeSmall={true}
        />
        <LeftInfoModal
          img={images.IconWarningYellow}
          className="error"
          mainTitle={"¿Seguro que desea continuar?"}
          mainText={"Se cancelará la firma previamente solicitada"}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ cancelSignModalShow: false })}
          active={cancelSignModalShow}
          onClickAccept={() => {
            this.setState({ cancelSignModalShow: false }, () =>
              this.validateAndSave()
            );
          }}
        />
      </NewHiringContainer>
    );
  }
}

export default withPolicy(withGeneral(withRouter(HealthQuestionnaireLayout)));
