import React from "react";
import GoBackLayout from "./go-back-layout";
import { withRouter } from "react-router";

class GoBackScreen extends React.Component<any, {}> {
  goBack = () => {
    this.props.onclick ? this.props.onclick() : this.props.history.goBack();
  };

  render() {
    const { img, text, onclick } = this.props;
    return <GoBackLayout img={img} text={text} onclick={() => this.goBack()} />;
  }
}

export default withRouter(GoBackScreen);
