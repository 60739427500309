import styled from "styled-components";
import { colors } from "../../../../assets";
import { BodyTextContainer } from "../../../../components/components-text/body-text/body-text-styles";
import { NoteTextContainer } from "../../../../components/components-text/note-text/note-text-styles";

export const HiringDetailPendantContainer = styled.div`
  display: flex;

  .detail-pendant-hiring-main {
    width: 100%;
    padding-right: 40px;
  }

  .detail-pendant-hiring-back {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
  }

  .detail-pendant-hiring-buttons-container {
    align-self: flex-start;
    position: sticky;
    top: 80px;
  }

  .detail-pendant-hiring-buttons-item {
    min-width: 200px;
    margin-bottom: 16px;
    margin-left: 40px;
  }

  .detail-pendant-hiring-special {
    padding: 60px;
    margin-bottom: 48px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
  }

  .detail-pendant-hiring-special-title {
    color: ${colors["PR-001-100"]};
    border-bottom: 1px solid ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 24px;
  }

  .detail-pendant-hiring-special-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .detail-pendant-hiring-special-item {
    display: flex;
  }

  .detail-pendant-hiring-special-subitem {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  .detail-pendant-hiring-special-subitem-title {
    text-transform: uppercase;
    color: ${colors["TX-001-30"]};
    margin-bottom: 6px;
  }

  .detail-pendant-hiring-special-subitem-content {
    color: ${colors["TX-001-100"]};
  }

  .detail-pendant-hiring-item-content-table {
    margin-bottom: 55px;
    min-width: 100%;
    max-width: 450px;
    overflow-x: auto;

    &:last-child {
      margin-bottom: 0;
    }

    thead {
      border-bottom: 1px solid ${colors["PR-001-100"]};
    }

    th {
      padding: 15px 15px;
      min-width: 50px;
      max-width: 100px;
      color: ${colors["TX-001-30"]};
      .highlighted {
        color: ${colors["TX-001-50"]};
      }
    }

    td > div {
      padding: 8px 0;
    }

    td {
      ${BodyTextContainer} {
        display: inline-block;
        &.highlighted {
          font-weight: 600;
        }
      }
    }

    .main-table-row {
      &:first-child {
        td {
          padding-top: 20px;
        }
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  .detail-pendant-hiring-item-content-advise {
    color: ${colors["TX-001-30"]};
  }

  .detail-pendant-hiring-item-content-capital-text {
    margin-bottom: 50px;

    p {
      color: ${colors["TX-001-50"]};
    }

    span {
      color: ${colors["TX-001-100"]};
      margin-left: 8px;
    }
  }

  .detail-pendant-hiring-container {
    margin-bottom: 50px;
  }

  .detail-pendant-hiring-item {
    padding: 60px;
    margin-bottom: 48px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
  }

  .detail-pendant-hiring-item-title {
    color: ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 24px;
  }

  .detail-pendant-hiring-divider {
    border: 0.5px solid ${colors["PR-001-100"]};
    height: 0;
    width: 100%;
    margin-top: 40px;
  }

  .detail-pendant-hiring-footer {
    padding-top: 37px;
    margin-bottom: 130px;
    color: ${colors["PR-001-100"]};
    padding-left: 60px;
    padding-right: 60px;
    ${NoteTextContainer} {
      line-height: 1.5em;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pendant-hiring-pagination {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
    margin-top: 20px;
  }
`;
