import styled from "styled-components";

export const CampaignProgressStyle = styled.div`
  margin: 40px 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  .campaign-progress-line {
    position: absolute;
    width: 100%;
    padding: 0 40px;
    padding-left: 14px;
    top: 10px;
    &__track {
      height: 1px;
      background: #b3cadc;
      width: 100%;
    }
    &::before {
      width: 6px;
      height: 6px;
      background: #6cc9f0;
      border-radius: 100%;
      position: absolute;
      content: "";
      left: 0px;
      top: -2px;
    }
    &::after {
      width: 150px;
      height: 1px;
      background: #6cc9f0;
      position: absolute;
      content: "";
      left: 14px;
      top: 0px;
    }
  }

  .campaign-progress-reward {
    top: 0px;
    z-index: 3;
    position: absolute;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    &:hover {
      .campaign-progress-reward__subtitle {
        opacity: 1;
      }
    }
    &__title {
      color: #004f8b;
      margin-bottom: 4px;
    }
    &__subtitle {
      text-align: center;
      color: #004f8b;
      opacity: 0;
      width: 300px;
      transition: 0.3s all ease-in-out;
    }

    &.active {
      .campaign-progress-reward-dot {
        width: 22px;
        height: 22px;
        border: 4px solid white;
        background: #6cc9f0;
        border-radius: 100%;
        margin-bottom: 4px;
        margin-top: 0px;
        &::before {
          content: "";
          width: 6px;
          height: 6px;
          background: white;
          border-radius: 100%;
          margin-bottom: 4px;
          position: absolute;
          top: 4px;
          left: 4px;
        }
      }
    }

    .campaign-progress-reward-dot {
      width: 14px;
      height: 14px;
      border: 4px solid white;
      background: #004f8b;
      border-radius: 100%;
      margin-bottom: 4px;
      position: relative;
      margin-top: 4px;
    }
  }

  .campaign-progress-status {
    left: 120px;
    top: 0px;
    z-index: 3;
    position: absolute;
    cursor: pointer;

    &:hover {
      .campaign-progress-status__subtitle {
        opacity: 1;
      }
    }
    &__title {
      color: #004f8b;
      margin-bottom: 4px;
      width: 100%;
      text-align: center;
    }
    &__subtitle {
      text-align: center;
      color: #004f8b;
      opacity: 0;
      transition: 0.3s all ease-in-out;
      width: 100%;
      text-align: center;
    }
  }
  .campaign-progress-status-dot {
    width: 22px;
    height: 22px;
    border: 4px solid white;
    background: #6cc9f0;
    border-radius: 100%;
    margin: 0 auto;
    margin-bottom: 4px;
    position: relative;
    text-align: center;
    &::before {
      content: "";
      width: 6px;
      height: 6px;
      background: white;
      border-radius: 100%;
      margin-bottom: 4px;
      position: absolute;
      top: 4px;
      left: 4px;
    }
  }
`;
