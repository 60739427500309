import styled from "styled-components";
import { colors } from "../../../../../../assets"
import { images } from "../../../../../../assets/images";

export const PolicyDataContainer = styled.div`
.custom-file-upload {
    display: flex;

    input[type="text"] {
      border-radius: 3px 0px 0px 3px;
    }
}

.custom-file-upload-button {

  background-color: #e4e4e4;

  > input[type="file"] {
    display:none;

    ~ div {
      background-color: ${colors["PR-001-100"]};
      color: #fff;
      border-radius: 0px 3px 3px 0px;

      &:before {

        content: url(${images.IconSearchWhite});
        position: absolute;
        width: 15px;
        height: 15px;
        left: 15px;
      }
    }

    &:disabled {
      ~ div {
          background-color: #e2e2e2;
          border-radius: 0px 3px 3px 0px;
          color: #ababab;

          &:before {
            content: url(${images.IconSearchGray});
          }
        }
    }        
  }
}

.uploadedFile {
    background-color: #fff;
    height: 45px;
    width: 96%;
    padding-left: 20px;
    line-height: 42px;   
    margin-left: 2%;
    margin-top: 15px;
    float: left;
    color: ${colors["PR-001-100"]};
    font-weight: bold;

    > div {
      width: 50px;
      height: 43px;
      background: #fff;
      float: right;
    }

    .icon-check-block {
      width: 15px;
      height: 15px;
      border-radius: 100%;
      margin-top: 14px;
      margin-right: 25px;
      background: ${colors["SU-001-100"]};     
      float: left;
      text-align: center;
  
      img {
        margin-bottom: 13px;
        width: 81%;
      }
    }
}

.upload-buttons {
    > div {
      position: relative;
      background-color: #fff;
      color: ${colors["PR-001-100"]};
      border: 1px solid ${colors["PR-001-100"]};
      min-width: 50px
      max-width: 45%;
      transition: all 300ms;

      &:hover {
        background-color: ${colors["PR-001-100"]};
        color: #fff;
      }

      &.disabled {
        position: relative;                     
        pointer-events: none;
        cursor: default;
        background-color: #e2e2e2;          
        color: #ababab;  
        border: none;
        
        .main-button-icon {
          display: none;
        }

        &:before {
          content: url(${images.IconUploadGrey});
          width: 18px;
          height: 18px;
          margin-right: 0px;
        }
      }
}}

.personal-data-wrapper {
  overflow: initial;
  padding-right: 16px;
  padding: 20px;
  background-color: #fbfbfb;
  display: block;
  width: 100%;
  float: left;
  margin-top: 40px;
}
@media (min-width: 320px) {
  .personal-data-wrapper {
    width: 55% !important;
  }
}
@media (min-width: 1201px) {
  .personal-data-wrapper {
    width: 100% !important;
  }
}
  .error {
    input {
      border: 1px solid ${colors["ER-001-100"]};
    }
  }

  .input-box-error-file {
    color: ${colors["ER-001-100"]};
    margin-top: -15px;

    p {
      color: ${colors["ER-001-100"]};
    }
  }

  .input-box-error p {
    color: #e40202;
  }
`;