import GeneralServices from "./GeneralServices";
import { GetPaymentLinkDto, GetPaymentStatusDto, PostSendingErrorMailDto } from "./dto/request/PaymentRequests.dto";

class PaymentServices {
  getPaymentLink = async (args: GetPaymentLinkDto) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader,
        endpoint: `redsys/paymentURL?cantidad=${args.cantidad}&moneda=${args.moneda}&pedido=${args.pedido}`,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  getPaymentStatus = async (args: GetPaymentStatusDto) : Promise<any> => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader,
        endpoint: `redsys/payment/status?pedido=${args.pedido}`,
      });
      return response;
    } catch (err) {
      throw err;
    }
  };

  SendPaymentErrorMail = async (args: PostSendingErrorMailDto) : Promise<any> => {
    try {
      const response = await GeneralServices.post({
        setLoading: args.setLoading,
        loader: args.loader,
        endpoint: `redsys/sendErrorMail`,
        data:args.data
      });
      return response;
    } catch (err) {
      throw err;
    }
  };

}

export default new PaymentServices();
