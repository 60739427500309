import React, { Component } from "react";
import LabelText from "../../../../../../components/components-text/label-text";
import InputBox from "../../../../../../components/input-box/input-box";
import PhoneBox from "../../../../../../components/phone-box/phone-box";
import SelectBox from "../../../../../../components/select-box/select-box";
import { COMUNICATION_CHANNEL_OPTIONS, COMUNICATION_LANGUAGE_OPTIONS_BOX, CONTACT_HOUR_OPTIONS_BOX, NO_PAPER_OPTIONS_BOX, ValueFormHealth } from "../../../../../../constants/health";
import { isValidEmail, isValidMobile } from "../../../../../../utils/validation";
import { ContactDataDto, DefaultContactDataDto } from "./contact-form-item-dto";
import { HealthContactFormContainer } from "./contact-form-item-style";
import HocelotServices from "../../../../../../services/HocelotServices";

export interface ContactData {
    mobilePhone: string,
    landlinePhone: string,
    email: string,
    comunicationChannel: string,
    paperless: string,
    contactHour: string,
    comunicationLanguage: string,
    sameAsTaker: boolean,
}

interface HealthContactFormProps {
    validate: {
        validateRequest: boolean;
        isValidated: boolean;
    };
    onValidated: (person: ContactData) => void;
    onCleanForm?: boolean;
    onEditInsured?: number;
    insured?: boolean;
    taker?: ContactData;
    insureds?: ContactData[];
    insuredIndex: number;
    isReadOnly?: boolean;
    onAnyChange: Function;
    useTakerData?: any;
    isPersonalCheckChanging?: boolean;
}

interface HealthContactFormState {
    contactDto: ContactDataDto;
    previousData: ContactDataDto;
}

class HealthContactFormItem extends Component<HealthContactFormProps, HealthContactFormState> {

    constructor(props: HealthContactFormProps) {
        super(props);
        this.state = {
            contactDto: DefaultContactDataDto(),
            previousData: DefaultContactDataDto(),
        }
    }

    componentWillReceiveProps(nextProps: HealthContactFormProps) {
        let { validate } = this.props;
        if (nextProps.useTakerData && !nextProps.onCleanForm) {
            if (!this.state.contactDto.sameAsTaker) {
                let newContactData = this.state.contactDto;
                newContactData.sameAsTaker = !newContactData.sameAsTaker;
                this.storePreviousData();
                this.completeDataFromTaker();
                this.setState({ contactDto: newContactData });
            }
        }
        else if (!nextProps.useTakerData && !nextProps.onCleanForm) {
            if (nextProps.useTakerData !== undefined && this.state.contactDto.sameAsTaker !== nextProps.useTakerData && nextProps.onEditInsured) {
                let newContactData = this.state.contactDto;
                newContactData.sameAsTaker = false;
                this.completeInsuredData(nextProps.onEditInsured);
                this.setState({ contactDto: newContactData });
            }
        }
        if (nextProps.validate.validateRequest) {
            validate.validateRequest = false;
            validate.isValidated = this.validateForm();
        }
        else if (nextProps.onCleanForm) {
            this.cleanForm();
        }
        if (nextProps.onEditInsured !== undefined && nextProps.onEditInsured !== -1 && !nextProps.useTakerData) {
            this.completeInsuredData(nextProps.onEditInsured);
        }
        if (nextProps.isPersonalCheckChanging && !nextProps.useTakerData) {
            this.getPreviousData();
        }
    }

    getPreviousData() {
        let { previousData, contactDto } = this.state;

        contactDto.comunicationChannel = previousData.comunicationChannel;
        contactDto.comunicationLanguage = previousData.comunicationLanguage;
        contactDto.contactHour = previousData.contactHour;
        contactDto.emailInput = previousData.emailInput;
        contactDto.landlinePhone = previousData.landlinePhone;
        contactDto.mobilePhone = previousData.mobilePhone;
        contactDto.paperlessBox = previousData.paperlessBox;
        contactDto.sameAsTaker = false;

        previousData = DefaultContactDataDto();
        this.setState({ previousData, contactDto });
    }

    completeDataFromTaker() {
        const { taker, insured } = this.props;
        if (taker && Object.keys(taker).length > 0) {
            taker.sameAsTaker = true;
            this.setContactData(taker, insured === true)
        }
    }

    setContactData(newData: ContactData, disabled: boolean) {
        let { contactDto } = this.state;
        contactDto.comunicationChannel = this.getValueFormHealthContact(newData.comunicationChannel, disabled);
        contactDto.comunicationLanguage = this.getValueFormHealthContact(newData.comunicationLanguage, disabled);
        contactDto.contactHour = this.getValueFormHealthContact(newData.contactHour, disabled);
        contactDto.emailInput = this.getValueFormHealthContact(newData.email, disabled);
        contactDto.landlinePhone = this.getValueFormHealthContact(newData.landlinePhone, disabled);
        contactDto.mobilePhone = this.getValueFormHealthContact(newData.mobilePhone, disabled);
        contactDto.paperlessBox = this.getValueFormHealthContact(newData.paperless, disabled);

        contactDto.sameAsTaker = newData.sameAsTaker;

        this.setState({ contactDto });
    }

    getValueFormHealthContact(value: string | ValueFormHealth | undefined, disabled: boolean): ValueFormHealth {
        if (!value || typeof value === 'string') {
            return {
                value: value ? value : '',
                errorCode: '',
                disabled: disabled
            };
        } else {
            return {
                value: value.value,
                errorCode: '',
                disabled: disabled,
            };
        }

    }

    componentDidMount() {
        if (this.props.taker !== undefined && this.props.taker.mobilePhone !== undefined && !this.props.insured) {
            this.completeFormTakerData();
        }
        else if (this.props.taker !== undefined && this.props.taker.email !== undefined && !this.props.insured) {
            let { contactDto } = this.state;

            contactDto.emailInput.value = this.props.taker.email;

            this.setState({ contactDto });
        }
    }

    storePreviousData() {
        const { contactDto } = this.state;
        const previousData: ContactDataDto = JSON.parse(JSON.stringify(contactDto));

        previousData.comunicationChannel.errorCode = "";
        previousData.comunicationLanguage.errorCode = "";
        previousData.contactHour.errorCode = "";
        previousData.emailInput.errorCode = "";
        previousData.landlinePhone.errorCode = "";
        previousData.mobilePhone.errorCode = "";
        previousData.paperlessBox.errorCode = "";

        this.setState({ previousData })
    }

    completeInsuredData(index: number) {
        let { contactDto } = this.state;
        const { insureds } = this.props;

        if (insureds && insureds[index] !== undefined) {

            contactDto.comunicationChannel.value = insureds[index].comunicationChannel !== undefined ? insureds[index].comunicationChannel : COMUNICATION_CHANNEL_OPTIONS.TELEFONICO;
            contactDto.comunicationLanguage.value = insureds[index].comunicationLanguage !== undefined ? insureds[index].comunicationLanguage : "";
            contactDto.contactHour.value = insureds[index].contactHour !== undefined ? insureds[index].contactHour : "";
            contactDto.emailInput.value = insureds[index].email !== undefined ? insureds[index].email : "";
            contactDto.landlinePhone.value = insureds[index].landlinePhone !== undefined ? insureds[index].landlinePhone : "";
            contactDto.mobilePhone.value = insureds[index].mobilePhone !== undefined ? insureds[index].mobilePhone : '';
            contactDto.paperlessBox.value = insureds[index].paperless !== undefined ? insureds[index].paperless : 'No';

            contactDto.emailInput.disabled = insureds[index].sameAsTaker;
            contactDto.landlinePhone.disabled = insureds[index].sameAsTaker;
            contactDto.mobilePhone.disabled = insureds[index].sameAsTaker;

            contactDto.emailInput.errorCode = '';
            contactDto.landlinePhone.errorCode = '';
            contactDto.mobilePhone.errorCode = '';

            contactDto.comunicationChannel.disabled = contactDto.sameAsTaker;
            contactDto.comunicationLanguage.disabled = contactDto.sameAsTaker;
            contactDto.contactHour.disabled = contactDto.sameAsTaker;
            contactDto.emailInput.disabled = contactDto.sameAsTaker;
            contactDto.landlinePhone.disabled = contactDto.sameAsTaker;
            contactDto.mobilePhone.disabled = contactDto.sameAsTaker;
            contactDto.paperlessBox.disabled = contactDto.sameAsTaker;

            this.setState({ contactDto });
        }
    }

    completeFormTakerData() {
        let { contactDto } = this.state;
        const { taker } = this.props;

        if (taker) {

            contactDto.comunicationChannel.value = taker.comunicationChannel;
            contactDto.comunicationLanguage.value = taker.comunicationLanguage !== undefined ? taker.comunicationLanguage : '';
            contactDto.contactHour.value = taker.contactHour;
            contactDto.emailInput.value = taker.email;
            contactDto.landlinePhone.value = taker.landlinePhone;
            contactDto.mobilePhone.value = taker.mobilePhone;
            contactDto.paperlessBox.value = taker.paperless;
        }
        this.setState({ contactDto });
    }

    handleFormChange(value: string, form: string): void {
        let { contactDto } = this.state;
        this.props.onAnyChange();

        if (form === 'CORREO') {
            contactDto.emailInput.value = value;
            if (contactDto.emailInput.errorCode) {
                if (contactDto.emailInput.value.length === 0) {
                    contactDto.emailInput.errorCode = ''
                }
                else if (isValidEmail(contactDto.emailInput.value)) {
                    contactDto.emailInput.errorCode = '';
                }
            }
        }
        this.setState({ contactDto })
    }

    async validateEmail(value: string) {

        let { contactDto } = this.state;
        if (!this.props.insured) {
            try {
                const result = await HocelotServices.verifyEmail({ data: { email: value.trim() }, setLoading: () => { } });
                contactDto.emailInput.errorCode = result.isVerified ? '' : 'invalid-email';
            } catch (error) {
                contactDto.emailInput.errorCode = error && error.status === 422 ? 'invalid-email' : 'hocelot-verify-error';
            }
            this.setState({ contactDto })
        }
    }

    handleSelectChange(value: string, type: string) {
        let { contactDto } = this.state;
        this.props.onAnyChange();
        switch (type) {
            case 'PAPERLESS':
                contactDto.paperlessBox.value = value;
                break;

            case 'CONTACT_HOUR':
                contactDto.contactHour.value = value;
                break;

            case 'COMUNICATION_LANGUAGE':
                contactDto.comunicationLanguage.value = value;
                contactDto.comunicationLanguage.errorCode = '';
                break;
            default:
                break;
        }

        this.setState({ contactDto });
    }

    handlePhoneValueChange(value: string, type: string) {
        let { contactDto } = this.state;
        this.props.onAnyChange();
        if (type === 'FIJO') {
            contactDto.landlinePhone.value = value;
        }
        else {
            if (contactDto.mobilePhone.value.replace('+34 ', '').length === 0) {
                contactDto.mobilePhone.errorCode = '';
            }
            contactDto.mobilePhone.value = value;
        }
        this.setState({ contactDto });
    }

    handleRadioChange(radio: string) {
        let { contactDto } = this.state;
        if (radio === COMUNICATION_CHANNEL_OPTIONS.CARTA_POSTAL) {
            contactDto.paperlessBox.value = 'Si';
        }
        else {
            contactDto.paperlessBox.value = 'No';
        }
        contactDto.comunicationChannel.value = radio;
        this.setState({ contactDto })
    }

    validateForm(): boolean {
        let { contactDto } = this.state;
        let noErrors = true;

        //Validación mail
        if (contactDto.emailInput.value.length > 0 && contactDto.emailInput.errorCode !== "") {
            noErrors = false;
        }
        else if (contactDto.emailInput.value.length === 0) {
            contactDto.emailInput.errorCode = 'required';
            noErrors = false;
        }
        else {
            contactDto.emailInput.errorCode = '';
        }

        //Validación teléfono movil
        const mobileFields = contactDto.mobilePhone.value.split(' ');
        const mobilePrefix = contactDto.mobilePhone.value.split(' ')[0];
        if (mobileFields.length < 2) {
            contactDto.mobilePhone.errorCode = 'required';
            noErrors = false;
        }
        else if (mobilePrefix === '+34' && !isValidMobile(contactDto.mobilePhone.value)) {
            contactDto.mobilePhone.errorCode = 'invalid-phone-number';
            noErrors = false;
        } else {
            contactDto.mobilePhone.errorCode = '';
        }

        if (noErrors) {

            this.props.onValidated(
                {
                    mobilePhone: contactDto.mobilePhone.value,
                    landlinePhone: contactDto.landlinePhone.value,
                    email: contactDto.emailInput.value,
                    comunicationChannel: contactDto.comunicationChannel.value,
                    paperless: contactDto.paperlessBox.value,
                    comunicationLanguage: contactDto.comunicationLanguage.value,
                    contactHour: contactDto.comunicationLanguage.value,
                    sameAsTaker: contactDto.sameAsTaker,
                }
            )
        }

        this.setState({ contactDto });
        return noErrors;
    }

    cleanForm() {
        this.setState({ contactDto: DefaultContactDataDto() });
    }

    render() {
        const { contactDto } = this.state;
        const { isReadOnly } = this.props;

        return (
            <HealthContactFormContainer>
                <div className="content-wrapper bottomMargin20" ref={''}>
                    <div className="personal-data-wrapper" id="data-contact">
                        <div className="data-title topMargin20">
                            <h5>DATOS CONTACTO</h5>
                        </div>
                        <div className="colSin-100">

                            <div className="colSin-50 rightPadding20 topMargin20 bottomMargin20">
                                <div className="inputBox">
                                    <PhoneBox
                                        errorCode={contactDto.mobilePhone.errorCode}
                                        onChange={(value: string) => this.handlePhoneValueChange(value, 'MOVIL')}
                                        labelText=''
                                        required={true}
                                        initialValue={contactDto.mobilePhone.value}
                                        disabled={contactDto.mobilePhone.disabled || isReadOnly}
                                    />
                                    <label>teléfono móvil</label>
                                </div>
                            </div>
                            <div className="colSin-50 rightPadding20 topMargin20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        errorCode={contactDto.landlinePhone.errorCode}
                                        onChange={(value: string) => this.handlePhoneValueChange(value, 'FIJO')}
                                        placeholder="TELÉFONO FIJO"
                                        type={"number"}
                                        value={contactDto.landlinePhone.value}
                                        maxLength={12}
                                        disabled={contactDto.landlinePhone.disabled || isReadOnly}
                                    />
                                </div>
                            </div>

                            <div className="colSin-100 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        errorCode={contactDto.emailInput.errorCode}
                                        onChange={(value: string) => this.handleFormChange(value, "CORREO")}
                                        onBlur={((value: string) => this.validateEmail(value))}
                                        placeholder="CORREO ELECTRÓNICO"
                                        required={true}
                                        type={"email"}
                                        value={contactDto.emailInput.value}
                                        disabled={contactDto.emailInput.disabled || isReadOnly}
                                    />
                                </div>
                            </div>

                            {false && (
                                <div>
                                    <div className="colSin-50 rightPadding20 bottomMargin20">
                                        <div className={`input-box-topbar-label required`}>
                                            <LabelText>
                                                <p>CANAL DE COMUNICACIÓN</p>
                                            </LabelText>
                                        </div>
                                        <div className="contact-radio-main-row">
                                            <div className="radioBox">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        disabled={contactDto.comunicationChannel.disabled || isReadOnly}
                                                        name={COMUNICATION_CHANNEL_OPTIONS.TELEFONICO}
                                                        value={COMUNICATION_CHANNEL_OPTIONS.TELEFONICO}
                                                        onChange={() => this.handleRadioChange(COMUNICATION_CHANNEL_OPTIONS.TELEFONICO)}
                                                        checked={contactDto.comunicationChannel.value === COMUNICATION_CHANNEL_OPTIONS.TELEFONICO ? true : false}
                                                    />
                                                    <span>TELEFÓNICO</span>
                                                </label>
                                            </div>
                                            <div className="radioBox">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        disabled={contactDto.comunicationChannel.disabled || isReadOnly}
                                                        name={COMUNICATION_CHANNEL_OPTIONS.EMAIL}
                                                        value={COMUNICATION_CHANNEL_OPTIONS.EMAIL}
                                                        onChange={() => this.handleRadioChange(COMUNICATION_CHANNEL_OPTIONS.EMAIL)}
                                                        checked={contactDto.comunicationChannel.value === COMUNICATION_CHANNEL_OPTIONS.EMAIL ? true : false}
                                                    />
                                                    <span>EMAIL</span>
                                                </label>
                                            </div>
                                            <div className="radioBox">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        disabled={contactDto.comunicationChannel.disabled || isReadOnly}
                                                        name={COMUNICATION_CHANNEL_OPTIONS.SMS}
                                                        value={COMUNICATION_CHANNEL_OPTIONS.SMS}
                                                        onChange={() => this.handleRadioChange(COMUNICATION_CHANNEL_OPTIONS.SMS)}
                                                        checked={contactDto.comunicationChannel.value === COMUNICATION_CHANNEL_OPTIONS.SMS ? true : false}
                                                    />
                                                    <span className="recover-checkmark"> SMS</span>
                                                </label>
                                            </div>
                                            <div className="radioBox">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        disabled={contactDto.comunicationChannel.disabled || isReadOnly}
                                                        name={COMUNICATION_CHANNEL_OPTIONS.CARTA_POSTAL}
                                                        value={COMUNICATION_CHANNEL_OPTIONS.CARTA_POSTAL}
                                                        onChange={() => this.handleRadioChange(COMUNICATION_CHANNEL_OPTIONS.CARTA_POSTAL)}
                                                        checked={contactDto.comunicationChannel.value === COMUNICATION_CHANNEL_OPTIONS.CARTA_POSTAL ? true : false}
                                                    />
                                                    <span className="recover-checkmark"> Carta postal</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="colSin-50 rightPadding20 bottomMargin20">
                                        <div className="selectBox">
                                            <SelectBox
                                                optionsText={NO_PAPER_OPTIONS_BOX}
                                                optionKey={"value"}
                                                optionValue={"label"}
                                                defaultValue={contactDto.paperlessBox.value}
                                                onChange={(value: string) => this.handleSelectChange(value, 'PAPERLESS')}
                                                propValue={contactDto.paperlessBox.value}
                                                required={true}
                                                disabled={contactDto.paperlessBox.disabled || isReadOnly}
                                            />
                                            <label>SIN PAPEL</label>
                                        </div>
                                    </div>

                                    <div className="colSin-50 rightPadding20 bottomMargin20">
                                        <div className="selectBox">
                                            <SelectBox
                                                optionsText={CONTACT_HOUR_OPTIONS_BOX}
                                                optionKey={"value"}
                                                optionValue={"label"}
                                                defaultValue={contactDto.contactHour.value}
                                                onChange={(value: string) => this.handleSelectChange(value, 'CONTACT_HOUR')}
                                                propValue={contactDto.contactHour.value}
                                                disabled={contactDto.contactHour.disabled || isReadOnly}
                                            />
                                            <label>HORARIO DE CONTACTO</label>
                                        </div>
                                    </div>
                                    <div className="colSin-50 rightPadding20 bottomMargin20">
                                        <div className="selectBox">
                                            <SelectBox
                                                optionsText={COMUNICATION_LANGUAGE_OPTIONS_BOX.sort((a, b) => {
                                                    return String(a.label).localeCompare(b.label);
                                                })}
                                                optionKey={"value"}
                                                optionValue={"label"}
                                                defaultValue={contactDto.comunicationLanguage.value}
                                                onChange={(value: string) => this.handleSelectChange(value, 'COMUNICATION_LANGUAGE')}
                                                propValue={contactDto.comunicationLanguage.value}
                                                errorCode={contactDto.comunicationLanguage.errorCode}
                                                required={true}
                                                disabled={contactDto.comunicationLanguage.disabled || isReadOnly}
                                            />
                                            <label>LENGUA COMUNICACIÓN</label>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </HealthContactFormContainer >
        )
    }
}
export default HealthContactFormItem;
