import React, { FC } from "react";
import { CheckboxWrapperProps } from "./checkbox-wrapper.types";
import { PersonalInformationContainer } from "../../../../hirings/new-hiring/health/forms/personal-information/personal-information-style";
import DicotomicCheckbox from "../../../../../components/dicotomic-checkbox/dicotomic-checkbox";

const CheckboxWrapper: FC<CheckboxWrapperProps> = ({
  label,
  isChecked,
  onCheckboxChange,
  children,
  step,
  name
}) => {
  return (
    <PersonalInformationContainer>
      <div className="personal-data-row">
        <DicotomicCheckbox
          className={"optional"}
          checkboxText={label}
          onChange={(value: boolean) => onCheckboxChange(name, value, step)}
          initChecked={isChecked}
        />
      </div>
      {isChecked && children}
    </PersonalInformationContainer>
  );
};

export default CheckboxWrapper;
