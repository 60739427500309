import styled from "styled-components";
import { MainButtonContainer } from "../main-button/main-button-style";
import { colors } from "../../assets/colors";

export const DicotomicButtonContainer = styled.div`
  min-width: fit-content;
  filter: ${props => (props.disabled ? "grayscale(1)" : "none")};
  pointer-events: ${props => (props.disabled ? "none" : "all")};

  .dicotomic-button-label {
    color: ${colors["primaryColor"]};
    margin-bottom: 10px;
    position: relative;

    p {
      font-size: 16px;
    }

    &.required {
      padding: 0 0 0 15px;
    }
  }  

  .dicotomic-container {
    display: flex;
  }

  .dicotomic-item {
    width: 50%;
    font-size: 15px
  }

  .dicotomic-item {
    position: relative;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    &.active {
      ${MainButtonContainer} {
        background-color: ${colors["SU-001-100"]};
        color: #ffffff;
      }
    }
  }

  ${MainButtonContainer} {
    background: #f4f7fa;
    color: ${colors["PR-001-100"]};
    padding: 0 16px;
    min-width: 100px;
    font-size: 12px;
  }

  .input-box-error {
    color: ${colors["ER-001-100"]};
    padding-top: 3px;
  }
`;
