import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import HeadText from "../../../../../components/components-text/head-text";
import NoteText from "../../../../../components/components-text/note-text";
import Title03Text from "../../../../../components/components-text/title-03-text";
import WebText from "../../../../../components/components-text/web-text";
import MainTable from "../../../../../components/main-table";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { formatter } from "../../../../../utils/utility";
import { IMediator } from "../../../../../models/mediator-model";
import { IUser } from "../../../../../models/user-model";
import { TravelResultDataContainer } from "./travel-result-data-style";
import { capitalizeFirstLetter, currencyFormat } from "../../../../../utils";
import PayTypesServices from "../../../../../services/PayTypesServices";
import { withPayTypes } from "../../../../../context/mixin/with-pay-types";
import { withDynamicProducts } from "../../../../../context/mixin/with-dynamic-products";
import { SPANISH_COUNTRY_OPTION } from "../../../../../constants/optionsTravelPolicy";
interface TravelResultProps extends RouteComponentProps {
  user: IUser;
  mediator: IMediator;
  data: any;
  resumeTravelHiring: boolean;
  setLoading: any;
  coverages?: any;
  hasInformativeNote?: boolean;
  history: any;
  is403?: boolean;
  is404?: boolean;
  isCampaignConfigration?: boolean;
  isLogged?: boolean;
  loading?: boolean;
  location: any;
  match: any;
  policy?: any;
  setHasInformativeNote?: Function;
  saveHiring: Function;
  prices: any;
  setProductsData: () => void;
  sendSign: () => void;
  allPayTypes: any;
  travelCountries: any;
}

interface IDocumentsResult {
  //TODO: Esta columna hay que quitarla hasta saber si viene de algún sitio o se quita
  /* catalog: {
    text: string,
    type: string,
    link: string,
    key: string,
  }; */
  document: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  date: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  action: {
    text: string;
    type: string;
    key: string;
    img: any;
    onClick: Function;
  }
}

interface TravelDocument {
  id: string,
  name: string,
  date: Date,
  type?: string,
  associatedTo: string
}

export interface IProtectionData {
  consentDate: string;
  dataTransfer: string;
  thirdParty: string;
  thirdPartyCalls: string;
}

class TravelResultDataLayout extends React.Component<TravelResultProps, {}> {
  private hoveredElement: any;

  constructor(props: TravelResultProps) {
    super(props);
  }

  componentDidMount = async () => {

    try {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 100);
      await this.props.setProductsData();
      await this.props.saveHiring();
    } catch (error) {
      console.error(error)
    }
  };
  getGuaranteeTableColumns = () => [
    {
      text: "Coberturas incluidas en la póliza",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
  ];

  getGuaranteeTableRowsTravel = () => {
    let { data } = this.props
    let orderedCoverages: any[] = data.taker.policy.coverages.sort((a: any, b: any) => {
      if (a.code > b.code)
        return 1
      else
        return -1
    });
    let coverages: any[] = []

    orderedCoverages.map((coverage: any) => {
      coverages.push({
        name: coverage.description,
        capital: 1
      })
    })

    return coverages.map((c: any) => ({
      coverages: {
        text: c.name ? capitalizeFirstLetter(c.name.toLowerCase()) : "",
        type: "",
        link: "",
        key: "entire-mini-body",
      },
      capital: {
        text: c.capital === 1 || c.capital === formatter.format(1.00) ? "Incluido" : currencyFormat(c.capital) + " €",
        type: "",
        link: "",
        key: "highlighted",
      },
    }));
  }

  getPaymentTableRows = () => {
    const { allPayTypes } = this.props 
    const payments: any[] = this.props.prices && this.props.prices.payments ? this.props.prices.payments : []

    const paymentTableRows = payments.map((payment: any, index: number) => ({
      periodicity: {
        text: PayTypesServices.getPayTypeByCode(allPayTypes, payment.payType).description,
        order: PayTypesServices.getPayTypeByCode(allPayTypes, payment.payType).payTypeOrder,
        type: "",
        link: "",
        key: "highlighted",
      },
      netPremium: {
        text: `${currencyFormat(payment.netPremium)} €`,
        type: "",
        link: "",
        key: "body",
      },
      taxs: {
        text: `${currencyFormat(payment.taxs)} €`,
        type: "",
        link: "",
        key: "body",
      },
      total: {
        text: `${currencyFormat(payment.totalPremium)} €`,
        type: "",
        link: "",
        key: "body",
      },
    }));

    paymentTableRows.sort(
      (a: any, b: any) => a.periodicity.order - b.periodicity.order
    );

    return paymentTableRows;
  };
  getPaymentTableColumns = () => [
    {
      text: "Periodicidad",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Total prima neta",
      filter: false,
      ascendent: true,
      key: "",
    },
    {
      text: "Impuestos y consorcio",
      filter: false,
      ascendent: false,
      key: "",
    },
    {
      text: "Total recibo",
      filter: false,
      ascendent: true,
      key: "",
    },
  ];

  render() {
    const { data, prices, policy, travelCountries } = this.props

    return (
      <TravelResultDataContainer>
        <div className="data-flow-status-title">
          <h2>DETALLE PREVIO DE LA CONTRATACIÓN</h2>
        </div>

        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <h3>{'DATOS DEL SEGURO'}</h3>
          </div>
        </div>

        <div className="result-simulation-special">
          <div className="result-simulation-special-title">
            <Title03Text>
              <p>Nº {prices.requestNumber}</p>
            </Title03Text>
            <div>
              <p className="price">
                {formatter.format(Number(prices && prices.payments && prices.payments[0].totalPremium))}
              </p>
            </div>
          </div>
          <div className="result-simulation-special-container">
            <div className="result-simulation-special-item">
              <HeadText>
                <p>
                  {policy.product.products_name ? policy.product.products_name : ''}
                </p>
              </HeadText>
            </div>
            <div className="result-simulation-special-item">
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>FECHA EMISIÓN</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>
                      {prices.starterDay && moment(prices.starterDay).format('DD/MM/YYYY')}
                    </p>
                  </WebText>
                </div>
              </div>
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>DELEGACIÓN</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>
                      {data.mediatorUser.delegation}
                    </p>
                  </WebText>
                </div>
              </div>
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>MEDIADOR</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>
                      {data.mediatorUser.code}
                    </p>
                  </WebText>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="result-simulation-item float">
          <div className="result-simulation-special-title">
            <Title03Text>
              <p>Cliente</p>
            </Title03Text>
          </div>
          <div className="result-simulation-special">
            <div className="result-hiring-special-container col-100">
              <div className="result-hiring-special-first-item col-30">
                <HeadText>
                  <p>Datos del Viaje</p>
                </HeadText>
              </div>
              <div className="result-hiring-special-item col-70">
                <div className="result-hiring-special-row">
                  <div className="result-hiring-special-subitem col-40">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Destino</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker && data.taker.policy && data.taker.policy.travelDestination && Object.keys(travelCountries).length ?
                            travelCountries[data.taker.policy.travelDestination]
                            : SPANISH_COUNTRY_OPTION
                          }
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Fecha inicio</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker && data.taker.policy && data.taker.policy.departureDate}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-40">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Fecha fin</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker && data.taker.policy && data.taker.policy.returnDate}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className=""></div>
                </div>

                <div className="result-hiring-special-row">
                  <div className="result-hiring-special-subitem col-50">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Cantidad de pasajeros</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.insureds && data.insureds.length}
                        </p>
                      </WebText>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="result-simulation-special">
            <div className="result-hiring-special-title">
              <Title03Text>
                <p>Tomador</p>
              </Title03Text>
            </div>
            <div className="result-hiring-special-container col-100">
              <div className="result-hiring-special-first-item col-30">
                <HeadText>
                  <p>
                    {data.taker.name ?
                      `${data.taker.name} ${data.taker.lastname1} ${data.taker.lastname2}` : ''
                    }
                  </p>
                </HeadText>
              </div>
              <div className="result-hiring-special-item col-70">

                <div className="result-hiring-special-row">
                  <div className="result-hiring-special-subitem col-40">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Tipo documento</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker.docType} {data.taker.physicalPerson ? 'Persona física' : 'Persona jurídica'}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Nº documento</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker.docNumber}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Género</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker.gender ? data.taker.gender === 'H' ? 'Hombre' : 'Mujer' : ""}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Fecha de nacimiento</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker.birthDate}
                        </p>
                      </WebText>
                    </div>
                  </div>
                </div>

                <div className="result-hiring-special-row">
                  <div className="result-hiring-special-subitem col-40">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>DOMICILIO</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker.address ? data.taker.address.address.value + ", " + data.taker.address.number : ""}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>LOCALIDAD</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker.address ? data.taker.address.town.value : ''}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>PROVINCIA</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker.address ? data.taker.address.province.value : ''}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>CÓDIGO POSTAL</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker.address ? data.taker.address.postalCode : ''}
                        </p>
                      </WebText>
                    </div>
                  </div>
                </div>

                <div className="result-hiring-special-row">
                  <div className="result-hiring-special-subitem col-40">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Email</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker.email}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Teléfono fijo</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker.landlinePhone}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Teléfono móvil</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.taker.mobilePhone}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="col-20"></div>
                </div>

                <div className="result-hiring-special-row">
                  <div className="result-hiring-special-subitem col-40">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>FECHA CONSENTIMIENTO</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.protectionData.consentDate}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>CESIÓN DE DATOS</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.protectionData.dataTransfer === 'S' ? 'SI' : 'NO'}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-40">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>ENVÍO DE PUBLICIDAD DE TERCEROS</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.protectionData.thirdParty === 'S' ? 'SI' : 'NO'}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className=""></div>
                </div>

                <div className="result-hiring-special-row">
                  <div className="result-hiring-special-subitem col-50">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Cesión de llamadas promocionando productos de terceros</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {data.protectionData.thirdPartyCalls === 'S' ? 'SI' : 'NO'}
                        </p>
                      </WebText>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="result-simulation-item float">
          <div className="result-simulation-special-title">
            <Title03Text>
              <p>Asegurados</p>
            </Title03Text>
          </div>
          {data.insureds && data.insureds.map((insured: any) => {
            return (
              <div className="result-hiring-special-container col-100">
                <div className="result-hiring-special-first-item col-30">
                  <HeadText>
                    <p>
                      {insured.name ?
                        `${insured.name} ${insured.lastname1} ${insured.lastname2}` : ''
                      }
                    </p>
                  </HeadText>
                </div>
                <div className="result-hiring-special-item col-70">

                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Tipo documento</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {insured.docType}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Nº documento</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {insured.docNumber}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Género</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {insured.gender === 'H' ? 'Hombre' : 'Mujer'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Fecha de nacimiento</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {insured.birthDate}
                          </p>
                        </WebText>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <div className="result-simulation-item float">

          <div className="result-simulation-special-title">
            <Title03Text>
              <p>Garantías</p>
            </Title03Text>
          </div>
          <div className="detail-pendant-simulation-item-content">
            <div className="detail-pendant-simulation-item-content-table">
              <MainTable
                className="table noZebra"
                columns={this.getGuaranteeTableColumns()}
                rows={this.getGuaranteeTableRowsTravel()}
                keysRow={["coverages", "capital"]}
              />
            </div>
          </div>
        </div>

        <div className="result-simulation-item float">
          <div className="result-simulation-special-title">
            <Title03Text>
              <p>Desglose por forma de pago</p>
            </Title03Text>
          </div>
          <div className="detail-pendant-simulation-item-content">
            <div className="detail-pendant-simulation-item-content-table">
              <MainTable
                className="table noZebra"
                columns={this.getPaymentTableColumns()}
                rows={this.getPaymentTableRows()}
                keysRow={["periodicity", "netPremium", "taxs", "total"]}
              />
            </div>
          </div>
        </div>

      </TravelResultDataContainer >
    );
  }
}

export default withDynamicProducts(withCoverages(withPayTypes(withPolicy(withGeneral(withRouter(TravelResultDataLayout))))));
