import React from "react";
import { withRouter } from "react-router-dom";
import { ClientsPoliciesContainer } from "./clients-policies-style";
import GoBack from "../../../components/go-back";
import MainTable from "../../../components/main-table";
import Pagination from "../../../components/pagination";
import FilterInput from "../../../components/filter-input/filter-input-layout";
import { policesColumns, POLICY_SEARCH_OPTION } from "../../../constants/clientsPolicies";
import { withGeneral } from "../../../context/mixin/with-general";
import Body02Text from "../../../components/components-text/body-02-text";
import MainButton from "../../../components/main-button";
import { isValidCif, isValidDocumentID } from "../../../utils/validation";
import PoliciesServices from "../../../services/PoliciesServices";
import XLSX from 'xlsx';
import { ChooseHiringModalContainer } from "../../../components/choose-hiring-modal/choose-hiring-modal-style";
import H4Text from "../../../components/components-text/h4-text";
import { ErrorModalContainer } from "../../../components/error-modal/error-modal-style";
import { IClientsPoliciesProps, ClientsPoliciesState, ISearchPolicyDataRow, SearchPolicyDataDto } from "./dto/client-policies-layout-dto";
import { ERROR_MESSAGES } from "../../../constants/errorMessages";
import NoteText from "../../../components/components-text/note-text";
import SelectBox from "../../../components/select-box/select-box";
import { getRelatedMediatorsCodes } from "../../../utils/mediatorCode";
class ClientsPoliciesLayout extends React.Component<IClientsPoliciesProps, ClientsPoliciesState> {
  constructor(props: IClientsPoliciesProps) {
    super(props);

    const mediator = props.mediator ? props.mediator : localStorage.loggedMediator ? JSON.parse(localStorage.loggedMediator) : "";
    let codeOptions: Array<string> = [];
    if (mediator.relatedKeys) {
      codeOptions = mediator.relatedKeys.relatedKeysList ? JSON.parse(JSON.stringify(mediator.relatedKeys.relatedKeysList)) : [];
      if (!codeOptions.includes(mediator.mediatorCode))
        codeOptions.unshift(mediator.mediatorCode);
    }

    this.state = {
      identificationNumberInput: this.getDefaultPolicySearchInput(),
      policyNumberInput: this.getDefaultPolicySearchInput(),
      searchTypeFilter: {
        type: props.location.state ? props.location.state.searchType : '',
        text: props.location.state ? props.location.state.searchText : '',
        validity: props.location.state ? true : false,
      },
      mediatorCodesInput: {
        codeOptions: getRelatedMediatorsCodes(this.props.mediator),
        disabled: props.location.state && props.location.state.searchType ? false : true,
        showError: false,
        errorText: '',
        selectedCode: props.location.state && props.location.state.searchCode ? props.location.state.searchCode : props.mediator.mediatorCode,
      },
      policies: [],
      policiesFilter: [],
      page: props.location.state ? props.location.state.page : 1,
      totalPages: 0,
      showItems: 10,
      order: props.location.state ? props.location.state.order : '',
      ascendent: props.location.state ? props.location.state.ascendent : false,
      showModal: false,
      download: '',
      modalError: false,
      modalErrorMessage: '',
      serverError: false,
      noDataMessage: '',
      userEmpty: false,
    };
  }

  componentDidMount() {
    if (this.props.history.location.state) {
      if (this.props.mediator) {
        this.getRows(true)
      }
      this.setState({ userEmpty: true });
    }
  }

  getDefaultPolicySearchInput = () => ({ showError: false, errorText: '' });

  // getMediatorCodesDictionary(codeOptions: Array<string>): { [key: string]: string }[] {
  //   let codesDictionary: { [key: string]: string }[] = [];
  //   codeOptions.forEach(element => {
  //     codesDictionary.push({ value: element, label: element });
  //   })
  //   return codesDictionary;
  // };

  async getRows(newSearch: boolean) {
    const { searchTypeFilter, mediatorCodesInput, showItems, page, order, ascendent } = this.state;

    const { state } = this.props.location;

    const data = newSearch ?
      {
        codMediador: mediatorCodesInput.selectedCode,
        searchType: searchTypeFilter.type,
        data: searchTypeFilter.text
      } :

      {
        codMediador: state && state.searchCode ? state.searchCode : mediatorCodesInput.selectedCode,
        searchType: state && state.searchType ? state.searchType : searchTypeFilter.type,
        data: state && state.searchText ? state.searchText : searchTypeFilter.text
      };


    const queryParams = [
      `skip=${showItems * (newSearch ? 0 : page - 1)}`,
      `take=${showItems}`,
      order ? `order=${ascendent ? '' : '-'}${order}` : ''
    ];

    const resPolicies = await PoliciesServices.searchPolicy(
      this.props.setLoading,
      true,
      queryParams,
      data
    ).catch(() => {
      this.setState({ serverError: true, policies: rows, policiesFilter: rows, totalPages: 0, noDataMessage: '' });
      throw ERROR_MESSAGES["server-error"];
    });

    const rows: ISearchPolicyDataRow[] = [];
    if (resPolicies !== undefined) {
      resPolicies.policyList.forEach((policy: SearchPolicyDataDto) => {
        const row: ISearchPolicyDataRow = {
          codigo_negocio: this.setInputDataRow(policy.grupo_negocio, "", "responsive", policy.poliza),
          colectivo: this.setInputDataRow(policy.colectivo + '|' + policy.des_colectivo, "", "responsive", policy.poliza),
          poliza: this.setInputDataRow(policy.poliza + '/' + policy.orden, "", "responsive number", policy.poliza),
          cod_mediador: this.setInputDataRow(policy.cod_mediador, "", "responsive", policy.poliza),
          nif_tomador: this.setInputDataRow(policy.nif_tomador, "", "responsive", policy.poliza),
          nom_tomador: this.setInputDataRow(policy.nom_tomador, "", "responsive", policy.poliza),
          estado_poliza: this.setInputDataRow(policy.estado_poliza, "", "responsive", policy.poliza),
          corriente_pago: this.setInputDataRow(policy.corriente_pago, "", "responsive", policy.poliza),
        };
        rows.push(row);
      });
      const pages = Math.ceil(resPolicies.count / showItems);
      this.setState({ policies: rows, policiesFilter: rows, totalPages: pages, page, noDataMessage: rows.length < 1 ? ERROR_MESSAGES["no-data"] : '' });
      this.saveLastSearch();
    }
    else {
      if (this.state.serverError !== true) {
        this.setState({ policies: [], page: 1, totalPages: 0, policiesFilter: [], noDataMessage: ERROR_MESSAGES["no-data"] });
      }
    }
  }

  setInputDataRow = (value: string, type: string, key: string, idLink: string,) => ({ text: value, type: type, key: key, idLink: idLink });

  saveLastSearch() {
    this.props.history.replace({
      state: {
        searchType: this.state.searchTypeFilter.type,
        searchText: this.state.searchTypeFilter.text,
        searchCode: this.state.mediatorCodesInput.selectedCode,
        page: this.state.page,
        order: this.state.order,
        ascendent: this.state.ascendent,
      }
    });
  }

  handleRadioChange(value: string) {
    let { searchTypeFilter, identificationNumberInput, policyNumberInput } = this.state;
    switch (value) {
      case POLICY_SEARCH_OPTION.TOMADOR:
      case POLICY_SEARCH_OPTION.ASEGURADO:
        identificationNumberInput.showError = false;
        identificationNumberInput.errorText = '';
        if (searchTypeFilter.type === POLICY_SEARCH_OPTION.POLIZA) {
          searchTypeFilter.text = '';
          searchTypeFilter.validity = false;
        }
        break;
      case POLICY_SEARCH_OPTION.POLIZA:
        identificationNumberInput.showError = false;
        searchTypeFilter.validity = false;
        searchTypeFilter.text = '';
        break;
      default:
        break;
    }
    searchTypeFilter.type = value;
    this.setState({ searchTypeFilter, identificationNumberInput, policyNumberInput });
  }

  handleInputChange = (value: string, key: string = "") => {
    let { searchTypeFilter, identificationNumberInput, policyNumberInput } = this.state;

    if (key === "idDocumento") {
      if (value.length > 0) {
        identificationNumberInput.showError = false;
        identificationNumberInput.errorText = '';
        searchTypeFilter.validity = (searchTypeFilter.type !== 'Poliza' && value.length >= 1);
      }
      else {
        identificationNumberInput.showError = false;
        identificationNumberInput.errorText = '';
        searchTypeFilter.validity = false;
      }
    } else if (key === "poliza") {
      searchTypeFilter.validity = (searchTypeFilter.type === POLICY_SEARCH_OPTION.POLIZA && value.length >= 5);
    }

    searchTypeFilter.text = value;
    this.setState({ searchTypeFilter, identificationNumberInput, policyNumberInput });
  };

  handleSelectedCodesChange = (selectedCode: string) => {
    const { mediatorCodesInput } = this.state;
    mediatorCodesInput.selectedCode = selectedCode;
    this.setState({ mediatorCodesInput });
  };

  handleOrder = (column: string, ascendent: boolean | undefined) => {
    this.setState({ order: column, ascendent }, () => this.getRows(false))
  };

  setPage = (page: number) => {
    this.setState({ page }, () => this.getRows(false));
  };

  async download() {
    const { order, ascendent, download, mediatorCodesInput, searchTypeFilter } = this.state;
    const { state } = this.props.location;

    const data = {
      codMediador: state && state.searchCode ? state.searchCode : mediatorCodesInput.selectedCode,
      searchType: state && state.searchType ? state.searchType : searchTypeFilter.type,
      data: state && state.searchText ? state.searchText : searchTypeFilter.text
    }

    const queryParams = [
      `fileType=${download}`,
      order ? `order=${ascendent ? '' : '-'}${order}` : ''
    ];

    const policiesData = await PoliciesServices.downloadPolicies(
      this.props.setLoading,
      true,
      queryParams,
      data,
    ).catch(() => {
      this.setState({ modalError: true, modalErrorMessage: ERROR_MESSAGES["unknow-error"] });
    });

    switch (download) {
      case 'excel':
        if (policiesData !== undefined) {
          let worksheet = XLSX.read(JSON.parse(policiesData.workbook).data, { type: "buffer" });

          XLSX.writeFile(worksheet, data.data + "_" + new Date().toLocaleDateString() + '.xlsx');
          this.setState({ download: '', modalError: false, modalErrorMessage: '', showModal: false });
        }
        break;
      case 'csv':
        if (policiesData !== undefined) {
          const blob = new Blob([policiesData.workbook]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");

          link.href = url;
          link.download = data.data + "_" + new Date().toLocaleDateString() + '.csv';
          link.click();
          this.setState({ download: '', modalError: false, modalErrorMessage: '', showModal: false });
        }
        break;
      default:
        this.setState({ modalError: true, modalErrorMessage: ERROR_MESSAGES["no-type-selected"] });
        break;
    }
  }

  render() {
    const {
      searchTypeFilter,
      policiesFilter,
      totalPages,
      page,
      policyNumberInput,
      identificationNumberInput,
      showModal,
      serverError,
      noDataMessage,
      modalErrorMessage,
      userEmpty,
      mediatorCodesInput,
      order,
      ascendent
    } = this.state;

    const { is404 } = this.props;

    var notShow = false;

    if (is404) {
      notShow = true;
    }

    if (userEmpty && this.props.mediator) {
      this.getRows(true);
      this.setState({ userEmpty: false });
    }


    return (
      notShow ? "" :
        (<ClientsPoliciesContainer>
          <div className="back-arrow-container">
            <GoBack text="Volver" />
          </div>
          <div className="main-title-container">
            <h2>PÓLIZAS</h2>
          </div>

          <div className="main-filter-container">
            <h4>Búsqueda</h4>
            <div className="policy-radio-main-row">
              <div className="radioBox">
                <label>
                  <input
                    type="radio"
                    name="recibo"
                    value={POLICY_SEARCH_OPTION.TOMADOR}
                    onChange={() => this.handleRadioChange(POLICY_SEARCH_OPTION.TOMADOR)}
                    defaultChecked={searchTypeFilter.type === POLICY_SEARCH_OPTION.TOMADOR ? true : false}
                  />
                  <span>Tomador</span>
                </label>
              </div>
              <div className="radioBox">
                <label>
                  <input
                    type="radio"
                    name="recibo"
                    value={POLICY_SEARCH_OPTION.ASEGURADO}
                    onChange={() => this.handleRadioChange(POLICY_SEARCH_OPTION.ASEGURADO)}
                    defaultChecked={searchTypeFilter.type === POLICY_SEARCH_OPTION.ASEGURADO ? true : false}
                  />
                  <span>Asegurado</span>
                </label>
              </div>
              <div className="radioBox">
                <label>
                  <input
                    type="radio"
                    name="recibo"
                    value={POLICY_SEARCH_OPTION.POLIZA}
                    onChange={() => this.handleRadioChange(POLICY_SEARCH_OPTION.POLIZA)}
                    defaultChecked={searchTypeFilter.type === POLICY_SEARCH_OPTION.POLIZA ? true : false}
                  />
                  <span className="recover-checkmark"> Póliza</span>
                </label>
              </div>
            </div>

            <div className="main-filter-second-row">
              <div className="col-3 main-filter-item">
                <div className="inputBox">
                  <FilterInput
                    placeholder={'NIF/CIF/NIE/PASAPORTE'}
                    onChange={(value: string) => this.handleInputChange(value, "idDocumento")}
                    onEnter={() => searchTypeFilter.validity ? this.getRows(true) : ''}
                    showError={identificationNumberInput.showError}
                    errorText={identificationNumberInput.errorText}
                    required={searchTypeFilter.type === POLICY_SEARCH_OPTION.TOMADOR ||
                      searchTypeFilter.type === POLICY_SEARCH_OPTION.ASEGURADO}
                    disabled={searchTypeFilter.type !== POLICY_SEARCH_OPTION.TOMADOR &&
                      searchTypeFilter.type !== POLICY_SEARCH_OPTION.ASEGURADO}
                    initValue={searchTypeFilter.type !== POLICY_SEARCH_OPTION.TOMADOR &&
                      searchTypeFilter.type !== POLICY_SEARCH_OPTION.ASEGURADO ? searchTypeFilter.text : ''}
                    cleanValueDisabled={searchTypeFilter.type !== POLICY_SEARCH_OPTION.TOMADOR &&
                      searchTypeFilter.type !== POLICY_SEARCH_OPTION.ASEGURADO}
                    maxLength={9}
                  />
                </div>
              </div>

              <div className="col-3 main-filter-item">
                <div className="inputBox">
                  <FilterInput
                    placeholder={'Póliza'}
                    onChange={(value: string) => this.handleInputChange(value, "poliza")}
                    onEnter={() => searchTypeFilter.validity ? this.getRows(true) : ''}
                    showError={policyNumberInput.showError}
                    errorText={policyNumberInput.errorText}
                    required={searchTypeFilter.type === POLICY_SEARCH_OPTION.POLIZA}
                    disabled={searchTypeFilter.type !== POLICY_SEARCH_OPTION.POLIZA}
                    initValue={searchTypeFilter.type === POLICY_SEARCH_OPTION.POLIZA ? searchTypeFilter.text : ''}
                    cleanValueDisabled={searchTypeFilter.type !== POLICY_SEARCH_OPTION.POLIZA}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="selectBox relatedMediators">
                  <SelectBox
                    // mediatorSelector={true}
                    isMediator={true}
                    optionsText={getRelatedMediatorsCodes(this.props.mediator)}
                    optionKey={"value"}
                    optionValue={"label"}
                    defaultValue={mediatorCodesInput.selectedCode}
                    onChange={(value: string) => this.handleSelectedCodesChange(value)}
                    propValue={mediatorCodesInput.selectedCode}
                    required={true}
                    disabled={searchTypeFilter.type ? false : true}
                  />
                  <label>Mediador</label>
                </div>
              </div>
            </div>

            <div className="main-filter-third-row">
              <div className="col-3 main-filter-item--select topMargin15">
                <MainButton
                  text={'Buscar'}
                  disabled={!searchTypeFilter.validity}
                  onClick={() => this.getRows(true)}
                />
              </div>

              <div className="col-3 main-filter-item--select topMargin15">
                <MainButton
                  className="download-button"
                  text={'Exportar'}
                  disabled={totalPages === 0}
                  onClick={() => this.setState({ showModal: true })}
                />
              </div>
            </div>
          </div>

          <ErrorModalContainer
            className={`${serverError && `active`}`}
            active={serverError}>
            <div className="recover-modal">
              <div className="server-error">
                <p>{ERROR_MESSAGES["unknow-error"]}</p>
              </div>
              <div className="button-error">
                <MainButton text="Aceptar" onClick={() => this.setState({ serverError: false })} />
              </div>

            </div>
          </ErrorModalContainer>

          {totalPages === 0 ? (
            <Body02Text>{noDataMessage}</Body02Text>
          ) : (
            <div>

              <div className="main-filter-container">
                <h4>Resultados</h4>
                <div className="client-policies-table">
                  <MainTable
                    className="table interactive"
                    columns={policesColumns}
                    rowLink={`/polizas-clientes/detalle/${mediatorCodesInput.selectedCode}`}
                    rowKeyLink={"poliza"}
                    rows={policiesFilter}
                    keysRow={["codigo_negocio", "colectivo", "poliza", "cod_mediador", "nif_tomador", "nom_tomador", "estado_poliza", "corriente_pago"]}
                    defaultOrder={{ [order]: ascendent }}
                    onOrder={(column: string, ascendent: boolean | undefined) => this.handleOrder(column, ascendent)}
                  />
                </div>
              </div>

              <ChooseHiringModalContainer className={`${showModal && `active`}`}>
                <div className="recover-modal">

                  <div className="recover-modal-main">
                    <div className="recover-modal-main-title">
                      <div className="recover-modal-text">
                        <H4Text>Selecciona formato de exportación</H4Text>
                      </div>
                    </div>

                    <div className="recover-modal-main-container">
                      <div className="recover-modal-main-row">
                        <div className="recover-modal-main-row-title">
                          <div className="radioBox">
                            <label>
                              <input
                                type="radio"
                                name="download"
                                value="excel"
                                onChange={() => this.setState({ download: 'excel', modalErrorMessage: '' })}
                                checked={this.state.download === 'excel'}
                              />
                              <span> Excel</span>
                            </label>
                          </div>
                        </div>
                        <div className="recover-modal-main-row-title">
                          <div className="radioBox">
                            <label>
                              <input
                                type="radio"
                                name="download"
                                value="csv"
                                onChange={() => this.setState({ download: 'csv', modalErrorMessage: '' })}
                                checked={this.state.download === 'csv'}
                              />
                              <span> Csv</span>
                            </label>
                          </div>
                        </div>

                        <div className="download-filter-error">
                          <NoteText>
                            <p>{modalErrorMessage}</p>
                          </NoteText>
                        </div>

                      </div>
                    </div>

                    <div className="recover-modal-button">
                      <MainButton text="Aceptar" onClick={() => this.download()} />
                      <MainButton text="Cancelar" onClick={() => this.setState({ showModal: false, modalErrorMessage: '', download: '' })} type={'ghost'} />
                    </div>

                  </div>
                </div>
              </ChooseHiringModalContainer>

              <div className="client-policies-pagination">
                <Pagination page={page} limit={totalPages} callback={(page) => this.setPage(page)} />
              </div>

            </div>
          )}
        </ClientsPoliciesContainer>
        )
    );
  }

}

export default withGeneral(withRouter(ClientsPoliciesLayout));
