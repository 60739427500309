export const DAYS: any[] = [
  { label: "Todos", value: "" },
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  { label: "03", value: "03" },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  { label: "07", value: "07" },
  { label: "08", value: "08" },
  { label: "09", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
];

export const MONTHS: any[] = [
  { label: "Todos", value: "" },
  { label: "Enero", value: "01" },
  { label: "Febrero", value: "02" },
  { label: "Marzo", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Mayo", value: "05" },
  { label: "Junio", value: "06" },
  { label: "Julio", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Septiembre", value: "09" },
  { label: "Octubre", value: "10" },
  { label: "Noviembre", value: "11" },
  { label: "Diciembre", value: "12" },
];

export const MONTHS_2: any[] = [
  { label: "Todos", value: "" },
  { label: "Enero", value: "1" },
  { label: "Febrero", value: "2" },
  { label: "Marzo", value: "3" },
  { label: "Abril", value: "4" },
  { label: "Mayo", value: "5" },
  { label: "Junio", value: "6" },
  { label: "Julio", value: "7" },
  { label: "Agosto", value: "8" },
  { label: "Septiembre", value: "9" },
  { label: "Octubre", value: "10" },
  { label: "Noviembre", value: "11" },
  { label: "Diciembre", value: "12" },
];

export const STATE: any[] = [
  { label: "Todos", value: "" },
  { label: "Pendiente", value: "Pendiente" },
  { label: "Cobrado", value: "Cobrado" },
  { label: "Anulado", value: "Anulado" },
];

export const STATE_PAY: any[] = [
  { label: "Todos", value: "" },
  { label: "Cobrado", value: "Cobrado" },
  { label: "Pendiente", value: "Pendiente" },
  { label: "Anulado", value: "Anulado" },
];

export const RECEIPT_STATE_PAY: any[] = [
  { label: "Todos", value: "" },
  { label: "Cobrado", value: "Cobrado" },
  { label: "Pendiente", value: "Pendiente" },
  { label: "Impagado", value: "Impagado" },
];

export const VALIDITY: any[] = [
  { label: "Todos", value: "" },
  { label: "Anulada", value: "Anulada" },
  { label: "En Suspenso", value: "En Suspenso" },
  { label: "En Vigor", value: "En Vigor" },
  { label: "Extinguida", value: "Extinguida" },
  { label: "Propuesta", value: "Propuesta" },
];