export interface NotaInformativaMediadorData {
    id: string,
    extension: string,
}

export enum ProductType {
    tranquility,
    death,
    pet,
    health,
    healthCopay,
    proxima,
    proximaPlus,
    momento,
    integral100k,
    integral180k,
    integral240k,
    dental,
}

export enum YesNoEnum {
    "si",
    "no"
}

export enum DocumentType {
    "Documentación Cotización / Simulación",
    "Envío de Simulación (Email / Carta)",
    "Documento indentificativo de cliente (NIF)",
    "Documento recibo compañía anterior",
    "Documento autonomo (RETA)",
    "Documento miembro colectivo",
    "Documento de proyecto de seguro",
    "Cuestionario de salud",
    "Documentación Precontractual",
    "Envío de Doc. Precontractual (Email / Carta)",
    "Envío de Doc. Precontractual (Email / Carta) (CS firmado)",
    "Documento de valoración médica",
    "Solicitud Información médica (Email/Carta)",
    "Domentacion Informes Medicos",
    "Condiciones Particulares",
    "Mandato SEPA",
    "Welcomepack",
    "Envió Welcomepack (Email / Carta)",
    "Welcome pack (CCPP firmadas)",
    "Recibo de prima",
    "Duplicado de Recibo de prima",
    "Documentacion Siniestros",
    "Envío de recibo (Email / Carta)",
    "Tarjeta digital",
    "Envío de Tarjeta (Email / Carta)",
    "Carta de Renovación",
    "Comunicación de anulación de póliza",
    "Comunicación modificación de seguro",
    "Documento de Solicitud de seguro",
    "Documentación otros"
}

export enum DocumentTypeForTemplate {
    "Documento de Simulación de seguro",
    "DIP (Documento de Información de Producto)",
    "Nota Informativa del producto",
    "Nota Informativa del distribuidor",
    "Envío de Simulación (plantilla email)",
    "Documento de solicitud",
    "Documento de proyecto de seguro",
    "Envío de Propuesta (plantilla email)",
    "Cuestionario de salud",
    "Documento de valoración médica",
    "Solicitud Información médica (plantilla email)",
    "Condicionado General",
    "Condiciones Particulares",
    "Documento Servicio Valor Añadido.",
    "Carta bienvenida WP",
    "Mandato SEPA",
    "Recibo de prima",
    "Duplicado de Recibo de prima",
    "Envío de recibo (plantilla email)",
    "Tarjeta Sanitaria.",
    "Hoja de tarjeta sanitaria",
    "Encarte tarjeta.",
    "Carta de Renovación.",
    "Solicitud Baja Poliza",
    "Comuncación de anulación de póliza",
    "Solicitud Alta/ Baja Asegurado.",
    "Solicitud de cambio tomador",
    "Solicitud Anulación /incorporación garantía",
    "Solicitud de modifcación de datos",
    "Comuncación modificación de seguro"
}


export interface GestDocCreateFileRequestDto {
    nodeId: string;
    file: File
    fileInfo: Metadata;
}

export interface GestDocSearchFileRequestDto {
    fileInfo: Metadata;
    docsLanguage?: string;
}
export interface GesDocProductInfo {
    areaName: string
    type: string
    combinacion_comercial: string
}

export interface Metadata {
    "SITE"?: string;
    "TYPE"?: string;
    "nodeType"?: string;

    // Product
    "ins:productIdSenda"?: string;
    "ins:productDocumentType"?: string;

    // Person
    "ins:personIdSenda"?: string;
    "ins:personIdentityDocument"?: string;
    "ins:personDocumentType"?: string;

    // Policy
    "ins:policyIdSenda"?: string;
    "ins:policyDocumentType"?: string;

    // Proposal
    "ins:proposalIdSenda"?: string;
    "ins:proposalDocumentType"?: string;

    // Common
    "ins:relatedTemplateReference"?: string;
    "ins:relatedTemplateNodeRef"?: string;
    "ins:relatedTemplateVersion"?: string;

    "ins:signatureType"?: string;
    "ins:signatureDate"?: string;

    "ins:asisacode"?: string;
    "ins:asisaitem"?: string;
    "ins:asisagroup"?: string;

    "name"?: string;
    "ins:areaName"?: string;
    "-ins:areaName"?: string;

    "ins:originName"?: string;

    "ins:securityLevel"?: string;
    "ins:mediatorCode"?: string;
    "ins:collectiveCode"?: string;

    "ins:relatedPersonIdsSenda"?: string;
    "ins:relatedPersonIdentityDocuments"?: string;

    "ins:relatedProductIdsSenda"?: string;

    // Alfresco
    "exif:pixelYDimension"?: number;
    "exif:pixelXDimension"?: number;
    "template:current"?: string;
    "template:companyCif"?: string;
    "cm:versionType"?: string;
    "cm:versionLabel"?: string;
    "template:processPhase"?: string[];
    "template:emission"?: string;
    "template:exportSenda"?: string;
    "template:company"?: string;
    "template:responsible"?: string;
    "template:lopd"?: string;
    "cm:lastThumbnailModification"?: string[];
    "template:registryData"?: string;
    "template:signatureRequired"?: string;
    "template:signatureIncluded"?:string;
    "cm:title"?: DocumentTypeForTemplate | string;
    "cm:name"?: string;
    "template:mainLogo"?: string;
    "template:language"?: string;
    "template:reference"?: string;
    "template:contactChannel"?: string[];
    "template:formats"?: string[];
    "template:entity"?: string;
    "template:compositions"?: string;
    "template:area"?: string | string[];
    "cm:created"?: Date;
    "template:templateDocumentTypification"?: string;
    "template:templateProductCode"?: string;

    "commercial:documentType"?: string;
    "commercial:documentCode"?: string;
    "commercial:centerArea"?: string;
    "commercial:centerCompany"?: string;
    "commercial:centerCompanyCif"?: string;
    "commercial:centerName"?: string;
    "commercial:centerCode"?: string;
    "commercial:date"?: Date;

    // Person Multiple Values
    "MultiplePersonIdentityDocument"?: string[];
    "MultiplePersonIdSenda"?: string[];

    //ins2 metadata
    "ins2:documentCode"?: string;
    "ins2:scanned"?: Date;
    "ins2:signed"?: YesNoEnum;
    "ins2:documentType"?: DocumentType | string;
    "ins2:processPhase"?: string;
    "ins2:processEntity"?: string;
    "ins2:companyCenter"?: string;
    "ins2:companyCenterCif"?: string;
    "ins2:companyCenterName"?: string;
    "ins2:companyCenterCode"?: string;
    "ins2:companyCenterArea"?: string;
    "ins2:mediatorCode"?: string;
    "ins2:mediatorCif"?: string;
    "ins2:mediatorName"?: string;
    "ins2:takerType"?: string;
    "ins2:takerName"?: string;
    "ins2:takerSurname1"?: string;
    "ins2:takerSurname2"?: string;
    "ins2:takerNif"?: string;
    "ins2:policyRequestNumber"?: string;
    "ins2:policyId"?: string;
    "ins2:policyOrderId"?: string;

    //Siniestros
    "ins2:accidentNumber"?: string;
    "ins2:coverage"?: string;
    "ins2:accidentDocumentType"?: string;

    //Insureds
    //Intenté usarlo con un template string indexable pero typescript no lo permite por debajo de la versión 4.4
    "ins2:insured1Name"?: string;
    "ins2:insured1Surname1"?: string;
    "ins2:insured1Surname2"?: string;
    "ins2:insured1Nif"?: string;

    "ins2:insured2Name"?: string;
    "ins2:insured2Surname1"?: string;
    "ins2:insured2Surname2"?: string;
    "ins2:insured2Nif"?: string;

    "ins2:insured3Name"?: string;
    "ins2:insured3Surname1"?: string;
    "ins2:insured3Surname2"?: string;
    "ins2:insured3Nif"?: string;

    "ins2:insured4Name"?: string;
    "ins2:insured4Surname1"?: string;
    "ins2:insured4Surname2"?: string;
    "ins2:insured4Nif"?: string;

    "ins2:insured5Name"?: string;
    "ins2:insured5Surname1"?: string;
    "ins2:insured5Surname2"?: string;
    "ins2:insured5Nif"?: string;

    "ins2:insured6Name"?: string;
    "ins2:insured6Surname1"?: string;
    "ins2:insured6Surname2"?: string;
    "ins2:insured6Nif"?: string;

    "ins2:insured7Name"?: string;
    "ins2:insured7Surname1"?: string;
    "ins2:insured7Surname2"?: string;
    "ins2:insured7Nif"?: string;

    "ins2:insured8Name"?: string;
    "ins2:insured8Surname1"?: string;
    "ins2:insured8Surname2"?: string;
    "ins2:insured8Nif"?: string;

    "ins2:insured9Name"?: string;
    "ins2:insured9Surname1"?: string;
    "ins2:insured9Surname2"?: string;
    "ins2:insured9Nif"?: string;

    "ins2:insured10Name"?: string;
    "ins2:insured10Surname1"?: string;
    "ins2:insured10Surname2"?: string;
    "ins2:insured10Nif"?: string;

    "ins2:collectiveCode"?: string;
    "ins2:collectiveName"?: string;
    "ins2:productId"?: string;
    "ins2:productName"?: string;
}

export interface GestDocFileInfoResponseDto {
    entry: {
        isFile: true,
        createdByUser: {
            id: string,
            displayName: string
        },
        modifiedAt: Date,
        nodeType: string,
        content: {
            mimeType: string,
            mimeTypeName: string,
            sizeInBytes: number,
            encoding: string
        },
        parentId: string,
        aspectNames: string[],
        createdAt: Date,
        isFolder: boolean,
        modifiedByUser: {
            id: string,
            displayName: string
        },
        name: string,
        id: string,
        properties: Metadata
    }
}

export interface GestDocFileListResponseDto {
    list: {
        pagination: {
            count: number,
            hasMoreItems: boolean,
            totalItems: number,
            skipCount: number,
            maxItems: number
        },
        context: {
            consistency: {
                lastTxId: number
            }
        },
        entries: GestDocFileInfoResponseDto[]
    }
}

export interface GestDocFileDataResponseDto {
    fileType: string;
    fileName: string;
    fileData: Buffer;
}
