import React, { Component } from "react";
import PoliciesItemLayout from "./policies-item-layout";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { array } from "prop-types";

interface PoliciesItemScreenState {
  open: boolean;
  type: string;
  typeName: String;
  nameItem: String;
  arrayComplexItem?: any;
  changeStep: any;
  image: any;
}

interface PoliciesItemScreenProps extends RouteComponentProps {
  arrayComplexItem?: any;
  changeStep: any;
  colorCode?: string;
  disabled?:boolean;
  image: any;
  link: string | string[];
  nameItem: String;
  setItemClick?: Function;
  textButton?: string;
  id: string;
  type: string;
  typeName: String;
  setGlobalInternalName: Function;
  cardName: string;
}

class PoliciesItemScreen extends Component<
  PoliciesItemScreenProps,
  PoliciesItemScreenState
> {
  state: PoliciesItemScreenState = this.state;
  containerRef: any = React.createRef();

  constructor(props: PoliciesItemScreenProps) {
    super(props);
    const {
      type,
      typeName,
      nameItem,
      arrayComplexItem,
      changeStep,
      image
    } = this.props;

    switch (type) {
      case "complex":
        this.state = {
          open: false,
          type: type,
          typeName: typeName,
          nameItem: nameItem,
          arrayComplexItem: arrayComplexItem,
          changeStep: changeStep,
          image: image
        };
        break;
      case "simple":
        this.state = {
          open: false,
          type: type,
          typeName: typeName,
          nameItem: nameItem,
          changeStep: changeStep,
          image: image
        };
        break;
    }
  }

  changeType = (complex: string) => {
    let { type } = this.state;

    type = complex;

    this.setState({ type });
  };

  changeLifeActive = (index: number) => {
    const { setItemClick, arrayComplexItem } = this.props;
    let policiesComplexItems: any = this.containerRef.current.getElementsByClassName(
      "policies-complex-item"
    );

    for (let i = 0; i < policiesComplexItems.length; i++) {
      policiesComplexItems[i].classList.remove("active");
    }

    policiesComplexItems[index].classList.add("active");

    if (setItemClick) {
      setItemClick(arrayComplexItem[index]);
    }

    return true;
  };

  changeOpen = () => {
    let { open } = this.state;

    if (open) {
      open = false;
    } else {
      open = true;
    }

    this.setState({ open });
  };

  setType: any = (type: string) => {
    let {
      typeName,
      nameItem,
      arrayComplexItem,
      open,
      changeStep,
      image
    } = this.state;
    const { setItemClick = () => { }, link, textButton, colorCode, disabled, id, setGlobalInternalName, cardName } = this.props;
    
    switch (type) {
      case "simple":
        return (
          <PoliciesItemLayout
            id={id}
            type={type}
            link={link}
            typeName={typeName}
            nameItem={nameItem}
            image={image}
            open={open}
            changeOpen={this.changeOpen}
            changeLifeActive={this.changeLifeActive}
            changeStep={changeStep}
            textButton={textButton}
            colorCode={colorCode}
            disabled={disabled}
            setGlobalInternalName={setGlobalInternalName}
            cardName={cardName}
          />
        );
        break;
      case "complex":
        return (
          <PoliciesItemLayout
            id={id}
            type={type}
            link={link}
            typeName={typeName}
            nameItem={nameItem}
            image={image}
            arrayComplexItem={arrayComplexItem}
            open={open}
            changeOpen={this.changeOpen}
            changeLifeActive={this.changeLifeActive}
            changeStep={changeStep}
            textButton={textButton}
            colorCode={colorCode}
            disabled={disabled}
            setGlobalInternalName={setGlobalInternalName}
            cardName={cardName}
          />
        );
        break;
    }
  };

  render() {
    let { type } = this.props;
    var layout = this.setType(type);
    return <div ref={this.containerRef}>{layout}</div>;
  }
}

export default withRouter(PoliciesItemScreen);
