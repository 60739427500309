import styled from 'styled-components';

export const FaqsContainer = styled.div`
.container{
  margin: 0; 
  padding: 0; 
  height: 100%; 
  overflow: hidden;
}
.content{
  position:absolute; 
  left: 220px; 
  right: 0; 
  bottom: 5px; 
  top: 80px;
}
`;