import styled from "styled-components";
import { colors } from "../../assets";
import { MainButtonContainer } from "../main-button/main-button-style";

export const LeftInfoModalContainer = styled.div`
  display: none;
  color: ${colors["PR-001-100"]};
  position: fixed;
  z-index: 9999;
  background: ${colors["background-modal"]};
  left: 0;
  width: 100%;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &.active {
    display: flex;
  }

  &.error {
    color: ${colors["ER-001-100"]};

    .left-info-modal-icon-bg {
      background: rgba(228, 2, 2, 0.3);
    }

    ${MainButtonContainer} {
      background: ${colors["ER-001-100"]};
    }

    .ghost {
      border: none !important;
      color: #ffffff !important;
    }
  }

  &.success {
    
    /* .left-info-modal-icon-bg {
      background: rgba(64, 202, 169, 0.3);
    } */
    /* color: ${colors["SU-001-100"]}; */

    ${MainButtonContainer} {
      background: ${colors["SU-001-100"]};
    }
  }

  &.warning {
    .left-info-modal-icon-bg {
      background: ${colors["errorColor"]};;
    }
  }
  
  &.alert {
    color: ${colors["AL-001-100"]};

    .left-info-modal-icon-bg {
      background: ${colors["AL-001-100"]};
    }

    
    .left-info-modal-main-secondary {
      margin-top: 20px;
    }

    .left-info-modal-button ${MainButtonContainer} {
        &:first-child {
          color: white !important;
          background-color: ${colors["AL-001-90"]};
          border-color: ${colors["AL-001-90"]} !important;

          &:hover {
            background-color: ${colors["AL-001-100"]};
            border-color: ${colors["AL-001-100"]};
          }
        }
        &:last-child {
          color: white;
          background-color: ${colors["AL-001-90"]};
          border-color: ${colors["AL-001-90"]};
  
          &:hover {
            background-color: ${colors["AL-001-100"]};
            border-color: ${colors["AL-001-100"]};
          }
        }
      }
  }

  &.info {
    color: ${colors["PR-001-80"]};

    .left-info-modal-icon-bg {
      background: ${colors["PR-001-80"]};
    }

    .left-info-modal-button ${MainButtonContainer} {
        &:last-child {
          color: white;
          background-color: ${colors["PR-001-80"]};
          border-color: ${colors["PR-001-80"]};
  
          &:hover {
            background-color: ${colors["PR-001-100"]};
            border-color: ${colors["PR-001-100"]};
          }
        }
      }
    }

    .ghost {
      border: 1px solid ${colors["PR-001-80"]} !important;
      color:  ${colors["PR-001-80"]} !important;

      &:hover {
        border: 1px solid ${colors["PR-001-100"]};
        color: ${colors["PR-001-100"]};
      }
    }
  }

  .left-info-modal-container {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 20px 22px 35px 35px;
    max-width: 620px;
    max-height: 90vh;
    overflow: hidden;
    overflow-y: auto;
  }

  .left-info-modal-close {
    text-align: right;

    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .left-info-modal-main-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .left-info-modal-icon {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .left-info-modal-icon-bg {
    display: inline-block;
    background: rgba(179, 202, 220, 0.3);
    width: 62px;
    min-width: 62px;
    height: 62px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 34px;
    }
  }

  .left-info-modal-main-content {
    padding-left: 80px;
  }

  .left-info-modal-main {
    padding-right: 65px;
  }

  .left-info-modal-main-copy {
    margin-bottom: 20px;
    color: ${colors["TX-001-50"]}};

    ul li.left-info-modal-main-list {
      list-style-type: disc;
      margin-bottom: 12px;
      color: ${colors["TX-001-50"]}};
      overflow-wrap: anywhere;
    }
  }

  .left-info-modal-main-wrap {
    white-space: pre-wrap;
  }

  .left-info-modal-info {
    *{
      display:inline; 
      vertical-align: text-top;
    }

    img {
      height: 20px;
      width: 20px;
      vertical-align: text-bottom;
    }

    font-size: 14px;
    margin-top: 16px;
    color: ${colors["TX-001-50"]}};
  }

  .left-info-modal-main-secondary {
    margin-top: 25px;
  }

  .left-info-modal-input {
    margin-bottom: 20px;
    input {
      width: 100%;
    }
  }

  .left-info-modal-text {
    padding-left: 20px;
  }

  .left-info-modal-main-conditions {
    margin-bottom: 40px;
  }

  .left-info-modal-main-conditions-item {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .left-info-modal-button {
    display: flex;
    margin-top: 20px;

    ${MainButtonContainer} {
      width: 185px;

      &:first-child {
        margin-right: 12px;
      }

      &:last-child {
        background-color: ${colors["errorColor"]};
        border-color: ${colors["errorColor"]};

        &:hover {
          background-color: ${colors["errorDarkColor"]};
          border-color: ${colors["errorDarkColor"]};
        }
      }
    }
  }
`;
