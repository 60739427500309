import React from "react";
import {
  Icon,
  StyledCell,
  StyledHeader,
  StyledTable,
  TableContainer,
} from "./table.style";
import { TableProps } from "./table.types";

const Table: React.FC<TableProps> = ({
  columns,
  data,
  actions,
  actionsColumnName,
}) => {
  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <StyledHeader key={index} style={{ textAlign: column.position }}>
                {column.title.toUpperCase()}
              </StyledHeader>
            ))}
            {actions && (
              <StyledHeader>
                {actionsColumnName ? actionsColumnName : ""}
              </StyledHeader>
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <StyledCell
                  key={colIndex}
                  style={{ textAlign: column.position }}
                >
                  {row[column.key]}
                </StyledCell>
              ))}
              {actions && (
                <StyledCell style={{ textAlign: "center" }}>
                  {actions.map((action, actionIndex) => (
                    <Icon
                      key={actionIndex}
                      src={action.icon}
                      onClick={() => action.onClick(row)}
                      style={{ cursor: "pointer" }}
                      alt={action.alt ? action.alt : ""}
                    />
                  ))}
                </StyledCell>
              )}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default Table;
