import React, { Component } from "react";
import { MainButtonContainer } from "./main-button-style";
import ButtonText from "../components-text/button-text";

class MainButton extends Component<any, {}> {

  containerRef: any;
  constructor(props: any) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const { onClick, withTab, disabled } = this.props;
    withTab && !disabled && this.containerRef.current.addEventListener("keyup", (e: any) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        onClick();
      }
    });
  }

  componentDidUpdate(prevProps: any) {
    const { onClick, withTab, disabled } = this.props;
    if (withTab && !disabled &&  prevProps.withTab !== withTab) {
      this.containerRef.current.addEventListener("keyup", (e: any) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          onClick();
        }
      });
    }
  }

  render() {
    const { text, type, icon, iconImg, onClick, disabled, withTab, height, fontSize } = this.props;
    return (
      <MainButtonContainer
        ref={this.containerRef}
        disabled={disabled}
        className={type} onClick={() => !disabled && onClick()}
        tabIndex={withTab && !disabled ? 0 : undefined}
        height={height}
        fontSize={fontSize}
      >
        {icon && (
          <div className="main-button-icon">
            <img src={iconImg} />
          </div>
        )}
        <div className="main-button-text">
          <ButtonText fontSize={fontSize} >{text}</ButtonText>
        </div>
      </MainButtonContainer>
    );
  }
}

export default MainButton;
