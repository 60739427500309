import React from "react";
import LeftInfoModalLayout from "./left-info-modal-layout";

export default class LeftInfoModal extends React.Component<any, {}> {
  render() {
    const {
      img,
      className,
      onClickAccept,
      mainTitle,
      mainText,
      active,
      buttonText,
      close,
      withCancel,
      withInput,
      closeButtonText,
      noSecondaryButton,
      list,
      secondaryText,
      info,
      centerPrimaryButton,
      hasAcceptNoClose,
      buttonFontSizeSmall,
    } = this.props;

    return (
      <LeftInfoModalLayout
        className={className}
        img={img}
        onClickAccept={() => onClickAccept()}
        mainTitle={mainTitle}
        mainText={mainText}
        list={list}
        buttonText={buttonText}
        active={active}
        close={close}
        withCancel={withCancel}
        withInput={withInput}
        closeButtonText={closeButtonText}
        noSecondaryButton={noSecondaryButton}
        secondaryText={secondaryText}
        info={info}
        centerPrimaryButton = {centerPrimaryButton}
        hasAcceptNoClose= {hasAcceptNoClose}
        buttonFontSizeSmall={buttonFontSizeSmall}
      />
    );
  }
}
