import styled from "styled-components";
import { colors } from "../../../../assets";
import { BodyTextContainer } from "../../../../components/components-text/body-text/body-text-styles";
import { NoteTextContainer } from "../../../../components/components-text/note-text/note-text-styles";

export const ResultContainer = styled.div`
  .result-hero-container {
    margin-bottom: 40px;
    color: ${colors["PR-001-100"]};
    font-family: "museo sans";
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
  }

  .result-hero-type {
    font-weight: 700;
    p {
      font-size: 10px;
      text-transform: uppercase;

      span {
        margin-top: 6px;
        color: ${props => colors[props.colorSpan]};
        font-size: 24px;
        display: block;
        text-transform: initial;
      }
    }
  }

  .result-hero-data {
    text-align: right;
    font-family: "museo sans";
    font-size: 12px;
    font-weight: 300;

    &__name {
      font-weight: 500;
      font-size: 14px;
    }

    p {
      margin-bottom: 6px;
    }
  }

  .result-welcome {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    margin-bottom: 64px;
    padding: 0 40px;

    &__title {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 28px;
    }
    &__description {
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .result-back {
    margin-bottom: 95px;
  }

  .result-special {
    padding: 40px;
    margin-bottom: 24px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
  }

  .result-special-title {
    color: ${colors["PR-001-100"]};
    border-bottom: 1px solid ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 24px;
  }

  .result-item-subtitle-container {
    margin-bottom: 24px;

    .result-item-subtitle-item {
      margin-bottom: 16px;
      display: flex;
      font-family: "Museo Sans";

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .result-item-subtitle-item-key {
    color: ${colors["TX-001-50"]};
    min-width: 75px;
    margin-right: 12px;
  }

  .result-item-subtitle-item-value {
    color: ${colors["TX-001-100"]};
  }

  .result-special-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .result-special-item {
    display: flex;
  }

  .result-special-subitem {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  .result-special-subitem-title {
    text-transform: uppercase;
    color: ${colors["TX-001-30"]};
    margin-bottom: 6px;
  }

  .result-special-subitem-content {
    color: ${colors["TX-001-100"]};
  }

  .result-item-content-table {
    min-width: 100%;
    max-width: 1250px;
    overflow-x: auto;
    margin-bottom: 55px;

    .table {
      min-width: 1020px;
    }

    @media (max-width: 1420px) {
      overflow-x: scroll;
    }

    .entire-mini-title {
      font-size: 16px;
    }

    .dimmed-mini-title {
      font-size: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    thead {
      border-bottom: 1px solid ${colors["PR-001-100"]};
    }

    th {
      .highlighted {
        color: ${colors["TX-001-50"]};
      }
    }

    td {
      > div {
        padding: 8px 0;
        padding-right: 16px;
      }

      ${BodyTextContainer} {
        display: inline-block;
        &.highlighted {
          font-weight: 600;
        }
      }
    }

    .main-table-row {
      &:first-child {
        td {
          > div {
            padding-top: 20px;
          }
        }
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  .result-item-content-advise {
    color: ${colors["TX-001-30"]};
  }

  .result-item-content-capital-text {
    margin-bottom: 40px;

    p {
      color: ${colors["TX-001-50"]};
    }

    span {
      color: ${colors["TX-001-100"]};
      margin-left: 8px;
    }
  }

  .result-container {
    margin-bottom: 40px;
  }

  .result-item {
    padding: 40px;
    margin-bottom: 24px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;

    &.padding0 {
      padding: 0 !important;
    }
  }

  .result-item-title {
    color: ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 24px;
  }

  .result-footer {
    padding-top: 37px;
    margin-bottom: 80px;
    color: ${colors["PR-001-100"]};

    ${NoteTextContainer} {
      line-height: 1.5em;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
