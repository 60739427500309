import React, { Component } from 'react';
import { LoaderSpinnerContainer } from './loader-spinner-style';

interface LoaderSpinnerState {
    active: boolean;
}

interface LoaderSpinnerProps {

}

class LoaderSpinner extends Component<LoaderSpinnerProps, LoaderSpinnerState> {

    state: LoaderSpinnerState = {
        active: true
    }

    render() {

        const { active } = this.state;

        return (
            <LoaderSpinnerContainer className={`${(active) ? 'active' : ''}`}>
                <div className="loader-spinner-holder">
                    <div className="spinner"></div>
                </div>
            </LoaderSpinnerContainer>
        );
    }
}

export default LoaderSpinner;