import styled from 'styled-components';
import { colors } from '../../assets';

export const FaqsContainer = styled.div`
  .buttons-container {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
  }

  .buttons-container > div {
    width: 110px;
  }

  .inputBox input::placeholder {
    opacity: 1;
  }

  .content {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
    line-height: 1.3em;

    .list {
      border: 1px solid #dcdcdc;
      border-radius: 4px;
      padding: 10px;
      margin-top: 15px;
      height: calc(100vh - 320px);
      overflow-y: scroll;
      
      p {
        margin-bottom: 0.5em;
        cursor: pointer;

        &:hover, &.selected {
          color: #ffffff;
          background-color: ${colors["PR-001-100"]};
          transition: all 0.3s;
        }
      }
    }

    .response {
      border-radius: 4px;
      padding: 10px;
      margin-top: 15px;
      max-width: 900px;
      height: calc(100vh - 240px);
      overflow-y: scroll;

      &-pregunta {
        color: ${colors["PR-001-100"]};
      }

      &-content {
        margin: 15px 0;
        text-align: justify;

        .texto {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .bullet {
          padding-left: 2em;
          position: relative;
          margin-bottom: 5px;

          &::before {
            content: "";
            width: 0.4em;
            height: 0.4em;
            background: ${colors["PR-001-100"]};
            position: absolute;
            left: 1em;
            top: 0.4em;
            border-radius: 100%;
          }
        }

        .img {
          width: 100%;
          text-align: center;
  
          img {
            width: auto;
            height: auto;
            max-width: 100%;
          }
        }
      }

    }
  }
`