/**
    MainTable
    columns={this.memberTableColumns1} array de objetos para las columnas, cada objeto puede tener:
      'text': 'texto de la columna',
            'filter': booleano para poder filtrar,
            'ascendent': orden del filtro,
            'key': 'clase para estilar'
    rows={this.memberTableRows1} array de objetos para las rows, cada objeto puede tener:
      'text': 'texto para la row', 
      'type': 'clase para los estilados segun el tipo de poliza', 
        (keep-calm, pets, deaths, complete, incomplete)
        'key': 'highlighted'
        booleano para indicar si las celdas son links
    rowKeyLink={''} en caso de ser una ruta dinamica, el parametro que se pondrá al final de la ruta
    'rowLink': '', en caso de ser link, la ruta a la que apunta
    keysRow={['taker','province','phone','email']} nombre del objeto que recoge toda la info de cada row, tiene que coincidir con el nº de columnas
    />
 */

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import BodyText from "../components-text/body-text";
import WebText from "../components-text/web-text";
import TooltipBox from "../tooltip-box/tooltip-box";
import { MainTableContainer } from "./main-table-style";

interface MainTableProps {
  rows: any[];
  columns: any[];
  orderBy: Function;
  keysRow: string[];
  rowKeyLink: string;
  rowLink: string;
  className?: string;
  defaultOrder?: any;
  justOrderOneColumn?: boolean;
  keysAlignRight?: string[];
  mediator?: string;
}

interface MainTableState {
  activeColumnHead: activeObject;
  tooltipActive: boolean;
  tooltipX: string;
  tooltipY: string;
  exitTooltipActive: boolean;
  tooltipMessage: string;
}

interface activeObject {
  [key: string]: any;
}

interface tableButton {
  img: string;
  link: string;
  onClick: Function;
}

class MainTableLayout extends Component<MainTableProps, MainTableState> {
  state: MainTableState = {
    activeColumnHead: {},
    tooltipActive: false,
    exitTooltipActive: false,
    tooltipY: '0px',
    tooltipX: '0px',
    tooltipMessage: '',
  };

  handleClickOnColumnHead(key: string, ascendent: boolean | undefined) {
    const { orderBy, justOrderOneColumn } = this.props;
    let { activeColumnHead } = this.state;

    if (justOrderOneColumn) {
      activeColumnHead = { [key]: activeColumnHead[key] === true ? undefined : activeColumnHead[key] === false ? true : false }
    }
    else {
      if (activeColumnHead[key] === true) {
        activeColumnHead[key] = undefined;
      } else if (activeColumnHead[key] === false) {
        activeColumnHead[key] = true;
      } else {
        activeColumnHead[key] = false;
      }
    }
    orderBy(key, activeColumnHead[key]);
    this.setState({ activeColumnHead });
  }

  setMediatorTooltipActive(ev: any) {
    const tooltipX = `${ev.pageX - ev.target.clientWidth}px`;
    const tooltipY = `${ev.pageY + ev.currentTarget.clientHeight}px`;
    this.setState({ 
        tooltipActive: (ev.target.dataset['tooltips'] !== ''),
        tooltipX, 
        tooltipMessage: ev.target.dataset['tooltips'], 
        tooltipY, 
    })
  }

  renderCellTable(item: any) {
    if (item) {
      switch (item.key) {

        //TODO: Esta parte es para escoger el pago en salud, de momento nos mandaron ocultarlo
        /* case "radio":
          return (
            <div>
              <div className="radio-row">
                <div className="radioBox">
                  <label>
                    <input
                      type="radio"
                      checked={item.selected}
                    />
                    <span>{item.text}</span>
                  </label>
                </div>
              </div>
            </div>
          ); */

        case "responsive":
          if (String(item.text).length > 50) {
            return (
              <div className="responsiveEllipsis">
                <p className="ellipsis2lines">{String(item.text).substr(0, 50) + '...'}</p>
                <span className="tooltip">{item.text}</span>
              </div>
            );
          }
          else {
            return (
              <div className="responsive">
                <p>{item.text}</p>
              </div>
            );
          }

        case "responsive number":
          if (String(item.text).length > 50) {
            return (
              <div className="responsive">
                <p className="ellipsis2lines">{String(item.text).substr(0, 50) + '...'}</p>
                <span className="tooltip">{item.text}</span>
              </div>
            );
          }
          else {
            return (
              <div className="responsive">
                <p>{item.text}</p>
              </div>
            );
          }

        case "beneficiaries":
          return (
            <div className={`beneficiaries`}>
              <p>{item.text}</p>
            </div>
          );

        case "beneficiaries percentage":
          return (
            <div className={`beneficiaries percentage`}>
              <p>{item.text}</p>
            </div>
          );

        case "highlighted":
          return (
            <BodyText className="highlighted">
              <p>{item.text}</p>
            </BodyText>
          );

        case "dimmed-mini-body":
          return (
            <BodyText className="dimmed-mini-body">
              <p>{item.text}</p>
            </BodyText>
          );

        case "dimmed-mini-list":
          return (
            <div className="dimmed-mini-list">
              <ul>
                {item.array.map((text: string) => (
                  <li>
                    <BodyText>
                      <p>{text}</p>
                    </BodyText>
                  </li>
                ))}
              </ul>
            </div>
          );

        case "entire-mini-body":
          return (
            <BodyText className="entire-mini-body">
              <p>{item.text}</p>
            </BodyText>
          );

        case "tag-container":
          return (
            <div>
              <WebText className={`normal tag-container ${item.type}`}>
                <p>{item.text}</p>
              </WebText>
            </div>
          );

        case "body":
          return (
            <BodyText>
              <p>{item.text}</p>
            </BodyText>
          );

        case "link":
          return (
            <BodyText className="hire">
              <NavLink to={item.link}>{item.text}</NavLink>
            </BodyText>
          );

        case "button":
          return (       
          <div className="colSin-100" data-tooltips={item.toolTips}
              onMouseEnter={(e: any) => this.setMediatorTooltipActive(e)}
              onMouseLeave={(e: any) => this.setState({ tooltipActive: false })}
            > 
              <BodyText className={`inline hire${item.disabled ? " disabled" : ""} ${item.type === "sign" ? " sign" : ""}`} toolTips={item.toolTips} >
                <div className="colSin-100" data-tooltips={item.toolTips}>{item.text}</div>
              </BodyText>

          </div>
          );

        case "icon-button":
          return (
            <div className="table-icon-container">
              <div className="table-icon-item" onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                item.onClick()
              }}>
                <button>
                  <img src={item.img} />
                </button>
              </div>
            </div>
          );
        case "checkbox":
          return (
            <div className="table-checkbox-container">
              <div className={item.value ? "checkbox-checked" : "checkbox-unchecked"} id={item.text} onClick={(ev) => {
                ev.stopPropagation();
                item.onClick(item.text)
              }
              }>
              </div>
            </div>
          );
        case "buttons":
          return (
            <div className="table-icon-container">
              {item.items.map((button: tableButton, index: number) =>
                button.link ? (
                  <div key={index} className="table-icon-item">
                    <NavLink to={button.link}>
                      <img src={button.img} />
                    </NavLink>
                  </div>
                ) : (
                  <div key={index} className="table-icon-item" onClick={() => button.onClick()}>
                    <button>
                      <img src={button.img} />
                    </button>
                  </div>
                ),
              )}
            </div>
          );
        
        case "taker" :
          return (
              <ul className="dimmed-mini-list">
                {item.text.filter((i: string) => ( i !== '' && i !== undefined)).map((text: string) => (
                  <li>
                    <BodyText>
                      <p>{text}</p>
                    </BodyText>
                  </li>
                ))}
              </ul>
            
          );
        
          case "mediator"  :
          return (
            <div>
                <div
                  onMouseEnter={(e: any) => this.setMediatorTooltipActive(e)}
                  onMouseLeave={(e: any) => this.setState({tooltipActive: false})}
                >
                  <BodyText>
                      <p data-tooltips={item.toolTips}>{item.text}</p>
                  </BodyText>
                </div>
            </div>
          );

        default:
          return (
            <BodyText className={item.key}>
              <p>{item.text}</p>
            </BodyText> 
          )
      }
    }
  }

  renderHeadTable(item: any) {
    switch (item.key) {

      case "responsiveHeader":
        return (
          <div className="responsiveHeader">
            <p>{item.text}</p>
          </div>
        );

      case "highlighted":
        return (
          <BodyText className="highlighted">
            <p>{item.text}</p>
          </BodyText>
        );

      case "beneficiaries-header":
        return (
          <BodyText className="beneficiaries-header">
            <p>{item.text}</p>
          </BodyText>
        );

      case "checkbox-header":
        return (
          <div>
            <div style={{ cursor: "pointer" }} onClick={() => item.onClick()}>Seleccionar página</div>
          </div>
        );
      case "dimmed-mini-title":
        return (
          <BodyText className="dimmed-mini-title">
            <p>{item.text}</p>
          </BodyText>
        );

      case "entire-mini-title":
        return (
          <BodyText className="entire-mini-title">
            <p>{item.text}</p>
          </BodyText>
        );

      default:
        return (
          <BodyText className={item.key}>
            <p>{item.text}</p>
          </BodyText>
        );
    }
  }

  render() {
    const { rows, columns, keysRow, rowLink, rowKeyLink, className, defaultOrder, keysAlignRight, mediator } = this.props;
    let { activeColumnHead, tooltipActive, tooltipMessage, tooltipX, tooltipY } = this.state;

    return (
      <>
      <MainTableContainer className={className ? className : ""}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={`column-${index}`}
                onClick={() => (column.filter ? this.handleClickOnColumnHead(keysRow[index], activeColumnHead[keysRow[index]]) : "")}
                className={`${column.filter ? 'pointer' : ''} 
                ${defaultOrder && Object.keys(activeColumnHead).length === 0 && defaultOrder[keysRow[index]] !== undefined ?
                    (`filter ${defaultOrder[keysRow[index]]}` && this.setActiveColumn(defaultOrder)) :
                    column.filter ? activeColumnHead[keysRow[index]] !== undefined ?
                      `filter ${activeColumnHead[keysRow[index]] ?
                        "active"
                        : ""}`
                      : "filterReady"
                      : ''}
                    ${String(column.key).includes('fixedWidth150') ? 'fixedWidth150' :
                    String(column.key).includes('fixedWidth160') ? 'fixedWidth160' :
                      String(column.key).includes('fixedWidth170') ? 'fixedWidth170' : ''}`
                }
              >
                {this.renderHeadTable(column)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr className="main-table-row" key={`row-${index}`}>
              {keysRow.map((key, indexKey) => (
                <td onClick={row[key] && row[key].onClick ? () => !row[key].disabled && row[key].onClick() : () => { }} key={`td-${indexKey}`}
                  className={row[key].key.includes('number') ? 'number' : (keysAlignRight ? (keysAlignRight.includes(key) ? 'align-right-cell isCurrency' : '') : '') + (row[key].key.includes('selected') && ' selected')}>
                  {rowLink && row[key].key !== "link" && row[key].key !== "button" ? (
                    rowKeyLink !== 'poliza' && rowKeyLink !== 'recibo' ? (
                      <NavLink
                        to={`${rowLink}${rowKeyLink ?
                          `${row.mediator ? '/' + (row.mediator.text !== '' ? row.mediator.text : mediator) : ''}/${row[rowKeyLink].text}`
                          : ""}`}
                      >
                        {this.renderCellTable(row[key])}</NavLink>
                    ) : (
                      <NavLink
                        to={{
                          pathname: `${rowLink}${rowKeyLink ?
                            (rowKeyLink === 'poliza' ?
                              `/${row[rowKeyLink].text}` : `/${row[key].idLink}`)
                            : ""}`,
                          state: {
                            data: row,
                          }
                        }}
                      >
                        {this.renderCellTable(row[key])}</NavLink>
                    )
                  ) : (
                    this.renderCellTable(row[key])
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </MainTableContainer>
      <div className={'profile-sidebar-data-tooltip'}>
          <TooltipBox
            active={(tooltipMessage) ? tooltipActive : false}
            tooltipTitle={tooltipMessage}
            tooltipText={''}
            centerArrow={true}
            left={tooltipX} 
            top={tooltipY}
          />
      </div>
      </>
    );
  }
  setActiveColumn(defaultOrder: any) {
    this.state.activeColumnHead = defaultOrder;
  }

}

export default MainTableLayout;
