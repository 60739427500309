import React, { FC, useState } from "react";
import { CardContainer, ComparisonCard } from "./card-comparison-healt-style";
import MainButton from "../main-button";
import { images } from "../../assets";
import TooltipBox from "../tooltip-box/tooltip-box";
import { ImportsForPagoType, PolicyData } from "../../containers/forms/comparator/health-comparator/comparator.types";
import { getPaymentTime } from "../../containers/forms/comparator/health-comparator/assets";
import { STORAGE_KEY } from "../../constants/health";
import { deepGet, formatNumberFraction } from "../../utils/utility";
import PayTypesServices from "../../services/PayTypesServices";
import LeftInfoModal from "../left-info-modal";

export const CardComparisonHealt: FC<PolicyData> = ({ policy, history, completePolicies, discountLiteral }) => {
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);
  const [modalError, setModalError] = useState<boolean>(false);
  const { insuranceName, price, payType, productDescription, originalPayType, productType, productId } = policy;
  const formatedPrice = formatNumberFraction.format(Number(price));
  const [integer, decimal] = formatedPrice.split(",");
  const data = localStorage.getItem('HEALTH_SIMULATION');
  const convertedData = data ? JSON.parse(data) : {};


  const contratar = () => {
    localStorage.setItem('internalName', productType);
    const { body } = completePolicies;
    history.push({
      pathname: `/contratacion/nueva/salud/tomador`,
      state: {
        simulationId:
          body &&
          body.RESPUESTA &&
          body.RESPUESTA.DATOS_COTIZACION &&
          body.RESPUESTA.DATOS_COTIZACION.COTIZACION
            ? body.RESPUESTA.DATOS_COTIZACION.COTIZACION
            : "",
        dataForm: {
          mediator:
            convertedData && convertedData.mediatorData
              ? convertedData.mediatorData
              : {},
          insureds:
            convertedData && convertedData.insuredData
              ? convertedData.insuredData
              : [],
          price: { price: convertedData.selectedPrice },
        },
        data: {
          taker: {
            physicalPerson: true,
            email:
              convertedData && convertedData.taker && convertedData.taker.email
                ? convertedData.taker.email
                : "",
          },
          ...convertedData,
          insureds:
            convertedData && convertedData.insuredData
              ? convertedData.insuredData
              : [],
          payment: {},
          beneficiaries: [],
          loan: {},
        },
      },
    });
  }

  const simular = async () => {
    localStorage.setItem('internalName', productType);
    const importsForPago: ImportsForPagoType = completePolicies.body.RESPUESTA.IMPORTESFORPAGO.find((imports: ImportsForPagoType) => imports.PERIODO_COBRO === originalPayType);
    const priceData = {
      discount: importsForPago.DESCUENTOS ? importsForPago.DESCUENTOS : '',
      payType: originalPayType,
      paymentTime: getPaymentTime(originalPayType),
      price: importsForPago.PRIMATOTAL ? importsForPago.PRIMATOTAL : '',
      taxes: importsForPago.TOTAL_IMPUESTOS ? importsForPago.TOTAL_IMPUESTOS : '',
      total: importsForPago.PRIMATOTAL ? importsForPago.PRIMATOTAL : '',
    }

    localStorage.setItem('HEALTH_SIMULATION', JSON.stringify({...convertedData, responseSimulation: completePolicies, priceData}))

    const healthSimulationData = {...convertedData, responseSimulation: completePolicies, priceData};
    const { selectedPrice, campaign: selectedCampaigns } = convertedData;

    healthSimulationData.responseSimulation = completePolicies;
    try {
    const productPayType = await PayTypesServices.getProductPayTypes({productId, setLoading: () => {}, loader: false});
    if (productPayType.length > 0) {
      const state = {
        mediatorData: healthSimulationData.mediatorData,
        insuredData: healthSimulationData.insuredData,
        productPayType,
        priceData: {
          price: selectedPrice,
          campaigns: selectedCampaigns,
        },
      };
  
      if (healthSimulationData) {
        healthSimulationData.priceData = state.priceData;
        healthSimulationData.payments = selectedPrice;
        localStorage.setItem(
          STORAGE_KEY.sKey,
          JSON.stringify({...healthSimulationData, productPayType})
        );
      } else {
        localStorage.setItem(
          STORAGE_KEY.sKey,
          JSON.stringify({ insuredData: state.priceData, productPayType })
        );
      }
      history.push({
        pathname: "/simulacion/nueva/salud/resultado",
        state: { ...state }
      });
    } else {
      setModalError(true)
    }
  } catch (error) {
    setModalError(true)
  }
  }

  return (
    <>
      <CardContainer>
        <ComparisonCard>
          <div className="leftColumn flexColumn">
            <div className="healthInsuranceNameContainer">
              <p className="healthInsuranceName">{insuranceName}</p>
              <p className="descText blueText">
                {!!deepGet(completePolicies, 'body.RESPUESTA.CAMPANYAS')
                  ? discountLiteral
                  : ""}
              </p>
            </div>
            <div className="healthInsuranceInfo">
              <p className="smallText blueText">Desde</p>
              <p className="bigText blueText bigNumber">{integer}</p>
              <div className="flexColumn smallData">
                <p className="blueText mediumText">,{decimal}€</p>
                <div className="smallDataColumn">
                  <p className="smallText minWidth">/{payType}</p>
                  <img
                    src={images.IconInfoBlue}
                    alt="informacion"
                    style={{ width: "25px" }}
                    onMouseEnter={() => setTooltipActive(true)}
                    onMouseLeave={() => setTooltipActive(false)}
                  />
                </div>
                <TooltipBox
                  active={tooltipActive}
                  tooltipTitle={productDescription}
                  tooltipText={""}
                  left={"123px"}
                  top={"122px"}
                  centerArrow={true}
                  fontSizeSmall={true}
                />
              </div>
            </div>
          </div>
          <div className="rightColumn flexColumn">
            <MainButton
              height={"30px"}
              text={"Simular"}
              onClick={() => {
                simular()
              }}
            />
            <MainButton
              type={"orange"}
              height={"30px"}
              text={"Contratar"}
              onClick={() => {
                contratar()
              }}
            />
          </div>
        </ComparisonCard>
        <LeftInfoModal
          img={ images.WarningError }
          mainTitle={"error"}
          mainText={'Ha habido un error al cargar datos de las formas de pago'}
          noSecondaryButton={true}
          buttonText="Volver"
          active={modalError}
          onClickAccept={() => setModalError(false)}
          close={() => setModalError(false)}
          className={"error"}
        />
      </CardContainer>
    </>
  );
};

export default CardComparisonHealt;
