import styled from "styled-components";
import { colors } from "../../assets/colors";

export const CardMyCampaignsStyle = styled.div`
  border-radius: 3px;
  padding: 16px 10px 9px 10px;
  font-family: "museo sans";
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  .card-my-campaigns-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
    text-transform: uppercase;

    &__subtitle {
      font-size: 12px;
      line-height: 14px;
      font-weight: 300;
      letter-spacing: 0.04em;
    }
    &__status {
      border-radius: 100%;
      width: 16px;
      height: 16px;
      background: #c4c4c4;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 10px;
        width: 10px;
      }
    }
  }

  .card-my-campaigns-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
  }
  .card-my-campaigns-subtitle {
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
  }

  &.finished {
    background: ${colors["SU-001-100"]};
    border-radius: 3px;
    color: ${colors["FL-001-Default"]};
    .card-my-campaigns-top {
      &__status {
        background: ${colors["FL-001-Default"]};
      }
    }
  }
  &.in_action, &.in_process {
    background: ${colors["FL-001-Default"]};
    border-radius: 3px;
    color: ${colors["PR-001-100"]};
    .card-my-campaigns-top {
      &__subtitle {
        color: ${colors["PR-001-80"]};
      }
      &__status {
        background: ${colors["PR-001-100"]};
      }
    }
  }
  
  &.planned, &.definition {
    background: ${colors["PR-001/5"]};
    border-radius: 3px;
    color: ${colors["PR-001-100"]};
    .card-my-campaigns-top {
      &__subtitle {
        color: ${colors["SC-001-100"]};
      }
      &__status {
        background: ${colors["FL-001-Default"]};
      }
    }
  }
  &.revoked, &.cancelled {
    background: ${colors["PR-001/5"]};
    border-radius: 3px;
    color: ${colors["ER-001-80"]};
    .card-my-campaigns-top {
      &__status {
        background: ${colors["FL-001-Default"]};
      }
    }
  }

  &.active {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  }
`;
