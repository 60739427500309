/** 
 <DicotomicCheckbox
    name=""
    errorText="asd asd asd asd asd"
    showError
    className="mandatory"
    checkboxText="Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, expedita!"
/> 
*/

import React, { Component } from "react";
import { DicotomicCheckboxContainer } from "./dicotomic-checkbox-style";
import { images } from "../../assets";
import WebText from "../components-text/web-text";
import NoteText from "../components-text/note-text";


interface DicotomicCheckboxProps {
    className?: string;
    checkboxText: string;
    onChange: Function;
    initChecked?: boolean;
    disabled?: boolean
    showError?: boolean;
    errorText?: string;
}

interface DicotomicCheckboxState {
    checked: boolean;
}

class DicotomicCheckbox extends Component<DicotomicCheckboxProps, DicotomicCheckboxState> {

    readonly state: DicotomicCheckboxState;
    containerRef: any;

    constructor(props: DicotomicCheckboxProps) {
        super(props);
        this.state = {
            checked: props.initChecked || false
        };
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        const { disabled } = this.props;
        !disabled && this.containerRef.current.addEventListener("keyup", (e: any) => {
            if (e.keyCode === 13) {
                e.preventDefault();
                this.handleOnchange();
            }
        });
    }

    componentDidUpdate() {
        const { initChecked } = this.props;
        const { checked } = this.state;
        if (initChecked !== checked && initChecked !== undefined && initChecked !== null) {
            this.setState({ checked: initChecked || false });
        }
    }

    handleOnchange = () => {
        let { checked } = this.state;
        const { disabled } = this.props;
        if (disabled !== true) {
            this.setState({ checked: !checked });
            this.props.onChange(!checked);
        }
    }

    render() {
        const {
            className, checkboxText,
            showError, errorText,
            disabled
        } = this.props;

        return (
            <DicotomicCheckboxContainer className={className} tabIndex={!disabled ? 0 : -1} ref={this.containerRef} >
                <label className="checkbox-container">
                    <input
                        checked={this.state.checked}
                        type="checkbox"
                        onChange={this.handleOnchange}
                        tabIndex={-1}
                    />
                    <div className="checkmark-container">
                        <div className="checkmark-item">
                            <img src={this.state.checked ? images.IconTickWhite : images.IconCloseWhite} alt="" />
                        </div>
                    </div>
                    <WebText>
                        <p>{checkboxText}</p>
                    </WebText>
                </label>
                <div className="checkbox-error">
                    {showError && <NoteText>
                        <p>{errorText}</p>
                    </NoteText>}
                </div>
            </DicotomicCheckboxContainer>
        );
    }
}

export default DicotomicCheckbox;
