import React, { Component } from "react";
import { NotificationModalContainer } from "./notification-modal-style";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { images } from "../../assets";
import H4Text from "../components-text/h4-text";
import MainButton from "../main-button";
import WebText from "../components-text/web-text";
import NoteText from "../components-text/note-text";
import MenuText from "../components-text/menu-text";
import Body02Text from "../components-text/body-02-text";

interface NotificationModalRouterProps {}

interface NotificationModalProps extends RouteComponentProps<NotificationModalRouterProps> {
  className?: string;
  active?: boolean;
  close: Function;
  onClickAccept: Function;
}

interface NotificationModalState {
  active: string;
}

class NotificationModalLayout extends Component<NotificationModalProps, NotificationModalState> {
  readonly state: NotificationModalState;

  public static defaultProps = {
    active: false,
    className: "",
    close: () => {},
    onClickAccept: () => {},
  };

  constructor(props: NotificationModalProps) {
    super(props);
    this.state = {
      active: props.active ? "active" : "",
    };
  }

  componentDidUpdate(prevProps: NotificationModalProps) {
    if (prevProps.active !== this.props.active) {
      this.setState({ active: this.props.active ? "active" : "" });
    }
  }

  close() {
    this.setState({ active: "" });
  }

  open() {
    this.setState({ active: "active" });
  }

  acceptNotificationModal() {
    const { onClickAccept, close } = this.props;
    if (onClickAccept) {
      onClickAccept();
    }
    close();
  }

  render() {
    const { className, close } = this.props;
    const { active } = this.state;

    return (
      <NotificationModalContainer className={`${active} ${className}`}>
        <div className="notification-modal-container">
          <div className="notification-modal-close">
            <img src={images.IconCloseGrey} onClick={() => close()} />
          </div>

          <div className="notification-modal-main">
            <div className="notification-modal-main-title">
              <div className="notification-modal-icon-bg">
                <img src={images.IconAsisaBlueOutline} />
              </div>
              <div className="notification-modal-text">
                <MenuText>
                  <p>Notificación corporativa</p>
                </MenuText>
              </div>
            </div>

            <div className="notification-modal-main-date">
              <NoteText>
                <p>05/07/2019</p>
              </NoteText>
            </div>

            <div className="notification-modal-main-subtitle">
              <Body02Text>
                <p>Lorem ipsum dolor sit amet consectitur adispiscing elit lorem</p>
              </Body02Text>
            </div>

            <div className="notification-modal-main-content">
              <div className="notification-modal-main-copy">
                <WebText>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  </p>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  </p>
                </WebText>
              </div>

              <div className="notification-modal-main-docs">
                <div className="doc-row">
                  <div className="doc-row-item" title="Descargar archivo">
                    <div className="doc-row-icon">
                      <img src={images.IconClipGrey} alt="Archivo adjunto" />
                    </div>
                    <div className="doc-row-text">
                      <p>Lorem ipsum dolor</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="notification-modal-button">
                <MainButton text="Eliminar" onClick={() => this.acceptNotificationModal()} />
              </div>
            </div>
          </div>
        </div>
      </NotificationModalContainer>
    );
  }
}

export default withRouter(NotificationModalLayout);
