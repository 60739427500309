import React from "react";
import { HeadTextContainer } from "./head-text-style";

interface HeadText {
    children: any;
    className?: string;
    color?: string;
}

const HeadText = ({children = '', className='', color=''}:HeadText) => {
        return (
        <HeadTextContainer color={color} className={className}>
            {children}
        </HeadTextContainer>
    )
} 
export default HeadText;