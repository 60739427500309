import React, { ChangeEvent } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { images } from "../../../../../assets/images";
import NoteText from "../../../../../components/components-text/note-text";
import InputBox from "../../../../../components/input-box/input-box";
import MainButton from "../../../../../components/main-button";
import { isValidIban } from "../../../../../utils/iban";
import { downloadFile } from "../../../../../utils/utility";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import GestDocServices from "../../../../../services/Gest-Doc.Services";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { IPaymentMethod } from "../../../../../services/HealthService-dto";
import { GestDoc } from "../../../../../services/GestDoc";
import { GesDocProductInfo, GestDocFileListResponseDto } from "../../../../../models/gest-doc-models";
import { ValueFormHealth } from "../../../../../constants/health";
import DicotomicCheckbox from "../../../../../components/dicotomic-checkbox/dicotomic-checkbox";
import { acceptedFileMimeType } from "../../../../../constants/acceptedFileMimeType";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";
import { DateFormat, DentalPaymentMethodsInsuredState, DentalPaymentMethodsProps, DentalPaymentMethodsState, IBooleanInputState, IIban, IPaymentMethodsFormValuesState, ISepaFileState, ISignedFileState, PaymentInsuredData, PaymentMethod } from "./dental-payment-methods-dto";
import { DentalPaymentMethodsContainer } from "./dental-payment-methods-style";
import { DentalHireMenuHelper, Steps } from "../../../../../components/dental-hire-menu/dental-hire-menu";
import DentalService from "../../../../../services/DentalService";
import { AREA_NAMES } from "../../../../../constants/policyType";

class DentalPaymentMethodsLayout extends React.Component<DentalPaymentMethodsProps, DentalPaymentMethodsState> {
    constructor(props: DentalPaymentMethodsProps) {
        super(props);
        const paymentMethod: PaymentMethod = this.getInitialPaymentMethod(props.paymentMethod);
        const isCopayDisabled: boolean = props.isReadOnly || !paymentMethod.areInsuredsCopayGrouped || !paymentMethod.copayIban || paymentMethod.copayIban.sameAsMain;
        const areInsuredsCopayDisabled: boolean = props.isReadOnly || paymentMethod.areInsuredsCopayGrouped === true;
        this.state = {
            formValues: {
                mainIban: this.getDefaultValueForm(paymentMethod.mainIban.iban, props.isReadOnly),
                areInsuredsCopayGrouped: this.getDefaultBooleanInput(paymentMethod.areInsuredsCopayGrouped, props.isReadOnly),
                isPhysicalSignature: this.getDefaultBooleanInput(paymentMethod.isPhysicalSignature, !paymentMethod.isPhysicalSignature),
                isIbanCopaySameAsMain: this.getDefaultBooleanInput(paymentMethod.copayIban && paymentMethod.copayIban.sameAsMain ? paymentMethod.copayIban.sameAsMain : undefined, props.isReadOnly),
                copayIban: this.getDefaultValueForm(paymentMethod.copayIban ? paymentMethod.copayIban.iban : undefined, isCopayDisabled),
                insureds: this.getInitialInsuredsData(props.insuredsCopay, paymentMethod, areInsuredsCopayDisabled),
            },
            mainSepa: this.getDefaultISepaFile(paymentMethod.mainIban, paymentMethod, props.isReadOnly, true),
            copaySepa: this.getDefaultISepaFile(paymentMethod.copayIban, paymentMethod, isCopayDisabled, !isCopayDisabled),
            signedDocument: this.getDefaultISignedFile(paymentMethod.isPhysicalSignature, paymentMethod.isPhysicalSignature),
            anyChange: false,
            modalErrorShow: false,
            modalErrorText: "",
            previousModalShow: false,
            replaceDocModalShow: false,
            showCalendar: false,
            replaceDocModalCallback: () => { },
            cancelSignModalShow: false
        };
    }

    async componentDidMount() {
        const { paymentMethod, policy, insuredsCopay, data, cotizacion } = this.props;
        let { mainSepa, copaySepa, formValues } = this.state;

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 100);

        if (!paymentMethod)
            return; // Primera vez que se entra

        // Como SENDA no devuelve el ID del nodo de alfresco, si vemos que tenemos el iban pero no el sepaFileNodeId,
        // significa que es posible que ya se haya subido el documento, así que lo intentamos recuperar de Alfresco,
        // si no lo encontramos, damos por supuesto que no lo ha subido.
        let anyChange: boolean = false;
        const existMainSepaNodeId = paymentMethod.mainIban.iban && paymentMethod.mainIban.sepaNodeId;
        const existCopaySepaNodeId = policy.product.products_isCopay && paymentMethod.areInsuredsCopayGrouped === true && paymentMethod.copayIban && paymentMethod.copayIban.sameAsMain === false;
        const mayExistInsuredSepaNodeId = policy.product.products_isCopay && paymentMethod.areInsuredsCopayGrouped === false;

        if (!existMainSepaNodeId || !existCopaySepaNodeId) {
            let result: GestDocFileListResponseDto | undefined;
            try {
                result = await GestDocServices.searchFile(this.props.setLoading, true,
                    GestDoc.getSepaPolicySearch(cotizacion));
            } catch (error) {
                result = undefined;
            }
            if (result && result.list && result.list.entries && result.list.entries.length) {
                mainSepa.nodeId = result.list.entries[0].entry.id;

                if (result.list.entries.length > 1 && policy.product.products_isCopay && paymentMethod.areInsuredsCopayGrouped === true && paymentMethod.copayIban && paymentMethod.copayIban.sameAsMain === false) {
                    copaySepa.nodeId = result.list.entries[1].entry.id;
                }
                anyChange = true;
            }
        }
        if (mayExistInsuredSepaNodeId) {
            let insuredDocuments: string[] = [];
            insuredsCopay.forEach(insured => {
                if (insured.copay && !insured.copay.sameAsMain && insured.copay.sepaSignedDate && !insured.copay.sepaNodeId) {
                    insuredDocuments.push(insured.docNumber);
                }
            });

            if (insuredDocuments) {
                let insuredPromises = insuredDocuments.map(doc => {
                    return GestDocServices.searchFile(this.props.setLoading, true,
                        GestDoc.getSepaInsuredSearch(data.insureds));
                });
                let allResults: GestDocFileListResponseDto[] | undefined;
                try {
                    allResults = await Promise.all(insuredPromises);
                } catch (error) {
                    allResults = undefined;
                }
                if (allResults && allResults.length) {
                    formValues.insureds.forEach((insured, index) => {
                        let promiseIndex = insuredDocuments.findIndex(doc => { return doc === this.props.insuredsCopay[index].docNumber });
                        if (promiseIndex !== -1 && allResults && allResults[index] && allResults[index].list && allResults[index].list.entries && allResults[index].list.entries.length) {
                            insured.sepa.nodeId = allResults[index].list.entries[0].entry.id;
                        }
                    });
                    anyChange = true;
                }
            }
        }

        if (anyChange)
            this.setState({ mainSepa, copaySepa, formValues });
    }

    getInitialPaymentMethod = (initialPaymentMethod?: PaymentMethod): PaymentMethod => {
        const {policy}=this.props
        let paymentMethod = initialPaymentMethod;
        if (!paymentMethod) {
            paymentMethod = {
                mainIban: {
                    iban: "",
                    sameAsMain: true,
                },
                areInsuredsCopayGrouped: policy.product.products_isCopay ? true : undefined,
                copayIban: policy.product.products_isCopay ? {
                    iban: "",
                    sameAsMain: true,
                    sepaSignedDate: moment().format(DateFormat),
                } : undefined,
                isPhysicalSignature: false,
            };
        }
        return paymentMethod;
    }

    getInitialInsuredsData(insuredsData: PaymentInsuredData[], paymentMethod: PaymentMethod, isDisabled: boolean): DentalPaymentMethodsInsuredState[] {
        let insuredsCopay: DentalPaymentMethodsInsuredState[] = insuredsData.map(insured => {
            let isIbanDisabled = isDisabled || !insured.copay || insured.copay.sameAsMain ? true : false;
            let isSepaRequired = insured.copay && insured.copay.sepaSignedDate ? true : false;
            return {
                iban: this.getDefaultValueForm(insured.copay ? insured.copay.iban : "", isIbanDisabled),
                isIbanRequired: !isIbanDisabled,
                isIbanSameAsMain: this.getDefaultBooleanInput(insured.copay ? insured.copay.sameAsMain : true, isDisabled),
                sepa: this.getDefaultISepaFile(insured.copay, paymentMethod, isIbanDisabled, isSepaRequired),
                isOpen: !paymentMethod.areInsuredsCopayGrouped,
            };
        });
        return insuredsCopay;
    }

    getDefaultISignedFile = (isDisabled: boolean, isRequired: boolean): ISignedFileState => {
        return {
            file: undefined,
            error: "",
            disabled: isDisabled,
            isRequired: isRequired,
        }
    }

    getDefaultISepaFile = (value: IIban | undefined, paymentMethod: PaymentMethod, isDisabled: boolean, isRequired: boolean): ISepaFileState => {
        return {
            file: undefined,
            error: "",
            disabled: isDisabled,
            isSigned: this.getDefaultBooleanInput(value && value.sepaSignedDate ? true : false, isDisabled),
            nodeId: value ? value.sepaNodeId : undefined,
            signedDate: this.getDefaultValueForm(value ? value.sepaSignedDate : undefined, isDisabled),
            linkedIban: this.getSepaLinkedIban(value, paymentMethod),
            isRequired: isRequired,
        };
    }

    getSepaLinkedIban = (iban: IIban | undefined, paymentMethod: PaymentMethod): string => {
        if (iban && iban.iban)
            return iban.iban;

        if (paymentMethod.areInsuredsCopayGrouped === true)
            return paymentMethod.copayIban ? paymentMethod.copayIban.iban : "";

        if (iban && iban.sameAsMain)
            return paymentMethod.mainIban.iban;

        return "";
    }

    getDefaultValueForm = (value: string | undefined, isDisabled: boolean): ValueFormHealth => {
        return {
            value: value ? value : "",
            disabled: isDisabled,
            errorCode: "",
            code: "",
        };
    }

    getDefaultBooleanInput(value: boolean | undefined, isDisabled: boolean): IBooleanInputState {
        return {
            value: value === true,
            isDisabled: isDisabled,
        };
    }

    handleCheckChange = (value: boolean) => {
        let { formValues, signedDocument } = this.state;

        formValues.isPhysicalSignature.value = value;
        formValues.isPhysicalSignature.isDisabled = !value;
        signedDocument = this.getDefaultISignedFile(false, value);

        this.setState({ formValues, signedDocument, anyChange: true });
    }

    handleValueChange = (inputName: string, value: string, index?: number): void => {
        let { formValues, copaySepa } = this.state
        // HACK: Para que typescript no proteste por el tipo
        switch (inputName) {
            case "mainIban":
                formValues[inputName].value = value;
                formValues[inputName].errorCode = "";
                break;

            case "copayIban":
                formValues[inputName].value = value;
                formValues[inputName].errorCode = "";
                break;

            case "copaySepa.signedDate":
                copaySepa.signedDate.value = value;
                copaySepa.signedDate.errorCode = "";
                break;

            case "insured.iban":
                if (index !== undefined) {
                    formValues.insureds[index].iban.value = value;
                    formValues.insureds[index].iban.errorCode = "";
                }
                break;

            case "insured.signedDate":
                if (index !== undefined) {
                    formValues.insureds[index].sepa.signedDate.value = value;
                    formValues.insureds[index].sepa.signedDate.errorCode = "";
                }
                break;

            default:
                break;
        }
        this.setState({ formValues, copaySepa, anyChange: true });
    }

    handleRadioChange = (inputName: string, value: boolean, index?: number): void => {
        let { formValues, copaySepa, showCalendar } = this.state

        let changeDisabledsOfIsIbanCopaySameAsMain = (isIbanCopaySameAsMain: boolean) => {
            formValues.copayIban.disabled = this.props.isReadOnly || isIbanCopaySameAsMain;
            copaySepa.disabled = formValues.copayIban.disabled;
            copaySepa.isRequired = !copaySepa.disabled;
            if (formValues.copayIban.disabled) {

                formValues.copayIban.errorCode = "";
                formValues.copayIban.value = "";
            }
            if (copaySepa.disabled) {

                copaySepa.error = "";
                copaySepa.file = undefined;
            }
            if (inputName === 'areInsuredsCopayGrouped') {

                if (formValues.areInsuredsCopayGrouped.value) {
                    formValues.insureds.map((insured: any, index: number) => this.handleRadioChange('insured.isIbanSameAsMain', true, index))
                } else {
                    this.handleRadioChange("isIbanCopaySameAsMain", true)
                }
            }
        }

        let changeDisabledOfInsured = (insured: DentalPaymentMethodsInsuredState, disable: boolean, isCopayGrouped: boolean = false) => {
            let isIbanDisabled = disable || insured.isIbanSameAsMain.value;
            if (isCopayGrouped) {
                insured.isOpen = !disable;
                insured.isIbanSameAsMain.isDisabled = disable;
            }

            insured.iban.disabled = isIbanDisabled;
            insured.sepa.disabled = isIbanDisabled;
            insured.sepa.isSigned.isDisabled = isIbanDisabled;
            insured.sepa.signedDate.disabled = !insured.sepa.isSigned.value || isIbanDisabled;

            insured.isIbanRequired = !isIbanDisabled;
            insured.sepa.isRequired = !insured.sepa.isSigned.isDisabled && insured.sepa.isSigned.value;

            if (isIbanDisabled) {
                insured.iban.errorCode = "";
                insured.iban.value = "";
                insured.sepa.error = "";
                insured.sepa.file = undefined;
                insured.sepa.isSigned.isDisabled = true;
                insured.sepa.isSigned.value = false;
                insured.sepa.signedDate.errorCode = "";
                insured.sepa.signedDate.value = ""
            }
        }

        // HACK: Para que typescript no proteste por el tipo
        switch (inputName) {
            case "areInsuredsCopayGrouped":
                formValues[inputName].value = value;
                formValues.isIbanCopaySameAsMain.isDisabled = this.props.isReadOnly || !value;
                changeDisabledsOfIsIbanCopaySameAsMain(!value || formValues.isIbanCopaySameAsMain.value);
                formValues.insureds.forEach(insured => { changeDisabledOfInsured(insured, value, true) });
                showCalendar = !value
                break;

            case "isIbanCopaySameAsMain":
                formValues[inputName].value = value;
                changeDisabledsOfIsIbanCopaySameAsMain(value);
                break;

            case "insured.isIbanSameAsMain":
                if (index !== undefined) {
                    formValues.insureds[index].isIbanSameAsMain.value = value;
                    changeDisabledOfInsured(formValues.insureds[index], this.props.isReadOnly || value)
                }
                break;

            case "insured.isSigned":
                if (index !== undefined) {
                    formValues.insureds[index].sepa.isSigned.value = value;
                    formValues.insureds[index].sepa.signedDate.disabled = this.props.isReadOnly || !value;
                    formValues.insureds[index].sepa.isRequired = !formValues.insureds[index].sepa.signedDate.disabled;

                    if (formValues.insureds[index].sepa.signedDate.disabled) {
                        formValues.insureds[index].sepa.signedDate.errorCode = "";
                        formValues.insureds[index].sepa.error = "";
                    }
                }
                break;

            default:
                break;
        }
        this.setState({ formValues, copaySepa, showCalendar, anyChange: true });
    }

    onTabHeaderClick = (index: number): void => {
        let { formValues } = this.state
        formValues.insureds[index].isOpen = !formValues.insureds[index].isOpen;
        this.setState({ formValues });
    }

    onSignedDocumentFileChange(e: ChangeEvent<HTMLInputElement>) {
        const { signedDocument } = this.state;
        if (e.target.files && e.target.files.length > 0) {

            signedDocument.file = e.target.files[0];
            this.setState({ signedDocument });
        }

    }

    onMainSepaFileChange = (event: React.ChangeEvent<HTMLInputElement>, replaceFile: boolean = false): void => {
        let { mainSepa } = this.state;
        if (mainSepa.file && mainSepa.nodeId && !replaceFile) {
            this.setState({ replaceDocModalShow: true, replaceDocModalCallback: () => { this.onMainSepaFileChange(event, true); } });
            return;
        }
        let newSepa = this.onISepaFileChange(mainSepa, event);
        this.setState({ mainSepa: newSepa, anyChange: true });
    }

    onInsuredSepaFileChange = (index: number, event: React.ChangeEvent<HTMLInputElement>, replaceFile: boolean = false): void => {
        let { formValues } = this.state;
        if (formValues.insureds[index].sepa.file && formValues.insureds[index].sepa.nodeId && !replaceFile) {
            this.setState({ replaceDocModalShow: true, replaceDocModalCallback: () => { this.onInsuredSepaFileChange(index, event, true); } });
            return;
        }
        let newSepa = this.onISepaFileChange(formValues.insureds[index].sepa, event);
        formValues.insureds[index].sepa = newSepa;
        this.setState({ formValues, anyChange: true });
    }

    onISepaFileChange = (sepa: ISepaFileState, event: React.ChangeEvent<HTMLInputElement>): ISepaFileState => {
        if (event.target.files && event.target.files.length > 0) {
            sepa.file = event.target.files[0];
            sepa.error = "";
        } else {
            sepa.file = undefined;
        }
        return sepa;
    }

    onCancelSepaFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = "";
    }

    showErrorModal = (text: string = "Ha ocurrido un error.") => this.setState({ modalErrorShow: true, modalErrorText: text });
    hideErrorModal = () => this.setState({ modalErrorShow: false, modalErrorText: "" });

    async downloadDocument(id: string): Promise<void> {
        var result = await GestDocServices.getFile(this.props.setLoading, true, id);
        if (result && result.fileData) {
            downloadFile(result.fileData, result.fileName, result.fileType, true);
        } else {
            this.showErrorModal("Se ha producido un error al descargar el archivo.");
        }
    }



    async validateAndSave() {

        if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED)
            this.props.removeSign()

        let { formValues, mainSepa } = this.state;
        let { setLoading, cotizacion } = this.props;

        let mainSepaFile: File | undefined, copaySepaFile: File | undefined, insuredSepaFileArray: File[] | undefined;
        if (mainSepa.file) {
            mainSepaFile = mainSepa.file;
        } else if (mainSepa.nodeId) {
            let mainSepaGestDoc = await GestDocServices.getFile(this.props.setLoading, true, mainSepa.nodeId);
            mainSepaFile = new File([mainSepaGestDoc.fileData], mainSepaGestDoc.fileName);
        }


        const request: IPaymentMethod = {
            COTIZACION: cotizacion,
            PAGO_POLIZA: {
                MEDIO_PAGO: "BANC",
                IBAN: formValues.mainIban.value.split(' ').join(''),
                EXISTE_MANDATO_COBRO: mainSepaFile ? "S" : "N"
            },
            TIPO_FIRMA_DOC_PRECONTRACTUAL: formValues.isPhysicalSignature.value ? "NO" : "DI",
        };
        if (mainSepaFile) request.PAGO_POLIZA.FECHA_MANDATO_COBRO = moment(new Date()).format('DD/MM/YYYY').toString()
        let response = await DentalService.setPaymentMethod(setLoading, true, request, mainSepaFile, copaySepaFile, insuredSepaFileArray);

        let isOk = response && response.header && response.header.state === 200;
        if (response.date) {
            this.props.data.paymentMethodDate = response.date;
        }
        return isOk;
    }

    async setSendaPaymentMethod(): Promise<boolean> {
        if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED) {
            this.setState({ cancelSignModalShow: true })
            return false;
        }
        else {
            return await this.validateAndSave().then((result) => result)
        }
    }

    async setPaymentDataOnPolicy(): Promise<void> {
        const { formValues, mainSepa, copaySepa } = this.state;
        const { policy } = this.props 
        let values: { paymentMethod: PaymentMethod, insureds?: PaymentInsuredData[] };
        values = {
            paymentMethod: {
                mainIban: {
                    iban: formValues.mainIban.value,
                    sameAsMain: true,
                    sepaNodeId: mainSepa.nodeId,
                    sepaSignedDate: mainSepa.signedDate.value ? mainSepa.signedDate.value : moment().format(DateFormat),
                },
                isPhysicalSignature: formValues.isPhysicalSignature.value,
            },
        };

        if (policy.product.products_isCopay) {
            values.paymentMethod.areInsuredsCopayGrouped = formValues.areInsuredsCopayGrouped.value;
            if (formValues.areInsuredsCopayGrouped.value) {
                values.paymentMethod.copayIban = {
                    sameAsMain: formValues.isIbanCopaySameAsMain.value,
                    iban: !formValues.isIbanCopaySameAsMain.value ? formValues.copayIban.value : "",
                    sepaNodeId: !formValues.isIbanCopaySameAsMain.value ? copaySepa.nodeId : undefined,
                    sepaSignedDate: mainSepa.signedDate.value ? mainSepa.signedDate.value : moment().format(DateFormat),
                };
            } else {
                values.insureds = this.props.insuredsCopay;
                values.insureds.forEach((insured, index) => {
                    insured.copay = {
                        sameAsMain: formValues.insureds[index].isIbanSameAsMain.value,
                        iban: !formValues.insureds[index].isIbanSameAsMain.value ? formValues.insureds[index].iban.value : "",
                        sepaNodeId: !formValues.insureds[index].isIbanSameAsMain.value ? formValues.insureds[index].sepa.nodeId : undefined,
                        sepaSignedDate: !formValues.insureds[index].isIbanSameAsMain.value ? formValues.insureds[index].sepa.signedDate.value : undefined
                    };
                });
            }
        }

        await this.props.policy.setDentalData(values);
    }

    isFormDataValid(): boolean {
        let { formValues, mainSepa } = this.state
        let isDataValid = true;

        function resetErrors(formValues: IPaymentMethodsFormValuesState, mainSepa: ISepaFileState): void {
            formValues.mainIban.errorCode = '';
            formValues.mainIban.errorCode = '';
            mainSepa.error = '';
            formValues.copayIban.errorCode = '';
            formValues.copayIban.errorCode = '';
            mainSepa.error = '';
            formValues.insureds.forEach(insured => {
                insured.iban.errorCode = '';
                insured.iban.errorCode = '';
                insured.sepa.error = '';
                insured.sepa.signedDate.errorCode = '';
                insured.sepa.signedDate.errorCode = '';
            });
        }

        resetErrors(formValues, mainSepa);

        if (!formValues.mainIban.value) {
            formValues.mainIban.errorCode = 'required';
            isDataValid = false;
        }
        else if (!isValidIban(formValues.mainIban.value)) {
            formValues.mainIban.errorCode = 'invalid-iban';
            isDataValid = false;
        }

        this.setState({ formValues, mainSepa });
        return isDataValid;
    }

    async tryGoToPreviousStep(forceGoPrevious: boolean = false): Promise<void> {
        if (!this.state.anyChange || forceGoPrevious) {
            this.props.history.push(DentalHireMenuHelper.getPreviousStepNavigationPath(Steps.MetodosPago));
        }
        this.setState({ previousModalShow: true });
    }

    async tryGoToNextStep(): Promise<void> {
        if (this.props.isReadOnly) {
            this.props.history.push(DentalHireMenuHelper.getNextStepNavigationPath(Steps.MetodosPago));
            return;
        }
        if (!this.isFormDataValid()) {
            return;
        }
        let anyError: boolean = false;

        let isPaymentMethodSet = await this.setSendaPaymentMethod().catch((error) => {
            anyError = true;
            this.showErrorModal(`${(error && error.data && error.data.message) ?
                error.data.message : 'Se ha producido un error. Vuelva a intentarlo más tarde.'}`)
        });
        anyError = !isPaymentMethodSet;

        await this.setPaymentDataOnPolicy();
        if (!anyError) {
            this.props.saveHiring(false);
            this.props.history.push(DentalHireMenuHelper.getNextStepNavigationPath(Steps.MetodosPago));
        }
    }

    renderIban(iban: ValueFormHealth, sepaFile: ISepaFileState, isIbanRequired: boolean, onIbanChange: (value: string) => void, onSepaFileChange: (event: ChangeEvent<HTMLInputElement>) => void): JSX.Element {

        return <div>
            <div className="colSin-100 topMargin20">
                <div className="colSin-50 rightPadding20">
                    <div className="inputBox">
                        <InputBox
                            type={"iban"}
                            placeholder="iban"
                            required={isIbanRequired}
                            errorCode={iban.errorCode}
                            value={iban.value}
                            onChange={(value: string) => onIbanChange(value)}
                            disabled={iban.disabled}
                        />
                    </div>
                </div>

                <div className="colSin-50 rightPadding20">
                    <div className="inputBox custom-file-upload">

                        <div className={`colSin-70`}>
                            <input type="text" readOnly value={(sepaFile.file ? sepaFile.file.name : "")} disabled={sepaFile.disabled} title="SEPA" />
                        </div>

                        <div className="colSin-30">
                            <label className="custom-file-upload-button topMargin5">
                                <input type="file" onChange={onSepaFileChange} accept={acceptedFileMimeType.toString()} disabled={sepaFile.disabled} title="SEPA" />
                                <MainButton
                                    onClick={() => { return !sepaFile.disabled; }}
                                    text="Buscar"
                                    disabled={sepaFile.disabled}
                                />
                            </label>
                        </div>

                        <label>Adjuntar mandato SEPA</label>
                    </div>
                    {sepaFile.error && (
                        <div className="input-box-error-file">
                            <NoteText>
                                <p>{sepaFile.error}</p>
                            </NoteText>
                        </div>
                    )}
                </div>
            </div>

            <div className="colSin-100 topMargin10">
                {sepaFile.nodeId && (
                    <div className="pull-right rightPadding20 downloadSepaButton">
                        <MainButton
                            onClick={async () => { await this.downloadDocument(sepaFile.nodeId ? sepaFile.nodeId : ""); }}
                            icon={true}
                            iconImg={images.IconDownloadWhite}
                            text="Descargar documento SEPA"
                            disabled={sepaFile.disabled}
                        />
                    </div>
                )}
            </div>
        </div>
    }

    render() {
        const { isReadOnly } = this.props;
        const { formValues, mainSepa, showCalendar, cancelSignModalShow } = this.state;

        return (<DentalPaymentMethodsContainer showCalendar={showCalendar}>
            <div className="data-flow-status">
                <div className="data-flow-status-title">
                    <h2>{'MEDIOS DE PAGO'}</h2>
                </div>
            </div>

            <div className="content-wrapper bottomMargin20 filesSection">
                <div className="personal-data-wrapper">
                    <div className="data-title">
                        <h5>PAGO DEL SEGURO</h5>
                    </div>
                    {this.renderIban(formValues.mainIban, mainSepa, true, (value: string) => this.handleValueChange("mainIban", value), (event: React.ChangeEvent<HTMLInputElement>) => this.onMainSepaFileChange(event))}

                    <div className="colSin-100">

                        <DicotomicCheckbox
                            initChecked={formValues.isPhysicalSignature.value}
                            disabled={isReadOnly}
                            className={"optional"}
                            checkboxText={"Firma Física"}
                            onChange={(value: boolean) => this.handleCheckChange(value)}
                        />

                    </div>

                </div>

            </div>

            <div className="colSin-100 topMargin20 bottomMargin20">
                <div className="personal-data-buttons">
                    <div className="personal-data-buttons__item">
                        <MainButton
                            withTab={true}
                            icon={true}
                            type={"white"}
                            iconImg={images.IconArrowLeftBlue}
                            text="Atrás"
                            onClick={() => this.tryGoToPreviousStep()}
                        />
                    </div>
                    <div className="personal-data-buttons__item">
                        <MainButton
                            withTab={true}
                            text="Siguiente"
                            onClick={() => this.tryGoToNextStep()}
                        />
                    </div>
                </div>
            </div>

            <LeftInfoModal
                img={images.IconWarningYellow}
                mainTitle={"¿Desea sustituir el mandato sepa existente por el que acaba de seleccionar?"}
                mainText={"Perderá el anteior documento."}
                buttonText="Reemplazar"
                closeButtonText="Cancelar"
                close={() => this.setState({ replaceDocModalShow: false }, () => this.onCancelSepaFileChange)}
                active={this.state.replaceDocModalShow}
                onClickAccept={() => this.setState({ replaceDocModalShow: false }, () => this.state.replaceDocModalCallback)}
                className="warning"
            />
            <LeftInfoModal
                img={images.IconWarningYellow}
                mainTitle={"¿Seguro que desea volver atrás sin guardar?"}
                mainText={"Perderá todos los cambios realizados."}
                buttonText="Ir atrás"
                closeButtonText="Cancelar"
                close={() => this.setState({ previousModalShow: false })}
                active={this.state.previousModalShow}
                onClickAccept={() => this.setState({ previousModalShow: false }, () => this.tryGoToPreviousStep(true))}
                className="warning"
            />
            <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
                <div className="recover-modal">
                    <div className="server-error">
                        <p>{this.state.modalErrorText}</p>
                    </div>
                    <div className="button-error">
                        <MainButton text="Aceptar" onClick={this.hideErrorModal} />
                    </div>
                </div>
            </ErrorModalContainer>
            <LeftInfoModal
                img={images.IconWarningYellow}
                className="error"
                mainTitle={"¿Seguro que desea continuar?"}
                mainText={"Se cancelará la firma previamente solicitada"}
                buttonText="Continuar"
                closeButtonText="Cancelar"
                close={() => this.setState({ cancelSignModalShow: false })}
                active={cancelSignModalShow}
                onClickAccept={() => {
                    this.setState({ cancelSignModalShow: false }, () => this.validateAndSave())
                }}
            />
        </DentalPaymentMethodsContainer>);
    }
}

export default withPolicy(withGeneral(withRouter(DentalPaymentMethodsLayout)));
