import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../assets";
import { ErrorModalContainer } from "../../../../components/error-modal/error-modal-style";
import GuideLine from "../../../../components/guide-line";
import HealthCampaingItem from "../../../../components/health-campaign-item/health-campaign-item";
import HealthPriceItem from "../../../../components/health-price-item/health-price-item";
import MainButton from "../../../../components/main-button";
import { withPolicy } from "../../../../context/mixin/with-policy";
import { campaign } from "../../../hirings/new-hiring/health/health-price/health-price-dto/campaign-dto";
import { DentalPriceDataContainer } from "./travel-price-data-style"

interface DentalPriceProps extends RouteComponentProps {
  setLoading: Function;
  data: any;
  policy: any;
}

export interface DentalpriceTypeModel {
  payType: string;
  price: string;
  discount: string;
  taxes: string;
  total: string;
}

export interface selectedCampaign {
  code: string,
  name: string
}

export interface SelectedPriceInterface {
  discount: string;
  paymentTime: string;
  payType: string,
  price: string;
  taxes: string;
  total: string;
}

interface DentalPriceState {
  selectablePrices: { [id: string]: any };
  selectedPrice: SelectedPriceInterface;
  selectedCampaigns: selectedCampaign[];
  selectableCampaigns: campaign[];
  modalErrorShow: boolean;
  modalErrorText: string;
  responseSimulation: any;
}

class DentalPriceDataLayout extends React.Component<DentalPriceProps, DentalPriceState> {

  constructor(props: DentalPriceProps) {
    super(props);

    this.state = {
      selectablePrices: this.props.data.selectablePrices,
      selectedPrice: this.props.data.priceData.price,
      selectedCampaigns: this.props.data.priceData ? this.props.data.priceData.campaigns : [],
      selectableCampaigns: this.props.data.selectableCampaigns,
      modalErrorShow: false,
      modalErrorText: '',
      responseSimulation: this.props.data.resPrices
    };
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  };


  toValidate = async () => {
    const { history } = this.props;

    history.push("/simulacion/nueva/dental/resultado");

  }

  hideErrorModal = () => this.setState({ modalErrorShow: false, modalErrorText: '' });

  handlePriceRadioChange(time: string, payType: string, price: string, discount: string, taxes: string, total: string) {
    const value = {
      paymentTime: time,
      payType,
      price,
      discount,
      taxes,
      total
    }
    this.setState({ selectedPrice: value });
  }

  render() {

    const { selectedPrice, selectablePrices, selectedCampaigns, selectableCampaigns } = this.state;

    return (
      <DentalPriceDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">

            <h2>{'DATOS DEL SEGURO'}</h2>

          </div>
          <div className="data-flow-status-guide">
            <GuideLine
              steps={[0, 0, 0, 0]}
              currentStep={3}
            />
          </div>
        </div>

        <div className="content-wrapper bottomMargin20" ref={''}>
          <div className="personal-data-wrapper">
            <div className="data-title">
              <h5>CALCULO DE PRIMA ANUAL SEGÚN FORMA DE PAGO</h5>
            </div>
          </div>
          <div className="colSin-100">

            {Object.keys(selectablePrices).map((key, index) => (
              <HealthPriceItem
                paymentTime={key}
                price={selectablePrices[key].PRIMATOTAL}
                defaultChecked={key.toLocaleUpperCase() === selectedPrice.paymentTime.toLocaleUpperCase()}
                discount={selectablePrices[key].DESCUENTOS}
                onClick={
                  async (
                    time: string,
                    price: string,
                    discount: string
                  ) => {
                    this.handlePriceRadioChange(
                      time,
                      selectablePrices[key].PERIODO_COBRO,
                      price,
                      discount,
                      selectablePrices[key].TOTAL_IMPUESTOS,
                      selectablePrices[key].PRIMATOTAL)
                  }
                } />
            ))}

          </div>
        </div>

        <div className="content-wrapper bottomMargin20">

          {selectableCampaigns.map((b, index) => (
            <HealthCampaingItem
              campaignName={b.name}
              campaignDescription={b.description}
              campaignCode={b.code}
              defaultChecked={selectedCampaigns.some(campaign => campaign['code'] === b.code)}
              disabled={b.isRequired}
              onClick={() => { }}
            />)
          )}

        </div>

        <div className="colSin-100">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push("/simulacion/nueva/dental/asegurados")}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                text="Siguiente"
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>

        <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={this.hideErrorModal} />
            </div>
          </div>
        </ErrorModalContainer>

      </DentalPriceDataContainer>
    );
  }
}

export default withPolicy(withRouter(DentalPriceDataLayout));
