import React from "react";
import { SimulationResultContainer } from "./gip-result-style";
import { RouteComponentProps, withRouter } from "react-router-dom";
import WebText from "../../../../components/components-text/web-text";
import { NewSimulationContainer } from "../../../simulations/new-simulation/new-simulation-style";
import Title03Text from "../../../../components/components-text/title-03-text";
import NoteText from "../../../../components/components-text/note-text";
import MainButton from "../../../../components/main-button";
import { images } from "../../../../assets";
import { InflowSidebarContainer } from "../../../../components/inflow-sidebar/inflow-sidebar-style";
import { withGeneral } from "../../../../context/mixin/with-general";
import { withPolicy } from "../../../../context/mixin/with-policy";
import moment from "moment";
import { downloadFile, formatter } from "../../../../utils/utility";
import GoBack from "../../../../components/go-back";
import GipInsuredResultItem from "../../../../components/gip-insured-item/gip-insured-result-item";
import GipPaymentResultItem from "../../../../components/gip-result-item/gip-payment-result-item";
import { STORAGE_KEY } from "../../../../constants/health";
import { IUser } from "../../../../models/user-model";
import { IMediator } from "../../../../models/mediator-model";
import { GipHireMenuHelper } from "../../../../components/gip-menu/gip-hire-menu";
import { calcAge } from "../../../../utils/utility";
import { getDayMonthYear } from "../../../../utils/time";
import SimulationServices from "../../../../services/SimulationServices";
import LeftInfoModal from "../../../../components/left-info-modal";
import { getDataForm } from "../../../../utils/mapData";
import { withDynamicProducts } from "../../../../context/mixin/with-dynamic-products";
import GipServices from "../../../../services/GipServices";
import { formatInsured, formatPayments } from "../../../../utils/GIPSimulationDocument";
import { isValidEmail } from "../../../../utils/validation";

interface HealthSimulationResultLayoutProps extends RouteComponentProps {
  setLoading: Function;
  dataForm: any;
  data: any;
  policy: any;
  responseSimulation: any;
  user: IUser;
  mediator: IMediator;
  match: any;
  productsByAreaName: any;
}

interface HealthSimulationResultLayoutState {
  showLeftInfoModal: boolean,
  leftInfoModalClassName: string,
  leftInfoModalImg: string,
  leftInfoModalTitle: string,
  leftInfoModalText: string,
  leftInfoModalButtonText: string,
  leftInfoModalCloseButtonText: string,
  leftInfoModalClick: Function,
  leftInfoModalWithInput?: any;
  sendPDFEmail: {
    value: string,
    errorCode: string,
    labelText: string
  };
  documentSent: boolean,
}
class GipSimulationResultLayout extends React.Component<
  HealthSimulationResultLayoutProps,
  HealthSimulationResultLayoutState
> {
  constructor(props: HealthSimulationResultLayoutProps) {
    super(props);

    this.state = {
      showLeftInfoModal: false,
      leftInfoModalClassName: "",
      leftInfoModalImg: "",
      leftInfoModalTitle: "",
      leftInfoModalText: "",
      leftInfoModalButtonText: "",
      leftInfoModalCloseButtonText: "",
      leftInfoModalClick: () => { },
      leftInfoModalWithInput: null,
      sendPDFEmail: {
        value: "",
        errorCode: "",
        labelText: "Email"
      },
      documentSent: false,
    };
  }



  async componentDidMount() { }

  changeStep = (goBack: boolean = false) => {
    if (goBack) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/simulacion/nueva/decesos/asegurado");
    }
  }

  getBodySimulation = () => {
    const { data, policy, user } = this.props;
    const { sendPDFEmail, documentSent } = this.state;

    const loggedUser  = `${user.name} ${user.lastname1} ${user.lastname2}`;

    return {
      
      insuredData: data.insuredData,
      loggedUser,
      mediatorAssociatedToPolicy: data.mediator.mediatorCodeValue,
      mediatorData: {
        name: data.mediator.mediatorValue,
        startDate: data.mediator.startDateValue
      },
      policyType: policy.product.products_internalName,
      postalCode: data.mediator.postalCodeValue,
      priceData: data.selectedPrice,
      ...(sendPDFEmail.value && documentSent && 
        {taker: 
          {
          email: sendPDFEmail.value,
          }
        }
      ),
    }
  }

  async saveSimulation() {

    const { data, setLoading, policy } = this.props;
    const body = this.getBodySimulation();
    try {
      await SimulationServices.simulation({
        loader: true,
        setLoading: setLoading,
        data: {
          body: body,
          response: data.simulationResponse,
          productType: policy.product.products_internalName
        },
      });

    } catch (err) {
      this.setState({
        showLeftInfoModal: true,
        leftInfoModalClassName: "",
        leftInfoModalImg: images.WarningError,
        leftInfoModalTitle: "Error al guardar la simulación",
        leftInfoModalText: "",
        leftInfoModalButtonText: "Aceptar",
        leftInfoModalClick: () => {
          localStorage.removeItem(STORAGE_KEY.sKey);
          this.props.history.push("");
        },
      });
    }

    this.props.history.push("/simulacion/pendiente");
  }

  exit() {
    this.showExitModal();
  }

  openLeftInfoModal() {
    this.setState({ showLeftInfoModal: true });
  }

  closeLeftModal() {
    this.setState({
      showLeftInfoModal: false,
      leftInfoModalCloseButtonText: "",
      leftInfoModalWithInput: null,
    });
  }

  showExitModal = () => {
    this.setState({
      showLeftInfoModal: true,
      leftInfoModalClassName: "",
      leftInfoModalImg: images.WarningError,
      leftInfoModalTitle: "¿Desea salir del proceso?",
      leftInfoModalText: "",
      leftInfoModalButtonText: "Salir",
      leftInfoModalClick: () => {
        localStorage.removeItem(STORAGE_KEY.sKey);
        this.props.history.push("")
      },
    });
  };


  goBack() {
    const { policy } = this.props

    this.props.history.push(
      GipHireMenuHelper.getSimulationStepNavigationPath(
        GipHireMenuHelper.getSimulationStepFromNavigationRoute(
          this.props.match.url,
          policy.product.products_internalName
        ) - 1,
        policy.product.products_internalName
      )
    )
  }

  downloadDocument = async(email: boolean) => {
    const { data, policy } = this.props
    const { sendPDFEmail } = this.state

    try {
      const args = {
        loader: true,
        setLoading: () => { },
      };
      
      const requestData = {
        simulationDate: moment().format('DD/MM/YYYY').toLocaleString(),
        mediatorCode: data.mediator.mediatorCodeValue,
        mediatorName: data.mediator.mediatorValue,
        productName: policy.product.products_name,
        effectDate: moment(data.mediator.startDateValue).format('DD/MM/YYYY'),
        insureds: formatInsured(data.insuredData, data.priceDataInsuredSelected, data.mediator.postalCodeValue),
        payment: data.simulationResponse && data.simulationResponse.responseContent ?
          formatPayments(data.simulationResponse.responseContent.salida.ListaProductosResultado[0].ListaTarifasFormaPagoResultado)
          : [],
        productInternalName: policy.product.products_internalName,
        ...(email && {email: sendPDFEmail.value})
      }

      const document = await GipServices.getGIPSimulationDocument(args, requestData)

      if (document && !email) {
        downloadFile(document.fileData, document.fileName, document.fileType)
      }

    } catch (error) {
      this.showPDFModalError(!email)
    }
  }

  sendPdfEmailClick = async () => {
    const { sendPDFEmail } = this.state;
    this.setState({
      ...this.state,
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconContactCollapsedWhite,
      leftInfoModalClassName: "",
      leftInfoModalTitle: "Envío del PDF",
      leftInfoModalText: 'Por favor, ingrese un email para el envio del PDF',
      leftInfoModalButtonText: "Enviar",
      leftInfoModalClick: this.validateHandler,
      leftInfoModalWithInput: {
        ...sendPDFEmail,
        onChange: (value: string) => {
          this.setState({
            ...this.state,
            sendPDFEmail: { ...sendPDFEmail, value },
          })
        }
      }
    });
  };

  validateHandler = async () => {
    const { sendPDFEmail, leftInfoModalWithInput } = this.state;
    
    if (!sendPDFEmail.value || !isValidEmail(sendPDFEmail.value)) {
      this.setState({
        sendPDFEmail: {
          ...sendPDFEmail,
          errorCode: "invalid-email"
        },
        leftInfoModalWithInput: {
          ...leftInfoModalWithInput,
          errorCode: "invalid-email"
        }
      });
      return;
    }
    this.closeLeftModal();

    await this.downloadDocument(true)

    this.setState({
      ...this.state,
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconContactCollapsedWhite,
      leftInfoModalClassName: "",
      leftInfoModalTitle: "El pdf se ha enviado por correo",
      leftInfoModalText:
        "El presupuesto se ha enviado satisfactoriamente al correo suministrado.",
      leftInfoModalCloseButtonText: "Cerrar",
      leftInfoModalButtonText: "Aceptar",
      leftInfoModalClick: () => { },
      leftInfoModalWithInput: null,
      documentSent: true,
    });
    
  };



  showPDFModalError = (download: boolean) => {
    this.setState({
      ...this.state,
      showLeftInfoModal: true,
      leftInfoModalClassName: "error",
      leftInfoModalImg: images.WarningError,
      leftInfoModalTitle: `No se ha podido ${download ? 'descargar' : 'enviar por email'} el PDF`,
      leftInfoModalText:
        "Se ha producido un error en el servicio. Intentelo más tarde",
      leftInfoModalButtonText: "Cerrar",
      leftInfoModalClick: () => { },
    });
  }

  render() {
    const {leftInfoModalWithInput } = this.state 
    try {
      return (
        <NewSimulationContainer>

          <SimulationResultContainer>
            <div className="detail-pendant-simulation-back bottomMargin10">
              <GoBack text={"VOLVER A LA SELECCIÓN DE FORMA DE PAGO"} onClick={() => this.goBack()} />
            </div>
            <div className="colSin-100 height80"></div>
            <div className="topMargin20">
              <h5>
                Resultado de la Simulación
              </h5>
            </div>

            <div className="result-simulation-special">
              <div className="result-simulation-special-title">
                <Title03Text>
                  { this.props.policy.product ?
                    `Póliza ${this.props.policy.product.products_cardName}: ${this.props.policy.product.products_name}`
                    : 'Póliza'
                  }
                </Title03Text>
                <div>
                  <p className="price">
                    {formatter.format(this.props.data.priceDataSelected.TotalPrimaNeta)} <span> / {this.props.data.priceDataSelected.DesFormaPago}</span>
                  </p>
                </div>
              </div>


              <div className="result-simulation-special-container">
                <div className="result-simulation-special-item">
                  <div className="result-simulation-special-subitem">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Código de Cliente</p>
                      </NoteText>
                    </div>
                    <div className="result-simulation-special-subitem-content">
                      <WebText>
                        <p>
                          {this.props.data.policyData.colective}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-simulation-special-subitem">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Código de Producto</p>
                      </NoteText>
                    </div>
                    <div className="result-simulation-special-subitem-content">
                      <WebText>
                        <p>
                          {this.props.data.policyData.combiproduct}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-simulation-special-subitem">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Simulación</p>
                      </NoteText>
                    </div>
                    <div className="result-simulation-special-subitem-content">
                      <WebText>
                        <p>
                          {moment().format('DD/MM/YYYY').toLocaleString()}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-simulation-special-subitem">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Expira</p>
                      </NoteText>
                    </div>
                    <div className="result-simulation-special-subitem-content">
                      <WebText>
                        <p>
                          {moment().add(1, 'month').format('DD/MM/YYYY').toLocaleString()}
                        </p>
                      </WebText>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="result-simulation-container">
              <div className="result-simulation-item float">
                <div className="result-simulation-item-title">
                  <Title03Text>
                    <p>Desglose por forma de pago</p>
                  </Title03Text>
                </div>
                <div className="result-simulation-item-content-table">
                  <div className="tabs">
                    <GipPaymentResultItem
                      key={1}
                      yearlyPrice={this.props.data.priceDataSelected.TotalPrimaNetaAnio}
                      paymentType={this.props.data.priceDataSelected.DesFormaPago}
                      priceData={this.props.data.priceData}
                      checked={false}
                    />
                  </div>
                </div>
              </div>

              <div className="result-simulation-item float">
                <div className="result-simulation-item-title">
                  <Title03Text>
                    <p>Miembros de la póliza</p>
                  </Title03Text>
                </div>
                <div className="result-simulation-item-content-table">
                  <div className="tabs">
                    {
                      this.props.data.insuredData.map((insured: any, index: number) => (
                        <GipInsuredResultItem
                          age={calcAge(getDayMonthYear(insured.birthDate).toString())}
                          birthDate={insured.birthDate}
                          checked={false}
                          id={this.props.data.insuredData.length - index}
                          index={index + 1}
                          name={`Asegurado ${index + 1}`}
                          key={index}
                          paymentType={this.props.data.priceDataInsuredSelected[index].DesFormaPago}
                          totalPrice={this.props.data.priceDataInsuredSelected[index].TotalPrimaNeta}
                          priceData={this.props.data.priceDataInsured[index]}
                          onClick={() => 'this.handleRemoveInsured(index)'}

                        />))}
                  </div>
                </div>
              </div>
            </div>

            <div className="result-simulation-footer">
              <ul>
                <NoteText>
                  <li key="">
                    * Impuesto sobre las primas de seguro incluido.
                  </li>
                </NoteText>
              </ul>
            </div>

            {true ? (
              <div>
                <div className="detail-pendant-simulation-divider" />
                <div className="detail-pendant-simulation-footer">
                  <ul>
                    <NoteText>
                      <li>
                        * Encontrará la nota informativa anexa en el documento
                        Resultado de Simulación PDF, que puede descargar
                      </li>
                    </NoteText>
                    <NoteText>
                      <li>
                        * Este presupuesto tiene validez durante 30 días naturales,
                        siempre que se mantengan las circunstancias declaradas por
                        los asegurados. La aceptación del proyecto está supeditada a
                        la cumplimentación de la solicitud de seguro y cuestionarios
                        de salud asegurados. Así mismo, ASISA
                        se reserva el derecho de la aceptación del riesgo.
                      </li>
                    </NoteText>
                  </ul>
                </div>
              </div>
            ) : ""}
          </SimulationResultContainer>

          <InflowSidebarContainer>
            <div className="inflow-sidebar">
              <div className="inflow-buttons-container">
                <div className="inflow-buttons-item">
                  <MainButton
                    type="full-light"
                    text="Guardar Simulación"
                    icon
                    iconImg={images.IconSaveWhite}
                    onClick={() => this.saveSimulation()}
                  />
                </div>
                <div className="inflow-buttons-item">
                  <MainButton
                    type="full-light"
                    text="Descargar PDF"
                    icon
                    iconImg={images.IconDownloadWhite}
                    onClick={() => this.downloadDocument(false)}
                  />
                </div>
                <div className="inflow-buttons-item">
                  <MainButton
                    type="full-light"
                    text="Enviar PDF por Email"
                    icon
                    iconImg={images.IconContactCollapsedWhite}
                    onClick={() => this.sendPdfEmailClick()}
                  />
                </div>
                <div className="inflow-buttons-item">
                  <MainButton
                    /* disabled={
                      !responseSimulation.documents ||
                      !responseSimulation.documents[0]
                    } */
                    type="full-light"
                    text="Terminar y Salir"
                    onClick={() => this.showExitModal()}
                  />
                </div>
              </div>
            </div>
          </InflowSidebarContainer>

          {this.state.showLeftInfoModal ?
            (
              <LeftInfoModal
                img={this.state.leftInfoModalImg}
                className={this.state.leftInfoModalClassName}
                mainTitle={this.state.leftInfoModalTitle}
                mainText={this.state.leftInfoModalText}
                buttonText={this.state.leftInfoModalButtonText}
                close={() => this.closeLeftModal()}
                active={this.state.showLeftInfoModal}
                onClickAccept={this.state.leftInfoModalClick}
                closeButtonText={this.state.leftInfoModalCloseButtonText}
                withInput={leftInfoModalWithInput}
              />
            ) : ""}

        </NewSimulationContainer >
      );
    }
    catch {
      const { history } = this.props;
      history.replace("");
      return null;
    }
  }
}

export default withGeneral(withDynamicProducts(withPolicy(withRouter(GipSimulationResultLayout))));
