import styled from "styled-components";
import { colors } from "../../../../assets";
import { BodyTextContainer } from "../../../../components/components-text/body-text/body-text-styles";
import { NoteTextContainer } from "../../../../components/components-text/note-text/note-text-styles";
import { images } from "../../../../assets/images";

export const SimulationResultContainer = styled.div`
  padding: 0px 50px;
  .result-hero-container {
    margin-bottom: 40px;
    color: ${colors["PR-001-100"]};
    font-family: "museo sans";
    display: flex;
    justify-content: space-between;
    /* padding: 0 30px; */
  }

  .result-hero-type {
    font-weight: 700;
    p {
      font-size: 10px;
      text-transform: uppercase;

      span {
        margin-top: 6px;
        color: ${(props) => colors[props.colorSpan]};
        font-size: 24px;
        display: block;
        text-transform: initial;
      }
    }
  }

  .result-hero-data {
    text-align: right;
    font-family: "museo sans";
    font-size: 12px;
    font-weight: 300;

    &__name {
      font-weight: 500;
      font-size: 14px;
    }

    p {
      margin-bottom: 6px;
    }
  }

  .result-welcome {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    margin-bottom: 64px;
    padding: 0 40px;

    &__title {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 28px;
    }
    &__description {
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .result-simulation-back {
    margin-bottom: 95px;
  }

  .result-simulation-special {
    padding: 40px;
    margin-bottom: 24px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
  }

  .result-simulation-special-title {
    color: ${colors["PR-001-100"]};
    border-bottom: 1px solid ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p.price {
      float: right;
      font-size: 36px;
      color: #004f8b;
      text-align: end;
      padding-left: 16px;

      span {
        font-size: 28px;
      }
    }
  }

  .result-simulation-special-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
  }

  .result-simulation-special-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .result-simulation-special-subitem {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  .result-simulation-special-subitem-title {
    text-transform: uppercase;
    color: ${colors["TX-001-30"]};
    margin-bottom: 6px;
  }

  .result-simulation-special-subitem-content {
    color: ${colors["TX-001-100"]};
  }

  .result-simulation-item-content-table
    .table
    .main-table-row:first-child
    td
    > div {
    padding-top: 0;
  }
  .float-right-cell {
    float: right;
  }
  .result-simulation-item-content-table {
    margin-bottom: 75px;
    min-width: 100%;
    max-width: 1250px;
    overflow-x: auto;

    .table {
      min-width: 840px;

      .main-table-row td {
        padding: 0 15px;
      }

      th {
        padding: 30px 15px 0;
      }

      .entire-mini-title {
        font-size: 16px !important;
      }

      .dimmed-mini-title {
        font-size: 16px !important;
      }

      .dimmed-mini-body {
        font-size: 16px !important;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    thead {
      border-bottom: 1px solid ${colors["PR-001-100"]};
    }

    th {
      padding: 10px 15px;
      color: ${colors["TX-001-30"]};
      .highlighted {
        color: ${colors["TX-001-50"]};
      }
    }

    td {
      > div {
        padding: 8px 0;
      }

      ${BodyTextContainer} {
        display: inline-block;
        &.highlighted {
          font-weight: 600;
        }
      }
    }

    .main-table-row {
      &:first-child {
        td {
          > div {
            padding-top: 20px;
          }
        }
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  .result-simulation-item-content-advise {
    color: ${colors["TX-001-30"]};
  }

  .result-simulation-item-content-capital-text {
    p {
      color: ${colors["TX-001-50"]};
    }

    span {
      color: ${colors["TX-001-100"]};
      margin-left: 8px;
    }
  }

  .result-simulation-container {
    margin-bottom: 40px;
  }

  .result-simulation-item {
    padding: 40px;
    margin-bottom: 24px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
    
    &.float {
      width: 100%;
      display: block;
      float: left;
    }

    .result-simulation-item-content {
      h5 {
        color: ${colors["PR-001-100"]};
        padding-left: 30px;
        position: relative;
        font-size: 24px;

        &:before {
          content: url(${images.IconCheckBlue});
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-right: 8px;
          position: absolute;
          left: 0;
          top: 1px;
        }
      }
    }
  }

  .result-simulation-item-title {
    color: ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 24px;
  }

  .result-simulation-footer {
    padding-top: 37px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 80px;
    color: ${colors["PR-001-100"]};
    border-top: 1px solid ${colors["PR-001-100"]};

    ${NoteTextContainer} {
      line-height: 1.5em;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  .detail-pendant-simulation-divider {
    border: 0.5px solid ${colors["PR-001-100"]};
    height: 0;
    width: 100%;
    margin-top: 40px;
  }

  .detail-pendant-simulation-footer {
    padding-top: 37px;
    margin-bottom: 40px;
    color: ${colors["PR-001-100"]};
    padding-left: 40px;
    padding-right: 40px;
    ${NoteTextContainer} {
      line-height: 1.5em;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .tabs {
    overflow: hidden;

    .tab {
      width: 100%;
      overflow: hidden;
      position: relative;

      .delete {
        width: 24px;
        height: 24px;
        line-height: 24px;
        position: absolute;
        left: calc(100% - 170px);
        top: 18px;
        background-color: #3f3f3f;
        color: #fff;
        border-radius: 100%;
        text-align: center;
        cursor: pointer;
        z-index: 2;

        &:before {
          content: url(${images.IconCloseWhite});
        }

        &:hover {
          background-color: #004a8f;
        }
      }

      .tab-label {
        display: flex;
        justify-content: space-between;
        padding: 1em;
        background: #fff;
        color: #3f3f3f;
        border: 1px solid #f4f4f4;
        font-size: 18px;
        height: 60px;

        &:after {
          content: "❯";
          width: 30px;
          height: 30px;
          text-align: center;
          transition: all 300ms;
          transform: rotate(90deg);
          border-radius: 100%;
          text-align: center;
          line-height: 30px;
          margin-top: -5px;
        }

        span {
          transition: all 300ms;
        }
      }

      .tab-content {
        height: 0;
        padding: 0 1em;
        color: #2c3e50;
        background: #fff;
        transition: all 300ms;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
      }

      .tab-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        background: #2c3e50;
        cursor: pointer;

        &:hover {
          background: #1a252f;
        }
      }

      input[type="checkbox"] {
        position: absolute;
        right: 2px;
        opacity: 0;
        width: 60px;
        height: 60px;
        cursor: pointer;
        z-index: 1;
        margin: 0;

        &:hover {
          + {
            .tab-label:after {
              background-color: #efefef;
            }
          }
        }

        &:checked {
          + {
            .tab-label {
              font-weight: bold;
            }

            .tab-label:after {
              transform: rotate(270deg);
            }
          }

          ~ .tab-content {
            min-height: 70px;
            height: auto;
            padding: 25px 15px 20px;
            opacity: 1;
            box-shadow: 0px 4px 10px -3px #e0e0e0;
          }
        }
      }
    }
  }


  .checkbox-container input:not(:checked) {
    color: ${colors["TX-001-50"]};
  }

  outline: none;
  
  &.mandatory {
    .checkbox-container input:checked ~ .checkmark-container {
      background: #f7f7f7;

      .checkmark-item {
        background: ${colors["TX-001-80"]};
      }
    }
  }

  &.optional {
    .checkbox-container input:checked ~ .checkmark-container {
      background: ${colors["SU-001-10"]};

      .checkmark-item {
        background: ${colors["SU-001-100"]};
      }
    }
  }

  .checkbox-container {
    display: flex;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 15px;

    &.noPointer {
      cursor: initial;
    }

    div:nth-of-type(2) {
      line-height: 25px;
      font-size: 16px;
    }

    &:hover {
      input ~ .checkmark {
        background-color: rgba(0, 79, 139, 0.3);
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark-container {
        background: #e8f9f5;
        justify-content: flex-end;

        .checkmark-item {
          background: #40caa9;
        }

        img {
          max-width: 14px;
        }
      }
  }

  .checkbox-error {
    color: red;
    display: block;
  }

  .checkmark-container {
    min-width: 24px;
    background: #ffffff;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 14px;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;

    img {
      max-width: 14px;
    }
  }

  .checkmark-item {
    background: ${colors["TX-001-30"]};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    width: 20px;
    height: 20px;    
  }

  .checkmark-container-readonly {
    min-width: 24px;
    background: #ffffff;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 14px;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;

    img {
      max-width: 14px;
    }

    .checkmark-item {
      background: #3f3f3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      width: 20px;
      height: 20px;    
    }
  }
`;
