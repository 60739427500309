import styled from "styled-components";
import { colors } from "../../assets";

export const NotificationsContainer = styled.div`
  .back-arrow-container {
    margin-bottom: 18px;
  }
  .main-title-container {
    margin-bottom: 38px;
    color: ${colors["PR-001-100"]};
  }

  .main-filter-container {
    display: flex;
    width: 100%;
    margin-bottom: 70px;
  }

  .main-filter-item {
    width: 100%;
    margin-right: 36px;
    max-width: 160px;
  }

  .main-results-number {
    color: ${colors["TX-001-100"]};
    margin-bottom: 40px;
    span {
      font-weight: 600;
    }
  }

  .notifications-table {
    margin-bottom: 75px;
    tr {
      cursor: pointer;
    }
  }

  .notifications-pagination {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
  }
`;
