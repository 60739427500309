import React from 'react';
import { images } from '../../../assets';
import Loader from '../../loader';
import MainModal from '../../main-modal';

interface ExternalAuthState {
  isUserError: boolean;
  isGenericError: boolean;
  isDecodeError: boolean;
}

const EXTERNAL_AUTH_ERRORS = {
  userError: "No se ha podido completar la validación del proceso de autenticación.",
  decodeError: "Se ha producido un error con la autenticación.",
  generic: "Se ha producido un error durante el proceso de autenticación.",
}

const jwt = require('jsonwebtoken');

export default class ExternalAuth extends React.Component< {} , ExternalAuthState> {

  constructor(props: any) {
    super(props);
    this.state = {
      isUserError: false,
      isGenericError: false,
      isDecodeError: false,
    };
  }

  async componentDidMount() {
    try {
      const url = new URL(window.location.href);
      const authtoken = url.searchParams.get("authtoken");
      const error = url.searchParams.get("error");
      if (authtoken) {
        this.cleanLocalStorage();
        const { user } = jwt.decode(authtoken);
        localStorage.setItem('accessToken', authtoken);
        localStorage.setItem('userName', user);
        window.location.href = "/";
      }
      else if (error) error === "user" ? this.setIsUserError() : this.setIsGenericError();
      else window.location.href = "/";
    } catch(e) {
      console.error(e);
      this.setIsDecodeError();
    } 
  }

  cleanLocalStorage() {
    const cookiesStorage = localStorage.getItem("acceptedCookies");
    localStorage.clear();
    if (cookiesStorage) {
      localStorage.setItem("acceptedCookies", cookiesStorage);
    }
  }

  setIsUserError = () => this.setState({ isUserError: true });

  setIsGenericError = () => this.setState({ isGenericError: true });

  setIsDecodeError = () => this.setState({ isDecodeError: true });

  render() {
    const { isUserError, isGenericError, isDecodeError } = this.state;
    return (
    <>
      { <Loader />}
      { (isUserError || isGenericError || isDecodeError) && 
        <MainModal
          mainTitle={isUserError ? EXTERNAL_AUTH_ERRORS.userError : isDecodeError ? EXTERNAL_AUTH_ERRORS.decodeError : EXTERNAL_AUTH_ERRORS.generic }
          buttonText={"Ir a Inicio"}
          centered={true}
          active={true}
          img={images.WarningError}
          className={"warning"}
          close={() => window.location.href = "/" }
          onClickAccept={() => window.location.href = "/" }
        />
      }
    </>
    );
  }
}
