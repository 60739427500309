import React from "react";
import { images, colors } from "../../assets";
import CampaignProgress from "../../components/campaign-progress/campaign-progress";
import CampaignSidebar from "../../components/campaign-sidebar/campaign-sidebar";
import Title02Text from "../../components/components-text/title-02-text";
import { CampaignsDetailContainer } from "./campaigns-detail-style";
import GoBack from "../../components/go-back";
import MainModal from "../../components/main-modal";
import CardCampaignWeek from "../../components/card-campaign-week/card-campaign-week";
import Body02Text from "../../components/components-text/body-02-text";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ICampaign } from "../../models/campaigns-model";
import CampaignsConfigurationServices from "../../services/CampaignsConfigurationServices";
import moment from "moment";
interface CampaignsPropsDetail extends RouteComponentProps {
  match: any;
}
interface CampaignsDetailState {
  renderModal: string;
  campaign?: ICampaign;
}
const tableModal = {
  title: "Tus resultados a 31/12/2019",
  content: [
    { title: "Primas salud", value: "15.000" },
    { title: "Puntos dental", value: "760" },
    { title: "Puntos hospitales", value: "900" },
    { title: "Puntos vida", value: "300" },
    { title: "Puntos decesos", value: "50" }
  ]
};
class CampaignsLayout extends React.Component<
  CampaignsPropsDetail,
  CampaignsDetailState
> {
  constructor(props: CampaignsPropsDetail) {
    super(props);
    this.state = {
      renderModal: "",
      campaign: undefined
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.getCampaign(id);
  }

  async getCampaign(id: string) {
    const args = {
      id,
      setLoading: () => {}
    };
    const campaign = await CampaignsConfigurationServices.getCampaign(args);
    this.setState({ campaign });
  }

  render() {
    const { renderModal, campaign } = this.state;
    const prizesCond =
      campaign !== undefined
        ? campaign.prizes.map((prize: any) => prize.conditions)
        : [];
    const prizes =
      campaign !== undefined
        ? campaign.prizes.map((prize: any) =>
            prize.conditions.map((condition: any) => ({
              value: condition.value,
              unit: condition.unit
            }))
          )
        : [];
    return (
      <>
        <CampaignsDetailContainer>
          <div className="back-arrow-container">
            <GoBack />
          </div>
          {/* <div className="new-hiring-flow-status">
            <div className="new-hiring-flow-status-title">
              <HeadsTitleText>
                <p>Segundo mes</p>
              </HeadsTitleText>
            </div>
          </div> */}
          <div className="campaigns-section-title">
            <Title02Text>Campaña Viaje de incentivos 2019</Title02Text>
            <div className="campaigns-section-title__subtitle">
              <Body02Text color={colors["PR-001-50"]}>
                {"02/03/2019 — 28/04/2019"}
              </Body02Text>
            </div>
          </div>

          <div className="campaigns-section-progress">
            <CampaignProgress prizes={prizesCond} />
          </div>

          <div className="campaigns-section-subtitle">
            {/* <div className="campaigns-section-subtitle__text">
              <Title02Text>
                Abril <span>(12/04/2019)</span>
              </Title02Text>
            </div> */}
            <div
              className="campaigns-section-subtitle__refresh"
              onClick={() => {}}
            >
              <img src={images.IconRefresh} alt="" />
              <div className="campaigns-section-subtitle__refresh__text">
                {"VER CONVERSION"}
              </div>
            </div>
          </div>

          <div className="campaigns-card-week-cards">
            <CardCampaignWeek type={"blue"} date={moment().toISOString()} />
          </div>
        </CampaignsDetailContainer>
        <MainModal
          buttonText={"fin de campaña"}
          img={images.IconStarGreen}
          mainTitle={"¡Enhorabuena! Conseguiste el objetivo de la campaña"}
          mainText={
            "Con tu puntuación puedes optar al primer premio. La revisaremos y nos pondremos en contacto contigo, por favor, marca el botón de Fin de Campaña para ello. Mira todo lo que has conseguido:"
          }
          active={renderModal === "modal-campaign-success"}
          close={() => this.setState({ renderModal: "" })}
          table={tableModal}
          buttonClassName={"green"}
          onClickAccept={() => {}}
        />
        <MainModal
          buttonText={"Añadir"}
          img={images.iconCampaignsBlue}
          mainTitle={"Añadir primas de salud"}
          mainText={
            "Introduce el valor de las primas que has conseguido y se sumará a tu progreso, recuerda que con tu esfuerzo, llegarás al objetivo final"
          }
          active={renderModal === "modal-count-campaign"}
          close={() => this.setState({ renderModal: "" })}
          buttonClassName={"blue"}
          inputCount={"hola"}
          onClickAccept={() => {}}
        />
        <CampaignSidebar prizes={prizes} />
      </>
    );
  }
}

export default withRouter(CampaignsLayout);
