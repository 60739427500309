import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import NoteText from "../../../../../components/components-text/note-text";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import { GipHireMenuHelper } from "../../../../../components/gip-menu/gip-hire-menu";
import { HealthHireMenuHelper, Steps } from "../../../../../components/health-hire-menu/health-hire-menu";
import InputBox from "../../../../../components/input-box/input-box";
import LeftInfoModal from "../../../../../components/left-info-modal";
import MainButton from "../../../../../components/main-button";
import SelectBox from "../../../../../components/select-box/select-box";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { DocumentType, GestDocFileInfoResponseDto } from "../../../../../models/gest-doc-models";
import { IMediator } from "../../../../../models/mediator-model";
import { IUser } from "../../../../../models/user-model";
import GestDocServices from "../../../../../services/Gest-Doc.Services";
import { GestDoc } from "../../../../../services/GestDoc";
import HealthService from "../../../../../services/HealthService";
import { ConstantsResponseDto, DocumentDto } from "../../../../../services/HealthService-dto";
import { changeFileName, downloadFile, getFileExtension } from "../../../../../utils/utility";
import { RequiredDocDataContainer } from "./gip-required-doc-data-style";
import { acceptedFileMimeType } from "../../../../../constants/acceptedFileMimeType";

var mime = require('mime-types')

interface Associate {
  index: number,
  name: string,
  document: string,
  [key: string]: any;
}

interface NewDocument {
  docType: string,
  description?: string,
  file?: File,
  associatedTo: string,
}

interface HealthDocument {
  id?: string,
  name: string,
  description?: string,
  type: string,
  associatedTo: string,
  file?: File
}

interface RequiredDocProps extends RouteComponentProps {
  user: IUser;
  saveHiring: Function;
  setLoading: Function;
  readOnly: boolean;
  taker: any;
  insureds: any[];
  cotizacion: string;
  data: any;
  mediator: IMediator;
  match: any;
}

interface RequiredDocState {
  newDocument: NewDocument,
  readOnly: boolean,
  uploadedDocuments: HealthDocument[],
  documentTypes: ConstantsResponseDto[],
  associates: Associate[],
  modalErrorShow: boolean,
  modalErrorText: string,
  showConfirmRemoveDocumentModal: boolean,
  showConfirmRemoveDocumentModalId: number,
  areTakerDocumentIdImagesUploaded: boolean,
}

class GipRequiredDocDataLayout extends React.Component<RequiredDocProps, RequiredDocState> {

  constructor(props: RequiredDocProps) {
    super(props);

    let associates: Associate[] = [];
    if (props.insureds && props.insureds.length) {
      associates = props.insureds.map((insured, index) => (
        {
          index: index,
          name: insured.name,
          document: insured.docNumber,
          title: `${insured.name} ${insured.lastname1} (${insured.docNumber})`
        }
      ));
    }
    if (props.taker.docNumber && associates.filter((i) => i.document === props.taker.docNumber).length === 0) {
      associates.unshift({
        index: -1,
        name: props.taker.name,
        document: props.taker.docNumber,
        title: `${props.taker.name} ${props.taker.lastname1} (${props.taker.docNumber})`
      });
    }
    associates.unshift({
      index: -2,
      name: "",
      document: "",
      title: "Ninguno"
    });

    this.state = {
      newDocument: {
        docType: "",
        description: "",
        file: undefined,
        associatedTo: ""
      },
      readOnly: this.props.readOnly,
      uploadedDocuments: [],
      documentTypes: [],
      associates: associates,
      modalErrorShow: false,
      modalErrorText: "",
      showConfirmRemoveDocumentModal: false,
      showConfirmRemoveDocumentModalId: -1,
      areTakerDocumentIdImagesUploaded: false
    };
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);

    /* if (!this.state.documentTypes.length) {
      this.loadDocumentTypes();
    }
    this.loadHealthDocumentsFromGestDoc(); */
  };

  loadDocumentTypes = async () => {
    let documentTypes = await HealthService.getDocumentTypes({ setLoading: this.props.setLoading, loader: true });
    this.setState({ documentTypes: documentTypes });
  }

  loadHealthDocumentsFromGestDoc = async () => {
    const { setLoading, data } = this.props;

    let entries: GestDocFileInfoResponseDto[] = [];
    if (this.state.associates.length && data && data.taker) {
      //Buscamos las fotocopias del DNI subidas
      let peopleGestDocDocuments = await GestDocServices.searchFile(setLoading, true,
        GestDoc.getPeopleDocumentsRequest(
          data.taker
        )
      )
      await data.insureds.forEach(async (insured: any) => {
        const response = await GestDocServices.searchFile(setLoading, true,
          GestDoc.getPeopleDocumentsRequest(
            undefined, [insured]
          )
        )
        if (response && response.list && response.list.entries && response.list.entries.length > 0)
          peopleGestDocDocuments.list.entries.concat(response.list.entries)
      });
      if (peopleGestDocDocuments.list.entries.length) {
        entries = entries.concat(peopleGestDocDocuments.list.entries);
      }
    }

    //Buscamos últimos recibos pagados
    let lastRecipe = await GestDocServices.searchFile(setLoading, true,
      GestDoc.getLastReceiptDocumentsRequest(data.taker)
    )

    if (lastRecipe.list.entries.length) {
      entries = entries.concat(lastRecipe.list.entries);
    }
    //TODO: Falta buscar otro tipo de documentación

    let uploadedDocuments: HealthDocument[] = [];
    await Promise.all(entries.map(async (ele) => {
      const fileInfo = (await GestDocServices.getFileInfo(this.props.setLoading, true, ele.entry.id));
      const associated = fileInfo.entry.properties["ins2:takerNif"];
      uploadedDocuments.push({
        id: ele.entry.id,
        name: ele.entry.name,
        description: "",
        type: this.getElementType(fileInfo.entry.properties["ins2:documentType"]),
        associatedTo: associated ? this.getAssociated(associated) : ''
      })
    }));
    this.setState({ uploadedDocuments: uploadedDocuments }, () => this.checkTakerDocumentImagesUploaded())
  }

  getAssociated(nif: string): string {
    const { data } = this.props;
    if (data.taker.docNumber === nif) {
      return '0'
    }

    data.insureds.forEach((insured: any, index: number) => {
      if (insured.docNumber === nif)
        return (index + 1).toString()
    })
    return ''
  }

  getElementType(documentType: DocumentType | string | undefined): string {
    if (documentType) {
      switch (documentType) {
        case "Documento indentificativo de cliente (NIF)":
          return 'DR04';

        case "Documento recibo compañía anterior":
          return 'DR26';

        case "Cuestionario de salud":
          return 'TR01';

        //TODO: Case para otro tipo de documentación
        /* case DocumentType[""]:
          return 'DR31'; */

        default:
          return ''
      }
    }
    return ''
  }

  tryGoToNextStep = async () => {
    if (this.state.readOnly) {
      this.props.history.push(
        GipHireMenuHelper.getStepNavigationPath(
          GipHireMenuHelper.getStepFromNavigationRoute(
            this.props.match.url,
            GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
          ) + 1,
          this.props.data.insureds.lenght,
          GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
        )
      );
    }

    const error = await this.sendDocumentToSenda();
    if (!error) {
      //await this.checkTakerDocumentImagesUploaded();
      if (/* this.state.areTakerDocumentIdImagesUploaded */true) {

        this.props.saveHiring();
        this.props.history.push(
          GipHireMenuHelper.getStepNavigationPath(
            GipHireMenuHelper.getStepFromNavigationRoute(
              this.props.match.url,
              GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
            ) + 1,
            this.props.data.insureds.lenght,
            GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
          )
        );
        return;
      }
      this.setState({ modalErrorShow: true, modalErrorText: "Para poder continuar es necesario adjuntar el anverso y el reverso del DNI del tomador." })
    }
  }

  async checkTakerDocumentImagesUploaded() {
    let areTakerDocumentIdImagesUploaded = false;
    let documentIdTaker = this.state.uploadedDocuments
      .filter((doc) => (doc.associatedTo === this.props.taker.docNumber)
        || doc.associatedTo === '0');

    if (documentIdTaker.length >= 1) {
      areTakerDocumentIdImagesUploaded = true;
    }

    if (this.state.areTakerDocumentIdImagesUploaded !== areTakerDocumentIdImagesUploaded)
      this.setState({ areTakerDocumentIdImagesUploaded: areTakerDocumentIdImagesUploaded });
  }

  onDocumentTypeChange(value: string) {
    const { newDocument } = this.state;
    newDocument.docType = value;
    this.setState({ newDocument: newDocument });
  }

  onAssociatedToChange(value: string) {
    const { newDocument } = this.state;
    newDocument.associatedTo = value ? value : "";
    this.setState({ newDocument: newDocument });
  }

  onDescriptionChange(value: string) {
    const { newDocument } = this.state;
    newDocument.description = value;
    this.setState({ newDocument: newDocument });
  }

  onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { newDocument } = this.state;
    if (event.target.files && event.target.files.length > 0) {
      newDocument.file = event.target.files[0];
      this.setState({ newDocument });
    }
  };

  isAdjuntarDocumentoButtonDisabled = (): boolean => {
    const { newDocument } = this.state;
    const { readOnly } = this.props;
    return readOnly || !(newDocument.file && newDocument.docType)
  }

  async uploadDocument() {
    const { newDocument, associates } = this.state;

    if (!(newDocument.file && newDocument.docType)) {
      this.showErrorModal("Primero debe seleccionar un archivo para subir y un tipo.");
      return;
    }

    let fileExtension = getFileExtension(newDocument.file.name);
    let mimeType = mime.lookup(fileExtension);

    if (!(acceptedFileMimeType.includes(mimeType) || mimeType.includes('image'))) {
      this.showErrorModal(`No se permite la extensión ${fileExtension} del fichero introducido. Revise los tipos aceptados.`);
      return;
    }

    let newFileName = `${newDocument.docType}${newDocument.associatedTo
      ? `_${newDocument.associatedTo}`
      : ""}_${moment().format("YYYYMMDDHHmmss")}.${fileExtension}`;
    newDocument.file = changeFileName(newDocument.file, newFileName);

    const associateIndex = associates.filter((associate) => associate.document === newDocument.associatedTo).map((associate) => associate.index)

    this.addNewUploadedDocument({
      name: newFileName,
      description: newDocument.description,
      type: newDocument.docType,
      associatedTo: associateIndex[0].toString(),
      file: newDocument.file
    });
    this.clearForm();
    await this.checkTakerDocumentImagesUploaded();

  }

  clearForm() {
    this.setState({
      newDocument: {
        docType: "",
        description: "",
        file: undefined,
        associatedTo: ""
      }
    });
  }

  async sendDocumentToSenda(): Promise<boolean> {
    const { uploadedDocuments } = this.state;
    const { setLoading, cotizacion } = this.props;

    /* let filesInfoArray: DocumentDto[] = [];
    let filesArray: File[] = [];

    await Promise.all(uploadedDocuments.map(async (file) => {
      if (!file.id && file.file) {
        filesInfoArray.push({
          [file.name]: {
            type: file.type,
            associatedTo: file.associatedTo
          }
        })
        filesArray.push(file.file)
      }
      else if (file.id && !file.file) {
        let fileWithId = await GestDocServices.getFile(
          setLoading,
          true,
          file.id
        )
          .then((response) => response)
          .catch((e) => console.log(e))
        if (fileWithId) {
          filesInfoArray.push({
            [file.name]: {
              type: file.type,
              associatedTo: file.associatedTo
            }
          })
          filesArray.push(new File([fileWithId.fileData], fileWithId.fileName))
        }
      }
    }))

    let error = false;
    if (filesArray.length > 0) {
      error = await HealthService.uploadDocumentsToSenda(setLoading, true, {
        cotizacion: cotizacion,
        fileInfo: filesInfoArray,
        files: filesArray,
      }).catch((error) => {
        this.setState({ modalErrorShow: true, modalErrorText: `Error al subir el documento: ${error.data.message}` })
        return true;
      }) === true ? true : false;
    } */
    return /* error */false;
  }

  async downloadDocument(id: string) {
    const { setLoading } = this.props;
    var result = await GestDocServices.getFile(setLoading, true, id);
    if (result && result.fileData) {
      downloadFile(result.fileData, result.fileName, result.fileType, true);
    } else {
      this.showErrorModal("Se ha producido un error al descargar el archivo.");
    }
  }

  async confirmDeleteDocument(index: number) {
    this.setState({ showConfirmRemoveDocumentModal: true, showConfirmRemoveDocumentModalId: index });
  }

  async deleteDocument(index: number) {
    const { setLoading } = this.props;
    const { uploadedDocuments } = this.state;
    const id = uploadedDocuments[index].id

    if (id) {
      let isDeleted = await GestDocServices.deleteFile(setLoading, true, id);
      if (isDeleted) {
        let { uploadedDocuments } = this.state;
        uploadedDocuments = uploadedDocuments.filter(doc => doc.id !== id);
        this.setState({ uploadedDocuments: uploadedDocuments });
        await this.checkTakerDocumentImagesUploaded();
      } else {
        this.showErrorModal("Se ha producido un error al eliminar el archivo.");
      }
    }
    else {
      uploadedDocuments.splice(index, 1);
    }
  }

  addNewUploadedDocument(newDoc: HealthDocument) {
    let { uploadedDocuments } = this.state;
    uploadedDocuments.push(newDoc);
    this.setState({ uploadedDocuments: uploadedDocuments });
  }

  showErrorModal = (text: string = "Ha ocurrido un error.") => this.setState({ modalErrorShow: true, modalErrorText: text });
  hideErrorModal = () => this.setState({ modalErrorShow: false, modalErrorText: "" });

  render() {
    const {
      newDocument,
      readOnly,
      uploadedDocuments,
      documentTypes,
      associates,
      areTakerDocumentIdImagesUploaded
    } = this.state;

    return (
      <RequiredDocDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <h2>{'DOCUMENTACION REQUERIDA'}</h2>
          </div>
        </div>

        <div className="content-wrapper bottomMargin20 filesSection" ref={''}>
          <div className="personal-data-wrapper">
            {!readOnly && (
              <div>
                <div className="data-title">
                  <h5>ADJUNTAR DOCUMENTOS</h5>
                </div>

                <div className="colSin-100 topMargin20">

                  <div className="colSin-50 rightPadding20">
                    <div className="selectBox">
                      <SelectBox
                        optionsText={documentTypes}
                        optionKey={"value"}
                        optionValue={"label"}
                        defaultValue={newDocument.docType}
                        initialValue={newDocument.docType}
                        onChange={(value: string) => this.onDocumentTypeChange(value)}
                        required={true}
                        disabled={readOnly}
                      />
                      <label>Tipo de documento</label>
                    </div>
                  </div>

                  <div className="colSin-50 rightPadding20">
                    <div className="selectBox">
                      <SelectBox
                        optionsText={associates}
                        optionKey={"document"}
                        optionValue={"title"}
                        defaultValue={""}
                        initialValue={newDocument.associatedTo}
                        onChange={(value: string) => this.onAssociatedToChange(value)}
                        required={false}
                        disabled={readOnly}
                      />
                      <label>Asegurado</label>
                    </div>
                  </div>

                  <div className="colSin-100 rightPadding20">
                    <div className="inputBox custom-file-upload">
                      <div className="colSin-100">
                        <InputBox
                          type={"text"}
                          required={false}
                          errorCode={""}
                          disabled={readOnly}
                          cleanValue={true}
                          value={newDocument.description ? newDocument.description : ""}
                          onChange={(value: string) => this.onDescriptionChange(value)}
                        />
                      </div>
                      <label>Descripción</label>
                    </div>
                  </div>

                </div>

                <div className="colSin-100 topMargin20">
                  <div className="colSin-50 rightPadding20">
                    <div className="inputBox custom-file-upload">

                      <div className="colSin-70 required">
                        <input type="text" readOnly value={(newDocument.file ? newDocument.file.name : "")} disabled={readOnly} />
                      </div>

                      <div className="colSin-30">
                        <label className="custom-file-upload-button topMargin5">
                          <input type="file" onChange={this.onFileChange} accept={acceptedFileMimeType.toString()} />
                          <MainButton
                            onClick={() => { return true }}
                            text="Buscar"
                            disabled={readOnly}
                          />
                        </label>
                      </div>

                      <label>Adjuntar documento</label>
                    </div>
                  </div>

                  <div className="colSin-50 rightPadding20 topMargin15">
                    <label className="custom-file-upload">
                      <div className={"upload-buttons"}>
                        <MainButton
                          type={this.isAdjuntarDocumentoButtonDisabled() ? "disabled" : ""}
                          onClick={() => 'this.uploadDocument()'}
                          text="Adjuntar Documento"
                          icon={true}
                          iconImg={images.IconUploadBlue}
                          disabled={this.isAdjuntarDocumentoButtonDisabled()}
                        />
                      </div>
                    </label>
                  </div>

                </div>
              </div>
            )}
            {!readOnly && !areTakerDocumentIdImagesUploaded &&
              <div className="colSin-100 topMargin20">
                <NoteText>
                  <p>Para poder continuar es necesario adjuntar el anverso y el reverso del DNI del tomador.</p>
                </NoteText>
              </div>
            }
            <div className="colSin-100 topMargin20">
              {uploadedDocuments.map((document, index) => (
                <div key={index} className="uploadedFile">

                  <div className="icon-check-block">
                    <img src={images.IconCheckWhite} alt="" />
                  </div>
                  {document.name}
                  <MainButton
                    key={`downloadBtn${index}`}
                    //TODO: Hacer validación para el document.id (Aunque en principio para pulsar estos botones debe estar)
                    onClick={() => document.id && this.downloadDocument(document.id)}
                    text=""
                    icon={true}
                    iconImg={images.IconEyeBlue}
                  />
                  {false && (!readOnly && (<MainButton
                    key={`deleteBtn${index}`}
                    //TODO: Hacer validación para el document.id (Aunque en principio para pulsar estos botones debe estar)
                    onClick={() => this.confirmDeleteDocument(index)}
                    text=""
                    icon={true}
                    iconImg={images.IconDeleteBlue}
                  />))}
                </div>
              )).reverse()}
            </div>

          </div>
        </div>
        <div className="colSin-100 topMargin20 bottomMargin20">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push(
                  GipHireMenuHelper.getStepNavigationPath(
                    GipHireMenuHelper.getStepFromNavigationRoute(
                      this.props.match.url,
                      GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
                    ) - 1,
                    this.props.data.insureds.lenght,
                    GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
                  )
                )}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                disabled={/* !areTakerDocumentIdImagesUploaded */false}
                withTab={true}
                text="Siguiente"
                onClick={() => this.tryGoToNextStep()}
              />
            </div>
          </div>
        </div>
        <LeftInfoModal
          img={images.IconEraseRed}
          mainTitle={"¿Quieres borrar este documento?"}
          mainText={"Una vez borrado, no podrá volver a recuperarlo."}
          buttonText="Eliminar"
          close={() => this.setState({ showConfirmRemoveDocumentModal: false, showConfirmRemoveDocumentModalId: -1 })}
          active={this.state.showConfirmRemoveDocumentModal}
          onClickAccept={async () => await this.deleteDocument(this.state.showConfirmRemoveDocumentModalId)}
          className="error"
        />
        <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={this.hideErrorModal} />
            </div>
          </div>
        </ErrorModalContainer>
      </RequiredDocDataContainer>
    );
  }
}

export default withCoverages(withPolicy(withGeneral(withRouter(GipRequiredDocDataLayout))));
