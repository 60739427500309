import styled from "styled-components";
import { colors } from "../../assets";

export const NotFoundContainer = styled.div`
  background: #ffffff;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:1;

  .not-found-content {
    width: 100%;
    padding: 10%;
    z-index: 2;
  }
  .go-back-container {
    margin-bottom: 140px;
  }

  .not-found-content-middle {
    position: absolute;
    right: 25%;
    top: 60%;
    z-index: 1;
  }
  .not-found-content-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }

  .go-back-item {
    cursor: pointer;
    max-width: 22px;
    img {
      max-width: 20px;
    }
  }

  .not-found-text {
    color: ${colors["PR-001-100"]};
  }

  .not-found-title {
    margin-bottom: 40px;
    font-size: 120px;
    font-family: "grouch";
  }

  .not-found-description {
    margin-bottom: 60px;
    max-width: 440px;
  }

  .not-found-btn {
    max-width: 140px;
    margin-bottom: 32px;
  }
`;
