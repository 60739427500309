import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { LegalPolicyContainer } from "../legal-policy/legal-policy-style";
import GoBack from "../../components/go-back";
import { withGeneral } from "../../context/mixin/with-general";

interface DataProtectionProps { }

interface DataProtectionProps extends RouteComponentProps {
  isLogged: boolean;
  history: any;
}

class DataProtectionLayout extends React.Component<DataProtectionProps, {}> {
  render() {
    const { isLogged, history } = this.props;
    return (
      <LegalPolicyContainer>
        { !isLogged && 
        <div className="back-arrow-container">
          <GoBack text="Volver" onClick={() => history.push('/')} />
        </div> }

        <div className="legal-title-selection">
          <p>
            Política de <br /> protección de datos
          </p>
        </div>

        <div className="legal-subtitle">
          <p>1 Introducción</p>
        </div>

        <div className="legal-text">
          <p>
            Esta Política de Protección de Datos está adaptada específicamente a la web de ASISA (www.asisa.es), así como a las aplicaciones y demás productos y servicios, que el titular de los datos de carácter personal pueda contratar a través de la citada página web (denominados colectivamente “Servicios”). A tal efecto, y en las condiciones que se recogen en la Política de Protección de Datos, ASISA podrá comunicar sus datos personales a otras empresas del Grupo, cuando así sea necesario para la prestación de dichos Servicios.
          </p>
          <p>
            Esta Política de Protección de Datos podrá modificarse por ASISA, previa comunicación al titular de los datos a través de la página web o por otros medios para que pueda conocerla y continuar utilizando nuestros Servicios. El hecho de continuar disfrutando de nuestros Servicios después de haberle comunicado las referidas modificaciones, supondrá que está de acuerdo con las mismas, salvo que sea necesario el consentimiento expreso.
          </p>
        </div>

        <div className="legal-subtitle">
          <p>2 ¿Quién es el responsable del tratamiento de sus datos personales?</p>
        </div>

        <div className="legal-text">
          <p>
            El responsable de tratamiento de los datos personales que el titular de los datos facilite en la página web ({" "}
            <a href="www.asisa.es">www.asisa.es</a> ) será la sociedad ASISA ASISTENCIA SANITARIA INTERPROVINCIAL S.A.U. (ASISA), con domicilio sito
            en la calle Juan Ignacio Luca de Tena, 12, 28027, Madrid.
          </p>
          <p>
            Para la adecuada gestión del tratamiento de sus datos personales, ASISA tiene designado un Delegado de Protección de Datos ante el que
            podrá acudir para resolver cualquier cuestión que precise, pudiendo contactar a través del correo electrónico <a href="mailto:DPO@grupoasisa.com">DPO@grupoasisa.com</a>.
          </p>
        </div>

        <div className="legal-subtitle">
          <p>3 ¿Con qué finalidad tratamos sus datos?</p>
        </div>

        <div className="legal-text">
          <p className="list">En ASISA podemos tratar los datos personales recabados del titular de los mismos para las siguientes finalidades:</p>

          <ul>
            <li>
              Contacto: gestionar los datos de los interesados que contactan con ASISA a través de los formularios que ASISA pone a disposición del usuario. Asimismo, sus datos serán tratados con el fin de gestionar las consultas que usted nos haga llegar a través de los canales habilitados a tal efecto en nuestra página web.
            </li>
            <li>
              Trabaja con nosotros: gestionar la recepción de su Currículum Vitae a través del envío de un correo electrónico, así como de los datos personales que en su caso se generen como consecuencia de la participación en procesos de selección, con la finalidad de analizar su perfil profesional y de posibilitarle la participación en los procesos de selección de personal organizados por ASISA a la vista de los puestos vacantes o de nueva creación que se originen periódicamente, así como tramitar su eventual incorporación en la plantilla de ASISA. Le informamos de que conservaremos sus datos por un periodo de no superior a un año por si en el futuro entendemos que encaja en alguno de los perfiles de empleo existentes.
            </li>
            <li>
              Acceso a “Mi Área Privada”: gestionar su registro en “Mi Área Privada”, así como los datos posteriores que se pudieran generar a través del citado canal, con la finalidad de llevar a cabo la administración general de su cuenta, el mantenimiento, cumplimiento, desarrollo, control y gestión de las peticiones que usted registre a través del portal “Mi Área Privada”, así como de la gestión de su relación con ASISA.
            </li>
            <li>
              A través de su registro en el Área Privada y, por medio de la misma, usted podrá gestionar, entre otros, los siguientes servicios: autorizaciones, tarjeta sanitaria, doctor virtual, así como la modificación de sus datos personales o bancarios. También podrá consultar sus asistencias médicas, sus recibos, sus copagos, información de su contrato, además de diferente documentación relacionada con usted.
            </li>
            <li>
              Contratación de productos y servicios: gestionar la contratación de productos y servicios, así como datos posteriores que se pudieran derivar de la relación con ASISA. Asimismo, en el procedimiento de contratación se pedirán, entre otros, datos relativos a la tarjeta de crédito, los cuales serán comunicados a la entidad que gestione el cobro. Esta solicitud se realizará con la finalidad principal de validación de la tarjeta y del pago de la prestación de servicios y responde a la libre y legítima aceptación de la relación jurídica entre el Usuario y ASISA en la cual el desarrollo, cumplimiento y control implica necesariamente la conexión de este tratamiento con ficheros de terceros en relación al cumplimiento de las finalidades especificadas.
            </li>
            <li>
              Publicidad de productos y servicios propios: ASISA podrá hacerle llegar, tanto a través de correo postal, como mediante correo electrónico, SMS u otros medios de comunicación electrónica, publicidad tanto de carácter general como adaptada a sus características de la propia entidad, relacionada con los servicios propios servicios de la compañía.
            </li>
            <li>
              Perfilado con fuentes externas: ASISA podrá elaborar perfiles en base a información facilitada por terceros mediante estudios de marketing y técnicas y procedimientos estadísticos y de segmentación que permitan personalizar la oferta de productos y servicios adecuadas a sus características y necesidades. Dicha aceptación no supondrá la toma de decisiones automáticas.
            </li>
            <li>
              Otros canales: ASISA podrá contactar también con el interesado y recoger sus datos a través de otros canales tales como teléfono, correo electrónico, apps titularidad de ASISA o redes sociales, en cuyo caso será informado sobre las finalidades para los que se tratarán sus datos, y en su caso, se recabará convenientemente su consentimiento en cada uno de dichos canales.
            </li>
          </ul>
        </div>
        <div className="legal-subtitle">
          <p>4 ¿Qué datos tratamos y de qué fuente se obtienen?</p>
        </div>

        <div className="legal-text">
          <p className="list">Los datos tratados provienen de:</p>
          <ul>
            <li>
              Datos facilitados por el interesado, a través de la cumplimentación de los formularios a tal efecto habilitados y de las relaciones que entabla con ASISA.
            </li>
            <li>
              Datos derivados de la prestación del servicio: de forma indirecta, al derivar de la propia prestación del servicio y del mantenimiento de esta actividad. Dentro de esta categoría se incluyen los datos de navegación a través de la página web pública o del acceso a la Oficina Virtual.
            </li>
            <li>La propia gestión, mantenimiento y desarrollo de la relación contractual.</li>
            <li>
              Datos obtenidos de estudios de marketing en los que usted haya aceptado participar y haya dado el consentimiento para ser utilizados.
            </li>
          </ul>
          <p className="list">En función del servicio, los datos tratados podrán abarcar la siguiente tipología:</p>

          <ul>
            <li>
              Datos identificativos (p.ej., nombre, apellidos, documento de identidad, dirección postal correo electrónico, número de teléfono).
            </li>
            <li>Datos socioeconómicos (p.ej. económicos, financieros y de seguros).</li>
            <li>Datos de transacciones de bienes y servicios (p. ej. pagos de los servicios realizados).</li>
            <li>Datos de información comercial (p.ej. intereses en productos o servicios).</li>
            <li>Datos socioeconómicos (económicos, financieros y de seguros).</li>
            <li>Datos de salud.</li>
          </ul>
        </div>
        <div className="legal-subtitle">
          <p>5 ¿Cuál es la legitimación legal para el tratamiento de sus datos?</p>
        </div>
        <div className="legal-text">
          <p>
            El tratamiento de sus datos relativos a “Contacto”, registro a “Mi Área privada” y otros canales de comunicación a su disposición, están basados en el mantenimiento de su solicitud y en su caso, para la prestación de los servicios contratados.
          </p>
          <p>
            De igual forma, la base legal para el tratamiento de sus datos derivados de la contratación, es la ejecución del contrato de prestación de servicios o productos de ASISA (según los términos y condiciones que constan en la página web).
          </p>
          <p>
            El tratamiento de sus datos relativos a “Trabaja con nosotros”, está basado en la remisión voluntaria de su Currículum Vitae y su conservación, en virtud del interés legítimo.
          </p>
          <p>
            El envío de publicidad y marketing de productos propios o similares a los contratados por el interesado, está basado en el interés legítimo de ASISA, cuando se registre en “Mi Área Privada” o contrate un servicio. En el resto de supuestos, así como cuando la publicidad sea de terceros, el tratamiento estará basado en el consentimiento que necesariamente se le deberá solicitar.
          </p>
        </div>
        <div className="legal-subtitle">
          <p>6 ¿A quién se comunican sus datos?</p>
        </div>
        <div className="legal-text">
          <p>
            Los datos personales tratados por ASISA para alcanzar las finalidades detalladas anteriormente podrán ser comunicados, entre otros, a los siguientes destinatarios en función de la base legitimadora de la comunicación.
          </p>
          <p className="list">
            En virtud de lo anterior, las siguientes comunicaciones de datos persiguen garantizar el correcto desarrollo de la relación contractual, así como dar cumplimiento a obligaciones legales que exigen realizar las mencionadas comunicaciones:
          </p>
          <ul>
            <li>Organismos y Administraciones Públicas.</li>
            <li>Empresas de Reaseguro o Coaseguro a fin de celebrar, tramitar o gestionar, en su caso, las prestaciones contenidas en su póliza.</li>
            <li>Entidades financieras para la gestión de cobros y pagos.</li>
            <li>
              Entidades titulares de ficheros de servicios de información sobre solvencia patrimonial y crédito, tanto para su consulta en los supuestos legalmente establecidos, como en caso de incumplimiento de sus obligaciones dinerarias.
            </li>
          </ul>
        </div>
        <div className="legal-subtitle">
          <p>7 ¿Por cuánto tiempo conservaremos sus datos?</p>
        </div>
        <div className="legal-text">
          <p>
            Los datos personales serán conservados durante el tiempo necesario para la prestación del servicio o mientras el interesado no retire su consentimiento. Posteriormente, los datos serán suprimidos conforme a lo dispuesto en la normativa de protección de datos, lo que implica su bloqueo, estando disponibles tan solo a solicitud de Jueces y tribunales, Defensor del Pueblo, Ministerio Fiscal o las Administraciones Públicas competentes durante el plazo de prescripción de las acciones que pudieran derivar y, transcurrido éste, se procederá a su completa eliminación, salvo que exista normativa legal española o de los estados miembros que obligue a su conservación.
          </p>
        </div>

        <div className="legal-subtitle">
          <p>8 ¿Cuáles son sus derechos?</p>
        </div>

        <div className="legal-text">
          <p className="list">
            Cualquier interesado tiene derecho a obtener confirmación sobre si en ASISA se están tratando datos personales que le conciernen, o no.
            Del mismo modo, tal y como queda previsto en el Reglamento General de Protección de Datos, usted tiene podrá ejercitar los siguientes derechos:
          </p>
          <ul>
            <li>Acceder a sus datos con el fin de conocer el tratamiento de los datos personales del interesado llevados a cabo por ASISA.</li>
            <li>
              Solicitar la rectificación o supresión de sus datos cuando el interesado los considere inexactos, y que sean objeto de tratamiento por parte de ASISA o incluso, a solicitar su supresión cuando, entre otros motivos, los datos ya no fueran necesarios para los fines que fueron recogidos.
            </li>
            <li>
              Solicitar la limitación del tratamiento de sus datos en los casos en los que la normativa así lo contemple. En estos casos, ASISA conservará los datos objeto de la limitación para el ejercicio o defensa de reclamaciones tal y como queda previsto en el Reglamento General de Protección de Datos.
            </li>
            <li>
              A la portabilidad de sus datos, en los casos en los que el interesado solicite recibir los datos de carácter personal que le incumban, y que haya facilitado a ASISA. Estos datos se proporcionarán al interesado en un formato estructurado de uso común y lectura mecánica.
            </li>
            <li>
              Oponerse al tratamiento de sus datos en determinadas circunstancias y por motivos relacionados con su situación particular, en cuyo caso, ASISA dejaría de tratarlos salvo por motivos legítimos imperiosos o por el ejercicio o la defensa de posibles reclamaciones.
            </li>
          </ul>
          <p>
            Asimismo, el interesado tiene derecho, en cualquier momento, a oponerse al envío de publicidad por medios electrónicos enviados por ASISA, y retirar su consentimiento, sin que ello afecte a la licitud del tratamiento basado antes de haber prestado dicho consentimiento.
          </p>
          <p>
            Igualmente, el interesado, podrá ejercer los derechos anteriormente descritos, mediante solicitud escrita dirigida a ASISA ASISTENCIA SANITARIA INTERPROVINCIAL S.A.U y al domicilio postal calle Juan Ignacio Luca de Tena número 12, 28027, Madrid, o bien remitiendo un correo electrónico a la dirección <a href='mailto:DPO@grupoasisa.com'>DPO@grupoasisa.com</a>, indicando en ambos casos “Política de Protección de Datos”.
          </p>
          <p>
            Finalmente, el titular de los datos puede presentar una reclamación ante la Agencia Española de Protección de Datos, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos.
          </p>
        </div>
      </LegalPolicyContainer>
    );
  }
}

export default withRouter(withGeneral(DataProtectionLayout));
