import React, { FC } from 'react';
import { ButtonProps } from './button.type';
import { Button as StyledButton } from './button.style';

const Button: FC<ButtonProps> = ({ text, onClick, disabled = false }) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled}>{text}</StyledButton>
  )
}

export default Button;
