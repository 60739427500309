import React from "react";
import { images } from "../../../../assets";
import H4Text from "../../../../components/components-text/h4-text";
import { ModalParticipantContainer } from "./modal-participant-style";
import { ALL_PRODUCTS_KEY_VALUE } from "../../../../constants/campaignsConfig";

interface IModalParticipantProps {
    close: () => void;
    participant: any | null;
    selectedParticipantPrize: string;
    campaign: any;

}

interface IModalParticipantState {

}


class ModalParticipant extends React.Component<
    IModalParticipantProps,
    IModalParticipantState
    > {
    containerRef: any = null;
    constructor(props: IModalParticipantProps) {
        super(props);
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside.bind(this));
    }


    isVisible(e: any) {
        return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
    }

    handleClickOutside(ev: any) {
        const { close } = this.props;
        this.isVisible(ev.target) &&
            this.containerRef.current &&
            !this.containerRef.current.contains(ev.target) &&
            close();
    }


    render() {
        const { close, campaign, participant, selectedParticipantPrize } = this.props;

        return (
            <ModalParticipantContainer>
                <div className="modal-participant-aux-container">
                    <div
                        className="modal-participant-container"
                        ref={this.containerRef}
                    >
                        <div className="modal-participant-close">
                            <img src={images.IconCloseGrey} onClick={() => close()} />
                        </div>
                        <div className="modal-participant-subtitle">
                            {campaign.name}
                        </div>
                        <div className="modal-participant-header">
                            <div className="modal-participant-header-title">
                                <div className="modal-participant-header-title__icon">
                                    <img
                                        src={images.IconPrizeBlue}
                                    />
                                </div>
                                <div className="modal-participant-header-title__text">
                                    <H4Text>
                                        {'Detalle del objetivo conseguido'}
                                    </H4Text>
                                </div>
                            </div>
                        </div>
                        <div className="modal-participant-wrapper">
                            <div className="modal-participant-card">
                                <div className="modal-participant-card-title">
                                    <div className="modal-participant-card-title__text">
                                        {participant.mediator}
                                    </div>
                                    <div className="modal-participant-card-title__info">
                                        {participant.mediatorType}
                                    </div>
                                </div>
                                <div className="modal-participant-card-subtitle">
                                    {participant.mediatorEmail}
                                </div>
                            </div>
                            <div className="modal-participant-card">
                                <div className="modal-participant-card-tiny-title">
                                    {'RESULTADOS A 31/12/2019'}
                                </div>
                                {participant.products.map((product: any) => (
                                    <>
                                        <div className="modal-participant-card-row">
                                            <div>
                                                {`Primas ${ALL_PRODUCTS_KEY_VALUE[product.product].toLowerCase()}`}
                                            </div>
                                            <div>
                                                {product.sum}
                                            </div>
                                        </div>
                                        <div className="modal-participant-card-row">
                                            <div>
                                                {`Pólizas ${ALL_PRODUCTS_KEY_VALUE[product.product].toLowerCase()}`}
                                            </div>
                                            <div>
                                                {product.policies}
                                            </div>
                                        </div>
                                        <div className="modal-participant-card-row">
                                            <div>
                                                {`Puntos ${ALL_PRODUCTS_KEY_VALUE[product.product].toLowerCase()}`}
                                            </div>
                                            <div>
                                                {product.points}
                                            </div>
                                        </div>
                                    </>
                                ))}
                                <div className="modal-participant-card-result-row">
                                    <div>
                                        {'Objetivo alcanzado'}
                                    </div>
                                    <div>
                                        {selectedParticipantPrize}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalParticipantContainer>
        )
    }
}

export default ModalParticipant;