import React from "react";
import { Link } from "react-router-dom";
import { NotificationsContainer } from "./notifications-style";
import Title02Text from "../../components/components-text/title-02-text";
import GoBack from "../../components/go-back";
import MainTable from "../../components/main-table";
import Pagination from "../../components/pagination";
import InfoModal from "../../components/info-modal";
import { images } from "../../assets";
import LeftInfoModal from "../../components/left-info-modal";
import NotificationModal from "../../components/notification-modal";
interface NotificationsProps {}
interface NotificationsState {
  showLeftInfoModal?: boolean;
  showNotificationModal?: boolean;
}

export default class NotificationsLayout extends React.Component<NotificationsProps, NotificationsState> {
  constructor(props: NotificationsProps) {
    super(props);
    this.state = {
      showLeftInfoModal: false,
      showNotificationModal: false,
    };
  }

  tableColumns = [
    {
      text: "",
      filter: false,
      ascendent: true,
    },
    {
      text: "Fecha",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Tipología",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Detalle",
      filter: true,
      ascendent: false,
      key: "highlighted",
    },
    {
      text: "",
      filter: false,
      ascendent: true,
    },
  ];

  tableRows = [
    {
      type: {
        key: "buttons",
        items: [
          {
            img: images.BellBlueIcon,
            onClick: () => {
              this.openNotificationModal();
            },
          },
        ],
      },
      date: {
        text: "14/02/2019",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      month: {
        text: "Alerta",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      detail: {
        text: "Lorem ipsum dolor sit amet consectitur adipiscing elit lorem ipsum dolor",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      amount: {
        text: "14,12€",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      buttons: {
        key: "buttons",
        items: [
          {
            img: images.IconClipLightBlue,
            link: "#descarga",
            // en el link habrá que meter el enlace detalle de ese usuario segun el flujo de poliza en el que este el usuario
          },
          {
            img: images.IconEraseRed,
            onClick: () => {
              this.openLeftInfoModal();
            },
          },
        ],
      },
    },
    {
      type: {
        key: "buttons",
        items: [
          {
            img: images.IconWifiBlue,
            onClick: () => {
              this.openNotificationModal();
            },
          },
        ],
      },
      date: {
        text: "14/02/2019",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      month: {
        text: "Campañas",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      detail: {
        text: "Lorem ipsum dolor sit amet consectitur adipiscing elit lorem ipsum dolor",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      amount: {
        text: "14,12€",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      buttons: {
        key: "buttons",
        items: [
          {
            img: images.IconClipLightBlue,
            link: "#descarga",
            // en el link habrá que meter el enlace detalle de ese usuario segun el flujo de poliza en el que este el usuario
          },
          {
            img: images.IconEraseRed,
            onClick: () => {
              this.openLeftInfoModal();
            },
          },
        ],
      },
    },
    {
      type: {
        key: "buttons",
        items: [
          {
            img: images.IconClockBlue,
            onClick: () => {
              this.openNotificationModal();
            },
          },
        ],
      },
      date: {
        text: "14/02/2019",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      month: {
        text: "Estacional",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      detail: {
        text: "Lorem ipsum dolor sit amet consectitur adipiscing elit lorem ipsum dolor",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      amount: {
        text: "14,12€",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      buttons: {
        key: "buttons",
        items: [
          {
            img: images.IconClipLightBlue,
            link: "#descarga",
            // en el link habrá que meter el enlace detalle de ese usuario segun el flujo de poliza en el que este el usuario
          },
          {
            img: images.IconEraseRed,
            onClick: () => {
              this.openLeftInfoModal();
            },
          },
        ],
      },
    },
    {
      type: {
        key: "buttons",
        items: [
          {
            img: images.IconAsisaBlue,
            onClick: () => {
              this.openNotificationModal();
            },
          },
        ],
      },
      date: {
        text: "14/02/2019",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      month: {
        text: "Corporativa",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      detail: {
        text: "Lorem ipsum dolor sit amet consectitur adipiscing elit lorem ipsum dolor",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      amount: {
        text: "14,12€",
        type: "",
        key: "body",
        onClick: () => {
          this.openNotificationModal();
        },
      },
      buttons: {
        key: "buttons",
        items: [
          {
            img: images.IconClipLightBlue,
            link: "#descarga",
            onClick: () => {
              this.openLeftInfoModal();
            },
          },
          {
            img: images.IconEraseRed,
            onClick: () => {
              this.openLeftInfoModal();
            },
          },
        ],
      },
    },
  ];

  openLeftInfoModal() {
    this.setState({ showLeftInfoModal: true });
  }

  closeLeftInfoModal() {
    this.setState({ showLeftInfoModal: false });
  }

  openNotificationModal() {
    this.setState({ showNotificationModal: true });
  }

  closeNotificationModal() {
    this.setState({ showNotificationModal: false });
  }

  render() {
    const { showLeftInfoModal, showNotificationModal } = this.state;

    return (
      <>
        <NotificationsContainer>
          <div className="back-arrow-container">
            <GoBack />
          </div>
          <div className="main-title-container">
            <Title02Text>
              <p>Notificaciones</p>
            </Title02Text>
          </div>

          <div className="notifications-table">
            <MainTable
              columns={this.tableColumns}
              rowKeyLink={"year"}
              rows={this.tableRows}
              keysRow={["type", "date", "month", "detail", "amount", "buttons"]}
            />
          </div>

          <div className="notifications-pagination">
            <Pagination page={1} limit={10} callback={() => console.log("hey")} />
          </div>

          <InfoModal
            img={images.IconCheckboxBlue}
            text={"Vas a iniciar el proceso de simulación, ¿estás seguro?"}
            onClickAccept={() => console.log("click")}
          />
        </NotificationsContainer>

        <LeftInfoModal
          img={images.IconEraseRed}
          mainTitle={"¿Quieres borrar la notificación?"}
          mainText={"Si elimina la notificación, luego no podrá recuperarla ni los archivos adjuntos a esta"}
          buttonText="Eliminar"
          close={() => this.closeLeftInfoModal()}
          active={showLeftInfoModal}
          onClickAccept={() => console.log("accion de ok")}
          className="error"
        />

        <NotificationModal
          active={showNotificationModal}
          close={() => this.closeNotificationModal()}
          onClickAccept={() => {
            this.closeNotificationModal();
            this.openLeftInfoModal();
          }}
        />
      </>
    );
  }
}
