import React, { Component } from "react";
import MainButton from "../main-button";
import { CardConditionCampaignContainer } from "./card-condition-campaign-style";

interface ICardConditionCampaignProps {
  onClick: () => void;
  status: string;
  title: string;
}

const states: any = {
  completed: "Completado",
  notCompleted: "No completado"
};

export const CardConditionCampaign = (props: ICardConditionCampaignProps) => {
  const { onClick, status, title } = props;
  return (
    <CardConditionCampaignContainer status={status}>
      {/* <div className="card-conditition-state">{states[status]}</div> */}
      <div className="card-conditition-title">{title}</div>
      <div className="card-conditition-button">
        <MainButton
          text={status === "completed" ? "Editar" : "Completar"}
          onClick={onClick}
          type={status === "completed" ? "white" : ""}
        />
      </div>
    </CardConditionCampaignContainer>
  );
};
