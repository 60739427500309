import React from "react";
import { CampaignsConfigPointsContainer } from "./campaigns-config-points-style";
import Title02Text from "../../../components/components-text/title-02-text";
import SelectBox from "../../../components/select-box/select-box";
import MainButton from "../../../components/main-button";
import MainTable from "../../../components/main-table";
import Pagination from "../../../components/pagination";
import { images } from "../../../assets";
import { withRouter, RouteComponentProps } from "react-router-dom";
import CampaignsConfigurationServices from "../../../services/CampaignsConfigurationServices";
import moment from "moment";
import MainModalCampaigns from "../../../components/main-modal-campaigns";
import ModalCreatePoints, {
  IModalCreatePointsState
} from "./modal-create-points/modal-create-points";
import ZeroContent from "../../../components/zero-content/zero-content";
import { ALL_PRODUCTS_KEY_VALUE } from "../../../constants/campaignsConfig";

const PRODUCT_TYPES: { [key: string]: string } = {
  PREMIUM: "Por prima",
  HIRING: "Por contratación"
};

const NUM_ROWS = 6;

interface ICampaignsConfigPointsProps extends RouteComponentProps { }

interface ICampaignsConfigPointsState {
  rows: any[];
  page: number;
  totalPages: number;
  modal: string;
  selectedScoreId: string;
  yearsDropdown: any[];
  selectedYear: string;
  selectedPointsConfig?: IModalCreatePointsState;
  hasCampaigns: boolean;
}

class CampaignsConfigPoints extends React.Component<
  ICampaignsConfigPointsProps,
  ICampaignsConfigPointsState
  > {
  constructor(props: ICampaignsConfigPointsProps) {
    super(props);
    this.state = {
      rows: [],
      page: 1,
      totalPages: 0,
      selectedScoreId: "",
      modal: "",
      yearsDropdown: [],
      selectedYear: '',
      hasCampaigns: false,
    };
  }

  componentDidMount() {
    const { yearsDropdown } = this.state;
    let currentYear = moment();
    const nextYears = moment().add(10, 'years');
    while (currentYear.isBefore(nextYears)) {
      const year = currentYear.format('YYYY');
      yearsDropdown.push(
        {
          label: year,
          value: year
        })
      currentYear.add(1, 'year')
    }
    this.setState({ yearsDropdown }, () => this.getScores());
  }

  async getScores() {
    const { page, selectedYear } = this.state;
    const args = {
      take: NUM_ROWS,
      skip: page,
      year: selectedYear,
      setLoading: () => { }
    };
    const resp = await CampaignsConfigurationServices.getScores(args);
    this.setState({ totalPages: Math.ceil(resp.count) }, () => this.getRows(resp.items));
  }

  async createScore(state: IModalCreatePointsState) {
    const { selectedPointsConfig } = this.state;
    const data = {
      product: state.product,
      productLine: state.productLine,
      productType: state.productType,
      minPremium: parseFloat(state.minPremium),
      value: state.value,
      year: state.year
    };
    const args: any = {
      data,
      setLoading: () => { }
    };
    this.setState({ modal: "" });
    if (selectedPointsConfig && selectedPointsConfig.id) {
      args.id = selectedPointsConfig.id;
      await CampaignsConfigurationServices.putScore(args);
    } else {
      await CampaignsConfigurationServices.postScore(args);
    }
    this.setState({
      modal: "create-success-modal",
      selectedPointsConfig: undefined
    });
  }

  async deleteScore() {
    const { selectedScoreId } = this.state;
    const args = {
      id: selectedScoreId,
      setLoading: () => { }
    };
    await CampaignsConfigurationServices.deleteScore(args);
    this.setState({ modal: "delete-success-modal" });
  }

  getRows(scores: any) {
    const rows: any[] = [];
    const years: number[] = [];
    scores.forEach((score: any) => {
      const scoreState: IModalCreatePointsState = {
        id: score.id,
        year: score.year,
        value: score.value,
        product: score.product,
        productLine: score.productLine,
        productType: score.productType,
        minPremium: score.minPremium ? score.minPremium.toString() : '-',
        errors: {}
      };
      rows.push({
        productLine: {
          text: ALL_PRODUCTS_KEY_VALUE[score.productLine],
          type: "",
          key: "highlighted"
        },
        product: {
          text: ALL_PRODUCTS_KEY_VALUE[score.product],
          type: "",
          key: "body"
        },
        productType: {
          text: PRODUCT_TYPES[score.productType],
          type: "",
          key: "body"
        },
        hiringPoints: {
          text: score.productType === "HIRING" ? score.value : "X",
          type: "",
          key: "body"
        },
        primePoints: {
          text: score.productType === "PREMIUM" ? score.value : "X",
          type: "",
          key: "body"
        },
        minPremium: {
          text: score.minPremium || '-',
          type: "",
          key: "body"
        },
        buttons: {
          key: "buttons",
          items: [
            {
              img: images.IconEditLightBlue,
              onClick: () => {
                this.setState({
                  modal: "create-modal",
                  selectedPointsConfig: scoreState
                });
              }
            },
            {
              img: images.IconDeleteLightBlue,
              onClick: () => {
                this.setState({
                  modal: "delete-modal",
                  selectedScoreId: score.id
                });
              }
            }
          ]
        }
      });
    });

    this.setState({
      rows,
      hasCampaigns: true
    });
  }

  setPage(page: number) {
    this.setState({ page }, () => this.getScores());
  }

  render() {
    const {
      rows,
      page,
      totalPages,
      modal,
      yearsDropdown,
      selectedPointsConfig,
      hasCampaigns
    } = this.state;
    return (
      <CampaignsConfigPointsContainer>
        <div className="campaigns-config-points-section-title">
          <Title02Text>Puntos por contratación/prima</Title02Text>
        </div>
        <div className="campaigns-config-points-filter">
          <div className="campaigns-config-points-filter__item">
            <SelectBox
              type="ghost"
              placeholder="Año"
              required
              optionsText={yearsDropdown}
              optionKey={"value"}
              optionValue={"label"}
              defaultValue={""}
              initialValue={""}
              onChange={(value: string) => this.setState({
                selectedYear: value
              }, () => this.getScores())}
            />
          </div>
          <div className="campaigns-config-points-filter__item">
            <MainButton
              text="Crear"
              onClick={() =>
                this.setState({
                  modal: "create-modal"
                })
              }
            />
          </div>
        </div>
        {rows.length > 0 ? (
          <>
            <div className="campaigns-config-points-table">
              <MainTable
                columns={this.columns}
                rows={rows}
                keysRow={[
                  "productLine",
                  "product",
                  "productType",
                  "hiringPoints",
                  "primePoints",
                  "minPremium",
                  "buttons"
                ]}
              />
            </div>
            <div className="campaigns-config-points-pagination">
              <Pagination
                page={page}
                limit={totalPages}
                callback={page => this.setPage(page)}
              />
            </div>
          </>
        ) : hasCampaigns && (
          <ZeroContent
            text={
              "Para que no hay puntos configurados todavía. ¿A qué estás esperando para crear uno?"
            }
          />
        )}

        {modal === "delete-modal" && (
          <MainModalCampaigns
            mainTitle={"Eliminar puntuación"}
            mainText={
              "¿Estás seguro de que quieres eliminar esta publicación? Recuerda que no podrás recuperarla más tarde y tendrás que volver a configurarla si es necesario."
            }
            buttonText={"Eliminar"}
            close={() => this.setState({ modal: "" })}
            active={true}
            onClickAccept={() => this.deleteScore()}
            centered={true}
            buttonClassName={"red"}
            img={images.IconWarningRed}
          />
        )}
        {modal === "delete-success-modal" && (
          <MainModalCampaigns
            mainTitle={`¡Oops!\nHas eliminado una puntuación`}
            close={() =>
              this.setState({ modal: "" }, () => {
                this.getScores();
              })
            }
            active={true}
            onClickAccept={() => { }}
            centered={true}
            buttonClassName={"red"}
            img={images.IconWarningRed}
          />
        )}
        {modal === "create-success-modal" && (
          <MainModalCampaigns
            mainTitle={`¡Genial!`}
            mainText={
              selectedPointsConfig
                ? "Has editado una configuración de puntos. Recuerda que podrás editarla o eliminarla siempre que no se esté usando."
                : "Has creado una nueva configuración de puntos. Recuerda que podrás editarla o eliminarla siempre que no se esté usando."
            }
            close={() =>
              this.setState(
                { modal: "", selectedPointsConfig: undefined },
                () => {
                  this.getScores();
                }
              )
            }
            active={true}
            onClickAccept={() => { }}
            centered={true}
            img={images.IconDoubleFill}
          />
        )}
        {modal === "create-modal" && (
          <ModalCreatePoints
            close={() =>
              this.setState({ modal: "", selectedPointsConfig: undefined })
            }
            onSave={(state: IModalCreatePointsState) => this.createScore(state)}
            editPoint={selectedPointsConfig}
          />
        )}
      </CampaignsConfigPointsContainer>
    );
  }

  columns = [
    {
      text: "RAMO",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "PRODUCTO",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "TIPO",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "PUNTOS POR CONTRATACIÓN",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "PUNTOS POR PRIMA",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "PRIMA MÍNIMA",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "",
      filter: false,
      ascendent: true,
      key: ""
    }
  ];
}

export default withRouter(CampaignsConfigPoints);
