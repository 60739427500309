import React from "react";
import { BeneficiariesContainer } from "./beneficiaries-style";
import { RouteComponentProps, withRouter } from "react-router-dom";
import WebText from "../../../../components/components-text/web-text";
import BodyText from "../../../../components/components-text/body-text";
import { colors, images } from "../../../../assets";
import CheckBlockButton from "../../../../components/check-block-button/check-block-button";

import MainButton from "../../../../components/main-button";
import MainTable from "../../../../components/main-table";
import AddBeneficiariesModal from "../../../../components/add-beneficiaries-modal/add-beneficiaries-modal";
import InputBox from "../../../../components/input-box/input-box";
import Title02Text from "../../../../components/components-text/title-02-text";
import { NewHiringContainer } from "../new-hiring-style";
import HeadsTitleText from "../../../../components/components-text/heads-title-text";
import GuideLine from "../../../../components/guide-line";
import { ValueForm } from "../../../../utils/interfaces";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import { withPolicy } from "../../../../context/mixin/with-policy";
import LeftInfoModal from "../../../../components/left-info-modal";
import { getDayMonthYear } from "../../../../utils/time";
import DicotomicCheckbox from "../../../../components/dicotomic-checkbox/dicotomic-checkbox";

interface LoanInfoInterface {
  active: ValueForm;
  bank: ValueForm;
  endDate: ValueForm;
  nLoan: ValueForm;
  [key: string]: ValueForm;
}

interface BeneficiariesLayoutProps extends RouteComponentProps {
  isLoan: boolean;
  changeLoan: Function;
  changeBeneficiaries: Function;
  changeValidateForm: Function;
  isDisabled: boolean;
  policy: any;
  newStep: Function;
}

interface BeneficiariesLayoutState {
  loanInfo: LoanInfoInterface;
  addBeneficiariesActive: boolean;
  type: string;
  loan: boolean;
  showLeftInfoModal: boolean;
  beneficiary: any;
  indexRemove: number;
  beneficiaries: any[];
  isReadOnly: boolean;
}

class BeneficiariesLayout extends React.Component<
  BeneficiariesLayoutProps,
  BeneficiariesLayoutState
> {
  private tableColumns = [
    {
      text: "Beneficiarios",
      key: "highlighted",
    },
    {
      text: "DNI",
    },
    {
      text: "Porcentaje",
    },
    {
      text: "",
    },
  ];

  readonly state: BeneficiariesLayoutState;

  constructor(props: BeneficiariesLayoutProps) {
    super(props);
    if (props.policy.data.insureds.length === 0)
      props.history.push(
        `/${props.history.location.pathname.indexOf("simulacion") > -1
          ? "simulacion"
          : "contratacion"
        }/nueva`
      );

    this.state = {
      loanInfo: {
        active: {
          value:
          props.policy.product.products_mandatoryLoan
              ? props.policy.product.products_mandatoryLoan
              : props.policy.data.loan && props.policy.data.loan.active
                ? props.policy.data.loan.active.value
                : false,
          error: false,
          errorCode: "",
          required: true,
        },
        bank: {
          value:
            props.policy.data.loan && props.policy.data.loan.bank
              ? props.policy.data.loan.bank.value
              : "",
          error: false,
          errorCode: "",
          required: true,
        },
        endDate: {
          value:
            props.policy.data.loan && props.policy.data.loan.endDate
              ? getDayMonthYear(props.policy.data.loan.endDate.value)
              : "",
          error: false,
          errorCode: "",
          required: true,
        },
        nLoan: {
          value:
            props.policy.data.loan && props.policy.data.loan.nLoan
              ? props.policy.data.loan.nLoan.value
              : "",
          error: false,
          errorCode: "",
          required: true,
        },
      },
      beneficiaries: JSON.parse(
        JSON.stringify(props.policy.data.beneficiaries)
      ),
      addBeneficiariesActive: false,
      showLeftInfoModal: false,
      type: props.policy.data.beneficiaries.length === 0
        ? "heirs"
        : "beneficiaries",
      beneficiary: {},
      loan: props.policy.product.products_mandatoryLoan
        ? props.policy.product.products_mandatoryLoan
        : props.policy.data.loan && props.policy.data.loan.active
          ? props.policy.data.loan.active.value
          : false,
      indexRemove: -1,
      isReadOnly: props.policy.data.isReadOnly && props.policy.data.isReadOnly
    };

    props.changeValidateForm(this.validateForms());
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  };

  changeLoanValue = (value: any, key: string = "") => {
    const { loanInfo, loan } = this.state;
    const { changeLoan } = this.props;
    loanInfo[key].value = value;
    if (loanInfo[key].required && loanInfo[key].value === "") {
      loanInfo[key].error = true;
      loanInfo[key].errorCode = "required";
    } else {
      loanInfo[key].error = false;
      loanInfo[key].errorCode = "";
    }
    this.setState({ loanInfo: loanInfo });
    changeLoan(loanInfo, loan);
    this.changeValidateForm(this.validateForms());
  };

  changeLoanActive = () => {
    const { loanInfo, loan } = this.state;
    const { changeLoan } = this.props;
    changeLoan(loanInfo, loan);
  }

  changeBeneficiaries = (beneficiaries: any[]) => {
    const { changeBeneficiaries } = this.props;

    this.setState({ addBeneficiariesActive: false, beneficiaries }, () => {
      changeBeneficiaries(beneficiaries);
      this.changeValidateForm(this.validateForms());
    });
  };

  changeValidateForm = (isValid: boolean) => {
    const { changeValidateForm } = this.props;
    changeValidateForm(isValid);
  };

  getNameBeneficiary = (b : any) => {
    
    let nameBeneficiary = b.name.value;
    nameBeneficiary = `${nameBeneficiary} ${b.lastName1 && b.lastName1.value !== undefined && b.lastName1.value !== '' ? b.lastName1.value : ''}`
    nameBeneficiary = `${nameBeneficiary} ${b.lastName2 && b.lastName2.value !== undefined && b.lastName2.value !== '' ? b.lastName2.value : ''}`
    return nameBeneficiary

  }

  getRows = (beneficiaries: any[]) =>
    beneficiaries.map((b, index) => ({
      beneficiaries: {
        text: this.getNameBeneficiary(b),
        type: "",
        key: "body",
      },
      nif: {
        text: b.docNumber.value,
        type: "",
        key: "body",
      },
      percentage: {
        text: b.percentage.value + " %",
        type: "",
        key: "body",
      },
      buttons: {
        key: "buttons",
        items: [
          {
            img: images.IconEditBlue,
            onClick: () => !this.state.isReadOnly && this.editBeneficiary(index),
            disabled: this.state.isReadOnly
          },
          {
            img: images.IconEraseRed,
            onClick: () => !this.state.isReadOnly && this.setState({ showLeftInfoModal: true, indexRemove: index }),
            disabled: this.state.isReadOnly
          },
        ],
      },
    }));

  closeLeftInfoModal = () => {
    this.setState({ showLeftInfoModal: false });
  };

  addBeneficiaries = (beneficiaries: any[]) => {
    this.props.policy.handleNewChanges();
    this.setState({ beneficiaries }, () => this.changeBeneficiaries(beneficiaries));
  };

  removeBeneficiary = () => {
    const { indexRemove, beneficiaries } = this.state;
    beneficiaries.splice(indexRemove, 1);

    this.props.policy.handleNewChanges();

    this.setState({ beneficiaries }, () => this.changeBeneficiaries(beneficiaries));
  };

  editBeneficiary = (index: number) => {
    const { beneficiaries } = this.state;
    const beneficiary = beneficiaries[index];
    beneficiaries.splice(index, 1);
    this.props.policy.handleNewChanges();
    this.setState({ beneficiary, addBeneficiariesActive: true, beneficiaries });
  };

  validateForms() {
    let validLoan = this.validateLoanForm();
    let validBeneficiaries = this.validateBeneficiariesForm();
    return (validLoan && validBeneficiaries);
  }

  validateBeneficiariesForm() {
    const { beneficiaries, type } = this.state;
    if (type == "beneficiaries") {
      let sum = 0;
      beneficiaries.forEach((b: any) => (sum += parseFloat(b.percentage.value)));
      return (sum == 100);
    } else if (type == "heirs") {
      return true;
    }
    return false;
  }

  validateLoanForm() {
    const { loan } = this.state;
    const { loanInfo } = this.state;
    if (loan) {
      if (loanInfo && Object.keys(loanInfo).length === 0 && loanInfo.constructor === Object) {
        return false;
      }
      let error = false;
      Object.keys(loanInfo).forEach((k: string) => {
        if (loanInfo[k].error || !loanInfo[k].value) error = true;
      });
      return !error;
    }
    return true;
  }

  render() {
    const {
      addBeneficiariesActive,
      type,
      loan,
      loanInfo,
      showLeftInfoModal,
      beneficiary,
      beneficiaries,
      isReadOnly
    } = this.state;
    const { isDisabled, newStep, policy } = this.props;
    return (
      <NewHiringContainer>
        <div className="new-hiring-flow-status">
          <div className="new-hiring-flow-status-title">
            <HeadsTitleText>
              <p>Beneficiarios</p>
            </HeadsTitleText>
          </div>
          <div className="new-hiring-flow-status-guide">
            <GuideLine steps={[0, 0, 0, 0]} currentStep={2} />
          </div>
        </div>

        <DicotomicCheckbox
          checkboxText={"¿Existe prestamo hipotecario?"}
          initChecked={loan}
          onChange={
            (isLoan: any) => {
              this.setState({ loan: isLoan }, () => {
                this.changeLoanActive();
                this.changeValidateForm(this.validateForms());
              });
            }
          }
          disabled={isReadOnly || policy.product.products_mandatoryLoan}
        />
        <BeneficiariesContainer>
          {loan && (
            <div className="legal-heirs-irrevocable-container">
              <div className="legal-heirs-irrevocable-container-title">
                <Title02Text>
                  <p>Cláusula hipotecaria</p>
                </Title02Text>
              </div>
              <div className="legal-heirs-irrevocable-container-description">
                <WebText>
                  {/* <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, reprehenderit nihil? Commodi dolorum ullam facere rerum deleniti
                    accusantium doloribus laborum iste tenetur. Aliquam vitae aut quae. Quia placeat illum exercitationem.
                  </p> */}
                </WebText>
              </div>
              <div className="legal-heirs-irrevocable-container-form">
                <div className="legal-heirs-irrevocable-container-form__row">
                  <div className="legal-heirs-irrevocable-container-form__row__item">
                    <div className="inputBox">
                      <InputBox
                        placeholder="Entidad/persona"
                        type="text"
                        disabled={isReadOnly}
                        required={loanInfo.bank.required}
                        value={loanInfo.bank.value}
                        errorCode={loanInfo.bank.errorCode}
                        onChange={(value: string) =>
                          this.changeLoanValue(value, "bank")
                        }
                      />
                    </div>
                  </div>
                  <div className="legal-heirs-irrevocable-container-form__row__item">
                    <div className="inputBox">
                      <CalendarBox
                        placeholder="Fecha de vencimiento"
                        required={loanInfo.endDate.required}
                        initialValue={loanInfo.endDate.value}
                        errorCode={loanInfo.endDate.errorCode}
                        disabled={isReadOnly}
                        onChange={(value: string) =>
                          this.changeLoanValue(value, "endDate")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="legal-heirs-irrevocable-container-form__row">
                  <div className="legal-heirs-irrevocable-container-form__row__item">
                    <div className="inputBox">
                      <InputBox
                        placeholder="Número de préstamo"
                        type="text"
                        disabled={isReadOnly}
                        required={loanInfo.nLoan.required}
                        value={loanInfo.nLoan.value}
                        errorCode={loanInfo.nLoan.errorCode}
                        onChange={(value: string) =>
                          this.changeLoanValue(value, "nLoan")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="new-hiring-title">
            <Title02Text>
              <p>Información beneficiarios</p>
            </Title02Text>
          </div>
          <div className="new-hiring-subtitle">
            <WebText>
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit
              </p> */}
            </WebText>
          </div>
          <div className="legal-heirs-options">
            <div
              className="legal-heirs-options__item"
              onClick={() => !isReadOnly &&
                this.setState({ type: "heirs" }, () => {
                  this.changeBeneficiaries([]);
                })
              }
            >
              <CheckBlockButton
                checked={type === "heirs"}
                buttonText="Herederos legales"
                disabled={isReadOnly}
              />
            </div>
            <div
              className="legal-heirs-options__item"
              onClick={() => !isReadOnly &&
                this.setState({ type: "beneficiaries" }, () => {
                  this.changeBeneficiaries([]);
                })
              }
            >
              <CheckBlockButton
                checked={type === "beneficiaries"}
                buttonText="Beneficiarios designados"
                disabled={isReadOnly}
              />
            </div>
          </div>
          <div className="legal-heirs-description">
            <BodyText color={colors["PR-001-100"]}>
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p> */}
            </BodyText>
          </div>
          {type === "beneficiaries" && (
            <>
              <div className="legal-heirs-button">
                <MainButton
                  text="Añadir beneficiarios"
                  type="full-light"
                  onClick={() =>
                    this.setState({
                      addBeneficiariesActive: true,
                      beneficiary: {},
                    })
                  }
                  disabled={isReadOnly}
                />
              </div>
              {beneficiaries.length > 0 && (
                <div className="legal-heirs-table">
                  <MainTable
                    columns={this.tableColumns}
                    rows={this.getRows(beneficiaries)}
                    keysRow={["beneficiaries", "nif", "percentage", "buttons"]}
                  />
                </div>
              )}
            </>
          )}

          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.goBack()}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                disabled={isDisabled}
                text="Siguiente"
                onClick={() => newStep()}
              />
            </div>
          </div>
        </BeneficiariesContainer>

        <AddBeneficiariesModal
          img={images.IconUserBlue}
          onClickAccept={(beneficiaries: any) =>
            this.addBeneficiaries(beneficiaries)
          }
          close={() =>
            this.setState({
              addBeneficiariesActive: false,
              beneficiaries: beneficiaries 
            }, 
            () => this.changeValidateForm(this.validateForms()))                                   
          }
          mainTitle="Añadir beneficiarios"
          mainText=""
          buttonText="Terminar"
          active={addBeneficiariesActive}
          beneficiaries={beneficiaries}
          beneficiary={beneficiary}
        />
        <LeftInfoModal
          img={images.IconEraseRed}
          mainTitle={"¿Quieres borrar al beneficiario?"}
          mainText={
            "Si elimina al beneficiario, luego no podrá recuperar sus datos"
          }
          buttonText="Eliminar"
          close={() => this.closeLeftInfoModal()}
          active={showLeftInfoModal}
          onClickAccept={() => this.removeBeneficiary()}
          className="error"
        />
      </NewHiringContainer>
    );
  }
}

export default withPolicy(withRouter(BeneficiariesLayout));