import React, { Component } from "react";
import { SelectBoxContainer } from "./select-box-style";
import { images } from "../../assets/images";
import TooltipBox from "../tooltip-box/tooltip-box";
import BodyText from "../components-text/body-text";
import NoteText from "../components-text/note-text";
import LabelText from "../components-text/label-text";
import JSON from "../../utils/json";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import CheckboxItemLayout from "../checkbox-item/checkbox-item-layout";
import { colors } from "../../assets";
import TooltipBoxLight from "../tooltip-box-light/tooltip-box-light";

interface SelectBoxProps {
  className?: string;
  name?: string;
  icon?: string;
  iconFocus?: string;
  iconDisabled?: string;
  disabled?: boolean;
  required?: boolean;
  errorCode?: string;
  type?: string;
  suggestedOptionsText?: Array<{
    [key: string]: string;
  }>;
  optionsText: Array<{
    [key: string]: string;
  }>;
  optionKey: string;
  optionValue: string;

  placeholder?: string;
  labelText?: string;
  initialValue?: string;
  defaultValue?: string;
  onChange: (value: string, error?: boolean) => void;
  mediatorSelector?: boolean;
  isMediator?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;

  multiple?: boolean;
  onMultipleChange?: (values: string[]) => void;
  selectedMultipleOptions?: string[];

  propValue?: string;
  refreshValues?: boolean;
  health?: boolean;
}

interface SelectBoxState {
  showList: boolean;
  showTooltip: boolean;
  showSelector: boolean;
  checkedValues: boolean[];
  value: string;
  error: string;
  hoveredItem: number;
  refreshed?: boolean;
}

class SelectBox extends Component<SelectBoxProps, SelectBoxState> {
  private toggleContainer: any;

  constructor(props: SelectBoxProps) {
    super(props);
    this.state = {
      showList: false,
      showTooltip: false,
      showSelector: false,
      checkedValues: [],
      value: "",
      error: "",
      hoveredItem: -1,
      refreshed: this.props.refreshValues
    };
    this.toggleContainer = React.createRef();

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  public static defaultProps = {
    icon: images.IconArrowDownBlue,
    iconDisabled: images.IconArrowDownGrey
  };

  componentDidMount = () => {
    const { value } = this.state;
    const {
      defaultValue,
      onChange,
      multiple,
      optionsText,
      selectedMultipleOptions,
      optionKey,
      refreshValues
    } = this.props;
    if (multiple) {
      const checkedValues = new Array(optionsText.length).fill(false);
      if (selectedMultipleOptions) {
        optionsText.forEach((option, index) => {
          checkedValues[index] = selectedMultipleOptions.includes(
            option[optionKey]
          );
        });
      }
      this.setState({
        checkedValues
      });
    }
    if (defaultValue && defaultValue !== "" && value === "") {
      this.setState({ value: defaultValue }, () => {
        onChange(defaultValue);
      });
    }

    window.addEventListener("click", this.onClickOutsideHandler);
    window.addEventListener('keydown', this.keyHandler.bind(this));
  };

  componentDidUpdate(prevProps: SelectBoxProps) {
    const { value, showList, refreshed } = this.state;
    const {
      initialValue,
      optionsText,
      multiple,
      selectedMultipleOptions,
      optionKey,
      refreshValues,
    } = this.props;

    if (initialValue && initialValue !== value && !showList) {
      this.setState({ value: initialValue });
    } else if (prevProps.initialValue !== initialValue && initialValue === "") {
      this.setState({ value: "", });
    }

    // Hack to refresh dropdowns when AddictionsModal calls this
    if (refreshValues && !refreshed && initialValue !== value && !showList) {
      this.refreshValue();
    } else if (refreshed && !refreshValues) {
      this.returnRefreshState();
    }
    //

    if (
      multiple &&
      selectedMultipleOptions &&
      selectedMultipleOptions !== prevProps.selectedMultipleOptions
    ) {
      const checkedValues = new Array(optionsText.length).fill(false);
      if (selectedMultipleOptions) {
        optionsText.forEach((option, index) => {
          checkedValues[index] = selectedMultipleOptions.includes(
            option[optionKey]
          );
        });
      }
      this.setState({
        checkedValues
      });
    }
  }

  refreshValue() {
    this.setState({ value: '', refreshed: true });
  }

  returnRefreshState() {
    this.setState({ refreshed: false });
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClickOutsideHandler);
  }

  onClickOutsideHandler(event: Event) {
    if (
      this.state.showList &&
      !this.toggleContainer.current.contains(event.target)
    ) {
      this.onToggle(false, this.state.value);
    }
  }

  onToggle(show: boolean, value: string) {
    if (!show) {
      const { onChange } = this.props;
      this.setState({ showList: show, value, hoveredItem: -1 }, () => {
        onChange(value);
      });
    } else {
      this.setState({ showList: show });
    }
  }

  keyHandler(ev: any) {
    const { optionsText, optionKey } = this.props;
    const { hoveredItem, showList } = this.state;
    if (!showList) return;
    switch (ev.key && ev.key.toLowerCase()) {
      case 'arrowdown':
        ev.preventDefault();
        const newPostion = optionsText.length - 1 <= hoveredItem ? 0 : hoveredItem + 1;
        this.setState({ hoveredItem: newPostion })
        break;
      case 'arrowup':
        ev.preventDefault();
        this.setState({ hoveredItem: hoveredItem <= 0 ? optionsText.length - 1 : hoveredItem - 1 })
        break;
      case 'enter':
        if (hoveredItem > -1) {
          ev.preventDefault();
          this.onToggle(false, optionsText[hoveredItem][optionKey]);
        }
        break;
    }
  }

  onCheck(index: number) {
    const { onMultipleChange, optionsText, optionKey } = this.props;
    const { checkedValues } = this.state;
    const selectedOptions: string[] = [];
    checkedValues[index] = !checkedValues[index];
    if (onMultipleChange) {
      optionsText.forEach((option, index) => {
        if (checkedValues[index]) selectedOptions.push(option[optionKey]);
      });
      onMultipleChange(selectedOptions);
    }
    this.setState({ checkedValues });
  }

  getSelectorLabel(selectLabel: string): string {
    const { optionsText } = this.props;
    const optionsSelector = optionsText.find(x => x.value === selectLabel);
    if (optionsSelector) return optionsSelector.label;
    return selectLabel;
  }

  render() {
    const {
      disabled,
      className,
      icon,
      iconDisabled,
      name,
      initialValue,
      labelText,
      placeholder,
      errorCode,
      suggestedOptionsText,
      optionsText,
      optionKey,
      optionValue,
      withTooltip,
      mediatorSelector,
      isMediator,
      tooltipTitle,
      tooltipText,
      type,
      required,
      multiple,
      propValue,
      health
    } = this.props;
    const { showList, showTooltip, showSelector, value, checkedValues, hoveredItem } = this.state;

    const error_text = errorCode ? ERROR_MESSAGES[errorCode] : "";

    let selectLabel = propValue ? propValue : placeholder !== undefined ? placeholder : "Seleccionar";
    if (value !== "" && propValue === undefined) {
      selectLabel = JSON.getLabel(value, optionsText, optionKey, optionValue);
    }
    if (multiple) {
      selectLabel = optionsText.reduce(
        (prevValue: any, value, index) => {
          if (
            prevValue ===
            (placeholder !== undefined ? placeholder : "Seleccionar") &&
            checkedValues[index]
          )
            return value[optionValue];
          if (checkedValues[index])
            return `${prevValue}, ${value[optionValue]}`;
          return prevValue;
        },
        placeholder !== undefined ? placeholder : "Seleccionar"
      );
    }

    return (
      <SelectBoxContainer
        ref={this.toggleContainer}
        className={`${className} ${showList ? "show-list" : ""} ${disabled ? "disabled" : ""
          } ${errorCode ? "error" : ""} ${value !== "" ? "complete" : ""} ${type ? type : ""
          } ${required ? "required" : ""} `}
      >
        {labelText && (
          <div className="input-box-topbar">
            {labelText && (
              <div className="input-box-topbar-label">
                <LabelText color={showList ? colors["SC-001-100"] : ""}>
                  <p>{labelText}</p>
                </LabelText>
              </div>
            )}

            {withTooltip && (
              <div
                className="input-box-topbar-icon"
                onClick={() =>
                  this.setState((prevState: SelectBoxState) => ({
                    showTooltip: !prevState.showTooltip
                  }))
                }
              >
                <img
                  src={disabled ? images.IconInfoGrey : images.IconInfoBlue}
                />
                <div className="input-box-tooltip">
                  <TooltipBox
                    active={showTooltip}
                    tooltipTitle={tooltipTitle}
                    tooltipText={tooltipText}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {!disabled && (
          <input
            onFocus={() => this.onToggle(true, "")}
          />
        )}

        <div
          className="input-box-main"
          onClick={() => this.onToggle(!showList, value)}
        >
          <div className="input-box-main-field"
            onMouseEnter={() => {
              this.setState((prevState: SelectBoxState) => ({
                showSelector: true
              }))
            }}
            onMouseLeave={() => {
              this.setState((prevState: SelectBoxState) => ({
                showSelector: false
              }))
            }}>
            <BodyText >
              <p style={{
                whiteSpace: 'nowrap',
                paddingRight: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}>{isMediator ? this.getSelectorLabel(selectLabel) : selectLabel}</p>
              {mediatorSelector && !showList &&
                (<div className="input-box-tooltip">
                  <TooltipBoxLight
                    active={showSelector}
                    tooltipTitle={this.getSelectorLabel(selectLabel)}
                    noText={true}
                  />
                </div>)
              }
            </BodyText>
          </div>
          <div className="input-box-icon">
            <img
              src={
                disabled ? iconDisabled : showList ? images.IconCloseBlue : icon
              }
            />
          </div>
        </div>
        <ul className={`${health ? 'input-box-main-list-health' : 'input-box-main-list'}`}>
          {suggestedOptionsText &&
            suggestedOptionsText.map((option, i) => (
              <li
                key={i}
                onClick={() => {
                  this.onToggle(false, option[optionKey]);
                }}
              >
                <BodyText>
                  <p>{option[optionValue]}</p>
                </BodyText>
              </li>
            ))}
          {suggestedOptionsText && <div className="selector-separator" />}
          {optionsText.map((option, i) => (
            <li
              key={i}
              onClick={() => {
                if (!multiple) this.onToggle(false, option[optionKey]);
                else this.onCheck(i);
              }}
              className={hoveredItem === i ? 'hovered' : ''}
            >
              {multiple && (
                <div className="input-box-checkbox">
                  <CheckboxItemLayout
                    name=""
                    checked={checkedValues[i]}
                    onChange={() => this.onCheck(i)}
                    checkboxText={""}
                    errorText={""}
                    showError={false}
                  />
                </div>
              )}
              <BodyText>
                <p>{option[optionValue]}</p>
              </BodyText>
            </li>
          ))}
        </ul>

        {
          errorCode && (
            <div className="input-box-error">
              <NoteText>
                <p>{`* ${error_text}`}</p>
              </NoteText>
            </div>
          )
        }
      </SelectBoxContainer >
    );
  }
}

export default SelectBox;
