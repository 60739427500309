import styled from "styled-components";
import { colors } from "../../../assets";

export const CampaignsConfigParticipantsContainer = styled.div`
  font-family: Museo Sans;
  font-style: normal;
  font-weight: 500;
  .campaigns-config-participants-table {
    margin-bottom: 75px;
  }

  .campaigns-config-participants-pagination {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
  }

  .campaigns-config-participants-header {
    &__back {
      margin-bottom: 10px;
    }

    &__title {
      font-size: 32px;
      line-height: 45px;
      color: ${colors["PR-001-100"]};
      margin-bottom: 10px;
      margin-bottom: 33px;
    }
  }

  .campaigns-config-participants-filters {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    grid-gap: 37px;
    margin-bottom: 70px;
  }
`;
