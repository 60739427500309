import styled from "styled-components";
import { colors } from "../../assets";
import { MainButtonContainer } from "../main-button/main-button-style";

export const NotificationModalContainer = styled.div`
  display: none;
  color: ${colors["PR-001-100"]};
  position: fixed;
  z-index: 9999;
  background: ${colors["background-modal"]};
  left: 0;
  width: 100%;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &.active {
    display: flex;
  }

  .notification-modal-container {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 20px 22px 40px 40px;
    max-width: 620px;
    max-height: 90vh;
    overflow: hidden;
    overflow-y: scroll;
  }

  .notification-modal-close {
    text-align: right;

    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .notification-modal-main-title {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }

  .notification-modal-main-date {
    color: ${colors["TX-001-50"]};
    margin-bottom: 16px;
  }

  .notification-modal-main-subtitle {
    color: ${colors["TX-001-100"]};
    margin-bottom: 20px;
  }

  .notification-modal-icon {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notification-modal-icon-bg {
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 20px;
    }
  }

  .notification-modal-main {
    padding-right: 65px;
  }

  .notification-modal-main-copy {
    margin-bottom: 44px;
    color: ${colors["TX-001-50"]};
    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .notification-modal-text {
    padding-left: 12px;
  }

  .notification-modal-main-conditions {
    margin-bottom: 40px;
  }

  .notification-modal-main-conditions-item {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .notification-modal-button {
    display: flex;

    ${MainButtonContainer} {
      width: 160px;

      &:first-child {
        margin-right: 12px;
      }
    }
  }

  .doc-row {
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 46px;
    }
  }

  .doc-row-item {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .doc-row-icon {
    margin-right: 16px;

    img {
      max-width: 18px;
    }
  }

  .doc-row-text {
    text-transform: uppercase;
    font-family: "museo sans";
    font-weight: 500;
    font-size: 14px;
    color: ${colors["TX-001-50"]};
  }
`;
