import React, { Component } from 'react';
import { TooltipBoxContainer } from './tooltip-box-style';
import InfoText from '../components-text/info-text';
import Menu02Text from '../components-text/menu-02-text';

interface TooltipBoxProps {
    active: boolean;
    tooltipTitle?: string;
    tooltipText?: string;
    leftArrow?: boolean;
    centerArrow?: boolean;
    type?: string;
    left?: string;
    top?: string;
    fontSizeSmall?: boolean;
}

class TooltipBox extends Component<TooltipBoxProps, {}> {
    render() {
        const { active, tooltipTitle, tooltipText, leftArrow, centerArrow, left, top, fontSizeSmall } = this.props;

        return (
            <TooltipBoxContainer tooltipText={tooltipText} className={`${active ? 'active' : ''}`} leftArrow={leftArrow} centerArrow={centerArrow} left={left} top={top}>
                <div className={`tooltip-modal${left && top ? '-mediator' : ''}`}>
                    <div className="tooltip-title">
                        <Menu02Text fontSizeSmall={fontSizeSmall}>
                            <p>{tooltipTitle}</p>
                        </Menu02Text>
                    </div>
                    {tooltipText && (
                        <div className="tooltip-main">
                            <InfoText>
                                <p>{tooltipText}</p>
                            </InfoText>
                        </div>
                    )}
                </div>
            </TooltipBoxContainer>
        )
    }
}

export default TooltipBox