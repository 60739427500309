import styled from "styled-components";
import { colors } from "../../assets";

export const HeaderContainer = styled.header`
  background: #ffffff;
  font-family: "museo sans";
  
  .header-top {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100vw;
    height: 100px
  }

  .header-top-advice {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    background: #89D4F3;
    margin-left: 270px;
    margin-right: 40px;
    padding: 8px 40px;
    height: 70px;
    top: 0;
    left: 0;
    width: calc(100% - 320px);
    &__text {
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
    }
    &__button {
      cursor: pointer;
      text-align: center;
      background: #004F8B;
      border: 1.5px solid #004F8B;
      box-sizing: border-box;
      border-radius: 3px;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
      padding: 8px 11px;
      min-width: 190px;
      margin-left: 10px;
    }
  }
  .header-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top: ${props => props.isHome && props.showPortalAntiguo ? '70px' : 0};
    left: 0;
    right: 0;
    height: 80px;
    padding: 20px 30px;
    z-index: 36;
    background: #ffffff;
  }

  .profile-sidebar-data-tooltip {
    position: fixed;
    top: ${props => props.isHome ? '142px' : '62px'};
  }

  .header-notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &.pendant {
      &:before {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: ${colors["SC-001-100"]};
        position: absolute;
        top: -1px;
        right: -1px;
      }
    }
  }

  .header-item {
    margin-right: 40px;
    padding: 8px;
    cursor: pointer;

    &.notifications {
      padding: 0;
      cursor: default;

      &.active {
        .new-notifications {
          right: 40px;
        }
      }
    }

    .header-user-name {
      font-weight: 700;
      font-size: 14px;
      color: ${colors["PR-001-100"]};

      &.active {
        color: ${colors["SC-001-100"]};
      }
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      max-width: 24px;
    }
  }

  .new-notifications {
    position: fixed;
    top: 72px;
    right: -390px;
    width: 390px;
    padding: 22px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    background: #fff;
    z-index: 99;

    .arrow-up {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid white;
      position: absolute;
      top: -12px;
      left: 164px;
    }
  }

  .new-notifications-title {
    margin-bottom: 12px;
    color: ${colors["TX-001-30"]};
  }

  .new-notifications-container {
    margin-bottom: 12px;
  }

  .new-notifications-item {
    text-overflow: ellipsis;
    display: flex;
    padding: 12px 0;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    transition: 0.3s all ease-in-out;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    img {
      max-width: 16px;
      margin-right: 16px;
    }

    &:hover {
      background: rgba(0, 79, 139, 0.1);
    }
  }

  .new-notifications-see-more {
    text-align: right;
    color: ${colors["TX-001-30"]};
  }

  .new-notifications-see-more-item {
    display: inline-block;
    cursor: pointer;
  }
`;

export const ProfileContainer = styled.aside`
  font-family: "museo sans";
  position: fixed;
  top: 80px;
  left: 100%;
  transition: 0.3s all ease-in-out;
  z-index: 20;
  background: #fff;

  &.active {
    left: calc(100% - 280px);
  }

  .profile-sidebar {
    height: calc(100vh - 80px);
    padding: 0 40px;
    width: 280px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  }

  .profile-sidebar-close {
    margin-bottom: 60px;
    text-align: right;
    .profile-sidebar-close-item {
      cursor: pointer;
      display: inline-block;
      img {
        max-width: 16px;
      }
    }
  }

  .profile-sidebar-name {
    text-align: center;
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 20px;
    color: ${colors["PR-001-100"]};
    line-height: 1.35em;
  }

  .profile-sidebar-action {
    margin-bottom: 48px;
  }

  .profile-sidebar-data-container {
    padding: 40px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-bottom: 40px;
  }

  .profile-sidebar-data-item {
    margin-bottom: 28px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .profile-sidebar-data-item-title {
    color: ${colors["TX-001-30"]};
    margin-bottom: 6px;
  }

  .profile-sidebar-data-item-description {
    color: ${colors["PR-001-100"]};
  }

  

  @media (max-height: 860px) {
    .profile-sidebar {
      padding-right: 24px;
    }
  }
`;
