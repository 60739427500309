export const priority = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

export const MAX_CHARACTERS: { [key: string]: number } = {
  RESUMEN: 100,
  DESCRIPTION: 500,
};

export const STATUS = {
  DRAFT: 1,
  ACTIVE: 2,
  DELETED: 3,
};

export const MEDIATOR: { [key: string]: { label: string; value: string } } = {
  ASISA: { label: "Mediador ASISA", value: "SUSCAGENTE" },
  OCASO: { label: "Mediador OCASO", value: "SUSCAGENTEOCASO" },
};
