import React from "react";
import { H3TextContainer } from "./h3-text-styles";

interface H3TextProps {
    children: any;
    className?: string;
    color?: string;
}

const H3Text = ({children = '', className='', color=''}:H3TextProps) => {
        return (
        <H3TextContainer color={color} className={className}>
            {children}
        </H3TextContainer>
    )
} 
export default H3Text;