import * as React from "react";
import { CampaignProgressStyle } from "./campaign-progress-style";
import BreadcrumbText from "../components-text/breadcrumb-text";
import WebText from "../components-text/web-text";
import { ICondition } from "../../containers/campaigns-config/campaigns-create/modals/modal-add-condition";
import { ALL_PRODUCTS_KEY_VALUE } from "../../constants/campaignsConfig";

export interface ICampaignProgressProps {
  prizes: ICondition[][];
}

export interface ICampaignProgressState {
  prizesRefs: React.Ref<Element>[];
}

export default class CampaignProgress extends React.Component<
  ICampaignProgressProps,
  ICampaignProgressState
> {
  constructor(props: ICampaignProgressProps) {
    super(props);

    this.state = {
      prizesRefs: []
    };
  }

  componentDidMount() {
    const { prizes } = this.props;
    const prizesRefs: React.Ref<Element>[] = [];
    prizes.forEach(() => {
      prizesRefs.push(React.createRef());
    });
    this.setState({ prizesRefs }, () => {
      this.displayPrizes();
    });
  }

  componentDidUpdate(prevProps: ICampaignProgressProps) {
    const { prizes } = this.props;
    if (prevProps.prizes !== prizes) {
      const prizesRefs: React.Ref<Element>[] = [];
      prizes.forEach(() => {
        prizesRefs.push(React.createRef());
      });
      this.setState({ prizesRefs }, () => {
        this.displayPrizes();
      });
    }
  }

  displayPrizes() {
    const { prizesRefs } = this.state;
    prizesRefs.forEach((prize: any, index: number) => {
      prize.current.style.right = `${index * (500 / prizesRefs.length)}px`;
    });
  }

  render() {
    const { prizes } = this.props;
    const { prizesRefs } = this.state;

    return (
      <CampaignProgressStyle>
        {prizesRefs.map((ref: any, index) => (
          <div className="campaign-progress-reward" ref={ref}>
            <div className="campaign-progress-reward-dot" />
            <div className="campaign-progress-reward__title">
              <BreadcrumbText>{`${index + 1}º Premio`}</BreadcrumbText>
            </div>
            <div className="campaign-progress-reward__subtitle">
              {prizes[index] &&
                prizes[index].map(condition => {
                  return condition.products.map((product: string) => (
                    <WebText>{`${condition.value} ${ALL_PRODUCTS_KEY_VALUE[product]}`}</WebText>
                  ));
                })}
            </div>
          </div>
        ))}
        <div className="campaign-progress-line">
          <div className="campaign-progress-line__track" />
        </div>

        <div className="campaign-progress-status">
          <div className="campaign-progress-status-dot" />
          <div className="campaign-progress-status__title"></div>
          <div className="campaign-progress-status__subtitle">
            <WebText>80.000 Salud TODO</WebText>
            <WebText>650 Productos TODO</WebText>
          </div>
        </div>
      </CampaignProgressStyle>
    );
  }
}
