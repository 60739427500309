import React, { Component } from "react";
import { ZeroContentContainer } from "./zero-content-style";
import { images } from "../../assets/images";
import WebText from "../components-text/web-text";

interface ZeroContentProps {
  className?: string;
  img?: string;
  title?: string;
  text?: string;
}

interface ZeroContentState {}

class ZeroContent extends Component<ZeroContentProps, {}> {
  state: ZeroContentState = {};

  public static defaultProps = {
    className: "",
    img: images.WaveNoContent,
    title: "Ooops",
    text: "Parece que no hay contrataciones pendientes. Siga navegando por el portal para consultar los datos necesarios.",
  };

  componentDidMount = () => {
    setTimeout(function() {
      window.scrollTo(0, 0);
    }, 100);
  };

  componentDidUpdate() {}

  render() {
    const { className, img, title, text } = this.props;
    const {} = this.state;

    return (
      <ZeroContentContainer className={className}>
        <div className="zero-content-container">
          <div className="zero-content-img">
            <img src={img} alt="" />
          </div>
          <div className="zero-content-title">
            <p>{title}</p>
          </div>
          <div className="zero-content-text">
            <WebText>{text}</WebText>
          </div>
        </div>
      </ZeroContentContainer>
    );
  }
}

export default ZeroContent;
