import styled from "styled-components";

export const HealthAddressFormContainer = styled.div`
  .personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  .personal-data-wrapper {
    overflow: initial;
    padding-right: 16px;
    padding: 20px;
    background-color: #fbfbfb;
    display: block;
    width: 100%;
    float: left;
    margin-top: 40px;
  }
  @media (min-width: 320px) {
    .personal-data-wrapper {
      width: 55% !important;
    }
  }
  @media (min-width: 1201px) {
    .personal-data-wrapper {
      width: 100% !important;
    }
  }
`;
