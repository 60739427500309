import { RouteComponentProps } from "react-router-dom";
import { ValueFormHealth } from "../../../../../constants/health";
import PolicyFunctional from "../../../../../functional/policy";
import { IMediator } from "../../../../../models/mediator-model";
import { IUser } from "../../../../../models/user-model";
import { ProductPaymentMethod } from "../../../../../services/PayMethodServices.dto";

// CONSTS

export const DateFormat: string = "DD/MM/YYYY";

export enum DOCUMENT_TYPE {
    NIE = 'NIE',
    NIF = 'NIF',
    NIFP = 'NIFP',
    PASP = 'PASP',
    CIF = 'CIF',
    MENORES = 'MENORES'
}

// PROPS

export interface IIban {
    iban: string;
    sameAsMain: boolean;
    sepaNodeId?: string;
    sepaSignedDate?: string; // dd/MM/yyyy
}

export interface PaymentMethod {
    mainIban: IIban;
    copayIban?: IIban;
    areInsuredsCopayGrouped?: boolean;
    isPhysicalSignature: boolean;
}

export interface PaymentInsuredData {
    readonly name: string;
    readonly lastname1: string;
    readonly lastname2?: string;
    readonly docType: DOCUMENT_TYPE;
    readonly docNumber: string;
    copay?: IIban;
}

export interface PaymentMethodsProps extends RouteComponentProps {
    paymentMethod?: PaymentMethod;
    cotizacion: string;
    fechaEfecto: string;
    takerDocumentId: string;
    policy: PolicyFunctional;
    insuredsCopay: PaymentInsuredData[];
    saveHiring: (saveActualHealthStep: boolean) => void;
    isReadOnly: boolean;
    setLoading: Function;
    user: IUser;
    mediator: IMediator;
    data: any;
    showCalendar: boolean;
    removeSign: Function;
    productsByAreaName: any;
    checkProductArea: Function;
}

// STATES
export interface ISepaFileState {
    error: string;
    disabled: boolean;
    isSigned: IBooleanInputState;
    linkedIban: string;
    signedDate: ValueFormHealth; // dd/MM/yyyy
    isRequired: boolean;

    file?: File;
    nodeId?: string;
}

export interface ISignedFileState {

    error: string;
    disabled: boolean;
    isRequired: boolean;
    file?: File;

}
export interface IBooleanInputState {
    value: boolean;
    isDisabled: boolean;
}

export interface PaymentMethodsInsuredState {
    isIbanSameAsMain: IBooleanInputState;
    iban: ValueFormHealth;
    sepa: ISepaFileState;
    isOpen: boolean;
    isIbanRequired: boolean;
}

export interface IPaymentMethodsFormValuesState {
    mainIban: ValueFormHealth;
    areInsuredsCopayGrouped: IBooleanInputState;
    isIbanCopaySameAsMain: IBooleanInputState;
    copayIban: ValueFormHealth;
    insureds: PaymentMethodsInsuredState[];
    isPhysicalSignature: IBooleanInputState;
    fechaEfectoRetroactiva?: 'N' | 'S';
}

export interface PaymentMethodsState {
    formValues: IPaymentMethodsFormValuesState;
    mainSepa: ISepaFileState;
    copaySepa: ISepaFileState;
    signedDocument: ISignedFileState;
    anyChange: boolean;
    modalErrorShow: boolean;
    modalErrorText: string;
    previousModalShow: boolean;
    replaceDocModalShow: boolean;
    replaceDocModalCallback: Function;
    showCalendar: boolean;
    cancelSignModalShow: boolean;
    isCopay:boolean;
    productPaymentMethod: ProductPaymentMethod;
}
