import styled from "styled-components";
import { colors } from "../../assets";
import { MainButtonContainer } from "../main-button/main-button-style";
import { InputBoxContainer } from "../input-box/input-box-style";
import { SelectBoxContainer } from "../select-box/select-box-style";

export const AddBeneficiariesModalContainer = styled.div`
  display: none;
  color: ${colors["PR-001-100"]};
  position: fixed;
  z-index: 9999;
  background: ${colors["background-modal"]};
  left: 0;
  width: 100%;
  top: 0;
  align-items: center;
  font-family: 'museo sans';
  justify-content: center;
  height: 100vh;
  
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    width:50%;
  }
  .add-beneficiaries-modal-users-msg {
    width: 100%; 
    text-align: center;
  }
  
  .center {
    width:100%
    text-align: center;
  }

  .add-beneficiaries-modal-column-content {
    margin-top: 16px;
    border-left: 1px solid #aaa;
    padding-left:20px;
  }

  .error {
    color: ${colors["ER-001-100"]};
  }
  .disabled {
    background:#F7F7F7
  }

  &.active {
    display: flex;
  }

  &.error {
    color: ${colors["ER-001-100"]};

    .add-beneficiaries-modal-icon-bg {
      background: rgba(255, 65, 0, 0.3);
    }

    ${MainButtonContainer} {
      background: ${colors["ER-001-100"]};
    }
  }

  &.warning {
    .add-beneficiaries-modal-icon-bg {
      background: rgba(255, 240, 201, 0.3);
    }
  }

  .add-beneficiaries-modal-container {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 20px 20px 20px;
    width: 950px
    max-width: 950px;
    max-height: 90vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .select-beneficiarie {
    width: 600px;
  }

  .firts-button {
    padding-right: 12px;
  }

  .actions-select {
    display: flex;
    margin-top: 40px;
  }

  .select-modal-main-action {
    display: flex;
    margin-top: 20px;
  }
  
  .add-beneficiaries-modal-container::-webkit-scrollbar {
    display: none;
  }

  .add-beneficiaries-modal-close {
    position: relative;
    float: right;
    top: -10px;
    right: -10px;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .add-beneficiaries-modal-main-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .add-beneficiaries-modal-icon {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-beneficiaries-modal-icon-bg {
    display: inline-block;
    background: rgba(179, 202, 220, 0.3);
    width: 62px;
    height: 62px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 20px;
    }
  }

  .add-beneficiaries-modal-main-content {
  }

  .add-beneficiaries-modal-main {
  }

  .add-beneficiaries-modal-main-copy {
    margin-bottom: 20px;
    color: ${colors["TX-001-50"]}};
  }

  .add-beneficiaries-modal-text {
    padding-left: 20px;
  }

  .add-beneficiaries-modal-main-conditions {
    margin-bottom: 40px;
  }

  .add-beneficiaries-modal-main-conditions-item {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .add-beneficiaries-modal-button {
    display: flex;
    place-self: center;
    ${MainButtonContainer} {
      width: 190px;
      margin-top: 16px;
    }
  }modal-button

  .add-beneficiaries-modal-users-container {
    margin-bottom: 38px;
    ${InputBoxContainer} {
      input {
        border-radius: 3px;
        padding-top: 6px;
        padding-bottom: 9px;
        top: 45%;
      }
    }
    /* ${SelectBoxContainer} {
      .input-box-main-field {
        border-radius: 3px;
        padding-bottom: 9px;
        top: 45%;
      }
    } */
  }
  .add-beneficiaries-columns-container {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;

  }

  .add-beneficiaries-modal-users-row {
    width: 100%;
    margin-bottom: 16px;
    padding-right: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    &__item {
      margin-right: 12px;
      width: 100%;
      padding-left: 77px;
      &:last-child {
        margin-right: 0;
      }

      
      &.percentage {
        width: 218px;
        margin-right: 24px;
      }

      &.actions {
        height: 48px;
        display:flex;
        padding-left:0px;
        img {
          width: 24px;
          height: 24px;
        }

        .action-item {
          cursor: pointer;
          padding: 4px 4px 4px 8px;
          margin-right: 12px;
          height: 35px;
          max-height: 35px;
          position: relative;
          width:230px;
          background:#274074;
          border-radius:10px;
          
          label {
            position: absolute;
            left: 35px;
            top: 10px;
            cursor: pointer;
            color:#fff;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .action-item.disabled {
          background: #F7F7F7;
        }
        .action-item.disabled {
          color: #B5BCC6;
        }
      }
    }
  }

  .add-beneficiaries-modal-users-add {
    text-transform: uppercase;
    color: #6cc9f0;
    margin-bottom: 34px;
    font-weight: 500;
    font-size: 14px;
    p {
      display: inline-block;
      cursor: pointer;
    }
  }

  .add-beneficiaries-modal-users-check-container {
    margin-bottom: 40px;
  }

  .add-beneficiaries-modal-users-check-item {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .added-users-container {
    margin-bottom: 28px;
    height: 506px;
    overflow-y: auto;
  }

  .added-users-row {
    display: flex;
    justify-content: space-between;
    background: rgba(181, 188, 198, 0.15);
    padding: 14px;
    border-radius: 13px;
    margin-bottom: 12px;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    &__text {
      &__name {
        margin-bottom: 8px;
      }
      &__info {
      }
    }

    &__action {
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
      flex-direction: column;
      &__button {
        padding: 4px;
        cursor: pointer;
      
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;
