export const navSimulationItems = [
  {
    link: "/simulacion/pendiente",
    text: "Pendientes",
  },
  {
    link: "/simulacion/expirada",
    text: "Expiradas",
  },
  {
    link: "/simulacion/nueva",
    text: "Nueva",
  },
];

export const navHiringItem = [
  {
    link: "/contratacion/pendiente",
    text: "Pendientes",
  },
  {
    link: "/contratacion/realizada",
    text: "Realizadas",
  },
  {
    link: "/contratacion/pendiente?new=true",
    text: "Nueva",
  },
];

export const navClientsItem = [
  {
    link: "/polizas-clientes",
    text: "Pólizas",
  },
  /* {
    link: "/liquidaciones-clientes",
    text: "Liquidaciones",
  }, */
  {
    link: "/recibos-clientes",
    text: "Recibos",
  },
  {
    link: "/ficheros-eiac",
    text: "Ficheros EIAC",
  },
  // {
  //   link: "/facturas",
  //   text: "Facturas",
  // },
  {
    link: "/informes",
    text: "Informes",
  }
];
export const navClientsItemSuplemento = [
  {
    link: "/suplementos",
    text: "Suplementos",
  },

];
