import styled from "styled-components";
import { colors } from "../../../assets";

export const CampaignsConfigPointsContainer = styled.div`
  .campaigns-config-points-section-title {
    margin-bottom: 75px;
    color: #004f8b;
  }

  .campaigns-config-points-filter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 75px;
    &__item {
      width: 100%;
      max-width: 160px;
    }
  }

  .campaigns-config-points-table {
    margin-bottom: 75px;
  }

  .campaigns-config-points-pagination {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
  }
`;
