import styled from "styled-components";
import { colors } from "../../assets";

export const CalendarItemContainer = styled.div`
  background: white;
  width: 320px;
  color: ${colors["PR-001-100"]};
  font-size: 13px;
  font-family: "museo sans";
  border-radius: 8px;

  .arrows {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .calendar-select-type  {
    display: flex;
  }

  .current-month {
    text-transform: uppercase;
    color: ${colors["HV-TextLink"]};
    font-family: "museo sans";
    font-size: 14px;
    padding: 6px 16px 6px 0;
    position: relative;
    min-width: 100px;

    .pick-arrow {
      position: absolute;
      z-index: 9;
      right: 0;

      img {
        max-width: 14px;
        cursor: pointer;
      }

      &.top {
        top: 0;
      }

      &.bottom {
        bottom: 0;
      }
    }
  }

  .current-year {
    text-transform: uppercase;
    color: ${colors["HV-TextLink"]};
    font-family: "museo sans";
    font-size: 14px;
    padding: 6px 16px 6px 0;
    position: relative;

    .pick-arrow {
      position: absolute;
      z-index: 9;
      right: 0;

      img {
        max-width: 14px;
        cursor: pointer;
      }

      &.top {
        top: 0;
      }

      &.bottom {
        bottom: 0;
      }
    }
  }

  .calendar-header  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .calendar-arrow-container {
    display: flex;
    align-items: center;
  }

  /* GRID */

  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  /* Calendar */

  .calendar {
    display: block;
    position: relative;
    width: 100%;
    background: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 24px 18px 28px;
  }

  .header {
    text-transform: uppercase;
    font-weight: 700;
    padding: 1.5em 0;
    border-bottom: 1px solid ${colors["PR-001-100"]};
  }

  .icon {
    cursor: pointer;
    transition: 0.15s ease-out;

    &:hover {
      color: ${colors["PR-001-100"]};
    }

    &:first-of-type {
      margin-left: 1em;
    }

    &:last-of-type {
      margin-right: 1em;
    }
  }

  .calendar-days {
    display: flex;
    justify-content: space-between;
  }

  .calendar-days-day {
    text-transform: uppercase;
    font-weight: 400;
    padding: 12px 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors["PR-001-100"]};
  }

  .cell {
    position: relative;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    transition: 0.25s ease-out;
    border-radius: 50%;

    &:hover {
      background: ${colors["PR-001-30"]};
      transition: 0.5s ease-out;

      .bg {
        opacity: 0.05;
        transition: 0.5s ease-in;
      }

      &::after  {
        content: "";
        width: 30px;
        height: 30px;
        background: ${colors["PR-001-100"]};
        color: #ffffff;
        position: absolute;
        left: 5px;
        top: 5px;
        border-radius: 100%;
        z-index: -1;
        opacity: 0.5;
      }
    }
  }

  .selected {
    position: relative;
    z-index: 1;
    color: #ffffff;

    .bg {
      opacity: 0.05;
      transition: 0.5s ease-in;
    }

    p {
      color: #fff;
    }

    &::after  {
      content: "";
      color: #ffffff;
      width: 30px;
      height: 30px;
      background: ${colors["PR-001-100"]};
      position: absolute;
      left: 5px;
      top: 5px;
      border-radius: 100%;
      z-index: -1;
    }
    &:hover {
      background: white;
    }
  }

  .disabled {
    opacity: 0;
    pointer-events: none;
  }

  .no-allow {
    opacity: 0.5;
    pointer-events: none;
  }

  .arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 14px;
      cursor: pointer;
    }
  }

  .arrow-left {
    margin-right: 30px;
    position: relative;
    &:before {
      content: "";
      background: ${colors["SC-001-50"]};
      border-radius: 50%;
      width: 6px;
      height: 6px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -18px;
    }
  }

  .select-month-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9;
    display: none;
    &.active {
      display: block;
    }
  }

  .select-month-container-row {
    display: flex;
    color: ${colors["PR-001-100"]};
    height: 25%;
  }

  .select-month-container-item {
    width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    text-transform: capitalize;

    &:hover {
      background: #f7f7f7;
    }
  }

  .year-select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    z-index: 12;
    display: none;

    &.active {
      display: block;
    }

    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    li {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      margin-bottom: 16px;
    }
  }

  .year-select-close {
    text-align: right;
    padding: 10px 16px;

    img {
      max-width: 16px;
      cursor: pointer;
    }
  }
`;
