import React, { Component } from "react";
import { GoBackContainer } from "./go-back-style";
import { images } from "../../assets";
import { withRouter } from "react-router";
import BreadcrumbText from "../components-text/breadcrumb-text";

interface GoBackProps {
  img?: string;
  text?: string;
  onclick?: any;
}

class GoBackLayout extends Component<any, {}> {
  public static defaultProps = {
    img: images.GoBackArrow,
  };

  render() {
    const { img, text, onclick } = this.props;

    return (
      <GoBackContainer>
        <div className="go-back-container">
          <img src={img} onClick={() => onclick()} />
          <div className="go-back-text" onClick={() => onclick()}>
            <BreadcrumbText>
              <p>{text}</p>
            </BreadcrumbText>
          </div>
        </div>
      </GoBackContainer>
    );
  }
}

export default withRouter(GoBackLayout);
