import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import MainButton from "../../../../../components/main-button";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { TakerDataContainer } from "./gip-taker-data-style";
import PersonalInformation, { PersonalData } from "../forms/personal-information/gip-personal-information";
import HealthAddressFormItem, { AddressData } from "../forms/address-item/gip-address-form-item";
import HealthContactFormItem, { ContactData } from "../forms/contact-item/gip-contact-form-item";
import PolicyData from "../forms/policy-data/gip-policy-data";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { HealthHireMenuHelper } from "../../../../../components/health-hire-menu/health-hire-menu";
import { GipHireMenuHelper } from "../../../../../components/gip-menu/gip-hire-menu";
import { IMediator } from "../../../../../models/mediator-model";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";

export interface PolicyDataInterface {
  startDate: string;
  paymentType: string;
  iban: string;
  selectedMediator: string;
}

export interface takerData {
  addressData: AddressData,
  contactData: ContactData
}

interface TakerProps extends RouteComponentProps {
  user: any;
  policy: any;
  setLoading: Function;
  data: any;
  saveHiring: Function;
  isReadOnly: boolean;
  match: any;
  mediator: IMediator;
  selectedMediatorCode: string;
  removeSign: Function;
}

interface TakerState {
  physicalPerson: boolean,
  validatePersonalInformation: {
    validateRequest: boolean,
    isValidated: boolean
  },
  validatePolicyData: {
    validateRequest: boolean,
    isValidated: boolean
  },
  validateAddress: {
    validateRequest: boolean,
    isValidated: boolean,
  },
  validateContact: {
    validateRequest: boolean,
    isValidated: boolean,
  },
  personalData: PersonalData;
  addressData: AddressData;
  contactData: ContactData;
  policyData: PolicyDataInterface;
  nextScreen: boolean;
  anyChange: boolean;
  previousModalShow: boolean;
  isCopay: boolean;
  cancelSignModalShow: boolean;
}

class GipTakerDataLayout extends React.Component<TakerProps, TakerState> {

  constructor(props: TakerProps) {
    super(props);

    this.state = {
      physicalPerson: props.data.taker.physicalPerson !== undefined ? props.data.taker.physicalPerson : true,
      validatePersonalInformation: {
        validateRequest: false,
        isValidated: false
      },
      validatePolicyData: {
        validateRequest: false,
        isValidated: false
      },
      validateAddress: {
        validateRequest: false,
        isValidated: false
      },
      validateContact: {
        validateRequest: false,
        isValidated: false
      },
      personalData: {
        name: '',
        birthDate: new Date(),
        docNumber: '',
        gender: '',
        docType: '',
        lastname1: '',
        nationality: '',
        civilStatus: '',
        nationalityLabel: '',
        profession: { value: '', code: '', other: '' },
        lastname2: '',
        sameAsTaker: false,
        passportDate: undefined,
        physicalPerson: true,
      },
      addressData: {
        address: { value: '', code: '', label: '' },
        wayType: '',
        number: '',
        postalCode: '',
        province: { value: '', code: '', label: '' },
        town: { value: '', code: '', label: '' },
        other: '',
        sameAsTaker: false
      },
      contactData: {
        comunicationChannel: '',
        comunicationLanguage: '',
        email: '',
        mobilePhone: '',
        paperless: '',
        contactHour: '',
        landlinePhone: '',
      },
      policyData: {
        startDate: '',
        paymentType: '',
        iban: '',
        selectedMediator: ''
      },
      nextScreen: false,
      anyChange: false,
      previousModalShow: false,
      isCopay: false,
      cancelSignModalShow:false
    };
  }

  componentDidMount = () => {
    const { policy } = this.props
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    const isCopay= policy.product.products_isCopay
    this.setState({ isCopay});
    
  };

  componentDidUpdate = (prevProps: Readonly<TakerProps>, prevState: Readonly<TakerState>) => {
    const { validatePersonalInformation, validateAddress, validateContact, nextScreen } = this.state;
    const { history, policy } = this.props;


    if (this.state.physicalPerson && nextScreen && validatePersonalInformation.isValidated && validateAddress.isValidated && validateContact.isValidated)
      history.push(
        `/contratacion/nueva/${GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
        }/asegurados/${this.props.data.insureds.length > 0 ? this.props.data.insureds.length - 1 : ''
        }`
      );
    else if (nextScreen && validatePersonalInformation.isValidated && validateAddress.isValidated)
      history.push(
        `/contratacion/nueva/${GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
        }/asegurados/${this.props.data.insureds.length > 0 ? this.props.data.insureds.length - 1 : ''}`
      );
      if(Object.keys(prevProps.policy.product).length===0 && Object.keys(policy.product).length!==0){
        const isCopay = policy.product.products_isCopay
        this.setState({ isCopay});
      }

  }

  receiveTakerAddress(takerAddress: AddressData) {
    let { addressData } = this.state;
    addressData = takerAddress;
    this.setState({ addressData });
  }

  receiveTakerContact(takerContact: ContactData) {
    let { contactData } = this.state;
    contactData = takerContact;
    this.setState({ contactData });
  }

  receiveInsuredPerson(takerPerson: PersonalData) {
    let { personalData } = this.state;
    personalData = takerPerson;
    this.setState({ personalData });
  }

  receiveTakerPolicy(takerPolicy: PolicyDataInterface) {
    let { policyData } = this.state;
    policyData = takerPolicy;
    this.setState({ policyData });
  }

  errorScrollTo() {
    const { validatePersonalInformation, validateAddress, validateContact } = this.state;
    let element = ''
    if (!validatePersonalInformation.isValidated) {
      element = 'data-personal'
    }
    else if (!validateAddress.isValidated) {
      element = 'data-address'
    }
    else if (!validateContact.isValidated) {
      element = 'data-contact'
    }
    if (element !== '') {

      const section = document.getElementById(element);
      if (section) {

        const pixelY = section.offsetTop
        if (pixelY > 0) window.scrollTo(0, pixelY)

      }
    }
  }

  async addTaker() {
    if (this.props.isReadOnly) {
      const { history } = this.props;
      history.push(
        `/contratacion/nueva/${GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
        }/asegurados/${this.props.data.insureds.length > 0 ? this.props.data.insureds.length - 1 : ''
        }`
      );
    }

    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED  && this.state.anyChange === true)
      this.setState({ cancelSignModalShow: true })
    else {
      await this.validateAndNextScreen()
    }
  }

  async validateAndNextScreen() {

    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true ){
      await this.props.removeSign()
    }

    await this.toValidate();
    const { validatePersonalInformation, validateAddress, validateContact, validatePolicyData, isCopay } = this.state;
    if (validatePersonalInformation.isValidated && validatePolicyData.isValidated && validateAddress.isValidated && validateContact.isValidated) {
      const { personalData, addressData, contactData, policyData } = this.state;
      let taker = {
        taker: true,
        ...personalData,
        ...contactData,
        address: addressData,
        policy: policyData,
        isCopay: isCopay
      }

      this.props.policy.setHealthData(taker);
      this.props.saveHiring();
      this.setState({ nextScreen: true })

    } else {
      this.errorScrollTo()
    }
  }

  toValidate = async () => {
    let {
      validatePersonalInformation,
      validatePolicyData,
      validateAddress,
      validateContact
    } = this.state

    validatePersonalInformation.validateRequest = true
    validatePolicyData.validateRequest = true
    validateAddress.validateRequest = true
    validateContact.validateRequest = true

    this.setState({
      validatePersonalInformation,
      validatePolicyData,
      validateAddress,
      validateContact
    });
  }

  handlePersonChange(value: any) {
    if (value === 'physical') {
      this.setState({ physicalPerson: true });
    }
    else {
      this.setState({ physicalPerson: false });
    }
  }

  onComponentAnyChange() {
    const { anyChange } = this.state;
    if (!anyChange)
      this.setState({ anyChange: true });

    let { data } = this.props;
    if (Object.keys(data.taker).length === 0) {
      //Si es la primera vez que se crea el tomador
      data.RGPD_tomador_nuevo = ''
    }
    else {
      //Si el tomador se está modificando
      data.RGPD_tomador_nuevo = 'SI'
    }
  }

  render() {

    const {
      validatePersonalInformation,
      validatePolicyData,
      validateAddress,
      validateContact,
      physicalPerson,
      cancelSignModalShow
    } = this.state

    return (
      <TakerDataContainer>
        <div className="colSin-100">
          <div className="data-flow-status">
            <div className="data-flow-status-title">
              <h2>DATOS DEL TOMADOR</h2>
            </div>
          </div>

          <PersonalInformation
            validate={validatePersonalInformation}
            onValidated={(taker: PersonalData) => this.receiveInsuredPerson(taker)}
            handlePersonChange={(value: any) => this.handlePersonChange(value)}
            setLoading={this.props.setLoading}
            insured={false}
            insureds={this.props.data.insureds}
            taker={this.props.data.taker}
            insuredIndex={-1}
            isReadOnly={this.props.isReadOnly}
            onAnyChange={() => { this.onComponentAnyChange(); }}
            currentHealthStep={HealthHireMenuHelper.getStepFromNavigationRoute(this.props.history.location.pathname)}
            physicalPersonValue={physicalPerson}
          />
          <HealthAddressFormItem
            validate={validateAddress}
            onValidated={(taker: AddressData) => this.receiveTakerAddress(taker)}
            setLoading={this.props.setLoading}
            physicalPerson={physicalPerson}
            taker={this.props.data.taker.address}
            insuredIndex={-1}
            isReadOnly={this.props.isReadOnly}
            onAnyChange={() => { this.onComponentAnyChange(); }}
          />
          <div>
            <HealthContactFormItem
              validate={validateContact}
              onValidated={(taker: ContactData) => this.receiveTakerContact(taker)}
              taker={this.props.data.taker}
              insuredIndex={-1}
              isReadOnly={this.props.isReadOnly}
              onAnyChange={() => { this.onComponentAnyChange(); }}
            />

            <PolicyData
              validate={validatePolicyData}
              onValidated={(taker: PolicyDataInterface) => this.receiveTakerPolicy(taker)}
              data={this.props.data}
              isReadOnly={this.props.isReadOnly}
              mediator={this.props.mediator}
              policy={this.props.policy}
              selectedMediatorCode={this.props.selectedMediatorCode}
            />
          </div>
        </div>

        <div className="colSin-100 topMargin20 bottomMargin20">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => { this.onPreviousClick(); }}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                text="Siguiente"
                onClick={() => this.addTaker()}
              />
            </div>
          </div>
        </div>

        <LeftInfoModal
          img={images.IconWarningYellow}
          mainTitle={"¿Seguro que desea volver atrás sin guardar?"}
          mainText={"Perderá todos los cambios realizados."}
          buttonText="Ir atrás"
          closeButtonText="Cancelar"
          close={() => this.setState({ previousModalShow: false })}
          active={this.state.previousModalShow}
          onClickAccept={() => this.setState({ previousModalShow: false }, () => this.onPreviousClick(true))}
          className="error"
        />
         <LeftInfoModal
          img={images.IconWarningYellow}
          className="error"
          mainTitle={"¿Seguro que desea continuar?"}
          mainText={"Se cancelará la firma previamente solicitada"}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ cancelSignModalShow: false })}
          active={cancelSignModalShow}
          onClickAccept={() => {
            this.setState({ cancelSignModalShow: false }, () => this.validateAndNextScreen())
          }}
        />
      </TakerDataContainer>
    );
  }

  onPreviousClick(force: boolean = false) {
    if (force || !this.state.anyChange) {
      this.props.history.push("/contratacion/nueva");
    } else {
      this.setState({ previousModalShow: true });
    }
  }
}

export default withCoverages(withPolicy(withGeneral(withRouter(GipTakerDataLayout))));
