import styled from "styled-components";
import { MainButtonContainer } from "../../components/main-button/main-button-style";

export const HomeContainer = styled.div`
  margin-top: 81px;

  .notifications-container {
    margin-bottom: 50px;
  }

  .home-hero-intro-container {
    display: flex;
    gap: 40px;
    margin-bottom: 50px;
  }

  .home-hero-intro-item {
    background: #f9fbfc;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.pic {
      img {
        max-width: 800px;
        position: absolute;
        right: 0;
        z-index:-1;
        top: -10px;
        
      }
    }
  }

  .home-hero-intro-title {
    margin-top: 15px;
    margin-bottom: 12px;
    color: #004f8b;
  }

  .home-hero-intro-body {
    margin-bottom: 48px;
    font-family: "Museo Sans";
    font-weight: 700;
    font-size: 32px;
    color: #004f8b;
    line-height: 1.3em;
    max-width: 300px;

    p {
      color: #004f8b;
    }
  }

  .home-hero-intro-btn {
    max-width: 180px;
  }

  .home-data-wrapper {
    display: flex;
    /*margin-bottom: 120px;*/
    font-family: "Museo Sans";
  }

  .home-hiring-data-container {
    /* max-width: 420px;
    min-width: 420px;
    width: 420px; */
    margin-right: 40px;
    background: #f9fbfc;
    border-radius: 3px;

    background: #f9fbfc;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .home-simulation-data-container {
    padding: 20px;
    background: #f9fbfc;
    border-radius: 3px;
    width: 100%;
    border-bottom: 2px solid #ddd;
    margin-bottom: 50px;
  }

  .home-simulation-data-content {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }

  .home-hiring-data-top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .home-hiring-data-title {
    color: #091e42;
  }

  .home-hiring-data-middle-container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .home-hiring-data-amount {
    text-align: right;
    color: #6cc9f0;
    margin-right: 16px;

    p {color: #6cc9f0;}
  }

  .home-hiring-data-amount-hired {
    font-weight: 500;
    font-size: 100px;
  }

  .home-hiring-data-subtitle {
    padding-top: 6px;
    color: #004f8b;
    margin-bottom: 17px;
  }

  .home-hiring-data-variation {
    display: flex;

    img {
      max-width: 16px;
      margin-right: 3px;
    }

    &.good {
      color: #40caa9;
    }

    &.bad {
      color: #de566b;
    }
  }

  .home-hiring-data-info {
    color: #004F8B;
    font-size: 18px;
    line-height: 22px;
  }

  .home-hiring-data-btn-container {
    max-width: 175px;
    ${MainButtonContainer} {
      max-height: 48px;
      .main-button-text {
        padding-top: 1px;
      }
    }
  }

  .home-simulation-data-top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .home-simulation-data-slot-container {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    /* &:last-child {
      margin-bottom: 0;
      border-top: 0.75px solid #004F8B;
      padding-top: 24px;
    } */
  }

  .home-simulation-data-slot-number {
    font-weight: 500;
    font-size: 100px;
    color: #6cc9f0;
    margin-right: 16px;
    display: flex;
    justify-content: flex-end;

    p {
      color: #6cc9f0;
    }
  }

  .home-simulation-data-slot-info {
    width: 100%;
  }

  .home-simulation-data-slot-top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .home-simulation-data-slot-info-top-title {
    margin-right: 27px;
    color: #004f8b;
    max-width: 240px;
  }

  .home-simulation-data-slot-info-top-btn {
    min-width: 175px;

    ${MainButtonContainer} {
      max-height: 348px;
      .main-button-text {
        padding-top: 1px;
      }
    }
  }

  .home-modification-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 150px;
  }


  .pic {
    img {
      max-width: 600px;
      position: absolute;
      right: 50px;
      z-index:-1;
              
      &.position-0 {
        top: 35px;
      }
      &.position-1 {
        top: 125px;
      }
      &.position-2 {
        top: 195px;
      }
      &.position-3 {
        top: 270px;
      }
    }
  }


  .home-modification-title {
    margin-top: 15px;
    margin-bottom: 12px;
    color: #004f8b;
  }

  .home-modification-body {
    margin-bottom: 48px;
    font-family: "Museo Sans";
    font-weight: 700;
    font-size: 32px;
    color: #004f8b;
    line-height: 1.3em;
    max-width: 450px;

    p {
      color: #004f8b;
    }
  }

  .home-modification-btn {
    max-width: 180px;
  }
`;
