import styled from "styled-components";
import { colors } from "../../assets";

export const ZeroContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  width: 100%;

  .zero-content-container {
    position: relative;
    padding: 48px 18px 18px 48px;
  }
  .zero-content-img {
    position: absolute;
    top: -60px;
    left: -65px;
    max-width: 265px;
    z-index: -1;
  }
  .zero-content-title {
    font-family: "grouch";
    color: ${colors["PR-001-100"]};
    font-weight: 500;
    font-size: 68px;
    margin-bottom: 32px;
  }
  .zero-content-text {
    color: ${colors["PR-001-100"]};
    max-width: 360px;
  }
`;
