import React, { FC, useEffect, useState } from "react";
import { PersonalInformationContainer } from "../../../../hirings/new-hiring/health/forms/personal-information/personal-information-style";
import InputBox from "../../../../../components/input-box/input-box";
import CalendarBox from "../../../../../components/calendar-box/calendar-box";
import moment from "moment";
import DicotomicButton from "../../../../../components/dicotomic-button";
import SEX from "../../../../../constants/sex.json";
import SearchBox from "../../../../../components/search-box-mediadores/search-box";
import SelectBox from "../../../../../components/select-box/select-box";
import { UseFormFunctionsProps } from "../../hooks/hooks.types";
import ConstantsServices from "../../../../../services/ConstantsServices";
import DOC_TYPES from "../../../../../constants/doc_types";
import { deepGet } from '../../../../../utils/utility';

const TakerPersonalInformation: FC<UseFormFunctionsProps> = ({
  values,
  errors,
  handleChange
}) => {
  const [professionList, setProfessionList] = useState([]);
  const [professionFiltered, setProfessionFiltered] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [countriesFiltered, setCountriesFiltered] = useState([]);

  useEffect(() => {
    getCountriesList();
    getProfessionList();
  }, []);

  const getCountriesList = async () => {
    const countries = await ConstantsServices.getCountries({
      setLoading: () => {},
    });
    setCountriesList(countries);
  };

  const getProfessionList = async () => {
    const professionsList = await ConstantsServices.getProfessions({
      setLoading: () => {},
    });
    setProfessionList(professionsList);
  };

  const changeValueSearchBox = (ev: any, arg1: string, arg2: string) => {
    const value: string = ev.target.value;

    if (value.length < 2) return;

    if (value.length >= 2) {
      switch (arg2) {
        case "profession":
          setProfessionFiltered(
            professionList.filter((profession: {label: string}) =>
              profession.label
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            )
          );
          break;
        case "nationality":
          setCountriesFiltered(
            countriesList.filter((country: {label: string}) =>
              country.label
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            )
          );
          break;
        default:
          break;
      }
    }
  };

  return (
    <PersonalInformationContainer>
      <div className="content-wrapper">
        <div className="personal-data-wrapper">
          <div className="data-title" id="data-personal">
            <h5>DATOS PERSONALES</h5>
          </div>
          <div className="personal-data-fisica">
            <div className="personal-data-row">
              <div className="personal-data-item col-50">
                <div className="inputBox">
                  <InputBox
                    placeholder="Nombre"
                    required={true}
                    errorCode={deepGet(errors, 'taker.name', '')}
                    disabled={false}
                    value={values.taker.name}
                    onChange={(value: string) =>
                      handleChange("name", value, "taker")
                    }
                    type={"letters"}
                  />
                </div>
              </div>

              <div className="personal-data-item col-50">
                <div className="inputBox">
                  <InputBox
                    placeholder="Apellido 1"
                    required={true}
                    errorCode={deepGet(errors, 'taker.lastname1', '')}
                    disabled={false}
                    value={values.taker.lastname1}
                    onChange={(value: string) =>
                      handleChange("lastname1", value, "taker")
                    }
                    type={"letters"}
                  />
                </div>
              </div>
            </div>

            <div className="personal-data-row">
              <div className="personal-data-item col-50">
                <div className="inputBox">
                  <InputBox
                    placeholder="Apellido 2"
                    required={false}
                    errorCode={''}
                    disabled={false}
                    value={values.taker.lastname2}
                    onChange={(value: string) =>
                      handleChange("lastname2", value, "taker")
                    }
                    type={"letters"}
                  />
                </div>
              </div>

              <div className="personal-data-item col-50">
                <div className="inputBox">
                  <CalendarBox
                    initialValue={values.taker.birthDate}
                    placeholder="Fecha de nacimiento"
                    forbidFutureDates
                    minAge={0}
                    maxDate={moment().toISOString()}
                    required={true}
                    disabled={false}
                    errorCode={deepGet(errors, 'taker.birthDate', '')}
                    onChange={(value: string) =>
                      handleChange("birthDate", value, "taker")
                    }
                    health={true}
                  />
                </div>
              </div>
            </div>

            <div className="personal-data-row">
              <div className="personal-data-item col-50">
                <div className="personal-data-item col-25">
                  <DicotomicButton
                    required={true}
                    labelText="Sexo"
                    options={SEX}
                    withTab={true}
                    disabled={false}
                    initialValue={values.taker.gender}
                    onChange={(value: string) =>
                      handleChange("gender", value, "taker")
                    }
                    errorCode={deepGet(errors, 'taker.gender', '')}
                  />
                </div>
              </div>
              <div className="personal-data-item col-50">
                <div className="inputBox">
                  <SearchBox
                    onChange={(ev: any) =>
                      changeValueSearchBox(ev, "contact", "nationality")
                    }
                    labelText=""
                    optionsText={countriesFiltered}
                    optionKey={"value"}
                    optionValue={"label"}
                    disabled={false}
                    initialValue={values.taker.nationality.label}
                    required={true}
                    errorCode={deepGet(errors, 'taker.nationality', '')}
                    onSelect={(value: {value: string, label: string}) => {
                      handleChange("nationality", value, "taker");
                    }}
                  />
                  <label>Nacionalidad</label>
                </div>
              </div>
            </div>

            <div className="personal-data-container">
              <div className="personal-data-row">
                <div className="personal-data-item col-50">
                  <div className="selectBox">
                    <SelectBox
                      placeholder="Documento de identidad"
                      required={true}
                      errorCode={deepGet(errors, 'taker.docType', '')}
                      optionsText={DOC_TYPES.taker}
                      optionKey={"value"}
                      optionValue={"label"}
                      initialValue={values.taker.docType}
                      defaultValue={values.taker.docType}
                      disabled={false}
                      onChange={(value: string) =>
                        handleChange("docType", value, "taker")
                      }
                    />
                    <label>{"Documento de identidad"}</label>
                  </div>
                </div>
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Nº de documento"
                      required={true}
                      errorCode={deepGet(errors, 'taker.docNumber', '')}
                      maxLength={9}
                      value={values.taker.docNumber}
                      disabled={false}
                      onChange={(value: string) =>
                        handleChange("docNumber", value.toUpperCase(), "taker")
                      }
                      type={values.taker.docType === 'NIF' ?
                      "NIF" : values.taker.docType === 'NIE' ?
                        "NIE" : values.taker.docType === 'PAS' ?
                          "PAS" : "NIF"}
                    />
                  </div>
                </div>

                {values.taker.docType === "PASP" && (
                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <CalendarBox
                        initialValue={values.taker.passportDate}
                        placeholder="Fecha de caducidad del pasaporte"
                        minDate={moment().toISOString()}
                        required={true}
                        disabled={values.taker.docType !== "PASP"}
                        errorCode={deepGet(errors, 'taker.passportDate', '')}
                        onChange={(value: string) =>
                          handleChange("passportDate", value, "taker")
                        }
                        health={true}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="personal-data-row">
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <SearchBox
                      onChange={(ev: any) =>
                        changeValueSearchBox(ev, "occupation", "profession")
                      }
                      labelText=""
                      required={false}
                      errorCode={''}
                      optionsText={professionFiltered}
                      optionKey={"value"}
                      optionValue={"label"}
                      initialValue={values.taker.profession.label}
                      onSelect={(value: {value: string, label: string}) =>
                        handleChange("profession", value, "taker")
                      }
                      disabled={false}
                    />
                    <label>{"Profesión"}</label>
                  </div>
                </div>
                <div className="personal-data-item col-50"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PersonalInformationContainer>
  );
};

export default TakerPersonalInformation;
