import { SimpleSelectboxInterface } from "../containers/simulations/new-simulation/choose-policy/new-simulation.dto";
import { IMediator } from "../models/mediator-model";

export const getRelatedMediatorsCodes = (mediator: IMediator): SimpleSelectboxInterface[] => {
    let relatedCodesArray: Array<SimpleSelectboxInterface> = [];

    // Mediador usuario
    relatedCodesArray.push({ 
        label: `${mediator.mediatorCode} - ${mediator.mediatorName.trim()} - ${mediator.orderNif}`,
        value: mediator.mediatorCode
    });

    if (mediator && mediator.relatedKeys && mediator.relatedKeys.relatedKeysList.length > 0) {
       // Mediadores relacionados
        mediator.relatedKeys.relatedKeysList.forEach((code: string, key: number) => {
            if (code !== mediator.mediatorCode) {
                if (mediator.relatedMediators && mediator.relatedMediators[key] && mediator.relatedMediators[key].mediatorName) {
                    relatedCodesArray.push({
                        label: `${code} - ${mediator.relatedMediators[key].mediatorName.trim()} - ${mediator.relatedMediators[key].orderNif}`,
                        value: code
                    });
                } 
                else relatedCodesArray.push({label: code, value: code });
            }  
        });
    }
    return sortRelatedMediatorsCodes(relatedCodesArray);
}


const sortRelatedMediatorsCodes = (relatedCodesArray: Array<SimpleSelectboxInterface>): Array<SimpleSelectboxInterface> => {
    return relatedCodesArray.sort(function (a: any, b: any) {
        if (a.label < b.label)  return -1;
        else if (a.label > b.label) return 1;
        return 0;
    });
}

export const getUserFromLocalStorage = () => {
    const localUser: string | null = localStorage.getItem('loggedUser');
    if (localUser) return JSON.parse(localUser);
    return null;
};

export const getMediatorCode = () => {
    const user = getUserFromLocalStorage() || { code: 0 };
    return user.code;
};

export const getUserRoles = () => {
    const user = getUserFromLocalStorage() || { roles: [] };
    return user.roles;
};

export const getUserCode = () => {
    const user = getUserFromLocalStorage();
    return user && user.user;
};
