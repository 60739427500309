export interface GetPaymentLinkDto {
    setLoading: Function;
    loader: boolean;
    cantidad: number;
    moneda: CURRENCY;
    pedido: string;
}

export interface GetPaymentStatusDto {
    setLoading: Function;
    loader: boolean;
    pedido: string;
}

export interface PostSendingErrorMailDto{
    setLoading: Function;
    loader: boolean;
    data: {
        email: string;
        pedido: string;
        precio: string;
    }
    
}

export enum CURRENCY {
    EUROS = 'EUR',
}

interface RedsysQueryOperationMetadataResponseDto {
    Numero: string;
    Origen: string;
    Pedido: string;
    OrderId: string;
}
interface RedsysQueryOperationResponseDto {
    Ds_State: string;
    Ds_Response?: string;
}
  
export interface GetPaymentStatusResponseDto {
    $: RedsysQueryOperationMetadataResponseDto;
    RespuestaOperacion: RedsysQueryOperationResponseDto;
}
