import React from "react";
import {
  AREA_NAMES,
  PRODUCT_CARD_NAME_COLOR,
} from "../../../constants/policyType";
import { HealthHireMenuHelper, Steps as DentalSteps } from "../../../components/health-hire-menu/health-hire-menu"
import { getDayMonthYear } from "../../../utils/time";
import { getDataForm } from "../../../utils/mapData";
import HiringServices from "../../../services/HiringServices";
import { HIRING_STATUS, SIGNIG_STATUS, HIRING_STATUS_GROUP_REVISION } from "../new-hiring/types";
import moment from "moment";
import { IHiringOne, PendantHiringProps, PendantHiringState } from "./pensant-hiring-dto";
import { GipHireMenuHelper } from "../../../components/gip-menu/gip-hire-menu";
import { TravelHireMenuHelper } from "../../../components/travel-hire-menu/travel-hire-menu";
import { DentalHireMenuHelper, Steps } from "../../../components/dental-hire-menu/dental-hire-menu";

import { CONST_HIRING_STATUS } from "../new-hiring/types";

export 
class PendantHiringScreen extends React.Component<
  PendantHiringProps,
  PendantHiringState
> {
  goToNewHiring() {
    this.props.history.push("/contratacion/nueva");
  }

  savePrevState = () => {
    // Guardamos el estado 
    this.setState({
      isSearch: true,
      previousSearch: this.state.search,
      previousFilter: this.state.filter,
      previousSelectedMediatorCodes: this.state.selectedMediatorCodes
    })

  }

  getPathName = (hiringOne : any) : string => {
    try {
      let pathName : string = '';
      const { checkProductArea, allProducts } = this.props;
      const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, hiringOne.productType)
      const isGIPArea = checkProductArea(AREA_NAMES.GIP, hiringOne.productType)
      const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, hiringOne.productType)
      const product = allProducts[hiringOne.productType]
      const productHiringLink = product.products_link
      
      if(isHealthArea){
        pathName = HealthHireMenuHelper.getStepNavigationPath(hiringOne.body.currentHealthStep ? hiringOne.body.currentHealthStep : Steps.DatosTomador, hiringOne.body.insureds && hiringOne.body.insureds.length > 0 ? hiringOne.body.insureds.length : 0);
      } else if(isGIPArea && hiringOne.body.currentHealthStep){
        pathName = GipHireMenuHelper.getStepNavigationPath(hiringOne.body.currentHealthStep, hiringOne.body.insureds ? hiringOne.body.insureds.length : 0, hiringOne.productType);
      } else if(isTravelArea){
        pathName = TravelHireMenuHelper.getStepNavigationPath(hiringOne.body.currentTravelStep ? hiringOne.body.currentTravelStep : Steps.DatosTomador, hiringOne.body.insureds && hiringOne.body.insureds.length > 0 ? hiringOne.body.insureds.length : 0, hiringOne.productType);
      } else {
        pathName = `/contratacion${productHiringLink}`;
      }
      
      if ( isHealthArea &&
        hiringOne.body.currentHealthStep === Steps.ProteccionDatos &&
        (hiringOne.body.taker && !hiringOne.body.taker.physicalPerson))
        pathName = HealthHireMenuHelper.getStepNavigationPath(Steps.Prima, hiringOne.body.insureds && hiringOne.body.insureds.length > 0 ? hiringOne.body.insureds.length : 0)

      return pathName;
    } catch (error) {
      console.error(error)
      return '';
    }
  }

  getNavigationPath(hiringOne: IHiringOne): string {
    const { checkProductArea, allProducts } = this.props
    const product = allProducts[hiringOne.productType]
    const productHiringLink = product.products_link
    let path = '';
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, hiringOne.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, hiringOne.productType)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, hiringOne.productType)
    if (isHealthArea) {
      path = HealthHireMenuHelper.getStepNavigationPath(
        hiringOne.body.currentHealthStep ?
          hiringOne.body.currentHealthStep :
          Steps.DatosTomador,
        hiringOne.body.insureds && hiringOne.body.insureds.length > 0 ?
          hiringOne.body.insureds.length :
          0)
    }
    else if (isGIPArea) {
      path = GipHireMenuHelper.getStepNavigationPath(
        hiringOne.body.currentHealthStep,
        hiringOne.body.insureds ?
          hiringOne.body.insureds.length :
          0,
          hiringOne.productType)
    }
    else if (isDentalArea) {
      path = DentalHireMenuHelper.getStepNavigationPath(
        hiringOne.body.currentDentalStep ?
          hiringOne.body.currentDentalStep :
          DentalSteps.DatosTomador,
        hiringOne.body.insureds && hiringOne.body.insureds.length > 0 ?
          hiringOne.body.insureds.length :
          0)
    }
    else {
      path = `/contratacion${productHiringLink}`;
    }

    return path;
  }

  getHiring = async () => {
    const { setLoading, allProducts } = this.props;
    const { showItems, search, page, ascendent, order, filter, selectedMediatorCodes,
      relatedMediatorCodes, isSearch, previousSearch, previousFilter, previousSelectedMediatorCodes } = this.state;

    let searched = false;

    let filtro = (isSearch) ? filter : previousFilter;
    let busqueda = (isSearch) ? search : previousSearch;
    let mediadoresSeleccionados: any = (isSearch) ? selectedMediatorCodes : previousSelectedMediatorCodes;

    const params = [
      `skip=${showItems * (page - 1)}`,
      `take=${showItems}`,
    ];
    searched = filtro.policyStatus.value.length > 0;

    if (filtro.policyStatus.value === '' || filtro.policyStatus.type === 'all') {
      params.push(`status=all`);
      searched = true;
    }
    else if (filtro.policyStatus.type === 'policy') {
      params.push(`status=${filtro.policyStatus.search}`);
      searched = true;
    }
    else if (filtro.policyStatus.type === 'sign') {
      params.push(`signStatus=${filtro.policyStatus.search}`);
      searched = true;
    }

    if (busqueda) {
      params.push(`search=${busqueda}`);
      searched = true;
    }
    if (filtro.policyType.value && filtro.policyType.value !== 'all') {
      params.push(`policyType=${filtro.policyType.value}`);
      searched = true;
    }
    if (filtro.takerNIF.value) {
      params.push(`takerNIF=${filtro.takerNIF.value}`);
      searched = true;
    }
    if (filtro.dateSince.value) {
      params.push(`dateSince=${moment(filtro.dateSince.value, 'DD/MM/YYYY').format('YYYY-MM-DD').toString()}`);
      searched = true;
    }
    if (filtro.dateUntil.value) {
      params.push(`dateUntil=${moment(filtro.dateUntil.value, 'DD/MM/YYYY').format('YYYY-MM-DD').toString()}`);
      searched = true;
    }

    if (mediadoresSeleccionados) {
      mediadoresSeleccionados.forEach((value: string) => {
        const relatedMediator = relatedMediatorCodes.find(med => med.label === value)
        if (relatedMediator) params.push(`mediatorCodes=${relatedMediator.value}`);
      });
    }

    if (ascendent === true && order) {
      params.push(`order=${order}`);
    } else if (ascendent === false && order) {
      params.push(`order=-${order}`);
    }

    const resHiring = await HiringServices.getHiring(true, setLoading, params);
    if (resHiring && resHiring.status) {

      this.setState({
        error: resHiring.data.message || 'Se ha producido un error, por favor inténtelo de nuevo más tarde',
        showError: true,
        hiring: [],
        hiringFilter: [],
        hiringObj: [],
        rowCount: 0,
        searched,
        totalPages: 0,
      });
    } else {
      const hiring = resHiring.items.map((hiringOne: IHiringOne) => {
        const product = allProducts[hiringOne.productType]
        const productName = product.products_name
        const productCardName = product.products_cardName

        return {
          databaseId: {
            text: hiringOne.id,
            type: "",
            key: "inline",
          },
          id: {
            text: hiringOne.body.idcotiza || hiringOne.body.requestNumber || hiringOne.body.requestId || hiringOne.id,
            type: "",
            key: "inline",
          },
          "policy-type": {
            text: productName,
            type: PRODUCT_CARD_NAME_COLOR[productCardName],
            key: "tag-container",
          },
          taker: {
            text: `${hiringOne.body.taker.name !== undefined ? hiringOne.body.taker.name : ''} 
            ${hiringOne.body.taker.lastname1 !== undefined ? hiringOne.body.taker.lastname1 : ''} 
            ${hiringOne.body.taker.lastname2 !== undefined ? hiringOne.body.taker.lastname2 : ''}
            / ${hiringOne.body.taker.docNumber !== undefined ? hiringOne.body.taker.docNumber : ''}`,
            type: "",
            key: "normal",
          },
          date: {
            text: getDayMonthYear(hiringOne.createdAt),
            type: "",
            key: "inline",
          },
          expiration: {
            text: getDayMonthYear(hiringOne.expiration),
            type: "",
            key: "inline",
          },
          policyStatus: {
            text: this.getHiringStatus(hiringOne.status, hiringOne.signatureStatus),
            type: "",
            key: "normal",
          },
          mediator: {
            text: hiringOne.mediatorAssociatedToPolicy ? hiringOne.mediatorAssociatedToPolicy : "",
            type: "",
            key: "mediator",
            toolTips: hiringOne.body.mediatorUser && hiringOne.body.mediatorUser.name ?
              hiringOne.body.mediatorUser.name :
              '',
          },
          loggedMediator: {
            text: hiringOne.body.loggedUserCode ?
              hiringOne.body.loggedUserCode :
              hiringOne.body.loggedUser ? hiringOne.body.loggedUser : "",
            type: "",
            key: "mediator",
            toolTips: hiringOne.body.loggedUserCode ?
              hiringOne.body.loggedUser ? hiringOne.body.loggedUser : '' :
              '',
          },
          action: {
            // disabled: true
            key: "button",
            text: this.getActionText(hiringOne),
            type: this.isFinalStatus(hiringOne) ? "sign" : "",
            onClick: () => this.isFinalStatus(hiringOne) ? this.goToDetail(hiringOne) : this.updateHiring(hiringOne),
            toolTips: this.setActionTooltip(hiringOne)
          }
        };
      });

      const pages = Math.ceil(resHiring.count / showItems);

      this.setState({
        hiring,
        hiringFilter: hiring,
        hiringObj: resHiring,
        rowCount: resHiring.count,
        searched,
        totalPages: pages,
        error: '',
        showError: false
      });
    };
  }

  getHiringStatus = (hiringStatus: string, signatureStatus: string) => {
    if (hiringStatus === CONST_HIRING_STATUS.finished) return CONST_HIRING_STATUS.EMITIDA;
    else if (HIRING_STATUS_GROUP_REVISION.includes(hiringStatus)) {
      return HIRING_STATUS[HIRING_STATUS.findIndex((status) => status.search === hiringStatus)].label
    }
    else if (hiringStatus !== CONST_HIRING_STATUS.PENDIENTE || (hiringStatus === CONST_HIRING_STATUS.PENDIENTE && !signatureStatus)) return hiringStatus;
    else{
      const status = HIRING_STATUS[HIRING_STATUS.findIndex((status) => status.search === signatureStatus && status.type === 'sign')];
      return status ? status.label : 'DESCONOCIDO';
    }
  }

  getActionText(hiringOne: IHiringOne) {
    const { checkProductArea } = this.props;
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, hiringOne.productType);

    if (hiringOne.status === CONST_HIRING_STATUS.RECHAZADA || hiringOne.status === CONST_HIRING_STATUS.EXPIRADA || (isTravelArea && HIRING_STATUS_GROUP_REVISION.includes(hiringOne.status))) return hiringOne.status;
    else if (hiringOne.signatureStatus === SIGNIG_STATUS.FIRMADA  || this.checkPhysicalSign(hiringOne)) return "Firmada";
    else if (hiringOne.status === CONST_HIRING_STATUS.PENDIENTE && !hiringOne.signatureStatus) return "Continuar";
    else if (hiringOne.status === CONST_HIRING_STATUS.ANULADA_MEDIADORES) return "Anulada";
    else return "Modificar";
  }

  checkPhysicalSign = (hiringOne: IHiringOne) => hiringOne.body.physicalSignatureNode && (HIRING_STATUS_GROUP_REVISION.includes(hiringOne.status) || hiringOne.status === CONST_HIRING_STATUS.EMITIDA)

  setActionTooltip = (hiringOne: IHiringOne) => {
    // Estados de solicitud
    if (hiringOne.status === CONST_HIRING_STATUS.RECHAZADA) return "La solicitud se ha rechazado. Contacte con la delegación para más detalle.";
    if (hiringOne.status === CONST_HIRING_STATUS.EN_REVISION) return "La solicitud se ha quedado pendiente de revisión. Contacte con la delegación para más detalle.";
    if (hiringOne.status === CONST_HIRING_STATUS.SELERIES) return "La solicitud se ha quedado pendiente de revisión. Contacte con la delegación para más detalle.";
    if (hiringOne.status === CONST_HIRING_STATUS.PDTEAUTO) return "La solicitud se ha quedado pendiente de revisión. Contacte con la delegación para más detalle.";
    if (hiringOne.status === CONST_HIRING_STATUS.RESERIES) return "La solicitud se ha quedado pendiente de revisión. Contacte con la delegación para más detalle.";
    if (hiringOne.status === CONST_HIRING_STATUS.EXPIRADA) return "La solicitud ha expirado. Contacte con la delegación para más detalle.";
    if (hiringOne.status === CONST_HIRING_STATUS.PENDIENTE_ERROR) return "Modificar una solicitud que ha generado un error en circuito de emisión, para volver a enviar firma al cliente.";
    if (hiringOne.status === CONST_HIRING_STATUS.ANULADA_MEDIADORES) return "Contratación Anulada en el Portal del Mediador, esta anulación es posible que no esté actualizada en Senda.";

    // Estado de error por comportamiento inesperado: PENDIENTE - FIRMADA
    if (hiringOne.status === CONST_HIRING_STATUS.PENDIENTE && hiringOne.signatureStatus === SIGNIG_STATUS.FIRMADA) return "Ha ocurrido un error con esta solicitud. Por favor, contacte con el buzón de soporte.";

    // No existe firma
    if (!hiringOne.signatureStatus) return "Continuar con la solicitud de contratación.";

    // Estados de firma
    if (hiringOne.signatureStatus === SIGNIG_STATUS.SOLICITADA) return "Modificar una solicitud que se ha enviado al cliente y está en circuito de firma.";
    if (hiringOne.signatureStatus === SIGNIG_STATUS.EXPIRADA) return "Modificar una solicitud cuyo proceso de firma ha expirado; pero se desea volver a enviar firma al cliente.";
    if (hiringOne.signatureStatus === SIGNIG_STATUS.ERROR) return "Modificar una solicitud cuyo proceso de firma ha generado un error; pero se desea volver a enviar firma al cliente.";
    if (hiringOne.signatureStatus === SIGNIG_STATUS.RECHAZADA) return "Modificar una solicitud cuyo proceso de firma ha sido rechazado por el cliente; pero se desea volver a enviar firma al cliente.";

    return "";
  }

  isFinalStatus = (hiringOne: IHiringOne) => { return hiringOne.signatureStatus === SIGNIG_STATUS.FIRMADA || HIRING_STATUS_GROUP_REVISION.includes(hiringOne.status) || hiringOne.status === CONST_HIRING_STATUS.RECHAZADA || hiringOne.status === CONST_HIRING_STATUS.EXPIRADA || hiringOne.status === CONST_HIRING_STATUS.ANULADA_MEDIADORES }


  goToDetail = (hiringOne: IHiringOne) => {
    const { history } = this.props;
    history.push(`/contratacion/pendiente/detalle/${hiringOne.mediatorAssociatedToPolicy}/${hiringOne.id}`);
  }

  updateHiring = async (hiringOne: IHiringOne) => {
    if (hiringOne.signatureStatus === SIGNIG_STATUS.FIRMADA || hiringOne.status === CONST_HIRING_STATUS.RECHAZADA || hiringOne.status === CONST_HIRING_STATUS.EXPIRADA) return;
    if (hiringOne.signatureStatus === SIGNIG_STATUS.SOLICITADA) {
      this.setState({
        showInfoModal: true,
        infoModalMainText: "Si continuas se cancelará el proceso de firma anterior, ¿Estás seguro?",
        infoModalClass: "error",
        infoModalOnClickAccept: () => this.cancelRequestedSign(hiringOne)
      });
    }
    else {
      this.setState({
        showInfoModal: true,
        infoModalMainText: "Vas a continuar con la solicitud, ¿Estás seguro?",
        infoModalClass: "",
        infoModalOnClickAccept: () => this.goToHiring(hiringOne)
      });
    }
  }

  cancelRequestedSign = async (hiringOne: IHiringOne) => {
    try {
      this.setState({ showInfoModal: false });
      const { setLoading } = this.props;
      const updateSignHiring = await HiringServices.updateSignHiring({ data: { id: hiringOne.id, signId: hiringOne.body.signData.signId }, setLoading: setLoading });
      hiringOne = updateSignHiring;
      this.goToHiring(hiringOne)
    } catch (error) {
      if (error && error.status === 409) {
        this.setState({
          error: 'No es posible cancelar el proceso de firma cuando no se encuentra en estado de SOLICITADA.',
          showError: true,
        });
      } else {
        this.setState({
          error: 'Se ha producido un error, por favor inténtelo de nuevo más tarde.',
          showError: true,
        });
      }
    }
  }

  goToHiring(hiringOne: IHiringOne) {
    const { history, checkProductArea, allProducts, productsByAreaName } = this.props;
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, hiringOne.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, hiringOne.productType)
    const product = allProducts[hiringOne.productType]

    localStorage.setItem('internalName', hiringOne.productType)
    let pathName = this.getNavigationPath(hiringOne);
    const goToDetail = hiringOne.body.currentHealthStep === Steps.DatosAsegurados ? hiringOne.body.insureds.length - 1 : undefined;

    if ((isHealthArea || isDentalArea) &&
      hiringOne.body.currentHealthStep === Steps.ProteccionDatos &&
      (hiringOne.body.taker && !hiringOne.body.taker.physicalPerson))
      pathName = HealthHireMenuHelper.getStepNavigationPath(Steps.Prima, hiringOne.body.insureds && hiringOne.body.insureds.length > 0 ? hiringOne.body.insureds.length : 0)

    if (isHealthArea || isDentalArea) hiringOne.body.signatureStatus = hiringOne.signatureStatus || '';

    const path = pathName && pathName.split('?')[0] ? pathName.split('?')[0] : undefined
    const params = pathName && pathName.split('?')[1] ? pathName.split('?')[1] : undefined

    history.push({
      pathname: path,
      search: params,
      state: {
        goToDetail: goToDetail,
        dataForm: getDataForm(
          hiringOne.body,
          hiringOne.productType,
          product.products_coverageName,
          productsByAreaName,
          this.props.coverages,
          hiringOne.status
        ),
        data: {
          payment: {},
          beneficiaries: [],
          loan: {},
          ...hiringOne.body,
        },
        isReadOnly: hiringOne.status === 'EN REVISION', // esta variable se activa cuando en caso de que no se quiera permitir la edición de la póliza
        hiringId: hiringOne.id,
        selectedMediatorCode: hiringOne.mediatorAssociatedToPolicy,
      },
    })
  }

}
export default PendantHiringScreen;
