import AddressServices from "../../../../services/AddressServices";
import { deepGet } from "../../../../utils/utility";
import { FormValues } from "../hooks/hooks.types";
import { ValidationErrors, ValidationRules } from "../new-insured/newInsuredStepper.types";
import { UpdatedStateFunctionType } from "./components.types";

export const getProvinces = async (input: string, setProvinces: UpdatedStateFunctionType) => {
  const resProvinces = await AddressServices.getProvinces({
    loader: false,
    setLoading: () => {},
    params: [`input=${input}`],
  });
  const provinces = resProvinces.map((province: {name: string, code: string}) => ({
    label: province.name,
    value: province.code,
  }));
  setProvinces(provinces);
};

export const getCities = async (input: string, setCities: UpdatedStateFunctionType, values: FormValues) => {
  const resCities = await AddressServices.getCities({
    loader: false,
    setLoading: () => {},
    params: [`input=${input}`, `provinceCode=${values.address.province.value}`],
  });
  const cities = resCities.map((p: { name: string, code: string }) => ({
    label: p.name,
    value: p.code,
  }));
  setCities(cities);
};

export const getStreets = async (input: string, setStreets: UpdatedStateFunctionType, values: FormValues) => {
  const resStreets = await AddressServices.getStreets({
    loader: false,
    setLoading: () => {},
    params: [
      `input=${input}`,
      `provinceCode=${values.address.province.code}`,
      `cityCode=${values.address.town.code}`,
    ],
  });
  const streets = resStreets.map((p: {code: string, extra:  {street: {code: string, name: string}}}) => ({
    label: `${p.extra.street.code} ${p.extra.street.name}`,
    value: p.code,
    extra: p.extra,
  }));
  setStreets(streets);
};

export const getPostalCodes = async (input: string, setPostalCodes: UpdatedStateFunctionType, values: FormValues) => {
  const resPostalCodes = await AddressServices.getPostalCodes({
    loader: false,
    setLoading: () => {},
    params: [
      `input=${input}`,
      `provinceCode=${values.address.province.code}`,
      `cityCode=${values.address.town.code}`,
      `street=${deepGet(values, 'address.address.code')}`,
    ],
  });
  const postalCodesResponse = resPostalCodes.map((p: { name: string}) => ({
    label: p.name,
    value: p.name,
  }));

  setPostalCodes(postalCodesResponse);
};

export const validateFields = <T>(
  values: T,
  rules: ValidationRules<T>
): { errors: ValidationErrors<T>; hasValidation: boolean } => {
  const errors: ValidationErrors<T> = {};
  let hasValidation = true;

  Object.keys(rules).forEach((field) => {
    const rule = rules[field as keyof T];
    const value = values[field as keyof T];
    if (typeof rule === 'string') {
      if (!value) {
        errors[field as keyof T] = rule;
        hasValidation = false;
      }
    } else if (typeof rule === 'function') {
      const result = rule(value);
      if (typeof result === 'string') {
        errors[field as keyof T] = result;
        hasValidation = false;
      } else if (result !== null && typeof result === 'object') {
        errors[field as keyof T] = result;
        hasValidation = false;
      }
    }
  });

  return { errors, hasValidation };
};
