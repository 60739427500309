import { deepGet } from "../../../utils/utility";

const policyData = () => {
    const policyDataOBJ = localStorage.getItem('policyData') || '{}';
    return JSON.parse(policyDataOBJ);
}

export const getNifTomador = () => {
    return deepGet(policyData(), 'nifTomador', '') || '';
}

export const getPolicyNumber = () => {
    return deepGet(policyData(), 'numeroPoliza', '') || '';
}

export const getProductName = () => {
    return deepGet(policyData(), 'producto', '') || '';
}

export const getRamo = () => {
    return deepGet(policyData(), 'ramo', '') || '';
}

export const getPolicyData = () => {
    return policyData();
}