import React from "react";
import { Link } from "react-router-dom";
import { LegalPolicyContainer } from "../legal-policy/legal-policy-style";

interface AdditionalPolicyProps {}

export default class AdditionalPolicyLayout extends React.Component<AdditionalPolicyProps, {}> {
  render() {
    return (
      <LegalPolicyContainer>
        <div className="legal-title-selection">
          <p>
            Información <br /> adicional RGPD
          </p>
        </div>

        <div className="legal-subtitle-section">
          <p>01 ¿Quién es el responsable del tratamiento de sus datos personales?</p>
        </div>

        <div className="legal-text">
          <p>
            El responsable del tratamiento es ASISA ASISTENCIA SANITARIA INTERPROVINCIAL, S.A.U, con CIF A08169294 y con domicilio social en Calle
            Juan Ignacio Luca de Tena, 12, 28027, (en adelante, ASISA).
          </p>
          <p>
            La ENTIDAD ha nombrado formalmente un Delegado de Protección de Datos Personales, que tiene habilitado el siguiente canal de comunicación:
            DPO@grupoasisa.com
          </p>
        </div>

        <div className="legal-subtitle-section">
          <p>02 ¿Cómo obtenemos sus datos personales?</p>
        </div>

        <div className="legal-text">
          <p>
            Para dar respuesta a esta pregunta deben distinguirse entre las fuentes de las cuales proceden sus datos personales y la tipología de
            datos personales tratados por ASISA:
          </p>
        </div>

        <div className="legal-subtitle-p-title">
          <p>Fuentes de las que proceden los datos personales</p>
        </div>
        <div className="legal-text">
          <ul>
            <li>Aportados por Vd. en las relaciones que entabla con ASISA.</li>
            <li>Los resultantes de la propia gestión, mantenimiento y desarrollo de la relación contractual.</li>
          </ul>
        </div>
        <div className="legal-subtitle-p-title">
          <p>Tipologías de datos personales</p>
        </div>
        <div className="legal-text">
          <ul>
            <li> Datos identificativos.</li>
            <li> Datos de características personales.</li>
            <li> Datos de circunstancias sociales.</li>
            <li> Datos socioeconómicos (económicos, financieros y de seguros).</li>
            <li> Datos de salud.</li>
          </ul>
        </div>

        <div className="legal-subtitle-section">
          <p>03 ¿Con qué finalidades tratamos sus datos personales?</p>
        </div>

        <div className="legal-text">
          <p className="list">En ASISA tratamos sus datos personales para alcanzar las finalidades que se detallan a continuación:</p>
          <ul>
            <li>
              Cumplir, controlar y ejecutar la prestación de asistencia sanitaria garantizada en el contrato de seguro y en los Conciertos suscritos
              con las distintas Mutualidades.
            </li>
            <li>Enviar comunicaciones comerciales sobre bienes y/o servicios propios del Grupo ASISA.</li>
          </ul>
        </div>

        <div className="legal-subtitle-section">
          <p>04 ¿Cuál es la legitimación de la entidad para el tratamiento de sus datos?</p>
        </div>

        <div className="legal-text">
          <p>
            La base para el tratamiento de sus datos personales se encuentra en la prestación de asistencia sanitaria al asegurado y al mutualista y/o
            beneficiario por parte de ASISA, en base a lo dispuesto en el contrato de seguro que le vincula con dicho asegurado y a lo recogido en el
            Concierto suscrito entre ASISA y la Mutualidad respectiva.
          </p>
          <p>
            Asimismo, el envío de comunicaciones comerciales se basa en el consentimiento que se le solicita, sin que en ningún caso la retirada de
            dicho consentimiento condicione la ejecución de dicho contrato.
          </p>
        </div>

        <div className="legal-subtitle-section">
          <p>05 ¿A qué destinatarios se comunicarán sus datos personales?</p>
        </div>

        <div className="legal-text">
          <p>
            Los datos personales tratados por ASISA para alcanzar las finalidades detalladas anteriormente podrán ser comunicados a los siguientes
            destinatarios en función de la base legitimadora de la comunicación.
          </p>
          <p className="list">
            En virtud de lo anterior, las siguientes comunicaciones de datos persiguen garantizar el correcto desarrollo de la relación contractual,
            así como dar cumplimiento a obligaciones legales que exigen realizar las mencionadas comunicaciones:
          </p>

          <ul>
            <li>Entidades que forman parte del Grupo ASISA y entidades colaboradoras del mismo.</li>
            <li>Organismos y Administraciones Públicas.</li>
            <li>
              Empresas de Reaseguro o Coaseguro a fin de celebrar, tramitar o gestionar, en su caso, las prestaciones contenidas en la presente
              Póliza.
            </li>
            <li>
              Médicos, centros médicos, hospitales y otras instituciones o personas, identificadas como prestadores de servicios sanitarios en la
              Lista de Facultativos elaborada por ASISA que puede consultar en su sitio web: www.asisa.es.
            </li>
            <li>Entidades financieras para la gestión de cobros y pagos.</li>
            <li>
              Entidades titulares de ficheros de servicios de información sobre solvencia patrimonial y crédito, tanto para su consulta en los
              supuestos legalmente establecidos, como en caso de incumplimiento de sus obligaciones dinerarias.
            </li>
          </ul>
        </div>

        <div className="legal-subtitle-section">
          <p>06 ¿Por cuánto tiempo conservamos sus datos?</p>
        </div>
        <div className="legal-text">
          <p>
            Los datos personales se conservarán durante la vigencia del contrato y, posteriormente, siempre que Vd. no haya ejercitado su derecho de
            supresión, serán conservados teniendo en cuenta los plazos legales que resulten de aplicación en cada caso concreto, teniendo en cuenta la
            tipología de los datos, así como la finalidad del tratamiento.
          </p>
          <p>
            Dichos plazos podrán ser consultados en la Política de Conservación de Datos de ASISA incorporada en la página web www.asisa.es, así como
            en aquellos otros lugares visibles para el titular de los datos.
          </p>
        </div>

        <div className="legal-subtitle-section">
          <p>07 ¿Cuáles son sus derechos en relación al tratamiento que hacemos de sus datos?</p>
        </div>
        <div className="legal-text">
          <p>
            ASISA le informa que tiene derecho a acceder a sus datos personales y a obtener confirmación sobre cómo se están tratando dichos datos.
            Asimismo, tiene derecho a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros
            motivos, los datos ya no sean necesarios para los fines que hayan sido recabados por ASISA.
          </p>
          <p>
            En determinadas circunstancias, podrá solicitar la limitación del tratamiento de sus datos, en cuyo caso ASISA únicamente los conservará
            para el ejercicio o la defensa de las posibles reclamaciones.
          </p>
          <p className="list">
            Asimismo, también en determinadas circunstancias, usted podrá oponerse al tratamiento de sus datos personales con la finalidad informada
            por ASISA. En ese caso, ASISA cesará en el tratamiento de los datos personales, salvo que concurran motivos legítimos, o para garantizar
            el ejercicio o la defensa de posibles reclamaciones. Por último, usted podrá solicitar el derecho a la portabilidad y obtener para sí
            mismo o para otro prestador de servicios determinada información derivada de la relación contractual formalizada con ASISA. Vd. podrá
            ejercer tales derechos mediante:
          </p>
          <ul>
            <li>
              Escrito dirigido a ASISA ASISTENCIA SANITARIA INTERPROVINCIAL, S.A.U a la dirección postal Calle Juan Ignacio Luca de Tena número 12,
              28027, Madrid.
            </li>
            <li>Mediante escrito dirigido a la dirección de correo electrónico DPO@grupoasisa.com</li>
          </ul>
          <p>
            En ambos supuestos, se exige acreditar la identidad de la persona que ejerce sus derechos, mediante el envío de copia de su DNI, NIE,
            Pasaporte o documento equivalente, por las dos caras.
          </p>
          <p>
            ASISA le facilitará la información solicitada en el plazo máximo de un mes a partir de la recepción de la solicitud. Dicho plazo podrá
            prorrogarse otros dos meses en caso necesario, teniendo en cuenta la complejidad y el número de solicitudes.
          </p>
          <p>
            Vd. podrá retirar el consentimiento en cualquier momento, en el caso de que se haya otorgado el consentimiento para alguna finalidad
            específica, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada. Vd. podrá presentar
            reclamación ante la Autoridad de Control competente en materia de protección de datos. No obstante, en primera instancia, podrá presentar
            reclamación ante el Delegado de Protección de Datos, quien resolverá la reclamación en el plazo máximo de dos meses.
          </p>
        </div>
      </LegalPolicyContainer>
    );
  }
}
