import { PRODUCT_TYPE } from "./policyType";

export const PRODUCT_BRANCHES_DROPDOWN = [
  {
    label: "Vida",
    value: "life"
  },
  {
    label: "Mascotas",
    value: "pet"
  },
  {
    label: "Decesos",
    value: "death"
  }
];

export const LIFE_PRODUCTS_DROPDOWN = [
  {
    label: "Tranquilidad",
    value: PRODUCT_TYPE.TRANQUILITY
  },
  {
    label: "Tranquilidad plus",
    value: PRODUCT_TYPE.TRANQUILITY_PLUS
  },
  {
    label: "Tranquilidad hipoteca",
    value: PRODUCT_TYPE.MORTGAGE
  },
  {
    label: "Mujer",
    value: PRODUCT_TYPE.WOMAN
  },
  {
    label: "Retorno",
    value: PRODUCT_TYPE.RETURN
  }
];

export const PET_PRODUCTS_DROPDOWN = [
  {
    label: "Mascotas",
    value: "pet"
  }
];

export const DEATH_PRODUCTS_DROPDOWN = [
  {
    label: "Decesos",
    value: "death"
  }
];

export const ALL_PRODUCTS_KEY_VALUE: any = {
  life: "Vida",
  [PRODUCT_TYPE.DEATH]: "Decesos",
  [PRODUCT_TYPE.PET]: "Mascotas",
  [PRODUCT_TYPE.RETURN]: "Retorno",
  [PRODUCT_TYPE.WOMAN]: "Mujer",
  [PRODUCT_TYPE.MORTGAGE]: "Tranquilidad hipoteca",
  [PRODUCT_TYPE.TRANQUILITY]: "Tranquilidad",
  [PRODUCT_TYPE.TRANQUILITY_PLUS]: "Tranquilidad plus"
};

export const optionsUnits = [
  { label: "Póliza", value: "POLICY" },
  { label: "Prima", value: "PREMIUM" },
  { label: "Puntos", value: "POINTS" }
];

export const optionsIncentiveUnits = [
  { label: "Prima", value: "PREMIUM" },
];

export const optionsContestUnits = [
  { label: "Puntos", value: "POINTS" }
];

export const optionsUnitsKeyValue: any = {
  POLICY: "Pólizas",
  PREMIUM: "Primas",
  POINTS: "Puntos"
};


export const CAMPAIGN_STATES: any = {
  definition: 'Definición',
  planned: 'Planificada',
  in_action: 'En ejecución',
  in_process: 'En seguimiento',
  finished: 'Finalizada',
  revoked: 'Anulada',
  cancelled: 'Cancelada'
}

export const CAMPAIGN_PERIODS_DROPDOWN = [
  {
    label: "Anual", value: "anual"
  },
  {
    label: "Bimestral", value: "bimestral"
  },
]