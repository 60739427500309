import React, { Component } from "react";
import { LeftInfoModalContainer } from "./left-info-modal-style";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { images } from "../../assets";
import H4Text from "../components-text/h4-text";
import MainButton from "../main-button";
import WebText from "../components-text/web-text";
import InputBox from "../input-box/input-box";
import { onKeyDown } from "../../utils/utility";

interface LeftInfoModalRouterProps {}

interface IInputProp {
  labelText: string;
  value: string;
  errorCode: string;
  onChange: Function;
}

interface LeftInfoModalProps
  extends RouteComponentProps<LeftInfoModalRouterProps> {
  className?: string;
  img?: string;
  onClickAccept: Function;
  mainTitle: string;
  mainText: string;
  buttonText: string;
  active?: boolean;
  close: Function;
  withCancel?: boolean;
  withInput?: IInputProp;
  closeButtonText?: string;
  noSecondaryButton?:boolean;
  list?: string[];
  secondaryText?: string;
  info?: string;
  centerPrimaryButton: boolean;
  hasAcceptNoClose: boolean;
  buttonFontSizeSmall?: boolean;
}

interface LeftInfoModalState {
  active: string;
}

class LeftInfoModalLayout extends Component<
  LeftInfoModalProps,
  LeftInfoModalState
> {
  readonly state: LeftInfoModalState;
  containerRef: any;
  subContainerRef: any;

  public static defaultProps = {
    active: false,
    img: images.IconSearchBlue,
    onClickAccept: () => {},
    className: "",
    mainTitle: "",
    mainText: "",
    buttonText: "",
    close: () => {},
    hasAcceptNoClose: false,
    buttonFontSizeSmall: false,
  };

  constructor(props: LeftInfoModalProps) {
    super(props);
    this.state = {
      active: props.active ? "active" : "",
    };
    this.containerRef = React.createRef();
    this.subContainerRef = React.createRef();
  }

  isVisible(e: any) {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
  }

  handleClickOutside(ev: any) {
    const { close } = this.props;
    const { active } = this.state;
    this.isVisible(ev.target) &&
      this.subContainerRef.current &&
      !this.subContainerRef.current.contains(ev.target) &&
      active &&
      close();
  }

  componentDidUpdate(prevProps: LeftInfoModalProps) {
    if (prevProps.active !== this.props.active) {
      if (this.props.active) {
        this.containerRef.current.addEventListener(
          "click",
          this.handleClickOutside.bind(this)
        );
      } else {
        this.containerRef.current.removeEventListener(
          "click",
          this.handleClickOutside.bind(this)
        );
      }
      this.setState({ active: this.props.active ? "active" : "" });
    }
  }


  close() {
    this.setState({ active: "" });
  }

  open() {
    this.setState({ active: "active" });
  }

  acceptLeftInfoModal() {
    const { onClickAccept, close, withInput, hasAcceptNoClose } = this.props;
    if (onClickAccept) {
      onClickAccept();
    }
    if (withInput) return;
    if (!hasAcceptNoClose) close();
  }

  render() {
    const {
      img,
      mainTitle,
      mainText,
      className,
      buttonText,
      close,
      withCancel,
      withInput,
      closeButtonText,
      noSecondaryButton,
      list,
      secondaryText,
      info,
      centerPrimaryButton,
      hasAcceptNoClose,
      buttonFontSizeSmall,
    } = this.props;
    const { active } = this.state;

    return (
      <LeftInfoModalContainer
        className={`${active} ${className}`}
        ref={this.containerRef}
      >
        <div className="left-info-modal-container" ref={this.subContainerRef}>
          { !hasAcceptNoClose && <div className="left-info-modal-close">
            <img src={images.IconCloseGrey} onClick={() => close()} />
          </div>}

          <div className="left-info-modal-main">
            <div className="left-info-modal-main-title">
              <div className="left-info-modal-icon-bg">
                <img src={img} />
              </div>
              <div className="left-info-modal-text">
                <H4Text>{mainTitle}</H4Text>
              </div>
            </div>
            <div className="left-info-modal-main-content">
              <div className="left-info-modal-main-copy" dangerouslySetInnerHTML={{__html: mainText}}/>
              { list && 
                <ul>
                  { list.map((item, key) => (
                    <li key={key} className="left-info-modal-main-list" dangerouslySetInnerHTML={{__html: item}}/>
                 ))}
                </ul> 
              }
              { secondaryText &&
                <div className="left-info-modal-main-copy left-info-modal-main-wrap left-info-modal-main-secondary" dangerouslySetInnerHTML={{__html: secondaryText}}/>
              }  
              {withInput && (
                <div className="left-info-modal-input inputBox">
                  <InputBox
                    placeholder={withInput.labelText}
                    errorCode={withInput.errorCode}
                    maxLength={100}
                    value={withInput.value}
                    onChange={(value: string) => withInput.onChange(value)}
                    onKeyDown={(ev: KeyboardEvent) =>
                      onKeyDown(
                        ev,
                        "Enter",
                        this.acceptLeftInfoModal.bind(this)
                      )
                    }
                  />
                </div>
              )}
              <div className="left-info-modal-button" style={{justifyContent: centerPrimaryButton ? "center" : "inherit" }}>
              {!noSecondaryButton &&
                    <MainButton
                      text={closeButtonText ? closeButtonText : "Cancelar"}
                      onClick={() => close()}
                      type={"ghost"}
                      fontSize={buttonFontSizeSmall ? '12px' : '16px'}
                    />
                }
                <MainButton
                  text={buttonText}
                  onClick={() => this.acceptLeftInfoModal()}
                  fontSize={buttonFontSizeSmall ? '12px' : '16px'}
                />
                
              </div>
            </div>
          </div>
          { info &&
             <div className="left-info-modal-info">
              <img src={images.IconInfoGrey} />
              <div className=" left-info-modal-main-wrap" dangerouslySetInnerHTML={{__html: info}}/>
            </div>
          }   
        </div>
      </LeftInfoModalContainer>
    );
  }
}

export default withRouter(LeftInfoModalLayout);
