import styled from "styled-components";
import { colors, mediaqueries } from "../../assets";

export const LoginContainer = styled.div`
  font-family: "museo sans";
  position: relative;

  .login-main-pic {
    margin-bottom: 70px;
    img {
      max-width: 90px;
    }
  }

  .login-main-title {
    color: ${colors["PR-001-100"]};
    font-weight: 500;
    margin-bottom: 40px;

    &__portal {
      font-size: 28px;
      margin-bottom: 4px;
    }

    &__subject {
      font-size: 84px;
      font-weight: 600;
      margin-bottom: 8px;
      font-family: "grouch";
    }

    &__country {
      max-width: 550px;
      text-align: right;
      text-transform: uppercase;
      font-weight: 700;
      color: ${colors["PR-001-50"]};
    }
  }

  .login-main-right-wave {
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .login-main-bottom-wave {
    position: fixed;
    bottom: -6px;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
  }

  .login-form {
    display: flex;
    align-items: flex-start;
    min-width: 400px;
    justify-content: center;
    padding: 24px 0;
  }

  .login-idserver-button {
    max-width: 380px;
    border: 1px solid ${colors["PR-001-100"]};
    padding: 24px;
    height: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    &__title {
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      color: ${colors["PR-001-50"]};
      margin-bottom: 16px;
    }
    &__text {
      color: ${colors["PR-001-100"]};
      margin-bottom: 25px;
      font-weight: 300;
      font-size: 16px;
      line-height: 27px;
    }
    &__button {
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.24);
      width: 150px;
    }
  }

  ${mediaqueries["max-width-1440"]} {
    .login-main-right-wave {
      img {
        max-width: 450px;
      }
    }

    .login-main-bottom-wave {
      transform: none;
      left: 50%;
      img {
        max-width: 500px;
      }
    }
  }
`;
