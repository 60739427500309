import React from "react";
import {
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import { NewHiringContainer, ChoosePolicyContainer } from "../new-hiring-style";
import GuideLine from "../../../../components/guide-line";
import GoBack from "../../../../components/go-back";
import Title02Text from "../../../../components/components-text/title-02-text";
import { withPolicy } from "../../../../context/mixin/with-policy";
import { ERROR_MESSAGES } from "../../../../constants/errorMessages";
import MediatorServices from "../../../../services/MediatorServices";
import { isString } from "util";
import DynamicProducts from "../../../../components/dynamic-products/DynamicProducts";

interface ChoosePolicyProps extends RouteComponentProps {
  hasInformativeNoteLife?: boolean;
  hasInformativeNoteNotLife?: boolean;
  policy: any;
  setLoading: Function;
  hasInformativeNote?: boolean;
  setShowInfo: Function;
  showInfo: boolean;
  user: any;
}

interface checkboxConditionsInternObject {
  checkboxText: string;
  errorText: string;
  checked: boolean;
  name: string;
  onChange: Function;
  showError: boolean;
  [key: string]: any;
}

interface checkboxConditionsObject {
  [key: string]: checkboxConditionsInternObject;
}

interface ChoosePolicyState {
  checkboxConditions: checkboxConditionsObject;
  enabledButton: boolean;
  guideLineCurrentStep: number;
  healthPolicySelected: PolicyLinkItem;
  redirectLink: string;
  showNewHiringModal: boolean;
  mediatorType: String;
  hiringAllowed: boolean;
  noInformativeNoteMessage: String;
  isCallingMediator: boolean;
}

interface PolicyLinkItem {
  text: string;
  link: string;
}

class ChoosePolicy extends React.Component<
  ChoosePolicyProps,
  ChoosePolicyState
> {
  private _typePoliciesText = ["1", "2", "3", "4"];

  private _guideLine = [
    {
      onCLick: () => { }
    },
    {
      onCLick: () => { }
    },
    {
      onCLick: () => { }
    },
    {
      onCLick: () => { }
    }
  ];

  readonly state: ChoosePolicyState;

  constructor(props: ChoosePolicyProps) {
    super(props);
    this.state = {
      checkboxConditions: {
        cond1: {
          checkboxText:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit, commodi.",
          errorText: "Este campo es obligatorio",
          checked: false,
          name: "cond1",
          onChange: (ev: any) => this.handleOnChange(ev),
          showError: false
        },
        cond2: {
          checkboxText:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit, commodi.",
          errorText: "Este campo es obligatorio",
          checked: false,
          name: "cond2",
          onChange: (ev: any) => this.handleOnChange(ev),
          showError: false
        }
      },
      enabledButton: true,
      guideLineCurrentStep: 0,
      healthPolicySelected: { text: "", link: "" },
      redirectLink: "",
      showNewHiringModal: props.showInfo,
      mediatorType: '0',
      hiringAllowed: true,
      noInformativeNoteMessage: '',
      isCallingMediator: false,
    };
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    localStorage.removeItem('internalName')
    this.props.policy.policyInfo.changePolicyType();
  };

  async componentWillReceiveProps(nextProps: ChoosePolicyProps) {
    if (nextProps.user !== '' && this.state.mediatorType === '0' && !this.state.isCallingMediator) {
      if (isString(nextProps.user)) {
        const user = JSON.parse(nextProps.user);
        this.setState({ isCallingMediator: true }, () => this.checkMediatorType(user.code));
      }
      else
        this.setState({ isCallingMediator: true }, () => this.checkMediatorType(nextProps.user.code));
    }
  }

  async checkMediatorType(userCode: string) {
    const response = await MediatorServices.getMediatorInfo({
      setLoading: this.props.setLoading,
      loader: true,
      mediatorCode: userCode,
    }).catch(() => {
      throw ERROR_MESSAGES["server-error"];
    });

    this.checkIsMediatorAllowed(response.type)
  }

  goToPendantHiring() {
    this.props.history.push("/contratacion/pendiente");
  }

  enableAcceptButton() {
    const { checkboxConditions } = this.state;
    const validated =
      Object.keys(checkboxConditions).filter(key => {
        if (!checkboxConditions[key].checked) {
          return checkboxConditions[key];
        }
      }).length !== 0;
    this.setState({ enabledButton: validated });
  }

  handleOnChange(ev: any) {
    const { checkboxConditions } = this.state;
    checkboxConditions[ev.target.name].checked = !checkboxConditions[
      ev.target.name
    ].checked;
    this.setState({ checkboxConditions }, () => this.enableAcceptButton());
  }

  setGuideLine = (step: number) => {
    this.setState({ guideLineCurrentStep: step });
  };

  guideLineModificator = (step: number) => {
    const { guideLineCurrentStep } = this.state;

    if (guideLineCurrentStep === 0 && step === -1) {
      return;
    }

    if (guideLineCurrentStep === this._guideLine.length - 1 && step === 1) {
      return;
    }

    this.setState({ guideLineCurrentStep: guideLineCurrentStep + step });
  };

  changeTextSwitchCurrentStep = (arrayText: any) => {
    const { guideLineCurrentStep } = this.state;
    let text: string = arrayText[guideLineCurrentStep];
    return text;
  };

  setHealthItemClick = (healthPolicySelected: PolicyLinkItem) => {
    this.setState({ healthPolicySelected });
  };

  navigateHealth() {
    const { healthPolicySelected } = this.state;
    if (healthPolicySelected.link) {
      this.props.history.push(healthPolicySelected.link);
    }
  }

  closeNewHiringModal() {
    const { setShowInfo, showInfo } = this.props;
    if (setShowInfo && showInfo) setShowInfo(false);
    this.setState({ showNewHiringModal: false });
  }

  checkIsMediatorAllowed(mediatorType: String) {
    const { hasInformativeNote } = this.props;

    if (!hasInformativeNote) {
      if (mediatorType.includes('1') || mediatorType.includes('4') || mediatorType.includes('6')) {
        this.setState({ hiringAllowed: false, noInformativeNoteMessage: 'Es necesaria la nota informativa para emitir una póliza', mediatorType: mediatorType, isCallingMediator: false });
      }
      else {
        this.setState({ noInformativeNoteMessage: 'Es obligatorio el envío al cliente de su nota informativa de distribuidor', mediatorType: mediatorType, isCallingMediator: false });
      }
    }

  }

  render() {
    const { guideLineCurrentStep } = this.state;
    let hide: string;
    return (
      <NewHiringContainer>
        <div
          className={`new-hiring-flow-name ${(hide =
            guideLineCurrentStep == 0 ? "hide" : "")}`}
        >
          <GoBack
            text="Nueva simulación"
            onclick={() => this.guideLineModificator(-1)}
          />
        </div>

        <div className="new-hiring-flow-status">
          <div className="new-hiring-flow-status-title">
            {/* <HeadsTitleText>
              <p>Tipo de póliza</p>
            </HeadsTitleText> */}
            <div className="new-hiring-title">
              <Title02Text>
                <p>Escoja la póliza a contratar</p>
              </Title02Text>
            </div>
          </div>
          <div className="new-hiring-flow-status-guide">
            <GuideLine
              steps={this._guideLine}
              currentStep={guideLineCurrentStep}
            />
          </div>
        </div>

        <ChoosePolicyContainer>
          <div className="policies-container">
            <DynamicProducts
              type="contratacion"
              setItemHealthClick={(value: PolicyLinkItem) => this.setHealthItemClick(value)}
              navigateHealth={() => this.navigateHealth()}
            />
            {
              /* 
               <div className={`${!hiringAllowed && 'informative-note-message'}`}>
                <p>{noInformativeNoteMessage}</p>
              */
            }
          </div>
        </ChoosePolicyContainer >
        <div>
          <p> En cumplimiento de lo establecido en el artículo 173 del Real Decreto-Ley 3/2020, de 4 de febrero le informamos que es
            responsabilidad del mediador la entrega de su nota informativa al tomador/asegurado  así como sus actualizaciones, sin que,
            la utilización de este portal le exima de comprobar el cumplimiento de esta obligación</p>
        </div>
      </NewHiringContainer >
    );
  }
}
export default withPolicy(withRouter(ChoosePolicy));
