import * as React from "react";
import { CardMyCampaignsStyle } from "./card-my-campaigns-style";
import Body02Text from "../components-text/body-02-text";
import { images } from "../../assets";

export interface ICardMyCampaignsProps {
  type: string;
  title: string;
  status: string;
  active?: boolean;
}

export interface ICardMyCampaignsState { }

export default class CardMyCampaigns extends React.Component<
  ICardMyCampaignsProps,
  ICardMyCampaignsState
  > {
  constructor(props: ICardMyCampaignsProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { title, type, status, active } = this.props;
    let subtitle = "Objetivo conseguido";
    let statusImg = images.IconCheckGreen;
    switch (status) {
      case "in_action":
        subtitle = "En progreso";
        statusImg = images.IconClockWhite;
        break;
      case "in_process":
        subtitle = "En seguimiento";
        statusImg = images.IconClockWhite;
        break;
      case "planned":
        subtitle = "Planificada";
        statusImg = images.IconPendingBlue;
        break;
      case "definition":
        subtitle = "Pendiente";
        statusImg = images.IconPendingBlue;
        break;
      case "revoked":
        subtitle = "Anulada";
        statusImg = images.IconWarningRed;
        break;
      case "cancelled":
        subtitle = "Cancelada";
        statusImg = images.IconWarningRed;
        break;
      case "finished":
        subtitle = "Finalizada";
        statusImg = images.IconWarningRed;
        break;
    }
    return (
      <CardMyCampaignsStyle className={`${status} ${active ? "active" : ""}`}>
        <div className="card-my-campaigns-top">
          <div className="card-my-campaigns-top__subtitle">
            <p>{type}</p>
          </div>
          <div className="card-my-campaigns-top__status">
            <img src={statusImg} />
          </div>
        </div>
        <div className="card-my-campaigns-title">
          <Body02Text>{title}</Body02Text>
        </div>
        <div className="card-my-campaigns-subtitle">
          <p>{subtitle}</p>
        </div>
      </CardMyCampaignsStyle>
    );
  }
}
