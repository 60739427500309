export const receiptColumns = [
    {
        text: "Nº de recibo",
        filter: true,
        ascendent: true,
        key: 'responsiveHeader fixedWidth150'
    },
    {
        text: "Cliente",
        filter: true,
        ascendent: true,
        key: 'responsiveHeader fixedWidth150'
    },
    {
        text: "Línea de negocio/Grupo negocio",
        filter: true,
        ascendent: false,
        key: 'responsiveHeader fixedWidth170'
    },
    {
        text: "Póliza",
        filter: true,
        ascendent: true,
        key: 'responsiveHeader fixedWidth150'
    },
    {
        text: "Mediador",
        filter: true,
        ascendent: true,
        key: 'responsiveHeader fixedWidth150'
    },
    {
        text: "Nombre completo del tomador",
        filter: true,
        ascendent: true,
        key: 'responsiveHeader fixedWidth170'
    },
    {
        text: "Fecha cobertura",
        filter: true,
        ascendent: true,
        key: 'responsiveHeader fixedWidth170'
    },
    {
        text: "Estado",
        filter: true,
        ascendent: true,
        key: 'responsiveHeader fixedWidth150'
    },
    {
        text: "Importe total",
        filter: true,
        ascendent: true,
        key: 'responsiveHeader fixedWidth150'
    },
];

export const RECEIPT_SEARCH_OPTION = {
    RECIBO: "Recibo",
    TOMADOR: "Tomador",
    POLIZA: "Poliza",
}