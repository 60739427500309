import styled, { css } from 'styled-components';

export const H4TextContainer = styled.div`
    white-space:pre-wrap;
    font-family: "museo sans";
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    
    ${props => css`
        color: ${props.color}
    `}



`;