import { getPolicyData } from "../../services/utils";

export const getTitles = () => {
    return  {
        newIncorporation: 'Incorporación nuevo asegurado',
        basicData: 'Cambio de datos básicos',
    }
    
}

export const getMediatorCompleteName = (): string => {
    const loggedMediatorString = localStorage.getItem("loggedMediator");
    const loggedMediator = loggedMediatorString
      ? JSON.parse(loggedMediatorString)
      : { mediatorCode: "" };
    let selectedMediator = loggedMediator.relatedMediators.find((med: {mediatorCode: string}) => med.mediatorCode === getPolicyData().mediator);
    if (!selectedMediator) selectedMediator = loggedMediator;
    return `${selectedMediator.mediatorCode} - ${selectedMediator.mediatorName} - ${selectedMediator.orderNif}`
}
