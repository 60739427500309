import GeneralServices from "./GeneralServices";
import {
  GestDocCreateFileRequestDto, GestDocSearchFileRequestDto, GestDocFileListResponseDto,
  GestDocFileInfoResponseDto, GestDocFileDataResponseDto,
  GesDocProductInfo
} from "../models/gest-doc-models";

class GestDocServices {
  getFile = async (setLoading: any, loader: boolean, idNode: string): Promise<GestDocFileDataResponseDto> => {
    try {
      const response = await GeneralServices.getFile({
        setLoading: setLoading,
        loader: loader,
        endpoint: `gestdoc/getFile/${idNode}`,
      });

      let result = {
        fileData: response.data,
        fileName: this.getFileName(response.headers["content-disposition"]),
        fileType: response.headers["content-type"],
      };
      return result;
    }
    catch (err) {
      throw err;
    }
  }

  getFileName(disposition: string | undefined): string {
    let filename = "";
    if (disposition && disposition.indexOf('attachment') !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  }

  getFileInfo = async (setLoading: any, loader: boolean, idNode: string): Promise<GestDocFileInfoResponseDto> => {
    try {
      const response = await GeneralServices.get({
        setLoading: setLoading,
        loader: loader === false ? false : true,
        endpoint: `gestdoc/getFileInfo/${idNode}`,
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  uploadFile = async (setLoading: any, loader: boolean, data: GestDocCreateFileRequestDto, majorVersion: boolean = false): Promise<GestDocFileInfoResponseDto> => {
    try {
      const fd = new FormData();
      fd.append("nodeId", data.nodeId);
      fd.append("file", data.file, data.file.name);
      fd.append("fileInfo", JSON.stringify(data.fileInfo));

      let endpoint = "gestdoc/uploadFile";
      if (majorVersion)
        endpoint += "?majorVersion=true"

      const response = await GeneralServices.postFormData({
        setLoading: setLoading,
        loader: loader,
        endpoint: endpoint,
        data: fd
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  updateFile = async (setLoading: any, loader: boolean, idNode: string): Promise<GestDocFileInfoResponseDto> => {
    try {
      const response = await GeneralServices.put({
        setLoading: setLoading,
        loader: loader,
        endpoint: `gestdoc/updateFile/${idNode}`
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  };

  deleteFile = async (setLoading: any, loader: boolean, idNode: string): Promise<Boolean> => {
    try {
      const response = await GeneralServices.deleteReq({
        setLoading: setLoading,
        loader: loader,
        endpoint: `gestdoc/deleteFile/${idNode}`,
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  };

  searchFile = async (setLoading: any, loader: boolean, data: GestDocSearchFileRequestDto): Promise<any> => {
    try {
      const response = await GeneralServices.post({
        setLoading: setLoading,
        loader: loader,
        endpoint: `gestdoc/searchFile`,
        data: data
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  
  searchFileOrderByModifiedAt = async (setLoading: any, loader: boolean, data: GestDocSearchFileRequestDto): Promise<any> => {
    try {
      const response = await GeneralServices.post({
        setLoading: setLoading,
        loader: loader,
        endpoint: `gestdoc/searchFileOrderByModifiedAt`,
        data: data
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  searchAndGetFirstFile = async (setLoading: any, loader: boolean, data: GestDocSearchFileRequestDto): Promise<GestDocFileDataResponseDto> => {
    try {
      const response = await GeneralServices.postFileResponse({
        setLoading: setLoading,
        loader: loader,
        endpoint: `gestdoc/searchAndGetFirstFile`,
        data: data
      });

      let result = {
        fileData: response ? response.data : null,
        fileName: response && response.headers ? this.getFileName(response.headers["content-disposition"]) : "",
        fileType: response && response.headers ? response.headers["content-type"] : ""
      };
      return result;
    } catch (err) {
      throw err;
    }
  };

  preHiringDocs = async (setLoading: any, loader: boolean, data: any): Promise<GestDocFileDataResponseDto> => {
    try {
      const response = await GeneralServices.getFile({
        setLoading: setLoading,
        loader: loader,
        endpoint: `gestdoc/preHiringDocs?idProducto=${data.id}&type=${data.type}`,
      });

      let result = {
        fileData: response ? response.data : null,
        fileName: response && response.headers ? this.getFileName(response.headers["content-disposition"]) : "",
        fileType: response && response.headers ? response.headers["content-type"] : ""
      };
      return result;
    } catch (err) {
      throw err;
    }
  };

  getNotaInformativaMediador = async (setLoading: any, loader: boolean, productType: string): Promise<GestDocFileDataResponseDto> => {
    try {
      const response = await GeneralServices.getFile({
        setLoading: setLoading,
        loader: loader,
        endpoint: `gestdoc/getNotaInformativaMediador?productType=${productType}`,
      });

      let result = {
        fileData: response ? response.data : null,
        fileName: response && response.headers ? this.getFileName(response.headers["content-disposition"]) : "",
        fileType: response && response.headers ? response.headers["content-type"] : ""
      };
      return result;
    } catch (err) {
      throw err;
    }
  };

  searchNodeAndGetInfo = async (setLoading: any, loader: boolean, productType: string): Promise<any> => {
    try {
      const response = await GeneralServices.get({
        setLoading: setLoading,
        loader: loader,
        endpoint: `gestdoc/searchNodeAndGetInfo?productType=${productType}`,
      });
      return response;
    } catch (err) {
      throw err;
    }
  };
  searchAndGetLastFile = async (setLoading: any, loader: boolean, data: GestDocSearchFileRequestDto): Promise<GestDocFileDataResponseDto> => {
    try {
      const response = await GeneralServices.postFileResponse({
        setLoading: setLoading,
        loader: loader,
        endpoint: `gestdoc/searchAndGetLastFile`,
        data: data
      });

      let result = {
        fileData: response ? response.data : null,
        fileName: response && response.headers ? this.getFileName(response.headers["content-disposition"]) : "",
        fileType: response && response.headers ? response.headers["content-type"] : ""
      };
      return result;
    } catch (err) {
      throw err;
    }
  };
}

export default new GestDocServices();