import React, { FC, useState } from "react";
import SearchBox from "../../../../../components/search-box-mediadores/search-box";
import InputBox from "../../../../../components/input-box/input-box";
import { UseFormFunctionsProps } from "../../hooks/hooks.types";
import DicotomicCheckbox from "../../../../../components/dicotomic-checkbox/dicotomic-checkbox";
import { addressData } from "../../new-insured/utils";
import { getCities, getPostalCodes, getProvinces, getStreets } from "../assets";
import { deepGet } from "../../../../../utils/utility";
import { HealthAddressFormContainer } from "../../../../hirings/new-hiring/health/forms/address-item/address-form-item-style";

const AddressForm: FC<UseFormFunctionsProps> = ({
  values,
  errors,
  handleChange,
}) => {
  const [isAddressNotNormalized, setIsAddressNotNormalized] = useState<boolean>(
    values.address.isAddressNotNormalized
  );
  const [provinces, setProvinces] = useState<{ label: string }[]>([]);
  const [cities, setCities] = useState<{ label: string }[]>([]);
  const [streets, setStreets] = useState<{ label: string }[]>([]);
  const [postalCodes, setPostalCodes] = useState<{ label: string }[]>([]);

  const changeValueSearchBox = (ev: any, arg: string) => {
    const value: string = ev.target.value;

    if (value.length < 2) return;

    if (value.length >= 2) {
      switch (arg) {
        case "province":
          if (
            !provinces[0] ||
            provinces[0].label.toLowerCase() !== value.toLowerCase()
          ) {
            getProvinces(value, setProvinces);
          }
          break;
        case "city":
          if (
            !cities[0] ||
            cities[0].label.toLowerCase() !== value.toLowerCase()
          ) {
            getCities(value, setCities, values);
          }
          break;
        case "street":
          if (
            !streets[0] ||
            streets[0].label.toLowerCase() !== value.toLowerCase()
          ) {
            getStreets(value, setStreets, values);
          }
          break;
        case "postalCode":
          if (
            !postalCodes[0] ||
            postalCodes[0].label.toLowerCase() !== value.toLowerCase()
          ) {
            getPostalCodes(value, setPostalCodes, values);
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <HealthAddressFormContainer>
      <div className="content-wrapper bottomMargin20">
        <div className="personal-data-wrapper margin-top-20" id="data-address">
          <div className="data-title">
            <h5>DATOS DOMICILIO</h5>
          </div>

          <div className="colSin-100">
            <div className="colSin-33 rightPadding20 bottomMargin20 topMargin20">
              {!isAddressNotNormalized ? (
                <div className="inputBox">
                  <SearchBox
                    onChange={(ev: any) => changeValueSearchBox(ev, "province")}
                    errorCode={deepGet(errors, 'address.province', '')}
                    onSelect={(item: string) =>
                      handleChange("province", item, "address")
                    }
                    required
                    disabled={false}
                    withLoader={true}
                    initialValue={values.address.province.label}
                    optionsText={provinces}
                    optionKey={"code"}
                    optionValue={"label"}
                    labelText=""
                    notScroll
                  />
                  <label>PROVINCIA</label>
                </div>
              ) : (
                <div className="inputBox">
                  <InputBox
                    placeholder="Provincia"
                    disabled={false}
                    required
                    errorCode={deepGet(errors, 'address.province', '')}
                    value={values.address.province.label}
                    type={"text"}
                    onChange={(value) =>
                      handleChange(
                        "province",
                        { value: "", label: value },
                        "address"
                      )
                    }
                    notScroll
                  />
                </div>
              )}
            </div>

            <div className="colSin-66 rightPadding20 bottomMargin20 topMargin20">
              {!isAddressNotNormalized ? (
                <div className="inputBox">
                  <SearchBox
                    onChange={(ev: any) => changeValueSearchBox(ev, "city")}
                    errorCode={deepGet(errors, 'address.town', '')}
                    onSelect={(item: string) =>
                      handleChange("town", item, "address")
                    }
                    required
                    disabled={values.address.province.label.length < 3}
                    initialValue={values.address.town.label}
                    optionsText={cities}
                    optionKey={"code"}
                    optionValue={"label"}
                    labelText=""
                    withLoader={true}
                    notScroll
                  />
                  <label>POBLACIÓN</label>
                </div>
              ) : (
                <div className="inputBox">
                  <InputBox
                    placeholder="Población"
                    disabled={false}
                    required
                    errorCode={deepGet(errors, 'address.town', '')}
                    value={values.address.town.label}
                    type={"text"}
                    onChange={(value) =>
                      handleChange(
                        "town",
                        { label: value, value: "" },
                        "address"
                      )
                    }
                    notScroll
                  />
                </div>
              )}
            </div>

            <div className="colSin-100 rightPadding20 bottomMargin20">
              {!isAddressNotNormalized ? (
                <div className="inputBox">
                  <SearchBox
                    onChange={(ev: any) => changeValueSearchBox(ev, "street")}
                    errorCode={deepGet(errors, 'address.address', '')}
                    onSelect={(item: string) =>
                      handleChange("address", item, "address")
                    }
                    required
                    disabled={values.address.town.label.length < 3}
                    initialValue={values.address.address.label}
                    optionsText={streets}
                    optionKey={"code"}
                    optionValue={"label"}
                    labelText=""
                    withLoader={true}
                    notScroll
                  />
                  <label>DOMICILIO</label>
                </div>
              ) : (
                <div className="inputBox">
                  <InputBox
                    placeholder="Domicilio"
                    disabled={false}
                    required
                    errorCode={deepGet(errors, 'address.address', '')}
                    value={values.address.address.label}
                    type={"text"}
                    onChange={(value) =>
                      handleChange(
                        "address",
                        { value: "", label: value },
                        "address"
                      )
                    }
                    notScroll
                  />
                </div>
              )}
            </div>

            <div className="colSin-20 rightPadding20 bottomMargin20">
              <div className="inputBox">
                <InputBox
                  errorCode={deepGet(errors, 'address.number', '')}
                  onChange={(value: string) =>
                    handleChange("number", value, "address")
                  }
                  placeholder="NÚMERO/KM"
                  required
                  disabled={false}
                  type={"text"}
                  maxLength={10}
                  value={values.address.number}
                />
              </div>
            </div>

            <div className="colSin-20 rightPadding20 bottomMargin20">
              <div className="inputBox">
                <InputBox
                  onChange={(value: string) =>
                    handleChange("portalValue", value, "address")
                  }
                  placeholder="PORTAL"
                  type={"number"}
                  disabled={false}
                  maxLength={5}
                  value={values.address.portalValue}
                />
              </div>
            </div>

            <div className="colSin-20 rightPadding20 bottomMargin20">
              <div className="inputBox">
                <InputBox
                  onChange={(value: string) =>
                    handleChange("stairsValue", value, "address")
                  }
                  placeholder="ESCALERA"
                  type={"text"}
                  disabled={false}
                  maxLength={5}
                  value={values.address.stairsValue}
                />
              </div>
            </div>

            <div className="colSin-20 rightPadding20 bottomMargin20">
              <div className="inputBox">
                <InputBox
                  onChange={(value: string) =>
                    handleChange("floorValue", value, "address")
                  }
                  placeholder="PISO"
                  type={"text"}
                  disabled={false}
                  maxLength={5}
                  value={values.address.floorValue}
                />
              </div>
            </div>

            <div className="colSin-20 rightPadding20 bottomMargin20">
              <div className="inputBox">
                <InputBox
                  onChange={(value: string) =>
                    handleChange("doorValue", value, "address")
                  }
                  placeholder="PUERTA"
                  type={"text"}
                  maxLength={5}
                  disabled={false}
                  value={values.address.doorValue}
                />
              </div>
            </div>

            <div className="colSin-20 rightPadding20 bottomMargin20">
              {!isAddressNotNormalized ? (
                <div className="inputBox">
                  <SearchBox
                    onChange={(ev: any) =>
                      changeValueSearchBox(ev, "postalCode")
                    }
                    errorCode={deepGet(errors, 'address.postalCode', '')}
                    onSelect={(item: string) =>
                      handleChange("postalCode", item, "address")
                    }
                    required
                    disabled={values.address.address.label.length < 3}
                    initialValue={values.address.postalCode.value}
                    optionsText={postalCodes}
                    optionKey={"code"}
                    optionValue={"label"}
                    labelText=""
                    withLoader={true}
                    notScroll
                  />
                  <label>CÓDIGO POSTAL</label>
                </div>
              ) : (
                <div className="inputBox">
                  <InputBox
                    required
                    placeholder="Código postal"
                    disabled={false}
                    errorCode={deepGet(errors, 'address.postalCode', '')}
                    value={values.address.postalCode.value}
                    maxLength={5}
                    type={"number"}
                    onChange={(value) =>
                      handleChange(
                        "postalCode",
                        { label: value, value },
                        "address"
                      )
                    }
                    notScroll
                  />
                </div>
              )}
            </div>

            <div className="colSin-80 rightPadding20 bottomMargin20">
              <div className="inputBox">
                <DicotomicCheckbox
                  className={`optional`}
                  checkboxText="CONTRATAR CON DIRECCIÓN NO NORMALIZADA"
                  onChange={(value: boolean) =>
                    {
                      setIsAddressNotNormalized(value);
                      handleChange('address', addressData)
                    }
                  }
                  disabled={false}
                  initChecked={isAddressNotNormalized}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HealthAddressFormContainer>
  );
};

export default AddressForm;
