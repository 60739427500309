import { differenceInYears } from "date-fns";
import moment from "moment";
import React, { ChangeEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import HeadText from "../../../../../components/components-text/head-text";
import NoteText from "../../../../../components/components-text/note-text";
import Title03Text from "../../../../../components/components-text/title-03-text";
import WebText from "../../../../../components/components-text/web-text";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import GipInsuredResultItem from "../../../../../components/gip-insured-item/gip-insured-result-item";
import { GipHireMenuHelper } from "../../../../../components/gip-menu/gip-hire-menu";
import GipPaymentResultItem from "../../../../../components/gip-result-item/gip-payment-result-item";
import { HealthHireMenuHelper, Steps } from "../../../../../components/health-hire-menu/health-hire-menu";
import HealthInsuredItem from "../../../../../components/dental-insured-item/dental-insured-item";
import MainButton from "../../../../../components/main-button";
import MainTable from "../../../../../components/main-table";
import { ERROR_MESSAGES } from "../../../../../constants/errorMessages";
import { SIGNATURE_TYPE } from "../../../../../constants/gest-doc";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { GestDocFileInfoResponseDto, Metadata } from "../../../../../models/gest-doc-models";
import { IMediator } from "../../../../../models/mediator-model";
import { IUser } from "../../../../../models/user-model";
import GestDocServices from "../../../../../services/Gest-Doc.Services";
import { GestDoc } from "../../../../../services/GestDoc";
import GipServices from "../../../../../services/GipServices";
import { downloadFile, formatter } from "../../../../../utils/utility";
import { Insured } from "../gip-guarantee/gip-guarantee-dto/gip-guarantee-risks-dto";
import { ResultDataContainer } from "./gip-result-data-style";
import { calcAge } from "../../../../../utils/utility";
import { getDayMonthYear } from "../../../../../utils/time";
import { GIP_PRODUCTS_NAME } from "../../../../../constants/gipProducts";
import { acceptedFileMimeType } from "../../../../../constants/acceptedFileMimeType";

interface ResultProps extends RouteComponentProps {
  user: IUser;
  mediator: IMediator;
  data: any;
  resumeHiring: boolean;
  setLoading: any;
  coverages?: any;
  hasInformativeNote?: boolean;
  history: any;
  is403?: boolean;
  is404?: boolean;
  isCampaignConfigration?: boolean;
  isLogged?: boolean;
  loading?: boolean;
  location: any;
  match: any;
  policy?: any;
  setHasInformativeNote?: Function;
  saveHiring: Function;
  cotizacion: string;
}

interface IQuestionnairesResult {
  documentNumber: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  name: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  birthDate: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  age: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  gender: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  state: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
}
export interface IProtectionData {
  consentDate: string;
  dataTransfer: string;
  thirdParty: string;
  thirdPartyCalls: string;
}

interface ResultState {
  // price: {
  //   value: string;
  //   paymentTime: string;
  //   iban: string;
  //   paymentType: string;
  //   campaigns: any[];
  // },
  // mediator: {
  //   delegation: string;
  //   mediatorValue: string;
  // },
  // startDate: string;
  // taker: any;
  // insureds: any[];
  serverError: string;
  questionnairesData: IQuestionnairesResult[];
  // order: string;
  // ascendent: boolean | undefined;
  // uploadedDocuments: HealthDocument[];
  // uploadedDocumentsRows: IDocumentsResult[];
  // signedDocument: ISignedFileState;
  // signatureNode: string;
  // protectionData: IProtectionData;
}

const documentsColumns = [
  //TODO: Esta columna hay que quitarla hasta saber si viene de algún sitio o se quita
  /* {
    text: "Catálogo",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  }, */
  {
    text: "Documento",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Fecha anexión",
    filter: false,
    ascendent: false,
    key: "responsiveHeader",
  },
  {
    text: "Ver",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  }
];

const questionnairesColumns = [
  {
    text: "Nº documento",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Nombre",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },

  {
    text: "Cuestionario",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  }
];

class ResultDataLayout extends React.Component<ResultProps, ResultState> {
  constructor(props: ResultProps) {
    super(props);
    this.state = {
      serverError: '',
      questionnairesData: this.getQuestionnairesData(props.data.questionnaires),
    };
  }

  /* getInitialPaymentMethod = (initialPaymentMethod?: PaymentMethod): PaymentMethod => {
    let paymentMethod = initialPaymentMethod;
    if (!paymentMethod) {
      paymentMethod = {
        mainIban: {
          iban: "",
          sameAsMain: true,
        },
        areInsuredsCopayGrouped: this.props.isCopay ? true : undefined,
        copayIban: this.props.isCopay ? {
          iban: "",
          sameAsMain: true,
          sepaSignedDate: moment().format(DateFormat),
        } : undefined,
        isPhysicalSignature: false,
      };
    }
    return paymentMethod;
  } */

  /* getDefaultISignedFile = (isDisabled: boolean, isRequired: boolean): ISignedFileState => {
    return {
      file: undefined,
      error: "",
      disabled: isDisabled,
      isRequired: isRequired,
    }
  }

  getISignedFile = (isDisabled: boolean, isRequired: boolean, file: File): ISignedFileState => {
    return {
      file: file,
      error: "",
      disabled: isDisabled,
      isRequired: isRequired,
    }
  } */

  getQuestionnairesData(questionnaires: any): IQuestionnairesResult[] {
    try {
      let questionnairesDataArray: IQuestionnairesResult[] = [];

      Object.keys(questionnaires).forEach((questionnaire: any, index: number) => {
        let name = '';
        this.props.data.insureds.forEach((insured: Insured) => {
          if (questionnaires[questionnaire].insuredDocNumber === insured.docNumber) {
            name = `${insured.name} ${insured.lastname1} ${insured.lastname2}`
          }
        })
        let questionnaireData: any = {
          documentNumber: {
            text: questionnaires[questionnaire].insuredDocNumber,
            type: "",
            link: "",
            key: "responsive",
          },
          name: {
            text: name,
            type: "",
            link: "",
            key: "responsive",
          },
          action: {
            text: '',
            type: "",
            key: "icon-button",
            img: images.IconDownloadBlue,
            onClick: () => this.getQuestionnaireDocument(questionnaires[questionnaire].insuredDocNumber)
          }
        }

        questionnairesDataArray.push(questionnaireData)
      });

      return questionnairesDataArray;
    }
    catch {

      const { history } = this.props;
      history.replace("");
      return [];

    }
  }

  async getQuestionnaireDocument(docNumber: string) {
    const { setLoading, data } = this.props;

    let name = '';
    let gender = '';
    let birthDate = '';
    data.insureds.forEach((insured: Insured) => {
      if (docNumber === insured.docNumber) {
        name = `${insured.name} ${insured.lastname1} ${insured.lastname2}`
        gender = insured.gender;
        birthDate = moment(new Date(insured.birthDate)).format('DD/MM/YYYY').toString();
      }
    })

    let questionnaireDocument = await GipServices.getGipInsuredQuestionnaire({
      setLoading: setLoading,
      loader: true,
      data: {
        insuredName: name,
        insuredGender: gender,
        insuredDocNumber: docNumber,
        insuredBirthDate: birthDate,
        questionnaireData: data.questionnaires.find((insuredQuestionnaire: any) => insuredQuestionnaire.insuredDocNumber && insuredQuestionnaire.insuredDocNumber === docNumber)
      }
    });

    if (questionnaireDocument) {
      const buffer = Buffer.from(questionnaireDocument, "base64");
      downloadFile(buffer, `${docNumber}_questionnaire.pdf`, "PDF", true)
    }
  }

  async downloadDocument(id: string) {
    const { setLoading } = this.props;
    var result = await GestDocServices.getFile(setLoading, true, id);
    if (result && result.fileData) {
      downloadFile(result.fileData, result.fileName, result.fileType, true);
    } else {
      this.setState({ serverError: 'Error al descargar el archivo' })
    }
  }

  loadHealthDocumentsFromGestDoc = async () => {
    const { setLoading, data } = this.props;

    let entries: GestDocFileInfoResponseDto[] = [];
    //Buscamos las fotocopias del DNI subidas
    let peopleGestDocDocuments = await GestDocServices.searchFile(setLoading, true,
      GestDoc.getPeopleDocumentsRequest(
        data.taker
      )
    )
    await data.insureds.forEach(async (insured: any) => {
      const response = await GestDocServices.searchFile(setLoading, true,
        GestDoc.getPeopleDocumentsRequest(
          undefined, [insured]
        )
      )
      if (response && response.list && response.list.entries && response.list.entries.length > 0)
        peopleGestDocDocuments.list.entries.concat(response.list.entries)
    });
    if (peopleGestDocDocuments.list.entries.length) {
      entries = entries.concat(peopleGestDocDocuments.list.entries);
    }

    //Buscamos últimos recibos pagados
    let lastRecipe = await GestDocServices.searchFile(setLoading, true,
      GestDoc.getLastReceiptDocumentsRequest(data.taker)
    )

    if (lastRecipe.list.entries.length) {
      entries = entries.concat(lastRecipe.list.entries);
    }

    //Buscamos cuestionarios
    let questionnaires = await GestDocServices.searchFile(setLoading, true,
      GestDoc.getGipQuestionnaireDocumentsSearch(data.insureds)
    )

    //TODO: Falta buscar otro tipo de documentación

    if (questionnaires.list.entries.length) {
      entries = entries.concat(questionnaires.list.entries);
    }

    let uploadedDocuments = entries.map((ele) => {
      return {
        id: ele.entry.id,
        name: ele.entry.name,
        date: ele.entry.createdAt,
        associatedTo: ele.entry.properties && ele.entry.properties["ins:personIdentityDocument"] ? ele.entry.properties["ins:personIdentityDocument"] : ""
      }
    });
    //this.setState({ uploadedDocuments: uploadedDocuments });
  }

  componentWillReceiveProps(nextProps: ResultProps) {
    if (nextProps.resumeHiring) {
      this.resumeHiring();
    }
  }

  async resumeHiring() {
    /* 
        const { policy } = this.props
        if (this.toValidate()) {
    
          //Si se ha escogido la firma física la subimos a alfresco
          //TODO: No sabemos los metadatos todavía, estos son temporales
    
          if (this.props.data.paymentMethod.isPhysicalSignature) {
            let { signatureNode } = this.state;
            if (signatureNode && signatureNode !== '') {
              await GestDocServices.deleteFile(this.props.setLoading, true, signatureNode)
            }
    
            const { signedDocument } = this.state;
            const { setLoading, data, user, mediator, cotizacion } = this.props;
            const productType: string = policy.product.products_internalName
            let productInfo: IProductConfig = await HealthService.getProductConfig(productType, setLoading, false);
            let metadata: Metadata;
            metadata = GestDoc.getFirmaSolicitudSeguroRequest(
              false,
              user,
              data.taker,
              cotizacion,
              data.insureds,
              productInfo.colective,
              productType,
              productInfo.combiProd,
              mediator.nif
            ).fileInfo;
            metadata["ins:signatureType"] = SIGNATURE_TYPE["Manuscrita"];
    
            if (signedDocument.file) {
              const response = await GestDoc.uploadDocument(NOTA_INFORMATIVA_PARENT_NODE_ID, signedDocument.file, metadata, this.props.setLoading);
              if (response) {
                this.props.data.physicalSignatureNode = response.entry.id;
                signatureNode = response.entry.id;
                this.setState({ signatureNode })
                this.props.saveHiring(true)
              }
            }
    
          }
    
          const response: SendaHiringResponse = await HealthService.hiring({
            setLoading: this.props.setLoading,
            data: {
              COTIZACION: this.props.cotizacion,
              ID_FIRMA_DIGITAL: this.props.data.paymentMethod.isPhysicalSignature ? "NO" : "DI"
            },
          }).catch((error) => {
            this.setState({ serverError: `Error al emitir la póliza: ${error.data.message}` });
            throw ERROR_MESSAGES["server-error"];
          });
    
          if (response && response.body) {
            this.props.data.status = response.body.PAPISOLEMISRESP.PAPIEMISRESP.ESTADO;
            this.props.saveHiring(true);
            this.props.history.push('/contratacion/pendiente')
          }
        }
     */
  }


  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    //this.loadHealthDocumentsFromGestDoc();
    //this.loadSignDocument();
  };

  /* async loadSignDocument() {

    if (this.props.data.physicalSignatureNode) {
      const paymentMethod: PaymentMethod = this.getInitialPaymentMethod(this.props.data.paymentMethod);
      let fileResponse = await GestDocServices.getFile(this.props.setLoading, true, this.props.data.physicalSignatureNode)
      const file: File = new File([(fileResponse.fileData.buffer)], fileResponse.fileName);
      this.setState({ signedDocument: this.getISignedFile(paymentMethod.isPhysicalSignature, paymentMethod.isPhysicalSignature, file) })
    }

  } */

  render() {
    let { data } = this.props;
    let { questionnairesData } = this.state;

    //const isSignedDocumentRequired = this.props.data.paymentMethod.isPhysicalSignature ? 'required' : '';
    //const coveragesAmount = this.getCoverages(this.props.data.priceData.priceResponse.body.RESPUESTA.IMPORTES_ASEGURADO_GARANTIA)
    try {
      return (
        <ResultDataContainer>

          <div className="data-flow-status-title">

            <h2>DETALLE PREVIO DE LA CONTRATACIÓN</h2>

          </div>

          <div className="data-flow-status">
            <div className="data-flow-status-title">

              <h3>{'DATOS DEL SEGURO'}</h3>

            </div>
          </div>
          <div className="result-simulation-special">
            <div className="result-simulation-special-title">
              <Title03Text>
                <p>Póliza</p>
              </Title03Text>
              <div>
                <p className="price">
                  {formatter.format(Number(data.pricingData.priceDataSelected.TotalPrimaNeta))}
                  <span>{'/' + data.pricingData.priceDataSelected.DesFormaPago}</span>
                </p>
              </div>
            </div>
            <div className="result-simulation-special-container">
              <div className="result-simulation-special-item">
                <HeadText>
                  <p>
                    Salud: {GIP_PRODUCTS_NAME[this.props.match.params.product]}
                  </p>
                </HeadText>
              </div>
              <div className="result-simulation-special-item">
                <div className="result-simulation-special-subitem">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>FECHA EMISIÓN</p>
                    </NoteText>
                  </div>
                  <div className="result-simulation-special-subitem-content">
                    <WebText>
                      <p>
                        {data.taker.policy.startDate}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-simulation-special-subitem">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>DELEGACIÓN</p>
                    </NoteText>
                  </div>
                  <div className="result-simulation-special-subitem-content">
                    <WebText>
                      <p>
                        {data.mediatorUser.delegation}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-simulation-special-subitem">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>MEDIADOR</p>
                    </NoteText>
                  </div>
                  <div className="result-simulation-special-subitem-content">
                    <WebText>
                      <p>
                        {data.mediatorUser.code}
                      </p>
                    </WebText>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="result-simulation-item float">
            <div className="result-simulation-special-title">
              <Title03Text>
                <p>Cliente</p>
              </Title03Text>
            </div>
            <div className="result-simulation-item-content">
            </div>

            <div className="result-simulation-special">
              <div className="result-hiring-special-title">
                <Title03Text>
                  <p>Tomador</p>
                </Title03Text>
              </div>
              <div className="result-hiring-special-container col-100">
                <div className="result-hiring-special-first-item col-30">
                  <HeadText>
                    <p>
                      {data.taker.name ?
                        `${data.taker.name} ${data.taker.lastname1} ${data.taker.lastname2}` : ''
                      }
                    </p>
                  </HeadText>
                </div>
                <div className="result-hiring-special-item col-70">

                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Tipo documento</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.docType} {data.taker.physicalPerson ? 'Persona física' : 'Persona jurídica'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Nº documento</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.docNumber}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Género</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.gender === 'H' ? 'Hombre' : 'Mujer'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Fecha de nacimiento</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {moment(data.taker.birthDate).format('DD/MM/YYYY')}
                          </p>
                        </WebText>
                      </div>
                    </div>
                  </div>

                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>DOMICILIO</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.address ? data.taker.address.address.value + ", " + data.taker.address.number : ""}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>LOCALIDAD</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.address ? data.taker.address.town.value : ''}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>PROVINCIA</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.address ? data.taker.address.province.value : ''}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>CÓDIGO POSTAL</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.address ? data.taker.address.postalCode : ''}
                          </p>
                        </WebText>
                      </div>
                    </div>
                  </div>

                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Email</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.email}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Teléfono fijo</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.landlinePhone}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Teléfono móvil</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.mobilePhone}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="col-20"></div>
                  </div>

                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>CUENTA BANCARIA</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.paymentMethod.mainIban.iban ? data.paymentMethod.mainIban.iban : ""}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>FORMA DE PAGO</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.pricingData.priceDataSelected.DesFormaPago}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>MEDIO DE PAGO</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.paymentMethod.mainIban.iban ? 'DOMICILIACIÓN' : ''}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="col-20"></div>
                  </div>

                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>FECHA CONSENTIMIENTO</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.protectionData.protectionData.consentDate.value}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>CESIÓN DE DATOS</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.protectionData.protectionData.dataTransfer.value === 'S' ? 'SI' : 'NO'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>ENVÍO DE PUBLICIDAD DE TERCEROS</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.protectionData.protectionData.thirdParty.value === 'S' ? 'SI' : 'NO'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className=""></div>
                  </div>

                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-50">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Cesión de llamadas promocionando productos de terceros</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.protectionData.protectionData.thirdPartyCalls.value === 'S' ? 'SI' : 'NO'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>

          <div className="data-flow-status">
            <div className="data-flow-status-title">

              <h3>{'DATOS DEL RIESGO'}</h3>

            </div>
          </div>

          <div className="result-simulation-item float">
            <div className="result-simulation-special-title">
              <Title03Text>
                <p>Asegurados</p>
              </Title03Text>
            </div>
            <div className="result-simulation-item-content">
            </div>
            {
              this.props.data.insureds.map((insured: any, index: number) => (
                <div className="result-simulation-special">
                  <div className="result-hiring-special-title">
                    <Title03Text>
                      <p>Asegurado {index + 1}</p>
                    </Title03Text>
                  </div>
                  <div className="result-hiring-special-container col-100">
                    <div className="result-hiring-special-first-item col-100">
                      <div className="result-hiring-special-title2">
                        <HeadText>
                          <p>
                            {data.taker.name ?
                              `${insured.name} ${insured.lastname1} ${insured.lastname2}` : ''
                            }
                          </p>
                        </HeadText>
                      </div>

                      <div className="result-hiring-special-row">
                        <div className="result-hiring-special-subitem col-40">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Tipo documento</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.docType} {insured.physicalPerson ? 'Persona física' : 'Persona jurídica'}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Nº documento</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.docNumber}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Género</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.gender === 'H' ? 'Hombre' : 'Mujer'}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Fecha de nacimiento</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {moment(insured.birthDate).format('DD/MM/YYYY')}
                              </p>
                            </WebText>
                          </div>
                        </div>
                      </div>

                      <div className="result-hiring-special-row">
                        <div className="result-hiring-special-subitem col-40">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>DOMICILIO</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.address ? insured.address.address.value + ", " + insured.address.number : ""}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>LOCALIDAD</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.address ? insured.address.town.value : ''}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>PROVINCIA</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.address ? insured.address.province.value : ''}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>CÓDIGO POSTAL</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.address ? insured.address.postalCode : ''}
                              </p>
                            </WebText>
                          </div>
                        </div>
                      </div>

                      <div className="result-hiring-special-row">
                        <div className="result-hiring-special-subitem col-40">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Email</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.email}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Teléfono fijo</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.landlinePhone}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Teléfono móvil</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.mobilePhone}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="col-20"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )
              )}
          </div>

          <div className="data-flow-status">
            <div className="data-flow-status-title">

              <h3>{'DATOS DE PAGO'}</h3>

            </div>
          </div>

          <div className="result-simulation-container">
            <div className="result-simulation-item float">
              <div className="result-simulation-special-title">
                <Title03Text>
                  <p>Desglose por forma de pago</p>
                </Title03Text>
              </div>
              <div className="result-simulation-item-content-table">
                <div className="tabs">
                  <GipPaymentResultItem
                    key={0}
                    yearlyPrice={data.pricingData.priceDataSelected.TotalPrimaNetaAnio}
                    paymentType={data.pricingData.priceDataSelected.DesFormaPago}
                    priceData={data.pricingData.priceData}
                    checked={false}
                  />
                </div>
              </div>
            </div>

            <div className="result-simulation-item float">
              <div className="result-simulation-special-title">
                <Title03Text>
                  <p>Miembros de la póliza</p>
                </Title03Text>
              </div>
              <div className="result-simulation-item-content-table">
                <div className="tabs">
                  {
                    this.props.data.insureds.map((insured: any, index: number) => (
                      <GipInsuredResultItem
                        age={calcAge(getDayMonthYear(insured.birthDate).toString())}
                        birthDate={moment(insured.birthDate).format('DD/MM/YYYY')}
                        checked={false}
                        id={this.props.data.insureds.length - index}
                        index={index + 1}
                        name={insured.name}
                        key={index + 1}
                        paymentType={data.pricingData.priceDataInsuredSelected[index].DesFormaPago}
                        totalPrice={data.pricingData.priceDataInsuredSelected[index].TotalPrimaNeta}
                        priceData={data.pricingData.priceDataInsured[index]}
                        onClick={() => 'this.handleRemoveInsured(index)'}

                      />))}
                </div>
              </div>
            </div>
          </div>

          {/* questionnairesData.length > 0 */true &&
            <div className="result-simulation-special">
              <div className="result-simulation-item-content">
                <div className="result-hiring-special-title">
                  <Title03Text>
                    <p>Cuestionarios de salud</p>
                  </Title03Text>
                </div>
                <MainTable
                  className="table"
                  columns={questionnairesColumns}
                  rowLink={"/contratacion/pendiente/detalle"}
                  rows={questionnairesData}
                  keysRow={[
                    "documentNumber",
                    "name",
                    "action",
                  ]}
                />
              </div>
            </div>
          }

          {/* uploadedDocumentsRows.length > 0 */false &&
            <div className="result-simulation-special">
              <div className="result-simulation-item-content">
                <div className="result-hiring-special-title">
                  <Title03Text>
                    <p>Documentos</p>
                  </Title03Text>
                </div>
                <div className="detail-clients-policies-item-content-table">
                  <MainTable
                    className="table topMargin15"
                    columns={documentsColumns}
                    rows={/* uploadedDocumentsRows */[]}
                    keysRow={[/* "catalog", */ "document", "date", "action"]}
                  />
                </div>
              </div>
            </div>
          }

          {/* this.props.data.paymentMethod.isPhysicalSignature */false &&
            <div className="result-simulation-special">
              <div className="result-simulation-item-content">
                <div className="result-hiring-special-title">
                  <Title03Text>
                    <p>Firma física</p>
                  </Title03Text>
                </div>
                <div className="colSin-100 topMargin20">
                  <div className="colSin-50 rightPadding20">
                    <div className="inputBox custom-file-upload">

                      <div className={`colSin-70 ${/* isSignedDocumentRequired */false}`}>
                        <input type="text" readOnly value={/* signedDocument.file ? signedDocument.file.name : "" */''} disabled={false} title="Documento Firmado" />
                      </div>

                      <div className="colSin-30">
                        <label className="custom-file-upload-button topMargin5">
                          <input type="file"
                            accept={acceptedFileMimeType.toString()}
                            onChange={(e) => 'this.onSignedDocumentFileChange(e)'}
                            title="DOCUMENTO FIRMADO"
                          />
                          <MainButton
                            onClick={() => { return true }}
                            text="Buscar"
                          />
                        </label>
                      </div>

                      <label>Adjuntar documento firmado</label>
                    </div>
                    {/* signedDocument.error */true && (
                      <div className="input-box-error-file">
                        <NoteText>
                          <p>{/* signedDocument.error */}</p>
                        </NoteText>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="colSin-100">
            <div className="personal-data-buttons">
              <div className="personal-data-buttons__item">
                <MainButton
                  withTab={true}
                  icon={true}
                  type={"white"}
                  iconImg={images.IconArrowLeftBlue}
                  text="Atrás"
                  onClick={() => this.props.history.push(
                    GipHireMenuHelper.getStepNavigationPath(
                      GipHireMenuHelper.getStepFromNavigationRoute(
                        this.props.match.url,
                        GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
                      ) - 1,
                      this.props.data.insureds.lenght,
                      GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
                    )
                  )}
                />
              </div>
            </div>
          </div>
          <ErrorModalContainer className={`${/* serverError */false && `active`}`}>
            <div className="recover-modal">
              <div className="server-error">
                <p>{/* serverError */}</p>
              </div>
              <div className="button-error">
                <MainButton text="Aceptar" onClick={() => this.setState({ serverError: '' })} />
              </div>

            </div>
          </ErrorModalContainer>
        </ResultDataContainer >
      );
    }
    catch {

      const { history } = this.props;
      history.replace("");

      return "";
    }
  }
}

export default withGeneral(withCoverages(withPolicy(withRouter(ResultDataLayout))));
