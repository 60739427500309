import styled from "styled-components";
import { colors } from "../../assets";

export const DicotomicCheckboxContainer = styled.div`
  display: block;
  color: ${colors["TX-001-80"]};

  .checkbox-container input:not(:checked) {
    color: ${colors["TX-001-50"]};
  }

  outline: none;
  
  &.mandatory {
    .checkbox-container input:checked ~ .checkmark-container {
      background: #f7f7f7;

      .checkmark-item {
        background: ${colors["TX-001-80"]};
      }
    }
  }

  &.optional {
    .checkbox-container input:checked ~ .checkmark-container {
      background: ${colors["SU-001-10"]};

      .checkmark-item {
        background: ${colors["SU-001-100"]};
      }
    }
  }

  &.disabled {
    filter: grayscale(1);
    pointer-events: none;
    p {
      color: ${colors["disabled"]};
    }
  }

  .checkbox-container {
    display: flex;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    div:nth-of-type(2) {
      line-height: 25px;
      font-size: 16px;
    }

    &:hover {
      input ~ .checkmark {
        background-color: rgba(0, 79, 139, 0.3);
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark-container {
        background: ${colors["FL-001-Disabled"]};
        justify-content: flex-end;

        .checkmark-item {
          background: ${colors["PR-001-100"]};
        }

        img {
          max-width: 14px;
        }
      }
    }
  }

  .checkbox-error {
    color: red;
    display: block;
  }

  .checkmark-container {
    min-width: 45px;
    background: #e0e0e0;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 14px;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;

    img {
      max-width: 10px;
    }
  }

  .checkmark-item {
    background: ${colors["TX-001-30"]};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    width: 20px;
    height: 20px;    
  }
`;
