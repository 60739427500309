import styled from "styled-components";

export const LabelTooltipContainer = styled.div`
  position: relative;
  z-index: 9;
  display: inherit;
  top: ${(props) => props.tooltipIconTop || "-72px"};
  left: ${(props) => props.tooltipIconLeft || "190px"};
  height: 0;

  img {
    width: 24px;
    cursor: pointer;
    pointer-events: initial;
  }
`;
