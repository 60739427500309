import styled from "styled-components";

export const CookiesModalStyle = styled.div`
	.cookies-advice {
		position: fixed;
		display: flex;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background: #f2f2f2;
		width: 100%;
		z-index: 9999;
		padding: 40px 80px;
		box-shadow: 0 7px 10px 0 rgba(0, 79, 139, 0.07);
		border-radius: 2px;

		&.none {
			display: none;
		}

		/* .simple-button {
			p {
				padding: 0 20px;
				height: 36px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		} */
	}

	.cookies-advice-close {
		position: absolute;
		cursor: pointer;
		width: 30px;
		height: 24px;
		top: 30px;
		right: 30px;
		img {
			max-width: 30px;
			cursor: pointer;
		}
	}

	.cookies-advice-main {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.cookies-advice-body {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		color: #091e42;
		margin-right: 40px;
		font-family: "museo sans", sans-serif;
		flex: 1;
	}

	.cookies-advice-options {
		display: flex;
		flex-flow: column;
		width: 200px;

		&__item {
			width: 100%;

			&:not(:first-child) {
				margin-top: 16px;
			}
		}
	}

	/* .cookies-advice-options-item {
		&:first-child {
			display: flex;
		}
	} */

	/* .cookies-advice-ok {
		margin-right: 8px;
	}

	.cookies-advice-no {
		margin-right: 16px;
	} */

	.modal-cookies-settings {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 126;
		opacity: 0;
		visibility: hidden;
		overflow-y: hidden;
		display: none;
		background: rgba(17, 17, 17, 0.83);

		&.active {
			overflow-y: scroll;
			display: block;
		}
	}

	.modal-cookies-settings-container {
		position: relative;
		padding: 80px;
		background: #fff;
		width: calc(100% - 160px);
		max-width: 846px;
		z-index: 1;
		margin: 80px auto;
	}

	.modal-cookies-settings-header {
		display: flex;
	}

	.modal-cookies-settings-header-title {
		font-size: 20px;
		color: rgb(114, 114, 114);
	}

	.modal-cookies-settings-close {
		position: absolute;
		cursor: pointer;
		width: 30px;
		height: 24px;
		top: 30px;
		right: 30px;
	}

	.modal-cookies-settings-close-top,
	.modal-cookies-settings-close-bottom {
		width: 20px;
		height: 2px;
		background: #004f8b;
		transition: all 0.3s ease-in-out;
		position: absolute;
	}

	.modal-cookies-settings-close-top {
		top: 10px;
		right: 7px;
		transform: rotate(45deg);
	}

	.modal-cookies-settings-close-bottom {
		top: 10px;
		right: 7px;
		transform: rotate(-45deg);
	}

	.modal-cookies-settings-text {
		color: #111111;
		font-size: 12px;
		margin-bottom: 40px;
		font-size: 16px;
		line-height: 24px;
		font-family: "museo sans", sans-serif;
	}

	.modal-cookies-settings-final-text {
		color: #111111;
		font-size: 12px;
		margin-top: 40px;
		margin-bottom: 32px;
		font-size: 16px;
		line-height: 24px;
		font-family: "museo sans", sans-serif;
	}

	.modal-cookies-settings-options-title {
		color: #2a2a2a;
		margin-bottom: 16px;
		flex: auto;
		font-family: "museo sans", sans-serif;
		font-size: 16px;
		line-height: 24px;
		font-weight: bold;
	}

	.modal-cookies-settings-options {
		display: flex;
		align-items: center;

		&:not(:first-child) {
			margin-top: 24px;
		}
	}

	.modal-cookies-settings-options-item {
		display: flex;
		cursor: pointer;
		flex: 1;
	}

	.modal-cookies-settings-drop-title {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #111111;
		position: relative;
		margin-left: 8px;
		font-family: "museo sans", sans-serif;
		transition: all 0.3s ease-in-out;
	}

	.modal-cookies-settings-options-more-info {
		padding-top: 4px;
	}

	.modal-cookies-settings-options-more-info-icon {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		min-width: 15px;
		min-height: 15px;
		border-radius: 100%;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
	}

	.modal-cookies-settings-options-more-info-text {
		font-size: 12px;
		font-weight: bold;
		color: black;
		padding: 1px;
	}

	.modal-cookies-settings-options-text {
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		color: #111111;
		margin-left: 24px;
		margin-right: 50px;
		opacity: 0;
		visibility: hidden;
		max-height: 0px;
		font-family: "museo sans", sans-serif;
		transition: all 0.3s ease-in-out;

		&.active {
			max-height: 1000px;
		}
	}

	.modal-cookies-settings-options-info {
		flex: 1;
		align-self: flex-start;
		margin-top: 8px;
		user-select: none;
	}

	.modal-cookies-settings-option-buttons {
		display: flex;
		margin-left: 32px;
		border-radius: 3px;
		background: #dbdfe3;
		position: relative;
		align-self: flex-start;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			height: 100%;
			border-radius: 3px;
			transition: all 0.3s ease-in-out;
			background: #ff4100;
		}

		&.active {
			&::before {
				left: 50%;
				background: #40caa9;
			}
		}

		&.required {
			&::before {
				background: #848fa1;
			}
		}
	}

	.modal-cookies-settings-options-reject {
		padding: 12px 18px;
		position: relative;
		color: #004f8b;
		font-family: "museo sans", sans-serif;
		border-radius: 3px;
		text-transform: uppercase;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		width: 110px;
		align-items: center;
		display: flex;
		justify-content: center;

		&.required {
			cursor: initial;
			color: #848fa1;
			pointer-events: none;
		}

		&.active {
			color: #ffffff;
		}
	}

	.modal-cookies-settings-option-button-agree {
		padding: 12px 18px;
		color: #004f8b;
		font-family: "museo sans", sans-serif;
		border-radius: 3px;
		text-transform: uppercase;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		cursor: pointer;
		align-items: center;
		display: flex;
		justify-content: center;
		width: 110px;
		transition: all 0.3s ease-in-out;
		position: relative;

		&.required {
			cursor: initial;
			color: #848fa1;
			pointer-events: none;
		}

		&.active {
			color: white;
		}
	}

	.settings-footer {
		display: flex;
		padding: 10px;
		justify-content: center;
	}

	.modal-cookies-settings-save {
		text-align: center;
		display: flex;
		cursor: pointer;
	}

	.modal-cookies-settings-save {
		font-family: "museo sans", sans-serif;

		& > * {
			flex-basis: calc(50% - 12px);

			&:not(:first-child) {
				margin-left: 24px;
			}
		}

		&__success-button {
			background: #004f8b;
			border-radius: 3px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #ffffff;
			text-transform: uppercase;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			color: #ffffff;
			width: 200px;
			padding: 16px 24px;
			transition: all 0.3s ease-in-out;

			&:hover {
				opacity: 0.8;
			}
		}

		&__reject-button {
			padding: 16px 24px;
			border: 1px solid #004f8b;
			font-size: 14px;
			line-height: 16px;
			text-transform: uppercase;
			border-radius: 3px;
			color: #004f8b;
			font-weight: normal;
			transition: all 0.3s ease-in-out;
			width: 100%;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	.active {
		opacity: 1;
		visibility: visible;
		transition: all 0.3s ease-in-out;
	}

	.modal-cookies-settings-options {
		&.open {
			.modal-cookies-settings-drop-title {
				color: #004f8b;
			}

			.modal-cookies-settings-options-text {
				padding-top: 8px;
			}

			.modal-cookies-settings-options-more-info-icon {
				transform: rotate(180deg);
			}
		}
	}

	@media (max-width: 1024px) {
		.box-modal-cookies {
			padding: 40px 48px;
		}

		.modal-cookies-pop-buttons {
			flex-direction: column;
		}

		.modal-cookies-pop-buttons-secondary {
			margin-bottom: 20px;
		}
	}

	@media (max-width: 768px) {
		.modal-cookies-settings-close {
			top: 18px;
			right: 18px;
		}

		.box-modal-cookies {
			padding: 24px;
			flex-flow: column;
		}

		.box-modal-cookies-buttons {
			margin-left: 0;
			margin-top: 24px;

			&__item {
				width: 100%;
			}
		}

		.modal-cookies-settings-container {
			width: auto;
			max-width: unset;
			margin: 40px 20px;
			padding: 56px 24px;
		}

		.modal-cookies-pop-container {
			flex-direction: column;
		}

		.modal-cookies-pop-buttons {
			flex-direction: row;
		}

		.modal-cookies-pop-buttons-secondary {
			margin-bottom: 0px;
			margin-left: 0;
		}

		.modal-cookies-pop-text {
			margin-bottom: 20px;
		}

		.modal-cookies-settings-options {
			flex-direction: column;
		}

		.modal-cookies-settings-options-info {
			margin-top: 0;
			margin-bottom: 12px;
		}

		.modal-cookies-settings-option-buttons {
			margin: 0 auto;
		}

		.modal-cookies-settings-save {
			flex-flow: wrap;

			& > * {
				flex-basis: 100%;

				&:not(:first-child) {
					margin-left: 0;
				}
			}

			&__success-button {
				order: -1;
				margin-bottom: 16px;
			}
		}
	}
`;
