import { ComparisonCardData } from "./comparator.types";

export const getComparisonCardData = (
  response: any[], payType: PayType
): ComparisonCardData[] => {
  const cardData =
    response.length > 0
      ? response.map(({ body }) => {
          const { RESPUESTA, productDescription, productName, productType, productId } = body;
          const { FACTOR_COMERCIAL, RECIBOS } = RESPUESTA;
          return {
            insuranceName: productName ? productName : '',
            productDescription: productDescription ? productDescription : '',
            desc: FACTOR_COMERCIAL && FACTOR_COMERCIAL.DESCUENTO_PORCENTAJE ? FACTOR_COMERCIAL.DESCUENTO_PORCENTAJE : '',
            price: RECIBOS && payType ? getPriceBasedOnPaymentMethod(RECIBOS, payType).price : '',
            payType: RECIBOS && payType ? getPriceBasedOnPaymentMethod(RECIBOS, payType).payType : '',
            originalPayType: payType ? payType : 'MENS',
            productType: productType ? productType : '',
            productId 
          };
        })
      : [
        {
          insuranceName: "",
          productDescription: "",
          desc: "",
          price: "",
          payType: "",
          originalPayType: "MENS" as PayType,
          productType: "",
          productId: null,
        },
      ];

  return cardData;
};

export const getPriceBasedOnPaymentMethod = ( paymentAmounts: ImportesForPago[], payTypeSelected: PayType): { price: string; payType: string } => {
    const result = paymentAmounts.find((amount) => {
      return amount.PERIODO_COBRO === payTypeSelected;
    });
    const price = result && result.PRIMATOTAL ? result.PRIMATOTAL : '0';
  return { price, payType: PAY_TYPES[payTypeSelected] };
};

export type PayType = "MENS" | "BIME" | "TRIM" | "SEME" | "ANUA";

const PAY_TYPES = {
  MENS: "mes",
  BIME: "bimestre",
  TRIM: "trimestre",
  SEME: "semestre",
  ANUA: "año",
};

export const getPaymentTime = (value: string): string => {
  switch (value) {
    case 'MENS':
      return 'Mensual';
    case 'BIME':
      return 'Bimestral';
    case 'TRIM':
      return 'Trimestral';
    case 'SEME':
      return 'Semestral';
    case 'ANUA':
      return 'Anual';
    default:
      return '';
  }
}

type ImportesForPago = {
    PERIODO_COBRO: PayType,
    PRIMANETA: string,
    PRIMATOTAL: string,
    PRIMAPURA: string,
    GASTOSINT: string,
    GASTOSEXT: string,
    GASTOSEMI: string,
    DEDUCCIONES: string,
    CONSORCIO: string,
    CAMPANYAS: string,
    DESCUENTOS: string,
    DGS: string,
    IPS: string,
    RECARGOFRA: string,
    INEM: string,
    ASF: string,
    TOTAL_IMPUESTOS: string
};