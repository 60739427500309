import moment from "moment";
import { ICampanyas, IHealthCoverage } from "../../../constants/health";
import SimulationServices from "../../../services/SimulationServices";
import { DentalInsuredSimulationInterface } from "./dental-form-insured/dental-insured-data-layout";
import { DentalpriceTypeModel, selectedCampaign, SelectedPriceInterface } from "./dental-form-price/dental-price-data-layout";
import PayTypesServices from "../../../services/PayTypesServices";

/**
 * 
 * @param setLoading 
 * @param data 
 * @param selectedCampaigns 
 * @param selectedPrice 
 * @returns selectable prices and whole prices response
 */
export async function getPrices(setLoading: Function, data: any, selectedCampaigns: selectedCampaign[], selectedPrice: SelectedPriceInterface, allPayTypes: any[], product: any) {

    let selectablePrices: { [id: string]: DentalpriceTypeModel } = {};

    const formattedData = formatData(data, selectedCampaigns, selectedPrice)

    const resPrices = await SimulationServices.dental({
        loader: true,
        setLoading: setLoading,
        data: formattedData
    }, product.products_internalName).catch((error) => {
        throw error;
    });

    if (resPrices && resPrices.body && resPrices.body.RESPUESTA && resPrices.body.RESPUESTA.IMPORTESFORPAGO) {
        const prices = resPrices.body.RESPUESTA.IMPORTESFORPAGO;

        prices.forEach((price: any) => {
            selectablePrices[PayTypesServices.getPayTypeByCode(allPayTypes, price.PERIODO_COBRO).description] = price;
        });

    }

    return [selectablePrices, resPrices.body];
}

function formatData(data: any, selectedCampaigns: selectedCampaign[], selectedPrice: SelectedPriceInterface) {
    let obj: any = {};

    obj.insureds = [];
    obj.coverages = [];

    data.insuredData && data.insuredData.forEach((insured: DentalInsuredSimulationInterface, index: number) => {

        const newInsured: any = {
            birthdate: moment.utc(insured.birthDate, 'DD/MM/YYYY').toISOString(),
            riskNumber: String(Number(index) + 1),
            relationship: insured.parentesco,
            hasAnotherInsurance: insured.hasAnotherInsurance
        }

        data.insuredData[index].coverages
            .filter((cov: IHealthCoverage) => (cov.required || cov.checked))
            .forEach((coverage: IHealthCoverage) => {
                const capital: number = +(coverage.capitalValue ? coverage.capitalValue : "1");

                const newCoverage: any = {
                    code: coverage.code,
                    riskNumber: String(Number(index) + 1),
                    capital: String(capital)
                };
                obj.coverages.push(newCoverage)
            })

        obj.insureds.push(newInsured)
    });
    const campanyas: ICampanyas[] = selectedCampaigns.map(campanya => ({ CAMPANYA: campanya.code }));
    obj.campanyas = campanyas;

    obj.payType = selectedPrice.payType;

    obj.mediator = data.mediatorData && data.mediatorData.code
    obj.startDate = data.mediatorData && moment.utc(data.mediatorData.startDate, 'DD/MM/YYYY').toISOString();
    return obj;
}