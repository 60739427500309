import styled from "styled-components";
import { colors } from "../../assets";
import { BodyTextContainer } from "../../components/components-text/body-text/body-text-styles";

export const HealthCampaingResultContainer = styled.div`
.priceModel {
    margin-top: 15px
}

.selectBox {
    height: auto;
    margin-bottom: 20px;
}
.result-simulation-special-row {
    width: 100%;
    display: flex;
    float: left;
    margin-bottom: 20px;
}
.col-25 {
    width: 25%;
}
.col-2 {
    width: 20%;
}
.col-30 {
    width: 30%;
}
.col-40 {
    width: 40%;
    text-wrap: balance;
}
.col-50 {
    width: 50%;
}
.col-60 {
    width: 60%;
}
.col-70 {
    width: 70%;
}
.detail-amount {
    text-align: right;
}
.tab-label-title {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background-color: #004a8f;
    color: #fff;
    border: 1px solid #f4f4f4;
    font-size: 18px;
    height: 60px;

}
.tab-content-table {
    display: flex;
    justify-content: space-between;
    border: 1px solid #f4f4f4;
}
.table-campaign {
    width: 96%;
    thead {
        border-bottom: 1px solid ${colors["PR-001-100"]};
    }    
    th {
        padding: 10px 15px;
        color: ${colors["FL-001-Default"]};
        .highlighted { color: ${colors["TX-001-50"]};}
        background-color: ${colors["PR-001-100"]};

        p {
            color: ${colors["FL-001-Default"]};
        }
    }
    td {
        > div {
            padding: 8px 0;
            font-size: 16px;
        }
    
        ${BodyTextContainer} {
            display: inline-block;
            &.highlighted { font-weight: 600;}
        }
    }
}
.float-right-cell {
    float: right;
}
.amount {
    text-align: right;
}
`;
