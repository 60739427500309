import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../assets";
import { ErrorModalContainer } from "../../../../components/error-modal/error-modal-style";
import GuideLine from "../../../../components/guide-line";
import HealthCampaingItem from "../../../../components/health-campaign-item/health-campaign-item";
import HealthPriceItem from "../../../../components/health-price-item/health-price-item";
import MainButton from "../../../../components/main-button";
import { ICampanyas, IHealthCoverage, STORAGE_KEY } from "../../../../constants/health";
import { withPolicy } from "../../../../context/mixin/with-policy";
import CampaignsServices from "../../../../services/CampaignsServices";
import SimulationServices from "../../../../services/SimulationServices";
import { campaign } from "../../../hirings/new-hiring/health/health-price/health-price-dto/campaign-dto";
import { PriceDataContainer } from "./price-data-style"
import PayTypesServices from "../../../../services/PayTypesServices";
import { withPayTypes } from "../../../../context/mixin/with-pay-types";
import { DEFAULT_PAY_METHOD } from "../../../../constants/payTypes";
import { AllPayType, ProductPayType } from "../../../../services/PayTypesServices.dto";

interface PriceProps extends RouteComponentProps {
  setLoading: Function;
  policy: any;
  user: any;
  allPayTypes: AllPayType[];
  productPayTypes: ProductPayType[];
}

interface priceTypeModel {
  payType: string;
  price: string;
  discount: string;
  taxes: string;
  total: string;
}

interface selectedCampaign {
  code: string,
  name: string
}

interface PriceState {
  selectablePrices: { [id: string]: any };
  selectedPrice: {
    discount: string;
    paymentTime: string;
    payType: string,
    price: string;
    taxes: string;
    total: string;
  };
  selectedCampaigns: selectedCampaign[];
  selectableCampaigns: campaign[];
  healthSimulationData: any;
  modalErrorShow: boolean;
  modalErrorText: string;
  pricesError: boolean;
}

class PriceDataLayout extends React.Component<PriceProps, PriceState> {

  constructor(props: PriceProps) {
    super(props);

    var healthSimulationData;
    if (localStorage.getItem(STORAGE_KEY.sKey)) {
      healthSimulationData = JSON.parse(String(localStorage.getItem(STORAGE_KEY.sKey)));
    }

    this.state = {
      selectablePrices: {},
      selectedPrice: {
        paymentTime: healthSimulationData.priceData ? healthSimulationData.priceData.price.paymentTime : DEFAULT_PAY_METHOD.description,
        payType: healthSimulationData.priceData ? healthSimulationData.priceData.price.payType : DEFAULT_PAY_METHOD.code,
        price: '',
        discount: '',
        taxes: '',
        total: ''
      },
      selectedCampaigns: healthSimulationData.priceData ? healthSimulationData.priceData.campaigns : [],
      selectableCampaigns: [],
      healthSimulationData: healthSimulationData,
      modalErrorShow: false,
      modalErrorText: '',
      pricesError: true,
    };
  }

  formatData(data: any, simulation?: boolean) {
    const { selectedPrice, selectedCampaigns } = this.state;
    const { policy } = this.props;

    let obj: any = {};

    const keys = Object.keys(data.insuredData)
    obj.insureds = [];

    keys.forEach((index) => {

      const newInsured: any = {
        birthdate: moment.utc(data.insuredData[index].birthDate, 'DD/MM/YYYY').toISOString(),
        coverages: [],
        address: {
          postalCode: data.insuredData[index].address.postalCode
        },
        sex: data.insuredData[index].gender,
        profesion: data.insuredData[index].profession ? data.insuredData[index].profession.code : ''
      }
      if (data.insuredData[index].nationality) {
        newInsured.nationality = data.insuredData[index].nationality;
      }

      data.insuredData[index].coverages
        .filter((cov: IHealthCoverage) => (cov.required || cov.checked))
        .map((coverage: IHealthCoverage) => {
          const capital: number = +(coverage.capitalValue ? coverage.capitalValue : 1);

          const newCoverage: any = {
            code: coverage.code,
            type: coverage.code,
            capital: capital
          };
          return newInsured.coverages.push(newCoverage)

        })
      obj.insureds.push(newInsured)
    });
    const campanyas: ICampanyas[] = selectedCampaigns.map(campanya => ({ CAMPANYA: campanya.code }));
    obj.campanyas = campanyas;

    obj.productType = policy.product.products_internalName
    obj.mediator = data.mediatorData.code
    obj.startDate = moment.utc(data.mediatorData.startDate, 'DD/MM/YYYY').toISOString();
    obj = {
      ...obj,
      ...(data.mediatorData.endDate && { endDate: moment.utc(data.mediatorData.endDate, "DD/MM/YYYY").toISOString() }),
      ...(data.taker.docsLanguage && { docsLanguage: data.taker.docsLanguage }),
      ...(data.mediatorData.payment && { payment: { payType: data.mediatorData.payment }}),
    }

    if (simulation) {
      obj.payment = {
        payType: selectedPrice.payType
      }
    }
    
    return obj;
  }

  async getPrices() {
    const { setLoading, productPayTypes } = this.props;
    const { selectedPrice, healthSimulationData } = this.state;

    let selectablePrices: { [id: string]: priceTypeModel } = {};

    const data = this.formatData(healthSimulationData)
    const resPrices = await (SimulationServices)['health']({
      loader: true,
      setLoading: setLoading,
      data: data
    }, data.productType)
      .catch((error) => {
        this.setState({
          modalErrorShow: true,
          modalErrorText: error
        });
        throw error;
      });

    if (resPrices && resPrices.body && resPrices.body.RESPUESTA && resPrices.body.RESPUESTA.IMPORTESFORPAGO) {
      const prices = resPrices.body.RESPUESTA.IMPORTESFORPAGO;
      const selected = prices.find((price: any) => price.PERIODO_COBRO === selectedPrice.payType);
      prices.forEach((price: any) => {
        selectablePrices[PayTypesServices.getPayTypeByCode(productPayTypes, price.PERIODO_COBRO).description] = price;
      });

      this.setState({
        selectedPrice: {
          paymentTime: selectedPrice.paymentTime,
          payType: selected ? selected.PERIODO_COBRO : '',
          price: selected ? selected.PRIMANETA : '',
          discount: selected ? selected.DESCUENTOS : '',
          taxes: selected ? selected.TOTAL_IMPUESTOS : '',
          total: selected ? selected.PRIMATOTAL : ''
        },
        pricesError: false
      });
    }

    return selectablePrices;
  }

  getCampaigns = async () => {
    const { setLoading, policy } = this.props;
    if (policy && policy.product && policy.product.products_colectivo && policy.product.products_combinacion_comercial) {
      let allCampaigns: campaign[] = await CampaignsServices.getCampaigns({
        loader: true,
        setLoading: setLoading,
        colective: policy.product.products_colectivo,
        combinacion_comercial: policy.product.products_combinacion_comercial,
        fecha_inicio: this.checkPolicyStartDate(this.state.healthSimulationData)
      }).catch((error) => {
        this.setState({
          modalErrorShow: true,
          modalErrorText: 'Se ha producido un error. Vuelva a intentarlo más tarde.'
        })
        throw error.statusText
      });

      return allCampaigns;
    }
    else {
      this.setState({
        modalErrorShow: true,
        modalErrorText: 'Se ha producido un error. Vuelva a intentarlo más tarde.'
      })
      return []
    }
  }

  checkPolicyStartDate (data: any): string {
    if (data && data.mediatorData && data.mediatorData.startDate) {
      return data.mediatorData.startDate;
    }
    return '';
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    this.loadData();
  };

  async componentDidUpdate(prevProps: any) {
    let { policy } = this.props;

    if (Object.keys(prevProps.policy.product).length === 0 && Object.keys(policy.product).length !== 0) {
      const prices = await this.getPrices();
      this.setState({ selectablePrices: prices });
    }
  }

  loadData = async () => {
    const { policy } = this.props
    const campaigns = await this.getCampaigns();

    let selectedCampaigns = campaigns.filter(campaign => campaign.isRequired)
    this.setState({ selectableCampaigns: campaigns, selectedCampaigns }, async () => {
      if (Object.keys(policy.product).length !== 0) {
        const prices = await this.getPrices();
        this.setState({ selectablePrices: prices });
        if (Object.keys(prices).length === 1) {
          const priceName = Object.keys(prices)[0];
          const price = prices[priceName] as { [id: string]: any };
          this.handlePriceRadioChange(
            priceName,
            price.PERIODO_COBRO,
            price.PRIMATOTAL,
            price.DESCUENTOS,
            price.TOTAL_IMPUESTOS,
            price.PRIMATOTAL,
          );
        }
      }
    })
  }

  toValidate = async () => {
    const { history, setLoading } = this.props;
    const { healthSimulationData } = this.state;
    const data = this.formatData(healthSimulationData, true);

    let anyError: boolean = false;
    try {
      const response = await (SimulationServices)['health']({
        loader: true,
        setLoading: setLoading,
        data: data
      }, data.productType);
      healthSimulationData.responseSimulation = response;
    } catch (error) {
      this.setState({
        modalErrorShow: true,
        modalErrorText: error as string,
      });
      anyError = true;
    }

    if (anyError) return;
    else {
      const state = {
        mediatorData: healthSimulationData.mediatorData,
        insuredData: healthSimulationData.insuredData,
        priceData: {
          price: this.state.selectedPrice,
          campaigns: this.state.selectedCampaigns
        }
      }

      if (healthSimulationData) {
        healthSimulationData.priceData = state.priceData;
        healthSimulationData.payments = this.state.selectablePrices;
        localStorage.setItem(STORAGE_KEY.sKey, JSON.stringify(healthSimulationData));
      }
      else {
        localStorage.setItem(STORAGE_KEY.sKey, JSON.stringify({ insuredData: state.priceData }));
      }

      history.replace({ state: state });
      history.push("/simulacion/nueva/salud/resultado");
    }
  }

  hideErrorModal = () => this.setState({ modalErrorShow: false, modalErrorText: '' });

  handlePriceRadioChange(time: string, payType: string, price: string, discount: string, taxes: string, total: string) {
    const value = {
      paymentTime: time,
      payType,
      price,
      discount,
      taxes,
      total
    }
    this.setState({ selectedPrice: value });
  }

  async handleSelectedCampaignsChange(code: string, name: string, selected: boolean) {

    let { selectedCampaigns } = this.state;
    if (selected) {
      if (selectedCampaigns.some(campaign => campaign['code'] === code)) {
        const index = selectedCampaigns.findIndex(campaign => campaign.code === code);
        selectedCampaigns.splice(index, 1);
      }
    }
    else {
      if (!selectedCampaigns.some(campaign => campaign['code'] === code))
        selectedCampaigns.push({ code, name });
    }
    this.setState({ selectedCampaigns });
    const prices = await this.getPrices();
    this.setState({ selectablePrices: prices })
  }

  renderPriceLayoutTitle = () => {
    const { selectablePrices } = this.state;
    if (selectablePrices['Único']) {
      return "CÁLCULO DE PRIMA SEGÚN FORMA DE PAGO ÚNICO";
    }
    return "CÁLCULO DE PRIMA ANUAL SEGÚN FORMA DE PAGO"
  }

  render() {

    const { selectedPrice, selectablePrices, selectedCampaigns, selectableCampaigns, pricesError } = this.state;

    return (
      <PriceDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">

            <h2>{'DATOS DEL SEGURO'}</h2>

          </div>
          <div className="data-flow-status-guide">
            <GuideLine
              steps={[0, 0, 0, 0]}
              currentStep={3}
            />
          </div>
        </div>

        <div className="content-wrapper bottomMargin20" ref={''}>
          <div className="personal-data-wrapper">
            <div className="data-title">
              <h5>{this.renderPriceLayoutTitle()}</h5>
            </div>
          </div>
          <div className="colSin-100">

            {Object.keys(selectablePrices).map((key, index) => (
              <HealthPriceItem
                key={key}
                paymentTime={key}
                price={selectablePrices[key].PRIMATOTAL}
                defaultChecked={key.toLocaleUpperCase() === selectedPrice.paymentTime.toLocaleUpperCase()}
                discount={selectablePrices[key].DESCUENTOS}
                onClick={
                  async (
                    time: string,
                    price: string,
                    discount: string
                  ) => {
                    this.handlePriceRadioChange(
                      time,
                      selectablePrices[key].PERIODO_COBRO,
                      price,
                      discount,
                      selectablePrices[key].TOTAL_IMPUESTOS,
                      selectablePrices[key].PRIMATOTAL)
                  }
                } />
            ))}

          </div>
        </div>

        <div className="content-wrapper bottomMargin20">

          {selectableCampaigns.map((b, index) => (
            <HealthCampaingItem
              campaignName={b.name}
              campaignDescription={b.description}
              campaignCode={b.code}
              defaultChecked={selectedCampaigns.some(campaign => campaign['code'] === b.code)}
              disabled={b.isRequired}
              onClick={(campaignCode: string, campaignName: string, selected: boolean) => this.handleSelectedCampaignsChange(campaignCode, campaignName, selected)}
            />)
          )}

        </div>

        <div className="colSin-100">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push("/simulacion/nueva/salud/asegurados")}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                text="Siguiente"
                disabled={pricesError}
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>

        <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={this.hideErrorModal} />
            </div>
          </div>
        </ErrorModalContainer>

      </PriceDataContainer>
    );
  }
}

export default withPayTypes(withPolicy(withRouter(PriceDataLayout)));
