import styled from "styled-components";
import { colors } from "../../assets";
import { BodyTextContainer } from "../../components/components-text/body-text/body-text-styles";

export const GipInsuredContainer = styled.div`
.selectBox {
    height: auto;
    margin-bottom: 20px;
}

.col-10 {
    width: 10%;
}
.col-15 {
    width: 15%;
}
.col-20 {
    width: 20%;
}
.col-25 {
    width: 25%;
}
.col-2 {
    width: 20%;
}
.col-30 {
    width: 30%;
}
.col-35 {
    width: 35%;
}
.col-40 {
    width: 40%;
}
.col-50 {
    width: 50%;
}
.col-60 {
    width: 60%;
}
.col-70 {
    width: 70%;
}
.col-80 {
    width: 80%;
}
.result-simulation-special-row {
    width: 100%;
    display: flex;
    float: left;
    margin-bottom: 20px;
}
.tab-label-title {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background-color: #004a8f;
    color: #fff;
    border: 1px solid #f4f4f4;
    font-size: 18px;
    height: 60px;

}
.tab-content-table {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #fff;
    color: #3f3f3f;
    border: 1px solid #f4f4f4;
    font-size: 18px;
    height: 60px;
}
.table-campaign {
    width: 96%;
    thead {
        border-bottom: 1px solid ${colors["PR-001-100"]};
    }    
    th {
        padding: 10px 15px;
        color: ${colors["FL-001-Default"]};
        .highlighted { color: ${colors["TX-001-50"]};}
        background-color: ${colors["PR-001-100"]};

        p {
            color: ${colors["FL-001-Default"]};
        }
    }
    td {
        > div {
            padding: 8px 0;
            font-size: 16px;
        }
    
        ${BodyTextContainer} {
            display: inline-block;
            &.highlighted { font-weight: 600;}
        }
    }
}
.float-right-cell {
    float: right;
}
.amount {
    text-align: right;
}
// .selected-price {
//     background-clip: padding-box;
//     background-color: #6CC9F0;
//     font-weight: bold;
// }

`;

