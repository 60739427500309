import React, { FC, useEffect, useState } from "react";
import { GuaranteeDataContainer } from "../../../../hirings/new-hiring/health/health- guarantee/guarantee-data-style";
import { UseFormFunctionsProps } from "../../hooks/hooks.types";
import { getDayMonthYear } from "../../../../../utils/time";
import { calcAge, deepGet } from "../../../../../utils/utility";
import HealthService from "../../../../../services/HealthService";
import { capitalizeFirstLetter } from "../../../../../utils";
import {
  formatCapitalText,
  isOutOfCoverageAgeRange,
} from "../../../../../utils/coverages";
import { images } from "../../../../../assets";
import { Coverage } from "../../../comparator/health-comparator/comparator/comparator.types";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import MainButton from "../../../../../components/main-button";
import SelectBox from "../../../../../components/select-box/select-box";
import { HEALTH_BENEFICIARIES_TYPES } from "../../../../../constants/health";
import { Beneficiaries, ItemType, RemoveProps } from "./policy-warranty-step.types";
import MainTable from "../../../../../components/main-table";
import AddBeneficiariesModal from "../../../../../components/add-beneficiaries-modal/add-beneficiaries-modal";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { emptyRemoveProps } from "../utils";
import { getCovConfig, keyRows, tableColumns } from "./utils";

const PolicyWarrantyStep: FC<UseFormFunctionsProps> = ({ values, errors, handleChange, hideTitle = false}) => {
  const [coverages, setCoverages] = useState<Coverage[]>([]);
  const [getCoverageFail, setGetCoverageFail] = useState({});
  const [covConfig, setCovConfig] = useState<ItemType[]>([]);
  const [activeBeneficiairesModal, setActiveBeneficiairesModal] = useState({ status: false, code: "", });
  const [confirmationModal, setConfirmationModal] = useState<{status: boolean, data: RemoveProps}>({ status: false, data: emptyRemoveProps});
  const [beneficiary, setBeneficiary] = useState({});
  const age = calcAge(getDayMonthYear(values.taker.birthDate).toString());

  useEffect(() => {
    getCoverages();
    getCovConfig(setCovConfig);
  }, []);

  const formatCoveragesHealth = (coveragesData: any) => {
    const coveragesObj: any = [];

    coveragesData.forEach((coverage: any) => {
      let desc = "";
      if (coverage.name)
        desc = capitalizeFirstLetter(coverage.name.toLowerCase());

      let coverageObj: any = {
        code: coverage.code,
        description: desc,
        required: coverage.required ? coverage.required : false,
        edadMin: coverage.edadMin ? coverage.edadMin : "",
        edadMax: coverage.edadMax ? coverage.edadMax : "",
        capital: coverage.capitales ? "" : 1,
        capitales: coverage.capitales ? coverage.capitales : [],
      };

      if (coverage.capitales) {
        coverageObj.capitalRequired = true;
        coverageObj.errorCode = "";
      }

      coveragesObj.push(coverageObj);
    });

    return coveragesObj;
  };

  const getCoverages = async () => {
    try {
      const data = await HealthService.getCoveragesFromDB(() => {}, true, "health" );
      if (data !== undefined) {
        const coverages2 = formatCoveragesHealth(data);
        setCoverages(coverages2);
        if (Object.keys(values.guarantees).length === 0) {
          coverages2.forEach((coverage: Coverage) => {
            handleChange(
              coverage.code,
              {
                label: coverage.description,
                value: coverage.required,
                capitalToInsure: "",
              },
              "guarantees"
            );
          });
        }
      } else {
        setCoverages([]);
      }
    } catch (error) {
      setGetCoverageFail({
        serverError: true,
        buttonDisable: true,
        modalErrorText: `Error al obtener las coberturas`,
      });
      setCoverages([]);
    }
  };

  const getRows = (beneficiaries: Beneficiaries[], code: string) =>
    beneficiaries.map((beneficiarie, index) => ({
      beneficiaries: {
        text: `${beneficiarie.name.value} ${beneficiarie.lastName1.value} ${beneficiarie.lastName2.value}`,
        type: "",
        key: "body",
      },
      nif: {
        text: beneficiarie.docNumber.value,
        type: "",
        key: "body",
      },
      percentage: {
        text: beneficiarie.percentage.value + " %",
        type: "",
        key: "body",
      },
      buttons: {
        key: "buttons",
        items: [
          {
            img: images.IconEditBlue,
            onClick: () => {
              const updatedBeneficiaries = [...beneficiaries];
              updatedBeneficiaries.splice(index, 1);
              handleChange(code, {...deepGet(values, `guarantees.${code}`, {}), beneficiaries: updatedBeneficiaries}, 'guarantees')
              setBeneficiary(beneficiarie)
              setActiveBeneficiairesModal({ status: true, code })
            },
          },
          {
            img: images.IconEraseRed,
            onClick: () => setConfirmationModal({ status: true, data: { beneficiaries, index, code }}),
          },
        ],
      },
    }));

  const removeBeneficiary = (data: RemoveProps) => {
    if (data.beneficiaries ) {
      const {beneficiaries, index, code} = data
      const updatedBeneficiaries = [...beneficiaries];
      updatedBeneficiaries.splice(index, 1);
  
      handleChange(code, {...deepGet(values, `guarantees.${code}`, {}), beneficiaries: updatedBeneficiaries}, 'guarantees')
    }
  };

  const renderCoverages = (coverages: Coverage[]) => {
    return coverages.map((coverage: Coverage, item: number) => (
      <div className="rightPadding10" key={item}>
        <label
          className={`checkbox-container ${
            coverage.required || isOutOfCoverageAgeRange(age, coverage.edadMin, coverage.edadMax)
              ? " noPointer"
              : ""
          }`}
        >
          <input
            type="checkbox"
            checked={ deepGet(values, `guarantees.${coverage.code}.value`, false) || false }
            onChange={(e) => {
              if (!coverage.required) {
                handleChange( coverage.code, { label: coverage.description, value: e.target.checked }, "guarantees" );
              }
            }}
          />
          <div
            className={`checkmark-container${
              coverage.required || isOutOfCoverageAgeRange(age, coverage.edadMin, coverage.edadMax)
                ? "-readonly"
                : ""
            }`}
          >
            <div className="checkmark-item">
              <img
                src={
                  coverage.required || coverages.some((e) => e.code === coverage.code)
                    ? images.IconTickWhite
                    : images.IconCloseWhite
                }
                alt=""
              />
            </div>
          </div>
          <div>{coverage.description}</div>
        </label>
        {coverage.capitalRequired &&
          deepGet(values, `guarantees.${coverage.code}.value`, false) && (
            <div>
              <div className="inputBox leftMargin30" />
              <div className="selectBox">
                <SelectBox
                  required={true}
                  errorCode={deepGet(errors, `guarantees.${coverage.code}.capitalToInsure`)}
                  optionsText={formatCapitalText(coverage.capitales)}
                  optionKey={"value"}
                  optionValue={"label"}
                  defaultValue={deepGet( values, `guarantees.${coverage.code}.capitalToInsure`, "" )}
                  onChange={(value: string) =>
                    handleChange(
                      coverage.code,
                      {
                        label: coverage.description,
                        value: deepGet( values, `guarantees.${coverage.code}.value` ),
                        capitalToInsure: value,
                        beneficiariesType: deepGet( values, `guarantees.${coverage.code}.beneficiariesType` ),
                        beneficiaries: deepGet( values, `guarantees.${coverage.code}.beneficiaries` )
                      },
                      "guarantees"
                    )
                  }
                  health
                />
                <label>{"Capital a asegurar"}</label>
              </div>
              <div className="leftMargin30 colSin-80">
                {deepGet(
                  covConfig.find((e) => e.code === coverage.code),
                  "config.reqBeneficiaries",
                  false
                ) && (
                  <div>
                    <div className="contact-radio-main-row">
                      <div className="radioBox">
                        <label>
                          <input
                            type="radio"
                            name={'beneficiariesType'}
                            value={HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS}
                            onChange={() =>
                              handleChange(
                                coverage.code,
                                { ...deepGet( values, `guarantees.${coverage.code}`, {} ),
                                  beneficiariesType: HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS,
                                },
                                "guarantees"
                              )
                            }
                            checked={
                              deepGet( values, `guarantees.${coverage.code}.beneficiariesType`, "" ) 
                              === HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS
                            }
                          />
                          <span>HEREDEROS LEGALES</span>
                        </label>
                      </div>
                      <div className="radioBox">
                        <label>
                          <input
                            type="radio"
                            name={'beneficiariesType'}
                            value={  HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES }
                            onChange={() =>
                              handleChange(
                                coverage.code,
                                {...deepGet(values, `guarantees.${coverage.code}`, {}),
                                  beneficiariesType: HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES,
                                },
                                "guarantees"
                              )
                            }
                            checked={
                              deepGet(values,`guarantees.${coverage.code}.beneficiariesType`, "") ===
                              HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES
                            }
                          />
                          <span>BENEFICIARIOS DESIGNADOS</span>
                        </label>
                      </div>
                    </div>

                    {deepGet( values, `guarantees.${coverage.code}.beneficiariesType`, "" ) 
                      === HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES &&
                      (deepGet( values, `guarantees.${coverage.code}.beneficiaries.length`,  0 ) || 0) > 0 && (
                        <MainTable
                          columns={tableColumns}
                          rows={getRows( deepGet( values, `guarantees.${coverage.code}.beneficiaries`, [] ) || [], coverage.code )}
                          keysRow={keyRows}
                        />
                      )}
                  </div>
                )}
              </div>
            </div>
          )}

        {deepGet( values, `guarantees.${coverage.code}.beneficiariesType`, "" ) 
        === HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES && (
          <>
          <MainButton
            text="Añadir beneficiarios"
            type="full-light"
            onClick={() =>
              setActiveBeneficiairesModal({ status: true, code: coverage.code })
            }
          />
          {deepGet(errors, `guarantees.${coverage.code}.beneficiaries`) === 'required' && (
            <div className="error-msg"><span>* Añadir beneficiarios es obigatorio.</span></div>
          )}
          </>
        )}
      </div>
    ));
  };

  return (
    <>
      <GuaranteeDataContainer>
        <div className="colSin-100">
          {!hideTitle && <div className="data-flow-status">
            <div className="data-flow-status-title">
              <h2>GARANTIAS DE LA PÓLIZA</h2>
            </div>
          </div>}
          <div className="content-wrapper">
            <div className="tabs">
              <div className="tab">
                <input type="checkbox" defaultChecked />
                <label className="tab-label">
                  <div>
                    <span>ASEGURADO</span>
                    <span>FECHA NACIMIENTO</span>
                    <span>EDAD</span>
                    <span>Nº DOCUMENTO</span>
                  </div>
                  <div>
                    <span>{`${values.taker.name} ${values.taker.lastname1} ${values.taker.lastname2}`}</span>
                    <span>{getDayMonthYear(values.taker.birthDate)}</span>
                    <span>{age}</span>
                    <span>{values.taker.docNumber}</span>
                  </div>
                </label>
                <div className="tab-content">
                  {/* GARANTIAS OBLIGATORIAS */}
                  <div className="colSin-40">
                    {coverages &&
                      renderCoverages(
                        coverages
                          .filter((coverage: { required: boolean }) => {
                            return coverage.required;
                          })
                          .sort((a, b) => (a.code < b.code ? 1 : -1))
                      )}
                  </div>
                  {/* GARANTIAS OPCIONALES */}
                  {
                    !deepGet(values, 'policyData.isOcasoPolicy', false) && (
                    <div className="colSin-60">
                    {coverages &&
                      renderCoverages( coverages.filter((coverage) => !coverage.required)
                      )}
                  </div>
                    )
}
                </div>
              </div>
            </div>
          </div>
        </div>
      </GuaranteeDataContainer>

      <AddBeneficiariesModal
        active={activeBeneficiairesModal.status}
        beneficiaries={ deepGet(  values, `guarantees.${activeBeneficiairesModal.code}.beneficiaries` ) || [] }
        beneficiary={beneficiary}
        buttonText="Terminar"
        close={() => setActiveBeneficiairesModal({ status: false, code: "" })}
        img={images.IconUserBlue}
        insuredDocNumber={deepGet(values, "taker.docNumber", "")}
        mainTitle="Añadir beneficiarios"
        mainText=""
        onClickAccept={(beneficiaries: any) => {
          setActiveBeneficiairesModal({ status: false, code: "" });
          handleChange(
            activeBeneficiairesModal.code,
            {...deepGet(values, `guarantees.${activeBeneficiairesModal.code}`, {} ),
              beneficiaries,
            },
            "guarantees"
          );
        }}
      />
      <LeftInfoModal
        img={images.IconEraseRed}
        mainTitle={"¿Quieres borrar al beneficiario?"}
        mainText={"Si elimina al beneficiario, luego no podrá recuperar sus datos"}
        buttonText="Eliminar"
        close={() => setConfirmationModal({ status: false, data: emptyRemoveProps})}
        active={confirmationModal.status}
        onClickAccept={() => removeBeneficiary(confirmationModal.data)}
        className="error"
      />
      <ErrorModalContainer className={`${deepGet(getCoverageFail, "serverError") && `active`}`} >
        <div className="recover-modal">
          <div className="server-error">
            <p> {deepGet( getCoverageFail, "modalErrorText", "Error al obtener coberturas" )} </p>
          </div>
          <div className="button-error">
            <MainButton text="Aceptar" onClick={() => setGetCoverageFail({ serverError: false })} />
          </div>
        </div>
      </ErrorModalContainer>
    </>
  );
};

export default PolicyWarrantyStep;
