import React from 'react'
import {
  RouteComponentProps,
  withRouter,
  Switch,
  Route,
} from 'react-router-dom'

// Components

import { images } from '../../../assets'
import {
  DentalHireMenu,
  DentalHireMenuHelper,
  Steps as StepsDental,
} from '../../../components/dental-hire-menu/dental-hire-menu'
import GoBack from '../../../components/go-back'
import {
  HealthHireMenu,
  HealthHireMenuHelper,
  Steps,
  Steps as StepsSalud,
} from '../../../components/health-hire-menu/health-hire-menu'
import InflowSidebar from '../../../components/inflow-sidebar/inflow-sidebar'
import { InflowSidebarContainer } from '../../../components/inflow-sidebar/inflow-sidebar-style'
import LeftInfoModal from '../../../components/left-info-modal'
import NotFoundLayout from '../../../components/not-found/not-found-layout'
import { NewHiringContainer } from './new-hiring-style'

import { PolicyContext } from '../../../context/constants'
import { withGeneral } from '../../../context/mixin/with-general'
import HiringServices from '../../../services/HiringServices'
import { removeEmpty } from '../../../utils'
import ChoosePolicy from './choose-policy/choose-policy'

import DataHiring from '../../forms/form-data/data-layout'
import InsuredMembersLayout from '../../forms/insured-members/insured-members-layout'
import MascotsInsuredData from '../../forms/mascots/mascots-insured-data/mascots-insured-data'
import BeneficiariesLayout from './beneficiaries/beneficiaries-layout'

import PolicyFunctional from '../../../functional/policy'

import moment, { utc } from 'moment'
import BreadcrumbText from '../../../components/components-text/breadcrumb-text'
import { COVERAGES_PETS } from '../../../constants/coverages'
import { ERROR_MESSAGES } from '../../../constants/errorMessages'
import {
  AREA_NAMES,
  ENG_AREA_NAMES,
  getProductTypeFromURL,
  PRODUCT_TYPE,
} from '../../../constants/policyType'
import { withCoverages } from '../../../context/mixin/with-coverages'

import { IMediator } from '../../../models/mediator-model'
import { IUser } from '../../../models/user-model'
import { PricesResponseDto } from '../../../services/dto/response/SimulationServiceResponse.dto'
import GestDocServices from '../../../services/Gest-Doc.Services'
import { GestDoc } from '../../../services/GestDoc'
import HealthService from '../../../services/HealthService'
import {
  BuildGipPrecontractualDocumentResponseDto,
  BuildPrecontractualDocumentResponseDto,
} from '../../../services/HealthService-dto'
import SigningServices from '../../../services/SigningServices'
import {
  SigningRequestDto,
  SigningResponseDto,
} from '../../../services/SigningServices.dto'
import SimulationServices from '../../../services/SimulationServices'
import {
  CLIENT_NODE_ID,
  NOTA_INFORMATIVA_PARENT_NODE_ID,
  REACT_APP_SIGN_PROVIDER,
  REACT_APP_SIGN_REQUEST_TYPE,
  REACT_APP_SIGN_SUBJECT,
} from '../../../services/url'
import {
  ICoverages,
  IInsuranceProposalRequest,
  IInsuredCoveragesDataForm,
  IInsuredInsuranceProposal,
} from '../../../utils/interfaces'
import { deepGet, downloadFile } from '../../../utils/utility'
import { IGetPricesRequest, IInsured } from '../interfaces/hiring.base'
import GipInsuredDataLayout from './gip/gip-insured/gip-insured-data-layout'
import GipPaymentMethodsLayout from './gip/gip-payment-methods/gip-payment-methods-layout'
import GipTakerDataLayout from './gip/gip-taker/gip-taker-data-layout'
import GuaranteeDataLayout from './health/health- guarantee/guarantee-data-layout'
import InsuredDataLayout from './health/health-insured/insured-data-layout'
import PaymentMethodsLayout from './health/health-payment-methods/payment-methods-layout'
import PriceDataLayout from './health/health-price/price-data-layout'
import QuestionnaireDataLayout from './health/health-questionnaire/health-questionnaire-data-layout'
import ResultDataLayout from './health/health-result/result-data-layout'
import TakerDataLayout from './health/health-taker/taker-data-layout'
import QuestionnaireLayout from './questionnaire/questionnaire-layout'
import ResultLayout from './result/result-layout'
import {
  CONST_HIRING_STATUS,
  isValidStatusToCancelSignRequest,
  isValidStatusToEnableButton
} from './types'
// import GipGuaranteeDataLayout from "./gip/gip-guarantee/gip-guarantee-data-layout";
import isEqual from 'date-fns/is_equal/index.js'
import { SIGN_HIRING_STATUS_TYPE } from '../../../../src/constants/hiring'
import CrossProtectionData from '../../../components/cross/data-protection/cross-protection-data'
import { GipHireMenu, GipHireMenuHelper } from '../../../components/gip-menu/gip-hire-menu'
import { StepsTravel, TravelHireMenu, TravelHireMenuHelper } from '../../../components/travel-hire-menu/travel-hire-menu'
import { withDynamicProducts } from '../../../context/mixin/with-dynamic-products'
import { GestDocFileInfoResponseDto } from '../../../models/gest-doc-models'
import DentalService from '../../../services/DentalService'
import { CURRENCY, GetPaymentStatusResponseDto } from '../../../services/dto/request/PaymentRequests.dto'
import GeneralServices from '../../../services/GeneralServices'
import GipServices from '../../../services/GipServices'
import { BuildHiringPrecontractualDocumentResponseDto } from '../../../services/HiringServices-dto'
import PaymentServices from '../../../services/PaymentServices'
import ReceiptsServices from '../../../services/ReceiptsServices'
import { formatCoveragesTravel } from '../../../utils/coverages'
import { calculateDiferenceDays } from '../../../utils/time'
import RequiredDocDataLayout from './common/required-documentation/required-doc-data-layout'
import DentalGuaranteeDataLayout from './dental/dental-guarantee/dental-guarantee-data-layout'
import DentalInsuredDataLayout from './dental/dental-insured/dental-insured-data-layout'
import DentalPaymentMethodsLayout from './dental/dental-payment-methods/dental-payment-methods-layout'
import DentalPriceDataLayout from './dental/dental-price/dental-price-data-layout'
import DentalResultDataLayout from './dental/dental-result/dental-result-data-layout'
import DentalTakerDataLayout from './dental/dental-taker/dental-taker-data-layout'
import GipPriceDataLayout from './gip/gip-price/gip-price-data-layout'
import GipProtectionData from './gip/gip-protection-data/gip-protection-data'
import GipQuestionnaireDataLayout from './gip/gip-questionnaire/gip-questionnaire-data-layout'
import GipRequiredDocDataLayout from './gip/gip-required-documentation/gip-required-doc-data-layout'
import GipResultDataLayout from './gip/gip-result/gip-result-data-layout'
import {
  getPetInsuredDataForm,
  getPetInsuredsData,
} from './pet/pet-insureds-format'
import { PetHireNavHelper } from './pet/pet-navigation'
import TravelCloseResultDataLayout from './travel/travel-close-result/travel-close-result-data-layout'
import TravelInsuredDataLayout from './travel/travel-insured/travel-insured-data-layout'
import TravelProtectionData from './travel/travel-protection-data/travel-protection-data'
import TravelResultDataLayout from './travel/travel-result/travel-result-data-layout'
import TravelTakerDataLayout from './travel/travel-taker/travel-taker-data-layout'

interface NewHiringProps extends RouteComponentProps {
  setGuideLine: any
  setLoading: Function
  user: IUser
  mediator: IMediator
  coverages?: any
  match: any
  checkProductArea: Function
  allProducts: any
}

const SENDA_WARNING_MAIN_TEXT = 'Se va a realizar la solicitud de firma, aunque la solicitud puede requerir de revisión por la siguiente causa:';
const SENDA_TRAVEL_WARNING_MAIN_TEXT = 'Se ha generado la cotización, aunque la solicitud requiere de revisión por la siguiente causa:';
const SENDA_WARNING_SECONDARY_TEXT = '¿Desea continuar con el envío de la solicitud de firma?';
const SENDA_ERROR_TEXT = 'No se ha podido enviar la solicitud de firma por un error inesperado. Por favor, inténtelo más tarde y si continua ocurriendo contacte con el buzón de soporte.';
const SENDA_BUSINESS_MAIN_TEXT = 'No se ha podido enviar la solicitud de firma porque hay errores en la solicitud que deben ser corregidos antes de continuar:';
const SENDA_BUSINESS_SECONDARY_TEXT = 'Si tiene dudas para solucionarlos, por favor, contacte con la delegación.';
const SENDA_INFO = 'Para más información sobre el estado de la solicitud contactar con la delegación.';
const SENDA_TRAVEL_EXCEPTION_MAIN_TEXT = 'No es posible continuar la contratación por los errores que se detallan:';
const GESTDOC_DOC_NOT_AVAILABLE = 'La documentación se está generando. Por favor, inténtelo de nuevo transcurridos unos segundos.';

const PASSPORT_MIN_DIGITS_GESTDOC = 9
const MAX_RETROACTIVE_EFFECTIVE_DATE = 7

class NewHiring extends PolicyFunctional {
  constructor(props: NewHiringProps) {
    super(props)
    this.state = {
      downloadErrorMessage: '',
      savingEntityError: false,
      showLeftInfoModal: false,
      showNewSignInfoModal: false,
      showSendInfoModal: false,
      showSignedInfoModal: false,
      showExceptionModal: false,
      showPaymentInfoModal: false,
      showRetroactiveDate:
        this.checkRetroactiveDateNumber(this.getStateHistory('data')) >
        MAX_RETROACTIVE_EFFECTIVE_DATE,
      showListCompaniesException: false,
      showInfo: true,
      data: this.getStateHistory('data') || {
        taker: {},
        insureds: [],
        payment: {},
        beneficiary: {},
        beneficiaries: [],
        loan: {},
        signData: {
          signId: '',
          signRequestDate: '',
          signStatus: '',
        },
      },
      dataForm: this.getStateHistory('dataForm') || {
        taker: {},
        insureds: [],
        beneficiary: {},
        beneficiaries: [],
        loan: {},
        isReadOnly: false,
      },
      validateForm: false,
      addInsured: false,
      indexRemove: -1,
      responsePolicy: {},
      showInsuredModal: false,
      hiring: true,
      pathname: props.history.location.pathname,
      showHiringModal: false,
      saveHiring: false,
      changeState: false,
      simulationId: this.getStateHistory('simulationId'),
      hiringId: this.getStateHistory('hiringId'),
      fromSimulation:
        this.getStateHistory('fromSimulation') ||
        this.getStateHistory('simulationId') !== undefined,
      isEdit: {},
      saveInsured: false,
      saveQuestionnaire: false,
      resumeHiring: false,
      resumeDentalHiring: false,
      goToDetail: this.getStateHistory('goToDetail'),
      isReadOnly: this.getStateHistory('isReadOnly'),
      changeInsuredModalShow: false,
      removeInsured: false,
      infoModalShow: false,
      infoModalText: '',
      haveAnyChanges: false,
      showCancelHiringModal: false,
      downloadError: false,
      selectedMediatorCode: this.getStateHistory('selectedMediatorCode') || '',
      leftInfoModalClassName: '',
      leftInfoModalTitle: '',
      leftInfoModalText: '',
      leftInfoModalCloseButtonText: '',
      leftInfoModalButtonText: '',
      leftInfoModalClick: () => {},
      cancelSignModalShow: false,
      product: this.getProduct(),
      prices: {},
      setProductsDataError: false,
      document: null,
      productPayTypes: [],
      goToValidatePet: false,
    }
  }

  componentWillUnmount(): void {
    localStorage.removeItem('internalName')
  }

  checkRetroactiveDateNumber(data: any): number {
    if (
      data &&
      data.taker &&
      data.taker.policy &&
      data.taker.policy &&
      data.taker.policy.startDate
    ) {
      return this.getDifferenceInDays(data.taker.policy.startDate)
    } else if (data && data.mediatorData && data.mediatorData.startDate) {
      return this.getDifferenceInDays(data.mediatorData.startDate)
    } else if (data && data.taker && data.taker.starterDay) {
      return this.getDifferenceInDays(data.taker.starterDay)
    } else if (data && data.payment && data.payment.starterDay) {
      return this.getDifferenceInDays(data.payment.starterDay)
    }
    return 0
  }

  getDifferenceInDays(date: string): any {
    const splitDate: any = date.split('/')
    const startDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0])
    return calculateDiferenceDays(startDate, new Date())
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.history.location.pathname !== prevState.keyNavItem) {
      return { pathname: nextProps.history.location.pathname }
    } else return null
  }

  openHiringModal = () => {
    this.setState({ showHiringModal: true, saveHiring: false })
  }

  openSaveModal = () => {
    this.setState({ showHiringModal: true, saveHiring: true })
  }

  openListCompaniesExpectionModal = () => {
    this.setState({ showListCompaniesException: true })
  }

  closeListCompaniesExpectionModal = () => {
    this.setState({ showListCompaniesException: false })
  }

  closeHiringModal = () => {
    this.setState({ showHiringModal: false })
  }

  getStateHistory = (name: string) => {
    const { history } = this.props

    if (history.location.state) {
      if (
        history.location.pathname.indexOf('contratacion/nueva/mascotas') !== -1 &&
        name === 'data'
      ) {
        const { data } = history.location.state
        const petCoverages = this.props.coverages && this.props.coverages.pet_2024 || [];

        if (data && data.simulationInsureds && !data.insureds.length && petCoverages.length > 0) {
          data.insureds = getPetInsuredsData(
            data.simulationInsureds,
            data.taker,
            petCoverages
          )
        }
        return data
      }

      if (
        history.location.pathname.indexOf('contratacion/nueva/mascotas') !== -1 &&
        name === 'dataForm'
      ) {
        const { data, dataForm } = history.location.state
        const petCoverages = this.props.coverages && this.props.coverages.pet_2024 || [];

        if (data && dataForm && data.simulationInsureds && !dataForm.insureds.length && petCoverages.length > 0) {
          dataForm.insureds = getPetInsuredDataForm(
            data.simulationInsureds,
            data.taker,
            petCoverages
          )
        }
        return dataForm
      }

      return history.location.state[name]
    }

    return undefined
  }

  changePolicyType = () => {
    this.setState(
      {
        dataForm: {
          taker: {},
          insureds: [],
          beneficiary: {},
          beneficiaries: [],
          loan: {},
        },
        data: {
          taker: {},
          insureds: [],
          payment: {},
          beneficiary: {},
          beneficiaries: [],
          loan: {},
          signData: {
            signId: '',
            signRequestDate: '',
            signStatus: '',
          },
        },
        selectedMediatorCode: '',
      },
      () => this.setPolicyType()
    )
  }

  newStep = (go?: string, isOldInsured?: boolean) => {
    const { history, checkProductArea } = this.props
    const { dataForm, data, product } = this.state

    const isLifeArea = checkProductArea(
      AREA_NAMES.LIFE,
      product.products_internalName
    )
    const isDeathArea = checkProductArea(
      AREA_NAMES.DEATH,
      product.products_internalName
    )
    const isPetArea = checkProductArea(
      AREA_NAMES.PET,
      product.products_internalName
    )
    const isHealthArea = checkProductArea(
      AREA_NAMES.HEALTH,
      product.products_internalName
    )

    let url = '/contratacion/nueva/${type}/${step}'
    const nInsuredsMax = isDeathArea || isPetArea ? 10 : 2
    let ok = true
    let hiring = false
    let validateForm = false
    const { search, pathname } = history.location
    const insuredNumber: RegExpMatchArray | null = search.match(/index=(\d+)/i)
    const isNoHealthPolicy = isLifeArea || isDeathArea || isPetArea
    const productType = getProductTypeFromURL(product.products_link)

    switch (go) {
      case 'taker':
        url = url.replace('${type}', productType)
        url = url.replace('/${step}', '/tomador')
        break

      case 'showAll':
        url = url.replace('${type}', 'miembros')
        url = url.replace('/${step}', '')
        break

      case 'dataProtection':
        url = url.replace('${type}', productType)
        url = url.replace('/${step}', '/proteccion-datos')
        break
      case 'insured':
        if (nInsuredsMax <= dataForm.insureds.length) {
          ok = false
        }
        url = url.replace('${type}', productType)
        url = url.replace('${step}', 'asegurado')
        url += `?index=${
          isNoHealthPolicy && isOldInsured
            ? isPetArea
              ? `0`
              : data.insureds.length - 1
            : data.insureds.length
        }`
        break

      case 'insuredBeneficiary':
        if (nInsuredsMax < dataForm.insureds.length) {
          ok = false
        }
        url = url.replace('${type}', productType)
        url = url.replace('${step}', 'asegurado-beneficiario')
        break

      case 'beneficiary':
        url = url.replace('${type}', productType)
        url = url.replace('${step}', 'beneficiarios')
        break
      case 'healthBeneficiary':
        validateForm = !isHealthArea
        url = isHealthArea
          ? url.replace('${type}', 'salud')
          : url.replace('${type}', productType)
        //url = url.replace("${step}", "beneficiarios");
        url = url.replace('${step}', 'documentacion-requerida')
        break
      case 'questionnaires':
        url = url.replace('${type}', productType)

        if (isHealthArea) {
          url = url.replace('${step}', 'cuestionarios-salud/0')
          this.handleEditQuestionnaire(0)
        } else {
          url = url.replace('${step}', 'cuestionarios-vida/0')
        }

        break
      case 'reviewInsured':
        ok = true
        // hiring = true;
        url = url.replace('${type}', productType)
        url = url.replace('${step}', 'asegurado')
        url += `?index=${
          insuredNumber && insuredNumber[1] ? parseInt(insuredNumber[1]) + 1 : 0
        }`
        break
      case 'documentacion-requerida':
        validateForm = isHealthArea
        url = isHealthArea
          ? url.replace('${type}', 'salud')
          : url.replace('${type}', productType)
        url = url.replace('${step}', 'documentacion-requerida')
        break
      default:
        if (!this.state.haveAnyChanges) {
          hiring = true
          this.hiring()
        } else {
          ok = false
        }
        break
    }
    if (ok) {
      this.setState({ validateForm, changeState: true }, () => {
        this.setState({ changeState: false })
        if (!hiring) {
          history.push(url, { from: { pathname, search } })
        }
      })
    } else if (go === 'insured') {
      this.openInsuredModal()
    }
  }

  checkHiringAllowed() {
    this.hiring()
  }

  setLoan = (loan: any, active: boolean) => {
    const { data, dataForm } = this.state
    loan.active.value = active

    let error = false
    Object.keys(loan).forEach((k: string) => {
      if (loan[k].error || loan[k].value === '' || loan[k].value === undefined)
        error = true
    })

    dataForm.loan = loan

    if (loan.endDate.value && loan.endDate.value !== 'Invalid Date') {
      const dateEnd = new Date(loan.endDate.value)
      const date = moment(dateEnd, 'YYYY-MM-DD').utc().toDate()

      const mortgage = {
        swExistePrestHipo: loan.active.value && !error,
        entity: loan.bank.value,
        number: loan.nLoan.value,
        expirationDate: loan.endDate.value,
      }

      if (!error) {
        data.mortgage = mortgage
        data.loan = loan
      } else {
        mortgage.swExistePrestHipo = false
        data.mortgage = mortgage
      }
    }

    this.setState({
      data,
      dataForm,
    })
  }

  setBeneficiaries = (beneficiaries: any[]) => {
    const { dataForm, data } = this.state
    dataForm.beneficiaries = beneficiaries
    data.beneficiaries = beneficiaries.map((i) => ({
      name: i.name.value,
      lastName1: i.lastName1.value,
      lastName2: i.lastName2.value,
      docType: i.docType.value,
      docNumber: i.docNumber.value,
      percentage: parseFloat(i.percentage.value),
    }))

    this.setState({
      data,
      dataForm,
    })
  }

  setQuestionnaires = (
    index: number,
    questionnaires: any[],
    validateForm?: boolean
  ) => {
    //TODO: Insertar en dataForm y data, cuando index + 1 === data.insureds.length validateForm = true
    const { data, dataForm } = this.state
    dataForm.insureds[index].questionnaires = questionnaires
    data.questionnairesValuesData = []
    data.insureds[index].questionnaires = questionnaires.map(
      (q: any, index: number) => {
        data.questionnairesValuesData.push({
          question: q.question,
          code: q.code,
        })
        const object = {
          code: q.code,
          answer:
            q.answer.value === 'No'
              ? false
              : q.question === 'Estatura' || q.question === 'Peso'
              ? q.answer.value
              : true,
          subquestionnaires: [],
        }
        if (q.subquestionnaire.length > 0 && q.answer.value !== 'No') {
          object.subquestionnaires = q.subquestionnaire.map((sq: any) => ({
            code: sq.code,
            answer:
              q.answer.value === 'No'
                ? 'No'
                : sq.answer.value
                ? sq.answer.value
                : '-',
          }))
          data.questionnairesValuesData[index].subquestionnaires =
            q.subquestionnaire.map((sq: any) => ({
              code: sq.code,
              question: sq.question,
            }))
        } else delete object['subquestionnaires']
        return object
      }
    )
    this.setState({
      data,
      dataForm,
      validateForm: validateForm ? validateForm : false,
    })
  }

  hiring = async () => {
    const { setLoading } = this.props
    const { data, simulationId, hiringId, selectedMediatorCode, product } =
    this.state
    const dataSend: any = removeEmpty(data)

    if (simulationId) {
      dataSend.simulationId = simulationId
    }

    if (hiringId) {
      dataSend.id = hiringId
    }

    if (product.products_internalName === PRODUCT_TYPE.WOMAN) {
      dataSend.insureds.forEach((insured: any) => {
        insured.coverages.forEach((cov: any) => {
          if (!cov.capital) cov.capital = 0
        })
      })
    }

    if (
      !dataSend.taker.foreignAddress ||
      !dataSend.taker.foreignAddress.country
    )
      delete dataSend.taker.foreignAddress
    dataSend.insureds.forEach &&
      dataSend.insureds.forEach((insured: any) => {
        if (!!insured.foreignAddress && !insured.foreignAddress.country)
          delete insured.foreignAddress
      })

    if (dataSend.taker.birthdate && dataSend.taker.birthdate.includes('/')) {
      dataSend.taker.birthdate = this.dateToISOString(dataSend.taker.birthdate)
    }

    dataSend.insureds &&
      dataSend.insureds.forEach((insured: any) => {
        if (insured.birthdate && insured.birthdate.includes('/')) {
          insured.birthdate = this.dateToISOString(insured.birthdate)
        }

        if (insured.docType === 'NOID') {
          delete insured.docNumber
        } else if (insured.docType === 'MENOR') {
          insured.docNumber = 'MENOR'
        }
      })

    if (
      dataSend.payment &&
      dataSend.payment.starterDay &&
      dataSend.payment.starterDay.includes('/')
    ) {
      dataSend.payment.starterDay = this.dateToISOString(
        dataSend.payment.starterDay
      )
    }

    dataSend.mediatorAssociatedToPolicy = selectedMediatorCode
    dataSend.loggedUser = `${this.props.user.name} ${this.props.user.lastname1} ${this.props.user.lastname2}`
    dataSend.loggedUserMail = this.props.user.email
    dataSend.loggedUserCode = this.props.user.code
    dataSend.productType = product.products_internalName

    try {
      const response = await (HiringServices as any)[
        product.products_areaName.toLocaleLowerCase()
      ]({
        loader: true,
        setLoading: setLoading,
        data: dataSend,
      })

      this.setState({ validateForm: false, responsePolicy: response }, () => {
        if (response && response.warnings && response.warnings.length > 0) {
          const warns = response.warnings.map((r: any) => r.message)
          this.openExceptionModal(
            warns.length > 1
              ? 'Se han producido avisos como resultado de la operación:'
              : warns[0],
            'info',
            warns.length > 1 ? warns : []
          )
        } else {
          this.goToNextPage()
        }
      })
    } catch (err) {
      this.checkError(err, false)
    }
  }

  goToNextPage() {
    this.closeExceptionModal()
    const { history } = this.props
    const { product } = this.state
    const productType = getProductTypeFromURL(product.products_link)

    let url = '/contratacion/nueva/${type}/${step}'
    url = url.replace('${type}', productType)
    url = url.replace('${step}', 'resultado')
    history.push(url)
  }

  checkError(err: any, isHiringSimulation: boolean) {
    const data: any = err && err.data && err.data.errors ? err.data.errors : {}
    const genericErrorText = 'Se ha producido un error, inténtelo más tarde.'
    if (data) {
      const exceptions = []
      if (data.comment) exceptions.push(data.comment)
      if (data.description) exceptions.push(data.description)
      if (data.warnings)
        data.warnings.forEach((d: any) =>
          exceptions.push(`${d}\n`.replace('/<BR>/g', '\n'))
        )

      if (err.data.code === 'SENDA_EXCEPTION') {
        if (isHiringSimulation && err.data.errors.sendaCode === '2') {
          this.openExceptionModal(
            SENDA_BUSINESS_MAIN_TEXT,
            'warn',
            exceptions,
            SENDA_BUSINESS_SECONDARY_TEXT
          )
        } else
          this.openExceptionModal(
            exceptions.length > 0
              ? 'Se han producido uno o más errores como resultado de la operación:'
              : genericErrorText,
            'error',
            exceptions
          )
      } else
        this.openExceptionModal(
          isHiringSimulation ? SENDA_ERROR_TEXT : genericErrorText,
          'error',
          []
        )
    } else if (err.status == 422) this.setState({ savingEntityError: true })
    else this.openExceptionModal(genericErrorText, 'error', [])
  }

  dateToISOString(date: string) {
    return utc(date, 'DD/MM/YYYY').toISOString(true)
  }

  travelAddressConverter(address: any) {
    const { product } = this.state
    return {
      street: address.address && address.address.value,
      postalCode: address.postalCode,
      city: address.town && address.town.value,
      country: address.country && address.country.code,
      door: address.door,
      floorNumber: address.floor,
      province: product.products_internalName === PRODUCT_TYPE.TRAVEL_WELCOME ? (address.province && address.province.value) : (address.province && address.province.code),
      stairs: address.stairs,
      streetNumber: address.number,
      streetType: address.wayType,
    }
  }

  getInsureds(data: any) {
    try {
      const { checkProductArea } = this.props
      const { product } = this.state
      const isTravelArea = checkProductArea(
        AREA_NAMES.TRAVEL,
        product.products_internalName
      )
      const newInsureds = data.insureds.map((insuredRaw: any) => {
        const insured: IInsured = insuredRaw
        return {
          address: isTravelArea
            ? this.travelAddressConverter(insuredRaw.address)
            : {
                street: insured.address && insured.address.street,
                postalCode: insured.address && insured.address.postalCode,
                city: insured.address && insured.address.cityLabel,
                country: insured.address && insured.address.country,
                door: insured.address && insured.address.door,
                floorNumber: insured.address && insured.address.floorNumber,
                province: insured.address && insured.address.province,
                stairs: insured.address && insured.address.stairs,
                streetNumber: insured.address && insured.address.streetNumber,
                streetType: insured.address && insured.address.streetType,
              },
          birthdate: this.dateToISOString(
            isTravelArea ? insuredRaw.birthDate : insured.birthdate
          ),
          docNumber: insured.docNumber,
          docType: insured.docType,
          email: insured.email,
          lastname1: insured.lastname1,
          movilPhoneNumber: insured.movilPhoneNumber,
          movilPhonePrefix: insured.movilPhonePrefix,
          name: insured.name,
          coverages: isTravelArea
            ? formatCoveragesTravel(data.taker.policy.coverages)
            : insured.coverages,
          sex: isTravelArea ? insuredRaw.gender : insured.sex,
          starterDay: insured.starterDay,
          occupation: insured.occupation,
          lastname2: insured.lastname2 ? insured.lastname2 : undefined,
          nationality:
            insured.nationality && typeof insured.nationality === 'string'
              ? insured.nationality
              : undefined,
          foreignAddress:
            insured.foreignAddress && insured.foreignAddress.address
              ? {
                  address:
                    insured.foreignAddress && insured.foreignAddress.address
                      ? insured.foreignAddress.address
                      : '',
                  country:
                    insured.foreignAddress && insured.foreignAddress.country
                      ? insured.foreignAddress.country
                      : '',
                }
              : undefined,
          landlinePhoneNumber: insured.landlinePhoneNumber,
          profesion: insured.occupation ? insured.occupation : '',
          riskActivity: insured.riskActivity,
          questionnaires: insured.questionnaires,
        }
      })
      return newInsureds
    } catch (error) {
      console.log(error)
    }
  }

  getPricesRequest(data: any, petInfo: any[]): IGetPricesRequest {
    const { checkProductArea } = this.props
    const { product } = this.state
    const isPetArea = checkProductArea(AREA_NAMES.PET, product.products_internalName)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, product.products_internalName)
    let newData: IGetPricesRequest =
       {
          taker: {
            address: isTravelArea
              ? this.travelAddressConverter(data.taker.address)
              : {
                  street: data.taker.address && data.taker.address.street,
                  postalCode:
                    data.taker.address && data.taker.address.postalCode,
                  city: data.taker.address && data.taker.address.cityLabel,
                  country: data.taker.address && data.taker.address.country,
                  door: data.taker.address && data.taker.address.door,
                  floorNumber:
                    data.taker.address && data.taker.address.floorNumber,
                  province: data.taker.address && data.taker.address.province,
                  stairs: data.taker.address && data.taker.address.stairs,
                  streetNumber:
                    data.taker.address && data.taker.address.streetNumber,
                  streetType:
                    data.taker.address && data.taker.address.streetType,
                },
            docNumber: data.taker.docNumber,
            birthdate: moment(
              data.taker.birthdate
                ? data.taker.birthdate
                : data.taker.birthDate,
              'DD/MM/YYYY'
            )
              .format('YYYY-MM-DDTHH:mm:ss.sssZZ')
              .toString(),
            docType: data.taker.docType,
            email: data.taker.email,
            lastname1: data.taker.lastname1,
            lastname2: data.taker.lastname2
              ? data.taker.lastname2
              : undefined,
            nationality:
              data.taker.nationality &&
              typeof data.taker.nationality === 'string'
                ? data.taker.nationality
                : undefined,
            foreignAddress:
              data.taker.foreignAddress && data.taker.foreignAddress.address
                ? {
                    address:
                      data.taker.foreignAddress &&
                      data.taker.foreignAddress.address
                        ? data.taker.foreignAddress.address
                        : '',
                    country:
                      data.taker.foreignAddress &&
                      data.taker.foreignAddress.country
                        ? data.taker.foreignAddress.country.value
                        : '',
                  }
                : undefined,
            landlinePhoneNumber: data.taker.landlinePhoneNumber,
            movilPhonePrefix: data.taker.movilPhonePrefix,
            movilPhoneNumber: data.taker.movilPhoneNumber,
            name: data.taker.name,
            starterDay: moment().format('DD/MM/YYYY'),
            sex: isTravelArea
              ? data.taker.physicalPerson === false
                ? undefined
                : data.taker.gender
              : data.taker.sex,
            payType: data.payment.payType,
            serviceCode: data.taker.serviceCode ? data.taker.serviceCode : '',
            takerType: isTravelArea
              ? data.taker.physicalPerson === false
                ? 'PJUR'
                : 'PFIS'
              : undefined,
          },
          insureds: isPetArea ? data.insureds : this.getInsureds(data),
          
          beneficiaries: data.beneficiaries ? data.beneficiaries : [],
          petInfo: isPetArea ? petInfo : undefined,
          travelInfo: isTravelArea
            ? {
                departureDate:
                  data.taker &&
                  data.taker.policy &&
                  data.taker.policy.departureDate
                    ? data.taker.policy.departureDate
                    : '',
                returnDate:
                  data.taker &&
                  data.taker.policy &&
                  data.taker.policy.returnDate
                    ? data.taker.policy.returnDate
                    : '',
                travelDestination:
                  data.taker &&
                  data.taker.policy &&
                  data.taker.policy.travelDestination
                    ? data.taker.policy.travelDestination
                    : '',
                residenceCountry:
                  data.taker &&
                  data.taker.policy &&
                  data.taker.policy.residenceCountry
                    ? data.taker.policy.residenceCountry
                    : '',
              }
            : undefined,
          payment: {
            starterDay: moment(data.payment.starterDay, 'DD/MM/YYYY')
              .format('YYYY-MM-DDTHH:mm:ss.sssZZ')
              .toString(),
            payType: data.payment.payType,
            iban: data.payment.iban,
          },
          quote: true,
          protectionData: data.protectionData,
        }
      if (data.beneficiary && Object.entries(data.beneficiary).length !== 0) {
        newData = {
          ...newData,
          beneficiary: {
            address: {
              street:
                data.beneficiary.address && data.beneficiary.address.street,
              postalCode:
                data.beneficiary.address && data.beneficiary.address.postalCode,
              city: data.beneficiary.address && data.beneficiary.address.city,
              country:
                data.beneficiary.address && data.beneficiary.address.country,
              door: data.beneficiary.address && data.beneficiary.address.door,
              floorNumber:
                data.beneficiary.address &&
                data.beneficiary.address.floorNumber,
              province:
                data.beneficiary.address && data.beneficiary.address.province,
              stairs:
                data.beneficiary.address && data.beneficiary.address.stairs,
              streetNumber:
                data.beneficiary.address &&
                data.beneficiary.address.streetNumber,
              streetType:
                data.beneficiary.address && data.beneficiary.address.streetType,
            },
            docNumber: data.beneficiary.docNumber,
            birthdate: moment(data.beneficiary.birthdate, 'DD/MM/YYYY')
              .format('YYYY-MM-DDTHH:mm:ss.sssZZ')
              .toString(),
            docType: data.beneficiary.docType,
            email: data.beneficiary.email,
            lastname1: data.beneficiary.lastname1,
            lastname2: data.beneficiary.lastname2
              ? data.beneficiary.lastname2
              : undefined,
            nationality:
              data.beneficiary.nationality &&
              typeof data.beneficiary.nationality === 'string'
                ? data.beneficiary.nationality
                : undefined,
            foreignAddress:
              data.beneficiary.foreignAddress &&
              data.beneficiary.foreignAddress.address
                ? {
                    address:
                      data.beneficiary.foreignAddress &&
                      data.beneficiary.foreignAddress.address
                        ? data.beneficiary.foreignAddress.address
                        : '',
                    country:
                      data.beneficiary.foreignAddress &&
                      data.beneficiary.foreignAddress.country
                        ? data.beneficiary.foreignAddress.country.value
                        : '',
                  }
                : undefined,
            landlinePhoneNumber: data.beneficiary.landlinePhoneNumber,
            movilPhonePrefix: data.beneficiary.movilPhonePrefix,
            movilPhoneNumber: data.beneficiary.movilPhoneNumber,
            name: data.beneficiary.name,
            starterDay: moment().format('DD/MM/YYYY'),
            sex: data.beneficiary.sex,
            payType: data.payment.payType,
            serviceCode: data.beneficiary.serviceCode
              ? data.beneficiary.serviceCode
              : '',
          },
        }
      }
     
  return newData
  }

  getPrices = async () => {
    const { setLoading, checkProductArea, coverages } = this.props
    const { product, data } = this.state
    const petInfo: any[] = []
    const isPetArea = checkProductArea(AREA_NAMES.PET, product.products_internalName)
    const isLifeArea = checkProductArea(AREA_NAMES.LIFE, product.products_internalName)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, product.products_internalName)

    if (Object.keys(data.taker).length === 0) return

    if (isPetArea) {
      data.insureds.forEach((pet: any, index: number) => {
        petInfo[index] = {
          ...pet,
          birthdate: moment(pet.birthdate, 'DD/MM/YYYY')
            .format('YYYY-MM-DDTHH:mm:ss.sssZZ')
            .toString(),
        }
      })
    }

    const newData: IGetPricesRequest = this.getPricesRequest(data, petInfo)
    if (isLifeArea) {
      newData.taker.capital = data.taker.capital
      newData.taker.occupation = data.taker.occupation
    }

    if (isTravelArea) {
      const productType: string = product.products_coverageName
      if(productType && coverages[productType]){
        newData.coverages = formatCoveragesTravel(coverages[productType]);
      }else{
        const resCoverages = await HealthService.getCoveragesFromDB(setLoading, true, productType)
        if (resCoverages !== undefined) {
          newData.coverages = formatCoveragesTravel(resCoverages);
        }
      }
    }
    
    const response = await SimulationServices.getPrices(
      {
        loader: true,
        setLoading: setLoading,
        data: newData,
      },
      product.products_areaName,
      product.products_internalName
    ).catch((ex) => {
      const data: any =
        ex && ex.data && ex.data.errors ? ex.data.errors : undefined
      if (data) {
        const exceptions = []
        if (data.comment) exceptions.push(data.comment)
        if (data.description) exceptions.push(data.description)
        if (data.warnings) {
          data.warnings.forEach((d: any) =>
            exceptions.push(`${d}\n`.replace('/<BR>/g', '\n'))
          )
        }

        if (ex.data.code === 'SENDA_EXCEPTION') {
          this.openExceptionModal(
            exceptions.length > 0
              ? 'Se han producido uno o más errores como resultado de la operación:'
              : 'Se ha producido un error, inténtelo más tarde.',
            'error',
            exceptions
          )
        } else
          this.openExceptionModal(
            'Se ha producido un error, inténtelo más tarde.',
            'error',
            []
          )
      } else if (ex.status == 422) {
        this.setState({ savingEntityError: true })
      } else {
        this.openExceptionModal(
          'Se ha producido un error, inténtelo más tarde.',
          'error',
          []
        )
      }
      return undefined
    })
    return response
  }

  downloadDocs = async () => {
    try {
      const { checkProductArea } = this.props
      const { prices, data, document, product } = this.state
      const isGipArea = checkProductArea(
        AREA_NAMES.GIP,
        product.products_internalName
      )
      if (data.policyData && isGipArea) {
        const doc: BuildPrecontractualDocumentResponseDto =
          await this.getGipPrecontractualDocument()
        downloadFile(doc.fileData, doc.fileName, doc.fileType)
      } else {
        if (document) {
          downloadFile(document.fileData, document.fileName, document.fileType)
        } else {
          const createdDocument = await this.createDocumentation(prices)
          await this.setState({ document: createdDocument })
          if (createdDocument)
            downloadFile(
              createdDocument.fileData,
              createdDocument.fileName,
              createdDocument.fileType
            )
        }
      }
    } catch (error) {
      this.setState({ downloadError: true })
    }
  }

  getProductInfo() {
    const { product } = this.state
    return {
      businessLine: product.ln_linea_negocio,
      family: product.ln_family,
      colective: product.products_colectivo,
      combiProd: product.products_combinacion_comercial,
    }
  }

  setProductsData = async () => {
    try {
      const { data } = this.state
      this.setState({ setProductsDataError: false, document: null })
      const prices: PricesResponseDto | undefined = await this.getPrices()
      if (prices && prices.warnings && prices.warnings.length > 0) {
        const warns = prices.warnings.map((r: any) => r.message)
        this.openPricesModal(
          warns.length > 1
            ? 'Se han producido avisos como resultado de la operación:'
            : warns[0],
          'info',
          warns.length > 1 ? warns : []
        )
      }

      if (prices) {
        this.setState({ prices: prices })
        if (!data.requestId || data.requestId !== prices.requestNumber) {
          data.requestId = prices.requestNumber
          this.setState({ data })
        }
      } else {
        this.setState({ prices: {}, setProductsDataError: true })
      }
    } catch (error) {
      this.setState({ prices: {}, setProductsDataError: true })
      console.log(error)
    }
  }

  closeDownloadErrorModal() {
    this.setState({ downloadError: false, downloadErrorMessage: '' })
  }

  getRequestInsuranceDoc = (): IInsuranceProposalRequest => {
    const { data, hiringId, product } = this.state
    const { coverages, checkProductArea } = this.props
    const internalName = product.products_internalName
    const coverageName = product.products_coverageName
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, internalName)

    let covs: any[]
    covs = coverages[coverageName]
    try {
      if (isTravelArea) covs = data.taker.policy.coverages
    } catch (error) {
      console.error(error)
    }

    const request: IInsuranceProposalRequest = {
      hiringId: hiringId ? hiringId : 0,
      taker: data.taker,
      insureds: data.insureds,
      payment: data.payment,
      coverages: covs,
      protectionData: data.protectionData,
      beneficiary: data.beneficiary,
      beneficiaries: data.beneficiaries,
      questionnairesValuesData: data.questionnairesValuesData,
      mortgage: data.mortgage,
    }
    return request
  }

  getInsuranceProposal = async () => {
    const { setLoading } = this.props
    const { data } = this.state

    if (data.proposalNodeId) {
      const document = await GestDocServices.getFile(
        setLoading,
        true,
        data.proposalNodeId
      )
      downloadFile(document.fileData, document.fileName, document.fileType)
    }
  }

  getInsuranceRequest = async () => {
    const { setLoading } = this.props
    const { data } = this.state

    if (data.requestNodeId) {
      const document = await GestDocServices.getFile(
        setLoading,
        true,
        data.requestNodeId
      )
      downloadFile(document.fileData, document.fileName, document.fileType)
    }
  }

  async generateInsuranceRequest(prices: PricesResponseDto) {
    const { setLoading, user, mediator, checkProductArea } = this.props
    const { data, product } = this.state
    const internalName = product.products_internalName
    const isLifeArea = checkProductArea(AREA_NAMES.LIFE, internalName)

    const request = this.getRequestInsuranceDoc()
    request.insureds.map((insured: IInsuredInsuranceProposal) => {
      insured.coverages.map((coverage: ICoverages) => {
        const newInsured = prices.insured.find(
          (i) => i.docNumber === insured.docNumber
        )
        const newCoverage = newInsured
          ? newInsured.coverages.find((c) => c.code === coverage.code)
          : ''
        if (newCoverage) {
          coverage.payment = newCoverage.payment
        }
      })
    })

    const documentRequest = await HiringServices.generateInsuranceRequest(
      setLoading,
      true,
      product.products_internalName,
      request
    )
    const requestFile: File = new File(
      [new Blob([documentRequest.fileData])],
      `${data.taker.docNumber}_${ENG_AREA_NAMES[
        product.products_areaName
      ].toLocaleLowerCase()}_request_document.pdf`
    )

    const metadata = GestDoc.getPolicyDocumentSearchRequest(
      isLifeArea,
      user,
      data.taker,
      prices.requestNumber.toString(),
      data.insureds,
      product.products_colectivo,
      internalName,
      product.products_combinacion_comercial,
      mediator.nif
    )

    const respuesta = await GestDoc.uploadDocument(
      CLIENT_NODE_ID,
      requestFile,
      metadata.fileInfo,
      setLoading
    )
    if (respuesta) return respuesta.entry.id
  }

  async generateInsuranceProposal(prices: PricesResponseDto) {
    const { setLoading, user, mediator, checkProductArea } = this.props
    const { data, product } = this.state
    const isLifeArea = checkProductArea(
      AREA_NAMES.LIFE,
      product.products_internalName
    )
    const isPetArea = checkProductArea(
      AREA_NAMES.PET,
      product.products_internalName
    )

    const request = JSON.parse(JSON.stringify(this.getRequestInsuranceDoc()))

    let dataRequest = { ...request }

    let premiums: any = {}
    prices.insured.forEach((insured: any, index: number) => {
      const { docNumber, coverages } = insured
      const idInsured = isPetArea ? index : docNumber

      premiums[idInsured] = premiums[idInsured] || 0

      dataRequest.insureds[index].coverages = coverages

      coverages.forEach((coverage: ICoverages) => {
        const { payment } = coverage
        const { tax } = payment
        premiums[idInsured] += payment.netPremium + tax.ips + tax.dgs

        let coverageIndex = dataRequest.insureds[index].coverages.findIndex(
          (cov: any) => cov.code === coverage.code
        )
        if (coverageIndex !== -1)
          dataRequest.insureds[index].coverages[coverageIndex].capital =
            coverage.capital
      })
    })

    dataRequest.insureds.map(
      (insured: any, index: number) =>
        (insured.premium = premiums[insured.docNumber] || premiums[index])
    )

    const documentProposal = await HiringServices.generateInsuranceProposal(
      setLoading,
      true,
      product.products_internalName,
      dataRequest
    )
    const requestFile: File = new File(
      [new Blob([documentProposal.fileData])],
      `${data.taker.docNumber}_${ENG_AREA_NAMES[
        product.products_areaName
      ].toLocaleLowerCase()}_proposal_document.pdf`
    )

    const metadata = GestDoc.getPolicyDocumentSearchProposal(
      isLifeArea,
      user,
      data.taker,
      prices.requestNumber.toString(),
      data.insureds,
      product.products_colectivo,
      product.products_internalName,
      product.products_combinacion_comercial,
      mediator.nif
    )

    const respuesta = await GestDoc.uploadDocument(
      CLIENT_NODE_ID,
      requestFile,
      metadata.fileInfo,
      setLoading
    )
    if (respuesta) return respuesta.entry.id
  }

  async createDocumentation(
    prices: PricesResponseDto
  ): Promise<BuildHiringPrecontractualDocumentResponseDto | undefined> {
    const { setLoading, user, checkProductArea } = this.props
    const { data, selectedMediatorCode, product } = this.state

    const isLifeArea = checkProductArea(
      AREA_NAMES.LIFE,
      product.products_internalName
    )
    const isTravelArea = checkProductArea(
      AREA_NAMES.TRAVEL,
      product.products_internalName
    )

    let requestNodeId
    let proposalNodeId
    try {
      if (prices) {
        if (!isTravelArea) {
          requestNodeId = await this.generateInsuranceRequest(prices)
          data.requestNodeId = requestNodeId
          this.setState({ data })
        }

        //Crear la propuesta del seguro
        if (!isLifeArea && !isTravelArea) {
          proposalNodeId = await this.generateInsuranceProposal(prices)
          data.proposalNodeId = proposalNodeId
          this.setState({ data })
        }

        let insuredIds: any = []
        data.insureds.map((ins: any) => {
          ins.useTakerData = ins.docNumber === data.taker.docNumber
          if (ins.docNumber) {
            if (ins.docNumber) {
              insuredIds.push(ins.docNumber)
            }
          } else {
            if (ins.numChip) {
              insuredIds.push(ins.numChip)
            }
          }
        })

        if (((isLifeArea || proposalNodeId) && requestNodeId) || isTravelArea) {
          let document = await HiringServices.buildHiringPrecontractualDocument(
            setLoading,
            true,
            {
              policyType: product.products_internalName,
              insuredIds: insuredIds,
              mediatorCode: selectedMediatorCode || user.code,
              requestNodeId: requestNodeId,
              proposalNodeId: proposalNodeId,
            }
          ).catch((e) => {
            if (e.status === 409) {
              this.infoModalOpen(GESTDOC_DOC_NOT_AVAILABLE)
            }
            return e
          })

          if (document && !document.status) {
            return document
          } else {
            return undefined
          }
        }
      }
      this.setState({ downloadError: true })
      return undefined
    } catch (e) {
      console.log(e)
      this.setState({ downloadError: true })
      return undefined
    }
  }

  async checkHiring() {
    const { setLoading, checkProductArea } = this.props
    const { data, prices, product } = this.state
    const isLifeArea = checkProductArea(
      AREA_NAMES.LIFE,
      product.products_internalName
    )
    const isDeathArea = checkProductArea(
      AREA_NAMES.DEATH,
      product.products_internalName
    )
    const isPetArea = checkProductArea(
      AREA_NAMES.PET,
      product.products_internalName
    )
    const isTravelArea = checkProductArea(
      AREA_NAMES.TRAVEL,
      product.products_internalName
    )

    try {
      const isNotHealthPolicy =
        isLifeArea || isDeathArea || isPetArea || isTravelArea
      if (!isNotHealthPolicy) this.sendSignConfirm()

      const petInfo: any[] = []
      if (isPetArea) {
        data.insureds.forEach((pet: any, index: number) => {
          petInfo[index] = {
            ...pet,
            birthdate: moment(pet.birthdate, 'DD/MM/YYYY')
              .format('YYYY-MM-DDTHH:mm:ss.sssZZ')
              .toString(),
          }
        })
      }
      const newData: IGetPricesRequest = this.getPricesRequest(
        isPetArea ? removeEmpty(data) : data,
        petInfo
      )
      newData.requestId = prices.requestNumber
      newData.productType = product.products_internalName

      let hiring
      if (isLifeArea) {
        newData.taker.capital = data.taker.capital
        newData.taker.occupation = data.taker.occupation
        hiring = await HiringServices.lifeSimulation({
          data: newData,
          setLoading: setLoading,
        })
      } else if (isDeathArea) {
        hiring = await HiringServices.deathSimulation({
          data: newData,
          setLoading: setLoading,
        })
      } else if (isPetArea) {
        newData.insureds = petInfo
        hiring = await HiringServices.petSimulation({
          data: newData,
          setLoading: setLoading,
        })
      } else if (isTravelArea) {
        newData.payment.paymentMethod = 'TABA'
        hiring = await HiringServices.travelSimulation({
          data: newData,
          setLoading: setLoading,
        })
      }

      if (hiring && hiring.warnings && hiring.warnings.length > 0) {
        const hiringOK = 'No se emite la poliza por que no se ha realizado el pago de la misma:  COTIZACION GENERADA:';
        const warns = hiring.warnings.filter((w: any) => !w.includes(hiringOK));
        if (warns.length > 0) {
          if (isTravelArea && hiring.exception) this.openShowTravelWarningsHiring(SENDA_TRAVEL_EXCEPTION_MAIN_TEXT, 'info', warns);
          else this.openShowWarningsHiring(SENDA_WARNING_MAIN_TEXT, 'info', warns, SENDA_WARNING_SECONDARY_TEXT);
        } 
        else { // Si solo tiene el error del pago se continua.
          if (isTravelArea) this.handlePayHiring();
          else this.sendSignConfirm();
        }
      }
      else { // Si no llegan avisos se continua.
        if (isTravelArea) this.handlePayHiring();
        else this.sendSignConfirm();
      }
    } catch (error) {
      this.checkError(error, true);
    }
  }

  sendSignConfirm = async () => {
    const { setLoading } = this.props
    const { data, document, showWarningsHiring, product, prices } = this.state

    try {
      if (showWarningsHiring) this.closeShowWarningsHiring()
      if (data.policyData) {
        this.checkSignBeforeGipPrecontractualDocument()
      } else {
        if (
          data.signData &&
          data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED
        ) {
          await this.removeSignRequestData(
            'CANCELADA PARA ENVÍO DE NUEVA PETICIÓN',
            false
          )
        }
        if (prices) {
          data.requestId = prices.requestNumber
          this.setState({ data })
          let createdDocument = document
          if (!document) {
            createdDocument = await this.createDocumentation(prices)
            await this.setState({ document: createdDocument })
          }
          const takerFullName =
            `${data.taker.name} ${data.taker.lastname1} ${data.taker.lastname2}`.trim()

          if (createdDocument) {
            const documentBase64 = Buffer.from(
              createdDocument.fileData
            ).toString('base64')

            let signData: SigningRequestDto = {
              signingRequestType: REACT_APP_SIGN_REQUEST_TYPE,
              externalProvider: REACT_APP_SIGN_PROVIDER,
              subject: REACT_APP_SIGN_SUBJECT,
              documentName: createdDocument.fileName,
              documentBase64: documentBase64,
              signers: [
                {
                  fullName: takerFullName,
                  identityDocument: data.taker.docNumber,
                  phoneNumber: data.taker.movilPhoneNumber
                  ? `${data.taker.movilPhonePrefix}${data.taker.movilPhoneNumber}`
                  : data.taker.mobilePhone.split(' ').join(''),
                  email: data.taker.email,
                }
              ],
              language: 'es',
              uploadToGitDoc: true,
              gitDocParameters: {
                collective: Number(product.products_colectivo),
                fullname: takerFullName,
                nif: data.taker.docNumber,
                order: '01',
                policyCode: prices.requestNumber.toString(),
                subject: 'DOCUMENTOS FIRMADOS',
                topic: 'DOCUMENTACION ALTA',
              },
            }

            const signResponse: SigningResponseDto =
              await SigningServices.postSignRequest(setLoading, true, signData)
                .then((data: any) => {
                  return data
                })
                .catch((e) => {
                  throw e
                })

            if (signResponse.id) {
              const signStatusResponse = await SigningServices.getSignRequest(
                setLoading,
                true,
                signResponse.id
              )
              data.signData = {
                signId: signResponse.id,
                signRequestDate: new Date(),
                signStatus: signStatusResponse.status,
              }
              this.setState({ data })
              await this.saveHiring(true)
              this.setState({
                ...this.state,
                showSignedInfoModal: true,
                leftInfoModalClassName: '',
                leftInfoModalTitle: 'Firma solicitada al tomador.',
                leftInfoModalText:
                  'La póliza se emitirá una vez se haya realizado la firma aceptando el documento precontractual.',
                leftInfoModalCloseButtonText: 'Salir',
                leftInfoModalClick: () => {
                  this.props.history.replace('/contratacion/pendiente')
                },
              })
            } else {
              data.signData = {
                signId: '',
                signRequestDate: '',
                signStatus: '',
              }
              this.openExceptionModal(ERROR_MESSAGES['unknow-error'], 'error')
            }

            return
          }
        }
      }
    } catch (ex) {
      console.log('ex')
      this.setState({ downloadError: true })
    }
  }

  sendSign = async () => {
    const { data } = this.state

    if (data.signData && data.signData.signId && data.signData.signId !== '') {
      this.setState({
        ...this.state,
        showNewSignInfoModal: true,
        leftInfoModalClassName: '',
        leftInfoModalTitle: 'Envío de solicitud de firma',
        leftInfoModalText:
          'Cualquier petición de firma existente asociada a esta contratación será cancelada. ¿Desea continuar?',
        leftInfoModalCloseButtonText: 'Cerrar',
        leftInfoModalButtonText: 'Aceptar',
        leftInfoModalClick: () => {
          this.checkHiring()
        },
      })
    } else {
      this.checkHiring()
    }
  }

  getFormatForCompare = (data: any, policyChosen: string = ''): any => {
    const { checkProductArea } = this.props
    const {
      beneficiaries,
      insureds,
      paymentMethod,
      priceData,
      protectionData,
      questionaries,
      taker,
      uploadedQuests,
    } = data

    let newTaker = JSON.parse(JSON.stringify(taker))
    let newInsureds = JSON.parse(JSON.stringify(insureds))
    let newPriceData = priceData
      ? JSON.parse(JSON.stringify(priceData))
      : undefined

    delete newTaker.taker

    const isLifeArea = checkProductArea(AREA_NAMES.LIFE, policyChosen)
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policyChosen)
    const isPetArea = checkProductArea(AREA_NAMES.PET, policyChosen)

    if (isLifeArea || isDeathArea || isPetArea) {
      delete newTaker.checkedConditions
      delete newTaker.foreignAddress
      delete newTaker.nationality
      delete newTaker.payment
      delete newTaker.payType
      delete newTaker.country
    }
    if (newPriceData && newPriceData.priceResponse)
      delete newPriceData.priceResponse

    newInsureds.map((insured: any) => {
      delete insured.iban
      delete insured.payType
      delete insured.starterDay
      delete insured.capital
      delete insured.useTakerData
      delete insured.foreignAddress
      delete insured.checkedConditions
      delete insured.numPets
      delete insured.payment
      delete insured.country
      delete insured.nationality
      delete insured.nationalityLabel
      delete insured.numberInsured
      delete insured.streetType
      delete insured.street
      delete insured.streetNumber
      delete insured.stairs
      delete insured.floorNumber
      delete insured.door
      delete insured.province
      delete insured.city
      delete insured.postalCode
      delete insured.passportDate
      // ordenamos la coverturas, para producto vida-tranquilidad
      if (insured.coverages) {
        insured.coverages.sort((a: any, b: any) => {
          if (a.code > b.code) return 1
          if (a.code > b.code) return -1
          return 0
        })
      }
    })

    const newData = {
      beneficiaries,
      newInsureds,
      paymentMethod,
      newPriceData,
      protectionData,
      questionaries,
      newTaker,
      uploadedQuests,
    }

    return newData
  }

  getMediatorName = (
    mediator: IMediator,
    selectedMediatorCode: string
  ): string => {
    let mediatorName = ''

    if (mediator.mediatorCode === selectedMediatorCode) {
      mediatorName = mediator.mediatorName.trim()
    } else {
      mediatorName = mediator.relatedMediators
        ? mediator.relatedMediators
            .find((m: any) => m.mediatorCode === selectedMediatorCode)
            .mediatorName.trim() || ''
        : ''
    }
    return mediatorName
  }

  cancelSignatureReguest = (hiringId: number, data: any) => {
    // obtengo los datos almacenados de la póliza
    this.getDataPolicy(hiringId, data.mediatorAssociatedToPolicy).then(
      (res) => {
        const hiringDB = JSON.parse(JSON.stringify(res))
        const bodyHiringDB = hiringDB.body

        if (bodyHiringDB) {
          const newData = this.getFormatForCompare(data, hiringDB.productType)
          const dbData = this.getFormatForCompare(
            bodyHiringDB.body,
            hiringDB.productType
          )
          // comparamos los dos objetos
          // si los datos de la póliza fueron modificados,
          // solicitamos la cancelacion de la solicitud de la firma
          if (!isEqual(newData, dbData)) {
            this.removeSignAsync().then(
              (resp) =>
                (data.signData = {
                  signId: '',
                  signRequestDate: '',
                  signStatus: '',
                })
            )
          }
        }
      }
    )
  }

  saveHiring = async (
    saveActualHealthStep: boolean = false,
    noRedirect?: boolean,
    clearQuestionnaires?: boolean 
  ) => {
    const { history, setLoading, user, checkProductArea } = this.props
    let { data, hiringId, selectedMediatorCode, simulationId, product } =
    this.state

    const internalName = product.products_internalName
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, internalName)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, internalName)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, internalName)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, internalName)

    if (simulationId) {
      data.simulationId = simulationId
    }

    if (hiringId) {
      data.id = hiringId
    }

    const mediatorAssociatedToPolicy =
      selectedMediatorCode !== '' ? selectedMediatorCode : user.code
    const mediator = JSON.parse(localStorage.getItem('loggedMediator') || '')

    data.mediatorUser = {
      mediator: localStorage.getItem('userName'),
      code: mediatorAssociatedToPolicy,
      name: this.getMediatorName(mediator, mediatorAssociatedToPolicy),
      delegation: user.delegation,
    }

    // if (policyType === "mascotas") {
    //   dataSend.insureds = dataSend.insureds.length
    // }
    if (isHealthArea) {
      data.questionnaires = clearQuestionnaires ? {} : (data.questionnaires) ? data.questionnaires : {} 
      let currentHealthStep = HealthHireMenuHelper.getStepFromNavigationRoute(
        this.props.history.location.pathname
      )
      data.currentHealthStep = saveActualHealthStep
        ? currentHealthStep
        : HealthHireMenuHelper.getNextStep(currentHealthStep)
    }
    if (isDentalArea) {
      let currentDentalStep = DentalHireMenuHelper.getStepFromNavigationRoute(
        this.props.history.location.pathname
      )
      data.currentDentalStep = saveActualHealthStep
        ? currentDentalStep
        : DentalHireMenuHelper.getNextStep(currentDentalStep)
    }
    if (isGIPArea) {
      data.questionnaires = data.questionnaires ? data.questionnaires : []
      let currentHealthStep = GipHireMenuHelper.getStepFromNavigationRoute(
        this.props.history.location.pathname,
        internalName
      )
      data.currentHealthStep = saveActualHealthStep
        ? currentHealthStep
        : GipHireMenuHelper.getNextStep(currentHealthStep)
    }
    if (isTravelArea) {
      let firstTravelStep = TravelHireMenuHelper.getFirstStep
      data.currentTravelStep = firstTravelStep
      data.requestNumber = data.requestId
    }
    let isNotRedirecting = true
    if (!data.noRedirect) {
      isNotRedirecting = data.noRedirect
      data.noRedirect = true
    }

    try {
      //const responseSimulation = await (SimulationServices as any)[
      //   policyChosen
      // ]({
      //   loader: true,
      //   setLoading: setLoading,
      //   data: dataSend
      // });
      const userName = `${user.name} ${user.lastname1} ${user.lastname2}`
      const loader = true

      // comprobamos si se ha hecho la solicitd de la firma precontractual
      // y si el producto es salud
      if (
        data.id &&
        data.mediatorAssociatedToPolicy &&
        data.signData &&
        data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED &&
        isNotRedirecting
      ) {
        const res = await HiringServices.getOneHiring({
          loader: true,
          setLoading,
          id: data.id,
          mediator: data.mediatorAssociatedToPolicy,
        })
        if (res) {
          const hiringDB = JSON.parse(JSON.stringify(res))
          const bodyHiringDB = hiringDB.body

          if (
            bodyHiringDB &&
            bodyHiringDB.signData &&
            bodyHiringDB.signData.signStatus ===
              SIGN_HIRING_STATUS_TYPE.REQUESTED &&
            !(isHealthArea || isGIPArea)
          ) {
            const newData = this.getFormatForCompare(data, internalName)
            const dbData = this.getFormatForCompare(bodyHiringDB, internalName)
            // const anyChangeData = !isEqual(newData, dbData)
            const anyChangeData = !(
              JSON.stringify(newData) === JSON.stringify(dbData)
            )

            if (anyChangeData) {
              this.setState({ cancelSignModalShow: true })
              return
            }
          }
        }
      }
      
      const response = await HiringServices.save({
        loader,
        setLoading: setLoading,
        data: {
          body: {
            ...data,
            mediatorAssociatedToPolicy,
            loggedUser: userName,
            loggedUserMail: user.email,
            loggedUserCode: user.code,
            simulationId: simulationId,
          },
          //response: responseSimulation,
          productType: product.products_internalName,
        },
      })
      //
      if (response && (response.policyId || response.id))
        this.setState({ hiringId: response.policyId || response.id })

      data.id = response.id
      hiringId = response.id
      this.setState({ data, hiringId })
    } catch (err) {
      const data: any =
        err && err.data && err.data.errors ? err.data.errors : {}
      if (data) {
        const exceptions = []
        if (data.comment) exceptions.push(data.comment)
        if (data.description) exceptions.push(data.description)
        if (data.warnings) {
          data.warnings.forEach((d: any) =>
            exceptions.push(`${d}\n`.replace('/<BR>/g', '\n'))
          )
        }
        this.openExceptionModal(
          exceptions.length > 0
            ? 'Se han producido uno o más errores como resultado de la operación:'
            : 'Se ha producido un error, inténtelo más tarde.',
          'error',
          exceptions
        )
      } else {
        this.openExceptionModal(
          'Se ha producido un error, inténtelo más tarde.',
          'error',
          []
        )
      }
    }
  }

  removeSign = async () => {
    const { setLoading } = this.props
    let { data } = this.state
    const deleteSigningResponse = await SigningServices.removeSignRequest(
      setLoading,
      true,
      this.state.data.signData.signId,
      { reason: 'CANCELADA PARA ENVÍO DE NUEVA PETICIÓN' }
    )
    if (deleteSigningResponse && deleteSigningResponse.status == 200) {
      data.sendToSign = false
      data.signData.signStatus = ''
      data.signData.signId = ''
      data.signData.signRequestDate = ''
      this.setState({ data }, () => this.saveHiring())
    }
  }

  cancelHiring = async () => {
    const { setLoading, history } = this.props
    const { data } = this.state

    try {
      if (data.signData && data.signData.signId) {
        const signStatusResponse = await SigningServices.removeSignRequest(
          setLoading,
          true,
          data.signData.signId,
          { reason: 'CANCELADA POR EL USUARIO' }
        )
      }
      data.updateStatus = 'CANCELADA'
      this.setState({ data }, async () => {
        await this.saveHiring(false)
        history.push('/contratacion/pendiente')
      })
    } catch (error) {
      console.log(error)
      this.openExceptionModal(
        'Ha ocurrido un error.\nNo se ha podido cancelar esta contratación.',
        'error'
      )
    }
  }

  getDataPolicy = (hiringId: number, mediator: string) => {
    const { setLoading } = this.props

    return new Promise((resolve, reject) => {
      HiringServices.getOneHiring({
        loader: true,
        setLoading,
        id: hiringId,
        mediator,
      })
        .then((response: any) => resolve(response))
        .catch((err: any) => reject(err))
    })
  }

  removeSignAsync = () => {
    return new Promise((resolve, reject) => {
      this.removeSignRequestData(
        'CANCELADA PARA ENVÍO DE NUEVA PETICIÓN',
        false
      )
        .then((resp: any) => resolve(resp))
        .catch((err: any) => reject(err))
    })
  }

  getStep = (): string => {
    const {
      history: {
        location: { pathname, search },
      },
      checkProductArea,
    } = this.props
    const { dataForm, product } = this.state
    const insuredNumber: string[] = search.split('=')
    const internalName = product.products_internalName
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, internalName)
    let coverages: any
    let coveragesOptional: any
    let step = ''
    let flagAsegurado = false
    if (
      pathname === '/contratacion/nueva/mascotas/asegurado' &&
      dataForm.insureds.length > 0
    ) {
      flagAsegurado = this.coveragesOptional()
    }
    if (isHealthArea) step = 'showAll'
    else step = 'dataProtection'
    if (!isHealthArea && pathname.indexOf('beneficiarios') > -1) {
      step = 'questionnaires'
    } else if (isHealthArea && pathname.indexOf('precio') > -1) {
      step = 'questionnaires'
    } else if (pathname.indexOf('proteccion-datos') > -1) {
      step = 'showAll'
    } else if (
      pathname.indexOf('tomador') > -1 ||
      (insuredNumber[0] &&
        parseInt(insuredNumber[1]) !== dataForm.insureds.length - 1)
    ) {
      if (
        dataForm.insureds.length !== 0 &&
        pathname !== '/contratacion/nueva/mascotas/asegurado'
      ) {
        step = 'reviewInsured'
        // this.handleGoToDetail(insuredNumber[1] ? parseInt(insuredNumber[1]) + 1 : 0);
      } else if (dataForm.insureds.length === 0) {
        step = 'insured'
      } else if (flagAsegurado) {
        step = 'insuredBeneficiary'
      } else {
        step = 'dataProtection'
      }
    } else if (pathname.indexOf('cuestionarios-vida') > -1) {
      step = ''
    } else if (flagAsegurado) {
      step = 'insuredBeneficiary'
    } else {
      step = 'dataProtection'
    }

    //TODO: ESTO ERA LO QUE CANCELABA LA FIRMA AL PASAR DE PANTALLA EN MASCOTAS DECESOS Y VIDA
    /* if (
      hiringId &&
      data.mediatorAssociatedToPolicy &&
      data.signData &&
      data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED
    ) {
      // obtengo los datos almacenados de la póliza
      //
      // this.cancelSignatureReguest(hiringId, data);
      this.getDataPolicy(hiringId, data.mediatorAssociatedToPolicy)
        .then((res) => {

          const hiringDB = JSON.parse(JSON.stringify(res));
          const bodyHiringDB = hiringDB.body;

          if (bodyHiringDB) {
            const newData = this.getFormatForCompare(data, hiringDB.productType);
            const dbData = this.getFormatForCompare(bodyHiringDB, hiringDB.productType);
            // comparamos los dos objetos
            // si los datos de la póliza fueron modificados, 
            // solicitamos la cancelacion de la solicitud de la firma
            if (newData !== dbData) {

              this.removeSignAsync()
                .then(resp => data.signData = { signId: '', signRequestDate: '', signStatus: '' })
                .catch(err => console.log(err));

            }

          }
        });

    } */
    return step
  }

  changeValidateForm = (validateForm: boolean) =>
    this.setState({ validateForm })

  handleGoToDetail(index: number, forceGo: boolean = false) {
    const { haveAnyChanges, product } = this.state;
    const { checkProductArea } = this.props
    const internalName = product.products_internalName
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, internalName)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, internalName)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, internalName)
    const productType = getProductTypeFromURL(product.products_link)

    if (!isHealthArea && !isGIPArea && !isTravelArea) {
      this.setState({ goToDetail: index }, () => {
        this.setState({ goToDetail: undefined })
      })
    } else {
      if (
        !forceGo &&
        this.state.goToDetail === index &&
        this.props.history.location.pathname.includes('/asegurados')
      )
        return
      if (this.state.isReadOnly || forceGo) {
        if (isHealthArea)
          this.props.history.push(
            `/contratacion/nueva/salud/asegurados/${index}`
          )
        else if (isTravelArea) {
          this.props.history.push(
            `/contratacion/nueva/travel/${internalName}/asegurados/${index}`
          )
        } else if (isGIPArea) {
          this.props.history.push(
            `/contratacion/nueva/${internalName}/asegurados/${index}`
          )
        } else {
          this.props.history.push(
            `/contratacion/nueva/${productType}/asegurados/${index}`
          )
        }
        this.setState({
          goToDetail: index,
          changeInsuredModalShowIndex: undefined,
        })
      } else {
        if (haveAnyChanges) {
          this.setState({
            changeInsuredModalShow: true,
            changeInsuredModalShowIndex: index,
          })
        } else {
          this.handleGoToDetail(index, true)
        }
      }
    }
  }

  handleGoToDentalDetail(index: number, forceGo: boolean = false) {
    const { haveAnyChanges } = this.state

    if (
      !forceGo &&
      this.state.goToDetail === index &&
      this.props.history.location.pathname.includes('/asegurados')
    )
      return

    if (this.state.isReadOnly || forceGo) {
      this.props.history.push(`/contratacion/nueva/dental/asegurados/${index}`)
      this.setState({
        goToDetail: index,
        changeInsuredModalShowIndex: undefined,
      })
    } else {
      if (haveAnyChanges) {
        this.setState({
          changeInsuredModalShow: true,
          changeInsuredModalShowIndex: index,
        })
      } else {
        this.handleGoToDentalDetail(index, true)
      }
    }
  }

  async handleAddInsured(forceGo: boolean = false) {
    const { haveAnyChanges, product } = this.state;
    const { checkProductArea } = this.props
    const internalName = product.products_internalName
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, internalName)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, internalName)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, internalName)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, internalName)
    const productType = product.products_link
      ? getProductTypeFromURL(product.products_link)
      : ''

    if (forceGo) {
      if (isHealthArea)
        this.props.history.push('/contratacion/nueva/salud/asegurados?new=true')
      else if (isDentalArea)
        this.props.history.push(
          '/contratacion/nueva/dental/asegurados?new=true'
        )
      else if (isGIPArea) {
        this.props.history.push(
          `/contratacion/nueva/${internalName}/asegurados?new=true`
        )
      } else if (isTravelArea) {
        this.props.history.push(
          `/contratacion/nueva/travel/${internalName}/asegurados?new=true`
        )
      } else {
        this.props.history.push(
          `/contratacion/nueva/${productType}/asegurados?new=true`
        ) //
      }
      this.setState(
        { addInsured: true, goToDetail: -1, haveAnyChanges: false },
        () => this.setState({ addInsured: false })
      )
    } else {
      if (haveAnyChanges) {
        this.setState({
          changeInsuredModalShow: true,
          changeInsuredModalShowIndex: -1,
        })
      } else {
        this.handleAddInsured(true)
      }
    }
  }

  async handleSaveInsured() {
    this.setState({ saveInsured: true }, () =>
      this.setState({ saveInsured: false })
    )
  }

  handleEditQuestionnaire(index: number) {
    const { product } = this.state
    const { checkProductArea } = this.props
    const internalName = product.products_internalName
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, internalName)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, internalName)
    if (!isHealthArea && !isGIPArea) {
      this.setState({ goToDetail: index }, () => {
        this.setState({ goToDetail: undefined })
      })
    } else {
      if (isGIPArea) {
        this.props.history.push(`/contratacion/nueva/${internalName}/cuestionarios-salud/${index}`);
        this.setState({ goToDetail: index });
      }
      else {
        this.props.history.push(`/contratacion/nueva/salud/cuestionarios-salud/${index}`);
        this.setState({ goToDetail: index });
      }
    }
  }

  async handleSaveQuestionnaire() {
    this.setState({ saveQuestionnaire: true }, () =>
      this.setState({ saveQuestionnaire: false })
    )
  }

  async handleResumeHiring() {
    this.setState({ resumeHiring: true }, () =>
      this.setState({ resumeHiring: false })
    )
  }

  async handleTravelHiring() {
    const { setLoading, checkProductArea } = this.props
    const { data, prices, product } = this.state
    const isTravelArea = checkProductArea(
      AREA_NAMES.TRAVEL,
      product.products_internalName
    )

    try {
      const newData: IGetPricesRequest = this.getPricesRequest(data, [])
      newData.requestId = prices.requestNumber

      let hiring
      if (isTravelArea) {
        newData.payment.paymentMethod = 'TABA';
        newData.productType = product.products_internalName
        hiring = await HiringServices.travel({ data: newData, setLoading: setLoading });
      }

      if (hiring && hiring.warnings && hiring.warnings.length > 0) {
        const hiringOK =
          'No se emite la poliza por que no se ha realizado el pago de la misma:  COTIZACION GENERADA:'
        const warns = hiring.warnings.filter((w: any) => !w.includes(hiringOK))
        if (warns.length > 0) {
          this.openShowTravelWarningsHiring(SENDA_TRAVEL_WARNING_MAIN_TEXT, 'info', warns)
        }
      } else {
        data.emissionData = hiring
      }
      return hiring
    } catch (error) {
      this.checkError(error, true)
    }
  }

  async isPaid(payment: any): Promise<boolean> {
    try {
      const paymentStatus = (await PaymentServices.getPaymentStatus(
        payment
      ).then((response) => {
        return response.data
      })) as [GetPaymentStatusResponseDto]
      const lastPayment = paymentStatus[paymentStatus.length - 1]
      if (
        lastPayment.RespuestaOperacion.Ds_State === 'F' &&
        lastPayment.RespuestaOperacion.Ds_Response === '00'
      ) {
        return true
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async handlePayHiring() {
    const { setLoading } = this.props
    const { data, prices } = this.state
    if (
      data &&
      data.requestNumber &&
      prices &&
      prices.payments &&
      prices.payments[0] &&
      prices.payments[0].totalPremium
    ) {
      const payment = {
        setLoading: setLoading,
        loader: true,
        cantidad: parseFloat(
          (prices.payments[0].totalPremium * 100).toFixed(0)
        ), //Convierte euros a centimos
        moneda: CURRENCY.EUROS,
        pedido: data.requestNumber,
      }
      try {
        if ((await this.isPaid(payment)) === true) {
          //Already paid
          this.setState({
            showPaymentInfoModal: true,
          })
          return
        }
        const paymentURL = await PaymentServices.getPaymentLink(payment)
        await GeneralServices.setLoading(true)
        const paymentWindow = window.open(
          paymentURL,
          'Pago',
          'height=1200,width=800,popup=true,toolbar=0,menubar=0,location=0'
        )
        if (paymentWindow) {
          paymentWindow.focus()
        }
        var timer = setInterval(async () => {
          try {
            if (paymentWindow && paymentWindow.closed) {
              clearInterval(timer)
              await GeneralServices.setLoading(false)
              if ((await this.isPaid(payment)) === false) {
                let args = {
                  setLoading: setLoading,
                  loader: true,
                  data: {
                    email: data.taker.email,
                    pedido: data.requestNumber.toString(),
                    productName: this.state.product.products_name,
                    precio: prices.payments[0].totalPremium.toString(),
                    mediatorCode: this.props.user.code,
                    mediator: `${this.props.user.name} ${this.props.user.lastname1} ${this.props.user.lastname2}`,
                    starterDay: moment(this.state.prices.starterDay).format(
                      'DD/MM/YYYY'
                    ),
                  },
                }
                await PaymentServices.SendPaymentErrorMail(args)
                this.setState({
                  typeModal: 'error',
                  showPricesModal: true,
                  textModal:
                    'Ha habido un problema con el proceso del pago. Vuelve a intentarlo más tarde.',
                  listModal: [],
                })
                return
              }
              this.setState({
                showPaymentInfoModal: true,
              })
            }
          } catch (error) {
            this.setState({
              typeModal: 'error',
              showPricesModal: true,
              textModal:
                'Ha habido un problema con el proceso del pago. Vuelve a intentarlo más tarde.',
              listModal: [],
            })
          }
        }, 1000)
      } catch (e) {
        this.setState({
          typeModal: 'error',
          showPricesModal: true,
          textModal:
            'Ha habido un problema con la pasarela de pago. Vuelve a intentarlo más tarde.',
          listModal: [],
        })
      }
    } else {
      this.setState({
        typeModal: 'error',
        showPricesModal: true,
        textModal:
          'Ha habido un problema con la obtención de los precios. Vuelve a intentarlo más tarde.',
      })
    }
  }

  async handleHiringAfterPayment() {
    try {
      const { data, product } = this.state
      this.setState({
        showPaymentInfoModal: false,
      })
      //EMITIRPOL
      const hiringResponse = await this.handleTravelHiring();
      if (hiringResponse) {
        //CONTRATACION BACKGROUND
        if (hiringResponse.id && hiringResponse.id.policyId) {
          data.status = CONST_HIRING_STATUS.EMITIDA;
          data.updateStatus = CONST_HIRING_STATUS.EMITIDA;
          data.policyNumber = hiringResponse.id.policyId;
        } else {
          data.status = CONST_HIRING_STATUS.EN_REVISION;
          data.updateStatus = "REVISION";
          if (hiringResponse && hiringResponse.warnings && hiringResponse.warnings.length > 0) {
            const hiringOK = 'No se emite la poliza por que no se ha realizado el pago de la misma:  COTIZACION GENERADA:';
            const warns = hiringResponse.warnings.filter((w: any) => !w.includes(hiringOK));
            if (warns.length > 0) data.emisionError = warns;
          }
        }
        await this.saveHiring();

        //CONSRECIBO
        let policyIdList: Array<number> = []
        if (hiringResponse && hiringResponse.id && hiringResponse.id.policyId) {
          if (Array.isArray(hiringResponse.id.policyId)) {
            policyIdList = hiringResponse.id.policyId
          } else {
            policyIdList.push(hiringResponse.id.policyId)
          }
        }

        const paymentDate = moment()
        for (const policyId of policyIdList) {
          const receiptResponse = await ReceiptsServices.getReceiptConsrecibo(
            this.props.setLoading,
            true,
            {
              businessLine: product.ln_linea_negocio,
              id: policyId,
              searchType: 'POL',
            }
          )
          if (
            receiptResponse &&
            receiptResponse.data &&
            receiptResponse.data.recibos &&
            receiptResponse.data.recibos.recibo &&
            receiptResponse.data.recibos.recibo.nrecibo
          ) {
            this.state.data.recipes = receiptResponse.data.recibos
            //ESTRECIBO
            const updatedReceipt =
              await ReceiptsServices.updateReceiptStatusEstrecibo(
                this.props.setLoading,
                true,
                {
                  businessLine: product.ln_linea_negocio,
                  id: receiptResponse.data.recibos.recibo.nrecibo.split('/')[1],
                  searchType: 'REC',
                  idYear:
                    receiptResponse.data.recibos.recibo.nrecibo.split('/')[0],
                  payType: 'TABA',
                  payDate: paymentDate.format('DD-MM-YYYY'),
                  status: 'COBR',
                }
              )
          }
        }
        //Redirección al resumen
        this.props.history.push(
          TravelHireMenuHelper.getNextStepNavigationPath(
            StepsTravel.DetallePrevioContratacion,
            this.state.product.products_internalName
          )
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  async handleResumeDentalHiring() {
    this.setState({ resumeDentalHiring: true }, () =>
      this.setState({ resumeDentalHiring: false })
    )
  }

  async getPrecontractualDocument(): Promise<
    BuildPrecontractualDocumentResponseDto | undefined
  > {
    const { checkProductArea } = this.props
    const { data, product } = this.state
    const insuredIds = data.insureds.map((ins: any) => this.getInsuredId(ins))
    const isDentalArea = checkProductArea(
      AREA_NAMES.DENTAL,
      product.products_internalName
    )
    let doc
    if (Object.keys(product).length !== 0) {
      if (isDentalArea) {
        doc = await DentalService.buildPrecontractualDocument(
          this.props.setLoading,
          true,
          {
            COTIZACION: data.idcotiza,
            insuredIds: insuredIds,
            mediatorCode: this.props.user.code,
            paymentMethodDate: data.paymentMethodDate,
          },
          product.products_internalName
        ).catch((e) => {
          if (e.status === 409) {
            this.openExceptionModal(GESTDOC_DOC_NOT_AVAILABLE, 'error')
          }
          return e
        })
      } else {
        const docsLanguageValue = deepGet(data, "taker.policy.docsLanguage", "");
        let docsLanguageLabel = "";
        if (docsLanguageValue) {
          const docsLanguageLabels: { label: string, value: string }[] = deepGet(product, "productFields.step1.docsLanguage.options", []) || [];
          const option = docsLanguageLabels.find((lang) => lang.value === docsLanguageValue);
          docsLanguageLabel = option ? option.label : "";
        }

        const fetchData = {
          COTIZACION: data.idcotiza,
          insuredIds: insuredIds,
          mediatorCode: this.props.user.code,
          paymentMethodDate: data.paymentMethodDate,
          ...(docsLanguageLabel ? { language: docsLanguageLabel } : "")
        };

        doc = await HealthService.buildPrecontractualDocument(
          this.props.setLoading,
          true,
          fetchData,
          product.products_internalName
        ).catch((e) => {
          if (e.status === 409) {
            this.infoModalOpen(GESTDOC_DOC_NOT_AVAILABLE)
          }
          return e
        })
      }
    }

    if (doc && !doc.status) {
      return doc
    } else {
      return undefined
    }
  }

  async getPrecontractualDocumentDental(): Promise<
    BuildPrecontractualDocumentResponseDto | undefined
  > {
    const { data, product } = this.state
    const insuredIds = data.insureds.map((ins: any) => ins.docNumber)
    const doc = await DentalService.buildPrecontractualDocument(
      this.props.setLoading,
      true,
      {
        COTIZACION: data.idcotiza,
        insuredIds: insuredIds,
        mediatorCode: this.props.user.code,
        paymentMethodDate: data.paymentMethodDate,
      },
      product.products_internalName
    ).catch((e) => {
      if (e.status === 409) {
        this.openExceptionModal(GESTDOC_DOC_NOT_AVAILABLE, 'error')
      }
      return e
    })

    if (doc && !doc.status) {
      return doc
    } else {
      return undefined
    }
  }

  getInsuredId(ins: any) {
    if (
      (ins.docType === 'PAS' || ins.docType === 'PASP') &&
      ins.docNumber &&
      ins.docNumber.length < PASSPORT_MIN_DIGITS_GESTDOC
    ) {
      return (
        new Array(PASSPORT_MIN_DIGITS_GESTDOC - ins.docNumber.length)
          .fill('0')
          .join('') + ins.docNumber
      )
    } else return ins.docNumber
  }

  async handleDownloadPrecontractualDocument() {
    const { product } = this.state
    const { checkProductArea } = this.props
    const isDentalArea = checkProductArea(
      AREA_NAMES.DENTAL,
      product.products_internalName
    )

    let doc
    try {
      if (isDentalArea) {
        doc = await this.getPrecontractualDocumentDental()
      } else {
        doc = await this.getPrecontractualDocument()
      }

      if (doc) downloadFile(doc.fileData, doc.fileName, doc.fileType)
    } catch (error) {
      this.setState({ downloadError: true })
    }
  }

  async getGipPrecontractualDocument(): Promise<BuildGipPrecontractualDocumentResponseDto> {
    const { data, product } = this.state
    let questionnaires = data.questionnaires
    const insuredData = data.insureds.map((ins: any) => ({
      insuredName: ins.name + ' ' + ins.lastname1 + ' ' + ins.lastname2,
      insuredGender: ins.gender,
      insuredDocNumber: ins.docNumber,
      insuredBirthDate: ins.birthDate,
      questionnaireData: questionnaires.find(
        (insuredQuestionnaire: any) =>
          insuredQuestionnaire.insuredDocNumber === ins.docNumber
      ),
    }))
    const doc = await GipServices.buildGipPrecontractualDocument(
      this.props.setLoading,
      true,
      {
        insuredData: insuredData,
        mediatorCode: this.props.user.code,
      },
      product.products_internalName
    )

    return doc
  }

  async handleDownloadGipPrecontractualDocument() {
    const doc: BuildPrecontractualDocumentResponseDto =
      await this.getGipPrecontractualDocument()
    downloadFile(doc.fileData, doc.fileName, doc.fileType)
  }

  async handleSignPrecontractualDocument() {
    const { data, product } = this.state
    const { setLoading, user, mediator } = this.props
    let canSendSignRequest = true
    // si ya se ha enviado una solicitud
    // se cancela la solicitud anterior
    if (data.signData && data.signData.signId && data.signData.signId !== '') {
      canSendSignRequest = false
      const checkSigningResponse = await SigningServices.getSignRequest(
        setLoading,
        true,
        data.signData.signId
      )

      if (
        checkSigningResponse.status === SIGN_HIRING_STATUS_TYPE.REJECTED ||
        checkSigningResponse.status === SIGN_HIRING_STATUS_TYPE.CANCELLED ||
        checkSigningResponse.status === SIGN_HIRING_STATUS_TYPE.EXPIRED ||
        checkSigningResponse.status === SIGN_HIRING_STATUS_TYPE.SIGNED ||
        checkSigningResponse.status === SIGN_HIRING_STATUS_TYPE.FAILED
      ) {
        data.signData.signStatus = ''
        data.signData.signId = ''
        data.signData.signRequestDate = ''
        canSendSignRequest = true
      } else {
        const deleteSigningResponse = await SigningServices.removeSignRequest(
          setLoading,
          true,
          data.signData.signId,
          { reason: 'CANCELADA PARA ENVÍO DE NUEVA PETICIÓN' }
        )

        if (
          deleteSigningResponse.status === 200 ||
          deleteSigningResponse.status === 204
        ) {
          data.signData.signStatus = ''
          data.signData.signId = ''
          data.signData.signRequestDate = ''
          canSendSignRequest = true
        } else
          this.openExceptionModal(
            ERROR_MESSAGES['unknow-error-sign-not-deleted'],
            'error'
          )
      }
    }
    if (canSendSignRequest) {
      const doc = await this.getPrecontractualDocument()

      if (doc) {
        const documentBase64: string = Buffer.from(doc.fileData).toString(
          'base64'
        )

        let signData: SigningRequestDto = {
          signingRequestType: REACT_APP_SIGN_REQUEST_TYPE,
          externalProvider: REACT_APP_SIGN_PROVIDER,
          subject: REACT_APP_SIGN_SUBJECT,
          documentName: doc.fileName,
          documentBase64: documentBase64,
          signers: [
            {
              fullName: `${data.taker.name} ${data.taker.lastname1} ${data.taker.lastname2}`,
              identityDocument: data.taker.docNumber,
              phoneNumber: data.taker.mobilePhone.replace(/\s/g, ''),
              email: data.taker.email,
            }
          ],
          language: 'es',
          uploadToGitDoc: true,
          gitDocParameters: {
            topic: 'Firma Precontractual',
            subject: 'Firma Precontractual',
            fullname: `${data.taker.name} ${data.taker.lastname1} ${data.taker.lastname2}`,
            policyCode: data.idcotiza ? data.idcotiza : '',
            collective: Number(product.products_colectivo),
            order: '12345', // (max 5 length) TODO: Esto no se sabe de donde sacarlo
            nif: data.taker.docNumber,
          },
        }

        const signingResponse: SigningResponseDto =
          await SigningServices.postSignRequest(setLoading, true, signData)
            .then((data: any) => {
              return data
            })
            .catch((e) => {
              throw e
            })

        if (signingResponse.id) {
          //const signStatusResponse = await SigningServices.getSignRequest(setLoading, true, signingResponse.id);
          data.signData = {
            signId: signingResponse.id,
            signRequestDate: new Date(),
            signStatus: SIGN_HIRING_STATUS_TYPE.REQUESTED,
          }

          const buffer = Buffer.from(documentBase64, 'base64')
          const file: File = new File(
            [buffer],
            'DocumentacionPrecontractual.pdf'
          )

          const metadata = GestDoc.getFirmaSolicitudSeguroRequest(
            false,
            user,
            data.taker,
            data.idcotiza,
            data.insureds,
            product.products_colectivo,
            product.products_internalName,
            product.products_combinacion_comercial,
            mediator.nif
          ).fileInfo

          if (file) {
            let response: GestDocFileInfoResponseDto | null
            // comprobamos si ya hemos subido la documentación a alfreso
            // un cuyo caso, subimos una nueva vesión
            if (!data.signatureNode) {
              response = await GestDoc.uploadDocument(
                NOTA_INFORMATIVA_PARENT_NODE_ID,
                file,
                metadata,
                this.props.setLoading
              )
            } else {
              // nueva vesión de la documentación precontractual
              response = await GestDoc.uploadNewVersionDocument(
                data.signatureNode,
                file,
                setLoading
              )
            }
            if (response) {
              data.physicalSignatureNode = response.entry.id
              data.signatureNode = response.entry.id
            }
          }

          var jwt = require('jsonwebtoken')
          var decoded = await jwt.verify(localStorage.accessToken, 'TODO')

          data.user = {
            user: decoded.user,
          }
          data.noRedirect = false
          await this.saveHiring(true)

          this.setState({
            ...this.state,
            showSignedInfoModal: true,
            leftInfoModalClassName: '',
            leftInfoModalTitle: 'Firma solicitada al tomador.',
            leftInfoModalText:
              'La póliza se emitirá una vez se haya realizado la firma aceptando el documento precontractual.',
            leftInfoModalCloseButtonText: 'Salir',
            leftInfoModalClick: () => {
              this.props.history.replace('/contratacion/pendiente')
            },
          })
        } else {
          this.openExceptionModal(ERROR_MESSAGES['unknow-error'], 'error')
        }
      }
    }
  }

  async checkSignBeforeGipPrecontractualDocument() {
    const { data } = this.state

    if (data.signData && data.signData.signId && data.signData.signId !== '') {
      this.setState({
        ...this.state,
        showNewSignInfoModal: true,
        leftInfoModalClassName: '',
        leftInfoModalTitle: 'Envío de solicitud de firma',
        leftInfoModalText:
          'Cualquier petición de firma existente asociada a esta contratación será cancelada. ¿Desea continuar?',
        leftInfoModalCloseButtonText: 'Cerrar',
        leftInfoModalButtonText: 'Aceptar',
        leftInfoModalClick: () => {
          this.handleSignGipPrecontractualDocument()
        },
      })
    } else this.handleSignGipPrecontractualDocument()
  }

  async checkSignBeforePrecontractualDocument() {
    const { data } = this.state

    if (data.signData && data.signData.signId && data.signData.signId !== '') {
      this.setState({
        ...this.state,
        showNewSignInfoModal: true,
        leftInfoModalClassName: '',
        leftInfoModalTitle: 'Envío de solicitud de firma',
        leftInfoModalText:
          'Cualquier petición de firma existente asociada a esta contratación será cancelada. ¿Desea continuar?',
        leftInfoModalCloseButtonText: 'Cerrar',
        leftInfoModalButtonText: 'Aceptar',
        leftInfoModalClick: () => {
          this.handleSignPrecontractualDocument()
        },
      })
    } else this.handleSignPrecontractualDocument()
  }

  async handleSignGipPrecontractualDocument() {
    const { data, hiringId } = this.state
    const { setLoading } = this.props
    let canSendSignRequest = true
    let cancelledSignature = false
    if (data.signData && data.signData.signId && data.signData.signId !== '') {
      canSendSignRequest = false
      const checkSigningResponse = await SigningServices.getSignRequest(
        setLoading,
        true,
        this.state.data.signData.signId
      )

      if (
        checkSigningResponse.status === SIGN_HIRING_STATUS_TYPE.REJECTED ||
        checkSigningResponse.status === SIGN_HIRING_STATUS_TYPE.CANCELLED ||
        checkSigningResponse.status === SIGN_HIRING_STATUS_TYPE.EXPIRED ||
        checkSigningResponse.status === SIGN_HIRING_STATUS_TYPE.SIGNED ||
        checkSigningResponse.status === SIGN_HIRING_STATUS_TYPE.FAILED
      ) {
        data.sendToSign = false
        data.signData.signStatus = ''
        data.signData.signId = ''
        data.signData.signRequestDate = ''
        canSendSignRequest = true
        cancelledSignature = true
      } else {
        const deleteSigningResponse = await SigningServices.removeSignRequest(
          setLoading,
          true,
          this.state.data.signData.signId,
          { reason: 'CANCELADA PARA ENVÍO DE NUEVA PETICIÓN' }
        )

        if (deleteSigningResponse.status == 200) {
          data.sendToSign = false
          data.signData.signStatus = ''
          data.signData.signId = ''
          data.signData.signRequestDate = ''
          canSendSignRequest = true
          cancelledSignature = true
        } else
          this.openExceptionModal(
            ERROR_MESSAGES['unknow-error-sign-not-deleted'],
            'error'
          )
      }
    }

    if (canSendSignRequest) {
      const doc: BuildPrecontractualDocumentResponseDto =
        await this.getGipPrecontractualDocument()
      const documentBase64: string = Buffer.from(doc.fileData).toString(
        'base64'
      )

      let signData: SigningRequestDto = {
        signingRequestType: REACT_APP_SIGN_REQUEST_TYPE,
        externalProvider: REACT_APP_SIGN_PROVIDER,
        subject: REACT_APP_SIGN_SUBJECT,
        documentName: doc.fileName,
        documentBase64: documentBase64,
        signers: [
          {
            fullName: `${data.taker.name} ${data.taker.lastname1} ${data.taker.lastname2}`,
            identityDocument: data.taker.docNumber,
            phoneNumber: data.taker.mobilePhone.replace(/\s/g, ''),
            email: data.taker.email,
          }
        ],
        language: 'es',
        uploadToGitDoc: true,
        gitDocParameters: {
          topic: 'Firma Precontractual',
          subject: 'Firma Precontractual',
          fullname: `${data.taker.name} ${data.taker.lastname1} ${data.taker.lastname2}`,
          policyCode: hiringId.toString(),
          collective: data.policyData.colective,
          order: '12345', // (max 5 length) TODO: Esto no se sabe de donde sacarlo
          nif: data.taker.docNumber,
        },
      }

      const signingResponse: SigningResponseDto =
        await SigningServices.postSignRequest(setLoading, true, signData)
          .then((data: any) => {
            return data
          })
          .catch((e) => {
            throw e
          })

      if (signingResponse.id) {
        data.sendToSign = true
        data.signData.signId = signingResponse.id
        data.signData.signRequestDate = new Date().toISOString()
        data.signData.signStatus = SIGN_HIRING_STATUS_TYPE.REQUESTED
        data.noRedirect = false

        await this.saveHiring(true)

        this.setState({
          ...this.state,
          showSignedInfoModal: true,
          leftInfoModalClassName: '',
          leftInfoModalTitle: 'Firma solicitada al tomador.',
          leftInfoModalText:
            'La solicitud se enviará para su tramitación una vez se haya realizado la firma aceptando el documento precontractual.',
          leftInfoModalCloseButtonText: 'Salir',
          leftInfoModalClick: () => {
            this.props.history.replace('/contratacion/pendiente')
          },
        })
      } else {
        if (cancelledSignature)
          this.openExceptionModal(
            ERROR_MESSAGES['unknow-error-sign-deleted'],
            'error'
          )
        else this.openExceptionModal(ERROR_MESSAGES['unknow-error'], 'error')
      }
    }
  }

  checkQuestionnairesCompleted = () => {
    const { data } = this.state
    let canContinue = true
    data.insureds.forEach((ins: any) => {
      if (
        !ins.docNumber ||
        data.questionnaires.find(
          (insuredQuestionnaire: any) =>
            insuredQuestionnaire.insuredDocNumber === ins.docNumber
        ) === undefined
      )
        canContinue = false
    })
    return canContinue
  }

  async unlockGipForm() {
    const { data } = this.state
    if (data.signData && data.signData.signId && data.signData.signId !== '') {
      this.setState({
        ...this.state,
        showNewSignInfoModal: true,
        leftInfoModalClassName: '',
        leftInfoModalTitle: 'Modificación de datos',
        leftInfoModalText:
          'Cualquier petición de firma existente asociada a esta contratación será cancelada. ¿Desea continuar?',
        leftInfoModalCloseButtonText: 'Aceptar',
        leftInfoModalButtonText: 'Aceptar',
        leftInfoModalClick: () => {
          this.removeSignRequestData()
        },
      })
    } else this.removeSignRequestData()
  }

  async removeSignRequestData(reason?: string, saveHiring: boolean = true) {
    const { setLoading } = this.props
    const { data } = this.state

    let canUnlock = true
    if (data.signData && data.signData.signId && data.signData.signId !== '') {
      canUnlock = false
      const checkSigningResponse = await SigningServices.getSignRequest(
        setLoading,
        true,
        data.signData.signId
      )

      if (isValidStatusToCancelSignRequest(checkSigningResponse.status)) {
        const deleteSigningResponse = await SigningServices.removeSignRequest(
          setLoading,
          true,
          data.signData.signId,
          { reason: reason ? reason : 'CANCELADA PARA MODIFICACIÓN DE DATOS' }
        )

        if (deleteSigningResponse.status == 200) {
          if (saveHiring) {
            data.sendToSign = false
            data.signData.signStatus = ''
            data.signData.signId = ''
            data.signData.signRequestDate = ''
            canUnlock = true
            await this.saveHiring(true)
          }
        } else
          this.openExceptionModal(
            ERROR_MESSAGES['unknow-error-sign-not-deleted'],
            'error'
          )
      } else {
        data.sendToSign = false
        data.signData.signStatus = ''
        data.signData.signId = ''
        data.signData.signRequestDate = ''
        canUnlock = true
        if (saveHiring) await this.saveHiring(true)
      }

      if (canUnlock && saveHiring) {
        this.setState({ isReadOnly: false })
        this.setState({
          ...this.state,
          showSendInfoModal: true,
          leftInfoModalClassName: '',
          leftInfoModalTitle: 'Modificación permitida',
          leftInfoModalText:
            'Recuerde que se ha de solicitar una nueva firma una vez modificados los datos',
          leftInfoModalCloseButtonText: 'Cerrar',
          leftInfoModalButtonText: 'Aceptar',
          leftInfoModalClick: () => {},
        })
      }
    }
  }

  async handleSendMailPrecontractualDocument(showModal: boolean = true) {
    const { data, product } = this.state
    const { checkProductArea } = this.props
    const isHealthArea = checkProductArea(
      AREA_NAMES.HEALTH,
      product.products_internalName
    )
    const isTravelArea = checkProductArea(
      AREA_NAMES.TRAVEL,
      product.products_internalName
    )
    const isDentalArea = checkProductArea(
      AREA_NAMES.DENTAL,
      product.products_internalName
    )

    if (isHealthArea || isDentalArea) {
      const insuredIds = data.insureds.map((ins: any) => this.getInsuredId(ins))

      try {
        let docsLanguageLabel = "";
        if (isHealthArea) {
          const docsLanguageValue = deepGet(data, "taker.policy.docsLanguage", "");
          if (docsLanguageValue) {
            const docsLanguageLabels: { label: string, value: string }[] = deepGet(product, "productFields.step1.docsLanguage.options", []) || [];
            const option = docsLanguageLabels.find((lang) => lang.value === docsLanguageValue);
            docsLanguageLabel = option ? option.label : "";
          }
        }

        const mailSendedOk = await HealthService.sendPrecontractualDocument(
          this.props.setLoading,
          true,
          {
            COTIZACION: data.idcotiza,
            insuredIds: insuredIds,
            mediatorCode: this.props.user.code,
            email: data.taker.email,
            mediatorName: data.mediatorData && data.mediatorData.name
              ? data.mediatorData.name
              : `${this.props.user.name} ${this.props.user.lastname1} ${this.props.user.lastname2}`,
            paymentMethodDate: data.paymentMethodDate,
            ...(docsLanguageLabel ? { language: docsLanguageLabel } : "")
          },
          product.products_internalName
        ).catch((e) => {
          if (e.status === 409) {
            this.infoModalOpen(GESTDOC_DOC_NOT_AVAILABLE)
          }
          console.log(e)
          this.openExceptionModal(
            'Se ha producido un error al enviar la documentación precontractual.',
            'error'
          )
        })
        if (showModal && mailSendedOk) {
          this.setState({
            ...this.state,
            showSendInfoModal: true,
            leftInfoModalClassName: '',
            leftInfoModalTitle: 'La documentación se ha enviado por correo',
            leftInfoModalText:
              'El presupuesto se ha enviado satisfactoriamente al correo suministrado.',
            leftInfoModalCloseButtonText: 'Cerrar',
            leftInfoModalButtonText: 'Aceptar',
            leftInfoModalClick: () => {},
          })
        }
      } catch (ex) {
        this.openExceptionModal(
          'Se ha producido un error al enviar la documentación precontractual.',
          'error'
        )
      }
    } else if (isTravelArea) {
      const insuredIds = data.insureds.map((ins: any) => this.getInsuredId(ins))
      try {
        const mailSendedOk =
          await HiringServices.buildHiringPrecontractualDocument(
            this.props.setLoading,
            true,
            {
              policyType: product.products_internalName,
              insuredIds: insuredIds,
              mediatorCode: this.props.user.code,
              mediator: `${this.props.user.name} ${this.props.user.lastname1} ${this.props.user.lastname2}`,
              email: data.taker.email,
              requestNumber: data.requestNumber
                ? data.requestNumber
                : undefined,
            }
          ).catch((e) => {
            if (e.status === 409) {
              this.infoModalOpen(
                'La documentación se está generando. Por favor, inténtelo mas tarde.'
              )
            }
            console.log(e)
            this.openExceptionModal(
              'Se ha producido un error al enviar la documentación precontractual.',
              'error'
            )
          })
        if (showModal && mailSendedOk) {
          this.setState({
            ...this.state,
            showSendInfoModal: true,
            leftInfoModalClassName: '',
            leftInfoModalTitle: 'La documentación se ha enviado por correo',
            leftInfoModalText:
              'El presupuesto se ha enviado satisfactoriamente al correo suministrado.',
            leftInfoModalCloseButtonText: 'Cerrar',
            leftInfoModalButtonText: 'Aceptar',
            leftInfoModalClick: () => {},
          })
        }
      } catch (ex) {
        this.openExceptionModal(
          'Se ha producido un error al enviar la documentación precontractual.',
          'error'
        )
      }
    } else {
      let questionnaires = data.questionnaires
      const insuredData = data.insureds.map((ins: any) => ({
        insuredName: ins.name + ' ' + ins.lastname1 + ' ' + ins.lastname2,
        insuredGender: ins.gender,
        insuredDocNumber: ins.docNumber,
        insuredBirthDate: ins.birthDate,
        questionnaireData: questionnaires.find(
          (insuredQuestionnaire: any) =>
            insuredQuestionnaire.insuredDocNumber === ins.docNumber
        ),
      }))
      const doc = await GipServices.sendGipPrecontractualDocument(
        this.props.setLoading,
        true,
        {
          insuredData: insuredData,
          mediatorCode: this.props.user.code,
          email: data.taker.email,
          mediator: `${this.props.user.name} ${this.props.user.lastname1}`,
        },
        product.products_internalName
      )
      if (showModal && doc) {
        this.setState({
          ...this.state,
          showSendInfoModal: true,
          leftInfoModalClassName: '',
          leftInfoModalTitle: 'La documentación se ha enviado por correo',
          leftInfoModalText:
            'El presupuesto se ha enviado satisfactoriamente al correo suministrado.',
          leftInfoModalCloseButtonText: 'Cerrar',
          leftInfoModalButtonText: 'Aceptar',
          leftInfoModalClick: () => {},
        })
      }
    }
  }

  async handleSendMailDentalPrecontractualDocument() {
    const { data, product } = this.state

    const insuredIds = data.insureds.map((ins: any) => ins.docNumber)
    try {
      const mailSendedOk = await DentalService.sendPrecontractualDocument(
        this.props.setLoading,
        true,
        {
          COTIZACION: data.idcotiza,
          insuredIds: insuredIds,
          mediatorCode: this.props.user.code,
          email: data.taker.email,
          mediatorName: `${this.props.user.name} ${this.props.user.lastname1} ${this.props.user.lastname2}`,
          paymentMethodDate: data.paymentMethodDate,
        },
        product.products_internalName
      ).catch((e) => {
        if (e.status === 409) {
          this.openExceptionModal(GESTDOC_DOC_NOT_AVAILABLE, 'error')
        }
        console.log(e)
        this.openExceptionModal(
          'Se ha producido un error al enviar la documentación precontractual.',
          'error'
        )
      })
      if (mailSendedOk) {
        this.setState({
          ...this.state,
          showSendInfoModal: true,
          leftInfoModalClassName: '',
          leftInfoModalTitle: 'La documentación se ha enviado por correo',
          leftInfoModalText:
            'El presupuesto se ha enviado satisfactoriamente al correo suministrado.',
          leftInfoModalCloseButtonText: 'Cerrar',
          leftInfoModalButtonText: 'Aceptar',
          leftInfoModalClick: () => {},
        })
      }
    } catch (ex) {
      console.log(ex)
      this.openExceptionModal(
        'Se ha producido un error al enviar la documentación precontractual.',
        'error'
      )
    }
  }

  async haveInsuredSaved(saved: boolean) {
    const { haveAnyChanges } = this.state
    if (haveAnyChanges !== saved) this.setState({ haveAnyChanges: saved })
  }
  async OnAnyChange(changes: boolean) {
    const { haveAnyChanges } = this.state
    if (changes && !haveAnyChanges) {
      this.setState({ haveAnyChanges: true })
    }
  }
  coveragesOptional = () => {
    const { dataForm } = this.state
    const optional =
      dataForm &&
      dataForm.insureds &&
      dataForm.insureds.find((insured: IInsuredCoveragesDataForm) =>
        Object.keys(insured.coverages).some(
          (code: string) =>
            insured.coverages[code].complementary &&
            insured.coverages[code].value
        )
      )
    if (optional !== undefined) {
      return true
    }
    return false
  }

  selectedCoveragesOpt = () => {
    const { indexRemove, dataForm } = this.state
    if (indexRemove > -1) {
      let coverageOpt = 0
      const mascotsRemove = Object.keys(
        dataForm.insureds[indexRemove].coverages
      ).some(
        (code) =>
          dataForm.insureds[indexRemove].coverages[code].complementary &&
          dataForm.insureds[indexRemove].coverages[code].value
      )
      if (mascotsRemove) {
        dataForm.insureds.map((insured: any) => {
          const coveragesOptional = Object.keys(insured.coverages).some(
            (code) =>
              insured.coverages[code].complementary &&
              insured.coverages[code].value
          )
          if (coveragesOptional) {
            coverageOpt++
          }
        })
      }
      return coverageOpt
    }
    return -1
  }

  handleValidatePet = (goToValidatePet: boolean) => {
    this.setState({ goToValidatePet: goToValidatePet })
  }

  render() {
    const {
      showLeftInfoModal,
      data,
      validateForm,
      addInsured,
      dataForm,
      showExceptionModal,
      showWarningsHiring,
      showTravelWarningsHiring,
      showRetroactiveDate,
      showInfo,
      showInsuredModal,
      showHiringModal,
      showPaymentInfoModal,
      saveHiring,
      responsePolicy,
      changeState,
      isEdit,
      typeModal,
      textModal,
      listModal,
      secondaryText,
      showPricesModal,
      fromSimulation,
      isReadOnly,
      goToDetail,
      infoModalShow,
      infoModalText,
      selectedMediatorCode,
      savingEntityError,
      showCancelHiringModal,
      downloadError,
      downloadErrorMessage,
      showSendInfoModal,
      showNewSignInfoModal,
      showSignedInfoModal,
      leftInfoModalClick,
      leftInfoModalTitle,
      leftInfoModalText,
      cancelSignModalShow,
      product,
      setProductsDataError,
      productPayTypes,
      showListCompaniesException,
    } = this.state
    const { checkProductArea, history } = this.props
    const pathname = this.props.history.location.pathname
    const showGoBack = false
    const isSignedDocument =
      (data.signId && data.signId !== '') ||
      (data.paymentMethod && data.paymentMethod.isPhysicalSignature)
    const isGIPArea = checkProductArea(
      AREA_NAMES.GIP,
      product.products_internalName
    )
    const isLifeArea = checkProductArea(
      AREA_NAMES.LIFE,
      product.products_internalName
    )
    const isHealthArea = checkProductArea(
      AREA_NAMES.HEALTH,
      product.products_internalName
    )
    const isTravelArea = checkProductArea(
      AREA_NAMES.TRAVEL,
      product.products_internalName
    )
    const isDentalArea = checkProductArea(
      AREA_NAMES.DENTAL,
      product.products_internalName
    )
    const isPetArea = checkProductArea(
      AREA_NAMES.PET,
      product.products_internalName
    )
    const productType = product.products_link
      ? getProductTypeFromURL(product.products_link)
      : ''

    return (
      <NewHiringContainer>
        <PolicyContext.Provider
          value={{
            policyInfo: {
              changePolicyType: this.changePolicyType,
            },
            validateForm: this.validateForm,
            setHealthData: this.setHealthData,
            setTravelData: this.setTravelData,
            setDentalData: this.setDentalData,
            changeData: this.changeData,
            changeValidateForm: this.changeValidateForm,
            setProtectionData: this.setProtectionData,
            lengthInsured: data.insureds ? data.insureds.length : 0,
            data: dataForm,
            handleNewChanges: this.handleNewChanges,
            setMediatorSelectedCode: this.setMediatorSelectedCode,
            product: product,
            productPayTypes: productPayTypes,
          }}
        >
          {pathname.indexOf('miembros') === -1 && showGoBack ? (
            <div className='new-hiring-flow-name'>
              <GoBack text='Volver' />
            </div>
          ) : (
            pathname.indexOf('miembros') === -1 && (
              <div className='new-hiring-subtitle-2'>
                <BreadcrumbText>{'NUEVA CONTRATACIÓN'}</BreadcrumbText>
              </div>
            )
          )}
          <Switch>
            {!isHealthArea && !isGIPArea && !isDentalArea && (
              <Route
                exact
                path={`/contratacion/nueva/${productType}/proteccion-datos`}
                render={(props) => (
                  <CrossProtectionData
                    {...props}
                    data={data}
                    goBack={() =>
                      isPetArea && this.coveragesOptional()
                        ? this.newStep('insuredBeneficiary')
                        : this.newStep('insured', true)
                    }
                    newStep={() => this.newStep(this.getStep())}
                    onlyView={true}
                  />               
                )}
              />
            )}

            <Route
              exact
              path='/contratacion/nueva/mascotas/asegurado'
              render={(props) => (
                <MascotsInsuredData
                  linkDetail='/contratacion/nueva/'
                  goToDetail={goToDetail}
                  {...props}
                  goBack={() => this.newStep('taker')}
                  newStep={() => this.newStep(this.getStep())}
                  companiesException={() =>
                    this.openListCompaniesExpectionModal()
                  }
                  goToValidatePet={this.state.goToValidatePet}
                  validatePet={(goToValidatePet: boolean) =>
                    this.handleValidatePet(goToValidatePet)
                  }
                />
              )}
            />
            <Route
              path='/contratacion/nueva/mascotas/asegurado-beneficiario'
              render={(props) => (
                <DataHiring
                  linkDetail='/contratacion/nueva/'
                  goToDetail={goToDetail}
                  changeState={changeState}
                  insured={true}
                  hiring={true}
                  nationality={true}
                  noProfession={true}
                  address2={true}
                  goBack={() => this.newStep('insured', true)}
                  newStep={() => this.newStep(this.getStep())}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path='/contratacion/nueva/mascotas/tomador'
              render={(props) => (
                <DataHiring
                  linkDetail='/contratacion/nueva/'
                  goToDetail={goToDetail}
                  changeState={changeState}
                  nationality={true}
                  noRequired={true}
                  noProfession={true}
                  civilStatus={true}
                  fromSimulation={fromSimulation}
                  coverages={COVERAGES_PETS}
                  hiring={true}
                  newStep={() => this.newStep(this.getStep())}
                  showInfo={showInfo}
                  selectedMediatorCode={selectedMediatorCode}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path='/contratacion/nueva/mascotas/resultado'
              render={(props) => (
                <ResultLayout
                  responseHiring={responsePolicy}
                  dataForm={dataForm}
                  data={data}
                  selectedMediatorCode={selectedMediatorCode}
                  {...props}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/decesos/tomador'
              render={(props) => (
                <DataHiring
                  linkDetail='/contratacion/nueva/'
                  goToDetail={goToDetail}
                  changeState={changeState}
                  nationality={true}
                  // civilStatus={true}
                  hiring={true}
                  fromSimulation={fromSimulation}
                  noProfession={true}
                  address2={true}
                  newStep={() => this.newStep(this.getStep())}
                  showInfo={showInfo}
                  selectedMediatorCode={selectedMediatorCode}
                  {...props}
                />
              )}
            />
            <Route
              path='/contratacion/nueva/decesos/asegurado'
              render={(props) => (
                <DataHiring
                  linkDetail='/contratacion/nueva/'
                  goToDetail={goToDetail}
                  changeState={changeState}
                  insured={true}
                  hiring={true}
                  nationality={true}
                  noProfession={true}
                  address2={true}
                  newStep={() => this.newStep(this.getStep())}
                  goBack={() => this.newStep('taker')}
                  {...props}
                />
              )}
            />
            <Route
              path='/contratacion/nueva/decesos/resultado'
              render={(props) => (
                <ResultLayout
                  responseHiring={responsePolicy}
                  dataForm={dataForm}
                  data={data}
                  selectedMediatorCode={selectedMediatorCode}
                  {...props}
                />
              )}
            />

            {/* Poliza de vida */}
            <Route
              path='/contratacion/nueva/vida/tomador'
              render={(props) => (
                <DataHiring
                  linkDetail='/contratacion/nueva/'
                  goToDetail={goToDetail}
                  changeState={changeState}
                  hiring={true}
                  nationality={true}
                  fromSimulation={fromSimulation}
                  showInfo={showInfo}
                  capital={{
                    min: 18000,
                    max: 300000,
                  }}
                  newStep={() => this.newStep(this.getStep())}
                  selectedMediatorCode={selectedMediatorCode}
                  {...props}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/vida/asegurado'
              render={(props) => (
                <DataHiring
                  linkDetail='/contratacion/nueva/'
                  goToDetail={goToDetail}
                  changeState={changeState}
                  hiring={true}
                  nationality={true}
                  insured={true}
                  capital={{
                    min: 18000,
                    max: 300000,
                  }}
                  newStep={() => this.newStep(this.getStep())}
                  {...props}
                  goBack={() => this.newStep('taker')}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/vida/beneficiarios'
              render={(props) => (
                <BeneficiariesLayout
                  changeBeneficiaries={(beneficiaries: any) =>
                    this.setBeneficiaries(beneficiaries)
                  }
                  changeLoan={(loan: any, active: boolean) =>
                    this.setLoan(loan, active)
                  }
                  changeValidateForm={(isValid: boolean) =>
                    this.changeValidateForm(isValid)
                  }
                  newStep={() => this.newStep(this.getStep())}
                  isDisabled={!validateForm}
                  {...props}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/vida/cuestionarios-vida/:index'
              render={(props) => (
                <QuestionnaireLayout
                  changeQuestionary={(
                    index: number,
                    questionnaires: any[],
                    isOk?: boolean
                  ) => this.setQuestionnaires(index, questionnaires, isOk)}
                  data={data}
                  setProductsData={() => this.setProductsData()}
                  {...props}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/vida/resultado'
              render={(props) => (
                <ResultLayout
                  responseHiring={responsePolicy}
                  dataForm={dataForm}
                  data={data}
                  selectedMediatorCode={selectedMediatorCode}
                  {...props}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/miembros'
              render={(props) => (
                <InsuredMembersLayout
                  disabled={true}
                  {...props}
                  data={data}
                  hiring={true}
                  removeInsured={(index: number) =>
                    this.handleLeftInfoModalRemove(index)
                  }
                  checkInsuredsForBeneficiary={this.checkInsuredsForBeneficiary}
                  addNewInsured={() => this.handleLeftInfoModal()}
                  action={() =>
                    isLifeArea
                      ? this.newStep('beneficiary')
                      : this.openHiringModal()
                  }
                  actionSave={() => this.openSaveModal()}
                  linkDetail='/contratacion/nueva/'
                  steps={isLifeArea ? [0, 0, 0, 0, 0] : [0, 0, 0, 0]}
                  positionStep={isLifeArea ? 3 : 2}
                  downloadDocs={this.downloadDocs}
                  sendSign={this.sendSign}
                  generateInsuranceProposal={this.getInsuranceProposal}
                  generateInsuranceRequest={this.getInsuranceRequest}
                  coverages={this.props.coverages}
                  actionCancel={() => this.openCancelHiringModal()}
                  setProductsData={() => this.setProductsData()}
                  disableDownloadDocs={setProductsDataError}
                  disableSendSign={setProductsDataError}
                />
              )}
            />
            <Route
              exact
              path='/contratacion/nueva'
              render={(props) => (
                <ChoosePolicy
                  showInfo={showInfo}
                  setShowInfo={(value: boolean) =>
                    this.setState({ showInfo: value })
                  }
                  setLoading={this.props.setLoading}
                  hasInformativeNoteLife={this.props.hasInformativeNoteLife}
                  hasInformativeNoteNotLife={
                    this.props.hasInformativeNoteNotLife
                  }
                  user={this.props.user}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/salud/tomador'
              render={(props) => (
                <TakerDataLayout
                  {...props}
                  isReadOnly={isReadOnly}
                  data={data}
                  onGotoDetail={goToDetail}
                  saveHiring={this.saveHiring}
                  selectedMediatorCode={selectedMediatorCode}
                  mediator={this.props.mediator}
                  removeSign={this.removeSign}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/salud/asegurados/:index?'
              render={(props) => (
                <InsuredDataLayout
                  {...props}
                  isReadOnly={isReadOnly}
                  saveInsured={this.state.saveInsured}
                  newInsured={this.state.addInsured}
                  removeInsured={this.state.removeInsured}
                  indexRemove={this.state.indexRemove}
                  data={data}
                  haveInsuredSaved={(saved: boolean) =>
                    this.haveInsuredSaved(saved)
                  }
                  onGotoDetail={goToDetail}
                  saveHiring={this.saveHiring}
                  goToInsured={(index: number) =>
                    this.handleGoToDetail(index, true)
                  }
                  removeSign={this.removeSign}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/salud/garantias'
              render={(props) => (
                <GuaranteeDataLayout
                  {...props}
                  data={data}
                  changeBeneficiaries={(loan: any) =>
                    this.setBeneficiaries(loan)
                  }
                  isReadOnly={isReadOnly}
                  changeLoan={this.setLoan}
                  irrevocableHeirs={true}
                  newStep={() => this.newStep('documentacion-requerida')}
                  saveHiring={this.saveHiring}
                  goToInsureds={() => this.handleGoToDetail(0, true)}
                  removeSign={this.removeSign}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/salud/documentacion-requerida'
              render={(props) => (
                <RequiredDocDataLayout
                  {...props}
                  taker={data.taker}
                  insureds={data.insureds}
                  cotizacion={data.idcotiza}
                  data={data}
                  readOnly={isReadOnly}
                  saveHiring={this.saveHiring}
                  removeSign={this.removeSign}
                  nextStep={HealthHireMenuHelper}
                  step={StepsSalud.DocumentacionRequerida}
                  service={HealthService}
                  areaEndpoint="sendaHealth"
                />
              )}
            />

            <Route
              path='/contratacion/nueva/salud/proteccion-datos'
              render={(props) => (
                <CrossProtectionData
                  {...props}
                  cotizacion={data.idcotiza}
                  data={data}
                  readOnly={isReadOnly}
                  saveHiring={this.saveHiring}
                  setLoading={data.setLoading}
                  removeSign={this.removeSign}
                  nextStep={HealthHireMenuHelper.getNextStepNavigationPath(Steps.ProteccionDatos)}
                  previousStep={HealthHireMenuHelper.getPreviousStepNavigationPath(Steps.ProteccionDatos)}
                  area={"sendaHealth"}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/salud/precio'
              render={(props) => (
                <PriceDataLayout
                  newStep={() => this.newStep(this.getStep())}
                  {...props}
                  isReadOnly={isReadOnly}
                  data={data}
                  saveHiring={this.saveHiring}
                  setLoading={data.setLoading}
                  removeSign={this.removeSign}
                  user={this.props.user}
                  productPayTypes={productPayTypes}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/salud/cuestionarios-salud/:index'
              render={(props) => (
                <QuestionnaireDataLayout
                  {...props}
                  isReadOnly={isReadOnly}
                  data={data}
                  onGotoDetail={goToDetail}
                  saveQuestionnaire={this.state.saveQuestionnaire}
                  saveHiring={this.saveHiring}
                  newStep={() => this.newStep(this.getStep())}
                  cotizacion={data.idcotiza}
                  removeSign={this.removeSign}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/salud/medios-pago'
              render={(props) => (
                <PaymentMethodsLayout
                  paymentMethod={data.paymentMethod}
                  cotizacion={data.idcotiza}
                  data={data}
                  fechaEfecto={data.taker.policy && data.taker.policy.startDate}
                  takerDocumentId={data.taker.docNumber}
                  isReadOnly={isReadOnly}
                  saveHiring={this.saveHiring}
                  insuredsCopay={data.insureds}
                  setLoading={data.setLoading}
                  policy={data.policy}
                  removeSign={this.removeSign}
                  {...props}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/salud/resultado'
              render={(props) => (
                <ResultDataLayout
                  {...props}
                  data={data}
                  resumeHiring={this.state.resumeHiring}
                  setLoading={this.props.setLoading}
                  saveHiring={(saveStep: boolean) => this.saveHiring(saveStep)}
                  cotizacion={data.idcotiza}
                  removeSign={this.removeSign}
                />
              )}
            />
            {/* Rutas Gip */}
            <Route
              path='/contratacion/nueva/dental/tomador'
              render={(props) => (
                <DentalTakerDataLayout
                  {...props}
                  isReadOnly={isReadOnly}
                  data={data}
                  saveHiring={() => this.saveHiring()}
                  selectedMediatorCode={selectedMediatorCode}
                  mediator={this.props.mediator}
                  removeSign={this.removeSign}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/dental/asegurados/:index?'
              render={(props) => (
                <DentalInsuredDataLayout
                  {...props}
                  isReadOnly={isReadOnly}
                  saveInsured={this.state.saveInsured}
                  newInsured={this.state.addInsured}
                  removeInsured={this.state.removeInsured}
                  indexRemove={this.state.indexRemove}
                  data={data}
                  OnAnyChange={(changes: boolean) => this.OnAnyChange(changes)}
                  haveAnyChanges={this.state.haveAnyChanges}
                  haveInsuredSaved={(saved: boolean) =>
                    this.haveInsuredSaved(saved)
                  }
                  onGotoDetail={goToDetail}
                  saveHiring={() => this.saveHiring()}
                  goToInsured={(index: number) =>
                    this.handleGoToDentalDetail(index, true)
                  }
                  removeSign={this.removeSign}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/dental/garantias'
              render={(props) => (
                <DentalGuaranteeDataLayout
                  {...props}
                  data={data}
                  isReadOnly={isReadOnly}
                  newStep={() => this.newStep('documentacion-requerida')}
                  saveHiring={() => this.saveHiring()}
                  removeSign={this.removeSign}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/dental/documentacion-requerida'
              render={(props) => (
                <RequiredDocDataLayout
                  taker={data.taker}
                  insureds={data.insureds}
                  cotizacion={data.idcotiza}
                  data={data}
                  readOnly={isReadOnly}
                  saveHiring={() => this.saveHiring()}
                  removeSign={this.removeSign}
                  nextStep={DentalHireMenuHelper}
                  step={StepsDental.DocumentacionRequerida}
                  service={DentalService}
                  areaEndpoint="sendaDental"
                />
              )}
            />

            <Route
              path='/contratacion/nueva/dental/proteccion-datos'
              render={(props) => (
                <CrossProtectionData
                  {...props}
                  cotizacion={data.idcotiza}
                  data={data}
                  readOnly={isReadOnly}
                  saveHiring={this.saveHiring}
                  setLoading={data.setLoading}
                  removeSign={this.removeSign}
                  nextStep={DentalHireMenuHelper.getNextStepNavigationPath(StepsDental.ProteccionDatos)}
                  previousStep={DentalHireMenuHelper.getPreviousStepNavigationPath(StepsDental.ProteccionDatos)}
                  area={"sendaDental"}
                />               
              )}
            />

            <Route
              path='/contratacion/nueva/dental/precio'
              render={(props) => (
                <DentalPriceDataLayout
                  {...props}
                  isReadOnly={isReadOnly}
                  data={data}
                  saveHiring={() => this.saveHiring}
                  setLoading={data.setLoading}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/dental/medios-pago'
              render={(props) => (
                <DentalPaymentMethodsLayout
                  paymentMethod={data.paymentMethod}
                  cotizacion={data.idcotiza}
                  data={data}
                  isReadOnly={isReadOnly}
                  saveHiring={() => this.saveHiring}
                  insuredsCopay={data.insureds}
                  setLoading={data.setLoading}
                  policy={data.policy}
                  removeSign={() => this.removeSign}
                  {...props}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/dental/resultado'
              render={(props) => (
                <DentalResultDataLayout
                  {...props}
                  data={data}
                  resumeDentalHiring={this.state.resumeDentalHiring}
                  setLoading={this.props.setLoading}
                  saveHiring={(saveStep: boolean) => this.saveHiring(saveStep)}
                  cotizacion={data.idcotiza}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/:product/tomador'
              render={(props) => (
                <GipTakerDataLayout
                  {...props}
                  isReadOnly={isReadOnly}
                  data={data}
                  onGotoDetail={goToDetail}
                  saveHiring={this.saveHiring}
                  removeSign={this.removeSign}
                  selectedMediatorCode={selectedMediatorCode}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/:product/asegurados/:index?'
              render={(props) => (
                <GipInsuredDataLayout
                  {...props}
                  isReadOnly={isReadOnly}
                  policyType={product.products_internalName}
                  saveInsured={this.state.saveInsured}
                  newInsured={this.state.addInsured}
                  removeInsured={this.state.removeInsured}
                  indexRemove={this.state.indexRemove}
                  data={data}
                  onGotoDetail={goToDetail}
                  saveHiring={this.saveHiring}
                  removeSign={this.removeSign}
                  haveInsuredSaved={(saved: boolean) =>
                    this.haveInsuredSaved(saved)
                  }
                  goToInsured={(index: number) =>
                    this.handleGoToDetail(index, true)
                  }
                />
              )}
            />
            <Route
              path='/contratacion/nueva/:product/medios-pago'
              render={(props) => (
                <GipPaymentMethodsLayout
                  paymentMethod={data.paymentMethod}
                  cotizacion={data.idcotiza}
                  data={data}
                  fechaEfecto={data.taker.policy.startDate}
                  takerDocumentId={data.taker.docNumber}
                  isReadOnly={isReadOnly}
                  saveHiring={this.saveHiring}
                  insuredsCopay={data.insureds}
                  setLoading={data.setLoading}
                  product={data.product}
                  removeSign={this.removeSign}
                  policy={data.policy}
                  {...props}
                />
              )}
            />
            {/* <Route
              path="/contratacion/nueva/:product/garantias"
              render={(props) => (
                <GipGuaranteeDataLayout
                  {...props}
                  data={data}
                  changeBeneficiaries={(loan: any) =>
                    this.setBeneficiaries(loan)
                  }
                  isReadOnly={isReadOnly}
                  changeLoan={this.setLoan}
                  irrevocableHeirs={true}
                  newStep={() => this.newStep("documentacion-requerida")}
                  saveHiring={this.saveHiring}
                  goToInsureds={() => this.handleGoToDetail(0, true)}
                />
              )}
            /> */}

            <Route
              path='/contratacion/nueva/:product/documentacion-requerida'
              render={(props) => (
                <GipRequiredDocDataLayout
                  {...props}
                  taker={data.taker}
                  insureds={data.insureds}
                  cotizacion={data.idcotiza}
                  data={data}
                  removeSign={this.removeSign}
                  readOnly={isReadOnly}
                  saveHiring={this.saveHiring}
                />
              )}
            />

            {isGIPArea && (
              <Route
                path='/contratacion/nueva/:product/proteccion-datos'
                render={(props) => (
                  <GipProtectionData
                    {...props}
                    cotizacion={data.idcotiza}
                    data={data}
                    readOnly={isReadOnly}
                    saveHiring={this.saveHiring}
                    setLoading={data.setLoading}
                    removeSign={this.removeSign}
                  />
                )}
              />
            )}

            <Route
              path='/contratacion/nueva/:product/precio'
              render={(props) => (
                <GipPriceDataLayout
                  newStep={() => this.newStep(this.getStep())}
                  {...props}
                  isReadOnly={isReadOnly}
                  data={data}
                  saveHiring={this.saveHiring}
                  removeSign={this.removeSign}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/:product/cuestionarios-salud/:index'
              render={(props) => (
                <GipQuestionnaireDataLayout
                  {...props}
                  isReadOnly={isReadOnly}
                  data={data}
                  onGotoDetail={goToDetail}
                  saveQuestionnaire={this.state.saveQuestionnaire}
                  saveHiring={this.saveHiring}
                  checkCompleted={this.checkQuestionnairesCompleted}
                  newStep={() => this.newStep(this.getStep())}
                  removeSign={this.removeSign}
                  cotizacion={data.idcotiza}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/:product/resultado'
              render={(props) => (
                <GipResultDataLayout
                  {...props}
                  data={data}
                  resumeHiring={this.state.resumeHiring}
                  setLoading={this.props.setLoading}
                  saveHiring={(saveStep: boolean) => this.saveHiring(saveStep)}
                  cotizacion={data.idcotiza}
                />
              )}
            />

            {/* Rutas Travel */}
            <Route
              path='/contratacion/nueva/travel/:product/tomador'
              render={(props) => (
                <TravelTakerDataLayout
                  {...props}
                  isReadOnly={isReadOnly}
                  data={data}
                  onGotoDetail={goToDetail}
                  saveHiring={() => this.saveHiring()}
                  removeSign={this.removeSign}
                  selectedMediatorCode={selectedMediatorCode}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/travel/:product/asegurados/:index?'
              render={(props) => (
                <TravelInsuredDataLayout
                  {...props}
                  isReadOnly={isReadOnly}
                  saveInsured={this.state.saveInsured}
                  newInsured={this.state.addInsured}
                  removeInsured={this.state.removeInsured}
                  indexRemove={this.state.indexRemove}
                  data={data}
                  onGotoDetail={goToDetail}
                  saveHiring={() => this.saveHiring()}
                  removeSign={this.removeSign}
                  haveInsuredSaved={(saved: boolean) =>
                    this.haveInsuredSaved(saved)
                  }
                  goToInsured={(index: number) =>
                    this.handleGoToDetail(index, true)
                  }
                />
              )}
            />

            <Route
              path='/contratacion/nueva/travel/:product/proteccion-datos'
              render={(props) => (
                <TravelProtectionData
                  {...props}
                  cotizacion={data.idcotiza}
                  data={data}
                  readOnly={isReadOnly}
                  saveHiring={() => this.saveHiring()}
                  setLoading={data.setLoading}
                  removeSign={this.removeSign}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/travel/:product/resultado'
              render={(props) => (
                <TravelResultDataLayout
                  {...props}
                  data={data}
                  saveHiring={() => this.saveHiring()}
                  prices={this.state.prices}
                  setProductsData={() => this.setProductsData()}
                  sendSign={this.sendSign}
                />
              )}
            />

            <Route
              path='/contratacion/nueva/travel/:product/resumen'
              render={(props) => (
                <TravelCloseResultDataLayout
                  {...props}
                  data={data}
                  prices={this.state.prices}
                />
              )}
            />

            <Route path='/' component={NotFoundLayout} />
          </Switch>
          {!isHealthArea &&
            !isGIPArea &&
            !isDentalArea &&
            !isTravelArea &&
            pathname.indexOf('resultado') === -1 &&
            pathname.indexOf('miembros') === -1 &&
            Object.keys(product).length > 0 && (
              <InflowSidebarContainer>
                <div className='inflow-sidebar'>
                  {!isHealthArea ? (
                    <InflowSidebar
                      onGotoDetail={
                        !pathname.includes('beneficiarios')
                          ? (index: number) => this.handleGoToDetail(index)
                          : undefined
                      }
                      // onGotoDetail={undefined}
                      clickLightButton={() => this.handleLeftInfoModal()}
                      insuredMembers={data.insureds}
                      taker={data.taker}
                      disabled={!validateForm}
                      beneficiary={data.beneficiary}
                      selected={isEdit.index}
                      removeInsured={(index: number) =>
                        this.handleLeftInfoModalRemove(index)
                      }
                      linkShowAll={'/contratacion/nueva/miembros'}
                      linkDetail='/contratacion/nueva/'
                      saveHiring={() => this.saveHiring()}
                      sendSign={() => this.sendSign()}
                      downloadDocs={() => this.downloadDocs()}
                      actionCancel={() => this.openCancelHiringModal()}
                      disableDownloadDocs={setProductsDataError}
                      disableSendSign={setProductsDataError}
                      addNewInsured={() => this.handleLeftInfoModal()}
                      validatePet={this.handleValidatePet}
                      currentStep={PetHireNavHelper.getStepFromNavigationRoute(
                        this.props.history.location.pathname
                      )}
                    />
                  ) : (
                    <InflowSidebar
                      onGotoDetail={
                        !pathname.includes('beneficiarios')
                          ? (index: number) => this.handleGoToDetail(index)
                          : undefined
                      }
                      // onGotoDetail={undefined}
                      clickLightButton={() => this.handleLeftInfoModal()}
                      insuredMembers={data.insureds}
                      taker={data.taker}
                      signData={data.signData}
                      disabled={true}
                      selected={isEdit.index}
                      removeInsured={(index: number) =>
                        this.handleLeftInfoModalRemove(index)
                      }
                      linkShowAll={'/contratacion/nueva/miembros'}
                      linkDetail='/contratacion/nueva/'
                      saveHiring={() => this.saveHiring()}
                      sendSign={() => this.sendSign()}
                      downloadDocs={() => this.downloadDocs()}
                      actionCancel={() => this.openCancelHiringModal()}
                      generateInsuranceProposal={() =>
                        this.getInsuranceProposal()
                      }
                      generateInsuranceRequest={() =>
                        this.getInsuranceRequest()
                      }
                      disableDownloadDocs={setProductsDataError}
                      disableSendSign={setProductsDataError}
                    />
                  )}
                </div>
              </InflowSidebarContainer>
            )}

          {Object.keys(product).length ? (
            isHealthArea ? (
              <HealthHireMenu
                initialCurrentStep={HealthHireMenuHelper.getStepFromNavigationRoute(
                  this.props.history.location.pathname
                )}
                initialInsureds={data.insureds}
                codMediador={
                  this.state.selectedMediatorCode || this.props.user.code
                }
                selectedMediator={
                  data.taker && data.taker.policy
                    ? data.taker.policy.selectedMediator
                    : undefined
                }
                currentNumInsured={this.state.goToDetail}
                isSignedDocument={isSignedDocument}
                disableSignButton={
                  !isValidStatusToEnableButton(data.signatureStatus)
                }
                disableSubButtons={isReadOnly}
                isHired={
                  (data.status && data.status === 'V') || data.status === 'P'
                }
                insuredOnSave={() => this.handleSaveInsured()}
                insuredSaved={this.state.haveAnyChanges}
                insuredOnAdd={(saved: boolean) => this.handleAddInsured(saved)}
                insuredOnEdit={(index: number) => this.handleGoToDetail(index)}
                insuredOnRemove={(index: number) =>
                  this.handleLeftInfoModalRemove(index)
                }
                questionnaireOnEdit={(index: number) =>
                  this.handleEditQuestionnaire(index)
                }
                questionnaireOnSave={() => this.handleSaveQuestionnaire()}
                resumeOnHiring={() => this.handleResumeHiring()}
                downloadPrecontractualDocument={() =>
                  this.handleDownloadPrecontractualDocument()
                }
                sendMailPrecontractualDocument={() =>
                  this.handleSendMailPrecontractualDocument()
                }
                signPrecontractualDocument={() =>
                  this.checkSignBeforePrecontractualDocument()
                }
                physicalSignature={
                  data.paymentMethod && data.paymentMethod.isPhysicalSignature
                }
              />
            ) : isDentalArea ? (
              <DentalHireMenu
                initialCurrentStep={DentalHireMenuHelper.getStepFromNavigationRoute(
                  this.props.history.location.pathname
                )}
                initialInsureds={data.insureds}
                codMediador={
                  this.state.selectedMediatorCode || this.props.user.code
                }
                selectedMediator={
                  data.taker && data.taker.policy
                    ? data.taker.policy.selectedMediator
                    : undefined
                }
                currentNumInsured={this.state.goToDetail}
                isSignedDocument={isSignedDocument}
                disableSignButton={
                  !isValidStatusToEnableButton(
                    data.signatureStatus && data.signatureStatus
                  )
                }
                disableSubButtons={isReadOnly}
                isHired={
                  (data.status && data.status === 'V') || data.status === 'P'
                }
                insuredOnSave={() => this.handleSaveInsured()}
                insuredSaved={this.state.haveAnyChanges}
                insuredOnAdd={(saved: boolean) => this.handleAddInsured(saved)}
                insuredOnEdit={(index: number) =>
                  this.handleGoToDentalDetail(index)
                }
                insuredOnRemove={(index: number) =>
                  this.handleLeftInfoModalRemove(index)
                }
                resumeOnHiring={() => this.handleResumeDentalHiring()}
                downloadPrecontractualDocument={() =>
                  this.handleDownloadPrecontractualDocument()
                }
                sendMailPrecontractualDocument={() =>
                  this.handleSendMailDentalPrecontractualDocument()
                }
                signPrecontractualDocument={() =>
                  this.checkSignBeforePrecontractualDocument()
                }
                physicalSignature={
                  data.paymentMethod && data.paymentMethod.isPhysicalSignature
                }
              ></DentalHireMenu>
            ) : isGIPArea ? (
              <GipHireMenu
                initialCurrentStep={GipHireMenuHelper.getStepFromNavigationRoute(
                  this.props.history.location.pathname,
                  product.products_internalName
                )}
                initialInsureds={data.insureds}
                codMediador={
                  this.state.selectedMediatorCode || this.props.user.code
                }
                selectedMediator={
                  data.taker && data.taker.policy
                    ? data.taker.policy.selectedMediator
                    : undefined
                }
                currentNumInsured={this.state.goToDetail}
                isSignedDocument={isSignedDocument}
                disableSignButton={
                  !isValidStatusToEnableButton(data.signatureStatus)
                }
                disableSubButtons={isReadOnly}
                isHired={
                  (data.status && data.status === 'V') || data.status === 'P'
                }
                insuredOnSave={() => this.handleSaveInsured()}
                insuredSaved={this.state.haveAnyChanges}
                insuredOnAdd={(saved: any) => this.handleAddInsured(saved)}
                insuredOnEdit={(index: number) => this.handleGoToDetail(index)}
                insuredOnRemove={(index: number) =>
                  this.handleLeftInfoModalRemove(index)
                }
                questionnaireOnEdit={(index: number) =>
                  this.handleEditQuestionnaire(index)
                }
                questionnaireOnSave={() => this.handleSaveQuestionnaire()}
                resumeOnHiring={() => this.handleResumeHiring()}
                downloadPrecontractualDocument={() =>
                  this.handleDownloadPrecontractualDocument()
                }
                downloadGIPPrecontractualDocument={() =>
                  this.handleDownloadGipPrecontractualDocument()
                }
                sendMailPrecontractualDocument={() =>
                  this.handleSendMailPrecontractualDocument()
                }
                signPrecontractualDocument={() =>
                  this.checkSignBeforeGipPrecontractualDocument()
                }
                unlockForm={() => this.unlockGipForm()}
                physicalSignature={
                  data.paymentMethod && data.paymentMethod.isPhysicalSignature
                }
                policyType={product.products_internalName}
                isReadOnly={isReadOnly}
              />
            ) :
              isTravelArea &&
                  (<TravelHireMenu
                    initialCurrentStep={
                      TravelHireMenuHelper.getStepFromNavigationRoute(
                        this.props.history.location.pathname,
                        product.products_internalName
                      )}
                    initialInsureds={data.insureds}
                    codMediador={this.state.selectedMediatorCode || this.props.user.code}
                    selectedMediator={data.taker && data.taker.policy ? data.taker.policy.selectedMediator : undefined}
                    currentNumInsured={this.state.goToDetail}
                    isSignedDocument={isSignedDocument}
                    disableSignButton={!isValidStatusToEnableButton(data.signatureStatus)}
                    disableSubButtons={isReadOnly}
                    isHired={data.status && data.status === 'V' || data.status === 'P'}
                    insuredOnSave={() => this.handleSaveInsured()}
                    insuredSaved={this.state.haveAnyChanges}
                    insuredOnAdd={(saved: any) => this.handleAddInsured(saved)}
                    insuredOnEdit={(index: number) => this.handleGoToDetail(index)}
                    insuredOnRemove={(index: any) => this.handleLeftInfoModalRemove(index)}
                    questionnaireOnEdit={(index: any) => this.handleEditQuestionnaire(index)}
                    questionnaireOnSave={() => this.handleSaveQuestionnaire()}
                    payHiring={() => this.checkHiring()}
                    policyType={product.products_internalName}
                    isReadOnly={isReadOnly}
                    saveHiring={() => this.saveHiring(true)}
                    downloadDocs={this.downloadDocs}
                    sendMailPrecontractualDocument={(showModal: boolean = true) => this.handleSendMailPrecontractualDocument(showModal)}
                    sendSign={() => this.sendSign()}
                  />) 
          ) : ''
          }
        </PolicyContext.Provider >
        <LeftInfoModal
          img={images.IconUserBlue}
          mainTitle={
            saveHiring
              ? '¿Quieres guardar la contratación?'
              : '¿Quieres contratar?'
          }
          mainText={
            saveHiring
              ? 'Si guardas, saldrás del proceso de contratación.'
              : 'Si aceptas, contratarás la poliza correspondiente'
          }
          withCancel={true}
          buttonText={saveHiring ? 'Si, guardar' : 'Si, contratar'}
          active={showHiringModal}
          onClickAccept={() =>
            saveHiring ? this.saveHiring() : this.checkHiringAllowed()
          }
          close={() => this.closeHiringModal()}
        />
        <LeftInfoModal
          img={images.IconEraseRed}
          mainTitle={'¿Quieres cancelar la contratación?'}
          mainText={
            'Al confirmar, no podrás recuperar la información de la póliza correspondiente'
          }
          buttonText={'Si, Cancelar'}
          closeButtonText={'No'}
          active={showCancelHiringModal}
          onClickAccept={() => this.cancelHiring()}
          close={() => this.closeCancelHiringModal()}
        />
        <LeftInfoModal
          img={addInsured ? images.IconUserBlue : images.IconEraseRed}
          className={addInsured ? '' : 'error'}
          mainTitle={
            isPetArea
              ? addInsured
                ? dataForm.insureds && dataForm.insureds.length === 0
                  ? '¿Quieres añadir una mascota?'
                  : '¿Quieres añadir otra mascota?'
                : '¿Quieres eliminar esta mascota?'
              : addInsured
              ? dataForm.insureds && dataForm.insureds.length === 0
                ? '¿Quieres añadir un asegurado?'
                : '¿Quieres añadir otro asegurado?'
              : '¿Quieres eliminar al asegurado?'
          }
          mainText={
            isPetArea
              ? addInsured
                ? ''
                : showLeftInfoModal && this.selectedCoveragesOpt() !== 1
                ? 'Si eliminas los datos de la mascota, no podrás recuperarlo más tarde'
                : 'Esta mascota tiene incluida garantías opcionales, si la elimina también se eliminarán los datos del asegurado asociado y no podrás recuperarlo'
              : addInsured
              ? ''
              : 'Si eliminas los datos del asegurado, no podrás recuperarlo más tarde'
          }
          buttonText={addInsured ? 'Sí, añadir' : 'Eliminar'}
          active={showLeftInfoModal}
          onClickAccept={() => {
            if (addInsured) this.newStep('insured')
            else {
              this.removeInsured()
              this.setState({ removeInsured: true }, () =>
                this.setState({ removeInsured: false })
              )
              if (isHealthArea) this.saveHiring()
              if (
                isPetArea &&
                history.location.pathname.indexOf(
                  'contratacion/nueva/miembros'
                ) > -1
              )
                this.setProductsData()
            }
          }}
          close={() => this.closeLeftModal()}
        />
        <LeftInfoModal
          img={
            typeModal === 'error' || typeModal === 'warn'
              ? images.WarningError
              : images.IconWarningYellow
          }
          className={typeModal === 'warn' ? 'error' : typeModal}
          mainTitle={
            typeModal === 'error'
              ? 'Error en el servicio'
              : typeModal === 'warn'
              ? 'Error de contratación'
              : 'Aviso'
          }
          mainText={textModal}
          list={listModal || []}
          buttonText={
            typeModal === 'error' || typeModal === 'warn'
              ? 'Cerrar'
              : 'Continuar'
          }
          active={showExceptionModal}
          secondaryText={secondaryText}
          onClickAccept={() =>
            typeModal === 'error' || typeModal === 'warn'
              ? this.closeExceptionModal()
              : this.goToNextPage()
          }
          close={() =>
            typeModal === 'error' || typeModal === 'warn'
              ? this.closeExceptionModal()
              : this.goToNextPage()
          }
          noSecondaryButton
        />
        <LeftInfoModal
          img={
            typeModal === 'error'
              ? images.WarningError
              : images.IconWarningYellow
          }
          className={typeModal}
          mainTitle={typeModal === 'error' ? 'Error en el servicio' : 'Aviso'}
          mainText={textModal}
          list={listModal || []}
          buttonText={typeModal === 'error' ? 'Cerrar' : 'Continuar'}
          active={showPricesModal}
          onClickAccept={() => this.closePricesModal()}
          close={() => this.closePricesModal()}
          noSecondaryButton
        />
        <LeftInfoModal
          img={images.IconInfoBlue}
          mainTitle={'Información'}
          mainText={infoModalText}
          buttonText={'Cerrar'}
          active={infoModalShow}
          onClickAccept={() => this.infoModalClose()}
          close={() => this.infoModalClose()}
          noSecondaryButton
        />
        <LeftInfoModal
          img={images.WarningError}
          className={'error'}
          mainTitle={
            isPetArea ? 'Máximo de mascotas aseguradas' : 'Máximo de asegurados'
          }
          mainText={
            isPetArea
              ? 'No puedes añadir más mascotas'
              : 'No puedes añadir más asegurados.'
          }
          buttonText={'Cerrar'}
          active={showInsuredModal}
          onClickAccept={() => this.closeInsuredModal()}
          close={() => this.closeInsuredModal()}
          noSecondaryButton
        />

        <LeftInfoModal
          img={images.IconWarningYellow}
          className='error'
          mainTitle={'¿Seguro que desea continuar?'}
          mainText={'Perderá todos los cambios introducidos.'}
          buttonText='Continuar'
          closeButtonText='Cancelar'
          close={() => this.setState({ changeInsuredModalShow: false })}
          active={this.state.changeInsuredModalShow}
          onClickAccept={() => {
            this.setState(
              { changeInsuredModalShow: false, haveAnyChanges: false },
              () => {
                let index = this.state.changeInsuredModalShowIndex
                  ? this.state.changeInsuredModalShowIndex
                  : 0
                if (index === -1) this.handleAddInsured(true)
                else this.handleGoToDetail(index, true)
              }
            )
          }}
        />
        <LeftInfoModal
          img={images.IconWarningYellow}
          className='error'
          mainTitle={'¿Seguro que desea continuar?'}
          mainText={'Se cancelará la firma previamente solicitada'}
          buttonText='Continuar'
          closeButtonText='Cancelar'
          close={() => this.setState({ cancelSignModalShow: false })}
          active={cancelSignModalShow}
          onClickAccept={() => {
            this.setState({ cancelSignModalShow: false }, () =>
              this.removeSign()
            )
          }}
        />
        <LeftInfoModal
          img={images.IconWarningYellow}
          className={'warning'}
          mainTitle={'Error'}
          mainText={
            'Se ha producido un error al mostrar el detalle de la póliza pero se ha emitido correctamente'
          }
          buttonText={'Aceptar'}
          active={savingEntityError}
          onClickAccept={() => {
            this.setState({ savingEntityError: false })
            this.props.history.push('/contratacion/realizada')
          }}
          close={() => {
            this.setState({ savingEntityError: false })
            this.props.history.push('/contratacion/realizada')
          }}
          noSecondaryButton
        />
        <LeftInfoModal
          img={images.IconWarningYellow}
          className={'warning'}
          mainTitle={'Error en el servicio'}
          mainText={
            downloadErrorMessage
              ? downloadErrorMessage
              : 'Se ha producido un error al obtener la documentación'
          }
          buttonText={'Aceptar'}
          active={downloadError}
          onClickAccept={() => {
            this.closeDownloadErrorModal()
          }}
          close={() => {
            this.closeDownloadErrorModal()
          }}
          noSecondaryButton
        />
        <LeftInfoModal
          img={images.IconUserBlue}
          className={''}
          mainTitle={leftInfoModalTitle}
          mainText={leftInfoModalText}
          buttonText={'Cerrar'}
          noSecondaryButton
          active={showSendInfoModal}
          onClickAccept={() => {
            this.setState({ showSendInfoModal: false })
          }}
          close={() => this.closeLeftModal()}
        />
        <LeftInfoModal
          img={images.IconUserBlue}
          className={''}
          mainTitle={leftInfoModalTitle}
          mainText={leftInfoModalText}
          buttonText={'Aceptar'}
          active={showNewSignInfoModal}
          onClickAccept={() => {
            leftInfoModalClick()
          }}
          close={() => this.closeLeftSignModal()}
        />
        <LeftInfoModal
          img={images.IconUserBlue}
          className={''}
          mainTitle={leftInfoModalTitle}
          mainText={leftInfoModalText}
          buttonText={'Aceptar'}
          noSecondaryButton
          active={showSignedInfoModal}
          onClickAccept={() => {
            leftInfoModalClick()
          }}
          close={() => leftInfoModalClick()}
        />
        <LeftInfoModal
          img={images.IconWarningYellow}
          className={'info'}
          mainTitle={'Aviso de contratación'}
          mainText={textModal}
          list={listModal || []}
          buttonText={'Continuar'}
          closeButtonText={'Cancelar'}
          active={showWarningsHiring}
          secondaryText={secondaryText}
          onClickAccept={() => isTravelArea ? this.handlePayHiring() : this.sendSignConfirm()}
          close={() => this.closeShowWarningsHiring()}
          info={SENDA_INFO}
        />
        {/* AVISO DE CONTRATACIÓN DE TRAVEL POST-PAGO */}
        <LeftInfoModal
          img={images.IconWarningYellow}
          className={'info'}
          mainTitle={'Aviso de contratación'}
          mainText={textModal}
          list={listModal || []}
          buttonText={'Aceptar'}
          active={showTravelWarningsHiring}
          onClickAccept={() => this.closeShowTravelWarningsHiring()}
          close={() => this.closeShowTravelWarningsHiring()}
          info={SENDA_INFO}
          noSecondaryButton={true}
          centerPrimaryButton={true}
        />

        <LeftInfoModal
          img={images.IconInfoBlue}
          className={'info'}
          mainTitle={'Información de pago'}
          mainText={'Pago realizado con éxito.'}
          buttonText={'Continuar'}
          active={showPaymentInfoModal}
          onClickAccept={() => this.handleHiringAfterPayment()}
          withCancel={false}
          noSecondaryButton={true}
        />

        <LeftInfoModal
          img={images.IconWarningYellow}
          className={'alert'}
          mainTitle={'Aviso de contratación'}
          mainText={`Fecha de efecto retroactiva mayor a lo permitido, se recomienda modificarla.`}
          secondaryText={'¿Desea modificar la fecha de efecto?'}
          buttonText={'Sí, modificar'}
          closeButtonText={'No, continuar'}
          active={showRetroactiveDate}
          close={() => this.closeShowRetroactiveDate()}
          onClickAccept={() => {
            this.closeShowRetroactiveDate()
            this.newStep('taker')
          }}
        />

        <LeftInfoModal
          img={images.IconWarningYellow}
          className={'info'}
          mainTitle={'Aviso de contratación'}
          mainText={`No ha sido posible obtener la lista de compañías`}
          secondaryText={
            'Puede continuar con el proceso de contratación, sin seleccionar que posee otro seguro de otra compañía'
          }
          buttonText={'Aceptar'}
          active={showListCompaniesException}
          close={() => this.closeListCompaniesExpectionModal()}
          onClickAccept={() => this.closeListCompaniesExpectionModal()}
          noSecondaryButton
        />
      </NewHiringContainer>
    )
  }
}

export default withGeneral(
  withDynamicProducts(withCoverages(withRouter(NewHiring)))
)
