import React, { ChangeEvent, FC, useEffect, useState } from "react";
import SearchBox from "../search-box-mediadores/search-box";
import { NationalitySelectProps } from "./nationality-select.types";
import ConstantsServices from "../../services/ConstantsServices";

const NationalitySelect: FC<NationalitySelectProps> = ({
  nationalityValue,
  nationalityError,
  onSelect,
  required = false,
  disabled = false,
}) => {
  const [countriesList, setCountriesList] = useState<{ label: string; value: string }[]>([]);
  const [countriesFiltered, setCountriesFiltered] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    getCountriesList();
  }, []);

  const getCountriesList = async () => {
    const countries = await ConstantsServices.getCountries({
      setLoading: () => {},
    });
    setCountriesList(countries);
  };

  const changeValue = (ev: ChangeEvent<HTMLInputElement>) => {
    const value: string = ev.target.value;

    if (value.length < 2) return;

    setCountriesFiltered(
      countriesList.filter((country: { label: string }) =>
        country.label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            value
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
      )
    );
  };
  return (
    <div className="inputBox">
      <SearchBox
        onChange={(ev: ChangeEvent<HTMLInputElement>) => changeValue(ev)}
        labelText=""
        optionsText={countriesFiltered}
        optionKey={"value"}
        optionValue={"label"}
        disabled={disabled}
        initialValue={nationalityValue}
        required={required}
        errorCode={nationalityError}
        onSelect={(value: { value: string; label: string }) => {
          onSelect(value.label);
        }}
      />
      <label>Nacionalidad</label>
    </div>
  );
};

export default NationalitySelect;
