import { IDataProtectionMethod } from "../constants/health";
import { ProtectionDataSendaResponse, SendaBaseResponse } from "./CrossService.SendaBaseResponse";
import GeneralServices from "./GeneralServices";
import { RequiredDocumentsDto } from "./dto/CrossService-dto";

class CrossServices {

    setDataProtection = async (setLoading: Function, loader: boolean, request: IDataProtectionMethod, areaEndpoint: string): Promise<SendaBaseResponse> => {
        try {
            const response = await GeneralServices.post({
                setLoading,
                loader,
                endpoint: `${areaEndpoint}/setDataProtection`,
                data: request
            });
            return response.data;
        } catch (err) {
            const baseError = "Error al enviar protección de datos";
            const genericError = 'Se ha producido un error al establecer protección de datos. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    }

    getDataProtection = async (setLoading: Function, loader: boolean, cotizacion: string, areaEndpoint: string): Promise<ProtectionDataSendaResponse> => {
        try {
            const response = await GeneralServices.get({
                setLoading,
                loader,
                endpoint: `${areaEndpoint}/getDataProtection/${cotizacion}`
            });
            return response.data;
        } catch (err) {
            const baseError = "Error al consultar protección de datos";
            const genericError = 'Se ha producido un error al consultar la protección de datos. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    }

    uploadDocumentsToSenda = async (setLoading: Function, loader: boolean, data: RequiredDocumentsDto, areaEndpoint: string): Promise<SendaBaseResponse> => {
        try {
            const fd = new FormData();
            fd.append("body", JSON.stringify({
                "cotizacion": data.cotizacion,
                "fileInfo": data.fileInfo
            }));
            data.files.forEach(file => {
                fd.append(`files`, file);
            });

            const response = await GeneralServices.postFormData({
                setLoading: setLoading,
                loader: loader,
                endpoint: `${areaEndpoint}/uploadDocuments`,
                data: fd
            });
            return response.data;
        } catch (err) {
            const baseError = "Error al subir documentos";
            const genericError = 'Se ha producido un error al subir documentos. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    }



    returnError(e: any, baseError: string, genericError: string) {
        return e && e.data && e.data.message ? `${baseError}: ${e.data.message}` : genericError;
    }
}

export default new CrossServices();