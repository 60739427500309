import styled from "styled-components";
import { colors } from "../../assets";

export const ComparisonCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 490px;
  height: 130px;
  margin-top: 12px;
  position: relative;
  padding: 3px 20px 10px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  /* Estilos para la franja azul en la parte superior */
  &:before {
    content: "";
    position: absolute;
    top: -12px;
    left: 0;
    right: 0;
    height: 12px;
    background-color: ${colors["PR-001-100"]};
  }
  .flexColumn {
    display: flex;
    flex-direction: column;
  }
  .leftColumn {
    justify-content: space-between;
    gap: 10px;
    height: 100%;
    align-items: center;
  }
  .rightColumn {
    gap: 10px;
    margin-top: 18px;
    margin-right: 6px;
  }
  .smallText {
    font-size: 10px;
  }
  .mediumText {
    font-size: 18px;
  }
  .bigText {
    font-size: 60px;
    font-weight: 100;
  }
  .blueText {
    color: ${colors["PR-001-100"]};
  }
  .healthInsuranceName {
    font-size: 20px;
    font-weight: 400;
  }
  .healthInsuranceInfo {
    display: flex;
    align-items: center;
    width: 280px;
    margin-left: 22px;
  }
  .healthInsuranceNameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .smallData {
    flex-direction: column;
    gap: 2px;
  }
  .spaceAround {
    display: flex;
    justify-content: space-around;
    margin-bottom: 51px;
  }
  .descText {
    margin-top: 5px;
    font-size: 13px;
  }
  .smallDataColumn {
    display: flex;
    align-items: center;
  }
  .minWidth {
    width: 50px;
  }
  .bigNumber {
    width: 120px;
    display: flex;
    justify-content: center;
  }
`;

export const CardContainer = styled.div`
  width: 49%;
  display: flex;
  justify-content: center;

  @media (max-width: 1524px) {
    width: 99%;
  }
`;