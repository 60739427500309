import axios from "axios";
import { SERVICES_URL } from "./url";

class GeneralServices {
  setLoading = (loading: boolean) => {
    const w: any = window;
    if (w.setLoader) w.setLoader(loading);
  };
  setIsSlowProcess = (isSlowProcess: boolean) => {
    const w: any = window;
    if (w.setIsSlowProcess) w.setIsSlowProcess(isSlowProcess);
  };

  setIsLogged = (logged: boolean) => {
    const w: any = window;
    if (w.setIsLogged) w.setIsLogged(logged);
  };
  getIsLogged = () => {
    const w: any = window;
    if (w.getIsLogged) return w.getIsLogged();
    return false
  };

  get = async (args: any) => {
    args.setLoading(args.loader);
    this.setLoading(args.loader);
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.accessToken
          ? `${localStorage.accessToken}`
          : "",
      };
      const response = await axios({
        method: "GET",
        url: SERVICES_URL + args.endpoint,
        headers,
        responseType: args.arraybuffer ? "arraybuffer" : undefined,
      });
      if (!args.ignoreSetFalse) {
        args.setLoading(false);
        this.setLoading(false);
      }
      return response;
    } catch (err) {
      args.setLoading(false);
      this.setLoading(false);
      this.handleError(err.response);
      throw err.response;
    }
  };

  getFile = async (args: any) => {
    args.setLoading(args.loader);
    this.setLoading(args.loader);
    try {
      const headers = {
        Authorization: localStorage.accessToken
          ? `${localStorage.accessToken}`
          : "",
          "accept": "*/*",
          "accept-encoding": "gzip, deflate, br",
      };
      const response = await axios({
        method: "GET",
        responseType: 'arraybuffer',
        url: SERVICES_URL + args.endpoint,
        headers
      });
      if (!args.ignoreSetFalse) {
        args.setLoading(false);
        this.setLoading(false);
      }
      return response;
    } catch (err) {
      args.setLoading(false);
      this.setLoading(false);
      this.handleError(err.response);
      throw err.response;
    }
  };

  post = async (args: any, ignoreSetFalse?: boolean) => {
    this.setIsSlowProcess(args.isSlowProcess);
    args.setLoading(args.loader);
    this.setLoading(args.loader);
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.accessToken
          ? `${localStorage.accessToken}`
          : "",
      };
      const response = await axios({
        method: "POST",
        url: SERVICES_URL + args.endpoint,
        headers,
        data: JSON.stringify(args.data),
      });
      if (!ignoreSetFalse) {
        args.setLoading(false);
        this.setLoading(false);
        this.setIsSlowProcess(false);
      }
      return response;
    } catch (err) {
      args.setLoading(false);
      this.setLoading(false);
      this.setIsSlowProcess(false);
      this.handleError(err.response);
      throw err.response;
    }
  };
  postFileResponse = async (args: any, ignoreSetFalse?: boolean) => {
    args.setLoading(args.loader);
    this.setLoading(args.loader);
    try {
      const headers = {
        Authorization: localStorage.accessToken
          ? `${localStorage.accessToken}`
          : "",
          "Content-Type": "application/json",
          "accept": "*/*",
          "accept-encoding": "gzip, deflate, br",
      };
      const response = await axios({
        method: "POST",
        responseType: 'arraybuffer',
        url: SERVICES_URL + args.endpoint,
        headers,
        data: JSON.stringify(args.data),
      });
      if (!ignoreSetFalse) {
        args.setLoading(false);
        this.setLoading(false);
      }
      return response;
    } catch (err) {
      args.setLoading(false);
      this.setLoading(false);
      this.handleError(err.response);
      throw err.response;
    }
  };
  postFormData = async (args: any) => {
    this.setIsSlowProcess(args.isSlowProcess);
    args.setLoading(args.loader);
    this.setLoading(args.loader);
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": undefined,
        "Content-Length": undefined,
        Authorization: localStorage.accessToken
          ? `${localStorage.accessToken}`
          : "",
      };
      const response = await axios({
        method: "POST",
        url: SERVICES_URL + args.endpoint,
        headers,
        data: args.data,
      });
      args.setLoading(false);
      this.setLoading(false);
      this.setIsSlowProcess(false);
      return response;
    } catch (err) {
      args.setLoading(false);
      this.setLoading(false);
      this.setIsSlowProcess(false);
      this.handleError(err.response);
      throw err.response;
    }
  };

  patch = async (args: any) => {
    args.setLoading(args.loader);
    this.setLoading(args.loader);
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.accessToken
          ? `${localStorage.accessToken}`
          : "",
      };
      const response = await axios({
        method: "PATCH",
        url: SERVICES_URL + args.endpoint,
        headers,
        data: JSON.stringify(args.data),
      });
      args.setLoading(false);
      this.setLoading(false);
      return response;
    } catch (err) {
      args.setLoading(false);
      this.setLoading(false);
      this.handleError(err.response);
      throw err.response;
    }
  };

  put = async (args: any) => {
    args.setLoading(args.loader);
    this.setLoading(args.loader);
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.accessToken
          ? `${localStorage.accessToken}`
          : "",
      };
      const response = await axios({
        method: "PUT",
        url: SERVICES_URL + args.endpoint,
        headers,
        data: JSON.stringify(args.data),
      });
      args.setLoading(false);
      this.setLoading(false);
      return response;
    } catch (err) {
      args.setLoading(false);
      this.setLoading(false);
      this.handleError(err.response);
      throw err.response;
    }
  };

  deleteReq = async (args: any) => {
    args.setLoading(args.loader);
    this.setLoading(args.loader);
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.accessToken
          ? `${localStorage.accessToken}`
          : "",
      };
      const response = await axios({
        method: "DELETE",
        url: SERVICES_URL + args.endpoint,
        headers,
        data: args.data,
      });
      args.setLoading(false);
      this.setLoading(false);
      return response;
    } catch (err) {
      args.setLoading(false);
      this.setLoading(false);
      this.handleError(err.response);
      throw err.response;
    }
  };

  handleError = (err: any) => {
    if (err && err.data && err.status && window.location.origin !== window.location.href && this.getIsLogged()) {
      const jwtExpired = err.data.errors && err.data.errors.message === "jwt expired";

      if ( (err.status === 403 || err.status === 401 || jwtExpired) && !localStorage.servicesError ) {
        localStorage.setItem('servicesError', "error");
        if ( window.location.pathname !== "/" &&
          window.location.pathname.indexOf("aviso-legal") === -1 &&
          window.location.pathname.indexOf("politica-cookies") === -1 &&
          window.location.pathname.indexOf("politica-proteccion-datos") === -1 &&
          window.location.pathname.indexOf("politica-conservacion-datos") === -1 &&
          window.location.pathname.indexOf("politica-adicional-rgpd") === -1
        ) {
          window.location.href = window.location.origin;
        }
        else {
          window.location.reload();
        }
      }
    }
  };
  
}

export default new GeneralServices();
