import React, { Component } from "react";
import { CampaignsCreateStepContainer } from "./campains-create-steps-style";
import InputBox from "../../../../components/input-box/input-box";
import CalendarBox from "../../../../components/calendar-box/calendar-box";

interface ICampaingsCreateStep1Props {
  goNext: boolean;
  onNext: (state: ICampaingsCreateStep1State) => void;
  previousState?: ICampaingsCreateStep1State;
}

export interface ICampaingsCreateStep1State {
  bases: string;
  error: string;
}

class CampaingsCreateStep1 extends Component<
  ICampaingsCreateStep1Props,
  ICampaingsCreateStep1State
> {
  constructor(props: ICampaingsCreateStep1Props) {
    super(props);
    this.state = {
      bases: "",
      error: ""
    };
  }

  componentDidMount() {
    const { previousState } = this.props;
    if (previousState) this.setState(previousState);
  }

  componentDidUpdate(prevProps: ICampaingsCreateStep1Props) {
    const { goNext, previousState } = this.props;
    if (goNext && prevProps.goNext !== goNext) {
      this.handleNext();
    }
    if (previousState && prevProps.previousState !== previousState) {
      this.setState(previousState);
    }
  }

  handleNext() {
    const { onNext } = this.props;
    const { bases } = this.state;
    let isCorrect = true;
    let error = "";
    if (!bases || bases === "") {
      isCorrect = false;
      error = "required";
    }
    this.setState({ error }, () => {
      if (isCorrect) onNext(this.state);
    });
  }

  render() {
    const { bases, error } = this.state;
    return (
      <CampaignsCreateStepContainer>
        <div className="section">
          <div className="section__title">{"DEFINICIÓN DE LAS BASES"}</div>
          <div className="section__subtitle"></div>
          <div className="col-100">
            <div>
              <InputBox
                labelText={"BASES"}
                value={bases}
                area={true}
                errorCode={error}
                placeholder={"Escribe aquí"}
                onChange={val => this.setState({ bases: val })}
              />
            </div>
          </div>
        </div>
      </CampaignsCreateStepContainer>
    );
  }
}

export default CampaingsCreateStep1;
