import React from "react";
import { ResultContainer } from "./result-style";
import { RouteComponentProps, withRouter } from "react-router-dom";
import MainTable from "../../../../components/main-table";
import { NewHiringContainer } from "../new-hiring-style";
import HeadsTitleText from "../../../../components/components-text/heads-title-text";
import GuideLine from "../../../../components/guide-line";
import Title03Text from "../../../../components/components-text/title-03-text";
import HeadText from "../../../../components/components-text/head-text";
import NoteText from "../../../../components/components-text/note-text";
import MainButton from "../../../../components/main-button";
import { images } from "../../../../assets";
import { InflowSidebarContainer } from "../../../../components/inflow-sidebar/inflow-sidebar-style";
import LeftInfoModal from "../../../../components/left-info-modal";
import { withPolicy } from "../../../../context/mixin/with-policy";
import { withGeneral } from "../../../../context/mixin/with-general";
import { capitalizeFirstLetter, currencyFormat } from "../../../../utils";
import {
  LABEL_SEX,
  LABEL_TYPE_PETS,
} from "../../../../constants";
import { getDayMonthYear } from "../../../../utils/time";
import CoveragesServices from "../../../../services/CoveragesServices";
import { convertBase64ToFile, downloadFile } from "../../../../utils/utility";
import { withDynamicProducts } from "../../../../context/mixin/with-dynamic-products";
import { AREA_NAMES, setColorSpan } from "../../../../constants/policyType";
import { IUser } from "../../../../models/user-model";
import { withPayTypes } from "../../../../context/mixin/with-pay-types";
import PayTypesServices from "../../../../services/PayTypesServices";
interface ResultLayoutProps extends RouteComponentProps {
  setLoading: Function;
  dataForm: any;
  data: any;
  policy: any;
  responseHiring: any;
  selectedMediatorCode: string;
  checkProductArea: Function;
  user: IUser;
  allPayTypes: any;
}

interface ResultLayoutState {
  changeGuideLine?: any;
  setGuideLine?: any;
  showLeftInfoModal: boolean;
  leftInfoModalImg: string;
  leftInfoModalTitle: string;
  leftInfoModalText: string;
  leftInfoModalButtonText: string;
  leftInfoModalClick: Function;
  takerInfoRows1: any[];
  takerInfoColumns1: any[];
  takerInfoRows2: any[];
  takerInfoColumns2: any[];
  insuredInfoColumnsLife1: any[];
  insuredInfoColumnsLife2: any[];
  insuredInfoColumnsDeaths: any[];
  insuredInfoColumnsPets: any[];
  contractTableColumns: any[];
  contractTableRows: any[];
  amountTableColumns: any[];
  firstAmountTableRows: any[];
  anualAmountTableRows: any[];
  accountTableColumns: any[];
  accountTableRows: any[];
  mainGuaranteesTableColumns: any[];
  mainGuaranteesTableRows: any[];
  complementaryGuaranteesTableColumns: any[];
  complementaryGuaranteesTableRows: any[];
}

class ResultLayout extends React.Component<
  ResultLayoutProps,
  ResultLayoutState
> {
  constructor(props: ResultLayoutProps) {
    super(props);
    if (props.dataForm.insureds.length === 0) {
      props.history.push("/contratacion/nueva");
    }
    this.state = {
      showLeftInfoModal: false,
      leftInfoModalImg: "",
      leftInfoModalTitle: "",
      leftInfoModalText: "",
      leftInfoModalButtonText: "",
      leftInfoModalClick: () => { },
      takerInfoRows1: this.getTakerInfoRows1(),
      takerInfoColumns1: this.getTakerInfoColumns1(),
      takerInfoRows2: this.getTakerInfoRows2(),
      takerInfoColumns2: this.getTakerInfoColumns2(),
      insuredInfoColumnsLife1: this.getInsuredInfoColumnsLife1(),
      insuredInfoColumnsLife2: this.getInsuredInfoColumnsLife2(),
      insuredInfoColumnsDeaths: this.getInsuredColumnsDeaths(),
      insuredInfoColumnsPets: this.getInsuredColumnsPets(),
      contractTableColumns: this.getContractTableColumns(),
      contractTableRows: this.getContractTableRows(),
      amountTableColumns: this.getAmountTableColumns(),
      firstAmountTableRows: this.getFirstAmountTableRows(),
      anualAmountTableRows: this.getAnualAmountTableRows(),
      accountTableColumns: this.getAccountTableColumns(),
      accountTableRows: this.getAccountTableRows(),
      mainGuaranteesTableColumns: this.getMainGuaranteesTableColumns(),
      mainGuaranteesTableRows: this.getMainGuaranteesTableRows(),
      complementaryGuaranteesTableColumns: this.getComplementaryGuaranteesTableColumns(),
      complementaryGuaranteesTableRows: this.getComplementaryGuaranteesTableRows(),
    };
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);

    this.getCoverageDescriptions();
  };

  getCoverageDescriptions = async () => {
    const { policy, data, checkProductArea } = this.props;
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)

    if (
      isDeathArea &&
      data &&
      data.taker &&
      data.taker.address &&
      data.taker.address.postalCode
    ) {
      const args = {
        setLoading: () => { },
        loader: true,
        productType: policy.product.products_internalName,
        postalCode: data.taker.address.postalCode,
      };
      const coveragesDesc: any = await CoveragesServices.getCoverageDescriptions(
        args
      );
      // const deathCoveragesDescrition = coveragesDesc.data[0].breakdown.reduce(
      //   (value: string, cov: any) => `${value} ${cov.description}. \n`,
      //   ""
      // );

      const deathCoveragesDescrition: string[] = coveragesDesc[0].breakdown.map((cov: any) => `${cov.description}.`);

      this.setState({
        mainGuaranteesTableRows: this.getMainGuaranteesTableRows(deathCoveragesDescrition),
      });
    }
  };

  getTakerInfoRows1 = () => {
    const { dataForm } = this.props;

    const takerInfoRows = dataForm.taker.address
      ? [
        {
          address: {
            text: dataForm.taker.address.street.value
              ? dataForm.taker.address.street.value.label
              : "",
            type: "",
            link: "",
            key: "entire-mini-body",
          },
          locality: {
            text: dataForm.taker.address.city.value
              ? dataForm.taker.address.city.value.label
              : "",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
          province: {
            text: dataForm.taker.address.province.value
              ? dataForm.taker.address.province.value.label
              : "",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
          postalCode: {
            text: dataForm.taker.address.postalCode.value
              ? dataForm.taker.address.postalCode.value.label
              : "",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
        },
      ]
      : [];

    return takerInfoRows;
  };

  getTakerInfoColumns1 = () => {
    return [
      {
        text: "Dirección postal",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "Localidad",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "Provincia",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
      {
        text: "C. Postal",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ];
  };

  getTakerInfoRows2 = () => {
    const { dataForm } = this.props;

    const takerInfoRows = dataForm.taker.info
      ? [
        {
          email: {
            text: dataForm.taker.info.email.value
              ? dataForm.taker.info.email.value
              : "",
            type: "",
            link: "",
            key: "entire-mini-body",
          },
          phone: {
            text: dataForm.taker.info.landlinePhoneNumber.value
              ? dataForm.taker.info.landlinePhoneNumber.value
              : "No introducido",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
          mobile: {
            text: dataForm.taker.info.mobile.value
              ? dataForm.taker.info.mobile.value
              : "No introducido",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
        },
      ]
      : [];

    return takerInfoRows;
  };

  getTakerInfoColumns2 = () => {
    const takerTableColumns2 = [
      {
        text: "Correo electrónico",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "Telefono fijo",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "Móvil",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
    ];
    return takerTableColumns2;
  };

  getInsuredInfoColumnsLife1 = () => {
    const insuredTableColumns1 = [
      {
        text: "Nombre",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "N.I.F",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "F. de nacimiento",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
      {
        text: "Sexo",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ];
    return insuredTableColumns1;
  };

  getInsuredInfoColumnsLife2 = () => {
    const insuredTableColumns2 = [
      {
        text: "Profesión",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "Actividad de riesgo",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ];
    return insuredTableColumns2;
  };

  getInsuredInfoRowsLife1 = (insured: any) => {
    return [
      {
        name: {
          text: `${insured.contact.name.value} ${insured.contact.lastname1.value} ${insured.contact.lastname2.value}`,
          type: "",
          link: "",
          key: "entire-mini-body",
        },
        nif: {
          text: insured.contact.docNumber.value,
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
        birthDate: {
          // text: moment(insured.contact.birthdate.value).format("DD/MM/YYYY"),
          text: getDayMonthYear(insured.contact.birthdate.value),
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
        sex: {
          text: LABEL_SEX[insured.contact.sex.value],
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
      },
    ];
  };

  getInsuredInfoRowsLife2 = (insured: any, index?: any) => {
    const { responseHiring } = this.props;
    const responseInsured =
      responseHiring.insureds[index] || responseHiring.insureds[0];

    return [
      {
        job: {
          text: `${insured.profession.occupation.value.label ||
            // insured.profession.occupation.label ||
            (responseInsured && responseInsured.profession.label) ||
            "-"
            }`,
          type: "",
          link: "",
          key: "entire-mini-body",
        },
        riskActivity: {
          text:
            insured.profession.riskActivity.value === "Y"
              ? insured.profession.activityType.value.label ||
              (responseInsured && responseInsured.occupation.label)
              : "No",
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
      },
    ];
  };

  getInsuredColumnsDeaths = () => {
    const insuredTableColumns1 = [
      {
        text: "Nombre",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "Fecha de nacimiento",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "Capital asegurado",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
      {
        text: "Prima neta anual",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ];

    return insuredTableColumns1;
  };

  getInsuredRowsDeaths = (insureds: any[]): any[] => {
    const { responseHiring } = this.props;

    return insureds.map((insured: any, index: number) => ({
      name: {
        text: `${insured.contact.name.value} ${insured.contact.lastname1.value} ${insured.contact.lastname2.value}`,
        type: "",
        link: "",
        key: "entire-mini-body",
      },
      birthDate: {
        // text: moment(insured.contact.birthdate.value).format("DD/MM/YYYY"),
        text: getDayMonthYear(insured.contact.birthdate.value),

        type: "",
        link: "",
        key: "dimmed-mini-body",
      },
      insuredAmount: {
        text: responseHiring.insureds && responseHiring.insureds[index]
          ? currencyFormat(
            responseHiring.insureds[index].coverages.reduce(
              (r: number, coverage: any) =>
                (coverage.capital > 1) ? (coverage.capital + r) : r,
              0
            )
          ) + " €"
          : "",
        type: "",
        link: "",
        key: "dimmed-mini-body",
      },
      netPrime: {
        text: responseHiring.insureds && responseHiring.insureds[index]
          ? currencyFormat(
            responseHiring.insureds[index].coverages.reduce(
              (r: number, coverage: any) => coverage.amounts.netPremium + r,
              0
            )
          ) + " €"
          : "",
        type: "",
        link: "",
        key: "dimmed-mini-body",
      },
    }));
  };

  getInsuredColumnsPets = () => {
    const insuredTableColumns1 = [
      {
        text: "Nombre",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "Microchip",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "Tipo",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
      {
        text: "Raza",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "F- Nacimiento",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "Prima neta anual",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ];
    return insuredTableColumns1;
  };

  getInsuredRowsPets = (insureds: any[]): any[] => {
    const { responseHiring } = this.props;

    return insureds.map((insured: any, index: number) => ({
      name: {
        text: insured.contact.name.value,
        type: "",
        link: "",
        key: "entire-mini-body",
      },
      microchip: {
        text: insured.contact.numChip.value,
        type: "",
        link: "",
        key: "dimmed-mini-body",
      },
      type: {
        text: LABEL_TYPE_PETS[insured.contact.type.value],
        type: "",
        link: "",
        key: "dimmed-mini-body",
      },
      breed: {
        text: insured.contact.breed.value.label,
        type: "",
        link: "",
        key: "dimmed-mini-body",
      },
      birthDate: {
        text: insured.contact.birthdate.value,
        type: "",
        link: "",
        key: "dimmed-mini-body",
      },
      netPrime: {
        text: responseHiring.insureds ?
          currencyFormat(
            responseHiring.insureds[index].coverages.reduce(
              (r: number, coverage: any) => (
                parseFloat(coverage.amounts.netPrime) + r, 0
              )
            )
          ) + " €" 
          : '',
        type: "",
        link: "",
        key: "dimmed-mini-body",
      },
    }));
  };

  getContractTableColumns = () => {
    return [
      {
        text: "Producto",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "Efecto",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      // {
      //   text: "Horas",
      //   filter: false,
      //   ascendent: false,
      //   key: "dimmed-mini-title"
      // },
      {
        text: "Duración",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "Renovable",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "Pago",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ];
  };

  getContractTableRows = () => {
    const { responseHiring, allPayTypes } = this.props;
    const date = new Date(responseHiring.effectDate);

    return [
      {
        product: {
          text: responseHiring.product ? responseHiring.product.combiProd : "",
          type: "",
          link: "",
          key: "entire-mini-body",
        },
        effect: {
          text: getDayMonthYear(date),
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
        // hours: {
        //   text: getHourMinutes(date),
        //   type: "",
        //   link: "",
        //   key: "dimmed-mini-body"
        // },
        duration: {
          text: getDayMonthYear(
            responseHiring.product
              ? new Date(responseHiring.product.expireDate)
              : date
          ),
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
        renew: {
          text: "Anual",
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
        payment: {
          text: responseHiring.amountsPay
            ? PayTypesServices.getPayTypeByCode(allPayTypes, responseHiring.amountsPay.paymentType).description
            : "",
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
      },
    ];
  };

  getAmountTableColumns = () => {
    const amountTableColumns = [
      {
        text: "Prima neta inicial",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "Recargo/Descuento*",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      // {
      //   text: "Sobre prima*",
      //   filter: false,
      //   ascendent: false,
      //   key: "dimmed-mini-title",
      // },
      {
        text: "Impuestos",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
      // {
      //   text: "Consorcio/p",
      //   filter: false,
      //   ascendent: false,
      //   key: "dimmed-mini-title",
      // },
      {
        text: "Consorcio",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
      {
        text: "Otros gastos",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
      {
        text: "Total recibo",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
    ];

    return amountTableColumns;
  };

  getFirstAmountTableRows = () => {
    const { responseHiring } = this.props;
    return [
      {
        netPrime: {
          text: responseHiring.amountsPay
            ? currencyFormat(responseHiring.amountsPay.recipes[0].netPremium)
            : "",
          type: "",
          link: "",
          key: "entire-mini-body",
        },
        surcharge: {
          text: responseHiring.amountsPay
            ? currencyFormat(responseHiring.amountsPay.recipes[0].tax.surcharge)
            : "",
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
        // overPrime: {
        //   text: responseHiring.amountsPay ? currencyFormat(responseHiring.amountsPay.recipes[0].totalPremium) : '',
        //   type: "",
        //   link: "",
        //   key: "dimmed-mini-body",
        // },
        taxes: {
          text: responseHiring.amountsPay
            ? currencyFormat(responseHiring.amountsPay.recipes[0].taxs)
            : "",
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
        // consortiumP: {
        //   text: responseHiring.amountsPay ? currencyFormat(responseHiring.amountsPay.recipes[0].totalPremium) : '',
        //   type: "",
        //   link: "",
        //   key: "dimmed-mini-body",
        // },
        consortium: {
          text: responseHiring.amountsPay
            ? currencyFormat(
              responseHiring.amountsPay.recipes[0].tax.dgs +
              responseHiring.amountsPay.recipes[0].tax.ips +
              responseHiring.amountsPay.recipes[0].tax.iem +
              responseHiring.amountsPay.recipes[0].tax.asf
            )
            : "",
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
        otherExpenses: {
          text: responseHiring.amountsPay
            ? currencyFormat(responseHiring.amountsPay.recipes[0].totalPremium)
            : "",
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
        totalReceipt: {
          text: responseHiring.amountsPay
            ? currencyFormat(responseHiring.amountsPay.recipes[0].totalPremium)
            : "",
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
      },
    ];
  };

  getAnualAmountTableRows = () => {
    const { responseHiring } = this.props;
    if (responseHiring.amountsPay) {
      const recipeTotal = responseHiring.amountsPay.recipes.reduce(
        (r: any, recipe: any) => {
          r.netPrime += parseFloat(recipe.netPremium);
          r.surcharge += parseFloat(recipe.tax.surcharge);
          r.taxes += parseFloat(recipe.taxs);
          r.consortium += parseFloat(recipe.tax.dgs);
          r.otherExpenses +=
            recipe.tax.dgs + recipe.tax.ips + recipe.tax.iem + recipe.tax.asf;
          r.totalReceipt += parseFloat(recipe.totalPremium);
          return r;
        },
        {
          netPrime: 0,
          surcharge: 0,
          taxes: 0,
          consortium: 0,
          otherExpenses: 0,
          totalReceipt: 0,
        }
      );
      return [
        {
          netPrime: {
            text: currencyFormat(recipeTotal.netPrime) + " €",
            type: "",
            link: "",
            key: "entire-mini-body",
          },
          surcharge: {
            text: currencyFormat(recipeTotal.surcharge) + " €",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
          // overPrime: {
          //   text: currencyFormat(recipeTotal.totalPremium) + ' €',
          //   type: "",
          //   link: "",
          //   key: "dimmed-mini-body",
          // },
          taxes: {
            text: currencyFormat(recipeTotal.taxes) + " €",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
          // consortiumP: {
          //   text: currencyFormat(recipeTotal.totalPremium) + ' €',
          //   type: "",
          //   link: "",
          //   key: "dimmed-mini-body",
          // },
          consortium: {
            text: currencyFormat(recipeTotal.consortium) + " €",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
          otherExpenses: {
            text: currencyFormat(recipeTotal.otherExpenses) + " €",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
          totalReceipt: {
            text: currencyFormat(recipeTotal.totalReceipt) + " €",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
        },
      ];
    }
    return [];
  };

  getAccountTableColumns = () => {
    const accountTableColumns = [
      {
        text: "IBAN",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      // {
      //   text: "Revalorización",
      //   filter: false,
      //   ascendent: true,
      //   key: "dimmed-mini-title",
      // },
    ];
    return accountTableColumns;
  };

  getAccountTableRows = () => {
    const { dataForm } = this.props;
    return [
      {
        accountNumber: {
          text: dataForm.taker.date
            ? dataForm.taker.date.iban.value
              .replace(/ /g, "")
              .trim()
              .replace(/(.{4})/g, "$1 ")
              .trim()
            : "",
          type: "",
          link: "",
          key: "entire-mini-body",
        },
        // revaluation: {
        //   text: "Sin crecimiento",
        //   type: "",
        //   link: "",
        //   key: "dimmed-mini-body",
        // },
      },
    ];
  };

  getMainGuaranteesTableColumns = () => {
    const mainGuaranteesTableColumns = [
      {
        text: "Seguro principal",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
        customClass: "table-col-2",
      },
      {
        text: "Resumen de la cobertura*",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
        customClass: "table-col-1",
      },
      {
        text: "Importe asegurado",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
        customClass: "table-col-2",
      },
    ];

    return mainGuaranteesTableColumns;
  };

  getComplementaryGuaranteesTableColumns = () => {
    const complementaryGuaranteesTableColumns = [
      {
        text: "Seguro complementario",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
        customClass: "table-col-2",
      },
      {
        text: "Resumen de la cobertura*",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
        customClass: "table-col-1",
      },
      {
        text: "Importe asegurado",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
        customClass: "table-col-2",
      },
    ];

    return complementaryGuaranteesTableColumns;
  };

  getMainGuaranteesTableRows = (deathDesc?: string[]) => {
    const { responseHiring } = this.props;
    const mandatoryGuaranteeTableRows: any[] = [];
    if (responseHiring.insureds) {
      Object.keys(responseHiring.insureds[0].coverages).forEach(
        (c, index: number) => {
          const split = c.split("-");
          const isNotShow = split.length > 1 && split[1] === "N";
          const coverage = responseHiring.insureds[0].coverages[c];
          const isComplementary =
            (split.length > 1 && split[1] === "C") ||
            split[1] === "O" ||
            !coverage.required;
          if (!isComplementary && !isNotShow) {
            mandatoryGuaranteeTableRows.push({
              mainInsurance: {
                text: coverage.name
                  ? capitalizeFirstLetter(coverage.name.toLowerCase())
                  : "",
                type: "",
                link: "",
                key: "entire-mini-body",
              },
              coverageResume: {
                text:
                  deathDesc && index === 0 ? deathDesc.map((description: string, index: number) => (
                    <p key={index} >{description}</p>
                  ))
                    : coverage.description || "",
                type: "",
                link: "",
                key: "dimmed-mini-body",
              },
              insuredAmount: {
                text:
                  coverage.capital === 1
                    ? "Incluida"
                    : currencyFormat(coverage.capital) + " €",
                type: "",
                link: "",
                key: "dimmed-mini-body",
              },
            });
          }
        }
      );
    }
    return mandatoryGuaranteeTableRows;
  };

  getNameBeneficiary = (b : any) => {
    
    let nameBeneficiary = b.name;
    nameBeneficiary = `${nameBeneficiary} ${b.lastName1 && b.lastName1 !== undefined && b.lastName1 !== '' ? b.lastName1 : ''}`
    nameBeneficiary = `${nameBeneficiary} ${b.lastName2 && b.lastName2 !== undefined && b.lastName2 !== '' ? b.lastName2 : ''}`
    return nameBeneficiary

  }

  getComplementaryGuaranteesTableRows = () => {
    const { responseHiring } = this.props;
    let coverages: any[] = [];
    if (responseHiring.insureds) {
      responseHiring.insureds.forEach((insured: any) => {
        Object.keys(insured.coverages).forEach((c, index) => {
          const split = c.split("-");
          const coverage = insured.coverages[index];
          const isComplementary =
            (split.length > 1 && split[1] === "C") ||
            split[1] === "O" ||
            !coverage.required;
          if (isComplementary) {
            coverages.push(coverage);
          }
        });
      });

      // TAKING OUT DUPLICATES
      coverages = coverages.filter(
        (value: any, index: any, initialArray: any) =>
          initialArray.findIndex(
            (t: any) => t.name === value.name
          ) === index
      );
      //

      return coverages.map((coverage) => ({
        mainInsurance: {
          text: coverage.name
            ? capitalizeFirstLetter(coverage.name.toLowerCase())
            : "",
          type: "",
          link: "",
          key: "entire-mini-body",
        },
        coverageResume: {
          text: coverage.description || "",
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
        insuredAmount: {
          text:
            coverage.capital === 1
              ? "Incluida"
              : currencyFormat(coverage.capital) + " €",
          type: "",
          link: "",
          key: "dimmed-mini-body",
        },
      }));
    }
    return [];
  };

  changeStep = (goBack: boolean = false) => {
    if (goBack) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/contratacion/nueva/asegurados");
    }
  };

  downloadHiring() {
    const { responseHiring } = this.props;
    this.setState({
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconDownloadBlue,
      leftInfoModalTitle: "¿Quieres descargar la póliza?",
      leftInfoModalText: "",
      leftInfoModalButtonText: "Descargar",
      leftInfoModalClick: () => {
        const document = responseHiring.documents.find(
          (d: any) => d.name === "hiring"
        );
        if (document) {
          const file = convertBase64ToFile(document.data, "application/pdf");
          downloadFile(
            file,
            `presupuesto-${responseHiring.product.businessLine}-${responseHiring.insureds[0].policyNumber}.pdf`,
            "application/pdf",
            true
          );
        }
      },
    });
  }

  downloadNote() {
    const { responseHiring } = this.props;
    this.setState({
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconDownloadBlue,
      leftInfoModalTitle: "¿Quieres ver la nota informativa?",
      leftInfoModalText: "",
      leftInfoModalButtonText: "Ver",
      leftInfoModalClick: () => {
        const document = responseHiring.documents.find(
          (d: any) => d.name === "informative"
        );
        if (document) {
          const file = convertBase64ToFile(document.data, "application/pdf");
          downloadFile(
            file,
            `nota-informativa-${responseHiring.product.businessLine}.pdf`,
            "application/pdf",
            true
          );
        }
      },
    });
  }

  openLeftInfoModal() {
    this.setState({ showLeftInfoModal: true });
  }

  closeLeftModal() {
    this.setState({ showLeftInfoModal: false });
  }

  renderInsuredsLife = () => {
    const { dataForm } = this.props;
    const { insuredInfoColumnsLife1, insuredInfoColumnsLife2 } = this.state;

    return dataForm.insureds.map((insured: any, index: any) => (
      <div className="result-item-content">
        <div className="result-item-content-table">
          <MainTable
            columns={insuredInfoColumnsLife1}
            rows={this.getInsuredInfoRowsLife1(insured)}
            keysRow={["name", "nif", "birthDate", "sex"]}
          />
        </div>
        <div className="result-item-content-table">
          <MainTable
            columns={insuredInfoColumnsLife2}
            rows={this.getInsuredInfoRowsLife2(insured, index)}
            keysRow={["job", "riskActivity"]}
          />
        </div>
      </div>
    ));
  };

  renderInsuredsDeaths = () => {
    const { dataForm } = this.props;
    const { insuredInfoColumnsDeaths } = this.state;
    return (
      <div className="result-item padding0">
        <div className="result-item-title">
          <Title03Text>
            <p>Relación de asegurados</p>
          </Title03Text>
        </div>
        <div className="result-item-content">
          <div className="result-item-content-table">
            <MainTable
              className="table noZebra"
              columns={insuredInfoColumnsDeaths}
              rows={this.getInsuredRowsDeaths(dataForm.insureds)}
              keysRow={["name", "birthDate", "insuredAmount", "netPrime"]}
            />
          </div>
        </div>
      </div>
    );
  };

  renderInsuredsPets = () => {
    const { dataForm } = this.props;
    const { insuredInfoColumnsPets } = this.state;

    return (
      <div className="result-item">
        <div className="result-item-title">
          <Title03Text>
            <p>Relación de mascotas</p>
          </Title03Text>
        </div>
        <div className="result-item-content">
          <div className="result-item-content-table">
            <MainTable
              className="table noZebra"

              columns={insuredInfoColumnsPets}
              rows={this.getInsuredRowsPets(dataForm.insureds)}
              keysRow={[
                "name",
                "microchip",
                "type",
                "breed",
                "birthDate",
                "netPrime",
              ]}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      showLeftInfoModal,
      leftInfoModalImg,
      leftInfoModalTitle,
      leftInfoModalText,
      leftInfoModalButtonText,
      leftInfoModalClick,
      takerInfoColumns1,
      takerInfoColumns2,
      takerInfoRows1,
      takerInfoRows2,
      contractTableColumns,
      contractTableRows,
      amountTableColumns,
      firstAmountTableRows,
      anualAmountTableRows,
      accountTableColumns,
      accountTableRows,
      mainGuaranteesTableColumns,
      mainGuaranteesTableRows,
      complementaryGuaranteesTableColumns,
      complementaryGuaranteesTableRows,
    } = this.state;
    const { policy, responseHiring, dataForm, data, selectedMediatorCode, checkProductArea, user } = this.props;
    const isLifeArea = checkProductArea(AREA_NAMES.LIFE, policy.product.products_internalName)
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)

    return (
      <NewHiringContainer>
        <div className="new-hiring-flow-status">
          <div className="new-hiring-flow-status-title">
            <HeadsTitleText>
              <p>Resultado de la contratación</p>
            </HeadsTitleText>
          </div>
          <div className="new-hiring-flow-status-guide">
            <GuideLine steps={[0, 0, 0, 0]} currentStep={3} />
          </div>
        </div>

        <ResultContainer
          colorSpan={setColorSpan(policy.product.ln_nameItem)}
        >
          <div className="result-hero-container">
            <div className="result-hero-type">
              <p>
                Póliza 
                <span>
                  {policy.product.products_name}
                </span>
              </p>
            </div>
            <div className="result-hero-data">
              <div className="result-hero-data__name">
                <p>
                  {user.name} {user.lastname1} {user.lastname2}
                </p>
              </div>
              <div className="result-hero-data__nif">
                <p>{selectedMediatorCode ? selectedMediatorCode : user.code}</p>
              </div>
              <div className="result-hero-data__email">
                <p>{user.email}</p>
              </div>
            </div>
          </div>

          <div className="result-welcome">
            <div className="result-welcome__title">
              <p>Estimado/a Sr./Sra:</p>
            </div>
            <div className="result-welcome__description">
              <p>
                En base a la información que nos ha proporcionado le mostramos
                el contenido de la póliza que ha contratado, agradeciendo su
                confianza en{" "}
                {isLifeArea
                  ? "ASISA VIDA SEGUROS."
                  : "ASISA."}
              </p>
            </div>
          </div>

          <div className="result-container">
            <div className="result-item">
              <div className="result-item-title">
                <Title03Text>
                  <p>Tomador del seguro</p>
                </Title03Text>
              </div>
              <div className="result-item-subtitle-container">
                <div className="result-item-subtitle-item">
                  <div className="result-item-subtitle-item-key">
                    <HeadText>
                      <p>Nombre:</p>
                    </HeadText>
                  </div>
                  <div className="result-item-subtitle-item-value">
                    <HeadText>
                      <p>
                        {dataForm.taker.contact
                          ? `${dataForm.taker.contact.name.value} ${dataForm.taker.contact.lastname1.value} ${dataForm.taker.contact.lastname2.value}`
                          : ""}
                      </p>
                    </HeadText>
                  </div>
                </div>
                <div className="result-item-subtitle-item">
                  <div className="result-item-subtitle-item-key">
                    <HeadText>N.I.F:</HeadText>
                  </div>
                  <div className="result-item-subtitle-item-value">
                    <HeadText>
                      {dataForm.taker.contact
                        ? dataForm.taker.contact.docNumber.value
                        : ""}
                    </HeadText>
                  </div>
                </div>
              </div>
              <div className="result-item-content">
                <div className="result-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={takerInfoColumns1}
                    rows={takerInfoRows1}
                    keysRow={["address", "locality", "province", "postalCode"]}
                  />
                </div>
                <div className="result-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={takerInfoColumns2}
                    rows={takerInfoRows2}
                    keysRow={["email", "phone", "mobile"]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="result-container">
            <div className="result-item">
              <div className="result-item-title">
                <Title03Text>
                  <p>Asegurado</p>
                </Title03Text>
              </div>
              {isLifeArea
                ? this.renderInsuredsLife()
                : isDeathArea
                  ? this.renderInsuredsDeaths()
                  : this.renderInsuredsPets()}
            </div>

            <div className="result-item">
              <div className="result-item-title">
                <Title03Text>
                  <p>Contrato/suplemento</p>
                </Title03Text>
              </div>
              <div className="result-item-subtitle-container">
                <div className="result-item-subtitle-item">
                  <div className="result-item-subtitle-item-key">
                    <HeadText>Nº de póliza:</HeadText>
                  </div>
                  <div className="result-item-subtitle-item-value">
                    <HeadText>
                      {responseHiring.insureds
                        ? responseHiring.insureds[0].policyNumber
                        : ""}
                    </HeadText>
                  </div>
                </div>
              </div>
              <div className="result-item-content">
                <div className="result-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={contractTableColumns}
                    rows={contractTableRows}
                    keysRow={[
                      "product",
                      "effect",
                      // "hours",
                      "duration",
                      "renew",
                      "payment",
                    ]}
                  />
                </div>
                <div className="result-item-content-advise">
                  <NoteText>
                    <p>*Fecha Duración excluida del período de cobertura</p>
                  </NoteText>
                </div>
              </div>
            </div>

            <div className="result-item">
              <div className="result-item-title">
                <Title03Text>
                  <p>Importe primer recibo del movimiento</p>
                </Title03Text>
              </div>
              <div className="result-item-subtitle-container">
                <div className="result-item-subtitle-item">
                  <div className="result-item-subtitle-item-key">
                    <HeadText>Período:</HeadText>
                  </div>
                  <div className="result-item-subtitle-item-value">
                    <HeadText>
                      {responseHiring.amountsPay
                        ? getDayMonthYear(
                          responseHiring.amountsPay.recipes[0].initDate
                        ) +
                        " — " +
                        getDayMonthYear(
                          responseHiring.amountsPay.recipes[0].endDate
                        )
                        : ""}
                    </HeadText>
                  </div>
                </div>
              </div>
              <div className="result-item-content">
                <div className="result-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={amountTableColumns}
                    rows={firstAmountTableRows}
                    keysRow={[
                      "netPrime",
                      "surcharge",
                      "taxes",
                      "consortium",
                      "otherExpenses",
                      "totalReceipt",
                    ]}
                  />
                </div>
                <div className="result-item-content-advise">
                  <NoteText>
                    <p>* Importes incluidos en la prima neta</p>
                  </NoteText>
                </div>
              </div>
            </div>

            <div className="result-item">
              <div className="result-item-title">
                <Title03Text>
                  <p>Importe anual total</p>
                </Title03Text>
              </div>
              <div className="result-item-content">
                <div className="result-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={amountTableColumns}
                    rows={anualAmountTableRows}
                    keysRow={[
                      "netPrime",
                      "surcharge",
                      "taxes",
                      "consortium",
                      "otherExpenses",
                      "totalReceipt",
                    ]}
                  />
                </div>
                <div className="result-item-content-advise">
                  <NoteText>
                    <p>* Importes incluidos en la prima neta</p>
                  </NoteText>
                </div>
              </div>
            </div>

            <div className="result-item">
              <div className="result-item-title">
                <Title03Text>
                  <p>Código cuenta cliente</p>
                </Title03Text>
              </div>
              <div className="result-item-content">
                <div className="result-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={accountTableColumns}
                    rows={accountTableRows}
                    keysRow={["accountNumber"]}
                  />
                </div>
              </div>
            </div>

            <div className="result-item">
              <div className="result-item-title">
                <Title03Text>
                  <p>Garantías del seguro</p>
                </Title03Text>
              </div>
              <div className="result-item-content">
                <div className="result-item-content-table">
                  <MainTable
                    className="table noZebra special"
                    columns={mainGuaranteesTableColumns}
                    rows={mainGuaranteesTableRows}
                    keysRow={[
                      "mainInsurance",
                      "coverageResume",
                      "insuredAmount",
                    ]}
                  />
                </div>
                <div className="result-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={complementaryGuaranteesTableColumns}
                    rows={complementaryGuaranteesTableRows}
                    keysRow={[
                      "mainInsurance",
                      "coverageResume",
                      "insuredAmount",
                    ]}
                  />
                </div>
                <div className="result-item-content-advise">
                  <NoteText>
                    <p>
                      * En estos apartados se ofre un resumen de las coberturas
                      contratadas tanto para la Garantía del Seguro Principal
                      como para las Garantías Complementarias. La definición
                      exacta y detallada de las coberturas es la que figura en
                      las Condiciones Generales.
                    </p>
                  </NoteText>
                </div>
              </div>
            </div>
            {isLifeArea && (
              <div className="result-item">
                <div className="result-item-title">
                  <Title03Text>
                    <p>Beneficiarios</p>
                  </Title03Text>
                </div>
                <div className="result-item-subtitle-container">
                  {data.mortgageLoan && (
                    <div className="result-item-subtitle-item">
                      <div className="result-item-subtitle-item-key">
                        <HeadText>
                          <p>Irrevocables:</p>
                        </HeadText>
                      </div>
                      <div className="result-item-subtitle-item-value">
                        <HeadText>
                          <p>{data.mortgageLoan.entity}</p>
                        </HeadText>
                        <HeadText>
                          <p>{data.mortgageLoan.number}</p>
                        </HeadText>
                      </div>
                    </div>
                  )}
                  <div className="result-item-subtitle-item">
                    <div className="result-item-subtitle-item-key">
                      <HeadText>
                        <p>Designados:</p>
                      </HeadText>
                    </div>
                    <div className="result-item-subtitle-item-value">
                      {data.beneficiaries.map((b: any) => (
                        <HeadText>
                          <p>
                            {this.getNameBeneficiary(b)}, {b.docType} {b.docNumber}
                          </p>
                        </HeadText>
                      ))}
                      <HeadText>
                        <p>En su defecto, Herederos legales</p>
                      </HeadText>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="result-footer">
            <ul>
              <NoteText>
                <li>
                  * Puede descargar las Condiciones Particulares de la
                  Contratación así como el Resultado de Simulación con la Nota
                  Informativa Previa.
                </li>
              </NoteText>
            </ul>
          </div>
        </ResultContainer>
        <InflowSidebarContainer>
          <div className="inflow-sidebar">
            <div className="inflow-buttons-container">
              <div className="inflow-buttons-item">
                <MainButton
                  type="full-light"
                  text="Ver resultado"
                  icon
                  iconImg={images.IconEyeWhiteFill}
                  onClick={() => this.downloadHiring()}
                />
              </div>
              <div className="inflow-buttons-item">
                <MainButton
                  type="full-light"
                  text="Ver Nota"
                  icon
                  iconImg={images.IconEyeWhiteFill}
                  onClick={() => this.downloadNote()}
                />
              </div>
              <div className="inflow-buttons-item">
                <MainButton
                  type="light"
                  text="Terminar"
                  onClick={() =>
                    this.props.history.push("/contratacion/pendiente")
                  }
                />
              </div>
            </div>
          </div>
        </InflowSidebarContainer>
        <LeftInfoModal
          img={leftInfoModalImg}
          mainTitle={leftInfoModalTitle}
          mainText={leftInfoModalText}
          buttonText={leftInfoModalButtonText}
          close={() => this.closeLeftModal()}
          active={showLeftInfoModal}
          onClickAccept={leftInfoModalClick}
        />
      </NewHiringContainer>
    );
  }
}

export default withGeneral(withDynamicProducts(withPayTypes(withPolicy(withRouter(ResultLayout)))));
