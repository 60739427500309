import styled from "styled-components";
import { colors } from "../../../assets";

export const ClientsLiquidationsContainer = styled.div`
  .back-arrow-container {
    margin-bottom: 18px;
  }
  .main-title-container {
    margin-bottom: 38px;
    color: ${colors["PR-001-100"]};
  }

  .main-filter-container {
    width: 100%;
    margin-bottom: 15px;
    background-color: #fbfbfb; 
    padding: 20px;

    &__buttons {
      display: inline-block;
      max-width: 160px;
    }

    &__filters {
      display: inline-block;
      max-width: 160px;
    }
  }

  .main-filter-item {
    width: 100%;
    margin-right: 36px;
    max-width: 160px;
    display: inline-block;

    
  }

  .main-results-number {
    color: ${colors["TX-001-100"]};
    margin-bottom: 40px;
    span {
      font-weight: 600;
    }
  }

  .pendant-simulations-table {
    margin-bottom: 75px;
    min-width: 100%;
    max-width: 1250px;
    overflow-x: auto;
  }

  .pendant-simulation-pagination {
    font-family: museo sans, sans-serif;
    color: ${colors["PR-001-100"]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
  }
`;
