import styled from "styled-components";
import { colors } from "../../../../assets";
import { BodyTextContainer } from "../../../../components/components-text/body-text/body-text-styles";

export const DetailClientsReceiptsContainer = styled.div`
  font-family: 'museo sans';
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  .row::after {
    content: "";
    clear: both;
    display: table;
  }
  
  [class*="col-"] {
    float: left;
    padding: 15px;
  }
  
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}

  .title{
    font-size: 2vw;
  }

  .conditions{
    border-bottom: 1px solid ${colors["PR-001-100"]};
  } 

  .tabs {
    font-size: 1.2vw;
    font-weight: bold;
    color: white;
    margin-left: 85px;
    ul{
      margin-left: -40px;
    }
    li{
      text-align: center;
      margin: 0px 2px -2px -0px;
      padding: 0px 20px 0px 20px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      cursor: pointer;
    }
  }

  .tab-selected{
    background: ${colors["PR-001-100"]};
  }

  .tab-not-selected{
    background: ${colors["PR-001-80"]};
  }

  .button-icon-container {
    border: none;

    button {
      background: none;
      padding: 0;
      min-width: 36px;
      min-height: 36px;
      border-radius: 5px;
      border: none;
      background: #004F8B;
      cursor: pointer;
      transition: .3s all ease-in-out;

      &:hover {
        background: #023f6d;
      }

      img {
        max-width: 20px;
      }

    }
  }

  .detail-clients-receipts-back {
    margin-bottom: 18px;
  }

  .detail-clients-receipts-item {
    background: ${colors["detail-item-background"]};
    width: 100%;
    margin-bottom: 15px;
    padding: 20px;
  }

  .detail-clients-policies-item {
    padding: 20px;
    margin-bottom: 48px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
  }

  .detail-clients-receipts-item-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 40px;
  }

  .detail-clients-receipts-item-content-table {
    margin-bottom: 55px;
    overflow-x: auto;
    min-width: 100%;
    max-width: 1250px;

    &:last-child {
      margin-bottom: 0;
    }

    thead {
      /* border-bottom: 1px solid ${colors["PR-001-100"]}; */
    }

    th {
      /* padding: 8px 0;
      color: ${colors["TX-001-30"]}; */
      .highlighted {
        /* color: ${colors["TX-001-50"]}; */
      }
    }

    td {
      /* padding: 8px 0; */

      ${BodyTextContainer} {
        display: inline-block;
        &.highlighted {
          /* font-weight: 600; */
        }
      }

    }

    .main-table-row {
      &:first-child {
        td {
          /* padding-top: 20px; */
        }
      }

      &:hover {
        /* box-shadow: none; */
      }
    }
  }

  @media (max-width: 1370px){
    .data-taker-info-item-title {
      display: block;
      margin-bottom: 6px;
    }
  }

  @media (max-width: 980px){
    .data-taker-info-item-title {
      display: block;
      margin-bottom: 6px;
    }

    .data-taker-info-item {
      margin-right: 22px;
    }
  }

  .filtersMargin{
    margin-bottom: 20px;
  }
`;
