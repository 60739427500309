import React, { Component } from "react";
import SelectBox from "../../../components/select-box/select-box";
import MainButton from "../../../components/main-button";
import { PermissionsUploadContainer } from "./permissions-upload-style";
import { images } from "../../../assets";
import { MainTableContainer } from "../../../components/main-table/main-table-style";
import BodyText from "../../../components/components-text/body-text";
import { getFileSize } from "../../../utils/utility";
import LeftInfoModal from "../../../components/left-info-modal";
import { withGeneral } from "../../../context/mixin/with-general";
import ProductsMediadoresServices from "../../../services/ProductsMediadoresServices";
import {
  validateDocuments,
  documentTableColumns,
  documentTypes,
} from "./utils";

export interface NewDocument {
  docType: string;
  file: File;
}

interface DocumentTypes {
  label: string;
  value: string;
  fileAccept: string[];
}

interface PermissionsUploadProps {
  setLoading: Function;
}

interface PermissionsUploadState {
  newDocument: NewDocument | null;
  documents: NewDocument[];
  documentTypes: DocumentTypes[];
  selectedType: string;
  errors: string[];
  errorsType: string;
  errorsModalTitle: string;
  fileToRemove: string;
  processingMessage: boolean;
}

class PermissionsUpload extends Component<
  PermissionsUploadProps,
  PermissionsUploadState
> {
  static state: PermissionsUploadState;
  constructor(props: PermissionsUploadProps) {
    super(props);
    this.state = {
      newDocument: null,
      documents: [],
      documentTypes,
      selectedType: "",
      errorsModalTitle: "",
      errors: [],
      errorsType: "",
      fileToRemove: "",
      processingMessage: false,
    };
  }

  handleSelectFileType = (value: string) => {
    this.setState({ selectedType: value });
  };

  handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const { selectedType, documents } = this.state;
      const repeatDoc = documents.find((doc) => doc.file.name === file.name);
      if (repeatDoc) {
        this.setState({
          errors: ["Documento ya añadido"],
          errorsModalTitle: "Aviso",
          errorsType: "info",
        });
        return;
      }

      const newDocument = {
        file,
        docType: selectedType,
      };
      this.setState({ newDocument });
    }
  };

  handleAddFile = async () => {
    const { newDocument, documents } = this.state;
    if (!newDocument) return;

    const errors = await validateDocuments(newDocument);
    let errorsModalTitle = `Archivo ${newDocument.file.name} no válido`;
    const docsSize = documents.reduce(
      (acc, doc) => acc + doc.file.size,
      newDocument.file.size
    );
    if (docsSize > 2048000) {
      errorsModalTitle = "Aviso";
      errors.push(
        "No es posible adjuntar este fichero ya que la suma del tamaño de los ficheros supera el tamaño máximo de 2MB."
      );
    }

    if (errors.length > 0) {
      this.setState({ errors, errorsModalTitle, errorsType: "info" });
      this.clearForm();
    } else {
      this.setState({ documents: [...documents, newDocument] }, () =>
        this.clearForm()
      );
    }
  };

  handleClickEjecutar = async () => {
    const userStorage: string = localStorage.getItem("loggedUser") || "{code:0}";
    const user = JSON.parse(userStorage);

    const { documents } = this.state;

    this.setState({ documents: [], processingMessage: true });

    await ProductsMediadoresServices.updatePermissionsByFile({
      loader: false,
      setLoading: () => {},
      user: user.user,
      files: documents.map((doc) => doc.file),
      userEmail: user.email,
    });
  };

  removeFile = () => {
    const { documents, fileToRemove } = this.state;
    const newDocuments = documents.filter(
      (doc) => doc.file.name !== fileToRemove
    );
    this.setState({ documents: newDocuments });
  };

  clearForm = () => {
    this.setState({
      newDocument: null,
    });
  };

  getFilesOptions = () => {
    const { documentTypes } = this.state;
    return documentTypes.map(({ label, value }) => ({ label, value }));
  };

  getFileAccept = () => {
    const { documentTypes, selectedType } = this.state;
    const fileTypes: DocumentTypes | undefined = documentTypes.find(
      (doc) => doc.value === selectedType
    );
    if (fileTypes) return fileTypes.fileAccept.join(", ");
  };

  render() {
    const {
      newDocument,
      selectedType,
      documents,
      fileToRemove,
      errorsModalTitle,
      errors,
      errorsType,
      processingMessage,
    } = this.state;
    const filesOptions = this.getFilesOptions();
    const fileAccept = this.getFileAccept();

    return (
      <PermissionsUploadContainer>
        <h3>Carga de Nuevos usuarios</h3>

        <div className="permissions">
          <div className="permissions-data">
            <div className="selectBox">
              <SelectBox
                optionsText={filesOptions}
                optionKey="value"
                optionValue="label"
                onChange={this.handleSelectFileType}
                className="required"
              />
              <label>Tipo de fichero</label>
            </div>
            <div className="colSin-100 topMargin20">
              <div className="colSin-60 rightPadding20">
                <div className="inputBox custom-file-upload">
                  <div className="colSin-70 required">
                    <input
                      type="text"
                      readOnly
                      value={newDocument ? newDocument.file.name : ""}
                    />
                  </div>

                  <div className="colSin-30">
                    <label className="custom-file-upload-button topMargin5">
                      <input
                        type="file"
                        onChange={this.handleSelectFile}
                        accept={fileAccept}
                      />
                      <MainButton
                        onClick={() => {
                          return true;
                        }}
                        text="Buscar"
                      />
                    </label>
                  </div>

                  <label>Adjuntar fichero</label>
                </div>
              </div>

              <div className="colSin-40 rightPadding20 topMargin15">
                <label className="custom-file-upload">
                  <div className="upload-buttons">
                    <MainButton
                      type={selectedType && newDocument ? "" : "disabled"}
                      onClick={this.handleAddFile}
                      text="Adjuntar Documento"
                      icon={true}
                      iconImg={images.IconUploadBlue}
                      disabled={selectedType && newDocument ? false : true}
                    />
                  </div>
                </label>
              </div>
            </div>

            <div className="colSin-100 topMargin20">
              {documents && documents.length > 0 && (
                <>
                  <MainTableContainer className="table noZebra uploadedTable">
                    <thead>
                      <tr>
                        {documentTableColumns.map((column, index) => (
                          <th key={`column-${index}`}>
                            <BodyText className="dimmed-mini-title">
                              <p>{column}</p>
                            </BodyText>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {documents.map((doc, index) => {
                        const { docType, file } = doc;
                        const size = getFileSize(file.size);

                        return (
                          <tr className="main-table-row" key={`row-${index}`}>
                            <td>
                              <BodyText className="dimmed-mini-body">
                                <p>{docType}</p>
                              </BodyText>
                            </td>
                            <td>
                              <BodyText className="dimmed-mini-body">
                                <p>{file.name}</p>
                              </BodyText>
                            </td>
                            <td className="action-buttons">
                              <div className="uploadedFileTable">
                                <MainButton
                                  key={`deleteBtnTable${index}`}
                                  onClick={() =>
                                    this.setState({ fileToRemove: file.name })
                                  }
                                  icon={true}
                                  iconImg={images.IconDeleteWhite}
                                />
                              </div>
                            </td>
                            <td>
                              <BodyText className="dimmed-mini-body">
                                <p>{size}</p>
                              </BodyText>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </MainTableContainer>
                  <p className="text-max-size">
                    * El tamaño máximo de la totalidad de ficheros a adjuntar es
                    de 2MB.
                  </p>
                </>
              )}
            </div>
          </div>

          <div className="permissions-action">
            <MainButton
              onClick={this.handleClickEjecutar}
              text="Ejecutar proceso de alta"
              disabled={documents.length <= 0}
            />
          </div>
        </div>

        <LeftInfoModal
          img={images.IconInfoBlue}
          className="info"
          mainTitle="¿Quieres eliminar este archivo?"
          buttonText="Aceptar"
          onClickAccept={this.removeFile}
          close={() => this.setState({ fileToRemove: "" })}
          active={fileToRemove}
        />

        <LeftInfoModal
          img={
            errorsType === "info" ? images.IconInfoBlue : images.IconWarningRed
          }
          className={errorsType}
          mainTitle={errorsModalTitle}
          buttonText="Aceptar"
          onClickAccept={() => {}}
          close={() => this.setState({ errors: [] })}
          active={errors.length > 0}
          list={errors}
          noSecondaryButton
          centerPrimaryButton
        />

        <LeftInfoModal
          img={images.IconInfoBlue}
          className="info"
          mainTitle="Aviso"
          mainText="El archivo va a procesarse en segundo plano."
          buttonText="Aceptar"
          onClickAccept={() => {}}
          close={() => this.setState({ processingMessage: false })}
          active={processingMessage}
          noSecondaryButton
          centerPrimaryButton
        />
      </PermissionsUploadContainer>
    );
  }
}

export default withGeneral(PermissionsUpload);
