import styled from "styled-components";
import { colors } from "../../../assets";

export const CampaignsConfigDetailContainer = styled.div`
  display: flex;
  font-family: Museo Sans;
  font-style: normal;
  .config-detail-body {
    width: 100%;
    &__title {
      font-weight: 500;
      font-size: 32px;
      line-height: 45px;
      color: ${colors["PR-001-100"]};
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      &__status {
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        margin-left: 16px;
        background: rgba(255, 230, 165, 0.2);

        color: ${colors["HV-Alert"]};
        border-radius: 3px;
        height: 32px;
        padding: 6px;
      }
    }

    &__back-button {
      margin-bottom: 6px;
    }
  }
  .red-status {
    color: #ED3C12;
    background: rgba(237, 60, 18, 0.2);
  }
  .yellow-status {
    color: #F3BC2D;
    background: rgba(243, 188, 45, 0.2);
  }
  .green-status {
    color: #40CAA9;
    background: rgba(64, 202, 169, 0.2);
  }
  .config-detail-side-bar {
    padding-left: 37px;
    &__button {
      width: 170px;
      margin-bottom: 16px;
      div {
        max-height: 40px;
      }
    }
    ::before {
      position: fixed;
    }
  }
  .config-detail-cards-container {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
  .config-detail-card {
    margin-bottom: 48px;
    width: 100%;
    background: ${colors["PR-001/5"]};
    padding: 52px;
    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: ${colors["PR-001-100"]};
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      &__info {
        padding: 8px 20px 8px 20px;
        background: rgb(130, 88, 101, 0.2);
        font-weight: 300;
        color: #632E3E;
        border-radius: 3px;
        font-size: 14px;
        line-height: 16px;
      }
      &__button {
        width: 170px;
      }
      div {
        max-height: 40px;
      }
    }
    &__body {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: ${colors['"TX-001-100"']};
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    &__item {
      margin-right: 50px;
      margin-bottom: 30px;
      &__title {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: ${colors["TX-001-50"]};
        margin-bottom: 6px;
      }
      &__text {
        font-size: 14px;
        line-height: 22px;
        color: ${colors['"TX-001-100"']};
      }
    }
    &__container {
      display: flex;
    }
    &__card {
      padding: 24px;
      margin-right: 16px;
      min-width: 200px;
      background: ${colors["FL-001-Default"]};
      &__line {
        margin-bottom: 24px;
        border: 0.5px solid ${colors["PR-001-100"]};
      }
      &__title {
        margin-bottom: 18px;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: ${colors["TX-001-100"]};
      }
      &__subtitle {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: ${colors["TX-001-80"]};
        margin-bottom: 6px;
      }
      &__detail {
        cursor: pointer;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: ${colors["SC-001-100"]};
      }
    }
  }
`;
