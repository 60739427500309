import styled from "styled-components";
import { colors } from "../../../assets/colors";

export const ManagerContainer = styled.div`
  margin-bottom: 10rem;

  .form-wrapper {
    padding: 20px;
    background-color: ${colors["detail-item-background"]};

    .form-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      justify-items: stretch;
      align-items: stretch;
      margin-bottom: 20px;
    }

    .textarea {
      margin-top: 15px;
    }

    .save-line {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .button-container {
        min-width: 150px;
      }
    }
  }

  .table {
    tbody {
      text-transform: initial;
    }

    td, tr, th {
      word-break: initial;
    }
    
    .visible {
      td {
        background-color: #d1e4f8;
      }
    }

    .future {
      td {
        background-color: #fff6ce;
      }
    }

    .past {
      td {
        background-color: #f7f7f7;
        p {
          color: #c5c5d5;
        }
      }
    }
    .action-buttons {
      min-width: 90px;

      > div {
        width: 35px;
        height: 35px;
      }
      
      div:first-child {
        margin-right: 16px;
      }

      .main-button-icon {
        justify-content: center;
        width: 100%;
        height: 100%;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .legend {
    display: flex;
    margin-top: 20px;
    margin-bottom: 50px;

    .item {
      display: flex;
      margin-right: 20px;
      align-items: center;

      .square {
        border: 1px solid black;
        width: 25px;
        height: 25px;
        margin-right: 5px; 
      }
    }
  }

  .visible {
    background-color: #d1e4f8;
  }
  .future {
    background-color: #fff6ce;
  }
  .past {
    background-color: #eee;
  }
  .draft {
    background-color: #ffffff;
  }
`;
