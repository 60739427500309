import React from "react";
import { SimulationResultContainer } from "./result-style";
import { RouteComponentProps, withRouter } from "react-router-dom";
import WebText from "../../../../components/components-text/web-text";
import MainTable from "../../../../components/main-table";
import { NewSimulationContainer } from "../new-simulation-style";

import HeadsTitleText from "../../../../components/components-text/heads-title-text";
import GuideLine from "../../../../components/guide-line";
import Title03Text from "../../../../components/components-text/title-03-text";
import HeadText from "../../../../components/components-text/head-text";
import NoteText from "../../../../components/components-text/note-text";
import MainButton from "../../../../components/main-button";
import { images } from "../../../../assets";
import { InflowSidebarContainer } from "../../../../components/inflow-sidebar/inflow-sidebar-style";
import LeftInfoModal from "../../../../components/left-info-modal";
import { withGeneral } from "../../../../context/mixin/with-general";
import { withPolicy } from "../../../../context/mixin/with-policy";
import { currencyFormat } from "../../../../utils";
import SimulationServices from "../../../../services/SimulationServices";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import { downloadFile, formatter } from "../../../../utils/utility";
import moment from "moment";
import { getDataForm } from "../../../../utils/mapData";
import { isValidEmail } from "../../../../utils/validation";
import { IHealthCoverage } from "../../../../constants/health";
import { withDynamicProducts } from "../../../../context/mixin/with-dynamic-products";
import { IUser } from "../../../../models/user-model";
import { withPayTypes } from "../../../../context/mixin/with-pay-types";
import PayTypesServices from "../../../../services/PayTypesServices";
import { IMediator } from "../../../../models/mediator-model";

interface TravelSimulationResultLayoutProps extends RouteComponentProps {
  setLoading: Function;
  data: any;
  policy: any;
  responseSimulation: any;
  coverages: any;
  mediator: any;
  productsByAreaName: any;
  user: IUser;
  allPayTypes: any;
  travelCountries: any;
}

interface TravelSimulationResultLayoutState {
  showLeftInfoModal: boolean;
  simulationSaved: boolean;
  leftInfoModalImg: string;
  leftInfoModalClassName: string;
  leftInfoModalTitle: string;
  leftInfoModalText: string;
  leftInfoModalButtonText: string;
  leftInfoModalClick: Function;
  leftInfoModalCloseButtonText?: string;
  leftInfoModalWithInput?: any;
  mediator: any;
  sendPDFEmail: {
    value: string,
    errorCode: string,
    labelText: string
  };
  document: any;
  simulationId: number
}

class TravelSimulationResultLayout extends React.Component<
  TravelSimulationResultLayoutProps,
  TravelSimulationResultLayoutState
> {
  constructor(props: TravelSimulationResultLayoutProps) {
    super(props);

    this.state = {
      mediator: {},
      simulationSaved: false,
      showLeftInfoModal: false,
      leftInfoModalImg: "",
      leftInfoModalClassName: "",
      leftInfoModalTitle: "",
      leftInfoModalText: "",
      leftInfoModalButtonText: "",
      leftInfoModalClick: () => { },
      leftInfoModalCloseButtonText: "",
      leftInfoModalWithInput: null,
      sendPDFEmail: {
        value: "",
        errorCode: "",
        labelText: "Email"
      },
      document: null,
      simulationId: -1
    };
  }

  componentDidMount = async() => {
    const { user } = this.props;

    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);

    this.setState({ mediator: user });
  };

  async saveSimulation() {
    const { policy, data, setLoading, user } = this.props;
    const { sendPDFEmail } = this.state;
    if (sendPDFEmail.value && data && data.taker) data.taker.email = sendPDFEmail.value;

    const mediator = JSON.parse(localStorage.getItem('loggedMediator') || '');
    data.loggedUser = `${user.name} ${user.lastname1} ${user.lastname2}`;
    data.mediatorUser = {
      mediator: localStorage.getItem('userName'),
      code: data.mediatorAssociatedToPolicy || mediator.mediatorCode,
      name: this.getMediatorName(mediator, data.mediatorAssociatedToPolicy || mediator.mediatorCode),
      delegation: user.delegation,
    }

    try {
      const savedSimulation = await SimulationServices.simulation({
        loader: true,
        setLoading: setLoading,
        data: {
          body: data,
          response: data.simulationResponse,
          productType: policy.product.products_internalName,
        },
      });
      this.setState({
        showLeftInfoModal: true,
        simulationSaved: true,
        leftInfoModalImg: images.IconSaveBlue,
        leftInfoModalClassName: "",
        leftInfoModalTitle: "La simulación se ha guardado",
        leftInfoModalText:
          "La simulación se ha guardado en “simulaciones pendientes”. Tiene una validez de 30 días. ",
        leftInfoModalButtonText: "Ir a pendientes",
        leftInfoModalClick: () =>
          this.props.history.push("/simulacion/pendiente"),
        leftInfoModalWithInput: null,
        simulationId: savedSimulation.id
      });
    } catch (err) {
      this.setState({
        showLeftInfoModal: true,
        leftInfoModalClassName: "error",
        leftInfoModalImg: images.WarningError,
        leftInfoModalTitle: "La simulación no se ha guardado",
        leftInfoModalText: "Se ha producido un error, prueba más tarde.",
        leftInfoModalButtonText: "Ir a pendientes",
        leftInfoModalClick: () =>
          this.props.history.push("/simulacion/pendiente"),
        leftInfoModalWithInput: null
      });
    }
  }

  getMediatorName = ( med: IMediator, selectedMediatorCode: string ): string => {
    if (med.mediatorCode === selectedMediatorCode) return med.mediatorName.trim();
    else return med.relatedMediators ? med.relatedMediators.find((m: any) => m.mediatorCode === selectedMediatorCode).mediatorName.trim() || '' : '';
  }

  getBodySimulation = () => {
    const { data, user, policy } = this.props;
    const loggedUser = `${user.name} ${user.lastname1} ${user.lastname2}`;

    return {

      insuredData: data.taker,
      loggedUser,
      // mediatorAssociatedToPolicy: data.mediator.mediatorCodeValue,
      // mediatorData: {
      //   name: data.mediator.mediatorValue,
      //   startDate: data.mediator.startDateValue
      // },
      //postalCode: data.mediator.postalCodeValue,
      priceData: data.simulationResponse,
    }
  }

  hireSimulation() {
    const { history, data, productsByAreaName, policy } = this.props;
    const { simulationId } = this.state;
    const body = this.getBodySimulation();
    const productHiringLink = policy.product.products_link
    const pathname = productHiringLink && productHiringLink.split('?')[0] ? productHiringLink.split('?')[0] : undefined

    this.setState({
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconArrowRightBlue,
      leftInfoModalClassName: "",
      leftInfoModalTitle: "¿Quieres contratar esta simulación?",
      leftInfoModalText: "",
      leftInfoModalButtonText: "Si",
      leftInfoModalClick: () => {
        let dataToPush: any = {};
        dataToPush.insureds = [];
        dataToPush.taker = data.taker;
        dataToPush.mediatorData = data.mediator;

        const mediator = JSON.parse(localStorage.getItem('loggedMediator') || '');

        history.push({
          pathname: `/contratacion${pathname}`,
          state: {
            dataForm: getDataForm(
              body,
              policy.product.products_internalName,
              policy.product.products_coverageName,
              productsByAreaName
            ),
            data: {
              ...dataToPush,
              payment: {},
              beneficiaries: [],
              loan: {},
              signData: {
                signId: "",
                signRequestDate: "",
                signStatus: ""
              },

            },
            simulationId,
            selectedMediatorCode: data.mediatorAssociatedToPolicy || mediator.mediatorCode
          },
        })
      }

    });
  }
  downloadPDFButton() {
    this.setState({
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconDownloadBlue,
      leftInfoModalClassName: "",
      leftInfoModalTitle: "¿Quieres descargar el presupuesto?",
      leftInfoModalText: "",
      leftInfoModalButtonText: "Descargar",
      leftInfoModalClick: () => {
        this.downloadSimulation();
      },
      leftInfoModalWithInput: null
    });
  }

  async getSimulationDocument(document: any, email: boolean) {
    const { policy, data, mediator } = this.props;
    const { sendPDFEmail } = this.state;

    return SimulationServices.getSimulationDocument(true, this.props.setLoading, {
      policyType: policy.product.products_internalName,
      mediatorCode: mediator.mediatorCode,
      mediator: mediator.mediatorName,
      simulationId: data.simulationResponse.requestNumber.toString(),
      businessLine: policy.product.ln_linea_negocio,
      bufferBase64: document.data,
      ...(email && {email: sendPDFEmail.value})
    })
  }

  async downloadSimulation() {
  try {
    const { policy, data } = this.props;
    const { document } = this.state;
    let doc: any = null;

    if (!document) {
      const docAux = await SimulationServices.getDocument({
        setLoading: () => { },
        businessLine: policy.product.ln_linea_negocio,
        requestNumber: data.simulationResponse.requestNumber.toString(),
      })
      this.setState({ document: docAux })
      doc = await this.getSimulationDocument(docAux, false)
    } else {
      doc = await this.getSimulationDocument(document, false)
    }
    if (doc.status === 204) {
      this.showModalDocNotAvailable();
    }
    else downloadFile(doc.fileData, doc.fileName, doc.fileType);
  } catch (error) {
    console.error(error)
    this.showModalError()
  }
}


showModalDocNotAvailable = (
  title: string = 'No se ha podido recuperar el PDF', 
  message: string = 'La documentación se está generando. Por favor, inténtelo de nuevo transcurridos unos segundos.', 
  buttonText: string= 'Aceptar'
) => {
  this.setState({
    ...this.state,
    showLeftInfoModal: true,
    leftInfoModalClassName: "info",
    leftInfoModalImg: images.IconWarningYellow,
    leftInfoModalTitle: title,
    leftInfoModalText: message,
    leftInfoModalButtonText: buttonText,
    leftInfoModalWithInput: null,
    leftInfoModalClick: () => { },
  });
}


showExitModal = () => {
  this.setState({
    showLeftInfoModal: true,
    leftInfoModalClassName: "",
    leftInfoModalImg: images.WarningError,
    leftInfoModalTitle: "¿Desea salir del proceso?",
    leftInfoModalText: "",
    leftInfoModalButtonText: "Salir",
    leftInfoModalClick: () =>
      this.props.history.push("/simulacion/pendiente"),
    leftInfoModalWithInput: null
  });
};

closeLeftModal() {
  this.setState({ showLeftInfoModal: false });
}

closeEmailModal() {
  this.setState({
    ...this.state,
    showLeftInfoModal: false,
    leftInfoModalCloseButtonText: ""
  });
}

showModalError = (
  title: string = 'No se ha podido recuperar el PDF',
  message: string = 'Se ha producido un error, inténtelo de nuevo',
  buttonText: string = 'Cerrar'
) => {
  this.setState({
    ...this.state,
    showLeftInfoModal: true,
    leftInfoModalClassName: "error",
    leftInfoModalImg: images.WarningError,
    leftInfoModalTitle: title,
    leftInfoModalText: message,
    leftInfoModalButtonText: buttonText,
    leftInfoModalClick: () => { },
    leftInfoModalWithInput: null
  });
}

validateHandler = async () => {
  try {
    const { sendPDFEmail, leftInfoModalWithInput } = this.state;
    const { policy, data } = this.props;
    if (!sendPDFEmail.value || !isValidEmail(sendPDFEmail.value)) {
      this.setState({
        sendPDFEmail: {
          ...sendPDFEmail,
          errorCode: "invalid-email"
        },
        leftInfoModalWithInput: {
          ...leftInfoModalWithInput,
          errorCode: "invalid-email"
        }
      });
      return;
    }
    this.closeEmailModal();
    const document = await SimulationServices.getDocument({
      setLoading: () => { },
      businessLine: policy.product.ln_linea_negocio,
      requestNumber: data.simulationResponse.requestNumber.toString(),
    });
    this.getSimulationDocument(document, true)
      .then((response) => {
        if (response.status === 204) {
          this.showModalDocNotAvailable();
        } else {
          this.setState({
            ...this.state,
            showLeftInfoModal: true,
            leftInfoModalImg: images.IconContactCollapsedWhite,
            leftInfoModalClassName: "",
            leftInfoModalTitle: "El pdf se ha enviado por correo",
            leftInfoModalText:
              "El presupuesto se ha enviado satisfactoriamente al correo suministrado.",
            leftInfoModalCloseButtonText: "Cerrar",
            leftInfoModalButtonText: "Aceptar",
            leftInfoModalClick: () => { },
            leftInfoModalWithInput: null
          });
        }
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          showLeftInfoModal: true,
          leftInfoModalClassName: "error",
          leftInfoModalImg: images.WarningError,
          leftInfoModalTitle: "No se ha podido guardar la simulación",
          leftInfoModalText:
            "Se ha producido un error, verifique que haya ingresado un email válido.",
          leftInfoModalButtonText: "Cerrar",
          leftInfoModalClick: () => { },
          leftInfoModalWithInput: null
        });
        throw error;
      });

  } catch (error) {
    console.error(error)
    this.showModalError()
  }
};

sendPdfEmailClick = async () => {
  const { sendPDFEmail } = this.state;
  this.setState({
    ...this.state,
    showLeftInfoModal: true,
    leftInfoModalImg: images.IconContactCollapsedWhite,
    leftInfoModalClassName: "",
    leftInfoModalTitle: "Envío del PDF",
    leftInfoModalText: 'Por favor, ingrese un email para el envio del PDF',
    leftInfoModalButtonText: "Enviar",
    leftInfoModalClick: this.validateHandler,
    leftInfoModalWithInput: {
      ...sendPDFEmail,
      onChange: (value: string) => {
        this.setState({
          ...this.state,
          sendPDFEmail: { ...sendPDFEmail, value },
        })
      }
    }
  });
};

getPaymentTableColumns = () => {
  try {
    return [
      {
        text: "FORMA DE PAGO",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "PRIMA NETA",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "IMPUESTOS Y CONSORCIO",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "TOTAL RECIBO",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ]
  } catch (error) {
    console.error(error);
  }
}

getPaymentTableRows = () => {
  try {
    const { data, allPayTypes } = this.props;
    return [
      {
        payType: {
          text: data && data.simulationResponse && Array.isArray(data.simulationResponse.payments) ? PayTypesServices.getPayTypeByCode(allPayTypes, data.simulationResponse.payments[0].payType).description : "Pago único",
          filter: false,
          ascendent: true,
          key: "entire-mini-title",
        },
        netPremium: {
          text: data && data.simulationResponse && Array.isArray(data.simulationResponse.payments) ? formatter.format(Number(data.simulationResponse.payments[0].netPremium)) : "",
          filter: false,
          ascendent: true,
          key: "dimmed-mini-title",
        },
        taxs: {
          text: data && data.simulationResponse && Array.isArray(data.simulationResponse.payments) ? formatter.format(Number(data.simulationResponse.payments[0].taxs)) : "",
          filter: false,
          ascendent: true,
          key: "dimmed-mini-title",
        },
        totalPremium: {
          text: data && data.simulationResponse && Array.isArray(data.simulationResponse.payments) ? formatter.format(Number(data.simulationResponse.payments[0].totalPremium)) : "",
          filter: false,
          ascendent: true,
          key: "dimmed-mini-title",
        },
      },
    ]
  } catch (error) {
    console.error(error);
  }
}

getTravelTableColumns = () => {
  try {
    return [
      {
        text: "DESTINO DEL VIAJE",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "CANTIDAD DE PASAJEROS",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ]
  } catch (error) {
    console.error(error);
  }
}

getTravelTableRows = () => {
  try {
    const { data, travelCountries } = this.props;

    return [
      {
        destination: {
          text: data && data.taker && data.taker.policy && data.taker.policy.travelDestination && Object.keys(travelCountries).length
            ? travelCountries[data.taker.policy.travelDestination]
            : "",
          filter: false,
          ascendent: true,
          key: "entire-mini-title",
        },
        numInsureds: {
          text: data && data.taker ? data.taker.policy.numPassengers : "",
          filter: false,
          ascendent: true,
          key: "dimmed-mini-title",
        },
      },
    ]
  } catch (error) {
    console.error(error);
  }
}

render() {
  const { policy, data } = this.props;
  const {
    mediator,
    simulationSaved,
    showLeftInfoModal,
    leftInfoModalImg,
    leftInfoModalClassName,
    leftInfoModalTitle,
    leftInfoModalText,
    leftInfoModalButtonText,
    leftInfoModalClick,
    leftInfoModalCloseButtonText,
    leftInfoModalWithInput
  } = this.state;

  return (
    <NewSimulationContainer>
      <div className="new-simulation-flow-status">
        <div className="new-simulation-flow-status-title">
          <HeadsTitleText>
            <p>Resultado de la simulación</p>
          </HeadsTitleText>
        </div>
        <div className="new-simulation-flow-status-guide">
          <GuideLine steps={[0, 0, 0]} currentStep={2} />
        </div>
      </div>

      <SimulationResultContainer colorSpan={"travel-blue"}>
        <div className="result-hero-container">
          <div className="result-hero-type">
            <p>
              Póliza {policy && policy.product && policy.product.products_name}
            </p>
          </div>
          <div className="result-hero-data">
            <div className="result-hero-data__name">
              <p>
                {mediator.name} {mediator.lastname1} {mediator.lastname2}
              </p>
            </div>
            <div className="result-hero-data__nif">
              <p>{data.mediatorCode || mediator.code}</p>
            </div>
            <div className="result-hero-data__phone">
              <p>{mediator.phone}</p>
            </div>
            <div className="result-hero-data__email">
              <p>{mediator.email}</p>
            </div>
          </div>
        </div>
        <div className="result-welcome">
          <div className="result-welcome__title">
            <p>Estimado/a Sr./Sra:</p>
          </div>
          <div className="result-welcome__description">
            <p>
              En base a la información que nos ha proporcionado, le hemos
              preparado un presupuesto de seguro personalizado para que pueda
              evaluar las ventajas en precio y coberturas que le ofrece ASISA.
            </p>
          </div>
        </div>

        <div className="result-simulation-special">
          <div className="result-simulation-special-title">
            <Title03Text>
              <p>{`ID: ${data && data.simulationResponse ? data.simulationResponse.requestNumber : ''}`}</p>
            </Title03Text>
          </div>
          <div className="result-simulation-special-container">
            <div className="result-simulation-special-item">
              <HeadText>
                <p>
                  Póliza {policy && policy.product && policy.product.products_name}
                </p>
              </HeadText>
            </div>
            <div className="result-simulation-special-item">
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>Fecha inicio del viaje</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>{data && data.taker && data.taker.policy ? moment(data.taker.policy.departureDate, "DD/MM/YYYY").format("DD/MM/YYYY") : ""}</p>
                  </WebText>
                </div>
              </div>
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>Fecha fin del viaje</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>{data && data.taker && data.taker.policy ? moment(data.taker.policy.returnDate, "DD/MM/YYYY").format("DD/MM/YYYY") : ""}</p>
                  </WebText>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="result-simulation-container">
          <div className="result-simulation-item">
            <div className="result-simulation-item-title">
              <Title03Text>
                <p>Datos del viaje</p>
              </Title03Text>
            </div>
            <div className="result-simulation-item-content">
              <div className="result-simulation-item-content-table">
                <MainTable
                  className="table noZebra"
                  columns={this.getTravelTableColumns()}
                  rows={this.getTravelTableRows()}
                  keysRow={["destination", "numInsureds"]}
                />
              </div>
            </div>
          </div>
          <div className="result-simulation-item">
            <div className="result-simulation-item-title">
              <Title03Text>
                <p>Garantías</p>
              </Title03Text>
            </div>
            <div className="result-simulation-item-content">
              <div className="result-simulation-item-content-table">
                <>
                  <div className="colSin-50 rightPadding10">
                    {data.policyData.coverages.filter((cov: IHealthCoverage) => cov.required === true)
                      .map((coverage: IHealthCoverage, item: number) => (
                        <label
                          className={`checkbox-container${(coverage.required) ? ' noPointer' : ''}`}
                          key={item}>
                          <input
                            type="checkbox"
                            checked={coverage.required || coverage.checked}
                            onChange={(e) => ''}
                          />
                          <div className={`checkmark-container-readonly`}>
                            <div className="checkmark-item">
                              <img src={(coverage.required || coverage.checked) ? images.IconTickWhite : images.IconCloseWhite} alt="" />
                            </div>
                          </div>
                          <div className="sc-iwsKbI eLRFbD">
                            <p>{coverage.description}</p>
                          </div>
                        </label>

                      ))}
                  </div>

                  <div className="colSin-50 rightPadding10">
                    {data.policyData.coverages.filter((cov: IHealthCoverage) => cov.required === false)
                      .map((coverage: IHealthCoverage, item: number) => (
                        <div>

                          <label
                            className={`checkbox-container${(coverage.required) ? ' noPointer' : ''}`}
                            key={item}>

                            <input
                              type="checkbox"
                              checked={(coverage.required || coverage.checked)}
                              onChange={(e) => ''}
                            />
                            <div className={
                              `checkmark-container-readonly`
                            }>
                              <div className="checkmark-item">
                                <img src={
                                  ((coverage.required ||
                                    coverage.checked)) ? images.IconTickWhite : images.IconCloseWhite} alt="" />
                              </div>
                            </div>
                            <div className="sc-iwsKbI eLRFbD">
                              <p>{coverage.description}</p>
                            </div>
                          </label>
                        </div>
                      ))}
                  </div>
                </>
              </div>
            </div>
          </div>

          <div className="result-simulation-item">
            <div className="result-simulation-item-title">
              <Title03Text>
                <p>Desglose por forma de pago</p>
              </Title03Text>
            </div>
            <div className="result-simulation-item-content">
              <div className="result-simulation-item-content-table">
                <MainTable
                  className="table noZebra"
                  columns={this.getPaymentTableColumns()}
                  rows={this.getPaymentTableRows()}
                  keysRow={["payType", "netPremium", "taxs", "totalPremium"]}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="result-simulation-footer">
          <ul>
            <NoteText>
              <li>
                * Encontrará la nota informativa anexa en el documento
                Resultado de Simulación PDF, que puede descargar
              </li>
            </NoteText>
            <NoteText>
              <li>
                * Este presupuesto tiene validez durante 30 días naturales,
                siempre que se mantengan las circunstancias declaradas por los
                asegurados. La aceptación del proyecto está supeditada a la
                cumplimentación de la solicitud de seguro y cuestionarios de
                salud asegurados. Así mismo, ASISA se reserva el derecho de la
                aceptación del riesgo.
              </li>
            </NoteText>
            <NoteText>
              <li>
                * Este presupuesto se emite a efectos puramente informativos y
                no se puede considerar una propuesta de seguro, por lo que
                carece de valor contractual. La prima reflejada está sujeta a
                la veracidad de la información aquí recogida así como a la no
                existencia de enfermedad grave alguna o accidente del
                asegurado, que pueda ser causa de fallecimiento, incapacidad
                permanente absoluta o cualquier otra contingencia objeto de
                cobertura, y está basada en la tarifa vigente a la fecha del
                presente presupuesto. Las coberturas y su alcance se regirán
                por lo establecido en las Condiciones Generales, Particulares
                y Especiales de la Póliza.
              </li>
            </NoteText>
          </ul>
        </div>
      </SimulationResultContainer>

      <InflowSidebarContainer>
        <div className="inflow-sidebar">
          <div className="inflow-buttons-container">
            <div className="inflow-buttons-item">
              <MainButton text="Contratar" onClick={() => this.hireSimulation()} />
            </div>
            <div className="inflow-buttons-item">
              <MainButton
                type="full-light"
                text="Guardar"
                disabled={simulationSaved}
                icon
                iconImg={images.IconSaveWhite}
                onClick={() => this.saveSimulation()}
              />
            </div>
            <div className="inflow-buttons-item">
              <MainButton
                type="full-light"
                text="Descargar PDF"
                icon
                iconImg={images.IconDownloadWhite}
                onClick={() => this.downloadPDFButton()}
              />
            </div>
            <div className="inflow-buttons-item">
              <MainButton
                type="full-light"
                text="Enviar PDF por Email"
                icon
                iconImg={images.IconContactCollapsedWhite}
                onClick={() => this.sendPdfEmailClick()}
              />
            </div>
            <div className="inflow-buttons-item">
              <MainButton
                type="light"
                text="Terminar"
                onClick={() => this.showExitModal()}
              />
            </div>
          </div>
        </div>
      </InflowSidebarContainer>

      <LeftInfoModal
        img={leftInfoModalImg}
        close={() => this.closeLeftModal()}
        className={leftInfoModalClassName}
        mainTitle={leftInfoModalTitle}
        mainText={leftInfoModalText}
        buttonText={leftInfoModalButtonText}
        active={showLeftInfoModal}
        onClickAccept={leftInfoModalClick}
        closeButtonText={leftInfoModalCloseButtonText}
        withInput={leftInfoModalWithInput}
      />
    </NewSimulationContainer>
  );
}
}

export default withGeneral(withDynamicProducts(withCoverages(withPayTypes(withPolicy(withRouter(TravelSimulationResultLayout)))))
);
