import React from "react";
import { RouteComponentProps } from "react-router-dom";

import {
  isValidEmail,
  isValidDocumentID,
  isValidPhone,
  isValidPhone_ESP,
  isValidNumber,
} from "../utils/validation";
import { isValidIban } from "../utils/iban";
import moment from "moment";
import { IUser } from "../models/user-model";
import { IMediator } from "../models/mediator-model";
import { HEALTH_BENEFICIARIES_TYPES } from "../constants/health";
import { AREA_NAMES, getProductTypeFromURL, getProductTypes } from "../constants/policyType";
import PayTypesServices from "../services/PayTypesServices";
import { IInsuredCoveragesDataForm, IInsuredInsuranceProposal } from "../utils/interfaces";
import { IProtectionData } from "../components/cross/data-protection/interfaces";
import FlowServices from "../services/FlowServices";
import { FLOW_AREA } from "../constants/flow";

interface PolicyFunctionalProps extends RouteComponentProps {
  setGuideLine: any;
  setLoading: Function;
  user: IUser;
  mediator: IMediator;
  hasInformativeNoteLife?: boolean;
  hasInformativeNoteNotLife?: boolean;
  coverages?: any;
  match: any;
  checkProductArea: Function;
  allProducts: any;
}

interface PolicyFunctionalState {
  showLeftInfoModal: boolean;
  savingEntityError?: boolean;
  typeModal?: "info" | "error" | "warn";
  textModal?: string;
  listModal?: string[];
  secondaryText?: string;
  showExceptionModal: boolean;
  showPricesModal?: boolean;
  showWarningsHiring?: boolean;
  showTravelWarningsHiring?: boolean;
  showPaymentInfoModal?: boolean;
  showRetroactiveDate?: boolean;
  data: any;
  dataForm: any;
  validateForm: boolean;
  addInsured: boolean;
  indexRemove: number;
  responsePolicy: any;
  showInsuredModal: boolean;
  hiring: boolean;
  showInfo: boolean;
  pathname?: string;
  showHiringModal?: boolean;
  saveHiring?: boolean;
  changeState?: boolean;
  isEdit: any;
  simulationId?: any;
  hiringId?: any;
  fromSimulation?: boolean;
  goToDetail?: number;
  saveInsured?: boolean;
  saveQuestionnaire?: boolean,
  resumeHiring?: boolean,
  resumeDentalHiring?: boolean,
  isReadOnly?: boolean,
  sendToSign?: string,
  changeInsuredModalShow?: boolean,
  changeInsuredModalShowIndex?: number,
  removeInsured?: boolean;
  infoModalShow: boolean;
  infoModalText: string;
  haveAnyChanges?: boolean;
  showCancelHiringModal?: boolean;
  downloadError?: boolean;
  selectedMediatorCode: string;
  leftInfoModalClassName?: string;
  leftInfoModalTitle?: string;
  leftInfoModalText?: string;
  leftInfoModalCloseButtonText?: string;
  leftInfoModalButtonText?: string;
  leftInfoModalClick?: any,
  showSendInfoModal?: boolean;
  showNewSignInfoModal?: boolean;
  showSignedInfoModal?: boolean;
  downloadErrorMessage?: string;
  cancelSignModalShow?: boolean;
  product: any;
  prices: any;
  setProductsDataError?: boolean;
  document?: any;
  saveModalTravelShow?: boolean;
  productPayTypes: any[];
  showListCompaniesException?:boolean;
  goToValidatePet?: boolean;
}

interface IParsedProtectionData {
  consentDate: string;
  dataTransfer: string;
  thirdParty: string;
  thirdPartyCalls: string;
}

class PolicyFunctional extends React.Component<
  PolicyFunctionalProps,
  PolicyFunctionalState
> {
  componentDidMount = async () => {
    window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    if (
      !this.props.history.location.state ||
      Object.keys(this.state.product).length === 0
    ) {
      await this.setPolicyType();
      this.handleNewChanges(true);
    }else if(Object.keys(this.state.product).length !== 0){
      if (Object.keys(this.state.productPayTypes).length === 0) {
        await this.getProductPayTypes(this.state.product.products_id)
      }
      await this.getProductFields(this.state.product);
    }
  };

  componentDidUpdate(prevProps: Readonly<PolicyFunctionalProps>, prevState: Readonly<PolicyFunctionalState>, snapshot?: any): void {
    const { allProducts } = this.props
    if (Object.keys(prevProps.allProducts).length === 0 && Object.keys(allProducts).length !== 0) {
      this.setPolicyType();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
  }

  handleNewChanges = (isSaved: boolean = false) => {
    this.setState({ haveAnyChanges: !isSaved })
  }

  handleWindowBeforeUnload = (ev: BeforeUnloadEvent) => {
    const { history } = this.props;
    const pathnameShow = [
      "/nueva/mascotas/",
      "/nueva/decesos/",
      "/nueva/vida/",
      "/nueva/miembros",
      "/nueva/salud",
    ];
    ev.preventDefault();
    const filter = pathnameShow.filter((i) => {
      return history.location.pathname.indexOf(i) > -1;
    });
    if (filter.length > 0) ev.returnValue = `Are you sure you want to leave?`;
    return "Unsaved changes. Are you sure?";
  };

  isHiring = (url: string) => {
    let urlParts = url.split('/')
    let isHiring = false

    urlParts.forEach((element: any) => {
      if (element === 'contratacion') {
        isHiring = true
      }
    })

    return isHiring
  }

  redirect = () => {
    let isHiring = this.isHiring(location.pathname)
    if (isHiring) {
      this.props.history.push(`/contratacion/nueva/`)
    } else {
      this.props.history.push(`/simulacion/nueva/`)
    }
  }

  checkURLChange = async (product: any) => {
    if (Object.keys(product).length) {

      let hasProductChanged = await this.checkProductChange(product)

      if (hasProductChanged) {
        localStorage.removeItem('internalName')
        this.setState({ product: {} });
        this.redirect()
      }
    }
  }

  checkProductChange = async (product: any) => {
    let hasChanged = false

    let isHiring = this.isHiring(location.pathname)
    let productTypes = await getProductTypes(isHiring)

    let productLink = isHiring ? product.products_link : product.products_linkSimulacion
    let productType = getProductTypeFromURL(productLink)
    let urlProductType = getProductTypeFromURL(location.pathname)

    if (!productTypes.includes(urlProductType)) {
      return false
    }

    if (urlProductType === productType) {

      let urlQuery = location.search
      let productHasQuery = productLink.indexOf('?') !== -1

      if (productHasQuery && urlQuery === '' || !productHasQuery && urlQuery !== '') {
        hasChanged = true
      }
    } else {
      hasChanged = true
    }

    return hasChanged
  }

  getProduct = () => {
    const { allProducts } = this.props;
    const product_internalName = localStorage.getItem('internalName')

    if(product_internalName && Object.keys(allProducts).length && allProducts[product_internalName]){
      return allProducts[product_internalName]
    }else{
      return {}
    }
  }

  getProductPayTypes = async(productId: any) => {
    const args = {
      loader: true,
      setLoading: () => { },
      productId
    }
    const productPayTypes = await PayTypesServices.getProductPayTypes(args)
    this.setState({productPayTypes})
  }

  getProductFields = async(product: any) => {
    const { checkProductArea, history } = this.props;
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, product.products_internalName);
    if (!isHealthArea) return;
    
    let flowService = null;
    const pathname = history.location.pathname;
    if (pathname.indexOf(FLOW_AREA.SIMULACION) > -1) flowService = FlowServices.getSimulationProductFields;
    if (pathname.indexOf(FLOW_AREA.CONTRATACION) > -1) flowService = FlowServices.getHiringProductFields;

    if (flowService) {
      const productName = product.products_internalName;
      const productFields = await flowService({loader: true, setLoading: this.props.setLoading, productName});
      if (productFields.ok) {
        this.setState({ product: { ...product, productFields: productFields.data }});
      }
      if (productFields.error) {
        this.setState({ showExceptionModal: true, typeModal: "error", textModal: "Vuelva a intentarlo más tarde." });
      }
    }
  }

  setPolicyType = async () => {
    const { history, allProducts } = this.props;
    const { productPayTypes } = this.state;

    const pathname = history.location.pathname;
    let product
    let product_internalName = localStorage.getItem('internalName') ? localStorage.getItem('internalName') : ''

    if (product_internalName && product_internalName !== '' && Object.keys(allProducts).length !== 0) {
      product = allProducts[product_internalName]
      //this.checkURLChange(product)   

      this.setState({ product })
      if(productPayTypes.length === 0){
        this.getProductPayTypes(product.products_id)
      }

      if (!product.productFields) {
        this.getProductFields(product);
      }
    } else {
      this.setState({ product: {}, productPayTypes: [] });
      if (product_internalName === '') {
        this.redirect()
      }
    }
  };

  handleLeftInfoModal() {
    this.setState({ showLeftInfoModal: true, addInsured: true });
  }

  handleLeftInfoModalRemove(index: number) {
    this.setState({
      showLeftInfoModal: true,
      addInsured: false,
      indexRemove: index
    });
  }

  closeLeftModal() {
    this.setState({ showLeftInfoModal: false });
  }
  closeLeftSignModal() {
    this.setState({ showNewSignInfoModal: false });
  }
  closeSaveTravelModal() {
    this.setState({ saveModalTravelShow: false });
  }
  closeSignedInfoModal() {
    this.setState({ showSignedInfoModal: false });
  }
  infoModalOpen(infoModalText: string) {
    this.setState({ infoModalShow: true, infoModalText });
  }

  infoModalClose() {
    this.setState({ infoModalShow: false, infoModalText: "" });
  }

  closeExceptionModal() {
    this.setState({ showExceptionModal: false });
  }

  openExceptionModal(textModal: string, typeModal: "info" | "error" | "warn", exceptions?: string[], secondaryText?: string) {
    this.setState({ showExceptionModal: true, textModal, typeModal, listModal: exceptions || [], secondaryText });
  }

  closePricesModal() {
    this.setState({ showPricesModal: false });
  }

  openPricesModal(textModal: string, typeModal: "info" | "error", exceptions?: string[]) {
    this.setState({ showPricesModal: true, textModal, typeModal, listModal: exceptions || [] });
  }

  closeShowWarningsHiring() {
    this.setState({ showWarningsHiring: false });
  }

  openShowWarningsHiring(textModal: string, typeModal: "info" | "error", exceptions?: string[], secondaryText?: string) {
    this.setState({ showWarningsHiring: true, textModal, typeModal, listModal: exceptions || [], secondaryText });
  }

  closeShowTravelWarningsHiring() {
    this.setState({ showTravelWarningsHiring: false });
  }

  openShowTravelWarningsHiring(textModal: string, typeModal: "info" | "error", exceptions?: string[], secondaryText?: string) {
    this.setState({ showTravelWarningsHiring: true, textModal, typeModal, listModal: exceptions || [], secondaryText });
  }

  
  closeShowRetroactiveDate() {
    this.setState({ showRetroactiveDate: false });
  }

  openShowRetroactiveDate(textModal: string, typeModal: "info", exceptions?: string[], secondaryText?: string) {
    this.setState({ showRetroactiveDate: true, textModal, typeModal, listModal: exceptions || [], secondaryText });
  }


  closeInsuredModal() {
    this.setState({ showInsuredModal: false });
  }

  openInsuredModal() {
    this.setState({ showInsuredModal: true });
  }

  openCancelHiringModal = () => {
    this.setState({ showCancelHiringModal: true });
  }

  closeCancelHiringModal = () => {
    this.setState({ showCancelHiringModal: false });
  }

  changeData = () => {
    const { validateForm } = this.state;
    if (validateForm) {
      this.setState({ validateForm: false });
    }
  };

  validateForm = (formData: any, type?: string, block?: string, isEdit?: any, isNotOk?: boolean, insuredIndex?: string) => {
    if (!formData) return formData;
    const { data, dataForm, product } = this.state;
    const { checkProductArea } = this.props;
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, product.products_internalName)
    const isPetArea = checkProductArea(AREA_NAMES.PET, product.products_internalName)
    if (product.products_areaName === AREA_NAMES.TRAVEL) return this.validateTravelForm(formData);
    let isOk = true;
    let blocks = [];
    if (block) blocks.push(block);
    else blocks = Object.keys(formData);

    let docNumber = "";
    let numChip = "";
    const urlString = window.location.href;
    const url = new URL(urlString);

    blocks.forEach((b) => {
      if (!formData[b]) return;
      Object.keys(formData[b]).forEach((key) => {
        if (key === "country" && b === "foreignAddress" && type === "insured" && formData.coverages && formData.coverages["DC04"] && formData.coverages["DC04"].value) {
          if (!formData[b][key].value || formData[b][key].value.value === '') {
            formData[b][key].error = true;
            formData[b][key].errorCode = "required";
            isOk = false;
          }
          if (formData[b][key].value && formData[b][key].value.value !== '') {
            formData[b][key].error = false;
            formData[b][key].errorCode = "";
          }
        }
        else if (key === "checkedConditions" && formData[b][key].required && (formData[b][key].value === "" || !formData[b][key].value)) {
          formData[b][key].error = true;
          formData[b][key].errorCode = "required";
        }
        else if ((key === "country" || key === "address") && (type === "taker" || (formData.coverages && formData.coverages["DC04"] && !formData.coverages["DC04"].value))) { }
        else if (key === "numPets") {
          if(formData[b][key].value===""){
            formData[b][key].error = true;
            formData[b][key].errorCode = "required";
          }
          else if(parseInt(formData[b][key].value, 10) < 1){
            formData[b][key].error = true;
            formData[b][key].errorCode = "positive-number-error";
          }
          else if(parseInt(formData[b][key].value, 10) > 5 && !isPetArea){
            formData[b][key].error = true;
            formData[b][key].errorCode = "max-num-of-pets";
          }
          else if(parseInt(formData[b][key].value, 10) > 10&& isPetArea){
            formData[b][key].error = true;
            formData[b][key].errorCode = "max-error-mascots-10";
          }
          else {
            formData[b][key].error = false;
            formData[b][key].errorCode = "";
          }
        }
        else if (b === "address2" && formData[b][key].disabled) {
          formData[b][key].error = false;
          formData[b][key].errorCode = "";
        }
        else if ((formData[b][key].required && formData[b][key].value === "") || (key === "docNumber" && formData[b][key].value === "MENOR")) {
          if (key === "docNumber" && formData.contact.docType.value === "MENOR" && formData[b][key].value === "") {
            formData.contact.docNumber.value = "MENOR";
          }
          if (key === "docNumber" && (formData.contact.docType.value === "NOID" || formData.contact.docType.value === "MENOR")) { }
          else {
            formData[b][key].error = true;
            formData[b][key].errorCode = "required";
            isOk = false;
          }
        }
        else if (key === "birthdate" && formData[b][key].required && (!formData[b][key].value || !(formData[b][key].value.indexOf("/") >= 0 || formData[b][key].value.indexOf(":") >= 0))) {
          formData[b][key].error = true;
          formData[b][key].errorCode = "required";
          isOk = false;
        } else if (
          isDeathArea &&
          type !== "taker" &&
          key === "birthdate" &&
          moment().diff(moment(formData[b][key].value, 'DD/MM/YYYY'), "years") > 70
        ) {
          formData[b][key].errorCode = "invalid-70-age";
          formData[b][key].error = true;
          isOk = false;
        }
        else if (key === "email" && type !== "taker" && type !== "beneficiary" && formData[b][key].value !== "" && !isValidEmail(formData[b][key].value)) {
          formData[b][key].errorCode = "invalid-email";
          formData[b][key].error = true;
          isOk = false;
        }
        else if (key === "docNumber" && (formData.contact.docType.value !== "NOID" || formData.contact.docType.value !== "MENOR")) {
          docNumber = formData[b][key].value;
          const insureds = insuredIndex ? data.insureds.filter((insured: any, index: number) => index.toString() !== insuredIndex).map((i: any) => i)
            : data.insureds.map((i: any) => i);
          const existInsuredWithTakerData = dataForm.insureds && dataForm.insureds.length > 0 ? dataForm.insureds.some((insured: any) => insured.useTakerData) : false;
          let repeat = false;
          if (type === "taker" && (!existInsuredWithTakerData)) {
            repeat = !!insureds.filter((i: any) => i.docNumber && i.docNumber.toLowerCase() === docNumber.toLowerCase()).length;
          }
          else if (type !== "taker" && !formData.useTakerData) {
            repeat = !!insureds.filter(
              (i: any, index: number) =>
                isEdit.index && i.docNumber && i.docNumber.toLowerCase() === docNumber.toLowerCase() && index.toString() !== isEdit.index.toString()).length ||
              (data.taker && data.taker.docNumber.toLowerCase() === docNumber.toLowerCase());
          }
          if (formData[b][key].required && formData[b][key].value === "") {
            formData[b][key].errorCode = "required";
            formData[b][key].error = true;
            isOk = false;
          }
          else if (!isValidDocumentID(formData[b][key].value, formData[b]["docType"].value) && formData[b][key].value !== "") {
            formData[b][key].errorCode = "invalid-format";
            formData[b][key].error = true;
            isOk = false;
          }
          else if (repeat && formData[b][key].value !== "") {
            formData[b][key].errorCode = "dni-repeat";
            formData[b][key].error = true;
            isOk = false;
          }
          else {
            formData[b][key].error = false;
            formData[b][key].errorCode = "";
          }
        }
        else if (key === "numChip") {
          numChip = formData[b][key].value;
          const insureds = data.insureds.map((i: any) => i);
          let repeat = false;
          if (type !== "taker") {
            const indexInsured = parseInt(url.searchParams.get("index") || "-1", 10);
            repeat = !!insureds.filter((i: any, index: number) => i.numChip && i.numChip.toLowerCase() === numChip.toLowerCase() && 
              indexInsured!==index).length;
          }
          if (numChip.length !== 15 || !isValidNumber(numChip)) {
            formData[b][key].errorCode = "invalid-num-chip";
            formData[b][key].error = true;
            isOk = false;
          }
          else if (repeat) {
            formData[b][key].errorCode = "num-chip-repeat";
            formData[b][key].error = true;
            isOk = false;
          }
          else {
            formData[b][key].error = false;
            formData[b][key].errorCode = "";
          }
        }
        else if (key === "landlinePhoneNumber" && !isValidPhone_ESP(formData[b][key].value)) {
          formData[b][key].errorCode = "invalid-format";
          formData[b][key].error = true;
          isOk = false;
        }
        else if (key === "mobile" && !isValidPhone(formData[b][key].value)) {
          formData[b][key].errorCode = "invalid-format";
          formData[b][key].error = true;
          isOk = false;
        }
        else if (key === "capital" && (formData[b][key].value > formData[b][key].max || formData[b][key].value < formData[b][key].min)) {
          formData[b][key].errorCode = formData[b][key].value > formData[b][key].max ? "max-error" : "min-error";
          formData[b][key].error = true;
          isOk = false;
        }
        else if (key === "capital" && formData[b][key].value !== "" && !isValidNumber(formData[b][key].value)) {
          formData[b][key].errorCode = "number-error";
          formData[b][key].error = true;
          isOk = false;
        }
        else if (key === "iban" && !isValidIban(formData[b][key].value) && type === "taker") {
          formData[b][key].errorCode = "invalid-iban";
          formData[b][key].error = true;
          isOk = false;
        }
        else if (b === "coverages") {
          if(isPetArea && formData[b][key].otherInsurance && formData[b][key].otherInsurance!==null){
            if(formData[b][key].otherInsurance.company.required && formData[b][key].otherInsurance.company.value === ""){
              formData[b][key].otherInsurance.company.error = true;
              formData[b][key].otherInsurance.company.errorCode = "required";
              isOk = false;
            }
            else {
              formData[b][key].otherInsurance.company.errorCode = "";
              formData[b][key].otherInsurance.company.error = false;
            }
            if(formData[b][key].otherInsurance.numberPolicy.required && formData[b][key].otherInsurance.numberPolicy.value === ""){
              formData[b][key].otherInsurance.numberPolicy.error = true;
              formData[b][key].otherInsurance.numberPolicy.errorCode = "required";
              isOk = false;
            }
            else if(formData[b][key].otherInsurance.numberPolicy.required && formData[b][key].otherInsurance.numberPolicy.value.length>15){
              formData[b][key].otherInsurance.numberPolicy.error = true;
              formData[b][key].otherInsurance.numberPolicy.errorCode = "max-length-policyNumber";
              isOk = false;
            } else{
              formData[b][key].otherInsurance.numberPolicy.errorCode = "";
              formData[b][key].otherInsurance.numberPolicy.error = false;
            }
          }
          if (formData[b][key].capital) {
            if (formData[b][key].capital.value !== "" && !isValidNumber(formData[b][key].capital.value)) {
              formData[b][key].capital.errorCode = "number-error";
              formData[b][key].capital.error = true;
              isOk = false;
            }
            else if (formData[b][key].capital.required && formData[b][key].capital.value === "") {
              formData[b][key].capital.error = true;
              formData[b][key].capital.errorCode = "required";
              isOk = false;
            }
            else if (formData[b][key].capital.required && (formData[b][key].capital.value > formData[b][key].max || formData[b][key].capital.value < formData[b][key].min)) {
              formData[b][key].capital.error = true;
              formData[b][key].capital.errorCode = formData[b][key].capital.value > formData[b][key].max ? "max-error" : "min-error";
              isOk = false;
            }
            else if (formData[b][key].capital.required && formData[b][key].capital.errorCode) {
              formData[b][key].capital.error = true;
              formData[b][key].capital.errorCode = formData[b][key].capital.errorCode
            }
            else {
              formData[b][key].capital.errorCode = "";
              formData[b][key].capital.error = false;
            }
          }
        }
        else{
          formData[b][key].error = false;
          formData[b][key].errorCode = "";
        }
      });
    });
    if (isNotOk) isOk = false;
    const isEditNew = isEdit && isEdit.value ? isEdit : { value: isOk, index: isOk ? data.insureds.length : -1 };
    if (!block && isOk) {
      this.setState({ validateForm: isOk, isEdit: isEditNew });
      this.setData(docNumber, numChip, blocks, formData, type);
    }
    return { formData, isEdit: isEditNew };
  };

  validateTravelForm = (formData: any) => {
    let isValid = true;
    Object.keys(formData).forEach((key: string) => {
      if (
        formData[key].required &&
        (!formData[key].value || formData[key].value === "")
      ) {
        formData[key].error = "required";
        isValid = false;
      } else {
        formData[key].error = "";
      }
    });
    if (isValid) this.setState({ validateForm: isValid, dataForm: formData });
    else this.setState({ validateForm: isValid });
    return { formData };
  };

  setProtectionData = (protectionData: IProtectionData) => {
    let { data } = this.state;
    data.protectionData = this.parseProtectionData(protectionData);
    this.setState({ data })
  }

  setGipSimulationData = (step: string, dataToSave: any) => {
    let { data } = this.state;

    switch (step) {
      case "mediator":
        data.mediator = dataToSave;
        break;
      case "insured":
        data.insuredData = dataToSave;
        break;
      case "policyData":
        data.policyData = dataToSave;
        break;
      case "priceDataSelected":
        data.priceDataSelected = dataToSave;
        break;
      case "priceData":
        data.priceData = dataToSave;
        break;
      case "priceDataInsured":
        data.priceDataInsured = dataToSave;
        break;
      case "priceDataInsuredSelected":
        data.priceDataInsuredSelected = dataToSave;
        break;
      case "simulationResponse":
        data.simulationResponse = dataToSave;
        break;
      case "selectedPrice":
        data.selectedPrice = dataToSave;
        break;
    }
    this.setState({ data });
  }

  setTravelSimulationData = (step: string, dataToSave: any) => {
    let { data } = this.state;

    switch (step) {

      case "insured":
        data.taker = dataToSave;
        break;
      case "policyData":
        data.policyData = dataToSave;
        break;
      case "simulationResponse":
        data.simulationResponse = dataToSave;
        break;
      case "mediatorCode":
        data.mediatorCode = dataToSave;
        data.mediatorAssociatedToPolicy = dataToSave;
        break;
      default:
        break;
    }
    this.setState({ data });

  }

  setHealthData = async (insuredFormData: any, index?: number) => {
    let { data, product, hiringId } = this.state;
    const { checkProductArea } = this.props;

    const isGIPArea = checkProductArea(AREA_NAMES.GIP, product.products_internalName)

    if (insuredFormData.status) {
      data.status = insuredFormData.status;
    }

    if (insuredFormData.insured) {
      if (index !== undefined) {
        data.insureds.forEach((insured: any, number: number) => {
          if (number === index) {
            data.insureds[number] = {
              ...insuredFormData,
              coverages: insured.coverages,
              beneficiaries: insured.beneficiaries,
              beneficiariesType: insured.beneficiariesType ? insured.beneficiariesType : HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS,
            }
          }
        });
      } else {
        data.insureds.push(insuredFormData);
      }
      data.noRedirect = true
    }
    else if (insuredFormData.guarantees === true) {
      delete insuredFormData.taker.taker;
      delete insuredFormData.guarantees;
      data = JSON.parse(JSON.stringify(insuredFormData));
      data.noRedirect = true;
    }
    else if (insuredFormData.taker === true) {
      data.taker = insuredFormData;
      data.noRedirect = true;
      if (data.taker.isCopay) {
        delete data.taker.isCopay;
        data.isCopay = true;
      }
      if (data.status === undefined || data.status === '')
        data.status = 'ENCURSO';
    }
    else if (insuredFormData.questionnaire && insuredFormData.insuredDocNumber) {
      if (data.questionnaires) {
        data.questionnaires[insuredFormData.insuredDocNumber] = insuredFormData;
      } else {
        data.questionnaires = {};
        data.questionnaires[insuredFormData.insuredDocNumber] = insuredFormData;
      }

      data.noRedirect = true
    }
    else if (insuredFormData.questionnaireGip && insuredFormData.insuredDocNumber) {
      if (data.questionnaires) {
        data.questionnaires = data.questionnaires.filter((insuredQuestionnaire: any) => insuredQuestionnaire.insuredDocNumber != insuredFormData.insuredDocNumber)
        data.questionnaires.push(insuredFormData);
      } else {
        data.questionnaires = [];
        data.questionnaires.push(insuredFormData);
      }

      data.noRedirect = true
    }

    else if (insuredFormData.price) {
      data.priceData = insuredFormData;
    }
    else if (insuredFormData.paymentMethod) {
      data.paymentMethod = insuredFormData.paymentMethod;
      if (insuredFormData.insureds) {
        (insuredFormData.insureds as Array<any>).forEach((insured, index) => {
          data.insureds[index].copay = insured.copay ? insured.copay : undefined;
        })
      } else if (data.isCopay) {
        (data.insureds as Array<any>).forEach((insured, index) => {
          data.insureds[index].copay = undefined;
        })
      }
    }
    else if (insuredFormData.policyData) {
      data.policyData = insuredFormData.policyData
    }
    else if (isGIPArea && insuredFormData.protectionData) {
      data.protectionData = insuredFormData
    }
    else if (insuredFormData.pricingData) {
      data.pricingData = insuredFormData.pricingData

    }
    this.setState({ data, hiringId });
  }

  setTravelData = async (insuredFormData: any, index?: number) => {
    let { data } = this.state;
    if (insuredFormData.status) {
      data.status = insuredFormData.status;
    }

    if (insuredFormData.insured) {
      if (index !== undefined) {
        data.insureds.forEach((insured: any, number: number) => {
          if (number === index) {
            data.insureds[number] = {
              ...insuredFormData,
              coverages: insured.coverages,
              beneficiaries: insured.beneficiaries,
              beneficiariesType: insured.beneficiariesType ? insured.beneficiariesType : HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS,
            }
          }
        });
      } else {
        data.insureds.push(insuredFormData);
      }
      data.noRedirect = true
    }
    else if (insuredFormData.guarantees === true) {
      delete insuredFormData.taker.taker;
      delete insuredFormData.guarantees;
      data = JSON.parse(JSON.stringify(insuredFormData));
      data.noRedirect = true;
    }
    else if (insuredFormData.taker === true) {
      data.taker = insuredFormData;
      data.noRedirect = true;
      if (data.status === undefined || data.status === '')
        data.status = 'ENCURSO';
    }
    else if (insuredFormData.questionnaire && insuredFormData.insuredDocNumber) {
      if (data.questionnaires) {
        data.questionnaires[insuredFormData.insuredDocNumber] = insuredFormData;
      } else {
        data.questionnaires = {};
        data.questionnaires[insuredFormData.insuredDocNumber] = insuredFormData;
      }

      data.noRedirect = true
    }
    else if (insuredFormData.questionnaireGip && insuredFormData.insuredDocNumber) {
      if (data.questionnaires) {
        data.questionnaires = data.questionnaires.filter((insuredQuestionnaire: any) => insuredQuestionnaire.insuredDocNumber != insuredFormData.insuredDocNumber)
        data.questionnaires.push(insuredFormData);
      } else {
        data.questionnaires = [];
        data.questionnaires.push(insuredFormData);
      }

      data.noRedirect = true
    }

    else if (insuredFormData.price) {
      data.priceData = insuredFormData;
    }
    else if (insuredFormData.paymentMethod) {
      data.paymentMethod = insuredFormData.paymentMethod;
      if (insuredFormData.insureds) {
        (insuredFormData.insureds as Array<any>).forEach((insured, index) => {
          data.insureds[index].copay = insured.copay ? insured.copay : undefined;
        })
      }
    }
    else if (insuredFormData.policyData) {
      data.policyData = insuredFormData.policyData
    }
    else if (insuredFormData.pricingData) {
      data.pricingData = insuredFormData.pricingData

    }
    this.setState({ data });
  }

  setDentalData = async (insuredFormData: any, index?: number) => {
    let { data, hiringId } = this.state;

    if (insuredFormData.status) {
      data.status = insuredFormData.status;
    }

    if (insuredFormData.insured) {
      if (index !== undefined) {
        data.insureds.forEach((insured: any, number: number) => {
          if (number === index) {
            data.insureds[number] = {
              ...insuredFormData,
              coverages: insured.coverages,
            }
          }
        });
      } else {
        data.insureds.push(insuredFormData);
      }
      data.noRedirect = true
    }
    else if (insuredFormData.guarantees === true) {
      delete insuredFormData.taker.taker;
      delete insuredFormData.guarantees;
      data = JSON.parse(JSON.stringify(insuredFormData));
      data.noRedirect = true;
    }
    else if (insuredFormData.taker === true) {
      data.taker = insuredFormData;
      data.noRedirect = true;
      if (data.status === undefined || data.status === '')
        data.status = 'ENCURSO';
    }

    else if (insuredFormData.price) {
      data.priceData = insuredFormData;
    }
    else if (insuredFormData.paymentMethod) {
      data.paymentMethod = insuredFormData.paymentMethod;
    }
    else if (insuredFormData.policyData) {
      data.policyData = insuredFormData.policyData
    }
    else if (insuredFormData.pricingData) {
      data.pricingData = insuredFormData.pricingData

    }
    this.setState({ data, hiringId });
  }

  parseProtectionData(protectionData: IProtectionData): IParsedProtectionData {
    return {
      consentDate: protectionData.consentDate.value,
      dataTransfer: protectionData.dataTransfer.value,
      thirdParty: protectionData.thirdParty.value,
      thirdPartyCalls: protectionData.thirdPartyCalls.value,
    }
  }

  setMediatorSelectedCode = (mediatorCode: string) => {
    let selectedMediatorCode = mediatorCode;
    this.setState({ selectedMediatorCode });
  }

  setData = (
    docNumber: string,
    numChip: string,
    blocks: string[],
    info: any,
    type?: string
  ) => {
    const { data, dataForm, hiring, product } = this.state;
    const { history, checkProductArea } = this.props;
    const pathname = history.location.pathname;
    const isPet = pathname.indexOf("mascotas") > -1;
    const isDeath = pathname.indexOf("decesos") > -1;
    const isLife = checkProductArea(AREA_NAMES.LIFE, product.products_internalName)
    const urlString = window.location.href;
    const url = new URL(urlString);
    const index = parseInt(url.searchParams.get("index") || "-1", 10);

    let insuredIndex = data.insureds.findIndex(
      (i: any) =>
        i.docNumber && i.docNumber.toLowerCase() === docNumber.toLowerCase()
    );
    if (numChip) {
      insuredIndex = data.insureds.findIndex(
        (i: any) =>
          i.numChip && i.numChip.toLowerCase() === numChip.toLowerCase()
      );
    }
    if (index > -1 && dataForm.insureds[index]) {
      insuredIndex = index;
    }
    const insured =
      insuredIndex !== -1
        ? { ...data.insureds[insuredIndex] }
        : { address: {} };

    if (insured.coverages) {
      insured.coverages = [];
    }
    const taker: any = { address: {} };
    const payment: any = { iban: "", payType: "" };
    const beneficiary:any = { address: {} }
    const notInsured = ["payments", "nationality"];

    if (type === "taker") {
      dataForm.taker = Object.assign({}, info);
      if(dataForm.beneficiary && Object.entries(dataForm.beneficiary).length>0 && dataForm.beneficiary.useTakerData){
        dataForm.beneficiary = Object.assign(dataForm.beneficiary, info);
      }
      // dataForm.insureds[0]
      //   ? (dataForm.insureds[0] = Object.assign(
      //     {},
      //     { ...dataForm.insureds[insuredIndex], ...info }
      //   ))
      //   : dataForm.insureds.push(Object.assign({}, info));
    }
    if (type === "beneficiary") {
      dataForm.beneficiary = Object.assign({}, info);
    }
    if (insuredIndex !== -1 && type !== "taker" && type!== "beneficiary") {
      dataForm.insureds[insuredIndex] = Object.assign(
        {},
        { ...dataForm.insureds[insuredIndex], ...info }
      );
    } else if (type !== "taker" && type!== "beneficiary") {
      if (isPet || isDeath || !dataForm.insureds[0])
        dataForm.insureds.push(Object.assign({}, info));
      else dataForm.insureds[0] = Object.assign({}, info);
    }

    blocks.forEach((b) => {
      if (!info[b]) return;
      if (b === 'useTakerData' && info[b]) {
        if(isPet){
          beneficiary.useTakerData = info[b]
        }
        else{
          insured.useTakerData = info[b]
        }
      }
      Object.keys(info[b]).forEach((key) => {
        if (b === "questionnaires") {
          insured.questionnaires = info[b];
        } else if (b === "foreignAddress") {
          if (!taker.foreignAddress) taker.foreignAddress = {};
          if (!beneficiary.foreignAddress) beneficiary.foreignAddress = {};
          if (!insured.foreignAddress) insured.foreignAddress = {};
          if (type === "taker") {
            taker.foreignAddress[key] =
              info[b][key].value.value || info[b][key].value;
          }
          if (type === "beneficiary") {
            beneficiary.foreignAddress[key] =
              info[b][key].value.value || info[b][key].value;
          }
          insured.foreignAddress[key] =
            info[b][key].value.value || info[b][key].value;
          if (key === 'country') insured.foreignAddress[`${key}Label`] = info[b][key].value.label;

        } else if (b === "address") {
          if (key === "street") {
            const street = info[b][key].value.value || info[b][key].value;
            const streetType =
              info[b][key].value && info[b][key].value.extra
                ? info[b][key].value.extra.street.code
                : "";
              if (type === "taker") {
                taker.address.street = street;
                taker.address.streetType = streetType;
              }
              if (type === "beneficiary") {
                beneficiary.address.street = street;
                beneficiary.address.streetType = streetType;
              }
              insured.address.street = street;
              insured.address.streetType = streetType;           
          } else if (key === "province" || key === "city") {
            if (type === "taker") {
              taker.address[key] = info[b][key].value.value;
              taker.address[key + "Label"] = info[b][key].value.label;
            }
            if (type === "beneficiary") {
              beneficiary.address[key] = info[b][key].value.value;
              beneficiary.address[key + "Label"] = info[b][key].value.label;
            }
            insured.address[key] = info[b][key].value.value;
            insured.address[key + "Label"] = info[b][key].value.label;
          } else {
            if (type === "taker") {
              taker.address[key] =
                info[b][key].value.value || info[b][key].value;
            }
            if (type === "beneficiary") {
              beneficiary.address[key] =
                info[b][key].value.value || info[b][key].value;
            }
            insured.address[key] =
              info[b][key].value.value || info[b][key].value;
          }
        } /*else if (b === "foreignAddress" && policyChosen === "death") {
          if (type === "taker") {
            taker.foreignAddress[key] =
              info[b][key].value.value || info[b][key].value;
          }
          insured.foreignAddress[key] =
            info[b][key].value.value || info[b][key].value;
        } else if (b === "address2" && policyChosen === "death") {
          if (key === "country") {
            insured.foreignAddress[key] = info[b][key].value.label;
          } else if (
            !insured.foreignAddress["address"] &&
            info[b].streetType.value
          ) {
            insured.foreignAddress[
              "address"
            ] = `${info[b].streetType.value} ${info[b].street.value}, Nº ${info[b].streetNumber.value}, ${info[b].stairs.value} ${info[b].floorNumber.value}-${info[b].door.value}, ${info[b].city.value}, ${info[b].province.value}`;
          }
          if (key === "country" && !info[b][key].value.label) {
            delete insured.foreignAddress;
          }
        } */ else if (
          key === "mobile"
        ) {
          const mobilePart = info[b][key].value.split(" ");
          if (type === "taker") {
            taker.movilPhonePrefix = mobilePart[0] || "";
            taker.movilPhoneNumber = mobilePart[1] || "";
          }
          if (type === "beneficiary") {
            beneficiary.movilPhonePrefix = mobilePart[0] || "";
            beneficiary.movilPhoneNumber = mobilePart[1] || "";
          }

          insured.movilPhonePrefix = mobilePart[0] || "";
          insured.movilPhoneNumber = mobilePart[1] || "";
        } else if (key === "riskActivity" || key === "activityType") {
          if (type === "taker") {
            taker.riskActivity =
              info[b].activityType.value.value || info[b].activityType.value;
            if (info[b].activityType.value.label)
              taker.riskActivityLabel = info[b].activityType.value.label;
            else if (info[b].activityType.value)
              taker.riskActivityLabel = info[b].activityType.label;
          }
          if (type === "beneficiary") {
            beneficiary.riskActivity =
              info[b].activityType.value.value || info[b].activityType.value;
            if (info[b].activityType.value.label)
              beneficiary.riskActivityLabel = info[b].activityType.value.label;
            else if (info[b].activityType.value)
              beneficiary.riskActivityLabel = info[b].activityType.label;
          }
          insured.riskActivity =
            info[b].activityType.value.value || info[b].activityType.value;
          if (info[b].activityType.value.label)
            insured.riskActivityLabel = info[b].activityType.value.label
          else if (info[b].activityType.value)
            insured.riskActivityLabel = info[b].activityType.label;

        } else {

          if (key === 'occupation' && info[b].occupation.label) {
            if (type === "taker")
              taker.occupationLabel = info[b].occupation.label;
            if (type === "beneficiary")
              beneficiary.occupationLabel = info[b].occupation.label;

            insured.occupationLabel = info[b].occupation.label
          }

          if (b === "date" && hiring && type === "taker" && key!=="numPets") {
            payment[key] =
              info[b][key].value.value ||
              info[b][key].value.split(" ").join("");
          } else if (!notInsured.includes(key)) {
            if(b === "coverages" && isPet && !hiring){
              data.coverages=[];
              data.simulationInsureds = [];
              dataForm.simulationInsureds = [];

              let objCoverage= {
                capital: 1,
                code: "",
                type: "pet",
              }
              let coverages:any = []
                info.coverages.map((coverage:any)=>{
                  let newObjCoverage = {...objCoverage};
                  Object.keys(coverage).forEach((key) => {
                    if(coverage[key].value){
                      newObjCoverage.capital=coverage[key].capital?Number(coverage[key].capital.value):1;
                      newObjCoverage.code=key
                      coverages.push({...newObjCoverage})
                    }
                  });
                  data.coverages.push({"coverages":[...coverages]})
                  data.simulationInsureds.push({"coverages":[...coverages]});
                  dataForm.simulationInsureds.push({"coverages":[...coverages]});
                  coverages=[];
                })
            }
            else if (b === "coverages") {
              const coverage = {
                type: key.split("-")[0],
                code: key.split("-")[0],
                capital: info[b][key].value
                  ? this.getCapital(info[b][key].capital, info["date"], info[b][key].complementary, isLife, isPet, key)
                  : 0,
                complementary: key.indexOf("-C") > -1,
                optional: key.indexOf("-O") > -1,
                notShow: key.indexOf("-N") > -1,
                name: info[b][key].name,
                otherInsurance: info[b][key].otherInsurance
                ?{company:info[b][key].otherInsurance.company.value,
                  numberPolicy:info[b][key].otherInsurance.numberPolicy.value}
                :null
              };
              if (coverage.capital !== 0 || key.indexOf("-N") > -1) {
                if (insured.coverages) {
                  insured.coverages.push(coverage);
                } else {
                  insured.coverages = [coverage];
                }
              }
            } else {
              if (key === "birthdate" && info[b][key].value.required) {
                const dateParts: string[] = info[b][key].value.split("/");
                if (dateParts.length > 2) {
                  const dateObject: Date = new Date(
                    +dateParts[2],
                    +dateParts[1] - 1,
                    +dateParts[0]
                  );
                  if (type === "taker") taker[key] = dateObject.toISOString();
                  if (type === "beneficiary") beneficiary[key] = dateObject.toISOString();
                  insured[key] = dateObject.toISOString();
                }
              } else {
                if (b === 'contact' && key === 'breed') {
                  insured[`${key}Label`] = info[b][key].value ? info[b][key].value.label : '';
                }
                insured[key] = info[b][key].value
                  ? info[b][key].value.value || info[b][key].value
                  : "";
                if (type === "taker")
                  taker[key] = info[b][key].value
                    ? info[b][key].value.value || info[b][key].value
                    : "";
                if (type === "beneficiary")
                beneficiary[key] = info[b][key].value
                    ? info[b][key].value.value || info[b][key].value
                    : "";
              }
            }
          } else {
            if (b === 'contact' && key === 'nationality') {
              if (type === "taker") {
                taker[`${key}Label`] = info[b][key].value ? info[b][key].value.label : '';
                taker[key] = info[b][key].value ? info[b][key].value.value : '';
              } else if (type === "beneficiary") {
                beneficiary[`${key}Label`] = info[b][key].value ? info[b][key].value.label : '';
                beneficiary[key] = info[b][key].value ? info[b][key].value.value : '';
              } else {
                insured[`${key}Label`] = info[b][key].value ? info[b][key].value.label : '';
                taker[key] = info[b][key].value ? info[b][key].value.value : '';
              }
            } else {
              if (type === "taker") {
                taker[key] = info[b][key].value ? info[b][key].value.value || info[b][key].value : "";
              } else if (type === "beneficiary") {
                beneficiary[key] = info[b][key].value ? info[b][key].value.value || info[b][key].value : "";
              } else {
                insured[key] = info[b][key].value ? info[b][key].value.value || info[b][key].value : "";
              }
            }
          }
        }
      });
    });
    if (type === "taker") {
      data.taker = taker;
      // data.insureds[0]
      //   ? (data.insureds[0] = insured)
      //   : data.insureds.push(insured);
      if (hiring) data.payment = payment;
      if (
        dataForm.beneficiary &&
        Object.entries(dataForm.beneficiary).length>0 &&
        data.beneficiary &&
        Object.entries(data.beneficiary).length>0 &&
        (data.beneficiary.useTakerData || dataForm.beneficiary.useTakerData)
        ) {
          Object.assign(data.beneficiary, data.taker);
          const takerDataCopy = JSON.parse(JSON.stringify(data.taker));
          const beneficiaryDataCopy = JSON.parse(JSON.stringify(dataForm.beneficiary.useTakerData));
          const beneficiaryData = data.beneficiary;
          takerDataCopy.coverages = beneficiaryData.coverages;
          if (beneficiaryData && beneficiaryData.questionnaires) {
            takerDataCopy.questionnaires = beneficiaryData.questionnaires;
          }
          if (beneficiaryDataCopy && beneficiaryDataCopy.questionnaires) {
            dataForm.beneficiary = beneficiaryDataCopy.questionnaires;
          }
          dataForm.beneficiary = JSON.parse(JSON.stringify(dataForm.taker));
          data.beneficiary = takerDataCopy;
          dataForm.beneficiary.useTakerData = true;
          data.beneficiary.useTakerData = true;
          if (beneficiaryDataCopy && beneficiaryDataCopy.foreignAddress) {
            dataForm.beneficiary.foreignAddress = beneficiaryDataCopy.foreignAddress;
          }
          if (beneficiaryDataCopy.coverages) {
            dataForm.beneficiary.coverages = beneficiaryDataCopy.coverages;
          }
        }
      if (
        dataForm.insureds &&
        dataForm.insureds.length > 0 &&
        dataForm.insureds.some((insured: any) => insured.useTakerData) &&
        data.insureds &&
        data.insureds.length > 0
      ) {
        // TODO: change JSON.parse(JSON.stringify.) to _.cloneDeep()
        const takerDataCopy = JSON.parse(JSON.stringify(data.taker));
        const insuredDataCopy = JSON.parse(JSON.stringify(dataForm.insureds.find((insured: any) => insured.useTakerData)));
        const insuredUseTakerDataIndex = dataForm.insureds.findIndex((insured: any) => insured.useTakerData);
        const insuredData = data.insureds[insuredUseTakerDataIndex];
        takerDataCopy.coverages = insuredData.coverages;
        if (insuredData && insuredData.questionnaires) {
          takerDataCopy.questionnaires = insuredData.questionnaires;
        }
        if (insuredDataCopy && insuredDataCopy.questionnaires) {
          dataForm.insureds[insuredUseTakerDataIndex] = insuredDataCopy.questionnaires;
        }
        dataForm.insureds[insuredUseTakerDataIndex] = JSON.parse(JSON.stringify(dataForm.taker));
        data.insureds[insuredUseTakerDataIndex] = takerDataCopy;
        dataForm.insureds[insuredUseTakerDataIndex].useTakerData = true;
        if (insuredDataCopy && insuredDataCopy.foreignAddress) {
          dataForm.insureds[insuredUseTakerDataIndex].foreignAddress = insuredDataCopy.foreignAddress;
        }
        if (insuredDataCopy.coverages) {
          dataForm.insureds[insuredUseTakerDataIndex].coverages = insuredDataCopy.coverages;
        }
      }
    } else if (type === "beneficiary") {
      data.beneficiary = beneficiary;
      if (
        dataForm.insureds &&
        dataForm.insureds.length > 0 &&
        data.insureds &&
        data.insureds.length > 0 &&
        (data.insureds.some((insured: IInsuredInsuranceProposal) => insured.useTakerData) || dataForm.insureds.some((insured: IInsuredCoveragesDataForm) => insured.useTakerData))      
        ) {
        // TODO: change JSON.parse(JSON.stringify.) to _.cloneDeep()
        const beneficiaryDataCopy = JSON.parse(JSON.stringify(data.beneficiary));
        const insuredDataCopy = JSON.parse(JSON.stringify(dataForm.insureds.find((insured: any) => insured.useTakerData)));
        const insuredUseTakerDataIndex = dataForm.insureds.findIndex((insured: any) => insured.useTakerData);
        const insuredData = data.insureds[insuredUseTakerDataIndex];
        beneficiaryDataCopy.coverages = insuredData.coverages;
        if (insuredData && insuredData.questionnaires) {
          beneficiaryDataCopy.questionnaires = insuredData.questionnaires;
        }
        if (insuredDataCopy && insuredDataCopy.questionnaires) {
          dataForm.insureds[insuredUseTakerDataIndex] = insuredDataCopy.questionnaires;
        }
        dataForm.insureds[insuredUseTakerDataIndex] = JSON.parse(JSON.stringify(dataForm.taker));
        data.insureds[insuredUseTakerDataIndex] = beneficiaryDataCopy;
        dataForm.insureds[insuredUseTakerDataIndex].useTakerData = true;
        if (insuredDataCopy && insuredDataCopy.foreignAddress) {
          dataForm.insureds[insuredUseTakerDataIndex].foreignAddress = insuredDataCopy.foreignAddress;
        }
        if (insuredDataCopy.coverages) {
          dataForm.insureds[insuredUseTakerDataIndex].coverages = insuredDataCopy.coverages;
        }
      }
    } else {
      if (
        data.insureds[insuredIndex] &&
        data.insureds[insuredIndex].questionnaires
      ) {
        insured.questionnaires = data.insureds[insuredIndex].questionnaires;
      } else if (data.insureds[0] && data.insureds[0].questionnaires) {
        insured.questionnaires = data.insureds[0].questionnaires;
      }
      insuredIndex !== -1
        ? (data.insureds[insuredIndex] = insured)
        : isPet || isDeath || !data.insureds[0]
          ? data.insureds.push(insured)
        : (data.insureds[0] = insured);
    }
    if(data.insureds.length === 1 && dataForm.insureds.length){
      const coverages=dataForm.insureds[0].coverages;
      const coveragesOptional = Object.keys(coverages).some(
        code=>coverages[code].complementary && coverages[code].value
      )
      if(!coveragesOptional){
        data.beneficiary={};
        dataForm.beneficiary={};
      }
    }
    this.setState({ data, dataForm });
  };

  getCapital = (capitalCoverage: any, date: any, complementary?: boolean, isLife?: boolean, isPets?: boolean, code?: any): number => {
      if (capitalCoverage) {
        return (
          parseFloat(capitalCoverage.value.value) ||
          parseFloat(capitalCoverage.value)
        );
      } else {
        if (!complementary) {
          if (date && date.capital && date.capital.value) {
            return parseFloat(date.capital.value);
          }
        }
        if (isLife && complementary) return parseFloat(date.capital.value);
        if(isPets && complementary && code){
          switch(code){
            case "AV04":
              return 1000;
            case "AV05":
              return 1000;
            case "AV06":
              return 150;
          }
        }
      }
    return 1;
  };

  getDataForm = () => {
    const { data } = this.state;
    const urlString = window.location.href;
    const url = new URL(urlString);
    const index = url.searchParams.get("index");
    let dataUser = {};
    if (index === "0") {
      dataUser = {
        taker: data.taker,
        insured: data.insureds[parseInt(index)],
      };
    } else if (index) {
      dataUser = {
        insured: data.insureds[parseInt(index)],
      };
    }
    return dataUser;
  };

  tryReloadInsureds() {
    const { dataForm, product } = this.state;
    const { checkProductArea } = this.props
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, product.products_internalName)
    const isPetArea = checkProductArea(AREA_NAMES.PET, product.products_internalName);
    const type = isDeathArea ? 'decesos' : 'mascotas';

    if (isDeathArea || isPetArea) {
      const urlString = window.location.href;
      const url = new URL(urlString);
      let selectedIndex = url.searchParams.get("index");
      if (dataForm.insureds.length > 0 && selectedIndex && parseInt(selectedIndex) > 0) {
        this.props.history.replace(`/contratacion/nueva/${type}/asegurado?index=${parseInt(selectedIndex) - 1}`);
      }
    }
  }

  removeInsured = () => {
    const { data, indexRemove, dataForm, goToDetail, product } = this.state;
    const { checkProductArea } = this.props
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, product.products_internalName)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, product.products_internalName)
    const isPetArea = checkProductArea(AREA_NAMES.PET, product.products_internalName)

    if (dataForm.insureds !== undefined && dataForm.insureds.length !== 0) {
      if (
        indexRemove > 0 ||
        (data.insureds.length > 1 && dataForm.insureds.length > 1)
      ) {

        const docNumberRemove = data.insureds[indexRemove].docNumber;

        if (isGIPArea) {
          if (data.questionnaires) {
            data.questionnaires = data.questionnaires.filter((insuredQuestionnaire: any) => insuredQuestionnaire.insuredDocNumber != docNumberRemove)
          }
          data.insureds.splice(indexRemove, 1);
          this.setState({ data, dataForm });
        }
        else {
          let { data } = this.state;
          if (isHealthArea) {
            const insuredDocNumber = data.insureds[indexRemove].docNumber;
            delete data.questionnaires[insuredDocNumber];
            data.insureds.splice(indexRemove, 1);
            this.setState({ data, dataForm });
          } else {
            data.insureds.splice(indexRemove, 1);
            dataForm.insureds.splice(indexRemove, 1);
            this.setState({ data, dataForm }, () => this.tryReloadInsureds());
          }
          if (data.questionnaires && data.questionnaires[docNumberRemove]) {
            delete data.questionnaires[docNumberRemove];
          }
        }
        if(isPetArea){
          let coverageOpt = false;
          dataForm.insureds.map((insured:any)=>{
            const coveragesOptional = Object.keys(insured.coverages).some(
            code=>insured.coverages[code].complementary && insured.coverages[code].value)
            if(coveragesOptional){
              coverageOpt = true;
            }
          });
          if(!coverageOpt && data.beneficiary){
            delete data.beneficiary;
            this.setState({ data, dataForm });
          }
        }
      }
    }
    else {
      if (indexRemove >= 0 || data.insureds.length > 1) {

        let indexInsured = goToDetail;

        if (isHealthArea && goToDetail && goToDetail > - 1) {

          if (goToDetail > indexRemove) indexInsured = goToDetail - 1;

        }

        const docNumberRemove = data.insureds[indexRemove].docNumber;
        if (data.questionnaires && data.questionnaires[docNumberRemove]) {
          delete data.questionnaires[docNumberRemove];
        }

        data.insureds.splice(indexRemove, 1);
        this.setState({ data, dataForm, goToDetail: indexInsured });

      }
    }
  };

  checkInsuredsForBeneficiary = () => {
    const {data, dataForm} = this.state;
    let coverageOpt = false;
    dataForm.insureds.map((insured:any)=>{
      const coveragesOptional = Object.keys(insured.coverages).some(
      code=>insured.coverages[code].complementary && insured.coverages[code].value)
      if(coveragesOptional){
        coverageOpt = true;
      }
    });
    if(!coverageOpt && data.beneficiary){
      delete data.beneficiary;
      this.setState({ data, dataForm });
    }
  }
}

export default PolicyFunctional;
