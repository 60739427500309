import React from "react";
import NewSimulationLayout from "./new-simulation-layout";

export default class NewSimulation extends React.Component<any, {}> {
  render() {
    return <NewSimulationLayout  
      setLoading={this.props.setLoading} 
      hasInformativeNoteLife={this.props.hasInformativeNoteLife} 
      hasInformativeNoteNotLife={this.props.hasInformativeNoteNotLife} 
      user={this.props.user}
      mediator={this.props.mediator}
      />;
  }
}
