import styled from "styled-components";
import { colors } from "../../../../assets";
import { images } from "../../../../assets/images";

export const TravelInsuredDataContainer = styled.div`
  .data-flow-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  .content-wrapper {
    overflow: initial;
    padding-right: 16px;
    padding: 20px;
    background-color: #fbfbfb;
    float: left;
    width: 100%;
    display: block;
  }
  @media (min-width: 320px) {
    .content-wrapper {
      width: 55% !important;
    }
  }
  @media (min-width: 1201px) {
    .content-wrapper {
      width: 100% !important;
    }
  }
  .data-row {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }

  .data-wrapper {
    display: block;
  }

  .data-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 30px;
  }

  .tabs {
    overflow: hidden;

    .tab {
      width: 100%;
      overflow: hidden;
      position: relative;

      .delete {
        width: 24px;
        height: 24px;
        line-height: 24px;
        position: absolute;
        left: calc(100% - 170px);
        top: 18px;
        background-color: #3f3f3f;
        color: #fff;
        border-radius: 100%;
        text-align: center;
        cursor: pointer;
        z-index: 2;

        &:before {
          content: url(${images.IconCloseWhite});
        }

        &:hover {
          background-color: #004a8f;
        }
      }

      .tab-label {
        display: flex;
        justify-content: space-between;
        padding: 1em;
        background: #fff;
        color: #3f3f3f;
        border: 1px solid #f4f4f4;
        font-size: 18px;
        height: 60px;

        &:after {
          content: "❯";
          width: 30px;
          height: 30px;
          text-align: center;
          transition: all 300ms;
          transform: rotate(90deg);
          border-radius: 100%;
          text-align: center;
          line-height: 30px;
          margin-top: -5px;
        }

        span {
          transition: all 300ms;
        }
      }

      .tab-content {
        height: 0;
        padding: 0 1em;
        color: #2c3e50;
        background: #fff;
        transition: all 300ms;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
      }

      .tab-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        background: #2c3e50;
        cursor: pointer;

        &:hover {
          background: #1a252f;
        }
      }

      input[type="checkbox"] {
        position: absolute;
        right: 2px;
        opacity: 0;
        width: 60px;
        height: 60px;
        cursor: pointer;
        z-index: 1;
        margin: 0;

        &:hover {
          + {
            .tab-label:after {
              background-color: #efefef;
            }
          }
        }

        &:checked {
          + {
            .tab-label {
              font-weight: bold;
            }

            .tab-label:after {
              transform: rotate(270deg);
            }
          }

          ~ .tab-content {
            min-height: 70px;
            height: auto;
            padding: 25px 15px 20px;
            opacity: 1;
            box-shadow: 0px 4px 10px -3px #e0e0e0;
          }
        }
      }
    }
  }


  .checkbox-container input:not(:checked) {
    color: ${colors["TX-001-50"]};
  }

  outline: none;
  
  &.mandatory {
    .checkbox-container input:checked ~ .checkmark-container {
      background: #f7f7f7;

      .checkmark-item {
        background: ${colors["TX-001-80"]};
      }
    }
  }

  &.optional {
    .checkbox-container input:checked ~ .checkmark-container {
      background: ${colors["SU-001-10"]};

      .checkmark-item {
        background: ${colors["SU-001-100"]};
      }
    }
  }

  .checkbox-container {
    display: flex;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 15px;

    &.noPointer {
      cursor: initial;
    }

    div:nth-of-type(2) {
      line-height: 25px;
      font-size: 16px;
    }

    &:hover {
      input ~ .checkmark {
        background-color: rgba(0, 79, 139, 0.3);
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark-container {
        background: #e8f9f5;
        justify-content: flex-end;

        .checkmark-item {
          background: #40caa9;
        }

        img {
          max-width: 14px;
        }
      }
  }

  .checkbox-error {
    color: red;
    display: block;
  }

  .checkmark-container {
    min-width: 45px;
    background: #e0e0e0;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 14px;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;

    img {
      max-width: 10px;
    }
  }

  .checkmark-item {
    background: ${colors["TX-001-30"]};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    width: 20px;
    height: 20px;    
  }

  .checkmark-container-readonly {
    min-width: 24px;
    background: #ffffff;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 14px;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;

    img {
      max-width: 14px;
    }

    .checkmark-item {
      background: #3f3f3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      width: 20px;
      height: 20px;    
    }
  }
  
`;
