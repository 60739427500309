import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { MascotsInsuredDataContainer } from "../../../mascots/mascots-insured-data/mascots-insured-data-style";
import InputBox from "../../../../../components/input-box/input-box";
import { PersonalBasicDataProps } from "../personal-basic-data/personal-basic-data.types";
import CalendarBox from "../../../../../components/calendar-box/calendar-box";
import DicotomicButton from "../../../../../components/dicotomic-button";
import TYPE_PETS from "../../../../../constants/typePets.json";
import CheckboxWrapper from "../checkbox-wrapper/checkbox-wrapper";
import SearchBox from "../../../../../components/search-box-mediadores/search-box";
import ConstantsServices from "../../../../../services/ConstantsServices";

const PetForm: FC<PersonalBasicDataProps> = ({ formData }) => {
  const [petBreedList, setPetBreedList] = useState<{label: string}[]>([])
  const [petBreedsFiltered, setPetBreedsFiltered] = useState<{label: string}[]>([])
  const { values, handleChange, errors } = formData;

  useEffect(() => {
    values.newPetType && getPetBreed();
  }, [values.newPetType])
  
  const getPetBreed = async () => {
    let responsePetBreeds = await ConstantsServices.getPetBreeds({
        loader: false,
        setLoading: () => {},
        params: [`petType=${values.newPetType}`],
      });
      setPetBreedList(responsePetBreeds)
  }

  const filteredPetBreedList = (value: string) => {
    setPetBreedsFiltered(petBreedList.filter((pet: {label: string}) =>
        pet.label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            value
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
      ))
  }

  return (
    <MascotsInsuredDataContainer>
      <div>
        {/* Nombre */}
        <CheckboxWrapper
          label={"Modificar Nombre de Mascota"}
          isChecked={values.check.petName}
          step="check"
          onCheckboxChange={handleChange}
          name="petName"
        >
          <div className="personal-data-item col-50">
            <div className="inputBox">
              <InputBox
                placeholder="Nombre"
                required={values.check.petName}
                errorCode={errors.petNewName}
                value={values.petNewName}
                onChange={(value: string) => handleChange("petNewName", value)}
              />
            </div>
          </div>
        </CheckboxWrapper>
        {/* Fecha de nacimiento */}
        <CheckboxWrapper
          label={"Modificar Fecha de nacimiento de Mascota"}
          isChecked={values.check.petBirthdate}
          step="check"
          onCheckboxChange={handleChange}
          name="petBirthdate"
        >
          <div className="personal-data-row">
            <div className="personal-data-item col-50">
              <div className="inputBox">
                <CalendarBox
                  initialValue={values.birthDate}
                  placeholder="Fecha de nacimiento"
                  disabled
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="personal-data-item col-50">
              <div className="inputBox">
                <CalendarBox
                  initialValue={values.newPetBirthdate}
                  forbidFutureDates={true}
                  placeholder="Nueva Fecha de Nacimiento"
                  required={values.check.petBirthdate}
                  errorCode={errors.newPetBirthdate}
                  onChange={(value: string) =>
                    handleChange("newPetBirthdate", value)
                  }
                />
              </div>
            </div>
          </div>
        </CheckboxWrapper>
        {/* Tipo y raza de mascota */}
        <CheckboxWrapper
          label={"Modificar Tipo y Raza de Mascota"}
          isChecked={values.check.petType}
          step="check"
          onCheckboxChange={handleChange}
          name="petType"
        >
          <div className="personal-data-row">
            <div className="personal-data-item col-50">
              <DicotomicButton
                labelText="Tipo"
                options={TYPE_PETS}
                initialValue={values.petType}
                onChange={() => {}}
                disabled
              />
            </div>
            <div className="personal-data-item col-50">
              <DicotomicButton
                errorCode={errors.newPetType}
                labelText="Nuevo Tipo"
                options={TYPE_PETS}
                initialValue={values.newPetType}
                onChange={(value: string) => handleChange("newPetType", value)}
                withTab={true}
                required={values.check.petType}
              />
            </div>
          </div>
          <div className="personal-data-row">
            <div className="personal-data-item col-50">
              <div className="inputBox topMargin10">
                <SearchBox
                  onChange={() => {}}
                  labelText=""
                  optionsText={petBreedsFiltered}
                  optionKey={"value"}
                  optionValue={"label"}
                  initialValue={values.breed}
                  onSelect={() => {}}
                  disabled
                />
                <label>Raza</label>
              </div>
            </div>
            <div className="personal-data-item col-50">
              <div className="inputBox topMargin10">
                <SearchBox
                  onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                    filteredPetBreedList(ev.target.value);
                  }}
                  labelText=""
                  optionsText={petBreedsFiltered}
                  optionKey={"value"}
                  optionValue={"label"}
                  initialValue={values.newBreed}
                  required={values.check.petType}
                  errorCode={errors.newBreed}
                  onSelect={(value: { label: string; value: number }) =>
                    handleChange("newBreed", value.label)
                  }
                />
                <label>Nueva Raza</label>
              </div>
            </div>
          </div>
        </CheckboxWrapper>
        {/* Microchip */}
        <CheckboxWrapper
          label={"Modificar Nº Microchip"}
          isChecked={values.check.numChip}
          step="check"
          onCheckboxChange={handleChange}
          name="numChip"
        >
          <div className="personal-data-row">
            <div className="personal-data-item col-50">
              <div className="inputBox topMargin10">
                <InputBox
                  placeholder="Nº Microchip"
                  value={values.numChip}
                  disabled
                />
              </div>
            </div>
            <div className="personal-data-item col-50">
              <div className="inputBox topMargin10">
                <InputBox
                  placeholder="Nuevo Nº Microchip"
                  required={values.check.numChip}
                  errorCode={errors.newNumChip}
                  value={values.newNumChip}
                  maxLength={15}
                  type={"number"}
                  onChange={(value: string) =>
                    handleChange("newNumChip", value)
                  }
                />
              </div>
            </div>
          </div>
        </CheckboxWrapper>
      </div>
    </MascotsInsuredDataContainer>
  );
};

export default PetForm;
