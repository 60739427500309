import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../assets";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import GuideLine from "../../../../components/guide-line";
import MainButton from "../../../../components/main-button";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../context/mixin/with-general";
import { withPolicy } from "../../../../context/mixin/with-policy";
import { DentalMediatorDataContainer } from "./dental-mediator-data-style";
import { getRelatedMediatorsCodes } from "../../../../utils/mediatorCode";
import { IMediator } from "../../../../models/mediator-model";
import SelectBox from "../../../../components/select-box/select-box";
import MediatorServices from "../../../../services/MediatorServices";
import { HEALTH_MAX_EFFECT_DAYS } from "../../../../services/url";
interface DentalMediatorProps extends RouteComponentProps {
  user: any;
  mediator: IMediator;
  policy: any;
  data: any;
}

interface DentalMediatorState {
  mediatorValue: string;
  mediatorData: {
    code: string;
    name: string;
    startDate: string;
    nif: string;
    email: string;
  };
  nextDisabled: boolean;
  relatedMediatorCodes: any;
  mediatorCodeSelected: string;
  maxEffectDays: number;
}

class DentalMediatorDataLayout extends React.Component<DentalMediatorProps, DentalMediatorState> {
  constructor(props: any) {
    super(props);
    this.state = {
      mediatorValue: `${props.user.name} ${props.user.lastname1} ${props.user.lastname2}`,
      mediatorData: {
        code: "",
        name: "",
        startDate: props.data && props.data.mediatorData && props.data.mediatorData.startDate
          ? props.data.mediatorData.startDate
          : moment().format("DD/MM/YYYY").toLocaleString(),
        email: "",
        nif: "",
      },
      nextDisabled: false,
      relatedMediatorCodes: getRelatedMediatorsCodes(this.props.mediator),
      mediatorCodeSelected: props.data && props.data.mediatorData && props.data.mediatorData.code
        ? props.data.mediatorData.code
        : props.mediator.mediatorCode,
      maxEffectDays: HEALTH_MAX_EFFECT_DAYS ? Number(HEALTH_MAX_EFFECT_DAYS) : 0
    };
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  };

  async handleSelectMediatorChange(value: string) {
    this.props.policy.setMediatorSelectedCode &&
      this.props.policy.setMediatorSelectedCode(value);
    this.setState({ mediatorCodeSelected: value });
    const mediator = {
      setLoading: () => { },
      loader: true,
      mediatorCode: value,
    };
    const mediatorName = await MediatorServices.getMediatorInfo(mediator);
    let { mediatorData, mediatorValue } = this.state;
    mediatorValue = mediatorName.mediatorName;
    mediatorData.name = value;
    mediatorData.code = value;
    mediatorData.email = mediatorName.email;
    this.setState({ mediatorData, mediatorValue });
  }

  toValidate = async () => {
    const { history } = this.props;
    const { mediatorValue } = this.state;
    let { mediatorData } = this.state;
    let { data } = this.props;

    mediatorData.name = mediatorValue;

    data.mediatorData = mediatorData;
    
    history.push("/simulacion/nueva/dental/asegurados");
  };

  changeDateValue(value: string, error?: boolean, errorCode?: string) {
    let { mediatorData } = this.state;
    mediatorData.startDate = value;
    this.setState({ mediatorData, nextDisabled: errorCode ? true : false });
  }

  render() {
    let {
      nextDisabled,
      relatedMediatorCodes,
      mediatorCodeSelected,
      maxEffectDays
    } = this.state;
    return (
      <DentalMediatorDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <h2>{"DATOS DEL SEGURO"}</h2>
          </div>
          <div className="data-flow-status-guide">
            <GuideLine steps={[0, 0, 0, 0]} currentStep={1} />
          </div>
        </div>

        <div className="content-wrapper bottomMargin20" ref={""}>
          <div className="personal-data-wrapper">
            <div className="data-title">
              <h5>DATOS SIMULACION</h5>
            </div>

            <div className="colSin-100 topMargin20">
              <div className="colSin-50 rightPadding20">
                <div className="inputBox">
                  <SelectBox
                    isMediator={true}
                    optionsText={relatedMediatorCodes}
                    optionKey={"value"}
                    optionValue={"label"}
                    defaultValue={mediatorCodeSelected}
                    onChange={(value: string) =>
                      this.handleSelectMediatorChange(value)
                    }
                    propValue={mediatorCodeSelected}
                    required
                  />
                  <label>Mediador</label>
                </div>
              </div>

              <div className="colSin-50 rightPadding20">
                <div className="inputBox">
                  <CalendarBox
                    initialValue={this.state.mediatorData.startDate}
                    placeholder="Fecha efecto"
                    maxDate={moment().add(maxEffectDays, "days").toLocaleString()}
                    minDate={moment().subtract(1, "days").toLocaleString()}
                    onChange={(
                      value: string,
                      error?: boolean,
                      errorCode?: string
                    ) => {
                      this.changeDateValue(value, error, errorCode);
                    }}
                    required={true}
                    errorCode={""}
                    health={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="colSin-100">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push("/simulacion/nueva")}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                text="Siguiente"
                disabled={nextDisabled}
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>

      </DentalMediatorDataContainer>
    );
  }
}

export default (withGeneral(withCoverages(withPolicy(withRouter(DentalMediatorDataLayout)))));