import styled from "styled-components";
import { colors } from "../../assets";

export const CalendarBoxContainer = styled.div`
  z-index: 9;

  &.show-calendar {
    z-index: 12;
    .input-box-main-field {
      background: #ffffff;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
      outline: none;
      border-bottom: none;
    }
    .calendar-popup {
      display: block;
    }
  }

  &.required {
    .input-box-main-field {
    }
  }



  &.disabled {
    pointer-events: none;

    .input-box-topbar-label {
      color: ${colors["TX-001-30"]};
    }

    .input-box-main-field {
      background: ${colors["FL-001-Disabled"]};

      input {
        color: ${colors["TX-001-30"]};
      }

      &:before {
        background: ${colors["TX-001-30"]};
      }
    }
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
  }

  .input-box-topbar-label {
    color: ${colors["PR-001-50"]};
    text-transform: uppercase;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      width: 24px;
      cursor: pointer;
    }
  }

  .input-box-main-field {
    

    p {
      cursor: inherit;
    }

    &:hover {
      background: #f0fafd;
    }

    &:focus {
      background: #ffffff;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
      outline: none;
      border-bottom: none;
    }
  }

  .input-box-icon {
    position: absolute;
    top: 25px;
    right: 16px;
    width: 24px;
    z-index: 10;
    img {
      width: 25px;
      cursor: pointer;
    }
    &.cross {
      right: 50px;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 6px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 29;
    width: 260px;
    display: none;
  }

  .calendar-popup {
    position: absolute;
    right: 0;
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 40;
    display: none;
    color: ${colors["PR-001-100"]};

    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;

      &:hover {
        background: #f0fafd;
      }
    }
  }

  .input-box-error {
    color: ${colors["ER-001-100"]};
    padding-top: 3px;

    p {
      color: ${colors["ER-001-100"]};
    }
  }

  &.error {
    .input-box-main-field > input,
    textarea {
      border: 1px solid ${colors["ER-001-100"]};
      &::placeholder {
        color: ${colors["ER-001-100"]};
      }
    }
  }

  input {

    &::placeholder  {
      color: ${colors["PR-001-100"]};
    }
  }
`;
