import { IDataProtectionMethod } from "../constants/dental-constants";
import { BuildPrecontractualDocumentPDFRequest, BuildPrecontractualDocumentRequest, BuildPrecontractualDocumentResponseDto, IPaymentMethod, RequiredDocumentsDto } from "./DentalService-dto";
import { DentalRiskDataRequest, HiringDentalDto } from "./dto/DentalService-dto";
import { SendaBasePriceResponse } from "../containers/hirings/new-hiring/dental/dental-price/dental-price-dto/dental-prices-dto";
import GeneralServices from "./GeneralServices";
import { SendaBaseResponse, SendaPaymentResponse } from "./HealthService.SendaBaseResponse";
import GestDocServices from "./Gest-Doc.Services";

class DentalServices {

    postRiskData = async (setLoading: Function, loader: boolean, request: DentalRiskDataRequest, internalName: string): Promise<SendaBaseResponse> => {
        try {
            const response = await GeneralServices.post({
                setLoading,
                isSlowProcess: true,
                loader,
                endpoint: `sendaDental/riskData?internalName=${internalName}`,
                data: request,
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    getPrices = async (args: any): Promise<SendaBasePriceResponse> => {
        try {
            const response = await GeneralServices.post({
                setLoading: args.setLoading,
                isSlowProcess: true,
                loader: args.loader === false ? false : true,
                endpoint: `sendaDental/prices`,
                data: args.data
            });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    }

    uploadDocumentsToSenda = async (setLoading: Function, loader: boolean, data: RequiredDocumentsDto): Promise<SendaBaseResponse> => {
        try {
            const fd = new FormData();
            fd.append("body", JSON.stringify({
                "cotizacion": data.cotizacion,
                "fileInfo": data.fileInfo
            }));
            data.files.forEach(file => {
                fd.append(`files`, file);
            });

            const response = await GeneralServices.postFormData({
                setLoading: setLoading,
                loader: loader,
                endpoint: "sendaDental/uploadDocuments",
                data: fd
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    getDataProtection = async (setLoading: Function, loader: boolean, cotizacion: string): Promise<SendaBaseResponse> => {
        try {
            const response = await GeneralServices.get({
                setLoading,
                loader,
                endpoint: `sendaDental/getDataProtection/${cotizacion}`
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    setDataProtection = async (setLoading: Function, loader: boolean, request: IDataProtectionMethod): Promise<SendaBaseResponse> => {
        try {
            const response = await GeneralServices.post({
                setLoading,
                loader,
                endpoint: "sendaDental/setDataProtection",
                data: request
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    setPaymentMethod = async (setLoading: Function, loader: boolean, data: IPaymentMethod, mainSepa?: File, copaySepa?: File, insuredSepaFileArray?: File[]): Promise<SendaPaymentResponse> => {
        try {
            const fd = new FormData();
            fd.append("body", JSON.stringify(data));
            if (mainSepa)
                fd.append("mainSepa", mainSepa, mainSepa.name);
            if (copaySepa)
                fd.append("copaySepa", copaySepa, copaySepa.name);
            if (insuredSepaFileArray && insuredSepaFileArray.length > 0)
                insuredSepaFileArray.forEach((insuredFile) => {
                    fd.append("insuredSepa", insuredFile, insuredFile.name)
                });
            const response = await GeneralServices.postFormData({
                setLoading: setLoading,
                isSlowProcess: true,
                loader: loader,
                endpoint: "sendaDental/setPaymentMethod",
                data: fd
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    async buildPrecontractualDocument(setLoading: Function, loader: boolean, request: BuildPrecontractualDocumentRequest, productType: string): Promise<BuildPrecontractualDocumentResponseDto> {
        try {
            const response = await GeneralServices.postFileResponse({
                setLoading: setLoading,
                loader: loader,
                endpoint: `sendaDental/buildPrecontractualDocument?productType=${productType}`,
                data: request,
            }).catch((e) => {
                throw e;
            });

            return {
                fileData: response.data,
                fileName: GestDocServices.getFileName(response.headers["content-disposition"]),
                fileType: response.headers["content-type"],
            }

        } catch (err) {
            throw err;
        }
    }
    sendPrecontractualDocument = async (setLoading: Function, loader: boolean, request: BuildPrecontractualDocumentPDFRequest, productType: string): Promise<BuildPrecontractualDocumentResponseDto> => {
        try {
            const response = await GeneralServices.postFileResponse({
                setLoading: setLoading,
                loader: loader,
                endpoint: `sendaDental/buildHiringPdf?productType=${productType}`,
                data: request,
            });

            return {
                fileData: response.data,
                fileName: GestDocServices.getFileName(response.headers["content-disposition"]),
                fileType: response.headers["content-type"],
            }

        } catch (err) {
            throw err;
        }
    }
    
    dentalHiring = async (args: HiringDentalDto): Promise<SendaBaseResponse> => {
        try {
            const response = await GeneralServices.post({
                setLoading: args.setLoading,
                isSlowProcess: true,
                loader: args.loader === false ? false : true,
                endpoint: `sendaDental/dentalHiring`,
                data: args.data,
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    };

    getPolicyByNIF = async (setLoading: any, loader: boolean, nif: string): Promise<SendaBaseResponse> => {
        try {
            const response = await GeneralServices.get({
                setLoading,
                loader,
                endpoint: `sendaDental/checkPolicyByNIF/${nif}`
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

}

export default new DentalServices();