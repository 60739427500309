import GeneralServices from "./GeneralServices";
import { IDownloadReceiptResponseDto, IGetReceiptConsreciboDto, IPendingReceiptResponseDto, IReceiptDetail, IReceiptDetailResponseDto, IReceiptSearch, ISearchReceiptResponseDto, IUpdateReceiptStatusEstreciboDto } from "./ReceiptsServices-dto";

class ReceiptsServices {
  getReceipts = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `receipts${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return response.data;
    }
    catch (err) {
      return { items: [], count: 0 };
    }
  }

  getAllReceipts = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `files?filetype=RECI&format=csv`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  getReceiptFile = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `files/single?filetype=RECI&format=${args.format}&id=${args.id}`,
      });
      return response.data;
    }
    catch (err) {
      return {};
    }
  }

  getYears = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `receipts/years/all${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  searchReceipts = async (setLoading: Function, loader: boolean, queryParams: Array<string>, data: IReceiptSearch): Promise<ISearchReceiptResponseDto> => {
    try {
      const response = await GeneralServices.post({
        setLoading: setLoading,
        loader: loader === false ? false : true,
        endpoint: `receipts/search${queryParams ? '?' + queryParams.join("&") : ''}`,
        data: data
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  searchReceiptDetail = async (setLoading: Function, loader: boolean,  data: IReceiptDetail): Promise<IReceiptDetailResponseDto> => {
    try {
      const response = await GeneralServices.post({
        setLoading: setLoading,
        loader: loader === false ? false : true,
        endpoint: `receipts/detail`,
        data: data
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  downloadReceipts = async (setLoading: Function, loader: boolean, queryParams: Array<string>, data: IReceiptSearch): Promise<IDownloadReceiptResponseDto> => {
    try {
      const response = await GeneralServices.post({
        setLoading: setLoading,
        loader: loader === false ? false : true,       
        endpoint: `receipts/download${queryParams ? '?' + queryParams.join("&") : ''}`,
        data: data
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  getPendingReceipts = async (setLoading: Function, loader: boolean, userCode: string): Promise<IPendingReceiptResponseDto> => {
    try {
      const response = await GeneralServices.get({
        setLoading: setLoading,
        loader: loader === false ? false : true,
        endpoint: `receipts/pending/${userCode}`,     
      });
      return {     
        fileData: response.data.fileData,
        fileName: response.data.fileName,
      }
    }
    catch (err) {
      throw err;
    }
  }

  getReceiptConsrecibo = async (setLoading: Function, loader: boolean, data: IGetReceiptConsreciboDto): Promise<any> => {
    try {
      const response = await GeneralServices.get({
        setLoading: setLoading,
        loader: loader === false ? false : true,
        endpoint: `senda/receipt?businessLine=${data.businessLine}&id=${data.id}&searchType=${data.searchType}`,   
      });
      return {     
        data: response.data
      }
    }
    catch (err) {
      throw err;
    }
  }

  updateReceiptStatusEstrecibo = async (setLoading: Function, loader: boolean,  data: IUpdateReceiptStatusEstreciboDto): Promise<IReceiptDetailResponseDto> => {
    try {
      const response = await GeneralServices.put({
        setLoading: setLoading,
        loader: loader === false ? false : true,
        endpoint: `senda/receipt/status`,
        data: data
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

}

export default new ReceiptsServices();