import React from "react";
import { SimulationResultContainer } from "./result-style";
import { RouteComponentProps, withRouter } from "react-router-dom";
import WebText from "../../../../components/components-text/web-text";
import MainTable from "../../../../components/main-table";
import { NewSimulationContainer } from "../new-simulation-style";
import Title03Text from "../../../../components/components-text/title-03-text";
import HeadText from "../../../../components/components-text/head-text";
import NoteText from "../../../../components/components-text/note-text";
import MainButton from "../../../../components/main-button";
import { images } from "../../../../assets";
import { InflowSidebarContainer } from "../../../../components/inflow-sidebar/inflow-sidebar-style";
import LeftInfoModal from "../../../../components/left-info-modal";
import MediatorServices from "../../../../services/MediatorServices";
import { withGeneral } from "../../../../context/mixin/with-general";
import { withPolicy } from "../../../../context/mixin/with-policy";
import SimulationServices from "../../../../services/SimulationServices";
import moment from "moment";
import { deepGet, downloadFile, formatter } from "../../../../utils/utility";
import { isValidEmail } from "../../../../utils/validation";
import GoBack from "../../../../components/go-back";
import { STORAGE_KEY } from "../../../../constants/health";
import {
  COVERAGES_INSURED_ORDER,
  IAmountPay,
  IAmountQuoteCoverage,
  ICoveragesAmount,
  ICoveragesQuoteAmount,
  IInformativeCoverageConcepts,
  ISimulationResult,
  ITotalPremiumConcepts,
} from "./interfaces/result-interface";
import HealthCampaignItem from "../../../../components/health-campaign-item-result/health-campaign-item-result";
import HealthService from "../../../../services/HealthService";
import { IUser } from "../../../../models/user-model";
import { IMediator } from "../../../../models/mediator-model";
import { AREA_NAMES, setColorSpan } from "../../../../constants/policyType";
import HealthInsuredItem from "../../../../components/health-insured-item/health-insured-item";
import { withDynamicProducts } from "../../../../context/mixin/with-dynamic-products";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import PayTypesServices from "../../../../services/PayTypesServices";
import { withPayTypes } from "../../../../context/mixin/with-pay-types";
import { MODAL_DOWNLOAD_PDF, MODAL_DOWNLOAD_PDF_KO, MODAL_DOWNLOAD_PDF_OK, MODAL_SAVE_SIMULATION_KO, MODAL_SEND_MAIL_PDF, MODAL_SEND_MAIL_PDF_OK, MODAL_SEND_PDF_KO, modalCotent } from "./result-constants";
import { ALL_PAY_TYPES } from "../../../../constants/payTypes";
import { AllPayType, ProductPayType } from "../../../../services/PayTypesServices.dto";

interface HealthSimulationResultLayoutProps extends RouteComponentProps {
  setLoading: Function;
  dataForm: any;
  data: any;
  policy: any;
  coverages: any;
  responseSimulation: any;
  user: IUser;
  mediator: IMediator;
  match: any;
  checkProductArea: Function;
  allPayTypes: AllPayType[];
  productPayTypes: ProductPayType[];
}

interface HealthSimulationResultLayoutState {
  changeGuideLine?: any;
  healthSimulationData: any;
  insuredMembersInfoRows: any[];
  insuredMembersInfoColumns: any[];
  leftInfoModalImg: string;
  leftInfoModalClassName: string;
  leftInfoModalTitle: string;
  leftInfoModalText: string;
  leftInfoModalButtonText: string;
  leftInfoModalCloseButtonText?: string;
  leftInfoModalClick: Function;
  mandatoryGuaranteeTableColumns: any[];
  mandatoryGuaranteeTableRows: any[];
  optionalGuaranteeTableColumns: any[];
  optionalGuaranteeTableRows: any[];
  paymentTableColumns: any[];
  paymentTableRows: any[];
  riskInfoColumns: any[];
  riskInfoRows: any[];
  setGuideLine?: any;
  showLeftInfoModal: boolean;
  showEmailModal: boolean;
  simulationDbData: any;
  simulationSaved: boolean;
  takerInfoRows: any[];
  takerInfoColumns: any[];
  takerEmail: any;
  simulation: ISimulationResult;
  amountPay: IAmountPay;
  coveragesAmount: ICoveragesAmount[];
  coveragesCampaign: ICoveragesAmount[];
  amountQuoteCoverage: IAmountQuoteCoverage[] | [];
  totalPremiumConcepts: ITotalPremiumConcepts[] | [];
  informativeCoverageConcepts: IInformativeCoverageConcepts[] | [];
  healthCoverages: any;
  modality: string;
  healthCoveragesOrder: any;
}
class HealthSimulationResultLayout extends React.Component<
  HealthSimulationResultLayoutProps,
  HealthSimulationResultLayoutState
> {
  constructor(props: HealthSimulationResultLayoutProps) {
    super(props);

    var healthSimulationData = {
      mediatorData: {},
      insuredData: [
        {
          coverages: [],
        },
      ],
      priceData: {
        price: {},
        campaigns: [],
      },
    };
    if (props.match && props.match.params && props.match.params.id) {
      // Nada
    } else if (localStorage.getItem(STORAGE_KEY.sKey)) {
      healthSimulationData = JSON.parse(
        String(localStorage.getItem(STORAGE_KEY.sKey))
      );
    } else {
      this.props.history.push("/simulacion/nueva/salud/mediador");
    }

    this.state = {
      showLeftInfoModal: false,
      simulationSaved: false,
      leftInfoModalImg: "",
      leftInfoModalClassName: "",
      leftInfoModalTitle: "",
      leftInfoModalText: "",
      leftInfoModalButtonText: "",
      leftInfoModalClick: () => { },
      takerInfoRows: this.getTakerInfoRows(),
      takerInfoColumns: this.getTakerInfoColumns(),
      healthSimulationData: healthSimulationData,
      insuredMembersInfoRows: this.getInsuredMembersInfoRows(),
      insuredMembersInfoColumns: this.getInsuredMembersInfoColumns(),
      paymentTableColumns: this.getPaymentTableColumns(),
      paymentTableRows: this.getPaymentTableRows(),
      riskInfoColumns: this.getRiskInfoColumns(),
      riskInfoRows: this.getRiskInfoRows(),
      mandatoryGuaranteeTableColumns: this.getMandatoryGuaranteeTableColumns(),
      mandatoryGuaranteeTableRows: this.getMandatoryGuaranteeTableRows(),
      optionalGuaranteeTableColumns: this.getOptionalGuaranteeTableColumns(),
      optionalGuaranteeTableRows: this.getOptionalGuaranteeTableRows(),
      leftInfoModalCloseButtonText: "",
      showEmailModal: false,
      takerEmail: {
        value: "",
        errorCode: "",
        labelText: "Email",
      },
      simulationDbData: null,
      simulation: this.getSimulationDataIni(),
      amountPay: this.getAmountPayIni(),
      coveragesAmount: [this.getCoveragesAmountIni()],
      coveragesCampaign: [this.getCoveragesAmountIni()],
      amountQuoteCoverage: [],
      totalPremiumConcepts: [],
      informativeCoverageConcepts: [],
      healthCoverages: [],
      modality: "",
      healthCoveragesOrder: []
    };
  }

  getCoveragesAmountIni = (): ICoveragesAmount => {
    return {
      PRIMANETA: "0",
      PRIMATOTAL: "0",
      TOTAL_IMPUESTOS: "0",
      CAPITAL: "1",
      CODE: "",
      DESCRIPTION: "",
      NUMERO_DE_RIESGO: 0,
    };
  };

  getSimulationDataIni = (): ISimulationResult => {
    return {
      COTIZACION: "",
      LINEA_NEGOCIO: "",
      COLECTIVO: "",
      MEDIADOR: "",
      COMBINACION_COMERCIAL_POLIZA: "",
      FECHA_EFECTO: "",
      ESTADO: "",
    };
  };

  getAmountPayIni = (): IAmountPay => {
    return {
      ASF: "0",
      CAMPANYAS: "0",
      CONSORCIO: "0",
      DEDUCCIONES: "0",
      DESCUENTOS: "0",
      DGS: "0",
      GASTOSEMI: "0",
      GASTOSEXT: "0",
      GASTOSINT: "0",
      INEM: "0",
      IPS: "0",
      PERIODO_COBRO: "",
      PRIMANETA: "0",
      PRIMAPURA: "0",
      PRIMATOTAL: "0",
      RECARGOFRA: "0",
      TOTAL_IMPUESTOS: "0",
    };
  };

  setSimulationFromDb = async (idSimulation: any) => {
    const { setLoading } = this.props;
    const simulationData = await SimulationServices.getSimulation({
      loader: true,
      setLoading: setLoading,
      id: idSimulation,
    });
    this.setState({
      healthSimulationData: simulationData.body,
      simulationDbData: simulationData,
    });
  };

  async componentDidUpdate(prevProps: any) {
    let { policy } = this.props;
    if (Object.keys(prevProps.policy.product).length === 0 && Object.keys(policy.product).length !== 0) {
      await this.setProductDescription()
      await this.setHealthCoverages()
    }
  }

  async setProductDescription() {
    const { policy } = this.props;
    if (Object.keys(policy.product).length !== 0) {
      const productDescription: string = policy.product.products_name.toUpperCase();

      this.setState({ modality: productDescription });
    }
  }

  async setHealthCoverages() {
    const { policy, coverages } = this.props;

    if (Object.keys(policy.product).length !== 0) {
      try {
        let healthCoverages: any;
        if (coverages[policy.product.products_coverageName]) {
          healthCoverages = coverages[policy.product.products_coverageName];
        } else {
          healthCoverages = await HealthService.getCoverages(this.props.setLoading, true, policy.product.products_internalName);
        }
        this.setState({ healthCoverages });
      } catch (error) {
        this.setState({
          ...this.state,
          showLeftInfoModal: true,
          leftInfoModalClassName: "error",
          leftInfoModalImg: images.WarningError,
          leftInfoModalTitle: "Error",
          leftInfoModalText: error as string,
          leftInfoModalButtonText: "Cerrar",
          leftInfoModalClick: () => { },
        });
      }

    }
  }

  async componentDidMount() {
    const { healthSimulationData } = this.state;
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      this.setSimulationFromDb(this.props.match.params.id);
    }
    await this.setProductDescription()

    await this.setHealthCoverages()

    const { RESPUESTA } = healthSimulationData.responseSimulation.body;
    const simulation = RESPUESTA.DATOS_COTIZACION;
    const coverages = RESPUESTA.COBERTURAS;
    const amountPay = RESPUESTA.RECIBOS.filter(
      (amount: any) =>
        amount.PERIODO_COBRO === healthSimulationData.priceData.price.payType
    )[0];
    const coveragesAmount = coverages.map((cov: any) => ({
      CODE: cov.GARANTIA,
      key: "dimmed-mini-body",
      CAPITAL:
        Number(cov.CAPITAL) === 1
          ? "Incluido"
          : formatter.format(Number(cov.CAPITAL)),
      NUMERO_DE_RIESGO: Number(cov.NUMERO_DE_RIESGO),
      DESCRIPTION: this.getDescriptionCoverage(cov.GARANTIA),
      ...this.getCoveragesQuoteAmount(
        RESPUESTA,
        healthSimulationData.priceData.price.payType,
        cov.GARANTIA,
        true,
        Number(cov.NUMERO_DE_RIESGO)
      ),
    }));

    let obj: any = [];
    coverages.forEach((coverage: any) => {
      const cov = obj.find((ele: any) => ele.CODE === coverage.GARANTIA);

      if (obj.length > 0 && cov) {
        cov.CAPITAL =
          parseInt(cov.CAPITAL) === 1
            ? cov.CAPITAL
            : parseInt(cov.CAPITAL) + parseInt(coverage.CAPITAL);
      } else {
        const newItem: ICoveragesAmount = {
          CODE: coverage.GARANTIA,
          CAPITAL: coverage.CAPITAL,
          DESCRIPTION: this.getDescriptionCoverage(coverage.GARANTIA),
          ...this.getCoveragesQuoteAmount(
            RESPUESTA,
            healthSimulationData.priceData.price.payType,
            coverage.GARANTIA,
            false,
          ),
        };
        obj.push(newItem);
      }
    });
    const coveragesCampaign = obj.sort(
      (a: any, b: any) =>
        COVERAGES_INSURED_ORDER[a.CODE] - COVERAGES_INSURED_ORDER[b.CODE]
    );

    const amountQuoteCoverage = RESPUESTA.IMPORTES_ASEGURADO_GARANTIA.filter(
      (item: IAmountQuoteCoverage) =>
        item.PERIODO_COBRO === healthSimulationData.priceData.price.payType
    );

    const amountCoverages = RESPUESTA.IMPORTES_COTIZACION_GARANTIA.filter(
      (item: IAmountQuoteCoverage) =>
        item.PERIODO_COBRO === healthSimulationData.priceData.price.payType
    );

    amountQuoteCoverage.sort(
      (a: IAmountQuoteCoverage, b: IAmountQuoteCoverage) =>
        COVERAGES_INSURED_ORDER[a.GARANTIA] -
        COVERAGES_INSURED_ORDER[b.GARANTIA]
    );

    amountCoverages.sort(
      (a: IAmountQuoteCoverage, b: IAmountQuoteCoverage) =>
        COVERAGES_INSURED_ORDER[a.GARANTIA] -
        COVERAGES_INSURED_ORDER[b.GARANTIA]
    );

    const totalPremiumConceptsList = [
      "CONSORCIO",
      "DGS",
      "GARANTIA",
      "IPS",
      "PRIMANETA",
      "RECARGOFRA",
    ];

    const informativeCoverageConceptsLit = [
      "GARANTIA",
      "PRIMAPURA",
      "SOBREPRIMA",
      "EXTRAPRIMA",
      "GASTOSINT",
      "GASTOSEXT",
      "GASTOSEMI",
      "DEDUCCIONES",
      "RECARGOCONS",
      "RECARGOBEN",
      "DESCUENTOFP",
      "CAMPANYAS",
      "DESCUENTOS",
    ];

    let totalPremiumConcepts: any = [];
    let informativeCoverageConcepts: any = [];

    amountCoverages.forEach((item: any, index: number) => {
      let newPremiumConcepts: any = {};
      let newInformativeConcepts: any = {};

      Object.entries(item).forEach(([key, value]) => {
        if (totalPremiumConceptsList.includes(key)) {
          newPremiumConcepts[key] = value;
        }
        if (informativeCoverageConceptsLit.includes(key)) {
          newInformativeConcepts[key] = value;
        }
      });
      totalPremiumConcepts.push(newPremiumConcepts);
      informativeCoverageConcepts.push(newInformativeConcepts);
    });
    this.setState({
      simulation,
      amountPay,
      coveragesAmount,
      coveragesCampaign,
      totalPremiumConcepts,
      informativeCoverageConcepts,
    });

  }

  changeStep = (goBack: boolean = false) => {
    if (goBack) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/simulacion/nueva/decesos/asegurado");
    }
  };

  hireSimulation() {
    const { policy } = this.props
    this.setState({
      ...this.state,
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconHiringWhite,
      leftInfoModalClassName: "",
      leftInfoModalTitle: "Proceso de contratación",
      leftInfoModalText:
        "¿Ya has visto el resultado de la simulación y estás interesado en contratar esta póliza?",
      leftInfoModalButtonText: "Empezar",
      leftInfoModalClick: () => {
        // Eliminamos el localstorage y cambiamos la url a la de la simulacion guardada
        localStorage.removeItem(STORAGE_KEY.sKey);
        let simulationId =
          this.state.simulationDbData && this.state.simulationDbData.id
            ? this.state.simulationDbData.id
            : undefined;
        let dataToPush = this.state.healthSimulationData;
        let email
        if (dataToPush.taker && dataToPush.taker.mail) {
          email = dataToPush.taker.mail
        } else {
          email = ""
        }
        dataToPush.insureds = dataToPush.insuredData;

        dataToPush.insureds.forEach((insured: any, index: number) => {
          insured.address.sameAsTaker = false;
          insured.sameAsTaker = false;
        });

        dataToPush.insuredData.forEach((insured: any) => {
          insured.coverages = insured.coverages.filter((cov: any) => cov.checked || cov.required)
          insured.coverages.forEach((cov: any) => {
            if (cov.required && !cov.capital) cov.capital = 1
          })
        });

        //if (dataToPush.taker) dataToPush.taker.name = "";

        this.props.history.push({
          pathname: `/contratacion/nueva/salud/tomador`,
          state: {
            simulationId: simulationId,
            dataForm: {
              mediator: dataToPush.mediatorData,
              insureds: dataToPush.insuredData,
              price: dataToPush.priceData,
            },
            data: {
              taker: {
                physicalPerson: true,
                email: email,
              },
              ...dataToPush,
              payment: {},
              beneficiaries: [],
              loan: {},
            },
          },
        });
      },
    });
  }

  async saveSimulation(responseSimulation: string) {
    const { policy, setLoading, user } = this.props;
    const { healthSimulationData } = this.state;
    try {
      const loggedUser = `${user.name} ${user.lastname1} ${user.lastname2}`
      const body = {
        ...healthSimulationData,
        loggedUser,
        mediatorAssociatedToPolicy: healthSimulationData.mediatorData ? healthSimulationData.mediatorData.code : undefined,
      }

      const response = await SimulationServices.simulation({
        loader: true,
        setLoading: setLoading,
        data: {
          body,
          response: responseSimulation,
          productType: policy.product.products_internalName,
        },
      });
      this.setState({ simulationDbData: response });
      return true;
    } catch (err) {
      return false;
    }
  }

  async downloadSimulation() {
    const { healthSimulationData } = this.state;
    const { policy } = this.props;

    if (
      this.state.healthSimulationData.responseSimulation &&
      this.state.healthSimulationData.responseSimulation.body &&
      this.state.healthSimulationData.responseSimulation.body.RESPUESTA &&
      this.state.healthSimulationData.responseSimulation.body.RESPUESTA
        .DATOS_COTIZACION &&
      this.state.healthSimulationData.responseSimulation.body.RESPUESTA
        .DATOS_COTIZACION.COTIZACION
    ) {

      const { simulation } = this.state;
      const docsLanguageLabel = this.getDocsLanguageLabel();
      const response = await SimulationServices.getHealthSimulationDocument({
        loader: true,
        setLoading: this.props.setLoading,
        data: {
          mediator: healthSimulationData.mediatorData.code,
          mediatorCif: healthSimulationData.mediatorData.nif,
          productType: policy.product.products_internalName,
          simulationId:
            this.state.healthSimulationData.responseSimulation.body.RESPUESTA
              .DATOS_COTIZACION.COTIZACION,
          email: '',
          mediatorName: healthSimulationData.mediatorData.name,
          combinacion_comercial_poliza: simulation.COMBINACION_COMERCIAL_POLIZA,
          ...(docsLanguageLabel ? { language: docsLanguageLabel } : ""),
        },
      })
        .then((response) => {
          if (response.status === 204) this.showModalDocNotAvailable();
          return response.data.data;
        })
        .catch((error) => {
          if (error.status === 502) this.showModalDocNotAvailable();
          else this.showModalError();

          throw error;
        });

      if (response) {
        this.updateShowModalEmail(MODAL_DOWNLOAD_PDF_OK, () => { this.props.history.push("/simulacion/pendiente") });
        downloadFile(
          new Int8Array(response),
          `Documentacion_Simulacion_${this.state.healthSimulationData.responseSimulation.body.RESPUESTA.DATOS_COTIZACION.COTIZACION}.pdf`,
          "application/pdf",
          true
        );
      }
    } else {
      this.showModalError()
    }
  }


  showModalError = (
    title: string = 'No se ha podido recuperar el PDF',
    message: string = 'Se ha producido un error, inténtelo de nuevo.',
    buttonText: string = 'Cerrar'
  ) => {
    this.setState({
      ...this.state,
      showLeftInfoModal: true,
      leftInfoModalClassName: "error",
      leftInfoModalImg: images.WarningError,
      leftInfoModalTitle: title,
      leftInfoModalText: message,
      leftInfoModalButtonText: buttonText,
      leftInfoModalClick: () => { },
    });
  }

  showModalDocNotAvailable = (
    title: string = 'No se ha podido recuperar el PDF',
    message: string = 'La documentación se está generando. Por favor, inténtelo de nuevo transcurridos unos segundos.',
    buttonText: string = 'Aceptar'
  ) => {
    this.setState({
      ...this.state,
      showLeftInfoModal: true,
      leftInfoModalClassName: "info",
      leftInfoModalImg: images.IconWarningYellow,
      leftInfoModalTitle: title,
      leftInfoModalText: message,
      leftInfoModalButtonText: buttonText,
      leftInfoModalClick: () => { },
    });
  }
  updateShowModalEmail(modalCotent: modalCotent, action: any) {
    const { img, title, text, text_button, close_button, modal_class_name, showEmailModal, showLeftInfoModal } = modalCotent
    this.setState({
      ...this.state,
      leftInfoModalImg: img,
      leftInfoModalClassName: modal_class_name,
      leftInfoModalTitle: title,
      leftInfoModalText: text,
      leftInfoModalButtonText: text_button,
      leftInfoModalClick: action,
      leftInfoModalCloseButtonText: close_button,
      showEmailModal,
      showLeftInfoModal
    });
  }
  checkBefore = async (action: string) => {
    const { healthSimulationData } = this.state;
    const { policy } = this.props;
    // Entra y comprueba si existe email, en el caso de que no exista, muestra un modal para introducirlo
    // Se llama a validateHandler para validar el email 
    if (
      !this.state.healthSimulationData.taker ||
      !this.state.healthSimulationData.taker.email
    ) {
      switch (action) {
        case MODAL_SEND_MAIL_PDF.action_text:
          this.updateShowModalEmail(MODAL_SEND_MAIL_PDF, () => this.validateHandler(MODAL_SEND_MAIL_PDF.action_text));
          break;
        case MODAL_DOWNLOAD_PDF.action_text:
          this.updateShowModalEmail(MODAL_DOWNLOAD_PDF, () => this.validateHandler(MODAL_DOWNLOAD_PDF.action_text));
          break;
      }
    } else {
      //En el caso de que ya se haya introducido un email, se guarda la simulación
      try {
        if (!this.state.simulationDbData) {
          const saveOk = await this.saveSimulation(
            healthSimulationData.responseSimulation
          );

          if (!saveOk) {
            const updatedHealthSimulationData = this.state.healthSimulationData;
            updatedHealthSimulationData.taker = null;
            this.setState({ ...this.state, healthSimulationData: updatedHealthSimulationData })
            this.updateShowModalEmail(MODAL_SAVE_SIMULATION_KO, () => { })
            return;
          }
          // Eliminamos el localstorage y cambiamos la url a la de la simulacion guardada
          localStorage.removeItem(STORAGE_KEY.sKey);
          this.props.history.replace({
            pathname: `/simulacion/nueva/salud/resultado/${this.state.simulationDbData.id}`,
          });
        }
        // En el caso de que sea mail hacemos el build (En el back si el metodo lleva email como parametro envia el correo)
        if (MODAL_SEND_MAIL_PDF.action_text === action) {
          const { simulation } = this.state;
          const docsLanguageLabel = this.getDocsLanguageLabel();

          await SimulationServices.getHealthSimulationDocument({
            loader: true,
            setLoading: this.props.setLoading,
            data: {
              mediator: healthSimulationData.mediatorData.code,
              mediatorCif: healthSimulationData.mediatorData.nif,
              productType: policy.product.products_internalName,
              simulationId:
                this.state.healthSimulationData.responseSimulation.body.RESPUESTA
                  .DATOS_COTIZACION.COTIZACION,
              email: healthSimulationData.taker.email,
              mediatorName: healthSimulationData.mediatorData.name,
              combinacion_comercial_poliza: simulation.COMBINACION_COMERCIAL_POLIZA,
              ...(docsLanguageLabel ? { language: docsLanguageLabel } : ""),
            },
          })
            .then((response) => {
              if (response.status === 204) this.showModalDocNotAvailable();
              else this.updateShowModalEmail(MODAL_SEND_MAIL_PDF_OK, () => { this.props.history.push("/simulacion/pendiente") });
              return response.data.data;
            })
            .catch((error) => {
              throw error;
            });
        }
        // En el caso que sea descarga de pdf llamamos al metodo para descargar
        if (MODAL_DOWNLOAD_PDF.action_text === action) {
          await this.downloadSimulation();
        }

      } catch (error) {
        const errorMsg = deepGet(error, "data.message", "");
        switch (action) {
          case MODAL_SEND_MAIL_PDF.action_text:
            const mailErrorModal = MODAL_SEND_PDF_KO;
            if (errorMsg) mailErrorModal.text = errorMsg;
            this.updateShowModalEmail(mailErrorModal, () => { });
            break;
          case MODAL_DOWNLOAD_PDF.action_text:
            const downloadErrorModal = MODAL_DOWNLOAD_PDF_KO;
            if (errorMsg) downloadErrorModal.text = errorMsg;
            this.updateShowModalEmail(downloadErrorModal, () => { });
            break;
        }
      }
    }
  };

  getDocsLanguageLabel() {
    const { healthSimulationData } = this.state;
    const { policy } = this.props;
    const docsLanguageValue = deepGet(healthSimulationData, "taker.docsLanguage", "");
    let docsLanguageLabel = "";
    if (docsLanguageValue) {
      const docsLanguageLabels: { label: string, value: string }[] = deepGet(policy, "product.productFields.step1.docsLanguage.options", []) || [];
      const option = docsLanguageLabels.find((lang) => lang.value === docsLanguageValue);
      docsLanguageLabel = option ? option.label : "";
    }
    return docsLanguageLabel;
  }

  openLeftInfoModal() {
    this.setState({ showLeftInfoModal: true });
  }

  closeLeftModal() {
    this.setState({
      showLeftInfoModal: false,
      leftInfoModalCloseButtonText: "",
    });
  }

  closeEmailModal() {
    this.setState({
      ...this.state,
      showEmailModal: false,
      leftInfoModalCloseButtonText: "",
      // takerEmail: {
      //   value: "",
      //   errorCode: "",
      //   labelText: "Email",
      // },
    });
  }

  getTakerInfoRows = () => {
    return [];
  };

  getTakerInfoColumns = () => {
    const { policy, checkProductArea } = this.props;
    const takerInfoColumnsPets = [
      {
        text: "Tomador",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "DNI/Pasaporte",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
    ];
    const takerInfoColumns = [
      {
        text: "Tomador",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "Fecha de nacimiento",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "DNI/Pasaporte",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
    ];
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
    return isPetArea ? takerInfoColumnsPets : takerInfoColumns;
  };

  getInsuredMembersInfoRows = () => {
    return [];
  };

  getInsuredMembersInfoColumns = () => {
    return [];
  };

  getPaymentTableRows = () => {
    const { productPayTypes } = this.props
    const healthSimulationData = JSON.parse(
      String(localStorage.getItem(STORAGE_KEY.sKey))
    );

    const { RECIBOS } = healthSimulationData.responseSimulation.body.RESPUESTA;

    if (!RECIBOS) return [];
    let prices: any = [];

    const getSequentialReceipt = (payType: string) => {
      const receipt = RECIBOS.filter(
        (receipt: any) =>
          receipt.INRECIBO === "N" && receipt.PERIODO_COBRO === payType
      );
      return receipt.length > 0 ? receipt[0].PRIMATOTAL : 0;
    };

    const productPayTypesVerified = productPayTypes && productPayTypes.length > 0
    ? productPayTypes 
    : (healthSimulationData && healthSimulationData.productPayType && healthSimulationData.productPayType.length > 0 ) 
        ? healthSimulationData.productPayType 
        : this.props.allPayTypes;

    let payments = RECIBOS.filter(
      (firtstReceipt: any) => firtstReceipt.INRECIBO === "S"
    ).map((receipt: any) => ({
      payType: PayTypesServices.getPayTypeByCode(productPayTypesVerified, receipt.PERIODO_COBRO).description,
      order: PayTypesServices.getPayTypeByCode(productPayTypesVerified, receipt.PERIODO_COBRO).payTypeOrder,
      firtsReceipt: receipt.PRIMATOTAL,
      sequential: getSequentialReceipt(receipt.PERIODO_COBRO),
    }));

    Object.keys(payments).forEach((index) => {
      const { payType, order, firtsReceipt, sequential } = payments[index];

      const newItem = {
        periodicity: {
          text: payType,
          order: order,
          type: "",
          link: "",
          key: "entire-mini-body",
        },
        firstReceipt: {
          text: formatter.format(Number(firtsReceipt)),
          type: "",
          link: "",
          key: "float-right-cell",
        },
        sequential: {
          text: formatter.format(Number(sequential)),
          type: "",
          link: "",
          key: "float-right-cell",
        },
      };
      prices.push(newItem);
    });

    prices.sort((a: any, b: any) => a.periodicity.order - b.periodicity.order);
    return prices;
  };

  getPaymentTableColumns = () => {
    const paymentTableColumns = [
      {
        text: "Forma de pago",
        filter: false,
        ascendent: true,
        key: "align-right-cell",
      },
      {
        text: "1er Recibo",
        filter: false,
        ascendent: true,
        key: "align-right-cell",
      },
      {
        text: "Sucesivos",
        filter: false,
        ascendent: false,
        key: "align-right-cell",
      },
    ];

    return paymentTableColumns;
  };

  getRiskInfoRows = () => {
    return [];
  };

  getRiskInfoColumns = () => {
    const { policy, checkProductArea } = this.props;
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)

    const riskInfoColumns = [
      {
        text:
          isDeathArea
            ? "Localidad del domicilio del tomador"
            : "Profesión",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text:
          isDeathArea
            ? "Capital decesos por asegurado"
            : "Actividad de riesgo",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ];

    return riskInfoColumns;
  };

  getMandatoryGuaranteeTableRows = () => {
    return [];
  };

  getMandatoryGuaranteeTableColumns = () => {
    const mandatoryGuaranteeTableColumns = [
      {
        text: "GARANTÍAS OBLIGATORIAS (PARA TODOS LOS ASEGURADOS)",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
    ];

    return mandatoryGuaranteeTableColumns;
  };

  getOptionalGuaranteeTableRows = () => {
    return [];
  };

  getOptionalGuaranteeTableColumns = () => {
    const optionalGuaranteeTableColumns = [
      {
        text: "GARANTÍAS OPCIONALES", //(ESTANCIA EN EL EXTRANJERO SUPERIOR A 90 DÍAS)",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
    ];

    return optionalGuaranteeTableColumns;
  };

  showExitModal = () => {
    this.setState({
      showLeftInfoModal: true,
      leftInfoModalClassName: "",
      leftInfoModalImg: images.WarningError,
      leftInfoModalTitle: "¿Desea salir del proceso?",
      leftInfoModalText: "",
      leftInfoModalButtonText: "Salir",
      leftInfoModalClick: () => {
        localStorage.removeItem(STORAGE_KEY.sKey);
        this.props.history.push("");
      },
    });
  };
  /**
   * Valida el email 
   * @param action 
   */
  validateHandler = (action: string) => {
    const { takerEmail } = this.state;
    if (!isValidEmail(takerEmail.value)) {
      this.setState({
        ...this.state,
        takerEmail: {
          ...takerEmail,
          errorCode: "invalid-email",
        },
      });
    } else {
      // Guardamos el email en el nombre del taker para que aparezca el email en el listado de pendientes
      var updatedHealthSimulationData = this.state.healthSimulationData;
      updatedHealthSimulationData.taker = {
        ...updatedHealthSimulationData.taker,
        email: takerEmail.value,
      };
      this.setState(
        {
          ...this.state,
          healthSimulationData: updatedHealthSimulationData,
          takerEmail: {
            ...takerEmail,
            errorCode: "",
            labelText: "Correo electrónico",
          },
        },
        () => {
          this.closeEmailModal();
          // Llama al ceckBefore para que haga la parte del else
          this.checkBefore(action)

        }
      );
    }
  };

  getDescriptionCoverage = (codeCoverage: string) => {
    const { healthSimulationData } = this.state;
    const { coverages } = healthSimulationData.insuredData[0];

    const coverage = coverages.filter((cov: any) => cov.code === codeCoverage);

    return coverage.length > 0 ? coverage[0].description : "";
  };

  getCoveragesQuoteAmount = (
    response: any,
    payType: string,
    codeCoverage: string,
    isFormat: boolean = true,
    numRiesgo: number = 0,
  ): ICoveragesQuoteAmount => {
    const coverageQuoteAmount = response.IMPORTES_ASEGURADO_GARANTIA.filter(
      (cov: any) =>
        cov.PERIODO_COBRO === payType && cov.GARANTIA === codeCoverage
    );
    if (!coverageQuoteAmount) {
      return { PRIMANETA: "", PRIMATOTAL: "", TOTAL_IMPUESTOS: "" };
    }
    let coverage: any = {}
    if (numRiesgo > 0) {
      coverage = coverageQuoteAmount.find((cov: any) => {
        return Number(cov.NUMERO_DE_RIESGO.toString()) === numRiesgo
      });
    } else {
      coverage = coverageQuoteAmount[0]
    }
    return {
      PRIMATOTAL: isFormat
        ? formatter.format(Number(coverage.PRIMATOTAL))
        : coverage.PRIMATOTAL,
      PRIMANETA: isFormat
        ? formatter.format(Number(coverage.PRIMANETA))
        : coverage.PRIMANETA,
      TOTAL_IMPUESTOS: isFormat
        ? formatter.format(Number(coverage.TOTAL_IMPUESTOS))
        : coverage.TOTAL_IMPUESTOS,
      PERIODO_COBRO: coverage.PERIODO_COBRO,
    };
  };

  render() {
    const {
      amountPay,
      coveragesAmount,
      healthSimulationData,
      informativeCoverageConcepts,
      leftInfoModalButtonText,
      leftInfoModalClassName,
      leftInfoModalClick,
      leftInfoModalCloseButtonText,
      leftInfoModalImg,
      leftInfoModalText,
      leftInfoModalTitle,
      paymentTableColumns,
      paymentTableRows,
      showEmailModal,
      showLeftInfoModal,
      takerEmail,
      totalPremiumConcepts,
      healthCoverages,
      modality,
    } = this.state;
    const { policy } = this.props
    let pageTitle: string;
    let goBack: JSX.Element;
    let showPendantFooter: boolean;
    // eslint-disable-next-line no-undef
    let displayNewSimulationButtons: React.CSSProperties;
    if (this.state.simulationDbData) {
      const daysFromCreation = moment
        .duration(moment().diff(moment(this.state.simulationDbData.createdAt)))
        .asDays();

      pageTitle = `DETALLE SIMULACIÓN ${daysFromCreation >= 30 ? "EXPIRADA" : "PENDIENTE"
        }`;
      goBack = (
        <GoBack
          text={`VOLVER A SIMULACIONES PENDIENTES`}
          onClick={() => this.props.history.push("/simulacion/pendiente")}
        />
      );
      showPendantFooter = true;
      displayNewSimulationButtons = { display: "none" };
    } else {
      pageTitle = "RESUMEN DE LA SIMULACIÓN";
      goBack = <GoBack text={"VOLVER"} onClick={() => this.props.history.push("/simulacion/nueva/salud/precios")}/>;
      showPendantFooter = false;
      displayNewSimulationButtons = {};
    }

    return (
      <NewSimulationContainer>
        <SimulationResultContainer
          colorSpan={setColorSpan(policy.product.ln_nameItem)}
        >
          <div className="detail-pendant-simulation-back bottomMargin10">
            {goBack}
          </div>
          <div className="colSin-100 height80"></div>
          <div className="topMargin20">
            <h5>{pageTitle}</h5>
          </div>

          <div className="result-simulation-special">
            <div className="result-simulation-special-title">
              <Title03Text>
                {
                  <p>
                    {`Nº ${healthSimulationData.responseSimulation.body.RESPUESTA.DATOS_COTIZACION.COTIZACION}`}
                  </p>
                }
              </Title03Text>
              <div>
                <p className="price">
                  {formatter.format(Number(amountPay.PRIMATOTAL))}{' '}
                  <span>
                    {healthSimulationData.priceData.price.payType === ALL_PAY_TYPES.UNIC
                      ? 'Pago único'
                      : healthSimulationData.priceData.price.paymentTime}
                  </span>
                </p>
              </div>
            </div>

            <div className="result-simulation-special-container">
              <div className="result-simulation-special-item">
                <HeadText>
                  <p>Póliza Salud</p>
                </HeadText>
              </div>
              <div className="result-simulation-special-item">
                <div className="result-simulation-special-subitem">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Fecha efecto</p>
                    </NoteText>
                  </div>
                  <div className="result-simulation-special-subitem-content">
                    <WebText>
                      <p>{healthSimulationData.mediatorData.startDate}</p>
                    </WebText>
                  </div>
                </div>
                <div className="result-simulation-special-subitem">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Expira</p>
                    </NoteText>
                  </div>
                  <div className="result-simulation-special-subitem-content">
                    <WebText>
                      <p>
                        {moment()
                          .add(1, "month")
                          .format("DD/MM/YYYY")
                          .toLocaleString()}
                      </p>
                    </WebText>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="result-simulation-container">
            {healthSimulationData.responseSimulation &&
              healthSimulationData.responseSimulation.body &&
              healthSimulationData.responseSimulation.body.RESPUESTA &&
              healthCoverages &&
              healthCoverages.length > 0 && (
                <div className="result-simulation-item float">
                  <div className="result-simulation-special-title">
                    <Title03Text>
                      <p>Modalidad</p>
                    </Title03Text>
                  </div>
                  <div className="result-simulation-item-content">
                    <HealthCampaignItem
                      checked={false}
                      index={1}
                      onCheckInsured={() => { }}
                      coveragesPriceAmount={healthSimulationData.responseSimulation.body.RESPUESTA.IMPORTES_ASEGURADO_GARANTIA.filter(
                        (cov: any) =>
                          cov.PERIODO_COBRO ===
                          healthSimulationData.priceData.price.payType
                      )}
                      coverages={
                        healthSimulationData.responseSimulation.body.RESPUESTA
                          .COBERTURAS
                      }
                      totalPremiumConcepts={totalPremiumConcepts}
                      informativeCoverageConcepts={informativeCoverageConcepts}
                      totalPrice={
                        healthSimulationData.responseSimulation.body.RESPUESTA.IMPORTESFORPAGO.filter(
                          (item: any) =>
                            item.PERIODO_COBRO ===
                            healthSimulationData.priceData.price.payType
                        )[0].PRIMATOTAL
                      }
                      healthCoverages={healthCoverages}
                      modalityName={modality}
                      payType={amountPay && amountPay.PERIODO_COBRO || ""}
                    />
                  </div>
                </div>
              )}
            <div className="result-simulation-item float">
              <div className="result-simulation-item-title">
                <Title03Text>
                  <p>Datos Tomador</p>
                </Title03Text>
              </div>
              <div className="result-simulation-item-content-table">
                <div className="tabs">




                  {(healthSimulationData.taker && healthSimulationData.taker.name) &&

                    <label className="tab-label col-100">
                      <div className="result-simulation-special-row" style={{ display: 'flex' }}>
                        <div className="result-hiring-special-subitem col-40">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>NOMBRE</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {healthSimulationData.taker.name + " " + healthSimulationData.taker.lastname1
                                  + " " + healthSimulationData.taker.lastname2}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        {(healthSimulationData.taker && healthSimulationData.taker.email) &&
                          <div className="result-hiring-special-subitem col-35">
                            <div className="result-simulation-special-subitem-title">
                              <NoteText>
                                <p>Correo</p>
                              </NoteText>
                            </div>
                            <div className="result-hiring-special-subitem-content">
                              <WebText>
                                <p>
                                  {healthSimulationData.taker.email}
                                </p>
                              </WebText>
                            </div>
                          </div>
                        }
                      </div>
                    </label>

                  }
                </div>
              </div>
            </div>
            <div className="result-simulation-item float">
              <div className="result-simulation-item-title">
                <Title03Text>
                  <p>Miembros de la póliza</p>
                </Title03Text>
              </div>
              <div className="result-simulation-item-content-table">
                <div className="tabs">
                  {healthSimulationData.insuredData.map(
                    (insured: any, index: number) => (
                      <HealthInsuredItem
                        age={Math.floor(
                          moment().diff(
                            moment(insured.birthDate, "DD/MM/YYYY", true),
                            "years",
                            true
                          )
                        )}
                        gender={insured.gender}
                        birthDate={insured.birthDate}
                        checked={false}
                        coverages={insured.coverages}
                        id={index + 1}
                        index={index + 1}
                        key={index}
                        onChange={() => "this.handleRemoveInsured(index)"}
                        onClick={() => "this.handleRemoveInsured(index)"}
                        onCheckInsured={(insured: any) =>
                          "this.handleInsuredCheck(insured)"
                        }
                        result={true}
                        isPrices={true}
                        coverageAmount={coveragesAmount.filter(
                          (cov: any) => cov.NUMERO_DE_RIESGO === index + 1
                        )}
                        healthCoverages={healthCoverages}
                        profession={insured.profession}
                      />
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="result-simulation-item float">
              <div className="result-simulation-item-title">
                <Title03Text>
                  <p>Desglose por forma de pago</p>
                </Title03Text>
              </div>
              <div className="result-simulation-item-content">
                <div className="result-simulation-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={paymentTableColumns}
                    rows={paymentTableRows}
                    keysRow={["periodicity", "firstReceipt", "sequential"]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="result-simulation-footer">
            <ul>
              <NoteText>
                <li key="">* Impuesto sobre las primas de seguro incluido.</li>
              </NoteText>
            </ul>
          </div>

          {showPendantFooter ? (
            <div>
              <div className="detail-pendant-simulation-divider" />
              <div className="detail-pendant-simulation-footer">
                <ul>
                  <NoteText>
                    <li>
                      * Encontrará la nota informativa anexa en el documento
                      Resultado de Simulación PDF, que puede descargar
                    </li>
                  </NoteText>
                  <NoteText>
                    <li>
                      * Este presupuesto tiene validez durante 30 días
                      naturales, siempre que se mantengan las circunstancias
                      declaradas por los asegurados. La aceptación del proyecto
                      está supeditada a la cumplimentación de la solicitud de
                      seguro y cuestionarios de salud asegurados. Así mismo,
                      ASISA se reserva el derecho de la aceptación del riesgo.
                    </li>
                  </NoteText>
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}
        </SimulationResultContainer>

        <InflowSidebarContainer>
          <div className="inflow-sidebar">
            <div className="inflow-buttons-container">
              <div className="inflow-buttons-item">
                <MainButton
                  text="Contratar"
                  onClick={() => this.hireSimulation()}
                />
              </div>
              <div className="inflow-buttons-item">
                <MainButton
                  /* disabled={
                    !responseSimulation.documents ||
                    !responseSimulation.documents[0]
                  } */
                  type="full-light"
                  text="Descargar PDF"
                  icon
                  iconImg={images.IconDownloadWhite}
                  onClick={() => this.checkBefore(MODAL_DOWNLOAD_PDF.action_text)}
                />
              </div>
              <div className="inflow-buttons-item">
                <MainButton
                  /* disabled={
                    !responseSimulation.documents ||
                    !responseSimulation.documents[0]
                  } */
                  type="full-light"
                  text="Enviar PDF por Email"
                  icon
                  iconImg={images.IconContactCollapsedWhite}
                  onClick={() => this.checkBefore(MODAL_SEND_MAIL_PDF.action_text)}
                />
              </div>
              <div
                className="inflow-buttons-item"
                style={displayNewSimulationButtons}
              >
                <MainButton
                  type="light"
                  text="Terminar"
                  onClick={() => this.showExitModal()}
                />
              </div>
            </div>
          </div>
        </InflowSidebarContainer>

        <LeftInfoModal
          img={leftInfoModalImg}
          className={leftInfoModalClassName}
          mainTitle={leftInfoModalTitle}
          mainText={leftInfoModalText}
          buttonText={leftInfoModalButtonText}
          close={() => this.closeLeftModal()}
          active={showLeftInfoModal}
          onClickAccept={leftInfoModalClick}
          closeButtonText={leftInfoModalCloseButtonText}
        />
        <LeftInfoModal
          img={(leftInfoModalImg) ? leftInfoModalImg : images.IconContactCollapsedWhite}
          className={leftInfoModalClassName}
          mainTitle={leftInfoModalTitle}
          mainText={leftInfoModalText}
          buttonText={leftInfoModalButtonText}
          close={() => this.closeEmailModal()}
          active={showEmailModal}
          onClickAccept={leftInfoModalClick}
          withInput={{
            labelText: takerEmail.labelText,
            value: takerEmail.value,
            errorCode: takerEmail.errorCode,
            onChange: (value: string) =>
              this.setState({
                ...this.state,
                takerEmail: { ...takerEmail, value },
              }),
          }}
        />
      </NewSimulationContainer>
    );
  }
}

export default withGeneral(withDynamicProducts(withCoverages(withPayTypes(withPolicy((withRouter(HealthSimulationResultLayout)))))));
