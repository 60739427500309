import styled from "styled-components";
import { colors } from "../../assets";
import { MainButtonContainer } from "../main-button/main-button-style";

export const CheckboxModalContainer = styled.div`
  display: none;
  color: ${colors["PR-001-100"]};
  position: fixed;
  z-index: 9999;
  background: ${colors["background-modal"]};
  left: 0;
  width: 100%;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &.active {
    display: flex;
  }

  &.error {
  }

  .checkbox-modal-container {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 20px 40px 40px;
    max-width: 620px;
    max-height: 90vh;
    overflow-y: auto;
    scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
  }
  
  /* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

  .checkbox-modal-container::-webkit-scrollbar {
      -webkit-appearance: none;
  }

  .checkbox-modal-container::-webkit-scrollbar:vertical {
      width:10px;
  }

  .checkbox-modal-container::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
      display: none;
  } 

  .checkbox-modal-container::-webkit-scrollbar:horizontal {
      height: 10px;
  }

  .checkbox-modal-container::-webkit-scrollbar-thumb {
      background-color: #797979;
      border-radius: 20px;
      border: 2px solid #f1f2f3;
  }

  .checkbox-modal-container::-webkit-scrollbar-track {
      border-radius: 10px;  
  }

  .checkbox-modal-close {
    text-align: right;

    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .checkbox-modal-main-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .checkbox-modal-icon {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkbox-modal-icon-bg {
    display: inline-block;
    background: #ffcd4b4d;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 20px;
    }
  }

  .checkbox-modal-main-content {
    padding-left: 80px;
  }

  .checkbox-multiselect-main-content {
    padding-left: 20px;
    margin-top: 20px;
  }

  .checkbox-modal-main-copy {
    margin-bottom: 20px;
  }

  .checkbox-modal-text {
    padding-left: 20px;
  }

  .checkbox-modal-main-conditions {
    margin-bottom: 40px;
  }

  .checkbox-modal-main-conditions-item {
    margin-bottom: 16px;
    &::last-child {
      margin-bottom: 0;
    }
  }

  .checkbox-modal-button {
    display: flex;

    ${MainButtonContainer} {
      width: 190px;

      &:first-child {
        margin-right: 12px;
      }
    }
  }
`;
