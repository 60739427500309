import React from "react";
import { Title02TextContainer } from "./title-02-text-styles";

interface Title02TextProps {
    children: any;
    className?: string;
    color?: string;
}

const Title02Text = ({children = '', className='', color=''}:Title02TextProps) => {
        return (
        <Title02TextContainer color={color} className={className}>
            {children}
        </Title02TextContainer>
    )
} 
export default Title02Text;