import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import GoBack from "../../../components/go-back";
import SelectBox from "../../../components/select-box/select-box";
import CardReports from "../../../components/card-reports";
import LeftInfoModal from "../../../components/left-info-modal";
import { images } from "../../../assets/images";
import { ClientsReportsContainer } from "./clients-reports-style";

import { withGeneral } from "../../../context/mixin/with-general";
import ReportServices from "../../../services/ReportServices";
import { IMediator } from "../../../models/mediator-model";
import { MONTHS } from "../../../constants/filter";
import { getLastNYears } from "../../../utils/time";
import { getRelatedMediatorsCodes } from "../../../utils/mediatorCode";
import { downloadBlob, convertBase64ToFile } from "../../../utils/utility";
import { REPORT_TYPE, FORMATS } from "../../../constants/clientsReports";
import { REACT_APP_REPORTS_YEARS } from "../../../services/url";

interface Props extends RouteComponentProps {
  mediator: IMediator;
  setLoading: Function;
}

type FilterType = {
  year: string;
  month: string;
  mediatorCode: string;
};

type MediatorCodesInputType = {
  codeOptions: { [key: string]: string }[];
  selectedCode: string;
};

export type ReportType = {
  name: string;
  label: string;
  url: string;
  format: string;
};

type ErrorType = {
  img: string;
  className: string;
  mainTitle: string;
  mainText: string;
};

const ClientsReports = ({ mediator, setLoading, location }: Props) => {
  const initialError: ErrorType = {
    img: "",
    className: "",
    mainTitle: "",
    mainText: "",
  };
  const mediatorCode = (mediator && mediator.mediatorCode) || "";
  const mediatorCodesInput: MediatorCodesInputType = {
    codeOptions: getRelatedMediatorsCodes(mediator),
    selectedCode:
      location.state && location.state.searchCode
        ? location.state.searchCode
        : mediatorCode,
  };
  const [filter, setFilter] = useState<FilterType>({
    year: "",
    month: "",
    mediatorCode,
  });
  const [error, setError] = useState<ErrorType>(initialError);

  const reportsYears = Number(REACT_APP_REPORTS_YEARS) || 5;
  const years = getLastNYears(reportsYears);
  const months = [...MONTHS].slice(1);
  const disabled = !filter.year || !filter.month || !mediatorCode;

  const handleDownload = async (type: ReportType) => {
    if (disabled) return;

    const response = await ReportServices.getReports(
      setLoading,
      true,
      filter,
      type
    );

    if (response.ok) {
      const { fileData, fileName } = response.data;
      const fileNameParts = fileName.split(".");
      const format = fileNameParts[fileNameParts.length - 1];
      downloadBlob(convertBase64ToFile(fileData, FORMATS[format]), fileName);
      return;
    }

    if (response.error) {
      if (response.errorStatus === 404) {
        setError({
          img: images.IconInfoBlue,
          className: "info",
          mainTitle: "Aviso",
          mainText: response.message,
        });
      } else {
        setError({
          img: images.IconWarningYellow,
          className: "warning",
          mainTitle: "Error",
          mainText: response.message,
        });
      }
    }
  };

  return (
    <ClientsReportsContainer>
      <div className="black-arrow-container">
        <GoBack text="Volver" />
      </div>

      <div className="main-title-container">
        <h2>Informes</h2>
      </div>

      <div className="filter-container">
        <div className="selectBox">
          <SelectBox
            optionsText={years}
            optionKey="value"
            optionValue="label"
            initialValue=""
            onChange={(value: string) => setFilter({ ...filter, year: value })}
            required
          />
          <label>AÑO</label>
        </div>

        <div className="selectBox">
          <SelectBox
            optionsText={months}
            optionKey="value"
            optionValue="label"
            initialValue=""
            onChange={(value: string) => setFilter({ ...filter, month: value })}
            required
          />
          <label>MES</label>
        </div>

        <div className="selectBox relatedMediators">
          <SelectBox
            isMediator
            optionsText={mediatorCodesInput.codeOptions}
            optionKey={"value"}
            optionValue={"label"}
            defaultValue={mediatorCodesInput.selectedCode}
            onChange={(value: string) =>
              setFilter({ ...filter, mediatorCode: value })
            }
            required={true}
            propValue={mediatorCodesInput.selectedCode}
            disabled={mediatorCodesInput.codeOptions.length < 2}
          />
          <label>Mediador</label>
        </div>
      </div>

      <div className="cards-container">
        <CardReports
          image={images.IconHiringWhite}
          title="Pólizas"
          onClick={() => handleDownload(REPORT_TYPE.POLICIES)}
          disabled={disabled}
        />
        <CardReports
          image={images.IconClipWhite}
          title="Facturas"
          onClick={() => handleDownload(REPORT_TYPE.INVOICES)}
          disabled={disabled}
        />
        {/* <CardReports
          image={images.IconoComisiones}
          title="Comisiones"
          onClick={() => handleDownload(REPORT_TYPE.COMMISSIONS)}
          disabled={disabled}
        /> */}
        {/* <div className="comisiones-place" /> */}
        <CardReports
          image={images.IconSearchWhite}
          title="Detalle de Facturación"
          onClick={() => handleDownload(REPORT_TYPE.INVOICES_DETAIL)}
          disabled={disabled}
        />
      </div>

      <LeftInfoModal
        img={error.img}
        className={error.className}
        mainTitle={error.mainTitle}
        mainText={error.mainText}
        buttonText="Cerrar"
        active={error.mainText}
        onClickAccept={() => setError(initialError)}
        close={() => setError(initialError)}
        noSecondaryButton
      />
    </ClientsReportsContainer>
  );
};

export default withGeneral(withRouter(ClientsReports));
