import * as React from "react";
import { CampaignSidebarStyle } from "./campaign-sidebar-style";
import MainButton from "../main-button";
import ItemCampaignGoal from "../item-campaign-goal/item-campaign-goal";
import { images } from "../../assets";
import MenuText from "../components-text/menu-text";
import CardMyCampaigns from "../card-my-campaigns/card-my-campaigns";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ICampaign } from "../../models/campaigns-model";
import moment from "moment";
import {
  ALL_PRODUCTS_KEY_VALUE,
  optionsUnitsKeyValue
} from "../../constants/campaignsConfig";
import { PRODUCT_TYPE } from "../../constants/policyType";

interface IPrize {
  value: string | number;
  unit: string;
  conditions?: {
    mediatorType: string;
    productLines: string[];
    products: string[];
    unit: string;
    value: string;
  }[];
}
export interface ICampaignSidebarProps extends RouteComponentProps {
  prizes?: IPrize[];
  campaigns?: ICampaign[];
  onCampaignClick?: (campaign: ICampaign) => void;
}

export interface ICampaignSidebarState {
  clickedCampaign: number;
}

class CampaignSidebar extends React.Component<
  ICampaignSidebarProps,
  ICampaignSidebarState
  > {
  constructor(props: ICampaignSidebarProps) {
    super(props);

    this.state = {
      clickedCampaign: -1
    };
  }

  // getCampaignStatus(start: string, end: string) {
  //   const startM = moment(start);
  //   const endM = moment(end);
  //   const now = moment();
  //   if (endM.isBefore(now)) return "done"; //TODO CHECK WHEN undone
  //   if (startM.isAfter(now)) return "pending";
  //   return "inProgress";
  // }

  getProductIcon(type: string) {
    switch (type) {
      case "life":
        return images.Electro;
      case PRODUCT_TYPE.DEATH:
        return images.EyeClose;
      case PRODUCT_TYPE.PET:
        return images.BellBlueIcon;
      case PRODUCT_TYPE.RETURN:
        return images.CloseSessionBlueIcon;
      case PRODUCT_TYPE.WOMAN:
        return images.IconUserBlue;
      case PRODUCT_TYPE.MORTGAGE:
        return images.IconHouseBlue;
      case PRODUCT_TYPE.TRANQUILITY:
        return images.Heart;
      case PRODUCT_TYPE.TRANQUILITY_PLUS:
        return images.Sun;
    }
    return "";
  }

  onCampaignClick(campaign: ICampaign, index: number) {
    const { onCampaignClick } = this.props;
    this.setState({ clickedCampaign: index });
    onCampaignClick && onCampaignClick(campaign);
  }

  getPrizeConditions(prize: IPrize) {
    const conditions = prize.conditions;
    const formattedConditions: any[] = [];
    let mediatorType: string | null = null;
    if (conditions) {
      conditions.forEach((condition) => {
        if (!mediatorType) mediatorType = condition.mediatorType;
        if (condition.mediatorType === mediatorType) formattedConditions.push(condition);
      })
    };
    return formattedConditions;
  }

  render() {
    const { prizes, campaigns } = this.props;
    const { clickedCampaign } = this.state;
    return (
      <CampaignSidebarStyle>
        {prizes && (
          <div className="campaigns-sidebar-button">
            <MainButton
              type={"full-light"}
              icon
              iconImg={images.IconHiringFullWhite}
              text={"bases de la campaña"}
              onClick={() => alert("click bases")}
            />
          </div>
        )}
        {prizes && prizes.length > 0 && (
          <div className="campaigns-sidebar-goal">
            <div className="campaigns-sidebar-section-title">
              <MenuText>Mi objetivo</MenuText>
            </div>
            {prizes && prizes.length > 1 ? (
              <>
                {prizes.map((prize, index) => (
                  <div className="campaigns-sidebar-goal__item">
                    <ItemCampaignGoal
                      title={`${index + 1}º Premio`}
                      icon={images.Medal}
                      text={this.getPrizeConditions(prize).map((cond: any) => {
                        return cond.products.map((product: string) => (
                          <div>
                            {`${cond.value} ${
                              optionsUnitsKeyValue[cond.unit]
                              } ${ALL_PRODUCTS_KEY_VALUE[
                                product
                              ].toLowerCase()}`}
                          </div>
                        ));
                      })}
                    />
                  </div>
                ))}
              </>
            ) : (
                prizes &&
                prizes.length === 1 &&
                prizes[0].conditions && (
                  <>
                    {prizes[0].conditions.map((condition: any) => {
                      return condition.products.map((product: string) => (
                        <div className="campaigns-sidebar-goal__item">
                          <ItemCampaignGoal
                            title={`${condition.value} ${
                              optionsUnitsKeyValue[condition.unit]
                              } ${ALL_PRODUCTS_KEY_VALUE[product].toLowerCase()}`}
                            icon={this.getProductIcon(product)}
                          />
                        </div>
                      ));
                    })}
                  </>
                )
              )}
          </div>
        )}


        <div className="campaigns-sidebar-campaign">
          <div className="campaigns-sidebar-section-title">
            <MenuText>mis campañas</MenuText>
          </div>
          {campaigns &&
            campaigns.map((campaign: ICampaign, index: number) => (
              <div
                className="campaigns-sidebar-campaign__item"
                onClick={
                  () => this.onCampaignClick(campaign, index) //this.props.history.push(`/campañas/detalle/${campaign.id}`)
                }
              >
                <CardMyCampaigns
                  type={`${moment(campaign.start).format(
                    "DD/MM/YYYY"
                  )} — ${moment(campaign.end).format("DD/MM/YYYY")}`}
                  title={campaign.name}
                  status={campaign.status}
                  active={clickedCampaign === index}
                />
              </div>
            ))}
        </div>

        <div className="campaigns-sidebar-policy">
          <div className="campaigns-sidebar-section-title">
            <MenuText>mis pólizas y recibos</MenuText>
          </div>
          <div className="campaigns-sidebar-policy-cards">
            <div className="campaigns-sidebar-policy-cards__item">
              <div className="campaigns-sidebar-policy-cards-flex-container">
                <div className="campaigns-sidebar-policy-cards__item__number">
                  <p>120</p>
                </div>
                <div className="campaigns-sidebar-policy-cards__item__subtitle">
                  <p>NUEVAS PÓLIZAS CONTRATADAS</p>
                </div>
              </div>
            </div>

            <div className="campaigns-sidebar-policy-cards__item">
              <div className="campaigns-sidebar-policy-cards-flex-container">
                <div className="campaigns-sidebar-policy-cards__item__number">
                  <p>120</p>
                </div>
                <div className="campaigns-sidebar-policy-cards__item__subtitle">
                  <p>NUEVOS RECIBOS DE PÓLIZAS</p>
                </div>
              </div>

              <div className="campaigns-sidebar-policy-cards-subsection">
                <div className="campaigns-sidebar-policy-cards-subsection__item">
                  <div className="campaigns-sidebar-policy-cards-subsection__item__number">
                    102
                  </div>
                  <div className="campaigns-sidebar-policy-cards-subsection__item__text">
                    Pagados
                  </div>
                </div>

                <div className="campaigns-sidebar-policy-cards-subsection__item">
                  <div className="campaigns-sidebar-policy-cards-subsection__item__number">
                    18
                  </div>
                  <div className="campaigns-sidebar-policy-cards-subsection__item__text">
                    Pendientes
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second sidebar */}
        {/* <div className="campaigns-sidebar-campaign" />
        <div className="campaigns-card-week-item">
          <ItemCampaignGoal
            text={firstGoal}
            title="1º Premio"
            icon={images.Medal}
          />
        </div> */}
      </CampaignSidebarStyle>
    );
  }
}

export default withRouter(CampaignSidebar);
