export const PARENTESCO: any[] = [
    { label: "Cónyuge/pareja de hecho", value: "CONYPAR", max: 1 },
    { label: "Hija/o", value: "HIJA/O", max: 10 },
    { label: "Ninguno de los anteriores", value: "OTRO", max: 10 },
    { label: "Progenitor", value: "PROGENITOR",  max: 4 },
    { label: "Tomador", value: "TOMADOR", max: 1},
    { label: "Abuela/o", value: "ABUELA/O",  max: 10 },
    { label: "Nieta/o", value: "NIETA/O",  max: 10 },
  ];

  export interface IDataProtectionMethod {
    COTIZACION: string,
    PROTECCION_DATOS: {
      TIPO_CONSENTIMIENTO: string,
      FECHA_CONSENTIMIENTO: string,
      RECEPCION_COMUNICACION: string,
      CANCELACION_DATOS: string,
      OBSERVACIONES: string,
      CESION_DATOS: string,
      PUBLICIDAD_ESCRITA_PROPIOS: string,
      PUBLICIDAD_ESCRITA_TERCEROS: string,
      PUBLICIDAD_TELEFONICA_PROPIOS: string,
      PUBLICIDAD_TELEFONICA_TERCEROS: string,
      RED_COMERCIAL: string,
      ROBINSON: string
    }
  }
  