export const countries: any = {
    "001": "Alemania",
    "002": "Andorra",
    "003": "Austria",
    "004": "Bélgica",
    "005": "Ciudad Vaticano",
    "006": "Dinamarca",
    "007": "Finlandia",
    "008": "Francia",
    "009": "Gibraltar",
    "010": "Grecia",
    "011": "Irlanda",
    "012": "Isla De Man",
    "014": "Italia",
    "015": "Liechtenstein",
    "016": "Luxemburgo",
    "017": "Malta",
    "018": "Mónaco",
    "019": "Noruega",
    "020": "Países Bajos",
    "021": "Portugal",
    "022": "Reino Unido",
    "023": "San Marino",
    "024": "Suecia",
    "025": "Suiza",
    "026": "Canadá",
    "027": "Estados Unidos De América",
    "028": "Albania",
    "029": "Bielorrusia",
    "030": "Bosnia Herzegovina",
    "031": "Bulgaria",
    "032": "Chipre",
    "033": "Croacia",
    "034": "Eslovaquia",
    "035": "Eslovenia",
    "036": "Estonia",
    "037": "Georgia",
    "038": "Hungría",
    "039": "Letonia",
    "040": "Lituania",
    "041": "Macedonia",
    "042": "Moldavia",
    "043": "Polonia",
    "044": "República Checa",
    "045": "República Eslovaca",
    "046": "Rumanía",
    "047": "Rusia",
    "048": "Turquía",
    "049": "Ucrania",
    "050": "Yugoslavia",
    "051": "Antigua Y Barbuda",
    "052": "Bahamas",
    "053": "Barbados",
    "054": "Belize",
    "055": "Bermudas",
    "056": "Costa Rica",
    "057": "Cuba",
    "058": "Dominica",
    "059": "El Salvador",
    "060": "Granada",
    "061": "Haití",
    "062": "Honduras",
    "063": "Jamaica",
    "064": "México",
    "065": "Nicaragua",
    "066": "Panamá",
    "067": "Papúa-Nueva Guinea",
    "068": "Polinesia Francesa",
    "069": "Puerto Rico",
    "070": "República Dominicana",
    "071": "San Cristobal Y Nevis",
    "072": "San Vicente",
    "073": "Santa Lucía",
    "074": "Trinidad Y Tobago",
    "075": "Argentina",
    "076": "Bolivia",
    "077": "Brasil",
    "078": "Chile",
    "079": "Colombia",
    "080": "Ecuador",
    "081": "Guatemala",
    "082": "Guyana",
    "083": "Guayana Francesa",
    "084": "Islas Malvinas (Falklands)",
    "085": "Paraguay",
    "086": "Perú",
    "087": "Surinam",
    "088": "Uruguay",
    "089": "Venezuela",
    "090": "Argelia",
    "091": "Azerbaiyán",
    "092": "Egipto",
    "093": "Israel",
    "094": "Jordania",
    "095": "Líbano",
    "096": "Libia",
    "097": "Marruecos",
    "098": "Siria",
    "099": "Túnez",
    "100": "Angola",
    "101": "Benin",
    "102": "Botswana",
    "103": "Burkina / Faso",
    "104": "Burundi",
    "105": "Cabo Verde",
    "106": "Camerún",
    "107": "Chad",
    "108": "Comores",
    "109": "Congo",
    "110": "Costa De Marfil",
    "111": "Djibouti",
    "112": "Eritrea",
    "113": "Etiopía",
    "114": "Gabon",
    "115": "Gambia",
    "116": "Ghana",
    "117": "Guinea",
    "118": "Guinea Ecuatorial",
    "119": "Guinea-bissau",
    "120": "Kenya",
    "121": "Lesotho",
    "122": "Liberia",
    "123": "Madagascar",
    "124": "Malawi",
    "125": "Mali",
    "126": "Mauricio",
    "127": "Mauritania",
    "128": "Mozambique",
    "129": "Namibia",
    "130": "Níger",
    "131": "Nigeria",
    "132": "Republica Centroafricana",
    "133": "Rwanda",
    "134": "Sáhara Occidental",
    "135": "Samoa Occidental",
    "136": "Santo Tome Y Pr",
    "137": "Senegal",
    "138": "Seychelles Y Dep.",
    "139": "Sierra Leona",
    "140": "Somalia",
    "141": "Sudáfrica",
    "142": "Sudán",
    "143": "Tanzania",
    "144": "Togo",
    "145": "Tonga",
    "146": "Uganda",
    "147": "Yemen",
    "148": "Zambia",
    "149": "Zimbabwe",
    "150": "Afganistán",
    "151": "Anguilla",
    "152": "Antillas Neerlandesas",
    "153": "Arabia Saudita",
    "154": "Armenia",
    "155": "Aruba",
    "156": "Australia",
    "157": "Bahrein",
    "158": "Bangladesh",
    "159": "Bhoutan",
    "160": "Brunei",
    "161": "Burma",
    "162": "China",
    "163": "Corea Del Norte",
    "164": "Corea Del Sur",
    "165": "Emiratos Árabes",
    "166": "Fed. Estados Micronesia",
    "167": "Fidji",
    "168": "Filipinas",
    "169": "Groenlandia",
    "170": "Guadalupe",
    "171": "Guam E Islas Oceanía Americanas",
    "172": "Hong-kong",
    "173": "India",
    "174": "Indonesia",
    "175": "Irak",
    "176": "Irán",
    "177": "Isla Bouvet",
    "178": "Isla Niue",
    "179": "Isla Norfolk",
    "180": "Islas Cayman",
    "181": "Islas Christmas",
    "182": "Islas Cook",
    "183": "Islas De Cocos (Keeling)",
    "184": "Islas Feroe",
    "185": "Islas Heard Y Macdonal",
    "186": "Islas Maldivas",
    "187": "Islas Marianas Norte",
    "188": "Islas Marshall",
    "189": "Islas Menores De EE.UU.",
    "190": "Islas Pitcairn",
    "191": "Islas Salomon",
    "192": "Islas Turcas Y Caic",
    "193": "Islas Vírgenes Americanas",
    "194": "Islas Vírgenes Británicas",
    "195": "Islas Wallis Y Fortuna",
    "196": "Japón",
    "197": "Kampuchea (Camboya)",
    "198": "Kazajstán",
    "199": "Kirguizistán",
    "200": "Kiribati",
    "201": "Kuwait",
    "202": "Laos",
    "203": "Macao",
    "204": "Malasia (Oriental Y Occidental)",
    "205": "Martinica",
    "206": "Mayotte",
    "207": "Mongolia",
    "208": "Montserrat",
    "209": "Myanmar (Ant. Birmania)",
    "210": "Nauru",
    "211": "Nepal",
    "212": "Nueva Caledonia",
    "213": "Nueva Zelanda",
    "214": "Oceanía Americ.",
    "215": "Oceanía Australiana",
    "216": "Oceanía Neozel.",
    "217": "Oman",
    "218": "Pakistán",
    "219": "Palau",
    "220": "Qatar",
    "221": "Regiones Polares",
    "222": "República Cabo Verde",
    "223": "Reunión",
    "224": "San Pedro Y Miquelon",
    "225": "Santa Helena Y Dep.",
    "226": "Singapur",
    "227": "Sri Lanka",
    "228": "Swaziland",
    "229": "Taiwán",
    "230": "Tajikistán",
    "231": "Thailandia",
    "232": "Turkmenistán",
    "233": "Tuvalu",
    "234": "Uzbekistán",
    "235": "Vanuatu",
    "236": "Vietnam",
    "ESPA": "España"
}