export enum SIGN_HIRING_STATUS_TYPE {
  NEW = 'New',
  REQUESTED = 'Requested',
  SIGNED = 'Signed',
  REJECTED = 'Rejected',
  EXPIRED = 'Expired',
  CANCELLED = 'Cancelled',
  FAILED = 'Failed'
}

export const VALID_STATUS_TYPE = {
  ALLOW_SIGN: [0, 1, 2, 3, 4, 5, 6, 7],
  ALLOW_HIRE: [2],
  ALLOW_EDIT: [0, 1, 2, 3, 4, 5, 6, 7],
}

export const isValidStatusToEnableButton = (validIndexes: Array<number>, signStatus: string) =>
  !validIndexes.includes(Object.keys(SIGN_HIRING_STATUS_TYPE).indexOf(signStatus && signStatus.toUpperCase()));