import GeneralServices from "./GeneralServices";
import { IRemoveSigneRequest, SigningRequestDto, SigningResponseDto } from "./SigningServices.dto";

class SigningServices {

  /* SigningRequestDto example:
      let signData: SigningRequestDto = {
        signingRequestType: "Deferred",
        externalProvider: "Evicertia",
        subject: "Firma Precontractual",
        documentName: file.name, // type => File 
        documentBase64: await convertFileToBase64(file), // import { convertFileToBase64 } from "../utils/utility";
        signerFullName: `${data.taker.name} ${data.taker.lastname1}`,
        signerIdentityDocument: data.taker.docNumber,
        signerPhoneNumber: data.taker.mobilePhone.split(' ').join(''),
        signerEmail: data.taker.emailInput,
        language: "es",
        uploadToGitDoc: true,
        gitDocParameters: { // solo si uploadToGitDoc = true
          topic: "Firma Precontractual",
          subject: "Firma Precontractual",
          fullname: `${data.taker.name} ${data.taker.lastname1}`,
          policyCode: this.props.data.idcotiza ? this.props.data.idcotiza : '',
          collective: "4325", // Extracted from Backend products.ts
          order: `${data.taker.name} ${data.taker.lastname1}`,
          nif: data.taker.docNumber,
        },
      };
  */
  postSignRequest = async (setLoading: Function, loader: boolean, signData: SigningRequestDto): Promise<SigningResponseDto> => {
    try {
      const response = await GeneralServices.post({
        setLoading: setLoading,
        loader: loader,
        endpoint: `signing/signrequest`,
        data: signData
      });
      return response.data;
    }
    catch (err) {
      return {
        id: ''
      }
    }
  };

  getSignRequest = async (setLoading: Function, loader: boolean, id: string): Promise<any> => {
    try {
      const response = await GeneralServices.get({
        setLoading: setLoading,
        loader: loader,
        endpoint: `signing/signrequest/${id}`,
      });
      return response.data;
    }
    catch (err) {
      return undefined;
    };
  }

  removeSignRequest = async (setLoading: Function, loader: boolean, id: string, data: IRemoveSigneRequest): Promise<any> => {
    try {
      const response = await GeneralServices.deleteReq({
        setLoading: setLoading,
        loader: loader,
        endpoint: `signing/signrequest/${id}`,
        data,
      });
      return response;
    }
    catch (err) {
      return err;
    };
  }

}

export default new SigningServices();
