import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../assets";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import GuideLine from "../../../../components/guide-line";
import MainButton from "../../../../components/main-button";
import { STORAGE_KEY } from "../../../../constants/health";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../context/mixin/with-general";
import { withPolicy } from "../../../../context/mixin/with-policy";
import { MediatorDataContainer } from "./mediator-data-style";
import { getRelatedMediatorsCodes } from "../../../../utils/mediatorCode";
import { IMediator } from "../../../../models/mediator-model";
import SelectBox from "../../../../components/select-box/select-box";
import MediatorServices from "../../../../services/MediatorServices";
import { HEALTH_MAX_EFFECT_DAYS } from "../../../../services/url";
import InputBox from "../../../../components/input-box/input-box";
import { deepGet } from "../../../../utils/utility";
import PhoneBox from "../../../../components/phone-box/phone-box";
import { validateData } from "./mediator-data.helpers";
import { defaultMaxMinDates, getMaxMinDates, MaxMinDatesType } from "../../../../utils/startEndDates";

interface MediatorProps extends RouteComponentProps {
  user: any;
  match: any;
  mediator: IMediator;
  policy: any;
}

export interface Taker {
  name: string;
  lastname1: string;
  lastname2: string;
  email: string;
  mobilePhone: string;
  landlinePhone: string;
  docsLanguage: string;
}

export interface MediatorData {
  code: string;
  name: string;
  startDate: string;
  endDate: string;
  nif: string;
  email: string;
  payment?: string;
}

interface MediatorState {
  mediatorValue: string;
  mediatorData: MediatorData;
  taker: Taker;
  healthSimulationData: any;
  nextDisabled: boolean;
  isCopay: boolean;
  relatedMediatorCodes: any;
  mediatorCodeSelected: string;
  maxEffectDays: number;
  productFields: any | null;
  errors: { [key: string]: string };
  maxMinDates: MaxMinDatesType;
}

class MediatorDataLayout extends React.Component<MediatorProps, MediatorState> {
  constructor(props: any) {
    super(props);
    var healthSimulationData;
    if (localStorage.getItem(STORAGE_KEY.sKey)) {
      healthSimulationData = JSON.parse(
        String(localStorage.getItem(STORAGE_KEY.sKey))
      );
    }

    this.state = {
      mediatorValue: `${props.user.name} ${props.user.lastname1} ${props.user.lastname2}`,
      mediatorData: {
        code: "",
        name: "",
        startDate: healthSimulationData
          ? healthSimulationData.mediatorData.startDate
          : moment().format("DD/MM/YYYY").toLocaleString(),
        endDate: healthSimulationData
          ? healthSimulationData.mediatorData.endDate
          : "",
        email: "",
        nif: "",
        payment: "",
      },
      taker: (healthSimulationData && healthSimulationData.taker) ? healthSimulationData.taker : {
        name: "",
        lastname1: "",
        lastname2: "",
        email: "",
        mobilePhone: "",
        landlinePhone: "",
        docsLanguage: "",
      },
      healthSimulationData: healthSimulationData,
      nextDisabled: false,
      isCopay: false,
      relatedMediatorCodes: getRelatedMediatorsCodes(this.props.mediator),
      mediatorCodeSelected: props.selectedMediatorCode ? props.selectedMediatorCode : props.mediator.mediatorCode,
      maxEffectDays: HEALTH_MAX_EFFECT_DAYS ? Number(HEALTH_MAX_EFFECT_DAYS) : 0,
      productFields: null,
      errors: {},
      maxMinDates: defaultMaxMinDates,
    };
  }

  componentDidMount = () => {
    const { policy } = this.props
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    const isCopay = policy.product.products_isCopay
    this.setState({ isCopay });
  };

  componentDidUpdate(prevProps: Readonly<MediatorProps>, prevState: Readonly<MediatorState>): void {
    const { policy } = this.props
    if (Object.keys(prevProps.policy.product).length === 0 && Object.keys(policy.product).length !== 0) {
      const isCopay = policy.product.products_isCopay
      this.setState({ isCopay });
    }
    const productFields: any | null = deepGet(policy, "product.productFields", null);
    const productPayTypes: { code: string, value: string }[]  = deepGet(policy, "productPayTypes", []) || [];

    if (!this.state.productFields && productFields && productPayTypes.length > 0) {
      const { mediatorData } = this.state;
      const maxMinDates = getMaxMinDates(mediatorData.startDate, productFields.step1);
      const fixEndDate = maxMinDates.fixEndDate;
      const endDate = fixEndDate
        ? moment(maxMinDates.endMinDate).format("DD/MM/YYYY") || ""
        : mediatorData.endDate;
      const mediatorNewData = { ...mediatorData, endDate };

      const hasOnlyOnePayType = productPayTypes.length === 1;
      if (hasOnlyOnePayType && !mediatorData.payment) {
        const payment = productPayTypes[0].value;
        mediatorNewData.payment = payment;
      }
      this.setState({ mediatorData: mediatorNewData, productFields, maxMinDates });
    }
  }

  async handleSelectMediatorChange(value: string) {
    this.props.policy.setMediatorSelectedCode &&
      this.props.policy.setMediatorSelectedCode(value);
    this.setState({ mediatorCodeSelected: value });
    const w: any = window;
    const mediator = {
      setLoading: w.setLoader,
      loader: true,
      mediatorCode: value,
    };
    const mediatorName = await MediatorServices.getMediatorInfo(mediator);
    let { mediatorData, mediatorValue } = this.state;
    mediatorValue = mediatorName.mediatorName;
    mediatorData.name = value;
    mediatorData.code = value;
    mediatorData.email = mediatorName.email;
    this.setState({ mediatorData, mediatorValue });
  }
  toValidate = () => {
    const { mediatorValue, healthSimulationData, isCopay, mediatorData, taker, productFields } = this.state;
    
    //Validamos los campos como indique el back
    const newErrors = validateData({ productFields, taker, mediatorData });
    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors });
      return;
    }

    mediatorData.name = mediatorValue;
    
    if (healthSimulationData) {
      const newHealthSimulationData = {
        ...healthSimulationData,
        mediatorData,
        taker,
      };
      localStorage.setItem(STORAGE_KEY.sKey, JSON.stringify({ ...newHealthSimulationData, isCopay }));
    } else {
      localStorage.setItem(STORAGE_KEY.sKey, JSON.stringify({ mediatorData: this.state.mediatorData, isCopay, taker }));
    }
    
    const { history } = this.props;
    if (mediatorData.code) history.push("/simulacion/nueva/salud/asegurados");
  };

  changeDateValue(input: "startDate" | "endDate", value: string, error?: boolean, errorCode?: string) {
    let { mediatorData, errors, productFields, maxMinDates } = this.state;
    mediatorData[input] = value;

    if (input === "startDate" && maxMinDates.endMinDate) {
      const newMaxMinDates = getMaxMinDates(value, productFields.step1);
      const { endMinDate } = newMaxMinDates;
      mediatorData.endDate = newMaxMinDates.fixEndDate ? moment(endMinDate).format("DD/MM/YYYY") : "";
      this.setState({ maxMinDates: newMaxMinDates });
    }
    const newErrors = { ...errors, [`step1.${input}`]: ""};
    this.setState({ mediatorData, nextDisabled: errorCode ? true : false, errors: newErrors });
  }
  
  handleValueChange(input: string, value: string) {
    let { taker, errors } = this.state;
    const newTaker = {...taker, [input]: value };
    const newErrors = {...errors, [`step1.${input}`]: "" };
    this.setState({ taker: newTaker, errors: newErrors });
  }

  render() {
    let {
      mediatorData,
      nextDisabled,
      relatedMediatorCodes,
      mediatorCodeSelected,
      taker,
      productFields,
      errors,
      maxMinDates,
    } = this.state;

    return (
      <MediatorDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <h2>{"DATOS DEL SEGURO"}</h2>
          </div>
          <div className="data-flow-status-guide">
            <GuideLine steps={[0, 0, 0, 0]} currentStep={1} />
          </div>
        </div>
        <div className="content-wrapper bottomMargin20" ref={''}>
          <div className="personal-data-wrapper">
            <div className="data-title" id="data-personal">
              <h5>DATOS PERSONALES</h5>
            </div>

            <div className='personal-data-fisica'>
              <div className="personal-data-row">
                <div className="personal-data-item col-50">
                  {deepGet(productFields, "step1.name", false) && (
                    <div className="inputBox">
                      <InputBox
                        placeholder="Nombre"
                        required={deepGet(productFields, "step1.name.required", false)}
                        errorCode={errors["step1.name"]}
                        value={taker.name}
                        onChange={(value: string) => this.handleValueChange("name", value.toUpperCase())}
                        type="letters"
                      />
                    </div>
                  )}
                </div>

                <div className="personal-data-item col-50">
                  {deepGet(productFields, "step1.lastname1", false) && (
                    <div className="inputBox">
                      <InputBox
                        placeholder="Apellido 1"
                        required={deepGet(productFields, "step1.lastname1.required", false)}
                        errorCode={errors["step1.lastname1"]}
                        value={taker.lastname1}
                        onChange={(value: string) => this.handleValueChange("lastname1", value.toUpperCase())}
                        type="letters"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="personal-data-row">
                <div className="personal-data-item col-50">
                  {deepGet(productFields, "step1.lastname2", false) && (
                    <div className="inputBox">
                      <InputBox
                        placeholder="Apellido 2"
                        required={deepGet(productFields, "step1.lastname2.required", false)}
                        value={taker.lastname2}
                        onChange={(value: string) => this.handleValueChange("lastname2", value.toUpperCase())}
                        type="letters"
                        errorCode={errors["step1.lastname2"]}
                      />
                    </div>
                  )}
                </div>
              </div>

              {deepGet(productFields, "step1.email", false) && (
                <div className="personal-data-row">
                  <div className="personal-data-item col-100">
                    <div className="inputBox">
                      <InputBox
                        placeholder="Correo electrónico"
                        required={deepGet(productFields, "step1.email.required", false)}
                        value={taker.email}
                        onChange={(value: string) => this.handleValueChange("email", value.toUpperCase())}
                        type="email"
                        errorCode={errors["step1.email"]}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="personal-data-row">
                {deepGet(productFields, "step1.mobilePhone", false) && (
                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <PhoneBox
                        errorCode={errors["step1.mobilePhone"]}
                        onChange={(value: string) => this.handleValueChange("mobilePhone", value)}
                        labelText=""
                        initialValue={taker.mobilePhone}
                        required={deepGet(productFields, "step1.mobilePhone.required", false)}
                      />
                      <label>teléfono móvil</label>
                    </div>
                  </div>
                )}
                {deepGet(productFields, "step1.landlinePhone", false) && (
                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <InputBox
                        errorCode={errors["step1.landlinePhone"]}
                        onChange={(value: string) => this.handleValueChange("landlinePhone", value)}
                        placeholder="TELÉFONO FIJO"
                        type="number"
                        value={taker.landlinePhone}
                        maxLength={12}
                        required={deepGet(productFields, "step1.landlinePhone.required", false)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        
        <div className="content-wrapper bottomMargin20" ref={""}>
          <div className="personal-data-wrapper">
            <div className="data-title">
              <h5>DATOS SIMULACION</h5>
            </div>

            <div className="colSin-100 topMargin20">
              <div className="colSin-50 rightPadding20">
                <div className="inputBox">
                  <SelectBox
                    isMediator={true}
                    optionsText={relatedMediatorCodes}
                    optionKey={"value"}
                    optionValue={"label"}
                    defaultValue={mediatorCodeSelected}
                    onChange={(value: string) =>
                      this.handleSelectMediatorChange(value)
                    }
                    propValue={mediatorCodeSelected}
                    required
                  />
                  <label>Mediador</label>
                </div>
              </div>

              <div className="colSin-50 rightPadding20">
                {deepGet(productFields, "step1.startDate", false) && (
                  <div className="inputBox">
                    <CalendarBox
                      initialValue={mediatorData.startDate}
                      placeholder="Fecha efecto"
                      maxDate={maxMinDates.startMaxDate}
                      minDate={maxMinDates.startMinDate}
                      onChange={(
                        value: string,
                        error?: boolean,
                        errorCode?: string
                      ) => {
                        this.changeDateValue("startDate", value, error, errorCode);
                      }}
                      required={true}
                      errorCode={errors["step1.startDate"]}
                      health={true}
                      withTooltip={deepGet(productFields, "step1.startDate.tooltip", false)}
                      tooltipTitle={deepGet(productFields, "step1.startDate.tooltip", "")}
                      tooltipText=""
                      tooltipIconLeft="140px"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="colSin-100 topMargin20">
              <div className="colSin-50 rightPadding20">
                {deepGet(productFields, "step1.docsLanguage", false) && (
                  <div className="selectBox">
                    <SelectBox
                      placeholder="Idioma de generación de documentos"
                      required={deepGet(productFields, "step1.docsLanguage.required", false)}
                      optionsText={deepGet(productFields, "step1.docsLanguage.options", []) || []}
                      optionKey="value"
                      optionValue="label"
                      initialValue={taker.docsLanguage}
                      defaultValue={taker.docsLanguage}
                      onChange={(value:string) => this.handleValueChange("docsLanguage", value)}
                      errorCode={errors["step1.docsLanguage"]}
                    />
                    <label>Idioma de generación de documentos</label>
                  </div>
                )}
              </div>

              <div className="colSin-50 rightPadding20">
                {deepGet(productFields, "step1.endDate", false) && (
                  <div className="inputBox">
                    <CalendarBox
                      initialValue={mediatorData.endDate}
                      placeholder="Fecha vencimiento"
                      maxDate={maxMinDates.endMaxDate}
                      minDate={maxMinDates.endMinDate}
                      onChange={(value: string, error?: boolean, errorCode?: string) => {
                        this.changeDateValue("endDate", value, error, errorCode);
                      }}
                      required={deepGet(productFields, "step1.endDate.required", false)}
                      errorCode={errors["step1.endDate"]}
                      health={true}
                      withTooltip={deepGet(productFields, "step1.endDate.tooltip", false)}
                      tooltipTitle={deepGet(productFields, "step1.endDate.tooltip", "")}
                      tooltipText=""
                      tooltipIconLeft="190px"
                      disabled={maxMinDates.fixEndDate}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="colSin-100">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push("/simulacion/nueva")}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                text="Siguiente"
                disabled={nextDisabled || !mediatorData.code}
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>

        {/* <InflowSidebarContainer>
          <div className="inflow-sidebar">
            <div className="inflow-buttons-container">
            <div className="inflow-sidebar-type">
            <img src={images.MiniWavePoliciePurple} alt="" />
            <div className="inflow-sidebar-type-wrapper">
              <div className="inflow-sidebar-type-text">
                <p>Póliza</p>
              </div>
              <div className="inflow-sidebar-type-title">
                <p>Salud</p>
              </div>
            </div>
          </div>
            </div>
          </div>
        </InflowSidebarContainer> */}
      </MediatorDataContainer>
    );
  }
}

export default withGeneral(withCoverages(withPolicy(withRouter(MediatorDataLayout))));