import React from "react";
import { NoteTextContainer } from "./note-text-styles";

interface NoteTextProps {
    children: any; 
    className?: string;
    color?: string;
}

const NoteText = ({children = {}, className='', color=''}:NoteTextProps ) => {

        return (
        <NoteTextContainer color={color} className={className}>
            {children}
        </NoteTextContainer>
    )
} 
export default NoteText;