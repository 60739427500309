import React, { Component } from "react";
import { CoveragesResponse, ImportesCotizacionGarantiaBodyResponse } from "../../containers/hirings/new-hiring/health/health-price/health-price-dto/health-prices-dto";
import {
    IInformativeCoverageConcepts,
    DETAIL_COVERAGE_CONCEPT_DESCRIPTION,
    ITotalPremiumConcepts
} from "../../containers/simulations/new-simulation/result/interfaces/result-interface";
import { formatter, formatterRoundNumbers } from "../../utils/utility";
import MainTable from "../main-table";
import { HealthCampaingResultContainer } from "./health-campaign-item-style";
import { ALL_PAY_TYPES } from "../../constants/payTypes";

interface ITableColumns {
    text: string,
    filter: boolean,
    ascendent: boolean,
    key: string,
}

interface HealthCampaignProps {
    checked: boolean;
    index: number;
    onCheckInsured?: any;
    coveragesPriceAmount: ImportesCotizacionGarantiaBodyResponse[];
    coverages: CoveragesResponse[];
    informativeCoverageConcepts: IInformativeCoverageConcepts[] | [];
    totalPremiumConcepts: ITotalPremiumConcepts[] | [];
    totalPrice: string;
    healthCoverages: any;
    modalityName: string;
    payType?: string;
}

interface HealthCampaignState {
    checked: boolean;
    informativeConceptsTableColumns: ITableColumns[];
    totalPremiumConceptsTableColumns: ITableColumns[];
    checkedRows: boolean[];
    healthCoveragesOrder: any;
}

class HealthCampaignItem extends Component<HealthCampaignProps, HealthCampaignState> {

    constructor(props: HealthCampaignProps) {
        super(props);

        let checkedRowsArray: boolean[] = [];
        props.coverages && props.coverages.length > 0 && props.coverages.forEach(() => checkedRowsArray.push(false));

        this.state = {
            checked: this.props.checked,
            informativeConceptsTableColumns: this.getTableColumns(),
            totalPremiumConceptsTableColumns: this.getTableColumns(),
            checkedRows: checkedRowsArray,
            healthCoveragesOrder: []
        }
    }

    getConceptsTableRows = (tableType: string, codeCoverage: string) => {

        const detail: any = (tableType === 'informativeConcepts') ? this.props.informativeCoverageConcepts : this.props.totalPremiumConcepts;

        const result = detail.filter((item: any) => item.GARANTIA === codeCoverage);

        let rows: any = [];

        result.forEach((row: any) => {
            let index = 1
            Object.entries(row).forEach(([key, value]) => {
                if (key === 'GARANTIA') return;
                const newRow = {
                    index: {
                        text: index++,
                        type: "",
                        link: "",
                        key: "entire-mini-body",
                    },
                    concept: {
                        text: DETAIL_COVERAGE_CONCEPT_DESCRIPTION[key],
                        type: "",
                        link: "",
                        key: "entire-mini-body",
                    },
                    amount: {
                        text: formatter.format(Number(value)),
                        type: "",
                        link: "",
                        key: "float-right-cell",
                    }
                }
                rows.push(newRow);
            });
        });

        return rows;
    }


    getTableColumns = () => {

        const tableColumns = [
            {
                text: "",
                filter: false,
                ascendent: true,
                key: "dimmed-mini-title",
            },
            {
                text: "Concepto",
                filter: false,
                ascendent: true,
                key: "dimmed-mini-title",
            },
            {
                text: "Importe",
                filter: false,
                ascendent: false,
                key: "dimmed-mini-title",
            },
        ];
        return tableColumns;
    }

    handleOnCheck = () => {
        this.setState({ checked: !this.state.checked });
    }

    handleOnCheckDetailCoverage = (index: number) => {
        let { checkedRows } = this.state

        checkedRows[index] = !checkedRows[index];

        this.setState({ checkedRows });
    }
    getPrimaNeta = (code: string, coveragesPriceAmount: any) => {
        let primaNeta = coveragesPriceAmount.filter((p: any) => p.GARANTIA === code)
        if (primaNeta.length > 1) {
            let price = 0
            primaNeta.map((p: any) => {
                price = price + Number(p.PRIMANETA)
            })
            return price
        } else {
            return Number(primaNeta[0].PRIMANETA)
        }
    }
    getPrimaTotal = (code: string, coveragesPriceAmount: any) => {
        let primaNeta = coveragesPriceAmount.filter((p: any) => p.GARANTIA === code)
        if (primaNeta.length > 1) {
            let price = 0
            primaNeta.map((p: any) => {
                price = price + Number(p.PRIMATOTAL)
            })
            return price
        } else {
            return Number(primaNeta[0].PRIMATOTAL)
        }
    }
    getPrimaImpuestos = (code: string, coveragesPriceAmount: any) => {
        let primaNeta = coveragesPriceAmount.filter((p: any) => p.GARANTIA === code)
        if (primaNeta.length > 1) {
            let price = 0
            primaNeta.map((p: any) => {
                price = price + Number(p.TOTAL_IMPUESTOS)
            })
            return price
        } else {
            return Number(primaNeta[0].TOTAL_IMPUESTOS)
        }
    }

    render() {
        const { coverages, totalPrice, healthCoverages, coveragesPriceAmount, modalityName, payType } = this.props;
        const { informativeConceptsTableColumns, checkedRows } = this.state;
        let coveragesFiltered: CoveragesResponse[] = [];
        let coveragesFilteredOrdered = [];
        coverages.forEach((cov, id) => {

            if (Number(cov.CAPITAL) !== 1 && coveragesFiltered.findIndex(t => t.GARANTIA === cov.GARANTIA) !== -1) {
                coveragesFiltered[coveragesFiltered.findIndex(t => t.GARANTIA === cov.GARANTIA)] = {
                    ...coveragesFiltered[coveragesFiltered.findIndex(t => t.GARANTIA === cov.GARANTIA)],
                    CAPITAL: String(Number(coveragesFiltered[coveragesFiltered.findIndex(t => t.GARANTIA === cov.GARANTIA)].CAPITAL) + Number(cov.CAPITAL)).toString(),
                }
            }
            else if (coveragesFiltered.findIndex(t => t.GARANTIA === cov.GARANTIA) === -1) {
                coveragesFiltered.push(cov)
            }
        })

        let order: any = []


        let filter = healthCoverages.sort((a: any, b: any) => {
            if (a.required) return (b.required && a.code > b.code) ? 1 : -1;
            else if (b.required) return 1
            else if (a.code < b.code) return -1;
            else return 1;
        })

        filter.map((x: any) => {
            if (order.indexOf(x.code) < 0)
                order.push(x.code)
        })

        coveragesFilteredOrdered = order.map((order: any) => {
            return coveragesFiltered.find((x: any) => x.GARANTIA === order)
        })
        coveragesFilteredOrdered = coveragesFilteredOrdered.filter(function (x: any) {
            return x != undefined
        });
        //coverages.filter((cov, index, array) => array.findIndex(t => t.GARANTIA === cov.GARANTIA) === index)
        return (
            <HealthCampaingResultContainer>
                <div className="tabs">


                    <div className="tab">

                        <input type="checkbox" checked={this.state.checked} onChange={() => this.handleOnCheck()} />
                        <label className="tab-label">
                            <div className="colSin- rightPadding20">
                                <h5>{modalityName}</h5>
                            </div>
                            <div className="priceModel">
                                <span>
                                    {payType === ALL_PAY_TYPES.UNIC ? "Pago único" : "Precio total"}: {formatter.format(Number(totalPrice))}
                                </span>
                            </div>
                        </label>

                        <div className="tab-content">

                            <div className="tabs">
                                <label className="tab-label-title col-100">
                                    <div className="col-40">Cobertura</div>
                                    <div className="col-60">
                                        <div className="result-simulation-special-row">
                                            <div className="col-25">Capital</div>
                                            <div className="col-25">Prima Neta</div>
                                            <div className="col-25">Prima Total</div>
                                            <div className="col-25">Impuestos</div>
                                        </div>
                                    </div>
                                </label>
                                {healthCoverages.length > 0 && coveragesFiltered.length > 0 && coveragesFilteredOrdered.map((cov: any, index: number) => (
                                    <div className="tab" key={`coverage${index}`}>
                                        <input type="checkbox" checked={checkedRows[index]} onChange={() => this.handleOnCheckDetailCoverage(index)} />
                                        <label className="tab-label col-100">
                                            <div className="col-40"><strong>{index + 1}.
                                            </strong>{
                                                    (healthCoverages.filter((coverage: any) => coverage.code === cov.GARANTIA)[0].name &&
                                                        healthCoverages.filter((coverage: any) => coverage.code === cov.GARANTIA)[0].name.toUpperCase()) ||
                                                    (healthCoverages.filter((coverage: any) => coverage.code === cov.GARANTIA)[0].description &&
                                                        healthCoverages.filter((coverage: any) => coverage.code === cov.GARANTIA)[0].description.toUpperCase())
                                                }</div>
                                            <div className="col-60">
                                                <div className="result-simulation-special-row">
                                                    <div className="col-25">{Number(cov.CAPITAL) > 1 ? formatterRoundNumbers.format(Number(cov.CAPITAL)) : 'INCLUIDO'}</div>
                                                    <div className="col-25 amount">{formatter.format(Number(this.getPrimaNeta(cov.GARANTIA, coveragesPriceAmount)))}</div>
                                                    <div className="col-25 amount">{formatter.format(Number(this.getPrimaTotal(cov.GARANTIA, coveragesPriceAmount)))}</div>
                                                    <div className="col-25 amount">{formatter.format(Number(this.getPrimaImpuestos(cov.GARANTIA, coveragesPriceAmount)))}</div>
                                                </div>
                                            </div>
                                        </label>
                                        <div className="tab-content">
                                            <div className="tab-content-table col-100">
                                                <div className="col-50 float">
                                                    <MainTable
                                                        className="table-campaign noZebra"
                                                        columns={informativeConceptsTableColumns}
                                                        rows={this.getConceptsTableRows('informativeConcepts', healthCoverages.filter((coverage: any) => coverage.code === cov.GARANTIA)[0].code)}
                                                        keysRow={["index", "concept", "amount"]}
                                                    />

                                                </div>
                                                <div className="col-50 float">

                                                    <MainTable
                                                        className="table-campaign noZebra"
                                                        columns={informativeConceptsTableColumns}
                                                        rows={this.getConceptsTableRows('premiumConcepts', healthCoverages.filter((coverage: any) => coverage.code === cov.GARANTIA)[0].code)}
                                                        keysRow={["index", "concept", "amount"]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </HealthCampaingResultContainer>
        )

    }
}

export default HealthCampaignItem;

