import React from "react";
import { withRouter } from "react-router-dom";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { images } from "../../../../../assets/images";
import InputBox from "../../../../../components/input-box/input-box";
import MainButton from "../../../../../components/main-button";
import { isValidIban } from "../../../../../utils/iban";
import { IIban, PaymentMethodsProps } from "./gip-payment-methods-dto";
import { PaymentMethodsContainer } from "./gip-payment-methods-style";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { ValueFormHealth } from "../../../../../constants/health";
import { GipHireMenuHelper, Steps } from "../../../../../components/gip-menu/gip-hire-menu";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";

interface GipPaymentMethodsState {
    mainIban: ValueFormHealth;
    anyChange: boolean;
    modalErrorShow: boolean;
    modalErrorText: string;
    previousModalShow: boolean;
    replaceDocModalShow: boolean;
    replaceDocModalCallback: Function;
    cancelSignModalShow: boolean
}

interface IPaymentMethod {
    mainIban: IIban
}

class PaymentMethodsLayout extends React.Component<PaymentMethodsProps, GipPaymentMethodsState> {
    _fileAccepts: string = "application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*";

    constructor(props: PaymentMethodsProps) {
        super(props);

        const paymentMethod: IPaymentMethod = this.getInitialPaymentMethod(props.paymentMethod);
        this.state = {
            mainIban: this.getDefaultValueForm(paymentMethod.mainIban.iban, props.isReadOnly),
            anyChange: false,
            modalErrorShow: false,
            modalErrorText: "",
            previousModalShow: false,
            replaceDocModalShow: false,
            replaceDocModalCallback: () => { },
            cancelSignModalShow: false
        };
    }

    async componentDidMount() {

        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 100);

    }

    getInitialPaymentMethod = (initialPaymentMethod?: IPaymentMethod): IPaymentMethod => {
        let paymentMethod = initialPaymentMethod;
        if (!paymentMethod) {
            paymentMethod = {
                mainIban: {
                    iban: "",
                    sameAsMain: true,
                },
            };
        }
        return paymentMethod;
    }

    getDefaultValueForm = (value: string | undefined, isDisabled: boolean): ValueFormHealth => {
        return {
            value: value ? value : "",
            disabled: isDisabled,
            errorCode: "",
            code: "",
        };
    }



    handleIbanValueChange = (value: string): void => {
        let { mainIban } = this.state
        mainIban.value = value;
        mainIban.errorCode = "";
        this.setState({ mainIban, anyChange: true });
    }

    showErrorModal = (text: string = "Ha ocurrido un error.") => this.setState({ modalErrorShow: true, modalErrorText: text });
    hideErrorModal = () => this.setState({ modalErrorShow: false, modalErrorText: "" });

    async setPaymentDataOnPolicy(): Promise<void> {
        const { mainIban } = this.state;
        let values: { paymentMethod: IPaymentMethod };
        values = {
            paymentMethod: {
                mainIban: {
                    iban: mainIban.value,
                    sameAsMain: true,
                }
            },
        }
        await this.props.policy.setHealthData(values);
    }

    isFormDataValid(): boolean {
        let { mainIban } = this.state
        let isDataValid = true;

        function resetErrors(mainIban: ValueFormHealth): void {
            mainIban.errorCode = '';
            mainIban.errorCode = '';
        }

        resetErrors(mainIban);

        if (!mainIban.value) {
            mainIban.errorCode = 'required';
            isDataValid = false;
        }
        else if (!isValidIban(mainIban.value)) {
            mainIban.errorCode = 'invalid-iban';
            isDataValid = false;
        }

        this.setState({ mainIban });
        return isDataValid;
    }

    async tryGoToPreviousStep(forceGoPrevious: boolean = false): Promise<void> {
        if (!this.state.anyChange || forceGoPrevious) {
            let { product }: any = this.props.match.params
            this.props.history.push(GipHireMenuHelper.getPreviousStepNavigationPath(Steps.MediosPago, product));
        }
        this.setState({ previousModalShow: true });
    }

    async tryGoToNextStep(): Promise<void> {
        if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true) {
            this.setState({ cancelSignModalShow: true })
        }
        else {
            await this.validateAndSave()
        }
    }

    async validateAndSave() {
        if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true) {
            this.props.removeSign()
        }
        if (this.props.isReadOnly) {
            let { product }: any = this.props.match.params
            this.props.history.push(GipHireMenuHelper.getNextStepNavigationPath(Steps.MediosPago, product));
            return;
        }
        if (this.isFormDataValid()) {

            let { product }: any = this.props.match.params
            await this.setPaymentDataOnPolicy();
            await this.props.saveHiring(true);
            this.props.history.push(GipHireMenuHelper.getNextStepNavigationPath(Steps.MediosPago, product));
        }
    }

    renderIban(iban: ValueFormHealth, isIbanRequired: boolean, onIbanChange: (value: string) => void): JSX.Element {

        return <div>
            <div className="colSin-100 topMargin20">
                <div className="colSin-50 rightPadding20">
                    <div className="inputBox">
                        <InputBox
                            type={"iban"}
                            placeholder="iban"
                            required={isIbanRequired}
                            errorCode={iban.errorCode}
                            value={iban.value}
                            onChange={(value: string) => onIbanChange(value)}
                            disabled={iban.disabled}
                        />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        const { mainIban, cancelSignModalShow } = this.state;

        return (<PaymentMethodsContainer>
            <div className="data-flow-status">
                <div className="data-flow-status-title">
                    <h2>{'MEDIOS DE PAGO'}</h2>
                </div>
            </div>

            <div className="content-wrapper bottomMargin20 filesSection">
                <div className="personal-data-wrapper">
                    <div className="data-title">
                        <h5>PAGO DEL SEGURO</h5>
                    </div>
                    {this.renderIban(mainIban, true, (value: string) => this.handleIbanValueChange(value))}
                </div>

            </div>

            <div className="colSin-100 topMargin20 bottomMargin20">
                <div className="personal-data-buttons">
                    <div className="personal-data-buttons__item">
                        <MainButton
                            withTab={true}
                            icon={true}
                            type={"white"}
                            iconImg={images.IconArrowLeftBlue}
                            text="Atrás"
                            onClick={() => this.tryGoToPreviousStep()}
                        />
                    </div>
                    <div className="personal-data-buttons__item">
                        <MainButton
                            withTab={true}
                            text="Siguiente"
                            onClick={() => this.tryGoToNextStep()}
                        />
                    </div>
                </div>
            </div>

            <LeftInfoModal
                img={images.IconWarningYellow}
                mainTitle={"¿Desea sustituir el mandato sepa existente por el que acaba de seleccionar?"}
                mainText={"Perderá el anteior documento."}
                buttonText="Reemplazar"
                closeButtonText="Cancelar"
                close={() => this.setState({ replaceDocModalShow: false })}
                active={this.state.replaceDocModalShow}
                onClickAccept={() => this.setState({ replaceDocModalShow: false }, () => this.state.replaceDocModalCallback)}
                className="warning"
            />
            <LeftInfoModal
                img={images.IconWarningYellow}
                mainTitle={"¿Seguro que desea volver atrás sin guardar?"}
                mainText={"Perderá todos los cambios realizados."}
                buttonText="Ir atrás"
                closeButtonText="Cancelar"
                close={() => this.setState({ previousModalShow: false })}
                active={this.state.previousModalShow}
                onClickAccept={() => this.setState({ previousModalShow: false }, () => this.tryGoToPreviousStep(true))}
                className="warning"
            />
            <LeftInfoModal
                img={images.IconWarningYellow}
                className="error"
                mainTitle={"¿Seguro que desea continuar?"}
                mainText={"Se cancelará la firma previamente solicitada"}
                buttonText="Continuar"
                closeButtonText="Cancelar"
                close={() => this.setState({ cancelSignModalShow: false })}
                active={cancelSignModalShow}
                onClickAccept={() => {
                    this.setState({ cancelSignModalShow: false }, () => this.validateAndSave())
                }}
            />
            <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
                <div className="recover-modal">
                    <div className="server-error">
                        <p>{this.state.modalErrorText}</p>
                    </div>
                    <div className="button-error">
                        <MainButton text="Aceptar" onClick={this.hideErrorModal} />
                    </div>
                </div>
            </ErrorModalContainer>
        </PaymentMethodsContainer>);
    }
}

export default withPolicy(withGeneral(withRouter(PaymentMethodsLayout)));
