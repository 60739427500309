import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { HiringDetailPendantContainer } from "./detail-pendant-hiring-style";
import Title03Text from "../../../../components/components-text/title-03-text";
import HeadText from "../../../../components/components-text/head-text";
import NoteText from "../../../../components/components-text/note-text";
import WebText from "../../../../components/components-text/web-text";
import MainTable from "../../../../components/main-table";
import { capitalizeFirstLetter, currencyFormat } from "../../../../utils";
import { getDayMonthYear, addMonths } from "../../../../utils/time";
import { AREA_NAMES } from "../../../../constants/policyType";
import { withDynamicProducts } from "../../../../context/mixin/with-dynamic-products";
import { formatter } from "../../../../utils/utility";
import { withPayTypes } from "../../../../context/mixin/with-pay-types";
import PayTypesServices from "../../../../services/PayTypesServices";
import { PAY_TYPES } from "../../../../constants/payTypes";
import DicotomicCheckbox from "../../../../components/dicotomic-checkbox/dicotomic-checkbox";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import { ProductPayType } from "../../../../services/PayTypesServices.dto";

interface RouteParams {
  id: string;
}
interface HiringDetailPendantProps extends RouteComponentProps<RouteParams> {
  hiring: any;
  productsByAreaName: any;
  checkProductArea: Function;
  allProducts: any;
  allPayTypes: any;
  coverages:any;
  productPayTypes: ProductPayType[];
}

interface HiringDetailPendantState {
  memberTableRows1: any[];
  memberTableRows2: any[];
  memberTableRowsBeneficiary: any[];
  paymentTableRows: any[];
  guaranteeTableRows: any[];
  beneficiariesTableRows: any[];
  product: any;
}

class HiringDetailPendant extends React.Component<
  HiringDetailPendantProps,
  HiringDetailPendantState
> {
  readonly state: HiringDetailPendantState;

  constructor(props: HiringDetailPendantProps) {
    super(props);
    this.state = {
      memberTableRows1: [],
      memberTableRows2: [],
      memberTableRowsBeneficiary: [],
      paymentTableRows: [],
      guaranteeTableRows: [],
      beneficiariesTableRows: [],
      product: {}
    };
  }

  componentDidMount = () => {
    const { hiring, productsByAreaName, allProducts } = this.props
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    if(hiring.id && Object.keys(productsByAreaName).length!==0 && Object.keys(allProducts).length!==0){
      this.getHiring();
      const product = allProducts[hiring.productType]
      this.setState({product})
    }
  };

  componentDidUpdate = async(prevProps: HiringDetailPendantProps) => {
    const { hiring, productsByAreaName, allProducts } = this.props

    if (prevProps.hiring.id !== hiring.id && Object.keys(productsByAreaName).length!==0 && Object.keys(allProducts).length!==0) {
      this.getHiring();
      const product = allProducts[hiring.productType]
      this.setState({product})
    }
    
  };

  getNameBeneficiary = (b: any) => {

    let nameBeneficiary = b.name;
    nameBeneficiary = `${nameBeneficiary} ${b.lastName1 && b.lastName1 !== undefined && b.lastName1 !== '' ? b.lastName1 : ''}`
    nameBeneficiary = `${nameBeneficiary} ${b.lastName2 && b.lastName2 !== undefined && b.lastName2 !== '' ? b.lastName2 : ''}`
    return nameBeneficiary

  }

  getMemberTableColumns1 = () => [
    {
      text: "Tomador",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Provincia",
      filter: false,
      ascendent: true,
    },
    {
      text: "Teléfono",
      filter: false,
      ascendent: false,
    },
    {
      text: "Email",
      filter: false,
      ascendent: true,
    },
  ];
  getMemberTableColumnsBeneficiary = [
    {
      text: "Asegurado",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Provincia",
      filter: false,
      ascendent: true,
    },
    {
      text: "Teléfono",
      filter: false,
      ascendent: false,
    },
    {
      text: "Email",
      filter: false,
      ascendent: true,
    },
  ];

  getMemberTableColumns2 = () => {
    const { hiring, checkProductArea} = this.props;
    const isPetArea = checkProductArea(AREA_NAMES.PET, hiring.productType)
    const columns = [
      {
        text: isPetArea ? "Mascotas Aseguradas" : "Asegurados",
        filter: false,
        ascendent: true,
        key: "highlighted",
      },
      {
        text: "Nacimiento",
        filter: false,
        ascendent: true,
      },
    ];
    if (!isPetArea) {
      columns.push(
        {
          text: "Teléfono",
          filter: false,
          ascendent: false,
        },
        {
          text: "Email",
          filter: false,
          ascendent: true,
        }
      );
    }
    return columns;
  };

  getPaymentTableColumns = () => [
    {
      text: "Periodicidad",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Total prima neta",
      filter: false,
      ascendent: true,
      key: "",
    },
    {
      text: "Impuestos y consorcio",
      filter: false,
      ascendent: false,
      key: "",
    },
    {
      text: "Total recibo",
      filter: false,
      ascendent: true,
      key: "",
    },
  ];

  getPaymentTableColumns2 = () => [
    {
      text: "Periodicidad",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Total prima neta",
      filter: false,
      ascendent: true,
      key: "",
    },
    {
      text: "Prima neta por mes",
      filter: false,
      ascendent: true,
      key: "",
    },
    {
      text: "Prima neta por año",
      filter: false,
      ascendent: true,
      key: "",
    },
  ];

  getGuaranteeTableColumns = () => [
    {
      text: "Coberturas incluidas en la póliza",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Capital",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
  ];

  getBeneficiariesTableColumns = () => [
    {
      text: "Beneficiarios designados",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "dni/nie",
      filter: false,
      ascendent: true,
    },
    {
      text: "Asignación",
      filter: false,
      ascendent: false,
    },
  ];

  getHiring = async () => {
    const { hiring, checkProductArea } = this.props;
    let memberTableRows1 = [];
    let memberTableRows2 = [];
    let memberTableRowsBeneficiary = [];
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, hiring.productType)
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, hiring.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, hiring.productType)
    const isPetArea = checkProductArea(AREA_NAMES.PET, hiring.productType)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, hiring.productType)
    if (isGIPArea) {
      memberTableRows1 = [
        {
          taker: {
            text: `${hiring.body.taker.name} ${hiring.body.taker.lastname1} ${hiring.body.taker.lastname2}`,
            type: "",
            link: "",
            key: "highlighted",
          },
          province: {
            text: hiring.body.taker.address.province ? hiring.body.taker.address.province.value : "",
            type: "",
            link: "",
            key: "body",
          },
          phone: {
            text: hiring.body.taker.mobilePhone,
            type: "",
            link: "",
            key: "body",
          },
          email: {
            text: hiring.body.taker.email,
            type: "",
            link: "",
            key: "body",
          },
        },
      ];


      if (hiring.body.insureds) {
        hiring.body.insureds.map((insured: any) => {
          if (insured.name)
            memberTableRows2.push({
              insured: {
                text: `${insured.name} ${insured.lastname1} ${insured.lastname2}`,
                type: "",
                link: "",
                key: "highlighted",
              },
              "birth-date": {
                text: getDayMonthYear(insured.birthDate),
                type: "",
                link: "",
                key: "body",
              },
              phone: {
                text: insured.mobilePhone,
                type: "",
                link: "",
                key: "body",
              },
              email: {
                text: insured.email,
                type: "",
                link: "",
                key: "body",
              },
            });
        });

      }
    }
    else {
      if (isHealthArea || isDentalArea) {
        memberTableRows1 = [
          {
            taker: {
              text: `${hiring.body.taker.name} ${hiring.body.taker.lastname1} ${hiring.body.taker.lastname2}`,
              type: "",
              link: "",
              key: "highlighted",
            },
            province: {
              text: (hiring.body.taker.address && hiring.body.taker.address.province) ? hiring.body.taker.address.province.value : "",
              type: "",
              link: "",
              key: "body",
            },
            phone: {
              text: hiring.body.taker.mobilePhone,
              type: "",
              link: "",
              key: "body",
            },
            email: {
              text: hiring.body.taker.email,
              type: "",
              link: "",
              key: "body",
            },
          },
        ];
      }
      else {
        memberTableRows1 = [
          {
            taker: {
              text: `${hiring.body.taker.name} ${hiring.body.taker.lastname1} ${hiring.body.taker.lastname2}`,
              type: "",
              link: "",
              key: "highlighted",
            },
            province: {
              text: hiring.body.taker.address.provinceLabel || hiring.body.taker.address.province.value,
              type: "",
              link: "",
              key: "body",
            },
            phone: {
              text: hiring.body.taker.movilPhoneNumber || hiring.body.taker.mobilePhone,
              type: "",
              link: "",
              key: "body",
            },
            email: {
              text: hiring.body.taker.email,
              type: "",
              link: "",
              key: "body",
            },
          },
        ];
      }


      memberTableRows2 = hiring.body.insureds.map((insured: any) => {
        if (isPetArea) {
          return {
            insured: {
              text: `${insured.name}`,
              type: "",
              link: "",
              key: "highlighted",
            },
            "birth-date": {
              text: getDayMonthYear(insured.birthdate),
              type: "",
              link: "",
              key: "body",
            },
          };
        } else {
          if (isHealthArea || isDentalArea) {
            return {
              insured: {
                text: `${insured.name} ${insured.lastname1} ${insured.lastname2}`,
                type: "",
                link: "",
                key: "highlighted",
              },
              "birth-date": {
                text: getDayMonthYear(insured.birthDate),
                type: "",
                link: "",
                key: "body",
              },
              phone: {
                text: insured.mobilePhone,
                type: "",
                link: "",
                key: "body",
              },
              email: {
                text: insured.email,
                type: "",
                link: "",
                key: "body",
              },
            };
          }
          if(isTravelArea){
            return {
              insured: {
                text: `${insured.name} ${insured.lastname1} ${insured.lastname2}`,
                type: "",
                link: "",
                key: "highlighted",
              },
              "birth-date": {
                text: getDayMonthYear(insured.birthDate),
                type: "",
                link: "",
                key: "body",
              },
              phone: {
                text: insured.movil,
                type: "",
                link: "",
                key: "body",
              },
              email: {
                text: insured.email,
                type: "",
                link: "",
                key: "body",
              },
            };
          }
          else {
            return {
              insured: {
                text: `${insured.name} ${insured.lastname1} ${insured.lastname2}`,
                type: "",
                link: "",
                key: "highlighted",
              },
              "birth-date": {
                text: getDayMonthYear(insured.birthdate),
                type: "",
                link: "",
                key: "body",
              },
              phone: {
                text: insured.movilPhoneNumber,
                type: "",
                link: "",
                key: "body",
              },
              email: {
                text: insured.email,
                type: "",
                link: "",
                key: "body",
              },
            };
          }
        }
      });
    }

    const coverages: any = {};
    let paymentTableRows = [];
    if (isGIPArea) {
      if (hiring.body.pricingData)
        paymentTableRows = this.getPaymentTableRows2(hiring.body.pricingData.priceData);
      this.setState({
        memberTableRows1,
        memberTableRows2,
        paymentTableRows,
      });
    } else {
      paymentTableRows = isHealthArea ? this.getHealthPaymentTableRows(hiring.body) : this.getPaymentTableRows(hiring.response);

      hiring.body.insureds.forEach((insured: any) => {
        insured.coverages.forEach((c: any) => {
          coverages[c.code] = c;
        });
      });

      let guaranteeTableRows: any = [];
      if(isHealthArea || isDentalArea){
        guaranteeTableRows = this.getGuaranteeTableRowsHealth(hiring.body)
      }else if(isTravelArea) {
        guaranteeTableRows = this.getGuaranteeTableRowsTravel(hiring.body.taker.policy.coverages)

      }else{
        guaranteeTableRows = this.getGuaranteeTableRows(hiring.body);
      }

      const beneficiariesTableRows =
        hiring.body && hiring.body.beneficiaries
          ? hiring.body.beneficiaries.map((b: any) => ({
            name: {
              text: this.getNameBeneficiary(b),
              type: "",
              key: "highlighted",
            },
            dni: {
              text: b.docNumber,
              type: "",
              key: "body",
            },
            quantity: {
              text: b.percentage + " %",
              type: "",
              key: "body",
            },
          }))
          : [];
      this.setState({
        memberTableRows1,
        memberTableRows2,
        paymentTableRows,
        guaranteeTableRows,
        beneficiariesTableRows,
      });
    }

    if(isPetArea && hiring.body.beneficiary){
      memberTableRowsBeneficiary =  [
        {
          asegurado: {
            text: `${hiring.body.beneficiary.name} ${hiring.body.beneficiary.lastname1} ${hiring.body.beneficiary.lastname2}`,
            type: "",
            link: "",
            key: "highlighted",
          },
          province: {
            text: hiring.body.beneficiary.address.provinceLabel || hiring.body.beneficiary.address.province.value,
            type: "",
            link: "",
            key: "body",
          },
          phone: {
            text: hiring.body.beneficiary.movilPhoneNumber || hiring.body.beneficiary.mobilePhone,
            type: "",
            link: "",
            key: "body",
          },
          email: {
            text: hiring.body.beneficiary.email,
            type: "",
            link: "",
            key: "body",
          },
        },
      ];
      this.setState({memberTableRowsBeneficiary})
    }
  };

  getGuaranteeTableRows = (dataForm: any) => {
    let coverages: any[] = [];
    if (dataForm.insureds[0]) {
      dataForm.insureds.forEach((insured: any) => {
        insured.coverages.forEach((coverage: any) => {
          if (!coverage.isNotShow && coverage.code) {
            coverages.push({ name: coverage.name, capital: coverage.capital || 1 });
          }
        });
      });
    }

    // TAKING OUT DUPLICATES
    coverages = coverages.filter(
      (value: any, index: any, initialArray: any) =>
        initialArray.findIndex((t: any) => t.name === value.name) === index
    );
    //

    return coverages.map((c: any) => ({
      coverages: {
        text: c.name,
        type: "",
        link: "",
        key: "entire-mini-body",
      },
      capital: {
        text:
          c.capital === 1
            ? "Incluido"
            : currencyFormat(c.capital) + " €",
        type: "",
        link: "",
        key: "highlighted",
      },
    }));
  };

  getGuaranteeTableRowsHealth = (dataForm: any) => {
    let coverages: any[] = [];
    if (dataForm.insureds[0]) {
      dataForm.insureds.forEach((insured: any) => {
        insured.coverages.forEach((coverage: any) => {
          const created = coverages.find((coverageCreated: any) => {
            if (coverageCreated.name === coverage.description && coverageCreated.capital !== 1) {
              coverageCreated.capital = Number(coverageCreated.capital) + Number(coverage.capital);
              return true;
            }
            return false
          })
          if (!created) {
            coverages.push({
              name: coverage.description,
              capital: coverage.capital || 1,
              code: coverage.code
            });
          }

        });
      });
    }

    // TAKING OUT DUPLICATES
    coverages = coverages.filter(
      (value: any, index: any, initialArray: any) =>
        initialArray.findIndex((t: any) => t.name === value.name) === index
    );
    //

    return coverages.map((c: any) => ({
      coverages: {
        text: c.name,
        type: "",
        link: "",
        key: "entire-mini-body",
      },
      capital: {
        text:
          c.capital === 1
            ? "Incluido"
            : currencyFormat(c.capital) + " €",
        type: "",
        link: "",
        key: "highlighted",
      },
    }));
  };

    getGuaranteeTableRowsTravel = (dataForm:any) =>{
       let coverages: any[] = [];

       {        
        dataForm.map( (coverage:any) =>{
          coverages.push({
            name:coverage.description,
            capital : 1
          })
        })
    
        return coverages.map((c: any) => ({
          coverages: {
            text: c.name ? capitalizeFirstLetter(c.name.toLowerCase()) : "",
            type: "",
            link: "",
            key: "entire-mini-body",
          },
          capital: {
            text: c.capital === 1 || c.capital === formatter.format(1.00) ? "Incluido" : currencyFormat(c.capital) + " €",
            type: "",
            link: "",
            key: "highlighted",
          },
        }));
      }
    }
  getHealthPaymentTableRows = (hiringBody: any) => {
    const { productPayTypes } = this.props
    let payments: any[] = [];
    if (hiringBody && hiringBody.priceData && hiringBody.priceData.priceResponse && hiringBody.priceData.priceResponse.body && hiringBody.priceData.priceResponse.body.RESPUESTA) {
      hiringBody.priceData.priceResponse.body.RESPUESTA.RECIBOS.forEach((importePago: any) => {
        if (importePago.INRECIBO === 'S') {
          payments.push({
            payType: importePago.PERIODO_COBRO,
            price: importePago.PRIMANETA,
            taxes: importePago.TOTAL_IMPUESTOS,
            total: importePago.PRIMATOTAL
          })
        }
      })
    }

    const paymentTableRows = payments.map((payment: any, index: number) => ({
      periodicity: {
        text: PayTypesServices.getPayTypeByCode(productPayTypes, payment.payType).description,
        order: PayTypesServices.getPayTypeByCode(productPayTypes, payment.payType).payTypeOrder,
        type: "",
        link: "",
        key: "highlighted",
      },
      netPremium: {
        text: `${currencyFormat(payment.price)} €`,
        type: "",
        link: "",
        key: "body",
      },
      taxs: {
        text: `${currencyFormat(payment.taxes)} €`,
        type: "",
        link: "",
        key: "body",
      },
      total: {
        text: `${currencyFormat(payment.total)} €`,
        type: "",
        link: "",
        key: "body",
      },
    }));

    paymentTableRows.sort(
      (a: any, b: any) => a.periodicity.order - b.periodicity.order
    );

    return paymentTableRows;
  };

  getPaymentTableRows = (responseSimulation: any) => {
    const { allPayTypes } = this.props
    const payments: any[] =
      responseSimulation && responseSimulation.payments
        ? responseSimulation.payments.filter((p: any) => p.payType !== PAY_TYPES.BIME)
        : [];

    const paymentTableRows = payments.map((payment: any, index: number) => ({
      periodicity: {
        text: PayTypesServices.getPayTypeByCode(allPayTypes, payment.payType).description,
        order: PayTypesServices.getPayTypeByCode(allPayTypes, payment.payType).payTypeOrder,
        type: "",
        link: "",
        key: "highlighted",
      },
      netPremium: {
        text: `${currencyFormat(payment.netPremium)} €`,
        type: "",
        link: "",
        key: "body",
      },
      taxs: {
        text: `${currencyFormat(payment.taxs)} €`,
        type: "",
        link: "",
        key: "body",
      },
      total: {
        text: `${currencyFormat(payment.totalPremium)} €`,
        type: "",
        link: "",
        key: "body",
      },
    }));

    paymentTableRows.sort(
      (a: any, b: any) => a.periodicity.order - b.periodicity.order
    );

    return paymentTableRows;
  };

  getPaymentTableRows2 = (priceData: any) => {

    const paymentTableRows = priceData.map((payment: any, index: number) => ({
      periodicity: {
        text: payment.DesFormaPago,
        type: "",
        link: "",
        key: "highlighted",
      },
      netPremium: {
        text: `${currencyFormat(payment.TotalPrimaNeta)} €`,
        type: "",
        link: "",
        key: "body",
      },
      netPremiumMonth: {
        text: `${currencyFormat(payment.TotalPrimaNetaMes)} €`,
        type: "",
        link: "",
        key: "body",
      },
      netPremiumYear: {
        text: `${currencyFormat(payment.TotalPrimaNetaAnio)} €`,
        type: "",
        link: "",
        key: "body",
      },
    }));

    return paymentTableRows;
  };

  renderError = (errors: any, productType: string ) => {
    const { checkProductArea } = this.props
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, productType)

    return (

      <div className="detail-pendant-hiring-container">
        <div className="detail-pendant-hiring-item">
          <div className="detail-pendant-hiring-item-title">
            <Title03Text>
              <p>Error en la emisión</p>
            </Title03Text>
          </div>
          <div className="detail-pendant-hiring-item-content">
            {(typeof errors === 'string' && (isHealthArea || isDentalArea)) &&
              <WebText>
                <p>{`Error-${1} ${errors}`}</p>
              </WebText>
            }
            { typeof errors !== 'string' && typeof errors[0] !== 'string' &&
              errors.map(( { description }: { description: any }, index: number ) => 
                <WebText>
                  { typeof description === 'string' && <p>{`Error-${index + 1} ${ description.toLowerCase().includes('framework') ? 'Excepción General. Se ha producido un error.': description }`}</p> }
                  { Array.isArray(description) && description.toString() && <p>{`Error-${index + 1} ${ description.toString().includes('framework') ? 'Excepción General. Se ha producido un error.': description.toString() }`}</p> }
                </WebText>
              )}
            { typeof errors !== 'string' &&  typeof errors[0] === 'string' &&
              errors.map(( error: string, index: number ) => 
                <WebText>
                  <p>{`Error-${index + 1} ${ error.toLowerCase().includes('framework') ? 'Excepción General. Se ha producido un error.': error }`}</p>
                </WebText>
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { hiring, checkProductArea, coverages } = this.props;
    const {
      memberTableRows1,
      memberTableRows2,
      memberTableRowsBeneficiary,
      paymentTableRows,
      guaranteeTableRows,
      beneficiariesTableRows,
      product
    } = this.state;
    const isPetArea = checkProductArea(AREA_NAMES.PET, hiring.productType)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, hiring.productType)
    type InsuredType = {
      coverages: CoverageType[];
    };
    
    type CoverageType = {
      code: string;
      name: string;
      required: boolean;
      capital: string; 
    };
    const getTableNumberMascots = (insured: InsuredType, index: number): JSX.Element => {
      const { coverages } = this.props;
    
      const obtenerCapital = (coverage: CoverageType): string => {
        const objCapital = insured.coverages.find((cov: CoverageType) => cov.code === coverage.code);
        return objCapital ? formatter.format(Number(objCapital.capital)) : "0 €";
      };
    
      const main: JSX.Element[] = [];
      const complementary: JSX.Element[] = [];
    
      coverages.pet.forEach((coverage: CoverageType, key: number) => {
        const jsxElement = coverage.required ? (
          <p className="padding-contenido" key={key}>
            {coverage.name}
          </p>
        ) : (
          <div className="flex-space-between padding-cabeceras" key={key}>
            <DicotomicCheckbox
              initChecked={insured.coverages.some((covSelect: CoverageType) => coverage.code === covSelect.code)}
              className="mandatory"
              checkboxText={coverage.name}
              onChange={() => {}}
            />
            <p className="mb-15">{obtenerCapital(coverage)}</p>
          </div>
        );
    
        coverage.required ? main.push(jsxElement) : complementary.push(jsxElement);
      });
      return (
        <div key={index} className="detail-pendant-hiring-item-content-table bg-white">
          <div className="colSin-100 bg-snow">
            <div className="colSin-25 table-bgBlue padding-cabeceras borderB-white">Mascota {index + 1}</div>
          </div>
          <div className="colSin-50 borderR-white">
            <p className="table-bgBlue padding-cabeceras">GARANTÍAS OBLIGATORIAS</p>
            {main}
          </div>
          <div className="colSin-50">
            <p className="table-bgBlue padding-cabeceras">GARANTÍAS OPCIONALES</p>
            {complementary}
          </div>
        </div>
      );
    }
    
    return (
      <HiringDetailPendantContainer>
        <div className="detail-pendant-hiring-special">
          <div className="detail-pendant-hiring-special-title">
            <Title03Text>
              <p>ID: {hiring.body.idcotiza || hiring.body.requestNumber || hiring.body.requestId || hiring.id}</p>
              <p>MEDIADOR: {hiring.mediatorAssociatedToPolicy}</p>
            </Title03Text>
          </div>
          <div className="detail-pendant-hiring-special-container">
            <div className="detail-pendant-hiring-special-item">
              <HeadText>
                <p>Póliza {product.products_name ? product.products_name : ''}</p>
              </HeadText>
            </div>
            <div className="detail-pendant-hiring-special-item">
              <div className="detail-pendant-hiring-special-subitem">
                <div className="detail-pendant-hiring-special-subitem-title">
                  <NoteText>
                    <p>Contratación pendiente</p>
                  </NoteText>
                </div>
                <div className="detail-pendant-hiring-special-subitem-content">
                  <WebText>
                    <p>{getDayMonthYear(hiring.createdAt)}</p>
                  </WebText>
                </div>
              </div>
              <div className="detail-pendant-hiring-special-subitem">
                <div className="detail-pendant-hiring-special-subitem-title">
                  <NoteText>
                    <p>Expira</p>
                  </NoteText>
                </div>
                <div className="detail-pendant-hiring-special-subitem-content">
                  <WebText>
                    <p>{getDayMonthYear(addMonths(hiring.createdAt, 1))}</p>
                  </WebText>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          hiring.body.emisionError && hiring.body.emisionError.length > 0 &&
          this.renderError(hiring.body.emisionError, hiring.productType)
        }
        <div className="detail-pendant-hiring-container">
          <div className="detail-pendant-hiring-item">
            <div className="detail-pendant-hiring-item-title">
              <Title03Text>
                <p>Miembros de la póliza</p>
              </Title03Text>
            </div>
            <div className="detail-pendant-hiring-item-content">
              <div className="detail-pendant-hiring-item-content-table">
                <MainTable
                  className="table noZebra"
                  columns={this.getMemberTableColumns1()}
                  rows={memberTableRows1}
                  keysRow={["taker", "province", "phone", "email"]}
                />
              </div>
              {isPetArea && hiring.body.beneficiary &&
              <div className="detail-pendant-hiring-item-content-table">
                <MainTable
                  className="table noZebra"
                  columns={this.getMemberTableColumnsBeneficiary}
                  rows={memberTableRowsBeneficiary}
                  keysRow={["asegurado", "province", "phone", "email"]}
                />
              </div>
              }
              <div className="detail-pendant-hiring-item-content-table">
                <MainTable
                  className="table noZebra"
                  columns={this.getMemberTableColumns2()}
                  rows={memberTableRows2}
                  keysRow={
                    isPetArea
                      ? ["insured", "birth-date"]
                      : ["insured", "birth-date", "phone", "email"]
                  }
                />
              </div>
            </div>
          </div>

          {isGIPArea ?
            paymentTableRows && paymentTableRows.length > 0 &&
            (<div className="detail-pendant-hiring-item">
              <div className="detail-pendant-hiring-item">
                <div className="detail-pendant-hiring-item-title">
                  <Title03Text>
                    <p>Desglose por forma de pago</p>
                  </Title03Text>
                </div>
                <div className="detail-pendant-hiring-item-content">
                  <div className="detail-pendant-hiring-item-content-table">
                    <MainTable
                      className="table noZebra"
                      columns={this.getPaymentTableColumns2()}
                      rows={paymentTableRows}
                      keysRow={["periodicity", "netPremium", "netPremiumMonth", "netPremiumYear"]}
                    />
                  </div>
                  <div className="detail-pendant-hiring-item-content-advise">
                    <NoteText>
                      <p>* Impuesto sobre las Primas de seguro incluido</p>
                    </NoteText>
                  </div>
                </div>
              </div>
            </div>)
            :

            paymentTableRows && paymentTableRows.length > 0 && (
              <div className="detail-pendant-hiring-item">
                <div className="detail-pendant-hiring-item-title">
                  <Title03Text>
                    <p>Desglose por forma de pago</p>
                  </Title03Text>
                </div>
                <div className="detail-pendant-hiring-item-content">
                  <div className="detail-pendant-hiring-item-content-table">
                    <MainTable
                      className="table noZebra"
                      columns={this.getPaymentTableColumns()}
                      rows={paymentTableRows}
                      keysRow={["periodicity", "netPremium", "taxs", "total"]}
                    />
                  </div>
                  <div className="detail-pendant-hiring-item-content-advise">
                    <NoteText>
                      <p>* Impuesto sobre las Primas de seguro incluido</p>
                    </NoteText>
                  </div>
                </div>
              </div>
            )}

          {hiring.body.beneficiaries && hiring.body.beneficiaries.length > 0 && (
            <div className="detail-pendant-hiring-item">
              <div className="detail-pendant-hiring-item-title">
                <Title03Text>
                  <p>Beneficiarios</p> {/*TODO: BENEFICIARIOS*/}
                </Title03Text>
              </div>
              <div className="detail-pendant-hiring-item-content">
                <div className="detail-pendant-hiring-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={this.getBeneficiariesTableColumns()}
                    rows={beneficiariesTableRows}
                    keysRow={["name", "dni", "quantity"]}
                  />
                </div>
              </div>
            </div>
          )}

          {isGIPArea ? "" :
            (<div className="detail-pendant-hiring-item">
              <div className="detail-pendant-hiring-item-title">
                <Title03Text>
                  <p>Garantías</p>
                </Title03Text>
              </div>
              <div className="detail-pendant-hiring-item-content">
                {/* <div className="detail-pendant-hiring-item-content-capital">
                <div className="detail-pendant-hiring-item-content-capital-text">
                  <HeadText>
                    <p>
                      Capital a asegurar: <span>28000€</span>
                    </p>
                  </HeadText>
                </div>
              </div> */}
                {isPetArea? 
                  hiring.body.insureds.map((insured:any, i:number)=>{
                    return getTableNumberMascots(insured, i);
                  })
                :<div className="detail-pendant-hiring-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={this.getGuaranteeTableColumns()}
                    rows={guaranteeTableRows}
                    keysRow={["coverages", "capital"]}
                  />
                </div>}
                <div className="detail-pendant-hiring-item-content-advise">
                  <NoteText>
                    <p>* Impuesto sobre las Primas de seguro incluido</p>
                  </NoteText>
                </div>
              </div>
            </div>)
          }
        </div>
      </HiringDetailPendantContainer>
    );
  }
}

export default withDynamicProducts(withPayTypes(withCoverages(withRouter(HiringDetailPendant))));
