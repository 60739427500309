import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { DetailClientsPoliciesContainer } from "./detail-clients-policies-style";
import MainTable from "../../../../components/main-table";
import GoBack from "../../../../components/go-back";
import { withGeneral } from "../../../../context/mixin/with-general";
import PoliciesServices from "../../../../services/PoliciesServices";
import MainButton from "../../../../components/main-button";
import { ErrorModalContainer } from "../../../../components/error-modal/error-modal-style";
import { convertBase64ToFile, deepGet, downloadFile, formatter } from "../../../../utils/utility";
import { petDataColumns, personDataColumns, receiptDataColumns, POLICY_SEARCH_OPTION } from "../../../../constants/clientsPolicies";
import Pagination from "../../../../components/pagination";
import { DetailClientsPoliciesProps, DetailClientsPoliciesState, IPolicyDataRow, IPolicyReceiptDataRow, IPolicyRiskDataRow, 
  IPolicyTakerDataRow, ITableConfig, PolicyDetailDataDto, TakerDetailDataDto, ReceiptDetailDataDto, RiskDetailPersonDataDto, RiskDetailPetDataDto } from "../dto/detail-clients-policies-layout-dto";
import { ERROR_MESSAGES } from "../../../../constants/errorMessages";
import { IPolicyDetail, IPolicyReceiptsDetail, IPolicyRiskDetail, IPolicyTakerDetail } from "../../../../services/PoliciesServices-dto";
import GestDocServices from "../../../../services/Gest-Doc.Services";
import { Button } from "../../../../components/buttons";
import { getUserCode } from "../../../../utils/mediatorCode";
import { withDynamicProducts } from "../../../../context/mixin/with-dynamic-products";
import dateFns from 'date-fns';

class DetailClientsPoliciesLayout extends React.Component<DetailClientsPoliciesProps, DetailClientsPoliciesState> {
  constructor(props: DetailClientsPoliciesProps) {
    super(props);
    this.state = {
      policyDataRows: [],
      takerDataRows: [],
      personDataRows: [],
      petDataRows: [],
      receiptDataRows: [],
      mediatorCode: props.match.params.mediator,
      policy: props.match.params.id,
      order:  props.match.params.order,
      nifTomador: '',
      searchType: POLICY_SEARCH_OPTION.POLIZA,
      colective: '',
      ramo: '',
      text: '',
      idDelete: undefined,
      currentTab: 'datos-poliza',
      riskParams: {
        order: '',
        ascendent: false,
        page: 1,
        showItems: 6,
        totalPages: 0,
      },
      receiptsParams: {
        order: 'fec_expedicion',
        ascendent: false,
        page: 1,
        showItems: 6,
        totalPages: 0,
      },
      serverError: false,
      forbidden: (!props.location.state ? true : false),
      policyOrigin: '',
      noDataMessage: '',
    };
  }

  componentDidMount() {
    this.tryGetNavigationProps();
    if (!this.state.forbidden && !this.state.serverError)
      this.getPolicy();
  }

  tryGetNavigationProps = () => {
    if (this.props.location.state) {
      try {
        this.setState({
          colective: this.props.location.state.data.colectivo.text.substr(0, this.props.location.state.data.colectivo.text.indexOf('|')),
          ramo: this.props.location.state.data.colectivo.text.substr(this.props.location.state.data.colectivo.text.indexOf('|')+1),
          nifTomador: this.props.location.state.data.nif_tomador.text,
        });
      } catch {
        this.setState({
          serverError: true,
        });
      }
    }
  }

  getPolicy = async () => {
    const { policy, mediatorCode, order } = this.state;
    const { setLoading } = this.props;

    const data: IPolicyDetail = {
      codMediador: mediatorCode,
      policy: policy,
      policyOrder: order
    }

    const detailPolicy = await PoliciesServices.searchPolicyDetail(
      setLoading,
      true,
      data,
    ).catch(() => {
      this.setState({ serverError: true });
    });

    if (detailPolicy !== undefined) {
      const policyDataRows: IPolicyDataRow[] = [];
      detailPolicy.policyDetail.forEach((policy: PolicyDetailDataDto) => {
        const row: IPolicyDataRow = {
          policyRamo: {
            text: policy.ramo,
            type: "",
            link: "",
            key: "responsive",
          },
          policyGrupoNegocio: {
            text: policy.grupo_negocio,
            type: "",
            link: "",
            key: "responsive",
          },
          policyColective: {
            text: policy.colectivo,
            type: "",
            link: "",
            key: "responsive number",
          },
          policyMediator: {
            text: mediatorCode,
            type: "",
            link: "",
            key: "responsive number",
          },
          policyNumber: {
            text: policy.poliza,
            type: "",
            link: "",
            key: "responsive number",
          },
          status: {
            text: policy.estado_poliza,
            type: "",
            link: "",
            key: "responsive",
          },
          order: {
            text: policy.orden,
            type: "",
            link: "",
            key: "responsive",
          },
          policyReleaseDate: {
            text: policy.fec_emision ? new Date(policy.fec_emision).toLocaleDateString() : "",
            type: "",
            link: "",
            key: "responsive number",
          },
          policyInitialDate: {
            text: policy.fec_efecto_alta ? new Date(policy.fec_efecto_alta).toLocaleDateString() : "",
            type: "",
            link: "",
            key: "responsive number",
          },
          policyFinishDate: {
            text: policy.fec_vencimiento ? new Date(policy.fec_vencimiento).toLocaleDateString() : "",
            type: "",
            link: "",
            key: "responsive number",
          },
          policyPaymentForm: {
            text: policy.pago_fraccionado,
            type: "",
            link: "",
            key: "responsive",
          },
          controlid: {
            text: (policy.controlid) ? policy.controlid : '',
            type: "",
            link: "",
            key: "responsive number",
          },
          codPolizaUni: {
            text: (policy.cod_poliza_uni) ? policy.cod_poliza_uni : '',
            type: "",
            link: "",
            key: "responsive number",
          },
          codPoliza: {
            text: (policy.cod_poliza) ? policy.cod_poliza : '',
            type: "",
            link: "",
            key: "responsive number",
          }
        }
        policyDataRows.push(row);
      });
      this.setState({ policyDataRows: policyDataRows, policyOrigin: deepGet(detailPolicy, 'policyDetail[0].origen_poliza') ? detailPolicy.policyDetail[0].origen_poliza : '' });
    }
  }

  getPolicyDocuments = async () => {
    const { policyOrigin, policyDataRows } = this.state;
    if (policyOrigin === 'SENDA' && policyDataRows[0].policyRamo.text === 'ASISA SALUD' ) await this.downloadPolicyDocumentsFromGestDoc();
    else {
      let documentPolicy: any;
      if (policyOrigin === 'SENDA') documentPolicy = await this.getDocumentFromSenda();
      else if (policyOrigin === 'GIP') {
        const response = await this.getDocumentFromCmod()
        documentPolicy = response.document
      }
      if (documentPolicy !== undefined && documentPolicy.length > 0) {
        const type = 'application/pdf';
        const file_object = convertBase64ToFile(documentPolicy, type);
        const fileURL = URL.createObjectURL(file_object);
        window.open(fileURL);
      }
      else this.setState({ serverError: true });
    }
  }

  async downloadPolicyDocumentsFromGestDoc() {
    try {
      const { policy } = this.state;
      const queryParticularConditions = {
        "SITE": "clientes",
        "ins2:documentType":"Condiciones Particulares",
        "ins2:policyId": policy
      };
      const file = await GestDocServices.searchAndGetLastFile(this.props.setLoading, true, { fileInfo: queryParticularConditions });
      
      if (file && file.fileData && file.fileName && file.fileType) downloadFile(file.fileData, file.fileName, file.fileType, true);
      else this.setState({ serverError: true });

    } catch (error) {
      this.setState({ serverError: true });
    }
  }

  getDocumentFromSenda = async () => {
    const { policyDataRows } = this.state;
    const { setLoading } = this.props;
    console.log("policyDataRows", policyDataRows)

    const data = {
      businessLine: policyDataRows[0].policyRamo.text,
      requestNumber: policyDataRows[0].policyNumber.text,
      effectDate: policyDataRows[0].policyInitialDate.text,
    }

    return await PoliciesServices.getPolicyDocuments({
      loader: true,
      setLoading: setLoading,
      data: data,
      url: 'documents/documentSenda'
    }).catch(() => {
      this.setState({ serverError: true });
    });
  }

  getDocumentFromCmod = async () => {
    const { policyDataRows } = this.state;
    const { setLoading } = this.props;
    console.log("policyDataRows", policyDataRows)
    const data = {
      policyId: policyDataRows[0].policyNumber.text,
      order: policyDataRows[0].order.text,
      controlId: policyDataRows[0].controlid.text,
      codPoliza: policyDataRows[0].codPoliza.text,

    }
    try {
      const respuesta = await PoliciesServices.getPolicyDocuments({
        loader: true,
        setLoading: setLoading,
        data: data,
        url: 'Cmod/document'
      })
      return respuesta;

    } catch (e) {
      this.setState({ serverError: true });
    };
  }

  getTaker = async () => {
    const { nifTomador, mediatorCode } = this.state;
    const { setLoading } = this.props;

    const data: IPolicyTakerDetail = {
      codMediador: mediatorCode,
      nifTomador: nifTomador,
    }

    const takerDetail = await PoliciesServices.searchTakerDetail(
      setLoading,
      true,
      data,
    ).catch(() => {
      this.setState({ serverError: true });
    });

    if (takerDetail !== undefined) {
      const takerDataRows: IPolicyTakerDataRow[] = [];
      takerDetail.takerDetail.forEach((taker: TakerDetailDataDto) => {
        const row: IPolicyTakerDataRow = {
          policyNIF: {
            text: taker.nif_tomador,
            type: "",
            link: "",
            key: "responsive number",
          },
          birth_date: {
            text: taker.fec_nacimiento_tomador ? new Date(taker.fec_nacimiento_tomador).toLocaleDateString() : "",
            type: "",
            link: "",
            key: "responsive number",
          },
          genre: {
            text: taker.sexo,
            type: "",
            link: "",
            key: "responsive",
          },
          civil_state: {
            text: taker.des_estado_civil,
            type: "",
            link: "",
            key: "responsive",
          },
          direction: {
            text: taker.direccion_tomador,
            type: "",
            link: "",
            key: "responsive",
          },
          phone: {
            text: taker.telefono,
            type: "",
            link: "",
            key: "responsive number",
          },
          mail: {
            text: taker.email,
            type: "",
            link: "",
            key: "responsive",
          },
          takerName: {
            text: taker.nom_tomador,
            type: "",
            link: "",
            key: "responsive",
          }
        }
        takerDataRows.push(row);
      });

      this.setState({ takerDataRows: takerDataRows });
    }
  }

  getRisk = async () => {
    const { policy, searchType, policyDataRows, riskParams, mediatorCode } = this.state;
    const { setLoading } = this.props;
    const queryParams = [
      `skip=${riskParams.showItems * (riskParams.page - 1)}`,
      `take=${riskParams.showItems}`,
    ];

    if (riskParams.order) {
      queryParams.push(`order=${riskParams.ascendent ? '' : '-'}${riskParams.order}`);
    }

    const data: IPolicyRiskDetail = {
      ramo: policyDataRows[0].policyRamo.text,
      codMediador: mediatorCode,
      searchType: searchType,
      data: policy,
    }

    const riskDetail = await PoliciesServices.searchRiskDetail(
      setLoading,
      true,
      queryParams,
      data,
    ).catch(() => {
      this.setState({ serverError: true });
    });

    if (riskDetail !== undefined && riskDetail.count !== 0) {
      if (riskDetail.riskDetail.personList) {
        const personDataRows: IPolicyRiskDataRow[] = [];
        riskDetail.riskDetail.personList.forEach((person: RiskDetailPersonDataDto) => {
          const row: IPolicyRiskDataRow = {
            fec_efecto_alta: {
              text: person.fec_efecto_alta ? new Date(person.fec_efecto_alta).toLocaleDateString() : "",
              type: "",
              link: "",
              key: "responsive number",
            },
            fec_efecto_baja: {
              text: person.fec_efecto_baja ? new Date(person.fec_efecto_baja).toLocaleDateString() : "",
              type: "",
              link: "",
              key: "responsive number",
            },
            nom_asegurado: {
              text: person.nom_asegurado,
              type: "",
              link: "",
              key: "responsive",
            },
            nif_asegurado: {
              text: person.nif_asegurado,
              type: "",
              link: "",
              key: "responsive number",
            },
            fec_nacimiento: {
              text: person.fec_nacimiento ? new Date(person.fec_nacimiento).toLocaleDateString() : "",
              type: "",
              link: "",
              key: "responsive number",
            },
            sexo: {
              text: person.sexo,
              type: "",
              link: "",
              key: "responsive",
            },
            direccion: {
              text: person.direccion,
              type: "",
              link: "",
              key: "responsive",
            },
          }
          personDataRows.push(row);
        });

        const pages = Math.ceil(riskDetail.count / riskParams.showItems);
        riskParams.totalPages = pages;
        this.setState({ personDataRows: personDataRows, riskParams });
      }

      if (riskDetail.riskDetail.petList) {
        const petDataRows: any[] = [];
        riskDetail.riskDetail.petList.forEach((pet: RiskDetailPetDataDto) => {
          const row = {
            fec_efecto_alta: {
              text: pet.fec_efecto_alta ? new Date(pet.fec_efecto_alta).toLocaleDateString() : "",
              type: "",
              link: "",
              key: "responsive number",
            },
            fec_efecto_baja: {
              text: pet.fec_efecto_baja ? new Date(pet.fec_efecto_baja).toLocaleDateString() : "",
              type: "",
              link: "",
              key: "responsive number",
            },
            nom_mascota: {
              text: pet.nom_mascota,
              type: "",
              link: "",
              key: "responsive",
            },
            nom_propietario: {
              text: pet.nom_propietario,
              type: "",
              link: "",
              key: "responsive",
            },
            chip: {
              text: pet.chip,
              type: "",
              link: "",
              key: "responsive number",
            },
            fec_nacimiento: {
              text: pet.fec_nacimiento ? new Date(pet.fec_nacimiento).toLocaleDateString() : "",
              type: "",
              link: "",
              key: "responsive number",
            },
            tipo_mascota: {
              text: pet.tipo_mascota,
              type: "",
              link: "",
              key: "responsive",
            }
          }
          petDataRows.push(row);
        });

        const pages = Math.ceil(riskDetail.count / riskParams.showItems);
        riskParams.totalPages = pages;
        this.setState({ petDataRows: petDataRows, riskParams });
      }
    }
    else {
      if (this.state.serverError !== true) {
        this.setState({ noDataMessage: ERROR_MESSAGES["no-data"] });
      }
    }
  }

  getReceipts = async (newSearch: boolean) => {
    let { policy, colective, receiptsParams, mediatorCode } = this.state;
    const { setLoading } = this.props;

    if (newSearch) {
      this.setState({ receiptDataRows: [] });
      receiptsParams = {
        order: 'fec_expedicion',
        ascendent: false,
        page: 1,
        showItems: 6,
        totalPages: 0,
      }
    }

    const queryParams = [
      `skip=${receiptsParams.showItems * (receiptsParams.page - 1)}`,
      `take=${receiptsParams.showItems}`,
    ];

    if (receiptsParams.order) {
      queryParams.push(`order=${receiptsParams.ascendent ? '' : '-'}${receiptsParams.order}`);
    }

    const data: IPolicyReceiptsDetail = {
      codMediador: mediatorCode,
      colectivo: colective,
      poliza: policy,
    }

    const resReceipts = await PoliciesServices.searchReceiptDetail(
      setLoading,
      true,
      queryParams,
      data,
    ).catch(() => {
      this.setState({ serverError: true });
    });

    if (resReceipts !== undefined && resReceipts.count !== 0) {
      const receiptDataRows: IPolicyReceiptDataRow[] = [];
      resReceipts.receiptDetail.forEach((receipt: ReceiptDetailDataDto) => {
        const row: IPolicyReceiptDataRow = {
          numero_recibo: {
            text: receipt.numero_recibo,
            type: "",
            link: "",
            key: "responsive number",
          },
          estado_recibo: {
            text: receipt.estado_recibo,
            type: "",
            link: "",
            key: "responsive",
          },
          colectivo: {
            text: receipt.colectivo,
            type: "",
            link: "",
            key: "responsive",
          },
          fec_vencimiento_poliza: {
            text: receipt.fec_cobertur_hasta ? new Date(receipt.fec_cobertur_hasta).toLocaleDateString() : "",
            type: "",
            link: "",
            key: "responsive number",
          },
          fec_expedicion: {
            text: receipt.fec_expedicion ? new Date(receipt.fec_expedicion).toLocaleDateString() : "",
            type: "",
            link: "",
            key: "responsive number",
          },
          fec_envio_cobro: {
            text: receipt.fec_expedicion ? new Date(receipt.fec_expedicion).toLocaleDateString() : "",
            type: "",
            link: "",
            key: "responsive number",
          },
          fec_efecto: {
            text: receipt.fec_efecto ? new Date(receipt.fec_efecto).toLocaleDateString() : "",
            type: "",
            link: "",
            key: "responsive number",
          },
          imp_importe_total: {
            text: (receipt.imp_importe_total.length > 0 ? formatter.format(Number(receipt.imp_importe_total)) : ''),
            type: "",
            link: "",
            key: "responsive",
          },
        }
        receiptDataRows.push(row);
      });

      const pages = Math.ceil(resReceipts.count / receiptsParams.showItems);
      receiptsParams.totalPages = pages;
      this.setState({ receiptDataRows: receiptDataRows, receiptsParams });
    }
    else {
      if (this.state.serverError !== true) {
        this.setState({ noDataMessage: ERROR_MESSAGES["no-data"] });
      }
    }
  }

  generateSuplements = async () => {
    await this.getTaker();
    const { colective, policyDataRows, takerDataRows, policyOrigin } = this.state;
    const petitionary = getUserCode();
    const effectDate: string = policyDataRows.length > 0 && policyDataRows[0].policyInitialDate.text ? policyDataRows[0].policyInitialDate.text : '';
    const effectDateFormated = dateFns.format('', "DD/MM/YYYY", { locale: 'es' });
    const completeProduct = deepGet(this.props, 'location.state.data.colectivo.text', '') || ''; // EJ: '24300|PARTICULARES'
    const completProductArray = completeProduct.split('|');
    const policyData = {
      idPeticionario: petitionary,
      takerEmail: takerDataRows.length > 0 && takerDataRows[0].mail.text ? takerDataRows[0].mail.text : '',
      numeroColectivo: colective,
      ramo: policyDataRows.length > 0 && policyDataRows[0].policyRamo.text ? policyDataRows[0].policyRamo.text : '',
      producto: completProductArray.length === 2 ? completProductArray[1] : '',
      tomador: takerDataRows.length > 0 && takerDataRows[0].takerName.text ? takerDataRows[0].takerName.text : '',
      numeroPoliza: policyDataRows.length > 0 && policyDataRows[0].policyNumber.text ? policyDataRows[0].policyNumber.text : '',
      fechaEfecto: effectDateFormated !== 'Invalid Date' ? effectDateFormated : effectDate,
      policyOrigin,
      nifTomador: this.state.nifTomador,
      order: policyDataRows.length > 0 && policyDataRows[0].order.text ? policyDataRows[0].order.text : '',
      paymentType: policyDataRows.length > 0 && policyDataRows[0].policyPaymentForm.text ? policyDataRows[0].policyPaymentForm.text : '',
      mediator: this.props.match.params.mediator,
    }
    localStorage.setItem('policyRamo', policyDataRows.length > 0 && policyDataRows[0].policyRamo.text ? policyDataRows[0].policyRamo.text : '');
    localStorage.setItem('policyData', JSON.stringify(policyData));
    localStorage.setItem('policyOrigin', policyOrigin);
    this.props.history.push(`/suplementos/${this.state.policy}`);
  }

  render() {
    const { forbidden, serverError, currentTab, colective } = this.state;
    const { allProducts } = this.props;
    const noSupplementsList = [
      deepGet(allProducts, "healthStudents.products_colectivo", ""),
      deepGet(allProducts, "healthResidents.products_colectivo", ""),
    ];

    const hasSupplements = !noSupplementsList.includes(colective);

    /* PREVENT FORBIDDEN ACCESS*/
    if (forbidden) {
      return <Redirect to="/403" />
    }

    return (
      <DetailClientsPoliciesContainer>
        <div className="detail-clients-policies-back">
          <GoBack text="Volver" onClick={() => this.props.history.push('/polizas-clientes')} />
        </div>

        <ErrorModalContainer
          className={`${serverError && `active`}`}
          active={serverError}>
          <div className="recover-modal">
            <div className="server-error">
              <p>Se ha producido un error. Inténtelo más tarde.</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={() => this.setState({ serverError: false })} />
            </div>

          </div>
        </ErrorModalContainer>

        {<div className="tab-container">
          <div className="tabs">
            <ul className="col-12 padding0">
              <li className={`col-2 ${(currentTab === 'datos-poliza') ? `tab-selected` : `tab-not-selected`}`}
                data-id="datos-poliza" onClick={this.changeTab.bind(this)}>Datos póliza</li>
              <li className={`col-2 ${(currentTab === 'datos-tomador') ? `tab-selected` : `tab-not-selected`}`}
                data-id="datos-tomador" onClick={this.changeTab.bind(this)}>Datos tomador</li>
              <li className={`col-2 ${(currentTab === 'datos-riesgo') ? `tab-selected` : `tab-not-selected`}`}
                data-id="datos-riesgo" onClick={this.changeTab.bind(this)}>Datos de riesgo</li>
              <li className={`col-2 ${(currentTab === 'recibos') ? `tab-selected` : `tab-not-selected`}`}
                data-id="recibos" onClick={this.changeTab.bind(this)}>Recibos</li>
            </ul>
          </div>
        </div>
        }
        {(currentTab !== undefined) ? this.handleTab() : ''}

        {hasSupplements && (
          <div className="floatButton">
            <Button onClick={() => this.generateSuplements()} text={'Generar suplemento'}/>
          </div>
        )}
        

      </DetailClientsPoliciesContainer>
    );
  }

  handleTab() {
    const { currentTab, policyDataRows, takerDataRows, receiptDataRows, receiptsParams, riskParams, noDataMessage } = this.state;
    let element;

    switch (currentTab) {
      case 'datos-poliza':
        element = (
          <div className="detail-clients-policies-item col-12">
            <div className="colSin-35 padding10">
              <div className="bgWhite">
                <div className="inputBox filtersMargin">
                  <input value={policyDataRows.length > 0 && policyDataRows[0].policyMediator.text ? policyDataRows[0].policyMediator.text : ''} type="text" readOnly />
                  <label>MEDIADOR</label>
                </div>
                <div className="inputBox filtersMargin">
                  <input value={policyDataRows.length > 0 && policyDataRows[0].policyNumber.text ? policyDataRows[0].policyNumber.text : ''} type="text" readOnly />
                  <label>Nº DE PÓLIZA</label>
                </div>
                <div className="inputBox filtersMargin">
                  <input value={policyDataRows.length > 0 && policyDataRows[0].policyGrupoNegocio.text ? policyDataRows[0].policyGrupoNegocio.text : ''} type="text" readOnly />
                  <label>LINEA/GRUPO DE NEGOCIO</label>
                </div>
                <div className="inputBox filtersMargin">
                  <input value={policyDataRows.length > 0 && policyDataRows[0].policyColective.text ? policyDataRows[0].policyColective.text : ''} type="text" readOnly />
                  <label>COLECTIVO</label>
                </div>
              </div>
            </div>
            <div className="colSin-35 padding10">
              <div className="bgWhite height335">
                <div className="colSin-50 rightPadding10">
                  <div className="inputBox filtersMargin">
                    <input value={policyDataRows.length > 0 && policyDataRows[0].status.text ? policyDataRows[0].status.text : ''} type="text" readOnly />
                    <label>ESTADO</label>
                  </div>
                </div>
                <div className="colSin-50">
                  <div className="inputBox filtersMargin">
                    <input value={policyDataRows.length > 0 && policyDataRows[0].policyReleaseDate.text ? policyDataRows[0].policyReleaseDate.text : ''} type="text" readOnly />
                    <label>FECHA EMISIÓN</label>
                  </div>
                </div>
                <div className="colSin-50 rightPadding10">
                  <div className="inputBox filtersMargin">
                    <input value={policyDataRows.length > 0 && policyDataRows[0].policyInitialDate.text ? policyDataRows[0].policyInitialDate.text : ''} type="text" readOnly />
                    <label>FECHA EFECTO</label>
                  </div>
                </div>
                <div className="colSin-50">
                  <div className="inputBox filtersMargin">
                    <input value={policyDataRows.length > 0 && policyDataRows[0].policyFinishDate.text ? policyDataRows[0].policyFinishDate.text : ''} type="text" readOnly />
                    <label>FECHA VENCIMIENTO</label>
                  </div>
                </div>
                <div className="colSin-50 rightPadding10">
                  <div className="inputBox filtersMargin">
                    <input value={policyDataRows.length > 0 && policyDataRows[0].policyPaymentForm.text ? policyDataRows[0].policyPaymentForm.text : ''} type="text" readOnly />
                    <label>FORMA DE PAGO</label>
                  </div>
                </div>
              </div>
            </div>       
                <div className="colSin-30 padding10">
                  <div className="bgWhite height275 topPadding25">
                    <label>CONDICIONES PARTICULARES</label>
                    <div onClick={() => this.getPolicyDocuments()} className="button downloadFile horizontal topMargin5">VER DOCUMENTACIÓN</div>
                  </div>
            </div>                        
          </div>
        );
        break;

      case 'datos-tomador':
        element = (
          <div className="detail-clients-policies-item col-12">

            <div className="colSin-40 padding10">
              <div className="bgWhite height405">
                <div className="inputBox filtersMargin">
                  <input value={takerDataRows.length > 0 && takerDataRows[0].takerName.text ? takerDataRows[0].takerName.text : ''} type="text" readOnly />
                  <label>NOMBRE DEL TOMADOR</label>
                </div>
                <div className="inputBox filtersMargin">
                  <input value={takerDataRows.length > 0 && takerDataRows[0].policyNIF.text ? takerDataRows[0].policyNIF.text : ''} type="text" readOnly />
                  <label>DNI/NIE</label>
                </div>
                <div className="inputBox filtersMargin">
                  <input value={takerDataRows.length > 0 && takerDataRows[0].genre.text ? takerDataRows[0].genre.text : ''} type="text" readOnly />
                  <label>SEXO</label>
                </div>
                <div className="inputBox filtersMargin">
                  <input value={takerDataRows.length > 0 && takerDataRows[0].civil_state.text ? takerDataRows[0].civil_state.text : ''
                  } type="text" readOnly />
                  <label>ESTADO CIVIL</label>
                </div>
              </div>
            </div>

            <div className="colSin-40 padding10">
              <div className="bgWhite height355">
                <div className="inputBox filtersMargin">
                  <input value={takerDataRows.length > 0 && takerDataRows[0].direction.text ? takerDataRows[0].direction.text : ''} type="text" readOnly />
                  <label>DIRECCIÓN COMPLETA</label>
                </div>
                <div className="inputBox filtersMargin">
                  <input value={takerDataRows.length > 0 && takerDataRows[0].phone.text ? takerDataRows[0].phone.text : ''} type="text" readOnly />
                  <label>TELÉFONO</label>
                </div>
                <div className="inputBox filtersMargin">
                  <input value={takerDataRows.length > 0 && takerDataRows[0].mail.text ? takerDataRows[0].mail.text : ''} type="text" readOnly />
                  <label>EMAIL</label>
                </div>
              </div>
            </div>
          </div>);
        break;

      case 'datos-riesgo':
        element = (
          <div className="detail-clients-policies-item col-12">


            {(this.state.personDataRows && this.state.personDataRows.length > 0) ? this.checkPersons(riskParams) : this.checkPets(riskParams)}
            {this.state.personDataRows.length === 0 && noDataMessage}

          </div>);
        break;

      case 'recibos':
        element = (
          <div className="detail-clients-policies-item col-12">

            {receiptDataRows.length === 0 ? noDataMessage :
              (<>
                <div className="detail-clients-policies-item-content-table">
                  <MainTable
                    className="table topMargin15"
                    columns={receiptDataColumns}
                    rows={receiptDataRows}
                    keysRow={["numero_recibo", "estado_recibo", "colectivo", "fec_vencimiento_poliza", "fec_expedicion", "fec_envio_cobro", "fec_efecto", "imp_importe_total"]}
                    onOrder={(column: string, ascendent: boolean | undefined) => this.handleReceiptOrder(column, ascendent)}
                    defaultOrder={{ 'fec_expedicion': false }}
                    keysAlignRight={["imp_importe_total"]}
                  />
                </div>
                <div className="pendant-simulation-pagination">
                  <Pagination page={receiptsParams.page} limit={receiptsParams.totalPages} callback={(page) => this.setReceiptPage(page)} />
                </div>
              </>)}
          </div>);
        break;
      default: break;
    }

    return element;
  }

  setReceiptPage = (page: number) => {
    const { receiptsParams } = this.state;
    receiptsParams.page = page;
    this.setState({ receiptsParams }, () => this.getReceipts(false));
  };

  setRiskPage = (page: number) => {
    const { riskParams } = this.state;
    riskParams.page = page;
    this.setState({ riskParams }, () => this.getRisk());
  };

  handleReceiptOrder = (column: string, ascendent: boolean | undefined) => {
    const { receiptsParams } = this.state;
    receiptsParams.order = column;
    receiptsParams.ascendent = ascendent;
    this.setState({ receiptsParams }, () => this.getReceipts(false))
  };

  handleRiskOrder = (column: string, ascendent: boolean | undefined) => {
    const { riskParams } = this.state;
    riskParams.order = column;
    riskParams.ascendent = ascendent;
    this.setState({ riskParams }, () => this.getRisk())
  };

  changeTab(event: any): void {
    let { currentTab, noDataMessage } = this.state;

    switch (event.currentTarget.dataset.id) {
      case 'datos-poliza':
        if (this.state.currentTab !== event.currentTarget.dataset.id) {
          this.state.policyDataRows.length = 0;
          this.getPolicy()
          currentTab = 'datos-poliza';
        }
        break;

      case 'datos-tomador':
        if (this.state.currentTab !== event.currentTarget.dataset.id) {
          this.state.takerDataRows.length = 0;
          this.getTaker()
          currentTab = 'datos-tomador';
        }
        break;

      case 'datos-riesgo':
        if (this.state.currentTab !== event.currentTarget.dataset.id) {
          this.state.personDataRows.length = 0;
          this.state.petDataRows.length = 0;
          this.state.riskParams.page = 1;
          noDataMessage = '';
          this.getRisk()
          currentTab = 'datos-riesgo';
        }
        break;

      case 'recibos':
        if (this.state.currentTab !== event.currentTarget.dataset.id) {
          this.state.receiptDataRows.length = 0;
          this.state.receiptsParams.page = 1;
          noDataMessage = '';
          this.getReceipts(true)
          currentTab = 'recibos';
        }
        break;
    }

    this.setState({ currentTab, noDataMessage });
  }

  checkPersons(riskParams: ITableConfig) {
    return <div className="data-taker-container">
      <div className="data-taker-name">
        <h6>PERSONAS</h6>
      </div>
      <div className="detail-clients-policies-item-content-table">
        <MainTable
          className="table"
          columns={personDataColumns}
          rows={this.state.personDataRows}
          keysRow={["fec_efecto_alta", "fec_efecto_baja", "nom_asegurado", "nif_asegurado", "fec_nacimiento", "sexo", "direccion"]}
          onOrder={(column: string, ascendent: boolean | undefined) => this.handleRiskOrder(column, ascendent)}
        />
      </div>
      <div className="pendant-simulation-pagination">
        <Pagination page={riskParams.page} limit={riskParams.totalPages} callback={(page) => this.setReceiptPage(page)} />
      </div>
    </div>
  }

  checkPets(riskParams: ITableConfig) {
    if (this.state.petDataRows && this.state.petDataRows.length > 0)
      return <div className="data-taker-container">
        <div className="data-taker-name">
          <h6>MASCOTAS</h6>
        </div>
        <div className="detail-clients-policies-item-content-table">
          <MainTable
            className="table"
            columns={petDataColumns}
            rows={this.state.petDataRows}
            keysRow={["fec_efecto_alta", "fec_efecto_baja", "nom_mascota", "nom_propietario", "chip", "fec_nacimiento", "tipo_mascota"]}
            onOrder={(column: string, ascendent: boolean | undefined) => this.handleRiskOrder(column, ascendent)}
          />
        </div>
        <div className="pendant-simulation-pagination">
          <Pagination page={riskParams.page} limit={riskParams.totalPages} callback={(page) => this.setReceiptPage(page)} />
        </div>
      </div>
  }
}

export default withGeneral(withDynamicProducts(withRouter(DetailClientsPoliciesLayout)));
