import styled from "styled-components";
import { colors } from "../../../../../assets"
import { MainButtonContainer } from "../../../../../components/main-button/main-button-style";
import { DicotomicCheckboxContainer } from "../../../../../components/dicotomic-checkbox/dicotomic-checkbox-style";

export const TakerDataContainer = styled.div`
.data-flow-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .data-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 24px;
  }

  .data-subtitle {
    color: ${colors["TX-001-80"]};
    margin-bottom: 50px;
  }

  .content-wrapper {
    overflow: initial;
    padding-right: 16px;
    background-color: #fbfbfb;
    clear: both;
    height: auto;
  }

  .personal-data-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0;
  }

  .personal-data-row {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }

  .personal-data-item {
    margin-right: 40px;

    > ${MainButtonContainer} {
      max-width: 160px;
      margin-top: 28px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .col-25 {
    width: 25%;
  }

  .col-33 {
    width: 33%;
  }

  .col-50 {
    width: 50%;
  }

  .col-75 {
    width: 75%;
  }

  .col-100 {
    width: 100%;
  }

  .personal-checkbox-title {
    margin-bottom: 26px;
    color: ${colors["PR-001-100"]};
    text-transform: uppercase;
    height: 25px;
    width: 100%;
    display: block;
    float: left;
    &.with-description {
      margin-bottom: 10px;
    }
  }

  .personal-checkbox-container {
    margin-bottom: 80px;
  }

  .personal-checkbox-row {
    display: block;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .personal-checkbox-item {
    color: ${colors["TX-001-80"]};
    float: left;
    padding-right: 30px;
    margin-bottom: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  .personal-checkbox-subitem {
    margin-bottom: 45px;
    ${DicotomicCheckboxContainer} {
      min-height: 45px;
    }
    &:last-child {
      margin-bottom: 0;
    }

    .checkbox-container {
      p {
        height: 40px;
        white-space: break-spaces;
        line-height: 22px;
      }
    }
  }

  .personal-checkbox-description {
    margin-bottom: 32px;
    color: ${colors["TX-001-80"]};
  }

  .personal-sub-header {
    font-family: "museo sans";
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 18px;
  }

  .personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  .death-services-block {
    font-family: "museo sans";
  }

  .personal-data-death-services-title {
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #80a7c5;
  }

  .personal-data-death-services-item {
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase;
    list-style-type: circle;
    color: #004f8b;
  }

  .personal-data-death-services-item-container {
    list-style: inside;
    margin-left: 10px;
  }
  .receip-radio-main-row {
    margin-bottom: 20px;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
    font-family: "museo sans";
`;
