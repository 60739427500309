import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { InsuredMembersContainer } from "./insured-members-style";
import MainTable from "../../../components/main-table";
import { PolicyContainer } from "./policy-style";
import GoBack from "../../../components/go-back";
import HeadsTitleText from "../../../components/components-text/heads-title-text";
import GuideLine from "../../../components/guide-line";
import { images } from "../../../assets";
import MainButton from "../../../components/main-button";
import { InflowSidebarContainer } from "../../../components/inflow-sidebar/inflow-sidebar-style";
import { withPolicy } from "../../../context/mixin/with-policy";
import Body02Text from "../../../components/components-text/body-02-text";
import { isValidStatusToEnableButton, VALID_STATUS_TYPE } from "../../../constants/hiring";
import { withDynamicProducts } from "../../../context/mixin/with-dynamic-products";
import { AREA_NAMES, getProductTypeFromURL } from "../../../constants/policyType";
import { withPayTypes } from "../../../context/mixin/with-pay-types";
import PayTypesServices from "../../../services/PayTypesServices"
interface InsuredMembersProps extends RouteComponentProps {
  data: any;
  removeInsured: Function;
  checkInsuredsForBeneficiary: any;
  linkDetail: string;
  steps: number[];
  addNewInsured: Function;
  action: Function;
  actionSave?: Function;
  positionStep?: number;
  hiring?: boolean;
  policy: any;
  downloadDocs: () => void;
  sendSign: () => void;
  generateInsuranceProposal: () => void;
  generateInsuranceRequest: () => void;
  actionCancel?: Function;
  checkProductArea: Function;
  setProductsData: () => void;
  disableDownloadDocs?: boolean;
  disableSendSign?: boolean;
  productsByAreaName: any;
  allPayTypes: any;
}

interface InsuredMembersState {
  insuredRows: any[];
  steps: any[];
  takerRows: any[];
  beneficiaryRows?: any[];
  simulation: boolean;
  isReadOnly: boolean;
}

class InsuredMembersLayout extends React.Component<
  InsuredMembersProps,
  InsuredMembersState
> {
  readonly state: InsuredMembersState;

  constructor(props: InsuredMembersProps) {
    super(props);
    this.state = {
      insuredRows: [],
      takerRows: [],
      beneficiaryRows: [],
      steps: props.steps,
      simulation: !(!!props.hiring),
      isReadOnly: props.policy.data.isReadOnly
    };
  }

  componentDidMount =  async () => {
    const { policy, productsByAreaName, data, checkInsuredsForBeneficiary, hiring, checkProductArea } = this.props 
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)

    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);

    try {
      if(Object.keys(policy.product).length!==0 && Object.keys(productsByAreaName).length!==0) {
        this.checkInsureds()
        this.setProductsData()
        if(hiring && isPetArea) checkInsuredsForBeneficiary();
        if(data.beneficiary && Object.entries(data.beneficiary).length>0){
          this.setState({takerRows: this.getTakerRows(), insuredRows: this.getInsuredRows(), beneficiaryRows: this.getBeneficiaryRows()})
        }
        else{
          this.setState({takerRows: this.getTakerRows(), insuredRows: this.getInsuredRows()})  
        }
      }
    } catch (error) {
      console.log(error);               
    }
  };

  async componentDidUpdate(prevProps: InsuredMembersProps) {
    const { checkProductArea, data, policy, productsByAreaName } = this.props;
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)

    
    if( (Object.keys(prevProps.policy.product).length===0 && Object.keys(policy.product).length!==0 && Object.keys(productsByAreaName).length!==0) || 
    (Object.keys(prevProps.productsByAreaName).length===0 && Object.keys(productsByAreaName).length!==0 && Object.keys(policy.product).length!==0)) {
      this.checkInsureds()
      this.setProductsData()
      if(data.beneficiary && Object.entries(data.beneficiary).length>0){
        this.setState({takerRows: this.getTakerRows(), insuredRows: this.getInsuredRows(), beneficiaryRows: this.getBeneficiaryRows()})
      }
      else{
        this.setState({takerRows: this.getTakerRows(), insuredRows: this.getInsuredRows()})  
      }    }

    if (
      data.insureds.length !== 0 &&
      typeof data.insureds !== 'string' &&
      typeof data.insureds !== 'number' && (
        prevProps.data.insureds.length !== this.state.insuredRows.length ||
        prevProps.data !== data)
    ) {
      if(isPetArea){
        this.setState({ insuredRows: this.getInsuredRows(), beneficiaryRows: this.getBeneficiaryRows() });
      }
      else{
        this.setState({ insuredRows: this.getInsuredRows() });
      }
    }
  }

  checkInsureds() {
    const { checkProductArea, policy, data, history} = this.props 
    const { simulation } = this.state
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)

    if ( (!(simulation && isPetArea) && data.insureds.length === 0) || (simulation && isPetArea && Object.keys(data.taker).length === 0 )){
      history.push(
        `/${simulation
          ? "simulacion"
          : "contratacion"
        }/nueva`
      );
    }  
  }

  setProductsData(){
    const { policy, checkProductArea, setProductsData } = this.props;
    const { simulation } = this.state;

    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
    
    if(!simulation && (isDeathArea || isPetArea)){
      setProductsData()
    }
  }

  getTakerRows() {
    const { data, linkDetail, policy } = this.props;
    const { simulation } = this.state;
    const taker = data.taker;
    const baseUrl = simulation ? policy.product.products_linkSimulacion : policy.product.products_link

    return [
      {
        taker: {
          text: `${taker.name} ${taker.lastname1} ${taker.lastname2}`,
          type: "",
          key: "highlighted"
        },
        docNumber: {
          text: taker.docNumber,
          type: "",
          key: "body"
        },
        email: {
          text: taker.email,
          type: "",
          key: "body",
        },
        buttons: {
          key: "buttons",
          items: [
            {
              img: images.IconDetailLightBlue,
              link: linkDetail + getProductTypeFromURL(baseUrl) + "/tomador"
            }
          ]
        }
      }
    ]
  }

  takerTableColumns = [
    {
      text: "NOMBRE DEL TOMADOR",
      filter: false,
      ascendent: true
    },
    {
      text: "Nº de Documento",
      filter: false,
      ascendent: false
    },
    {
      text: "Email",
      filter: false,
      ascendent: false
    },
    {
      text: "",
      filter: false,
      ascendent: true
    }
  ];

  keysTakerRows = ["taker", "docNumber", "email", "buttons"];

  getBeneficiaryRows() {
    const { data, linkDetail, policy } = this.props;
    const { simulation } = this.state;
    const beneficiary = data.beneficiary;
    const baseUrl = simulation ? policy.product.products_linkSimulacion : policy.product.products_link
    if(data.beneficiary){
      return [
        {
          beneficiary: {
            text: `${beneficiary.name} ${beneficiary.lastname1} ${beneficiary.lastname2}`,
            type: "",
            key: "highlighted"
          },
          docNumber: {
            text: beneficiary.docNumber,
            type: "",
            key: "body"
          },
          email: {
            text: beneficiary.email,
            type: "",
            key: "body",
          },
          buttons: {
            key: "buttons",
            items: [
              {
                img: images.IconDetailLightBlue,
                link: linkDetail + getProductTypeFromURL(baseUrl) + "/asegurado-beneficiario"
              }
            ]
          }
        }
      ]
    }
    else{
      return;
    }
  }

  beneficiaryTableColumns = [
    {
      text: "NOMBRE DEL ASEGURADO",
      filter: false,
      ascendent: true
    },
    {
      text: "Nº de Documento",
      filter: false,
      ascendent: false
    },
    {
      text: "Email",
      filter: false,
      ascendent: false
    },
    {
      text: "",
      filter: false,
      ascendent: true
    }
  ];

  keysBeneficiaryRows = ["beneficiary", "docNumber", "email", "buttons"];

  getInsuredRows() {
    const { policy, checkProductArea, data, linkDetail, removeInsured, allPayTypes } = this.props;
    const { simulation } = this.state;
    
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, policy.product.products_internalName)
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
    const taker = data.taker;
    const baseUrl = simulation ? policy.product.products_linkSimulacion : policy.product.products_link 

    if (
      isPetArea &&
      taker.numPets &&
      simulation
    ) {
      return [
        {
          taker: {
            text: `${taker.name} ${taker.lastname1} ${taker.lastname2}`,
            type: "",
            key: "highlighted"
          },
          numPets: {
            text: taker.numPets,
            type: "",
            key: ""
          },
          startDate: {
            text: taker.starterDay,
            type: "",
            key: ""
          },
          paymentType: {
            text: PayTypesServices.getPayTypeByCode(allPayTypes, taker.payment).description,
            type: "",
            key: ""
          },
          buttons: {
            key: "buttons",
            items: [
              {
                img: images.IconDetailLightBlue,
                link: linkDetail + getProductTypeFromURL(baseUrl) + "/tomador"
              }
            ]
          }
        }
      ];
    } else if (isPetArea && simulation) return [];

    let rows: any = [];

    /* //! Tomador eliminado de la tabla.
    rows.push({
      insured: {
        text: 'Tomador',
        type: "",
        key: "highlighted"
      },
      name: {
        text: `${taker.name} ${taker.lastname1 ? taker.lastname1 : ''} ${taker.lastname2 ? taker.lastname2 : ''}`,
        type: "",
        key: "body"
      },
      buttons: {
        key: "buttons",
        items: [
          {
            img: images.IconDetailLightBlue,
            link: linkDetail + getProductTypeFromURL(baseUrl) + "/tomador"
          }
        ]
      }
    })*/

    rows = rows.concat(data.insureds.map((insured: any, index: number) => ({
      insured: {
        text: `Asegurado ${index + 1}`,
        type: "",
        key: "highlighted"
      },
      name: {
        text: `${insured.name} ${insured.lastname1 ? insured.lastname1 : ''} ${insured.lastname2 ? insured.lastname2 : ''}`,
        // index === 0 || !props.pets
        //   ? `${insured.name} ${insured.lastname1} ${insured.lastname2}`
        //   : `${insured.name}`,
        type: "",
        key: "body"
      },
      docNumber: {
        text: isPetArea ? insured.numChip : insured.docNumber,
        type: "",
        key: "body"
      },
      // status: {
      //   text: "No completado",
      //   type: "incomplete",
      //   key: "tag-container",
      // },
      buttons: {
        key: "buttons",
        items:
          (isGIPArea ?
            [
              {
                img: images.IconDetailLightBlue,
                link:
                  linkDetail + getProductTypeFromURL(baseUrl) + (`/asegurados/${index}`)
              }
            ]

            :

            [
              {
                img: images.IconDetailLightBlue,
                link: linkDetail + getProductTypeFromURL(baseUrl) + (`/asegurado?index=${index}`)
              },
              ...( !(index === 0 && data.insureds.length <= 1) ?
                [
                  {
                  img: images.IconEraseRed,
                  onClick: () => removeInsured(index)
                  }
                ]
                : [{}]
              )   
            ]
          )

      }
    })));
    return rows;
  }

  getInsuredColumns = () => {
    const { policy, checkProductArea } = this.props;
    const { simulation } = this.state
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)

    if(isPetArea && simulation){
      return [
        {
          text: "NOMBRE DEL TOMADOR",
          filter: false,
          ascendent: true
        },
        {
          text: "MASCOTAS A ASEGURAR",
          filter: false,
          ascendent: true
        },
        {
          text: "FECHA DE INICIO",
          filter: false,
          ascendent: true
        },
        {
          text: "TIPO DE PAGO",
          filter: false,
          ascendent: true
        },
        {
          text: "",
          filter: false,
          ascendent: true
        }
      ];
    }else if(isDeathArea){
      return [
        {
          text: "Asegurado",
          filter: false,
          ascendent: true
        },
        {
          text: "Nombre",
          filter: false,
          ascendent: false
        },
        {
          text: "Nº de Documento",
          filter: false,
          ascendent: false
        },
        // {
        //   text: "Datos personales",
        //   filter: false,
        //   ascendent: true,
        // },
        {
          text: "",
          filter: false,
          ascendent: true
        }
      ];
    }else{
      return [
        {
          text: "Nombre",
          filter: false,
          ascendent: false
        },
        {
          text: isPetArea ? "Chip" : "Nº de Documento",
          filter: false,
          ascendent: false
        },
        // {
        //   text: "Datos personales",
        //   filter: false,
        //   ascendent: true,
        // },
        {
          text: "",
          filter: false,
          ascendent: true
        }
      ]
    }
  }

  getInsuredRowKeys = () => {
    const { policy, checkProductArea } = this.props;
    const { simulation } = this.state
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)

    if(isPetArea && simulation){
      return ["taker", "numPets", "startDate", "paymentType", "buttons"];
    }else if(isDeathArea){
      return ["insured", "name", "docNumber", "buttons"]; //  "status"
    }else{
      return ["name", "docNumber", "buttons"]; //  "status"
    }
  }

  isPetsAndHasInsureds = () => {
    const { data, hiring, policy, checkProductArea } = this.props;
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
    if (isPetArea && hiring && data.insureds.length === 0) return true;
    if ((isPetArea && !hiring) || data.insureds.length > 0) {
      return false;
    }
    return true;
  };

  isButtonVisible = (product: any) => {
    const { simulation } = this.state;
    const { checkProductArea } = this.props;

    const isPetArea = checkProductArea(AREA_NAMES.PET, product.products_internalName)
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, product.products_internalName)

    return !(isPetArea || isDeathArea) || simulation ? true : false;
  }

  isHiringTakerIban = () => {
    const { hiring, data } = this.props;
    if (data.policyData) {
      if (hiring) {

        if (!data.paymentMethod || !data.paymentMethod.mainIban || !data.paymentMethod.mainIban.iban) {

          return true;
        }
      }
    } else {
      if (hiring) {

        if (!data.payment.iban) {

          return true;
        }
      }
      return false;
    }

  };


  goBackHandler = () => {
    const { insuredRows, simulation } = this.state
    const { history,checkProductArea, policy } = this.props;
    const { location: { state: historyState } } = history;
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)

    if (historyState && historyState.from && historyState.from.pathname) {
      const { from } = historyState;
      if (from.pathname.indexOf('asegurado') > -1) {
        let insuredNumber: any = insuredRows.length - 1;

        if (from.search && from.search !== "") {
          insuredNumber = from.search.match(/index=(\d+)/i)[1];
          if (parseInt(insuredNumber) !== insuredRows.length - 1) {
            // NOTE 📝: in case the insured is deleted but the user comes from that view and wants to go back
            insuredNumber = parseInt(insuredNumber) - 1;
          }

        }
        history.push(`${from.pathname}?index=${insuredNumber}`);
        return;
      }
      history.push(from.pathname);
      return;
    }
    if(!simulation && isPetArea){
      history.push("/contratacion/nueva/mascotas/proteccion-datos")
    }
    else{
      history.goBack();
    }
  };

  checkHireIsDisabled(buttonText: string): boolean {
    if (buttonText.toUpperCase() === 'CONTRATAR') {
      return this.props.data.signData && this.props.data.signData.signStatus !== '' ?
        isValidStatusToEnableButton(VALID_STATUS_TYPE.ALLOW_HIRE, this.props.data.signData.signStatus)
        : true
    }
    else {
      return false
    }
  }

  render() {
    const { insuredRows, steps, simulation, takerRows, beneficiaryRows, isReadOnly } = this.state;
    const {
      data,
      addNewInsured,
      action,
      positionStep,
      policy,
      actionSave,
      actionCancel,
      hiring,
      downloadDocs,
      sendSign,
      checkProductArea,
      disableDownloadDocs,
      disableSendSign
    } = this.props;
    const isLifeArea = checkProductArea(AREA_NAMES.LIFE, policy.product.products_internalName)
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)
    const mainButtonText: string =
      simulation
        ? "Simular"
        : isLifeArea
          ? "Continuar Proceso"
          : "Contratar";
    return (
      <PolicyContainer>
        <div className="new-simulation-flow-name">
          <GoBack text="Volver" onClick={this.goBackHandler} />
        </div>

        {
          takerRows.length > 0 && (!isPetArea || (isPetArea && hiring)) &&
            <>
              <div className="new-simulation-flow-status">
                <div className="new-simulation-flow-status-title">
                  <HeadsTitleText>
                    <p>Tomador</p>
                  </HeadsTitleText>
                </div>
                <div className="new-simulation-flow-status-guide">
                  <GuideLine steps={steps} currentStep={positionStep || 3} />
                </div>
              </div>

              <InsuredMembersContainer>
                <div className="insured-members-wrapper">
                  <div className="inflow-members-sidebar">
                    <div className="inflow-members-sidebar-wrapper">
                      <div className="inflow-members-item" />
                      <div className="inflow-members-item" />
                    </div>
                  </div>
                  <div className="wrapper-content-table">
                    <MainTable
                      className="table"
                      columns={this.takerTableColumns}
                      rows={takerRows}
                      keysRow={this.keysTakerRows}
                    />
                  </div>
                </div>
              </InsuredMembersContainer>
            </>
        }

        {
          beneficiaryRows && beneficiaryRows.length > 0 && (isPetArea && hiring) &&
            <>
              <div className="new-simulation-flow-status topMargin20">
                <div className="new-simulation-flow-status-title">
                  <HeadsTitleText>
                    <p>Asegurado</p>
                  </HeadsTitleText>
                </div>
              </div>
              <InsuredMembersContainer>
                <div className="insured-members-wrapper">
                  <div className="inflow-members-sidebar">
                    <div className="inflow-members-sidebar-wrapper">
                      <div className="inflow-members-item" />
                      <div className="inflow-members-item" />
                    </div>
                  </div>
                  <div className="wrapper-content-table">
                    <MainTable
                      className="table"
                      columns={this.beneficiaryTableColumns}
                      rows={beneficiaryRows}
                      keysRow={this.keysBeneficiaryRows}
                    />
                  </div>
                </div>
              </InsuredMembersContainer>
            </>
        }

        {
          insuredRows.length > 0 &&
          <>
            <div className="new-simulation-flow-status">
              <div className="new-simulation-flow-status-title topMargin20">
                <HeadsTitleText>
                  <p>{isPetArea?"Mascotas aseguradas":"Miembros asegurados"}</p>
                </HeadsTitleText>
              </div>
            </div>

            <InsuredMembersContainer>
              <div className="insured-members-wrapper">
                <div className="inflow-members-sidebar">
                  <div className="inflow-members-sidebar-wrapper">
                    <div className="inflow-members-item" />
                    <div className="inflow-members-item" />
                  </div>
                </div>
                  <div className="wrapper-content-table">
                    <MainTable
                      className="table"
                      columns={this.getInsuredColumns()}
                      rows={insuredRows}
                      keysRow={this.getInsuredRowKeys()}
                    />
                  </div>
              </div>
            </InsuredMembersContainer>
          </>

        }

        <InflowSidebarContainer>
          <div className="inflow-sidebar">
            {this.isHiringTakerIban() && (
              <div className="error-iban-container">
                <Body02Text>
                  <p>Faltan los datos bancarios</p>
                </Body02Text>
              </div>
            )}
            <div className="two-buttons-container">
              {mainButtonText === "Contratar" && (
                <>
                  <div className="two-buttons-item">
                    <MainButton
                      disabled={this.isPetsAndHasInsureds() || this.isHiringTakerIban() || disableDownloadDocs}
                      text="Descargar Documentación"
                      onClick={() => downloadDocs()}
                    />
                  </div>
                </>
              )}
              {mainButtonText === "Contratar" && (
                <>
                  <div className="two-buttons-item">
                    <MainButton
                      disabled={isReadOnly || this.isPetsAndHasInsureds() || this.isHiringTakerIban() || disableSendSign
                        || (data.signData && data.signData.signStatus && isValidStatusToEnableButton(VALID_STATUS_TYPE.ALLOW_SIGN, data.signData.signStatus))}
                      text="Enviar firma"
                      onClick={() => sendSign()}
                    />
                  </div>

                  <div className="two-buttons-item">
                    <MainButton
                      disabled={isReadOnly}
                      text="Cancelar"
                      onClick={() => actionCancel && actionCancel()}
                    />
                  </div>
                </>
              )} 
              {this.isButtonVisible(policy.product) && (
                <div className="two-buttons-item">
                  <MainButton
                    disabled={this.isPetsAndHasInsureds() || this.isHiringTakerIban()
                      || this.checkHireIsDisabled(mainButtonText)}
                    text={mainButtonText}
                    onClick={() => action()}
                  />
                </div>
              )}
              {!simulation && !isLifeArea && (
                <div className="two-buttons-item">
                  {/* ir al resultado de simulacion al hacer click */}
                  <MainButton
                    disabled={this.isPetsAndHasInsureds() || this.isHiringTakerIban()
                      || (data.signData && data.signData.signStatus && isValidStatusToEnableButton(VALID_STATUS_TYPE.ALLOW_EDIT, data.signData.signStatus))}
                    type="google"
                    text={"Guardar"}
                    onClick={() => actionSave && actionSave()}
                  />
                </div>
              )}
              {((isPetArea && !simulation || isDeathArea) && policy.data.insureds.length < 10) && (
                <div className="two-buttons-item">
                  <MainButton
                    type="full-light"
                    text={(isPetArea ? "Añadir mascota" : "Añadir asegurado")}
                    disabled={isReadOnly}
                    onClick={() => addNewInsured()}
                  />
                </div>
              )}
            </div>
          </div>
        </InflowSidebarContainer>
      </PolicyContainer>
    );
  }
}

export default withDynamicProducts(withPayTypes(withPolicy(withRouter(InsuredMembersLayout))));
