import GeneralServices from "./GeneralServices";

class StatsServices {
  getStats = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader && (args.loader === false ? false : true),
        endpoint: `stats`
      });
      return response.data;
    } catch (err) {
      return {};
    }
  };

  getCampaignStats = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader && (args.loader === false ? false : true),
        endpoint: `stats/campaigns?campaign=${args.id}`
      });
      return response.data;
    } catch (err) {
      return {};
    }
  };
}

export default new StatsServices();
