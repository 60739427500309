import styled from "styled-components";
import { colors } from "../../assets/colors";

export const InputBoxContainer = styled.div`
  &.error {
    .input-box-main-field > input,
    textarea {
      border: 1px solid ${colors["ER-001-100"]};
      &::placeholder {
        color: ${colors["ER-001-100"]};
      }
    }
  }

  &.grey {
    .input-box-main-field {
      input,
      textarea {
        color: ${colors["TX-001-30"]};
        &::placeholder {
          color: ${colors["TX-001-30"]};
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .input-box-topbar-label {
      color: ${colors["TX-001-30"]};
    }

    .input-box-main-field {
      background: ${colors["FL-001-Disabled"]};

      input,
      textarea {
        border: 1px solid ${colors["TX-001-30"]};
        color: ${colors["TX-001-30"]};
      }

      .currency-icon {
        color: ${colors["TX-001-30"]};
      }
    }
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
  }

  .input-box-topbar-label {
    color: ${colors["PR-001-50"]};
    text-transform: uppercase;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-field {


    &.required {
      &:before {
        content: "";
        z-index: 9;
        position: absolute;
        top: 23px;
        left: 8px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: transparent;
      }
    }

    &:hover {
      background: #f0fafd;
    }
  }

  textarea {
    height: 200px;
  }

  textarea .isQuestionnaire{
    width: 100%;
  }

  input, .currency-wrapper {
    height: 48px;
    width: -webkit-fill-available;;
  }

  .input-box-topbar-label .focus {
    p {
      color: red;
    }
  }

  // input,
  // textarea {
  //   font-family: "museo sans";
  //   line-height: 24px;
  //   font-size: 16px;
  //   width: 100%;
  //   font-weight: 300;
  //   padding: 13px 20px 9px;
  //   padding-right: 30px;
  //   transition: all 0.2s ease-in-out;
  //   color: ${colors["PR-001-100"]};
  //   border-radius: 3px;
  //   border: 0;
  //   border-bottom: 1px solid ${colors["PR-001-100"]};
  //   &::placeholder  {
  //     color: ${colors["PR-001-100"]};
  //   }
  //   &:focus {
  //     background: #ffffff;
  //     border-radius: 3px;
  //     border-bottom: none;
  //     box-shadow: ${props => props.type === 'currency' ? '0' : '0px 2px 8px rgba(0, 0, 0, 0.05)'};
  //   }
  // }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 20px;
    z-index: 10;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    transform: translateX(-50%);
    width: 260px;
    right: 0;
  }

  .input-box-error {
    color: ${colors["ER-001-100"]};
    padding-top: 3px;

    p {
      color: ${colors["ER-001-100"]};
    }
  }

  &.rounded {
    input,
    textarea {
      border: 1px solid ${colors["PR-001-100"]};
    }
  }

  &.blue {
    input,
    textarea {
      border: 0;
    }
    background: #f0f5f8;
  }
  .currency-wrapper {
    div {
      position: absolute;
      right: 10px;
      top: 30px;
    }
  }
  .input-box-topbar ~ .input-box-main {
    .currency-wrapper{
      .currency-icon {
        top: 47px;
      }
    }
  }

  .input-box-placeholder-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    top: ${(props) => props.tooltipIconTop || "-72px"};
    left: ${(props) => props.tooltipIconLeft || "190px"};
    height: 0;

    img {
      width: 24px;
      cursor: pointer;
    }
  }
`;
