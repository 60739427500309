import styled, { css } from 'styled-components';

export const BodyTextContainer = styled.div`
    font-family: "museo sans";
    font-weight: 300;
    font-size: 16px;
 
    ${props => css`
        color: ${props.color}
    `}




`;