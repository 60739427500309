import styled from "styled-components";
import { colors } from "../../assets";

export const PaginationContainer = styled.div`
  font-family: "museo sans";

  .pagination-container {
    display: flex;
    color: ${colors["PR-001-100"]};
  }

  .pagination-left {
    margin-right: 23px;
    min-width: 20px;
    img {
      max-width: 16px;
    }
  }

  .pagination-main {
    display: flex;
    justify-content: center;
    span {
      margin: 0 8px;

      &.actual {
        color: #6cc9f0;
        position: relative;
        &:after {
          content: "";
          width: 6px;
          height: 6px;
          background: #6cc9f0;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          bottom: -10px;
          transform: translateX(-50%);
        }
      }
    }
  }

  .pagination-right {
    margin-left: 23px;
    min-width: 20px;
    img {
      max-width: 16px;
    }
  }

  span {
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    opacity: 1;
    pointer-events: all;

    &.limit {
      opacity: 0.5;
      pointer-events: none;
      transform: translateX;
    }
  }

  .dots-container {
    color: #6cc9f0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 16px;
  }

  .dots-item {
    display: flex;
    span {
      margin: 0;
      cursor: default;
      width: 4px;
      height: 4px;
      background: #6cc9f0;
      border-radius: 50%;
      display: block;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
