import GeneralServices from "./GeneralServices";

import { MediatorInfoRequestDto } from "./dto/request/MediatorServiceRequest.dto";
import { IMediator } from "../models/mediator-model";

class MediatorServices {
  loginGoogle = async (args: any) => {
    try {
      const res = await GeneralServices.post({
        loader: true,
        setLoading: args.setLoading,
        data: args.data,
        endpoint: "auth/socialNetworkLogin"
      });

      return res.data;
    } catch (err) {
      throw err;
    }
  };

  login = async (args: any) => {
    try {
      const res = await GeneralServices.post({
        loader: true,
        setLoading: args.setLoading,
        data: args.data,
        endpoint: "auth/login"
      });

      return res.data;
    } catch (err) {
      throw err;
    }
  };

  getProfile = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader && (args.loader === false ? false : true),
        endpoint: `auth/profile`
      });
      return response.data;
    } catch (err) {
      return {};
    }
  };

  getMediatorInfo = async (args: MediatorInfoRequestDto): Promise<IMediator> => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader && (args.loader === false ? false : true),
        endpoint: `auth/mediatorInfo/${args.mediatorCode}${args.productPermissionCheck ? `?productPermissionCheck=true`:''}`
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };
}

export default new MediatorServices();
