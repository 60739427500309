import styled from "styled-components";

export const LoaderSpinnerContainer = styled.div`
  &.active {
    display: block;
  }
  height: 30px;


  .loader-holder {
    position: absolute;
    z-index: 99999;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
  }

  .spinner {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
    -webkit-animation: color-bubble 2s linear 0s infinite;
    animation: color-bubble 2s linear 0s infinite;
  }

  .spinner {
    animation: color-bubble 2s linear 0s infinite;

    &:after,
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 100%;
      box-shadow: 0 0 0 3px;
      -webkit-animation: spinner-bubble 1.3s linear 0s infinite;
      animation: spinner-bubble 1.3s linear 0s infinite;
      margin: auto;
      width: 10px;
      height: 10px;
      opacity: 0;
    }

    &:before {
      -webkit-animation: spinner-bubble 1.3s linear 2s infinite;
      animation: spinner-bubble 1.3s linear 2s infinite;
    }
  }

  @keyframes spinner-bubble {
    from {
      width: 10px;
      height: 10px;
      opacity: 0.8;
    }
    to {
      width: 50px;
      height: 50px;
      opacity: 0;
    }
  }

  @keyframes color-bubble {
    0% {
      color: rgb(23, 93, 146);
    }
    33% {
      color: rgba(23, 93, 146, 0.75);
    }
    66% {
      color: rgba(23, 93, 146, 0.5);
    }
    100% {
      color: rgba(23, 93, 146, 0.25);
    }
  }
`;
