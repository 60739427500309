import styled from "styled-components";
import { BreadcrumbTextContainer } from "../../../components/components-text/breadcrumb-text/breadcrumb-text-style";
import { colors } from "../../../assets";
import { MainButtonContainer } from "../../../components/main-button/main-button-style";

export const NewHiringContainer = styled.div`
  .hide {
    display: none;
  }

  .new-hiring-flow-name {
    margin-bottom: 17px;
    ${BreadcrumbTextContainer} {
      color: ${colors["TX-001-30"]};
    }
  }

  .new-hiring-subtitle-2 {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: ${colors["TX-001-50"]};
    margin-bottom: 17px;
  }

  .new-hiring-flow-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .new-hiring-flow-status-guide {
    display: flex;
    align-items: center;
  }

  .new-hiring-title {
    color: ${colors["PR-001-100"]};
  }

  .new-hiring-subtitle {
    color: ${colors["TX-001-80"]};
    margin-bottom: 50px;
  }

  .new-hiring-navigate-container {
    display: flex;
    margin-top: 122px;
    margin-bottom: 100px;
  }

  .new-hiring-navigate-item {
    width: 100%;
    max-width: 180px;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  .informative-note-message{
    p{
      color: ${colors["errorColor"]};
    }
  }
`;

export const ChoosePolicyContainer = styled.div`
  .policies-container {
    display: flex;
    flex-wrap: wrap;
    width: 130%;
    margin-bottom: 100px;
  }

  .policies-item {
    margin-right: 20px;
    width: 295px;
    height: 420px;
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    background: #ffffff;
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-right: 0;
    }

    &.open {
      .policies-item-cover {
        bottom: 260px;
        z-index: 1;

        .policies-info-link {
          display: none;
        }
      }

      .policies-info {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
      }

      .policies-info-title {
        margin-bottom: 0;
      }

      .policies-item-content {
        opacity: 1;
      }
    }
  }

  .policies-item-cover {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s all ease-in;
    background: #ffffff;
    z-index: 19;
  }

  .policies-item-content {
    width: 100%;
    height: 100%;
    padding: 105px 24px 24px;
    position: relative;
    z-index: 9;
    opacity: 0;
    transition: 0.3s all ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    ul {
      margin-bottom: 24px;
      li {
        margin-bottom: 16px;
        color: ${colors["PR-001-100"]};

        p {
          display: inline-block;
          cursor: pointer;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          color: ${colors["SC-004-100"]};
          padding-left: 18px;
          position: relative;

          &:before {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: ${colors["SC-004-100"]};
            position: absolute;
            top: 4px;
            left: 0;
          }
        }
      }
    }
  }

  .policies-wave {
    margin-bottom: 8px;
  }

  .policies-container {
    &:hover {
      .policies-item {
        opacity: 0.5;
      }
    }
  }

  .policies-container {
    .policies-item {
      &:hover {
        opacity: 1;
      }
    }
  }

  .policies-info-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 24px;
  }

  .policies-info {
    padding: 0 24px 24px;
  }

  .policies-info-link {
    ${MainButtonContainer} {
      max-width: 150px;
      height: auto;
      padding: 10px 8px;
    }
  }
`;
