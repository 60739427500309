import React, { Component } from "react";
import FooterContainer from "./footer-style";
import { icons } from "../../assets/icons";
import { Link } from "react-router-dom";
interface FooterProps {
  secondaryColor?: boolean;
}
class Footer extends Component<FooterProps> {
  showOptanonSettingsDisplay = () => {
    var btn = document.getElementById("openCookieSettings");
    btn && btn.click();
  }

  render() {
    return (
      <FooterContainer>
        <nav className="footer-wrapper">
          <div className="footer-top">
            <div className="logo-icon">
              <img src={icons.logoAsisaWhite} alt="logo-icon" />
            </div>
            <ul className="footer-list">
              <li className="footer-element">
                <Link to="/politica-cookies">
                  <p>Política de cookies</p>
                </Link>
              </li>
              <li className="footer-element">
                <p className="footer-configuration" onClick={() => this.showOptanonSettingsDisplay()}>Configuración de cookies</p>
              </li>
              <li className="footer-element">
                <Link to="/politica-proteccion-datos">
                  <p>Política de protección de datos</p>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </FooterContainer>
    );
  }
}

export default Footer;
