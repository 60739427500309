import React from "react";
import { DynamicProductsContext } from "../constants";

export const withDynamicProducts = Component => {

  return props => (
    <DynamicProductsContext.Consumer>
      {value => (
        <Component
          {...props}
          productsByAreaName={value.productsByAreaName}
          allProducts={value.allProducts}
          checkProductArea={value.checkProductArea}
          travelCountries={value.travelCountries}
        />
      )}
    </DynamicProductsContext.Consumer>
  );
};