import { ValueFormHealth, ValueFormHealthAddress } from "../../../../../../constants/health";

const DefaultValueFormHealthAddress = (isDisabled: boolean): ValueFormHealthAddress => {
    return {
        value: "",
        label: "",
        errorCode: "",
        code: "",
        disabled: isDisabled,
    };
}

const DefaultValueFormHealth = (isDisabled: boolean): ValueFormHealth => {
    return {
        value: "",
        errorCode: "",
        disabled: isDisabled,
        code:""
    };
}

export interface AddressDataItem {
    value: string;
    code: string;
    label: string;
}

export interface AddressDataDto {
    address: ValueFormHealthAddress;
    wayType: string;
    number: ValueFormHealth;
    portalValue: ValueFormHealth;
    stairsValue: ValueFormHealth;
    floorValue: ValueFormHealth;
    doorValue: ValueFormHealth;
    postalCode: ValueFormHealthAddress;
    town: ValueFormHealthAddress;
    province: ValueFormHealthAddress;
    provinceLabel: string;
    socialReason: ValueFormHealth;
    responsable: ValueFormHealth;
    sameAsTaker: boolean;
    normalizaDireciones: "N" | "S";
}

export const DefaultAddressDataDto = (): AddressDataDto => {
    return {
        address: DefaultValueFormHealthAddress(true),
        wayType: '',
        number: DefaultValueFormHealth(false),
        portalValue: DefaultValueFormHealth(false),
        stairsValue: DefaultValueFormHealth(false),
        floorValue: DefaultValueFormHealth(false),
        doorValue: DefaultValueFormHealth(false),
        postalCode: DefaultValueFormHealthAddress(true),
        town: DefaultValueFormHealthAddress(true),
        province: DefaultValueFormHealthAddress(false),
        provinceLabel: '',
        socialReason: DefaultValueFormHealth(false),
        responsable: DefaultValueFormHealth(false),
        sameAsTaker: false,
        normalizaDireciones: "S",
    };
}
