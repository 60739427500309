import { Taker, MediatorData } from "./mediator-data-layout";
import { deepGet } from "../../../../utils/utility";

interface ValidateDate {
  productFields: unknown;
  taker: Taker;
  mediatorData: MediatorData;
}

export function validateData({ productFields, taker, mediatorData }: ValidateDate) {
  const errors: { [key: string]: string } = {};

  if (deepGet(productFields, "step1.name.required", false) && !taker.name) {
    errors["step1.name"] = "required";
  }
  if (deepGet(productFields, "step1.lastname1.required", false) && !taker.lastname1) {
    errors["step1.lastname1"] = "required";
  }
  if (deepGet(productFields, "step1.lastname2.required", false) && !taker.lastname2) {
    errors["step1.lastname2"] = "required";
  }
  if (deepGet(productFields, "step1.email.required", false) && !taker.email) {
    errors["step1.email"] = "required";
  }
  if (deepGet(productFields, "step1.mobilePhone.required", false) && !taker.mobilePhone) {
    errors["step1.mobilePhone"] = "required";
  }
  if (deepGet(productFields, "step1.landlinePhone.required", false) && !taker.landlinePhone) {
    errors["step1.landlinePhone"] = "required";
  }
  if (deepGet(productFields, "step1.docsLanguage.required", false) && !taker.docsLanguage) {
    errors["step1.docsLanguage"] = "required";
  }
  if (deepGet(productFields, "step1.startDate.required", false) && !mediatorData.startDate) {
    errors["step1.startDate"] = "required";
  }
  if (deepGet(productFields, "step1.endDate.required", false) && !mediatorData.endDate) {
    errors["step1.endDate"] = "required";
  }

  return errors;
}
