import styled from 'styled-components';
import { colors } from '../../assets';

export const LegalPolicyContainer = styled.div` 
  margin-right: 247px;
  min-width: 600px;
  max-width: 1000px;
  .btn-azul {
    background: ${colors["PR-001-100"]};
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 20%);
    border: medium none;
    color: #fff !important;
    display: inline-block;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    float: right;
    font-weight: bold;
    font-size: 16px;
  }
  
  .btn-azul:hover {
    background: #003157;
    text-decoration: underline;
  }

  .go-back-container {
    margin-bottom: 22px;
  }

  .legal-title-selection {
    margin-bottom: 60px;
    font-family: 'grouch';
    font-size: 56px;
    line-height: 68px;
    color: #004F8B;
  }

  .legal-subtitle {
    font-family: "museo sans";
    font-size: 24px;
    line-height: 32px;
    color: #004F8B;
    margin-bottom: 32px;
    font-weight: 700;
  }

  .legal-subtitle-section {
    font-size: 16px;
    line-height: 27px;
    color: #004F8B;
    font-family: "museo sans";
    margin-bottom: 19px;
  }

  .legal-subtitle-p-title {
    font-family: "museo sans";
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: #004F8B;
    margin-bottom: 8px;
  }

  .legal-text {
    font-family: "museo sans";
    margin-bottom: 48px;
    color: #091E42;
    font-weight: 300;
    a {
      color: $ {
        colors["PR-001-100"]
      }
      ;
    }
    ul {
      padding-left: 30px;
    }
    li {
      position: relative;
      padding-left: 20px;
      font-size: 16px;
      line-height: 27px;
      color: #091E42;
      &:last-of-type {
        margin-bottom: 48px;
      }
      &::before {
        content: "";
        width: 6px;
        height: 6px;
        background: #004F8B;
        position: absolute;
        left: 0;
        top: 9px;
        border-radius: 100%;
      }
    }
    p {
      &.list {
        margin-bottom: 24px;
        &:last-of-type {
          margin-bottom: 24px;
        }
      }
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 27px;
      color: #091E42;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
    a {
      color: #004F8B;
      font-weight: bold;
    }
    .tablaGenerica th {
      font-weight: bold;
      vertical-align: middle;
      color: #ffffff;
      background-color: #004F8B;
      text-align: left;
      display: table-cell;
      font-size: 18px;
      line-height: 18px;
      padding-top: 14px;
      padding-left: 20px;
      padding-right: 1%;
      padding-bottom: 14px;
    }
    .tablaGenerica tr:nth-of-type(odd) {
      background: #f8f8f8;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px;
      margin: 0 auto;
    }
    .tablaGenerica td {
      color: #333;
      vertical-align: middle;
      border: none;
      font-size: 18px;
      line-height: 1.4em;
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 1em;
    }
    .tablaGenerica th,
    .tablaGenerica td {
      width: 16%;
    }
    table.tablaGenerica {
      background: #fff;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px;
      border: 1px solid #ddd;
      width: 100%;
      margin-bottom: 20px;
    }
    table.tablaGenerica thead,
    table.tablaGenerica tfoot {
      background: #f5f5f5;
      text-align: justify;
      font-size: 18px;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
      font-family: "museo sans";
    }
  }

  .custom-file-upload > input[type="file"] {
    display: none;
  } 

  .icon-check-hidden {
    display: none;
  }

  .icon-check-block {
    display: block;
    img {
      width: 22px;
    }
  }

  .warning {
    margin-bottom: 50px;
  }
`