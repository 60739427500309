import React from "react";
import { Container, Header, Img, Item } from "./guarantees-table.style";
import { images } from "../../../../../../assets";

type GuaranteesProps = {
  guarantees: string[];
}

const Guarantees: React.FC<GuaranteesProps> = ({ guarantees }) => {
  return (
    <Container>
      <Header>GARANTÍAS OBLIGATORIAS</Header>
      <div className="cell">
        <div className="internalCell">
          {guarantees.map((guarantee, index) => (
            <Item key={index}>
              <Img src={images.IconCheckBlue} alt={"check"} />
              <span style={{ fontSize: "12px" }}>{guarantee}</span>
            </Item>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Guarantees;
