import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { HeaderContainer, ProfileContainer } from "./header-style";
import { images } from "../../assets";
import Menu02Text from "../components-text/menu-02-text";
import BreadcrumbText from "../components-text/breadcrumb-text";
import BodyText from "../components-text/body-text";
import InfoModal from "../info-modal";
import { withGeneral } from "../../context/mixin/with-general";
import LeftInfoModal from "../left-info-modal";
import TooltipBox from "../tooltip-box/tooltip-box";
import ContactServices from "../../services/ContactServices";
import { IUser } from "../../models/user-model";
import AuthService from "../../auth/AuthService";
import { SAML_LOGOUT } from "../../services/url";
import { roleAllowed } from "../../constants";

const jwt = require('jsonwebtoken');
interface HeaderLayoutProps extends RouteComponentProps {
  history: any;
  isLogged: boolean;
  is404: boolean;
  is403: boolean;
  setUser: Function;
  user: IUser;
  isCampaignConfiguration: boolean;
  setHasInformativeNote: Function;
  authService: AuthService;
}
interface HeaderLayoutState {
  showProfile?: boolean;
  showNotifications?: boolean;
  newNotifications?: boolean;
  notShowHeader: string[];
  showInfoModal: boolean;
  activeLeftModal: boolean;
  profileTooltipActive: boolean;
  profileTooltipRight: string;
  exitTooltipActive: boolean;
}

class HeaderLayout extends Component<HeaderLayoutProps, HeaderLayoutState> {
  notShowHeader: string[] = [];
  simulationPages = "/simulacion/nueva";
  hiringPages = "/contratacion/nueva";
  notificationsRef: any;
  profileRef: any;
  profileButtonRef: any;

  constructor(props: HeaderLayoutProps) {
    super(props);
    this.state = {
      showProfile: false,
      showNotifications: false,
      newNotifications: false,
      notShowHeader: props.isLogged ? [] : ["/"],
      showInfoModal: false,
      activeLeftModal: false,
      profileTooltipActive: false,
      exitTooltipActive: false,
      profileTooltipRight: '0px'
    };
    this.notificationsRef = React.createRef();
    this.profileRef = React.createRef();
    this.profileButtonRef = React.createRef();

  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  isVisible(e: any) {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
  }

  handleClickOutside(ev: any) {
    const { showNotifications, showProfile } = this.state;
    if (this.isVisible(ev.target)) {
      this.notificationsRef.current &&
        !this.notificationsRef.current.contains(ev.target) &&
        showNotifications &&
        this.setState({ showNotifications: false });

      (this.profileRef.current &&
        !this.profileRef.current.contains(ev.target) &&
        this.profileButtonRef.current &&
        !this.profileButtonRef.current.contains(ev.target)) &&
        showProfile &&
        this.setState({ showProfile: false });
    }
  }

  componentDidUpdate(
    prevProps: HeaderLayoutProps,
    prevState: HeaderLayoutState
  ) {
    if (prevProps.isLogged !== this.props.isLogged && !this.props.isLogged) {
      const { notShowHeader } = this.state;
      notShowHeader.push("/");
      this.setState({ notShowHeader });
    } else if (
      prevProps.isLogged !== this.props.isLogged &&
      this.props.isLogged
    ) {
      const { notShowHeader } = this.state;
      const index = notShowHeader.findIndex(e => e === "/");
      if (index > -1) {
        notShowHeader.splice(index, 1);
        this.setState({ notShowHeader });
      }
    }
  }

  toggleProfile() {
    const { showProfile } = this.state;
    this.setState({ showProfile: !showProfile });
  }

  openProfile() {
    this.setState({ showProfile: true });
  }

  closeProfile() {
    this.setState({ showProfile: false });
  }

  openNotifications() {
    this.setState({ showNotifications: true });
  }

  closeNotifications() {
    const { history } = this.props;
    let activeLeftModal = false;
    if (history.location.pathname.indexOf("/nueva") > -1) {
      activeLeftModal = true;
    }

    this.setState({ showNotifications: false, activeLeftModal }, () => {
      if (!activeLeftModal) {
        history.push("/notificaciones");
      }
    });
  }

  toggleShowNotifications() {
    const { showNotifications } = this.state;
    this.setState({ showNotifications: !showNotifications });
  }

  logout = async () => {
    try {
      const accessToken = localStorage.accessToken;
      const { idp } = await jwt.decode(accessToken);
      if (idp === "asisa") {
        const authService = new AuthService();
        await authService.logout();
      } else if (idp === "ocaso") {
        const { setUser, setHasInformativeNote } = this.props;
        setHasInformativeNote(false);
        setUser({}, true);
        this.cleanLocalStorage();
        if (SAML_LOGOUT) window.location.href = SAML_LOGOUT;
        else window.location.href = window.location.origin;
      }
    } catch (err) { console.log(err) }
  };

  cleanLocalStorage() {
    const cookiesStorage = localStorage.getItem("acceptedCookies");
    localStorage.clear();
    if (cookiesStorage) {
      localStorage.setItem("acceptedCookies", cookiesStorage);
    }
  }

  openInfoModal() {
    this.setState({ showInfoModal: true });
  }

  closeInfoModal() {
    this.setState({ showInfoModal: false });
  }

  closeLeftModal() {
    this.setState({ activeLeftModal: false });
  }

  navigate() {
    this.props.history.push("/notificaciones");
  }

  setProfileTooltipActive(ev: any) {
    const profileTooltipRight = `${ev.target.offsetWidth / 2 + 85}px`;
    this.setState({ profileTooltipRight, profileTooltipActive: true })
  }

  async goToOldPortal() {
    const resp = await ContactServices.goToOldPortal();
    window.open(resp.url);
  }

  render() {
    const { history, is404, is403,  user, isCampaignConfiguration, isLogged } = this.props;
    const {
      showProfile,
      showNotifications,
      newNotifications,
      notShowHeader,
      showInfoModal,
      activeLeftModal,
      profileTooltipActive,
      profileTooltipRight,
      exitTooltipActive
    } = this.state;
    let notShow: boolean = false;
    if (notShowHeader.includes(history.location.pathname)) {
      notShow = true;
    }
    if (is404 || is403 || !isLogged) {
      notShow = true;
    }
    const isHome = history.location.pathname === '/';
    let userAlowed = false;
    
    if(user.roles){
      userAlowed = user.roles.includes(roleAllowed); 
    }
    return notShow ? (
      ""
    ) : (
        <>
          <HeaderContainer isHome={isHome} showPortalAntiguo={!userAlowed}>
            {isHome && !userAlowed &&(
              <div className="header-top">
                <div className="header-top-advice">
                  <div className="header-top-advice__text">
                    {`En estos momentos, el nuevo Portal de Mediadores no incluye todas las funcionalidades del portal anterior. 
                    Te facilitamos el acceso a dicha plataforma para acceder al resto de opciones.`}
                  </div>
                  <div className="header-top-advice__button" onClick={() => this.goToOldPortal()} >
                    {'IR AL PORTAL ANTIGUO'}
                  </div>
                </div>
              </div>
              
            )}
            <div className="header-container">
              {!isCampaignConfiguration && (<>
                {/* <div
                  className={`header-item notifications ${
                    showNotifications ? "active" : ""
                    }`}
                  ref={this.notificationsRef}
                >
                  <div
                    className={`header-notifications ${
                      newNotifications ? "pendant" : ""
                      }`}
                    onClick={() => this.toggleShowNotifications()}
                  >
                    <img
                      src={
                        showNotifications
                          ? images.BellLightBlueIcon
                          : images.BellBlueIcon
                      }
                      alt="icono alertas"
                    />
                  </div>
                  <div className="new-notifications" >
                    <div className="arrow-up" />
                    <div className="new-notifications-title">
                      <BreadcrumbText>
                        <p>RECIENTES</p>
                      </BreadcrumbText>
                    </div>
                    <div className="new-notifications-container">
                      <div className="new-notifications-item">
                        <img src={images.IconWifiBlue} />
                        <InfoText>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipiscing...
                      </p>
                        </InfoText>
                      </div>
                      <div className="new-notifications-item">
                        <img src={images.IconWifiBlue} />
                        <InfoText>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipiscing...
                      </p>
                        </InfoText>
                      </div>
                      <div className="new-notifications-item">
                        <img src={images.BellBlueIcon} />
                        <InfoText>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipiscing...
                      </p>
                        </InfoText>
                      </div>
                      <div className="new-notifications-item">
                        <img src={images.BellBlueIcon} />
                        <InfoText>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipiscing...
                      </p>
                        </InfoText>
                      </div>
                      <div className="new-notifications-item">
                        <img src={images.IconWifiBlue} />
                        <InfoText>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipiscing...
                      </p>
                        </InfoText>
                      </div>
                    </div>
                    <div className="new-notifications-see-more">
                      <div
                        className="new-notifications-see-more-item"
                        onClick={() => this.closeNotifications()}
                      >
                        <InfoText>
                          <p>Ver más</p>
                        </InfoText>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="header-item"
                  onMouseEnter={(e: any) => this.setProfileTooltipActive(e)}
                  onMouseLeave={(e: any) => this.setState({ profileTooltipActive: false })}
                  onClick={() => this.toggleProfile()}
                  ref={this.profileButtonRef}>
                  <Menu02Text
                    className={`header-user-name ${showProfile ? "active" : ""}`}
                  >
                    {user.name} {user.lastname1} {user.lastname2}
                  </Menu02Text>
                </div>
                {profileTooltipActive && !showProfile && (
                  <div className={'profile-sidebar-data-tooltip'} style={{ right: profileTooltipRight }}>
                    <TooltipBox
                      active={true}
                      tooltipTitle={'Perfil'}
                      tooltipText={''}
                      centerArrow={true}
                    />
                  </div>)}
              </>)}
              <div className="header-item"
                onClick={() => this.openInfoModal()}
                onMouseEnter={(e: any) => this.setState({ exitTooltipActive: true })}
                onMouseLeave={(e: any) => this.setState({ exitTooltipActive: false })}
              >
                <img src={images.CloseSessionBlueIcon} alt="cerrar sesión" />
                {exitTooltipActive && (
                  <div className={'profile-sidebar-data-tooltip'} style={{ right: '42px' }}>
                    <TooltipBox
                      active={true}
                      tooltipTitle={'Cerrar Sesión'}
                      tooltipText={''}
                    />
                  </div>)}
              </div>

            </div>
          </HeaderContainer>
          <ProfileContainer className={showProfile ? "active" : ""} ref={this.profileRef}>
            <div className="profile-sidebar">
              <div className="profile-sidebar-close">
                <div
                  className="profile-sidebar-close-item"
                  onClick={() => this.closeProfile()}
                >
                  <img src={images.IconCloseGrey} alt="cerrar desplegable" />
                </div>
              </div>
              <div className="profile-sidebar-name">
                <p>
                  {user.name} {user.lastname1} {user.lastname2}
                </p>
              </div>
              {/* <div className="profile-sidebar-action">
                <MainButton
                  text="Editar perfil"
                  type="full-light"
                  onClick={() => console.log("link a senda")}
                />
              </div> */}
              <div className="profile-sidebar-data-container">
                <div className="profile-sidebar-data-item">
                  <div className="profile-sidebar-data-item-title">
                    <BreadcrumbText>
                      <p>Rol</p>
                    </BreadcrumbText>
                  </div>
                  <div className="profile-sidebar-data-item-description">
                    <BodyText>
                      <p>Mediador {user.isExternal ? "externo" : "interno"}</p>
                    </BodyText>
                  </div>
                </div>
                <div className="profile-sidebar-data-item">
                  <div className="profile-sidebar-data-item-title">
                    <BreadcrumbText>
                      <p>Lugar de operación</p>
                    </BreadcrumbText>
                  </div>
                  <div className="profile-sidebar-data-item-description">
                    <BodyText>
                      <p>{user.delegation}</p>
                    </BodyText>
                  </div>
                </div>
                {/* <div className="profile-sidebar-data-item">
                  <div className="profile-sidebar-data-item-title">
                    <BreadcrumbText>
                      <p>Clave mediador</p>
                    </BreadcrumbText>
                  </div>
                  <div className="profile-sidebar-data-item-description">
                    <BodyText>

                      <p>AM67650</p>
                    </BodyText>
                  </div>
                </div> */}
                {/* <div className="profile-sidebar-data-item">
                  <div className="profile-sidebar-data-item-title">
                    <BreadcrumbText>
                      <p>Activo</p>
                    </BreadcrumbText>
                  </div>
                  <div className="profile-sidebar-data-item-description">
                    <BodyText>
                      
                      <p>18/10/2015</p>
                    </BodyText>
                  </div>
                </div> */}
                {/* <div className="profile-sidebar-data-item">
                  <div className="profile-sidebar-data-item-title">
                    <BreadcrumbText>
                      <p>NIE/NIF</p>
                    </BreadcrumbText>
                  </div>
                  <div className="profile-sidebar-data-item-description">
                    <BodyText>
                      
                      <p>89737934X</p>
                    </BodyText>
                  </div>
                </div> */}
                <div className="profile-sidebar-data-item">
                  <div className="profile-sidebar-data-item-title">
                    <BreadcrumbText>
                      <p>Email</p>
                    </BreadcrumbText>
                  </div>
                  <div className="profile-sidebar-data-item-description">
                    <BodyText>
                      <p>{user.email}</p>
                    </BodyText>
                  </div>
                </div>
              </div>
            </div>
          </ProfileContainer>
          <LeftInfoModal
            className="warning"
            img={images.IconWarningYellow}
            mainTitle={`Vas a salir de la ${
              history.location.pathname.indexOf("simulacion") > -1
                ? "simulación"
                : "contratación"
              }`}
            mainText={`Si sale del proceso de ${
              history.location.pathname.indexOf("simulacion") > -1
                ? "simulación"
                : "contratación"
              } se perderá los cambios que NO hayan sido guardados`}
            buttonText="Salir"
            active={activeLeftModal}
            close={() => this.closeLeftModal()}
            onClickAccept={() => this.navigate()}
          />
          {showInfoModal && (
            <InfoModal
              img={images.CloseSessionBlueIcon}
              // mainTitle={"Cerrar sesión"}
              mainText={"Vas a cerrar la sesión, ¿estás seguro?"}
              buttonText="Si, salir"
              close={() => this.closeInfoModal()}
              active={showInfoModal}
              onClickAccept={() => this.logout()}
            // className="error"
            />
          )}
        </>
      );
  }
}

export default withGeneral(withRouter(HeaderLayout));
