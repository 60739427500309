import React, { Component } from 'react';
import PoliciesItemScreen from './policies-item-screen';
import { withRouter } from 'react-router-dom'

class PoliciesItem extends Component<any, {}> {
    render() {
        let {
            id,
            type,
            typeName,
            nameItem,
            arrayComplexItem,
            setItemClick,
            changeStep,
            colorCode,
            disabled,
            image,
            link,
            textButton,
            setGlobalInternalName,
            cardName
        } = this.props;

        return (
            <PoliciesItemScreen
                colorCode={colorCode}
                textButton={textButton}
                id={id}
                type={type}
                typeName={typeName}
                nameItem={nameItem}
                setItemClick={setItemClick}
                arrayComplexItem={arrayComplexItem}
                changeStep={changeStep}
                image={image}
                link={link}
                disabled={disabled}
                setGlobalInternalName={setGlobalInternalName}
                cardName={cardName}
            />
        );
    }
}

export default withRouter(PoliciesItem);