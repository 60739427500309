import React from "react";
import { withRouter } from "react-router-dom";
import { PendantSimulationContainer } from "./pendant-simulation-style";
import NoteText from "../../../components/components-text/note-text";
import MainButton from "../../../components/main-button";
import FilterInput from "../../../components/filter-input/filter-input-layout";
import MainTable from "../../../components/main-table";
import Pagination from "../../../components/pagination";
import InfoModal from "../../../components/info-modal";
import { images } from "../../../assets";
import PendantSimulationScreen from "./pendant-simulation-screen";
import Body02Text from "../../../components/components-text/body-02-text";
import { withGeneral } from "../../../context/mixin/with-general";
import { withCoverages } from "../../../context/mixin/with-coverages";
import MultiSelect from "../../../components/multi-select-box/multi-select-box";
import { PendantSimulationProps } from "./pendant-simulation-dto";
import { getRelatedMediatorsCodes } from "../../../utils/mediatorCode";
import Loader from "../../../components/loader";
import { withDynamicProducts } from "../../../context/mixin/with-dynamic-products";

class PendantSimulation extends PendantSimulationScreen {
  constructor(props: PendantSimulationProps) {
    super(props);

    this.state = {
      showInfoModal: false,
      simulations: [],
      simulationsObj: [],
      simulationsFilter: [],
      search: "",
      page: 1,
      totalPages: 0,
      showItems: 10,
      order: "createdAt",
      ascendent: false,
      infoModalMainText: "",
      searched: false,
      infoModalOnClickAccept: () => { },
      relatedMediatorCodes: getRelatedMediatorsCodes(props.mediator),
      selectedMediatorCodes: [
        `${this.props.mediator.mediatorCode
        } - ${this.props.mediator.mediatorName.trim()} - ${this.props.mediator.orderNif
        }`,
      ],
      multiSelectError: "",
      loading: true,
    };
  }

  componentDidMount() {
    const { productsByAreaName, allProducts } = this.props
    this.setState({ search: '' })
    if(Object.keys(productsByAreaName).length!==0 && Object.keys(allProducts).length!==0){
      this.setState({loading:false})
      this.getSimulations();
    }
    
  }

  componentDidUpdate(prevProps: PendantSimulationProps) {
    const { productsByAreaName, allProducts } = this.props
    if (prevProps.isExpired !== this.props.isExpired) {
      this.setState(
        {
          page: 1,
          totalPages: 0,
          search: "",
          selectedMediatorCodes: [
            `${this.props.mediator.mediatorCode
            } - ${this.props.mediator.mediatorName.trim()} - ${this.props.mediator.orderNif
            }`,
          ],
        },
        () => {
          this.getSimulations();
        }
      );
    }
    if(Object.keys(prevProps.allProducts).length===0 && Object.keys(allProducts).length!==0 && Object.keys(productsByAreaName).length!==0 || 
    Object.keys(prevProps.productsByAreaName).length===0 && Object.keys(productsByAreaName).length!==0 && Object.keys(allProducts).length!==0){
      this.setState({loading:false})
      this.getSimulations();
    }
  }

  getColumns = () => [
    {
      text: "id",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Producto",
      filter: false,
      ascendent: true,
      key: 'inline',
    },
    {
      text: "Fecha",
      filter: true,
      ascendent: false,
    },
    {
      text: "Expira",
      filter: true,
      ascendent: true,
    },
    {
      text: "Tomador",
      filter: true,
      ascendent: true,
      key: "taker"
    },
    {
      text: "Mediador",
      filter: false,
      ascendent: true,
      key: 'inline',
    },
    {
      text: "Usuario",
      filter: false,
      ascendent: true,
      key: 'inline',
    },
    {
      text: "",
      filter: false,
      ascendent: true,
    },
  ];

  changeValue = (value: string, key: string = "") => {
    let { search } = this.state;
    search = value;
    this.setState({ search });
  };

  handleOrder = (column: string, ascendent: boolean | undefined) => {
    switch (column) {
      case "taker":
        this.setState({ order: "takerFullName", ascendent }, () =>
          this.getSimulations()
        );
        break;
      case "expiration":
        this.setState({ order: "expiration", ascendent }, () =>
          this.getSimulations()
        );
        break;
      case "date":
        this.setState({ order: "createdAt", ascendent }, () =>
          this.getSimulations()
        );
        break;
      default:
        break;
    }
  };

  setPage = (page: number) => {
    this.setState({ page }, () => this.getSimulations());
  };

  closeModal = () => this.setState({ showInfoModal: false });

  openModal = () => this.setState({ showInfoModal: true });

  handleNewSimulation = () => {
    this.setState({
      showInfoModal: true,
      infoModalMainText:
        "Vas a iniciar el proceso de simulación, ¿estás seguro?",
      infoModalOnClickAccept: () => this.goToNewSimulation(),
    });
  };

  handleSelectedMediatorChange(value: string[]) {
    let { multiSelectError } = this.state;
    if (value.length === 0)
      multiSelectError = "Debes seleccionar al menos un mediador";
    else multiSelectError = "";
    this.setState({ selectedMediatorCodes: value, multiSelectError });
  }

  render() {
    const {
      showInfoModal,
      simulations,
      page,
      totalPages,
      infoModalMainText,
      infoModalOnClickAccept,
      searched,
      relatedMediatorCodes,
      selectedMediatorCodes,
      multiSelectError,
      search,
    } = this.state;
    const { isExpired } = this.props;

    return (
      <PendantSimulationContainer>
        {(this.state.loading) &&
          <Loader />
        }
        {/* <div className="back-arrow-container">
          <GoBack />
        </div> */}
        <div className="main-title-container">
          <h2>
            {isExpired ? "Simulaciones expiradas" : "Simulaciones pendientes"}
          </h2>
        </div>
        <div className="main-filter-container height80">
          <div className="colSin-100">
            <div className="colSin-20 rightPadding20">
              <div className="inputBox">
                <FilterInput
                  resetSearch={search}
                  placeholder="Buscador simulaciones"
                  onChange={(value: string) =>
                    this.changeValue(value, "search")
                  }
                  onEnter={() =>
                    this.setState({ page: 1, totalPages: 0 }, () =>
                      this.getSimulations()
                    )
                  }
                />
                <label>Buscador simulaciones</label>
              </div>
            </div>
            <div className="colSin-50 rightPadding20 ">
              <div className="selectBox">
                <MultiSelect
                  onChange={(value: string[]) =>
                    this.handleSelectedMediatorChange(value)
                  }
                  options={relatedMediatorCodes}
                  defaultValues={selectedMediatorCodes}
                  errorCode={multiSelectError}
                  placeholder={""}
                  required
                />
                <label>Mediador/es</label>
              </div>
            </div>

            <div className="colSin-15 topMargin15 rightPadding20">
              <MainButton
                text="Nueva simulación"
                onClick={() => this.handleNewSimulation()}
              />
            </div>

            <div className="colSin-15 topMargin15">
              <MainButton
                text="Buscar"
                onClick={() => {
                  this.getSimulations()
                  this.setPage(1)
                }}
                disabled={multiSelectError}
              />
            </div>
          </div>
        </div>

        {searched && (
          <div className="main-results-number">
            <NoteText>
              <p>
                Se han encontrado <span>{simulations.length} resultados</span>
              </p>
            </NoteText>
          </div>
        )}

        {totalPages === 0 ? (
          <Body02Text>No hay datos</Body02Text>
        ) : (
          <>
            <div className="pendant-simulations-table special">
              <MainTable
                className="table"
                columns={this.getColumns()}
                rowLink={"/simulacion/pendiente/detalle"}
                rowKeyLink={"databaseId"}
                rows={simulations}
                keysRow={[
                  "id",
                  "policy-type",
                  "date",
                  "expiration",
                  "taker",
                  "mediator",
                  "loggedMediator",
                  "action",
                ]}
                onOrder={(column: string, ascendent: boolean | undefined) =>
                  this.handleOrder(column, ascendent)
                }
              />
            </div>

            <div className="pendant-simulation-pagination">
              <Pagination
                page={page}
                limit={totalPages}
                callback={(page) => this.setPage(page)}
              />
            </div>
          </>
        )}
        {showInfoModal && (
          <InfoModal
            img={images.IconCheckboxBlue}
            mainText={infoModalMainText}
            onClickAccept={() => infoModalOnClickAccept()}
            buttonText="Aceptar"
            close={() => this.closeModal()}
            active={showInfoModal}
          />
        )}
      </PendantSimulationContainer>
    );
  }
}

export default withGeneral(withDynamicProducts(withCoverages(withRouter(PendantSimulation))));
