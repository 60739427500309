// React
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
// Assets
import { images } from "../../assets";
// Components
import MainButton from "../main-button";
import InsuredCard from "../insured-card/insured-card";
import { HealthHireMenuContainer } from "./gip-hire-menu-style";
import { GipProducts } from "../../constants/optionsLifePolicy";
import { withPolicy } from "../../context/mixin/with-policy";
export enum Steps {
    DatosTomador = 1,
    DatosAsegurados = 2,
    //Garantias = 3,
    // DocumentacionRequerida = 3,
    ProteccionDatos = 3,
    Prima = 4,
    Cuestionarios = 5,
    MediosPago = 6,
    DetallePrevioContratacion = 7
}

export enum SimulationSteps {
    DatosSeguro = 1,
    DatosRiesgo = 2,
    Precio = 3,
    Resumen = 4
}

interface HealthHireMenuProps extends RouteComponentProps<any> {
    // Required
    initialCurrentStep: Steps,
    initialInsureds: any[], // => { name?: string, lastname1?: string, lastname2?: string, [x: string]: any }
    codMediador: string,
    selectedMediator?: string,
    policyType: string,
    // Optional
    disableSubButtons?: boolean,
    currentNumInsured?: number,
    isSignedDocument?: boolean;
    isHired?: boolean;
    disableSignButton?: boolean;
    physicalSignature?: boolean;
    isReadOnly?: boolean;
    insuredSaved?: boolean;
    // Functions
    previousOnNavigation?: () => boolean,
    insuredOnAdd?: (insuredSaved: any) => Promise<void>,
    insuredOnRemove?: (index: number) => void,
    insuredOnEdit?: (index: number) => void,
    insuredOnSave?: () => void,
    questionnaireOnEdit?: (index: number) => void,
    questionnaireOnSave?: () => void,
    resumeOnHiring?: () => void,
    downloadPrecontractualDocument?: () => void,
    downloadGIPPrecontractualDocument?: () => void,
    sendMailPrecontractualDocument?: () => void,
    signPrecontractualDocument?: () => void,
    unlockForm?: () => void,
    policy: any,
}

interface GipHireMenuState {
    currentStep: Steps,
    insureds: any[], // => { name?: string, lastname1?: string, lastname2?: string, [x: string]: any }
    showInsuredsOnDatosAsegurados: boolean,
    showInsuredsOnCuestionarios: boolean,
}

export class GipHireMenuHelper {
    static getStepFromNavigationRoute(path: string, product: string): Steps {
        if (path.indexOf(`/contratacion/nueva/${product}/tomador`) !== -1) return Steps.DatosTomador;
        else if (path.indexOf(`/contratacion/nueva/${product}/asegurados`) !== -1) return Steps.DatosAsegurados;
        //else if (path.indexOf(`/contratacion/nueva/${product}/garantias`) !== -1) return Steps.Garantias;
        // else if (path.indexOf(`/contratacion/nueva/${product}/documentacion-requerida`) !== -1) return Steps.DocumentacionRequerida;
        else if (path.indexOf(`/contratacion/nueva/${product}/proteccion-datos`) !== -1) return Steps.ProteccionDatos;
        else if (path.indexOf(`/contratacion/nueva/${product}/precio`) !== -1) return Steps.Prima;
        else if (path.indexOf(`/contratacion/nueva/${product}/cuestionarios-salud`) !== -1) return Steps.Cuestionarios;
        else if (path.indexOf(`/contratacion/nueva/${product}/medios-pago`) !== -1) return Steps.MediosPago;
        else if (path.indexOf(`/contratacion/nueva/${product}/resultado`) !== -1) return Steps.DetallePrevioContratacion;
        else return Steps.DatosTomador;
    }

    static getSimulationStepFromNavigationRoute(path: string, product: string): SimulationSteps {
        if (path.indexOf(`/simulacion/nueva/salud/${product}/mediador`) !== -1) return SimulationSteps.DatosSeguro;
        else if (path.indexOf(`/simulacion/nueva/salud/${product}/asegurados`) !== -1) return SimulationSteps.DatosRiesgo;
        else if (path.indexOf(`/simulacion/nueva/salud/${product}/precios`) !== -1) return SimulationSteps.Precio;
        else if (path.indexOf(`/simulacion/nueva/salud/${product}/resultado`) !== -1) return SimulationSteps.Resumen;
        else return SimulationSteps.DatosSeguro;
    }

    static getStepNavigationPath(step: Steps, insuredsLength: number, product: string): string {
        let currentInsured = insuredsLength - 1;
        currentInsured = currentInsured < 0 ? 0 : currentInsured;
        switch (step) {
            case Steps.DatosTomador: return `/contratacion/nueva/${product}/tomador`;
            case Steps.DatosAsegurados: return insuredsLength ?
                `/contratacion/nueva/${product}/asegurados/` :
                `/contratacion/nueva/${product}/asegurados/${currentInsured}`;
            // case Steps.Garantias: return `/contratacion/nueva/${product}/garantias`;
            // case Steps.DocumentacionRequerida: return `/contratacion/nueva/${product}/documentacion-requerida`;
            case Steps.ProteccionDatos: return `/contratacion/nueva/${product}/proteccion-datos`;
            case Steps.Prima: return `/contratacion/nueva/${product}/precio`;
            case Steps.Cuestionarios: return `/contratacion/nueva/${product}/cuestionarios-salud/0`;
            case Steps.MediosPago: return `/contratacion/nueva/${product}/medios-pago`;
            case Steps.DetallePrevioContratacion: return `/contratacion/nueva/${product}/resultado`;
            default: return "";
        }
    }

    static getSimulationStepNavigationPath(step: SimulationSteps, product: string, resultId?: string): string {
        switch (step) {
            case SimulationSteps.DatosSeguro: return `/simulacion/nueva/salud/${product}/mediador`;
            case SimulationSteps.DatosRiesgo: return `/simulacion/nueva/salud/${product}/asegurados`;
            case SimulationSteps.Precio: return `/simulacion/nueva/salud/${product}/precios`;
            case SimulationSteps.Resumen: return `/simulacion/nueva/salud/${product}/resultado`;
            default: return "";
        }
    }

    static getCurrentProduct(product: string): GipProducts {
        switch (product) {
            case GipProducts.Momento: return GipProducts.Momento;
            case GipProducts.Proxima: return GipProducts.Proxima;
            case GipProducts.ProximaPlus: return GipProducts.ProximaPlus;
            case GipProducts.Integral100: return GipProducts.Integral100;
            case GipProducts.Integral180: return GipProducts.Integral180;
            case GipProducts.Integral240: return GipProducts.Integral240;
            default: return GipProducts.Proxima;
        }
    }

    static getPreviousStep(currentStep: Steps): Steps {
        try {
            return currentStep - 1;
        } catch {
            return Steps.DatosTomador;
        }
    }

    static getPreviousStepNavigationPath(currentStep: number, product: string): string {
        return this.getStepNavigationPath(this.getPreviousStep(currentStep), 1, product);
    }

    static getNextStep(currentStep: Steps): Steps {
        try {
            return currentStep + 1;
        } catch {
            return Steps.DetallePrevioContratacion;
        }
    }

    static getNextStepNavigationPath(currentStep: Steps, product: string): string {
        return this.getStepNavigationPath(this.getNextStep(currentStep), 1, product);
    }
}

class GipHireMenuLayout extends React.Component<HealthHireMenuProps, GipHireMenuState> {
    constructor(props: HealthHireMenuProps) {
        super(props);

        this.state = {
            currentStep: props.initialCurrentStep,
            insureds: props.initialInsureds,
            showInsuredsOnDatosAsegurados: props.initialCurrentStep === Steps.DatosAsegurados,
            showInsuredsOnCuestionarios: props.initialCurrentStep === Steps.Cuestionarios,
        };
    }

    componentDidMount() {
        this.props.history.listen((location, action) => {
            var step = GipHireMenuHelper.getStepFromNavigationRoute(location.pathname, this.props.policyType);
            this.setState({
                currentStep: step,
                showInsuredsOnDatosAsegurados: step === Steps.DatosAsegurados,
                showInsuredsOnCuestionarios: step === Steps.Cuestionarios,
            });
        });
    }

    getButtonCssName = (step: Steps): string => step === this.state.currentStep ? "" : "google"

    isMenuItemDisabled = (step: Steps): boolean => step > this.state.currentStep;

    isMenuItemSelected = (step: Steps): boolean => step === this.state.currentStep;

    handleNavigation(step: Steps): void {
        if (step === this.state.currentStep)
            return;

        let doNavigation = Steps.DatosAsegurados || Steps.Cuestionarios || !this.props.previousOnNavigation || this.props.previousOnNavigation()
        if (doNavigation) {

            switch (step) {
                case Steps.DatosAsegurados:
                    this.setState({ showInsuredsOnDatosAsegurados: true, showInsuredsOnCuestionarios: false })
                    break;
                case Steps.Cuestionarios:
                    this.setState({ showInsuredsOnDatosAsegurados: false, showInsuredsOnCuestionarios: true })
                    break;
                default:
                    this.props.history.push(GipHireMenuHelper.getStepNavigationPath(step, this.state.insureds.length, this.props.policyType));
                    break;
            }

            if (step !== Steps.DatosAsegurados && step !== Steps.Cuestionarios)
                this.setState({ showInsuredsOnDatosAsegurados: false, showInsuredsOnCuestionarios: false })
        }
    }

    tryDownloadPrecontractualDocument(): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.downloadPrecontractualDocument)
                this.props.downloadPrecontractualDocument();
        }
    }

    tryDownloadGipPrecontractualDocument(): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.downloadGIPPrecontractualDocument)
                this.props.downloadGIPPrecontractualDocument();
        }
    }
    tyrSendMailPrecontractualDocument() {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.sendMailPrecontractualDocument)
                this.props.sendMailPrecontractualDocument();
        }
    }

    trySignPrecontractualDocument() {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.signPrecontractualDocument)
                this.props.signPrecontractualDocument();
        }
    }

    tryUnlockForm() {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.unlockForm)
                this.props.unlockForm();
        }
    }

    tryResumeOnHiring(): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.resumeOnHiring)
                this.props.resumeOnHiring();
        }
    }

    tryQuestionnaireOnSave(): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.questionnaireOnSave)
                this.props.questionnaireOnSave();
        }
    }

    async tryInsuredOnAdd(): Promise<void> {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.insuredOnAdd)
                await this.props.insuredOnAdd(this.props.insuredSaved);
        }
    }

    tryInsuredOnSave(): void {
        if (this.props.insuredOnSave)
            this.props.insuredOnSave()
    }

    isSubMenuItemSelected = (step: Steps, numInsured: number): boolean => step === this.state.currentStep && numInsured === (this.props.currentNumInsured ? this.props.currentNumInsured : 0);

    tryRemoveInsured(event: any, step: Steps, numInsured: number): void {
        event.stopPropagation();
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (step === Steps.DatosAsegurados && this.props.insuredOnRemove)
                this.props.insuredOnRemove(numInsured)
        }
    }

    tryGoToDetail(step: Steps, numInsured: number): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (step === Steps.DatosAsegurados && this.props.insuredOnEdit)
                this.props.insuredOnEdit(numInsured)
            else if (step === Steps.Cuestionarios && this.props.questionnaireOnEdit)
                this.props.questionnaireOnEdit(numInsured)
        }
    }

    renderInsuredCards(renderForStep: Steps, showRemoveIco: boolean = true, divClickGoToDetail: boolean = false): JSX.Element[] {
        let elements: JSX.Element[] = [];
        if (this.state.insureds) {
            for (let i = 0; i < this.state.insureds.length; i++) {
                elements.push(
                    <li>
                        <InsuredCard
                            selected={this.isSubMenuItemSelected(renderForStep, i)}
                            insuredMember={this.state.insureds[i]}
                            removeInsured={!this.props.disableSubButtons && showRemoveIco ? (event: any) => this.tryRemoveInsured(event, renderForStep, i) : undefined}
                            goToDetail={() => this.tryGoToDetail(renderForStep, i)}
                            textSubtitle={"Asegurado"}
                            divClickGoToDetail={divClickGoToDetail}
                        />
                    </li>
                );
            }
        }
        return elements;
    }

    getMainButtonMenu(title: string, step: Steps): JSX.Element {
        return <MainButton
            text={title}
            type={this.getButtonCssName(step)}
            withTab={true}
            disabled={this.isMenuItemDisabled(step)}
            onClick={() => this.handleNavigation(step)}
        />;
    }

    getMainButtonSubMenu(title: string, step: Steps, onClick: Function, type: string | undefined = "full-light", image?: string | undefined, disabled: boolean | undefined = undefined): JSX.Element {
        return <MainButton
            text={title}
            icon={image !== undefined}
            iconImg={image}
            type={type}
            withTab={true}
            disabled={disabled !== undefined ? disabled : this.isMenuItemDisabled(step)}
            onClick={() => onClick()}
        />
    }

    render() {
        const { policy } = this.props
        const productName = policy.product.products_name
        const productCardName = policy.product.products_cardName
        return (
            <HealthHireMenuContainer>
                <div className="inflow-sidebar-type">
                    <img src={images.MiniWavePoliciePymes} alt="" />
                    <div className="inflow-sidebar-type-wrapper">
                        <div className="inflow-sidebar-type-text">
                            <p>PÓLIZA {productCardName}</p>
                        </div>
                        <div className="inflow-sidebar-type-title">
                            <p>{productName}</p>
                        </div>
                    </div>
                </div>
                <div className="cod-mediador">CÓD. MEDIADOR<br /><span>{this.props.selectedMediator ? this.props.selectedMediator : this.props.codMediador}</span></div>
                <ul className="ulMenu">
                    <li>{this.getMainButtonMenu("DATOS DEL TOMADOR", Steps.DatosTomador)}</li>
                    <li>
                        {this.getMainButtonMenu("DATOS DE LOS ASEGURADOS", Steps.DatosAsegurados)}
                        <ul className="ulSubmenu" style={{ "display": (this.state.showInsuredsOnDatosAsegurados ? "block" : "none") }}>
                            {
                                !this.props.disableSubButtons && this.state.currentStep === Steps.DatosAsegurados &&
                                <li>{this.getMainButtonSubMenu("GUARDAR ASEGURADO", Steps.DatosAsegurados, () => this.tryInsuredOnSave(), "full-light", images.IconSaveWhite)}</li>
                            }
                            {this.renderInsuredCards(Steps.DatosAsegurados, this.state.currentStep === Steps.DatosAsegurados, true)}
                            {
                                !this.props.disableSubButtons && this.state.insureds.length < 4 &&
                                <li>{this.getMainButtonSubMenu("NUEVO ASEGURADO", Steps.DatosAsegurados, () => this.tryInsuredOnAdd(), "full-light", images.PlusWhite, this.isMenuItemDisabled(Steps.DatosAsegurados) || this.state.insureds.length === 10)}</li>
                            }
                        </ul>
                    </li>
                    {/* <li>{this.getMainButtonMenu("GARANTÍAS", Steps.Garantias)}</li> */}
                    {/* <li>{this.getMainButtonMenu("DOCUMENTACIÓN REQUERIDA", Steps.DocumentacionRequerida)}</li> */}
                    <li>{this.getMainButtonMenu("PROTECCIÓN DE DATOS", Steps.ProteccionDatos)}</li>
                    <li>{this.getMainButtonMenu("PRIMA", Steps.Prima)}</li>
                    <li>
                        {this.getMainButtonMenu("CUESTIONARIOS", Steps.Cuestionarios)}
                        <ul className="ulSubmenu" style={{ "display": (this.state.showInsuredsOnCuestionarios ? "block" : "none") }}>
                            <li>
                                {
                                    !this.props.disableSubButtons && this.state.currentStep === Steps.Cuestionarios &&
                                    this.getMainButtonSubMenu("GUARDAR CUESTIONARIO", Steps.Cuestionarios, () => this.tryQuestionnaireOnSave(), "full-light", images.IconUploadWhite)
                                }
                            </li>
                            {this.renderInsuredCards(Steps.Cuestionarios, false)}
                        </ul>
                    </li>
                    <li>{this.getMainButtonMenu("METODOS DE PAGO", Steps.MediosPago)}</li>
                    <li>
                        {this.getMainButtonMenu("DETALLE PREV. CONTRATACIÓN", Steps.DetallePrevioContratacion)}
                        <ul className="ulSubmenu" style={{ "display": (true ? "block" : "none") }}>
                            <li>
                                {
                                    this.state.currentStep === Steps.DetallePrevioContratacion &&
                                    this.getMainButtonSubMenu("DESCARGAR", Steps.DetallePrevioContratacion, () => this.tryDownloadGipPrecontractualDocument(), "full-light")
                                }
                                {
                                    this.state.currentStep === Steps.DetallePrevioContratacion &&
                                    this.getMainButtonSubMenu("ENVIAR POR EMAIL", Steps.DetallePrevioContratacion, () => this.tyrSendMailPrecontractualDocument(), "full-light")
                                }
                                {
                                    this.state.currentStep === Steps.DetallePrevioContratacion &&
                                    this.getMainButtonSubMenu(
                                        "FIRMA PRECONTRACTUAL",
                                        Steps.DetallePrevioContratacion,
                                        () => this.trySignPrecontractualDocument(),
                                        "full-light",
                                        undefined,
                                        this.props.physicalSignature || this.props.disableSignButton || this.props.isReadOnly)
                                }
                                {/* {
                                    this.props.isReadOnly &&
                                    this.getMainButtonSubMenu(
                                        "MODIFICAR SOLICITUD", 
                                        Steps.DetallePrevioContratacion,
                                        () => this.tryUnlockForm(),
                                        "full-light",
                                        undefined,
                                        false)
                                } */}
                                {/* {
                                    this.state.currentStep === Steps.DetallePrevioContratacion &&
                                    this.getMainButtonSubMenu(
                                        "CONTRATAR",
                                        Steps.DetallePrevioContratacion,
                                        () => this.tryResumeOnHiring(),
                                        "full-light",
                                        undefined,
                                        !this.props.isSignedDocument || this.props.isHired)
                                } */}
                            </li>
                        </ul>
                    </li>
                </ul>
            </HealthHireMenuContainer>
        );
    }
}

export const GipHireMenu = withPolicy(withRouter(GipHireMenuLayout))
