import styled from 'styled-components';
import { colors } from "../../assets";

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const StyledTable = styled.table`
  width: 100%;
  white-space: nowrap;
  border-collapse: collapse;
`;

export const StyledHeader = styled.th`
  background-color: ${colors["PR-001-100"]};
  color: white;
  border: 1px solid white;
  padding: 20px;
`;

export const StyledCell = styled.td`
  border: 1px solid lightgray;
  padding: 20px;
`;

export const Icon = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 0px;
`;
