import styled from "styled-components";
import { InputBoxContainer } from "./../../../../components/input-box/input-box-style";
import { colors } from "./../../../../assets";
import { CalendarBoxContainer } from "../../../../components/calendar-box/calendar-box-style";

export const BeneficiariesContainer = styled.div`
  .legal-heirs-options {
    display: flex;
    margin-bottom: 30px;
    flex-direction: row;   

    &__item {
      margin-right: 20px;
      flex-grow: 1;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .legal-heirs-description {
    margin-bottom: 50px;
  }

  .legal-heirs-button {
    margin-bottom: 40px;
    max-width: 240px;
  }

  .legal-heirs-irrevocable-container {
    ${InputBoxContainer} {
      input {       
        border-radius: 3px;
        padding-top: 9px;
        padding-bottom: 9px;
        top: 45%;
      }
      .input-box-main-field {
        &:before {
          top: 45%;
        }
      }
    }

    ${CalendarBoxContainer}{
      .input-box-main-field {        
        border-radius: 3px;
      }
    }
  }

  .legal-heirs-irrevocable-container-title {
    margin-bottom: 19px;
    color: ${colors["PR-001-100"]};
  }

  .legal-heirs-irrevocable-container-description {
    color: ${colors["TX-001-80"]};
    margin-bottom: 40px;
  }

  .legal-heirs-irrevocable-container-form {
    margin-bottom: 50px;
    &__row {
      display: flex;
      margin-bottom: 38px;
      justify-content: space-between;


      &:last-child {
        margin-bottom: 0;
      }

      &__item {
        width: calc((100% - 40px) / 2);
      }
    }
  }

  .personal-data-buttons {
    display: flex;
    padding: 0 40px;  
    border-radius: 3px; 
    float: left;

    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  .beneficiaries-insured-item {
    padding: 20px 40px 0 40px;
    margin-bottom: 24px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
    
    &.float {
      width: 100%;
      display: block;
      float: left;
    }
  }

  .beneficiaries-insured-special-title {
    color: ${colors["PR-001-100"]};
    border-bottom: 1px solid ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 20px;
  }
`;
