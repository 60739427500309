import styled from "styled-components";
import { colors } from "../../../assets";

export const Button = styled.button`
  background-color: ${colors["PR-001-100"]};
  color: white;
  padding: 13px 13px;
  border: none;
  border-radius: 5px;
  font-size: medium;
  text-transform: uppercase;

  &:hover {
    background-color: ${colors["HV-Text"]};
    cursor: pointer; // Cambia el cursor a un puntero
  }
`;