import GeneralServices from "./GeneralServices";

class liquidationsServices {
  getLiquidations = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `liquidations${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return response.data;
    }
    catch (err) {
      return { items: [], count: 0 };
    }
  }

  getAllLiquidations = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: 'liquidations',
      });
      return response.data;
    }
    catch (err) {
      return { items: [], count: 0 };
    }
  }

  getYears = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `liquidations/years/all${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }


}

export default new liquidationsServices();