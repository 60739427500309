import styled from 'styled-components';
import { colors } from '../../assets';

export const InputCounterContainer = styled.div`
  position: relative;
  font-family: "museo sans";

  &.required {
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #e40202;
    }
  }

  .counter-field-topbar {
    padding-left: 20px;
    margin-bottom: 7px;
    color: #004f8b;
    font-size: 16px;
    font-weight: 500;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .counter-field-main {
    display: flex;
    height: 40px;
  }

  .counter-button {
    height: 20px;
    background: ${colors['SU-001-100']};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    min-width: 40px;
    width: 40px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;

    &:hover {
      background: #42b99d;
    }

    img {
      height: 15px;
    }
    
  }

  .counter-button-radius-top {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .counter-button-radius-bottom {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .invert {
      img {
        transform: rotate(180deg);
      }
    }

  .counter-button-container {
    display: block;
  }

  &.isBig {
    .counter-field-main {     
      .counter-input-field {       
        div {
          font-size: 16px;
          width: 10%;
        } 
      }
    } 
  }

  .counter-input-field {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f4f4;
    color: #004f8b;
    div {
      font-size: 16px;
      width: 20%;
      /* width: 68px; */
    }
    input {
      width: 100%;
      padding-left: 5%;
      height: 100%;
      text-align: right;
      color: #004f8b;
      text-align: center;
      font-size: 16px;
      margin-right: 2px;
      &::after {
        content: 'cm';
      }
      &::placeholder {
        color: #cccccc;
      }
    }
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type=number] {
      -moz-appearance:textfield;
  }

  .input-counter-error {
    color: ${colors["errorColor"]};
    margin: 12px 0 0 10px;

    p {
      color: ${colors["errorColor"]};
    }
  }

  &.disabled {
    pointer-events: none;

    .counter-field-topbar {
      color: ${colors["TX-001-30"]};
    }

    .counter-input-field {
      background: ${colors["FL-001-Disabled"]};
      color: ${colors["TX-001-30"]};

      input {
        color: ${colors["TX-001-30"]};
      }

      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${colors["TX-001-30"]};
      }
    }

    .counter-button {
      background: ${colors["TX-001-30"]};
    }
  }
`;
