import GeneralServices from "./GeneralServices";

class ConstantsServices {
  getCountries = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: `senda/countries${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  getProfessions = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: `senda/professions${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  getLifeQuestionnaires = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: 'senda/lifeQuestionnaires',
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  getActivities = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: `senda/riskActivities${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }


  getPetBreeds = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: `senda/petBreeds${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  getCompanies = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: (args.loader && args.loader) === false ? false : true,
        //TODO: CAMBIAR ENDPOINT CUANDO EL BACK LO CREE
        endpoint: `hiring/getCompaniesList`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }
}

export default new ConstantsServices();