import React, { Component } from "react";
import { InsuredCardContainer } from "./insured-card-style";
import { withRouter, RouteComponentProps } from "react-router";
import { images } from "../../assets";
import Body02Text from "../components-text/body-02-text";

interface InsuredCardProps extends RouteComponentProps {
  className?: string;
  insuredMember: any;
  goToDetail?: Function;
  removeInsured?: Function;
  selected: boolean;
  textSubtitle?: string;
  divClickGoToDetail?: boolean;
}

class InsuredCard extends Component<InsuredCardProps, {}> {
  render() {
    const {
      className,
      divClickGoToDetail,
      goToDetail,
      insuredMember,
      removeInsured,
      selected,
      textSubtitle,
    } = this.props;

    return (
      <InsuredCardContainer
        className={`${className} ${selected ? "active" : ""}`.trim()}
      >
        {/* puede recibir clases ok y error para cambiar puntito de color  */}
        <div onClick={() => ((!removeInsured || divClickGoToDetail) && goToDetail) ? goToDetail() : null} className={"insured-card"} >
          <div className="insured-card-status"></div>
          <div className="insured-card-name">
            <Body02Text>
              <p>
                {insuredMember.name} {insuredMember.lastname1}{" "}
                {insuredMember.lastname2}
              </p>
            </Body02Text>
          </div>
          {insuredMember.type && (
            <div className="insured-card-type">
              <p>{insuredMember.type === "feline" ? "Gato" : "Perro"}</p>
            </div>
          )}
          {textSubtitle && (
            <div className="insured-card-type">
              <p>{textSubtitle}</p>
            </div>
          )}
          {goToDetail && (
            <div className="insured-card-details" onClick={() => goToDetail()}>
              <img src={images.IconEyeBlue} />
            </div>
          )}
          {/* al hacer click en este icono sale leftInfoModal, con clase error y el texto de seguro quieres borrar al asegurado?, menos el tomador que no se puede borrar */}
          {removeInsured && !selected && (
            <div className="insured-card-erase" onClick={(event) => removeInsured(event)}>
              <img src={images.IconEraseBlue} />
            </div>
          )}
        </div>
      </InsuredCardContainer >
    );
  }
}

export default withRouter(InsuredCard);
