import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import Header from "./components/header";
import MainSidebar from "./components/main-sidebar";
import Layout from "./components/layout";
import CollapsedSidebar from "./components/collapsed-sidebar";
import Home from "./containers/home";
import LoginLayout from "./containers/login/login-layout";
import Campaigns from "./containers/campaigns";
import Contact from "./containers/contact";
import Supplements from "./containers/supplements";
import Supplement from "./containers/supplements/supplement";

import ClientsPolicies from "./containers/clients/clients-policies";
import ClientsLiquidations from "./containers/clients/clients-liquidations";
import ClientsReceipts from "./containers/clients/clients-receipts";
import ClientsReports from "./containers/clients/clients-reports";
import CookiesPolicy from "./containers/cookies-policy";
import LegalPolicy from "./containers/legal-policy";
import Notifications from "./containers/notifications";

import DataProtection from "./containers/data-protection";
import DataConservation from "./containers/data-conservation";
import AdditionalPolicy from "./containers/aditional-policy";
import DetailClientsPolicies from "./containers/clients/clients-policies/detail-clients-policies";
import DetailClientReceipts from "./containers/clients/clients-receipts/detail-clients-receipts";

// Simulation
import PendantSimulation from "./containers/simulations/pendant-simulation/pendant-simulation-layout";
import DetailPendantSimulation from "./containers/simulations/pendant-simulation/detail-pendant-simulation/detail-pendant-simulation-layout";
import Simulation from "./containers/simulations/new-simulation";

//Hiring
import HiringPendant from "./containers/hirings/pendant-hiring/pendant-hiring-layout";
import NewHiringContainer from "./containers/hirings/new-hiring/new-hiring-container";
import HiringDetailPendantMain from "./containers/hirings/pendant-hiring/detail-pendant-hiring-container/detail-pendant-hiring-container";

import CampaignsDetail from "./containers/campaigns-detail";
import Permissions from "./containers/permissions";
import NotFoundLayout from "./components/not-found/not-found-layout";
import ScrollTop from "./components/scroll-top/scroll-top";
import Loader from "./components/loader";
import { GeneralContext, CoveragesContext, DynamicProductsContext, PayTypesContext } from "./context/constants";
import MediatorServices from "./services/MediatorServices";
import CampaignsConfig from "./containers/campaigns-config/campaigns-config";
import CampaignsConfigPoints from "./containers/campaigns-config/campaigns-config-points/campaigns-config-points";
import CampaignsCreate from "./containers/campaigns-config/campaigns-create/campaigns-create";
import CampaignsConfigDetail from "./containers/campaigns-config/campaigns-config-detail/campaigns-config-detail";
import CampaignsConfigParticipants from "./containers/campaigns-config/campaigns-config-participants/campaigns-config-participants";

import CoveragesServices from "./services/CoveragesServices";
import MainModal from "./components/main-modal";
import { images } from "./assets";
import DoneHiring from "./containers/hirings/done-hiring/done-hiring";
import ClientsEiac from "./containers/clients/clients-eiac";
import Cookies from "js-cookie";
import ContractPolicy from "./containers/contract-policy";
import ReactGA from 'react-ga';
import { roleNotAllowed, roleNotAllowedReports } from "./constants";
import ForbiddenLayout from "./components/forbidden/forbidden-layout";
import Footer from "./components/footer/footer";
import { IMediator } from "./models/mediator-model";
import { GestDoc } from "./services/GestDoc";
import AuthService, { IAuthService } from "./auth/AuthService";
import SigninCallback from "./components/auth/SigninCallback";
import LogoutCallback from "./components/auth/LogoutCallback";
import ExternalAuth from "./components/auth/ocaso/ExternalAuth";
import { REACT_APP_LOCKED, SERVICES_URL } from "./services/url";
import Faqs from "./containers/faqs";
import ProductServices from "./services/ProductServices";
import RolPortalServices from "./services/RolPortalServices";
import PayTypesServices from "./services/PayTypesServices";
import TravelCountriesServices from "./services/TravelCountriesServices";
import ClientsInvoices from "./containers/clients/clients-invoices";
import FaqsOcaso from "./containers/faqs-ocaso";
import Comparator from "./containers/comparator/health-comparator";
import CommunicationsManager from "./containers/communications/manager";
import CommunicationsScreen from "./containers/communications/screen";
import NewInsuredStepperForm from "./containers/forms/supplements/new-insured/newInsuredStepperForm";
import BasicDataForm from "./containers/forms/supplements/basic-data/basic-data-form";
import ClearCache from "./ClearCache";

const ADMIN = "ADMIN";

interface AppState {
  loading: boolean;
  isSlowProcess: boolean;
  loadingInit: boolean;
  user: any;
  mediator: IMediator;
  isLogged: boolean;
  is404: boolean;
  is403: boolean;
  coverages: { [key: string]: string };
  showScreenAlert: boolean;
  showDelegationAlert: boolean;
  isCampaignConfig: boolean;
  acceptedCookies: boolean;
  hasInformativeNote: boolean;
  productsByAreaName: any;
  allProducts: any;
  allPayTypes: any;
  travelCountries: any;
}

class App extends Component<{}, AppState> {
  state: AppState;
  authService: IAuthService = new AuthService();

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      isSlowProcess: false,
      loadingInit: false,
      user: localStorage.loggedUser ? JSON.parse(localStorage.loggedUser) : "",
      mediator: localStorage.loggedMediator ? JSON.parse(localStorage.loggedMediator) : "",
      isLogged: localStorage.accessToken ? true : false,
      is404: false,
      is403: false,
      coverages: {},
      showScreenAlert: false,
      showDelegationAlert: false,
      isCampaignConfig: false,
      acceptedCookies: !!localStorage.getItem("acceptedCookies"),
      hasInformativeNote: GestDoc.getNotaInformativaNotLifeMediadorLocalStorage() ? true : false,
      productsByAreaName: {},
      allProducts: {},
      allPayTypes: {},
      travelCountries: {},
    };

  }

  async componentDidMount() {
    const w: any = window;
    w.setLoader = this.setLoading;
    w.setIsSlowProcess = this.setIsSlowProcess;
    
    w.getIsLogged = this.getIsLogged;

    this.checkCookies();
    const isCampaignConfig = window.location.href.indexOf("campanhas") > -1;
    this.setState({ isCampaignConfig });
    !isCampaignConfig && this.getUser();
    if (window.screen.width < 650) {
      this.setState({ showScreenAlert: true });
    }
    if (
      !location.pathname.includes("/contratacion/nueva") &&
      !location.pathname.includes("/simulacion/nueva") &&
      !location.pathname.includes("/comparacion/nueva")
    ) {
      localStorage.removeItem("internalName");
    }
  }

  checkCookies = () => {
    if (Cookies.get('tracing_cookie') === 'true') {
      ReactGA.initialize('UA-18371539-19', {
        gaOptions: {
          siteSpeedSampleRate: 100
        },
      });
      ReactGA.set({ allowAdFeatures: 'false', page: window.location.pathname });
      ReactGA.pageview(window.location.pathname);
    }
  }

  async getCoverages() { }

  setLoading = (loading: boolean) => this.setState({ loading });

  setIsSlowProcess = (isSlowProcess: boolean) => this.setState({ isSlowProcess });

  setUser = (user: any, isLogged: boolean) =>
    this.setState({ user, isLogged }, () => isLogged && this.setCoverages());

  getUser = async () => {
    const { isLogged } = this.state;
    try {
      this.setState({ loadingInit: true }, async () => {
        if (isLogged) {
          const user = await MediatorServices.getProfile({
            setLoading: this.setLoading,
            loader: true,
          });

          if (Object.keys(user).length === 0 || localStorage.servicesError) {
            this.closeSession();
          } else {
            if (user.code) {

              const rolesPortal = await RolPortalServices.getInfo({
                setLoading: this.setLoading,
                loader: true,
                mediatorCode: user.code,
              });

              if (rolesPortal && rolesPortal.length > 0) {
                for (let i = 0; i < rolesPortal.length; i++) {
                  if (!user.roles.includes(rolesPortal[i].rolPortal)) {
                    user.roles.push(rolesPortal[i].rolPortal)
                  }
                }

              }

              const mediator = await MediatorServices.getMediatorInfo({
                setLoading: this.setLoading,
                loader: true,
                mediatorCode: user.code,
              });

              mediator.relatedMediators = [];
              const promisesRelatedMediators: Promise<any>[] = [];

              if (mediator && mediator.relatedKeys && mediator.relatedKeys.relatedKeysList) {
                mediator.relatedKeys.relatedKeysList.forEach(async (code) => {
                  promisesRelatedMediators.push(MediatorServices.getMediatorInfo({
                    setLoading: this.setLoading,
                    loader: false,
                    mediatorCode: code,
                  }));
                });
              }

              await Promise.all(promisesRelatedMediators).then(
                data => mediator.relatedMediators = data
              ).catch(err => console.error(err));

              this.setState({ user, mediator }, async () => {
                await this.setCoverages();
                await this.setProductsByAreaName();
                await this.setAllProducts();
                await this.setPayTypes();
                await this.setTravelCountries();
                this.setState({ loadingInit: false, loading: false });
              });
              
              localStorage.setItem("loggedUser", JSON.stringify(user));
              localStorage.setItem("loggedMediator", JSON.stringify(mediator));
              if (localStorage.getItem("isLogin") === 'true') {
                localStorage.removeItem("isLogin");
                if (!user.delegation) this.setState({ showDelegationAlert: true });
              }
            } else {
              const rolesPortal = await RolPortalServices.getInfo({
                setLoading: this.setLoading,
                loader: true,
                mediatorCode: user.user,
              });
              if (rolesPortal && rolesPortal.length > 0) {
                for (let i = 0; i < rolesPortal.length; i++) {                
                  if (!user.roles.includes(rolesPortal[i].rolPortal)) {
                    user.roles.push(rolesPortal[i].rolPortal)
                  }
                }

              }
              this.setState({ loadingInit: false, loading: false, user });
              localStorage.setItem("loggedUser", JSON.stringify(user));
            }
          }
        }
        else this.setState({ loadingInit: false, loading: false });
      });
    } catch (err) { this.setState({ loadingInit: false, loading: false }); }
  };

  closeSession = () => {
    let isOcasoIDP = false;
    try {
      const jwt = require('jsonwebtoken');
      const { idp } = jwt.decode(localStorage.accessToken)
      isOcasoIDP = (idp === "ocaso");
    } catch (error) { console.log(error) }

    this.cleanLocalStorage();

    if (window.location.pathname !== "/" &&
      window.location.pathname.indexOf("aviso-legal") === -1 &&
      window.location.pathname.indexOf("politica-cookies") === -1 &&
      window.location.pathname.indexOf("politica-proteccion-datos") === -1 &&
      window.location.pathname.indexOf("politica-conservacion-datos") === -1 &&
      window.location.pathname.indexOf("politica-adicional-rgpd") === -1) {
      window.location.href = (isOcasoIDP) ? (SERVICES_URL + "auth/loginOcaso") : window.location.origin;
    }
    else if (isOcasoIDP) window.location.href = (SERVICES_URL + "auth/loginOcaso");
    else window.location.reload();
  }

  cleanLocalStorage = () => {
    const cookiesStorage = localStorage.getItem("acceptedCookies");
    localStorage.clear();
    if (cookiesStorage) localStorage.setItem("acceptedCookies", cookiesStorage);
  }

  setIsLogged = (isLogged: boolean) =>
    this.setState(
      { isLogged },
      () => isLogged && !this.state.isCampaignConfig && this.setCoverages()
    );

  getIsLogged = () => this.state.isLogged;

  setIs404 = (is404: boolean) => this.setState({ is404 });

  setIs403 = (is403: boolean) => this.setState({ is403 });

  setCoverages() {
    const { isCampaignConfig } = this.state;
    if (isCampaignConfig) return;

    return new Promise((res, rej) => {
      CoveragesServices.getProductsCoverages({
        setLoading: () => { },
        loader: false,
        ignoreSetFalse: true,
      })
        .then((resp: any) => {
          this.setState({ coverages: resp.data });
          res("");
        })
        .catch((err) => rej(err));
    })
  }

  setHasInformativeNote = (hasInformativeNote: boolean) =>
    this.setState({ hasInformativeNote }, () => hasInformativeNote);

  setProductsByAreaName = async () => {
    const args = {
      loader: true,
      setLoading: () => { }
    };
    const productsByAreaName = await ProductServices.getProductsByAreaName(args)
    this.setState({ productsByAreaName })
  }

  setAllProducts = async () => {
    const args = {
      loader: true,
      setLoading: () => { }
    };
    const allProducts = await ProductServices.getAllProducts(args)
    this.setState({ allProducts })
  }

  setPayTypes = async () => {
    const args = {
      loader: true,
      setLoading: () => { }
    };
    const allPayTypes = await PayTypesServices.getAllPayTypes(args)
    this.setState({ allPayTypes })
  }

  setTravelCountries = async () => {
    const args = {
      loader: true,
      setLoading: () => { }
    };
    const travelCountries = await TravelCountriesServices.getAllTravelCountries(args)
    this.setState({ travelCountries })
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.isLogged !== this.state.isLogged) {
      this.getUser()
    }
  }

  render() {
    const {
      isLogged,
      loading,
      isSlowProcess,
      loadingInit,
      user,
      mediator,
      is404,
      coverages,
      showScreenAlert,
      showDelegationAlert,
      isCampaignConfig,
      acceptedCookies,
      is403,
      hasInformativeNote,
      productsByAreaName,
      allProducts,
      allPayTypes,
      travelCountries
    } = this.state;
    const isLocalhost = window.location.href.indexOf("localhost:30") > -1;
    return (
      <>
        <Router>

          <ScrollTop>
            <GeneralContext.Provider
              value={{
                isCampaignConfiguration: isCampaignConfig,
                loading: loading,
                isLogged: isLogged,
                user: user,
                mediator: mediator,
                is404: is404,
                is403: is403,
                hasInformativeNote: hasInformativeNote,
                setLoading: this.setLoading,
                setUser: this.setUser,
                setIsLogged: this.setIsLogged,
                setIs404: this.setIs404,
                setIs403: this.setIs403,
                setHasInformativeNote: this.setHasInformativeNote,
              }}
            >
              <DynamicProductsContext.Provider
                value={{
                  productsByAreaName: productsByAreaName,
                  allProducts: allProducts,
                  checkProductArea: (area: string, internalName: string) => ProductServices.checkProductArea(area, internalName, productsByAreaName),
                  travelCountries: travelCountries
                }}
              >
                <CoveragesContext.Provider
                  value={{
                    coverages,
                  }}
                >
                  <PayTypesContext.Provider
                    value={{
                      allPayTypes,
                    }}
                  >
                    <div className={`${!isLogged ? "is-login-main" : ""}`}>
                      <Layout>
                        {/* <BlockResponsive /> */}
                        { !REACT_APP_LOCKED && isLogged && (
                          <>
                            <Header />
                            <MainSidebar />
                            <CollapsedSidebar />
                          </>
                        )}
                        { REACT_APP_LOCKED && <LoginLayout isLocked={true}/> }

                        { !REACT_APP_LOCKED && 
                          <>
                            {showScreenAlert && (
                              <MainModal
                                mainTitle={"¡Pantalla inadecuada!"}
                                buttonText={"Cerrar"}
                                centered={true}
                                active={true}
                                img={images.IconWarningYellow}
                                className={"warning"}
                                close={() => this.setState({ showScreenAlert: false })}
                                onClickAccept={() =>
                                  this.setState({ showScreenAlert: false })
                                }
                              />
                            )}

                            { showDelegationAlert && (
                              <MainModal
                                mainTitle={`Su usuario, ${user.user}, no tiene delegación asignada en Senda. Por favor, contacte con Soporte para su asignación.`}
                                buttonText={"Aceptar"}
                                centered={true}
                                active={true}
                                hideIcon={true}
                                close={ () => this.setState({ showDelegationAlert: false }) }
                                onClickAccept={() => this.setState({ showDelegationAlert: false }) }
                              />
                            )}

                            {isLogged && !isCampaignConfig ? (
                              <Switch>
                                <Route path="/logout-complete" render={() => <LogoutCallback authService={this.authService} />} />
                                <Route path="/contratacion/nueva" component={NewHiringContainer} />
                                <Route path="/contratacion/pendiente/detalle/:mediator/:id" component={HiringDetailPendantMain} />
                                <Route exact path="/contratacion/realizada" component={DoneHiring} />
                                <Route exact path="/contratacion/pendiente" component={HiringPendant} />
                                <Route path="/simulacion/nueva" component={Simulation} />
                                <Route exact path="/simulacion/pendiente" render={() => (<PendantSimulation {...this.props} isExpired={false} />)} />
                                <Route exact path="/simulacion/expirada" render={() => (<PendantSimulation {...this.props} isExpired={true} />)} />
                                <Route path="/simulacion/pendiente/detalle/:mediator/:id" component={DetailPendantSimulation} />
                                <Route path="/polizas-clientes/detalle/:mediator/:id/:order" component={DetailClientsPolicies} />
                                <Route path="/polizas-clientes" component={ClientsPolicies} mediator={this.state.mediator} />
                                <Route path="/liquidaciones-clientes" component={ClientsLiquidations} />
                                <Route path="/recibos-clientes/detalle/:mediator/:anio/:numRecibo" component={DetailClientReceipts} />
                                <Route path="/recibos-clientes/detalle/:mediator/:numRecibo" component={DetailClientReceipts} />
                                <Route path="/recibos-clientes" component={ClientsReceipts} mediator={this.state.mediator} />
                                {user.roles ? (!user.roles.includes(roleNotAllowed) && mediator.mediatorCode) &&
                                  <Route path="/ficheros-eiac" component={ClientsEiac} mediator={this.state.mediator} />
                                  : mediator.mediatorCode &&
                                  <Route path="/ficheros-eiac" component={ClientsEiac} mediator={this.state.mediator} />
                                }
                                {/* { user.roles && !user.roles.includes(roleNotAllowed) && mediator.mediatorCode &&
                                  <Route path="/facturas" component={ClientsInvoices} mediator={this.state.mediator} />
                                } */}
                                {
                                  user.roles && !user.roles.includes(roleNotAllowedReports) && mediator.mediatorCode &&
                                  <Route path="/informes" component={ClientsReports} mediator={this.state.mediator} />
                                }
                                {
                                  user.roles && user.roles.includes(ADMIN) &&
                                  <Route path="/permisos" component={Permissions} />
                                }
                                {/* Nuevos formularios de suplementos */}
                                <Route path="/suplemento/alta-asegurado" component={NewInsuredStepperForm} />
                                <Route path="/suplemento/datos-basicos" component={BasicDataForm} />

                                <Route path="/campañas/detalle/:id" component={CampaignsDetail} />
                                <Route path="/campañas" component={Campaigns} />
                                <Route path="/suplementos" component={Supplements} />
                                <Route path="/suplemento/:param1/:param2" component={Supplement} />
                                <Route path="/suplemento/:param1" component={Supplement} />
                                
                                <Route path="/gestor-notificaciones" component={CommunicationsManager} />
                                <Route path="/panel-notificaciones" component={CommunicationsScreen} />
                                <Route path="/preguntas-frecuentes" component={Faqs} />
                                <Route path="/preguntas-ocaso" component={FaqsOcaso} />

                                <Route path="/contacto" component={Contact} />
                                <Route path="/aviso-contratacion" render={() => (<ContractPolicy user={user} setLoading={this.setLoading} />)} />
                                <Route path="/notificaciones" component={Notifications} />
                                {/* <Route path="/asisa" component={Asisa} /> */}
                                <Route path="/aviso-legal" component={LegalPolicy} />
                                <Route path="/politica-cookies" component={CookiesPolicy} />
                                <Route path="/politica-proteccion-datos" component={DataProtection} />
                                <Route path="/politica-conservacion-datos" component={DataConservation} />
                                <Route path="/politica-adicional-rgpd" component={AdditionalPolicy} />

                                {/* comparador */}
                                <Route path="/comparacion/nueva" component={Comparator} />

                                <Route path="/403" component={ForbiddenLayout} />
                                <Route path="/external" component={ExternalAuth} />
                                <Route exact path="/" component={Home} />
                                <Route path="/" component={NotFoundLayout} />
                              </Switch>
                            ) : isLogged && !hasInformativeNote && !isCampaignConfig ? (
                              <Switch>
                                <Route path="/logout-complete" render={() => <LogoutCallback authService={this.authService} />} />
                                <Route path="/403" component={ForbiddenLayout} />
                                <Route path="/external" component={ExternalAuth} />
                                <Route exact path="/" component={Home} />
                                <Route path="/" component={Home} />
                              </Switch>
                            ) : isLogged && isCampaignConfig ? (
                              <Switch>
                                <Route path="/logout-complete" render={() => <LogoutCallback authService={this.authService} />} />
                                <Route exact path="/config-campañas" component={CampaignsConfig} />
                                <Route exact path="/config-campañas/detail/:id/participants" component={CampaignsConfigParticipants} />
                                <Route exact path="/config-campañas/detail/:id" component={CampaignsConfigDetail} />
                                <Route exact path="/config-campañas/detail/:id/:editedCampaign" component={CampaignsConfigDetail} />
                                <Route exact path="/config-campañas/edit/:id" component={CampaignsCreate} />
                                <Route exact path="/config-campañas/create" component={CampaignsCreate} />
                                <Route exact path="/config-puntos" component={CampaignsConfigPoints} />
                                <Route path="/external" component={ExternalAuth} />
                                <Route exact path="/" component={CampaignsConfig} />
                              </Switch>
                            ) : (
                              <Switch>
                                <Route path="/aviso-legal" component={LegalPolicy} />
                                <Route path="/politica-cookies" component={CookiesPolicy} />
                                <Route path="/politica-proteccion-datos" component={DataProtection} />
                                <Route path="/politica-conservacion-datos" component={DataConservation} />
                                <Route path="/politica-adicional-rgpd" component={AdditionalPolicy} />
                                <Route path="/signin-callback" render={() => <SigninCallback authService={this.authService} />} />
                                <Route path="/external" component={ExternalAuth} />
                                <Route exact path="/" render={() => <LoginLayout authService={this.authService} />} />
                                <Route path="/" component={NotFoundLayout} />
                              </Switch>
                            )}
                          </>
                        }
                      </Layout>
                      {loadingInit && <Loader />}
                      {loading && <Loader isSlowProcess={isSlowProcess} />}
                      {/* <CookiesModal /> */}
                      { !REACT_APP_LOCKED &&  !isLogged && <Footer />}
                    </div>
                    <ClearCache />
                  </PayTypesContext.Provider>
                </CoveragesContext.Provider>
              </DynamicProductsContext.Provider>
            </GeneralContext.Provider>
          </ScrollTop>
        </Router>
      </>
    );
  }
}

export default App;
