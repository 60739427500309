import React from "react";
import { CampaignsCreateContainer } from "./campaigns-create-style";
import Title02Text from "../../../components/components-text/title-02-text";
import HeadsTitleText from "../../../components/components-text/heads-title-text";
import GuideLine from "../../../components/guide-line";
import MainButton from "../../../components/main-button";
import CampaingsCreateStep0, {
  ICampaingsCreateStep0State
} from "./campaigns-create-steps/campaings-create-step-0";
import CampaingsCreateStep1, {
  ICampaingsCreateStep1State
} from "./campaigns-create-steps/campaings-create-step-1";
import CampaingsCreateStep2, {
  ICampaingsCreateStep2State
} from "./campaigns-create-steps/campaings-create-step-2";
import CampaingsCreateStep3, {
  ICampaingsCreateStep3State
} from "./campaigns-create-steps/campaings-create-step-3";
import CampaingsCreateStep4, {
  ICampaingsCreateStep4State
} from "./campaigns-create-steps/campaings-create-step-4";
import CampaingsCreateStep5, {
  ICampaingsCreateStep5State
} from "./campaigns-create-steps/campaings-create-step-5";
import CampaignsConfigurationServices from "../../../services/CampaignsConfigurationServices";
import moment from "moment";
import { ICondition } from "./modals/modal-add-condition";
import GoBackLayout from "../../../components/go-back/go-back-layout";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { CampaingsPrizeCard } from "./campaigns-prize-card/campaigns-prize-card";

interface ICampaignsCreateProps extends RouteComponentProps {
  match: any;
  history: any;
}

interface ICampaignsCreateState {
  step: number;
  goNext: boolean;
  disabledNext: boolean;
  editCampaignStatus?: string;
  stepsData: {
    [key: number]: {
      desc: string;
      state?: any;
    };
  };
}

class CampaignsCreate extends React.Component<
  ICampaignsCreateProps,
  ICampaignsCreateState
  > {
  constructor(props: ICampaignsCreateProps) {
    super(props);
    this.state = {
      step: 0,
      disabledNext: false,
      goNext: false,
      stepsData: {
        0: {
          desc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        },
        1: {
          desc:
            "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        },
        2: {
          desc:
            "Lorem ipsum dolor sit amet, consectetur et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        },
        3: {
          desc:
            "elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        },
        4: {
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        },
        5: {
          desc:
            "incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet"
        }
      }
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    if (id) {
      this.setEdit(id);
    }
  }

  async setEdit(id: string) {
    const { stepsData } = this.state;
    const args: any = {
      id,
      setLoading: () => { }
    };
    const resp: any = await CampaignsConfigurationServices.getCampaign(args);
    stepsData[0].state = {
      formData: {
        name: resp.name,
        start: moment(resp.start).format("DD/MM/YYYY"),
        end: moment(resp.end).format("DD/MM/YYYY"),
        inProcessStart: moment(resp.inProcessStart).format("DD/MM/YYYY"),
        inProcessEnd: moment(resp.inProcessEnd).format("DD/MM/YYYY"),
        adviceStartDate: moment(resp.adviceStartDate).format("DD/MM/YYYY")
      },
      selectedPeriod: moment(resp.start).diff(moment(resp.end), 'month') > 3 ? 'anual' : 'bimestral',
      campaignStatus: resp.status,
      errors: {}
    };
    stepsData[1].state = {
      bases: resp.bases,
      error: ""
    };
    stepsData[2].state = {
      selectedCard: resp.type.toLowerCase(),
      selectedAudience: resp.audience,
      savedAudience: false
    };
    stepsData[3].state = {
      formData: []
    };
    const runners: ICondition[][] = [],
      coordinators: ICondition[][] = [],
      buyers: ICondition[][] = [],
      exclusiveAgents: ICondition[][] = [];
    resp.prizes.forEach((prize: any, index: number) => {
      stepsData[3].state.formData.push({
        name: prize.name,
        prize: prize.value,
        errors: {}
      });

      runners.push([]);
      coordinators.push([]);
      buyers.push([]);
      exclusiveAgents.push([]);
      prize.conditions.forEach((cond: any) => {
        const condition = {
          unit: cond.unit,
          productLines: cond.productLines,
          products: cond.products,
          value: parseFloat(cond.value)
        };
        switch (cond.mediatorType) {
          case "runners":
            runners[index].push(condition);
            break;
          case "coordinators":
            coordinators[index].push(condition);
            break;
          case "buyers":
            buyers[index].push(condition);
            break;
          case "exclusiveAgents":
            exclusiveAgents[index].push(condition);
          default:
        }
      });
    });

    stepsData[4].state = {
      modal: "",
      selectedCondition: "",
      conditions: {
        runners,
        coordinators,
        buyers,
        exclusiveAgents
      }
    };
    stepsData[5].state = {
      summaryDisplay: resp.summaryDisplay,
      error: ""
    };
    this.setState({ stepsData, editCampaignStatus: resp.status });
  }

  renderStep() {
    const { step, goNext, stepsData } = this.state;
    switch (step) {
      case 0:
        return (
          <CampaingsCreateStep0
            goNext={goNext}
            onNext={(state: ICampaingsCreateStep0State) => this.onNext(state)}
            previousState={stepsData[step].state}
          />
        );
      case 1:
        return (
          <CampaingsCreateStep1
            goNext={goNext}
            onNext={(state: ICampaingsCreateStep1State) => this.onNext(state)}
            previousState={stepsData[step].state}
          />
        );
      case 2:
        return (
          <CampaingsCreateStep2
            setDisabledNext={disabled => this.setDisabledNext(disabled)}
            goNext={goNext}
            onNext={(state: ICampaingsCreateStep2State) => this.onNext(state)}
            previousState={stepsData[step].state}
          />
        );
      case 3:
        return (
          <CampaingsCreateStep3
            setDisabledNext={disabled => this.setDisabledNext(disabled)}
            goNext={goNext}
            onNext={(state: ICampaingsCreateStep3State) => this.onNext(state)}
            saveState={(state: ICampaingsCreateStep3State) =>
              this.saveState(state)
            }
            previousState={stepsData[step].state}
            isContest={stepsData[2].state.selectedCard === "contest"}
          />
        );
      case 4:
        return (
          <CampaingsCreateStep4
            setDisabledNext={disabled => this.setDisabledNext(disabled)}
            goNext={goNext}
            onNext={(state: ICampaingsCreateStep4State) => this.onNext(state)}
            previousState={stepsData[step].state}
            prizes={stepsData[3].state}
            isContest={stepsData[2].state.selectedCard === "contest"}
            selectedAudience={
              stepsData[2].state ? stepsData[2].state.selectedAudience : []
            }
          />
        );
      case 5:
        return (
          <CampaingsCreateStep5
            goNext={goNext}
            onNext={(state: ICampaingsCreateStep5State) => this.onNext(state)}
            savePrizes={(state: ICampaingsCreateStep3State) => {
              stepsData[3].state = state;
              this.setState({
                stepsData
              });
            }}
            previousState={stepsData[step].state}
            isContest={stepsData[2].state.selectedCard === "contest"}
            prizes={stepsData[3].state}
          />
        );
    }
  }

  setDisabledNext(disabledNext: boolean) {
    this.setState({ disabledNext });
  }

  onNext(state: any) {
    const { step, stepsData, editCampaignStatus } = this.state;
    stepsData[step].state = state;
    const isDefinition = !editCampaignStatus || editCampaignStatus === 'definition';
    if (step < 5 && isDefinition) {
      this.setState({ stepsData, step: step + 1 });
    } else {
      this.setState({ stepsData }, () => this.handleSubmit());
    }
  }

  saveState(state: any) {
    const { step, stepsData } = this.state;
    stepsData[step].state = state;
    this.setState({ stepsData });
  }

  previousStep() {
    const { step } = this.state;
    if (step > 0) {
      this.setState({ step: step - 1 });
    }
  }

  async handleSubmit() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { stepsData, editCampaignStatus } = this.state;
    const requestBody: any = {};

    requestBody.name = stepsData[0].state.formData.name;
    requestBody.start = moment(
      stepsData[0].state.formData.start,
      "DD/MM/YYYY"
    ).toISOString(true);
    requestBody.end = moment(
      stepsData[0].state.formData.end,
      "DD/MM/YYYY"
    ).toISOString(true);
    requestBody.inProcessStart = moment(
      stepsData[0].state.formData.inProcessStart,
      "DD/MM/YYYY"
    ).toISOString(true);
    requestBody.inProcessEnd = moment(
      stepsData[0].state.formData.inProcessEnd,
      "DD/MM/YYYY"
    ).toISOString(true);
    requestBody.messageDate = moment(
      stepsData[0].state.formData.adviceStartDate,
      "DD/MM/YYYY"
    ).toISOString(true);

    requestBody.bases = stepsData[1].state.bases;

    requestBody.type = stepsData[2].state.selectedCard.toUpperCase();
    requestBody.audience = stepsData[2].state.selectedAudience;
    if (requestBody.audience.length === 0) {
      requestBody.audience = ['runners', 'coordinators', 'buyers', 'exclusiveAgents']
    }

    requestBody.status = editCampaignStatus ? editCampaignStatus : 'definition';

    requestBody.prizes = [];

    //if (requestBody.type === "CONTEST") {
    stepsData[3].state.formData.forEach((prize: any, index: number) => {
      requestBody.prizes.push({
        name: prize.name,
        value: prize.prize,
        order: index + 1,
        conditions: []
      });
    });

    Object.entries(stepsData[4].state.conditions).forEach(entry => {
      const key = entry[0];
      const value: any = entry[1];
      if (requestBody.audience.includes(key)) {
        value.forEach((prize: any, index: number) => {
          prize.forEach((condition: any) => {
            requestBody.prizes[index].conditions.push({
              mediatorType: key,
              productLines: condition.productLines,
              products: condition.products,
              unit: condition.unit,
              value: condition.value,
              prize: "" //TODO CHECK THIS!!!!
            });
          });
        });
      }
    });
    //} else {
    // requestBody.prizes.push({
    //   name: stepsData[3].state.formData[0].name,
    //   value: stepsData[3].state.formData[0].prize,
    //   order: 1
    // });
    // requestBody.prizes[0].conditions = [];
    // Object.entries(stepsData[4].state.conditions).forEach(entry => {
    //   const key = entry[0];
    //   const value: any = entry[1];
    //   if (requestBody.audience.includes(key)) {
    //     value[0].forEach((condition: any) => {
    //       requestBody.prizes[0].conditions.push({
    //         mediatorType: key,
    //         productLines: condition.productLines,
    //         products: condition.products,
    //         unit: condition.unit,
    //         value: condition.value,
    //         prize: "" //TODO CHECK THIS!!!!
    //       });
    //     });
    //   }
    // });
    //}

    if (stepsData[5].state.summaryDisplay !== "NO") {
      requestBody.summaryDisplay = stepsData[5].state.summaryDisplay;
    }

    const args = {
      id,
      loader: true,
      setLoading: () => { },
      data: requestBody
    };

    let resp = null;
    if (id) {
      resp = await CampaignsConfigurationServices.putCampaigns(args);
    } else {
      resp = await CampaignsConfigurationServices.postCampaigns(args);
    }

    this.props.history.push(`/config-campañas/detail/${resp.id}/${!!id}`);
  }

  handleDeletePrize(index: number) {
    const { stepsData } = this.state;
    if (stepsData[3].state) {
      stepsData[3].state.formData.splice(index, 1);
    }
    this.setState({ stepsData, step: 3 });
  }

  handleAddPrize() {
    const { stepsData } = this.state;
    stepsData[3].state.formData.push({
      name: "",
      prize: "",
      errors: {}
    });
    this.setState({ stepsData, step: 3 });
  }

  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { step, stepsData, disabledNext, editCampaignStatus } = this.state;
    const isDefinition = !editCampaignStatus || editCampaignStatus === 'definition';
    return (
      <CampaignsCreateContainer>
        <div className="campaigns-main-container">
          <div className="campaigns-back-button">
            <GoBackLayout
              text="CAMPAÑAS"
              onclick={() => this.props.history.goBack()}
            />
          </div>
          <div className="campaigns-create-status">
            <div className="campaigns-create-status__title">
              <HeadsTitleText>
                <p>{`Paso ${step + 1}`}</p>
              </HeadsTitleText>
            </div>
            <div className="campaigns-create-status__guide">
              <GuideLine steps={[0, 0, 0, 0, 0, 0]} currentStep={step} />
            </div>
          </div>
          <div className="campaigns-create-section-title">
            <Title02Text>{id ? "Editar campaña" : "Crear campaña"}</Title02Text>
          </div>
          <div className="campaigns-create-section-subtitle">
            {stepsData[step].desc}
          </div>
          <div className="campaigns-create-forms">{this.renderStep()}</div>
        </div>
        <div className="campaigns-side-bar">
          <div className="campaigns-side-bar__item">
            <MainButton
              text={
                step < 5 && isDefinition
                  ? "SIGUIENTE"
                  : id
                    ? "GUARDAR CAMBIOS"
                    : "GENERAR CAMPAÑA"
              }
              disabled={disabledNext}
              onClick={() => {
                this.setState({ goNext: true }, () => {
                  this.setState({ goNext: false });
                });
              }}
            />
          </div>

          {step > 0 && (
            <div className="campaigns-side-bar__item">
              <MainButton
                text="ATRÁS"
                type={"light"}
                onClick={() => this.previousStep()}
              />
            </div>
          )}
          {isDefinition && stepsData[2].state &&
            /*stepsData[2].state.selectedCard === "contest" &&*/
            stepsData[3].state && (
              <>
                <div className="campaigns-side-bar-prizes">
                  <div className="campaigns-side-bar-prizes__title">
                    PREMIOS
                  </div>
                  {stepsData[3].state.formData.map(
                    (prize: any, index: number) => (
                      <>
                        <div className="campaigns-side-bar-prizes__card">
                          <CampaingsPrizeCard
                            title={prize.name}
                            subtitle={prize.prize}
                            selected={true}
                            onClickDetail={() => {
                              this.setState({ step: 3 });
                            }}
                            onClickErase={
                              index === 0
                                ? undefined
                                : () => this.handleDeletePrize(index)
                            }
                          />
                        </div>
                      </>
                    )
                  )}
                  <div className="campaigns-side-bar-prizes__button">
                    <MainButton
                      type={"full-light"}
                      text={"AÑADIR PREMIO"}
                      onClick={() => this.handleAddPrize()}
                    />
                  </div>
                </div>
              </>
            )}
        </div>
      </CampaignsCreateContainer>
    );
  }
}

export default withRouter(CampaignsCreate);
