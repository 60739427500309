import React from "react";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../assets";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import GuideLine from "../../../../components/guide-line";
import InputBox from "../../../../components/input-box/input-box";
import MainButton from "../../../../components/main-button";
import { InsuredDataContainer } from "./insured-data-style"
import { IHealthCoverage, STORAGE_KEY, IProfession } from "../../../../constants/health";
import HealthService from "../../../../services/HealthService";
import { capitalizeFirstLetter } from "../../../../utils";
import DicotomicButton from "../../../../components/dicotomic-button";
import SEX from "../../../../constants/sex.json";
import AddressServices from "../../../../services/AddressServices";
import { calcAge } from "../../../../utils/utility";
import { getDayMonthYear } from "../../../../utils/time";
import LeftSearchBoxModal from "../../../../components/left-searchbox-modal";
import ConstantsServices from "../../../../services/ConstantsServices";
import { ErrorModalContainer } from "../../../../components/error-modal/error-modal-style";
import { isCapitalInRangeHealth, isCapitalInRangeHealthByCoverage, isOutOfCoverageAgeRange } from "../../../../utils/coverages";
import HealthInsuredItem from "../../../../components/health-insured-item/health-insured-item";
import { withPolicy } from "../../../../context/mixin/with-policy";
import CoveragesServices from "../../../../services/CoveragesServices";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import { deepGet } from "../../../../utils/utility";
import SearchBox from "../../../../components/search-box-mediadores/search-box";

interface InsuredProps extends RouteComponentProps {
  setLoading: Function,
  policy: any,
  coverages: any;
}

interface InsuredSimulationInterface {
  birthDate: string,
  coverages: IHealthCoverage[],
  postalCode: string,
  gender?: string,
  checked: boolean,
  profession: { code: string, value: string },
  nationality: string,
}

interface InsuredState {
  insuredData: InsuredSimulationInterface[],
  postalCode: string,
  birthDate: string,
  profession: IProfession,
  valid: {
    postalCode: {
      validated: boolean,
      errorCode: string
    },
    gender: {
      validated: boolean,
      errorCode: string
    },
    nationality: {
      validated: boolean,
      errorCode: string,
    },
    birthDate: boolean,
  },
  mediatorData: {
    name: string;
    startDate: string;
  },
  healthSimulationData: any,
  coverages: IHealthCoverage[],
  gender: string,
  showLeftSearchBoxModal: boolean,
  professionsList: any[],
  covConfig: any[],
  indexInsured: number,
  serverError:boolean,
  modalErrorText:string,
  productFields: any | null,
  countries: any[],
  countriesFiltered: any[],
  nationality: string,
  isMaxInsureds: boolean,
}

class InsuredDataLayout extends React.Component<InsuredProps, InsuredState> {

  constructor(props: InsuredProps) {
    super(props);
    var healthSimulationData;
    if (localStorage.getItem(STORAGE_KEY.sKey)) {
      healthSimulationData = JSON.parse(String(localStorage.getItem(STORAGE_KEY.sKey)));
    }

    this.state = {
      birthDate: '',
      coverages: [],
      healthSimulationData: healthSimulationData,
      insuredData: healthSimulationData.insuredData ? healthSimulationData.insuredData : [],
      mediatorData: healthSimulationData.mediatorData,
      postalCode: '',
      valid: {
        postalCode: {
          validated: false,
          errorCode: ''
        },
        gender: {
          validated: false,
          errorCode: ''
        },
        nationality: {
          validated: false,
          errorCode: '',
        },
        birthDate: false
      },
      gender: '',
      showLeftSearchBoxModal: false,
      professionsList: [],
      covConfig: [],
      profession: {
        errorCode: '',
        labelText: 'Profesión',
        value: '',
      },
      indexInsured: 0,
      serverError:false,
      modalErrorText:"",
      productFields: null,
      countries: [],
      countriesFiltered: [],
      nationality: "",
      isMaxInsureds: false,
    }

  }

  formatCoveragesHealth = (coveragesData: any) => {
    const coveragesObj: any = [];
    coveragesData.sort((a: any, b: any) => {
      return a.order > b.order;
    });

    coveragesData.forEach((coverage: any) => {

      let desc = "";
      if (coverage.name)
        desc = capitalizeFirstLetter(coverage.name.toLowerCase())

      let coverageObj: any = {

        code: coverage.code,
        checked: false,
        description: desc,
        required: coverage.required ? coverage.required : false,
        edadMin: coverage.edadMin ? coverage.edadMin : "",
        edadMax: coverage.edadMax ? coverage.edadMax : "",
        capMaxCober: coverage.capMaxCober,
        capMinCober: coverage.capMinCober,
        capitales: coverage.capitales
      }

      coveragesObj.push(coverageObj)

    })


    return coveragesObj
  }

  async getCoverages() {
    const { setLoading, policy, coverages } = this.props;
    const productType: string = policy.product.products_coverageName;

    if (coverages && coverages[productType]) {
      this.setState({ coverages: this.formatCoveragesHealth(coverages[productType]) });
    } else {
      const resCoverages = await HealthService.getCoveragesFromDB(setLoading, true, productType)
      .catch((error) => {
        this.setState({ 
          serverError: true, 
          modalErrorText: `Se ha producido un error. Vuelva a intentarlo más tarde.` 
        });
        throw error.statusText;
      });
      if (resCoverages !== undefined) {
        const cov = this.formatCoveragesHealth(resCoverages);
        this.setState({ coverages: cov });
      }
    }
  }

  componentDidMount = async () => {
    let { professionsList, insuredData } = this.state;
    let { policy } = this.props;
    const covConfigs = await CoveragesServices.getCoveragesConfig({
      setLoading: () => { },
      loader: false,
      ignoreSetFalse: true,
    })


    professionsList = await ConstantsServices.getProfessions({
      setLoading: this.props.setLoading
    });

    let filterProfessions = professionsList
      .filter((p) => p.product === 'ALL')
      .sort(function (a: any, b: any) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

    const productFields = deepGet(policy, "product.productFields", null);
    const maxInsureds = deepGet(policy, "product.productFields.step2.maxInsureds", 10) || 10;
    let isMaxInsureds = false;
    if (insuredData && insuredData.length >= maxInsureds) isMaxInsureds = true;
    const countries = await ConstantsServices.getCountries({
      setLoading: this.props.setLoading,
    });
    const formatedCountries = countries.map((country: any) => ({label: country.label.toUpperCase(), value: country.value}));

    this.setState({ professionsList: filterProfessions, covConfig: covConfigs, productFields, countries: formatedCountries, isMaxInsureds });

    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    if(Object.keys(policy.product).length!==0){
      this.getCoverages()
    }
  };

  componentDidUpdate(prevProps:InsuredProps, prevState: InsuredState){
    let { policy } = this.props;
    const { insuredData, isMaxInsureds } = this.state;

    if(Object.keys(prevProps.policy.product).length===0 && Object.keys(policy.product).length!==0 ){
      this.getCoverages()
    }
    const productFields = deepGet(policy, "product.productFields", null);
    if (!prevProps.policy.product.productFields && productFields) {
      this.setState({ productFields });
    }

    const maxInsureds = deepGet(policy, "product.productFields.step2.maxInsureds", 10) || 10;
    let currentIsMaxInsureds = false;
    if (insuredData && insuredData.length >= maxInsureds) {
      currentIsMaxInsureds = true;
    }
    if (isMaxInsureds !== currentIsMaxInsureds) {
      this.setState({ isMaxInsureds: currentIsMaxInsureds });
    }
  }

  getPostalCodes = async (input: string) => {
    const { setLoading } = this.props;
    const resPostalCodes = await AddressServices.getPostalCodes({
      loader: true,
      setLoading: setLoading,
      params: [
        `input=${input}`,
        `provinceCode=*`,
        `cityCode=*`,
        `street=0`,
      ],
    });

    return resPostalCodes.length > 0;
  };


  toValidate = async () => {
    const { history } = this.props;
    const state = {
      insuredData: this.state.insuredData,
      mediatorData: this.state.mediatorData
    }
    let { insuredData } = this.state;
    const { healthSimulationData } = this.state;
    let error: boolean = false;

    insuredData.forEach((insured: InsuredSimulationInterface) => {

      insured.coverages.forEach((coverage: IHealthCoverage) => {

        if (coverage.checked && coverage.capitales) {

          if (coverage.capitalValue === '' || coverage.capitalValue === undefined) {
            coverage.errorCode = 'required';
            error = true;
          } else {
            const rangeError = isCapitalInRangeHealthByCoverage(coverage)

            if(rangeError !== ''){
              coverage.errorCode = rangeError
              error = true
            } else{
              coverage.errorCode = '';
            }
          }
        }
      });

    });

    if (!error) {

      if (healthSimulationData) {
        healthSimulationData.insuredData = this.state.insuredData;
        localStorage.setItem(STORAGE_KEY.sKey, JSON.stringify(healthSimulationData));
      }
      else {
        localStorage.setItem(STORAGE_KEY.sKey, JSON.stringify({ insuredData: this.state.insuredData }));
      }

      history.replace({ state: state })
      history.push("/simulacion/nueva/salud/precios")

    }
    this.setState({ insuredData })
  }

  handleBlurPostalCode(value: string) {
    const { valid } = this.state

    if (value.length > 0 && value.length < 5) {
      valid.postalCode.errorCode = 'invalid-postal-code';
      valid.postalCode.validated = false;
    }

    this.setState({ valid: valid })
  }

  handlePostalCode = async (value: string) => {

    const { valid } = this.state

    if (value.length <= 5) {
      valid.postalCode.errorCode = '';

      if (value.length === 5) {
        valid.postalCode.validated = await this.getPostalCodes(value);
        if (!valid.postalCode.validated) {
          valid.postalCode.errorCode = 'invalid-postal-code';
        }
      } else {
        if (valid.postalCode.validated) valid.postalCode.errorCode = 'invalid-postal-code';
        valid.postalCode.validated = false;
      }

      this.setState({ postalCode: value, valid: valid })

    } else {
      if (valid.postalCode) {
        valid.postalCode.validated = false
        this.setState({ valid: valid })
      }
    }


  }

  handleBirthData = (value: string) => {
    const { valid } = this.state
    valid.birthDate = (value.length === 10)
    this.setState({ birthDate: value, valid: valid })

  }

  handleAddInsured = () => {
    const { postalCode, birthDate, valid, coverages, gender, nationality, insuredData, productFields } = this.state;
    valid.birthDate = false;
    valid.postalCode.validated = false;

    insuredData.forEach((insured: InsuredSimulationInterface) => {
      insured.checked = false;
    });

    const newInsuredData = JSON.parse(JSON.stringify(insuredData))
    if(this.validateAgeInsureds(birthDate)){
      newInsuredData.push({
        birthDate,
        coverages: JSON.parse(JSON.stringify(coverages)),
        address: { postalCode },
        gender: gender,
        checked: true,
        profession: { code: '', value: '' },
        nationality,
      });
    }
    this.setState({
      birthDate: '',
      insuredData: newInsuredData,
      postalCode: '',
      gender: '',
      nationality: '',
    })

    const maxInsureds = deepGet(productFields, "step2.maxInsureds", 10) || 10;
    if (newInsuredData.length >= Number(maxInsureds)) {
      this.setState({ isMaxInsureds: true });
    }
  }

  handleRemoveInsured = (id: number) => {
    let insuredData = this.state.insuredData;
    insuredData.splice(id, 1);
    this.setState({ insuredData }, () => {
      var healthSimulationData;
      if (localStorage.getItem(STORAGE_KEY.sKey)) {
        healthSimulationData = JSON.parse(String(localStorage.getItem(STORAGE_KEY.sKey)));
      }
      if (healthSimulationData) {
        healthSimulationData.insuredData = this.state.insuredData;
        localStorage.setItem(STORAGE_KEY.sKey, JSON.stringify(healthSimulationData));
      }
    });
  }

  onChangeOption = (insured: number, age: number, coverage: IHealthCoverage, value: boolean) => {

    const covCapital = this.state.covConfig;

    if (coverage.required || isOutOfCoverageAgeRange(age, coverage.edadMin, coverage.edadMax)) return false
    
    const reqProfession = covCapital.filter(ele => ele.config.reqProfession === true)
      .map(ele => ele.code).includes(coverage.code)
    //const showLeftSearchBoxModal = (reqProfession && value === true)

    const { insuredData, profession } = this.state
    const newInsuredData = JSON.parse(JSON.stringify(insuredData))
    const index = newInsuredData[insured].coverages.map(function (e: any) {
      return e.code
    }).indexOf(coverage.code)
    newInsuredData[insured].coverages[index].checked = value

    if (reqProfession) {

      if (value === false) {

        newInsuredData[insured].profession.code = ''
        newInsuredData[insured].profession.value = ''

      }
      profession.value = insuredData[insured].profession.value
      profession.errorCode = ''

    }

    this.setState({ insuredData: newInsuredData, indexInsured: insured })
  }

  handleGenderChange(value: string) {
    this.setState({ gender: value });
  }

  handleCapitalChange(capitalValue: string, insuredIndex: number, coverageCode: string) {
    let { insuredData } = this.state;
    insuredData[insuredIndex].coverages.forEach((coverage: IHealthCoverage) => {
      if (coverage.code === coverageCode) {
        coverage.capitalValue = capitalValue;
        coverage.errorCode = '';
      }
    })
    this.setState({ insuredData });
  }

  handleInsuredCheck(insuredIndex: number) {
    let { insuredData } = this.state;
    insuredData.forEach((insured: InsuredSimulationInterface, index) => {
      if (index === insuredIndex) {
        insured.checked = !insured.checked;
      }
    });

    this.setState({ insuredData });
  }

  closeLeftModal() {
    const { profession, insuredData, indexInsured, covConfig } = this.state;

    const covReqProfession = covConfig;

    const codeCoverage = covReqProfession.filter(ele => ele.config.reqProfession)[0].code;

    const index = insuredData[indexInsured].coverages.map(function (e: any) {
      return e.code
    }).indexOf(codeCoverage);

    insuredData[indexInsured].coverages[index].checked = false;

    this.setState({
      showLeftSearchBoxModal: false,
      profession: { ...profession, errorCode: '', value: '' },
      insuredData,
    });

  }

  handleProfessionChange(professionSelected: any) {
    const { insuredData, indexInsured, profession } = this.state;

    insuredData[indexInsured].profession.value = professionSelected.label;
    insuredData[indexInsured].profession.code = professionSelected.value;
    profession.errorCode = '';
    profession.value = professionSelected.label;

    this.setState({ insuredData, profession });

  }

  handleOnChangeNationality = (value: any) => {
    if (value.length < 2) return;
    const { countries } = this.state;
    const countriesFiltered = countries.filter((country) =>
      country.label
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          value
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        )
    );
    this.setState({ countriesFiltered });
  };

  handleOnSelectNationality = (item: any) => {
    this.setState({ nationality: item.value });
  }

  validateProfession = () => {
    const { profession } = this.state;

    if (profession.value === '') {

      this.setState({
        profession: {
          ...profession,
          errorCode: "required",
        },
      });

    } else {
      this.setState({ showLeftSearchBoxModal: false })
    }
  }

  validateAgeInsureds = (birthDate: any) => {
    const { serverError, coverages } = this.state;     
      const covReqAgeMax = coverages.filter((c: any) => c.required && c.edadMax !== '');
  
        const age = calcAge(birthDate) || 0;
        const isHighAge = covReqAgeMax.some((c: any) => age > c.edadMax );
        
        if (isHighAge && !serverError) {
          this.setState({ 
              serverError: true, 
              modalErrorText: `El asegurado excede de la edad máxima permitida para alguna de las coberturas requeridas.` 
          });
          return false
        }
          return true


  }

  getIsDisabledMainButton = () => {
    const { valid, gender, nationality, productFields, postalCode, birthDate } = this.state;
    const requiredFields = [
      deepGet(productFields, "step2.postalCode.required", false) ? "postalCode" : "",
      deepGet(productFields, "step2.birthDate.required", false) ? "birthDate" : "",
      deepGet(productFields, "step2.nationality.required", false) ? "nationality" : "",
      deepGet(productFields, "step2.gender.required", false) ? "gender" : "",
    ];
    if ((requiredFields.includes("postalCode") && !postalCode) || !valid.postalCode.validated) return true;
    if ((requiredFields.includes("birthDate") && !birthDate) || !valid.birthDate) return true;
    if (requiredFields.includes("gender") && !gender) return true;
    if (requiredFields.includes("nationality") && !nationality) return true;
    return false;
  }

  render() {

    const {
      birthDate,
      gender,
      insuredData,
      postalCode,
      profession,
      professionsList,
      showLeftSearchBoxModal,
      valid,
      serverError,
      modalErrorText,
      productFields,
      countriesFiltered,
      nationality,
      isMaxInsureds,
    } = this.state

    return (
      <InsuredDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">

            <h2>{'DATOS DEL SEGURO'}</h2>

          </div>
          <div className="data-flow-status-guide">
            <GuideLine
              steps={[0, 0, 0, 0]}
              currentStep={2}
            />
          </div>
        </div>

        <div className="data-wrapper">
          <div className="content-wrapper bottomMargin40">
            <div className="personal-data-wrapper">
              <div className="data-title">
                <h5>DATOS DE LOS ASEGURADOS</h5>
              </div>
            </div>
            <div className="data-row colSin-100">
              {deepGet(productFields, "step2.postalCode", false) && (
                <div className="colSin-33 rightPadding20 topMargin12">
                  <div className="inputBox">
                    <InputBox
                      errorCode={valid.postalCode.errorCode}
                      maxLength={5}
                      onChange={(value: string) => this.handlePostalCode(value)}
                      onBlur={(value: string) => this.handleBlurPostalCode(value)}
                      placeholder="Código Postal"
                      required
                      type={"number"}
                      value={postalCode}
                      withTooltip={deepGet(productFields, "step2.postalCode.tooltip", false)}
                      tooltipTitle={deepGet(productFields, "step2.postalCode.tooltip", "")}
                      tooltipText=""
                      tooltipIconTop="-72px"
                      tooltipIconLeft="145px"
                      disabled={isMaxInsureds}
                    />
                  </div>
                </div>
              )}
              {deepGet(productFields, "step2.birthDate", false) && (
                <div className="colSin-33 rightPadding20 topMargin12">
                  <div className="inputBox">
                    <CalendarBox
                      initialValue={birthDate}
                      placeholder="Fecha de nacimiento"
                      forbidFutureDates
                      maxDate={moment().toISOString()}
                      required={deepGet(productFields, "step2.birthDate.required", false)}
                      onChange={(value: string) => {
                        this.handleBirthData(value);
                      }}
                      health={true}
                      disabled={isMaxInsureds}
                    />
                  </div>
                </div>
              )}

              {deepGet(productFields, "step2.gender", false) && (
                <div className="colSin-33 rightPadding20">
                  <DicotomicButton
                    required={true}
                    labelText="Sexo"
                    options={SEX}
                    withTab={true}
                    initialValue={gender}
                    onChange={(value: string) => this.handleGenderChange(value)}
                    errorCode={valid.gender.errorCode}
                    disabled={isMaxInsureds}
                  />
                </div>
              )}


            </div>

            <div className="data-row colSin-100">

              <div className="colSin-50">
                {deepGet(productFields, "step2.nationality", false) && (
                  <div className="inputBox">
                    <SearchBox
                      onChange={(ev: any) => this.handleOnChangeNationality(ev.target.value)}
                      labelText=""
                      optionsText={countriesFiltered}
                      optionKey="value"
                      optionValue="label"
                      initialValue={nationality && countriesFiltered ? countriesFiltered.find((country) => country.value === nationality).label : ""}
                      required={deepGet(productFields, "step2.nationality.required", false)}
                      errorCode={valid.nationality.errorCode}
                      onSelect={(value: any) => this.handleOnSelectNationality(value)}
                      disabled={isMaxInsureds}
                    />
                    <label>Nacionalidad</label>
                  </div>
                )}
              </div>
              <div className="colSin-25" />
              <div className="colSin-25 rightPadding20 topPadding15">
                <MainButton
                  disabled={this.getIsDisabledMainButton() || isMaxInsureds}
                  text="Añadir Asegurado"
                  onClick={() => this.handleAddInsured()}
                />
              </div>

            </div>
            {isMaxInsureds && <p className="text-max-size">{`*El máximo número de asegurados es de ${deepGet(productFields, "step2.maxInsureds", 10)}.`}</p>}
          </div>

          <div className="content-wrapper bottomMargin20">
            <div className="tabs">

              {insuredData.map((insurance, index) => (
                <HealthInsuredItem
                  age={calcAge(getDayMonthYear(insurance.birthDate).toString())}
                  birthDate={insurance.birthDate}
                  checked={insurance.checked}
                  coverages={insurance.coverages}
                  gender={insurance.gender}
                  id={index + 1}
                  index={index}
                  key={index}
                  onChange={
                    (insured: number, age: number = calcAge(getDayMonthYear(insurance.birthDate).toString()), coverage: IHealthCoverage, value: boolean) => this.onChangeOption(insured, age, coverage, value)
                  }
                  onCheckInsured={(insured: number) => this.handleInsuredCheck(insured)}
                  onClick={() => this.handleRemoveInsured(index)}
                  handleCapitalChange={(value: string, coverageCode: string) => this.handleCapitalChange(value, index, coverageCode)}
                />

              ))}
            </div>
          </div>
        </div>

        <LeftSearchBoxModal
          img={images.IconSearchBlue}
          className={''}
          mainTitle={'Profesión del asegurado'}
          mainText={'Seleccione de la lista, la profesión del asegurado, para activar esta garantía'}
          buttonText={'Aceptar'}
          close={() => this.closeLeftModal()}
          active={showLeftSearchBoxModal}
          onClickAccept={() => this.validateProfession()}
          withSearhBox={{
            errorCode: profession.errorCode,
            labelText: profession.labelText,
            optionsText: professionsList,
            onChange: (value: string) => this.handleProfessionChange(value),
            value: profession.value,
          }}
        />

        <div className="colSin-100 topMargin20 bottomMargin40">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push("/simulacion/nueva/salud/mediador")}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                text="Siguiente"
                disabled={insuredData.length <= 0}
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>
        <ErrorModalContainer className={`${serverError && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={() => this.setState({ serverError: false })} />
            </div>

          </div>
        </ErrorModalContainer>
      </InsuredDataContainer>
    );
  }
}

export default withPolicy(withCoverages(withRouter(InsuredDataLayout)));