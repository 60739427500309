import React from "react";
import HealthComparatorLayout from "./health-comparator-layout";


export default class Comparator extends React.Component<any> {
  render() {
    const { setGuideLine } = this.props;

    return <HealthComparatorLayout setGuideLine={setGuideLine} />;
  }
}