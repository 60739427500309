import styled from "styled-components";
import { colors } from "../../assets/colors";

export const SearchBoxContainer = styled.div`
  position: relative;
  font-family: "museo sans";

  &.show-list {
    z-index: 12;
    .input-box-main-list {
      display: block;
    }
  }

  .hovered {
    background: #f0fafd;
  }

  &.show-tooltip {
    .input-box-topbar {
      z-index: 13;
    }

    .input-box-tooltip {
      display: block;
    }
  }

  &.required {
    .input-box-main-field {
      &:before {
        content: "";
        z-index: 9;
        position: absolute;
        top: 23px;
        left: 4px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: transparent;
      }
    }
  }



  &.disabled {
    pointer-events: none;

    .input-box-topbar-label {
      color: ${colors["TX-001-30"]};
    }

    .input-box-main-field {
      background: ${colors["FL-001-Disabled"]};
    }
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
  }

  .input-box-topbar-label {
    color: ${colors["PR-001-50"]};
    text-transform: uppercase;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-field {
    position: relative;

    &:hover {
      background: #f0fafd;
    }
  }

  input {

    &::placeholder  {
      color: ${colors["PR-001-100"]};
    }

    &:focus {
      border-radius: 3px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
      width: 100%;
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 20px;
    z-index: 10;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 29;
    width: 260px;
    display: none;
  }

  .input-box-main-list {
    position: inherit;
    top: calc(100% + 10px);
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 12;
    background: #ffffff;
    width: 100%;
    color: ${colors["PR-001-100"]};
    max-height: 250px;
    overflow-y: scroll;

    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;

      &:hover {
        background: #f0fafd;
      }

      &.no-data {
        font-size: 14px;
        line-height: 20px;
        color: ${colors["TX-001-50"]};
        padding: 15px;
      }
    }
  }

  .input-box-error {
    color: ${colors["ER-001-100"]};
  }
`;
