import React, { Component } from "react";
import { CheckboxItemContainer } from "./checkbox-item-style";
import WebText from "../components-text/web-text";
import NoteText from "../components-text/note-text";

interface CheckboxItemProps {
  className?: string;
  name: string;
  checked: boolean;
  onChange: Function;
  showError: boolean;
  checkboxText: string;
  errorText: string;
  withTab?: boolean;
  disabled?:boolean;
}
class CheckboxItemLayout extends Component<CheckboxItemProps, {}> {
  containerRef: any;
  inputRef: any;

  constructor(props: CheckboxItemProps) {
    super(props);
    this.state = {};
    this.containerRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { withTab } = this.props;
    withTab && this.containerRef.current.addEventListener("keyup", (e: any) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        this.inputRef.current.click();
      }
    });
  }

  render() {
    const {
      name,
      checked,
      onChange,
      showError,
      checkboxText,
      errorText,
      className,
      withTab,
      disabled
    } = this.props;
    return (
      <CheckboxItemContainer className={`${showError ? 'error' : ''} ${className ? className : ""}`} tabIndex={!!withTab ? 0 : -1} ref={this.containerRef}>
        <label className={`checkbox-container ${className ? className : ""} ${disabled ? "disabled" : ""}`}>
          <input
            name={name}
            tabIndex={-1}
            disabled={disabled}
            checked={checked}
            onChange={ev => onChange(ev)}
            type="checkbox"
            ref={this.inputRef}
          />
          <span className="checkmark" />
          <WebText className='checkbox-text' color={disabled? "#ababab":""}>
            {checkboxText}
          </WebText>
        </label>
        <div className="checkbox-error">
          {showError && (
            <NoteText>
              <NoteText>
                <p>{errorText}</p>
              </NoteText>
            </NoteText>
          )}
        </div>
      </CheckboxItemContainer>
    );
  }
}

export default CheckboxItemLayout;
