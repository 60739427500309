import React from 'react';
import GoBackScreen from './go-back-screen';

export default class GoBack extends React.Component<any, {}> {
    render(){
        const {img, text, onClick} = this.props;
        return(
            <GoBackScreen img={img} text={text} onclick={ onClick }/>
        )
    }
}