import React from "react";
import { QuestionnaireContainer } from "./questionnaire-style";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { NewHiringContainer } from "../new-hiring-style";
import HeadsTitleText from "../../../../components/components-text/heads-title-text";
import GuideLine from "../../../../components/guide-line";
import CheckboxItem from "../../../../components/checkbox-item";
import InputCounter from "../../../../components/input-counter/input-counter";
import QuestionnaireItem from "../../../../components/questionnaire-item/questionnaire-item";
import MainButton from "../../../../components/main-button";
import { images } from "../../../../assets";
import { withGeneral } from "../../../../context/mixin/with-general";
import { withPolicy } from "../../../../context/mixin/with-policy";
import ConstantsServices from "../../../../services/ConstantsServices";

interface RouteParams {
  index: string;
}

interface QuestionnaireLayoutProps extends RouteComponentProps<RouteParams> {
  setLoading: Function;
  changeQuestionary: Function;
  policy: any;
  data: any;
  setProductsData: () => void;
}

interface QuestionnaireLayoutState {
  questionnaire: any[];
}

class QuestionnaireLayout extends React.Component<
  QuestionnaireLayoutProps,
  QuestionnaireLayoutState
  > {
  readonly state: QuestionnaireLayoutState;

  constructor(props: QuestionnaireLayoutProps) {
    super(props);
    if (
      props.data.insureds.length === 0 ||
      props.data.insureds.length < parseInt(props.match.params.index)
    )
      props.history.push(
        `/${
        props.history.location.pathname.indexOf("simulacion") > -1
          ? "simulacion"
          : "contratacion"
        }/nueva`
      );
    this.state = {
      questionnaire: []
    };
  }
  componentDidMount() {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    this.getQuestionnaires();
  }

  componentDidUpdate(prevProps: QuestionnaireLayoutProps) {
    if (
      prevProps.match.params.index !== this.props.match.params.index
    ) {
      this.getQuestionnaires();
    }
  }

  getQuestionnaires = async () => {
    const { setLoading, policy, match, data } = this.props;
    const insured = data.insureds[match.params.index];
    if (
      insured &&
      insured.questionnaires &&
      insured.questionnaires.length > 0 &&
      insured.questionnaires[0].question
    ) {
      this.setState({ questionnaire: insured.questionnaires });
    } else {
      const resQuestionnaire = await ConstantsServices.getLifeQuestionnaires({
        loader: true,
        setLoading: setLoading,
      });
      const insuredQuestionnaire = insured.questionnaires || [];

      const questionnaire = resQuestionnaire.map((q: any, index: number) => {
        const indexQ: number = insuredQuestionnaire.findIndex(
          (iq: any) => iq.code === q.id
        );
        let value = "";
        if (indexQ !== -1) {
          if (insuredQuestionnaire[indexQ].answer) {
            if (typeof insuredQuestionnaire[indexQ].answer === "boolean")
              value = insuredQuestionnaire[indexQ].answer ? 'Sí' : 'No';
            else value = insuredQuestionnaire[indexQ].answer;
          } else value = insuredQuestionnaire[indexQ].answer === false ? 'No' : "";
        }
        return {
          index: index,
          code: q.id,
          question: q.question,
          answer: {
            value: value,
            error: false,
            errorCode: "",
            required: true,
            disabled: false
          },
          subquestionnaire: q.subquestionnaire
            ? q.subquestionnaire.map((sq: any, indexSq: number) => {
              let sqInsuredIndex = -1;
              if (indexQ !== -1) {
                sqInsuredIndex = insuredQuestionnaire[indexQ]
                  .subquestionnaires
                  ? insuredQuestionnaire[indexQ].subquestionnaires.findIndex(
                    (subq: any) => subq.code === sq.id
                  )
                  : -1;
              }
              return {
                index: indexSq,
                code: sq.id,
                question: sq.question,
                answer: {
                  value:
                    sqInsuredIndex !== -1
                      ? insuredQuestionnaire[indexQ].subquestionnaires[
                        sqInsuredIndex
                      ].answer
                      : "",
                  error: false,
                  errorCode: "",
                  required: true,
                  disabled: false
                }
              };
            })
            : []
        };
      });
      this.setState({ questionnaire });
    }
  };

  changeValue = (index: number, value: string) => {
    const { questionnaire } = this.state;
    questionnaire[index].answer.value = value;
    this.props.policy.handleNewChanges();
    this.setState({ questionnaire });
  };

  handleChangeQuestionnaire = (question: any, index: number) => {
    const { questionnaire } = this.state;
    questionnaire[index] = question;
    this.props.policy.handleNewChanges();
    this.setState({ questionnaire });
  };

  renderAmounts = () => {
    const { isReadOnly } = this.props.policy.data;
    const { questionnaire } = this.state;
    const amounts = questionnaire.filter(
      q => q.question === "Estatura" || q.question === "Peso"
    );

    return amounts.map(q => (
      <div className="questionnaire-amount-item">
        <InputCounter
          onChange={(value: string) => this.changeValue(q.index, value)}
          value={`${q.answer.value}`}
          labelText={q.question}
          disabled={q.answer.disabled || isReadOnly}
          required={q.answer.required}
          errorCode={q.answer.errorCode}
          unit={q.question === "Estatura" ? 'cm' : 'kg'}
        />
      </div>
    ));
  };

  nextQuestionnaire = async () => {
    const { match, data, history, changeQuestionary } = this.props;
    const { questionnaire } = this.state;
    let isOk = true;
    questionnaire.forEach(q => {
      if (
        q.answer.value === "" &&
        (q.question === "Estatura" || q.question === "Peso")
      ) {
        q.answer.error = true;
        q.answer.errorCode = "required";
        isOk = false;
      } else {
        if (!/^([0-9])*$/.test(q.answer.value) && (q.question === "Estatura" || q.question === "Peso")) {
          q.answer.error = true;
          q.answer.errorCode = "no-decimals";
          isOk = false;
        } else {
          q.answer.error = false;
          q.answer.errorCode = "";
        }
      }
      for (let i = 0; i < q.subquestionnaire.length; i++) {
        if (
          q.answer.value !== "No" &&
          q.subquestionnaire[i].answer.value === ""
        ) {
          q.subquestionnaire[i].answer.error = true;
          q.subquestionnaire[i].answer.errorCode = "required";
          isOk = false;
        } else {
          q.subquestionnaire[i].answer.error = false;
          q.subquestionnaire[i].answer.errorCode = "";
        }
      }
    });
    this.setState({ questionnaire });
    if (isOk) {
      await this.props.setProductsData()
      const index = parseInt(match.params.index);
      if (index + 1 === data.insureds.length) {
        //TODO OK siguiente PASO
        changeQuestionary(index, questionnaire, isOk);
      } else {
        changeQuestionary(index, questionnaire);
        history.push(
          `/contratacion/nueva/vida/cuestionarios-vida/${index + 1}`
        );
      }
    }
  };

  renderInputs = () => {
    const { isReadOnly } = this.props.policy.data;
    const { questionnaire } = this.state;
    const inputs = questionnaire.filter(
      q => q.question !== "Estatura" && q.question !== "Peso"
    );
    return inputs.map(q => (
      <div className="questionnaire-data-row">
        <div className="questionnaire-question">
          <QuestionnaireItem
            onChange={(value: any, index: number) =>
              this.handleChangeQuestionnaire(value, index)
            }
            question={q}
            disabled={q.answer.value === "No" || isReadOnly}
          />
        </div>
        <div className="questionnaire-options-container">
          <div className="questionnaire-options-item">
            <CheckboxItem
              checkboxText="Sí"
              errorText="error"
              checked={q.answer.value === "Sí"}
              name="yes"
              onChange={() => this.changeValue(q.index, "Sí")}
              showError={false}
              disabled={isReadOnly}
            />
          </div>
          <div className="questionnaire-options-item">
            <CheckboxItem
              checkboxText="No"
              errorText="error"
              checked={q.answer.value === "No"}
              name="no"
              onChange={() => this.changeValue(q.index, "No")}
              showError={false}
              disabled={isReadOnly}
            />
          </div>
        </div>
      </div>
    ));
  };

  render() {
    const { match, data } = this.props;

    return (
      <NewHiringContainer>
        <div className="new-hiring-flow-status">
          <div className="new-hiring-flow-status-title">
            <HeadsTitleText>
              <p>Beneficiarios</p>
            </HeadsTitleText>
          </div>
          <div className="new-hiring-flow-status-guide">
            <GuideLine steps={[0, 0, 0, 0]} currentStep={2} />
          </div>
        </div>

        <QuestionnaireContainer>
          <div className="questionnaire-title">
            <p>Datos clínicos asegurado</p>
          </div>
          <div className="questionnaire-subtitle">
            <p>
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit */}
            </p>
          </div>
          <div className="questionnaire-amount-container">
            {this.renderAmounts()}
          </div>
          <div className="questionnaire-data-container">
            {this.renderInputs()}
          </div>
          <div className="questionnaire-button-container">
            {parseInt(match.params.index) > 0 && (
              <div className="questionnaire-button-item">
                <MainButton
                  type={"white"}
                  text="Atrás"
                  onClick={() => { }}
                  icon
                  iconImg={images.IconArrowLeftBlue}
                />
              </div>
            )}

            {/* <div className="questionnaire-button-item">
              
            </div> */}

            <div className="personal-data-buttons">
              <div className="personal-data-buttons__item">
                <MainButton
                  withTab={true}
                  icon={true}
                  type={'white'}
                  iconImg={images.IconArrowLeftBlue}
                  text="Atrás"
                  onClick={() => this.props.history.goBack()}
                />
              </div>
              <div className="personal-data-buttons__item">
                <MainButton
                  text={
                    data.insureds.length > parseInt(match.params.index) + 1
                      ? "Siguiente"
                      : "Validar"
                  }
                  onClick={() => this.nextQuestionnaire()}
                />
              </div>
            </div>
          </div>
        </QuestionnaireContainer>
      </NewHiringContainer>
    );
  }
}

export default withPolicy(withGeneral(withRouter(QuestionnaireLayout)));
