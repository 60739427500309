export const CIVIL_STATUS = [
  {value: 'married', label: 'Casado'},
  {value: 'single', label: 'Soltero'}
];

export const GIP_CIVIL_STATUS = [
  {value: 'CASADO', label: 'Casado'},
  {value: 'SOLTERO', label: 'Soltero'},
  {value: 'DIVORCIADO', label: 'Divorciado'},
  {value: 'VIUDO', label: 'Viudo'}
];