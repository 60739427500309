
import React, { useRef } from "react";
import { PermissionsContainer } from "./permissions-style";
import ProductsMediadoresServices from "../../services/ProductsMediadoresServices";
import ProductServices from "../../services/ProductServices";
import { withGeneral } from "../../context/mixin/with-general";
import GoBack from "../../components/go-back";
import SelectBox from "../../components/select-box/select-box";
import MainButton from "../../components/main-button";
import { images } from "../../assets";
import InputBox from "../../components/input-box/input-box";
import MainModal from "../../components/main-modal";
import WebText from "../../components/components-text/web-text";
import { PRODUCT_CARD_NAME_COLOR } from "../../constants/policyType";
import moment from "moment";
import MediatorServices from "../../services/MediatorServices";
import LeftInfoModal from "../../components/left-info-modal";
import PermissionsUpload from "./permissions-upload";

interface FilterProductMediador {
  mediatorCode: string;
  selectedProductsId: string[];
}

interface PermissionsState {
  products: any; // IProduct
  resp: any; // IProductsMediadores
  filter: FilterProductMediador;
  selected: any;
  searched: boolean;
  validateSave: boolean;
  auxProductMediadores: any;
  allSelected: boolean;
  mediatorExists: boolean;
  mediatorErrorText: string;
}
interface ProductAux {
  index: number,
  mediador: string,
  permiso: boolean,
  producto_id: number,
  areaName: string,
  internalName: string,
  name: string,
  modify: boolean,
  producto_mediador_id?: number,
  todos_incluidos: boolean,
  createdAt?: string,
  updatedAt?: string,
  colectivo: string,
  combinacion_comercial: string,
  cardName: string,
}

interface PermissionsProps {
  setLoading: Function;
  allProducts: any;
  productsByAreaName: any;
}


class PermissionsLayout extends React.Component<PermissionsProps, PermissionsState> {
  static state: PermissionsState;
  constructor(props: PermissionsProps) {
    super(props);
    this.state = {
      auxProductMediadores: [],
      products: {},
      resp: [],
      selected: [],
      filter: {
        mediatorCode: '',
        selectedProductsId: [],
      },
      searched: false,
      validateSave: false,
      allSelected: false,
      mediatorExists: true,
      mediatorErrorText: ''
    }
  }
  textModal = "Los permisos de este usuario se van a modificiar. ¿Desea continuar?"

  tableColumns = [
    {
      text: "mediador",
      filter: false,
      ascendent: true,
      key: "inline",
    },
    {
      text: "Producto",
      filter: false,
      ascendent: true,
      key: "inline",
    },
    {
      text: "Estado permiso",
      filter: false,
      ascendent: true,
      key: "inline",
    },
    {
      text: "Registro",
      filter: false,
      ascendent: true,
      key: "inline",
    }
  ];


  getRows = (users: any[]) => {

    const { products } = this.state;

    let usermap = [
      {
        mediador: {
          text: "mediador",
          type: "",
          key: "mediador",
        },
        productoId: {
          text: "productoId",
          type: "",
          key: "productoId",
        },
        esIncluido: {
          text: "esIncluido",
          type: "",
          key: "esIncluido",
        },
        registro: {
          text: "registro",
          type: "",
          key: "registro",
        }
      }
    ]

    if (users.length > 0) {

      usermap = users.map((b, index) => ({
        id: {
          text: b.id,
          type: "",
          key: "mediador",
        },
        mediador: {
          text: b.mediador,
          type: "",
          key: "mediador",
        },
        productoId: {
          text: products.find((product: any) => product.id === b.productoId).name,
          type: "",
          key: "productoId",
        },
        esIncluido: {
          text: String(b.esIncluido),
          type: "",
          key: "esIncluido",
        },
        registro: {
          text: "registro",
          type: "",
          key: "registro",
        }
      }));
    }
    return usermap;
  }

  async componentDidMount() {
    let { products } = this.state
    products = await ProductServices.getProductsByName({
      setLoading: this.props.setLoading,
    })
    this.setState({ products })
  }
  
  procesarIncluidos = (producto: any, producto_mediador: any) => {
    if (producto_mediador) {
      return producto_mediador.esIncluido
    }
    return true;
  }
  procesaroNoIncluidos = (producto: any, producto_mediador: any) => {
    if (producto_mediador) {
      return producto_mediador.esIncluido
    }
    return false;
  }
  async getAllUsers() {
    try {
      await MediatorServices.getMediatorInfo({
        setLoading: () => { },
        loader: true,
        mediatorCode: this.state.filter.mediatorCode,
        productPermissionCheck: true
      });
      this.setState({mediatorExists: true, mediatorErrorText: ''})
    } catch (error) {
      this.setState({ resp: [], auxProductMediadores: [], mediatorExists: false, mediatorErrorText: `El mediador ${this.state.filter.mediatorCode} no existe.`, searched: false })
      return
    }

    const args = {
      setLoading: () => { },
      loader: true,
      params: { mediatorCode: this.state.filter.mediatorCode, productos: this.state.filter.selectedProductsId }
    };
    const resp = await ProductsMediadoresServices.getUsers(args);
    //Tengo tanto los productos como los products mediadores
    //Tengo que guardar un array de objetos en el que guarde el estado del producto teniendo en cuenta
    let obj: any = []
    const { products, filter } = this.state;

    let temp_products = products;
    if (filter.selectedProductsId.length > 0) {
      const productFilters = products.filter((prod: any) => filter.selectedProductsId.includes(prod.id))
      temp_products = productFilters;
    }
    temp_products.map((prod: any, index: any) => {
      let product_mediador = resp.find((pm: any) => prod.id === pm.productoId)
      let permiso = false;
      if (prod.todos_incluidos) {
        permiso = this.procesarIncluidos(prod, product_mediador)
      } else {
        permiso = this.procesaroNoIncluidos(prod, product_mediador);
      }

      let aux_producto: ProductAux = {
        index,
        mediador: filter.mediatorCode,
        permiso,
        producto_id: prod.id,
        areaName: prod.areaName,
        internalName: prod.internalName,
        name: prod.name,
        modify: false,
        todos_incluidos: prod.todos_incluidos,
        producto_mediador_id: -1,
        createdAt: product_mediador ? moment(product_mediador.createdAt).format('DD/MM/YYYY HH:mm') : '',
        updatedAt: product_mediador ? moment(product_mediador.updatedAt).format('DD/MM/YYYY HH:mm') : '',
        colectivo: prod.colectivo,
        combinacion_comercial: prod.combinacion_comercial,
        cardName: prod.cardName,
      }
      if (product_mediador) {
        aux_producto.producto_mediador_id = product_mediador.id;
      }
      obj.push(aux_producto);
    })
    if (obj.length > 0) {
      this.setState({ resp: resp, auxProductMediadores: obj, searched: true })
    } else {
      this.setState({ resp, auxProductMediadores: [], searched: true })

    }

  }

  async activatePermissionById(productsModified: any) {
    const args = {
      setLoading: () => { },
      loader: true,
      request: productsModified
    };
    const resp = await ProductsMediadoresServices.updatePermissions(args);
    if (resp) { this.getAllUsers() }
  }
  handleSave = () => {
    if (this.state.auxProductMediadores.length > 0) {
      this.setState({ validateSave: true })
    }
  }
  savePermissions = () => {
    const { auxProductMediadores } = this.state;
    const productsModified = auxProductMediadores.filter((x: any) => x.modify)
    this.activatePermissionById(productsModified)
    this.setState({ validateSave: true })
  }

  handleFilter = () => {

    if (this.state.filter.mediatorCode !== '') {
      this.getAllUsers();
    }
  }

  handleChangeProducts(value: string[]) {
    this.setState(prevState => ({ ...prevState.resp, filter: { ...prevState.filter, selectedProductsId: value } }))
  }

  handleChangeFilterMediatorCode = (value: string) => {
    let val = value;
    this.setState(prevState => ({
      ...prevState.resp,
      filter: { ...prevState.filter, mediatorCode: val },
    }))
  }

  handleEsIncluidoTable = (id: number, esIncluido: number) => {

    let { resp, selected } = this.state;
    const target = resp.find((rec: any) => rec.id === id)

    if (target) {
      target.esIncluido = Number(!target.esIncluido);
      target.modify = !target.modify
    }

    const selectedTarget = selected.find((rec: any) => rec.id === id)

    if (selectedTarget) {
      selected.splice(selected.findIndex((rec: any) => rec.id === id), 1);
    } else {
      selected.push(target)
    }
    this.setState({ selected })
  }

  closeModal = () => {
    this.setState({ validateSave: false });
  };

  closeMediatorError = () => {
    this.handleChangeFilterMediatorCode('')
    this.setState({ mediatorExists: true, mediatorErrorText: '', searched: false  });
  } 


  handlePermiso = (product: any) => {
    const { auxProductMediadores } = this.state;
    auxProductMediadores[product.index].permiso = !auxProductMediadores[product.index].permiso;
    auxProductMediadores[product.index].modify = true;
    this.setState({
      auxProductMediadores
    });
  }
  seleccionarTodos = () => {
    const { auxProductMediadores, allSelected } = this.state;
    const permiso = !allSelected
    for (let i = 0; i < auxProductMediadores.length; i++) {
      if (auxProductMediadores[i].permiso !== permiso) {
        auxProductMediadores[i].permiso = permiso;
        auxProductMediadores[i].modify = true;

      }
    }
    this.setState({ auxProductMediadores, allSelected: permiso });
  }
  checkDisabled = () => {
    const { auxProductMediadores } = this.state;
    return !auxProductMediadores.some((p: any) => p.modify === true)

  }
  render() {
    const { resp, products, filter, searched, validateSave, auxProductMediadores, allSelected, mediatorExists, mediatorErrorText } = this.state;
    const { selectedProductsId } = filter

    return (
      <PermissionsContainer>
        <div className="go-back-head">
          <GoBack />
        </div>
        <div className="permission-content"></div>
        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <h2>CONFIGURAR PERMISOS DE USUARIOS</h2>
          </div>
        </div>
        <div className="data-wrapper">
          <div className="content-wrapper bottomMargin40">
            <div className="personal-data-wrapper">
              <div className="data-title">
                <h3> Filtrar </h3>
              </div>


              <div className="permission-columns-container">
                <div className="permission-column">
                  <div className="permission-row first-row">
                    <div className="inputBox">
                      <InputBox
                        required={true}
                        onChange={(value: string) => this.handleChangeFilterMediatorCode(value)}
                        onBlur={(value: string) => this.handleChangeFilterMediatorCode(value)}
                        placeholder="Mediador"
                        type={"text"}
                        value={this.state.filter.mediatorCode}
                      />
                    </div>
                  </div>
                  <div className="permission-row">
                    <div className="inputBox">
                      {products.length > 0 &&
                        <SelectBox
                          selectedMultipleOptions={selectedProductsId}
                          multiple={true}
                          placeholder="Todos los productos"
                          optionsText={products}
                          optionKey={"id"}
                          optionValue={"name"}
                          onMultipleChange={(values) =>
                            this.handleChangeProducts(
                              values
                            )
                          }
                          onChange={() => { }}
                        />
                      }
                    </div>
                  </div>
                  <div className="data-row colSin-100">
                    <div className="left-align">
                      <div className="inputBoxSearch">
                        <div className="add-permission-modal-button    filter-search-button">
                          <MainButton icon={true} iconImg={images.IconSearchWhite} disabled={false} text={"Buscar"} onClick={() => this.handleFilter()} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {(auxProductMediadores.length === 0 && !searched) ? <></> :
                (auxProductMediadores.length === 0 && searched) ? <span> No se han encontrado registros </span> :
                  <>
                    <div className="selectAllButton">
                      <MainButton disabled={false} text={(allSelected) ? "Eliminar todos" : "Seleccionar Todos"} onClick={() => this.seleccionarTodos()} />
                    </div>
                    <div className="permissions-table-container">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Producto Id</th>
                            <th>Mediador</th>
                            <th>Producto</th>
                            <th>Colectivo</th>
                            <th>Combinación comercial</th>
                            <th>Creado</th>
                            <th>Actualizado</th>
                            <th>Estado de Permiso</th>                          
                          </tr>
                        </thead>
                        <tbody>
                          {
                            auxProductMediadores.map((product: any, index: any) => {
                              return <tr className="main-table-row" key={index}>
                                <td className={(product.modify ? 'fondo-modificado' : '')}>
                                  <p className={(product.modify ? 'modificado' : '')} >{product.producto_id}</p>
                                </td>
                                <td className={(product.modify ? 'fondo-modificado' : '')}>
                                  <p className={(product.modify ? 'modificado' : '')} >{product.mediador}</p>
                                </td>
                                <td className={(product.modify ? 'fondo-modificado' : '')}>
                                  <WebText className={`normal tag-container ${PRODUCT_CARD_NAME_COLOR[product.cardName]}`}>
                                    <p className={(product.modify ? 'modificado' : '')} >{product.name}</p>
                                  </WebText>
                                </td>
                                <td className={(product.modify ? 'fondo-modificado' : '')}>
                                  <p className={(product.modify ? 'modificado' : '')} >{product.colectivo}</p>
                                </td>
                                <td className={(product.modify ? 'fondo-modificado' : '')}>
                                  <p className={(product.modify ? 'modificado' : '')} >{product.combinacion_comercial}</p>
                                </td>
                                <td className={(product.modify ? 'fondo-modificado' : '')}>
                                  <p className={(product.modify ? 'modificado' : '')} >{product.createdAt}</p>
                                </td>
                                <td className={(product.modify ? 'fondo-modificado' : '')}>
                                  <p className={(product.modify ? 'modificado' : '')} >{product.updatedAt}</p>
                                </td>
                                <td className={(product.modify ? 'fondo-modificado' : '')}>
                                  <div>
                                    <span onClick={(e: any) => this.handlePermiso(product)}>
                                      <div className={"dicotomic " + (product.permiso ? 'active' : 'inactive')}>
                                        <div className="checkbox-container">
                                          <div className="checkmark-container">
                                            <img src={product.permiso ? images.IconTickWhite : images.IconCloseBlue} alt="" />
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </td>

                              </tr>
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className="save-footer">
                      <div className="save-text">
                        <p>Para actualizar los datos es necesario guardar</p>
                      </div>
                      <div className="data-row colSin-100">
                        <div className="left-align">
                          <div className="inputBoxSearch">
                            <div className="add-permission-modal-button">
                              <MainButton disabled={this.checkDisabled()} text={"Guardar"} onClick={() => this.handleSave()} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
              }
            </div>
          </div>
        </div>
        <div className="hr" />
        <PermissionsUpload />
        {validateSave && (
          <MainModal
            mainTitle={this.textModal}
            buttonText={"Aceptar"}
            centered={true}
            active={validateSave}
            img={images.IconWarningYellow}
            className={"warning"}
            close={() => this.closeModal()}
            onClickAccept={() => this.savePermissions()}
          />
        )}
        <LeftInfoModal
          img={images.WarningError}
          className={"error"}
          mainTitle={"Mediador no encontrado"}
          mainText={mediatorErrorText}
          buttonText={"Cerrar"}
          active={!mediatorExists}
          onClickAccept={() => this.closeMediatorError()}
          close={() => this.closeMediatorError()}
          noSecondaryButton
        />
      </PermissionsContainer>
    )
  }
}

export default withGeneral(PermissionsLayout);
