import React from "react";
import { ButtonTextContainer } from "./button-text-style";

interface ButtonTextProps {
    children: any;
    className?: string;
    color?: string;
    fontSize?: string;
}


const ButtonText = ({children = '', className='', color='', fontSize = '16px'}:ButtonTextProps) => {
        return (
        <ButtonTextContainer color={color} className={className} fontSize={fontSize}>
            {children}
        </ButtonTextContainer>
    )
} 
export default ButtonText;