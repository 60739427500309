import React, { useState } from "react";
import { NewInsuredContainer } from "./newInsuredStepper-style";
import BreadcrumbText from "../../../../components/components-text/breadcrumb-text";
import TakerStep from "./taker/taker-step";
import { TakerDataContainer } from "../../../hirings/new-hiring/gip/gip-taker/gip-taker-data-style";
import MainButton from "../../../../components/main-button";
import { images } from "../../../../assets";
import PolicyWarrantyStep from "./policy-warranty/policy-warranty-step";
import RequiredDocumentsStep from "./required-documents/required-documents-step";
import StepsMenu from "../../../../components/steps-menu/steps-menu";
import useForm from "../hooks/useForm";
import { initialStepperData, validateData, validateEmailByHocelot } from "./utils";
import HealthQuestionnaireDataLayout from "../../../hirings/new-hiring/health/health-questionnaire/health-questionnaire-data-layout";
import { ParsedResponse } from "./questionnaire.utils";
import SelectBox from "../../../../components/select-box/select-box";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import moment from "moment";
import SupplementsServices from "../../../../services/SupplementsServices";
import LeftInfoModal from "../../../../components/left-info-modal";
import { deepGet } from "../../../../utils/utility";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getMediatorCompleteName } from "../../../supplements/utils";

const stepsMenuProps = {
  policyData: {
    policyType: "", // ejemplo: Salud
    policyName: "", // ejemplo: Completa
    disabled: true,
  },
};

const buttonList = [
  { buttonText: "Datos del asegurado", stepNumber: 1 },
  { buttonText: "Garantías", stepNumber: 2 },
  { buttonText: "Documentación requerida", stepNumber: 3 },
  { buttonText: "Cuestionarios", stepNumber: 4 },
];

const NewInsuredStepperForm = (props: RouteComponentProps) => {
  const [steps, setSteps] = useState<number>(1);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState<{status: boolean, valuesForm: ParsedResponse[]}>({status: false, valuesForm: []});
  const [modal, setModal] = useState({ msg: "", status: false, type: "" });
  const [hasFechaEfecto, setHasFechaEfecto] = useState({
    status: false,
    isInitialRender: true,
  });
  const [emailError, setEmailError] = useState("");
  const { values, errors, handleChange, setErrors } = useForm(
    initialStepperData(),
    validateData,
    isFirstTime
  );

    const getPolicyData = () => {
    const policyDataString = localStorage.getItem('policyData') || '{}';
    return JSON.parse(policyDataString);
  }

  const loggedMediatorString = localStorage.getItem("loggedMediator");
  const loggedMediator = loggedMediatorString
    ? JSON.parse(loggedMediatorString)
    : { mediatorCode: "" };

  const goToNextStep = async () => {
    const { errors, hasStepOneValidation, guaranteesHasValidation } = validateData(values);
    const errorEmail = await validateEmailByHocelot(deepGet(values, 'contact.email') || '')
    setEmailError(errorEmail);
    if (steps === 1 && hasStepOneValidation && !errorEmail) {
      setIsFirstTime(true);
      setSteps((previousValue) => previousValue + 1 );
    } else if (steps === 2 && guaranteesHasValidation) {
      setSteps((previousValue) => previousValue + 1);
    } else if (steps === 3) {
      setSteps((previousValue) => previousValue + 1);
    } else {
      setIsFirstTime(false);
      setErrors(errors);
    }
  };

  const goToPreviousStep = () => {
    setSteps((previousValue) => previousValue - 1 );
  };

  const sendForm = async () => {
    handleChange("questionnaire", confirmationModal.valuesForm, "clinicalData");

    const response = await SupplementsServices.sendNewInsuranceEmail({...values, clinicalData: {questionnaire: confirmationModal.valuesForm}});

    if (response.data.status) {
      setModal({
        msg: "Los datos han sido enviado correctamente",
        status: true,
        type: "success",
      });
    } else {
      setModal({ msg: response.data.msg, status: true, type: "error" });
    }
  };

  const closeModal = () => {
    const loggedUserString = localStorage.getItem("loggedUser") || "{}";
    const loggedUser = JSON.parse(loggedUserString);
    const policyDataString = localStorage.getItem("policyData") || "{}";
    const policyData = JSON.parse(policyDataString);
    props.history.push(
      `/polizas-clientes/detalle/${getPolicyData().mediator}/${deepGet(
        policyData,
        "numeroPoliza"
      )}/${deepGet(getPolicyData(), "order", "")}`,
      {
        data: {
          colectivo: {
            text: `${getPolicyData().numeroColectivo}|${getPolicyData().producto}`,
          },
          nif_tomador: {
            text: getPolicyData().nifTomador,
          },
        },
      }
    );
  };
  
  return (
    <NewInsuredContainer>
      <div className="subTitle">
        <BreadcrumbText>
          {"NUEVO SUPLEMENTO - INCORPORAR NUEVO ASEGURADO"}
        </BreadcrumbText>
      </div>
      <div
        className="personal-data-row"
        style={{ width: "calc(100% - 290px)" }}
      >
        <div className="personal-data-item col-50">
          <div className="selectBox">
            <SelectBox
              isMediator={true}
              optionsText={loggedMediator.relatedMediators}
              optionKey={"value"}
              disabled={true}
              optionValue={"label"}
              onChange={() => {}}
              defaultValue={getPolicyData().mediator}
              propValue={getMediatorCompleteName()}
              required
            />
            <label>Mediador</label>
          </div>
        </div>
        <div className="personal-data-item col-50">
          <div className="inputBox">
            <CalendarBox
              initialValue={""}
              placeholder="Fecha de efecto de suplemento"
              minDate={moment().subtract(1, 'days').toISOString()}
              required={true}
              disabled={false}
              errorCode={
                !hasFechaEfecto.status && !hasFechaEfecto.isInitialRender
                  ? "required"
                  : ""
              }
              onChange={(value: string) => {
                localStorage.setItem('policyData', JSON.stringify({...getPolicyData(), fechaEfecto: value}))
                setHasFechaEfecto({ status: true, isInitialRender: false });
              }}
              health={true}
            />
          </div>
        </div>
      </div>
      <div className="step-container">
        <div className="step">
          {steps === 1 && (
            <TakerStep
              values={values}
              errors={errors}
              handleChange={handleChange}
              customError={emailError}
              setCustomError={setEmailError}
            />
          )}
          {steps === 2 && (
            <PolicyWarrantyStep
              values={values}
              errors={errors}
              handleChange={handleChange}
            />
          )}
          {steps === 3 && (
            <RequiredDocumentsStep
              values={values}
              errors={errors}
              handleChange={handleChange}
            />
          )}
          {steps === 4 && (
            <HealthQuestionnaireDataLayout
              data={{
                insureds: [values.taker],
                questionnaires: [values.taker],
              }}
              saveHiring={(value: ParsedResponse[]) => setConfirmationModal({ status: true, valuesForm: value})}
              isSupplementsQuestionaire={true}
              onBack={() => setSteps(3)}
              setHasFechaEfecto={setHasFechaEfecto}
            />
          )}
        </div>
      </div>
      <StepsMenu
        policyData={stepsMenuProps.policyData}
        buttonFunction={setSteps}
        actualStep={steps}
        buttonList={buttonList}
      />

      {steps !== 4 && (
        <TakerDataContainer>
          <div className="colSin-100 topMargin20 bottomMargin20">
            <div className="personal-data-buttons">
              <div className="personal-data-buttons__item">
                <MainButton
                  withTab={true}
                  icon={true}
                  type={"white"}
                  iconImg={images.IconArrowLeftBlue}
                  disabled={steps === 1}
                  text="Atrás"
                  onClick={goToPreviousStep}
                />
              </div>
              <div className="personal-data-buttons__item">
                <MainButton
                  withTab={true}
                  text="Siguiente"
                  disabled={steps === 4}
                  onClick={goToNextStep}
                />
              </div>
            </div>
          </div>
        </TakerDataContainer>
      )}
      <LeftInfoModal
        img={
          modal.type === "error" ? images.WarningError : images.IconCheckGreen2
        }
        mainTitle={modal.type === "error" ? "Error" : "Aviso"}
        mainText={modal.msg}
        noSecondaryButton={true}
        buttonText="Continuar"
        active={modal.status}
        onClickAccept={closeModal}
        close={() => setModal({ msg: "", status: false, type: "" })}
        className={modal.type === "error" ? "error" : "info"}
      />
      <LeftInfoModal
        img={images.IconCheckGreen2}
        mainTitle={"Aviso"}
        mainText={'Se va a enviar una solicitud de suplemento para la incorporación de un nuevo asegurado'}
        secondaryText={'¿Desea Continuar?'}
        noSecondaryButton={false}
        buttonText={"Si"}
        closeButtonText={'NO'}
        active={confirmationModal.status}
        onClickAccept={sendForm}
        close={() => setConfirmationModal({status: false, valuesForm: []})}
        className={"info"}
      />
    </NewInsuredContainer>
  );
};

export default withRouter(NewInsuredStepperForm);
