import styled from "styled-components";
import { colors } from "../../assets";
import { MainButtonContainer } from "../main-button/main-button-style";
import { InputBoxContainer } from "../input-box/input-box-style";

export const ChooseHiringModalContainer = styled.div`
  display: none;
  color: ${colors["PR-001-100"]};
  position: fixed;
  z-index: 9999;
  background: ${colors["background-modal"]};
  left: 0;
  width: 100%;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: "museo sans";

  &.active {
    display: flex;
  }

  &.error {
    color: ${colors["ER-001-100"]};

    .recover-modal-icon-bg {
      background: rgba(255, 65, 0, 0.3);
    }

    ${MainButtonContainer} {
      background: ${colors["ER-001-100"]};
    }
  }

  &.warning {
    .recover-modal-icon-bg {
      background: rgba(255, 240, 201, 0.3);
    }
  }

  .recover-modal {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 20px 40px 40px;
    max-width: 620px;
  }

  .recover-modal-close {
    text-align: right;

    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .recover-modal-main-title {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }

  .recover-modal-icon {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .recover-modal-icon-bg {
    display: inline-block;
    background: rgba(179, 202, 220, 0.3);
    width: 62px;
    min-width: 62px;
    height: 62px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 20px;
    }
  }

  .recover-modal-main-action {
    padding-left: 80px;
  }

  .recover-modal-main {
    padding-right: 65px;
  }

  .recover-modal-main-copy {
    margin-bottom: 20px;
    color: ${colors["TX-001-50"]}};
  }

  .recover-modal-text {
    padding-left: 20px;
  }

  .recover-modal-main-conditions {
    margin-bottom: 40px;
  }

  .recover-modal-main-conditions-item {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .recover-modal-button {
    display: flex;

    ${MainButtonContainer} {
      width: 190px;

      &:first-child {
        margin-right: 12px;
      }
    }
  }

  ${InputBoxContainer} {
    &.disabled {
      .input-box-main-field {
        &.required {
          &:before {
            top: 21px;
          }
        }

        input {
          color: ${colors["TX-001-30"]};
          border: none;
          &::placeholder {
            color: ${colors["TX-001-30"]};
          }
        }
      }
    }

    .input-box-main-field {
      input {
        border: 1px solid ${colors["PR-001-100"]};
        border-radius: 3px;
        padding: 9px 20px;
      }
    }
  }

  .recover-modal-main-container {
    padding-left: 80px;
    margin-bottom: 38px;
  }

  .recover-modal-main-row {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .recover-modal-main-row-title {
    font-weight: 700;
    font-size: 14px;
    color: ${colors["PR-001-100"]};
    text-transform: uppercase;
    position: relative;
    padding-left: 35px;

    .recover-option {
      -webkit-appearance: checkbox;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      display: block;
      width: 16px;
      left: 0;
      top: -2px;
      bottom: 0;
      height: 16px;
      z-index: 99;
    }

    .recover-checkmark {
      position: absolute;
      top: -2px;
      left: 0;
      height: 16px;
      width: 16px;
      border: 1px solid ${colors["PR-001-100"]};
      border-radius: 50%;

      &:hover {
        background: ${colors["PR-001-30"]};
      }
    }

    .recover-option:checked ~ .recover-checkmark {
      background: ${colors["PR-001-100"]};
    }

    .recover-checkmark:after {
      content: "";
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: white;
    }

    .recover-option:checked ~ .recover-checkmark:after {
      display: block;
    }
  }

  .recover-modal-main-row-input {
    margin-top: 18px;
  }

  .export-error{
    display: none;
    color: red;
    &.active{
      display: flex;
    }
  }

  .server-error{
    color: red;
    margin-bottom: 50px;
    font-size: 1.2vw;
  }

  .button-error{
    width: 30%;
    position: relative;
    left: 50%;
    margin-left: -15%;
  }
`;
