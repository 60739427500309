import { SearchPolicyResponseDto, DownloadPolicyResponseDto } from "../containers/clients/clients-policies/dto/client-policies-layout-dto";
import { PolicyDetailResponseDto, TakerDetailResponseDto, RiskDetailResponseDto, ReceiptDetailResponseDto } from "../containers/clients/clients-policies/dto/detail-clients-policies-layout-dto";
import GeneralServices from "./GeneralServices";
import { IPolicyDetail, IPolicyReceiptsDetail, IPolicyRiskDetail, IPolicyTakerDetail, ISearchPolicy } from "./PoliciesServices-dto";

class PoliciesServices {
  getAllPolicies = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `files?filetype=POLI&format=csv`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  getEIACFile = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        arraybuffer: args.arraybuffer,
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `files/download${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  getSelectedEIACFiles = async (args: any, selectedIds:string[]) => {
    try {
      const response = await GeneralServices.get({
        arraybuffer: args.arraybuffer,
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `files/download-selected${args.params ? '?' + args.params.join("&") : ''}${ '&ids='+selectedIds.join('|')}`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  getEIACFiles = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `files/full${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  getPolicies = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `policies${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  getPolicy = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `policies/${args.id}`,
      });
      return response.data;
    }
    catch (err) {
      return {};
    }
  }

  getPolicyFile = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `files/single?filetype=POLI&format=${args.format}&id=${args.id}`,
      });
      return response.data;
    }
    catch (err) {
      return {};
    }
  }

  getComments = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `policies/${args.idPolicy}/comments`,
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  createComment = async (args: any) => {
    try {
      const response = await GeneralServices.post({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `policies/${args.idPolicy}/comments`,
        data: args.data
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  editComment = async (args: any) => {
    try {
      const response = await GeneralServices.put({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `policies/${args.idPolicy}/comments/${args.idComment}`,
        data: args.data
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  deleteComment = async (args: any) => {
    try {
      const response = await GeneralServices.deleteReq({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `policies/${args.idPolicy}/comments/${args.idComment}`
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  searchPolicy = async (setLoading: Function, loader: boolean, queryParams: Array<string>, data: ISearchPolicy): Promise<SearchPolicyResponseDto> => {
    try {
      const response = await GeneralServices.post({
        setLoading: setLoading,
        loader: loader === false ? false : true,
        endpoint: `policies/search${queryParams ? '?' + queryParams.join("&") : ''}`,
        data: data
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  searchPolicyDetail = async (setLoading: Function, loader: boolean, data: IPolicyDetail): Promise<PolicyDetailResponseDto> => {
    try {
      const response = await GeneralServices.post({
        setLoading: setLoading,
        loader: loader === false ? false : true,
        endpoint: `policies/detail`,     
        data: data
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  getPolicyDocuments = async (args: any) => {
    try {
      const response = await GeneralServices.post({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: args.url,
        data: args.data
      });
      return response.data;
    }
    catch (err) {
      return [];
    }
  }

  downloadPolicies = async (setLoading: Function, loader: boolean, queryParams: Array<string>, data: ISearchPolicy): Promise<DownloadPolicyResponseDto> => {
    try {
      const response = await GeneralServices.post({
        setLoading: setLoading,
        loader: loader === false ? false : true,       
        endpoint: `policies/download${queryParams ? '?' + queryParams.join("&") : ''}`,
        data: data
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  searchTakerDetail = async (setLoading: Function, loader: boolean, data: IPolicyTakerDetail): Promise<TakerDetailResponseDto> => {
    try {
      const response = await GeneralServices.post({
        setLoading: setLoading,
        loader: loader === false ? false : true,
        endpoint: `policies/taker`,
        data: data
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  searchRiskDetail = async (setLoading: Function, loader: boolean, queryParams: Array<string>, data: IPolicyRiskDetail): Promise<RiskDetailResponseDto> => {
    try {
      const response = await GeneralServices.post({
        setLoading: setLoading,
        loader: loader === false ? false : true,
        endpoint: `policies/risk${queryParams ? '?' + queryParams.join("&") : ''}`,
        data: data
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  searchReceiptDetail = async (setLoading: Function, loader: boolean, queryParams: Array<string>, data: IPolicyReceiptsDetail): Promise<ReceiptDetailResponseDto> => {
    try {
      const response = await GeneralServices.post({
        setLoading: setLoading,
        loader: loader === false ? false : true,
        endpoint: `policies/receipt${queryParams ? '?' + queryParams.join("&") : ''}`,
        data: data
      });
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }
}

export default new PoliciesServices();