import moment from "moment";
import { IInsuranceProposalRequest } from "../utils/interfaces";
import GeneralServices from "./GeneralServices";
import GestDocServices from "./Gest-Doc.Services";
import { BuildHiringPrecontractualDocumentRequest, BuildHiringPrecontractualDocumentResponseDto } from "./HiringServices-dto";
import { PRODUCT_TYPE } from "../constants/policyType";

class HiringServices {

  vida = async (args: any) => {
    try {
      const response1 = await GeneralServices.post(
        {
          setLoading: args.setLoading,
          loader: (args.loader && args.loader) === false ? false : true,
          endpoint: "hiring/life",
          data: args.data,
        },
        true
      );
      if(args.data.productType===PRODUCT_TYPE.MORTGAGE){
        const response2 = await GeneralServices.get({
          setLoading: args.setLoading,
          loader: (args.loader && args.loader) === false ? false : true,
          endpoint: `hiring/life/${args.data.productType}/${response1.data.id.policyId}`,
        });
        return response2.data
      }else{
        return response1.data;
      }
    } catch (err) {
      throw err;
    }
  };

  lifeSimulation = async (args: any) => {
    try {
      const response = await GeneralServices.post(
        {
          setLoading: args.setLoading,
          isSlowProcess: true,
          loader: (args.loader && args.loader) === false ? false : true,
          endpoint: "hiring/lifeSimulation",
          data: args.data,
        }
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  };


  decesos = async (args: any) => {
    try {
      const response = await GeneralServices.post(
        {
          setLoading: args.setLoading,
          loader: (args.loader && args.loader) === false ? false : true,
          endpoint: "hiring/death",
          data: args.data,
        }
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  deathSimulation = async (args: any) => {
    try {
      const response = await GeneralServices.post(
        {
          setLoading: args.setLoading,
          isSlowProcess: true,
          loader: (args.loader && args.loader) === false ? false : true,
          endpoint: "hiring/deathSimulation",
          data: args.data,
        }
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  mascotas = async (args: any) => {
    try {
      if (args.data && args.data.insureds) {
        args.data.insureds = args.data.insureds.map((insured: any) => ({
          ...insured,
          birthdate: moment.utc(insured.birthdate).toISOString(),
        }));
      }
      const response = await GeneralServices.post(
        {
          setLoading: args.setLoading,
          loader: (args.loader && args.loader) === false ? false : true,
          endpoint: "hiring/pet",
          data: args.data,
        }
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  petSimulation = async (args: any) => {
    try {
      if (args.data && args.data.insureds) {
        args.data.insureds = args.data.insureds.map((insured: any) => ({
          ...insured,
          birthdate: moment.utc(insured.birthdate).toISOString(),
        }));
      }
      const response = await GeneralServices.post(
        {
          setLoading: args.setLoading,
          loader: (args.loader && args.loader) === false ? false : true,
          isSlowProcess: true,
          endpoint: "hiring/petSimulation",
          data: args.data,
        }
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  travel = async (args: any) => {
    try {
      const response = await GeneralServices.post(
        {
          setLoading: args.setLoading,
          loader: (args.loader && args.loader) === false ? false : true,
          endpoint: "hiring/travel",
          data: args.data,
        }
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  travelSimulation = async (args: any) => {
    try {
      const response = await GeneralServices.post(
        {
          setLoading: args.setLoading,
          isSlowProcess: true,
          loader: (args.loader && args.loader) === false ? false : true,
          endpoint: "hiring/travelSimulation",
          data: args.data,
        }
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  save = async (args: any) => {
    try {
      const response = await GeneralServices.post({
        setLoading: args.setLoading,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: "hiring",
        data: args.data,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  getHiring = async (loader: boolean = true, setLoading: Function, params?: string[]) => {
    try {
      const response = await GeneralServices.get({
        loader: loader === false ? false : true,
        endpoint: `hiring${params ? "?" + params.join("&") : ""}`,
        setLoading: setLoading,
      });
      return response.data;
    } catch (err) {
      return err;
    }
  };

  getOneHiring = async (args: any) => {
    try {
      // Obtenemos el mediador logeado
      const loggedMediator = JSON.parse(localStorage.getItem("loggedMediator") || '');
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `hiring${args.mediator ? '/' + args.mediator : ''}/${args.id}/${loggedMediator.mediatorCode}`,
      });

      return response.data;
    } catch (err) {
      return {};
    }
  };

  buildHiringPrecontractualDocument = async (setLoading: Function, loader: boolean, request: BuildHiringPrecontractualDocumentRequest): Promise<BuildHiringPrecontractualDocumentResponseDto> => {
    try {
      const response = await GeneralServices.postFileResponse({
        setLoading: setLoading,
        loader: loader,
        endpoint: "senda/buildHiringPrecontractualDocument",
        data: request,
      }).catch((e) => {
        throw e;
      });

      return {
        fileData: response.data,
        fileName: GestDocServices.getFileName(response.headers["content-disposition"]),
        fileType: response.headers["content-type"],
      }

    } catch (err) {
      throw err;
    }
  }


  generateInsuranceProposal = async (setLoading: Function, loader: boolean, policyType: string, request: IInsuranceProposalRequest): Promise<BuildHiringPrecontractualDocumentResponseDto> => {
    try {
      const response = await GeneralServices.postFileResponse({
        setLoading,
        loader,
        endpoint: `senda/getInsuranceProposal?policyType=${policyType}`,
        data: request,
      });
      return {
        fileData: response.data,
        fileName: GestDocServices.getFileName(response.headers["content-disposition"]),
        fileType: response.headers["content-type"],
      }
    } catch (error) {

      throw error;
    }
  }

  generateInsuranceRequest = async (setLoading: Function, loader: boolean, policyType: string, request: IInsuranceProposalRequest): Promise<BuildHiringPrecontractualDocumentResponseDto> => {
    try {
      const response = await GeneralServices.postFileResponse({
        setLoading,
        loader,
        endpoint: `senda/getInsuranceRequest?policyType=${policyType}`,
        data: request,
      });
      return {
        fileData: response.data,
        fileName: GestDocServices.getFileName(response.headers["content-disposition"]),
        fileType: response.headers["content-type"],
      }
    } catch (error) {

      throw error;
    }
  }

  updateSignHiring = async (args: any) => {
    try {
      const response = await GeneralServices.put(
        {
          setLoading: args.setLoading,
          loader: (args.loader && args.loader) === false ? false : true,
          endpoint: "hiring/updateSignHiring",
          data: args.data,
        }
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  updateStatusHiring = async (args: any) => {
    try {
      const response = await GeneralServices.put(
        {
          setLoading: args.setLoading,
          loader: (args.loader && args.loader) === false ? false : true,
          endpoint: "hiring/updateHiringStatus",
          data: args.data,
        }
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  };

}

export default new HiringServices();
