import styled from "styled-components";
import { colors } from "../../assets";
import { MainButtonContainer } from "../main-button/main-button-style";

export const MainModalContainer = styled.div`
  display: none;
  color: ${colors["PR-001-100"]};
  position: fixed;
  z-index: 9999;
  background: ${colors["background-modal"]};
  left: 0;
  width: 100%;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &.active {
    display: flex;
  }

  &.error {
    color: ${colors["ER-001-100"]};

    .main-info-modal-icon-bg {
      background: rgba(255, 217, 204, 0.3);
    }

    ${MainButtonContainer} {
      background: ${colors["ER-001-100"]};
    }
  }

  &.warning {
    .main-info-modal-icon-bg {
      background: rgba(255, 240, 201, 0.3);
    }
  }

  .main-info-modal-container {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 20px 22px 40px 40px;
    max-width: 620px;
    width: 620px;
    max-height: 90vh;
    overflow: hidden;
  }

  .main-info-modal-close {
    text-align: right;

    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .main-info-modal-main-title {
    display: flex;
    flex-direction: ${props => (props.centered ? "column" : "row")};
    align-items: center;
    margin-bottom: ${props => (props.centered ? "0" : "20px")};
  }

  .main-info-modal-icon {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-info-modal-icon-bg {
    display: flex;
    margin-bottom: ${props => (props.centered ? "20px" : "0")};
    background: rgba(179, 202, 220, 0.3);
    width: 62px;
    min-width: 62px;
    height: 62px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    img {
      max-width: 20px;
    }
  }

  .main-info-modal-main-content {
    padding-left: ${props => (props.centered ? "0" : "80px")};
  }

  .main-info-modal-main {
    padding-right: ${props => (props.centered ? "0" : "65px")};
  }

  .main-info-modal-main-copy {
    margin-bottom: 40px;
    color: ${colors["TX-001-50"]}};
  }

  .main-info-modal-text {
    padding-left: ${props => (props.centered ? "0" : "20px")};
    margin-bottom: ${props => (props.centered ? "48px" : "0")};
  }

  .main-info-modal-main-conditions {
    margin-bottom: 40px;
  }

  .main-info-modal-main-conditions-item {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .main-info-modal-button {
    display: flex;
    justify-content: ${props => (props.centered ? "center" : "initial")};

    ${MainButtonContainer} {
      width: 160px;
    }
  }

  .main-info-modal-table {
    background: rgba(181, 188, 198, 0.08);
    border-radius: 3px;
    padding: 28px 20px 20px;
    color: #004f8b;
    font-family: "museo sans";
    margin-bottom: 48px;
  }
  .main-info-modal-table-title  {
    margin-bottom: 24px;
  }

  .main-info-modal-table-content {
    &__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .main-info-modal-main-input {
    margin-bottom: 60px;
  }
`;
