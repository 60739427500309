import React, { Component } from 'react';
import { TooltipBoxLightContainer } from './tooltip-box-light-style';
import InfoText from '../components-text/info-text';
import Menu02Text from '../components-text/menu-02-text';

interface TooltipBoxLightProps {
    active: boolean;
    tooltipTitle: string;
    tooltipText: string;
    leftArrow?: boolean;
    noText?: boolean;
    type?: string;
}

class TooltipBoxLight extends Component<TooltipBoxLightProps, {}> {

    public static defaultProps = {
        active: false,
        tooltipTitle: 'Titulo string',
        tooltipText: 'body string'
    }


    render() {
        const { active, tooltipTitle, tooltipText, leftArrow, noText } = this.props;

        return (
            <TooltipBoxLightContainer className={`${active ? 'active' : ''}`} leftArrow={leftArrow}>
                <div className="tooltip-modal">
                    <div className="tooltip-title">
                        <p>{tooltipTitle}</p>
                    </div>
                    {!noText && (<div className="tooltip-main">
                        <InfoText>
                            <p>{tooltipText}</p>
                        </InfoText>
                    </div>)}
                </div>
            </TooltipBoxLightContainer>
        )
    }
}

export default TooltipBoxLight