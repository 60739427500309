import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface ScrollToTopProps extends RouteComponentProps {

}

interface ScrollToTopState {

}

class ScrollTop extends Component<ScrollToTopProps, ScrollToTopState> {

  componentDidUpdate(prevProps: ScrollToTopProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollTop)