import { Dispatch, SetStateAction } from "react";
import requiredDocuments from "../../../../../utils/requiredDocuments";
import { ConstantsResponseBoxDto } from "../../../../hirings/new-hiring/common/required-documentation/required-doc-data-layout";

export const loadDocumentTypes = async (products_id: number, setDocumentTypes: Dispatch<SetStateAction<ConstantsResponseBoxDto[]>>) => {
  let result = await requiredDocuments.getDocumentTypes(() => {}, products_id);
  setDocumentTypes(result.documentTypes)
}

export const tableColumns = [
  "Tipo de documento",
  "Asegurado",
  "Opciones",
  "Tamaño *",
];

export const getType = (
  type: string | undefined,
  documentTypes: ConstantsResponseBoxDto[]
): string => {
  let elem = documentTypes.find((doc) => doc.value === type);
  if (elem) {
    return elem.label;
  }
  return "";
};

export const getFileSize = (size: number) => {
  if (size < 1024) {
    return `${size} bytes`;
  } else if (size >= 1024 && size < 1048576) {
    return `${(size / 1024).toFixed(1)} KB`;
  } else if (size >= 1048576) {
    return `${(size / 1048576).toFixed(1)} MB`;
  }
};
