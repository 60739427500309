import moment from "moment";

import GeneralServices from "./GeneralServices";
import { BaseServiceRequestDto } from "./dto/request/BaseServiceRequest.dto";
import { IAddNotification, IUpdateNotification } from "./CommunicationsServices-dto";
import { getShowNotificationsFormat, sortByType } from "../containers/communications/helpers";
import { STATUS } from "../constants/communications";

interface IGetAll extends BaseServiceRequestDto {
  filter: any,
}
interface ICreateRequest extends BaseServiceRequestDto {
  notification: IAddNotification,
}

interface IUpdateRequest extends BaseServiceRequestDto {
  notification: IUpdateNotification,
  id: string,
}

class CommunicationsServices {
  getTypes = async ({ setLoading, loader }: BaseServiceRequestDto): Promise<any> => {
    try {
      const response = await GeneralServices.get({
        setLoading,
        loader,
        endpoint: "communications/getTypes",
      });
      return {
        ok: true,
        data: response.data
      };
    } catch (error) {
      return {
        error: true,
        message: typeof error === "string" ? error : "Se ha producido un error, inténtelo más tarde.",
      }
    }
  }
  
  getAll = async ({ setLoading, loader }: BaseServiceRequestDto): Promise<any> => {
    try {
      const response = await GeneralServices.get({
        setLoading,
        loader,
        endpoint: "communications/getAll",
      });
      return {
        ok: true,
        data: response.data
      };
    } catch (error) {
      return {
        error: true,
        message: typeof error === "string" ? error : "Se ha producido un error, inténtelo más tarde.",
      }
    }
  }

  getByQuery = async ({ setLoading, loader, filter }: IGetAll): Promise<any> => {
    try {
      const query = new URLSearchParams(filter);
      const response = await GeneralServices.get({
        setLoading,
        loader,
        endpoint: `communications/get?${query}`,
      });
      return {
        ok: true,
        data: response.data
      };
    } catch (error) {
      return {
        error: true,
        message: typeof error === "string" ? error : "Se ha producido un error, inténtelo más tarde.",
      }
    }
  }

  create = async ({ setLoading, loader, notification }: ICreateRequest): Promise<any> => {
    try {
      const response = await GeneralServices.post({
        setLoading,
        loader,
        endpoint: "communications/add",
        data: notification,
      });
      return {
        ok: true,
        data: response.data
      };
    } catch (error) {
      return {
        error: true,
        message: typeof error === "string" ? error : "Se ha producido un error, inténtelo más tarde.",
      }
    }
  }

  update = async ({ setLoading, loader, notification, id }: IUpdateRequest): Promise<any> => {
    try {
      const response = await GeneralServices.put({
        setLoading,
        loader,
        endpoint: "communications/edit",
        data: notification,
      });
      return {
        ok: true,
        data: response.data
      };
    } catch (error) {
      return {
        error: true,
        message: typeof error === "string" ? error : "Se ha producido un error, inténtelo más tarde.",
      }
    }
  }

  delete = async ({ setLoading, loader, notification, id }: IUpdateRequest): Promise<any> => {
    // sólo cambia el estado a "deleted", no se borra de la base de datos
    try {
      const response = await GeneralServices.put({
        setLoading,
        loader,
        endpoint: "communications/remove",
        data: notification,
      });
      return {
        ok: true,
        data: response.data
      };
    } catch (error) {
      return {
        error: true,
        message: typeof error === "string" ? error : "Se ha producido un error, inténtelo más tarde.",
      }
    }
  }

  getHomeNotifications = async (user : { roles: string[]}) => {
    const roles = (user && user.roles) || [];
    const newroles = [...roles];
    // El ADMIN ve las notificaciones de OCASO y ASISA
    if (newroles.includes("ADMIN")) {
      if (!newroles.includes("SUSCAGENTEOCASO")) newroles.push("SUSCAGENTEOCASO");
      if (!newroles.includes("SUSCAGENTE")) newroles.push("SUSCAGENTE");
    }
    const filter = {
      fechaActual: moment().format("YYYY-MM-DD").toString(),
      allowedMediatorType: [...newroles, "all"],
      estadoId: STATUS.ACTIVE,
    };

    try {
      const response = await this.getByQuery({
        setLoading: () => {},
        loader: false,
        filter,
      });
      const formatedNotifications = getShowNotificationsFormat(response.data);
      const sortedNotifications = sortByType(formatedNotifications)
      return {
        ok: true,
        data: sortedNotifications.slice(0, 3),
      };
    } catch (error) {
      return {
        error: true,
        message: typeof error === "string" ? error : "Se ha producido un error, inténtelo más tarde.",
      }
    }
  }
}

export default new CommunicationsServices();
