import React from "react";
import {
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import {
  NewSimulationContainer,
  ChoosePolicyContainer
} from "../new-simulation-style";
import { images } from "../../../../assets";
import CheckboxModal from "../../../../components/checkbox-modal";
import GuideLine from "../../../../components/guide-line";
import Title02Text from "../../../../components/components-text/title-02-text";
import { withPolicy } from "../../../../context/mixin/with-policy";
import BreadcrumbText from "../../../../components/components-text/breadcrumb-text";
import { STORAGE_KEY } from "../../../../constants/health";
import { ERROR_MESSAGES } from "../../../../constants/errorMessages";
import MediatorServices from "../../../../services/MediatorServices";
import { isString } from "util";
import DynamicProducts from "../../../../components/dynamic-products/DynamicProducts";

interface NewSimulationProps extends RouteComponentProps {
  policy: any;
  setLoading: Function;
  hasInformativeNote?: boolean;
  user: any;
}

interface checkboxConditionsInternObject {
  checkboxText: string;
  errorText: string;
  checked: boolean;
  name: string;
  onChange: Function;
  showError: boolean;
  [key: string]: any;
}

interface checkboxConditionsObject {
  [key: string]: checkboxConditionsInternObject;
}

interface PolicyLinkItem {
  text: string;
  link: string;
}

interface NewSimulationState {
  checkboxConditions: checkboxConditionsObject;
  enabledButton: boolean;
  guideLineCurrentStep: number;
  healthPolicySelected: PolicyLinkItem;
  redirectLink: string;
  mediatorType: String;
  hiringAllowed: boolean;
  noInformativeNoteMessage: String;
  isCallingMediator: boolean;
}


class NewSimulationLayout extends React.Component<
  NewSimulationProps,
  NewSimulationState
> {

  constructor(props: NewSimulationProps) {
    super(props);
    this.state = {
      checkboxConditions: {
        cond1: {
          checkboxText:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit, commodi.",
          errorText: "Este campo es obligatorio",
          checked: false,
          name: "cond1",
          onChange: (ev: any) => this.handleOnChange(ev),
          showError: false
        },
        cond2: {
          checkboxText:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit, commodi.",
          errorText: "Este campo es obligatorio",
          checked: false,
          name: "cond2",
          onChange: (ev: any) => this.handleOnChange(ev),
          showError: false
        }
      },
      enabledButton: true,
      guideLineCurrentStep: 0,
      healthPolicySelected: { text: "", link: "" },
      redirectLink: "",
      mediatorType: '0',
      hiringAllowed: true,
      noInformativeNoteMessage: '',
      isCallingMediator: false,
    };
  }

  typePoliciesText = ["1", "2", "3", "4"];

  guideLine = [
    {
      onCLick: () => { }
    },
    {
      onCLick: () => { }
    },
    {
      onCLick: () => { }
    }
  ];

  LeftInfoModal: any = null;

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    localStorage.removeItem('internalName')
    this.props.policy.policyInfo.changePolicyType();
    localStorage.removeItem(STORAGE_KEY.sKey);
  };

  async componentWillReceiveProps(nextProps: NewSimulationProps) {
    if (nextProps.user !== '' && this.state.mediatorType === '0' && !this.state.isCallingMediator) {
      if (isString(nextProps.user)) {
        const user = JSON.parse(nextProps.user);
        this.setState({ isCallingMediator: true }, () => this.checkMediatorType(user.code));
      }
      else
        this.setState({ isCallingMediator: true }, () => this.checkMediatorType(nextProps.user.code));
    }
  }

  async checkMediatorType(userCode: string) {
    const response = await MediatorServices.getMediatorInfo({
      setLoading: this.props.setLoading,
      loader: true,
      mediatorCode: userCode,
    }).catch(() => {
      throw ERROR_MESSAGES["server-error"];
    });

    this.checkIsMediatorAllowed(response.type)
  }

  checkIsMediatorAllowed(mediatorType: String) {
    const { hasInformativeNote } = this.props;

    if (!hasInformativeNote) {
      if (mediatorType.includes('1') || mediatorType.includes('4') || mediatorType.includes('6')) {
        this.setState({ hiringAllowed: false, noInformativeNoteMessage: 'Es necesaria la nota informativa para simular una póliza', isCallingMediator: false });
      }
      else {
        this.setState({ noInformativeNoteMessage: 'Es obligatorio el envío al cliente de su nota informativa de distribuidor', isCallingMediator: false });
      }
    }

  }

  goToPendantSimulation() {
    this.props.history.push("/simulacion/pendiente");
  }

  enableAcceptButton() {
    const { checkboxConditions } = this.state;
    const validated =
      Object.keys(checkboxConditions).filter(key => {
        if (!checkboxConditions[key].checked) {
          return checkboxConditions[key];
        }
      }).length !== 0;
    this.setState({ enabledButton: validated });
  }

  handleOnChange(ev: any) {
    const { checkboxConditions } = this.state;
    checkboxConditions[ev.target.name].checked = !checkboxConditions[
      ev.target.name
    ].checked;
    this.setState({ checkboxConditions }, () => this.enableAcceptButton());
  }

  setGuideLine = (step: number) => {
    this.setState({ guideLineCurrentStep: step });
  };

  guideLineModificator = (step: number) => {
    const { guideLineCurrentStep } = this.state;

    if (guideLineCurrentStep === 0 && step === -1) {
      return;
    }

    if (guideLineCurrentStep === this.guideLine.length - 1 && step === 1) {
      return;
    }

    this.setState({ guideLineCurrentStep: guideLineCurrentStep + step });
  };

  changeTextSwitchCurrentStep = (arrayText: any) => {
    const { guideLineCurrentStep } = this.state;
    let text: string = arrayText[guideLineCurrentStep];
    return text;
  };

  setItemHealthClick = (healthPolicySelected: PolicyLinkItem) => {
    this.setState({ healthPolicySelected });
  };

  navigateHealth() {
    const { healthPolicySelected } = this.state;
    this.props.history.push(healthPolicySelected.link);
  }

  render() {
    const {
      checkboxConditions,
      enabledButton,
      guideLineCurrentStep,
      hiringAllowed,
      noInformativeNoteMessage
    } = this.state;
    let hide: string;
    return (
      <NewSimulationContainer>
        <div
          className={`new-simulation-flow-name ${(hide =
            guideLineCurrentStep == 0 ? "hide" : "")}`}
        >
          <BreadcrumbText>
            <p>{'NUEVA SIMULACIÓN'}</p>
          </BreadcrumbText>

        </div>

        <div className="new-simulation-flow-status">
          {/* <div className="new-simulation-flow-status-title">
            <HeadsTitleText>
              <p>Tipo de póliza</p>
            </HeadsTitleText>
          </div> */}
          <div className="new-simulation-title">
            <Title02Text>
              <p>Escoja la póliza a simular</p>
            </Title02Text>
          </div>
          <div className="new-simulation-flow-status-guide">
            <GuideLine
              steps={this.guideLine}
              currentStep={guideLineCurrentStep}
            />
          </div>
        </div>

        <ChoosePolicyContainer>
          <div className="policies-container">
            <DynamicProducts
              type="simulacion"
              setItemHealthClick={(value: PolicyLinkItem) => this.setItemHealthClick(value)}
              navigateHealth={() => this.navigateHealth()}
            />
          </div >
        </ChoosePolicyContainer >
        <div>
          <p> En cumplimiento de lo establecido en el artículo 173 del Real Decreto-Ley 3/2020, de 4 de febrero le informamos que es
            responsabilidad del mediador la entrega de su nota informativa al tomador/asegurado  así como sus actualizaciones, sin que,
            la utilización de este portal le exima de comprobar el cumplimiento de esta obligación</p>
        </div>

        {/* sacar modal antes de iniciar la simulacion */}
        <CheckboxModal
          img={images.IconWarningYellow}
          mainTitle="Acepta para continuar"
          mainText="Si vuelve a simular perderá todos los datos guardados en la simulación anterior."
          checkConditions={checkboxConditions}
          enableAcceptButton={enabledButton}
          onClickAccept={() => this.goToPendantSimulation()}
        />
        <div className={`${!hiringAllowed && 'informative-note-message'}`}>
          <p>{noInformativeNoteMessage}</p>
        </div>
      </NewSimulationContainer >
    );
  }
}

export default withPolicy(withRouter(NewSimulationLayout));
