import moment from "moment";
import { deepGet } from "./utility";

type StepType = {
  startDate: { min: number | undefined; max: number | undefined };
  endDate: { min: number | undefined; max: number | undefined };
};

export type MaxMinDatesType = {
  startMinDate: string | undefined;
  startMaxDate: string | undefined;
  endMinDate: string | undefined;
  endMaxDate: string | undefined;
  fixEndDate: boolean;
};

export const defaultMaxMinDates = {
  startMinDate: undefined,
  startMaxDate: undefined,
  endMinDate: undefined,
  endMaxDate: undefined,
  fixEndDate: false,
};

export function getMaxMinDates(startDate: string, step: StepType): MaxMinDatesType {
  const startDateMin = deepGet(step, "startDate.min") || 0;
  const startDateMax = deepGet(step, "startDate.max", 0);
  const endDateMin = deepGet(step, "endDate.min");
  const endDateMax = deepGet(step, "endDate.max");
  const fixEndDate = endDateMin !== undefined && endDateMin === endDateMax;

  // Restamos 1 día por el funcionamiento heredado del componente CalendarBox
  const calculatedDates = {
    startMinDate: moment()
      .add(Number(startDateMin) - 1, "days")
      .toLocaleString(),
    startMaxDate: moment()
      .add(Number(startDateMax) - 1, "days")
      .toLocaleString(),
    endMinDate: moment(startDate, "DD/MM/YYYY")
      .add(Number(endDateMin) - 1, "days")
      .toLocaleString(),
    endMaxDate: moment(startDate, "DD/MM/YYYY")
      .add(Number(endDateMax) - 1, "days")
      .toLocaleString(),
    fixEndDate,
  };

  return calculatedDates;
}
