import React, { Component } from "react";
import { CampaignsCreateStepContainer } from "./campains-create-steps-style";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import InputBox from "../../../../components/input-box/input-box";
import MainButton from "../../../../components/main-button";

interface ICampaingsCreateStep3Props {
  setDisabledNext: (disabled: boolean) => void;
  goNext: boolean;
  onNext: (state: ICampaingsCreateStep3State) => void;
  saveState: (state: ICampaingsCreateStep3State) => void;
  previousState?: ICampaingsCreateStep3State;
  isContest: boolean;
}

export interface ICampaingsCreateStep3State {
  formData: {
    name: string;
    prize: string;
    errors: { [key: string]: string };
  }[];
}

class CampaingsCreateStep3 extends Component<
  ICampaingsCreateStep3Props,
  ICampaingsCreateStep3State
  > {
  constructor(props: ICampaingsCreateStep3Props) {
    super(props);
    this.state = {
      formData: [
        {
          name: "",
          prize: "",
          errors: {}
        }
      ]
    };
  }

  componentDidMount() {
    const { previousState, setDisabledNext } = this.props;
    setDisabledNext(false);
    if (previousState) this.setState(previousState);
  }

  componentDidUpdate(prevProps: ICampaingsCreateStep3Props) {
    const { goNext, previousState } = this.props;
    if (goNext && prevProps.goNext !== goNext) {
      this.handleNext();
    }
    if (previousState && prevProps.previousState !== previousState) {
      this.setState(previousState);
    }
  }

  handleNext() {
    const { onNext } = this.props;
    const { formData } = this.state;
    let isCorrect = true;
    formData.forEach((form, index) => {
      const errors: { [key: string]: string } = {};
      Object.entries(form).forEach((entry: any) => {
        const field = entry[0];
        const value: string | number = entry[1];
        if ((!value || value === "") && value !== 0) {
          errors[field] = "required";
          isCorrect = false;
        }
      });
      formData[index].errors = errors;
    });
    this.setState({ formData }, () => {
      if (isCorrect) onNext(this.state);
    });
  }

  handleChange(field: string, value: string, index: number) {
    const { formData } = Object.assign(this.state);
    formData[index][field] = value;
    this.setState({ formData });
  }

  handleAddPrize() {
    const { saveState } = this.props;
    const { formData } = this.state;
    formData.push({
      name: "",
      prize: "",
      errors: {}
    });
    this.setState({ formData }, () => {
      saveState(this.state);
    });
  }

  render() {
    const { isContest } = this.props;
    const { formData } = this.state;
    const formattedFormData: any = formData//isContest ? formData : [formData[0]];
    return (
      <CampaignsCreateStepContainer>
        <div className="section">
          {formattedFormData.map((item: any, index: number) => (
            <>
              <div className="section__title">
                {/* {isContest
                  ? `PREMIO ${index + 1}`
                  : "DEFINIR PREMIO DEL OBJETIVO"} */}
                {`PREMIO ${index + 1}`}
              </div>
              <div className="section__subtitle"></div>
              <div className={"col-50"}>
                <div>
                  <InputBox
                    labelText="NOMBRE"
                    required={true}
                    errorCode={item.errors.name}
                    value={item.name}
                    onChange={(value: string) =>
                      this.handleChange("name", value, index)
                    }
                  />
                </div>
                <div>
                  <InputBox
                    labelText="PREMIO"
                    required={true}
                    errorCode={item.errors.prize}
                    value={item.prize}
                    onChange={(value: string) =>
                      this.handleChange("prize", value, index)
                    }
                  />
                </div>
              </div>
            </>
          ))}
        </div>
        {//isContest && (
          <div className={"step-3-button-container"}>
            <MainButton
              type={"light"}
              text={"+ AÑADIR MÁS"}
              onClick={() => this.handleAddPrize()}
            />
          </div>
          //)
        }
      </CampaignsCreateStepContainer>
    );
  }
}

export default CampaingsCreateStep3;
