export const getPolicyData = () => {
  const policyData = localStorage.getItem("policyData");
  const isOcasoPolicyString = localStorage.getItem("loggedUser") || "{}";
  const isOcasoPolicyObj = JSON.parse(isOcasoPolicyString);
  const isOcasoPolicy =
    isOcasoPolicyObj &&
    isOcasoPolicyObj.roles &&
    Array.isArray(isOcasoPolicyObj.roles)
      ? isOcasoPolicyObj.roles.includes("SUSCAGENTEOCASO")
      : false;
  return policyData
    ? { ...JSON.parse(policyData), isOcasoPolicy }
    : { isOcasoPolicy };
};
