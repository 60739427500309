import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import Title03Text from "../../../../../components/components-text/title-03-text";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import GipInsuredResultItem from "../../../../../components/gip-insured-item/gip-insured-result-item";
import { GipHireMenuHelper } from "../../../../../components/gip-menu/gip-hire-menu";
import GipPaymentResultItem from "../../../../../components/gip-result-item/gip-payment-result-item";
import MainButton from "../../../../../components/main-button";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { formatter } from "../../../../../utils/utility";
import { GipPriceDataContainer } from "./gip-price-data-style";
import { calcAge } from "../../../../../utils/utility";
import { getDayMonthYear } from "../../../../../utils/time";
import HeadText from "../../../../../components/components-text/head-text";
import NoteText from "../../../../../components/components-text/note-text";
import WebText from "../../../../../components/components-text/web-text";
import SelectBox from "../../../../../components/select-box/select-box";
import { HealthPricingSimulationPaymentOptionDto } from "../../../../../services/dto/response/GipSimulationResponse.dto";
import { GIP_PRODUCTS_NAME } from "../../../../../constants/gipProducts";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { DEFAULT_PAY_METHOD } from "../../../../../constants/payTypes";

interface PriceProps extends RouteComponentProps {
  user: any;
  setLoading: Function;
  newStep: Function;
  data: any;
  policy: any;
  saveHiring: Function;
  isReadOnly?: boolean;
  coverages: any;
  match: any;
  removeSign:Function;
}

interface PriceState {
  modalErrorShow: boolean;
  modalErrorText: string;
  insuredData: any[];
  paymentTime: string;
  priceData: any;
  priceDataSelected: any;
  priceDataInsured: any;
  priceDataInsuredSelected: any;
  taker: any;
  cancelSignModalShow:boolean;
  anyChange:boolean;
}

class GipPriceDataLayout extends React.Component<PriceProps, PriceState> {

  constructor(props: any) {
    super(props);

    this.state = {
      modalErrorShow: false,
      modalErrorText: '',
      insuredData: this.props.data.insureds && this.props.data.insureds.length > 0 ? this.props.data.insureds : [],
      paymentTime: this.props.data.taker.policy.paymentType,
      priceData: this.props.data.pricingData.priceData,
      priceDataSelected: this.props.data.pricingData.priceDataSelected,
      priceDataInsured: this.props.data.pricingData.priceDataInsured,
      priceDataInsuredSelected: this.props.data.pricingData.priceDataInsuredSelected,
      taker: this.props.data.taker,
      cancelSignModalShow:false,
      anyChange: false
    };
  }



  componentDidMount() {
  }

  handlePriceChange(value: string) {
    let {paymentTime} = this.state
    const {policy} = this.props

    if (paymentTime !== value){
      this.setState({anyChange: true});
    }
    this.setState({ paymentTime: value })
    const { priceData, priceDataInsured, taker } = this.state;
    let tipoPago: any = policy.productPayTypes.find((payType:any) => payType.value === value)
    let codPago = tipoPago ? parseInt(tipoPago.num) : 0
    let paymentInsuredSelected: HealthPricingSimulationPaymentOptionDto[] = [];

    priceData.forEach((priceOption: HealthPricingSimulationPaymentOptionDto) => {
      if (priceOption.CodFormaPago === codPago) {
        this.setState({ priceDataSelected: priceOption });
      }
    })
    priceDataInsured.forEach((priceSet: HealthPricingSimulationPaymentOptionDto[]) => {
      priceSet.forEach(priceOption => {
        if (priceOption.CodFormaPago === codPago) {
          paymentInsuredSelected.push(priceOption)
        }
      })
    })

    this.setState({ priceDataInsuredSelected: paymentInsuredSelected })
    let newTaker = taker;
    if (newTaker.policy)
      newTaker.policy.paymentType = value
    this.setState({ taker: newTaker })
  }

  async savePriceData() {
    if (this.props.isReadOnly) {
      this.props.history.push(
        GipHireMenuHelper.getStepNavigationPath(
          GipHireMenuHelper.getStepFromNavigationRoute(
            this.props.match.url,
            GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
          ) + 1,
          this.props.data.insureds.lenght,
          GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
        )
      );
      return;
    }

    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true) {
      this.setState({ cancelSignModalShow: true })
      return false;
    }
    else {
      await this.validateAndSave()
    }
  }

  async validateAndSave() {

    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true)
      this.props.removeSign()
    
    let sendData = {
      pricingData: {
        priceData: this.state.priceData,
        priceDataSelected: this.state.priceDataSelected,
        priceDataInsured: this.state.priceDataInsured,
        priceDataInsuredSelected: this.state.priceDataInsuredSelected

      }
    }
    this.props.policy.setHealthData(sendData);

    this.props.policy.setHealthData(this.state.taker);
    await this.props.saveHiring(true);

    this.props.history.push(
      GipHireMenuHelper.getStepNavigationPath(
        GipHireMenuHelper.getStepFromNavigationRoute(
          this.props.match.url,
          GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
        ) + 1,
        this.props.data.insureds.lenght,
        GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
      )
    );
  }


  render() {

    const { data, policy } = this.props;
    const { priceData, priceDataSelected, priceDataInsured, priceDataInsuredSelected, paymentTime,cancelSignModalShow } = this.state
    try {
      return (
        <GipPriceDataContainer>
          <div className="data-flow-status">
            <div className="data-flow-status-title">
              <h2>{'PRIMA'}</h2>
              <h3>{'DATOS DEL SEGURO'}</h3>

            </div>
          </div>
          <div className="result-simulation-special">
            <div className="result-simulation-special-title">
              <Title03Text>
                <p>Póliza</p>
              </Title03Text>
              <div>
                <p className="price">
                  {formatter.format(Number(priceDataSelected.TotalPrimaNeta))}
                  <span>{'/' + priceDataSelected.DesFormaPago}</span>
                </p>
              </div>
            </div>
            <div className="result-simulation-special-container">
              <div className="result-simulation-special-item">
                <HeadText>
                  <p>
                    Salud: {GIP_PRODUCTS_NAME[this.props.match.params.product]}
                  </p>
                </HeadText>
              </div>
              <div className="result-simulation-special-item">
                <div className="result-simulation-special-subitem">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>FECHA EMISIÓN</p>
                    </NoteText>
                  </div>
                  <div className="result-simulation-special-subitem-content">
                    <WebText>
                      <p>
                        {data.taker.policy.startDate}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-simulation-special-subitem">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>DELEGACIÓN</p>
                    </NoteText>
                  </div>
                  <div className="result-simulation-special-subitem-content">
                    <WebText>
                      <p>
                        {data.mediatorUser.delegation}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-simulation-special-subitem">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>MEDIADOR</p>
                    </NoteText>
                  </div>
                  <div className="result-simulation-special-subitem-content">
                    <WebText>
                      <p>
                        {data.mediatorUser.code}
                      </p>
                    </WebText>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="result-simulation-item float">
            <div className="result-simulation-special-title">
              <Title03Text>
                <p>Cliente</p>
              </Title03Text>
            </div>
            <div className="result-simulation-item-content">
            </div>

            <div className="result-simulation-special">
              <div className="result-hiring-special-title">
                <Title03Text>
                  <p>Tomador</p>
                </Title03Text>
              </div>
              <div className="result-hiring-special-container col-100">
                <div className="result-hiring-special-first-item col-30">
                  <HeadText>
                    <p>
                      {data.taker.name ?
                        `${data.taker.name} ${data.taker.lastname1} ${data.taker.lastname2}` : ''
                      }
                    </p>
                  </HeadText>
                </div>
                <div className="result-hiring-special-item col-70">

                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Tipo documento</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.docType} {data.taker.physicalPerson ? 'Persona física' : 'Persona jurídica'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Nº documento</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.docNumber}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Género</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.gender === 'H' ? 'Hombre' : 'Mujer'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Fecha de nacimiento</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {moment(data.taker.birthDate).format('DD/MM/YYYY')}
                          </p>
                        </WebText>
                      </div>
                    </div>
                  </div>

                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>DOMICILIO</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.address ? data.taker.address.address.value + ", " + data.taker.address.number : ""}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>LOCALIDAD</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.address ? data.taker.address.town.value : ''}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>PROVINCIA</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.address ? data.taker.address.province.value : ''}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>CÓDIGO POSTAL</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.address ? data.taker.address.postalCode : ''}
                          </p>
                        </WebText>
                      </div>
                    </div>
                  </div>

                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Email</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.email}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Teléfono fijo</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.landlinePhone}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Teléfono móvil</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {data.taker.mobilePhone}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="col-20"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="data-flow-status">
            <div className="data-flow-status-title">

              <h3>{'DATOS DEL RIESGO'}</h3>

            </div>
          </div>

          <div className="result-simulation-item float">
            <div className="result-simulation-special-title">
              <Title03Text>
                <p>Asegurados</p>
              </Title03Text>
            </div>
            <div className="result-simulation-item-content">
            </div>
            {
              this.props.data.insureds.map((insured: any, index: number) => (
                <div className="result-simulation-special">
                  <div className="result-hiring-special-title">
                    <Title03Text>
                      <p>Asegurado {index + 1}</p>
                    </Title03Text>
                  </div>
                  <div className="result-hiring-special-container col-100">
                    <div className="result-hiring-special-first-item col-100">
                      <div className="result-hiring-special-title2">
                        <HeadText>
                          <p>
                            {data.taker.name ?
                              `${insured.name} ${insured.lastname1} ${insured.lastname2}` : ''
                            }
                          </p>
                        </HeadText>
                      </div>

                      <div className="result-hiring-special-row">
                        <div className="result-hiring-special-subitem col-40">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Tipo documento</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.docType} {insured.physicalPerson ? 'Persona física' : 'Persona jurídica'}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Nº documento</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.docNumber}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Género</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.gender === 'H' ? 'Hombre' : 'Mujer'}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Fecha de nacimiento</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {moment(insured.birthDate).format('DD/MM/YYYY')}
                              </p>
                            </WebText>
                          </div>
                        </div>
                      </div>

                      <div className="result-hiring-special-row">
                        <div className="result-hiring-special-subitem col-40">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>DOMICILIO</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.address ? insured.address.address.value + ", " + insured.address.number : ""}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>LOCALIDAD</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.address ? insured.address.town.value : ''}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>PROVINCIA</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.address ? insured.address.province.value : ''}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>CÓDIGO POSTAL</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.address ? insured.address.postalCode : ''}
                              </p>
                            </WebText>
                          </div>
                        </div>
                      </div>

                      <div className="result-hiring-special-row">
                        <div className="result-hiring-special-subitem col-40">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Email</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.email}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Teléfono fijo</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.landlinePhone}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="result-hiring-special-subitem col-20">
                          <div className="result-simulation-special-subitem-title">
                            <NoteText>
                              <p>Teléfono móvil</p>
                            </NoteText>
                          </div>
                          <div className="result-hiring-special-subitem-content">
                            <WebText>
                              <p>
                                {insured.mobilePhone}
                              </p>
                            </WebText>
                          </div>
                        </div>
                        <div className="col-20"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )
              )}
          </div>

          <div className="data-flow-status">
            <div className="data-flow-status-title">

              <h3>{'DATOS DE PAGO'}</h3>

            </div>
          </div>
          <div className="result-simulation-item float">
            <div className="personal-data-item col-50">
              <div className="selectBox">
                <SelectBox
                  labelText=""
                  required={true}
                  errorCode={''}
                  optionsText={policy.productPayTypes}
                  optionKey={"value"}
                  optionValue={"label"}
                  defaultValue={paymentTime || DEFAULT_PAY_METHOD.code}
                  onChange={(value: string) => this.handlePriceChange(value)}
                  disabled={this.props.isReadOnly}
                />
                <label>Tipo de pago</label>
              </div>
            </div>
          </div>
          <div className="result-simulation-container">
            <div className="result-simulation-item float">
              <div className="result-simulation-special-title">
                <Title03Text>
                  <p>Desglose por forma de pago</p>
                </Title03Text>
              </div>
              <div className="result-simulation-item-content-table">
                <div className="tabs">
                  <GipPaymentResultItem
                    key={0}
                    yearlyPrice={priceDataSelected.TotalPrimaNetaAnio}
                    paymentType={priceDataSelected.DesFormaPago}
                    priceData={priceData}
                    checked={false}
                  />
                </div>
              </div>
            </div>

            <div className="result-simulation-item float">
              <div className="result-simulation-special-title">
                <Title03Text>
                  <p>Miembros de la póliza</p>
                </Title03Text>
              </div>
              <div className="result-simulation-item-content-table">
                <div className="tabs">
                  {
                    this.props.data.insureds.map((insured: any, index: number) => (
                      <GipInsuredResultItem
                        age={calcAge(getDayMonthYear(insured.birthDate).toString())}
                        birthDate={moment(insured.birthDate).format('DD/MM/YYYY')}
                        checked={false}
                        id={this.props.data.insureds.length - index}
                        index={index + 1}
                        name={insured.name}
                        key={index + 1}
                        paymentType={priceDataInsuredSelected[index].DesFormaPago}
                        totalPrice={priceDataInsuredSelected[index].TotalPrimaNeta}
                        priceData={priceDataInsured[index]}
                        onClick={() => 'this.handleRemoveInsured(index)'}

                      />))}
                </div>
              </div>
            </div>
          </div>




          <div className="colSin-100 topMargin20 bottomMargin20">
            <div className="personal-data-buttons">
              <div className="personal-data-buttons__item">
                <MainButton
                  withTab={true}
                  icon={true}
                  type={"white"}
                  iconImg={images.IconArrowLeftBlue}
                  text="Atrás"
                  onClick={() => this.props.history.push(
                    GipHireMenuHelper.getStepNavigationPath(
                      GipHireMenuHelper.getStepFromNavigationRoute(
                        this.props.match.url,
                        GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
                      ) - 1,
                      this.props.data.insureds.lenght,
                      GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
                    )
                  )}
                />
              </div>
              <div className="personal-data-buttons__item">
                <MainButton
                  withTab={true}
                  text="Siguiente"
                  onClick={() => this.savePriceData()}
                />
              </div>
            </div>
          </div>

          <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
            <div className="recover-modal">
              <div className="server-error">
                <p>{this.state.modalErrorText}</p>
              </div>
              <div className="button-error">
                <MainButton
                  text="Aceptar"
                  onClick={() => this.setState({ modalErrorShow: false, modalErrorText: '' })} />
              </div>
            </div>
          </ErrorModalContainer>
          <LeftInfoModal
          img={images.IconWarningYellow}
          className="error"
          mainTitle={"¿Seguro que desea continuar?"}
          mainText={"Se cancelará la firma previamente solicitada"}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ cancelSignModalShow: false })}
          active={cancelSignModalShow}
          onClickAccept={() => {
            this.setState({ cancelSignModalShow: false }, () => this.validateAndSave())
          }}
        />


        </GipPriceDataContainer >
      );

    } catch {

      const { history } = this.props;
      history.replace("");

      return "";
    }
  }
}

export default withCoverages(withPolicy(withGeneral(withRouter(GipPriceDataLayout))));
