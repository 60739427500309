import React, { FC } from "react";
import { StepsMenuContainer } from "./steps-menu.style";
import { images } from "../../assets";
import { deepGet } from "../../utils/utility";
import MainButton from "../main-button";
import { StepsProps } from "./steps-menu.types";

const StepsMenu: FC<StepsProps> = ({ policyData, buttonList, buttonFunction, actualStep }) => {
    const loggedMediatorString = localStorage.getItem('loggedMediator');
    const loggedMediator = loggedMediatorString ? JSON.parse(loggedMediatorString) : { mediatorCode: ''};

  return (
    <StepsMenuContainer>
      {policyData.disabled ? (
        <div className="policyDataDisabled"></div>
      ) : (
        <div className="inflow-sidebar-type">
          <img src={images.MiniWavePolicieDental} alt="" />
          <div className="inflow-sidebar-type-wrapper">
            <div className="inflow-sidebar-type-text">
              <p>PÓLIZA {policyData.policyType.toLocaleUpperCase()}</p>
            </div>
            <div className="inflow-sidebar-type-title">
              <p>{policyData.policyName.toLocaleUpperCase()}</p>
            </div>
          </div>
        </div>
      )}
      <div className="cod-mediador">
        CÓD. MEDIADOR
        <br />
        <span>{deepGet(loggedMediator, "mediatorCode", "")}</span>
      </div>
      <ul className="ulMenu">
        {buttonList.map(({ buttonText, stepNumber }, index) => {
          return (
            <li key={index}>
              <MainButton
                text={buttonText}
                type={actualStep === stepNumber ? "" : "google"}
                withTab={true}
                disabled={stepNumber > actualStep}
                onClick={() => buttonFunction(stepNumber)}
              />
            </li>
          );
        })}
      </ul>
    </StepsMenuContainer>
  );
};

export default StepsMenu;
