import React from "react";
import { BodyTextContainer } from "./body-text-styles";

interface BodyTextProps {
    children: any;
    className?: string;
    color?: string;
    toolTips?: string;
}

const BodyText = ({children = '', className='', color='', toolTips=''}:BodyTextProps) => {
        return (
        <BodyTextContainer color={color} className={className} data-tooltips={toolTips}>
            {children}
        </BodyTextContainer>
    )
} 
export default BodyText;