import React, { useEffect, useState } from "react";
import { MediatorDataContainer } from "../step-1/mediator-form.style";
import GuideLine from "../../../../../components/guide-line";
import MainButton from "../../../../../components/main-button";
import { images } from "../../../../../assets";
import { InsuredDataContainer } from "../step-2/insured-form.style";
import CardComparisonHealt from "../../../../../components/card-comparison-health/card-comparison-healt";
import ComparatorServices from "../../../../../services/ComparatorServices";
import { getComparisonCardData } from "../assets";
import LeftInfoModal from "../../../../../components/left-info-modal";
import moment from "moment";
import { Coverage, CreateComparisonData, Data, ErrorType } from "./comparator.types";
import { deepGet, downloadBlob, formatNumberFraction } from "../../../../../utils/utility";

const Comparator = ({ history, cardName }: { history: any, cardName: string}) => {
  const initialError: ErrorType = {
    img: "",
    className: "",
    mainTitle: "",
    mainText: "",
  };
  const [policies, setPolicies] = useState<any[]>([]);
  const [completePolicies, setCompletePolicies] = useState<any[]>([]);
  const [fetchError, setFetchError] = useState<ErrorType>(initialError);
  const [downloading, setDownloading] = useState(false);
  const data = localStorage.getItem('HEALTH_SIMULATION');
  const convertedData = data ? JSON.parse(data) : {};

  useEffect(() => {
    getTypes();
  }, []);

  const getTypes = async () => {
    const response = await ComparatorServices.createComparison({loader: true, setLoading: () => {}, data: tranformLocalData(convertedData), cardName});
    if (response.ok) {
      const payType = convertedData && convertedData.selectedPrice && convertedData.selectedPrice.payType ? convertedData.selectedPrice.payType : '';
      setPolicies(getComparisonCardData(response.data, payType));
      setCompletePolicies(response.data);
      return;
    }

    if (response.error) {
      if (response.statusCode === 404) {
        setFetchError({
          img: images.IconInfoBlue,
          className: "info",
          mainTitle: "Aviso",
          mainText: response.message,
        })
      } else {
        setFetchError({
          img: images.IconWarningYellow,
          className: "error",
          mainTitle: "Error",
          mainText: response.message,
        })
      }
    }
  };

  const downloadDoc = async () => {
    const data = {
      taker: convertedData.taker,
      insureds: convertedData.insuredData.map((insured: {gender: string, birthDate: string}) => ({ sex: insured.gender, birthdate: insured.birthDate })),
      payment: { payType: convertedData.selectedPrice.payType, starterDay: completePolicies[0].body.RESPUESTA.DATOS_COTIZACION.FECHA_EFECTO },
      campanyas: !!deepGet(convertedData, 'campaign.CAMPANYA'),
      discountLiteral: deepGet(completePolicies[0], 'body.discountLiteral') || '',
      comparedProducts: completePolicies.map((policy) => {
        const price = policy.body.RESPUESTA.RECIBOS.find((amount: {PERIODO_COBRO: string}) => convertedData.selectedPrice.payType === amount.PERIODO_COBRO).PRIMATOTAL;
        return {
          productType: policy.body.productType,
          productName: policy.body.productName,
          primaTotal: formatNumberFraction.format(Number(price))
        }
      })
    }

    const policyType = localStorage.getItem('internalName') || "";
    const response = await ComparatorServices.downloadComparator({loader: true, setLoading: setDownloading, data, policyType });
    if (response.ok) {
      downloadBlob(new Blob([response.data], {type: 'application/pdf'}), "comparador-presupuesto-salud.pdf");
    } else {
      setFetchError({
        img: images.IconWarningYellow,
        className: "error",
        mainTitle: "Error",
        mainText: response.message,
      });
    }
  };

  return (
    <>
      <InsuredDataContainer>
        <MediatorDataContainer>
          <div className="data-flow-status">
            <div className="data-flow-status-title">
              <h1 className="comparator-title">
                {"ESTOS PRODUCTOS SE AJUSTAN A TUS NECESIDADES"}
              </h1>
            </div>
            <div className="data-flow-status-guide">
              <GuideLine steps={[0, 0, 0, 0]} currentStep={3} />
            </div>
          </div>

          <div className="content-wrapper">
            <div className="comparator-flex">
              {policies.map((policy, index) => {
                return (
                  <CardComparisonHealt
                    key={index}
                    policy={policy}
                    history={history}
                    completePolicies={completePolicies[index]}
                    discountLiteral={deepGet(completePolicies[0], 'body.discountLiteral', '') || ''}
                  />
                );
              })}
            </div>
          </div>

          <div className="colSin-100 topMargin20">
            <div className="personal-data-buttons">
              <div className="personal-data-buttons__item">
                <MainButton
                  withTab={true}
                  icon={true}
                  type={"white"}
                  iconImg={images.IconArrowLeftBlue}
                  text="Atrás"
                  onClick={() => history.goBack()}
                />
              </div>
              <div className="personal-data-buttons__item">
                <MainButton
                  text={downloading ? "Descargando..." : "Descargar presupuesto"}
                  onClick={downloadDoc}
                  disabled={downloading}
                />
              </div>
            </div>
          </div>
        </MediatorDataContainer>
      </InsuredDataContainer>
      <LeftInfoModal
        img={fetchError.img}
        className={fetchError.className}
        mainTitle={fetchError.mainTitle}
        mainText={fetchError.mainText}
        buttonText="Cerrar"
        onClickAccept={() => setFetchError(initialError)}
        close={() => setFetchError(initialError)}
        active={fetchError.mainText}
        noSecondaryButton
      />
    </>
  );
};

export default Comparator;

export const tranformLocalData = (data: Data): CreateComparisonData => {
  return {
    insureds: data && data.insuredData 
    ? data.insuredData.map((insured: any) => {
        return {
          ...insured, 
          birthdate: moment(insured.birthDate, "DD/MM/YYYY").toISOString(), 
          sex: insured.gender, 
          profession: "", 
          coverages: insured.coverages
            .filter((coverage: Coverage) => coverage.required)
            .map((coverage: Coverage) => { 
              return {code: coverage.code, capital: 1, type: coverage.code}
            })
        }
      }) 
    : [],
    campanyas: (data && data.campaign && data.campaign.CAMPANYA !== '') ? [data.campaign] : [],
    mediator: data && data.mediatorData && data.mediatorData.code ? data.mediatorData.code : '',
    startDate: data && data.mediatorData && data.mediatorData.startDate ? moment(data.mediatorData.startDate, "DD/MM/YYYY").toISOString() : '',
  }
}
