export const NOTA_INFORMATIVA_PARENT_NODE_ID = process.env.REACT_APP_CLIENT_NODE_ID;
export const SERVICES_URL = process.env.REACT_APP_SERVICES_URL;
export const HEALTH_MAX_EFFECT_DAYS = process.env.REACT_APP_HEALTH_MAX_EFFECT_DAYS;
export const TRAVEL_MAX_EFFECT_DAYS = process.env.REACT_APP_TRAVEL_MAX_EFFECT_DAYS;
export const CLIENT_NODE_ID = process.env.REACT_APP_CLIENT_NODE_ID;
export const COMMERCIAL_NODE_ID = process.env.REACT_APP_COMMERCIAL_NODE_ID;
export const OIDC_AUTHORITY = process.env.REACT_APP_OIDC_AUTHORITY;
export const OIDC_CLIENT_ID = process.env.REACT_APP_OIDC_CLIENT_ID;
export const OIDC_CLIENT_SECRET = process.env.REACT_APP_OIDC_CLIENT_SECRET;
export const OIDC_CLIENT_ROOT = process.env.REACT_APP_OIDC_CLIENT_ROOT;
export const OIDC_CLIENT_SCOPE = process.env.REACT_APP_OIDC_CLIENT_SCOPE;
export const SAML_LOGOUT = process.env.REACT_APP_SAML_LOGOUT;
export const REACT_APP_OCASO_FORM_URL = process.env.REACT_APP_OCASO_FORM_URL; 
export const REACT_APP_LOCKED = process.env.REACT_APP_LOCKED === "true" ? true : false;
export const REACT_APP_REPORTS_YEARS = process.env.REACT_APP_REPORTS_YEARS;
export const REACT_APP_SIGN_REQUEST_TYPE = process.env.REACT_APP_SIGN_REQUEST_TYPE;
export const REACT_APP_SIGN_PROVIDER = process.env.REACT_APP_SIGN_PROVIDER;
export const REACT_APP_SIGN_SUBJECT = process.env.REACT_APP_SIGN_SUBJECT;
