import GeneralServices from "./GeneralServices";

class RolPortalServices {

  getInfo = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `roles-portal/${args.mediatorCode} `
      });
      return response.data;
    } catch (err) {
      return [];
    }
  };


}

export default new RolPortalServices();
