import React, { Component } from "react";
import { formatter } from "../../utils/utility";
import { HealthPriceContainer } from "./health-price-style";

interface HealthPriceProps {
    paymentTime: string;
    price: string;
    discount?: string;
    defaultChecked: boolean;
    onClick: any;
    disabled?: boolean;
}

interface HealthPriceState {
}

class HealthPriceItem extends Component<HealthPriceProps, HealthPriceState> {

    render() {
        const { paymentTime, price, discount, defaultChecked, onClick, disabled } = this.props;
        return (
            <HealthPriceContainer>
                <div className="colSin-25 rightPadding10" onClick={() => onClick(paymentTime, price, discount)}>
                    <div className={`radioBoxButton ${disabled ? "disabled" : ""}`}>
                        <label>
                            <input type="radio" name="tipoPago" checked={defaultChecked} />
                            <span>{paymentTime}</span>
                            <span>{formatter.format(Number(price))}</span>
                        </label>
                    </div>
                </div>
            </HealthPriceContainer>
        )

    }
}

export default HealthPriceItem;
