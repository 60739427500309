import GeneralServices from "./GeneralServices";

class ContactServices {
  goToOldPortal = async () => {
    try {
      const res = await GeneralServices.get({
        loader: false,
        setLoading: () => { },
        endpoint: "auth/preportal"
      });

      return res.data;
    }
    catch (err) {
      throw err;
    }
  }
  contact = async (args: any) => {    
    try {
      const res = await GeneralServices.postFormData({
        loader: true,
        setLoading: args.setLoading,
        data: args.data,
        endpoint: "contact"
      });

      return res.data;
    }
    catch (err) {
      throw err;
    }
  }

}

export default new ContactServices();