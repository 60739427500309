// React
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
// Assets
import { images } from "../../assets";
// Components
import MainButton from "../main-button";
import InsuredCard from "../insured-card/insured-card";
import { HealthHireMenuContainer } from "./health-hire-menu-style";
import { withPolicy } from "../../context/mixin/with-policy";
import { deepGet } from "../../utils/utility";
import LeftInfoModal from "../left-info-modal";

export enum Steps {
    DatosTomador = 1,
    DatosAsegurados = 2,
    Garantias = 3,
    DocumentacionRequerida = 4,
    ProteccionDatos = 5,
    Prima = 6,
    Cuestionarios = 7,
    MetodosPago = 8,
    DetallePrevioContratacion = 9
}

interface HealthHireMenuProps extends RouteComponentProps<any> {
    // Required
    initialCurrentStep: Steps,
    initialInsureds: any[], // => { name?: string, lastname1?: string, lastname2?: string, [x: string]: any }
    codMediador: string,
    selectedMediator?: string,
    insuredSaved?: boolean;
    // Optional
    disableSubButtons?: boolean,
    currentNumInsured?: number,
    isSignedDocument?: boolean;
    isHired?: boolean;
    disableSignButton?: boolean;
    physicalSignature?: boolean;
    // Functions
    previousOnNavigation?: () => boolean,
    insuredOnAdd?: (insuredSaved: any) => Promise<void>,
    insuredOnRemove?: (index: number) => void,
    insuredOnEdit?: (index: number) => void,
    insuredOnSave?: () => void,
    questionnaireOnEdit?: (index: number) => void,
    questionnaireOnSave?: () => void,
    resumeOnHiring?: () => void,
    downloadPrecontractualDocument?: () => void,
    sendMailPrecontractualDocument?: (showModal?: boolean) => void,
    signPrecontractualDocument?: () => void,
    policy: any,
    payHiring?: () => void,
    setLoading?: () => void,
}

interface HealthHireMenuState {
    currentStep: Steps,
    insureds: any[], // => { name?: string, lastname1?: string, lastname2?: string, [x: string]: any }
    showInsuredsOnDatosAsegurados: boolean,
    showInsuredsOnCuestionarios: boolean,
    mediador: string,
    modal: {
        img: string,
        className: string,
        mainTitle: string,
        mainText: string,
    }
}

export class HealthHireMenuHelper {
    static getStepFromNavigationRoute(path: string): Steps {
        if (path.indexOf("/contratacion/nueva/salud/tomador") !== -1) return Steps.DatosTomador;
        else if (path.indexOf("/contratacion/nueva/salud/asegurados") !== -1) return Steps.DatosAsegurados;
        else if (path.indexOf("/contratacion/nueva/salud/garantias") !== -1) return Steps.Garantias;
        else if (path.indexOf("/contratacion/nueva/salud/documentacion-requerida") !== -1) return Steps.DocumentacionRequerida;
        else if (path.indexOf("/contratacion/nueva/salud/proteccion-datos") !== -1) return Steps.ProteccionDatos;
        else if (path.indexOf("/contratacion/nueva/salud/precio") !== -1) return Steps.Prima;
        else if (path.indexOf("/contratacion/nueva/salud/cuestionarios-salud") !== -1) return Steps.Cuestionarios;
        else if (path.indexOf("/contratacion/nueva/salud/medios-pago") !== -1) return Steps.MetodosPago;
        else if (path.indexOf("/contratacion/nueva/salud/resultado") !== -1) return Steps.DetallePrevioContratacion;
        else return Steps.DatosTomador;
    }

    static getStepNavigationPath(step: Steps, insuredsLength: number): string {
        let currentInsured = insuredsLength - 1;
        currentInsured = currentInsured < 0 ? 0 : currentInsured;
        switch (step) {
            case Steps.DatosTomador: return "/contratacion/nueva/salud/tomador";
            case Steps.DatosAsegurados: return insuredsLength === 0 ?
                '/contratacion/nueva/salud/asegurados/' :
                `/contratacion/nueva/salud/asegurados/${currentInsured}`;
            case Steps.Garantias: return "/contratacion/nueva/salud/garantias";
            case Steps.DocumentacionRequerida: return "/contratacion/nueva/salud/documentacion-requerida";
            case Steps.ProteccionDatos: return "/contratacion/nueva/salud/proteccion-datos";
            case Steps.Prima: return "/contratacion/nueva/salud/precio";
            case Steps.Cuestionarios: return "/contratacion/nueva/salud/cuestionarios-salud/0";
            case Steps.MetodosPago: return "/contratacion/nueva/salud/medios-pago";
            case Steps.DetallePrevioContratacion: return "/contratacion/nueva/salud/resultado";
            default: return "";
        }
    }

    static getPreviousStep(currentStep: Steps): Steps {
        try {
            return currentStep - 1;
        } catch {
            return Steps.DatosTomador;
        }
    }

    static getPreviousStepNavigationPath(currentStep: Steps): string {
        return this.getStepNavigationPath(this.getPreviousStep(currentStep), 1);
    }

    static getNextStep(currentStep: Steps): Steps {
        try {
            return currentStep + 1;
        } catch {
            return Steps.DetallePrevioContratacion;
        }
    }

    static getNextStepNavigationPath(currentStep: Steps): string {
        return this.getStepNavigationPath(this.getNextStep(currentStep), 1);
    }
}

class HealthHireMenuLayout extends React.Component<HealthHireMenuProps, HealthHireMenuState> {
    constructor(props: HealthHireMenuProps) {
        super(props);

        this.state = {
            currentStep: props.initialCurrentStep,
            insureds: props.initialInsureds,
            showInsuredsOnDatosAsegurados: props.initialCurrentStep === Steps.DatosAsegurados,
            showInsuredsOnCuestionarios: props.initialCurrentStep === Steps.Cuestionarios,
            mediador: '',
            modal: { img: "", className: "", mainTitle: "", mainText: "" },
        };
    }

    componentDidMount() {
        this.props.history.listen((location, action) => {
            var step = HealthHireMenuHelper.getStepFromNavigationRoute(location.pathname);
            this.setState({
                currentStep: step,
                showInsuredsOnDatosAsegurados: step === Steps.DatosAsegurados,
                showInsuredsOnCuestionarios: step === Steps.Cuestionarios,
            });
        });
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.initialInsureds !== this.props.initialInsureds) {
            this.setState({ insureds: nextProps.initialInsureds });
        }

    }

    getButtonCssName = (step: Steps): string => step === this.state.currentStep ? "" : "google"

    isMenuItemDisabled = (step: Steps): boolean => step > this.state.currentStep;

    isMenuItemSelected = (step: Steps): boolean => step === this.state.currentStep;

    handleNavigation(step: Steps): void {
        if (step === this.state.currentStep)
            return;

        let doNavigation = Steps.DatosAsegurados || Steps.Cuestionarios || !this.props.previousOnNavigation || this.props.previousOnNavigation()
        if (doNavigation) {

            switch (step) {
                case Steps.DatosAsegurados:
                    this.setState({ showInsuredsOnDatosAsegurados: true, showInsuredsOnCuestionarios: false })
                    break;
                case Steps.Cuestionarios:
                    this.setState({ showInsuredsOnDatosAsegurados: false, showInsuredsOnCuestionarios: true })
                    break;
                default:
                    this.props.history.push(HealthHireMenuHelper.getStepNavigationPath(step, this.state.insureds.length));
                    break;
            }

            if (step !== Steps.DatosAsegurados && step !== Steps.Cuestionarios)
                this.setState({ showInsuredsOnDatosAsegurados: false, showInsuredsOnCuestionarios: false })
        }
    }

    tryDownloadPrecontractualDocument(): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.downloadPrecontractualDocument)
                this.props.downloadPrecontractualDocument();
        }
    }

    tyrSendMailPrecontractualDocument() {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.sendMailPrecontractualDocument)
                this.props.sendMailPrecontractualDocument();
        }
    }

    trySignPrecontractualDocument() {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.signPrecontractualDocument)
                this.props.signPrecontractualDocument();
        }
    }
    tryResumeOnHiring(): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.resumeOnHiring)
                this.props.resumeOnHiring();
        }
    }

    tryQuestionnaireOnSave(): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.questionnaireOnSave)
                this.props.questionnaireOnSave();
        }
    }

    async tryInsuredOnAdd(): Promise<void> {
        const { policy } = this.props;
        const { insureds } = this.state;
        const maxInsureds = deepGet(policy, "product.productFields.step2.maxInsureds", 10) || 10;
        if (insureds.length >= maxInsureds) {
            this.setState({ modal: {
                img: images.IconInfoBlue,
                className: "info",
                mainTitle: "Aviso",
                mainText: "Número máximo de asegurados alcanzado",
            }});
            return;
        }
        
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.insuredOnAdd)
                await this.props.insuredOnAdd(this.props.insuredSaved);
        }
    }

    tryInsuredOnSave(): void {
        if (this.props.insuredOnSave)
            this.props.insuredOnSave()
    }

    isSubMenuItemSelected = (step: Steps, numInsured: number): boolean => step === this.state.currentStep && numInsured === (this.props.currentNumInsured ? this.props.currentNumInsured : 0);

    tryRemoveInsured(event: any, step: Steps, numInsured: number): void {
        event.stopPropagation();
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (step === Steps.DatosAsegurados && this.props.insuredOnRemove)
                this.props.insuredOnRemove(numInsured)
        }
    }

    tryGoToDetail(step: Steps, numInsured: number): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (step === Steps.DatosAsegurados && this.props.insuredOnEdit)
                this.props.insuredOnEdit(numInsured)
            else if (step === Steps.Cuestionarios && this.props.questionnaireOnEdit)
                this.props.questionnaireOnEdit(numInsured)
        }
    }

    renderInsuredCards(renderForStep: Steps, showRemoveIco: boolean = true, divClickGoToDetail: boolean = false): JSX.Element[] {
        let elements: JSX.Element[] = [];
        if (this.state.insureds) {
            for (let i = 0; i < this.state.insureds.length; i++) {
                elements.push(
                    <li key={`asegurado-${i}`}>
                        <InsuredCard
                            selected={this.isSubMenuItemSelected(renderForStep, i)}
                            insuredMember={this.state.insureds[i]}
                            removeInsured={!this.props.disableSubButtons && showRemoveIco ? (event: any) => this.tryRemoveInsured(event, renderForStep, i) : undefined}
                            goToDetail={() => this.tryGoToDetail(renderForStep, i)}
                            textSubtitle={"Asegurado"}
                            divClickGoToDetail={divClickGoToDetail}
                        />
                    </li>
                );
            }
        }
        return elements;
    }

    getMainButtonMenu(title: string, step: Steps): JSX.Element {
        return <MainButton
            text={title}
            type={this.getButtonCssName(step)}
            withTab={true}
            disabled={this.isMenuItemDisabled(step)}
            onClick={() => this.handleNavigation(step)}
        />;
    }

    getMainButtonSubMenu(title: string, step: Steps, onClick: Function, type: string | undefined = "full-light", image?: string | undefined, disabled: boolean | undefined = undefined): JSX.Element {
        return <MainButton
            text={title}
            icon={image !== undefined}
            iconImg={image}
            type={type}
            withTab={true}
            disabled={disabled !== undefined ? disabled : this.isMenuItemDisabled(step)}
            onClick={() => onClick()}
        />
    }

    cleanModal = () => {
        this.setState({ modal: { img: "", className: "", mainTitle: "", mainText: ""}});
    }
    
    render() {
        const {policy} = this.props
        const productName = policy.product.products_name
        const { modal } = this.state;
        const payType = deepGet(policy, "data.price.price.payType") || "";

        const productCardName = policy.product.products_cardName
        return (
            <HealthHireMenuContainer>
                <div className="inflow-sidebar-type">
                    <img src={images.MiniWavePolicieSalud} alt="" />
                    <div className="inflow-sidebar-type-wrapper">
                        <div className="inflow-sidebar-type-text">
                            <p>PÓLIZA {productCardName}</p>
                        </div>
                        <div className="inflow-sidebar-type-title">
                            <p>{productName}</p>
                        </div>
                    </div>
                </div>
                <div className="cod-mediador">CÓD. MEDIADOR<br /><span>{this.state.mediador ? this.state.mediador : this.props.selectedMediator ? this.props.selectedMediator : this.props.codMediador}</span></div>
                <ul className="ulMenu">
                    <li>{this.getMainButtonMenu("DATOS DEL TOMADOR", Steps.DatosTomador)}</li>
                    <li>
                        {this.getMainButtonMenu("DATOS DE LOS ASEGURADOS", Steps.DatosAsegurados)}
                        <ul className="ulSubmenu" style={{ "display": (this.state.showInsuredsOnDatosAsegurados ? "block" : "none") }}>
                            {
                                !this.props.disableSubButtons && this.state.currentStep === Steps.DatosAsegurados &&
                                <li>{this.getMainButtonSubMenu("GUARDAR ASEGURADO", Steps.DatosAsegurados, () => this.tryInsuredOnSave(), "full-light", images.IconSaveWhite)}</li>
                            }
                            {this.renderInsuredCards(Steps.DatosAsegurados, this.state.currentStep === Steps.DatosAsegurados, true)}
                            {
                                !this.props.disableSubButtons &&
                                <li>{this.getMainButtonSubMenu("NUEVO ASEGURADO", Steps.DatosAsegurados, () => this.tryInsuredOnAdd(), "full-light", images.PlusWhite, this.isMenuItemDisabled(Steps.DatosAsegurados) || this.state.insureds.length === 10)}</li>
                            }
                        </ul>
                    </li>
                    <li>{this.getMainButtonMenu("GARANTÍAS", Steps.Garantias)}</li>
                    <li>{this.getMainButtonMenu("DOCUMENTACIÓN REQUERIDA", Steps.DocumentacionRequerida)}</li>
                    <li>{this.getMainButtonMenu("PROTECCIÓN DE DATOS", Steps.ProteccionDatos)}</li>
                    <li>{this.getMainButtonMenu("PRIMA", Steps.Prima)}</li>
                    <li>
                        {this.getMainButtonMenu("CUESTIONARIOS", Steps.Cuestionarios)}
                        <ul className="ulSubmenu" style={{ "display": (this.state.showInsuredsOnCuestionarios ? "block" : "none") }}>
                            <li>
                                {
                                    !this.props.disableSubButtons && this.state.currentStep === Steps.Cuestionarios &&
                                    this.getMainButtonSubMenu("GUARDAR CUESTIONARIO", Steps.Cuestionarios, () => this.tryQuestionnaireOnSave(), "full-light", images.IconUploadWhite)
                                }
                            </li>
                            {this.renderInsuredCards(Steps.Cuestionarios, false)}
                        </ul>
                    </li>
                    <li>{this.getMainButtonMenu("MÉTODOS DE PAGO", Steps.MetodosPago)}</li>
                    <li>
                        {this.getMainButtonMenu("DETALLE PREV. CONTRATACIÓN", Steps.DetallePrevioContratacion)}
                        <ul className="ulSubmenu" style={{ "display": (true ? "block" : "none") }}>
                            <li>
                                {
                                    this.state.currentStep === Steps.DetallePrevioContratacion &&
                                    this.getMainButtonSubMenu("DESCARGAR", Steps.DetallePrevioContratacion, () => this.tryDownloadPrecontractualDocument(), "full-light")
                                }
                                {
                                    this.state.currentStep === Steps.DetallePrevioContratacion &&
                                    this.getMainButtonSubMenu("ENVIAR POR EMAIL", Steps.DetallePrevioContratacion, () => this.tyrSendMailPrecontractualDocument(), "full-light")
                                }
                                {
                                    this.state.currentStep === Steps.DetallePrevioContratacion &&
                                    this.getMainButtonSubMenu(
                                        "FIRMA PRECONTRACTUAL",
                                        Steps.DetallePrevioContratacion,
                                        () => this.trySignPrecontractualDocument(),
                                        "full-light",
                                        undefined,
                                        this.props.physicalSignature || this.props.disableSignButton)
                                }
                                {
                                    this.state.currentStep === Steps.DetallePrevioContratacion &&
                                    this.props.physicalSignature &&
                                    this.getMainButtonSubMenu(
                                        "CONTRATAR",
                                        Steps.DetallePrevioContratacion,
                                        () => this.tryResumeOnHiring(),
                                        "full-light",
                                        undefined,
                                        !this.props.isSignedDocument || this.props.isHired)
                                }
                            </li>
                        </ul>
                    </li>
                </ul>
                <LeftInfoModal
                    img={modal.img}
                    className={modal.className}
                    mainTitle={modal.mainTitle}
                    mainText={modal.mainText}
                    buttonText="Cerrar"
                    onClickAccept={this.cleanModal}
                    close={this.cleanModal}
                    active={modal.mainText}
                    noSecondaryButton
                />
            </HealthHireMenuContainer>
        );
    }
}

export const HealthHireMenu = withPolicy(withRouter(HealthHireMenuLayout))
