import { formatter } from '../../../../utils/utility';

type Coverage = {
  capital: number,
  code: string,
  type: string,
}

type Coverages = {
  coverages: Coverage[]
}

type Taker = {
  name: string,
  lastname1: string,
  lastname2?: string,
}

type InsuredData = {
  address: any,
  name: string,
  birthdate: string,
  type: string,
  breedLabel: string,
  breed: string,
  owner: string,
  numChip: string,
  coverages: any,
}


export function getPetInsuredsData(insureds: Coverages[], taker: Taker, coveragesRaw: any): InsuredData[] {
  const owner = `${taker.name} ${taker.lastname1} ${taker.lastname2}`;

  const insuredData = insureds.map((insured) => {

    const formatedCoverages = insured.coverages.map((coverage) => {
      const { capital, code } = coverage;
      const coverageRaw = coveragesRaw.filter((cov: any) => cov.code === code);
 
      return {
        type: code,
        code,
        capital,
        complementary: false,
        optional: !coverageRaw.required,
        notShow: false,
        name: coverageRaw.name,
        otherInsurance: null,
      }
    });

    const newInsured: InsuredData = {
      address: {},
      name: "",
      birthdate: "",
      type: "",
      breedLabel: "",
      breed: "",
      owner,
      numChip: "",
      coverages: formatedCoverages,
    };

    return newInsured;
  });

  return insuredData;
}

function formatCapitales(cap: any) {
  let options: Array<{
    [key: string]: string;
  }> = []
  let s = cap.map((v: any) => {
    options.push({ "label": `${formatter.format(Number(v.importe))}`, "value": v.importe })
  })
  return options;
}

function getCoverages(coverages: any, coveragesRaw: any) {
  const formatedCoverage: any = {};
  
  coveragesRaw.forEach((coverageRaw: any) => {
    const coverage = coverages.find((cov: any) => cov.code === coverageRaw.code);

    let capital = "";

    if (coverage !== undefined) {
      capital = `${coverage.capital}`;
    }

    const required = coverageRaw.required;
    const name = coverageRaw.name;


    formatedCoverage[coverageRaw.code] = {
        value: coverage !== undefined ? true : false,
        error: false,
        errorCode: "",
        required: required,
        name,
        complementary: !required,
        optional: false,
        notShow: false,
        otherInsurance: null,
      }

    if (!required) {
      const newCapital = {
        "value": capital,
        "error": false,
        "errorCode": "",
        "required": false
      }
    
      formatedCoverage[coverageRaw.code].capital = newCapital;
      formatedCoverage[coverageRaw.code].options = formatCapitales(coverageRaw.capitales)
    }
  });

  return formatedCoverage;
}

export function getPetInsuredDataForm(insureds: Coverages[], taker: Taker, coveragesRaw: any) {
  const owner = `${taker.name} ${taker.lastname1} ${taker.lastname2}`;
  
  const insuredsDataForm = insureds.map((insured) => {
    const coverages = getCoverages(insured.coverages, coveragesRaw);
    
    return {
      contact: {
        name: {
          value: "",
          error: false,
          errorCode: "",
          required: true,
        },
        birthdate: {
          value: "",
          error: false,
          errorCode: "",
          required: true,
        },
        type: {
          value: "",
          error: false,
          errorCode: "",
          required: true,
        },
        breed: {
          value: {
            value: "",
            label: ""
          },
          error: false,
          errorCode: "",
          required: true,
        },
        owner: {
          value: owner,
          error: false,
          errorCode: "",
          required: true,
        },
        numChip: {
          value: "",
          error: false,
          errorCode: "",
          required: true,
        },
      },
      coverages,
      isReadOnly: false,
    }
  });
  return insuredsDataForm;
}
