
export interface Address {
  way_type: string;
  street: string;
  postal_code: string;
  way_number: string;
  floor: string;
  stair: string;
  door: string;
  province: string;
  city: string;
  city_name: string;
  [key: string]: string;
}

export interface SignDataDto {
  signId: string,
  signRequestDate: Date,
  signStatus: string
}

export const SIGNIG_STATUS = {
  SOLICITADA: 'SOLICITADA',
  FIRMADA: 'FIRMADA',
  RECHAZADA: 'RECHAZADA',
  EXPIRADA: 'EXPIRADA',
  ERROR: 'ERROR'
}

export const RESPONSE_SIGNIG_STATUS = {
  CANCELLED: 'Cancelled',
  COMPLETED: 'Competed',
  EXPIRED: 'Expired',
  FAILED: 'Failed',
  NEW: 'New',
  REJECTED: 'Rejected',
  REQUESTED: 'Requested',
  SIGNED: 'Signed',
}

export const CONST_HIRING_STATUS = {
  PENDIENTE: 'PENDIENTE',
  PENDIENTE_SOL_FIRMADA: 'PENDIENTE SOLICITADA FIRMA',
  PENDIENTE_RECHAZADA: 'PENDIENTE RECHAZADA',
  PENDIENTE_ERROR: 'PENDIENTE - ERROR',
  EN_REVISION: 'EN REVISION',
  SELERIES: 'SELERIES',
  PDTEAUTO: 'PDTEAUTO',
  RESERIES: 'RESERIES', 
  EXPIRADA: 'EXPIRADA',
  finished: 'finished',
  EMITIDA: 'EMITIDA',
  RECHAZADA: 'RECHAZADA',
  ANULADA_MEDIADORES: 'ANULADA MEDIADORES',
  CANCELADA: 'CANCELADA'
}

// Agrupación de los antiguos estados EN_REVISION
// para mantener el funcionamiento
export const HIRING_STATUS_GROUP_REVISION = [
  CONST_HIRING_STATUS.EN_REVISION,
  CONST_HIRING_STATUS.SELERIES,
  CONST_HIRING_STATUS.PDTEAUTO,
  CONST_HIRING_STATUS.RESERIES,
];

export const HIRING_STATUS = [
  { label: 'Todas', value: '0', search: 'all', type: 'all' },

  { label: 'Revisión solicitante', value: '2', search: CONST_HIRING_STATUS.EN_REVISION, type: 'policy' },
  { label: 'Selección de riesgos', value: '11', search: CONST_HIRING_STATUS.SELERIES, type: 'policy' },
  { label: 'Pendiente de autorizar', value: '12', search: CONST_HIRING_STATUS.PDTEAUTO, type: 'policy' },
  { label: 'Revisión Selección de riesgos', value: '13', search: CONST_HIRING_STATUS.RESERIES, type: 'policy' },
  { label: 'Pendiente', value: '1', search: CONST_HIRING_STATUS.PENDIENTE, type: 'policy' },
  { label: 'Pendiente - Error', value: '3', search: CONST_HIRING_STATUS.PENDIENTE_ERROR, type: 'policy' },
  { label: 'Expirada', value: '4', search: CONST_HIRING_STATUS.EXPIRADA, type: 'policy' },
  { label: 'Rechazada', value: '5', search: CONST_HIRING_STATUS.RECHAZADA, type: 'policy' },

  { label: 'Pendiente - Firma Solicitada', value: '6', search: SIGNIG_STATUS.SOLICITADA, type: 'sign' },
  { label: 'Pendiente - Firma con Error', value: '7', search: SIGNIG_STATUS.ERROR, type: 'sign' },
  { label: 'Pendiente - Firma Expirada', value: '8', search: SIGNIG_STATUS.EXPIRADA, type: 'sign' },
  { label: 'Pendiente - Firma Rechazada', value: '9', search: SIGNIG_STATUS.RECHAZADA, type: 'sign' },
  { label: 'Pendiente - Firmada', value: '14', search: SIGNIG_STATUS.FIRMADA, type: 'sign' },

  { label: 'Anulada mediadores', value: '15', search: CONST_HIRING_STATUS.ANULADA_MEDIADORES, type: 'policy' },
  { label: 'Pendiente - Firma Cancelada', value: '16', search: CONST_HIRING_STATUS.CANCELADA, type: 'sign' }
]

export const SIGNATURE_STATUS_MODIFY_POLICY = [
  SIGNIG_STATUS.SOLICITADA,
  SIGNIG_STATUS.RECHAZADA,
  SIGNIG_STATUS.EXPIRADA,
  SIGNIG_STATUS.ERROR,
  SIGNIG_STATUS.FIRMADA,
]

export const isValidStatusToEnableButton = (signStatus: string) => (!signStatus || signStatus === '') || SIGNATURE_STATUS_MODIFY_POLICY.indexOf(signStatus) !== -1

export const isValidStatusToCancelSignRequest = (signStatus: string) => (!signStatus || signStatus === '') || SIGNATURE_STATUS_CANCEL_REQUEST.indexOf(signStatus) !== -1

export const SIGNATURE_STATUS_CANCEL_REQUEST = [
  RESPONSE_SIGNIG_STATUS.REQUESTED,
]
