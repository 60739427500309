import styled from "styled-components";
import { MainButtonContainer } from "../../components/main-button/main-button-style";

export const ContactContainer = styled.div`
  font-family: "museo sans";
  .inputArea, inputBox {
    width: 100%;
    margin-bottom:90px;

    textarea {
      width:100%;
      height:110px;
    }
  }
  .go-back-head  {
    margin-bottom: 96px;
  }
  .contact-content {
    display: flex;
  }
  .contact-left  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 300px;
    width: 100%;
    max-width: 400px;
    margin-right: 20px;
    color: #004f8b;

    .contact-left-top {
      &__title  {
        font-size: 53px;
        line-height: 62px;
        color: #004f8b;
        margin-bottom: 20px;
        font-weight: 700;
      }
      &__subtitle {
        font-size: 18px;
        line-height: 30px;
        color: #004f8b;
        max-width: 300px;
      }
    }

    .contact-info  {
      margin-bottom: 20px;
    }

    .contact-info-item {
      display: flex;
      margin-bottom: 12px;
      &:last-of-type {
        margin-bottom: 0px;
      }
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        font-size: 12px;
        line-height: 14px;
        color: #004f8b;
        opacity: 0.99;
      }
      &__link {
        width: 100%;
      }
    }
  }

  .contact-right {
    background: #b2cadc14;
    border-radius: 3px;
    width: 100%;
    padding: 40px;
    max-width: 700px;

    .contact-right-form {
      margin-bottom: 60px;
    }
    .contact-right-input {
      margin-bottom: 28px;
    }
    &__button {
      width: 142px;
      ${MainButtonContainer} {
        height: 40px;
      }
    }
  }
`;
