import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IAuthService } from '../../auth/AuthService';
import { withGeneral } from '../../context/mixin/with-general';
import MediatorServices from '../../services/MediatorServices';

interface SigninCallbackProps extends RouteComponentProps {
  authService: IAuthService;
}
class SigninCallback extends React.Component<SigninCallbackProps, {}> {

  async componentDidMount() {
    try {
      const { authService } = this.props;
      const signinCallbackResponse = await authService.signinCallback();
      const res = await MediatorServices.login({
        loader: true,
        setLoading: () => {},
        data: { token: signinCallbackResponse.id_token },
      });
      localStorage.setItem('accessToken', res.token);
      localStorage.setItem('userName', res.user);
      localStorage.setItem('isLogin', 'true');
    } catch (error) {
      console.log("Error SigninCallback", error)
    } finally {
      window.location.href = "/"
    }
  }

  render() {
    return (<h1>Iniciando sesión...</h1>);
  }
}

export default withGeneral(withRouter(SigninCallback));
