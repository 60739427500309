import moment from "moment";

export const downloadFile = (
  file: any,
  filename: string,
  type: string,
  download?: boolean
) => {
  const url = window.URL.createObjectURL(
    new Blob([file], {
      type,
    })
  );
  const link: HTMLAnchorElement | string = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  // if (download) {
  //   link.setAttribute("download", filename);
  // } else {
  //   link.setAttribute("target", "_blank");
  // }
  link.click();
};

export const downloadBlob = ( blob: Blob, filename: string ) => {
  const url = window.URL.createObjectURL(blob);
  const link: HTMLAnchorElement | string = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.click();
};



export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => typeof reader.result === "string" ? resolve(reader.result) : resolve("");
    reader.onerror = error => reject(error);
  });
}

export const convertBase64ToFile = (file: any, type: string) => {
  const byteString = atob(file);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], {
    type,
  });
  return newBlob;
};

export const readFileAsText = (
  file: File
): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (err) => reject(err);
    reader.readAsText(file);
  });


export const onKeyDown = (
  event: KeyboardEvent,
  key: string,
  onKeyPressed: Function,
) => {
  const keyCode = event.key;
  if (keyCode === key) {
    onKeyPressed();
  }
};

export const formatter = new Intl.NumberFormat(
  'de-DE', {
  style: 'currency',
  currency: 'EUR'
});

export const formatterRoundNumbers = new Intl.NumberFormat(
  'de-DE', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 0
});

export const formatNumberFraction = new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const getFileExtension = (fileName: string): string => {
  var expression = /(?:\.([^.]+))?$/; // https://regexr.com/663sa
  var result = expression.exec(fileName);
  return result && result.length > 1 && result[1] ? result[1] : ""
}

export const calcAge = (dateString: string): number => {
  let dateMoment = moment(dateString, "DD/MM/YYYY", true)
  if (!dateMoment.isValid()) {
    dateMoment = moment(moment(dateString, 'YYYY-MM-DD').format('DD/MM/YYYY'), "DD/MM/YYYY", true)
  }

  const age = moment().diff(dateMoment, "years", true);
  return Math.floor(age);
}

export const convertDateToString = (date: Date): string => {
  let month = String(date.getMonth() + 1);
  let day = String(date.getDate());
  const year = String(date.getFullYear());
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return `${day}/${month}/${year}`;
}
export const changeFileName = (file: File, newFilename: string): File => new File([file], newFilename, { type: file.type });

export const getFileSize = (size: number) => {
  if (size < 1024) {
    return `${size} bytes`;
  } else if (size >= 1024 && size < 1048576) {
    return `${(size / 1024).toFixed(1)} KB`;
  } else if (size >= 1048576) {
    return `${(size / 1048576).toFixed(1)} MB`;
  }
}

type GetReturnType<T> = T | undefined;
type ValueType = Record<string | number, unknown>;
 
export function deepGet<T>(
  value: unknown,
  query: string | Array<string | number>,
  defaultVal: GetReturnType<T> = undefined
): GetReturnType<T> {
  const splitQuery = Array.isArray(query)
    ? query
    : query
        .replace(/(\[(\d)\])/g, ".$2")
        .replace(/^\./, "")
        .split(".");
 
  if (!splitQuery.length || splitQuery[0] === undefined) return value as T;
 
  const key = splitQuery[0];
 
  if (typeof value !== "object" || value === null || !(key in value) || (value as ValueType)[key] === undefined) {
    return defaultVal;
  }
 
  return deepGet((value as ValueType)[key], splitQuery.slice(1), defaultVal);
}
