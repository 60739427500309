import React from 'react';
import { Link } from 'react-router-dom';
import { LegalPolicyContainer } from '../legal-policy/legal-policy-style';

interface DataConservationProps {

}

export default class DataConservationLayout extends React.Component<DataConservationProps, {}> {
    render() {
        return (
            <LegalPolicyContainer>
                <div className="legal-title-selection">
                    <p>Política de <br /> conservación de datos</p>
                </div>

                <div className="legal-subtitle">
                    <p>01 Objetivo del procedimiento</p>
                </div>

                <div className="legal-text">
                    <p>
                        El Reglamento 2016/679, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE    (en adelante, el “RGPD”) pretende garantizar y proteger todo lo concerniente al tratamiento de los datos personales, es decir, el uso de cualquier información de personas físicas.
                </p>
                    <p>
                        Mediante la presente Política de conservación de datos personales, se pretende establecer unas directrices de actuación relativas a determinar cuándo debe procederse a la conservación o a la destrucción de los datos, a los efectos de dar cumplimiento a las exigencias derivadas del deber de calidad.
                </p>

                </div>

                <div className="legal-subtitle">
                    <p>02 Ámbito</p>
                </div>

                <div className="legal-text">
                    <p>La presente Política deberá ser observada por ASISA SANITARIA INTERPROVINCIAL S.A.U., Compañía de Seguros y Reaseguros, (en adelante, ASISA o la Entidad), así como por las empresas que traten datos de carácter personal en calidad de Encargados del Tratamiento, siendo de aplicación respecto de datos que sean objeto tanto de tratamiento automatizado como de tratamiento no automatizado (soporte papel).</p>
                    <p>La presente Política deberá ser observada por todo el personal que maneje datos de carácter personal en el desarrollo de su actividad diaria.</p>
                </div>

                <div className="legal-subtitle">
                    <p>03 Obligaciones de supresión de datos personales</p>
                </div>

                <div className="legal-subtitle-section">
                    <p>3.1 CONSIDERACIONES PREVIAS</p>
                </div>
                <div className="legal-text">
                    <p>Los datos personales deben ser adecuados, pertinentes y limitados a lo necesario para los fines para los que sean tratados. Ello requiere, en particular, garantizar que se limite a un mínimo estricto su plazo de conservación. Los datos personales sólo deben tratarse si la finalidad del tratamiento no pudiera lograrse razonablemente por otros medios. Para garantizar que los datos personales no se conservan más tiempo del necesario, el Responsable del tratamiento ha de establecer plazos para su supresión o revisión periódica. A tal efecto, la presente Política debe guiarse por Principio de limitación del plazo de conservación del apartado e) del artículo 5.1 del RGPD, en virtud del cual los datos personales deben ser “mantenidos de forma que se permita la identificación de los interesados durante no más tiempo del necesario para los fines del tratamiento de los datos personales”.</p>
                    <p>Esta obligación resulta de aplicación para cuando ASISA actúe como responsable del tratamiento, pero a su vez, cuando actúe como encargado del tratamiento.
                        En particular, cuando la entidad tenga la consideración de Encargado del Tratamiento, de conformidad con el apartado g) del art. 28.3 del RGPD, una vez cumplida la prestación contractual que dio lugar al encargo del tratamiento, los datos personales deberán, a elección del responsable, ser suprimidos o devueltos al Responsable del tratamiento, debiendo suprimir asimismo las copias existentes a menos que se requiera la conservación de los datos personales en virtud del Derecho de la Unión o de los Estados miembros.</p>
                </div>
                <div className="legal-subtitle-section">
                    <p>3.2 CAUSAS HABILITANTES DE LA SUPRESIÓN DE LOS DATOS PERSONALES</p>
                </div>

                <div className="legal-subtitle-p-title">
                    <p>Terminación de la condición legitimadora del tratamiento</p>
                </div>
                <div className="legal-text">
                    <p>Cuando los mismos ya no sean útiles ni necesarios para la finalidad que justificó su recogida y tratamiento, o una vez cumplida y agotada dicha finalidad deberá procederse a la supresión de los datos personales, siempre y cuando no sea necesario proceder al bloqueo de los mismos para responder ante posibles responsabilidades derivadas del tratamiento de datos personales y por el plazo de prescripción de las mismas previstas en el Derecho de la Unión o de los Estados miembros que se aplique al responsable del tratamiento, conforme se recoge en el apartado 4.</p>
                </div>
                <div className="legal-subtitle-p-title">
                    <p>Ejercicio del derecho de supresión</p>
                </div>

                <div className="legal-text">
                    <p className="list">
                        Cuando el interesado ejerza el derecho de supresión de los datos personales, siempre que concurran alguna de las circunstancias siguientes:
                </p>
                    <ul>
                        <li>Los datos personales ya no sean necesarios en relación con los fines para los que fueron recogidos o tratados de otro modo.</li>
                        <li>El interesado retire el consentimiento en que se basa el tratamiento y este no se base en otro fundamento jurídico.</li>
                        <li>El interesado se oponga al tratamiento con arreglo al artículo 21.1 del RGPD (derecho de oposición), y no prevalezcan otros motivos legítimos para el tratamiento, o el interesado se oponga al tratamiento cuando éste tenga por objeto la mercadotécnica directa (artículo 21.2 del RGPD).</li>
                        <li>Los datos personales hayan sido tratados ilícitamente.</li>
                        <li>Los datos personales deban suprimirse para el cumplimiento de una obligación legal establecida en el Derecho de la Unión o de los Estados miembros que se aplique al responsable del tratamiento.</li>
                        <li>Los datos personales se hayan obtenido en relación con la oferta de servicios de la sociedad de la información a niños, mencionados en el artículo 8.1 del RGPD.</li>
                    </ul>
                    <p>En estos casos, deberá procederse a la eliminación de los datos personales, siempre y cuando no sea necesario proceder al bloqueo de los mismos para responder ante posibles responsabilidades derivadas del tratamiento de datos personales y por el plazo de prescripción de las mismas previstas en el Derecho de la Unión o de los Estados miembros que se aplique al responsable del tratamiento, conforme se recoge en el apartado 4.</p>
                    <p>No obstante lo anterior, no se aplicará el derecho de supresión, y por tanto los datos podrán continuar siendo tratados por el responsable del tratamiento cuando el tratamiento sea necesario:</p>

                    <ul>
                        <li>Para ejercer el derecho a la libertad de expresión e información.</li>
                        <li>Para el cumplimiento de una obligación legal que requiera el tratamiento de datos impuesta por el Derecho de la Unión o de los Estados miembros que se aplique al responsable del tratamiento, o para el cumplimiento de una misión realizada en interés público o en el ejercicio de poderes públicos conferidos al responsable.</li>
                        <li>Por razones de interés público en el ámbito de la salud pública de conformidad con el artículo 9, apartado 2, letras h) e i), y apartado 3.</li>
                        <li>Con fines de archivo en interés público, fines de investigación científica o histórica o fines estadísticos, de conformidad con el artículo 89, apartado 1, en la medida en que el derecho indicado en el apartado 1 pudiera hacer imposible u obstaculizar gravemente el logro de los objetivos de dicho tratamiento, o para la formulación, el ejercicio o la defensa de reclamaciones.</li>

                    </ul>
                </div>
                <div className="legal-subtitle">
                    <p>04 Mecanismos de supresión de los datos personales</p>
                </div>

                <div className="legal-text">
                    <p>En los supuestos en que, en atención a los criterios anteriores, se concluya el deber de suprimir los datos personales, se deberá proceder a la eliminación física de los mismos ya se encuentren éstos en soporte automatizado o no automatizados:</p>
                </div>

                <div className="legal-subtitle-section">
                    <p>4.1 DATOS CONTENIDOS EN SOPORTES NO AUTOMATIZADOS</p>
                </div>

                <div className="legal-text">
                    <p className="list">Si los datos están contenidos en soporte no automatizado, se deberá proceder a la destrucción física de los documentos. A tal efecto se recomienda:</p>
                    <ul>
                        <li>La utilización de proveedores de servicio de destrucción documental.</li>
                        <li>La utilización de herramientas de destrucción física de papel, tal y como, destructoras de papel.</li>
                        <li>Opcionalmente, y en el caso en que la supresión tenga como origen el ejercicio de derecho de supresión, la supresión podrá tener lugar mediante la entrega de dicha información a la persona o personas que sean titulares de la misma.</li>
                    </ul>
                </div>

                <div className="legal-subtitle-section">
                    <p>4.2 DATOS CONTENIDOS EN SOPORTES INFORMÁTICOS</p>
                </div>

                <div className="legal-text">
                    <p>Si los datos están contenidos en soporte informático, se procederá de igual forma a su eliminación física de la aplicación, sin que sea suficiente el empleo de una marca lógica o el mantenimiento de otro fichero alternativo en el que se registren los derechos de supresión.</p>
                </div>
                <div className="legal-subtitle-section">
                    <p>4.3 MÉTODOS DE BORRADO IDENTIFICATIVOS</p>
                </div>

                <div className="legal-text">
                    <p>Sin perjuicio de cualquier otro método de destrucción que pudiera valorarse, a continuación, se recogen los principales métodos de borrado identificados, clasificados en función de la capacidad efectiva para la eliminación lógica de los datos personales así como sus ventajas e inconvenientes.</p>
                </div>

                <div className="legal-subtitle-p-title">
                    <p>Métodos que no destruyen la información de forma segura, comandos de borrado y   destrucción de la información mediante la utilización de comandos de borrado del  sistema operativo</p>
                </div>

                <div className="legal-text">
                    <ul>
                        <li>Formateo: formateo de un dispositivo.</li>
                        <li>Anonimización: su eficacia dependerá de la técnica de anonimización empleada en cada caso, que deberá garantizar de forma irreversible su identificación.</li>
                    </ul>
                </div>
                <div className="legal-subtitle-p-title">
                    <p>Métodos de destrucción de la información de forma segura</p>
                </div>

                <div className="legal-text">
                    <ul>
                        <li>Desmagnetización: exposición de los soportes de almacenamiento a un potente campo magnético.</li>
                        <li>Destrucción: podrá realizarse a través de diversos procedimientos mecánicos, en función de la naturaleza del soporte, en particular: desintegración, pulverización, fusión, incineración y trituración.</li>
                        <li>Sobreescritura: escritura de un patrón de datos sobre los datos contenidos en los dispositivos de almacenamiento. Deberá asegurarse la sobre-escritura de la totalidad de la superficie de almacenamiento para asegurar la completa destrucción de los datos.</li>
                    </ul>
                </div>

                <div className="legal-subtitle">
                    <p>05 Plazos legales de conservación de datos</p>
                </div>

                <div className="legal-text">
                    <p>En los casos en los que exista obligación legal de conservar los datos durante un periodo de tiempo determinado y/o durante los plazos de prescripción de las acciones que pudieran derivarse de la actividad o servicio prestado, la empresa procederá al bloqueo de los datos durante los referidos plazos.</p>
                    <p>Los datos bloqueados quedarán, únicamente a disposición de las Administraciones Públicas, Jueces y Tribunales, para la atención de las posibles responsabilidades nacidas del tratamiento, durante el plazo de prescripción de éstas y/o durante los plazos legales establecidos al efecto. Cumplidos los indicados plazos, deberá procederse a la supresión de los datos bloqueados de acuerdo con lo descrito en el apartado 4.</p>
                    <p>Para garantizar que los datos personales no se conservan más tiempo del necesario, el responsable del tratamiento ha de establecer plazos para su supresión o revisión periódica, en cada caso, para cada actividad de tratamiento.</p>
                    <p>Para ello, y sin perjuicio de cualesquiera otros plazos que puedan estar previstos en otras normas o circulares que resulten igualmente de aplicación, se recoge en el Anexo I los plazos durante los cuales será necesario conservar los datos personales que hubiesen sido objeto de tratamiento en función de la actividad que tenga causa en el tratamiento de datos personales.</p>
                </div>


                <div className="legal-subtitle-section">
                    <p>5.1 ANEXO I – PLAZOS CONSERVACIÓN DE LA DOCUMENTACIÓN</p>
                </div>

                <div className="legal-subtitle-p-title">
                    <p>Protección de datos</p>
                </div>
                <div className="legal-text">
                    <ul>
                        <li>Plazo — 3 años</li>
                        <li>Descripción — prescribirán las infracciones: muy graves, a los 3 años; graves, a los 2 años; y, leves, al año.</li>
                        <li>Ref. legal — artículos 72.1, 73.1 y 74.1 del Proyecto de Ley Orgánica de Protección de datos</li>
                    </ul>
                </div>
                <div className="legal-subtitle-p-title">
                    <p>Acciones civiles personales</p>
                </div>

                <div className="legal-text">
                    <ul>
                        <li>Plazo — 5 años</li>
                        <li>Descripción — acciones personales de todo tipo que no tengan plazo especial de prescripción fijado desde que pudiera exigirse la obligación, teniendo en cuenta que en las obligaciones continuadas de hacer o no hacer, el plazo comenzará cada vez que se incumplan. Régimen transitorio: acciones derivadas de relaciones jurídicas nacidas entre el 7/10/2000 y el 7/10/2005: plazo de prescripción de 15 años; acciones derivadas de relaciones jurídicas nacidas entre el 7/10/2005 y el 7/10/2015: 5 años después de la entrada en vigor de la ley, es decir, 7/10/2020; acciones derivadas de relaciones jurídicas nacidas a partir de 7/10/2015: plazo de prescripción de cinco (5) años.</li>
                        <li>Ref. legal — artículo 1962 del Código Civil y artículo 1939 del Código Civil en lo que se refiere al régimen transitorio.</li>
                    </ul>
                </div>


                <div className="legal-subtitle-p-title">
                    <p>Documentación de carácter laboral o relacionada con seguridad social</p>
                </div>

                <div className="legal-text">
                    <ul>
                        <li>Plazo — 4 años</li>
                        <li>Descripción — documentación o los registros o soportes informáticos en que se hayan transmitido los correspondientes datos que acrediten el cumplimiento de las obligaciones en materia de colocación y empleo, afiliación, altas, bajas o variaciones que, en su caso, se produjeran en relación con dichas materias, así como los documentos de cotización y los recibos justificativos del pago de salarios y del pago delegado de prestaciones. Añadir toda la documentación contractual: el RD 1424/2002, de 27 de diciembre, por el que se regula la comunicación del contenido de los contratos y de sus copias básicas, nada establece a este respecto, por lo que analógicamente aplicaremos el precepto anterior.</li>

                        <li>Ref. legal — artículo 21.1 del Real Decreto Legislativo 5/2000, de 4 de agosto, por el que se aprueba el texto refundido de la Ley sobre Infracciones y Sanciones en el Orden Social.</li>
                    </ul>
                </div>

                <div className="legal-subtitle-p-title">
                    <p>Documentación contable</p>
                </div>
                <div className="legal-text">
                    <ul>
                        <li>Plazo — 6 años</li>
                        <li>Descripción — libros, correspondencia, documentación y justificantes concernientes a su negocio, debidamente ordenados, a partir del último asiento realizado en los libros, salvo lo que se establezca por disposiciones generales o especiales. Esta obligación mercantil se extiende tanto a los libros obligatorios (ingresos, gastos, bienes de inversión y provisiones, además de la documentación y justificantes en que se soporten las anotaciones registradas en los libros (facturas emitidas y recibidas, tickets, facturas rectificativas, documentos bancarios, etc.).</li>
                        <li>Ref. legal — art. 30 del Real Decreto de 22 de agosto de 1885, por el que se publica el Código de Comercio.</li>
                    </ul>
                </div>

                <div className="legal-subtitle-p-title">
                    <p>Documentación fiscal</p>
                </div>

                <div className="legal-text">
                    <ul>
                        <li>Plazo — 6 años</li>
                        <li>Descripción — los libros de contabilidad y otros libros registros obligatorios según la normativa tributaria que proceda (IRPF, IVA, IS, etc.), así como los soportes documentales que justifiquen las anotaciones registradas en los libros (incluidos los programas y archivos informáticos y cualquier otro justificante que tenga trascendencia fiscal), deben conservarse, al menos, durante el periodo en que la Administración tiene derecho a comprobar e investigar y en consecuencia, a liquidar deuda tributaria.</li>
                        <li>Ref. legal — sección 3ª (La prescripción), Arts. 66 a 70 de la Ley 58/2003, de 17 de diciembre, General Tributaria.</li>
                    </ul>
                </div>

                <div className="legal-subtitle-p-title">
                    <p>Auditoría de cuentas</p>
                </div>
                <div className="legal-text">
                    <ul>
                        <li>Plazo — 5 años</li>
                        <li>Descripción — los auditores de cuentas y las sociedades de auditoría de cuentas conservarán y custodiarán durante el plazo de cinco años, a contar desde la fecha del informe de auditoría, la documentación referente a cada auditoría de cuentas por ellos realizada, incluidos los papeles de trabajo del auditor que constituyan las pruebas y el soporte de las conclusiones que consten en el informe.</li><li>Ref. legal — artículo 30 de 22/2015, de 20 de julio, de Auditoría de Cuentas.</li>
                    </ul>
                </div>

                <div className="legal-subtitle-p-title">
                    <p>Videovigilancia</p>
                </div>
                <div className="legal-text">
                    <ul>
                        <li>Plazo — 1 mes</li>
                        <li>Descripción — las imágenes/sonidos captados por los sistemas de video vigilancia serán cancelados en el plazo máximo de un mes desde su captación.</li>
                        <li>Ref. legal — instrucción 1/2006, de 8 de noviembre, de la Agencia Española de Protección de Datos, sobre el tratamiento de datos personales con fines de vigilancia a través de sistemas de cámaras o videocámaras.</li>
                    </ul>
                </div>
                <div className="legal-text">
                    <ul>
                        <li>

                            Plazo — 1 mes
                        </li>
                        <li>

                            Descripción — las grabaciones serán destruidas en el plazo máximo de un mes desde su captación, salvo que estén relacionadas con infracciones penales o administrativas graves o muy graves en materia de seguridad pública, con una investigación policial en curso o con un procedimiento judicial o administrativo abierto.
                        </li>
                        <li>

                            Ref. legal — Artículo 8 de la Ley Orgánica 4/1997, de 4 de agosto, de utilización de videocámaras por fuerzas y cuerpos de seguridad en lugares públicos. Ver también artículos 18 y 19 del RD 596/1999 de 16 de abril.
                        </li>
                    </ul>
                </div>
                <div className="legal-text">
                    <ul>
                        <li>
                            Plazo — 10 días
                        </li>
                        <li>
                            Descripción — colocación  en zonas comunes centro escolar para protección de los menores:
                            “También contribuiría a la ponderación que estamos realizando la implantación de unos periodos de conservación muy reducidos para la conservación de las imágenes guardadas. La Instrucción 1/2006 establece en su artículo 6 un plazo de un mes desde su captación. Pero podría incluso reducirse ese plazo, teniendo en cuenta que si hubiera acaecido un suceso que afectara a los menores debería ser apreciado en un lapso de tiempo mucho más breve. Así, entendemos que podría establecerse un plazo de diez días, que por un lado es inferior a un mes, pero suficientemente extenso para que el centro docente haya podido percatarse de la existencia de un perjuicio concreto y específico para el menor que pudiera tener consecuencias jurídicas, coincidiendo así con el plazo para el ejercicio del derecho de cancelación de los datos, y conciliado con la protección de los datos personales. Transcurrido dicho plazo de diez días sólo podría conservarse las imágenes que revelaran algún tipo de hecho trascendente en relación con el interés superior del menor”.
                        </li>
                        <li>
                            Ref. legal — informe Jurídico AEPD 475/2014
                        </li>
                    </ul>
                </div>

                <div className="legal-subtitle-p-title">
                    <p>Control acceso a edificios</p>
                </div>

                <div className="legal-text">
                    <ul>
                        <li>
                            Plazo — 1 mes
                        </li>
                        <li>
                            Descripción — los datos incluidos en ficheros automatizados creados para controlar el acceso a edificios, deben cancelarse transcurrido 1 mes a partir de su obtención.
                        </li>
                        <li>
                            Ref. legal — norma quinta de la Instrucción 1/1996, de 1 de marzo, de la Agencia de Protección de Datos, sobre ficheros automatizados establecidos con la finalidad de controlar el acceso a los edificios.
                        </li>
                    </ul>
                </div>


                <div className="legal-subtitle-p-title">
                    <p>Consumidores y usuarios</p>
                </div>

                <div className="legal-text">
                    <ul>
                        <li>
                            Plazo — 3 años
                        </li>
                        <li>
                            Descripción — la acción para reclamar el cumplimiento de la reparación y sustitución del producto por cualquier disconformidad con el contrato, prescribe a los tres años desde la entrega del producto.
                        </li>
                        <li>
                            Ref. legal — artículo 123.4 del Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias.
                        </li>
                    </ul>
                </div>
                <div className="legal-text">
                    <ul>
                        <li>
                            Plazo — 6 meses
                        </li>
                        <li>
                            Descripción — la acción para reclamar el cumplimiento de lo dispuesto en la garantía comercial adicional prescribe a los seis meses desde la finalización del plazo de garantía.
                        </li>
                        <li>
                            Ref. legal — artículo 125.3 del Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias.
                        </li>

                    </ul>
                </div>
                <div className="legal-text">
                    <ul>
                        <li>
                            Plazo — 3 años
                        </li>

                        <li>
                            Descripción — la acción o derecho de recuperación de los productos entregados por el consumidor y usuario al empresario para su reparación prescribe a los tres años a partir del momento de la entrega.
                        </li>
                        <li>
                            Ref. legal — artículo 127 del Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias.
                        </li>

                    </ul>
                </div>
                <div className="legal-text">
                    <ul>
                        <li>
                            Plazo — 3 años
                        </li>
                        <li>
                            Descripción — la acción de reparación de los daños y perjuicios causados por productos fabricados o importados prescribirá a los tres años, a contar desde la fecha en que el perjudicado sufrió el perjuicio, ya sea por defecto del producto o por el daño que dicho defecto le ocasionó, siempre que se conozca al responsable de dicho perjuicio.
                        </li>
                        <li>
                            Ref. legal — artículo 143 del Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias.
                        </li>
                    </ul>
                </div>

                <div className="legal-subtitle-p-title">
                    <p>Servicios de sociedad de la información y comercio electrónico</p>
                </div>

                <div className="legal-text">
                    <ul>
                        <li>

                            Plazo — 3 años
                        </li>
                        <li>

                            Descripción — prescribirán las infracciones muy graves, a los 3 años; graves, a los 2 años; y, leves, a los 6 meses.
                        </li>
                        <li>

                            Ref. legal — artículo 45 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico.
                        </li>
                    </ul>
                </div>

                <div className="legal-subtitle-p-title">
                    <p>Blanqueo de capitales</p>
                </div>

                <div className="legal-text">
                    <ul>
                        <li>
                            Plazo — 10 años
                        </li>
                        <li>
                            Descripción — documentación en que se formalice el cumplimiento de las obligaciones establecidas en la Ley.
                        </li>
                        <li>
                            Ref. legal — en todo caso, el sistema de archivo de los sujetos obligados deberá asegurar la adecuada gestión y disponibilidad de la documentación, tanto a efectos de control interno, como de atención en tiempo y forma a los requerimientos de las autoridades. Artículo 25 de la Ley 10/2010 de 28 de abril, de prevención del blanqueo de capitales y de la financiación del terrorismo.
                        </li>
                    </ul>
                </div>

                <div className="legal-subtitle-p-title">
                    <p>Seguros</p>
                </div>

                <div className="legal-text">
                    <ul>
                        <li>
                            Plazo — 10 días
                        </li>
                        <li>
                            Descripción — datos que les hubieran sido facilitados con anterioridad a la celebración de un contrato si el mismo no llegara a celebrarse, a menos que contasen con el consentimiento específico del interesado que deberá ser expreso si se tratase de datos relacionados con la salud.
                        </li>
                        <li>
                            Ref. legal — artículo 99.9 de la Ley 20/2015, de 14 de julio, de ordenación, supervisión y solvencia de las entidades aseguradoras y reaseguradoras.
                        </li>
                    </ul>
                </div>




            </LegalPolicyContainer>
        )
    }
}