import ProductServices from "../services/ProductServices"

export const AREA_NAMES = {
  LIFE: 'Vida',
  DEATH: 'Decesos',
  PET: 'Mascotas',
  HEALTH: 'Salud',
  GIP: 'GIP',
  DENTAL: 'Dental',
  TRAVEL: 'Travel'
}

export const CARD_NAMES = {
  VIDA: 'Vida',
  DECESOS: 'Decesos',
  MASCOTAS: 'Mascotas',
  SALUD: 'Salud',
  GIP: 'GIP',
  DENTAL: 'Dental',
  TRAVEL: 'Travel',
  PYMES: 'Pymes',
}

export const GESDOC_TYPE = {
  NIP: 'NIP',
  DIP: 'DIP',
}
export const VIAJES = "Viajes"
export const ENG_AREA_NAMES = {
  [AREA_NAMES.LIFE]: 'Life',
  [AREA_NAMES.DEATH]: 'Death',
  [AREA_NAMES.PET]: 'Pet',
  [AREA_NAMES.HEALTH]: 'Health',
  [AREA_NAMES.GIP]: 'GIP',
  [AREA_NAMES.TRAVEL]: 'Travel',
}

export const PRODUCT_CARD_NAME_COLOR: any = {
  [CARD_NAMES.VIDA]: 'keep-calm',
  [CARD_NAMES.DECESOS]: 'deaths',
  [CARD_NAMES.MASCOTAS]: 'pets',
  [CARD_NAMES.SALUD]: 'health',
  [CARD_NAMES.DENTAL]: 'dental',
  [CARD_NAMES.TRAVEL]: 'travel',
  [CARD_NAMES.PYMES]: 'pymes-color',
}

export enum PRODUCT_TYPE {
  TRANQUILITY = 'tranquility',
  MORTGAGE = 'mortgage',
  WOMAN = 'woman',
  RETURN = 'return',
  TRANQUILITY_PLUS = 'tranquility_plus',
  DEATH = 'death',
  PET = 'pet',
  HEALTH = 'health',
  HEALTH_COPAY = 'healthCopay',
  HEALTH_OCASO = 'healthOcaso',
  HEALTH_COPAY_OCASO = 'healthCopayOcaso',
  HEALTH_COPAY_PLUS = 'healthCopayPlus',
  HEALTH_MUTUALISTAS = 'healthMutualistas',
  PROXIMA = 'proxima',
  PROXIMA_PLUS = 'proximaPlus',
  MOMENTO = 'momento',
  INTEGRAL_100K = 'integral100k',
  INTEGRAL_180K = 'integral180k',
  INTEGRAL_240K = 'integral240k',
  DENTAL = 'dental',
  TRAVEL_WELCOME = 'welcome',
  TRAVEL_ALL_YEAR = 'allYear',
  TRAVEL_STUDY = 'study',
  TRAVEL_AND_YOU = 'andYou',
  TRAVEL_AND_YOU_BASIC = 'andYouBasic',
  TRAVEL_AND_YOU_CRUISE = 'andYouCruise',
}


export const getProductTypeFromURL = (url: string) => {
  let urlParts = url ? url.split('/') : []
  let productType = ''

  urlParts.forEach((element:any, index:number) => {
    if(element==='nueva' || element==='salud'){
      if(urlParts[index+1].includes('tomador') || urlParts[index+1].includes('mediador')){
        productType= urlParts[index]
      }else{
        productType= urlParts[index+1]
      }
    }
  })

  return productType
} 

export const getProductTypes = async (isHiring: boolean) => {
  let productTypes: string[] = []
  
  const args = {
    loader: false,
    setLoading: () => { }
  };
  let allProductLinks = await ProductServices.getProductLinks(args)

  let productLinks = allProductLinks.map((product:any) => {return isHiring ? product.link : product.linkSimulacion})
  productLinks.forEach((link: string) => {
    let product = getProductTypeFromURL(link)
    !productTypes.includes(product) && productTypes.push(product)
  })
  
  return productTypes
}

export const setColorSpan = (businessLine : string) => {
  let color = ''
  switch(businessLine){
    case AREA_NAMES.LIFE: 
      color = "SC-004-100"
      break
    case AREA_NAMES.DEATH: 
      color = "SC-005-100"
      break
    case AREA_NAMES.PET:
      color = "SC-002-100"
      break
    case AREA_NAMES.HEALTH:
      color = "PR-001-100"
      break
    default:
      color = "SC-002-100" //Establecer un color por defecto distinto al de mascotas
      break        
  }
  return color
} 