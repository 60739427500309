import styled from 'styled-components';
import { colors } from '../../../../assets';

export const QuestionnaireContainer = styled.div`
  font-family: 'museo sans';
  .questionnaire-title {
    margin-bottom: 19px;
    color: ${colors['PR-001-100']};
    font-weight: 700;
    font-size: 32px;
  }

  .questionnaire-subtitle {
    margin-bottom: 43px;
    color: ${colors['TX-001-80']};
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
  }

  .questionnaire-amount-container {
    margin-bottom: 60px;
    display: flex;
  }

  .questionnaire-amount-item {
    margin-right: 112px;
    &:last-child {
      margin-right: 0;
    }
  }

  .questionnaire-data-container {
    margin-bottom: 118px;
  }

  .questionnaire-data-row {
    margin-bottom: 78px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .questionnaire-question {
    margin-right: 60px;
    width: 100%;
  }

  .questionnaire-options-container {
    width: 130px;
    min-width: 130px;
    display: flex;
  }

  .questionnaire-options-item {
    margin-right: 35px;
    &:last-child {
      margin-right: 0;
    }
  }

  .questionnaire-button-container {
    display: flex;
    margin-bottom: 100px;
  }

  .questionnaire-button-item {
    margin-right: 22px;
    min-width: 180px;
    width: 180px;
    &:last-child {
      margin-right: 0;
    }
  }

  .personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  @media (max-width: 1140px) {
    .questionnaire-amount-container {
      flex-direction: column;
    }

    .questionnaire-amount-item {
      margin: 0;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
