import React, { FC } from "react";
import Table from "../../../../../../components/table-simple/table";
import { images } from "../../../../../../assets";
import Guarantees from "../guarantees-table/guarantees-table";
import { InsuredDataContainer } from "../insured-form.style";
import { calcAge } from "../../../../../../utils/utility";
import { getDayMonthYear } from "../../../../../../utils/time";
import { InsuredSimulationInterface } from "../insured-form.types";
import { Action, Column } from "../../../../../../components/table-simple/table.types";

type DataProps = {
  data: InsuredSimulationInterface[];
  handleRemoveInsured: (row: any) => void;
};

const InsuredTable: FC<DataProps> = ({ data, handleRemoveInsured }) => {
  const dataToTable = convertInsuredData(data);
  const guarantees = data[0].coverages.reduce((acc: string[], coverage) => {
    if (coverage.required) {
      acc.push(coverage.description);
    }
    return acc;
  }, []);

  const actions: Action[] = [
    {
      icon: images.IconDeleteBlue,
      onClick: handleRemoveInsured,
    },
  ];

  return (
    <InsuredDataContainer>
      <div
        className="content-wrapper"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "flex-start",
        }}
      >
        <div style={{ width: "70%" }}>
          <Table columns={columns} data={dataToTable} actions={actions} />
        </div>
        <div style={{ width: "23%" }}>
          <Guarantees guarantees={guarantees}/>
        </div>
      </div>
    </InsuredDataContainer>
  );
};

export default InsuredTable;

const convertInsuredData = (insuredArray: any[]) => {
  const tableData = insuredArray.map(({ birthDate, gender }, index) => {
    return {
      insured: `Asegurado ${index + 1}`,
      birthDate,
      age: calcAge(getDayMonthYear(birthDate).toString()),
      gender: gender === "H" ? "Hombre" : "Mujer",
      id: index,
    };
  });
  return tableData;
};

const columns: Column[] = [
  {
    title: "ASEGURADOS",
    key: "insured",
    position: "left",
  },
  {
    title: "FECHA NACIMIENTO",
    key: "birthDate",
    position: "left",
  },
  {
    title: "EDAD",
    key: "age",
    position: "center",
  },
  {
    title: "SEXO",
    key: "gender",
    position: "center",
  },
];
