import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IAuthService } from '../../auth/AuthService';
import { withGeneral } from '../../context/mixin/with-general';

interface LogoutCallbackProps extends RouteComponentProps {
  authService: IAuthService;
  setUser: Function;
  setHasInformativeNote: Function;
}

class LogoutCallback extends React.Component<LogoutCallbackProps, {}> {

  async componentDidMount() {
    const { history, authService, setUser, setHasInformativeNote } = this.props;
    try {
      await authService.logoutCallback();
      setUser({}, false);
      setHasInformativeNote(false);
      const cookiesStorage = localStorage.getItem('acceptedCookies');
      localStorage.clear();
      if (cookiesStorage) {
        localStorage.setItem('acceptedCookies', cookiesStorage);
      }
    } catch (error) {
      console.log("Error LogoutCallback", error)
    } finally {
      history.push("/");
    }
  }

  render() {
    return (<h1>Cerrando sesión...</h1>);
  }
}

export default withGeneral(withRouter(LogoutCallback));
