import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import LeftInfoModal from "../../../../../components/left-info-modal";
import MainButton from "../../../../../components/main-button";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { isValidDate } from "../../../../../utils/validation";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";
import PersonalInformation, { PersonalData } from "../travel-forms/personal-information/personal-information";
import TravelAddressFormItem, { AddressData } from "../travel-forms/address-item/address-form-item";
import TravelContactFormItem, { ContactData } from "../travel-forms/contact-item/contact-form-item";
import { TravelInsuredDataContainer } from "./travel-insured-data-style";
import { Taker } from "../../health/health- guarantee/health-guarantee-dto/health-guarantee-risks-dto";
import { TravelHireMenuHelper } from "../../../../../components/travel-hire-menu/travel-hire-menu";


export interface TravelInsuredData extends PersonalData {
  address: AddressData;
  contactData: ContactData;
  coverages: any;
}

interface TravelInsuredProps extends RouteComponentProps {
  data: any;
  goToInsured: Function;
  isReadOnly: boolean;
  indexRemove: number;
  match: any;
  newInsured: boolean;
  onGotoDetail: number | undefined;
  policy: any;
  removeInsured: boolean;
  saveHiring: Function;
  saveInsured: boolean;
  setLoading: Function;
  user: any;
  haveInsuredSaved: Function;
  OnAnyChange: Function
  removeSign: Function;
  haveAnyChanges: boolean
}

interface TravelInsuredState {
  validateContact: {
    validateRequest: boolean;
    isValidated: boolean;
  },
  validateAddress: {
    validateRequest: boolean;
    isValidated: boolean;
  },
  validatePerson: {
    validateRequest: boolean;
    isValidated: boolean;
  },
  cleanForm: boolean;
  personalData: PersonalData;
  contactData: ContactData;
  addressData: AddressData;
  insuredList: any[];
  previousModalShow: boolean;
  nextModalShow: boolean;
  forceNext: boolean;
  usePersonalData: boolean;
  useAddressData: boolean;
  useContactData: boolean;
  indexInsured: number;
  anyChange: boolean;
  isPersonalCheckChanging: boolean;
  cancelSignModalShow: boolean;
  policyErrorModalShow: boolean;
}

class TravelInsuredDataLayout extends React.Component<TravelInsuredProps, TravelInsuredState> {

  constructor(props: any) {
    super(props);

    let insureds = [];
    props.data.insureds ?
      insureds = props.data.insureds.map((insured: any) => {

        const newInsured = insured.sameAsTaker ?
          this.handleGetTakerPersonalData(insured, this.props.data.taker) :
          insured;

        if (insured.coverages && insured.coverages.length > 0 && (insured.name === undefined || insured.name.length === 0)) {

          return insured = { ...newInsured, isDataValid: false }

        }
        else {
          return newInsured;
        }

      }) :
      insureds = [];

    this.state = {
      validateContact: {
        validateRequest: false,
        isValidated: true,
      },
      validateAddress: {
        validateRequest: false,
        isValidated: false,
      },
      validatePerson: {
        validateRequest: false,
        isValidated: true,
      },
      cleanForm: false,
      personalData: {
        name: '',
        birthDate: moment().format("DD/MM/YYYY"),
        docNumber: '',
        gender: '',
        civilStatus: '',
        docType: '',
        lastname1: '',
        nationality: '',
        nationalityLabel: '',
        profession: { value: '', code: '', other: '' },
        lastname2: '',
        relationship: '',
        sameAsTaker: false,
        passportDate: undefined,
        physicalPerson: true,
        hasAnotherInsurance: false,
        numPoliza: '',
      },
      contactData: {
        comunicationChannel: '',
        comunicationLanguage: '',
        email: '',
        mobilePhone: '',
        paperless: '',
        contactHour: '',
        landlinePhone: '',
        sameAsTaker: false
      },
      addressData: {
        address: { value: '', code: '', label: '' },
        wayType: '',
        number: '',
        postalCode: '',
        province: { value: '', code: '', label: '' },
        town: { value: '', code: '', label: '' },
        door: '',
        floor: '',
        portal: '',
        stairs: '',
        sameAsTaker: false,
        normalizaDireciones: "S",
      },
      insuredList: insureds,
      previousModalShow: false,
      nextModalShow: false,
      forceNext: false,
      usePersonalData: false,
      useAddressData: false,
      useContactData: false,
      indexInsured: this.props.match.params.index ? Number(this.props.match.params.index) :  -1,
      anyChange: false,
      isPersonalCheckChanging: false,
      cancelSignModalShow: false,
      policyErrorModalShow: false
    };
  }

  componentWillReceiveProps(nextProps: any) {
    try {
      if (nextProps.saveInsured) {
        this.saveInsured();
      }
      if (nextProps.removeInsured) {
        this.removeInsured();
      }
      if (nextProps.newInsured || this.props.location.search.indexOf("new=true") > 0) {
        this.setState({ cleanForm: true, indexInsured: -1, usePersonalData: false, useAddressData: false, useContactData: false },
          () => this.setState({ cleanForm: false }));
        this.props.history.replace(`/contratacion/nueva/travel/${this.props.match.params.product}/asegurados`)
      }
      else if (nextProps.onGotoDetail !== undefined && nextProps.onGotoDetail !== this.state.indexInsured) {
        const { insuredList } = this.state;
        const insuredData = insuredList[nextProps.onGotoDetail];
  
        this.setState({
          indexInsured: nextProps.onGotoDetail,
          usePersonalData: nextProps.onGotoDetail === 0 ? insuredData.sameAsTaker: false,
          useAddressData: insuredData.address && insuredData.address.sameAsTaker,
          useContactData: insuredData.sameAsTaker,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate() {
    const { history } = this.props;
    const { validateContact, validateAddress, validatePerson, cleanForm } = this.state;
    if (validateAddress.validateRequest) {
      validateAddress.validateRequest = false;
      validateContact.validateRequest = false;
      validatePerson.validateRequest = false;

      if (validateContact.isValidated && validateAddress.isValidated && validateAddress.isValidated) {
        history.push(`/contratacion/nueva/travel/${this.props.match.params.product}/proteccion-datos`);
      }

      if (cleanForm) {
        this.setState({ cleanForm: false });
      }
    }
  }
  _actualizarCuestionarios = (index: number, insured: any, insureds: any[], value: any = "") => {
    // En el insured nos llegaria el dni actualizado
    // Tenemos que buscar 
    try {

      let val1 = insureds[index][value];
      let val2 = insured[value]
      if (value === "birthDate")
        return !moment(val1).isSame(moment(val2))
      return val1 !== val2
    } catch (x) {
      return false
    }
  }

  _existsQuestionnaire = (docNumber: string) => !!this.props.data.questionnaires[docNumber];

  componentDidMount = () => {
    const { match, history, data } = this.props;

    if (match && match.params && match.params.index && this.props.onGotoDetail !== Number(match.params.index)) {
      this.setState({ indexInsured: Number(match.params.index) })
      this.props.goToInsured(Number(match.params.index))
    }

    if (history && history.location && history.location.search && history.location.search.indexOf("new=true") > 0) {
      this.setState({ indexInsured: -1, cleanForm: true })
      return;
    }
    if (match && match.params && match.params.index) {
      const { insuredList } = this.state;

      const useAddressData = insuredList.length > 0 &&
        insuredList[match.params.index] &&
        insuredList[match.params.index].address &&
        insuredList[match.params.index].address.sameAsTaker;

      const useContactData = insuredList.length > 0 &&
        insuredList[match.params.index].sameAsTaker;

      const usePersonalData = insuredList.length > 0 &&
        insuredList[match.params.index].sameAsTaker;

      this.setState({
        indexInsured: match.params.index,
        useAddressData,
        usePersonalData,
        useContactData
      });
    }
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);

    if (data.insureds !== undefined && data.insureds.length !== 0) {
      this.setSimulationDataInvalid();
    }

    this.handleInsuredAsTakerChange();
    this.props.haveInsuredSaved(false);
  };

  setSimulationDataInvalid() {
    this.setState({ indexInsured: 0 })
  }

  toValidate = async () => {
    let { validateContact, validateAddress, validatePerson } = this.state;
    validateContact.validateRequest = true;
    validateAddress.validateRequest = true;
    validatePerson.validateRequest = true;

    this.setState({ validateContact, validateAddress, validatePerson });
  }

  async saveInsured() {
    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED)
      this.setState({ cancelSignModalShow: true }) 
    else {
      await this.validateAndSave()
    }
  }

  async validateAndSave() {
    const { data } = this.props;
    if (data && data.signData && data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED)
      this.props.removeSign()

    await this.toValidate();
    if (this.state.validateAddress.isValidated &&
      this.state.validateContact.isValidated &&
      this.state.validatePerson.isValidated) {
      const { personalData, contactData, addressData } = this.state;
      let { indexInsured } = this.state;
      let insured = {
        ...personalData,
        ...contactData,
        address: addressData,
        insured: true,
        coverages: data && data.taker && data.taker.policy ? data.taker.policy.coverages : [],
        beneficiaries: [],
        isDataValid: true,
      }
      // TENEMOS  en this.state.indexInsured el seleccionado y en this.props.data.insureds todos. 
      // Tenemos que mirar si el insured ha cambiado algo y cambiarlo en questi
      if (data && this.state.indexInsured !== undefined && this.state.indexInsured !== -1 && data.questionnaires && data.insureds[indexInsured] && this._existsQuestionnaire(data.insureds[indexInsured].docNumber)) {
        //Comprobar dni 
        if (this._actualizarCuestionarios(this.state.indexInsured, insured, data.insureds, "docNumber")) {
          // Tenemos que actualizar cuestionarios
          data.questionnaires[insured.docNumber] = data.questionnaires[data.insureds[indexInsured].docNumber]
          delete data.questionnaires[data.insureds[indexInsured].docNumber];
          // aqui tenemos guardada la key del questionary pero nos falta actualizar el docNumber
          data.questionnaires[insured.docNumber].insuredDocNumber = insured.docNumber
        }
        //Comprobar fecha de nacimiento
        if (this._actualizarCuestionarios(this.state.indexInsured, insured, data.insureds, "birthDate")) {
          data.questionnaires[insured.docNumber].questionnaireData.questionnaireResponse["04"].value = moment(insured.birthDate).format('DD/MM/YYYY')
        }
        if (this._actualizarCuestionarios(this.state.indexInsured, insured, data.insureds, "gender")) {
          data.questionnaires[insured.docNumber].questionnaireData.questionnaireResponse["02"].value = insured.gender

        }
      }


      this.props.policy.setTravelData(insured, indexInsured > -1 ? indexInsured : undefined);
      this.props.saveHiring(true);

      let newInsuredList = this.state.insuredList;

      if (indexInsured === -1) {
        newInsuredList.push(insured);
        indexInsured = newInsuredList.length - 1;
        this.props.goToInsured(indexInsured);
      } else {
        const coverages = newInsuredList[indexInsured] !== undefined ?
          newInsuredList[indexInsured].coverages :
          [];
        newInsuredList[indexInsured] = { ...insured, coverages };
      }
      this.setState(
        {
          anyChange: false,
          indexInsured,
          insuredList: newInsuredList,
        }, () => this.props.haveInsuredSaved(false)
      );
    }
  }

  removeInsured() {
    const { insureds } = this.props.data;
    const { onGotoDetail, indexRemove, goToInsured } = this.props;

    if (onGotoDetail && (onGotoDetail > indexRemove)) {

      const index = onGotoDetail - 1;
      this.props.history.replace(`/contratacion/nueva/travel/${this.props.match.params.product}/asegurados/${index}`);
      goToInsured(index)

    }
    const newInsuredList = JSON.parse(JSON.stringify(insureds));
    this.setState({ insuredList: newInsuredList });
  }

  onNextClick(forceNext: boolean = false) {
    const { policy } = this.props

    if (forceNext || !this.state.anyChange) {
      if (!this.props.isReadOnly)
        this.props.saveHiring();
        this.props.history.push(
          TravelHireMenuHelper.getStepNavigationPath(
            TravelHireMenuHelper.getStepFromNavigationRoute(
              this.props.match.url,
              policy.product.products_internalName
            ) + 1,
            this.props.data.insureds.lenght,
            this.props.match.params.product
          )
        );
    } else {
      this.setState({ nextModalShow: true });
    }
  }

  onPreviousClick(forcePrevious: boolean = false) {
    const { policy } = this.props

    if (forcePrevious || !this.state.anyChange)
      this.props.history.push(
        TravelHireMenuHelper.getStepNavigationPath(
          TravelHireMenuHelper.getStepFromNavigationRoute(
            this.props.match.url,
            policy.product.products_internalName
          ) - 1,
          this.props.data.insureds.lenght,
          policy.product.products_internalName
        )
        );
    else
      this.setState({ previousModalShow: true });
  }

  onComponentAnyChange() {
    const { anyChange } = this.state;
    if (anyChange === false) {
      this.setState({ anyChange: true });
      this.props.haveInsuredSaved(anyChange)
    }
  }

  receiveInsuredAddress(insuredAddress: AddressData) {
    let { addressData } = this.state;
    addressData = insuredAddress;
    this.setState({ addressData });
  }

  receiveInsuredContact(insuredContact: ContactData) {
    let { contactData } = this.state;
    contactData = insuredContact;
    this.setState({ contactData });
  }

  receiveInsuredPerson(insuredPerson: PersonalData) {
    let { personalData } = this.state;
    personalData = insuredPerson;
    this.setState({ personalData });
  }

  handleUseTakerData(useData: boolean, type: string) {
    if (type === 'personal') {
      this.setState({ usePersonalData: useData, useAddressData: useData, useContactData: useData, isPersonalCheckChanging: true },
        () => this.setState({ isPersonalCheckChanging: false }));
    }
    else {
      if (!this.state.usePersonalData) {
        this.setState({ useAddressData: useData });
      }
      else {
        this.setState({ useAddressData: useData });
      }
    }
  }

  checkDataIsValid(): boolean {
    const { insuredList } = this.state;
    if (insuredList && insuredList.length > 0) {
      // filtra la lista de asegurados a los que se les ha completado sus datos (isDataValid)
      const validInsuredList = insuredList.filter((insured: any) => insured.isDataValid);
      return validInsuredList.length === insuredList.length;
    }
    return false;
  }

  getInsuredsAdress = (insureds: any[] | undefined): AddressData[] | undefined => insureds ? insureds.map((obj) => obj.address) : undefined;

  handleInsuredAsTakerChange() {
    let { insuredList } = this.state;
    let { policy, saveHiring, data, isReadOnly } = this.props;

    let haveChanges: boolean = false;
    insuredList.forEach((insured: TravelInsuredData, indexInsured: number) => {
      if (insured.sameAsTaker) {
        //Validación por si el tomador cambió a persona jurídica
        if (!data.taker.physicalPerson) {
          insured.sameAsTaker = false;
        }
        else {
          insured = this.handleGetTakerPersonalData(insured, data.taker);
        }

        policy.setTravelData(insured, indexInsured > -1 ? indexInsured : undefined);
        haveChanges = true;
      }

      if (insured && insured.address && insured.address.sameAsTaker) {

        insured = this.handleGetTakerAddressData(insured, data.taker);

        policy.setTravelData(insured, indexInsured > -1 ? indexInsured : undefined);
        haveChanges = true;
      }
    })
    !isReadOnly && haveChanges && saveHiring(true);
  }
  catchErrorPoliceNumber() {
    this.setState({ policyErrorModalShow: true })
  }

  handleGetTakerPersonalData(insured: TravelInsuredData, taker: Taker): TravelInsuredData {
    if (this.props.data.questionnaires && this._existsQuestionnaire(insured.docNumber)) {
      if (taker.docNumber !== insured.docNumber) {
        // Tenemos que actualizar cuestionarios
        this.props.data.questionnaires[taker.docNumber] = this.props.data.questionnaires[insured.docNumber]
        delete this.props.data.questionnaires[insured.docNumber];
        // aqui tenemos guardada la key del questionary pero nos falta actualizar el docNumber
        this.props.data.questionnaires[taker.docNumber].insuredDocNumber = taker.docNumber
      }
      if (!moment(taker.birthDate).isSame(moment(insured.birthDate))) {
        if (this.props.data.questionnaires[insured.docNumber])
          this.props.data.questionnaires[insured.docNumber].questionnaireData.questionnaireResponse["04"].value = moment(taker.birthDate).format('DD/MM/YYYY')
        else if (this.props.data.questionnaires[taker.docNumber])
          this.props.data.questionnaires[taker.docNumber].questionnaireData.questionnaireResponse["04"].value = moment(taker.birthDate).format('DD/MM/YYYY')
      }
      if (taker.gender !== insured.gender) {
        if (this.props.data.questionnaires[insured.docNumber])
          this.props.data.questionnaires[insured.docNumber].questionnaireData.questionnaireResponse["02"].value = taker.gender
        else if (this.props.data.questionnaires[taker.docNumber])
          this.props.data.questionnaires[taker.docNumber].questionnaireData.questionnaireResponse["02"].value = taker.gender
      }
    }
    insured.name = taker.name;
    insured.lastname1 = taker.lastname1 ? taker.lastname1 : '';
    insured.lastname2 = taker.lastname2;
    insured.birthDate = moment(taker.birthDate, "DD/MM/YYYY").format("DD/MM/YYYY");
    insured.docType = taker.docType;
    insured.docNumber = taker.docNumber;
    insured.passportDate = taker.passportDate ? isValidDate(taker.passportDate) ? moment(taker.passportDate).toDate() : undefined : undefined;
    insured.gender = taker.gender ? taker.gender : '';
    insured.nationality = taker.nationality;
    insured.nationalityLabel = taker.nationalityLabel ? taker.nationalityLabel : '';
    insured.relationship = taker.relationship;
    insured.physicalPerson = taker.physicalPerson;
    insured.tc2 = taker.tc2;
    insured.reta = taker.reta;
    insured.responsable = taker.responsable;
    insured.hasAnotherInsurance = insured.hasAnotherInsurance;
    return insured;
  }

  handleGetTakerAddressData(insured: TravelInsuredData, taker: Taker): TravelInsuredData {
    insured.address.address = taker.address.address
    insured.address.wayType = taker.address.wayType;
    insured.address.number = taker.address.number;
    insured.address.portal = taker.address.portal;
    insured.address.stairs = taker.address.stairs;
    insured.address.floor = taker.address.floor;
    insured.address.door = taker.address.door;
    insured.address.postalCode = taker.address.postalCode;
    insured.address.town = taker.address.town;
    insured.address.province = taker.address.province;
    insured.address.normalizaDireciones = taker.address.normalizaDireciones;

    return insured;
  }

  render() {
    const { validateContact, validateAddress, validatePerson, cleanForm, useAddressData, useContactData, usePersonalData, indexInsured, isPersonalCheckChanging, cancelSignModalShow } = this.state;
    const { isReadOnly, policy } = this.props;
    const productInternalName = policy.product.products_internalName

    return (
      <TravelInsuredDataContainer>
        <div className="colSin-100">
          <div className="data-flow-status">
            <div className="data-flow-status-title">
              <h2>DATOS DEL ASEGURADO</h2>
            </div>
          </div>

          <PersonalInformation
            validate={validatePerson}
            onValidated={(insured: PersonalData) => this.receiveInsuredPerson(insured)}
            onCheckTakerData={(useData: boolean) => this.handleUseTakerData(useData, 'personal')}
            onCleanForm={cleanForm}
            insured={true}
            setLoading={this.props.setLoading}
            taker={this.props.data.taker}
            insureds={this.props.data.insureds}
            insuredIndex={indexInsured}
            isReadOnly={isReadOnly}
            currentTravelStep={TravelHireMenuHelper.getStepFromNavigationRoute(this.props.history.location.pathname, productInternalName)}
            onAnyChange={() => { this.onComponentAnyChange(); }}
            policyModal={() => { this.catchErrorPoliceNumber(); }}
          />

          <TravelAddressFormItem
            validate={validateAddress}
            onValidated={(insured: AddressData) => this.receiveInsuredAddress(insured)}
            onCheckTakerData={(useData: boolean) => this.handleUseTakerData(useData, 'address')}
            onCleanForm={cleanForm}
            insured={true}
            setLoading={this.props.setLoading}
            useTakerData={useAddressData}
            usePersonalTakerData={usePersonalData}
            taker={this.props.data.taker.address}
            insureds={this.getInsuredsAdress(this.props.data.insureds)}
            insuredIndex={indexInsured}
            isReadOnly={isReadOnly}
            physicalPerson={true}
            isPersonalCheckChanging={isPersonalCheckChanging}
            onAnyChange={() => { this.onComponentAnyChange(); }}
          />

          <TravelContactFormItem
            validate={validateContact}
            onValidated={(insured: ContactData) => this.receiveInsuredContact(insured)}
            useTakerData={useContactData}
            onCleanForm={cleanForm}
            onEditInsured={indexInsured}
            taker={this.props.data.taker}
            insureds={this.props.data.insureds}
            insuredIndex={indexInsured}
            insured={true}
            isReadOnly={isReadOnly}
            onAnyChange={() => { this.onComponentAnyChange(); }}
            isPersonalCheckChanging={isPersonalCheckChanging}
          />

          <div className="colSin-100 topMargin20 bottomMargin20">
            <div className="personal-data-buttons">
              <div className="personal-data-buttons__item">
                <MainButton
                  withTab={true}
                  icon={true}
                  type={"white"}
                  iconImg={images.IconArrowLeftBlue}
                  text="Atrás"
                  onClick={() => this.onPreviousClick()}
                />
              </div>
              <div className="personal-data-buttons__item">
                <MainButton
                  disabled={!this.checkDataIsValid()}
                  withTab={true}
                  text="Siguiente"
                  onClick={() => this.onNextClick()}
                />
              </div>
            </div>
          </div>

          <LeftInfoModal
            img={images.IconWarningYellow}
            mainTitle={"¿Seguro que desea continuar sin guardar?"}
            mainText={"Perderá todos los cambios introducidos."}
            buttonText="Continuar"
            closeButtonText="Cancelar"
            close={() => this.setState({ previousModalShow: false })}
            active={this.state.previousModalShow}
            onClickAccept={() => this.setState({ previousModalShow: false }, () => this.onPreviousClick(true))}
            className="error"
          />
          <LeftInfoModal
            img={images.IconWarningYellow}
            mainTitle={"Error en la búsqueda"}
            mainText={"No se ha encontrado un número de póliza en Asisa Salud asociado al NIF indicado."}
            buttonText="Continuar"
            closeButtonText="Cancelar"
            close={() => this.setState({ policyErrorModalShow: false })}
            active={this.state.policyErrorModalShow}
            onClickAccept={() => this.setState({ policyErrorModalShow: false })}
            className="error"
            noSecondaryButton={true}
          />
          <LeftInfoModal
            img={images.IconWarningYellow}
            mainTitle={"¿Seguro que desea continuar sin guardar?"}
            mainText={"Perderá todos los cambios introducidos."}
            buttonText="Continuar"
            closeButtonText="Cancelar"
            close={() => this.setState({ nextModalShow: false })}
            active={this.state.nextModalShow}
            onClickAccept={() => this.setState({ nextModalShow: false }, () => this.onNextClick(true))}
            className="error"
          />
          <LeftInfoModal
            img={images.IconWarningYellow}
            className="error"
            mainTitle={"¿Seguro que desea continuar?"}
            mainText={"Se cancelará la firma previamente solicitada"}
            buttonText="Continuar"
            closeButtonText="Cancelar"
            close={() => this.setState({ cancelSignModalShow: false })}
            active={cancelSignModalShow}
            onClickAccept={() => {
              this.setState({ cancelSignModalShow: false }, () => this.validateAndSave())
            }}
          />
        </div>
      </TravelInsuredDataContainer>
    );
  }
}

export default withCoverages(withPolicy(withGeneral(withRouter(TravelInsuredDataLayout))));
