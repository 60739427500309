export const compare = (a, b) => {
    if (a.last_nom < b.last_nom)
        return -1;
    if (a.last_nom > b.last_nom)
        return 1;
    return 0;
}

export const capitalizeFirstLetter = text => {
    if (typeof text !== 'string') return ''
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export const capitalizeEachWord = (sentence) => {
    const words = sentence.trim().split(" ");

    return words.map((word) => {
      return word[0].toUpperCase() + word.substring(1); 
    }).join(" ");
  
  }

export const getData = data => {
    const object = {};
    Object.keys(data).forEach(key => {
        object[key] = data[key].value;
    });
    return object;
}

export const removeEmpty = obj => {
    let newObj = {};
    Object.keys(obj).forEach(prop => {
        if (obj[prop] && typeof obj[prop] === "object" && !Array.isArray(obj[prop]) && Object.keys(obj[prop]).length > 0) {
            newObj[prop] = removeEmpty(obj[prop]);
        } else if (Array.isArray(obj[prop])) {
            newObj[prop] = obj[prop].map(o => {
                if (o && typeof o === "object" && !Array.isArray(o)) {
                    return removeEmpty(o);
                } else {
                    return o;
                }
            })
        } else if (typeof obj[prop] !== "object" && (obj[prop] || obj[prop] === false || obj[prop] === 0)) {
            newObj[prop] = obj[prop];
        }
    });
    return newObj;
};

export function currencyFormat(num = 0) {
    if (typeof num !== 'number') num = parseFloat(num);
    return num
        .toFixed(2) // always two decimal digits
        .replace('.', ',') // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // use . as a separator
}

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} array to split
 * @param chunk_size {Integer} Size of every group
 */
export function chunkArray(myArray, chunk_size) {
    if (!myArray) return [];

    var arrayLength = myArray.length;
    var tempArray = [];

    for (let index = 0; index < arrayLength; index += chunk_size) {
        const myChunk = myArray.slice(index, index + chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }

    return tempArray;
}