import React, { Component } from 'react';
import LoaderSpinner from '../loader-spinner';
import { SupplementIframeContainer } from './supplement-iframe-style';

interface SupplementIframeProps {
    url: string;
}

interface SupplementIframeStage {
    loaded: boolean;
}

class SupplementIframeLayout extends Component<SupplementIframeProps, SupplementIframeStage> {

    constructor(props: SupplementIframeProps){
        super(props);

        this.state = {
            loaded : true
        }
    
    };
    hideSpinner = () => {
        this.setState({
            loaded: false
        });
      };
    render() {
        const { url } = this.props;

        return (
            <SupplementIframeContainer>
                <div className="container">
                    <div className='content'>
                {this.state.loaded ? (
                    <LoaderSpinner />
                    ) : null}
                    <iframe 

          
                    width="100%" 
                    height="100%" 
                    scrolling="auto"
                        src={url}
                        onLoad={this.hideSpinner}
                        />
                    </div> 
                </div>
            </SupplementIframeContainer>
        )
    }
}

export default SupplementIframeLayout