import React, { Component } from "react";
import { HealthCampaingContainer } from "./health-campaign-style";

interface HealthCampaignProps {
    campaignName: string;
    campaignDescription: string;
    campaignCode: string;
    onClick: any;
    defaultChecked?: boolean;
    disabled?: boolean;
}

interface HealthCampaignState {
    selected: boolean;
}

class HealthCampaingItem extends Component<HealthCampaignProps, HealthCampaignState> {

    constructor(props: HealthCampaignProps) {
        super(props);
        this.state = {
            selected: props.defaultChecked ? props.defaultChecked : false,
        }
    }

    handleChange() {
        const { selected } = this.state;
        this.setState({ selected: !selected })
    }

    render() {
        const { campaignName, campaignDescription, campaignCode, onClick, defaultChecked, disabled } = this.props;
        const { selected } = this.state;
        return (
            <HealthCampaingContainer>
                <div className="campaingdiv-33">
                    <label className={`offersCard ${disabled ? "disabled" : ""}`}>
                        <input checked={defaultChecked} type="checkbox" name="checkbox"
                            onClick={() => onClick(campaignCode, campaignName, selected)} onChange={() => this.handleChange()} />
                        <span className="checkmark"></span>
                        <div className={` ${selected ? "campaingTitle" : "campaingTitleDisabled"}`}>
                            <h5 className="campaingTittle">{campaignName}</h5>
                        </div>
                        <p className="campaingText">{campaignDescription}</p>
                        <i></i>
                    </label>
                </div>
            </HealthCampaingContainer>
        )

    }
}

export default HealthCampaingItem;
