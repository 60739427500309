import React from "react";
import { HeadsTitleTextContainer } from "./heads-title-text-style";

interface HeadsTitleTextProps {
    children: any;
    className?: string;
    color?: string;
}

const HeadsTitleText = ({children = '', className='', color=''}: HeadsTitleTextProps) => {
        return (
        <HeadsTitleTextContainer color={color} className={className}>
            {children}
        </HeadsTitleTextContainer>
    )
} 
export default HeadsTitleText;