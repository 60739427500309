import React, { useEffect, useState } from "react";
import moment from "moment";

import { withGeneral } from "../../../context/mixin/with-general";
import { ScreenContainer } from "./screen-style";
import CardCommunications from "../../../components/card-communications";
import HeadText from "../../../components/components-text/head-text";
import { STATUS } from "../../../constants/communications";
import CommunicationsServices from "../../../services/CommunicationsServices";
import { getUserRoles } from "../../../utils/mediatorCode";
import {
  NotificationType,
  getShowNotificationsFormat,
  sortByType,
} from "../helpers";

interface ScreenProps {
  setLoading: Function;
  loading: boolean;
}

function Screen(props: ScreenProps) {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  useEffect(() => {
    const userRoles = getUserRoles();
    const newroles = [...userRoles];
    // El ADMIN ve las notificaciones de ASISA y OCASO
    if (newroles.includes("ADMIN")) {
      if (!newroles.includes("SUSCAGENTEOCASO")) newroles.push("SUSCAGENTEOCASO");
      if (!newroles.includes("SUSCAGENTE")) newroles.push("SUSCAGENTE");
    }
    const getNotifications = async () => {
      const args = {
        setLoading: props.setLoading,
        loader: true,
      };
      const filter = {
        fechaActual: moment().format("YYYY-MM-DD").toString(),
        allowedMediatorType: [...newroles, "all"],
        estadoId: STATUS.ACTIVE,
      };
      const response = await CommunicationsServices.getByQuery({
        ...args,
        filter,
      });
      if (response.ok) {
        const notificationsList = getShowNotificationsFormat(response.data);
        const sortedNotifications = sortByType(notificationsList);
        setNotifications(sortedNotifications);
      }
    };
    getNotifications();
  }, []);

  return (
    <ScreenContainer>
      <h2>PANEL DE NOTIFICACIONES</h2>
      {notifications.length > 0 &&
        notifications.map((notification) => {
          return (
            <CardCommunications
              key={notification.id}
              notification={notification}
              origin="panel"
            />
          );
        })}
      {!props.loading && notifications.length <= 0 && (
        <HeadText>No existen notificaciones en este momento.</HeadText>
      )}
    </ScreenContainer>
  );
}

export default withGeneral(Screen);
