import styled from "styled-components";
import { colors } from "../../../../../assets";
import { MainButtonContainer } from "../../../../../components/main-button/main-button-style";

export const MediatorDataContainer = styled.div`
  .data-flow-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  .comparator-title {
    color: ${colors["PR-001-100"]};
    font-weight: 700;
    font-size: 18px;
  }

  .comparator-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .content-wrapper {
    overflow: initial;
    padding-right: 16px;
    padding: 20px;
    background-color: #fbfbfb;
    display: block;
    width: 100%;
    float: left;
  }

  .data-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 30px;
  }

  .personal-data-row {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }

  .personal-data-item {
    margin-right: 40px;

    > ${MainButtonContainer} {
      max-width: 160px;
      margin-top: 28px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .col-25 {
    width: 25%;
  }

  .col-33 {
    width: 33%;
  }

  .col-50 {
    width: 50%;
  }

  .col-75 {
    width: 75%;
  }

  .col-100 {
    width: 100%;
  }

  .personal-checkbox-title {
    margin-bottom: 26px;
    color: ${colors["PR-001-100"]};
    text-transform: uppercase;
    height: 25px;
    width: 100%;
    display: block;
    float: left;
    &.with-description {
      margin-bottom: 10px;
    }
  }

  .personal-checkbox-container {
    margin-bottom: 80px;
  }

  .personal-checkbox-row {
    display: block;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .personal-checkbox-item {
    color: ${colors["TX-001-80"]};
    float: left;
    padding-right: 30px;
    margin-bottom: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  .personal-checkbox-description {
    margin-bottom: 32px;
    color: ${colors["TX-001-80"]};
  }

  .personal-sub-header {
    font-family: "museo sans";
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 18px;
  }

  .personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  .mb-5 {
    margin-bottom: 5px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
`;
