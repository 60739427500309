import moment from "moment";
import React, { Component } from "react";
import { PolicyDataContainer } from "./policy-data-style"
import SelectBox from "../../../../../../components/select-box/select-box";
import CalendarBox from "../../../../../../components/calendar-box/calendar-box";
import { HEALTH_MAX_EFFECT_DAYS } from "../../../../../../services/url";
import { IMediator } from "../../../../../../models/mediator-model";
import { SimpleSelectboxInterface } from "../../../../../simulations/new-simulation/choose-policy/new-simulation.dto";
import { getRelatedMediatorsCodes } from "../../../../../../utils/mediatorCode";

interface PolicyDataProps {
    validate: {
        validateRequest: boolean;
        isValidated: boolean;
    },
    onValidated?: any;
    insured?: boolean;
    data?: any;
    isReadOnly?: boolean;
    mediator: IMediator;
    selectedMediatorCode: string;
    policy: any;
}

interface PolicyDataState {
    policy: {
        startDate: {
            value: string,
            errorMessage: string
        },
        paymentType: {
            value: string,
            errorMessage: string
        }
    };
    maxEffectDays: number;
    relatedMediatorCodes: Array<SimpleSelectboxInterface>;
    mediatorCodeSelected: string,
}

class PolicyData extends Component<PolicyDataProps, PolicyDataState> {

    constructor(props: PolicyDataProps) {
        super(props)
        const getMediador = () => {
            if (props.selectedMediatorCode) {
                return props.selectedMediatorCode;
            } else {
                if (props.data.mediatorData && props.data.mediatorData.code) {
                    return props.data.mediatorData.code
                } else {
                    if (props.data && props.data.taker && props.data.taker.policy && props.data.taker.policy.selectedMediator) {
                        return props.data.taker.policy.selectedMediator;
                    } else {
                        return props.mediator.mediatorCode;
                    }
                }
            }

        }
        this.state = {
            policy: {
                startDate: {
                    value: (props.data.mediatorData && props.data.mediatorData.startDate) ? props.data.mediatorData.startDate : moment().format('DD/MM/YYYY').toLocaleString(),
                    errorMessage: ''
                },
                paymentType: {
                    value: (props.policy.data.price && props.policy.data.price.price && props.policy.data.price.price.payType) ?
                        props.policy.data.price.price.payType :
                        '',
                    errorMessage: ''
                }
            },
            maxEffectDays: HEALTH_MAX_EFFECT_DAYS ? Number(HEALTH_MAX_EFFECT_DAYS) : 0,
            relatedMediatorCodes: getRelatedMediatorsCodes(this.props.mediator),
            mediatorCodeSelected: getMediador(),
        }
    }

    componentWillReceiveProps(nextProps: any) {

        let { validate } = this.props;

        if (nextProps.validate.validateRequest) {

            validate.validateRequest = false;
            validate.isValidated = this.validateForm();

        }

    }

    componentDidMount() {
        if (this.props.data !== undefined && this.props.data.taker && this.props.data.taker.policy !== undefined) {
            this.completeFormTakerData();
        }
    }

    completeFormTakerData() {
        let { policy, mediatorCodeSelected } = this.state;
        const { data } = this.props;

        policy.paymentType.value = data.taker.policy.paymentType;
        policy.startDate.value = data.taker.policy.startDate;
        if (data.mediatorData && data.mediatorData.code)
            mediatorCodeSelected = data.mediatorData.code;


        this.setState({ policy, mediatorCodeSelected });
    }

    handleValueChange(input: string, value: string) {
        let { policy } = this.state;
        switch (input) {
            case 'startDate':
            case 'paymentType':
                Object.keys(policy).forEach((k: string) => {
                    if (k === input) {
                        policy[k].errorMessage = '';
                        policy[k].value = value;
                    }
                });
                break;

            default:
                break;
        }
        this.setState({ policy })
    }

    validateForm(): boolean {

        let { policy, mediatorCodeSelected } = this.state;
        let noErrors = true;

        if (policy.startDate.value.length === 0) {
            policy.startDate.errorMessage = 'required';
            noErrors = false;
        }
        if (policy.paymentType.value.length === 0) {
            policy.paymentType.errorMessage = 'required';
            noErrors = false;
        }

        if (noErrors) {
            if (this.props.onValidated) {
                this.props.onValidated(
                    {
                        startDate: policy.startDate.value,
                        paymentType: policy.paymentType.value,
                        selectedMediator: mediatorCodeSelected
                    }
                );
            }
        }

        this.setState({ policy });
        return noErrors;
    }

    handleSelectMediatorChange(value: string): void {
        this.props.policy.setMediatorSelectedCode && this.props.policy.setMediatorSelectedCode(value);
        this.setState({ mediatorCodeSelected: value });
    }

    render() {
        const { policy, maxEffectDays, relatedMediatorCodes, mediatorCodeSelected } = this.state
        const { isReadOnly } = this.props;
        return (
            <PolicyDataContainer>
                <div className="content-wrapper bottomMargin20" ref={''}>
                    <div className="personal-data-wrapper" id="data-policy">
                        <div className="data-title">
                            <h5>DATOS DE PÓLIZA</h5>
                        </div>
                        <div className="personal-data-container">
                            <div className="personal-data-row">
                                {relatedMediatorCodes.length > 0 &&
                                    <div className="personal-data-mediador col-100">
                                        <div className="selectBox">
                                            <SelectBox
                                                isMediator={true}
                                                optionsText={relatedMediatorCodes}
                                                optionKey={"value"}
                                                disabled={isReadOnly}
                                                optionValue={"label"}
                                                defaultValue={mediatorCodeSelected}
                                                onChange={(value: string) => this.handleSelectMediatorChange(value)}
                                                propValue={mediatorCodeSelected}
                                                required
                                            />
                                            <label>Mediador</label>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="personal-data-row">
                                <div className="personal-data-item col-50" ref={''}>
                                    <div className="inputBox">
                                        <CalendarBox
                                            labelText=""
                                            initialValue={policy.startDate.value}
                                            disabled={isReadOnly}
                                            minDate={moment().subtract(1, "day").toISOString(true)}
                                            maxDate={moment().add(maxEffectDays, "days").toISOString()}
                                            onChange={(value: string) => this.handleValueChange('startDate', value)}
                                            required={true}
                                        />
                                        <label>Fecha de Inicio</label>
                                    </div>
                                </div>
                                <div className="personal-data-item col-50">
                                    <div className="selectBox">
                                        <SelectBox
                                            labelText=""
                                            required={true}
                                            errorCode={policy.paymentType.errorMessage}
                                            optionsText={this.props.policy.productPayTypes}
                                            optionKey={"value"}
                                            optionValue={"label"}
                                            initialValue={policy.paymentType.value}
                                            onChange={(value: string) => this.handleValueChange('paymentType', value)}
                                            disabled={isReadOnly}
                                        />
                                        <label>Tipo de pago</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </PolicyDataContainer>
        )
    }
}

export default PolicyData;