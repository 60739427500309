import React, { Component } from "react";
import { LeftSearchBoxModalContainer } from "./left-searchbox-modal-style";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { images } from "../../assets";
import H4Text from "../components-text/h4-text";
import MainButton from "../main-button";
import WebText from "../components-text/web-text";
import InputBox from "../input-box/input-box";
import { onKeyDown } from "../../utils/utility";
import SearchBox from "../search-box-mediadores/search-box";

interface LeftSearchBoxModalRouterProps { }

interface IInputProp {
  labelText: string;
  value: string;
  errorCode: string;
  onChange: Function;
}

interface ISearchProp {
  labelText: string;
  value: string;
  errorCode: string;
  onChange: Function;
  optionsText: any[];
}

interface LeftISearchBoxModalProps
  extends RouteComponentProps<LeftSearchBoxModalRouterProps> {
  className?: string;
  img?: string;
  onClickAccept: Function;
  mainTitle: string;
  mainText: string;
  buttonText: string;
  active?: boolean;
  close: Function;
  withCancel?: boolean;
  withInput?: IInputProp;
  withSearchBox: ISearchProp;
  closeButtonText?: string;
  noSecondaryButton?: boolean;
}

interface LeftSearchBoxModalState {
  active: string;
  filteredList: any[];
}

class LeftSearchBoxModalLayout extends Component<
  LeftISearchBoxModalProps,
  LeftSearchBoxModalState
> {
  readonly state: LeftSearchBoxModalState;
  containerRef: any;
  subContainerRef: any;

  public static defaultProps = {
    active: false,
    img: images.IconSearchBlue,
    onClickAccept: () => { },
    className: "",
    mainTitle: "",
    mainText: "",
    buttonText: "",
    close: () => { },
  };

  constructor(props: LeftISearchBoxModalProps) {
    super(props);
    this.state = {
      active: props.active ? "active" : "",
      filteredList: []
    };
    this.containerRef = React.createRef();
    this.subContainerRef = React.createRef();
  }

  isVisible(e: any) {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
  }

  handleClickOutside(ev: any) {
    const { close } = this.props;
    const { active } = this.state;
    this.isVisible(ev.target) &&
      this.subContainerRef.current &&
      !this.subContainerRef.current.contains(ev.target) &&
      active &&
      close();
  }

  componentDidUpdate(prevProps: LeftISearchBoxModalProps) {
    if (prevProps.active !== this.props.active) {
      if (this.props.active) {
        this.containerRef.current.addEventListener(
          "click",
          this.handleClickOutside.bind(this)
        );
      } else {
        this.containerRef.current.removeEventListener(
          "click",
          this.handleClickOutside.bind(this)
        );
      }
      this.setState({ active: this.props.active ? "active" : "" });
    }
  }


  close() {
    this.setState({ active: "" });
  }

  open() {
    this.setState({ active: "active" });
  }

  acceptLeftInfoModal() {
    // const { isModifiedAccept, modifiedAccept } = this.state;
    const { onClickAccept, close, withSearchBox } = this.props;
    if (onClickAccept) {
      onClickAccept();
    }
    if (withSearchBox) return;
    close();
  }

  changeValueSearchBox(ev: any) {
    const value: string = ev.target.value;
    this.setState({
      filteredList: this.props.withSearchBox.optionsText.filter((prefession) =>
        prefession.label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            value
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
      ),
    });
  }

  render() {
    const {
      img,
      mainTitle,
      mainText,
      className,
      buttonText,
      close,
      withCancel,
      withInput,
      withSearchBox,
      closeButtonText,
      noSecondaryButton,
    } = this.props;
    const { active, filteredList } = this.state;

    return (
      <LeftSearchBoxModalContainer
        className={`${active} ${className}`}
        ref={this.containerRef}
      >
        <div className="left-info-modal-container" ref={this.subContainerRef}>
          <div className="left-info-modal-close">
            <img src={images.IconCloseGrey} onClick={() => close()} />
          </div>

          <div className="left-info-modal-main">
            <div className="left-info-modal-main-title">
              <div className="left-info-modal-icon-bg">
                <img src={img} />
              </div>
              <div className="left-info-modal-text">
                <H4Text>{mainTitle}</H4Text>
              </div>
            </div>
            <div className="left-info-modal-main-content">
              <div className="left-info-modal-main-copy" dangerouslySetInnerHTML={{ __html: mainText }} />
              {/* {withInput && (
                <div className="left-info-modal-input inputBox">
                  <InputBox
                    placeholder={withInput.labelText}
                    errorCode={withInput.errorCode}
                    maxLength={100}
                    value={withInput.value}
                    onChange={(value: string) => withInput.onChange(value)}
                    onKeyDown={(ev: KeyboardEvent) =>
                      onKeyDown(
                        ev,
                        "Enter",
                        this.acceptLeftInfoModal.bind(this)
                      )
                    }
                  />
                </div>
              )} */}
              {withSearchBox && (

                <div className="selectBox">
                  <SearchBox
                    onChange={(ev: any) => this.changeValueSearchBox(ev)}
                    labelText=""
                    required={true}
                    errorCode={withSearchBox.errorCode}
                    optionsText={filteredList}
                    optionKey={"value"}
                    optionValue={"label"}
                    initialValue={withSearchBox.value}
                    onSelect={(value: any) => withSearchBox.onChange(value)}
                    disabled={false}
                  />
                  <label>{withSearchBox.labelText}</label>
                </div>

              )}
              <div className="left-info-modal-button">
                {!noSecondaryButton &&
                  <MainButton
                    text={closeButtonText ? closeButtonText : "Cancelar"}
                    onClick={() => close()}
                    type={"ghost"}
                  />
                }
                <MainButton
                  text={buttonText}
                  onClick={() => this.acceptLeftInfoModal()}
                />

              </div>
            </div>
          </div>
        </div>
      </LeftSearchBoxModalContainer>
    );
  }
}

export default withRouter(LeftSearchBoxModalLayout);
