import moment from "moment";
import React, { ChangeEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import HeadText from "../../../../../components/components-text/head-text";
import NoteText from "../../../../../components/components-text/note-text";
import Title03Text from "../../../../../components/components-text/title-03-text";
import WebText from "../../../../../components/components-text/web-text";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import MainButton from "../../../../../components/main-button";
import MainTable from "../../../../../components/main-table";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { GestDocFileInfoResponseDto, Metadata } from "../../../../../models/gest-doc-models";
import { IMediator } from "../../../../../models/mediator-model";
import { IUser } from "../../../../../models/user-model";
import GestDocServices from "../../../../../services/Gest-Doc.Services";
import { GestDoc } from "../../../../../services/GestDoc";
import { NOTA_INFORMATIVA_PARENT_NODE_ID } from "../../../../../services/url";
import { downloadFile, formatter } from "../../../../../utils/utility";
import { acceptedFileMimeType } from "../../../../../constants/acceptedFileMimeType";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { DateFormat, ISignedFileState, PaymentMethod } from "../dental-payment-methods/dental-payment-methods-dto";
import { DentalResultDataContainer } from "./dental-result-data-style";
import { DentalHireMenuHelper, Steps } from "../../../../../components/dental-hire-menu/dental-hire-menu";
import DentalInsuredHiringResultItem from "../../../../../components/dental-insured-hiring-result-item/dental-insured-hiring-result-item";
import { PriceBodyResponse } from "../dental-price/dental-price-dto/dental-prices-dto";
import DentalService from "../../../../../services/DentalService";
import { ERROR_MESSAGES } from "../../../../../constants/errorMessages";
import { CONST_HIRING_STATUS } from "../../types";

interface DentalResultProps extends RouteComponentProps {
  user: IUser;
  mediator: IMediator;
  data: any;
  resumeDentalHiring: boolean;
  setLoading: any;
  coverages?: any;
  history: any;
  loading?: boolean;
  policy?: any;
  saveHiring: Function;
  cotizacion: string;
}

interface IDocumentsResult {
  //TODO: Esta columna hay que quitarla hasta saber si viene de algún sitio o se quita
  /* catalog: {
    text: string,
    type: string,
    link: string,
    key: string,
  }; */
  document: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  date: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  action: {
    text: string;
    type: string;
    key: string;
    img: any;
    onClick: Function;
  }
}

interface DentalDocument {
  id: string,
  name: string,
  date: Date,
  type?: string,
  associatedTo: string
}

export interface IProtectionData {
  consentDate: string;
  dataTransfer: string;
  thirdParty: string;
  thirdPartyCalls: string;
}

interface DentalResultState {
  price: {
    value: string;
    paymentTime: string;
    iban: string;
    paymentType: string;
    campaigns: any[];
  },
  mediator: {
    delegation: string;
    mediatorValue: string;
  },
  startDate: string;
  taker: any;
  insureds: any[];
  serverError: string;
  uploadedDocuments: DentalDocument[];
  uploadedDocumentsRows: IDocumentsResult[];
  signedDocument: ISignedFileState;
  signatureNode: string;
  protectionData: IProtectionData;
  showHiredModal: boolean;
  hiredInfoModalTitle: string;
  hiredInfoModalText: string;
}
const documentsColumns = [
  //TODO: Esta columna hay que quitarla hasta saber si viene de algún sitio o se quita
  /* {
    text: "Catálogo",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  }, */
  {
    text: "Documento",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Fecha anexión",
    filter: false,
    ascendent: false,
    key: "responsiveHeader",
  },
  {
    text: "Ver",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  }
];

class DentalResultDataLayout extends React.Component<DentalResultProps, DentalResultState> {
  private hoveredElement: any;

  constructor(props: DentalResultProps) {
    super(props);

    this.hoveredElement = React.createRef();

    const paymentMethod: PaymentMethod = this.getInitialPaymentMethod(props.data.paymentMethod);

    this.state = {
      price: {
        paymentTime: props.data.priceData ? props.data.priceData.price.paymentTime.toUpperCase() : '',
        value: props.data.priceData ? props.data.priceData.price.price : '',
        iban: props.data.paymentMethod && props.data.paymentMethod.mainIban.iban ? props.data.paymentMethod.mainIban.iban : '',
        paymentType: props.data.taker.policy ? props.data.taker.policy.paymentType.toUpperCase() : '',
        campaigns: props.data.priceData ? props.data.priceData.campaigns : [],
      },
      mediator: {
        delegation: props.user.delegation ? props.user.delegation : '',
        mediatorValue: props.user.name ? (props.user.name + ' ' +
          (props.user.lastname1 ? props.user.lastname1 : '') + ' ' +
          (props.user.lastname2 ? props.user.lastname2 : '')) : '',
      },
      startDate: props.data.taker.policy ? props.data.taker.policy.startDate : '',
      taker: props.data.taker ? props.data.taker : '',
      insureds: props.data.insureds ? props.data.insureds : [],
      serverError: '',
      uploadedDocuments: [],
      uploadedDocumentsRows: [],
      signedDocument: this.getDefaultISignedFile(paymentMethod.isPhysicalSignature, paymentMethod.isPhysicalSignature),
      signatureNode: this.props.data.physicalSignatureNode ? this.props.data.physicalSignatureNode : '',
      protectionData: props.data.protectionData,
      showHiredModal: false,
      hiredInfoModalTitle: "",
      hiredInfoModalText: ""
    };
  }

  getInitialPaymentMethod = (initialPaymentMethod?: PaymentMethod): PaymentMethod => {
    const { policy } = this.props
    let paymentMethod = initialPaymentMethod;

    if (!paymentMethod) {
      paymentMethod = {
        mainIban: {
          iban: "",
          sameAsMain: true,
        },
        areInsuredsCopayGrouped: policy.product.products_isCopay ? true : undefined,
        copayIban: policy.product.products_isCopay ? {
          iban: "",
          sameAsMain: true,
          sepaSignedDate: moment().format(DateFormat),
        } : undefined,
        isPhysicalSignature: false,
      };
    }
    return paymentMethod;
  }

  getDefaultISignedFile = (isDisabled: boolean, isRequired: boolean): ISignedFileState => {
    return {
      file: undefined,
      error: "",
      disabled: isDisabled,
      isRequired: isRequired,
    }
  }

  getISignedFile = (isDisabled: boolean, isRequired: boolean, file: File): ISignedFileState => {
    return {
      file: file,
      error: "",
      disabled: isDisabled,
      isRequired: isRequired,
    }
  }

  async downloadDocument(id: string) {
    const { setLoading } = this.props;
    var result = await GestDocServices.getFile(setLoading, true, id);
    if (result && result.fileData) {
      downloadFile(result.fileData, result.fileName, result.fileType, true);
    } else {
      this.setState({ serverError: 'Error al descargar el archivo' })
    }
  }

  loadDentalDocumentsFromGestDoc = async () => {
    const { setLoading, data } = this.props;

    let entries: GestDocFileInfoResponseDto[] = [];
    //Buscamos las fotocopias del DNI subidas
    let peopleGestDocDocuments = await GestDocServices.searchFile(setLoading, true,
      GestDoc.getPeopleDocumentsRequest(
        data.taker
      )
    )
    await data.insureds.forEach(async (insured: any) => {
      const response = await GestDocServices.searchFile(setLoading, true,
        GestDoc.getPeopleDocumentsRequest(
          undefined, [insured]
        )
      )
      if (response && response.list && response.list.entries && response.list.entries.length > 0)
        peopleGestDocDocuments.list.entries.concat(response.list.entries)
    });
    if (peopleGestDocDocuments.list.entries.length) {
      entries = entries.concat(peopleGestDocDocuments.list.entries);
    }

    //Buscamos libro de familia
    //TODO: De momento en Alfresco el libro de familia se sube como tipo de documento "Documentación otros" lo buscamos así pero no sabemos si es definitivo
    let familyBook = await GestDocServices.searchFile(setLoading, true,
      GestDoc.getFamilyBookDocumentRequest(data.taker)
    )

    if (familyBook.list.entries.length) {
      entries = entries.concat(familyBook.list.entries);
    }
    //Buscamos Proyecto de Seguro
    let proyectoSeguro = await GestDocServices.searchFile(setLoading, true,
      GestDoc.getProyectoSeguro(data.idcotiza)
    )
    if (proyectoSeguro.list.entries.length) {
      let arrayProyectoSeguro = []
      if (proyectoSeguro.list.entries.length > 1) {
        arrayProyectoSeguro = proyectoSeguro.list.entries.sort(function (a: any, b: any) {
          var dateA = new Date(a.entry.modifiedAt)
          var dateB = new Date(b.entry.modifiedAt)
          return dateA < dateB ? 1 : -1
        })
      } else {
        arrayProyectoSeguro = proyectoSeguro.list.entries
      }
      entries = entries.concat(arrayProyectoSeguro[0]);
    }
    //Buscamos Solicitud Alta Seguro

    let solicitudAlta = await GestDocServices.searchFile(setLoading, true,
      GestDoc.getSolicitudAlta(data.idcotiza)
    )
    if (solicitudAlta.list.entries.length) {
      let arraySolicitudAlta = []
      if (solicitudAlta.list.entries.length > 1) {
        arraySolicitudAlta = solicitudAlta.list.entries.sort(function (a: any, b: any) {
          var dateA = new Date(a.entry.modifiedAt)
          var dateB = new Date(b.entry.modifiedAt)
          return dateA < dateB ? 1 : -1
        })
      } else {
        arraySolicitudAlta = solicitudAlta.list.entries
      }
      entries = entries.concat(arraySolicitudAlta[0]);
    }

    let uploadedDocuments = entries.map((ele) => {
      return {
        id: ele.entry.id,
        name: ele.entry.name,
        date: ele.entry.createdAt,
        associatedTo: ele.entry.properties && ele.entry.properties["ins:personIdentityDocument"] ? ele.entry.properties["ins:personIdentityDocument"] : ""
      }
    });
    this.setState({ uploadedDocuments: uploadedDocuments }, () => this.loadDocumentRows());
  }

  loadDocumentRows() {
    const { uploadedDocuments } = this.state;
    let rows: IDocumentsResult[] = [];
    if (uploadedDocuments.length > 0) {
      uploadedDocuments.forEach((document) => {
        let row: IDocumentsResult = {
          date: {
            text: moment(document.date).format('DD/MM/YYYY').toString(),
            type: "",
            link: "",
            key: "responsive",
          },
          document: {
            text: document.name ? document.name : '',
            type: "",
            link: "",
            key: "responsive",
          },
          action: {
            text: '',
            type: "",
            key: "icon-button",
            img: images.IconDownloadBlue,
            onClick: () => this.downloadDocument(document.id)
          }
        }
        rows.push(row)
      })
    }

    this.setState({ uploadedDocumentsRows: rows })
  }

  componentWillReceiveProps(nextProps: DentalResultProps) {
    if (nextProps.resumeDentalHiring) {
      this.resumeHiring();
    }
  }

  async resumeHiring() {
    if (this.toValidate()) {

      if (this.props.data.paymentMethod.isPhysicalSignature) {
        let { signatureNode } = this.state;
        if (signatureNode && signatureNode !== '') {
          await GestDocServices.deleteFile(this.props.setLoading, true, signatureNode)
        }

        const { signedDocument } = this.state;
        const { data, user, mediator, cotizacion, policy } = this.props;
        let metadata: Metadata;
        metadata = GestDoc.getFirmaSolicitudSeguroRequest(
          true,
          user,
          data.taker,
          cotizacion,
          data.insureds,
          policy.product.products_colectivo,
          policy.product.products_internalName,
          policy.product.products_combinacion_comercial,
          mediator.nif
        ).fileInfo;

        if (signedDocument.file) {
          const response = await GestDoc.uploadDocument(NOTA_INFORMATIVA_PARENT_NODE_ID, signedDocument.file, metadata, this.props.setLoading);
          if (response) {
            this.props.data.physicalSignatureNode = response.entry.id;
            signatureNode = response.entry.id;
            this.setState({ signatureNode })
            await this.props.saveHiring(true)
          }
        }

      }

      const response: any = await DentalService.dentalHiring({
        setLoading: this.props.setLoading,
        data: {
          COTIZACION: this.props.cotizacion,
          ID_FIRMA_DIGITAL: ''
        },
      }).catch((error) => {
        this.setState({ serverError: `Error al emitir la póliza: ${error.data.message}` });
        throw ERROR_MESSAGES["server-error"];
      });

      if (response && response.body) {
        if (response.body.RESPUESTA && response.body.RESPUESTA.ESTADO && response.body.RESPUESTA.ESTADO.toString() === "") {
          this.props.data.status = CONST_HIRING_STATUS.PENDIENTE_ERROR
          this.props.data.updateStatus = "PENDIENTE_ERROR";
          this.props.saveHiring(true);
          this.setState({ showHiredModal: true, hiredInfoModalTitle: "La póliza ha sido enviada", hiredInfoModalText: "La póliza ha sido enviada con algún error." })
        } else if (response.body.PAPISOLEMISRESP &&
          response.body.PAPISOLEMISRESP.PAPIEMISRESP &&
          response.body.PAPISOLEMISRESP.PAPIEMISRESP.POLIZSEC &&
          response.body.PAPISOLEMISRESP.PAPIEMISRESP.POLIZSEC.toString() !== "") {
          const idPolicy = response.body.PAPISOLEMISRESP.PAPIEMISRESP.POLIZSEC;
          this.props.data.status = CONST_HIRING_STATUS.EMITIDA
          this.props.data.updateStatus = CONST_HIRING_STATUS.EMITIDA;
          this.props.data.policyNumber = idPolicy;
          this.props.saveHiring(true);
          this.setState({ showHiredModal: true, hiredInfoModalTitle: "La póliza ha sido emitida", hiredInfoModalText: `La póliza ${idPolicy ? (idPolicy + " ") : "" }se ha emitido correctamente.` })
        } else {
          this.props.data.status = CONST_HIRING_STATUS.EN_REVISION
          this.props.data.updateStatus = "REVISION";
          const warns: string[] = [];
          if (response.header && response.header.warnings && Array.isArray(response.header.warnings)){
            response.header.warnings.forEach((w: any) => warns.push(w.message));
          }
          this.props.data.emisionError = warns;
          this.props.saveHiring(true);
          this.setState({ showHiredModal: true, hiredInfoModalTitle: "La póliza ha sido enviada", hiredInfoModalText: "La póliza ha sido enviada a falta de comprobación." })
        }
      }
    }

  }

  componentDidMount = () => {
    const { policy } = this.props
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    
    this.loadDentalDocumentsFromGestDoc();
    
    if(Object.keys(policy.product).length!==0){
      this.loadSignDocument();
    }
  };

  componentDidUpdate(prevProps: Readonly<DentalResultProps>, prevState: Readonly<DentalResultState>, snapshot?: any): void {
    const { policy } = this.props
    if(Object.keys(prevProps.policy.product).length===0 && Object.keys(policy.product).length!==0){
      this.loadSignDocument();
    }
  }

  async loadSignDocument() {
    if (this.props.data.physicalSignatureNode) {
      const paymentMethod: PaymentMethod = this.getInitialPaymentMethod(this.props.data.paymentMethod);
      let fileResponse = await GestDocServices.getFile(this.props.setLoading, true, this.props.data.physicalSignatureNode)
      const file: File = new File([(fileResponse.fileData.buffer)], fileResponse.fileName);
      this.setState({ signedDocument: this.getISignedFile(paymentMethod.isPhysicalSignature, paymentMethod.isPhysicalSignature, file) })
    }

  }

  loadInsuredData = (insureds: any, priceResponse: PriceBodyResponse) => {
    const { data } = this.props;
    return insureds.map((insured: any, index: number) =>
    (
      <DentalInsuredHiringResultItem
        age={Math.floor(moment().diff(moment(insured.birthDate, "DD/MM/YYYY", true), "years", true))}
        birthDate={insured.birthDate}
        checked={false}
        coverages={insured.coverages}
        id={insureds.length - index}
        index={index + 1}
        key={index}
        insured={insured}
        pricesResponse={priceResponse}
        payType={data.priceData.price.payType}
      />
    )
    )
  }

  async onSignedDocumentFileChange(e: ChangeEvent<HTMLInputElement>) {
    const { signedDocument } = this.state;
    const { user, data, cotizacion, mediator, policy } = this.props;
    if (e.target.files && e.target.files.length > 0) {

      signedDocument.file = e.target.files[0];

      if (signedDocument.file) {
        let metadata = GestDoc.getFirmaSolicitudSeguroRequest(
          true,
          user,
          data.taker,
          cotizacion,
          data.insureds,
          policy.product.products_colectivo,
          policy.product.products_internalName,
          policy.product.products_combinacion_comercial,
          mediator.nif
        ).fileInfo;
        const response = await GestDoc.uploadDocument(NOTA_INFORMATIVA_PARENT_NODE_ID, signedDocument.file, metadata, this.props.setLoading);
        if (response) {
          this.props.data.physicalSignatureNode = response.entry.id;
          const signatureNode = response.entry.id;
          this.setState({ signatureNode })
          await this.props.saveHiring(true)
        }
      }

      signedDocument.error = ''
      this.setState({ signedDocument });
    }

  }

  toValidate(): boolean {
    let { signedDocument } = this.state;
    let isDataValid: boolean = true;

    if (this.props.data.paymentMethod.isPhysicalSignature && !signedDocument.file) {
      signedDocument.error = '* Ha marcado la casilla Firma Física, debe adjuntar el documento firmado'
      isDataValid = false;
    }

    if (isDataValid) {
      return true;
    }
    else {
      this.hoveredElement.current.scrollIntoView();
      this.setState({ signedDocument })
      return false
    }
  }

  getTotalPrice = (payType: string, IMPORTESFORPAGO: Array<any>) => {
    const totalPrice: any = IMPORTESFORPAGO.find(price => price.PERIODO_COBRO === payType)

    return (totalPrice.PRIMATOTAL)
  }

  render() {
    const { price, mediator, startDate, taker, insureds, serverError, uploadedDocumentsRows, signedDocument, protectionData, showHiredModal, hiredInfoModalTitle, hiredInfoModalText } = this.state;
    let { data } = this.props;
    const { policy } = this.props
    const totalPrice = this.getTotalPrice(
      data && data.priceData && data.priceData.price && data.priceData.price.payType,
      data && data.priceData && data.priceData.priceResponse && data.priceData.priceResponse.body && data.priceData.priceResponse.body.RESPUESTA && data.priceData.priceResponse.body.RESPUESTA.IMPORTESFORPAGO
    )
    const takerAddress =
      taker.address ?
        `${taker.address.address.value} ${taker.address.portal ? ', ' + taker.address.portal : ''} ${taker.address.floor ? ', ' + taker.address.floor : ''} ${taker.address.door}`
        : '';

    return (
      <DentalResultDataContainer>

        <div className="data-flow-status-title">

          <h2>DETALLE PREVIO DE LA CONTRATACIÓN</h2>

        </div>

        <div className="result-simulation-special">
          <div className="result-simulation-special-title">
            <Title03Text>
              <p>{`Nº ${this.props.cotizacion}`}</p>
            </Title03Text>
            <div>
              <p className="price">
                {formatter.format(Number(totalPrice))}
              </p>
            </div>
          </div>
          <div className="result-simulation-special-container">
            <div className="result-simulation-special-item">
              <HeadText>
                <p>
                  Póliza {policy.product.products_name ? policy.product.products_name : ''}
                </p>
              </HeadText>
            </div>
            <div className="result-simulation-special-item">
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>FECHA EFECTO</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>
                      {startDate}
                    </p>
                  </WebText>
                </div>
              </div>
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>FECHA VENCIMIENTO</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>
                      {moment(startDate, 'DD/MM/YYYY').add(1, 'year').format('DD/MM/YYYY')}
                    </p>
                  </WebText>
                </div>
              </div>
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>DELEGACIÓN</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>
                      {mediator.delegation}
                    </p>
                  </WebText>
                </div>
              </div>
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>MEDIADOR</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>
                      {mediator.mediatorValue}
                    </p>
                  </WebText>
                </div>
              </div>
            </div>
          </div>
        </div>

        {price.campaigns && price.campaigns.length > 0 &&
          <div className="result-simulation-item float">
            <div className="result-simulation-special-title">
              <Title03Text>
                <p>Campañas seleccionadas</p>
              </Title03Text>
            </div>
            <div className="result-simulation-item-content">
              {
                price.campaigns.map((campaign: { code: string, name: string }, index: number) => (
                  <div className="colSin- rightPadding20" key={index}>
                    <h5>{campaign.name}</h5>
                  </div>
                ))
              }
            </div>
          </div>}

        <div className="result-simulation-special">
          <div className="result-hiring-special-title">
            <Title03Text>
              <p>Tomador</p>
            </Title03Text>
          </div>
          <div className="result-hiring-special-container col-100">
            <div className="result-hiring-special-first-item col-30">
              <HeadText>
                <p>
                  {taker.name ?
                    `${taker.name} ${taker.lastname1} ${taker.lastname2}` : ''
                  }
                </p>
              </HeadText>
            </div>
            <div className="result-hiring-special-item col-70">

              <div className="result-hiring-special-row">
                <div className="result-hiring-special-subitem col-40">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Tipo documento</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {`${taker.docType} ${taker.physicalPerson ? 'Persona física' : 'Persona jurídica'}`}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Nº documento</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.docNumber}
                      </p>
                    </WebText>
                  </div>
                </div>
                {taker.physicalPerson ?
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Género</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {taker.gender === 'H' ? 'Hombre' : 'Mujer'}
                        </p>
                      </WebText>
                    </div>
                  </div> 
                  : 
                  <div className="result-hiring-special-subitem col-20"> 
                   <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Responsable</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {taker.responsable? taker.responsable:""}
                        </p>
                      </WebText>
                    </div>
                  </div>}
                {taker.physicalPerson ?
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Fecha de nacimiento</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {moment(taker.birthDate).format('DD/MM/YYYY')}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  :
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>Reta</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {taker.reta? taker.reta : ""}
                        </p>
                      </WebText>
                    </div>
                     </div>}
              </div> 

              <div className="result-hiring-special-row">
                <div className="result-hiring-special-subitem col-40">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>DOMICILIO</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {takerAddress}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>LOCALIDAD</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.address ? taker.address.town.value : ''}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>PROVINCIA</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.address ? taker.address.province.value : ''}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>CÓDIGO POSTAL</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.address ? taker.address.postalCode : ''}
                      </p>
                    </WebText>
                  </div>
                </div>
              </div>

              <div className="result-hiring-special-row">
                <div className="result-hiring-special-subitem col-40">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Email</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.email}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Teléfono fijo</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.landlinePhone}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Teléfono móvil</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.mobilePhone}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="col-20"></div>
              </div>

              <div className="result-hiring-special-row">
                <div className="result-hiring-special-subitem col-40">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>CUENTA BANCARIA</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {price.iban}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>FORMA DE PAGO</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {price.paymentTime}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>MEDIO DE PAGO</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {price.iban ? 'DOMICILIACIÓN' : ''}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="col-20"></div>
              </div>

              {taker.physicalPerson &&
                <div>
                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>FECHA CONSENTIMIENTO</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {protectionData.consentDate}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>CESIÓN DE DATOS</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {protectionData.dataTransfer === 'S' ? 'SI' : 'NO'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>ENVÍO DE PUBLICIDAD DE TERCEROS</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {protectionData.thirdParty === 'S' ? 'SI' : 'NO'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className=""></div>
                  </div>
                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-50">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Cesión de llamadas promocionando productos de terceros</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {protectionData.thirdPartyCalls === 'S' ? 'SI' : 'NO'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>

        </div>

        {data &&
          data.priceData &&
          data.priceData.priceResponse &&
          data.priceData.priceResponse.body &&
          data.priceData.priceResponse.body.RESPUESTA &&
          <div className="result-simulation-item-content">
            <div className="tabs">
              <div className="result-hiring-special-title">
                <Title03Text>
                  <p>Asegurados</p>
                </Title03Text>
              </div>
              {
                this.loadInsuredData(insureds, data.priceData.priceResponse.body.RESPUESTA)
              }
            </div>
          </div>
        }

        {
          uploadedDocumentsRows.length > 0 &&
          <div className="result-simulation-special">
            <div className="result-simulation-item-content">
              <div className="result-hiring-special-title">
                <Title03Text>
                  <p>Documentos</p>
                </Title03Text>
              </div>
              <div className="detail-clients-policies-item-content-table">
                <MainTable
                  className="table topMargin15"
                  columns={documentsColumns}
                  rows={uploadedDocumentsRows}
                  keysRow={[/* "catalog", */ "document", "date", "action"]}
                />
              </div>
            </div>
          </div>
        }

        {
          this.props.data.paymentMethod && this.props.data.paymentMethod.isPhysicalSignature &&
          <div className="result-simulation-special">
            <div className="result-simulation-item-content">
              <div className="result-hiring-special-title">
                <Title03Text>
                  <p>Firma física</p>
                </Title03Text>
              </div>
              <div className="colSin-100 topMargin20">
                <div className="colSin-50 rightPadding20">
                  <div className="inputBox custom-file-upload">

                    <div className={`colSin-70 ${'isSignedDocumentRequired'}`}>
                      <input type="text" readOnly value={signedDocument.file ? signedDocument.file.name : ""} disabled={false} title="Documento Firmado" />
                    </div>

                    <div ref={this.hoveredElement} className="colSin-30">
                      <label className="custom-file-upload-button topMargin5">
                        <input type="file"
                          accept={acceptedFileMimeType.toString()}
                          onChange={(e) => this.onSignedDocumentFileChange(e)}
                          title="DOCUMENTO FIRMADO"
                        />
                        <MainButton
                          onClick={() => { return true }}
                          text="Buscar"
                        />
                      </label>
                    </div>

                    <label>Adjuntar documento firmado</label>
                  </div>
                  {signedDocument.error && (
                    <div className="input-box-error-file">
                      <NoteText>
                        <p>{signedDocument.error}</p>
                      </NoteText>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }

        <div className="colSin-100">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push(DentalHireMenuHelper.getPreviousStepNavigationPath(Steps.DetallePrevioContratacion))}
              />
            </div>
          </div>
        </div>
        <ErrorModalContainer className={`${serverError && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{serverError}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={() => this.setState({ serverError: '' })} />
            </div>

          </div>
        </ErrorModalContainer>
        <LeftInfoModal
          img={images.IconUserBlue}
          className={""}
          mainTitle={hiredInfoModalTitle}
          mainText={hiredInfoModalText}
          buttonText={"Aceptar"}
          noSecondaryButton
          active={showHiredModal}
          onClickAccept={() => this.props.history.push('/contratacion/pendiente')}
          close={() => this.props.history.push('/contratacion/pendiente')}
        />
      </DentalResultDataContainer >
    );
  }
}

export default withCoverages(withPolicy(withGeneral(withRouter(DentalResultDataLayout))));
