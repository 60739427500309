import styled from "styled-components";
import { colors } from "../../../assets";
import { images } from "../../../assets/images";

export const PermissionsUploadContainer = styled.div`
  margin-bottom: 80px;

  .selectBox {
    max-width: 300px;
  }

  .permissions {
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-column-gap: 40px;
    grid-row-gap: 20px;

    &-data {
      flex-grow: 1;
    }

    &-action {
      align-self: flex-end;

      & > div {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .table.uploadedTable {
      min-width: initial;

      th {
        text-align: center;
      }

      .action-buttons {
        text-align: center;
      }
    }

    .uploadedFileTable > div {
      display: flex;
      width: 35px;
      height: 35px;
    }
  }

  .custom-file-upload-button {
    background-color: #e4e4e4;

    > input[type="file"] {
      display: none;

      ~ div {
        background-color: ${colors["PR-001-100"]};
        color: #fff;
        border-radius: 0px 3px 3px 0px;

        &:before {
          content: url(${images.IconSearchWhite});
          position: absolute;
          width: 15px;
          height: 15px;
          left: 15px;
        }
      }

      &:disabled {
        ~ div {
          background-color: #e2e2e2;
          border-radius: 0px 3px 3px 0px;
          color: #ababab;

          &:before {
            content: url(${images.IconSearchGray});
          }
        }
      }
    }
  }

  .text-max-size {
    text-align: right;
    font-style: italic;
    margin-top: 14px;
    font-size: 14px;
  }

  .custom-file-upload {
    display: flex;

    input[type="text"] {
      border-radius: 3px 0px 0px 3px;
    }
  }

  .upload-buttons {
    > div {
      position: relative;
      background-color: #fff;
      color: ${colors["PR-001-100"]};
      border: 1px solid ${colors["PR-001-100"]};
      min-width: 250px;
      max-width: 45%;
      transition: all 300ms;

      &:hover {
        background-color: ${colors["PR-001-100"]};
        color: #fff;

        &:before {
          content: url(${images.IconUploadWhite});
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }

        .main-button-icon {
          display: none;
        }
      }

      &.disabled {
        position: relative;
        pointer-events: none;
        cursor: default;
        background-color: #e2e2e2;
        color: #ababab;
        border: none;

        .main-button-icon {
          display: none;
        }

        &:before {
          content: url(${images.IconUploadGrey});
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }
    }
  }
  .left-info-modal-container {
    max-width: 900px;

    ul {
      margin-bottom: 40px;
    }
  }
`;
