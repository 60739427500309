import React, { Component } from "react";
import { ValueType } from "react-select/lib/types";
import { SimpleSelectboxInterface } from "../../containers/simulations/new-simulation/choose-policy/new-simulation.dto";
import NoteText from "../components-text/note-text";
import { MultiSelectContainer } from "./multi-select-box-style";
import MultiSelectModal from "./multi-select-modal";

interface MultiSelectProps {
  options: Array<SimpleSelectboxInterface>;
  onChange: Function;
  placeholder?: string;
  noOptionsMessage?: string;
  disabled?: boolean;
  required?: boolean;
  errorCode?: string;
  defaultValues: Array<string>;
}

interface MultiSelectState {
  isFocused: boolean;
  defaultValues: Array<string>;
  openModal: boolean;
}

export default class MultiSelect extends Component<
  MultiSelectProps,
  MultiSelectState
> {
  constructor(props: MultiSelectProps) {
    super(props);

    this.state = {
      isFocused: false,
      defaultValues: this.props.defaultValues ? this.props.defaultValues : [],
      openModal: false,
    };
  }

  componentDidUpdate(prevProps: MultiSelectProps) {
    if (prevProps.defaultValues !== this.props.defaultValues) {
      this.setState({ defaultValues: this.props.defaultValues });
    }
  }

  MultigetOptionLabel = (name: string) => {
    return name;
  };

  MultigetOptionValue = (value: string) => {
    return value;
  };

  MultihandleChange = (selectedElements: ValueType<string>) => {
    const { onChange } = this.props;
    onChange(selectedElements);
    this.handleOnFocusMenu(true);
  };

  handleOnFocusMenu = (isSelected: boolean) => {
    this.setState({ isFocused: isSelected });
  };

  handleOnClickAccept = (values: Array<string>) => {
    this.setState({ defaultValues: values, openModal: false });
    this.props.onChange(values);
  };

  openModal() {
    this.setState({ openModal: true });
  }

  closeModal() {
    this.setState({ openModal: false });
  }

  render() {
    const {
      options,
      placeholder,
      noOptionsMessage,
      disabled,
      required,
      errorCode,
    } = this.props;

    const { isFocused, defaultValues, openModal } = this.state;

    return (
      <>
        <MultiSelectContainer
          onClick={() => this.openModal()}
          className={`${disabled ? "disabled" : ""} ${
            errorCode ? "error" : ""
          } ${required ? "required" : ""}`}>
          <div className="multiSelect__control">
            {defaultValues.map((item, key) => (
              <p key={key} className="multiSelect__control-input">
                {item}
              </p>
            ))}
          </div>

          {errorCode && (
            <div className="input-box-error">
              <NoteText>
                <p>{`* ${errorCode}`}</p>
              </NoteText>
            </div>
          )}
        </MultiSelectContainer>
        {openModal && (
          <MultiSelectModal
            defaultValues={defaultValues}
            active={true}
            mainTitle="Selecciona Mediador/es"
            onClickAccept={(e: Array<string>) => this.handleOnClickAccept(e)}
            onCloseModal={() => this.closeModal()}
            checkConditions={options}
            className={`${errorCode ? "error" : ""}`}
          />
        )}
      </>
    );
  }
}
