import GeneralServices from "./GeneralServices";

class CampaignsConfigurationServices {
  postCampaigns = async (args: any) => {
    try {
      const res = await GeneralServices.post({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        data: args.data,
        endpoint: "campaigns"
      });

      return res.data;
    } catch (err) {
      throw err;
    }
  };

  putCampaigns = async (args: any) => {
    try {
      const res = await GeneralServices.put({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        data: args.data,
        endpoint: `campaigns/${args.id}`
      });

      return res.data;
    } catch (err) {
      throw err;
    }
  };

  getCampaigns = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `campaigns${args.take ? `?take=${args.take}&skip=${args.skip}${!!args.year ? `&year=${args.year}` : ''}` : ''}`
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  getCampaign = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `campaigns/${args.id}`
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  deleteCampaign = async (args: any) => {
    try {
      const res = await GeneralServices.deleteReq({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `campaigns/${args.id}/cancell`
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  getScores = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `score?take=${args.take}&skip=${args.skip}${!!args.year ? `&year=${args.year}` : ''}`
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  postScore = async (args: any) => {
    try {
      const res = await GeneralServices.post({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: "score",
        data: args.data
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  putScore = async (args: any) => {
    try {
      const res = await GeneralServices.put({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `score/${args.id}`,
        data: args.data
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  deleteScore = async (args: any) => {
    try {
      const res = await GeneralServices.deleteReq({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `score/${args.id}`
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  getParticipants = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `stats/dashboard?campaign=${args.id}&take=${args.take}&skip=${args.skip}${args.search ? `&search=${args.search}` : ''}`
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new CampaignsConfigurationServices();
