
import { COMUNICATION_CHANNEL_OPTIONS, ValueFormHealth } from "../../../../../../constants/health";

const DefaultValueFormHealth = (isDisabled: boolean, defaultValue: string = ""): ValueFormHealth => {
    return {
        value: defaultValue,
        errorCode: "",
        disabled: isDisabled,
        code: ''
    };
}

export interface ContactDataDto {
    paperlessBox: ValueFormHealth;
    contactHour: ValueFormHealth;
    comunicationLanguage: ValueFormHealth;
    mobilePhone: ValueFormHealth;
    landlinePhone: ValueFormHealth;
    emailInput: ValueFormHealth;
    comunicationChannel: ValueFormHealth;
}

export const DefaultContactDataDto = (): ContactDataDto => {
    return {
        paperlessBox: DefaultValueFormHealth(false, "No"),
        contactHour: DefaultValueFormHealth(false),
        comunicationLanguage: DefaultValueFormHealth(false),
        mobilePhone: DefaultValueFormHealth(false),
        landlinePhone: DefaultValueFormHealth(false),
        emailInput: DefaultValueFormHealth(false),
        comunicationChannel: DefaultValueFormHealth(false, COMUNICATION_CHANNEL_OPTIONS.TELEFONICO),
    };
}