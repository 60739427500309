import React from "react";
import { withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import MainButton from "../../../../../components/main-button";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { getDayMonthYear } from "../../../../../utils/time";
import { NewHiringContainer } from "../../new-hiring-style";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import { calcAge } from "../../../../../utils/utility";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";
import { DentalGuaranteeDataContainer } from "./dental-guarantee-data-style";
import { DentalGuaranteeProps, DentalGuaranteeState } from "./dental-guarantee-dto/dental-guarantee-dto";
import { CoverageInterface } from "../../gip/gip-guarantee/gip-guarantee-dto/gip-guarantee-dto";
import { DentalHireMenuHelper, Steps } from "../../../../../components/dental-hire-menu/dental-hire-menu";
import DentalService from "../../../../../services/DentalService";
import { SendaDentalBaseResponse } from "../../../../../services/dto/DentalService-dto";
import HealthService from "../../../../../services/HealthService";
import { IHealthCoverage } from "../../../../../constants/health";
import { withCoverages } from "../../../../../context/mixin/with-coverages";

class DentalGuaranteeDataLayout extends React.Component<DentalGuaranteeProps, DentalGuaranteeState> {

  constructor(props: DentalGuaranteeProps) {
    super(props);

    this.state = {
      currentIndexInsured: -1,
      data: [],
      serverError: false,
      buttonDisable: false,
      modalErrorText: '',
      cancelSignModalShow: false,
      anyChange: false,
      coverages: []
    }
  }

  async getCoverages() {
    const { data, setLoading, policy, coverages} = this.props;
    const productType: string = policy.product.products_coverageName;
    let coveragesAux: IHealthCoverage[] = [] 

    if (coverages && coverages[productType] ) {
      coveragesAux = coverages[productType]
    } else if(productType){
      coveragesAux = await HealthService.getCoveragesFromDB(setLoading, true, productType)
      .catch((error) => {
        this.setState({ serverError: true, buttonDisable: true, modalErrorText: `Error al obtener las coberturas: ${error.data.message}` })
        return []
      });
    }

    data.insureds.map((insured: any) => {
      insured.coverages = this.formatCoverages(coveragesAux);
    });
    
    this.setState({ coverages: [...this.formatCoverages(coveragesAux)], data })
  }

  formatCoverages(unformattedCoverages: IHealthCoverage[]): IHealthCoverage[] {
    if (unformattedCoverages && unformattedCoverages.length > 0) {
      let formattedCoverages: IHealthCoverage[] = []
      unformattedCoverages.forEach((coverage: any) => {
        formattedCoverages.push({
          capMaxCober: coverage.capMaxCober,
          capMinCober: coverage.capMinCober,
          checked: coverage.required,
          code: coverage.code,
          description: coverage.name,
          edadMax: coverage.edadMax ? coverage.edadMax : "",
          edadMin: coverage.edadMin ? coverage.edadMin : "",
          required: coverage.required,
          capital: coverage.capital,
        })
      })
      return formattedCoverages;
    }
    return []
  }

  validateAgeInsureds = () => {
    const { data } = this.props;
    const { serverError, coverages } = this.state;
    if (data && data.insureds) {

      const covReqAgeMax = coverages.filter((c: any) => c.required && c.edadMax !== '');

      data.insureds.map((insured: any) => {

        const age = calcAge(insured.birthDate) || 0;
        const isHighAge = covReqAgeMax.some((c: any) => age > c.edadMax);

        if (isHighAge && !serverError) {
          this.setState({
            serverError: true,
            buttonDisable: true,
            modalErrorText: `El asegurado ${insured.name} ${insured.lastname1} ${insured.lastname2}
              excede de la edad máxima permitida para alguna de las coberturas requeridas.`
          });
          return;
        }

      });
    }
  }

  toValidate = async () => {
    if (this.props.isReadOnly)
      return this.props.newStep();

    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange)
      this.setState({ cancelSignModalShow: true })
    else {
      await this.validateAndSave()
    }
  }

  async validateAndSave() {

    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange)
      this.props.removeSign()

    const { data } = this.state;
    const { setLoading, policy } = this.props;

    let isError = false;
    let status: string = '';

    const riskRequestData = {
      taker: data.taker,
      insureds: data.insureds.map((insured: any) => {
        return {
          ...insured,
          coverages: insured.coverages.map((coverage: any) => {
            return {
              code: coverage.code,
              capital: Number(coverage.capital)
            }
          })
        }
      }),
      idcotiza: data.idcotiza
    }

    const riskData: SendaDentalBaseResponse = await DentalService.postRiskData(setLoading, true, riskRequestData, policy.product.products_internalName)
      .then((data: any) => {
        return data;
      })
      .catch((error) => {
        isError = true
        this.setState({
          serverError: true,
          modalErrorText: `${(error && error.data && error.data.message) ?
            error.data.message : 'Se ha producido un error. Vuelva a intentarlo más tarde.'}`
        })
      });

    if (riskData && riskData.body && riskData.body.PAPISOLSALDAT && riskData.body.PAPISOLSALDAT.RESPUESTA && riskData.body.PAPISOLSALDAT.RESPUESTA.COTIZACION) {
      data.idcotiza = riskData.body.PAPISOLSALDAT.RESPUESTA.COTIZACION;

      if (isError === false) {

        const newData = {
          ...data,
          guarantees: true,
          status
        }
        this.setState(newData);
        this.props.policy.setDentalData(newData);
        this.props.saveHiring();
        this.props.newStep();

      }
    }
  }

  async componentDidMount() {
    const { data, policy } = this.props;

    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);

    if (data && data.insureds && data.insureds.length === 0) {
      this.props.history.push(
        this.props.history.location.pathname.replace("garantias", "tomador")
      );
    }

    if(Object.keys(policy.product).length!==0){
      await this.getCoverages()
      this.validateAgeInsureds()
    }

  }

  async componentDidUpdate(prevProps: Readonly<DentalGuaranteeProps>, prevState: Readonly<DentalGuaranteeState>, snapshot?: any) {
    const { policy } = this.props;
    const { coverages } = this.state;

    if(Object.keys(prevProps.policy.product).length===0 && Object.keys(policy.product).length!==0 && Object.keys(coverages).length===0 ){
      await this.getCoverages()
      this.validateAgeInsureds()
    }
  }

  onHandleChange(insured: any, coverage: any) {

    if (coverage.required) return false

    const { data } = this.state

    const index = insured.coverages.map(function (e: any) {
      return e.code
    }).indexOf(coverage.code)

    const id = data.insureds.map(function (e: any) {
      return e.docNumber
    }).indexOf(insured.docNumber)

    if (index === -1) {

      data.insureds[id].coverages.push(coverage)

    } else {
      data.insureds[id].coverages.splice(index, 1);
    }

    this.setState({ data, currentIndexInsured: id })

  }

  render() {
    const {
      data,
      serverError,
      buttonDisable,
      cancelSignModalShow
    } = this.state
    const { isReadOnly } = this.props;

    return (

      <NewHiringContainer>
        <DentalGuaranteeDataContainer>

          <div className="colSin-100">

            <div className="data-flow-status">
              <div className="data-flow-status-title">

                <h2>GARANTIAS DE LA PÓLIZA</h2>

              </div>
            </div>

            <div className="content-wrapper">
              <div className="tabs">
                {data && data.insureds && data.insureds.map((insured: any, index: number) => (

                  <div className="tab" key={index}>

                    <input
                      type="checkbox"
                      defaultChecked
                    />

                    <label className="tab-label">
                      <div>
                        <span>ASEGURADO</span>
                        <span>FECHA NACIMIENTO</span>
                        <span>EDAD</span>
                        <span>Nº DOCUMENTO</span>
                      </div>
                      <div>
                        <span>{`${insured.name} ${insured.lastname1} ${insured.lastname2}`}</span>
                        <span>{getDayMonthYear(insured.birthDate)}</span>
                        <span>{calcAge(getDayMonthYear(insured.birthDate).toString())}</span>
                        <span>{insured.docNumber}</span>
                      </div>
                    </label>
                    <div className="tab-content" >
                      {insured && insured.coverages && insured.coverages.map((coverage: CoverageInterface, coverageIndex: number) =>
                        <div className="rightPadding10" key={coverageIndex}>
                          <label
                            className={`checkbox-container ${isReadOnly ? "disabled" : ""} ${(coverage.required) ? ' noPointer' : ''}`}>
                            <input
                              disabled={isReadOnly}
                              type="checkbox"
                              checked={
                                coverage.required ||
                                insured.coverages.map(function (e: any) {
                                  return e.code
                                }).indexOf(coverage.code) > -1
                              }
                              onChange={(e) => this.onHandleChange(insured, coverage)}
                            />
                            <div className={
                              `checkmark-container${coverage.required ? '-readonly' : ''}`
                            }>
                              <div className="checkmark-item">
                                <img
                                  src={
                                    coverage.required ||
                                      insured.coverages.map(function (e: any) { return e.code })
                                        .indexOf(coverage.code) > -1 ?
                                      images.IconTickWhite :
                                      images.IconCloseWhite}
                                  alt="" />
                              </div>
                            </div>
                            <div>
                              {coverage.description}
                            </div>
                          </label>
                        </div>
                      )}
                    </div>

                  </div>

                ))}

              </div>
            </div>

            <div className="colSin-100 topMargin20 bottomMargin20">
              <div className="personal-data-buttons">

                <div className="personal-data-buttons__item">
                  <MainButton
                    withTab={true}
                    icon={true}
                    type={"white"}
                    iconImg={images.IconArrowLeftBlue}
                    text="Atrás"
                    onClick={() => this.props.history.push(DentalHireMenuHelper.getPreviousStepNavigationPath(Steps.Garantias, data.insureds.length))}
                  />
                </div>
                <div className="personal-data-buttons__item">
                  <MainButton
                    withTab={true}
                    text="Siguiente"
                    disabled={buttonDisable}
                    onClick={() => this.toValidate()}
                  />
                </div>
              </div>
            </div>

          </div>



        </DentalGuaranteeDataContainer>
        <LeftInfoModal
          img={images.IconWarningYellow}
          className="error"
          mainTitle={"¿Seguro que desea continuar?"}
          mainText={"Se cancelará la firma previamente solicitada"}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ cancelSignModalShow: false })}
          active={cancelSignModalShow}
          onClickAccept={() => {
            this.setState({ cancelSignModalShow: false }, () => this.validateAndSave())
          }}
        />

        <ErrorModalContainer className={`${serverError && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={() => this.setState({ serverError: false })} />
            </div>

          </div>
        </ErrorModalContainer>

      </NewHiringContainer>
    );
  }
}

export default withGeneral(withCoverages(withPolicy(withRouter(DentalGuaranteeDataLayout))));
