export const REPORT_TYPE = {
  INVOICES: {
    name: "invoices",
    label: "Facturas",
    url: "invoices",
    format: "pdf",
  },
  INVOICES_DETAIL: {
    name: "invoicesDetail",
    label: "Detalle de Facturación",
    url: "invoices/detail",
    format: "csv/xlsx",
  },
  POLICIES: {
    name: "policies",
    label: "Pólizas",
    url: "policies/getPolicy",
    format: "csv/xlsx",
  },
  COMMISSIONS: {
    name: "commissions",
    label: "Comisiones",
    url: "liquidations/download",
    format: "csv/xlsx",
  },
};

interface FormatMap {
  pdf: string;
  csv: string;
  xml: string;
  [key: string]: string;
}

export const FORMATS: FormatMap = {
  pdf: "application/pdf",
  csv: "text/csv",
  xml: "application/xml",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}
