import React, { Component } from "react";
import { CampaignsConfigDetailContainer } from "./campaigns-config-detail-style";
import MainButton from "../../../components/main-button";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CampaignsConfigurationServices from "../../../services/CampaignsConfigurationServices";
import moment from "moment";
import GoBackLayout from "../../../components/go-back/go-back-layout";
import { CAMPAIGN_STATES } from "../../../constants/campaignsConfig";
import CampaignsDetailPrizeModal from "./campaigns-detail-prize-modal/campaigns-detail-prize-modal";
import MainModalCampaigns from "../../../components/main-modal-campaigns";
import { images } from "../../../assets";
interface CampaignsConfigDetailProps extends RouteComponentProps {
  match: any;
  history: any;
}

interface CampaignsConfigDetailState {
  campaign?: any;
  selectedPrize: any;
  modal: string;
}

class CampaignsConfigDetail extends Component<
  CampaignsConfigDetailProps,
  CampaignsConfigDetailState
  > {
  constructor(props: CampaignsConfigDetailProps) {
    super(props);
    this.state = {
      selectedPrize: null,
      modal: ''
    };
  }

  componentDidMount() {
    this.getCampaign();
  }

  async getCampaign() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const args: any = {
      id,
      setLoading: () => { }
    };

    const resp: any = await CampaignsConfigurationServices.getCampaign(args);
    this.setState({ campaign: resp });
  }

  async deleteCampaign() {
    const { campaign } = this.state;
    const args = {
      id: campaign.id,
      setLoading: () => { }
    };
    await CampaignsConfigurationServices.deleteCampaign(args);
    this.setState({ modal: "delete-success-modal" });
  }

  async setCampaignStatus(status: string) {
    const { campaign } = this.state;

    campaign.status = status;
    const args = {
      id: campaign.id,
      loader: true,
      setLoading: () => { },
      data: campaign
    }
    await CampaignsConfigurationServices.putCampaigns(args);
    this.setState({ modal: '' }, () => this.getCampaign());
  }


  getStatusColor() {
    const { campaign: { status } } = this.state;
    switch (status) {
      case 'revoked':
      case 'cancelled':
        return 'red-status';
      case 'definition':
      case 'planned':
      case 'in_action':
        return 'yellow-status';
      case 'in_process':
      case 'finished':
        return 'green-status';
    }
  }

  render() {
    const {
      match: {
        params: { id, editedCampaign }
      }
    } = this.props;
    const { campaign, selectedPrize, modal } = this.state;
    const isCancellable = campaign && campaign.status !== 'cancelled' &&
      campaign.status !== 'revoked' &&
      campaign.status !== 'finished' &&
      campaign.status !== 'in_process';
    const revokeSelected = campaign && (campaign.status === 'planned' ||
      campaign.status === 'definition');
    return (
      <>
        {campaign ? (
          <>
            <CampaignsConfigDetailContainer>
              <div className="config-detail-body">
                <div className="config-detail-body__back-button">
                  <GoBackLayout onclick={() => this.props.history.goBack()} />
                </div>
                <div className="config-detail-body__title">
                  {editedCampaign === false
                    ? "Nueva campaña generada"
                    : editedCampaign
                      ? "Campaña editada"
                      : "Campaña generada"}
                  <div className={`config-detail-body__title__status ${this.getStatusColor()}`}>
                    {CAMPAIGN_STATES[campaign.status]}
                  </div>
                </div>
                <div className="config-detail-cards-container">
                  <div className="config-detail-card">
                    <div className="config-detail-card__title">
                      <div className="config-detail-card__title__text">
                        {campaign.name}
                      </div>
                      <div className="config-detail-card__title__info">
                        {campaign.type === 'CONTEST' ? 'Concurso' : 'Incentivo'}
                      </div>
                    </div>
                    <div className="config-detail-card__body">
                      <div className="config-detail-card__item">
                        <div className="config-detail-card__item__title">
                          FECHA INICIO
                      </div>
                        <div className="config-detail-card__item__text">
                          {moment(campaign.start).format("DD/MM/YYYY")}
                        </div>
                      </div>
                      <div className="config-detail-card__item">
                        <div className="config-detail-card__item__title">
                          FECHA FIN
                      </div>
                        <div className="config-detail-card__item__text">
                          {moment(campaign.end).format("DD/MM/YYYY")}
                        </div>
                      </div>
                      <div className="config-detail-card__item">
                        <div className="config-detail-card__item__title">
                          FECHA MENSAJE BIENVENIDA
                      </div>
                        <div className="config-detail-card__item__text">
                          {moment(campaign.messageDate).format("DD/MM/YYYY")}
                        </div>
                      </div>
                      {campaign.inProcessStart && (
                        <div className="config-detail-card__item">
                          <div className="config-detail-card__item__title">
                            FECHA INICIO SEGUIMIENTO
                      </div>
                          <div className="config-detail-card__item__text">
                            {moment(campaign.inProcessStart).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      )}
                      {campaign.inProcessEnd && (
                        <div className="config-detail-card__item">
                          <div className="config-detail-card__item__title">
                            FECHA FIN SEGUIMIENTO
                       </div>
                          <div className="config-detail-card__item__text">
                            {moment(campaign.inProcessEnd).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      )}
                      {campaign.revokedStart && (
                        <div className="config-detail-card__item">
                          <div className="config-detail-card__item__title">
                            FECHA ANULACIÓN
                       </div>
                          <div className="config-detail-card__item__text">
                            {moment(campaign.revokedStart).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      )}
                      {campaign.cancellDate && (
                        <div className="config-detail-card__item">
                          <div className="config-detail-card__item__title">
                            FECHA CANCELACIÓN
                       </div>
                          <div className="config-detail-card__item__text">
                            {moment(campaign.cancellDate).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="config-detail-card">
                    <div className="config-detail-card__title">Bases</div>
                    <div className="config-detail-card__body">
                      {campaign.bases}
                    </div>
                  </div>
                  <div className="config-detail-card">
                    <div className="config-detail-card__title">
                      <div className="config-detail-card__title__text">
                        Objetivos y condiciones
                    </div>
                      {moment(campaign.start).isBefore(moment()) && (
                        <div className="config-detail-card__title__button">
                          <MainButton
                            type={"light"}
                            text="VER PARTICIPANTES"
                            onClick={() =>
                              this.props.history.push(
                                `/config-campañas/detail/${id}/participants`
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className="config-detail-card__container">
                      {campaign.prizes.map((prize: any, index: number) => (
                        <div className="config-detail-card__card">
                          <div className="config-detail-card__card__subtitle">
                            {campaign.prizes.length === 1 ? ('Premio') : (`Premio ${index + 1}`)}
                          </div>
                          <div className="config-detail-card__card__line" />
                          <div className="config-detail-card__card__title">
                            {prize.name}
                          </div>
                          <div className="config-detail-card__card__detail" onClick={() => this.setState({ selectedPrize: prize })}>
                            VER DETALLE
                      </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="config-detail-side-bar">
                {campaign.status === 'definition' && (
                  <div className="config-detail-side-bar__button">
                    <MainButton text="PUBLICAR" onClick={() => this.setState({ modal: 'publish-modal' })} />
                  </div>
                )}
                {isCancellable && (
                  <div className="config-detail-side-bar__button">
                    <MainButton text={revokeSelected ? 'ANULAR' : 'CANCELAR'} type="red" onClick={() => this.setState({ modal: 'delete-modal' })} />
                  </div>
                )}
              </div>
            </CampaignsConfigDetailContainer>
            {selectedPrize && (
              <CampaignsDetailPrizeModal
                campaign={campaign}
                prize={selectedPrize}
                close={() => this.setState({ selectedPrize: null })}
              />
            )}
            {modal === 'publish-modal' && (
              <MainModalCampaigns
                mainTitle={'¿Quieres publicar la campaña?'}
                mainText={'Una vez publicada no podrás editar los datos de la campaña.'}
                buttonText={'Publicar'}
                close={() => this.setState({ modal: "" }, () => this.getCampaign())}
                active={true}
                onClickAccept={() => this.setCampaignStatus('planned')}
                centered={true}
                buttonClassName={'green'}
                img={images.IconCheckGreen}
              />
            )}
            {modal === "delete-modal" && (
              <MainModalCampaigns
                mainTitle={revokeSelected ? "Anular campaña" : "Cancelar campaña"}
                mainText={
                  `¿Estás seguro de que quieres ${revokeSelected ? "anular" : "cancelar"} esta campaña? Recuerda que no podrás recuperarla más tarde y tendrás que volver a crearla si es necesario.`
                }
                buttonText={revokeSelected ? "Anular" : "Cancelar"}
                close={() => this.setState({ modal: "" })}
                active={true}
                onClickAccept={() => this.deleteCampaign()}
                centered={true}
                buttonClassName={"red"}
                img={images.IconWarningRed}
              />
            )}
            {modal === "delete-success-modal" && (
              <MainModalCampaigns
                mainTitle={`¡Oops!\nHas ${revokeSelected ? "anulado" : "cancelado"} una campaña`}
                close={() =>
                  this.setState({ modal: "" }, () => {
                    this.getCampaign();
                  })
                }
                active={true}
                onClickAccept={() => { }}
                centered={true}
                buttonClassName={"red"}
                img={images.IconWarningRed}
              />
            )}
          </>
        ) : (
            <></>
          )}
      </>
    );
  }
}

export default CampaignsConfigDetail;
