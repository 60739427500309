import React from 'react';
import GuideLineLayout from './guide-line-layout';

export default class GuideLineScreen extends React.Component<any, {}> {
    render(){
        const { steps, isClickable, currentStep } = this.props;
        return(
            <GuideLineLayout steps={steps} isClickable={isClickable} currentStep={currentStep}/>
        )
    }
}