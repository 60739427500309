import ArrowSelectDownBlue from "../images/arrow-select-down-blue.svg";
import BellBlueIcon from "../images/blue-bell-icon.svg";
import BellLightBlueIcon from "../images/light-blue-bell-icon.svg";
import BellWhiteIcon from "../images/white-bell-icon.svg";
import BigLoginBottomWave from "../images/big-login-bottom-wave.svg";
import BigLoginRightWave from "../images/big-login-right-wave.svg";
import CloseSessionBlueIcon from "../images/close-session-blue-icon.svg";
import ChevronDown from "../images/chevron-down.svg";
import DarkBlueWave from "../images/dark-blue-wave.svg";
import Electro from "../images/electro.svg";
import EyeClose from "../images/eye-close.svg";
import IconHouseBlue from "../images/house-blue.svg";
import GoBackArrow from "../images/go-back-arrow.svg";
import GoogleIcon from "../images/google-icon.svg";
import Heart from "../images/heart.svg";
import HeroImage from "../images/hero-image.png";
import newHeroImage from "../images/newHomeHero.png";
import IconAlertWhite from "../images/icon-alert-white.svg";
import IconAlertRed from "../images/icon-alert-red.svg";
import IconDoubleFill from "../images/icon-double-fill.svg";
import IconArrowDownBlue from "../images/icon-arrow-down-blue.svg";
import IconArrowDownGrey from "../images/icon-arrow-down-grey.svg";
import IconArrowDownLightBlue from "../images/icon-arrow-down-light-blue.svg";
import IconArrowDownRed from "../images/icon-arrow-down-red.svg";
import IconArrowLeftBlue from "../images/icon-arrow-left-blue.svg";
import IconArrowLeftGrey from "../images/icon-arrow-left-grey.svg";
import IconArrowLeftLightBlue from "../images/icon-arrow-left-light-blue.svg";
import IconArrowRightBlue from "../images/icon-arrow-right-blue.svg";
import IconArrowUpLightBlue from "../images/icon-arrow-up-light-blue.svg";
import IconArrowWhite from "../images/icon-arrow-white.svg";
import IconFilterTable from "../images/icon-filterTable-grey.svg";
import IconAsisaBlue from "../images/icon-asisa-blue.svg";
import IconAsisaBlueOutline from "../images/icon-asisa-blue-outline.svg";
import IconCalendarBlue from "../images/calendar.svg";
import IconCalendarGrey from "../images/icon-calendar-grey.svg";
import IconCalendarLightBlue from "../images/calendar.svg";
import iconCampaignsWhite from "../images/icon-campaigns-white.svg";
import IconCancelRed from "../images/icon-cancel-red.svg";
import IconCheckboxBlue from "../images/icon-checkbox-blue.svg";
import IconCheckBlue from "../images/check_blue.svg";
import IconCheckGreen from "../images/icon-check-green.svg";
import IconCheckGreen2 from "../images/check_green.svg";
import IconCheckWhite from "../images/icon-check-white.svg";
import IconClientsWhite from "../images/icon-clients-white.svg";
import IconClientsBlue from "../images/icon-clients-blue.svg";
import IconCookies from "../images/cookies.svg";
import IconPdf from "../images/icon-pdf.svg";
import IconClipGrey from "../images/icon-clip-grey.svg";
import IconClipWhite from "../images/icon-clip-white.svg";
import IconClipLightBlue from "../images/icon-clip-light-blue.svg";
import IconClockBlue from "../images/icon-clock-blue.svg";
import IconClockGrey from "../images/icon-clock-grey.svg";
import IconClockWhite from "../images/icon-clock-white.svg";
import IconPendingBlue from "../images/icon-pending-blue.svg";
import IconCloseBlue from "../images/icon-close-blue.svg";
import IconCloseGrey from "../images/icon-close-grey.svg";
import IconCloseWhite from "../images/icon-close-white.svg";
import IconContactCollapsedWhite from "../images/icon-contact-collapsed-white.svg";
import IconCrossGreen from "../images/icon-cross-green.svg";
import IconDeleteBlue from "../images/icon-delete-blue.svg";
import IconDeleteWhite from "../images/icon-delete-white.svg";
import IconDetailLightBlue from "../images/icon-detail-light-blue.svg";
import IconDownloadBlue from "../images/icon-download-blue.svg";
import IconDownloadGreen from "../images/icon-download-green.svg";
import IconDownloadLightBlue from "../images/icon-download-light-blue.svg";
import IconDownloadWhite from "../images/icon-download-white.svg";
import IconDownloadWhite2 from "../images/download_white.svg";
import IconDownloadBlue2 from "../images/download_blue.svg";
import IconEditLightBlue from "../images/icon-edit-light-blue.svg";
import IconEditBlueClear from "../images/icon-edit-blue-clear.svg";
import IconDeleteLightBlue from "../images/icon-delete-light-blue.svg";
import IconMask from "../images/icon-mask.svg";
import IconEditBlue from "../images/icon-edit-blue.svg";
import IconEraseBlue from "../images/icon-erase-blue.svg";
import IconEraseRed from "../images/icon-erase-red.svg";
import IconEyeBlue from "../images/icon-eye-blue.svg";
import IconEyeWhite from "../images/icon-eye-white.svg";
import IconEyeBlueFill from "../images/icon-eye-blue-fill.svg";
import IconEyeWhiteFill from "../images/icon-eye-white-fill.svg";
import IconEyeDark from "../images/icon-eye-dark.png";
import IconEyeLight from "../images/icon-eye-light.png";
import IconCheckTableBlue from "../images/icon-ckeck-table-blue.svg";
import IconHireBlue from "../images/icon-hire-blue.svg";
import IconHiringWhite from "../images/icon-hiring-white.svg";
import IconHiringFullWhite from "../images/icon-hiring-full-white.svg";
import IconInfoBlue from "../images/icon-info-blue.svg";
import IconInfoGrey from "../images/icon-info-grey.svg";
import IconLegalCollapsedWhite from "../images/icon-legal-collapsed-white.svg";
import IconMailBlue from "../images/icon-mail-blue.svg";
import IconMessageWhite from "../images/icon-message-white.svg";
import IconOkGreen from "../images/icon-ok-green.svg";
import IconPencilWhite from "../images/icon-pencil-white.svg";
import IconPhoneBlue from "../images/icon-phone-blue.svg";
import IconPrintWhite from "../images/icon-print-white.svg";
import IconRefresh from "../images/button-refresh.svg";
import IconRefresh2 from "../images/button-refresh-2.svg";
import IconSaveBlue from "../images/icon-save-blue.svg";
import IconStarGreen from "../images/icon-star-green.svg";
import IconStarBlue from "../images/icon-star-blue.svg";
import IconSaveWhite from "../images/icon-save-white.svg";
import IconSearchBlue from "../images/icon-search-blue.svg";
import IconSearchGray from "../images/search-gray.svg";
import IconSearchWhite from "../images/search-white.svg";
import IconSimulationWhite from "../images/icon-simulation-white.svg";
import IconHomeWhite from "../images/icon-home-white.svg"
import IconTickWhite from "../images/icon-tick-white.svg";
import IconTrashWhite from "../images/icon-trash-white.svg";
import IconUploadBlue from "../images/upload_blue.svg";
import IconUploadWhite from "../images/upload_white.svg";
import IconUploadGrey from "../images/upload_grey.svg";
import IconUserBlue from "../images/icon-user-blue.svg";
import IconWarningYellow from "../images/icon-warning-yellow.svg";
import IconWarningBlue from "../images/warning-blue.svg";
import IconWifiBlue from "../images/icon-wifi-blue.svg";
import IconWarningRed from "../images/icon-warning-red.svg";
import LightBlueWave from "../images/light-blue-wave.svg";
import LogoAsisaBlue from "../images/logo-asisa-blue.svg";
import LogoAsisaCollapsedWhite from "../images/logo-asisa-collapsed-white.svg";
import LogoAsisaWhite from "../images/logo-asisa-white.svg";
import Medal from "../images/medal.svg";
import IconPrizeBlue from "../images/icon-prize-blue.svg";
import MiniWavePoliciePurple from "../images/miniwave-policie-purple.svg";
import MiniWavePolicieRed from "../images/miniwave-policie-red.svg";
import MiniWavePolicieYellow from "../images/miniwave-policie-yellow.svg";
import MiniWavePolicieBlue from "../images/miniwave-policie-blue.svg";
import MiniWavePolicieSalud from "../images/miniwave-policie-salud.svg";
import MiniWavePolicieTravel from "../images/miniwave-policie-travel.svg";
import MiniWavePolicieDental from "../images/miniwave-policie-dental.svg";
import MiniWavePoliciePymes from "../images/miniwave-policie-pymes.svg";
import NotFoundImg from "../images/not-found-img.svg";
import PlusBlue from "../images/plus-blue.svg";
import PlusWhite from "../images/plus-white.svg";
import Sun from "../images/sun.svg";
import Tooth from "../images/tooth.svg";
import IconFileExcel from "../images/icon-file-excel.svg";
import IconFileLightBlue from "../images/icon-file-light-blue.svg";
import WaveNoContent from "../images/wave-no-content.svg";
import WavePoliciePurple from "../images/wave-policie-purple.svg";
import WavePolicieRed from "../images/wave-policie-red.svg";
import WavePolicieYellow from "../images/wave-policie-yellow.svg";
import WavePolicieBlue from "../images/wave-policie-blue.svg";
import WavePolicieDental from "../images/wave-policie-dental.svg";
import WaveCampaignsCard from "../images/wave-campaigns-card.svg";
import iconCampaignsBlue from "../images/icon-campaigns-blue.svg";
import BlueBulletPoint from "../images/blue-bullet-point.svg";

import NotFoundMiddle from "../images/not-found-middle.svg";
import NotFoundTop from "../images/not-found-top.svg";
import WarningError from "../images/warning-icon.svg";

import WavePolicieSalud from "../images/wave-policie-salud.svg";
import WavePolicieTravel from "../images/wave-policie-travel.svg";
import WavePoliciePymes from "../images/wave-policie-pymes.svg";
import Required from "../images/required.svg";
import RequiredDisabled from "../images/requiredDisabled.svg";

import IconoComisiones from "./icono_comisiones.png";
import IconoFacturacion from "./icono_facturacion.png";
import IconoFacturas from "./icono_facturas.png";
import IconoPoliza from "./icono_poliza.png";


export const images = {
  ArrowSelectDownBlue: ArrowSelectDownBlue,
  BellBlueIcon: BellBlueIcon,
  BellLightBlueIcon: BellLightBlueIcon,
  BellWhiteIcon: BellWhiteIcon,
  BigLoginBottomWave: BigLoginBottomWave,
  BigLoginRightWave: BigLoginRightWave,
  CloseSessionBlueIcon: CloseSessionBlueIcon,
  DarkBlueWave: DarkBlueWave,
  Electro: Electro,
  EyeClose: EyeClose,
  IconHouseBlue: IconHouseBlue,
  GoBackArrow: GoBackArrow,
  GoogleIcon: GoogleIcon,
  Heart: Heart,
  iconCampaignsBlue: iconCampaignsBlue,
  HeroImage: HeroImage,
  newHeroImage,
  IconPdf: IconPdf,
  IconFileExcel: IconFileExcel,
  IconFileLightBlue: IconFileLightBlue,
  IconCheckTableBlue: IconCheckTableBlue,
  IconPrizeBlue: IconPrizeBlue,
  IconAlertWhite: IconAlertWhite,
  IconAlertRed,
  IconArrowDownBlue: IconArrowDownBlue,
  IconArrowDownGrey: IconArrowDownGrey,
  IconArrowDownLightBlue: IconArrowDownLightBlue,
  IconArrowDownRed: IconArrowDownRed,
  IconArrowLeftBlue: IconArrowLeftBlue,
  IconArrowLeftGrey: IconArrowLeftGrey,
  IconArrowLeftLightBlue: IconArrowLeftLightBlue,
  IconArrowRightBlue: IconArrowRightBlue,
  IconArrowUpLightBlue: IconArrowUpLightBlue,
  IconArrowWhite: IconArrowWhite,
  IconFilterTable: IconFilterTable,
  IconAsisaBlue: IconAsisaBlue,
  IconAsisaBlueOutline: IconAsisaBlueOutline,
  IconCalendarBlue: IconCalendarBlue,
  IconCalendarGrey: IconCalendarGrey,
  IconCalendarLightBlue: IconCalendarLightBlue,
  iconCampaignsWhite: iconCampaignsWhite,
  IconCancelRed: IconCancelRed,
  IconCheckboxBlue: IconCheckboxBlue,
  IconCheckBlue: IconCheckBlue,
  IconCheckGreen: IconCheckGreen,
  IconCheckGreen2: IconCheckGreen2,
  IconCheckWhite: IconCheckWhite,
  IconClientsWhite: IconClientsWhite,
  IconClientsBlue: IconClientsBlue,
  IconCookies: IconCookies,
  IconClipGrey: IconClipGrey,
  IconClipWhite: IconClipWhite,
  IconClipLightBlue: IconClipLightBlue,
  IconClockBlue: IconClockBlue,
  IconClockGrey: IconClockGrey,
  IconClockWhite: IconClockWhite,
  IconPendingBlue: IconPendingBlue,
  IconCloseBlue: IconCloseBlue,
  IconCloseGrey: IconCloseGrey,
  IconCloseWhite: IconCloseWhite,
  IconContactCollapsedWhite: IconContactCollapsedWhite,
  IconCrossGreen: IconCrossGreen,
  IconDeleteBlue: IconDeleteBlue,
  IconDeleteWhite: IconDeleteWhite,
  IconDetailLightBlue: IconDetailLightBlue,
  IconDownloadGreen: IconDownloadGreen,
  IconDownloadBlue: IconDownloadBlue,
  IconDownloadBlue2: IconDownloadBlue2,
  IconDownloadLightBlue: IconDownloadLightBlue,
  IconDownloadWhite: IconDownloadWhite,
  IconDownloadWhite2: IconDownloadWhite2,
  IconEditBlue: IconEditBlue,
  IconEraseBlue: IconEraseBlue,
  IconEraseRed: IconEraseRed,
  IconEyeBlue: IconEyeBlue,
  IconEyewhite: IconEyeWhite,
  IconEyeBlueFill: IconEyeBlueFill,
  IconEyeWhiteFill: IconEyeWhiteFill,
  IconEyeDark: IconEyeDark,
  IconEyeLight: IconEyeLight,
  IconHireBlue: IconHireBlue,
  IconHiringWhite: IconHiringWhite,
  IconHiringFullWhite: IconHiringFullWhite,
  IconInfoBlue: IconInfoBlue,
  IconInfoGrey: IconInfoGrey,
  IconLegalCollapsedWhite: IconLegalCollapsedWhite,
  IconMailBlue: IconMailBlue,
  IconMessageWhite: IconMessageWhite,
  IconOkGreen: IconOkGreen,
  IconPencilWhite: IconPencilWhite,
  IconPhoneBlue: IconPhoneBlue,
  IconPrintWhite: IconPrintWhite,
  IconRefresh: IconRefresh,
  IconRefresh2: IconRefresh2,
  IconSaveBlue: IconSaveBlue,
  IconSaveWhite: IconSaveWhite,
  IconSearchBlue: IconSearchBlue,
  IconSearchGray: IconSearchGray,
  IconSearchWhite: IconSearchWhite,
  IconSimulationWhite: IconSimulationWhite,
  IconHomeWhite: IconHomeWhite,
  IconTickWhite: IconTickWhite,
  IconTrashWhite: IconTrashWhite,
  IconUploadBlue: IconUploadBlue,
  IconUploadWhite: IconUploadWhite,
  IconUserBlue: IconUserBlue,
  IconWarningYellow: IconWarningYellow,
  IconWarningBlue: IconWarningBlue,
  IconWarningRed: IconWarningRed,
  IconWifiBlue: IconWifiBlue,
  IconEditLightBlue: IconEditLightBlue,
  IconEditBlueClear: IconEditBlueClear,
  IconDeleteLightBlue: IconDeleteLightBlue,
  LightBlueWave: LightBlueWave,
  LogoAsisaBlue: LogoAsisaBlue,
  LogoAsisaCollapsedWhite: LogoAsisaCollapsedWhite,
  LogoAsisaWhite: LogoAsisaWhite,
  Medal: Medal,
  IconStarGreen: IconStarGreen,
  IconStarBlue,
  MiniWavePoliciePurple: MiniWavePoliciePurple,
  MiniWavePolicieRed: MiniWavePolicieRed,
  MiniWavePolicieYellow: MiniWavePolicieYellow,
  MiniWavePolicieBlue: MiniWavePolicieBlue,
  MiniWavePolicieSalud: MiniWavePolicieSalud,
  MiniWavePolicieTravel: MiniWavePolicieTravel,
  MiniWavePolicieDental: MiniWavePolicieDental,
  MiniWavePoliciePymes: MiniWavePoliciePymes,
  NotFoundImg: NotFoundImg,
  PlusBlue: PlusBlue,
  PlusWhite: PlusWhite,
  Sun: Sun,
  Tooth: Tooth,
  WaveNoContent: WaveNoContent,
  WavePoliciePurple: WavePoliciePurple,
  WavePolicieSalud: WavePolicieSalud,
  WavePolicieTravel: WavePolicieTravel,
  WavePolicieRed: WavePolicieRed,
  WavePolicieYellow: WavePolicieYellow,
  WavePolicieBlue: WavePolicieBlue,
  WavePoliciePymes: WavePoliciePymes,
  WaveCampaignsCard: WaveCampaignsCard,
  NotFoundMiddle: NotFoundMiddle,
  NotFoundTop: NotFoundTop,
  WarningError: WarningError,
  IconMask: IconMask,
  IconDoubleFill: IconDoubleFill,
  IconUploadGrey: IconUploadGrey,
  BlueBulletPoint: BlueBulletPoint,
	ChevronDown: ChevronDown,
  Required: Required,
  RequiredDisabled: RequiredDisabled,
  WavePolicieDental:WavePolicieDental,
  IconoComisiones,
  IconoFacturacion,
  IconoFacturas,
  IconoPoliza,
};
