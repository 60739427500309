import styled from "styled-components";
import { colors } from "../../assets";
import { MainButtonContainer } from "../main-button/main-button-style";

export const InfoModalContainer = styled.div`
  display: none;
  color: ${colors["PR-001-100"]};
  position: fixed;
  z-index: 9999;
  background: ${colors["background-modal"]};
  left: 0;
  width: 100%;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &.active {
    display: flex;
  }

  &.error {
    color: ${colors["ER-001-100"]};

    .left-info-modal-icon-bg {
      background: rgba(255, 217, 204, 0.3);
    }

    ${MainButtonContainer} {
      background: ${colors["ER-001-100"]};
    }

    .ghost {
      border: none !important;
      color: #ffffff !important;
    }
  }

  &.success {
    /* .left-info-modal-icon-bg {
      background: rgba(64, 202, 169, 0.3);
    } */
    /* color: ${colors["SU-001-100"]}; */

    ${MainButtonContainer} {
      background: ${colors["SU-001-100"]};
    }
    .ghost {
      color: ${colors["SU-001-100"]} !important;
      border-color: ${colors["SU-001-100"]} !important;
      background: #fff !important;
    }
  }

  .info-modal-container {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 22px 22px 40px;
    max-width: 620px;
    max-height: 90vh;
    overflow: hidden;
  }

  .info-modal-close {
    text-align: right;

    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .info-modal-icon {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info-modal-icon-bg {
    display: inline-block;
    background: ${colors["PR-001-30"]};
    width: 62px;
    height: 62px;
    background: #b3cadc4d;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 20px;
    }
  }

  .info-modal-text {
    margin-bottom: 42px;
    padding: 0 100px;
    text-align: center;
  }

  .info-modal-button {
    display: flex;
    justify-content: center;
    > div:first-child {
      margin-right: 20px;
    }
    div {
      width: 140px;
    }
  }
  .extra-text{
    white-space:pre-wrap;
    font-family: "museo sans";
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    overflow: auto;
    height: 260px;
  }
`;
