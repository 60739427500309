import React, { Component } from 'react';
import { FaqsContainer } from './faqs-iframe-style';
import InfoText from '../components-text/info-text';
import Menu02Text from '../components-text/menu-02-text';
import LoaderSpinner from '../loader-spinner';

interface FaqsIframeProps {
    url: string;
}

interface FaqsIframeStage {
    loaded: boolean;
}


class FaqsIframe extends Component<FaqsIframeProps, FaqsIframeStage> {

    constructor(props: FaqsIframeProps){
        super(props);

        this.state = {
            loaded : true
        }
    
    };

    hideSpinner = () => {
        this.setState({
            loaded: false
        });
      };



    render() {
        const { url } = this.props;

        return (
            <FaqsContainer>
                <div className="container">
                    <div className='content'>
                {this.state.loaded ? (
                    <LoaderSpinner />
                    ) : null}
                    <iframe 

          
                    width="100%" 
                    height="100%" 
                    scrolling="auto"
                        src={url}
                        onLoad={this.hideSpinner}
                        />
                    </div> 
                </div>
            </FaqsContainer>
        )
    }
}

export default FaqsIframe