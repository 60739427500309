import React, { Component } from "react";
import { InputBoxContainer } from "./input-box-style";
import { images } from "../../assets/images";
import TooltipBox from "../tooltip-box/tooltip-box";
import NoteText from "../components-text/note-text";
import LabelText from "../components-text/label-text";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import { colors } from "../../assets";
import Cleave from "cleave.js/react";
import { LabelTooltip } from "../form";

interface InputBoxProps {
  className?: string;
  disabled?: boolean;
  icon?: string;
  iconFocus?: string;
  iconDisabled?: string;
  errorCode?: string;
  extraErrorData?: string;
  type?:
  | "text"
  | "number"
  | "email"
  | "NIF"
  | "NIE"
  | "PAS"
  | "letters"
  | "iban"
  | "numbersletter"
  | "currency";
  required?: boolean;
  filledValue?: string;
  value: string | null;
  labelText?: string;
  onChange?: (value: string, error?: boolean) => void;
  withTooltip?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;
  placeholder?: string;
  message?: string;
  area?: boolean;
  areaRows?: number;
  maxLength?: number;
  onKeyDown?: any;
  onBlur?: (value: string, error?: boolean) => void;
  cleanValue?: boolean;
  notScroll?: boolean;
  notUsePlacehoderAsLabel?: boolean;
  tooltipIconTop?: string;
  tooltipIconLeft?: string;
}

interface InputBoxState {
  prevInputValue: string;
  focus: boolean;
  showTooltip: boolean;
  error: string;
  inputValue: string;
  showError: boolean;
}

class InputBox extends Component<InputBoxProps, {}> {
  state: InputBoxState = {
    prevInputValue: "",
    focus: false,
    showTooltip: false,
    showError: false,
    error: "",
    inputValue: "",
  };

  componentDidMount = () => {
    const { value, type, notScroll, errorCode, required } = this.props;
    if (!notScroll)
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 100);
    if (value && value !== "") {
      this.setState({
        inputValue:
          type === "currency" ? this.formatMoney(parseFloat(value)) : value,
      });
    }
    if (required && errorCode) {
      this.setState({ showError: true });
    }
  };

  componentDidUpdate(prevProps: InputBoxProps) {
    const { filledValue, value, errorCode, type, cleanValue } = this.props;
    const { inputValue, prevInputValue } = this.state;
    if (errorCode && errorCode !== prevProps.errorCode) {
      this.setState({ showError: true });
    } else if (
      filledValue &&
      filledValue !== "" &&
      filledValue !== inputValue
    ) {
      this.setState({ inputValue: filledValue });
    } else if (value !== "" && inputValue === "" && prevInputValue === "") {
      this.setState({ inputValue: value });
    } else if (prevProps.value !== value && value === "") {
      this.setState({ inputValue: "" });
    } else if (value && value !== prevProps.value) {
      this.setState({
        inputValue:
          type === "currency" ? this.formatMoney(parseFloat(value)) : value,
      });
    } else if (cleanValue && value !== inputValue) {
      this.setState({ inputValue: value });
    }
  }

  formatMoney(amount: number) {
    const formattedMoney = amount
      .toString()
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      .replace(".", "+")
      .split(",")
      .join(".")
      .replace("+", ",");
    return formattedMoney;
  }

  handleBlur(value: string) {
    const { onChange, type, onBlur } = this.props;
    if (value && value !== value.trim()) {
      onChange && onChange(value.trim());
      this.setState({ inputValue: value.trim(), prevInputValue: value })
    }
    this.setState({ focus: false });
    if (type && type === "currency") {
      value = value.split(".").join("").replace(",", ".");
    }
    // onChange && onChange(value);
    onBlur && onBlur(value);
  }

  handleChange(value: string) {
    const { type, onChange } = this.props;
    const { inputValue } = this.state;
    const letters = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]*$/i;
    const numbers = /^[0-9]*$/i;
    const numbersletter = /^[0-9a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[0-9a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[0-9\u00f1\u00d1a-zA-ZÀ-ÿ\u00f1\u00d1]*$/i;

    if (type === "iban") {
      value = value.replace(/ /g, "").trim();
      value = value.slice(0, 24);
      value = value.replace(/(.{4})/g, "$1 ").trim();
      value = value.toUpperCase();
    }

    if (type === "email") {
      value = value.replace(/ /g, "");
    }

    if (type === "number" && !numbers.test(value)) {
      value = inputValue;
    }
    if (type === "numbersletter" && !numbersletter.test(value) && value !== "") {
      value = inputValue;

    }
    if (!type || type !== "letters" || letters.test(value) || value === "") {
      this.setState({
        inputValue: value,
        prevInputValue: inputValue,
        //showError: false
      });
      onChange && onChange(value);
    }
  }

  render() {
    const {
      disabled,
      className,
      icon,
      iconDisabled,
      required,
      labelText,
      type,
      errorCode,
      withTooltip,
      tooltipTitle,
      tooltipText,
      placeholder,
      message,
      area,
      areaRows,
      maxLength,
      extraErrorData,
      notUsePlacehoderAsLabel,
      tooltipIconLeft,
      tooltipIconTop,
    } = this.props;
    const { showTooltip, inputValue, focus, showError } = this.state;

    const error_text = errorCode ? ERROR_MESSAGES[errorCode] : "";

    return (
      <InputBoxContainer
        className={`${className} ${disabled ? "disabled" : ""} ${errorCode && showError ? "error" : ""
          }`}
        focus={focus}
        type={type}
      >
        {labelText && (
          <div className="input-box-topbar">
            <div className={`input-box-topbar-label ${focus ? "focus" : ""}`}>
              <LabelText color={focus ? colors["SC-001-100"] : ""}>
                <p>{labelText}</p>
              </LabelText>
            </div>
            {withTooltip && (
              <div
                className="input-box-topbar-icon"
                onClick={() =>
                  this.setState((prevState: InputBoxState) => ({
                    showTooltip: !prevState.showTooltip,
                  }))
                }
              >
                <img
                  src={disabled ? images.IconInfoGrey : images.IconInfoBlue}
                />
                <div className="input-box-tooltip">
                  <TooltipBox
                    active={showTooltip}
                    tooltipTitle={tooltipTitle}
                    tooltipText={tooltipText}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="input-box-main">
          <div className={`input-box-main-field ${required ? "required" : ""}`}>
            {area ? (
              <textarea
                rows={areaRows}
                disabled={disabled}
                value={inputValue}
                tabIndex={disabled ? -1 : 0}
                onBlur={(e) => this.handleBlur(e.target.value)}
                onChange={(e) => this.handleChange(e.target.value)}
                onFocus={(e) => this.setState({ focus: true })}
                placeholder={placeholder}
                maxLength={maxLength}
              />
            ) : type === "currency" ? (
              <div className="currency-wrapper">
                <Cleave
                  disabled={disabled}
                  tabIndex={disabled ? -1 : 0}
                  value={inputValue}
                  onBlur={(e) => this.handleBlur(e.target.value)}
                  onChange={(e) => this.handleChange(e.target.value)}
                  onFocus={(e) => this.setState({ focus: true })}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  options={{
                    numeral: true,
                    numeralDecimalMark: ",",
                    delimiter: ".",
                  }}
                />
                <div className="currency-icon">{"€"}</div>
              </div>
            ) : (
              <input
                disabled={disabled}
                tabIndex={disabled ? -1 : 0}
                type={"text"}
                value={inputValue}
                onBlur={(e) => this.handleBlur(e.target.value)}
                onChange={(e) => this.handleChange(e.target.value)}
                onFocus={(e) => this.setState({ focus: true })}
                placeholder={placeholder}
                maxLength={maxLength}
                onKeyDown={(e) =>
                  this.props.onKeyDown && this.props.onKeyDown(e)
                }
              />
            )}
            {!notUsePlacehoderAsLabel &&
              <label>{placeholder}</label>}
            {!notUsePlacehoderAsLabel && !labelText && withTooltip && (
                <LabelTooltip
                showTooltip={() => this.setState({ showTooltip: true})}
                hideTooltip={() => this.setState({ showTooltip: false })}
                tooltipTitle={tooltipTitle}
                tooltipText={tooltipText}
                isTooltipVisible={showTooltip}
                tooltipIconTop={tooltipIconTop}
                tooltipIconLeft={tooltipIconLeft}
              />
            )}
          </div>
          <div className="input-box-icon">
            <img src={disabled ? iconDisabled : icon} />
          </div>
        </div>
        {errorCode && showError && (
          <div className="input-box-error">
            <NoteText>
              <p>{`* ${error_text} ${errorCode === "login-blocked" ? extraErrorData : ""
                }`}</p>
            </NoteText>
          </div>
        )}
        {message && (
          <div className="input-box-message">
            <NoteText>
              <p>{`* ${message}`}</p>
            </NoteText>
          </div>
        )}
      </InputBoxContainer>
    );
  }
}

export default InputBox;
