import React, { Component } from "react";
import { InputCounterContainer } from "./input-counter-style";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import NoteText from "../components-text/note-text";
import { images } from "../../assets";
interface InputBoxProps {
  className?: string;
  disabled?: boolean;
  errorCode?: string;
  required?: boolean;
  value: string;
  labelText?: string;
  onChange: (value: string, error?: boolean) => void;
  placeholder?: string;
  message?: string;
  unit?: string;
  onlyNumbers?: boolean;
}

interface InputBoxState {
  prevInputValue: string;
  focus: boolean;
  showTooltip: boolean;
  error: string;
  inputValue: string;
}

interface InputBoxState {
  prevInputValue: string;
  focus: boolean;
  showTooltip: boolean;
  error: string;
  inputValue: string;
}
class InputCounter extends Component<InputBoxProps, InputBoxState> {
  state: InputBoxState = {
    prevInputValue: "",
    focus: false,
    showTooltip: false,
    error: "",
    inputValue: "",
  };

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    const { value } = this.props;
    if (value !== "") {
      this.setState({ inputValue: value });
    }
  };

  componentDidUpdate(prevProps: InputBoxProps) {
    const { value } = this.props;
    const { inputValue, prevInputValue } = this.state;

    if (value !== "" && inputValue === "" && prevInputValue === "") {
      this.setState({ inputValue: value });
    } else if (prevProps.value !== value && value === "") {
      this.setState({ inputValue: "" });
    } else if (value && value !== prevProps.value) {
      this.setState({ inputValue: value });
    }
  }

  handleBlur(value: string) {
    const { onChange } = this.props;
    onChange(value);
  }

  handleChange(value: string) {
    const { inputValue } = this.state;
    this.handleBlur(value);
    this.setState({ inputValue: value, prevInputValue: inputValue });
  }
  processInput(e: any) {
    const value = e.target.value;
    if (this.props.onlyNumbers) {
      let validNumber = new RegExp(/^[0-9]+$|^$|^\s$/);
      if (validNumber.test(value) && (value === '' || parseInt(value) <= 999)) {
        this.handleChange(value)
      }
    } else {
      if ((parseFloat(value) > 0 && parseFloat(value) <= 999.99) || value === "") {
        this.handleChange(value)
      }

    }
  }
  render() {
    const {
      disabled,
      className,
      required,
      labelText,
      value,
      errorCode,
      message,
      unit,
      onlyNumbers
    } = this.props;

    const { inputValue } = this.state;
    const error_text = errorCode ? ERROR_MESSAGES[errorCode] : "";

    return (
      <InputCounterContainer
        className={`${className} ${disabled ? "disabled" : ""} ${errorCode ? "error" : ""
          } ${required ? "required" : ""}`}
      >
        {labelText && (
          <div className="counter-field-topbar">
            {labelText}
          </div>
        )}
        <div className="counter-field-main">
          <div className={`counter-input-field ${required ? "unitable" : ""}`}>
            <input
              disabled={disabled}
              type={onlyNumbers ? "text" : "number"}
              value={inputValue}
              onBlur={(e) => this.handleBlur(e.target.value)}
              onChange={(e) => this.processInput(e)
              }
            />
            {unit && <div className={"counter-field-unit"}>
              {unit && unit}
            </div>}
          </div>
          <div className="counter-button-container">
            <div
              className="counter-button counter-button-radius-top"
              onClick={() => disabled ? true : 
                this.handleChange(
                  (inputValue && parseFloat(inputValue) < 999.99
                    ? parseFloat(inputValue) + 1
                    : 1
                  ).toString()
                )
              }
            >
              <img src={images.IconArrowWhite} />
            </div>
            <div
              className="counter-button invert counter-button-radius-bottom"
              onClick={() => disabled ? true :
                this.handleChange(
                  (inputValue && parseFloat(inputValue) > 0
                    ? parseFloat(inputValue) - 1
                    : 0
                  ).toString()
                )
              }
            >
              <img src={images.IconArrowWhite} />
            </div>
          </div>
          {errorCode && (
            <div className="input-counter-error">
              <NoteText>
                <p>{`* ${error_text}`}</p>
              </NoteText>
            </div>
          )}
        </div>
      </InputCounterContainer>
    );
  }
}

export default InputCounter;
