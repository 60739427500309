import React from "react";
import { BreadcrumbTextContainer } from "./breadcrumb-text-style";

interface BreadcrumbTextProps {
    children: any;
    className?: string;
    color?: string;
}

const BreadcrumbText = ({children = '', className='', color=''}:BreadcrumbTextProps) => {
        return (
        <BreadcrumbTextContainer color={color} className={className}>
            {children}
        </BreadcrumbTextContainer>
    )
} 
export default BreadcrumbText;