import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../assets";
import { ErrorModalContainer } from "../../../../components/error-modal/error-modal-style";
import { GipHireMenuHelper } from "../../../../components/gip-menu/gip-hire-menu";
import GuideLine from "../../../../components/guide-line";
import HealthPriceItem from "../../../../components/health-price-item/health-price-item";
import MainButton from "../../../../components/main-button";
import GipServices from "../../../../services/GipServices";
import { PriceDataContainer } from "./gip-price-data-style"
import { HealthPricingSimulationPaymentOptionDto, HealthPricingSimulationProductResultDto } from "../../../../services/dto/response/GipSimulationResponse.dto"
import { withPolicy } from "../../../../context/mixin/with-policy";
import { DEFAULT_PAY_METHOD } from "../../../../constants/payTypes";

interface PriceProps extends RouteComponentProps {
  setLoading: Function;
  match: any;
  data: any;
  policy: any;
}

interface priceTypeModel {
  paymentTime: string;
  payType: string;
  price: string;
  discount: string;
  taxes: string;
  total: string;
}


interface PriceState {
  selectablePrices: { [id: string]: priceTypeModel };
  selectedPrice: {
    discount: string;
    paymentTime: string;
    payType: string,
    price: string;
    taxes: string;
    total: string;

  };
  selectedKey: string;
  modalErrorShow: boolean;
  modalErrorText: any;
  simulationOutput: HealthPricingSimulationProductResultDto[];
  responseBody: any,
}

class GipPriceDataLayout extends React.Component<PriceProps, PriceState> {

  constructor(props: PriceProps) {
    super(props);

    this.state = {
      selectablePrices: {},
      selectedPrice: props.data.selectedPrice
      ? props.data.selectedPrice
      : {
        paymentTime: DEFAULT_PAY_METHOD.description,
        payType: DEFAULT_PAY_METHOD.code,
        price: '0.00',
        discount: '0.00',
        taxes: '0.00',
        total: '0.00'
      },
      selectedKey: props.data.selectedPrice && props.data.selectedPrice.paymentTime 
        ? props.data.selectedPrice.paymentTime 
        : DEFAULT_PAY_METHOD.description,
      modalErrorShow: false,
      modalErrorText: '',
      simulationOutput: [],
      responseBody: {}
    };
  }



  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    this.loadData();
  };

  loadData = async () => {
    var prices: { [id: string]: priceTypeModel } = {};
    try {
      var dataReply = await this.GipSimulation();
      if (dataReply.result === 0) {
        this.setState({responseBody: dataReply});
        if (dataReply.responseContent && dataReply.responseContent.salida) {
          if (typeof (dataReply.responseContent.salida) !== "string" && dataReply.responseContent.salida.ListaProductosResultado.length === 1) {
            this.setState({ simulationOutput: dataReply.responseContent.salida.ListaProductosResultado })
            var priceList = dataReply.responseContent.salida.ListaProductosResultado
            priceList.forEach(priceSet => {
              priceSet.ListaTarifasFormaPagoResultado.forEach(priceOption => prices[priceOption.DesFormaPago] = {
                paymentTime: priceOption.DesFormaPago,
                discount: "0",
                payType: this.getPayType(priceOption.CodFormaPago.toString()),
                price: priceOption.TotalPrimaNeta.toString(),
                taxes: "0",
                total: priceOption.TotalPrimaNeta.toString()
              })
            })
          }
        }
      }else if (dataReply.result > 0){
        this.setState({ modalErrorShow: true, modalErrorText: dataReply.reason})
        return
      }
    }
    catch {
      const { history } = this.props;
      history.replace("");
    }

    if (prices[DEFAULT_PAY_METHOD.description] && !this.props.data.selectedPrice)
      this.setState({ selectedPrice: prices[DEFAULT_PAY_METHOD.description]})

    this.setState({ selectablePrices: prices });

  }

  toValidate = async () => {

    let { 
          responseBody, 
          selectedPrice, 
          simulationOutput, 
        } = this.state;

    const { policy } = this.props

    let paymentInsured: HealthPricingSimulationPaymentOptionDto[][] = [];
    let paymentInsuredSelected: HealthPricingSimulationPaymentOptionDto[] = [];
    
    simulationOutput.forEach(priceSet => {
      priceSet.ListaTarifasFormaPagoResultado.forEach(priceOption => {
        if (priceOption.DesFormaPago === selectedPrice.paymentTime) {
          this.props.policy.setGipSimulationData('priceDataSelected', priceOption);
        }
      })
      priceSet.ListaTarifasPorAsegurado.forEach(insured => {
        paymentInsured.push(insured.ListaTarifas);
        insured.ListaTarifas.forEach(priceOption => {
          if (priceOption.DesFormaPago === selectedPrice.paymentTime) {
            paymentInsuredSelected.push(priceOption);
          }
        })
      })
    })


      this.props.policy.setGipSimulationData('priceData', simulationOutput[0].ListaTarifasFormaPagoResultado);
      this.props.policy.setGipSimulationData('priceDataInsured', paymentInsured);
      this.props.policy.setGipSimulationData('priceDataInsuredSelected', paymentInsuredSelected);
      this.props.policy.setGipSimulationData('simulationResponse', responseBody);
      this.props.policy.setGipSimulationData('selectedPrice', selectedPrice);
    

    this.props.history.push(
      GipHireMenuHelper.getSimulationStepNavigationPath(
        GipHireMenuHelper.getSimulationStepFromNavigationRoute(
          this.props.match.url,
          policy.product.products_internalName
        ) + 1,
        policy.product.products_internalName
      )
    )
  }

  goBack() {
    const { policy } = this.props

    this.props.history.push(
      GipHireMenuHelper.getSimulationStepNavigationPath(
        GipHireMenuHelper.getSimulationStepFromNavigationRoute(
          this.props.match.url,
          policy.product.products_internalName
        ) - 1,
        policy.product.products_internalName
      )
    )
  }

  hideErrorModal = () => this.setState({ modalErrorShow: false, modalErrorText: '' });

  handlePriceRadioChange(paymentTime: string, payType: string, price: string, discount: string, taxes: string, total: string) {
    const value = {
      discount,
      paymentTime,
      payType,
      price,
      taxes,
      total
    }
    this.setState({ selectedPrice: value });
    this.setState({ selectedKey: paymentTime });
  }

  getPayType = (payTypeEnum : string) => {
    const { policy } = this.props
    const payType = policy.productPayTypes.find((payType:any) => payType.order === payTypeEnum);
    return payType ? payType.value : payTypeEnum;
  }
  
  getDateForGip(dateString: string) {
    try {
      const [day, month, year] = dateString.split('/');
      const date = new Date(year + "-" + month + "-" + day).toISOString();
      return date;
    }
    catch (err) {
      throw err;
    }
  }

  async GipSimulation() {
    let data = this.props.data;
    let insuredList: { birthdate: string; isInsuredClient: number; }[] = [];
    data.insuredData.forEach((insured: { birthDate: string; }) => { insuredList.push({ "birthdate": this.getDateForGip(insured.birthDate), "isInsuredClient": 0 }) })
    let request = {
      startDate: this.getDateForGip(data.mediator.startDateValue),
      colective: data.policyData.colective,
      combiProd: data.policyData.combiproduct,
      insuredList: insuredList,
      province: parseInt(data.mediator.postalCodeValue.substring(0, 2))
    }

    const response = await GipServices.getGipSimulation({
      loader: true,
      setLoading: this.props.setLoading,
      data: request,
    })
      .then((response) => response)
      .catch(error => { throw error });

    return response;
  }

  render() {

    const { selectablePrices, selectedKey, selectedPrice } = this.state;

    return (
      <PriceDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">

            <h2>{'DATOS DEL SEGURO'}</h2>

          </div>
          <div className="data-flow-status-guide">
            <GuideLine
              steps={[0, 0, 0, 0]}
              currentStep={3}
            />
          </div>
        </div>

        <div className="content-wrapper bottomMargin20" ref={''}>
          <div className="personal-data-wrapper">
            <div className="data-title">
              <h5>CALCULO DE PRIMA ANUAL SEGÚN FORMA DE PAGO</h5>
            </div>
          </div>
          <div className="colSin-100">

            {Object.keys(selectablePrices).map((key, index) => (
              <HealthPriceItem
                paymentTime={key}
                price={selectablePrices[key].price}
                defaultChecked={selectablePrices[key].paymentTime === selectedKey}
                discount={selectablePrices[key].discount}
                onClick={
                  (
                    time: string,
                    price: string,
                    discount: string
                  ) => this.handlePriceRadioChange(
                    time,
                    selectablePrices[key].payType,
                    price,
                    discount,
                    selectablePrices[key].taxes,
                    selectablePrices[key].total)
                } />
            ))}

          </div>
        </div>


        <div className="colSin-100">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.goBack()}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                text="Siguiente"
                disabled = {!selectedPrice.price}
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>

        <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={this.hideErrorModal} />
            </div>
          </div>
        </ErrorModalContainer>

      </PriceDataContainer>
    );
  }
}

export default withPolicy(withRouter(GipPriceDataLayout));
