import React, { useState } from "react";
import { PersonalInformationContainer } from "../../../../hirings/new-hiring/health/forms/personal-information/personal-information-style";
import SelectBox from "../../../../../components/select-box/select-box";
import VariableData from "../variable-data/variable-data";
import { PersonalBasicDataProps } from "./personal-basic-data.types";
import { deepGet } from "../../../../../utils/utility";
import dateFns from "date-fns";
import { defaultCheck } from "../../basic-data/utils";

const PersonalBasicData: React.FC<PersonalBasicDataProps> = ({
  step,
  formData,
  personsList,
  handleMultipleChanges,
  typeForm
}) => {
  const { values, handleChange } = formData;
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  const onSelectInsurance = (value: string) => {
    handleChange("nameSelected", value);
    if (personsList && personsList[Number(value)]) {
      const birthDate: string =
        deepGet(personsList, `${Number(value)}.birthDate`, "") || "";
      const formattedBirthDate = dateFns.format(birthDate, "DD/MM/YYYY", {
        locale: "es",
      });
      handleMultipleChanges &&
        handleMultipleChanges({
          docNumber: deepGet(personsList, `${Number(value)}.nif`, ""),
          birthDate:
            formattedBirthDate !== "Invalid Date"
              ? formattedBirthDate
              : birthDate.substring(0, 10),
          gender: deepGet(personsList, `${Number(value)}.gender`, ""),
          name: deepGet(personsList, `${Number(value)}.name`, ""),
          breed: deepGet(personsList, `${Number(value)}.breed`, ""),
          numChip: deepGet(personsList, `${Number(value)}.numChip`, ""),
          petType: deepGet(personsList, `${Number(value)}.petType`, ""),
        });
    }
    if (value === "0") {
      handleChange("type", "TOMADOR", "");
    } else {
      if (personsList) {
        const person = personsList.find((person) => person.value === value);
        if (person && person.label) {
          handleChange("type", person.label.split(" - ")[0] || "", "");
        }
      }
    }
    if (!isFirstTime) {
      handleChange("check", defaultCheck);
    } else {
      setIsFirstTime(false);
    }
  };

  return (
    <PersonalInformationContainer>
      <div className="message margin-top-25">
        <p>
          {" "}
          Seleccione las opciones de modificación de datos que desea incluir en
          el suplemento{" "}
        </p>
      </div>
      <div className="content-wrapper">
        <div className="personal-data-wrapper">
          <div className="personal-data-fisica">
            <div className="personal-data-row">
              <div className="personal-data-item col-100">
                <div className="selectBox">
                  <SelectBox
                    required={true}
                    errorCode={""}
                    optionsText={personsList || []}
                    optionKey={"value"}
                    optionValue={"label"}
                    initialValue={values.nameSelected}
                    defaultValue={values.nameSelected}
                    disabled={false}
                    onChange={(value: string) => onSelectInsurance(value)}
                  />
                  <label>{"Interviniente en la póliza"}</label>
                </div>
              </div>
            </div>
            <VariableData
              typeForm={typeForm}
              formData={formData}
              step={step}
            />
          </div>
        </div>
      </div>
    </PersonalInformationContainer>
  );
};

export default PersonalBasicData;
