import React from "react";
import { withRouter } from "react-router-dom";
import { ClientsReceiptsContainer } from "./clients-receipts-style";
import GoBack from "../../../components/go-back";
import MainTable from "../../../components/main-table";
import Pagination from "../../../components/pagination";
import SelectBox from "../../../components/select-box/select-box";
import ReceiptsServices from "../../../services/ReceiptsServices";
import { withGeneral } from "../../../context/mixin/with-general";
import Body02Text from "../../../components/components-text/body-02-text";
import MainButton from "../../../components/main-button";
import FilterInput from "../../../components/filter-input/filter-input-layout";
import { isValidCif, isValidDocumentID } from "../../../utils/validation";
import { ChooseHiringModalContainer } from "../../../components/choose-hiring-modal/choose-hiring-modal-style";
import H4Text from "../../../components/components-text/h4-text";
import XLSX from 'xlsx';
import { formatter, downloadFile, convertBase64ToFile } from "../../../utils/utility";
import { ErrorModalContainer } from "../../../components/error-modal/error-modal-style";
import { receiptColumns, RECEIPT_SEARCH_OPTION } from "../../../constants/clientsReceipts";
import { ERROR_MESSAGES } from "../../../constants/errorMessages";
import { RECEIPT_STATE_PAY } from "../../../constants/filter";
import { IClientsReceiptsProps, IClientsReceiptsState, ISearchReceiptDataRow } from "./dto/clients-receipts-layout-dto";
import NoteText from "../../../components/components-text/note-text";
import { ISearchReceiptDataDto } from "../../../services/ReceiptsServices-dto";
import { getRelatedMediatorsCodes } from "../../../utils/mediatorCode";

class ClientsReceiptsLayout extends React.Component<IClientsReceiptsProps, IClientsReceiptsState> {
  constructor(props: IClientsReceiptsProps) {
    super(props);

    const mediator = props.mediator ? props.mediator : localStorage.loggedMediator ? JSON.parse(localStorage.loggedMediator) : "";

    this.state = {
      searchTypeFilter: {
        search: props.location.state ? props.location.state.searchText : '',
        state: props.location.state ? props.location.state.searchState : '',
        validity: props.location.state ? true : false,
        type: props.location.state ? props.location.state.searchType : '',
      },
      identificationNumberInput: this.getDefaultReceiptSearchInput(),
      policyNumberInput: this.getDefaultReceiptSearchInput(),
      receiptNumberInput: this.getDefaultReceiptSearchInput(),
      mediatorCodesInput: {
        codeOptions: getRelatedMediatorsCodes(mediator),
        disabled: props.location.state && props.location.state.searchType ? false : true,
        showError: false,
        errorText: '',
        selectedCode: props.location.state && props.location.state.searchCode ? props.location.state.searchCode : props.mediator.mediatorCode,
      },
      receipts: [],
      receiptsFilter: [],
      years: [],
      page: props.location.state ? props.location.state.page : 1,
      totalPages: 0,
      showItems: 10,
      order: props.location.state ? props.location.state.order : 'fec_cobertur_desde',
      ascendent: props.location.state ? props.location.state.ascendent : false,
      stateDisabled: props.location.state ? (props.location.state.searchType !== RECEIPT_SEARCH_OPTION.RECIBO ? false : true) : true,
      serverError: false,
      showModal: false,
      download: '',
      modalError: false,
      modalErrorMessage: '',
      noDataMessage: '',
      userEmpty: false,
    };
  }

  componentDidMount() {
    if (this.props.history.location.state) {
      this.props.mediator ? this.getRows(true) : this.setState({ userEmpty: true });
    }
  }

  getDefaultReceiptSearchInput = () => ({ showError: false, errorText: '' })



  getRows = async (newSearch: boolean) => {
    const { mediatorCodesInput, searchTypeFilter, showItems, page, order, ascendent } = this.state;

    const { state } = this.props.location;

    const data = newSearch ?
      {
        codMediador: mediatorCodesInput.selectedCode,
        searchType: searchTypeFilter.type,
        data: searchTypeFilter.search,
        estadoRecibo: searchTypeFilter.state === 'Todos' || searchTypeFilter.type === RECEIPT_SEARCH_OPTION.RECIBO ? '' :
          searchTypeFilter.state
      } :

      {
        codMediador: state && state.searchCode ? state.searchCode : mediatorCodesInput.selectedCode,
        searchType: state && state.searchType ? state.searchType : searchTypeFilter.type,
        data: state && state.searchText ? state.searchText : searchTypeFilter.search,
        estadoRecibo: state ? state.searchState : searchTypeFilter.state === 'Todos' || searchTypeFilter.type === RECEIPT_SEARCH_OPTION.RECIBO ? '' :
          searchTypeFilter.state
      };

    const params = [
      `skip=${showItems * (newSearch ? 0 : page - 1)}`,
      `take=${showItems}`,
      order ? `order=${ascendent ? '' : '-'}${order}` : ''
    ];

    const resReceipts = await ReceiptsServices.searchReceipts(
      this.props.setLoading,
      true,
      params,
      data
    ).catch(() => {
      this.setState({ serverError: true, modalErrorMessage: ERROR_MESSAGES["server-error"] });
      throw ERROR_MESSAGES["server-error"];
    });

    const rows: ISearchReceiptDataRow[] = [];
    if (resReceipts !== undefined && resReceipts.count !== 0) {
      resReceipts.receiptList.forEach((receipt: ISearchReceiptDataDto) => {
        const row: ISearchReceiptDataRow = {
          numero_recibo: this.setInputDataRow(receipt.numero_recibo, "", "responsive", receipt.numero_recibo),
          cliente: this.setInputDataRow(receipt.colectivo, "", "responsive", receipt.numero_recibo),
          grupo_negocio: this.setInputDataRow(receipt.grupo_negocio, "", "responsive", receipt.numero_recibo),
          poliza: this.setInputDataRow(receipt.poliza, "", "responsive", receipt.numero_recibo),
          cod_mediador: this.setInputDataRow(data.codMediador, "", "responsive", receipt.numero_recibo),
          nom_tomador: this.setInputDataRow(receipt.nom_tomador, "", "responsive", receipt.numero_recibo),
          fec_cobertur_desde: this.setInputDataRow(receipt.fec_cobertur_desde ? new Date(receipt.fec_cobertur_desde).toLocaleDateString() : "", "", "responsive", receipt.numero_recibo),
          estado_recibo: this.setInputDataRow(receipt.estado_recibo, "", "responsive", receipt.numero_recibo),
          imp_importe_total: this.setInputDataRow(receipt.imp_importe_total > 0 ? formatter.format(Number(receipt.imp_importe_total)) : '', "", "responsive", receipt.numero_recibo),
        }
        rows.push(row);
      });

      const pages = Math.ceil(resReceipts.count / showItems);
      this.setState({ receipts: rows, receiptsFilter: rows, totalPages: pages, page, noDataMessage: '' });
      this.saveLastSearch();
    }
    else {
      if (this.state.serverError !== true) {
        this.setState({ receipts: [], page: 1, totalPages: 0, receiptsFilter: [], noDataMessage: ERROR_MESSAGES["no-data"] });
      }
    }
  }

  setInputDataRow = (value: string, type: string, key: string, idLink: string,) => ({ text: value, type: type, key: key, idLink: idLink });

  saveLastSearch() {
    this.props.history.replace({
      state: {
        searchType: this.state.searchTypeFilter.type,
        searchText: this.state.searchTypeFilter.search,
        searchCode: this.state.mediatorCodesInput.selectedCode,
        searchState: this.state.searchTypeFilter.state,
        page: this.state.page,
        order: this.state.order,
        ascendent: this.state.ascendent,
      }
    });
  }

  handleRadioChange(value: string) {
    let { identificationNumberInput, policyNumberInput, receiptNumberInput, stateDisabled } = this.state;
    switch (value) {
      case 'Tomador':
        identificationNumberInput.showError = false;
        identificationNumberInput.errorText = '';
        stateDisabled = false;
        break;

      case 'Poliza':
        policyNumberInput.showError = policyNumberInput.errorText.length > 0;
        identificationNumberInput.showError = false;
        stateDisabled = false;
        break;

      case 'Recibo':
        receiptNumberInput.showError = receiptNumberInput.errorText.length > 0;
        identificationNumberInput.showError = false;
        stateDisabled = true;
        break;

      default:
        break;
    }

    this.setState({
      searchTypeFilter: {
        type: value,
        search: '',
        validity: false,
        state: '',
      }, identificationNumberInput, policyNumberInput, receiptNumberInput, stateDisabled
    });
  }

  handleInputChange(value: string, key: string = "") {
    const { identificationNumberInput, receiptNumberInput, policyNumberInput } = this.state;
    let { searchTypeFilter } = this.state;

    searchTypeFilter.search = value;

    switch (key) {
      case "Tomador":
        if (value.length > 0) {
          identificationNumberInput.showError = false;
          identificationNumberInput.errorText = '';
          searchTypeFilter.validity = value.length > 0;
        }
        else {
          identificationNumberInput.showError = false;
          identificationNumberInput.errorText = '';
          searchTypeFilter.validity = false;
        }
        break;
      case "Poliza":
        searchTypeFilter.validity = (searchTypeFilter.type === RECEIPT_SEARCH_OPTION.POLIZA && value.length >= 5);
        break;
      case "Recibo":
        searchTypeFilter.validity = (searchTypeFilter.type === RECEIPT_SEARCH_OPTION.RECIBO && value.length > 0);
        break;
      default:
        break;
    }

    this.setState({ searchTypeFilter, identificationNumberInput, receiptNumberInput, policyNumberInput });
  }

  handleSelectChange(value: string) {
    let { searchTypeFilter } = this.state;
    searchTypeFilter.state = value;
    this.setState({ searchTypeFilter });
  }

  handleSelectedCodesChange = (selectedCode: string) => {
    const { mediatorCodesInput } = this.state;
    mediatorCodesInput.selectedCode = selectedCode;
    this.setState({ mediatorCodesInput });
  };

  handleOrder = (column: string, ascendent: boolean | undefined) => {
    this.setState({ order: column, ascendent }, () => this.getRows(false))
  }

  setPage = (page: number) => {
    this.setState({ page }, () => this.getRows(false));
  }

  async downloadReceipts() {
    const { order, ascendent, download, mediatorCodesInput, searchTypeFilter } = this.state;
    const { state } = this.props.location;

    const data = {
      codMediador: state && state.searchCode ? state.searchCode : mediatorCodesInput.selectedCode,
      searchType: state && state.searchType ? state.searchType : searchTypeFilter.type,
      data: state && state.searchText ? state.searchText : searchTypeFilter.search,
      estadoRecibo: state ? state.searchState : searchTypeFilter.state === 'Todos' || searchTypeFilter.type === RECEIPT_SEARCH_OPTION.RECIBO ? '' :
        searchTypeFilter.state
    }

    const queryParams: Array<string> = [
      `fileType=${download}`,
      order ? `order=${ascendent ? '' : '-'}${order}` : ''
    ];

    const receiptsData = await ReceiptsServices.downloadReceipts(
      this.props.setLoading,
      true,
      queryParams,
      data,
    ).catch(() => {
      this.setState({ modalError: true, modalErrorMessage: ERROR_MESSAGES["unknow-error"] });
    });

    switch (download) {
      case 'excel':
        if (receiptsData !== undefined) {
          let worksheet = XLSX.read(JSON.parse(receiptsData.workbook).data, { type: "buffer" });

          XLSX.writeFile(worksheet, data.data + "_" + new Date().toLocaleDateString() + '.xlsx');
          this.setState({ download: '', modalError: false, modalErrorMessage: '', showModal: false });
        }
        break;
      case 'csv':
        if (receiptsData !== undefined) {
          const blob = new Blob([receiptsData.workbook]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");

          link.href = url;
          link.download = data.data + "_" + new Date().toLocaleDateString() + '.csv';
          link.click();
          this.setState({ download: '', modalError: false, modalErrorMessage: '', showModal: false });
        }
        break;
      default:
        this.setState({ modalError: true, modalErrorMessage: ERROR_MESSAGES["no-type-selected"] });
        break;
    }
  }

  async getPendingReceipts() {
    const { setLoading, mediator } = this.props;
    let serverError = false;

    const fileRaw = await ReceiptsServices.getPendingReceipts(
      setLoading,
      true,
      mediator.mediatorCode,
    ).catch(() => {
      serverError = true;
      this.setState({ serverError: true, modalError: true, modalErrorMessage: ERROR_MESSAGES["unknow-error"] });
    });

    if (fileRaw !== undefined && fileRaw.fileData !== undefined) {
      let file = convertBase64ToFile(fileRaw.fileData, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;");
      downloadFile(file, fileRaw.fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;", true);
    }
    else {
      if (!serverError)
        this.setState({ serverError: true, modalError: true, modalErrorMessage: ERROR_MESSAGES["file-not-found"] });
    }
  }

  render() {
    const {
      receipts,
      totalPages,
      page,
      identificationNumberInput,
      policyNumberInput,
      receiptNumberInput,
      stateDisabled,
      serverError,
      showModal,
      noDataMessage,
      searchTypeFilter,
      modalErrorMessage,
      userEmpty,
      mediatorCodesInput,
      order,
      ascendent } = this.state;

    if (userEmpty && this.props.mediator) {
      this.getRows(true);
      this.setState({ userEmpty: false });
    }

    return (
      <ClientsReceiptsContainer>
        <div className="back-arrow-container">
          <GoBack text="Volver" />
        </div>

        <div className="main-title-container-complex">
          <div className="main-title-container-complex-item col-2">
            <h2>RECIBOS</h2>
          </div>
          <div className="col-3">
            <MainButton
              text={'Descargar Recibos Pendientes'}
              onClick={() => this.getPendingReceipts()}
            />
          </div>
        </div>

        <div className="main-filter-container">
          <h4>Búsqueda</h4>
          <div className="receip-radio-main-row">
            <div className="radioBox">
              <label>
                <input
                  className="receipt-option"
                  type="radio"
                  name="recibo"
                  value={RECEIPT_SEARCH_OPTION.TOMADOR}
                  onChange={() => this.handleRadioChange(RECEIPT_SEARCH_OPTION.TOMADOR)}
                  defaultChecked={searchTypeFilter.type === RECEIPT_SEARCH_OPTION.TOMADOR}
                />
                <span>Tomador</span>
              </label>
            </div>
            <div className="radioBox">
              <label>
                <input
                  className="receipt-option"
                  type="radio"
                  name="recibo"
                  value={RECEIPT_SEARCH_OPTION.POLIZA}
                  onChange={() => this.handleRadioChange(RECEIPT_SEARCH_OPTION.POLIZA)}
                  defaultChecked={searchTypeFilter.type === RECEIPT_SEARCH_OPTION.POLIZA}
                />
                <span>PÓLIZA</span>
              </label>
            </div>
            <div className="radioBox">
              <label>
                <input
                  className="receipt-option"
                  type="radio"
                  name="recibo"
                  value="numRecibo"
                  onChange={() => this.handleRadioChange(RECEIPT_SEARCH_OPTION.RECIBO)}
                  defaultChecked={searchTypeFilter.type === RECEIPT_SEARCH_OPTION.RECIBO}
                />
                <span>Nº Recibo</span>
              </label>
            </div>
          </div>
          <div className="main-filter-second-row">
            <div className="col-2 main-filter-item">
              <div className="inputBox">
                <FilterInput
                  placeholder={'NIF/CIF/NIE/PASAPORTE'}
                  onChange={(value: string) => this.handleInputChange(value, "Tomador")}
                  onEnter={() => searchTypeFilter.validity ? this.getRows(true) : ''}
                  showError={identificationNumberInput.showError}
                  errorText={identificationNumberInput.errorText}
                  required={searchTypeFilter.type === RECEIPT_SEARCH_OPTION.TOMADOR}
                  disabled={searchTypeFilter.type !== RECEIPT_SEARCH_OPTION.TOMADOR}
                  initValue={searchTypeFilter.type === RECEIPT_SEARCH_OPTION.TOMADOR ? searchTypeFilter.search : ''}
                  cleanValueDisabled={searchTypeFilter.type !== RECEIPT_SEARCH_OPTION.TOMADOR}
                  maxLength={9}
                />
              </div>
            </div>

            <div className="col-2 main-filter-item">
              <div className="inputBox">
                <FilterInput
                  placeholder={'Póliza'}
                  onChange={(value: string) => this.handleInputChange(value, "Poliza")}
                  onEnter={() => searchTypeFilter.validity ? this.getRows(true) : ''}
                  showError={policyNumberInput.showError}
                  errorText={policyNumberInput.errorText}
                  required={searchTypeFilter.type === RECEIPT_SEARCH_OPTION.POLIZA}
                  disabled={searchTypeFilter.type !== RECEIPT_SEARCH_OPTION.POLIZA}
                  initValue={searchTypeFilter.type === RECEIPT_SEARCH_OPTION.POLIZA ? searchTypeFilter.search : ''}
                  cleanValueDisabled={searchTypeFilter.type !== RECEIPT_SEARCH_OPTION.POLIZA}
                />
              </div>
            </div>

            <div className="col-2 main-filter-item">
              <div className="inputBox">
                <FilterInput
                  placeholder={'Nº recibo'}
                  onChange={(value: string) => this.handleInputChange(value, "Recibo")}
                  onEnter={() => searchTypeFilter.validity ? this.getRows(true) : ''}
                  showError={receiptNumberInput.showError}
                  errorText={receiptNumberInput.errorText}
                  required={searchTypeFilter.type === RECEIPT_SEARCH_OPTION.RECIBO}
                  disabled={searchTypeFilter.type !== RECEIPT_SEARCH_OPTION.RECIBO}
                  initValue={searchTypeFilter.type === RECEIPT_SEARCH_OPTION.RECIBO ? searchTypeFilter.search : ''}
                  cleanValueDisabled={searchTypeFilter.type !== RECEIPT_SEARCH_OPTION.RECIBO}
                />
              </div>
            </div>

            <div className={`main-filter-item--select first col-2 ${stateDisabled ? "disabled" : ""}`}>
              <div className="selectBox">
                <SelectBox
                  optionsText={RECEIPT_STATE_PAY}
                  optionKey={"value"}
                  optionValue={"label"}
                  defaultValue={searchTypeFilter.state}
                  onChange={(value: string) => this.handleSelectChange(value)}
                  propValue={searchTypeFilter.state}
                  disabled={stateDisabled}
                />
                <label>Tipo de búsqueda</label>
              </div>
            </div>

            <div className={`first col-4 ${stateDisabled ? "disabled" : ""}`}>
              <div className="selectBox relatedMediators">
                <SelectBox
                  // mediatorSelector={true}
                  isMediator={true}
                  optionsText={mediatorCodesInput.codeOptions}
                  optionKey={"value"}
                  optionValue={"label"}
                  defaultValue={mediatorCodesInput.selectedCode}
                  onChange={(value: string) => this.handleSelectedCodesChange(value)}
                  propValue={mediatorCodesInput.selectedCode}
                  required={true}
                  disabled={searchTypeFilter.type ? false : true}
                />
                <label>Mediador</label>
              </div>
            </div>
          </div>

          <div className="main-filter-third-row">
            <div className="main-filter-item--select col-2 topMargin15">
              <MainButton
                text={'Buscar'}
                disabled={!searchTypeFilter.validity}
                onClick={() => this.setState({ order: 'fec_cobertur_desde', ascendent: false }, () => this.getRows(true))}
              />
            </div>

            <div className="main-filter-item--select col-2 topMargin15">
              <MainButton
                className="download-button"
                text={'Exportar'}
                disabled={totalPages === 0}
                onClick={() => this.setState({ showModal: true })}
              />
            </div>
          </div>
        </div>

        {totalPages === 0 ? (
          <Body02Text>{noDataMessage}</Body02Text>
        ) : (
          <>
            <div className="client-receipts-table">
              <MainTable
                className={"table interactive"}
                columns={receiptColumns}
                rowLink={`/recibos-clientes/detalle/${mediatorCodesInput.selectedCode}`}
                rowKeyLink={"recibo"}
                rows={receipts}
                keysRow={["numero_recibo", "cliente", "grupo_negocio", "poliza", "cod_mediador", "nom_tomador", "fec_cobertur_desde", "estado_recibo", "imp_importe_total"]}
                onOrder={(column: string, ascendent: boolean | undefined) => this.handleOrder(column, ascendent)}
                defaultOrder={{ [order]: ascendent }}
                keysAlignRight={["imp_importe_total"]}
              />
            </div>

            <ChooseHiringModalContainer
              className={`${showModal && `active`}`}
              active={showModal}>
              <div className="recover-modal">
                <div className="recover-modal-main">
                  <div className="recover-modal-main-title">
                    <div className="recover-modal-text">
                      <H4Text>Selecciona formato de exportación</H4Text>
                    </div>
                  </div>

                  <div className="recover-modal-main-container">
                    <div className="recover-modal-main-row">
                      <div className="recover-modal-main-row-title">
                        <div className="radioBox">
                          <label>
                            <input
                              type="radio"
                              name="download"
                              value="excel"
                              onChange={() => this.setState({ download: 'excel', modalErrorMessage: '' })}
                              checked={this.state.download === 'excel'}
                            />
                            <span> Excel</span>
                          </label>
                        </div>
                      </div>
                      <div className="recover-modal-main-row-title">
                        <div className="radioBox">
                          <label>
                            <input
                              type="radio"
                              name="download"
                              value="csv"
                              onChange={() => this.setState({ download: 'csv', modalErrorMessage: '' })}
                              checked={this.state.download === 'csv'}
                            />
                            <span> Csv</span>
                          </label>
                        </div>
                      </div>

                      <div className="download-filter-error">
                        <NoteText>
                          <p>{modalErrorMessage}</p>
                        </NoteText>
                      </div>

                    </div>
                  </div>

                  <div className="recover-modal-button">
                    <MainButton text="Aceptar" onClick={() => this.downloadReceipts()} />
                    <MainButton text="Cancelar" onClick={() => this.setState({ showModal: false, modalErrorMessage: '', download: '' })} type={'ghost'} />
                  </div>

                </div>
              </div>
            </ChooseHiringModalContainer>

            <div className="client-receipt-pagination">
              <Pagination page={page} limit={totalPages} callback={(page) => this.setPage(page)} />
            </div>
          </>
        )}

        <ErrorModalContainer
          className={`${serverError && `active`}`}
          active={serverError}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{modalErrorMessage}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={() => this.setState({ serverError: false, modalErrorMessage: "" })} />
            </div>

          </div>
        </ErrorModalContainer>

      </ClientsReceiptsContainer >
    );
  }
}

export default withGeneral(withRouter(ClientsReceiptsLayout));
