import React, { Component } from "react";
import { DicotomicButtonContainer } from "./dicotomic-button-style";
import MainButton from "../main-button";
import LabelText from "../components-text/label-text";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import NoteText from "../components-text/note-text";

interface DicotomicButtonProps {
  labelText?: string;
  errorCode?: string;
  options: Array<{
    code: any;
    label: string;
  }>;
  onChange: Function;
  initialValue?: string | boolean;
  disabled?: boolean;
  withTab?: boolean;
  required?: boolean;
  notScroll?: boolean;
}

interface DicotomicButtonState {
  value: string | boolean;
}

class DicotomicButton extends Component<
  DicotomicButtonProps,
  DicotomicButtonState
  > {
  option0Ref: any;
  option1Ref: any;
  state = {
    value: ""
  };

  constructor(props: DicotomicButtonProps) {
    super(props);
    this.option0Ref = React.createRef();
    this.option1Ref = React.createRef();
  }

  componentDidMount = () => {
    const { onChange, initialValue, withTab, options, disabled, notScroll } = this.props;
    if (!notScroll) {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 100);
    }
    if (initialValue && initialValue !== "") {
      this.setState({ value: initialValue }, () => {
        onChange(initialValue);
      });
    }
    withTab && !disabled && this.option0Ref.current.addEventListener("keyup", (e: any) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        this.handleOnChange(options[0].code);
      }
    });
    withTab && !disabled && this.option1Ref.current.addEventListener("keyup", (e: any) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        this.handleOnChange(options[1].code);
      }
    });
  };

  componentDidUpdate() {
    const { value } = this.state;
    const { initialValue } = this.props;
    if (initialValue && initialValue !== value) {
      this.setState({ value: initialValue });
    }
  }


  handleOnChange(optionText: string) {
    const { onChange, disabled } = this.props;
    if (!disabled) {
      this.setState({ value: optionText }, () => {
        onChange(optionText);
      });
    }
  }

  render() {
    const { labelText, options, errorCode, disabled, initialValue, withTab, required } = this.props;

    const error_text = errorCode ? ERROR_MESSAGES[errorCode] : "";

    return (
      <DicotomicButtonContainer disabled={disabled}>
        <div className={`dicotomic-button-label ${required ? 'required' : ''}`}>
          <LabelText>
            <p>{labelText}</p>
          </LabelText>
        </div>
        <div className="dicotomic-container">
          <label
            className={`dicotomic-item ${
              initialValue === options[0].code ? "active" : ""
              }`}
            tabIndex={withTab && !disabled ? 0 : undefined}
            ref={this.option0Ref}
          >
            <MainButton
              text={options[0].label}
              onClick={() => {
                this.handleOnChange(options[0].code);
              }}
            />
          </label>
          <label
            className={`dicotomic-item ${
              initialValue === options[1].code ? "active" : ""
              }`}
            tabIndex={withTab && !disabled ? 0 : undefined}
            ref={this.option1Ref}
          >
            <MainButton
              text={options[1].label}
              onClick={() => {
                this.handleOnChange(options[1].code);
              }}
            />
          </label>
        </div>
        {errorCode && (
          <div className="input-box-error">
            <NoteText>
              <p>{`* ${error_text}`}</p>
            </NoteText>
          </div>
        )}
      </DicotomicButtonContainer>
    );
  }
}

export default DicotomicButton;
