import React from "react";
import { SimulationResultContainer } from "./result-style";
import { RouteComponentProps, withRouter } from "react-router-dom";
import WebText from "../../../../components/components-text/web-text";
import MainTable from "../../../../components/main-table";
import { NewSimulationContainer } from "../new-simulation-style";
import HeadsTitleText from "../../../../components/components-text/heads-title-text";
import GuideLine from "../../../../components/guide-line";
import Title03Text from "../../../../components/components-text/title-03-text";
import HeadText from "../../../../components/components-text/head-text";
import NoteText from "../../../../components/components-text/note-text";
import MainButton from "../../../../components/main-button";
import { images } from "../../../../assets";
import { InflowSidebarContainer } from "../../../../components/inflow-sidebar/inflow-sidebar-style";
import LeftInfoModal from "../../../../components/left-info-modal";
import { withGeneral } from "../../../../context/mixin/with-general";
import { withPolicy } from "../../../../context/mixin/with-policy";
import { currencyFormat } from "../../../../utils";
import { getDayMonthYear, addMonths } from "../../../../utils/time";
import SimulationServices from "../../../../services/SimulationServices";
import moment from "moment";
import { countries } from "../../../../constants/countries";
import { downloadFile, formatter } from "../../../../utils/utility";
import { isValidEmail } from "../../../../utils/validation";
import { IMediator } from "../../../../models/mediator-model";
import { IUser } from "../../../../models/user-model";
import { withDynamicProducts } from "../../../../context/mixin/with-dynamic-products";
import { AREA_NAMES, setColorSpan } from "../../../../constants/policyType";
import DicotomicCheckbox from "../../../../components/dicotomic-checkbox/dicotomic-checkbox";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import PayTypesServices from "../../../../services/PayTypesServices";
import { withPayTypes } from "../../../../context/mixin/with-pay-types";

interface SimulationResultLayoutProps extends RouteComponentProps {
  setLoading: Function;
  dataForm: any;
  coverages: any;
  data: any;
  policy: any;
  responseSimulation: any;
  selectedMediatorCode: string;
  mediator: IMediator;
  user: IUser;
  checkProductArea: Function;
  allPayTypes: any;
}

interface SimulationResultLayoutState {
  changeGuideLine?: any;
  setGuideLine?: any;
  showLeftInfoModal: boolean;
  simulationSaved: boolean;
  leftInfoModalImg: string;
  leftInfoModalClassName: string;
  leftInfoModalTitle: string;
  leftInfoModalText: string;
  leftInfoModalButtonText: string;
  leftInfoModalClick: Function;
  takerInfoRows: any[];
  takerInfoColumns: any[];
  insuredMembersInfoRows: any[];
  insuredMembersInfoColumns: any[];
  paymentTableColumns: any[];
  paymentTableRows: any[];
  riskInfoColumns: any[];
  riskInfoRows: any[];
  mandatoryGuaranteeTableColumns: any[];
  mandatoryGuaranteeTableRows: any[];
  optionalGuaranteeTableColumns: any[];
  optionalGuaranteeTableRows: any[];
  showEmailModal: boolean;
  takerEmail: any;
  leftInfoModalCloseButtonText: string;
  simulationId: number
}

class SimulationResultLayout extends React.Component<
  SimulationResultLayoutProps,
  SimulationResultLayoutState
> {
  constructor(props: SimulationResultLayoutProps) {
    super(props);
    if (Object.keys(props.dataForm.taker).length === 0) {
      props.history.push("/simulacion/nueva");
    }

    this.state = {
      showLeftInfoModal: false,
      simulationSaved: false,
      leftInfoModalImg: "",
      leftInfoModalClassName: "",
      leftInfoModalTitle: "",
      leftInfoModalText: "",
      leftInfoModalButtonText: "",
      leftInfoModalClick: () => { },
      takerInfoRows: this.getTakerInfoRows(),
      takerInfoColumns: this.getTakerInfoColumns(),
      insuredMembersInfoRows: this.getInsuredMembersInfoRows(),
      insuredMembersInfoColumns: this.getInsuredMembersInfoColumns(),
      paymentTableColumns: this.getPaymentTableColumns(),
      paymentTableRows: this.getPaymentTableRows(),
      riskInfoColumns: this.getRiskInfoColumns(),
      riskInfoRows: this.getRiskInfoRows(),
      mandatoryGuaranteeTableColumns: this.getMandatoryGuaranteeTableColumns(),
      mandatoryGuaranteeTableRows: this.getMandatoryGuaranteeTableRows(),
      optionalGuaranteeTableColumns: this.getOptionalGuaranteeTableColumns(),
      optionalGuaranteeTableRows: this.getOptionalGuaranteeTableRows(),
      leftInfoModalCloseButtonText: "",
      showEmailModal: false,
      takerEmail: {
        value: "",
        errorCode: "",
        labelText: "Correo electrónico",
      },
      simulationId: -1
    };
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  };

  hireSimulation() {
    const { dataForm, data, policy, checkProductArea } = this.props;
    const { simulationId } = this.state;

    const newDataForm = JSON.parse(JSON.stringify(dataForm));

    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)
    const isRequiredEmail = isDeathArea ? false : true;
    try {
      if (newDataForm.taker) {
        newDataForm.taker.date.iban.required = true;
        newDataForm.taker.date.payType.required = true;
        newDataForm.taker.address.street.required = true;
        newDataForm.taker.address.streetNumber.required = true;
        newDataForm.taker.address.province.required = true;
        newDataForm.taker.address.city.required = true;
        newDataForm.taker.address.postalCode.required = true;
        newDataForm.taker.contact.birthdate.required = true;
        newDataForm.taker.contact.sex.required = true;
        newDataForm.taker.contact.docType.required = true;
        newDataForm.taker.contact.docNumber.required = true;
        newDataForm.taker.info.email.required = true;
      }

      if (newDataForm.insureds) {
        newDataForm.insureds.forEach((insured: any) => {
          insured.contact.sex.required = true;
          insured.address.street.required = true;
          insured.address.streetNumber.required = true;
          insured.address.province.required = true;
          insured.address.city.required = true;
          insured.address.postalCode.required = true;
          insured.contact.birthdate.required = true;
          insured.contact.name.required = true;
          insured.contact.lastname1.required = true;
          insured.contact.docType.required = true;
          insured.contact.docNumber.required = true;
          insured.info.email.required = isRequiredEmail;
        });
      }
    } catch (err) { }

    const productHiringLink = policy.product.products_link
    const pathname = productHiringLink && productHiringLink.split('?')[0] ? productHiringLink.split('?')[0] : undefined
    const params = productHiringLink && productHiringLink.split('?')[1] ? productHiringLink.split('?')[1] : undefined

    this.setState({
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconHireBlue,
      leftInfoModalClassName: "",
      leftInfoModalTitle: "Proceso de contratación",
      leftInfoModalText:
        "¿Ya has visto el resultado de la simulación y estás interesado en contratar esta póliza?",
      leftInfoModalButtonText: "Empezar",
      leftInfoModalClick: () =>
        this.props.history.push({
          pathname: `/contratacion${pathname}`,
          search: params,
          state: {
            dataForm: {
              ...newDataForm,
              beneficiaries: [],
              loan: {},
            },
            data: {
              ...data,
              beneficiaries: [],
              loan: {},
            },
            fromSimulation: true,
            selectedMediator: this.props.selectedMediatorCode,
            simulationId
          },
        }),
    });
  }

  getMediatorName = (mediator: IMediator, selectedMediatorCode: string): string => {
    let mediatorName = '';

    if (mediator.mediatorCode === selectedMediatorCode) {
      mediatorName = mediator.mediatorName.trim();
    }
    else {

      mediatorName = mediator.relatedMediators ?
        mediator.relatedMediators.find((m: any) => m.mediatorCode === selectedMediatorCode).mediatorName.trim() || '' :
        '';
    }
    return mediatorName;
  }

  async saveSimulation() {
    const {
      policy,
      responseSimulation,
      data,
      setLoading,
      selectedMediatorCode,
      mediator,
      user
    } = this.props;

    try {

      const loggedUser = `${user.name} ${user.lastname1} ${user.lastname2}`;
      const productType = policy.product.products_internalName
      const mediatorName = this.getMediatorName(mediator, selectedMediatorCode);

      const savedSimulation = await SimulationServices.simulation({
        loader: true,
        setLoading: setLoading,
        data: {
          body: {
            ...data,
            mediatorAssociatedToPolicy: selectedMediatorCode,
            mediatorName,
            loggedUser,
            loggedUserCode: user.code,
          },
          response: responseSimulation,
          productType,
        },
      });
      this.setState({
        simulationSaved: true,
        showLeftInfoModal: true,
        leftInfoModalImg: images.IconSaveBlue,
        leftInfoModalClassName: "",
        leftInfoModalTitle: "La simulación se ha guardado",
        leftInfoModalText:
          "La simulación se ha guardado en “simulaciones pendientes”. Tiene una validez de 30 días. ",
        leftInfoModalButtonText: "Ir a pendientes",
        leftInfoModalClick: () =>
          this.props.history.push("/simulacion/pendiente"),
        simulationId: savedSimulation.id
      });
    } catch (err) {
      console.log(err);
      this.setState({
        showLeftInfoModal: true,
        leftInfoModalClassName: "error",
        leftInfoModalImg: images.WarningError,
        leftInfoModalTitle: "La simulación no se ha guardado",
        leftInfoModalText: "Se ha producido un error, prueba más tarde.",
        leftInfoModalButtonText: "Ir a pendientes",
        leftInfoModalClick: () =>
          this.props.history.push("/simulacion/pendiente"),
      });
    }
  }

  async downloadDoc() {
    const { responseSimulation, policy, user, checkProductArea } = this.props;
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)

    const insuredIds =
      isPetArea
        ? [
          responseSimulation.taker.docNumber
            ? responseSimulation.taker.docNumber
            : "",
        ]
        : responseSimulation.insured.map((ins: any) =>
          ins.docNumber ? ins.docNumber : ""
        );
    try {
      let doc = await SimulationServices.getSimulationDocument(
        true,
        this.props.setLoading,
        {
          policyType: policy.product.products_internalName,
          mediatorCode: user.code,
          simulationId: responseSimulation.requestNumber.toString(),
          businessLine: responseSimulation.businessLine,
          bufferBase64: responseSimulation.documents[0].data,
        }
      );
      if (doc.status === 204) this.showModalDocNotAvailable();
      else downloadFile(doc.fileData, doc.fileName, doc.fileType, true);
    } catch (e) {
      this.setState({
        ...this.state,
        showLeftInfoModal: true,
        leftInfoModalClassName: "error",
        leftInfoModalImg: images.WarningError,
        leftInfoModalTitle: "No se ha podido descargar el PDF",
        leftInfoModalText:
          "Se ha producido un error en el servicio. Intentelo más tarde",
        leftInfoModalButtonText: "Cerrar",
        leftInfoModalClick: () => { },
      });
    }

  }

  showModalDocNotAvailable = (
    title: string = 'No se ha podido recuperar el PDF', 
    message: string = 'La documentación se está generando. Por favor, inténtelo de nuevo transcurridos unos segundos.', 
    buttonText: string= 'Aceptar'
  ) => {
    this.setState({
      ...this.state,
      showLeftInfoModal: true,
      leftInfoModalClassName: "info",
      leftInfoModalImg: images.IconWarningYellow,
      leftInfoModalTitle: title,
      leftInfoModalText: message,
      leftInfoModalButtonText: buttonText,
      leftInfoModalClick: () => { },
    });
  }

  downloadSimulation() {
    const { responseSimulation } = this.props;
    if (responseSimulation.requestNumber) {
      this.downloadDoc();
    } else {
      this.setState({
        ...this.state,
        showLeftInfoModal: true,
        leftInfoModalClassName: "error",
        leftInfoModalImg: images.WarningError,
        leftInfoModalTitle: "No se ha podido descargar el PDF",
        leftInfoModalText:
          "Se ha producido un error, verifique que haya simulado correctamente.",
        leftInfoModalButtonText: "Cerrar",
        leftInfoModalClick: () => { },
      });
    }
  }

  sendPdfEmail = async () => {
    const {
      responseSimulation,
      setLoading,
      policy,
      mediator,
      selectedMediatorCode,
    } = this.props;
    const { takerEmail } = this.state;
    let mediatorCode;
    let mediatorName;
    //mediator -> relatedMediator[]
    if (mediator.mediatorCode !== selectedMediatorCode) {
      if (mediator.relatedMediators) {
        mediatorCode = mediator.relatedMediators.find(
          (x) => x.mediatorCode === selectedMediatorCode
        ).mediatorCode;
        mediatorName = mediator.relatedMediators.find(
          (x) => x.mediatorCode === selectedMediatorCode
        ).mediatorName;
      }
    } else {
      mediatorCode = selectedMediatorCode;
      mediatorName = mediator.mediatorName;
    }
    if (
      responseSimulation.taker &&
      !responseSimulation.taker.email &&
      !takerEmail.value
    ) {
      this.setState({
        ...this.state,
        showEmailModal: true,
        leftInfoModalImg: images.IconContactCollapsedWhite,
        leftInfoModalClassName: "",
        leftInfoModalTitle: "No se ha suministrado correo del tomador",
        leftInfoModalText:
          "Por favor, ingrese un correo válido para el envio del PDF",
        leftInfoModalButtonText: "Enviar",
        leftInfoModalClick: this.validateHandler,
      });
    } else {
      try {
        let doc = await SimulationServices.getSimulationDocument(
          true,
          setLoading,
          {
            policyType: policy.product.products_internalName,
            mediatorCode: mediatorCode,
            simulationId: responseSimulation.requestNumber.toString(),
            businessLine: responseSimulation.businessLine,
            bufferBase64: responseSimulation.documents[0].data,
            mediator: mediatorName,
            email: responseSimulation.taker.email || takerEmail.value,
          }
        );
        if (doc.status === 204) this.showModalDocNotAvailable();
        else {
          this.setState({
            ...this.state,
            showLeftInfoModal: true,
            leftInfoModalImg: images.IconContactCollapsedWhite,
            leftInfoModalClassName: "",
            leftInfoModalTitle: "El pdf se ha enviado por correo",
            leftInfoModalText:
              "El presupuesto se ha enviado satisfactoriamente al correo suministrado.",
            leftInfoModalCloseButtonText: "Cerrar",
            leftInfoModalButtonText: "Aceptar",
            leftInfoModalClick: () => { },
          });
        }
      } catch (error) {
        console.error(error);
        this.setState({
          ...this.state,
          showLeftInfoModal: true,
          leftInfoModalClassName: "error",
          leftInfoModalImg: images.WarningError,
          leftInfoModalTitle: "No se ha podido enviar el PDF",
          leftInfoModalText:
            "Se ha producido un error, verifique que haya ingresado un email válido.",
          leftInfoModalButtonText: "Cerrar",
          leftInfoModalClick: () => { },
        });
      }
    }
  };
  openLeftInfoModal() {
    this.setState({ showLeftInfoModal: true });
  }

  closeLeftModal() {
    this.setState({
      showLeftInfoModal: false,
      leftInfoModalCloseButtonText: "",
    });
  }

  closeEmailModal() {
    this.setState({
      ...this.state,
      showEmailModal: false,
      leftInfoModalCloseButtonText: "",
      takerEmail: {
        value: "",
        errorCode: "",
        labelText: "Correo electrónico",
      },
    });
  }

  getTakerInfoRows = () => {
    const { dataForm, policy, checkProductArea } = this.props;
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
    const takerInfoRows = dataForm.taker.contact
      ? [
        {
          taker: {
            text: dataForm.taker.contact.name.value
              ? `${dataForm.taker.contact.name.value} ${dataForm.taker.contact.lastname1.value} ${dataForm.taker.contact.lastname2.value}`
              : "",
            type: "",
            link: "",
            key: "entire-mini-body",
          },
          ...(!isPetArea && {
            birthDate: {
              text: dataForm.taker.contact.birthdate.value
                ? getDayMonthYear(dataForm.taker.contact.birthdate.value)
                : "",
              type: "",
              link: "",
              key: "dimmed-mini-body",
            },
          }),
          dni: {
            text: dataForm.taker.contact.docNumber.value
              ? dataForm.taker.contact.docNumber.value
              : "",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },

        },
      ]
      : [];
    return takerInfoRows;
  };

  getTakerInfoColumns = () => {
    const { policy, checkProductArea } = this.props;
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)

    const takerInfoColumns = [
      {
        text: "Tomador",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      ...(!isPetArea ?
        [
          {
            text: "Fecha de nacimiento",
            filter: false,
            ascendent: true,
            key: "dimmed-mini-title",
          }
        ]
        : []),
      {
        text: "DNI/Pasaporte",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
    ];
    return takerInfoColumns;
  };

  getInsuredMembersInfoRows = () => {
    const { dataForm, policy, checkProductArea } = this.props;
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)
    const insuredMembersInfoRows = dataForm.insureds.map(
      (insured: any, index: number) => {
        const hasForeignAssistance =
          dataForm.insureds.length > 0 &&
          dataForm.insureds[index].coverages["DC04"] &&
          dataForm.insureds[index].coverages["DC04"].value;
        const data: any = {};
        data["insured"] = {
          text: `${insured.contact.name.value} ${insured.contact.lastname1.value} ${insured.contact.lastname2.value}`,
          type: "",
          link: "",
          key: "entire-mini-body",
        };
        data["birthDate"] = {
          text: getDayMonthYear(insured.contact.birthdate.value),
          type: "",
          link: "",
          key: "dimmed-mini-body",
        };
        data["dni"] = {
          text: insured.contact.docNumber.value,
          type: "",
          link: "",
          key: "dimmed-mini-body",
        };
        if (isDeathArea) {
          data["timeAbroad"] = {
            text: hasForeignAssistance ? "Sí" : "No",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          };
        }
        if (isDeathArea) {
          data["foreignCountry"] = {
            text: hasForeignAssistance
              ? countries[insured.foreignAddress.country.value.value]
              : "",
            type: "",
            link: "",
            key: "dimmed-mini-body",
          };
        }
        return data;
      }
    );
    if (isPetArea)
      insuredMembersInfoRows.splice(0, 1);

    return insuredMembersInfoRows;
  };

  getInsuredMembersInfoColumns = () => {
    const { policy, dataForm, checkProductArea } = this.props;
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)
    const hasForeignAssistance =
      dataForm.insureds.length > 0 &&
      dataForm.insureds[0].coverages["DC04"] &&
      dataForm.insureds[0].coverages["DC04"].value;
    const insuredMembersInfoColumns = [
      {
        text: "Asegurados",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "Fecha de nacimiento",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "DNI/PASAPORTE",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ];
    if (isDeathArea) {
      insuredMembersInfoColumns.push({
        text: "Estancia extranjero > 90 días",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      });
    }
    if (isDeathArea && hasForeignAssistance) {
      insuredMembersInfoColumns.push({
        text: "País de residencia",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      });
    }

    return insuredMembersInfoColumns;
  };

  getPaymentTableRows = () => {
    const { responseSimulation, allPayTypes } = this.props;

    const payments: any[] = responseSimulation.payments;
    if (!payments) return [];

    let paymentTableRows = payments.map((payment: any, index: number) => {
      if (payment && payment.totalPremium && payment.totalPremium > 0) {
        return {
          periodicity: {
            text: PayTypesServices.getPayTypeByCode(allPayTypes, payment.payType).description,
            order: PayTypesServices.getPayTypeByCode(allPayTypes, payment.payType).payTypeOrder,
            type: "",
            link: "",
            key: "entire-mini-body",
          },
          netPremium: {
            text: `${currencyFormat(payment.netPremium)} €`,
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
          taxs: {
            text: `${currencyFormat(payment.taxs)} €`,
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
          total: {
            text: `${currencyFormat(payment.totalPremium)} €`,
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
        }
      }
    }
    );
    paymentTableRows = paymentTableRows.filter(function (element) {
      return element !== undefined;
    });
    paymentTableRows.sort(
      (a: any, b: any) => a.periodicity.order - b.periodicity.order
    );

    return paymentTableRows;
  };

  getPaymentTableColumns = () => {
    const paymentTableColumns = [
      {
        text: "Periodicidad",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "Total prima neta",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "Impuestos y consorcio",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
      {
        text: "Total recibo",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ];

    return paymentTableColumns;
  };

  getRiskInfoRows = () => {
    const { dataForm, policy, checkProductArea } = this.props;
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)
    const riskInfoRows = dataForm.taker.profession
      ? [
        {
          job: {
            text:
              isDeathArea
                ? dataForm.insureds[0].address2.city.value
                : dataForm.taker.profession.occupation.value.label,
            type: "",
            link: "",
            key: "entire-mini-body",
          },
          risk: {
            text:
              isDeathArea
                ? "3.524,00 €"
                : !!dataForm.taker.profession.riskActivity.value && dataForm.taker.profession.riskActivity.value === "Y"
                  ? "Si"
                  : "No", //TODO: NI IDEA DE que poner aqui en deaths
            type: "",
            link: "",
            key: "dimmed-mini-body",
          },
        },
      ]
      : [];

    return riskInfoRows;
  };

  getRiskInfoColumns = () => {
    const { policy, checkProductArea } = this.props;
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)
    const riskInfoColumns = [
      {
        text:
          isDeathArea
            ? "Localidad del domicilio del tomador"
            : "Profesión",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text:
          isDeathArea
            ? "Capital decesos por asegurado"
            : "Actividad de riesgo",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ];

    return riskInfoColumns;
  };

  getMandatoryGuaranteeTableRows = () => {
    const { dataForm, policy, checkProductArea } = this.props;
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
    let mandatoryGuaranteeTableRows: any[] = [];
    if (dataForm.insureds[0]) {
      Object.keys(dataForm.insureds[0].coverages).forEach((c) => {
        const split = c.split("-");
        const isComplementary =
          (split.length > 1 && split[1] === "C") || split[1] === "O";
        const isNotShow = split.length > 1 && split[1] === "N";
        const coverage = dataForm.insureds[0].coverages[c];
        if (
          !isComplementary &&
          !isNotShow &&
          coverage.value &&
          !coverage.complementary
        ) {
          mandatoryGuaranteeTableRows.push({
            coverages: {
              text: coverage.name,
              type: "",
              link: "",
              key: "entire-mini-body",
            },
          });
        }
      });
    } else if (isPetArea && dataForm.taker.coverages) {
      Object.keys(dataForm.taker.coverages).forEach((c) => {
        const split = c.split("-");
        const coverage = dataForm.taker.coverages[c];
        const isComplementary =
          (split.length > 1 && split[1] === "C") ||
          split[1] === "O" ||
          coverage.complementary;
        const isNotShow = split.length > 1 && split[1] === "N";
        if (!isComplementary && !isNotShow && coverage.value) {
          mandatoryGuaranteeTableRows.push({
            coverages: {
              text: coverage.name,
              type: "",
              link: "",
              key: "entire-mini-body",
            },
          });
        }
      });
    }
    return mandatoryGuaranteeTableRows;
  };

  getMandatoryGuaranteeTableColumns = () => {
    const mandatoryGuaranteeTableColumns = [
      {
        text: "GARANTÍAS OBLIGATORIAS (PARA TODOS LOS ASEGURADOS)",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
    ];

    return mandatoryGuaranteeTableColumns;
  };

  getOptionalGuaranteeTableRows = () => {
    const { dataForm } = this.props;
    let coverages: string[] = [];
    if (dataForm.insureds[0]) {
      dataForm.insureds.forEach((insured: any) => {
        Object.keys(insured.coverages).forEach((c) => {
          const split = c.split("-");
          const coverage = insured.coverages[c];
          const isComplementary =
            (split.length > 1 && split[1] === "C") ||
            split[1] === "O" ||
            coverage.complementary;
          if (isComplementary && coverage.value) {
            coverages.push(coverage.name);
          }
        });
      });
    }
    // TAKING OUT DUPLICATES
    coverages = coverages.filter(
      (value: any, index: any, initialArray: any) =>
        initialArray.findIndex((t: any) => t === value) === index
    );
    //

    return coverages.map((c: string) => ({
      coverages: {
        text: c,
        type: "",
        link: "",
        key: "entire-mini-body",
      },
    }));
  };

  getOptionalGuaranteeTableColumns = () => {
    const optionalGuaranteeTableColumns = [
      {
        text: "GARANTÍAS OPCIONALES", //(ESTANCIA EN EL EXTRANJERO SUPERIOR A 90 DÍAS)",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
    ];

    return optionalGuaranteeTableColumns;
  };

  showExitModal = () => {
    this.setState({
      showLeftInfoModal: true,
      leftInfoModalClassName: "",
      leftInfoModalImg: images.WarningError,
      leftInfoModalTitle: "¿Desea salir del proceso?",
      leftInfoModalText: "",
      leftInfoModalButtonText: "Salir",
      leftInfoModalClick: () =>
        this.props.history.push("/simulacion/pendiente"),
    });
  };

  validateHandler = () => {
    const { takerEmail } = this.state;
    if (!isValidEmail(takerEmail.value)) {
      this.setState({
        ...this.state,
        takerEmail: {
          ...takerEmail,
          errorCode: "invalid-email",
        },
      });
    } else {
      this.setState(
        {
          ...this.state,
          takerEmail: {
            ...takerEmail,
            errorCode: "",
            labelText: "Correo electrónico",
          },
        },
        () => {
          this.closeEmailModal();
          this.sendPdfEmail();
        }
      );
    }
  };

  render() {
    const {
      showLeftInfoModal,
      leftInfoModalImg,
      simulationSaved,
      leftInfoModalClassName,
      leftInfoModalTitle,
      leftInfoModalText,
      leftInfoModalButtonText,
      leftInfoModalClick,
      takerInfoRows,
      takerInfoColumns,
      paymentTableColumns,
      paymentTableRows,
      insuredMembersInfoRows,
      insuredMembersInfoColumns,
      riskInfoColumns,
      riskInfoRows,
      mandatoryGuaranteeTableColumns,
      mandatoryGuaranteeTableRows,
      optionalGuaranteeTableColumns,
      optionalGuaranteeTableRows,
      showEmailModal,
      takerEmail,
      leftInfoModalCloseButtonText,
    } = this.state;
    const { policy, responseSimulation, dataForm, data, selectedMediatorCode, checkProductArea, user } =
      this.props;
    const hasForeignAssistance =
      dataForm.insureds.length > 0 &&
      dataForm.insureds[0].coverages["DC04"] &&
      dataForm.insureds[0].coverages["DC04"].value;
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
    const isLifeArea = checkProductArea(AREA_NAMES.LIFE, policy.product.products_internalName)
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, policy.product.products_internalName)

    const getTableNumberMascots = (insured: any, index: number) => {
      const { coverages } = this.props;
      let garantias: any = [];
      let main: any = [];
      let complementary: any = [];
      const obtenerCapital = (coverage: any) => {
        let objCapital = insured.coverages.find((cov: any) => cov.code === coverage.code);
        return objCapital ? formatter.format(Number(objCapital.capital)) : "0 €";
      }
      coverages.pet.map((coverage: any, key: number) => {
        if (coverage.required) {
          main.push(<p className="padding-contenido" key={key}>{coverage.name}</p>)
        }
        else {
          complementary.push(
            <div className={"flex-space-between padding-cabeceras"} key={key}>
              <DicotomicCheckbox
                initChecked={insured.coverages.some((covSelect: any) => coverage.code === covSelect.code) ? true : false}
                className="mandatory"
                checkboxText={coverage.name}
                onChange={() => { }}
              />
              {<p className="mb-15">{obtenerCapital(coverage)}</p>}
            </div>
          )
        }
      })
      garantias.push(
        <div key={index} className="result-simulation-item-content-table bg-white">
          <div className="colSin-100 bg-snow"><div className="colSin-25 table-bgBlue padding-cabeceras borderB-white">Mascota {index + 1}</div></div>
          <div className="colSin-50 borderR-white">
            <p className="table-bgBlue padding-cabeceras">GARANTÍAS OBLIGATORIAS</p>
            {main}
          </div>
          <div className="colSin-50">
            <p className="table-bgBlue padding-cabeceras">GARANTÍAS OPCIONALES</p>
            {complementary}
          </div>
        </div>
      )
      return garantias
    }

    return (
      <NewSimulationContainer>
        <div className="new-simulation-flow-status">
          <div className="new-simulation-flow-status-title">
            <HeadsTitleText>
              <p>Resultado de la simulación</p>
            </HeadsTitleText>
          </div>
          <div className="new-simulation-flow-status-guide">
            <GuideLine steps={[0, 0, 0]} currentStep={2} />
          </div>
        </div>

        <SimulationResultContainer
          colorSpan={setColorSpan(policy.product.ln_nameItem)}
        >
          <div className="result-hero-container">
            <div className="result-hero-type">
              <p>
                {`Póliza ${policy.product.products_areaName}`}
                <span>
                  {policy.product.products_name}
                </span>
              </p>
            </div>
            <div className="result-hero-data">
              <div className="result-hero-data__name">
                <p>
                  {user.name} {user.lastname1} {user.lastname2}
                </p>
              </div>
              <div className="result-hero-data__nif">
                <p>
                  {selectedMediatorCode ? selectedMediatorCode : user.code}
                </p>
              </div>
              <div className="result-hero-data__email">
                <p>{user.email}</p>
              </div>
            </div>
          </div>

          <div className="result-welcome">
            <div className="result-welcome__title">
              <p>Estimado/a Sr./Sra:</p>
            </div>
            <div className="result-welcome__description">
              <p>
                En base a la información que nos ha proporcionado, le hemos
                preparado un presupuesto de seguro personalizado para que pueda
                evaluar las ventajas en precio y coberturas que le ofrece{" "}
                {isLifeArea
                  ? "ASISA VIDA"
                  : "ASISA"}
                .
              </p>
            </div>
          </div>

          <div className="result-simulation-special">
            <div className="result-simulation-special-title">
              <Title03Text>
                <p>Simulación</p>
              </Title03Text>
            </div>
            <div className="result-simulation-special-container">
              <div className="result-simulation-special-item">
                <HeadText>
                  <p>
                    {
                      `Póliza de ${policy.product.products_name}`
                    }
                  </p>
                </HeadText>
              </div>
              <div className="result-simulation-special-item">
                <div className="result-simulation-special-subitem">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Fecha efecto</p>
                    </NoteText>
                  </div>
                  <div className="result-simulation-special-subitem-content">
                    <WebText>
                      <p>
                        {getDayMonthYear(
                          data.taker.starterDay
                            ? moment(
                              data.taker.starterDay,
                              "DD/MM/YYYY"
                            ).toDate()
                            : new Date()
                        )}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-simulation-special-subitem">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Expira</p>
                    </NoteText>
                  </div>
                  <div className="result-simulation-special-subitem-content">
                    <WebText>
                      <p>
                        {getDayMonthYear(
                          addMonths(
                            data.taker.starterDay
                              ? moment(
                                data.taker.starterDay,
                                "DD/MM/YYYY"
                              ).toDate()
                              : new Date(),
                            1
                          )
                        )}
                      </p>
                    </WebText>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="result-simulation-container">
            <div className="result-simulation-item">
              <div className="result-simulation-item-title">
                <Title03Text>
                  <p>Información del tomador</p>
                </Title03Text>
              </div>
              <div className="result-simulation-item-content">
                <div className="result-simulation-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={takerInfoColumns}
                    rows={takerInfoRows}
                    keysRow={
                      isPetArea ? ["taker", "dni"] : ["taker", "birthDate", "dni"]
                    }
                  />
                </div>
              </div>
            </div>
            {!isPetArea && <div className="result-simulation-item">
              <div className="result-simulation-item-title">
                <Title03Text>
                  <p>Información de los asegurados</p>
                </Title03Text>
              </div>
              <div className="result-simulation-item-content">
                <div className="result-simulation-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={insuredMembersInfoColumns}
                    rows={insuredMembersInfoRows}
                    keysRow={
                      isDeathArea &&
                        hasForeignAssistance
                        ? [
                          "insured",
                          "birthDate",
                          "dni",
                          "timeAbroad",
                          "foreignCountry",
                        ]
                        : isDeathArea
                          ? ["insured", "birthDate", "dni", "timeAbroad"]
                          : ["insured", "birthDate", "dni"]
                    }
                  />
                </div>
              </div>
            </div>}
            {riskInfoRows.length > 0 &&
              !isPetArea &&
              ((isDeathArea && dataForm.insureds[0].address2.city.value) ||
                !isDeathArea) ? (
              <div className="result-simulation-item">
                <div className="result-simulation-item-title">
                  <Title03Text>
                    <p>Información del riesgo</p>
                  </Title03Text>
                </div>
                <div className="result-simulation-item-content">
                  <div className="result-simulation-item-content-table">
                    <MainTable
                      className="table noZebra"
                      columns={riskInfoColumns}
                      rows={riskInfoRows}
                      keysRow={["job", "risk"]}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {isPetArea ?
              <div className="result-simulation-item">
                <div className="result-simulation-item-title">
                  <Title03Text>
                    <p>Garantías</p>
                  </Title03Text>
                </div>
                <div className="result-simulation-item-content">
                  {responseSimulation.insured.map((insured: any, i: number) => {
                    return getTableNumberMascots(insured, i);
                  })}
                </div>
              </div>
              : <div className="result-simulation-item">
                <div className="result-simulation-item-title">
                  <Title03Text>
                    <p>Garantías</p>
                  </Title03Text>
                </div>
                <div className="result-simulation-item-content">
                  <div className="result-simulation-item-content-table">
                    <MainTable
                      className="table noZebra"
                      columns={mandatoryGuaranteeTableColumns}
                      rows={mandatoryGuaranteeTableRows}
                      keysRow={["coverages"]}
                    />
                  </div>
                  {optionalGuaranteeTableRows.length > 0 && (
                    <div className="result-simulation-item-content-table">
                      <MainTable
                        className="table noZebra"
                        columns={optionalGuaranteeTableColumns}
                        rows={optionalGuaranteeTableRows}
                        keysRow={["coverages"]}
                      />
                    </div>
                  )}
                </div>
              </div>}

            {paymentTableRows && paymentTableRows.length > 0 && (
              <div className="result-simulation-item">
                <div className="result-simulation-item-title">
                  <Title03Text>
                    <p>Desglose por forma de pago</p>
                  </Title03Text>
                </div>
                <div className="result-simulation-item-content">
                  <div className="result-simulation-item-content-table">
                    <MainTable
                      className="table noZebra"
                      columns={paymentTableColumns}
                      rows={paymentTableRows}
                      keysRow={["periodicity", "netPremium", "taxs", "total"]}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="result-simulation-footer">
            <ul>
              <NoteText>
                <li>
                  * Encontrará la nota informativa anexa en el documento
                  Resultado de Simulación PDF, que puede descargar
                </li>
              </NoteText>
              <NoteText>
                <li>
                  * Este presupuesto tiene validez durante 30 días naturales,
                  siempre que se mantengan las circunstancias declaradas por los
                  asegurados. La aceptación del proyecto está supeditada a la
                  cumplimentación de la solicitud de seguro y cuestionarios de
                  salud asegurados. Así mismo,{" "}
                  {isLifeArea
                    ? "ASISA VIDA"
                    : "ASISA"}{" "}
                  se reserva el derecho de la aceptación del riesgo.
                </li>
              </NoteText>
              <NoteText>
                <li>
                  * Este presupuesto se emite a efectos puramente informativos y
                  no se puede considerar una propuesta de seguro, por lo que
                  carece de valor contractual. La prima reflejada está sujeta a
                  la veracidad de la información aquí recogida así como a la no
                  existencia de enfermedad grave alguna o accidente del
                  asegurado, que pueda ser causa de fallecimiento, incapacidad
                  permanente absoluta o cualquier otra contingencia objeto de
                  cobertura, y está basada en la tarifa vigente a la fecha del
                  presente presupuesto. Las coberturas y su alcance se regirán
                  por lo establecido en las Condiciones Generales, Particulares
                  y Especiales de la Póliza.
                </li>
              </NoteText>
            </ul>
          </div>
        </SimulationResultContainer>

        <InflowSidebarContainer>
          <div className="inflow-sidebar">
            <div className="inflow-buttons-container">
              <div className="inflow-buttons-item">
                <MainButton
                  text="Contratar"
                  onClick={() => this.hireSimulation()}
                />
              </div>
              <div className="inflow-buttons-item">
                <MainButton
                  type="full-light"
                  text="Guardar"
                  disabled={simulationSaved}
                  icon
                  iconImg={images.IconSaveWhite}
                  onClick={() => this.saveSimulation()}
                />
              </div>
              <div className="inflow-buttons-item">
                <MainButton
                  disabled={
                    !responseSimulation.documents ||
                    !responseSimulation.documents[0]
                  }
                  type="full-light"
                  text="Descargar PDF"
                  icon
                  iconImg={images.IconDownloadWhite}
                  onClick={() => this.downloadSimulation()}
                />
              </div>
              <div className="inflow-buttons-item">
                <MainButton
                  disabled={
                    !responseSimulation.documents ||
                    !responseSimulation.documents[0]
                  }
                  type="full-light"
                  text="Enviar PDF por Email"
                  icon
                  iconImg={images.IconContactCollapsedWhite}
                  onClick={() => this.sendPdfEmail()}
                />
              </div>
              <div className="inflow-buttons-item">
                <MainButton
                  type="light"
                  text="Terminar"
                  onClick={() => this.showExitModal()}
                />
              </div>
            </div>
          </div>
        </InflowSidebarContainer>

        <LeftInfoModal
          img={leftInfoModalImg}
          className={leftInfoModalClassName}
          mainTitle={leftInfoModalTitle}
          mainText={leftInfoModalText}
          buttonText={leftInfoModalButtonText}
          close={() => this.closeLeftModal()}
          active={showLeftInfoModal}
          onClickAccept={leftInfoModalClick}
          closeButtonText={leftInfoModalCloseButtonText}
        />
        <LeftInfoModal
          img={images.IconContactCollapsedWhite}
          className={leftInfoModalClassName}
          mainTitle={leftInfoModalTitle}
          mainText={leftInfoModalText}
          buttonText={leftInfoModalButtonText}
          close={() => this.closeEmailModal()}
          active={showEmailModal}
          onClickAccept={leftInfoModalClick}
          withInput={{
            labelText: takerEmail.labelText,
            value: takerEmail.value,
            errorCode: takerEmail.errorCode,
            onChange: (value: string) =>
              this.setState({
                ...this.state,
                takerEmail: { ...takerEmail, value },
              }),
          }}
        />
      </NewSimulationContainer>
    );
  }
}

export default withGeneral(withDynamicProducts(withCoverages(withPayTypes(withPolicy(withRouter(SimulationResultLayout))))));
