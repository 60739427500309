import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { MainSidebarContainer } from "./main-sidebar-style";
import { images } from "../../assets";
import NoteText from "../components-text/note-text";
import MainNavItem from "../main-nav-item";
import {
  navSimulationItems,
  navHiringItem,
  navClientsItem,
  roleNotAllowed,
} from "../../constants";
import { withGeneral } from "../../context/mixin/with-general";
import { roleNotAllowedReports } from "../../constants";


interface openObject {
  home: string;
  simulation: string;
  hiring: string;
  clients: string;
  campaigns: string;
  [key: string]: any;
}

interface activeObject {
  home: string;
  simulation: string;
  hiring: string;
  //clients: string;
  campaigns: string;
  [key: string]: any;
}

interface pathNamesObject {
  home: string[];
  simulation: string[];
  hiring: string[];
  //clients: string[];
  campaigns: string[];
  [key: string]: any;
}

interface exitInflowPathNamesObject {
  [key: string]: string;
}

interface MainSidebarLayoutState {
  open: openObject;
  active: activeObject;
  keyNavItem: string;
  notShowMainSidebar: string[];
}
const ADMIN = "ADMIN";

class MainSidebarLayout extends Component<any, MainSidebarLayoutState> {
  pathNames: pathNamesObject = {
    home: ["/"],
    simulation: [
      "/simulacion/pendiente",
      "/simulacion/expirada",
      "/simulacion/nueva",
    ],
    hiring: [
      "/contratacion/pendiente",
      "/contratacion/realizada",
      "/contratacion/nueva",
    ],
    clients: [
      "/polizas-clientes",
      "/liquidaciones-clientes",
      "/recibos-clientes",
    ],
    campaigns: ["/campañas"],
    supplements: ["/suplementos"],
    communicationsManager: ["/gestor-notificaciones"],
    communicationsScreen: ["/panel-notificaciones"]
  };

  exitInflowPathNames: exitInflowPathNamesObject = {
    "/": "home",
    "/simulacion/pendiente": "simulation",
    "/simulacion/expirada": "simulation",
    "/simulacion/nueva": "simulation",
    "/contratacion/pendiente": "hiring",
    "/contratacion/realizada": "hiring",
    "/contratacion/nueva": "hiring",
    "/polizas-clientes": "clients",
    "/liquidaciones-clientes": "clients",
    "/recibos-clientes": "clients",
    "/ficheros-eiac": "clients",
    "/campañas": "campaigns",
    "/suplementos": "supplements"
  };

  readonly state: MainSidebarLayoutState;

  constructor(props: any) {
    super(props);
    this.state = {
      open: {
        home: "",
        simulation: "",
        hiring: "",
        clients: "",
        campaigns: "",
        configPuntos: "",
        configCampaigns: "",
        supplements: "",
      },
      active: {
        home: "",
        simulation: "",
        hiring: "",
        clients: "",
        campaigns: "",
        configPuntos: "",
        configCampaigns: "",
        supplements: "",
      },
      keyNavItem: props.history.location.pathname,
      notShowMainSidebar: [
        "/simulacion/nueva",
        "/comparacion/nueva",
        "/contratacion/nueva",
        "/simulacion/asegurados",
        "/campañas",
        "/suplementos",
        "/suplemento",
        "/preguntas-frecuentes",
        "/gestor-notificaciones",
        "/panel-notificaciones",
        "/suplemento/alta-asegurado"
      ],
    };

    if (!props.isLogged) {
      this.state.notShowMainSidebar.push("/");
    }
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    const { active } = this.state;
    let url = this.props.history.location.pathname;

    Object.keys(this.pathNames).forEach((auxKey) => {
      if (this.pathNames[auxKey].indexOf(url) > -1) {
        active[auxKey] = "active";
        this.setState({ active });
      }
    });
  };

  static getDerivedStateFromProps(
    nextProps: any,
    prevState: MainSidebarLayoutState
  ) {
    if (nextProps.history.location.pathname !== prevState.keyNavItem) {
      return { keyNavItem: nextProps.history.location.pathname };
    } else return null;
  }

  componentDidUpdate(prevProps: any, prevState: MainSidebarLayoutState) {
    if (prevState.keyNavItem !== this.state.keyNavItem) {
      if (this.state.keyNavItem === "/") {
        this.menuHomeLink(this.exitInflowPathNames[this.state.keyNavItem]);
      } else {
        this.openNavItem(this.exitInflowPathNames[this.state.keyNavItem]);
        this.highlightNavLink(this.exitInflowPathNames[this.state.keyNavItem]);
      }
    }
    if (prevProps.isLogged !== this.props.isLogged && !this.props.isLogged) {
      const { notShowMainSidebar } = this.state;
      notShowMainSidebar.push("/");
      this.setState({ notShowMainSidebar });
    } else if (
      prevProps.isLogged !== this.props.isLogged &&
      this.props.isLogged
    ) {
      const { notShowMainSidebar } = this.state;
      const index = notShowMainSidebar.findIndex((e) => e === "/");
      if (index > -1) {
        notShowMainSidebar.splice(index, 1);
      }

      this.setState({ notShowMainSidebar });
    }
  }

  openNavItem(key: string) {
    const { open } = this.state;
    Object.keys(open).forEach((auxKey) => {
      if (auxKey !== key) {
        open[auxKey] = "";
      }
    });

    open[key] = open[key] = "open";

    this.setState({ open });
  }

  highlightNavLink(key: string) {
    const { active } = this.state;
    Object.keys(active).forEach((auxKey) => {
      if (auxKey !== key) {
        active[auxKey] = "";
      }
    });

    active[key] = active[key] ? "" : "active";

    this.setState({ active });
  }

  menuHomeLink(key: string) {
    this.highlightNavLink(key);
    this.openNavItem(key);
  }

  showOptanonSettingsDisplay = () => {
    var btn = document.getElementById("openCookieSettings");
    btn && btn.click();
  }


  render() {
    const { history, is404, isCampaignConfiguration, isLogged, user, is403 } = this.props;
    const { open, active, notShowMainSidebar } = this.state;
    // usuario OCASO no puede ver submenú informes
    const newNavClientsItem = user && user.roles && user.roles.includes(roleNotAllowedReports) ? navClientsItem.filter(item => item.link !== "/informes") : navClientsItem;
    let notShowSidebar: boolean = false;
    if (
      notShowMainSidebar.filter((item) => {
        if (item.length > 1 && history.location.pathname.indexOf(item) > -1) {
          return true;
        } else if (item.length === 1 && history.location.pathname === "/") {
          return true;
        }
        return false;
      }).length !== 0 &&
      !isCampaignConfiguration
    ) {
      notShowSidebar = true;
    }

    if (is404 || is403 || !isLogged) {
      notShowSidebar = true;
    }

    return notShowSidebar ? (
      ""
    ) : (
      <MainSidebarContainer>
        <div className="sidebar-logo">
          <NavLink to="/" exact onClick={() => this.menuHomeLink("home")}>
            <img src={images.LogoAsisaWhite} alt="logotipo Asisa" />
          </NavLink>
        </div>
        <nav className="sidebar-nav">
          <div className="sidebar-nav-main">
            {!isCampaignConfiguration && (
              <>
                <MainNavItem
                  text="Home"
                  icon={images.IconHomeWhite}
                  directLink="/"
                  onClickDropdown={() => this.openNavItem("home")}
                  open={open["home"]}
                  active={active["home"]}
                  onClickLink={() => {}} // this.onClickLink("home")}
                />
                <MainNavItem
                  text="Simulación"
                  icon={images.IconSimulationWhite}
                  dropdownItems={navSimulationItems}
                  onClickDropdown={() => this.openNavItem("simulation")}
                  open={open["simulation"]}
                  active={active["simulation"]}
                  onClickLink={() => {}} // this.onClickLink("simulation")}
                />
                <MainNavItem
                  text="Contratación"
                  icon={images.IconHiringWhite}
                  dropdownItems={navHiringItem}
                  onClickDropdown={() => this.openNavItem("hiring")}
                  open={open["hiring"]}
                  active={active["hiring"]}
                  onClickLink={() => {}} // this.onClickLink("hiring")}
                />
                {
                  user.roles ? !user.roles.includes(roleNotAllowed) && user.code &&
                    <MainNavItem
                      text="Clientes"
                      icon={images.IconClientsWhite}
                      dropdownItems={newNavClientsItem}
                      onClickDropdown={() => this.openNavItem("clients")}
                      open={open["clients"]}
                      /* open={"open"} */
                      active={active["clients"]}
                      onClickLink={() => {}} // this.onClickLink("clients")}
                    />
                  : user.code &&
                    <MainNavItem
                      text="Clientes"
                      icon={images.IconClientsWhite}
                        dropdownItems={newNavClientsItem}
                      onClickDropdown={() => this.openNavItem("clients")}
                      // open={open["clients"]}
                      open={"open"}
                      active={active["clients"]}
                      onClickLink={() => {}} // this.onClickLink("clients")}
                    />
                }

                <MainNavItem
                  text="Notificaciones"
                  icon={images.BellWhiteIcon}
                  directLink="/panel-notificaciones"
                />
                
                {/* <MainNavItem
                    text="Campañas"
                    icon={images.iconCampaignsWhite}
                    directLink="/campañas"
                    onClickDropdown={() => this.openNavItem("campaigns")}
                    open={open["campaigns"]}
                    active={active["campaigns"]}
                    onClickLink={() => { }} // this.onClickLink("campaigns")}
                  /> */}
              </>
            )}
            {isCampaignConfiguration && (
              <>
                <MainNavItem
                  text="Campañas"
                  icon={images.iconCampaignsWhite}
                  directLink="/"
                  onClickDropdown={() => this.openNavItem("configCampaigns")}
                  open={open["configCampaigns"]}
                  active={active["configCampaigns"]}
                  onClickLink={() => {}} // this.onClickLink("campaigns")}
                />
                <MainNavItem
                  text="Config. puntos"
                  icon={images.IconHiringWhite}
                  directLink="/config-puntos"
                  onClickDropdown={() => this.openNavItem("configPuntos")}
                  open={open["configPuntos"]}
                  active={active["configPuntos"]}
                  onClickLink={() => {}} // this.onClickLink("campaigns")}
                />
              </>
            )}
          </div>
          <div className="sidebar-nav-footer">
              <div className="sidebar-nav-footer-container">
                {user.roles && user.roles.includes(ADMIN) &&
                <div className="sidebar-nav-footer-item">
                  <NavLink to="/permisos">
                    <NoteText>Permisos</NoteText>
                  </NavLink>
                </div>
              }
                {
                  user.roles && user.roles.includes("ADMIN") && (
                    <div className="sidebar-nav-footer-item">
                      <NavLink to="/gestor-notificaciones">
                        <NoteText>Gestor notificaciones</NoteText>
                      </NavLink>
                    </div>
                  )
                }
                <div className="sidebar-nav-footer-item">
                  <NavLink to="/aviso-contratacion">
                    <NoteText>Previo a la contratación</NoteText>
                  </NavLink>
                </div>
              <div className="sidebar-nav-footer-item">
                <NavLink to="/contacto">
                  <NoteText>Contacto</NoteText>
                </NavLink>
              </div>
              {user.roles && !user.roles.includes('SUSCAGENTEOCASO') &&
              <div className="sidebar-nav-footer-item">
                <NavLink to="/preguntas-frecuentes">
                  <NoteText>Preguntas frecuentes</NoteText>
                </NavLink>
              </div>
              }
              {user.roles && user.roles.includes('SUSCAGENTEOCASO') &&
              <div className="sidebar-nav-footer-item">
                <NavLink to="/preguntas-ocaso">
                  <NoteText>Preguntas frecuentes</NoteText>
                </NavLink>
              </div>
              }
            </div>
            <div className="sidebar-nav-footer">
              {/* <div className="sidebar-nav-footer-container">
                <div className="sidebar-nav-footer-item">
                  <NavLink to="/asisa">
                    <NoteText>Asisa</NoteText>
                  </NavLink>
                </div>
                <div className="sidebar-nav-footer-item">
                  <NavLink to="/contacto">
                    <NoteText>Contacto</NoteText>
                  </NavLink>
                </div>
              </div> */}
              <div className="sidebar-nav-footer-container">
                <div className="sidebar-nav-footer-item">
                  <NavLink to="/politica-cookies">
                    <NoteText>Política de cookies</NoteText>
                  </NavLink>
                </div>
                <div className="sidebar-nav-footer-item">
                  <NoteText>
                    <p className="footer-side-configuration" onClick={() => this.showOptanonSettingsDisplay()}>Configuración de cookies</p>
                  </NoteText>
                </div>
                <div className="sidebar-nav-footer-item">
                  <NavLink to="/politica-proteccion-datos">
                    <NoteText>Política protección de datos</NoteText>
                  </NavLink>
                </div>
              </div>
              {/* <div className="sidebar-nav-footer-item">
                  <NavLink to="/politica-conservacion-datos">
                    <NoteText>Política conservación de datos</NoteText>
                  </NavLink>
                </div>
                <div className="sidebar-nav-footer-item">
                  <NavLink to="/politica-adicional-rgpd">
                    <NoteText>Información adicional RGPD</NoteText>
                  </NavLink>
                </div> */}
            </div>
            </div>
          </nav>
        </MainSidebarContainer>
      );
  }
}

export default withGeneral(withRouter(MainSidebarLayout));
