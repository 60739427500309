import React from 'react';
import MainNavItemLayout from './main-nav-item-layout';

export default class MainNavItem extends React.Component<any, {}> {
    render(){
        const {icon, className, text, dropdownItems, directLink, onClickLink, onClickDropdown, active, open} = this.props;
        return(
            <MainNavItemLayout open={open} active={active} onClickLink={onClickLink} onClickDropdown={onClickDropdown} directLink={directLink} className={className} icon={icon} text={text} dropdownItems={dropdownItems}/>
        )
    }
}