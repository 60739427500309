import styled from "styled-components";
import { colors } from "../../../../assets";

export const MediatorDataContainer = styled.div`
  .data-flow-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  .content-wrapper {
    overflow: initial;
    padding-right: 16px;
    padding: 20px;
    background-color: #fbfbfb;
    display: block;
    width: 100%;
    float: left;
  }

  .data-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 30px;
  }
`;
