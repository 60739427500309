import styled, { css } from 'styled-components';

export const WebTextContainer = styled.div`
    font-family: "museo sans";
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    p {
        white-space: nowrap;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
     
    ${props => css`
        color: ${props.color}
    `}




`;