import styled from "styled-components";
import { colors } from "../../assets";

export const CheckBlockButtonContainer = styled.div`
  font-family: "museo sans";
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  background: #f4f7fa;
  padding: 20px;
  cursor: pointer;

  &.active {
    background: ${colors["SU-001-100"]};

    .check-icon > img {
      display: block;
    }

    .check-text {
      color: ${colors["NT-001-000"]};
    }
  }

  .check-icon {
    background: ${colors["NT-001-000"]};
    width: 18px;
    height: 18px;
    margin-bottom: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding-top: 5px;

    img {
      width: 10px;
      height: 10px;
      display: none;
    }
  }

  .check-text {
    color: ${colors["PR-001-100"]};
  }
`;
