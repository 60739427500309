import styled from 'styled-components';

export const ProtectionDataContainer = styled.div`
.personal-data-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0;
}

.personal-data-row {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.personal-data-item {
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }
}

.personal-data-buttons {
  display: flex;
  &__item {
    margin-right: 70px;
    min-width: 100px;
    &:last-child {
      margin-right: 20px;
      width: 180px;
    }
  }
}
.personal-data-wrapper {
  overflow: initial;
  padding-right: 16px;
  padding: 20px;
  background-color: #fbfbfb;
  display: block;
  width: 100%;
  float: left;
  margin-top: 40px;
}
@media (min-width: 320px) {
  .personal-data-wrapper {
    width: 55% !important;
  }
}
@media (min-width: 1201px) {
  .personal-data-wrapper {
    width: 100% !important;
  }
}
.col-25 {
  width: 25%;
}

.col-33 {
  width: 33%;
}

.col-50 {
  width: 50%;
}

.col-75 {
  width: 75%;
}

.col-100 {
  width: 100%;
}

.tooLongLabelHandler > div {
  margin-top: 20px;
}
`;
