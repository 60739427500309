import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import MainButton from "../../../../../components/main-button";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { IMediator } from "../../../../../models/mediator-model";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";
import { TravelTakerDataContainer } from "./travel-taker-data-style";
import HealthAddressFormItem, { AddressData } from "../travel-forms/address-item/address-form-item";
import HealthContactFormItem, { ContactData } from "../travel-forms/contact-item/contact-form-item";
import PersonalInformation, { PersonalData } from "../travel-forms/personal-information/personal-information";
import PolicyData from "../travel-forms/policy-data/policy-data";
import { StepsTravel, TravelHireMenuHelper } from "../../../../../components/travel-hire-menu/travel-hire-menu";
import HealthService from "../../../../../services/HealthService";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import { IHealthCoverage } from "../../../../../constants/health";
import moment from "moment-timezone";
import { formatCoveragesTravel } from "../../../../../utils/coverages";
import { PRODUCT_TYPE } from "../../../../../constants/policyType";


export interface TravelPolicyDataInterface {
  selectedMediator: string;
  departureDate: string;
  returnDate: string;
  travelDestination: string;
  residenceCountry: string;
}

export interface TravelTakerData {
  addressData: AddressData,
  contactData: ContactData
}

interface TravelTakerProps extends RouteComponentProps {
  user: any;
  policy: any;
  setLoading: Function;
  data: any;
  saveHiring: Function;
  isReadOnly: boolean;
  selectedMediatorCode: string;
  mediator: IMediator;
  removeSign: Function;
  coverages: any;
}

interface TravelTakerState {
  physicalPerson: boolean,
  validatePersonalInformation: {
    validateRequest: boolean,
    isValidated: boolean
  },
  validatePolicyData: {
    validateRequest: boolean,
    isValidated: boolean
  },
  validateAddress: {
    validateRequest: boolean,
    isValidated: boolean,
  },
  validateContact: {
    validateRequest: boolean,
    isValidated: boolean,
  },
  personalData: PersonalData;
  addressData: AddressData;
  contactData: ContactData;
  policyData: TravelPolicyDataInterface;
  nextScreen: boolean;
  anyChange: boolean;
  previousModalShow: boolean;
  cancelSignModalShow: boolean;
  modalErrorShow: boolean;
  modalErrorText: string,
  coverages: IHealthCoverage[],
}

class TravelTakerDataLayout extends React.Component<TravelTakerProps, TravelTakerState> {

  constructor(props: TravelTakerProps) {
    super(props);

    this.state = {
      physicalPerson: props.data.taker.physicalPerson !== undefined ? props.data.taker.physicalPerson : true,
      validatePersonalInformation: {
        validateRequest: false,
        isValidated: false
      },
      validatePolicyData: {
        validateRequest: false,
        isValidated: false
      },
      validateAddress: {
        validateRequest: false,
        isValidated: false
      },
      validateContact: {
        validateRequest: false,
        isValidated: false
      },
      personalData: {
        name: '',
        birthDate: moment().format("DD/MM/YYYY"),
        docNumber: '',
        gender: '',
        civilStatus: '',
        docType: '',
        lastname1: '',
        nationality: '',
        nationalityLabel: '',
        profession: { value: '', code: '', other: '' },
        lastname2: '',
        relationship: '',
        sameAsTaker: false,
        passportDate: undefined,
        physicalPerson: true,
        hasAnotherInsurance: false,
        numPoliza: ''
      },
      addressData: {
        address: { value: '', code: '', label: '' },
        wayType: '',
        number: '',
        postalCode: '',
        province: { value: '', code: '', label: '' },
        town: { value: '', code: '', label: '' },
        door: '',
        floor: '',
        portal: '',
        stairs: '',
        sameAsTaker: false,
        normalizaDireciones: "S",
      },
      contactData: {
        comunicationChannel: '',
        comunicationLanguage: '',
        email: props.data.taker && props.data.taker.email ? props.data.taker.email : '',
        mobilePhone: '',
        paperless: '',
        contactHour: '',
        landlinePhone: '',
        sameAsTaker: false
      },
      policyData: {
        departureDate: props.data.taker && props.data.taker.departureDate ? props.data.taker.departureDate : '',
        returnDate: props.data.taker && props.data.taker.returnDate ? props.data.taker.returnDate : '',
        travelDestination: props.data.taker && props.data.taker.travelDestination ? props.data.taker.travelDestination : '',
        residenceCountry: props.data.taker && props.data.taker.residenceCountry ? props.data.taker.residenceCountry : '1', //Valor por defecto es España
        selectedMediator:  props.data.mediatorCode ? props.data.mediatorCode : ''
      },
      nextScreen: false,
      anyChange: false,
      previousModalShow: false,
      cancelSignModalShow: false,
      modalErrorShow: false,
      modalErrorText: "",
      coverages: [],
    };
  }

  componentDidMount = async() => {
    const { policy } = this.props;

    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    if (Object.keys(policy.product).length !== 0) {
      await this.getCoverages()
    }

  };

  componentDidUpdate = async (prevProps: Readonly<TravelTakerProps>) => {
    const { validatePersonalInformation, validateAddress, validateContact, nextScreen } = this.state;
    const { history, policy, data } = this.props;
    const productInternalName = policy.product.products_internalName

    let isValidData = validatePersonalInformation.isValidated && ((productInternalName && productInternalName === PRODUCT_TYPE.TRAVEL_WELCOME) || validateAddress.isValidated);
    if (this.state.physicalPerson && isValidData) {
      isValidData = validateContact.isValidated;
    }

    if (nextScreen && isValidData && productInternalName) {
      history.push(`/contratacion/nueva/travel/${productInternalName}/asegurados/${data && data.insureds && data.insureds.length > 0 ? 0 : ''}`);
    }

    if (Object.keys(prevProps.policy.product).length === 0 && Object.keys(policy.product).length !== 0) {
      await this.getCoverages()
    }
  }

  async getCoverages() {
    const { setLoading, policy, coverages} = this.props;
    const productCoverageName: string = policy.product.products_coverageName;
    let coveragesAux: IHealthCoverage[] = [] 

    if (coverages && coverages[productCoverageName]) {
      coveragesAux = formatCoveragesTravel(coverages[productCoverageName])
    } else {
      if(productCoverageName){
        const resCoverages = await HealthService.getCoveragesFromDB(setLoading, true, productCoverageName)
        .catch((error) => {
          this.setState({
            modalErrorShow: true,
            modalErrorText: `Se ha producido un error al obtener las coberturas. Vuelva a intentarlo más tarde.`
          });
          throw error.statusText;
        });
        coveragesAux = formatCoveragesTravel(resCoverages)
      }
    }
    this.setState({ coverages: coveragesAux })
  }

  receiveTakerAddress(takerAddress: AddressData) {
    let { addressData } = this.state;
    addressData = takerAddress;
    this.setState({ addressData });
  }

  receiveTakerContact(takerContact: ContactData) {
    let { contactData } = this.state;
    contactData = takerContact;
    this.setState({ contactData });
  }

  receiveInsuredPerson(takerPerson: PersonalData) {
    let { personalData } = this.state;
    personalData = takerPerson;
    this.setState({ personalData });
  }

  receiveTakerPolicy(takerPolicy: TravelPolicyDataInterface) {
    let { policyData } = this.state;
    policyData = takerPolicy;
    this.setState({ policyData });
  }

  errorScrollTo() {
    const { validatePersonalInformation, validateAddress, validateContact } = this.state;
    let element = ''
    if (!validatePersonalInformation.isValidated) {
      element = 'data-personal'
    }
    else if (!validateAddress.isValidated) {
      element = 'data-address'
    }
    else if (!validateContact.isValidated) {
      element = 'data-contact'
    }
    if (element !== '') {

      const section = document.getElementById(element);
      if (section) {

        const pixelY = section.offsetTop
        if (pixelY > 0) window.scrollTo(0, pixelY)

      }
    }
  }

  async addTaker() {
    const { policy } = this.props

    if (this.props.isReadOnly) {
      this.props.history.push(TravelHireMenuHelper.getNextStepNavigationPath(StepsTravel.DatosAsegurados, policy.product.products_internalName));
    } else {

      if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true)
        this.setState({ cancelSignModalShow: true })
      else {
        await this.validateAndNextScreen()
      }
    }
  }

  async validateAndNextScreen() {

    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true) {
      await this.props.removeSign()
    }

    if (this.props.isReadOnly) {
      this.setState({ nextScreen: true })
    }
    else {

      await this.toValidate();
      const { validatePersonalInformation, validateAddress, validateContact, validatePolicyData, } = this.state;
      if (validatePersonalInformation.isValidated && validatePolicyData.isValidated && validateAddress.isValidated && validateContact.isValidated) {
        const { personalData, addressData, contactData, policyData } = this.state;
        let taker = {
          taker: true,
          ...personalData,
          ...contactData,
          address: addressData,
          policy: policyData,
        }

        if (this.state.physicalPerson === false &&
          this.props.data.insureds &&
          this.props.data.insureds.length >= 1 &&
          this.props.data.insureds[0].sameAsTaker === true &&
          this.props.data.insureds[0].physicalPerson === true) {
          this.props.data.insureds[0].sameAsTaker = false;
          this.props.data.insureds[0].physicalPerson = true
        }
        else if (this.props.data.insureds && this.props.data.insureds.length >= 1 && this.props.data.insureds[0].docNumber === personalData.docNumber && this.state.physicalPerson === true) {
          this.props.data.insureds[0].sameAsTaker = true;
        }

        //Si es la primera vez que se crea el tomador o se modifica el documendo de identidad 
        if (this.props.data.notFirstRGPDCall !== undefined) {
          this.props.data.notFirstRGPDCall = !(Object.keys(this.props.data.taker).length === 0 ||
            this.props.data.taker.docNumber !== this.state.personalData.docNumber)
            ? this.props.data.notFirstRGPDCall
            : false;
        }
        await this.props.policy.setTravelData(taker);
        await this.props.saveHiring();
        this.setState({ nextScreen: true })

      } else {
        this.errorScrollTo()
      }
    }
  }

  toValidate = async () => {
    let {
      validatePersonalInformation,
      validatePolicyData,
      validateAddress,
      validateContact
    } = this.state

    validatePersonalInformation.validateRequest = true
    validatePolicyData.validateRequest = true
    validateAddress.validateRequest = true
    validateContact.validateRequest = true

    this.setState({
      validatePersonalInformation,
      validatePolicyData,
      validateAddress,
      validateContact
    });
  }

  handlePersonChange(value: any) {
    if (value === 'physical') {
      this.setState({ physicalPerson: true });
    }
    else {
      this.setState({ physicalPerson: false });

    }
  }

  onComponentAnyChange() {
    const { anyChange } = this.state;
    if (!anyChange)
      this.setState({ anyChange: true });
  }

  onPreviousClick(force: boolean = false) {
    if (force || !this.state.anyChange) {
      this.props.history.push("/contratacion/nueva");
    } else {
      this.setState({ previousModalShow: true });
    }
  }

  render() {
    const {
      validatePersonalInformation,
      validatePolicyData,
      validateAddress,
      validateContact,
      physicalPerson,
      cancelSignModalShow,
      coverages
    } = this.state
    const { policy } = this.props
    const productInternalName = policy.product.products_internalName
    
    return (
      <TravelTakerDataContainer>
        <div className="colSin-100">
          <div className="data-flow-status">
            <div className="data-flow-status-title">
              <h2>DATOS DEL TOMADOR</h2>
            </div>
          </div>

          <PolicyData
            validate={validatePolicyData}
            onValidated={(taker: TravelPolicyDataInterface) => this.receiveTakerPolicy(taker)}
            data={this.props.data}
            isReadOnly={this.props.isReadOnly}
            mediator={this.props.mediator}
            selectedMediatorCode={this.props.selectedMediatorCode}
            coverages={coverages}
          />
          <PersonalInformation
            validate={validatePersonalInformation}
            onValidated={(taker: PersonalData) => this.receiveInsuredPerson(taker)}
            handlePersonChange={(value: any) => this.handlePersonChange(value)}
            setLoading={this.props.setLoading}
            insured={false}
            insureds={this.props.data.insureds}
            taker={this.props.data.taker}
            insuredIndex={-1}
            isReadOnly={this.props.isReadOnly}
            onAnyChange={() => { this.onComponentAnyChange(); }}
            currentTravelStep={TravelHireMenuHelper.getStepFromNavigationRoute(this.props.history.location.pathname, productInternalName)}
            physicalPersonValue={physicalPerson}
            policyModal={() => { }}
          />
          <HealthAddressFormItem
            validate={validateAddress}
            onValidated={(taker: AddressData) => this.receiveTakerAddress(taker)}
            setLoading={this.props.setLoading}
            physicalPerson={physicalPerson}
            taker={this.props.data.taker.address}
            insuredIndex={-1}
            isReadOnly={this.props.isReadOnly}
            onAnyChange={() => { this.onComponentAnyChange(); }}
          />
          <div>
            <HealthContactFormItem
              validate={validateContact}
              onValidated={(taker: ContactData) => this.receiveTakerContact(taker)}
              taker={this.props.data.taker}
              insuredIndex={-1}
              isReadOnly={this.props.isReadOnly}
              onAnyChange={() => { this.onComponentAnyChange(); }}
            />
          </div>
        </div>

        <div className="colSin-100 topMargin20 bottomMargin20">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => { this.onPreviousClick(); }}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                text="Siguiente"
                onClick={() => this.addTaker()}
              />
            </div>
          </div>
        </div>

        <LeftInfoModal
          img={images.IconWarningYellow}
          mainTitle={"¿Seguro que desea volver atrás sin guardar?"}
          mainText={"Perderá todos los cambios realizados."}
          buttonText="Ir atrás"
          closeButtonText="Cancelar"
          close={() => this.setState({ previousModalShow: false })}
          active={this.state.previousModalShow}
          onClickAccept={() => this.setState({ previousModalShow: false }, () => this.onPreviousClick(true))}
          className="error"
        />
        <LeftInfoModal
          img={images.IconWarningYellow}
          className="error"
          mainTitle={"¿Seguro que desea continuar?"}
          mainText={"Se cancelará la firma previamente solicitada"}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ cancelSignModalShow: false })}
          active={cancelSignModalShow}
          onClickAccept={() => {
            this.setState({ cancelSignModalShow: false }, () => this.validateAndNextScreen())
          }}
        />
        <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={() => this.setState({ modalErrorShow: false })} />
            </div>

          </div>
        </ErrorModalContainer>
      </TravelTakerDataContainer>
    );
  }


}

export default withCoverages(withPolicy(withGeneral(withRouter(TravelTakerDataLayout))));
