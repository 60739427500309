import GeneralServices from "./GeneralServices";
import { BaseServiceRequestDto } from "./dto/request/BaseServiceRequest.dto";

interface IProductFieldsRequest extends BaseServiceRequestDto {
  productName: string;
}

class FlowServices {
  getSimulationProductFields = async ({ setLoading, loader, productName }: IProductFieldsRequest) => {
    try {
      const response = await GeneralServices.get({
        loader,
        setLoading,
        endpoint: `flow/simulationFlow?productName=${productName}`,
      });

      return {
        ok: true,
        data: response.data,
      };
    } catch (error) {
      return {
        error: true,
        message: "Error",
      };
    }
  };

  getHiringProductFields = async ({ setLoading, loader, productName }: IProductFieldsRequest) => {
    try {
      const response = await GeneralServices.get({
        loader,
        setLoading,
        endpoint: `flow/hiringFlow?productName=${productName}`,
      });
      return {
        ok: true,
        data: response.data,
      };
    } catch (error) {
      return {
        error: true,
        message: "Error",
      };
    }
  };
}

export default new FlowServices();
