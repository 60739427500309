import styled from "styled-components";
import { colors } from "../../assets";

export const GuideLineContainer = styled.div`
  display: flex;
  align-items: center;
  .dot {
    width: 6px;
    height: 6px;
    background: ${colors["PR-001-100"]};
    display: inline-block;
    margin-right: 54px;
    border-radius: 50%;
    position: relative;
    transition: all 0.3s;
    z-index: 9;
    &:last-child {
      margin-right: 0;
    }

    &.clickable {
      cursor: pointer;
    }

    &.done {
      background: ${colors["SC-001-100"]};
      position: relative;
      z-index: 29;

      &:after {
        content: "";
        position: absolute;
        z-index: -9;
        top: 50%;
        left: calc(100% + 4px);
        transform: translateY(-50%);
        height: 1px;
        background: ${colors["SC-001-100"]};
        width: 46px;
      }
    }

    &.active {
      background: ${colors["SC-001-100"]};
      width: 14px;
      height: 14px;
      position: relative;

      &:after {
        display: block;
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        background: #ffffff;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        transform: translate(-50%, -50%);
        z-index: 29;
      }
    }
  }
`;
