import GeneralServices from "./GeneralServices";

class TravelCountriesServices {

    getAllTravelCountries = async (args: any) => {
        try {
            const response = await GeneralServices.get({
                setLoading: args.setLoading,
                loader: args.loader === false ? false : true,
                endpoint: `travelCountries/allTravelCountries`,
            });
            return response.data;
        } catch (err) {
            return [
                {
                    value: '', 
                    label:''
                }
            ];
        }
    };

    getTravelCountriesByProduct = async (args: any) => {
        try {
            const response = await GeneralServices.get({
                setLoading: args.setLoading,
                loader: args.loader === false ? false : true,
                endpoint: `travelCountries?product=${args.product}`,
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    };

}

export default new TravelCountriesServices();