import React from 'react';
import CheckboxModalLayout from './checkbox-modal-layout';

export default class CheckboxModalScreen extends React.Component<any, {}> {
    render(){
        const {img, className, onClickAccept, mainTitle, mainText, active, checkConditions, enableAcceptButton} = this.props;

        return(
            <CheckboxModalLayout enableAcceptButton={enableAcceptButton} img={img} mainTitle={mainTitle} mainText={mainText} className={className} onClickAccept={()=> onClickAccept()} active={active} checkConditions={checkConditions}/>
        )
    }
}