import React, { Component } from "react";
import { MainModalContainer } from "./main-modal-style";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { images } from "../../assets";
import H4Text from "../components-text/h4-text";
import MainButton from "../main-button";
import WebText from "../components-text/web-text";
import HeadsTitleText from "../components-text/heads-title-text";
import ButtonText from "../components-text/button-text";
import InputBox from "../input-box/input-box";

interface table {
  title: string;
  content: any;
}

interface MainModalProps extends RouteComponentProps {
  className?: string;
  img?: string;
  onClickAccept?: Function;
  mainTitle: string;
  mainText: string;
  buttonText: string;
  active?: boolean;
  close: Function;
  centered?: boolean;
  table?: any;
  buttonClassName?: string;
  inputCount?: string;
  hideIcon?: boolean;
}

interface MainModalState {
  active: string;
}

class MainModalLayout extends Component<MainModalProps, MainModalState> {
  readonly state: MainModalState;

  public static defaultProps = {
    active: false,
    img: images.IconSearchBlue,
    onClickAccept: () => { },
    className: "",
    mainTitle: "",
    mainText: "",
    buttonText: "",
    close: () => { },
    centered: false,
    hideIcon: false
  };

  constructor(props: MainModalProps) {
    super(props);

    this.state = {
      active: props.active ? "active" : "",
    };
  }

  componentDidUpdate(prevProps: MainModalProps) {
    if (prevProps.active !== this.props.active) {
      this.setState({ active: this.props.active ? "active" : "" });
    }
  }

  close() {
    this.setState({ active: "" });
  }

  open() {
    this.setState({ active: "active" });
  }

  acceptMainModal() {
    const { onClickAccept, close } = this.props;
    if (onClickAccept) {
      onClickAccept();
    }
    close();
  }

  render() {
    const {
      img,
      mainTitle,
      mainText,
      className,
      buttonText,
      close,
      centered,
      table,
      buttonClassName,
      inputCount,
      hideIcon
    } = this.props;
    const { active } = this.state;

    return (
      <MainModalContainer centered={centered} className={`${active} ${className} `}>
        <div className="main-info-modal-container">
          <div className="main-info-modal-close">
            <img src={images.IconCloseGrey} onClick={() => close()} />
          </div>

          <div className="main-info-modal-main">
            <div className="main-info-modal-main-title">
              { !hideIcon && <div className="main-info-modal-icon-bg">
                  <img src={img} />
                </div>
              }
              <div className="main-info-modal-text" style={{ marginTop: hideIcon ? '30px' : '0' }}>
                <H4Text>{mainTitle}</H4Text>
              </div>
            </div>

            <div className="main-info-modal-main-content">
              {!centered && (
                <div className="main-info-modal-main-copy">
                  <WebText>{mainText}</WebText>
                </div>
              )}

              {inputCount && (
                <div className="main-info-modal-main-input">
                  <InputBox required className={"rounded"} placeholder={inputCount} value="" onChange={() => { }} />
                </div>
              )}

              {table && (
                <div className="main-info-modal-table">
                  <div className="main-info-modal-table-title">
                    <HeadsTitleText>{table.title}</HeadsTitleText>
                  </div>
                  <div className="main-info-modal-table-content">
                    {table.content.map((item: any, index: number) => (
                      <div className="main-info-modal-table-content__item">
                        <div className="main-info-modal-table-content__item__title">
                          <WebText>{item.title}</WebText>
                        </div>
                        <div className="main-info-modal-table-content__item__text">
                          <ButtonText>{item.value}</ButtonText>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="main-info-modal-button">
                <MainButton type={buttonClassName} text={buttonText} onClick={() => this.acceptMainModal()} />
              </div>
            </div>
          </div>
        </div>
      </MainModalContainer>
    );
  }
}

export default withRouter(MainModalLayout);
