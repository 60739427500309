import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const InputFileContainer = styled.div`
  width: 100%;
  &.dark-blue-label {
    .input-file-message-label {
      color: #1948a0;
      padding-left: 0;
    }
  }

  &.error {
    .input-box-main-field {
      color: ${colors["ER-001-100"]};
    }
  }

  .input-file-message-drag {
    width: 100%;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    margin-bottom: 12px;

    input {
      position: absolute;
      width: 100%;
      opacity: 0;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 2;
    }
    &__dashed {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      text-align: center;
      padding: 10px;
      margin-bottom: 20px;

      &::before {
        content: '';
        position: absolute;
        border: 4px dashed #bcc5d7;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
      }
    }
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &__text {
      pointer-events: none;
      max-width: 200px;
      color: ${colors['NE-002/100']};
      z-index: 2;
      span {
        font-weight: 500;
      }
    }
  }

  .input-file-message-disclaimer {
    margin-bottom: 32px;
    color: ${colors['NE-002/100']};
  }
  .input-file-message-bottom {
    display: flex;
    justify-content: space-between;
    &__left {
      display: flex;
      .input-file-message-button {
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .input-file-message-button {
    width: 140px;
  }

  .input-file-message-label {
    color: ${colors["PR-001-50"]};
    text-transform: uppercase;
  }

  .input-file-message-counter {
    margin-left: 32px;
    display: flex;
    align-items: center;
    line-height: normal;
    font-size: 16px;
    text-align: right;
    color: #004f8b;

    &__icon {
      margin-top: 3px;
    }
  }

  .input-file-message-select  {
    margin-bottom: 40px;
  }

  .input-file-message-label  {
    color: ${colors["PR-001-50"]};
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }

  &.required {
    .input-file-message-label  {
      padding-left: 12px;
      position: relative;
      &::before  {
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 8px;
        background: ${colors['PR-001/100']};
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .input-box-main-field {
    position: relative;
    width: 100%;
    padding: 13px 18px 9px;
    padding-right: 30px;
    transition: all 0.2s ease-in-out;
    margin-bottom: 4px;
    color: ${colors["PR-001-100"]};
    border-bottom: 1px solid ${colors["PR-001-100"]};
    cursor: pointer;
    height: 48px;

    p {
      cursor: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:hover {
      background: #f0fafd;
    }

    &:focus {
      background: #ffffff;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
      outline: none;
      border-bottom: none;
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 14px;
    z-index: 10;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }
  .input-box-error {
    color: ${colors["ER-001-100"]};
  }
`;


