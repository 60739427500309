import React from "react";
import moment from "moment";

import MainButton from "../../../components/main-button";
import { MainTableContainer } from "../../../components/main-table/main-table-style";
import BodyText from "../../../components/components-text/body-text";
import { images } from "../../../assets";
import { NotificationType } from "../helpers";
import { MEDIATOR, STATUS } from "../../../constants/communications";

interface NotificationsTableProps {
  notifications: NotificationType[];
  editNotification: Function;
  setIdToRemove: Function;
  lineasNegocio: { label: string; value: string }[];
  types: { label: string; value: string }[];
}

function NotificationsTable({
  notifications,
  editNotification,
  setIdToRemove,
  lineasNegocio,
  types,
}: NotificationsTableProps) {
  const tableColumns = [
    "Descripción de la notificación",
    "Prioridad",
    "Tipo mediador",
    "Ramo",
    "Tipo",
    "Fecha de inicio",
    "Fecha de fin",
    "Opciones",
  ];

  const getLineaNegocioLabel = (id: string) => {
    const linea = lineasNegocio.find((linea) => {
      return linea.value === id.toString();
    });
    return linea && linea.label ? linea.label : "";
  };

  const getMediatorLabel = (allowed: string) => {
    const mediatorList = Object.values(MEDIATOR);
    const mediatorObj = mediatorList.find((med) => med.value === allowed);
    return mediatorObj && mediatorObj.label ? mediatorObj.label : "Todos";
  };

  const getTipoLabel = (id: string) => {
    const typeObj = types.find((elem) => elem.value === id);
    return typeObj && typeObj.label ? typeObj.label : "";
  };

  const getNotificationClassName = (notification: NotificationType): string => {
    if (notification.status === STATUS.DRAFT.toString()) return "draft";
  
    const today = moment().startOf('day');
    const dateInit = moment(notification.dateInit, 'DD/MM/YYYY');
    const dateEnd = moment(notification.dateEnd, 'DD/MM/YYYY');
  
    if (today >= dateInit && today <= dateEnd) return "visible";
    if (today > dateEnd) return "past";
    if (today < dateInit) return "future";
    return "";
  }

  return (
    <>
      <MainTableContainer className="table noZebra uploadedTable">
        <thead>
          <tr>
            {tableColumns.map((column, index) => (
              <th key={`column-${index}`}>
                <BodyText className="dimmed-mini-title">
                  <p>{column}</p>
                </BodyText>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {notifications.map((notification) => {
            return (
              <tr
                className={`${getNotificationClassName(notification)} main-table-row`}
                key={notification.id}
              >
                <td>
                  <BodyText className="dimmed-mini-body">
                    <p>
                      {notification.resumen}
                      <br />
                      {notification.description}
                    </p>
                  </BodyText>
                </td>
                <td>
                  <BodyText className="dimmed-mini-body">
                    <p>{notification.priority}</p>
                  </BodyText>
                </td>
                <td>
                  <BodyText className="dimmed-mini-body">
                    <p>{getMediatorLabel(notification.mediator)}</p>
                  </BodyText>
                </td>
                <td>
                  <BodyText className="dimmed-mini-body">
                    <p>{getLineaNegocioLabel(notification.lineaNegocio)}</p>
                  </BodyText>
                </td>
                <td>
                  <BodyText className="dimmed-mini-body">
                    <p>{getTipoLabel(notification.type)}</p>
                  </BodyText>
                </td>
                <td>
                  <BodyText className="dimmed-mini-body">
                    <p>{notification.dateInit}</p>
                  </BodyText>
                </td>
                <td>
                  <BodyText className="dimmed-mini-body">
                    <p>{notification.dateEnd}</p>
                  </BodyText>
                </td>
                <td>
                  <div className="action-buttons">
                    <MainButton
                      onClick={() => editNotification(notification)}
                      icon={true}
                      iconImg={images.IconEyewhite}
                    />
                    <MainButton
                      onClick={() => setIdToRemove(notification.id)}
                      icon={true}
                      iconImg={images.IconDeleteWhite}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </MainTableContainer>
      
      <div className="legend">
        <div className="item">
          <div className="square visible" />
          <p>Publicación visible</p>
        </div>
        <div className="item">
          <div className="square future" />
          <p>Publicación en futuro</p>
        </div>
        <div className="item">
          <div className="square past" />
          <p>Publicación pasada</p>
        </div>
        <div className="item">
          <div className="square draft" />
          <p>Borrador</p>
        </div>
      </div>
    </>
  );
}

export default NotificationsTable;
