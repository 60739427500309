import { withRouter } from "react-router";
import { withGeneral } from "../../../context/mixin/with-general";
import { withPolicy } from "../../../context/mixin/with-policy";
import { IProtectionDataProps } from "./interfaces";
import React, { useEffect, useState } from 'react';
import moment from "moment";
import { useProtectionData } from "./hooks/useProtectionData";
import { ProtectionDataContainer } from "./protection-data-style";
import InputBox from "../../input-box/input-box";
import SelectBox from "../../select-box/select-box";
import MainButton from "../../main-button";
import { ErrorModalContainer } from "../../error-modal/error-modal-style";
import LeftInfoModal from "../../left-info-modal";
import { images } from "../../../assets";
import { SENDA_INFORMADO, YES_NO_CONSTANT } from "../../../constants";
import { SIGN_HIRING_STATUS_TYPE } from "../../../constants/hiring";
import CrossService from "../../../services/CrossService";


const CrossProtectionData = (props: IProtectionDataProps) => {

  const {
    validateData,
    initializeProtectionDataFromProps,
    changeDataTransferValue,
    changeThirdPartyValue,
    changeThirdPartyCallsValue,
    changeAllValuesDataProtection,
    postDataProtection,
    saveSendaResponse,
    getProtectionData,
    recoverSendaInfo,
    anyChange,
    consentDate,
    dataTransfer,
    thirdParty,
    thirdPartyCalls,
  } = useProtectionData({})
  const [isReadOnly, setIsReadOnly] = useState((props.policy && props.policy.data && props.policy.data.isReadOnly) || false)
  const [canPostProtectionData, setCanPostProtectionData] = useState(false);
  const [modal, setModal] = useState({ visible: false, text: '' })
  const [cancelSignModalShow, setCancelSignModalShow] = useState(false);


  function formatPD(val: any): string {
    return val !== '' ? val : "IS"
  }

  useEffect(() => {

    const setCanPostData = () => {
      setCanPostProtectionData(true)
    }
    /**
     * Get data function
     */
    const getData = async () => {
      const response = await CrossService.getDataProtection(props.setLoading, true, props.cotizacion, props.area);
      if (response && response.body && response.body.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS !== '') {
        const respuestaParse = {
          fechaConsentimiento: response.body.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS.FECHA_CONSENTIMIENTO,
          cesionDatos: response.body.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS.CESION_DATOS,
          publicidadEscritaTerceros: response.body.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS.PUBLICIDAD_ESCRITA_TERCEROS,
          publicidadTelefonica: response.body.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS.PUBLICIDAD_TELEFONICA_TERCEROS
        }
        const { fechaConsentimiento, cesionDatos, publicidadEscritaTerceros, publicidadTelefonica } = respuestaParse;
        changeAllValuesDataProtection(formatPD(fechaConsentimiento), formatPD(cesionDatos), formatPD(publicidadEscritaTerceros), formatPD(publicidadTelefonica))
        saveSendaResponse(formatPD(fechaConsentimiento), formatPD(cesionDatos), formatPD(publicidadEscritaTerceros), formatPD(publicidadTelefonica))
        setCanPostData()
      } else {
        changeAllValuesDataProtection(moment().format('DD/MM/YYYY').toString(), '', '', '')
        setCanPostData()
        props.data.RGPD_tomador_nuevo = true;
        // Si no se recuperan datos              
      }
    }

    // Data from props
    let { data } = props;
    if (data) {
      initializeProtectionDataFromProps(data)
    }

    // Can be onlyView, (Vida, Mascotas...). Is not necessary recover data 
    if (!props.onlyView) {
      if (data && data.taker && !data.taker.physicalPerson) {
        props.history.push(props.nextStep);
      }
      // Eliminamos comprobar primera llamada
      getData().catch((error) => {
        setModal({ visible: true, text: error })
      })
    } else {
      setCanPostData()
    }
  }, []);


  function isDisabled(): boolean | undefined {
    return !canPostProtectionData || thirdParty.disabled || isReadOnly
  }


  async function validateAndSave() {
    setCancelSignModalShow(false)
    if (props.data && props.data.signData && props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && anyChange === true) {
      props.removeSign()
    }

    let error = validateData()
    if (!error) {

      let resultPost = { error: false, errorText: '' }
      if (canPostProtectionData) {
        resultPost = await postDataProtection(props.setLoading, props.cotizacion, props.area);
      }
      if (!resultPost.error) {
        props.policy.setProtectionData(getProtectionData());
        await props.saveHiring();
        props.history.push(props.nextStep)
      } else {
        // Controlamos el error que puede dar en el caso de que existan datos persistidos en senda, en ese caso bloquemos la operativa y recuperamos los datos que habia
        // además permitimos continuar sin enviar la información
        if (resultPost.errorText.includes("Ya existen datos de Proteccion de Datos para el Tomador")) {
          setCanPostProtectionData(false);
          recoverSendaInfo();
          setModal({ visible: true, text: "La protección de datos ya ha sido informada, se ha recuperado la información desde senda y puede continuar el proceso" })
        } else {
          setModal({ visible: true, text: resultPost.errorText })
        }
      }
    }
  }

  async function toValidate() {
    if (!props.onlyView) {
      if (props.data && props.data.signData && props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && anyChange === true) {
        setModal({ visible: false, text: '' })
        return false;
      }
      else {
        await validateAndSave()
      }
    } else {
      let error = validateData()
      if (!error) {
        props.policy.setProtectionData(getProtectionData());
        props.newStep();
      }
    }
  }
  return (

    <ProtectionDataContainer>
      <div className="content-wrapper">
        <div className="personal-data-wrapper">
          <div className="data-flow-status">
            <div className="data-flow-status-title">
              <h2>PROTECCIÓN DE DATOS</h2>
            </div>
          </div>
          <div className="personal-data-container">
            <div className="personal-data-row">
              <div className="personal-data-item col-33">
                <div className="inputBox">
                  <InputBox
                    type={"text"}
                    placeholder="Fecha de consentimiento"
                    value={consentDate.value}
                    disabled={isDisabled()}
                    required={consentDate.required}
                  />
                </div>
              </div>

              <div className="personal-data-item col-33">
                <div className="selectBox">
                  <SelectBox
                    placeholder="Seleccionar"
                    required={dataTransfer.required}
                    errorCode={dataTransfer.errorCode}
                    disabled={isDisabled()}
                    optionsText={!isDisabled() ? YES_NO_CONSTANT : SENDA_INFORMADO}
                    optionKey={"value"}
                    optionValue={"label"}
                    initialValue={dataTransfer.value}
                    defaultValue={dataTransfer.value}
                    onChange={(value: string) => {

                      changeDataTransferValue(value)
                    }}
                  />
                  <label>{"Cesión de datos"}</label>
                </div>
              </div>
            </div>

            <div className="data-title">
              <h5>ENVÍO DE PUBLICIDAD ESCRITA</h5>
            </div>

            <div className="personal-data-row">

              <div className="personal-data-item col-33">
                <div className="selectBox">
                  <SelectBox
                    placeholder="Seleccionar"
                    required={thirdParty.required}
                    errorCode={thirdParty.errorCode}
                    disabled={isDisabled()}
                    optionsText={!isDisabled() ? YES_NO_CONSTANT : SENDA_INFORMADO}
                    optionKey={"value"}
                    optionValue={"label"}
                    initialValue={thirdParty.value}
                    defaultValue={thirdParty.value}
                    onChange={(value: string) => {
                      changeThirdPartyValue(value);
                    }}
                  />
                  <label>{"Envío de publicidad de terceros"}</label>
                </div>
              </div>

            </div>

            <div className="data-title">
              <h5>ACCIÓN COMERCIAL POR TELÉFONO</h5>
            </div>

            <div className="personal-data-row">
              <div className="personal-data-item col-33">
                <div className="selectBox tooLongLabelHandler">
                  <SelectBox
                    placeholder="Seleccionar"
                    errorCode={thirdPartyCalls.errorCode}
                    disabled={isDisabled()}
                    optionsText={!isDisabled() ? [...YES_NO_CONSTANT, { value: '-', label: 'Seleccionar' }] : [...SENDA_INFORMADO, { value: '-', label: 'Seleccionar' }]}
                    optionKey={"value"}
                    optionValue={"label"}
                    initialValue={thirdPartyCalls.value}
                    defaultValue={thirdPartyCalls.value}
                    onChange={(value: string) => {
                      changeThirdPartyCallsValue(value);
                    }}
                  />
                  <label>{"Cesión de llamadas promocionando productos de terceros"}</label>
                </div>
              </div>
            </div>
          </div>

          <div className="personal-data-container">
            <div className="personal-data-row">
              <div className="personal-data-buttons">
                <div className="personal-data-buttons__item">
                  <MainButton
                    withTab={true}
                    icon={true}
                    type={"white"}
                    iconImg={images.IconArrowLeftBlue}
                    text="Atrás"
                    onClick={() => (!props.onlyView) ? props.history.push(props.previousStep) : props.goBack()}
                  />
                </div>
                <div className="personal-data-buttons__item">
                  <MainButton
                    withTab={true}
                    text="Siguiente"
                    onClick={() => toValidate()}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <ErrorModalContainer className={`${modal.visible && `active`}`}>
        <div className="recover-modal">
          <div className="server-error">
            <p>{modal.text}</p>
          </div>
          <div className="button-error">
            <MainButton text="Aceptar" onClick={() => setModal({ visible: false, text: '' })} />
          </div>
        </div>
      </ErrorModalContainer>
      <LeftInfoModal
        img={images.IconWarningYellow}
        className="error"
        mainTitle={"¿Seguro que desea continuar?"}
        mainText={"Se cancelará la firma previamente solicitada"}
        buttonText="Continuar"
        closeButtonText="Cancelar"
        close={() => setCancelSignModalShow(false)}
        active={cancelSignModalShow}
        onClickAccept={() => {
          validateAndSave()
        }}
      />
    </ProtectionDataContainer>
  )
}

export default withPolicy(withGeneral(withRouter(CrossProtectionData)));

