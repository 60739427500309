import styled from "styled-components";
import { colors } from "../../../../../assets";
import { images } from "../../../../../assets/images";

export const ResultDataContainer = styled.div`


.custom-file-upload-button {
  background-color: #e4e4e4;

  > input[type="file"] {
    display:none;

    ~ div {
      background-color: ${colors["PR-001-100"]};
      color: #fff;
      border-radius: 0px 3px 3px 0px;

      &:before {

        content: url(${images.IconSearchWhite});
        position: absolute;
        width: 15px;
        height: 15px;
        left: 15px;
      }
    }

  &:disabled {
    ~ div {
      background-color: #e2e2e2;
      border-radius: 0px 3px 3px 0px;
      color: #ababab;

      &:before {
        content: url(${images.IconSearchGray});
      }
    }
  }        
}
}

.custom-file-upload {
display: flex;

input[type="text"] {
  border-radius: 3px 0px 0px 3px;
}
}


.personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      margin-bottom: 24px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
}

  .col-100 {
    width: 100%;
  }

  .col-80 {
    width: 80%;
  }

  .col-70 {
    width: 70%;
  }
  
  .col-50 {
    width: 50%;
  }

  .col-40 {
    width: 40%;
  }

  .col-33 {
    width: 33%;
  }

  .col-30 {
    width: 30%;
  }

  .col-25 {
    width: 25%;
  }

  .col-20 {
    width: 20%;
  }

  .result-subitem-row-title{
    p {
      font-weight: bold;
      border-bottom: 1px solid ${colors["PR-001-100"]};
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  .result-simulation-special-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .result-simulation-special-item {
    display: flex;
    p{
        font-weight: bold;
    }
  }

  .result-simulation-special-subitem {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  .result-hiring-special-container {
    display: flex;
    margin-bottom: 20px;
  }

  .result-hiring-special-item {
    float: left;
    display: flex;
    flex-direction: column;
    margin-left: 75px;
  }

  .result-hiring-special-first-item {
    p{
        font-weight: bold;
    }
  }

  .result-hiring-special-row {
    width: 100%;
    display: flex;
    float: left;
    margin-bottom: 20px;
  }

  .result-hiring-special-subitem {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  .result-simulation-special-subitem-title {
    text-transform: uppercase;
    color: ${colors["TX-001-30"]};
    margin-bottom: 6px;
  }

  .result-simulation-special-subitem-content {
    color: ${colors["TX-001-100"]};
  }

  .result-hiring-special-subitem-content {
    p{
      color: ${colors["PR-001-100"]};
    }
  }

  .result-simulation-special {
    padding: 40px;
    margin-bottom: 24px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
  }

  .result-simulation-special-title {
    color: ${colors["PR-001-100"]};
    border-bottom: 1px solid ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 24px;

    p.price {
      float: right;
      font-size: 36px;
      position: relative;
      top: -37px;
      color: #004f8b;

      span {
        font-size: 28px;
      }
    }
  }

  .result-hiring-special-title {
    color: ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  .result-hiring-special-title2 {
    margin-bottom: 12px;
  }

  .tabs {
    overflow: hidden;
    padding: 40px;

    .tab {
      width: 100%;
      overflow: hidden;
      position: relative;

      .delete {
        width: 24px;
        height: 24px;
        line-height: 24px;
        position: absolute;
        left: calc(100% - 170px);
        top: 18px;
        background-color: #3f3f3f;
        color: #fff;
        border-radius: 100%;
        text-align: center;
        cursor: pointer;
        z-index: 2;

        &:before {
          content: url(${images.IconCloseWhite});
        }

        &:hover {
          background-color: #004a8f;
        }
      }

      .tab-label {
        display: flex;
        justify-content: space-between;
        padding: 1em;
        background: #fff;
        border: 1px solid #f4f4f4;

        &:after {
          content: "❯";
          width: 30px;
          height: 30px;
          text-align: center;
          transition: all 300ms;
          transform: rotate(90deg);
          border-radius: 100%;
          text-align: center;
          line-height: 30px;
          margin-top: -5px;
        }

        span {
          transition: all 300ms;
        }
      }

      .tab-content {
        height: 0;
        padding: 0 1em;
        color: #2c3e50;
        background: #fff;
        transition: all 300ms;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
      }

      .tab-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        background: #2c3e50;
        cursor: pointer;

        &:hover {
          background: #1a252f;
        }
      }

      input[type="checkbox"] {
        position: absolute;
        right: 2px;
        opacity: 0;
        width: 60px;
        height: 60px;
        cursor: pointer;
        z-index: 1;
        margin: 0;

        &:hover {
          + {
            .tab-label:after {
              background-color: #efefef;
            }
          }
        }

        &:checked {
          + {
            .tab-label {
              font-weight: bold;
            }

            .tab-label:after {
              transform: rotate(270deg);
            }
          }

          ~ .tab-content {
            min-height: 70px;
            height: auto;
            padding: 25px 15px 20px;
            opacity: 1;
            box-shadow: 0px 4px 10px -3px #e0e0e0;
          }
        }
      }
    }
  }

  .checkbox-container input:not(:checked) {
    color: ${colors["TX-001-50"]};
  }

  &.mandatory {
    .checkbox-container input:checked ~ .checkmark-container {
      background: #f7f7f7;

      .checkmark-item {
        background: ${colors["TX-001-80"]};
      }
    }
  }

  &.optional {
    .checkbox-container input:checked ~ .checkmark-container {
      background: ${colors["SU-001-10"]};

      .checkmark-item {
        background: ${colors["SU-001-100"]};
      }
    }
  }

  .result-simulation-item {
    padding: 40px;
    margin-bottom: 24px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
    
    &.float {
      width: 100%;
      display: block;
      float: left;
      margin-bottom: 50px;
      margin-top: 15px;
    }

    .result-simulation-item-content {
      h5 {
        color: ${colors["PR-001-100"]};
        padding-left: 30px;
        position: relative;
        font-size: 24px;

        &:before {
          content: url(${images.IconCheckBlue});
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-right: 8px;
          position: absolute;
          left: 0;
          top: 1px;
        }
      }
    }
  }

  .checkbox-container {
    display: flex;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 15px;

    &.noPointer {
      cursor: initial;
    }

    div:nth-of-type(2) {
      line-height: 25px;
      font-size: 16px;
    }

    &:hover {
      input ~ .checkmark {
        background-color: rgba(0, 79, 139, 0.3);
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark-container {
        background: #e8f9f5;
        justify-content: flex-end;

        .checkmark-item {
          background: #40caa9;
        }

        img {
          max-width: 14px;
        }
      }
    }
  }

  .input-box-error-file {
    color: ${colors["ER-001-100"]};
    margin-top: -15px;

    p {
      color: ${colors["ER-001-100"]};
    }
  }

  .checkmark-container-readonly {
    min-width: 24px;
    background: #ffffff;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 14px;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;

    img {
      max-width: 14px;
    }

    .checkmark-item {
      background: #3f3f3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      width: 20px;
      height: 20px;    
    }
  }

  .checkmark-item {
    background: ${colors["TX-001-30"]};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    width: 20px;
    height: 20px;    
  }

  .checkmark-container {
    min-width: 24px;
    background: #ffffff;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 14px;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;

    img {
      max-width: 14px;
    }
  }

  .table-head-beneficiaries {
    justify-content: flex-start;
    th {
      text-align: left;
    }
  }

  .table-body-beneficiaries {
    justify-content: flex-start;
    td {
      padding-right: 30px;
      text-align: left;
    }
  }

`;
