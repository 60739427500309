import React, { Component } from "react";
import { CalendarBoxContainer } from "./calendar-box-style";
import { images } from "../../assets/images";
import TooltipBox from "../tooltip-box/tooltip-box";
import NoteText from "../components-text/note-text";
import BodyText from "../components-text/body-text";
import CalendarItem from "../calendar-item/calendar-item";
import LabelText from "../components-text/label-text";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import { colors } from "../../assets";
import Cleave from "cleave.js/react";
import moment from "moment";
import dateFns from "date-fns";
import { LabelTooltip } from "../form";

interface CalendarBoxProps {
  className?: string;
  disabled?: boolean;
  icon?: string;
  iconDisabled?: string;
  errorCode?: string;

  forbidFutureDates?: boolean;
  minAge?: number;
  maxAge?: number;
  maxDate?: string;
  minDate?: string;
  required?: boolean;

  initialValue: string;
  labelText?: string;
  placeholder?: string;
  onChange: (value: string, error?: boolean, errorCode?: string) => void;

  withTooltip?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;
  tooltipIconTop?: string,
  tooltipIconLeft?: string,

  health?: boolean;
  //Este prop se añade para quitar la funcionalidad que no permite borrar la fecha sin romper el componente en otros productos
  deleteIcon?: boolean;
}

interface CalendarBoxState {
  focus: boolean;
  showCalendar: boolean;
  showTooltip: boolean;
  value: string;
  errorCodeState: string;
  error_code: string;
}

class CalendarBox extends Component<CalendarBoxProps, CalendarBoxState> {
  private toggleContainer: any;

  constructor(props: CalendarBoxProps) {
    super(props);
    this.state = {
      focus: false,
      showCalendar: false,
      showTooltip: false,
      value: "",
      errorCodeState: '',
      error_code: "",
    };
    this.toggleContainer = React.createRef();
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  public static defaultProps = {
    icon: images.IconCalendarBlue,
  };

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    const { value } = this.state;
    const { initialValue } = this.props;
    if (
      initialValue &&
      typeof initialValue === "string" &&
      initialValue.toLowerCase() !== "invalid date" &&
      value === ""
    ) {
      this.setState({ value: initialValue });
    } else {
      this.setState({ value: "" });
    }

    window.addEventListener("click", this.onClickOutsideHandler);
  };

  componentDidUpdate(prevProps: CalendarBoxProps) {
    const { value } = this.state;
    const { initialValue } = this.props;

    if (
      initialValue &&
      initialValue !== "Invalid date" &&
      initialValue !== prevProps.initialValue
    ) {
      this.setState({ value: initialValue, errorCodeState: '' });
    } else if (prevProps.initialValue !== initialValue && initialValue === "") {
      this.setState({ value: "" });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClickOutsideHandler);
  }

  onClickOutsideHandler(event: Event) {
    if (
      this.state.showCalendar &&
      !this.toggleContainer.current.contains(event.target)
    ) {
      this.onToggleCalendar(false, this.state.value);
    }
  }

  onToggleCalendar(show: boolean, value: string) {
    const { minAge, minDate, maxDate } = this.props;
    if (value === "" || value.toLowerCase() === "invalid date") {
      this.setState({ showCalendar: show, value: "" });
    } else {
      const { onChange } = this.props;
      const isCorrect = moment().diff(
        moment(value, "DD/MM/YYYY", true),
        "years",
        true
      );

      let isValid = true;
      if (moment(value, "DD/MM/YYYY", true).isValid()) {
        if (minDate) {
          let min = moment(new Date(minDate)).add(1, "day").toISOString(true);
          isValid = moment(value, "DD/MM/YYYY").isAfter(moment(min));
          if (!isValid) {
            value = dateFns.format(min, "DD/MM/YYYY");
            isValid = true;
          }
        }
        if (maxDate) {
          isValid =
            isValid && moment(value, "DD/MM/YYYY").isBefore(moment(maxDate));
          if (!isValid) {
            value = dateFns.format(maxDate, "DD/MM/YYYY");
            isValid = true;
          }
        }
      }

      if (isCorrect && isValid) {

        if (this.props.health) {
          this.setState({ errorCodeState: isNaN(isCorrect) ? 'invalid-date' : '' });
        }

        const correctValue = minAge && isCorrect < minAge ? "" : value;
        if (correctValue === "") {
          this.setState({ error_code: "underage" });
        } else {
          this.setState({ error_code: "" });
        }
        this.setState({ showCalendar: show, value: value }, () => {
          if (value !== "" && !this.props.health) onChange(value);
          if (this.props.health) onChange(correctValue, this.state.errorCodeState ? true : false, this.state.errorCodeState);
        });
      } else if (value !== "") {
        this.setState({ showCalendar: show, value: value }, () => { });
      } else {
        this.setState({ showCalendar: show });
      }
    }
  }

  onDelete() {
    this.props.onChange('');
    this.setState({ value: '', })
  }

  render() {
    let {
      className,
      disabled,
      errorCode,
      forbidFutureDates,
      icon,
      iconDisabled,
      initialValue,
      labelText,
      maxAge,
      maxDate,
      minAge,
      minDate,
      deleteIcon,
      placeholder,
      required,
      tooltipText,
      tooltipTitle,
      withTooltip,
      tooltipIconTop,
      tooltipIconLeft,
    } = this.props;
    const { showCalendar, showTooltip, value, error_code: error_code, errorCodeState } = this.state;

    const error_text = errorCode || error_code || errorCodeState ? ERROR_MESSAGES[errorCode || error_code || errorCodeState] : "";

    return (
      <CalendarBoxContainer
        ref={this.toggleContainer}
        className={`${className} ${showCalendar ? "show-calendar" : ""} ${disabled ? "disabled" : ""
          } ${errorCode || error_code || (this.props.health && errorCodeState) ? "error" : ""} ${value !== "" ? "complete" : ""} ${required ? "required" : ""
          }`}
      >
        {labelText && (
          <div className="input-box-topbar">
            <div className="input-box-topbar-label">
              <LabelText color={showCalendar ? colors["SC-001-100"] : ""}>
                <p>{labelText}</p>
              </LabelText>
            </div>

            {withTooltip && (
              <div
                className="input-box-topbar-icon"
                onClick={() =>
                  this.setState((prevState: CalendarBoxState) => ({
                    showTooltip: !prevState.showTooltip,
                  }))
                }
              >
                <img
                  src={disabled ? images.IconInfoGrey : images.IconInfoBlue}
                />
                <div className={"input-box-tooltip "}>
                  <TooltipBox
                    active={showTooltip}
                    tooltipTitle={tooltipTitle}
                    tooltipText={tooltipText}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="input-box-main">
          <div className="input-box-main-field">
            <BodyText>
              <Cleave
                value={value}
                tabIndex={disabled ? -1 : 0}
                placeholder={placeholder}
                onBlur={() => this.onToggleCalendar(false, initialValue)}
                onFocus={() => this.onToggleCalendar(false, "")}
                options={{
                  date: true,
                  delimiter: "/",
                  datePattern: ["d", "m", "Y"],
                }}
                onChange={(e) => this.onToggleCalendar(false, e.target.value)}
              />
              <label>{placeholder}</label>
              {!labelText && withTooltip && (
                <LabelTooltip
                  showTooltip={() => this.setState({ showTooltip: true})}
                  hideTooltip={() => this.setState({ showTooltip: false })}
                  tooltipTitle={tooltipTitle}
                  tooltipText={tooltipText}
                  isTooltipVisible={showTooltip}
                  tooltipIconTop={tooltipIconTop}
                  tooltipIconLeft={tooltipIconLeft}
                />
              )}
            </BodyText>
          </div>
          {deleteIcon &&
            <div className="input-box-icon cross"
              onClick={() => this.onDelete()}
            >
              <img
                src={
                  images.IconCloseBlue
                }
              />
            </div>}
          <div
            className="input-box-icon"
            onClick={() => {
              this.onToggleCalendar(!showCalendar, value);
            }}
          >
            <img
              src={
                disabled
                  ? iconDisabled
                  : showCalendar
                    ? images.IconCalendarLightBlue
                    : icon
              }
            />
          </div>
        </div>
        {/*!disabled && (
          <input
            onFocus={() => this.onToggleCalendar(true, "")}
            onBlur={() => this.onToggleCalendar(false, "")}
          />
        )*/}
        <div className="calendar-popup">
          <CalendarItem
            forbidFutureDates={forbidFutureDates}
            minAge={minAge}
            maxAge={maxAge}
            maxDate={maxDate}
            minDate={minDate}
            onSelect={(value, closeCalendar) => {
              this.onToggleCalendar(!closeCalendar, value);
            }}
            date={value}
          />
        </div>

        {(errorCode || error_code) && (
          <div className="input-box-error">
            <NoteText>
              <p>{`* ${error_text}`}</p>
            </NoteText>
          </div>
        )}
      </CalendarBoxContainer>
    );
  }
}

export default CalendarBox;
