import moment from "moment";
import React, { Component } from "react";
import { PersonalInformationContainer } from "./personal-information-style"
import SelectBox from "../../../../../../components/select-box/select-box";
import CalendarBox from "../../../../../../components/calendar-box/calendar-box";
import DicotomicButton from "../../../../../../components/dicotomic-button";
import InputBox from "../../../../../../components/input-box/input-box";
import SEX from "../../../../../../constants/sex.json";
import DOC_TYPES from "../../../../../../constants/doc_types";
import CheckboxItemLayout from "../../../../../../components/checkbox-item/checkbox-item-layout";
import SearchBox from "../../../../../../components/search-box-mediadores/search-box";
import ConstantsServices from "../../../../../../services/ConstantsServices";
import { isValidCif, isValidDate, isValidDocumentID } from "../../../../../../utils/validation";
import { DefaultPersonalData, DefaultProfessionData, DefaultValueFormHealth, PersonalDataDto, DefaultNationalityFormHealth } from "./personal-information-dto/personal-information-dto";
import { calcAge, convertDateToString } from "../../../../../../utils/utility";
import YesNo from "../../../../../../constants/typeYesNo.json"
import { INSURED_MAX_AGE_TRAVEL } from "../../../../../../constants/optionsTravelPolicy";


export interface PersonalData {
  name: string;
  lastname1: string;
  lastname2?: string;
  birthDate?: string;
  civilStatus: string;
  gender: string;
  nationality: string | undefined;
  nationalityLabel: string;
  docType: string;
  docNumber: string;
  passportDate: Date | undefined;
  relationship?: string;
  profession: {
    value: string,
    code: string,
    other: string,
  };
  sameAsTaker: boolean;
  physicalPerson: boolean;
  tc2?: string;
  reta?: string;
  responsable?: string;
  hasAnotherInsurance: boolean;
  numPoliza: string;
}

interface PersonalInformationProps {
  validate: {
    validateRequest: boolean;
    isValidated: boolean;
  },
  onValidated: (person: PersonalData) => void;
  onCleanForm?: boolean;
  insured?: boolean;
  handlePersonChange?: any;
  onCheckTakerData?: any;
  setLoading?: Function;
  isReadOnly?: boolean;
  onAnyChange: Function;
  insureds?: PersonalData[];
  taker?: PersonalData;
  insuredIndex: number;
  currentTravelStep: number;
  physicalPersonValue?: boolean;
  policyModal: Function
}

interface PersonalInformationState {
  person: PersonalDataDto;
  stateDisabled: boolean;
  professionsFiltered: any[];
  countries: any[];
  countriesFiltered: any[];
  previousData: PersonalDataDto;
  docTypes: { [key: string]: string; }[];
  prevDocType: string;
}

class PersonalInformation extends Component<PersonalInformationProps, PersonalInformationState> {
  _searchWaiter: any = null;

  constructor(props: PersonalInformationProps) {
    super(props)
    
    this.state = {
      person: !props.insured
        ? this.getPersonalDataDto(props.taker)
        : (props.insureds && props.insuredIndex > -1 && props.insureds.length > 0
          ? this.getPersonalDataDto(props.insureds[props.insuredIndex], props.insureds[props.insuredIndex].sameAsTaker)
          : DefaultPersonalData()),
      stateDisabled: props.isReadOnly ? props.isReadOnly : false,
      professionsFiltered: [],
      countries: [],
      countriesFiltered: [],
      previousData: DefaultPersonalData(),
      docTypes: !props.insured && props.taker && props.taker.birthDate
        ? this.getDocTypes(props.taker.birthDate)
        : (props.insureds && props.insuredIndex > -1 &&
          props.insureds[props.insuredIndex] &&
          props.insureds[props.insuredIndex].birthDate !== undefined
          ? this.getDocTypes(props.insureds[props.insuredIndex].birthDate)
          : DOC_TYPES.insuredsHealth),
      prevDocType: !props.insured && props.taker
        ? props.taker.docType
        : (props.insureds && props.insuredIndex > -1 && props.insureds.length > 0
          ? props.insureds[props.insuredIndex].docType
          : ''),
    }
  }
  checkDate(date: any) {
    var formats = [
      moment.ISO_8601,
    ];
    let isValid = moment(date, formats, true).isValid();
    if (isValid) {
      return moment(date).format("DD/MM/YYYY")
    } else {
      return date
    }

  }
  getPersonalDataDto(personData?: PersonalData, disabled: boolean = false): PersonalDataDto {

    const country = this.state && this.state.countries && this.state.countries.length > 0 && this.state.countries.find((country) => country.value === 'ESPA')

    const newPerson = (!personData || Object.keys(personData).length === 0) ? DefaultPersonalData() : {
      name: this.getValueFormHealth(personData.name, disabled),
      lastName1: this.getValueFormHealth(personData.lastname1, disabled),
      lastName2: this.getValueFormHealth(personData.lastname2, disabled),
      birthDate: this.getValueFormHealth(personData.birthDate ? this.checkDate(personData.birthDate) : '', disabled),
      civilStatus: this.getValueFormHealth(personData.civilStatus, disabled),
      docType: this.getValueFormHealth(personData.docType, disabled),
      docNumber: calcAge(moment(personData.birthDate).format("DD/MM/YYYY")) > 16 && personData.docType === 'MENOR'
        ? this.getValueFormHealth('', disabled) :
        this.getValueFormHealth(personData.docNumber, disabled),
      sex: this.getValueFormHealth(personData.gender, disabled),
      nationality: DefaultNationalityFormHealth(disabled),
      nationalityLabel: personData.nationalityLabel ? personData.nationalityLabel : country ? country.value : '',
      relationship: this.getValueFormHealth(personData.relationship, disabled),
      profession: {
        value: personData.profession ? personData.profession.value : "",
        code: personData.profession ? personData.profession.code : "",
        errorCode: "",
        disabled: false,
        other: {
          value: personData.profession && personData.profession.value === 'OTRA' && personData.profession.other ? personData.profession.other : "",
          errorCode: "",
          disabled: false,
        }
      },
      passportDate: this.getValueFormHealth(personData.passportDate ? moment(personData.passportDate).format("DD/MM/YYYY") : "", disabled),
      sameAsTaker: personData.sameAsTaker,
      physicalPerson: personData.physicalPerson !== undefined ?
        personData.physicalPerson :
        this.props.physicalPersonValue ?
          this.props.physicalPersonValue :
          true,
      tc2: this.getValueFormHealth(personData.tc2, disabled),
      reta: this.getValueFormHealth(personData.reta, disabled),
      responsable: this.getValueFormHealth(personData.responsable, disabled),
      hasAnotherInsurance: personData.hasAnotherInsurance,
      numPoliza: this.getValueFormHealth(personData.numPoliza, disabled),
    };

    return newPerson;
  }

  getValueFormHealth(value: string | undefined, disabled: boolean) {
    return {
      value: value ? value : "",
      errorCode: '',
      disabled: disabled,
      code: ''
    };
  }

  getDocTypes(birthDate: string | Date | undefined): { [key: string]: string; }[] {
    var age = 0;
    if (birthDate)
      if (typeof birthDate === 'string')
        age = calcAge(birthDate);
      else
        age = calcAge(convertDateToString(birthDate));

    return age < 18 || this.props.isReadOnly ? DOC_TYPES.insuredsHealth : DOC_TYPES.othersHealth;
  }

  componentWillReceiveProps(nextProps: PersonalInformationProps) {
    let { validate } = this.props;

    if (nextProps.validate.validateRequest) {
      validate.validateRequest = false;
      validate.isValidated = this.validateForm();
    }
    else if (nextProps.onCleanForm) {
      this.cleanForm();
    }
    // else if (this.props.insuredIndex === -1 && nextProps.insured && nextProps.insureds && nextProps.insureds.length === 0 ) {
    //   const data = JSON.parse(JSON.stringify(nextProps.taker));
    //   this.completeInsuredData(data, true);
    // }
    else if (this.props.insuredIndex !== nextProps.insuredIndex) {
      const insuredIndex: number = nextProps.insureds && nextProps.insureds.length === 1 ? 0 : nextProps.insuredIndex;
      if (nextProps.insuredIndex !== -1 && nextProps.insureds &&
        nextProps.insureds.length > 0 && nextProps.insureds[insuredIndex].sameAsTaker) {

        const data = JSON.parse(JSON.stringify(nextProps.taker));
        data.profession = JSON.parse(JSON.stringify(nextProps.insureds[insuredIndex].profession));
        data.hasAnotherInsurance = JSON.parse(JSON.stringify(nextProps.insureds[insuredIndex].hasAnotherInsurance));
        data.numPoliza = nextProps.insureds[insuredIndex].numPoliza ? JSON.parse(JSON.stringify(nextProps.insureds[insuredIndex].numPoliza)) : "";
        this.completeInsuredData(data, true);
      }
      else {
        this.completeInsuredData(insuredIndex === -1 ? nextProps.taker : nextProps.insureds ? nextProps.insureds[insuredIndex] : undefined);
      }
    } else if (nextProps.insureds && nextProps.insureds.length > 0 && !this.state.person.nationalityLabel) {
      let { countries, person } = this.state;

      const country = countries.find((country) => country.value === 'ESPA')
      if (country) {
        person.nationality.value = country.label;
        person.nationality.code = country.value;
        person.nationalityLabel = country.value;
        this.setState({ person });
      }
    }
  }

  async componentDidMount() {
    try {
      let { countries, docTypes, person } = this.state;
      const { insured, insuredIndex, insureds, taker } = this.props;


      countries = await ConstantsServices.getCountries({
        setLoading: this.props.setLoading,
      });

      countries.map((country: any) => country.label = country.label.toUpperCase());

      if (taker && taker.nationality) {
        const country = countries.find((country) => country.value === taker.nationality)
        person.nationality.value = country.label;
        person.nationality.code = country.value;
        person.nationalityLabel = country.value;
      }
      
      if (!insured && taker)
        docTypes = this.getDocTypes(taker.birthDate);
      else if (insureds && insuredIndex > -1)
        docTypes = this.getDocTypes(insureds[insuredIndex].birthDate);

      if (insured && insureds && insureds.length === 0 && taker && taker.physicalPerson) {
        this.setState({ person });
      }

      this.setState({ countries, docTypes });
    } catch (error) {
      console.error(error);
    }
  }

  async handleValueChange(input: string, value: any) {
    let { person, docTypes, prevDocType } = this.state;
    const { taker, setLoading } = this.props;
    if (!(input === 'sex' && (taker && (!taker.physicalPerson || value === taker.gender))) &&
      !(input === 'docType' && (taker && (!taker.physicalPerson || value === taker.docType)))) {
      this.props.onAnyChange();
    }

    switch (input) {
      case 'name':
      case 'lastName1':
      case 'lastName2':
      case 'birthDate':
      case 'docNumber':
      case 'sex':
      case 'docType':
      case 'passportDate':
        value = value.toUpperCase()
        Object.keys(person).forEach((k: string) => {
          if (k === input) {
            person[k].errorCode = '';
            person[k].value = value;

            if (input === 'docType') {
              person.docNumber.disabled = value === "MENOR" ? true : person.docNumber.disabled;
              person.docNumber = (person.docNumber.disabled && value === "MENOR") ? this.getValueFormHealth(undefined, true) : value !== "MENOR" && prevDocType === "MENOR" ? this.getValueFormHealth(undefined, false) : person.docNumber;
              prevDocType = value;
              if(isValidDocumentID(person.docNumber.value, person.docType.value === 'MENOR' ? 'NIF' : person.docType.value)) person.docNumber.errorCode = '';
            }

            if (input === 'birthDate') {
              var age = calcAge(value);
              if (!isNaN(age) && age < 18) {
                docTypes = DOC_TYPES.insuredsHealth
                person.docType = docTypes.find(element => element.value === person.docType.value) ? person.docType : this.getValueFormHealth(undefined, false);
                prevDocType = person.docType.value;
              }
              else if (!isNaN(age) && age > 17) {
                docTypes = DOC_TYPES.othersHealth
                person.docType = docTypes.find(element => element.value === person.docType.value) ? person.docType : this.getValueFormHealth(undefined, false);
                person.docNumber.disabled = person.docType.value === "MENOR";
                prevDocType = person.docType.value;
              }
            }
          }
        });
        break;
      case 'nationality':
        person.nationality.value = value.label;
        person.nationality.code = value.value;
        person.nationality.errorCode = '';
        person.nationalityLabel = value.label;
        person.lastName2.errorCode = '';
        break;
      default:
        break;
    }

    this.setState({ person, docTypes, prevDocType });
  }

  validateForm(): boolean {
    let { person } = this.state;
    let noErrors = true;

    if (person.physicalPerson) {
      if (person.name.value.length === 0) {
        person.name.errorCode = 'required';
        noErrors = false;
      }
      if (person.lastName1.value.length === 0) {
        person.lastName1.errorCode = 'required';
        noErrors = false;
      }
      if (person.birthDate.value.length === 0) {
        person.birthDate.errorCode = 'required';
        noErrors = false;
      }
      else {

        if (moment(person.birthDate.value, "DD/MM/YYYY").isAfter(moment())) {
          person.birthDate.errorCode = 'invalid-date';
          noErrors = false;
        }

      }
      if (person.nationality.value.length === 0) {
        person.nationality.errorCode = 'required';
        noErrors = false;
      }
      if (person.docType.value !== 'MENOR' && person.docNumber.value.length === 0) {
        person.docNumber.errorCode = 'required';
        noErrors = false;
      }
      else if (!isValidDocumentID(person.docNumber.value, person.docType.value === 'MENOR' ? 'NIF' : person.docType.value)) {

        if (person.docType.value !== 'MENOR' && person.docNumber.value.length > 0) {
          person.docNumber.errorCode = 'invalid-document-dni';
          noErrors = false;
        }

        if (person.docType.value !== 'MENOR' && person.docType.value === 'NIF' && person.docNumber.value.length === 9) {
          person.docNumber.errorCode = 'invalid-document-dni';
          noErrors = false;
        }
        else {
          if (person.docType.value !== 'MENOR') {
            person.docNumber.errorCode = 'invalid-format';
            noErrors = false;
          }
        }
      }
      else if (this.props.insured && this.props.insureds && this.props.insureds.length) {
        this.props.insureds.forEach((insured: any, index: number) => {
          if (insured.docNumber === person.docNumber.value && index !== this.props.insuredIndex) {
            person.docNumber.errorCode = 'repeated-document-number';
            noErrors = false;
          }
        });
        if (!person.sameAsTaker && this.props.insured && this.props.taker && person.docNumber.value === this.props.taker.docNumber) {
          person.docNumber.errorCode = 'repeated-document-number-taker';
          noErrors = false;
        }
      }
      if (person.sex.value.length === 0) {
        person.sex.errorCode = 'required';
        noErrors = false;
      }
      if (person.docType.value.length === 0) {
        person.docType.errorCode = 'required';
        noErrors = false;
      }
      if (person.docType.value === 'PAS' && person.passportDate.value.length === 0) {
        person.passportDate.errorCode = 'required';
        noErrors = false;
      }
      if (this.props.insured && person.profession.value === 'OTRA') {
        if (person.profession.other.value.length === 0) {
          noErrors = false;
          person.profession.other.errorCode = 'required';
        }
      }
      if (this.props.insured && person.hasAnotherInsurance && person.numPoliza.value.length === 0) {
        person.numPoliza.errorCode = 'required';
        noErrors = false;
      }
      if (
        !this.props.insured &&
        !(person.docNumber.value === '') &&
        this.props.insureds
      ) {

        const insured = this.props.insureds.find((persona: PersonalData) => (
          persona.docNumber === person.docNumber.value && !persona.sameAsTaker
        ))

        if (insured) {
          noErrors = false;
          person.docNumber.errorCode = 'repeated-document-number-insured';
        }

      }
    }
    else {
      if (person.docType.value.length === 0) {
        person.docType.errorCode = 'required';
        noErrors = false;
      }
      if (!person.docNumber.value) {
        person.docNumber.errorCode = 'required';
        noErrors = false;
      }
      else if (person.docType.value === 'CIF' && !isValidCif(person.docNumber.value)) {
        person.docNumber.errorCode = 'invalid-format';
        noErrors = false;
      }
      if (!person.name.value) {
        person.name.errorCode = 'required';
        noErrors = false;
      }
    }

    if (noErrors) {
      if (this.props.onValidated) {
        if (person.physicalPerson) {
          if (person.docType.value === 'MENOR' && !person.docNumber.value && this.props.insureds) {
            let minorSequencial = 0;
            this.props.insureds.map((insured: any) => {
              if (insured.docType === 'MENOR' && insured.docNumber.length < 2) {
                if (insured.docNumber > minorSequencial) {
                  minorSequencial = insured.docNumber;
                }
              }
            });
            person.docNumber.value = String(Number(minorSequencial) + 1);
            person.docNumber.disabled = true;
          }

          this.props.onValidated(
            {
              name: person.name.value,
              lastname1: person.lastName1.value,
              lastname2: person.lastName2.value,
              birthDate: person.physicalPerson ? moment(person.birthDate.value, "DD/MM/YYYY").utcOffset(0, true).format("DD/MM/YYYY") : undefined,
              docType: person.docType.value,
              civilStatus: person.civilStatus.value,
              docNumber: person.docNumber.value,
              passportDate: isValidDate(person.passportDate.value) ? moment(person.passportDate.value, "DD/MM/YYYY").utcOffset(0, true).toDate() : undefined,
              gender: person.sex.value,
              nationality: person.nationality.code,
              nationalityLabel: person.nationality.value,
              relationship: person.relationship.value,
              profession: {
                value: person.profession.value,
                code: person.profession.code,
                other: person.profession.other.value
              },
              sameAsTaker: person.sameAsTaker,
              physicalPerson: person.physicalPerson,
              tc2: person.tc2.value,
              reta: person.reta.value,
              responsable: person.responsable.value,
              hasAnotherInsurance: person.hasAnotherInsurance,
              numPoliza: person.numPoliza.value
            }
          );
        }
        else {
          this.props.onValidated(
            {
              name: person.name.value,
              docNumber: person.docNumber.value,
              sameAsTaker: person.sameAsTaker,
              lastname1: "",
              lastname2: "",
              docType: person.docType.value,
              civilStatus: "",
              passportDate: undefined,
              gender: "",
              nationality: "",
              nationalityLabel: "",
              relationship: "",
              profession: {
                value: "",
                code: "",
                other: ""

              },
              physicalPerson: false,
              tc2: person.tc2.value,
              reta: person.reta.value,
              responsable: person.responsable.value,
              hasAnotherInsurance: false,
              numPoliza: ''
            }
          );
        }
      }
    }

    this.setState({ person });
    return noErrors;
  }

  cleanForm() {
    this.setState({
      person: DefaultPersonalData(),
      stateDisabled: false,
      previousData: DefaultPersonalData(),
      docTypes: DOC_TYPES.insuredsHealth,
      countriesFiltered: this.state.countries,
    });
  }

  completeInsuredData = async (personData?: PersonalData, isTakerData: boolean = false) => {
    let { person, docTypes, prevDocType } = this.state;
    person = this.getPersonalDataDto(personData, personData ? personData.sameAsTaker ? personData.sameAsTaker : isTakerData : false);

    if (isTakerData) {
      person.sameAsTaker = isTakerData;
    }

    docTypes = this.getDocTypes(person.birthDate.value);
    person.docType = docTypes.find(element => element.value === person.docType.value) ? person.docType : this.getValueFormHealth(undefined, false);
    prevDocType = person.docType.value;


    this.setState({ person, docTypes, prevDocType });
  }

  async completeFormTakerData(person: PersonalDataDto) {
    let getPreviousData = (person: PersonalDataDto) => {
      const previousData: PersonalDataDto = JSON.parse(JSON.stringify(person));

      previousData.birthDate.errorCode = "";
      previousData.name.errorCode = "";
      previousData.lastName1.errorCode = "";
      previousData.lastName2.errorCode = "";
      previousData.birthDate.errorCode = "";
      previousData.docNumber.errorCode = "";
      previousData.docType.errorCode = "";
      previousData.sex.errorCode = "";
      previousData.nationality.errorCode = "";
      previousData.relationship.errorCode = "";
      previousData.profession.errorCode = "";
      previousData.profession.other.errorCode = "";
      previousData.passportDate.errorCode = "";
      previousData.responsable.errorCode = "";
      previousData.numPoliza.errorCode = "";
      return previousData;
    }
    let { taker } = this.props;
    let { docTypes, prevDocType } = this.state;

    let previousData = getPreviousData(person);
    if (taker) {
      if (!this.props.insured) {
        if (person.physicalPerson) {
          this.props.handlePersonChange('physical');
        }
        else {
          this.props.handlePersonChange('legal');
        }
      }
      taker.numPoliza = previousData.numPoliza.value
      person = this.getPersonalDataDto(taker, this.props.insured ? this.props.insured : false);
      person.sameAsTaker = true;

      docTypes = this.getDocTypes(person.birthDate.value);
      person.docType = docTypes.find(element => element.value === person.docType.value) ? person.docType : this.getValueFormHealth(undefined, false);
      prevDocType = person.docType.value
      person.hasAnotherInsurance = previousData.hasAnotherInsurance
      person.numPoliza = previousData.numPoliza

      this.setState({ person: person, previousData, docTypes, prevDocType });
    }
  }

  async handleCheckTakerChange() {
    try {
      let { person } = this.state;
      person.sameAsTaker = !person.sameAsTaker;
      this.props.onCheckTakerData(person.sameAsTaker);

      if (person.sameAsTaker) {
        this.completeFormTakerData(person);
        this.props.onAnyChange();
      }
      else {
        this.getPreviousData(person);
        this.props.onAnyChange();
      }
    } catch (error) {
      console.error(error)
    }
  }

  getPreviousData(person: PersonalDataDto) {
    let { previousData, docTypes, prevDocType } = this.state;

    person.name = previousData.name;
    person.lastName1 = previousData.lastName1;
    person.lastName2 = previousData.lastName2;
    person.birthDate = previousData.birthDate;
    person.docType = previousData.docType;
    person.docNumber = previousData.docNumber;
    person.sex = previousData.sex;
    person.nationality = previousData.nationality;
    person.relationship = previousData.relationship;
    person.profession = previousData.profession;
    person.passportDate = previousData.passportDate;
    person.tc2 = previousData.tc2;
    person.reta = previousData.reta;
    person.responsable = previousData.responsable;
    previousData = DefaultPersonalData();

    docTypes = this.getDocTypes(person.birthDate.value);
    person.docType = docTypes.find(element => element.value === person.docType.value) ? person.docType : this.getValueFormHealth(undefined, false);
    prevDocType = person.docType.value;

    this.setState({ previousData, person, docTypes, prevDocType });
  }

  changeValueSearchBox(ev: any, arg1: string, arg2: string) {
    if (this._searchWaiter) {
      clearInterval(this._searchWaiter);
    }
    const { person } = this.state;
    const value: string = ev.target.value;

    // Reset searchBox values to default if delete prev data
    if (value.length < 1) {
      if (this.state.person.hasOwnProperty(arg2) && arg2 === "profession") {
        person.profession = DefaultProfessionData();
      }
      if (this.state.person.hasOwnProperty(arg2) && arg2 === "nationality") {
        person.nationality = DefaultValueFormHealth();
        person.nationalityLabel = '';
      }
      this.setState({ person });
    }

    if (value.length < 2) return;

    if (value.length >= 2) {
      switch (arg2) {
        case "nationality":
          this.setState({
            countriesFiltered: this.state.countries.filter((country) =>
              country.label
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            ),
          });
          break;
        default:
          break;
      }
    }
  }

  isFirstInsured = (): boolean => this.props.insuredIndex === 0 || (this.props.insuredIndex === -1 && (!this.props.insureds || this.props.insureds.length === 0));

  checkTomadorPhisicalToInsured = (): boolean => {
    const { currentTravelStep } = this.props;
    const { person } = this.state;
    if (currentTravelStep === 2) {
      return true;
    } else if (person.physicalPerson) {
      return true
    } else {
      return false;
    }
  }

  render() {
    const { person, docTypes, countriesFiltered, previousData } = this.state;
    const { isReadOnly } = this.props;
    return (

      <PersonalInformationContainer>
        <div className="content-wrapper bottomMargin20" ref={''}>
          <div className="personal-data-wrapper">
            <div className="data-title" id="data-personal">
              <h5>DATOS PERSONALES</h5>
            </div>
            {(this.props.currentTravelStep === 2 && this.props.taker && this.props.taker.physicalPerson && this.isFirstInsured() &&
              <div className="bottomMargin20">
                <CheckboxItemLayout
                  name='Los datos personales son los mismos que los del tomador'
                  checked={person.sameAsTaker}
                  onChange={() => this.handleCheckTakerChange()}
                  showError={false}
                  checkboxText='El tomador de la póliza es el asegurado'
                  errorText='Error'
                  disabled={isReadOnly}
                />
              </div>)}
            {(this.props.currentTravelStep === 1 && <div className="receip-radio-main-row">
              <div className={isReadOnly ? "radioBox disabled" : "radioBox"}>
                <label>
                  <input
                    type="radio"
                    disabled={isReadOnly}
                    name="person"
                    value={'personal'}
                    onChange={async () => {
                      this.props.handlePersonChange('physical');
                      person.docType = DefaultValueFormHealth();
                      person.lastName1.value = (person.lastName1.value === undefined ? '' : person.lastName1.value);
                      person.physicalPerson = true;
                      this.setState({ person });
                    }}
                    checked={person.physicalPerson}
                  />
                  <span>PERSONA FÍSICA</span>
                </label>
              </div>
              <div className={isReadOnly ? "radioBox disabled" : "radioBox"}>
                <label>
                  <input
                    type="radio"
                    name="person"
                    value={'legal'}
                    onChange={async () => {
                      this.props.handlePersonChange('legal');
                      person.docType = DefaultValueFormHealth();
                      person.physicalPerson = false;
                      this.setState({ person });
                    }}
                    checked={!person.physicalPerson}
                    disabled={isReadOnly}
                  />
                  <span>PERSONA JURÍDICA</span>
                </label>
              </div>
            </div>)}
            {this.checkTomadorPhisicalToInsured() ? (
              <div className='personal-data-fisica'>
                <div className="personal-data-row">
                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <InputBox
                        placeholder="Nombre"
                        required={true}
                        errorCode={person.name.errorCode}
                        disabled={person.name.disabled || isReadOnly}
                        value={person.name.value}
                        onChange={(value: string) => this.handleValueChange('name', value)}
                        type={"letters"}
                      />
                    </div>
                  </div>

                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <InputBox
                        placeholder="Apellido 1"
                        required={true}
                        errorCode={person.lastName1.errorCode}
                        disabled={person.lastName1.disabled || isReadOnly}
                        value={person.lastName1.value}
                        onChange={(value: string) => this.handleValueChange('lastName1', value)}
                        type={"letters"}
                      />
                    </div>
                  </div>
                </div>

                <div className="personal-data-row">
                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <InputBox
                        placeholder="Apellido 2"
                        required={false}
                        errorCode={person.lastName2.errorCode}
                        disabled={person.lastName2.disabled || isReadOnly}
                        value={person.lastName2.value}
                        onChange={(value: string) => this.handleValueChange("lastName2", value)
                        }
                        type={"letters"}
                      />
                    </div>
                  </div>

                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <CalendarBox
                        initialValue={person.birthDate.value}
                        placeholder="Fecha de nacimiento"
                        forbidFutureDates
                        minAge={this.props.insured ? 0 : 18}
                        //maxAge={this.props.insured ? INSURED_MAX_AGE_TRAVEL : INSURED_MAX_AGE_TRAVEL - 18}
                        maxDate={moment().toISOString()}
                        required={true}
                        disabled={person.birthDate.disabled || isReadOnly}
                        errorCode={person.birthDate.errorCode}
                        onChange={(value: string) => this.handleValueChange('birthDate', value)}
                        health={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="personal-data-row">
                  <div className="personal-data-item col-50">
                    <div className="personal-data-item col-25">
                      <DicotomicButton
                        required={true}
                        labelText="Sexo"
                        options={SEX}
                        withTab={true}
                        disabled={person.sex.disabled || isReadOnly}
                        initialValue={person.sex.value}
                        onChange={(value: string) => this.handleValueChange('sex', value)}
                        errorCode={person.sex.errorCode}
                      />
                    </div>
                  </div>
                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <SearchBox
                        onChange={(ev: any) =>
                          this.changeValueSearchBox(
                            ev,
                            "contact",
                            "nationality"
                          )
                        }
                        labelText=""
                        optionsText={countriesFiltered}
                        optionKey={"value"}
                        optionValue={"label"}
                        disabled={person.nationality.disabled || isReadOnly}
                        initialValue={person.nationality.value || ""}
                        required={true}
                        errorCode={person.nationality.errorCode}
                        onSelect={(value: any) =>
                          this.handleValueChange("nationality", value)
                        }
                      />
                      <label>Nacionalidad</label>
                    </div>
                  </div>
                </div>

                <div className="personal-data-container">
                  <div className="personal-data-row">
                    <div className="personal-data-item col-50">
                      <div className="selectBox">
                        <SelectBox
                          placeholder="Documento de identidad"
                          required={true}
                          errorCode={person.docType.errorCode}
                          optionsText={this.props.currentTravelStep !== 1 ? docTypes : DOC_TYPES.takerHealth}
                          optionKey={"value"}
                          optionValue={"label"}
                          initialValue={person.docType.value}
                          defaultValue={person.docType.value}
                          disabled={person.docType.disabled || isReadOnly}
                          onChange={(value: string) => this.handleValueChange('docType', value)}
                        />
                        <label>{"Documento de identidad"}</label>
                      </div>
                    </div>
                    <div className="personal-data-item col-50">
                      <div className="inputBox">
                        <InputBox
                          placeholder="Nº de documento"
                          required={person.docType.value !== 'MENOR'}
                          errorCode={person.docNumber.errorCode}
                          maxLength={9}
                          value={person.docType.value !== 'MENOR' ? person.docNumber.value : ''}
                          disabled={person.docNumber.disabled || person.docType.value === 'MENOR' || isReadOnly}
                          onChange={(value: string) => this.handleValueChange('docNumber', value)}
                          type={person.docType.value === 'NIF' ?
                            "NIF" : person.docType.value === 'NIE' ?
                              "NIE" : person.docType.value === 'PAS' ?
                                "PAS" : "NIF"}
                        />
                      </div>
                    </div>

                    {(person.docType.value === 'PAS') &&
                      <div className="personal-data-item col-50">
                        <div className="inputBox">
                          <CalendarBox
                            initialValue={person.passportDate.value}
                            placeholder="Fecha de caducidad del pasaporte"
                            minDate={moment().toISOString()}
                            required={true}
                            disabled={person.passportDate.disabled || isReadOnly}
                            errorCode={person.passportDate.errorCode}
                            onChange={(value: string) => this.handleValueChange('passportDate', value)}
                            health={true}
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            ) : (
              <div className='personal-data-juridica'>
                <div className="personal-data-row">
                  <div className="personal-data-item col-33">
                    <div className="selectBox">
                      <SelectBox
                        placeholder="Documento de identidad"
                        required={true}
                        errorCode={person.docType.errorCode}
                        optionsText={DOC_TYPES.takerTravelLegalPerson}
                        optionKey={"value"}
                        optionValue={"label"}
                        initialValue={person.docType.value}
                        defaultValue={person.docType.value}
                        disabled={person.docType.disabled || isReadOnly}
                        onChange={(value: string) => this.handleValueChange('docType', value)}
                      />
                      <label>{"Documento de identidad"}</label>
                    </div>
                  </div>
                  <div className="personal-data-item col-33">
                    <div className="inputBox">
                      <InputBox
                        placeholder="Nº de documento"
                        required={true}
                        errorCode={person.docNumber.errorCode}
                        disabled={person.docNumber.disabled || isReadOnly}
                        value={person.docNumber.value}
                        onChange={(value: string) => this.handleValueChange("docNumber", value)
                        }
                        type={"text"}
                      />
                    </div>
                  </div>
                  <div className="personal-data-item col-33">
                    <div className="inputBox">
                      <InputBox
                        placeholder="Razón Social"
                        required={true}
                        errorCode={person.name.errorCode}
                        disabled={person.name.disabled || isReadOnly}
                        value={person.name.value}
                        onChange={(value: string) => this.handleValueChange("name", value)
                        }
                        type={"numbersletter"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </PersonalInformationContainer>
    )
  }
}

export default PersonalInformation;
