import styled from "styled-components";

export const HealthPriceContainer = styled.div`
.radioBoxButton {

    label {
      position: relative;
      padding: 32px 6px;
      border-radius: 6px;
      display: inline-block;
      cursor: pointer;
      margin: 8px 0;
      text-transform: uppercase;
      color: #3f3f3f;
      -webkit-tap-highlight-color: transparent;
      width: 100%;
      height: 148px;
      background-color: #fff;
      text-align: center;
      border: 1px solid #ddd;
      transition: all 300ms;
      overflow: visible;

      &:hover {
        box-shadow: 0 5px 10px #d8d8d8;
        z-index: 1;
      }

      input {
        vertical-align: middle;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: none;
        border: 0;
        background-color: #fff;
        box-shadow: inset 0 0 0 1px #3f3f3f;
        appearance: none;
        padding: 0;
        margin: 0;
        transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
        pointer-events: none;

        &:focus {
          outline: none;
        }

        &:checked {
          box-shadow: inset 0 0 0 6px #004a8f;

          ~ span {
            font-weight: 600;
            color: #004a8f;
          }

          ~ span:nth-of-type(2) {
            padding-top: 25px;
            font-size: 36px;
            color: #004a8f;
          }

          ~ i {
            border: 1px solid #004a8f;
            box-shadow: 0 5px 10px #d8d8d8;
          }
        }
      }

      span {
        vertical-align: middle;
        display: inline-block;
        line-height: 20px;
        padding: 0 8px;
        font-size: 16px;
        transition: all 300ms;
      }

      span:nth-of-type(2) {
        display: block;
        padding-top: 25px;
        font-size: 24px;
      }

      i {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        border: 1px solid transparent;
        border-radius: 6px;
        text-transform: initial;
        padding-top: 115px;
        font-style: normal;
        font-size: 14px;
        color: #f90;
      }
    }
  }

  .radioBoxButton.disabled {
    filter: contrast(50%) brightness(135%) grayscale(1);
    pointer-events: none;
  }
`;
