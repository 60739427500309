import React from "react";
import { Body02TextContainer } from "./body-02-text-styles";

interface Body02TextProps {
    children: any;
    className?: string;
    color?: string;
}

const Body02Text = ({children = '', className='', color=''}:Body02TextProps) => {
        return (
        <Body02TextContainer color={color} className={className}>
            {children}
        </Body02TextContainer>
    )
} 
export default Body02Text; 