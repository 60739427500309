import React, { Component } from "react";
import dateFns, { differenceInYears } from "date-fns";
import es from "date-fns/locale/es";
import { images } from "../../assets/images";
import { CalendarItemContainer } from "./calendar-item-style";
import { capitalizeFirstLetter } from "../../utils";
import moment, { months } from "moment";

interface CalendarItemProps {
  onSelect: (value: string, closeCalendar: boolean) => void;
  forbidFutureDates?: boolean;
  minAge?: number;
  maxAge?: number;
  maxDate?: string;
  minDate?: string;
  date?: string;
}

class CalendarItem extends Component<CalendarItemProps, any> {
  state = {
    showYears: false,
    showMonths: false,
    currentDate: new Date()
  };

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    const { currentDate } = this.state;
    const { minAge, minDate, date } = this.props;
    if (minAge) {
      const startDate = dateFns.subYears(currentDate, minAge);
      this.setState({ currentDate: startDate });
    }
    if (minDate) {
      //this.setState({ currentDate: moment(minDate).toDate() });
    }
    if (date && date !== '') {
      this.setState({ currentDate: moment(date, 'DD/MM/YYYY').toDate() })
    }
  };

  componentDidUpdate(prevProps: CalendarItemProps) {
    const { date } = this.props;
    if (date && prevProps.date !== date && date !== '') {
      this.setState({ currentDate: moment(date, 'DD/MM/YYYY').toDate() })
    }
    else if (prevProps.date !== date && date === '') {
      this.setState({ currentDate: moment(moment(), 'DD/MM/YYYY').toDate() })
    }
  }

  isAllowSelect(date: Date, monthSearch = false) {
    const { forbidFutureDates, minAge, maxDate, minDate } = this.props;
    if (monthSearch
      && ((maxDate && dateFns.getMonth(date) === dateFns.getMonth(maxDate))
        || (minDate && dateFns.getMonth(date) === dateFns.getMonth(minDate)))
    ) {
      return true;
    }
    if (minAge && differenceInYears(new Date(), date) < minAge) {
      return false;
    } else if (forbidFutureDates && dateFns.isFuture(date)) {
      return false;
    } else if (
      (maxDate && moment(maxDate).isBefore(date)) ||
      (minDate && moment(minDate).isAfter(date))
    ) {
      return false;
    }
    return true;
  }

  renderHeader() {
    const dateFormatMonth = "MMMM";
    const dateFormatYear = "YYYY";

    return (
      <div className="calendar-header">
        {/* Current Month */}
        <div className="current-month">
          <p onClick={() => this.setState({ showMonths: true })}>
            {capitalizeFirstLetter(
              dateFns.format(this.state.currentDate, dateFormatMonth, {
                locale: es
              })
            )}
          </p>
          <div className="pick-arrow top">
            <img
              src={images.IconArrowUpLightBlue}
              alt="Elegir mes"
              onClick={() => this.prevMonth()}
            />
          </div>
          <div className="pick-arrow bottom">
            <img
              src={images.IconArrowDownLightBlue}
              alt="Elegir mes"
              onClick={() => this.nextMonth()}
            />
          </div>
        </div>
        {/* Current Year */}
        <div className="current-year">
          <p onClick={() => this.setState({ showYears: true })}>
            {capitalizeFirstLetter(
              dateFns.format(this.state.currentDate, dateFormatYear, {
                locale: es
              })
            )}
          </p>
          <div className="pick-arrow top" onClick={() => this.nextYear()}>
            {/* al hacer click, meter clase active a .year-select */}
            <img src={images.IconArrowUpLightBlue} alt="Elegir año" />
          </div>
          <div className="pick-arrow bottom" onClick={() => this.prevYear()}>
            {/* al hacer click, meter clase active a .year-select */}
            <img src={images.IconArrowDownLightBlue} alt="Elegir año" />
          </div>
        </div>
        {/* Swipe Month */}
        <div className="calendar-arrow-container">
          {/* Arrow Left */}
          <div className="arrows arrow-left" onClick={() => this.prevMonth()}>
            <img src={images.IconArrowLeftBlue} alt="" />
          </div>
          {/* Arrow Right */}
          <div className="arrows arrow-right" onClick={() => this.nextMonth()}>
            <img src={images.IconArrowRightBlue} alt="" />
          </div>
        </div>
      </div>
    );
  }

  renderDaysHeader() {
    const dateFormat = "dd";
    const days = [];
    // "L","M","X","J","V","S","D"
    let startDate = dateFns.startOfWeek(this.state.currentDate, {
      weekStartsOn: 1
    });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="calendar-days-day" key={i}>
          <div>
            <p>
              {dateFns.format(dateFns.addDays(startDate, i), dateFormat, {
                locale: es
              })}
            </p>
          </div>
        </div>
      );
    }
    return <div className="calendar-days">{days}</div>;
  }

  renderCells() {
    const { currentDate } = this.state;
    const monthStart = dateFns.startOfMonth(currentDate);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = dateFns.endOfWeek(monthEnd, { weekStartsOn: 1 });
    const dateFormat = "DD";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`col cell ${!dateFns.isSameMonth(day, monthStart)
                ? " disabled"
                : !this.isAllowSelect(day)
                  ? " no-allow"
                  : dateFns.isSameDay(day, currentDate)
                    ? " selected"
                    : ""
              }`}
            key={dateFns.format(day, "DD-MM-YYYY")}
            onClick={() => this.onDateClick(dateFns.parse(cloneDay), true)}
          >
            {/* Days */}
            <div>
              <p>{formattedDate}</p>
            </div>
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={dateFns.format(day, "DD-MM-YYYY")}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  renderSelectYear() {
    const { minAge, maxAge, maxDate, minDate, forbidFutureDates } = this.props;
    const { currentDate } = this.state;
    const currentYear = new Date().getFullYear();
    let maxYear = minAge
      ? currentYear - minAge
      : maxDate
        ? moment(maxDate).get("year")
        : forbidFutureDates
          ? currentYear
          : currentYear + 50;
    const minYear = minDate
      ? moment(minDate).get("year")
      : maxYear - (maxAge ? maxAge : 200);
    let years = [];
    for (let i = maxYear; i >= minYear; i--) {
      let child = [<p key={i}>{i}</p>];
      const yearDate = dateFns.setYear(currentDate, i);
      years.push(
        <li
          className={`${!this.isAllowSelect(yearDate) ? "no-allow" : ""}`}
          key={i}
          onClick={() => {
            const selected = dateFns.setYear(currentDate, i);
            this.onDateClick(selected, false);
          }}
        >
          {child}
        </li>
      );
    }

    const { showYears } = this.state;

    return (
      <div className={"year-select " + (showYears ? "active" : "")}>
        <div
          className="year-select-close"
          onClick={() => this.setState({ showYears: false })}
        >
          <img src={images.IconCrossGreen} alt="cerrar seleccion de año" />
        </div>
        <ul>{years}</ul>
      </div>
    );
  }

  renderSelectMonth() {
    const { showMonths, currentDate } = this.state;
    const { maxDate, minDate } = this.props;
    let tempDate = dateFns.setMonth(new Date(), 0);
    let rows = [];
    let months = [];
    for (let i = 0; i < 12; i++) {
      const monthDate = dateFns.setMonth(currentDate, i);
      months.push(
        <div
          className={`select-month-container-item ${!this.isAllowSelect(monthDate, true) ? "no-allow" : ""
            }`}
          key={i}
          onClick={() => {
            const selected = dateFns.setMonth(currentDate, i);
            this.onDateClick(selected, false);
          }}
        >
          <p>{dateFns.format(tempDate, "MMMM", { locale: es })}</p>
        </div>
      );
      tempDate = dateFns.addMonths(tempDate, 1);

      if ((i + 1) % 3 === 0) {
        rows.push(
          <div className="select-month-container-row" key={i}>
            {months}
          </div>
        );
        months = [];
      }
    }

    if (maxDate && currentDate > new Date(maxDate)) {
      this.setState({ currentDate: new Date(maxDate) });
    }

    if (minDate && currentDate < new Date(minDate)) {
      this.setState({ currentDate: new Date(minDate) });
    }

    return (
      <div className={"select-month-container " + (showMonths ? "active" : "")}>
        {rows}
      </div>
    );
  }

  onDateClick(date: Date, close: boolean) {
    const { onSelect, minDate, maxDate } = this.props;
    if (
      (minDate && moment(minDate).isAfter(date)) ||
      (maxDate && moment(maxDate).isBefore(date))
    )
      return;
    if (this.isAllowSelect(date)) {
      this.setState(
        {
          currentDate: date,
          showMonths: false,
          showYears: false
        },
        () => {
          onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"), close);
        }
      );
    }
  }

  nextMonth() {
    const { onSelect } = this.props;
    const nextMonth = dateFns.addMonths(this.state.currentDate, 1);
    if (this.isAllowSelect(nextMonth, true)) {
      this.setState(
        {
          currentDate: nextMonth
        },
        () => {
          onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"), false);
        }
      );
    }
  }

  prevMonth() {
    const { onSelect, minDate } = this.props;
    const prevMonth = dateFns.subMonths(this.state.currentDate, 1);
    if (this.isAllowSelect(prevMonth, true)) {
      this.setState(
        {
          currentDate: prevMonth
        },
        () => {
          onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"), false);
        }
      );
    } else if (minDate) {
      this.setState(
        {
          currentDate: minDate
        },
        () => {
          onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"), false);
        }
      );
    }
  }

  nextYear = () => {
    const { onSelect, forbidFutureDates } = this.props;
    const nextYear = dateFns.addYears(this.state.currentDate, 1);

    if (this.isAllowSelect(nextYear)) {
      this.setState(
        {
          currentDate: nextYear
        },
        () => {
          onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"), false);
        }
      );
    }
  };

  prevYear = () => {
    const { onSelect } = this.props;
    const prevYear = dateFns.subYears(this.state.currentDate, 1);

    if (this.isAllowSelect(prevYear)) {
      this.setState(
        {
          currentDate: prevYear
        },
        () => {
          onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"), false);
        }
      );
    }
  };

  render() {
    return (
      <CalendarItemContainer>
        <div className="calendar">
          {this.renderHeader()}
          {this.renderDaysHeader()}
          {this.renderCells()}
          {this.renderSelectYear()}
          {this.renderSelectMonth()}
        </div>
      </CalendarItemContainer>
    );
  }
}

export default CalendarItem;
