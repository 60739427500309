import dateFns from "date-fns";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en";
import ca from "date-fns/locale/ca";

const locales = { es, en, ca };
// TODO cheack save as const lang

function parseHourToDate(param) {
  if (param instanceof Date) return param;
  return new Date(
    param.match(/^\d\d:\d\d$/) ? `1970-01-01T${param}:00` : param
  );
}

/**
 *
 * @param {Date} start
 * @param {Date} end
 * @returns {number} minutes between minimun start and maximun finish
 */
export const calculateDifferenceMinutes = (
  start = new Date(),
  finish = new Date()
) => {
  const diff = dateFns.differenceInMinutes(
    parseHourToDate(finish),
    parseHourToDate(start)
  );
  return diff > 0 ? diff : 0;
};
/**
 *
 * @param {Date} start
 * @param {Date} end
 * @returns {number} hours between minimun start and maximun finish
 */
export const calculateDiferenceHours = (start, finish) =>
  dateFns.differenceInHours(new Date(finish), new Date(start));


/**
 *
 * @param {Date} start
 * @param {Date} finish
 * @returns {number} days between minimun start and maximun finish
 */
export const calculateDiferenceDays = (start, finish) =>
  dateFns.differenceInDays(finish, start);

/**
 *
 * @param {Date} object to format
 * @param {string} lang es | en | pt
 * @returns {string} DD de MMMM
 */
export const getDayMonth = (time, lang = "es") =>
  dateFns.format(time, "DD MMMM", { locale: locales[lang] });

export const getMonth = (time, lang = "es") =>
  dateFns.format(time, "MMMM", { locale: locales[lang] });

export const getDay = (time, lang = "es") =>
  dateFns.format(time, "DD", { locale: locales[lang] });

export const getDateFormat = (time, format, lang = "es") =>
  dateFns.format(time, format, { locale: locales[lang] });

/**
 *
 * @param {Date} date to get hour
 * @returns {number} days between minimun start and maximun finish
 */
export const getHourMinutes = (d) => dateFns.format(d, "HH:mm");

/**
 *
 * @param {Date} date to get hour
 * @param {string} language es | en | ca
 * @returns {number} days between minimun start and maximun finish
 */
export const getDayMonthYear = (d, lang = "es") => {
  if (typeof d === "string" && !d.includes("-")) {
    return d;
  }
  return dateFns.format(d, "DD/MM/YYYY", { locale: locales[lang] });
};

/**
 *
 * @param {Date} date to get hour
 * @param {string} language es | en | ca
 * @returns {number} days between minimun start and maximun finish
 */
export const getDayMonthNumberYear = (d, lang = "es") =>
  dateFns.format(d, "MM/DD/YYYY", { locale: locales[lang] });

/**
 *
 * @param {Date} date to get hour
 * @returns {number} days between minimun start and maximun finish
 */
export const getYear = (d) => dateFns.getYear(d);

/**
 *
 * @param {Date} date to get hour
 * @param {string} language es | en | ca
 * @returns {number} days between minimun start and maximun finish
 */
export const isPast = (d) => dateFns.isBefore(d, new Date());

/**
 *
 * @param {Date} date to compare
 * @param {Date} date to compare
 * @returns {boolean} true if both dates are on the same day
 */
export const isSameDay = (start, finish) => dateFns.isSameDay(start, finish);

/**
 *
 * @param {Date} date to get hour
 * @param {string} language es | en | ca
 * @returns {number} days between minimun start and maximun finish
 */
export const isAfter = (d, d2) => dateFns.isAfter(d, d2);

/**
 *
 * @param {Date} start date
 * @param {Date} end date
 * @param {string} lang es | en | pt
 * @returns {number} days between minimun start and maximun finish
 */
export function getHourDuration(start, finish, lang = "es") {
  return `${dateFns.format(start, "[De] HH:mm", {
    locale: locales[lang],
  })} ${dateFns.format(finish, " [a] HH:mm", {
    locale: locales[lang],
  })} `;
}

/**
 *
 * @param {Date} date to cut minutes to 00 or 30
 * @returns {Date} hour rounded down formated as HH:MM
 */
export function setHourRoundedDown30(param = new Date()) {
  const minutes = dateFns.getMinutes(param);
  return dateFns.setMinutes(param, minutes >= 30 ? 30 : 0);
}

/**
 *
 * @param {Date} date or hour to get hour
 * @returns {string} hour rounded down formated as HH:MM
 */
export function getHourRoundedDown30(param = new Date()) {
  const date = parseHourToDate(param);
  if (dateFns.getMinutes(date) < 30) {
    return `${dateFns.getHours(date)}:${"00"}`;
  } else if (date.getHours(date) === 23) {
    return `${"23"}:${"40"}`;
  } else {
    return `${dateFns.getHours(date) + 1}:${"00"}`;
  }
}

/**
 *
 * @param {Date} date to cut minutes to 00 or 30
 * @returns {Date} hour rounded down formated as HH:MM
 */
export function setHourRoundedUp30(param = new Date()) {
  const minutes = dateFns.getMinutes(param);
  const hours = dateFns.getHours(param);
  if (minutes > 30)
    return dateFns.setHours(dateFns.setMinutes(param, 0), hours + 1);

  return dateFns.setMinutes(param, 30);
}

/**
 *
 * @param {Date} date or hour to get hour
 * @returns {string} hour rounded up formated as HH:MM
 */
export function getHourRoundedUp30(param = new Date()) {
  const date = parseHourToDate(param);
  const hour = dateFns.getHours(date);
  const minute = dateFns.getMinutes(date);

  return `${minute > 30 ? hour + 1 : hour}:${minute > 30 ? "00" : "30"}`;
}

export const formatDay = (date = "") => {
  const dateSplit = date.split("/");

  return new Date(dateSplit[1] + "/" + dateSplit[0] + "/" + dateSplit[2]);
};

export const addMinutes = (date, min = 30) => dateFns.addMinutes(date, min);

export const setMinutes = (date, min = 0) => dateFns.setMinutes(date, min);

export const setHours = (date, h = 0) => dateFns.setHours(date, h);

export const addMonths = (date, months = 1) => dateFns.addMonths(date, months);
export const addDays = (date, days = 1) => dateFns.addDays(date, days);

/**
 *
 * @param {Date} date or hour to get hour
 * @param {string} lang es | en | pt
 * @returns {string} hour rounded up formated as HH:MM
 */
export function getTimeAgo(time = new Date(), lang = "es") {
  const now = new Date();

  const years = dateFns.differenceInYears(now, time);
  if (years > 0) return `Hace ${years} años`;

  const months = dateFns.differenceInMonths(now, time);
  if (months > 0) return `Hace ${months} meses`;

  const days = dateFns.differenceInDays(now, time);
  if (days > 0) return `Hace ${days} días`;

  const hours = dateFns.differenceInHours(now, time);
  if (hours > 0) return `Hace ${hours} horas`;

  const minutes = dateFns.differenceInMinutes(now, time);
  if (minutes > 0) return `Hace ${minutes} minutos`;

  const seconds = dateFns.differenceInSeconds(now, time);
  if (seconds > 0) return `Hace ${seconds} segundos`;

  return "Hace un momento";
}

/**
 *
 * @param {string} date to check
 * @param {string} date to compare
 * @returns {number} differrence in miliseconds
 */
export function getTimeDiference(first, second) {
  return new Date(first).getTime() - new Date(second).getTime();
}

/**
 *
 * @param {number} yearsCount to return
 * @returns {{ [key: string]: string; }[]} [{ label: string, value: string }]
 */
export function getLastNYears(yearsCount) {
  const currentYear = new Date().getFullYear();
  // Puesto que el array se inicializa con `undefined` en cada posición,
  // el valor de `value` a continuación será `undefined`;
  const lastNYears = Array.from({ length: yearsCount }, (value, index) => {
      const year = currentYear - index;
      return { label: year.toString(), value: year.toString() };
  });
  return lastNYears;
}
