import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { PaginationContainer, LiPolicies } from "./policies-item-style";
import MenuText from "../../components/components-text/menu-text";
import MainButton from "../../components/main-button";
import TitleText from "../../components/components-text/title-text";
import ButtonText from "../../components/components-text/button-text";
import { withPolicy } from "../../context/mixin/with-policy";
import ProductServices from "../../services/ProductServices";
import Loader from "../loader";
import { CARD_NAMES } from "../../constants/policyType";

interface PoliciesItemProps extends RouteComponentProps {
  id: string;
  type: string;
  link: string | string[];
  linkSimulacion: string | string[];
  typeName: String;
  nameItem: String;
  arrayComplexItem?: any;
  open: boolean;
  changeOpen: any;
  changeLifeActive: any;
  changeStep: any;
  image: any;
  setItemClick?: Function;
  policy: any;
  textButton?: string;
  colorCode?: string;
  disabled?: boolean;
  setGlobalInternalName: Function;
  cardName: string;
}

interface PoliciesItemState {
  item: any;
  selectedIndex: string | null;
  contractButton: Boolean;
  idSelected: string;
  linkSelected: string;
  textSelected: string;
  loading: boolean;
}

class PoliciesItemLayout extends Component<
  PoliciesItemProps,
  PoliciesItemState
> {
  state: PoliciesItemState = this.state;

  constructor(props: PoliciesItemProps) {
    super(props);

    this.state = {
      item: this.props,
      selectedIndex: "",
      contractButton: true,
      idSelected: "",
      linkSelected: "",
      textSelected: "",
      loading: false,
    };
  }

  shouldComponentUpdate = (nextProps: PoliciesItemProps) => {
    if (this.props.open !== nextProps.open) {
      let { open } = this.state.item;
      open = nextProps.open;
      this.setState({ item: { open } });
    }

    return true;
  };

  changeActive = (index: number, id: string = "", link: string = "", text: string) => {
    let {
      contractButton,
      selectedIndex,
      idSelected,
      linkSelected,
      textSelected,
    } = this.state;

    let nameLi = document.getElementsByClassName("policies-complex-item")[index]
      .children[0].children[0].textContent;

    this.props.changeLifeActive(index);

    selectedIndex = nameLi;
    contractButton = false;
    textSelected = text;
    
    if (id) {
      idSelected = id;
      this.setState({
        idSelected,
      });
    }
    if (link) {
      linkSelected = link;
      this.setState({
        linkSelected,
        contractButton,
        selectedIndex,
        textSelected,
      });
    } else {
      this.setState({ contractButton, selectedIndex, textSelected });
    }
  };

  setTypePolicies: any = () => {
    const { item, contractButton } = this.state;
    let {
      type,
      typeName,
      nameItem,
      arrayComplexItem,
      image,
      textButton,
      history,
      disabled,
      setGlobalInternalName,
      cardName
    } = this.props;
    let listComplexItem = "";
    
    let clickLink = async (link: string, product_id: string, text: string) => {
      // Se hace llamada al servidor 
      let json: string = localStorage.getItem('loggedUser') || "{code:0}";
      let user = JSON.parse(json)
      let args = {
        product_id: product_id,
        mediator_id: user.code,
        loader: false,
        setLoading: () => { }
      };
      const isAllowed = await ProductServices.getProductPermission(args);
      if(isAllowed === true) {
        if(link.includes('http')){
          this.setState({ loading: true})
          window.location.href = link        
        }else{
          history.push(String(link));
          setGlobalInternalName(product_id)
          this.props.policy.policyInfo.changePolicyType();
        }
      } else {
        history.push(`/403`);
      }
    }
    if (arrayComplexItem !== undefined) {
      listComplexItem = arrayComplexItem.map(
        (item: { text: string; link: string }, index: number) => {
          return (
            <LiPolicies
              key={index}
              className="policies-complex-item"
              onClick={() =>
                this.changeActive(
                  index,
                  this.props.id.length > 0 ? this.props.id[index] : "",
                  this.props.link.length > 0 ? this.props.link[index] : "",
                  item.text
                )
              }
            >
              <ButtonText>
                <p>{item.text}</p>
              </ButtonText>
            </LiPolicies>
          );
        }
      );
    }

    switch (type) {
      case "simple":
        return (
          <div className="policies-item">
            <div className="policies-wave">
              <img src={image} alt="" />
            </div>
            <div className="policies-info">
              <div className="policies-info-title">
                <TitleText>
                  <p>{cardName}</p>
                </TitleText>
              </div>
              <div className="policies-info-link">
                <MainButton
                  text={textButton ? textButton : "Contratar"}
                  onClick={async () => {
                    clickLink(
                      String(this.props.link),
                      String(this.props.id),
                      String(nameItem)
                    );
                  }}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        );
      case "complex":
        return (
          <div
            className={`policies-item ${!item.open ? "" : "open"}`}
            onClick={() => item.changeOpen && item.changeOpen()}
          >
            <div className="policies-item-cover">
              <div className="policies-wave">
                <img src={image} alt="" />
              </div>
              <div className="policies-info">
                <div className="policies-info-title">
                  <TitleText>
                    <p>{cardName}</p>
                  </TitleText>
                </div>
                <div
                  className={`policies-info-link ${
                    cardName === CARD_NAMES.SALUD &&
                    history.location.pathname.split("/")[1] === "simulacion"
                      ? "mt-21"
                      : ""
                  }`}
                >
                  <MainButton
                    type={"light"}
                    text="Ver producto"
                    onClick={() => item.changeOpen()}
                  />
                  <div>
                    {cardName === CARD_NAMES.SALUD &&
                      history.location.pathname.split("/")[1] ===
                        "simulacion" && (
                        <MainButton
                          text="Comparar"
                          onClick={() => {
                            setGlobalInternalName(item.id[0]);
                            this.props.history.push("/comparacion/nueva/salud/mediador");
                          }}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="policies-item-content">
              <div className="product-container">
                <ul className="display-scroll">{listComplexItem}</ul>
              </div>
              <div className="policies-info-link">
                {contractButton ? (
                  <MainButton
                    disabled={contractButton || disabled}
                    text={textButton ? textButton : "Contratar"}
                    onClick={() => {
                      clickLink(
                        String(this.state.linkSelected),
                        String(this.state.idSelected),
                        String(this.state.textSelected)
                      );
                    }}
                  />
                ) : (
                  <MainButton
                    disabled={contractButton || disabled}
                    text={textButton ? textButton : "Contratar"}
                    onClick={() => {
                      clickLink(
                        String(this.state.linkSelected),
                        String(this.state.idSelected),
                        String(this.state.textSelected)
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        );
      default:
        return;
    }
  };

  render() {
    const { colorCode } = this.props;
    const { loading } = this.state;

    return (
      <PaginationContainer colorCode={colorCode}>
        {loading && <Loader />}
        {this.setTypePolicies()}
      </PaginationContainer>
    );
  }
}

export default withRouter(withPolicy(PoliciesItemLayout));
