import GeneralServices from "./GeneralServices";

class CypherServices {
  encrypt = async (args: any) => {
    try {
      const res = await GeneralServices.post({
        loader: true,
        setLoading: args.setLoading,
        data: args.data,
        endpoint: "auth/encrypt"
      });

      return res.data;
    } catch (err) {
      throw err;
    }
  };


  decrypt = async (args: any) => {
    try {
      const res = await GeneralServices.post({
        loader: true,
        setLoading: args.setLoading,
        data: args.data,
        endpoint: "auth/decrypt"
      });

      return res.data;
    } catch (err) {
      throw err;
    }
  };
}

export default new CypherServices();
