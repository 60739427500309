import styled from "styled-components";
import { colors } from "../../assets";

export const CollapsedSidebarContainer = styled.aside`
  position: fixed;
  width: 82px;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  background: ${colors["PR-001-100"]};
  color: ${colors["FL-001-Default"]};
  padding: 35px 25px;
  z-index: 99;

  img {
    cursor: pointer;
    max-width: 24px;
  }
  
  .collapsed-sidebar-tooltip {
    position: fixed;
    left: 74px;
  }

  .collapsed-sidebar-logo {
    margin-bottom: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .collapsed-sidebar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 86%;
  }

  .collapsed-sidebar-main {
    margin-bottom: 20px;
  }

  .collapsed-sidebar-main-item {
    margin-bottom: 40px;
    opacity: 0.5;
    &.active {
      opacity: 1;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .collapsed-sidebar-footer-item {
    margin-bottom: 40px;
    opacity: 0.5;
    &.active {
      opacity: 1;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-height: 620px) {
    .collapsed-sidebar-logo {
      margin-bottom: 68px;
    }

    .collapsed-sidebar-main-item {
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .collapsed-sidebar-nav {
      height: calc(100vh - 175px);
    }
  }
`;
