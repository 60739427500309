import React from "react";
import { Link } from "react-router-dom";
import { LegalPolicyContainer } from "./legal-policy-style";
interface LegalPolicyProps { }

export default class LegalPolicyLayout extends React.Component<LegalPolicyProps, {}> {
  render() {
    return (
      <LegalPolicyContainer>
        <div className="legal-title-selection">
          <p>Aviso legal</p>
        </div>
        <div className="legal-text">
          <p>
            En cumplimiento de lo dispuesto en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico,
            se informa de que el Sitio Web <a href='https://webmediadores.asisa.es/'>https://webmediadores.asisa.es/</a> (en adelante, el Sitio Web) es propiedad de “ASISA, ASISTENCIA SANITARIA INTERPROVINCIAL DE SEGUROS,
            S.A.U.”(en adelante, el Titular) con domicilio en la calle Juan Ignacio Luca de Tena 12, 28027, Madrid; teléfono de contacto +34915955700.
            Inscrita en el Registro Mercantil de Madrid, al tomo 4280, folio 111, hoja M-71063, Inscripción 165.
          </p>
          <p>
            A continuación, se procede a mostrar las condiciones que de un modo general regulan el acceso al Sitio Web, tanto de los servicios como funcionalidades allí ofrecidos, si perjuicio de aplicación de otras condiciones distintas o su modificación.
          </p>
        </div>

        <div className="legal-subtitle">
          <p>CONDICIONES GENERALES</p>
        </div>

        <div className="legal-subtitle">
          <p>01 Identificación de las partes</p>
        </div>

        <div className="legal-text">
          <p>
            Las Condiciones Generales descritas a continuación regulan el uso del Sitio Web y quedan suscritas de una parte por el Titular y de otra por ti, como usuario persona física o jurídica que accede voluntariamente de manera libre y gratuita al Sitio Web del Titular, al que se accede mediante la dirección URL <a href='https://webmediadores.asisa.es/'>https://webmediadores.asisa.es/</a>
          </p>
          <p>
            La utilización del Sitio Web <a href='https://webmediadores.asisa.es/'>https://webmediadores.asisa.es/</a>  supone la aceptación por el usuario de las advertencias y menciones vigentes en cada momento y que a continuación se especifican.
          </p>
        </div>

        <div className="legal-subtitle">
          <p>02 Registro del usuario y acceso gratuito a los contenidos</p>
        </div>

        <div className="legal-text">
          <p>
            Tendrá la consideración de usuario la persona que navegue y haga uso del Sitio Web del Titular.
          </p>
          <p>
            Como regla general, el acceso y la navegación por el usuario de este Sitio Web no requieren el previo registro del mismo.
          </p>
          <p>
            No obstante lo anterior, existe la posibilidad de que el acceso a algunos servicios implique la obligación para el usuario
            de estar registrado en las bases de datos correspondientes y que, por tanto, sea necesario que suministre determinados datos
            de carácter personal, cuyo tratamiento se someterá en todo caso a lo dispuesto en la vigente normativa sobre protección de datos.
          </p>
          <p>
            En el supuesto de que el acceso a determinados servicios implique el registro obligatorio, el usuario deberá utilizar sus claves de
            acceso para su uso exclusivo, siendo su correcta utilización de su absoluta responsabilidad.
          </p>
          <p>
            Ello significa que el Titular no asumirá ninguna responsabilidad derivada de los perjuicios que, en su caso, pudieran ocasionarse a terceros como
            consecuencia de que otras personas hagan uso de la contraseña del usuario, ya sea con su conocimiento o sin él.
          </p>
          <p>
            Asimismo, el acceso y la navegación por el usuario dentro del Sitio Web son gratuitos, sin perjuicio de que la contratación de algún servicio pueda
            suponer el abono de alguna cantidad, lo que se indicará en un apartado visible de la página.
          </p>
        </div>

        <div className="legal-subtitle">
          <p>03 Obligaciones del usuario y denegación de acceso a los contenidos del sitio web</p>
        </div>

        <div className="legal-text">
          <p>
            El usuario se obliga al cumplimiento de las presentes Condiciones de Uso, así como a cumplir las especiales advertencias o
            instrucciones de uso contenidas en las mismas o en el Sitio Web y obrar siempre conforme a la Ley, a las buenas costumbres
            y a las exigencias de la buena fe, empleando la diligencia adecuada a la naturaleza del servicio del que disfruta, absteniéndose
            de utilizar el Sitio Web de cualquier forma que pueda dañar o deteriorar el normal funcionamiento del mismo, los bienes o derechos
            del Titular, sus proveedores, el resto de usuarios o, en general, de cualquier tercero.
          </p>
          <p>
            En este sentido, el usuario mediante el mero acceso y navegación a través del Sitio Web deberá:
          </p>
          <ul>
            <li>Emplear el Sitio Web de manera diligente, correcta y lícita, siempre en respeto de la legislación vigente, la moral y
              las buenas costumbres, así como el orden público.</li>
            <li>Revisar periódicamente estas Condiciones, o cualesquiera otras que resulten aplicables, comprobando las modificaciones que pudieran producirse.</li>
            <li>No utilizar el Sitio Web con fines comerciales, especialmente para recabar información o contenidos con la finalidad de prestar servicios que
              constituyan claramente una competencia.</li>
            <li>No modificar o tratar de modificar en modo alguno, ni realizar acciones o utilizar medios orientados a simular la apariencia o las funcionalidades del Sitio Web.</li>
            <li>No dañar, deshabilitar, sobrecargar o dificultar el servicio (o la red o redes conectadas al servicio), o inferir en su uso o disfrute.</li>
            <li>Abstenerse de llevar a cabo acción alguna que conlleve la instrucción de virus informáticos, gusanos, troyanos o cualquier otra clase de código
              malicioso destinado a interrumpir, destruir o limitar las funcionalidades del Sitio Web.</li>
            <li>No emplear técnicas de ingeniería inversa y/o descifrar o utilizar cualquier otro sistema destinado a conocer el código.</li>
            <li>En cualquier caso, no realizar ningún otro tipo de acto que pudiera vulnerar derechos e interés propios o de
              terceros como pueden ser, a modo de ejemplo, derechos de propiedad intelectual o industrial.</li>
          </ul>
          <p>
            El Titular, podrá denegar el acceso a este Sitio Web en el caso de incumplimiento de las presentes Condiciones de Uso por parte del usuario.
          </p>
        </div>
        <div className="legal-subtitle">
          <p>04 Propiedad industrial e intelectual</p>
        </div>

        <div className="legal-text">
          <p>
            La denominación “MEDIADORES ASISA” y otros usos distintivos que aparecen en esta página web son de la exclusiva propiedad y titularidad de
            “ASISA, ASISTENCIA SANITARIA INTERPROVINCIAL DE SEGUROS, S.A.U”, que ha procedido a su registro.</p>
          <p>
            Asimismo, el nombre de dominio https://webmediadores.asisa.es/  y aquellos otros mediante los cuales se pueda acceder de forma directa a este Sitio Web son
            también de la exclusiva titularidad del Titular, por lo que su uso indebido será perseguido de acuerdo con lo previsto en la Ley.
          </p>
          <p>
            La citada titularidad le confiere el derecho exclusivo de utilización de dichos signos distintivos, por lo que aquellos terceros que carezcan de
            autorización tienen prohibida su utilización.
          </p>
          <p>
            Todos los textos, imágenes, sonidos, vídeos y, en general, las obras intelectuales que existen en este Sitio Web están protegidos como derecho de autor
            por la legislación sobre propiedad intelectual, exceptuando las aplicaciones de dominio público por voluntad expresa de su autor, cuya titularidad no es
            del Titular y que son de acceso gratuito para el usuario, que podrá proceder a su descarga.
          </p>
          <p>
            No obstante, se reconoce al usuario autorización para poder visualizar, imprimir, copiar o almacenar, en su disco duro o en otro soporte físico,
            los contenidos de este Sitio Web, siempre que se observe el respeto a los derechos de autor y de propiedad industrial, de acuerdo con lo señalado en este apartado.
          </p>
          <p>
            Cualesquiera otros usos distintos de los meramente personales citados, requerirá autorización expresa y escrita del Titular.
          </p>
          <p>
            Los enlaces contenidos o que puedan contenerse en este Sitio Web que dirijan a otras páginas web para facilitar al usuario el acceso a las mismas respetan
            los derechos de propiedad intelectual.
          </p>
        </div>


        <div className="legal-subtitle">
          <p>05 Responsabilidad</p>
        </div>
        <div className="legal-text">
          <p>
            “ASISA” asume el compromiso de poner todas aquellas medidas a su alcance para evitar la exhibición de información o contenidos ilícitos dentro de este Sitio Web,
            así como para eliminarlos en el supuesto de que tenga constancia de la existencia de los mismos.
          </p>
          <p>
            Igualmente, el Titular realizará todas las acciones a su alcance encaminadas a evitar y, en su caso, eliminar de su página web aquellos enlaces a sitios de contenido ilegal,
            que inciten a actividades ilícitas o que supongan una vulneración de los derechos que consagra el ordenamiento jurídico.
          </p>
          <p>
            Asimismo, pone en conocimiento de los usuarios que cabe la posibilidad de que el contenido de la información de este Sitio Web no esté actualizado o sea totalmente exacta,
            por lo que no se hace responsable de dichos contenidos, ni de los suministrados por los proveedores u otros terceros que utilicen este Sitio Web, así como de los posibles
            perjuicios que el uso de la misma pudiera ocasionar.
          </p>
          <p>
            El Titular desarrollará los esfuerzos precisos para evitar errores y, en su caso, repararlos o actualizarlos lo antes posible, no pudiendo garantizar su inexistencia ni que
            el contenido de la información se encuentre permanentemente actualizado.
          </p>
          <p>
            El Titular no se hace responsable de las opiniones vertidas en los foros que pudieran tener lugar dentro de su Sitio Web, si bien, se compromete a velar por la correcta
            utilización de los mismos.
          </p>
          <p>
            Toda la información contenida en este Sitio Web será susceptible de ser modificada de manera y/o con la periodicidad que el Titular estime oportuno, sin que estos
            cambios deban ser comunicados a los usuarios. Ante cualquier duda, se recomienda que antes de iniciar la navegación, lean atentamente el presente Aviso Legal.
          </p>
          <p>
            El Titular no se hace responsable de los perjuicios que se pudieran derivar de, con carácter meramente enunciativo y no limitativo:
          </p>
          <ul>
            <li>
              Interferencias, omisiones, interrupciones, virus informáticos, averías y/o desconexiones en el funcionamiento operativo de este sistema electrónico o
              en los aparatos y equipos informáticos de los Usuarios, motivadas por causas ajenas al Titular, que impidan o retrasen la prestación de los servicios o
              la navegación por el Sistema;
            </li>
            <li>
              Retrasos o bloqueos en el uso causados por deficiencias o sobrecargas de Internet o en otros sistemas electrónicos;
            </li>
            <li>
              Acciones de terceras personas realizadas mediante intromisiones ilegítimas, fuera del control y que no sean atribuibles al Titular;
            </li>
            <li>
              Divergencias de la información, documentación y/o demás contenido del Sitio Web que pudieren existir entre la versión electrónica y la versión impresa;
            </li>
            <li>
              La imposibilidad de dar el servicio o permitir el acceso por causas no imputables, debidas al usuario, a terceros, o a supuestos de fuerza mayor;
            </li>
            <li>
              Falta de veracidad, exactitud, exhaustividad y actualidad de los contenidos ofrecidos por sí misma o por terceros.
            </li>
          </ul>
          <p>
            Con carácter general, la información disponible en este Sitio Web tiene que ser entendida como una guía sin propósito de validez legal.
            La información facilitada a través del mismo no sustituye la publicidad legal de las leyes, de las disposiciones generales y de los actos
            que tengan que ser publicados formalmente a los diarios o boletines oficiales de las administraciones públicas. En el caso de cualquier
            discrepancia entre la versión de documentos e información obtenida en este Sitio Web y la versión considerada oficial, la versión oficial de
            las administraciones públicas es la única legalmente válida.
          </p>
        </div>

        <div className="legal-subtitle">
          <p>06 Protección de datos</p>
        </div>

        <div className="legal-text">
          <p>
            El Titular podrá tratar los datos personales recabados a través del Sitio Web.
          Para más información consulte nuestra <a href={`${window.location.origin}/politica-proteccion-datos`}>Política de Privacidad</a>.
          </p>
        </div>

        <div className="legal-subtitle">
          <p>07 Cookies</p>
        </div>

        <div className="legal-text">
          <p>
            Te informamos que el Sitio Web podrá utilizar cookies con las finalidades de facilitar su navegación y precargar sus preferencias.
          Para más información, consulte nuestra <a href={`${window.location.origin}/politica-cookies`}>Política de Cookies</a>.
          </p>
        </div>

        <div className="legal-subtitle">
          <p>08 Indemnización</p>
        </div>

        <div className="legal-text">
          <p>
            El Titular podrá sancionar a cualquiera de los usuarios que incumplan las condiciones que le son aplicables, con la imposibilidad de acceder,
            temporal o indefinidamente al Sitio Web. La duración de la sanción dependerá del tipo de infracción cometida. La restricción de acceso no
            conllevará en ningún caso derecho a indemnización.
          </p>
          <p>
            Cualquier tipo de daño, perjuicio, perdida o coste (incluidos honorarios de abogados y/o procuradores) derivado de un incumplimiento
            por parte del usuario de estas Condiciones o de cualesquiera otras que resulten aplicables.
            Ello abarca cualquier reclamación de terceros derivada de dichos incumplimientos.
          </p>
        </div>

        <div className="legal-subtitle">
          <p>09 Modificaciones</p>
        </div>

        <div className="legal-text">
          <p>
            Las presentes Condiciones pueden ser en cualquier momento modificadas y/o actualizadas sin necesidad de previo aviso.
            Las modificaciones efectuadas entrarán en vigor a partir de su publicación en el Sitio Web, sea cual sea el medio forma empleado para ello.
          </p>
          <p>
            La modificación solo afectará a los usuarios que las hubieran aceptado con posterioridad a dicha modificación.
          </p>
        </div>

        <div className="legal-subtitle">
          <p>10 Idioma</p>
        </div>

        <div className="legal-text">
          <p>
            El idioma aplicable a las presentes Condiciones es el español. Si se facilitan versiones en otros idiomas como el inglés,
            será únicamente por mera cortesía y mayor comodidad para el usuario. En caso de contradicción prevalecerá la versión en español.
          </p>
        </div>

        <div className="legal-subtitle">
          <p>11 Legislación y fuero jurisdiccional</p>
        </div>

        <div className="legal-text">
          <p>
            Las Condiciones Generales señaladas están sujetas a lo dispuesto en la legislación española.
          </p>
          <p>
            Toda discrepancia o controversia entre las partes relacionada con la página web: <a href='https://webmediadores.asisa.es/'>https://webmediadores.asisa.es/ </a>
          se someterá a los Juzgados y Tribunales de la ciudad de Madrid, con renuncia expresa a cualquier otro fuero que pudiera corresponderles.
          </p>
        </div>
      </LegalPolicyContainer>
    );
  }
}
