import React, { Component } from "react";
import { InflowSidebarContainer } from "./inflow-sidebar-style";
import { images } from "../../assets";
import { RouteComponentProps, withRouter } from "react-router";
import Menu02Text from "../components-text/menu-02-text";
import MainButton from "../main-button";
import InsuredCard from "../insured-card/insured-card";
import { withPolicy } from "../../context/mixin/with-policy";
import { SignDataDto } from "../../containers/hirings/new-hiring/types";
import { isValidStatusToEnableButton, VALID_STATUS_TYPE } from "../../constants/hiring";
import Loader from "../loader";
import { withGeneral } from "../../context/mixin/with-general";
import { AREA_NAMES, CARD_NAMES } from "../../constants/policyType";
import { withDynamicProducts } from "../../context/mixin/with-dynamic-products";
import CoveragesServices from "../../services/CoveragesServices";
import { Steps as PetSteps } from '../../containers/hirings/new-hiring/pet/pet-navigation';
import { capitalValidation } from "../../utils/coverages";

interface RouteParams {
  index: string;
}
interface InflowSidebarProps extends RouteComponentProps<RouteParams> {
  policy: any;
  clickLightButton: Function;
  insuredMembers: any[];
  taker?: any;
  beneficiary?: any;
  signData: SignDataDto,
  disabled: boolean;
  removeInsured: Function;
  linkShowAll: string;
  linkDetail: string;
  saveHiring?: Function;
  selected?: string;
  onGotoDetail?: (index: number) => void;
  onClick?: any;
  downloadDocs: () => void;
  sendSign: () => void;
  generateInsuranceRequest: () => void;
  actionCancel?: Function;
  mediator: any;
  checkProductArea: Function;
  disableDownloadDocs: boolean;
  disableSendSign: boolean;
  addNewInsured?: () => void;
  validatePet?: (goToValidatePet: boolean) => void;
  currentStep?: number;
}

interface InflowSidebarState {
  policiesCard: any;
  loading: boolean;
  covConfig: any[];
}

class InflowSidebar extends Component<InflowSidebarProps, InflowSidebarState> {
  state: InflowSidebarState;

  constructor(props: InflowSidebarProps) {
    super(props);
    this.state = {
      policiesCard : {
        policiesType: '',
        policiesName: '',
        policiesImage: '',
      },
      loading: false,
      covConfig: []
    };
  }

  async componentDidMount() {
    const { policy } = this.props
    const covConfigs = await CoveragesServices.getCoveragesConfig({
      setLoading: () => { },
      loader: false,
      ignoreSetFalse: true,
    })
    this.setState({ covConfig: covConfigs })

    if(Object.keys(policy.product).length!==0){
      let policiesCard = await this.getPoliciesCard()
      this.setState({policiesCard})
    }

  }

  async componentDidUpdate(prevProps: InflowSidebarProps) {
    const { policy } = this.props

    if(Object.keys(prevProps.policy.product).length===0 && Object.keys(policy.product).length!==0){
      let policiesCard = await this.getPoliciesCard();
      this.setState({policiesCard})
    }

  }

  getImage (cardName: string) {
    let image
    const imageRefs: any = {
      [CARD_NAMES.VIDA] : images.MiniWavePolicieYellow,
      [CARD_NAMES.DECESOS]: images.MiniWavePoliciePurple,
      [CARD_NAMES.MASCOTAS]: images.MiniWavePolicieRed,
      [CARD_NAMES.SALUD]: images.MiniWavePolicieSalud,
      [CARD_NAMES.TRAVEL]: images.MiniWavePolicieTravel,
      [CARD_NAMES.DENTAL]: images.MiniWavePolicieDental,
      [CARD_NAMES.PYMES]: images.MiniWavePoliciePymes, 
    }

    image = imageRefs[cardName]

    return image ? image : images.MiniWavePolicieBlue
  }

  async getPoliciesCard() {
    const { policy, mediator } = this.props;
    let policiesCard: any = {};


    if(Object.keys(policy.product).length){
      
      const cardName = policy.product.products_cardName
      const productName = policy.product.products_name
      
      policiesCard = {
        policiesType: "Póliza " + cardName,
        policiesName: productName,
        policiesImage: this.getImage(cardName),
      };

    }else{
      policiesCard = {
        policiesType: '',
        policiesName: '',
        policiesImage: '',
      };
    }
    
    return policiesCard;
  }

  goToDetail = (index: number) => {
    const { onGotoDetail } = this.props;
    if (index !== undefined && onGotoDetail) {
      onGotoDetail(index);
    }
  };

  getTextButton = () => {
    const { checkProductArea, policy } = this.props
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, policy.product.products_internalName)

    if (this.isLastQuestionnaire()) {
      return isTravelArea ? "Simular" : "Contratar";
    }

    return "Continuar Proceso";
  };

  isLastQuestionnaire = () => {
    const { history, insuredMembers } = this.props;
    const pathnameSplit = history.location.pathname.split("/");
    const index = pathnameSplit[pathnameSplit.length - 1];
    return (
      history.location.pathname.indexOf("cuestionarios-vida") > -1 &&
      insuredMembers.length <= parseInt(index) + 1
    );
  };

  isNotDataForm() {
    const { history } = this.props;
    return history.location.pathname.indexOf("cuestionarios-vida") > -1;
  }

  checkHireIsDisabled(buttonText: string): boolean {
    if (buttonText.toUpperCase() === 'CONTRATAR') {
      return this.props.signData && this.props.signData.signStatus && this.props.signData.signStatus !== '' ?
        isValidStatusToEnableButton(VALID_STATUS_TYPE.ALLOW_HIRE, this.props.signData.signStatus)
        : true
    }
    else {
      return false
    }
  }

  handleValidatePet = (goToValidatePet: boolean) => {
    this.props.validatePet && this.props.validatePet(goToValidatePet);
  }

  isMenuItemDisabled = (step: PetSteps): boolean => {
    if (this.props.currentStep) {
      return step > this.props.currentStep;
    }
    return false;
  }

  isButtonDisabled = (step: PetSteps): boolean => {
    if (this.props.currentStep) {
      return step !== this.props.currentStep;
    }
    return false;
  }

  canAddPet = () => {
    let canAddPet = !this.isButtonDisabled(PetSteps.DatosMascotas);
    
    const { insuredMembers } = this.props;
    insuredMembers.forEach((pet) => {
      const validatedCoverages = capitalValidation(pet.coverages);
      if (Object.keys(validatedCoverages).length !== 0) {
        canAddPet = false;
      }

      canAddPet = pet.birthdate &&
      pet.breed &&
      pet.breedLabel &&
      pet.name &&
      pet.numChip &&
      pet.owner &&
      pet.type ? canAddPet : false;
    });

    return canAddPet;
  }

  render() {
    let { policiesCard, loading, covConfig } = this.state;
    let {
      insuredMembers,
      taker,
      beneficiary,
      signData,
      disabled,
      removeInsured,
      linkShowAll,
      saveHiring,
      onClick,
      downloadDocs,
      sendSign,
      actionCancel,
      checkProductArea,
      policy,
      disableDownloadDocs,
      disableSendSign,
      addNewInsured,
    } = this.props;

    const urlString = window.location.href;
    const url = new URL(urlString);
    const selectedTaker = url.pathname.indexOf("tomador") > -1;
    const selectedBeneficiary = url.pathname.indexOf("asegurado-beneficiario") > -1;
    const selectedInsured = url.searchParams.get("index");
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, policy.product.products_internalName)
    const isPetArea = checkProductArea(AREA_NAMES.PET, policy.product.products_internalName)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, policy.product.products_internalName)


    
    let continueButtonText = this.getTextButton();
    return (
      <InflowSidebarContainer>
        {loading && <Loader />}
        <div className="inflow-sidebar-wrapper">

          {(this.isLastQuestionnaire() || this.isNotDataForm()) && continueButtonText === "Contratar" && (
            <div className="inflow-sidebar-continue">
              <MainButton
                disabled={disabled || disableDownloadDocs}
                text="Descargar Documentación"
                onClick={() => downloadDocs()}
              />
            </div>
          )}

          {(this.isLastQuestionnaire() || this.isNotDataForm()) && continueButtonText === "Contratar" && (
            <div className="inflow-sidebar-continue">
              <MainButton
                disabled={disabled || disableSendSign || (signData && signData.signStatus && isValidStatusToEnableButton(VALID_STATUS_TYPE.ALLOW_SIGN, signData.signStatus))}
                text="Enviar firma"
                onClick={() => sendSign()}
              />
            </div>
          )}

          {this.isLastQuestionnaire() && !isTravelArea && (
            <div className="inflow-sidebar-continue">
              <MainButton
                disabled={disabled || (signData && signData.signStatus && isValidStatusToEnableButton(VALID_STATUS_TYPE.ALLOW_EDIT, signData.signStatus))}
                text="Cancelar"
                onClick={() => actionCancel && actionCancel()}
              />
            </div>
          )}

          {this.isLastQuestionnaire() && !isTravelArea && (
            <div className="inflow-sidebar-continue">
              <MainButton
                disabled={disabled || (signData && signData.signStatus && isValidStatusToEnableButton(VALID_STATUS_TYPE.ALLOW_EDIT, signData.signStatus))}
                text={"Guardar"}
                onClick={saveHiring ? saveHiring : () => { }}
              />
            </div>
          )}

          {/* <div className="inflow-sidebar-saving">
            <img src={images.IconClockGrey} />
            <NoteText>
              <p>Datos introducidos guardados</p>
            </NoteText>
          </div> */}

          <div className="inflow-sidebar-type">
            <img src={policiesCard.policiesImage} alt="" />
            <div className="inflow-sidebar-type-wrapper">
              <div className="inflow-sidebar-type-text">
                <p>{policiesCard.policiesType}</p>
              </div>
              <div className="inflow-sidebar-type-title">
                <p>{policiesCard.policiesName}</p>
              </div>
            </div>
          </div>
          <div className="inflow-sidebar-members">
            {(insuredMembers.length > 0 || (!!taker && !!taker.name)|| (!!beneficiary && !!beneficiary.name) ) && (
              <>
                <div className="inflow-sidebar-members-title">
                  <Menu02Text>
                    <p>Miembros</p>
                  </Menu02Text>
                </div>
                <div className="inflow-sidebar-members-container">
                  {taker && url.pathname.indexOf('salud/beneficiarios') === -1 && (
                    <div
                      className="inflow-sidebar-members-item"
                      key={`insuredMember-taker`}
                    >
                      <InsuredCard
                        selected={selectedTaker}
                        insuredMember={taker}
                        goToDetail={
                          this.props.onGotoDetail
                            ? () => this.goToDetail(-1)
                            : undefined
                        }
                        textSubtitle={"Tomador"}
                      />
                    </div>
                  )}
                  {beneficiary && Object.entries(beneficiary).length>0 && url.pathname.indexOf('salud/beneficiarios') === -1 && (
                    <div
                      className="inflow-sidebar-members-item"
                      key={`insuredMember-beneficiary`}
                    >
                      <InsuredCard
                        selected={selectedBeneficiary}
                        insuredMember={beneficiary}
                        goToDetail={
                          this.props.onGotoDetail
                            ? () => this.goToDetail(-2)
                            : undefined
                        }
                        textSubtitle={"Asegurado"}
                      />
                    </div>
                  )}

                  {isPetArea && (url.pathname.indexOf('contratacion/nueva/mascotas/asegurado') > -1 || insuredMembers.length > 0)  && (
                    <>
                      <div className="inflow-sidebar-members-title">
                        <Menu02Text>
                          <p>Mascotas</p>
                        </Menu02Text>
                      </div>
                      <MainButton
                        text="Guardar mascota"
                        icon
                        iconImg={images.IconSaveWhite}
                        type="full-light"
                        withTab
                        disabled={this.isButtonDisabled(PetSteps.DatosMascotas)}
                        onClick={() => this.handleValidatePet(true)}
                      />
                    </>
                  )}

                  {insuredMembers.map((insuredMember, index: number) => (
                    url.pathname.indexOf('salud/beneficiarios') !== -1 ?
                      insuredMember.coverages.map((coverage: any) => (
                        covConfig.filter(ele => ele.config.reqBeneficiaries === true)
                          .map(ele => ele.code).includes(coverage.code) &&
                        <div
                          className="inflow-sidebar-members-item"
                          key={`insuredMember-${index}`}
                        >
                          <InsuredCard
                            selected={
                              !!selectedInsured &&
                              selectedInsured === index.toString()
                            }
                            insuredMember={insuredMember}
                            removeInsured={
                              index > 0 || insuredMembers.length > 1
                                ?
                                (url.pathname.indexOf('salud/beneficiarios') > -1 || url.pathname.indexOf('salud/garantias') > -1)
                                  ? undefined
                                  : () => removeInsured(index)
                                : undefined
                            }
                            goToDetail={
                              this.props.onGotoDetail
                                ? () => this.goToDetail(index)
                                : undefined
                            }
                            textSubtitle={insuredMember.tomador ? 'Tomador' : isPetArea? `Mascota asegurada ${index + 1}`
                              : "Asegurado"}
                          />
                        </div>
                      ))
                      : <div
                        className="inflow-sidebar-members-item"
                        key={`insuredMember-${index}`}
                      >
                        <InsuredCard
                          selected={
                            !!selectedInsured &&
                            selectedInsured === index.toString()
                          }
                          insuredMember={insuredMember}
                          removeInsured={isPetArea && this.isMenuItemDisabled(PetSteps.DatosMascotas)
                            ? undefined
                            : index > 0 || insuredMembers.length > 1 
                              ?
                              (url.pathname.indexOf('salud/beneficiarios') > -1 || url.pathname.indexOf('salud/garantias') > -1)
                                ? undefined
                                : () => removeInsured(index)
                              : undefined
                          }
                          goToDetail={isPetArea && this.isMenuItemDisabled(PetSteps.DatosMascotas)
                            ? undefined
                            : this.props.onGotoDetail
                              ? () => this.goToDetail(index)
                              : undefined
                          }
                          textSubtitle={insuredMember.tomador ? 'Tomador' : isPetArea? `Mascota asegurada ${index + 1}`
                          : "Asegurado"}
                        />
                      </div>
                  ))}
                 {
                    isPetArea && insuredMembers.length > 0 && insuredMembers.length < 10 && url.pathname.indexOf('contratacion') > -1 && (
                      <MainButton
                        text="Añadir mascota"
                        icon
                        iconImg={images.PlusWhite}
                        type="full-light"
                        withTab
                        disabled={!this.canAddPet()}
                        onClick={addNewInsured}
                      />
                    )
                  } 
                </div>
              </>
            )}
            <div className="inflow-sidebar-buttons-container">
              {isPetArea &&
                insuredMembers.length > 0 && (
                  <div className="inflow-sidebar-buttons-item">
                    <MainButton
                      text="Ver todos"
                      type="light"
                      onClick={() => {
                        this.props.history.push({
                          pathname: linkShowAll,
                          state: {
                            insuredMembers,
                          },
                        });
                      }}
                      disabled={this.isMenuItemDisabled(PetSteps.ProteccionDatos)}
                    />
                  </div>
                )}
              {
                isHealthArea &&
                url.pathname.indexOf('asegurados') > -1 &&
                url.pathname.indexOf('contratacion') > -1 &&
                (
                  <div className="inflow-buttons-item">
                    <MainButton
                      text="NUEVO ASEGURADO"
                      onClick={() => onClick ? onClick() : ''}
                    />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </InflowSidebarContainer>
    );
  }
}

export default withGeneral(withDynamicProducts(withPolicy(withRouter(InflowSidebar))));
