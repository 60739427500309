import styled from "styled-components";
import { colors } from "../../../../assets";
import { images } from "../../../../assets";

export const CampaignsCreateStepContainer = styled.div`
  .section {
    padding-top: 48px;
    &__title {
      font-family: Museo Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      color: ${colors["PR-001-100"]};
    }
    &__subtitle {
      font-family: Museo Sans;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      color: ${colors["TX-001-50"]};
      margin-top: 8px;
      margin-bottom: 28px;
    }
  }
  .col-30 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    margin-bottom: 50px;
  }
  .col-50 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-bottom: 50px;
  }
  .col-100 {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
  .card-container {
    transition: all 0.2s ease-in-out;
    min-height: 172px;
    padding: 24px;
    border-radius: 3px;
    margin-top: 48px;
    background-image: url(${images.WaveCampaignsCard});
    background-repeat: no-repeat;
    background-position: unset;
    background-position-x: right;
    &__subtitle {
      font-family: Museo Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #004f8b;
    }
    &__title {
      font-family: Museo Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 44px;
      line-height: 52px;
      color: #004f8b;
    }

    &__button {
      margin-top: 24px;
      width: 130px;
      div {
        max-height: 32px;
      }
    }

    &__info {
      margin-top: 24px;
      font-family: Museo Sans;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #848fa1;
      max-width: 195px;
    }

    &__list {
      li {
        font-family: Museo Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #004f8b;
        list-style: none;
        padding-left: 20px;
        position: relative;
        margin-top: 16px;
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background: #004f8b;
          position: absolute;
          top: 5px;
          left: 0;
        }
      }
    }
  }
  .card-shadow {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  }
  .card-opacity {
    opacity: 0.68;
  }
  .step-2-next-button {
    margin-top: 62px;
    width: 170px;
    div {
      max-height: 40px;
    }
  }
  .step-3-button-container {
    width: 100px;
  }

  .campaigns-drag-card {
    transition: all 0.3 ease-in-out;
    height: 54px;
    width: 300px;
    margin-bottom: 20px;
    background: #f0f4f8;
    border: 2px dashed #d8dbe1;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: grab;
    display: flex;
    align-items: center;
    padding-left: 14px;
    &__text {
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      color: #004f8b;
    }
  }
  .campaigns-drag-container {
    display: flex;
  }
  .campaigns-drag-header {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      height: 54px;
      align-items: center;
      margin-right: 14px;
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #848fa1;
    }
  }

  .floating,
  .dragged {
    cursor: grabbing;
    .campaigns-drag-card {
      border: 2px solid rgb(58, 75, 104, 0.2);
    }
  }
`;
