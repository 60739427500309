// React
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
// Assets
import { images } from "../../assets";
// Components
import MainButton from "../main-button";
import InsuredCard from "../insured-card/insured-card";
import { TravelHireMenuProps } from "./travel-hire-menu-style";
import { withPolicy } from "../../context/mixin/with-policy";

export enum StepsTravel {
    DatosTomador = 1,
    DatosAsegurados = 2,
    ProteccionDatos = 3,
    DetallePrevioContratacion = 4,
    Resumen = 5
}
export enum SimulationSteps {
    DatosRiesgo = 1,
    Resumen = 2
}
interface TravelHireMenuProps extends RouteComponentProps<any> {
    // Required
    initialCurrentStep: StepsTravel,
    initialInsureds: any[], // => { name?: string, lastname1?: string, lastname2?: string, [x: string]: any }
    codMediador: string,
    selectedMediator?: string,
    insuredSaved?: boolean;
    policyType: string,
    // Optional
    disableSubButtons?: boolean,
    currentNumInsured?: number,
    isSignedDocument?: boolean;
    isHired?: boolean;
    disableSignButton?: boolean;
    physicalSignature?: boolean;
    // Functions
    previousOnNavigation?: () => boolean,
    insuredOnAdd?: (insuredSaved: any) => Promise<void>,
    insuredOnRemove?: (index: number) => void,
    insuredOnEdit?: (index: number) => void,
    insuredOnSave?: () => void,
    questionnaireOnEdit?: (index: number) => void,
    questionnaireOnSave?: () => void,
    payHiring?: () => void,
    downloadDocs?: () => void,
    sendMailPrecontractualDocument?: (showModal?: boolean) => void,
    signPrecontractualDocument?: () => void,
    policy: any,
    saveHiring?: () => void
    sendSign?: () => void,
}

interface TravelHireMenuState {
    currentStep: StepsTravel,
    insureds: any[], // => { name?: string, lastname1?: string, lastname2?: string, [x: string]: any }
    showInsuredsOnDatosAsegurados: boolean,
    mediador: string
}

export class TravelHireMenuHelper {
    static getStepFromNavigationRoute(path: string, product: string): StepsTravel {
        if (path.indexOf(`/contratacion/nueva/travel/${product}/tomador`) !== -1) return StepsTravel.DatosTomador;
        else if (path.indexOf(`/contratacion/nueva/travel/${product}/asegurados`) !== -1) return StepsTravel.DatosAsegurados;
        else if (path.indexOf(`/contratacion/nueva/travel/${product}/proteccion-datos`) !== -1) return StepsTravel.ProteccionDatos;
        else if (path.indexOf(`/contratacion/nueva/travel/${product}/resultado`) !== -1) return StepsTravel.DetallePrevioContratacion;
        else if (path.indexOf(`/contratacion/nueva/travel/${product}/resumen`) !== -1) return StepsTravel.Resumen;
        else return StepsTravel.DatosTomador;
    }


    static getStepNavigationPath(step: StepsTravel, insuredsLength: number, product: string): string {
        let currentInsured = insuredsLength - 1;
        currentInsured = currentInsured < 0 ? 0 : currentInsured;
        switch (step) {
            case StepsTravel.DatosTomador: return `/contratacion/nueva/travel/${product}/tomador`;
            case StepsTravel.DatosAsegurados: return insuredsLength === 0 ?
                `/contratacion/nueva/travel/${product}/asegurados/` :
                `/contratacion/nueva/travel/${product}/asegurados/${currentInsured}`;
            case StepsTravel.ProteccionDatos: return `/contratacion/nueva/travel/${product}/proteccion-datos`;
            case StepsTravel.DetallePrevioContratacion: return `/contratacion/nueva/travel/${product}/resultado`;
            case StepsTravel.Resumen: return `/contratacion/nueva/travel/${product}/resumen`;
            default: return "";
        }
    }
    static getPreviousStep(currentStep: StepsTravel): StepsTravel {
        try {
            return currentStep - 1;
        } catch {
            return StepsTravel.DatosTomador;
        }
    }
    static getFirstStep(): StepsTravel {
        return StepsTravel.DatosTomador

    }
    static getSimulationStepNavigationPath(step: SimulationSteps, product: string, resultId?: string): string {
        switch (step) {
            case SimulationSteps.DatosRiesgo: return `/simulacion/nueva/travel/${product}/mediador`;
            case SimulationSteps.Resumen: return `/simulacion/nueva/travel/${product}/resultado`;
            default: return "";
        }
    }

    static getSimulationStepFromNavigationRoute(path: string, product: string): SimulationSteps {
        if (path.indexOf(`/simulacion/nueva/travel/${product}/asegurados`) !== -1) return SimulationSteps.DatosRiesgo;
        else if (path.indexOf(`/simulacion/nueva/travel/${product}/resultado`) !== -1) return SimulationSteps.Resumen;
        else return SimulationSteps.DatosRiesgo;
    }

    static getPreviousStepNavigationPath(currentStep: number, product: string): string {
        return this.getStepNavigationPath(this.getPreviousStep(currentStep), 1, product);
    }
    static getNextStep(currentStep: StepsTravel): StepsTravel {
        try {
            return currentStep + 1;
        } catch {
            return StepsTravel.DetallePrevioContratacion;
        }
    }

    static getNextStepNavigationPath(currentStep: StepsTravel, product: string): string {
        return this.getStepNavigationPath(this.getNextStep(currentStep), 1, product);
    }
}

class TravelHireMenuLayout extends React.Component<TravelHireMenuProps, TravelHireMenuState> {
    constructor(props: TravelHireMenuProps) {
        super(props);

        this.state = {
            currentStep: props.initialCurrentStep,
            insureds: props.initialInsureds,
            showInsuredsOnDatosAsegurados: props.initialCurrentStep === StepsTravel.DatosAsegurados,
            mediador: '',
        };
    }

    componentDidMount() {
        this.props.history.listen((location, action) => {
            var step = TravelHireMenuHelper.getStepFromNavigationRoute(location.pathname, this.props.policyType);
            this.setState({
                currentStep: step,
                showInsuredsOnDatosAsegurados: step === StepsTravel.DatosAsegurados,
            });
        });
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.initialInsureds !== this.props.initialInsureds) {
            this.setState({ insureds: nextProps.initialInsureds });
        }

    }

    getButtonCssName = (step: StepsTravel): string => step === this.state.currentStep ? "" : "google"

    isMenuItemDisabled = (step: StepsTravel): boolean => step > this.state.currentStep;

    isMenuItemSelected = (step: StepsTravel): boolean => step === this.state.currentStep;

    handleNavigation(step: StepsTravel): void {
        if (step === this.state.currentStep)
            return;

        let doNavigation = StepsTravel.DatosAsegurados || !this.props.previousOnNavigation || this.props.previousOnNavigation()
        if (doNavigation) {

            switch (step) {
                case StepsTravel.DatosAsegurados:
                    this.setState({ showInsuredsOnDatosAsegurados: true })
                    break;
                default:
                    this.props.history.push(TravelHireMenuHelper.getStepNavigationPath(step, this.state.insureds.length, this.props.policyType));
                    break;
            }

            if (step !== StepsTravel.DatosAsegurados)
                this.setState({ showInsuredsOnDatosAsegurados: false, })
        }
    }

    tryDownloadPrecontractualDocument(): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.downloadDocs)
                this.props.downloadDocs();
        }
    }

    tyrSendMailPrecontractualDocument() {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.sendMailPrecontractualDocument)
                this.props.sendMailPrecontractualDocument();
        }
    }

    trySignPrecontractualDocument() {
        if (this.props.sendSign)
            this.props.sendSign();
    }

    async tryPayHiring() {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.sendMailPrecontractualDocument && this.props.payHiring){
                await this.props.sendMailPrecontractualDocument(false);
                await this.props.payHiring();
            }
        }
    }

    tryQuestionnaireOnSave(): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.questionnaireOnSave)
                this.props.questionnaireOnSave();
        }
    }

    async tryInsuredOnAdd(): Promise<void> {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (this.props.insuredOnAdd)
                await this.props.insuredOnAdd(this.props.insuredSaved);
        }
    }

    tryInsuredOnSave(): void {
        if (this.props.insuredOnSave)
            this.props.insuredOnSave()
    }
    tryHiringOnSave(): void {
        if (this.props.saveHiring)
            this.props.saveHiring()
    }
    isSubMenuItemSelected = (step: StepsTravel, numInsured: number): boolean => step === this.state.currentStep && numInsured === (this.props.currentNumInsured ? this.props.currentNumInsured : 0);

    tryRemoveInsured(event: any, step: StepsTravel, numInsured: number): void {
        event.stopPropagation();
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (step === StepsTravel.DatosAsegurados && this.props.insuredOnRemove)
                this.props.insuredOnRemove(numInsured)
        }
    }

    tryGoToDetail(step: StepsTravel, numInsured: number): void {
        if (!this.props.previousOnNavigation || this.props.previousOnNavigation()) {
            if (step === StepsTravel.DatosAsegurados && this.props.insuredOnEdit)
                this.props.insuredOnEdit(numInsured)
        }
    }

    renderInsuredCards(renderForStep: StepsTravel, showRemoveIco: boolean = true, divClickGoToDetail: boolean = false): JSX.Element[] {
        let elements: JSX.Element[] = [];
        if (this.state.insureds) {
            for (let i = 0; i < this.state.insureds.length; i++) {
                elements.push(
                    <li key={`asegurado-${i}`}>
                        <InsuredCard
                            selected={this.isSubMenuItemSelected(renderForStep, i)}
                            insuredMember={this.state.insureds[i]}
                            removeInsured={!this.props.disableSubButtons && showRemoveIco ? (event: any) => this.tryRemoveInsured(event, renderForStep, i) : undefined}
                            goToDetail={() => this.tryGoToDetail(renderForStep, i)}
                            textSubtitle={"Asegurado"}
                            divClickGoToDetail={divClickGoToDetail}
                        />
                    </li>
                );
            }
        }
        return elements;
    }

    getMainButtonMenu(title: string, step: StepsTravel): JSX.Element {
        return <MainButton
            text={title}
            type={this.getButtonCssName(step)}
            withTab={true}
            disabled={this.isMenuItemDisabled(step)}
            onClick={() => this.handleNavigation(step)}
        />;
    }

    getMainButtonSubMenu(title: string, step: StepsTravel, onClick: Function, type: string | undefined = "full-light", image?: string | undefined, disabled: boolean | undefined = undefined): JSX.Element {
        return <MainButton
            text={title}
            icon={image !== undefined}
            iconImg={image}
            type={type}
            withTab={true}
            disabled={disabled !== undefined ? disabled : this.isMenuItemDisabled(step)}
            onClick={() => onClick()}
        />
    }

    render() {
        const { policy } = this.props
        const { currentStep } = this.state
        const productName = policy.product.products_name
        const productCardName = policy.product.products_cardName
        return (
            <TravelHireMenuProps>
                <div className="inflow-sidebar-type">
                    <img src={images.MiniWavePolicieTravel} alt="" />
                    <div className="inflow-sidebar-type-wrapper">
                        <div className="inflow-sidebar-type-text">
                            <p>PÓLIZA {productCardName}</p>
                        </div>
                        <div className="inflow-sidebar-type-title">
                            <p>{productName}</p>
                        </div>
                    </div>
                </div>
                <div className="cod-mediador">CÓD. MEDIADOR<br /><span>{this.state.mediador ? this.state.mediador : this.props.selectedMediator ? this.props.selectedMediator : this.props.codMediador}</span></div>
                <ul className="ulMenu">
                    {currentStep !== StepsTravel.Resumen ? 
                        <div>
                            <li>{this.getMainButtonMenu("DATOS DEL TOMADOR", StepsTravel.DatosTomador)}</li>
                            <li>
                                {this.getMainButtonMenu("DATOS DE LOS ASEGURADOS", StepsTravel.DatosAsegurados)}
                                <ul className="ulSubmenu" style={{ "display": (this.state.showInsuredsOnDatosAsegurados ? "block" : "none") }}>
                                    {
                                        !this.props.disableSubButtons && this.state.currentStep === StepsTravel.DatosAsegurados &&
                                        <li>{this.getMainButtonSubMenu("GUARDAR ASEGURADO", StepsTravel.DatosAsegurados, () => this.tryInsuredOnSave(), "full-light", images.IconSaveWhite)}</li>
                                    }
                                    {this.renderInsuredCards(StepsTravel.DatosAsegurados, this.state.currentStep === StepsTravel.DatosAsegurados, true)}
                                    {
                                        !this.props.disableSubButtons &&
                                        <li>{this.getMainButtonSubMenu("NUEVO ASEGURADO", StepsTravel.DatosAsegurados, () => this.tryInsuredOnAdd(), "full-light", images.PlusWhite, this.isMenuItemDisabled(StepsTravel.DatosAsegurados) || this.state.insureds.length === 10)}</li>
                                    }
                                </ul>
                            </li>
                            <li>{this.getMainButtonMenu("PROTECCIÓN DE DATOS", StepsTravel.ProteccionDatos)}</li>
                            <li>
                                {this.getMainButtonMenu("DETALLE PREV. CONTRATACIÓN", StepsTravel.DetallePrevioContratacion)}
                                <ul className="ulSubmenu" style={{ "display": (true ? "block" : "none") }}>
                                    <li>
                                        {
                                            this.state.currentStep === StepsTravel.DetallePrevioContratacion &&
                                            this.getMainButtonSubMenu("GUARDAR", StepsTravel.DetallePrevioContratacion, () => this.tryHiringOnSave(), "full-light")
                                        }
                                        {
                                            this.state.currentStep === StepsTravel.DetallePrevioContratacion &&
                                            this.getMainButtonSubMenu("DESCARGAR", StepsTravel.DetallePrevioContratacion, () => this.tryDownloadPrecontractualDocument(), "full-light")
                                        }
                                        {
                                            this.state.currentStep === StepsTravel.DetallePrevioContratacion &&
                                            this.getMainButtonSubMenu("ENVIAR POR EMAIL", StepsTravel.DetallePrevioContratacion, () => this.tyrSendMailPrecontractualDocument(), "full-light")
                                        }
                                        {
                                            this.state.currentStep === StepsTravel.DetallePrevioContratacion &&
                                            this.getMainButtonSubMenu(
                                                "PAGAR AHORA",
                                                StepsTravel.DetallePrevioContratacion,
                                                () => this.tryPayHiring(),
                                                "full-light",
                                                undefined,
                                                this.props.isHired)
                                        }
                                    </li>
                                </ul>
                            </li>
                        </div>
                        :
                        <ul className="ulSubmenu" style={{ "display": (true ? "block" : "none") }}>
                            <li>
                                {
                                    this.state.currentStep === StepsTravel.Resumen &&
                                    this.getMainButtonSubMenu("TERMINAR", StepsTravel.Resumen, () => this.props.history.push('/'), "full-light")
                                }
                            </li>
                        </ul>
                    }
                </ul>
            </TravelHireMenuProps>
        );
    }
}

export const TravelHireMenu = withPolicy(withRouter(TravelHireMenuLayout))
