import * as React from "react";
import { CardCampaignWeekStyle } from "./card-campaign-week-style";
import Title03Text from "../components-text/title-03-text";
import MenuText from "../components-text/menu-text";
import { images } from "../../assets";
import NoteText from "../components-text/note-text";
import Body02Text from "../components-text/body-02-text";
import HeadsTitleText from "../components-text/heads-title-text";
import moment from "moment";
import { PRODUCT_TYPE } from "../../constants/policyType";

export interface IStat {
  points?: number;
  policies?: number;
  sum?: number;
}

export interface ICardCampaignWeekProps {
  type?: string;
  disable?: boolean;
  date: string;
  conditionProducts?: string[];
  isTotal?: boolean;
  stats?: {
    tranquility: IStat;
    mortgage: IStat;
    death: IStat;
    pet: IStat;
  };
}

export interface ICardCampaignWeekState { }

export default class CardCampaignWeek extends React.Component<
  ICardCampaignWeekProps,
  ICardCampaignWeekState
  > {
  constructor(props: ICardCampaignWeekProps) {
    super(props);

    this.state = { complete: true };
  }

  public render() {
    const { type, disable, date, stats, conditionProducts, isTotal } = this.props;
    return (
      <CardCampaignWeekStyle
        className={`${type} ${disable && "disable"}`}
        disabled={disable}
      >
        {/* <div className="card-campaign-week-subtitle">
          <MenuText>semana 1</MenuText>
        </div> */}
        <div className="card-campaign-week-title">
          <div className="card-campaign-week-title__text">
            <Title03Text>{isTotal ? 'Total acumulado' : `Acumulado hasta el ${moment(date).format(
              "DD [de] MMMM"
            )}`}</Title03Text>
          </div>
          {/* <div
            className="card-campaign-week-title__icon"
            onClick={() => (!complete ? console.log("modal") : {})}
          >
            {disable ? (
              <img src={images.PlusWhite} alt="" />
            ) : (
              <img
                src={complete ? images.IconTickWhite : images.PlusBlue}
                alt=""
              />
            )}
          </div> */}
        </div>
        {!disable && stats && conditionProducts && (
          <div className="card-campaign-week-table">
            {/*  */}
            {conditionProducts.includes(PRODUCT_TYPE.TRANQUILITY) && stats.tranquility && (
              <div className="card-campaign-week-table-item">
                <div className="card-campaign-week-table-item__title">
                  <Body02Text>Pólizas tranquilidad</Body02Text>
                </div>
                <div className="card-campaign-week-table-item-text">
                  <HeadsTitleText>{stats.tranquility.policies}</HeadsTitleText>
                </div>
              </div>
            )}

            {/*  */}
            {/*conditionProducts.includes("tranquility_plus") && (
              <div className="card-campaign-week-table-item complete">
                <div className="card-campaign-week-table-item__title">
                  <Body02Text>Pólizas tranquilidad plus</Body02Text>
                </div>
                <div className="card-campaign-week-table-item-text">
                  <HeadsTitleText>{stats.tranquilityPlus.count}</HeadsTitleText>
                </div>
              </div>
            )*/}
            {/*  */}
            {conditionProducts.includes(PRODUCT_TYPE.MORTGAGE) && stats.mortgage && (
              <div className="card-campaign-week-table-item">
                <div className="card-campaign-week-table-item__title">
                  <Body02Text>Pólizas hipoteca</Body02Text>
                </div>
                <div className="card-campaign-week-table-item-text">
                  <HeadsTitleText>{stats.mortgage.policies}</HeadsTitleText>
                </div>
              </div>
            )}
            {/*  */}
            {/*conditionProducts.includes("woman") && (
              <div className="card-campaign-week-table-item">
                <div className="card-campaign-week-table-item__title">
                  <Body02Text>Pólizas mujer</Body02Text>
                </div>
                <div className="card-campaign-week-table-item-text">
                  <HeadsTitleText>{stats.woman.count}</HeadsTitleText>
                </div>
              </div>
            )*/}
            {/*  */}
            {/*conditionProducts.includes("return") && (
              <div className="card-campaign-week-table-item">
                <div className="card-campaign-week-table-item__title">
                  <Body02Text>Pólizas retorno</Body02Text>
                </div>
                <div className="card-campaign-week-table-item-text">
                  <HeadsTitleText>{stats.return.count}</HeadsTitleText>
                </div>
              </div>
            )*/}
            {/*  */}
            {conditionProducts.includes("death") && stats.death && (
              <div className="card-campaign-week-table-item">
                <div className="card-campaign-week-table-item__title">
                  <Body02Text>Pólizas decesos</Body02Text>
                </div>
                <div className="card-campaign-week-table-item-text">
                  <HeadsTitleText>{stats.death.policies}</HeadsTitleText>
                </div>
              </div>
            )}
            {/*  */}
            {conditionProducts.includes("pet") && stats.pet && (
              <div className="card-campaign-week-table-item">
                <div className="card-campaign-week-table-item__title">
                  <Body02Text>Pólizas mascotas</Body02Text>
                </div>
                <div className="card-campaign-week-table-item-text">
                  <HeadsTitleText>{stats.pet.policies}</HeadsTitleText>
                </div>
              </div>
            )}
          </div>
        )}
      </CardCampaignWeekStyle>
    );
  }
}
