import styled from "styled-components";
import { colors, mediaqueries } from "../../assets";

export const MainSidebarContainer = styled.div`
  background: ${colors["PR-001-100"]};
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 230px;
  padding: 37px 22px;
  height: 100vh;
  z-index: 39;
  overflow-y: scroll;

  .footer-side-configuration {
    cursor: pointer;
    color: inherit;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  @media (max-height: 700px) {
  }

  .sidebar-logo {
    display: block;
    margin-bottom: 90px;
    a {
      display: inline-block;
    }

    img {
      max-width: 115px;
    }
  }

  .sidebar-nav {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .sidebar-nav-main {
    padding-bottom: 24px;
  }

  .sidebar-nav-footer-container {
    padding: 20px 0 32px;

    &:first-child {
      border-top: 1px solid #ffffff1a;
      // border-bottom: 1px solid #ffffff1a;
    }
  }

  .sidebar-nav-footer-item {
    display: block;
    margin-bottom: 18px;

    a {
      display: inline-block;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${mediaqueries["max-height-780"]} {
    .sidebar-logo {
      margin-bottom: 60px;
      img {
        max-width: 90px;
      }
    }

    .sidebar-nav-footer-container {
      padding: 12px 0 20px;
      &:first-child {
        padding: 14px 0 22px;
      }
    }
  }

  ${mediaqueries["max-height-600"]} {
    .sidebar-nav {
      height: 86%;
    }
  }
`;
