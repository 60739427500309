import { formatter} from "../utils/utility";

export const CAPITAL_OPTIONS_RC = [
    { value: "300000", label: formatter.format(Number("300000"))},
    { value: "200000", label: formatter.format(Number("200000"))},
    { value: "150000", label: formatter.format(Number("150000"))},
  ];
export const CAPITAL_OPTIONS_AV04 = [
    { value: "1000", label: formatter.format(Number("1000"))},
  ];
export const CAPITAL_OPTIONS_AV05 = [
    { value: "1000", label: formatter.format(Number("1000"))},
  ];
export const CAPITAL_OPTIONS_AV06 = [
    { value: "150", label: formatter.format(Number("150"))},
  ];