import React, { Component } from 'react';
import { FilterInputContainer } from './filter-input-style';
import { images } from '../../assets';
import NoteText from '../components-text/note-text';


interface FilterInputProps {
    required?: boolean;
    disabled?: boolean;
    showError?: boolean;
    errorText?: string;
    img?: string;
    placeholder?: string;
    onChange: Function;
    onEnter?: Function;
    initValue?: string;
    cleanValueDisabled?: boolean;
    maxLength?: number;
    resetSearch?: string;
}

class FilterInput extends Component<FilterInputProps, {}> {

    state = {
        inputValue: this.props.initValue ? this.props.initValue : '',
        enterClicked: false,
    }

    public static defaultProps = {       
        img: images.IconSearchBlue,
    }

    componentDidUpdate(prevProps: FilterInputProps){
        if (prevProps.resetSearch !== this.props.resetSearch) {
            this.setState({ inputValue: this.props.resetSearch })
        }
    }

    emptyInput() {
        const { onChange } = this.props;
        onChange('');
        this.setState({ enterClicked: false });
        this.setState({
            inputValue: '',
        });
    }

    updateInputValue(evt: any) {
        const { onChange } = this.props;
        this.setState({
            inputValue: evt.target.value,
        });

        onChange(evt.target.value);
    }

    handleKeyDown = (e: any) => {
        const { onEnter } = this.props;
        if (e.key === 'Enter' && onEnter) {
            this.handleEnter();
        }
    }

    handleEnter = () => {
        const { onEnter } = this.props;
        this.setState({ enterClicked: true });
        onEnter && onEnter();
    }

    render() {
        const { errorText, showError, placeholder, disabled, required, cleanValueDisabled, maxLength } = this.props;
        const { inputValue, enterClicked } = this.state;

        return (
            <FilterInputContainer className={`${disabled ? "disabled" : ""} ${showError ? "error" : ""}`}>
                <div className={`input-filter-container ${required ? "required" : ""}`}>
                    <input placeholder={placeholder} value={cleanValueDisabled ? this.state.inputValue = '' : inputValue} onChange={(evt) => this.updateInputValue(evt)} type="text" onKeyDown={this.handleKeyDown} disabled = {disabled} maxLength={maxLength}/>
                    {!disabled && inputValue.length > 0 ? <div className="input-filter-icon" onClick={() => inputValue ? this.emptyInput() : this.handleEnter()} >
                        <button className="deleteButton"/>
                    </div> : ""}
                </div>
                <label>{placeholder}</label>
                <div className="input-filter-error">
                    {showError && <NoteText><p>{errorText}</p></NoteText>}
                </div>
            </FilterInputContainer>
        )
    }
}

export default FilterInput;