import React, { FC, useEffect, useState } from "react";
import { UseFormFunctionsProps } from "../../hooks/hooks.types";
import { RequiredDocDataContainer } from "../../../../hirings/new-hiring/common/required-documentation/required-doc-data-style";
import SelectBox from "../../../../../components/select-box/select-box";
import { deepGet, downloadFile } from '../../../../../utils/utility';
import { ConstantsResponseBoxDto } from "../../../../hirings/new-hiring/common/required-documentation/required-doc-data-layout";
import { getFileSize, getType, loadDocumentTypes, tableColumns } from "./utils";
import InputBox from "../../../../../components/input-box/input-box";
import { Taker } from "../newInsuredStepper.types";
import MainButton from "../../../../../components/main-button";
import { acceptedFileMimeType } from "../../../../../constants/acceptedFileMimeType";
import { images } from "../../../../../assets";
import BodyText from "../../../../../components/components-text/body-text";
import { MainTableContainer } from "../../../../../components/main-table/main-table-style";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";

const RequiredDocumentsStep: FC<UseFormFunctionsProps> = ({
  values,
  errors,
  handleChange,
  hideTitle = false,
  hideInsurance = false,
  docTypeDisabled = false,
  required = false,
}) => {
  const [documentTypes, setDocumentTypes] = useState<ConstantsResponseBoxDto[]>(
    []
  );
  const [actualForm, setActualForm] = useState<{type: string, description: string, file: {}}>({
    type: "",
    description: "",
    file: {},
  });
  const [modalError, setModalError] = useState<{status: boolean, text: string}>({ status: false, text: "" });
  const [showMaxSizeModal, setShowMaxSizeModal] = useState<boolean>(false);
  const [uploadDocuments, setUploadDocuments] = useState<any[]>(deepGet(values, 'documents.uploadDocuments', []) || []);
  const [confirmationDeleteModal, setConfirmationDeleteModal] = useState({ status: false, index: -1});

  const taker: Taker = deepGet(values, 'taker') || { name: '', lastname1: '', lastname2: '', docNumber: ''};
  const takerCompleteName = `${taker.name} ${taker.lastname1} ${taker.lastname2}`;
  const associatesFilter = [{index:'0', name: takerCompleteName, document: taker.docNumber, title: `${takerCompleteName} (${taker.docNumber})`}]

  useEffect(() => {
    loadDocumentTypes(4, setDocumentTypes );
  }, [])

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setActualForm((prevValue) => ({...prevValue, file}));
    }
  };

  const attachDocument = () => {
    const newUploadDocuments = [...uploadDocuments, actualForm];
    const filesSize = newUploadDocuments.reduce((totalSize: number, file: {file: {size: number}}) => totalSize + file.file.size, 0);
    if (filesSize >= (1048576 * 5)) {
      setShowMaxSizeModal(true);
      return;
    }
    setUploadDocuments(newUploadDocuments);
    handleChange("uploadDocuments", newUploadDocuments, "documents");
    setActualForm({ type: hideInsurance ? 'DR04 - FOTOCOPIA DNI-CIFDR04' : '', description: '', file: {}});
  };

  const deleteDocument = () => {
    const newUploadsDocuments = uploadDocuments.filter((item, index) => index !== confirmationDeleteModal.index);
    setUploadDocuments(newUploadsDocuments);
    handleChange('uploadDocuments', newUploadsDocuments, 'documents');
  }

  const downloadDocument = (document: any) => {
    if (document) {
      downloadFile(document.file, document.file.name, document.file.type, false);
    } else {
      setModalError({status: true, text: 'Ha habido un error en la carga del fichero'})
    }
  }

  return (
    <RequiredDocDataContainer>
      {!hideTitle && (
        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <h2>{"DOCUMENTACION REQUERIDA"}</h2>
          </div>
        </div>
      )}

      <div className="content-wrapper bottomMargin20 filesSection">
        <div className="personal-data-wrapper">
          <div>
            <div className="data-title">
              <h5>ADJUNTAR DOCUMENTOS</h5>
            </div>

            <div className="colSin-100 wrap">
              <div className={`rightPadding20 ${ !hideInsurance ? "colSin-50" : "colSin-100" }`} >
                <div className="selectBox">
                  <SelectBox
                    required={false}
                    optionsText={documentTypes}
                    optionKey={"value"}
                    optionValue={"label"}
                    defaultValue={docTypeDisabled ? "DR04" : actualForm.type}
                    initialValue={docTypeDisabled ? "DR04" : actualForm.type}
                    onChange={(value: string) =>
                      setActualForm((prevValue) => ({
                        ...prevValue,
                        type: value,
                      }))
                    }
                    disabled={docTypeDisabled}
                  />
                  <label>Tipo de documento</label>
                </div>
              </div>

              {!hideInsurance && (
                <div className="colSin-50 rightPadding20">
                  <div className="selectBox">
                    <SelectBox
                      optionsText={associatesFilter}
                      optionKey={"document"}
                      optionValue={"title"}
                      defaultValue={deepGet(values, "taker.docNumber", "")}
                      initialValue={deepGet(values, "taker.docNumber", "")}
                      onChange={() => {}}
                      disabled={true}
                    />
                    <label>Asegurado</label>
                  </div>
                </div>
              )}
              {!hideInsurance && (
                <div className="colSin-100 rightPadding20">
                  <div className="inputBox custom-file-upload">
                    <div className="colSin-100">
                      <InputBox
                        type={"text"}
                        required={false}
                        errorCode={""}
                        cleanValue={true}
                        value={actualForm.description}
                        onChange={(value: string) =>
                          setActualForm((prevValue) => ({
                            ...prevValue,
                            description: value,
                          }))
                        }
                      />
                    </div>
                    <label>Descripción</label>
                  </div>
                </div>
              )}
            </div>

            <div className="colSin-100 topMargin20">
              <div className="colSin-50 rightPadding20">
                <div className="inputBox custom-file-upload">
                  <div className="colSin-70">
                    <input
                      type="text"
                      readOnly
                      value={deepGet(actualForm, 'file.name', '')}
                    />
                    {required && errors.documents && <p className="error">* Este campo es obligatorio</p>}
                  </div>

                  <div className="colSin-30">
                    <label className="custom-file-upload-button topMargin5">
                      <input
                        type="file"
                        onChange={onFileChange}
                        accept={acceptedFileMimeType.toString()}
                      />
                      <MainButton
                        onClick={() => {
                          return true;
                        }}
                        text="Buscar"
                      />
                    </label>
                  </div>

                  <label>Adjuntar documento</label>
                </div>
              </div>

              <div className="colSin-50 rightPadding20 topMargin15">
                <label className="custom-file-upload">
                  <div className={"upload-buttons"}>
                    <MainButton
                      type={
                        deepGet(actualForm, "file.name", "") === ""
                          ? "disabled"
                          : ""
                      }
                      onClick={attachDocument}
                      text="Adjuntar Documento"
                      icon={true}
                      iconImg={images.IconUploadBlue}
                      disabled={deepGet(actualForm, "file.name", "") === ""}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="colSin-100 topMargin20">
            {uploadDocuments.length > 0 && (
              <>
                <MainTableContainer className="table noZebra uploadedTable">
                  <thead>
                    <tr>
                      {tableColumns.map((column, index) => (
                        <th key={`column-${index}`}>
                          <BodyText className="dimmed-mini-title">
                            <p>{column}</p>
                          </BodyText>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {uploadDocuments
                      .map(
                        (row, index) =>
                          !row.hidden && (
                            <tr className="main-table-row" key={`row-${index}`}>
                              <td>
                                <BodyText className="dimmed-mini-body">
                                  <p>{getType(row.type, documentTypes)}</p>
                                  <p>{row.type}</p>
                                </BodyText>
                              </td>
                              <td>
                                <BodyText className="dimmed-mini-body">
                                  <p> {takerCompleteName}(Nuevo) </p>
                                </BodyText>
                              </td>
                              <td>
                                <div className="uploadedFileTable">
                                  <MainButton
                                    key={`downloadBtnTable${index}`}
                                    onClick={() => row && downloadDocument(row) }
                                    text=""
                                    icon={true}
                                    iconImg={images.IconEyewhite}
                                  />
                                  {!row.id && (
                                    <MainButton
                                      key={`deleteBtnTable${index}`}
                                      onClick={() => setConfirmationDeleteModal({status: true, index})}
                                      text=""
                                      icon={true}
                                      iconImg={images.IconDeleteWhite}
                                    />
                                  )}
                                </div>
                              </td>
                              <td>
                                <BodyText className="dimmed-mini-body">
                                  <p>
                                    {row.size
                                      ? getFileSize(row.size)
                                      : row.file
                                      ? getFileSize(row.file.size)
                                      : ""}
                                  </p>
                                </BodyText>
                              </td>
                            </tr>
                          )
                      )
                      .reverse()}
                  </tbody>
                </MainTableContainer>
                <p className="text-max-size">
                  * El tamaño máximo de la totalidad de ficheros a adjuntar es
                  de 5MB.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <LeftInfoModal
        img={images.IconEraseRed}
        mainTitle={"¿Quieres borrar este documento?"}
        mainText={"Una vez borrado, no podrá volver a recuperarlo."}
        buttonText="Eliminar"
        close={() => setConfirmationDeleteModal({ status: false, index: -1})}
        active={confirmationDeleteModal.status}
        onClickAccept={async () => deleteDocument()}
        className="error"
      />
      <ErrorModalContainer 
        className={`${modalError.status && `active`}`}
      >
        <div className="recover-modal">
          <div className="server-error">
            <p>{modalError.text}</p>
          </div>
          <div className="button-error">
            <MainButton text="Aceptar" onClick={() => setModalError({ status: false, text: ''})} />
          </div>
        </div>
      </ErrorModalContainer>
      <LeftInfoModal
        img={images.IconWarningYellow}
        mainTitle={"Aviso de documentación"}
        mainText={
          "La documentación adjunta supera el máximo del tamaño establecido (5MB)."
        }
        secondaryText={"Por favor, corrija antes de continuar."}
        buttonText="Aceptar"
        noSecondaryButton={true}
        close={() => setShowMaxSizeModal(false)}
        active={showMaxSizeModal}
        onClickAccept={() =>setShowMaxSizeModal(false)}
        className="info"
      />
    </RequiredDocDataContainer>
  );
};

export default RequiredDocumentsStep;
