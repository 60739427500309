import { deepGet } from "../../../../utils/utility";

type QuestionnaireResponse = {
  id: string;
  parentId: string;
  isEnabled: boolean;
  isRequired: boolean;
  errorCode: string;
  value: string;
  subValue: string;
};

export type ParsedResponse = {
  question: string;
  response: string;
  input: string;
};

export function parseResponses(
  responses: Record<string, QuestionnaireResponse>,
  drugs: { checked: string; subValue: string }
): ParsedResponse[] {
  const responseArray = Object.values(responses).map((response) => {
    if (response.id === "2001A1") {
      return getReturn( "drinkType", response.value, deepGet(drinkText, response.value, "") || "" );
    } else if (response.id === "2001A2") {
      return getReturn( "drinkFrecuency",  response.value, deepGet(drinkFrequency, response.value, "") || "" );
    } else if (response.id === "1001A1") {
        return getReturn( "cigaretteType",  response.value, deepGet(cigaretteText, response.value, "") || "" );
    } else if (response.id === "1001A2") {
        return getReturn( "cigaretteFrecuency",  response.value, deepGet(cigaretteFrequency, response.value, "") || "" );
    } else {
      return getReturn(response.id, response.value, response.subValue);
    }
  });
  if (drugs) {
    return [
      ...responseArray,
      { question: "drugs", response: drugs.checked, input: drugs.subValue },
    ];
  } else {
    return responseArray;
  }
}

const getReturn = (question: string, response: string, input: string) => {
  return {
    question,
    response,
    input,
  };
};
const drinkText = { AT1: "Cerveza/Vino", AT2: "Licores", AT3: "Destilados" };
const drinkFrequency = { AC1: "De 1 a 2", AC2: "De 2 a 3", AC3: "de 3 a 5", AC4: "Más de 5" };
const cigaretteText = { FT1: "Cigarrillos", FT2: "Puros" };
const cigaretteFrequency = { FC1: "De 1 a 2", FC2: "De 2 a 5", FC3: "de 5 a 10", FC4: "de 10 a 20", FC5: "Más de 20"};
