import { ValueFormHealth, ValueFormHealthAddress } from "../../../../../../constants/health";

const DefaultValueFormHealthAddress = (isDisabled: boolean): ValueFormHealthAddress => {
    return {
        value: "",
        label: "",
        errorCode: "",
        code: "",
        disabled: isDisabled,
    };
}

const DefaultValueFormHealth = (isDisabled: boolean): ValueFormHealth => {
    return {
        value: "",
        errorCode: "",
        disabled: isDisabled,
        code:""
    };
}

export interface AddressDataItem {
    value: string;
    code: string;
    label: string;
}

export interface AddressDataDto {
    address: ValueFormHealthAddress;
    wayType: string;
    number: ValueFormHealth;
    other: ValueFormHealth;
    postalCode: ValueFormHealthAddress;
    town: ValueFormHealthAddress;
    province: ValueFormHealthAddress;
    provinceLabel: string;
    socialReason: ValueFormHealth;
    responsable: ValueFormHealth;
    sameAsTaker: boolean;
    
}

export const DefaultAddressDataDto = (): AddressDataDto => {
    return {
        address: DefaultValueFormHealthAddress(true),
        wayType: '',
        number: DefaultValueFormHealth(false),
        other: DefaultValueFormHealth(false),
        postalCode: DefaultValueFormHealthAddress(true),
        town: DefaultValueFormHealthAddress(true),
        province: DefaultValueFormHealthAddress(false),
        provinceLabel: '',
        socialReason: DefaultValueFormHealth(false),
        responsable: DefaultValueFormHealth(false),
        sameAsTaker: false,
    };
}
