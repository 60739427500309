import React from "react";
import { withRouter } from "react-router-dom";
import { PendantHiringContainer } from "./pendant-hiring-style";
import FilterInput from "../../../components/filter-input/filter-input-layout";
import MainButton from "../../../components/main-button";
import NoteText from "../../../components/components-text/note-text";
import MainTable from "../../../components/main-table";
import Pagination from "../../../components/pagination";
import InfoModal from "../../../components/info-modal";
import { images } from "../../../assets";
import ChooseHiringModal from "../../../components/choose-hiring-modal";
import PendantHiringScreen from "./pendant-hiring-screen";
import { withGeneral } from "../../../context/mixin/with-general";
import Body02Text from "../../../components/components-text/body-02-text";
import SimulationServices from "../../../services/SimulationServices";
import LeftInfoModal from "../../../components/left-info-modal";
import {
  AREA_NAMES,
} from "../../../constants/policyType";
import { getDataForm } from "../../../utils/mapData";
import { withCoverages } from "../../../context/mixin/with-coverages";
import SelectBox from "../../../components/select-box/select-box";
import CalendarBox from "../../../components/calendar-box/calendar-box";
import { HIRING_STATUS } from "../new-hiring/types";
import MultiSelect from "../../../components/multi-select-box/multi-select-box";
import { PendantHiringProps, PendantHiringState } from "./pensant-hiring-dto";
import { getRelatedMediatorsCodes } from "../../../utils/mediatorCode";
import { ErrorModalContainer } from "../../../components/error-modal/error-modal-style";
import Loader from "../../../components/loader";
import ProductServices from "../../../services/ProductServices";
import { withDynamicProducts } from "../../../context/mixin/with-dynamic-products";

class PendantHiring extends PendantHiringScreen {

  constructor(props: PendantHiringProps) {
    super(props);
    this.state = {
      searchParams: props.history.location.search,
      showChooseHiringModal:
        props.history.location.search.indexOf("new=true") > -1 ? true : false,
      showInfoModal: false,
      hiring: [],
      hiringObj: [],
      hiringFilter: [],
      search: "",
      previousSearch: "",
      page: 1,
      totalPages: 0,
      showItems: 10,
      order: "createdAt",
      ascendent: false,
      infoModalMainText: "",
      infoModalClass: "", 
      infoModalOnClickAccept: () => { },
      imgLeftInfoModal: "",
      classNameLeftInfoModal: "",
      mainTitleLeftInfoModal: "",
      mainTextLeftInfoModal: "",
      buttonTextLeftInfoModal: "",
      showLeftInfoModal: false,
      searched: false,
      filter: {
        policyStatus: {
          label: '',
          value: '',
          search: '',
          type: ''
        },
        policyType: {
          label: '',
          value: '',
        },
        takerNIF: {
          value: '',
          errorCode: '',
        },
        dateSince: {
          value: '',
          errorCode: '',
        },
        dateUntil: {
          value: '',
          errorCode: '',
        },
      },
      previousFilter: {
        policyStatus: {
          label: '',
          value: '',
          search: '',
          type: ''
        },
        policyType: {
          label: '',
          value: '',
        },
        takerNIF: {
          value: '',
          errorCode: '',
        },
        dateSince: {
          value: '',
          errorCode: '',
        },
        dateUntil: {
          value: '',
          errorCode: '',
        },
      },
      relatedMediatorCodes: getRelatedMediatorsCodes(props.mediator),
      selectedMediatorCodes: [`${props.mediator.mediatorCode} - ${props.mediator.mediatorName.trim()} - ${props.mediator.orderNif}`],
      previousSelectedMediatorCodes: [],
      multiSelectError: '',
      isSearch: true,
      rowCount: 0,
      error: '',
      showError: false,
      loading: true,
      policyTypes: [
        {
          value: '', 
          label: ''
        }
      ],
    };
  }
  llamarGetHiring = async (value: string) => {
    const { productsByAreaName, allProducts } = this.props
    if (value !== "page") {
      await this.savePrevState()
    }
    if(Object.keys(productsByAreaName).length!==0 && Object.keys(allProducts).length!==0){
      this.setState({loading:false})
      this.getHiring();
    }
  }
  async componentDidMount() {
    await this.getPolicyTypes()
    this.llamarGetHiring("didmount");
  }

  async getPolicyTypes () {
    const allPolicyTypes =  [{ value: 'all', label: 'Todas' }]

    const args = {
      loader: true,
      setLoading: () => { }
    };
    const policyTypesDB= await ProductServices.getPolicyTypes(args)

    const policyTypes = [...allPolicyTypes, ...policyTypesDB] 
    
    this.setState({policyTypes})
  }

  static getDerivedStateFromProps(
    nextProps: PendantHiringProps,
    prevState: PendantHiringState
  ) {
    if (nextProps.history.location.search !== prevState.searchParams) {
      return { searchParams: nextProps.history.location.search };
    } else return null;
  }

  componentDidUpdate(
    prevProps: PendantHiringProps,
    prevState: PendantHiringState
  ) {
    const { productsByAreaName, allProducts } = this.props
    if (prevState.searchParams !== this.state.searchParams) {
      this.setState({
        showChooseHiringModal:
          this.state.searchParams.indexOf("new=true") > -1 ? true : false,
      });
    }
    if(Object.keys(prevProps.allProducts).length===0 && Object.keys(allProducts).length!==0 && Object.keys(productsByAreaName).length!==0 || 
    Object.keys(prevProps.productsByAreaName).length===0 && Object.keys(productsByAreaName).length!==0 && Object.keys(allProducts).length!==0){
      this.setState({loading:false})
      this.getHiring();
    }
  }

  getColumns = () => [
    {
      text: "id",
      filter: false,
      ascendent: true,
      key: "inline",
    },
    {
      text: "Producto",
      filter: true,
      ascendent: true,
      key: "inline"
    },
    {
      text: "Tomador",
      filter: true,
      ascendent: true,
      key: "inline"
    },
    // {
    //   text: "Nif",
    //   filter: true,
    //   ascendent: true,
    //   key: "responsive"
    // },
    {
      text: "Fecha creación",
      filter: true,
      ascendent: false,
      key: "inline"
    },
    {
      text: "Expira",
      filter: true,
      ascendent: true,
      key: "inline"
    },
    {
      text: "Estado",
      filter: true,
      ascendent: true,
      key: "inline"
    },
    {
      text: "Mediador",
      filter: false,
      ascendent: true,
      key: "inline"
    },
    {
      text: "Usuario",
      filter: false,
      ascendent: true,
      key: "inline"
    },
    {
      text: "",
      filter: false,
      ascendent: true,
      key: "responsive"
    },
  ];

  changeValue = (value: string, key: string = "") => {
    let { filter } = this.state;

    switch (key) {
      case 'nif':
        filter.takerNIF.value = value;
        break;

      case 'dateSince':
        filter.dateSince.value = value;
        break;

      case 'dateUntil':
        filter.dateUntil.value = value;
        break;

      case 'search':

        this.setState({ search: value });
        break;
    }

    this.setState({ filter });
  };

  handleOrder = (column: string, ascendent: boolean | undefined) => {
    switch (column) {
      case "taker":
        this.setState({ order: "takerFullName", ascendent }, () =>
          this.llamarGetHiring("taker")
        );
        break;
      case "expiration":
        this.setState({ order: "expiration", ascendent }, () =>
          this.llamarGetHiring("expiration")
        );
        break;
      case "date":
        this.setState({ order: "createdAt", ascendent }, () =>
          this.llamarGetHiring("date")
        );
        break;
      case "nif":
        this.setState({ order: "takerNif", ascendent }, () =>
          this.llamarGetHiring("nif")
        );
        break;
      case "policyState":
      case "policyStatus":
        this.setState({ order: "status", ascendent }, () =>
          this.llamarGetHiring("policitystate")
        );
        break;
      case "policy-type":
        this.setState({ order: "productType", ascendent }, () =>
          this.llamarGetHiring("policy-type")
        );
        break;
      default:
        break;
    }
  };

  setPage = (page: number) => {
    this.setState({ isSearch: false, page }, () => this.llamarGetHiring("page"));
  };
  changePage = (page: number) => {
    this.setState({ isSearch: true, page })
  }
  closeHiringModal = () =>
    this.setState({ showChooseHiringModal: false }, () =>
      this.props.history.push("/contratacion/pendiente")
    );

  closeModal = () => this.setState({ showInfoModal: false });
  closeModalError = () => this.setState({ error: "", showError: false });

  openHiringModal = () => this.setState({ showChooseHiringModal: true });

  handleNewHiring = () => {
    this.setState({
      showInfoModal: true,
      infoModalMainText:
        "Vas a iniciar el proceso de contratación, ¿estás seguro?",
      infoModalClass: "", 
      infoModalOnClickAccept: () => this.goToNewHiring(),
    });
  };

  closeLeftInfoModal = () =>
    this.setState({ showLeftInfoModal: false }, () =>
      this.props.history.push("/contratacion/pendiente")
    );

  chooseHiring = async (ok: string, search: string) => {
    const { setLoading, history, checkProductArea, productsByAreaName, allProducts } = this.props;
    if (ok === "Si") {
      const response = await SimulationServices.getSimulation({
        setLoading: setLoading,
        loader: true,
        id: search,
      });
      
      if (response.id) {
        const product = allProducts[response.productType]
        const isPetArea = checkProductArea(AREA_NAMES.PET, response.productType)
        const productHiringLink = product.products_link

        const noInsuredsSimulation = JSON.parse(JSON.stringify(response.body));
        noInsuredsSimulation.insureds = [];
        history.push({
          pathname: `/contratacion${productHiringLink}`,
          state: {
            simulationId: response.id,
            dataForm: getDataForm(
              response.body,
              response.productType,
              product.products_coverageName,
              productsByAreaName,
              this.props.coverages,
            ),
            data: {
              ...(isPetArea
                ? noInsuredsSimulation
                : response.body),
              payment: {},
              beneficiaries: [],
              signData: {},
              loan: {},
            },
          },
        });
      } else {
        this.setState({
          imgLeftInfoModal: images.WarningError,
          classNameLeftInfoModal: "error",
          mainTitleLeftInfoModal: "No existe simulación",
          mainTextLeftInfoModal:
            "No se encuentra la simulación que has introducido.",
          buttonTextLeftInfoModal: "Cerrar",
          showLeftInfoModal: true,
          showChooseHiringModal: false,
        });
      }
    } else {
      this.props.history.push("/contratacion/nueva");
    }
  };

  handleSelectChange(value: string, type: string) {
    let { filter, policyTypes } = this.state;

    switch (type) {
      case 'policyType':
        const policyType = policyTypes.find((type) => type.value === value);
        policyType !== undefined && (filter.policyType = policyType);
        break;

      case 'policyStatus':
        const policyState = HIRING_STATUS.find((state) => state.value === value);
        policyState !== undefined && (filter.policyStatus = policyState);
        break;
    }

    this.setState({ filter })
  }

  handleSelectedMediatorChange(value: string[]) {
    let { multiSelectError } = this.state;
    if (value.length === 0)
      multiSelectError = 'Debes seleccionar al menos un mediador';
    else
      multiSelectError = '';
    this.setState({ selectedMediatorCodes: value, multiSelectError });
  }

  render() {
    const {
      buttonTextLeftInfoModal,
      classNameLeftInfoModal,
      filter,
      hiringFilter,
      imgLeftInfoModal,
      infoModalMainText,
      infoModalClass,
      infoModalOnClickAccept,
      mainTextLeftInfoModal,
      mainTitleLeftInfoModal,
      page,
      rowCount,
      searched,
      showChooseHiringModal,
      showInfoModal,
      showLeftInfoModal,
      totalPages,
      relatedMediatorCodes,
      selectedMediatorCodes,
      multiSelectError,
      error,
      showError,
      policyTypes
    } = this.state;

    const todasElement = policyTypes.find((element) => element.value === 'all')
    policyTypes.splice(policyTypes.findIndex((element) => element.value === 'all'), 1)
    if (todasElement)
      policyTypes.sort((a, b) => a.label.localeCompare(b.label)).unshift(todasElement)

    return (
      <PendantHiringContainer>
        {(this.state.loading) &&
          <Loader />
        }

        <div className="main-title-container">
          <h2>Contrataciones pendientes</h2>
        </div>
        <div className="main-filter-container height80">
          <div className="colSin-30 rightPadding20">
            <div className="inputBox">
              <FilterInput
                placeholder={"Buscador Contrataciones"}
                onChange={(value: string) => this.changeValue(value, "search")}
                onEnter={() => this.setState({ page: 1, totalPages: 0 }, () => this.llamarGetHiring("contrataciones"))}
              />
              <label>Buscador Contrataciones</label>
            </div>
          </div>
          <div className="colSin-65 rightPadding20 ">
            <div className="selectBox">
              <MultiSelect
                onChange={(value: string[]) => this.handleSelectedMediatorChange(value)}
                options={relatedMediatorCodes}
                defaultValues={selectedMediatorCodes}
                errorCode={multiSelectError}
                placeholder={''}
                required
              />
              <label>Mediador/es</label>
            </div>
          </div>
          <div className="colSin-25 topMargin15">
            <MainButton
              text="Hacer nueva"
              onClick={() => this.openHiringModal()}
            />
          </div>
        </div >

        <div className="main-filter-container height80">

          <div className="selectBox colSin-33 rightPadding20">
            <SelectBox
              optionsText={policyTypes}
              optionKey={"value"}
              optionValue={"label"}
              defaultValue={filter.policyType.label}
              onChange={(value: string) => this.handleSelectChange(value, 'policyType')}
              propValue={filter.policyType.label}
            />
            <label>Producto</label>
          </div>

          <div className="colSin-33 rightPadding20">
            <div className="selectBox">
              <SelectBox
                optionsText={HIRING_STATUS}
                optionKey={"value"}
                optionValue={"label"}
                defaultValue={filter.policyStatus.label}
                onChange={(value: string) => this.handleSelectChange(value, 'policyStatus')}
                propValue={filter.policyStatus.label}
              />
              <label>Estado de contratación</label>
            </div>
          </div>

          <div className="colSin-33">
            <div className="inputBox">
              <FilterInput
                onChange={(value: string) => this.changeValue((value.toUpperCase()), 'nif')}
                initValue={filter.takerNIF.value.toUpperCase()}
                errorText={filter.takerNIF.errorCode}
                showError={filter.takerNIF.errorCode !== ''}
              />
              <label>NIF del tomador</label>
            </div>
          </div>

        </div >

        <div className="main-filter-container height80">

          <div className="colSin-33 rightPadding20">
            <div className="inputBox">
              <CalendarBox
                initialValue={filter.dateSince.value}
                placeholder="Fecha desde"
                forbidFutureDates
                onChange={(value: string) => this.changeValue(value, 'dateSince')}
                deleteIcon
              />
            </div>
          </div>

          <div className="colSin-33 rightPadding20">
            <div className="inputBox">
              <CalendarBox
                initialValue={filter.dateUntil.value}
                placeholder="Fecha hasta"
                onChange={(value: string) => this.changeValue(value, 'dateUntil')}
                deleteIcon
              />
            </div>
          </div>

          <div className="colSin-33">
            <div className="colSin-100 topMargin15 filter-search-button">
              <MainButton
                text="Buscar"
                icon={true}
                iconImg={images.IconSearchWhite}
                onClick={() => {
                  this.changePage(1)

                  this.llamarGetHiring("buscar")
                }}
                disabled={multiSelectError}
              />
            </div>
          </div>
        </div>

        {
          (searched && rowCount > 0) && (
            <div className="main-results-number">
              <NoteText>
                <p>
                  Se han encontrado <span>{rowCount} resultados</span>
                </p>
              </NoteText>
            </div>
          )
        }

        {
          totalPages === 0 ? (
            <Body02Text>No existen contrataciones registradas
              {searched && <span> con este criterio de búsqueda</span>}
            </Body02Text>
          ) : (
            <>
              <div className="pendant-simulations-table special">
                <MainTable
                  className="table"
                  columns={this.getColumns()}
                  rowLink={"/contratacion/pendiente/detalle"}
                  rowKeyLink={"databaseId"}
                  rows={hiringFilter}
                  mediator={this.props.mediator.mediatorCode}
                  keysRow={[
                    "id",
                    "policy-type",
                    "taker",
                    "date",
                    "expiration",
                    "policyStatus",
                    "mediator",
                    "loggedMediator",
                    "action",
                  ]}
                  onOrder={(column: string, ascendent: boolean | undefined) =>
                    this.handleOrder(column, ascendent)
                  }
                />
              </div>

              <div className="pendant-simulation-pagination">
                <Pagination
                  page={page}
                  limit={totalPages}
                  callback={(page) => this.setPage(page)}
                />
              </div>
            </>
          )
        }
        {
          showChooseHiringModal && (
            <ChooseHiringModal
              img={images.IconHireBlue}
              onClickAccept={(ok: string, search: string) =>
                this.chooseHiring(ok, search)
              }
              active={showChooseHiringModal}
              mainTitle="¿Quieres recuperar una simulación pendiente?"
              buttonText="Aceptar"
              close={() => this.closeHiringModal()}
            />
          )
        }

        {
          showInfoModal && (
            <InfoModal
              className={infoModalClass}
              img={images.IconCheckboxBlue}
              mainText={infoModalMainText}
              onClickAccept={() => infoModalOnClickAccept()}
              buttonText="Aceptar"
              close={() => this.closeModal()}
              active={showInfoModal}
            />
          )
        }
        {
          showLeftInfoModal && (
            <LeftInfoModal
              img={imgLeftInfoModal}
              className={classNameLeftInfoModal}
              mainTitle={mainTitleLeftInfoModal}
              mainText={mainTextLeftInfoModal}
              buttonText={buttonTextLeftInfoModal}
              active={showLeftInfoModal}
              onClickAccept={() => this.closeLeftInfoModal()}
              close={() => this.closeLeftInfoModal()}
            />
          )
        }
        <ErrorModalContainer
          className={`${showError && `active`}`}
          active={showError}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{error}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={() => this.closeModalError()} />
            </div>

          </div>
        </ErrorModalContainer>
      </PendantHiringContainer >
    );
  }
}

export default withGeneral(withDynamicProducts(withCoverages(withRouter(PendantHiring))));
