import classNames from "classnames";
import Cookies from "js-cookie";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../assets";
import { withGeneral } from "../../context/mixin/with-general";
import MainButton from "../main-button";
import { CookiesModalStyle } from "./cookies-modal-style";
import ReactGA from "react-ga";
import { cookieDomain } from "../..";
interface ICookiesModalState {
  active: boolean;
  activeModal: boolean;
  showOptions: any;
  options: any[];
}

interface CookiesModalRouterProps {}

interface CookiesModalProps
  extends RouteComponentProps<CookiesModalRouterProps> {
  isLogged: boolean;
  handleChangeActiveModal: Function;
  activeModal: boolean;
}

const textSettings: any = [
  `Cuando visita cualquier sitio web, puede almacenar o recuperar información en su navegador, principalmente en forma de cookies. 
		Esta información puede ser sobre usted, sus preferencias o su dispositivo y se utiliza principalmente para hacer que el sitio funcione 
		como espera. Por lo general, la información no lo identifica directamente, pero puede brindarle una experiencia web más personalizada.`,
  `Debido a que respetamos su derecho a la privacidad, puede optar por no permitir algunos tipos de cookies. Haga clic en los títulos de 
		las diferentes categorías para obtener más información y cambiar nuestra configuración predeterminada. Sin embargo, bloquear algunos 
		tipos de cookies puede afectar su experiencia en el sitio y los servicios que podemos ofrecer.`,
];

const titleOptions: any = "USTED PERMITE";

const buttonAcceptText: any = "Aceptar";
const buttonRejectText: any = "Rechazar";
const textFinal: any = `En algunos casos, los datos obtenidos de las cookies se 
  comparten con terceros. Puede ejercer su derecho a optar por no compartir ese intercambio 
  en cualquier momento desactivando las cookies.`;
class CookiesModal extends React.Component<
  CookiesModalProps,
  ICookiesModalState
> {
  state: ICookiesModalState;
  unlisten: any = null;

  constructor(props: any) {
    super(props);
    this.state = {
      active:
        props.activeModal === undefined &&
        Cookies.get("cookiesAccepted") !== "true",
      activeModal:
        !!props.activeModal && Cookies.get("cookiesAccepted") === "true",
      showOptions: {},
      options: this.getOptions(),
    };

    // this.unlisten = this.props.history.listen(() => {
    // 	this.handleChangeUrl();
    // });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { activeModal } = this.state;
    const {
      activeModal: propActiveModal,
      handleChangeActiveModal,
    } = this.props;

    if (prevState.activeModal !== activeModal) {
      if (activeModal) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "scroll";
        if (handleChangeActiveModal) {
          handleChangeActiveModal(false);
        }
      }
    }

    if (prevProps.activeModal !== propActiveModal) {
      if (propActiveModal) {
        this.setState({
          activeModal: true && Cookies.get("cookiesAccepted") === "true",
        });
      }
    }

    if (prevProps.isLogged !== this.props.isLogged && this.props.isLogged) {
      this.okCookies();
    }
  }

  componentWillUnmount() {
    document.body.style.overflowY = "scroll";
    // window.removeEventListener("scroll", this.handleScroll);
    // window.removeEventListener("login_cookie", this.handleLogin);
    // this.unlisten();
  }

  // handleLogin = () => {
  // 	if (Cookies.get("cookiesAccepted") !== "true") {
  // 		this.okCookies();
  // 		window.removeEventListener("login_cookie", this.handleLogin);
  // 	}
  // };

  // handleScroll = () => {
  // 	if (window.scrollY > 300 && Cookies.get("cookiesAccepted") !== "true") {
  // 		this.okCookies();
  // 	}
  // };

  // handleChangeUrl = () => {
  // 	if (Cookies.get("cookiesAccepted") !== "true") {
  // 		this.okCookies();
  // 	}
  // };

  getOptions = () => [
    {
      text: `Cookies estrictamente necesarias`,
      cookieKey: "accessInfoRequired",
      options: [
        `Estas cookies son necesarias para que el sitio web funcione y no se pueden desactivar en nuestros sistemas. 
					Por lo general, solo se establecen en respuesta a acciones realizadas por usted que equivalen a una solicitud de servicios, 
					como establecer sus preferencias de privacidad, iniciar sesión o completar formularios. Puede configurar su navegador para 
					bloquear o alertar sobre estas cookies, pero algunas partes del sitio no funcionarán. Estas cookies no almacenan ninguna 
					información de identificación personal.`,
      ],
      value: true,
      required: true,
    },
    {
      text: `Cookies Analíticas`,
      cookieKey: "tracing_cookie",
      options: [
        `Estas cookies nos permiten contar las visitas y las fuentes de tráfico, para que podamos medir y mejorar el rendimiento 
					de nuestro sitio. Nos ayudan a saber qué páginas son más y menos populares y a ver cómo se mueven los visitantes por el sitio. 
					Toda la información que recopilan estas cookies es agregada y, por lo tanto, anónima. Si no permite estas cookies, no sabremos 
					cuándo ha visitado nuestro sitio.`,
      ],
      value: Cookies.get("cookiesAccepted")
        ? Cookies.get("tracing_cookie") === "true"
          ? true
          : false
        : false,
      required: false,
    },
  ];

  okCookies() {
    const { options } = this.state;
    options.forEach((option) => {
      if (option.value) {
        Cookies.set(option.cookieKey, "true", { expires: 7 });
        if (option.cookieKey === "tracing_cookie") {
          ReactGA.initialize("UA-18371539-19", {
            gaOptions: {
              siteSpeedSampleRate: 100,
            },
          });
          ReactGA.set({
            page: window.location.pathname,
            allowAdFeatures: "false",
          });
          ReactGA.pageview(window.location.pathname);
        }
      } else {
        Cookies.set(option.cookieKey, "false", { expires: 7 });
        if (option.cookieKey === "tracing_cookie") {
          Cookies.remove("_ga", { domain: cookieDomain });
          Cookies.remove("_gid", { domain: cookieDomain });
          Cookies.remove("_gat_gtag_UA_18371539_19", { domain: cookieDomain });
          Cookies.remove("_gat", { domain: cookieDomain });
        }
      }
    });
    Cookies.set("cookiesAccepted", "true", { expires: 7 });
    this.setState({ active: false, activeModal: false });
    // this.props.setAcceptCookies(true);
  }

  handleShowOptions = (key: string) => {
    const { showOptions } = this.state;
    showOptions[key] = !showOptions[key];
    this.setState({ showOptions });
  };

  handleAccept = (index: number) => {
    const { options } = this.state;
    if (!options[index].required) {
      options[index].value = true;
      this.setState({ options });
    }
  };

  handleRefuse = (index: number) => {
    const { options } = this.state;
    if (!options[index].required) {
      options[index].value = false;
      this.setState({ options });
    }
  };

  render() {
    const { options, activeModal, showOptions, active } = this.state;
    return (
      <CookiesModalStyle>
        <div
          className={classNames("cookies-advice", { none: !active })}
          id="CookiesModal"
        >
          <div className="cookies-advice-main">
            <div className="cookies-advice-body">
              <p>
                En esta web utilizamos cookies propias y de terceros para
                analizar nuestros servicios y mostrarte publicidad relacionada
                con tus preferencias, en base a un perfil elaborado a partir de
                tus hábitos de navegación. Puedes aceptar todas las cookies
                pulsando el botón "Aceptar" o configurarlas y rechazar su uso en
                "Opciones".
              </p>
            </div>
            <div className="cookies-advice-options">
              <div className="cookies-advice-options__item">
                <div className="cookies-advice-ok">
                  <MainButton onClick={() => this.okCookies()} text="Aceptar" />
                </div>
              </div>
              <div className="cookies-advice-options__item">
                <div className="cookies-advice-no">
                  <MainButton
                    onClick={() =>
                      this.setState({ activeModal: true, active: false })
                    }
                    text="Opciones"
                    type="ghost"
                  />
                </div>
              </div>
              {/* <div className="cookies-advice-options-item">
                <div className="cookies-advice-more">
                  <MainButton onClick={() => this.props.history.push("/politica-cookies")} text="Más" type="ghost" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className={classNames("modal-cookies-settings", {
            active: activeModal,
          })}
        >
          <div className="modal-cookies-settings-container">
            <div className="modal-cookies-settings-header">
              <div
                className="modal-cookies-settings-close"
                onClick={() =>
                  this.setState({
                    activeModal: false,
                    active: Cookies.get("cookiesAccepted") !== "true",
                    options: this.getOptions(),
                  })
                }
              >
                <div className="modal-cookies-settings-close-top"></div>
                <div className="modal-cookies-settings-close-bottom"></div>
              </div>
            </div>
            {textSettings.map((text: string, index: number) => (
              <div
                key={`setting-text-${index}`}
                className="modal-cookies-settings-text"
              >
                <p>{text}</p>
              </div>
            ))}

            <div className="modal-cookies-settings-options-title">
              <p>{titleOptions}</p>
            </div>

            {options.map((option, index) => (
              <div
                key={`modal-cookies-settings-options-item-${index}`}
                className={classNames("modal-cookies-settings-options", {
                  open: showOptions[`option-item-${index}`],
                })}
              >
                <div className="modal-cookies-settings-options-info">
                  <div
                    className="modal-cookies-settings-options-item"
                    onClick={() =>
                      this.handleShowOptions(`option-item-${index}`)
                    }
                  >
                    <div className="modal-cookies-settings-options-more-info">
                      <div className="modal-cookies-settings-options-more-info-icon">
                        <img
                          src={images.ChevronDown}
                          alt="cerrar modal video"
                        />
                      </div>
                    </div>
                    <div className="modal-cookies-settings-drop-title">
                      <p>{option.text}</p>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "modal-cookies-settings-options-text",
                      {
                        active: showOptions[`option-item-${index}`],
                      }
                    )}
                  >
                    {option.options.map((op: any, indexOp: any) => (
                      <p key={`op-${indexOp}`}>{op}</p>
                    ))}
                  </div>
                </div>
                <div
                  className={classNames(
                    "modal-cookies-settings-option-buttons",
                    {
                      active: option.value,
                      required: option.required,
                    }
                  )}
                >
                  <div
                    className={classNames(
                      "modal-cookies-settings-options-reject",
                      "option-reject",
                      {
                        active: !option.value,
                        required: option.required,
                      }
                    )}
                    onClick={() => this.handleRefuse(index)}
                  >
                    <p>{buttonRejectText}</p>
                  </div>
                  <div
                    className={classNames(
                      "modal-cookies-settings-option-button",
                      "modal-cookies-settings-option-button-agree",
                      {
                        active: option.value,
                        required: option.required,
                      }
                    )}
                    onClick={() => this.handleAccept(index)}
                  >
                    <p>{buttonAcceptText}</p>
                  </div>
                </div>
              </div>
            ))}

            <div className="modal-cookies-settings-final-text">
              <p>{textFinal}</p>
            </div>
            <div className="modal-cookies-settings-save">
              <div
                onClick={() =>
                  this.setState({
                    activeModal: false,
                    active: Cookies.get("cookiesAccepted") !== "true",
                    options: this.getOptions(),
                  })
                }
                className="modal-cookies-settings-save__reject-button"
              >
                <p>Cancelar</p>
              </div>
              <div
                onClick={() => this.okCookies()}
                className="modal-cookies-settings-save__success-button"
              >
                <p>Aceptar y guardar</p>
              </div>
            </div>
          </div>
        </div>
      </CookiesModalStyle>
    );
  }
}

export default withRouter(withGeneral(CookiesModal));
