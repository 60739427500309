/* eslint-disable array-callback-return */
import React from "react";
import { withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import MainButton from "../../../../../components/main-button";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import HealthService from "../../../../../services/HealthService";
import { capitalizeFirstLetter } from "../../../../../utils";
import { GuaranteeDataContainer } from "./guarantee-data-style";
import AddBeneficiariesModal from "../../../../../components/add-beneficiaries-modal/add-beneficiaries-modal";
import { getDayMonthYear } from "../../../../../utils/time";
import { NewHiringContainer } from "../../new-hiring-style";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import MainTable from "../../../../../components/main-table";
import { BeneficiarieDto, HEALTH_BENEFICIARIES_TYPES, IBeneficiarieRequest } from "../../../../../constants/health";
import { GuaranteeProps, IrrevocableHeirsInterface, GuaranteeState, IBeneficiarie } from "./health-guarantee-dto/health-guarantee-dto";
import SelectBox from "../../../../../components/select-box/select-box";
import { SendaBaseResponse } from "../../../../../services/HealthService.SendaBaseResponse";
import { calcAge } from "../../../../../utils/utility";
import LeftSearchBoxModal from "../../../../../components/left-searchbox-modal";
import ConstantsServices from "../../../../../services/ConstantsServices";
import { CoverageInterface } from '../../health/health- guarantee/health-guarantee-dto/health-guarantee-dto';
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";
import { formatCapitalText, isCapitalInRangeHealth, isCapitalInRangeHealthByCoverage, isOutOfCoverageAgeRange } from "../../../../../utils/coverages";
import CoveragesServices from "../../../../../services/CoveragesServices";
import { withCoverages } from "../../../../../context/mixin/with-coverages";


const tableColumns = [
  {
    text: "Beneficiarios"
  },
  {
    text: "DNI"
  },
  {
    text: "Porcentaje"
  },
  {
    text: ""
  }
];

class GuaranteeDataLayout extends React.Component<GuaranteeProps, GuaranteeState> {

  constructor(props: GuaranteeProps) {
    super(props);

    var currentInsured: any = '';

    this.state = {
      beneficiaries: props.policy.data.beneficiaries,
      addBeneficiariesActive: false,
      addCoverage: false,
      beneficiary: {},
      coverages: [],
      currentInsured: currentInsured,
      currentIndexInsured: -1,
      data: [],
      objectRemove: {
        index: -1,
        insuredDocNumber: ''
      },
      irrevocableHeirs: {
        bank: {
          value:
            props.policy.data.loan && props.policy.data.loan.bank
              ? props.policy.data.loan.bank.value
              : "",
          error: false,
          errorCode: "",
          required: true,
        },
        endDate: {
          value:
            props.policy.data.loan && props.policy.data.loan.endDate
              ? getDayMonthYear(props.policy.data.loan.endDate.value)
              : "",
          error: false,
          errorCode: "",
          required: true,
        },
        rate: {
          value:
            props.policy.data.loan && props.policy.data.loan.rate
              ? props.policy.data.loan.rate.value
              : "",
          error: false,
          errorCode: "",
          required: true,
        },
        nLoan: {
          value:
            props.policy.data.loan && props.policy.data.loan.nLoan
              ? props.policy.data.loan.nLoan.value
              : "",
          error: false,
          errorCode: "",
          required: true,
        },
      },
      prevBeneficiaries: [],
      showLeftInfoModal: false,
      serverError: false,
      buttonDisable: false,
      modalErrorText: '',
      type:
        props.policy.data.beneficiaries && props.policy.data.beneficiaries.length === 0
          ? HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS
          : HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES,
      showLeftSearchBoxModal: false,
      professionsList: [],
      professionsFiltered: [],
      profession: {
        errorCode: '',
        labelText: 'Profesión',
        value: '',
      },
      cancelSignModalShow: false,
      anyChange:false,
      covConfig: []
    }
  }

  formatCoveragesHealth = (coveragesData: any) => {
    const coveragesObj: any = [];
    coveragesData.sort((a: any, b: any) => {
      return a.order > b.order;
    });

    coveragesData.forEach((coverage: any) => {

      let desc = "";
      if (coverage.name)
        desc = capitalizeFirstLetter(coverage.name.toLowerCase())

      let coverageObj: any = {

        code: coverage.code,
        description: desc,
        required: coverage.required ? coverage.required : false,
        edadMin: coverage.edadMin ? coverage.edadMin : "",
        edadMax: coverage.edadMax ? coverage.edadMax : "",
        capital: coverage.capitales ? '' : 1,
        capitales: coverage.capitales ? coverage.capitales : [],

      }
      if (coverage.capitales) {

        coverageObj.capitalRequired = true
        coverageObj.errorCode = ''

      }

      coveragesObj.push(coverageObj)

    })

    return coveragesObj
  }

  getCoverages = async () => {
    const { setLoading, policy, coverages } = this.props;
    const productType: string = policy.product.products_coverageName;
    let cs;

    if (coverages && coverages[productType]) {
      cs = this.formatCoveragesHealth(coverages[productType]);
    } else {
      cs = await HealthService.getCoveragesFromDB(setLoading, true, policy.product.products_coverageName)
      .then((data: any) => {
        if (data !== undefined) {
          data = this.formatCoveragesHealth(data)
          return data
        } else {
          return []
        }
      })
      .catch((error) => {
        this.setState({ serverError: true, buttonDisable: true, modalErrorText: `Error al obtener las coberturas: ${error.data.message}` })
        return []
      });
    }
    return cs;
  }

  onHandleChange(insured: any, coverage: any, checked: boolean) {
    const { covConfig } = this.state;
    const age = calcAge(insured.birthDate)
    if (coverage.required || isOutOfCoverageAgeRange(age, coverage.edadMin, coverage.edadMax)) return false
    coverage.checked = !checked;
    coverage.capitalValue = `${coverage.capital}`;

    const { data } = this.state

    const index = insured.coverages.map(function (e: any) {
      return e.code
    }).indexOf(coverage.code)

    const id = data.insureds.map(function (e: any) {
      return e.docNumber
    }).indexOf(insured.docNumber)
    //Comprobar reqBeneficiaries
    const addBeneficiaries = covConfig.filter(ele => ele.config.reqBeneficiaries === true)
      .map(ele => ele.code).includes(coverage.code);
    //Comprobar reqBenreqProfessioneficiaries
    const reqProfession = covConfig.filter(ele => ele.config.reqProfession === true)
      .map(ele => ele.code).includes(coverage.code);

    if (index === -1) {

      data.insureds[id].coverages.push(coverage)
      if (addBeneficiaries) data.insureds[id].beneficiariesType = HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS;

    } else {

      data.insureds[id].coverages.splice(index, 1);
      if (addBeneficiaries) {
        data.insureds[id].beneficiaries = [];
        data.insureds[id].beneficiariesType = '';
      }
      if (reqProfession) {

        data.insureds[id].profession.code = '';
        data.insureds[id].profession.value = '';

      }

    }
    // const showLeftSearchBoxModal = (reqProfession && index === -1)

    this.setState({ data, currentIndexInsured: id })

  }

  toValidate = async () => {
    if (this.props.isReadOnly)
      return this.props.newStep();

    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange)
      this.setState({ cancelSignModalShow: true })
    else {
      await this.validateAndSave()
    }
  }

  async validateAndSave() {

    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED&& this.state.anyChange)
      this.props.removeSign()

    const { data, covConfig } = this.state
    const { setLoading, policy } = this.props;
    const { insureds } = data;

    let isValidate = true;
    let isError = false;
    let beneficiaries: BeneficiarieDto[] = [];
    let status: string = '';
    insureds.map((insured: any) => {

      let addBeneficiaries = false;
      insured.coverages.filter((cov: any) => cov.capitalRequired === true).map((coverage: any) => {

        coverage.errorCode = ''

        if (!addBeneficiaries) {

          addBeneficiaries = covConfig.filter(ele => ele.config.reqBeneficiaries === true)
            .map(ele => ele.code).includes(coverage.code);
        }

        if (coverage.capitalRequired && coverage.capital === '') {

          coverage.errorCode = 'required'
          isValidate = false

        } else {
          
          if (coverage.capMinCober > 0) {

            if (coverage.capital < coverage.capMinCober) {
              coverage.errorCode = 'min-error'
              isValidate = false
            } else if (coverage.capital > coverage.capMaxCober) {
              coverage.errorCode = 'max-error'
              isValidate = false
            }

          }else{
            const rangeError = isCapitalInRangeHealthByCoverage(coverage)

            if(rangeError !== ''){
              coverage.errorCode = rangeError
              isValidate = false
            } 
          
          }
        }

      })

      if (addBeneficiaries && insured.beneficiariesType === HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES) {
        if (this.calculateTotalPercentage(insured) !== 100) {
          isValidate = false;
          insured.error = true;
        }
      }

      insured.beneficiaries && insured.beneficiaries.forEach((beneficiarie: IBeneficiarie) => {
        const newBeneficiarie: BeneficiarieDto = {
          docNumber: beneficiarie.docNumber.value,
          name: beneficiarie.name.value,
          lastName1: beneficiarie.lastName1.value,
          lastName2: beneficiarie.lastName2.value,
          percentage: parseFloat(beneficiarie.percentage.value),
          insuredIndex: beneficiarie.insuredIndex
        }
        beneficiaries.push(newBeneficiarie);
      })

    })

    if (isValidate === true) {

      const riskData: SendaBaseResponse = await HealthService.getRiskData(setLoading, true, data, policy.product.products_internalName)
        .then((data: any) => {
          return data;
        })
        .catch((error) => {
          isError = true
          this.setState({
            serverError: true,
            modalErrorText: error
          })
        });

      let IDCotiza = '';
      //Checkeamos los asegurados
      for (let i = 0; i < data.insureds.length; i++) {
        if (data.insureds[i].docType === 'MENOR') {
          if (riskData.body && riskData.body.RESPUESTA && riskData.body.RESPUESTA.ASEGURADOS && riskData.body.RESPUESTA.ASEGURADOS[i])
            data.insureds[i].docNumber = riskData.body.RESPUESTA.ASEGURADOS[i].NUMERO_DOCUMENTO
        }
      }
      riskData &&
        (IDCotiza = riskData.body && riskData.body.RESPUESTA && riskData.body.RESPUESTA.COTIZACION);

      data.idcotiza = IDCotiza;

      if (beneficiaries && beneficiaries.length > 0 && IDCotiza) {

        const beneficiariesData: IBeneficiarieRequest = {
          idcotiza: IDCotiza,
          beneficiaries
        }

        const responseBeneficiarie = await HealthService.setBeneficiaries(setLoading, true, beneficiariesData)
          .catch((error) => {
            isError = true;
            this.setState({
              serverError: true,
              modalErrorText: `${(error && error.data && error.data.message) ?
                error.data.message : 'Se ha producido un error. Vuelva a intentarlo más tarde.'}`
            })
          });

        status = responseBeneficiarie === undefined ? riskData.body.RESPUESTA.ESTADO : responseBeneficiarie.body.RESPUESTA.ESTADO;
      }

      if (isError === false) {

        const newData = {
          ...data,
          guarantees: true,
          beneficiaries,
          status
        }
        //Checkeamos los dnis de los asegurados
        this.setState(newData);
        this.props.policy.setHealthData(newData);
        this.props.saveHiring();
        this.props.newStep();

      }
    } else {
      this.setState(data)
    }
  }

  async componentDidUpdate(prevProps:any){
    let { policy } = this.props;

    if(Object.keys(prevProps.policy.product).length===0 && Object.keys(policy.product).length!==0 ){
      await this.handleAddCoveragesOnEnter();
      this.validateAgeInsureds();
    }
  }

  componentWillMount = async () => {
    const { data, policy } = this.props;

    if (data && data.insureds && data.insureds.length === 0) {
      this.props.history.push(
        this.props.history.location.pathname.replace("garantias", "tomador")
      );
    }
    if(Object.keys(policy.product).length!==0){
      await this.handleAddCoveragesOnEnter();
      this.validateAgeInsureds();
    }
    
  };

  async componentDidMount() {
    const covConfigs = await CoveragesServices.getCoveragesConfig({
      setLoading: () => { },
      loader: false,
      ignoreSetFalse: true,
    })
    this.setState({ covConfig: covConfigs })

    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);

  }

  async handleAddCoveragesOnEnter() {
    const { data } = this.props;
    let { professionsList } = this.state;

    professionsList = await ConstantsServices.getProfessions({
      setLoading: this.props.setLoading
    });

    let filterProfessions = professionsList
      .filter((p) => p.product === 'ALL')
      .sort(function (a: any, b: any) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

    const coverages = await this.getCoverages()
      .then(data => {
        return data
      });

    data.insureds.map((insured: any) => {

      if (insured.coverages !== undefined && insured.coverages.length > 0) {
        insured.coverages = insured.coverages.filter((c:any) => c.required || c.checked);
        coverages.map((coverage: any, index: number) => {
          let equalCoverageIndex: number = -1;
          const equalCoverage: any[] = [];
          equalCoverage.push(...insured.coverages.filter((insuredCoverage: any, insuredCoverageIndex: number) => {
            if (coverage.code === insuredCoverage.code) {
              equalCoverageIndex = insuredCoverageIndex;
              if (coverage.capitales && coverage.capitales.length > 0 && insuredCoverage.capital === undefined) {
                insuredCoverage.capitalRequired = true;
                insuredCoverage.errorCode = '';
                insuredCoverage.capital = '';

              } else if ((!coverage.capitales || coverage.capitales.length === 0) && insuredCoverage.required === false) {
                insuredCoverage.capitalRequired = false;
                insuredCoverage.errorCode = '';
                insuredCoverage.capital = 1;

              }
              return insuredCoverage;
            }


          }));

          if (equalCoverage.length === 1) {
            if (!insured.coverages.some((cov: CoverageInterface) => { return cov.code === equalCoverage[0].code }) && !equalCoverage[0].required) {
              insured.coverages.splice(equalCoverageIndex, 1);
            }
          }
          else if (coverage.required) {
            insured.coverages.push(coverage);
          }

        });

      }
      else {
        const req = coverages.filter((coverage: any) => coverage.required === true);
        req.map((coverage: any, item: number) => (insured.coverages.push(coverage)))
      }

    });
    this.setState({ coverages: [...coverages], data, professionsList: filterProfessions });
  }

  handleCapitalChange = (indexInsured: number, codeCoverage: string, value: string) => {

    const { data } = this.state;

    const indexCoverage = data.insureds[indexInsured].coverages.findIndex((cov: any) => cov.code === codeCoverage)
    const newCoverage = JSON.parse(JSON.stringify(data.insureds[indexInsured].coverages[indexCoverage]))

    if (newCoverage.capitalRequired === true) {

      newCoverage.capital = !isNaN(parseInt(value)) ? parseInt(value) : '';
      newCoverage.errorCode = ''

    }
    data.insureds[indexInsured].coverages.splice(indexCoverage, 1)
    data.insureds[indexInsured].coverages.push(newCoverage)
    this.setState({ data })
  }

  changeBeneficiaries = (beneficiaries: any[]) => {
    const { changeBeneficiaries, policy, irrevocableHeirs } = this.props;
    const { prevBeneficiaries, currentIndexInsured, } = this.state;
    const { insureds } = this.state.data;
    let { data,anyChange } = this.state;

    data.insureds[currentIndexInsured].error = '';

    if (beneficiaries.length < prevBeneficiaries.length) {
      beneficiaries = JSON.parse(JSON.stringify(prevBeneficiaries));
    }
    changeBeneficiaries(beneficiaries);

    let sum = 0;
    const validateForm = irrevocableHeirs
      ? this.validateForm(policy.data.loan)
      : false;
    beneficiaries.forEach((b: any, index: number) => {
      sum += parseFloat(b.percentage.value);
      beneficiaries[index].insuredIndex = currentIndexInsured;
    });
    data.beneficiaries = JSON.parse(JSON.stringify(beneficiaries));

    policy.changeValidateForm(!validateForm && sum === 100);

    const { type } = this.state;
    if (type === HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES) {
      insureds[currentIndexInsured].beneficiaries = beneficiaries
      anyChange = true
    }
    else {
      insureds[currentIndexInsured].beneficiaries = [];

    }
    this.setState({ addBeneficiariesActive: false, beneficiaries,anyChange });

  };

  getRows = (beneficiaries: any[], insuredDocNumber: String, indexInsured: number) =>
    beneficiaries.map((b, index) => ({
      beneficiaries: {
        text: b.name.value + ' ' + b.lastName1.value + ' ' + b.lastName2.value,
        type: "",
        key: "body",
      },
      nif: {
        text: b.docNumber.value,
        type: "",
        key: "body",
      },
      percentage: {
        text: b.percentage.value + " %",
        type: "",
        key: "body",
      },
      buttons: {
        key: "buttons",
        items: [
          {
            img: images.IconEditBlue,
            onClick: () => this.editBeneficiary(index, insuredDocNumber, indexInsured),
          },
          {
            img: images.IconEraseRed,
            onClick: () =>
              this.setState({ showLeftInfoModal: true, objectRemove: { index, insuredDocNumber } }),
          },
        ],
      },
    }));

  editBeneficiary = (index: number, insuredDocNumber: String, indexInsured: number) => {
    let { beneficiaries, prevBeneficiaries, data, anyChange } = this.state;
    let beneficiary = '';
    data.insureds.forEach((insured: any) => {
      if (insured.docNumber === insuredDocNumber) {
        beneficiary = insured.beneficiaries[index];
        beneficiaries = JSON.parse(JSON.stringify(insured.beneficiaries));
        beneficiaries.splice(index, 1);
        anyChange =true
      }
    });
    this.setState({ beneficiary, addBeneficiariesActive: true, prevBeneficiaries, beneficiaries, currentIndexInsured: indexInsured, anyChange });
  };

  closeLeftInfoModal = () => {
    this.setState({ showLeftInfoModal: false });
  };

  removeBeneficiary = () => {
    let { objectRemove, beneficiaries, data, anyChange } = this.state;
    const { policy, irrevocableHeirs } = this.props;
    const validateForm = irrevocableHeirs
      ? this.validateForm(policy.data.loan)
      : false;
    let sum = 0;
    data.insureds.forEach((insured: any) => {
      if (insured.docNumber === objectRemove.insuredDocNumber) {
        insured.beneficiaries.splice(objectRemove.index, 1);
        insured.beneficiaries.forEach((beneficiarie: any) => (sum += parseFloat(beneficiarie.percentage.value)))
      }
    });
    anyChange =true;
    policy.changeValidateForm(!validateForm && sum === 100);
    this.setState({ beneficiaries, data, anyChange });
  };

  validateForm(loan: IrrevocableHeirsInterface) {
    let error = false;
    if (loan && Object.keys(loan).length === 0 && loan.constructor === Object) {
      return true;
    }
    if (loan) {
      Object.keys(loan).forEach((k: string) => {
        if (loan[k].error || !loan[k].value) error = true;
      });
      this.props.policy.changeValidateForm(!error);
      return error;
    }
  };

  changeBeneficiarieType(type: string, insuredIndex: number) {
    let { data, anyChange } = this.state;

    data.insureds[insuredIndex].beneficiariesType = type;
    anyChange =true;
    this.setState({
      anyChange,
      type,
      data
    })
  };

  calculateTotalPercentage(insured: any): number {
    let totalPercentage: number = 0;
    insured.beneficiaries && insured.beneficiaries.forEach((beneficiary: any) => {
      totalPercentage += parseInt(beneficiary.percentage.value);
    });
    return totalPercentage;
  }

  handleRadioChange(radioValue: string, insuredIndex: number) {
    let { data,anyChange } = this.state;

    data.insureds[insuredIndex].beneficiariesType = radioValue;
    this.changeBeneficiarieType(radioValue, insuredIndex);
    if (radioValue === HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS) {
      anyChange=true
      data.insureds[insuredIndex].beneficiaries = [];
      data.insureds[insuredIndex].error = '';
    }

    this.setState({ data,anyChange })
  }

  getCapital(coverage: any) {
    if (coverage.capital) return String(coverage.capital).toString()
    else if (coverage.capitalValue) return String(coverage.capitalValue).toString()
    return ''
  }

  renderCoverages = (insured: any, indexInsured: number, coverages: any, isReadOnly: boolean) => {
    const { covConfig } = this.state;

    const age = calcAge(insured.birthDate)

    return coverages.map((coverage: any, item: number) => {
      const checked = insured.coverages.map(function (e: any) {
        return e.code
      }).indexOf(coverage.code) > -1
      return (
      <div className="rightPadding10" key={item}>
        <label
          className={`checkbox-container ${isReadOnly ? "disabled" : ""} ${(coverage.required) || isOutOfCoverageAgeRange(age, coverage.edadMin, coverage.edadMax) ? ' noPointer' : ''}`}>
          <input
            disabled={isReadOnly}
            type="checkbox"
            checked={coverage.required || checked}
            onChange={(e) => this.onHandleChange(insured, coverage, checked)}
          />
          <div className={
            `checkmark-container${coverage.required || isOutOfCoverageAgeRange(age, coverage.edadMin, coverage.edadMax) ? '-readonly' : ''}`
          }>
            <div className="checkmark-item">
              <img
                src={
                  coverage.required ||
                    insured.coverages.map(function (e: any) { return e.code })
                      .indexOf(coverage.code) > -1 ?
                    images.IconTickWhite :
                    images.IconCloseWhite}
                alt="" />
            </div>
          </div>
          <div>
            {coverage.description}
          </div>
        </label>
        {coverage.capitalRequired && insured.coverages &&
          insured.coverages.map(function (e: any) { return e.code })
            .indexOf(coverage.code) > -1 && (
            <div>
              <div className={`inputBox ${isReadOnly ? "disabled" : ""} leftMargin30`} key={coverage.code} />
              <div className="selectBox">
                <SelectBox
                  required={true}
                  errorCode={insured.coverages &&
                    insured.coverages.map(function (e: any) { return e.code })
                      .indexOf(coverage.code) > -1 &&
                    insured.coverages.find((cov: any) => cov.code === coverage.code).errorCode}
                optionsText={formatCapitalText(coverage.capitales)}
                  optionKey={"value"}
                  optionValue={"label"}
                  initialValue={insured.coverages &&
                    insured.coverages.map(function (e: any) { return e.code })
                      .indexOf(coverage.code) > -1 ? this.getCapital(insured.coverages.find((cov: any) => cov.code === coverage.code)) : ''}
                  defaultValue={insured.coverages &&
                    insured.coverages.map(function (e: any) { return e.code })
                      .indexOf(coverage.code) > -1 ? this.getCapital(insured.coverages.find((cov: any) => cov.code === coverage.code)) : ''}
                  onChange={(value: string) => this.handleCapitalChange(indexInsured, coverage.code, value)}
                  health
                />
                <label>{"Capital a asegurar"}</label>
              </div>
            {covConfig.filter(ele => ele.config.reqBeneficiaries === true).map(ele => ele.code).includes(coverage.code) &&
                <div className="leftMargin30 colSin-80">

                  {(insured.coverages.map(function (e: any) {
                    return e.code
                  }).indexOf(coverage.code) > -1) &&
                    <div>
                      <div className="contact-radio-main-row">
                        <div className={isReadOnly ? "radioBox disabled" : "radioBox"}>
                          <label>
                            <input
                              type="radio"
                              disabled={isReadOnly}
                              name={insured.name + HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS}
                              value={insured.name + HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS}
                              onChange={() => this.handleRadioChange(HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS, indexInsured)}
                              checked={insured.beneficiariesType === HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS}
                            />
                            <span>HEREDEROS LEGALES</span>
                          </label>
                        </div>
                        <div className={isReadOnly ? "radioBox disabled" : "radioBox"}>
                          <label>
                            <input
                              type="radio"
                              disabled={isReadOnly}
                              name={insured.name + HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES}
                              value={insured.name + HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES}
                              onChange={() => this.handleRadioChange(HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES, indexInsured)}
                              checked={insured.beneficiariesType === HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES}
                            />
                            <span>BENEFICIARIOS DESIGNADOS</span>
                          </label>
                        </div>
                      </div>
                      {insured.beneficiaries && insured.beneficiaries.length > 0 &&
                        < MainTable
                          columns={tableColumns}
                          rows={this.getRows(insured.beneficiaries, insured.docNumber, indexInsured)}
                          keysRow={isReadOnly ?
                            ["beneficiaries", "nif", "percentage", ""] :
                            ["beneficiaries", "nif", "percentage", "buttons"]}
                        />
                      }
                    </div>
                  }
                  {insured.error &&
                    <div className="error-percentage">
                      <a>Tiene que sumar 100%, la suma ahora mismo es de {this.calculateTotalPercentage(insured)}%</a>
                    </div>
                  }

                  {insured.beneficiariesType === HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES &&
                    <MainButton
                      disabled={isReadOnly || insured.coverages &&
                        insured.coverages.map(function (e: any) { return e.code })
                          .indexOf(coverage.code) === -1
                      }
                      text="Añadir beneficiarios"
                      type="full-light"
                      onClick={() =>
                        this.setState({
                          addBeneficiariesActive: insured.beneficiariesType === HEALTH_BENEFICIARIES_TYPES.DESIGNATED_BENEFICIARIES,
                          currentIndexInsured: indexInsured,
                          beneficiary: {},
                          beneficiaries: insured.beneficiaries ? insured.beneficiaries : []
                        })
                      }
                    />
                  }
                </div>
              }
            </div>
          )}
      </div>
    )})
  }

  handleCloseAddBeneficiariesModal() {
    let { data, currentIndexInsured } = this.state;

    if (data.insureds[currentIndexInsured].beneficiaries === undefined || data.insureds[currentIndexInsured].beneficiaries.length === 0) {
      data.insureds[currentIndexInsured].beneficiariesType = HEALTH_BENEFICIARIES_TYPES.LEGAL_HEIRS;
    }

    this.setState({
      addBeneficiariesActive: false,
      data
    })
  }

  closeLeftModal() {
    const { data, currentIndexInsured, profession, covConfig } = this.state;

    const codeCoverage = covConfig.filter(ele => ele.config.reqProfession)[0].code;

    const index = data.insureds[currentIndexInsured].coverages.map(function (e: any) {
      return e.code
    }).indexOf(codeCoverage);

    //data.insureds[currentIndexInsured].coverages[index].checked = false;
    data.insureds[currentIndexInsured].coverages.splice(index, 1);

    this.setState({
      showLeftSearchBoxModal: false,
      profession: { ...profession, errorCode: '', value: '' },
      data,
    });

  }

  validateProfession = () => {
    const { profession, currentIndexInsured } = this.state;
    const { data } = this.props;

    if (profession.value === '' && !(data.insureds[currentIndexInsured] && data.insureds[currentIndexInsured].profession.value !== '')) {

      this.setState({
        profession: {
          ...profession,
          errorCode: "required",
        },
      });

    } else {
      this.setState({ showLeftSearchBoxModal: false })
    }
  }

  validateAgeInsureds = () => {
    const { data } = this.props;
    const { serverError, coverages } = this.state;
    if (data && data.insureds) {

      const covReqAgeMax = coverages.filter((c: any) => c.required && c.edadMax !== '');

      data.insureds.map((insured: any) => {

        const age = calcAge(insured.birthDate) || 0;
        const isHighAge = covReqAgeMax.some((c: any) => age > c.edadMax);

        if (isHighAge && !serverError) {
          this.setState({
            serverError: true,
            buttonDisable: true,
            modalErrorText: `El asegurado ${insured.name} ${insured.lastname1} ${insured.lastname2}
              excede de la edad máxima permitida para alguna de las coberturas requeridas.`
          });
          return;
        }

      });
    }
  }

  handleProfessionChange(professionSelected: any) {
    let { data, profession, currentIndexInsured,anyChange } = this.state;

    data.insureds[currentIndexInsured].profession.value = professionSelected.label;
    data.insureds[currentIndexInsured].profession.code = professionSelected.value;
    anyChange =true;
    profession.errorCode = '';
    profession.value = professionSelected.label;

    this.setState({ data, profession, anyChange });

  }

  render() {
    const {
      addBeneficiariesActive,
      beneficiaries,
      beneficiary,
      coverages,
      currentIndexInsured,
      data,
      profession,
      professionsList,
      serverError,
      showLeftInfoModal,
      showLeftSearchBoxModal,
      buttonDisable,
      cancelSignModalShow
    } = this.state

    return (

      <NewHiringContainer>
        <GuaranteeDataContainer>

          <div className="colSin-100">

            <div className="data-flow-status">
              <div className="data-flow-status-title">

                <h2>GARANTIAS DE LA PÓLIZA</h2>

              </div>
            </div>

            <div className="content-wrapper">
              <div className="tabs">
                {data && data.insureds && data.insureds.map((insured: any, index: number) => (

                  <div className="tab" key={index}>

                    <input
                      type="checkbox"
                      defaultChecked
                    />

                    <label className="tab-label">
                      <div>
                        <span>ASEGURADO</span>
                        <span>FECHA NACIMIENTO</span>
                        <span>EDAD</span>
                        <span>Nº DOCUMENTO</span>
                      </div>
                      <div>
                        <span>{`${insured.name} ${insured.lastname1} ${insured.lastname2}`}</span>
                        <span>{getDayMonthYear(insured.birthDate)}</span>
                        <span>{calcAge(getDayMonthYear(insured.birthDate).toString())}</span>
                        <span>{insured.docNumber}</span>
                      </div>
                    </label>

                    <div className="tab-content" >
                      {/* GARANTIAS OBLIGATORIAS */}
                      <div className="colSin-40">
                        {coverages && this.renderCoverages(insured, index, coverages.filter((coverage) => { return coverage.required }), this.props.isReadOnly)}
                      </div>
                      {/* GARANTIAS OPCIONALES */}
                      <div className="colSin-60">
                        {coverages && this.renderCoverages(insured, index, coverages.filter((coverage) => { return !coverage.required }), this.props.isReadOnly)}
                      </div>
                    </div>

                  </div>

                ))}

              </div>
            </div>

            <div className="colSin-100 topMargin20 bottomMargin20">
              <div className="personal-data-buttons">

                <div className="personal-data-buttons__item">
                  <MainButton
                    withTab={true}
                    icon={true}
                    type={"white"}
                    iconImg={images.IconArrowLeftBlue}
                    text="Atrás"
                    onClick={() => this.props.goToInsureds()}
                  />
                </div>
                <div className="personal-data-buttons__item">
                  <MainButton
                    withTab={true}
                    text="Siguiente"
                    disabled={buttonDisable}
                    onClick={() => this.toValidate()}
                  />
                </div>
              </div>
            </div>

          </div>



        </GuaranteeDataContainer>

        <AddBeneficiariesModal
          active={addBeneficiariesActive}
          beneficiaries={beneficiaries}
          beneficiary={beneficiary}
          buttonText="Terminar"
          close={() => this.handleCloseAddBeneficiariesModal()}
          img={images.IconUserBlue}
          insuredDocNumber={(currentIndexInsured === -1) ? '' : data.insureds[currentIndexInsured].docNumber}
          mainTitle="Añadir beneficiarios"
          mainText=""
          onClickAccept={(beneficiaries: any) =>
            this.changeBeneficiaries(beneficiaries)
          }
        />
        <LeftInfoModal
          img={images.IconEraseRed}
          mainTitle={"¿Quieres borrar al beneficiario?"}
          mainText={
            "Si elimina al beneficiario, luego no podrá recuperar sus datos"
          }
          buttonText="Eliminar"
          close={() => this.closeLeftInfoModal()}
          active={showLeftInfoModal}
          onClickAccept={() => this.removeBeneficiary()}
          className="error"
        />
        <LeftSearchBoxModal
          img={images.IconSearchBlue}
          className={''}
          mainTitle={'Profesión del asegurado'}
          mainText={'Seleccione de la lista, la profesión del asegurado, para activar esta garantía'}
          buttonText={'Aceptar'}
          close={() => this.closeLeftModal()}
          active={showLeftSearchBoxModal}
          onClickAccept={() => this.validateProfession()}
          withSearhBox={{
            errorCode: profession.errorCode,
            labelText: profession.labelText,
            optionsText: professionsList,
            onChange: (value: string) => this.handleProfessionChange(value),
            value: (this.props.data.insureds[currentIndexInsured] && this.props.data.insureds[currentIndexInsured].profession.value)
              || profession.value,
          }}
        />
        <LeftInfoModal
          img={images.IconWarningYellow}
          className="error"
          mainTitle={"¿Seguro que desea continuar?"}
          mainText={"Se cancelará la firma previamente solicitada"}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ cancelSignModalShow: false })}
          active={cancelSignModalShow}
          onClickAccept={() => {
            this.setState({ cancelSignModalShow: false }, () => this.validateAndSave())
          }}
        />

        <ErrorModalContainer className={`${serverError && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={() => this.setState({ serverError: false })} />
            </div>

          </div>
        </ErrorModalContainer>

      </NewHiringContainer>
    );
  }
}

export default withPolicy(withCoverages(withGeneral(withRouter(GuaranteeDataLayout))));
