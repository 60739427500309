import React from 'react';
import { HiringHealthDeclarationResultPendantContainer } from './health-declaration-result-style';
import NoteText from '../../../../components/components-text/note-text'
import Title03Text from '../../../../components/components-text/title-03-text';
import WebText from '../../../../components/components-text/web-text';
import { RouteComponentProps } from 'react-router';
import ConstantsServices from '../../../../services/ConstantsServices';
import { stringify } from 'querystring';
import { images } from "../../../../assets";
import HealthQuestionnairesTable from '../../../../components/health-questionnaires-table/health-questionnaires-table';
import { AREA_NAMES } from '../../../../constants/policyType';

interface RouteParams {
  page: string;
  id: string;
  mediator: string;
}

interface HiringHealthDeclarationResultPendantProps extends RouteComponentProps<RouteParams> {
  hiring: any;
  questionnaires: any;
  setLoading: Function;
  checkProductArea: Function;
}

interface HiringHealthDeclarationResultPendantState {
}


export default class HiringHealthDeclarationResultPendantLayout extends React.Component<HiringHealthDeclarationResultPendantProps, HiringHealthDeclarationResultPendantState> {

  readonly state: HiringHealthDeclarationResultPendantState;

  constructor(props: HiringHealthDeclarationResultPendantProps) {
    super(props);
    if (!props.hiring.id) {
      props.history.push(`/contratacion/pendiente/detalle/${props.match.params.mediator}/${props.match.params.id}`)
    }
    this.state = {}

  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  };

  getHealthRenderQuestionnaires(): JSX.Element {
    const { match, hiring } = this.props;

    const questionnaireIndex = +match.params.page - 1;
    const insured = hiring.body.insureds[questionnaireIndex];
    const questionnaire = { [insured.docNumber]: hiring.body.questionnaires[insured.docNumber] };

    return (
      <HiringHealthDeclarationResultPendantContainer>
        <div className="health-declaration-container">
          <div className="health-declaration-title">
            <Title03Text>
              <p>Declaración de salud — Asegurado {match.params.page}</p>
            </Title03Text>
          </div>

          <div className="health-declaration-measures-container">
            <HealthQuestionnairesTable
              questionnaires={questionnaire}
              insureds={[insured]}
              setLoading={this.props.setLoading}
            />
          </div>
        </div>
      </HiringHealthDeclarationResultPendantContainer>
    )
  }

  render() {
    const { match, hiring, history, questionnaires, checkProductArea } = this.props;
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, hiring.productType)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, hiring.productType)

    if (
      (
        (hiring && hiring.body && !hiring.body.questionnaires) ||
        (hiring && hiring.body && Object.keys(hiring.body.questionnaires).length === 0)
      )
      && isHealthArea) {
      return (
        <HiringHealthDeclarationResultPendantContainer>
          <div className="health-declaration-container">
            <div className="health-declaration-title">
              <p>No hay información de los cuestionarios para esta contratación</p>
            </div>
          </div>
        </HiringHealthDeclarationResultPendantContainer>
      )
    }

    if (isHealthArea) {
      return (
        this.getHealthRenderQuestionnaires()
      )
    }

    let questionnaire = [];
    let subquestionnaire: any[] = [];
    if (isGIPArea) {
      let existingQuestionnaire = hiring.body.questionnaires.find((insuredQuestionnaire: any) => insuredQuestionnaire.insuredDocNumber === hiring.body.insureds[+match.params.page - 1].docNumber)
      if (existingQuestionnaire) {
        let qSections = existingQuestionnaire.questionnaireData
        qSections.forEach((section: { questDetail: string, subquestionnaires: any }) => {
          subquestionnaire = []
          section.subquestionnaires.forEach((q: any) => subquestionnaire.push({ answer: q.selectedValue, response: q.response, code: q.id }));
          questionnaire.push({ name: section.questDetail, subquestionnaires: subquestionnaire })

        });

      }
    }
    else {
      questionnaire = hiring.body.insureds[+match.params.page - 1] ?
        hiring.body.insureds[+match.params.page - 1].questionnaires
        : [];
    }

    return (
      questionnaire && questionnaire.length > 0 ?
        (
          <HiringHealthDeclarationResultPendantContainer>
            <div className="health-declaration-container">
              <div className="health-declaration-title">
                <Title03Text>
                  <p>Declaración de salud — Asegurado {match.params.page}</p>
                </Title03Text>
              </div>

              {isGIPArea ? (
                <div className="health-declaration-question-container">
                  {questionnaire.slice(0).map((q: any) => (
                    <div>
                      <div className="health-declaration-question-row-giptitle">
                        <div className="health-declaration-question-information">
                          <div className="health-declaration-question-response-item__titlegip">
                            <p>
                              {q.name || '----------------------------------'}
                            </p>
                          </div>
                        </div>
                      </div>

                      {
                        q.subquestionnaires && q.subquestionnaires.map((sq: any) => (
                          <div className="health-declaration-question-row-gip">
                            <div className="health-declaration-question-information">
                              <div className="health-declaration-question-statement">
                                <p>{questionnaires[sq.code]}</p>
                              </div>
                              <div className="health-declaration-question-response-container">
                                <div className="health-declaration-question-response-item">
                                  <div className="health-declaration-question-response-item__text">
                                    <WebText>
                                      <p>{sq.response || ''}</p>
                                    </WebText>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {sq.answer ?
                              (<div className="health-declaration-question-status">
                                <NoteText>
                                  <p>{sq.answer}</p>
                                </NoteText>
                              </div>)
                              : ""}
                          </div>

                        ))
                      }
                    </div>
                  ))}
                </div>

              )
                :
                (<div className="health-declaration-question-container">
                  {questionnaire.slice(2).map((q: any) => (
                    <div className="health-declaration-question-row">
                      <div className="health-declaration-question-information">
                        <div className="health-declaration-question-statement">
                          <p>
                            {questionnaires[q.code] || 'Lorem ipsum dolor sit amet consectitur adipiscing lorem ipsum dolor'}
                          </p>
                        </div>
                        <div className="health-declaration-question-response-container">
                          {q.subquestionnaires && q.subquestionnaires.map((sq: any) => (
                            <div className="health-declaration-question-response-item">
                              <div className="health-declaration-question-response-item__title">
                                <NoteText>
                                  <p>{questionnaires[sq.code]}</p>
                                </NoteText>
                              </div>
                              <div className="health-declaration-question-response-item__text">
                                <WebText>
                                  <p>{sq.answer || 'No contestado'}</p>
                                </WebText>
                              </div>
                            </div>

                          ))}
                        </div>
                      </div>
                      <div className="health-declaration-question-status">
                        <NoteText>
                          <p>{q.answer ? "Si" : "No"}</p>
                        </NoteText>
                      </div>
                    </div>

                  ))}
                </div>)
              }
            </div>
          </HiringHealthDeclarationResultPendantContainer >
        )
        :
        (
          <HiringHealthDeclarationResultPendantContainer>
            <div className="health-declaration-container">
              <div className="health-declaration-title">
                <p>No hay información de los cuestionarios para esta contratación</p>
              </div>
            </div>
          </HiringHealthDeclarationResultPendantContainer>
        )

    );
  }
}
