import styled from "styled-components";
import { colors } from "../../assets";

export const HealthHireMenuContainer = styled.div`
  position: fixed;
  top: 110px;
  right: 25px;
  width: 290px;
  height: calc(100% - 120px);

  .ulMenu {
    list-style: none;
    overflow: auto;
    height: calc(100% - 185px);
    padding-right: 5px;
    padding-bottom: 5px;

    ::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      background-color:  #c1c1c1;
    }
  }

  .ulMenu li {
    margin-top: 5px;

    > div {
      justify-content: left;
      padding-left: 10px;
      position: relative;

      &[disabled] {
        border: none;

        &:after {
          content: "";
          position: absolute;
          right: 10px;
          top: 22px;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background-color: #b5bcc6;
          border: none;
        }
      }

      &:after {
        content: "";
        position: absolute;
        right: 10px;
        top: 16px;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background-color: #004f8b;
        border: 4px solid #fff;
      }
    }
  }
  .ulSubmenu {
    list-style: none;
    margin-bottom: 10px;
  }
  .ulSubmenu li {
    margin: 1px 5px 5px 5px;

    > div {
      justify-content: center;
      padding-left: 0;
      margin: 5px 10px;

      &:after {
        content: none;
      }
    }

    .main-button-icon {
      margin-left: -10px;
      margin-right: 10px;
    }

    .insured-card {
      margin-bottom: 6px;
      box-shadow: none;
    }
  }

  .inflow-sidebar-type {
    overflow: hidden;
    margin-bottom: 18px;
    color: ${colors["PR-001-100"]};
    font-family: "museo sans";
    font-weight: 700;
    border-radius: 8px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
  }
  .inflow-sidebar-type-wrapper {
    padding: 0 12px 12px;
  }
  .inflow-sidebar-type-text {
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 4px;
  }
  .inflow-sidebar-type-title {
    font-size: 24px;
  }
  .insured-card-details {
    right: 10px;
  }

  .cod-mediador {
    font-size: 22px;
    font-weight: normal;
    margin: 10px 0 20px;
    text-align: center;
  }
  .cod-mediador span {
  }
`;
