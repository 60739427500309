import React from "react";
import ChooseHiringModalLayout from "./choose-hiring-modal-layout";

export default class ChooseHiringModal extends React.Component<any, {}> {
  render() {
    const { img, className, onClickAccept, mainTitle, mainText, active, buttonText, close } = this.props;

    return (
      <ChooseHiringModalLayout
        className={className}
        img={img}
        onClickAccept={(ok: string, search: string) => onClickAccept(ok, search)}
        mainTitle={mainTitle}
        mainText={mainText}
        buttonText={buttonText}
        active={active}
        close={close}
      />
    );
  }
}
