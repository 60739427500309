import RequiredDocumentsServices from "../services/RequiredDocumentsServices";

class RequiredDocuments {

    getDocumentTypes = async (setLoading: any, productId: any) => {
        let documentTypes: { value: any; label: string; showFor: string, obligatorio: string, documentTypeMetadata: string }[] = [];
        const personaTypesRes = await RequiredDocumentsServices.getDocumentsPersonaTypes({ setLoading, loader: true });
        const DOC_OBLIGA_USER: { value: string, name: string }[] = [];
        const docPersonaTypes = personaTypesRes.ok ? personaTypesRes.data : null;
        for (let key in docPersonaTypes) {
            DOC_OBLIGA_USER.push({
                value: docPersonaTypes[key].value,
                name: `<strong>${key === "TOMADOR"
                    ? "Tomador*(opción TOMADOR/PÓLIZA del desplegable)"
                    : `Todos los ${docPersonaTypes[key].label}` }</strong>`,
            });
        }
                    
        let permissions = await RequiredDocumentsServices.getRequiredDocuments({ setLoading, loader: true, product: productId })
        // Comprobamos cual son los documentso obligatorios:
        let docObligatorios = "Documentos obligatorios: <br>";
        if (permissions) {
            // En el caso que haya doc con permisos
            permissions.map((x: any) => {
                // En el mismo nucle aprovechamos y damos formato
                documentTypes.push({
                    value: x.documents_tipo,
                    label: x.documents_tipo + " - " + x.documents_descripcion,
                    showFor: x.required_documents_usuarios,
                    obligatorio: x.required_documents_obligatorio,
                    documentTypeMetadata: x.documents_documentType
                })

                //Si es obligatorio
                if (x.required_documents_obligatorio) {
                    // Convertimos en array para manejar la ampliación de tipos de usuarios
                    const requiredDocumentsArray = x.required_documents_obligatorio.split("-");
                    //Comprobamos       
                    docObligatorios += x.documents_descripcion + " para ";
                    DOC_OBLIGA_USER.map((u: any, index) => {
                        if (requiredDocumentsArray.includes(u.value)) {
                            docObligatorios += u.name + `${index === DOC_OBLIGA_USER.length -1 ? "" : ", "}`;
                        }
                    })
                    docObligatorios += "<br>"
                }
            })
        }
        return {
            documentTypes,
            docObligatorios,
            docPersonaTypes,
        }

    };


}

export default new RequiredDocuments();
