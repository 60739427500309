import React, { Component } from "react";
import { ForbiddenContainer } from "./forbidden.style";
import { images } from "../../assets";
import WebText from "../components-text/web-text";
import MainButton from "../main-button";
import { withGeneral } from "../../context/mixin/with-general";

class ForbiddenLayout extends Component<any, {}> {
    componentDidMount() {
        const { setIs403, is403 } = this.props;
        setIs403(true);
    }

    componentWillUnmount() {
        const { setIs403 } = this.props;
        setIs403(false);
    }

    render() {
        const { history } = this.props;
        return (
            <ForbiddenContainer>
                <div className="not-found-content-middle">
                    <img src={images.NotFoundMiddle} alt="" />
                </div>
                <div className="not-found-content-top">
                    <img src={images.NotFoundTop} alt="" />
                </div>
                <div className="not-found-content">
                    <div className="go-back-container">
                        <div className="go-back-item" onClick={() => (history.push("/"))}>
                            <img src={images.IconArrowLeftGrey} />
                        </div>
                    </div>

                    <div className="not-found-text">
                        <div className="not-found-title">
                            <p>403</p>
                        </div>
                        <WebText className="not-found-description">
                            <p>
                                Usted no tiene permisos para acceder a "{window.location.pathname}"
                            </p>
                        </WebText>
                    </div>

                    <div className="not-found-btn">
                        <MainButton text="Volver" onClick={() => history.push("/")} />
                    </div>
                </div>

                <div className="" />
            </ForbiddenContainer>
        );
    }
}

export default withGeneral(ForbiddenLayout);
