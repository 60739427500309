import GeneralServices from "./GeneralServices";

class HocelotServices {

  verifyEmail = async (args: any) => {
    const res = await GeneralServices.post({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "hocelot/verifyEmail"
    });

    return res.data;
  };

}

export default new HocelotServices();
