import React, { Component } from "react";
import CheckboxItemLayout from "../../../../../../components/checkbox-item/checkbox-item-layout";
import InputBox from "../../../../../../components/input-box/input-box";
import SearchBox from "../../../../../../components/search-box-mediadores/search-box";
import { ValueFormHealthAddress } from "../../../../../../constants/health";
import AddressServices from "../../../../../../services/AddressServices";
import { AddressDataDto, DefaultAddressDataDto, AddressDataItem } from "./address-form-item-dto";
import { HealthAddressFormContainer } from "./address-form-item-style";
import InfoModal from "../../../../../../components/info-modal";
import { images } from "../../../../../../assets";
import DicotomicCheckbox from "../../../../../../components/dicotomic-checkbox/dicotomic-checkbox";
import { deepGet } from "../../../../../../utils/utility";
import TooltipBox from "../../../../../../components/tooltip-box/tooltip-box";

export interface AddressData {
    address: AddressDataItem;
    wayType: string;
    number: string;
    portal?: string;
    stairs?: string;
    floor?: string;
    door?: string;
    postalCode: string;
    town: AddressDataItem;
    province: AddressDataItem;
    sameAsTaker: boolean;
    normalizaDireciones: "N" | "S";
}

interface HealthAddressFormProps {
    validate: {
        validateRequest: boolean;
        isValidated: boolean;
    };
    physicalPerson: boolean;
    onValidated: (person: AddressData) => void;
    onCleanForm?: boolean;
    insured?: boolean;
    onCheckTakerData?: any;
    useTakerData?: boolean;
    setLoading: Function;
    taker?: AddressData;
    insureds?: AddressData[];
    insuredIndex: number;
    isReadOnly?: boolean;
    onAnyChange: Function;
    usePersonalTakerData?: boolean;
    isPersonalCheckChanging?: boolean;
    productFields?: any;
}

interface HealthAddressFormState {
    addressData: AddressDataDto;
    provinces: any[];
    cities: any[];
    streets: any[];
    postalCodes: any[];
    physicalPerson: boolean;
    previousData: AddressDataDto;
    showModal: boolean;
    infoModalText: string;
    showAddressTooltip: boolean;
}

class HealthAddressFormItem extends Component<HealthAddressFormProps, HealthAddressFormState> {

    private _searchWaiter: any = null;

    constructor(props: HealthAddressFormProps) {
        super(props);
        this.state = {
            addressData: DefaultAddressDataDto(),
            provinces: [],
            cities: [],
            streets: [],
            postalCodes: [],
            physicalPerson: this.props.physicalPerson,
            previousData: DefaultAddressDataDto(),
            showModal: false,
            infoModalText: '',
            showAddressTooltip: false,
        }
    }

    componentWillReceiveProps(nextProps: HealthAddressFormProps) {
        let { validate, onCleanForm } = this.props;
        if (nextProps.validate.validateRequest) {
            validate.validateRequest = false;
            validate.isValidated = this.validateForm();
        }
        else if (nextProps.onCleanForm) {
            this.cleanForm();
            onCleanForm = false;
        }

        this.setState({ physicalPerson: nextProps.physicalPerson });

        if (nextProps.useTakerData && !nextProps.onCleanForm) {

            if (!this.state.addressData.sameAsTaker) {
                let newAddressData = this.state.addressData;
                newAddressData.sameAsTaker = !newAddressData.sameAsTaker;
                this.storePreviousData();
                this.completeFormTakerData();
                this.setState({ addressData: newAddressData });
            }
        }
        if (nextProps.isPersonalCheckChanging && !nextProps.useTakerData) {
            this.getPreviousData();
        }
        if (nextProps.insuredIndex !== undefined && nextProps.insuredIndex !== -1 && this.props.insuredIndex !== nextProps.insuredIndex) {
            this.completeInsuredData(nextProps.insuredIndex);
        }
    }

    componentDidMount() {
        if (!this.props.insured && this.props.taker !== undefined) {
            this.completeFormTakerData();
        }
        if (this.props.insured && this.props.taker !== undefined && this.state.addressData.sameAsTaker) {
            this.completeFormTakerData();
        }
    }

    handleCheckTakerChange() {
        let { addressData } = this.state
        const { useTakerData, usePersonalTakerData } = this.props;
        if (!useTakerData) {
            if (!addressData.sameAsTaker) {
                this.storePreviousData();
                this.completeFormTakerData();
                this.props.onAnyChange()
                this.props.onCheckTakerData(true);
            }
        }
        else if (addressData.sameAsTaker && !usePersonalTakerData) {
            this.getPreviousData();
            this.props.onCheckTakerData(false);
        }

    }

    storePreviousData() {
        const { addressData } = this.state;
        const previousData: AddressDataDto = JSON.parse(JSON.stringify(addressData));

        previousData.address.errorCode = "";
        previousData.number.errorCode = "";
        previousData.portalValue.errorCode = "";
        previousData.stairsValue.errorCode = "";
        previousData.floorValue.errorCode = "";
        previousData.doorValue.errorCode = "";
        previousData.postalCode.errorCode = "";
        previousData.town.errorCode = "";
        previousData.province.errorCode = "";

        this.setState({ previousData })
    }

    getPreviousData() {
        let { previousData, addressData } = this.state;

        addressData.address = previousData.address;
        addressData.wayType = previousData.wayType;
        addressData.postalCode = previousData.postalCode;
        addressData.town = previousData.town;
        addressData.province = previousData.province;
        addressData.provinceLabel = previousData.province.value;

        addressData.number = previousData.number;
        addressData.doorValue = previousData.doorValue;
        addressData.floorValue = previousData.floorValue;
        addressData.portalValue = previousData.portalValue;
        addressData.stairsValue = previousData.stairsValue;
        addressData.normalizaDireciones = previousData.normalizaDireciones;

        addressData.sameAsTaker = false;

        previousData = DefaultAddressDataDto();
        this.setState({ previousData, addressData });
    }

    completeInsuredData(index: number) {
        const { insureds } = this.props;

        let currentInsured = insureds ? insureds[index] : undefined;
        if (currentInsured) {
            if (currentInsured.sameAsTaker) {
                this.completeFormTakerData();
            }
            else {
                this.setAddressData(currentInsured, currentInsured.sameAsTaker);
            }
        }
        else {
            this.cleanForm();
        }
    }

    setAddressData(newData: AddressData, disabled: boolean) {
        let { addressData } = this.state;
        addressData.address = this.getValueFormHealthAddress(newData.address, disabled);
        addressData.wayType = newData.wayType;
        addressData.postalCode = this.getValueFormHealthAddress(newData.postalCode, disabled);
        addressData.town = this.getValueFormHealthAddress(newData.town, disabled);
        addressData.province = this.getValueFormHealthAddress(newData.province, disabled);
        addressData.number = this.getValueFormHealthAddress(newData.number, disabled);
        addressData.doorValue = this.getValueFormHealthAddress(newData.door, disabled);
        addressData.floorValue = this.getValueFormHealthAddress(newData.floor, disabled);
        addressData.portalValue = this.getValueFormHealthAddress(newData.portal, disabled);
        addressData.stairsValue = this.getValueFormHealthAddress(newData.stairs, disabled);
        addressData.normalizaDireciones = newData.normalizaDireciones;
        addressData.sameAsTaker = newData.sameAsTaker;

        this.setState({ addressData });
    }

    getValueFormHealthAddress(value: string | AddressDataItem | undefined, disabled: boolean): ValueFormHealthAddress {
        if (!value || typeof value === 'string') {
            return {
                value: value ? value : '',
                label: '',
                errorCode: '',
                disabled: disabled,
                code: '',
            };
        } else {
            return {
                value: value.value,
                label: value.label,
                errorCode: '',
                disabled: disabled,
                code: value.code,
            };
        }

    }

    mapAddressDataItem = (item: ValueFormHealthAddress): AddressDataItem => {
        return {
            value: item.value,
            code: item.code,
            label: item.label
        }
    }

    completeFormTakerData() {
        const { taker, insured } = this.props;
        if (taker && Object.keys(taker).length > 0) {
            taker.sameAsTaker = true;
            this.setAddressData(taker, insured === true)
        }
    }

    handleChangeNormalizedAddress = (value: boolean) => {
        const { addressData } = this.state;
        const normalizaDireciones = value ? "N" : "S";
        addressData.normalizaDireciones = normalizaDireciones;
        this.props.onAnyChange();
        // Si de no normalizado pasa a normalizado
        // limpia los datos para coger los correctos normalizados
        if (normalizaDireciones === "S") {
            addressData.province = {
                ...addressData.province,
                value: "",
                label: "",
                code: "",
                errorCode: "",
            }
            addressData.town = {
                ...addressData.town,
                value: "",
                label: "",
                errorCode: "",
                disabled: true,
            }
            addressData.address = {
                ...addressData.address,
                value: "",
                label: "",
                errorCode: "",
                disabled: true,
            }
            addressData.postalCode = {
                ...addressData.postalCode,
                value: "",
                label: "",
                errorCode: "",
                disabled: true,
            }
        } else {
            addressData.province = {
                ...addressData.province,
                errorCode: "",
                disabled: false,
            }
            addressData.town = {
                ...addressData.town,
                errorCode: "",
                disabled: false,
            }
            addressData.address = {
                ...addressData.address,
                errorCode: "",
                disabled: false,
            }
            addressData.postalCode = {
                ...addressData.postalCode,
                errorCode: "",
                disabled: false,
            }
        }
        this.setState({ addressData });
    }

    async handleFormChange(value: any, input: string): Promise<void> {
        let { addressData } = this.state
        this.props.onAnyChange();
        const normalizaDireciones = addressData.normalizaDireciones === "S";
        switch (input) {
            case "number":
            case "stairsValue":
            case "portalValue":
            case "doorValue":
            case "floorValue":
                value = value.toUpperCase();
                Object.keys(addressData).forEach((k: string) => {
                    if (k === input) {
                        if (addressData[k].value.length === 0) {
                            addressData[k].errorCode = '';
                        }
                        addressData[k].value = value;
                    }
                })
                this.setState({ addressData });
                break;

            case "province":
            case "town":
            case "postalCode":
                Object.keys(addressData).forEach((k: string) => {
                    if (k === input) {
                        if (addressData[k].value.length === 0) {
                            addressData[k].errorCode = '';
                        }
                        addressData[k].value = value.label;
                        addressData[k].code = value.value;
                    }
                })

                if (input === 'province' && normalizaDireciones) {
                    addressData.town.disabled = false;
                    addressData.address.disabled = true;
                    addressData.postalCode.disabled = true;
                    addressData.provinceLabel = value.label;

                }
                else if (input === 'town' && normalizaDireciones) {
                    addressData.address.disabled = false;
                    addressData.postalCode.disabled = true;
                }
                else if (!normalizaDireciones && input === 'postalCode' && value.value.length === 5) {
                    addressData.province.code = value.value.slice(0, 2);
                }

                this.setState({ addressData });
                break;
            case "address":
                addressData.postalCode.disabled = false;
                addressData.wayType = normalizaDireciones ? value.extra.street.code : "CALLE";
                if (addressData.address.value.length === 0) {
                    addressData.address.errorCode = '';
                }
                addressData.address.value = normalizaDireciones ? value.extra.street.name : value.value;
                addressData.address.label = value.label;
                addressData.address.code = value.value;
                break;
        }
        this.setState({ addressData })
        let formatedStreet = addressData.address.value;
        if (addressData.wayType) {
            formatedStreet = addressData.wayType + " " + formatedStreet
        }
        //Comprobación código INE
        if (this.checkAddresComplete() && normalizaDireciones) {
            let resAddressCheck = await AddressServices.getAddressCheck({
                city: addressData.town.value,
                street: formatedStreet,
                house: addressData.number.value,
                zip: addressData.postalCode.value,
                loader: true,
                setLoading: this.props.setLoading
            });

            if (resAddressCheck && resAddressCheck.length > 0) {
                addressData.postalCode.errorCode = '';
                addressData.province.errorCode = '';
                addressData.town.errorCode = '';
                addressData.number.errorCode = '';
                addressData.address.errorCode = '';
                this.setState({ addressData });
            }
            else {//Cuando la dirección no es válida
                addressData.postalCode.errorCode = 'invalid-address';
                addressData.province.errorCode = 'invalid-address';
                addressData.town.errorCode = 'invalid-address';
                addressData.number.errorCode = 'invalid-address';
                addressData.address.errorCode = 'invalid-address';
                this.setState({ addressData });
                if(resAddressCheck.data.message){
                    this.setState({showModal : true, infoModalText: resAddressCheck.data.message});
                }
            }
        }
    }
    checkAddresComplete(): boolean {
        const { addressData } = this.state;

        if (addressData.address.value !== '' &&
            addressData.town.value !== '' &&
            addressData.number.value !== '' &&
            addressData.postalCode.value !== '')
            return true

        return false;
    }

    validateForm(): boolean {
        let { addressData } = this.state;
        let noErrors = true;

        //Validación código postal
        if (!addressData.postalCode.value) {
            addressData.postalCode.errorCode = 'required';
            noErrors = false;
        }

        //Validación población
        if (!addressData.town.value) {
            addressData.town.errorCode = 'required';
            noErrors = false;
        }

        //Validación provincia
        if (!addressData.province.value) {
            addressData.province.errorCode = 'required';
            noErrors = false;
        }

        //Validación domicilio
        if (!addressData.address.value) {
            addressData.address.errorCode = 'required';
            noErrors = false;
        }

        //Validación número/km
        if (!addressData.number.value) {
            addressData.number.errorCode = 'required';
            noErrors = false;
        }

        //Si el checkAddress dió error
        if (
            addressData.number.errorCode === 'invalid-address' ||
            addressData.address.errorCode === 'invalid-address' ||
            addressData.province.errorCode === 'invalid-address' ||
            addressData.town.errorCode === 'invalid-address' ||
            addressData.postalCode.errorCode === 'invalid-address'
        ) {
            noErrors = false;
        }

        if (noErrors) {
            if (this.props.physicalPerson) {
                this.props.onValidated(
                    {
                        address: this.mapAddressDataItem(addressData.address),
                        wayType: addressData.wayType,
                        number: addressData.number.value,
                        portal: addressData.portalValue.value,
                        stairs: addressData.stairsValue.value,
                        floor: addressData.floorValue.value,
                        door: addressData.doorValue.value,
                        postalCode: addressData.postalCode.value,
                        town: this.mapAddressDataItem(addressData.town),
                        province: this.mapAddressDataItem(addressData.province),
                        sameAsTaker: addressData.sameAsTaker,
                        normalizaDireciones: addressData.normalizaDireciones
                    }
                );
            }
            else {
                this.props.onValidated(
                    {
                        address: this.mapAddressDataItem(addressData.address),
                        wayType: addressData.wayType,
                        number: addressData.number.value,
                        portal: addressData.portalValue.value,
                        stairs: addressData.stairsValue.value,
                        floor: addressData.floorValue.value,
                        door: addressData.doorValue.value,
                        postalCode: addressData.postalCode.value,
                        town: this.mapAddressDataItem(addressData.town),
                        province: this.mapAddressDataItem(addressData.province),
                        sameAsTaker: addressData.sameAsTaker,
                        normalizaDireciones: addressData.normalizaDireciones,
                    }
                );
            }
        }
        this.setState({ addressData });
        return noErrors;
    }

    cleanForm() {
        let { addressData, streets, postalCodes, provinces, cities } = this.state;

        addressData = DefaultAddressDataDto();
        streets = [];
        postalCodes = [];
        provinces = [];
        cities = [];

        this.setState({ addressData, streets, postalCodes, provinces, cities });
    }

    getProvinces = async (input: string, block: string) => {
        const { setLoading } = this.props;
        let { addressData } = this.state;
        const resProvinces = await AddressServices.getProvinces({
            loader: false,
            setLoading: setLoading,
            params: [`input=${input}`],
        });
        const provinces = resProvinces.map((p: any) => ({
            label: p.name,
            value: p.code,
        }));
        addressData.town.value = "";
        addressData.address.value = "";
        addressData.address.label = "";
        addressData.postalCode.value = "";
        addressData.number.value = "";
        addressData.doorValue.value = "";
        addressData.stairsValue.value = "";
        addressData.floorValue.value = "";
        addressData.portalValue.value = "";

        this.setState({
            provinces,
            cities: [],
            streets: [],
            postalCodes: [],
            addressData
        });
    };

    getCities = async (input: string, block: string) => {
        const { setLoading } = this.props;
        const { addressData } = this.state;
        const resCities = await AddressServices.getCities({
            loader: false,
            setLoading: setLoading,
            params: [
                `input=${input}`,
                `provinceCode=${addressData.province.code}`,
            ],
        });
        const cities = resCities.map((p: any) => ({
            label: p.name,
            value: p.code,
        }));
        addressData.address.value = "";
        addressData.postalCode.value = "";
        addressData.number.value = "";
        addressData.doorValue.value = "";
        addressData.stairsValue.value = "";
        addressData.floorValue.value = "";
        addressData.portalValue.value = "";

        this.setState({ cities, streets: [], postalCodes: [], addressData });
    };

    getStreets = async (input: string, block: string) => {
        const { setLoading } = this.props;
        const { addressData } = this.state;
        const resStreets = await AddressServices.getStreets({
            loader: false,
            setLoading: setLoading,
            params: [
                `input=${input}`,
                `provinceCode=${addressData.province.code}`,
                `cityCode=${addressData.town.code}`,
            ],
        });
        addressData.postalCode.value = "";
        const streets = resStreets.map((p: any) => ({
            label: `${p.extra.street.code} ${p.extra.street.name}`,
            value: p.code,
            extra: p.extra,
        }));
        addressData.postalCode.value = "";
        addressData.number.value = "";
        addressData.doorValue.value = "";
        addressData.stairsValue.value = "";
        addressData.floorValue.value = "";
        addressData.portalValue.value = "";
        this.setState({ streets, postalCodes: [], addressData });
    };

    getPostalCodes = async (input: string, block: string) => {
        const { setLoading } = this.props;
        const { addressData } = this.state;
        const resPostalCodes = await AddressServices.getPostalCodes({
            loader: false,
            setLoading: setLoading,
            params: [
                `input=${input}`,
                `provinceCode=${addressData.province.code}`,
                `cityCode=${addressData.town.code}`,
                `street=${addressData.address.code}`,
            ],
        });
        const postalCodes = resPostalCodes.map((p: any) => ({
            label: p.name,
            value: p.name,
        }));

        this.setState({ postalCodes });
    };

    changeValueSearchBox = (ev: any, block: string, key: string = "") => {
        const { provinces, cities, streets, postalCodes, addressData } = this.state;

        if (this._searchWaiter) {
            clearInterval(this._searchWaiter);
        }
        const value: string = ev.target.value;
        if (value.length < 2) return;

        this._searchWaiter = setTimeout(async () => {
            switch (key) {
                case "province":
                    if (
                        !provinces[0] ||
                        provinces[0].label.toLowerCase() !== value.toLowerCase()
                    ) {
                        this.getProvinces(value, block);
                    }
                    break;
                case "city":
                    if (
                        !cities[0] ||
                        cities[0].label.toLowerCase() !== value.toLowerCase()
                    ) {
                        this.getCities(value, block);
                    }
                    break;
                case "postalCode":
                    if (
                        !postalCodes[0] ||
                        postalCodes[0].label.toLowerCase() !== value.toLowerCase()
                    ) {
                        this.getPostalCodes(value, block);
                    }
                    break;
                case "street":
                    if (
                        !streets[0] ||
                        streets[0].label.toLowerCase() !== value.toLowerCase()
                    ) {
                        this.getStreets(value, block);
                    }
                    break;
                default:
                    break;
            }
        }, 400);
    };

    render() {
        const { addressData,
            provinces,
            cities,
            streets,
            postalCodes,
            showModal,
            showAddressTooltip,
        } = this.state

        const { insured, isReadOnly, productFields } = this.props;
        const normalizaDireciones = addressData.normalizaDireciones === "S";
        const addressTitleTooltip = deepGet(productFields, "step2.addressTitleTooltip.tooltip", "");

        return (
            <HealthAddressFormContainer>
                <div className="content-wrapper bottomMargin20" ref={''}>
                    <div className="personal-data-wrapper" id="data-address">
                        <div className="data-title">
                            <h5>
                                DATOS DOMICILIO
                                {addressTitleTooltip && (
                                    <div className="data-title-tooltip">
                                        <img
                                            src={images.IconInfoBlue}
                                            alt=""
                                            onMouseEnter={() => this.setState({ showAddressTooltip: true})}
                                            onMouseLeave={() => this.setState({ showAddressTooltip: false })}
                                        />
                                        {showAddressTooltip && 
                                            <div className="data-title-tooltip-container">
                                                <TooltipBox
                                                    active={showAddressTooltip}
                                                    tooltipTitle={addressTitleTooltip}
                                                    tooltipText={""}
                                                    leftArrow
                                                    left={""} 
                                                    top={""}
                                                />
                                            </div>
                                        }
                                    </div>
                                )}
                            </h5>
                        </div>

                        <div className="colSin-100">
                            {insured ? (
                                <CheckboxItemLayout
                                    name='Los datos del domicilio son los mismos que los del tomador'
                                    checked={addressData.sameAsTaker}
                                    onChange={() => this.handleCheckTakerChange()}
                                    showError={false}
                                    checkboxText='Los datos del domicilio son los mismos que los del tomador'
                                    errorText='Error'
                                    disabled={isReadOnly}
                                />
                            ) : ''
                            }

                            <div className="colSin-33 rightPadding20 bottomMargin20 topMargin20">
                                {normalizaDireciones ? (
                                    <div className="inputBox">
                                        <SearchBox
                                            onChange={(ev: any) =>
                                                this.changeValueSearchBox(ev, "address", "province")
                                            }
                                            errorCode={addressData.province.errorCode}
                                            onSelect={(item: string) => this.handleFormChange(item, "province")}
                                            required
                                            disabled={addressData.province.disabled || isReadOnly}
                                            withLoader={true}
                                            initialValue={addressData.province.value}
                                            optionsText={provinces}
                                            optionKey={"code"}
                                            optionValue={"label"}
                                            labelText=""
                                            notScroll
                                        />
                                        <label>PROVINCIA</label>
                                    </div>
                                ) : (
                                    <div className="inputBox">
                                        <InputBox
                                            placeholder="Provincia"
                                            disabled={addressData.province.disabled || isReadOnly}
                                            required
                                            errorCode={addressData.province.errorCode}
                                            value={addressData.province.value}
                                            type={"text"}
                                            onChange={(value) =>
                                            this.handleFormChange({ label: value.toUpperCase(), value }, "province")
                                            }
                                            notScroll
                                        />   
                                    </div>
                                )}
                            </div>

                            <div className="colSin-66 rightPadding20 bottomMargin20 topMargin20">
                                {normalizaDireciones ? (
                                    <div className="inputBox">
                                        <SearchBox
                                            onChange={(ev: any) =>
                                                this.changeValueSearchBox(ev, "address", "city")
                                            }
                                            errorCode={addressData.town.errorCode}
                                            onSelect={(item: string) => this.handleFormChange(item, "town")}
                                            required
                                            disabled={addressData.town.disabled || isReadOnly}
                                            initialValue={addressData.town.value}
                                            optionsText={cities}
                                            optionKey={"code"}
                                            optionValue={"label"}
                                            labelText=''
                                            withLoader={true}
                                            notScroll
                                        />
                                        <label>POBLACIÓN</label>
                                    </div>
                                ) : (
                                    <div className="inputBox">
                                        <InputBox
                                            placeholder="Población"
                                            disabled={addressData.town.disabled || isReadOnly}
                                            required
                                            errorCode={addressData.town.errorCode}
                                            value={addressData.town.value}
                                            type={"text"}
                                            onChange={(value) => this.handleFormChange({ label: value.toUpperCase(), value }, "town")}
                                            notScroll
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="colSin-100 rightPadding20 bottomMargin20">
                                {normalizaDireciones ? (
                                    <div className="inputBox">
                                        <SearchBox
                                            onChange={(ev: any) =>
                                                this.changeValueSearchBox(ev, "address", "street")
                                            }
                                            errorCode={addressData.address.errorCode}
                                            onSelect={(item: string) => this.handleFormChange(item, "address")}
                                            required
                                            disabled={addressData.address.disabled || isReadOnly}
                                            initialValue={addressData.address.label}
                                            optionsText={streets}
                                            optionKey={"code"}
                                            optionValue={"label"}
                                            labelText=''
                                            withLoader={true}
                                            notScroll
                                        />
                                        <label>DOMICILIO</label>
                                    </div>
                                ) : (
                                    <div className="inputBox">
                                        <InputBox
                                            placeholder="Domicilio"
                                            disabled={addressData.address.disabled || isReadOnly}
                                            required
                                            errorCode={addressData.address.errorCode}
                                            value={addressData.address.label}
                                            type={"text"}
                                            onChange={(value) => this.handleFormChange({ label: value.toUpperCase(), value }, "address")}
                                            notScroll
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">

                                <div className="inputBox">
                                    <InputBox
                                        errorCode={addressData.number.errorCode}
                                        onChange={(value: string) => this.handleFormChange(value, "number")}
                                        placeholder="NÚMERO/KM"
                                        required
                                        disabled={addressData.number.disabled || isReadOnly}
                                        type={"text"}
                                        maxLength={10}
                                        value={addressData.number.value}
                                    />
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        onChange={(value: string) => this.handleFormChange(value, "portalValue")}
                                        placeholder="PORTAL"
                                        type={"number"}
                                        disabled={addressData.portalValue.disabled || isReadOnly}
                                        maxLength={5}
                                        value={addressData.portalValue.value}
                                    />
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        onChange={(value: string) => this.handleFormChange(value, "stairsValue")}
                                        placeholder="ESCALERA"
                                        type={"text"}
                                        disabled={addressData.stairsValue.disabled || isReadOnly}
                                        maxLength={5}
                                        value={addressData.stairsValue.value}
                                    />
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        onChange={(value: string) => this.handleFormChange(value, "floorValue")}
                                        placeholder="PISO"
                                        type={"text"}
                                        disabled={addressData.floorValue.disabled || isReadOnly}
                                        maxLength={5}
                                        value={addressData.floorValue.value}
                                    />
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        onChange={(value: string) => this.handleFormChange(value, "doorValue")}
                                        placeholder="PUERTA"
                                        type={"text"}
                                        maxLength={5}
                                        disabled={addressData.doorValue.disabled || isReadOnly}
                                        value={addressData.doorValue.value}
                                    />
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">
                                {normalizaDireciones ? (
                                    <div className="inputBox">
                                        <SearchBox
                                            onChange={(ev: any) =>
                                                this.changeValueSearchBox(ev, "address", "postalCode")
                                            }
                                            errorCode={addressData.postalCode.errorCode}
                                            onSelect={(item: string) => this.handleFormChange(item, "postalCode")}
                                            required
                                            disabled={addressData.postalCode.disabled || isReadOnly}
                                            initialValue={addressData.postalCode.value}
                                            optionsText={postalCodes}
                                            optionKey={"code"}
                                            optionValue={"label"}
                                            labelText=''
                                            withLoader={true}
                                            notScroll
                                        />
                                        <label>CÓDIGO POSTAL</label>
                                    </div>
                                ) : (
                                    <div className="inputBox">
                                        <InputBox
                                            required
                                            placeholder="Código postal"
                                            disabled={addressData.postalCode.disabled || isReadOnly}
                                            errorCode={addressData.postalCode.errorCode}
                                            value={addressData.postalCode.value}
                                            maxLength={5}
                                            type={"number"}
                                            onChange={(value) => this.handleFormChange({ label: value, value }, "postalCode")}
                                            notScroll
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="colSin-80 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <DicotomicCheckbox
                                        className={`${addressData.province.disabled || isReadOnly ? "disabled" : "" } optional`}
                                        checkboxText="CONTRATAR CON DIRECCIÓN NO NORMALIZADA"
                                        onChange={(value: boolean) => this.handleChangeNormalizedAddress(value)}
                                        disabled={addressData.province.disabled || isReadOnly}
                                        initChecked={addressData.normalizaDireciones === "N"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && (
                    <InfoModal
                    mainText={"Domicilio incorrecto. Por favor, verifique si los datos son correctos o debe modificarlos. "+this.state.infoModalText}
                    close={() => this.setState({ showModal: false })}
                    active={true}
                    img={images.IconInfoBlue}
                    />
                )}
            </HealthAddressFormContainer >
        )

    }
}

export default HealthAddressFormItem;
