import React, { Component } from "react";
import CheckboxItemLayout from "../../../../../../components/checkbox-item/checkbox-item-layout";
import InputBox from "../../../../../../components/input-box/input-box";
import SearchBox from "../../../../../../components/search-box-mediadores/search-box";
import { ValueFormHealthAddress } from "../../../../../../constants/health";
import AddressServices from "../../../../../../services/AddressServices";
import { AddressDataDto, DefaultAddressDataDto, AddressDataItem } from "./gip-address-form-item-dto";
import { HealthAddressFormContainer } from "./gip-address-form-item-style";
import InfoModal from "../../../../../../components/info-modal";
import { images } from "../../../../../../assets";

export interface AddressData {
    address: AddressDataItem;
    wayType: string;
    number: string;
    other?: string,
    postalCode: string;
    town: AddressDataItem;
    province: AddressDataItem;
    sameAsTaker: boolean;
}

interface GipAddressFormProps {
    validate: {
        validateRequest: boolean;
        isValidated: boolean;
    };
    physicalPerson: boolean;
    onValidated: (person: AddressData) => void;
    onCleanForm?: boolean;
    insured?: boolean;
    onCheckTakerData?: any;
    useTakerData?: boolean;
    setLoading: Function;
    taker?: AddressData;
    insureds?: AddressData[];
    insuredIndex: number;
    isReadOnly?: boolean;
    onAnyChange: Function;
    usePersonalTakerData?: boolean;
    isPersonalCheckChanging?: boolean;
}

interface GipAddressFormState {
    addressData: AddressDataDto;
    provinces: any[];
    cities: any[];
    streets: any[];
    postalCodes: any[];
    physicalPerson: boolean;
    previousData: AddressDataDto;
    showModal: boolean;
    infoModalText: string;
}

class GipAddressFormItem extends Component<GipAddressFormProps, GipAddressFormState> {

    private _searchWaiter: any = null;

    constructor(props: GipAddressFormProps) {
        super(props);
        this.state = {
            addressData: DefaultAddressDataDto(),
            provinces: [],
            cities: [],
            streets: [],
            postalCodes: [],
            physicalPerson: this.props.physicalPerson,
            previousData: DefaultAddressDataDto(),
            showModal: false,
            infoModalText: ''
        }
    }

    componentWillReceiveProps(nextProps: GipAddressFormProps) {
        let { validate, onCleanForm } = this.props;
        if (nextProps.validate.validateRequest) {
            validate.validateRequest = false;
            validate.isValidated = this.validateForm();
        }
        else if (nextProps.onCleanForm) {
            this.cleanForm();
            onCleanForm = false;
        }

        this.setState({ physicalPerson: nextProps.physicalPerson });

        if (nextProps.useTakerData && !nextProps.onCleanForm) {

            if (!this.state.addressData.sameAsTaker) {
                let newAddressData = this.state.addressData;
                newAddressData.sameAsTaker = !newAddressData.sameAsTaker;
                this.storePreviousData();
                this.completeFormTakerData();
                this.setState({ addressData: newAddressData });
            }
        }
        if (nextProps.isPersonalCheckChanging && !nextProps.useTakerData) {
            this.getPreviousData();
        }
        if (nextProps.insuredIndex !== undefined && nextProps.insuredIndex !== -1 && this.props.insuredIndex !== nextProps.insuredIndex) {
            this.completeInsuredData(nextProps.insuredIndex);
        }
    }

    componentDidMount() {
        if (!this.props.insured && this.props.taker !== undefined) {
            this.completeFormTakerData();
        }
        if (this.props.insured && this.props.taker !== undefined && this.state.addressData.sameAsTaker) {
            this.completeFormTakerData();
        }
    }

    handleCheckTakerChange() {
        let { addressData } = this.state
        const { useTakerData, usePersonalTakerData } = this.props;
        if (!useTakerData) {
            if (!addressData.sameAsTaker) {
                this.storePreviousData();
                this.completeFormTakerData();
                this.props.onCheckTakerData(true);
            }
        }
        else if (addressData.sameAsTaker && !usePersonalTakerData) {
            this.getPreviousData();
            this.props.onCheckTakerData(false);
        }

    }

    storePreviousData() {
        const { addressData } = this.state;
        const previousData: AddressDataDto = JSON.parse(JSON.stringify(addressData));

        previousData.address.errorCode = "";
        previousData.number.errorCode = "";
        previousData.other.errorCode = "";
        previousData.postalCode.errorCode = "";
        previousData.town.errorCode = "";
        previousData.province.errorCode = "";

        this.setState({ previousData })
    }

    getPreviousData() {
        let { previousData, addressData } = this.state;

        addressData.address = previousData.address;
        addressData.wayType = previousData.wayType;
        addressData.postalCode = previousData.postalCode;
        addressData.town = previousData.town;
        addressData.province = previousData.province;
        addressData.provinceLabel = previousData.province.value;

        addressData.number = previousData.number;
        addressData.other = previousData.other;

        addressData.sameAsTaker = false;

        previousData = DefaultAddressDataDto();
        this.setState({ previousData, addressData });
    }

    completeInsuredData(index: number) {
        const { insureds } = this.props;

        let currentInsured = insureds ? insureds[index] : undefined;
        if (currentInsured) {
            if (currentInsured.sameAsTaker) {
                this.completeFormTakerData();
            }
            else {
                this.setAddressData(currentInsured, currentInsured.sameAsTaker);
            }
        }
        else {
            this.cleanForm();
        }
    }

    setAddressData(newData: AddressData, disabled: boolean) {
        let { addressData } = this.state;
        addressData.address = this.getValueFormHealthAddress(newData.address, disabled);
        addressData.wayType = newData.wayType;
        addressData.postalCode = this.getValueFormHealthAddress(newData.postalCode, disabled);
        addressData.town = this.getValueFormHealthAddress(newData.town, disabled);
        addressData.province = this.getValueFormHealthAddress(newData.province, disabled);
        addressData.number = this.getValueFormHealthAddress(newData.number, disabled);
        addressData.other = this.getValueFormHealthAddress(newData.other, disabled);

        addressData.sameAsTaker = newData.sameAsTaker;

        this.setState({ addressData });
    }

    getValueFormHealthAddress(value: string | AddressDataItem | undefined, disabled: boolean): ValueFormHealthAddress {
        if (!value || typeof value === 'string') {
            return {
                value: value ? value : '',
                label: '',
                errorCode: '',
                disabled: disabled,
                code: '',
            };
        } else {
            return {
                value: value.value,
                label: value.label,
                errorCode: '',
                disabled: disabled,
                code: value.code,
            };
        }

    }

    mapAddressDataItem = (item: ValueFormHealthAddress): AddressDataItem => {
        return {
            value: item.value,
            code: item.code,
            label: item.label
        }
    }

    completeFormTakerData() {
        const { taker, insured } = this.props;
        if (taker && Object.keys(taker).length > 0) {
            taker.sameAsTaker = true;
            this.setAddressData(taker, insured === true)
        }
    }

    async handleFormChange(value: any, input: string): Promise<void> {
        let { addressData } = this.state;
        this.props.onAnyChange();

        switch (input) {
            case "number":
            case "other":
                value = value.toUpperCase();
                Object.keys(addressData).forEach((k: string) => {
                    if (k === input) {
                        if (addressData[k].value.length === 0) {
                            addressData[k].errorCode = '';
                        }
                        addressData[k].value = value;
                    }
                })
                this.setState({ addressData });
                break;

            case "province":
            case "town":
            case "postalCode":
                Object.keys(addressData).forEach((k: string) => {
                    if (k === input) {
                        if (addressData[k].value.length === 0) {
                            addressData[k].errorCode = '';
                        }
                        addressData[k].value = value.label;
                        addressData[k].code = value.value;
                    }
                })

                if (input === 'province') {
                    addressData.town.disabled = false;
                    addressData.address.disabled = true;
                    addressData.postalCode.disabled = true;
                    addressData.provinceLabel = value.label;

                }
                else if (input === 'town') {
                    addressData.address.disabled = false;
                    addressData.postalCode.disabled = true;
                }

                this.setState({ addressData });
                break;
            case "address":
                addressData.postalCode.disabled = false;
                addressData.wayType = value.extra.street.code;
                if (addressData.address.value.length === 0) {
                    addressData.address.errorCode = '';
                }
                addressData.address.value = value.extra.street.name;
                addressData.address.label = value.label;
                addressData.address.code = value.value;
                break;
        }
        let formatedStreet = addressData.address.value;
        if (addressData.wayType) {
            formatedStreet = addressData.wayType + " " + formatedStreet
        }
        //Comprobación código INE
        if (this.checkAddresComplete()) {
            let resAddressCheck = await AddressServices.getAddressCheck({
                city: addressData.town.value,
                street: formatedStreet,
                house: addressData.number.value,
                zip: addressData.postalCode.value,
                loader: true,
                setLoading: this.props.setLoading
            });
            if (resAddressCheck[0] && resAddressCheck[0].zip && resAddressCheck[0].zip.toString() !== addressData.postalCode.value || resAddressCheck[0] &&  !resAddressCheck[0].zip) {

                addressData.postalCode.errorCode = 'invalid-postal-code';
                this.setState({ addressData });
            }
            else if (resAddressCheck && resAddressCheck.length > 0) {
                addressData.postalCode.errorCode = '';
                addressData.province.errorCode = '';
                addressData.town.errorCode = '';
                addressData.number.errorCode = '';
                addressData.address.errorCode = '';
                this.setState({ addressData });
            }
            else {//Cuando la dirección no es válida
                addressData.postalCode.errorCode = 'invalid-address';
                addressData.province.errorCode = 'invalid-address';
                addressData.town.errorCode = 'invalid-address';
                addressData.number.errorCode = 'invalid-address';
                addressData.address.errorCode = 'invalid-address';
                this.setState({ addressData });
                if(resAddressCheck.data.message){
                    this.setState({showModal : true, infoModalText: resAddressCheck.data.message});
                  }
            }
        }
    }
    checkAddresComplete(): boolean {
        const { addressData } = this.state;

        if (addressData.address.value !== '' &&
            addressData.town.value !== '' &&
            addressData.number.value !== '' &&
            addressData.postalCode.value !== '')
            return true

        return false;
    }

    validateForm(): boolean {
        let { addressData } = this.state;
        let noErrors = true;

        //Validación código postal
        if (!addressData.postalCode.value) {
            addressData.postalCode.errorCode = 'required';
            noErrors = false;
        }

        //Validación población
        if (!addressData.town.value) {
            addressData.town.errorCode = 'required';
            noErrors = false;
        }

        //Validación provincia
        if (!addressData.province.value) {
            addressData.province.errorCode = 'required';
            noErrors = false;
        }

        //Validación domicilio
        if (!addressData.address.value) {
            addressData.address.errorCode = 'required';
            noErrors = false;
        }

        //Validación número/km
        if (!addressData.number.value) {
            addressData.number.errorCode = 'required';
            noErrors = false;
        }

        if (noErrors) {
            if (this.props.physicalPerson) {
                this.props.onValidated(
                    {
                        address: this.mapAddressDataItem(addressData.address),
                        wayType: addressData.wayType,
                        number: addressData.number.value,
                        other: addressData.other.value,
                        postalCode: addressData.postalCode.value,
                        town: this.mapAddressDataItem(addressData.town),
                        province: this.mapAddressDataItem(addressData.province),
                        sameAsTaker: addressData.sameAsTaker
                    }
                );
            }
            else {
                this.props.onValidated(
                    {
                        address: this.mapAddressDataItem(addressData.address),
                        wayType: addressData.wayType,
                        number: addressData.number.value,
                        other: addressData.other.value,
                        postalCode: addressData.postalCode.value,
                        town: this.mapAddressDataItem(addressData.town),
                        province: this.mapAddressDataItem(addressData.province),
                        sameAsTaker: addressData.sameAsTaker
                    }
                );
            }
        }
        this.setState({ addressData });
        return noErrors;
    }

    cleanForm() {
        let { addressData, streets, postalCodes, provinces, cities } = this.state;

        addressData = DefaultAddressDataDto();
        streets = [];
        postalCodes = [];
        provinces = [];
        cities = [];

        this.setState({ addressData, streets, postalCodes, provinces, cities });
    }

    getProvinces = async (input: string, block: string) => {
        const { setLoading } = this.props;
        let { addressData } = this.state;
        const resProvinces = await AddressServices.getProvinces({
            loader: false,
            setLoading: setLoading,
            params: [`input=${input}`],
        });
        const provinces = resProvinces.map((p: any) => ({
            label: p.name,
            value: p.code,
        }));
        addressData.town.value = "";
        addressData.address.value = "";
        addressData.postalCode.value = "";

        this.setState({
            provinces,
            cities: [],
            streets: [],
            postalCodes: [],
            addressData
        });
    };

    getCities = async (input: string, block: string) => {
        const { setLoading } = this.props;
        const { addressData } = this.state;
        const resCities = await AddressServices.getCities({
            loader: false,
            setLoading: setLoading,
            params: [
                `input=${input}`,
                `provinceCode=${addressData.province.code}`,
            ],
        });
        const cities = resCities.map((p: any) => ({
            label: p.name,
            value: p.code,
        }));
        addressData.address.value = "";
        addressData.postalCode.value = "";

        this.setState({ cities, streets: [], postalCodes: [], addressData });
    };

    getStreets = async (input: string, block: string) => {
        const { setLoading } = this.props;
        const { addressData } = this.state;
        const resStreets = await AddressServices.getStreets({
            loader: false,
            setLoading: setLoading,
            params: [
                `input=${input}`,
                `provinceCode=${addressData.province.code}`,
                `cityCode=${addressData.town.code}`,
            ],
        });
        addressData.postalCode.value = "";
        const streets = resStreets.map((p: any) => ({
            label: `${p.extra.street.code} ${p.extra.street.name}`,
            value: p.code,
            extra: p.extra,
        }));

        this.setState({ streets, postalCodes: [], addressData });
    };

    getPostalCodes = async (input: string, block: string) => {
        const { setLoading } = this.props;
        const { addressData } = this.state;
        const resPostalCodes = await AddressServices.getPostalCodes({
            loader: false,
            setLoading: setLoading,
            params: [
                `input=${input}`,
                `provinceCode=${addressData.province.code}`,
                `cityCode=${addressData.town.code}`,
                `street=${addressData.address.code}`,
            ],
        });
        const postalCodes = resPostalCodes.map((p: any) => ({
            label: p.name,
            value: p.name,
        }));

        this.setState({ postalCodes });
    };

    changeValueSearchBox = (ev: any, block: string, key: string = "") => {
        const { provinces, cities, streets, postalCodes } = this.state;

        if (this._searchWaiter) {
            clearInterval(this._searchWaiter);
        }
        const value: string = ev.target.value;
        if (value.length < 2) return;

        this._searchWaiter = setTimeout(async () => {
            switch (key) {
                case "province":
                    if (
                        !provinces[0] ||
                        provinces[0].label.toLowerCase() !== value.toLowerCase()
                    ) {
                        this.getProvinces(value, block);
                    }
                    break;
                case "city":
                    if (
                        !cities[0] ||
                        cities[0].label.toLowerCase() !== value.toLowerCase()
                    ) {
                        this.getCities(value, block);
                    }
                    break;
                case "postalCode":
                    if (
                        !postalCodes[0] ||
                        postalCodes[0].label.toLowerCase() !== value.toLowerCase()
                    ) {
                        this.getPostalCodes(value, block);
                    }
                    break;
                case "street":
                    if (
                        !streets[0] ||
                        streets[0].label.toLowerCase() !== value.toLowerCase()
                    ) {
                        this.getStreets(value, block);
                    }
                    break;
                default:
                    break;
            }
        }, 400);
    };

    render() {
        const { addressData,
            provinces,
            cities,
            streets,
            postalCodes,
            showModal
        } = this.state

        const { insured, isReadOnly } = this.props;

        return (
            <HealthAddressFormContainer>
                <div className="content-wrapper bottomMargin20" ref={''}>
                    <div className="personal-data-wrapper" id="data-address">
                        <div className="data-title">
                            <h5>DATOS DOMICILIO</h5>
                        </div>

                        <div className="colSin-100">
                            {insured ? (
                                <CheckboxItemLayout
                                    name='Los datos del domicilio son los mismos que los del tomador'
                                    checked={addressData.sameAsTaker}
                                    onChange={() => this.handleCheckTakerChange()}
                                    showError={false}
                                    checkboxText='Los datos del domicilio son los mismos que los del tomador'
                                    errorText='Error'
                                    disabled={isReadOnly}
                                />
                            ) : ''
                            }

                            <div className="colSin-33 rightPadding20 bottomMargin20 topMargin20">
                                <div className="inputBox">
                                    <SearchBox
                                        onChange={(ev: any) =>
                                            this.changeValueSearchBox(ev, "address", "province")
                                        }
                                        errorCode={addressData.province.errorCode}
                                        onSelect={(item: string) => this.handleFormChange(item, "province")}
                                        required
                                        disabled={addressData.province.disabled || isReadOnly}
                                        withLoader={true}
                                        initialValue={addressData.province.value}
                                        optionsText={provinces}
                                        optionKey={"code"}
                                        optionValue={"label"}
                                        labelText=""
                                    />
                                    <label>PROVINCIA</label>
                                </div>
                            </div>

                            <div className="colSin-66 rightPadding20 bottomMargin20 topMargin20">
                                <div className="inputBox">
                                    <SearchBox
                                        onChange={(ev: any) =>
                                            this.changeValueSearchBox(ev, "address", "city")
                                        }
                                        errorCode={addressData.town.errorCode}
                                        onSelect={(item: string) => this.handleFormChange(item, "town")}
                                        required
                                        disabled={addressData.town.disabled || isReadOnly}
                                        initialValue={addressData.town.value}
                                        optionsText={cities}
                                        optionKey={"code"}
                                        optionValue={"label"}
                                        labelText=''
                                        withLoader={true}
                                    />
                                    <label>POBLACIÓN</label>
                                </div>
                            </div>

                            <div className="colSin-60 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <SearchBox
                                        onChange={(ev: any) =>
                                            this.changeValueSearchBox(ev, "address", "street")
                                        }
                                        errorCode={addressData.address.errorCode}
                                        onSelect={(item: string) => this.handleFormChange(item, "address")}
                                        required
                                        disabled={addressData.address.disabled || isReadOnly}
                                        initialValue={addressData.address.label}
                                        optionsText={streets}
                                        optionKey={"code"}
                                        optionValue={"label"}
                                        labelText=''
                                        withLoader={true}
                                    />
                                    <label>DOMICILIO</label>
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">

                                <div className="inputBox">
                                    <InputBox
                                        errorCode={addressData.number.errorCode}
                                        onChange={(value: string) => this.handleFormChange(value, "number")}
                                        placeholder="NÚMERO/KM"
                                        required
                                        disabled={addressData.number.disabled || isReadOnly}
                                        type={"text"}
                                        maxLength={10}
                                        value={addressData.number.value}
                                    />
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        onChange={(value: string) => this.handleFormChange(value, "other")}
                                        placeholder="Resto de dirección"
                                        type={"text"}
                                        disabled={addressData.other.disabled || isReadOnly}
                                        maxLength={10}
                                        value={addressData.other.value}
                                    />
                                </div>
                            </div>

                            <div className="colSin-20 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <SearchBox
                                        onChange={(ev: any) =>
                                            this.changeValueSearchBox(ev, "address", "postalCode")
                                        }
                                        errorCode={addressData.postalCode.errorCode}
                                        onSelect={(item: string) => this.handleFormChange(item, "postalCode")}
                                        required
                                        disabled={addressData.postalCode.disabled || isReadOnly}
                                        initialValue={addressData.postalCode.value}
                                        optionsText={postalCodes}
                                        optionKey={"code"}
                                        optionValue={"label"}
                                        labelText=''
                                        withLoader={true}
                                    />
                                    <label>CÓDIGO POSTAL</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showModal && (
                        <InfoModal
                        mainText={"Domicilio incorrecto. Por favor, verifique si los datos son correctos o debe modificarlos. "+this.state.infoModalText}
                        close={() => this.setState({ showModal: false })}
                        active={true}
                        img={images.IconInfoBlue}
                        />
                    )}
                </div>

            </HealthAddressFormContainer >
        )

    }
}

export default GipAddressFormItem;
