import styled from "styled-components";
import { colors } from "../../assets";

export const InsuredCardContainer = styled.div`
  &.active {
    .insured-card {
      background: #FFFFFF;
      opacity: 1;
    }

    .insured-card-status {
      background: ${colors["SU-001-100"]};
    }
  }

  &.error {
    .insured-card-status {
      background: ${colors["ER-001-100"]};
    }
  }

  .insured-card {
    position: relative;
    cursor: pointer;
    padding: 28px 16px 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    color: ${colors["PR-001-100"]};
    background: rgba(179,202,220, 0.15);
  }

  .insured-card-status {
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 50%;
    display: block;
    width: 6px;
    height: 6px;
    background: #C4C4C4;
  }

  .insured-card-name {
    margin-bottom: 8px;
  }

  .insured-card-type {
    font-family: "museo sans";
    font-weight: 300;
    font-size: 12px;
    padding-right: 32px;
  }

  .insured-card-details {
    position: absolute;
    bottom: 8px;
    right: 34px;

    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .insured-card-erase {
    position: absolute;
    bottom: 6px;
    right: 30px;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }
`;
