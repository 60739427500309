import React from "react";
import SimulationServices from "../../../services/SimulationServices";
import {
  PRODUCT_CARD_NAME_COLOR,
  AREA_NAMES,
} from "../../../constants/policyType";
import { getDayMonthYear, addDays } from "../../../utils/time";
import { getDataForm } from "../../../utils/mapData";
import { ISimulationOne, PendantSimulationProps, PendantSimulationState } from './pendant-simulation-dto';

class PendantSimulationScreen extends React.Component<
  PendantSimulationProps,
  PendantSimulationState
> {
  goToNewSimulation() {
    this.props.history.push("/simulacion/nueva");
  }

  getSimulations = async () => {
    const { setLoading, history, checkProductArea, productsByAreaName, allProducts } = this.props;
    const { showItems, search, page, ascendent, order, selectedMediatorCodes, relatedMediatorCodes } = this.state;

    const params = [`skip=${showItems * (page - 1)}`, `take=${showItems}`];
    if (search) {
      params.push(`search=${search}`);
    }

    if (selectedMediatorCodes) {
      selectedMediatorCodes.forEach((value: string) => {
        const relatedMediator = relatedMediatorCodes.find(med => med.label === value)
        if (relatedMediator) params.push(`mediatorCodes=${relatedMediator.value}`);
      });
    }

    if (window.location.pathname.includes("simulacion/expirada")) {
      params.push(`expired=true`);
    }

    if (ascendent === true && order) {
      params.push(`order=${order}`);
    } else if (ascendent === false && order) {
      params.push(`order=-${order}`);
    }

    const resSimulations = await SimulationServices.getSimulations(true, setLoading, params);
    
    const simulations = resSimulations.items.map((simulation: ISimulationOne) => {
      
      const product = allProducts[simulation.productType]
      const productName = product.products_name
      const productCardName = product.products_cardName
      const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, simulation.productType)
      const isGIPArea = checkProductArea(AREA_NAMES.GIP, simulation.productType)
      const isPetArea = checkProductArea(AREA_NAMES.PET, simulation.productType)
      const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, simulation.productType)
      const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, simulation.productType)
      const productHiringLink = product.products_link

      if (simulation.body.taker && !isGIPArea) {
        simulation.body.taker.coverages = simulation.body && simulation.body.insureds && simulation.body.insureds[0]
          ? simulation.body.insureds[0].coverages
          : [];
      }

      const noInsuredsSimulation = JSON.parse(JSON.stringify(simulation.body));
      noInsuredsSimulation.insureds = [];
      return {
        databaseId: {
          text: simulation.id,
          type: "",
          key: "inline",
        },
        id: {
          text: simulation.response.requestNumber ||
            simulation.requestNumber || simulation.id,
          type: "",
          key: "inline",
        },
        "policy-type": {
          text: productName,
          type: PRODUCT_CARD_NAME_COLOR[productCardName],
          key: "tag-container",
        },
        date: {
          text: getDayMonthYear(simulation.createdAt),
          type: "",
          key: "inline",
        },
        expiration: {
          text: getDayMonthYear(simulation.expiration) || getDayMonthYear(addDays(simulation.createdAt, 30)),
          type: "",
          key: "inline",
        },
        taker: {
          text: [
            simulation.body.taker
              ? simulation.body.taker.name 
                ? `${simulation.body.taker.name} ${simulation.body.taker.lastname1
                  ? simulation.body.taker.lastname1 : ''} ${simulation.body.taker.lastname2
                    ? simulation.body.taker.lastname2 : ''}`
                : simulation.body.taker.email ? simulation.body.taker.email : '-'
              : '-'
            ,
            simulation.body.taker ? simulation.body.taker.docNumber : ''
          ],
          type: "",
          key: "taker",
        },
        // nif: {
        //   text: simulation.body.taker ? simulation.body.taker.docNumber : "-",
        //   type: "",
        //   key: "inline",
        // },
        mediator: {
          text: simulation.mediatorAssociatedToPolicy ? simulation.mediatorAssociatedToPolicy : "",
          type: "",
          key: "mediator",
          toolTips: this.getToolTipsMediator(simulation),
        },
        loggedMediator: {
          text: this.getLoggedMediator(simulation),
          type: "",
          key: "mediator",
          toolTips: this.getToolTipsLoggedMediator(simulation),
        },
        ...(isGIPArea ? 
          {
            action: {
              key: "",
              text: "",
            }
          } 
          : 
          {
            action: {
              key: "button",
              text: "Contratar",
              type: "",
              disabled: this.props.isExpired,
              onClick: () =>
                this.setState({
                  showInfoModal: true,
                  infoModalMainText:
                    "Vas a iniciar el proceso de contratación para esta simulación pendiente, ¿Estás seguro?",
                  infoModalOnClickAccept: () => {

                    let dataToPush;
                    if (isPetArea) {
                      dataToPush = noInsuredsSimulation;
                      dataToPush.simulationInsureds = noInsuredsSimulation.coverages;
                    } else {
                      dataToPush = simulation.body;
                      if (isHealthArea || isGIPArea) {
                        dataToPush.insureds = dataToPush.insuredData;
                        if (isGIPArea)                     
                          dataToPush.taker = {};
                      }else if(isTravelArea){
                        dataToPush.taker = simulation.body.taker
                        dataToPush.mediatorData = simulation.mediator
                        // simulation.body.policyType = simulation.productType
                      }
                      if (isDentalArea) {
                        var insuredsST = dataToPush.insuredData
                        if (dataToPush.insuredData.find((x: any) => x.parentesco === "TOMADOR")) {
                          insuredsST = dataToPush.insuredData.filter((x: any) => x.parentesco !== "TOMADOR")
                          insuredsST.unshift(dataToPush.insuredData.filter((x: any) => x.parentesco === "TOMADOR")[0])
                        }
                        dataToPush.insureds = insuredsST
                        if (!simulation.body.policyType) {
                          dataToPush.taker.name = '';
                        } else
                          dataToPush.taker = {};
                      }
                    }
                    const pathname = productHiringLink && productHiringLink.split('?')[0] ? productHiringLink.split('?')[0] : undefined
                    const params = productHiringLink && productHiringLink.split('?')[1] ? productHiringLink.split('?')[1] : undefined
                    
                    if (simulation.body.policyType) {
                      localStorage.setItem('internalName', simulation.body.policyType)
                      history.push({
                        pathname: `/contratacion${pathname}`,
                        search: params,
                        state: {
                          simulationId: simulation.id,
                          dataForm: getDataForm(
                            simulation.body,
                            simulation.productType,
                            product.products_coverageName,
                            productsByAreaName
                          ),
                          data: {
                            ...dataToPush,
                            payment: {},
                            beneficiaries: [],
                            loan: {},
                            signData: {
                              signId: "",
                              signRequestDate: "",
                              signStatus: ""
                            },
                          },
                          selectedMediatorCode: simulation.mediatorAssociatedToPolicy
                        },
                      })
                    }
                    else {
                      localStorage.setItem('internalName', simulation.productType)
                      history.push({
                        pathname: `/contratacion${pathname}`,
                        search: params,
                        state: {
                          simulationId: simulation.id,
                          dataForm: getDataForm(
                            simulation.body,
                            simulation.productType,
                            product.products_coverageName,
                            productsByAreaName,
                            this.props.coverages,
                            `/contratacion${productHiringLink}`
                          ),
                          data: {
                            ...dataToPush,
                            payment: {},
                            beneficiaries: [],
                            loan: {},
                          },
                          selectedMediatorCode: simulation.mediatorAssociatedToPolicy,
                        },
                      })
                    }
                  },
                }),
            }
          }
        ),
      };
    });

    const pages = Math.ceil(resSimulations.count / showItems);
    this.setState({
      searched: search !== "",
      simulationsObj: resSimulations,
      simulations,
      simulationsFilter: simulations,
      totalPages: pages,
    });
  };

  getLoggedMediator(simulation: ISimulationOne): string {
    const { checkProductArea} = this.props
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, simulation.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, simulation.productType)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, simulation.productType)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, simulation.productType)

    if (isHealthArea || isDentalArea || isGIPArea || isTravelArea) {
      return simulation.mediator ? simulation.mediator : "";
    }
    return simulation.body.loggedUserCode ?
      simulation.body.loggedUserCode : simulation.body.loggedUser ? simulation.body.loggedUser :
        '';
  }

  getToolTipsLoggedMediator(simulation: ISimulationOne): string {
    const { checkProductArea} = this.props
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, simulation.productType)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, simulation.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, simulation.productType)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, simulation.productType)

    if (isHealthArea || isDentalArea || isGIPArea || isTravelArea) {
      return simulation.body.loggedUser ? simulation.body.loggedUser : "";
    }
    return simulation.body.loggedUserCode ?
      simulation.body.loggedUser ? simulation.body.loggedUser : '' :
      '';
  }

  getToolTipsMediator(simulation: ISimulationOne): string {
    const { checkProductArea} = this.props
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, simulation.productType)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, simulation.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, simulation.productType)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, simulation.productType)

    if (isHealthArea || isDentalArea || isGIPArea ) {
      return simulation.body.mediatorData && simulation.body.mediatorData.name ? simulation.body.mediatorData.name : ''
    }
    if (isTravelArea) {
      return simulation.body.mediatorUser && simulation.body.mediatorUser.name ? simulation.body.mediatorUser.name : ''
    }
    return simulation.body.mediatorName ? simulation.body.mediatorName : '';
  }
}
export default PendantSimulationScreen;
