import styled from "styled-components";
import { MainButtonContainer } from "../../components/main-button/main-button-style";
import { InputBoxContainer } from "../../components/input-box/input-box-style";
import { colors, images } from "../../assets";
import { BodyTextContainer } from "../../components/components-text/body-text/body-text-styles";


export const PermissionsContainer = styled.div`

font-family: "museo sans";
.save-footer {
  display:flex;
}
  .permissions-table-container{
    overflow-x: auto !important;

    th, td {
      width: calc(100%/8); /* to enable "word-break: break-all" */
      padding: 15px;
      text-wrap: balance;
      word-break: keep-all;
    }

    td {
      border-bottom: none;
      border-left: none;
      min-width: 200px;
    }
    
  }
  .inputArea, inputBox {
    width: 100%;
    margin-bottom:90px;
  }
  .selectAllButton{
    width:200px;
  }
  .save-text{
    padding-block: 20px;
    width: 492px;
  }
  .scrollingTable{
    padding-block: 20px;
  }
  .permission-content {
    display: flex;
    background:red;
    width:500px;
  }
  .filter-search-button{
    float: right;

    img{
      margin-right: 10px;
    }
  }
  .go-back-head  {

  }
  .permission-content {
    display: flex;
    background:red;
  }
  .permission-left  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 300px;
    width: 100%;
    max-width: 400px;
    margin-right: 20px;
    color: #004f8b;

    .permission-left-top {
      &__title  {
        font-size: 53px;
        line-height: 62px;
        color: #004f8b;
        margin-bottom: 20px;
        font-weight: 700;
      }
      &__subtitle {
        font-size: 18px;
        line-height: 30px;
        color: #004f8b;
        max-width: 300px;
      }
    }

    .permission-info  {
      margin-bottom: 20px;
    }

    .permission-info-item {
      display: flex;
      margin-bottom: 12px;
      &:last-of-type {
        margin-bottom: 0px;
      }
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        font-size: 12px;
        line-height: 14px;
        color: #004f8b;
        opacity: 0.99;
      }
      &__link {
        width: 100%;
      }
    }
  }

  .permission-right {
    background: #b2cadc14;
    border-radius: 3px;
    width: 100%;
    padding: 40px;
    max-width: 700px;

    .permission-right-form {
      margin-bottom: 60px;
    }
    .permission-right-input {
      margin-bottom: 28px;
    }
  }
    .add-permission-modal-button {
      display: flex;
      justify-content: flex-end;
      ${MainButtonContainer} {
        width: 190px;
        margin-top: 16px;
      }
    }

    &__button {
      width: 142px;
      ${MainButtonContainer} {
        height: 40px;
      }
    }

  .left-align{
      flex-direction: row-reverse;
      display: flex;
  }
  .permission-columns-container {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;  
  }

  .permission-column {
    display:flex;
    width:100%;
  }

  .first-row{
    width: 60% !important;
    margin-right: 80px;
  }

  .permission-row {
    width: 100%;
    margin-bottom: 16px;
    padding-right: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .permission-container {
    margin-bottom: 38px;
    ${InputBoxContainer} {
      input {
        border-radius: 3px;
        padding-top: 6px;
        padding-bottom: 9px;
        top: 45%;
      }
    }
  }

  .permissions-table-container {

  .table td:nth-child(odd) {
    background-color: #f9f9f9;
  }
  table ,table .table{
    border-collapse: collapse;
    width: 100%;
    min-width: 1000px;
    text-transform: uppercase;
    font-size: 16px;
    margin-top:60px;
  th {
    text-transform: uppercase;
    padding: 18px 10px;
    min-width: 50px;

    &.pointer {
      cursor: pointer;
    }

    &.filterReady {
      cursor: pointer;
      p {
        padding-right: 30px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 0%;
          right: 0;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          background: url(${images.IconFilterTable}) no-repeat center;
          transition: 0.3s all ease-in-out;
        }
      }
    }

    &.filter {
      cursor: pointer;
      p {
        padding-right: 30px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          background: url(${images.IconArrowDownGrey}) no-repeat center;
          transition: 0.3s all ease-in-out;
        }
      }

      &.active {
        p {
          &:after {
            transform: translateY(-50%) rotate(-180deg);
          }
        }
      }
    }

    ${BodyTextContainer} {
      display: inline-block;
      line-height: 16px;
    }
  }

  td {
    color: ${colors["TX-001-100"]};
    vertical-align: middle;

    > a {
      display: inline-block;
    }
    div {
      display: inline-block;
      line-height: 18px;

      &.table-icon-container {
        display: flex;
        justify-content: flex-end;

        .table-icon-item {
          margin-right: 16px;
          cursor: pointer;
          a,
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 24px;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      p{
        text-wrap: balance;
        word-break: keep-all;
        line-height: 23px;
      }
    }
  }

  .align-right-cell {
    text-align: right;
  }
  
  .number-align-right {
    float: right;
  }

  .tag-container {
    border-radius: 3px;
    padding: 5px 12px;
  }

  .highlighted {
    font-weight: 500;
    font-size: 16px;
  }

  .responsive {
    font-size: 15px;
  }

  .responsiveHeader{
    font-size: 15px;
  }

  .dimmed-mini-title {
    font-weight: 500;
    font-size: 16px;
    color: ${colors["TX-001-30"]};
  }

  .entire-mini-title {
    font-weight: 500;
    font-size: 12px;
    color: ${colors["TX-001-50"]};
  }

  .dimmed-mini-body {
    font-weight: 300;
    font-size: 16px;
    color: ${colors["TX-001-100"]};
  }

  .dimmed-mini-list {
    ${BodyTextContainer} {
      font-weight: 300;
      font-size: 14px;
      color: ${colors["TX-001-100"]};
    }

    li {
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .keep-calm {
    color: ${colors["SC-004-100"]};
    background: ${colors["keep-calm"]};
    font-size: 16px;

    p {
      color: ${colors["SC-004-100"]};
    }
  }

  .pymes-color {
    color: ${colors["SC-006-30"]};
    background: ${colors["pymes-color"]};
    font-size: 16px;

  }

  .pets {
    color: ${colors["SC-002-100"]};
    background: ${colors["pets-color"]};
    font-size: 16px;

    p {
      color: ${colors["SC-002-100"]};
    }
  }

  .health {
    color: ${colors["PR-001-100"]};
    background: ${colors["health-color"]};
    font-size: 16px;

    p {
      color: ${colors["PR-001-100"]};
    }
  }
  
  .healthCopay {
    color: ${colors["PR-001-100"]};
    background: ${colors["health-color"]};
    font-size: 16px;

    p {
      color: ${colors["PR-001-100"]};
    }
  }
  .dental {
    color: ${colors["SU-001-10"]};
    background: ${colors["dental-color"]};
    font-size: 16px;

    p {
      color: ${colors["SU-001-10"]};
    }
  }
  .travel {
    color: ${colors["travel-blue"]};
    background: ${colors["travel-blue-background"]};
    font-size: 16px;

    p {
      color: ${colors["travel-blue"]};
    }
  }

  .deaths {
    color: ${colors["SC-005-100"]};
    background: ${colors["deaths-color"]};
    font-size: 16px;

    p {
      color: ${colors["SC-005-100"]};
    }
  }

  .complete {
    color: ${colors["SU-001-100"]};
    background: ${colors["complete-color"]};

    p {
      color: ${colors["SU-001-100"]};
    }
  }

  .incomplete {
    color: ${colors["ER-001-100"]};
    background: ${colors["incomplete-color"]};

    p {
      color: ${colors["ER-001-100"]};
    }
  }

  .hire {
    color: #ffffff;
    text-transform: uppercase;
    background: #004a8f;
    padding: 5px 12px !important;
    width: 100%;
    text-align: center;
    border-radius: 4px;
  }

  .entire-mini-body {
    font-weight: 500;
    font-size: 14px;
    color: ${colors["TX-001-100"]};
  }

  .hire {
    color: #ffffff;
    text-transform: uppercase;
    background: #004a8f;
    padding: 5px 12px !important;
    width: 100%;
    text-align: center;
    border-radius: 4px;
  }

  .table-col-3 {
    width: calc(85px * 3);
    max-width: calc(85px * 3);
    min-width: calc(85px * 3);
    overflow: hidden;
  }

  .table-col-4 {
    width: calc(85px * 4);
    max-width: calc(85px * 4);
    min-width: calc(85px * 4);
    overflow: hidden;
  }

  .main-table-row {
    transition: 0.3s all ease-in-out;
  }
  .modificado {
    font-weight: bold;
  }
  .fondo-modificado{
    background-color:${colors["SC-004-30"]} !important;
  }
  .guardado {
    color: ${colors["HV-Success"]};
    font-weight: bold;
  }
  .dicotomic {
    display: block;
  color: ${colors["SU-001-100"]};

  .checkbox-container input:not(:checked) {
    color: ${colors["TX-001-50"]};
  }

  outline: none;
  
  &.mandatory {
    .checkbox-container input:checked ~ .checkmark-container {
      background: #f7f7f7;

      .checkmark-item {
        background: ${colors["SU-001-100"]};
      }
    }
  }

  &.optional {
    .checkbox-container input:checked ~ .checkmark-container {
      background: ${colors["SU-001-10"]};

      .checkmark-item {
        background: ${colors["SU-001-100"]};
      }
    }
  }

  .checkbox-container {
    display: flex;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    div:nth-of-type(2) {
      line-height: 25px;
      font-size: 16px;
    }

    &:hover {
      input ~ .checkmark {
        background-color: rgba(0, 79, 139, 0.3);
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark-container {
        background: ${colors["FL-001-Disabled"]};
        justify-content: flex-end;

        .checkmark-item {
          background: ${colors["SU-001-100"]};
        }

        img {
          max-width: 14px;
        }
      }
    }
  }

  .checkbox-error {
    color: red;
    display: block;
  }

  .checkmark-container {
    min-width: 45px;
    background: #e0e0e0;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 14px;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;

    img {
      max-width: 18px;
    }
  }

  .checkmark-item {
    background: ${colors["TX-001-30"]};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    width: 20px;
    height: 20px;    
  }


  }
  .active .checkbox-container .checkmark-container img{
    background: ${colors["SU-001-100"]};
  }

  .active .checkbox-container .checkmark-container{
    padding-left:24px;
  }
  .table {
    border: 0 !important;

  }
  table {
    width: 100%;
    }
  
  table, td {
    border-collapse: collapse;
  }
  
  thead {
    display: table; /* to take the same width as tr */
    width: calc(100% - 17px); /* - 17px because of the scrollbar width */
  }
  
  tbody {
    display: block; /* to enable vertical scrolling */
    max-height: 350px; /* e.g. */
    overflow-y: scroll; /* keeps the scrollbar even if it doesn't need it; display purpose */
  }
  
  tr {
    display: table; /* display purpose; th's border */
    width: 100%;
  }
}
}

  .hr {
    background-color: #ababab;
    height: 2px;
    width: 100%;
  }
`;