import styled from 'styled-components';
import { mediaqueries } from '../../assets';

export const MainNavItemContainer = styled.div`
    color: inherit;
    font-family: 'museo sans';
    margin-bottom: 55px;

    &.open {
        .nav-item-content  {
            max-height: 300px;
            z-index: 9;
            position: initial;
            visibility: visible;
            opacity: 1;
            padding-top: 12px;
        }
    }

    .nav-item-head {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        transition: .3s all ease-in-out;
        opacity: .5;

        &:hover,
        &.active {
            opacity: 1;
        }

        &.open {
            opacity: 1;
        }

        &__icon {
            margin-right: 14px;
            min-width: 24px;
            img {
                max-width: 20px;
            }
        }

        &__text {
            text-transform: uppercase;
        }

        &__arrow {
            position: absolute;
            top: 50%;
            right: 0;
            min-width: 12px;
            transform: translateY(-50%);
            img {
                transition: .3s all ease-in-out;
                transform: rotate(-180deg);
                max-width: 20px;
            }
        }

    }


    .nav-item-content {
        transition: .3s all ease-in-out;
        max-height: 0;
        padding: 0;
        z-index: -1;
        position: relative;
        visibility: hidden;
        opacity: 0;

        li {
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }

            &.active {
                .nav-dropdown-item {
                    &:before {
                        opacity: 0.5;
                    }
                }
            }

        }

        .nav-dropdown-item {
            font-size: 14px;
            a {
                position: relative;
                padding-left: 39px;
                opacity: 0.5;
                
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 6px;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #fff;
                    opacity: 0;
                    transition: .3s all ease-in-out;
                }

                &:hover {
                    opacity: 1;
                    &:before {
                        opacity: 1;
                    }
                }

                &.active {
                    opacity: 1;
                    &:before {
                        opacity: 1;
                    }
                }
            }
            
        }

    }

    ${mediaqueries["max-height-780"]}{
        margin-bottom: 36px;
        &:last-child {
            margin-bottom: 0;
        }

    }

    ${mediaqueries["max-height-600"]}{
        margin-bottom: 22px;

    }

`