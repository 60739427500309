import React, { Component } from "react";

import { GipInsuredContainer } from "./gip-insured-style";

interface GipInsuredProps {
  age: number;
  birthDate: string;
  checked: boolean;
  id: number;
  index: number;
  onClick: Function;
}


class GipInsuredItem extends Component<GipInsuredProps> {




  render() {

    const {
      age,
      birthDate,
      id,
      onClick,
    } = this.props


    return (
      <GipInsuredContainer>
        <div className="tab">
            <label className="tab-label">
              <span>{`Asegurado ${id}`}</span>
              <span>{birthDate}</span>
              <span>{age} años</span>
              <span className="delete" onClick={() => onClick()}></span>
            </label>
        </div>

      </GipInsuredContainer>
    )

  }
}

export default GipInsuredItem;



