import React from "react";
import NotificationModalLayout from "./notification-modal-layout";

export default class NotificationModal extends React.Component<any, {}> {
  render() {
    const { className, active, close, onClickAccept } = this.props;

    return <NotificationModalLayout className={className} active={active} onClickAccept={onClickAccept} close={close} />;
  }
}
