import React from "react";
import { Body03TextContainer } from "./body-03-text-styles";

interface Body03TextProps {
    children: any;
    className?: string;
    color?: string;
}

const Body03Text = ({children = '', className='', color=''}:Body03TextProps) => {
        return (
        <Body03TextContainer color={color} className={className}>
            {children}
        </Body03TextContainer>
    )
} 
export default Body03Text;