import {
    GestDocCreateFileRequestDto, GestDocFileInfoResponseDto,
    GestDocSearchFileRequestDto, Metadata, ProductType, NotaInformativaMediadorData, DocumentType, YesNoEnum
} from "../models/gest-doc-models"
import { AREA_NAME, ORIGIN_NAME, PERSON_DOCUMENT_TYPE } from "../constants/gest-doc";
import GestDocServices from "./Gest-Doc.Services";
import moment from "moment";
import { IUser } from "../models/user-model";
import { InsuredDataDto, TakerDataDto } from "../containers/hirings/new-hiring/new-hiring.dto";
import { Taker } from "../containers/hirings/new-hiring/health/health- guarantee/health-guarantee-dto/health-guarantee-risks-dto";
import { AREA_NAMES, PRODUCT_TYPE, VIAJES } from "../constants/policyType";

export class GestDoc {
    static getNotaInformativaLifeMediadorLocalStorage = (): NotaInformativaMediadorData => localStorage["currentNotaInformativaLife"] ? JSON.parse(localStorage["currentNotaInformativaLife"]) : undefined;
    static getNotaInformativaNotLifeMediadorLocalStorage = (): NotaInformativaMediadorData => localStorage["currentNotaInformativaNotLife"] ? JSON.parse(localStorage["currentNotaInformativaNotLife"]) : undefined;

    static setNotaInformativaLifeMediadorLocalStorage = (nota: NotaInformativaMediadorData): void => localStorage.setItem("currentNotaInformativaLife", JSON.stringify(nota));
    static setNotaInformativaNotLifeMediadorLocalStorage = (nota: NotaInformativaMediadorData): void => localStorage.setItem("currentNotaInformativaNotLife", JSON.stringify(nota));

    static getNotaInformativaMediadorNewName = (mediatorCode: string, isLife: boolean, fileExtension: string): string =>
        `NOTA_INFORMATIVA_MEDIADOR_${mediatorCode}_${isLife ? 'Asisa Vida' : 'Asisa'}.${fileExtension}`

    static getGIPProductNoteReference(productType: ProductType): string {
        switch (productType) {
            case ProductType.momento:
                return 'AIG11036';
            case (ProductType.proxima):
            case (ProductType.proximaPlus):
                return 'AIG10236';
            case (ProductType.integral100k):
            case (ProductType.integral180k):
            case (ProductType.integral240k):
                return 'AIG10231';
            default: return '';
        }
    }


    static getArea(productType: ProductType): string {
        switch (ProductType[productType]) {
            case PRODUCT_TYPE.TRANQUILITY: return AREA_NAMES.LIFE;
            case PRODUCT_TYPE.DEATH: return AREA_NAMES.DEATH;
            case PRODUCT_TYPE.PET: return AREA_NAMES.PET;
            case PRODUCT_TYPE.HEALTH:
            case PRODUCT_TYPE.HEALTH_COPAY:
            case PRODUCT_TYPE.HEALTH_OCASO:
            case PRODUCT_TYPE.HEALTH_COPAY_OCASO:
            case PRODUCT_TYPE.HEALTH_COPAY_PLUS:
            case PRODUCT_TYPE.HEALTH_MUTUALISTAS:
            case PRODUCT_TYPE.PROXIMA:
            case PRODUCT_TYPE.PROXIMA_PLUS:
            case PRODUCT_TYPE.MOMENTO:
            case PRODUCT_TYPE.INTEGRAL_100K:
            case PRODUCT_TYPE.INTEGRAL_180K:
            case PRODUCT_TYPE.INTEGRAL_240K:
                return AREA_NAMES.HEALTH;
            case PRODUCT_TYPE.DENTAL:
                return AREA_NAMES.DENTAL
            default: return AREA_NAMES.LIFE;
        }
    }    

    static getSepaInsuredSearch(
        insureds: InsuredDataDto[]
    ): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "SITE": "clientes",
                "ins2:documentType": DocumentType[15],
                ...this.getInsuredsDataSearch(insureds)
            }
        };
    }


    static getSepaPolicySearch(
        requestId: string
    ): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "SITE": "clientes",
                "ins2:documentType": DocumentType[15],
                "ins2:policyRequestNumber": requestId
            }
        };
    }

    static getquestionnaireDocumentsRequest(
        isLife: boolean,
        mediator: IUser,
        taker: Taker,
        insureds: InsuredDataDto[],
        requestId: string,
        collectiveCode: string,
        collective: string,
        product: string,
        mediatorCif: string
    ): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "ins2:documentType": DocumentType[7],
                "ins2:processPhase": "Solicitud",
                "ins2:processEntity": "Asegurado",
                "ins2:companyCenter": isLife ? 'Asisa Vida' : 'Asisa',
                "ins2:companyCenterCif": isLife ? 'A087425070' : 'A08169294',
                "ins2:companyCenterName": isLife ? 'ASISA VIDA SEGUROS S.A.U.' : 'ASISA, ASISTENCIA SANITARIA INTERPROVINCIAL DE SEGUROS, S.A.',
                "ins2:companyCenterCode": isLife ? '0001' : '0003',
                "ins2:mediatorCode": mediator.code,
                "ins2:mediatorCif": mediatorCif,
                "ins2:mediatorName": mediator.name,
                "ins2:takerType": taker.physicalPerson ? 'Física' : 'Jurídica',
                "ins2:takerNif": taker.docNumber,
                "ins2:takerName": taker.name,
                "ins2:takerSurname1": taker.lastname1,
                "ins2:takerSurname2": taker.lastname2,
                "ins2:policyRequestNumber": requestId,
                "ins2:policyOrderId": requestId + '01',
                ...this.getInsuredsData(insureds),
                "ins2:collectiveCode": collectiveCode,
                "ins2:collectiveName": collective,
                "ins2:productId": product,
                "ins2:productName": collective,
                "ins2:scanned": moment().toDate()
            }
        }
    }

    static setNotaInformativaMediadorRequest(mediatorCode: string, mediator: any, isLife: boolean): GestDocSearchFileRequestDto {
        const metadata: GestDocSearchFileRequestDto = {
            fileInfo: {
                "cm:name": `NOTA_INFORMATIVA_MEDIADOR_${mediatorCode}_${isLife ? 'Asisa Vida' : 'Asisa'}`,
                "commercial:documentType": "NID",
                "commercial:documentCode": isLife ? "AFR01V0035" : "AFR01S0010",
                "nodeType": "commercial:document",
                "commercial:centerCompany": isLife ? 'Asisa Vida' : 'Asisa',
                "commercial:centerCompanyCif": isLife ? 'A87425070' : 'A08169294',
                "commercial:centerName": isLife ? "ASISA VIDA SEGUROS S.A.U." : "ASISA, ASISTENCIA SANITARIA INTERPROVINCIAL DE SEGUROS, S.A.",
                "commercial:centerCode": isLife ? "0001" : "0003",
                "ins:mediatorCode": mediatorCode,
                "ins2:mediatorCif": mediator.mediatorCif,
                "ins2:mediatorName": mediator.mediatorName,
                "commercial:date": moment().toDate(),
            }
        };
        return metadata;
    }


    static getNotaInformativaMediadorCommercialRequest(mediatorCode: string, isLife: boolean): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "SITE": 'comercial',
                "commercial:documentType": "NID",
                "commercial:centerCompany": isLife ? 'Asisa Vida' : 'Asisa',
                "commercial:centerCompanyCif": isLife ? 'A87425070' : 'A08169294',
                "ins:mediatorCode": mediatorCode,
            }
        };
    }


    static getPolicyPrecontractualDocumentationRequest(
        isLife: boolean,
        mediator: IUser,
        taker: TakerDataDto,
        requestId: string,
        insureds: InsuredDataDto[],
        collectiveCode: string,
        collective: string,
        product: string,
        mediatorCif: string
    ): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "ins2:documentType": DocumentType[8],
                "ins2:processPhase": 'Solicitud',
                "ins2:processEntity": "Tomador",
                "ins2:companyCenter": isLife ? 'Asisa Vida' : 'Asisa',
                "ins2:companyCenterCif": isLife ? 'A087425070' : 'A08169294',
                "ins2:companyCenterName": isLife ? 'ASISA VIDA SEGUROS S.A.U.' : 'ASISA, ASISTENCIA SANITARIA INTERPROVINCIAL DE SEGUROS, S.A.',
                "ins2:companyCenterCode": isLife ? '0001' : '0003',
                "ins2:mediatorCode": mediator.code,
                "ins2:mediatorCif": mediatorCif,
                "ins2:mediatorName": mediator.name,
                "ins2:takerType": 'Física',
                "ins2:takerNif": taker.docNumber,
                "ins2:takerName": taker.name,
                "ins2:takerSurname1": taker.lastname1,
                "ins2:takerSurname2": taker.lastname2,
                "ins2:policyRequestNumber": requestId,
                "ins2:policyOrderId": requestId + '01',
                ...this.getInsuredsData(insureds),
                "ins2:collectiveCode": collectiveCode,
                "ins2:collectiveName": collective,
                "ins2:productId": product,
                "ins2:productName": collective,
                "ins2:scanned": moment().toDate()
            }
        };
    }

    static searchPolicyDocumentSearchRequest(
        isLife: boolean,
        mediator: IUser,
        taker: TakerDataDto,
        requestId: string,
        insureds: InsuredDataDto[],
        collectiveCode: string,
        collective: string,
        product: string,
        mediatorCif: string
    ): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "SITE": "clientes",
                "ins2:documentType": DocumentType[28],
                "ins2:processPhase": 'Solicitud',
                "ins2:processEntity": "Asegurado",
                "ins2:companyCenter": isLife ? 'Asisa Vida' : 'Asisa',
                "ins2:companyCenterCif": isLife ? 'A087425070' : 'A08169294',
                "ins2:companyCenterName": isLife ? 'ASISA VIDA SEGUROS S.A.U.' : 'ASISA, ASISTENCIA SANITARIA INTERPROVINCIAL DE SEGUROS, S.A.',
                "ins2:companyCenterCode": isLife ? '0001' : '0003',
                "ins2:mediatorCode": mediator.code,
                "ins2:mediatorCif": mediatorCif,
                "ins2:mediatorName": mediator.name,
                "ins2:takerType": 'Física',
                "ins2:takerNif": taker.docNumber,
                "ins2:takerName": taker.name,
                "ins2:takerSurname1": taker.lastname1,
                "ins2:takerSurname2": taker.lastname2,
                "ins2:policyRequestNumber": requestId,
                "ins2:policyOrderId": requestId + '01',
                ...this.getInsuredsData(insureds),
                "ins2:collectiveCode": collectiveCode,
                "ins2:collectiveName": collective,
                "ins2:productId": product,
                "ins2:productName": collective
            }
        };
    }

    static getPolicyDocumentSearchRequest(
        isLife: boolean,
        mediator: IUser,
        taker: TakerDataDto,
        requestId: string,
        insureds: InsuredDataDto[],
        collectiveCode: string,
        collective: string,
        product: string,
        mediatorCif: string
    ): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "ins2:documentType": DocumentType[28],
                "ins2:processPhase": 'Solicitud',
                "ins2:processEntity": "Asegurado",
                "ins2:companyCenter": isLife ? 'Asisa Vida' : 'Asisa',
                "ins2:companyCenterCif": isLife ? 'A087425070' : 'A08169294',
                "ins2:companyCenterName": isLife ? 'ASISA VIDA SEGUROS S.A.U.' : 'ASISA, ASISTENCIA SANITARIA INTERPROVINCIAL DE SEGUROS, S.A.',
                "ins2:companyCenterCode": isLife ? '0001' : '0003',
                "ins2:mediatorCode": mediator.code,
                "ins2:mediatorCif": mediatorCif,
                "ins2:mediatorName": mediator.name,
                "ins2:takerType": 'Física',
                "ins2:takerNif": taker.docNumber,
                "ins2:takerName": taker.name,
                "ins2:takerSurname1": taker.lastname1,
                "ins2:takerSurname2": taker.lastname2,
                "ins2:policyRequestNumber": requestId,
                "ins2:policyOrderId": requestId + '01',
                ...this.getInsuredsData(insureds),
                "ins2:collectiveCode": collectiveCode,
                "ins2:collectiveName": collective,
                "ins2:productId": product,
                "ins2:productName": collective,
                "ins2:scanned": moment().toDate()
            }
        };
    }

    static searchPolicyDocumentSearchProposal(
        isLife: boolean,
        mediator: IUser,
        taker: TakerDataDto,
        requestId: string,
        insureds: InsuredDataDto[],
        collectiveCode: string,
        collective: string,
        product: string,
        mediatorCif: string
    ): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "SITE": "clientes",
                "ins2:documentType": DocumentType[6],
                "ins2:processPhase": 'Solicitud',
                "ins2:processEntity": "Tomador",
                "ins2:companyCenter": isLife ? 'Asisa Vida' : 'Asisa',
                "ins2:companyCenterCif": isLife ? 'A087425070' : 'A08169294',
                "ins2:companyCenterName": isLife ? 'ASISA VIDA SEGUROS S.A.U.' : 'ASISA, ASISTENCIA SANITARIA INTERPROVINCIAL DE SEGUROS, S.A.',
                "ins2:companyCenterCode": isLife ? '0001' : '0003',
                "ins2:mediatorCode": mediator.code,
                "ins2:mediatorCif": mediatorCif,
                "ins2:mediatorName": mediator.name,
                "ins2:takerType": 'Física',
                "ins2:takerNif": taker.docNumber,
                "ins2:takerName": taker.name,
                "ins2:takerSurname1": taker.lastname1,
                "ins2:takerSurname2": taker.lastname2,
                "ins2:policyRequestNumber": requestId,
                "ins2:policyOrderId": requestId + '01',
                ...this.getInsuredsData(insureds),
                "ins2:collectiveCode": collectiveCode,
                "ins2:collectiveName": collective,
                "ins2:productId": product,
                "ins2:productName": collective
            }
        };
    }

    static getPolicyDocumentSearchProposal(
        isLife: boolean,
        mediator: IUser,
        taker: TakerDataDto,
        requestId: string,
        insureds: InsuredDataDto[],
        collectiveCode: string,
        collective: string,
        product: string,
        mediatorCif: string
    ): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "ins2:documentType": DocumentType[6],
                "ins2:processPhase": 'Solicitud',
                "ins2:processEntity": "Tomador",
                "ins2:companyCenter": isLife ? 'Asisa Vida' : 'Asisa',
                "ins2:companyCenterCif": isLife ? 'A087425070' : 'A08169294',
                "ins2:companyCenterName": isLife ? 'ASISA VIDA SEGUROS S.A.U.' : 'ASISA, ASISTENCIA SANITARIA INTERPROVINCIAL DE SEGUROS, S.A.',
                "ins2:companyCenterCode": isLife ? '0001' : '0003',
                "ins2:mediatorCode": mediator.code,
                "ins2:mediatorCif": mediatorCif,
                "ins2:mediatorName": mediator.name,
                "ins2:takerType": 'Física',
                "ins2:takerNif": taker.docNumber,
                "ins2:takerName": taker.name,
                "ins2:takerSurname1": taker.lastname1,
                "ins2:takerSurname2": taker.lastname2,
                "ins2:policyRequestNumber": requestId,
                "ins2:policyOrderId": requestId + '01',
                ...this.getInsuredsData(insureds),
                "ins2:collectiveCode": collectiveCode,
                "ins2:collectiveName": collective,
                "ins2:productId": product,
                "ins2:productName": collective,
                "ins2:scanned": moment().toDate()
            }
        };
    }

    static getDocumentByDocumentType(docType: string, taker?: Taker, insureds?: InsuredDataDto[]): GestDocSearchFileRequestDto {
        return taker ?
            {
                fileInfo: {
                    "SITE": "clientes",
                    "ins2:documentType": docType,
                    "ins2:takerNif": taker.docNumber,
                }
            }
            :
            {
                fileInfo: {
                    "SITE": "clientes",
                    "ins2:documentType": docType,
                    ...this.getInsuredsDataSearch(insureds)
                }
            };
    }
    static getPeopleDocumentsRequest(taker?: Taker, insureds?: InsuredDataDto[]): GestDocSearchFileRequestDto {
        return taker ?
            {
                fileInfo: {
                    "SITE": "clientes",
                    "ins2:documentType": DocumentType[2],
                    "ins2:takerNif": taker.docNumber,
                }
            }
            :
            {
                fileInfo: {
                    "SITE": "clientes",
                    "ins2:documentType": DocumentType[2],
                    ...this.getInsuredsDataSearch(insureds)
                }
            };
    }
    static getPeopleBookFamilyRequest(taker?: Taker, insureds?: InsuredDataDto[]): GestDocSearchFileRequestDto {
        return taker ?
            {
                fileInfo: {
                    "SITE": "clientes",
                    "ins2:documentType": DocumentType[29],
                    "ins2:takerNif": taker.docNumber,
                }
            }
            :
            {
                fileInfo: {
                    "SITE": "clientes",
                    "ins2:documentType": DocumentType[29],
                    ...this.getInsuredsDataSearch(insureds)
                }
            };
    }
    static getProyectoSeguro(COTIZACION: any): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "SITE": 'clientes',
                "ins2:documentType": DocumentType[6],
                "ins2:policyRequestNumber": COTIZACION
            }
        }
    }
    static getSolicitudAlta(requestId: any): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "SITE": 'clientes',
                "ins2:documentType": DocumentType[28],
                "ins2:policyRequestNumber": requestId
            }
        };
    }
    static getLastReceiptDocumentsRequest(taker: Taker): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "SITE": "clientes",
                "ins2:documentType": DocumentType[3],
                "ins2:takerNif": taker.docNumber,
            }
        }
    }

    static getFamilyBookDocumentRequest(taker: Taker): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "SITE": "clientes",
                "ins2:documentType": DocumentType[29],
                "ins2:takerNif": taker.docNumber,
            }
        }
    }

    static getquestionnaireDocumentsSearch(
        documentNumber: string,
        requestNumber: string
    ): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "SITE": "clientes",
                "ins2:policyRequestNumber": requestNumber,
                "ins2:insured1Nif": documentNumber,
                "ins2:documentType": "Cuestionario de salud"
            }
        }
    }

    static getGipQuestionnaireDocumentsSearch(
        insureds: InsuredDataDto[]
    ): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "SITE": "clientes",
                "ins2:documentType": DocumentType[7],
                ...this.getInsuredsDataSearch(insureds),
            }
        }
    }

    static getFirmaSolicitudSeguroRequest(
        isSigned: boolean,
        mediator: IUser,
        taker: TakerDataDto,
        requestId: string,
        insureds: InsuredDataDto[],
        collectiveCode: string,
        collective: string,
        product: string,
        mediatorCif: string
    ): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "nodeType": "ins2:client",
                "ins2:documentType": DocumentType[8],
                "ins2:processPhase": 'Solicitud',
                "ins2:processEntity": "Tomador",
                "ins2:companyCenter": 'Asisa',
                "ins2:companyCenterCif": 'A08169294',
                "ins2:companyCenterName": 'ASISA, ASISTENCIA SANITARIA INTERPROVINCIAL DE SEGUROS, S.A.',
                "ins2:companyCenterCode": '0003',
                "ins2:mediatorCode": mediator.code,
                "ins2:mediatorCif": mediatorCif,
                "ins2:mediatorName": mediator.name,
                "ins2:takerType": 'Física',
                "ins2:takerNif": taker.docNumber,
                "ins2:takerName": taker.name,
                "ins2:takerSurname1": taker.lastname1,
                "ins2:takerSurname2": taker.lastname2,
                "ins2:policyRequestNumber": requestId,
                "ins2:policyOrderId": requestId + '01',
                ...this.getInsuredsData(insureds),
                "ins2:collectiveCode": collectiveCode,
                "ins2:collectiveName": collective,
                "ins2:productId": product,
                "ins2:productName": collective,
                "ins2:scanned": moment().toDate(),
                "ins2:signed": isSigned ? YesNoEnum.si : YesNoEnum.no
            }
        };
    }

    static getInsuredsData(insureds: any): Metadata {
        if (insureds && insureds.length > 0) {
            let insuredMetadata: Metadata = {};
            insureds.forEach((insured: any, index: number) => {
                insuredMetadata = {
                    ...insuredMetadata,
                    [`ins2:insured${index + 1}Name`]: insured.name ? insured.name : '',
                    [`ins2:insured${index + 1}Surname1`]: insured.lastname1 ? insured.lastname1 : '',
                    [`ins2:insured${index + 1}Surname2`]: insured.lastname2 ? insured.lastname2 : '',
                    [`ins2:insured${index + 1}Nif`]: insured.docNumber ? insured.docNumber : insured.numChip,
                }
            })
            return insuredMetadata;
        }
        return {};
    }

    static getInsuredsDataSearch(insureds: any): Metadata {
        if (insureds && insureds.length > 0) {
            let insuredMetadata: Metadata = {};
            insureds.forEach((insured: any, index: number) => {
                insuredMetadata = {
                    ...insuredMetadata,
                    [`ins2:insured${index + 1}Nif`]: insured.docNumber,
                }
            })
            return insuredMetadata;
        }
        return {};
    }

    static async searchNodeAndGetInfo(searchRequest: GestDocSearchFileRequestDto, setLoading: Function | undefined): Promise<GestDocFileInfoResponseDto | undefined> {
        var result = await GestDocServices.searchFile(setLoading, true, searchRequest);
        if (result && result.list && result.list.entries && result.list.entries.length > 0) {
            let entry = result.list.entries[0];
            if (!entry.entry.properties) {
                entry = await GestDocServices.getFileInfo(setLoading, true, entry.entry.id);
            }
            return entry;
        }
        return undefined;
    }


    static async searchNodeID(searchRequest: GestDocSearchFileRequestDto, setLoading: Function | undefined): Promise<string | undefined> {
        const result = await GestDocServices.searchFile(setLoading, true, searchRequest);
        if (result && result.list && result.list.entries && result.list.entries.length > 0) {
            let entry = result.list.entries[0];
            return entry.entry.id;
        }
        return undefined;
    }


    static isFileExtensionAllowed = (extension: string, currentExtension: string | undefined = undefined)
        : boolean => currentExtension ? extension === currentExtension : extension === "docx" || extension === "pdf";

    static async uploadNewVersionDocument(nodeId: string, file: File,
        setLoading: Function | undefined): Promise<GestDocFileInfoResponseDto | null> {

        const request: GestDocCreateFileRequestDto = {
            nodeId: nodeId,
            fileInfo: {},
            file: file,
        };

        const result = await GestDocServices.uploadFile(
            setLoading,
            true,
            request,
            true
        ).catch(() => {
            return null;
        });
        return result;
    }

    static async uploadDocument(directoryNodeId: string | undefined, file: File, searchMetadata: Metadata, setLoading: Function | undefined,
        templateInfo: GestDocFileInfoResponseDto | undefined = undefined): Promise<GestDocFileInfoResponseDto | null> {

        const request: GestDocCreateFileRequestDto = {
            nodeId: directoryNodeId ? directoryNodeId : "",
            fileInfo: searchMetadata,
            file: file,
        };
        if (templateInfo && templateInfo.entry) {
            request.fileInfo["ins:relatedTemplateNodeRef"] = templateInfo.entry.id;
            if (templateInfo.entry.properties) {
                request.fileInfo["ins:relatedTemplateReference"] = templateInfo.entry.properties["template:reference"];
                request.fileInfo["ins:relatedTemplateVersion"] = templateInfo.entry.properties["cm:versionLabel"];
            }
        }

        const result = await GestDocServices.uploadFile(
            setLoading,
            true,
            request
        ).catch(() => {
            return null;
        });
        return result;
    }



    static getRequiredDocumentsSendaHealthRequest(cotizacion: string): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "ins:policyIdSenda": cotizacion,
                "ins:originName": ORIGIN_NAME["Proporcionado-por-el-cliente"],
            }
        };
    }

    static getRequiredDocumentsSendaHealthOfPeopleRequest(ids: string[]): GestDocSearchFileRequestDto {
        return {
            fileInfo: {
                "ins:originName": ORIGIN_NAME["Proporcionado-por-el-cliente"],
                "MultiplePersonIdentityDocument": ids,
            }
        };
    }

    static getSepaRequest(documentId?: string, personIdSenda?: string): GestDocSearchFileRequestDto {
        let request: GestDocSearchFileRequestDto = {
            fileInfo: {
                "ins:personDocumentType": PERSON_DOCUMENT_TYPE["SEPA-Mandato"],
                "ins:originName": ORIGIN_NAME["Proporcionado-por-el-cliente"],
            }
        };
        if (documentId)
            request.fileInfo["ins:personIdentityDocument"] = documentId;
        if (personIdSenda)
            request.fileInfo["ins:personIdSenda"] = personIdSenda;
        return request;
    }

    static getPersonDocumentImagesRequest(documentId?: string, personIdSenda?: string): GestDocSearchFileRequestDto {
        let request: GestDocSearchFileRequestDto = {
            fileInfo: {
                "ins:personDocumentType": PERSON_DOCUMENT_TYPE["Documento-identificativo-cliente"],
                "ins:originName": ORIGIN_NAME["Proporcionado-por-el-cliente"],
            }
        };
        if (documentId)
            request.fileInfo["ins:personIdentityDocument"] = documentId;
        if (personIdSenda)
            request.fileInfo["ins:personIdSenda"] = personIdSenda;
        return request;
    }

    static getPersonHealthQuestionnaireNewName = (documentId: string, fileExtension: string): string =>
        `CuestionarioSalud_${documentId}_${moment().format("YYYYMMDDHHmmss")}.${fileExtension}`;

    static getPersonHealthQuestionnaireTemplateRequest(): GestDocSearchFileRequestDto {
        let request: GestDocSearchFileRequestDto = {
            fileInfo: {
                "ins:personDocumentType": PERSON_DOCUMENT_TYPE["Cuestionario-Salud"],
                "TYPE": "template:document",
                "ins:areaName": AREA_NAME["Salud"]
            }
        };
        return request;
    }

}
