import React, { Component } from "react";
import { images } from "../../assets";
import HeadText from "../components-text/head-text";
import NoteText from "../components-text/note-text";
import WebText from "../components-text/web-text";
import MainTable from "../main-table";
import SelectBox from "../select-box/select-box";
import { HealthInsuredContainer } from "./health-insured-style";
import { IHealthCoverage } from "../../constants/health";
import { COVERAGES_INSURED_ORDER, ICoveragesAmount } from "../../containers/simulations/new-simulation/result/interfaces/result-interface";
import moment from "moment";
import { formatCapitalText, isOutOfCoverageAgeRange } from "../../utils/coverages";
interface HealthInsuredProps {
  age: number;
  birthDate: string;
  checked: boolean;
  coverages: any;
  id: number;
  index: number;
  onChange: Function;
  onClick: Function;
  result?: boolean;
  onCheckInsured?: any;
  hiring?: boolean;
  insured?: any;
  handleCapitalChange?: Function;
  isPrices?: boolean;
  healthCoverages?: any;
  coverageAmount?: any;
  profession?: { code: string, value: string };
  emisionResult?: boolean;
  gender?: string;
}

const beneficiariesColumns = [
  {
    text: "BENEFICIARIOS DESIGNADOS",
    filter: false,
    ascendent: true,
    key: "beneficiaries-header",
  },
  {
    text: "DOCUMENTO",
    filter: false,
    ascendent: true,
    key: "beneficiaries-header",
  },
  {
    text: "PORCENTAJE",
    filter: false,
    ascendent: false,
    key: "beneficiaries-header",
  }
]

const insuredTableColumns = [
  {
    text: "",
    filter: false,
    ascendent: true,
    key: "entire-mini-title",
  },
  {
    text: "Cobertura",
    filter: false,
    ascendent: true,
    key: "dimmed-mini-title",
  },
  {
    text: "Capital",
    filter: false,
    ascendent: true,
    key: "dimmed-mini-title",
  },
  {
    text: "Prima neta",
    filter: false,
    ascendent: true,
    key: "dimmed-mini-title",
  },
  {
    text: "Prima total",
    filter: false,
    ascendent: true,
    key: "dimmed-mini-title",
  },
  {
    text: "Impuesto",
    filter: false,
    ascendent: false,
    key: "dimmed-mini-title",
  },
];
interface HealthInsuredState {
  checked: boolean;
}

class HealthInsuredItem extends Component<HealthInsuredProps, HealthInsuredState> {

  constructor(props: HealthInsuredProps) {
    super(props)
    this.state = { checked: this.props.checked }
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.checked !== undefined) {
      this.setState({ checked: nextProps.checked });
    }
  }

  handleOnCheck(): void {

    this.props.onCheckInsured(this.props.index);
    this.setState({ checked: !this.state.checked });

  }

  buildBeneficiariesTableRows(beneficiaries: any) {

    const beneficiariesRows = beneficiaries.map((beneficiary: any) => ({
      beneficiaries: {
        text: `${beneficiary.name.value} ${beneficiary.lastName1.value} ${beneficiary.lastName2.value}`,
        key: 'beneficiaries',
        idLink: 'beneficiaries'
      },
      document: {
        text: beneficiary.docNumber.value,
        key: 'beneficiaries',
        idLink: 'document'
      },
      percentage: {
        text: beneficiary.percentage.value + "%",
        key: 'beneficiaries percentage',
        idLink: 'percentage'
      }
    }));

    return beneficiariesRows;
  }

  getInsuredTableRows = (coverageAmount: any, coverages: any) => {
    const { emisionResult, healthCoverages } = this.props;
    let order: any = []


    let filter = healthCoverages.sort((a: any, b: any) => {
      if (a.required) return (b.required && a.code > b.code) ? 1 : -1;
      else if (b.required) return 1
      else if (a.code < b.code) return -1;
      else return 1;

    })
    filter.map((x: any) => {
      if (order.indexOf(x.code) < 0)
        order.push(x.code)
    })
    let coveragesFilteredOrdered = order.map((order: any) => {
      return coverageAmount.find((x: any) =>
        x.CODE === order)
    })
    coveragesFilteredOrdered = coveragesFilteredOrdered.filter(function (x: any) {
      if (x != undefined) {
        if (x.CAPITAL && x.CAPITAL.toUpperCase() === "INCLUIDO") {
          x.CAPITAL = "1"
        } else {
          let res = coverages.find((y: any) => x.CODE === y.code)
          if (res && res.capital) {
            x.CAPITAL = res.capital.toString()
          }
          if (res && res.capitalValue) {
            x.CAPITAL = res.capitalValue
          }
        }
        return x;
      }
    });
    const rows = coveragesFilteredOrdered.map((coverage: ICoveragesAmount) => ({
      item: {
        text: 1,
        key: 'coverage item',
        idLink: 'item',
        order: COVERAGES_INSURED_ORDER[coverage.CODE] || 99
      },
      description: {
        text: coverage.DESCRIPTION,
        key: 'coverage description',
        idLink: 'description'
      },
      capital: {
        text: emisionResult ?
          coverages.find((cov: any) => coverage.CODE === cov.code).required ?
            'INCLUIDO' :
            coverage.CAPITAL
          :
          (coverage.CAPITAL === "1") ? 'INCLUIDO' :
            `${coverage.CAPITAL} €`,
        key: '',
        idLink: 'capital'
      },
      netPremium: {
        text: coverage.PRIMANETA,
        key: 'number-align-right',
        idLink: 'netPremium'
      },
      totalPremium: {
        text: coverage.PRIMATOTAL,
        key: 'number-align-right',
        idLink: 'totalPremium'
      },
      taxes: {
        text: coverage.TOTAL_IMPUESTOS,
        key: 'number-align-right',
        idLink: 'taxes'
      },
      required: coverages.find((cov: any) => cov.code === coverage.CODE) && coverages.find((cov: any) => cov.code === coverage.CODE).required,
    }))

    rows.map((ele: any, index: number) => ele.item.text = index + 1)

    return rows;
  }

  handleCapitalChange(value: String, coverageCode: string) {
    this.props.handleCapitalChange && this.props.handleCapitalChange(value, coverageCode);
  }



  render() {

    const {
      age,
      birthDate,
      coverages,
      coverageAmount,
      hiring = false,
      id,
      index,
      insured,
      gender,
      isPrices = false,
      onChange,
      onClick,
      result,
      profession,
    } = this.props

    return (
      <HealthInsuredContainer>
        <div className="tab">
          {
            (result === undefined || result === false) ? (<span className="delete" onClick={() => onClick()}></span>) : ''
          }
          <input
            type="checkbox"
            checked={this.state.checked}
            onChange={() => this.handleOnCheck()} />
          {hiring ?

            <label className="tab-label col-100">
              <div className="result-hiring-special-first-item col-30">
                <HeadText>
                  <p>
                    {insured.name + ' ' + insured.lastname1 + ' ' + insured.lastname2}
                  </p>
                </HeadText>
              </div>
              <div className="result-hiring-special-item col-70">
                <div className="result-hiring-special-row">
                  <div className="result-hiring-special-subitem col-40">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>DOMICILIO</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {insured.address ?
                            `${insured.address.address.value} ${insured.address.portal ? ', ' + insured.address.portal : ''} ${insured.address.floor ? ', ' + insured.address.floor : ''} ${insured.address.door}`
                            : ''}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>LOCALIDAD</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {insured.address ? insured.address.town.value : ''}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>PROVINCIA</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {insured.address ? insured.address.province.value : ''}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>CÓDIGO POSTAL</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {insured.address ? insured.address.postalCode : ''}
                        </p>
                      </WebText>
                    </div>
                  </div>
                </div>
              </div>
            </label>
            :
            insured ?
              this.props.emisionResult ?
                <label className="tab-label col-100">
                  <div className="result-simulation-special-row">
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>DOCUMENTO</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {insured.docNumber}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-35">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>NOMBRE</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {insured.name + ' ' + insured.lastname1 + ' ' + insured.lastname2}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-25">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>FECHA NACIMIENTO</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {moment(new Date(insured.birthDate)).format('DD/MM/YYYY').toString()}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>GÉNERO</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {insured.gender === 'H' ? 'Hombre' : 'Mujer'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                  </div>
                </label>
                :
                <label className="tab-label col-100">
                  <div className="result-simulation-special-row">
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>NOMBRE</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {insured.name + ' ' + insured.lastname1 + ' ' + insured.lastname2}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-35">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>FECHA NACIMIENTO</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {moment(new Date(insured.birthDate)).format('DD/MM/YYYY').toString()}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-25">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>EDAD</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {Math.floor(moment().diff(moment(moment(new Date(insured.birthDate)).format('DD/MM/YYYY').toString(), "DD/MM/YYYY", true), "years", true))} años
                          </p>
                        </WebText>
                      </div>

                    </div>
                    {/* {(insured.gender) &&

                      <div className="result-hiring-special-subitem col-25">
                        <div className="result-simulation-special-subitem-title">
                          <NoteText>
                            <p>SEXO</p>
                          </NoteText>
                        </div>
                        <div className="result-hiring-special-subitem-content">
                          <WebText>
                            <p>
                              {(insured.gender.toUpperCase() === "H") ? "Hombre" : "Mujer"}
                            </p>
                          </WebText>
                        </div>
                      </div>
                    } */}
                  </div>
                </label>
              :
              <label className="tab-label">
                <span>{`Asegurado ${id}`}</span>
                <span>{birthDate}</span>
                <span>{age} años</span>
                {(gender) &&
                  <span style={{ width: "100px" }}>{(gender.toUpperCase() === "H") ? "Hombre" : "Mujer"}</span>
                }
              </label>
          }

          <div className="tab-content">
            {isPrices ?
              <MainTable
                className="table noZebra"
                columns={insuredTableColumns}
                rows={this.getInsuredTableRows(coverageAmount, coverages)}
                keysRow={['item', 'description', 'capital', 'netPremium', 'totalPremium', 'taxes']}
              />
              :
              <>
                <div className="colSin-50 rightPadding10">
                  {coverages.filter((cov: IHealthCoverage) => cov.required === true)
                    .map((coverage: IHealthCoverage, item: number) => (
                      <label
                        className={`checkbox-container${(coverage.required || result) ? ' noPointer' : ''}`}
                        key={item}>
                        <input
                          type="checkbox"
                          checked={coverage.required || coverage.checked}
                          onChange={(e) => onChange(index, age, coverage, e.target.checked)}
                        />
                        <div className={`checkmark-container${coverage.required ? '-readonly' : ''}`}>
                          <div className="checkmark-item">
                            <img src={(coverage.required || coverage.checked) ? images.IconTickWhite : images.IconCloseWhite} alt="" />
                          </div>
                        </div>
                        <div className="sc-iwsKbI eLRFbD">
                          <p>{coverage.description}</p>
                        </div>
                      </label>

                    ))}
                </div>

                <div className="colSin-50 rightPadding10">
                  {coverages.filter((cov: IHealthCoverage) => cov.required === false)
                    .map((coverage: IHealthCoverage, item: number) => (
                      <div key={coverage.code}>

                        <label
                          className={`checkbox-container${(coverage.required || result || isOutOfCoverageAgeRange(age, coverage.edadMin, coverage.edadMax)) ? ' noPointer' : ''}`}
                          key={item}>

                          <input
                            type="checkbox"
                            checked={hiring || (coverage.required || coverage.checked)}
                            onChange={(e) => onChange(index, age, coverage, e.target.checked)}
                          />
                          <div className={
                            `checkmark-container${coverage.required || isOutOfCoverageAgeRange(age, coverage.edadMin, coverage.edadMax) ? '-readonly' : ''}`
                          }>
                            <div className="checkmark-item">
                              <img src={
                                (hiring || (coverage.required ||
                                  coverage.checked)) ? images.IconTickWhite : images.IconCloseWhite} alt="" />
                            </div>
                          </div>
                          <div className="sc-iwsKbI eLRFbD">
                            <p>{coverage.description}</p>
                          </div>
                        </label>
                        {(coverage.capitales && (coverage.checked || (hiring && result))) &&
                          <div className="selectBox">
                            <SelectBox
                              required={!result}
                              errorCode={coverage.errorCode}
                              optionsText={formatCapitalText(coverage.capitales) || []}
                              optionKey={"value"}
                              optionValue={"label"}
                              initialValue={result ? coverage.capital ? coverage.capital.toString() : '' : coverage.capitalValue}
                              defaultValue={result ? coverage.capital ? coverage.capital.toString() : '' : coverage.capitalValue}
                              disabled={!coverage.checked || result}
                              onChange={(value: string) => this.handleCapitalChange(value, coverage.code)}
                              health
                            />
                            <label>{"Capital a asegurar"}</label>
                          </div>
                        }                   

                        {profession && coverage.capitales && (coverage.checked || (!hiring && result)) &&
                          <div className="selectBox">
                            <SelectBox
                              optionsText={[{ label: profession.value, value: profession.value }]}
                              optionKey={"label"}
                              optionValue={"value"}
                              initialValue={profession.value}
                              defaultValue={profession.value}
                              propValue={profession.value}
                              disabled={true}
                              onChange={(value: string) => ''}
                              health
                            />
                            <label>Profesión</label>
                          </div>
                        }

                      </div>
                    ))}
                </div>
                {hiring && insured.beneficiaries && insured.beneficiaries.length > 0 &&
                  <div className="colSin-70">
                    <MainTable
                      columns={beneficiariesColumns}
                      rows={this.buildBeneficiariesTableRows(insured.beneficiaries)}
                      keysRow={["beneficiaries", "document", "percentage"]}
                    />
                  </div>
                }
              </>
            }

            {this.props.emisionResult && insured.beneficiaries && insured.beneficiaries.length > 0 &&
              <div className="beneficiaries-table colSin-70">
                <MainTable
                  columns={beneficiariesColumns}
                  rows={this.buildBeneficiariesTableRows(insured.beneficiaries)}
                  keysRow={["beneficiaries", "document", "percentage"]}
                />
              </div>
            }


          </div>

        </div>

      </HealthInsuredContainer>
    )

  }
}

export default HealthInsuredItem;



