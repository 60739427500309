import styled, { css } from 'styled-components';

export const Body02TextContainer = styled.div `
    font-family: "museo sans";
    font-weight: 500;
    font-size: 16px;

    ${props => css`
        color: ${props.color}
    `}




`;