import React, { Component } from "react";
import { NotFoundContainer } from "./not-found-style";
import { Link } from "react-router-dom";
import { images } from "../../assets";
import WebText from "../components-text/web-text";
import MainButton from "../main-button";
import { withGeneral } from "../../context/mixin/with-general";

class NotFoundLayout extends Component<any, {}> {
  componentDidMount() {
    const { setIs404 } = this.props;
    setIs404(true);
  }
  componentWillUnmount() {
    const { setIs404 } = this.props;
    setIs404(false);
  }

  render() {
    const { history } = this.props;
    return (
      <NotFoundContainer>
        <div className="not-found-content-middle">
          <img src={images.NotFoundMiddle} alt="" />
        </div>
        <div className="not-found-content-top">
          <img src={images.NotFoundTop} alt="" />
        </div>
        <div className="not-found-content">
          <div className="go-back-container">
            <div className="go-back-item" onClick={() => (history.push("/"))}>
              <img src={images.IconArrowLeftGrey} />
            </div>
          </div>

          <div className="not-found-text">
            <div className="not-found-title">
              <p>404</p>
            </div>
            <WebText className="not-found-description">
              <p>
                Algo no ha ido bien, la página que está intentando acceder no existe. Si tiene algún problema, póngase
                en contacto con nosotros en el 666 666 666 o en mediadores@asisa.es
              </p>
            </WebText>
          </div>

          <div className="not-found-btn">
            <MainButton text="Volver" onClick={() => history.push("/")} />
          </div>
        </div>

        <div className="" />
      </NotFoundContainer>
    );
  }
}

export default withGeneral(NotFoundLayout);
