import { differenceInYears } from "date-fns";
import moment from "moment";
import React, { ChangeEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import HeadText from "../../../../../components/components-text/head-text";
import NoteText from "../../../../../components/components-text/note-text";
import Title03Text from "../../../../../components/components-text/title-03-text";
import WebText from "../../../../../components/components-text/web-text";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import { HealthHireMenuHelper, Steps } from "../../../../../components/health-hire-menu/health-hire-menu";
import HealthQuestionnairesTable from "../../../../../components/health-questionnaires-table/health-questionnaires-table";
import MainButton from "../../../../../components/main-button";
import MainTable from "../../../../../components/main-table";
import { ERROR_MESSAGES } from "../../../../../constants/errorMessages";
import { SIGNATURE_TYPE } from "../../../../../constants/gest-doc";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { GestDocFileInfoResponseDto, Metadata } from "../../../../../models/gest-doc-models";
import { IMediator } from "../../../../../models/mediator-model";
import { IUser } from "../../../../../models/user-model";
import GestDocServices from "../../../../../services/Gest-Doc.Services";
import { GestDoc } from "../../../../../services/GestDoc";
import HealthService from "../../../../../services/HealthService";
import { SendaHiringResponse } from "../../../../../services/HealthService.SendaBaseResponse";
import { NOTA_INFORMATIVA_PARENT_NODE_ID } from "../../../../../services/url";
import { deepGet, downloadFile, formatter } from "../../../../../utils/utility";
import { ICoveragesQuoteAmount } from "../../../../simulations/new-simulation/result/interfaces/result-interface";
import { Insured } from "../health- guarantee/health-guarantee-dto/health-guarantee-risks-dto";
import { DateFormat, ISignedFileState, PaymentMethod } from "../health-payment-methods/payment-methods-dto";
import { ImportesAseguradoGarantiaBodyResponse } from "../health-price/health-price-dto/health-prices-dto";
import { ResultDataContainer } from "./result-data-style";
import { acceptedFileMimeType } from "../../../../../constants/acceptedFileMimeType";
import LeftInfoModal from "../../../../../components/left-info-modal";
import HealthInsuredItem from "../../../../../components/health-insured-item/health-insured-item";
import { CONST_HIRING_STATUS } from "../../types";
import RequiredDocumentsServices from "../../../../../services/RequiredDocumentsServices";
import requiredDocuments from "../../../../../utils/requiredDocuments";
const TOMADOR = "T";
const ASEGURADOS = "A";
const DT_NIF = "Documento indentificativo de cliente (NIF)";
const DT_LAST_RECEIPT = "Documento recibo compañía anterior";
const DT_HEALTH_QUESTIONNAIRE = "Cuestionario de salud";
const DT_MUTUAL_CETIFICATE = "Certificado de Mutualidad";
const TYPE_NIF = "DR04";
const TYPE_LAST_RECEIPT = "DR26";
const TYPE_HEALTH_QUESTIONNAIRE = "TR01";
const TYPE_MUTUAL_CETIFICATE = "DR07";
interface ResultProps extends RouteComponentProps {
  user: IUser;
  mediator: IMediator;
  data: any;
  resumeHiring: boolean;
  setLoading: any;
  coverages?: any;
  hasInformativeNote?: boolean;
  history: any;
  is403?: boolean;
  is404?: boolean;
  isCampaignConfigration?: boolean;
  isLogged?: boolean;
  loading?: boolean;
  location: any;
  match: any;
  policy?: any;
  setHasInformativeNote?: Function;
  saveHiring: Function;
  cotizacion: string;
}

interface IQuestionnairesResult {
  documentNumber: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  name: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  birthDate: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  age: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  gender: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  state: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
}

interface IDocumentsResult {
  //TODO: Esta columna hay que quitarla hasta saber si viene de algún sitio o se quita
  /* catalog: {
    text: string,
    type: string,
    link: string,
    key: string,
  }; */
  document: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  date: {
    text: string,
    type: string,
    link: string,
    key: string,
  };
  action: {
    text: string;
    type: string;
    key: string;
    img: any;
    onClick: Function;
  }
}

interface HealthDocument {
  id: string,
  name: string,
  date: Date,
  type?: string,
  associatedTo: string
}

export interface IProtectionData {
  consentDate: string;
  dataTransfer: string;
  thirdParty: string;
  thirdPartyCalls: string;
}

interface ResultState {
  price: {
    value: string;
    paymentTime: string;
    iban: string;
    paymentType: string;
    campaigns: any[];
  },
  mediator: {
    delegation: string;
    mediatorValue: string;
  },
  startDate: string;
  taker: any;
  insureds: any[];
  serverError: string;
  questionnairesData: IQuestionnairesResult[];
  order: string;
  ascendent: boolean | undefined;
  uploadedDocuments: HealthDocument[];
  uploadedDocumentsRows: IDocumentsResult[];
  signedDocument: ISignedFileState;
  signatureNode: string;
  protectionData: IProtectionData;
  showHiredModal: boolean;
  hiredInfoModalTitle: string;
  hiredInfoModalText: string;
  isCopay: boolean;
  hiredInfoModalList: string[];
  documentTypes: any,
}
const documentsColumns = [
  //TODO: Esta columna hay que quitarla hasta saber si viene de algún sitio o se quita
  /* {
    text: "Catálogo",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  }, */
  {
    text: "Documento",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Fecha anexión",
    filter: false,
    ascendent: false,
    key: "responsiveHeader",
  },
  {
    text: "Ver",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  }
];

const questionnairesColumns = [
  {
    text: "Nº documento",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Nombre",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Fecha nacimiento",
    filter: false,
    ascendent: false,
    key: "responsiveHeader",
  },
  {
    text: "Edad",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  {
    text: "Sexo",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  },
  //TODO: DE MOMENTO NO HAY DE DONDE SACAR ESTE ESTADO
  /* {
    text: "Estado",
    filter: true,
    ascendent: true,
    key: "responsiveHeader",
  }, */
  {
    text: "Cuestionario",
    filter: false,
    ascendent: true,
    key: "responsiveHeader",
  }
];

class ResultDataLayout extends React.Component<ResultProps, ResultState> {
  private hoveredElement: any;

  constructor(props: ResultProps) {
    super(props);

    this.hoveredElement = React.createRef();

    const { policy } = props
    const isCopay = policy.product.products_isCopay
    const paymentMethod: PaymentMethod = this.getInitialPaymentMethod(isCopay, props.data.paymentMethod);

    this.state = {
      price: {
        paymentTime: props.data.priceData ? props.data.priceData.price.paymentTime.toUpperCase() : '',
        value: props.data.priceData ? props.data.priceData.price.price : '',
        iban: props.data.paymentMethod && props.data.paymentMethod.mainIban.iban ? props.data.paymentMethod.mainIban.iban : '',
        paymentType: props.data.taker.policy ? props.data.taker.policy.paymentType.toUpperCase() : '',
        campaigns: props.data.priceData ? props.data.priceData.campaigns : [],
      },
      mediator: {
        delegation: props.user.delegation ? props.user.delegation : '',
        mediatorValue: props.user.name ? (props.user.name + ' ' +
          (props.user.lastname1 ? props.user.lastname1 : '') + ' ' +
          (props.user.lastname2 ? props.user.lastname2 : '')) : '',
      },
      startDate: props.data.taker.policy ? props.data.taker.policy.startDate : '',
      taker: props.data.taker ? props.data.taker : '',
      insureds: props.data.insureds ? props.data.insureds : [],
      serverError: '',
      questionnairesData: this.getQuestionnairesData(props.data.questionnaires),
      order: '',
      ascendent: undefined,
      uploadedDocuments: [],
      uploadedDocumentsRows: [],
      signedDocument: this.getDefaultISignedFile(paymentMethod.isPhysicalSignature, paymentMethod.isPhysicalSignature),
      signatureNode: this.props.data.physicalSignatureNode ? this.props.data.physicalSignatureNode : '',
      protectionData: props.data.protectionData,
      showHiredModal: false,
      hiredInfoModalTitle: "",
      hiredInfoModalText: "",
      isCopay: isCopay,
      hiredInfoModalList: [],
      documentTypes: null
    };
  }

  getInitialPaymentMethod = (isCopay: boolean, initialPaymentMethod?: PaymentMethod): PaymentMethod => {
    let paymentMethod = initialPaymentMethod;
    if (!paymentMethod) {
      paymentMethod = {
        mainIban: {
          iban: "",
          sameAsMain: true,
        },
        areInsuredsCopayGrouped: isCopay ? true : undefined,
        copayIban: isCopay ? {
          iban: "",
          sameAsMain: true,
          sepaSignedDate: moment().format(DateFormat),
        } : undefined,
        isPhysicalSignature: false,
      };
    }
    return paymentMethod;
  }

  getDefaultISignedFile = (isDisabled: boolean, isRequired: boolean): ISignedFileState => {
    return {
      file: undefined,
      error: "",
      disabled: isDisabled,
      isRequired: isRequired,
    }
  }

  getISignedFile = (isDisabled: boolean, isRequired: boolean, file: File): ISignedFileState => {
    return {
      file: file,
      error: "",
      disabled: isDisabled,
      isRequired: isRequired,
    }
  }

  getQuestionnairesData(questionnaires: any): IQuestionnairesResult[] {
    let questionnairesDataArray: IQuestionnairesResult[] = [];

    Object.keys(questionnaires).forEach((questionnaire: any, index: number) => {
      let name = '';
      this.props.data.insureds.forEach((insured: Insured) => {
        if (questionnaire === insured.docNumber) {
          name = `${insured.name} ${insured.lastname1} ${insured.lastname2}`
        }
      })
      let questionnaireData: any = {
        documentNumber: {
          text: questionnaires[questionnaire].insuredDocNumber,
          type: "",
          link: "",
          key: "responsive",
        },
        birthDate: {
          text: questionnaires[questionnaire].questionnaireData.questionnaireResponse['04'].value,
          type: "",
          link: "",
          key: "responsive",
        },
        age: {
          text: differenceInYears(new Date(),
            moment(questionnaires[questionnaire].questionnaireData.questionnaireResponse['04'].value, 'DD/MM/YYYY').toDate()).toString(),
          type: "",
          link: "",
          key: "responsive",
        },
        gender: {
          text: questionnaires[questionnaire].questionnaireData.questionnaireResponse['02'].value === 'H' ? 'HOMBRE' : 'MUJER',
          type: "",
          link: "",
          key: "responsive",
        },
        name: {
          text: name,
          type: "",
          link: "",
          key: "responsive",
        },
        action: {
          text: '',
          type: "",
          key: "icon-button",
          img: images.IconDownloadBlue,
          onClick: () => this.getQuestionnaireId(questionnaires[questionnaire].insuredDocNumber)
        }
        //TODO: DE MOMENTO NO HAY DE DONDE SACAR ESTE ESTADO
        /* ,
        state: {
          text: 'CUMPLIMENTADO',
          type: "",
          link: "",
          key: "responsive",
        } */
      }

      questionnairesDataArray.push(questionnaireData)
    });

    return questionnairesDataArray;
  }

  async getQuestionnaireId(docNumber: string) {
    const { setLoading, data } = this.props;
    let questionnaireDocument = await GestDocServices.searchFile(setLoading, true,
      GestDoc.getquestionnaireDocumentsSearch(docNumber, data.idcotiza)
    )

    if (questionnaireDocument.list.entries.length) {
      this.downloadDocument(questionnaireDocument.list.entries[0].entry.id)
    }
  }

  async downloadDocument(id: string) {
    const { setLoading } = this.props;
    var result = await GestDocServices.getFile(setLoading, true, id);
    if (result && result.fileData) {
      downloadFile(result.fileData, result.fileName, result.fileType, true);
    } else {
      this.setState({ serverError: 'Error al descargar el archivo' })
    }
  }

  loadHealthDocumentsFromGestDoc = async () => {
    const { setLoading, data, policy } = this.props;
    const { documentTypes, taker } = this.state;
    const docsLanguageValue = deepGet(taker, "policy.docsLanguage", "");
    let docsLanguageLabel = "";
    if (docsLanguageValue) {
      const docsLanguageLabels: { label: string, value: string }[] = deepGet(policy, "product.productFields.step1.docsLanguage.options", []) || [];
      const option = docsLanguageLabels.find((lang) => lang.value === docsLanguageValue);
      docsLanguageLabel = option ? option.label : "";
    }

    let entries: GestDocFileInfoResponseDto[] = [];
    
    for (let i = 0; i < documentTypes.length; i++) {
      //Para el tomador
      if (documentTypes[i].showFor.includes('T')) {
        const docTomador = await GestDocServices.searchFileOrderByModifiedAt(setLoading, true,
          GestDoc.getDocumentByDocumentType(documentTypes[i].documentTypeMetadata, data.taker)
        );
        if (docTomador && docTomador.list && docTomador.list.entries && docTomador.list.entries.length > 0) {
          entries = entries.concat(docTomador.list.entries[0]);
        }
      }
      if (documentTypes[i].showFor.includes('A')) {
        //Para los asegurados
        for (let a = 0; a < data.insureds.length; a++) {
          const doc = await GestDocServices.searchFileOrderByModifiedAt(setLoading, true,
            GestDoc.getDocumentByDocumentType(documentTypes[i].documentTypeMetadata, undefined, [data.insureds[a]])
          );
          if (doc && doc.list && doc.list.entries && doc.list.entries.length > 0) {
            entries = entries.concat(doc.list.entries[0]);
          }
        }
      }
    }

    //Buscamos Proyecto de Seguro
    const searchProyectoData = {
      ...GestDoc.getProyectoSeguro(data.idcotiza),
      ...(docsLanguageLabel ? { language: docsLanguageLabel } : ""),
    };
    let proyectoSeguro = await GestDocServices.searchFile(setLoading, true, searchProyectoData);
    if (proyectoSeguro.list.entries.length) {
      let arrayProyectoSeguro = []
      if (proyectoSeguro.list.entries.length > 1) {
        arrayProyectoSeguro = proyectoSeguro.list.entries.sort(function (a: any, b: any) {
          var dateA = new Date(a.entry.modifiedAt)
          var dateB = new Date(b.entry.modifiedAt)
          return dateA < dateB ? 1 : -1
        })
      } else {
        arrayProyectoSeguro = proyectoSeguro.list.entries
      }
      entries = entries.concat(arrayProyectoSeguro[0]);
    }
    //Buscamos Solicitud Alta Seguro
    const searchSolicitudData = {
      ...GestDoc.getSolicitudAlta(data.idcotiza),
      ...(docsLanguageLabel ? { language: docsLanguageLabel } : ""),
    };
    let solicitudAlta = await GestDocServices.searchFile(setLoading, true, searchSolicitudData);
    if (solicitudAlta.list.entries.length) {
      let arraySolicitudAlta = []
      if (solicitudAlta.list.entries.length > 1) {
        arraySolicitudAlta = solicitudAlta.list.entries.sort(function (a: any, b: any) {
          var dateA = new Date(a.entry.modifiedAt)
          var dateB = new Date(b.entry.modifiedAt)
          return dateA < dateB ? 1 : -1
        })
      } else {
        arraySolicitudAlta = solicitudAlta.list.entries
      }
      entries = entries.concat(arraySolicitudAlta[0]);
    }
    //Buscamos cuestionarios
    const questionnairesPromises: Promise<any>[] = [];
    let questionnaires: any[] = [];
    if (data.insureds && data.insureds.length) {
      data.insureds.forEach(async (insured: any) => {
        const searchQuestionnaireData = {
          ...GestDoc.getquestionnaireDocumentsSearch(insured.docNumber, data.idcotiza),
          ...(docsLanguageLabel ? { language: docsLanguageLabel } : ""),
        };
        questionnairesPromises.push(
          GestDocServices.searchFile(setLoading, true, searchQuestionnaireData)
        )
      })
    }


    await Promise.all(questionnairesPromises).then(
      data => questionnaires = data
    ).catch(err => console.error(err));

    //TODO: Falta buscar otro tipo de documentación

    if (questionnaires && questionnaires.length > 0) {
      questionnaires.forEach((questionnaire) => {
        if (questionnaire.list.entries.length) {
          let arrayCuestionario = []
          if (solicitudAlta.list.entries.length > 1) {
            arrayCuestionario = questionnaire.list.entries.sort(function (a: any, b: any) {
              var dateA = new Date(a.entry.modifiedAt)
              var dateB = new Date(b.entry.modifiedAt)
              return dateA < dateB ? 1 : -1
            })
          } else {
            arrayCuestionario = questionnaire.list.entries
          }
          entries = entries.concat(arrayCuestionario[0]);
        }
      })
    }

    let uploadedDocuments = entries.map((ele) => {
      return {
        id: ele.entry.id,
        name: ele.entry.name,
        date: ele.entry.createdAt,
        associatedTo: ele.entry.properties && ele.entry.properties["ins:personIdentityDocument"] ? ele.entry.properties["ins:personIdentityDocument"] : ""
      }
    });
    const unique = uploadedDocuments.filter(
      (obj, index) =>
        uploadedDocuments.findIndex((item) => item.id === obj.id) === index);
    this.setState({ uploadedDocuments: unique }, () => this.loadDocumentRows());
  }

  loadDocumentRows() {
    const { uploadedDocuments } = this.state;
    //TODO: Falta definir los datos que pertenecen a cada columna
    let rows: IDocumentsResult[] = [];
    if (uploadedDocuments.length > 0) {
      uploadedDocuments.forEach((document) => {
        let row: IDocumentsResult = {
          //TODO: Esta columna hay que quitarla hasta saber si viene de algún sitio o se quita
          /* catalog: {
            text: document.type ? document.type : '',
            type: "",
            link: "",
            key: "responsive",
          }, */
          date: {
            text: moment(document.date).format('DD/MM/YYYY').toString(),
            type: "",
            link: "",
            key: "responsive",
          },
          //TODO: El documento nos pidieron que de momento se cogiese del tipo
          document: {
            text: document.name ? document.name : '',
            type: "",
            link: "",
            key: "responsive",
          },
          action: {
            text: '',
            type: "",
            key: "icon-button",
            img: images.IconDownloadBlue,
            onClick: () => this.downloadDocument(document.id)
          }
        }
        rows.push(row)
      })
    }

    this.setState({ uploadedDocumentsRows: rows })
  }

  componentWillReceiveProps(nextProps: ResultProps) {
    if (nextProps.resumeHiring) {
      this.resumeHiring();
    }
  }

  async resumeHiring() {
    const {policy, data} = this.props
    if (this.toValidate()) {
      // Si se ha escogido la firma física la subimos a alfresco
      if (this.props.data.paymentMethod.isPhysicalSignature) {
        let { signatureNode } = this.state;
        if (signatureNode && signatureNode !== '') {
          await GestDocServices.deleteFile(this.props.setLoading, true, signatureNode)
        }

        const { signedDocument } = this.state;
        const { setLoading, data, user, mediator, cotizacion } = this.props;

        let metadata: Metadata = {};
        if(Object.keys(policy.product).length!==0){
          metadata = GestDoc.getFirmaSolicitudSeguroRequest(
            true,
            user,
            data.taker,
            cotizacion,
            data.insureds,
            policy.product.products_colectivo,
            policy.product.products_internalName,
            policy.product.products_combinacion_comercial,
            mediator.nif
          ).fileInfo;
        }
        
        if (signedDocument.file && Object.keys(metadata).length!==0) {
          const response = await GestDoc.uploadDocument(NOTA_INFORMATIVA_PARENT_NODE_ID, signedDocument.file, metadata, this.props.setLoading);
          if (response) {
            this.props.data.physicalSignatureNode = response.entry.id;
            signatureNode = response.entry.id;
            this.setState({ signatureNode })
            await this.props.saveHiring(true)
          }
        }

      }
      try {
        const response: any = await HealthService.hiring({
          setLoading: this.props.setLoading,
          data: {
            COTIZACION: this.props.cotizacion,
            ID_FIRMA_DIGITAL: '',
            FECH_EFEC_RETROACTIVA: deepGet(data, 'fechaEfectoRetroactiva') || null,
          },
        });

        if (response && response.body) {
          if (typeof response.body.PAPISOLEMISRESP.PAPIEMISRESP === 'object' && (
            response.body.PAPISOLEMISRESP.PAPIEMISRESP.ESTADO.toString() === "EMITIDA" ||
            response.body.PAPISOLEMISRESP.PAPIEMISRESP.ESTADO.toString() === "V" || 
            response.body.PAPISOLEMISRESP.PAPIEMISRESP.POLIZSEC)) {
            const idPolicy = response.body.PAPISOLEMISRESP.PAPIEMISRESP.POLIZSEC;
            this.props.data.status = CONST_HIRING_STATUS.EMITIDA;
            this.props.data.updateStatus = CONST_HIRING_STATUS.EMITIDA;
            this.props.data.policyNumber = idPolicy;
            this.props.saveHiring(true);
            this.setState({ showHiredModal: true, hiredInfoModalTitle: "La póliza ha sido emitida", hiredInfoModalText: `La póliza ${idPolicy ? (idPolicy + " ") : "" }se ha emitido correctamente.`, hiredInfoModalList: [] })
            
          } else {
            this.props.data.status = CONST_HIRING_STATUS.EN_REVISION;
            this.props.data.updateStatus = "REVISION";
            const warns: string[] = [];
            if (response.header && response.header.warnings && Array.isArray(response.header.warnings)){
              response.header.warnings.forEach((w: any) => warns.push(w.message));
            }
            this.props.data.emisionError = warns;
            this.props.saveHiring(true);
            this.setState({ showHiredModal: true, hiredInfoModalTitle: "La solicitud ha sido enviada", hiredInfoModalText: "La solicitud se ha quedado pendiente de revisión. Contacte con la delegación para más detalle.", hiredInfoModalList: warns })
          }
        }
        else throw ERROR_MESSAGES["server-error"];
      } catch (error) {
        this.props.data.status = CONST_HIRING_STATUS.PENDIENTE_ERROR;
        this.props.data.updateStatus = "PENDIENTE_ERROR";
        this.props.saveHiring(true);
        this.setState({ showHiredModal: true, hiredInfoModalTitle: "La solicitud no se ha podido tramitar", hiredInfoModalText: error as string, hiredInfoModalList: []})
      }
    }
  }
  componentDidMount = async () => {
    const { policy } = this.props
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    if (Object.keys(policy.product).length !== 0) {
      await this.loadDocumentTypes()
      this.loadHealthDocumentsFromGestDoc();
      this.loadSignDocument();
    }
  };

  async componentDidUpdate(prevProps: Readonly<ResultProps>, prevState: Readonly<ResultState>) {
    const { policy } = this.props
    if (Object.keys(prevProps.policy.product).length === 0 && Object.keys(policy.product).length !== 0) {
      const isCopay = policy.product.products_isCopay
      this.setState({isCopay});
      await this.loadDocumentTypes()
      this.loadHealthDocumentsFromGestDoc();
      this.loadSignDocument();
    }
  }
  loadDocumentTypes = async () => {
    let result = await requiredDocuments.getDocumentTypes(this.props.setLoading, this.props.policy.product.products_id);
    this.setState({ documentTypes: result.documentTypes });
  }

  async loadSignDocument() {
    const { isCopay } = this.state
    if (this.props.data.physicalSignatureNode) {
      const paymentMethod: PaymentMethod = this.getInitialPaymentMethod(isCopay, this.props.data.paymentMethod);
      let fileResponse = await GestDocServices.getFile(this.props.setLoading, true, this.props.data.physicalSignatureNode)
      const file: File = new File([(fileResponse.fileData.buffer)], fileResponse.fileName);
      this.setState({ signedDocument: this.getISignedFile(paymentMethod.isPhysicalSignature, paymentMethod.isPhysicalSignature, file) })
    }

  }

  loadInsuredData = (insureds: any) => {
    return insureds.map((insured: any, index: number) =>
    (

      <HealthInsuredItem
        age={Math.floor(moment().diff(moment(insured.birthDate, "DD/MM/YYYY", true), "years", true))}
        birthDate={insured.birthDate}
        checked={false}
        coverages={insured.coverages}
        id={insureds.length - index}
        index={index + 1}
        key={index}
        onChange={() => 'this.handleRemoveInsured(index)'}
        onClick={() => 'this.handleRemoveInsured(index)'}
        onCheckInsured={(insured: any) => 'this.handleInsuredCheck(insured)'}
        result={true}
        hiring
        insured={insured}
      />
    )
    )
  }

  async onSignedDocumentFileChange(e: ChangeEvent<HTMLInputElement>) {
    const { signedDocument } = this.state;
    const { user, data, cotizacion, mediator, setLoading, policy } = this.props;
    if (e.target.files && e.target.files.length > 0) {

      signedDocument.file = e.target.files[0];

      if (signedDocument.file) {
        
        let metadata
        if(Object.keys(policy.product).length!==0){
          metadata = GestDoc.getFirmaSolicitudSeguroRequest(
            true,
            user,
            data.taker,
            cotizacion,
            data.insureds,
            policy.product.products_colectivo,
            policy.product.products_internalName,
            policy.product.products_combinacion_comercial,
            mediator.nif
          ).fileInfo;
        }

        let response
        if(metadata){
          response = await GestDoc.uploadDocument(NOTA_INFORMATIVA_PARENT_NODE_ID, signedDocument.file, metadata, this.props.setLoading);
        }

        if (response) {
          this.props.data.physicalSignatureNode = response.entry.id;
          const signatureNode = response.entry.id;
          this.setState({ signatureNode })
          await this.props.saveHiring(true)
        }
      }

      signedDocument.error = ''
      this.setState({ signedDocument });
    }

  }

  toValidate(): boolean {
    let { signedDocument } = this.state;
    let isDataValid: boolean = true;

    if (this.props.data.paymentMethod.isPhysicalSignature && !signedDocument.file) {
      signedDocument.error = '* Ha marcado la casilla Firma Física, debe adjuntar el documento firmado'
      isDataValid = false;
    }

    if (isDataValid) {
      return true;
    }
    else {
      this.hoveredElement.current.scrollIntoView();
      this.setState({ signedDocument })
      return false
    }
  }

  getCoverages(coverages: ImportesAseguradoGarantiaBodyResponse[]) {
    const coveragesAmount = coverages.filter((cov: ImportesAseguradoGarantiaBodyResponse) => cov.PERIODO_COBRO === this.state.price.paymentType)
      .map((cov: ImportesAseguradoGarantiaBodyResponse) => ({

        CODE: cov.GARANTIA,
        key: "dimmed-mini-body",
        CAPITAL: Number(cov.PRIMATOTAL) === 1 ? 'Incluido' : formatter.format(Number(cov.PRIMATOTAL)),
        NUMERO_DE_RIESGO: Number(cov.NUMERO_DE_RIESGO),
        DESCRIPTION: this.getDescriptionCoverage(cov.GARANTIA),
        ...this.getCoveragesQuoteAmount(this.props.data.priceData.priceResponse.body.RESPUESTA, this.state.price.paymentType, cov.GARANTIA, true, Number(cov.NUMERO_DE_RIESGO))

      }));
    return coveragesAmount;
  }

  getCoveragesQuoteAmount = (
    response: any,
    payType: string,
    codeCoverage: string,
    isFormat: boolean = true,
    numRiesgo: number = 0,
  ): ICoveragesQuoteAmount => {
    const coverageQuoteAmount = response.IMPORTES_ASEGURADO_GARANTIA.filter(
      (cov: any) =>
        cov.PERIODO_COBRO === payType && cov.GARANTIA === codeCoverage
    );
    if (!coverageQuoteAmount) {
      return { PRIMANETA: "", PRIMATOTAL: "", TOTAL_IMPUESTOS: "" };
    }
    let coverage: any = {}
    if (numRiesgo > 0) {
      coverage = coverageQuoteAmount.find((cov: any) => {
        return Number(cov.NUMERO_DE_RIESGO.toString()) === numRiesgo
      });
    } else {
      coverage = coverageQuoteAmount[0]
    }
    return {
      PRIMATOTAL: isFormat
        ? formatter.format(Number(coverage.PRIMATOTAL))
        : coverage.PRIMATOTAL,
      PRIMANETA: isFormat
        ? formatter.format(Number(coverage.PRIMANETA))
        : coverage.PRIMANETA,
      TOTAL_IMPUESTOS: isFormat
        ? formatter.format(Number(coverage.TOTAL_IMPUESTOS))
        : coverage.TOTAL_IMPUESTOS,
      PERIODO_COBRO: coverage.PERIODO_COBRO,
    };
  };

  getDescriptionCoverage = (codeCoverage: string) => {
    const { coverages } = this.props.data.insureds[0];

    const coverage = coverages.filter((cov: any) => cov.code === codeCoverage)

    return coverage.length > 0 ? coverage[0].description : '';

  }
  getTotalPrice = (payType: string, IMPORTESFORPAGO: Array<any>) => {
    const totalPrice: any = IMPORTESFORPAGO.find(price => price.PERIODO_COBRO === payType)

    return (totalPrice.PRIMATOTAL)
  }

  render() {
    const { price, mediator, startDate, taker, insureds, serverError, questionnairesData, uploadedDocumentsRows, signedDocument, protectionData, showHiredModal, hiredInfoModalTitle, hiredInfoModalText, hiredInfoModalList } = this.state;
    const totalPrice = this.getTotalPrice(this.props.data.priceData.price.payType, this.props.data.priceData.priceResponse.body.RESPUESTA.IMPORTESFORPAGO)
    let { data } = this.props;
    const takerAddress =
      taker.address ?
        `${taker.address.address.value} ${taker.address.portal ? ', ' + taker.address.portal : ''} ${taker.address.floor ? ', ' + taker.address.floor : ''} ${taker.address.door}`
        : '';

    return (
      <ResultDataContainer>

        <div className="data-flow-status-title">

          <h2>DETALLE PREVIO DE LA CONTRATACIÓN</h2>

        </div>

        <div className="result-simulation-special">
          <div className="result-simulation-special-title">
            <Title03Text>
              <p>{`Nº ${this.props.cotizacion}`}</p>
            </Title03Text>
            <div>
              <p className="price">
                {formatter.format(Number(totalPrice))}
                {/* <span>{'/' + price.paymentTime}</span> */}
              </p>
            </div>
          </div>
          <div className="result-simulation-special-container">
            <div className="result-simulation-special-item">
              <HeadText>
                <p>
                  Póliza Salud
                </p>
              </HeadText>
            </div>
            <div className="result-simulation-special-item">
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>FECHA EMISIÓN</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>
                      {startDate}
                    </p>
                  </WebText>
                </div>
              </div>
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>FECHA VENCIMIENTO</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>
                      {moment(startDate, 'DD/MM/YYYY').add(1, 'year').format('DD/MM/YYYY')}
                    </p>
                  </WebText>
                </div>
              </div>
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>DELEGACIÓN</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>
                      {mediator.delegation}
                    </p>
                  </WebText>
                </div>
              </div>
              <div className="result-simulation-special-subitem">
                <div className="result-simulation-special-subitem-title">
                  <NoteText>
                    <p>MEDIADOR</p>
                  </NoteText>
                </div>
                <div className="result-simulation-special-subitem-content">
                  <WebText>
                    <p>
                      {mediator.mediatorValue}
                    </p>
                  </WebText>
                </div>
              </div>
            </div>
          </div>
        </div>

        {price.campaigns && price.campaigns.length > 0 &&
          <div className="result-simulation-item float">
            <div className="result-simulation-special-title">
              <Title03Text>
                <p>Campañas seleccionadas</p>
              </Title03Text>
            </div>
            <div className="result-simulation-item-content">
              {
                price.campaigns.map((campaign: { code: string, name: string }, index: number) => (
                  <div className="colSin- rightPadding20" key={index}>
                    <h5>{campaign.name}</h5>
                  </div>
                ))
              }
            </div>
          </div>}

        <div className="result-simulation-special">
          <div className="result-hiring-special-title">
            <Title03Text>
              <p>Tomador</p>
            </Title03Text>
          </div>
          <div className="result-hiring-special-container col-100">
            <div className="result-hiring-special-first-item col-30">
              <HeadText>
                <p>
                  {taker.name ?
                    `${taker.name} ${taker.lastname1} ${taker.lastname2}` : ''
                  }
                </p>
              </HeadText>
            </div>
            <div className="result-hiring-special-item col-70">

              <div className="result-hiring-special-row">
                <div className="result-hiring-special-subitem col-40">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Tipo documento</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {`${taker.docType} ${taker.physicalPerson ? 'Persona física' : 'Persona jurídica'}`}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Nº documento</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.docNumber}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Género</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.gender === 'H' ? 'Hombre' : 'Mujer'}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Fecha de nacimiento</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {moment(taker.birthDate).format('DD/MM/YYYY')}
                      </p>
                    </WebText>
                  </div>
                </div>
              </div>

              <div className="result-hiring-special-row">
                <div className="result-hiring-special-subitem col-40">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>DOMICILIO</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {takerAddress}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>LOCALIDAD</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.address ? taker.address.town.value : ''}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>PROVINCIA</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.address ? taker.address.province.value : ''}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>CÓDIGO POSTAL</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.address ? taker.address.postalCode : ''}
                      </p>
                    </WebText>
                  </div>
                </div>
              </div>

              <div className="result-hiring-special-row">
                <div className="result-hiring-special-subitem col-40">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Email</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.email}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Teléfono fijo</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.landlinePhone}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Teléfono móvil</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {taker.mobilePhone}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="col-20"></div>
              </div>

              <div className="result-hiring-special-row">
                <div className="result-hiring-special-subitem col-40">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>CUENTA BANCARIA</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {price.iban}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>FORMA DE PAGO</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {price.paymentTime}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-20">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>MEDIO DE PAGO</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {price.iban ? 'DOMICILIACIÓN' : 'TARJETA BANCARIA'}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="col-20"></div>
              </div>

              {taker.physicalPerson &&
                <div>
                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>FECHA CONSENTIMIENTO</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {protectionData.consentDate}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-20">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>CESIÓN DE DATOS</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {protectionData.dataTransfer === 'S' ? 'SI' : 'NO'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className="result-hiring-special-subitem col-40">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>ENVÍO DE PUBLICIDAD DE TERCEROS</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {protectionData.thirdParty === 'S' ? 'SI' : 'NO'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                    <div className=""></div>
                  </div>
                  <div className="result-hiring-special-row">
                    <div className="result-hiring-special-subitem col-50">
                      <div className="result-simulation-special-subitem-title">
                        <NoteText>
                          <p>Cesión de llamadas promocionando productos de terceros</p>
                        </NoteText>
                      </div>
                      <div className="result-hiring-special-subitem-content">
                        <WebText>
                          <p>
                            {protectionData.thirdPartyCalls === 'S' ? 'SI' : 'NO'}
                          </p>
                        </WebText>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>

        </div>

        {/* <div className="result-simulation-item-content">
          <div className="tabs">
            <div className="result-hiring-special-title">
              <Title03Text>
                <p>Asegurados</p>
              </Title03Text>
            </div>
            {
              this.loadInsuredData(insureds)
            }
          </div>
        </div> */}
        {questionnairesData.length > 0 &&
          <div className="result-simulation-special">
            <div className="result-simulation-item-content">
              <div className="result-hiring-special-title">
                <Title03Text>
                  <p>Cuestionarios de salud</p>
                </Title03Text>
              </div>
              <HealthQuestionnairesTable
                questionnaires={data.questionnaires}
                insureds={data.insureds}
                setLoading={this.props.setLoading}
              />
            </div>
          </div>
        }

        {uploadedDocumentsRows.length > 0 &&
          <div className="result-simulation-special">
            <div className="result-simulation-item-content">
              <div className="result-hiring-special-title">
                <Title03Text>
                  <p>Documentos</p>
                </Title03Text>
              </div>
              <div className="detail-clients-policies-item-content-table">
                <MainTable
                  className="table topMargin15"
                  columns={documentsColumns}
                  rows={uploadedDocumentsRows}
                  keysRow={[/* "catalog", */ "document", "date", "action"]}
                />
              </div>
            </div>
          </div>
        }

        {this.props.data.paymentMethod.isPhysicalSignature &&
          <div className="result-simulation-special">
            <div className="result-simulation-item-content">
              <div className="result-hiring-special-title">
                <Title03Text>
                  <p>Firma física</p>
                </Title03Text>
              </div>
              <div className="colSin-100 topMargin20">
                <div className="colSin-50 rightPadding20">
                  <div className="inputBox custom-file-upload">

                    <div className={`colSin-70 ${'isSignedDocumentRequired'}`}>
                      <input type="text" readOnly value={signedDocument.file ? signedDocument.file.name : ""} disabled={false} title="Documento Firmado" />
                    </div>

                    <div ref={this.hoveredElement} className="colSin-30">
                      <label className="custom-file-upload-button topMargin5">
                        <input type="file"
                          accept={acceptedFileMimeType.toString()}
                          onChange={(e) => this.onSignedDocumentFileChange(e)}
                          title="DOCUMENTO FIRMADO"
                        />
                        <MainButton
                          onClick={() => { return true }}
                          text="Buscar"
                        />
                      </label>
                    </div>

                    <label>Adjuntar documento firmado</label>
                  </div>
                  {signedDocument.error && (
                    <div className="input-box-error-file">
                      <NoteText>
                        <p>{signedDocument.error}</p>
                      </NoteText>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }

        <div className="colSin-100">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push(HealthHireMenuHelper.getPreviousStepNavigationPath(Steps.DetallePrevioContratacion))}
              />
            </div>
          </div>
        </div>
        <ErrorModalContainer className={`${serverError && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{serverError}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={() => this.setState({ serverError: '' })} />
            </div>

          </div>
        </ErrorModalContainer>
        <LeftInfoModal
          img={images.IconUserBlue}
          className={""}
          mainTitle={hiredInfoModalTitle}
          mainText={hiredInfoModalText}
          buttonText={"Aceptar"}
          noSecondaryButton
          active={showHiredModal}
          list={hiredInfoModalList}
          onClickAccept={() => this.props.history.push('/contratacion/pendiente')}
          close={() => this.props.history.push('/contratacion/pendiente')}
        />
      </ResultDataContainer >
    );
  }
}

export default withCoverages(withPolicy(withGeneral(withRouter(ResultDataLayout))));
