import React, { Component } from "react";
import { images } from "../../assets";
import { IHealthCoverage } from "../../constants/health";
import { DentalInsuredContainer } from "./dental-insured-style";
import WebText from "../components-text/web-text";
interface DentalInsuredProps {
  age: number;
  birthDate: string;
  checked: boolean;
  id: number;
  index: number;
  onChange: Function;
  onClick: Function;
  result?: boolean;
  onCheckInsured?: any;
  insured?: any;
  gender?: string;
  parentesco?: string;
  hasAnotherInsurance?: boolean;
  coverages: IHealthCoverage[];
}

interface DentalInsuredState {
  checked: boolean;
}

class DentalInsuredItem extends Component<DentalInsuredProps, DentalInsuredState> {

  constructor(props: DentalInsuredProps) {
    super(props)
    this.state = { checked: this.props.checked }
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.checked !== undefined) {
      this.setState({ checked: nextProps.checked });
    }
  }

  handleOnCheck(): void {
    this.props.onCheckInsured(this.props.index);
    this.setState({ checked: !this.state.checked });
  }

  render() {

    const {
      age,
      birthDate,
      id,
      onClick,
      result,
      parentesco,
      coverages,
      hasAnotherInsurance
    } = this.props

    return (
      <DentalInsuredContainer>
        <div className="tab">
        <input
                type="checkbox"
                checked={this.state.checked}
                onChange={() => this.handleOnCheck()} />
          <div className="tab-label">
            
              <span className="colSin-15">{`Asegurado ${id}`}</span>
              <span className="colSin-10">{birthDate}</span>
              <span className="colSin-10">{age} años</span>
              {parentesco &&
                <span className="colSin-10" >{parentesco}</span>
              }{
                hasAnotherInsurance? 
                <div className="colSin-10">
                  <span className="salud">Salud</span>
                </div>:
                <div className="colSin-10">
                  <></>
                </div>
              }
              
              {
            (result === undefined || result === false) ? (<span className="delete colSin-5" onClick={() => onClick()}></span>) : ''
          }
          
          </div>
          <div className="tab-content">
            <div className="colSin-50 rightPadding10">
              {coverages && coverages.filter((cov: IHealthCoverage) => cov.required === true)
                .map((coverage: IHealthCoverage, item: number) => (
                  <label
                    className={`checkbox-container${(coverage.required || result) ? ' noPointer' : ''}`}
                    key={item}>
                    <input
                      type="checkbox"
                      checked={coverage.required || coverage.checked}
                    //onChange={(e) => onChange(index, age, coverage, e.target.checked)}
                    />
                    <div className={`checkmark-container${coverage.required ? '-readonly' : ''}`}>
                      <div className="checkmark-item">
                        <img src={(coverage.required || coverage.checked) ? images.IconTickWhite : images.IconCloseWhite} alt="" />
                      </div>
                    </div>
                    <div className="sc-iwsKbI eLRFbD">
                      <p>{coverage.description}</p>
                    </div>
                  </label>

                ))}
            </div>
          </div>
        </div>

      </DentalInsuredContainer>
    )

  }
}

export default DentalInsuredItem;



