import { getMediatorCode } from "../../../../utils/mediatorCode";
import { isValidDocumentID } from "../../../../utils/validation";
import {
  RiskDetailPersonDataDto,
  RiskDetailPetDataDto,
} from "../../../clients/clients-policies/dto/detail-clients-policies-layout-dto";
import { validateFields } from "../components/assets";
import { FormValues } from "../hooks/hooks.types";
import { getPolicyNumber } from "../utils";
import {
  Check,
  DefaultCheck,
  HasBasicDataValidationType,
  InitialDataType,
  ResultMergeInsurancesAndTaker,
  Taker,
} from "./basic-data.types";
export const initialData = (): InitialDataType => {
  return {
    mediator: getPolicyData().mediator,
    policy: getPolicyNumber(),
    docNumber: "",
    name: "",
    nameSelected: "0",
    newName: "",
    newLastname1: "",
    newLastname2: "",
    documents: { uploadDocuments: [] },
    newDocType: "",
    newDocNumber: "",
    birthDate: "",
    newBirthDate: "",
    gender: "",
    newGender: "",
    newNationality: "",
    check: defaultCheck,
    petNewName: "",
    newPetType: "",
    newNumChip: "",
    newPetBirthdate: "",
    newBreed: "",
    petType: "",
    breed: "",
  };
};

export const defaultCheck: DefaultCheck = {
  name: false,
  doc: false,
  birthDate: false,
  gender: false,
  nationality: false,
  petName: false,
  petType: false,
  numChip: false,
  petBirthdate: false,
}

export const validateBasicData = (values: FormValues) => {
  const newDocType = values && values.newDocType ? values.newDocType : "";
  const { errors } = validateFields(
    values,
    basicDataRules(values.check, newDocType)
  );
  const hasValidation = {
    name: !errors.newName && !errors.newLastname1,
    doc: !errors.newDocNumber && !errors.newDocType,
    birthDate: !errors.newBirthDate,
    gender: !errors.newGender,
    nationality: !errors.newNationality,
    documents: !errors.documents,
    petName: !errors.petNewName,
    petType: !errors.newPetType && !errors.newBreed,
    numChip: !errors.newNumChip,
    petBirthdate: !errors.newPetBirthdate,
  };

  return { errors, hasValidation };
};

const validateField = (
  checkField: boolean,
  newField: string,
  customValidation?: (field: string) => string | null
) => {
  if (checkField && !newField) {
    return "required";
  } else if (checkField && newField && customValidation) {
    return customValidation(newField);
  }
  return null;
};

export const basicDataRules = (check: Check, newDocType: string) => {
  return {
    newName: (newName: string) => validateField(check.name, newName),
    newLastname1: (newLastname1: string) =>
      validateField(check.name, newLastname1),
    newDocType: (newDocType: string) => validateField(check.doc, newDocType),
    newDocNumber: (newDocNumber: string) =>
      validateField(check.doc, newDocNumber, (docNumber) => {
        const isValid = isValidDocumentID(docNumber, newDocType);
        return isValid ? null : "invalid-identification";
      }),
    newBirthDate: (newBirthDate: string) =>
      validateField(check.birthDate, newBirthDate),
    newGender: (newGender: string) => validateField(check.gender, newGender),
    newNationality: (newNationality: string) =>
      validateField(check.nationality, newNationality),
    petNewName: (petNewName: string) =>
      validateField(check.petName, petNewName),
    newPetType: (newPetType: string) =>
      validateField(check.petType, newPetType),
    petType: (newNationality: string) =>
      validateField(check.nationality, newNationality),
    newNumChip: (newNumChip: string) =>
      validateField(check.numChip, newNumChip, (numChip) => {
        return numChip.length !== 15 ? "invalid-num-chip" : null;
      }),
    newPetBirthdate: (newPetBirthdate: string) =>
      validateField(check.petBirthdate, newPetBirthdate),
    newBreed: (newBreed: string) => validateField(check.petType, newBreed),
    documents: (documents: { uploadDocuments: any[] }) => {
      return documents.uploadDocuments.length < 1 ? "required" : null;
    },
  };
};

export const isValidStep = (hasValidation: HasBasicDataValidationType): boolean => {
  const { documents, ...rest } = hasValidation;
  return Object.values(rest).every((value) => value);
};

export const getPolicyData = () => {
  const policyDataString = localStorage.getItem("policyData") || "{}";
  return JSON.parse(policyDataString);
};

export const mergeInsurancesAndTaker = (
  insurances: RiskDetailPersonDataDto[],
  taker: Taker,
  pets: RiskDetailPetDataDto[]
): ResultMergeInsurancesAndTaker[] => {
  let result: ResultMergeInsurancesAndTaker[] = [];

  // Tomador
  result.push({
    value: "0",
    label: taker && taker.nom_tomador ? "TOMADOR - " + taker.nom_tomador : "",
    nif: taker && taker.nif_tomador,
    birthDate: taker && taker.fec_nacimiento_tomador,
    gender: taker && taker.sexo,
    name: taker && taker.nom_tomador,
    breed: "",
    numChip: "",
    petType: "",
  });

  // Asegurados
  if (Object.keys(insurances).length > 0) {
    insurances.forEach((insurance, index) => {
      // Si el asegurado no tiene el mismo NIF que el tomador, lo añade al array
      if (taker && insurance && insurance.nif_asegurado !== taker.nif_tomador) {
        result.push({
          value: result.length.toString(),
          label: insurance.nom_asegurado
            ? "ASEGURADO - " + insurance.nom_asegurado
            : "",
          nif: insurance.nif_asegurado,
          birthDate:
            typeof insurance.fec_nacimiento === "string"
              ? insurance.fec_nacimiento
              : insurance.fec_nacimiento.toISOString(),
          gender: insurance.sexo,
          name: insurance && insurance.nom_asegurado,
          breed: "",
          numChip: "",
          petType: "",
        });
      }
    });
  }

  // Mascotas
  if (Object.keys(pets).length > 0) {
    pets.forEach((pet) => {
      result.push({
        value: result.length.toString(),
        label: pet && pet.nom_mascota ? "MASCOTA - " + pet.nom_mascota : "",
        nif: "",
        birthDate:
          typeof pet.fec_nacimiento === "string"
            ? pet.fec_nacimiento
            : pet.fec_nacimiento.toISOString(),
        gender: "",
        name: pet && pet.nom_mascota ? pet.nom_mascota : "",
        breed: pet && pet.raza ? pet.raza : "",
        numChip: pet && pet.chip ? pet.chip : "",
        petType: pet && pet.tipo_mascota && pet.tipo_mascota === 'CANINA' ? "canine" : "feline",
      });
    });
  }
  return result;
};

export const buttonList = [
  { buttonText: "Datos básicos", stepNumber: 1 },
  { buttonText: "Doc. requeridos", stepNumber: 2 },
];

export const stepsMenuProps = {
  policyData: {
    policyType: "",
    policyName: "",
    disabled: true,
  },
};
