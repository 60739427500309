import moment from "moment";
import { questionnaireModel, AnswerModel, Questionnaire, questionnaireData, QuestionnaireResponses, QuestionnaireResponsesSub, ResponseData, SendaResponseQuestionnaire, multipleResponseAnswer, HealthQuestionnairesDataList, QuestionnaireRequest, PREGUNTA } from "./health-questionnaire-dto/health-questionnaire-dto";

enum MainQuestionnaires {
    ASISAC1 = '0',
    ASISAC2 = '1',
    ASISAC22 = '1101',
    ASISAC3 = '2',
}

const questionnaireResponseDefaultBaseBody: QuestionnaireRequest = {
    NUMERIES: '',
    TIPOCUES: "POLIZA",
    CODICUES: "ASISASALUD",
    IDIOMA: "ESPA",
    IDCUESTIONARIO: "6456",
    CUESTIONARIO: {}
}

const questionnaireDefaultBaseBody: Questionnaire = {
    ALIAS: "ASISASALUD",
    CUESTIONARIO: "ASISASALUD",
    RESPUESTA_CUESTIONARIO: questionnaireResponseDefaultBaseBody
}

export function getSendaPostBody(questionnaires: { [id: string]: questionnaireData }, questionnaireBaseValues: HealthQuestionnairesDataList, idCotizacion: string, insureds: any): SendaResponseQuestionnaire {
    let response: SendaResponseQuestionnaire = {
        COTIZACION: idCotizacion,
        RESPUESTAS_CUESTIONARIOS: [] as Questionnaire[]
    }

    for (let key in questionnaires) {
        let questionnaire = questionnaires[key];
        let questionnaireResponse = Object.assign({}, questionnaireDefaultBaseBody);
        questionnaireResponse.RESPUESTA_CUESTIONARIO = Object.assign({}, questionnaireResponseDefaultBaseBody);

        if (questionnaireBaseValues) {
            const firstQuestionnaire = questionnaireBaseValues['ASISASALUD'];
            if (firstQuestionnaire) {
                const index = insureds.findIndex((insured: any) => insured.docNumber === key) + 1
                questionnaireResponse.RESPUESTA_CUESTIONARIO.TIPOCUES = firstQuestionnaire.TIPOCUES;
                questionnaireResponse.RESPUESTA_CUESTIONARIO.CODICUES = firstQuestionnaire.CODICUES;
                questionnaireResponse.RESPUESTA_CUESTIONARIO.IDCUESTIONARIO = firstQuestionnaire.IDENTIFICADOR;

                questionnaireResponse.ALIAS = questionnaireResponse.ALIAS.concat(`${index}`);
                questionnaireResponse.RESPUESTA_CUESTIONARIO.NUMERIES = `${index}`;

                if (firstQuestionnaire.PREGUNTAS_CUESTIONARIO.PREGUNTA) {
                    firstQuestionnaire.PREGUNTAS_CUESTIONARIO.PREGUNTA.forEach((pregunta: PREGUNTA) => {
                        questionnaireResponse.RESPUESTA_CUESTIONARIO.CUESTIONARIO[pregunta.CODIPREG] = getSubquestionnaire(questionnaire.questionnaireData, pregunta, questionnaireBaseValues)
                    })
                }
            }
        }

        response.RESPUESTAS_CUESTIONARIOS.push(JSON.parse(JSON.stringify(questionnaireResponse)));
    }

    response.RESPUESTAS_CUESTIONARIOS.sort((questionnaire: Questionnaire, questionnaire2: Questionnaire) => {
        if (questionnaire.RESPUESTA_CUESTIONARIO.NUMERIES > questionnaire2.RESPUESTA_CUESTIONARIO.NUMERIES)
            return 1
        else
            return -1
    })

    return response;
}
/**
 * 
 * @param questionnaireResponse 
 * @param pregunta 
 * @param healthQuestionnairesDataList 
 * @returns 
 */
function getSubquestionnaire(questionnaireResponse: questionnaireModel, pregunta: PREGUNTA, healthQuestionnairesDataList: HealthQuestionnairesDataList): QuestionnaireResponsesSub {
    let questionnaire: QuestionnaireResponsesSub = { SUBCUESTIONARIO: {} };
    const array_respuestas_cuestionario = pregunta.RESPUESTAS_PREGUNTA.RESPUESTA;
    if (array_respuestas_cuestionario)
        array_respuestas_cuestionario.forEach((respuesta) => {
            if (respuesta.TIPORESP === 'CUES' && respuesta.SUBCODICUES) {
                let nextQuestionnaire = healthQuestionnairesDataList[respuesta.SUBCODICUES];
                if (nextQuestionnaire && nextQuestionnaire.PREGUNTAS_CUESTIONARIO.PREGUNTA)
                    nextQuestionnaire.PREGUNTAS_CUESTIONARIO.PREGUNTA.forEach((nextPregunta) => {
                        if (nextPregunta.SWRESMUL === 'N') {
                            //Simple question
                            if (nextPregunta.TIPODATO) {

                                if (nextPregunta.TIPODATO === 'SUBCUEST') {
                                    questionnaire.SUBCUESTIONARIO[nextPregunta.CODIPREG] = {
                                        SUBCUESTIONARIO: getSubquestionnaire(questionnaireResponse, nextPregunta, healthQuestionnairesDataList).SUBCUESTIONARIO
                                    }
                                }
                                // Es la respuesta del cuestionario con el codigo de cuestionario
                                let respuesta_cuestionario = questionnaireResponse.questionnaireResponse[nextPregunta.CODIPREG];
                                if (respuesta_cuestionario
                                    && respuesta_cuestionario.subCues
                                    && respuesta_cuestionario.value !== 'n') {
                                    //Si tiene subcuestionario volvemos a entrar en el mismo método
                                    questionnaire.SUBCUESTIONARIO[nextPregunta.CODIPREG] = {
                                        [nextPregunta.TIPODATO]: respuesta_cuestionario.value,
                                        SUBCUESTIONARIO: getSubquestionnaire(questionnaireResponse, nextPregunta, healthQuestionnairesDataList).SUBCUESTIONARIO
                                    }
                                } else if (respuesta_cuestionario) {
                                    //Si no tiene subcuestionario añadimos la respuesta simple

                                    if (nextPregunta.TIPODATO === 'RADIO') {
                                        if (respuesta_cuestionario.value && respuesta_cuestionario.value !== 'n') {
                                            questionnaire.SUBCUESTIONARIO[nextPregunta.CODIPREG] = {
                                                [nextPregunta.TIPODATO]: respuesta_cuestionario.value,
                                                TXT_LIBRE: respuesta_cuestionario.subValue || respuesta_cuestionario.value
                                            }
                                        }
                                        else {
                                            questionnaire.SUBCUESTIONARIO[nextPregunta.CODIPREG] = {
                                                [nextPregunta.TIPODATO]: respuesta_cuestionario.value
                                            }
                                        }
                                    }
                                    else {
                                        questionnaire.SUBCUESTIONARIO[nextPregunta.CODIPREG] =
                                            respuesta_cuestionario.value ?
                                                respuesta_cuestionario.value :
                                                ''
                                    }

                                }
                            }
                        }
                        else {
                            //Multiple response question
                            if (nextPregunta.RESPUESTAS_PREGUNTA.RESPUESTA) {
                                nextPregunta.RESPUESTAS_PREGUNTA.RESPUESTA.forEach((multipleResponse) => {

                                    //Si es respuesta tipo VATA
                                    if (multipleResponse.TIPORESP === 'VATA' || multipleResponse.TIPORESP === 'VATX') {
                                        if (questionnaireResponse.questionnaireMultipleResponse && questionnaireResponse.questionnaireMultipleResponse[nextPregunta.CODIPREG] && questionnaireResponse.questionnaireMultipleResponse[nextPregunta.CODIPREG].length > 0) {
                                            let respuestaValor = questionnaireResponse.questionnaireMultipleResponse[nextPregunta.CODIPREG]
                                                .find((i) => i.answerId === multipleResponse.IDENTIFICADOR);

                                            if (respuestaValor && (respuestaValor.checked || respuestaValor.isEnabled))
                                                questionnaire.SUBCUESTIONARIO[nextPregunta.CODIPREG] = {
                                                    ...questionnaire.SUBCUESTIONARIO[nextPregunta.CODIPREG],
                                                    [multipleResponse.VALUERES.concat("_SUBCUESTIONARIO")]: respuestaValor.subValue,
                                                    [multipleResponse.VALUERES]: true
                                                }
                                        }
                                    }

                                    //Si es respuesta tipo cuestionario (CUES)
                                    if (multipleResponse.CUESTIONARIO && multipleResponse.CUESTIONARIO.PREGUNTAS_CUESTIONARIO.PREGUNTA) {
                                        if (questionnaireResponse.questionnaireMultipleResponse && questionnaireResponse.questionnaireMultipleResponse[nextPregunta.CODIPREG] && questionnaireResponse.questionnaireMultipleResponse[nextPregunta.CODIPREG].length > 0) {
                                            let respuestaValor = questionnaireResponse.questionnaireMultipleResponse[nextPregunta.CODIPREG]
                                                .find((i) => i.answerId === multipleResponse.IDENTIFICADOR);
                                            if (
                                                respuestaValor && respuestaValor.isEnabled
                                            )
                                                questionnaire.SUBCUESTIONARIO[nextPregunta.CODIPREG] = {
                                                    ...questionnaire.SUBCUESTIONARIO[nextPregunta.CODIPREG],
                                                    [multipleResponse.VALUERES.concat("_SUBCUESTIONARIO")]: getMultipleResponses(questionnaireResponse, multipleResponse.CUESTIONARIO.PREGUNTAS_CUESTIONARIO.PREGUNTA),
                                                    [multipleResponse.VALUERES]: true
                                                }
                                        }
                                        else {
                                            questionnaire.SUBCUESTIONARIO[nextPregunta.CODIPREG] = {
                                                ...questionnaire.SUBCUESTIONARIO[nextPregunta.CODIPREG],
                                                [multipleResponse.VALUERES]: false
                                            }
                                        }
                                    }
                                })
                            }
                        }
                    })
            }
        })

    return questionnaire;
}

function getMultipleResponses(questionnaireResponse: questionnaireModel, preguntas: PREGUNTA[]): multipleResponseAnswer {
    let responses: multipleResponseAnswer = {};

    preguntas.forEach((pregunta) => {
        responses[pregunta.CODIPREG] = {
            [pregunta.TIPODATO]: questionnaireResponse.questionnaireResponse[pregunta.CODIPREG].value
        }
    })

    return responses;
}

export const calculateIMC = (weight: number, height: number): number => {
    const mtsHeight = height / 100;

    return weight / (mtsHeight * mtsHeight);
}

function checkSubquestionnaire(subcuestionario: any, result: { weight: number, height: number, needsReview: boolean }) {
    for (let subkey in subcuestionario) {
        if (typeof subcuestionario[subkey] === 'object') {
            if (subcuestionario[subkey]["RADIO"] === 's') {
                result.needsReview = true;
            }
            // Recursivamente revisa los subcuestionarios anidados
            checkSubquestionnaire(subcuestionario[subkey], result);
        }
    }
}

function calculateAge(birthdateParams: string): number {
    let [day, month, year] = birthdateParams.split('/');
    let birthDate = moment(`${year}-${month}-${day}`, "YYYY-MM-DD");
    let age = moment().diff(birthDate, 'years');

    return age;
}

export function getFormReviewStatus(response: SendaResponseQuestionnaire, ADULT_IMC_MIN= 17, ADULT_IMC_MAX= 35, SENIOR_IMC_MIN= 17, SENIOR_IMC_MAX= 30) {
    let results = [];
  
    for (let questionnaire of response.RESPUESTAS_CUESTIONARIOS) {
      let result = {
        weight: 0,
        height: 0,
        needsReview: false,
        needsReviewByIMC: false
      };
  
      let cuestionario = questionnaire.RESPUESTA_CUESTIONARIO.CUESTIONARIO;
      for (let key in cuestionario) {
        let subcuestionario = cuestionario[key].SUBCUESTIONARIO;
        if (subcuestionario["05"]) {
          result.weight = parseInt(subcuestionario["05"]);
        }
        if (subcuestionario["06"]) {
          result.height = parseInt(subcuestionario["06"]);
        }
        if (subcuestionario["04"]) {
          let age = calculateAge(subcuestionario["04"]);
          let imc = calculateIMC(result.weight, result.height);
          if (age <= 59) {
            if (imc < ADULT_IMC_MIN || imc > ADULT_IMC_MAX) {
              result.needsReviewByIMC = true;
            }
          } else {
            if (imc < SENIOR_IMC_MIN || imc > SENIOR_IMC_MAX) {
              result.needsReviewByIMC = true;
            }
          }
        }
        checkSubquestionnaire(subcuestionario, result);
      }
  
      results.push(result);
    }
  
    return results;
  }
  