import { getDayMonthYear } from "./time"
import { calcAge } from "./utility"

export const formatPayments = (payments: any) => {
    let formattedPayments

    if(!payments){
        return []
    }else if(Array.isArray(payments)){
        formattedPayments = []
        payments.forEach( (payment: any, index: number) => {
            formattedPayments[index] = {
                paymentTime: payment.DesFormaPago,
                billCost: payment.TotalPrimaNeta,
                monthlyCost: payment.TotalPrimaNetaMes,
                annuallyCost: payment.TotalPrimaNetaAnio,
            }
        })
    }else if(Object.keys(payments).length){
        formattedPayments = {
            paymentTime: payments.DesFormaPago,
            billCost: payments.TotalPrimaNeta,
            monthlyCost: payments.TotalPrimaNetaMes,
            annuallyCost: payments.TotalPrimaNetaAnio,
        }
    }

    return formattedPayments
    
}

export const formatInsured = (insureds: any, prices: any, postalCode: string) => {
    let insuredsAux: any = []

    if(insureds && insureds.length){
        insureds.forEach((insured: any, index: number) => {
            insuredsAux[index] = {
              age: calcAge(getDayMonthYear(insured.birthDate).toString()),
              selectedPrice: Array.isArray(prices) ? formatPayments(prices[index]) : formatPayments(prices),
              postalCode,
            }
        })
    }else{
        return []
    }

    return insuredsAux
}