import React from 'react';
import HomeLayout from './home-layout';
import GeneralServices from '../../services/GeneralServices';
import { withGeneral } from '../../context/mixin/with-general';

interface HomeState {
    coverages: any[];
}

interface HomeProps {
    setLoading: Function;
}
class Home extends React.Component<HomeProps, HomeState> {

    readonly state: HomeState;

    constructor(props: any) {
        super(props);
        this.state = {
            coverages: []
        }
    }

    componentDidMount() {
        const { setLoading } = this.props;

    }

    render() {
        return (
            <HomeLayout {...this.props} />
        )
    }
}

export default withGeneral(Home);