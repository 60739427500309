import React from "react";
import { WebTextContainer } from "./web-text-style";

interface WebTextProps {
    children: any;
    className?: string;
    color?: string;
}

const WebText = ({children = '', className='', color=''}:WebTextProps) => {
        return (
        <WebTextContainer color={color} className={className}>
            {children}
        </WebTextContainer>
    )
} 
export default WebText;