import moment from "moment";
import React, { Component } from "react";
import { PolicyDataContainer } from "./policy-data-style"
import SelectBox from "../../../../../../components/select-box/select-box";
import CalendarBox from "../../../../../../components/calendar-box/calendar-box";
import { HEALTH_MAX_EFFECT_DAYS } from "../../../../../../services/url";
import { IMediator } from "../../../../../../models/mediator-model";
import { SimpleSelectboxInterface } from "../../../../../simulations/new-simulation/choose-policy/new-simulation.dto";
import { getRelatedMediatorsCodes } from "../../../../../../utils/mediatorCode";
import { deepGet } from "../../../../../../utils/utility";
import { defaultMaxMinDates, getMaxMinDates, MaxMinDatesType } from "../../../../../../utils/startEndDates";

interface PolicyDataProps {
    validate: {
        validateRequest: boolean;
        isValidated: boolean;
    },
    onValidated?: any;
    insured?: boolean;
    data?: any;
    isReadOnly?: boolean;
    mediator: IMediator;
    selectedMediatorCode: string;
    policy: any;
}

interface PolicyDataState {
    policy: {
        startDate: {
            value: string,
            errorMessage: string
        },
        endDate: {
            value: string,
            errorMessage: string,
        },
        paymentType: {
            value: string,
            errorMessage: string
        },
        docsLanguage: {
            value: string,
            errorMessage: string,
        }
    };
    maxEffectDays: number;
    relatedMediatorCodes: Array<SimpleSelectboxInterface>;
    mediatorCodeSelected: string,
    maxMinDates: MaxMinDatesType,
}

class PolicyData extends Component<PolicyDataProps, PolicyDataState> {

    constructor(props: PolicyDataProps) {
        super(props)
        const getMediador = () => {
            if (props.selectedMediatorCode) {
                return props.selectedMediatorCode;
            } else {
                if (props.data.mediatorData && props.data.mediatorData.code) {
                    return props.data.mediatorData.code
                } else {
                    if (props.data && props.data.taker && props.data.taker.policy && props.data.taker.policy.selectedMediator) {
                        return props.data.taker.policy.selectedMediator;
                    } else {
                        return props.mediator.mediatorCode;
                    }
                }
            }

        }

        this.state = {
            policy: {
                startDate: {
                    value: (props.data.mediatorData && props.data.mediatorData.startDate) ? props.data.mediatorData.startDate : moment().format('DD/MM/YYYY').toLocaleString(),
                    errorMessage: ''
                },
                endDate: {
                    value: deepGet(props, "data.mediatorData.endDate", "") || "",
                    errorMessage: '',
                },
                paymentType: {
                    value: (props.policy.data.price && props.policy.data.price.price && props.policy.data.price.price.payType) ?
                        props.policy.data.price.price.payType :
                        '',
                    errorMessage: ''
                },
                docsLanguage: {
                    value: deepGet(props, "data.taker.docsLanguage", "") || "",
                    errorMessage: "",
                },
            },
            maxEffectDays: HEALTH_MAX_EFFECT_DAYS ? Number(HEALTH_MAX_EFFECT_DAYS) : 0,
            relatedMediatorCodes: getRelatedMediatorsCodes(this.props.mediator),
            mediatorCodeSelected: getMediador(),
            maxMinDates: defaultMaxMinDates,
        }
    }

    componentWillReceiveProps(nextProps: any) {
        let { validate } = this.props;

        if (nextProps.validate.validateRequest) {

            validate.validateRequest = false;
            validate.isValidated = this.validateForm();

        }

        // Opción con pago único
        if (this.props.policy.productPayTypes.length !== nextProps.policy.productPayTypes.length) {
            const paymentOptions: any = deepGet(nextProps.policy, "productPayTypes", []) || [];
            if (paymentOptions.length === 1) {
                const { policy } = this.state;
                policy.paymentType.value = paymentOptions[0] && paymentOptions[0].value;
                this.setState({ policy });
            }
        }
    }

    componentDidMount() {
        if (this.props.data !== undefined && this.props.data.taker && this.props.data.taker.policy !== undefined) {
            this.completeFormTakerData();
        }
    }

    componentDidUpdate(prevProps: Readonly<PolicyDataProps>): void {
        const { policy } = this.props;
        const productFields: any | undefined = deepGet(policy, "product.productFields");
        const prevProductFields = deepGet(prevProps.policy, "product.productFields", null);

        if ((!prevProductFields && productFields) || (productFields && !this.state.maxMinDates.startMinDate)) {
            const maxMinDates = getMaxMinDates(this.state.policy.startDate.value, productFields.step1);
            const fixEndDate = maxMinDates.fixEndDate;
            const endDate = fixEndDate
                ? moment(maxMinDates.endMinDate).format("DD/MM/YYYY")
                : this.state.policy.endDate.value;
            const newPolicy = { ...this.state.policy, endDate: { value: endDate || "", errorMessage: '' }}
            this.setState({ policy: newPolicy, maxMinDates });
        }
    }

    completeFormTakerData() {
        let { policy, mediatorCodeSelected } = this.state;
        const { data } = this.props;

        policy.paymentType.value = data.taker.policy.paymentType;
        policy.startDate.value = data.taker.policy.startDate;

        if (deepGet(this.props, "policy.product.productFields.step1.docsLanguage", false)) {
            policy.docsLanguage.value = data.taker.policy.docsLanguage;
        }
        if (deepGet(this.props, "policy.product.productFields.step1.endDate", false)) {
            policy.endDate.value = data.taker.policy.endDate || moment(policy.startDate.value, "DD/MM/YYYY").add(Number(deepGet(this.props, "policy.product.productFields.step1.endDate.min")), "days").format("DD/MM/YYYY");
        }
        if (data.mediatorData && data.mediatorData.code)
            mediatorCodeSelected = data.mediatorData.code;

        this.setState({ policy, mediatorCodeSelected });
    }

    handleValueChange(input: string, value: string) {
        let { policy, maxMinDates } = this.state;
        switch (input) {
            case 'startDate':
            case 'paymentType':
            case "endDate":
            case "docsLanguage":
                Object.keys(policy).forEach((k: string) => {
                    if (k === input) {
                        policy[k].errorMessage = '';
                        policy[k].value = value;
                    }
                });
                break;

            default:
                break;
        }

        if (input === "startDate" && maxMinDates.endMinDate) {
            const productFields: any = deepGet(this.props.policy, "product.productFields");
            if (productFields.step1) {
                const newMaxMinDates = getMaxMinDates(value, productFields.step1);
                const { endMinDate } = newMaxMinDates;
                const endDate = newMaxMinDates.fixEndDate ? moment(endMinDate).format("DD/MM/YYYY") : "";
                policy.endDate.value = endDate;
                policy.endDate.errorMessage = "";
                this.setState({ maxMinDates: newMaxMinDates });
            }
        }
        this.setState({ policy })
    }

    validateForm(): boolean {
        let { policy, mediatorCodeSelected } = this.state;
        const productFields = deepGet(this.props, "policy.product.productFields", null);
        let noErrors = true;

        if (deepGet(productFields, "step1.startDate.required", false) && policy.startDate.value.length === 0) {
            policy.startDate.errorMessage = 'required';
            noErrors = false;
        }
        if (deepGet(productFields, "step1.endDate.required", false) && policy.endDate.value.length === 0) {
            policy.endDate.errorMessage = 'required';
            noErrors = false;
        }
        if (deepGet(productFields, "step1.docsLanguage.required", false) && policy.docsLanguage.value.length === 0) {
            policy.docsLanguage.errorMessage = 'required';
            noErrors = false;
        }
        if (deepGet(productFields, "step1.paymentType.required") && policy.paymentType.value.length === 0) {
            policy.paymentType.errorMessage = 'required';
            noErrors = false;
        }

        if (noErrors) {
            if (this.props.onValidated) {
                this.props.onValidated(
                    {
                        startDate: policy.startDate.value,
                        endDate: policy.endDate.value,
                        paymentType: policy.paymentType.value,
                        selectedMediator: mediatorCodeSelected,
                        docsLanguage: policy.docsLanguage.value,
                    }
                );
            }
        }

        this.setState({ policy });
        return noErrors;
    }

    handleSelectMediatorChange(value: string): void {
        this.props.policy.setMediatorSelectedCode && this.props.policy.setMediatorSelectedCode(value);
        this.setState({ mediatorCodeSelected: value });
    }

    render() {
        const {
            policy,
            relatedMediatorCodes,
            mediatorCodeSelected,
            maxMinDates
        } = this.state
        const { isReadOnly } = this.props;
        const productFields = deepGet(this.props, "policy.product.productFields", null);
        
        return (
            <PolicyDataContainer>
                <div className="content-wrapper bottomMargin20" ref={''}>
                    <div className="personal-data-wrapper" id="data-policy">
                        <div className="data-title">
                            <h5>DATOS DE PÓLIZA</h5>
                        </div>
                        <div className="personal-data-container">
                            <div className="personal-data-row">
                                {relatedMediatorCodes.length > 0 &&
                                    <div className="personal-data-mediador col-100">
                                        <div className="selectBox">
                                            <SelectBox
                                                isMediator={true}
                                                optionsText={relatedMediatorCodes}
                                                optionKey={"value"}
                                                disabled={isReadOnly}
                                                optionValue={"label"}
                                                defaultValue={mediatorCodeSelected}
                                                onChange={(value: string) => this.handleSelectMediatorChange(value)}
                                                propValue={mediatorCodeSelected}
                                                required
                                            />
                                            <label>Mediador</label>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="personal-data-row">
                                <div className="personal-data-item col-50">
                                    {deepGet(productFields, "step1.paymentType", false) && (
                                        <div className="selectBox">
                                            <SelectBox
                                                labelText=""
                                                required={deepGet(productFields, "paymentType.required", true)}
                                                errorCode={deepGet(policy, "paymentType.errorMessage", "")}
                                                optionsText={this.props.policy.productPayTypes}
                                                optionKey={"value"}
                                                optionValue={"label"}
                                                initialValue={policy.paymentType.value}
                                                onChange={(value: string) => this.handleValueChange('paymentType', value)}
                                                disabled={isReadOnly}
                                            />
                                            <label>Tipo de pago</label>
                                        </div>
                                    )}
                                </div>

                                <div className="personal-data-item col-50" ref={''}>
                                    {deepGet(productFields, "step1.startDate", false) && (
                                        <div className="inputBox">
                                            <CalendarBox
                                                initialValue={policy.startDate.value}
                                                disabled={isReadOnly}
                                                minDate={maxMinDates.startMinDate}
                                                maxDate={maxMinDates.startMaxDate}
                                                onChange={(value: string) => this.handleValueChange('startDate', value)}
                                                required={deepGet(productFields, "step1.startDate.required")}
                                                withTooltip={deepGet(productFields, "step1.startDate.tooltip", false)}
                                                tooltipTitle={deepGet(productFields, "step1.startDate.tooltip", "")}
                                                tooltipText=""
                                                tooltipIconLeft="150px"
                                                errorCode={deepGet(policy, "startDate.errorMessage", "")}
                                            />
                                            <label>Fecha efecto</label>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="personal-data-row">
                                <div className="personal-data-item col-50">
                                    {deepGet(productFields, "step1.docsLanguage", false) && (
                                        <div className="selectBox">
                                            <SelectBox
                                                placeholder="Idioma de generación de documentos"
                                                required={deepGet(productFields, "step1.docsLanguage.required", false)}
                                                optionsText={deepGet(productFields, "step1.docsLanguage.options", []) || []}
                                                optionKey="value"
                                                optionValue="label"
                                                initialValue={policy.docsLanguage.value}
                                                defaultValue={policy.docsLanguage.value}
                                                onChange={(value: string) => this.handleValueChange("docsLanguage", value)}
                                                errorCode={deepGet(policy, "docsLanguage.errorMessage", "")}
                                            />
                                            <label>Idioma de generación de documentos</label>
                                        </div>
                                    )}
                                </div>

                                <div className="personal-data-item col-50">
                                    {deepGet(productFields, "step1.endDate", false) && (
                                        <div className="inputBox">
                                            <CalendarBox
                                                labelText=""
                                                initialValue={policy.endDate.value ? policy.endDate.value : ""}
                                                maxDate={maxMinDates.endMaxDate}
                                                minDate={maxMinDates.endMinDate}
                                                onChange={(value: string) => this.handleValueChange("endDate", value)}
                                                required={deepGet(productFields, "step1.endDate.required")}
                                                withTooltip={deepGet(productFields, "step1.endDate.tooltip", false)}
                                                tooltipTitle={deepGet(productFields, "step1.endDate.tooltip", "")}
                                                tooltipText=""
                                                tooltipIconLeft="180px"
                                                errorCode={deepGet(policy, "endDate.errorMessage", "")}
                                                disabled={maxMinDates.fixEndDate || isReadOnly}
                                            />
                                            <label>Fecha vencimiento</label>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </PolicyDataContainer>
        )
    }
}

export default PolicyData;