import GeneralServices from "./GeneralServices";
import { BaseServiceRequestDto } from "./dto/request/BaseServiceRequest.dto";

class RequiredDocumentsServices {

  getRequiredDocuments = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `required-documents?producto=${args.product} `
      });
      return response.data;
    } catch (err) {
      return [];
    }
  };

  getDocumentsPersonaTypes = async(args: BaseServiceRequestDto) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: 'required-documents/persona-types',
      });
      return {
        ok: true,
        data: response.data,
      };
    } catch (error) {
      return {
        error: true,
        message: typeof error === "string" ? error : "Se ha producido un error, inténtelo más tarde.",
      }
    }
  };
}

export default new RequiredDocumentsServices();
