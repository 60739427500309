import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ClientsLiquidationsContainer } from "./clients-liquidations-style";
import GoBack from "../../../components/go-back";
import Title02Text from "../../../components/components-text/title-02-text";
import MainTable from "../../../components/main-table";
import Pagination from "../../../components/pagination";
import InfoModal from "../../../components/info-modal";
import { images } from "../../../assets";
import SelectBox from "../../../components/select-box/select-box";
import DOC_TYPES from "../../../constants/doc_types";
import { capitalizeFirstLetter, currencyFormat } from "../../../utils";
import { getMonth, getYear, getDateFormat } from "../../../utils/time";
import LiquidationsServices from "../../../services/LiquidationsServices";
import { withGeneral } from "../../../context/mixin/with-general";
import { MONTHS } from "../../../constants/filter";
import Body02Text from "../../../components/components-text/body-02-text";
import MainButton from "../../../components/main-button";

interface ClientsLiquidationsProps extends RouteComponentProps {
  setLoading: Function;
  user: any;
}

interface ClientsLiquidationsData {
  filter: {
    year: string;
    month: string;
    [key: string]: string;
  }
  liquidations: any[];
  liquidationsFilter: any[];
  years: any[];
  page: number;
  totalPages: number;
  showItems: number;
}

class ClientsLiquidationsLayout extends React.Component<ClientsLiquidationsProps, ClientsLiquidationsData> {
  constructor(props: ClientsLiquidationsProps) {
    super(props);

    this.state = {
      filter: {
        year: '',
        month: '',
        day: '',
        state: '',
      },
      liquidations: [],
      liquidationsFilter: [],
      years: [],
      page: 1,
      totalPages: 0,
      showItems: 10
    };
  }

  componentDidMount() {
    this.getRows()
  }

  componentDidUpdate(prevProps: ClientsLiquidationsProps) {
    if (prevProps.user.code !== this.props.user.code)
      this.getRows()
  }

  getColumns = () => [
    {
      text: "año",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "mes",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Fecha de abono",
      filter: false,
      ascendent: false,
      key: "highlighted",
    },
    {
      text: "Importe",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Fichero EIAC",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "",
      filter: false,
      ascendent: true,
    },
  ];

  getRows = async () => {

    const { setLoading, user } = this.props;
    const { showItems, page, filter } = this.state;

    const params = [`skip=${showItems * (page - 1)}`, `take=${showItems}`];
    if (filter.day) {
      params.push(`day=${filter.day}`);
    }
    if (filter.month) {
      params.push(`month=${filter.month}`);
    }
    if (filter.year) {
      params.push(`year=${filter.year}`);
    }
    if (filter.state) {
      params.push(`receiptState=${filter.state}`);
    }
    const resLiquidations = await LiquidationsServices.getLiquidations({
      loader: true,
      setLoading: setLoading,
      params
    });

    const rows: any[] = [];
    const yearsAll: string[] = [];

    resLiquidations.items.forEach((liquidation: any) => {
      const row =
      {
        year: {
          text: liquidation.startDate ? getYear(liquidation.startDate).toString() : '-',
          type: "",
          key: "highlighted",
        },
        month: {
          text: liquidation.startDate ? capitalizeFirstLetter(getMonth(liquidation.startDate)) : '-',
          type: "keep-calm",
          key: "tag-container",
        },
        subscriptionDate: {
          text: liquidation.startDate ? getDateFormat(liquidation.startDate, 'DD/MM/YYYY') : '-',
          type: "",
          key: "body",
        },
        amount: {
          text: currencyFormat(liquidation.totalPrime) + ' €',
          type: "",
          key: "body",
        },
        eiacFile: {
          text: liquidation.filename,
          type: "",
          key: "body",
        },
        buttons: {
          key: "buttons",
          items: [
            {
              img: images.IconDownloadLightBlue,
              onClick: () => window.open(liquidation.fileuri),
            },
          ],
        },
      };

      rows.push(row);
      yearsAll.push(getYear(liquidation.issueDate).toString());
    });

    const pages = Math.ceil(resLiquidations.count / showItems);

    const years = await LiquidationsServices.getYears({
      loader: true,
      setLoading: setLoading,
    });
    //this.getYears(new Date().getFullYear() - 20)
    //Array.from(new Set(yearsAll));

    const yearsObj = [{ label: 'Año', value: '' }].concat(years.sort(
      (a: number, b: number) => b - a).map(
        (y: any) => ({ label: y.toString(), value: y.toString() })));

    this.setState({ years: yearsObj, liquidations: rows, liquidationsFilter: rows, totalPages: pages });

  }


  async getAllLiquidations() {
    const args = {
      setLoading: () => { },
      loader: true
    }
    const liquidations = await LiquidationsServices.getAllLiquidations(args);
    //TODO implement when end-point is available
  }

  infoModal: any = null;

  changeValue = (value: string, key: string = "") => {
    const { filter } = this.state;
    if (filter[key] !== value) {
      filter[key] = value;

      this.setState({ filter, page: 1 }, () => {
        this.getRows();
      });
    }
  };

  setPage = (page: number) => {
    this.setState({ page }, () => this.getRows());
  }

  render() {
    const { year, month } = this.state.filter;
    const { years, liquidations, totalPages, page, showItems } = this.state;

    return (
      <ClientsLiquidationsContainer>
        <div className="back-arrow-container">
          <GoBack text="Volver"/>
        </div>
        <div className="main-title-container ">

          <h2>LIQUIDACIONES</h2>

        </div>

        <div className="main-filter-container height100">

          <div className="colSin-20 rightMargin20">
            <div className="selectBox">
              <SelectBox
                required
                optionsText={years}
                optionKey={"value"}
                optionValue={"label"}
                defaultValue={""}
                initialValue={year}
                onChange={(value: string) => this.changeValue(value, "year")}
              />
              <label>AÑO</label>
            </div>
          </div>
          <div className="colSin-20 rightMargin20">
            <div className="selectBox">
              <SelectBox
                required
                optionsText={MONTHS}
                optionKey={"value"}
                optionValue={"label"}
                defaultValue={""}
                initialValue={month}
                onChange={(value: string) => this.changeValue(value, "month")}
              />
              <label>MES</label>
            </div>
          </div>
          <div className="colSin-20 height20"></div>
          <div className="colSin-20 topMargin15">
            <MainButton
              text={'Descargar todos'}
              onClick={() => this.getAllLiquidations()}
            />
          </div>
        </div>
        {totalPages === 0 ? (
          <div className="colSin-100 topMargin15">No hay datos</div>
        ) : (
            <>
              <div className="pendant-simulations-table">
                <MainTable
                  columns={this.getColumns()}
                  rows={liquidations}
                  keysRow={["year", "month", "subscriptionDate", "amount", "eiacFile", "buttons"]}
                />
              </div>

              <div className="pendant-simulation-pagination">
                <Pagination page={page} limit={totalPages} callback={(page) => this.setPage(page)} />
              </div>
            </>
          )}
      </ClientsLiquidationsContainer>
    );
  }
}

export default withGeneral(withRouter(ClientsLiquidationsLayout));
