import React, { Component } from "react";
import { DoneHiringContainer } from "./dont-hiring-style";
import FilterInput from "../../../components/filter-input/filter-input-layout";
import MainButton from "../../../components/main-button";
import MainTable from "../../../components/main-table";
import HiringServices from "../../../services/HiringServices";
import { PRODUCT_CARD_NAME_COLOR} from "../../../constants/policyType";
import { getDayMonthYear } from "../../../utils/time";
import { RouteComponentProps, withRouter } from "react-router-dom";
import InfoModal from "../../../components/info-modal";
import { images } from "../../../assets";
import NoteText from "../../../components/components-text/note-text";
import Body02Text from "../../../components/components-text/body-02-text";
import Pagination from "../../../components/pagination";
import Loader from "../../../components/loader";
import { withDynamicProducts } from "../../../context/mixin/with-dynamic-products";
import MultiSelect from "../../../components/multi-select-box/multi-select-box";
import { getRelatedMediatorsCodes } from "../../../utils/mediatorCode";
import { IMediator } from "../../../models/mediator-model";
import { SimpleSelectboxInterface } from "../../simulations/new-simulation/choose-policy/new-simulation.dto";
import { withGeneral } from "../../../context/mixin/with-general";

interface DoneHiringProps extends RouteComponentProps {
  allProducts: any;
  mediator: IMediator;
}

interface DoneHiringState {
  hiringFilter: any[];
  page: number;
  showItems: number;
  totalPages: number;
  search: string;
  searched: boolean;
  ascendent?: boolean;
  order: string;
  rows: any[];
  showInfoModal: boolean;
  infoModalMainText: string;
  infoModalOnClickAccept: Function;
  selectedMediatorCodes: string[];
  relatedMediatorCodes: SimpleSelectboxInterface[];
  loading: boolean;
  multiSelectError: string;
}

class DoneHiring extends Component<DoneHiringProps, DoneHiringState> {
  constructor(props: DoneHiringProps) {
    super(props);
    this.state = {
      hiringFilter: [],
      page: 1,
      searched: false,
      totalPages: 0,
      showItems: 10,
      search: "",
      order: "createdAt",
      rows: [],
      showInfoModal: false,
      infoModalMainText: "",
      ascendent: false,
      infoModalOnClickAccept: () => { },
      loading: true,
      relatedMediatorCodes: getRelatedMediatorsCodes(props.mediator),
      selectedMediatorCodes: [`${props.mediator.mediatorCode} - ${props.mediator.mediatorName.trim()} - ${props.mediator.orderNif}`],
      multiSelectError: '',
    };
  }

  async componentDidMount() {
    const { allProducts } = this.props;
    if(Object.keys(allProducts).length!==0){
      this.setState({loading: false})
      await this.getDoneHiring();
    }
  }

  async componentDidUpdate(prevProps: Readonly<DoneHiringProps>, prevState: Readonly<DoneHiringState>) {
    const { allProducts } = this.props
    if(Object.keys(prevProps.allProducts).length===0 && Object.keys(allProducts).length!==0){
      this.setState({loading: false})
      await this.getDoneHiring();
    }
  }


  async getDoneHiring() {
    const { showItems, search, page, ascendent, order, selectedMediatorCodes, relatedMediatorCodes } = this.state;
    const { allProducts } = this.props

    const params = [
      `skip=${showItems * (page - 1)}`,
      `take=${showItems}`,
      "status=EMITIDA",
    ];
    if (search) {
      params.push(`search=${search}`);
    }

    if (ascendent === true && order) {
      params.push(`order=${order}`);
    } else if (ascendent === false && order) {
      params.push(`order=-${order}`);
    }

    if (selectedMediatorCodes) {
      selectedMediatorCodes.forEach((value: string) => {
        const relatedMediator = relatedMediatorCodes.find(med => med.label === value);
        if (relatedMediator) params.push(`mediatorCodes=${relatedMediator.value}`);
      });
    }
    
    const resHiring = await HiringServices.getHiring(true, () => { }, params);
    
    const rows = resHiring.items.map((hiring: any) => {
      const product = allProducts[hiring.productType]
      const productName = product.products_name
      const productCardName = product.products_cardName

      const row = {
        request: {
          text:  hiring.body.idcotiza || hiring.body.requestNumber || hiring.body.requestId,
          type: "",
          key: "highlighted",
        },
        "policy-type": {
          text: productName,
          type: PRODUCT_CARD_NAME_COLOR[productCardName],
          key: "tag-container",
        },
        date: {
          text: getDayMonthYear(hiring.createdAt),
          type: "",
          key: "body",
        },
        taker: {
          text: `${hiring.body.taker.name} ${hiring.body.taker.lastname1 || ""} ${hiring.body.taker.lastname2 || ""
            }`,
          type: "",
          key: "body",
        },
        nif: {
          text: hiring.body.taker.docNumber,
          type: "",
          key: "body",
        },
        policy: {
          text: hiring.policyNumber,
          type: "",
          key: "body",
        },
      }
      return row
    });

    const totalPages = Math.ceil(resHiring.count / showItems);
    
    this.setState({ rows, totalPages, searched: search !== "" });
  }

  handleOrder = (column: string, ascendent: boolean | undefined) => {
    switch (column) {
      case "taker":
        this.setState({ order: "takerFullName", ascendent }, () =>
          this.getDoneHiring()
        );
        break;
      case "nif":
        this.setState({ order: "takerNif", ascendent }, () =>
          this.getDoneHiring()
        );
        break;
      case "date":
        this.setState({ order: "createdAt", ascendent }, () =>
          this.getDoneHiring()
        );
        break;
      default:
        break;
    }
  };

  handleNewHiring = () => {
    this.setState({
      showInfoModal: true,
      infoModalMainText:
        "Vas a iniciar el proceso de contratación, ¿estás seguro?",
      infoModalOnClickAccept: () =>
        this.props.history.push("/contratacion/nueva"),
    });
  };

  closeModal = () => this.setState({ showInfoModal: false });

  setPage = (page: number) => {
    this.setState({ page }, () => this.getDoneHiring());
  };


  handleSelectedMediatorChange(value: string[]) {
    let { multiSelectError } = this.state;
    if (value.length === 0)
      multiSelectError = 'Debes seleccionar al menos un mediador';
    else
      multiSelectError = '';
    this.setState({ selectedMediatorCodes: value, multiSelectError });
  }

  render() {
    const {
      rows,
      showInfoModal,
      infoModalMainText,
      infoModalOnClickAccept,
      searched,
      totalPages,
      page,
      selectedMediatorCodes,
      relatedMediatorCodes,
      multiSelectError,
    } = this.state;
    return (
      <DoneHiringContainer>
        {(this.state.loading) &&
          <Loader />
        }
        <div className="main-title-container">
          <h2>Contrataciones realizadas</h2>
        </div>
        <div className="main-filter-container height80">
          <div className="colSin-25 rightPadding20">
            <div className="inputBox">
              <FilterInput
                placeholder={"Buscador Contrataciones"}
                onChange={(value: string) => this.setState({ search: value })}
                onEnter={this.getDoneHiring.bind(this)}
              />
              <label>Buscador Contrataciones</label>
            </div>
          </div>

          <div className="colSin-45 rightPadding20 ">
            <div className="selectBox">
              <MultiSelect
                onChange={(value: string[]) => this.handleSelectedMediatorChange(value)}
                options={relatedMediatorCodes}
                defaultValues={selectedMediatorCodes}
                errorCode={multiSelectError}
                placeholder={''}
                required
              />
              <label>Mediador/es</label>
            </div>
          </div>

          <div className="colSin-15 topMargin15 rightPadding20">
            <MainButton
              text="Buscar"
              onClick={() => {
                this.getDoneHiring()
                this.setPage(1)
              }}
              disabled={multiSelectError}
            />
          </div>

          <div className="colSin-15 topMargin15">
            <MainButton
              text="Hacer nueva"
              onClick={() => this.handleNewHiring()}
            />
          </div>
        </div>

        {searched && (
          <div className="main-results-number">
            <NoteText>
              <p>
                Se han encontrado <span>{rows.length} resultados</span>
              </p>
            </NoteText>
          </div>
        )}
        {totalPages === 0 ? (
          <Body02Text>No hay datos</Body02Text>
        ) : (
          <>
            <div className="done-hiring-table">
              <MainTable
                className="table"
                columns={this.columns}
                rows={rows}
                keysRow={["request", "policy-type", "date", "taker", "nif", "policy"]}
                onOrder={(column: string, ascendent: boolean | undefined) =>
                  this.handleOrder(column, ascendent)
                }
              />
            </div>
            <div className="done-hiring-pagination">
              <Pagination
                page={page}
                limit={totalPages}
                callback={(page) => this.setPage(page)}
              />
            </div>
          </>
        )}

        {showInfoModal && (
          <InfoModal
            img={images.IconCheckboxBlue}
            mainText={infoModalMainText}
            onClickAccept={() => infoModalOnClickAccept()}
            buttonText="Aceptar"
            close={() => this.closeModal()}
            active={showInfoModal}
          />
        )}
      </DoneHiringContainer>
    );
  }

  columns = [
    {
      text: "Solicitud",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Producto",
      filter: false,
      ascendent: true,
    },
    {
      text: "Contratación",
      filter: true,
      ascendent: true,
    },
    {
      text: "Cliente",
      filter: true,
      ascendent: true,
    },
    {
      text: "Nif",
      filter: true,
      ascendent: true,
    },
    {
      text: "Póliza",
      filter: false,
      ascendent: true,
    },
  ];
}

export default withGeneral(withDynamicProducts(withRouter(DoneHiring)));
