import React, { Component } from "react";
import { ModalAddConditionContainer } from "./modal-add-condition-style";
import { images } from "../../../../assets";
import H4Text from "../../../../components/components-text/h4-text";
import MainButton from "../../../../components/main-button";
import SelectBox from "../../../../components/select-box/select-box";
import InputBox from "../../../../components/input-box/input-box";
import {
  PRODUCT_BRANCHES_DROPDOWN,
  LIFE_PRODUCTS_DROPDOWN,
  PET_PRODUCTS_DROPDOWN,
  DEATH_PRODUCTS_DROPDOWN,
  optionsUnits,
  optionsContestUnits,
  optionsIncentiveUnits
} from "../../../../constants/campaignsConfig";

interface IModalAddConditionProps {
  close: () => void;
  onSave: (conditions: ICondition[][]) => void;
  title: string;
  conditions?: ICondition[][];
  isContest: boolean;
}

interface IModalAddConditionState {
  conditions: ICondition[][];
  openedPrizes: boolean[];
  errors: {
    unit: string;
    productLines: string;
    products: string;
    value: string;
  }[][];
}

export interface ICondition {
  unit: string;
  productLines: string[];
  products: string[];
  value: number;
}

class ModalAddCondition extends Component<
  IModalAddConditionProps,
  IModalAddConditionState
  > {
  containerRef: any;

  constructor(props: IModalAddConditionProps) {
    super(props);
    this.state = {
      openedPrizes: [true],
      conditions: [
        [
          {
            unit: props.isContest ? 'POINTS' : 'PREMIUM',
            productLines: [],
            products: [],
            value: NaN
          }
        ]
      ],
      errors: [[]]
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const { conditions } = this.props;
    document.addEventListener("click", this.handleClickOutside.bind(this));
    if (conditions && conditions.length > 0) {
      const openedPrizes = new Array(conditions.length).fill(false);
      this.setState({ conditions, openedPrizes });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  isVisible(e: any) {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
  }

  handleClickOutside(ev: any) {
    const { close } = this.props;
    this.isVisible(ev.target) &&
      this.containerRef.current &&
      !this.containerRef.current.contains(ev.target) &&
      close();
  }

  addMore(index: number) {
    const { isContest } = this.props;
    const { conditions } = this.state;
    conditions[index].push({
      unit: isContest ? 'POINTS' : 'PREMIUM',
      productLines: [],
      products: [],
      value: NaN
    });
    this.setState({ conditions });
  }

  removeCondition(ev: any, prizeIndex: number, index: number) {
    const { conditions } = this.state;
    conditions[prizeIndex].splice(index, 1);
    this.setState({ conditions });
  }

  handleChange(
    prizeIndex: number,
    index: number,
    field: string,
    value: string | number | string[]
  ) {
    const { conditions } = Object.assign(this.state);
    if (field === "productLines") {
      conditions[prizeIndex][index].products = [];
    }
    conditions[prizeIndex][index][field] = value;
    this.setState({ conditions });
  }

  handleSave() {
    const { onSave } = this.props;
    const { conditions, errors } = this.state;
    let hasError = false;
    conditions.forEach((cond, index) => {
      const err: any = [];
      cond.forEach((condition, index) => {
        err.push({
          unit: "",
          productLines: "",
          products: "",
          value: ""
        });
        if (!condition.unit || condition.unit === "") {
          err[index].unit = "required";
          hasError = true;
        }
        if (!condition.productLines || condition.productLines.length === 0) {
          err[index].productLines = "required";
          hasError = true;
        }
        if (!condition.products || condition.products.length === 0) {
          err[index].products = "required";
          hasError = true;
        }
        if (!condition.value) {
          err[index].value = "required";
          hasError = true;
        }
        if (condition.value <= 0) {
          err[index].value = "positive-number-error";
          hasError = true;
        }
      });
      errors[index] = err;
    });
    this.setState({ errors });
    if (!hasError) {
      onSave(conditions);
    }
  }

  toogleAccordion(index: number) {
    const { openedPrizes } = this.state;
    openedPrizes[index] = !openedPrizes[index];
    this.setState({ openedPrizes });
  }

  render() {
    const { close, title } = this.props;
    const { conditions, errors, openedPrizes } = this.state;
    const isContest = true;
    return (
      <ModalAddConditionContainer>
        <div className="modal-add-condition-aux-container">
          <div
            className="modal-add-condition-container"
            ref={this.containerRef}
          >
            <div className="modal-add-condition-close">
              <img src={images.IconCloseGrey} onClick={() => close()} />
            </div>
            <div className="modal-add-condition-step">{"Condiciones"}</div>
            <div className="modal-add-condition-header">
              <div className="modal-add-condition-header-title">
                <div className="modal-add-condition-header-title__icon">
                  <img src={images.IconClientsBlue} />
                </div>
                <div className="modal-add-condition-header-title__text">
                  <H4Text>{title}</H4Text>
                </div>
              </div>
            </div>
            <div className="modal-add-condition-wrapper">
              <div className="modal-add-condition-subtitle">
                Defina el premio del objetivo de la campaña.
              </div>
              <div className="modal-add-condition-main">
                {conditions.map((cond, prizeIndex) => (
                  <>
                    {conditions.length > 1 && isContest && (
                      <div
                        className="modal-add-condition-accordion"
                        onClick={() => this.toogleAccordion(prizeIndex)}
                      >
                        <div className="modal-add-condition-accordion__title">
                          {`${prizeIndex + 1}º PREMIO`}
                        </div>
                        <div
                          className={`modal-add-condition-accordion__arrow ${
                            openedPrizes[prizeIndex] ? "opened" : ""
                            }`}
                        >
                          <img src={images.IconArrowDownBlue} />
                        </div>
                      </div>
                    )}
                    {(openedPrizes[prizeIndex] || conditions.length === 1) &&
                      cond.map((condition, index) => {
                        let productOptions: any = [];
                        if (condition.productLines.includes("life"))
                          productOptions = productOptions.concat(
                            LIFE_PRODUCTS_DROPDOWN
                          );
                        if (condition.productLines.includes("pet"))
                          productOptions = productOptions.concat(
                            PET_PRODUCTS_DROPDOWN
                          );
                        if (condition.productLines.includes("death"))
                          productOptions = productOptions.concat(
                            DEATH_PRODUCTS_DROPDOWN
                          );
                        return (
                          <>
                            <div className={"modal-add-condition-main__title"}>
                              <div> {`Condición ${index + 1}`}</div>
                              {index > 0 && (
                                <img
                                  src={images.IconEraseBlue}
                                  onClick={ev => {
                                    this.removeCondition(ev, prizeIndex, index);
                                  }}
                                />
                              )}
                            </div>
                            <div
                              className={`${
                                conditions.length > 1 && isContest
                                  ? "modal-col-50"
                                  : "modal-col-100"
                                }`}
                            >
                              <div
                                className={"modal-add-condition-main__field"}
                              >
                                <SelectBox
                                  className={"rounded"}
                                  optionsText={this.props.isContest ? optionsContestUnits : optionsIncentiveUnits}
                                  optionKey={"value"}
                                  optionValue={"label"}
                                  disabled={true}
                                  defaultValue={condition.unit}
                                  initialValue={condition.unit}
                                  errorCode={
                                    errors[prizeIndex] &&
                                      errors[prizeIndex][index]
                                      ? errors[prizeIndex][index].unit
                                      : ""
                                  }
                                  onChange={value =>
                                    this.handleChange(
                                      prizeIndex,
                                      index,
                                      "unit",
                                      value
                                    )
                                  }
                                  placeholder={"Unidad del premio"}
                                />
                              </div>
                              <div
                                className={"modal-add-condition-main__field"}
                              >
                                <SelectBox
                                  className={"rounded"}
                                  optionsText={PRODUCT_BRANCHES_DROPDOWN}
                                  optionKey={"value"}
                                  optionValue={"label"}
                                  multiple={true}
                                  selectedMultipleOptions={
                                    condition.productLines
                                  }
                                  errorCode={
                                    errors[prizeIndex] &&
                                      errors[prizeIndex][index]
                                      ? errors[prizeIndex][index].productLines
                                      : ""
                                  }
                                  onMultipleChange={values =>
                                    this.handleChange(
                                      prizeIndex,
                                      index,
                                      "productLines",
                                      values
                                    )
                                  }
                                  onChange={() => { }}
                                  placeholder={"Ramo"}
                                />
                              </div>
                            </div>
                            <div
                              className={`${
                                conditions.length > 1 && isContest
                                  ? "modal-col-50"
                                  : "modal-col-100"
                                }`}
                            >
                              {productOptions.length > 0 && (
                                <div
                                  className={"modal-add-condition-main__field"}
                                >
                                  <SelectBox
                                    className={"rounded"}
                                    optionsText={productOptions}
                                    optionKey={"value"}
                                    optionValue={"label"}
                                    multiple={true}
                                    selectedMultipleOptions={condition.products}
                                    errorCode={
                                      errors[prizeIndex] &&
                                        errors[prizeIndex][index]
                                        ? errors[prizeIndex][index].products
                                        : ""
                                    }
                                    onMultipleChange={values =>
                                      this.handleChange(
                                        prizeIndex,
                                        index,
                                        "products",
                                        values
                                      )
                                    }
                                    onChange={() => { }}
                                    placeholder={"Producto"}
                                  />
                                </div>
                              )}

                              <div
                                className={"modal-add-condition-main__field"}
                              >
                                <InputBox
                                  type="number"
                                  errorCode={
                                    errors[prizeIndex] &&
                                      errors[prizeIndex][index]
                                      ? errors[prizeIndex][index].value
                                      : ""
                                  }
                                  onChange={value =>
                                    this.handleChange(
                                      prizeIndex,
                                      index,
                                      "value",
                                      parseFloat(value)
                                    )
                                  }
                                  value={
                                    condition.value
                                      ? condition.value.toString()
                                      : ""
                                  }
                                  className={"rounded"}
                                  placeholder={"Valor"}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                    {(openedPrizes[prizeIndex] || conditions.length === 1) && (
                      <div
                        className="modal-add-condition-more"
                        onClick={() => this.addMore(prizeIndex)}
                      >
                        {"+ Añadir mas"}
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
            <div className="modal-add-condition-buttons">
              <div className="modal-add-condition-buttons__item">
                <MainButton
                  text={"Cancelar"}
                  type={"white"}
                  onClick={() => close()}
                />
              </div>
              <div className="modal-add-condition-buttons__item">
                <MainButton
                  text={"Aceptar"}
                  onClick={() => this.handleSave()}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalAddConditionContainer>
    );
  }
}

export default ModalAddCondition;
