import styled from "styled-components";
import { colors } from "../../../../assets";

export const PriceDataContainer = styled.div`
  .data-flow-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  .content-wrapper {
    overflow: initial;
    padding-right: 16px;
    padding: 25px 20px;
    background-color: #fbfbfb;
    width: 100%;
    display: block;
    float: left;
  }
  @media (min-width: 320px) {
    .content-wrapper {
      width: 100% !important;
    }
  }
  @media (min-width: 1201px) {
    .content-wrapper {
      width: 100% !important;
    }
  }
  .data-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 24px;
  }

  .radioBoxButton {
    label {
      position: relative;
      padding: 32px 6px;
      border-radius: 6px;
      display: inline-block;
      cursor: pointer;
      margin: 8px 0;
      text-transform: uppercase;
      color: #3f3f3f;
      -webkit-tap-highlight-color: transparent;
      width: 100%;
      height: 148px;
      background-color: #fff;
      text-align: center;
      border: 1px solid #ddd;
      transition: all 300ms;
      overflow: visible;

      &:hover {
        box-shadow: 0 5px 10px #d8d8d8;
        z-index: 1;
      }

      input {
        vertical-align: middle;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: none;
        border: 0;
        background-color: #fff;
        box-shadow: inset 0 0 0 1px #3f3f3f;
        appearance: none;
        padding: 0;
        margin: 0;
        transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
        pointer-events: none;

        &:focus {
          outline: none;
        }

        &:checked {
          box-shadow: inset 0 0 0 6px #004a8f;

          ~ span {
            font-weight: 600;
            color: #004a8f;
          }

          ~ span:nth-of-type(2) {
            padding-top: 25px;
            font-size: 30px;
            color: #004a8f;
          }

          ~ i {
            border: 1px solid #004a8f;
            box-shadow: 0 5px 10px #d8d8d8;
          }
        }
      }

      span {
        vertical-align: middle;
        display: inline-block;
        line-height: 20px;
        padding: 0 8px;
        font-size: 16px;
        transition: all 300ms;
      }

      span:nth-of-type(2) {
        display: block;
        padding-top: 25px;
        font-size: 24px;
      }

      i {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        border: 1px solid transparent;
        border-radius: 6px;
        text-transform: initial;
        padding-top: 115px;
        font-style: normal;
        font-size: 14px;
        color: #f90;
      }
    }
  }

  .offersCard {
    position: relative;
    width: 100%;
    height: 230px;
    background-color: #fff;
    border-radius: 6px;
    display: block;
    cursor: pointer;
    transition: all 300ms;
    overflow: visible;
    margin-bottom: 10px;

    &:hover {
      box-shadow: 0 5px 10px #d8d8d8;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        ~ .checkmark {
          &:after {
            display: block;
          }
        }

        ~ h5 {
          background-color: #004a8f;
          color: #fff;
          font-weight: 700;
        }

        ~ i {
          box-shadow: 0 5px 10px #d8d8d8;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 23px;
      left: 16px;
      height: 24px;
      width: 24px;
      background-color: #fff;
      border: 1px solid #004a8f;
      border-radius: 3px;
      transition: all 300ms;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid #004a8f;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    h5 {
      color: rgb(0, 74, 143);
      text-align: center;
      height: 91px;
      line-height: 35px;
      border-radius: 6px 6px 0px 0px;
      margin: 0px;
      transition: all 300ms ease 0s;
      font-weight: 300;
      white-space: initial;
      padding-inline: 10px;
    }

    p {
      font-size: 18px;
      padding: 20px 25px;
      text-align: center;
      white-space: pre-wrap;
      line-height: 24px;
    }

    i {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      transition: all 300ms;
    }
  }
  .campaingTitle{
    border-radius: 10px 10px 0px 0px;
    padding-block: 15px;
      padding-inline: 47px;
      background-color: rgb(0, 74, 143);
      color: white;
      h5 {
        color: white;
      }
  }
  .campaingTitleDisabled{
    border-radius: 10px 10px 0px 0px;
    padding-block: 15px;
    padding-inline: 47px;
    background-color: rgb(244, 244, 244);
    h5 {
      color: rgb(0, 74, 143);
    }
  }
  .offersCard.disabled {
    filter: contrast(50%) brightness(135%) grayscale(1);
    pointer-events: none;
  }
`;
