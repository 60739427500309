interface productDocumentType {
    "Documento-cotizacion": string;
    "Solicitud-Seguro": string;
    "Nota-Informativa": string;
    "DIP": string;
    "Condiciones-Generales": string;
    "Tarjeta-Sanitaria": string;
    "Documento-Servicio-Valor-Añadido": string;
    [key: string]: string;
}

export const PRODUCT_DOCUMENT_TYPE: productDocumentType = {
    "Documento-cotizacion": "Documento de cotización",
    "Solicitud-Seguro": "Solicitud del Seguro",
    "Nota-Informativa": "Nota Informativa",
    "DIP": "DIP",
    "Condiciones-Generales": "Condiciones Generales y especiales",
    "Tarjeta-Sanitaria": "Tarjeta Sanitaria",
    "Documento-Servicio-Valor-Añadido": "Documento Servicio Valor Añadido"
};

interface personDocumentType {
    "Documento-identificativo-cliente": string;
    "Justificante-pertenencia": string;
    "Cuestionario-Salud": string;
    "Informacion-complementaria": string;
    "Peticion-Informacion-medica": string;
    "Informacion-medica": string;
    "SEPA-Mandato": string;
    [key: string]: string;
}

export const PERSON_DOCUMENT_TYPE: personDocumentType = {
    "Documento-identificativo-cliente": "Documento identificativo del cliente",
    "Justificante-pertenencia": "Justificante pertenencia",
    "Cuestionario-Salud": "Cuestionario de Salud",
    "Informacion-complementaria": "Información complementaria",
    "Peticion-Informacion-medica": "Petición Información médica",
    "Informacion-medica": "Información médica",
    "SEPA-Mandato": "SEPA – Mandato"
};

interface policyDocumentType {
    "Condiciones-Generales-especiales": string;
    "Condiciones-Particulares": string;
    "Renovacion-Condiciones": string;
    "Certificado-de seguro": string;
    [key: string]: string;
}

export const POLICY_DOCUMENT_TYPE: policyDocumentType = {
    "Condiciones-Generales-especiales": "Condiciones Generales y especiales",
    "Condiciones-Particulares": "Condiciones Particulares",
    "Renovacion-Condiciones": "Renovación Condiciones",
    "Certificado-de seguro": "Certificado de seguro"
};

interface proposalDocumentType {
    "Solicitud-seguro": string;
    "Solicitud-alta-asegurados": string;
    "Solicitud-Baja-poliza": string;
    "Solicitud-Baja-Asegurado": string;
    "Solicitud-cambio-tomador": string;
    "Solicitud-modificacion-datos": string;
    [key: string]: string;
}

export const PROPOSAL_DOCUMENT_TYPE: proposalDocumentType = {
    "Solicitud-seguro": "Solicitud de seguro",
    "Solicitud-alta-asegurados": "Solicitud de alta de asegurados",
    "Solicitud-Baja-poliza": "Solicitud Baja póliza",
    "Solicitud-Baja-Asegurado": "Solicitud Baja Asegurado",
    "Solicitud-cambio-tomador": "Solicitud cambio tomador",
    "Solicitud-modificacion-datos": "Solicitud modificación datos"
};

interface signatureType {
    "Manuscrita": string;
    "Digital": string;
    [key: string]: string;
}

export const SIGNATURE_TYPE: signatureType = {
    "Manuscrita": "Manuscrita",
    "Digital": "Digital"
};

interface areaName {
    "Salud": string;
    "Vida": string;
    "Accidentes": string;
    "Hospitalizacion": string;
    "Decesos": string;
    "Mascotas": string;
    "Viajes": string;
    [key: string]: string;
}

export const AREA_NAME: areaName = {
    "Salud": "Salud",
    "Vida": "Vida",
    "Accidentes": "Accidentes",
    "Hospitalizacion": "Hospitalización",
    "Decesos": "Decesos",
    "Mascotas": "Mascotas",
    "Viajes": "Viajes"
};

interface securityLevel {
    "1": string;
    "2": string;
    "3": string;
    "4": string;
    [key: string]: string;
}

export const SECURITY_LEVEL: securityLevel = {
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4"
};

interface originName {
    "Generado-por-el-sistema": string;
    "Proporcionado-por-el-cliente": string;
    [key: string]: string;
}

export const ORIGIN_NAME: originName = {
    "Generado-por-el-sistema": "Generado por el sistema",
    "Proporcionado-por-el-cliente": "Proporcionado por el cliente"
};

interface asisaGroup {
    "PORTADAS-Y-PRIMERAS-HOJAS": string;
    "TARJETAS-Y-CD-(SOLO-CUANDO-ES-ENVIO-FISICO)": string;
    "CONDICIONES-GENERALES-Y-ESPECIALES": string;
    "DOCUMENTOS-INFORMATIVOS": string;
    "CARTAS-BIENVENIDO": string;
    "DOCUMENTOS-A-CUMPLIMENTAR": string;
    "PUBLICIDAD": string;
    "OTROS": string;
    [key: string]: string;
}

export const ASISA_GROUP: asisaGroup = {
    "PORTADAS-Y-PRIMERAS-HOJAS": "PORTADAS Y PRIMERAS HOJAS",
    "TARJETAS-Y-CD-(SOLO-CUANDO-ES-ENVIO-FISICO)": "TARJETAS Y CD (SOLO CUANDO ES ENVIO FISICO)",
    "CONDICIONES-GENERALES-Y-ESPECIALES": "CONDICIONES GENERALES Y ESPECIALES",
    "DOCUMENTOS-INFORMATIVOS": "DOCUMENTOS INFORMATIVOS",
    "CARTAS-BIENVENIDO": "CARTAS BIENVENIDO",
    "DOCUMENTOS-A-CUMPLIMENTAR": "DOCUMENTOS A CUMPLIMENTAR",
    "PUBLICIDAD": "PUBLICIDAD",
    "OTROS": "OTROS"
};

interface asisaItem {
    "PRIMERA-HOJA-PACK": string;
    "PRIMERA-HOJA-PACK-CATALAN": string;
    "HOJA-TARJETAS-SALUD": string;
    "HOJA-TARJETAS-DENTAL": string;
    "HOJA-INDICE": string;
    "HOJA-INDICE-CATALAN": string;
    "HOJA-CD-ASISA-SALUD": string;
    "HOJA-CD-ASISA-SALUD-Y-DENTAL-SEVILLA": string;
    "HOJA-CD-ASISA-DENTAL": string;
    "HOJA-CD-ASISA-SALUD-Y-DENTAL": string;
    "HOJA-CD-ASISA-SALUD-Y-DENTAL-CATALAN": string;
    "CONDICIONES-ESPECIALES-HISDESAT": string;
    "CONDICIONES-ESPECIALES-REEMBOLSO-CAJA-DE-CREDITO-DE-INGENIEROS": string;
    "CONDICIONES-ESPECIALES-SALUD-CAJA-DE-CREDITO-DE-INGENIEROS": string;
    "CONDICIONES-ESPECIALES-GRUPO-MGO": string;
    "HOJA-DE-CD-ASISA-SALUD-Y-DENTAL-A-CORUÑA": string;
    "CONDICIONES-ESPECIALES-IBERMUTUATUR": string;
    "CONDICIONES-ESPECIALES-BANCO-POPULAR": string;
    "CONDICIONES-ESPECIALES-MOLNLYCKE-HEALT-CARE": string;
    "ANEXO-MODIFICATIVO-HOLCIM": string;
    "FRANQUICIAS-GRUPO-TRAGSA": string;
    "CONDICIONES-ESPECIALES-ALPARGATAS-EUROPE-SLU": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-INAER": string;
    "CONDICIONES-PARTICULARES-COLECTIVO-INAER": string;
    "CONDICIONES-ASISA-INTEGRAL-BANCA-MARCH": string;
    "DOCUMENTO-2-ANEXO-SISTEMAS-INFORMATICOS-ABIERTOS": string;
    "CONDICIONES-PARTICULARES-COLECTIVO-SIA": string;
    "CONDICIONES-ESPECIALES-GRUPO-PUMA": string;
    "DOCUMENTO-1-ANEXO-SISTEMAS-INFORMATICOS-ABIERTOS": string;
    "CONDICIONES-ESPECIALES-ALLEGRO-SYSTEM": string;
    "CUADRO-LIMITES-REEMBOLSO-1.000.000-€": string;
    "CONDICIONES-ESPECIALES-INTEGRAL-PWC": string;
    "SOLICITUD-ADHESION-AYUNTAMIENTO-SEVILLA": string;
    "CONDICIONES-GENERALES-ASISA-SALUD-BANCO-DE-SANTANDER": string;
    "NOTA-INFORMATIVA-BANCO-DE-SANTANDER": string;
    "HOJA-TARJETA-ASISA-SALUD-BANCO-DE-SANTANDER": string;
    "ASISTENCIA-EN-VIAJE-BANCO-DE-SANTANDER": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-FAMILIAR-BANCO-SANTANDER": string;
    "PRIMERA-HOJA-PACK-BANCO-SANTANDER": string;
    "CONDICIONES-GENERALES-ASISA-SALUD-BANCO-DE-SANTANDER-IGUALATORIO": string;
    "HOJA-TARJETA-ASISA-SALUD-BANCO-DE-SANTANDER-IGUALATORIO": string;
    "ASISTENCIA-EN-VIAJE-BANCO-DE-SANTANDER-IGUALATORIO": string;
    "CONDICIONES-ESPECIALES-BEIERSDORF": string;
    "CONDICIONES-PARTICULARES-GENERICAS-PROVISIONALES-PACK": string;
    "CONDICIONES-GENERALES-ASISA-ESENCIAL-BANCO-DE-SANTANDER": string;
    "CONDICIONES-GENERALES-ASISA-ESENCIAL-BANCO-DE-SANTANDER-IGUALATORIO": string;
    "CUESTIONARIO-DE-SALUD-AYUNTAMIENTO-DE-SEVILLA": string;
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-COLECTIVOS": string;
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-COLECTIVOS-EN-INGLES": string;
    "CONDICIONES-ESPECIALES-SALUD-PWC": string;
    "CONDICIONES-ESPECIALES-DEUTSCHE-PFANDBRIEFBANK-AG": string;
    "CUADRO-LIMITES-REEMBOLSO-INAER": string;
    "REEMBOLSO-DE-GASTOS-FARMACEUTICOS-SIA": string;
    "HOJA-FELIZ-AÑO-NUEVO---NAVIDAD": string;
    "HOJA-TARJETAS-SALUD-BANCO-SANTANDER-EXCLUSIVE": string;
    "CONDICIONES-GENERALES-SANTANDER-EXCLUSIVE": string;
    "CONDICIONES-GENERALES-BANCO-DE-SANTANDER-EMPRESAS": string;
    "CONDICIONES-ESPECIALES-GRUPO-RTVE": string;
    "CONDICIONES-ESPECIALES-CITI": string;
    "OPERATIVA-SOLICITUD-REEMBOLSO-DE-GASTOS-CITI": string;
    "OPERATIVA-SOLICITUD-REEMBOLSO-DE-GASTOS": string;
    "CONDICIONES-ESPECIALES-INDRA": string;
    "CONDICIONES-ESPECIALES-LABORATORIOS-INDAS": string;
    "NOTA-INFORMATIVA-BANCO-DE-SANTANDER-EMPRESAS": string;
    "HOJA-SEGURO-ROTURA-PROTESIS-MAMARIA": string;
    "CERTIFICADO-SEGURO-ROTURA-DE-PROTESIS-MAMARIA": string;
    "CONDICIONES-GENERALES-SEGURO-ROTURA-DE-PROTESIS-MAMARIAS": string;
    "GARANTIA-DE-ASESORAMIENTO-DE-ASISER": string;
    "SOLICITUD-DE-SEGURO-COLECTIVO-FAMILIARES-DE-EMPLEADOS-FECYT": string;
    "CUESTIONARIO-DE-SALUD-COLECTIVO-FAMILIARES-DE-EMPLEADOS-DE-FECYT": string;
    "SOLICITUD-DE-SEGURO-COLECTIVOS-PACK": string;
    "HOJA-TARJETA-DENTAL-FAMILIAR-BANCO-SANTANDER": string;
    "CONDICIONES-GENERALES-ASISA-SALUD-BANCO-SANTANDER-EMPLEADOS": string;
    "CONDICIONES-ESPECIALES-ASISA-VIDA-RETORNO": string;
    "FRANQUICIAS-DENTALES-PLUS-BANCO-DE-SANTANDER": string;
    "FRANQUICIAS-DENTAL-ASISA-FAMILIAR-BANCO-SANTANDER": string;
    "HOJA-AGENTES-CORPORACION-DIRECTA": string;
    "HOJA-TARJETAS-CORPORACION-DIRECTA": string;
    "CONDICIONES-GENERALES-ASISA-VIDA-RETORNO": string;
    "PRIMERA-HOJA-ASISA-DENTAL": string;
    "PRIMERA-HOJA-ASISA-DENTAL-BANCO-SANTANDER": string;
    "CONDICIONES-GENERALES-ASISA-SALUD-BANCO-DE-SANTANDER-V2": string;
    "CONDICIONES-ESPECIALES-INTEGRAL-ENRESA": string;
    "FRANQUICIAS-DENTALES-ESPECIALES-ENRESA": string;
    "CARTA-GARANTIAS-ADICIONALES-ASISA-SALUD-BEIERSDORF": string;
    "GUIA-OPERATIVA-GASTOS-DE-SEPELIO": string;
    "CONDICIONES-ESPECIALES-SALUD-ENRESA": string;
    "HOJA-PROTOCOLO-ACCESO-CLINICA-UNIVERSITARIA-DE-NAVARRA": string;
    "CARTA-ASISTENCIAL": string;
    "CARTA-COBERTURA-REPATRIACION-POR-FALLECIMIENTO": string;
    "HOJA-AGENTES-BARYMONT": string;
    "HOJA-CD-ASISA-SALUD-ISFAS": string;
    "HOJA-TARJETA-ASISA-ORO-PROFESIONALES": string;
    "PROGRAMA-DE-VENTAJAS-PROFESIONALES-LAVINIA": string;
    "SERVICIO-DE-ASISTENCIA-LEGAL": string;
    "SEGUNDA-OPINION-MEDICA": string;
    "ASISTENCIA-EN-VIAJES-PARA-MEDICOS": string;
    "NUEVAS-CONDICIONES-GENERALES-ASISA-HOSPITALIZACION-PROFESIONALES": string;
    "CONDICIONES-ESPECIALES-ASISA-INEGRAL-180.000-FAURECIA": string;
    "HOJA-SERVICIO-POSTVENTA-FAURECIA-SALUD": string;
    "HOJA-SERVICIO-POSVENTA-FAURECIA-REEMBOLSO": string;
    "PLAN-DE-PENSIONES-COOPERATIVISTAS-LAVINIA": string;
    "HOJA-TARJETA-SALUD-FAM-CAMINOS": string;
    "EXTRACTO-CONTRATO-POLIZA-FAM-CAMINOS": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-PLUS-BANCO-SANTANDER": string;
    "HOJA-TARJETAS-SALUD-BANCA-MARCH": string;
    "FRANQUICIA-DENTAL-BANCO-SANTANDER": string;
    "CONDICIONES-ESPECIALES-GRUPO-DTA": string;
    "CONDICIONES-ESPECIALES-ELECTRICA-DE-CADIZ": string;
    "CONDICIONES-ESPECIALES-DEISER": string;
    "CONDICIONES-ESPECIALES-ASOCIACION-DE-TECNICOS-Y-ECONOMISTAS-DEL-ESTADO": string;
    "CONDICIONES-ESPECIALES-PARTIDO-POPULAR": string;
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-MIJAS": string;
    "CLAUSULA-ENFERMEDADES-GRAVES-ASISA-COMO-CUESTIONARIO": string;
    "CONDICIONES-ESPECIALES-BANGES": string;
    "CONDICIONES-ESPECIALES-HUSQVARNA": string;
    "DOCUMENTO-CIRUGIA-AMBULATORIA-MARTINEZ-SOLE": string;
    "COBERTURA-REEMBOLSO-GASTOS-FARMACEUTICOS": string;
    "GARANTIAS-ESPECIALES-COLECTIVOS-MIJAS": string;
    "EXTRACTO-RESUMEN-POLIZA-FAM-CAMINOS": string;
    "SOLICITUD-DE-HOSPITALIZACION": string;
    "SOLICITUD-ACCIDENTES-PLUS": string;
    "SOLICITUD-ACCIDENTES": string;
    "HOJA-DE-TARJETA-DE-CAJA-DE-INGENIEROS": string;
    "CONDICIONES-ESPECIALES-ROURA-CEVASA": string;
    "CONDICIONES-ESPECIALES-LEASBROK": string;
    "HOJA-TARJETA-START-SALUD-DENTAL": string;
    "FRANQUICIAS-DENTALES-START-SALUD": string;
    "CONDICIONES-ESPECIALES-FOREIGN": string;
    "CONDICIONES-ESPECIALES-ALPHANUMERIC": string;
    "HOJA-CD-ASISA-SALUD-BANCA-MARCH": string;
    "DETALLE-COBERTURAS-FUNDACION-UNIVERSIDAD.ES": string;
    "REPATRIACION-FUNDACION-UNIVERSIDAD.ES": string;
    "INSTRUCCIONES-ASEGURADOS-FUNDACION-UNIVERSIDAD.ES": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-3": string;
    "CONDICIONES-GENERALES-ASISA-MOMENTO": string;
    "CUESTIONARIO-GALP-ENERGIA": string;
    "COBERTURAS-DESTACADAS-AUTORIDAD-PORTUARIA-DE-LAS-PALMAS": string;
    "SOLICITUD-DE-SEGURO-AUTORIDAD-PORTUARIA-DE-LAS-PALMAS": string;
    "CUESTIONARIO-DE-SALUD-AUTORIDAD-PORTUARIA-DE-LAS-PALMAS": string;
    "CONDICIONES-ESPECIALES-YECLA": string;
    "CONDICIONES-GENERALES-ASISA-INTERNACIONAL": string;
    "CONDICIONES-GENERALES-ASISA-INTERNACIONAL-EN-INGLES": string;
    "CONDICIONES-ESPECIALES-ASISA-INTERNACIONAL-AMPLIADO": string;
    "CONDICIONES-ESPECIALES-ASISA-INTERNACIONAL-AMPLIADO-2015-EN-INGLES": string;
    "CONDICIONES-ESPECIALES-ASISA-INTERNACIONAL-BASICO": string;
    "CONDICIONES-ESPECIALES-ASISA-INTERNACIONAL-BASICO-EN-INGLES": string;
    "CONDICIONES-ESPECIALES-ASISA-INTERNACIONAL-INTEGRAL": string;
    "CONDICIONES-ESPECIALES-ASISA-INTERNACIONAL-INTEGRAL-EN-INGLES": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-TIBIDABO": string;
    "CONDICIONES-ESPECIALES-YUME": string;
    "SOLICITUD-DE-SEGURO-ASISA-INTERNACIONAL": string;
    "FRANQUICIA-DENTAL-BENEFITS": string;
    "FRANQUICIAS-ASISA-DENTAL-PURO-FAM-CAMINOS": string;
    "GARANTIAS-ADICIONALES-SATISFACCION-GRUPO-VECTOR": string;
    "FAQ-MUTUALISTAS": string;
    "COBERTURAS-DESTACADAS-UNIVERSIDAD-INTERNACIONAL-DE-ANDALUCIA": string;
    "SERVICIO-DE-URGENCIAS-Y-EMERGENCIAS": string;
    "CONDICIONES-ESPECIALES-PARA-GRUPO-IRIZAR": string;
    "CONDICIONES-GENERALES-GRUPO-IRIZAR": string;
    "CONDICIONES-ESPECIALES-GRUPO-NISSAN": string;
    "CONDICIONES-ESPECIALES-EQMEDIAXL": string;
    "CODIGO-QR-CUADRO-MEDICO-SALUD": string;
    "CONDICIONES-ESPECIALES-GASTOS-FARMACEUTICOS": string;
    "CONDICIONES-ESPECIALES-REYAL-URBIS": string;
    "TEXTO-PROMOCION-4-MESES-GRATIS-2015": string;
    "CONDICIONES-GENERALES-ASISA-ACTIVA": string;
    "CONDICIONES-GENERALES-ASISA-ACTIVA-EN-INGLES": string;
    "CONDICIONES-ESPECIALES-SMS-1-MILLON": string;
    "HOJA-DE-TARJETAS-IBERMAN": string;
    "CONDICIONES-ESPECIALES-DIPUTACIÓN-DE-CADIZ": string;
    "ATENCION-PERSONALIZADA-A-LOS-ASEGURADOS-DIPUTACION-DE-CADIZ": string;
    "HOJA-TARJETAS-SALUD-DIPUTACION-CADIZ": string;
    "OFERTA-DIPUTACION-DE-CADIZ": string;
    "FRANQUICIAS-DENTAL-PARA-SALUD-+-GARANTIA-FAM-CAMINOS": string;
    "GARANTIAS-COMPLEMENTARIAS-DE-FARMACIA-SKY-SOLAR": string;
    "GARANTIAS-ADICIONALES-INTEGRAL-VECTOR": string;
    "CONDICIONES-ESPECIALES-GRUPO-SEPIDES": string;
    "EXCLUSION-DE-PARTOS-COBERTURAS-DE-SALUD": string;
    "REEMBOLSO-DE-FARMACIA-PARA-GENERICOS": string;
    "FRANQUICIAS-GRUPO-SEPIDES": string;
    "CODIGO-QR-CUADRO-MEDICO-DENTAL": string;
    "HOJA-EDA-PARA-APP": string;
    "HOJA-EDA-PARA-APP-IMAGEN-ANTIGUA": string;
    "HOJA-EDA-PARA-APP-CATALAN": string;
    "CONDICIONADO-GENERAL-POLIZA-ASISTENCIA-SANITARIA-BASIC": string;
    "CONDICIONES-GENERALES-SHELL-CLUBSMART": string;
    "PRIMERA-HOJA-PACK-CORTE-INGLES": string;
    "FRANQUICIAS-POLIZA-DENTAL-FA-EMPRESAS-PACK-CORTE-INGLES": string;
    "CONDICIONES-ESPECIALES-GRUPO-SEPI": string;
    "CONDICIONES-ESPECIALES-GRUPO-SEPI-JUBILADOS": string;
    "CONDICIONES-GENERALES-ASISA-MASTER-PACK-CORTE-INGLES": string;
    "HOJA-TARJETAS-PUMA": string;
    "CONDICIONES-ESPECIALES-GRUPO-DRAGADOS": string;
    "CONDICIONES-GENERALES-REPATRIACION-POR-FALLECIMIENTO": string;
    "CONDICIONES-GENERALES-REPATRIACION-POR-FALLECIMIENTO-INGLES": string;
    "CONDICIONES-GENERALES-REPATRIACIÓN-POR-FALLECIMIENTO-Y-ENFERMEDAD-O-ACCIDENTE-GRAVE": string;
    "CONDICIONES-GENERALES-REPATRIACION-POR-FALLECIMIENTO,-ENFERMEDAD-Y-ACCIDENTE-GRAVE-INGLES": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-PORTUGAL": string;
    "CONDICIONES-PARTICULARES-ASISA-DENTAL-PORTUGAL": string;
    "SOBRE-RESPUESTA-DIGITAL": string;
    "ORIENTACION-TELEFONICA-DENTAL-PORTUGAL": string;
    "ACCESO-ASISTENCIA-MEDICA-ESPAÑA-DENTAL-PORTUGAL": string;
    "ASISTENCIA-URGENTE-DENTAL-PORTUGAL": string;
    "GUIA-RAPIDA-DENTAL-PORTUGAL": string;
    "RAZONES-PARA-CONFIAR-EN-ASISA-DENTAL-PORTUGAL": string;
    "RED-CLINICAS-DENTAL-PORTUGAL": string;
    "PRIMERA-HOJA-ASISER-CARD": string;
    "CONDICIONES-GENERALES-ASISER-CARD": string;
    "CONDICIONES-PARTICULARES-ASISER-CARD": string;
    "ASISER-CARD-CONTIGO": string;
    "CONDICIONES-PARTICULARES-SISNET": string;
    "NOTA-INFORMATIVA-DENTAL-PORTUGAL-APLICALIA": string;
    "CONDICIONES-PARTICULARES-ASISA-DENTAL-PORTUGAL-APLICALIA": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-PORTUGAL-APLICALIA": string;
    "HOJA-TARJETAS-DENTAL-PORTUGAL": string;
    "LIBRO-AUXILIAR-DENTAL-PORTUGAL-APLICALIA": string;
    "UNA-MEJOR-PROTECCION": string;
    "INSTRUCCIONES-PARA-TRAMITACION-DEL-REEMBOLSO-MEYSS": string;
    "CONDICIONES-ESPECIALES-MEYSS": string;
    "SOLICITUD-REEMBOLSO-GASTOS-MEYSS": string;
    "FRANQUICIAS-POLIZA-DENTAL-SALUDCOR": string;
    "COBERTURA-REEMBOLSO-GASTOS-FARMACEUTICOS-AEBIA-TECNOLOGIA": string;
    "PRIMERA-HOJA-SALUDCOR-TE-DAMOS-LA-BIENVENIDA": string;
    "DESCARGAR-CUADRO-MEDICO-SALUDCOR": string;
    "HOJA-APP-SALUDCOR": string;
    "CONTRAPORTADA-SALUDCOR-CESS": string;
    "CONTRAPORTADA-SALUDCOR-SECI": string;
    "HOJA-TARJETAS-ASISTENCIA-MEDICA-SALUDCOR": string;
    "HOJA-TARJETAS-ASISTENCIA-DENTAL-SALUDCOR": string;
    "HOJA-CD-CUADRO-MEDICO-SALUDCOR": string;
    "CONDICIONES-GENERALES-SECI-PACK": string;
    "CONDICIONES-GENERALES-SEGURO-HOSPITALIZACION-SECI-PACK": string;
    "CONDICIONES-GENERALES-ASISA-MASTER-PACK-SECI": string;
    "HOJA-NOTAS-SALUDCOR": string;
    "CONDICIONES-GENERALES-ASISA-PROXIMA-PACK-BANCA-MARCH": string;
    "CONDICIONES-GENERALES-SALUD-ASISA-SATISFACCION-PLUS": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-BANCO-SANTANDER": string;
    "HOJA-PARA-PERSONALIZAR-EON": string;
    "CONDICIONES-ESPECIALES-PSN-ASESORAMIENTO": string;
    "TEXTO-PROMOCION-6-MESES-GRATIS-2016": string;
    "CONDICIONES-ESPECIALES-AGENCIA-EFE": string;
    "CUADRO-MEDICO-AGENCIA-EFE": string;
    "TEXTO-PROMOCION-15%-EN-2016-Y-10%-EN-2017": string;
    "GARANTIAS-ADICIONALES-EMBAJADA-DE-NICARAGUA": string;
    "CONDICIONES-ESPECIALES-INTEGRAL-200.000": string;
    "CONDICIONES-ESPECIALES-GRUPO-LABORATORIOS-INDAS": string;
    "OPERATIVA-SOLICITUD-REEMBOLSO-DE-GASTOS-FLOWSERVE": string;
    "CONDICIONES-GENERALES-SERVICIOS-COMPLETOS-COLECTIVOS-1488-Y-1489": string;
    "GARANTIAS-ADICIONALES-COSITAL": string;
    "GARANTIAS-ADICIONALES-ESENCIAL-VECTOR": string;
    "TEXTO-PROMOCION-6-MESES-GRATIS-2016-ESTUDIANTES-COLECTIVO-22353": string;
    "CONDICIONES-GENERALES-ASISA-PROXIMA-ALBACETE-BALOMPIE": string;
    "CONDICIONES-ESPECIALES-GERENCIA-URBANISMO-DE-SEVILLA": string;
    "HOJA-EXPLICATIVA-AREA-PRIVADA-WP": string;
    "CONDICIONES-GENERALES-ASISA-PROXIMA-PACK-USO-CASTILLA-Y-LEON": string;
    "TEXTO-PROMOCION-6-MESES-GRATIS-2017-ESTUDIANTES": string;
    "HOJA-INSTRUCCIONES-ASISA-DECESOS": string;
    "CONDICIONES-GENERALES-ASISA-DECESOS": string;
    "NORMAS-DE-CONTRATACION-ASISA-DECESOS": string;
    "ACCESO-ASISTENCIA-MEDICA-ESPAÑA-DENTAL-PORTUGAL-APRIL": string;
    "ASISTENCIA-URGENTE-DENTAL-PORTUGAL-APRIL": string;
    "GUIA-RAPIDA-DENTAL-PORTUGAL-APRIL": string;
    "RED-CLINICAS-DENTAL-PORTUGAL-APRIL": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-PORTUGAL-APRIL": string;
    "TEXTO-PROMOCIÓN-12%-EN-2016-Y-2017-Y-10%-EN-2018": string;
    "TEXTO-PROMOCION-3-MESES-GRATIS": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-ACTIVA": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-AUTONOMOS": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-DENTAL": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-DENTAL-FAMILIAR": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-DENTAL-FAMILIAR-FUNCIONARIOS": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-DENTAL-FUNCIONARIOS": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-DENTAL-PYMES": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-ESTRELLA": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-INTEGRAL": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-INTEGRAL-PYMES": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-INTERNACIONAL": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-MASTER": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-MOMENTO": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-PROXIMA": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-SALUD": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-SALUD-FAMILIAR": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-SALUD-PYMES": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-UTIL": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-ACCIDENTES": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-ACCIDENTES-PLUS": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-HOSPITALIZACION": string;
    "CUADRO-LIMITES-REEMBOLSO-60.000": string;
    "CONDICIONES-GENERALES-ASISA-PROXIMA-PACK-CORTE-INGLES": string;
    "GARANTIAS-ADICIONALES-GRUPO-NOVO-BANCO": string;
    "CONDICIONES-ESPECIALES-INTEGRAL-200-GRUPO-NOVO-BANCO": string;
    "CONDICIONES-ESPECIALES-INTEGRAL-1-MILLON-GRUPO-NOVO-BANCO": string;
    "CONDICIONES-ESPECIALES-INTEGRAL-250-GRUPO-NOVO-BANCO": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-6026-PRESIDENTE-CESCE": string;
    "CONDICIONES-GENERALES-ASISA-PROXIMA-COLECTIVO-C&A": string;
    "CONDICIONES-ESPECIALES-HIERROS-CANTON": string;
    "FRANQUICIAS-POLIZA-DENTAL-AYUNTAMIENTO-TENERIFE": string;
    "CONDICIONES-ESPECIALES-AYTO.-TENERIFE-COLECTIVOS-22525-Y-5911": string;
    "CONDICIONES-ESPECIALES-AYTO.-TENERIFE-COLECTIVOS-22524-Y-22517": string;
    "CONDICIONES-ESPECIALES-TEATRO-REAL": string;
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-GRUPO-BOLUDA": string;
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-LLANES": string;
    "CONDICIONES-PARTICULARES-GIP": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-VIDA-MUJER": string;
    "CONDICIONES-GENERALES-ASISA-VIDA-MUJER": string;
    "APENDICE-CONDICIONES-GENERALES-ASISA-VIDA-MUJER": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-DECESOS": string;
    "EXTRACTO-CONTRATO-POLIZA-FAM-CAMINOS-EXPATRIADOS": string;
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-ARONA": string;
    "CONDICIONES-GENERALES-ASISA-VIDA-TRANQUILIDAD": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-VIDA-TRANQUILIDAD": string;
    "CONDICIONES-GENERALES-ASISA-VIDA-TRANQUILIDAD-PLUS": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-VIDA-TRANQUILIDAD-PLUS": string;
    "CONDICIONES-ESPECIALES-GRUPO-ITP": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-VIDA-RETORNO": string;
    "FRANQUICIAS-DENTALES-AYUNTAMIENTO-MALAGA": string;
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-MALAGA": string;
    "COPAGOS-AYUNTAMIENTO-DE-MALAGA": string;
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-1.000.000": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-PACK-SECI": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-PACK-CESS": string;
    "FRANQUICIAS-ASISA-DENTAL-(PRODUCTO-DENTAL)-CESS": string;
    "FRANQUICIAS-ASISA-DENTAL-(PRODUCTO-DENTAL)-SECI": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-EMACSA": string;
    "SOLICITUD-DE-SEGURO-SIN-CUESTIONARIO-COLECTIVO-EMACSA": string;
    "FRANQUICIAS-POLIZA-DENTAL-COLECTIVO-EMACSA": string;
    "FRANQUICIAS-ASISA-DENTAL-PLUS-(GARANTÍA-DENTAL)-SECI": string;
    "CUADRO-MEDICO-ALBACETE-DEPORTIVO-6686": string;
    "HOJA-EDA-PARA-APP-ASISA-VIDA": string;
    "GARANTIA-DE-ASESORAMIENTO-DE-ASISER-ASISA-VIDA": string;
    "CONDICIONES-GENERALES-ASISA-VIDA-TAR-CENTERBROK": string;
    "NOTA-INFORMATIVA-ASISA-VIDA-TAR-CENTERBROK": string;
    "FRANQUICIAS-DENTAL-COLECTIVO-IMBISA": string;
    "CONDICIONES-ESPECIALES-REEMBOLSO-IMBISA": string;
    "PRIMERA-HOJA-PACK-MONTEPIO": string;
    "HOJA-TARJETAS-SALUD-MONTEPIO": string;
    "HOJA-TARJETAS-DENTAL-MONTEPIO": string;
    "FRANQUICIAS-DENTAL-PARA-MONTEPIO": string;
    "HOJA-APP-MONTEPIO": string;
    "CERTIFICADO-DE-SEGURO-PACK-MONTEPIO": string;
    "HOJA-CD-ASISA-SALUD-Y-DENTAL-MONTEPIO": string;
    "CUADRO-MEDICO-ALBACETE-DEPORTIVO-22795": string;
    "CONDICIONES-ESPECIALES-REEMBOLSO-IMBISA-22793": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-PACK-MONTEPIO": string;
    "HOJA-TARJETAS-SALUD-COLECTIVO-ONCE": string;
    "GARANTIAS-ADICIONALES-GRUPO-ALTAMIRA": string;
    "GARANTIA-DE-LAS-PRESTACIONES-PARA-PREVIMUTUA": string;
    "TEXTO-PROMOCION-30%-Y-25%-AÑO-2017": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-6807-AIRBUS": string;
    "FRANQUICIAS-ASISA-DENTAL-(PRODUCTO-DENTAL)-PREVIMUTUA": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-6828-AIRBUS": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-6829-AIRBUS": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-6830-AIRBUS": string;
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-180.000": string;
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-180.000-EN-INGLES": string;
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-240.000": string;
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-240.000-EN-INGLES": string;
    "CONDICIONES-GENERALES-COLECTIVO-ONCE-22911": string;
    "CONDICIONES-GENERALES-COLECTIVO-ONCE-6790": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-COMITE-DE-EMPRESA-ACG-INGENIERIA": string;
    "CONDICIONES-ESPECIALES-GRUPO-INDRA-PACK-125": string;
    "CONDICIONES-ESPECIALES-GRUPO-INDRA-PACK-209": string;
    "CERTIFICADO-ESPECIAL-COLECTIVO-XIBANYA": string;
    "CONDICIONES-ESPECIALES-INTEGRAL-250-GRUPO-FINSOLUTIA": string;
    "CONDICIONES-ESPECIALES-GRUPO-FINSOLUTIA": string;
    "HOJA-TARJETA-SALUD-BANCO-CAMINOS": string;
    "CONDICIONES-ESPECIALES-BANCO-CAMINOS": string;
    "FRANQUICIAS-DENTAL-PARA-SALUD-+-GARANTIA-BANCO-CAMINOS": string;
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-BANCO-CAMINOS-REEMBOLSO-DIRECTIVOS": string;
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-BANCO-CAMINOS-UP-GRADE": string;
    "CONDICIONES-ESPECIALES-BANCO-CAMINOS-DIRECTIVOS": string;
    "CONDICIONES-ESPECIALES-BANCO-CAMINOS-UP-GRADE": string;
    "CUADRO-MEDICO-ESPECIAL-COLECTIVO-ONCE": string;
    "CUADRO-MEDICO-ESPECIAL-GRUPO-EXIDE": string;
    "NOTA-INFORMATIVA-INTEGRAL-250-GRUPO-PRISA": string;
    "NOTA-INFORMATIVA-INTEGRAL-100-Y-SALUD-GRUPO-PRISA": string;
    "CONDICIONES-ESPECIALES-INTEGRAL-100-GRUPO-PRISA": string;
    "CONDICIONES-ESPECIALES-INTEGRAL-250-GRUPO-PRISA": string;
    "CONDICIONES-GENERALES-INTEGRAL-GRUPO-PRISA": string;
    "CONDICIONES-GENERALES-SALUD-GRUPO-PRISA": string;
    "CONDICIONES-ESPECIALES-FEYLO-SYLVANIA": string;
    "CONDICIONES-ESPECIALES-GRUPO-ANECA": string;
    "COBERTURAS-ADICIONALES-COLECTIVOS-PREVIMUTUA": string;
    "CONDICIONES-GENERALES-ASISA-MASCOTAS": string;
    "CONDICIONES-GENERALES-ASISA-VITALTEA": string;
    "GARANTIAS-RETRIBUCION-FLEXIBLE-COLECTIVO-RTVE": string;
    "CONDICIONES-GENERALES-ASISA-KIDS": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-VITALTEA": string;
    "CONDICIONES-ESPECIALES-TELECOMING": string;
    "CUADRO-MEDICO-ACOTADO-UBESOL": string;
    "CONDICIONES-ESPECIALES-REAL-FEDERACION-DE-ATLETISMO": string;
    "FRANQUICIAS-ASISA-MASCOTAS": string;
    "CONDICIONES-ESPECIALES-PERFUMES-Y-DISEÑO": string;
    "CONDICIONES-ESPECIALES-GRUPO-HASTEN": string;
    "HOJA-INDICE-ASISA-VIDA-PORTUGAL": string;
    "ASISA-VIDA-PORTUGAL-QUEM-SOMOS": string;
    "NOTA-INFORMATIVA-ASISA-VIDA-PORTUGAL": string;
    "CONDICIONES-GENERALES-ASISA-VIDA-PORTUGAL": string;
    "PREGUNTAS-FRECUENTES-E-CONTACTOS-ASISA-VIDA-PORTUGAL": string;
    "CONDICIONES-GENERALES-ASISA-ACTIVA-HASTA-2018": string;
    "CONDICIONES-ESPECIALES-ATMIRA": string;
    "HOJA-INDICE-ASISA-DENTAL-SUCURSAL-PORTUGAL": string;
    "NOTA-INFORMATIVA-ASISA-DENTAL-SUCURSAL-PORTUGAL": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-SUCURSAL-PORTUGAL": string;
    "TABELA-ACTOS-MEDICOS-ASISA-DENTAL-SUCURSAL-PORTUGAL": string;
    "PREGUNTAS-FRECUENTES-E-CONTACTOS-ASISA-DENTAL-SUCURSAL-PORTUGAL": string;
    "TEXTO-PROMOCION-30%-25%-O-15%-20%-AÑO-2018": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-MASCOTAS": string;
    "FRANQUICIAS-AUTORIDAD-PORTUARIA-LAS-PALMAS": string;
    "CONDICIONES-ESPECIALES-MIJAS-COMUNICACION": string;
    "COBERTURAS-DESTACADAS-COLECTIVO-AECID": string;
    "RESUMEN-COBERTURAS-BECARIOS-COLECTIVO-AECID": string;
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-OVIEDO-7793": string;
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-OVIEDO-7794": string;
    "NOTA-INFORMATIVA-ASISA-DENTAL-FAMILIAR-SUCURSAL-PORTUGAL": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-FAMILIAR-SUCURSAL-PORTUGAL": string;
    "PREGUNTAS-FRECUENTES-E-CONTACTOS-ASISA-DENTAL-FAMILIAR-SUCURSAL-PORTUGAL": string;
    "HOJA-INDICE-ASISA-DENTAL-FAMILIAR-SUCURSAL-PORTUGAL": string;
    "NOTA-INFORMATIVA-ASISA-ESTRELLA-KIDS": string;
    "NOTA-INFORMATIVA-ASISA-ACTIVA-PLUS-KIDS": string;
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-100.000-GRUPO-DACHSER": string;
    "TEXTO-PROMOCION-INVIERNO-2018": string;
    "CONDICIONES-GENERALES-ASISA-SALUD-BASIC-PACK-BANCA-MARCH": string;
    "CONDICIONES-GENERALES-ASISA-SALUD-20.000-PACK-BANCA-MARCH": string;
    "LISTADO-ENFERMEDADES-Y-PATOLOGIAS-GRAVES-ASISA-2018": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-CONTRIBUTIVOS-SUCURSAL-PORTUGAL": string;
    "PREGUNTAS-FRECUENTES-E-CONTACTOS-ASISA-DENTAL-CONTRIBUTIVOS-SUCURSAL-PORTUGAL": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL-NO-CONTRIBUTIVOS-SUCURSAL-PORTUGAL": string;
    "PREGUNTAS-FRECUENTES-E-CONTACTOS-ASISA-DENTAL-NO-CONTRIBUTIVOS-SUCURSAL-PORTUGAL": string;
    "REPATRIACION-COLECTIVO-NATIVE-LANGUAGE": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-TYPSA": string;
    "CONDICIONES-ESPECIALES-CONSEJO-GENERAL-DE-ENFERMERIA": string;
    "CONDICIONES-ESPECIALES-GRUPO-JLL-CM-COLECTIVOS-8051-8053-8055-8057": string;
    "CONDICIONES-ESPECIALES-GRUPO-JLL-1.000.000-COLECTIVOS-8052-8056-8227": string;
    "CONDICIONES-ESPECIALES-GRUPO-JLL-240.000-COLECTIVO-8054": string;
    "CONDICIONES-ESPECIALES-HAITONG-BANK": string;
    "CUADRO-LIMITES-REEMBOLSO-HAITONG-BANK": string;
    "CUADRO-LIMITES-REEMBOLSO-GRUPO-JLL-COLECTIVOS-8052-8056-8227": string;
    "CONDICIONES-ESPECIALES-HAITONG-BANK-COLECTIVOS-ABIERTOS": string;
    "CONDICIONES-GENERALES-SALUD-CONSEJO-GENERAL-DEL-NOTARIADO": string;
    "CONDICIONES-ESPECIALES-CONSEJO-GENERAL-DEL-NOTARIADO": string;
    "CONDICIONES-ESPECIALES-REEMBOLSO-150.000": string;
    "CONDICIONES-ESPECIALES-FAM-CAMINOS-EXPATRIADOS-MODULO-B": string;
    "CONDICIONES-ESPECIALES-FAM-CAMINOS-EXPATRIADOS-MODULO-C": string;
    "NOTA-INFORMATIVA-COLECTIVO-LEASPLAN": string;
    "OPERATIVA-SOLICITUD-REEMBOLSO-DE-GASTOS-LEASEPLAN": string;
    "CONDICIONES-ESPECIALES-GRUPO-NUETALIA-E-INDUSTRIAS-IMAR": string;
    "FRANQUICIAS-COLECTIVO-VIMCORSA": string;
    "FRANQUICIAS-COLECTIVO-FABRICA-NACIONAL-MONEDA-Y-TIMBRE": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-ADIF": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-LIFE-DESAROLLOS-COMERCIALES": string;
    "CONDICIONES-ESPECIALES-GRUPO-UBS-BANK": string;
    "CONDICIONES-ESPECIALES-PLASTIC-OMNIUM": string;
    "NOTA-INFORMATIVA-COLECTIVO-GRUPO-CEPSA": string;
    "COBERTURA-GASTOS-FARMACIA-REMBOLSO-ONLINE": string;
    "OPERATIVA-SOLICITUD-REEMBOLSO-DE-GASTOS-ONLINE": string;
    "CONDICIONES-ESPECIALES-AJUNTAMENT-CASTELL-PLATJA-DE-ARO": string;
    "CONDICIONES-ESPECIALES-COLECTIVO-LABORATORIOS-BIAL-MERCER": string;
    "NOTA-INFORMATIVA-GRUPO-PUMA-2018": string;
    "CONDICIONES-ESPECIALES-GRUPO-PUMA-250.000": string;
    "CONDICIONES-ESPECIALES-ZITRÓN---ASCAZ-SALUD": string;
    "CONDICIONES-ESPECIALES-ZITRON-ASCAZ-PROXIMA": string;
    "CONDICIONES-GENERALES-ZITRON-ASCAZ-PROXIMA": string;
    "COPAGO-ESPECIAL-ASISA-MOMENTO-CONSUM": string;
    "CONDICIONES-GENERALES-ASISA-VIDA-ACCIDENTES-COMPROMISO-10": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-VIDA-ACCIDENTES-COMPROMISO-10": string;
    "CONDICIONES-ESPECIALES-COLECTIVOS-UCO": string;
    "CONDICIONES-ESPECIALES-COLECTIVOS-FESTIVAL-CINE-DE-MALAGA": string;
    "CONDICIONES-ESPECIALES-TEATRO-CERVANTES-DE-MALAGA": string;
    "HOJA-LEYENDA-NUEVA-CLAUSULA-LOPD": string;
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-180.000-AUTORIDAD-PORTUARIA-ALICANTE": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-COBERTURA-DE-TRATAMIENTO-BUCODENTALES": string;
    "DECLARACION-DE-SALUD-ASISA-VIDA-SENDA": string;
    "CONDICIONES-GENERALES-ASISA-EXCLUSIVE-20.000-BANCA-MARCH": string;
    "CONDICIONES-GENERALES-ASISA-COBERTURA-TRATAMIENTO-BUCODENTALES": string;
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-GRANADA": string;
    "HOJA-CHAT-MEDICO-DECESOS-WP": string;
    "DOCUMENTO-INFORMACION-PRODUCTO-(DIP)-ASISA-COBERTURA-DE-TRATAMIENTO-BUCODENTALES": string;
    "CONDICIONES-GENERALES-ASISA-INTEGRAL-FAM-CAMINOS-PACK": string;
    "NOTA-INFORMATIVA-ASISA-VIDA-ACCIDENTES-10-PORTUGAL": string;
    "CONDICIONES-GENERALES-ASISA-VIDA-ACCIDENTES-10-PORTUGAL": string;
    "CONDICIONES-GENERALES-Y-PARTICULARES-BUCODENTAL-(CP-+-DAC-+-CG)": string;
    "CONDICIONES-ESPECIALES-KIMBERLY-CLARK": string;
    "COBERTURA-REPATRIACION-FALLECIMIENTO,-ENFERMEDAD-Y-ACCIDENTE-GRAVE": string;
    "CONDICIONES-GENERALES-ASISA-TRAVEL-ALL-YEAR": string;
    "CONDICIONES-GENERALES-ASISA-TRAVEL-STUDY": string;
    "CONDICIONES-GENERALES-ASISA-TRAVEL-AND-YOU": string;
    "TABLA-DE-GARANTIAS-ASISA-TRAVEL-ALL-YEAR": string;
    "TABLA-DE-GARANTIAS-ASISA-TRAVEL-STUDY": string;
    "TABLA-DE-GARANTIAS-ASISA-TRAVEL-AND-YOU": string;
    "COBERTURA-REPATRIACION-FALLECIMIENTO-COLECTIVO-BEST-CAREER": string;
    "INFORMACION-COBERTURAS-ADICIONALES-ASISA-DENTAL-SUCURSAL-PORTUGAL": string;
    "CONDICIONES-GENERALES-Y-PARTICULARES-ASISA-TRAVEL": string;
    "CONDICIONES-ESPECIALES-LABORATORIOS-BIAL": string;
    "CONDICIONES-ESPECIALES-ANAV": string;
    "COBERTURA-REPATRIACION-POR-FALLECIMIENTO-COLECTIVO-GESTION-EDUCATIVA-CONSULTORES": string;
    "CONDICIONES-PROMOCION-VERANO-2019": string;
    "HOJA-INDICE-ASISA-VIDA-ACCIDENTES-10-PORTUGAL": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-ACTIVA-BCN": string;
    "CONDICIONES-GENERALES-ASISA-INTEGRAL-PACK-FAM-CAMINOS": string;
    "CONDICIONES-ESPECIALES-WP-MALAGA-DEPORTES-Y-EVENTOS-SA": string;
    "CONDICIONES-GENERALES-ASISA-SALUD---PELAYO": string;
    "CONDICIONES-GENERALES-ASISA-INTEGRAL--PELAYO": string;
    "CONDICIONES-GENERALES-ASISA-DENTAL--PELAYO": string;
    "CONDICIONES-GENERALES-SALUD-UNIVERSIDAD-EUROPEA-DE-MADRID": string;
    "CONDICIONES-GENERALES-SALUD-UNIVERSIDAD-EUROPEA-DE-MADRID-EN-INGLES": string;
    "TEXTO-APP-ASISA-PARA-MARBELLA-INTERNATIONAL-UNIVERSITY-CASTELLANO-E-INGLES": string;
    "CONDICIONES-ESPECIALES-MARBELLA-INTERNATIONAL-UNIVERSITY": string;
    "HOJA-INDICE-ASISA-VIDA-PORTUGAL-CCAM": string;
    "ASISA-VIDA-PORTUGAL-QUEM-SOMOS-CCAM": string;
    "NOTA-INFORMATIVA-ASISA-VIDA-PORTUGAL-CCAM": string;
    "CONDICIONES-GENERALES-ASISA-VIDA-PORTUGAL-CCAM": string;
    "HOJA-AUTORIZACIONES-VIDA-PORTUGAL-CCAM": string;
    "PREGUNTAS-FRECUENTES-E-CONTACTOS-ASISA-VIDA-PORTUGAL-CCAM": string;
    "HOJA-AUTORIZACIONES-ASISA-VIDA-PORTUGAL": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-INTERNACIONAL-UNIVERSIDAD-EUROPEA-DE-MADRID": string;
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-INTERNACIONAL-UNIVERSIDAD-EUROPEA-DE-MADRID-EN-INGLES": string;
    "TABELA-ACTOS-MEDICOS-ASISA-DENTAL-FAMILIA-SUCURSAL-PORTUGAL": string;
    "CONDICIONES-ESPECIALES-DENTAL-CESCE": string;
    "CONDICIONES-ESPECIALES-SALUD-CESCE": string;
    "NOTA-INFORMATIVA-PELAYO-ASISA-SALUD-PLUS-PARTICULARES": string;
    "NOTA-INFORMATIVA-PELAYO-ASISA-SALUD-PLUS-PYMES-Y-AUTONOMOS": string;
    "NOTA-INFORMATIVA-PELAYO-ASISA-SALUD-PREMIUM-PARTICULARES": string;
    "NOTA-INFORMATIVA-PELAYO-ASISA-SALUD-PREMIUM-PYMES-Y-AUTONOMOS": string;
    "NOTA-INFORMATIVA-PELAYO-ASISA-INTEGRAL": string;
    "NOTA-INFORMATIVA-PELAYO-ASISA-DENTAL": string;
    "NOTA-INFORMATIVA-PELAYO-ASISA-DENTAL-FAMILIAR": string;
    "DOCUMENTO-DE-INFORMACION-DE-PRODUCTO-ASISA-SERVICIOS-COMPLETOS": string;
    "DOCUMENTO-DE-INFORMACION-DE-PRODUCTO-ASISA-MASTER": string;
    "DOCUMENTO-DE-INFORMACION-DE-PRODUCTO-ASISA-EL-CORTE-INGLES-EMPLEADOS": string;
    "CONDICIONES-GENERALES-Dirección-General-de-Educación.-Consejería-de-Educación-y-Cultura": string;
    "CONDICIONES-GENERALES-Dirección-General-de-Educación.-Consejería-de-Educación-y-Cultura-INGLES": string;
    "CONDICIONES-GENERALES-ASISA-PROXIMA-ASOCIACION-EUROPEA": string;
    "CONDICIONES-GENERALES-ASISA-SALUD-ASOCIACION-EUROPEA": string;
    "PORTADA-FOLLETO-PACK": string;
    "DOCUMENTOS-A-DEVOLVER-A-ASISA": string;
    "DOCUMENTOS-A-DEVOLVER-A-ASISA-CATALAN": string;
    "DOCUMENTOS-A-DEVOLVER-A-ASISA-EN-INGLES": string;
    "PRODUCTOS-ASISA": string;
    "MAS-PARA-SU-SALUD": string;
    "MAS-PARA-SU-SALUD-CATALAN": string;
    "PORTADA-CONDICIONES-GENERALES-SATISFACCION-BANCO-DE-SANTANDER": string;
    "PORTADA-CONDICIONES-GENERALES-B.-SANTANDER-EMPRESAS-NEGOCIOS-CON-DENTAL": string;
    "PORTADA-CONDICIONES-GENERALES-B.-SANTANDER-EMPRESAS-NEGOCIOS-SIN-DENTAL": string;
    "DOCUMENTOS-A-DEVOLVER-A-ASISA-B.-SANTANDER-IGUALATORIO": string;
    "PORTADA-FOLLETO-PACK-BANCO-SANTANDER": string;
    "PORTADA-FOLLETO-PACK-BANCO-SANTANDER-IGUALATORIO": string;
    "PORTADA-CONDICIONES-GENERALES-ASISA-SALUD": string;
    "PORTADA-TRANSPARENTE-EDA-19-X-23,3": string;
    "PORTADA-DIRECTA-WP-CORPORACION-DIRECTA": string;
    "PORTADA-DIRECTA-WP-ASISA-SALUD": string;
    "PORTADA-DIRECTA-WP-ASISA-SALUD-CATALAN": string;
    "PORTADA-DIRECTA-WP-BANCO-SANTANDER": string;
    "PORTADA-DIRECTA-BANCO-SANTANDER-IGUALATORIO": string;
    "PORTADA-DIRECTA-WP-ASISA-AUTONOMOS": string;
    "PORTADA-DIRECTA-WP-ASISA-ESTRELLA": string;
    "PORTADA-DIRECTA-WP-ASISA-INTEGRAL": string;
    "PORTADA-DIRECTA-WP-ASISA-MASTER": string;
    "PORTADA-DIRECTA-WP-ASISA-PROXIMA": string;
    "PORTADA-DIRECTA-WP-ASISA-VIDA-RETORNO": string;
    "PORTADA-DIRECTA-WP-ASISA-SALUD-FAMILIAR": string;
    "PORTADA-DIRECTA-WP-ASISA-UTIL": string;
    "PORTADA-DIRECTA-WP-ASISA-VIDA": string;
    "PORTADA-DIRECTA-ASISA-DENTAL-Y-DENTAL-FAMILIAR": string;
    "PORTADA-DIRECTA-ASISA-DENTAL-FAMILIAR-BANCO-SANTANDER": string;
    "PORTADA-DIRECTA-ASISA-PYMES": string;
    "PORTADA-DIRECTA-ASISA-HOSPITALIZACION": string;
    "PORTADA-DIRECTA-ASISA-ACCIDENTES-(ANTIGUA-PREVISION)": string;
    "PORTADA-DIRECTA-ASISA-ACCIDENTES-PLUS-(ANTIGUA-ACCIDENTES)": string;
    "PORTADA-DIRECTA-BARYMONT-SALUD": string;
    "PORTADA-DIRECTA-BARYMONT-PYMES-SALUD": string;
    "PORTADA-DIRECTA-BARYMONT-PYMES-DENTAL": string;
    "PORTADA-DIRECTA-BARYMONT-PYMES-DENTAL-FAMILIAR": string;
    "PORTADA-DIRECTA-ASISA-SALUD-PROFESIONALES-LAVINIA": string;
    "VENTAJAS-EXCLUSIVAS-PARA-MEDICOS": string;
    "PORTADA-DIRECTA-ASISA-SALUD-HOSPITALIZACION-PROFESIONALES": string;
    "PORTADA-DIRECTA-ASISA-SALUD-ESPECIAL-MEDICOS": string;
    "PORTADA-DIRECTA-FAM-CAMINOS": string;
    "PORTADA-DIRECTA-ASISA-SALUD-PROFESIONALES": string;
    "PORTADA-DIRECTA-START-SALUD-DENTAL": string;
    "PORTADA-DIRECTA-WP-BANCA-MARCH-SALUD": string;
    "PORTADA-DIRECTA-WP-BANCA-MARCH-DENTAL": string;
    "PORTADA-DIRECTA-ASISA-MOMENTO": string;
    "PORTADA-DIRECTA-ASISA-DENTAL-3": string;
    "PORTADA-DIRECTA-ASISA-INTERNACIONAL": string;
    "PORTADA-DIRECTA-ASISA-DENTAL-FAM-CAMINOS": string;
    "PORTADA-DIRECTA-WP-BANCA-MARCH-DENTAL-SIN-FAMILIAR": string;
    "PORTADA-DIRECTA-MIGRACION-ASTURIAS-MUFACE": string;
    "PORTADA-TRANSPARENTE-EDA-22-X-30,7": string;
    "PORTADA-DIRECTA-MIGRACION-ASTURIAS-ISFAS---MGJ": string;
    "DOCUMENTOS-A-DEVOLVER-A-ASISA-DIN-A-4": string;
    "PORTADA-CONDICIONES-GENERALES-ASISA-UTIL-BANCO-SANTANDER": string;
    "PORTADA-DIRECTA-ASISA-ACTIVA": string;
    "PORTADA-DIRECTA-ASISA-SALUD-CORTE-INGLES-CESS": string;
    "PORTADA-DIRECTA-ASISA-DENTAL-PORTUGAL": string;
    "PORTADA-SERVICIOS-DE-VALOR---MUITO-MAIS": string;
    "PORTADA-CONDICIONES-PARTICULARES-DENTAL-PORTUGAL": string;
    "PORTADA-DIRECTA-ASISER-CARD": string;
    "PORTADA-CONDICIONES-GENERALES-SATISFACCION-BANCO-DE-SANTANDER-PROMOCION-2015": string;
    "PORTADA-DIRECTA-ASISA-DENTAL-PORTUGAL-APLICALIA-WP": string;
    "PORTADA-TRANSPARENTE-EDA-19-X-23,3-SOLO-LOGO": string;
    "PORTADA-CONDICIONES-GENERALES-SATISFACCION-PLUS-BANCO-DE-SANTANDER": string;
    "PORTADA-SALUDCOR-CESS": string;
    "PORTADA-SALUDCOR-SECI": string;
    "PORTADA-DIRECTA-ASISA-SALUD-SECI": string;
    "PORTADA-DIRECTA-ASISA-DENTAL-PYMES": string;
    "PORTADA-DIRECTA-ASISA-DENTAL-FUNCIONARIOS": string;
    "PORTADA-DIRECTA-ASISA-DECESOS": string;
    "PORTADA-DIRECTA-ASISA-DENTAL-PORTUGAL-APRIL": string;
    "PORTADA-DIRECTA-WP-ASISA-ESENCIAL": string;
    "PORTADA-CONDICIONES-GENERALES-SECI": string;
    "PORTADA-CONDICIONES-GENERALES-CEES": string;
    "PORTADA-DIRECTA-WP-ASISA-VIDA-MUJER": string;
    "PORTADA-DIRECTA-WP-ASISA-INTEGRAL-PYMES": string;
    "PORTADA-DIRECTA-WP-ASISA-TRANQUILIDAD": string;
    "PORTADA-DIRECTA-WP-ASISA-VIDA-TRANQUILIDAD-PLUS": string;
    "PORTADA-DIRECTA-ASISA-SALUD-CORTE-INGLES-SECI": string;
    "PORTADA-DIRECTA-ASISA-VIDA-TAR-CENTERBROK": string;
    "PORTADA-DIRECTA-ASISA-MONTEPIO": string;
    "MAS-PARA-SU-SALUD-MONTEPIO": string;
    "PORTADA-CONDICIONES-GENERALES-MONTEPIO": string;
    "PORTADA-DIRECTA-ASISA-COLECTIVO-ONCE": string;
    "PORTADA-DIRECTA-ASISA-COLECTIVO-ONCE-ILUNION": string;
    "PORTADA-DIRECTA-BANCO-CAMINOS": string;
    "PORTADA-DIRECTA-ASISA-MASCOTAS": string;
    "PORTADA-CONDICIONES-PARTICULARES-ASISA-VIDA-PORTUGAL": string;
    "PORTADA-CONDICIONES-PARTICULARES-ASISA-DENTAL-SUCURSAL-PORTUGAL": string;
    "PORTADA-DIRECTA-WP-BANCA-MARCH-SALUD-BASIC": string;
    "PORTADA-DIRECTA-WP-BANCA-MARCH-SALUD-20.000": string;
    "PORTADA-DIRECTA-WP-FABRICA-NACIONAL-MONEDA-Y-TIMBRE": string;
    "PORTADA-DIRECTA-WP-ASISA-VIDA-ACCIDENTES-COMPROMISO-10": string;
    "PORTADA-DIRECTA-WP-ASISA-AYUNTAMIENTO-DE-GRANADA": string;
    "PORTADA-DIRECTA-WP-ASISA-AYUNTAMIENTO-DE-GRANADA-MUFACE": string;
    "PORTADA-DIRECTA-WP-ASISA-TRATAMIENTOS-BUCODENTAL": string;
    "PORTADA-CONDICIONES-PARTICULARES-ASISA-VIDA-ACCIDENTES-10-PORTUGAL": string;
    "PORTADA-DIRECTA-WP-ASISA-TRAVEL": string;
    "PORTADA-DIRECTA-WP-ASISA-SALUD-PELAYO": string;
    "PORTADA-DIRECTA-WP-ASISA-DENTAL-PELAYO": string;
    "PORTADA-DIRECTA-WP-ASISA-INTEGRAL-PELAYO": string;
    "PORTADA-DIRECTA-ASISA-INTERNACIONAL-UNIVERSIDAD-EUROPEA-MADRID": string;
    "PORTADA-CONDICIONES-PARTICULARES-ASISA-VIDA-CCAM-PORTUGAL": string;
    "PORTADA-ASISA-PROXIMA-ASOCIACION-EUROPEA": string;
    "PORTADA-ASISA-SALUD-ASOCIACION-EUROPEA": string;
    "INFORMACION-PRODUCTO-ASISA-SALUD": string;
    "INFORMACION-PRODUCTO-ASISA-HOSPITALIZACION": string;
    "INFORMACION-PRODUCTO-ASISA-ACCIDENTES-PLUS-(ANTIGUA-ACCIDENTES)": string;
    "INFORMACION-PRODUCTO-ASISA-ACCIDENTES-(ANTIGUA-PREVISION)": string;
    "INFORMACION-PRODUCTO-ASISA-DENTAL-Y-DENTAL-FAMILIAR": string;
    "INFORMACION-PRODUCTO-ASISA-VIDA": string;
    "INFORMACION-PRODUCTO-ASISA-MASTER": string;
    "INFORMACION-PRODUCTO-ASISA-PYMES": string;
    "INFORMACION-PRODUCTO-ASISA-AUTONOMOS": string;
    "INFORMACION-PRODUCTO-ASISA-INTEGRAL": string;
    "INFORMACION-PRODUCTO-ASISA-SALUD-FAMILIAR": string;
    "INFORMACION-PRODUCTO-ASISA-ESTRELLA": string;
    "INFORMACION-PRODUCTO-ASISA-PROXIMA": string;
    "HOJA-TODOS-LOS-PRODUCTOS": string;
    "INFORMACION-PRODUCTO-ASISA-DENTAL-FUNCIONARIOS": string;
    "INFORMACION-PRODUCTO-ASISA-DENTAL-PYMES": string;
    "INFORMACION-PRODUCTO-ASISA-INTEGRAL-PYMES": string;
    "INFORMACION-PRODUCTO-ASISA-INTERNACIONAL": string;
    "INFORMACION-PRODUCTO-ASISA-MOMENTO": string;
    "INFORMACION-PRODUCTO-ASISA-VIDA-RETORNO": string;
    "INFORMACION-PRODUCTO-ASISA-UTIL": string;
    "INFORMACION-PRODUCTO-ASISA-ACCIDENTES-Y-ACCIDENTES-PLUS-UNICA-FICHA": string;
    "INFORMACION-PRODUCTO-ASISA-DECESOS": string;
    "INFORMACION-PRODUCTO-ASISA-ACTIVA-PLUS": string;
    "INFORMACION-BIOCORD": string;
    "INFORMACION-BIOCORD-CATALAN": string;
    "INFORMACION-CLUB-ASISA": string;
    "INFORMACION-CLUB-ASISA-CATALAN": string;
    "INFORMACION-REPRODUCCION-ASISTIDA": string;
    "INFORMACION-REPRODUCCION-ASISTIDA-CATALAN": string;
    "HOJA-ACUERDO-CLINICA-DERMA-RUBER": string;
    "CLINICA-DERMATOLOGICA-INTERNACIONAL-BANCA-MARCH": string;
    "INFORMACION-LEGALITAS": string;
    "INFORMACION-CRIOCORD-CORTE-INGLES": string;
    "INFORMACION-REPRODUCCION-ASISTIDA-CORTE-INGLES": string;
    "INFORMACION-CRIOCONSERVACION-ESTANDAR": string;
    "INFORMACION-CRIOCONSERVACION-ESTANDAR-IMAGEN-ANTIGUA": string;
    "INFORMACION-CRIOCORD-MONTEPIO": string;
    "INFORMACION-REPRODUCCION-ASISTIDA-MONTEPIO": string;
    "INFORMACION-CLUB-ASISA-MONTEPIO": string;
    "INFORMACION-PAGINA-SERVICIO-VALOR-AÑADIDO": string;
    "INFORMACION-GENERAL-OPTICA": string;
    "INFORMACION-DIOLA": string;
    "INFORMACION-ASPAD-MASCOTAS": string;
    "INFORMACION-PAGINA-SERVICIO-VALOR-AÑADIDO-SIN-ASPAD-MASCOTAS": string;
    "FIRMA-CONDICIONES-GENERALES": string;
    "FIRMA-CONDICIONES-GENERALES-CATALAN": string;
    "FIRMA-CONDICIONES-GENERALES-EN-INGLES": string;
    "FIRMA-CONDICIONES-GENERALES-ASISA-MOMENTO": string;
    "FIRMA-CONDICIONES-GENERALES-ASISA-ACTIVA": string;
    "FIRMA-CONDICIONES-GENERALES-PACK-ELECTRONICO": string;
    "FIRMA-CONDICIONES-GENERALES-ASISA-MOMENTO-PACK-ELECTRONICO": string;
    "FIRMA-CONDICIONES-GENERALES-ASISA-ACTIVA-PACK-ELECTRONICO": string;
    "FIRMA-CONDICIONES-GENERALES-ASISA-VIDA": string;
    "FIRMA-CONDICIONES-GENERALES-BANCO-CAMINOS": string;
    "FIRMA-CONDICIONES-GENERALES-ASISA-VIDA-PORTUGAL": string;
    "FIRMA-CONDICIONES-GENERALES-ASISA-DENTAL-SUCURSAL-PORTUGAL": string;
    "CARTA-AYUNTAMIENTO-DE-SEVILLA": string;
    "CARTA-CONTRATACION-ASEGURADOS-COLECTIVOS-CERRADOS-(SIN-CP)": string;
    "CARTA-CONTRATACION-ASEGURADOS-COLECTIVOS-CERRADOS-EN-CATALAN-(SIN-CP)": string;
    "CARTA-INFORMACION-ASISA-SALUD-COLECTIVOS": string;
    "CARTA-DIPUTACION-DE-SORIA": string;
    "CARTA-AYUNTAMIENTO-DE-REUS": string;
    "CARTA-AYUNTAMIENTO-PUERTO-DE-SANTAMARIA": string;
    "CARTA-AYUNTAMIENTO-DE-MAZARRON": string;
    "CARTA-EL-CORTE-INGLES": string;
    "CARTA-AYUNTAMIENTO-DE-LUGO": string;
    "CARTA-COLECTIVO-BANCA-MARCH": string;
    "CARTA-COLECTIVO-FAMILIARES-IMED": string;
    "CARTA-ESPECIAL-COLECTIVOS-PANDA": string;
    "CARTA-COLECTIVO-BANCO-DE-SANTANDER": string;
    "CARTA-COLECTIVO-BANCO-DE-SANTANDER-IGUALATORIO": string;
    "CARTA-RETENCIONES-REACTIVAS": string;
    "CARTA-COLECTIVO-BANCO-SANTANDER-EXCLUSIVE": string;
    "CARTA-CONTRATACION-ASEGURADOS-COLECTIVOS-ABIERTOS-(CON-CP)": string;
    "CARTA-CONTRATACION-ASEGURADOS-COLECTIVOS-ABIERTOS-EN-INGLES-(CON-CP)": string;
    "CARTA-CONTRATACION-BANCO-SANTANDER-EMPRESAS-COLECTIVOS-CERRADOS": string;
    "CARTA-CONTRATACION-GRUPO-LIBERTY": string;
    "CARTA-PACK-SIMPLIFICADO-TARJETA-+-CP": string;
    "CARTA-PACK-SIMPLIFICADO-SOLO-TARJETA": string;
    "CARTA-INFORMACION-ASISA-SALUD": string;
    "CARTA-INFORMACION-ASISA-ESTRELLA": string;
    "CARTA-INFORMACION-ASISA-UTIL": string;
    "CARTA-INFORMACION-ASISA-MASTER": string;
    "CARTA-INFORMACION-ASISA-SALUD-FAMILIAR": string;
    "CARTA-INFORMACION-ASISA-AUTONOMOS": string;
    "CARTA-INFORMACION-ASISA-INTEGRAL-100": string;
    "CARTA-INFORMACION-ASISA-INTEGRAL-180": string;
    "CARTA-INFORMACION-ASISA-INTEGRAL-240": string;
    "CARTA-INFORMACION-ASISA-DENTAL-FAMILIAR": string;
    "CARTA-INFORMACION-ASISA-DENTAL": string;
    "CARTA-CONTRATACION-ASISA-VIDA": string;
    "CARTA-CONTRATACION-FECYT": string;
    "CARTA-ASISA-DENTAL-BANCO-SANTANDER": string;
    "CARTA-CORPORACION-DIRECTA": string;
    "CARTA-START-SALUD": string;
    "CARTA-INFORMACION-ASISA-PROXIMA": string;
    "CARTA-INFORMACION-ASISA-PYMES": string;
    "CARTA-INFORMACION-ASISA-PYMES-PLUS": string;
    "ASISA-INFORMACION-PYMES-INTEGRAL-180": string;
    "CARTA-INFORMACION-ASISA-PYMES-DENTAL": string;
    "CARTA-INFORMACION-ASISA-PYMES-PLUS-DENTAL": string;
    "CARTA-CONTRATACION-COLECTIVOS-PACK-ELECTRONICO": string;
    "CARTA-SANTANDER---BANESTO-V2-PACK-ELECTRONICO": string;
    "CARTA-INFORMACION-ASISA-INTEGRAL-240-COLECTIVOS": string;
    "CARTA-INFORMACION-ASISA-INTEGRAL-100-COLECTIVOS": string;
    "CARTA-INFORMACION-ASISA-INTEGRAL-180-COLECTIVOS": string;
    "CARTA-INFORMACION-ASISA-SANTANDER-SELECT": string;
    "CARTA-INFORMACION-ASISA-DENTAL-FAMILIAR-COLECTIVOS": string;
    "CARTA-INFORMACION-ASISA-DENTAL-COLECTIVOS": string;
    "CARTA-INFORMACION-ASISA-MASTER-COLECTIVOS": string;
    "CARTA-INFORMACION-ASISA-UTIL-COLECTIVOS": string;
    "CARTA-INFORMACION-ASISA-PROXIMA-COLECTIVOS": string;
    "CARTA-INFORMACION-ASISA-INTEGRAL-COLECTIVOS": string;
    "CARTA-CONTRATACION-BANCA-MARCH-PARA-ENVÍO-ELECTRONICO": string;
    "CARTA-INFORMACION-ASISA-HOSPITALIZACION": string;
    "CARTA-CONTRATACION-ASISA-ACCIDENTES-(ANTIGUA-PREVISION)": string;
    "CARTA-CONTRATACION-ASISA-TRIPLE-HOSPITALIZACION": string;
    "CARTA-CONTRATACION-ASISA-HOSPITALIZACION": string;
    "CARTA-CONTRATACION-ASISA-ACCIDENTES-PLUS-(ANTIGUA-ACCIDENTES)": string;
    "CARTA-CONTRATACION-BARYMONT": string;
    "CARTA-CONTRATACION-ISFAS": string;
    "CARTA-MIGRACION-ASISA-SALUD-PROFESIONALES-LAVINIA": string;
    "CARTA-MIGRACION-ASISA-SALUD-HOSPITALIZACION-PROFESIONALES": string;
    "CARTA-MIGRACION-ASISA-SALUD-ESPECIAL-MEDICOS": string;
    "CARTA-ASISA-SALUD-PROFESIONALES-LAVINIA": string;
    "CARTA-ASISA-SALUD-ESPECIAL-MEDICOS": string;
    "CARTA-FAM-CAMINOS": string;
    "CARTA-CONTRATACION-EMPRESAS-BANCA-MARCH-(C.-ABIERTOS)": string;
    "CARTA-CONTRATACION-BANCA-MARCH-(C.-CERRADO)": string;
    "CARTA-CONTRATACION-BANCO-SANTANDER---EMPRESAS-ASISA-DENTAL-(C.-CERRADO)": string;
    "CARTA-CONTRATACION-BANCO-SANTANDER---EMPRESAS-ASISA-DENTAL-(C.-ABIERTO)": string;
    "CARTA-CONTRATACION-BANCO-SANTANDER---EMPRESAS-ASISA-DENTAL-FAMILIAR-(C.-CERRADO)": string;
    "CARTA-PROTECCION-DATOS-NINTENDO": string;
    "CARTA-ASISA-SALUD-PROFESIONALES": string;
    "CARTA-INFORMACION-ASISA-ACCIDENTES": string;
    "CARTA-INFORMACION-ASISA-ACCIDENTES-PLUS": string;
    "CARTA-CONDICIONES-CONTRATACION-EL-CORTE-INGLES": string;
    "CARTA-DE-BIENVENIDA-PARA-COLECTIVOS-ABIERTOS-DENTALES-DE-BANCA-MARCH": string;
    "CARTA-DE-BIENVENIDA-PARA-COLECTIVOS-CERRADOS-DENTALES-DE-BANCA-MARCH": string;
    "CARTA-CONTRATACION-ASISA-DENTAL-C.-CERRADOS": string;
    "CARTA-ACUERDO-DE-DISTRIBUCION-BANCA-MARCH": string;
    "CARTA-GRUPO-INDITEX": string;
    "CARTA-FUNDACION-UNIVERSIDAD.ES": string;
    "CARTA-INFORMACION-GALP": string;
    "CARTA-BIENVENIDA-COLECTIVO-AUTORIDAD-PORTUARIA-DE-LAS-PALMAS": string;
    "CARTA-INFORMACION-ASISA-MOMENTO": string;
    "CARTA-CONTRATACION-ASISA-INTERNACIONAL": string;
    "CARTA-INFORMACION-ASISA-INTERNACIONAL": string;
    "CARTA-BIENVENIDA-DENTAL-FAM-CAMINOS": string;
    "CARTA-BIENVENIDA-ASISA-DENTAL-BANCA-MARCH": string;
    "CARTA-BIENVENIDA-UNIVERSIDAD-INTERNACIONAL-DE-ANDALUCIA": string;
    "CARTA-SALUD-COLECTIVOS-NACIONALES-ASTURIAS": string;
    "CARTA-MIGRACION-ASTURIAS-FAM-CAMINOS": string;
    "CARTA-MIGRACION-ASTURIAS-ACUERDO-DISTRIBUCION-SANTANDER": string;
    "CARTA-MIGRACION-ASTURIAS-SANTANDER-EMPLEADOS": string;
    "CARTA-MIGRACION-ASTURIAS-RESTO-COLECTIVOS": string;
    "CARTA-BIENVENIDA-GRUPO-IRIZAR": string;
    "CARTA-BIENVENIDA-CASTELLANO-GRUPO-IRIZAR": string;
    "CARTA-BIENVENIDA-GRUPO-NISSAN": string;
    "CARTA-ENVIO-GARANTIA-DENTAL-CORTE-INGLES-WP-PROVISIONAL": string;
    "CARTA-INFORMACION-ASISA-ACTIVA": string;
    "CARTA-BIENVENIDA-AYUNTAMIENTO-DE-LUCENA": string;
    "CARTA-BIENVENIDA-ASISA-CORPORACION-DIRECTA": string;
    "CARTA-BIENVENIDA-ASISA-CORPORACION-SIN-POSTVENTA": string;
    "CARTA-PACK-SIMPLIFICADO-TARJETA-+-CP-CORTE-INGLES-CESS": string;
    "CARTA-CONTRATACION-BP-OIL-ESPAÑA-PACK-ELECTRONICO": string;
    "CARTA-PACK-ASISA-DENTAL-PORTUGAL": string;
    "CARTA-PARA-TARJETA-DENTAL-PORTUGAL": string;
    "CARTA-CONTRATACION-ELECTRONICA": string;
    "CARTA-CONTRATACION-ELECTRONICA-ACTIVA-BCN": string;
    "CARTA-CONTRATACION-ASISER-CARD": string;
    "CARTA-PARA-TARJETA-ASISER-CARD": string;
    "CARTA-GARANTIA-COLECTIVO-BANCO-DE-SANTANDER-UNIVERSIDADES-4066": string;
    "CARTA-GARANTIA-COLECTIVO-BANCO-DE-SANTANDER-UNIVERSIDADES-4067": string;
    "CARTA-PACK-ASISA-DENTAL-PORTUGAL-APLICALIA": string;
    "CARTA-BIENVENIDA-CONSEJERIA-EDUCACION-Y-CULTURA-ASTURIAS": string;
    "CARTA-BIENVENIDA-MINISTERIO-EMPLEO-Y-SEGURIDAD-SOCIAL": string;
    "CARTA-CONTRATO-DIGITAL-ASISA-SALUD": string;
    "CARTA-CONTRATO-DIGITAL-ASISA-SALUD-FAMILIAR": string;
    "CARTA-CONTRATO-DIGITAL-ASISA-MASTER": string;
    "CARTA-CONTRATO-DIGITAL-ASISA-UTIL": string;
    "CARTA-CONTRATO-DIGITAL-ASISA-PROXIMA": string;
    "CARTA-CONTRATO-DIGITAL-ASISA-INTEGRAL": string;
    "CARTA-CONTRATO-DIGITAL-ASISA-AUTONOMOS": string;
    "CARTA-CONTRATO-DIGITAL-ASISA-ESTRELLA": string;
    "CARTA-CONTRATO-DIGITAL-ASISA-ACTIVA": string;
    "CARTA-CONTRATO-DIGITAL-ASISA-MOMENTO": string;
    "CARTA-CONTRATO-DIGITAL-ASISA-INTEGRAL-PYMES": string;
    "CARTA-CESS-SALUDCOR-CON-DENTAL": string;
    "CARTA-CESS-SALUDCOR-SIN-DENTAL": string;
    "CARTA-SECI-SALUDCOR-CON-DENTAL": string;
    "CARTA-SECI-SALUDCOR-SIN-DENTAL": string;
    "CARTA-SECI": string;
    "CARTA-CESS-SALUDCOR-CON-DENTAL-CANTANBRIA": string;
    "CARTA-CESS-SALUDCOR-CANTABRIA": string;
    "CARTA-CESS-SALUDCOR-CON-DENTAL-NAVARRA": string;
    "CARTA-CESS-SALUDCOR-NAVARRA": string;
    "CARTA-CONTRATACION-ASEGURADOS-COLECTIVO-DRAGADOS-(SIN-CP)": string;
    "CARTA-CONTRATACION-ASEGURADOS-COLECTIVO-ISEMAPI": string;
    "CARTA-PARA-TARJETA-ASISER-CARD-LEVANTE": string;
    "CARTA-BIENVENIDA-ESTUDIANTES-PACK-FISICO": string;
    "CARTA-BIENVENIDA-ESTUDIANTES-PACK-DIGITAL": string;
    "CARTA-CONTRATACION-PACK-ELECTRONICO-ASEGURADOS-COLECTIVOS-ABIERTOS-(CON-CP)": string;
    "CARTA-CONTRATACIÓN-FÍSICA-ACTIVA-BCN": string;
    "CARTA-CONTRATACIÓN-FÍSICA-GENERAL-OPTICA": string;
    "CARTA-CONTRATACIÓN-ELECTRONICA-GENERAL-OPTICA": string;
    "CARTA-BIENVENIDA-AYUNTAMIENTO-DE-SALOBREÑA": string;
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE": string;
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-COLFUTURO": string;
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-BECAR-DOCTORANDOS": string;
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-BECAR-MAESTRIAS-2015": string;
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-BECAR-MAESTRIAS-CIENCIAS-FORESTALES": string;
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-BECAR-MAESTRIAS-CIENCIA-Y-TECNOLOGIA": string;
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-BECAR-CURSOS-CORTOS-CIENCIAS-DEL-MAR": string;
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-DON-CARLOS-ANTONIO-LOPEZ": string;
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-BECAR-DOCTORANDOS-2015": string;
    "CARTA-BIENVENIDA-PACK-FISICO-ESTUDIANTES-COLECTIVO-22353": string;
    "CARTA-BIENVENIDA-PACK-ELECTRÓNICO-ESTUDIANTES-COLECTIVO-22353": string;
    "CARTA-BIENVENIDA-CLUB-ALBACETE-COELCTIVO-5717": string;
    "CARTA-BIENVENIDA-CLUB-ALBACETE-DENTAL": string;
    "CARTA-BIENVENIDA-ASISA-DECESOS": string;
    "CARTA-PACK-ASISA-DENTAL-PORTUGAL-APRIL": string;
    "CARTA-BIENVENIDA-AYUNTAMIENTO-CONCENTAINA": string;
    "CARTA-BIENVENIDA-GLOBALIA": string;
    "CARTA-CONTRATACION-ASISA-VIDA-MUJER": string;
    "CARTA-CONTRATACION-ASISA-VIDA-TRANQUILIDAD": string;
    "CARTA-CONTRATACION-ASISA-VIDA-TRANQUILIDAD-PLUS": string;
    "CARTA-CONTRATACION-ASISA-VIDA-RETORNO": string;
    "CARTA-CONTRATACION-GRUPO-BARCELO": string;
    "CARTA-INFORMACION-ASISA-INTEGRAL-1.000.000": string;
    "CARTA-PACK-SIMPLIFICADO-TARJETA-+-CP-CORTE-INGLES-SECI": string;
    "CARTA-BIENVENIDA-CLUB-ALBACETE-DEPORTIVO": string;
    "CARTA-BIENVENIDA-ASISA-MONTEPIO": string;
    "CARTA-BIENVENIDA-ASISA-COLECTIVO-PREVIMUTUA": string;
    "CARTA-DE-NOTA-INFORMATIVA-BANCO-CAMINOS": string;
    "CARTA-BIENVENIDA-ASISA-COLECTIVO-ONCE": string;
    "CARTA-BIENVENIDA-ASISA-COLECTIVO-MUTUALIDAD-DE-VIAJANTES": string;
    "CARTA-BIENVENIDA-ASISA-MASCOTAS": string;
    "CARTA-BIENVENIDA-ASISA-VIDA-PORTUGAL": string;
    "CARTA-BIENVENIDA-ASISA-DENTAL-SUCURSAL-PORTUGAL": string;
    "CARTA-BIENVENIDA-ASISA-COLECTIVO-AECID": string;
    "CARTA-BIENVENIDA-ASISA-PALMA-DE-MALLORCA-CATALAN": string;
    "CARTA-BIENVENIDA-ASISA-DENTAL-FAMILIAR-SUCURSAL-PORTUGAL": string;
    "CARTA-BIENVENIDA-ASISA-DIPUTACION-DE-LERIDA": string;
    "CARTA-BIENVENIDA-ASISA-DIPUTACION-DE-LERIDA-CATALAN": string;
    "CARTA-BIENVENIDA-ADELANTO-PROVISIONAL-PYMES": string;
    "CARTA-BIENVENIDA-ASISA-COLECTIVO-FABRICA-NACIONAL-MONEDA-Y-TIMBRE": string;
    "CARTA-CONTRATACION-ASISA-VIDA-ACCIDENTES-COMPROMISO-10": string;
    "CARTA-CONTRATACION-ASISA-MUFACE-AYUNTAMIENTO-DE-GRANADA": string;
    "CARTA-CONTRATACION-ASISA-AYUNTAMIENTO-DE-GRANADA-SALUD-ABIERTO-ELECTRONICO": string;
    "CARTA-CONTRATACION-ASISA-AYUNTAMIENTO-DE-GRANADA-SALUD-ABIERTO-FISICO": string;
    "CARTA-BIENVENIDA-ASISA-VIDA-ACCIDENTES-10-PORTUGAL": string;
    "CARTA-CONTRATACION-ASISA-TRATAMIENTO-BUCODENTAL": string;
    "CARTA-CONTRATACION-ASISA-TRAVEL-AND-YOU-Y-STUDY": string;
    "CARTA-CONTRATACION-ASISA-TRAVEL-ALL-YEAR": string;
    "CARTA-CONTRATACION-ASISA-SALUD-PELAYO": string;
    "CARTA-CONTRATACION-ASISA-DENTAL-PELAYO": string;
    "CARTA-BIENVENIDA-ASISA-VIDA-PORTUGAL-CCAM": string;
    "CARTA-CONTRATACION-ASISA-SALUD-SEPIE": string;
    [key: string]: string;
}

export const ASISA_ITEM: asisaItem = {
    "PRIMERA-HOJA-PACK" : "PRIMERA HOJA PACK",
    "PRIMERA-HOJA-PACK-CATALAN" : "PRIMERA HOJA PACK CATALAN",
    "HOJA-TARJETAS-SALUD" : "HOJA TARJETAS SALUD",
    "HOJA-TARJETAS-DENTAL": "HOJA TARJETAS DENTAL",
    "HOJA-INDICE" : "HOJA INDICE",
    "HOJA-INDICE-CATALAN" : "HOJA INDICE CATALAN",
    "HOJA-CD-ASISA-SALUD" : "HOJA CD ASISA SALUD",
    "HOJA-CD-ASISA-SALUD-Y-DENTAL-SEVILLA": "HOJA CD ASISA SALUD Y DENTAL SEVILLA",
    "HOJA-CD-ASISA-DENTAL": "HOJA CD ASISA DENTAL",
    "HOJA-CD-ASISA-SALUD-Y-DENTAL": "HOJA CD ASISA SALUD Y DENTAL",
    "HOJA-CD-ASISA-SALUD-Y-DENTAL-CATALAN": "HOJA CD ASISA SALUD Y DENTAL CATALAN",
    "CONDICIONES-ESPECIALES-HISDESAT" : "CONDICIONES ESPECIALES HISDESAT",
    "CONDICIONES-ESPECIALES-REEMBOLSO-CAJA-DE-CREDITO-DE-INGENIEROS": "CONDICIONES ESPECIALES REEMBOLSO CAJA DE CREDITO DE INGENIEROS",
    "CONDICIONES-ESPECIALES-SALUD-CAJA-DE-CREDITO-DE-INGENIEROS": "CONDICIONES ESPECIALES SALUD CAJA DE CREDITO DE INGENIEROS",
    "CONDICIONES-ESPECIALES-GRUPO-MGO": "CONDICIONES ESPECIALES GRUPO MGO",
    "HOJA-DE-CD-ASISA-SALUD-Y-DENTAL-A-CORUÑA": "HOJA DE CD ASISA SALUD Y DENTAL A CORUÑA",
    "CONDICIONES-ESPECIALES-IBERMUTUATUR" : "CONDICIONES ESPECIALES IBERMUTUATUR",
    "CONDICIONES-ESPECIALES-BANCO-POPULAR": "CONDICIONES ESPECIALES BANCO POPULAR",
    "CONDICIONES-ESPECIALES-MOLNLYCKE-HEALT-CARE" : "CONDICIONES ESPECIALES MOLNLYCKE HEALT CARE",
    "ANEXO-MODIFICATIVO-HOLCIM" : "ANEXO MODIFICATIVO HOLCIM",
    "FRANQUICIAS-GRUPO-TRAGSA": "FRANQUICIAS GRUPO TRAGSA",
    "CONDICIONES-ESPECIALES-ALPARGATAS-EUROPE-SLU": "CONDICIONES ESPECIALES ALPARGATAS EUROPE SLU",
    "CONDICIONES-ESPECIALES-COLECTIVO-INAER": "CONDICIONES ESPECIALES COLECTIVO INAER",
    "CONDICIONES-PARTICULARES-COLECTIVO-INAER": "CONDICIONES PARTICULARES COLECTIVO INAER",
    "CONDICIONES-ASISA-INTEGRAL-BANCA-MARCH": "CONDICIONES ASISA INTEGRAL BANCA MARCH",
    "DOCUMENTO-2-ANEXO-SISTEMAS-INFORMATICOS-ABIERTOS": "DOCUMENTO 2 ANEXO SISTEMAS INFORMATICOS ABIERTOS",
    "CONDICIONES-PARTICULARES-COLECTIVO-SIA": "CONDICIONES PARTICULARES COLECTIVO SIA",
    "CONDICIONES-ESPECIALES-GRUPO-PUMA" : "CONDICIONES ESPECIALES GRUPO PUMA",
    "DOCUMENTO-1-ANEXO-SISTEMAS-INFORMATICOS-ABIERTOS": "DOCUMENTO 1 ANEXO SISTEMAS INFORMATICOS ABIERTOS",
    "CONDICIONES-ESPECIALES-ALLEGRO-SYSTEM" : "CONDICIONES ESPECIALES ALLEGRO SYSTEM",
    "CUADRO-LIMITES-REEMBOLSO-1.000.000-€": "CUADRO LIMITES REEMBOLSO 1.000.000 €",
    "CONDICIONES-ESPECIALES-INTEGRAL-PWC" : "CONDICIONES ESPECIALES INTEGRAL PWC",
    "SOLICITUD-ADHESION-AYUNTAMIENTO-SEVILLA" : "SOLICITUD ADHESION AYUNTAMIENTO SEVILLA",
    "CONDICIONES-GENERALES-ASISA-SALUD-BANCO-DE-SANTANDER": "CONDICIONES GENERALES ASISA SALUD BANCO DE SANTANDER",
    "NOTA-INFORMATIVA-BANCO-DE-SANTANDER" : "NOTA INFORMATIVA BANCO DE SANTANDER",
    "HOJA-TARJETA-ASISA-SALUD-BANCO-DE-SANTANDER" : "HOJA TARJETA ASISA SALUD BANCO DE SANTANDER",
    "ASISTENCIA-EN-VIAJE-BANCO-DE-SANTANDER": "ASISTENCIA EN VIAJE BANCO DE SANTANDER",
    "CONDICIONES-GENERALES-ASISA-DENTAL-FAMILIAR-BANCO-SANTANDER" : "CONDICIONES GENERALES ASISA DENTAL FAMILIAR BANCO SANTANDER",
    "PRIMERA-HOJA-PACK-BANCO-SANTANDER" : "PRIMERA HOJA PACK BANCO SANTANDER",
    "CONDICIONES-GENERALES-ASISA-SALUD-BANCO-DE-SANTANDER-IGUALATORIO": "CONDICIONES GENERALES ASISA SALUD BANCO DE SANTANDER IGUALATORIO",
    "HOJA-TARJETA-ASISA-SALUD-BANCO-DE-SANTANDER-IGUALATORIO" : "HOJA TARJETA ASISA SALUD BANCO DE SANTANDER IGUALATORIO",
    "ASISTENCIA-EN-VIAJE-BANCO-DE-SANTANDER-IGUALATORIO": "ASISTENCIA EN VIAJE BANCO DE SANTANDER IGUALATORIO",
    "CONDICIONES-ESPECIALES-BEIERSDORF" : "CONDICIONES ESPECIALES BEIERSDORF",
    "CONDICIONES-PARTICULARES-GENERICAS-PROVISIONALES-PACK" : "CONDICIONES PARTICULARES GENERICAS PROVISIONALES PACK",
    "CONDICIONES-GENERALES-ASISA-ESENCIAL-BANCO-DE-SANTANDER" : "CONDICIONES GENERALES ASISA ESENCIAL BANCO DE SANTANDER",
    "CONDICIONES-GENERALES-ASISA-ESENCIAL-BANCO-DE-SANTANDER-IGUALATORIO" : "CONDICIONES GENERALES ASISA ESENCIAL BANCO DE SANTANDER IGUALATORIO",
    "CUESTIONARIO-DE-SALUD-AYUNTAMIENTO-DE-SEVILLA" : "CUESTIONARIO DE SALUD AYUNTAMIENTO DE SEVILLA",
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-COLECTIVOS": "CONDICIONES ESPECIALES ASISA INTEGRAL COLECTIVOS",
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-COLECTIVOS-EN-INGLES": "CONDICIONES ESPECIALES ASISA INTEGRAL COLECTIVOS EN INGLES",
    "CONDICIONES-ESPECIALES-SALUD-PWC": "CONDICIONES ESPECIALES SALUD PWC",
    "CONDICIONES-ESPECIALES-DEUTSCHE-PFANDBRIEFBANK-AG" : "CONDICIONES ESPECIALES DEUTSCHE PFANDBRIEFBANK AG",
    "CUADRO-LIMITES-REEMBOLSO-INAER": "CUADRO LIMITES REEMBOLSO INAER",
    "REEMBOLSO-DE-GASTOS-FARMACEUTICOS-SIA" : "REEMBOLSO DE GASTOS FARMACEUTICOS SIA",
    "HOJA-FELIZ-AÑO-NUEVO---NAVIDAD": "HOJA FELIZ AÑO NUEVO - NAVIDAD",
    "HOJA-TARJETAS-SALUD-BANCO-SANTANDER-EXCLUSIVE" : "HOJA TARJETAS SALUD BANCO SANTANDER EXCLUSIVE",
    "CONDICIONES-GENERALES-SANTANDER-EXCLUSIVE" : "CONDICIONES GENERALES SANTANDER EXCLUSIVE",
    "CONDICIONES-GENERALES-BANCO-DE-SANTANDER-EMPRESAS" : "CONDICIONES GENERALES BANCO DE SANTANDER EMPRESAS",
    "CONDICIONES-ESPECIALES-GRUPO-RTVE" : "CONDICIONES ESPECIALES GRUPO RTVE",
    "CONDICIONES-ESPECIALES-CITI" : "CONDICIONES ESPECIALES CITI",
    "OPERATIVA-SOLICITUD-REEMBOLSO-DE-GASTOS-CITI": "OPERATIVA SOLICITUD REEMBOLSO DE GASTOS CITI",
    "OPERATIVA-SOLICITUD-REEMBOLSO-DE-GASTOS" : "OPERATIVA SOLICITUD REEMBOLSO DE GASTOS",
    "CONDICIONES-ESPECIALES-INDRA": "CONDICIONES ESPECIALES INDRA",
    "CONDICIONES-ESPECIALES-LABORATORIOS-INDAS" : "CONDICIONES ESPECIALES LABORATORIOS INDAS",
    "NOTA-INFORMATIVA-BANCO-DE-SANTANDER-EMPRESAS": "NOTA INFORMATIVA BANCO DE SANTANDER EMPRESAS",
    "HOJA-SEGURO-ROTURA-PROTESIS-MAMARIA" : "HOJA SEGURO ROTURA PROTESIS MAMARIA",
    "CERTIFICADO-SEGURO-ROTURA-DE-PROTESIS-MAMARIA" : "CERTIFICADO SEGURO ROTURA DE PROTESIS MAMARIA",
    "CONDICIONES-GENERALES-SEGURO-ROTURA-DE-PROTESIS-MAMARIAS": "CONDICIONES GENERALES SEGURO ROTURA DE PROTESIS MAMARIAS",
    "GARANTIA-DE-ASESORAMIENTO-DE-ASISER" : "GARANTIA DE ASESORAMIENTO DE ASISER",
    "SOLICITUD-DE-SEGURO-COLECTIVO-FAMILIARES-DE-EMPLEADOS-FECYT" : "SOLICITUD DE SEGURO COLECTIVO FAMILIARES DE EMPLEADOS FECYT",
    "CUESTIONARIO-DE-SALUD-COLECTIVO-FAMILIARES-DE-EMPLEADOS-DE-FECYT": "CUESTIONARIO DE SALUD COLECTIVO FAMILIARES DE EMPLEADOS DE FECYT",
    "SOLICITUD-DE-SEGURO-COLECTIVOS-PACK" : "SOLICITUD DE SEGURO COLECTIVOS PACK",
    "HOJA-TARJETA-DENTAL-FAMILIAR-BANCO-SANTANDER": "HOJA TARJETA DENTAL FAMILIAR BANCO SANTANDER",
    "CONDICIONES-GENERALES-ASISA-SALUD-BANCO-SANTANDER-EMPLEADOS" : "CONDICIONES GENERALES ASISA SALUD BANCO SANTANDER EMPLEADOS",
    "CONDICIONES-ESPECIALES-ASISA-VIDA-RETORNO" : "CONDICIONES ESPECIALES ASISA VIDA RETORNO",
    "FRANQUICIAS-DENTALES-PLUS-BANCO-DE-SANTANDER": "FRANQUICIAS DENTALES PLUS BANCO DE SANTANDER",
    "FRANQUICIAS-DENTAL-ASISA-FAMILIAR-BANCO-SANTANDER" : "FRANQUICIAS DENTAL ASISA FAMILIAR BANCO SANTANDER",
    "HOJA-AGENTES-CORPORACION-DIRECTA": "HOJA AGENTES CORPORACION DIRECTA",
    "HOJA-TARJETAS-CORPORACION-DIRECTA" : "HOJA TARJETAS CORPORACION DIRECTA",
    "CONDICIONES-GENERALES-ASISA-VIDA-RETORNO": "CONDICIONES GENERALES ASISA VIDA RETORNO",
    "PRIMERA-HOJA-ASISA-DENTAL" : "PRIMERA HOJA ASISA DENTAL",
    "PRIMERA-HOJA-ASISA-DENTAL-BANCO-SANTANDER" : "PRIMERA HOJA ASISA DENTAL BANCO SANTANDER",
    "CONDICIONES-GENERALES-ASISA-SALUD-BANCO-DE-SANTANDER-V2" : "CONDICIONES GENERALES ASISA SALUD BANCO DE SANTANDER V2",
    "CONDICIONES-ESPECIALES-INTEGRAL-ENRESA": "CONDICIONES ESPECIALES INTEGRAL ENRESA",
    "FRANQUICIAS-DENTALES-ESPECIALES-ENRESA": "FRANQUICIAS DENTALES ESPECIALES ENRESA",
    "CARTA-GARANTIAS-ADICIONALES-ASISA-SALUD-BEIERSDORF": "CARTA GARANTIAS ADICIONALES ASISA SALUD BEIERSDORF",
    "GUIA-OPERATIVA-GASTOS-DE-SEPELIO": "GUIA OPERATIVA GASTOS DE SEPELIO",
    "CONDICIONES-ESPECIALES-SALUD-ENRESA" : "CONDICIONES ESPECIALES SALUD ENRESA",
    "HOJA-PROTOCOLO-ACCESO-CLINICA-UNIVERSITARIA-DE-NAVARRA": "HOJA PROTOCOLO ACCESO CLINICA UNIVERSITARIA DE NAVARRA",
    "CARTA-ASISTENCIAL" : "CARTA ASISTENCIAL",
    "CARTA-COBERTURA-REPATRIACION-POR-FALLECIMIENTO": "CARTA COBERTURA REPATRIACION POR FALLECIMIENTO",
    "HOJA-AGENTES-BARYMONT" : "HOJA AGENTES BARYMONT",
    "HOJA-CD-ASISA-SALUD-ISFAS" : "HOJA CD ASISA SALUD ISFAS",
    "HOJA-TARJETA-ASISA-ORO-PROFESIONALES": "HOJA TARJETA ASISA ORO PROFESIONALES",
    "PROGRAMA-DE-VENTAJAS-PROFESIONALES-LAVINIA": "PROGRAMA DE VENTAJAS PROFESIONALES LAVINIA",
    "SERVICIO-DE-ASISTENCIA-LEGAL": "SERVICIO DE ASISTENCIA LEGAL",
    "SEGUNDA-OPINION-MEDICA": "SEGUNDA OPINION MEDICA",
    "ASISTENCIA-EN-VIAJES-PARA-MEDICOS" : "ASISTENCIA EN VIAJES PARA MEDICOS",
    "NUEVAS-CONDICIONES-GENERALES-ASISA-HOSPITALIZACION-PROFESIONALES": "NUEVAS CONDICIONES GENERALES ASISA HOSPITALIZACION PROFESIONALES",
    "CONDICIONES-ESPECIALES-ASISA-INEGRAL-180.000-FAURECIA" : "CONDICIONES ESPECIALES ASISA INEGRAL 180.000 FAURECIA",
    "HOJA-SERVICIO-POSTVENTA-FAURECIA-SALUD": "HOJA SERVICIO POSTVENTA FAURECIA SALUD",
    "HOJA-SERVICIO-POSVENTA-FAURECIA-REEMBOLSO" : "HOJA SERVICIO POSVENTA FAURECIA REEMBOLSO",
    "PLAN-DE-PENSIONES-COOPERATIVISTAS-LAVINIA" : "PLAN DE PENSIONES COOPERATIVISTAS LAVINIA",
    "HOJA-TARJETA-SALUD-FAM-CAMINOS": "HOJA TARJETA SALUD FAM CAMINOS",
    "EXTRACTO-CONTRATO-POLIZA-FAM-CAMINOS": "EXTRACTO CONTRATO POLIZA FAM CAMINOS",
    "CONDICIONES-GENERALES-ASISA-DENTAL-PLUS-BANCO-SANTANDER" : "CONDICIONES GENERALES ASISA DENTAL PLUS BANCO SANTANDER",
    "HOJA-TARJETAS-SALUD-BANCA-MARCH" : "HOJA TARJETAS SALUD BANCA MARCH",
    "FRANQUICIA-DENTAL-BANCO-SANTANDER" : "FRANQUICIA DENTAL BANCO SANTANDER",
    "CONDICIONES-ESPECIALES-GRUPO-DTA": "CONDICIONES ESPECIALES GRUPO DTA",
    "CONDICIONES-ESPECIALES-ELECTRICA-DE-CADIZ" : "CONDICIONES ESPECIALES ELECTRICA DE CADIZ",
    "CONDICIONES-ESPECIALES-DEISER" : "CONDICIONES ESPECIALES DEISER",
    "CONDICIONES-ESPECIALES-ASOCIACION-DE-TECNICOS-Y-ECONOMISTAS-DEL-ESTADO": "CONDICIONES ESPECIALES ASOCIACION DE TECNICOS Y ECONOMISTAS DEL ESTADO",
    "CONDICIONES-ESPECIALES-PARTIDO-POPULAR": "CONDICIONES ESPECIALES PARTIDO POPULAR",
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-MIJAS": "CONDICIONES ESPECIALES AYUNTAMIENTO DE MIJAS",
    "CLAUSULA-ENFERMEDADES-GRAVES-ASISA-COMO-CUESTIONARIO": "CLAUSULA ENFERMEDADES GRAVES ASISA COMO CUESTIONARIO",
    "CONDICIONES-ESPECIALES-BANGES" : "CONDICIONES ESPECIALES BANGES",
    "CONDICIONES-ESPECIALES-HUSQVARNA": "CONDICIONES ESPECIALES HUSQVARNA",
    "DOCUMENTO-CIRUGIA-AMBULATORIA-MARTINEZ-SOLE" : "DOCUMENTO CIRUGIA AMBULATORIA MARTINEZ SOLE",
    "COBERTURA-REEMBOLSO-GASTOS-FARMACEUTICOS": "COBERTURA REEMBOLSO GASTOS FARMACEUTICOS",
    "GARANTIAS-ESPECIALES-COLECTIVOS-MIJAS" : "GARANTIAS ESPECIALES COLECTIVOS MIJAS",
    "EXTRACTO-RESUMEN-POLIZA-FAM-CAMINOS" : "EXTRACTO RESUMEN POLIZA FAM CAMINOS",
    "SOLICITUD-DE-HOSPITALIZACION": "SOLICITUD DE HOSPITALIZACION",
    "SOLICITUD-ACCIDENTES-PLUS" : "SOLICITUD ACCIDENTES PLUS",
    "SOLICITUD-ACCIDENTES": "SOLICITUD ACCIDENTES",
    "HOJA-DE-TARJETA-DE-CAJA-DE-INGENIEROS" : "HOJA DE TARJETA DE CAJA DE INGENIEROS",
    "CONDICIONES-ESPECIALES-ROURA-CEVASA" : "CONDICIONES ESPECIALES ROURA CEVASA",
    "CONDICIONES-ESPECIALES-LEASBROK" : "CONDICIONES ESPECIALES LEASBROK",
    "HOJA-TARJETA-START-SALUD-DENTAL" : "HOJA TARJETA START SALUD DENTAL",
    "FRANQUICIAS-DENTALES-START-SALUD": "FRANQUICIAS DENTALES START SALUD",
    "CONDICIONES-ESPECIALES-FOREIGN": "CONDICIONES ESPECIALES FOREIGN",
    "CONDICIONES-ESPECIALES-ALPHANUMERIC" : "CONDICIONES ESPECIALES ALPHANUMERIC",
    "HOJA-CD-ASISA-SALUD-BANCA-MARCH" : "HOJA CD ASISA SALUD BANCA MARCH",
    "DETALLE-COBERTURAS-FUNDACION-UNIVERSIDAD.ES" : "DETALLE COBERTURAS FUNDACION UNIVERSIDAD.ES",
    "REPATRIACION-FUNDACION-UNIVERSIDAD.ES" : "REPATRIACION FUNDACION UNIVERSIDAD.ES",
    "INSTRUCCIONES-ASEGURADOS-FUNDACION-UNIVERSIDAD.ES" : "INSTRUCCIONES ASEGURADOS FUNDACION UNIVERSIDAD.ES",
    "CONDICIONES-GENERALES-ASISA-DENTAL-3": "CONDICIONES GENERALES ASISA DENTAL 3",
    "CONDICIONES-GENERALES-ASISA-MOMENTO" : "CONDICIONES GENERALES ASISA MOMENTO",
    "CUESTIONARIO-GALP-ENERGIA" : "CUESTIONARIO GALP ENERGIA",
    "COBERTURAS-DESTACADAS-AUTORIDAD-PORTUARIA-DE-LAS-PALMAS" : "COBERTURAS DESTACADAS AUTORIDAD PORTUARIA DE LAS PALMAS",
    "SOLICITUD-DE-SEGURO-AUTORIDAD-PORTUARIA-DE-LAS-PALMAS" : "SOLICITUD DE SEGURO AUTORIDAD PORTUARIA DE LAS PALMAS",
    "CUESTIONARIO-DE-SALUD-AUTORIDAD-PORTUARIA-DE-LAS-PALMAS" : "CUESTIONARIO DE SALUD AUTORIDAD PORTUARIA DE LAS PALMAS",
    "CONDICIONES-ESPECIALES-YECLA": "CONDICIONES ESPECIALES YECLA",
    "CONDICIONES-GENERALES-ASISA-INTERNACIONAL" : "CONDICIONES GENERALES ASISA INTERNACIONAL",
    "CONDICIONES-GENERALES-ASISA-INTERNACIONAL-EN-INGLES" : "CONDICIONES GENERALES ASISA INTERNACIONAL EN INGLES",
    "CONDICIONES-ESPECIALES-ASISA-INTERNACIONAL-AMPLIADO" : "CONDICIONES ESPECIALES ASISA INTERNACIONAL AMPLIADO",
    "CONDICIONES-ESPECIALES-ASISA-INTERNACIONAL-AMPLIADO-2015-EN-INGLES": "CONDICIONES ESPECIALES ASISA INTERNACIONAL AMPLIADO 2015 EN INGLES",
    "CONDICIONES-ESPECIALES-ASISA-INTERNACIONAL-BASICO" : "CONDICIONES ESPECIALES ASISA INTERNACIONAL BASICO",
    "CONDICIONES-ESPECIALES-ASISA-INTERNACIONAL-BASICO-EN-INGLES" : "CONDICIONES ESPECIALES ASISA INTERNACIONAL BASICO EN INGLES",
    "CONDICIONES-ESPECIALES-ASISA-INTERNACIONAL-INTEGRAL" : "CONDICIONES ESPECIALES ASISA INTERNACIONAL INTEGRAL",
    "CONDICIONES-ESPECIALES-ASISA-INTERNACIONAL-INTEGRAL-EN-INGLES" : "CONDICIONES ESPECIALES ASISA INTERNACIONAL INTEGRAL EN INGLES",
    "CONDICIONES-ESPECIALES-COLECTIVO-TIBIDABO" : "CONDICIONES ESPECIALES COLECTIVO TIBIDABO",
    "CONDICIONES-ESPECIALES-YUME" : "CONDICIONES ESPECIALES YUME",
    "SOLICITUD-DE-SEGURO-ASISA-INTERNACIONAL" : "SOLICITUD DE SEGURO ASISA INTERNACIONAL",
    "FRANQUICIA-DENTAL-BENEFITS": "FRANQUICIA DENTAL BENEFITS",
    "FRANQUICIAS-ASISA-DENTAL-PURO-FAM-CAMINOS" : "FRANQUICIAS ASISA DENTAL PURO FAM CAMINOS",
    "GARANTIAS-ADICIONALES-SATISFACCION-GRUPO-VECTOR" : "GARANTIAS ADICIONALES SATISFACCION GRUPO VECTOR",
    "FAQ-MUTUALISTAS" : "FAQ MUTUALISTAS",
    "COBERTURAS-DESTACADAS-UNIVERSIDAD-INTERNACIONAL-DE-ANDALUCIA": "COBERTURAS DESTACADAS UNIVERSIDAD INTERNACIONAL DE ANDALUCIA",
    "SERVICIO-DE-URGENCIAS-Y-EMERGENCIAS" : "SERVICIO DE URGENCIAS Y EMERGENCIAS",
    "CONDICIONES-ESPECIALES-PARA-GRUPO-IRIZAR": "CONDICIONES ESPECIALES PARA GRUPO IRIZAR",
    "CONDICIONES-GENERALES-GRUPO-IRIZAR": "CONDICIONES GENERALES GRUPO IRIZAR",
    "CONDICIONES-ESPECIALES-GRUPO-NISSAN" : "CONDICIONES ESPECIALES GRUPO NISSAN",
    "CONDICIONES-ESPECIALES-EQMEDIAXL": "CONDICIONES ESPECIALES EQMEDIAXL",
    "CODIGO-QR-CUADRO-MEDICO-SALUD" : "CODIGO QR CUADRO MEDICO SALUD",
    "CONDICIONES-ESPECIALES-GASTOS-FARMACEUTICOS" : "CONDICIONES ESPECIALES GASTOS FARMACEUTICOS",
    "CONDICIONES-ESPECIALES-REYAL-URBIS": "CONDICIONES ESPECIALES REYAL URBIS",
    "TEXTO-PROMOCION-4-MESES-GRATIS-2015" : "TEXTO PROMOCION 4 MESES GRATIS 2015",
    "CONDICIONES-GENERALES-ASISA-ACTIVA": "CONDICIONES GENERALES ASISA ACTIVA",
    "CONDICIONES-GENERALES-ASISA-ACTIVA-EN-INGLES": "CONDICIONES GENERALES ASISA ACTIVA EN INGLES",
    "CONDICIONES-ESPECIALES-SMS-1-MILLON" : "CONDICIONES ESPECIALES SMS 1 MILLON",
    "HOJA-DE-TARJETAS-IBERMAN": "HOJA DE TARJETAS IBERMAN",
    "CONDICIONES-ESPECIALES-DIPUTACIÓN-DE-CADIZ": "CONDICIONES ESPECIALES DIPUTACIÓN DE CADIZ",
    "ATENCION-PERSONALIZADA-A-LOS-ASEGURADOS-DIPUTACION-DE-CADIZ" : "ATENCION PERSONALIZADA A LOS ASEGURADOS DIPUTACION DE CADIZ",
    "HOJA-TARJETAS-SALUD-DIPUTACION-CADIZ": "HOJA TARJETAS SALUD DIPUTACION CADIZ",
    "OFERTA-DIPUTACION-DE-CADIZ": "OFERTA DIPUTACION DE CADIZ",
    "FRANQUICIAS-DENTAL-PARA-SALUD-+-GARANTIA-FAM-CAMINOS": "FRANQUICIAS DENTAL PARA SALUD + GARANTIA FAM CAMINOS",
    "GARANTIAS-COMPLEMENTARIAS-DE-FARMACIA-SKY-SOLAR" : "GARANTIAS COMPLEMENTARIAS DE FARMACIA SKY SOLAR",
    "GARANTIAS-ADICIONALES-INTEGRAL-VECTOR" : "GARANTIAS ADICIONALES INTEGRAL VECTOR",
    "CONDICIONES-ESPECIALES-GRUPO-SEPIDES": "CONDICIONES ESPECIALES GRUPO SEPIDES",
    "EXCLUSION-DE-PARTOS-COBERTURAS-DE-SALUD" : "EXCLUSION DE PARTOS COBERTURAS DE SALUD",
    "REEMBOLSO-DE-FARMACIA-PARA-GENERICOS": "REEMBOLSO DE FARMACIA PARA GENERICOS",
    "FRANQUICIAS-GRUPO-SEPIDES" : "FRANQUICIAS GRUPO SEPIDES",
    "CODIGO-QR-CUADRO-MEDICO-DENTAL": "CODIGO QR CUADRO MEDICO DENTAL",
    "HOJA-EDA-PARA-APP" : "HOJA EDA PARA APP",
    "HOJA-EDA-PARA-APP-IMAGEN-ANTIGUA": "HOJA EDA PARA APP IMAGEN ANTIGUA",
    "HOJA-EDA-PARA-APP-CATALAN" : "HOJA EDA PARA APP CATALAN",
    "CONDICIONADO-GENERAL-POLIZA-ASISTENCIA-SANITARIA-BASIC": "CONDICIONADO GENERAL POLIZA ASISTENCIA SANITARIA BASIC",
    "CONDICIONES-GENERALES-SHELL-CLUBSMART" : "CONDICIONES GENERALES SHELL CLUBSMART",
    "PRIMERA-HOJA-PACK-CORTE-INGLES": "PRIMERA HOJA PACK CORTE INGLES",
    "FRANQUICIAS-POLIZA-DENTAL-FA-EMPRESAS-PACK-CORTE-INGLES" : "FRANQUICIAS POLIZA DENTAL FA EMPRESAS PACK CORTE INGLES",
    "CONDICIONES-ESPECIALES-GRUPO-SEPI" : "CONDICIONES ESPECIALES GRUPO SEPI",
    "CONDICIONES-ESPECIALES-GRUPO-SEPI-JUBILADOS" : "CONDICIONES ESPECIALES GRUPO SEPI JUBILADOS",
    "CONDICIONES-GENERALES-ASISA-MASTER-PACK-CORTE-INGLES": "CONDICIONES GENERALES ASISA MASTER PACK CORTE INGLES",
    "HOJA-TARJETAS-PUMA": "HOJA TARJETAS PUMA",
    "CONDICIONES-ESPECIALES-GRUPO-DRAGADOS" : "CONDICIONES ESPECIALES GRUPO DRAGADOS",
    "CONDICIONES-GENERALES-REPATRIACION-POR-FALLECIMIENTO": "CONDICIONES GENERALES REPATRIACION POR FALLECIMIENTO",
    "CONDICIONES-GENERALES-REPATRIACION-POR-FALLECIMIENTO-INGLES" : "CONDICIONES GENERALES REPATRIACION POR FALLECIMIENTO INGLES",
    "CONDICIONES-GENERALES-REPATRIACIÓN-POR-FALLECIMIENTO-Y-ENFERMEDAD-O-ACCIDENTE-GRAVE" : "CONDICIONES GENERALES REPATRIACIÓN POR FALLECIMIENTO Y ENFERMEDAD O ACCIDENTE GRAVE",
    "CONDICIONES-GENERALES-REPATRIACION-POR-FALLECIMIENTO,-ENFERMEDAD-Y-ACCIDENTE-GRAVE-INGLES" : "CONDICIONES GENERALES REPATRIACION POR FALLECIMIENTO, ENFERMEDAD Y ACCIDENTE GRAVE INGLES",
    "CONDICIONES-GENERALES-ASISA-DENTAL-PORTUGAL" : "CONDICIONES GENERALES ASISA DENTAL PORTUGAL",
    "CONDICIONES-PARTICULARES-ASISA-DENTAL-PORTUGAL": "CONDICIONES PARTICULARES ASISA DENTAL PORTUGAL",
    "SOBRE-RESPUESTA-DIGITAL" : "SOBRE RESPUESTA DIGITAL",
    "ORIENTACION-TELEFONICA-DENTAL-PORTUGAL": "ORIENTACION TELEFONICA DENTAL PORTUGAL",
    "ACCESO-ASISTENCIA-MEDICA-ESPAÑA-DENTAL-PORTUGAL" : "ACCESO ASISTENCIA MEDICA ESPAÑA DENTAL PORTUGAL",
    "ASISTENCIA-URGENTE-DENTAL-PORTUGAL": "ASISTENCIA URGENTE DENTAL PORTUGAL",
    "GUIA-RAPIDA-DENTAL-PORTUGAL" : "GUIA RAPIDA DENTAL PORTUGAL",
    "RAZONES-PARA-CONFIAR-EN-ASISA-DENTAL-PORTUGAL" : "RAZONES PARA CONFIAR EN ASISA DENTAL PORTUGAL",
    "RED-CLINICAS-DENTAL-PORTUGAL": "RED CLINICAS DENTAL PORTUGAL",
    "PRIMERA-HOJA-ASISER-CARD": "PRIMERA HOJA ASISER CARD",
    "CONDICIONES-GENERALES-ASISER-CARD" : "CONDICIONES GENERALES ASISER CARD",
    "CONDICIONES-PARTICULARES-ASISER-CARD": "CONDICIONES PARTICULARES ASISER CARD",
    "ASISER-CARD-CONTIGO" : "ASISER CARD CONTIGO",
    "CONDICIONES-PARTICULARES-SISNET" : "CONDICIONES PARTICULARES SISNET",
    "NOTA-INFORMATIVA-DENTAL-PORTUGAL-APLICALIA": "NOTA INFORMATIVA DENTAL PORTUGAL APLICALIA",
    "CONDICIONES-PARTICULARES-ASISA-DENTAL-PORTUGAL-APLICALIA": "CONDICIONES PARTICULARES ASISA DENTAL PORTUGAL APLICALIA",
    "CONDICIONES-GENERALES-ASISA-DENTAL-PORTUGAL-APLICALIA" : "CONDICIONES GENERALES ASISA DENTAL PORTUGAL APLICALIA",
    "HOJA-TARJETAS-DENTAL-PORTUGAL" : "HOJA TARJETAS DENTAL PORTUGAL",
    "LIBRO-AUXILIAR-DENTAL-PORTUGAL-APLICALIA": "LIBRO AUXILIAR DENTAL PORTUGAL APLICALIA",
    "UNA-MEJOR-PROTECCION": "UNA MEJOR PROTECCION",
    "INSTRUCCIONES-PARA-TRAMITACION-DEL-REEMBOLSO-MEYSS": "INSTRUCCIONES PARA TRAMITACION DEL REEMBOLSO MEYSS",
    "CONDICIONES-ESPECIALES-MEYSS": "CONDICIONES ESPECIALES MEYSS",
    "SOLICITUD-REEMBOLSO-GASTOS-MEYSS": "SOLICITUD REEMBOLSO GASTOS MEYSS",
    "FRANQUICIAS-POLIZA-DENTAL-SALUDCOR": "FRANQUICIAS POLIZA DENTAL SALUDCOR",
    "COBERTURA-REEMBOLSO-GASTOS-FARMACEUTICOS-AEBIA-TECNOLOGIA" : "COBERTURA REEMBOLSO GASTOS FARMACEUTICOS AEBIA TECNOLOGIA",
    "PRIMERA-HOJA-SALUDCOR-TE-DAMOS-LA-BIENVENIDA": "PRIMERA HOJA SALUDCOR TE DAMOS LA BIENVENIDA",
    "DESCARGAR-CUADRO-MEDICO-SALUDCOR": "DESCARGAR CUADRO MEDICO SALUDCOR",
    "HOJA-APP-SALUDCOR" : "HOJA APP SALUDCOR",
    "CONTRAPORTADA-SALUDCOR-CESS" : "CONTRAPORTADA SALUDCOR CESS",
    "CONTRAPORTADA-SALUDCOR-SECI" : "CONTRAPORTADA SALUDCOR SECI",
    "HOJA-TARJETAS-ASISTENCIA-MEDICA-SALUDCOR": "HOJA TARJETAS ASISTENCIA MEDICA SALUDCOR",
    "HOJA-TARJETAS-ASISTENCIA-DENTAL-SALUDCOR": "HOJA TARJETAS ASISTENCIA DENTAL SALUDCOR",
    "HOJA-CD-CUADRO-MEDICO-SALUDCOR": "HOJA CD CUADRO MEDICO SALUDCOR",
    "CONDICIONES-GENERALES-SECI-PACK" : "CONDICIONES GENERALES SECI PACK",
    "CONDICIONES-GENERALES-SEGURO-HOSPITALIZACION-SECI-PACK": "CONDICIONES GENERALES SEGURO HOSPITALIZACION SECI PACK",
    "CONDICIONES-GENERALES-ASISA-MASTER-PACK-SECI": "CONDICIONES GENERALES ASISA MASTER PACK SECI",
    "HOJA-NOTAS-SALUDCOR" : "HOJA NOTAS SALUDCOR",
    "CONDICIONES-GENERALES-ASISA-PROXIMA-PACK-BANCA-MARCH": "CONDICIONES GENERALES ASISA PROXIMA PACK BANCA MARCH",
    "CONDICIONES-GENERALES-SALUD-ASISA-SATISFACCION-PLUS" : "CONDICIONES GENERALES SALUD ASISA SATISFACCION PLUS",
    "CONDICIONES-GENERALES-ASISA-DENTAL-BANCO-SANTANDER": "CONDICIONES GENERALES ASISA DENTAL BANCO SANTANDER",
    "HOJA-PARA-PERSONALIZAR-EON": "HOJA PARA PERSONALIZAR EON",
    "CONDICIONES-ESPECIALES-PSN-ASESORAMIENTO": "CONDICIONES ESPECIALES PSN ASESORAMIENTO",
    "TEXTO-PROMOCION-6-MESES-GRATIS-2016" : "TEXTO PROMOCION 6 MESES GRATIS 2016",
    "CONDICIONES-ESPECIALES-AGENCIA-EFE": "CONDICIONES ESPECIALES AGENCIA EFE",
    "CUADRO-MEDICO-AGENCIA-EFE" : "CUADRO MEDICO AGENCIA EFE",
    "TEXTO-PROMOCION-15%-EN-2016-Y-10%-EN-2017" : "TEXTO PROMOCION 15% EN 2016 Y 10% EN 2017",
    "GARANTIAS-ADICIONALES-EMBAJADA-DE-NICARAGUA" : "GARANTIAS ADICIONALES EMBAJADA DE NICARAGUA",
    "CONDICIONES-ESPECIALES-INTEGRAL-200.000" : "CONDICIONES ESPECIALES INTEGRAL 200.000",
    "CONDICIONES-ESPECIALES-GRUPO-LABORATORIOS-INDAS" : "CONDICIONES ESPECIALES GRUPO LABORATORIOS INDAS",
    "OPERATIVA-SOLICITUD-REEMBOLSO-DE-GASTOS-FLOWSERVE" : "OPERATIVA SOLICITUD REEMBOLSO DE GASTOS FLOWSERVE",
    "CONDICIONES-GENERALES-SERVICIOS-COMPLETOS-COLECTIVOS-1488-Y-1489": "CONDICIONES GENERALES SERVICIOS COMPLETOS COLECTIVOS 1488 Y 1489",
    "GARANTIAS-ADICIONALES-COSITAL" : "GARANTIAS ADICIONALES COSITAL",
    "GARANTIAS-ADICIONALES-ESENCIAL-VECTOR" : "GARANTIAS ADICIONALES ESENCIAL VECTOR",
    "TEXTO-PROMOCION-6-MESES-GRATIS-2016-ESTUDIANTES-COLECTIVO-22353" : "TEXTO PROMOCION 6 MESES GRATIS 2016 ESTUDIANTES COLECTIVO 22353",
    "CONDICIONES-GENERALES-ASISA-PROXIMA-ALBACETE-BALOMPIE" : "CONDICIONES GENERALES ASISA PROXIMA ALBACETE BALOMPIE",
    "CONDICIONES-ESPECIALES-GERENCIA-URBANISMO-DE-SEVILLA": "CONDICIONES ESPECIALES GERENCIA URBANISMO DE SEVILLA",
    "HOJA-EXPLICATIVA-AREA-PRIVADA-WP": "HOJA EXPLICATIVA AREA PRIVADA WP",
    "CONDICIONES-GENERALES-ASISA-PROXIMA-PACK-USO-CASTILLA-Y-LEON": "CONDICIONES GENERALES ASISA PROXIMA PACK USO CASTILLA Y LEON",
    "TEXTO-PROMOCION-6-MESES-GRATIS-2017-ESTUDIANTES" : "TEXTO PROMOCION 6 MESES GRATIS 2017 ESTUDIANTES",
    "HOJA-INSTRUCCIONES-ASISA-DECESOS": "HOJA INSTRUCCIONES ASISA DECESOS",
    "CONDICIONES-GENERALES-ASISA-DECESOS" : "CONDICIONES GENERALES ASISA DECESOS",
    "NORMAS-DE-CONTRATACION-ASISA-DECESOS": "NORMAS DE CONTRATACION ASISA DECESOS",
    "ACCESO-ASISTENCIA-MEDICA-ESPAÑA-DENTAL-PORTUGAL-APRIL" : "ACCESO ASISTENCIA MEDICA ESPAÑA DENTAL PORTUGAL APRIL",
    "ASISTENCIA-URGENTE-DENTAL-PORTUGAL-APRIL": "ASISTENCIA URGENTE DENTAL PORTUGAL APRIL",
    "GUIA-RAPIDA-DENTAL-PORTUGAL-APRIL" : "GUIA RAPIDA DENTAL PORTUGAL APRIL",
    "RED-CLINICAS-DENTAL-PORTUGAL-APRIL": "RED CLINICAS DENTAL PORTUGAL APRIL",
    "CONDICIONES-GENERALES-ASISA-DENTAL-PORTUGAL-APRIL" : "CONDICIONES GENERALES ASISA DENTAL PORTUGAL APRIL",
    "TEXTO-PROMOCIÓN-12%-EN-2016-Y-2017-Y-10%-EN-2018": "TEXTO PROMOCIÓN 12% EN 2016 Y 2017 Y 10% EN 2018",
    "TEXTO-PROMOCION-3-MESES-GRATIS": "TEXTO PROMOCION 3 MESES GRATIS",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-ACTIVA" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA ACTIVA",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-AUTONOMOS": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA AUTONOMOS",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-DENTAL" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA DENTAL",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-DENTAL-FAMILIAR": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA DENTAL FAMILIAR",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-DENTAL-FAMILIAR-FUNCIONARIOS" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA DENTAL FAMILIAR FUNCIONARIOS",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-DENTAL-FUNCIONARIOS": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA DENTAL FUNCIONARIOS",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-DENTAL-PYMES" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA DENTAL PYMES",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-ESTRELLA" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA ESTRELLA",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-INTEGRAL" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA INTEGRAL",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-INTEGRAL-PYMES" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA INTEGRAL PYMES",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-INTERNACIONAL": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA INTERNACIONAL",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-MASTER" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA MASTER",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-MOMENTO": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA MOMENTO",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-PROXIMA": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA PROXIMA",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-SALUD": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA SALUD",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-SALUD-FAMILIAR" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA SALUD FAMILIAR",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-SALUD-PYMES": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA SALUD PYMES",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-UTIL" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA UTIL",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-ACCIDENTES" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA ACCIDENTES",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-ACCIDENTES-PLUS": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA ACCIDENTES PLUS",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-HOSPITALIZACION": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA HOSPITALIZACION",
    "CUADRO-LIMITES-REEMBOLSO-60.000" : "CUADRO LIMITES REEMBOLSO 60.000",
    "CONDICIONES-GENERALES-ASISA-PROXIMA-PACK-CORTE-INGLES" : "CONDICIONES GENERALES ASISA PROXIMA PACK CORTE INGLES",
    "GARANTIAS-ADICIONALES-GRUPO-NOVO-BANCO": "GARANTIAS ADICIONALES GRUPO NOVO BANCO",
    "CONDICIONES-ESPECIALES-INTEGRAL-200-GRUPO-NOVO-BANCO": "CONDICIONES ESPECIALES INTEGRAL 200 GRUPO NOVO BANCO",
    "CONDICIONES-ESPECIALES-INTEGRAL-1-MILLON-GRUPO-NOVO-BANCO" : "CONDICIONES ESPECIALES INTEGRAL 1 MILLON GRUPO NOVO BANCO",
    "CONDICIONES-ESPECIALES-INTEGRAL-250-GRUPO-NOVO-BANCO": "CONDICIONES ESPECIALES INTEGRAL 250 GRUPO NOVO BANCO",
    "CONDICIONES-ESPECIALES-COLECTIVO-6026-PRESIDENTE-CESCE": "CONDICIONES ESPECIALES COLECTIVO 6026 PRESIDENTE CESCE",
    "CONDICIONES-GENERALES-ASISA-PROXIMA-COLECTIVO-C&A" : "CONDICIONES GENERALES ASISA PROXIMA COLECTIVO C&A",
    "CONDICIONES-ESPECIALES-HIERROS-CANTON" : "CONDICIONES ESPECIALES HIERROS CANTON",
    "FRANQUICIAS-POLIZA-DENTAL-AYUNTAMIENTO-TENERIFE" : "FRANQUICIAS POLIZA DENTAL AYUNTAMIENTO TENERIFE",
    "CONDICIONES-ESPECIALES-AYTO.-TENERIFE-COLECTIVOS-22525-Y-5911" : "CONDICIONES ESPECIALES AYTO. TENERIFE COLECTIVOS 22525 Y 5911",
    "CONDICIONES-ESPECIALES-AYTO.-TENERIFE-COLECTIVOS-22524-Y-22517": "CONDICIONES ESPECIALES AYTO. TENERIFE COLECTIVOS 22524 Y 22517",
    "CONDICIONES-ESPECIALES-TEATRO-REAL": "CONDICIONES ESPECIALES TEATRO REAL",
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-GRUPO-BOLUDA": "CONDICIONES ESPECIALES ASISA INTEGRAL GRUPO BOLUDA",
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-LLANES" : "CONDICIONES ESPECIALES AYUNTAMIENTO DE LLANES",
    "CONDICIONES-PARTICULARES-GIP": "CONDICIONES PARTICULARES GIP",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-VIDA-MUJER" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA VIDA MUJER",
    "CONDICIONES-GENERALES-ASISA-VIDA-MUJER": "CONDICIONES GENERALES ASISA VIDA MUJER",
    "APENDICE-CONDICIONES-GENERALES-ASISA-VIDA-MUJER" : "APENDICE CONDICIONES GENERALES ASISA VIDA MUJER",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-DECESOS": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA DECESOS",
    "EXTRACTO-CONTRATO-POLIZA-FAM-CAMINOS-EXPATRIADOS": "EXTRACTO CONTRATO POLIZA FAM CAMINOS EXPATRIADOS",
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-ARONA": "CONDICIONES ESPECIALES AYUNTAMIENTO DE ARONA",
    "CONDICIONES-GENERALES-ASISA-VIDA-TRANQUILIDAD" : "CONDICIONES GENERALES ASISA VIDA TRANQUILIDAD",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-VIDA-TRANQUILIDAD": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA VIDA TRANQUILIDAD",
    "CONDICIONES-GENERALES-ASISA-VIDA-TRANQUILIDAD-PLUS": "CONDICIONES GENERALES ASISA VIDA TRANQUILIDAD PLUS",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-VIDA-TRANQUILIDAD-PLUS" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA VIDA TRANQUILIDAD PLUS",
    "CONDICIONES-ESPECIALES-GRUPO-ITP": "CONDICIONES ESPECIALES GRUPO ITP",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-VIDA-RETORNO" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA VIDA RETORNO",
    "FRANQUICIAS-DENTALES-AYUNTAMIENTO-MALAGA": "FRANQUICIAS DENTALES AYUNTAMIENTO MALAGA",
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-MALAGA" : "CONDICIONES ESPECIALES AYUNTAMIENTO DE MALAGA",
    "COPAGOS-AYUNTAMIENTO-DE-MALAGA": "COPAGOS AYUNTAMIENTO DE MALAGA",
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-1.000.000" : "CONDICIONES ESPECIALES ASISA INTEGRAL 1.000.000",
    "CONDICIONES-GENERALES-ASISA-DENTAL-PACK-SECI": "CONDICIONES GENERALES ASISA DENTAL PACK SECI",
    "CONDICIONES-GENERALES-ASISA-DENTAL-PACK-CESS": "CONDICIONES GENERALES ASISA DENTAL PACK CESS",
    "FRANQUICIAS-ASISA-DENTAL-(PRODUCTO-DENTAL)-CESS" : "FRANQUICIAS ASISA DENTAL (PRODUCTO DENTAL) CESS",
    "FRANQUICIAS-ASISA-DENTAL-(PRODUCTO-DENTAL)-SECI" : "FRANQUICIAS ASISA DENTAL (PRODUCTO DENTAL) SECI",
    "CONDICIONES-ESPECIALES-COLECTIVO-EMACSA" : "CONDICIONES ESPECIALES COLECTIVO EMACSA",
    "SOLICITUD-DE-SEGURO-SIN-CUESTIONARIO-COLECTIVO-EMACSA" : "SOLICITUD DE SEGURO SIN CUESTIONARIO COLECTIVO EMACSA",
    "FRANQUICIAS-POLIZA-DENTAL-COLECTIVO-EMACSA": "FRANQUICIAS POLIZA DENTAL COLECTIVO EMACSA",
    "FRANQUICIAS-ASISA-DENTAL-PLUS-(GARANTÍA-DENTAL)-SECI": "FRANQUICIAS ASISA DENTAL PLUS (GARANTÍA DENTAL) SECI",
    "CUADRO-MEDICO-ALBACETE-DEPORTIVO-6686" : "CUADRO MEDICO ALBACETE DEPORTIVO 6686",
    "HOJA-EDA-PARA-APP-ASISA-VIDA": "HOJA EDA PARA APP ASISA VIDA",
    "GARANTIA-DE-ASESORAMIENTO-DE-ASISER-ASISA-VIDA": "GARANTIA DE ASESORAMIENTO DE ASISER ASISA VIDA",
    "CONDICIONES-GENERALES-ASISA-VIDA-TAR-CENTERBROK" : "CONDICIONES GENERALES ASISA VIDA TAR CENTERBROK",
    "NOTA-INFORMATIVA-ASISA-VIDA-TAR-CENTERBROK": "NOTA INFORMATIVA ASISA VIDA TAR CENTERBROK",
    "FRANQUICIAS-DENTAL-COLECTIVO-IMBISA" : "FRANQUICIAS DENTAL COLECTIVO IMBISA",
    "CONDICIONES-ESPECIALES-REEMBOLSO-IMBISA" : "CONDICIONES ESPECIALES REEMBOLSO IMBISA",
    "PRIMERA-HOJA-PACK-MONTEPIO": "PRIMERA HOJA PACK MONTEPIO",
    "HOJA-TARJETAS-SALUD-MONTEPIO": "HOJA TARJETAS SALUD MONTEPIO",
    "HOJA-TARJETAS-DENTAL-MONTEPIO" : "HOJA TARJETAS DENTAL MONTEPIO",
    "FRANQUICIAS-DENTAL-PARA-MONTEPIO": "FRANQUICIAS DENTAL PARA MONTEPIO",
    "HOJA-APP-MONTEPIO" : "HOJA APP MONTEPIO",
    "CERTIFICADO-DE-SEGURO-PACK-MONTEPIO" : "CERTIFICADO DE SEGURO PACK MONTEPIO",
    "HOJA-CD-ASISA-SALUD-Y-DENTAL-MONTEPIO" : "HOJA CD ASISA SALUD Y DENTAL MONTEPIO",
    "CUADRO-MEDICO-ALBACETE-DEPORTIVO-22795": "CUADRO MEDICO ALBACETE DEPORTIVO 22795",
    "CONDICIONES-ESPECIALES-REEMBOLSO-IMBISA-22793" : "CONDICIONES ESPECIALES REEMBOLSO IMBISA 22793",
    "CONDICIONES-GENERALES-ASISA-DENTAL-PACK-MONTEPIO": "CONDICIONES GENERALES ASISA DENTAL PACK MONTEPIO",
    "HOJA-TARJETAS-SALUD-COLECTIVO-ONCE": "HOJA TARJETAS SALUD COLECTIVO ONCE",
    "GARANTIAS-ADICIONALES-GRUPO-ALTAMIRA": "GARANTIAS ADICIONALES GRUPO ALTAMIRA",
    "GARANTIA-DE-LAS-PRESTACIONES-PARA-PREVIMUTUA": "GARANTIA DE LAS PRESTACIONES PARA PREVIMUTUA",
    "TEXTO-PROMOCION-30%-Y-25%-AÑO-2017": "TEXTO PROMOCION 30% Y 25% AÑO 2017",
    "CONDICIONES-ESPECIALES-COLECTIVO-6807-AIRBUS": "CONDICIONES ESPECIALES COLECTIVO 6807 AIRBUS",
    "FRANQUICIAS-ASISA-DENTAL-(PRODUCTO-DENTAL)-PREVIMUTUA" : "FRANQUICIAS ASISA DENTAL (PRODUCTO DENTAL) PREVIMUTUA",
    "CONDICIONES-ESPECIALES-COLECTIVO-6828-AIRBUS": "CONDICIONES ESPECIALES COLECTIVO 6828 AIRBUS",
    "CONDICIONES-ESPECIALES-COLECTIVO-6829-AIRBUS": "CONDICIONES ESPECIALES COLECTIVO 6829 AIRBUS",
    "CONDICIONES-ESPECIALES-COLECTIVO-6830-AIRBUS": "CONDICIONES ESPECIALES COLECTIVO 6830 AIRBUS",
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-180.000" : "CONDICIONES ESPECIALES ASISA INTEGRAL 180.000",
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-180.000-EN-INGLES" : "CONDICIONES ESPECIALES ASISA INTEGRAL 180.000 EN INGLES",
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-240.000" : "CONDICIONES ESPECIALES ASISA INTEGRAL 240.000",
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-240.000-EN-INGLES" : "CONDICIONES ESPECIALES ASISA INTEGRAL 240.000 EN INGLES",
    "CONDICIONES-GENERALES-COLECTIVO-ONCE-22911": "CONDICIONES GENERALES COLECTIVO ONCE 22911",
    "CONDICIONES-GENERALES-COLECTIVO-ONCE-6790" : "CONDICIONES GENERALES COLECTIVO ONCE 6790",
    "CONDICIONES-ESPECIALES-COLECTIVO-COMITE-DE-EMPRESA-ACG-INGENIERIA" : "CONDICIONES ESPECIALES COLECTIVO COMITE DE EMPRESA ACG INGENIERIA",
    "CONDICIONES-ESPECIALES-GRUPO-INDRA-PACK-125" : "CONDICIONES ESPECIALES GRUPO INDRA PACK 125",
    "CONDICIONES-ESPECIALES-GRUPO-INDRA-PACK-209" : "CONDICIONES ESPECIALES GRUPO INDRA PACK 209",
    "CERTIFICADO-ESPECIAL-COLECTIVO-XIBANYA": "CERTIFICADO ESPECIAL COLECTIVO XIBANYA",
    "CONDICIONES-ESPECIALES-INTEGRAL-250-GRUPO-FINSOLUTIA": "CONDICIONES ESPECIALES INTEGRAL 250 GRUPO FINSOLUTIA",
    "CONDICIONES-ESPECIALES-GRUPO-FINSOLUTIA" : "CONDICIONES ESPECIALES GRUPO FINSOLUTIA",
    "HOJA-TARJETA-SALUD-BANCO-CAMINOS": "HOJA TARJETA SALUD BANCO CAMINOS",
    "CONDICIONES-ESPECIALES-BANCO-CAMINOS": "CONDICIONES ESPECIALES BANCO CAMINOS",
    "FRANQUICIAS-DENTAL-PARA-SALUD-+-GARANTIA-BANCO-CAMINOS": "FRANQUICIAS DENTAL PARA SALUD + GARANTIA BANCO CAMINOS",
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-BANCO-CAMINOS-REEMBOLSO-DIRECTIVOS": "CONDICIONES ESPECIALES ASISA INTEGRAL BANCO CAMINOS REEMBOLSO DIRECTIVOS",
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-BANCO-CAMINOS-UP-GRADE": "CONDICIONES ESPECIALES ASISA INTEGRAL BANCO CAMINOS UP GRADE",
    "CONDICIONES-ESPECIALES-BANCO-CAMINOS-DIRECTIVOS" : "CONDICIONES ESPECIALES BANCO CAMINOS DIRECTIVOS",
    "CONDICIONES-ESPECIALES-BANCO-CAMINOS-UP-GRADE" : "CONDICIONES ESPECIALES BANCO CAMINOS UP GRADE",
    "CUADRO-MEDICO-ESPECIAL-COLECTIVO-ONCE" : "CUADRO MEDICO ESPECIAL COLECTIVO ONCE",
    "CUADRO-MEDICO-ESPECIAL-GRUPO-EXIDE": "CUADRO MEDICO ESPECIAL GRUPO EXIDE",
    "NOTA-INFORMATIVA-INTEGRAL-250-GRUPO-PRISA" : "NOTA INFORMATIVA INTEGRAL 250 GRUPO PRISA",
    "NOTA-INFORMATIVA-INTEGRAL-100-Y-SALUD-GRUPO-PRISA" : "NOTA INFORMATIVA INTEGRAL 100 Y SALUD GRUPO PRISA",
    "CONDICIONES-ESPECIALES-INTEGRAL-100-GRUPO-PRISA" : "CONDICIONES ESPECIALES INTEGRAL 100 GRUPO PRISA",
    "CONDICIONES-ESPECIALES-INTEGRAL-250-GRUPO-PRISA" : "CONDICIONES ESPECIALES INTEGRAL 250 GRUPO PRISA",
    "CONDICIONES-GENERALES-INTEGRAL-GRUPO-PRISA": "CONDICIONES GENERALES INTEGRAL GRUPO PRISA",
    "CONDICIONES-GENERALES-SALUD-GRUPO-PRISA" : "CONDICIONES GENERALES SALUD GRUPO PRISA",
    "CONDICIONES-ESPECIALES-FEYLO-SYLVANIA" : "CONDICIONES ESPECIALES FEYLO SYLVANIA",
    "CONDICIONES-ESPECIALES-GRUPO-ANECA": "CONDICIONES ESPECIALES GRUPO ANECA",
    "COBERTURAS-ADICIONALES-COLECTIVOS-PREVIMUTUA": "COBERTURAS ADICIONALES COLECTIVOS PREVIMUTUA",
    "CONDICIONES-GENERALES-ASISA-MASCOTAS": "CONDICIONES GENERALES ASISA MASCOTAS",
    "CONDICIONES-GENERALES-ASISA-VITALTEA": "CONDICIONES GENERALES ASISA VITALTEA",
    "GARANTIAS-RETRIBUCION-FLEXIBLE-COLECTIVO-RTVE" : "GARANTIAS RETRIBUCION FLEXIBLE COLECTIVO RTVE",
    "CONDICIONES-GENERALES-ASISA-KIDS": "CONDICIONES GENERALES ASISA KIDS",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-VITALTEA" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA VITALTEA",
    "CONDICIONES-ESPECIALES-TELECOMING" : "CONDICIONES ESPECIALES TELECOMING",
    "CUADRO-MEDICO-ACOTADO-UBESOL": "CUADRO MEDICO ACOTADO UBESOL",
    "CONDICIONES-ESPECIALES-REAL-FEDERACION-DE-ATLETISMO" : "CONDICIONES ESPECIALES REAL FEDERACION DE ATLETISMO",
    "FRANQUICIAS-ASISA-MASCOTAS": "FRANQUICIAS ASISA MASCOTAS",
    "CONDICIONES-ESPECIALES-PERFUMES-Y-DISEÑO": "CONDICIONES ESPECIALES PERFUMES Y DISEÑO",
    "CONDICIONES-ESPECIALES-GRUPO-HASTEN" : "CONDICIONES ESPECIALES GRUPO HASTEN",
    "HOJA-INDICE-ASISA-VIDA-PORTUGAL" : "HOJA INDICE ASISA VIDA PORTUGAL",
    "ASISA-VIDA-PORTUGAL-QUEM-SOMOS": "ASISA VIDA PORTUGAL QUEM SOMOS",
    "NOTA-INFORMATIVA-ASISA-VIDA-PORTUGAL": "NOTA INFORMATIVA ASISA VIDA PORTUGAL",
    "CONDICIONES-GENERALES-ASISA-VIDA-PORTUGAL" : "CONDICIONES GENERALES ASISA VIDA PORTUGAL",
    "PREGUNTAS-FRECUENTES-E-CONTACTOS-ASISA-VIDA-PORTUGAL": "PREGUNTAS FRECUENTES E CONTACTOS ASISA VIDA PORTUGAL",
    "CONDICIONES-GENERALES-ASISA-ACTIVA-HASTA-2018" : "CONDICIONES GENERALES ASISA ACTIVA HASTA 2018",
    "CONDICIONES-ESPECIALES-ATMIRA" : "CONDICIONES ESPECIALES ATMIRA",
    "HOJA-INDICE-ASISA-DENTAL-SUCURSAL-PORTUGAL": "HOJA INDICE ASISA DENTAL SUCURSAL PORTUGAL",
    "NOTA-INFORMATIVA-ASISA-DENTAL-SUCURSAL-PORTUGAL" : "NOTA INFORMATIVA ASISA DENTAL SUCURSAL PORTUGAL",
    "CONDICIONES-GENERALES-ASISA-DENTAL-SUCURSAL-PORTUGAL": "CONDICIONES GENERALES ASISA DENTAL SUCURSAL PORTUGAL",
    "TABELA-ACTOS-MEDICOS-ASISA-DENTAL-SUCURSAL-PORTUGAL" : "TABELA ACTOS MEDICOS ASISA DENTAL SUCURSAL PORTUGAL",
    "PREGUNTAS-FRECUENTES-E-CONTACTOS-ASISA-DENTAL-SUCURSAL-PORTUGAL" : "PREGUNTAS FRECUENTES E CONTACTOS ASISA DENTAL SUCURSAL PORTUGAL",
    "TEXTO-PROMOCION-30%-25%-O-15%-20%-AÑO-2018": "TEXTO PROMOCION 30% 25% O 15% 20% AÑO 2018",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-MASCOTAS" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA MASCOTAS",
    "FRANQUICIAS-AUTORIDAD-PORTUARIA-LAS-PALMAS": "FRANQUICIAS AUTORIDAD PORTUARIA LAS PALMAS",
    "CONDICIONES-ESPECIALES-MIJAS-COMUNICACION" : "CONDICIONES ESPECIALES MIJAS COMUNICACION",
    "COBERTURAS-DESTACADAS-COLECTIVO-AECID" : "COBERTURAS DESTACADAS COLECTIVO AECID",
    "RESUMEN-COBERTURAS-BECARIOS-COLECTIVO-AECID" : "RESUMEN COBERTURAS BECARIOS COLECTIVO AECID",
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-OVIEDO-7793": "CONDICIONES ESPECIALES AYUNTAMIENTO DE OVIEDO 7793",
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-OVIEDO-7794": "CONDICIONES ESPECIALES AYUNTAMIENTO DE OVIEDO 7794",
    "NOTA-INFORMATIVA-ASISA-DENTAL-FAMILIAR-SUCURSAL-PORTUGAL": "NOTA INFORMATIVA ASISA DENTAL FAMILIAR SUCURSAL PORTUGAL",
    "CONDICIONES-GENERALES-ASISA-DENTAL-FAMILIAR-SUCURSAL-PORTUGAL" : "CONDICIONES GENERALES ASISA DENTAL FAMILIAR SUCURSAL PORTUGAL",
    "PREGUNTAS-FRECUENTES-E-CONTACTOS-ASISA-DENTAL-FAMILIAR-SUCURSAL-PORTUGAL": "PREGUNTAS FRECUENTES E CONTACTOS ASISA DENTAL FAMILIAR SUCURSAL PORTUGAL",
    "HOJA-INDICE-ASISA-DENTAL-FAMILIAR-SUCURSAL-PORTUGAL" : "HOJA INDICE ASISA DENTAL FAMILIAR SUCURSAL PORTUGAL",
    "NOTA-INFORMATIVA-ASISA-ESTRELLA-KIDS": "NOTA INFORMATIVA ASISA ESTRELLA KIDS",
    "NOTA-INFORMATIVA-ASISA-ACTIVA-PLUS-KIDS" : "NOTA INFORMATIVA ASISA ACTIVA PLUS KIDS",
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-100.000-GRUPO-DACHSER" : "CONDICIONES ESPECIALES ASISA INTEGRAL 100.000 GRUPO DACHSER",
    "TEXTO-PROMOCION-INVIERNO-2018" : "TEXTO PROMOCION INVIERNO 2018",
    "CONDICIONES-GENERALES-ASISA-SALUD-BASIC-PACK-BANCA-MARCH": "CONDICIONES GENERALES ASISA SALUD BASIC PACK BANCA MARCH",
    "CONDICIONES-GENERALES-ASISA-SALUD-20.000-PACK-BANCA-MARCH" : "CONDICIONES GENERALES ASISA SALUD 20.000 PACK BANCA MARCH",
    "LISTADO-ENFERMEDADES-Y-PATOLOGIAS-GRAVES-ASISA-2018" : "LISTADO ENFERMEDADES Y PATOLOGIAS GRAVES ASISA 2018",
    "CONDICIONES-GENERALES-ASISA-DENTAL-CONTRIBUTIVOS-SUCURSAL-PORTUGAL": "CONDICIONES GENERALES ASISA DENTAL CONTRIBUTIVOS SUCURSAL PORTUGAL",
    "PREGUNTAS-FRECUENTES-E-CONTACTOS-ASISA-DENTAL-CONTRIBUTIVOS-SUCURSAL-PORTUGAL" : "PREGUNTAS FRECUENTES E CONTACTOS ASISA DENTAL CONTRIBUTIVOS SUCURSAL PORTUGAL",
    "CONDICIONES-GENERALES-ASISA-DENTAL-NO-CONTRIBUTIVOS-SUCURSAL-PORTUGAL" : "CONDICIONES GENERALES ASISA DENTAL NO CONTRIBUTIVOS SUCURSAL PORTUGAL",
    "PREGUNTAS-FRECUENTES-E-CONTACTOS-ASISA-DENTAL-NO-CONTRIBUTIVOS-SUCURSAL-PORTUGAL": "PREGUNTAS FRECUENTES E CONTACTOS ASISA DENTAL NO CONTRIBUTIVOS SUCURSAL PORTUGAL",
    "REPATRIACION-COLECTIVO-NATIVE-LANGUAGE": "REPATRIACION COLECTIVO NATIVE LANGUAGE",
    "CONDICIONES-ESPECIALES-COLECTIVO-TYPSA": "CONDICIONES ESPECIALES COLECTIVO TYPSA",
    "CONDICIONES-ESPECIALES-CONSEJO-GENERAL-DE-ENFERMERIA": "CONDICIONES ESPECIALES CONSEJO GENERAL DE ENFERMERIA",
    "CONDICIONES-ESPECIALES-GRUPO-JLL-CM-COLECTIVOS-8051-8053-8055-8057": "CONDICIONES ESPECIALES GRUPO JLL CM COLECTIVOS 8051-8053-8055-8057",
    "CONDICIONES-ESPECIALES-GRUPO-JLL-1.000.000-COLECTIVOS-8052-8056-8227": "CONDICIONES ESPECIALES GRUPO JLL 1.000.000 COLECTIVOS 8052-8056-8227",
    "CONDICIONES-ESPECIALES-GRUPO-JLL-240.000-COLECTIVO-8054" : "CONDICIONES ESPECIALES GRUPO JLL 240.000 COLECTIVO 8054",
    "CONDICIONES-ESPECIALES-HAITONG-BANK" : "CONDICIONES ESPECIALES HAITONG BANK",
    "CUADRO-LIMITES-REEMBOLSO-HAITONG-BANK" : "CUADRO LIMITES REEMBOLSO HAITONG BANK",
    "CUADRO-LIMITES-REEMBOLSO-GRUPO-JLL-COLECTIVOS-8052-8056-8227": "CUADRO LIMITES REEMBOLSO GRUPO JLL COLECTIVOS 8052-8056-8227",
    "CONDICIONES-ESPECIALES-HAITONG-BANK-COLECTIVOS-ABIERTOS" : "CONDICIONES ESPECIALES HAITONG BANK COLECTIVOS ABIERTOS",
    "CONDICIONES-GENERALES-SALUD-CONSEJO-GENERAL-DEL-NOTARIADO" : "CONDICIONES GENERALES SALUD CONSEJO GENERAL DEL NOTARIADO",
    "CONDICIONES-ESPECIALES-CONSEJO-GENERAL-DEL-NOTARIADO": "CONDICIONES ESPECIALES CONSEJO GENERAL DEL NOTARIADO",
    "CONDICIONES-ESPECIALES-REEMBOLSO-150.000": "CONDICIONES ESPECIALES REEMBOLSO 150.000",
    "CONDICIONES-ESPECIALES-FAM-CAMINOS-EXPATRIADOS-MODULO-B" : "CONDICIONES ESPECIALES FAM CAMINOS EXPATRIADOS MODULO B",
    "CONDICIONES-ESPECIALES-FAM-CAMINOS-EXPATRIADOS-MODULO-C" : "CONDICIONES ESPECIALES FAM CAMINOS EXPATRIADOS MODULO C",
    "NOTA-INFORMATIVA-COLECTIVO-LEASPLAN" : "NOTA INFORMATIVA COLECTIVO LEASPLAN",
    "OPERATIVA-SOLICITUD-REEMBOLSO-DE-GASTOS-LEASEPLAN" : "OPERATIVA SOLICITUD REEMBOLSO DE GASTOS LEASEPLAN",
    "CONDICIONES-ESPECIALES-GRUPO-NUETALIA-E-INDUSTRIAS-IMAR" : "CONDICIONES ESPECIALES GRUPO NUETALIA E INDUSTRIAS IMAR",
    "FRANQUICIAS-COLECTIVO-VIMCORSA": "FRANQUICIAS COLECTIVO VIMCORSA",
    "FRANQUICIAS-COLECTIVO-FABRICA-NACIONAL-MONEDA-Y-TIMBRE": "FRANQUICIAS COLECTIVO FABRICA NACIONAL MONEDA Y TIMBRE",
    "CONDICIONES-ESPECIALES-COLECTIVO-ADIF" : "CONDICIONES ESPECIALES COLECTIVO ADIF",
    "CONDICIONES-ESPECIALES-COLECTIVO-LIFE-DESAROLLOS-COMERCIALES": "CONDICIONES ESPECIALES COLECTIVO LIFE DESAROLLOS COMERCIALES",
    "CONDICIONES-ESPECIALES-GRUPO-UBS-BANK" : "CONDICIONES ESPECIALES GRUPO UBS BANK",
    "CONDICIONES-ESPECIALES-PLASTIC-OMNIUM" : "CONDICIONES ESPECIALES PLASTIC OMNIUM",
    "NOTA-INFORMATIVA-COLECTIVO-GRUPO-CEPSA": "NOTA INFORMATIVA COLECTIVO GRUPO CEPSA",
    "COBERTURA-GASTOS-FARMACIA-REMBOLSO-ONLINE" : "COBERTURA GASTOS FARMACIA REMBOLSO ONLINE",
    "OPERATIVA-SOLICITUD-REEMBOLSO-DE-GASTOS-ONLINE": "OPERATIVA SOLICITUD REEMBOLSO DE GASTOS ONLINE",
    "CONDICIONES-ESPECIALES-AJUNTAMENT-CASTELL-PLATJA-DE-ARO" : "CONDICIONES ESPECIALES AJUNTAMENT CASTELL PLATJA DE ARO",
    "CONDICIONES-ESPECIALES-COLECTIVO-LABORATORIOS-BIAL-MERCER" : "CONDICIONES ESPECIALES COLECTIVO LABORATORIOS BIAL MERCER",
    "NOTA-INFORMATIVA-GRUPO-PUMA-2018": "NOTA INFORMATIVA GRUPO PUMA 2018",
    "CONDICIONES-ESPECIALES-GRUPO-PUMA-250.000" : "CONDICIONES ESPECIALES GRUPO PUMA 250.000",
    "CONDICIONES-ESPECIALES-ZITRÓN---ASCAZ-SALUD" : "CONDICIONES ESPECIALES ZITRÓN - ASCAZ SALUD",
    "CONDICIONES-ESPECIALES-ZITRON-ASCAZ-PROXIMA" : "CONDICIONES ESPECIALES ZITRON-ASCAZ PROXIMA",
    "CONDICIONES-GENERALES-ZITRON-ASCAZ-PROXIMA": "CONDICIONES GENERALES ZITRON-ASCAZ PROXIMA",
    "COPAGO-ESPECIAL-ASISA-MOMENTO-CONSUM": "COPAGO ESPECIAL ASISA MOMENTO CONSUM",
    "CONDICIONES-GENERALES-ASISA-VIDA-ACCIDENTES-COMPROMISO-10" : "CONDICIONES GENERALES ASISA VIDA ACCIDENTES COMPROMISO 10",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-VIDA-ACCIDENTES-COMPROMISO-10": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA VIDA ACCIDENTES COMPROMISO 10",
    "CONDICIONES-ESPECIALES-COLECTIVOS-UCO" : "CONDICIONES ESPECIALES COLECTIVOS UCO",
    "CONDICIONES-ESPECIALES-COLECTIVOS-FESTIVAL-CINE-DE-MALAGA" : "CONDICIONES ESPECIALES COLECTIVOS FESTIVAL CINE DE MALAGA",
    "CONDICIONES-ESPECIALES-TEATRO-CERVANTES-DE-MALAGA" : "CONDICIONES ESPECIALES TEATRO CERVANTES DE MALAGA",
    "HOJA-LEYENDA-NUEVA-CLAUSULA-LOPD": "HOJA LEYENDA NUEVA CLAUSULA LOPD",
    "CONDICIONES-ESPECIALES-ASISA-INTEGRAL-180.000-AUTORIDAD-PORTUARIA-ALICANTE": "CONDICIONES ESPECIALES ASISA INTEGRAL 180.000 AUTORIDAD PORTUARIA ALICANTE",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-COBERTURA-DE-TRATAMIENTO-BUCODENTALES": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA COBERTURA DE TRATAMIENTO BUCODENTALES",
    "DECLARACION-DE-SALUD-ASISA-VIDA-SENDA" : "DECLARACION DE SALUD ASISA VIDA SENDA",
    "CONDICIONES-GENERALES-ASISA-EXCLUSIVE-20.000-BANCA-MARCH": "CONDICIONES GENERALES ASISA EXCLUSIVE 20.000 BANCA MARCH",
    "CONDICIONES-GENERALES-ASISA-COBERTURA-TRATAMIENTO-BUCODENTALES": "CONDICIONES GENERALES ASISA COBERTURA TRATAMIENTO BUCODENTALES",
    "CONDICIONES-ESPECIALES-AYUNTAMIENTO-DE-GRANADA": "CONDICIONES ESPECIALES AYUNTAMIENTO DE GRANADA",
    "HOJA-CHAT-MEDICO-DECESOS-WP" : "HOJA CHAT MEDICO DECESOS WP",
    "DOCUMENTO-INFORMACION-PRODUCTO-(DIP)-ASISA-COBERTURA-DE-TRATAMIENTO-BUCODENTALES": "DOCUMENTO INFORMACION PRODUCTO (DIP) ASISA COBERTURA DE TRATAMIENTO BUCODENTALES",
    "CONDICIONES-GENERALES-ASISA-INTEGRAL-FAM-CAMINOS-PACK" : "CONDICIONES GENERALES ASISA INTEGRAL FAM CAMINOS PACK",
    "NOTA-INFORMATIVA-ASISA-VIDA-ACCIDENTES-10-PORTUGAL": "NOTA INFORMATIVA ASISA VIDA ACCIDENTES 10 PORTUGAL",
    "CONDICIONES-GENERALES-ASISA-VIDA-ACCIDENTES-10-PORTUGAL" : "CONDICIONES GENERALES ASISA VIDA ACCIDENTES 10 PORTUGAL",
    "CONDICIONES-GENERALES-Y-PARTICULARES-BUCODENTAL-(CP-+-DAC-+-CG)" : "CONDICIONES GENERALES Y PARTICULARES BUCODENTAL (CP + DAC + CG)",
    "CONDICIONES-ESPECIALES-KIMBERLY-CLARK" : "CONDICIONES ESPECIALES KIMBERLY CLARK",
    "COBERTURA-REPATRIACION-FALLECIMIENTO,-ENFERMEDAD-Y-ACCIDENTE-GRAVE": "COBERTURA REPATRIACION FALLECIMIENTO, ENFERMEDAD Y ACCIDENTE GRAVE",
    "CONDICIONES-GENERALES-ASISA-TRAVEL-ALL-YEAR" : "CONDICIONES GENERALES ASISA TRAVEL ALL YEAR",
    "CONDICIONES-GENERALES-ASISA-TRAVEL-STUDY": "CONDICIONES GENERALES ASISA TRAVEL STUDY",
    "CONDICIONES-GENERALES-ASISA-TRAVEL-AND-YOU": "CONDICIONES GENERALES ASISA TRAVEL AND YOU",
    "TABLA-DE-GARANTIAS-ASISA-TRAVEL-ALL-YEAR": "TABLA DE GARANTIAS ASISA TRAVEL ALL YEAR",
    "TABLA-DE-GARANTIAS-ASISA-TRAVEL-STUDY" : "TABLA DE GARANTIAS ASISA TRAVEL STUDY",
    "TABLA-DE-GARANTIAS-ASISA-TRAVEL-AND-YOU" : "TABLA DE GARANTIAS ASISA TRAVEL AND YOU",
    "COBERTURA-REPATRIACION-FALLECIMIENTO-COLECTIVO-BEST-CAREER": "COBERTURA REPATRIACION FALLECIMIENTO COLECTIVO BEST CAREER",
    "INFORMACION-COBERTURAS-ADICIONALES-ASISA-DENTAL-SUCURSAL-PORTUGAL" : "INFORMACION COBERTURAS ADICIONALES ASISA DENTAL SUCURSAL PORTUGAL",
    "CONDICIONES-GENERALES-Y-PARTICULARES-ASISA-TRAVEL" : "CONDICIONES GENERALES Y PARTICULARES ASISA TRAVEL",
    "CONDICIONES-ESPECIALES-LABORATORIOS-BIAL": "CONDICIONES ESPECIALES LABORATORIOS BIAL",
    "CONDICIONES-ESPECIALES-ANAV" : "CONDICIONES ESPECIALES ANAV",
    "COBERTURA-REPATRIACION-POR-FALLECIMIENTO-COLECTIVO-GESTION-EDUCATIVA-CONSULTORES": "COBERTURA REPATRIACION POR FALLECIMIENTO COLECTIVO GESTION EDUCATIVA CONSULTORES",
    "CONDICIONES-PROMOCION-VERANO-2019" : "CONDICIONES PROMOCION VERANO 2019",
    "HOJA-INDICE-ASISA-VIDA-ACCIDENTES-10-PORTUGAL" : "HOJA INDICE ASISA VIDA ACCIDENTES 10 PORTUGAL",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-ACTIVA-BCN" : "NOTA INFORMATIVA PREVIA CONTRATACION ASISA ACTIVA BCN",
    "CONDICIONES-GENERALES-ASISA-INTEGRAL-PACK-FAM-CAMINOS" : "CONDICIONES GENERALES ASISA INTEGRAL PACK FAM CAMINOS",
    "CONDICIONES-ESPECIALES-WP-MALAGA-DEPORTES-Y-EVENTOS-SA": "CONDICIONES ESPECIALES WP MALAGA DEPORTES Y EVENTOS SA",
    "CONDICIONES-GENERALES-ASISA-SALUD---PELAYO": "CONDICIONES GENERALES ASISA SALUD - PELAYO",
    "CONDICIONES-GENERALES-ASISA-INTEGRAL--PELAYO": "CONDICIONES GENERALES ASISA INTEGRAL- PELAYO",
    "CONDICIONES-GENERALES-ASISA-DENTAL--PELAYO": "CONDICIONES GENERALES ASISA DENTAL- PELAYO",
    "CONDICIONES-GENERALES-SALUD-UNIVERSIDAD-EUROPEA-DE-MADRID" : "CONDICIONES GENERALES SALUD UNIVERSIDAD EUROPEA DE MADRID",
    "CONDICIONES-GENERALES-SALUD-UNIVERSIDAD-EUROPEA-DE-MADRID-EN-INGLES" : "CONDICIONES GENERALES SALUD UNIVERSIDAD EUROPEA DE MADRID EN INGLES",
    "TEXTO-APP-ASISA-PARA-MARBELLA-INTERNATIONAL-UNIVERSITY-CASTELLANO-E-INGLES": "TEXTO APP ASISA PARA MARBELLA INTERNATIONAL UNIVERSITY CASTELLANO E INGLES",
    "CONDICIONES-ESPECIALES-MARBELLA-INTERNATIONAL-UNIVERSITY": "CONDICIONES ESPECIALES MARBELLA INTERNATIONAL UNIVERSITY",
    "HOJA-INDICE-ASISA-VIDA-PORTUGAL-CCAM": "HOJA INDICE ASISA VIDA PORTUGAL CCAM",
    "ASISA-VIDA-PORTUGAL-QUEM-SOMOS-CCAM" : "ASISA VIDA PORTUGAL QUEM SOMOS CCAM",
    "NOTA-INFORMATIVA-ASISA-VIDA-PORTUGAL-CCAM" : "NOTA INFORMATIVA ASISA VIDA PORTUGAL CCAM",
    "CONDICIONES-GENERALES-ASISA-VIDA-PORTUGAL-CCAM": "CONDICIONES GENERALES ASISA VIDA PORTUGAL CCAM",
    "HOJA-AUTORIZACIONES-VIDA-PORTUGAL-CCAM": "HOJA AUTORIZACIONES VIDA PORTUGAL CCAM",
    "PREGUNTAS-FRECUENTES-E-CONTACTOS-ASISA-VIDA-PORTUGAL-CCAM" : "PREGUNTAS FRECUENTES E CONTACTOS ASISA VIDA PORTUGAL CCAM",
    "HOJA-AUTORIZACIONES-ASISA-VIDA-PORTUGAL" : "HOJA AUTORIZACIONES ASISA VIDA PORTUGAL",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-INTERNACIONAL-UNIVERSIDAD-EUROPEA-DE-MADRID": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA INTERNACIONAL UNIVERSIDAD EUROPEA DE MADRID",
    "NOTA-INFORMATIVA-PREVIA-CONTRATACION-ASISA-INTERNACIONAL-UNIVERSIDAD-EUROPEA-DE-MADRID-EN-INGLES": "NOTA INFORMATIVA PREVIA CONTRATACION ASISA INTERNACIONAL UNIVERSIDAD EUROPEA DE MADRID EN INGLES",
    "TABELA-ACTOS-MEDICOS-ASISA-DENTAL-FAMILIA-SUCURSAL-PORTUGAL" : "TABELA ACTOS MEDICOS ASISA DENTAL FAMILIA SUCURSAL PORTUGAL",
    "CONDICIONES-ESPECIALES-DENTAL-CESCE" : "CONDICIONES ESPECIALES DENTAL CESCE",
    "CONDICIONES-ESPECIALES-SALUD-CESCE": "CONDICIONES ESPECIALES SALUD CESCE",
    "NOTA-INFORMATIVA-PELAYO-ASISA-SALUD-PLUS-PARTICULARES" : "NOTA INFORMATIVA PELAYO ASISA SALUD PLUS PARTICULARES",
    "NOTA-INFORMATIVA-PELAYO-ASISA-SALUD-PLUS-PYMES-Y-AUTONOMOS": "NOTA INFORMATIVA PELAYO ASISA SALUD PLUS PYMES Y AUTONOMOS",
    "NOTA-INFORMATIVA-PELAYO-ASISA-SALUD-PREMIUM-PARTICULARES": "NOTA INFORMATIVA PELAYO ASISA SALUD PREMIUM PARTICULARES",
    "NOTA-INFORMATIVA-PELAYO-ASISA-SALUD-PREMIUM-PYMES-Y-AUTONOMOS" : "NOTA INFORMATIVA PELAYO ASISA SALUD PREMIUM PYMES Y AUTONOMOS",
    "NOTA-INFORMATIVA-PELAYO-ASISA-INTEGRAL": "NOTA INFORMATIVA PELAYO ASISA INTEGRAL",
    "NOTA-INFORMATIVA-PELAYO-ASISA-DENTAL": "NOTA INFORMATIVA PELAYO ASISA DENTAL",
    "NOTA-INFORMATIVA-PELAYO-ASISA-DENTAL-FAMILIAR" : "NOTA INFORMATIVA PELAYO ASISA DENTAL FAMILIAR",
    "DOCUMENTO-DE-INFORMACION-DE-PRODUCTO-ASISA-SERVICIOS-COMPLETOS": "DOCUMENTO DE INFORMACION DE PRODUCTO ASISA SERVICIOS COMPLETOS",
    "DOCUMENTO-DE-INFORMACION-DE-PRODUCTO-ASISA-MASTER" : "DOCUMENTO DE INFORMACION DE PRODUCTO ASISA MASTER",
    "DOCUMENTO-DE-INFORMACION-DE-PRODUCTO-ASISA-EL-CORTE-INGLES-EMPLEADOS": "DOCUMENTO DE INFORMACION DE PRODUCTO ASISA EL CORTE INGLES EMPLEADOS",
    "CONDICIONES-GENERALES-Dirección-General-de-Educación.-Consejería-de-Educación-y-Cultura" : "CONDICIONES GENERALES Dirección General de Educación. Consejería de Educación y Cultura",
    "CONDICIONES-GENERALES-Dirección-General-de-Educación.-Consejería-de-Educación-y-Cultura-INGLES": "CONDICIONES GENERALES Dirección General de Educación. Consejería de Educación y Cultura INGLES",
    "CONDICIONES-GENERALES-ASISA-PROXIMA-ASOCIACION-EUROPEA": "CONDICIONES GENERALES ASISA PROXIMA ASOCIACION EUROPEA",
    "CONDICIONES-GENERALES-ASISA-SALUD-ASOCIACION-EUROPEA": "CONDICIONES GENERALES ASISA SALUD ASOCIACION EUROPEA",
    "PORTADA-FOLLETO-PACK": "PORTADA FOLLETO PACK",
    "DOCUMENTOS-A-DEVOLVER-A-ASISA" : "DOCUMENTOS A DEVOLVER A ASISA",
    "DOCUMENTOS-A-DEVOLVER-A-ASISA-CATALAN" : "DOCUMENTOS A DEVOLVER A ASISA CATALAN",
    "DOCUMENTOS-A-DEVOLVER-A-ASISA-EN-INGLES" : "DOCUMENTOS A DEVOLVER A ASISA EN INGLES",
    "PRODUCTOS-ASISA" : "PRODUCTOS ASISA",
    "MAS-PARA-SU-SALUD" : "MAS PARA SU SALUD",
    "MAS-PARA-SU-SALUD-CATALAN" : "MAS PARA SU SALUD CATALAN",
    "PORTADA-CONDICIONES-GENERALES-SATISFACCION-BANCO-DE-SANTANDER" : "PORTADA CONDICIONES GENERALES SATISFACCION BANCO DE SANTANDER",
    "PORTADA-CONDICIONES-GENERALES-B.-SANTANDER-EMPRESAS-NEGOCIOS-CON-DENTAL" : "PORTADA CONDICIONES GENERALES B. SANTANDER EMPRESAS NEGOCIOS CON DENTAL",
    "PORTADA-CONDICIONES-GENERALES-B.-SANTANDER-EMPRESAS-NEGOCIOS-SIN-DENTAL" : "PORTADA CONDICIONES GENERALES B. SANTANDER EMPRESAS NEGOCIOS SIN DENTAL",
    "DOCUMENTOS-A-DEVOLVER-A-ASISA-B.-SANTANDER-IGUALATORIO": "DOCUMENTOS A DEVOLVER A ASISA B. SANTANDER IGUALATORIO",
    "PORTADA-FOLLETO-PACK-BANCO-SANTANDER": "PORTADA FOLLETO PACK BANCO SANTANDER",
    "PORTADA-FOLLETO-PACK-BANCO-SANTANDER-IGUALATORIO": "PORTADA FOLLETO PACK BANCO SANTANDER IGUALATORIO",
    "PORTADA-CONDICIONES-GENERALES-ASISA-SALUD" : "PORTADA CONDICIONES GENERALES ASISA SALUD",
    "PORTADA-TRANSPARENTE-EDA-19-X-23,3": "PORTADA TRANSPARENTE EDA 19 X 23,3",
    "PORTADA-DIRECTA-WP-CORPORACION-DIRECTA": "PORTADA DIRECTA WP CORPORACION DIRECTA",
    "PORTADA-DIRECTA-WP-ASISA-SALUD": "PORTADA DIRECTA WP ASISA SALUD",
    "PORTADA-DIRECTA-WP-ASISA-SALUD-CATALAN": "PORTADA DIRECTA WP ASISA SALUD CATALAN",
    "PORTADA-DIRECTA-WP-BANCO-SANTANDER": "PORTADA DIRECTA WP BANCO SANTANDER",
    "PORTADA-DIRECTA-BANCO-SANTANDER-IGUALATORIO" : "PORTADA DIRECTA BANCO SANTANDER IGUALATORIO",
    "PORTADA-DIRECTA-WP-ASISA-AUTONOMOS": "PORTADA DIRECTA WP ASISA AUTONOMOS",
    "PORTADA-DIRECTA-WP-ASISA-ESTRELLA" : "PORTADA DIRECTA WP ASISA ESTRELLA",
    "PORTADA-DIRECTA-WP-ASISA-INTEGRAL" : "PORTADA DIRECTA WP ASISA INTEGRAL",
    "PORTADA-DIRECTA-WP-ASISA-MASTER" : "PORTADA DIRECTA WP ASISA MASTER",
    "PORTADA-DIRECTA-WP-ASISA-PROXIMA": "PORTADA DIRECTA WP ASISA PROXIMA",
    "PORTADA-DIRECTA-WP-ASISA-VIDA-RETORNO" : "PORTADA DIRECTA WP ASISA VIDA RETORNO",
    "PORTADA-DIRECTA-WP-ASISA-SALUD-FAMILIAR" : "PORTADA DIRECTA WP ASISA SALUD FAMILIAR",
    "PORTADA-DIRECTA-WP-ASISA-UTIL" : "PORTADA DIRECTA WP ASISA UTIL",
    "PORTADA-DIRECTA-WP-ASISA-VIDA" : "PORTADA DIRECTA WP ASISA VIDA",
    "PORTADA-DIRECTA-ASISA-DENTAL-Y-DENTAL-FAMILIAR": "PORTADA DIRECTA ASISA DENTAL Y DENTAL FAMILIAR",
    "PORTADA-DIRECTA-ASISA-DENTAL-FAMILIAR-BANCO-SANTANDER" : "PORTADA DIRECTA ASISA DENTAL FAMILIAR BANCO SANTANDER",
    "PORTADA-DIRECTA-ASISA-PYMES" : "PORTADA DIRECTA ASISA PYMES",
    "PORTADA-DIRECTA-ASISA-HOSPITALIZACION" : "PORTADA DIRECTA ASISA HOSPITALIZACION",
    "PORTADA-DIRECTA-ASISA-ACCIDENTES-(ANTIGUA-PREVISION)": "PORTADA DIRECTA ASISA ACCIDENTES (ANTIGUA PREVISION)",
    "PORTADA-DIRECTA-ASISA-ACCIDENTES-PLUS-(ANTIGUA-ACCIDENTES)": "PORTADA DIRECTA ASISA ACCIDENTES PLUS (ANTIGUA ACCIDENTES)",
    "PORTADA-DIRECTA-BARYMONT-SALUD": "PORTADA DIRECTA BARYMONT SALUD",
    "PORTADA-DIRECTA-BARYMONT-PYMES-SALUD": "PORTADA DIRECTA BARYMONT PYMES SALUD",
    "PORTADA-DIRECTA-BARYMONT-PYMES-DENTAL" : "PORTADA DIRECTA BARYMONT PYMES DENTAL",
    "PORTADA-DIRECTA-BARYMONT-PYMES-DENTAL-FAMILIAR": "PORTADA DIRECTA BARYMONT PYMES DENTAL FAMILIAR",
    "PORTADA-DIRECTA-ASISA-SALUD-PROFESIONALES-LAVINIA" : "PORTADA DIRECTA ASISA SALUD PROFESIONALES LAVINIA",
    "VENTAJAS-EXCLUSIVAS-PARA-MEDICOS": "VENTAJAS EXCLUSIVAS PARA MEDICOS",
    "PORTADA-DIRECTA-ASISA-SALUD-HOSPITALIZACION-PROFESIONALES" : "PORTADA DIRECTA ASISA SALUD HOSPITALIZACION PROFESIONALES",
    "PORTADA-DIRECTA-ASISA-SALUD-ESPECIAL-MEDICOS": "PORTADA DIRECTA ASISA SALUD ESPECIAL MEDICOS",
    "PORTADA-DIRECTA-FAM-CAMINOS" : "PORTADA DIRECTA FAM CAMINOS",
    "PORTADA-DIRECTA-ASISA-SALUD-PROFESIONALES" : "PORTADA DIRECTA ASISA SALUD PROFESIONALES",
    "PORTADA-DIRECTA-START-SALUD-DENTAL": "PORTADA DIRECTA START SALUD DENTAL",
    "PORTADA-DIRECTA-WP-BANCA-MARCH-SALUD": "PORTADA DIRECTA WP BANCA MARCH SALUD",
    "PORTADA-DIRECTA-WP-BANCA-MARCH-DENTAL" : "PORTADA DIRECTA WP BANCA MARCH DENTAL",
    "PORTADA-DIRECTA-ASISA-MOMENTO" : "PORTADA DIRECTA ASISA MOMENTO",
    "PORTADA-DIRECTA-ASISA-DENTAL-3": "PORTADA DIRECTA ASISA DENTAL 3",
    "PORTADA-DIRECTA-ASISA-INTERNACIONAL" : "PORTADA DIRECTA ASISA INTERNACIONAL",
    "PORTADA-DIRECTA-ASISA-DENTAL-FAM-CAMINOS": "PORTADA DIRECTA ASISA DENTAL FAM CAMINOS",
    "PORTADA-DIRECTA-WP-BANCA-MARCH-DENTAL-SIN-FAMILIAR": "PORTADA DIRECTA WP BANCA MARCH DENTAL SIN FAMILIAR",
    "PORTADA-DIRECTA-MIGRACION-ASTURIAS-MUFACE" : "PORTADA DIRECTA MIGRACION ASTURIAS MUFACE",
    "PORTADA-TRANSPARENTE-EDA-22-X-30,7": "PORTADA TRANSPARENTE EDA 22 X 30,7",
    "PORTADA-DIRECTA-MIGRACION-ASTURIAS-ISFAS---MGJ": "PORTADA DIRECTA MIGRACION ASTURIAS ISFAS - MGJ",
    "DOCUMENTOS-A-DEVOLVER-A-ASISA-DIN-A-4" : "DOCUMENTOS A DEVOLVER A ASISA DIN A-4",
    "PORTADA-CONDICIONES-GENERALES-ASISA-UTIL-BANCO-SANTANDER": "PORTADA CONDICIONES GENERALES ASISA UTIL BANCO SANTANDER",
    "PORTADA-DIRECTA-ASISA-ACTIVA": "PORTADA DIRECTA ASISA ACTIVA",
    "PORTADA-DIRECTA-ASISA-SALUD-CORTE-INGLES-CESS" : "PORTADA DIRECTA ASISA SALUD CORTE INGLES CESS",
    "PORTADA-DIRECTA-ASISA-DENTAL-PORTUGAL" : "PORTADA DIRECTA ASISA DENTAL PORTUGAL",
    "PORTADA-SERVICIOS-DE-VALOR---MUITO-MAIS" : "PORTADA SERVICIOS DE VALOR - MUITO MAIS",
    "PORTADA-CONDICIONES-PARTICULARES-DENTAL-PORTUGAL": "PORTADA CONDICIONES PARTICULARES DENTAL PORTUGAL",
    "PORTADA-DIRECTA-ASISER-CARD" : "PORTADA DIRECTA ASISER CARD",
    "PORTADA-CONDICIONES-GENERALES-SATISFACCION-BANCO-DE-SANTANDER-PROMOCION-2015": "PORTADA CONDICIONES GENERALES SATISFACCION BANCO DE SANTANDER PROMOCION 2015",
    "PORTADA-DIRECTA-ASISA-DENTAL-PORTUGAL-APLICALIA-WP": "PORTADA DIRECTA ASISA DENTAL PORTUGAL APLICALIA WP",
    "PORTADA-TRANSPARENTE-EDA-19-X-23,3-SOLO-LOGO": "PORTADA TRANSPARENTE EDA 19 X 23,3 SOLO LOGO",
    "PORTADA-CONDICIONES-GENERALES-SATISFACCION-PLUS-BANCO-DE-SANTANDER": "PORTADA CONDICIONES GENERALES SATISFACCION PLUS BANCO DE SANTANDER",
    "PORTADA-SALUDCOR-CESS" : "PORTADA SALUDCOR CESS",
    "PORTADA-SALUDCOR-SECI" : "PORTADA SALUDCOR SECI",
    "PORTADA-DIRECTA-ASISA-SALUD-SECI": "PORTADA DIRECTA ASISA SALUD SECI",
    "PORTADA-DIRECTA-ASISA-DENTAL-PYMES": "PORTADA DIRECTA ASISA DENTAL PYMES",
    "PORTADA-DIRECTA-ASISA-DENTAL-FUNCIONARIOS" : "PORTADA DIRECTA ASISA DENTAL FUNCIONARIOS",
    "PORTADA-DIRECTA-ASISA-DECESOS" : "PORTADA DIRECTA ASISA DECESOS",
    "PORTADA-DIRECTA-ASISA-DENTAL-PORTUGAL-APRIL" : "PORTADA DIRECTA ASISA DENTAL PORTUGAL APRIL",
    "PORTADA-DIRECTA-WP-ASISA-ESENCIAL" : "PORTADA DIRECTA WP ASISA ESENCIAL",
    "PORTADA-CONDICIONES-GENERALES-SECI": "PORTADA CONDICIONES GENERALES SECI",
    "PORTADA-CONDICIONES-GENERALES-CEES": "PORTADA CONDICIONES GENERALES CEES",
    "PORTADA-DIRECTA-WP-ASISA-VIDA-MUJER" : "PORTADA DIRECTA WP ASISA VIDA MUJER",
    "PORTADA-DIRECTA-WP-ASISA-INTEGRAL-PYMES" : "PORTADA DIRECTA WP ASISA INTEGRAL PYMES",
    "PORTADA-DIRECTA-WP-ASISA-TRANQUILIDAD" : "PORTADA DIRECTA WP ASISA TRANQUILIDAD",
    "PORTADA-DIRECTA-WP-ASISA-VIDA-TRANQUILIDAD-PLUS" : "PORTADA DIRECTA WP ASISA VIDA TRANQUILIDAD PLUS",
    "PORTADA-DIRECTA-ASISA-SALUD-CORTE-INGLES-SECI" : "PORTADA DIRECTA ASISA SALUD CORTE INGLES SECI",
    "PORTADA-DIRECTA-ASISA-VIDA-TAR-CENTERBROK" : "PORTADA DIRECTA ASISA VIDA TAR CENTERBROK",
    "PORTADA-DIRECTA-ASISA-MONTEPIO": "PORTADA DIRECTA ASISA MONTEPIO",
    "MAS-PARA-SU-SALUD-MONTEPIO": "MAS PARA SU SALUD MONTEPIO",
    "PORTADA-CONDICIONES-GENERALES-MONTEPIO": "PORTADA CONDICIONES GENERALES MONTEPIO",
    "PORTADA-DIRECTA-ASISA-COLECTIVO-ONCE": "PORTADA DIRECTA ASISA COLECTIVO ONCE",
    "PORTADA-DIRECTA-ASISA-COLECTIVO-ONCE-ILUNION": "PORTADA DIRECTA ASISA COLECTIVO ONCE ILUNION",
    "PORTADA-DIRECTA-BANCO-CAMINOS" : "PORTADA DIRECTA BANCO CAMINOS",
    "PORTADA-DIRECTA-ASISA-MASCOTAS": "PORTADA DIRECTA ASISA MASCOTAS",
    "PORTADA-CONDICIONES-PARTICULARES-ASISA-VIDA-PORTUGAL": "PORTADA CONDICIONES PARTICULARES ASISA VIDA PORTUGAL",
    "PORTADA-CONDICIONES-PARTICULARES-ASISA-DENTAL-SUCURSAL-PORTUGAL" : "PORTADA CONDICIONES PARTICULARES ASISA DENTAL SUCURSAL PORTUGAL",
    "PORTADA-DIRECTA-WP-BANCA-MARCH-SALUD-BASIC": "PORTADA DIRECTA WP BANCA MARCH SALUD BASIC",
    "PORTADA-DIRECTA-WP-BANCA-MARCH-SALUD-20.000" : "PORTADA DIRECTA WP BANCA MARCH SALUD 20.000",
    "PORTADA-DIRECTA-WP-FABRICA-NACIONAL-MONEDA-Y-TIMBRE" : "PORTADA DIRECTA WP FABRICA NACIONAL MONEDA Y TIMBRE",
    "PORTADA-DIRECTA-WP-ASISA-VIDA-ACCIDENTES-COMPROMISO-10": "PORTADA DIRECTA WP ASISA VIDA ACCIDENTES COMPROMISO 10",
    "PORTADA-DIRECTA-WP-ASISA-AYUNTAMIENTO-DE-GRANADA": "PORTADA DIRECTA WP ASISA AYUNTAMIENTO DE GRANADA",
    "PORTADA-DIRECTA-WP-ASISA-AYUNTAMIENTO-DE-GRANADA-MUFACE" : "PORTADA DIRECTA WP ASISA AYUNTAMIENTO DE GRANADA MUFACE",
    "PORTADA-DIRECTA-WP-ASISA-TRATAMIENTOS-BUCODENTAL": "PORTADA DIRECTA WP ASISA TRATAMIENTOS BUCODENTAL",
    "PORTADA-CONDICIONES-PARTICULARES-ASISA-VIDA-ACCIDENTES-10-PORTUGAL": "PORTADA CONDICIONES PARTICULARES ASISA VIDA ACCIDENTES 10 PORTUGAL",
    "PORTADA-DIRECTA-WP-ASISA-TRAVEL" : "PORTADA DIRECTA WP ASISA TRAVEL",
    "PORTADA-DIRECTA-WP-ASISA-SALUD-PELAYO" : "PORTADA DIRECTA WP ASISA SALUD PELAYO",
    "PORTADA-DIRECTA-WP-ASISA-DENTAL-PELAYO": "PORTADA DIRECTA WP ASISA DENTAL PELAYO",
    "PORTADA-DIRECTA-WP-ASISA-INTEGRAL-PELAYO": "PORTADA DIRECTA WP ASISA INTEGRAL PELAYO",
    "PORTADA-DIRECTA-ASISA-INTERNACIONAL-UNIVERSIDAD-EUROPEA-MADRID": "PORTADA DIRECTA ASISA INTERNACIONAL UNIVERSIDAD EUROPEA MADRID",
    "PORTADA-CONDICIONES-PARTICULARES-ASISA-VIDA-CCAM-PORTUGAL" : "PORTADA CONDICIONES PARTICULARES ASISA VIDA CCAM PORTUGAL",
    "PORTADA-ASISA-PROXIMA-ASOCIACION-EUROPEA": "PORTADA ASISA PROXIMA ASOCIACION EUROPEA",
    "PORTADA-ASISA-SALUD-ASOCIACION-EUROPEA": "PORTADA ASISA SALUD ASOCIACION EUROPEA",
    "INFORMACION-PRODUCTO-ASISA-SALUD": "INFORMACION PRODUCTO ASISA SALUD",
    "INFORMACION-PRODUCTO-ASISA-HOSPITALIZACION": "INFORMACION PRODUCTO ASISA HOSPITALIZACION",
    "INFORMACION-PRODUCTO-ASISA-ACCIDENTES-PLUS-(ANTIGUA-ACCIDENTES)" : "INFORMACION PRODUCTO ASISA ACCIDENTES PLUS (ANTIGUA ACCIDENTES)",
    "INFORMACION-PRODUCTO-ASISA-ACCIDENTES-(ANTIGUA-PREVISION)" : "INFORMACION PRODUCTO ASISA ACCIDENTES (ANTIGUA PREVISION)",
    "INFORMACION-PRODUCTO-ASISA-DENTAL-Y-DENTAL-FAMILIAR" : "INFORMACION PRODUCTO ASISA DENTAL Y DENTAL FAMILIAR",
    "INFORMACION-PRODUCTO-ASISA-VIDA" : "INFORMACION PRODUCTO ASISA VIDA",
    "INFORMACION-PRODUCTO-ASISA-MASTER" : "INFORMACION PRODUCTO ASISA MASTER",
    "INFORMACION-PRODUCTO-ASISA-PYMES": "INFORMACION PRODUCTO ASISA PYMES",
    "INFORMACION-PRODUCTO-ASISA-AUTONOMOS": "INFORMACION PRODUCTO ASISA AUTONOMOS",
    "INFORMACION-PRODUCTO-ASISA-INTEGRAL" : "INFORMACION PRODUCTO ASISA INTEGRAL",
    "INFORMACION-PRODUCTO-ASISA-SALUD-FAMILIAR" : "INFORMACION PRODUCTO ASISA SALUD FAMILIAR",
    "INFORMACION-PRODUCTO-ASISA-ESTRELLA" : "INFORMACION PRODUCTO ASISA ESTRELLA",
    "INFORMACION-PRODUCTO-ASISA-PROXIMA": "INFORMACION PRODUCTO ASISA PROXIMA",
    "HOJA-TODOS-LOS-PRODUCTOS": "HOJA TODOS LOS PRODUCTOS",
    "INFORMACION-PRODUCTO-ASISA-DENTAL-FUNCIONARIOS": "INFORMACION PRODUCTO ASISA DENTAL FUNCIONARIOS",
    "INFORMACION-PRODUCTO-ASISA-DENTAL-PYMES" : "INFORMACION PRODUCTO ASISA DENTAL PYMES",
    "INFORMACION-PRODUCTO-ASISA-INTEGRAL-PYMES" : "INFORMACION PRODUCTO ASISA INTEGRAL PYMES",
    "INFORMACION-PRODUCTO-ASISA-INTERNACIONAL": "INFORMACION PRODUCTO ASISA INTERNACIONAL",
    "INFORMACION-PRODUCTO-ASISA-MOMENTO": "INFORMACION PRODUCTO ASISA MOMENTO",
    "INFORMACION-PRODUCTO-ASISA-VIDA-RETORNO" : "INFORMACION PRODUCTO ASISA VIDA RETORNO",
    "INFORMACION-PRODUCTO-ASISA-UTIL" : "INFORMACION PRODUCTO ASISA UTIL",
    "INFORMACION-PRODUCTO-ASISA-ACCIDENTES-Y-ACCIDENTES-PLUS-UNICA-FICHA" : "INFORMACION PRODUCTO ASISA ACCIDENTES Y ACCIDENTES PLUS UNICA FICHA",
    "INFORMACION-PRODUCTO-ASISA-DECESOS": "INFORMACION PRODUCTO ASISA DECESOS",
    "INFORMACION-PRODUCTO-ASISA-ACTIVA-PLUS": "INFORMACION PRODUCTO ASISA ACTIVA PLUS",
    "INFORMACION-BIOCORD" : "INFORMACION BIOCORD",
    "INFORMACION-BIOCORD-CATALAN" : "INFORMACION BIOCORD CATALAN",
    "INFORMACION-CLUB-ASISA": "INFORMACION CLUB ASISA",
    "INFORMACION-CLUB-ASISA-CATALAN": "INFORMACION CLUB ASISA CATALAN",
    "INFORMACION-REPRODUCCION-ASISTIDA" : "INFORMACION REPRODUCCION ASISTIDA",
    "INFORMACION-REPRODUCCION-ASISTIDA-CATALAN" : "INFORMACION REPRODUCCION ASISTIDA CATALAN",
    "HOJA-ACUERDO-CLINICA-DERMA-RUBER": "HOJA ACUERDO CLINICA DERMA RUBER",
    "CLINICA-DERMATOLOGICA-INTERNACIONAL-BANCA-MARCH" : "CLINICA DERMATOLOGICA INTERNACIONAL BANCA MARCH",
    "INFORMACION-LEGALITAS" : "INFORMACION LEGALITAS",
    "INFORMACION-CRIOCORD-CORTE-INGLES" : "INFORMACION CRIOCORD CORTE INGLES",
    "INFORMACION-REPRODUCCION-ASISTIDA-CORTE-INGLES": "INFORMACION REPRODUCCION ASISTIDA CORTE INGLES",
    "INFORMACION-CRIOCONSERVACION-ESTANDAR" : "INFORMACION CRIOCONSERVACION ESTANDAR",
    "INFORMACION-CRIOCONSERVACION-ESTANDAR-IMAGEN-ANTIGUA": "INFORMACION CRIOCONSERVACION ESTANDAR IMAGEN ANTIGUA",
    "INFORMACION-CRIOCORD-MONTEPIO" : "INFORMACION CRIOCORD MONTEPIO",
    "INFORMACION-REPRODUCCION-ASISTIDA-MONTEPIO": "INFORMACION REPRODUCCION ASISTIDA MONTEPIO",
    "INFORMACION-CLUB-ASISA-MONTEPIO" : "INFORMACION CLUB ASISA MONTEPIO",
    "INFORMACION-PAGINA-SERVICIO-VALOR-AÑADIDO" : "INFORMACION PAGINA SERVICIO VALOR AÑADIDO",
    "INFORMACION-GENERAL-OPTICA": "INFORMACION GENERAL OPTICA",
    "INFORMACION-DIOLA" : "INFORMACION DIOLA",
    "INFORMACION-ASPAD-MASCOTAS": "INFORMACION ASPAD MASCOTAS",
    "INFORMACION-PAGINA-SERVICIO-VALOR-AÑADIDO-SIN-ASPAD-MASCOTAS": "INFORMACION PAGINA SERVICIO VALOR AÑADIDO SIN ASPAD MASCOTAS",
    "FIRMA-CONDICIONES-GENERALES" : "FIRMA CONDICIONES GENERALES",
    "FIRMA-CONDICIONES-GENERALES-CATALAN" : "FIRMA CONDICIONES GENERALES CATALAN",
    "FIRMA-CONDICIONES-GENERALES-EN-INGLES" : "FIRMA CONDICIONES GENERALES EN INGLES",
    "FIRMA-CONDICIONES-GENERALES-ASISA-MOMENTO" : "FIRMA CONDICIONES GENERALES ASISA MOMENTO",
    "FIRMA-CONDICIONES-GENERALES-ASISA-ACTIVA": "FIRMA CONDICIONES GENERALES ASISA ACTIVA",
    "FIRMA-CONDICIONES-GENERALES-PACK-ELECTRONICO": "FIRMA CONDICIONES GENERALES PACK ELECTRONICO",
    "FIRMA-CONDICIONES-GENERALES-ASISA-MOMENTO-PACK-ELECTRONICO": "FIRMA CONDICIONES GENERALES ASISA MOMENTO PACK ELECTRONICO",
    "FIRMA-CONDICIONES-GENERALES-ASISA-ACTIVA-PACK-ELECTRONICO" : "FIRMA CONDICIONES GENERALES ASISA ACTIVA PACK ELECTRONICO",
    "FIRMA-CONDICIONES-GENERALES-ASISA-VIDA": "FIRMA CONDICIONES GENERALES ASISA VIDA",
    "FIRMA-CONDICIONES-GENERALES-BANCO-CAMINOS" : "FIRMA CONDICIONES GENERALES BANCO CAMINOS",
    "FIRMA-CONDICIONES-GENERALES-ASISA-VIDA-PORTUGAL" : "FIRMA CONDICIONES GENERALES ASISA VIDA PORTUGAL",
    "FIRMA-CONDICIONES-GENERALES-ASISA-DENTAL-SUCURSAL-PORTUGAL": "FIRMA CONDICIONES GENERALES ASISA DENTAL SUCURSAL PORTUGAL",
    "CARTA-AYUNTAMIENTO-DE-SEVILLA" : "CARTA AYUNTAMIENTO DE SEVILLA",
    "CARTA-CONTRATACION-ASEGURADOS-COLECTIVOS-CERRADOS-(SIN-CP)": "CARTA CONTRATACION ASEGURADOS COLECTIVOS CERRADOS (SIN CP)",
    "CARTA-CONTRATACION-ASEGURADOS-COLECTIVOS-CERRADOS-EN-CATALAN-(SIN-CP)" : "CARTA CONTRATACION ASEGURADOS COLECTIVOS CERRADOS EN CATALAN (SIN CP)",
    "CARTA-INFORMACION-ASISA-SALUD-COLECTIVOS": "CARTA INFORMACION ASISA SALUD COLECTIVOS",
    "CARTA-DIPUTACION-DE-SORIA" : "CARTA DIPUTACION DE SORIA",
    "CARTA-AYUNTAMIENTO-DE-REUS": "CARTA AYUNTAMIENTO DE REUS",
    "CARTA-AYUNTAMIENTO-PUERTO-DE-SANTAMARIA" : "CARTA AYUNTAMIENTO PUERTO DE SANTAMARIA",
    "CARTA-AYUNTAMIENTO-DE-MAZARRON": "CARTA AYUNTAMIENTO DE MAZARRON",
    "CARTA-EL-CORTE-INGLES" : "CARTA EL CORTE INGLES",
    "CARTA-AYUNTAMIENTO-DE-LUGO": "CARTA AYUNTAMIENTO DE LUGO",
    "CARTA-COLECTIVO-BANCA-MARCH" : "CARTA COLECTIVO BANCA MARCH",
    "CARTA-COLECTIVO-FAMILIARES-IMED" : "CARTA COLECTIVO FAMILIARES IMED",
    "CARTA-ESPECIAL-COLECTIVOS-PANDA" : "CARTA ESPECIAL COLECTIVOS PANDA",
    "CARTA-COLECTIVO-BANCO-DE-SANTANDER": "CARTA COLECTIVO BANCO DE SANTANDER",
    "CARTA-COLECTIVO-BANCO-DE-SANTANDER-IGUALATORIO": "CARTA COLECTIVO BANCO DE SANTANDER IGUALATORIO",
    "CARTA-RETENCIONES-REACTIVAS" : "CARTA RETENCIONES REACTIVAS",
    "CARTA-COLECTIVO-BANCO-SANTANDER-EXCLUSIVE" : "CARTA COLECTIVO BANCO SANTANDER EXCLUSIVE",
    "CARTA-CONTRATACION-ASEGURADOS-COLECTIVOS-ABIERTOS-(CON-CP)": "CARTA CONTRATACION ASEGURADOS COLECTIVOS ABIERTOS (CON CP)",
    "CARTA-CONTRATACION-ASEGURADOS-COLECTIVOS-ABIERTOS-EN-INGLES-(CON-CP)": "CARTA CONTRATACION ASEGURADOS COLECTIVOS ABIERTOS EN INGLES (CON CP)",
    "CARTA-CONTRATACION-BANCO-SANTANDER-EMPRESAS-COLECTIVOS-CERRADOS" : "CARTA CONTRATACION BANCO SANTANDER EMPRESAS COLECTIVOS CERRADOS",
    "CARTA-CONTRATACION-GRUPO-LIBERTY": "CARTA CONTRATACION GRUPO LIBERTY",
    "CARTA-PACK-SIMPLIFICADO-TARJETA-+-CP": "CARTA PACK SIMPLIFICADO TARJETA + CP",
    "CARTA-PACK-SIMPLIFICADO-SOLO-TARJETA": "CARTA PACK SIMPLIFICADO SOLO TARJETA",
    "CARTA-INFORMACION-ASISA-SALUD" : "CARTA INFORMACION ASISA SALUD",
    "CARTA-INFORMACION-ASISA-ESTRELLA": "CARTA INFORMACION ASISA ESTRELLA",
    "CARTA-INFORMACION-ASISA-UTIL": "CARTA INFORMACION ASISA UTIL",
    "CARTA-INFORMACION-ASISA-MASTER": "CARTA INFORMACION ASISA MASTER",
    "CARTA-INFORMACION-ASISA-SALUD-FAMILIAR": "CARTA INFORMACION ASISA SALUD FAMILIAR",
    "CARTA-INFORMACION-ASISA-AUTONOMOS" : "CARTA INFORMACION ASISA AUTONOMOS",
    "CARTA-INFORMACION-ASISA-INTEGRAL-100": "CARTA INFORMACION ASISA INTEGRAL 100",
    "CARTA-INFORMACION-ASISA-INTEGRAL-180": "CARTA INFORMACION ASISA INTEGRAL 180",
    "CARTA-INFORMACION-ASISA-INTEGRAL-240": "CARTA INFORMACION ASISA INTEGRAL 240",
    "CARTA-INFORMACION-ASISA-DENTAL-FAMILIAR" : "CARTA INFORMACION ASISA DENTAL FAMILIAR",
    "CARTA-INFORMACION-ASISA-DENTAL": "CARTA INFORMACION ASISA DENTAL",
    "CARTA-CONTRATACION-ASISA-VIDA" : "CARTA CONTRATACION ASISA VIDA",
    "CARTA-CONTRATACION-FECYT": "CARTA CONTRATACION FECYT",
    "CARTA-ASISA-DENTAL-BANCO-SANTANDER": "CARTA ASISA DENTAL BANCO SANTANDER",
    "CARTA-CORPORACION-DIRECTA" : "CARTA CORPORACION DIRECTA",
    "CARTA-START-SALUD" : "CARTA START SALUD",
    "CARTA-INFORMACION-ASISA-PROXIMA" : "CARTA INFORMACION ASISA PROXIMA",
    "CARTA-INFORMACION-ASISA-PYMES" : "CARTA INFORMACION ASISA PYMES",
    "CARTA-INFORMACION-ASISA-PYMES-PLUS": "CARTA INFORMACION ASISA PYMES PLUS",
    "ASISA-INFORMACION-PYMES-INTEGRAL-180": "ASISA INFORMACION PYMES INTEGRAL 180",
    "CARTA-INFORMACION-ASISA-PYMES-DENTAL": "CARTA INFORMACION ASISA PYMES DENTAL",
    "CARTA-INFORMACION-ASISA-PYMES-PLUS-DENTAL" : "CARTA INFORMACION ASISA PYMES PLUS DENTAL",
    "CARTA-CONTRATACION-COLECTIVOS-PACK-ELECTRONICO": "CARTA CONTRATACION COLECTIVOS PACK ELECTRONICO",
    "CARTA-SANTANDER---BANESTO-V2-PACK-ELECTRONICO" : "CARTA SANTANDER - BANESTO V2 PACK ELECTRONICO",
    "CARTA-INFORMACION-ASISA-INTEGRAL-240-COLECTIVOS" : "CARTA INFORMACION ASISA INTEGRAL 240 COLECTIVOS",
    "CARTA-INFORMACION-ASISA-INTEGRAL-100-COLECTIVOS" : "CARTA INFORMACION ASISA INTEGRAL 100 COLECTIVOS",
    "CARTA-INFORMACION-ASISA-INTEGRAL-180-COLECTIVOS" : "CARTA INFORMACION ASISA INTEGRAL 180 COLECTIVOS",
    "CARTA-INFORMACION-ASISA-SANTANDER-SELECT": "CARTA INFORMACION ASISA SANTANDER SELECT",
    "CARTA-INFORMACION-ASISA-DENTAL-FAMILIAR-COLECTIVOS": "CARTA INFORMACION ASISA DENTAL FAMILIAR COLECTIVOS",
    "CARTA-INFORMACION-ASISA-DENTAL-COLECTIVOS" : "CARTA INFORMACION ASISA DENTAL COLECTIVOS",
    "CARTA-INFORMACION-ASISA-MASTER-COLECTIVOS" : "CARTA INFORMACION ASISA MASTER COLECTIVOS",
    "CARTA-INFORMACION-ASISA-UTIL-COLECTIVOS" : "CARTA INFORMACION ASISA UTIL COLECTIVOS",
    "CARTA-INFORMACION-ASISA-PROXIMA-COLECTIVOS": "CARTA INFORMACION ASISA PROXIMA COLECTIVOS",
    "CARTA-INFORMACION-ASISA-INTEGRAL-COLECTIVOS" : "CARTA INFORMACION ASISA INTEGRAL COLECTIVOS",
    "CARTA-CONTRATACION-BANCA-MARCH-PARA-ENVÍO-ELECTRONICO" : "CARTA CONTRATACION BANCA MARCH PARA ENVÍO ELECTRONICO",
    "CARTA-INFORMACION-ASISA-HOSPITALIZACION" : "CARTA INFORMACION ASISA HOSPITALIZACION",
    "CARTA-CONTRATACION-ASISA-ACCIDENTES-(ANTIGUA-PREVISION)" : "CARTA CONTRATACION ASISA ACCIDENTES (ANTIGUA PREVISION)",
    "CARTA-CONTRATACION-ASISA-TRIPLE-HOSPITALIZACION" : "CARTA CONTRATACION ASISA TRIPLE HOSPITALIZACION",
    "CARTA-CONTRATACION-ASISA-HOSPITALIZACION": "CARTA CONTRATACION ASISA HOSPITALIZACION",
    "CARTA-CONTRATACION-ASISA-ACCIDENTES-PLUS-(ANTIGUA-ACCIDENTES)" : "CARTA CONTRATACION ASISA ACCIDENTES PLUS (ANTIGUA ACCIDENTES)",
    "CARTA-CONTRATACION-BARYMONT" : "CARTA CONTRATACION BARYMONT",
    "CARTA-CONTRATACION-ISFAS": "CARTA CONTRATACION ISFAS",
    "CARTA-MIGRACION-ASISA-SALUD-PROFESIONALES-LAVINIA" : "CARTA MIGRACION ASISA SALUD PROFESIONALES LAVINIA",
    "CARTA-MIGRACION-ASISA-SALUD-HOSPITALIZACION-PROFESIONALES" : "CARTA MIGRACION ASISA SALUD HOSPITALIZACION PROFESIONALES",
    "CARTA-MIGRACION-ASISA-SALUD-ESPECIAL-MEDICOS": "CARTA MIGRACION ASISA SALUD ESPECIAL MEDICOS",
    "CARTA-ASISA-SALUD-PROFESIONALES-LAVINIA" : "CARTA ASISA SALUD PROFESIONALES LAVINIA",
    "CARTA-ASISA-SALUD-ESPECIAL-MEDICOS": "CARTA ASISA SALUD ESPECIAL MEDICOS",
    "CARTA-FAM-CAMINOS" : "CARTA FAM CAMINOS",
    "CARTA-CONTRATACION-EMPRESAS-BANCA-MARCH-(C.-ABIERTOS)" : "CARTA CONTRATACION EMPRESAS BANCA MARCH (C. ABIERTOS)",
    "CARTA-CONTRATACION-BANCA-MARCH-(C.-CERRADO)" : "CARTA CONTRATACION BANCA MARCH (C. CERRADO)",
    "CARTA-CONTRATACION-BANCO-SANTANDER---EMPRESAS-ASISA-DENTAL-(C.-CERRADO)" : "CARTA CONTRATACION BANCO SANTANDER - EMPRESAS ASISA DENTAL (C. CERRADO)",
    "CARTA-CONTRATACION-BANCO-SANTANDER---EMPRESAS-ASISA-DENTAL-(C.-ABIERTO)" : "CARTA CONTRATACION BANCO SANTANDER - EMPRESAS ASISA DENTAL (C. ABIERTO)",
    "CARTA-CONTRATACION-BANCO-SANTANDER---EMPRESAS-ASISA-DENTAL-FAMILIAR-(C.-CERRADO)": "CARTA CONTRATACION BANCO SANTANDER - EMPRESAS ASISA DENTAL FAMILIAR (C. CERRADO)",
    "CARTA-PROTECCION-DATOS-NINTENDO" : "CARTA PROTECCION DATOS NINTENDO",
    "CARTA-ASISA-SALUD-PROFESIONALES" : "CARTA ASISA SALUD PROFESIONALES",
    "CARTA-INFORMACION-ASISA-ACCIDENTES": "CARTA INFORMACION ASISA ACCIDENTES",
    "CARTA-INFORMACION-ASISA-ACCIDENTES-PLUS" : "CARTA INFORMACION ASISA ACCIDENTES PLUS",
    "CARTA-CONDICIONES-CONTRATACION-EL-CORTE-INGLES": "CARTA CONDICIONES CONTRATACION EL CORTE INGLES",
    "CARTA-DE-BIENVENIDA-PARA-COLECTIVOS-ABIERTOS-DENTALES-DE-BANCA-MARCH": "CARTA DE BIENVENIDA PARA COLECTIVOS ABIERTOS DENTALES DE BANCA MARCH",
    "CARTA-DE-BIENVENIDA-PARA-COLECTIVOS-CERRADOS-DENTALES-DE-BANCA-MARCH": "CARTA DE BIENVENIDA PARA COLECTIVOS CERRADOS DENTALES DE BANCA MARCH",
    "CARTA-CONTRATACION-ASISA-DENTAL-C.-CERRADOS" : "CARTA CONTRATACION ASISA DENTAL C. CERRADOS",
    "CARTA-ACUERDO-DE-DISTRIBUCION-BANCA-MARCH" : "CARTA ACUERDO DE DISTRIBUCION BANCA MARCH",
    "CARTA-GRUPO-INDITEX" : "CARTA GRUPO INDITEX",
    "CARTA-FUNDACION-UNIVERSIDAD.ES": "CARTA FUNDACION UNIVERSIDAD.ES",
    "CARTA-INFORMACION-GALP": "CARTA INFORMACION GALP",
    "CARTA-BIENVENIDA-COLECTIVO-AUTORIDAD-PORTUARIA-DE-LAS-PALMAS": "CARTA BIENVENIDA COLECTIVO AUTORIDAD PORTUARIA DE LAS PALMAS",
    "CARTA-INFORMACION-ASISA-MOMENTO" : "CARTA INFORMACION ASISA MOMENTO",
    "CARTA-CONTRATACION-ASISA-INTERNACIONAL": "CARTA CONTRATACION ASISA INTERNACIONAL",
    "CARTA-INFORMACION-ASISA-INTERNACIONAL" : "CARTA INFORMACION ASISA INTERNACIONAL",
    "CARTA-BIENVENIDA-DENTAL-FAM-CAMINOS" : "CARTA BIENVENIDA DENTAL FAM CAMINOS",
    "CARTA-BIENVENIDA-ASISA-DENTAL-BANCA-MARCH" : "CARTA BIENVENIDA ASISA DENTAL BANCA MARCH",
    "CARTA-BIENVENIDA-UNIVERSIDAD-INTERNACIONAL-DE-ANDALUCIA" : "CARTA BIENVENIDA UNIVERSIDAD INTERNACIONAL DE ANDALUCIA",
    "CARTA-SALUD-COLECTIVOS-NACIONALES-ASTURIAS": "CARTA SALUD COLECTIVOS NACIONALES ASTURIAS",
    "CARTA-MIGRACION-ASTURIAS-FAM-CAMINOS": "CARTA MIGRACION ASTURIAS FAM CAMINOS",
    "CARTA-MIGRACION-ASTURIAS-ACUERDO-DISTRIBUCION-SANTANDER" : "CARTA MIGRACION ASTURIAS ACUERDO DISTRIBUCION SANTANDER",
    "CARTA-MIGRACION-ASTURIAS-SANTANDER-EMPLEADOS": "CARTA MIGRACION ASTURIAS SANTANDER EMPLEADOS",
    "CARTA-MIGRACION-ASTURIAS-RESTO-COLECTIVOS" : "CARTA MIGRACION ASTURIAS RESTO COLECTIVOS",
    "CARTA-BIENVENIDA-GRUPO-IRIZAR" : "CARTA BIENVENIDA GRUPO IRIZAR",
    "CARTA-BIENVENIDA-CASTELLANO-GRUPO-IRIZAR": "CARTA BIENVENIDA CASTELLANO GRUPO IRIZAR",
    "CARTA-BIENVENIDA-GRUPO-NISSAN" : "CARTA BIENVENIDA GRUPO NISSAN",
    "CARTA-ENVIO-GARANTIA-DENTAL-CORTE-INGLES-WP-PROVISIONAL" : "CARTA ENVIO GARANTIA DENTAL CORTE INGLES WP PROVISIONAL",
    "CARTA-INFORMACION-ASISA-ACTIVA": "CARTA INFORMACION ASISA ACTIVA",
    "CARTA-BIENVENIDA-AYUNTAMIENTO-DE-LUCENA" : "CARTA BIENVENIDA AYUNTAMIENTO DE LUCENA",
    "CARTA-BIENVENIDA-ASISA-CORPORACION-DIRECTA": "CARTA BIENVENIDA ASISA CORPORACION DIRECTA",
    "CARTA-BIENVENIDA-ASISA-CORPORACION-SIN-POSTVENTA": "CARTA BIENVENIDA ASISA CORPORACION SIN POSTVENTA",
    "CARTA-PACK-SIMPLIFICADO-TARJETA-+-CP-CORTE-INGLES-CESS": "CARTA PACK SIMPLIFICADO TARJETA + CP CORTE INGLES CESS",
    "CARTA-CONTRATACION-BP-OIL-ESPAÑA-PACK-ELECTRONICO" : "CARTA CONTRATACION BP OIL ESPAÑA PACK ELECTRONICO",
    "CARTA-PACK-ASISA-DENTAL-PORTUGAL": "CARTA PACK ASISA DENTAL PORTUGAL",
    "CARTA-PARA-TARJETA-DENTAL-PORTUGAL": "CARTA PARA TARJETA DENTAL PORTUGAL",
    "CARTA-CONTRATACION-ELECTRONICA": "CARTA CONTRATACION ELECTRONICA",
    "CARTA-CONTRATACION-ELECTRONICA-ACTIVA-BCN" : "CARTA CONTRATACION ELECTRONICA ACTIVA BCN",
    "CARTA-CONTRATACION-ASISER-CARD": "CARTA CONTRATACION ASISER CARD",
    "CARTA-PARA-TARJETA-ASISER-CARD": "CARTA PARA TARJETA ASISER CARD",
    "CARTA-GARANTIA-COLECTIVO-BANCO-DE-SANTANDER-UNIVERSIDADES-4066": "CARTA GARANTIA COLECTIVO BANCO DE SANTANDER UNIVERSIDADES 4066",
    "CARTA-GARANTIA-COLECTIVO-BANCO-DE-SANTANDER-UNIVERSIDADES-4067": "CARTA GARANTIA COLECTIVO BANCO DE SANTANDER UNIVERSIDADES 4067",
    "CARTA-PACK-ASISA-DENTAL-PORTUGAL-APLICALIA": "CARTA PACK ASISA DENTAL PORTUGAL APLICALIA",
    "CARTA-BIENVENIDA-CONSEJERIA-EDUCACION-Y-CULTURA-ASTURIAS": "CARTA BIENVENIDA CONSEJERIA EDUCACION Y CULTURA ASTURIAS",
    "CARTA-BIENVENIDA-MINISTERIO-EMPLEO-Y-SEGURIDAD-SOCIAL" : "CARTA BIENVENIDA MINISTERIO EMPLEO Y SEGURIDAD SOCIAL",
    "CARTA-CONTRATO-DIGITAL-ASISA-SALUD": "CARTA CONTRATO DIGITAL ASISA SALUD",
    "CARTA-CONTRATO-DIGITAL-ASISA-SALUD-FAMILIAR" : "CARTA CONTRATO DIGITAL ASISA SALUD FAMILIAR",
    "CARTA-CONTRATO-DIGITAL-ASISA-MASTER" : "CARTA CONTRATO DIGITAL ASISA MASTER",
    "CARTA-CONTRATO-DIGITAL-ASISA-UTIL" : "CARTA CONTRATO DIGITAL ASISA UTIL",
    "CARTA-CONTRATO-DIGITAL-ASISA-PROXIMA": "CARTA CONTRATO DIGITAL ASISA PROXIMA",
    "CARTA-CONTRATO-DIGITAL-ASISA-INTEGRAL" : "CARTA CONTRATO DIGITAL ASISA INTEGRAL",
    "CARTA-CONTRATO-DIGITAL-ASISA-AUTONOMOS": "CARTA CONTRATO DIGITAL ASISA AUTONOMOS",
    "CARTA-CONTRATO-DIGITAL-ASISA-ESTRELLA" : "CARTA CONTRATO DIGITAL ASISA ESTRELLA",
    "CARTA-CONTRATO-DIGITAL-ASISA-ACTIVA" : "CARTA CONTRATO DIGITAL ASISA ACTIVA",
    "CARTA-CONTRATO-DIGITAL-ASISA-MOMENTO": "CARTA CONTRATO DIGITAL ASISA MOMENTO",
    "CARTA-CONTRATO-DIGITAL-ASISA-INTEGRAL-PYMES" : "CARTA CONTRATO DIGITAL ASISA INTEGRAL PYMES",
    "CARTA-CESS-SALUDCOR-CON-DENTAL": "CARTA CESS SALUDCOR CON DENTAL",
    "CARTA-CESS-SALUDCOR-SIN-DENTAL": "CARTA CESS SALUDCOR SIN DENTAL",
    "CARTA-SECI-SALUDCOR-CON-DENTAL": "CARTA SECI SALUDCOR CON DENTAL",
    "CARTA-SECI-SALUDCOR-SIN-DENTAL": "CARTA SECI SALUDCOR SIN DENTAL",
    "CARTA-SECI": "CARTA SECI",
    "CARTA-CESS-SALUDCOR-CON-DENTAL-CANTANBRIA" : "CARTA CESS SALUDCOR CON DENTAL CANTANBRIA",
    "CARTA-CESS-SALUDCOR-CANTABRIA" : "CARTA CESS SALUDCOR CANTABRIA",
    "CARTA-CESS-SALUDCOR-CON-DENTAL-NAVARRA": "CARTA CESS SALUDCOR CON DENTAL NAVARRA",
    "CARTA-CESS-SALUDCOR-NAVARRA" : "CARTA CESS SALUDCOR NAVARRA",
    "CARTA-CONTRATACION-ASEGURADOS-COLECTIVO-DRAGADOS-(SIN-CP)" : "CARTA CONTRATACION ASEGURADOS COLECTIVO DRAGADOS (SIN CP)",
    "CARTA-CONTRATACION-ASEGURADOS-COLECTIVO-ISEMAPI" : "CARTA CONTRATACION ASEGURADOS COLECTIVO ISEMAPI",
    "CARTA-PARA-TARJETA-ASISER-CARD-LEVANTE": "CARTA PARA TARJETA ASISER CARD LEVANTE",
    "CARTA-BIENVENIDA-ESTUDIANTES-PACK-FISICO": "CARTA BIENVENIDA ESTUDIANTES PACK FISICO",
    "CARTA-BIENVENIDA-ESTUDIANTES-PACK-DIGITAL" : "CARTA BIENVENIDA ESTUDIANTES PACK DIGITAL",
    "CARTA-CONTRATACION-PACK-ELECTRONICO-ASEGURADOS-COLECTIVOS-ABIERTOS-(CON-CP)" : "CARTA CONTRATACION PACK ELECTRONICO ASEGURADOS COLECTIVOS ABIERTOS (CON CP)",
    "CARTA-CONTRATACIÓN-FÍSICA-ACTIVA-BCN": "CARTA CONTRATACIÓN FÍSICA ACTIVA BCN",
    "CARTA-CONTRATACIÓN-FÍSICA-GENERAL-OPTICA": "CARTA CONTRATACIÓN FÍSICA GENERAL OPTICA",
    "CARTA-CONTRATACIÓN-ELECTRONICA-GENERAL-OPTICA" : "CARTA CONTRATACIÓN ELECTRONICA GENERAL OPTICA",
    "CARTA-BIENVENIDA-AYUNTAMIENTO-DE-SALOBREÑA": "CARTA BIENVENIDA AYUNTAMIENTO DE SALOBREÑA",
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE": "CARTA BIENVENIDA COLECTIVO SEPIE",
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-COLFUTURO": "CARTA BIENVENIDA COLECTIVO SEPIE COLFUTURO",
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-BECAR-DOCTORANDOS": "CARTA BIENVENIDA COLECTIVO SEPIE BECAR DOCTORANDOS",
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-BECAR-MAESTRIAS-2015" : "CARTA BIENVENIDA COLECTIVO SEPIE BECAR MAESTRIAS 2015",
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-BECAR-MAESTRIAS-CIENCIAS-FORESTALES": "CARTA BIENVENIDA COLECTIVO SEPIE BECAR MAESTRIAS CIENCIAS FORESTALES",
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-BECAR-MAESTRIAS-CIENCIA-Y-TECNOLOGIA" : "CARTA BIENVENIDA COLECTIVO SEPIE BECAR MAESTRIAS CIENCIA Y TECNOLOGIA",
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-BECAR-CURSOS-CORTOS-CIENCIAS-DEL-MAR" : "CARTA BIENVENIDA COLECTIVO SEPIE BECAR CURSOS CORTOS CIENCIAS DEL MAR",
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-DON-CARLOS-ANTONIO-LOPEZ" : "CARTA BIENVENIDA COLECTIVO SEPIE DON CARLOS ANTONIO LOPEZ",
    "CARTA-BIENVENIDA-COLECTIVO-SEPIE-BECAR-DOCTORANDOS-2015" : "CARTA BIENVENIDA COLECTIVO SEPIE BECAR DOCTORANDOS 2015",
    "CARTA-BIENVENIDA-PACK-FISICO-ESTUDIANTES-COLECTIVO-22353": "CARTA BIENVENIDA PACK FISICO ESTUDIANTES COLECTIVO 22353",
    "CARTA-BIENVENIDA-PACK-ELECTRÓNICO-ESTUDIANTES-COLECTIVO-22353" : "CARTA BIENVENIDA PACK ELECTRÓNICO ESTUDIANTES COLECTIVO 22353",
    "CARTA-BIENVENIDA-CLUB-ALBACETE-COELCTIVO-5717" : "CARTA BIENVENIDA CLUB ALBACETE COELCTIVO 5717",
    "CARTA-BIENVENIDA-CLUB-ALBACETE-DENTAL" : "CARTA BIENVENIDA CLUB ALBACETE DENTAL",
    "CARTA-BIENVENIDA-ASISA-DECESOS": "CARTA BIENVENIDA ASISA DECESOS",
    "CARTA-PACK-ASISA-DENTAL-PORTUGAL-APRIL": "CARTA PACK ASISA DENTAL PORTUGAL APRIL",
    "CARTA-BIENVENIDA-AYUNTAMIENTO-CONCENTAINA" : "CARTA BIENVENIDA AYUNTAMIENTO CONCENTAINA",
    "CARTA-BIENVENIDA-GLOBALIA" : "CARTA BIENVENIDA GLOBALIA",
    "CARTA-CONTRATACION-ASISA-VIDA-MUJER" : "CARTA CONTRATACION ASISA VIDA MUJER",
    "CARTA-CONTRATACION-ASISA-VIDA-TRANQUILIDAD": "CARTA CONTRATACION ASISA VIDA TRANQUILIDAD",
    "CARTA-CONTRATACION-ASISA-VIDA-TRANQUILIDAD-PLUS" : "CARTA CONTRATACION ASISA VIDA TRANQUILIDAD PLUS",
    "CARTA-CONTRATACION-ASISA-VIDA-RETORNO" : "CARTA CONTRATACION ASISA VIDA RETORNO",
    "CARTA-CONTRATACION-GRUPO-BARCELO": "CARTA CONTRATACION GRUPO BARCELO",
    "CARTA-INFORMACION-ASISA-INTEGRAL-1.000.000": "CARTA INFORMACION ASISA INTEGRAL 1.000.000",
    "CARTA-PACK-SIMPLIFICADO-TARJETA-+-CP-CORTE-INGLES-SECI": "CARTA PACK SIMPLIFICADO TARJETA + CP CORTE INGLES SECI",
    "CARTA-BIENVENIDA-CLUB-ALBACETE-DEPORTIVO": "CARTA BIENVENIDA CLUB ALBACETE DEPORTIVO",
    "CARTA-BIENVENIDA-ASISA-MONTEPIO" : "CARTA BIENVENIDA ASISA MONTEPIO",
    "CARTA-BIENVENIDA-ASISA-COLECTIVO-PREVIMUTUA" : "CARTA BIENVENIDA ASISA COLECTIVO PREVIMUTUA",
    "CARTA-DE-NOTA-INFORMATIVA-BANCO-CAMINOS" : "CARTA DE NOTA INFORMATIVA BANCO CAMINOS",
    "CARTA-BIENVENIDA-ASISA-COLECTIVO-ONCE" : "CARTA BIENVENIDA ASISA COLECTIVO ONCE",
    "CARTA-BIENVENIDA-ASISA-COLECTIVO-MUTUALIDAD-DE-VIAJANTES": "CARTA BIENVENIDA ASISA COLECTIVO MUTUALIDAD DE VIAJANTES",
    "CARTA-BIENVENIDA-ASISA-MASCOTAS" : "CARTA BIENVENIDA ASISA MASCOTAS",
    "CARTA-BIENVENIDA-ASISA-VIDA-PORTUGAL": "CARTA BIENVENIDA ASISA VIDA PORTUGAL",
    "CARTA-BIENVENIDA-ASISA-DENTAL-SUCURSAL-PORTUGAL" : "CARTA BIENVENIDA ASISA DENTAL SUCURSAL PORTUGAL",
    "CARTA-BIENVENIDA-ASISA-COLECTIVO-AECID": "CARTA BIENVENIDA ASISA COLECTIVO AECID",
    "CARTA-BIENVENIDA-ASISA-PALMA-DE-MALLORCA-CATALAN": "CARTA BIENVENIDA ASISA PALMA DE MALLORCA CATALAN",
    "CARTA-BIENVENIDA-ASISA-DENTAL-FAMILIAR-SUCURSAL-PORTUGAL": "CARTA BIENVENIDA ASISA DENTAL FAMILIAR SUCURSAL PORTUGAL",
    "CARTA-BIENVENIDA-ASISA-DIPUTACION-DE-LERIDA" : "CARTA BIENVENIDA ASISA DIPUTACION DE LERIDA",
    "CARTA-BIENVENIDA-ASISA-DIPUTACION-DE-LERIDA-CATALAN" : "CARTA BIENVENIDA ASISA DIPUTACION DE LERIDA CATALAN",
    "CARTA-BIENVENIDA-ADELANTO-PROVISIONAL-PYMES" : "CARTA BIENVENIDA ADELANTO PROVISIONAL PYMES",
    "CARTA-BIENVENIDA-ASISA-COLECTIVO-FABRICA-NACIONAL-MONEDA-Y-TIMBRE" : "CARTA BIENVENIDA ASISA COLECTIVO FABRICA NACIONAL MONEDA Y TIMBRE",
    "CARTA-CONTRATACION-ASISA-VIDA-ACCIDENTES-COMPROMISO-10": "CARTA CONTRATACION ASISA VIDA ACCIDENTES COMPROMISO 10",
    "CARTA-CONTRATACION-ASISA-MUFACE-AYUNTAMIENTO-DE-GRANADA" : "CARTA CONTRATACION ASISA MUFACE AYUNTAMIENTO DE GRANADA",
    "CARTA-CONTRATACION-ASISA-AYUNTAMIENTO-DE-GRANADA-SALUD-ABIERTO-ELECTRONICO": "CARTA CONTRATACION ASISA AYUNTAMIENTO DE GRANADA SALUD ABIERTO ELECTRONICO",
    "CARTA-CONTRATACION-ASISA-AYUNTAMIENTO-DE-GRANADA-SALUD-ABIERTO-FISICO" : "CARTA CONTRATACION ASISA AYUNTAMIENTO DE GRANADA SALUD ABIERTO FISICO",
    "CARTA-BIENVENIDA-ASISA-VIDA-ACCIDENTES-10-PORTUGAL": "CARTA BIENVENIDA ASISA VIDA ACCIDENTES 10 PORTUGAL",
    "CARTA-CONTRATACION-ASISA-TRATAMIENTO-BUCODENTAL" : "CARTA CONTRATACION ASISA TRATAMIENTO BUCODENTAL",
    "CARTA-CONTRATACION-ASISA-TRAVEL-AND-YOU-Y-STUDY" : "CARTA CONTRATACION ASISA TRAVEL AND YOU Y STUDY",
    "CARTA-CONTRATACION-ASISA-TRAVEL-ALL-YEAR": "CARTA CONTRATACION ASISA TRAVEL ALL YEAR",
    "CARTA-CONTRATACION-ASISA-SALUD-PELAYO" : "CARTA CONTRATACION ASISA SALUD PELAYO",
    "CARTA-CONTRATACION-ASISA-DENTAL-PELAYO": "CARTA CONTRATACION ASISA DENTAL PELAYO",
    "CARTA-BIENVENIDA-ASISA-VIDA-PORTUGAL-CCAM" : "CARTA BIENVENIDA ASISA VIDA PORTUGAL CCAM",
    "CARTA-CONTRATACION-ASISA-SALUD-SEPIE": "CARTA CONTRATACION ASISA SALUD SEPIE"
};

interface asisaCode {
    "W0010000": string;
    "W0010000C": string;
    "W0010001": string;
    "W0010002": string;
    "W0010003": string;
    "W0010003C": string;
    "W0010004": string;
    "W0010009": string;
    "W0010010": string;
    "W0010011": string;
    "W0010011C": string;
    "W0010012": string;
    "W0010013": string;
    "W0010014": string;
    "W0010015": string;
    "W0010016": string;
    "W0010019": string;
    "W0010020": string;
    "W0010021": string;
    "W0010022": string;
    "W0010023": string;
    "W0010024": string;
    "W0010025": string;
    "W0010026": string;
    "W0010027": string;
    "W0010028": string;
    "W0010029": string;
    "W0010030": string;
    "W0010031": string;
    "W0010032": string;
    "W0010033": string;
    "W0010035": string;
    "W0010036": string;
    "W0010037": string;
    "W0010038": string;
    "W0010039": string;
    "W0010040": string;
    "W0010041": string;
    "W0010042": string;
    "W0010043": string;
    "W0010044": string;
    "W0010045": string;
    "W0010046": string;
    "W0010047": string;
    "W0010048": string;
    "W0010049": string;
    "W0010050": string;
    "W0010051": string;
    "W0010051I": string;
    "W0010052": string;
    "W0010053": string;
    "W0010054": string;
    "W0010055": string;
    "W0010060": string;
    "W0010061": string;
    "W0010062": string;
    "W0010063": string;
    "W0010064": string;
    "W0010066": string;
    "W0010067": string;
    "W0010068": string;
    "W0010069": string;
    "W0010070": string;
    "W0010071": string;
    "W0010073": string;
    "W0010074": string;
    "W0010075": string;
    "W0010076": string;
    "W0010077": string;
    "W0010078": string;
    "W0010079": string;
    "W0010080": string;
    "W0010081": string;
    "W0010082": string;
    "W0010083": string;
    "W0010085": string;
    "W0010086": string;
    "W0010087": string;
    "W0010088": string;
    "W0010089": string;
    "W0010090": string;
    "W0010091": string;
    "W0010092": string;
    "W0010093": string;
    "W0010094": string;
    "W0010095": string;
    "W0010096": string;
    "W0010097": string;
    "W0010098": string;
    "W0010099": string;
    "W0010100": string;
    "W0010101": string;
    "W0010102": string;
    "W0010103": string;
    "W0010105": string;
    "W0010106": string;
    "W0010107": string;
    "W0010108": string;
    "W0010109": string;
    "W0010110": string;
    "W0010111": string;
    "W0010112": string;
    "W0010113": string;
    "W0010114": string;
    "W0010115": string;
    "W0010116": string;
    "W0010117": string;
    "W0010118": string;
    "W0010119": string;
    "W0010120": string;
    "W0010121": string;
    "W0010122": string;
    "W0010123": string;
    "W0010124": string;
    "W0010125": string;
    "W0010126": string;
    "W0010127": string;
    "W0010128": string;
    "W0010129": string;
    "W0010130": string;
    "W0010131": string;
    "W0010132": string;
    "W0010133": string;
    "W0010134": string;
    "W0010135": string;
    "W0010136": string;
    "W0010137": string;
    "W0010138": string;
    "W0010139": string;
    "W0010140": string;
    "W0010141": string;
    "W0010142": string;
    "W0010143": string;
    "W0010144": string;
    "W0010145": string;
    "W0010146": string;
    "W0010147": string;
    "W0010148": string;
    "W0010149": string;
    "W0010150": string;
    "W0010151": string;
    "W0010152": string;
    "W0010152I": string;
    "W0010153": string;
    "W0010153I": string;
    "W0010154": string;
    "W0010154I": string;
    "W0010155": string;
    "W0010155I": string;
    "W0010156": string;
    "W0010157": string;
    "W0010158": string;
    "W0010159": string;
    "W0010160": string;
    "W0010161": string;
    "W0010162": string;
    "W0010163": string;
    "W0010164": string;
    "W0010167": string;
    "W0010168": string;
    "W0010169": string;
    "W0010170": string;
    "W0010171": string;
    "W0010172": string;
    "W0010173": string;
    "W0010174": string;
    "W0010175": string;
    "W0010175I": string;
    "W0010176": string;
    "W0010177": string;
    "W0010178": string;
    "W0010179": string;
    "W0010180": string;
    "W0010181": string;
    "W0010182": string;
    "W0010183": string;
    "W0010184": string;
    "W0010185": string;
    "W0010186": string;
    "W0010187": string;
    "W0010188": string;
    "W0010189": string;
    "W0010190": string;
    "W0010190A": string;
    "W0010190C": string;
    "W0010191": string;
    "W0010192": string;
    "W0010193": string;
    "W0010194": string;
    "W0010195": string;
    "W0010196": string;
    "W0010197": string;
    "W0010198": string;
    "W0010199": string;
    "W0010200": string;
    "W0010200I": string;
    "W0010201": string;
    "W0010201I": string;
    "W0010213": string;
    "W0010214": string;
    "W0010215": string;
    "W0010216": string;
    "W0010217": string;
    "W0010218": string;
    "W0010219": string;
    "W0010220": string;
    "W0010221": string;
    "W0010222": string;
    "W0010223": string;
    "W0010224": string;
    "W0010225": string;
    "W0010226": string;
    "W0010227": string;
    "W0010228": string;
    "W0010229": string;
    "W0010230": string;
    "W0010232": string;
    "W0010233": string;
    "W0010234": string;
    "W0010235": string;
    "W0010236": string;
    "W0010239": string;
    "W0010240": string;
    "W0010241": string;
    "W0010242": string;
    "W0010243": string;
    "W0010244": string;
    "W0010245": string;
    "W0010246": string;
    "W0010247": string;
    "W0010248": string;
    "W0010249": string;
    "W0010250": string;
    "W0010251": string;
    "W0010252": string;
    "W0010253": string;
    "W0010254": string;
    "W0010255": string;
    "W0010256": string;
    "W0010257": string;
    "W0010258": string;
    "W0010259": string;
    "W0010260": string;
    "W0010261": string;
    "W0010262": string;
    "W0010263": string;
    "W0010264": string;
    "W0010265": string;
    "W0010266": string;
    "W0010267": string;
    "W0010268": string;
    "W0010269": string;
    "W0010270": string;
    "W0010271": string;
    "W0010272": string;
    "W0010273": string;
    "W0010274": string;
    "W0010275": string;
    "W0010276": string;
    "W0010277": string;
    "W0010278": string;
    "W0010279": string;
    "W0010280": string;
    "W0010281": string;
    "W0010282": string;
    "W0010283": string;
    "W0010284": string;
    "W0010285": string;
    "W0010286": string;
    "W0010287": string;
    "W0010288": string;
    "W0010289": string;
    "W0010290": string;
    "W0010291": string;
    "W0010292": string;
    "W0010293": string;
    "W0010294": string;
    "W0010295": string;
    "W0010296": string;
    "W0010297": string;
    "W0010298": string;
    "W0010299": string;
    "W0010300": string;
    "W0010301": string;
    "W0010302": string;
    "W0010303": string;
    "W0010304": string;
    "W0010305": string;
    "W0010306": string;
    "W0010307": string;
    "W0010308": string;
    "W0010309": string;
    "W0010310": string;
    "W0010311": string;
    "W0010312": string;
    "W0010313": string;
    "W0010314": string;
    "W0010315": string;
    "W0010316": string;
    "W0010317": string;
    "W0010318": string;
    "W0010319": string;
    "W0010320": string;
    "W0010321": string;
    "W0010322": string;
    "W0010323": string;
    "W0010324": string;
    "W0010325": string;
    "W0010326": string;
    "W0010327": string;
    "W0010328": string;
    "W0010329": string;
    "W0010330": string;
    "W0010331": string;
    "W0010332": string;
    "W0010333": string;
    "W0010334": string;
    "W0010335": string;
    "W0010336": string;
    "W0010337": string;
    "W0010338": string;
    "W0010339": string;
    "W0010340": string;
    "W0010341": string;
    "W0010342": string;
    "W0010343": string;
    "W0010344": string;
    "W0010345": string;
    "W0010346": string;
    "W0010347": string;
    "W0010348": string;
    "W0010349": string;
    "W0010350": string;
    "W0010351": string;
    "W0010352": string;
    "W0010353": string;
    "W0010354": string;
    "W0010355": string;
    "W0010356": string;
    "W0010357": string;
    "W0010358": string;
    "W0010359": string;
    "W0010360": string;
    "W0010361": string;
    "W0010362": string;
    "W0010363": string;
    "W0010365": string;
    "W0010366": string;
    "W0010367": string;
    "W0010368": string;
    "W0010369": string;
    "W0010370": string;
    "W0010371": string;
    "W0010372": string;
    "W0010373": string;
    "W0010373I": string;
    "W0010374": string;
    "W0010374I": string;
    "W0010375": string;
    "W0010376": string;
    "W0010377": string;
    "W0010378": string;
    "W0010379": string;
    "W0010380": string;
    "W0010381": string;
    "W0010382": string;
    "W0010383": string;
    "W0010384": string;
    "W0010385": string;
    "W0010386": string;
    "W0010387": string;
    "W0010388": string;
    "W0010389": string;
    "W0010390": string;
    "W0010391": string;
    "W0010392": string;
    "W0010393": string;
    "W0010394": string;
    "W0010395": string;
    "W0010396": string;
    "W0010397": string;
    "W0010398": string;
    "W0010399": string;
    "W0010400": string;
    "W0010401": string;
    "W0010402": string;
    "W0010403": string;
    "W0010404": string;
    "W0010405": string;
    "W0010406": string;
    "W0010407": string;
    "W0010408": string;
    "W0010409": string;
    "W0010410": string;
    "W0010411": string;
    "W0010412": string;
    "W0010413": string;
    "W0010414": string;
    "W0010415": string;
    "W0010416": string;
    "W0010417": string;
    "W0010418": string;
    "W0010419": string;
    "W0010420": string;
    "W0010421": string;
    "W0010422": string;
    "W0010423": string;
    "W0010424": string;
    "W0010425": string;
    "W0010426": string;
    "W0010427": string;
    "W0010428": string;
    "W0010429": string;
    "W0010430": string;
    "W0010431": string;
    "W0010432": string;
    "W0010433": string;
    "W0010434": string;
    "W0010435": string;
    "W0010436": string;
    "W0010437": string;
    "W0010438": string;
    "W0010439": string;
    "W0010440": string;
    "W0010441": string;
    "W0010442": string;
    "W0010443": string;
    "W0010444": string;
    "W0010445": string;
    "W0010446": string;
    "W0010447": string;
    "W0010448": string;
    "W0010449": string;
    "W0010450": string;
    "W0010451": string;
    "W0010452": string;
    "W0010453": string;
    "W0010454": string;
    "W0010455": string;
    "W0010456": string;
    "W0010457": string;
    "W0010458": string;
    "W0010459": string;
    "W0010460": string;
    "W0010461": string;
    "W0010462": string;
    "W0010463": string;
    "W0010464": string;
    "W0010465": string;
    "W0010466": string;
    "W0010467": string;
    "W0010468": string;
    "W0010469": string;
    "W0010470": string;
    "W0010471": string;
    "W0010472": string;
    "W0010473": string;
    "W0010474": string;
    "W0010475": string;
    "W0010476": string;
    "W0010477": string;
    "W0010478": string;
    "W0010479": string;
    "W0010480": string;
    "W0010481": string;
    "W0010482": string;
    "W0010483": string;
    "W0010484": string;
    "W0010485": string;
    "W0010486": string;
    "W0010487": string;
    "W0010488": string;
    "W0010489": string;
    "W0010490": string;
    "W0010492": string;
    "W0010493": string;
    "W0010494": string;
    "W0010495": string;
    "W0010496": string;
    "W0010497": string;
    "W0010498": string;
    "W0010499": string;
    "W0010500": string;
    "W0010501": string;
    "W0010502": string;
    "W0010503": string;
    "W0010504": string;
    "W0010505": string;
    "W0010506": string;
    "W0010507": string;
    "W0010508": string;
    "W0010509": string;
    "W0010510": string;
    "W0010511": string;
    "W0010512": string;
    "W0010513": string;
    "W0010514": string;
    "W0010515": string;
    "W0010516": string;
    "W0010517": string;
    "W0010518": string;
    "W0010519": string;
    "W0010520": string;
    "W0010521": string;
    "W0010522": string;
    "W0010523": string;
    "W0010523I": string;
    "W0010524": string;
    "W0010525": string;
    "W0010526": string;
    "W0010527": string;
    "W0010528": string;
    "W0010529": string;
    "W0010530": string;
    "W0010531": string;
    "W0010532": string;
    "W0010533": string;
    "W0010533I": string;
    "W0010534": string;
    "W0010536": string;
    "W0010537": string;
    "W0010538": string;
    "W0010539": string;
    "W0010540": string;
    "W0010541": string;
    "W0010542": string;
    "W0010543": string;
    "W0010544": string;
    "W0010545": string;
    "W0010546": string;
    "W0010547": string;
    "W0010548": string;
    "W0010549": string;
    "W0010550": string;
    "W0010551": string;
    "W0020000": string;
    "W0020001": string;
    "W0020001C": string;
    "W0020001I": string;
    "W0020002": string;
    "W0020003": string;
    "W0020003C": string;
    "W0020004": string;
    "W0020005": string;
    "W0020006": string;
    "W0020007": string;
    "W0020008": string;
    "W0020009": string;
    "W0020010": string;
    "W0020012": string;
    "W0020013": string;
    "W0020014": string;
    "W0020014C": string;
    "W0020015": string;
    "W0020016": string;
    "W0020017": string;
    "W0020018": string;
    "W0020019": string;
    "W0020020": string;
    "W0020021": string;
    "W0020022": string;
    "W0020023": string;
    "W0020024": string;
    "W0020025": string;
    "W0020026": string;
    "W0020028": string;
    "W0020029": string;
    "W0020030": string;
    "W0020031": string;
    "W0020032": string;
    "W0020033": string;
    "W0020034": string;
    "W0020035": string;
    "W0020036": string;
    "W0020037": string;
    "W0020038": string;
    "W0020039": string;
    "W0020040": string;
    "W0020041": string;
    "W0020042": string;
    "W0020043": string;
    "W0020044": string;
    "W0020045": string;
    "W0020046": string;
    "W0020047": string;
    "W0020048": string;
    "W0020049": string;
    "W0020050": string;
    "W0020051": string;
    "W0020052": string;
    "W0020053": string;
    "W0020054": string;
    "W0020055": string;
    "W0020056": string;
    "W0020057": string;
    "W0020058": string;
    "W0020059": string;
    "W0020060": string;
    "W0020061": string;
    "W0020062": string;
    "W0020063": string;
    "W0020064": string;
    "W0020065": string;
    "W0020066": string;
    "W0020067": string;
    "W0020068": string;
    "W0020069": string;
    "W0020070": string;
    "W0020071": string;
    "W0020072": string;
    "W0020073": string;
    "W0020074": string;
    "W0020075": string;
    "W0020076": string;
    "W0020077": string;
    "W0020078": string;
    "W0020079": string;
    "W0020080": string;
    "W0020081": string;
    "W0020082": string;
    "W0020083": string;
    "W0020084": string;
    "W0020085": string;
    "W0020086": string;
    "W0020087": string;
    "W0020088": string;
    "W0020089": string;
    "W0020090": string;
    "W0020091": string;
    "W0020092": string;
    "W0020093": string;
    "W0020094": string;
    "W0020095": string;
    "W0020096": string;
    "W0020097": string;
    "W0020098": string;
    "W0020099": string;
    "W0020100": string;
    "W0020101": string;
    "W0020102": string;
    "W0020103": string;
    "W0020104": string;
    "W0020105": string;
    "W0020106": string;
    "W0030000": string;
    "W0030001": string;
    "W0030002": string;
    "W0030003": string;
    "W0030004": string;
    "W0030006": string;
    "W0030007": string;
    "W0030008": string;
    "W0030009": string;
    "W0030010": string;
    "W0030011": string;
    "W0030012": string;
    "W0030013": string;
    "W0030014": string;
    "W0030015": string;
    "W0030016": string;
    "W0030017": string;
    "W0030018": string;
    "W0030019": string;
    "W0030020": string;
    "W0030021": string;
    "W0030022": string;
    "W0030023": string;
    "W0030024": string;
    "W0040000": string;
    "W0040000C": string;
    "W0040001": string;
    "W0040001C": string;
    "W0040002": string;
    "W0040002C": string;
    "W0040003": string;
    "W0040004": string;
    "W0040005": string;
    "W0040006": string;
    "W0040007": string;
    "W0040008": string;
    "W0040008A": string;
    "W0040009": string;
    "W0040010": string;
    "W0040011": string;
    "W0040012": string;
    "W0040013": string;
    "W0040014": string;
    "W0040015": string;
    "W0040016": string;
    "W0050000": string;
    "W0050000C": string;
    "W0050000I": string;
    "W0050001": string;
    "W0050002": string;
    "W0050003": string;
    "W0050004": string;
    "W0050005": string;
    "W0050006": string;
    "W0050007": string;
    "W0050008": string;
    "W0050009": string;
    "W0060000": string;
    "W0060001": string;
    "W0060001C": string;
    "W0060002": string;
    "W0060003": string;
    "W0060004": string;
    "W0060005": string;
    "W0060006": string;
    "W0060007": string;
    "W0060008": string;
    "W0060009": string;
    "W0060010": string;
    "W0060011": string;
    "W0060012": string;
    "W0060013": string;
    "W0060014": string;
    "W0060015": string;
    "W0060017": string;
    "W0060017I": string;
    "W0060018": string;
    "W0060019": string;
    "W0060020": string;
    "W0060021": string;
    "W0060022": string;
    "W0060023": string;
    "W0060024": string;
    "W0060025": string;
    "W0060026": string;
    "W0060027": string;
    "W0060028": string;
    "W0060029": string;
    "W0060030": string;
    "W0060031": string;
    "W0060032": string;
    "W0060033": string;
    "W0060034": string;
    "W0060035": string;
    "W0060036": string;
    "W0060037": string;
    "W0060038": string;
    "W0060039": string;
    "W0060040": string;
    "W0060041": string;
    "W0060042": string;
    "W0060043": string;
    "W0060044": string;
    "W0060045": string;
    "W0060046": string;
    "W0060047": string;
    "W0060048": string;
    "W0060049": string;
    "W0060050": string;
    "W0060051": string;
    "W0060052": string;
    "W0060053": string;
    "W0060054": string;
    "W0060055": string;
    "W0060056": string;
    "W0060057": string;
    "W0060058": string;
    "W0060059": string;
    "W0060060": string;
    "W0060061": string;
    "W0060062": string;
    "W0060063": string;
    "W0060064": string;
    "W0060065": string;
    "W0060066": string;
    "W0060067": string;
    "W0060068": string;
    "W0060069": string;
    "W0060070": string;
    "W0060071": string;
    "W0060072": string;
    "W0060073": string;
    "W0060074": string;
    "W0060075": string;
    "W0060076": string;
    "W0060077": string;
    "W0060078": string;
    "W0060079": string;
    "W0060080": string;
    "W0060081": string;
    "W0060082": string;
    "W0060083": string;
    "W0060084": string;
    "W0060085": string;
    "W0060086": string;
    "W0060087": string;
    "W0060088": string;
    "W0060090": string;
    "W0060091": string;
    "W0060092": string;
    "W0060093": string;
    "W0060094": string;
    "W0060095": string;
    "W0060096": string;
    "W0060097": string;
    "W0060099": string;
    "W0060100": string;
    "W0060101": string;
    "W0060102": string;
    "W0060103": string;
    "w0060104": string;
    "W0060105": string;
    "W0060106": string;
    "W0060107": string;
    "W0060108": string;
    "W0060109": string;
    "W0060110": string;
    "W0060111": string;
    "W0060112": string;
    "W0060113": string;
    "W0060114": string;
    "W0060115": string;
    "W0060116": string;
    "W0060117": string;
    "W0060118": string;
    "W0060119": string;
    "W0060120": string;
    "W0060121": string;
    "W0060122": string;
    "W0060123": string;
    "W0060124": string;
    "W0060125": string;
    "W0060126": string;
    "W0060127": string;
    "W0060128": string;
    "W0060129": string;
    "W0060130": string;
    "W0060131": string;
    "W0060132": string;
    "W0060133": string;
    "W0060134": string;
    "W0060135": string;
    "W0060136": string;
    "W0060137": string;
    "W0060138": string;
    "W0060139": string;
    "W0060140": string;
    "W0060141": string;
    "W0060142": string;
    "W0060143": string;
    "W0060144": string;
    "W0060145": string;
    "W0060146": string;
    "W0060147": string;
    "W0060148": string;
    "W0060149": string;
    "W0060150": string;
    "W0060151": string;
    "W0060152": string;
    "W0060153": string;
    "W0060154": string;
    "W0060155": string;
    "W0060156": string;
    "W0060157": string;
    "W0060158": string;
    "W0060159": string;
    "W0060160": string;
    "W0060161": string;
    "W0060162": string;
    "W0060163": string;
    "W0060164": string;
    "W0060165": string;
    "W0060166": string;
    "W0060167": string;
    "W0060168": string;
    "W0060169": string;
    "W0060170": string;
    "W0060171": string;
    "W0060172": string;
    "W0060173": string;
    "W0060174": string;
    "W0060175": string;
    "W0060177": string;
    "W0060178": string;
    "W0060179": string;
    "W0060180": string;
    "W0060181": string;
    "W0060182": string;
    "W0060183": string;
    "W0060184": string;
    "W0060185": string;
    "W0060186": string;
    "W0060187": string;
    "W0060188": string;
    "W0060189": string;
    "W0060189C": string;
    "W0060190": string;
    "W0060191": string;
    "W0060192": string;
    "W0060193": string;
    "W0060194": string;
    "W0060195": string;
    "W0060196": string;
    "W0060197": string;
    "W0060198": string;
    "W0060199": string;
    "W0060200": string;
    "W0060201": string;
    "W0060202": string;
    "W0060203": string;
    [key: string]: string;
}

export const ASISA_CODE: asisaCode = {
    "W0010000": "W0010000",
    "W0010000C": "W0010000C",
    "W0010001": "W0010001",
    "W0010002": "W0010002",
    "W0010003": "W0010003",
    "W0010003C": "W0010003C",
    "W0010004": "W0010004",
    "W0010009": "W0010009",
    "W0010010": "W0010010",
    "W0010011": "W0010011",
    "W0010011C": "W0010011C",
    "W0010012": "W0010012",
    "W0010013": "W0010013",
    "W0010014": "W0010014",
    "W0010015": "W0010015",
    "W0010016": "W0010016",
    "W0010019": "W0010019",
    "W0010020": "W0010020",
    "W0010021": "W0010021",
    "W0010022": "W0010022",
    "W0010023": "W0010023",
    "W0010024": "W0010024",
    "W0010025": "W0010025",
    "W0010026": "W0010026",
    "W0010027": "W0010027",
    "W0010028": "W0010028",
    "W0010029": "W0010029",
    "W0010030": "W0010030",
    "W0010031": "W0010031",
    "W0010032": "W0010032",
    "W0010033": "W0010033",
    "W0010035": "W0010035",
    "W0010036": "W0010036",
    "W0010037": "W0010037",
    "W0010038": "W0010038",
    "W0010039": "W0010039",
    "W0010040": "W0010040",
    "W0010041": "W0010041",
    "W0010042": "W0010042",
    "W0010043": "W0010043",
    "W0010044": "W0010044",
    "W0010045": "W0010045",
    "W0010046": "W0010046",
    "W0010047": "W0010047",
    "W0010048": "W0010048",
    "W0010049": "W0010049",
    "W0010050": "W0010050",
    "W0010051": "W0010051",
    "W0010051I": "W0010051I",
    "W0010052": "W0010052",
    "W0010053": "W0010053",
    "W0010054": "W0010054",
    "W0010055": "W0010055",
    "W0010060": "W0010060",
    "W0010061": "W0010061",
    "W0010062": "W0010062",
    "W0010063": "W0010063",
    "W0010064": "W0010064",
    "W0010066": "W0010066",
    "W0010067": "W0010067",
    "W0010068": "W0010068",
    "W0010069": "W0010069",
    "W0010070": "W0010070",
    "W0010071": "W0010071",
    "W0010073": "W0010073",
    "W0010074": "W0010074",
    "W0010075": "W0010075",
    "W0010076": "W0010076",
    "W0010077": "W0010077",
    "W0010078": "W0010078",
    "W0010079": "W0010079",
    "W0010080": "W0010080",
    "W0010081": "W0010081",
    "W0010082": "W0010082",
    "W0010083": "W0010083",
    "W0010085": "W0010085",
    "W0010086": "W0010086",
    "W0010087": "W0010087",
    "W0010088": "W0010088",
    "W0010089": "W0010089",
    "W0010090": "W0010090",
    "W0010091": "W0010091",
    "W0010092": "W0010092",
    "W0010093": "W0010093",
    "W0010094": "W0010094",
    "W0010095": "W0010095",
    "W0010096": "W0010096",
    "W0010097": "W0010097",
    "W0010098": "W0010098",
    "W0010099": "W0010099",
    "W0010100": "W0010100",
    "W0010101": "W0010101",
    "W0010102": "W0010102",
    "W0010103": "W0010103",
    "W0010105": "W0010105",
    "W0010106": "W0010106",
    "W0010107": "W0010107",
    "W0010108": "W0010108",
    "W0010109": "W0010109",
    "W0010110": "W0010110",
    "W0010111": "W0010111",
    "W0010112": "W0010112",
    "W0010113": "W0010113",
    "W0010114": "W0010114",
    "W0010115": "W0010115",
    "W0010116": "W0010116",
    "W0010117": "W0010117",
    "W0010118": "W0010118",
    "W0010119": "W0010119",
    "W0010120": "W0010120",
    "W0010121": "W0010121",
    "W0010122": "W0010122",
    "W0010123": "W0010123",
    "W0010124": "W0010124",
    "W0010125": "W0010125",
    "W0010126": "W0010126",
    "W0010127": "W0010127",
    "W0010128": "W0010128",
    "W0010129": "W0010129",
    "W0010130": "W0010130",
    "W0010131": "W0010131",
    "W0010132": "W0010132",
    "W0010133": "W0010133",
    "W0010134": "W0010134",
    "W0010135": "W0010135",
    "W0010136": "W0010136",
    "W0010137": "W0010137",
    "W0010138": "W0010138",
    "W0010139": "W0010139",
    "W0010140": "W0010140",
    "W0010141": "W0010141",
    "W0010142": "W0010142",
    "W0010143": "W0010143",
    "W0010144": "W0010144",
    "W0010145": "W0010145",
    "W0010146": "W0010146",
    "W0010147": "W0010147",
    "W0010148": "W0010148",
    "W0010149": "W0010149",
    "W0010150": "W0010150",
    "W0010151": "W0010151",
    "W0010152": "W0010152",
    "W0010152I": "W0010152I",
    "W0010153": "W0010153",
    "W0010153I": "W0010153I",
    "W0010154": "W0010154",
    "W0010154I": "W0010154I",
    "W0010155": "W0010155",
    "W0010155I": "W0010155I",
    "W0010156": "W0010156",
    "W0010157": "W0010157",
    "W0010158": "W0010158",
    "W0010159": "W0010159",
    "W0010160": "W0010160",
    "W0010161": "W0010161",
    "W0010162": "W0010162",
    "W0010163": "W0010163",
    "W0010164": "W0010164",
    "W0010167": "W0010167",
    "W0010168": "W0010168",
    "W0010169": "W0010169",
    "W0010170": "W0010170",
    "W0010171": "W0010171",
    "W0010172": "W0010172",
    "W0010173": "W0010173",
    "W0010174": "W0010174",
    "W0010175": "W0010175",
    "W0010175I": "W0010175I",
    "W0010176": "W0010176",
    "W0010177": "W0010177",
    "W0010178": "W0010178",
    "W0010179": "W0010179",
    "W0010180": "W0010180",
    "W0010181": "W0010181",
    "W0010182": "W0010182",
    "W0010183": "W0010183",
    "W0010184": "W0010184",
    "W0010185": "W0010185",
    "W0010186": "W0010186",
    "W0010187": "W0010187",
    "W0010188": "W0010188",
    "W0010189": "W0010189",
    "W0010190": "W0010190",
    "W0010190A": "W0010190A",
    "W0010190C": "W0010190C",
    "W0010191": "W0010191",
    "W0010192": "W0010192",
    "W0010193": "W0010193",
    "W0010194": "W0010194",
    "W0010195": "W0010195",
    "W0010196": "W0010196",
    "W0010197": "W0010197",
    "W0010198": "W0010198",
    "W0010199": "W0010199",
    "W0010200": "W0010200",
    "W0010200I": "W0010200I",
    "W0010201": "W0010201",
    "W0010201I": "W0010201I",
    "W0010213": "W0010213",
    "W0010214": "W0010214",
    "W0010215": "W0010215",
    "W0010216": "W0010216",
    "W0010217": "W0010217",
    "W0010218": "W0010218",
    "W0010219": "W0010219",
    "W0010220": "W0010220",
    "W0010221": "W0010221",
    "W0010222": "W0010222",
    "W0010223": "W0010223",
    "W0010224": "W0010224",
    "W0010225": "W0010225",
    "W0010226": "W0010226",
    "W0010227": "W0010227",
    "W0010228": "W0010228",
    "W0010229": "W0010229",
    "W0010230": "W0010230",
    "W0010232": "W0010232",
    "W0010233": "W0010233",
    "W0010234": "W0010234",
    "W0010235": "W0010235",
    "W0010236": "W0010236",
    "W0010239": "W0010239",
    "W0010240": "W0010240",
    "W0010241": "W0010241",
    "W0010242": "W0010242",
    "W0010243": "W0010243",
    "W0010244": "W0010244",
    "W0010245": "W0010245",
    "W0010246": "W0010246",
    "W0010247": "W0010247",
    "W0010248": "W0010248",
    "W0010249": "W0010249",
    "W0010250": "W0010250",
    "W0010251": "W0010251",
    "W0010252": "W0010252",
    "W0010253": "W0010253",
    "W0010254": "W0010254",
    "W0010255": "W0010255",
    "W0010256": "W0010256",
    "W0010257": "W0010257",
    "W0010258": "W0010258",
    "W0010259": "W0010259",
    "W0010260": "W0010260",
    "W0010261": "W0010261",
    "W0010262": "W0010262",
    "W0010263": "W0010263",
    "W0010264": "W0010264",
    "W0010265": "W0010265",
    "W0010266": "W0010266",
    "W0010267": "W0010267",
    "W0010268": "W0010268",
    "W0010269": "W0010269",
    "W0010270": "W0010270",
    "W0010271": "W0010271",
    "W0010272": "W0010272",
    "W0010273": "W0010273",
    "W0010274": "W0010274",
    "W0010275": "W0010275",
    "W0010276": "W0010276",
    "W0010277": "W0010277",
    "W0010278": "W0010278",
    "W0010279": "W0010279",
    "W0010280": "W0010280",
    "W0010281": "W0010281",
    "W0010282": "W0010282",
    "W0010283": "W0010283",
    "W0010284": "W0010284",
    "W0010285": "W0010285",
    "W0010286": "W0010286",
    "W0010287": "W0010287",
    "W0010288": "W0010288",
    "W0010289": "W0010289",
    "W0010290": "W0010290",
    "W0010291": "W0010291",
    "W0010292": "W0010292",
    "W0010293": "W0010293",
    "W0010294": "W0010294",
    "W0010295": "W0010295",
    "W0010296": "W0010296",
    "W0010297": "W0010297",
    "W0010298": "W0010298",
    "W0010299": "W0010299",
    "W0010300": "W0010300",
    "W0010301": "W0010301",
    "W0010302": "W0010302",
    "W0010303": "W0010303",
    "W0010304": "W0010304",
    "W0010305": "W0010305",
    "W0010306": "W0010306",
    "W0010307": "W0010307",
    "W0010308": "W0010308",
    "W0010309": "W0010309",
    "W0010310": "W0010310",
    "W0010311": "W0010311",
    "W0010312": "W0010312",
    "W0010313": "W0010313",
    "W0010314": "W0010314",
    "W0010315": "W0010315",
    "W0010316": "W0010316",
    "W0010317": "W0010317",
    "W0010318": "W0010318",
    "W0010319": "W0010319",
    "W0010320": "W0010320",
    "W0010321": "W0010321",
    "W0010322": "W0010322",
    "W0010323": "W0010323",
    "W0010324": "W0010324",
    "W0010325": "W0010325",
    "W0010326": "W0010326",
    "W0010327": "W0010327",
    "W0010328": "W0010328",
    "W0010329": "W0010329",
    "W0010330": "W0010330",
    "W0010331": "W0010331",
    "W0010332": "W0010332",
    "W0010333": "W0010333",
    "W0010334": "W0010334",
    "W0010335": "W0010335",
    "W0010336": "W0010336",
    "W0010337": "W0010337",
    "W0010338": "W0010338",
    "W0010339": "W0010339",
    "W0010340": "W0010340",
    "W0010341": "W0010341",
    "W0010342": "W0010342",
    "W0010343": "W0010343",
    "W0010344": "W0010344",
    "W0010345": "W0010345",
    "W0010346": "W0010346",
    "W0010347": "W0010347",
    "W0010348": "W0010348",
    "W0010349": "W0010349",
    "W0010350": "W0010350",
    "W0010351": "W0010351",
    "W0010352": "W0010352",
    "W0010353": "W0010353",
    "W0010354": "W0010354",
    "W0010355": "W0010355",
    "W0010356": "W0010356",
    "W0010357": "W0010357",
    "W0010358": "W0010358",
    "W0010359": "W0010359",
    "W0010360": "W0010360",
    "W0010361": "W0010361",
    "W0010362": "W0010362",
    "W0010363": "W0010363",
    "W0010365": "W0010365",
    "W0010366": "W0010366",
    "W0010367": "W0010367",
    "W0010368": "W0010368",
    "W0010369": "W0010369",
    "W0010370": "W0010370",
    "W0010371": "W0010371",
    "W0010372": "W0010372",
    "W0010373": "W0010373",
    "W0010373I": "W0010373I",
    "W0010374": "W0010374",
    "W0010374I": "W0010374I",
    "W0010375": "W0010375",
    "W0010376": "W0010376",
    "W0010377": "W0010377",
    "W0010378": "W0010378",
    "W0010379": "W0010379",
    "W0010380": "W0010380",
    "W0010381": "W0010381",
    "W0010382": "W0010382",
    "W0010383": "W0010383",
    "W0010384": "W0010384",
    "W0010385": "W0010385",
    "W0010386": "W0010386",
    "W0010387": "W0010387",
    "W0010388": "W0010388",
    "W0010389": "W0010389",
    "W0010390": "W0010390",
    "W0010391": "W0010391",
    "W0010392": "W0010392",
    "W0010393": "W0010393",
    "W0010394": "W0010394",
    "W0010395": "W0010395",
    "W0010396": "W0010396",
    "W0010397": "W0010397",
    "W0010398": "W0010398",
    "W0010399": "W0010399",
    "W0010400": "W0010400",
    "W0010401": "W0010401",
    "W0010402": "W0010402",
    "W0010403": "W0010403",
    "W0010404": "W0010404",
    "W0010405": "W0010405",
    "W0010406": "W0010406",
    "W0010407": "W0010407",
    "W0010408": "W0010408",
    "W0010409": "W0010409",
    "W0010410": "W0010410",
    "W0010411": "W0010411",
    "W0010412": "W0010412",
    "W0010413": "W0010413",
    "W0010414": "W0010414",
    "W0010415": "W0010415",
    "W0010416": "W0010416",
    "W0010417": "W0010417",
    "W0010418": "W0010418",
    "W0010419": "W0010419",
    "W0010420": "W0010420",
    "W0010421": "W0010421",
    "W0010422": "W0010422",
    "W0010423": "W0010423",
    "W0010424": "W0010424",
    "W0010425": "W0010425",
    "W0010426": "W0010426",
    "W0010427": "W0010427",
    "W0010428": "W0010428",
    "W0010429": "W0010429",
    "W0010430": "W0010430",
    "W0010431": "W0010431",
    "W0010432": "W0010432",
    "W0010433": "W0010433",
    "W0010434": "W0010434",
    "W0010435": "W0010435",
    "W0010436": "W0010436",
    "W0010437": "W0010437",
    "W0010438": "W0010438",
    "W0010439": "W0010439",
    "W0010440": "W0010440",
    "W0010441": "W0010441",
    "W0010442": "W0010442",
    "W0010443": "W0010443",
    "W0010444": "W0010444",
    "W0010445": "W0010445",
    "W0010446": "W0010446",
    "W0010447": "W0010447",
    "W0010448": "W0010448",
    "W0010449": "W0010449",
    "W0010450": "W0010450",
    "W0010451": "W0010451",
    "W0010452": "W0010452",
    "W0010453": "W0010453",
    "W0010454": "W0010454",
    "W0010455": "W0010455",
    "W0010456": "W0010456",
    "W0010457": "W0010457",
    "W0010458": "W0010458",
    "W0010459": "W0010459",
    "W0010460": "W0010460",
    "W0010461": "W0010461",
    "W0010462": "W0010462",
    "W0010463": "W0010463",
    "W0010464": "W0010464",
    "W0010465": "W0010465",
    "W0010466": "W0010466",
    "W0010467": "W0010467",
    "W0010468": "W0010468",
    "W0010469": "W0010469",
    "W0010470": "W0010470",
    "W0010471": "W0010471",
    "W0010472": "W0010472",
    "W0010473": "W0010473",
    "W0010474": "W0010474",
    "W0010475": "W0010475",
    "W0010476": "W0010476",
    "W0010477": "W0010477",
    "W0010478": "W0010478",
    "W0010479": "W0010479",
    "W0010480": "W0010480",
    "W0010481": "W0010481",
    "W0010482": "W0010482",
    "W0010483": "W0010483",
    "W0010484": "W0010484",
    "W0010485": "W0010485",
    "W0010486": "W0010486",
    "W0010487": "W0010487",
    "W0010488": "W0010488",
    "W0010489": "W0010489",
    "W0010490": "W0010490",
    "W0010492": "W0010492",
    "W0010493": "W0010493",
    "W0010494": "W0010494",
    "W0010495": "W0010495",
    "W0010496": "W0010496",
    "W0010497": "W0010497",
    "W0010498": "W0010498",
    "W0010499": "W0010499",
    "W0010500": "W0010500",
    "W0010501": "W0010501",
    "W0010502": "W0010502",
    "W0010503": "W0010503",
    "W0010504": "W0010504",
    "W0010505": "W0010505",
    "W0010506": "W0010506",
    "W0010507": "W0010507",
    "W0010508": "W0010508",
    "W0010509": "W0010509",
    "W0010510": "W0010510",
    "W0010511": "W0010511",
    "W0010512": "W0010512",
    "W0010513": "W0010513",
    "W0010514": "W0010514",
    "W0010515": "W0010515",
    "W0010516": "W0010516",
    "W0010517": "W0010517",
    "W0010518": "W0010518",
    "W0010519": "W0010519",
    "W0010520": "W0010520",
    "W0010521": "W0010521",
    "W0010522": "W0010522",
    "W0010523": "W0010523",
    "W0010523I": "W0010523I",
    "W0010524": "W0010524",
    "W0010525": "W0010525",
    "W0010526": "W0010526",
    "W0010527": "W0010527",
    "W0010528": "W0010528",
    "W0010529": "W0010529",
    "W0010530": "W0010530",
    "W0010531": "W0010531",
    "W0010532": "W0010532",
    "W0010533": "W0010533",
    "W0010533I": "W0010533I",
    "W0010534": "W0010534",
    "W0010536": "W0010536",
    "W0010537": "W0010537",
    "W0010538": "W0010538",
    "W0010539": "W0010539",
    "W0010540": "W0010540",
    "W0010541": "W0010541",
    "W0010542": "W0010542",
    "W0010543": "W0010543",
    "W0010544": "W0010544",
    "W0010545": "W0010545",
    "W0010546": "W0010546",
    "W0010547": "W0010547",
    "W0010548": "W0010548",
    "W0010549": "W0010549",
    "W0010550": "W0010550",
    "W0010551": "W0010551",
    "W0020000": "W0020000",
    "W0020001": "W0020001",
    "W0020001C": "W0020001C",
    "W0020001I": "W0020001I",
    "W0020002": "W0020002",
    "W0020003": "W0020003",
    "W0020003C": "W0020003C",
    "W0020004": "W0020004",
    "W0020005": "W0020005",
    "W0020006": "W0020006",
    "W0020007": "W0020007",
    "W0020008": "W0020008",
    "W0020009": "W0020009",
    "W0020010": "W0020010",
    "W0020012": "W0020012",
    "W0020013": "W0020013",
    "W0020014": "W0020014",
    "W0020014C": "W0020014C",
    "W0020015": "W0020015",
    "W0020016": "W0020016",
    "W0020017": "W0020017",
    "W0020018": "W0020018",
    "W0020019": "W0020019",
    "W0020020": "W0020020",
    "W0020021": "W0020021",
    "W0020022": "W0020022",
    "W0020023": "W0020023",
    "W0020024": "W0020024",
    "W0020025": "W0020025",
    "W0020026": "W0020026",
    "W0020028": "W0020028",
    "W0020029": "W0020029",
    "W0020030": "W0020030",
    "W0020031": "W0020031",
    "W0020032": "W0020032",
    "W0020033": "W0020033",
    "W0020034": "W0020034",
    "W0020035": "W0020035",
    "W0020036": "W0020036",
    "W0020037": "W0020037",
    "W0020038": "W0020038",
    "W0020039": "W0020039",
    "W0020040": "W0020040",
    "W0020041": "W0020041",
    "W0020042": "W0020042",
    "W0020043": "W0020043",
    "W0020044": "W0020044",
    "W0020045": "W0020045",
    "W0020046": "W0020046",
    "W0020047": "W0020047",
    "W0020048": "W0020048",
    "W0020049": "W0020049",
    "W0020050": "W0020050",
    "W0020051": "W0020051",
    "W0020052": "W0020052",
    "W0020053": "W0020053",
    "W0020054": "W0020054",
    "W0020055": "W0020055",
    "W0020056": "W0020056",
    "W0020057": "W0020057",
    "W0020058": "W0020058",
    "W0020059": "W0020059",
    "W0020060": "W0020060",
    "W0020061": "W0020061",
    "W0020062": "W0020062",
    "W0020063": "W0020063",
    "W0020064": "W0020064",
    "W0020065": "W0020065",
    "W0020066": "W0020066",
    "W0020067": "W0020067",
    "W0020068": "W0020068",
    "W0020069": "W0020069",
    "W0020070": "W0020070",
    "W0020071": "W0020071",
    "W0020072": "W0020072",
    "W0020073": "W0020073",
    "W0020074": "W0020074",
    "W0020075": "W0020075",
    "W0020076": "W0020076",
    "W0020077": "W0020077",
    "W0020078": "W0020078",
    "W0020079": "W0020079",
    "W0020080": "W0020080",
    "W0020081": "W0020081",
    "W0020082": "W0020082",
    "W0020083": "W0020083",
    "W0020084": "W0020084",
    "W0020085": "W0020085",
    "W0020086": "W0020086",
    "W0020087": "W0020087",
    "W0020088": "W0020088",
    "W0020089": "W0020089",
    "W0020090": "W0020090",
    "W0020091": "W0020091",
    "W0020092": "W0020092",
    "W0020093": "W0020093",
    "W0020094": "W0020094",
    "W0020095": "W0020095",
    "W0020096": "W0020096",
    "W0020097": "W0020097",
    "W0020098": "W0020098",
    "W0020099": "W0020099",
    "W0020100": "W0020100",
    "W0020101": "W0020101",
    "W0020102": "W0020102",
    "W0020103": "W0020103",
    "W0020104": "W0020104",
    "W0020105": "W0020105",
    "W0020106": "W0020106",
    "W0030000": "W0030000",
    "W0030001": "W0030001",
    "W0030002": "W0030002",
    "W0030003": "W0030003",
    "W0030004": "W0030004",
    "W0030006": "W0030006",
    "W0030007": "W0030007",
    "W0030008": "W0030008",
    "W0030009": "W0030009",
    "W0030010": "W0030010",
    "W0030011": "W0030011",
    "W0030012": "W0030012",
    "W0030013": "W0030013",
    "W0030014": "W0030014",
    "W0030015": "W0030015",
    "W0030016": "W0030016",
    "W0030017": "W0030017",
    "W0030018": "W0030018",
    "W0030019": "W0030019",
    "W0030020": "W0030020",
    "W0030021": "W0030021",
    "W0030022": "W0030022",
    "W0030023": "W0030023",
    "W0030024": "W0030024",
    "W0040000": "W0040000",
    "W0040000C": "W0040000C",
    "W0040001": "W0040001",
    "W0040001C": "W0040001C",
    "W0040002": "W0040002",
    "W0040002C": "W0040002C",
    "W0040003": "W0040003",
    "W0040004": "W0040004",
    "W0040005": "W0040005",
    "W0040006": "W0040006",
    "W0040007": "W0040007",
    "W0040008": "W0040008",
    "W0040008A": "W0040008A",
    "W0040009": "W0040009",
    "W0040010": "W0040010",
    "W0040011": "W0040011",
    "W0040012": "W0040012",
    "W0040013": "W0040013",
    "W0040014": "W0040014",
    "W0040015": "W0040015",
    "W0040016": "W0040016",
    "W0050000": "W0050000",
    "W0050000C": "W0050000C",
    "W0050000I": "W0050000I",
    "W0050001": "W0050001",
    "W0050002": "W0050002",
    "W0050003": "W0050003",
    "W0050004": "W0050004",
    "W0050005": "W0050005",
    "W0050006": "W0050006",
    "W0050007": "W0050007",
    "W0050008": "W0050008",
    "W0050009": "W0050009",
    "W0060000": "W0060000",
    "W0060001": "W0060001",
    "W0060001C": "W0060001C",
    "W0060002": "W0060002",
    "W0060003": "W0060003",
    "W0060004": "W0060004",
    "W0060005": "W0060005",
    "W0060006": "W0060006",
    "W0060007": "W0060007",
    "W0060008": "W0060008",
    "W0060009": "W0060009",
    "W0060010": "W0060010",
    "W0060011": "W0060011",
    "W0060012": "W0060012",
    "W0060013": "W0060013",
    "W0060014": "W0060014",
    "W0060015": "W0060015",
    "W0060017": "W0060017",
    "W0060017I": "W0060017I",
    "W0060018": "W0060018",
    "W0060019": "W0060019",
    "W0060020": "W0060020",
    "W0060021": "W0060021",
    "W0060022": "W0060022",
    "W0060023": "W0060023",
    "W0060024": "W0060024",
    "W0060025": "W0060025",
    "W0060026": "W0060026",
    "W0060027": "W0060027",
    "W0060028": "W0060028",
    "W0060029": "W0060029",
    "W0060030": "W0060030",
    "W0060031": "W0060031",
    "W0060032": "W0060032",
    "W0060033": "W0060033",
    "W0060034": "W0060034",
    "W0060035": "W0060035",
    "W0060036": "W0060036",
    "W0060037": "W0060037",
    "W0060038": "W0060038",
    "W0060039": "W0060039",
    "W0060040": "W0060040",
    "W0060041": "W0060041",
    "W0060042": "W0060042",
    "W0060043": "W0060043",
    "W0060044": "W0060044",
    "W0060045": "W0060045",
    "W0060046": "W0060046",
    "W0060047": "W0060047",
    "W0060048": "W0060048",
    "W0060049": "W0060049",
    "W0060050": "W0060050",
    "W0060051": "W0060051",
    "W0060052": "W0060052",
    "W0060053": "W0060053",
    "W0060054": "W0060054",
    "W0060055": "W0060055",
    "W0060056": "W0060056",
    "W0060057": "W0060057",
    "W0060058": "W0060058",
    "W0060059": "W0060059",
    "W0060060": "W0060060",
    "W0060061": "W0060061",
    "W0060062": "W0060062",
    "W0060063": "W0060063",
    "W0060064": "W0060064",
    "W0060065": "W0060065",
    "W0060066": "W0060066",
    "W0060067": "W0060067",
    "W0060068": "W0060068",
    "W0060069": "W0060069",
    "W0060070": "W0060070",
    "W0060071": "W0060071",
    "W0060072": "W0060072",
    "W0060073": "W0060073",
    "W0060074": "W0060074",
    "W0060075": "W0060075",
    "W0060076": "W0060076",
    "W0060077": "W0060077",
    "W0060078": "W0060078",
    "W0060079": "W0060079",
    "W0060080": "W0060080",
    "W0060081": "W0060081",
    "W0060082": "W0060082",
    "W0060083": "W0060083",
    "W0060084": "W0060084",
    "W0060085": "W0060085",
    "W0060086": "W0060086",
    "W0060087": "W0060087",
    "W0060088": "W0060088",
    "W0060090": "W0060090",
    "W0060091": "W0060091",
    "W0060092": "W0060092",
    "W0060093": "W0060093",
    "W0060094": "W0060094",
    "W0060095": "W0060095",
    "W0060096": "W0060096",
    "W0060097": "W0060097",
    "W0060099": "W0060099",
    "W0060100": "W0060100",
    "W0060101": "W0060101",
    "W0060102": "W0060102",
    "W0060103": "W0060103",
    "w0060104": "w0060104",
    "W0060105": "W0060105",
    "W0060106": "W0060106",
    "W0060107": "W0060107",
    "W0060108": "W0060108",
    "W0060109": "W0060109",
    "W0060110": "W0060110",
    "W0060111": "W0060111",
    "W0060112": "W0060112",
    "W0060113": "W0060113",
    "W0060114": "W0060114",
    "W0060115": "W0060115",
    "W0060116": "W0060116",
    "W0060117": "W0060117",
    "W0060118": "W0060118",
    "W0060119": "W0060119",
    "W0060120": "W0060120",
    "W0060121": "W0060121",
    "W0060122": "W0060122",
    "W0060123": "W0060123",
    "W0060124": "W0060124",
    "W0060125": "W0060125",
    "W0060126": "W0060126",
    "W0060127": "W0060127",
    "W0060128": "W0060128",
    "W0060129": "W0060129",
    "W0060130": "W0060130",
    "W0060131": "W0060131",
    "W0060132": "W0060132",
    "W0060133": "W0060133",
    "W0060134": "W0060134",
    "W0060135": "W0060135",
    "W0060136": "W0060136",
    "W0060137": "W0060137",
    "W0060138": "W0060138",
    "W0060139": "W0060139",
    "W0060140": "W0060140",
    "W0060141": "W0060141",
    "W0060142": "W0060142",
    "W0060143": "W0060143",
    "W0060144": "W0060144",
    "W0060145": "W0060145",
    "W0060146": "W0060146",
    "W0060147": "W0060147",
    "W0060148": "W0060148",
    "W0060149": "W0060149",
    "W0060150": "W0060150",
    "W0060151": "W0060151",
    "W0060152": "W0060152",
    "W0060153": "W0060153",
    "W0060154": "W0060154",
    "W0060155": "W0060155",
    "W0060156": "W0060156",
    "W0060157": "W0060157",
    "W0060158": "W0060158",
    "W0060159": "W0060159",
    "W0060160": "W0060160",
    "W0060161": "W0060161",
    "W0060162": "W0060162",
    "W0060163": "W0060163",
    "W0060164": "W0060164",
    "W0060165": "W0060165",
    "W0060166": "W0060166",
    "W0060167": "W0060167",
    "W0060168": "W0060168",
    "W0060169": "W0060169",
    "W0060170": "W0060170",
    "W0060171": "W0060171",
    "W0060172": "W0060172",
    "W0060173": "W0060173",
    "W0060174": "W0060174",
    "W0060175": "W0060175",
    "W0060177": "W0060177",
    "W0060178": "W0060178",
    "W0060179": "W0060179",
    "W0060180": "W0060180",
    "W0060181": "W0060181",
    "W0060182": "W0060182",
    "W0060183": "W0060183",
    "W0060184": "W0060184",
    "W0060185": "W0060185",
    "W0060186": "W0060186",
    "W0060187": "W0060187",
    "W0060188": "W0060188",
    "W0060189": "W0060189",
    "W0060189C": "W0060189C",
    "W0060190": "W0060190",
    "W0060191": "W0060191",
    "W0060192": "W0060192",
    "W0060193": "W0060193",
    "W0060194": "W0060194",
    "W0060195": "W0060195",
    "W0060196": "W0060196",
    "W0060197": "W0060197",
    "W0060198": "W0060198",
    "W0060199": "W0060199",
    "W0060200": "W0060200",
    "W0060201": "W0060201",
    "W0060202": "W0060202",
    "W0060203": "W0060203"
};







