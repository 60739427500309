const DOC_TYPES = {
  taker: [
    { value: "NIF", label: "NIF / DNI" },
    { value: "NIE", label: "NIE / Tarjeta de residencia" },
    { value: "PASP", label: "Pasaporte" }
  ],
  takerHealth: [
    { value: "NIF", label: "NIF / DNI" },
    { value: "NIE", label: "NIE / Tarjeta de residencia" },
    { value: "PAS", label: "Pasaporte" }
  ],
  takerTravelLegalPerson: [
    { value: "CIF", label: "CIF" },
    { value: "CIE", label: "CIE" }
  ],
  insuredsHealth: [
    { value: "NIF", label: "NIF / DNI" },
    { value: "NIE", label: "NIE / Tarjeta de residencia" },
    { value: "PAS", label: "Pasaporte" },
    { value: "NIFP", label: "NIFP" },
    { value: "MENOR", label: "Menores" }
  ],
  others: [
    { value: "NIF", label: "NIF / DNI" },
    { value: "NIE", label: "NIE / Tarjeta de residencia" },
    { value: "PASP", label: "Pasaporte" }
  ],
  othersHealth: [
    { value: "NIF", label: "NIF / DNI" },
    { value: "NIE", label: "NIE / Tarjeta de residencia" },
    { value: "PAS", label: "Pasaporte" }
  ],
  othersSimulation: [
    { value: "NOID", label: "" },
    { value: "NIF", label: "NIF / DNI" },
    { value: "NIE", label: "NIE / Tarjeta de residencia" },
    { value: "PASP", label: "Pasaporte" }
  ],
  beneficiaries: [
    { value: "NIF", label: "NIF / DNI" },
    { value: "NIE", label: "NIE / Tarjeta de residencia" },
    { value: "PASP", label: "Pasaporte" },
    { value: "MENOR", label: "Menor sin documento" }
  ],
  insureds: [
    { value: "NIF", label: "NIF / DNI" },
    { value: "NIE", label: "NIE / Tarjeta de residencia" },
    { value: "PASP", label: "Pasaporte" },
    { value: "MENOR", label: "MENOR SIN DOCUMENTO" }
  ],
  insuredsSimulation: [
    { value: "NOID", label: "" },
    { value: "NIF", label: "NIF / DNI" },
    { value: "NIE", label: "NIE / Tarjeta de residencia" },
    { value: "PASP", label: "Pasaporte" },
    { value: "MENOR", label: "MENOR SIN DOCUMENTO" }
  ]
};

export default DOC_TYPES;
