import React, { Component } from "react";
import { CampaignsCreateStepContainer } from "./campains-create-steps-style";
import { CardConditionCampaign } from "../../../../components/card-condition-campaign/card-condition-campaign";
import ModalAddCondition, { ICondition } from "../modals/modal-add-condition";
import { ICampaingsCreateStep2State } from "./campaings-create-step-2";
import { ICampaingsCreateStep3State } from "./campaings-create-step-3";

interface ICampaingsCreateStep4Props {
  goNext: boolean;
  onNext: (state: ICampaingsCreateStep4State) => void;
  previousState?: ICampaingsCreateStep4State;
  setDisabledNext: (disabled: boolean) => void;
  isContest: boolean;
  prizes?: ICampaingsCreateStep3State;
  selectedAudience?: string[];
}

export interface ICampaingsCreateStep4State {
  modal: string;
  selectedCondition: string;

  conditions: {
    [key: string]: ICondition[][];
  };
}

class CampaingsCreateStep4 extends Component<
  ICampaingsCreateStep4Props,
  ICampaingsCreateStep4State
  > {
  constructor(props: ICampaingsCreateStep4Props) {
    super(props);
    this.state = {
      modal: "",
      selectedCondition: "",
      conditions: {
        runners: [],
        coordinators: [],
        buyers: [],
        exclusiveAgents: []
      }
    };
  }

  componentDidMount() {
    const { setDisabledNext, previousState, prizes, isContest } = this.props;
    if (previousState) this.setState(previousState);
    else {
      if (prizes) {
        const conditions = {
          runners: prizes.formData.map(() => [
            { unit: isContest ? 'POINTS' : 'PREMIUM', productLines: [], products: [], value: NaN }
          ]),
          coordinators: prizes.formData.map(() => [
            { unit: isContest ? 'POINTS' : 'PREMIUM', productLines: [], products: [], value: NaN }
          ]),
          buyers: prizes.formData.map(() => [
            { unit: isContest ? 'POINTS' : 'PREMIUM', productLines: [], products: [], value: NaN }
          ]),
          exclusiveAgents: prizes.formData.map(() => [
            { unit: isContest ? 'POINTS' : 'PREMIUM', productLines: [], products: [], value: NaN }
          ])
        };
        this.setState({ conditions });
      }
      setDisabledNext(true);
    }
  }

  componentDidUpdate(prevProps: ICampaingsCreateStep4Props) {
    const { goNext, onNext } = this.props;
    if (goNext && prevProps.goNext !== goNext) {
      onNext(this.state);
    }
  }

  openModal(selectedCondition: string) {
    this.setState({ selectedCondition, modal: "modal-conditions" });
  }

  closeModal() {
    this.setState({ modal: "" });
  }

  handleSave(cond: ICondition[][]) {
    const { setDisabledNext, selectedAudience } = this.props;
    const { selectedCondition, conditions } = Object.assign(this.state);
    if (selectedCondition === '*') {
      conditions.runners = cond;
      conditions.coordinators = cond;
      conditions.buyers = cond;
      conditions.exclusiveAgents = cond;
    } else {
      conditions[selectedCondition] = cond;
    }

    if (
      selectedAudience &&
      (conditions.runners[0][0].products.length > 0 ||
        !selectedAudience.includes("runners")) &&
      (conditions.coordinators[0][0].products.length > 0 ||
        !selectedAudience.includes("coordinators")) &&
      (conditions.buyers[0][0].products.length > 0 ||
        !selectedAudience.includes("buyers")) &&
      (conditions.exclusiveAgents[0][0].products.length > 0 ||
        !selectedAudience.includes("exclusiveAgents"))
    ) {
      setDisabledNext(false);
    }
    this.setState({ modal: "", conditions });
  }

  render() {
    const { isContest, selectedAudience } = this.props;
    const { modal, conditions, selectedCondition } = this.state;
    return (
      <CampaignsCreateStepContainer>
        <div className="section">
          <div className="section__title">
            {"DEFINICIÓN DE LAS CONDICIONES"}
          </div>
          <div className="section__subtitle">{""}</div>
          <div className="col-50">
            {selectedAudience && selectedAudience.length === 0 && (
              <CardConditionCampaign
                title={"Todos los mediadores"}
                status={
                  conditions.runners.length === 0 ||
                    conditions.runners[0][0].unit === ""
                    ? "notCompleted"
                    : "completed"
                }
                onClick={() => this.openModal("*")}
              />
            )}
            {selectedAudience && selectedAudience.includes("runners") && (
              <CardConditionCampaign
                title={"Corredores"}
                status={
                  conditions.runners.length === 0 ||
                    conditions.runners[0][0].unit === ""
                    ? "notCompleted"
                    : "completed"
                }
                onClick={() => this.openModal("runners")}
              />
            )}
            {selectedAudience && selectedAudience.includes("coordinators") && (
              <CardConditionCampaign
                title={"Coordinadores"}
                status={
                  conditions.coordinators.length === 0 ||
                    conditions.coordinators[0][0].unit === ""
                    ? "notCompleted"
                    : "completed"
                }
                onClick={() => this.openModal("coordinators")}
              />
            )}
          </div>
          <div className="col-50">
            {selectedAudience && selectedAudience.includes("buyers") && (
              <CardConditionCampaign
                title={"Compradores"}
                status={
                  conditions.buyers.length === 0 ||
                    conditions.buyers[0][0].unit === ""
                    ? "notCompleted"
                    : "completed"
                }
                onClick={() => this.openModal("buyers")}
              />
            )}
            {selectedAudience &&
              selectedAudience.includes("exclusiveAgents") && (
                <CardConditionCampaign
                  title={"Agentes Exclusivos"}
                  status={
                    conditions.exclusiveAgents.length === 0 ||
                      conditions.exclusiveAgents[0][0].unit === ""
                      ? "notCompleted"
                      : "completed"
                  }
                  onClick={() => this.openModal("exclusiveAgents")}
                />
              )}
          </div>
        </div>
        {modal === "modal-conditions" && (
          <ModalAddCondition
            conditions={selectedCondition === '*' ? conditions.runners : conditions[selectedCondition]}
            onSave={(conditions: ICondition[][]) => this.handleSave(conditions)}
            close={() => this.closeModal()}
            title={"Corredores"}
            isContest={isContest}
          />
        )}
      </CampaignsCreateStepContainer>
    );
  }
}

export default CampaingsCreateStep4;
