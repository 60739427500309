import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import CalendarBox from "../../../../../components/calendar-box/calendar-box";
import GuideLine from "../../../../../components/guide-line";
import MainButton from "../../../../../components/main-button";
import { STORAGE_KEY } from "../../../../../constants/health";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { MediatorDataContainer } from "./mediator-form.style";
import { getRelatedMediatorsCodes } from "../../../../../utils/mediatorCode";
import SelectBox from "../../../../../components/select-box/select-box";
import MediatorServices from "../../../../../services/MediatorServices";
import { HEALTH_MAX_EFFECT_DAYS } from "../../../../../services/url";
import InputBox from "../../../../../components/input-box/input-box";
import { MediatorProps, MediatorState } from "./mediator-form.types";
import { getPaymentTime } from "../assets";

class MediatorForm extends React.Component<MediatorProps, MediatorState> {
  constructor(props: any) {
    super(props);

    var healthSimulationData;
    if (localStorage.getItem(STORAGE_KEY.sKey)) {
      healthSimulationData = JSON.parse(
        String(localStorage.getItem(STORAGE_KEY.sKey))
      );
    }

    let initialPayType: string = '';
    if (healthSimulationData && healthSimulationData.selectedPrice && healthSimulationData.selectedPrice.payType) {
      initialPayType = healthSimulationData.selectedPrice.payType;
    }

    this.state = {
      mediatorValue: `${props.user.name} ${props.user.lastname1} ${props.user.lastname2}`,
      mediatorData: {
        code: "",
        name: "",
        startDate: healthSimulationData
          ? healthSimulationData.mediatorData.startDate
          : moment().format("DD/MM/YYYY").toLocaleString(),
        email: "",
        nif: "",
      },
      selectedPrice: {
        paymentTime: "",
        payType: initialPayType,
        price: "",
        discount: "",
        taxes: "",
        total: "",
      },
      taker:
        healthSimulationData && healthSimulationData.taker
          ? healthSimulationData.taker
          : {
              name: "",
              lastname1: "",
              lastname2: "",
              email: "",
            },
      errorName: "",
      errorLastName: "",
      errorPeriodicity: "",
      healthSimulationData: healthSimulationData,
      nextDisabled: false,
      isCopay: false,
      relatedMediatorCodes: getRelatedMediatorsCodes(this.props.mediator),
      mediatorCodeSelected: props.selectedMediatorCode
        ? props.selectedMediatorCode
        : props.mediator.mediatorCode,
      maxEffectDays: HEALTH_MAX_EFFECT_DAYS
        ? Number(HEALTH_MAX_EFFECT_DAYS)
        : 0,
    };
  }

  componentDidMount = () => {
    const { policy } = this.props;
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    const isCopay = policy.product.products_isCopay;
    this.setState({ isCopay });
  };

  componentDidUpdate(
    prevProps: Readonly<MediatorProps>,
    prevState: Readonly<MediatorState>
  ): void {
    const { policy } = this.props;
    if ( Object.keys(prevProps.policy.product).length === 0 && Object.keys(policy.product).length !== 0) {
      const isCopay = policy.product.products_isCopay;
      this.setState({ isCopay });
    }
  }

  async handleSelectMediatorChange(value: string) {
    this.props.policy.setMediatorSelectedCode && this.props.policy.setMediatorSelectedCode(value);
    this.setState({ mediatorCodeSelected: value });
    const w: any = window;
    const mediator = {
      setLoading: w.setLoader,
      loader: true,
      mediatorCode: value,
    };
    const mediatorName = await MediatorServices.getMediatorInfo(mediator);
    let { mediatorData, mediatorValue } = this.state;
    mediatorValue = mediatorName.mediatorName;
    mediatorData.name = value;
    mediatorData.code = value;
    mediatorData.email = mediatorName.email;
    this.setState({ mediatorData, mediatorValue });
  }
  toValidate = async () => {
    const { history } = this.props;
    const {
      mediatorValue,
      healthSimulationData,
      isCopay,
      mediatorData,
      taker,
      selectedPrice,
    } = this.state;
    let validate = true;
    if (!taker.name) {
      validate = false;
      this.setState({ errorName: "required" });
    }
    if (!taker.lastname1) {
      validate = false;
      this.setState({ errorLastName: "required" });
    }
    if (selectedPrice.payType === '') {
      validate = false;
      this.setState({ errorPeriodicity: "required" });
    }
    if (validate) {
      mediatorData.name = mediatorValue;
      if (healthSimulationData) {
        healthSimulationData.mediatorData = this.state.mediatorData;
        healthSimulationData.taker = taker;
        localStorage.setItem(
          STORAGE_KEY.sKey,
          JSON.stringify({ ...healthSimulationData, isCopay, selectedPrice })
        );
      } else {
        localStorage.setItem(
          STORAGE_KEY.sKey,
          JSON.stringify({
            mediatorData: this.state.mediatorData,
            isCopay,
            taker,
            selectedPrice,
          })
        );
      }
      if (mediatorData.code) history.push("/comparacion/nueva/salud/asegurados");
    }
  };

  changeDateValue(value: string, error?: boolean, errorCode?: string) {
    let { mediatorData } = this.state;
    mediatorData.startDate = value;
    this.setState({ mediatorData, nextDisabled: errorCode ? true : false });
  }
  async handleValueChange(input: string, value: string) {
    let { taker } = this.state;
    value = value.toUpperCase();

    switch (input) {
      case "name":
        taker.name = value;
        break;
      case "lastname1":
        taker.lastname1 = value;
        break;
      case "lastname2":
        taker.lastname2 = value;
        break;
      case "email":
        taker.email = value;
        break;
      default:
        break;
    }
    this.setState({ taker });
  }

  periodicityChangeHandler(value: string) {
    this.setState((prevState) => ({
      selectedPrice: {
        ...prevState.selectedPrice,
        payType: value,
        paymentTime: getPaymentTime(value),
      },
    }));
  }

  render() {
    let {
      mediatorData,
      nextDisabled,
      relatedMediatorCodes,
      mediatorCodeSelected,
      maxEffectDays,
      taker,
      errorName,
      errorLastName,
      errorPeriodicity,
      selectedPrice,
    } = this.state;

    let initialPayType: string = '';
    if (selectedPrice && selectedPrice.payType) {initialPayType = selectedPrice.payType}

    return (
      <MediatorDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <h2>{"DATOS DEL SEGURO"}</h2>
          </div>
          <div className="data-flow-status-guide">
            <GuideLine steps={[0, 0, 0, 0]} currentStep={1} />
          </div>
        </div>
        <div className="content-wrapper bottomMargin20" ref={""}>
          <div className="personal-data-wrapper">
            <div className="data-title" id="data-personal">
              <h5>DATOS PERSONALES</h5>
            </div>

            <div className="personal-data-fisica">
              <div className="personal-data-row">
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Nombre"
                      required={true}
                      errorCode={errorName}
                      value={taker.name}
                      onChange={(value: string) =>
                        this.handleValueChange("name", value)
                      }
                      type={"letters"}
                    />
                  </div>
                </div>

                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Apellido 1"
                      required={true}
                      errorCode={errorLastName}
                      value={taker.lastname1}
                      onChange={(value: string) =>
                        this.handleValueChange("lastname1", value)
                      }
                      type={"letters"}
                    />
                  </div>
                </div>
              </div>

              <div className="personal-data-row">
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Apellido 2"
                      value={taker.lastname2}
                      onChange={(value: string) =>
                        this.handleValueChange("lastname2", value)
                      }
                      type={"letters"}
                    />
                  </div>
                </div>
              </div>
              <div className="personal-data-row">
                <div className="personal-data-item col-100">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Correo electrónico"
                      value={taker.email}
                      onChange={(value: string) =>
                        this.handleValueChange("email", value)
                      }
                      type={"email"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper bottomMargin20" ref={""}>
          <div className="personal-data-wrapper">
            <div className="data-title">
              <h5>DATOS SIMULACION</h5>
            </div>

            <div className="colSin-50 rightPadding20 mb-5">
              <div className="inputBox">
                <SelectBox
                  isMediator={true}
                  optionsText={relatedMediatorCodes}
                  optionKey={"value"}
                  optionValue={"label"}
                  defaultValue={mediatorCodeSelected}
                  onChange={(value: string) =>
                    this.handleSelectMediatorChange(value)
                  }
                  propValue={mediatorCodeSelected}
                  required
                />
                <label>Mediador</label>
              </div>
            </div>

            <div className="colSin-100 topMargin20">
              <div className="colSin-50 rightPadding20">
                <div className="selectBox">
                  <SelectBox
                    labelText=""
                    required={true}
                    optionsText={this.props.policy.productPayTypes}
                    errorCode={errorPeriodicity}
                    placeholder={"Seleccionar"}
                    initialValue={initialPayType ? initialPayType : ""}
                    optionKey={"value"}
                    optionValue={"label"}
                    onChange={(value: string) =>
                      this.periodicityChangeHandler(value)
                    }
                  />
                  <label>Periodicidad de pago</label>
                </div>
              </div>

              <div className="colSin-50 rightPadding20">
                <div className="inputBox">
                  <CalendarBox
                    initialValue={this.state.mediatorData.startDate}
                    placeholder="Fecha efecto"
                    maxDate={moment()
                      .add(maxEffectDays, "days")
                      .toLocaleString()}
                    minDate={moment().subtract(1, "days").toLocaleString()}
                    onChange={(
                      value: string,
                      error?: boolean,
                      errorCode?: string
                    ) => {
                      this.changeDateValue(value, error, errorCode);
                    }}
                    required={true}
                    errorCode={""}
                    health={true}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="colSin-100 mb-10">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.goBack()}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                text="Siguiente"
                disabled={nextDisabled || !mediatorData.code}
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>
      </MediatorDataContainer>
    );
  }
}

export default withGeneral(withCoverages(withPolicy(withRouter(MediatorForm))));
