import React from 'react';
import ContractPolicyLayout from './contract-policy-layout';

interface ContractPolicyProps { 
    setLoading: Function,
    user: any,
}
export default class ContractPolicy extends React.Component<ContractPolicyProps, {}> {
    render() {
        return (
            <ContractPolicyLayout 
                setLoading={this.props.setLoading} 
                user={this.props.user}
            />
        )
    }
}