import styled from "styled-components";

export const MultiSelectContainer = styled.div`
  width: 100%;

  .multiSelect__control {
    position: relative;
    width: 100%;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #004f8b;
    cursor: pointer;
    height: auto;
    min-height: 48px;
    max-height: 78px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    background-color: #f7fafb;
    overflow: hidden;

    &:hover {
      background-color: #eef1f1;
    }

    &-input {
      margin: 4px 8px
      padding: 2px 20px;
      border-radius: 4px;
      background-color: #d8d8d8;
    }
  }
`;
