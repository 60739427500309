import styled from "styled-components";
import { colors } from "../../../assets";

export const PendantHiringContainer = styled.div`
  .back-arrow-container {
    margin-bottom: 18px;
  }
  .main-title-container {
    margin-bottom: 38px;
    color: ${colors["PR-001-100"]};
  }

  .main-filter-container {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
  }

  .main-filter-item {
    width: 100%;
    margin-right: 36px;
  }

  .new-simulation {
    min-width: 170px;
  }

  .main-results-number {
    color: ${colors["TX-001-100"]};
    margin-bottom: 40px;
    span {
      font-weight: 600;
    }
  }

  .pendant-simulations-table {
    margin-bottom: 75px;
    min-width: 100%;
    max-width: 1250px;
    overflow-x: auto;
    
    &.special {
      th:first-of-type {        
        max-width: 60px;
        min-width: 60px;
      }
    }
  }

  .pendant-simulation-pagination {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
  }

  .filter-calendar{
    display: flex;
  }
  .filter-search-button{
    float: right;

    img{
      margin-right: 10px;
    }
    
  }
  
  div.inline {
    white-space: nowrap;
  }
  div.normal {
    white-space: normal;
  }
`;
