import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const TooltipBoxContainer = styled.div`
    display: none;

    &.active {
        display: block;
    }

    .tooltip-modal {
        color: ${colors['FL-001-Default']};
        background: ${colors['PR-001-100']};
        padding: ${props => props.tooltipText ? '20px 12px' : '11px 16px'};
        position: relative;
        max-width: 260px;
        border-radius: 3px;
        z-index: 19;
        &:before {
            content: "";
            position: absolute;
            left: ${props => props.leftArrow ? '-4px' : 'inherit'};
            right: ${props => props.leftArrow ? 'inherit' : props.centerArrow ? '26px' : '3px'};
            transform: rotate(45deg);
            top: ${props => props.leftArrow ? 'calc(50% - 8px);' : '-4px'};
            width: 13px;
            height: 16px;
            border-radius: 4px;
            background: ${colors['PR-001-100']};
        }
    }

    .tooltip-modal-mediator {
        color: ${colors['FL-001-Default']};
        background: ${colors['PR-001-100']};
        padding: ${props => props.tooltipText ? '20px 12px' : '11px 16px'};
        position: absolute;
        max-width: 260px;
        border-radius: 3px;
        z-index: 19;
        left: ${props => props.left};
        top: ${props => props.top}; 
        &:before {
            content: "";
            position: absolute;
            left: ${props => props.leftArrow ? '-4px' : 'inherit'};
            right: ${props => props.leftArrow ? 'inherit' : props.centerArrow ? '26px' : '3px'};
            transform: rotate(45deg);
            top: ${props => props.leftArrow ? 'calc(50% - 8px);' : '-4px'};
            width: 13px;
            height: 16px;
            border-radius: 4px;
            background: ${colors['PR-001-100']};
        }
    }

    .tooltip-title {
        margin-bottom: ${props => props.leftArrow || !props.tooltipText ? '0' : '8px'};
        color: ${colors['FL-001-Default']};
        div {
            font-weight: ${props => props.leftArrow || !props.tooltipText ? 300 : 500};
        }
    }

    .tooltip-main {
        font-size: 12px;
    }
`;