import GeneralServices from "./GeneralServices";

class ProductServices {

  getDynamicProducts = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `products/dinamicProducts/${args.mediator_id} `
      });
      return response.data;
    } catch (err) {
      return [];
    }
  };

  getProductPermission = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `products/permission?product_id=${args.product_id}&mediator_id=${args.mediator_id}`
      });
      return response.data.isAllowed;
    } catch (err) {
      return false;
    }
  };

  getProductLinks = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `products/productLinks`
      });
      return response.data;
    } catch (err) {
      return [] ;
    }
  }

  getPolicyTypes = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `products/getPolicyTypes`
      });
      return response.data;
    } catch (err) {
      return [] ;
    }
  }

  getProductsByAreaName = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `products/getProductsByAreaName`
      });
      return response.data;
    } catch (err) {
      return {};
    }
  }

  checkProductArea = (area: string, internalName: string,  productsByAreaName: any) => {
    if(Object.keys(productsByAreaName).length===0){
      return false
    }else{
     return productsByAreaName[area].includes(internalName);
    }
  }

  getAllProducts = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `products/getAllProducts`
      });
      return response.data;
    } catch (err) {
      return {};
    }
  }

  getProductsByName = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `products/getProductsByName`
      });
     
      return res.data;
    } catch (err) {
      return {};
    }
  };

}

export default new ProductServices();
