import React from "react";
import { RouteComponentProps, withRouter, Switch, Route } from "react-router-dom";
import BreadcrumbText from "../../../components/components-text/breadcrumb-text";
import LeftInfoModal from "../../../components/left-info-modal";
import notFoundLayout from "../../../components/not-found/not-found-layout";
import PolicyFunctional from "../../../functional/policy";
import { AREA_NAMES, PRODUCT_TYPE, getProductTypeFromURL } from "../../../constants/policyType";
import { images } from "../../../assets";
import { IMediator } from "../../../models/mediator-model";
import { IUser } from "../../../models/user-model";
import { NewSimulationContainer } from "../../simulations/new-simulation/new-simulation-style";
import { PolicyContext } from "../../../context/constants";
import { removeEmpty } from "../../../utils";
import { utc } from "moment";
import { withDynamicProducts } from "../../../context/mixin/with-dynamic-products";
import { withGeneral } from "../../../context/mixin/with-general";
import SimulationServices from "../../../services/SimulationServices";
import MediatorForm from "../../forms/comparator/health-comparator/step-1/mediator-form";
import InsuredForm from "../../forms/comparator/health-comparator/step-2/insured-form";
import Comparator from "../../forms/comparator/health-comparator/comparator/comparator";

interface SimulationLayoutProps extends RouteComponentProps {
  setGuideLine: any;
  setLoading: Function;
  user: IUser;
  mediator: IMediator;
  checkProductArea: Function;
  allProducts: any;
}

class HealthComparatorLayout extends PolicyFunctional {
  constructor(props: SimulationLayoutProps) {
    super(props);
    this.state = {
      showInfo: false,
      showLeftInfoModal: false,
      showExceptionModal: false,
      data: {
        taker: {},
        insureds: [],
        coverages: [],
      },
      dataForm: {
        taker: {},
        insureds: [],
      },
      validateForm: false,
      addInsured: false,
      indexRemove: -1,
      responsePolicy: {},
      hiring: false,
      showInsuredModal: false,
      changeState: false,
      isEdit: {},
      infoModalShow: false,
      infoModalText: "",
      selectedMediatorCode: "",
      product: {},
      prices: {},
      productPayTypes: [],
    };
  }

  changePolicyType = () => {
    this.setState(
      {
        dataForm: { taker: {}, insureds: [] },
        data: { taker: {}, insureds: [], coverages: [] },
        selectedMediatorCode: "",
      },
      () => this.setPolicyType()
    );
  };

  newStep = (go?: string, comesFromTaker?: boolean) => {
    const { history, checkProductArea } = this.props;
    const { pathname, search } = history.location;
    const { dataForm, data, product } = this.state;

    const isDeathArea = checkProductArea(
      AREA_NAMES.DEATH,
      product.products_internalName
    );
    const isPetArea = checkProductArea(
      AREA_NAMES.PET,
      product.products_internalName
    );
    const urlProduct = getProductTypeFromURL(product.products_linkSimulacion);
    let url = "/simulacion/nueva/${type}/${step}";
    const nInsuredsMax = isDeathArea || isPetArea ? 10 : 2;
    let ok = true;

    switch (go) {
      case "taker":
        url = url.replace("${type}", urlProduct);
        url = url.replace("/${step}", "/tomador");
        break;
      case "showAll":
        url = url.replace("${type}", "miembros");
        url = url.replace("/${step}", "");
        break;
      case "insured":
        if (nInsuredsMax <= dataForm.insureds.length) {
          ok = false;
        }
        url = url.replace("${type}", urlProduct);
        url = url.replace("${step}", "asegurado");
        url += `?index=${
          comesFromTaker && data.insureds.length > 0
            ? data.insureds.length - 1
            : data.insureds.length
        }`;
        break;
      default:
        this.simulate();
        break;
    }
    if (ok) {
      this.setState({ validateForm: false, changeState: true }, () => {
        this.setState({ changeState: false });
        history.push(url, { from: { pathname, search } });
      });
    } else {
      this.openInsuredModal();
    }
  };

  dateToISOString(date: string) {
    return utc(date, "DD/MM/YYYY").toISOString(true);
  }

  simulate = async () => {
    const { setLoading, checkProductArea } = this.props;
    const { data, selectedMediatorCode, product } = this.state;
    const dataSend: any = removeEmpty(data);
    const productInternalName = product.products_internalName;
    const isPetArea = checkProductArea(AREA_NAMES.PET, productInternalName);

    if (isPetArea) {
      dataSend.payment = {
        payType: dataSend.taker.payment,
      };
    }

    if (productInternalName === PRODUCT_TYPE.WOMAN) {
      dataSend.insureds.forEach((insured: any) => {
        insured.coverages.forEach((cov: any) => {
          if (!cov.capital) cov.capital = 0;
        });
      });
    }
    if (
      !dataSend.taker.foreignAddress.country ||
      Object.keys(dataSend.taker.foreignAddress.country).length === 0
    )
      delete dataSend.taker.foreignAddress;
    dataSend.insureds.forEach &&
      dataSend.insureds.forEach((insured: any) => {
        if (
          (!!insured.foreignAddress && !insured.foreignAddress.country) ||
          Object.keys(insured.foreignAddress.country).length === 0
        )
          delete insured.foreignAddress;
      });

    if (dataSend.taker.birthdate && dataSend.taker.birthdate.includes("/")) {
      dataSend.taker.birthdate = this.dateToISOString(dataSend.taker.birthdate);
    }

    if (!dataSend.payment) {
      dataSend.payment = {};
    }
    if (
      dataSend.payment.starterDay &&
      dataSend.payment.starterDay.includes("/")
    ) {
      dataSend.payment.starterDay = this.dateToISOString(
        dataSend.payment.starterDay
      );
    } else if (
      dataSend.taker.starterDay &&
      dataSend.taker.starterDay.includes("/")
    ) {
      dataSend.payment.starterDay = this.dateToISOString(
        dataSend.taker.starterDay
      );
    }

    if (dataSend.taker.docType === "NOID") {
      delete dataSend.taker.docNumber;
      delete dataSend.taker.docType;
    }

    Array.isArray(dataSend.insureds) &&
      dataSend.insureds.forEach((insured: any) => {
        if (insured.birthdate && insured.birthdate.includes("/")) {
          insured.birthdate = this.dateToISOString(insured.birthdate);
        }
        if (insured.docType === "MENOR") {
          insured.docNumber = "MENOR";
        } else if (insured.docType === "NOID") {
          delete insured.docNumber;
          delete insured.docType;
        }
      });

    if (selectedMediatorCode) {
      dataSend.selectedMediatorCode = selectedMediatorCode;
    }
    dataSend.productType = productInternalName;
    if (isPetArea) {
      dataSend.insureds = dataSend.coverages;
      delete dataSend.coverages;
    }
    try {
      const response = await (SimulationServices as any)[
        product.products_areaName.toLocaleLowerCase()
      ]({
        loader: true,
        setLoading: setLoading,
        data: dataSend,
      });

      this.setState({ validateForm: false, responsePolicy: response }, () => {
        if (response && response.warnings && response.warnings.length > 0) {
          const warns = response.warnings.map((r: any) => r.message);
          this.openExceptionModal(
            warns.length > 1
              ? "Se han producido avisos como resultado de la operación:"
              : warns[0],
            "info",
            warns.length > 1 ? warns : []
          );
        } else {
          this.goToNextPage();
        }
      });
    } catch (err) {
      this.checkError(err);
    }
  };

  goToNextPage() {
    this.closeExceptionModal();
    const { history } = this.props;
    const { product } = this.state;
    const urlProduct = getProductTypeFromURL(product.products_linkSimulacion);

    let url = "/simulacion/nueva/${type}/${step}";
    url = url.replace("${type}", urlProduct);
    url = url.replace("${step}", "resultado");
    history.push(url);
  }

  checkError(err: any) {
    const data: any = err && err.data && err.data.errors ? err.data.errors : {};
    if (data) {
      const exceptions = [];
      if (data.comment) exceptions.push(data.comment);
      if (data.description) exceptions.push(data.description);
      if (data.warnings)
        data.warnings.forEach((d: any) =>
          exceptions.push(`${d}\n`.replace("/<BR>/g", "\n"))
        );

      if (err.data.code === "SENDA_EXCEPTION") {
        this.openExceptionModal(
          exceptions.length > 0
            ? "Se han producido uno o más errores como resultado de la operación:"
            : "Se ha producido un error, inténtelo más tarde.",
          "error",
          exceptions
        );
      } else if (err.data.code === "SENDA_WARNING") {
        this.openExceptionModal(
          exceptions.length > 0
            ? "Se han producido uno o más avisos como resultado de la operación:"
            : "Se ha producido un error, inténtelo más tarde.",
          "error",
          exceptions
        );
      } else
        this.openExceptionModal(
          "Se ha producido un error, inténtelo más tarde.",
          "error",
          []
        );
    } else {
      this.openExceptionModal(
        "Se ha producido un error, inténtelo más tarde.",
        "error",
        []
      );
    }
  }

  getStep = (): string => {
    const {
      history: {
        location: { pathname },
      },
      checkProductArea,
    } = this.props;
    const { product } = this.state;
    const isPetArea = checkProductArea(
      AREA_NAMES.PET,
      product.products_internalName
    );
    let step = "showAll";
    if (pathname.indexOf("tomador") > -1 && !isPetArea) {
      step = "insured";
    }
    return step;
  };

  handleGoToDetail(index: number) {
    this.setState({ goToDetail: index }, () => {
      this.setState({ goToDetail: undefined });
    });
  }

  render() {
    const {
      showLeftInfoModal,
      data,
      validateForm,
      addInsured,
      dataForm,
      showExceptionModal,
      responsePolicy,
      showInsuredModal,
      changeState,
      isEdit,
      textModal,
      typeModal,
      listModal,
      goToDetail,
      infoModalText,
      infoModalShow,
      selectedMediatorCode,
      product,
      productPayTypes,
    } = this.state;
    const { setLoading, hasInformativeNoteNotLife, checkProductArea } =
      this.props;

    return (
      <NewSimulationContainer>
        <PolicyContext.Provider
          value={{
            policyInfo: {
              changePolicyType: this.changePolicyType,
            },
            validateForm: this.validateForm,
            changeData: this.changeData,
            lengthInsured: data.insureds.length,
            data: dataForm,
            handleNewChanges: this.handleNewChanges,
            setMediatorSelectedCode: this.setMediatorSelectedCode,
            setGipSimulationData: this.setGipSimulationData,
            setTravelSimulationData: this.setTravelSimulationData,
            product: product,
            productPayTypes: productPayTypes,
          }}
        >
          {this.props.history.location.pathname.indexOf("miembros") === -1 &&
          this.props.history.location.pathname !== "/comparacion/nueva" ? (
            <></>
          ) : (
            this.props.history.location.pathname.indexOf("miembros") === -1 && (
              <div className="new-simulation-subtitle-2">
                <BreadcrumbText>{"NUEVA SIMULACIÓN"}</BreadcrumbText>
              </div>
            )
          )}
          <Switch>
            <Route
              path="/comparacion/nueva/salud/mediador"
              render={(props) => <MediatorForm {...props} />}
            />
            <Route
              exact
              path="/comparacion/nueva/salud/asegurados"
              render={(props) => (
                <InsuredForm
                  setLoading={setLoading}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path="/comparacion/nueva/salud/comparador"
              render={(props) => (
                <Comparator history={this.props.history} cardName={product.products_cardName} />
              )}
            />

            <Route path="/" component={notFoundLayout} />
          </Switch>

        </PolicyContext.Provider>

        <LeftInfoModal
          img={addInsured ? images.IconUserBlue : images.IconEraseRed}
          className={addInsured ? "" : "error"}
          mainTitle={
            addInsured
              ? "¿Quieres añadir otro asegurado?"
              : "¿Quieres eliminar al asegurado?"
          }
          mainText={
            addInsured
              ? ""
              : "Si eliminas los datos del asegurado, no podrás recuperarlo más tarde"
          }
          buttonText={addInsured ? "SI, añadir" : "Eliminar"}
          active={showLeftInfoModal}
          onClickAccept={() =>
            addInsured ? this.newStep("insured") : this.removeInsured()
          }
          close={() => this.closeLeftModal()}
        />

        <LeftInfoModal
          img={
            typeModal === "error"
              ? images.WarningError
              : images.IconWarningYellow
          }
          className={typeModal}
          mainTitle={typeModal === "error" ? "Error en el servicio" : "Aviso"}
          mainText={textModal}
          list={listModal || []}
          buttonText={typeModal === "error" ? "Cerrar" : "Continuar"}
          active={showExceptionModal}
          onClickAccept={() =>
            typeModal === "error"
              ? this.closeExceptionModal()
              : this.goToNextPage()
          }
          close={() =>
            typeModal === "error"
              ? this.closeExceptionModal()
              : this.goToNextPage()
          }
          noSecondaryButton
        />

        <LeftInfoModal
          img={images.IconInfoBlue}
          mainTitle={"Información"}
          mainText={infoModalText}
          buttonText={"Cerrar"}
          active={infoModalShow}
          onClickAccept={() => this.infoModalClose()}
          close={() => this.infoModalClose()}
          noSecondaryButton
        />
        <LeftInfoModal
          img={images.WarningError}
          className={"error"}
          mainTitle={"Máximo de asegurados"}
          mainText={"No puedes añadir mas asegurados."}
          buttonText={"Cerrar"}
          active={showInsuredModal}
          onClickAccept={() => this.closeInsuredModal()}
          close={() => this.closeInsuredModal()}
          noSecondaryButton
        />
      </NewSimulationContainer>
    );
  }
}

export default withGeneral(
  withDynamicProducts(withRouter(HealthComparatorLayout))
);
