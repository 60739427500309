import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../assets";
import InputBox from "../../../../components/input-box/input-box";
import GuideLine from "../../../../components/guide-line";
import MainButton from "../../../../components/main-button";
import { STORAGE_KEY } from "../../../../constants/health";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../context/mixin/with-general";
import { withPolicy } from "../../../../context/mixin/with-policy";
import { MediatorDataContainer } from "./gip-mediator-data-style"
import AddressServices from "../../../../services/AddressServices";
import { GipHireMenuHelper } from "../../../../components/gip-menu/gip-hire-menu";
import { IUser } from "../../../../models/user-model";
import SelectBox from "../../../../components/select-box/select-box";
import GipServices from "../../../../services/GipServices";
import { getRelatedMediatorsCodes } from "../../../../utils/mediatorCode";
import { IMediator } from "../../../../models/mediator-model";
import MediatorServices from "../../../../services/MediatorServices";

interface MediatorProps extends RouteComponentProps {
  setLoading: Function,
  match: any;
  user: IUser;
  policy: any;
  mediator: IMediator;
  data: any;
}

interface MediatorState {
  postalCode: {
    value: string,
    error: boolean,
    errorCode: string
  },
  mediator: {
    value: string,
    error: boolean,
    errorCode: string
  },
  mediatorCode: {
    value: string,
    error: boolean,
    errorCode: string
  },
  startDate: {
    value?: string,
    error: boolean,
    errorCode: string
  },
  mediatorValue: string,
  mediatorData: {
    code: string;
    name: string;
    startDate: string;
    nif: string;
    email: string;
  },
  fechasEfecto:
  Array<{
    [key: string]: string;
  }>,
  relatedMediatorCodes: any;
  mediatorCodeSelected: string;
  nextDisabled: boolean;
}

class GipMediatorDataLayout extends React.Component<MediatorProps, MediatorState> {

  constructor(props: any) {
    super(props);

    this.state = {
      mediatorValue: `${props.user.name} ${props.user.lastname1} ${props.user.lastname2}`,
      mediatorData: {
        code: "",
        name: "",
        startDate: props.data.mediator && props.data.mediator.startDate
          ? props.data.mediator.startDate
          : moment().format("DD/MM/YYYY").toLocaleString(),
        email: "",
        nif: "",
      },
      postalCode: {
        value: props.data.mediator && props.data.mediator.postalCode 
          ? props.data.mediator.postalCode 
          : '',
        error: false,
        errorCode: ''
      },
      mediator: {
        value: '',
        error: false,
        errorCode: ''
      },
      mediatorCode: {
        value: props.data.mediator && props.data.mediator.mediatorCodeValue 
          ? props.data.mediator.mediatorCodeValue 
          : '',
        error: false,
        errorCode: ''
      },
      startDate: {
        value: props.data.mediator && props.data.mediator.startDate 
          ? props.data.mediator.startDate 
          : '',
        error: false,
        errorCode: ''
      },
      fechasEfecto: [],
      nextDisabled: true,
      relatedMediatorCodes: getRelatedMediatorsCodes(this.props.mediator),
      mediatorCodeSelected: props.data.mediator && props.data.mediator.mediatorCodeValue
        ? props.data.mediator.mediatorCodeValue
        : props.mediator.mediatorCode,
    }
  }
  componentDidMount = async () => {
    let { mediator, mediatorCode, relatedMediatorCodes } = this.state;
    let { user } = this.props;

    mediator.value = `${user.name} ${user.lastname1} ${user.lastname2}`
    mediatorCode.value = user.code

    const args = {
      loader: true,
      setLoading: () => { },
    };

    const listFechasEfecto = await GipServices.getDatesForEffectDay(args)

    this.setState({ fechasEfecto: listFechasEfecto })

  };
  async handleSelectMediatorChange(value: string) {
    this.props.policy.setMediatorSelectedCode &&
      this.props.policy.setMediatorSelectedCode(value);
    this.setState({ mediatorCodeSelected: value });
    const mediatorInfo = {
      setLoading: () => { },
      loader: true,
      mediatorCode: value,
    };
    const mediatorName = await MediatorServices.getMediatorInfo(mediatorInfo);
    let { mediatorData, mediatorValue, mediator, } = this.state;
    mediatorValue = mediatorName.mediatorName;
    mediatorData.name = value;
    mediatorData.code = value;
    mediatorData.email = mediatorName.email;
    //mediator.value = mediatorName.mediatorName

    this.setState({
      mediatorData, mediatorValue, mediator: {
        ...mediator,
        value: mediatorName.mediatorName
      }
    });
  }
  async getPostalCodes(input: string) {
    const { setLoading } = this.props;
    const resPostalCodes = await AddressServices.getPostalCodes({
      loader: true,
      setLoading: setLoading,
      params: [
        `input=${input}`,
        `provinceCode=*`,
        `cityCode=*`,
        `street=0`,
      ],
    });

    return resPostalCodes && resPostalCodes.length > 0;
  };

  handleBlurPostalCode(value: string) {
    const { postalCode } = this.state

    if (value.length > 0 && value.length < 5) {
      postalCode.errorCode = 'invalid-postal-code';
      postalCode.error = true;
    }

    this.setState({ postalCode })
  }

  async handlePostalCode(value: string) {
    const { postalCode } = this.state
    postalCode.value = value;

    if (value.length === 5) {
      const isValidPostalCode = await this.getPostalCodes(value);
      postalCode.error = !isValidPostalCode;
      postalCode.errorCode = isValidPostalCode ? '' : 'invalid-postal-code';
    } else {
      postalCode.error = false;
      postalCode.errorCode = '';
    }

    this.setState({ postalCode })
  }

  toValidate = async () => {
    let { mediator, postalCode, startDate, mediatorCodeSelected } = this.state;
    const { policy } = this.props

    if (!startDate.value) {
      this.setState({ startDate: { error: true, errorCode: 'required' } })
    }

    else if (postalCode.value.length === 0 ){
      this.setState({ postalCode: { value: postalCode.value, error: true, errorCode: 'required' }}) 
    }

    else if (postalCode.value.length !== 5 || postalCode.error ){
      this.setState({ postalCode: { value: postalCode.value, error: true, errorCode: 'invalid-postal-code' }}) 
    }

    else {
      this.props.policy.setGipSimulationData('mediator', {
        mediatorValue: mediator.value,
        mediatorCodeValue: mediatorCodeSelected,
        postalCodeValue: postalCode.value,
        startDateValue: startDate.value,
        code: mediatorCodeSelected, //TODO: esto se deberia de normalizar y poner las mismas variables en todos lados. Esto se esta duplicando porque fallaba en varios sitios, y  en esta forma es como se esta guardando en base de datos
        postalCode: postalCode.value,
        startDate: startDate.value,
      });

      this.props.history.push(
        GipHireMenuHelper.getSimulationStepNavigationPath(
          GipHireMenuHelper.getSimulationStepFromNavigationRoute(
            this.props.match.url,
            policy.product.products_internalName
          ) + 1,
          policy.product.products_internalName
        )
      )
    }
  }

  changeDateValue(value: string, error?: boolean, errorCode?: string) {
    const { startDate } = this.state;
    
    startDate.value = value;
    startDate.error = error ? error : false ;
    startDate.errorCode = errorCode ? errorCode : '';

    this.setState({ startDate, nextDisabled: error ? true : false });
  }

  render() {
    const {
      postalCode,
      mediator,
      startDate,
      fechasEfecto,
      relatedMediatorCodes,
      mediatorCodeSelected
    } = this.state
    return (
      <MediatorDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">

            <h2>{'DATOS DEL SEGURO'}</h2>

          </div>
          <div className="data-flow-status-guide">
            <GuideLine
              steps={[0, 0, 0, 0]}
              currentStep={1}
            />
          </div>
        </div>

        <div className="content-wrapper bottomMargin20" ref={''}>
          <div className="personal-data-wrapper">
            <div className="data-title">
              <h5>DATOS SIMULACION</h5>
            </div>

            <div className="colSin-100 topMargin20">
              <div className="colSin-50 rightPadding20">
                <div className="inputBox">
                  <SelectBox
                    isMediator={true}
                    optionsText={relatedMediatorCodes}
                    optionKey={"value"}
                    optionValue={"label"}
                    defaultValue={mediatorCodeSelected}
                    onChange={(value: string) =>
                      this.handleSelectMediatorChange(value)
                    }
                    propValue={mediatorCodeSelected}
                    required
                  />
                </div>
              </div>

              <div className="colSin-50 rightPadding20">
                <div className="inputBox">
                  {/* <CalendarBox
                    initialValue={startDate.value}
                    placeholder="Fecha efecto"
                    maxDate={moment().add(30, 'days').toLocaleString()}
                    minDate={moment().toLocaleString()}
                    onChange={(value: string, error?: boolean, errorCode?: string) => {
                      this.changeDateValue(value, error, errorCode);
                    }}
                    required={true}
                    errorCode={startDate.errorCode}
                    health={true}
                  /> */}
                  <SelectBox
                    placeholder={"Fecha efecto"}
                    required={true}
                    errorCode={startDate.errorCode}
                    optionsText={fechasEfecto}
                    optionKey={"value"}
                    optionValue={"label"}
                    onChange={(value: string) => {
                      this.changeDateValue(value);
                    }}
                    initialValue={startDate.value}
                  //refreshValues={active !== "active"}
                  />
                </div>
              </div>
            </div>
            <div className="data-row colSin-100">
              <div className="colSin-33 rightPadding20 topMargin12">
                <div className="inputBox">
                  <InputBox
                    errorCode={postalCode.errorCode}
                    maxLength={5}
                    onChange={(value: string) => this.handlePostalCode(value)}
                    onBlur={(value: string) => this.handleBlurPostalCode(value)}
                    placeholder="Código Postal"
                    required
                    type={"number"}
                    value={postalCode.value}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="colSin-100">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push('/simulacion/nueva')}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                text="Siguiente"
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>

      </MediatorDataContainer>
    );
  }
}

export default withCoverages(withPolicy(withGeneral(withRouter(GipMediatorDataLayout))));
