import moment from "moment";
import React, { Component } from "react";
import { PersonalInformationContainer } from "./gip-personal-information-style"
import SelectBox from "../../../../../../components/select-box/select-box";
import CalendarBox from "../../../../../../components/calendar-box/calendar-box";
import DicotomicButton from "../../../../../../components/dicotomic-button";
import InputBox from "../../../../../../components/input-box/input-box";
import SEX from "../../../../../../constants/sex.json";
import YesNo from "../../../../../../constants/typeYesNo.json"
import DOC_TYPES from "../../../../../../constants/doc_types";
import CheckboxItemLayout from "../../../../../../components/checkbox-item/checkbox-item-layout";
import SearchBox from "../../../../../../components/search-box-mediadores/search-box";
import ConstantsServices from "../../../../../../services/ConstantsServices";
import { isValidCif, isValidDocumentID } from "../../../../../../utils/validation";
import { DefaultPersonalData, DefaultProfessionData, DefaultValueFormHealth, PersonalDataDto } from "./personal-information-dto/gip-personal-information-dto";
import { calcAge, convertDateToString } from "../../../../../../utils/utility";
import { GIP_CIVIL_STATUS } from "../../../../../../constants/civilStatus";

export interface PersonalData {
  name: string;
  lastname1: string;
  lastname2?: string;
  birthDate: any;
  gender: string;
  nationality: string | undefined;
  nationalityLabel: string;
  docType: string;
  docNumber: string;
  passportDate: Date | undefined;
  civilStatus: string | undefined;
  profession: {
    value: string,
    code: string,
    other: string,
  };
  sameAsTaker: boolean;
  physicalPerson: boolean;
  tc2?: string;
  reta?: string;
  responsable?: string;
  hasComeAnotherInsurance?: boolean;
}

interface PersonalInformationProps {
  validate: {
    validateRequest: boolean;
    isValidated: boolean;
  },
  onValidated: (person: PersonalData) => void;
  takerAnotherInsuranceChange?: (taker: PersonalData) => void;
  onCleanForm?: boolean;
  insured?: boolean;
  handlePersonChange?: any;
  onCheckTakerData?: any;
  setLoading?: Function;
  isReadOnly?: boolean;
  onAnyChange: Function;
  insureds?: PersonalData[];
  taker?: PersonalData;
  insuredIndex: number;
  currentHealthStep: number;
  physicalPersonValue?: boolean;
}

interface PersonalInformationState {
  person: PersonalDataDto;
  stateDisabled: boolean;
  professionsList: any[];
  professionsFiltered: any[];
  countries: any[];
  countriesFiltered: any[];
  previousData: PersonalDataDto;
  docTypes: { [key: string]: string; }[];
  prevDocType: string;
}

class PersonalInformation extends Component<PersonalInformationProps, PersonalInformationState> {
  _searchWaiter: any = null;

  constructor(props: PersonalInformationProps) {
    super(props)

    this.state = {
      person: !props.insured
        ? this.getPersonalDataDto(props.taker)
        : (props.insureds && props.insuredIndex > -1
          ? this.getPersonalDataDto(props.insureds[props.insuredIndex], props.insureds[props.insuredIndex].sameAsTaker)
          : DefaultPersonalData()),
      stateDisabled: props.isReadOnly ? props.isReadOnly : false,
      professionsList: [],
      professionsFiltered: [],
      countries: [],
      countriesFiltered: [],
      previousData: DefaultPersonalData(),
      docTypes: !props.insured && props.taker
        ? this.getDocTypes(props.taker.birthDate)
        : (props.insureds && props.insuredIndex > -1
          ? this.getDocTypes(props.insureds[props.insuredIndex].birthDate)
          : DOC_TYPES.insuredsHealth),
      prevDocType: !props.insured && props.taker
        ? props.taker.docType
        : (props.insureds && props.insuredIndex > -1
          ? props.insureds[props.insuredIndex].docType
          : ''),
    }
  }

  getPersonalDataDto(personData?: PersonalData, disabled: boolean = false): PersonalDataDto {
    return !personData || Object.keys(personData).length === 0 ? DefaultPersonalData() : {
      name: this.getValueFormHealth(personData.name, disabled),
      lastName1: this.getValueFormHealth(personData.lastname1, disabled),
      lastName2: this.getValueFormHealth(personData.lastname2, disabled),
      birthDate: this.getValueFormHealth(personData.birthDate ? moment(personData.birthDate).format("DD/MM/YYYY") === 'Invalid date' ?
        personData.birthDate.toString() :
        moment(personData.birthDate).format("DD/MM/YYYY")
        : '', disabled),
      civilStatus: this.getValueFormHealth(personData.civilStatus, disabled),
      docType: this.getValueFormHealth(personData.docType, disabled),
      docNumber: calcAge(moment(personData.birthDate).format("DD/MM/YYYY")) > 16 && personData.docType === 'MENOR'
        ? this.getValueFormHealth('', disabled) :
        this.getValueFormHealth(personData.docNumber, disabled || (personData.docType === 'MENOR' && personData.docNumber.length <= 2)),
      sex: this.getValueFormHealth(personData.gender, disabled),
      nationality: {
        value: personData.nationalityLabel ? personData.nationalityLabel : '',
        errorCode: '',
        disabled: disabled,
        code: personData.nationality ? personData.nationality : ''
      },
      nationalityLabel: personData.nationalityLabel,
      profession: {
        value: personData.profession ? personData.profession.value : "",
        code: personData.profession ? personData.profession.code : "",
        errorCode: "",
        disabled: false,
        other: {
          value: personData.profession && personData.profession.value === 'OTRA' && personData.profession.other ? personData.profession.other : "",
          errorCode: "",
          disabled: false,
        }
      },
      passportDate: this.getValueFormHealth(personData.passportDate ? moment(personData.passportDate).format("DD/MM/YYYY") : "", disabled),
      sameAsTaker: personData.sameAsTaker,
      physicalPerson: personData.physicalPerson !== undefined ?
        personData.physicalPerson :
        this.props.physicalPersonValue ?
          this.props.physicalPersonValue :
          true,
      tc2: this.getValueFormHealth(personData.tc2, disabled),
      reta: this.getValueFormHealth(personData.reta, disabled),
      responsable: this.getValueFormHealth(personData.responsable, disabled),
      hasComeAnotherInsurance: personData.hasComeAnotherInsurance ? personData.hasComeAnotherInsurance : false,
    };
  }

  getValueFormHealth(value: string | undefined, disabled: boolean) {
    return {
      value: value ? value : "",
      errorCode: '',
      disabled: disabled,
      code: ''
    };
  }

  getDocTypes(birthDate: string | Date): { [key: string]: string; }[] {
    var age = 0;
    if (birthDate)
      if (typeof birthDate === 'string')
        age = calcAge(birthDate);
      else
        age = calcAge(convertDateToString(birthDate));

    return age < 18 || this.props.isReadOnly ? DOC_TYPES.insuredsHealth : DOC_TYPES.others;
  }

  componentWillReceiveProps(nextProps: PersonalInformationProps) {
    let { validate } = this.props;

    if (nextProps.validate.validateRequest) {
      validate.validateRequest = false;
      validate.isValidated = this.validateForm();
    }
    else if (nextProps.onCleanForm) {
      this.cleanForm();
    }
    // else if (this.props.insuredIndex === -1 && nextProps.insured && nextProps.insureds && nextProps.insureds.length === 0 ) {
    //   const data = JSON.parse(JSON.stringify(nextProps.taker));
    //   this.completeInsuredData(data, true);
    // }
    else if (this.props.insuredIndex !== nextProps.insuredIndex) {
      const insuredIndex: number = nextProps.insureds && nextProps.insureds.length === 1 ? 0 : nextProps.insuredIndex;
      if (nextProps.insuredIndex !== -1 && nextProps.insureds &&
        nextProps.insureds.length > 0 && nextProps.insureds[insuredIndex].sameAsTaker) {

        const data = JSON.parse(JSON.stringify(nextProps.taker));
        data.profession = JSON.parse(JSON.stringify(nextProps.insureds[insuredIndex].profession));
        this.completeInsuredData(data, true);

      }
      else {
        this.completeInsuredData(insuredIndex === -1 ? nextProps.taker : nextProps.insureds ? nextProps.insureds[insuredIndex] : undefined);
      }
    }

  }

  componentDidUpdate(prevProps: Readonly<PersonalInformationProps>, prevState: Readonly<PersonalInformationState>, snapshot?: any): void {
    if (!this.state.person.nationalityLabel || this.state.person.nationalityLabel.length === 0) {
      let { countries, person } = this.state;

      const country = countries.find((country) => country.value === 'ESPA')
      if (country) {
        person.nationality.value = country.label;
        person.nationality.code = country.value;
        person.nationalityLabel = country.value;
        this.setState({ person });
      }
    }
  }

  async componentDidMount() {
    let { professionsList, countries, docTypes, person } = this.state;
    const { insured, insuredIndex, insureds, taker } = this.props;
    professionsList = await ConstantsServices.getProfessions({
      setLoading: this.props.setLoading,
    });

    countries = await ConstantsServices.getCountries({
      setLoading: this.props.setLoading,
    });

    countries.map((country: any) => country.label = country.label.toUpperCase());

    const country = countries.find((country) => country.value === 'ESPA')

    person.nationality.value = country.label;
    person.nationality.code = country.value;
    person.nationalityLabel = country.value;

    let filterProfessions = professionsList
      .filter((p) => p.product === 'ALL')
      .sort(function (a: any, b: any) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });


    if (!insured && taker)
      docTypes = this.getDocTypes(taker.birthDate);
    else if (insureds && insuredIndex > -1)
      docTypes = this.getDocTypes(insureds[insuredIndex].birthDate);

    if (insured && insureds && insureds.length === 0 && taker && taker.physicalPerson) {
      this.setState({ person });
    }

    this.setState({ professionsList: filterProfessions, countries, docTypes });

  }

  async handleValueChange(input: string, value: any) {
    let { person, docTypes, prevDocType } = this.state;
    const { taker } = this.props;
    if (!(input === 'sex' && (taker && value === taker.gender)) &&
      !(input === 'docType' && (taker && value === taker.docType))) {
      this.props.onAnyChange();
    }

    switch (input) {
      case 'name':
      case 'lastName1':
      case 'lastName2':
      case 'birthDate':
      case 'docNumber':
      case 'sex':
      case 'docType':
      case 'passportDate':
      case "tc2":
      case "reta":
      case "responsable":
        value = value.toUpperCase()
        Object.keys(person).forEach((k: string) => {
          if (k === input) {
            person[k].errorCode = '';
            person[k].value = value;

            if (input === 'docType') {
              person.docNumber.disabled = value === "MENOR";
              person.docNumber = person.docNumber.disabled ? this.getValueFormHealth(undefined, true) : value !== "MENOR" && prevDocType === "MENOR" ? this.getValueFormHealth(undefined, false) : person.docNumber;
              prevDocType = value;
            }

            if (input === 'birthDate') {
              var age = calcAge(value);
              if (!isNaN(age) && age < 18) {
                docTypes = DOC_TYPES.insuredsHealth
                person.docType = docTypes.find(element => element.value === person.docType.value) ? person.docType : this.getValueFormHealth(undefined, false);
                prevDocType = person.docType.value;
              }
              else if (!isNaN(age) && age > 17) {
                docTypes = DOC_TYPES.others
                person.docType = docTypes.find(element => element.value === person.docType.value) ? person.docType : this.getValueFormHealth(undefined, false);
                person.docNumber.disabled = person.docType.value === "MENOR";
                prevDocType = person.docType.value;
              }
            }
          }
        });
        break;
      case 'civilStatus':
        person.civilStatus.value = value;
        break;

      case 'profession':
        person.profession.value = value.label;
        person.profession.code = value.value;
        person.profession.errorCode = '';
        break;

      case 'otherProfession':
        value = value.toUpperCase();
        person.profession.other.value = value;
        person.profession.other.errorCode = '';
        break;

      case 'nationality':
        person.nationality.value = value.label;
        person.nationality.code = value.value;
        person.nationality.errorCode = '';
        person.nationalityLabel = value.label;
        break;

      case 'hasComeAnotherInsurance':
        person.hasComeAnotherInsurance = value;
        break;

      default:
        break;
    }

    this.setState({ person, docTypes, prevDocType });
  }

  validateForm(): boolean {
    let { person } = this.state;
    let noErrors = true;

    if (person.physicalPerson) {
      if (person.name.value.length === 0) {
        person.name.errorCode = 'required';
        noErrors = false;
      }
      if (person.lastName1.value.length === 0) {
        person.lastName1.errorCode = 'required';
        noErrors = false;
      }
      if (person.birthDate.value.length === 0) {
        person.birthDate.errorCode = 'required';
        noErrors = false;
      }
      else {

        if (moment(person.birthDate.value, "DD/MM/YYYY").isAfter(moment())) {
          person.birthDate.errorCode = 'invalid-date';
          noErrors = false;
        }

      }
      if (person.nationality.value.length === 0) {
        person.nationality.errorCode = 'required';
        noErrors = false;
      }
      if (person.docType.value !== 'MENOR' && person.docNumber.value.length === 0) {
        person.docNumber.errorCode = 'required';
        noErrors = false;
      }
      else if (!isValidDocumentID(person.docNumber.value, person.docType.value === 'MENOR' ? 'NIF' : person.docType.value)) {

        if (person.docType.value !== 'MENOR' && person.docNumber.value.length > 0) {
          person.docNumber.errorCode = 'invalid-document-dni';
          noErrors = false;
        }

        if (person.docType.value !== 'MENOR' && person.docType.value === 'NIF' && person.docNumber.value.length === 9) {
          person.docNumber.errorCode = 'invalid-document-dni';
          noErrors = false;
        }
        else {
          if (person.docType.value !== 'MENOR') {
            person.docNumber.errorCode = 'invalid-format';
            noErrors = false;
          }
        }
      }
      else if (this.props.insured && this.props.insureds && this.props.insureds.length) {
        this.props.insureds.forEach((insured: any, index: number) => {
          if (insured.docNumber === person.docNumber.value && index !== this.props.insuredIndex) {
            person.docNumber.errorCode = 'repeated-document-number';
            noErrors = false;
          }
        });
        if (!person.sameAsTaker && this.props.insured && this.props.taker && person.docNumber.value === this.props.taker.docNumber) {
          person.docNumber.errorCode = 'repeated-document-number-taker';
          noErrors = false;
        }
      }
      if (person.sex.value.length === 0) {
        person.sex.errorCode = 'required';
        noErrors = false;
      }
      if (person.docType.value.length === 0) {
        person.docType.errorCode = 'required';
        noErrors = false;
      }
      if (person.docType.value === 'PASP' && person.passportDate.value.length === 0) {
        person.passportDate.errorCode = 'required';
        noErrors = false;
      }
      if (this.props.insured && person.profession.value === 'OTRA') {
        if (person.profession.other.value.length === 0) {
          noErrors = false;
          person.profession.other.errorCode = 'required';
        }
      }

      if (
        !this.props.insured &&
        !(person.docNumber.value === '') &&
        this.props.insureds
      ) {

        const insured = this.props.insureds.find((persona: PersonalData) => (
          persona.docNumber === person.docNumber.value && !persona.sameAsTaker
        ))

        if (insured) {
          noErrors = false;
          person.docNumber.errorCode = 'repeated-document-number-insured';
        }

      }
    }
    else {
      if (!person.name.value) {
        person.name.errorCode = 'required';
        noErrors = false;
      }
      else if (!isValidCif(person.docNumber.value)) {
        person.docNumber.errorCode = 'invalid-format';
        noErrors = false;
      }
      if (!person.docNumber.value) {
        person.docNumber.errorCode = 'required';
        noErrors = false;
      }
      if (!person.responsable.value) {
        person.responsable.errorCode = 'required';
        noErrors = false;
      }
    }

    if (noErrors) {
      if (this.props.onValidated) {
        if (person.physicalPerson) {
          if (person.docType.value === 'MENOR' && !person.docNumber.value && this.props.insureds) {
            let minorSequencial = 0;
            this.props.insureds.map((insured: any) => {
              if (insured.docType === 'MENOR' && insured.docNumber.length < 2) {
                if (insured.docNumber > minorSequencial) {
                  minorSequencial = insured.docNumber;
                }
              }
            });
            person.docNumber.value = String(Number(minorSequencial) + 1);
            person.docNumber.disabled = true;
          }

          this.props.onValidated(
            {
              name: person.name.value,
              lastname1: person.lastName1.value,
              lastname2: person.lastName2.value,
              birthDate: moment(person.birthDate.value, "DD/MM/YYYY").utcOffset(0, true).toDate(),
              docType: person.docType.value,
              docNumber: person.docNumber.value,
              passportDate: moment(person.passportDate.value, "DD/MM/YYYY").toDate(),
              gender: person.sex.value,
              nationality: person.nationality.code,
              nationalityLabel: person.nationality.value,
              civilStatus: person.civilStatus.value,
              profession: {
                value: person.profession.value,
                code: person.profession.code,
                other: person.profession.other.value
              },
              sameAsTaker: person.sameAsTaker,
              physicalPerson: person.physicalPerson,
              tc2: person.tc2.value,
              reta: person.reta.value,
              responsable: person.responsable.value,
              hasComeAnotherInsurance: person.hasComeAnotherInsurance
            }
          );
          if (person.sameAsTaker && this.props.taker && this.props.takerAnotherInsuranceChange) {

            let updatedTake = this.props.taker;
            updatedTake.hasComeAnotherInsurance = person.hasComeAnotherInsurance;
            this.props.takerAnotherInsuranceChange(updatedTake)
          }
        }
        else {
          this.props.onValidated(
            {
              name: person.name.value,
              docNumber: person.docNumber.value,
              sameAsTaker: person.sameAsTaker,
              lastname1: "",
              lastname2: "",
              birthDate: new Date(),
              civilStatus: "",
              docType: 'CIF',
              passportDate: new Date(),
              gender: "",
              nationality: "",
              nationalityLabel: "",
              profession: {
                value: "",
                code: "",
                other: ""

              },
              physicalPerson: false,
              tc2: person.tc2.value,
              reta: person.reta.value,
              responsable: person.responsable.value,
              hasComeAnotherInsurance: false
            }
          );
        }
      }
    }

    this.setState({ person });
    return noErrors;
  }

  cleanForm() {
    this.setState({
      person: DefaultPersonalData(),
      stateDisabled: false,
      previousData: DefaultPersonalData(),
      docTypes: DOC_TYPES.insuredsHealth
    });
  }

  completeInsuredData(personData?: PersonalData, isTakerData: boolean = false) {
    let { person, docTypes, prevDocType } = this.state;
    person = this.getPersonalDataDto(personData, personData ? personData.sameAsTaker ? personData.sameAsTaker : isTakerData : false);

    if (isTakerData) {
      person.sameAsTaker = isTakerData;
    }

    docTypes = this.getDocTypes(person.birthDate.value);
    person.docType = docTypes.find(element => element.value === person.docType.value) ? person.docType : this.getValueFormHealth(undefined, false);
    prevDocType = person.docType.value;

    this.setState({ person, docTypes, prevDocType });
  }

  completeFormTakerData(person: PersonalDataDto) {
    let getPreviousData = (person: PersonalDataDto) => {
      const previousData: PersonalDataDto = JSON.parse(JSON.stringify(person));
      previousData.birthDate.errorCode = "";
      previousData.name.errorCode = "";
      previousData.lastName1.errorCode = "";
      previousData.lastName2.errorCode = "";
      previousData.birthDate.errorCode = "";
      previousData.docNumber.errorCode = "";
      previousData.docType.errorCode = "";
      previousData.sex.errorCode = "";
      previousData.nationality.errorCode = "";
      previousData.civilStatus.errorCode = "";
      previousData.profession.errorCode = "";
      previousData.profession.other.errorCode = "";
      previousData.passportDate.errorCode = "";
      previousData.responsable.errorCode = "";
      return previousData;
    }
    let { taker } = this.props;
    let { docTypes, prevDocType } = this.state;

    let previousData = getPreviousData(person);
    if (taker) {
      if (!this.props.insured) {
        if (person.physicalPerson) {
          this.props.handlePersonChange('physical');
        }
        else {
          this.props.handlePersonChange('legal');
        }
      }

      taker.profession.value = previousData.profession.value;
      taker.profession.other = previousData.profession.other.value;
      taker.hasComeAnotherInsurance = previousData.hasComeAnotherInsurance;
      person = this.getPersonalDataDto(taker, this.props.insured ? this.props.insured : false);
      person.sameAsTaker = true;

      docTypes = this.getDocTypes(person.birthDate.value);
      person.docType = docTypes.find(element => element.value === person.docType.value) ? person.docType : this.getValueFormHealth(undefined, false);
      prevDocType = person.docType.value

      this.setState({ person, previousData, docTypes, prevDocType });
    }
  }

  handleCheckTakerChange() {
    let { person } = this.state;
    person.sameAsTaker = !person.sameAsTaker;
    this.props.onCheckTakerData(person.sameAsTaker);

    if (person.sameAsTaker) {
      this.completeFormTakerData(person);
    }
    else {
      this.getPreviousData(person);
    }
  }

  getPreviousData(person: PersonalDataDto) {
    let { previousData, docTypes, prevDocType } = this.state;

    person.name = previousData.name;
    person.lastName1 = previousData.lastName1;
    person.lastName2 = previousData.lastName2;
    person.birthDate = previousData.birthDate;
    person.docType = previousData.docType;
    person.docNumber = previousData.docNumber;
    person.sex = previousData.sex;
    person.nationality = previousData.nationality;
    person.civilStatus = previousData.civilStatus;
    person.profession = previousData.profession;
    person.passportDate = previousData.passportDate;
    person.tc2 = previousData.tc2;
    person.reta = previousData.reta;
    person.responsable = previousData.responsable;
    person.hasComeAnotherInsurance = previousData.hasComeAnotherInsurance
    previousData = DefaultPersonalData();

    docTypes = this.getDocTypes(person.birthDate.value);
    person.docType = docTypes.find(element => element.value === person.docType.value) ? person.docType : this.getValueFormHealth(undefined, false);
    prevDocType = person.docType.value;

    this.setState({ previousData, person, docTypes, prevDocType });
  }

  changeValueSearchBox(ev: any, arg1: string, arg2: string) {
    if (this._searchWaiter) {
      clearInterval(this._searchWaiter);
    }
    const { person } = this.state;
    const value: string = ev.target.value;

    // Reset searchBox values to default if delete prev data
    if (value.length < 1) {
      if (this.state.person.hasOwnProperty(arg2) && arg2 === "profession") {
        person.profession = DefaultProfessionData();
      }
      if (this.state.person.hasOwnProperty(arg2) && arg2 === "nationality") {
        person.nationality = DefaultValueFormHealth();
        person.nationalityLabel = '';
      }
      this.setState({ person });
    }

    if (value.length < 2) return;

    if (value.length >= 2) {
      switch (arg2) {

        case "profession":
          this.setState({
            professionsFiltered: this.state.professionsList.filter((prefession) =>
              prefession.label
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            ),
          });
          break;
        case "nationality":
          this.setState({
            countriesFiltered: this.state.countries.filter((country) =>
              country.label
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            ),
          });
          break;
        default:
          break;
      }
    }
  }

  isFirstInsured = (): boolean => this.props.insuredIndex === 0 || (this.props.insuredIndex === -1 && (!this.props.insureds || this.props.insureds.length === 0));

  render() {
    const { person, professionsList, countries, docTypes, countriesFiltered, professionsFiltered } = this.state;
    const { isReadOnly } = this.props;
    return (

      <PersonalInformationContainer>
        <div className="content-wrapper bottomMargin20" ref={''}>
          <div className="personal-data-wrapper">
            <div className="data-title" id="data-personal">
              <h5>DATOS PERSONALES</h5>
            </div>
            {(this.props.currentHealthStep === 2 && this.props.taker && this.props.taker.physicalPerson && this.isFirstInsured() &&
              <div className="bottomMargin20">
                <CheckboxItemLayout
                  name='Los datos personales son los mismos que los del tomador'
                  checked={person.sameAsTaker}
                  onChange={() => this.handleCheckTakerChange()}
                  showError={false}
                  checkboxText='El tomador de la póliza es el asegurado'
                  errorText='Error'
                  disabled={isReadOnly}
                />
              </div>)}
            {(this.props.currentHealthStep === 1 && <div className="receip-radio-main-row">
              <div className={isReadOnly ? "radioBox disabled" : "radioBox"}>
                <label>
                  <input
                    type="radio"
                    disabled={isReadOnly}
                    name="person"
                    value={'personal'}
                    onChange={() => {
                      const { person } = this.state;
                      this.props.handlePersonChange('physical');
                      person.lastName1.value = (person.lastName1.value === undefined ? '' : person.lastName1.value);
                      person.physicalPerson = true;
                      this.setState({ person });
                    }}
                    checked={person.physicalPerson}
                  />
                  <span>PERSONA FÍSICA</span>
                </label>
              </div>
            </div>)}
            <div className='personal-data-fisica'>
              <div className="personal-data-row">
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Nombre"
                      required={true}
                      errorCode={person.name.errorCode}
                      disabled={person.name.disabled || isReadOnly}
                      value={person.name.value}
                      onChange={(value: string) => this.handleValueChange('name', value)}
                      type={"letters"}
                    />
                  </div>
                </div>

                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Apellido 1"
                      required={true}
                      errorCode={person.lastName1.errorCode}
                      disabled={person.lastName1.disabled || isReadOnly}
                      value={person.lastName1.value}
                      onChange={(value: string) => this.handleValueChange('lastName1', value)}
                      type={"letters"}
                    />
                  </div>
                </div>
              </div>

              <div className="personal-data-row">
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <InputBox
                      placeholder="Apellido 2"
                      required={false}
                      errorCode={person.lastName2.errorCode}
                      disabled={person.lastName2.disabled || isReadOnly}
                      value={person.lastName2.value}
                      onChange={(value: string) => this.handleValueChange("lastName2", value)
                      }
                      type={"letters"}
                    />
                  </div>
                </div>

                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <CalendarBox
                      initialValue={person.birthDate.value}
                      placeholder="Fecha de nacimiento"
                      forbidFutureDates
                      minAge={this.props.insured ? 0 : 18}
                      maxDate={moment().toISOString()}
                      required={true}
                      disabled={person.birthDate.disabled || isReadOnly}
                      errorCode={person.birthDate.errorCode}
                      onChange={(value: string) => this.handleValueChange('birthDate', value)}
                      health={true}
                    />
                  </div>
                </div>
              </div>

              <div className="personal-data-row">
                <div className="personal-data-item col-50">

                  <DicotomicButton
                    required={true}
                    labelText="Sexo"
                    options={SEX}
                    withTab={true}
                    disabled={person.sex.disabled || isReadOnly}
                    initialValue={person.sex.value}
                    onChange={(value: string) => this.handleValueChange('sex', value)}
                    errorCode={person.sex.errorCode}
                  />

                </div>
                <div className="personal-data-item col-50">
                  <div className="inputBox">
                    <SearchBox
                      onChange={(ev: any) =>
                        this.changeValueSearchBox(
                          ev,
                          "contact",
                          "nationality"
                        )
                      }
                      labelText=""
                      optionsText={countriesFiltered}
                      optionKey={"value"}
                      optionValue={"label"}
                      disabled={person.nationality.disabled || isReadOnly}
                      initialValue={person.docType.value === 'NIF' ? person.nationality.value || '' : ""}
                      required={true}
                      errorCode={person.nationality.errorCode}
                      onSelect={(value: any) =>
                        this.handleValueChange("nationality", value)
                      }
                    />
                    <label>Nacionalidad</label>
                  </div>
                </div>
              </div>

              <div className="personal-data-container">
                <div className="personal-data-row">
                  <div className="personal-data-item col-50">
                    <div className="selectBox">
                      <SelectBox
                        placeholder="Documento de identidad"
                        required={true}
                        errorCode={person.docType.errorCode}
                        optionsText={this.props.currentHealthStep !== 1 ? docTypes : DOC_TYPES.taker}
                        optionKey={"value"}
                        optionValue={"label"}
                        initialValue={person.docType.value}
                        defaultValue={person.docType.value}
                        disabled={person.docType.disabled || isReadOnly}
                        onChange={(value: string) => this.handleValueChange('docType', value)}
                      />
                      <label>{"Documento de identidad"}</label>
                    </div>
                  </div>
                  <div className="personal-data-item col-50">
                    <div className="inputBox">
                      <InputBox
                        placeholder="Nº de documento"
                        required={person.docType.value !== 'MENOR'}
                        errorCode={person.docNumber.errorCode}
                        maxLength={9}
                        value={person.docType.value !== 'MENOR' ? person.docNumber.value : ''}
                        disabled={person.docNumber.disabled || person.docType.value === 'MENOR' || isReadOnly}
                        onChange={(value: string) => this.handleValueChange('docNumber', value)}
                        type={person.docType.value === 'NIF' ?
                          "NIF" : person.docType.value === 'NIE' ?
                            "NIE" : person.docType.value === 'PASP' ?
                              "PAS" : "NIF"}
                      />
                    </div>
                  </div>

                  {(person.docType.value === 'PASP') &&
                    <div className="personal-data-item col-50">
                      <div className="inputBox">
                        <CalendarBox
                          initialValue={person.passportDate.value}
                          placeholder="Fecha de caducidad del pasaporte"
                          minDate={moment().toISOString()}
                          required={true}
                          disabled={person.passportDate.disabled || isReadOnly}
                          errorCode={person.passportDate.errorCode}
                          onChange={(value: string) => this.handleValueChange('passportDate', value)}
                          health={true}
                        />
                      </div>
                    </div>
                  }

                </div>
                {this.props.insured && (
                  <div className="personal-data-row">
                    <div className="personal-data-item col-50">
                      <div className="selectBox">
                        <SearchBox
                          onChange={(ev: any) =>
                            this.changeValueSearchBox(
                              ev,
                              "occupation",
                              "profession",
                            )
                          }
                          labelText=""
                          required={false}
                          errorCode={person.profession.errorCode}
                          optionsText={professionsFiltered}
                          optionKey={"value"}
                          optionValue={"label"}
                          initialValue={person.profession.value}
                          onSelect={(value: any) =>
                            this.handleValueChange("profession", value)
                          }
                          disabled={person.profession.disabled || isReadOnly}
                        />
                        <label>{"Profesión"}</label>
                      </div>
                    </div>
                    <div className="personal-data-item col-50">
                      <DicotomicButton
                        required={true}
                        labelText="¿El asegurado viene de otra aseguradora?"
                        options={YesNo}
                        disabled={isReadOnly}
                        withTab={true}
                        initialValue={person.hasComeAnotherInsurance}
                        onChange={(value: boolean) => this.handleValueChange('hasComeAnotherInsurance', value)}
                      />
                    </div>
                  </div>
                )}

              </div>
              <div className="personal-data-container">
                <div className="personal-data-row">
                  <div className="personal-data-item col-50">
                    <div className="selectBox">
                      <SelectBox
                        placeholder="Estado civil"
                        optionsText={GIP_CIVIL_STATUS}
                        disabled={person.civilStatus.disabled || isReadOnly}
                        optionKey={"value"}
                        optionValue={"label"}
                        initialValue={person.civilStatus.value}
                        onChange={(value: string) => this.handleValueChange('civilStatus', value)}
                      />
                      <label>Estado Civil</label>
                    </div>
                  </div>
                  <div className="personal-data-item col-50">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PersonalInformationContainer>
    )
  }
}

export default PersonalInformation;
