import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import { TravelHireMenuHelper, StepsTravel } from "../../../../../components/travel-hire-menu/travel-hire-menu";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import InputBox from "../../../../../components/input-box/input-box";
import LeftInfoModal from "../../../../../components/left-info-modal";
import MainButton from "../../../../../components/main-button";
import SelectBox from "../../../../../components/select-box/select-box";
import { YES_NO_CONSTANT } from "../../../../../constants";
import { IDataProtectionMethod } from "../../../../../constants/health";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { ValueForm } from "../../../../../utils/interfaces";
import { TravelProtectionDataContainer } from "./travel-protection-data-style";


export interface TravelIProtectionData {
  consentDate: ValueForm;
  dataTransfer: ValueForm;
  thirdParty: ValueForm;
  thirdPartyCalls: ValueForm;
  isReadOnly: boolean;
}

interface TravelIProtectionDataState {
  RGPD_tomador_nuevo: boolean;
  protectionData: TravelIProtectionData;
  canPostProtectionData: boolean;
  modalErrorShow: boolean;
  modalErrorText: string;
  cancelSignModalShow: boolean;
  anyChange: boolean;
}

interface TravelIProtectionDataProps extends RouteComponentProps {
  history: any;
  newStep: Function;
  goBack: Function;
  policy: any;
  data: any;
  setLoading: any;
  cotizacion: string;
  saveHiring: Function;
  removeSign: Function;
  match: any;
  readOnly: boolean;
}

class TravelProtectionDataLayout extends React.Component<
TravelIProtectionDataProps,
TravelIProtectionDataState
> {

  constructor(props: TravelIProtectionDataProps) {
    super(props);

    this.state = {
      protectionData: {
        consentDate: {
          value: props.data.protectionData ? props.data.protectionData.consentDate : moment().format('DD/MM/YYYY').toString(),
          error: false,
          errorCode: '',
          required: false,
          disabled: true,
        },
        dataTransfer: {
          value: props.data.protectionData ? props.data.protectionData.dataTransfer : '',
          error: false,
          errorCode: '',
          required: true,
          disabled: false,
        },
        thirdParty: {
          value: props.data.protectionData ? props.data.protectionData.thirdParty : '',
          error: false,
          errorCode: '',
          required: true,
          disabled: false,
        },
        thirdPartyCalls: {
          value: props.data.protectionData ? props.data.protectionData.thirdPartyCalls : '',
          error: false,
          errorCode: '',
          required: true,
          disabled: false,
        },
        isReadOnly: (props.policy && props.policy.data && props.policy.data.isReadOnly) || props.readOnly || false,
      },
      canPostProtectionData: false,
      modalErrorShow: false,
      modalErrorText: "",
      RGPD_tomador_nuevo: props.data.RGPD_tomador_nuevo ? props.data.RGPD_tomador_nuevo : true,
      cancelSignModalShow: false,
      anyChange: false,
    };
  }

  async componentDidMount() {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);

    let { data } = this.props;

    this.setState({ canPostProtectionData: data.RGPD_tomador_nuevo })

  }

  assignProtectionResponse(response: any) {
    let { protectionData } = this.state;
    protectionData = {
      ...protectionData,
      consentDate: { ...protectionData.consentDate, value: response.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS.FECHA_CONSENTIMIENTO },
      dataTransfer: { ...protectionData.dataTransfer, value: response.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS.CESION_DATOS },
      thirdParty: { ...protectionData.thirdParty, value: response.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS.PUBLICIDAD_ESCRITA_TERCEROS },
      thirdPartyCalls: { ...protectionData.thirdPartyCalls, value: response.SOLICITUD_PASO_PROTECCION_DATOS.PROTECCION_DATOS.PUBLICIDAD_TELEFONICA_TERCEROS }
    }
    this.setState({ protectionData })
  }

  async postProtectionData(): Promise<boolean> {
    const { protectionData } = this.state;

    const request = this.createProtectionDataRequest(protectionData, this.props.cotizacion);

    return true
  }

  createProtectionDataRequest(protectionData: TravelIProtectionData, cotizacion: string): IDataProtectionMethod {
    return {
      COTIZACION: cotizacion,
      PROTECCION_DATOS: {
        CANCELACION_DATOS: 'N',
        CESION_DATOS: protectionData.dataTransfer.value,
        FECHA_CONSENTIMIENTO: protectionData.consentDate.value,
        OBSERVACIONES: '',
        PUBLICIDAD_ESCRITA_PROPIOS: 'S',
        PUBLICIDAD_ESCRITA_TERCEROS: protectionData.thirdParty.value,
        PUBLICIDAD_TELEFONICA_PROPIOS: 'S',
        PUBLICIDAD_TELEFONICA_TERCEROS: protectionData.thirdPartyCalls.value !== '' ? protectionData.thirdPartyCalls.value : '-',
        RECEPCION_COMUNICACION: 'EMAIL',
        RED_COMERCIAL: 'N',
        ROBINSON: 'N',
        TIPO_CONSENTIMIENTO: 'GDPR'
      }
    }
  }

  changeValue(value: string, type: string) {
    let { protectionData, anyChange } = this.state;
    switch (type) {
      case 'dataTransfer':
        protectionData.dataTransfer.value === value ? anyChange = false : anyChange = true;
        protectionData.dataTransfer.value = value;
        protectionData.dataTransfer.errorCode = '';
        break;
      case 'thirdParty':
        protectionData.thirdParty.value === value ? anyChange = false : anyChange = true;
        protectionData.thirdParty.value = value;
        protectionData.thirdParty.errorCode = '';
        break;
      case 'thirdPartyCalls':
        protectionData.thirdPartyCalls.value === value ? anyChange = false : anyChange = true;
        protectionData.thirdPartyCalls.value = value;
        protectionData.thirdPartyCalls.errorCode = '';
        break;
      default:
        break;
    }
    this.setState({ protectionData, anyChange });
  }

  async toValidate() {
    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true) {
      this.setState({ cancelSignModalShow: true })
      return false;
    }
    else if (this.props.readOnly) {
      this.props.history.push(TravelHireMenuHelper.getNextStepNavigationPath(StepsTravel.ProteccionDatos, this.props.match.params.product))
    }
    else {
      await this.validateAndSave()
    }
  }

  async validateAndSave() {

    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true)
      this.props.removeSign()

    let { protectionData, canPostProtectionData } = this.state;
    let { data } = this.props;
    let error = false;
    if (protectionData.consentDate.value === '') {
      error = true;
      protectionData.consentDate.errorCode = 'required';
    }
    if (protectionData.dataTransfer.value === '') {
      error = true;
      protectionData.dataTransfer.errorCode = 'required';
    }
    if (protectionData.thirdParty.value === '') {
      error = true;
      protectionData.thirdParty.errorCode = 'required';
    }

    if (!error) {
  
        this.props.policy.setProtectionData(protectionData);
        await this.props.saveHiring();
        this.props.history.push(TravelHireMenuHelper.getNextStepNavigationPath(StepsTravel.ProteccionDatos, this.props.match.params.product))
    } else
      this.setState({ protectionData })
  }

  hideErrorModal = () => this.setState({ modalErrorShow: false, modalErrorText: "" });

  render() {
    let { protectionData, canPostProtectionData, cancelSignModalShow } = this.state;

    return (
      <TravelProtectionDataContainer>
        <div className="content-wrapper">
          <div className="personal-data-wrapper">
            <div className="data-flow-status">
              <div className="data-flow-status-title">
                <h2>PROTECCIÓN DE DATOS</h2>
              </div>
            </div>
            <div className="personal-data-container">
              <div className="personal-data-row">
                <div className="personal-data-item col-33">
                  <div className="inputBox">
                    <InputBox
                      type={"text"}
                      placeholder="Fecha de consentimiento"
                      value={protectionData.consentDate.value}
                      disabled={!canPostProtectionData || protectionData.consentDate.disabled || protectionData.isReadOnly}
                      required={protectionData.consentDate.required}
                    />
                  </div>
                </div>

                <div className="personal-data-item col-33">
                  <div className="selectBox">
                    <SelectBox
                      placeholder="Seleccionar"
                      required={protectionData.dataTransfer.required}
                      errorCode={protectionData.dataTransfer.errorCode}
                      disabled={protectionData.isReadOnly}
                      optionsText={YES_NO_CONSTANT}
                      optionKey={"value"}
                      optionValue={"label"}
                      initialValue={protectionData.dataTransfer.value}
                      defaultValue={protectionData.dataTransfer.value}
                      onChange={(value: string) => {
                        this.changeValue(value, "dataTransfer");
                      }}
                    />
                    <label>{"Cesión de datos"}</label>
                  </div>
                </div>
              </div>

              <div className="data-title">
                <h5>ENVÍO DE PUBLICIDAD ESCRITA</h5>
              </div>

              <div className="personal-data-row">

                <div className="personal-data-item col-33">
                  <div className="selectBox">
                    <SelectBox
                      placeholder="Seleccionar"
                      required={protectionData.thirdParty.required}
                      errorCode={protectionData.thirdParty.errorCode}
                      disabled={protectionData.isReadOnly}
                      optionsText={YES_NO_CONSTANT}
                      optionKey={"value"}
                      optionValue={"label"}
                      initialValue={protectionData.thirdParty.value}
                      defaultValue={protectionData.thirdParty.value}
                      onChange={(value: string) => {
                        this.changeValue(value, "thirdParty");
                      }}
                    />
                    <label>{"Envío de publicidad de terceros"}</label>
                  </div>
                </div>

              </div>

              <div className="data-title">
                <h5>ACCIÓN COMERCIAL POR TELÉFONO</h5>
              </div>

              <div className="personal-data-row">
                <div className="personal-data-item col-33">
                  <div className="selectBox tooLongLabelHandler">
                    <SelectBox
                      placeholder="Seleccionar"
                      errorCode={protectionData.thirdPartyCalls.errorCode}
                      disabled={protectionData.isReadOnly}
                      optionsText={[...YES_NO_CONSTANT, { value: '-', label: 'Seleccionar' }]}
                      optionKey={"value"}
                      optionValue={"label"}
                      initialValue={protectionData.thirdPartyCalls.value}
                      defaultValue={protectionData.thirdPartyCalls.value}
                      onChange={(value: string) => {
                        this.changeValue(value, "thirdPartyCalls");
                      }}
                    />
                    <label>{"Cesión de llamadas promocionando productos de terceros"}</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="personal-data-container">
              <div className="personal-data-row">
                <div className="personal-data-buttons">
                  <div className="personal-data-buttons__item">
                    <MainButton
                      withTab={true}
                      icon={true}
                      type={"white"}
                      iconImg={images.IconArrowLeftBlue}
                      text="Atrás"
                      onClick={() => this.props.history.push(TravelHireMenuHelper.getPreviousStepNavigationPath(StepsTravel.ProteccionDatos, this.props.match.params.product))}
                    />
                  </div>
                  <div className="personal-data-buttons__item">
                    <MainButton
                      withTab={true}
                      text="Siguiente"
                      onClick={() => this.toValidate()}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={this.hideErrorModal} />
            </div>
          </div>
        </ErrorModalContainer>
        <LeftInfoModal
          img={images.IconWarningYellow}
          className="error"
          mainTitle={"¿Seguro que desea continuar?"}
          mainText={"Se cancelará la firma previamente solicitada"}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ cancelSignModalShow: false })}
          active={cancelSignModalShow}
          onClickAccept={() => {
            this.setState({ cancelSignModalShow: false }, () => this.validateAndSave())
          }}
        />
      </TravelProtectionDataContainer>
    );
  }

}

export default withPolicy(withGeneral(withRouter(TravelProtectionDataLayout)));
