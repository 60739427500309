import styled from "styled-components";
import { colors } from "../../../../assets";
import { NoteTextContainer } from "../../../../components/components-text/note-text/note-text-styles";

export const HiringHealthDeclarationResultPendantContainer = styled.div`
  .health-declaration-result-back {
    margin-bottom: 95px;
  }

  .health-declaration-result-footer {
    padding-top: 37px;
    margin-bottom: 130px;
    color: ${colors["PR-001-100"]};

    ${NoteTextContainer} {
      line-height: 1.5em;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .health-declaration-result-pagination {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
    margin-top: 20px;
  }

  .health-declaration-container {
    background: rgba(179, 202, 220, 0.08);
    padding: 60px;
    font-family: "museo sans";
  }

  .health-declaration-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 40px;
  }

  .health-declaration-measures-container {
    display: flex;
    margin-bottom: 48px;
  }

  .health-declaration-measures-item {
    margin-right: 60px;
    color: ${colors["TX-001-50"]};
    font-weight: 500;

    &:last-child {
      margin-right: 0;
    }

    span {
      margin-left: 12px;
      color: ${colors["TX-001-100"]};
    }
  }

  .health-declaration-question-container {
  }

  .health-declaration-question-row {
    margin-bottom: 50px;
    display: flex;
    align-items: flex-start;

    &:last-child {
      margin-bottom: 0;
    }
  }


  .health-declaration-question-row-giptitle {
    margin-bottom: 25px;
    display: flex;
    align-items: flex-start;

  }

  .health-declaration-question-row-gip {
    margin-bottom: 50px;
    display: flex;
    align-items: flex-start;

    &:last-child {
      margin-bottom: 100px;
    }

  }

  .health-declaration-question-information {
    margin-right: 47px;
  }

  .health-declaration-question-statement {
    color: ${colors["TX-001-100"]};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
    padding-left: 16px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 7px;
      left: 0;
      background: ${colors["PR-001-100"]};
      border-radius: 50%;
      width: 6px;
      height: 6px;
    }
  }
  
  .health-declaration-question-response-container {
    padding-left: 16px;
  }

  .health-declaration-question-response-item {
    margin-bottom: 22px;

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      margin-bottom: 8px;
      color: ${colors["TX-001-30"]};
      text-transform: uppercase;
    }

    &__titlegip {
      font-size: 18px;
      margin-bottom: 8px;
      color: ${colors["TX-001-30"]};
      text-transform: uppercase;
    }

    &__text {
      color: ${colors["TX-001-100"]};
    }
  }

  .health-declaration-question-status {
    background: #091e42;
    color: #fff;
    padding: 6px 18px;
    border-radius: 3px;
    text-transform: uppercase;

    p {
      color: #fff;
    }
  }

  .no-questionnaire {
    display: flex;
    width: 100%;
    padding: 10%;
    z-index: 2;
    justify-content: space-between;
  }

  .no-questionnaire-text {
    display: flex;
    width: 80%
    padding: 1px;
    vertical-align: middle;
    * {
      text-align: center
    }
  }

  .no-questionnaire-icon {
    display: flex;
    width:20%
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    img {
      margin-left: auto;
      margin-right: auto;
      max-width: 40px;
      vertical-align: middle;
    }
  }
`;
