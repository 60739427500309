import styled from "styled-components";
import { colors } from "../../../assets";

export const ClientsPoliciesContainer = styled.div`

  * {
    font-family: museo sans, sans-serif;
  }
  
.col-1 {width: 8.33% !important;}
.col-2 {width: 16.66%; !important}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}



  h1 {
    font-size: 36px;
    color: #004a8f;
    margin: 10px 0 20px;
}

  h2 {
      font-size: 32px;
      color: #004a8f;
      margin: 10px 0 20px;

  }

  h3 {
      font-size: 28px;
      color: #3f3f3f;
      margin: 10px 0 20px;

  }

  h4 {
      font-size: 24px;
      color: #3f3f3f;
      margin: 10px 0 20px;

  }

  h5 {
      font-size: 22px;
      color: #3f3f3f;
      font-weight: normal;
      margin: 10px 0 20px;

  }

  h6 {
      font-size: 20px;
      color: #3f3f3f;
      font-weight: normal;
      margin: 10px 0 20px;

  }

  .back-arrow-container {
    margin-bottom: 18px;
  }
  .main-title-container {
    margin-bottom: 38px;
    color: ${colors["PR-001-100"]};
  }

  .main-results-number {
    color: ${colors["TX-001-100"]};
    margin-bottom: 40px;
    span {
      font-weight: 600;
    }
  }

  .client-policies-table {
    margin-bottom: 20px;
    min-width: 100%;
    max-width: 1250px;
    overflow-x: auto;
  }

  .client-policies-pagination {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 75px;
  }

  .download-button{
    background: ${colors["PR-001-100"]};
    color: white;
    min-width: 160px;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    appearance: none;
    border-radius: 4px;
    border: none;

    &:hover {
      cursor: pointer;
      background: ${colors["HV-Text"]}; 
    }
  }

  .recover-option {
    -webkit-appearance: checkbox;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    display: block;
    width: 16px;
    left: 0;
    top: -2px;
    bottom: 0;
    height: 16px;
    z-index: 99;
  }

  .radio-container {
    font-size: 1vw;
    color: ${colors["PR-001-100"]};
    text-transform: uppercase;
    position: relative;
    padding-left: 35px;
    display: flex;

    .radio-content{
      top: -2px;
      position: relative;
    }

    .receipt-option {
      -webkit-appearance: checkbox;
      position: relative;
      opacity: 0;
      cursor: pointer;
      display: block;
      width: 16px;
      left: 20px;
      top: -2px;
      bottom: 0;
      height: 16px;
      z-index: 99;
    }

    .recover-checkmark {
      position: relative;
      top: -2px;
      left: 0;
      height: 15px;
      width: 15px;
      border: 1px solid ${colors["PR-001-100"]};
      border-radius: 50%;

      &:hover {
        background: ${colors["PR-001-30"]};
      }
    }

    .receipt-option:checked ~ .recover-checkmark {
      background: ${colors["PR-001-100"]};
    }

    .recover-checkmark:after {
      content: "";
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 6px;
      height: 6px;
      border-radius: 70%;
      background: white;
    }

    .receipt-option:checked ~ .recover-checkmark:after {
      display: block;
    }
  }

  .main-filter-container {
    width: 100%;
    margin-bottom: 15px;
    background-color: #fbfbfb; 
    padding: 20px;
  }

  .policy-radio-main-row {
    margin-bottom: 20px;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
    font-family: "museo sans";
  }

  .main-filter-second-row{
    margin-top: 40px;
    display: flex;
    width: 100%;
  }

  .main-filter-third-row{
    display: flex;
  }

  .download-filter-error {
    margin-top: 8px;
    color: ${colors["ER-001-100"]};

    p {
      color: ${colors["ER-001-100"]};
    }
  }

  .filter-divider{
    color: ${colors["PR-001-100"]};
  }

  .main-filter-item {
    max-width: 20%;
    margin-right: 25px;

    &.first{
      margin-left: 5%;
    }

    &--select {
      max-width: 225px;
      margin-right: 25px;
      &.first{
        margin-right: 25px;      
      }
      &:last-child {  
        margin-right: 0px;
      }
    }
  }

`;
