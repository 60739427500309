import styled, { css } from 'styled-components';

export const Menu02TextContainer = styled.div`
    font-family: "museo sans";
    font-weight: 700;
    font-size: ${props => props.fontSizeSmall ? '10px' : '14px'};
    line-height: 17px;

    
    ${props => css`
        color: ${props.color}
    `}




`;