import styled from "styled-components";
import { colors } from "../../../../assets";

export const CampaignsPrizeCardContainer = styled.div`
  &.active {
    .campaigns-card {
      background: #ffffff;
      opacity: 1;
    }

    .campaigns-card-status {
      background: #c4c4c4;
    }
  }

  &.error {
    .campaigns-card-status {
      background: ${colors["ER-001-100"]};
    }
  }

  .campaigns-card {
    position: relative;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    color: ${colors["PR-001-100"]};
    background: rgba(179, 202, 220, 0.15);
  }

  .campaigns-card-status {
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 50%;
    display: block;
    width: 6px;
    height: 6px;
    background: ${colors["SU-001-100"]};
  }

  .campaigns-card-name {
    margin-bottom: 8px;
  }

  .campaigns-card-type {
    font-family: "museo sans";
    font-weight: 300;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }

  .campaigns-card-details {
    /* position: absolute;
    bottom: 8px;
    right: 34px; */
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .campaigns-card-erase {
    /* position: absolute;
    bottom: 6px;
    right: 8px; */
    margin-left: 8px;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .campaigns-card-buttons {
    /* width: 32px; */
    display: flex;
    align-items: center;
  }
`;
