import React from 'react';
import packageJson from '../package.json';
import { colors } from './assets';
import { version } from 'process';
global.appVersion = packageJson.version;

const background = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo oscuro semitransparente
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999
};

const modal = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '80%', // Ajusta el ancho según sea necesario
    maxWidth: '400px', // Ancho máximo
};

const buttonContainerStyle = {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-around',
};

const buttonStyle = {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
};

const primaryButtonStyle = {
    ...buttonStyle,
    backgroundColor: colors["PR-001-100"],
    color: 'white',
};

const secondaryButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#6c757d',
    color: 'white',
};
// version from response - first param, local version second param
const isDifferent = (a, b) => {
    if (a === b) {
        return false;
    }

    var a_components = a.split(".");
    var b_components = b.split(".");

    var len = Math.min(a_components.length, b_components.length);

    // loop while the components are equal
    for (var i = 0; i < len; i++) {
        // A bigger than B
        if (parseInt(a_components[i]) > parseInt(b_components[i])) {
            return true;
        }

        // B bigger than A
        if (parseInt(a_components[i]) < parseInt(b_components[i])) {
            return true;
        }
    }

    // If one's a prefix of the other, the longer one is greater.
    if (a_components.length > b_components.length) {
        return true;
    }

    if (a_components.length < b_components.length) {
        return true;
    }

    // Otherwise they are the same.
    return false;
};

class ClearCache extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: () => {

                const currentVersion = global.appVersion;
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function (names) {
                        for (let name of names) caches.delete(name);
                    });
                }
                localStorage.setItem("version", currentVersion)
                // delete browser cache and hard reload
                window.location.reload(true);

            },
            addDataIntoCache: (cacheName, url, response) => {
                // Converting our response into Actual Response form
                const data = new Response(JSON.stringify(response));
                console.log(data);
                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open(cacheName).then((cache) => {
                        cache.put(url, data);
                        alert("Data Added into cache!");
                    });
                }
            }
        };
    }

    componentDidMount() {

        const version = localStorage.getItem("version")
        const versionA = global.appVersion

        if (!version || isDifferent(versionA, version)) {
            fetch('/meta.json')
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersion = meta.version;
                    const currentVersion = global.appVersion;
                    const shouldForceRefresh = isDifferent(latestVersion, currentVersion);
                    if (shouldForceRefresh) {
                        this.setState({ loading: false, isLatestVersion: false });
                    } else {
                        this.setState({ loading: false, isLatestVersion: true });
                    }
                });
        }
    }
    render() {
        const { loading, isLatestVersion, refreshCacheAndReload, addDataIntoCache } = this.state;
        if (loading) return null;
        if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            return <div style={background}>
                <div style={modal}>
                    <h2>Nueva versión disponible</h2>
                    <p>Se ha encontrado una nueva versión y es necesario refrescar la página</p>
                    <div style={buttonContainerStyle}>
                        <button style={primaryButtonStyle} onClick={() => refreshCacheAndReload()}>Actualizar</button>
                    </div>
                </div>
            </div>
        }
        return null
    }
}

export default ClearCache;