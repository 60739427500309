export { navSimulationItems, navHiringItem, navClientsItem } from "./navLinks";

export const RISK_ACTIVITY = [
  { code: "Y", label: "Si" },
  { code: "N", label: "No" }
];

export const LABEL_SEX: any = {
  M: "Mujer",
  H: "Hombre"
};

export const LABEL_TYPE_PETS: any = {
  canine: "Perro",
  feline: "Gato"
};

export const YES_NO_CONSTANT = [
  { value: "S", label: "Si" },
  { value: "N", label: "No" },
];
export const SENDA_INFORMADO = [
  { value: "S", label: "Si" },
  { value: "N", label: "No" },
  { value: "IS", label: "Informado (Consultar Senda)" },
]
export const roleNotAllowed = "EIAC_EXCL_PORTAL";

export const roleAllowed = "SUSCAGENTEOCASO";

export const rolesSuplementos = ["SUSCAGENTEOCASO", "SUSCAGENTE"];

export const roleNotAllowedReports = "SUSCAGENTEOCASO";