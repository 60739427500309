import styled from "styled-components";

const FooterContainer = styled.footer`
  width: 100%;
  height: 80px;
  padding-left: 80px;
  padding-right: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #094992;

  .footer-configuration {
    cursor: pointer;
  }
  
  .footer-wrapper {
    width: 100%;
  }

  p {
    color: white;
  }

  .footer-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-bottom {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .footer-bottom-apple {
      margin-right: 17px;
      height: 24px;
      width: 76px;
    }
    .footer-bottom-google {
      height: 32px;
      width: 102px;
    }
  }

  .footer-list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-element {
    margin-right: 40px;
    font-family: "museo sans";
    font-size: 14px;
    line-height: 16px;
    color: white;
    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 768px) {
    height: auto;
    justify-content: center;
    padding: 20px;
    .logo-icon  {
      margin-bottom: 20px;
      width: 115px;
    }
    .footer-top  {
      flex-direction: column;
    }
    .footer-bottom {
      align-items: center;
      justify-content: center;
    }
    .footer-list {
      flex-direction: column;
    }
    .footer-element {
      margin-right: 0px;
      margin-bottom: 8px;
    }
  }
`;

export default FooterContainer;
