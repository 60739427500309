import styled from "styled-components";
import { colors } from "../../assets";

export const CardContainer = styled.div`
  background-color: ${colors["PR-001-100"]};
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 28%;
    height: 69px;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* Sombra */

  .content {
    display: flex;
    align-items: center;
    padding-inline: 20px;
    .icon {
      width: 40px;
    }
    .title {
      font-size: 1em;
      color: ${colors["FL-001-Default"]};
      margin-left: 12px;
    }
  }

  .action {
    display: flex;
    padding: 0.6em;
    align-items: flex-end;
  }
`;
