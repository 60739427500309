import { NewDocument } from "./permissions-upload";
import { readFileAsText } from "../../../utils/utility";

export async function validateDocuments(doc: NewDocument): Promise<string[]> {
  const errors: string[] = [];

  // Validaciones: formato del fichero, nombre del fichero, numero de campos, cual es el primer campo
  const { file, docType } = doc;
  try {
    const text = await readFileAsText(file);

    if (!file.name.toLocaleLowerCase().includes(docType.toLocaleLowerCase())) {
      errors.push(
        `El nombre del documento no coincide con el tipo de documento ${docType}.`
      );
    }

    const fileExtension = file.name.split(".").pop();
    if (fileExtension !== "csv") {
      errors.push(`La extensión del archivo es errónea.`);
      return errors;
    }

    if (typeof text !== "string") {
      errors.push(`Formato del archivo no válido.`);
      return errors;
    }

    if (!text || text.length <= 0) {
      errors.push(`Archivo vacío.`);
      return errors;
    }

    const rows = text.split(/\r?\n|\r/);
    const columns = rows[0].split(";");

    if (columns.length !== 19) {
      errors.push(`Número de columnas del fichero no válido.`);
    }

    if (columns[0] !== "DELEGACION") {
      errors.push(`Campos del archivo erróneos.`);
    }

    return errors;
  } catch (err) {
    return ["Archivo no válido."];
  }
}

export const documentTypes = [
  {
    label: "USUARIOS OCASO",
    value: "OCASO",
    fileAccept: [
      ".csv",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ],
  },
];

export const documentTableColumns = [
  "Tipo de fichero",
  "Nombre del fichero",
  "Opciones",
  "Tamaño",
];
