import React from "react";
import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../assets";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import GuideLine from "../../../../components/guide-line";
import GipInsuredItem from "../../../../components/gip-insured-item/gip-insured-item";
import MainButton from "../../../../components/main-button";
import { InsuredDataContainer } from "./gip-insured-data-style"
import { withPolicy } from "../../../../context/mixin/with-policy";
import { calcAge } from "../../../../utils/utility";
import { getDayMonthYear } from "../../../../utils/time";
import { GipHireMenuHelper } from "../../../../components/gip-menu/gip-hire-menu";


interface InsuredProps extends RouteComponentProps {
  setLoading: Function,
  match: any,
  data: any,
  policy: any;
}

interface InsuredSimulationInterface {
  birthDate: string,
  checked: boolean,
}

interface InsuredState {
  insuredData: InsuredSimulationInterface[],
  birthDate: string,
  errorCode: string,
  valid: {
    birthDate: boolean
  },
  indexInsured: number,

}

class GipInsuredDataLayout extends React.Component<InsuredProps, InsuredState> {

  constructor(props: InsuredProps) {
    super(props);

    this.state = {
      birthDate: '',
      insuredData: [],
      errorCode: "",
      valid: {
        birthDate: false,
      },
      indexInsured: 0,
    }
    if (this.props.data.insuredData)
      this.setState({ insuredData: this.props.data.insuredData });


  }


  componentDidMount = async () => {
    if (this.props.data.insuredData) {
      this.setState({ insuredData: this.props.data.insuredData })
    }
  };


  toValidate = async () => {
    const { setLoading, policy } = this.props;

    let { insuredData } = this.state;
    this.props.policy.setGipSimulationData('insured', insuredData);
    this.props.policy.setGipSimulationData('policyData', { colective: parseInt(policy.product.products_colectivo), combiproduct: parseInt(policy.product.products_combinacion_comercial) });
    this.props.history.push(
      GipHireMenuHelper.getSimulationStepNavigationPath(
        GipHireMenuHelper.getSimulationStepFromNavigationRoute(
          this.props.match.url,
          policy.product.products_internalName
        ) + 1,
        policy.product.products_internalName
      )
    )
  }

  goBack() {
    const { policy } = this.props;

    this.props.history.push(
      GipHireMenuHelper.getSimulationStepNavigationPath(
        GipHireMenuHelper.getSimulationStepFromNavigationRoute(
          this.props.match.url,
          policy.product.products_internalName
        ) - 1,
        policy.product.products_internalName
      )
    )
  }

  handleBirthData = (value: string) => {
    const { valid } = this.state
    valid.birthDate = (value.length === 10)
    this.setState({ birthDate: value, valid: valid })
  }

  handleAddInsured = () => {
    const { birthDate, valid, } = this.state

    valid.birthDate = false;
    if (birthDate === "") {
      this.setState({ errorCode: "required" })

    } else {

      let { insuredData } = this.state

      insuredData.forEach((insured: InsuredSimulationInterface) => {
        insured.checked = false;
      });


      const newInsuredData = JSON.parse(JSON.stringify(insuredData))

      newInsuredData.push({
        birthDate,
        checked: true,
      });

      this.setState({
        birthDate: '',
        insuredData: newInsuredData,
      })
    }


  }

  handleCheckOldInsured = () => {

  }

  handleRemoveInsured = (id: number) => {
    let insuredData = this.state.insuredData;
    insuredData.splice(id, 1);
    this.setState({ insuredData });
  }

  onChangeOption = () => { }

  handleInsuredCheck(insuredIndex: number) {
    let { insuredData } = this.state;
    insuredData.forEach((insured: InsuredSimulationInterface, index) => {
      if (index === insuredIndex) {
        insured.checked = !insured.checked;
      }
    });

    this.setState({ insuredData });
  }
  getMaxAge = () => {
    var date = new Date();
    date.setFullYear(date.getFullYear() - 65)
    var fechaMax = moment(date).toISOString()

    return fechaMax
  }


  render() {

    const {
      birthDate,
      insuredData,
      errorCode
    } = this.state

    return (
      <InsuredDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">

            <h2>{'DATOS DEL SEGURO'}</h2>

          </div>
          <div className="data-flow-status-guide">
            <GuideLine
              steps={[0, 0, 0, 0]}
              currentStep={2}
            />
          </div>
        </div>

        <div className="data-wrapper">
          <div className="content-wrapper bottomMargin40">
            <div className="personal-data-wrapper">
              <div className="data-title">
                <h5>DATOS DE LOS ASEGURADOS</h5>
              </div>
            </div>
            <div className="data-row colSin-100">
              <div className="colSin-33 rightPadding20 topMargin12">
                <div className="inputBox">
                  <CalendarBox
                    initialValue={birthDate}
                    placeholder="Fecha de nacimiento"
                    forbidFutureDates
                    maxDate={moment().toISOString()}
                    minDate={this.getMaxAge()}
                    required
                    // disabled={useTakerData}
                    errorCode={errorCode}
                    onChange={(value: string) => {
                      this.handleBirthData(value);
                    }}
                    health={true}
                  />
                </div>
              </div>

              <div className="colSin-33 topPadding25 ">
                <MainButton
                  disabled={this.state.insuredData.length >= 4}
                  text="Añadir Asegurado"
                  onClick={() => this.handleAddInsured()}
                />
              </div>
            </div>

            <div className="data-row colSin-100">

              <div className="colSin-75" />



            </div>
          </div>

          <div className="content-wrapper bottomMargin20">
            <div className="tabs">

              {insuredData.map((insurance, index) => (
                <GipInsuredItem
                  age={calcAge(getDayMonthYear(insurance.birthDate).toString())}
                  birthDate={insurance.birthDate}
                  checked={insurance.checked}
                  id={index + 1}
                  index={index}
                  key={index}
                  onClick={() => this.handleRemoveInsured(index)}
                />

              ))}
            </div>
          </div>
        </div>

        <div className="colSin-100 topMargin20">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.goBack()}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                text="Siguiente"
                disabled={insuredData.length <= 0}
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>
      </InsuredDataContainer>
    );
  }
}

export default withPolicy(withRouter(GipInsuredDataLayout));