import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ClientsEiacContainer } from "./clients-eiac-style";
import GoBack from "../../../components/go-back";
import Title02Text from "../../../components/components-text/title-02-text";
import MainTable from "../../../components/main-table";
import Pagination from "../../../components/pagination";
import SelectBox from "../../../components/select-box/select-box";
import FilterInput from "../../../components/filter-input/filter-input-layout";
import {
  STATE_PAY,
  VALIDITY,
  MONTHS,
  MONTHS_2,
} from "../../../constants/filter";
import PoliciesServices from "../../../services/PoliciesServices";
import { withGeneral } from "../../../context/mixin/with-general";
import Body02Text from "../../../components/components-text/body-02-text";
import MainButton from "../../../components/main-button";
import moment from "moment";
import { images } from "../../../assets";
import { IMediator } from "../../../models/mediator-model";
import { getRelatedMediatorsCodes } from "../../../utils/mediatorCode";
import WebText from "../../../components/components-text/web-text";

interface ClientsEiacProps extends RouteComponentProps {
  setLoading: Function;
  mediator: IMediator;
}

interface ClientsEiacData {
  filter: {
    search: string;
    year: string;
    month: string;
    mediatorCode: string;
    [key: string]: string;
  };
  policies: any[];
  policiesFilter: any[];
  policiesFilterChecked: any[];
  years: any[];
  yearsCSV: any[];
  mediatorCodesInput: {
        codeOptions: { [key: string]: string }[],
        disabled: boolean,
        showError: boolean,
        errorText: string,
        selectedCode: string,
    },
  page: number;
  totalPages: number;
  showItems: number;
  order: string;
  ascendent: boolean | undefined;
  selectedCSV: boolean;
  isResult: boolean;
}

class ClientsEiacLayout extends React.Component<
  ClientsEiacProps,
  ClientsEiacData
> {

  private policiesChecked: any[] = [];
  private CSVLastYear = 2022;

  constructor(props: ClientsEiacProps) {
    super(props);

    const years = [{ label: "Todos", value: "" }];
    const yearsCSV: any[] = [];
    let currentYear = moment();
    Array(10)
      .fill(null)
      .forEach(() => {
        const year = currentYear.format("YYYY");
        years.push({
          label: year,
          value: year,
        });
        if(Number(year) <= this.CSVLastYear) {
          yearsCSV.push({
            label: year,
            value: year,
          });
        }
        currentYear.subtract(1, "year");
      });

    this.state = {
      years,
      yearsCSV,
      mediatorCodesInput: {
        codeOptions: getRelatedMediatorsCodes(props.mediator),
        disabled: props.location.state && props.location.state.searchType ? false : true,
        showError: false,
        errorText: '',
        selectedCode: props.location.state && props.location.state.searchCode ? props.location.state.searchCode : props.mediator.mediatorCode,
      },
      filter: {
        search: "",
        year: "",
        month: "",
        mediatorCode: props.mediator.mediatorCode ? props.mediator.mediatorCode : "",
      },
      policies: [],
      policiesFilter: [],
      policiesFilterChecked: [],
      page: 1,
      totalPages: 0,
      showItems: 10,
      order: "",
      ascendent: false,
      selectedCSV: false,
      isResult: false,
    };
  }


  componentDidUpdate(prevProps: ClientsEiacProps) {
    if (prevProps.mediator.mediatorCode !== this.props.mediator.mediatorCode) this.getRows();
  }

  getColumns = () => [
    {
      text: "NOMBRE DEL FICHERO",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "FECHA DE CREACIÓN",
      filter: false,
      ascendent: true,
    },
    {
      text: "TAMAÑO",
      filter: true,
      ascendent: false,
    },
    {
      text: "",
      filter: false,
      ascendent: true,
    },
    {
      text: "",
      key: "checkbox-header",
      onClick: () => this.selectAllCheckbox(),
      filter: false,
      ascendent: true,
    },
  ];

  getRows = async () => {
    const { setLoading } = this.props;
    const {
      policiesFilterChecked,
      showItems,
      selectedCSV,
      filter,
      page,
    } = this.state;

    this.setState({    ...this.state, isResult:false });

    if (!filter.search && (!filter.month || !filter.year || !filter.mediatorCode)) return;
    const params = [
      `${selectedCSV ? "format=csv" : "format=xml"}`,
      `skip=${showItems * (page - 1)}`,
      `take=${showItems}`, 
      `month=${filter.month}`,
      `year=${filter.year}`,
      `mediatorCode=${filter.mediatorCode}`
    ];
    if (filter.search) {
      params.push(`name=${filter.search}`);
    }

    const resPolicies = await PoliciesServices.getEIACFiles({
      params,
      loader: true,
      setLoading: setLoading,
    });

    const rows: any[] = [];
    let selected = false;
    if (!!resPolicies && !!resPolicies.files) {
      resPolicies.files.forEach((policy: any) => {
        let selectedPolicy = policiesFilterChecked.filter(
          temp => temp.check.text === (selectedCSV ? policy.csv : policy.xml))[0];
        !!selectedPolicy ? selected = true : selected = false;
        const row = {
          name: {
            text: policy.name,
            type: "",
            key: "highlighted",
          },
          cratedAt: {
            text: policy.date.split(" ")[0],
            type: "",
            key: "body",
          },
          size: {
            text: policy.xml_size || policy.csv_size,
            type: "",
            key: "body",
          },
          download: {
            text: "",
            key: "icon-button",
            img: images.IconDownloadLightBlue,
            onClick: () => this.handleGetEIACFile(policy.csv || policy.xml),
          },
          check: {
            text: selectedCSV ? policy.csv : policy.xml,
            key: "checkbox",
            value: selected,
            onClick: () => this.handleClickCheckbox(policy.csv || policy.xml),
          },
        };
        rows.push(row);
      });

      const pages = Math.ceil(resPolicies.count / showItems);
      this.setState({ policies: rows, policiesFilter: rows, totalPages: pages, isResult:true });
    }
  };

  handleClickCheckbox(policyFilepath: string) {
    // poner valor selected de la policy a !selected
    let policyChecked = this.state.policiesFilter.filter(policy => policy.check.text === policyFilepath);
    policyChecked[0].check.value = !!!policyChecked[0].check.value;
    let selectedPolicy = this.policiesChecked.filter(policy => policy.check.text === policyFilepath)[0];
    if (selectedPolicy) {
      this.policiesChecked = this.policiesChecked.filter(policy => policy.check.text !== policyFilepath);
      this.setState({ policiesFilterChecked: this.policiesChecked });
    } else {
      this.policiesChecked.push(policyChecked[0]);
      this.setState({ policiesFilterChecked: this.policiesChecked });
    }
  }

  selectPolicy(policyFilepath: string) {
    let policyChecked = this.state.policiesFilter.filter(policy => policy.check.text === policyFilepath);
    policyChecked[0].check.value = true;
    let selectedPolicy = this.policiesChecked.filter(policy => policy.check.text === policyFilepath)[0];
    if (!selectedPolicy) {
      this.policiesChecked.push(policyChecked[0]);
      this.setState({ policiesFilterChecked: this.policiesChecked });
    }
  }

  selectAllCheckbox() {
    this.state.policiesFilter.forEach(policy => {
      this.selectPolicy(policy.check.text);
    }
    );
  }

  changeValue = (value: string, key: string = "") => {
    if(key === "mediatorCode"){
      const { mediatorCodesInput } = this.state;
      mediatorCodesInput.selectedCode = value;
    }
    const { filter } = this.state;
    
    if(key === "search" && value === ""){
      filter[key] = value;
      this.getRows();
    } 
 
    if (filter[key] !== value) {
      filter[key] = value;

      this.setState({ filter, page: 1 }, () => {
        if (key === "year" || key === "month" || key === "mediatorCode") {
          this.getRows();
        }
      });
    }
  };

  handleOrder = (column: string, ascendent: boolean | undefined) => {
    switch (column) {
      case "taker":
        this.setState({ order: "takerName", ascendent }, () => this.getRows());
        break;
      case "validity":
        this.setState({ order: "policyValidity", ascendent }, () =>
          this.getRows()
        );
        break;
      case "payment":
        this.setState({ order: "payState", ascendent }, () => this.getRows());
        break;
      default:
        break;
    }
  };

  async handleDownloadAll() {
    const { selectedCSV, filter } = this.state;
    const params = [`mediatorCode=${filter.mediatorCode}`,
    selectedCSV ? "format=csv" : "format=xml",
      "encoding=base64",
    ];
    const args = {
      arrayBuffer: false,
      params,
      loader: true,
      setLoading: () => { },
    };
    const eiacFile = await PoliciesServices.getEIACFile(args);
    this.downloadFile(
      eiacFile.data,
      `EIAC-${selectedCSV ? "CSV" : "XML"}-FILES.zip`
    );
  }

  async handleDownloadSelected() {
    const { selectedCSV, policiesFilterChecked } = this.state;
    const arrayPoliciesNames = policiesFilterChecked.map(policy => policy.check.text);
    const params = [
      selectedCSV ? "format=csv" : "format=xml",
      "encoding=base64",
    ];
    const args = {
      arrayBuffer: false,
      params,
      loader: true,
      setLoading: () => { },
    };
    const eiacFile = await PoliciesServices.getSelectedEIACFiles(args, arrayPoliciesNames);
    this.downloadFile(
      eiacFile.data,
      `EIAC-${selectedCSV ? "CSV" : "XML"}-FILES.zip`
    );
  }

  async handleGetEIACFile(filename: string) {
    const { mediatorCode } = this.state.filter;
    const params = [`mediatorCode=${mediatorCode}`, `filepath=${filename}`];
    const args = {
      arrayBuffer: false,
      params,
      loader: true,
      setLoading: () => { },
    };
    const eiacFile = await PoliciesServices.getEIACFile(args);
    this.downloadSingleFile(eiacFile, filename);
  }

  downloadSingleFile(file: any, filename: string) {
    const { selectedCSV } = this.state;
    var blob = new Blob([file], {
      type: selectedCSV ? "text/csv" : "application/xml",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${filename.replace(".zip", "")}${selectedCSV ? ".csv" : ".xml"
      }`;
    link.click();
  }

  downloadFile(file: any, filename: string) {
    //var blob = new Blob([file], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.setAttribute("href", "data:text/plain;base64," + file);
    //link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }

  handleChangeTab(selectedCSV: boolean) {
    const { filter } = this.state;
    if (selectedCSV && Number(filter.year) > this.CSVLastYear) {
      filter.year = '';
    }

    this.setState({
      filter,
      selectedCSV,
      policiesFilterChecked: []
    }, () => {
      this.policiesChecked = [];
      this.getRows();
    });
  }

  setPage = (page: number) => {
    this.setState({ page },() => this.getRows())
  };

  render() {
    const { state, validity, search, year} = this.state.filter;
    const { isResult, policies, totalPages, page, years, yearsCSV, selectedCSV, mediatorCodesInput } = this.state;

    return (
      <ClientsEiacContainer>
        <div className="back-arrow-container">
          <GoBack text="Volver" />
        </div>
        <div className="main-title-container">
          <h2>Ficheros EIAC</h2>
        </div>
        <div className="main-filter-container">
          <div className="tabs-container">
            <div className="tabs-container__tabs">
              <div
                className={!selectedCSV ? "selected" : ""}
                onClick={() => this.handleChangeTab(false)}
              >
                {"FORMATO XML"}
              </div>
              <div
                className={selectedCSV ? "selected" : ""}
                onClick={() => this.handleChangeTab(true)}
              >
                {"FORMATO CSV"}
              </div>
            </div>
            <div
              className={`tabs-container__line ${selectedCSV ? "selected-right" : ""
                }`}
            />
          </div>
          { selectedCSV &&
            <div className="filter-row-container col-12"> 
              <WebText className={`normal tag-container keep-calm`}>
                <p>No hay datos en ese formato posteriores a 2022, si en pestaña “Formato XML”.</p>
              </WebText>
            </div>
          }
          <div className="filter-row-container col-12">
            <div className="col-2 rightPadding15">
              <div className="inputBox">
                <FilterInput
                  placeholder={`Nombre del fichero ${selectedCSV ? "CSV" : "XML"
                    }`}
                  onChange={(value: string) => this.changeValue(value, "search")}
                  onEnter={this.getRows}
                />
              </div>
            </div>
            <div className="col-2 rightPadding15">
              <div className="selectBox">
                <SelectBox
                  optionsText={selectedCSV ? yearsCSV : years}
                  optionKey={"value"}
                  optionValue={"label"}
                  defaultValue={""}
                  initialValue={state}
                  onChange={(value: string) => this.changeValue(value, "year")}
                  required={!search}
                  refreshValues={selectedCSV && (Number(year) > this.CSVLastYear || year === '')}
                />
                <label>AÑO</label>
              </div>
            </div>
            <div className="col-2 rightPadding20">
              <div className="selectBox">
                <SelectBox
                  optionsText={MONTHS}
                  optionKey={"value"}
                  optionValue={"label"}
                  defaultValue={""}
                  initialValue={validity}
                  required={!search}
                  onChange={(value: string) => this.changeValue(value, "month")}
                />
                <label>MES</label>
              </div>
            </div>
            <div className="col-6">
              <div className="selectBox relatedMediators">
                <SelectBox
                  // mediatorSelector={true}
                  isMediator={true}
                  optionsText={mediatorCodesInput.codeOptions}
                  optionKey={"value"}
                  optionValue={"label"}
                  defaultValue={mediatorCodesInput.selectedCode}
                  onChange={(value: string) => this.changeValue(value, "mediatorCode")}
                  required={true}
                  propValue={mediatorCodesInput.selectedCode}
                />
                <label>Mediador</label>
              </div>
            </div>
          </div>
          <div className="filter-row-container col-12">
            <div className="col-2 topMargin15 rightPadding20">
              <MainButton
                text={"Descargar todas"}
                disabled={totalPages === 0}
                onClick={() => this.handleDownloadAll()}
              />
            </div>
            <div className="col-2 topMargin15 botPadding10">
              <MainButton
                text={"Descargar selección"}
                disabled={this.policiesChecked.length === 0}
                onClick={() => this.handleDownloadSelected()}
              />
            </div>
          </div>
        </div>
        {totalPages === 0 ? (
          !isResult ? <></> : <Body02Text>No hay datos</Body02Text>
        ) : (
          <>
            <div className="pendant-simulations-table">
              <MainTable
                className="table"
                columns={this.getColumns()}
                rows={policies}
                keysRow={["name", "cratedAt", "size", "download", "check"]}
              />
            </div>

            <div className="pendant-simulation-pagination">
              <Pagination
                page={page}
                limit={totalPages}
                callback={(page) => this.setPage(page)}
              />
            </div>
          </>
        )}
      </ClientsEiacContainer>
    );
  }
}

export default withGeneral(withRouter(ClientsEiacLayout));
