import GeneralServices from "./GeneralServices";
import { UpdatePermissionsByFileRequest } from "./dto/request/ProductsMediadoresServicesRequest.dto";
import { UpdatePermissionsByFileResponse } from "./dto/response/ProductsMediadoresServicesResponse.dto";

class ProductsMediadoresServices {
  getAllUsers = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `products-mediadores`
      });
     
      return res.data;
    } catch (err) {
      throw err;
    }
  };


  getUsers = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `products-mediadores/findByFilter?mediador=${args.params.mediatorCode}&productos=${args.params.productos.toString()}`,
      });   
      return res.data;
    } catch (err) {
      return {};
    }
  };


  getUsersByProduct = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `products-mediadores/findByFilter?mediador=${args.params.mediatorCode}&productosId=${args.params.productoId}`,
      });   
      return res.data;
    } catch (err) {
      throw err;
    }
  };
  activatePermission = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `products-mediadores/activar${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  deactivatePermission = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `products-mediadores/desactivar${args.params ? '?' + args.params.join("&") : ''}`,
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  activateById = async (args: any) => {
    let string = `products-mediadores/activateById?id=${args.params.id}&esIncluido=${args.params.esIncluido}`
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `products-mediadores/activateById?id=${args.params.id}&esIncluido=${args.params.esIncluido}`
      });
      return response.data;
    }
    catch (err) {
      console.log("err",err)
      return {};
    }
  }
  updatePermissions = async (args: any): Promise<any> => {
    const { setLoading, loader, request } = args;
    try {
      const response = await GeneralServices.post({
        setLoading,
        loader,
        endpoint: `products-mediadores/update`,
        data: request,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }
  activateAll = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `products-mediadores/activate`,
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };
  deactivateAll = async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `products-mediadores/deactivate`,
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };
  deletePermission= async (args: any) => {
    try {
      const res = await GeneralServices.get({
        loader: args.loader ? true : false,
        setLoading: args.setLoading,
        endpoint: `products-mediadores/delete/${args.params ? '?' + args.params.join("&") : ''}`,//delete/mediador|esIncluido|producto ID
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  updatePermissionsByFile = async ({
    loader,
    setLoading,
    user,
    userEmail,
    files,
  }: UpdatePermissionsByFileRequest) => {
    try {
      const fd = new FormData();
      fd.append("body", JSON.stringify({ user, user_email: userEmail }));
      files.forEach((file: any) => {
        fd.append("stream", file);
      });

      const res = await GeneralServices.postFormData({
        setLoading,
        loader,
        endpoint: "permissions/upload-permissions",
        data: fd,
      });
      return res.data;
    } catch (err) {
      return { error: true };
    }
  };
}

export default new ProductsMediadoresServices();
