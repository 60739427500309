import React from "react";
import { CampaignsConfigContainer } from "./campaigns-config-style";
import Title02Text from "../../components/components-text/title-02-text";
import SelectBox from "../../components/select-box/select-box";
import MainButton from "../../components/main-button";
import MainTable from "../../components/main-table";
import Pagination from "../../components/pagination";
import { images } from "../../assets";
import { withRouter, RouteComponentProps } from "react-router-dom";
import CampaignsConfigurationServices from "../../services/CampaignsConfigurationServices";
import moment from "moment";
import MainModalCampaigns from "../../components/main-modal-campaigns";
import ZeroContent from "../../components/zero-content/zero-content";
import { CAMPAIGN_STATES } from "../../constants/campaignsConfig";

const NUM_ROWS = 6;

interface ICampaignsConfigProps extends RouteComponentProps { }

interface ICampaignsConfigState {
  rows: any[];
  page: number;
  totalPages: number;
  modal: string;
  selectedCampaignId: string;
  selectedCampaignStatus: string;
  yearsDropdown: any[];
  selectedYear: string;
  actionMainTitle: string;
  actionMainText: string;
  actionButtonText: string;
  actionButtonClassName: string;
  actionImage: string;
  actionCampaign: any;
  hasCampaigns: boolean;

}

const CAMPAIGN_TYPES: any = {
  INCENTIVE: "Incentivo",
  CONTEST: "Concurso"
};



class CampaignsConfig extends React.Component<
  ICampaignsConfigProps,
  ICampaignsConfigState
  > {
  constructor(props: ICampaignsConfigProps) {
    super(props);
    this.state = {
      rows: [],
      page: 1,
      totalPages: 0,
      selectedCampaignId: "",
      selectedCampaignStatus: "",
      modal: "",
      yearsDropdown: [
        { label: 'Todos', value: '*' }
      ],
      selectedYear: '*',
      actionMainTitle: '',
      actionMainText: '',
      actionButtonText: '',
      actionButtonClassName: '',
      actionImage: '',
      actionCampaign: null,
      hasCampaigns: false
    };
  }

  componentDidMount() {
    const { yearsDropdown } = this.state;
    let currentYear = moment();
    const nextYears = moment().add(10, 'years');
    while (currentYear.isBefore(nextYears)) {
      const year = currentYear.format('YYYY');
      yearsDropdown.push(
        {
          label: year,
          value: year
        })
      currentYear.add(1, 'year')
    }
    this.setState({ yearsDropdown }, () => {
      this.getCampaigns();
    })
  }

  async getCampaigns() {
    const { page, selectedYear } = this.state;
    const args = {
      loader: true,
      setLoading: () => { },
      skip: page - 1,
      take: NUM_ROWS,
      year: selectedYear === '*' ? null : selectedYear
    };
    const resp = await CampaignsConfigurationServices.getCampaigns(args);
    this.setState({
      totalPages: Math.ceil(resp.count / NUM_ROWS)
    }, () => {
      this.getRows(resp.items);
    })
  }

  async deleteCampaign() {
    const { selectedCampaignId } = this.state;
    const args = {
      id: selectedCampaignId,
      setLoading: () => { }
    };
    await CampaignsConfigurationServices.deleteCampaign(args);
    this.setState({ modal: "delete-success-modal" });
  }

  async setCampaignStatus(campaign: any, status: string, revokedStart?: string, revokedEnd?: string) {
    campaign.status = status;
    if (revokedStart) {
      campaign.revokedStart = revokedStart
    } else {
      delete campaign.revokedStart
    }
    if (revokedEnd) {
      campaign.revokedEnd = revokedEnd
    } else {
      delete campaign.revokedEnd
    }
    const args = {
      id: campaign.id,
      loader: true,
      setLoading: () => { },
      data: campaign
    }
    await CampaignsConfigurationServices.putCampaigns(args);
  }

  getStatusColor(campaign: any) {
    switch (campaign.status) {
      case 'revoked':
      case 'cancelled':
        return 'incomplete';
      case 'definition':
      case 'planned':
      case 'in_action':
        return 'keep-calm';
      case 'finished':
      case 'in_process':
        return 'complete';
    }
  }

  activeRevokeCampaignToogle(campaign: any) {
    if (campaign.status === 'definition') {
      this.setCampaignStatus(campaign, 'planned');
    } else if (!campaign.status.revokedStart) {
      this.setCampaignStatus(campaign, campaign.status, moment().toISOString());
    } else {
      this.setCampaignStatus(campaign, campaign.status, campaign.revokedStart, moment().toISOString());
    }
  }

  openActionModal(campaign: any) {
    const isDefinition = campaign.status === 'definition';
    const isNotRevoked = !campaign.status.revokedStart;
    this.setState({
      modal: 'action-modal',
      actionMainTitle: isDefinition ? '¿Quieres publicar la campaña?' : isNotRevoked ? '¿Quieres anular la campaña' : '¿Quieres reactivar la campaña',
      actionMainText: isDefinition ? 'Una vez publicada no podrás editar los datos de la campaña.' : isNotRevoked ? 'Una vez anulada las polizas en realizadas en periodo de anulación no contarán.'
        : 'Una cez reactivada recuerda que las pólizas que han sido realizadas en periodo de anulación no serán contadas.',
      actionButtonText: isDefinition ? 'Publicar' : isNotRevoked ? 'Anular' : 'Reactivar',
      actionButtonClassName: isDefinition || !isNotRevoked ? 'green' : 'red',
      actionImage: isDefinition || !isNotRevoked ? images.IconCheckGreen : images.IconCloseBlue,
      actionCampaign: campaign
    });
  }

  getRows(campaigns: any) {
    const rows: any[] = [];
    const years: number[] = [];
    campaigns.forEach((campaign: any) => {
      const start = moment(campaign.start);
      const end = moment(campaign.end);
      if (!years.includes(start.year())) years.push(start.year());

      moment(campaign.start).format("DD/MM/YYYY");
      const row = {
        name: {
          text: campaign.name,
          type: "",
          key: "highlighted"
        },
        startDate: {
          text: start.format("DD/MM/YYYY"),
          type: "",
          key: "body"
        },
        endDate: {
          text: end.format("DD/MM/YYYY"),
          type: "",
          key: "body"
        },
        type: {
          text: CAMPAIGN_TYPES[campaign.type],
          type: "",
          key: "body"
        },
        state: {
          text: CAMPAIGN_STATES[campaign.status],
          type: this.getStatusColor(campaign),
          key: "tag-container"
        },
        buttons: {
          key: "buttons",
          items: [
            {
              img: images.IconDetailLightBlue,
              onClick: () =>
                this.props.history.push(`config-campañas/detail/${campaign.id}`)
            },
          ]
        }
      }

      if (campaign.status === 'definition' ||
        campaign.status === 'planned' ||
        campaign.status === 'in_action' ||
        campaign.status === 'in_process') {
        row.buttons.items.push(
          {
            img: images.IconEditLightBlue,
            onClick: () =>
              this.props.history.push(`config-campañas/edit/${campaign.id}`)
          },
        )
      }
      if (campaign.status !== 'cancelled' &&
        campaign.status !== 'revoked' &&
        campaign.status !== 'finished' &&
        campaign.status !== 'in_process') {
        row.buttons.items.push(
          {
            img: images.IconDeleteLightBlue,
            onClick: () => {
              this.setState({
                modal: "delete-modal",
                selectedCampaignId: campaign.id,
                selectedCampaignStatus: campaign.status
              });
            }
          }
        )
      }

      if (campaign.status === 'definition') {
        row.buttons.items.push(
          {
            img: campaign.status === 'definition' ? images.IconCheckGreen : images.IconCloseBlue,
            onClick: () => this.openActionModal(campaign)
          }
        )
      }
      rows.push(row);
    });

    // const yearsDropdown = [{ value: "*", label: "Todos" }];
    // years.forEach(year => {
    //   yearsDropdown.push({ value: year.toString(), label: year.toString() });
    // });



    this.setState({
      rows,
      //yearsDropdown,
      hasCampaigns: true
    });
  }

  setPage(page: number) {
    this.setState({ page }, () => this.getCampaigns());
  }

  render() {
    const { history } = this.props;
    const {
      rows,
      page,
      totalPages,
      modal,
      yearsDropdown,
      selectedYear,
      actionMainTitle,
      actionMainText,
      actionButtonText,
      actionButtonClassName,
      actionImage,
      actionCampaign,
      selectedCampaignStatus,
      hasCampaigns
    } = this.state;
    const revokeSelected = selectedCampaignStatus === 'planned' ||
      selectedCampaignStatus === 'definition';
    return (
      <CampaignsConfigContainer>
        <div className="campaigns-config-section-title">
          <Title02Text>Campañas</Title02Text>
        </div>
        <div className="campaigns-config-filter">
          <div className="campaigns-config-filter__item">
            <SelectBox
              type="ghost"
              placeholder="Año"
              required
              optionsText={yearsDropdown}
              optionKey={"value"}
              optionValue={"label"}
              defaultValue={""}
              initialValue={""}
              onChange={(value: string) => this.setState({ selectedYear: value }, () => this.getCampaigns())}
            />
          </div>
          <div className="campaigns-config-filter__item">
            <MainButton
              text="Crear"
              onClick={() => history.push("/config-campañas/create")}
            />
          </div>
        </div>
        {rows.length > 0 ? (
          <>
            <div className="campaigns-config-table">
              <MainTable
                columns={this.columns}
                rows={rows}
                keysRow={[
                  "name",
                  "startDate",
                  "endDate",
                  "type",
                  "state",
                  "buttons"
                ]}
              />
            </div>
            <div className="campaigns-config-pagination">
              <Pagination
                page={page}
                limit={totalPages}
                callback={page => this.setPage(page)}
              />
            </div>
          </>
        ) : hasCampaigns && (
          <ZeroContent text={'Para que no hay campañas. ¿A qué estás esperando para crear una?'} />
        )

        }
        {modal === 'action-modal' && (
          <MainModalCampaigns
            mainTitle={actionMainTitle}
            mainText={actionMainText}
            buttonText={actionButtonText}
            close={() => this.setState({ modal: "" }, () => this.getCampaigns())}
            active={true}
            onClickAccept={() => this.activeRevokeCampaignToogle(actionCampaign)}
            centered={true}
            buttonClassName={actionButtonClassName}
            img={actionImage}
          />
        )

        }
        {modal === "delete-modal" && (
          <MainModalCampaigns
            mainTitle={revokeSelected ? "Anular campaña" : "Cancelar campaña"}
            mainText={
              `¿Estás seguro de que quieres ${revokeSelected ? "anular" : "cancelar"} esta campaña? Recuerda que no podrás recuperarla más tarde y tendrás que volver a crearla si es necesario.`
            }
            buttonText={revokeSelected ? "Anular" : "Cancelar"}
            close={() => this.setState({ modal: "" })}
            active={true}
            onClickAccept={() => this.deleteCampaign()}
            centered={true}
            buttonClassName={"red"}
            img={images.IconWarningRed}
          />
        )}
        {modal === "delete-success-modal" && (
          <MainModalCampaigns
            mainTitle={`¡Oops!\nHas ${revokeSelected ? "anulado" : "cancelado"} una campaña`}
            close={() =>
              this.setState({ modal: "" }, () => {
                this.getCampaigns();
              })
            }
            active={true}
            onClickAccept={() => { }}
            centered={true}
            buttonClassName={"red"}
            img={images.IconWarningRed}
          />
        )}
      </CampaignsConfigContainer>
    );
  }

  columns = [
    {
      text: "nombre",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "fecha inicio",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "fecha fin",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "tipo",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "estado",
      filter: false,
      ascendent: true,
      key: "tag-container"
    },
    {
      text: "",
      filter: false,
      ascendent: true,
      key: ""
    }
  ];
}

export default withRouter(CampaignsConfig);
