import React, { Component } from 'react';
import { InputFileContainer } from './input-files-style';
import { images } from '../../assets/images';
import { getDateFormat } from '../../utils/time';
import { ERROR_MESSAGES } from '../../constants/errorMessages';
import LabelText from '../components-text/label-text';
import BodyText from '../components-text/body-text';
import InfoText from '../components-text/info-text';
import NoteText from '../components-text/note-text';

interface AttachedFile {
  name: string;
  size: string;
  creation_date: string;
  error: boolean;
  data: any;
}

interface InputFileProps {
  className?: string;
  labelText?: string;
  required?: boolean;
  errorCode?: string;
  value?: string;
  accept?: string[];
  placeholder: string;
  onChange?: (files: AttachedFile[]) => void;
  onConfirm?: (files: AttachedFile[]) => void;
}

interface InputFileState {
  selectedFiles: AttachedFile[];
}

export default class InputFile extends Component<InputFileProps, InputFileState> {
  state: InputFileState = {
    selectedFiles: [],
  };
  fileInput: any;

  componentDidMount() {
    document.body.classList.add('no-scroll');
  }
  componentWillUnmount() {
    document.body.classList.remove('no-scroll');
  }

  uploadFile(files: any) {
    const inputFiles: AttachedFile[] = [];

    for (const file of files) {
      let error = false;
      if (file.size > 1024 * 1024 * 2) {
        // max size 2MB
        error = true;
      }
      inputFiles.push({
        error,
        data: file,
        name: file.name,
        size: file.size,
        creation_date: getDateFormat(new Date(file.lastModified), 'DD MMM YYYY'),
      });
    }

    this.setState({ selectedFiles: inputFiles }, () => {
      const { onChange } = this.props;
      if (onChange) onChange(inputFiles);
    });
  }

  removeFile(item: any, index: number) {
    const { selectedFiles } = this.state;

    selectedFiles.splice(index, 1);

    this.setState({ selectedFiles }, () => {
      const { onChange } = this.props;
      if (onChange) onChange(selectedFiles);
    });
  }

  // DEPRECATED: not being used
  confirmFiles() {
    const { selectedFiles } = this.state;
    const { onConfirm } = this.props;

    const tempFiles = selectedFiles.slice();

    this.setState({ selectedFiles: [] }, () => {
      if (onConfirm) onConfirm(tempFiles);
    });
  }

  render() {
    const { selectedFiles } = this.state;
    const { className, labelText, required, errorCode, value, accept, placeholder } = this.props;
    const errorText = errorCode ? ERROR_MESSAGES[errorCode] : '';
    return (
      <InputFileContainer
        className={`${className} ${required && 'required'} ${errorCode ? ' error' : ''}`}
      >
        {labelText && (
          <div className="input-file-message-label">
            <LabelText>{labelText}</LabelText>
          </div>
        )}
        <div className="input-file-message-container">
          <div className="input-file-message-drag">
            <form className="uploader" encType="multipart/form-data">
              <input
                type="file"
                name="file"
                id="file"
                onChange={e => this.uploadFile(e.target.files)}
                accept={accept ? accept.toString() : 'image/*'}
              />
              <div className="input-box-main">
                <div className="input-box-main-field">
                  <BodyText>
                    <p>{value || placeholder}</p>
                  </BodyText>
                </div>
                <div className="input-box-icon">
                  <img src={images.IconMask} />
                </div>
              </div>
            </form>
          </div>
          {errorCode ? (
            <div className="input-box-error">
              <NoteText>
                <p>{`* ${errorText}`}</p>
              </NoteText>
            </div>
          ) : (
              <div className="input-file-message-disclaimer">
                <InfoText>Los archivos no pueden superar los 2MB*</InfoText>
              </div>
            )}
        </div>
      </InputFileContainer>
    );
  }
}
