import React, { Component } from "react";
import { MainModalCampaignsContainer } from "./main-modal-campaigns-style";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { images } from "../../assets";
import H4Text from "../components-text/h4-text";
import MainButton from "../main-button";
import WebText from "../components-text/web-text";
import HeadsTitleText from "../components-text/heads-title-text";
import ButtonText from "../components-text/button-text";
import InputBox from "../input-box/input-box";

interface table {
  title: string;
  content: any;
}

interface MainModalCampaignsProps extends RouteComponentProps {
  className?: string;
  img?: string;
  onClickAccept?: Function;
  mainTitle: string;
  mainText: string;
  buttonText?: string;
  active?: boolean;
  close: Function;
  centered?: boolean;
  table?: any;
  buttonClassName?: string;
  inputCount?: string;
}

interface MainModalCampaignsState {
  active: string;
}

class MainModalCampaignsLayout extends Component<
  MainModalCampaignsProps,
  MainModalCampaignsState
> {
  readonly state: MainModalCampaignsState;

  public static defaultProps = {
    active: false,
    img: images.IconSearchBlue,
    onClickAccept: () => {},
    className: "",
    mainTitle: "",
    mainText: "",
    buttonText: "",
    close: () => {},
    centered: false
  };

  containerRef: any;

  constructor(props: MainModalCampaignsProps) {
    super(props);

    this.state = {
      active: props.active ? "active" : ""
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  handleClickOutside(ev: any) {
    const { close, active } = this.props;
    active &&
      this.isVisible(ev.target) &&
      this.containerRef.current &&
      !this.containerRef.current.contains(ev.target) &&
      close();
  }

  isVisible(e: any) {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
  }

  componentDidUpdate(prevProps: MainModalCampaignsProps) {
    if (prevProps.active !== this.props.active) {
      this.setState({ active: this.props.active ? "active" : "" });
    }
  }

  close() {
    this.setState({ active: "" });
  }

  open() {
    this.setState({ active: "active" });
  }

  acceptMainModalCampaigns() {
    const { onClickAccept, close } = this.props;
    if (onClickAccept) {
      onClickAccept();
    }
    close();
  }

  render() {
    const {
      img,
      mainTitle,
      mainText,
      className,
      buttonText,
      close,
      centered,
      table,
      buttonClassName,
      inputCount
    } = this.props;
    const { active } = this.state;

    return (
      <MainModalCampaignsContainer
        centered={centered}
        className={`${active} ${className} `}
        buttonClassName={buttonClassName}
      >
        <div className="main-info-modal-container" ref={this.containerRef}>
          <div className="main-info-modal-close">
            <img src={images.IconCloseGrey} onClick={() => close()} />
          </div>

          <div className="main-info-modal-main">
            <div className="main-info-modal-main-title">
              <div className="main-info-modal-icon-bg">
                <img src={img} />
              </div>
              <div className="main-info-modal-text">
                <H4Text>{mainTitle}</H4Text>
              </div>
            </div>

            <div className="main-info-modal-main-content">
              {mainText && (
                <div className="main-info-modal-main-copy">
                  <WebText>{mainText}</WebText>
                </div>
              )}
              {inputCount && (
                <div className="main-info-modal-main-input">
                  <InputBox
                    required
                    className={"rounded"}
                    placeholder={inputCount}
                    value=""
                    onChange={() => {}}
                  />
                </div>
              )}

              {table && (
                <div className="main-info-modal-table">
                  <div className="main-info-modal-table-title">
                    <HeadsTitleText>{table.title}</HeadsTitleText>
                  </div>
                  <div className="main-info-modal-table-content">
                    {table.content.map((item: any, index: number) => (
                      <div className="main-info-modal-table-content__item">
                        <div className="main-info-modal-table-content__item__title">
                          <WebText>{item.title}</WebText>
                        </div>
                        <div className="main-info-modal-table-content__item__text">
                          <ButtonText>{item.value}</ButtonText>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {buttonText && (
                <div className="main-info-modal-button">
                  <MainButton
                    type={buttonClassName}
                    text={buttonText}
                    onClick={() => this.acceptMainModalCampaigns()}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </MainModalCampaignsContainer>
    );
  }
}

export default withRouter(MainModalCampaignsLayout);
