import styled from "styled-components";
import { BreadcrumbTextContainer } from "../../components/components-text/breadcrumb-text/breadcrumb-text-style";
import { colors } from "../../assets";

export const CampaignsContainer = styled.div`
  margin-bottom: 120px;

  .campaigns-loading-container {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .new-hiring-flow-name {
    margin-bottom: 17px;
    ${BreadcrumbTextContainer} {
      color: ${colors["TX-001-30"]};
    }
  }

  .new-hiring-flow-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .new-hiring-flow-status-guide {
    display: flex;
    align-items: center;
  }

  .new-hiring-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 24px;
  }

  .new-hiring-subtitle {
    color: ${colors["TX-001-80"]};
    margin-bottom: 50px;
  }

  .new-hiring-navigate-container {
    display: flex;
    margin-top: 122px;
    margin-bottom: 100px;
  }

  .new-hiring-navigate-item {
    width: 100%;
    max-width: 180px;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  .campaigns-card-week-item {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .campaigns-card-week-cards {
    display: flex;
    margin-bottom: 120px;
    &__item {
      margin-right: 4px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .campaigns-card-week-cards-total-count {
    width: 154px;
    height: 209px;
    padding: 32px 16px 18px;
    font-family: "museo sans";
    border-radius: 3px;
    background: rgba(179, 202, 220, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .campaigns-section-advice {
    color: #004f8b;
    margin-top: 48px;
    margin-bottom: 24px;
  }

  .campaigns-card-week-cards-total-count-title {
    color: #004f8b;
    margin-bottom: 8px;
  }

  .campaigns-card-week-cards-total-count-min {
    color: #b3cadc;
  }
  .campaigns-card-week-cards-total-count-item {
    margin-bottom: 12px;
    &:last-child  {
      margin-bottom: 0px;
    }
    &__number {
      color: #004f8b;
    }
    &__subtitle {
      color: #b3cadc;
    }
  }
  .campaigns-section-title {
    margin-bottom: 8px;
    color: #004f8b;
  }

  .margin-to-delete {
    margin-bottom: 320px;
  }

  .campaigns-section-subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    &__text {
      color: #004f8b;
      span  {
        color: #b3cadc;
        font-size: 20px;
        line-height: 28px;
      }
    }
    &__refresh {
    }
  }
  .campaigns-section-progress {
    margin-bottom: 80px;
  }

  .campaigns-card-divider {
    width: 100%;
    border-top: 1px solid ${colors["FL-001-Disabled"]};
    padding: 32px 0px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 80px;
  }

  .campaigns-section-progress {
    margin-bottom: 180px;
  }

  .loader-holder {
    position: relative;
    z-index: 99999;
    width: 100px;
    height: 100px;
    margin-top: 200px;
  }

  .spinner {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
    -webkit-animation: color-bubble 2s linear 0s infinite;
    animation: color-bubble 2s linear 0s infinite;
  }

  .spinner {
    animation: color-bubble 2s linear 0s infinite;

    &:after,
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 100%;
      box-shadow: 0 0 0 3px;
      -webkit-animation: spinner-bubble 1.3s linear 0s infinite;
      animation: spinner-bubble 1.3s linear 0s infinite;
      margin: auto;
      width: 10px;
      height: 10px;
      opacity: 0;
    }

    &:before {
      -webkit-animation: spinner-bubble 1.3s linear 2s infinite;
      animation: spinner-bubble 1.3s linear 2s infinite;
    }
  }

  @keyframes spinner-bubble {
    from {
      width: 10px;
      height: 10px;
      opacity: 0.8;
    }
    to {
      width: 50px;
      height: 50px;
      opacity: 0;
    }
  }

  @keyframes color-bubble {
    0% {
      color: rgb(23, 93, 146);
    }
    33% {
      color: rgba(23, 93, 146, 0.75);
    }
    66% {
      color: rgba(23, 93, 146, 0.5);
    }
    100% {
      color: rgba(23, 93, 146, 0.25);
    }
  }
`;
