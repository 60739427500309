import React, { Component } from "react";
import Body02Text from "../../../../components/components-text/body-02-text";
import { CampaignsPrizeCardContainer } from "./campaigns-prize-card-style";
import { images } from "../../../../assets";

interface ICardProps {
  className?: string;
  selected: boolean;
  title: string;
  subtitle: string;
  onClickErase?: () => void;
  onClickDetail: () => void;
}

export const CampaingsPrizeCard = (props: ICardProps) => {
  const {
    className,
    selected,
    title,
    subtitle,
    onClickErase,
    onClickDetail
  } = props;
  return (
    <CampaignsPrizeCardContainer
      className={`${className} ${selected ? "active" : ""}`.trim()}
    >
      <div className={"campaigns-card"}>
        <div className="campaigns-card-name">
          <Body02Text>
            <p>{title}</p>
          </Body02Text>
        </div>
        <div className="campaigns-card-type">
          <p>{subtitle}</p>
          <div className="campaigns-card-buttons">
            <div
              className="campaigns-card-details"
              onClick={() => onClickDetail()}
            >
              <img src={images.IconEyeBlue} />
            </div>
            {onClickErase && (
              <div
                className="campaigns-card-erase"
                onClick={() => onClickErase()}
              >
                <img src={images.IconEraseBlue} />
              </div>
            )}
          </div>
        </div>
      </div>
    </CampaignsPrizeCardContainer>
  );
};
