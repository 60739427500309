import GeneralServices from "./GeneralServices";
import { BaseServiceRequestDto } from "./dto/request/BaseServiceRequest.dto";

type GetFaqTypes = BaseServiceRequestDto;

interface GetFaqsType extends BaseServiceRequestDto {
  lineaNegocio: string,
}

class FaqsServices {
  getFaqs = async ({ lineaNegocio, loader, setLoading }: GetFaqsType) => {
    try {
      const response = await GeneralServices.get({
        loader,
        setLoading,
        endpoint: `faq/getFaqByLineaNegocio?linea_negocio=${lineaNegocio}`,
      });
      return response.data;
    } catch (err) {
      return {
        error: true,
        err,
      };
    }
  }

  getFaqAreas = async ({ loader, setLoading }: GetFaqTypes) => {
    try {
      const response = await GeneralServices.get({
        loader,
        setLoading,
        endpoint: `faq/getLineasNegocio`,
      });
      return response.data;
    } catch (err) {
      return {
        error: true,
        err
      }
    }
  }
}

export default new FaqsServices();
