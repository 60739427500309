import styled from "styled-components";
import { colors } from "../../../../../assets";
import { BodyTextContainer } from "../../../../../components/components-text/body-text/body-text-styles";
import { images } from "../../../../../assets/images";
import { NoteTextContainer } from "../../../../../components/components-text/note-text/note-text-styles";

export const PriceDataContainer = styled.div`

.result-simulation-item-content-table
    .table
    .main-table-row:first-child
    td
    > div {
    padding-top: 0;
  }
  .float-right-cell {
    float: right;
  }
  .result-simulation-item-content-table {
    margin-bottom: 75px;
    min-width: 100%;
    max-width: 1250px;
    overflow-x: auto;

    .table {
      min-width: 840px;

      .main-table-row td {
        padding: 0 15px;
      }

      th {
        padding: 30px 15px 0;
      }

      .entire-mini-title {
        font-size: 16px !important;
      }

      .dimmed-mini-title {
        font-size: 16px !important;
      }

      .dimmed-mini-body {
        font-size: 16px !important;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    thead {
      border-bottom: 1px solid ${colors["PR-001-100"]};
    }

    th {
      padding: 10px 15px;
      color: ${colors["TX-001-30"]};
      .highlighted {
        color: ${colors["TX-001-50"]};
      }
    }

    td {
      > div {
        padding: 8px 0;
      }

      ${BodyTextContainer} {
        display: inline-block;
        &.highlighted {
          font-weight: 600;
        }
      }
    }

    .main-table-row {
      &:first-child {
        td {
          > div {
            padding-top: 20px;
          }
        }
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  .result-simulation-item-content-advise {
    color: ${colors["TX-001-30"]};
  }

  .result-simulation-item-content-capital-text {
    p {
      color: ${colors["TX-001-50"]};
    }

    span {
      color: ${colors["TX-001-100"]};
      margin-left: 8px;
    }
  }

  .result-simulation-container {
    margin-bottom: 40px;
  }

  .result-simulation-item {
    padding: 40px;
    margin-bottom: 24px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
    
    &.float {
      width: 100%;
      display: block;
      float: left;
    }
    @media (min-width: 320px) {
      result-simulation-item {
        &.float {
          width: 55% !important;
        }
      }
    }
    @media (min-width: 1201px) {
      .result-simulation-item {
        &.float {
          width: 100% !important;
        }
      }
    }

    .result-simulation-item-content {
      h5 {
        color: ${colors["PR-001-100"]};
        padding-left: 30px;
        position: relative;
        font-size: 24px;

        &:before {
          content: url(${images.IconCheckBlue});
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-right: 8px;
          position: absolute;
          left: 0;
          top: 1px;
        }
      }
    }
  }

  .result-simulation-item-title {
    color: ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 24px;
  }

.detail-content {
  background: ${colors["detail-item-background"]};
}

.personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  .tabs {
    overflow: hidden;

    .tab {
      width: 100%;
      overflow: hidden;
      position: relative;

      .delete {
        width: 24px;
        height: 24px;
        line-height: 24px;
        position: absolute;
        left: calc(100% - 170px);
        top: 18px;
        background-color: #3f3f3f;
        color: #fff;
        border-radius: 100%;
        text-align: center;
        cursor: pointer;
        z-index: 2;

        &:before {
          content: url(${images.IconCloseWhite});
        }

        &:hover {
          background-color: #004a8f;
        }
      }

      .tab-label {
        display: flex;
        justify-content: space-between;
        padding: 1em;
        background: #fff;
        color: #3f3f3f;
        border: 1px solid #f4f4f4;
        font-size: 18px;
        height: 60px;

        &:after {
          content: "❯";
          width: 30px;
          height: 30px;
          text-align: center;
          transition: all 300ms;
          transform: rotate(90deg);
          border-radius: 100%;
          text-align: center;
          line-height: 30px;
          margin-top: -5px;
        }

        span {
          transition: all 300ms;
        }
      }

      .tab-content {
        height: 0;
        padding: 0 1em;
        color: #2c3e50;
        background: #fff;
        transition: all 300ms;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
      }

      .tab-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        background: #2c3e50;
        cursor: pointer;

        &:hover {
          background: #1a252f;
        }
      }

      input[type="checkbox"] {
        position: absolute;
        right: 2px;
        opacity: 0;
        width: 60px;
        height: 60px;
        cursor: pointer;
        z-index: 1;
        margin: 0;

        &:hover {
          + {
            .tab-label:after {
              background-color: #efefef;
            }
          }
        }

        &:checked {
          + {
            .tab-label {
              font-weight: bold;
            }

            .tab-label:after {
              transform: rotate(270deg);
            }
          }

          ~ .tab-content {
            min-height: 70px;
            height: auto;
            padding: 25px 15px 20px;
            opacity: 1;
            box-shadow: 0px 4px 10px -3px #e0e0e0;
          }
        }
      }
    }
  }

  .result-simulation-item {
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
    margin-bottom: 24px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
    
    &.float {
      width: 100%;
      display: block;
      float: left;
    }

    .result-simulation-item-content {
      h5 {
        color: ${colors["PR-001-100"]};
        padding-left: 30px;
        position: relative;
        font-size: 24px;

        &:before {
          content: url(${images.IconCheckBlue});
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-right: 8px;
          position: absolute;
          left: 0;
          top: 1px;
        }
      }
    }
  }

  .result-simulation-item-title {
    color: ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 24px;
  }

  .result-simulation-footer {
    padding-top: 37px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 80px;
    color: ${colors["PR-001-100"]};
    border-top: 1px solid ${colors["PR-001-100"]};

    ${NoteTextContainer} {
      line-height: 1.5em;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  .detail-pendant-simulation-divider {
    border: 0.5px solid ${colors["PR-001-100"]};
    height: 0;
    width: 100%;
    margin-top: 40px;
  }

  .detail-pendant-simulation-footer {
    padding-top: 37px;
    margin-bottom: 40px;
    color: ${colors["PR-001-100"]};
    padding-left: 40px;
    padding-right: 40px;
    ${NoteTextContainer} {
      line-height: 1.5em;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .result-simulation-container {
    margin-bottom: 40px;
  }
`;
