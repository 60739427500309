import React, { Component } from "react";
import { CheckboxModalContainer } from "../../checkbox-modal/checkbox-modal-style";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { images } from "../../../assets";
import H4Text from "../../components-text/h4-text";
import MainButton from "../../main-button";
import CheckboxItem from "../../checkbox-item";

interface MultiSelectModalRouterProps { }

interface MultiSelectModalProps
  extends RouteComponentProps<MultiSelectModalRouterProps> {
  className?: string;
  onClickAccept: Function;
  onCloseModal: Function;
  mainTitle: string;
  active?: boolean;
  checkConditions: any[] | any;
  enableAcceptButton?: boolean;
  defaultValues: Array<string>;
}

interface MultiSelectModalState {
  active: string;
  defaultValues: Array<string>;
  selectedValues: Array<string>;
}

class MultiSelectModalLayout extends Component<
  MultiSelectModalProps,
  MultiSelectModalState
> {
  readonly state: MultiSelectModalState;

  public static defaultProps = {
    active: false,
    onClickAccept: () => { },
    onCloseModal: () => { },
    className: "",
    mainTitle: "",
    checkConditions: [],
    enableAcceptButton: false,
    defaultValues: [],
    selectedValues: [],
  };

  constructor(props: MultiSelectModalProps) {
    super(props);
    this.state = {
      active: props.active ? "active" : "",
      defaultValues: props.defaultValues,
      selectedValues: props.defaultValues
    };
  }

  openCheckboxModal() {
    this.setState({ active: "active" });
  }

  closeMultiSelectModal() {
    this.setState({ active: "" });
    this.props.onCloseModal();
  }

  acceptMultiSelectModal() {
    this.props.onClickAccept(this.state.selectedValues);
    this.setState({ active: "" });
  }

  handleChange(e: any) {
    let isChecked = e.target.checked;
    let name = e.target.name;

    if (isChecked) {
      this.state.selectedValues.push(name);
    } else {
      let filtered = this.state.selectedValues.filter((item) => item !== name);
      this.setState({ selectedValues: filtered });
    }


  }
  componentDidMount() {
    const { defaultValues } = this.props;
    let selected = [];
    for (const val in defaultValues) {
      selected.push(defaultValues[val])
    }
    this.setState({
      selectedValues: selected
    })
  }
  render() {
    const {
      mainTitle,
      className,
      checkConditions,
      enableAcceptButton,
      defaultValues,
    } = this.props;
    const { active, selectedValues } = this.state;

    return (
      <CheckboxModalContainer className={`${active} ${className}`}>
        <div className="checkbox-modal-container">
          <div className="checkbox-modal-close">
            <img
              src={images.IconCloseBlue}
              onClick={() => this.closeMultiSelectModal()}
            />
          </div>

          <div className="checkbox-modal-main">
            <div className="checkbox-modal-main-title">
              <div className="checkbox-modal-text">
                <H4Text>{mainTitle}</H4Text>
              </div>
            </div>

            <div className="checkbox-multiselect-main-content">
              <div className="checkbox-modal-main-copy">
                {Object.keys(checkConditions).map((key: any, i) => (
                  <div key={i} className="checkbox-modal-main-conditions-item">
                    <CheckboxItem
                      checked={selectedValues.find(
                        (value) => value === checkConditions[key].label
                      )}
                      className={"isMulti"}
                      checkboxText={checkConditions[key].label}
                      name={checkConditions[key].label}
                      onChange={(e: any) => this.handleChange(e)}
                    />
                  </div>
                ))}
              </div>

              <div className="checkbox-modal-button">
                <MainButton
                  type={"white"}
                  text="Volver"
                  onClick={() => this.closeMultiSelectModal()}
                />
                <MainButton
                  disabled={enableAcceptButton}
                  text="Aceptar"
                  onClick={() => this.acceptMultiSelectModal()}
                />
              </div>
            </div>
          </div>
        </div>
      </CheckboxModalContainer>
    );
  }
}

export default withRouter(MultiSelectModalLayout);
