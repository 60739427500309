interface InterfaceErrorMessage {
  required: string;
  "invalid-num-chip": string;
  "invalid-format": string;
  "invalid-document-value": string,
  "invalid-document-type-value": string,
  "dni-repeat": string;
  "num-chip-repeat": string;
  "invalid-prefix": string;
  "invalid-email": string;
  "field-empty": string;
  "form-error": string;
  "server-error": string;
  "login-error": string;
  "min-error": string;
  "max-error": string;
  "number-error": string;
  "invalid-iban": string;
  "positive-number-error": string;
  "invalid-address": string;
  "document-duplicated": string;
  "max-num-of-pets": string;
  "invalid-identification": string;
  "unknow-error": string;
  "no-data": string;
  "no-type-selected": string;
  "file-not-found": string;
  "max-error-mascots-10": string;
  "max-length-policyNumber": string;
  [key: string]: string;
}

export const ERROR_MESSAGES: InterfaceErrorMessage = {
  required: "Campo obligatorio",
  "invalid-num-chip": "El chip consta de 15 carácteres numéricos",
  "invalid-format": "Formato no válido",
  "invalid-document-dni": "El dni introducido no es válido",
  "invalid-document-value": "El Numero de documento es obligatorio al indicar Documento de identidad",
  "invalid-document-type-value": "El Documento de identidad es obligatorio al indicar Numero de documento",
  "dni-repeat": "DNI repetido",
  "num-chip-repeat": "Número de microchip repetido",
  "invalid-prefix": "Prefijo no válido",
  "invalid-email": "Email no válido",
  "field-empty": "Revisa que has rellenado todo correctamente",
  "form-error": "Revisa que has rellenado todo correctamente",
  "server-error": "Ha ocurrido un fallo en el servidor",
  "login-error": "Usuario no existe o password errónea",
  "login-blocked":
    "La cuenta se ha bloqueado porque han habido demasiados intentos fallidos de inicio de sesión intente de nuevo",
  "min-error": "El número no llega al mínimo",
  "max-error": "El número se pasa del máximo",
  "number-error": "Debe ser un número",
  "invalid-iban": "Iban no válido",
  "positive-number-error": "El número tiene que ser mayor de 0",
  "invalid-70-age": "El asegurado no puede ser mayor de 70 años",
  "invalid-address": "La dirección no es válida",
  "invalid-second-date": "La fecha de fin no puede menor que la de inicio",
  "invalid-first-date": "La fecha de inicio no puede ser mayor que la de fin",
  "invalid-follow-start-date":
    "La fecha de inicio de seguimiento no puede ser menor que el fin del periodo",
  "invalid-follow-end-date":
    "La fecha de fin de seguimiento no puede ser mayor que la de fin",
  "document-duplicated": "ya existe un asegurado con el documento ingresado",
  "max-num-of-pets": "máximo de mascotas a asegurar: 5",
  "invalid-identification": 'Introduce un NIF/CIF/NIE válido',
  "unknow-error": "Se ha producido un error. Inténtelo más tarde.",
  "no-data": "No hay datos.",
  "no-type-selected": "Debe escoger un tipo de fichero.",
  "file-not-found": "No se ha encontrado el fichero de recibos pendientes para el usuario actual.",
  "invalid-phone-number": "Introduzca un número válido",
  "repeated-document-number": "El número de documento ya pertenece a otro asegurado",
  "repeated-document-number-taker": "El número de documento ya pertenece al tomador",
  "repeated-document-number-insured": "El número de documento pertenece a un asegurado",
  "invalid-postal-code": "El código postal no es válido",
  'invalid-date': 'La fecha no es válida',
  '0-not-valid-percentage': 'El porcentaje no puede ser 0',
  'insured-not-beneficiary': 'El documento ingresado corresponde al asegurado',
  'iban-copago-same-as-main': 'El iban del copago agrupado debe ser diferente al del pago del seguro',
  "underage": "El tomador debe tener mínimo 18 años.",
  "no-decimals": "No puede contener decimales.",
  "unknow-error-sign-deleted": "Error al solicitar nueva firma, petición de firma anterior cancelada",
  "unknow-error-sign-not-deleted": "Error al cancelar la petición de firma existente",
  "badDate" : "Fecha de ida superior a la fecha de vuelta",
  "hocelot-verify-error": "Error al verificar correo electrónico.",
  "max-error-mascots-10": "El número máximo de mascotas a asegurar es 10",
  "max-length-policyNumber": "Longitud máxima es de 15 números"
};

export const SENDA_ERROR_CODES: any = {
  AV100: "Error en los datos de operador",
  AV200: "Error en los datos de póliza",
  AV201: "Error en los Otros datos de los datos de poliza",
  AV300: "Error en los datos de Tomador",
  AV301: "Error en los datos de pago del Tomador",
  AV302: "Error en los datos de contacto del Tomador",
  AV303: "Error en los datos de domicilio del Tomador",
  AV400: "Error en los pagos",
  AV500: "Error en las campañas",
  AV600: "Error en los productos",
  AV610: "Error en los objetos de riesgo",
  AV611: "Error en los datos de asegurado de riesgo",
  AV612: "Error en los datos de contacto de asegurado",
  AV613: "Error en los datos de domicilio de asegurado",
  AV614: "Error en los datos de pago de asegurado",
  AV615: "Error en los datos del cuestionario de asegurado",
  AV616: "Error en los datos de cláusula de asegurado",
  AV617: "Error en la profesión de asegurado",
  AV620: "Error en los datos de un producto de riesgo",
  AV621: "Error en los datos de coberturas",
  AV622: "Error en los datos de cobertura de cobertura",
  AV623: "Error en los datos de sobreprimas",
  AV624: "Error en los datos de extraprimas",
  AV625: "Error en los datos de descuentos de cobertura",
  AV626: "Error en los datos de las campañas de una cobertura",
  AV627: "Error en datos de franquicia de cobertura",
  AV637: "Error en datos de viaje",
};
