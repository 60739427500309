import { capitalizeFirstLetter } from ".";
import { IHealthCoverage } from "../constants/health";

export const formatCoverages = (coveragesData: any) => {
  if (!coveragesData) return null;
  const coverages: any = {
    main: {
      title: "Garantías obligatorias",
      items: []
    },
    complementary: {
      title: "Garantías opcionales",
      items: []
    }
  };
  const requiredCov: any = {};
  const dependantCov: any = {};

  coveragesData.forEach((cov: any) => {
    if (cov.mainCoverage) {
      if (requiredCov[cov.mainCoverage]) {
        requiredCov[cov.code] = [cov.mainCoverage].concat(
          requiredCov[cov.mainCoverage]
        );
      } else {
        requiredCov[cov.code] = [cov.mainCoverage];
      }
    }
  });
  Object.entries(requiredCov).forEach(entry => {
    const key = entry[0];
    const dependant: any = [];
    Object.entries(requiredCov).forEach((entry1: any) => {
      if (entry1[1].includes(key)) {
        dependant.push(entry1[0]);
      }
    });
    dependantCov[key] = dependant;
  });
  coveragesData.forEach((cov: any) => {
    if (cov.required) {
      coverages.main.items.push({
        name: capitalizeFirstLetter(cov.name.toLowerCase()),
        code: cov.code,
        checkbox: true,
        disabled: true,
        optionCode: cov.optioncode,
      });
      if (cov.maxValue > 0) {
        coverages.main.items[coverages.main.items.length - 1].capital = {
          min: cov.minValue,
          max: cov.maxValue,
        };
      }
    } else {
      coverages.complementary.items.push({
        name: capitalizeFirstLetter(cov.name.toLowerCase()),
        code: cov.code,
        checkbox: true,
        disabled: false,
        requiredCoverages: requiredCov[cov.code],
        dependantCoverages: dependantCov[cov.code],
        optionCode: cov.optioncode
      });
      if (cov.maxValue > 0) {
        coverages.complementary.items[
          coverages.complementary.items.length - 1
        ].capital = {
          min: cov.minValue,
          max: cov.maxValue,
        };
      }
    }
  });
  return coverages;
};

export const isCapitalInRange = (coverage: any) => {
  const value = coverage.capital.value
  let min, max
  if(coverage.max){
    min = coverage.min 
    max = coverage.max
  }else{
    const capitalValues = coverage.options.map((capital: { label: string, value: string}) => Number(capital.value))
    min = Math.min(...capitalValues)
    max = Math.max(...capitalValues)
  }
          
  if(value<min){
    return 'min-error'
  }else if(value>max){
    return 'max-error'
  }
  return ''
}

export const isCapitalInRangeHealth = (covCapital: any, capital: number, coverageCode: string) => {
  const coverageIndex = covCapital.items.findIndex((cov:any) => cov.code === coverageCode)
  const capitalValues = covCapital.items[coverageIndex].capital.options.map((option:any) => Number(option.value))
  const min = Math.min(...capitalValues)
  const max = Math.max(...capitalValues)
  
  if(capital < min){
    return "min-error"
  }else if(capital > max){
    return "max-error"
  }
  return ''

}
// export const isCapitalInRangeHealthCoverages = (coverage:any) => {
//   const coverageIndex = covCapital.items.findIndex((cov:any) => cov.code === coverageCode)
//   const capitalValues = covCapital.items[coverageIndex].capital.options.map((option:any) => Number(option.value))
//   const min = Math.min(...capitalValues)
//   const max = Math.max(...capitalValues)

//   if(capital < min){
//     return "min-error"
//   }else if(capital > max){
//     return "max-error"
//   }
//   return ''

// }
export const formatCapitalText = (capitales: { importe: string }[]) => {
  return capitales.map(function (item) {
    return { label: `${item.importe}€`, value: item.importe };
  });
}
export const isCapitalInRangeHealthByCoverage = (coverage: IHealthCoverage) => {
  const capitales = formatCapitalText(coverage.capitales)
  const capitalValues = capitales.map((option: any) => Number(option.value))
  const min = Math.min(...capitalValues)
  const max = Math.max(...capitalValues)

  if (Number(coverage.capital) < min) {
    return "min-error"
  } else if (Number(coverage.capital) > max) {
    return "max-error"
  }
  return ''

}

export const capitalValidation = (coverages: any) => {
  const validatedCoverages = {...coverages}
  let error=false
  Object.keys(coverages).forEach(coverageCode => {
    if(coverages[coverageCode].value && coverages[coverageCode].capital){
      if(coverages[coverageCode].capital.value){
        const rangeError= isCapitalInRange(coverages[coverageCode])
        if(rangeError!==''){
          error=true
          validatedCoverages[coverageCode].capital.error = true
          validatedCoverages[coverageCode].capital.errorCode = rangeError
        }         
      }else{
        error=true
        validatedCoverages[coverageCode].capital.error = true
        validatedCoverages[coverageCode].capital.errorCode = 'required'
      }
    }
    if(coverages[coverageCode].otherInsurance && coverages[coverageCode].otherInsurance!==null){
      if(coverages[coverageCode].otherInsurance.company.required && coverages[coverageCode].otherInsurance.company.value === ""){
        validatedCoverages[coverageCode].otherInsurance.company.error = true;
        validatedCoverages[coverageCode].otherInsurance.company.errorCode = "required";
      }
      if(coverages[coverageCode].otherInsurance.numberPolicy.required && coverages[coverageCode].otherInsurance.numberPolicy.value === ""){
        validatedCoverages[coverageCode].otherInsurance.numberPolicy.error = true;
        validatedCoverages[coverageCode].otherInsurance.numberPolicy.errorCode = "required";
      }
    }
  })
  return error ? validatedCoverages : {}
}


//COVERAGE AGE<MIN_AGE OR AGE>MAX_AGE
export const isOutOfCoverageAgeRange = (age: number, edadMin: string, edadMax: string): boolean => {
  const insuredAge = age || 0;
  const minAge = parseInt(edadMin) || 0;
  const maxAge = parseInt(edadMax) || 0;
  let isOutOfRange = false

  if(maxAge===0 || minAge===0){
    if(maxAge===0 && minAge===0){
      isOutOfRange=false
    }else if( (maxAge===0 && insuredAge<minAge) || (minAge===0 && insuredAge>maxAge)){
      isOutOfRange = true
    }
  }else if(insuredAge < minAge || insuredAge > maxAge) {
    isOutOfRange = true
  }
  return isOutOfRange
}

export const formatCoveragesTravel = (coveragesData: any) => {
  const coveragesObj: any = [];

  coveragesData.forEach((coverage: any) => {
    let desc = "";
    if (coverage.name)
      desc = capitalizeFirstLetter(coverage.name.toLowerCase())
      
    let coverageObj: any = {
      name: coverage.name,
      code: coverage.code,
      checked: false,
      description: desc,
      required: coverage.required ? coverage.required : false,
      edadMin: coverage.edadMin ? coverage.edadMin : "",
      edadMax: coverage.edadMax ? coverage.edadMax : "",
      capMaxCober: coverage.capMaxCober,
      capMinCober: coverage.capMinCober,
      capital: 1,
      type: coverage.code
    }

    coveragesObj.push(coverageObj)

  })
  return coveragesObj
}
