import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DetailPendantSimulationContainer } from "./detail-pendant-simulation-style";
import Title03Text from "../../../../components/components-text/title-03-text";
import HeadText from "../../../../components/components-text/head-text";
import NoteText from "../../../../components/components-text/note-text";
import WebText from "../../../../components/components-text/web-text";
import MainTable from "../../../../components/main-table";
import GoBack from "../../../../components/go-back";
import { withGeneral } from "../../../../context/mixin/with-general";
import SimulationServices from "../../../../services/SimulationServices";
import {
  AREA_NAMES,
} from "../../../../constants/policyType";
import { getDayMonthYear } from "../../../../utils/time";
import { capitalizeFirstLetter, currencyFormat } from "../../../../utils";
import { images } from "../../../../assets";
import LeftInfoModal from "../../../../components/left-info-modal";
import MainButton from "../../../../components/main-button";
import { getDataForm } from "../../../../utils/mapData";
import { addDays } from "date-fns";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import { downloadFile, convertBase64ToFile, formatter, deepGet } from "../../../../utils/utility";
import moment from "moment";
import { withDynamicProducts } from "../../../../context/mixin/with-dynamic-products";
import PayTypesServices from "../../../../services/PayTypesServices";
import { withPayTypes } from "../../../../context/mixin/with-pay-types";
import { PAY_TYPES } from "../../../../constants/payTypes";
import { SPANISH_COUNTRY_OPTION } from "../../../../constants/optionsTravelPolicy";
import DicotomicCheckbox from "../../../../components/dicotomic-checkbox/dicotomic-checkbox";
import GipServices from "../../../../services/GipServices";
import { formatInsured, formatPayments } from "../../../../utils/GIPSimulationDocument";
import { isValidEmail } from "../../../../utils/validation";
import { ProductPayType } from "../../../../services/PayTypesServices.dto";
import FlowServices from "../../../../services/FlowServices";

interface RouteParams {
  id: string;
  mediator: string;
}
interface DetailPendantSimulationProps
  extends RouteComponentProps<RouteParams> {
  setLoading: Function;
  coverages: any;
  productsByAreaName: any;
  checkProductArea: Function;
  allProducts: any;
  allPayTypes: any;
  travelCountries: any;
}

interface DetailPendantSimulationState {
  simulation: any;
  memberTableRows1: any[];
  memberTableRows2: any[];
  paymentTableRows: any[];
  memberTableRows1Travel: any[];
  guaranteeTableRows: any[];
  showLeftInfoModal: boolean;
  leftInfoModalImg: string;
  leftInfoModalClassName: string;
  leftInfoModalTitle: string;
  leftInfoModalText: string;
  leftInfoModalButtonText: string;
  leftInfoModalClick: Function;
  leftInfoModalWithInput: any;
  product: any;
  sendPDFEmail: {
    value: string,
    errorCode: string,
    labelText: string
  };
}

class DetailPendantSimulation extends React.Component<
  DetailPendantSimulationProps,
  DetailPendantSimulationState
> {
  readonly state: DetailPendantSimulationState;

  constructor(props: DetailPendantSimulationProps) {
    super(props);
    this.state = {
      simulation: {
        body: {
          taker: {},
          insureds: [],
        },
        response: {
          documents: [],
        },
      },
      memberTableRows1: [],
      memberTableRows2: [],
      paymentTableRows: [],
      memberTableRows1Travel: [],
      guaranteeTableRows: [],
      showLeftInfoModal: false,
      leftInfoModalImg: "",
      leftInfoModalClassName: "",
      leftInfoModalTitle: "",
      leftInfoModalText: "",
      leftInfoModalButtonText: "",
      leftInfoModalClick: () => { },
      leftInfoModalWithInput: null,
      product: {},
      sendPDFEmail: {
        value: "",
        errorCode: "",
        labelText: "Email"
      },
    };
  }

  componentDidMount() {
    const { productsByAreaName, allProducts } = this.props

    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    if (Object.keys(productsByAreaName).length !== 0 && Object.keys(allProducts).length !== 0) {
      this.getSimulation();
    }
  }

  componentDidUpdate(prevProps: Readonly<DetailPendantSimulationProps>, prevState: Readonly<DetailPendantSimulationState>, snapshot?: any): void {
    const { productsByAreaName, allProducts } = this.props

    if (Object.keys(prevProps.productsByAreaName).length === 0 && Object.keys(productsByAreaName).length !== 0 && Object.keys(allProducts).length !== 0
      || Object.keys(prevProps.allProducts).length === 0 && Object.keys(allProducts).length !== 0 && Object.keys(productsByAreaName).length !== 0) {
      this.getSimulation();
    }
  }

  getMemberTableColumns1 = () => [
    {
      text: "Tomador",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Provincia",
      filter: false,
      ascendent: true,
    },
    {
      text: "Teléfono",
      filter: false,
      ascendent: false,
    },
    {
      text: "Email",
      filter: false,
      ascendent: true,
    },
  ];

  getMemberTableColumns2 = () => [
    {
      text: "Asegurados",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Nacimiento",
      filter: false,
      ascendent: true,
    },
    {
      text: "Teléfono",
      filter: false,
      ascendent: false,
    },
    {
      text: "Email",
      filter: false,
      ascendent: true,
    },
  ];

  getMemberTableColumns3 = () => [
    {
      text: "Asegurados",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Nacimiento",
      filter: false,
      ascendent: true,
    }
  ];

  getMemberTableColumns1Travel = () => [
    {
      text: "Num. Pasajeros",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Destino",
      filter: false,
      ascendent: true,
    },
    {
      text: "Fecha Ida",
      filter: false,
      ascendent: false,
    },
    {
      text: "Fecha Vuelta",
      filter: false,
      ascendent: true,
    },
  ];

  getPaymentTableColumns = () => [
    {
      text: "Periodicidad",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Total prima neta",
      filter: false,
      ascendent: true,
      key: "",
    },
    {
      text: "Impuestos y consorcio",
      filter: false,
      ascendent: false,
      key: "",
    },
    {
      text: "Total recibo",
      filter: false,
      ascendent: true,
      key: "",
    },
  ];

  getPaymentTableColumns2 = () => [
    {
      text: "Periodicidad",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Total prima neta",
      filter: false,
      ascendent: true,
      key: "",
    },
    {
      text: "Prima neta por mes",
      filter: false,
      ascendent: true,
      key: "",
    },
    {
      text: "Prima neta por año",
      filter: false,
      ascendent: true,
      key: "",
    },
  ];

  getGuaranteeTableColumns = () => [
    {
      text: "Coberturas incluidas en la póliza",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
    {
      text: "Capital",
      filter: false,
      ascendent: true,
      key: "highlighted",
    },
  ];

  getSimulation = async () => {
    const { match, setLoading, checkProductArea, allProducts, travelCountries } = this.props;

    const simulation = await SimulationServices.getSimulation({
      loader: true,
      setLoading: setLoading,
      id: match.params.id,
      mediator: match.params.mediator,
    });

    const product = allProducts[simulation.productType]
    this.setState({ product })
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, simulation.productType)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, simulation.productType)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, simulation.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, simulation.productType)
    const isPetArea = checkProductArea(AREA_NAMES.PET, simulation.productType)

    let memberTableRows1Travel = [{}]

    const memberTableRows1 = [
      {
        taker: {
          text: simulation.body.taker
            ? `${simulation.body.taker.name} ${simulation.body.taker.lastname1 ? simulation.body.taker.lastname1 : ''} ${simulation.body.taker.lastname2 ? simulation.body.taker.lastname2 : ''}`
            : "-",
          type: "",
          link: "",
          key: "highlighted",
        },
        province: {
          text:
            simulation.body.taker && simulation.body.taker.address
              ? simulation.body.taker.address.provinceLabel
              : "-",
          type: "",
          link: "",
          key: "body",
        },
        phone: {
          text: simulation.body.taker
            ? simulation.body.taker.movilPhoneNumber
            : "-",
          type: "",
          link: "",
          key: "body",
        },
        email: {
          text: simulation.body.taker ? simulation.body.taker.email : "-",
          type: "",
          link: "",
          key: "body",
        },
      },
    ];

    let memberTableRows2 =
      simulation.body.insureds && simulation.body.insureds.map
        ? simulation.body.insureds.map((insured: any) => ({
          insured: {
            text: `${insured.name} ${insured.lastname1 && insured.lastname1} ${insured.lastname2 && insured.lastname2}`,
            type: "",
            link: "",
            key: "highlighted",
          },
          "birth-date": {
            text: getDayMonthYear(insured.birthdate),
            type: "",
            link: "",
            key: "body",
          },
          phone: {
            text: insured.movilPhoneNumber,
            type: "",
            link: "",
            key: "body",
          },
          email: {
            text: insured.email,
            type: "",
            link: "",
            key: "body",
          },
        }))
        : [];

    if (isHealthArea || isGIPArea || isDentalArea) {
      if (simulation.response.responseContent) {
        memberTableRows2 =
          simulation.body.insuredData && simulation.body.insuredData.map
            ? simulation.body.insuredData.map((insured: any, index: number) => ({
              insured: {
                text: `Asegurado ${index + 1}`,
                type: "",
                link: "",
                key: "highlighted",
              },
              "birth-date": {
                text: getDayMonthYear(insured.birthDate),
                type: "",
                link: "",
                key: "body",
              }
            }))
            : [];
      }
      else {
        memberTableRows2 =
          simulation.body.insuredData && simulation.body.insuredData.map
            ? simulation.body.insuredData.map((insured: any, index: number) => ({
              insured: {
                text: `Asegurado ${index + 1}`,
                type: "",
                link: "",
                key: "highlighted",
              },
              "birth-date": {
                text: getDayMonthYear(insured.birthDate),
                type: "",
                link: "",
                key: "body",
              },
              phone: {
                text: insured.movilPhoneNumber,
                type: "",
                link: "",
                key: "body",
              },
              email: {
                text: insured.email,
                type: "",
                link: "",
                key: "body",
              },
            }))
            : [];
      }
    }
    if (isTravelArea) {
      memberTableRows1Travel = [
        {
          numPassengers: {
            text: simulation.body.taker.policy.numPassengers,
            type: "",
            link: "",
            key: "highlighted",
          },
          travelDestination: {
            text: simulation.body && simulation.body.taker && simulation.body.taker.policy && simulation.body.taker.policy.travelDestination
              && Object.keys(travelCountries).length
              ? travelCountries[simulation.body.taker.policy.travelDestination]
              : SPANISH_COUNTRY_OPTION,
            type: "",
            link: "",
            key: "body",
          },
          departureDate: {
            text: simulation.body.taker.policy.departureDate,
            type: "",
            link: "",
            key: "body",
          },
          returnDate: {
            text: simulation.body.taker.policy.returnDate,
            type: "",
            link: "",
            key: "body",
          },
        },
      ];
    }
    let paymentTableRows;

    const args = {
      loader: true,
      setLoading: setLoading,
      productId: product.products_id,
    };
    const productPayTypes = await PayTypesServices.getProductPayTypes(args);

    if (simulation.response.responseContent) {
      paymentTableRows = this.getPaymentTableRows2(simulation.response);
    }
    else {
      paymentTableRows = (isHealthArea || isDentalArea) ? this.getHealthPaymentTableRows(simulation.response, productPayTypes) : this.getPaymentTableRows(simulation.response);
    }

    this.setState({ simulation })
    let guaranteeTableRows
    const coverages: any = {};
    if (isHealthArea || isDentalArea || isGIPArea) {
      simulation.body.insuredData.forEach &&
        simulation.body.insuredData.forEach((insured: any) => {
          if (insured.coverages) {
            insured.coverages.forEach((c: any) => {
              coverages[c.code] = c;
            });
          }
        });
      this.setState({ simulation })
    }
    else {
      simulation.body.insureds.forEach &&
        simulation.body.insureds.forEach((insured: any) => {
          if (insured.coverages) {
            insured.coverages.forEach((c: any) => {
              coverages[c.code] = c;
            });
          }
        });
    }

    if (isPetArea) {
      simulation.response.insured.forEach &&
        simulation.response.insured.forEach((insured: any) => {
          if (insured.coverages) {
            insured.coverages.forEach((c: any) => {
              coverages[c.code] = c;
            });
          }
        });
    }
    if (simulation.response) {
      if (!isTravelArea) {
        guaranteeTableRows = this.getGuaranteeTableRows(simulation.response);
      } else {
        guaranteeTableRows = this.getGuaranteeTableRowsTravel(simulation.body)
      }

      this.setState({
        simulation,
        memberTableRows1,
        memberTableRows2,
        paymentTableRows,
        memberTableRows1Travel,
        guaranteeTableRows,
      });
    }
  };
  getGuaranteeTableRowsTravel = (body: any) => {
    let coverages: any[] = []

    body.policyData.coverages.map((coverage: any) => {
      coverages.push({
        name: coverage.description,
        capital: 1
      })
    })

    return coverages.map((c: any) => ({
      coverages: {
        text: c.name ? capitalizeFirstLetter(c.name.toLowerCase()) : "",
        type: "",
        link: "",
        key: "entire-mini-body",
      },
      capital: {
        text: c.capital === 1 || c.capital === formatter.format(1.00) ? "Incluido" : currencyFormat(c.capital) + " €",
        type: "",
        link: "",
        key: "highlighted",
      },
    }));
  }
  getGuaranteeTableRows = (dataForm: any) => {
    let coverages: any[] = [];
    // if (!dataForm.body) {
    //   return coverages;
    // }
    const { simulation } = this.state;
    const { checkProductArea } = this.props;

    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, simulation.productType)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, simulation.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, simulation.productType)

    if ((!isHealthArea && !isGIPArea && !isDentalArea) && dataForm.insured[0] && dataForm.insured[0].coverages) {
      dataForm.insured.forEach((insured: any) => {
        insured.coverages.forEach((coverage: any) => {
          if (!coverage.isNotShow && coverage.code) {
            coverages.push({
              name: coverage.name,
              capital: coverage.capital || 1,
            });
          }
        });
      });
    }
    else {
      simulation.body.insuredData.forEach((insured: any) => {
        if (insured.coverages && insured.coverages.length > 0)
          insured.coverages.forEach((coverage: any) => {
            if ((coverage.checked || coverage.required) && coverage.code) {
              const created = coverages.find((coverageCreated: any) => {
                if (coverageCreated.name === coverage.description && coverageCreated.capital !== 1) {
                  coverageCreated.capital = Number(coverageCreated.capital) + Number(coverage.capitalValue);
                  return true;
                }
                return false
              })
              if (!created)
                coverages.push({
                  name: coverage.description,
                  capital: coverage.capitalValue || 1,
                  code: coverage.code
                });
            }
          });
      });
      coverages.sort((a, b) => a.code > b.code ? 1 : -1)
    }

    // TAKING OUT DUPLICATES
    coverages = coverages.filter(
      (value: any, index: any, initialArray: any) =>
        initialArray.findIndex((t: any) => t.name === value.name) === index
    );
    //

    return coverages.map((c: any) => ({
      coverages: {
        text: c.name ? capitalizeFirstLetter(c.name.toLowerCase()) : "",
        type: "",
        link: "",
        key: "entire-mini-body",
      },
      capital: {
        text: c.capital === 1 || c.capital === formatter.format(1.00) ? "Incluido" : currencyFormat(c.capital) + " €",
        type: "",
        link: "",
        key: "highlighted",
      },
    }));
  };

  getHealthPaymentTableRows = (simulationResponse: any, productPayTypes: ProductPayType[]) => {
    let payments: any[] = [];
    if (simulationResponse && simulationResponse.body && simulationResponse.body.RESPUESTA) {
      simulationResponse.body.RESPUESTA.RECIBOS.forEach((importePago: any) => {
        if (importePago.INRECIBO === 'S') {
          payments.push({
            payType: importePago.PERIODO_COBRO,
            price: importePago.PRIMANETA,
            taxes: importePago.TOTAL_IMPUESTOS,
            total: importePago.PRIMATOTAL
          })
        }
      })
    }

    const paymentTableRows = payments.map((payment: any, index: number) => ({
      periodicity: {
        text: PayTypesServices.getPayTypeByCode(productPayTypes, payment.payType).description,
        order: PayTypesServices.getPayTypeByCode(productPayTypes, payment.payType).payTypeOrder,
        type: "",
        link: "",
        key: "highlighted",
      },
      netPremium: {
        text: `${currencyFormat(payment.price)} €`,
        type: "",
        link: "",
        key: "body",
      },
      taxs: {
        text: `${currencyFormat(payment.taxes)} €`,
        type: "",
        link: "",
        key: "body",
      },
      total: {
        text: `${currencyFormat(payment.total)} €`,
        type: "",
        link: "",
        key: "body",
      },
    }));

    paymentTableRows.sort(
      (a: any, b: any) => a.periodicity.order - b.periodicity.order
    );

    return paymentTableRows;
  };

  getPaymentTableRows = (responseSimulation: any) => {
    const { allPayTypes } = this.props
    const payments: any[] =
      responseSimulation && responseSimulation.payments
        ? responseSimulation.payments.filter((p: any) => p.payType !== PAY_TYPES.BIME)
        : [];

    const paymentTableRows = payments.map((payment: any, index: number) => ({
      periodicity: {
        text: PayTypesServices.getPayTypeByCode(allPayTypes, payment.payType).description,
        order: PayTypesServices.getPayTypeByCode(allPayTypes, payment.payType).payTypeOrder,
        type: "",
        link: "",
        key: "highlighted",
      },
      netPremium: {
        text: `${currencyFormat(payment.netPremium)} €`,
        type: "",
        link: "",
        key: "body",
      },
      taxs: {
        text: `${currencyFormat(payment.taxs)} €`,
        type: "",
        link: "",
        key: "body",
      },
      total: {
        text: `${currencyFormat(payment.totalPremium)} €`,
        type: "",
        link: "",
        key: "body",
      },
    }));

    paymentTableRows.sort(
      (a: any, b: any) => a.periodicity.order - b.periodicity.order
    );

    return paymentTableRows;
  };

  getPaymentTableRows2 = (responseSimulation: any) => {
    const payments: any[] = responseSimulation.responseContent.salida.ListaProductosResultado[0].ListaTarifasFormaPagoResultado

    const paymentTableRows = payments.map((payment: any, index: number) => ({
      periodicity: {
        text: payment.DesFormaPago,
        type: "",
        link: "",
        key: "highlighted",
      },
      netPremium: {
        text: `${currencyFormat(payment.TotalPrimaNeta)} €`,
        type: "",
        link: "",
        key: "body",
      },
      netPremiumMonth: {
        text: `${currencyFormat(payment.TotalPrimaNetaMes)} €`,
        type: "",
        link: "",
        key: "body",
      },
      netPremiumYear: {
        text: `${currencyFormat(payment.TotalPrimaNetaAnio)} €`,
        type: "",
        link: "",
        key: "body",
      },
    }));

    return paymentTableRows;
  };

  showPdf() {
    const { simulation, product } = this.state;
    const { checkProductArea } = this.props;

    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, simulation.productType)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, simulation.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, simulation.productType)

    this.setState({
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconDownloadBlue,
      leftInfoModalClassName: "",
      leftInfoModalTitle: "¿Quieres descargar la póliza?",
      leftInfoModalText: "",
      leftInfoModalButtonText: "Descargar",
      // leftInfoModalClick: () =>
      //   window.open(simulation.response.documents[0].url)

      // lLAMAR AL GET DOCUMENT

      leftInfoModalClick: async () => {
        try {
          if (isHealthArea) {
            const docsLanguageValue = deepGet(simulation, "body.taker.docsLanguage", "");
            let docsLanguageLabel = "";
            if (docsLanguageValue) {
              const productName = product.products_internalName;
              const productFields = await FlowServices.getSimulationProductFields({loader: true, setLoading: this.props.setLoading, productName});
              const docsLanguageLabels: { label: string, value: string }[] = deepGet(productFields, "data.step1.docsLanguage.options", []) || [];
              const option = docsLanguageLabels.find((lang) => lang.value === docsLanguageValue);
              docsLanguageLabel = option ? option.label : "";
            }

            const doc = await SimulationServices.getHealthSimulationDocument({
              loader: true,
              setLoading: () => { },
              data: {
                mediatorCif: simulation.body.mediatorData.nif,
                mediator: simulation.mediator,
                productType: simulation.productType,
                simulationId: simulation.requestNumber ? simulation.requestNumber.toString() : '',
                email: '',
                mediatorName: simulation.body.mediatorData.name,
                combinacion_comercial_poliza: simulation.body.responseSimulation.body.RESPUESTA.DATOS_COTIZACION.COMBINACION_COMERCIAL_POLIZA,
                ...(docsLanguageLabel ? { language: docsLanguageLabel } : ""),
              }
            });
            if (doc.status === 204) this.showModalDocNotAvailable();
            else downloadFile(new Int8Array(doc.data.data), `Documentacion_Simulacion_${simulation.response.body.RESPUESTA.DATOS_COTIZACION.COTIZACION}.pdf`, "application/pdf", true);
          } else if (isDentalArea) {
            const doc = await SimulationServices.getDentalSimulationDocument({
              loader: true,
              setLoading: () => { },
              data: {
                mediatorCif: simulation.body.mediatorData.nif,
                mediator: simulation.mediator,
                productType: simulation.productType,
                simulationId: simulation.requestNumber.toString(),
                email: '',
                mediatorName: simulation.body.mediatorData.name,
              }
            });
            if (doc.status === 204) this.showModalDocNotAvailable();
            else downloadFile(new Int8Array(doc.data.data), `Documentacion_Simulacion_${simulation.response.DATOS_COTIZACION.COTIZACION}.pdf`, "application/pdf", true);
          } else if (isGIPArea) {
            this.downloadGIPDocument()
          }
          else {
            // Llamar al get docuemnt 
            const document = await SimulationServices.getDocument({
              setLoading: () => { },
              businessLine: simulation.response.businessLine,
              requestNumber: simulation.response.requestNumber,
            });
            const file = convertBase64ToFile(document.data, "application/pdf");
            //downloadFile(file, `poliza.pdf`, "application/pdf", true);
            const doc = await SimulationServices.getSimulationDocument(true, this.props.setLoading, {
              policyType: simulation.productType,
              mediatorCode: simulation.mediator,
              simulationId: simulation.response.requestNumber.toString(),
              businessLine: simulation.response.businessLine,
              bufferBase64: document.data,
            });

            if (doc.status === 204) this.showModalDocNotAvailable();
            else downloadFile(doc.fileData, doc.fileName, doc.fileType);
          }
        } catch (error) {
          this.setState({
            showLeftInfoModal: true,
            leftInfoModalClassName: "",
            leftInfoModalImg: images.WarningError,
            leftInfoModalTitle: "Error al descargar el documento de simulación",
            leftInfoModalText: "",
            leftInfoModalButtonText: "Aceptar",
            leftInfoModalClick: () => {},
          });
        }
      },
    });
  }
  
  showModalDocNotAvailable = (
    title: string = 'No se ha podido recuperar el PDF', 
    message: string = 'La documentación se está generando. Por favor, inténtelo de nuevo transcurridos unos segundos.', 
    buttonText: string= 'Aceptar'
  ) => {
    this.setState({
      ...this.state,
      showLeftInfoModal: true,
      leftInfoModalClassName: "info",
      leftInfoModalImg: images.IconWarningYellow,
      leftInfoModalTitle: title,
      leftInfoModalText: message,
      leftInfoModalButtonText: buttonText,
      leftInfoModalWithInput: null,
      leftInfoModalClick: () => { },
    });
  }

  downloadGIPDocument = async(email?: string) => {
    const { simulation } = this.state;
    const { allProducts } = this.props;

    try {
      const args = {
        loader: true,
        setLoading: () => { },
      };
      const paymentTime = simulation.body.priceData.paymentTime
      const insuredsPrices = simulation.response.responseContent.salida.ListaProductosResultado[0].ListaTarifasPorAsegurado.map((insuredPayment: any) => {
        return insuredPayment.ListaTarifas.find((payment: any)=> payment.DesFormaPago === paymentTime)
      })
      
      const requestData = {
        simulationDate: getDayMonthYear(simulation.createdAt).toLocaleString(),
        mediatorCode: simulation.body.mediatorAssociatedToPolicy,
        mediatorName: simulation.body.mediatorData.name,
        productName: allProducts[simulation.productType] ? allProducts[simulation.productType].products_name : '',
        effectDate: moment(simulation.body.mediatorData.startDate).format('DD/MM/YYYY'),
        insureds: formatInsured(simulation.body.insuredData, insuredsPrices, simulation.body.postalCode),
        payment: simulation.response && simulation.response.responseContent ?
          formatPayments(simulation.response.responseContent.salida.ListaProductosResultado[0].ListaTarifasFormaPagoResultado)
          : [],
        productInternalName: simulation.productType,
        ...(email && {email}) 
      }

      const document = await GipServices.getGIPSimulationDocument(args, requestData)

      if (document && !email) {
        downloadFile(document.fileData, document.fileName, document.fileType)
      }

    } catch (error) {
      this.setState({
        showLeftInfoModal: true,
        leftInfoModalClassName: "",
        leftInfoModalImg: images.WarningError,
        leftInfoModalTitle: "Error al descargar el documento de simulación",
        leftInfoModalText: "",
        leftInfoModalButtonText: "Aceptar",
        leftInfoModalClick: () => {
        },
      });
    }
  } 

  sendPdfEmailClick = async () => {
    const { sendPDFEmail, simulation } = this.state;

    const simulationEmail = simulation.body && simulation.body.taker && simulation.body.taker.email 
    const email = simulationEmail
      ? simulationEmail
      : sendPDFEmail.value

    if(email){
      await this.downloadGIPDocument(email)
    }else{
      this.setState({
        ...this.state,
        showLeftInfoModal: true,
        leftInfoModalImg: images.IconContactCollapsedWhite,
        leftInfoModalClassName: "",
        leftInfoModalTitle: "Envío del PDF",
        leftInfoModalText: 'Por favor, ingrese un email para el envio del PDF',
        leftInfoModalButtonText: "Enviar",
        leftInfoModalClick: async() => { await this.validateAndUpdateSimulation() },
        leftInfoModalWithInput: {
          ...sendPDFEmail,
          onChange: (value: string) => {
            this.setState({
              ...this.state,
              sendPDFEmail: { ...sendPDFEmail, value },
            })
          }
        }
      });
    }
  };

  validateAndUpdateSimulation = async() => {
    const { simulation } = this.state;
    const simulationEmail = simulation.body && simulation.body.taker && simulation.body.taker.email 

    await this.validateHandler()
    if(!simulationEmail)
      await this.updateSimulation()

  }

  validateHandler = async () => {
    const { sendPDFEmail, leftInfoModalWithInput } = this.state;
    
    if (!sendPDFEmail.value || !isValidEmail(sendPDFEmail.value)) {
      this.setState({
        sendPDFEmail: {
          ...sendPDFEmail,
          errorCode: "invalid-email"
        },
        leftInfoModalWithInput: {
          ...leftInfoModalWithInput,
          errorCode: "invalid-email"
        }
      });
      return;
    }
    this.closeLeftModal();

    await this.downloadGIPDocument(sendPDFEmail.value)

    this.setState({
      ...this.state,
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconContactCollapsedWhite,
      leftInfoModalClassName: "",
      leftInfoModalTitle: "El pdf se ha enviado por correo",
      leftInfoModalText:
        "El presupuesto se ha enviado satisfactoriamente al correo suministrado.",
      leftInfoModalButtonText: "Aceptar",
      leftInfoModalClick: () => { },
      leftInfoModalWithInput: null,
    });
    
  };

  async updateSimulation() {

    const { simulation, sendPDFEmail } = this.state;
    const { setLoading } = this.props;

    try {
      await SimulationServices.simulation({
        loader: true,
        setLoading: setLoading,
        data: {
          body: {
            ...simulation.body,
            taker: {
              email: sendPDFEmail.value
            }

          },
          response: simulation.response,
          productType: simulation.productType,
          id: simulation.id
        },
      });

    } catch (err) {
      this.setState({
        showLeftInfoModal: true,
        leftInfoModalClassName: "",
        leftInfoModalImg: images.WarningError,
        leftInfoModalTitle: "Error al guardar la simulación",
        leftInfoModalText: "",
        leftInfoModalButtonText: "Aceptar",
        leftInfoModalClick: () => {
        },
      });
    }
  }

  async goToHiring() {
    const { simulation } = this.state;
    const { history, checkProductArea, productsByAreaName, allProducts } = this.props;

    const noInsuredsSimulation = JSON.parse(JSON.stringify(simulation.body));
    noInsuredsSimulation.insureds = [];

    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, simulation.productType)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, simulation.productType)
    const isPetArea = checkProductArea(AREA_NAMES.PET, simulation.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, simulation.productType)

    const product = allProducts[simulation.productType]
    const productHiringLink = product.products_link


    this.setState({
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconArrowRightBlue,
      leftInfoModalClassName: "",
      leftInfoModalTitle: "¿Quieres contratar esta simulación?",
      leftInfoModalText: "",
      leftInfoModalButtonText: "Si",
      leftInfoModalClick: () => {
        let dataToPush;
        if (isPetArea) {
          dataToPush = noInsuredsSimulation;
          dataToPush.simulationInsureds = noInsuredsSimulation.coverages;
        } else {
          dataToPush = simulation.body;
          if (isHealthArea || isGIPArea) {
            dataToPush.insureds = dataToPush.insuredData;
            if (!simulation.body.policyType)
             { /* dataToPush.taker.name = ''; */ }
            else
              dataToPush.taker = {};
          }
          if (isDentalArea) {
            var insuredsST = dataToPush.insuredData
            if (dataToPush.insuredData.find((x: any) => x.parentesco === "TOMADOR")) {
              insuredsST = dataToPush.insuredData.filter((x: any) => x.parentesco !== "TOMADOR")
              insuredsST.unshift(dataToPush.insuredData.filter((x: any) => x.parentesco === "TOMADOR")[0])
            }
            dataToPush.insureds = insuredsST
            if (!simulation.body.policyType)
              dataToPush.taker.name = '';
            else
              dataToPush.taker = {};
          }
        }
        const pathname = productHiringLink && productHiringLink.split('?')[0] ? productHiringLink.split('?')[0] : undefined
        const params = productHiringLink && productHiringLink.split('?')[1] ? productHiringLink.split('?')[1] : undefined
        if (simulation.body.policyType) {
          localStorage.setItem('internalName', simulation.body.policyType)
          history.push({
            pathname: `/contratacion${pathname}`,
            search: params,
            state: {
              simulationId: simulation.id,
              dataForm: getDataForm(
                simulation.body,
                simulation.productType,
                product.products_coverageName,
                productsByAreaName
              ),
              data: {
                ...dataToPush,
                payment: {},
                beneficiaries: [],
                loan: {},
                signData: {
                  signId: "",
                  signRequestDate: "",
                  signStatus: ""
                },
              },
              selectedMediatorCode: simulation.mediatorAssociatedToPolicy
            },
          })
        }
        else {
          localStorage.setItem('internalName', simulation.productType)
          history.push({
            pathname: `/contratacion${pathname}`,
            search: params,
            state: {
              simulationId: simulation.id,
              dataForm: getDataForm(
                simulation.body,
                simulation.productType,
                product.products_coverageName,
                productsByAreaName,
                this.props.coverages
              ),
              data: {
                ...dataToPush,
                payment: {},
                beneficiaries: [],
                loan: {},
              },
              selectedMediatorCode: simulation.mediatorAssociatedToPolicy
            },
          })
        }
      }
    });
  }

  closeLeftModal() {
    this.setState({ 
      showLeftInfoModal: false,
      leftInfoModalWithInput: null,
    });
  }

  render() {
    const {
      showLeftInfoModal,
      leftInfoModalImg,
      leftInfoModalClassName,
      leftInfoModalTitle,
      leftInfoModalText,
      leftInfoModalButtonText,
      leftInfoModalClick,
      leftInfoModalWithInput,
      simulation,
      memberTableRows1,
      memberTableRows1Travel,
      memberTableRows2,
      paymentTableRows,
      guaranteeTableRows,
      product
    } = this.state;
    const { checkProductArea } = this.props
    const daysFromCreation = moment.duration(moment().diff(moment(simulation.createdAt))).asDays();

    const isGIPArea = checkProductArea(AREA_NAMES.GIP, simulation.productType)
    const isLifeArea = checkProductArea(AREA_NAMES.LIFE, simulation.productType)
    const isPetArea = checkProductArea(AREA_NAMES.PET, simulation.productType)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, simulation.productType)

    type InsuredType = {
      coverages: CoverageType[];
    };

    type CoverageType = {
      code: string;
      name: string;
      required: boolean;
      capital: string;
    };
    const getTableNumberMascots = (insured: InsuredType, index: number): JSX.Element => {
      const { coverages } = this.props;

      const obtenerCapital = (coverage: CoverageType): string => {
        const objCapital = insured.coverages.find((cov: CoverageType) => cov.code === coverage.code);
        return objCapital ? formatter.format(Number(objCapital.capital)) : "0 €";
      };

      const main: JSX.Element[] = [];
      const complementary: JSX.Element[] = [];

      coverages.pet.forEach((coverage: CoverageType, key: number) => {
        const jsxElement = coverage.required ? (
          <p className="padding-contenido" key={key}>
            {coverage.name}
          </p>
        ) : (
          <div className="flex-space-between padding-cabeceras" key={key}>
            <DicotomicCheckbox
              initChecked={insured.coverages.some((covSelect: CoverageType) => coverage.code === covSelect.code)}
              className="mandatory"
              checkboxText={coverage.name}
                onChange={() => { }}
            />
            <p className="mb-15">{obtenerCapital(coverage)}</p>
          </div>
        );

        coverage.required ? main.push(jsxElement) : complementary.push(jsxElement);
      });

      return (
        <div key={index} className="detail-pendant-simulation-item-content-table bg-white">
          <div className="colSin-100 bg-snow">
            <div className="colSin-25 table-bgBlue padding-cabeceras borderB-white">Mascota {index + 1}</div>
          </div>
          <div className="colSin-50 borderR-white">
            <p className="table-bgBlue padding-cabeceras">GARANTÍAS OBLIGATORIAS</p>
            {main}
          </div>
          <div className="colSin-50">
            <p className="table-bgBlue padding-cabeceras">GARANTÍAS OPCIONALES</p>
            {complementary}
          </div>
        </div>
      );
    };

    return (
      <DetailPendantSimulationContainer>
        <div>
          <div className="detail-pendant-simulation-back">
            <GoBack
              text={`Detalle simulación ${daysFromCreation >= 30
                ? "expirada"
                : "pendiente"
                }`}
            />
          </div>
          <div className="detail-pendant-simulation-main">
            <div className="detail-pendant-simulation-special">
              <div className="detail-pendant-simulation-special-title">
                <Title03Text>
                  <p>ID: {simulation.response.requestNumber ||
                    (simulation.response.body &&
                      simulation.response.body.RESPUESTA &&
                      simulation.response.body.RESPUESTA.DATOS_COTIZACION &&
                      simulation.response.body.RESPUESTA.DATOS_COTIZACION.COTIZACION &&
                      simulation.response.body.RESPUESTA.DATOS_COTIZACION.COTIZACION) || simulation.id}</p>
                  <p>MEDIADOR: {simulation.mediatorAssociatedToPolicy}</p>
                </Title03Text>
              </div>
              <div className="detail-pendant-simulation-special-container">
                <div className="detail-pendant-simulation-special-item">
                  <HeadText>
                    <p>{product && product.products_name ? `Póliza ${product.products_name}` : ''}</p>
                  </HeadText>
                </div>
                <div className="detail-pendant-simulation-special-item">
                  {simulation.body.policyType && product.products_cardName ? (
                    <div className="detail-pendant-simulation-special-subitem">
                      <div className="detail-pendant-simulation-special-subitem-title">
                        <NoteText>
                          <p>Tipo de Póliza</p>
                        </NoteText>
                      </div>
                      <div className="detail-pendant-simulation-special-subitem-content">
                        <WebText>
                          <p>
                            {product.products_cardName}
                          </p>
                        </WebText>
                      </div>
                    </div>
                  )
                    : ""
                  }
                  {this.state.simulation.body.postalCode ? (
                    <div className="detail-pendant-simulation-special-subitem">
                      <div className="detail-pendant-simulation-special-subitem-title">
                        <NoteText>
                          <p>Código Postal</p>
                        </NoteText>
                      </div>
                      <div className="detail-pendant-simulation-special-subitem-content">
                        <WebText>
                          <p>
                            {this.state.simulation.body.postalCode}
                          </p>
                        </WebText>
                      </div>
                    </div>
                  )
                    : ""
                  }
                  <div className="detail-pendant-simulation-special-subitem">
                    <div className="detail-pendant-simulation-special-subitem-title">
                      <NoteText>
                        <p>Simulación</p>
                      </NoteText>
                    </div>
                    <div className="detail-pendant-simulation-special-subitem-content">
                      <WebText>
                        <p>{getDayMonthYear(simulation.createdAt)}</p>
                      </WebText>
                    </div>
                  </div>
                  <div className="detail-pendant-simulation-special-subitem">
                    <div className="detail-pendant-simulation-special-subitem-title">
                      <NoteText>
                        <p>Expira</p>
                      </NoteText>
                    </div>
                    <div className="detail-pendant-simulation-special-subitem-content">
                      <WebText>
                        <p>
                          {getDayMonthYear(addDays(simulation.createdAt, 30))}
                        </p>
                      </WebText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detail-pendant-simulation-container">
              <div className="detail-pendant-simulation-item">
                <div className="detail-pendant-simulation-item-title">
                  <Title03Text>
                    <p>Miembros de la póliza</p>
                  </Title03Text>
                </div>
                {isTravelArea && (<div className="detail-pendant-simulation-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={this.getMemberTableColumns1Travel()}
                    rows={memberTableRows1Travel}
                    keysRow={["numPassengers", "travelDestination", "departureDate", "returnDate"]}
                  />
                </div>)}
                <div className="detail-pendant-simulation-item-content">
                  {((!this.state.simulation.response.responseContent) && !isTravelArea) ?
                    (<div className="detail-pendant-simulation-item-content-table">
                      <MainTable
                        className="table noZebra"
                        columns={this.getMemberTableColumns1()}
                        rows={memberTableRows1}
                        keysRow={["taker", "province", "phone", "email"]}
                      />
                    </div>)
                    : ""}
                  {!isTravelArea && !isPetArea &&
                    <div className="detail-pendant-simulation-item-content-table">
                      {(this.state.simulation.response.responseContent) ?
                        (<MainTable
                          className="table noZebra"
                          columns={this.getMemberTableColumns3()}
                          rows={memberTableRows2}
                          keysRow={["insured", "birth-date"]}
                        />)
                        :
                        (<MainTable
                          className="table noZebra"
                          columns={this.getMemberTableColumns2()}
                          rows={memberTableRows2}
                          keysRow={["insured", "birth-date", "phone", "email"]}
                        />)
                      }
                    </div>
                  }
                </div>
              </div>

              {this.state.simulation.response.responseContent ?
                (<div className="detail-pendant-simulation-item">
                  <div className="detail-pendant-simulation-item-title">
                    <Title03Text>
                      <p>Desglose por forma de pago</p>
                    </Title03Text>
                  </div>
                  <div className="detail-pendant-simulation-item-content">
                    <div className="detail-pendant-simulation-item-content-table">
                      <MainTable
                        className="table noZebra"
                        columns={this.getPaymentTableColumns2()}
                        rows={paymentTableRows}
                        keysRow={["periodicity", "netPremium", "netPremiumMonth", "netPremiumYear"]}
                      />
                    </div>
                    <div className="detail-pendant-simulation-item-content-advise">
                      <NoteText>
                        <p>* Impuesto sobre las Primas de seguro incluido</p>
                      </NoteText>
                    </div>
                  </div>
                </div>)
                :
                paymentTableRows && paymentTableRows.length > 0 && (
                  <div className="detail-pendant-simulation-item">
                    <div className="detail-pendant-simulation-item-title">
                      <Title03Text>
                        <p>Desglose por forma de pago</p>
                      </Title03Text>
                    </div>
                    <div className="detail-pendant-simulation-item-content">
                      <div className="detail-pendant-simulation-item-content-table">
                        <MainTable
                          className="table noZebra"
                          columns={this.getPaymentTableColumns()}
                          rows={paymentTableRows}
                          keysRow={["periodicity", "netPremium", "taxs", "total"]}
                        />
                      </div>
                      <div className="detail-pendant-simulation-item-content-advise">
                        <NoteText>
                          <p>* Impuesto sobre las Primas de seguro incluido</p>
                        </NoteText>
                      </div>
                    </div>
                  </div>
                )
              }
              {!this.state.simulation.response.responseContent ?
                (<div className="detail-pendant-simulation-item">
                  <div className="detail-pendant-simulation-item-title">
                    <Title03Text>
                      <p>Garantías</p>
                    </Title03Text>
                  </div>

                  <div className="detail-pendant-simulation-item-content">
                    <div className="detail-pendant-simulation-item-content-table">
                      {isPetArea ?
                        simulation.response.insured.map((insured: any, i: number) => {
                          return getTableNumberMascots(insured, i);
                        })
                        : <MainTable
                          className="table noZebra"
                          columns={this.getGuaranteeTableColumns()}
                          rows={guaranteeTableRows}
                          keysRow={["coverages", "capital"]}
                        />}
                    </div>
                    <div className="detail-pendant-simulation-item-content-advise">
                      <NoteText>
                        <p>* Impuesto sobre las Primas de seguro incluido</p>
                      </NoteText>
                    </div>
                  </div>
                </div>)
                : ""
              }

            </div>
            <div className="detail-pendant-simulation-divider" />
            <div className="detail-pendant-simulation-footer">
              <ul>
                <NoteText>
                  <li>
                    * Encontrará la nota informativa anexa en el documento
                    Resultado de Simulación PDF, que puede descargar
                  </li>
                </NoteText>
                <NoteText>
                  <li>
                    * Este presupuesto tiene validez durante 30 días naturales,
                    siempre que se mantengan las circunstancias declaradas por
                    los asegurados. La aceptación del proyecto está supeditada a
                    la cumplimentación de la solicitud de seguro y cuestionarios
                    de salud asegurados. Así mismo,{" "}
                    {isLifeArea
                      ? "ASISA VIDA"
                      : "ASISA"}{" "}
                    se reserva el derecho de la aceptación del riesgo.
                  </li>
                </NoteText>
              </ul>
            </div>
          </div>
        </div>
        <div className="detail-pendant-simulation-buttons-container">
          {daysFromCreation < 30 && !isGIPArea && (
            <div className="detail-pendant-simulation-buttons-item">
              <MainButton text="Contratar" onClick={() => this.goToHiring()} />
            </div>
          )}
          {(simulation && simulation.productType) &&
            daysFromCreation < 30 && (
              <div className="detail-pendant-simulation-buttons-item">
                <MainButton
                  type="full-light"
                  text="Descargar PDF"
                  onClick={() => this.showPdf()}
                />
              </div>
            )
          }
          {(simulation && simulation.productType) && isGIPArea &&
            daysFromCreation < 30 && (
              <div className="detail-pendant-simulation-buttons-item">
                <MainButton
                  type="full-light"
                  text="Enviar PDF por email"
                  onClick={() => this.sendPdfEmailClick()}
                />
              </div>
            )
          }
        </div>
        <LeftInfoModal
          img={leftInfoModalImg}
          className={leftInfoModalClassName}
          mainTitle={leftInfoModalTitle}
          mainText={leftInfoModalText}
          buttonText={leftInfoModalButtonText}
          close={() => this.closeLeftModal()}
          active={showLeftInfoModal}
          onClickAccept={leftInfoModalClick}
          withInput={leftInfoModalWithInput}
        />
      </DetailPendantSimulationContainer>
    );
  }
}

export default withGeneral(withDynamicProducts(withCoverages(withPayTypes(withRouter(DetailPendantSimulation)))));
