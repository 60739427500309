import React from "react";
import { GeneralContext } from "../constants";

export const withGeneral = Component => {

  return props => (
    <GeneralContext.Consumer>
      {value => (
        <Component
          {...props}
          isLogged={value.isLogged}
          setIsLogged={value.setIsLogged}
          loading={value.loading}
          setLoading={value.setLoading}
          user={value.user}
          mediator={value.mediator}
          setUser={value.setUser}
          is404={value.is404}
          is403={value.is403}
          setIs404={value.setIs404}
          setIs403={value.setIs403}
          isCampaignConfiguration={value.isCampaignConfiguration}
          hasInformativeNote={value.hasInformativeNote}
          setHasInformativeNote={value.setHasInformativeNote}
          productsByAreaName={value.productsByAreaName}
        />
      )}
    </GeneralContext.Consumer>
  );
};
