import React, { Component } from "react";
import { AddBeneficiariesModalContainer } from "./add-beneficiaries-modal-style";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { images } from "../../assets";
import H4Text from "../components-text/h4-text";
import MainButton from "../main-button";
import WebText from "../components-text/web-text";
import InputBox from "../input-box/input-box";
import CheckboxItem from "../checkbox-item";
import DOC_TYPES from "../../constants/doc_types";
import SelectBox from "../select-box/select-box";
import { isValidDocumentID } from "../../utils/validation";
import BodyText from "../components-text/body-text";

interface AddBeneficiariesModalRouterProps { }

interface AddBeneficiariesModalProps extends RouteComponentProps<AddBeneficiariesModalRouterProps> {
    className?: string;
    img?: string;
    onClickAccept: Function;
    close?: Function;
    mainTitle: string;
    mainText: string;
    buttonText: string;
    active?: boolean;
    beneficiaries: any[];
    beneficiary: any;
    policyType?: string;
    insuredDocNumber?: string;
}

interface AddBeneficiariesModalState {
    active: string;
    beneficiaries: any[];
    beneficiary: any;
    showError: boolean;
    percentageTotal: number;
}

const BENEFICIARY = {
    name: {
        value: '',
        error: false,
        errorCode: '',
        required: true,
    },
    lastName1: {
        value: '',
        error: false,
        errorCode: '',
        required: true,
    },
    lastName2: {
        value: '',
        error: false,
        errorCode: '',
        required: false,
    },
    docType: {
        value: '',
        error: false,
        errorCode: '',
        required: true,
    },
    docNumber: {
        value: '',
        error: false,
        errorCode: '',
        required: true,
    },
    percentage: {
        value: '',
        error: false,
        errorCode: '',
        required: true,
    }
}

class AddBeneficiariesModal extends Component<AddBeneficiariesModalProps, AddBeneficiariesModalState> {
    readonly state: AddBeneficiariesModalState;

    public static defaultProps = {
        active: false,
        img: images.IconSearchBlue,
        onClickAccept: () => { },
        close: () => { },
        className: "",
        mainTitle: "",
        mainText: "",
        buttonText: "",
        policyType: '',
        insuredDocNumber: '',
    };

    constructor(props: AddBeneficiariesModalProps) {
        super(props);   
        
        this.state = {
            active: props.active ? "active" : "",
            beneficiaries: [],
            beneficiary: JSON.parse(JSON.stringify(BENEFICIARY)),
            showError: false,
            percentageTotal: 0
        };
    }

    componentDidUpdate(prevProps: AddBeneficiariesModalProps) {
        if (prevProps.active !== this.props.active) {
            
            this.setState({
                ...this.state,
                active: this.props.active ? "active" : "",
                beneficiaries: JSON.parse(JSON.stringify(this.props.beneficiaries)),
                beneficiary: Object.keys(this.props.beneficiary).length > 0 ? JSON.parse(JSON.stringify(this.props.beneficiary)) :
                    {
                        name: {
                            value: '',
                            error: false,
                            errorCode: '',
                            required: true,
                        },
                        lastName1: {
                            value: '',
                            error: false,
                            errorCode: '',
                            required: true,
                        },
                        lastName2: {
                            value: '',
                            error: false,
                            errorCode: '',
                            required: false,
                        },
                        docType: {
                            value: '',
                            error: false,
                            errorCode: '',
                            required: true,
                        },
                        docNumber: {
                            value: '',
                            error: false,
                            errorCode: '',
                            required: true,
                        },
                        percentage: {
                            value: '',
                            error: false,
                            errorCode: '',
                            required: true,
                        }
                    }
            });
        }
    }

    closeAddBeneficiariesModal(accept?: boolean) {
        const { close, onClickAccept, beneficiaries } = this.props;
        if (!accept && close) close();
        this.setState({ active: "", showError: false });
    }

    acceptAddBeneficiariesModal() {
        const { onClickAccept } = this.props;
        const { beneficiaries, beneficiary } = this.state;
        let isOk = true;
        Object.keys(beneficiary).forEach(k => {
            if (beneficiary[k].error || (beneficiary[k].required && !beneficiary[k].value)){
                isOk = false;
            } 
        });

        let percentage = 0;
        beneficiaries.forEach(b => {
            percentage += parseFloat(b.percentage.value);
        })
        if (percentage === 100) {
            onClickAccept(beneficiaries);
            this.closeAddBeneficiariesModal();
        } else {
            this.setState({ showError: true, percentageTotal: percentage });
        }
    }

    editBeneficiary = (index: number) => {
        const { beneficiaries } = this.state;
        const beneficiary = beneficiaries[index];
        beneficiaries.splice(index, 1);
        this.setState({ beneficiary, beneficiaries });
    }

    deleteBeneficiary = (index: number) => {
        const { beneficiaries } = this.state;
        beneficiaries.splice(index, 1);
        let percentage = 0;
        beneficiaries.forEach(b => {
            percentage += parseFloat(b.percentage.value);
        })
        this.setState({ beneficiaries, percentageTotal: percentage });

    }

    changeValue = (value: any, key: string = "") => {
        const { beneficiary } = this.state;

        beneficiary[key].value = String(value).toUpperCase();
        if (key === 'docType') {
            if (value === 'MENOR') {
                beneficiary.docNumber.required = false;
                beneficiary.docNumber.value = "MENOR";
            } else {
                beneficiary.docNumber.required = true;
                beneficiary.docNumber.value = "";
            }
        }
        if (beneficiary[key].required && beneficiary[key].value === '') {
            beneficiary[key].error = true;
            beneficiary[key].errorCode = 'required';
        } 
        else {
            beneficiary[key].error = false;
            beneficiary[key].errorCode = '';
        }

        this.setState({ beneficiary, showError: false, percentageTotal: 0 });
    };

    addBeneficiary = () => {
        const { insuredDocNumber } = this.props;
        const { beneficiaries, beneficiary, percentageTotal } = this.state;
        let isOk = true;
        Object.keys(beneficiary).forEach(k => {
            if (typeof(beneficiary[k].value) === 'string') beneficiary[k].value = beneficiary[k].value.trim();
            if (beneficiary[k].error || (!beneficiary[k].value && beneficiary[k].required)) {
                isOk = false;
                beneficiary[k].value.length === 0 && (beneficiary[k].errorCode = 'required')
                this.setState({ beneficiary });
            }
        });

        beneficiary.docNumber.errorCode = '';
        beneficiary.docNumber.error = false;

        beneficiaries.forEach((beneficiaryLoop: any) => {
            if (beneficiary.docNumber.value === beneficiaryLoop.docNumber.value && beneficiary.docNumber.value !== "MENOR") {
                isOk = false;
                beneficiary.docNumber.errorCode = 'dni-repeat';
                this.setState({ beneficiary });
            }
        });

        
        if (!isValidDocumentID(beneficiary.docNumber.value, beneficiary.docType.value) && !(beneficiary.docType.value === "MENOR")) {
            isOk = false;
            beneficiary.docNumber.errorCode = beneficiary.docType.value === 'NIF' ? 'invalid-document-dni' : 'invalid-format';
            beneficiary.docNumber.error = true;
            this.setState({ beneficiary });
        } 
        else if (insuredDocNumber && beneficiary.docNumber.value === insuredDocNumber) {
            
            isOk = false;
            beneficiary.docNumber.errorCode = 'insured-not-beneficiary';
            beneficiary.docNumber.error = true;
            this.setState({ beneficiary });
        }
        
        if (beneficiary.percentage.value === '0') {
            isOk = false;
            beneficiary.percentage.errorCode = '0-not-valid-percentage';
            beneficiary.percentage.error = true;
            this.setState({ beneficiary });
        }

        if (isOk) {
            let percentage = 0;
            beneficiaries.forEach(b => {
                percentage += parseFloat(b.percentage.value);
            })
            percentage += parseFloat(beneficiary.percentage.value);
            if (percentage <= 100) {
                beneficiaries.push(beneficiary);
                this.setState({
                    beneficiaries, percentageTotal: percentage, beneficiary: JSON.parse(JSON.stringify(BENEFICIARY)), showError: false
                })
            } else {
                this.setState({ showError: true, percentageTotal: percentage });
            }
        }
    }

    cleanBeneficiary = () => {
        this.setState({
            beneficiary: JSON.parse(JSON.stringify(BENEFICIARY))
        })
    }

    render() {
        const { img, mainTitle, mainText, className, buttonText } = this.props;
        const { active, beneficiary, beneficiaries, percentageTotal, showError } = this.state;
      
        let percentageNow = beneficiaries.reduce((accumulator, currentValue) => {
            let percentage = Number(currentValue.percentage.value)
            return Number(accumulator) + percentage
          },0);

         return (
            <AddBeneficiariesModalContainer className={`${active} ${className}`}>
                <div className="add-beneficiaries-modal-container">

                     <div className="add-beneficiaries-modal-main">
                    <div className="add-beneficiaries-modal-close">
                        <img src={images.IconCloseGrey} onClick={() => this.closeAddBeneficiariesModal()} />
                    </div>

                        <div className="add-beneficiaries-modal-main-title">
                            <div className="add-beneficiaries-modal-icon-bg">
                                <img src={img} />
                            </div>
                            <div className="add-beneficiaries-modal-text">
                                <H4Text>{mainTitle}</H4Text>
                            </div>
                        </div>

                        <div className="add-beneficiaries-modal-main-content">
                            <div className="add-beneficiaries-modal-main-copy">
                                <WebText>{mainText}</WebText>
                            </div>

                            <div className="add-beneficiaries-columns-container">
                                <div className="add-beneficiaries-modal-users-container column">
                                    <div className="add-beneficiaries-modal-users-row">
                                        <div className="add-beneficiaries-modal-users-row__item name">
                                            <div className="inputBox height95">
                                                <InputBox
                                                    required={beneficiary.name.required}
                                                    errorCode={beneficiary.name.errorCode}
                                                    value={beneficiary.name.value}
                                                    onChange={(value: string) => this.changeValue(value, "name")}
                                                    placeholder="Nombre"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="add-beneficiaries-modal-users-row__item lastname1">
                                            <div className="inputBox height95">
                                                <InputBox
                                                    required={beneficiary.lastName1.required}
                                                    errorCode={beneficiary.lastName1.errorCode}
                                                    value={beneficiary.lastName1.value}
                                                    onChange={(value: string) => this.changeValue(value, "lastName1")}
                                                    placeholder="Apellido 1"
                                                    type="text" />
                                            </div>
                                        </div>
                                            <div className="add-beneficiaries-modal-users-row__item lastname2">
                                                <div className="inputBox height95">
                                                    <InputBox
                                                        required={beneficiary.lastName2.required}
                                                        errorCode={beneficiary.lastName2.errorCode}
                                                        value={beneficiary.lastName2.value}
                                                        onChange={(value: string) => this.changeValue(value, "lastName2")}
                                                        placeholder="Apellido 2"
                                                        type="text" />
                                                </div>
                                            </div>
                                        
                                        <div className="add-beneficiaries-modal-users-row__item nie">
                                            <div className="selectBox height95">
                                                <SelectBox
                                                    placeholder="Tipo"
                                                    required={beneficiary.docType.required}
                                                    errorCode={beneficiary.docType.errorCode}
                                                    optionsText={DOC_TYPES.beneficiaries}
                                                    optionKey={"value"}
                                                    optionValue={"label"}
                                                    initialValue={beneficiary.docType.value}
                                                    onChange={(value: string) => this.changeValue(value, "docType")}
                                                />
                                                <label>DOCUMENTO DE IDENTIDAD</label>
                                            </div>                   
                                        </div>
                                        <div className="add-beneficiaries-modal-users-row__item nie">
                                            <div className="inputBox height95">
                                                <InputBox
                                                    required={beneficiary.docNumber.required}
                                                    errorCode={beneficiary.docNumber.errorCode}
                                                    value={beneficiary.docNumber.value}
                                                    onChange={(value: string) => this.changeValue(value, "docNumber")}
                                                    placeholder="NÚMERO DE DOCUMENTO"
                                                    type="text"
                                                    disabled={beneficiary.docType.value === "MENOR"}
                                                />
                                            </div>
                                        </div>
                                        <div className="add-beneficiaries-modal-users-row__item percentage">
                                            <div className="inputBox height95">
                                                <InputBox
                                                    required={beneficiary.percentage.required}
                                                    errorCode={beneficiary.percentage.errorCode}
                                                    value={beneficiary.percentage.value}
                                                    onChange={(value: string) => this.changeValue(value, "percentage")}
                                                    placeholder="%"
                                                    type="number"
                                                    maxLength={3}
                                                />
                                            </div>
                                        </div>
                                        {showError && (
                                            <div className={`add-beneficiaries-modal-users-row ${percentageTotal === 100 ? "" : "error"}`}>
                                                <BodyText>Tiene que sumar 100%, la suma ahora mismo es de {percentageTotal}</BodyText>
                                            </div>
                                        )} 
                                        <div className="add-beneficiaries-modal-users-row__item actions">
                                        {  percentageTotal < 100 ? 
                                            <div className="action-item" onClick={() => this.addBeneficiary()}>
                                                <label>AÑADIR BENEFICIARIO</label>
                                                <img src={images.IconOkGreen} />
                                            </div>
                                            :
                                            <div className="action-item disabled" onClick={() => this.addBeneficiary()}>
                                                <label>AÑADIR BENEFICIARIO</label>
                                                <img src={images.IconOkGreen} />
                                            </div>
                                        }
           
                                            <div className="action-item" onClick={() => this.cleanBeneficiary()}>
                                                <label>LIMPIAR DATOS</label>
                                                <img src={images.IconCancelRed} />
                                            </div>
                                            
                                        </div>    

                                    </div>
                                </div>
                            <div className="add-beneficiaries-modal-column-content column">
                                <div className="added-users-container">
                                    {beneficiaries.map((b, index) => (
                                        <div key={index} className="added-users-row">
                                            <div className="added-users-row__text">
                                                <div className="added-users-row__text__name">
                                                    <p>{b.name.value} {b.lastName1 && b.lastName1.value} {b.lastName2 && b.lastName2.value}</p>
                                                </div>
                                                <div className="added-users-row__text__info">
                                                    <p>{b.docNumber.value} - {b.percentage.value}%</p>
                                                </div>
                                            </div>
                                            <div className="added-users-row__action">
                                                <div className="added-users-row__action__button" onClick={() => this.editBeneficiary(index)}>
                                                    <img src={images.IconEditBlue} />
                                                </div>
                                                <div className="added-users-row__action__button"  onClick={() => this.deleteBeneficiary(index)}>
                                                    <img src={images.IconDeleteBlue} />
                                                </div>
                                             </div>
                                        </div>

                                    ))}
                                </div>

                                    <div className={`add-beneficiaries-modal-users-msg center`}>
                                        <span>{100 - percentageNow < 0 ? 0 : 100 - percentageNow}% pendiente de asignar a nivel de beneficiarios</span>
                                   
                                    </div>
                                    <div className="add-beneficiaries-modal-button">
                                        <MainButton disabled={percentageNow == 100 ? false : true} text={buttonText} onClick={() => this.acceptAddBeneficiariesModal()} />
                                    </div>
                            
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AddBeneficiariesModalContainer>
        );
    }
}

export default withRouter(AddBeneficiariesModal);
