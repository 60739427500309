import styled from "styled-components";
import { colors, images } from "../../assets";
import { BodyTextContainer } from "../components-text/body-text/body-text-styles";

export const MainTableContainer = styled.table`
  text-align: left;
  font-family: "museo sans";
  width: 100%;

  th {
    text-transform: uppercase;
    padding: 18px 10px;
    min-width: 50px;

    &.pointer {
      cursor: pointer;
    }

    &.filterReady {
      cursor: pointer;
      p {
        padding-right: 30px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 0%;
          right: 0;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          background: url(${images.IconFilterTable}) no-repeat center;
          transition: 0.3s all ease-in-out;
        }
      }
    }

    &.filter {
      cursor: pointer;
      p {
        padding-right: 30px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          background: url(${images.IconArrowDownGrey}) no-repeat center;
          transition: 0.3s all ease-in-out;
        }
      }

      &.active {
        p {
          &:after {
            transform: translateY(-50%) rotate(-180deg);
          }
        }
      }
    }

    ${BodyTextContainer} {
      display: inline-block;
      line-height: 16px;
    }
  }

  td {
    color: ${colors["TX-001-100"]};
    vertical-align: middle;

    > a {
      display: inline-block;
      margin: 10px 0;
    }
    div {
      display: inline-block;
      line-height: 18px;

      &.table-icon-container {
        display: flex;
        justify-content: flex-end;

        .table-icon-item {
          margin-right: 16px;
          cursor: pointer;
          a,
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 24px;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .align-right-cell {
    text-align: right;
  }
  
  .number-align-right {
    float: right;
  }

  .tag-container {
    border-radius: 3px;
    padding: 5px 12px;
  }

  .highlighted {
    font-weight: 500;
    font-size: 16px;
  }

  .responsive {
    font-size: 15px;
  }

  .responsiveHeader{
    font-size: 15px;
  }

  .dimmed-mini-title {
    font-weight: 500;
    font-size: 16px;
    color: ${colors["TX-001-30"]};
  }

  .entire-mini-title {
    font-weight: 500;
    font-size: 12px;
    color: ${colors["TX-001-50"]};
  }

  .dimmed-mini-body {
    font-weight: 300;
    font-size: 16px;
    color: ${colors["TX-001-100"]};
  }

  .dimmed-mini-list {
    ${BodyTextContainer} {
      font-weight: 300;
      font-size: 14px;
      color: ${colors["TX-001-100"]};
    }

    li {
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .entire-mini-body {
    font-weight: 500;
    font-size: 14px;
    color: ${colors["TX-001-100"]};
  }

  .keep-calm {
    color: ${colors["SC-004-100"]};
    background: ${colors["keep-calm"]};
    font-size: 16px;

    p {
      color: ${colors["SC-004-100"]};
    }
  }

  .pymes-color{
    background: ${colors["pymes-color"]};
    font-size: 16px;
  }

  .pets {
    color: ${colors["SC-002-100"]};
    background: ${colors["pets-color"]};
    font-size: 16px;

    p {
      color: ${colors["SC-002-100"]};
    }
  }

  .health {
    color: ${colors["PR-001-100"]};
    background: ${colors["health-color"]};
    font-size: 16px;

    p {
      color: ${colors["PR-001-100"]};
    }
  }
  
  .healthCopay {
    color: ${colors["PR-001-100"]};
    background: ${colors["health-color"]};
    font-size: 16px;

    p {
      color: ${colors["PR-001-100"]};
    }
  }
  .dental {
    color: ${colors["SU-001-10"]};
    background: ${colors["dental-color"]};
    font-size: 16px;

    p {
      color: ${colors["SU-001-10"]};
    }
  }
  .travel {
    color: ${colors["travel-blue"]};
    background: ${colors["travel-blue-background"]};
    font-size: 16px;

    p {
      color: ${colors["travel-blue"]};
    }
  }

  .deaths {
    color: ${colors["SC-005-100"]};
    background: ${colors["deaths-color"]};
    font-size: 16px;

    p {
      color: ${colors["SC-005-100"]};
    }
  }

  .complete {
    color: ${colors["SU-001-100"]};
    background: ${colors["complete-color"]};

    p {
      color: ${colors["SU-001-100"]};
    }
  }

  .incomplete {
    color: ${colors["ER-001-100"]};
    background: ${colors["incomplete-color"]};

    p {
      color: ${colors["ER-001-100"]};
    }
  }

  .hire {
    color: #ffffff;
    text-transform: uppercase;
    background: #004a8f;
    padding: 5px 12px !important;
    width: 100%;
    text-align: center;
    border-radius: 4px;
  }

  .table-col-3 {
    width: calc(85px * 3);
    max-width: calc(85px * 3);
    min-width: calc(85px * 3);
    overflow: hidden;
  }

  .table-col-4 {
    width: calc(85px * 4);
    max-width: calc(85px * 4);
    min-width: calc(85px * 4);
    overflow: hidden;
  }

  .main-table-row {
    transition: 0.3s all ease-in-out;

    th{
      padding: 10px;
    }
    
    td {
      padding: 0 15px;
      min-width: 50px;
      &.number{
        text-align: left;
      }
      > div {
        padding: 0;
        &.table-icon-container {
          padding: 0;
          display: flex;
          justify-content: flex-start;
        }
        &.hire {
          &.disabled {
            opacity: 0.4;
            pointer-events: none;
          }
          cursor: pointer;
        }
      }
      div .hire {
        &.disabled {
          opacity: 0.2;
          pointer-events: none;
        }
      }
      div .hire {
        &.sign {
          opacity: 0.2;
        }
        cursor: pointer;
      }
    }

    .table-icon-item {
      a {
        padding: 25px 16px;
      }

      button {
        padding: 25px 16px;
        -webkit-appearance: none;
        background: none;
        border: none;
        cursor: pointer;
      }
    }

    &:hover {
      box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.05);
    }
  }

  &.detail-table {
    thead {
      text-align: center;
      tr {
        border-bottom: 1px solid #004f8b;
      }
    }

    th {
      ${BodyTextContainer} {
        font-size: 12px;
      }
      padding: 8px 0;
      color: ${colors["TX-001-30"]};
      .highlighted {
        color: ${colors["TX-001-50"]};
      }
    }

    .main-table-row {
      td {
        > div {
          padding: 8px 0;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      &:first-child {
        td {
          > div {
            padding-top: 22px;
          }
        }
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  .beneficiaries{
    p {
      color: ${colors["PR-001-100"]};
      margin-bottom: 20px;
    }

    &.percentage{
      p {
        text-align: center;
      }
    }
  }

  .beneficiaries-header{
    p {
      font-weight: bold;
    }
  }

  .radio-row{
    margin-bottom: 20px;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
    font-family: "museo sans";
  }
`;
