import React from "react";
import CheckboxItemScreen from "./checkbox-item-screen";

export default class CheckboxItem extends React.Component<any, {}> {
  render() {
    const {
      className,
      name,
      checked,
      onChange,
      showError,
      checkboxText,
      errorText,
      withTab,
      disabled
    } = this.props;
    return (
      <CheckboxItemScreen
        className={className}
        name={name}
        checked={checked}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange(ev)}
        checkboxText={checkboxText}
        errorText={errorText}
        showError={showError}
        withTab={withTab}
        disabled={disabled}
      />
    );
  }
}
