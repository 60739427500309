import React, { ReactChildren } from "react";
import { LayoutStyle, LayoutContainer } from "./layout-style";
import { withRouter } from "react-router";
import { withGeneral } from "../../context/mixin/with-general";

interface LayoutState {
  inFlow: string[];
  isFull: string[];
  login: string[];
}

class Layout extends React.Component<any, any> {
  notShowHeader: string[] = [];
  notShowSidebar: string[] = [];

  readonly state: LayoutState;

  constructor(props: any) {
    super(props);
    this.state = {
      inFlow: [
        "/comparacion/nueva",
        "/contratacion/nueva",
        "/simulacion/nueva",
        "/simulacion/asegurados",
        "/campañas",
        "/suplemento/alta-asegurado"
      ],
      isFull: [
        "/suplementos",
        "/suplemento",
        "/preguntas-frecuentes",
        "/preguntas-ocaso",
        "/gestor-notificaciones",
        "/panel-notificaciones",
      ],
      login: []
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.isLogged !== this.props.isLogged && !this.props.isLogged) {
      const { inFlow } = this.state;
      inFlow.push("/");
      this.setState({ inFlow });
    } else if (
      prevProps.isLogged !== this.props.isLogged &&
      this.props.isLogged
    ) {
      const { inFlow } = this.state;
      const index = inFlow.findIndex(e => e === "/");
      if (index > -1) {
        inFlow.splice(index, 1);
        this.setState({ inFlow });
      }
    }
  }

  render() {
    const { history, is404, children, isLogged } = this.props;
    let notShowHeader = false;
    let notShowSidebar = false;
    let login = false;
    let inFlowActive = false;
    let isFullActive = false;

    if (this.notShowHeader.includes(history.location.pathname)) {
      notShowHeader = true;
    }

    if (this.notShowSidebar.includes(history.location.pathname)) {
      notShowSidebar = true;
    }

    if (this.state.login.includes(history.location.pathname)) {
      login = true;
    }

    if (
      this.state.inFlow.filter(
        (item: string) => history.location.pathname.indexOf(item) > -1
      ).length > 0
    ) {
      inFlowActive = true;
    }

    if (
      this.state.isFull.filter(
        (item: string) => history.location.pathname.indexOf(item) > -1
      ).length > 0
    ) {
      isFullActive = true;
    }

    if (is404) {
      notShowHeader = true;
    }

    return (
      <>
        <LayoutStyle />
        <LayoutContainer
          className={`${notShowHeader ? "no-header" : ""} ${
            notShowSidebar ? "no-sidebar" : ""
          } ${!isLogged ? "is-login" : "is-not-login"} 
            ${ inFlowActive ? "in-flow" : ""}
            ${ isFullActive ? "is-full" : ""}
          `}
        >
          {children}
        </LayoutContainer>
      </>
    );
  }
}

export default withGeneral(withRouter(Layout));
