import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import Title03Text from "../../../../../components/components-text/title-03-text";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import HealthCampaignItem from "../../../../../components/health-campaign-item-result/health-campaign-item-result";
import HealthCampaingItem from "../../../../../components/health-campaign-item/health-campaign-item";
import MainButton from "../../../../../components/main-button";
import MainTable from "../../../../../components/main-table";
import { ICampanyas, IHealthCoverage, IPricesMethod } from "../../../../../constants/health";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import CampaignsServices from "../../../../../services/CampaignsServices";
import HealthService from "../../../../../services/HealthService";
import { formatter } from "../../../../../utils/utility";
import { ICoveragesQuoteAmount } from "../../../../simulations/new-simulation/result/interfaces/result-interface";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { DentalCampaign } from "./dental-price-dto/dental-campaign-dto";
import { ImportesAseguradoGarantiaBodyResponse, SendaBasePriceResponse } from "./dental-price-dto/dental-prices-dto";
import { DentalPriceDataContainer } from "./dental-price-data-style";
import { DentalHireMenuHelper, Steps } from "../../../../../components/dental-hire-menu/dental-hire-menu";
import DentalService from "../../../../../services/DentalService";
import DentalInsuredHiringResultItem from "../../../../../components/dental-insured-hiring-result-item/dental-insured-hiring-result-item";
import { campaign } from "../../health/health-price/health-price-dto/campaign-dto";
import { withPayTypes } from "../../../../../context/mixin/with-pay-types";
import PayTypesServices from "../../../../../services/PayTypesServices";
import { DEFAULT_PAY_METHOD } from "../../../../../constants/payTypes";

interface DentalPriceProps extends RouteComponentProps {
  setLoading: Function;
  data: any;
  policy: any;
  saveHiring: Function;
  isReadOnly?: boolean;
  coverages: any;
  allPayTypes: any;
}

interface priceTypeModel {
  payType: string;
  price: string;
  taxes: string;
  total: string;
}

interface selectedCampaign {
  code: string,
  name: string
}

interface ITableColumn {
  text: string,
  filter: boolean,
  ascendent: boolean,
  key: string,
}

interface ITableRow {
  text: string,
  type: string,
  link?: string,
  key: string,
}

interface IPaymentTableRows {
  periodicity: ITableRow,
  firstReceipt: ITableRow,
  sequential: ITableRow
}

interface DentalPriceState {
  selectablePrices: priceTypeModel[];
  selectedPrice: {
    paymentTime: string;
    payType: string,
    price: string;
    taxes: string;
    total: string;
  };
  secondReceipsPrice: priceTypeModel[];
  selectedCampaigns: selectedCampaign[];
  selectableCampaigns: DentalCampaign[];
  canContinue: boolean;
  modalErrorShow: boolean;
  modalErrorText: string;
  insuredData: any[];
  pricesResponse: SendaBasePriceResponse;
  paymentTableColumns: ITableColumn[];
  paymentTableRows: IPaymentTableRows[];
  coverages: any;
  modality: string;
  cancelSignModalShow: boolean;
  valueNewSignData: any;
}

class PriceDataLayout extends React.Component<DentalPriceProps, DentalPriceState> {

  constructor(props: any) {
    super(props);

    this.state = {
      selectablePrices: [],
      selectedPrice: {
        paymentTime: props.data.taker &&
          props.data.taker.policy &&
          props.data.taker.policy.paymentType ?
          PayTypesServices.getPayTypeByCode(props.allPayTypes, props.data.taker.policy.paymentType).description :
          DEFAULT_PAY_METHOD.description,
        payType: props.data.taker &&
          props.data.taker.policy &&
          props.data.taker.policy.paymentType ?
          props.data.taker.policy.paymentType : DEFAULT_PAY_METHOD.code,
        price: '',
        taxes: '',
        total: ''
      },
      secondReceipsPrice: [],
      selectedCampaigns: [],
      selectableCampaigns: [],
      canContinue: true,
      modalErrorShow: false,
      modalErrorText: '',
      insuredData: this.props.data.insureds && this.props.data.insureds.length > 0 ? this.props.data.insureds : [],
      pricesResponse: this.getDefaultPriceResponse(),
      paymentTableColumns: this.getPaymentTableColumns(),
      paymentTableRows: [],
      coverages: [],
      modality: '',
      cancelSignModalShow: false,
      valueNewSignData: {
        code: "",
        name: "",
        selected: false,
        fromAPI: false
      }

    };
  }

  getPaymentTableColumns = () => {
    const paymentTableColumns = [
      {
        text: "Forma de pago",
        filter: false,
        ascendent: true,
        key: "align-right-cell",
      },
      {
        text: "1er Recibo",
        filter: false,
        ascendent: true,
        key: "align-right-cell",
      },
      {
        text: "Sucesivos",
        filter: false,
        ascendent: false,
        key: "align-right-cell",
      },
    ];

    return paymentTableColumns;
  };

  getDefaultPriceResponse(): SendaBasePriceResponse {
    return {
      body: {
        RESPUESTA: {
          ASEGURADOS: [],
          DATOS_COTIZACION: {
            COLECTIVO: '',
            COMBINACION_COMERCIAL_POLIZA: '',
            COTIZACION: '',
            ESTADO: '',
            FECHA_EFECTO: '',
            LINEA_NEGOCIO: '',
            MEDIADOR: ''
          },
          FACTOR_COMERCIAL: {
            DESCUENTO_PORCENTAJE: '',
            TIPO_DESCUENTO: ''
          },
          IMPORTESFORPAGO: [],
          IMPORTES_ASEGURADO_GARANTIA: [],
          IMPORTES_COTIZACION_GARANTIA: [],
          RECIBOS: [],
          COBERTURAS: [],
          CAMPANYAS: []
        }
      }
    }
  }

  formatData(data: any, simulation?: boolean) {
    const { selectedCampaigns } = this.state;

    const campanyas: ICampanyas[] = selectedCampaigns.map(campanya => ({ CAMPANYA: campanya.code }));
    const request: IPricesMethod = {
      COTIZACION: data.idcotiza,
      CAMPANYAS: campanyas.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.CAMPANYA === value.CAMPANYA
        ))
      )
    }
    return request;
  }

  getPrices = async () => {
    const { setLoading, data, allPayTypes } = this.props;
    let { selectedPrice, selectablePrices, secondReceipsPrice, selectableCampaigns } = this.state;

    const priceRequest: IPricesMethod = this.formatData(data)

    let resPrices = await DentalService.getPrices({
      setLoading: setLoading,
      data: priceRequest,
      loader: true
    }).catch((error) => {
      this.setState({
        canContinue: false,
        modalErrorShow: true,
        modalErrorText: `${(error && error.data && error.data.message) ?
          error.data.message : 'Se ha producido un error. Vuelva a intentarlo más tarde.'}`
      })
    });

    if (resPrices !== undefined) {

      //Seleccionar las campañas que devuelve la api
      resPrices.body.RESPUESTA.CAMPANYAS && resPrices.body.RESPUESTA.CAMPANYAS.forEach((campanya) => {
        let name = selectableCampaigns.find((campaign) => campaign.code === campanya.CAMPANYA);
        if (name)
          this.handleSelectedCampaignsChange(campanya.CAMPANYA, name.name, false, true)
      })

      selectablePrices = []
      secondReceipsPrice = []

      resPrices.body.RESPUESTA.RECIBOS.forEach((importePago) => {

        if (importePago.INRECIBO === 'S') {
          selectablePrices.push(
            {
              payType: importePago.PERIODO_COBRO,
              price: importePago.PRIMANETA,
              taxes: importePago.TOTAL_IMPUESTOS,
              total: importePago.PRIMATOTAL
            }
          )
        }
        else {
          secondReceipsPrice.push(
            {
              payType: importePago.PERIODO_COBRO,
              price: importePago.PRIMANETA,
              taxes: importePago.TOTAL_IMPUESTOS,
              total: importePago.PRIMATOTAL
            }
          )
        }
      })

      let selected = selectablePrices.filter((selectablePrice) => selectablePrice.payType === selectedPrice.payType)[0];

      let prices: IPaymentTableRows[] = []
      selectablePrices.forEach((price, index) => {

        const newItem = {
          periodicity: {
            text: PayTypesServices.getPayTypeByCode(allPayTypes, price.payType).description,
            type: "",
            key: `radio`,
            selected: price.payType === selected.payType,
            //TODO: Esta parte es para escoger el pago en salud, de momento nos mandaron ocultarlo
            /* onClick: () =>
              this.setState({
                selectedPrice:
                {
                  ...price,
                  paymentTime: LABEL_PAY_TYPE[price.payType]
                }
              }, () => this.getPrices()) */
          },
          firstReceipt: {
            text: formatter.format(Number(price.total)),
            type: "",
            link: "",
            key: `float-right-cell`,
          },
          sequential: {
            text: secondReceipsPrice[index] ? formatter.format(Number(secondReceipsPrice[index].total)) : formatter.format(0.00),
            type: "",
            link: "",
            key: `float-right-cell`,
          }
        }
        prices.push(newItem)

      })

      this.setState({
        selectedPrice: { ...selected, paymentTime: PayTypesServices.getPayTypeByCode(allPayTypes, selected.payType).description },
        selectablePrices,
        secondReceipsPrice,
        paymentTableRows: prices,
        pricesResponse: resPrices,
        canContinue: true
      });
    }

    return selectablePrices;
  }

  getCampaigns = async () => {
    const { setLoading, policy, data } = this.props;
    let allCampaigns: campaign[] = [];
    if (policy && policy.product && policy.product.products_colectivo && policy.product.products_combinacion_comercial) {
      allCampaigns = await CampaignsServices.getCampaigns({
        loader: true,
        setLoading,
        colective: policy.product.products_colectivo,
        combinacion_comercial: policy.product.products_combinacion_comercial,
        fecha_inicio: this.checkPolicyStartDate(data)
      })
        .catch((error) => {
          this.setState({
            modalErrorShow: true,
            modalErrorText: `Se ha producido un error al obtener las coberturas. Vuelva a intentarlo más tarde.`
          });
          throw error.statusText;
        });
    }

    return allCampaigns;
  }

  checkPolicyStartDate (data: any): string {
    if (data && data.taker && data.taker.policy && data.taker.policy && data.taker.policy.startDate) {
      return data.taker.policy.startDate;
    }
    return new Date().toISOString();
  }

  async componentDidMount() {
    const { policy } = this.props;

    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);

    this.getSelectedPriceAndCampaigns()

    if (Object.keys(policy.product).length!==0) {
      const productDescription = policy.product.products_name.toUpperCase()
      await this.getCoverages()
      this.setState({ modality: productDescription }, () => this.loadData())
    }

  };

  async componentDidUpdate(prevProps: Readonly<DentalPriceProps>, prevState: Readonly<DentalPriceState>, snapshot?: any) {
    const { policy } = this.props;

    if (Object.keys(prevProps.policy.product).length===0 && Object.keys(policy.product).length!==0) {
      const productDescription = policy.product.products_name.toUpperCase()
      await this.getCoverages()
      this.setState({ modality: productDescription }, () => this.loadData())
    }

  }

  
  getSelectedPriceAndCampaigns = () => {
    const { data } = this.props
    if (data.priceData !== undefined && data.priceData.price !== undefined) {
      const selectedPrice = {
        paymentTime: data.priceData.price.paymentTime,
        payType: data.priceData.price.payType,
        price: data.priceData.price.price,
        discount: data.priceData.price.discount,
        taxes: data.priceData.price.taxes,
        total: data.priceData.price.total
      }
      this.setState({ selectedPrice})
    }
    if (data.priceData !== undefined && data.priceData.campaigns !== undefined) {
      const selectedCampaigns = data.priceData.campaigns;
      this.setState({ selectedCampaigns})
    }
  }

  async getCoverages() {
    const { setLoading, policy, coverages} = this.props;
    const productType: string = policy.product.products_coverageName;
    let coveragesAux: IHealthCoverage[] = [] 

    if (coverages && coverages[productType] ) {
      coveragesAux = coverages[productType]
    } else if(productType){
      coveragesAux = await HealthService.getCoveragesFromDB(setLoading, true, productType)
      .catch((error) => {
        this.setState({ modalErrorShow: true, modalErrorText: `Error al obtener las coberturas: ${error.data.message}` })
        return []
      });
    }
    
    this.setState({ coverages: [...this.formatCoverages(coveragesAux)] })
  }

  formatCoverages(unformattedCoverages: IHealthCoverage[]): IHealthCoverage[] {
    if (unformattedCoverages && unformattedCoverages && unformattedCoverages.length > 0) {
      let formattedCoverages: IHealthCoverage[] = []
      unformattedCoverages.forEach((coverage: any) => {
        formattedCoverages.push({
          capMaxCober: coverage.capMaxCober,
          capMinCober: coverage.capMinCober,
          checked: coverage.required,
          code: coverage.code,
          description: coverage.name,
          edadMax: coverage.edadMax,
          edadMin: coverage.edadMin,
          required: coverage.required,
          capital: coverage.capital,
        })
      })
      return formattedCoverages;
    }
    return []
  }

  loadData = async () => {
    let campaigns = await this.getCampaigns();
    let { selectedCampaigns } = this.state
    let selectedCampaignsChecked = campaigns.filter((campaign: DentalCampaign) => campaign.isRequired);

    if (selectedCampaigns && selectedCampaigns.length > 0) {
      selectedCampaigns.forEach((campaign) => {
        let campaingToPush = campaigns.find((campaignRes) => campaignRes.code === campaign.code);
        if (campaingToPush) {
          const code = campaingToPush.code;
          // si no tiene la campaña seleccionada la agregamos
          if (selectedCampaigns.some((c: any) => c.code === code)) selectedCampaignsChecked.push(campaingToPush)
        }
      })
    }
    this.setState({ selectableCampaigns: campaigns, selectedCampaigns: selectedCampaignsChecked }, async () => {
      let prices = await this.getPrices();
      this.setState({ selectablePrices: prices })
    })
  }

  async handleSelectedCampaignsChange(code: string, name: string, selected: boolean, fromAPI: boolean = false) {
    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED) {
      this.setState({ cancelSignModalShow: true, valueNewSignData: { code: code, name: name, selected: selected, fromAPI: fromAPI } })
    } else {
      await this.getSelectedCampaigns(code, name, selected, fromAPI)
    }

  }
  async getSelectedCampaigns(code: string, name: string, selected: boolean, fromAPI: boolean = false) {
    let { selectedCampaigns } = this.state;
    if (selected) {
      if (selectedCampaigns.some(campaign => campaign['code'] === code)) {
        const index = selectedCampaigns.findIndex(campaign => campaign.code === code);
        selectedCampaigns.splice(index, 1);
      }
    }
    else {
      if (!selectedCampaigns.some(campaign => campaign['code'] === code))
        selectedCampaigns.push({ code, name });
    }
    this.setState({ selectedCampaigns });
    if (!fromAPI) {
      let prices = await this.getPrices();
      this.setState({ selectablePrices: prices });
    }

  }
  savePriceData() {
    const { selectedCampaigns, selectedPrice, pricesResponse } = this.state;

    if (this.props.isReadOnly)
      return this.props.history.push(DentalHireMenuHelper.getNextStepNavigationPath(Steps.Prima))

    if (selectedPrice.price === '') {

      this.setState({
        modalErrorShow: true,
        modalErrorText: 'Se ha producido un error durante el proceso de contratación, por favor inténtelo de nuevo más tarde.'
      })
      return;

    }

    const priceData = {
      price: selectedPrice,
      campaigns: selectedCampaigns,
      priceResponse: pricesResponse
    }

    this.props.policy.setHealthData(priceData);
    this.props.saveHiring();
    this.props.history.push(DentalHireMenuHelper.getNextStepNavigationPath(Steps.Prima))
  }

  getUniqueListBy = (arr: any, key: any) => {
    return [...new Map(arr.map((item: any) => [item[key], item])).values()]
  }

  getDescriptionCoverage = (codeCoverage: string) => {
    const { insuredData } = this.state;
    let arrayCoverage: any = [];

    insuredData.map(x => {
      Array.prototype.push.apply(arrayCoverage, x.coverages)

    })
    if (arrayCoverage) {
      arrayCoverage = this.getUniqueListBy(arrayCoverage, "code")
    }
    const coverage = arrayCoverage.filter((cov: any) => cov.code === codeCoverage)

    return coverage.length > 0 ? coverage[0].description : '';

  }


  getCoveragesQuoteAmount = (
    response: any,
    payType: string,
    codeCoverage: string,
    isFormat: boolean = true,
    numRiesgo: number = 0,
  ): ICoveragesQuoteAmount => {
    const coverageQuoteAmount = response.IMPORTES_ASEGURADO_GARANTIA.filter(
      (cov: any) =>
        cov.PERIODO_COBRO === payType && cov.GARANTIA === codeCoverage
    );
    if (!coverageQuoteAmount) {
      return { PRIMANETA: "", PRIMATOTAL: "", TOTAL_IMPUESTOS: "" };
    }
    let coverage: any = {}
    if (numRiesgo > 0) {
      coverage = coverageQuoteAmount.find((cov: any) => {
        return Number(cov.NUMERO_DE_RIESGO.toString()) === numRiesgo
      });
    } else {
      coverage = coverageQuoteAmount[0]
    }
    return {
      PRIMATOTAL: isFormat
        ? formatter.format(Number(coverage.PRIMATOTAL))
        : coverage.PRIMATOTAL,
      PRIMANETA: isFormat
        ? formatter.format(Number(coverage.PRIMANETA))
        : coverage.PRIMANETA,
      TOTAL_IMPUESTOS: isFormat
        ? formatter.format(Number(coverage.TOTAL_IMPUESTOS))
        : coverage.TOTAL_IMPUESTOS,
      PERIODO_COBRO: coverage.PERIODO_COBRO,
    };
  };

  getCoveragesAmount(coverages: ImportesAseguradoGarantiaBodyResponse[]) {
    const coveragesAmount = coverages.filter((cov: ImportesAseguradoGarantiaBodyResponse) => cov.PERIODO_COBRO === this.state.selectedPrice.payType)
      .map((cov: ImportesAseguradoGarantiaBodyResponse) => ({

        CODE: cov.GARANTIA,
        key: "dimmed-mini-body",
        CAPITAL: Number(cov.PRIMATOTAL) === 1 ? 'Incluido' : formatter.format(Number(cov.PRIMATOTAL)),
        NUMERO_DE_RIESGO: Number(cov.NUMERO_DE_RIESGO),
        DESCRIPTION: this.getDescriptionCoverage(cov.GARANTIA),
        ...this.getCoveragesQuoteAmount(this.state.pricesResponse.body.RESPUESTA, this.state.selectedPrice.payType, cov.GARANTIA, true, Number(cov.NUMERO_DE_RIESGO))

      }));
    return coveragesAmount;
  }

  getPremiumConceptsAndInformativeConcepts() {
    const { pricesResponse, selectedPrice } = this.state;
    let totalPremiumConcepts: any = [];
    let informativeCoverageConcepts: any = [];
    const totalPremiumConceptsList = [
      'CONSORCIO',
      'DGS',
      'GARANTIA',
      'IPS',
      'PRIMANETA',
      'RECARGOFRA',
    ]

    const informativeCoverageConceptsLit = [
      'GARANTIA',
      'PRIMAPURA',
      'SOBREPRIMA',
      'EXTRAPRIMA',
      'GASTOSINT',
      'GASTOSEXT',
      'GASTOSEMI',
      'DEDUCCIONES',
      'RECARGOCONS',
      'RECARGOBEN',
      'DESCUENTOFP',
      'CAMPANYAS',
      'DESCUENTOS',
    ]

    pricesResponse.body.RESPUESTA.IMPORTES_COTIZACION_GARANTIA.filter(
      (price) => price.PERIODO_COBRO === selectedPrice.payType).forEach((item: any, index: number) => {

        let newPremiumConcepts: any = {};
        let newInformativeConcepts: any = {};

        Object.entries(item).forEach(([key, value]) => {

          if (totalPremiumConceptsList.includes(key)) {
            newPremiumConcepts[key] = value;
          }
          if (informativeCoverageConceptsLit.includes(key)) {
            newInformativeConcepts[key] = value;
          }

        })
        totalPremiumConcepts.push(newPremiumConcepts);
        informativeCoverageConcepts.push(newInformativeConcepts);
      });

    return { totalPremiumConcepts, informativeCoverageConcepts }
  }

  render() {

    const { selectedCampaigns, selectableCampaigns, paymentTableColumns, paymentTableRows, pricesResponse, selectedPrice, coverages,
      modality, canContinue, cancelSignModalShow, valueNewSignData } = this.state;
    const { isReadOnly, data } = this.props;

    const coveragesAmount = this.getCoveragesAmount(pricesResponse.body.RESPUESTA.IMPORTES_ASEGURADO_GARANTIA)

    return (
      <DentalPriceDataContainer>

        <div className="data-flow-status">
          <div className="data-flow-status-title">

            <h2>{'DATOS DEL SEGURO'}</h2>

          </div>
        </div>

        <div className="result-simulation-container">

          {pricesResponse.body.RESPUESTA.IMPORTES_COTIZACION_GARANTIA.filter((coverage) => coverage.PERIODO_COBRO === selectedPrice.payType).length > 0 &&
            <div className="result-simulation-item float">
              <div className="result-simulation-special-title">
                <Title03Text>
                  <p>Modalidad</p>
                </Title03Text>
              </div>
              <div className="result-simulation-item-content">
                <HealthCampaignItem
                  checked={false}
                  index={1}
                  onCheckInsured={() => { }}
                  coverages={pricesResponse.body.RESPUESTA.COBERTURAS}
                  coveragesPriceAmount={pricesResponse.body.RESPUESTA.IMPORTES_COTIZACION_GARANTIA.filter((coverage) => coverage.PERIODO_COBRO === selectedPrice.payType)}
                  totalPremiumConcepts={this.getPremiumConceptsAndInformativeConcepts().totalPremiumConcepts}
                  informativeCoverageConcepts={this.getPremiumConceptsAndInformativeConcepts().informativeCoverageConcepts}
                  totalPrice={pricesResponse.body.RESPUESTA.IMPORTESFORPAGO.filter(
                    (price) => price.PERIODO_COBRO === selectedPrice.payType).length > 0 ?
                    pricesResponse.body.RESPUESTA.IMPORTESFORPAGO.filter(
                      (price) => price.PERIODO_COBRO === selectedPrice.payType)[0].PRIMATOTAL : ''
                  }
                  healthCoverages={coverages}
                  modalityName={modality}
                />

              </div>
            </div>}

          <div className="result-simulation-item float">
            <div className="result-simulation-item-title">
              <Title03Text>
                <p>Desglose por forma de pago</p>
              </Title03Text>
            </div>
            <div className="colSin-100">

              <div className="result-simulation-item-content">
                <div className="result-simulation-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={paymentTableColumns}
                    rows={paymentTableRows}
                    keysRow={["periodicity", "firstReceipt", "sequential"]}
                    keysAlignRight={["firstReceipt", "sequential"]}
                  />
                </div>
              </div>
            </div>

          </div>

          <div className="result-simulation-item float">
            <div className="result-simulation-item-title">
              <Title03Text>
                <p>Miembros de la póliza</p>
              </Title03Text>
            </div>
            <div className="result-simulation-item-content-table">
              <div className="tabs">
                {data.insureds.map((insured: any, index: number) => {
                  return <DentalInsuredHiringResultItem
                    age={Math.floor(moment().diff(moment(insured.birthDate, "DD/MM/YYYY", true), "years", true))}
                    birthDate={insured.birthDate}
                    checked={false}
                    coverages={insured.coverages}
                    id={data.insureds.length - index}
                    index={index + 1}
                    key={index}
                    insured={insured}
                    pricesResponse={pricesResponse.body.RESPUESTA}
                    payType={data.priceData && data.priceData.price && data.priceData.price.payType ?
                      data.priceData.price.payType :
                      DEFAULT_PAY_METHOD.code}
                    isPrices={true}
                  />
                })
                }
              </div>
            </div>
          </div>
        </div>

        <div className="content-wrapper bottomMargin20">

          {selectableCampaigns.map((b, index) => (
            <HealthCampaingItem
              disabled={b.isRequired || isReadOnly}
              campaignName={b.name}
              campaignDescription={b.description}
              campaignCode={b.code}
              defaultChecked={b.isRequired || selectedCampaigns.some(campaign => campaign['code'] === b.code)}
              onClick={(campaignCode: string, campaignName: string, selected: boolean) => this.props.isReadOnly ? true : this.handleSelectedCampaignsChange(campaignCode, campaignName, selected)}
            />)
          )}

        </div>

        <div className="colSin-100 topMargin20 bottomMargin20">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.data.taker.physicalPerson ?
                  this.props.history.push(DentalHireMenuHelper.getPreviousStepNavigationPath(Steps.Prima)) :
                  this.props.history.push(DentalHireMenuHelper.getPreviousStepNavigationPath(Steps.Prima - 1))}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                disabled={!canContinue}
                withTab={true}
                text="Siguiente"
                onClick={() => this.savePriceData()}
              />
            </div>
          </div>
        </div>
        <LeftInfoModal
          img={images.IconWarningYellow}
          className="error"
          mainTitle={"¿Seguro que desea continuar?"}
          mainText={"Se cancelará la firma previamente solicitada"}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ cancelSignModalShow: false })}
          active={cancelSignModalShow}
          onClickAccept={() => {
            this.setState({ cancelSignModalShow: false }, () => this.getSelectedCampaigns(valueNewSignData.code, valueNewSignData.name, valueNewSignData.selected, valueNewSignData.fromAPI))
          }}
        />
        <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton
                text="Aceptar"
                onClick={() => this.setState({ modalErrorShow: false, modalErrorText: '' })} />
            </div>
          </div>
        </ErrorModalContainer>

      </DentalPriceDataContainer >
    );
  }
}

export default withCoverages(withPayTypes(withPolicy(withGeneral(withRouter(PriceDataLayout)))));
