import styled from "styled-components";
import { colors } from "../../../../../../assets";

export const Container = styled.div`
  width: 100%;
  height: 206px;
  border: 1px solid lightgray;

  .cell {
    height: 149px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .internalCell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Header = styled.div`
  background-color: ${colors["PR-001-100"]};
  color: white;
  font-size: 14px;
  padding: 20px;
  text-align: center;
  height: 57px;
`;

export const Item = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const Img = styled.img`
  height: 13px;
  width: 13px;
`;
