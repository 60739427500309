/**
    InfoModal
        img={images.IconCheckboxBlue} es el icono que va a salir en la modal
        text={'Vas a iniciar el proceso de contratación, ¿estás seguro?'} es el texto principal de la modal
        className={classname} es la clase que se le quiera meter al modal
        onClickAccept={()=> goToNewHiring()} meter la funcion a la que se quiera llamar desde el screen del container desde el que se importa
    />
 */

import React, { Component } from "react";
import { InfoModalContainer } from "./info-modal-style";
import { images } from "../../assets";
import H4Text from "../components-text/h4-text";
import MainButton from "../main-button";

interface InfoModalProps {
  className?: string;
  img?: string;
  onClickAccept: Function;
  mainText: string;
  buttonText: string;
  active?: boolean;
  close: Function;
  noSecondaryButton?: boolean;
  health?: boolean;
  extraText?: string;
}
interface InfoModalState {
  active: string;
}


class InfoModalLayout extends Component<InfoModalProps, InfoModalState> {
  readonly state: InfoModalState;
  containerRef: any;

  public static defaultProps = {
    active: false,
    img: images.IconSearchBlue,
    onClickAccept: () => {},
    className: "",
    mainText: "",
    buttonText: "",
    close: () => {},
  };

  constructor(props: InfoModalProps) {
    super(props);

    this.state = {
      active: props.active ? "active" : "",
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  componentDidUpdate(prevProps: InfoModalProps) {
    if (prevProps.active !== this.props.active) {
      this.setState({ active: this.props.active ? "active" : "" });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  handleClickOutside(ev: any) {
    const { close } = this.props;
    this.isVisible(ev.target) &&
      this.containerRef.current &&
      !this.containerRef.current.contains(ev.target) &&
      close();
  }

  isVisible(e: any) {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
  }

  close() {
    this.setState({ active: "" });
  }

  open() {
    this.setState({ active: "active" });
  }

  acceptInfoModal() {
    // const { isModifiedAccept, modifiedAccept } = this.state;
    const { onClickAccept, close, health } = this.props;
    if (onClickAccept) {
      onClickAccept();
    }
    (health || health === false) && close();
  }

  render() {
    const { img, mainText, className, buttonText, close, noSecondaryButton, extraText } = this.props;
    const { active } = this.state;

    return (
      <InfoModalContainer className={`${active} ${className}`}>
        <div className="info-modal-container" ref={this.containerRef}>
          <div className="info-modal-close">
            <img src={images.IconCloseBlue} onClick={() => close()} />
          </div>
          {img && img !== 'no' ? (
            <div className="info-modal-icon">
              <div className="info-modal-icon-bg">
                <img src={img} />
              </div>
            </div>
          ) : ''}
          <div className="info-modal-text">
            <H4Text>{mainText}</H4Text>
          </div>
          {extraText &&
            <div className="info-modal-text">
              <div className="extra-text">{extraText}</div>
            </div>
          }
          {buttonText && (
            <div className="info-modal-button">              
              { !noSecondaryButton &&  
                <MainButton text={'Cancelar'} onClick={() => close()} type={'ghost'} />
              }
              <MainButton
                onClick={() => this.acceptInfoModal()}
                text={buttonText}
              />
            </div>
          )}
        </div>
      </InfoModalContainer>
    );
  }
}

export default InfoModalLayout;
