import React, { Component } from "react";
import { SearchBoxContainer } from "./search-box-style";
import { images } from "../../assets/images";
import TooltipBox from "../tooltip-box/tooltip-box";
import NoteText from "../components-text/note-text";
import BodyText from "../components-text/body-text";
import LabelText from "../components-text/label-text";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import { colors } from "../../assets";
import LoaderSpinner from "../loader-spinner";

interface SearchBoxProps {
  className?: string;
  icon?: string;
  disabled?: boolean;

  required?: boolean;
  errorCode?: string;

  labelText: string;
  initialValue?: string;
  isCountry?: boolean;
  onSelect: (value: any) => void;
  onChange: Function;

  optionsText: Array<{
    [key: string]: string;
  }>;
  optionKey: string;
  optionValue: string;

  withTooltip?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;
  withLoader?: boolean;
  loader?: boolean;
  notScroll?:boolean;
}

interface SearchBoxState {
  showTooltip: boolean;
  showList: boolean;
  listFilter: any[];
  search: string;
  value: string;
  valueLabel: string;
  error: string;
  focus: boolean;
  hoveredItem: number;
  loaded: boolean;
  selectedValue: string;
}

class SearchBox extends Component<SearchBoxProps, SearchBoxState> {
  private toggleContainer: any;
  private hoveredElement: any;
  private listContainer: any;

  constructor(props: SearchBoxProps) {
    super(props);
    this.state = {
      showTooltip: false,
      showList: false,
      listFilter: props.optionsText,
      search: "",
      value: props.initialValue || "",
      valueLabel: "",
      error: "",
      focus: false,
      hoveredItem: -1,
      loaded: true,
      selectedValue: "",
    };
    this.toggleContainer = React.createRef();
    this.hoveredElement = React.createRef();
    this.listContainer = React.createRef();
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  componentDidMount = () => {
    const { notScroll } = this.props;
    if (!notScroll) {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 100);
    }

    window.addEventListener("click", this.onClickOutsideHandler);
    window.addEventListener("keydown", this.keyHandler.bind(this));
  };

  componentDidUpdate(prevProps: SearchBoxProps) {
    const { value, loaded } = this.state;
    const { initialValue, optionValue, optionsText } = this.props;
    if (
      prevProps.initialValue !== initialValue &&
      initialValue &&
      value === ""
    ) {
      this.setState({ value: initialValue });
    } else if (prevProps.initialValue !== initialValue && initialValue === "") {
      this.setState({ value: "", listFilter: optionsText, search: "" });
    } else if (initialValue && initialValue !== prevProps.initialValue) {
      this.setState({ value: initialValue });
    }

    // if (this.props.optionsText.length !== prevProps.optionsText.length) {
    //   const listFilter = this.props.optionsText.filter(
    //     (item) =>
    //       item[optionValue].toLowerCase().indexOf(value.toLowerCase()) > -1
    //   );
    //   this.setState({ listFilter, loaded: true });
    // } else {
    //   let i = 0;
    //   let change = false;
    //   while (i < this.props.optionsText.length) {
    //     if (
    //       this.props.optionsText[i].value !== prevProps.optionsText[i].value
    //     ) {
    //       change = true;
    //       break;
    //     }
    //     i++;
    //   }
    //   if (change) {
    //     const listFilter = this.props.optionsText.filter(
    //       (item) =>
    //         item[optionValue].toLowerCase().indexOf(value.toLowerCase()) > -1
    //     );
    //     this.setState({ listFilter, loaded: true });
    //   }
    // }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClickOutsideHandler);
  }

  onClickOutsideHandler(event: Event) {
    const { showList } = this.state;
    if (
      this.state.showList &&
      !this.toggleContainer.current.contains(event.target)
    ) {
      if (showList) {
        this.setItemBlur();
      }
    }
  }

  handleSelect(option: any) {
    const { optionValue, onSelect } = this.props;
    if (!option) return;
    this.setState(
      {
        value: option[optionValue],
        selectedValue: option[optionValue],
        showList: false,
        
      },
      () => {
        onSelect(option);
      }
    );
  }

  setActive() {
    const { showList } = this.state;
    // showList && (document.activeElement as HTMLElement).blur();
    this.setState({ showList: true, focus: true });
  }

  keyHandler(ev: any) {
    const { hoveredItem, showList, listFilter } = this.state;
    if (!showList) return;
    switch (ev.key.toLowerCase()) {
      case "arrowdown":
        ev.preventDefault();
        const newPostion =
          listFilter.length - 1 <= hoveredItem ? 0 : hoveredItem + 1;
        this.setState({ hoveredItem: newPostion }, () =>
          this.scrollToHovered()
        );
        break;
      case "arrowup":
        ev.preventDefault();
        this.setState(
          {
            hoveredItem:
              hoveredItem <= 0 ? listFilter.length - 1 : hoveredItem - 1,
          },
          () => this.scrollToHovered()
        );
        break;
      case "enter":
        ev.preventDefault();
        this.handleSelect(listFilter[hoveredItem]);
        break;
    }
  }

  scrollToHovered() {
    if (
      !this.hoveredElement.current ||
      !this.listContainer.current ||
      !this.listContainer.current.scrollTo
    )
      return;
    this.listContainer.current.scrollTo(
      0,
      this.hoveredElement.current.offsetTop
    );
  }

  setValueTimeout: any = null;
  setValue(e: any) {
    const { optionValue, onChange, onSelect, isCountry } = this.props;
    if(isCountry){
      onSelect("");
    }
    
    onChange(e);
    if (e.target.value === "") {
      this.setState({
        listFilter: this.props.optionsText,
        value: e.target.value,
        loaded: true,
      });
    } else {
      const listFilter = this.props.optionsText.filter(
        (item) =>
          item[optionValue].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(
            e.target.value
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          ) > -1
      );
      this.setState({
        listFilter,
        value: e.target.value,
        loaded: false,
        showList: true,
      });
      clearTimeout(this.setValueTimeout);
      this.setValueTimeout = setTimeout(() => {
        this.setState({ loaded: true });
      }, 10000);
    }
  }

  setItemBlur(state = {}) {
    const { listFilter, value, selectedValue } = this.state;
    const { optionValue } = this.props;
    // const element = listFilter.filter(
    //   (item) =>
    //     item[optionValue].toLowerCase().indexOf(value.toLowerCase()) > -1
    // );

    // if (value !== "" && element[0] && selectedValue !== value) {
    //   const list = this.props.optionsText.filter(
    //     (item) =>
    //       item[optionValue]
    //         .toLowerCase()
    //         .indexOf(element[0][optionValue].toLowerCase()) > -1
    //   );
    //   this.setState({
    //     ...state,
    //     value: element[0][optionValue],
    //     listFilter: list,
    //     showList: false,
    //   });
    //   this.props.onSelect(element[0]);
    // } else {
      this.setState({
        ...state,
        listFilter: this.props.optionsText,
        // value: "",
        value: selectedValue !== value ? value : selectedValue,
        showList: false,
      });
    // }
  }
  

  render() {
    const {
      className,
      icon,
      disabled,
      errorCode,
      labelText,
      optionsText,
      optionValue,
      withTooltip,
      tooltipTitle,
      tooltipText,
      required,
      withLoader,
    } = this.props;

    const {
      showTooltip,
      showList,
      listFilter,
      value,
      focus,
      hoveredItem,
      loaded,
    } = this.state;

    const error_text = errorCode ? ERROR_MESSAGES[errorCode] : "";

    return (
      <SearchBoxContainer
        ref={this.toggleContainer}
        className={`${className} ${disabled ? "disabled" : ""} ${
          errorCode ? "error" : ""
        } ${value !== "" ? "complete" : ""} ${required ? "required" : ""}`}
      >
        <div className="input-box-topbar">
          <div className="input-box-topbar-label">
            <LabelText color={focus ? colors["SC-001-100"] : ""}>
              <p>{labelText}</p>
            </LabelText>
          </div>

          {withTooltip && (
            <div
              className="input-box-topbar-icon"
              onClick={() =>
                this.setState((prevState: SearchBoxState) => ({
                  showTooltip: !prevState.showTooltip,
                }))
              }
            >
              <img src={images.IconInfoBlue} />
              <div className="input-box-tooltip">
                <TooltipBox
                  active={showTooltip}
                  tooltipTitle={tooltipTitle}
                  tooltipText={tooltipText}
                />
              </div>
            </div>
          )}
        </div>

        <div className="input-box-main">
          <div className="input-box-main-field">
            <input
              onFocus={() => this.setActive()}
              onBlur={() => {
                // setTimeout(() => {
                //   this.setState({ showList: false, focus: false });
                // }, 500);
                // setTimeout(() => {
                //   this.setItemBlur({ focus: false });
                // }, 500);
              }}
              disabled={disabled}
              type="text"
              autoComplete="nope"
              value={value}
              onChange={(e) => this.setValue(e)}
            />
          </div>
                <div className="input-box-icon" onClick={() => this.setActive()}>
            <img src={icon} />
          </div>
        </div>

        {showList && (
          <ul
          id='list-of-search'
            className="input-box-main-list"
            tabIndex={-1}
            ref={this.listContainer}
          >
            {this.props.loader ? (
              <li className="no-data">
                <LoaderSpinner />
              </li>
            ) : optionsText.length <= 0 && value !== "" ? (
              <li className="no-data">
                <p>No se han encontrado resultados.</p>
                <p>
                  Escriba más caracteres y/o compruebe que todo está correcto.
                </p>
              </li>
            ) : (
              optionsText.map((option, i) => (
                <li
                  key={i}
                  onClick={() => this.handleSelect(option)}
                  ref={hoveredItem === i ? this.hoveredElement : null}
                  className={hoveredItem === i ? "hovered" : ""}
                >
                  <BodyText>
                    <p>{option[optionValue]}</p>
                  </BodyText>
                </li>
              ))
            )}
          </ul>
        )}

        {errorCode && !disabled && (
          <div className="input-box-error">
            <NoteText>
              <p>{`* ${error_text}`}</p>
            </NoteText>
          </div>
        )}
      </SearchBoxContainer>
    );
  }
}

export default SearchBox;
