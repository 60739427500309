import styled from "styled-components";

export const DentalInsuredContainer = styled.div`
.selectBox {
    height: auto;
    margin-bottom: 20px;
}

.beneficiaries-table{
    margin-top: 20px;
}

.col-10 {
    width: 10%;
}
.col-15 {
    width: 15%;
}
.col-20 {
    width: 20%;
}
.col-25 {
    width: 25%;
}
.col-2 {
    width: 20%;
}
.col-30 {
    width: 30%;
}
.col-35 {
    width: 35%;
}
.col-40 {
    width: 40%;
}
.col-50 {
    width: 50%;
}
.col-60 {
    width: 60%;
}
.col-70 {
    width: 70%;
}
.result-simulation-special-row {
    width: 100%;
    display: flex;
    float: left;
    margin-bottom: 20px;
}
`;
