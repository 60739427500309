export const colors = {
  "PR-001-100": "#004F8B",
  "PR-001-80": "#3372A2",
  "PR-001-50": "#80A7C5",
  "PR-001-30": "#B3CADC",
  "SC-001-100": "#6CC9F0",
  "SC-001-80": "#89D4F3",
  "SC-001-50": "#B5E4F7",
  "SC-001-30": "#D3EFFB",
  "SC-002-100": "#ED3C12",
  "SC-002-80": "#E57889",
  "SC-002-50": "#EFABB5",
  "SC-002-30": "#F5CCD3",
  "SC-003-100": "#57B6B6",
  "SC-003-80": "#79C5C5",
  "SC-003-50": "#ABDBDB",
  "SC-003-30": "#CDE9E9",
  "SC-004-100": "#EFA859",
  "SC-004-80": "#FCCD85",
  "SC-004-50": "#FDDFB3",
  "SC-004-30": "#FEECD1",
  "SC-005-100": "#632E3E",
  "SC-005-80": "#AB93E1",
  "SC-005-50": "#CABCEC",
  "SC-005-30": "#CABCEC",
  "SC-006-80": "#807F7F",
  "ER-001-100": "#e40202",
  "ER-001-80": "#FF6733",
  "ER-001-50": "#FFA080",
  "ER-001-30": "#FFD9CC",
  "AL-001-100": "#F9C131",
  "AL-001-90": "#FFCD4B",
  "AL-001-80": "#FFD76F",
  "AL-001-50": "#FFE6A5",
  "AL-001-30": "#FFF0C9",
  "AL-001-20": "#FDF5E0",
  "SU-001-100": "#40CAA9",
  "SU-001-80": "#66D5BA",
  "SU-001-50": "#9FE4D4",
  "SU-001-30": "#C6EFE5",
  "SU-001-10": "#E8F9F5",
  "FL-001-Default": "#FFFFFF",
  "FL-001-Disabled": "#F7F7F7",
  "FL-001-30-Disabled": "#B5BCC6",
  "TX-001-100": "#091E42",
  "TX-001-80": "#3A4B68",
  "TX-001-50": "#848FA1",
  "TX-001-30": "#B5BCC6",
  "HV-Text": "#274074",
  "HV-TextLink": "#3F99BF",
  "HV-Error": "#CB3401",
  "HV-Alert": "#F3BC2D",
  "HV-Success": "#2AB291",
  "NT-001-100": "#191B1C",
  "NT-001-000": "#ffffff",
  "keep-calm": "#fffaf3",
  "pets-color": "#fcf2f3",
  "dental-color":"#34D9C3",
  "health-color": "#f2f2fc",
  "pymes-color": "#F2F2F2",
  "PR-001/5": "#F2F6F9",
  "deaths-color": "#f7f4fc",
  "complete-color": "#edfaf7",
  "incomplete-color": "#fff0eb",
  "detail-item-background": "#fbfbfb",
  "background-modal": "rgba(255, 255, 255, 0.8)",
  "background-modal-icon": "#b3cadc4d",
  "travel-blue": "#9AC23F",
  "travel-blue-background": "#EFFFC9",
  "primaryColor": "#3f3f3f",
  "errorColor": "#e40202",
  "errorDarkColor": "#b30000",
  "disabled": "#ABABAB",
  "orange": '#FF5400',
  "orange-dark": '#CC4400',
};
