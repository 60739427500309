import styled from "styled-components";
import { colors } from "../../assets";

export const CheckboxItemContainer = styled.div`
  display: block;
  .checkbox-text {
    align-self:center;
  }
  .checkbox-container {
    display: flex;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height:30px;
    &:hover {
      input ~ .checkmark {
        background-color: rgba(0, 79, 139, 0.3);
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background-color: ${colors["PR-001-100"]};
      }

      &:checked ~ .checkmark:after {
        display: block;
      }
    }
  }

  .checkbox-container.disabled {
    pointer-events: none;
    input {
      color: ${colors["FL-001-30-Disabled"]};

      &:checked ~ .checkmark {
        background-color: ${colors["FL-001-30-Disabled"]};
      }
    }

    .checkmark {
      border: 1px solid ${colors["FL-001-30-Disabled"]};
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid ${colors["PR-001-100"]};
    border-radius: 3px;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 1px;
      width: 3px;
      height: 11px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &.blue {
    color: ${colors["PR-001-80"]};
    .checkbox-text {
      p {
        color: ${colors["PR-001-80"]};
      }
    }
  }

  .isMulti {
    padding-top: 0px;
    &:hover {
      background-color: rgba(0, 79, 139, 0.3);
    }

    & p {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    & span {
      top: 6px;
    }
  }

  .checkbox-error {
    color: red;
    display: block;

    p {
      color: ${colors["errorColor"]};
    }
  }
`;
