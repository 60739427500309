import React from "react";
import { IconButtonWrapper } from "./icon-button-style";

type IconButtonProps = {
  icon: string;
  onClick: () => void;
  disabled?: boolean;
};

const IconButton = ({ icon, onClick, disabled }: IconButtonProps) => {
  return (
    <IconButtonWrapper>
      <button onClick={onClick} disabled={disabled}>
        <img src={icon} alt="" />
      </button>
    </IconButtonWrapper>
  );
};

export default IconButton;
