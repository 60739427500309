import React from "react";
import { Link } from "react-router-dom";

import { CardContainer } from "./card-communications-style";
import { images } from "../../assets";
import { NotificationType } from "../../containers/communications/helpers";

interface CardCommunicationsType {
  notification: NotificationType;
  origin: "home" | "panel";
}

const CardCommunications = ({
  notification,
  origin,
}: CardCommunicationsType) => {
  const { type, resumen, description } = notification;
  const isHome = origin === "home";
  const iconImage = type === "2" ? images.IconAlertRed : images.BellBlueIcon;

  return (
    <CardContainer origin={origin}>
      <div className="icon">
        <img src={iconImage} alt="" />
      </div>

      <div className="content">
        <p>
          {resumen}{" "}
          {isHome ? (
            <Link className="link" to="/panel-notificaciones">
              Más información
            </Link>
          ) : (
            ""
          )}
        </p>
        {!isHome ? <p>{description}</p> : ""}
      </div>
    </CardContainer>
  );
};

export default CardCommunications;
