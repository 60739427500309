import React from "react";
import CheckboxModalScreen from "./checkbox-modal-screen";

export default class CheckboxModal extends React.Component<any, {}> {
  render() {
    const { img, text, className, onClickAccept, mainTitle, mainText, active, checkConditions, enableAcceptButton } = this.props;

    return (
      <CheckboxModalScreen
        enableAcceptButton={enableAcceptButton}
        checkConditions={checkConditions}
        active={active}
        mainTitle={mainTitle}
        mainText={mainText}
        img={img}
        text={text}
        className={className}
        onClickAccept={() => onClickAccept()}
      />
    );
  }
}
