import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

export const LayoutStyle = createGlobalStyle`

html, body, div, span, applet, object, iframe, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;

}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
  box-sizing: border-box;
  font-family: "museo sans";
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  height: auto;
  width: 100%;
}

input {
	-webkit-appearance: none;
  border: none;
  background: none;
}

input, button {
  &:focus 
  {
    outline: unset;
  }

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: none !important; 
    color: inherit;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

button {
	margin:none;
}

`;

export const LayoutContainer = styled.div`
  padding-top: 80px;
  padding-left: 270px;
  padding-right: 40px;

  &.no-header {
    padding-top: 0;
  }

  &.no-sidebar {
    padding-left: 0;
  }

  &.is-login {
    padding: 80px 130px;
    flex: 1;
  }

  &.is-not-login {
    animation: showUp 1.5s;
  }

  &.in-flow {
    padding: 80px 350px 0px 125px;
  }

  &.is-full {
    padding: 80px 40px 0px 125px;
  }

  opacity: 1;

  @keyframes showUp {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
