import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  InsuredProps,
  InsuredSimulationInterface,
  InsuredState,
} from "./insured-form.types";
import { IHealthCoverage, STORAGE_KEY } from "../../../../../constants/health";
import { capitalizeFirstLetter } from "../../../../../utils";
import HealthService from "../../../../../services/HealthService";
import CoveragesServices from "../../../../../services/CoveragesServices";
import AddressServices from "../../../../../services/AddressServices";
import {
  isCapitalInRangeHealthByCoverage,
  isOutOfCoverageAgeRange,
} from "../../../../../utils/coverages";
import { calcAge } from "../../../../../utils/utility";
import GuideLine from "../../../../../components/guide-line";
import InputBox from "../../../../../components/input-box/input-box";
import CalendarBox from "../../../../../components/calendar-box/calendar-box";
import DicotomicButton from "../../../../../components/dicotomic-button";
import MainButton from "../../../../../components/main-button";
import { images } from "../../../../../assets";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import SEX from "../../../../../constants/sex.json";
import ConstantsServices from "../../../../../services/ConstantsServices";
import { InsuredDataContainer } from "./insured-form.style";
import InsuredTable from "./insured-table/insured-table";
import SelectBox from "../../../../../components/select-box/select-box";
import { campaign } from "../../../../hirings/new-hiring/health/health-price/health-price-dto/campaign-dto";
import CampaignsServices from "../../../../../services/CampaignsServices";

class InsuredForm extends React.Component<InsuredProps, InsuredState> {
  constructor(props: InsuredProps) {
    super(props);
    var healthSimulationData;
    if (localStorage.getItem(STORAGE_KEY.sKey)) {
      healthSimulationData = JSON.parse(
        String(localStorage.getItem(STORAGE_KEY.sKey))
      );
    }

    this.state = {
      birthDate: "",
      coverages: [],
      healthSimulationData: healthSimulationData,
      insuredData: healthSimulationData.insuredData
        ? healthSimulationData.insuredData
        : [],
      mediatorData: healthSimulationData.mediatorData,
      postalCode: "",
      valid: {
        postalCode: {
          validated: false,
          errorCode: "",
        },
        gender: {
          validated: false,
          errorCode: "",
        },
        birthDate: false,
      },
      gender: "",
      showLeftSearchBoxModal: false,
      professionsList: [],
      covConfig: [],
      profession: {
        errorCode: "",
        labelText: "Profesión",
        value: "",
      },
      indexInsured: 0,
      serverError: false,
      modalErrorText: "",
      errorCampaña: "",
      hasCampaign: false,
      campanyas: [],
      selectedCampaign: '',
      modalErrorShow: false,
    };
  }

  formatCoveragesHealth = (coveragesData: any) => {
    const coveragesObj: any = [];

    coveragesData.forEach((coverage: any) => {
      let desc = "";
      if (coverage.name)
        desc = capitalizeFirstLetter(coverage.name.toLowerCase());

      let coverageObj: any = {
        code: coverage.code,
        checked: false,
        description: desc,
        required: coverage.required ? coverage.required : false,
        edadMin: coverage.edadMin ? coverage.edadMin : "",
        edadMax: coverage.edadMax ? coverage.edadMax : "",
        capMaxCober: coverage.capMaxCober,
        capMinCober: coverage.capMinCober,
        capitales: coverage.capitales,
      };
      coveragesObj.push(coverageObj);
    });

    return coveragesObj;
  };

  async getCoverages() {
    const { setLoading, policy, coverages } = this.props;
    const productType: string = policy.product.products_coverageName;

    if (coverages && coverages[productType]) {
      this.setState({
        coverages: this.formatCoveragesHealth(coverages[productType]),
      });
    } else {
      const resCoverages = await HealthService.getCoveragesFromDB(
        setLoading,
        true,
        productType
      ).catch((error) => {
        this.setState({
          serverError: true,
          modalErrorText: `Se ha producido un error. Vuelva a intentarlo más tarde.`,
        });
        throw error.statusText;
      });
      if (resCoverages !== undefined) {
        const cov = this.formatCoveragesHealth(resCoverages);
        this.setState({ coverages: cov });
      }
    }
  }

  componentDidMount = async () => {
    let { professionsList } = this.state;
    let { policy } = this.props;
    const covConfigs = await CoveragesServices.getCoveragesConfig({
      setLoading: () => {},
      loader: false,
      ignoreSetFalse: true,
    });

    professionsList = await ConstantsServices.getProfessions({
      setLoading: this.props.setLoading,
    });

    let filterProfessions = professionsList
      .filter((p) => p.product === "ALL")
      .sort(function (a: any, b: any) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    
    const campanyas = await this.getCampaigns();
    const campanyasToSelect = campanyas.map((campaign: { code: string; name: string }) => ({
      value: campaign.code,
      label: campaign.name,
    }));

    this.setState({
      professionsList: filterProfessions,
      covConfig: covConfigs,
      campanyas: campanyasToSelect,
    });

    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    if (Object.keys(policy.product).length !== 0) {
      this.getCoverages();
    }
  };

  componentDidUpdate(prevProps: any) {
    let { policy } = this.props;

    if (
      Object.keys(prevProps.policy.product).length === 0 &&
      Object.keys(policy.product).length !== 0
    ) {
      this.getCoverages();
    }
  }

  getPostalCodes = async (input: string) => {
    const { setLoading } = this.props;
    const resPostalCodes = await AddressServices.getPostalCodes({
      loader: true,
      setLoading: setLoading,
      params: [`input=${input}`, `provinceCode=*`, `cityCode=*`, `street=0`],
    });

    return resPostalCodes.length > 0;
  };

  toValidate = async () => {
    let validate = true;

    if (!this.state.hasCampaign && this.state.campanyas.length > 0) {
      validate = false;
      this.setState({ errorCampaña: "required" });
    }
    const { history } = this.props;
    const state = {
      insuredData: this.state.insuredData,
      mediatorData: this.state.mediatorData,
    };
    let { insuredData } = this.state;
    const { healthSimulationData } = this.state;
    let error: boolean = false;

    insuredData.forEach((insured: InsuredSimulationInterface) => {
      insured.coverages.forEach((coverage: IHealthCoverage) => {
        if (coverage.checked && coverage.capitales) {
          if (
            coverage.capitalValue === "" ||
            coverage.capitalValue === undefined
          ) {
            coverage.errorCode = "required";
            error = true;
          } else {
            const rangeError = isCapitalInRangeHealthByCoverage(coverage);

            if (rangeError !== "") {
              coverage.errorCode = rangeError;
              error = true;
            } else {
              coverage.errorCode = "";
            }
          }
        }
      });
    });

    if (!error && validate) {
      if (healthSimulationData) {
        healthSimulationData.insuredData = this.state.insuredData;
        localStorage.setItem(
          STORAGE_KEY.sKey,
          JSON.stringify({...healthSimulationData, campaign: { CAMPANYA: this.state.selectedCampaign}})
        );
      } else {
        localStorage.setItem(
          STORAGE_KEY.sKey,
          JSON.stringify({ insuredData: this.state.insuredData, campaign: { CAMPANYA: this.state.selectedCampaign}})
        );
      }

      history.replace({ state: state });
      history.push("/comparacion/nueva/salud/comparador");
    }
    this.setState({ insuredData });
  };

  handleBlurPostalCode(value: string) {
    const { valid } = this.state;

    if (value.length > 0 && value.length < 5) {
      valid.postalCode.errorCode = "invalid-postal-code";
      valid.postalCode.validated = false;
    }

    this.setState({ valid: valid });
  }

  handlePostalCode = async (value: string) => {
    const { valid } = this.state;

    if (value.length <= 5) {
      valid.postalCode.errorCode = "";

      if (value.length === 5) {
        valid.postalCode.validated = await this.getPostalCodes(value);
        if (!valid.postalCode.validated) {
          valid.postalCode.errorCode = "invalid-postal-code";
        }
      } else {
        if (valid.postalCode.validated)
          valid.postalCode.errorCode = "invalid-postal-code";
        valid.postalCode.validated = false;
      }
      this.setState({ postalCode: value, valid: valid });
    } else {
      if (valid.postalCode) {
        valid.postalCode.validated = false;
        this.setState({ valid: valid });
      }
    }
  };

  handleBirthData = (value: string) => {
    const { valid } = this.state;
    valid.birthDate = value.length === 10;
    this.setState({ birthDate: value, valid: valid });
  };

  handleAddInsured = () => {
    const { postalCode, birthDate, valid, coverages, gender } = this.state;

    valid.birthDate = false;
    valid.postalCode.validated = false;

    let { insuredData } = this.state;

    insuredData.forEach((insured: InsuredSimulationInterface) => {
      insured.checked = false;
    });

    const newInsuredData = JSON.parse(JSON.stringify(insuredData));
    if (this.validateAgeInsureds(birthDate)) {
      newInsuredData.push({
        birthDate,
        coverages: JSON.parse(JSON.stringify(coverages)),
        address: { postalCode },
        gender: gender,
        checked: true,
        profession: { code: "", value: "" },
      });
    }
    this.setState({
      birthDate: "",
      insuredData: newInsuredData,
      postalCode: "",
      gender: "",
    });
  };

  handleRemoveInsured = (row: any) => {
    const id: number = row.id;
    let insuredData = this.state.insuredData;
    insuredData.splice(id, 1);
    this.setState({ insuredData }, () => {
      var healthSimulationData;
      if (localStorage.getItem(STORAGE_KEY.sKey)) {
        healthSimulationData = JSON.parse(
          String(localStorage.getItem(STORAGE_KEY.sKey))
        );
      }
      if (healthSimulationData) {
        healthSimulationData.insuredData = this.state.insuredData;
        localStorage.setItem(
          STORAGE_KEY.sKey,
          JSON.stringify(healthSimulationData)
        );
      }
    });
  };

  onChangeOption = (
    insured: number,
    age: number,
    coverage: IHealthCoverage,
    value: boolean
  ) => {
    const covCapital = this.state.covConfig;

    if (
      coverage.required ||
      isOutOfCoverageAgeRange(age, coverage.edadMin, coverage.edadMax)
    )
      return false;

    const reqProfession = covCapital
      .filter((ele) => ele.config.reqProfession === true)
      .map((ele) => ele.code)
      .includes(coverage.code);

    const { insuredData, profession } = this.state;
    const newInsuredData = JSON.parse(JSON.stringify(insuredData));
    const index = newInsuredData[insured].coverages
      .map(function (e: any) {
        return e.code;
      })
      .indexOf(coverage.code);
    newInsuredData[insured].coverages[index].checked = value;

    if (reqProfession) {
      if (value === false) {
        newInsuredData[insured].profession.code = "";
        newInsuredData[insured].profession.value = "";
      }
      profession.value = insuredData[insured].profession.value;
      profession.errorCode = "";
    }

    this.setState({ insuredData: newInsuredData, indexInsured: insured });
  };

  handleGenderChange(value: string) {
    this.setState({ gender: value });
  }

  handleCapitalChange(
    capitalValue: string,
    insuredIndex: number,
    coverageCode: string
  ) {
    let { insuredData } = this.state;
    insuredData[insuredIndex].coverages.forEach((coverage: IHealthCoverage) => {
      if (coverage.code === coverageCode) {
        coverage.capitalValue = capitalValue;
        coverage.errorCode = "";
      }
    });
    this.setState({ insuredData });
  }

  handleInsuredCheck(insuredIndex: number) {
    let { insuredData } = this.state;
    insuredData.forEach((insured: InsuredSimulationInterface, index) => {
      if (index === insuredIndex) {
        insured.checked = !insured.checked;
      }
    });

    this.setState({ insuredData });
  }

  closeLeftModal() {
    const { profession, insuredData, indexInsured, covConfig } = this.state;

    const covReqProfession = covConfig;
    const codeCoverage = covReqProfession.filter(
      (ele) => ele.config.reqProfession
    )[0].code;

    const index = insuredData[indexInsured].coverages
      .map(function (e: any) {
        return e.code;
      })
      .indexOf(codeCoverage);

    insuredData[indexInsured].coverages[index].checked = false;

    this.setState({
      showLeftSearchBoxModal: false,
      profession: { ...profession, errorCode: "", value: "" },
      insuredData,
    });
  }

  handleProfessionChange(professionSelected: any) {
    const { insuredData, indexInsured, profession } = this.state;

    insuredData[indexInsured].profession.value = professionSelected.label;
    insuredData[indexInsured].profession.code = professionSelected.value;
    profession.errorCode = "";
    profession.value = professionSelected.label;

    this.setState({ insuredData, profession });
  }

  validateProfession = () => {
    const { profession } = this.state;

    if (profession.value === "") {
      this.setState({
        profession: {
          ...profession,
          errorCode: "required",
        },
      });
    } else {
      this.setState({ showLeftSearchBoxModal: false });
    }
  };

  validateAgeInsureds = (birthDate: any) => {
    const { serverError, coverages } = this.state;
    const covReqAgeMax = coverages.filter(
      (c: any) => c.required && c.edadMax !== ""
    );

    const age = calcAge(birthDate) || 0;
    const isHighAge = covReqAgeMax.some((c: any) => age > c.edadMax);

    if (isHighAge && !serverError) {
      this.setState({
        serverError: true,
        modalErrorText: `El asegurado excede de la edad máxima permitida para alguna de las coberturas requeridas.`,
      });
      return false;
    }
    return true;
  };

  campanyaChangeHandler(value: string) {
    this.setState(() => ({
      selectedCampaign: value,
      hasCampaign: value ? true : false,
    }));
  }

  getCampaigns = async () => {
    const { setLoading, policy } = this.props;
    // Buscamos la campaña de todo el colectivo, no de un producto (no combinacion_comercial)
    if (policy && policy.product && policy.product.products_colectivo) {
      let allCampaigns: campaign[] = await CampaignsServices.getCampaigns({
        loader: true,
        setLoading: setLoading,
        colective: policy.product.products_colectivo,
        combinacion_comercial: "",
        fecha_inicio: this.checkPolicyStartDate(this.state.healthSimulationData)
      }).catch((error) => {
        this.setState({
          modalErrorShow: true,
          modalErrorText: 'Se ha producido un error. Vuelva a intentarlo más tarde.'
        })
        throw error.statusText
      });

      return allCampaigns;
    }
    else {
      this.setState({
        modalErrorShow: true,
        modalErrorText: 'Se ha producido un error. Vuelva a intentarlo más tarde.'
      })
      return []
    }
  }

  checkPolicyStartDate (data: any): string {
    if (data && data.mediatorData && data.mediatorData.startDate) {
      return data.mediatorData.startDate;
    }
    return '';
  }

  render() {
    const { birthDate, gender, insuredData, postalCode, valid, serverError } =
      this.state;

    return (
      <InsuredDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <h2>{"DATOS DEL SEGURO"}</h2>
          </div>
          <div className="data-flow-status-guide">
            <GuideLine steps={[0, 0, 0, 0]} currentStep={2} />
          </div>
        </div>

        <div className="data-wrapper">
          <div className="content-wrapper bottomMargin40">
            <div className="personal-data-wrapper">
              <div className="data-title">
                <h5>DATOS DE LOS ASEGURADOS</h5>
              </div>
            </div>
            <div className="data-row colSin-100">
              <div className="colSin-33 rightPadding20 topMargin12">
                <div className="inputBox">
                  <InputBox
                    errorCode={valid.postalCode.errorCode}
                    maxLength={5}
                    onChange={(value: string) => this.handlePostalCode(value)}
                    onBlur={(value: string) => this.handleBlurPostalCode(value)}
                    placeholder="Código Postal"
                    required
                    type={"number"}
                    value={postalCode}
                  />
                </div>
              </div>

              <div className="colSin-33 rightPadding20 topMargin12">
                <div className="inputBox">
                  <CalendarBox
                    initialValue={birthDate}
                    placeholder="Fecha de nacimiento"
                    forbidFutureDates
                    maxDate={moment().toISOString()}
                    required
                    onChange={(value: string) => {
                      this.handleBirthData(value);
                    }}
                    health={true}
                  />
                </div>
              </div>

              <div className="colSin-33 rightPadding20">
                <DicotomicButton
                  required={true}
                  labelText="Sexo"
                  options={SEX}
                  withTab={true}
                  initialValue={gender}
                  onChange={(value: string) => this.handleGenderChange(value)}
                  errorCode={valid.gender.errorCode}
                />
              </div>
            </div>

            <div className="data-row colSin-100">
              <div className="colSin-75" />

              <div className="colSin-25 rightPadding20 topPadding15">
                <MainButton
                  disabled={
                    !(valid.postalCode.validated && valid.birthDate && gender)
                  }
                  text="Añadir Asegurado"
                  onClick={() => this.handleAddInsured()}
                />
              </div>
            </div>
          </div>

          {insuredData.length > 0 && (
            <InsuredTable
              data={insuredData}
              handleRemoveInsured={this.handleRemoveInsured}
            />
          )}

          <InsuredDataContainer>
            <div className="colSin-100 topMargin20">
              <div className="colSin-50 rightPadding20">
                <div className="selectBox">
                  <SelectBox
                    labelText=""
                    required={true}
                    optionsText={this.state.campanyas}
                    errorCode={this.state.errorCampaña}
                    placeholder={this.state.campanyas.length > 0 ? "Seleccionar campaña" : "No hay campañas disponibles"}
                    initialValue={""}
                    disabled={this.state.campanyas.length === 0}
                    optionKey={"value"}
                    optionValue={"label"}
                    onChange={(value: string) =>
                      this.campanyaChangeHandler(value)
                    }
                  />
                  <label>Campaña</label>
                </div>
              </div>
            </div>
          </InsuredDataContainer>
        </div>

        <div className="colSin-100 topMargin20 mb-10">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.goBack()}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                text="Siguiente"
                disabled={insuredData.length <= 0}
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>
        <ErrorModalContainer className={`${serverError && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton
                text="Aceptar"
                onClick={() => this.setState({ serverError: false })}
              />
            </div>
          </div>
        </ErrorModalContainer>
      </InsuredDataContainer>
    );
  }
}

export default withPolicy(withCoverages(withRouter(InsuredForm)));
