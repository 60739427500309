import React from "react";
import { TitleTextContainer } from "./title-text-styles";

interface TitleTextProps {
    children: any;
    className?: string;
    color?: string;
}

const TitleText = ({children = '', className='', color=''}:TitleTextProps) => {
        return (
        <TitleTextContainer color={color} className={className}>
            {children}
        </TitleTextContainer>
    )
} 
export default TitleText;