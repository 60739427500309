import styled from "styled-components";
import { colors } from "../../../assets";

export const ClientsReportsContainer = styled.section`
  max-width: 1200px;

  .filter-container {
    background-color: ${colors["detail-item-background"]};
    padding: 2em;
    display: flex;
    flex-wrap: nowrap;
    gap: 1.5em;
    width: 100%;

    .selectBox {
      width: 20%;
    }

    .selectBox.relatedMediators {
      width: 60%;
    }
  }

  .cards-container {
    display: flex;
    justify-content:space-around; 
  }
`;
