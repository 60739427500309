import React, { Component } from "react";
import LabelText from "../../../../../../components/components-text/label-text";
import InputBox from "../../../../../../components/input-box/input-box";
import PhoneBox from "../../../../../../components/phone-box/phone-box";
import SelectBox from "../../../../../../components/select-box/select-box";
import { COMUNICATION_CHANNEL_OPTIONS, COMUNICATION_LANGUAGE_OPTIONS_BOX, CONTACT_HOUR_OPTIONS_BOX, NO_PAPER_OPTIONS_BOX } from "../../../../../../constants/health";
import { isValidEmail, isValidMobile } from "../../../../../../utils/validation";
import { ContactDataDto, DefaultContactDataDto } from "./gip-contact-form-item-dto";
import { HealthContactFormContainer } from "./gip-contact-form-item-style";
import HocelotServices from "../../../../../../services/HocelotServices";

export interface ContactData {
    mobilePhone: string,
    landlinePhone: string,
    email: string,
    comunicationChannel: string,
    paperless: string,
    contactHour: string,
    comunicationLanguage: string,

}

interface GipContactFormProps {
    validate: {
        validateRequest: boolean;
        isValidated: boolean;
    };
    onValidated: (person: ContactData) => void;
    onCleanForm?: boolean;
    onEditInsured?: number;
    insured?: boolean;
    taker?: ContactData;
    insureds?: ContactData[];
    insuredIndex: number;
    isReadOnly?: boolean;
    onAnyChange: Function;
    useTakerData?: boolean;
    isPersonalCheckChanging?: boolean,

}

interface GipContactFormState {
    contactDto: ContactDataDto,
    previousData: ContactDataDto,
    disabled?: boolean;
}

class GipContactFormItem extends Component<GipContactFormProps, GipContactFormState> {

    constructor(props: GipContactFormProps) {
        super(props);
        this.state = {
            contactDto: DefaultContactDataDto(),
            previousData: DefaultContactDataDto(),
            disabled: false,

        }
    }

    recoverPreviousData = () => {
        const { previousData } = this.state;
        if (previousData)
            this.setState({ contactDto: previousData })

    }
    componentWillReceiveProps(nextProps: GipContactFormProps) {
        let { validate } = this.props;
        if (nextProps.validate.validateRequest) {
            validate.validateRequest = false;
            validate.isValidated = this.validateForm();
        }
        else if (nextProps.onCleanForm) {
            this.cleanForm();
        }
        if (nextProps.onEditInsured !== undefined && nextProps.onEditInsured !== -1 && this.props.onEditInsured !== nextProps.onEditInsured) {
            this.completeInsuredData(nextProps.onEditInsured);
            this.setState({ disabled: nextProps.useTakerData });
        }
        if (nextProps.useTakerData && !nextProps.onCleanForm) {
            this.completeFormTakerData()
            this.setState({ disabled: true })

        }
        if (nextProps.isPersonalCheckChanging && !nextProps.useTakerData) {
            this.cleanForm();
            this.setState({ disabled: false })
        }
    }

    componentDidMount() {
        if (this.props.taker !== undefined && this.props.taker.mobilePhone !== undefined && !this.props.insured) {
            this.completeFormTakerData();
        }
        this.setState({
            disabled: this.props.isReadOnly
        })
    }

    completeInsuredData(index: number) {
        let { contactDto } = this.state;
        const { insureds } = this.props;

        if (insureds && insureds[index] !== undefined) {
            contactDto.comunicationChannel.value = insureds[index].comunicationChannel !== undefined ? insureds[index].comunicationChannel : COMUNICATION_CHANNEL_OPTIONS.TELEFONICO;
            contactDto.comunicationLanguage.value = insureds[index].comunicationLanguage !== undefined ? insureds[index].comunicationLanguage : "";
            contactDto.contactHour.value = insureds[index].contactHour !== undefined ? insureds[index].contactHour : "";
            contactDto.emailInput.value = insureds[index].email !== undefined ? insureds[index].email : "";
            contactDto.landlinePhone.value = insureds[index].landlinePhone !== undefined ? insureds[index].landlinePhone : "";
            contactDto.mobilePhone.value = insureds[index].mobilePhone !== undefined ? insureds[index].mobilePhone : "";
            contactDto.paperlessBox.value = insureds[index].paperless !== undefined ? insureds[index].paperless : 'No';

            contactDto.emailInput.errorCode = '';
            contactDto.landlinePhone.errorCode = '';
            contactDto.mobilePhone.errorCode = '';

            this.setState({ contactDto });
        }
    }

    completeFormTakerData() {
        let { contactDto } = this.state;
        const { taker } = this.props;

        if (taker) {

            contactDto.comunicationChannel.value = taker.comunicationChannel;
            contactDto.comunicationLanguage.value = taker.comunicationLanguage !== undefined ? taker.comunicationLanguage : '';
            contactDto.contactHour.value = taker.contactHour;
            contactDto.emailInput.value = taker.email;
            contactDto.landlinePhone.value = taker.landlinePhone;
            contactDto.mobilePhone.value = taker.mobilePhone;
            contactDto.paperlessBox.value = taker.paperless;
        }
        this.setState({ contactDto });
    }

    handleFormChange(value: string, form: string): void {
        let { contactDto } = this.state;
        this.props.onAnyChange();

        if (form === 'CORREO'){
            contactDto.emailInput.value = value;
            if (contactDto.emailInput.errorCode) {
                if (contactDto.emailInput.value.length === 0) {
                    contactDto.emailInput.errorCode = ''
                }
                else if (isValidEmail(contactDto.emailInput.value)) {
                    contactDto.emailInput.errorCode = '';
                }
            }
        }
        this.setState({ contactDto })
    }
    
    async validateEmail (value: string) {
        let { contactDto } = this.state;
        if (!this.props.insured) {

        try {
            const result = await HocelotServices.verifyEmail({data: { email: value }, setLoading: () => {}});
            contactDto.emailInput.errorCode = result.isVerified ? '' : 'invalid-email';
        } catch (error) {
            contactDto.emailInput.errorCode = error && error.status === 422 ? 'invalid-email' : 'hocelot-verify-error';
        }
        this.setState({ contactDto })
    }
    }

    handleSelectChange(value: string, type: string) {
        let { contactDto } = this.state;
        switch (type) {
            case 'PAPERLESS':
                contactDto.paperlessBox.value = value;
                break;

            case 'CONTACT_HOUR':
                contactDto.contactHour.value = value;
                break;

            case 'COMUNICATION_LANGUAGE':
                contactDto.comunicationLanguage.value = value;
                contactDto.comunicationLanguage.errorCode = '';
                break;
            default: break;
        }
        this.setState({ contactDto });
    }

    handlePhoneValueChange(value: string, type: string) {
        let { contactDto } = this.state;
        this.props.onAnyChange();
        if (type === 'FIJO') {
            contactDto.landlinePhone.value = value;
        }
        else {
            if (contactDto.mobilePhone.value.replace('+34 ', '').length === 0) {
                contactDto.mobilePhone.errorCode = '';
            }
            contactDto.mobilePhone.value = value;
        }
        this.setState({ contactDto });
    }

    handleRadioChange(radio: string) {
        let { contactDto } = this.state;
        if (radio === COMUNICATION_CHANNEL_OPTIONS.CARTA_POSTAL) {
            contactDto.paperlessBox.value = 'Si';
        }
        else {
            contactDto.paperlessBox.value = 'No';
        }
        contactDto.comunicationChannel.value = radio;
        this.setState({ contactDto })
    }

    validateForm(): boolean {
        let { contactDto } = this.state;
        let noErrors = true;
        let validEmail = true;
        let validMobile = true;

        //Validación mail
        if (contactDto.emailInput.value.length > 0 &&  contactDto.emailInput.errorCode !== "") {
            noErrors = false;
            validEmail = false;
        }
        else if (contactDto.emailInput.value.length === 0) {
            contactDto.emailInput.errorCode = 'required';
            noErrors = false;
            validEmail = false;
        }
        else {
            contactDto.emailInput.errorCode = '';
        }

        //Validación teléfono movil
        if (contactDto.mobilePhone.value.replace('+34 ', '').length === 0) {
            contactDto.mobilePhone.errorCode = 'required';
            noErrors = false;
            validMobile = false;
        }
        else if (!isValidMobile(contactDto.mobilePhone.value)) {
            contactDto.mobilePhone.errorCode = 'invalid-phone-number';
            noErrors = false;
            validMobile = false;
        } else {
            contactDto.mobilePhone.errorCode = '';
        }

        if (this.props.insured) {

            if (validEmail || validMobile) {

                noErrors = true;
                contactDto.emailInput.errorCode = '';
                contactDto.mobilePhone.errorCode = '';
            }

        }
        if (noErrors) {

            this.props.onValidated(
                {
                    mobilePhone: contactDto.mobilePhone.value,
                    landlinePhone: contactDto.landlinePhone.value,
                    email: contactDto.emailInput.value,
                    comunicationChannel: contactDto.comunicationChannel.value,
                    paperless: contactDto.paperlessBox.value,
                    comunicationLanguage: contactDto.comunicationLanguage.value,
                    contactHour: contactDto.comunicationLanguage.value
                }
            )
        }

        this.setState({ contactDto });
        return noErrors;
    }

    cleanForm() {
        this.setState({ contactDto: DefaultContactDataDto() });
    }

    render() {
        const { contactDto, disabled } = this.state;
        const { isReadOnly } = this.props;

        return (
            <HealthContactFormContainer>
                <div className="content-wrapper bottomMargin20" ref={''}>
                    <div className="personal-data-wrapper" id="data-contact">
                        <div className="data-title topMargin20">
                            <h5>DATOS CONTACTO</h5>
                        </div>
                        <div className="colSin-100">
                            <div className="colSin-50 rightPadding20 topMargin20 bottomMargin20">
                                <div className="inputBox">
                                    <PhoneBox
                                        errorCode={contactDto.mobilePhone.errorCode}
                                        onChange={(value: string) => this.handlePhoneValueChange(value, 'MOVIL')}
                                        labelText=''
                                        required={true}
                                        initialValue={contactDto.mobilePhone.value}
                                        disabled={contactDto.mobilePhone.disabled || isReadOnly || disabled}
                                    />
                                    <label>teléfono móvil</label>
                                </div>
                            </div>
                            <div className="colSin-50 rightPadding20 topMargin20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        errorCode={'Campo requerido'}
                                        onChange={(value: string) => this.handlePhoneValueChange(value, 'FIJO')}
                                        placeholder="TELÉFONO FIJO"
                                        type={"number"}
                                        value={contactDto.landlinePhone.value}
                                        maxLength={12}
                                        disabled={contactDto.landlinePhone.disabled || isReadOnly || disabled}
                                    />
                                </div>
                            </div>

                            <div className="colSin-100 rightPadding20 bottomMargin20">
                                <div className="inputBox">
                                    <InputBox
                                        errorCode={contactDto.emailInput.errorCode}
                                        onChange={(value: string) => this.handleFormChange(value, "CORREO")}
                                        onBlur={ ((value: string) => this.validateEmail(value))}
                                        placeholder="CORREO ELECTRÓNICO"
                                        required={true}
                                        type={"email"}
                                        value={contactDto.emailInput.value}
                                        disabled={contactDto.emailInput.disabled || isReadOnly || disabled}
                                    />
                                </div>
                            </div>

                            {false && (
                                <div>
                                    <div className="colSin-50 rightPadding20 bottomMargin20">
                                        <div className={`input-box-topbar-label required`}>
                                            <LabelText>
                                                <p>CANAL DE COMUNICACIÓN</p>
                                            </LabelText>
                                        </div>
                                        <div className="contact-radio-main-row">
                                            <div className="radioBox">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        disabled={contactDto.comunicationChannel.disabled || isReadOnly || disabled}
                                                        name={COMUNICATION_CHANNEL_OPTIONS.TELEFONICO}
                                                        value={COMUNICATION_CHANNEL_OPTIONS.TELEFONICO}
                                                        onChange={() => this.handleRadioChange(COMUNICATION_CHANNEL_OPTIONS.TELEFONICO)}
                                                        checked={contactDto.comunicationChannel.value === COMUNICATION_CHANNEL_OPTIONS.TELEFONICO ? true : false}
                                                    />
                                                    <span>TELEFÓNICO</span>
                                                </label>
                                            </div>
                                            <div className="radioBox">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        disabled={contactDto.comunicationChannel.disabled || isReadOnly || disabled}
                                                        name={COMUNICATION_CHANNEL_OPTIONS.EMAIL}
                                                        value={COMUNICATION_CHANNEL_OPTIONS.EMAIL}
                                                        onChange={() => this.handleRadioChange(COMUNICATION_CHANNEL_OPTIONS.EMAIL)}
                                                        checked={contactDto.comunicationChannel.value === COMUNICATION_CHANNEL_OPTIONS.EMAIL ? true : false}
                                                    />
                                                    <span>EMAIL</span>
                                                </label>
                                            </div>
                                            <div className="radioBox">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        disabled={contactDto.comunicationChannel.disabled || isReadOnly || disabled}
                                                        name={COMUNICATION_CHANNEL_OPTIONS.SMS}
                                                        value={COMUNICATION_CHANNEL_OPTIONS.SMS}
                                                        onChange={() => this.handleRadioChange(COMUNICATION_CHANNEL_OPTIONS.SMS)}
                                                        checked={contactDto.comunicationChannel.value === COMUNICATION_CHANNEL_OPTIONS.SMS ? true : false}
                                                    />
                                                    <span className="recover-checkmark"> SMS</span>
                                                </label>
                                            </div>
                                            <div className="radioBox">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        disabled={contactDto.comunicationChannel.disabled || isReadOnly || disabled}
                                                        name={COMUNICATION_CHANNEL_OPTIONS.CARTA_POSTAL}
                                                        value={COMUNICATION_CHANNEL_OPTIONS.CARTA_POSTAL}
                                                        onChange={() => this.handleRadioChange(COMUNICATION_CHANNEL_OPTIONS.CARTA_POSTAL)}
                                                        checked={contactDto.comunicationChannel.value === COMUNICATION_CHANNEL_OPTIONS.CARTA_POSTAL ? true : false}
                                                    />
                                                    <span className="recover-checkmark"> Carta postal</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="colSin-50 rightPadding20 bottomMargin20">
                                        <div className="selectBox">
                                            <SelectBox
                                                optionsText={NO_PAPER_OPTIONS_BOX}
                                                optionKey={"value"}
                                                optionValue={"label"}
                                                defaultValue={contactDto.paperlessBox.value}
                                                onChange={(value: string) => this.handleSelectChange(value, 'PAPERLESS')}
                                                propValue={contactDto.paperlessBox.value}
                                                required={true}
                                                disabled={contactDto.paperlessBox.disabled || isReadOnly || disabled}
                                            />
                                            <label>SIN PAPEL</label>
                                        </div>
                                    </div>

                                    <div className="colSin-50 rightPadding20 bottomMargin20">
                                        <div className="selectBox">
                                            <SelectBox
                                                optionsText={CONTACT_HOUR_OPTIONS_BOX}
                                                optionKey={"value"}
                                                optionValue={"label"}
                                                defaultValue={contactDto.contactHour.value}
                                                onChange={(value: string) => this.handleSelectChange(value, 'CONTACT_HOUR')}
                                                propValue={contactDto.contactHour.value}
                                                disabled={contactDto.contactHour.disabled || isReadOnly || disabled}
                                            />
                                            <label>HORARIO DE CONTACTO</label>
                                        </div>
                                    </div>
                                    <div className="colSin-50 rightPadding20 bottomMargin20">
                                        <div className="selectBox">
                                            <SelectBox
                                                optionsText={COMUNICATION_LANGUAGE_OPTIONS_BOX.sort((a, b) => {
                                                    return String(a.label).localeCompare(b.label);
                                                })}
                                                optionKey={"value"}
                                                optionValue={"label"}
                                                defaultValue={contactDto.comunicationLanguage.value}
                                                onChange={(value: string) => this.handleSelectChange(value, 'COMUNICATION_LANGUAGE')}
                                                propValue={contactDto.comunicationLanguage.value}
                                                errorCode={contactDto.comunicationLanguage.errorCode}
                                                required={true}
                                                disabled={contactDto.comunicationLanguage.disabled || isReadOnly || disabled}
                                            />
                                            <label>LENGUA COMUNICACIÓN</label>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </HealthContactFormContainer >
        )
    }
}
export default GipContactFormItem;
