import React, { Component } from "react";
import { ModalCreatePointsContainer } from "./modal-create-points-style";
import { images } from "../../../../assets";
import H4Text from "../../../../components/components-text/h4-text";
import MainButton from "../../../../components/main-button";
import SelectBox from "../../../../components/select-box/select-box";
import InputBox from "../../../../components/input-box/input-box";
import {
  PRODUCT_BRANCHES_DROPDOWN,
  LIFE_PRODUCTS_DROPDOWN,
  PET_PRODUCTS_DROPDOWN,
  DEATH_PRODUCTS_DROPDOWN
} from "../../../../constants/campaignsConfig";

const PRODUCT_TYPES_DROPDOWN = [
  {
    value: "PREMIUM",
    label: "Por prima"
  },
  {
    value: "HIRING",
    label: "Por contratación"
  }
];

const YEARS_DROPDOWN = [
  {
    value: "2020",
    label: "2020"
  },
  {
    value: "2021",
    label: "2021"
  },
  {
    value: "2022",
    label: "2022"
  },
  {
    value: "2022",
    label: "2022"
  },
  {
    value: "2023",
    label: "2023"
  },
  {
    value: "2024",
    label: "2024"
  },
  {
    value: "2025",
    label: "2025"
  },
  {
    value: "2026",
    label: "2026"
  },
  {
    value: "2027",
    label: "2027"
  },
  {
    value: "2028",
    label: "2028"
  },
  {
    value: "2029",
    label: "2029"
  },
  {
    value: "2030",
    label: "2030"
  }
];

interface IModalCreatePointsProps {
  close: () => void;
  onSave: (state: IModalCreatePointsState) => void;
  editPoint?: IModalCreatePointsState;
}

export interface IModalCreatePointsState {
  id?: number;
  year: string;
  value: string;
  minPremium: string;
  product: string;
  productLine: string;
  productType: string;
  errors: { [key: string]: string };
}

class ModalCreatePoints extends Component<
  IModalCreatePointsProps,
  IModalCreatePointsState
  > {
  containerRef: any;

  constructor(props: IModalCreatePointsProps) {
    super(props);
    this.state = {
      year: "",
      value: "",
      product: "",
      productLine: "",
      productType: "",
      minPremium: "",
      errors: {}
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const { editPoint } = this.props;
    if (editPoint) this.setState(editPoint);
    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  isVisible(e: any) {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
  }

  handleClickOutside(ev: any) {
    const { close } = this.props;
    this.isVisible(ev.target) &&
      this.containerRef.current &&
      !this.containerRef.current.contains(ev.target) &&
      close();
  }

  handleChange(field: string, value: string | number | string[]) {
    const state = Object.assign(this.state);
    state[field] = value;
    if (field === "productLine") state.product = "";
    this.setState(state);
  }

  handleSave() {
    const { onSave } = this.props;
    const {
      year,
      value,
      product,
      productLine,
      productType,
      minPremium,
      errors
    } = this.state;
    let hasError = false;
    const fields: any = { year, value, minPremium, product, productLine, productType };
    Object.entries(fields).forEach((fieldEntry: any) => {
      const key = fieldEntry[0];
      const value = fieldEntry[1];
      errors[key] = "";
      if (key !== 'minPremium' && (!value || value === "")) {
        errors[key] = "required";
        hasError = true;
      } else if (key === 'minPremium' && parseFloat(value) <= 0) {
        errors[key] = "positive-number-error";
        hasError = true;
      }
    });
    this.setState({ errors });
    if (!hasError) {
      const stateCopy = JSON.parse(JSON.stringify(this.state));
      if (stateCopy.minPremium === '') delete stateCopy.minPremium;
      onSave(stateCopy);
    }
  }

  render() {
    const { close, editPoint } = this.props;
    const {
      year,
      value,
      product,
      productLine,
      productType,
      minPremium,
      errors
    } = this.state;
    const productDropdown =
      productLine === "life"
        ? LIFE_PRODUCTS_DROPDOWN
        : productLine === "pet"
          ? PET_PRODUCTS_DROPDOWN
          : DEATH_PRODUCTS_DROPDOWN;
    return (
      <ModalCreatePointsContainer>
        <div className="modal-create-points-aux-container">
          <div
            className="modal-create-points-container"
            ref={this.containerRef}
          >
            <div className="modal-create-points-close">
              <img src={images.IconCloseGrey} onClick={() => close()} />
            </div>
            <div className="modal-create-points-header">
              <div className="modal-create-points-header-title">
                <div className="modal-create-points-header-title__icon">
                  <img
                    src={
                      editPoint ? images.IconEditBlueClear : images.IconStarBlue
                    }
                  />
                </div>
                <div className="modal-create-points-header-title__text">
                  <H4Text>
                    {editPoint ? "Editar puntuación" : "Crear puntuación"}
                  </H4Text>
                </div>
              </div>
            </div>
            <div className="modal-create-points-wrapper">
              <div className="modal-create-points-subtitle">
                Lorem ipsum dolor sit amet consectitur alit lorem ipsum dolor
                sit amet.
              </div>
              <div className="modal-create-points-main">
                <div className={"modal-col-50"}>
                  <div className={"modal-create-points-main__field"}>
                    <SelectBox
                      className={"rounded"}
                      optionsText={YEARS_DROPDOWN}
                      optionKey={"value"}
                      optionValue={"label"}
                      defaultValue={year}
                      initialValue={year}
                      errorCode={errors.year}
                      onChange={value => this.handleChange("year", value)}
                      placeholder={"Año"}
                      required={true}
                    />
                  </div>
                  <div className={"modal-create-points-main__field"}>
                    <SelectBox
                      className={"rounded"}
                      optionsText={PRODUCT_BRANCHES_DROPDOWN}
                      optionKey={"value"}
                      optionValue={"label"}
                      defaultValue={productLine}
                      initialValue={productLine}
                      errorCode={errors.productLine}
                      onChange={value =>
                        this.handleChange("productLine", value)
                      }
                      placeholder={"Ramo"}
                      required={true}
                    />
                  </div>
                </div>
                <div className={"modal-col-50"}>
                  <div className={"modal-create-points-main__field"}>
                    <SelectBox
                      className={"rounded"}
                      optionsText={productDropdown}
                      optionKey={"value"}
                      optionValue={"label"}
                      defaultValue={product}
                      initialValue={product}
                      errorCode={errors.product}
                      onChange={value => this.handleChange("product", value)}
                      placeholder={"Producto..."}
                      required={true}
                    />
                  </div>
                  <div className={"modal-create-points-main__field"}>
                    <SelectBox
                      className={"rounded"}
                      optionsText={PRODUCT_TYPES_DROPDOWN}
                      optionKey={"value"}
                      optionValue={"label"}
                      defaultValue={productType}
                      initialValue={productType}
                      errorCode={errors.productType}
                      onChange={value =>
                        this.handleChange("productType", value)
                      }
                      placeholder={"Contratación..."}
                      required={true}
                    />
                  </div>
                </div>
                <div className={"modal-col-50"}>
                  <div className={"modal-create-points-main__field"}>
                    <InputBox
                      onChange={value => this.handleChange("value", value)}
                      value={value}
                      placeholder={"Valor"}
                      errorCode={errors.value}
                      className={"rounded"}
                      required={true}
                    />
                  </div>
                  <div className={"modal-create-points-main__field"}>
                    <InputBox
                      onChange={value => this.handleChange("minPremium", value)}
                      value={minPremium}
                      placeholder={"Prima mínima"}
                      errorCode={errors.minPremium}
                      className={"rounded"}
                      type={'number'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-create-points-buttons">
              <div className="modal-create-points-buttons__item">
                <MainButton
                  text={"Cancelar"}
                  type={"white"}
                  onClick={() => close()}
                />
              </div>
              <div className="modal-create-points-buttons__item">
                <MainButton
                  text={"Guardar"}
                  onClick={() => this.handleSave()}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalCreatePointsContainer>
    );
  }
}

export default ModalCreatePoints;
