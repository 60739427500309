import GeneralServices from "./GeneralServices";
import { Comparation } from "../containers/forms/comparator/health-comparator/comparator.types";
import { CreateComparisonData } from "../containers/forms/comparator/health-comparator/comparator/comparator.types";

class ComparatorServices {
  createComparison = async ({
    setLoading,
    loader,
    data,
    cardName,
  }: {
    loader: boolean;
    setLoading: any;
    data: CreateComparisonData;
    cardName: string;
  }) => {
    try {
      const response = await GeneralServices.post({
        setLoading,
        loader,
        endpoint: `price-comparator/health?cardName=${cardName}`,
        data,
      });
      return {
        ok: true,
        data: response.data.success,
        message: "",
      };
    } catch (error) {
      let statusCode = 500;
      let message = "Se ha producido un error, inténtelo más tarde";
      if (error && error.data) {
        statusCode = error.data.statusCode;
        message = (error.data.message && error.data.message.split(".")[0]) || message;
      }
      return {
        error: true,
        statusCode,
        message,
      };
    }
  };

  downloadComparator = async ({
    setLoading,
    loader,
    data,
    policyType,
  }: {
    setLoading: any;
    loader: boolean;
    data: Comparation;
    policyType: string;
  }) => {
    try {
      const response = await GeneralServices.postFileResponse({
        setLoading,
        loader,
        endpoint: `price-comparator/download-pdf?policyType=${policyType}`,
        data,
      });
      return {
        ok: true,
        data: response.data,
        message: "",
      };
    } catch (error) {
      return {
        error: true,
        message:
          typeof error === "string"
            ? error
            : "Se ha producido un error, inténtelo más tarde.",
      };
    }
  };
}

export default new ComparatorServices();
