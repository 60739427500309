import React from "react";
import { CampaignsConfigDetailPrizeModalContainer } from "./campaigns-detail-prize-modal-style";
import { images } from "../../../../assets";
import H4Text from "../../../../components/components-text/h4-text";
import { ICondition } from "../../campaigns-create/modals/modal-add-condition";
import { optionsUnitsKeyValue, ALL_PRODUCTS_KEY_VALUE } from "../../../../constants/campaignsConfig";

interface CampaignsDetailPrizeModalProps {
    campaign: any;
    prize: any;
    close: () => void;
}

interface CampaignsDetailPrizeModalState {
    runners: ICondition[];
    coordinators: ICondition[];
    buyers: ICondition[];
    exclusiveAgents: ICondition[];
    selectedPublic: string;
}

class CampaignsDetailPrizeModal extends React.Component<CampaignsDetailPrizeModalProps, CampaignsDetailPrizeModalState> {
    containerRef: any;
    constructor(props: CampaignsDetailPrizeModalProps) {
        super(props);
        this.state = {
            runners: [],
            coordinators: [],
            buyers: [],
            exclusiveAgents: [],
            selectedPublic: ''
        }
        this.containerRef = React.createRef();

    }

    componentDidMount() {
        const { prize } = this.props;
        document.addEventListener("click", this.handleClickOutside.bind(this));
        const runners: ICondition[] = [];
        const coordinators: ICondition[] = [];
        const buyers: ICondition[] = [];
        const exclusiveAgents: ICondition[] = [];

        prize.conditions.forEach((cond: any) => {
            const condition = {
                unit: cond.unit,
                productLines: cond.productLines,
                products: cond.products,
                value: parseFloat(cond.value)
            };
            switch (cond.mediatorType) {
                case "runners":
                    runners.push(condition);
                    break;
                case "coordinators":
                    coordinators.push(condition);
                    break;
                case "buyers":
                    buyers.push(condition);
                    break;
                case "exclusiveAgents":
                    exclusiveAgents.push(condition);
                default:
            }
        });

        this.setState({ runners, coordinators, buyers, exclusiveAgents })
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside.bind(this));
    }


    isVisible(e: any) {
        return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
    }

    handleClickOutside(ev: any) {
        const { close } = this.props;
        this.isVisible(ev.target) &&
            this.containerRef.current &&
            !this.containerRef.current.contains(ev.target) &&
            close();
    }

    renderConditionsTable() {
        const { selectedPublic } = this.state;
        const conditions = Object.assign(this.state)[selectedPublic];
        return (
            <div className="conditions-table-container">
                <table>
                    <tr>
                        <th>{'COND.'}</th><th>{'UNIDAD'}</th><th>{'RAMO'}</th><th>{'PRODUCTO'}</th><th>{'VALOR'}</th>
                    </tr>
                    {conditions.map((condition: any, index: number) => (
                        <tr>
                            <td>
                                {`Cond. ${index + 1}`}
                            </td>
                            <td>
                                {optionsUnitsKeyValue[condition.unit]}
                            </td>
                            <td>
                                {condition.productLines.reduce((prev: string, productLine: string) => prev === '' ? ALL_PRODUCTS_KEY_VALUE[productLine] : `${prev}, ${ALL_PRODUCTS_KEY_VALUE[productLine]}`, '')}
                            </td>
                            <td>
                                {condition.products.reduce((prev: string, product: string) => prev === '' ? ALL_PRODUCTS_KEY_VALUE[product] : `${prev}, ${ALL_PRODUCTS_KEY_VALUE[product]}`, '')}
                            </td>
                            <td>
                                {condition.value}
                            </td>
                        </tr>
                    ))

                    }
                </table>
            </div>
        )
    }


    render() {
        const { campaign, prize } = this.props;
        const { runners, coordinators, buyers, exclusiveAgents, selectedPublic } = this.state;
        return (
            <CampaignsConfigDetailPrizeModalContainer>
                <div className="modal-prize-aux-container">
                    <div
                        className="modal-prize-container"
                        ref={this.containerRef}
                    >
                        <div className="modal-prize-close">
                            <img src={images.IconCloseGrey} onClick={() => close()} />
                        </div>
                        {selectedPublic === '' ? (
                            <div className="modal-prize-subtitle">
                                {campaign.name}
                            </div>
                        ) : (
                                <div className="modal-prize-back" onClick={() => this.setState({ selectedPublic: '' })}>
                                    <img src={images.IconArrowLeftLightBlue} />
                                    <div className="modal-prize-back__text">{'condiciones y objetivos'}</div>
                                </div>
                            )}

                        <div className="modal-prize-header">
                            <div className="modal-prize-header-title">
                                <div className="modal-prize-header-title__icon">
                                    <img
                                        src={
                                            selectedPublic !== '' ? images.IconCheckTableBlue
                                                : images.IconPrizeBlue
                                        }
                                    />
                                </div>
                                <div className="modal-prize-header-title__text">
                                    <H4Text>
                                        {prize.name}
                                    </H4Text>
                                    <div className="modal-prize-header-title__subtext">
                                        {`Premio ${prize.order}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedPublic !== '' ? (
                            this.renderConditionsTable()
                        ) : (
                                <>
                                    <div className="modal-prize-wrapper">
                                        <div className="modal-prize-advice">
                                            Objetivos y condiciones por grupos.
                            </div>
                                    </div>
                                    {runners.length > 0 && (
                                        <div className="modal-prize-view-button">
                                            <div className="modal-prize-view-button__text">
                                                {'CORREDORES'}</div>
                                            <div className="modal-prize-view-button__img" onClick={() => this.setState({ selectedPublic: 'runners' })}>
                                                <img src={images.IconEyeBlue} />
                                            </div>
                                        </div>
                                    )

                                    }
                                    {buyers.length > 0 && (
                                        <div className="modal-prize-view-button">
                                            <div className="modal-prize-view-button__text">

                                                {'COMPRADORES'}</div>
                                            <div className="modal-prize-view-button__img" onClick={() => this.setState({ selectedPublic: 'buyers' })}>
                                                <img src={images.IconEyeBlue} />
                                            </div>
                                        </div>
                                    )

                                    }
                                    {exclusiveAgents.length > 0 && (<div className="modal-prize-view-button">
                                        <div className="modal-prize-view-button__text">
                                            {'AGENTES EXCLUSIVOS'}
                                        </div>
                                        <div className="modal-prize-view-button__img" onClick={() => this.setState({ selectedPublic: 'exclusiveAgents' })}>
                                            <img src={images.IconEyeBlue} />
                                        </div>
                                    </div>)

                                    }
                                    {coordinators.length > 0 && (
                                        <div className="modal-prize-view-button">
                                            <div className="modal-prize-view-button__text">
                                                {'COORDINADORES'}
                                            </div>
                                            <div className="modal-prize-view-button__img" onClick={() => this.setState({ selectedPublic: 'coordinators' })}>
                                                <img src={images.IconEyeBlue} />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )

                        }


                    </div>
                </div>
            </CampaignsConfigDetailPrizeModalContainer>
        )
    }
}

export default CampaignsDetailPrizeModal