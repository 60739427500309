import React from "react";
import { CoveragesContext } from "../constants";

export const withCoverages = Component => {
  return props => (
    <CoveragesContext.Consumer>
      {value => <Component {...props} coverages={value.coverages} />}
    </CoveragesContext.Consumer>
  );
};
