import styled from "styled-components";
import { colors } from "../../assets/colors";
import { BodyTextContainer } from "../components-text/body-text/body-text-styles";

export const SelectBoxContainer = styled.div`
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
  }

  .hovered {
    background: #f0fafd;
  }

  &.rounded {
    .input-box-main-field {
      border: 1px solid ${colors["PR-001-100"]};
      border-radius: 3px;
    }
  }
  &.show-list {
    z-index: 12;
    .input-box-main-field {
      background: #ffffff;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
      outline: none;
      border-bottom: none;
    }
    .input-box-main-list {
      display: block;
    }
    
    .input-box-main-list-health {
      display: block;
    }
  }

  &.required {
    .input-box-main-field {
      &:before {
        content: "";
        z-index: 9;
        position: absolute;
        top: 23px;
        left: 4px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: transparent;
      }
    }
  }

  &.error {
    .input-box-main-field {
      color: ${colors["ER-001-100"]};
    }
  }

  &.disabled {
    pointer-events: none;

    .input-box-topbar-label {
      color: ${colors["TX-001-30"]};
    }

    .input-box-main-field {
      background: ${colors["FL-001-Disabled"]};
      border-bottom: 1px solid ${colors["TX-001-30"]};
      color: ${colors["TX-001-30"]};
    }
  }

  .selector-separator {
    background: ${colors["TX-001-10"]};
    height: 1px;
    margin: 0px 8px;
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
  }

  .input-box-topbar-label {
    color: ${colors["PR-001-50"]};
    text-transform: uppercase;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-field {
    position: relative;
    width: 100%;
    padding: 13px 18px 9px;
    padding-right: 30px;
    transition: all 0.2s ease-in-out;
    /* margin-bottom: 4px; */
    color: ${colors["PR-001-100"]};
    border-bottom: 1px solid ${colors["PR-001-100"]};
    cursor: pointer;
    height: 48px;

    p {
      cursor: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      //overflow: hidden;
    }

    &:hover {
      background: #f0fafd;
    }

    &:focus {
      background: #ffffff;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
      outline: none;
      border-bottom: none;
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 20px;
    z-index: 10;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    width: 260px;
    right: 0;
  }

  .input-box-checkbox {
    margin-top: 1px;
  }

  .input-box-main-list {
    position: absolute;
    top: calc(100% + 10px);
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 12;
    background: #ffffff;
    width: 100%;
    display: none;
    color: ${colors["PR-001-100"]};
    max-height: 250px;
    overflow-y: scroll;

    li:first-child {
      height: 45px;
    }

    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;
      display: flex;
      text-transform: capitalize;
      &:hover {
        background: #f0fafd;
      }
    }
  }

  .input-box-main-list-health {
    position: inherit;
    top: calc(100% + 10px);
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 12;
    background: #ffffff;
    width: 100%;
    display: none;
    color: ${colors["PR-001-100"]};
    max-height: 250px;
    overflow-y: scroll;

    li:first-child {
      height: 45px;
    }

    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;
      display: flex;
      &:hover {
        background: #f0fafd;
      }
    }
  }

  .input-box-error {
    color: ${colors["ER-001-100"]};
  }

  &.ghost {
    &.complete {
      .input-box-main-field {
        color: #004f8b;
        box-shadow: 0 2px 11px rgba(0, 0, 0, 0.05);
      }
    }

    &.show-list {
      .input-box-main {
        z-index: 19;
      }
      .input-box-main-field {
        box-shadow: -2px -9px 11px rgba(0, 0, 0, 0.035);
        &:hover {
          box-shadow: -2px -9px 11px rgba(0, 0, 0, 0.035);
          background: white;
        }
      }
    }

    .input-box-main-field {
      margin-bottom: 0;
      padding-left: 12px;
      border: none;
      font-size: 14px;
      font-weight: 500;
      color: #848fa1;

      &:hover {
        background: white;
        box-shadow: 0 2px 11px rgba(0, 0, 0, 0.05);
      }

      ${BodyTextContainer} {
        text-transform: uppercase;
      }

      &:before {
        display: none;
      }
    }

    .input-box-main-list {
      top: 100%;
    }
    
    .input-box-main-list-health {
      top: 100%;
    }
  }
`;
