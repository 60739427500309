import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './utilities.css';
import './mediaqueries.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'core-js';
import "react-app-polyfill/ie11";

export const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
