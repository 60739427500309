import { ENG_AREA_NAMES } from "../constants/policyType";
import { PricesResponseDto } from "./dto/response/SimulationServiceResponse.dto";
import GeneralServices from "./GeneralServices";
import GestDocServices from "./Gest-Doc.Services";

interface BuildSimulationDocumentResponseDto {
  fileType: string;
  fileName: string;
  fileData: Buffer;
  status: Number;
}
class SimulationServices {

  vida = async (args: any) => {
    try {
      const response = await GeneralServices.post({
        setLoading: args.setLoading,
        isSlowProcess: true,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: "simulations/life",
        data: args.data,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  decesos = async (args: any) => {
    try {
      let payload;
      if (args.data.insureds.length > 0) {
        const { payType, ...insuredsData } = args.data.insureds[0];
        args.data.insureds[0] = insuredsData;
        // payload = {
        //   insureds: insuredsData,
        //   payment: paytype,
        // };
        payload = { ...args.data, payment: { ...args.data.payment, payType } };
      }
      const response = await GeneralServices.post({
        setLoading: args.setLoading,
        isSlowProcess: true,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: "simulations/death",
        data: payload || args.data,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  travel = async (args: any) => {
    try {
      const response = await GeneralServices.post({
        setLoading: args.setLoading,
        isSlowProcess: true,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: `simulations/travel`,
        data: args.data,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  mascotas = async (args: any) => {
    try {
      const response = await GeneralServices.post({
        setLoading: args.setLoading,
        isSlowProcess: true,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: "simulations/pet",
        data: args.data,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  health = async (args: any, productType: string) => {
    try {
      const response = await GeneralServices.post({
        setLoading: args.setLoading,
        isSlowProcess: true,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: `simulations/health?productType=${productType}`,
        data: args.data,
      });
      return response.data;
    } catch (err) {
      const baseError = "Error al simular la póliza";
      const genericError = 'Se ha producido un error al simular la póliza. Vuelva a intentarlo más tarde.';
      throw this.returnError(err, baseError, genericError);
    }
  };

  dental = async (args: any, productType: string) => {
    try {
      const response = await GeneralServices.post({
        setLoading: args.setLoading,
        isSlowProcess: true,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: `simulations/dental?productType=${productType}`,
        data: args.data,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  returnError(e: any, baseError: string, genericError: string) {
    return e && e.data && e.data.message ? `${baseError}: ${e.data.message}` : genericError;
  }

  simulation = async (args: any) => {
    try {
      const response = await GeneralServices.post({
        setLoading: args.setLoading,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: "simulations",
        data: args.data,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  getSimulations = async (loader: boolean = true, setLoading: Function, params?: string[]) => {
    try {
      const response = await GeneralServices.get({
        loader: loader === false ? false : true,
        endpoint: `simulations${params ? "?" + params.join("&") : ""
          }`,
        setLoading: setLoading,
      });
      return response.data;
    } catch (err) {
      return { items: [], count: 0 };
    }
  };

  getSimulation = async (args: any) => {
    try {
      const loggedMediator = JSON.parse(localStorage.getItem("loggedMediator") || '');

      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `simulations${args.mediator ? '/' + args.mediator : ''}/${args.id}/${loggedMediator.mediatorCode}`,
      });
      return response.data;
    } catch (err) {
      return {};
    }
  };

  getSimulationDocument = async (loader: boolean = true, setLoading: Function, args: any): Promise<BuildSimulationDocumentResponseDto> => {
    try {

      const response = await GeneralServices.postFileResponse({
        setLoading: setLoading,
        loader: loader,
        data: args,
        endpoint: `simulations/buildSimulationPdf`,
      });
      return {
        fileData: response.data,
        fileName: GestDocServices.getFileName(response.headers["content-disposition"]),
        fileType: response.headers["content-type"],
        status: response.status,
      }
    } catch (err) {
      throw err;
    }
  }

  getDocument = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `senda/document?product=${args.businessLine}&document=OFERCOTI&id=${args.requestNumber}`,
      });
      return response.data;
    } catch (err) {
      return {};
    }
  };

  getHealthSimulationDocument = async (args: any) => {
    try {
      return await GeneralServices.post({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        data: args.data,
        endpoint: `sendaHealth/buildSimulationPdf`,

      });
    } catch (err) {
      throw err
    }
  }

  getDentalSimulationDocument = async (args: any) => {
    try {
      return await GeneralServices.post({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        data: args.data,
        endpoint: `sendaDental/buildSimulationPdf`,

      });
    } catch (err) {
      throw err
    }
  }

  getPrices = async (args: any, productArea: string, productType: string): Promise<PricesResponseDto> => {
    try {
      let payload;
      args.data.productType = productType

      if (productType === 'death') {

        if (args.data.insureds.length > 0) {
          const { payType, ...insuredsData } = args.data.insureds[0];
          args.data.insureds[0] = insuredsData;
          payload = { ...args.data, payment: { ...args.data.payment } };
        }

      }
      const response = await GeneralServices.post({
        setLoading: args.setLoading,
        loader: (args.loader && args.loader) === false ? false : true,
        endpoint: `simulations/getPrices${ENG_AREA_NAMES[productArea]}`,
        data: payload || args.data,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

}

export default new SimulationServices();
