import styled from "styled-components";
import { colors } from "../../assets";

export const CardContainer = styled.div`
  color: ${colors["primaryColor"]};
  background-color: #dae3f3;
  font-size: 20px;
  line-height: 22px;
  padding: 1rem;
  display: flex;
  align-items: center;

  ${({ origin }) =>
    origin === "panel" &&
    `
    margin-bottom: 5px;
    border: 2px solid ${colors["PR-001-100"]};
    padding: 1rem;

    .icon {
      margin-right: 2rem;
    }
  `}

  .icon {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }

  .content {
    a.link {
      color: ${colors["PR-001-100"]};
      text-decoration: underline;
      font-weight: 700;
    }
  }
`;
