import GeneralServices from "./GeneralServices";
import { BaseServiceRequestDto } from "./dto/request/BaseServiceRequest.dto";

export interface ILineaNegocioResponse {
  createdAt: Date;
  id: number;
  updatedAt: Date;
  linea_negocio: string;
  nameItem: string;
  image: null;
  family: null | string;
}

class LineaNegocioServices {
  getAll = async ({
    setLoading,
    loader,
  }: BaseServiceRequestDto): Promise<any> => {
    try {
      const response = await GeneralServices.get({
        setLoading,
        loader,
        endpoint: "lineaNegocio/getAll",
      });
      return {
        ok: true,
        data: response.data
      };
    } catch (error) {
      return {
        error: true,
        message: typeof error === "string" ? error : "Se ha producido un error, inténtelo más tarde.",
      }
    }
  };
}

export default new LineaNegocioServices();
