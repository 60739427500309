export interface ICoveragesQuoteAmount {
    PRIMANETA: string,
    PRIMATOTAL: string,
    TOTAL_IMPUESTOS: string,
    PERIODO_COBRO?: string,
  }
  
  export interface ICoveragesAmount extends ICoveragesQuoteAmount{
    CAPITAL: string,
    CODE: string,
    DESCRIPTION: string,
    NUMERO_DE_RIESGO?: number,
  }

  export interface ISimulationResult {
      COTIZACION: string,
      LINEA_NEGOCIO: string,
      COMBINACION_COMERCIAL_POLIZA: string,
      COLECTIVO: string,
      MEDIADOR: string,
      FECHA_EFECTO: string,
      ESTADO: string,
  }

  export interface IAmountPay {
    PERIODO_COBRO: string,
    PRIMANETA: string,
    PRIMATOTAL: string,
    PRIMAPURA: string,
    GASTOSINT: string,
    GASTOSEXT: string,
    GASTOSEMI: string,
    DEDUCCIONES: string,
    CONSORCIO: string,
    CAMPANYAS: string,
    DESCUENTOS: string,
    DGS: string,
    IPS: string,
    RECARGOFRA: string,
    INEM: string,
    ASF: string,
    TOTAL_IMPUESTOS: string,
  }

  export interface IInformativeCoverageConcepts {
    GARANTIA: string,
    PRIMAPURA: string,
    SOBREPRIMA: string,
    EXTRAPRIMA: string,
    GASTOSINT: string,
    GASTOSEXT: string,
    GASTOSEMI: string,
    DEDUCCIONES: string,
    RECARGOCONS: string,
    RECARGOBEN: string,
    DESCUENTOFP: string,
    CAMPANYAS: string,
    DESCUENTOS: string,
  }

  export interface ITotalPremiumConcepts {
    CONSORCIO: string,
    DGS: string,
    GARANTIA: string,
    IPS: string,
    PRIMANETA: string,
    RECARGOFRA: string,
  }

  export interface IAmountQuoteCoverage extends IInformativeCoverageConcepts, ITotalPremiumConcepts {
    PERIODO_COBRO: string,
    PRIMATOTAL: string,
    TOTAL_IMPUESTOS: string,
  }

  export interface ITableRows {
    text: string,
    order?: number,
    type: string,
    link: string,
    key: string,
  }

  interface IListOrder {
    [key: string]: number;
  }

  export const COVERAGES_INSURED_ORDER: IListOrder = {
    GS01: 1,
    GS29: 2,
    GS28: 3,
    GS30: 4,
    GS09: 5,
    GS99: 6,
  }

  interface IConceptsDescription {
    [key: string]: string;
  }

  export const DETAIL_COVERAGE_CONCEPT_DESCRIPTION: IConceptsDescription = {
    CAMPANYAS: 'Campañas',
    CONSORCIO: 'Consorcio',
    DEDUCCIONES: 'Deducción',
    DESCUENTOFP: 'Descuento por forma de pago',
    DESCUENTOS: 'Descuentos',
    DGS: 'DGS',
    EXTRAPRIMA: 'Extraprima',
    GARANTIA: 'Garantía',
    GASTOSEMI: 'Gastos de emisión',
    GASTOSEXT: 'Gastos de gestión externa',
    GASTOSINT: 'Gastos de gestión interna',
    IPS: 'IPS',
    PRIMANETA: 'Prima neta',
    PRIMAPURA: 'Prima pura',
    RECARGOBEN: 'Recargo beneficios',
    RECARGOCONS: 'Recargo consorcio',
    RECARGOFRA: 'Recargo fraccionamiento',
    SOBREPRIMA: 'Sobreprima',
  }

  