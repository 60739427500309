/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withGeneral } from "../../context/mixin/with-general";
import { IMediator } from "../../models/mediator-model";
import { SupplementsContainer } from "./supplements-style";
import SelectBox from "../../components/select-box/select-box";
import { getRelatedMediatorsCodes } from "../../utils/mediatorCode";
import SupplementsServices from "../../services/SupplementsServices";
import { getTitles } from "./utils";
import { getPolicyData } from "../../services/utils";

interface SupplementsProps extends RouteComponentProps {
    setLoading: Function;
    mediator: IMediator;
    user: any;
}

interface SupplementsData {
    mediatorCodesInput: {
        codeOptions: { [key: string]: string }[];
        disabled: boolean;
        showError: boolean;
        errorText: string;
        selectedCode: string;

    };
    supplements: any

}

class SupplementsLayout extends React.Component<SupplementsProps, SupplementsData> {

    constructor(props: SupplementsProps) {
        super(props);

        this.state = {
            mediatorCodesInput: {
                codeOptions: getRelatedMediatorsCodes(this.props.mediator),
                disabled: props.location.state && props.location.state.searchType ? false : true,
                showError: false,
                errorText: '',
                selectedCode: props.location.state && props.location.state.searchCode ? props.location.state.searchCode : props.mediator.mediatorCode,
            },

            supplements: []

        };
    };
    componentDidMount = async () => {
        try {
            const supplements = await SupplementsServices.getSupplements({
                loader: true,
                setLoading: this.props.setLoading
            });
            this.setState({ supplements })
        } catch (e) {
            console.log("Exception", e)
        }

    }
    handleSelectedCodesChange = (selectedCode: string) => {
        const { mediatorCodesInput } = this.state;
        mediatorCodesInput.selectedCode = selectedCode;
        this.setState({ mediatorCodesInput });
    };

    handleOnClickSupplement = (event: any) => {
        this.props.history.push({
            pathname: '/suplemento' + event.currentTarget.id,
            state: { mediator: this.state.mediatorCodesInput.selectedCode }
            //search: '?mediador='+this.state.mediatorCodesInput.selectedCode,
        })
    };
    checkSupplement = (supplement: any) => {
        const { user } = this.props;
        let allowedRoles = supplement.allowedRoles.split("-");
        return allowedRoles.some((x: any) => user.roles.includes(x))
    }
    _renderCol(data: any) {
        const titles = getTitles();
        return (
            <ul className="container-menu">
                {data.map((supplement: any, index: any) => {
                    if (supplement.title === titles.newIncorporation && (localStorage.getItem('policyOrigin') === 'GIP' || localStorage.getItem('policyRamo') !== 'ASISA SALUD') ) return null;
                    if (this.checkSupplement(supplement)) {
                        return supplement.child ? (
                          <div key={index} className="width-45">
                            {supplement.title === titles.basicData ? (
                              <div key={index} className="buttonText">
                                <li className="list-title">
                                  <a
                                    className="link"
                                    id={supplement.link}
                                    onClick={this.handleOnClickSupplement}
                                  >
                                    <strong>{supplement.title}</strong>{" "}
                                    {supplement.subTitle}
                                  </a>
                                </li>
                              </div>
                            ) : (
                              <div className="buttonText">
                                <li className="list-title">
                                  <a id={supplement.link}>
                                    <strong>{supplement.title}</strong>{" "}
                                    {supplement.subTitle}
                                  </a>
                                </li>
                              </div>
                            )}

                            {supplement.child && (
                              <div className="list">
                                <p className="list-text">
                                  Dentro de esta categoría, se podrá modificar:
                                </p>
                                <ul className="list-child">
                                  {supplement.child.map(
                                    (child: any, subIndex: any) => {
                                      return supplement.title === titles.basicData ? (
                                        <li  key={subIndex}> <a className="no-link" id={child.link}> {child.title} </a> </li>
                                      ) : (
                                        <li key={subIndex} className="link"> 
                                            <a id={child.link} onClick={ this.handleOnClickSupplement }> {child.title} </a>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div key={index} className="buttonText width-45">
                            <li className="list-title">
                              <a className="link" id={supplement.link} onClick={this.handleOnClickSupplement} >
                                <strong>{supplement.title}</strong>{" "}
                                {supplement.subTitle}
                              </a>
                            </li>
                          </div>
                        );
                    }
                    return null
                })}
            </ul>)
    }

    render() {
        const { mediatorCodesInput, supplements } = this.state;
        // Una vez esten todos los formularios, estos serian los SLICE
        //const dataFirst = data.slice(0, 3);
        //const dataSecond = data.slice(3);
        // Mientras
        // const dataFirst = supplements.slice(0, 2);
        // const dataSecond = supplements.slice(2);

        return (
            <>
                <SupplementsContainer>
                    <h1>MODIFICACIONES / SUPLEMENTOS</h1>
                    <div className="supplements-filter-container">
                        <div className="col-6">
                            <div className="selectBox relatedMediators">
                                <SelectBox
                                    // mediatorSelector={true}
                                    isMediator={true}
                                    optionsText={getRelatedMediatorsCodes(this.props.mediator)}
                                    optionKey={"value"}
                                    optionValue={"label"}
                                    defaultValue={getPolicyData().mediator}
                                    onChange={(value: string) => this.handleSelectedCodesChange(value)}
                                    propValue={mediatorCodesInput.selectedCode}
                                    disabled
                                />
                                <label>Mediador</label>
                            </div>
                        </div>
                    </div>
                    <div className="supplements-container">
                        <div className="supplements-row">
                            {this._renderCol(supplements)}
                            {/* <div className="col-6">

                                {this._renderCol(dataFirst)}

                            </div>
                            <div className="col-6">
                                {this._renderCol(dataSecond)}
                            </div> */}
                        </div>
                    </div>
                </SupplementsContainer>
            </>
        );
    }
}
export default withRouter(withGeneral(SupplementsLayout));