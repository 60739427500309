import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { LegalPolicyContainer } from "../legal-policy/legal-policy-style";
import { withGeneral } from "../../context/mixin/with-general";
import Cookies from 'js-cookie';
import CookiesModal from "../../components/cookies-modal/cookies-modal";
import GoBack from "../../components/go-back";

interface CookiesPolicyProps { }

interface CookiesPolicyProps extends RouteComponentProps {
  isLogged: boolean;
  history: any;
}

class CookiesPolicyLayout extends React.Component<CookiesPolicyProps, {}> {
  state = { activeModal: false };
  
  showOptanonSettingsDisplay = () => {
    var btn = document.getElementById("openCookieSettings");
    btn && btn.click();
  }

  render() {
    const { isLogged, history } = this.props;
    const { activeModal } = this.state;
    return (
      <LegalPolicyContainer>
        { !isLogged && 
        <div className="back-arrow-container">
          <GoBack text="Volver" onClick={() => history.push('/')} />
        </div> }
        <div className="legal-title-selection">
          {/* {!isLogged && (<div className="button-back" onClick={() => history.push('/')}> {"Volver"} </div>)} */}
          <p>Política de cookies</p>
          <button className="btn-azul right" onClick={() => this.showOptanonSettingsDisplay()}>Configuración de cookies</button>
        </div>

        <div className="legal-subtitle">
          <p>1 ¿Qué son las cookies?</p>
        </div>

        <div className="legal-text">
          <p>Las cookies son pequeños ficheros de datos que se generan en el ordenador del usuario y que nos permiten conocer su frecuencia de visitas, los contenidos más seleccionados y los elementos de seguridad que pueden intervenir en el control de acceso a áreas restringidas, así como la visualización de publicidad en función de criterios predefinidos por Asisa y que se activan por cookies servidas por dicha entidad o por terceros que prestan estos servicios por cuenta de Asisa.</p>
          <p>Por regla general, existen los siguientes tipos de cookies.</p>
          <p>Según quien sea la entidad que gestione el dominio desde donde se envían las cookies y trate los datos que se obtengan se pueden distinguir dos tipos:</p>
          <ul>
            <li><strong>Cookies propias</strong>: aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.</li>
            <li><strong>Cookies de terceros</strong>: aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.</li>
          </ul>
          <p>En el caso de que las cookies sean instaladas desde un equipo o dominio gestionado por el propio editor, pero la información que se recoja mediante éstas sea gestionada por un tercero, no pueden ser consideradas como cookies propias.</p>
          <p>Existe también una segunda clasificación según el plazo de tiempo que permanecen almacenadas en el navegador del cliente, pudiendo tratarse de:</p>
          <ul>
            <li><strong>Cookies de sesión</strong>: diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión (p.e. una lista de productos adquiridos).</li>
            <li><strong>Cookies de persistentes</strong>: los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.</li>
          </ul>
          <p>Por último, existe otra clasificación con seis tipos de cookies según la finalidad para la que se traten los datos obtenidos:</p>
          <ul>
            <li><strong>Cookies técnicas</strong>: Las cookies técnicas o necesarias son esenciales para que nuestra web pueda funcionar correctamente e incluye funcionalidades básicas como identificar la sesión o dar acceso a los usuarios registrados a zonas de acceso restringido. Por estos motivos las cookies técnicas no se pueden desactivar.</li>
            <li><strong>Cookies de Preferencia</strong>: Al utilizar cookies de preferencias, la página web puede recordar información durante tu tiempo de navegación asociada a la forma en que la página se comporta o el aspecto que tiene, como tu idioma preferido o la región en la que te encuentras.</li>
            <li><strong>Cookies Estadísticas</strong>: Utilizamos cookies de tipo estadístico para ver como interactúas con la página web, reuniendo información anónima durante el tiempo que navegas por ella. La finalidad de recabar esta información es la de introducir mejoras en la web en función del análisis de los datos agregados.</li>
            <li><strong>Cookies Publicitarias</strong>: Nos permiten la gestión de los espacios publicitarios incluidos en nuestro sitio web, aplicación o plataforma en base a criterios como el contenido mostrado o la frecuencia en la que se muestran los anuncios.</li>
            <li><strong>Cookies de Marketing</strong>: Las cookies de marketing se utilizan para analizar tu comportamiento mientras visitas la página web y para que, de forma puntual, otros proveedores puedan ofrecerte publicidad personalizada y relevante atendiendo a tu perfil de navegación.</li>
            <li><strong>Cookies de Redes sociales externas</strong>: se utilizan para que los visitantes puedan interactuar con el contenido de diferentes plataformas sociales (Facebook, YouTube, Twitter, LinkedIn, etc..) y que se generen únicamente para los usuarios de dichas redes sociales. Las condiciones de utilización de estas cookies y la información recopilada se regulan por la política de privacidad de la plataforma social correspondiente.</li>
          </ul>
        </div>

        <div className="legal-subtitle">
          <p>2 Cookies que nosotros utilizamos</p>
        </div>

        <div className="legal-text">
        <p>Desde la web de <a href="https://webmediadores.asisa.es/">ASISA - Mediadores España</a>, utilizamos cookies que tienen diferentes funciones:</p>
          <p><strong>Cookies Técnicas</strong>: Las cookies técnicas o necesarias son esenciales para que nuestra web pueda funcionar correctamente e incluye funcionalidades básicas como identificar la sesión o dar acceso a los usuarios registrados a zonas de acceso restringido. Por estos motivos las cookies técnicas no se pueden desactivar.</p>
          <p><strong>Cookies de Estadísticas</strong>: Utilizamos cookies de tipo estadístico para ver como interactúas con la página web, reuniendo información anónima durante el tiempo que navegas por ella. La finalidad de recabar esta información es la de introducir mejoras en la web en función del análisis de los datos agregados.</p>
          <p>Utilizamos cookies propias y de terceros. En la tabla siguiente podrá encontrar todas las cookies utilizadas:</p>

          <table className="tablaGenerica" style={{maxWidth: "100%"}} title="Cookies">
            <thead>
              <tr>
                <th scope="col" style={{width:"10%"}}>Categoria/Tipo</th>
                <th scope="col" style={{width:"10%"}}>Nombre</th>
                <th scope="col" style={{width:"55%"}}>Finalidad</th>
                <th scope="col" style={{width:"15%"}}>Caducidad</th>
                <th scope="col" style={{width:"10%"}}>Titular</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Técnicas</td>
                <td>incap_ses_</td>
                <td>Seguridad: Conserva los estados de usuario en todas las solicitudes de página</td>
                <td>Sesión</td>
                <td>asisa.es</td>
              </tr>
              <tr>
                <td>Técnicas</td>
                <td>visid_incap_</td>
                <td>Incapsula DDoS Protectiona y firewall de aplicaciones web: cookie para vincular determinadas sesiones a un visitante específico 
                  (visitante que representa una computadora específica). Con el fin de identificar clientes que ya hayan visitado Incapsula</td>
                <td>1 año</td>
                <td>asisa.es</td>
              </tr>
              <tr>
                <td>Técnicas</td>
                <td>OptanonConsent</td>
                <td>Esta cookie indica el estado de consentimiento de un usuario o visitante</td>
                <td>1 año</td>
                <td>asisa.es</td>
              </tr>
              <tr>
                <td>Técnicas</td>
                <td>OptanonAlertBoxClosed</td>
                <td>Esta cookie se utiliza para determinar si a un visitante se le debe mostrar el banner, cuando da el consentimiento 
                  o la interacción necesaria del usuario por la configuración</td>
                <td>1 año</td>
                <td>asisa.es</td>
              </tr>
              <tr>
                <td>Estadísticas</td>
                <td>tracing_cookie</td>
                <td>Estas cookies nos permiten contar las visitas y las fuentes de tráfico, para que podamos medir y mejorar el rendimiento 
                  de nuestro sitio. Nos ayudan a saber qué páginas son más y menos populares y a ver cómo se mueven los visitantes por el sitio. 
                  Toda la información que recopilan estas cookies es agregada y, por lo tanto, anónima</td>
                <td>7 días</td>
                <td>webmediadores.asisa.es</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="legal-subtitle">
          <p>3 Cómo cambiar las cookies (Desactivación y eliminación)</p>
        </div>
        <div className="legal-text">
          <ul>
            <li><strong>Como administrar cookies desde nuestro Banner:</strong><br />
            Dentro de la página web de ASISA, de forma automática, se abrirá una ventana donde podrá decidir si quiere aceptar la instalación de cookies lo que permite que se instalen y sean utilizadas tal y como se dispone en nuestra política de cookies o bien configurar las mismas según quiera.<br />
            La opción de configurar las cookies siempre estará presente en la parte inferior de la página en el caso de que cambie de opinión.</li>
            <li><strong>Como administrar cookies desde su navegador:</strong><br />
            La mayoría de los navegadores le permiten ver, administrar, eliminar y bloquear las cookies de un sitio web. Tenga en cuenta que si borra todas las cookies se perderán todas las preferencias de usuario que haya establecido, incluida la posibilidad de excluir cookies, ya que esta función requiere, precisamente, la colocación de una cookie de exclusión en su dispositivo.</li>
          </ul>

          <p>A continuación, puede ver cómo gestionar las cookies en los principales navegadores:</p>

          <p><strong>Google Chrome</strong></p>

          <p style={{marginLeft: "20px"}}>1. En el ordenador, abre Chrome.<br />
          2. Arriba a la derecha, haz clic en Más. Configuración.<br />
          3. En &quot;Privacidad y seguridad&quot;, haz clic en Configuración de sitios web.<br />
          4. Haz clic en Cookies.<br />
          5. A continuación, podrás: Activar las cookies: activa el interruptor que aparece junto a &quot;Con bloqueo&quot;.<br />
          Para más información, puedes consultar el <a href="https://support.google.com/chrome/answer/95647?hl=es&amp;ref_topic=3434352" title="Soporte de Google">soporte de Google</a> o la Ayuda del navegador.</p>

          <p><strong>Microsoft Internet Explorer</strong></p>

          <p style={{marginLeft: "20px"}}>1. Haz clic en &ldquo;herramientas&rdquo; en la esquina superior de tu buscador Windows y selecciona opciones &ldquo;opciones de internet&rdquo; y vuelve a clicar en la opción de &ldquo;Privacidad&rdquo;.<br />
          2. Asegúrate de que tu nivel de Privacidad está en nivel Medio o menor lo que permitirá el uso de cookies en tu buscador.<br />
          3. Si se selecciona una opción mayor a nivel Medio no se permitirá el uso de las cookies.<br />
          Para más información, puedes consultar el <a href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11" title="Soporte de Microsoft">soporte de Microsoft</a> o la Ayuda del navegador.</p>

          <p><strong>Mozilla Firefox</strong></p>

          <p style={{marginLeft: "20px"}}>1. Haz clic en la parte superior de la ventana de tu buscador. Haz clic en &ldquo;Herramientas&rdquo; y selecciona &ldquo;Opciones&rdquo;<br />
          2. Selecciona el icono de Privacidad &amp; Seguridad.<br />
          3. Haz clic en la opción de permitir cookies en esta web.<br />
          Para más información, puedes consultar el <a href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-" title="Soporte de Mozilla">soporte de Mozilla</a> o la Ayuda del navegador.</p>

          <p><strong>Safari</strong></p>

          <p style={{marginLeft: "20px"}}>1. Haz clic en el icono de herramientas en la parte superior de tu buscador y selecciona la opción de &ldquo;preferencias&rdquo;.<br />
          2. Haz clic en &ldquo;Privacidad y seguridad&rdquo; y selecciona la opción de Bloquear &ldquo;Impedir seguimiento entre sitios&rdquo;<br />
          3. Haz clic en &ldquo;Guardar&rdquo;.<br />
          Para más información, puedes consultar el <a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES&amp;locale=es_ES" title="Soporte de Apple">soporte de Apple</a> o la Ayuda del navegador.</p>

          <p>O puede consultar los <a href="http://www.youtube.com/user/desdelaAEPD" title="Tutoriales Agencia Española de Protección de Datos">vídeo tutoriales</a> publicados por la Agencia Española de Protección de Datos.</p>
        </div>
        <div className="legal-subtitle">
          <p>4 Cambios en la política de cookies</p>
        </div>
        <div className="legal-text">
          <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales. El presente documento será siempre la versión actualizada, adaptada a los nuevos requerimientos.</p>
        </div>
        <div className="legal-subtitle">
          <p>5 A quién van a ser comunicados sus datos</p>
        </div>
        <div className="legal-text">
          <p>Con carácter general, los datos de los usuarios recabados a través de las cookies propias de este sitio web, no serán transferidos a terceras personas.</p>
          <p>Sin embargo, con motivo de la utilización de cookies de terceros, es posible que los datos recabados sean transferidos fuera de la Unión Europea, debido a que estas cookies pueden estar alojadas fuera del Espacio Económico Europeo.</p>
          <p>Las cookies que pueden encontrarse alojadas fuera de la UE son las siguientes:</p>
          <ul>
            <li>
              <strong>_omappvp y _omappvs</strong>: Son de Retyp, LLC d/b/a OptinMonster, cuya Política de Cookies puede consultar en el siguiente <a href="https://optinmonster.com/gdpr/cookies/" title="OpinMonster cookies">link</a>.
            </li>
            <li>
              <strong>_ Marketing /Pixel</strong>: Son de Google Analytics, cuya Política de Cookies puede consultar en el siguiente <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id?hl=es" title="Google Analytics cookies">link</a>.
            </li>
          </ul>
        </div>
        <div className="legal-subtitle">
          <p>6 Elaboración de perfiles</p>
        </div>
        <div className="legal-text">
          <p>Asimismo, le informamos de que el responsable no tiene previsto realizar elaboraciones de perfiles con los datos recabados mediante las cookies empleadas en el sitio web.</p>
          <p>Si en el futuro fuesen a llevarse a cabo, se informaría previamente a los usuarios y se les solicitaría el pertinente consentimiento.</p>
        </div>
        {/* <div className="legal-text">
          <p>
            Si quieres cambiar la configuración pincha{" "}
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => Cookies.get("cookiesAccepted") === "true" && this.setState({ activeModal: true })}>
              aquí
              </a>.
          </p>
        </div> */}
        {Cookies.get("cookiesAccepted") === "true" && (
          <CookiesModal activeModal={activeModal} handleChangeActiveModal={(value: boolean) => this.setState({ activeModal: value })} />
        )}
      </LegalPolicyContainer>
    );
  }
}

export default withRouter(withGeneral(CookiesPolicyLayout));
