/**
    CheckboxModal 
        active: para que salga
        img={images.IconWarningYellow} : para pasarle la imagen que queramos
        className : para meterle una clase custom
        onClickAccept={()=> funcionalidad de clickar el boton de OK
        mainTitle='Acepta para continuar' : texto del titulo
        mainText='Si vuelve a simular perderá todos los datos guardados en la simulación anterior.' : texto del cuerpo
    />
 */ 

import React, {Component} from 'react';
import {CheckboxModalContainer} from './checkbox-modal-style';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { images } from '../../assets';
import H4Text from '../components-text/h4-text';
import MainButton from '../main-button';
import WebText from '../components-text/web-text';
import CheckboxItem from '../checkbox-item';

interface CheckboxModalRouterProps {

}


interface CheckboxModalProps extends RouteComponentProps<CheckboxModalRouterProps> {
    className?: string;
    img?: string;
    onClickAccept: Function; 
    mainTitle: string;
    mainText: string;
    active?: boolean;
    checkConditions: any[];
    enableAcceptButton?: boolean;
}
interface CheckboxModalState {
    active: string;
}


class CheckboxModalLayout extends Component<CheckboxModalProps, CheckboxModalState> {

    readonly state:CheckboxModalState;

    public static defaultProps = {
        active: false,
        img: images.IconSearchBlue,
        onClickAccept: ()=>{},
        className: '',
        mainTitle: '',
        mainText: '',
        checkConditions: [],
        enableAcceptButton: false
    }

    constructor(props: CheckboxModalProps){
        super(props)
        this.state = {active: props.active ? 'active' : ''}
    }


    openCheckboxModal(){
        this.setState({active: 'active'});
    }

    closeCheckboxModal(){
        this.props.history.goBack();
        this.setState({active: ''});
    }
    
    acceptCheckboxModal(){
        this.props.onClickAccept();
        this.setState({active: ''});
    }

    render(){

        const {img, mainTitle, mainText, className, checkConditions, enableAcceptButton} = this.props;
        const {active} = this.state;

        return(
            <CheckboxModalContainer className={`${active} ${className}`}>

                <div className="checkbox-modal-container">

                    <div className="checkbox-modal-close">
                        <img src={images.IconCloseBlue} onClick={()=>this.closeCheckboxModal()}/>
                    </div>
                    
                    <div className="checkbox-modal-main">

                        <div className="checkbox-modal-main-title">
                            <div className="checkbox-modal-icon-bg">
                                <img src={img}/>
                            </div>
                            <div className="checkbox-modal-text">
                                <H4Text>{mainTitle}</H4Text>
                            </div>
                        </div>

                        <div className="checkbox-modal-main-content">
                            <div className="checkbox-modal-main-copy">
                                <WebText>{mainText}</WebText>
                            </div>

                            <div className="checkbox-modal-main-conditions">
                                {Object.keys(checkConditions).map((key: any, i)=> (                             
                                    <div key={i} className="checkbox-modal-main-conditions-item">
                                        <CheckboxItem
                                            checkboxText={checkConditions[key].checkboxText}
                                            errorText={checkConditions[key].errorText}
                                            checked={checkConditions[key].checked}
                                            name={checkConditions[key].name}
                                            onChange={(ev:React.ChangeEvent<HTMLInputElement>) => checkConditions[key].onChange(ev)}
                                            showError={checkConditions[key].showError}
                                        />
                                    </div>
                                ))}

                            </div>
                            <div className="checkbox-modal-button">
                                <MainButton
                                    type={'white'}
                                    text="Volver"
                                    onClick={()=>this.closeCheckboxModal()}
                                />
                                <MainButton
                                    disabled={enableAcceptButton}
                                    text="Aceptar"
                                    onClick={()=> this.acceptCheckboxModal()}
                                />
                            </div>
                        </div>
                    </div>


                    
                </div>
            </CheckboxModalContainer>
        )
    }
}

export default withRouter(CheckboxModalLayout);