import GeneralServices from "./GeneralServices";
import { AllPayType, ProductPayType } from "./PayTypesServices.dto";

class PayTypesServices {

  getAllPayTypes = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `payTypes/allPayTypes`
      });
      return response.data;
    } catch (err) {
      return [];
    }
  };

  getProductPayTypes = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader === false ? false : true,
        endpoint: `payTypes/productPayTypes?product_id=${args.productId}`
      });

      return this.formatPayTypes(response.data)
    } catch (err) {
      return [];
    }
  }
  
  getPayTypeByCode = (payTypes:any, payTypeCode:string) => {
    return payTypes.find((payType:any) => payType.code === payTypeCode)
  }
  
  formatPayTypes = (payTypes: AllPayType[]): ProductPayType[] => {
    return payTypes.map((payType:any) => {
      return {
        value: payType.code,
        label: payType.description,
        order: payType.payTypeOrder,
        code: payType.code,
        description: payType.description,
        payTypeOrder: payType.payTypeOrder,
      }
    })
  }
}
export default new PayTypesServices();
