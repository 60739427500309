import styled, { css } from "styled-components";
import { colors } from "../../assets";

export const MainButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors["PR-001-100"]};
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  height: ${props => props.height ? props.height : '48px'};
  max-height: 48px;

  &:hover {
    background: ${colors["HV-Text"]};
  }

  
  &:active {
    border: 1.5px solid #6CC9F0;
  }
  
  &:focus {
    border: 1.5px solid #6CC9F0;
  }
  
  .main-button-icon {
    display: flex;
    align-items: center;
    img {
      max-width: 18px;
    }
  }
  
  &.orange {
    background: ${colors["orange"]};
    padding: 0px 15px;
    &:hover {
      background: ${colors["orange-dark"]};
    }
  }

  &.ghost {
    background: #fff;
    border: 1px solid ${colors["PR-001-100"]};
    color: ${colors["PR-001-100"]};
  } 
  
  &.google {
    background: #fff;
    border: 1px solid ${colors["PR-001-100"]};
    color: ${colors["PR-001-100"]};
  }
  
  &.white {
    background: ${colors["FL-001-Default"]};
    color: ${colors["PR-001-100"]};
  }
  
  &.light {
    background: ${colors["FL-001-Default"]};
    color: ${colors["PR-001-100"]};
    border: 1px solid transparent;

    &:hover {
      border-color: ${colors["PR-001-100"]};
    }
  }

  &.prev {
    justify-content: flex-start;
  }

  &.full-light {
    background: ${colors["SC-001-100"]};
    color: ${colors["FL-001-Default"]};
  }

  &.green {
    background: #40caa9;
    color: white;
  }

  &.red {
    background: ${colors["ER-001-100"]};

    &:hover {
      background-color: ${colors["errorDarkColor"]};
    }
  }

  ${props =>
    props.disabled &&
    css`
      background: ${colors["FL-001-Disabled"]};
      color: ${colors["FL-001-30-Disabled"]};
      pointer-events: none;
      cursor: default;

      &:hover {
        background: ${colors["FL-001-Disabled"]};
        color: ${colors["FL-001-30-Disabled"]};
      }

      &.ghost,
      &.white,
      &.light,
      &.full-light,
      &.green,
      &.google {
        background: ${colors["FL-001-Disabled"]};
        color: ${colors["FL-001-30-Disabled"]};
        pointer-events: none;
        cursor: default;

        &:hover {
          background: ${colors["FL-001-Disabled"]};
          color: ${colors["FL-001-30-Disabled"]};
        }
      }
    `}
`;
