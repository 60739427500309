import moment from "moment";

import { MEDIATOR } from "../../constants/communications";
import { ILineaNegocioResponse } from "../../services/LineaNegocioServices";
import {
  IGetNotification,
  Tipo,
} from "../../services/CommunicationsServices-dto";
import { IAddNotification } from "../../services/CommunicationsServices-dto";

export interface InitialNotificationType {
  codUser?: string;
  dateEnd: string; // "DD/MM/YYYY"
  dateInit: string; // "DD/MM/YYYY"
  description: string;
  lineaNegocio: string;
  mediator: string;
  priority: string;
  resumen: string;
  status: string;
  type: string;
  id?: string;
  [key: string]: string | undefined;
}

export interface NotificationType extends InitialNotificationType {}

export const getMediatorSelect = (): { label: string; value: string }[] => {
  const mediatorSelect = Object.keys(MEDIATOR).map((mediator: string) => ({
    label: MEDIATOR[mediator].label,
    value: MEDIATOR[mediator].value,
  }));
  return [{ label: "Todos", value: "all" }, ...mediatorSelect];
};

export const getLineaNegocioSelect = (
  data: ILineaNegocioResponse[]
): { label: string; value: string }[] => {
  const lineasNegocioSelect = data.map((linea) => ({
    label: linea.nameItem,
    value: linea.id.toString(),
  }));
  return [{ label: "Todos", value: "all" }, ...lineasNegocioSelect];
};

export const getTypesSelect = (
  data: Tipo[]
): { label: string; value: string }[] => {
  const typesSelect = data.map((type) => ({
    label: type.tipo,
    value: type.id.toString(),
  }));
  return typesSelect;
};

export const getAddNotificationFormat = (notification: NotificationType): IAddNotification => {
  const lineaNegocioId =
    notification.lineaNegocio === "all"
      ? null
      : Number(notification.lineaNegocio);
  const formatedNotification = {
    allowedMediatorType: notification.mediator,
    user: notification.codUser || null,
    contenido: notification.description,
    desde: moment(notification.dateInit, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),
    estadoId: Number(notification.status),
    hasta: moment(notification.dateEnd, 'DD/MM/YYYY').format("YYYY-MM-DD").toString(),
    lineaNegocioId,
    prioridad: Number(notification.priority),
    resumen: notification.resumen,
    tipoId: Number(notification.type),
  };

  return formatedNotification;
};

export const getShowNotificationsFormat = (
  notifications: IGetNotification[]
): NotificationType[] => {
  return notifications.map((notification) => ({
    codUser: notification.user || '',
    dateEnd: moment(notification.hasta, 'YYYY-MM-DD').format('DD/MM/YYYY').toString(),
    dateInit: moment(notification.desde, 'YYYY-MM-DD').format('DD/MM/YYYY').toString(),
    description: notification.contenido,
    lineaNegocio: notification.lineaNegocio
      ? notification.lineaNegocio.id.toString()
      : "all",
    mediator: notification.allowedMediatorType,
    priority: notification.prioridad.toString(),
    resumen: notification.resumen,
    status: notification.estado.id.toString(),
    type: notification.tipo.id.toString(),
    id: notification.id.toString(),
  }));
};

export const sortByPriority = (notifications: any) => {
  return notifications.sort((a: any, b: any) => {
    // El orden de prioridad es 1, 2, 3...... 0
    const aPriority = a.priority === "0" ? 1000 : a.priority;
    const bPriority = b.priority === "0" ? 1000 : b.priority;
    if (Number(aPriority) < Number(bPriority)) return -1;
    if (Number(aPriority) > Number(bPriority)) return 1;
    if (moment(a.dateInit, 'DD/MM/YYYY') < moment(b.dateInit, 'DD/MM/YYYY')) return -1;
    if (moment(a.dateInit, 'DD/MM/YYYY') > moment(b.dateInit, 'DD/MM/YYYY')) return 1;
    return 0;
  });
};

export const sortByType = (notifications: any) => {
  const incidencias = notifications.filter((notification: any) => notification.type == "2" );
  const notificaciones = notifications.filter((notification: any) => notification.type == "1" );

  return [...sortByPriority(incidencias), ...sortByPriority(notificaciones)];
};
