export const STORAGE_KEY = { sKey: 'HEALTH_SIMULATION' }

export const NO_PAPER_OPTIONS_BOX: any[] = [
  { label: "Si", value: "Si" },
  { label: "No", value: "No" }
];

export const CONTACT_HOUR_OPTIONS_BOX: any[] = [
  { label: "Mañana", value: "Mañana" },
  { label: "Tarde", value: "Tarde" },
  { label: "Noche", value: "Noche" }
];

export const COMUNICATION_LANGUAGE_OPTIONS_BOX: any[] = [
  { label: "CASTELLANO", value: "CASTELLANO" },
  { label: "EUSKERA", value: "EUSKERA" },
  { label: "CATALAN", value: "CATALAN" },
  { label: "GALLEGO", value: "GALLEGO" },
  { label: "VALENCIANO", value: "VALENCIANO" },
  { label: "INGLÉS", value: "INGLÉS" },
];

export const COMUNICATION_CHANNEL_OPTIONS = {
  TELEFONICO: "Telefónico",
  EMAIL: "Email",
  SMS: "SMS",
  CARTA_POSTAL: "Carta postal"
}

export interface ValueFormHealth {
  value: string;
  errorCode: string;
  disabled: boolean;
  min?: number;
  max?: number;
  code?: string;
}

export interface ValueFormHealthAddress {
  value: string;
  label: string;
  code: string;
  errorCode: string;
  disabled: boolean;
}

interface InterfaceAddictionModal {
  "0": string;
  "1": string;
  [key: string]: string;
}

export interface BeneficiarieDto {
  docNumber: string;
  name: string;
  lastName1: string;
  lastName2?: string;
  percentage: number;
  insuredIndex: number;
}

export interface IBeneficiarieRequest {
  idcotiza: string;
  beneficiaries: BeneficiarieDto[];
}
export interface IDataProtectionMethod {
  COTIZACION: string,
  PROTECCION_DATOS: {
    TIPO_CONSENTIMIENTO: string,
    FECHA_CONSENTIMIENTO: string,
    RECEPCION_COMUNICACION: string,
    CANCELACION_DATOS: string,
    OBSERVACIONES: string,
    CESION_DATOS: string,
    PUBLICIDAD_ESCRITA_PROPIOS: string,
    PUBLICIDAD_ESCRITA_TERCEROS: string,
    PUBLICIDAD_TELEFONICA_PROPIOS: string,
    PUBLICIDAD_TELEFONICA_TERCEROS: string,
    RED_COMERCIAL: string,
    ROBINSON: string
  }
}

export interface IProtectionData {
  dataTransfer: string,
  thirdParty: string,
  thirdPartyCalls: string,
  consentDate: string,
}

export interface ICampanyas {
  CAMPANYA: string;
}
export interface IPricesMethod {
  COTIZACION: string;
  CAMPANYAS: ICampanyas[]
}

export const SELECTBOXADDICTIONINDEX: InterfaceAddictionModal = {
  "0": "name",
  "1": "amount",
};

export const HEALTH_BENEFICIARIES_TYPES = {
  LEGAL_HEIRS: 'legalHeirs',
  DESIGNATED_BENEFICIARIES: 'designatedBeneficiaries'
}

export interface IHealthCoverage {
  capital?: string;
  capitales?: any;
  capitalValue?: string;
  capMaxCober: number;
  capMinCober: number;
  checked: boolean;
  code: string;
  description: string;
  edadMax: any;
  edadMin: any;
  errorCode?: string;
  required: boolean;
}

export interface IProfession {
  errorCode: string,
  labelText: string,
  value: string,
}