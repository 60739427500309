import { IBeneficiarieRequest, IDataProtectionMethod } from "../constants/health";
import { SendaBasePriceResponse } from "../containers/hirings/new-hiring/health/health-price/health-price-dto/health-prices-dto";
import { HealthQuestionnairesData, SendaResponseQuestionnaire, uploadQuestionnairesResponseDto } from "../containers/hirings/new-hiring/health/health-questionnaire/health-questionnaire-dto/health-questionnaire-dto";
import GeneralServices from "./GeneralServices";
import GestDocServices from "./Gest-Doc.Services";
import { BuildPrecontractualDocumentPDFRequest, BuildPrecontractualDocumentRequest, BuildPrecontractualDocumentResponseDto, ConstantsResponseDto, CoveragesResponseDto, getRiskRequest, HiringHealthDto, IPaymentMethod, RequiredDocumentsDto, SetShortagesRequest } from "./HealthService-dto";
import { ProtectionDataSendaResponse, SendaBaseResponse, SendaHiringResponse, SendaPaymentResponse } from "./HealthService.SendaBaseResponse";

class HealthServices {

    getPrices = async (args: any): Promise<SendaBasePriceResponse> => {
        try {
            const response = await GeneralServices.post({
                setLoading: args.setLoading,
                isSlowProcess: true,
                loader: args.loader === false ? false : true,
                endpoint: `sendaHealth/prices`,
                data: args.data
            });
            return response.data;
        } catch (err) {
            const baseError = "Error obteniendo precios";
            const genericError = 'Se ha producido un error al obtener los precios. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    }

    getCoverages = async (setLoading: Function, loader: boolean, productType: string): Promise<CoveragesResponseDto> => {
        try {
            const response = await GeneralServices.get({
                setLoading: setLoading,
                loader: loader,
                endpoint: `sendaHealth/healthCoverages?productType=${productType}`
            });
            return response.data;
        } catch (err) {
            const baseError = "Error obteniendo coberturas";
            const genericError = 'Se ha producido un error al obtener las coberturas. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    };

    getCoveragesFromDB = async (setLoading: Function, loader: boolean, productType: string): Promise<any> => {
        try {
            const response = await GeneralServices.get({
                setLoading: setLoading,
                loader: loader,
                endpoint: `coverages?product=${productType}`
            });
            return response.data
        } catch (err) {
            throw err;
            // const genericError = 'Se ha producido un error al obtener las coberturas. Vuelva a intentarlo más tarde.';
            // throw this.returnError(err, baseError, genericError);
        }
    };

    getQuestionnaries = async (setLoading: Function, loader: boolean): Promise<HealthQuestionnairesData> => {
        try {
            const response = await GeneralServices.get({
                setLoading: setLoading,
                loader: loader === false ? false : true,
                endpoint: `sendaHealth/healthQuestionnaires`
            });
            return response.data;
        } catch (err) {
            return {
                healthQuestList: undefined,
                modalLiteral: undefined,
            }
        }
    }

    getDocumentTypes = async (args: any): Promise<ConstantsResponseDto[]> => {
        try {
            const response = await GeneralServices.get({
                setLoading: args.setLoading,
                loader: args.loader === false ? false : true,
                endpoint: `sendaHealth/healthDocumentTypes${args.params ? '?' + args.params.join("&") : ''}`,
            });
            return response.data;
        } catch (err) {
            return [];
        }
    }

    uploadDocumentsToSenda = async (setLoading: Function, loader: boolean, data: RequiredDocumentsDto): Promise<SendaBaseResponse> => {
        try {
            const fd = new FormData();
            fd.append("body", JSON.stringify({
                "cotizacion": data.cotizacion,
                "fileInfo": data.fileInfo
            }));
            data.files.forEach(file => {
                fd.append(`files`, file);
            });

            const response = await GeneralServices.postFormData({
                setLoading: setLoading,
                loader: loader,
                endpoint: "sendaHealth/uploadDocuments",
                data: fd
            });
            return response.data;
        } catch (err) {
            const baseError = "Error al subir documentos";
            const genericError = 'Se ha producido un error al subir documentos. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    }

    hiring = async (args: HiringHealthDto): Promise<SendaHiringResponse> => {
        try {
            const response = await GeneralServices.post({
                setLoading: args.setLoading,
                isSlowProcess: true,
                loader: args.loader === false ? false : true,
                endpoint: `sendaHealth/healthHiring`,
                data: args.data,
            });
            return response.data;
        } catch (err) {
            const baseError = "Error al emitir la póliza";
            const genericError = 'Se ha producido un error al emitir la póliza. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    };


    getRiskData = async (setLoading: Function, loader: boolean, request: getRiskRequest, internalName: string): Promise<SendaBaseResponse> => {
        try {
            const response = await GeneralServices.post({
                setLoading,
                isSlowProcess: true,
                loader,
                endpoint: `sendaHealth/riskData?internalName=${internalName}`,
                data: request,
            });
            return response.data;
        } catch (err) {
            const baseError = "Error obteniendo datos de riesgo";
            const genericError = 'Se ha producido un error al obtener los datos de riesgo. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    }

    setBeneficiaries = async (setLoading: Function, loader: boolean, request: IBeneficiarieRequest): Promise<SendaBaseResponse> => {
        try {
            const response = await GeneralServices.post({
                setLoading,
                loader,
                endpoint: "sendaHealth/setBeneficiaries",
                data: request,
            });
            return response.data;
        } catch (err) {
            throw err;
            // const genericError = 'Se ha producido un error al establecer beneficiarios. Vuelva a intentarlo más tarde.';
            // throw this.returnError(err, baseError, genericError);
        }
    }

    uploadQuestionnaires = async (setLoading: Function, loader: boolean, request: SendaResponseQuestionnaire): Promise<uploadQuestionnairesResponseDto> => {
        try {
            const response = await GeneralServices.post({
                setLoading: setLoading,
                loader: loader === false ? false : true,
                endpoint: "sendaHealth/uploadhealthquestionnaires",
                data: request,
            });
            return response.data;
        } catch (err) {
            const baseError = "Error al subir cuestionarios";
            const genericError = 'Se ha producido un error al subir los cuestionarios. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    };

    async buildPrecontractualDocument(setLoading: Function, loader: boolean, request: BuildPrecontractualDocumentRequest, productType: string): Promise<BuildPrecontractualDocumentResponseDto> {
        try {
            const response = await GeneralServices.postFileResponse({
                setLoading: setLoading,
                loader: loader,
                endpoint: `sendaHealth/buildPrecontractualDocument?productType=${productType}`,
                data: request,
            });

            return {
                fileData: response.data,
                fileName: GestDocServices.getFileName(response.headers["content-disposition"]),
                fileType: response.headers["content-type"],
            }
        } catch (err) {
            throw err;
            // const genericError = 'Se ha producido un error al generar la documentación precontractual. Vuelva a intentarlo más tarde.';
            // throw this.returnError(err, baseError, genericError);
        }
    }

    setPaymentMethod = async (setLoading: Function, loader: boolean, data: IPaymentMethod, mainSepa?: File, copaySepa?: File, insuredSepaFileArray?: File[]): Promise<SendaPaymentResponse> => {
        try {
            const fd = new FormData();
            fd.append("body", JSON.stringify(data));
            if (mainSepa) fd.append("mainSepa", mainSepa, mainSepa.name);
            if (copaySepa) fd.append("copaySepa", copaySepa, copaySepa.name);
            if (insuredSepaFileArray && insuredSepaFileArray.length > 0) insuredSepaFileArray.forEach(f => fd.append("insuredSepa", f, f.name));
           
            const response = await GeneralServices.postFormData({
                setLoading: setLoading,
                isSlowProcess: true,
                loader: loader,
                endpoint: "sendaHealth/setPaymentMethod",
                data: fd
            });
            return response.data;
        } catch (err) {
            const baseError = "Error al establecer método de pago";
            const genericError = 'Se ha producido un error al establecer método de pago. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    }

    setDataProtection = async (setLoading: Function, loader: boolean, request: IDataProtectionMethod): Promise<SendaBaseResponse> => {
        try {
            const response = await GeneralServices.post({
                setLoading,
                loader,
                endpoint: "sendaHealth/setDataProtection",
                data: request
            });
            return response.data;
        } catch (err) {
            const baseError = "Error al enviar protección de datos";
            const genericError = 'Se ha producido un error al establecer protección de datos. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    }

    getDataProtection = async (setLoading: Function, loader: boolean, cotizacion: string): Promise<ProtectionDataSendaResponse> => {
        try {
            const response = await GeneralServices.get({
                setLoading,
                loader,
                endpoint: `sendaHealth/setDataProtection/${cotizacion}`
            });
            return response.data;
        } catch (err) {
            const baseError = "Error al consultar protección de datos";
            const genericError = 'Se ha producido un error al consultar la protección de datos. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    }

    setShortages = async (setLoading: Function, loader: boolean, COTIZACION: string): Promise<SendaBaseResponse> => {
        try {
            let request: SetShortagesRequest = { COTIZACION: COTIZACION };
            const response = await GeneralServices.post({
                setLoading: setLoading,
                loader: loader,
                endpoint: "sendaHealth/setShortages",
                data: request
            });
            return response.data;
        } catch (err) {
            const baseError = "Error al establecer carencias";
            const genericError = 'Se ha producido un error al establecer carencias. Vuelva a intentarlo más tarde.';
            throw this.returnError(err, baseError, genericError);
        }
    }

    sendPrecontractualDocument = async (setLoading: Function, loader: boolean, request: BuildPrecontractualDocumentPDFRequest, productType: string): Promise<BuildPrecontractualDocumentResponseDto> => {
        try {
            const response = await GeneralServices.postFileResponse({
                setLoading: setLoading,
                loader: loader,
                endpoint: `sendaHealth/buildHiringPdf?productType=${productType}`,
                data: request,
            });

            return {
                fileData: response.data,
                fileName: GestDocServices.getFileName(response.headers["content-disposition"]),
                fileType: response.headers["content-type"],
            }
        } catch (err) {
            throw err;
            // const genericError = 'Se ha producido un error al enviar la documentación precontractual. Vuelva a intentarlo más tarde.';
            // throw this.returnError(err, baseError, genericError);
        }
    }

    returnError(e:any, baseError:string, genericError: string) {
        return e && e.data && e.data.message? `${baseError}: ${e.data.message}` : genericError;
    }
}

export default new HealthServices();