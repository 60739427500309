import { Dispatch, SetStateAction } from "react";
import CoveragesServices from "../../../../../services/CoveragesServices";
import { ItemType } from "./policy-warranty-step.types";

export const getCovConfig = async (
  setCovConfig: Dispatch<SetStateAction<ItemType[]>>
) => {
  const covConfigs = await CoveragesServices.getCoveragesConfig({
    setLoading: () => {},
    loader: false,
    ignoreSetFalse: true,
  });
  setCovConfig(covConfigs);
};

export const tableColumns = [
  {
    text: "Beneficiarios",
  },
  {
    text: "DNI",
  },
  {
    text: "Porcentaje",
  },
  {
    text: "",
  },
];

export const keyRows = ["beneficiaries","nif","percentage","buttons"];
