import React from "react";
import CheckboxItem from "../../../components/checkbox-item";
import DicotomicCheckbox from "../../../components/dicotomic-checkbox/dicotomic-checkbox";
import InputBox from "../../../components/input-box/input-box";
import SearchBox from "../../../components/search-box-mediadores/search-box";
import { ValueForm } from "../../../utils/interfaces";

interface AddressItemProps {
  address: {
    street: ValueForm;
    streetNumber: ValueForm;
    stairs: ValueForm;
    floorNumber: ValueForm;
    door: ValueForm;
    province: ValueForm;
    city: ValueForm;
    postalCode: ValueForm;
    normalizaDireciones: ValueForm;
  };
  citiesFiltered: any[];
  insured?: boolean;
  isInsuredSimulationDeath: boolean;
  isReadOnly: boolean;
  loadSearchInput: boolean;
  onChangeNormalizedAddress: Function;
  onChangeValue: Function;
  onChangeValueSearchBox: Function;
  onUseTakerAddress: Function;
  postalCodesFiltered: any[];
  provincesFiltered: any[];
  streetsFiltered: any[];
  urlString: string;
  useTakerAddress: boolean;
  useTakerData: boolean;
}

function AddressItem({
  address,
  citiesFiltered,
  insured,
  isInsuredSimulationDeath,
  isReadOnly,
  loadSearchInput,
  onChangeNormalizedAddress,
  onChangeValue,
  onChangeValueSearchBox,
  onUseTakerAddress,
  postalCodesFiltered,
  provincesFiltered,
  streetsFiltered,
  urlString,
  useTakerAddress,
  useTakerData,
}: AddressItemProps) {
  const normalizaDireciones = address.normalizaDireciones.value === "S";
  return (
    <div className="personal-data-container">
      {insured && (
        <div className="personal-data-row">
          <div className="personal-data-item col-100">
            <CheckboxItem
              className={"blue"}
              disabled={useTakerData || isReadOnly}
              checkboxText={"Usar los mismos datos de domicilio que el tomador"}
              checked={useTakerData || useTakerAddress}
              onChange={() => {
                !useTakerData && onUseTakerAddress(!useTakerAddress);
              }}
            />
          </div>
        </div>
      )}
      <div className="personal-data-row">
        <div className="personal-data-item col-50">
          {normalizaDireciones ? (
            <div className="inputBox">
              <SearchBox
                onChange={(ev: any) => {
                  onChangeValueSearchBox(
                    ev.target.value,
                    "address",
                    "province"
                  );
                }}
                required={address.province.required}
                errorCode={address.province.errorCode}
                labelText=""
                disabled={useTakerData || useTakerAddress || isReadOnly}
                withLoader={true}
                optionsText={provincesFiltered}
                optionKey={"code"}
                optionValue={"label"}
                initialValue={address.province.value.label || ""}
                onSelect={(value: string) =>
                  onChangeValue(value, "address", "province")
                }
                loader={loadSearchInput}
                notScroll
              />
              <label>Provincia</label>
            </div>
          ) : (
            <div className="inputBox">
              <InputBox
                placeholder="Provincia"
                labelText=""
                disabled={useTakerAddress || useTakerData || isReadOnly}
                required={address.province.required}
                errorCode={address.province.errorCode}
                value={address.province.value.label}
                type={"text"}
                onChange={(value: string) =>
                  onChangeValue({ value: value, label: value }, "address", "province")
                }
                notScroll
              />   
            </div>
          )}
        </div>
        <div className="personal-data-item col-50">
          {normalizaDireciones ? (
            <div className="inputBox">
              <SearchBox
                onChange={(ev: any) => {
                  onChangeValueSearchBox(ev.target.value, "address", "city");
                }}
                required={address.city.required}
                errorCode={address.city.errorCode}
                withLoader={true}
                disabled={
                  !address.province.value.label ||
                  useTakerAddress ||
                  useTakerData ||
                  isReadOnly
                }
                labelText=""
                optionsText={citiesFiltered}
                optionKey={"code"}
                optionValue={"label"}
                initialValue={address.city.value.label || ""}
                onSelect={(value: string) =>
                  onChangeValue(value, "address", "city")
                }
                loader={loadSearchInput}
                notScroll
              />
              <label>Localidad</label>
            </div>
          ) : (
            <div className="inputBox">
              <InputBox
                placeholder="Localidad"
                labelText=""
                disabled={useTakerAddress || useTakerData || isReadOnly}
                required={address.city.required}
                errorCode={address.city.errorCode}
                value={address.city.value.label}
                type={"text"}
                onChange={(value: string) =>
                  onChangeValue({ value: value, label: value }, "address", "city")
                }
                notScroll
              />
            </div>
          )}
        </div>
      </div>
      <div className="personal-data-row">
        <div className="personal-data-item col-100">
          {normalizaDireciones ? (
            <div className="inputBox">
              <SearchBox
                onChange={(ev: any) =>
                  onChangeValueSearchBox(ev.target.value, "address", "street")
                }
                required={address.street.required}
                errorCode={address.street.errorCode}
                withLoader={true}
                disabled={
                  !address.city.value.label ||
                  useTakerAddress ||
                  useTakerData ||
                  isReadOnly
                }
                labelText=""
                optionsText={streetsFiltered}
                optionKey={"code"}
                optionValue={"label"}
                initialValue={address.street.value.label || ""}
                onSelect={(value: string) =>
                  onChangeValue(value, "address", "street")
                }
                loader={loadSearchInput}
                notScroll
              />
              <label>Domicilio</label>
            </div>
          ) : (
            <div className="inputBox">
              <InputBox
                placeholder="Domicilio"
                labelText=""
                disabled={useTakerAddress || useTakerData || isReadOnly}
                required={address.street.required}
                errorCode={address.street.errorCode}
                value={address.street.value.label}
                type={"text"}
                onChange={(value: string) =>
                  onChangeValue({ value: value, label: value }, "address", "street")
                }
                notScroll
              />
            </div>
          )}
        </div>
      </div>
      <div className="personal-data-row">
        <div className="personal-data-item col-25">
          <div className="inputBox">
            <InputBox
              placeholder="Número"
              labelText=""
              disabled={useTakerAddress || useTakerData || isReadOnly}
              required={address.streetNumber.required}
              errorCode={address.streetNumber.errorCode}
              value={address.streetNumber.value}
              type={"text"}
              onChange={(value: string) =>
                onChangeValue(value, "address", "streetNumber")
              }
              notScroll
            />
          </div>
        </div>
        <div className="personal-data-item col-25">
          <div className="inputBox">
            <InputBox
              required={address.stairs.required}
              disabled={useTakerAddress || useTakerData || isReadOnly}
              errorCode={address.stairs.errorCode}
              placeholder="Escalera"
              value={address.stairs.value}
              onChange={(value: string) =>
                onChangeValue(value, "address", "stairs")
              }
              notScroll
            />
          </div>
        </div>
        <div className="personal-data-item col-25">
          <div className="inputBox">
            <InputBox
              required={address.floorNumber.required}
              disabled={useTakerAddress || useTakerData || isReadOnly}
              errorCode={address.floorNumber.errorCode}
              placeholder="Piso"
              type={"text"}
              value={address.floorNumber.value}
              onChange={(value: string) =>
                onChangeValue(value, "address", "floorNumber")
              }
              notScroll
            />
          </div>
        </div>
        <div className="personal-data-item col-25">
          <div className="inputBox">
            <InputBox
              required={address.door.required}
              disabled={useTakerAddress || useTakerData || isReadOnly}
              errorCode={address.door.errorCode}
              placeholder="Puerta"
              value={address.door.value}
              onChange={(value: string) =>
                onChangeValue(value, "address", "door")
              }
              notScroll
            />
          </div>
        </div>
      </div>

      <div className="personal-data-row">
        <div className="personal-data-item col-20">
          <div className="inputBox">
            {isInsuredSimulationDeath ? (
              <InputBox
                required={address.postalCode.required}
                disabled={useTakerAddress || useTakerData || isReadOnly}
                errorCode={address.postalCode.errorCode}
                value={address.postalCode.value.label || ""}
                maxLength={5}
                type={"number"}
                onChange={(value: string) =>
                  onChangeValue(value, "address", "postalCode")
                }
                notScroll
              />
            ) : normalizaDireciones ? (
              <>
              <SearchBox
                key={urlString}
                onChange={(ev: any) =>
                  onChangeValueSearchBox(
                    ev.target.value,
                    "address",
                    "postalCode"
                  )
                }
                required={address.postalCode.required}
                errorCode={address.postalCode.errorCode}
                withLoader={true}
                disabled={
                  !address.street.value.label ||
                  useTakerAddress ||
                  useTakerData ||
                  isReadOnly
                }
                optionsText={postalCodesFiltered}
                labelText=""
                optionKey={"code"}
                optionValue={"label"}
                initialValue={address.postalCode.value.label || ""}
                onSelect={(value: string) =>
                  onChangeValue(value, "address", "postalCode")
                }
                loader={loadSearchInput}
                notScroll
              />
              <label>Código postal</label>
              </>
            ) : (
              <InputBox
                required={address.postalCode.required}
                placeholder="Código postal"
                disabled={useTakerAddress || useTakerData || isReadOnly}
                errorCode={address.postalCode.errorCode}
                value={address.postalCode.value.label || ""}
                maxLength={5}
                type={"number"}
                onChange={(value: string) =>
                  onChangeValue({ value: value, label: value }, "address", "postalCode")
                }
                notScroll
              />
            )}
          </div>
        </div>

        <div className="colSin-80 rightPadding20 bottomMargin20">
          <div className="inputBox">
            <DicotomicCheckbox
              className={`${
                isReadOnly || useTakerAddress || useTakerData ? "disabled" : ""
              } optional`}
              checkboxText="CONTRATAR CON DIRECCIÓN NO NORMALIZADA"
              onChange={(value: boolean) => onChangeNormalizedAddress(value)}
              disabled={isReadOnly || useTakerAddress || useTakerData}
              initChecked={
                address.normalizaDireciones &&
                address.normalizaDireciones.value === "N"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressItem;
