import React from "react";
import {
  RouteComponentProps,
  withRouter,
  Switch,
  Route,
} from "react-router-dom";
import { HiringDetailPendantContainer } from "./detail-pendant-hiring-container-style";
import NoteText from "../../../../components/components-text/note-text";
import GoBack from "../../../../components/go-back";
import MainButton from "../../../../components/main-button";
import HiringServices from "../../../../services/HiringServices";
import { currencyFormat } from "../../../../utils";
import { withGeneral } from "../../../../context/mixin/with-general";
import LeftInfoModal from "../../../../components/left-info-modal";
import { images } from "../../../../assets";
import Pagination from "../../../../components/pagination";
import DetailPendantHiringLayout from "../detail-pendant-hiring/detail-pendant-hiring-layout";
import HiringHealthDeclarationResultPendantLayout from "../health-declaration-result-pendant-hiring/health-declaration-result-pendant-hiring-layout";
import NotFoundLayout from "../../../../components/not-found/not-found-layout";
import ConstantsServices from "../../../../services/ConstantsServices";
import {
  AREA_NAMES,
} from "../../../../constants/policyType";
import { getDataForm } from "../../../../utils/mapData";
import { withCoverages } from "../../../../context/mixin/with-coverages";
import GipServices from "../../../../services/GipServices";
import { GipQuestDto } from "../../new-hiring/gip/gip-questionnaire/gip-questionnaire-dto/gip-questionnaire-dto";
import { HealthHireMenuHelper, Steps } from "../../../../components/health-hire-menu/health-hire-menu";
import { DentalHireMenuHelper } from "../../../../components/dental-hire-menu/dental-hire-menu";
import { withDynamicProducts } from "../../../../context/mixin/with-dynamic-products";
import { CONST_HIRING_STATUS, SIGNIG_STATUS, HIRING_STATUS_GROUP_REVISION } from "../../new-hiring/types";
import { StepsTravel, TravelHireMenuHelper } from "../../../../components/travel-hire-menu/travel-hire-menu";
import PayTypesServices from "../../../../services/PayTypesServices";
import { withPayTypes } from "../../../../context/mixin/with-pay-types";
import { PAY_TYPES } from "../../../../constants/payTypes";
import { AllPayType, ProductPayType } from "../../../../services/PayTypesServices.dto";

interface RouteParams {
  id: string;
  mediator: string;
  page: string;
}
interface HiringDetailPendantProps extends RouteComponentProps<RouteParams> {
  setLoading: Function;
  coverages: any;
  productsByAreaName: any;
  checkProductArea: Function;
  allPayTypes: AllPayType[];
  allProducts: any;
}

interface HiringDetailPendantState {
  hiring: any;
  showLeftInfoModal: boolean;
  leftInfoModalImg: string;
  leftInfoModalClassName: string;
  leftInfoModalTitle: string;
  leftInfoModalText: string;
  leftInfoModalButtonText: string;
  leftInfoModalClick: Function;
  noSecondaryButtonModal: boolean;
  page: number;
  questionnaires: any;
  productPayTypes?: ProductPayType[];
}

class HiringDetailPendantMain extends React.Component<
  HiringDetailPendantProps,
  HiringDetailPendantState
> {
  readonly state: HiringDetailPendantState;

  constructor(props: HiringDetailPendantProps) {
    super(props);
    const page = props.history.location.pathname.indexOf("cuestionarios") > -1;
    this.state = {
      hiring: {
        body: {
          taker: {
            address: {},
          },
          insureds: [],
          beneficiaries: [],
          emisionError: []
        },
      },
      showLeftInfoModal: false,
      leftInfoModalImg: "",
      leftInfoModalClassName: "",
      leftInfoModalTitle: "",
      leftInfoModalText: "",
      leftInfoModalButtonText: "",
      noSecondaryButtonModal: false,
      leftInfoModalClick: () => { },
      page: page
        ? +props.history.location.pathname.split("cuestionarios/")[1] + 1
        : 1,
      questionnaires: {},
      productPayTypes: [],
    };
  }

  componentDidMount = () => {
    const { productsByAreaName } = this.props
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    if (Object.keys(productsByAreaName).length !== 0) {
      this.getHiring();
    }
  };

  componentDidUpdate(prevProps: Readonly<HiringDetailPendantProps>, prevState: Readonly<HiringDetailPendantState>, snapshot?: any): void {
    const { productsByAreaName } = this.props

    if (Object.keys(prevProps.productsByAreaName).length === 0 && Object.keys(productsByAreaName).length !== 0) {
      this.getHiring();
    }
  }

  getHiring = async () => {
    const { match, setLoading, checkProductArea, allProducts } = this.props;
    const hiring = await HiringServices.getOneHiring({
      loader: true,
      setLoading: setLoading,
      id: match.params.id,
      mediator: match.params.mediator,
    });
    const isLifeArea = checkProductArea(AREA_NAMES.LIFE, hiring.productType)
    const isGIPArea = checkProductArea(AREA_NAMES.GIP, hiring.productType)
    if (hiring.id) {
      const product = allProducts[hiring.productType]
      const args = {
        loader: true,
        setLoading: () => {},
        productId: product.products_id,
      };
      const productPayTypes = await PayTypesServices.getProductPayTypes(args);
      if (isLifeArea)
        this.getQuestionnaireQuestions(hiring.productType);
      else if (isGIPArea)
        this.getQuestionnaireQuestionsGip(hiring);
      this.setState({ hiring, productPayTypes });
    }
  };

  getQuestionnaireQuestions = async (productType: string) => {
    const { setLoading } = this.props;
    const resQuestionnaire = await ConstantsServices.getLifeQuestionnaires({
      loader: true,
      setLoading: setLoading,
    });
    const questionnaires: any = {};
    resQuestionnaire.forEach((q: any) => {
      questionnaires[q.id] = q.question;
      if (q.subquestionnaire) {
        q.subquestionnaire.forEach((sq: any) => {
          questionnaires[sq.id] = sq.question;
        });
      }
    });
    this.setState({ questionnaires });
  };

  getQuestionnaireQuestionsGip = async (hiring: any) => {
    const questionnaires: any = {};
    let respQuest: GipQuestDto = await GipServices.getGipQuestionnaires(
      {
        setLoading: this.props.setLoading,
        loader: true
      }
    ).then((data: any) => {
      return data;
    })
      .catch((error) => {
        return;
      });
    respQuest.gipQuest.forEach(questionnaire => {
      questionnaire.subquestionnaires.forEach(q => {
        questionnaires[q.id] = q.question;

      });

    });

    this.setState({ questionnaires });

  };

  getPaymentTableRows = (responseSimulation: any) => {
    const { allPayTypes } = this.props
    const payments: any[] =
      responseSimulation && responseSimulation.payments
        ? responseSimulation.payments.filter((p: any) => p.payType !== PAY_TYPES.BIME)
        : [];

    const paymentTableRows = payments.map((payment: any, index: number) => ({
      periodicity: {
        text: PayTypesServices.getPayTypeByCode(allPayTypes, payment.payType).description,
        order: PayTypesServices.getPayTypeByCode(allPayTypes, payment.payType).payTypeOrder,
        type: "",
        link: "",
        key: "highlighted",
      },
      netPremium: {
        text: `${currencyFormat(payment.netPremium)} €`,
        type: "",
        link: "",
        key: "body",
      },
      taxs: {
        text: `${currencyFormat(payment.taxs)} €`,
        type: "",
        link: "",
        key: "body",
      },
      total: {
        text: `${currencyFormat(payment.totalPremium)} €`,
        type: "",
        link: "",
        key: "body",
      },
    }));

    paymentTableRows.sort(
      (a: any, b: any) => a.periodicity.order - b.periodicity.order
    );

    return paymentTableRows;
  };

  // showPdf() {
  //   const { hiring } = this.state;
  //   this.setState({
  //     showLeftInfoModal: true,
  //     leftInfoModalImg: images.IconDownloadBlue,
  //     leftInfoModalClassName: "",
  //     leftInfoModalTitle: "¿Quieres descargar la póliza?",
  //     leftInfoModalText: "",
  //     leftInfoModalButtonText: "Descargar",
  //     // leftInfoModalClick: () => window.open(hiring.response.documents[0].url)
  //     leftInfoModalClick: () => {
  //       const file = convertBase64ToFile(
  //         hiring.response.documents[0].data,
  //         "application/pdf"
  //       );
  //       downloadFile(file, `poliza.pdf`, "application/pdf", true);
  //     },
  //   });
  // }
  getPathName(hiring: any): string {
    const { checkProductArea } = this.props;
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, hiring.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, hiring.productType)
    if (isHealthArea) {
      return HealthHireMenuHelper.getStepNavigationPath(hiring.body.currentHealthStep ? hiring.body.currentHealthStep : Steps.DatosTomador, hiring.body.insureds && hiring.body.insureds.length > 0 ? hiring.body.insureds.length : 0)
    } else if (isDentalArea) {
      return DentalHireMenuHelper.getStepNavigationPath(hiring.body.currentHealthStep ? hiring.body.currentHealthStep : Steps.DatosTomador, hiring.body.insureds && hiring.body.insureds.length > 0 ? hiring.body.insureds.length : 0)
    } else {
      return `/contratacion/nueva/miembros`;
    }

  }
  updateHiring = async () => {
    const { hiring } = this.state;
    if (hiring.signatureStatus === SIGNIG_STATUS.FIRMADA || hiring.status === CONST_HIRING_STATUS.RECHAZADA || hiring.status === CONST_HIRING_STATUS.EXPIRADA) return;
    else if (hiring.signatureStatus === SIGNIG_STATUS.SOLICITADA) {
      this.setState({
        showLeftInfoModal: true,
        leftInfoModalClassName: "warning",
        leftInfoModalTitle: "Cancelación de firma",
        leftInfoModalImg: images.IconWarningYellow,
        leftInfoModalText: "Si continua se eliminará el proceso de firma anterior, ¿Estás seguro?",
        leftInfoModalButtonText: "Continuar",
        noSecondaryButtonModal: false,
        leftInfoModalClick: () => this.cancelRequestedSign()
      });
    }
    else this.showHiring();
  }

  cancelRequestedSign = async () => {
    let { hiring } = this.state;
    try {
      this.setState({ showLeftInfoModal: false });
      const { setLoading } = this.props;
      const updateSignHiring = await HiringServices.updateSignHiring({ data: { id: hiring.id, signId: hiring.body.signData.signId }, setLoading: setLoading });
      hiring = updateSignHiring;
      this.goToDetail();
    } catch (error) {
      if (error && error.status === 409) {
        this.setState({
          showLeftInfoModal: true,
          leftInfoModalClassName: "error",
          leftInfoModalImg: images.IconWarningYellow,
          leftInfoModalTitle: "Error cancelando firma",
          leftInfoModalText: 'No es posible cancelar el proceso de firma cuando no se encuentra en estado de SOLICITADA.',
          noSecondaryButtonModal: true,
          leftInfoModalButtonText: 'Salir',
          leftInfoModalClick: () => { }
        });
      } else {
        this.setState({
          showLeftInfoModal: true,
          leftInfoModalClassName: "error",
          leftInfoModalImg: images.IconWarningYellow,
          leftInfoModalTitle: "Error en el servicio",
          leftInfoModalText: 'Se ha producido un error, por favor inténtelo de nuevo más tarde.',
          leftInfoModalButtonText: 'Salir',
          noSecondaryButtonModal: true,
          leftInfoModalClick: () => { }
        });
      }
    }
  }

  showHiring() {
    this.setState({
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconArrowRightBlue,
      leftInfoModalClassName: "",
      leftInfoModalTitle: "¿Quieres contratar esta contratación pendiente?",
      leftInfoModalText: "",
      leftInfoModalButtonText: "Ir",
      noSecondaryButtonModal: false,
      leftInfoModalClick: () => this.goToDetail()
    });
  }

  goToDetail() {
    const { hiring } = this.state;
    const { history, checkProductArea, productsByAreaName, allProducts } = this.props;
    const isHealthArea = checkProductArea(AREA_NAMES.HEALTH, hiring.productType)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, hiring.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, hiring.productType)
    const product = allProducts[hiring.productType]

    let pathName: string = ""

    if (isHealthArea) {
      pathName = HealthHireMenuHelper.getStepNavigationPath(hiring.body.currentHealthStep ? hiring.body.currentHealthStep : Steps.DatosTomador, hiring.body.insureds && hiring.body.insureds.length > 0 ? hiring.body.insureds.length : 0)
    } else if (isDentalArea) {
      pathName = DentalHireMenuHelper.getStepNavigationPath(hiring.body.currentDentalStep ? hiring.body.currentDentalStep : Steps.DatosTomador, hiring.body.insureds && hiring.body.insureds.length > 0 ? hiring.body.insureds.length : 0)
    } else if (isTravelArea) {
      pathName = TravelHireMenuHelper.getStepNavigationPath(hiring.body.currentTravelStep ? hiring.body.currentTravelStep : StepsTravel.DatosTomador, hiring.body.insureds && hiring.body.insureds.length > 0 ? hiring.body.insureds.length : 0,hiring.productType)

    } else {
      pathName = `/contratacion/nueva/miembros`;
    }
    const goToDetail = hiring.body.currentHealthStep == Steps.DatosAsegurados ? hiring.body.insureds.length - 1 : undefined;

    localStorage.setItem('internalName', hiring.productType)

    const path = pathName && pathName.split('?')[0] ? pathName.split('?')[0] : undefined
    const params = pathName && pathName.split('?')[1] ? pathName.split('?')[1] : undefined

    history.push({
      pathname: path,
      search: params,
      state: {
        goToDetail: goToDetail,
        dataForm: getDataForm(
          hiring.body,
          hiring.productType,
          product.products_coverageName,
          productsByAreaName,
          this.props.coverages
        ),
        data: {
          payment: {},
          beneficiaries: [],
          loan: {},
          ...hiring.body
        },
        selectedMediatorCode: hiring.mediatorAssociatedToPolicy,
        hiringId: hiring.id
      },
    })
  }

  showCancelHiringDialog = async () => {
    this.setState({
      showLeftInfoModal: true,
      leftInfoModalClassName: "",
      leftInfoModalTitle: "Cancelación de contratación",
      leftInfoModalImg: images.IconArrowRightBlue,
      leftInfoModalText: "Va a cancelar la contratación en el Portal del Mediador y esta acción no se reflejará en Senda. ¿Desea Continuar?",
      leftInfoModalButtonText: "Continuar",
      noSecondaryButtonModal: false,
      leftInfoModalClick: () => this.cancelHiring(),
    });
  }

  cancelHiring = async () => {
    const { hiring } = this.state;
    const { setLoading } = this.props;
    try {
      const response = await HiringServices.updateStatusHiring({
        setLoading,
        loader: true,
        data: {
          id: hiring.id,
          status: CONST_HIRING_STATUS.ANULADA_MEDIADORES,
        }
      });
      this.setState({ hiring: response });
    } catch (error) {
      this.setState({
        showLeftInfoModal: true,
        leftInfoModalClassName: "error",
        leftInfoModalImg: images.IconWarningYellow,
        leftInfoModalTitle: "Error en el servicio",
        leftInfoModalText: 'Se ha producido un error, por favor inténtelo de nuevo más tarde.',
        leftInfoModalButtonText: 'Salir',
        noSecondaryButtonModal: true,
        leftInfoModalClick: () => { }
      });
    }
  }

  closeLeftModal() {
    this.setState({ showLeftInfoModal: false });
  }

  handleChangePage = (page: number) => {
    const { history, match } = this.props;
    const { hiring } = this.state;
    if (page === 1) {
      history.push(`/contratacion/pendiente/detalle/${hiring.mediatorAssociatedToPolicy}/${hiring.id}`);
    } else {
      history.push(
        `/contratacion/pendiente/detalle/${match.params.mediator}/${match.params.id}/cuestionarios/${page - 1
        }`
      );
    }
  };

  checkPhysicalSign = () => {
    const { hiring } = this.state;
    return hiring.body.physicalSignatureNode && (HIRING_STATUS_GROUP_REVISION.includes(hiring.status) || hiring.status === CONST_HIRING_STATUS.EMITIDA)
  }

  getActionText() {
    const { hiring } = this.state;
    const { checkProductArea } = this.props;
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, hiring.productType);

    if (hiring.status === CONST_HIRING_STATUS.RECHAZADA || hiring.status === CONST_HIRING_STATUS.EXPIRADA || (isTravelArea && HIRING_STATUS_GROUP_REVISION.includes(hiring.status))) return hiring.status;
    else if (hiring.signatureStatus === SIGNIG_STATUS.FIRMADA  || this.checkPhysicalSign()) return "Firmada";
    else if (hiring.status === CONST_HIRING_STATUS.PENDIENTE && !hiring.signatureStatus) return "Continuar";
    else return "Modificar";
  }

  isFinalStatus = () => {
    const { hiring } = this.state;
    return hiring.signatureStatus === SIGNIG_STATUS.FIRMADA || HIRING_STATUS_GROUP_REVISION.includes(hiring.status) || hiring.status === CONST_HIRING_STATUS.RECHAZADA || hiring.status === CONST_HIRING_STATUS.EXPIRADA;
  }


  render() {
    const {
      showLeftInfoModal,
      leftInfoModalImg,
      leftInfoModalClassName,
      leftInfoModalTitle,
      leftInfoModalText,
      leftInfoModalButtonText,
      leftInfoModalClick,
      noSecondaryButtonModal,
      hiring,
      page,
      questionnaires,
      productPayTypes,
    } = this.state;
    const { checkProductArea } = this.props
    const isPetArea = checkProductArea(AREA_NAMES.PET, hiring.productType)
    const isDeathArea = checkProductArea(AREA_NAMES.DEATH, hiring.productType)
    const isLifeArea = checkProductArea(AREA_NAMES.LIFE, hiring.productType)
    const isTravelArea = checkProductArea(AREA_NAMES.TRAVEL, hiring.productType)
    const isDentalArea = checkProductArea(AREA_NAMES.DENTAL, hiring.productType)

    const showCancelButton = hiring.status && hiring.status === 'PENDIENTE' && !hiring.signatureStatus; 

    return (
      <HiringDetailPendantContainer>
        <div>
          <div className="detail-pendant-hiring-back">
            <GoBack text="Detalle contratación pendiente" />
          </div>
          <div className="detail-pendant-hiring-main">
            <Switch>
              <Route
                exact
                path="/contratacion/pendiente/detalle/:mediator/:id/cuestionarios/:page"
                render={(props) => (
                  <HiringHealthDeclarationResultPendantLayout
                    hiring={hiring}
                    questionnaires={questionnaires}
                    setLoading={this.props.setLoading}
                    checkProductArea={this.props.checkProductArea}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/contratacion/pendiente/detalle/:mediator/:id"
                render={(props) => (
                  <DetailPendantHiringLayout hiring={hiring} productPayTypes={productPayTypes} {...props} />
                )}
              />
              <Route path="/" component={NotFoundLayout} />
            </Switch>
            {!isPetArea && !isDeathArea && !isDentalArea && !isTravelArea && (
              <div className="pendant-hiring-pagination">
                <Pagination
                  page={page}
                  limit={hiring.body.insureds.length + 1}
                  callback={(page) => this.handleChangePage(page)}
                />
              </div>
            )}
            <div className="detail-pendant-hiring-divider" />
            <div className="detail-pendant-hiring-footer">
              <ul>
                {/* <NoteText>
                  <li>
                    * Encontrará la nota informativa anexa en el documento
                    Resultado de Simulación PDF, que puede descargar
                  </li>
                </NoteText> */}
                <NoteText>
                  <li>
                    * Este presupuesto tiene validez durante 30 días naturales,
                    siempre que se mantengan las circunstancias declaradas por
                    los asegurados. La aceptación del proyecto está supeditada a
                    la cumplimentación de la solicitud de seguro y cuestionarios
                    de salud asegurados. Así mismo,{" "}
                    {isLifeArea
                      ? "ASISA VIDA"
                      : "ASISA"}{" "}
                    se reserva el derecho de la aceptación del riesgo.
                  </li>
                </NoteText>
              </ul>
            </div>
          </div>
        </div>
        <div className="detail-pendant-hiring-buttons-container">
          <div className="detail-pendant-hiring-buttons-item">
            {
              hiring.status && hiring.status !== CONST_HIRING_STATUS.ANULADA_MEDIADORES &&
              <MainButton
                disabled={this.isFinalStatus()}
                text={this.getActionText()}
                onClick={() => this.updateHiring()} />
            }
          </div>
          <div className="detail-pendant-hiring-buttons-item">
            {
              showCancelButton && (
                <MainButton
                  text="Cancelar contratación"
                  type="red"
                  onClick={this.showCancelHiringDialog}
                />
              )
            }
          </div>
          {/* <div className="detail-pendant-hiring-buttons-item">
            <MainButton
              type="full-light"
              text="Descargar PDF"
              onClick={() => this.showPdf()}
            />
          </div> */}
        </div>
        <LeftInfoModal
          img={leftInfoModalImg}
          className={leftInfoModalClassName}
          mainTitle={leftInfoModalTitle}
          mainText={leftInfoModalText}
          buttonText={leftInfoModalButtonText}
          close={() => this.closeLeftModal()}
          active={showLeftInfoModal}
          onClickAccept={leftInfoModalClick}
          noSecondaryButton={noSecondaryButtonModal}
        />
      </HiringDetailPendantContainer>
    );
  }
}

export default withGeneral(withDynamicProducts(withCoverages(withPayTypes(withRouter(HiringDetailPendantMain)))));
