import { SignoutResponse, User, UserManager, WebStorageStateStore } from 'oidc-client';
import { OIDC_AUTHORITY, OIDC_CLIENT_ID, OIDC_CLIENT_ROOT, OIDC_CLIENT_SCOPE, OIDC_CLIENT_SECRET } from '../services/url';

export interface IAuthService {
  getUser(): Promise<User | null>;
  login(): Promise<void>;
  logout(): Promise<void>;
  renewToken(): Promise<User>;
  signinCallback(): Promise<User>;
  logoutCallback(): Promise<SignoutResponse>;
}

export default class AuthService implements IAuthService {

  _userManager: UserManager;

  // Endpoint metadata IP: OIDC_AUTHORITY  + .well-known/openid-configuration
  
  constructor() {
    const settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: OIDC_AUTHORITY,
      client_id: OIDC_CLIENT_ID,
      client_secret: OIDC_CLIENT_SECRET,
      redirect_uri: `${OIDC_CLIENT_ROOT}signin-callback`,
      post_logout_redirect_uri: `${OIDC_CLIENT_ROOT}logout-complete`,
      automaticSilentRenew: false,
      silent_redirect_uri: `${OIDC_CLIENT_ROOT}silent-renew.html`,
      response_type: 'code',
      scope: OIDC_CLIENT_SCOPE,
      filterProtocolClaims: true,
      RequirePkce: true,
    };

    this._userManager = new UserManager(settings);
  }

  getUser(): Promise<User | null> {
    return this._userManager.getUser();
  }

  login(): Promise<void> {
    return this._userManager.signinRedirect();
  }

  renewToken(): Promise<User> {
    return this._userManager.signinSilent();
  }

  logout(): Promise<void> {
    return this._userManager.signoutRedirect();
  }

  signinCallback(): Promise<User> {
    return this._userManager.signinRedirectCallback();
  }
  
  logoutCallback(): Promise<SignoutResponse> {
    return this._userManager.signoutRedirectCallback();
  }
}
