import React, { Component } from "react";
import { CheckBlockButtonContainer } from "./check-block-button-style";
import { images } from "../../assets";
import Body03Text from "../components-text/body-03-text";

interface CheckBlockButtonProps {
  buttonText: string;
  onClick?: (checked: boolean) => void;
  initialValue?: boolean;
  checked?: boolean;
  disabled?: boolean;
}

interface CheckBlockButtonState {
  checked: boolean;
}

class CheckBlockButton extends Component<CheckBlockButtonProps, CheckBlockButtonState> {
  state: CheckBlockButtonState;

  constructor(props: CheckBlockButtonProps) {
    super(props)
    this.state = {
      checked: !!props.checked,
    }
  }


  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    const { initialValue } = this.props;
    if (initialValue) {
      this.setState({ checked: initialValue });
    }
  };

  componentDidUpdate(prevProps: CheckBlockButtonProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: !!this.props.checked })
    }
  }

  onClick() {
    const { onClick, disabled } = this.props;

    if (!disabled)
      this.setState(
        (prevState: CheckBlockButtonState) => ({ checked: !prevState.checked }),
        () => {
          if (onClick) {
            onClick(this.state.checked);
          }
        },
      );

  }

  render() {
    const { buttonText, checked, disabled } = this.props;

    return (
      <CheckBlockButtonContainer
        className={`${checked ? "active" : ""}`}
        onClick={() => this.onClick()}
        disabled={disabled}
      >
        <div className="check-icon">
          <img src={images.IconCheckGreen} alt="Seleccionar" />
        </div>
        <div className="check-text">
          <Body03Text>{buttonText}</Body03Text>
        </div>
      </CheckBlockButtonContainer>
    );
  }
}

export default CheckBlockButton;
