import styled from "styled-components";
import { colors } from "../../assets";

export const GoBackContainer = styled.div`
  display: inline-block;
  width: 100%;
  color: ${colors["TX-001-30"]};

  .go-back-text {
    margin-left: 16px;
    letter-spacing: 0.05em;
    cursor: pointer;
    padding-top: 2px;
  }

  .go-back-container {
    display: flex;
    align-items: center;
  }

  img {
    max-width: 20px;
    cursor: pointer;
  }
`;
