import GeneralServices from "./GeneralServices";
import { ReportType } from "../containers/clients/clients-reports/clients-reports";

interface ParamsType {
  year: string,
  month: string,
  mediatorCode: string,
  [key: string]: string;
}

class ReportServices {
  getReports = async (setLoading: Function, loader: boolean, params: ParamsType, type: ReportType) => {
    try {
      const query = new URLSearchParams(params);
      const endpoint = type.url;
      const response = await GeneralServices.get({
        setLoading,
        loader,
        endpoint: `${endpoint}?${query}`,
      });
      return {
        ok: true,
        data: response.data,
      }
    } catch(error) {
      const errorStatus = error && error.status;
      const message: string = errorStatus === 404 ? "No existen ficheros con los criterios de búsqueda indicados." : "Se ha producido un error al consultar los archivos. Vuelva a intentarlo más tarde.";

      return {
        error: true,
        errorStatus,
        message,
      }
    }
  }
}

export default new ReportServices();
