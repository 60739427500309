import moment from "moment";
import React, { useEffect, useState } from "react";
import { IDataProtectionBase, IProtectionDataProps } from "../interfaces";
import { IDataProtectionMethod } from "../../../../constants/health";
import { POST_DATA_PROTECTION } from "../constants";
import CrossService from "../../../../services/CrossService";


export const useProtectionData = ({ value = '', policyValue = '' }: any) => {
    const emptyOject: IDataProtectionBase = {
        value: '',
        error: false,
        errorCode: '',
        required: false,
        disabled: false,
    }
    const [consentDate, setConsentDate] = useState<IDataProtectionBase>(emptyOject)
    const [dataTransfer, setDataTransfer] = useState<IDataProtectionBase>(emptyOject)
    const [thirdParty, setThirdParty] = useState<IDataProtectionBase>(emptyOject)
    const [thirdPartyCalls, setThirdPartyCalls] = useState<IDataProtectionBase>(emptyOject)
    const [consentDateSenda, setConsentDateSenda] = useState<IDataProtectionBase>(emptyOject)
    const [dataTransferSenda, setDataTransferSenda] = useState<IDataProtectionBase>(emptyOject)
    const [thirdPartySenda, setThirdPartySenda] = useState<IDataProtectionBase>(emptyOject)
    const [thirdPartyCallsSenda, setThirdPartyCallsSenda] = useState<IDataProtectionBase>(emptyOject)
    const [anyChange, setAnyChanges] = useState(false);
    const [resultDataProtection, setResultDataProtection] = useState({ error: false, errorText: '' });


    const initializeProtectionDataFromProps = (data: any) => {

        setConsentDate({
            value: data.protectionData ? data.protectionData.consentDate : moment().format('DD/MM/YYYY').toString(),
            error: false,
            errorCode: '',
            required: false,
            disabled: true,
        })
        setDataTransfer({
            value: data.protectionData ? data.protectionData.dataTransfer : '',
            error: false,
            errorCode: '',
            required: true,
            disabled: false,
        })
        setThirdParty({
            value: data.protectionData ? data.protectionData.thirdParty : '',
            error: false,
            errorCode: '',
            required: true,
            disabled: false,
        })
        setThirdPartyCalls({
            value: data.protectionData ? data.protectionData.thirdPartyCalls : '',
            error: false,
            errorCode: '',
            required: true,
            disabled: false,
        })

    }
    const changeConsetDateError = (errorCode: string) => {
        setConsentDate({
            ...consentDate,
            errorCode
        })
    }
    const changeDataTransferError = (errorCode: string) => {
        setDataTransfer({
            ...dataTransfer,
            errorCode
        })
    }
    const changeThirdPartyError = (errorCode: string) => {
        setThirdParty({
            ...thirdParty,
            errorCode
        })
    }
    const changeConsetDateValue = (value: string) => {
        if (consentDate.value !== value) {
            setAnyChanges(true)
        }
        setConsentDate({
            ...consentDate,
            value
        })
    }
    const changeDataTransferValue = (value: string) => {
        if (dataTransfer.value !== value) {
            setAnyChanges(true)
        }

        setDataTransfer({
            ...dataTransfer,
            value
        })
    }
    const changeThirdPartyValue = (value: string) => {
        if (thirdParty.value !== value) {
            setAnyChanges(true)
        }
        setThirdParty({
            ...thirdParty,
            value
        })
    }
    const changeThirdPartyCallsValue = (value: string) => {
        if (thirdPartyCalls.value !== value) {
            setAnyChanges(true)
        }
        setThirdPartyCalls({
            ...thirdPartyCalls,
            value
        })
    }
    const recoverSendaInfo = () => {
        setConsentDate(consentDateSenda)
        setDataTransfer(dataTransferSenda)
        setThirdParty(thirdPartySenda)
        setThirdPartyCalls(thirdPartyCallsSenda)
    }
    const saveSendaResponse = (consentDate: string, dataTransfer: string, thirdParty: string, thirdPartyCalls: string) => {
        setConsentDateSenda({
            value: consentDate,
            error: false,
            errorCode: '',
            required: false,
            disabled: true,
        })
        setDataTransferSenda({
            value: dataTransfer,
            error: false,
            errorCode: '',
            required: true,
            disabled: false,
        })
        setThirdPartySenda({
            value: thirdParty,
            error: false,
            errorCode: '',
            required: true,
            disabled: false,
        })
        setThirdPartyCallsSenda({
            value: thirdPartyCalls,
            error: false,
            errorCode: '',
            required: true,
            disabled: false,
        })
    }
    const changeAllValuesDataProtection = (consentDate: string, dataTransfer: string, thirdParty: string, thirdPartyCalls: string) => {

        changeConsetDateValue(consentDate)
        changeDataTransferValue(dataTransfer)
        changeThirdPartyValue(thirdParty)
        changeThirdPartyCallsValue(thirdPartyCalls)
    }
    const getProtectionData = () => {
        return {
            consentDate,
            dataTransfer,
            thirdParty,
            thirdPartyCalls
        }
    }
    const resetValidationData = () => {
        changeConsetDateError('')
        changeDataTransferError('')
        changeThirdPartyError('')

    }

    const validateData = () => {
        resetValidationData()
        let error = false;
        if (consentDate.value === '') {
            changeConsetDateError('required')
            error = true;
        }
        if (dataTransfer.value === '') {
            changeDataTransferError('required')
            error = true;
        }
        if (thirdParty.value === '') {
            changeThirdPartyError('required')
            error = true;
        }

        return error;
    }
    const createProtectionDataRequest = (cotizacion: string): IDataProtectionMethod => {
        return {
            COTIZACION: cotizacion,
            PROTECCION_DATOS: {
                CANCELACION_DATOS: POST_DATA_PROTECTION.NO,
                CESION_DATOS: dataTransfer.value,
                FECHA_CONSENTIMIENTO: consentDate.value,
                OBSERVACIONES: '',
                PUBLICIDAD_ESCRITA_PROPIOS: POST_DATA_PROTECTION.YES,
                PUBLICIDAD_ESCRITA_TERCEROS: thirdParty.value,
                PUBLICIDAD_TELEFONICA_PROPIOS: POST_DATA_PROTECTION.YES,
                PUBLICIDAD_TELEFONICA_TERCEROS: thirdPartyCalls.value !== '' ? thirdPartyCalls.value : '-',
                RECEPCION_COMUNICACION: POST_DATA_PROTECTION.EMAIL,
                RED_COMERCIAL: POST_DATA_PROTECTION.NO,
                ROBINSON: POST_DATA_PROTECTION.NO,
                TIPO_CONSENTIMIENTO: POST_DATA_PROTECTION.GDPR
            }
        }
    }

    const postDataProtection = async (setLoading: Function, cotizacion: string, area: string) => {
        const request = createProtectionDataRequest(cotizacion);
        return await CrossService.setDataProtection(setLoading, true, request, area)
            .then(() => {
                return {
                    error: false,
                    errorText: ''
                }
            })
            .catch((error) => {
                return {
                    error: true,
                    errorText: error
                }
            });
    }
    return {
        setConsentDate,
        setDataTransfer,
        setThirdParty,
        setThirdPartyCalls,
        initializeProtectionDataFromProps,
        changeConsetDateValue,
        changeDataTransferValue,
        changeThirdPartyValue,
        changeThirdPartyCallsValue,
        changeAllValuesDataProtection,
        validateData,
        postDataProtection,
        setResultDataProtection,
        getProtectionData,
        saveSendaResponse,
        recoverSendaInfo,
        resultDataProtection,
        anyChange,
        consentDate,
        dataTransfer,
        thirdParty,
        thirdPartyCalls
    }
}