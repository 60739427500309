import { differenceInYears } from "date-fns";
import moment from "moment";
import React, { Component } from "react";
import { Insured } from "../../containers/hirings/new-hiring/health/health- guarantee/health-guarantee-dto/health-guarantee-risks-dto";
import { AddictionSubValueModel, AnswerModel, HealthQuestionnairesData, HealthQuestionnairesDataList, HealthQuestionnairyDto, PREGUNTA, RESPUESTA, SelectQuestModel } from "../../containers/hirings/new-hiring/health/health-questionnaire/health-questionnaire-dto/health-questionnaire-dto";
import { getDayMonthYear } from "../../utils/time";
import CalendarBox from "../calendar-box/calendar-box";
import DicotomicButton from "../dicotomic-button";
import DicotomicCheckbox from "../dicotomic-checkbox/dicotomic-checkbox";
import InputBox from "../input-box/input-box";
import InputCounter from "../input-counter/input-counter";
import MainButton from "../main-button";
import MainTable from "../main-table";
import SelectBox from "../select-box/select-box";
import { HealthQuestionnaireTableContainer } from "./health-questionnaires-table-style";
import SEX from "../../constants/sex.json"
import HealthService from "../../services/HealthService";
import NoteText from "../components-text/note-text";
import WebText from "../components-text/web-text";

interface HealthHealthQuestionnairesTableProps {
    questionnaires: any;
    insureds: Insured[];
    setLoading: Function;
}

interface HealthHealthQuestionnairesTableState {
    checkedRows: boolean[];
    questionnaireData: HealthQuestionnairesData;
    isDisabled: boolean;
}

class HealthQuestionnairesTable extends Component<HealthHealthQuestionnairesTableProps, HealthHealthQuestionnairesTableState> {

    constructor(props: HealthHealthQuestionnairesTableProps) {
        super(props);

        let checkedRowsArray: boolean[] = [];
        props.questionnaires && props.questionnaires.length > 0 && Object.keys(props.questionnaires)
            .forEach(() => checkedRowsArray.push(false));

        this.state = {
            checkedRows: checkedRowsArray,
            questionnaireData: {
                healthQuestList: {
                    ASISASALUD: undefined,
                    ASISAC1: undefined,
                    ASISAC2: undefined,
                    ASISAC21: undefined,
                    ASISAC211: undefined,
                    ASISAC212: undefined,
                    ASISAC22: undefined,
                    ASISAC3: undefined,
                },
                modalLiteral: undefined
            },
            isDisabled: false,
        }
    }

    async componentWillMount() {
        await this.getHealthQuestionnaires();
        this.setState({ isDisabled: true })
    }

    getHealthQuestionnaires = async () => {
        const { setLoading } = this.props;

        if (!this.state.questionnaireData.healthQuestList || !this.state.questionnaireData.healthQuestList.ASISASALUD) {
            let respQuest: HealthQuestionnairesData = await HealthService.getQuestionnaries(
                setLoading,
                true
            ).then((data: any) => {
                return data;
            })
                .catch((error) => {
                    return;
                });
            this.setState({ questionnaireData: respQuest })
        }
    }

    getTableColumns = () => {

        const tableColumns = [
            {
                text: "",
                filter: false,
                ascendent: true,
                key: "dimmed-mini-title",
            },
            {
                text: "Concepto",
                filter: false,
                ascendent: true,
                key: "dimmed-mini-title",
            },
            {
                text: "Importe",
                filter: false,
                ascendent: false,
                key: "dimmed-mini-title",
            },
        ];
        return tableColumns;
    }

    renderQuestionnairesTable() {
        const { questionnaires, insureds } = this.props
        const { checkedRows } = this.state;
        return insureds.map((insured: any, index: number) => {
            let name = '';
            let insuredIndex = -1;
            name = `${insured.name} ${insured.lastname1} ${insured.lastname2}`
            insuredIndex = index;
            return (
                <div className="tab" key={`questionnaire${index + 1}`}>
                    <input type="checkbox" checked={checkedRows[index]} onChange={() => this.handleOnCheckDetailQuestionnaire(index)} />
                    <label className="tab-label col-100">
                        <div className="result-simulation-special-row">
                            <div className="result-hiring-special-subitem col-20">
                                <div className="result-simulation-special-subitem-title">
                                    <NoteText>
                                        <p>DOCUMENTO</p>
                                    </NoteText>
                                </div>
                                <div className="result-hiring-special-subitem-content">
                                    <WebText>
                                        <p>
                                            {questionnaires[insured.docNumber].insuredDocNumber}
                                        </p>
                                    </WebText>
                                </div>
                            </div>
                            <div className="result-hiring-special-subitem col-40">
                                <div className="result-simulation-special-subitem-title">
                                    <NoteText>
                                        <p>NOMBRE</p>
                                    </NoteText>
                                </div>
                                <div className="result-hiring-special-subitem-content">
                                    <WebText>
                                        <p>
                                            {name}
                                        </p>
                                    </WebText>
                                </div>
                            </div>
                            <div className="result-hiring-special-subitem col-20">
                                <div className="result-simulation-special-subitem-title">
                                    <NoteText>
                                        <p>FECHA NACIMIENTO</p>
                                    </NoteText>
                                </div>
                                <div className="result-hiring-special-subitem-content">
                                    <WebText>
                                        <p>
                                            {questionnaires[insured.docNumber].questionnaireData.questionnaireResponse['04'].value}
                                        </p>
                                    </WebText>
                                </div>
                            </div>
                            <div className="result-hiring-special-subitem col-10">
                                <div className="result-simulation-special-subitem-title">
                                    <NoteText>
                                        <p>EDAD</p>
                                    </NoteText>
                                </div>
                                <div className="result-hiring-special-subitem-content">
                                    <WebText>
                                        <p>
                                            {differenceInYears(new Date(),
                                                moment(questionnaires[insured.docNumber].questionnaireData.questionnaireResponse['04'].value, 'DD/MM/YYYY').toDate()).toString()}
                                        </p>
                                    </WebText>
                                </div>
                            </div>
                            <div className="result-hiring-special-subitem col-15">
                                <div className="result-simulation-special-subitem-title">
                                    <NoteText>
                                        <p>SEXO</p>
                                    </NoteText>
                                </div>
                                <div className="result-hiring-special-subitem-content">
                                    <WebText>
                                        <p>
                                            {questionnaires[insured.docNumber].questionnaireData.questionnaireResponse['02'].value === 'H' ? 'HOMBRE' : 'MUJER'}
                                        </p>
                                    </WebText>
                                </div>
                            </div>
                        </div>
                    </label>

                    <div className="tab-content">
                        {/* Aquí se muestran las respuestas de los cuestionarios */}
                        <div className={"content-wrapper noEvents"} id={`questionnaire${index + 1}`}>
                            {this.state.questionnaireData.healthQuestList &&
                                this.state.questionnaireData.healthQuestList.ASISASALUD &&
                                this.renderHealthQuestionnaire(this.state.questionnaireData.healthQuestList.ASISASALUD, undefined, insured.docNumber)}
                        </div>
                    </div>
                </div>
            )
        })
    }

    addSelectQuestOptions(question: PREGUNTA, codigoCues: string, parentId: string): SelectQuestModel {
        let selectBoxes = question.RESPUESTAS_PREGUNTA.RESPUESTA ?
            ([] = question.RESPUESTAS_PREGUNTA.RESPUESTA.map((questResponse: RESPUESTA, index: number) => {
                return { label: questResponse.DESCRIPCION, value: questResponse.VALUERES };
            })
            ) : ([{ label: '', value: '' }]);
        return {
            id: question.CODIPREG,
            identificador: codigoCues,
            tituPreg: question.TITUPREG,
            selectBoxes: question.TITUPREG === "Tipo" ? selectBoxes.sort((a, b) => (a.label > b.label) ? 1 : -1) : selectBoxes
        }
    }

    setQuestionnaireResponseState(answerModel: AnswerModel, questionnaireIdentifier: string) {
        const questionnaireSelected = this.props.questionnaires[questionnaireIdentifier].questionnaireData;

        let newResponse = questionnaireSelected.questionnaireResponse;
        newResponse[answerModel.id] = answerModel;

        questionnaireSelected.questionnaireResponse = newResponse;
        this.setState({})
    }

    setQuestionnaireMultipleResponseState(id: string, answerModel: AnswerModel, parentId: string = "", questionnaireIdentifier: string) {
        const questionnaireSelected = this.props.questionnaires[questionnaireIdentifier];
        let newResponse = questionnaireSelected.questionnaireMultipleResponse;
        let answerToChange = newResponse[id] ? newResponse[id].find((val: any) => val.answerId === answerModel.answerId) : undefined;

        if (answerToChange) {
            answerToChange.value = answerModel.value;
            answerToChange.subValue = answerModel.subValue;
            answerToChange.checked = answerModel.checked;
        } else {
            if (!newResponse[id])
                newResponse[id] = [];

            newResponse[id].push(answerModel);
        }

        questionnaireSelected.questionnaireMultipleResponse = newResponse;
        this.setState({});
    }

    onQuestionValueChange(question: PREGUNTA, answerModel: AnswerModel, multiple: boolean = false, value: any = undefined, questionnaireIdentifier: string) {
        answerModel.value = value;
        answerModel.errorCode = answerModel.isRequired && value.length === 0 ? "required" : "";

        for (let key in this.props.questionnaires[questionnaireIdentifier].questionnaireData.questionnaireResponse) {
            let value = this.props.questionnaires[questionnaireIdentifier].questionnaireData.questionnaireResponse[key];
            if (value.parentId == answerModel.id) {
                value.isEnabled = answerModel.value == "s";
            }
        }

        if (multiple)
            this.setQuestionnaireMultipleResponseState(question.CODIPREG, answerModel, undefined, questionnaireIdentifier);
        else
            this.setQuestionnaireResponseState(answerModel, questionnaireIdentifier);
    }

    getQuestionnaireMultipleResponseState = (question: PREGUNTA, answerId: string, parentId: string = "", insured: string): AnswerModel => {
        let defaultAnswer;
        if (this.props.questionnaires[insured].questionnaireData.questionnaireMultipleResponse[question.CODIPREG])
            defaultAnswer = this.props.questionnaires[insured].questionnaireData.questionnaireMultipleResponse[question.CODIPREG].find((val: any) => val.answerId === answerId);
        if (!defaultAnswer)
            defaultAnswer = this.getDefaultAnswerModel(question, answerId, parentId, true);
        return defaultAnswer;
    }

    restartSubQuestionnaire(question: PREGUNTA, questionnaireIdentifier: string) {
        for (let key in this.props.questionnaires[questionnaireIdentifier].questionnaireData.questionnaireResponse) {
            let element = this.props.questionnaires[questionnaireIdentifier].questionnaireData.questionnaireResponse[key];
            if (element.parentId === question.CODIPREG) {
                element.checked = undefined;
                element.value = "";
            }
        }
        for (let key in this.props.questionnaires[questionnaireIdentifier].questionnaireData.questionnaireMultipleResponse) {
            let element = this.props.questionnaires[questionnaireIdentifier].questionnaireData.questionnaireMultipleResponse[key];
            element.forEach((v: any) => {
                if (v.parentId === question.CODIPREG) {
                    v.checked = false;
                    v.value = "";
                }
            })
        }
    }

    isQuestionDisabled(currentAnswerValue: AnswerModel | undefined, answer: RESPUESTA): boolean {
        if (currentAnswerValue) {
            if (currentAnswerValue.value === answer.VALUERES && currentAnswerValue.checked === true)
                return false;
            else if (currentAnswerValue.value === answer.VALUERES && currentAnswerValue.checked === false)
                return true;
            else if (currentAnswerValue.value === answer.VALUERES)
                return false;
        }
        return true;
    }

    isMultipleQuestionDisabled(currentAnswerValue: AnswerModel[], answer: RESPUESTA): boolean {
        let existingAnswer = currentAnswerValue ? currentAnswerValue.find((val) => val.answerId === answer.IDENTIFICADOR) : undefined;
        return this.isQuestionDisabled(existingAnswer, answer);
    }

    getSubQuestionnaire(SUBCODICUES?: string) {
        // ASISAC3 is disabled here
        if (SUBCODICUES !== "ASISAC3" &&
            SUBCODICUES != undefined && this.state.questionnaireData.healthQuestList != undefined &&
            SUBCODICUES in this.state.questionnaireData.healthQuestList) {
            const subCuestNameKey = SUBCODICUES as keyof HealthQuestionnairesDataList;
            const subQuestions = this.state.questionnaireData.healthQuestList && this.state.questionnaireData.healthQuestList[subCuestNameKey];
            return subQuestions;
        }
        return null;
    }

    isAnswerMultiple = (question: PREGUNTA) => question.SWRESMUL === "S";

    getTypesRows(types: any[]) {
        return types.map((b, index) => ({
            type: {
                text: b["name"].label,
                type: "",
                key: "body",
            },
            amount: {
                text: b["amount"].label,
                type: "",
                key: "body",
            }
        }))
    }

    renderHealthQuestionnaire = (questionnaire: HealthQuestionnairyDto | undefined, parentId: string = "", insured: string) => {
        let questionDetail: React.ReactNode[] = [];
        let questions: React.ReactNode[] = [];

        if (questionnaire &&
            questionnaire.PREGUNTAS_CUESTIONARIO &&
            questionnaire.PREGUNTAS_CUESTIONARIO.PREGUNTA) {
            questionnaire.PREGUNTAS_CUESTIONARIO.PREGUNTA.forEach((pregunta: PREGUNTA) => {
                switch (pregunta.TIPODATO) {
                    case 'SUBCUEST':
                        //Render subcuestionario
                        questions.push(this.renderSubcuest(pregunta, pregunta.CODIPREG, insured))
                        break;
                    case 'SELECT':
                        //Render pregunta con SELECT
                        questions.push(this.renderSelect(pregunta, pregunta.CODIPREG, insured))
                        break;
                    case 'RADIO':
                        //Render pregunta con RADIO
                        questions.push(pregunta.CODIPREG !== '02' ? <div className="question">{this.renderRadio(pregunta, pregunta.CODIPREG, insured)}</div> : this.renderRadio(pregunta, pregunta.CODIPREG, insured))
                        break;
                    case 'CHECK':
                        //Render pregunta con CHECK
                        questions.push(this.renderCheck(pregunta, pregunta.CODIPREG, insured))
                        break;
                    case 'DATE':
                        //Render pregunta con CHECK
                        questions.push(this.renderDateAnswers(pregunta, pregunta.CODIPREG, insured))
                        break;
                    case "DOUBLE":
                        questions.push(<div className="question isRow">{this.renderNumberAnswers(pregunta, pregunta.CODIPREG, insured)}</div>);
                        break;
                }
            })
        }

        if (questionnaire && questionnaire.TIPOCUES === 'DETA')
            questionDetail.push(
                <div className="subquestionnaire">
                    <h4>{questionnaire.TITULO}</h4>
                    <>{questions}</>
                </div>
            )
        else
            return questions

        return questionDetail;
    }

    renderNumberAnswers(question: PREGUNTA, parentId: string, insured: string) {
        // Disabled IMC (07) y PERCENTIL-OMS (08)
        if (question.CODIPREG !== '07' && question.CODIPREG !== '08') {
            let answerValue = this.getQuestionnaireResponseState(question, question.IDENTIFICADOR, parentId, insured);
            let unit = question.IDENTIFICADOR === "15519" ? "kg" : question.IDENTIFICADOR === "15520" ? "cm" : undefined;

            return <InputCounter
                key={insured + question.IDENTIFICADOR}
                onChange={(value: string) => ''}
                value={answerValue.value}
                labelText={question.TITUPREG.toUpperCase()}
                disabled={true}
                required={answerValue.isRequired}
                errorCode={answerValue.errorCode}
                unit={unit}
            />;
        }
    }

    renderDateAnswers(question: PREGUNTA, parentId: string, insured: string) {
        let answerValue = this.getQuestionnaireResponseState(question, question.IDENTIFICADOR, parentId, insured);

        return <div className="inputBox isRow marginTop">
            <CalendarBox
                className={"noChangeLabel"}
                key={insured + question.IDENTIFICADOR}
                initialValue={answerValue.value}
                placeholder={question.TITUPREG}
                forbidFutureDates
                maxDate={moment().toISOString()}
                required={true}
                errorCode={answerValue.errorCode}
                disabled={true}
                onChange={(value: string) => ''}
            />
        </div>
    }

    renderCheck(pregunta: PREGUNTA, parentId: string, insured: string): React.ReactNode[] {
        const { questionnaireData } = this.state;
        let questions: React.ReactNode[] = [];

        if (pregunta.RESPUESTAS_PREGUNTA && pregunta.RESPUESTAS_PREGUNTA.RESPUESTA) {
            pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.forEach((respuesta) => {
                let answerValue = this.getQuestionnaireMultipleResponseState(pregunta, respuesta.IDENTIFICADOR, parentId, insured);
                let checkValue = answerValue ? answerValue.checked : false;
                questions.push(
                    <div className={`switchBox disabled`}>
                        <DicotomicCheckbox
                            key={insured + "c" + respuesta.IDENTIFICADOR}
                            initChecked={checkValue}
                            disabled={true}
                            className={"optional"}
                            checkboxText={respuesta.DESCRIPCION}
                            onChange={(value: boolean) => ''}
                        />
                    </div>
                )
                if (
                    respuesta.TIPORESP === 'CUES' &&
                    respuesta.CUESTIONARIO &&
                    Object.keys(respuesta.CUESTIONARIO).length > 0 &&
                    answerValue.checked
                )
                    questions.push(this.renderHealthQuestionnaire(respuesta.CUESTIONARIO, undefined, insured))
                else if (answerValue.checked)
                    questions.push(this.renderAnswer(pregunta, respuesta, true, parentId, insured))
            })
        }
        return questions;
    }

    renderRadio(pregunta: PREGUNTA, parentId: string, insured: string): React.ReactNode[] {
        let questions: React.ReactNode[] = [];

        if (pregunta.CODIPREG !== '02')
            questions.push(<div className="question-title"><h6>{pregunta.TITUPREG}</h6></div>)
        if (pregunta.RESPUESTAS_PREGUNTA.RESPUESTA)
            pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.forEach((respuesta: RESPUESTA, index) => {
                let answerResult = this.getQuestionnaireResponseState(pregunta, respuesta.IDENTIFICADOR, parentId, insured);

                if (pregunta.CODIPREG === '02' && index === 0) {
                    questions.push(
                        <div className="personal-data-item isRow">
                            <div className="personal-data-item col-25">
                                <DicotomicButton
                                    key={insured + respuesta.IDENTIFICADOR}
                                    required={true}
                                    labelText={pregunta.TITUPREG}
                                    options={SEX}
                                    disabled={true}
                                    initialValue={answerResult.value}
                                    onChange={(e: string) => 'this.onAnswerValueChange(pregunta, respuesta, answerResult)'}
                                />
                            </div>
                        </div>);
                }
                else if (pregunta.CODIPREG !== '02')
                    questions.push(<div className={"radioBox disabled"}>
                        <label key={insured + "l" + respuesta.IDENTIFICADOR}>
                            <input
                                key={insured + respuesta.IDENTIFICADOR}
                                type="radio"
                                name={insured + respuesta.ID_PREGUNTA}
                                value={respuesta.VALUERES}
                                disabled={true}
                                checked={answerResult && answerResult.value === respuesta.VALUERES}
                                required={true}
                                onChange={(e) => 'this.onAnswerValueChange(pregunta, respuesta, answerResult)'}
                            />
                            <span className="recover-checkmark"> {respuesta.DESCRIPCION}</span>
                        </label>
                    </div>)

                if (respuesta.VALUERES === answerResult.value) {
                    questions.push(this.renderAnswer(pregunta, respuesta, false, pregunta.CODIPREG, insured))
                }
            })

        return questions;
    }

    renderAnswer(pregunta: PREGUNTA, respuesta: RESPUESTA, isParentMultiple: boolean, parentId: string, insured: string): React.ReactNode[] | JSX.Element[] {
        let question: JSX.Element[] = [];
        let questions: React.ReactNode[] = [];

        const isDisabled = true;

        let subAnswerValue =
            isParentMultiple ?
                this.getQuestionnaireMultipleResponseState(pregunta, respuesta.IDENTIFICADOR, undefined, insured) :
                this.getQuestionnaireResponseState(pregunta, respuesta.IDENTIFICADOR, parentId, insured);


        switch (respuesta.TIPORESP) {
            case ('VATA'):
                question.push(
                    <InputBox
                        key={insured + "sub" + respuesta.IDENTIFICADOR}
                        area={true}
                        areaRows={4}
                        type={"text"}
                        value={typeof subAnswerValue.subValue === "string" ? subAnswerValue.subValue : ""}
                        required={true}
                        errorCode={subAnswerValue && subAnswerValue.errorCode ? subAnswerValue.errorCode : ""}
                        disabled={true}
                        className={"question isQuestionnaire "}
                        cleanValue={true}
                        onChange={(value: string) => ''}
                        notScroll={true}
                    />
                )
                break;

            case ('VALO'):

                break;

            case "VATX": // Text
            case "TXTL": // Text
                question.push(
                    <InputBox
                        key={insured + "sub" + respuesta.IDENTIFICADOR}
                        type={"text"}
                        value={typeof subAnswerValue.subValue === "string" ? subAnswerValue.subValue : ""}
                        errorCode={subAnswerValue && subAnswerValue.errorCode ? subAnswerValue.errorCode : ""}
                        required={true}
                        disabled={true}
                        className={"question "}
                        cleanValue={true}
                        notScroll={true}
                        onChange={(value: string) => ''}
                    />
                )

                break;

            case ('CUES'):
                if (respuesta.CUESTIONARIO)
                    questions.push(
                        <div className="subquestionnaire">
                            <>{this.renderHealthQuestionnaire(respuesta.CUESTIONARIO, respuesta.SUBCODICUES, insured)}</>
                        </div>
                    )
                else
                    questions.push(
                        <div className="subquestionnaire">
                            <>{this.renderSubcuest(pregunta, pregunta.CODIPREG, insured, respuesta.SUBCODICUES)}</>
                        </div>
                    )
                break;

        }

        return question.length > 0 ? question : questions;
    }

    getDefaultAnswerModel = (question: PREGUNTA, answerId: string, parentId: string = "", multiple: boolean): AnswerModel => {
        let defValue, subCuesAnswer;

        if (multiple && question.RESPUESTAS_PREGUNTA.RESPUESTA)
            defValue = question.RESPUESTAS_PREGUNTA.RESPUESTA.find(val => val.IDENTIFICADOR === answerId);

        if (question.RESPUESTAS_PREGUNTA.RESPUESTA)
            subCuesAnswer = question.RESPUESTAS_PREGUNTA.RESPUESTA.find(val => val.SUBCODICUES !== "");

        let defaultAnswer = {
            id: question.CODIPREG,
            answerId: (multiple) ? answerId : undefined,
            parentId: parentId,
            isEnabled: multiple ? false : parentId !== undefined,
            isRequired: true,
            errorCode: "",
            value: "",
            subValue: "",
            checked: multiple ? false : undefined,
            subCues: multiple ? (defValue && defValue.SUBCODICUES ? defValue.SUBCODICUES : "") : (subCuesAnswer && subCuesAnswer.SUBCODICUES ? subCuesAnswer.SUBCODICUES : undefined),
        }

        let { questionnaires } = this.props;

        if (!multiple) {
            questionnaires.questionnaireResponse = {
                ...questionnaires.questionnaireResponse,
                [question.CODIPREG]: defaultAnswer
            };
        }

        return defaultAnswer;
    }

    getQuestionnaireResponseState = (question: PREGUNTA, answerId: string, parentId: string, insured: string): AnswerModel => {
        if (this.props.questionnaires[insured].questionnaireData.questionnaireResponse[question.CODIPREG])
            return this.props.questionnaires[insured].questionnaireData.questionnaireResponse[question.CODIPREG];
        else
            return this.getDefaultAnswerModel(question, answerId, parentId, false);
    }

    renderSelect(pregunta: PREGUNTA, parentId: string, insured: string): React.ReactNode[] {
        let questions: React.ReactNode[] = [];
        let answerValue = this.getQuestionnaireResponseState(pregunta, pregunta.IDENTIFICADOR, parentId, insured);
        if (answerValue)
            questions.push(
                <div className="selectBox topMargin10">
                    <div className="legal-heirs-table">
                        <SelectBox
                            key={insured + pregunta.IDENTIFICADOR}
                            placeholder={""}
                            required={answerValue.isRequired}
                            errorCode={answerValue.errorCode}
                            optionsText={pregunta.RESPUESTAS_PREGUNTA.RESPUESTA ? (
                                [] = pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.map((questResponse: RESPUESTA, index: number) => {
                                    return { label: questResponse.DESCRIPCION, value: questResponse.VALUERES };
                                })
                            ) : ([{ label: '', value: '' }])}
                            optionKey={"value"}
                            optionValue={"label"}
                            initialValue={answerValue.value ? answerValue.value : "Selecciona"}
                            defaultValue={''}
                            disabled={true}
                            onChange={(value: string) => ''} />
                        <label>{pregunta.TITUPREG}</label>
                    </div>
                </div>
            )
        return questions;
    }

    renderSubcuest(pregunta: PREGUNTA, parentId: string, insured: string, answerId?: string): React.ReactNode[] {
        const { questionnaireData } = this.state;
        let questions: React.ReactNode[] = [];
        if (pregunta.RESPUESTAS_PREGUNTA && pregunta.RESPUESTAS_PREGUNTA.RESPUESTA) {
            pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.forEach((respuesta) => {
                if (respuesta.SUBCODICUES && questionnaireData && questionnaireData.healthQuestList) {
                    if (answerId) {
                        if (answerId === respuesta.SUBCODICUES) {
                            const subCues = questionnaireData.healthQuestList[respuesta.SUBCODICUES];
                            if (subCues) {
                                questions.push(<div className="subquestionnaire">{this.renderHealthQuestionnaire(subCues, parentId, insured)}</div>)
                            }
                        }
                    }
                    else {
                        const subCues = questionnaireData.healthQuestList[respuesta.SUBCODICUES];
                        if (subCues)
                            questions.push(<div className="subquestionnaire">{this.renderHealthQuestionnaire(subCues, parentId, insured)}</div>)
                    }
                }
            })
        }
        return questions;
    }

    getSubvalue(respuesta: RESPUESTA): AddictionSubValueModel | string {
        if (respuesta.TIPORESP === 'CUES' && respuesta.CUESTIONARIO && respuesta.CUESTIONARIO.PREGUNTAS_CUESTIONARIO.PREGUNTA) {
            let subvalue: AddictionSubValueModel = {
                subCues: respuesta.IDENTIFICADOR,
                subvalues: {}
            }
            respuesta.CUESTIONARIO.PREGUNTAS_CUESTIONARIO.PREGUNTA.forEach((pregunta) => {
                subvalue.subvalues[pregunta.CODIPREG] = [];
                pregunta.RESPUESTAS_PREGUNTA.RESPUESTA && pregunta.RESPUESTAS_PREGUNTA.RESPUESTA.forEach((respuesta) => {
                    subvalue.subvalues[pregunta.CODIPREG].push({
                        dataType: respuesta.TIPORESP,
                        id: respuesta.ID_PREGUNTA,
                        label: respuesta.DESCRIPCION,
                        value: respuesta.VALUERES
                    })
                })
            })

            return subvalue;
        } else {
            return ''
        }
    }

    handleOnCheckDetailQuestionnaire = (index: number) => {
        let { checkedRows } = this.state

        checkedRows[index] = !checkedRows[index];

        this.setState({ checkedRows });
    }

    render() {

        return (
            <HealthQuestionnaireTableContainer>

                <div className="tabs">
                    {this.renderQuestionnairesTable()}
                </div>

            </HealthQuestionnaireTableContainer>
        )

    }
}

export default HealthQuestionnairesTable;

