import styled from "styled-components";

export const HealthCampaingContainer = styled.div`
.offersCard {
  position: relative;
  width: 100%;
  height: 230px;
  background-color: #fff;
  border-radius: 6px;
  display: block;
  cursor: pointer;
  transition: all 300ms;
  overflow: visible;
  margin: 10px 0;

  &:hover {
    box-shadow: 0 5px 10px #d8d8d8;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        &:after {
          display: block;
        }
      }

      ~ h5 {
        background-color: #004a8f;
        color: #fff;
        font-weight: 700;
      }

      ~ i {
        box-shadow: 0 5px 10px #d8d8d8;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 23px;
    left: 16px;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 1px solid #004a8f;
    border-radius: 3px;
    transition: all 300ms;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid #004a8f;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  h5 {
    color: rgb(0, 74, 143);
    text-align: center;
    height: 91px;
    line-height: 35px;
    border-radius: 6px 6px 0px 0px;
    margin: 0px;
    transition: all 300ms ease 0s;
    font-weight: 300;
    white-space: initial;
    padding-inline: 10px;
  }

  p {
    font-size: 18px;
    padding: 20px 25px;
    text-align: center;
    white-space: pre-wrap;
    line-height: 24px;
  }

  i {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    transition: all 300ms;
  }
}
.campaingTitle{
  border-radius: 10px 10px 0px 0px;
  padding-block: 15px;
    padding-inline: 47px;
    background-color: rgb(0, 74, 143);
    color: white;
    h5 {
      color: white;
    }
}
.campaingTitleDisabled{
  border-radius: 10px 10px 0px 0px;
  padding-block: 15px;
  padding-inline: 47px;
  background-color: rgb(244, 244, 244);
  h5 {
    color: rgb(0, 74, 143);
  }
}
.offersCard.disabled {
  filter: contrast(50%) brightness(135%) grayscale(1);
  pointer-events: none;
}

[class^="campaingdiv-"] {
  float: left;
  position: relative;
  padding: 0;
  margin: 0;
  padding-right: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.campaingdiv-33 {
  width: 33.333333%;
}
.campaingTittle {
  font-size: 20px;
}
.campaingText {
  font-size: 14px !important;
}
@media (max-width: 1540px) {
  [class^="campaingdiv"] {
      width: 100% !important;
  }
  .campaingTittle {
    font-size: 20px !important
  }
  .campaingText {
    font-size: 14px !important;
  }
}
@media (max-width: 1730px) {
  .campaingTittle {
      font-size: 14px;
  }
  .campaingText {
    font-size: 12px !important;
 
  }
}
 
`;
 
