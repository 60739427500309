import React, { Component } from "react";
import { CampaignsCreateStepContainer } from "./campains-create-steps-style";
import InputBox from "../../../../components/input-box/input-box";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import moment from "moment";
import SelectBox from "../../../../components/select-box/select-box";
import { CAMPAIGN_PERIODS_DROPDOWN } from "../../../../constants/campaignsConfig";

interface ICampaingsCreateStep0Props {
  goNext: boolean;
  onNext: (state: ICampaingsCreateStep0State) => void;
  previousState?: ICampaingsCreateStep0State;
}

export interface ICampaingsCreateStep0State {
  formData: {
    name: string;
    start: string;
    end: string;
    inProcessStart: string;
    inProcessEnd: string;
    adviceStartDate: string;
  };
  selectedPeriod?: string;
  campaignStatus?: string;
  errors: { [key: string]: string };
}

class CampaingsCreateStep0 extends Component<
  ICampaingsCreateStep0Props,
  ICampaingsCreateStep0State
  > {
  constructor(props: ICampaingsCreateStep0Props) {
    super(props);
    this.state = {
      formData: {
        name: "",
        start: "",
        end: "",
        inProcessStart: "",
        inProcessEnd: "",
        adviceStartDate: ""
      },
      errors: {}
    };
  }

  componentDidMount() {
    const { previousState } = this.props;
    if (previousState) this.setState(previousState);
  }

  componentDidUpdate(prevProps: ICampaingsCreateStep0Props) {
    const { goNext, previousState } = this.props;
    if (goNext && prevProps.goNext !== goNext) {
      this.handleNext();
    }
    if (previousState && prevProps.previousState !== previousState) {
      this.setState(previousState);
    }
  }

  toMoment(date: string) {
    return moment(date, 'DD/MM/YYYY');
  }

  handleNext() {
    const { onNext } = this.props;
    const { formData, selectedPeriod } = this.state;
    const errors: { [key: string]: string } = {};
    let isCorrect = true;
    if (selectedPeriod) {
      formData.end = (selectedPeriod === 'anual'
        ? this.toMoment(formData.start).add(1, 'year')
        : this.toMoment(formData.start).add(2, 'month')).format('DD/MM/YYYY')
    }

    if (this.toMoment(formData.start).isSameOrAfter(this.toMoment(formData.end))) {
      errors.start = "invalid-first-date";
      errors.end = "invalid-second-date";
      isCorrect = false;
    }
    if (this.toMoment(formData.inProcessStart).isSameOrAfter(this.toMoment(formData.inProcessEnd))) {
      errors.inProcessStart = "invalid-first-date";
      errors.inProcessEnd = "invalid-second-date";
      isCorrect = false;
    }
    if (this.toMoment(formData.inProcessStart).isBefore(this.toMoment(formData.end))) {
      errors.inProcessStart = "invalid-follow-start-date";
      isCorrect = false;
    }

    Object.entries(formData).forEach(entry => {
      const field = entry[0];
      const value = entry[1];
      if (!value || value === "") {
        errors[field] = "required";
        isCorrect = false;
      }
    });
    this.setState({ formData, errors }, () => {
      if (isCorrect) onNext(this.state);
    });
  }

  handleChange(field: string, value: string) {
    const { formData } = Object.assign(this.state);
    formData[field] = value;
    this.setState({ formData });
  }

  render() {
    const {
      formData: { name, start, end, adviceStartDate, inProcessStart, inProcessEnd },
      errors,
      selectedPeriod,
      campaignStatus
    } = this.state;
    const isDefinition = !campaignStatus || campaignStatus === 'definition';
    const isInAction = !campaignStatus || campaignStatus === 'definition' || campaignStatus === 'planned' || campaignStatus === 'in_action';

    return (
      <CampaignsCreateStepContainer>
        <div className="section">
          <div className="section__title">{"DATOS GENERALES"}</div>
          <div className="section__subtitle"></div>
          <div className="col-100">
            <div>
              <InputBox
                labelText={"NOMBRE DE LA CAMPAÑA"}
                value={name}
                errorCode={errors.name}
                onChange={value => this.handleChange("name", value)}
                required={true}
                disabled={!isDefinition}
              />
            </div>
          </div>
          <div className="col-50">
            <div>
              <CalendarBox
                initialValue={start}
                labelText="Fecha de inicio"
                required={true}
                errorCode={errors.start}
                minDate={moment().toISOString()}
                onChange={(value: string) => this.handleChange("start", value)}
                disabled={!isDefinition}
              />
            </div>
            <div>
              {/* <CalendarBox
                initialValue={end}
                labelText="Fecha de fin"
                required={true}
                errorCode={errors.end}
                minDate={moment().toISOString()}
                onChange={(value: string) => this.handleChange("end", value)}
              /> */}
              <SelectBox
                optionsText={CAMPAIGN_PERIODS_DROPDOWN}
                optionKey={'value'}
                optionValue={'label'}
                placeholder={'Seleccione periodo'}
                labelText="Periodo"
                errorCode={errors.end}
                initialValue={selectedPeriod}
                defaultValue={selectedPeriod}
                onChange={(value) => this.setState({ selectedPeriod: value })}
                disabled={!isInAction}
              />
            </div>
          </div>
          <div className="col-50">
            <div>
              <CalendarBox
                initialValue={inProcessStart || ''}
                labelText="Fecha de inicio de seguimiento"
                required={true}
                errorCode={errors.inProcessStart}
                minDate={moment().toISOString()}
                onChange={(value: string) => this.handleChange("inProcessStart", value)}

              />
            </div>
            <div>
              <CalendarBox
                initialValue={inProcessEnd || ''}
                labelText="Fecha de fin de seguimiento"
                required={true}
                errorCode={errors.inProcessEnd}
                minDate={moment().toISOString()}
                onChange={(value: string) => this.handleChange("inProcessEnd", value)}
              />
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section__title">{"AVISO INICIO DE CAMPAÑA"}</div>
          <div className="section__subtitle">
            {
              "Recuerde que no podrá editar ni eliminar la campaña una vez haya pasado la fecha de aviso de inicio de campaña. El mensaje debe producirse antes del inicio de la misma."
            }
          </div>
          <div className="col-50">
            <div>
              <CalendarBox
                initialValue={adviceStartDate}
                labelText="FECHA DEL MENSAJE DE INICIO"
                required={true}
                errorCode={errors.adviceStartDate}
                minDate={moment().toISOString()}
                onChange={(value: string) =>
                  this.handleChange("adviceStartDate", value)
                }
                disabled={!isDefinition}
              />
            </div>
          </div>
        </div>
      </CampaignsCreateStepContainer>
    );
  }
}

export default CampaingsCreateStep0;
