import styled, { css } from 'styled-components';

export const MenuTextContainer = styled.div`
    font-family: "museo sans";
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    
    ${props => css`
        color: ${props.color}
    `}




`;