import React from "react";
import { HealthQuestionnaireContainer } from "./gip-questionnaire-data-style";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getDayMonthYear } from "../../../../../utils/time";
import { NewHiringContainer } from "../../new-hiring-style";
import InputCounter from "../../../../../components/input-counter/input-counter";
import MainButton from "../../../../../components/main-button";
import { images } from "../../../../../assets";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import Title03Text from "../../../../../components/components-text/title-03-text";
import {
  GipQuestDto,
  GipQuestionnairesDto,
  GipSubquestionnaireDto,
  questionnaireModel,
  questionnaireResponse,
} from "./gip-questionnaire-dto/gip-questionnaire-dto";
import CalendarBox from "../../../../../components/calendar-box/calendar-box";
import DicotomicButton from "../../../../../components/dicotomic-button";
import SelectBox from "../../../../../components/select-box/select-box";
import InputBox from "../../../../../components/input-box/input-box";
import moment from "moment";
import DicotomicCheckbox from "../../../../../components/dicotomic-checkbox/dicotomic-checkbox";
import SEX from "../../../../../constants/sex.json";
import MainTable from "../../../../../components/main-table";
import AddAddictionsModal from "../../../../../components/add-addictions-modal/add-addictions-modal";
import LeftInfoModal from "../../../../../components/left-info-modal";
import { GestDoc } from "../../../../../services/GestDoc";
import GestDocServices from "../../../../../services/Gest-Doc.Services";
import {
  changeFileName,
  downloadFile,
  getFileExtension,
} from "../../../../../utils/utility";
import { ERROR_MESSAGES } from "../../../../../constants/errorMessages";
import { NOTA_INFORMATIVA_PARENT_NODE_ID } from "../../../../../services/url";
import { GestDocSearchFileRequestDto } from "../../../../../models/gest-doc-models";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import {
  HealthHireMenuHelper,
  Steps,
} from "../../../../../components/health-hire-menu/health-hire-menu";
import { IUser } from "../../../../../models/user-model";
import { IMediator } from "../../../../../models/mediator-model";
import { GipHireMenuHelper } from "../../../../../components/gip-menu/gip-hire-menu";
import GipServices from "../../../../../services/GipServices";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";

interface RouteParams {
  index: string;
}

interface HealthQuestionnaireLayoutProps
  extends RouteComponentProps<RouteParams> {
  setLoading: Function;
  newStep: Function;
  saveHiring: Function;
  checkCompleted: Function;
  saveQuestionnaire: boolean;
  goToDetail?: number;
  policy: any;
  data: any;
  user: IUser;
  mediator: IMediator;
  match: any;
  isReadOnly: boolean;
  cotizacion: string;
  removeSign: Function;

}

interface HealthQuestionnaireLayoutState {
  insuredIndex: number;
  insuredDocNumber: string;
  questionnaireData: GipQuestDto | undefined;
  questionnaireSelected: questionnaireModel;
  anyError: boolean;
  rendered: any;
  addAddictionsModalActive: boolean;
  activeSelectSubCues: string;
  hasPendingChanges: boolean;
  isLoadingData: boolean;
  modalErrorShow: boolean;
  modalErrorText: string;
  isQuestionnaireUploaded: boolean;
  previousModalShow: boolean;
  nextModalShow: boolean;
  serverError: boolean;
  errorMessage: string;
  cancelSignModalShow:boolean;
  allNotChecked: boolean;
  anyChange:boolean;
}

class HealthQuestionnaireLayout extends React.Component<
  HealthQuestionnaireLayoutProps,
  HealthQuestionnaireLayoutState
> {
  state: HealthQuestionnaireLayoutState;

  anyError: boolean = false;

  constructor(props: any) {
    super(props);
    if (
      props.data.insureds.length === 0 ||
      props.data.insureds.length < parseInt(props.match.params.index)
    )
      props.history.push(
        `/${props.history.location.pathname.indexOf("simulacion") > -1
          ? "simulacion"
          : "contratacion"
        }/nueva`
      );
    this.state = {
      insuredIndex: 0,
      insuredDocNumber: "",
      questionnaireSelected: {},
      questionnaireData: undefined,
      anyError: false,
      rendered: undefined,
      addAddictionsModalActive: false,
      activeSelectSubCues: "",
      hasPendingChanges: false,
      isLoadingData: false,
      modalErrorShow: false,
      modalErrorText: "",
      isQuestionnaireUploaded: false,
      previousModalShow: false,
      nextModalShow: false,
      serverError: false,
      errorMessage: "",
      allNotChecked: false,
      anyChange:false,
      cancelSignModalShow:false
    };
  }

  validateRadio(response: questionnaireResponse): string {
    if (
      response &&
      response.selectedValue === "SI" &&
      response.response &&
      response.response.length > 0
    ) {
      return "";
    } else if (response && response.selectedValue === "NO") {
      return "";
    } else {
      return "required";
    }
  }

  validateNumber(response: questionnaireResponse): string {
    if (response && response.response && response.response.length > 0) {
      return "";
    } else {
      return "required";
    }
  }

  validateQuestionnaire(): boolean {
    let { questionnaireData, questionnaireSelected } = this.state;
    let error: boolean = false;

    if (questionnaireData && questionnaireData.gipQuest) {
      questionnaireData.gipQuest.forEach((questionnaire) => {
        questionnaire.subquestionnaires.forEach((question) => {
          let response = questionnaireSelected[question.id];
          if (response) {
            switch (question.data_type) {
              case "string":
                response.errorCode = this.validateRadio(response);
                if (!error && response.errorCode && response.errorCode !== "") {
                  error = true;
                }
                break;
              case "number":
                response.errorCode = this.validateNumber(response);
                if (!error && response.errorCode && response.errorCode !== "") {
                  error = true;
                }
                break;
              case "integer":
                response.errorCode = this.validateNumber(response);
                if (!error && response.errorCode && response.errorCode !== "") {
                  error = true;
                }
                break;
              default:
                break;
            }
          } else {
            //Si no hay respuesta directamente es un error
            error = true;
          }
        });
      });
    }

    this.setState({ questionnaireSelected }, () => this.getRenderedQuest());
    return error;
  }

  parseQuestionnairesToSave() {
    const { questionnaireSelected, questionnaireData } = this.state;
    let questionnairesToSave: {
      questDetail: string;
      subquestionnaires: any;
    }[] = [];

    if (questionnaireData && questionnaireData.gipQuest) {
      questionnaireData.gipQuest.forEach((questionnaire) => {
        questionnairesToSave.push({
          questDetail: questionnaire.questionnaire,
          subquestionnaires: Object.values(questionnaireSelected).filter(
            (responseQuest) => {
              let questToReturn = questionnaire.subquestionnaires.find(
                (q) => q.id === responseQuest.id
              );
              if (questToReturn) return questToReturn;
            }
          ),
        });
      });
    }

    return questionnairesToSave;
  }

  async saveQuestionnaire() {
    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true) {
      this.setState({ cancelSignModalShow: true })
      return false;
    }
    else {
      await this.validateAndSave()
    }
}

async validateAndSave() {

  if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED && this.state.anyChange === true){
    this.props.removeSign()
  }
    
    let error = await this.validateQuestionnaire();
    if (!error) {
      const { questionnaireSelected, insuredDocNumber, allNotChecked } =
        this.state;

      let questionnaireGip = {
        questionnaireGip: true,
        insuredDocNumber: insuredDocNumber,
        questionnaireData: this.parseQuestionnairesToSave(),
        allNotChecked,
      };

      this.props.policy.setHealthData(questionnaireGip);
      this.props.saveHiring(true);
      this.state.isLoadingData = true;
    } else {
      this.setState({
        serverError: true,
        errorMessage: "Debe completar todos los cuestionarios",
      });
    }
  }

  componentWillReceiveProps(nextProps: any) {
    let { saveQuestionnaire } = this.props;
    if (nextProps.saveQuestionnaire) {
      saveQuestionnaire = false;
      this.saveQuestionnaire();
    }

    if (
      nextProps.onGotoDetail !== undefined &&
      nextProps.onGotoDetail !== this.state.insuredIndex
    ) {
      let insuredDocNumber =
        nextProps.data.insureds[nextProps.onGotoDetail].docNumber;
      let existingQuestionnaire = this.props.data.questionnaires.find(
        (insuredQuestionnaire: any) =>
          insuredQuestionnaire.insuredDocNumber &&
          insuredQuestionnaire.insuredDocNumber === insuredDocNumber
      );
      let questionnaire = JSON.parse(
        JSON.stringify(
          nextProps.data.questionnaires && existingQuestionnaire
            ? existingQuestionnaire
            : {}
        )
      );
      this.setState(
        {
          insuredIndex: nextProps.onGotoDetail,
          insuredDocNumber: insuredDocNumber,
          questionnaireSelected: questionnaire.questionnaireData
            ? this.parseQuestionnaireFromDB(questionnaire.questionnaireData)
            : {},
          allNotChecked: questionnaire.allNotChecked
            ? questionnaire.allNotChecked
            : false,
        },
        this.getHealthQuestionnaires
      );
    }
  }

  parseQuestionnaireFromDB(questionnaireData: any) {
    let parsedQuest = {};

    Object.values(questionnaireData).forEach((questionnaire: any) => {
      questionnaire.subquestionnaires.forEach((question: any) => {
        parsedQuest = {
          ...parsedQuest,
          [question.id]: question,
        };
      });
    });

    return parsedQuest;
  }

  componentWillMount() {
    let { insuredIndex, questionnaireSelected } = this.state;
    window.addEventListener("scroll", () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${window.scrollY}px`
      );
    });

    insuredIndex =
      this.props.match &&
        this.props.match.params &&
        this.props.match.params.index
        ? parseInt(this.props.match.params.index)
        : 0;
    let insuredIdentifier =
      this.props.data.insureds &&
        this.props.data.insureds[this.props.match.params.index]
        ? this.props.data.insureds[this.props.match.params.index].docNumber
        : "";

    if (insuredIdentifier && this.props.data.questionnaires) {
      let existingQuestionnaire = this.props.data.questionnaires.find(
        (insuredQuestionnaire: any) =>
          insuredQuestionnaire.insuredDocNumber &&
          insuredQuestionnaire.insuredDocNumber === insuredIdentifier
      );
      questionnaireSelected = JSON.parse(
        JSON.stringify(
          existingQuestionnaire
            ? this.parseQuestionnaireFromDB(
              existingQuestionnaire.questionnaireData
            )
            : this.parseQuestionnaireFromDB(questionnaireSelected)
        )
      );
    }
    this.setState(
      {
        insuredIndex,
        questionnaireSelected,
        insuredDocNumber: insuredIdentifier,
      },
      this.getHealthQuestionnaires
    );
  }

  getHealthQuestionnaires = async () => {
    const { setLoading } = this.props;

    if (!this.state.questionnaireData) {
      let respQuest: GipQuestDto = await GipServices.getGipQuestionnaires({
        setLoading,
        loader: true,
      })
        .then((data: any) => {
          return data;
        })
        .catch((error) => {
          this.setState({
            serverError: true,
            errorMessage: `Error al obtener cuestionarios: ${error.data.message}`,
          });
          return;
        });
      this.setState(
        { questionnaireData: respQuest, isLoadingData: true },
        this.getRenderedQuest
      );
    } else {
      this.getRenderedQuest();
    }
  };

  getRenderedQuest() {
    const { questionnaireData } = this.state;
    if (questionnaireData && questionnaireData.gipQuest) {
      const rendered = this.renderGipQuestionnaire(questionnaireData);
      this.setState({ rendered });
    }

    if (this.state.isLoadingData) {
      this.state.hasPendingChanges = false;
      this.state.isLoadingData = false;
    }
  }

  renderGipQuestionnaire(
    questionnaire: GipQuestDto | undefined,
    parentId: string = ""
  ): React.ReactNode[] {
    let questionDetail: React.ReactNode[] = [];

    if (
      questionnaire &&
      questionnaire.gipQuest &&
      questionnaire.gipQuest.length > 0
    ) {
      questionnaire.gipQuest.forEach((questionnaire: GipQuestionnairesDto) => {
        //TODO: Añadir el detalle del cuestionario
        let questions: React.ReactNode[] = [];

        questionnaire.subquestionnaires.forEach(
          (question: GipSubquestionnaireDto) => {
            switch (question.data_type) {
              case "string":
                //Render pregunta con RADIO
                questions.push(
                  <div className="question">{this.renderRadio(question)}</div>
                );
                break;
              case "integer":
                questions.push(
                  <div className="question isRow">
                    {this.renderNumber(question, true)}
                  </div>
                );
                break;
              case "number":
                questions.push(
                  <div className="question isRow">
                    {this.renderNumber(question, false)}
                  </div>
                );
                break;
              default:
                break;
            }
          }
        );

        questionDetail.push(
          <div className="subquestionnaire">
            <h4>{questionnaire.questionnaire}</h4>
            <>{questions}</>
          </div>
        );
      });
    }

    return questionDetail;
  }

  renderRadio(question: GipSubquestionnaireDto): React.ReactNode[] {
    const { questionnaireSelected, allNotChecked } = this.state;
    let questions: React.ReactNode[] = [];

    const questionResponse =
      questionnaireSelected && questionnaireSelected[question.id];

    questions.push(
      <div className="question-title">
        <h6>{question.question}</h6>
        <div
          className={
            this.props.isReadOnly || allNotChecked
              ? "radioBox disabled"
              : "radioBox"
          }
        >
          <label key={"SI" + question.id}>
            <input
              key={question.id}
              type="radio"
              name={question.id}
              value={"SI"}
              disabled={this.props.isReadOnly || allNotChecked}
              checked={
                questionResponse && questionResponse.selectedValue === "SI"
                  ? true
                  : false
              }
              required={true}
              onChange={(e) => this.onAnswerRadioValueChange(question, "SI")}
            />
            <span className="recover-checkmark"> SI</span>
          </label>
        </div>
        {questionResponse &&
          questionResponse.selectedValue === "SI" &&
          this.renderTextAnswer(question)}
        <div
          className={
            this.props.isReadOnly || allNotChecked
              ? "radioBox disabled"
              : "radioBox"
          }
        >
          <label key={"NO" + question.id}>
            <input
              key={question.id}
              type="radio"
              name={question.id}
              value={"NO"}
              disabled={this.props.isReadOnly || allNotChecked}
              checked={
                questionResponse && questionResponse.selectedValue === "NO"
                  ? true
                  : false
              }
              required={true}
              onChange={(e) => this.onAnswerRadioValueChange(question, "NO")}
            />
            <span className="recover-checkmark">NO</span>
          </label>
        </div>
      </div>
    );
    return questions;
  }

  renderNumber(question: GipSubquestionnaireDto, onlyNumbers: boolean = false) {
    let { questionnaireSelected } = this.state;

    const questionResponse =
      questionnaireSelected && questionnaireSelected[question.id];

    return (
      <InputCounter
        onlyNumbers={onlyNumbers}
        key={question.id}
        onChange={(value: string) =>
          this.onAnswerTextValueChange(question, value)
        }
        value={
          questionResponse && questionResponse.response
            ? questionResponse.response
            : ""
        }
        labelText={question.question.toUpperCase()}
        disabled={this.props.isReadOnly}
        required={true}
        errorCode={questionResponse && questionResponse.errorCode}
      />
    );
  }

  renderTextAnswer(question: GipSubquestionnaireDto) {
    let { questionnaireSelected, allNotChecked } = this.state;

    const questionResponse = questionnaireSelected[question.id];

    return (
      <InputBox
        key={"sub" + question.id}
        type={"text"}
        value={questionResponse.response ? questionResponse.response : null}
        errorCode={questionResponse && questionResponse.errorCode}
        required={true}
        disabled={allNotChecked}
        cleanValue={true}
        notScroll={true}
        onChange={(value: string) =>
          this.onAnswerTextValueChange(question, value)
        }
        placeholder={question.help_text}
        labelText={question.placeholder}
        notUsePlacehoderAsLabel={true}
      />
    );
  }

  onAnswerTextValueChange(question: GipSubquestionnaireDto, response: string) {
    let { questionnaireSelected,anyChange } = this.state;

    if (question.data_type === "number" || question.data_type === "integer") {
      questionnaireSelected[question.id] = {
        response: response,
        data_type: question.data_type,
        id: question.id,
        question: question.question,
        errorCode: "",
      };
      anyChange = true
    }
    let questSelected = questionnaireSelected[question.id];
    if (questSelected) {
      questSelected.response = response;
    }

    this.setState({ questionnaireSelected,anyChange });
  }

  onAnswerRadioValueChange(question: GipSubquestionnaireDto, response: string) {
    let { questionnaireSelected, anyChange } = this.state;

    questionnaireSelected[question.id] = {
      data_type: question.data_type,
      id: question.id,
      question: question.question,
      selectedValue: response,
      errorCode: "",
    };
    anyChange = true
    this.setState({
      questionnaireSelected,
      anyChange,
      rendered: this.renderGipQuestionnaire(this.state.questionnaireData),
    });
  }

  responseAllNot(value: boolean) {
    let { questionnaireData, questionnaireSelected, allNotChecked, anyChange } =
      this.state;

    allNotChecked = value;
    anyChange =true
    if (questionnaireData && questionnaireData.gipQuest) {
      questionnaireData.gipQuest.forEach((questionnaire) => {
        questionnaire.subquestionnaires.forEach((question) => {
          if (question.data_type === "string") {
            let questValue = questionnaireSelected[question.id];

            if (questValue) {
              questValue.selectedValue = allNotChecked ? "NO" : "";
              questValue.response = "";
            } else {
              questionnaireSelected[question.id] = {
                data_type: question.data_type,
                id: question.id,
                question: question.question,
                response: "",
                selectedValue: allNotChecked ? "NO" : "",
                errorCode: "",
              };
            }
          }
        });
      });
    }

    this.setState({ questionnaireSelected, allNotChecked, anyChange }, () =>
      this.getRenderedQuest()
    );
  }

  // Navigation methods //
  onBack(forcePrevious: boolean = false) {
    if (forcePrevious || !this.state.hasPendingChanges)
      this.props.history.push(
        GipHireMenuHelper.getStepNavigationPath(
          GipHireMenuHelper.getStepFromNavigationRoute(
            this.props.match.url,
            GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
          ) - 1,
          this.props.data.insureds.lenght,
          GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
        )
      );
  }

  async onNext(forceNext: boolean = false) { 
    if (this.props.isReadOnly) {
      this.props.history.push(
        GipHireMenuHelper.getStepNavigationPath(
          GipHireMenuHelper.getStepFromNavigationRoute(
            this.props.match.url,
            GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
          ) + 1,
          this.props.data.insureds.lenght,
          GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
        )
      );
      return;
    }
    this.props.saveHiring();
    if (this.props.checkCompleted()) {
      this.props.history.push(
        GipHireMenuHelper.getStepNavigationPath(
          GipHireMenuHelper.getStepFromNavigationRoute(
            this.props.match.url,
            GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
          ) + 1,
          this.props.data.insureds.lenght,
          GipHireMenuHelper.getCurrentProduct(this.props.match.params.product)
        )
      );
    } else {
      this.setState({
        modalErrorShow: true,
        modalErrorText:
          "Se requiere COMPLETAR y GUARDAR los cuestionarios de todos los asegurados",
      });
    }
  }
  // END Navigation methods //

  showErrorModal = (text: string = "Ha ocurrido un error.") =>
    this.setState({ modalErrorShow: true, modalErrorText: text });
  hideErrorModal = () =>
    this.setState({ modalErrorShow: false, modalErrorText: "" });

  render() {
    const { data, isReadOnly } = this.props;
    const { insuredIndex, rendered, allNotChecked, serverError, errorMessage, cancelSignModalShow } =
      this.state;
    return (
      <NewHiringContainer>
        <div className="new-hiring-flow-status">
          <div className="new-hiring-flow-status-title">
            <h2>{"DATOS CLÍNICOS DE LOS ASEGURADOS"}</h2>
          </div>
        </div>

        <HealthQuestionnaireContainer>
          <div className="content-wrapper" ref={""}>
            <div className="questionnaire-special-title">
              <Title03Text>
                <p>
                  {data.insureds.length > 0
                    ? data.insureds[insuredIndex].name +
                    " " +
                    data.insureds[insuredIndex].lastname1 +
                    " " +
                    (data.insureds[insuredIndex].lastname2
                      ? data.insureds[insuredIndex].lastname2
                      : "")
                    : ""}
                </p>
              </Title03Text>
            </div>
            <div
              className={`switchBox ${this.props.isReadOnly ? "disabled" : ""}`}
            >
              <DicotomicCheckbox
                disabled={isReadOnly}
                key={"allNo"}
                initChecked={allNotChecked}
                className={"optional"}
                checkboxText={"Responder todo NO"}
                onChange={(value: boolean) => this.responseAllNot(value)}
              />
            </div>
          </div>

          <div
            className={
              this.props.isReadOnly
                ? "content-wrapper noEvents"
                : "content-wrapper"
            }
            id="mainQuestionnaire"
          >
            {rendered}
          </div>

          <div className="content-wrapper topMargin20" ref={""}>
            <div className="questionnaire-button-container">
              <div className="personal-data-buttons">
                <div className="personal-data-buttons__item">
                  <MainButton
                    withTab={true}
                    icon={true}
                    type={"white"}
                    iconImg={images.IconArrowLeftBlue}
                    text="Atrás"
                    onClick={() => this.onBack()}
                  />
                </div>
                <div className="personal-data-buttons__item">
                  <MainButton
                    text={"Siguiente"}
                    disabled={/* areQuestionnairesCompleted*/ false}
                    onClick={() => this.onNext()}
                  />
                </div>
              </div>
            </div>
          </div>
        </HealthQuestionnaireContainer>

        <LeftInfoModal
          img={images.WarningError}
          className={"error"}
          mainTitle={"Se ha producido un error"}
          mainText={this.state.modalErrorText}
          buttonText={"Cerrar"}
          active={this.state.modalErrorShow}
          onClickAccept={this.hideErrorModal}
          close={this.hideErrorModal}
          noSecondaryButton
        />

        <LeftInfoModal
          img={images.IconWarningYellow}
          mainTitle={"¿Seguro que desea continuar sin guardar?"}
          mainText={"Perderá todos los cambios introducidos."}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ previousModalShow: false })}
          active={this.state.previousModalShow}
          onClickAccept={() =>
            this.setState({ previousModalShow: false }, () => this.onBack(true))
          }
          className="error"
        />

        <LeftInfoModal
          img={images.IconWarningYellow}
          mainTitle={"¿Seguro que desea continuar sin guardar?"}
          mainText={"Perderá todos los cambios introducidos."}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ nextModalShow: false })}
          active={this.state.nextModalShow}
          onClickAccept={() =>
            this.setState({ nextModalShow: false }, () => this.onNext(true))
          }
          className="error"
        />
        <LeftInfoModal
          img={images.IconWarningYellow}
          className="error"
          mainTitle={"¿Seguro que desea continuar?"}
          mainText={"Se cancelará la firma previamente solicitada"}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ cancelSignModalShow: false })}
          active={cancelSignModalShow}
          onClickAccept={() => {
            this.setState({ cancelSignModalShow: false }, () => this.validateAndSave())
          }}
        />
        <ErrorModalContainer className={`${serverError && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{errorMessage}</p>
            </div>
            <div className="button-error">
              <MainButton
                text="Aceptar"
                onClick={() => this.setState({ serverError: false })}
              />
            </div>
          </div>
        </ErrorModalContainer>
      </NewHiringContainer>
    );
  }
}

export default withPolicy(withGeneral(withRouter(HealthQuestionnaireLayout)));
