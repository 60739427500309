import React from "react";
import InfoModalLayout from "./info-modal-layout";

export default class InfoModal extends React.Component<any, {}> {

  render() {
    const { img, className, onClickAccept, mainText, active, buttonText, close, extraText } = this.props;

    return (
      <InfoModalLayout
        className={className}
        img={img}
        onClickAccept={() => onClickAccept()}
        mainText={mainText}
        buttonText={buttonText}
        active={active}
        close={close}
        extraText={extraText}
      />
    );
  }
}

