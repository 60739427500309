import React, { Component } from 'react';
import { QuestionnaireItemContainer } from './questionnaire-item-style';
import InputBox from '../input-box/input-box';
import { chunkArray } from '../../utils';

interface QuestionnaireItemProps {
  onChange: Function;
  question: any;
  disabled: boolean;
}

interface QuestionnaireItemState {

}

class QuestionnaireItem extends Component<QuestionnaireItemProps, QuestionnaireItemState> {
  readonly state: QuestionnaireItemState;

  constructor(props: QuestionnaireItemProps) {
    super(props);
    this.state = {};
  }

  handleChange = (value: string, indexFather: number, indexSub?: number) => {
    const { onChange, question } = this.props;
    if (indexSub || indexSub === 0) {
      question.subquestionnaire[indexSub].answer.value = value.toUpperCase();
    } else {
      question.answer.value = value.toUpperCase();
    }
    onChange(question, indexFather)

  }

  renderColumn(items: any[], pos?: any) {
    if (items.length % 2 !== 0) {
      const array = new Array(2 - (items.length % 2)).fill(0);

      return array.map((i, index) =>
        <div key={`col-empty-${pos}-${index}`} className="questionnaire-fields-col col-50" />
      );
    }
  }

  renderInputs() {
    const { question, disabled } = this.props;
    const s = question.subquestionnaire.findIndex((q: any) => q.question === 'Detalle');
    const array = chunkArray(question.subquestionnaire, 2);
    const divs = [];
    // if (s === -1) {
    //   divs.push(
    //     <div className="questionnaire-fields-row">
    //       <div className="questionnaire-fields-col">
    //         <InputBox
    //           placeholder="Describa"
    //           disabled={disabled}
    //           required={question.answer.required}
    //           errorCode={question.answer.errorCode}
    //           value={question.answer.value}
    //           onChange={(value: string) => this.handleChange(value, question.index)}
    //         />
    //       </div>
    //     </div>
    //   );
    // }
    // else {
    if (s !== -1) {
      const element = question.subquestionnaire[s];
      divs.push(
        <div className="questionnaire-fields-row">
          <div className="questionnaire-fields-col">
            <div className="inputBox">
              <InputBox
                placeholder={element.question}
                disabled={disabled}
                required={element.answer.required}
                errorCode={element.answer.errorCode}
                value={element.answer.value}
                onChange={(value: string) => this.handleChange(value, question.index, element.index)}
              />
            </div>
          </div>
        </div>
      );
      array.splice(s, 1);
    }

    const rows = array.map((row, pos) => (
      <div key={`row-${pos}`} className="questionnaire-fields-row">
        {row.map(i => (
          <div key={i.code} className="questionnaire-fields-col col-50">
            <div className="inputBox">
              <InputBox
                placeholder={i.question}
                disabled={disabled}
                required={i.answer.required}
                errorCode={i.answer.errorCode}
                value={i.answer.value}
                onChange={(value: string) => this.handleChange(value, question.index, i.index)}
              />
            </div>
          </div>
        ))}
        {this.renderColumn(row, pos)}
      </div>
    ));
    divs.push(...rows);
    return divs;
  }

  render() {
    const { question, disabled, onChange } = this.props;
    return (
      <QuestionnaireItemContainer>
        <div className="questionnaire-text">
          <p>{question.question}</p>
        </div>
        <div className="questionnaire-fields-container">
          {this.renderInputs()}
        </div>
      </QuestionnaireItemContainer>
    );
  }
}

export default QuestionnaireItem;
