import React from "react";
import { LegalPolicyContainer } from "../legal-policy/legal-policy-style";
import GoBack from "../../components/go-back";
import MainTable from "../../components/main-table";
import { ErrorModalContainer } from "../../components/error-modal/error-modal-style";
import MainButton from "../../components/main-button";
import {
  changeFileName,
  downloadFile,
  getFileExtension,
} from "../../utils/utility";
import { GestDoc } from "../../services/GestDoc";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import {
  GesDocProductInfo,
  GestDocFileDataResponseDto,
  GestDocSearchFileRequestDto,
  ProductType,
} from "../../models/gest-doc-models";
import GestDocServices from "../../services/Gest-Doc.Services";
import { IUser } from "../../models/user-model";
import { COMMERCIAL_NODE_ID } from "../../services/url";
import { AREA_NAME } from "../../constants/gest-doc";
import MediatorServices from "../../services/MediatorServices";
import { acceptedFileMimeType } from "../../constants/acceptedFileMimeType";
import { roleAllowed } from "../../constants";
import ProductServices from "../../services/ProductServices";
import { GESDOC_TYPE, PRODUCT_TYPE } from "../../constants/policyType";
import GeneralServices from "../../services/GeneralServices";
import SelectBox from "../../components/select-box/select-box";
import FilterInput from "../../components/filter-input/filter-input-layout";
import DynamicProducts from "../../components/dynamic-products/DynamicProducts";
import { Scroll } from "./contract-policy-style";


interface ContractPolicyProps {
  setLoading: Function;
  user: IUser;
}

interface ContractPolicyState {
  isUploaded: boolean;
  serverError: boolean;
  serverErrorMessage: string;
  okModal: boolean,
  modalText: string,
  user: any;
  dynamiProducts: any[];
  products: any[];
  cardFilter: any[];
  cardFilterValue: string;
  nameFilterVale: string;
}
export default class ContractPolicyLayout extends React.Component<
  ContractPolicyProps,
  ContractPolicyState
> {
  constructor(props: ContractPolicyProps) {
    super(props);
    this.state = {
      isUploaded: false,
      serverError: false,
      serverErrorMessage: "",
      okModal: false,
      modalText: "",
      user: localStorage.loggedUser ? JSON.parse(localStorage.loggedUser) : "",
      dynamiProducts: [],
      products: [],
      cardFilter: [],
      cardFilterValue: '',
      nameFilterVale: '',
    };
  }
  getAllProducts = (data: any): any[] => {
    let allProducts: any[] = [];
    for (const prod in data) {
      if (data.hasOwnProperty(prod)) {
        const businessLine = data[prod];
        allProducts = allProducts.concat(businessLine.products);
      }
    }
    return allProducts;
  };
  componentDidMount = async () => {
    const args = {
      mediator_id: this.state.user.code,
      loader: false,
      setLoading: () => { }
    }
    const dynamic_products = await ProductServices.getDynamicProducts(args);

    let products = this.getAllProducts(dynamic_products);
    //
    let cardNamesSet = new Set();
    products.forEach(producto => cardNamesSet.add(producto.cardName));
    let cardNamesArray = Array.from(cardNamesSet);
    const cardsFilter = cardNamesArray.map(item => ({
      label: item,
      value: item
    }));
    cardsFilter.unshift({
      label: "Seleccionar",
      value: ""
    });

    this.setState({
      dynamiProducts: products,
      products: products,
      cardFilter: cardsFilter,
    })

  }
  downloadFileFromLink(file: string) {
    const link = document.createElement("a");
    link.href = file;
    link.download = "";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  downloadFileFromGestDocResponse(
    file: GestDocFileDataResponseDto | undefined
  ) {
    if (file && file.fileData && file.fileName && file.fileType)
      downloadFile(file.fileData, file.fileName, file.fileType, true);
    else
      this.setState({
        serverError: true,
        serverErrorMessage: "Documento no encontrado",
      });
  }

  async downloadFileFromGestDoc(nodeId: string) {
    var file = await GestDocServices.getFile(
      this.props.setLoading,
      true,
      nodeId
    ).catch((ex) => {
      return undefined;
    });
    this.downloadFileFromGestDocResponse(file);
  }

  async findAndDownloadFileFromGestDoc(request: GestDocSearchFileRequestDto) {
    var file = await GestDocServices.searchAndGetFirstFile(
      this.props.setLoading,
      true,
      request
    ).catch((ex) => {
      return undefined;
    });
    this.downloadFileFromGestDocResponse(file);
  }
  async downloadPreHiringDocs(id: number, type: string) {
    let data = {
      id,
      type
    }
    var file = await GestDocServices.preHiringDocs(
      this.props.setLoading,
      true,
      data
    ).catch((ex) => {
      return undefined;
    });
    this.downloadFileFromGestDocResponse(file);
  }
  normalizarCadena(cadena: string) {
    return cadena
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }
  searchByProduct(value: any) {
    const { cardFilterValue } = this.state

    this.setState(
      {
        nameFilterVale: value
      }
    )
    this.searchProducts(value, cardFilterValue)

  }
  searchByArea(value: any) {
    const { nameFilterVale } = this.state

    this.setState(
      {
        cardFilterValue: value
      }
    )
    this.searchProducts(nameFilterVale, value)

  }
  searchProducts(name: string, card: string) {
    const { products } = this.state
    console.log(name, card)
    const prod = products.filter(x => this.normalizarCadena(x.name).includes(this.normalizarCadena(name)) && this.normalizarCadena(x.cardName).includes(this.normalizarCadena(card)))
    this.setState({
      dynamiProducts: prod
    })
  }
  async downloadNotaInformativaMediador(productType: string) {
    var file = await GestDocServices.getNotaInformativaMediador(
      this.props.setLoading,
      true,
      productType
    ).catch((ex) => {
      return undefined;
    });
    this.downloadFileFromGestDocResponse(file);
  }

  async searchNodeAndGetInfo(productType: string) {
    var response = await GestDocServices.searchNodeAndGetInfo(
      this.props.setLoading,
      true,
      productType
    ).catch((ex) => {
      return undefined;
    });
    return response.data
  }
  getInputFileAccept = (): string => {
    const file = GestDoc.getNotaInformativaLifeMediadorLocalStorage();
    if (file) {
      return GestDoc.getNotaInformativaLifeMediadorLocalStorage().extension ===
        "pdf"
        ? "application/pdf"
        : "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    } else {
      return acceptedFileMimeType.toString();
    }
  };

  async onFileSelected(
    event: React.ChangeEvent<HTMLInputElement>,
    areaName: string
  ) {
    this.setState({ isUploaded: false });

    if (event.target.files && event.target.files.length) {

      let file = event.target.files[0];
      if (event.target && event.target.value) {
        event.target.value = "";
        event.target.files = null;
      }
      const fileExtension = getFileExtension(file.name);

      const currentNotaInformativa = await GestDoc.searchNodeAndGetInfo(
        GestDoc.getNotaInformativaMediadorCommercialRequest(
          this.props.user.code,
          areaName === AREA_NAME["Vida"] ? true : false
        ),
        this.props.setLoading
      );
      if (currentNotaInformativa) {
        // update note informative
        if (
          GestDoc.isFileExtensionAllowed(
            fileExtension,
            currentNotaInformativa
              ? getFileExtension(currentNotaInformativa.entry.name)
              : "pdf"
          )
        ) {
          const newFileName = GestDoc.getNotaInformativaMediadorNewName(
            this.props.user.code,
            areaName === AREA_NAME["Vida"] ? true : false,
            fileExtension
          );
          file = changeFileName(file, newFileName);

          const result = await GestDoc.uploadNewVersionDocument(
            currentNotaInformativa.entry.id,
            file,
            this.props.setLoading
          );
          if (result) {
            GestDoc.setNotaInformativaLifeMediadorLocalStorage({
              id: result.entry.id,
              extension: getFileExtension(result.entry.name),
            });
            this.setState({ isUploaded: true, okModal: true, modalText: "Documento subido correctamente" });
          } else {
            this.setState({
              serverError: true,
              serverErrorMessage: ERROR_MESSAGES["unknow-error"],
            });
          }
        } else {
          this.setState({
            serverError: true,
            serverErrorMessage: `Solo se permiten archivos con la extensión "${getFileExtension(
              currentNotaInformativa.entry.name
            )}".`,
          });
        }
      } else {
        //put note informative
        if (GestDoc.isFileExtensionAllowed(fileExtension)) {

          const newFileName = GestDoc.getNotaInformativaMediadorNewName(
            this.props.user.code,
            areaName === AREA_NAME["Vida"] ? true : false,
            fileExtension
          );
          file = changeFileName(file, newFileName);

          let mediator: any = localStorage.loggedMediator
            ? JSON.parse(localStorage.loggedMediator)
            : "";
          if (!mediator)
            mediator = await MediatorServices.getMediatorInfo({
              setLoading: () => { },
              loader: true,
              mediatorCode: this.props.user.code,
            });

          const metadata =
            areaName === AREA_NAME["Vida"]
              ? GestDoc.setNotaInformativaMediadorRequest(
                this.props.user.code,
                mediator,
                true
              ).fileInfo
              : GestDoc.setNotaInformativaMediadorRequest(
                this.props.user.code,
                mediator,
                false
              ).fileInfo;

          const tempalteInfo = await this.searchNodeAndGetInfo(PRODUCT_TYPE.TRANQUILITY);
          const result = await GestDoc.uploadDocument(
            COMMERCIAL_NODE_ID,
            file,
            metadata,
            this.props.setLoading,
            tempalteInfo
          );
          if (result) {
            if (areaName === AREA_NAME["Vida"]) {
              GestDoc.setNotaInformativaLifeMediadorLocalStorage({
                id: result.entry.id,
                extension: getFileExtension(result.entry.name),
              });
            } else {
              GestDoc.setNotaInformativaNotLifeMediadorLocalStorage({
                id: result.entry.id,
                extension: getFileExtension(result.entry.name),
              });
            }

            //this.setState({ selectedFile: file, isUploaded: true });
          } else {
            this.setState({
              serverError: true,
              serverErrorMessage: ERROR_MESSAGES["unknow-error"],
            });
          }
        } else {
          this.setState({
            serverError: true,
            serverErrorMessage:
              'Solo se permiten archivos con las extensiones "pdf" y "docx".',
          });
        }
      }
    }
  }

  getRows(setLoading: any) {
    const { dynamiProducts } = this.state;
    const rows = dynamiProducts.map(prod => {
      // El id del producto, es prod.id[0] por lo que obtenemos todos los metadatos
      return {
        product: {
          text: prod.name,
          type: "",
          key: "highlighted"
        },
        file: {
          key: "button",
          text: "Descargar",
          type: "",
          onClick: async () => this.downloadPreHiringDocs(prod.id[0], GESDOC_TYPE.NIP)
        },
        file2: {
          key: "button",
          text: "Descargar",
          type: "",
          onClick: async () => this.downloadPreHiringDocs(prod.id[0], GESDOC_TYPE.DIP)
        }
      }
    })

    return rows
  }



  render() {
    const { setLoading, user } = this.props;



    return (
      <LegalPolicyContainer>
        <div className="go-back-container">
          <GoBack />
        </div>
        <div className="legal-title-selection">
          <p>Previo a la Contratación</p>
        </div>

        <div className="legal-text">
          <p>
            El mediador se encuentra obligado a dar cumplimiento al deber previo
            de información en la contratación de cada seguro y exigida por la
            LOSSEAR, RDOSSEAR y normativa de distribución aplicable, acreditando
            su entrega y asumiendo las consecuencias en caso de incumplimiento
            (nota informativa de producto, DIP y nota informativa del mediador).
          </p>
        </div>
        <div className="colSin-100" style={{ marginBottom: '24px' }}>
          <div className="colSin-33 rightPadding20">
            <div className="selectBox">
              <FilterInput
                placeholder={"Producto"}
                onChange={(value: string) => this.searchByProduct(value)}
              />
            </div>
          </div>
          <div className="colSin-33 rightPadding20">
            <div className="selectBox">
              <SelectBox
                optionsText={this.state.cardFilter}
                optionKey={"value"}
                optionValue={"label"}
                onChange={(value: string) => this.searchByArea(value)}
              />
              <label>Área</label>
            </div>
          </div>
        </div>
        <Scroll className="colSin-100">
          <div>
          <MainTable
            className="table noZebra pCont"
            columns={[{
              text: "Producto",
              filter: false,
              key: "highlighted"
            },
            {
              text: "Nota Informativa Producto",
              filter: false,
            },
            {
              text: "DIP",
              filter: false,
            }]}
            rows={this.getRows(setLoading)}
            keysRow={["product", "file", "file2"]}
          />
          </div>

        </Scroll>
        <div className="legal-text notaInformativa">
          <h2>Nota informativa del mediador</h2>
          {user.roles && !user.roles.includes(roleAllowed) && (
            <>
              <MainButton
                text={"Desc. Plantilla Vida"}
                onClick={() =>
                  this.downloadNotaInformativaMediador(
                    PRODUCT_TYPE.TRANQUILITY
                  )
                }
              />
              <label className="custom-file-upload">
                <input
                  type="file"
                  onChange={(e) => this.onFileSelected(e, AREA_NAME["Vida"])}
                  accept={this.getInputFileAccept()}
                />
                <MainButton
                  text={"Sub. Documento Vida"}
                  onClick={() => {
                    return true;
                  }}
                />
              </label>

              <MainButton
                text={"Desc. Documento Vida"}
                onClick={() =>
                  this.downloadNotaInformativaMediador(
                    PRODUCT_TYPE.TRANQUILITY
                  )
                }
              />
            </>
          )}
        </div>
        <div className="legal-text notaInformativa">
          <MainButton
            text={"Desc. Plantilla No Vida"}
            onClick={() =>
              this.downloadNotaInformativaMediador((
                PRODUCT_TYPE.HEALTH
              )
              )
            }
          />
          <label className="custom-file-upload">
            <input
              type="file"
              onChange={(e) => this.onFileSelected(e, "")}
              accept={this.getInputFileAccept()}
            />
            <MainButton
              text={"Sub. Documento No Vida"}
              onClick={() => {
                return true;
              }}
            />
          </label>
          {
            //TODO: Falta la subida de esta nota
          }
          <MainButton
            text={"Desc. Documento No Vida"}
            onClick={() =>
              this.findAndDownloadFileFromGestDoc(
                GestDoc.getNotaInformativaMediadorCommercialRequest(
                  this.props.user.code,
                  false
                )
              )
            }
          />
        </div>
        <p className="warning">
          {" "}
          En cumplimiento de lo establecido en el artículo 173 del Real
          Decreto-Ley 3/2020, de 4 de febrero le informamos que es
          responsabilidad del mediador la entrega de su nota informativa al
          tomador/asegurado así como sus actualizaciones, sin que, la
          utilización de este portal le exima de comprobar el cumplimiento de
          esta obligación
        </p>



        <ErrorModalContainer
          className={`${this.state.okModal && `active`}`}
        >
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalText}</p>
            </div>
            <div className="button-error">
              <MainButton
                text="Aceptar"
                onClick={() => this.setState({ okModal: false, modalText: '' })}
              />
            </div>
          </div>
        </ErrorModalContainer>

        <ErrorModalContainer
          className={`${this.state.serverError && `active`}`}
        >
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.serverErrorMessage}</p>
            </div>
            <div className="button-error">
              <MainButton
                text="Aceptar"
                onClick={() => this.setState({ serverError: false })}
              />
            </div>
          </div>
        </ErrorModalContainer>
      </LegalPolicyContainer >
    );
  }
}
