import GeneralServices from "./GeneralServices";
import { BasicDataModifications } from "./dto/request/SendBasicDataModificationsEmail.dto";
import { getPolicyData } from "./utils";

class SupplementsServices {
  getSupplements = async (args: any) => {
    try {
        const response = await GeneralServices.get({
          setLoading: args.setLoading,
          loader: args.loader === false ? false : true,
          endpoint: `supplements`,
        });
      return response.data;

    } catch (err) {
      return { items: [], count: 0 };
    }
  };

  sendNewInsuranceEmail = async (data: any) => {
    const formData = new FormData();
    if (data.documents && data.documents.uploadDocuments) {
      data.documents.uploadDocuments.forEach((doc: {type: string, description: string, file: File}) => {
        if (doc.file && doc.file instanceof File) {
          formData.append('files', doc.file, doc.file.name);
        }
      });
    }
  
    formData.append('taker', JSON.stringify(data.taker));
    formData.append('policyData', JSON.stringify(getPolicyData()));
    formData.append('address', JSON.stringify(data.address));
    formData.append('contact', JSON.stringify(data.contact));
    formData.append('guarantees', JSON.stringify(data.guarantees));
    formData.append('documents', JSON.stringify(data.documents));
    formData.append('clinicalData', JSON.stringify(data.clinicalData));
  
    try {
      const response = await GeneralServices.postFormData({
        setLoading: () => {},
        isSlowProcess: true,
        loader: true,
        endpoint: "supplements/upload-new-insurance",
        data: formData,
      });
      return response.data;
    } catch (err) {
      return err;
    }
  };

  sendBasicDataModificationsEmail = async (data: BasicDataModifications) => {
    const formData = new FormData();
    if (data.documents && data.documents.uploadDocuments) {
      data.documents.uploadDocuments.forEach((doc: {type: string, description: string, file: File}) => {
        if (doc.file && doc.file instanceof File) {
          formData.append('files', doc.file, doc.file.name);
        }
      });
    }
  
    formData.append('policyData', JSON.stringify(getPolicyData()));
    formData.append('data', JSON.stringify(data));
  
    try {
      const response = await GeneralServices.postFormData({
        setLoading: () => {},
        isSlowProcess: true,
        loader: true,
        endpoint: "supplements/basic-data-modification",
        data: formData,
      });
      return response.data;
    } catch (err) {
      return err;
    }
  };
}
export default new SupplementsServices();
