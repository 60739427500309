import React, { FC } from 'react'
import { TakerDataContainer } from '../../../../hirings/new-hiring/health/health-taker/taker-data-style'
import TakerPersonalInformation from '../../components/personal-information/taker-personal-information'
import { UseFormFunctionsProps } from '../../hooks/hooks.types'
import AddressForm from '../../components/address/address-form'
import ContactForm from '../../components/contact/contact-form'

const TakerStep: FC<UseFormFunctionsProps> = ({ values, errors, handleChange, handleSubmit, customError, setCustomError  }) => {
  return (
    <TakerDataContainer>
    <div className="colSin-100">
      <div className="data-flow-status">
        <div className="data-flow-status-title">
          <h2>DATOS DEL ASEGURADO</h2>
        </div>
      </div>

      <TakerPersonalInformation values={values} errors={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
      <AddressForm values={values} errors={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
      <ContactForm values={values} errors={errors} handleChange={handleChange} handleSubmit={handleSubmit} customError={customError} disableValidation={true} setCustomError={setCustomError}/>

    </div>

  </TakerDataContainer>
  )
}

export default TakerStep