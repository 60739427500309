import styled from "styled-components";
import { colors } from "../../../../assets";

export const ModalAddConditionContainer = styled.div`
  display: flex;
  color: ${colors["PR-001-100"]};
  position: fixed;
  z-index: 100;
  background: ${colors["background-modal"]};
  left: 0;
  width: 100%;
  top: 0;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  height: 100%;
  overflow: scroll;

  .modal-add-condition-more {
    font-family: Museo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #6cc9f0;
    cursor: pointer;
  }

  .modal-add-condition-step {
    color: ${colors["PR-001-100"]};
    font-family: Museo Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
    margin-left: 82px;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .modal-add-condition-aux-container {
    padding: 100px;
    height: 100%;
  }

  .modal-add-condition-container {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 20px 22px 40px 40px;
    max-width: 620px;
    width: 620px;
    /* max-height: 90vh;
    overflow: hidden; */
  }

  .modal-add-condition-close {
    text-align: right;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .modal-add-condition-header {
    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      &__icon {
        display: flex;
        background: rgba(179, 202, 220, 0.3);
        width: 62px;
        min-width: 62px;
        height: 62px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;

        img {
          max-width: 20px;
        }
      }
      &__text {
        margin-left: 20px;
      }
    }
  }

  .modal-add-condition-subtitle {
    margin-top: 8px;
    margin-bottom: 33px;
    margin-left: 82px;
    font-family: Museo Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: ${colors["TX-001-50"]};
  }

  .modal-add-condition-wrapper {
    padding-right: 54px;
  }

  .modal-add-condition-main {
    margin-left: 82px;
    &__field {
      margin-bottom: 38px;
    }
    &__title {
      padding-right: 12px;
      margin-bottom: 34px;
      font-family: Museo Sans;
      font-style: normal;
      margin-left: 14px;
      font-size: 18px;
      line-height: 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        height: 24px;
        width: 24px;
        cursor: pointer;
      }
    }
  }

  .modal-add-condition-buttons {
    margin-left: 82px;
    margin-top: 56px;
    display: flex;
    &__item {
      width: 160px;
    }
  }

  .modal-add-condition-accordion {
    display: flex;
    height: 54px;
    color: #004f8b;
    background: #b3cadc33;
    padding: 14px;
    line-height: 26px;
    margin-bottom: 34px;
    justify-content: space-between;
    cursor: pointer;
    &__title {
      font-weight: normal;
      font-size: 18px;
    }
    &__arrow {
      transition: all 0.3s;
      display: flex;
      align-items: center;
    }
  }

  .opened {
    transition: all 0.3s;
    transform: rotate(180deg);
  }

  .modal-add-condition-accordion:not(:first-child) {
    margin-top: 40px;
  }

  .modal-col-50 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
  .modal-col-100 {
    display: grid;
    grid-template-columns: 1fr;
  }
`;
