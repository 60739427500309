import React, { useState, useEffect } from "react";
import moment from "moment";

import NotificationsTable from "./notifications-table";
import SelectBox from "../../../components/select-box/select-box";
import CalendarBox from "../../../components/calendar-box/calendar-box";
import InputBox from "../../../components/input-box/input-box";
import DicotomicCheckbox from "../../../components/dicotomic-checkbox/dicotomic-checkbox";
import MainButton from "../../../components/main-button";
import LeftInfoModal from "../../../components/left-info-modal";
import { Textarea } from "../../../components/form";
import { ManagerContainer } from "./manager-style";

import { priority, MAX_CHARACTERS, STATUS, MEDIATOR } from "../../../constants/communications";
import { images } from "../../../assets";
import { getDateFormat } from "../../../utils/time";
import { getUserCode } from "../../../utils/mediatorCode";
import LineaNegocioServices from "../../../services/LineaNegocioServices";
import CommunicationsServices from "../../../services/CommunicationsServices";
import {
  InitialNotificationType,
  NotificationType,
  getAddNotificationFormat,
  getMediatorSelect,
  getLineaNegocioSelect,
  getTypesSelect,
  getShowNotificationsFormat,
  sortByPriority,
} from "../helpers";
import { withGeneral } from "../../../context/mixin/with-general";
import { IUpdateNotification } from "../../../services/CommunicationsServices-dto";

interface SelectType {
  label: string;
  value: string;
  [key: string]: string;
}

interface ErrorType {
  [key: string]: string;
}

interface ManagerProps {
  setLoading: Function;
}

function Manager(props: ManagerProps) {
  const initialNotificationValue = {
    dateEnd: getDateFormat(new Date(), "DD/MM/YYYY"),
    dateInit: getDateFormat(new Date(), "DD/MM/YYYY"),
    description: "",
    lineaNegocio: "",
    mediator: "",
    priority: "0",
    resumen: "",
    status: STATUS.ACTIVE.toString(),
    type: "",
  };

  const [lineasNegocio, setLineasNegocio] = useState<SelectType[]>([]);
  const [types, setTypes] = useState<SelectType[]>([]);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [notification, setNotification] = useState<InitialNotificationType>(initialNotificationValue);
  const [idToRemove, setIdToRemove] = useState("");
  const [errors, setErrors] = useState<ErrorType>({});
  const [fetchError, setFetchError] = useState("");

  const servicesArgs = { loader: true, setLoading: props.setLoading };

  useEffect(() => {
    getLineasNegocio();
    getNotifications();
    getTypes();
  }, []);

  const getLineasNegocio = async () => {
    const response = await LineaNegocioServices.getAll(servicesArgs);
    if (response.ok) {
      const lineasNegocioSelect = getLineaNegocioSelect(response.data);
      setLineasNegocio(lineasNegocioSelect);
    } else {
      setFetchError(response.message);
    }
  };

  const getNotifications = async () => {
    const response = await CommunicationsServices.getAll(servicesArgs);
    if (response.ok) {
      const notificationsList = getShowNotificationsFormat(response.data);
      const sortedNotifications = sortByPriority(notificationsList);
      setNotifications(sortedNotifications);
    } else {
      setFetchError(response.message);
    }
  };

  const getTypes = async () => {
    const response = await CommunicationsServices.getTypes(servicesArgs);
    if (response.ok) {
      const types = getTypesSelect(response.data);
      setTypes(types);
    } else {
      setFetchError(response.message);
    }
  };

  const handleOnChange = (key: string, value: string) => {
    const newNotification = { ...notification, [key]: value };
    if (key === "mediator" && value === MEDIATOR.OCASO.value) {
      // Agenge Ocaso solo puede ver ramo salud
      const ocasoLineaNegocio = lineasNegocio.find((item) => item.label.toLowerCase() === "salud");
      newNotification.lineaNegocio = ocasoLineaNegocio ? ocasoLineaNegocio.value : "";
    }

    setNotification(newNotification);
    if (Object.keys(errors).length > 0) {
      const newErrors = { ...errors };
      delete newErrors[key];
      setErrors(newErrors);
    }
  };

  const handleOnSave = async () => {
    const isValidNotification = validateNotification();

    if (isValidNotification) {
      const { id } = notification;
      const codUser: string = getUserCode() || "";
      const newNotification = getAddNotificationFormat({ ...notification, codUser });

      if (id) {
        const updateNotification: IUpdateNotification = { ...newNotification, id: Number(id) };
        const response = await CommunicationsServices.update({
          ...servicesArgs,
          notification: updateNotification,
          id,
        });
        if (response.error) setFetchError(response.message);
      } else {
        const response = await CommunicationsServices.create({
          ...servicesArgs,
          notification: newNotification,
        });
        if (response.error) setFetchError(response.message);
      }
      getNotifications();
      setNotification(initialNotificationValue);
    }
  };

  const handleOnRemove = async () => {
    const notificationToRemove = notifications.find((notification) => notification.id === idToRemove);

    if (notificationToRemove) {
      const newNotification = getAddNotificationFormat({
        ...notificationToRemove,
        status: STATUS.DELETED.toString(),
      });
      const response = await CommunicationsServices.delete({
        ...servicesArgs,
        notification: { ...newNotification, id: Number(idToRemove) },
        id: idToRemove,
      });
      if (response.error) setFetchError(response.message);
      getNotifications();
    }
  };

  const handleOnEdit = async (notification: NotificationType) => {
    setNotification(notification);
    setErrors({});
  };

  const validateNotification = () => {
    const newErrors: ErrorType = {};
    const requiredFields = [
      "dateEnd",
      "dateInit",
      "description",
      "lineaNegocio",
      "mediator",
      "priority",
      "resumen",
      "status",
      "type",
    ];
    Object.keys(notification).forEach((key) => {
      if (requiredFields.includes(key) && !notification[key]) {
        newErrors[key] = "required";
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const mediatorSelectOptions = getMediatorSelect();
  const ocasoSelectOptions = lineasNegocio.filter((item) => item.label === "Salud");

  return (
    <ManagerContainer>
      <h2>GESTOR DE NOTIFICACIONES</h2>
      <div className="form-wrapper">
        <div className="form-row">
          <div className="selectBox">
            <SelectBox
              errorCode={errors && errors.mediator ? errors.mediator : ""}
              onChange={(value) => handleOnChange("mediator", value)}
              optionKey="value"
              optionsText={mediatorSelectOptions}
              optionValue="label"
              placeholder="Seleccionar"
              required
              initialValue={notification.mediator}
            />
            <label>Tipo de mediador</label>
          </div>

          <div className="selectBox">
            <SelectBox
              errorCode={errors && errors.lineaNegocio ? errors.lineaNegocio : ""}
              onChange={(value) => handleOnChange("lineaNegocio", value)}
              optionKey="value"
              optionsText={notification.mediator === MEDIATOR.OCASO.value ? ocasoSelectOptions : lineasNegocio}
              optionValue="label"
              placeholder="Seleccionar"
              required
              initialValue={notification.lineaNegocio}
            />
            <label>Tipo de ramo</label>
          </div>

          <div className="selectBox">
            <SelectBox
              placeholder="Seleccionar"
              required
              errorCode={errors && errors.type ? errors.type : ""}
              optionsText={types}
              optionKey="value"
              optionValue="label"
              onChange={(value) => handleOnChange("type", value)}
              initialValue={notification.type}
            />
            <label>Tipo de notificación</label>
          </div>

          <div className="inputBox">
            <CalendarBox
              initialValue={notification.dateInit}
              required
              errorCode={errors && errors.dateInit ? errors.dateInit : ""}
              minDate={moment().add(-1, "day").toISOString(true)}
              onChange={(value) => handleOnChange("dateInit", value)}
            />
            <label>Fecha inicio</label>
          </div>

          <div className="inputBox">
            <CalendarBox
              initialValue={notification.dateEnd}
              labelText=""
              required
              errorCode={errors && errors.dateEnd ? errors.dateEnd : ""}
              minDate={
                notification.dateInit
                  ? moment(notification.dateInit, "DD/MM/YYYY").add(-1, "seconds").toISOString(true)
                  : moment().toISOString()
              }
              onChange={(value) => handleOnChange("dateEnd", value)}
            />
            <label>Fecha hasta</label>
          </div>

          <div className="selectBox">
            <SelectBox
              placeholder="Seleccionar"
              required
              errorCode={errors && errors.priority ? errors.priority : ""}
              optionsText={priority}
              optionKey="value"
              optionValue="label"
              onChange={(value) => handleOnChange("priority", value)}
              initialValue={notification.priority}
            />
            <label>Orden notificación</label>
          </div>
        </div>
        <div className="inputBox">
          <InputBox
            placeholder={`Resumen de notificación (máximo recomendado ${MAX_CHARACTERS.RESUMEN} caracteres) - ${
              MAX_CHARACTERS.RESUMEN - notification.resumen.length
            }`}
            type="text"
            value={notification.resumen}
            required
            errorCode={errors && errors.resumen ? errors.resumen : ""}
            onChange={(value) => handleOnChange("resumen", value)}
            maxLength={MAX_CHARACTERS.RESUMEN}
          />
        </div>
        <div className="textarea">
          <Textarea
            label={`Descripción de notificación (máximo ${MAX_CHARACTERS.DESCRIPTION} caracteres) - ${
              MAX_CHARACTERS.DESCRIPTION - notification.description.length
            }`}
            rows={4}
            onChange={(value) => handleOnChange("description", value)}
            value={notification.description}
            required
            errorCode={errors && errors.description ? errors.description : ""}
            maxLength={MAX_CHARACTERS.DESCRIPTION}
          />
        </div>

        <div className="save-line">
          <div className="inputBox">
            <DicotomicCheckbox
              checkboxText="Guardar notificación como borrador"
              onChange={(value: boolean) =>
                handleOnChange("status", value === true ? STATUS.DRAFT.toString() : STATUS.ACTIVE.toString())
              }
              initChecked={notification.status === STATUS.DRAFT.toString()}
              className="optional"
            />
          </div>
          <div className="button-container">
            <MainButton text="Guardar" onClick={handleOnSave} />
          </div>
        </div>
      </div>

      {notifications.length > 0 && (
        <NotificationsTable
          notifications={notifications}
          editNotification={handleOnEdit}
          setIdToRemove={setIdToRemove}
          lineasNegocio={lineasNegocio}
          types={types}
        />
      )}

      <LeftInfoModal
        img={images.IconInfoBlue}
        className="info"
        mainTitle="¿Quieres eliminar esta notificación?"
        buttonText="Aceptar"
        onClickAccept={handleOnRemove}
        close={() => setIdToRemove("")}
        active={idToRemove}
      />

      <LeftInfoModal
        img={images.IconAlertRed}
        className="error"
        mainTitle="Error"
        mainText={fetchError}
        buttonText="Cerrar"
        onClickAccept={() => setFetchError("")}
        close={() => setFetchError("")}
        active={fetchError}
        noSecondaryButton
      />
    </ManagerContainer>
  );
}

export default withGeneral(Manager);
