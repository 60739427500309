import styled from "styled-components";
import { colors } from "../../assets";

export const CampaignsConfigContainer = styled.div`
  font-family: Museo Sans;
  font-style: normal;
  font-weight: 500;

  .campaigns-config-section-title {
    margin-bottom: 75px;
    color: #004f8b;
  }

  .campaigns-config-filter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 75px;
    &__item {
      width: 100%;
      max-width: 160px;
    }
  }

  .campaigns-config-table {
    margin-bottom: 75px;
  }

  .campaigns-config-pagination {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
  }
`;
