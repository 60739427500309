import React, { Component } from "react";
import { HealthPricingSimulationPaymentOptionDto } from "../../services/dto/response/GipSimulationResponse.dto";
import { formatter } from "../../utils/utility";
import { GipInsuredContainer } from "./gip-insured-style";

interface GipInsuredResultProps {
  age: number;
  birthDate: string;
  checked: boolean;
  id: number;
  index: number;
  paymentType: string,
  totalPrice: number,
  name: string,
  priceData: HealthPricingSimulationPaymentOptionDto[],
  onClick: Function;
}

interface GipInsuredResultState {
  checked: boolean;
}

class GipInsuredResultItem extends Component<GipInsuredResultProps, GipInsuredResultState> {


  constructor(props: GipInsuredResultProps) {
    super(props)
    this.state = { checked: this.props.checked }
  }

  handleOnCheck = () => {
    this.setState({ checked: !this.state.checked });
  }

  render() {

    const {
      age,
      birthDate,
      id,
      paymentType,
      totalPrice,
      priceData,
      name
    } = this.props


    return (
      <GipInsuredContainer>
        <div className="tab">
          <input type="checkbox" checked={this.state.checked} onChange={() => this.handleOnCheck()} />
          <div className="tab-label">
            <span className="col-20">{name}</span>
            <span className="col-20">{birthDate}</span>
            <span className="col-20">{age} años</span>
            <span className="col-30">{formatter.format(totalPrice)} / {paymentType}</span>
          </div>
          <div className="tab-content">
            <div className="tabs">
              <label className="tab-label-title col-100">
                <div className="col-20">Periodicidad</div>
                <div className="col-80">
                  <div className="result-simulation-special-row">
                    <div className="col-30">Prima Neta</div>
                    <div className="col-35">Prima Neta por Mes</div>
                    <div className="col-35">Prima Neta por Año</div>
                  </div>
                </div>
              </label>
              {priceData.map((priceOption: HealthPricingSimulationPaymentOptionDto) => (
                priceOption.DesFormaPago === paymentType ?
                  <label className="tab-content-table selected-price col-100">
                    <div className="col-20">{priceOption.DesFormaPago}</div>
                    <div className="col-80">
                      <div className="result-simulation-special-row">
                        <div className="col-30 amount">{formatter.format(priceOption.TotalPrimaNeta)}</div>
                        <div className="col-35 amount">{formatter.format(priceOption.TotalPrimaNetaMes)}</div>
                        <div className="col-35 amount">{formatter.format(priceOption.TotalPrimaNetaAnio)}</div>
                      </div>
                    </div>
                  </label>
                  :
                  <label className="tab-content-table col-100">
                    <div className="col-20">{priceOption.DesFormaPago}</div>
                    <div className="col-80">
                      <div className="result-simulation-special-row">
                        <div className="col-30 amount">{formatter.format(priceOption.TotalPrimaNeta)}</div>
                        <div className="col-35 amount">{formatter.format(priceOption.TotalPrimaNetaMes)}</div>
                        <div className="col-35 amount">{formatter.format(priceOption.TotalPrimaNetaAnio)}</div>
                      </div>
                    </div>
                  </label>
              ))}
            </div>
          </div>
        </div >

      </GipInsuredContainer >
    )

  }
}

export default GipInsuredResultItem;



