import styled from "styled-components";
import { colors } from "../../../assets";

export const IconButtonWrapper = styled.div`
  button {
    border: none;
    text-decoration: none;
    background-color: ${colors["background-modal-icon"]};
    width: 50px;
    height: 50px;
    padding: 5px;
    text-align: center;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;
    }

    img {
      max-width: 100%;
    }
  }
`;
