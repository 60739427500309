import { images } from "../../../../assets"


export interface modalCotent {
    action_text: string,
    title: string,
    text: string,
    text_button: string,
    close_button?: string,
    modal_class_name: string,
    img: string,
    showEmailModal: boolean,
    showLeftInfoModal: boolean
}

export const MODAL_SEND_MAIL_PDF: modalCotent = {
    action_text: "SendPDFMail",
    title: "Envío del PDF",
    text: "Por favor, ingrese un email para el envio del PDF",
    text_button: "Enviar",
    modal_class_name: "",
    img: images.IconContactCollapsedWhite,
    showEmailModal: true,
    showLeftInfoModal: false
}
export const MODAL_DOWNLOAD_PDF: modalCotent = {
    action_text: "DownloadPDF",
    title: "Descargar PDF",
    text: "Por favor, ingrese un email",
    text_button: "Descargar",
    modal_class_name: "",
    img: images.IconDownloadWhite,
    showEmailModal: true,
    showLeftInfoModal: false
}
export const MODAL_SEND_MAIL_PDF_OK: modalCotent = {
    action_text: 'SendPDFMailOK',
    title: 'El pdf se ha enviado por email',
    text: '<div><p className="bottomMargin20">El presupuesto se ha enviado satisfactoriamente al email suministrado.</p> <br> <p className="bottomMargin5">La simulación se ha guardado en simulaciones pendientes.</p> <br> <p className="bottomMargin5">Tiene una validez de 30 días.</p></div>',
    text_button: "Ir a pendientes",
    close_button: 'Cerrar',
    modal_class_name: "",
    img: images.IconContactCollapsedWhite,
    showEmailModal: false,
    showLeftInfoModal: true
}
export const MODAL_DOWNLOAD_PDF_OK: modalCotent = {
    action_text: 'DonloadPDFOK',
    title: 'El pdf se está descargando',
    text: '<div><p className="bottomMargin20">El presupuesto se está descargando, compruebe la carpeta de descargas.</p> <br> <p className="bottomMargin5">La simulación se ha guardado en simulaciones pendientes.</p> <br> <p className="bottomMargin5">Tiene una validez de 30 días.</p></div>',
    text_button: "Ir a pendientes",
    close_button: 'Cerrar',
    modal_class_name: "",
    img: images.IconDownloadWhite,
    showEmailModal: false,
    showLeftInfoModal: true,
}
export const MODAL_SAVE_SIMULATION_KO: modalCotent = {
    action_text: 'SaveSimulationKO',
    title: 'No se ha podido guardar la simulación',
    text: 'Se ha producido un error, verifique que haya ingresado un email válido',
    text_button: 'Cerrar',
    close_button: '',
    modal_class_name: "error",
    img: images.WarningError,
    showEmailModal: false,
    showLeftInfoModal: true
}

export const MODAL_SEND_PDF_KO: modalCotent = {
    action_text: 'SendPDFKO',
    title: 'No se ha podido enviar el PDF',
    text: 'Se ha producido un error, verifique que haya ingresado un email válido',
    text_button: 'Cerrar',
    close_button: '',
    modal_class_name: "error",
    img: images.WarningError,
    showEmailModal: false,
    showLeftInfoModal: true
}
export const MODAL_DOWNLOAD_PDF_KO: modalCotent = {
    action_text: 'DownloadPDFKO',
    title: 'No se ha podido descargar la simulación',
    text: 'Se ha producido un error, verifique que haya ingresado un email válido',
    text_button: 'Cerrar',
    close_button: '',
    modal_class_name: "error",
    img: images.WarningError,
    showEmailModal: false,
    showLeftInfoModal: true
}
