import GeneralServices from "./GeneralServices";

class AddressServices {
  getProvinces = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader && (args.loader === false ? false : true),
        endpoint: `address?search=province${
          args.params ? "&" + args.params.join("&") : ""
        }`
      });
      return response.data;
    } catch (err) {
      return [];
    }
  };

  getCities = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader && (args.loader === false ? false : true),
        endpoint: `address?search=city${
          args.params ? "&" + args.params.join("&") : ""
        }`
      });
      return response.data;
    } catch (err) {
      return [];
    }
  };

  getStreets = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader && (args.loader === false ? false : true),
        endpoint: `address?search=street${
          args.params ? "&" + args.params.join("&") : ""
        }`
      });
      return response.data;
    } catch (err) {
      return [];
    }
  };

  getPostalCodes = async (args: any) => {
    try {
      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader && (args.loader === false ? false : true),
        endpoint: `address?search=zip${
          args.params ? "&" + args.params.join("&") : ""
        }`
      });
      return response.data;
    } catch (err) {
      return [];
    }
  };

  getAddressCheck = async (args: any) => {
    try {
      let city = args.city ? args.city : '';
      let house = args.house ? args.house : '';
      let street = args.street ? args.street : '';
      let zip = args.zip ? args.zip : '';

      if (city == '' || house == '' || street == '' || zip == '') {
        return [];
      }

      const response = await GeneralServices.get({
        setLoading: args.setLoading,
        loader: args.loader && (args.loader === false ? false : true),
        endpoint: `address/check?city=${args.city}&house=${args.house}&street=${args.street}&zip=${args.zip}`
      });
      return response.data;
    } catch (err) {
      return err;
    }
  };
  addStreetCode(data: any) {
    let streetFormated = data.address.street.value.value;
    if (data.address.street.value && data.address.street.value.extra && data.address.street.value.extra.street && data.address.street.value.extra.street.code) {
      streetFormated = data.address.street.value.extra.street.code + " " + streetFormated;
    }
    return streetFormated;
  }
}

export default new AddressServices();
