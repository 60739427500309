import React from "react";
import { images } from "../../../assets/images";
import TooltipBox from "../../tooltip-box/tooltip-box";
import { LabelTooltipContainer } from "./LabelTooltip.style";

type Props = {
  isTooltipVisible: boolean;
  showTooltip: () => void;
  hideTooltip: () => void;
  tooltipTitle?: string;
  tooltipText?: string;
  tooltipIconTop?: string;
  tooltipIconLeft?: string;
};

function LabelTooltip({
  tooltipText,
  tooltipTitle,
  isTooltipVisible,
  tooltipIconTop,
  tooltipIconLeft,
  showTooltip,
  hideTooltip,
}: Props) {
  return (
    <LabelTooltipContainer
      tooltipIconTop={tooltipIconTop}
      tooltipIconLeft={tooltipIconLeft}
    >
      <img
        src={images.IconInfoBlue}
        alt="icon"
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}  
      />
      {isTooltipVisible &&
        <div className={"input-box-placeholder-tooltip "}>
          <TooltipBox
            active={isTooltipVisible}
            tooltipTitle={tooltipTitle}
            tooltipText={tooltipText}
            leftArrow
            left="30px"
            top="-17px"
          />
        </div>
      }
    </LabelTooltipContainer>
  );
}

export default LabelTooltip;
