import moment from "moment";
import React, { Component } from "react";
import { PolicyDataContainer } from "./policy-data-style"
import SelectBox from "../../../../../../components/select-box/select-box";
import CalendarBox from "../../../../../../components/calendar-box/calendar-box";
import { TRAVEL_MAX_EFFECT_DAYS } from "../../../../../../services/url";
import { IMediator } from "../../../../../../models/mediator-model";
import { SimpleSelectboxInterface } from "../../../../../simulations/new-simulation/choose-policy/new-simulation.dto";
import { getRelatedMediatorsCodes } from "../../../../../../utils/mediatorCode";
import { images } from "../../../../../../assets";
import { IHealthCoverage } from "../../../../../../constants/health";
import { PRODUCT_TYPE } from "../../../../../../constants/policyType";
import { withPolicy } from "../../../../../../context/mixin/with-policy";
import TravelCountriesServices from "../../../../../../services/TravelCountriesServices";
import { TRAVEL_COUNTRIES_FIELDS } from "../../../../../../constants/optionsTravelPolicy";
import { ErrorModalContainer } from "../../../../../../components/error-modal/error-modal-style";
import MainButton from "../../../../../../components/main-button";

interface PolicyDataProps {
    validate: {
        validateRequest: boolean;
        isValidated: boolean;
    },
    onValidated?: any;
    data?: any;
    isReadOnly?: boolean;
    mediator: IMediator;
    selectedMediatorCode: string;
    policy: any;
    coverages?: IHealthCoverage[];
}

interface PolicyDataState {
    policy: {
        departureDate: {
            value: string,
            errorMessage: string
        },
        returnDate: {
            value: string,
            errorMessage: string
        },
        travelDestination: {
            value: string,
            errorMessage: string
        },
        residenceCountry: {
            value: string,
            errorMessage: string
        }
    };
    maxEffectDays: number;
    relatedMediatorCodes: Array<SimpleSelectboxInterface>;
    mediatorCodeSelected: string,
    showTravelDates: boolean;
    travelDestinations: any[];
    residenceCountries: any[];
    modalErrorText: string,
    modalErrorShow: boolean,
}

class PolicyData extends Component<PolicyDataProps, PolicyDataState> {

    constructor(props: PolicyDataProps) {
        super(props)
        const getMediador = () => {
            if (props.selectedMediatorCode) {
                return props.selectedMediatorCode;
            } else {
                if (props.data.mediatorData && props.data.mediatorData.code) {
                    return props.data.mediatorData.code
                } else {
                    if (props.data && props.data.taker && props.data.taker.policy && props.data.taker.policy.selectedMediator) {
                        return props.data.taker.policy.selectedMediator;
                    } else {
                        return props.mediator.mediatorCode;
                    }
                }
            }

        }

        this.state = {
            policy: {
                departureDate: {
                    value: '',
                    errorMessage: ''
                },
                returnDate: {
                    value: '',
                    errorMessage: ''
                },
                travelDestination: {
                    value: '',
                    errorMessage: ''
                },
                residenceCountry: {
                    value: '',
                    errorMessage: ''
                }
            },
            maxEffectDays: TRAVEL_MAX_EFFECT_DAYS ? Number(TRAVEL_MAX_EFFECT_DAYS) : 0,
            relatedMediatorCodes: getRelatedMediatorsCodes(this.props.mediator),
            mediatorCodeSelected: getMediador(),
            showTravelDates: false,
            travelDestinations: [],
            residenceCountries: [],
            modalErrorText: "",
            modalErrorShow: false,
        }
    }

    async componentDidMount() {
        const { policy } = this.props
        
        if (Object.keys(policy.product).length !== 0) {
            this.getTravelCountries()
            this.setPolicyData()
        }
    }
    
    async componentDidUpdate(prevProps: Readonly<PolicyDataProps>, prevState: Readonly<PolicyDataState>, snapshot?: any) {
        const { policy } = this.props

        if (Object.keys(prevProps.policy.product).length === 0 && Object.keys(policy.product).length !== 0) {
            this.getTravelCountries()
            this.setPolicyData()
        }
        
    }

    componentWillReceiveProps(nextProps: any) {
        let { validate } = this.props;
        if (nextProps.validate.validateRequest) {
            validate.validateRequest = false;
            validate.isValidated = this.validateForm();
        }
    }

    setPolicyData() {
        const { policy, data } = this.props

        const isAllYearProduct = policy.product.products_internalName === PRODUCT_TYPE.TRAVEL_ALL_YEAR;
        const isWelcomeProduct = policy.product.products_internalName === PRODUCT_TYPE.TRAVEL_WELCOME;

        const policyAux = {
            departureDate: {
                value: (data.taker && data.taker.policy && data.taker.policy.departureDate) ? this.setInitialDepartureDate(data.taker.policy.departureDate, isAllYearProduct) : 
                isAllYearProduct ? moment().format('DD/MM/YYYY') : '',
                errorMessage: ''
            },
            returnDate: {
                value: (data.taker && data.taker.policy && data.taker.policy.returnDate) ? this.setInitialReturnDate(data.taker.policy.departureDate, data.taker.policy.returnDate, isAllYearProduct) :
                isAllYearProduct ? moment().add(1, "year").subtract(1, "day").format('DD/MM/YYYY') : '',
                errorMessage: ''
            },
            travelDestination: {
                value: (data.taker && data.taker.policy) ?
                    data.taker.policy.travelDestination :
                    '',
                errorMessage: ''
            },
            residenceCountry: {
                value: (data.taker && data.taker.policy && data.taker.policy.residenceCountry) ?
                    data.taker.policy.residenceCountry : 
                    isWelcomeProduct ? '' : '1', //Valor por defecto es España
                errorMessage: ''
            }
        }
        const showTravelDatesAux = !isAllYearProduct

        this.setState({ policy: policyAux, showTravelDates: showTravelDatesAux })
    }

    setInitialDepartureDate(departureDate: string, isAllYearProduct: boolean) {
        if(isAllYearProduct) return moment().format('DD/MM/YYYY')
        if (moment(departureDate, "DD/MM/YYYY").isBefore(moment())) return moment().format('DD/MM/YYYY')
        return departureDate;
    }
    
    setInitialReturnDate(departureDate: string, returnDate: string, isAllYearProduct: boolean) {
        if (isAllYearProduct) return moment().add(1, "year").subtract(1, "day").format('DD/MM/YYYY');
        if (moment(returnDate, "DD/MM/YYYY").isBefore(moment())) return moment().add(1, "day").format('DD/MM/YYYY')
        if (moment(this.setInitialDepartureDate(departureDate, isAllYearProduct), "DD/MM/YYYY").isAfter(moment(returnDate, "DD/MM/YYYY"))) return moment().add(1, "day").format('DD/MM/YYYY')
        return returnDate;
    }

    handleValueChange(input: string, value: string) {
        let { policy, maxEffectDays } = this.state;
        if (input === 'departureDate' && moment(value, "DD/MM/YYYY").add(maxEffectDays, "day").isBefore(moment(policy.returnDate.value, "DD/MM/YYYY"))) {
            policy['returnDate'].errorMessage = '';
            policy['returnDate'].value = moment(value, "DD/MM/YYYY").add(maxEffectDays, "day").format("DD/MM/YYYY");
        }
        if (input === 'departureDate' && moment(value, "DD/MM/YYYY").isAfter(moment(policy.returnDate.value, "DD/MM/YYYY"))) {
            policy['returnDate'].errorMessage = '';
            policy['returnDate'].value = '';
        }
        if (input === 'travelDestination' || input === 'residenceCountry' || input === 'departureDate' || input === 'returnDate') {
            Object.keys(policy).forEach((k: string) => {
                if (k === input) {
                    policy[k].errorMessage = '';
                    policy[k].value = value;
                }
            });
        }
        this.setState({ policy })
    }

    validateForm(): boolean {

        let { policy, mediatorCodeSelected, showTravelDates } = this.state;
        let noErrors = true;
        const { coverages } = this.props;

        if(showTravelDates) {
            if(!policy.departureDate.value) {
                policy.departureDate.errorMessage = 'required';
                noErrors = false;
            }
            if (!policy.returnDate.value) {
                policy.returnDate.errorMessage = 'required';
                noErrors = false;
            }
            if(moment(policy.departureDate.value, "DD/MM/YYYY").isAfter(moment(policy.returnDate.value, "DD/MM/YYYY"))) {
                policy.returnDate.errorMessage = 'badDate';
                noErrors = false;
            }
        }
        if (!policy.travelDestination.value) {
            policy.travelDestination.errorMessage = 'required';
            noErrors = false;
        }
        if (!policy.residenceCountry.value) {
            policy.residenceCountry.errorMessage = 'required';
            noErrors = false;
        }
        if (noErrors) {
            if (this.props.onValidated) {
                this.props.onValidated(
                    {
                        departureDate: policy.departureDate.value,
                        returnDate: policy.returnDate.value,
                        travelDestination: policy.travelDestination.value,
                        residenceCountry: policy.residenceCountry.value,
                        selectedMediator: mediatorCodeSelected,
                        coverages
                    }
                );
            }
        }

        this.setState({ policy });
        return noErrors;
    }

    handleSelectMediatorChange(value: string): void {
        this.props.policy.setMediatorSelectedCode && this.props.policy.setMediatorSelectedCode(value);
        this.setState({ mediatorCodeSelected: value });
    }

    async getTravelCountries() {
        const { policy } = this.props
        try {
            const travelCountries = await TravelCountriesServices.getTravelCountriesByProduct({
                product: policy.product.products_internalName,
                loader: true,
                setLoading: () => { }
                })
            if(travelCountries && travelCountries.length){
                const travelDestinations = travelCountries.filter((country: any) => country.field === TRAVEL_COUNTRIES_FIELDS.TRAVEL_DESTINATION)
                const residenceCountries = travelCountries.filter((country: any) => country.field === TRAVEL_COUNTRIES_FIELDS.RESIDENCE_COUNTRY)
                this.setState({travelDestinations, residenceCountries})
            }
        } catch (error) {
            this.setState({ modalErrorShow: true, modalErrorText: 'Se ha producido un error al obtener los países de residencia y los destinos del viaje. Vuelva a intentarlo más tarde.'})
        }
    }

    render() {
        const { policy, maxEffectDays, relatedMediatorCodes, mediatorCodeSelected, showTravelDates, travelDestinations, residenceCountries, modalErrorText, modalErrorShow } = this.state
        const { isReadOnly, coverages } = this.props;

        return (
            <PolicyDataContainer>
                <div className="content-wrapper bottomMargin20" ref={''}>
                    <div className="personal-data-wrapper" id="data-policy">
                        <div className="data-title">
                            <h5>DATOS DE PÓLIZA</h5>
                        </div>
                        <div className="personal-data-container">
                            <div className="personal-data-row">
                                {relatedMediatorCodes.length > 0 &&
                                    <div className="personal-data-mediador col-100">
                                        <div className="selectBox">
                                            <SelectBox
                                                isMediator={true}
                                                optionsText={relatedMediatorCodes}
                                                optionKey={"value"}
                                                disabled={isReadOnly}
                                                optionValue={"label"}
                                                defaultValue={mediatorCodeSelected}
                                                onChange={(value: string) => this.handleSelectMediatorChange(value)}
                                                propValue={mediatorCodeSelected}
                                                required
                                            />
                                            <label>Mediador</label>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="personal-data-row">
                                {showTravelDates && <div className="personal-data-item col-50" ref={''}>
                                    <div className="inputBox">
                                        <CalendarBox
                                            labelText=""
                                            initialValue={policy.departureDate.value}
                                            disabled={isReadOnly}
                                            minDate={moment().subtract(1, "day").toISOString(true)}
                                            onChange={(value: string) => this.handleValueChange('departureDate', value)}
                                            required={true}
                                            errorCode={policy.departureDate.errorMessage}
                                        />
                                        <label>Fecha de Inicio del Viaje</label>
                                    </div>
                                </div>}
                                {showTravelDates && <div className="personal-data-item col-50" ref={''}>
                                    <div className="inputBox">
                                        <CalendarBox
                                            labelText=""
                                            initialValue={policy.returnDate.value}
                                            disabled={isReadOnly}
                                            minDate={policy.departureDate.value !== "" ? moment(policy.departureDate.value, "DD/MM/YYYY").add(-1, "seconds").toISOString(true) : moment().toISOString()}
                                            maxDate={moment(policy.departureDate.value, "DD/MM/YYYY").add(maxEffectDays, "days").toISOString(true)}
                                            onChange={(value: string) => this.handleValueChange('returnDate', value)}
                                            required={true}
                                            errorCode={policy.returnDate.errorMessage}
                                        />
                                        <label>Fecha de Finalización del Viaje</label>
                                    </div>
                                </div>}
                                <div className="personal-data-item col-50">
                                    <div className="selectBox">
                                        <SelectBox
                                            labelText=""
                                            required={true}
                                            errorCode={policy.travelDestination.errorMessage}
                                            optionsText={travelDestinations}
                                            optionKey={"value"}
                                            optionValue={"label"}
                                            initialValue={policy.travelDestination.value}
                                            onChange={(value: string) => this.handleValueChange('travelDestination', value)}
                                            disabled={isReadOnly}
                                        />
                                        <label>Destino del viaje</label>
                                    </div>
                                </div>
                                <div className="personal-data-item col-50">
                                    <div className="selectBox">
                                        <SelectBox
                                            labelText=""
                                            required={true}
                                            errorCode={policy.residenceCountry.errorMessage}
                                            optionsText={residenceCountries}
                                            optionKey={"value"}
                                            optionValue={"label"}
                                            initialValue={policy.residenceCountry.value}
                                            onChange={(value: string) => this.handleValueChange('residenceCountry', value)}
                                        />
                                        <label>País de residencia</label>
                                    </div>
                                </div>
                            </div>

                            <>
                                <div className="colSin-50 rightPadding10">
                                    {coverages && coverages.filter((cov: IHealthCoverage) => cov.required === true)
                                        .map((coverage: IHealthCoverage, item: number) => (
                                            <label
                                                className={`checkbox-container${(coverage.required) ? ' noPointer' : ''}`}
                                                key={item}>
                                                <input
                                                    type="checkbox"
                                                    checked={coverage.required || coverage.checked}
                                                    onChange={(e) => ''}
                                                />
                                                <div className={`checkmark-container${coverage.required ? '-readonly' : ''}`}>
                                                    <div className="checkmark-item">
                                                        <img src={(coverage.required || coverage.checked) ? images.IconTickWhite : images.IconCloseWhite} alt="" />
                                                    </div>
                                                </div>
                                                <div className="sc-iwsKbI eLRFbD">
                                                    <p>{coverage.description}</p>
                                                </div>
                                            </label>

                                        ))}
                                </div>

                                <div className="colSin-50 rightPadding10">
                                    {coverages && coverages.filter((cov: IHealthCoverage) => cov.required === false)
                                        .map((coverage: IHealthCoverage, item: number) => (
                                            <div>

                                                <label
                                                    className={`checkbox-container${(coverage.required) ? ' noPointer' : ''}`}
                                                    key={item}>

                                                    <input
                                                        type="checkbox"
                                                        checked={(coverage.required || coverage.checked)}
                                                        onChange={(e) => ''}
                                                    />
                                                    <div className={
                                                        `checkmark-container${coverage.required ? '-readonly' : ''}`
                                                    }>
                                                        <div className="checkmark-item">
                                                            <img src={
                                                                ((coverage.required ||
                                                                    coverage.checked)) ? images.IconTickWhite : images.IconCloseWhite} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="sc-iwsKbI eLRFbD">
                                                        <p>{coverage.description}</p>
                                                    </div>
                                                </label>
                                            </div>
                                        ))}
                                </div>
                            </>

                        </div>
                    </div>
                </div>
                <ErrorModalContainer className={`${modalErrorShow && `active`}`}>
                    <div className="recover-modal">
                        <div className="server-error">
                            <p>{modalErrorText}</p>
                        </div>
                        <div className="button-error">
                            <MainButton text="Aceptar" onClick={() => this.setState({ modalErrorShow: false })} />
                        </div>
                    </div>
                </ErrorModalContainer>
            </PolicyDataContainer>
        )
    }
}

export default withPolicy(PolicyData);