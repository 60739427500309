import styled from "styled-components";
import { colors } from "../../assets/colors";

export const CardCampaignWeekStyle = styled.div`
  padding: 32px;
  background: ${colors["PR-001-100"]};
  border-radius: 3px;

  .card-campaign-week-subtitle {
    color: ${colors["background-modal-icon"]};
    margin-bottom: 16px;
  }

  .card-campaign-week-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${props => (props.disabled ? "0px" : "32px")};
    color: white;
    &__icon {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
  .card-campaign-week-table {
    display: flex;
  }
  .card-campaign-week-table-item {
    color: white;
    margin-right: 40px;
    &__title {
      margin-bottom: 12px;
    }
    &.complete {
      .card-campaign-week-table-item-text {
        color: rgba(179, 202, 220, 0.8);
      }
    }
  }

  &.light-green {
    background: ${colors["PR-001/5"]};
    .card-campaign-week-title {
      color: ${colors["SU-001-100"]};
    }
    .card-campaign-week-table-item {
      color: ${colors["PR-001-100"]};
      &.complete {
        .card-campaign-week-table-item-text {
          color: ${colors["PR-001-100"]};
        }
      }
    }
  }

  &.green {
    background: ${colors["SU-001-100"]};
    .card-campaign-week-title {
      color: ${colors["FL-001-Default"]};
    }
    .card-campaign-week-table-item {
      color: ${colors["SU-001-10"]};
      .card-campaign-week-table-item-text {
        color: ${colors["FL-001-Default"]};
      }
    }
  }

  &.basic {
    background: ${colors["PR-001/5"]};
    .card-campaign-week-title {
      color: ${colors["PR-001-100"]};
    }
    .card-campaign-week-table-item {
      color: ${colors["PR-001-100"]};
      &.complete {
        .card-campaign-week-table-item-text {
          color: ${colors["PR-001-100"]};
        }
      }
    }
  }

  &.blue {
    background: ${colors["PR-001-100"]};
    .card-campaign-week-title {
      color: ${colors["FL-001-Default"]};
    }
    .card-campaign-week-table-item {
      color: ${colors["FL-001-Default"]};
      &.complete {
        .card-campaign-week-table-item-text {
          color: ${colors["FL-001-Default"]};
        }
      }
    }
  }

  &.red {
    background: ${colors["ER-001-80"]};
    .card-campaign-week-title {
      color: ${colors["FL-001-Default"]};
    }
    .card-campaign-week-table-item {
      color: ${colors["ER-001-30"]};
      .card-campaign-week-table-item-text {
        color: ${colors["FL-001-Default"]};
      }
    }
  }

  &.disable {
    background: #eeeff2;
    .card-campaign-week-title {
      color: ${colors["TX-001-80"]};
    }
    .card-campaign-week-table-item {
      color: ${colors["TX-001-50"]};
      &.complete {
        .card-campaign-week-table-item-text {
          color: ${colors["TX-001-50"]};
        }
      }
    }
  }
`;
