import React from "react";
import { H1TextContainer } from "./h1-text-styles";

interface H1TextProps {
    children: any;
    className?: string;
    color?: string;
}


const H1Text = ({children = '', className='', color=''}:H1TextProps) => {
        return (
        <H1TextContainer color={color} className={className}>
            {children}
        </H1TextContainer>
    )
} 
export default H1Text;