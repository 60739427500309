import React from "react";
import { CardContainer } from "./card-reports-style";
import { IconButton } from "../buttons";
import { images } from "../../assets";

type CardReportsProps = {
  image: string;
  title: string;
  onClick: () => void;
  disabled?: boolean;
};

const CardReports = ({ image, title, onClick, disabled }: CardReportsProps) => {
  return (
    <CardContainer>
      <div className="content">
        <div className="icon">
          <img src={image} alt="" />
        </div>
        <div className="title">{title}</div>
      </div>
      <div className="action">
        <IconButton
          icon={images.IconDownloadWhite2}
          onClick={onClick}
          disabled={disabled}
        />
      </div>
    </CardContainer>
  );
};

export default CardReports;
