import styled from "styled-components";
import { colors } from "../../../../../../assets/colors";
import { images } from "../../../../../../assets/images";

export const TravelContactFormContainer = styled.div`

.personal-data-buttons {
    display: flex;
    &__item {
      margin-right: 70px;
      min-width: 100px;
      &:last-child {
        margin-right: 20px;
        width: 180px;
      }
    }
  }

  .contact-radio-main-row{
    margin-bottom: 20px;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
    font-family: "museo sans";
  }
  
  .required label {
    background: url(${images.Required}) no-repeat 0% 50%;
    background-size: 12px;
    padding: 0 0 0 15px;
  }

  .input-box-topbar-label {
    position: relative;

    p {
      font-size: 16px;
    }

    &.required {
      padding-left: 15px;

      &:before {
        content: "";
        background: url(${images.Required}) no-repeat 0% 50%;
        background-size: 12px;
        z-index: 9;
        position: absolute;
        top: 3px;
        left: 0;
        width: 10px;
        height: 10px;
      }
    }
  }

  .radio-container {
    font-size: 1vw;
    color: ${colors["PR-001-100"]};
    text-transform: uppercase;
    position: relative;
    padding-left: 35px;
    display: flex;

    .radio-content{
      top: -2px;
      position: relative;
    }

    .receipt-option {
      -webkit-appearance: checkbox;
      position: relative;
      opacity: 0;
      cursor: pointer;
      display: block;
      width: 16px;
      left: 20px;
      top: -2px;
      bottom: 0;
      height: 16px;
      z-index: 99;
    }

    .recover-checkmark {
      position: relative;
      top: -2px;
      left: 0;
      height: 15px;
      width: 15px;
      border: 1px solid ${colors["PR-001-100"]};
      border-radius: 50%;

      &:hover {
        background: ${colors["PR-001-30"]};
      }
    }

    .receipt-option:checked ~ .recover-checkmark {
      background: ${colors["PR-001-100"]};
    }

    .recover-checkmark:after {
      content: "";
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 6px;
      height: 6px;
      border-radius: 70%;
      background: white;
    }

    .receipt-option:checked ~ .recover-checkmark:after {
      display: block;
    }
  }

  .personal-data-wrapper {
    overflow: initial;
    padding-right: 16px;
    padding: 20px;
    background-color: #fbfbfb;
    display: block;
    width: 100%;
    float: left;
    margin-top: 40px;
  }
  @media (min-width: 320px) {
    .personal-data-wrapper {
      width: 55% !important;
    }
  }
  @media (min-width: 1201px) {
    .personal-data-wrapper {
      width: 100% !important;
    }
  }
`;
