import styled from "styled-components";
import { colors } from "../../assets";
import { MainButtonContainer } from "../main-button/main-button-style";

export const PaginationContainer = styled.div`
  margin-right: 20px;
  margin-bottom: 20px;

  .policies-container {
    display: flex;
    width: 920px;
    margin-bottom: 100px;
  }

  .policies-item {
    margin-right: 20px;
    cursor: pointer;
    width: 295px;
    height: 420px;
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    background: #ffffff;
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-right: 0;
    }

    &.open {
      .policies-item-cover {
        bottom: 260px; 
        z-index: 1;

        .policies-info-link {
          display: none;
        }
      }

      .policies-info {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
      }

      .policies-info-title {
        margin-bottom: 0;
      }

      .policies-item-content {
        opacity: 1;
      }
    }
  }

  .policies-item-cover {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s all ease-in;
    background: #ffffff;
    z-index: 19;
  }
  .product-container{
    
    overflow:auto;
    margin-bottom:1vh;
    border-top: 2px solid #3372a250;
    border-bottom: 2px solid #3372a250;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .display-scroll{
    overflow-y: scroll;
  }
  .product-container::-webkit-scrollbar {
    display: none;
    border
  }

  .product-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .policies-item-content {
    width: 100%;
    height: 100%;
    padding: 105px 24px 24px;
    position: relative;
    z-index: 9;
    opacity: 0;
    transition: 0.3s all ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
   
    ul {
      height:100%
      margin-bottom: 24px;
      li {
        margin-bottom: 16px;
        color: ${colors["PR-001-100"]};

        p {
          display: inline-block;
          cursor: pointer;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          color: ${props => props.colorCode ? props.colorCode : colors["SC-004-100"]};
          padding-left: 18px;
          position: relative;

          &:before {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background:  ${props => props.colorCode ? props.colorCode : colors["SC-004-100"]};
            position: absolute;
            top: 4px;
            left: 0;
          }

          div {
            p {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  
  .policies-wave {
    margin-bottom: 8px;
  }

  .policies-container {
    &:hover {
      .policies-item {
        opacity: 0.5;
      }
    }
  }

  .policies-container {
    .policies-item {
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }

  .policies-info-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 24px;
  }

  .policies-info {
    padding: 0 24px 24px;
  }

  .policies-info-link {
      
    ${MainButtonContainer} {
      max-width: 160px;
      height: auto;
      padding: 10px 8px;
    }
  }
  .mt-21 {
    margin-top: -21px;
  }
`;

export const LiPolicies = styled.li`
  &policies-complex-item {
    margin-bottom: 16px;
    color: ${colors["PR-001-100"]};

    p {
      display: inline-block;
      cursor: pointer;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      color:  ${props => props.colorCode ? props.colorCode : colors["SC-004-100"]};
      padding-left: 18px;
      position: relative;

      &:before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background:  ${props => props.colorCode ? props.colorCode : colors["SC-004-100"]};
        position: absolute;
        top: 4px;
        left: 0;
      }
    }
  }
`;
