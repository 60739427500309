import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../assets";
import { ErrorModalContainer } from "../../../../components/error-modal/error-modal-style";
import GuideLine from "../../../../components/guide-line";
import HealthCampaingItem from "../../../../components/health-campaign-item/health-campaign-item";
import HealthPriceItem from "../../../../components/health-price-item/health-price-item";
import MainButton from "../../../../components/main-button";
import { ICampanyas, IHealthCoverage } from "../../../../constants/health";
import { withPolicy } from "../../../../context/mixin/with-policy";
import CampaignsServices from "../../../../services/CampaignsServices";
import SimulationServices from "../../../../services/SimulationServices";
import { campaign } from "../../../hirings/new-hiring/health/health-price/health-price-dto/campaign-dto";
import { DentalInsuredSimulationInterface } from "../dental-form-insured/dental-insured-data-layout";
import { getPrices } from "../dental-simulation-helper";
import { DentalPriceDataContainer } from "./dental-price-data-style"
import { withPayTypes } from "../../../../context/mixin/with-pay-types";

interface DentalPriceProps extends RouteComponentProps {
  setLoading: Function;
  data: any;
  policy: any;
  allPayTypes: any;
}

export interface DentalpriceTypeModel {
  payType: string;
  price: string;
  discount: string;
  taxes: string;
  total: string;
}

export interface selectedCampaign {
  code: string,
  name: string
}

export interface SelectedPriceInterface {
  discount: string;
  paymentTime: string;
  payType: string,
  price: string;
  taxes: string;
  total: string;
}

interface DentalPriceState {
  selectablePrices: { [id: string]: any };
  selectedPrice: SelectedPriceInterface;
  selectedCampaigns: selectedCampaign[];
  selectableCampaigns: campaign[];
  modalErrorShow: boolean;
  modalErrorText: string;
  responseSimulation: any;
}

class DentalPriceDataLayout extends React.Component<DentalPriceProps, DentalPriceState> {

  constructor(props: DentalPriceProps) {
    super(props);

    this.state = {
      selectablePrices: this.props.data.selectablePrices,
      selectedPrice: this.props.data.priceData.price,
      selectedCampaigns: this.props.data.priceData ? this.props.data.priceData.campaigns : [],
      selectableCampaigns: this.props.data.selectableCampaigns,
      modalErrorShow: false,
      modalErrorText: '',
      responseSimulation: this.props.data.resPrices
    };
  }

  formatData(data: any) {
    const { selectedCampaigns, selectedPrice } = this.state;
    let obj: any = {};

    obj.insureds = [];
    obj.coverages = [];

    data.insuredData && data.insuredData.forEach((insured: DentalInsuredSimulationInterface, index: number) => {

      const newInsured: any = {
        birthdate: moment.utc(insured.birthDate, 'DD/MM/YYYY').toISOString(),
        riskNumber: String(Number(index) + 1),
        relationship: insured.parentesco,
        hasAnotherInsurance: insured.hasAnotherInsurance
      }

      data.insuredData[index].coverages
        .filter((cov: IHealthCoverage) => (cov.required || cov.checked))
        .forEach((coverage: IHealthCoverage) => {
          const capital: number = +(coverage.capitalValue ? coverage.capitalValue : "1");

          const newCoverage: any = {
            code: coverage.code,
            riskNumber: String(Number(index) + 1),
            capital: String(capital)
          };
          obj.coverages.push(newCoverage)
        })

      obj.insureds.push(newInsured)
    });
    const campanyas: ICampanyas[] = selectedCampaigns.map(campanya => ({ CAMPANYA: campanya.code }));
    obj.campanyas = campanyas;

    obj.payType = selectedPrice.payType;

    obj.mediator = data.mediatorData && data.mediatorData.code
    obj.startDate = data.mediatorData && moment.utc(data.mediatorData.startDate, 'DD/MM/YYYY').toISOString();
    return obj;
  }

  async getPrices() {
    const { setLoading, data, allPayTypes, policy } = this.props;
    const { selectedPrice, selectedCampaigns } = this.state;

    const [selectablePrices, resPrices] = await getPrices(setLoading, data, selectedCampaigns, selectedPrice, allPayTypes, policy.product)
      .catch((error) => {
        this.setState({
          modalErrorShow: true,
          modalErrorText: `Error al simular la póliza: ${(error && error.data && error.data.message) ?
            error.data.message : 'Vuelva a intentarlo más tarde.'}`
        })
        throw error;
      })

    if (resPrices && resPrices.body && resPrices.body.RESPUESTA && resPrices.body.RESPUESTA.IMPORTESFORPAGO) {
      const prices = resPrices.body.RESPUESTA.IMPORTESFORPAGO;

      let selected = prices.find((price: any) => price.PERIODO_COBRO === selectedPrice.payType);

      this.setState({
        selectedPrice: {
          paymentTime: selectedPrice.paymentTime,
          payType: selected ? selected.PERIODO_COBRO : '',
          price: selected ? selected.PRIMANETA : '',
          discount: selected ? selected.DESCUENTOS : '',
          taxes: selected ? selected.TOTAL_IMPUESTOS : '',
          total: selected ? selected.PRIMATOTAL : ''
        },
        responseSimulation: resPrices.body
      });

    }
    return selectablePrices;
  }

  getCampaigns = async () => {
    const { setLoading, policy, data } = this.props;
    let allCampaigns: campaign[] = [];
    if (policy && policy.product && policy.product.products_colectivo && policy.product.products_combinacion_comercial) {
      allCampaigns = await CampaignsServices.getCampaigns({
        loader: true,
        setLoading,
        colective: policy.product.products_colectivo,
        combinacion_comercial: policy.product.products_combinacion_comercial,
        fecha_inicio: this.checkPolicyStartDate(data)
      })
        .catch((error) => {
          this.setState({
            modalErrorShow: true,
            modalErrorText: `Se ha producido un error al obtener las campañas. Vuelva a intentarlo más tarde.`
          });
          throw error.statusText;
        });
    }

    return allCampaigns;
  }

  checkPolicyStartDate (data: any): string {
    if (data && data.mediatorData && data.mediatorData.startDate) {
      return data.mediatorData.startDate;
    }
    return '';
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    this.loadData();
  };

  loadData = async () => {
    let campaigns = await this.getCampaigns();
    this.setState({ selectableCampaigns: campaigns })
  }

  toValidate = async () => {
    const { history, data, setLoading, policy } = this.props;

    const formattedData = this.formatData(data)
    const internalName = policy.product.products_internalName

    const resPrices = await SimulationServices.dental({
      loader: true,
      setLoading: setLoading,
      data: formattedData
    }, internalName)
      .catch((error) => {
        this.setState({
          modalErrorShow: true,
          modalErrorText: `Error al simular la póliza: ${(error && error.data && error.data.message) ?
            error.data.message : 'Vuelva a intentarlo más tarde.'}`
        })
        throw error;
      });

    if (resPrices) {
      data.priceData = {
        price: this.state.selectedPrice,
        campaigns: this.state.selectedCampaigns
      }

      data.resPrices = resPrices.body.RESPUESTA;

      history.push("/simulacion/nueva/dental/resultado");
    }
  }

  hideErrorModal = () => this.setState({ modalErrorShow: false, modalErrorText: '' });

  handlePriceRadioChange(time: string, payType: string, price: string, discount: string, taxes: string, total: string) {
    const value = {
      paymentTime: time,
      payType,
      price,
      discount,
      taxes,
      total
    }
    this.setState({ selectedPrice: value });
  }

  async handleSelectedCampaignsChange(code: string, name: string, selected: boolean) {

    let { selectedCampaigns } = this.state;
    if (selected) {
      if (selectedCampaigns.some(campaign => campaign['code'] === code)) {
        const index = selectedCampaigns.findIndex(campaign => campaign.code === code);
        selectedCampaigns.splice(index, 1);
      }
    }
    else {
      if (!selectedCampaigns.some(campaign => campaign['code'] === code))
        selectedCampaigns.push({ code, name });
    }
    this.setState({ selectedCampaigns });
    let prices = await this.getPrices();
    this.setState({ selectablePrices: prices })
  }

  render() {

    const { selectedPrice, selectablePrices, selectedCampaigns, selectableCampaigns } = this.state;

    return (
      <DentalPriceDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">

            <h2>{'DATOS DEL SEGURO'}</h2>

          </div>
          <div className="data-flow-status-guide">
            <GuideLine
              steps={[0, 0, 0, 0]}
              currentStep={3}
            />
          </div>
        </div>

        <div className="content-wrapper bottomMargin20" ref={''}>
          <div className="personal-data-wrapper">
            <div className="data-title">
              <h5>CALCULO DE PRIMA ANUAL SEGÚN FORMA DE PAGO</h5>
            </div>
          </div>
          <div className="colSin-100">

            {Object.keys(selectablePrices).map((key, index) => (
              <HealthPriceItem
                paymentTime={key}
                price={selectablePrices[key].PRIMATOTAL}
                defaultChecked={key.toLocaleUpperCase() === selectedPrice.paymentTime.toLocaleUpperCase()}
                discount={selectablePrices[key].DESCUENTOS}
                onClick={
                  async (
                    time: string,
                    price: string,
                    discount: string
                  ) => {
                    this.handlePriceRadioChange(
                      time,
                      selectablePrices[key].PERIODO_COBRO,
                      price,
                      discount,
                      selectablePrices[key].TOTAL_IMPUESTOS,
                      selectablePrices[key].PRIMATOTAL)
                  }
                } />
            ))}

          </div>
        </div>

        <div className="content-wrapper bottomMargin20">

          {selectableCampaigns.map((b, index) => (
            <HealthCampaingItem
              campaignName={b.name}
              campaignDescription={b.description}
              campaignCode={b.code}
              defaultChecked={selectedCampaigns.some(campaign => campaign['code'] === b.code)}
              disabled={b.isRequired}
              onClick={(campaignCode: string, campaignName: string, selected: boolean) => this.handleSelectedCampaignsChange(campaignCode, campaignName, selected)}
            />)
          )}

        </div>

        <div className="colSin-100">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push("/simulacion/nueva/dental/asegurados")}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                text="Siguiente"
                onClick={() => this.toValidate()}
              />
            </div>
          </div>
        </div>

        <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={this.hideErrorModal} />
            </div>
          </div>
        </ErrorModalContainer>

      </DentalPriceDataContainer>
    );
  }
}

export default withPayTypes(withPolicy(withRouter(DentalPriceDataLayout)));
