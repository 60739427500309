import React from "react";
import { SimulationResultContainer } from "./result-style";
import { RouteComponentProps, withRouter } from "react-router-dom";
import WebText from "../../../../components/components-text/web-text";
import MainTable from "../../../../components/main-table";
import { NewSimulationContainer } from "../new-simulation-style";
import { setColorSpan } from "../../../../constants/policyType";
import Title03Text from "../../../../components/components-text/title-03-text";
import HeadText from "../../../../components/components-text/head-text";
import NoteText from "../../../../components/components-text/note-text";
import MainButton from "../../../../components/main-button";
import { images } from "../../../../assets";
import { InflowSidebarContainer } from "../../../../components/inflow-sidebar/inflow-sidebar-style";
import LeftInfoModal from "../../../../components/left-info-modal";
import { withGeneral } from "../../../../context/mixin/with-general";
import { withPolicy } from "../../../../context/mixin/with-policy";
import SimulationServices from "../../../../services/SimulationServices";
import moment from "moment";
import { downloadFile, formatter } from "../../../../utils/utility";
import { isValidEmail } from "../../../../utils/validation";
import GoBack from "../../../../components/go-back";
import {
  IAmountPay,
  IAmountQuoteCoverage,
  ICoveragesQuoteAmount,
  IInformativeCoverageConcepts,
  ISimulationResult,
  ITotalPremiumConcepts,
} from "./interfaces/result-interface";
import { IUser } from "../../../../models/user-model";
import { IMediator } from "../../../../models/mediator-model";
import DentalInsuredResultItem from "../../../../components/dental-insured-result-item/dental-insured-result-item";
import DentalCampaignItem from "../../../../components/dental-campaign-item-result/dental-campaign-item-result";
import PayTypesServices from "../../../../services/PayTypesServices";
import { withPayTypes } from "../../../../context/mixin/with-pay-types";

interface DentalSimulationResultLayoutProps extends RouteComponentProps {
  setLoading: Function;
  data: any;
  policy: any;
  user: IUser;
  mediator: IMediator;
  allPayTypes: any;
}

interface DentalSimulationResultLayoutState {
  insuredMembersInfoRows: any[];
  insuredMembersInfoColumns: any[];
  leftInfoModalImg: string;
  leftInfoModalClassName: string;
  leftInfoModalTitle: string;
  leftInfoModalText: string;
  leftInfoModalButtonText: string;
  leftInfoModalCloseButtonText?: string;
  leftInfoModalClick: Function;
  mandatoryGuaranteeTableColumns: any[];
  mandatoryGuaranteeTableRows: any[];
  optionalGuaranteeTableColumns: any[];
  optionalGuaranteeTableRows: any[];
  paymentTableColumns: any[];
  paymentTableRows: any[];
  riskInfoColumns: any[];
  riskInfoRows: any[];
  showLeftInfoModal: boolean;
  showEmailModal: boolean;
  simulationDbData: any;
  takerInfoRows: any[];
  takerInfoColumns: any[];
  takerEmail: any;
  simulation: ISimulationResult;
  amountPay: IAmountPay;
  amountQuoteCoverage: IAmountQuoteCoverage[] | [];
  totalPremiumConcepts: ITotalPremiumConcepts[] | [];
  informativeCoverageConcepts: IInformativeCoverageConcepts[] | [];
  healthCoverages: any;
  modality: string;
}
class DentalSimulationResultLayout extends React.Component<
  DentalSimulationResultLayoutProps,
  DentalSimulationResultLayoutState
> {
  constructor(props: DentalSimulationResultLayoutProps) {
    super(props);

    this.state = {
      showLeftInfoModal: false,
      leftInfoModalImg: "",
      leftInfoModalClassName: "",
      leftInfoModalTitle: "",
      leftInfoModalText: "",
      leftInfoModalButtonText: "",
      leftInfoModalClick: () => { },
      takerInfoRows: this.getTakerInfoRows(),
      takerInfoColumns: this.getTakerInfoColumns(),
      insuredMembersInfoRows: this.getInsuredMembersInfoRows(),
      insuredMembersInfoColumns: this.getInsuredMembersInfoColumns(),
      paymentTableColumns: this.getPaymentTableColumns(),
      paymentTableRows: this.getPaymentTableRows(),
      riskInfoColumns: this.getRiskInfoColumns(),
      riskInfoRows: this.getRiskInfoRows(),
      mandatoryGuaranteeTableColumns: this.getMandatoryGuaranteeTableColumns(),
      mandatoryGuaranteeTableRows: this.getMandatoryGuaranteeTableRows(),
      optionalGuaranteeTableColumns: this.getOptionalGuaranteeTableColumns(),
      optionalGuaranteeTableRows: this.getOptionalGuaranteeTableRows(),
      leftInfoModalCloseButtonText: "",
      showEmailModal: false,
      takerEmail: {
        value: "",
        errorCode: "",
        labelText: "Email",
      },
      simulationDbData: null,
      simulation: this.getSimulationDataIni(),
      amountPay: this.getAmountPayIni(),
      amountQuoteCoverage: [],
      totalPremiumConcepts: [],
      informativeCoverageConcepts: [],
      healthCoverages: [],
      modality: "",
    };
  }

  getSimulationDataIni = (): ISimulationResult => {
    return {
      COTIZACION: "",
      LINEA_NEGOCIO: "",
      COLECTIVO: "",
      MEDIADOR: "",
      COMBINACION_COMERCIAL_POLIZA: "",
      FECHA_EFECTO: "",
      ESTADO: "",
    };
  };

  getAmountPayIni = (): IAmountPay => {
    return {
      ASF: "0",
      CAMPANYAS: "0",
      CONSORCIO: "0",
      DEDUCCIONES: "0",
      DESCUENTOS: "0",
      DGS: "0",
      GASTOSEMI: "0",
      GASTOSEXT: "0",
      GASTOSINT: "0",
      INEM: "0",
      IPS: "0",
      PERIODO_COBRO: "",
      PRIMANETA: "0",
      PRIMAPURA: "0",
      PRIMATOTAL: "0",
      RECARGOFRA: "0",
      TOTAL_IMPUESTOS: "0",
    };
  };

  setSimulationFromDb = async (idSimulation: any) => {
    const { setLoading } = this.props;
    const simulationData = await SimulationServices.getSimulation({
      loader: true,
      setLoading: setLoading,
      id: idSimulation,
    });
    this.setState({
      simulationDbData: simulationData,
    });
  };

  async componentDidMount() {
    const { data } = this.props;

    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);

    const amountPay = data.resPrices.RECIBOS.filter(
      (amount: any) =>
        amount.PERIODO_COBRO === data.priceData.price.payType
    )[0];

    const amountCoverages = data.resPrices.IMPORTES_COTIZACION_GARANTIA.filter(
      (item: IAmountQuoteCoverage) =>
        item.PERIODO_COBRO === data.priceData.price.payType
    );

    const coverages = data.resPrices.COBERTURAS;
    const coveragesAmount = coverages.map((cov: any) => {
      if (this.checkIfInsuredExists(Number(cov.NUMERO_DE_RIESGO) - 1)) {
        return {
          CODE: cov.GARANTIA,
          key: "dimmed-mini-body",
          CAPITAL:
            Number(cov.CAPITAL) === 1
              ? "Incluido"
              : formatter.format(Number(cov.CAPITAL)),
          NUMERO_DE_RIESGO: Number(cov.NUMERO_DE_RIESGO),
          DESCRIPTION: this.getDescriptionCoverage(cov.GARANTIA),
          ...this.getCoveragesQuoteAmount(
            data.resPrices,
            data.priceData.price.payType,
            cov.GARANTIA,
            true,
            Number(cov.NUMERO_DE_RIESGO)
          ),
        }
      }
    }).filter((x: any) => x !== undefined);

    const totalPremiumConceptsList = [
      "CONSORCIO",
      "DGS",
      "GARANTIA",
      "IPS",
      "PRIMANETA",
      "RECARGOFRA",
    ];

    const informativeCoverageConceptsLit = [
      "GARANTIA",
      "PRIMAPURA",
      "SOBREPRIMA",
      "EXTRAPRIMA",
      "GASTOSINT",
      "GASTOSEXT",
      "GASTOSEMI",
      "DEDUCCIONES",
      "RECARGOCONS",
      "RECARGOBEN",
      "DESCUENTOFP",
      "CAMPANYAS",
      "DESCUENTOS",
    ];

    let totalPremiumConcepts: any = [];
    let informativeCoverageConcepts: any = [];

    amountCoverages.forEach((item: any, index: number) => {
      let newPremiumConcepts: any = {};
      let newInformativeConcepts: any = {};

      Object.entries(item).forEach(([key, value]) => {
        if (totalPremiumConceptsList.includes(key)) {
          newPremiumConcepts[key] = value;
        }
        if (informativeCoverageConceptsLit.includes(key)) {
          newInformativeConcepts[key] = value;
        }
      });
      totalPremiumConcepts.push(newPremiumConcepts);
      informativeCoverageConcepts.push(newInformativeConcepts);
    });
    
    this.setProductDescription()

    this.setState({
      totalPremiumConcepts,
      informativeCoverageConcepts,
      amountPay,
      healthCoverages: coveragesAmount,
    });

  }
  
  componentDidUpdate(prevProps:any){
    let { policy } = this.props;
    if(Object.keys(prevProps.policy.product).length===0 && Object.keys(policy.product).length!==0 ){
      this.setProductDescription()
    }
  }

  setProductDescription () {
    const { policy } = this.props; 

    if(Object.keys(policy.product).length!==0) {
      const productDescription: string = policy.product.products_name.toUpperCase();
      this.setState({ modality: productDescription});
    }
  }
  

  hireSimulation() {
    this.setState({
      ...this.state,
      showLeftInfoModal: true,
      leftInfoModalImg: images.IconHiringWhite,
      leftInfoModalClassName: "",
      leftInfoModalTitle: "Proceso de contratación",
      leftInfoModalText:
        "¿Ya has visto el resultado de la simulación y estás interesado en contratar esta póliza?",
      leftInfoModalButtonText: "Empezar",
      leftInfoModalClick: () => {
        let simulationId =
          this.state.simulationDbData && this.state.simulationDbData.id
            ? this.state.simulationDbData.id
            : undefined;
        let dataToPush = this.props.data;
        let email
        if (dataToPush.taker && dataToPush.taker.mail) {
          email = dataToPush.taker.mail
        } else {
          email = ""
        }
        var insuredsST = dataToPush.insuredData
        if (dataToPush.insuredData.find((x: any) => x.parentesco === "TOMADOR")) {
          insuredsST = dataToPush.insuredData.filter((x: any) => x.parentesco !== "TOMADOR")
          insuredsST.unshift(dataToPush.insuredData.filter((x: any) => x.parentesco === "TOMADOR")[0])
        }

        dataToPush.insureds = insuredsST
        dataToPush.insureds.forEach((insured: any, index: number) => {
          insured.address = { sameAsTaker: false }
          insured.sameAsTaker = false;
        });

        if (dataToPush.taker) dataToPush.taker.name = "";

        this.props.history.push({
          pathname: `/contratacion/nueva/dental/tomador`,
          state: {
            simulationId: simulationId,
            dataForm: {
              mediator: dataToPush.mediatorData,
              insureds: dataToPush.insuredData,
              price: dataToPush.priceData,
            },
            data: {
              taker: {
                physicalPerson: true,
                email: email,
              },
              ...dataToPush,
              payment: {},
              beneficiaries: [],
              loan: {},
            },
          },
        });
      },
    });
  }

  async saveSimulation(responseSimulation: string) {
    const { policy, setLoading, user, data } = this.props;
    try {
      const loggedUser = `${user.name} ${user.lastname1} ${user.lastname2}`
      const body = {
        ...data,
        loggedUser,
        mediatorAssociatedToPolicy: data.mediatorData ? data.mediatorData.code : undefined,
      }
      const response = await SimulationServices.simulation({
        loader: true,
        setLoading: setLoading,
        data: {
          body,
          response: responseSimulation,
          productType: policy.product.products_internalName,
        },
      });
      this.setState({ simulationDbData: response });
      return true;
    } catch (err) {
      return false;
    }
  }

  async downloadSimulation() {
    const { data, policy } = this.props;

    if (
      data.resPrices &&
      data.resPrices.DATOS_COTIZACION &&
      data.resPrices.DATOS_COTIZACION.COTIZACION
    ) {

      const response = await SimulationServices.getDentalSimulationDocument({
        loader: true,
        setLoading: this.props.setLoading,
        data: {
          mediator: data.mediatorData.code,
          mediatorCif: data.mediatorData.nif,
          simulationId:
            data.resPrices.DATOS_COTIZACION.COTIZACION,
          email: '',
          mediatorName: data.mediatorData.name ? data.mediatorData.name : `${this.props.user.name} ${this.props.user.lastname1} ${this.props.user.lastname2}` ,
          productType: policy.product.products_internalName,
        },
      })
        .then((response) => {
          if (response.status === 204) this.showModalDocNotAvailable();
          return response.data.data;
        })
        .catch((error) => {
          if (error.status === 502) this.showModalDocNotAvailable();
          else this.showModalError();

          throw error;
        });

      response && downloadFile(
        new Int8Array(response),
        `Documentacion_Simulacion_${data.resPrices.DATOS_COTIZACION.COTIZACION}.pdf`,
        "application/pdf",
        true
      );
    } else {
      this.showModalError()
    }
  }

  showModalError = (
    title: string = 'No se ha podido recuperar el PDF',
    message: string = 'Se ha producido un error, inténtelo de nuevo.',
    buttonText: string = 'Cerrar'
  ) => {
    this.setState({
      ...this.state,
      showLeftInfoModal: true,
      leftInfoModalClassName: "error",
      leftInfoModalImg: images.WarningError,
      leftInfoModalTitle: title,
      leftInfoModalText: message,
      leftInfoModalButtonText: buttonText,
      leftInfoModalClick: () => { },
    });
  }

  showModalDocNotAvailable = (
    title: string = 'No se ha podido recuperar el PDF', 
    message: string = 'La documentación se está generando. Por favor, inténtelo de nuevo transcurridos unos segundos.', 
    buttonText: string= 'Aceptar'
  ) => {
    this.setState({
      ...this.state,
      showLeftInfoModal: true,
      leftInfoModalClassName: "info",
      leftInfoModalImg: images.IconWarningYellow,
      leftInfoModalTitle: title,
      leftInfoModalText: message,
      leftInfoModalButtonText: buttonText,
      leftInfoModalClick: () => { },
    });
  }

  sendPdfEmail = async () => {
    const { takerEmail } = this.state;
    const { data, policy} = this.props;

    if (
      !data.taker ||
      !data.taker.email
    ) {
      this.setState({
        ...this.state,
        showEmailModal: true,
        leftInfoModalImg: images.IconContactCollapsedWhite,
        leftInfoModalClassName: "",
        leftInfoModalTitle: "Envío del PDF",
        leftInfoModalText: "Por favor, ingrese un email para el envio del PDF",
        leftInfoModalButtonText: "Enviar",
        leftInfoModalClick: this.validateHandler,
      });
    }
    else {
      try {
        if (!this.state.simulationDbData) {
          var saveOk = await this.saveSimulation(
            data.resPrices
          );

          if (!saveOk) {
            const updatedHealthSimulationData = data;
            updatedHealthSimulationData.taker = null;
            this.setState({
              ...this.state,
              showLeftInfoModal: true,
              leftInfoModalClassName: "error",
              leftInfoModalImg: images.WarningError,
              leftInfoModalTitle: "No se ha podido guardar la simulación",
              leftInfoModalText:
                "Se ha producido un error, verifique que haya ingresado un email válido.",
              leftInfoModalButtonText: "Cerrar",
              leftInfoModalClick: () => { },
            });
            return;
          }

          this.props.history.replace({
            pathname: `/simulacion/nueva/dental/resultado/${this.state.simulationDbData.id}`,
          });
        }
        
        await SimulationServices.getDentalSimulationDocument({
          loader: true,
          setLoading: this.props.setLoading,
          data: {
            mediator: data.mediatorData.code,
            mediatorCif: data.mediatorData.nif,
            simulationId:
              data.resPrices.DATOS_COTIZACION.COTIZACION,
            email: takerEmail.value,
            mediatorName: data.mediatorData.name ? data.mediatorData.name : `${this.props.user.name} ${this.props.user.lastname1} ${this.props.user.lastname2}` ,
            productType: policy.product.products_internalName,

          },
        })
          .then((response) =>{
            if (response.status === 204) this.showModalDocNotAvailable();
            return response.data.data;
          })
          .catch((error) => {
            throw error;
          });
      } catch (error) {
        console.error(error);
        this.setState({
          ...this.state,
          showLeftInfoModal: true,
          leftInfoModalClassName: "error",
          leftInfoModalImg: images.WarningError,
          leftInfoModalTitle: "No se ha podido enviar el PDF",
          leftInfoModalText:
            "Se ha producido un error, verifique que haya ingresado un email válido.",
          leftInfoModalButtonText: "Cerrar",
          leftInfoModalClick: () => { },
        });
      }
    };
  }

  openLeftInfoModal() {
    this.setState({ showLeftInfoModal: true });
  }

  closeLeftModal() {
    this.setState({
      showLeftInfoModal: false,
      leftInfoModalCloseButtonText: "",
    });
  }

  closeEmailModal() {
    this.setState({
      ...this.state,
      showEmailModal: false,
      leftInfoModalCloseButtonText: "",
    });
  }

  getTakerInfoRows = () => {
    return [];
  };

  getTakerInfoColumns = () => {
    const takerInfoColumns = [
      {
        text: "Tomador",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "Fecha de nacimiento",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
      {
        text: "DNI/Pasaporte",
        filter: false,
        ascendent: false,
        key: "dimmed-mini-title",
      },
    ];
    return takerInfoColumns;
  };

  getInsuredMembersInfoRows = () => {
    return [];
  };

  getInsuredMembersInfoColumns = () => {
    return [];
  };

  getPaymentTableRows = () => {
    const { allPayTypes } = this.props
    const { RECIBOS } = this.props.data.resPrices;

    if (!RECIBOS) return [];
    let prices: any = [];

    const getSequentialReceipt = (payType: string) => {
      const receipt = RECIBOS.filter(
        (receipt: any) =>
          receipt.INRECIBO === "N" && receipt.PERIODO_COBRO === payType
      );
      return receipt.length > 0 ? receipt[0].PRIMATOTAL : 0;
    };

    let payments = RECIBOS.filter(
      (firtstReceipt: any) => firtstReceipt.INRECIBO === "S"
    ).map((receipt: any) => ({
      payType: PayTypesServices.getPayTypeByCode(allPayTypes, receipt.PERIODO_COBRO).description,
      order: PayTypesServices.getPayTypeByCode(allPayTypes, receipt.PERIODO_COBRO).payTypeOrder,
      firtsReceipt: receipt.PRIMATOTAL,
      sequential: getSequentialReceipt(receipt.PERIODO_COBRO),
    }));

    Object.keys(payments).forEach((index) => {
      const { payType, order, firtsReceipt, sequential } = payments[index];

      const newItem = {
        periodicity: {
          text: payType,
          order: order,
          type: "",
          link: "",
          key: "entire-mini-body",
        },
        firstReceipt: {
          text: formatter.format(Number(firtsReceipt)),
          type: "",
          link: "",
          key: "float-right-cell",
        },
        sequential: {
          text: formatter.format(Number(sequential)),
          type: "",
          link: "",
          key: "float-right-cell",
        },
      };
      prices.push(newItem);
    });

    prices.sort((a: any, b: any) => a.periodicity.order - b.periodicity.order);
    return prices;
  };

  getPaymentTableColumns = () => {
    const paymentTableColumns = [
      {
        text: "Forma de pago",
        filter: false,
        ascendent: true,
        key: "align-right-cell",
      },
      {
        text: "1er Recibo",
        filter: false,
        ascendent: true,
        key: "align-right-cell",
      },
      {
        text: "Sucesivos",
        filter: false,
        ascendent: false,
        key: "align-right-cell",
      },
    ];

    return paymentTableColumns;
  };

  getRiskInfoRows = () => {
    return [];
  };

  getRiskInfoColumns = () => {
    const riskInfoColumns = [
      {
        text: "Profesión",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
      {
        text: "Actividad de riesgo",
        filter: false,
        ascendent: true,
        key: "dimmed-mini-title",
      },
    ];

    return riskInfoColumns;
  };

  getMandatoryGuaranteeTableRows = () => {
    return [];
  };

  getMandatoryGuaranteeTableColumns = () => {
    const mandatoryGuaranteeTableColumns = [
      {
        text: "GARANTÍAS OBLIGATORIAS (PARA TODOS LOS ASEGURADOS)",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
    ];

    return mandatoryGuaranteeTableColumns;
  };

  getOptionalGuaranteeTableRows = () => {
    return [];
  };

  getOptionalGuaranteeTableColumns = () => {
    const optionalGuaranteeTableColumns = [
      {
        text: "GARANTÍAS OPCIONALES", //(ESTANCIA EN EL EXTRANJERO SUPERIOR A 90 DÍAS)",
        filter: false,
        ascendent: true,
        key: "entire-mini-title",
      },
    ];

    return optionalGuaranteeTableColumns;
  };

  showExitModal = () => {
    this.setState({
      showLeftInfoModal: true,
      leftInfoModalClassName: "",
      leftInfoModalImg: images.WarningError,
      leftInfoModalTitle: "¿Desea salir del proceso?",
      leftInfoModalText: "",
      leftInfoModalButtonText: "Salir",
      leftInfoModalClick: () => {
        this.props.history.push("");
      },
    });
  };

  validateHandler = () => {
    const { takerEmail } = this.state;
    const { data } = this.props;
    if (!isValidEmail(takerEmail.value)) {
      this.setState({
        ...this.state,
        takerEmail: {
          ...takerEmail,
          errorCode: "invalid-email",
        },
      });
    } else {
      // Guardamos el email en el nombre del taker para que aparezca el email en el listado de pendientes
      data.taker = {
        email: takerEmail.value,
        name: takerEmail.value,
      };

      this.setState(
        {
          ...this.state,
          takerEmail: {
            ...takerEmail,
            errorCode: "",
            labelText: "Correo electrónico",
          },
        },
        () => {
          this.closeEmailModal();
          this.sendPdfEmail();
        }
      );
    }
  };

  getDescriptionCoverage = (codeCoverage: string) => {
    const { coverages } = this.props.data.insuredData[0];

    const coverage = coverages.filter((cov: any) => cov.code === codeCoverage);

    return coverage.length > 0 ? coverage[0].description : "";
  };

  getCoveragesQuoteAmount = (
    response: any,
    payType: string,
    codeCoverage: string,
    isFormat: boolean = true,
    numRiesgo: number = 0,
  ): ICoveragesQuoteAmount => {
    const coverageQuoteAmount = response.IMPORTES_ASEGURADO_GARANTIA.filter(
      (cov: any) =>
        cov.PERIODO_COBRO === payType && cov.GARANTIA === codeCoverage
    );
    if (!coverageQuoteAmount) {
      return { PRIMANETA: "", PRIMATOTAL: "", TOTAL_IMPUESTOS: "" };
    }
    let coverage: any = {}
    if (numRiesgo > 0) {
      coverage = coverageQuoteAmount.find((cov: any) => {
        return Number(cov.NUMERO_DE_RIESGO.toString()) === numRiesgo
      });
    } else {
      coverage = coverageQuoteAmount[0]
    }
    return {
      PRIMATOTAL: isFormat
        ? formatter.format(Number(coverage.PRIMATOTAL))
        : coverage.PRIMATOTAL,
      PRIMANETA: isFormat
        ? formatter.format(Number(coverage.PRIMANETA))
        : coverage.PRIMANETA,
      TOTAL_IMPUESTOS: isFormat
        ? formatter.format(Number(coverage.TOTAL_IMPUESTOS))
        : coverage.TOTAL_IMPUESTOS,
      PERIODO_COBRO: coverage.PERIODO_COBRO,
    };
  };

  checkIfInsuredExists(index: number): boolean {
    const { data } = this.props;
    return data.resPrices.IMPORTES_ASEGURADO_GARANTIA.filter(
      (ins: any) => Number(ins.NUMERO_DE_RIESGO) === index + 1
    ).length > 0
  }

  render() {
    const {
      amountPay,
      informativeCoverageConcepts,
      leftInfoModalButtonText,
      leftInfoModalClassName,
      leftInfoModalClick,
      leftInfoModalCloseButtonText,
      leftInfoModalImg,
      leftInfoModalText,
      leftInfoModalTitle,
      paymentTableColumns,
      paymentTableRows,
      showEmailModal,
      showLeftInfoModal,
      takerEmail,
      totalPremiumConcepts,
      healthCoverages,
      modality,
    } = this.state;
    const { policy, data } = this.props;

    let pageTitle: string;
    let goBack: JSX.Element;
    let showPendantFooter: boolean;
    // eslint-disable-next-line no-undef

    if (this.state.simulationDbData) {
      const daysFromCreation = moment
        .duration(moment().diff(moment(this.state.simulationDbData.createdAt)))
        .asDays();

      pageTitle = `DETALLE SIMULACIÓN ${daysFromCreation >= 30 ? "EXPIRADA" : "PENDIENTE"
        }`;
      goBack = (
        <GoBack
          text={`VOLVER A SIMULACIONES PENDIENTES`}
          onClick={() => this.props.history.push("/simulacion/pendiente")}
        />
      );
      showPendantFooter = true;
    } else {
      pageTitle = "RESUMEN DE LA SIMULACIÓN";
      goBack = <GoBack text={"VOLVER"} onClick={() => this.props.history.push("/simulacion/nueva/dental/precios")} />;
      showPendantFooter = false;
    }
    return (
      <NewSimulationContainer>
        <SimulationResultContainer
          colorSpan={setColorSpan(policy.product.ln_nameItem)}
        >
          <div className="detail-pendant-simulation-back bottomMargin10">
            {goBack}
          </div>
          <div className="colSin-100 height80"></div>
          <div className="topMargin20">
            <h5>{pageTitle}</h5>
          </div>

          <div className="result-simulation-special">
            <div className="result-simulation-special-title">
              <Title03Text>
                {
                  <p>
                    {`Nº ${data.resPrices.DATOS_COTIZACION.COTIZACION}`}
                  </p>
                }
              </Title03Text>
              <div>
                <p className="price">
                  {formatter.format(Number(amountPay.PRIMATOTAL))} <span>{data.priceData.price.paymentTime}</span>
                </p>
              </div>
            </div>

            <div className="result-simulation-special-container">
              <div className="result-simulation-special-item">
                <HeadText>
                  <p>Póliza {policy.product.products_name ? policy.product.products_name : ''}</p>
                </HeadText>
              </div>
              <div className="result-simulation-special-item">
                <div className="result-simulation-special-subitem">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Fecha efecto</p>
                    </NoteText>
                  </div>
                  <div className="result-simulation-special-subitem-content">
                    <WebText>
                      <p>{data.mediatorData.startDate}</p>
                    </WebText>
                  </div>
                </div>
                <div className="result-simulation-special-subitem">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>Expira</p>
                    </NoteText>
                  </div>
                  <div className="result-simulation-special-subitem-content">
                    <WebText>
                      <p>
                        {moment()
                          .add(1, "month")
                          .format("DD/MM/YYYY")
                          .toLocaleString()}
                      </p>
                    </WebText>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="result-simulation-container">
            {data.resPrices &&
              data.coverages &&
              healthCoverages &&
              healthCoverages.length > 0 &&
              data.coverages.length > 0 && (
                <div className="result-simulation-item float">
                  <div className="result-simulation-special-title">
                    <Title03Text>
                      <p>Modalidad</p>
                    </Title03Text>
                  </div>
                  <div className="result-simulation-item-content">
                    <DentalCampaignItem
                      checked={false}
                      index={1}
                      onCheckInsured={() => { }}
                      coveragesPriceAmount={data.resPrices.IMPORTES_COTIZACION_GARANTIA.filter(
                        (cov: any) =>
                          cov.PERIODO_COBRO ===
                          data.priceData.price.payType
                      )}
                      coverages={
                        data.resPrices.COBERTURAS
                      }
                      totalPremiumConcepts={totalPremiumConcepts}
                      informativeCoverageConcepts={informativeCoverageConcepts}
                      totalPrice={
                        data.resPrices.IMPORTES_COTIZACION_GARANTIA.filter(
                          (item: any) =>
                            item.PERIODO_COBRO ===
                            data.priceData.price.payType
                        )[0].PRIMATOTAL
                      }
                      dentalCoverages={healthCoverages}
                      modalityName={modality}
                    />
                  </div>
                </div>
              )}

            <div className="result-simulation-item float">
              <div className="result-simulation-item-title">
                <Title03Text>
                  <p>Miembros de la póliza</p>
                </Title03Text>
              </div>
              <div className="result-simulation-item-content-table">
                <div className="tabs">
                  {data.insuredData.map(
                    (insured: any, index: number) => (

                      <DentalInsuredResultItem
                        age={Math.floor(
                          moment().diff(
                            moment(insured.birthDate, "DD/MM/YYYY", true),
                            "years",
                            true
                          )
                        )}
                        birthDate={insured.birthDate}
                        insured={insured}
                        checked={false}
                        id={index + 1}
                        index={index + 1}
                        key={index}
                        onChange={() => "this.handleRemoveInsured(index)"}
                        onClick={() => "this.handleRemoveInsured(index)"}
                        result={true}
                        coverages={data.coverages}
                        coverageAmount={healthCoverages}
                        onCheckInsured={() => ''}
                      />
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="result-simulation-item float">
              <div className="result-simulation-item-title">
                <Title03Text>
                  <p>Desglose por forma de pago</p>
                </Title03Text>
              </div>
              <div className="result-simulation-item-content">
                <div className="result-simulation-item-content-table">
                  <MainTable
                    className="table noZebra"
                    columns={paymentTableColumns}
                    rows={paymentTableRows}
                    keysRow={["periodicity", "firstReceipt", "sequential"]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="result-simulation-footer">
            <ul>
              <NoteText>
                <li key="">* Impuesto sobre las primas de seguro incluido.</li>
              </NoteText>
            </ul>
          </div>

          {showPendantFooter ? (
            <div>
              <div className="detail-pendant-simulation-divider" />
              <div className="detail-pendant-simulation-footer">
                <ul>
                  <NoteText>
                    <li>
                      * Encontrará la nota informativa anexa en el documento
                      Resultado de Simulación PDF, que puede descargar
                    </li>
                  </NoteText>
                  <NoteText>
                    <li>
                      * Este presupuesto tiene validez durante 30 días
                      naturales, siempre que se mantengan las circunstancias
                      declaradas por los asegurados. La aceptación del proyecto
                      está supeditada a la cumplimentación de la solicitud de
                      seguro y cuestionarios de salud asegurados. Así mismo,
                      ASISA se reserva el derecho de la aceptación del riesgo.
                    </li>
                  </NoteText>
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}
        </SimulationResultContainer>

        <InflowSidebarContainer>
          <div className="inflow-sidebar">
            <div className="inflow-buttons-container">
              <div className="inflow-buttons-item">
                <MainButton
                  text="Contratar"
                  onClick={() => this.hireSimulation()}
                />
              </div>
              <div className="inflow-buttons-item">
                <MainButton
                  /* disabled={
                    !responseSimulation.documents ||
                    !responseSimulation.documents[0]
                  } */
                  type="full-light"
                  text="Descargar PDF"
                  icon
                  iconImg={images.IconDownloadWhite}
                  onClick={() => this.downloadSimulation()}
                />
              </div>
              <div className="inflow-buttons-item">
                <MainButton
                  /* disabled={
                    !responseSimulation.documents ||
                    !responseSimulation.documents[0]
                  } */
                  type="full-light"
                  text="Enviar PDF por Email"
                  icon
                  iconImg={images.IconContactCollapsedWhite}
                  onClick={() => this.sendPdfEmail()}
                />
              </div>
              <div
                className="inflow-buttons-item"
              >
                <MainButton
                  type="light"
                  text="Terminar"
                  onClick={() => this.showExitModal()}
                />
              </div>
            </div>
          </div>
        </InflowSidebarContainer>

        <LeftInfoModal
          img={leftInfoModalImg}
          className={leftInfoModalClassName}
          mainTitle={leftInfoModalTitle}
          mainText={leftInfoModalText}
          buttonText={leftInfoModalButtonText}
          close={() => this.closeLeftModal()}
          active={showLeftInfoModal}
          onClickAccept={leftInfoModalClick}
          closeButtonText={leftInfoModalCloseButtonText}
        />
        <LeftInfoModal
          img={images.IconContactCollapsedWhite}
          className={leftInfoModalClassName}
          mainTitle={leftInfoModalTitle}
          mainText={leftInfoModalText}
          buttonText={leftInfoModalButtonText}
          close={() => this.closeEmailModal()}
          active={showEmailModal}
          onClickAccept={leftInfoModalClick}
          withInput={{
            labelText: takerEmail.labelText,
            value: takerEmail.value,
            errorCode: takerEmail.errorCode,
            onChange: (value: string) =>
              this.setState({
                ...this.state,
                takerEmail: { ...takerEmail, value },
              }),
          }}
        />
      </NewSimulationContainer>
    );
  }
}

export default withGeneral(withPayTypes(withPolicy(withRouter(DentalSimulationResultLayout))));
