import styled from "styled-components";
import { colors } from "../../../../assets";

export const NewInsuredContainer = styled.div`
  
  .subTitle {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: ${colors["TX-001-50"]};
    margin-bottom: 17px;
  }

  .container-background {
    background-color: #fbfbfb;
    padding: 20px;
  }

  .form-width {
    width: calc(100% - 290px) !important;
  }

  .margin-top-30 {
    margin-top: 30px;
  }

  .title {
    margin-bottom: 38px;
    color: ${colors["PR-001-100"]};
  }

  .col-50 {
    width: 50%;
  }

  .margin-top-50 {
    margin-top: 50px;
  }

  .personal-data-row {
    width: 100%;
    display: flex;
  }

  .step-container {
    display: flex;
    flex-direction: row; 
    gap: 50px;
  }

  .step {
    width: calc(100% - 290px)
  }

  .personal-data-item {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
