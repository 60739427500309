import React, { Component } from "react";
import { CampaignsCreateStepContainer } from "./campains-create-steps-style";
import CalendarBox from "../../../../components/calendar-box/calendar-box";
import SelectBox from "../../../../components/select-box/select-box";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { ICampaingsCreateStep3State } from "./campaings-create-step-3";
const summaryDisplayOptions = [
  { label: "Sí, semanal", value: "WEEKLY" },
  { label: "Sí, mensual", value: "MONTHLY" },
  { label: "No", value: "NO" }
];

interface ICampaingsCreateStep5Props {
  goNext: boolean;
  onNext: (state: ICampaingsCreateStep5State) => void;
  savePrizes: (state: ICampaingsCreateStep3State) => void;
  previousState?: ICampaingsCreateStep5State;
  prizes?: ICampaingsCreateStep3State;
  isContest: boolean;
}

export interface ICampaingsCreateStep5State {
  summaryDisplay: string;
  error: string;
  items: any[];
}

class CampaingsCreateStep5 extends Component<
  ICampaingsCreateStep5Props,
  ICampaingsCreateStep5State
  > {
  constructor(props: ICampaingsCreateStep5Props) {
    super(props);
    this.state = {
      summaryDisplay: "",
      error: "",
      items: []
    };
  }

  componentDidMount() {
    const { previousState, prizes } = this.props;
    if (previousState) this.setState(previousState);
    this.setState({
      items: prizes
        ? prizes.formData.map((prize: any, index: number) => ({
          ...prize,
          id: index
        }))
        : []
    });
  }

  componentDidUpdate(prevProps: ICampaingsCreateStep5Props) {
    const { goNext, previousState } = this.props;
    if (goNext && prevProps.goNext !== goNext) {
      this.handleNext();
    }
    if (previousState && prevProps.previousState !== previousState) {
      this.setState(previousState);
    }
  }

  handleNext() {
    const { onNext } = this.props;
    const { summaryDisplay } = this.state;
    let isCorrect = true;
    let error = "";
    if (!summaryDisplay || summaryDisplay === "") {
      isCorrect = false;
      error = "required";
    }
    this.setState({ error }, () => {
      if (isCorrect) onNext(this.state);
    });
  }

  itemRenderer = (item: any, index: number): JSX.Element => {
    return (
      <div className="campaigns-drag-card">
        <div className="campaigns-drag-card__text">{item.name}</div>
      </div>
    );
  };

  handleRLDDChange = (reorderedItems: Array<any>) => {
    const { prizes, savePrizes } = this.props;
    if (prizes) {
      prizes.formData = reorderedItems;
      savePrizes(prizes);
    }
    this.setState({ items: reorderedItems });
  };

  render() {
    const { isContest } = this.props;
    const { summaryDisplay, error, items } = this.state;
    return (
      <CampaignsCreateStepContainer>
        <div className="col-50">
          {/*isContest &&*/ items && (
            <div className="section">
              <div className="section__title">{"ORDEN DE LOS PREMIOS"}</div>
              <div className="section__subtitle">
                {
                  "Arrastre para ordenar los siguiente premios por orden de atribución."
                }
              </div>
              <div className="campaigns-drag-container">
                <div className="campaigns-drag-header">
                  {items.map((item, index) => (
                    <div className="campaigns-drag-header__item">
                      {`${index + 1}º PREMIO`}
                    </div>
                  ))}
                </div>

                <div>
                  <RLDD
                    items={items}
                    cssClasses="list-container"
                    itemRenderer={this.itemRenderer}
                    onChange={this.handleRLDDChange}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="section">
            <div className="section__title">{"VISUALIZAR RESUMEN"}</div>
            <div className="section__subtitle">
              {
                "Indique si quiere que los mediadores vean un resumen de la campaña dividida por periodos."
              }
            </div>
            <div>
              <SelectBox
                optionsText={summaryDisplayOptions}
                optionKey={"value"}
                optionValue={"label"}
                placeholder={"Seleccione la visualización"}
                errorCode={error}
                initialValue={summaryDisplay}
                defaultValue={summaryDisplay}
                onChange={value => {
                  this.setState({ summaryDisplay: value });
                }}
                labelText={"VISUAIZACIÓN"}
              />
            </div>
          </div>
        </div>
      </CampaignsCreateStepContainer>
    );
  }
}

export default CampaingsCreateStep5;
