import React, {Component} from 'react';
import {LoaderContainer} from './loader-style';

interface LoaderState {
    active: boolean;
    isSlowProcess?: boolean;
}

interface LoaderProps {
  isSlowProcess?: boolean;
}

class Loader extends Component<LoaderProps, LoaderState> {

    state: LoaderState = {
        active: true,
        isSlowProcess: this.props.isSlowProcess
    }

    render() {

    const {active, isSlowProcess} = this.state;

      return (
        <LoaderContainer className={`${(active) ? 'active': ''}`}>
            <div className="loader-holder">
              { isSlowProcess && <p>Este proceso puede tardar varios segundos, espere por favor.</p> }
              <div className="spinner"></div>
            </div>
        </LoaderContainer>
      );
    }
  }

export default Loader;