import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CampaignsConfigParticipantsContainer } from "./campaigns-config-participants-style";
import MainTable from "../../../components/main-table";
import Pagination from "../../../components/pagination";
import { images } from "../../../assets";
import GoBackLayout from "../../../components/go-back/go-back-layout";
import SelectBox from "../../../components/select-box/select-box";
import CampaignsConfigurationServices from "../../../services/CampaignsConfigurationServices";
import ModalParticipant from "./modal-participant/modal-participant";
import ZeroContent from "../../../components/zero-content/zero-content";
import FilterInput from "../../../components/filter-input/filter-input-layout";

interface ICampaignsConfigParticipantsProps extends RouteComponentProps {
  match: any;
  history: any;
}

interface ICampaignsConfigParticipantsState {
  rows: any[];
  campaign: any;
  page: number;
  totalPages: number;
  modal: string;
  selectedParticipant: any;
  selectedParticipantPrize: string;
  searchText: string;
  contentDownloaded: boolean;
}

const NUM_ROWS = 6;

class CampaignsConfigParticipants extends React.Component<
  ICampaignsConfigParticipantsProps,
  ICampaignsConfigParticipantsState
  > {
  constructor(props: ICampaignsConfigParticipantsProps) {
    super(props);
    this.state = {
      rows: [],
      page: 1,
      campaign: null,
      totalPages: 0,
      modal: '',
      searchText: '',
      selectedParticipant: null,
      selectedParticipantPrize: '',
      contentDownloaded: false,
    };
  }

  componentDidMount() {
    this.getCampaign();
  }

  async getCampaign() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const args: any = {
      id,
      setLoading: () => { }
    };
    const resp: any = await CampaignsConfigurationServices.getCampaign(args);
    this.setState({ campaign: resp }, () =>
      this.getParticipants());
  }

  async getParticipants(searchValue?: string) {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { page } = this.state;
    const args: any = {
      id,
      loader: true,
      setLoading: () => { },
      skip: page - 1,
      take: NUM_ROWS,
      search: searchValue
    }
    const resp = await CampaignsConfigurationServices.getParticipants(args);
    const mediators: any = {};
    resp.items.forEach((mediatorProduct: any) => {
      if (!mediators[mediatorProduct.mediator]) {
        mediators[mediatorProduct.mediator] = {
          mediator: mediatorProduct.mediator,
          mediatorEmail: mediatorProduct.mediatorEmail,
          mediatorType: mediatorProduct.mediatorType,
          products: []
        }
      }
      mediators[mediatorProduct.mediator].products.push({
        product: mediatorProduct.product,
        statRange: mediatorProduct.statRange,
        insureds: mediatorProduct.insureds,
        points: mediatorProduct.points,
        policies: mediatorProduct.policies,
        sum: mediatorProduct.sum,
      })
    })
    this.setState({ totalPages: Math.ceil(resp.count / NUM_ROWS) }, () => {
      this.getRows(mediators);
    });
  }

  getMediatorProgress(mediator: any) {
    const { campaign } = this.state;
    let progress = "No conseguido";
    const prizes = campaign.prizes;
    const prizeAchievements: any[] = [];
    prizes.forEach((prize: any) => {
      let conditionsOk: boolean | null = null;
      prize.conditions.forEach((condition: any) => {
        condition.products.forEach((product: string) => {
          const mediatorProduct = mediator.products.filter((prod: any) => prod.product === product);
          if (mediatorProduct.length > 0) {
            const conditionValue = parseInt(condition.value, 10);
            switch (condition.unit) {
              case 'POINTS':
                conditionsOk = conditionsOk === null ? conditionValue >= mediatorProduct.points
                  : conditionsOk && conditionValue >= mediatorProduct.points;
                break;
              case 'POLICY':
                conditionsOk === null ? conditionValue >= mediatorProduct.policies
                  : conditionsOk && conditionValue >= mediatorProduct.policies;
                break;
              case 'PREMIUM':
                conditionsOk = conditionsOk === null ? conditionValue >= mediatorProduct.sum
                  : conditionsOk && conditionValue >= mediatorProduct.sum;
                break;
            }
          }
        })
      });
      prizeAchievements.push(conditionsOk === null ? false : conditionsOk);
    });
    let achived = true;
    prizeAchievements.reverse().forEach((prizeAchived: boolean, index: number) => {
      achived = achived && prizeAchived;
      if (achived) {
        progress = `${index + 1}º premio`
      }
    })
    return progress;
  }

  getRows(mediators: any) {
    const rows: any[] = [];
    Object.values(mediators).forEach((mediator: any) => {
      const progress = this.getMediatorProgress(mediator);
      rows.push({
        mediatorCod: {
          text: mediator.mediator,
          type: "",
          key: "highlighted"
        },
        type: {
          text: mediator.mediatorType,
          type: "",
          key: "highlighted"
        },
        progress: {
          text: progress,
          type: "",
          key: "highlighted"
        },
        buttons: {
          key: "buttons",
          items: [
            {
              img: images.IconDetailLightBlue,
              onClick: () => this.setState({
                modal: 'modal-participant',
                selectedParticipant: mediator,
                selectedParticipantPrize: progress
              })
            }
          ]
        }
      });
    });
    this.setState({ rows },
      () => this.setState({ contentDownloaded: true }));
  }

  setPage(page: number) {
    this.setState({ page }, () => this.getParticipants());
  }

  render() {
    const {
      rows,
      page,
      totalPages,
      modal,
      campaign,
      selectedParticipant,
      selectedParticipantPrize,
      contentDownloaded,
      searchText
    } = this.state;
    return (
      <>
        <CampaignsConfigParticipantsContainer>
          <div className="campaigns-config-participants-header">
            <div className="campaigns-config-participants-header__back">
              <GoBackLayout
                onclick={() => this.props.history.goBack()}
                text={campaign ? campaign.name : ''}
              />
            </div>
            <div className="campaigns-config-participants-header__title">
              {"Participantes"}
            </div>
          </div>
          <div className="campaigns-config-participants-filters">
            <div className="campaigns-config-participants-filters__search">
              <FilterInput
                img={images.IconSearchBlue}
                placeholder={"Introduzca sus datos de búsqueda"}
                onChange={(value: any) => this.setState({ searchText: value }, () => {
                  if (value === '') this.getParticipants('')
                })}
                onEnter={(value: string) => value !== '' && this.getParticipants(searchText)}
              />
            </div>
            <div className="campaigns-config-participants-filters__dropdown">
              <SelectBox
                className={"ghost"}
                placeholder={"Tipo de público"}
                optionKey={"value"}
                optionValue={"label"}
                onChange={vale => { }}
                optionsText={[]}
              />
            </div>
            <div className="campaigns-config-participants-filters__dropdown">
              <SelectBox
                className={"ghost"}
                placeholder={"Progreso"}
                optionKey={"value"}
                optionValue={"label"}
                onChange={vale => { }}
                optionsText={[]}
              />
            </div>
          </div>
          {rows.length > 0 ? (
            <>
              <div className="campaigns-config-participants-table">
                <MainTable
                  columns={this.columns}
                  rows={rows}
                  keysRow={this.keyRows}
                />
              </div>
              <div className="campaigns-config-participants-pagination">
                <Pagination
                  page={page}
                  limit={totalPages}
                  callback={page => this.setPage(page)}
                />
              </div>
            </>
          ) : contentDownloaded && (
            <ZeroContent text={'Para que no hay participantes todavía.'} />
          )}
        </CampaignsConfigParticipantsContainer>
        {modal === 'modal-participant' && (
          <ModalParticipant
            campaign={campaign}
            participant={selectedParticipant}
            selectedParticipantPrize={selectedParticipantPrize}
            close={() => this.setState({ modal: '' })}
          />
        )}
      </>
    );
  }

  keyRows = ["mediatorCod", "type", "progress", "buttons"];
  columns = [
    {
      text: "cod. mediador",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "tipo público",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "progreso",
      filter: false,
      ascendent: true,
      key: ""
    },
    {
      text: "",
      filter: false,
      ascendent: true,
      key: ""
    }
  ];
}

export default withRouter(CampaignsConfigParticipants);
