import styled from "styled-components";
import { colors } from "../../../../assets";
import { BodyTextContainer } from "../../../../components/components-text/body-text/body-text-styles";
import { NoteTextContainer } from "../../../../components/components-text/note-text/note-text-styles";

export const DetailClientsPoliciesContainer = styled.div`
    font-family: 'museo sans';
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }

    .row::after {
      content: "";
      clear: both;
      display: table;
    }
    
    [class*="col-"] {
      float: left;
      padding: 15px;
    }
    
    .col-1 {width: 8.33%;}
    .col-2 {width: 16.66%;}
    .col-3 {width: 25%;}
    .col-4 {width: 33.33%;}
    .col-5 {width: 41.66%;}
    .col-6 {width: 50%;}
    .col-7 {width: 58.33%;}
    .col-8 {width: 66.66%;}
    .col-9 {width: 75%;}
    .col-10 {width: 83.33%;}
    .col-11 {width: 91.66%;}
    .col-12 {width: 100%;}

    .padding0 {
      padding: 0;
    }

    .title{
      font-size: 2vw;
    }

    .conditions{
      border-bottom: 1px solid ${colors["PR-001-100"]};
    } 

    .tabs {
      font-size: 18px;
      font-weight: bold;
      color: white;
      margin-left: 41px;
      width: 100%;

      ul{
        margin-left: -40px;
        border-bottom: 1px solid ${colors["PR-001-100"]};
      }

      li{
        text-align: center;
        margin: 0 5px 0 0;
        padding: 0px 20px 0px 20px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        cursor: pointer;
        height: 50px;
        line-height: 50px;
        text-transform: uppercase;
        min-width: 210px;
      }
    }

    .tab-selected {
      background: ${colors["PR-001-100"]};
    }

    .tab-not-selected{
      background: #f1f1f1;
      color: ${colors["primaryColor"]}; 
      font-weight: 400;
    }

  .detail-clients-policies-back {
    margin-bottom: 30px;
  }

  .detail-clients-policies-special {
    padding: 60px;
    margin-bottom: 48px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
  }

  .detail-clients-policies-special-title {
    color: ${colors["PR-001-100"]};
    border-bottom: 1px solid ${colors["PR-001-100"]};
    padding-bottom: 12px;
    margin-bottom: 24px;
  }

  .detail-clients-policies-special-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .detail-clients-policies-special-item {
    display: flex;
  }

  .detail-clients-policies-special-subitem {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  .detail-clients-policies-special-subitem-title {
    text-transform: uppercase;
    color: ${colors["TX-001-30"]};
    margin-bottom: 6px;
  }

  .detail-clients-policies-special-subitem-content {
    color: ${colors["TX-001-100"]};
  }

  .detail-clients-policies-item-content-table {
    margin-bottom: 55px;
    overflow-x: auto;
    min-width: 100%;
    max-width: 1250px;

    &:last-child {
      margin-bottom: 0;
    }

    thead {
      /* border-bottom: 1px solid ${colors["PR-001-100"]}; */
    }

    th {
      /* padding: 8px 0;
      color: ${colors["TX-001-30"]}; */
      .highlighted {
        /* color: ${colors["TX-001-50"]}; */
      }
    }

    td {
      /* padding: 8px 0; */

      ${BodyTextContainer} {
        display: inline-block;
        &.highlighted {
          /* font-weight: 600; */
        }
      }

    }

    .main-table-row {
      &:first-child {
        td {
          /* padding-top: 20px; */
        }
      }

      &:hover {
        /* box-shadow: none; */
      }
    }
  }

  .detail-clients-policies-item-content-advise {
    color: ${colors["TX-001-30"]};
  }

  .detail-clients-policies-item-content-capital-text {
    margin-bottom: 50px;

    p {
      color: ${colors["TX-001-50"]};
    }

    span {
      color: ${colors["TX-001-100"]};
      margin-left: 8px;
    }
  }

  .detail-clients-policies-container {
    margin-bottom: 50px;
  }

  .detail-clients-policies-item {
    padding: 20px;
    margin-bottom: 48px;
    background: ${colors["detail-item-background"]};
    border-radius: 3px;
  }

  .detail-clients-policies-item-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 40px;
  }

  .detail-clients-policies-footer {
    padding-top: 37px;
    margin-bottom: 80px;
    color: ${colors["PR-001-100"]};

    ${NoteTextContainer} {
      line-height: 1.5em;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .data-taker-container {
    margin-bottom: 30px;
  }

  .data-taker-name {
    font-size: 1vw;
    line-height: 26px;
    color: ${colors["TX-001-100"]};
    font-weight: 500;
    margin-bottom: 22px;
  }

  .data-taker-info {

  }

  .data-taker-info-row {
    margin-bottom: 26px;
    display: flex;
  }

  .data-taker-info-item {
    &.col-33 {
      width: 33%;
    }
  }

  .data-taker-info-item-title {
    font-size: 12px;
    line-height: 14px;
    color: ${colors["TX-001-30"]}
    margin-right: 14px;
    text-transform: uppercase;
    display: inline-block;
  }

  .data-taker-info-item-text {
    font-size: 14px;
    line-height: 20px;
    color: ${colors["TX-001-100"]};
    display: inline-block;
  }

  .detail-policies-conditions {
    font-family: 'museo sans';
  }

  .detail-policies-conditions-title {
    font-weight: 500;
    font-size: 1vw;
    color: #848FA1;
    text-transform: uppercase;

    &.only-title {
      border-bottom: 1px solid #004F8B;
      margin-bottom: 20px;
      padding-bottom: 12px;
    }
  }

  .detail-clients-policies-item-content {
    font-family: 'museo sans';
  }

  .detail-clients-policies-comments-wrapper {
    .detail-clients-policies-comments-content {
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pendant-simulation-pagination {
    font-family: "museo sans";
    color: ${colors["PR-001-100"]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
  }
  
  .detail-policies-conditions-description {
    font-weight: 300;
    font-size: 0.8vw;
    color: ${colors["TX-001-100"]};    
    line-height: 1.5em;
    textarea {
      border: 1px solid ${colors["SC-001-100"]};
      padding: 8px 12px;
      width: 100%;
      resize: none;
      outline: none;
      font-weight: 300;
      font-size: 16px;
      color: ${colors["TX-001-100"]};
      line-height: 1.5em;
      min-height: 120px;
    }
  }

  .detail-policies-conditions-error {
    font-size: 14px;
    font-family: 'museo sans';
    color: red;
    margin-top: 6px;
  }

  .detail-clients-policies-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .detail-clients-policies-item-title {
      margin: 0;
      padding: 0;
    }
  }

  .detail-policies-conditions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid #004F8B;
    margin-bottom: 20px;
    padding-bottom: 12px;

    .button-icon-container {
      display: flex;
      align-items: center;

      .button-icon-item {
        &:first-child {
          button {
            border-radius: 5px 0 0 5px;
          }
        }
        &:last-child {
          button {
            border-radius: 0 5px 5px 0;
          }
        }
      }

    }
  }

  .button-icon-container {
    border: none;

    button {
      background: none;
      padding: 0;
      min-width: 36px;
      min-height: 36px;
      border-radius: 5px;
      border: none;
      background: #004F8B;
      cursor: pointer;
      transition: .3s all ease-in-out;

      &:hover {
        background: #023f6d;
      }

      img {
        max-width: 20px;
      }

    }
  }

  @media (max-width: 1370px){
    .data-taker-info-item-title {
      display: block;
      margin-bottom: 6px;
    }
  }

  @media (max-width: 980px){
    .data-taker-info-item-title {
      display: block;
      margin-bottom: 6px;
    }

    .data-taker-info-item {
      margin-right: 22px;
    }
  }

  .filtersMargin{
    margin-bottom: 20px;
  }

  .floatButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 100px;
  }
`;
