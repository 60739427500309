import styled from "styled-components";
import { BreadcrumbTextContainer } from "../../components/components-text/breadcrumb-text/breadcrumb-text-style";
import { colors } from "../../assets";

export const CampaignsDetailContainer = styled.div`
  .new-hiring-flow-name {
    margin-bottom: 17px;
    ${BreadcrumbTextContainer} {
      color: ${colors["TX-001-30"]};
    }
  }
  .back-arrow-container {
    margin-bottom: 70px;
  }

  .new-hiring-flow-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .new-hiring-flow-status-guide {
    display: flex;
    align-items: center;
  }

  .new-hiring-title {
    color: ${colors["PR-001-100"]};
    margin-bottom: 24px;
  }

  .new-hiring-subtitle {
    color: ${colors["TX-001-80"]};
    margin-bottom: 50px;
  }

  .new-hiring-navigate-container {
    display: flex;
    margin-top: 122px;
    margin-bottom: 100px;
  }

  .new-hiring-navigate-item {
    width: 100%;
    max-width: 180px;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  .campaigns-card-week-item {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .campaigns-card-week-cards {
    display: flex;
    margin-bottom: 120px;
    > div {
      width: 100%;
    }
  }

  .campaigns-card-week-cards-total-count {
    width: 154px;
    height: 209px;
    padding: 32px 16px 18px;
    font-family: "museo sans";
    border-radius: 3px;
    background: rgba(179, 202, 220, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .campaigns-card-week-cards-total-count-title {
    color: #004f8b;
    margin-bottom: 8px;
  }

  .campaigns-card-week-cards-total-count-min {
    color: #b3cadc;
  }
  .campaigns-card-week-cards-total-count-item {
    margin-bottom: 12px;
    &:last-child  {
      margin-bottom: 0px;
    }
    &__number {
      color: #004f8b;
    }
    &__subtitle {
      color: #b3cadc;
    }
  }
  .campaigns-section-title {
    margin-bottom: 75px;
    line-height: 45px;
    color: #004f8b;
    &__subtitle {
      margin-top: 12px;
    }
  }

  .margin-to-delete {
    margin-bottom: 320px;
  }

  .campaigns-section-subtitle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
    &__text {
      color: #004f8b;
      span  {
        color: #b3cadc;
        font-size: 20px;
        line-height: 28px;
      }
    }
    &__refresh {
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        height: 32px;
        width: 32px;
      }
      &__text {
        text-transform: uppercase;
        color: #6cc9f0;
        width: 100%;
        font-family: Museo Sans;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .campaigns-section-progress {
    margin-bottom: 180px;
  }
`;
