import styled from "styled-components";
import { colors } from "../../assets";
import { MainButtonContainer } from "../main-button/main-button-style";

export const InflowSidebarContainer = styled.div`
  .inflow-sidebar {
    position: fixed;
    top: 110px;
    right: 50px;
    width: 250px;
  }

  .two-buttons-item {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .inflow-buttons-item {
    margin-bottom: 20px;

    ${MainButtonContainer} {
      .main-button-icon {
        margin-right: 11px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .inflow-sidebar-wrapper {
    max-height: calc(100vh - 130px);
    overflow-x: hidden;
  }

  .inflow-sidebar-continue {
    margin-bottom: 20px;
  }

  .inflow-sidebar-saving {
    display: flex;
    align-items: center;
    color: ${colors["TX-001-50"]};
    margin-bottom: 70px;

    img {
      margin-right: 14px;
      max-width: 16px;
    }
  }

  .inflow-sidebar-type {
    overflow: hidden;
    margin-bottom: 18px;
    color: ${colors["PR-001-100"]};
    font-family: "museo sans";
    font-weight: 700;
    border-radius: 8px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
  }

  .inflow-sidebar-type-wrapper {
    padding: 0 12px 12px;
  }

  .inflow-sidebar-type-text {
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .inflow-sidebar-type-title {
    font-size: 24px;
  }

  .inflow-sidebar-members-title {
    text-transform: uppercase;
    color: ${colors["TX-001-30"]};
    margin-bottom: 20px;
  }

  .inflow-sidebar-members-container {
    margin-bottom: 40px;
  }

  .inflow-sidebar-members-item {
    margin-bottom: 16px;

    .insured-card-details {
      right: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .inflow-sidebar-buttons-item {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .error-iban-container {
    margin-bottom: 5px;
    p {
      color: ${colors["ER-001-100"]};
    }
  }

  @media max-height(800px) {
    .inflow-sidebar-wrapper {
      overflow-y: scroll;
    }
  }
`;
