import styled from "styled-components";
import { colors } from "../../assets";

export const DentalInsuredContainer = styled.div`
.selectBox {
    height: auto;
    margin-bottom: 20px;
}

.beneficiaries-table{
    margin-top: 20px;
}
.tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #fff;
    color: #3f3f3f;
    border: 1px solid #f4f4f4;
    font-size: 18px;
    height: 60px;

    &:after {
      content: "❯";
      width: 30px;
      height: 30px;
      text-align: center;
      transition: all 300ms;
      transform: rotate(90deg);
      border-radius: 100%;
      text-align: center;
      line-height: 30px;
      margin-top: -5px;
    }
.col-5 {
    width: 5%;
}
.col-10 {
    width: 10%;
}
.col-15 {
    width: 15%;
}
.col-20 {
    width: 20%;
}
.col-25 {
    width: 25%;
}
.col-2 {
    width: 20%;
}
.col-30 {
    width: 30%;
}
.col-35 {
    width: 35%;
}
.col-40 {
    width: 40%;
}
.col-50 {
    width: 50%;
}
.col-60 {
    width: 60%;
}
.col-70 {
    width: 70%;
}
.result-simulation-special-row {
    width: 100%;
    display: flex;
    float: left;
    margin-bottom: 20px;
}
.salud{
    color: ${colors["PR-001-80"]};
    padding: 5px 10px
    background-color: #f2f2fc
    border-radius: 10%;
    
}

`;
