import { useState } from "react";
import { FormErrors, FormValues } from "./hooks.types";

const useForm = (
  initialValues: FormValues,
  validate: (values: FormValues) => FormErrors,
  isFirstTime: boolean,
) => {
  const [values, setValues] = useState<FormValues>(initialValues);
  const [errors, setErrors] = useState<FormErrors>({});

  const handleChange = (name: string, value: any, step?: string) => {
    setValues((prevValues) => {
      const newValues = step
        ? {
            ...prevValues,
            [step]: {
              ...prevValues[step],
              [name]: value,
            },
          }
        : {
            ...prevValues,
            [name]: value,
          };

    if (!isFirstTime) {
      const { errors } = validate(newValues);
      setErrors(errors);
    }

      return newValues;
    });
  };

  const handleSubmit =
    (callback: (values: FormValues) => void) =>
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
    };

    const handleMultipleChanges = (changes: Partial<FormValues>) => {
      setValues((prevValues) => {
        const newValues = {
          ...prevValues,
          ...changes,
        };
    
        if (!isFirstTime) {
          const { errors } = validate(newValues);
          setErrors(errors);
        }
    
        return newValues;
      });
    };
    

  return {
    values,
    errors,
    setErrors,
    handleChange,
    handleSubmit,
    handleMultipleChanges
  };
};

export default useForm;
