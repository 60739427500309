import styled from "styled-components";
import { colors } from "../../../../../../assets"
import { images } from "../../../../../../assets/images";

export const PolicyDataContainer = styled.div`
.custom-file-upload {
    display: flex;

    input[type="text"] {
      border-radius: 3px 0px 0px 3px;
    }
}

.custom-file-upload-button {

  background-color: #e4e4e4;

  > input[type="file"] {
    display:none;

    ~ div {
      background-color: ${colors["PR-001-100"]};
      color: #fff;
      border-radius: 0px 3px 3px 0px;

      &:before {

        content: url(${images.IconSearchWhite});
        position: absolute;
        width: 15px;
        height: 15px;
        left: 15px;
      }
    }

    &:disabled {
      ~ div {
          background-color: #e2e2e2;
          border-radius: 0px 3px 3px 0px;
          color: #ababab;

          &:before {
            content: url(${images.IconSearchGray});
          }
        }
    }        
  }
}

.uploadedFile {
    background-color: #fff;
    height: 45px;
    width: 96%;
    padding-left: 20px;
    line-height: 42px;   
    margin-left: 2%;
    margin-top: 15px;
    float: left;
    color: ${colors["PR-001-100"]};
    font-weight: bold;

    > div {
      width: 50px;
      height: 43px;
      background: #fff;
      float: right;
    }

    .icon-check-block {
      width: 15px;
      height: 15px;
      border-radius: 100%;
      margin-top: 14px;
      margin-right: 25px;
      background: ${colors["SU-001-100"]};     
      float: left;
      text-align: center;
  
      img {
        margin-bottom: 13px;
        width: 81%;
      }
    }
}

.upload-buttons {
    > div {
      position: relative;
      background-color: #fff;
      color: ${colors["PR-001-100"]};
      border: 1px solid ${colors["PR-001-100"]};
      min-width: 50px
      max-width: 45%;
      transition: all 300ms;

      &:hover {
        background-color: ${colors["PR-001-100"]};
        color: #fff;
      }

      &.disabled {
        position: relative;                     
        pointer-events: none;
        cursor: default;
        background-color: #e2e2e2;          
        color: #ababab;  
        border: none;
        
        .main-button-icon {
          display: none;
        }

        &:before {
          content: url(${images.IconUploadGrey});
          width: 18px;
          height: 18px;
          margin-right: 0px;
        }
      }
}}

.personal-data-wrapper {
  overflow: initial;
  padding-right: 16px;
  padding: 20px;
  background-color: #fbfbfb;
  display: block;
  width: 100%;
  float: left;
  margin-top: 40px;
}
@media (min-width: 320px) {
  .personal-data-wrapper {
    width: 55% !important;
  }
}
@media (min-width: 1201px) {
  .personal-data-wrapper {
    width: 100% !important;
  }
}
  .error {
    input {
      border: 1px solid ${colors["ER-001-100"]};
    }
  }

  .input-box-error-file {
    color: ${colors["ER-001-100"]};
    margin-top: -15px;

    p {
      color: ${colors["ER-001-100"]};
    }
  }

  .input-box-error p {
    color: #e40202;
  }

  .checkbox-container input:not(:checked) {
    color: ${colors["TX-001-50"]};
  }

  outline: none;
  
  &.mandatory {
    .checkbox-container input:checked ~ .checkmark-container {
      background: #f7f7f7;

      .checkmark-item {
        background: ${colors["TX-001-80"]};
      }
    }
  }

  &.optional {
    .checkbox-container input:checked ~ .checkmark-container {
      background: ${colors["SU-001-10"]};

      .checkmark-item {
        background: ${colors["SU-001-100"]};
      }
    }
  }

  .checkbox-container {
    display: flex;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 15px;

    &.noPointer {
      cursor: initial;
    }

    div:nth-of-type(2) {
      line-height: 25px;
      font-size: 16px;
    }

    &:hover {
      input ~ .checkmark {
        background-color: rgba(0, 79, 139, 0.3);
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark-container {
        background: #e8f9f5;
        justify-content: flex-end;

        .checkmark-item {
          background: #40caa9;
        }

        img {
          max-width: 14px;
        }
      }
  }

  .checkbox-error {
    color: red;
    display: block;
  }

  .checkmark-container {
    min-width: 45px;
    background: #e0e0e0;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 14px;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;

    img {
      max-width: 10px;
    }
  }

  .checkmark-item {
    background: ${colors["TX-001-30"]};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    width: 20px;
    height: 20px;    
  }

  .checkmark-container-readonly {
    min-width: 24px;
    background: #ffffff;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 14px;
    transition: 0.3s all ease-in-out;
    border-radius: 5px;

    img {
      max-width: 14px;
    }

    .checkmark-item {
      background: #3f3f3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      width: 20px;
      height: 20px;    
    }
  }
`;