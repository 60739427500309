import styled from "styled-components";
import { images, colors } from "../../../assets";

export const TextareaWrapper = styled.div`
  label {
    display: block;
    text-transform: uppercase;
    margin-bottom: 5px;

    &.required {
      background: url(${images.Required}) no-repeat 0% 50%;
      background-size: 12px;
      padding: 0 0 0 15px;
    }
  }

  textarea {
    width: 100%;
    border: 1px solid #dcdcdc;
    color: #004a8f;
    transition: bacground-color 0.2s ease;
    appearance: none;
    resize: none;
    border-radius: 4px;
  }

  &.error {
    textarea {
      border: 1px solid ${colors["ER-001-100"]};
    }
  }
`;
