import React, { Component } from 'react';
import { MainNavItemContainer } from './main-nav-item-style';
import { images } from '../../assets';
import MenuText from '../components-text/menu-text';
import { NavLink } from 'react-router-dom';
import NoteText from '../components-text/note-text';

/**
 * 
 * las props:
 * - icon: path for an optional img rendered at the left
 * - classname: optional classname
 * - text: main text
 * - dropdownItems: array of objects, keys: link and text
 * 
 */

interface MainNavItemLayoutProps {
    icon?: string;
    className?: string;
    text: string;
    dropdownItems?: dropdownItemsObject[];
    directLink?: any;
    onClickDropdown: Function;
    onClickLink: Function;
    active: string;
    open: string;
}


interface dropdownItemsObject {
    link: string;
    text: string;
}

class MainNavItemLayout extends Component<MainNavItemLayoutProps, {}> {

    public static defaultProps = {
        icon: '',
        className: '',
        text: '',
        directLink: '',
        dropdownItems: '',
        onClickDropdown: () => { },
        onClickLink: () => { },
        active: '',
        open: ''
    }

    onClickDropdownLink() {
        this.props.onClickLink();
        this.props.onClickDropdown();
    }

    render() {
        const { icon, className, text, dropdownItems, directLink, onClickDropdown, onClickLink, active, open } = this.props;

        return (
            <MainNavItemContainer className={`${className} ${open} ${active}`}>
                {dropdownItems ? (
                    <div className="nav-dropdown-container">
                        <div className={`nav-item-head ${open} ${active}`} onClick={() => onClickDropdown()}>
                            <div className="nav-item-head__icon">
                                <img src={icon} />
                            </div>
                            <div className="nav-item-head__text">
                                <MenuText>
                                    {text}
                                </MenuText>
                            </div>
                            <div className="nav-item-head__arrow">
                                <img src={images.IconArrowWhite} alt="flecha desplegable" />
                            </div>
                        </div>
                        <div className="nav-item-content">
                            <ul>
                                {dropdownItems.map((item, i) => (
                                    <li key={i}>
                                        <NoteText className="nav-dropdown-item">
                                            <NavLink exact={item.text.toLocaleLowerCase() === 'home'} to={item.link} onClick={() => onClickLink()}>{item.text}</NavLink>
                                        </NoteText>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ) : (
                        <NavLink exact={text.toLocaleLowerCase() === 'home' || text.toLocaleLowerCase() === 'campañas'} className={`nav-item-head`} to={directLink} onClick={() => this.onClickDropdownLink()}>
                            <div className="nav-item-head__icon">
                                <img src={icon} />
                            </div>
                            <div className="nav-item-head__text">
                                <MenuText>
                                    {text}
                                </MenuText>
                            </div>
                        </NavLink>
                    )}
            </MainNavItemContainer>
        )
    }
}

export default MainNavItemLayout;