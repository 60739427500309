import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const TooltipBoxLightContainer = styled.div`
    display: none;
    font-family: Museo Sans;
    font-style: normal;

    &.active {
        display: block;
    }

    .tooltip-modal {
        color: ${colors['PR-001-100']};
        background: ${colors['PR-001/5']};
        padding: 11px 16px;
        position: relative;
        max-width: 260px;
        border-radius: 3px;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        z-index: 19;
        &:before {
            content: "";
            position: absolute;
            left: ${props => props.leftArrow ? '-4px' : 'inherit'};
            right: ${props => props.leftArrow ? 'inherit' : '3px'};
            transform: rotate(45deg);
            top: calc(50% - 15px);
            width: 30px;
            height: 30px;
            border-radius: 4px;
            background: #F2F6F9;
            z-index: -1;
        }
    }

    .tooltip-title {
        margin-bottom: ${props => props.leftArrow ? '0' : '8px'};
        color: ${colors['NE-004-0']};

        p {
            color: ${colors['PR-001-100']};
            font-size: 14px;
        }
    }

    .tooltip-main {
        font-size: 12px;
    }
`;