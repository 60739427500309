import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import GoBack from "../../../../components/go-back";
import { withGeneral } from "../../../../context/mixin/with-general";
import MainButton from "../../../../components/main-button";
import ReceiptsServices from "../../../../services/ReceiptsServices";
import { DetailClientsReceiptsContainer } from "./detail-clients-receipts-style";
import { ErrorModalContainer } from "../../../../components/error-modal/error-modal-style";
import { ERROR_MESSAGES } from "../../../../constants/errorMessages";
import { IDetailClientsReceiptsProps, IDetailClientsReceiptsState, IDataRow, IDetailReceiptsParams, IReceiptDetailData } from "../dto/detail-clients-receipts-layout-dto";
import { IReceiptDetail, IReceiptDetailDataDto } from "../../../../services/ReceiptsServices-dto";

class DetailClientsReceiptsLayout extends React.Component<IDetailClientsReceiptsProps, IDetailClientsReceiptsState> {
  constructor(props: IDetailClientsReceiptsProps) {
    super(props);
    const routeParams: IDetailReceiptsParams = props.match.params;
    this.state = {
      receiptDataRows: [],
      numRecibo: routeParams.anio && routeParams.numRecibo ? `${routeParams.anio}/${routeParams.numRecibo}` : routeParams.numRecibo,
      mediatorCode: routeParams.mediator ? routeParams.mediator : '',
      serverError: false,
      redirect: false,
      forbidden: (!props.location.state ? true : false),
      noDataMessage: '',
    };
  }

  componentDidMount() {
    if (!this.state.forbidden)
      this.getReceipt();
  }

  getReceipt = async () => {
    const { numRecibo, mediatorCode } = this.state;
    const { setLoading } = this.props;

    const data: IReceiptDetail = {
      codMediador: mediatorCode,
      numRecibo: numRecibo
    }

    const resReceipts = await ReceiptsServices.searchReceiptDetail(
      setLoading,
      true,
      data,
    ).catch(() => {
      this.setState({ serverError: true });
    });

    if (resReceipts !== undefined && resReceipts.receiptDetail.length !== 0) {

      const receiptDataRows: IReceiptDetailData[] = [];
      resReceipts.receiptDetail.forEach((receipt: IReceiptDetailDataDto) => {
        const row: IReceiptDetailData = {
          receipNumber: this.getInputDataRow(receipt.numero_recibo, "", "", "responsive number"),
          state: this.getInputDataRow(receipt.estado_recibo, "", "", "responsive"),
          delegationCode: this.getInputDataRow(receipt.grupo_negocio, "", "", "responsive"),
          certificate: this.getInputDataRow(receipt.orden, "", "", "responsive"),
          mediator: this.getInputDataRow(mediatorCode, "", "", "responsive"),
          policy: this.getInputDataRow(receipt.poliza, "", "", "responsive"),
          taker: this.getInputDataRow(receipt.nom_tomador, "", "", "responsive"),
          startDate: this.getInputDataRow(receipt.fec_efecto ? new Date(receipt.fec_efecto).toLocaleDateString() : "", "", "", "responsive"),
          releaseDate: this.getInputDataRow(receipt.fec_expedicion ? new Date(receipt.fec_expedicion).toLocaleDateString() : "", "", "", "responsive"),
          totalAmount: this.getInputDataRow(receipt.imp_importe_total, "", "", "responsive number"),
        }
        receiptDataRows.push(row);
      });
      this.setState({ receiptDataRows: receiptDataRows });
    }
    else {
      if (this.state.serverError !== true) {
        this.setState({ noDataMessage: ERROR_MESSAGES["no-data"] });
      }
    }
  }

  getInputDataRow(value: string, type: string, link: string, key: string): IDataRow {
    return {
      text: value,
      type: type,
      link: link,
      key: key,
    }
  }

  render() {
    const { forbidden, receiptDataRows, serverError } = this.state;
    /* PREVENT FORBIDDEN ACCESS*/
    if (forbidden) {
      return <Redirect to="/403" />
    }

    return (
      <DetailClientsReceiptsContainer>
        <div className="detail-clients-receipts-back">
          <GoBack text="Volver" />
        </div>
        <div className="main-title-container">
          <h2>RECIBOS - Datos Generales</h2>
        </div>
        <ErrorModalContainer
          className={`${serverError && `active`}`}
          active={serverError}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{ERROR_MESSAGES["unknow-error"]}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={() => this.setState({ serverError: false })} />
            </div>
          </div>
        </ErrorModalContainer>

        <div className="detail-clients-policies-item col-12">
          <div className="colSin-30 padding10">
            <div className="bgWhite">
              <div className="inputBox filtersMargin">
                <input value={receiptDataRows.length > 0 && receiptDataRows[0].mediator.text ? receiptDataRows[0].mediator.text : ''} type="text" readOnly />
                <label>MEDIADOR</label>
              </div>
              <div className="inputBox filtersMargin">
                <input value={receiptDataRows.length > 0 && receiptDataRows[0].receipNumber.text ? receiptDataRows[0].receipNumber.text : ''} type="text" readOnly />
                <label>Nº RECIBO</label>
              </div>
              <div className="inputBox filtersMargin">
                <input value={receiptDataRows.length > 0 && receiptDataRows[0].state.text ? receiptDataRows[0].state.text : ''} type="text" readOnly />
                <label>ESTADO</label>
              </div>
              <div className="inputBox filtersMargin">
                <input value={receiptDataRows.length > 0 && receiptDataRows[0].delegationCode.text ? receiptDataRows[0].delegationCode.text : ''} type="text" readOnly />
                <label>LINEA DE NEGOCIO</label>
              </div>
            </div>
          </div>
          <div className="colSin-30 padding10">
            <div className="bgWhite height335">
              <div className="inputBox filtersMargin">
                <input value={receiptDataRows.length > 0 && receiptDataRows[0].policy.text ? receiptDataRows[0].policy.text : ''} type="text" readOnly />
                <label>PÓLIZA</label>
              </div>
              <div className="inputBox filtersMargin">
                <input value={receiptDataRows.length > 0 && receiptDataRows[0].certificate.text ? receiptDataRows[0].certificate.text : ''} type="text" readOnly />
                <label>CERTIFICADO</label>
              </div>
              <div className="inputBox filtersMargin">
                <input value={receiptDataRows.length > 0 && receiptDataRows[0].taker.text ? receiptDataRows[0].taker.text : ''} type="text" readOnly />
                <label>TOMADOR</label>
              </div>
            </div>
          </div>
          <div className="colSin-30 padding10">
            <div className="bgWhite height335">
              <div className="inputBox filtersMargin">
                <input value={receiptDataRows.length > 0 && receiptDataRows[0].startDate.text ? receiptDataRows[0].startDate.text : ''} type="text" readOnly />
                <label>FECHA EFECTO DE LA PÓLIZA</label>
              </div>
              <div className="inputBox filtersMargin">
                <input value={receiptDataRows.length > 0 && receiptDataRows[0].releaseDate.text ? receiptDataRows[0].releaseDate.text : ''} type="text" readOnly />
                <label>FECHA DE EMISIÓN</label>
              </div>
              <div className="inputBox filtersMargin">
                <input value={receiptDataRows.length > 0 && receiptDataRows[0].totalAmount.text ? receiptDataRows[0].totalAmount.text : ''} type="text" readOnly />
                <label>IMPORTE TOTAL DEL RECIBO</label>
              </div>
            </div>
          </div>
        </div>

      </DetailClientsReceiptsContainer>
    );
  }
}

export default withGeneral(withRouter(DetailClientsReceiptsLayout));
