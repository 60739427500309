import moment from "moment";
import React, { Component } from "react";
import { PARENTESCO } from "../../constants/dental-constants";
import { PriceBodyResponse } from "../../containers/hirings/new-hiring/dental/dental-price/dental-price-dto/dental-prices-dto";
import { ICoveragesAmount, ICoveragesQuoteAmount } from "../../containers/simulations/new-simulation/result/interfaces/result-interface";
import { formatter } from "../../utils/utility";
import HeadText from "../components-text/head-text";
import NoteText from "../components-text/note-text";
import WebText from "../components-text/web-text";
import MainTable from "../main-table";
import { DentalInsuredHiringResultContainer } from "./dental-insured-hiring-result-style";

interface DentalInsuredProps {
  age: number;
  birthDate: string;
  checked: boolean;
  id: number;
  index: number;
  onCheckInsured?: any;
  insured: any;
  gender?: string;
  parentesco?: string;
  coverages: any;
  pricesResponse: PriceBodyResponse;
  payType: string;
  isPrices?: boolean;
}

interface DentalInsuredState {
  checked: boolean;
  coverageAmount: ICoveragesAmount[];
}

const insuredTableColumns = [
  {
    text: "",
    filter: false,
    ascendent: true,
    key: "entire-mini-title",
  },
  {
    text: "Cobertura",
    filter: false,
    ascendent: true,
    key: "dimmed-mini-title",
  },
  {
    text: "Capital",
    filter: false,
    ascendent: true,
    key: "dimmed-mini-title",
  },
  {
    text: "Prima neta",
    filter: false,
    ascendent: true,
    key: "dimmed-mini-title",
  },
  {
    text: "Prima total",
    filter: false,
    ascendent: true,
    key: "dimmed-mini-title",
  },
  {
    text: "Impuesto",
    filter: false,
    ascendent: false,
    key: "dimmed-mini-title",
  },
];

class DentalInsuredHiringResultItem extends Component<DentalInsuredProps, DentalInsuredState> {

  constructor(props: DentalInsuredProps) {
    super(props)
    this.state = {
      checked: this.props.checked,
      coverageAmount: this.renderCoverageAmount()
    }
  }

  renderCoverageAmount(): ICoveragesAmount[] {
    const { pricesResponse, payType } = this.props;

    const coveragesAmount: ICoveragesAmount[] = [];
    pricesResponse.COBERTURAS.forEach((cov: any) => {
      if (this.checkIfInsuredExists(Number(cov.NUMERO_DE_RIESGO) - 1)) {
        coveragesAmount.push({
          CODE: cov.GARANTIA,
          CAPITAL:
            Number(cov.CAPITAL) === 1
              ? "Incluido"
              : formatter.format(Number(cov.CAPITAL)),
          NUMERO_DE_RIESGO: Number(cov.NUMERO_DE_RIESGO),
          DESCRIPTION: this.getDescriptionCoverage(cov.GARANTIA),
          ...this.getCoveragesQuoteAmount(
            pricesResponse,
            payType,
            cov.GARANTIA,
            true,
            Number(cov.NUMERO_DE_RIESGO)
          ),
        })
      }
    });

    return coveragesAmount.filter((x: any) => x !== undefined);

  }

  checkIfInsuredExists(index: number): boolean {
    const { pricesResponse } = this.props;
    return pricesResponse.IMPORTES_ASEGURADO_GARANTIA.filter(
      (ins: any) => Number(ins.NUMERO_DE_RIESGO) === index + 1 && Number(ins.NUMERO_DE_RIESGO) === this.props.index
    ).length > 0
  }

  getDescriptionCoverage = (codeCoverage: string) => {
    const { coverages } = this.props.insured;

    const coverage = coverages.filter((cov: any) => cov.code === codeCoverage);

    return coverage.length > 0 ? coverage[0].description : "";
  };

  getCoveragesQuoteAmount = (
    response: any,
    payType: string,
    codeCoverage: string,
    isFormat: boolean = true,
    numRiesgo: number = 0,
  ): ICoveragesQuoteAmount => {
    const coverageQuoteAmount = response.IMPORTES_ASEGURADO_GARANTIA.filter(
      (cov: any) =>
        cov.PERIODO_COBRO === payType && cov.GARANTIA === codeCoverage
    );
    if (!coverageQuoteAmount) {
      return { PRIMANETA: "", PRIMATOTAL: "", TOTAL_IMPUESTOS: "" };
    }
    let coverage: any = {}
    if (numRiesgo > 0) {
      coverage = coverageQuoteAmount.find((cov: any) => {
        return Number(cov.NUMERO_DE_RIESGO.toString()) === numRiesgo
      });
    } else {
      coverage = coverageQuoteAmount[0]
    }
    return {
      PRIMATOTAL: isFormat
        ? formatter.format(Number(coverage.PRIMATOTAL))
        : coverage.PRIMATOTAL,
      PRIMANETA: isFormat
        ? formatter.format(Number(coverage.PRIMANETA))
        : coverage.PRIMANETA,
      TOTAL_IMPUESTOS: isFormat
        ? formatter.format(Number(coverage.TOTAL_IMPUESTOS))
        : coverage.TOTAL_IMPUESTOS,
      PERIODO_COBRO: coverage.PERIODO_COBRO,
    };
  };

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.checked !== undefined) {
      this.setState({ checked: nextProps.checked });
    }
    this.setState({ coverageAmount: this.renderCoverageAmount() })
  }

  handleOnCheck(): void {
    this.setState({ checked: !this.state.checked });
  }

  getInsuredTableRows = (coverageAmount: any, coveragesParam: any) => {
    const { coverages } = this.props;
    let order: any = []


    let filter = coverages.sort((a: any, b: any) => {
      if (a.required) return (b.required && a.code > b.code) ? 1 : -1;
      else if (b.required) return 1
      else if (a.code < b.code) return -1;
      else return 1;

    })
    filter.map((x: any) => {
      if (order.indexOf(x.code) < 0)
        order.push(x.code)
    })
    let coveragesFilteredOrdered = order.map((order: any) => {
      return coverageAmount.find((x: any) =>
        x.CODE === order)
    })
    coveragesFilteredOrdered = coveragesFilteredOrdered.filter(function (x: any) {
      if (x != undefined) {
        if (x.CAPITAL && x.CAPITAL.toUpperCase() === "INCLUIDO") {
          x.CAPITAL = "1"
        } else {
          let res = coveragesParam.find((y: any) => x.CODE === y.code)
          if (res && res.capital) {
            x.CAPITAL = res.capital.toString()
          }
          if (res && res.capitalValue) {
            x.CAPITAL = res.capitalValue
          }
        }
        return x;
      }
    });
    const rows = coveragesFilteredOrdered.map((coverage: ICoveragesAmount) => ({
      item: {
        text: 1,
        key: 'coverage item',
        idLink: 'item'
      },
      description: {
        text: coverage.DESCRIPTION,
        key: 'coverage description',
        idLink: 'description'
      },
      capital: {
        text: 'INCLUIDO',
        key: '',
        idLink: 'capital'
      },
      netPremium: {
        text: coverage.PRIMANETA,
        key: 'number-align-right',
        idLink: 'netPremium'
      },
      totalPremium: {
        text: coverage.PRIMATOTAL,
        key: 'number-align-right',
        idLink: 'totalPremium'
      },
      taxes: {
        text: coverage.TOTAL_IMPUESTOS,
        key: 'number-align-right',
        idLink: 'taxes'
      },
      required: coveragesParam.find((cov: any) => cov.code === coverage.CODE) && coveragesParam.find((cov: any) => cov.code === coverage.CODE).required,
    }))

    rows.map((ele: any, index: number) => ele.item.text = index + 1)

    return rows;
  }

  getEmptyTableRows = (coverageAmount: any, coveragesParam: any) => {
    const { coverages } = this.props;
    let order: any = []


    let filter = coverages.sort((a: any, b: any) => {
      if (a.required) return (b.required && a.code > b.code) ? 1 : -1;
      else if (b.required) return 1
      else if (a.code < b.code) return -1;
      else return 1;

    })
    filter.map((x: any) => {
      if (order.indexOf(x.code) < 0)
        order.push(x.code)
    })
    const rows = coveragesParam.map((coverage: any) => ({
      item: {
        text: 1,
        key: 'coverage item',
        idLink: 'item'
      },
      description: {
        text: coverage.description,
        key: 'coverage description',
        idLink: 'description'
      },
      capital: {
        text: 'INCLUIDO',
        key: '',
        idLink: 'capital'
      },
      netPremium: {
        text: '-',
        key: 'number-align-right',
        idLink: 'netPremium'
      },
      totalPremium: {
        text: '-',
        key: 'number-align-right',
        idLink: 'totalPremium'
      },
      taxes: {
        text: '-',
        key: 'number-align-right',
        idLink: 'taxes'
      },
      required: coveragesParam.find((cov: any) => cov.code === coverage.CODE) && coveragesParam.find((cov: any) => cov.code === coverage.CODE).required,
    }))

    rows.map((ele: any, index: number) => ele.item.text = index + 1)

    return rows;
  }

  render() {

    const {
      coverages,
      insured,
      isPrices,
      id
    } = this.props;
    const { coverageAmount } = this.state;
    const relationship = PARENTESCO.find(n => n.value === insured.parentesco);

    return (
      <DentalInsuredHiringResultContainer>
        <div className="tab" key={id}>
          <input
            type="checkbox"
            checked={this.state.checked}
            onChange={() => this.handleOnCheck()} />

          {isPrices ?
            <label className="tab-label col-100">
              <div className="result-simulation-special-row">
                <div className="result-hiring-special-subitem col-40">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>NOMBRE</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {insured.name + ' ' + insured.lastname1 + ' ' + insured.lastname2}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-35">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>FECHA NACIMIENTO</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {moment(new Date(insured.birthDate)).format('DD/MM/YYYY').toString()}
                      </p>
                    </WebText>
                  </div>
                </div>
                <div className="result-hiring-special-subitem col-25">
                  <div className="result-simulation-special-subitem-title">
                    <NoteText>
                      <p>EDAD</p>
                    </NoteText>
                  </div>
                  <div className="result-hiring-special-subitem-content">
                    <WebText>
                      <p>
                        {Math.floor(moment().diff(moment(moment(new Date(insured.birthDate)).format('DD/MM/YYYY').toString(), "DD/MM/YYYY", true), "years", true))} años
                      </p>
                    </WebText>
                  </div>

                </div>
              </div>
            </label>
            :
            <label className="tab-label col-100">
              <div className="result-hiring-special-first-item col-30">
                <HeadText>
                  <p>
                    {insured.name + ' ' + insured.lastname1 + ' ' + insured.lastname2}
                  </p>
                </HeadText>
                <div className="result-relationship-special-subitem-title">
                  <NoteText>
                    PARENTESCO: <a>{relationship && relationship.label}</a>
                  </NoteText>
                </div>
              </div>
              <div className="result-hiring-special-item col-70">
                <div className="result-hiring-special-row">
                  <div className="result-hiring-special-subitem col-40">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>DOMICILIO</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {insured.address ?
                            `${insured.address.address.value} ${insured.address.portal ? ', ' + insured.address.portal : ''} ${insured.address.floor ? ', ' + insured.address.floor : ''} ${insured.address.door}`
                            : ''}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>LOCALIDAD</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {insured.address ? insured.address.town.value : ''}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>PROVINCIA</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {insured.address ? insured.address.province.value : ''}
                        </p>
                      </WebText>
                    </div>
                  </div>
                  <div className="result-hiring-special-subitem col-20">
                    <div className="result-simulation-special-subitem-title">
                      <NoteText>
                        <p>CÓDIGO POSTAL</p>
                      </NoteText>
                    </div>
                    <div className="result-hiring-special-subitem-content">
                      <WebText>
                        <p>
                          {insured.address ? insured.address.postalCode : ''}
                        </p>
                      </WebText>
                    </div>
                  </div>
                </div>
              </div>
            </label>}

          {coverageAmount && coverageAmount.length > 0 ?
            <div className="tab-content scrollx">
              <MainTable
                className="table noZebra"
                columns={insuredTableColumns}
                rows={this.getInsuredTableRows(coverageAmount, coverages)}
                keysRow={['item', 'description', 'capital', 'netPremium', 'totalPremium', 'taxes']}
              />
            </div> :
            <div className="tab-content scrollx">
              <MainTable
                className="table noZebra"
                columns={insuredTableColumns}
                rows={this.getEmptyTableRows(coverageAmount, coverages)}
                keysRow={['item', 'description', 'capital', 'netPremium', 'totalPremium', 'taxes']}
              />
            </div>
          }
        </div>

      </DentalInsuredHiringResultContainer>
    )

  }
}

export default DentalInsuredHiringResultItem;



