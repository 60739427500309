import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { images } from "../../../../../assets";
import NoteText from "../../../../../components/components-text/note-text";
import { ErrorModalContainer } from "../../../../../components/error-modal/error-modal-style";
import InputBox from "../../../../../components/input-box/input-box";
import LeftInfoModal from "../../../../../components/left-info-modal";
import MainButton from "../../../../../components/main-button";
import SelectBox from "../../../../../components/select-box/select-box";
import { withCoverages } from "../../../../../context/mixin/with-coverages";
import { withGeneral } from "../../../../../context/mixin/with-general";
import { withPolicy } from "../../../../../context/mixin/with-policy";
import { DocumentType } from "../../../../../models/gest-doc-models";
import GestDocServices from "../../../../../services/Gest-Doc.Services";
import { GestDoc } from "../../../../../services/GestDoc";
import { DocumentDto } from "../../../../../services/HealthService-dto";
import { changeFileName, downloadFile, getFileExtension } from "../../../../../utils/utility";
import { RequiredDocDataContainer } from "./required-doc-data-style";
import { acceptedFileMimeType } from "../../../../../constants/acceptedFileMimeType";
import { SIGN_HIRING_STATUS_TYPE } from "../../../../../constants/hiring";
import { MainTableContainer } from "../../../../../components/main-table/main-table-style";
import BodyText from "../../../../../components/components-text/body-text";
import requiredDocuments from "../../../../../utils/requiredDocuments";
import ReactHtmlParser from 'react-html-parser';
import CrossService from "../../../../../services/CrossService";

var mime = require('mime-types')

interface Associate {
  index: number,
  name: string,
  document: string,
  [key: string]: any;
}

interface NewDocument {
  docType: string,
  description?: string,
  file?: File,
  associatedTo: string,
}

interface DocumentDTO {
  id?: string,
  name: string,
  description?: string,
  type: string,
  associatedTo: string,
  file?: File,
  hidden?: boolean,
  isNew?: boolean,
  size?: number
}

interface RequiredDocProps extends RouteComponentProps {
  saveHiring: Function;
  setLoading: Function;
  readOnly: boolean;
  taker: any;
  insureds: any[];
  cotizacion: string;
  data: any;
  removeSign: Function;
  policy: any;
  nextStep: any;
  step: any;
  areaEndpoint: string;
}
export interface ConstantsResponseBoxDto {
  label: string;
  value: string;
  showFor: string;
  obligatorio: string;
  [key: string]: string;
}

interface DocPersonaType {
  [key: string]: { value: string, name: string };
}

interface RequiredDocState {
  newDocument: NewDocument,
  readOnly: boolean,
  uploadedDocuments: DocumentDTO[],
  documentTypes: ConstantsResponseBoxDto[],
  associates: Associate[],
  associatesFilter: Associate[],
  modalErrorShow: boolean,
  modalErrorText: string,
  showConfirmRemoveDocumentModal: boolean,
  showConfirmRemoveDocumentModalId: number,
  showReplaceDocModal: boolean,
  showMaxSizeModal: boolean,
  areTakerDocumentIdImagesUploaded: boolean,
  cancelSignModalShow: boolean,
  textoObligatorios: string,
  policyCargado: boolean;
  docPersonaTypes: DocPersonaType,
}

interface Insured {
  isStudent: boolean,
  sameAsTaker: boolean,
};

interface Data {
  insureds: Insured[];
};

class RequiredDocDataLayout extends React.Component<RequiredDocProps, RequiredDocState> {

  constructor(props: RequiredDocProps) {
    super(props);

    let associates: Associate[] = [];
    if (props.insureds && props.insureds.length) {
      associates = props.insureds.map((insured, index) => (
        {
          index: index,
          name: insured.name,
          document: insured.docNumber,
          title: `${insured.name} ${insured.lastname1} (${insured.docNumber})`,
          isStudent: insured.isStudent,
        }
      ));
    }
    associates.unshift({
      index: -1,
      name: "",
      document: '-1',
      title: "Tomador/Póliza"
    });

    this.state = {
      newDocument: {
        docType: "",
        description: "",
        file: undefined,
        associatedTo: "",
      },
      textoObligatorios: "",
      readOnly: this.props.readOnly,
      uploadedDocuments: [],
      documentTypes: [],
      associates: associates,
      associatesFilter: associates,
      modalErrorShow: false,
      modalErrorText: "",
      showConfirmRemoveDocumentModal: false,
      showConfirmRemoveDocumentModalId: -1,
      areTakerDocumentIdImagesUploaded: false,
      cancelSignModalShow: false,
      showReplaceDocModal: false,
      showMaxSizeModal: false,
      policyCargado: false,
      docPersonaTypes: {},
    };
  }

  componentDidMount = async () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
    const { data, history, policy } = this.props
    if (data && data.taker && Object.keys(data.taker).length !== 0) {
      if (!this.state.documentTypes.length && policy && policy.product && policy.product.products_id) {
        this.setState({ policyCargado: true })
        await this.loadDocumentTypes();
      }
      this.preselecionarAsociado();
      this.loadDocumentsFromGestDoc();
    }
    else {
      history.push("/contratacion/nueva");
    }
  };

  componentDidUpdate(prevProps: Readonly<RequiredDocProps>, prevState: Readonly<RequiredDocState>, snapshot?: any): void {
    const { data, policy } = this.props
    if (!this.state.policyCargado && policy && policy.product && policy.product.products_id && this.state.documentTypes.length === 0 && data && data.taker && Object.keys(data.taker).length !== 0) {
      this.setState({ policyCargado: true })
      this.loadDocumentTypes();
      this.preselecionarAsociado();
    }
  }

  preselecionarAsociado = () => {
    const { associates, newDocument } = this.state;
    newDocument.associatedTo = associates[0].document;
    this.setState({ newDocument })
  }

  loadDocumentTypes = async () => {
    const result = await requiredDocuments.getDocumentTypes(
      this.props.setLoading,
      this.props.policy.product.products_id
    );
    this.setState(
      {
        documentTypes: result.documentTypes,
        textoObligatorios: result.docObligatorios,
        docPersonaTypes: result.docPersonaTypes,
      },
      this.checkTakerDocumentImagesUploaded
    );
  }

  documentToAdd = async (entry: any, isAssociatedTaker: boolean) => {
    let fileInfoDNI = (await GestDocServices.getFileInfo(this.props.setLoading, true, entry.id));
    const isTaker = (fileInfoDNI.entry.properties["ins2:processEntity"]) === 'Tomador' ? true : false
    const dniAssociated = fileInfoDNI.entry.properties["ins2:insured1Nif"];
    if (isTaker === isAssociatedTaker) {
      return {
        id: entry.id,
        name: entry.name,
        date: entry.createdAt,
        description: "",
        type: this.getElementType(fileInfoDNI.entry.properties["ins2:documentType"]),
        associatedTo: dniAssociated ? this.getAssociated(dniAssociated, isTaker) : '-1',
        size: fileInfoDNI.entry.content.sizeInBytes
      }
    }
    return {
      id: '',
      name: '',
      description: '',
      type: '',
      associatedTo: '',
      size: 0
    }
  }

  loadDocumentsFromGestDoc = async () => {
    const { setLoading, data } = this.props;
    const { documentTypes, docPersonaTypes } = this.state;

    const uploadedDocuments: DocumentDTO[] = [];

    //Tenemos todos los document types
    //Por cada documento tengo que descargarlo mirando en documentos.

    for (let i = 0; i < documentTypes.length; i++) {
      //Para el tomador
      if (documentTypes[i].showFor.includes(docPersonaTypes.TOMADOR.value)) {
        const docTomador = await GestDocServices.searchFileOrderByModifiedAt(setLoading, true,
          GestDoc.getDocumentByDocumentType(documentTypes[i].documentTypeMetadata, data.taker)
        );
        if (docTomador && docTomador.list && docTomador.list.entries && docTomador.list.entries.length > 0) {
          for (let dt = 0; dt < docTomador.list.entries.length; dt++) {
            const documentAdd = await this.documentToAdd(docTomador.list.entries[dt].entry, true)
            if (documentAdd && documentAdd.id) {
              const res = uploadedDocuments.filter(e => e.id === documentAdd.id);
              if (res.length === 0) {
                uploadedDocuments.push(documentAdd)
                break;
              }
            }
          }
        }
      } else {
        // Para los distintos tipos de asegurados
        for (let a = 0; a < data.insureds.length; a++) {
          const doc = await GestDocServices.searchFileOrderByModifiedAt(setLoading, true,
            GestDoc.getDocumentByDocumentType(documentTypes[i].documentTypeMetadata, undefined, [data.insureds[a]])
          );
          if (doc && doc.list && doc.list.entries && doc.list.entries.length > 0) {
            for (let d = 0; d < doc.list.entries.length; d++) {
              const documentAdd = await this.documentToAdd(doc.list.entries[d].entry, false)
              if (documentAdd && documentAdd.id) {
                const res = uploadedDocuments.filter(e => e.id === documentAdd.id);
                if (res.length === 0) {
                  uploadedDocuments.push(documentAdd)
                  break;
                }
              }
            }
          }
        }
      }
    }
    this.setState({ uploadedDocuments: uploadedDocuments }, () => this.checkTakerDocumentImagesUploaded())
  }

  getAssociated(nif: string, isTaker: boolean): string {
    const { data } = this.props;
    if (isTaker) {
      return '-1'
    }
    for (let i = 0; i < data.insureds.length; i++) {
      if (data.insureds[i].docNumber === nif) {
        return (i).toString();
      }
    }
    return '';
  }

  getElementType(documentType: DocumentType | string | undefined): string {
    const { documentTypes } = this.state
    let elem = documentTypes.find(doc => doc.documentTypeMetadata === documentType)
    if (elem) {
      return elem.value
    }
    return ''
  }

  getType(type: string | undefined): string {
    const { documentTypes } = this.state
    let elem = documentTypes.find(doc => doc.value === type)
    if (elem) {
      return elem.label
    }
    return '';
  }

  getAssociatedName(associate: string) {
    const { associates } = this.state;
    const infoAssociated = associates.filter(a => a["title"] !== "Ninguno").find(a => (a.index).toString() === associate);
    if (infoAssociated) return infoAssociated.title;
    else return "Ninguno";
  }

  tryGoToNextStep = async () => {
    if (this.state.readOnly) {
      const { nextStep, step } = this.props;

      if (this.props.data.taker.physicalPerson)
        this.props.history.push(nextStep.getNextStepNavigationPath(step + 1));
      else
        this.props.history.push(nextStep.getNextStepNavigationPath(step));
      return;
    }

    const error = await this.sendDocumentToSenda();
    if (!error) {
      this.checkTakerDocumentImagesUploaded();
      if (this.state.areTakerDocumentIdImagesUploaded) {
        const { nextStep, step } = this.props;

        this.props.saveHiring();
        if (!this.props.data.taker.physicalPerson)
          this.props.history.push(nextStep.getNextStepNavigationPath(step + 1));
        else
          this.props.history.push(nextStep.getNextStepNavigationPath(step));
        return;
      }
      this.setState({ modalErrorShow: true, modalErrorText: "Compruebe la documentación obligatoria." })
    }
  }

  checkTakerDocumentImagesUploaded() {
    const { data } = this.props;
    const { uploadedDocuments, documentTypes, docPersonaTypes } = this.state;
    if (!docPersonaTypes) {
      this.setState({
        modalErrorShow: true,
        modalErrorText: "Se ha producido un error en la recuperación de los tipos de asegurado. Por favor, inténtelo más tarde."
      });
      return;
    }

    let isValidate = true;
    // Primero preguntamos si hay algun documento obligatorio 
    let mandatoryDoc = this.checkDoc(documentTypes)

    // Si lo hay comprobamos si esta subido
    if (mandatoryDoc.length > 0) {
      isValidate = true;
      for (let i = 0; i < mandatoryDoc.length; i++) {
        // Convertimos en array para manejar la ampliación de tipos de usuarios
        const requiredDocumentsArray = mandatoryDoc[i].obligatorio.split("-");
        // Tomador
        if (requiredDocumentsArray.includes(docPersonaTypes.TOMADOR.value)) {
          let documentIdTaker = uploadedDocuments.filter((doc) =>
          ((doc.associatedTo === this.props.taker.docNumber && doc.type === mandatoryDoc[i].value) ||
            (doc.associatedTo === '-1' && doc.type === mandatoryDoc[i].value)));
          if (documentIdTaker.length === 0) {
            isValidate = false;
            break;
          }
        }
        // Asegurado
        if (requiredDocumentsArray.includes(docPersonaTypes.ASEGURADO.value)) {
          let insuredsValidation = this.checkInsuredValidation(mandatoryDoc[i], data)
          if (!insuredsValidation) {
            isValidate = false;
            break;
          }
        }
        // Asegurado estudiante
        if (requiredDocumentsArray.includes(docPersonaTypes.ASEGURADO_ESTUDIANTE_EXTRANJERO.value)) {
          let insuredsValidation = this.checkInsuredStudentValidation(mandatoryDoc[i], data)
          if (!insuredsValidation) {
            isValidate = false;
            break;
          }
        }
      }
    }
    this.setState({ areTakerDocumentIdImagesUploaded: isValidate });
  }

  checkInsuredValidation(mandatoryDoc: ConstantsResponseBoxDto, data: Data): Boolean {
    let result = true;
    for (let i = 0; i < data.insureds.length; i++) {
      const isSameAsTaker = data.insureds[i].sameAsTaker;
      let documentInsured = this.state.uploadedDocuments.filter(
        (doc) => {
          if (doc.associatedTo === i.toString() && doc.type === mandatoryDoc.value) return true;
          if (isSameAsTaker && doc.associatedTo === '-1' && doc.type === mandatoryDoc.value) return true;
          return false;
        }
      );
      // Comprobamos si existen los documentos
      if (documentInsured.length === 0) {
        result = false;
        break;
      }
    }
    return result;
  }

  checkInsuredStudentValidation(mandatoryDoc: ConstantsResponseBoxDto, data: Data): Boolean {
    let result = true;
    for (let i = 0; i < data.insureds.length; i++) {
      const isStudent = data.insureds[i].isStudent;
      if (isStudent) {
        const isSameAsTaker = data.insureds[i].sameAsTaker;
        let documentInsured = this.state.uploadedDocuments.filter(
          (doc) => {
            if (doc.associatedTo === i.toString() && doc.type === mandatoryDoc.value) return true;
            if (isSameAsTaker && doc.associatedTo === '-1' && doc.type === mandatoryDoc.value) return true;
            return false;
          }
        );
        // Comprobamos si existen los documentos
        if (documentInsured.length === 0) {
          result = false;
          break;
        }
      }
    }
    return result;
  }

  checkDoc(documentTypes: ConstantsResponseBoxDto[]) {
    return documentTypes.filter(document => document.obligatorio.length > 0)
  }

  onDocumentTypeChange(value: string) {
    const { newDocument, associates, documentTypes, docPersonaTypes } = this.state;
    newDocument.docType = value;
    //Comprobamos para ese documento quien lo tiene disponible y quien obligatorio
    let doc = documentTypes.find(d => d.value === value);
    let asociados: Associate[] = [];
    if (doc) {
      const docShowFor = (doc && doc.showFor.split("-")) || [];
      associates.forEach((insured, index) => {
        const { isStudent } = insured;
        if (isStudent && (insured.index !== -1 && docShowFor.includes(docPersonaTypes.ASEGURADO_ESTUDIANTE_EXTRANJERO.value))) {
          asociados.push(insured);
        } else if (
          (doc && doc.showFor.length === 0)
          || (insured.index === -1 && docShowFor.includes(docPersonaTypes.TOMADOR.value))
          || (insured.index !== -1 && docShowFor.includes(docPersonaTypes.ASEGURADO.value))
          || (insured.index === -1 && index === 1 && docShowFor.includes(docPersonaTypes.ASEGURADO.value))
        ) {
          asociados.push(insured)
        }
      })
      newDocument.associatedTo = '';
    }
    this.setState({ newDocument: newDocument, associatesFilter: asociados });
  }

  onAssociatedToChange(value: string) {
    const { newDocument } = this.state;
    newDocument.associatedTo = value;
    this.setState({ newDocument: newDocument });
  }

  onDescriptionChange(value: string) {
    const { newDocument } = this.state;
    newDocument.description = value;
    this.setState({ newDocument: newDocument });
  }

  onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { newDocument } = this.state;
    if (event.target.files && event.target.files.length > 0) {
      newDocument.file = event.target.files[0];
      this.setState({ newDocument });
    }
  };

  isAdjuntarDocumentoButtonDisabled = (): boolean => {
    const { newDocument } = this.state;
    const { readOnly } = this.props;
    return readOnly || !(newDocument.file && newDocument.docType && newDocument.associatedTo)
  }

  uploadFile() {
    const { newDocument, uploadedDocuments, associates } = this.state;
    // Tenemos que seguir la siguiente instrucción
    // Si el asegurado es el tomador y el documento esta disponible tanto para tomador como para asegurado se lo asignamos al tomador.
    const associateIndex = newDocument.associatedTo === '-1' ? [-1] : this.isTakerAvailable();


    if (uploadedDocuments.find(doc => doc.type === newDocument.docType && doc.associatedTo === associateIndex[0].toString())) {
      this.setState({ showReplaceDocModal: true });
    } else this.uploadDocument();
  }

  isTakerAvailable() {
    const { newDocument, associates, documentTypes } = this.state;
    const { data } = this.props;
    // Buscamos el documentType para ver los permisos
    let doc = documentTypes.find(x => x.value === newDocument.docType)
    if (doc) {
      if (doc.showFor.includes('T') && newDocument.associatedTo === data.taker.docNumber) {
        return [-1]
      }
    }
    return associates.filter((associate) => associate.document === newDocument.associatedTo).map((associate) => associate.index)
  }

  async uploadDocument() {
    const { newDocument, associates, documentTypes, docPersonaTypes } = this.state;

    if (!(newDocument.file && newDocument.docType)) {
      this.showErrorModal("Primero debe seleccionar un archivo para subir y un tipo.");
      return;
    }

    let fileExtension = getFileExtension(newDocument.file.name);
    let mimeType = mime.lookup(fileExtension);

    if (!(acceptedFileMimeType.includes(mimeType) || mimeType.includes('image'))) {
      this.showErrorModal(`No se permite la extensión ${fileExtension} del fichero introducido. Revise los tipos aceptados.`);
      return;
    }

    let newFileName = `${newDocument.docType}${newDocument.associatedTo !== '-1'
      ? `_${newDocument.associatedTo}`
      : ""}_${moment().format("YYYYMMDDHHmmss")}.${fileExtension}`;
    newDocument.file = changeFileName(newDocument.file, newFileName);

    let associateIndex = newDocument.associatedTo === '-1' ? [-1] : this.isTakerAvailable()

    let doc = documentTypes.find(d => d.value === newDocument.docType);
    if (
      (doc && doc.obligatorio === docPersonaTypes.ASEGURADO.value && doc.showFor === docPersonaTypes.ASEGURADO.value && associateIndex[0] === -1)
      ||
      (doc && doc.obligatorio === docPersonaTypes.ASEGURADO_ESTUDIANTE_EXTRANJERO.value && doc.showFor === docPersonaTypes.ASEGURADO_ESTUDIANTE_EXTRANJERO.value && associateIndex[0] === -1)
    ) {
      associateIndex[0] = 0;
    }
    this.addNewUploadedDocument({
      name: newFileName,
      description: newDocument.description,
      type: newDocument.docType,
      associatedTo: associateIndex[0].toString(),
      file: newDocument.file,
      isNew: true
    });
    this.clearForm();
    this.checkTakerDocumentImagesUploaded();
  }

  clearForm() {
    const { associates, newDocument } = this.state;
    this.setState({
      newDocument: {
        docType: "",
        description: "",
        file: undefined,
        associatedTo: (associates.length > 0) ? associates[0].document : ""
      }
    });
  }

  async sendDocumentToSenda(): Promise<boolean> {
    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED) {
      this.setState({ cancelSignModalShow: true })
      return false;
    }
    else {
      return await this.validateAndSave().then((result) => result)
    }
  }

  async validateAndSave() {
    if (this.props.data && this.props.data.signData && this.props.data.signData.signStatus === SIGN_HIRING_STATUS_TYPE.REQUESTED)
      this.props.removeSign()

    const { uploadedDocuments } = this.state;
    const { setLoading, cotizacion } = this.props;

    let filesInfoArray: DocumentDto[] = [];
    let filesArray: File[] = [];

    await Promise.all(uploadedDocuments.filter(doc => !doc.hidden).map(async (file) => {
      if (!file.id && file.file) {
        filesInfoArray.push({
          [file.name]: {
            type: file.type,
            associatedTo: file.associatedTo
          }
        })
        filesArray.push(file.file)
      }
      else if (file.id && !file.file) {
        let fileWithId = await GestDocServices.getFile(
          setLoading,
          true,
          file.id
        )
          .then((response) => response)
          .catch((e) => console.log(e))
        if (fileWithId) {
          filesInfoArray.push({
            [file.name]: {
              type: file.type,
              associatedTo: file.associatedTo
            }
          })
          filesArray.push(new File([fileWithId.fileData], fileWithId.fileName))
        }
      }
    }))

    let error = false;
    if (filesArray.length > 0) {
      const filesSize = filesArray.reduce((totalSize, file) => totalSize + file.size, 0);
      if (filesSize >= (1048576 * 5)) { // 5MB
        this.setState({ showMaxSizeModal: true });
        return true;
      }
      const { areaEndpoint } = this.props;
      error = await CrossService.uploadDocumentsToSenda(setLoading, true, {
        cotizacion: cotizacion,
        fileInfo: filesInfoArray,
        files: filesArray,
      }, areaEndpoint).catch(error => {
        this.setState({
          modalErrorShow: true,
          modalErrorText: error
        })
        return true;
      }) === true ? true : false;
    }
    return error;
  }

  async downloadDocument(document: any) {
    const { setLoading } = this.props;
    if (document.id) {
      var result = await GestDocServices.getFile(setLoading, true, document.id);
      if (result && result.fileData) {
        downloadFile(result.fileData, result.fileName, result.fileType, true);
      } else {
        this.showErrorModal("Se ha producido un error al descargar el archivo.");
      }
    } else {
      downloadFile(document.file, document.name, document.type, true);

    }
  }

  async confirmDeleteDocument(index: number) {
    this.setState({ showConfirmRemoveDocumentModal: true, showConfirmRemoveDocumentModalId: index });
  }

  async deleteDocument(index: number) {
    const { uploadedDocuments } = this.state;
    const fileToDelete = uploadedDocuments[index];
    uploadedDocuments.forEach(doc => {
      if (doc.type === fileToDelete.type && doc.associatedTo === fileToDelete.associatedTo) doc.hidden = false;
    });
    uploadedDocuments.splice(index, 1);
    this.setState({ uploadedDocuments: uploadedDocuments }, async () => {
      this.checkTakerDocumentImagesUploaded();
    });
  }

  addNewUploadedDocument(newDoc: DocumentDTO) {
    let { uploadedDocuments } = this.state;

    uploadedDocuments.forEach(doc => {
      if (doc.type === newDoc.type && doc.associatedTo === newDoc.associatedTo) doc.hidden = true;
    });
    const newDocumentToDelete = uploadedDocuments.findIndex(
      doc => {
        if (doc.type === newDoc.type && doc.associatedTo === newDoc.associatedTo && doc.isNew) return true;
        return false;
      });


    if (newDocumentToDelete !== -1) uploadedDocuments[newDocumentToDelete] = newDoc;
    else uploadedDocuments.push(newDoc);
    this.setState({ uploadedDocuments: uploadedDocuments });
  }

  showErrorModal = (text: string = "Ha ocurrido un error.") => this.setState({ modalErrorShow: true, modalErrorText: text });
  hideErrorModal = () => this.setState({ modalErrorShow: false, modalErrorText: "" });


  getFileSize = (size: number) => {
    if (size < 1024) {
      return `${size} bytes`;
    } else if (size >= 1024 && size < 1048576) {
      return `${(size / 1024).toFixed(1)} KB`;
    } else if (size >= 1048576) {
      return `${(size / 1048576).toFixed(1)} MB`;
    }
  }

  render() {
    const {
      newDocument,
      readOnly,
      uploadedDocuments,
      documentTypes,
      associatesFilter,
      areTakerDocumentIdImagesUploaded,
      cancelSignModalShow
    } = this.state;

    return (
      <RequiredDocDataContainer>
        <div className="data-flow-status">
          <div className="data-flow-status-title">
            <h2>{'DOCUMENTACION REQUERIDA'}</h2>
          </div>
        </div>

        <div className="content-wrapper bottomMargin20 filesSection" ref={''}>
          <div className="personal-data-wrapper">
            {!readOnly && (
              <div>
                <div className="data-title">
                  <h5>ADJUNTAR DOCUMENTOS</h5>
                </div>

                <div className="colSin-100 topMargin20">

                  <div className="colSin-50 rightPadding20">
                    <div className="selectBox">
                      <SelectBox
                        optionsText={documentTypes}
                        optionKey={"value"}
                        optionValue={"label"}
                        defaultValue={newDocument.docType}
                        initialValue={newDocument.docType}
                        onChange={(value: string) => this.onDocumentTypeChange(value)}
                        required={true}
                        disabled={readOnly}
                      />
                      <label>Tipo de documento</label>
                    </div>
                  </div>

                  <div className="colSin-50 rightPadding20">
                    <div className="selectBox">
                      <SelectBox
                        optionsText={associatesFilter}
                        optionKey={"document"}
                        optionValue={"title"}
                        defaultValue={""}
                        initialValue={newDocument.associatedTo}
                        onChange={(value: string) => this.onAssociatedToChange(value)}
                        required={false}
                        disabled={readOnly}
                      />
                      <label>Asegurado</label>
                    </div>
                  </div>

                  <div className="colSin-100 rightPadding20">
                    <div className="inputBox custom-file-upload">
                      <div className="colSin-100">
                        <InputBox
                          type={"text"}
                          required={false}
                          errorCode={""}
                          disabled={readOnly}
                          cleanValue={true}
                          value={newDocument.description ? newDocument.description : ""}
                          onChange={(value: string) => this.onDescriptionChange(value)}
                        />
                      </div>
                      <label>Descripción</label>
                    </div>
                  </div>

                </div>

                <div className="colSin-100 topMargin20">
                  <div className="colSin-50 rightPadding20">
                    <div className="inputBox custom-file-upload">

                      <div className="colSin-70 required">
                        <input type="text" readOnly value={(newDocument.file ? newDocument.file.name : "")} disabled={readOnly} />
                      </div>

                      <div className="colSin-30">
                        <label className="custom-file-upload-button topMargin5">
                          <input type="file" onChange={this.onFileChange} accept={acceptedFileMimeType.toString()} />
                          <MainButton
                            onClick={() => { return true }}
                            text="Buscar"
                            disabled={readOnly}
                          />
                        </label>
                      </div>

                      <label>Adjuntar documento</label>
                    </div>
                  </div>

                  <div className="colSin-50 rightPadding20 topMargin15">
                    <label className="custom-file-upload">
                      <div className={"upload-buttons"}>
                        <MainButton
                          type={this.isAdjuntarDocumentoButtonDisabled() ? "disabled" : ""}
                          onClick={() => this.uploadFile()}
                          text="Adjuntar Documento"
                          icon={true}
                          iconImg={images.IconUploadBlue}
                          disabled={this.isAdjuntarDocumentoButtonDisabled()}
                        />
                      </div>
                    </label>
                  </div>

                </div>
              </div>
            )}
            {!readOnly && !areTakerDocumentIdImagesUploaded &&
              <div className="colSin-100 topMargin20">
                <NoteText>
                  <div className="content" style={{ fontSize: '16px', lineHeight: '22px' }}>{ReactHtmlParser(this.state.textoObligatorios)}</div>
                </NoteText>
              </div>
            }
            <div className="colSin-100 topMargin20">
              {uploadedDocuments && uploadedDocuments.length > 0 &&
                <>
                  <MainTableContainer className="table noZebra uploadedTable">
                    <thead>
                      <tr>
                        {["Tipo de documento", "Asegurado", "Opciones", "Tamaño *"].map((column, index) => (
                          <th key={`column-${index}`}>
                            <BodyText className="dimmed-mini-title"><p>{column}</p></BodyText>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {uploadedDocuments.map((row, index) => (
                        !row.hidden &&
                        <tr className="main-table-row" key={`row-${index}`}>
                          <td>
                            <BodyText className="dimmed-mini-body">
                              <p>{this.getType(row.type)}</p>
                            </BodyText>
                          </td>
                          <td>
                            <BodyText className="dimmed-mini-body">
                              <p>{this.getAssociatedName(row.associatedTo)}{row.isNew ? "(Nuevo)" : ""}</p>
                            </BodyText>
                          </td>
                          <td>
                            <div className="uploadedFileTable">
                              <MainButton
                                key={`downloadBtnTable${index}`}
                                onClick={() => row && this.downloadDocument(row)}
                                text=""
                                icon={true}
                                iconImg={images.IconEyewhite}
                              />
                              {(!readOnly && !row.id && (<MainButton
                                key={`deleteBtnTable${index}`}
                                onClick={() => this.confirmDeleteDocument(index)}
                                text=""
                                icon={true}
                                iconImg={images.IconDeleteWhite}
                              />))}
                            </div>
                          </td>
                          <td>
                            <BodyText className="dimmed-mini-body">
                              <p>{row.size ? this.getFileSize(row.size) : row.file ? this.getFileSize(row.file.size) : ""}</p>
                            </BodyText>
                          </td>
                        </tr>

                      )).reverse()}
                    </tbody>
                  </MainTableContainer>
                  <p className="text-max-size">* El tamaño máximo de la totalidad de ficheros a adjuntar es de 5MB.</p>
                </>
              }
            </div>

          </div>
        </div>
        <div className="colSin-100 topMargin20 bottomMargin20">
          <div className="personal-data-buttons">
            <div className="personal-data-buttons__item">
              <MainButton
                withTab={true}
                icon={true}
                type={"white"}
                iconImg={images.IconArrowLeftBlue}
                text="Atrás"
                onClick={() => this.props.history.push("/contratacion/nueva/salud/garantias")}
              />
            </div>
            <div className="personal-data-buttons__item">
              <MainButton
                disabled={!areTakerDocumentIdImagesUploaded}
                withTab={true}
                text="Siguiente"
                onClick={() => this.tryGoToNextStep()}
              />
            </div>
          </div>
        </div>
        <LeftInfoModal
          img={images.IconEraseRed}
          mainTitle={"¿Quieres borrar este documento?"}
          mainText={"Una vez borrado, no podrá volver a recuperarlo."}
          buttonText="Eliminar"
          close={() => this.setState({ showConfirmRemoveDocumentModal: false, showConfirmRemoveDocumentModalId: -1 })}
          active={this.state.showConfirmRemoveDocumentModal}
          onClickAccept={async () => await this.deleteDocument(this.state.showConfirmRemoveDocumentModalId)}
          className="error"
        />
        <ErrorModalContainer className={`${this.state.modalErrorShow && `active`}`}>
          <div className="recover-modal">
            <div className="server-error">
              <p>{this.state.modalErrorText}</p>
            </div>
            <div className="button-error">
              <MainButton text="Aceptar" onClick={this.hideErrorModal} />
            </div>
          </div>
        </ErrorModalContainer>
        <LeftInfoModal
          img={images.IconWarningYellow}
          className="error"
          mainTitle={"¿Seguro que desea continuar?"}
          mainText={"Se cancelará la firma previamente solicitada"}
          buttonText="Continuar"
          closeButtonText="Cancelar"
          close={() => this.setState({ cancelSignModalShow: false })}
          active={cancelSignModalShow}
          onClickAccept={() => {
            this.setState({ cancelSignModalShow: false }, () => this.validateAndSave())
          }}
        />
        <LeftInfoModal
          img={images.IconWarningYellow}
          mainTitle={"¿Desea sustituir el documento existente por el seleccionado?"}
          mainText={"Si continua con este proceso perderá el documento anterior."}
          buttonText="Reemplazar"
          closeButtonText="Cancelar"
          close={() => this.setState({ showReplaceDocModal: false })}
          active={this.state.showReplaceDocModal}
          onClickAccept={() => {
            this.setState({ showReplaceDocModal: false }, () => this.state.showReplaceDocModal)
            this.uploadDocument();
          }}
          className="warning"
        />
        <LeftInfoModal
          img={images.IconWarningYellow}
          mainTitle={"Aviso de documentación"}
          mainText={"La documentación adjunta supera el máximo del tamaño establecido (5MB)."}
          secondaryText={"Por favor, corrija antes de continuar."}
          buttonText="Aceptar"
          noSecondaryButton={true}
          close={() => this.setState({ showMaxSizeModal: false })}
          active={this.state.showMaxSizeModal}
          onClickAccept={() => this.setState({ showMaxSizeModal: false })}
          className="info"
        />
      </RequiredDocDataContainer>
    );
  }
}

export default withCoverages(withPolicy(withGeneral(withRouter(RequiredDocDataLayout))));