import { ValueFormHealth } from "../../../../../../../constants/health"

export const DefaultValueFormHealth = (isDisabled: boolean = false): ValueFormHealth => {
  return {
    value: "",
    errorCode: "",
    disabled: isDisabled,
    code: ""
  };
}
export const DefaultProfessionData = (): ProfessionData => {
  return {
    value: '',
    errorCode: '',
    disabled: false,
    code: '',
    other: {
      value: '',
      errorCode: '',
      disabled: false,
    },
  };
}

export interface ProfessionData {
  value: string,
  errorCode: string,
  disabled: boolean,
  code: string,
  other: {
    value: string,
    errorCode: string,
    disabled: boolean,
  }
}

export interface PersonalDataDto {
  name: ValueFormHealth,
  lastName1: ValueFormHealth,
  lastName2: ValueFormHealth,
  birthDate: ValueFormHealth,
  docType: ValueFormHealth,
  docNumber: ValueFormHealth,
  civilStatus: ValueFormHealth,
  sex: ValueFormHealth,
  nationality: ValueFormHealth,
  nationalityLabel: string;
  relationship: ValueFormHealth,
  profession: ProfessionData,
  passportDate: ValueFormHealth,
  physicalPerson: boolean,
  sameAsTaker: boolean,
  tc2: ValueFormHealth,
  reta: ValueFormHealth,
  responsable: ValueFormHealth,
  isStudent?: boolean,
}

export const DefaultPersonalData = (): PersonalDataDto => {
  return {
    name: DefaultValueFormHealth(),
    lastName1: DefaultValueFormHealth(),
    lastName2: DefaultValueFormHealth(),
    birthDate: DefaultValueFormHealth(),
    civilStatus: DefaultValueFormHealth(),
    docType: DefaultValueFormHealth(),
    docNumber: DefaultValueFormHealth(),
    sex: DefaultValueFormHealth(),
    nationality: DefaultValueFormHealth(),
    nationalityLabel: '',
    relationship: DefaultValueFormHealth(),
    profession: DefaultProfessionData(),
    passportDate: DefaultValueFormHealth(),
    physicalPerson: true,
    sameAsTaker: false,
    tc2: DefaultValueFormHealth(),
    reta: DefaultValueFormHealth(),
    responsable: DefaultValueFormHealth(),
    isStudent: false,
  };
}
