import { GipQuestDto } from "../containers/hirings/new-hiring/gip/gip-questionnaire/gip-questionnaire-dto/gip-questionnaire-dto";
import { HealthPricingSimulationResponseDto } from "./dto/response/GipSimulationResponse.dto";
import GeneralServices from "./GeneralServices";
import GestDocServices from "./Gest-Doc.Services";
import { BuildGipPrecontractualDocumentRequest, BuildGipPrecontractualDocumentResponseDto, BuildPrecontractualDocumentRequest,BuildPrecontractualDocumentResponseDto } from "./HealthService-dto";

class GipServices {

    getGipSimulation = async (args: any): Promise<HealthPricingSimulationResponseDto> => {
        try {
            const response = await GeneralServices.post({
                setLoading: args.setLoading,
                loader: args.loader === false ? false : true,
                endpoint: `gip/simulate`,
                data: args.data
            });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    }

    getGipQuestionnaires = async (args: any): Promise<GipQuestDto> => {
        try {
            const response = await GeneralServices.get({
                setLoading: args.setLoading,
                loader: args.loader === false ? false : true,
                endpoint: `senda/gipQuestionnaires`,
                data: args.data
            });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    }
    
    getGipInsuredQuestionnaire = async (args: any): Promise<any> => {
        try {
            const response = await GeneralServices.post({
                setLoading: args.setLoading,
                loader: args.loader === false ? false : true,
                endpoint: `gip/getInsuredQuestionnaire`,
                data: args.data
            });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    }

    buildGipPrecontractualDocument = async (setLoading: Function, loader: boolean, request: BuildGipPrecontractualDocumentRequest, productType: string): Promise<BuildGipPrecontractualDocumentResponseDto> => {
        try {
            const response = await GeneralServices.postFileResponse({
                setLoading: setLoading,
                loader: loader,
                endpoint: `gip/buildGipPrecontractualDocument?productType=${productType}`,
                data: request,
            });

            return {
                fileData: response.data,
                fileName: GestDocServices.getFileName(response.headers["content-disposition"]),
                fileType: response.headers["content-type"],
            }

        } catch (err) {
            throw err;
        }
    }

    sendGipPrecontractualDocument = async (setLoading: Function, loader: boolean, request: BuildGipPrecontractualDocumentRequest,  productType: string): Promise<BuildGipPrecontractualDocumentResponseDto> => {
        try {
            const response = await GeneralServices.post({
                setLoading: setLoading,
                loader: loader,
                endpoint: `gip/gip-send-mail?productType=${productType}`,
                data: request,
            });

            return {
                fileData: response.data,
                fileName: GestDocServices.getFileName(response.headers["content-disposition"]),
                fileType: response.headers["content-type"],
            }

        } catch (err) {
            throw err;
        }
    }
    getDatesForEffectDay = async (args: any): Promise<any> =>{
        try {
            const response = await GeneralServices.get({
                setLoading: args.setLoading,
                loader: args.loader === false ? false : true,
                endpoint: `gip/gip-get-effectDate`,
            });
            return response.data;
        }
        catch (err) {
            throw err;
        }
    }

    getGIPSimulationDocument = async(args: any, request: any) =>{
        try {
            const response = await GeneralServices.postFileResponse({
                setLoading: args.setLoading,
                loader: args.loader === false ? false : true,
                endpoint: `gip/simulation-document`,
                data: request
            });
            return {
                fileData: response.data,
                fileName: GestDocServices.getFileName(response.headers["content-disposition"]),
                fileType: response.headers["content-type"],
            }
        }
        catch (err) {
            throw err;
        }
    }

    
}
  


export default new GipServices();