import React from "react";
import { CampaignsContainer } from "./campaigns-style";
import GuideLine from "../../components/guide-line";
import CardCampaignWeek, { IStat } from "../../components/card-campaign-week/card-campaign-week";
import CampaignSidebar from "../../components/campaign-sidebar/campaign-sidebar";
import Title02Text from "../../components/components-text/title-02-text";
import { withRouter, RouteComponentProps } from "react-router-dom";
import GoBack from "../../components/go-back";
import Body02Text from "../../components/components-text/body-02-text";
import { colors } from "../../assets";
import CampaignsConfigurationServices from "../../services/CampaignsConfigurationServices";
import { ICampaign } from "../../models/campaigns-model";
import moment from "moment";
import "moment/locale/es.js";
import ZeroContent from "../../components/zero-content/zero-content";
import { capitalizeFirstLetter } from "../../utils";
import StatsServices from "../../services/StatsServices";
import CampaignProgress from "../../components/campaign-progress/campaign-progress";
import LoaderSpinner from "../../components/loader-spinner";
import Loader from "../../components/loader";
import { withGeneral } from "../../context/mixin/with-general";

interface IDate {
  start: string;
  end: string;
  stats?: any;
}

interface CampaignsProps extends RouteComponentProps {
}

interface CampaignsState {
  campaigns: ICampaign[];
  currentCampaign: ICampaign | null;
  conditionProducts: string[];
  campaignsDownloaded: boolean;
  filterDates: IDate[];
  nextPeriods?: IDate[];
  loadingCurrentCampaign: boolean;
}

class CampaignsLayout extends React.Component<CampaignsProps, CampaignsState> {
  constructor(props: CampaignsProps) {
    super(props);
    this.state = {
      campaigns: [],
      conditionProducts: [],
      currentCampaign: null,
      campaignsDownloaded: false,
      filterDates: [],
      loadingCurrentCampaign: false
    };
  }

  componentDidMount() {
    this.getAllCampaigns();
  }

  async getAllCampaigns() {
    const args = {
      setLoading: () => { },
      loader: true
    };
    const resp = await CampaignsConfigurationServices.getCampaigns(args);

    const campaigns = resp.items.sort((campaignA: any, campaignB: any) => {
      if (moment(campaignA.start).isAfter(moment(campaignB.start))) return -1;
      if (moment(campaignA.start).isBefore(moment(campaignB.start))) return 1;
      return 0;
    });
    let currentCampaign: any = null;

    // resp.forEach((campaign: any) => {
    //   campaign.status = this.getCampaignStatus(campaign.start, campaign.end);
    //   if (campaign.status === "inProgress") currentCampaign = campaign;
    // });
    let filterDates: any = [];
    if (currentCampaign) {
      currentCampaign = await this.getCurrentCampaign(currentCampaign.id);
      filterDates = await this.getFilterDates(currentCampaign);
    }
    const conditionProducts: any[] = this.getConditionsProducts(
      currentCampaign
    );

    this.setState({
      conditionProducts,
      campaigns,
      currentCampaign,
      filterDates,
      campaignsDownloaded: true
    });
  }

  async getFilterDates(currentCampaign: any) {
    const filterDates: any = [];
    const userStats = await this.getUserStats(currentCampaign.id);

    userStats.forEach((stat: any) => {
      const stats: IStat = {
        points: stat.points,
        policies: stat.policies,
        sum: stat.sum
      }
      const filterDate = filterDates.filter((filterDate: any) => filterDate.start === stat.rangeStart);
      if (filterDate.length > 0) {
        filterDate[0].stats[stat.product] = stats;
      } else {
        filterDates.push({
          stats: {
            [stat.product]: stats
          },
          start: stat.rangeStart,
          end: stat.rangeEnd
        })
      }
    });

    return filterDates;
  }

  async getCurrentCampaign(id: number) {
    const args = {
      id,
      loader: false,
      setLoading: () => { }
    };
    const currentCampaign = await CampaignsConfigurationServices.getCampaign(
      args
    );
    return currentCampaign;
  }

  async getUserStats(campaignId: number) {
    const args = {
      id: campaignId,
      loader: false,
      setLoading: () => { }
    };
    const stats = await StatsServices.getCampaignStats(args);
    return stats;
  }

  async selectCampaign(campaign: ICampaign) {
    if (!campaign.id) return;

    this.setState({ loadingCurrentCampaign: true })
    const currentCampaign = await this.getCurrentCampaign(campaign.id);
    const filterDates = await this.getFilterDates(currentCampaign);
    const nextPeriods = this.getNextPeriods(currentCampaign.summaryDisplay, currentCampaign.end);
    const conditionProducts: any[] = this.getConditionsProducts(
      currentCampaign
    );

    this.setState({
      conditionProducts,
      filterDates,
      nextPeriods,
      currentCampaign,
      loadingCurrentCampaign: false
    });
  }

  getConditionsProducts(currentCampaign: any) {
    if (!currentCampaign) return [];
    return Array.from(
      new Set(
        currentCampaign.prizes
          .map((prize: any) =>
            prize.conditions.map((cond: any) => cond.products)
          )
          .flat(10)
      )
    );
  }

  // getCampaignStatus(start: string, end: string) {
  //   const startM = moment(start);
  //   const endM = moment(end);
  //   const now = moment();
  //   if (endM.isBefore(now)) return "done"; //TODO CHECK WHEN undone
  //   if (startM.isAfter(now)) return "pending";
  //   return "inProgress";
  // }

  getNextPeriods(unitsRaw: string, campaignEnd: string) {
    const current = moment();
    const end = moment(campaignEnd);
    const unit: any = unitsRaw === "WEEKLY" ? "week" : "month";
    const units: any = `${unit}s`;
    const nextPeriods: any = [];
    while (current.isBefore(end)) {
      nextPeriods.push({
        start: current.toISOString(),
        end: current.endOf(units).toISOString()
      });
      current.add(1, units);
    }
    return nextPeriods;
  }

  render() {
    const {
      campaigns,
      currentCampaign,
      conditionProducts,
      campaignsDownloaded,
      filterDates,
      nextPeriods,
      loadingCurrentCampaign
    } = this.state;
    const prizesCond = currentCampaign
      ? currentCampaign.prizes.map((prize: any) => prize.conditions)
      : [];
    const prevPeriods = filterDates;
    return (
      <>
        {loadingCurrentCampaign ? (
          <CampaignsContainer>
            <div className="campaigns-loading-container">
              <div className="loader-holder">
                <div className="spinner"></div>
              </div>
            </div>
          </CampaignsContainer>
        ) : currentCampaign ? (
          <CampaignsContainer>
            <div className="new-hiring-flow-status">
              <div className="new-hiring-flow-status-title">
                <GoBack onclick={() => this.props.history.goBack()} />
              </div>
              {/* <div className="new-hiring-flow-status-guide">
                <GuideLine
                  steps={new Array(
                    prevPeriods.length + nextPeriods.length
                  ).fill(0)}
                  currentStep={prevPeriods.length ? prevPeriods.length - 1 : 0}
                />
              </div> */}
            </div>
            {/* components */}

            <div className="campaigns-section-title">
              <Title02Text>{`${capitalizeFirstLetter(
                moment(currentCampaign.start).format("MMMM")
              )} - ${capitalizeFirstLetter(
                moment(currentCampaign.end).format("MMMM")
              )} ${moment(currentCampaign.end).format("YYYY")}`}</Title02Text>
            </div>
            <div className="campaigns-section-subtitle">
              <Body02Text color={colors["PR-001-50"]}>
                {`${moment(currentCampaign.start).format(
                  "DD/MM/YYYY"
                )} - ${moment(currentCampaign.end).format("DD/MM/YYYY")}`}
              </Body02Text>
            </div>
            {/*currentCampaign.prizes.length > 1 && (   //!!FOR TRAVEL
              <div className="campaigns-section-progress">
                <CampaignProgress prizes={prizesCond} />
              </div>
            )*/}
            {currentCampaign && currentCampaign.status === 'cancelled' && (
              <div className="campaigns-section-advice">
                <Title02Text>
                  {'¡La campaña ha sido cancelada!'}
                </Title02Text>
              </div>
            )
            }

            {prevPeriods && prevPeriods[prevPeriods.length - 1] && (
              <div className="campaigns-card-week-item">
                <CardCampaignWeek
                  stats={prevPeriods[prevPeriods.length - 1].stats}
                  conditionProducts={conditionProducts}
                  isTotal={true}
                  type={currentCampaign.status === 'cancelled' ? 'red' : "blue"}
                  date={moment().toISOString()
                  }
                />
              </div>
            )}

            {prevPeriods && prevPeriods.slice(0, prevPeriods.length - 1).map(filterDate => (
              <div className="campaigns-card-week-item">
                <CardCampaignWeek
                  stats={filterDate.stats}
                  conditionProducts={conditionProducts}
                  type={"light-green"}
                  date={
                    moment(filterDate.end).isBefore(moment())
                      ? filterDate.end
                      : moment().toISOString()
                  }
                />
              </div>
            )
            )}

            {nextPeriods && nextPeriods.length > 0 && (
              <div className="campaigns-card-divider">
                <Body02Text color={colors["PR-001-100"]}>
                  {currentCampaign.summaryDisplay === "MONTHLY"
                    ? "PRÓXIMOS MESES"
                    : "PRÓXIMAS SEMANAS"}
                </Body02Text>
              </div>
            )}

            {nextPeriods && nextPeriods.map(filterDate => (
              <div className="campaigns-card-week-item">
                <CardCampaignWeek date={filterDate.end} disable={true} />
              </div>
            ))}
          </CampaignsContainer>
        ) : campaignsDownloaded ? (
          <ZeroContent
            title={"Oops"}
            text={"No existe ninguna campaña en progreso actualmente"}
          />
        ) : (
                <></>
              )}
        <CampaignSidebar
          campaigns={campaigns}
          prizes={currentCampaign ? currentCampaign.prizes : undefined}
          onCampaignClick={campaign => this.selectCampaign(campaign)}
        />
      </>
    );
  }
}

export default withRouter(CampaignsLayout);
